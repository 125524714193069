// import { json_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18 } from '../../../LocalStore/TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18';
import { json_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20 } from '../../../LocalStore/TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20';
import { jsonAterrosSanitariosAtivosPe15 } from '../../../LocalStore/ATERROS_SANITARIOS_ATIVOS_PE_15';
// Essa camada é muito pesada, não foi possível copiar da branch main
//import { json_TRA_PEAD_REDE_COPERGAS_01092023rede_copergs_01092023_19 } from '../../../LocalStore/TRA_PEAD_REDE_COPERGAS_01092023rede_copergs_01092023_19';
import { json_estacoes_solarimetricas_ifpe_16 } from '../../../LocalStore/estacoes_solarimetricas_ifpe_16';
import { jsonCnaeEmpresasTotal1 } from '../../../LocalStore/CNAE_EMPRESAS_total_1';
import { useLayers } from '../../../context/LayersContext';
import { GeoJSON, Pane, Marker, CircleMarker } from 'react-leaflet';
import {
  style_ATERROS_SANITARIOS_ATIVOS_PE_15_0,
  style_CNAE_EMPRESAS_total_1_0,
  style_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20_0,
  style_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20_1,
  style_estacoes_solarimetricas_ifpe_16_0,
  style_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18_0,
  style_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18_1,
  // style_TRA_PEAD_REDE_COPERGAS_01092023rede_copergs_01092023_19_0,
} from './layerStyles';

export function RenderAdditionalInformationLayers() {
  const { /*addNewLayer,*/ layers } = useLayers();
  if (!layers) return null;
  const localStorageDict = [
    // {
    //   name: 'Rede Copergás (Aço)',
    //   data: json_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18,
    //   style: [
    //     style_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18_0,
    //     style_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18_1,
    //   ],
    //   layerType: 'GEOJSON',
    // },
    {
      name: 'Rede Copergás (Cobre Revestido)',
      data: json_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20,
      style: [
        style_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20_0,
        style_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20_1,
      ],
      layerType: 'GEOJSON',
    },
    // {
    //   name: 'Rede Copergás (PEAD)',
    //   data: json_TRA_PEAD_REDE_COPERGAS_01092023rede_copergs_01092023_19,
    //   style: style_TRA_PEAD_REDE_COPERGAS_01092023rede_copergs_01092023_19_0,
    //   layerType: 'GEOJSON',
    // },
    {
      name: 'Estações solarimétricas',
      data: json_estacoes_solarimetricas_ifpe_16,
      style: style_estacoes_solarimetricas_ifpe_16_0,
      layerType: 'MARKER',
    },
    {
      name: 'Consumo de Energia Elétrica 2022 (Mwh)',
      data: jsonCnaeEmpresasTotal1,
      style: style_CNAE_EMPRESAS_total_1_0,
      layerType: 'CIRCLEMARKER',
    },
    {
      name: 'Aterros Sanitários Ativos',
      data: jsonAterrosSanitariosAtivosPe15,
      style: style_ATERROS_SANITARIOS_ATIVOS_PE_15_0,
      layerType: 'CIRCLEMARKER',
    },
  ];

  return (
    <>
      {localStorageDict.map((obj) => {
        if (layers.includes(obj.name)) {
          if (obj.layerType === 'GEOJSON') {
            //@ts-ignore
            return <GeoJSON data={obj.data} key={obj.name} style={obj.style} />;
          } else if (obj.layerType === 'CIRCLEMARKER') {
            return (
              <Pane name={obj.name}>
                {obj.data.features.map((feature) => (
                  <CircleMarker
                    key={obj.name}
                    center={[
                      // @ts-ignore
                      feature.geometry.coordinates[1],
                      // @ts-ignore
                      feature.geometry.coordinates[0],
                    ]}
                    // @ts-ignore
                    pathOptions={
                      typeof obj.style === 'function'
                        ? obj.style(feature)
                        : obj.style
                    }
                  />
                ))}
              </Pane>
            );
          } else if (obj.layerType === 'MARKER') {
            return (
              <Pane
                name="pane_estacoes_solarimetricas_ifpe_16"
                style={{ zIndex: 416, mixBlendMode: 'normal' }}
              >
                {obj.data.features.map((features) => (
                  <Marker
                    key={obj.name}
                    position={[
                      // @ts-ignore
                      features.geometry.coordinates[1],
                      // @ts-ignore
                      features.geometry.coordinates[0],
                    ]}
                    // @ts-ignore
                    icon={obj.style?.icon}
                  />
                ))}
              </Pane>
            );
          }
        }
      })}
    </>
  );
}
