import { useLayers } from '../../../context/LayersContext';
import { GeoJSON, Pane, Marker } from 'react-leaflet';
import { jsonCGHBaseExistente21 } from '../../../LocalStore/CGH__Base_Existente_21';
import { jsonEOLBaseExistente24 } from '../../../LocalStore/EOL__Base_Existente_24';
import { jsonLinhasDeTransmissoBaseExistente22 } from '../../../LocalStore/Linhas_de_Transmisso__Base_Existente_22';
import { jsonUFVBaseExistente14 } from '../../../LocalStore/UFV__Base_Existente_14';
import { jsonUHEBaseExistente25 } from '../../../LocalStore/UHE__Base_Existente_25';
import { jsonUTEFssilBaseExistente20 } from '../../../LocalStore/UTE_Fssil__Base_Existente_20';
import {
  styleUHEBaseExistente25,
  styleCGHBaseExistente21,
  styleEOLBaseExistente24,
  styleLinhasDeTransmissoBaseExistente22,
  styleUFVBaseExistente14,
  styleUTEFssilBaseExistente20,
} from './layerStyles';
export function RenderSistemaEletricoExistente() {
  const { /*addNewLayer,*/ layers } = useLayers();
  if (!layers) return null;
  const localStorageDict = [
    {
      name: 'Usinas Fotovotaicas',
      data: jsonUFVBaseExistente14,
      style: styleUFVBaseExistente14,
    },
    {
      name: 'Usinas de combustível fóssil (Termelétricas)',
      data: jsonUTEFssilBaseExistente20,
      style: styleUTEFssilBaseExistente20,
    },
    {
      name: 'Usinas Hidrelétricas',
      data: jsonUHEBaseExistente25,
      style: styleUHEBaseExistente25,
    },
    {
      name: 'Centrais Geradoras Hidrelétricas',
      data: jsonCGHBaseExistente21,
      style: styleCGHBaseExistente21,
    },
    {
      name: 'Linhas de Transmissão',
      data: jsonLinhasDeTransmissoBaseExistente22,
      style: styleLinhasDeTransmissoBaseExistente22,
      layerType: 'GEOJSON',
    },
    {
      name: 'Parques Eólicos',
      data: jsonEOLBaseExistente24,
      style: styleEOLBaseExistente24,
    },
  ];

  return (
    <>
      {localStorageDict.map((obj) => {
        if (layers.includes(obj.name)) {
          if (obj.data) {
            if (obj.layerType === 'GEOJSON') {
              return (
                <GeoJSON
                  //@ts-ignore
                  data={obj.data}
                  key={obj.name}
                  //@ts-ignore
                  style={obj.style}
                />
              );
            }
            return (
              <Pane
                name={obj.name}
                style={{ zIndex: 418, mixBlendMode: 'normal' }}
              >
                {obj.data.features.map((feature, index) => (
                  <Marker
                    key={index}
                    position={[
                      // @ts-ignore
                      feature.geometry.coordinates[1],
                      // @ts-ignore
                      feature.geometry.coordinates[0],
                    ]}
                    // @ts-ignore
                    icon={obj.style?.icon}
                  />
                ))}
              </Pane>
            );
          }
        }
      })}
    </>
  );
}
