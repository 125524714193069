import L from 'leaflet';
import EOLPlanejada from '../../../assets/markers/EOL_planejada.svg';
import UFVExpansao from '../../../assets/markers/UFV_Expansao.svg';
import substacaoExpansao from '../../../assets/markers/substacao_expansao.svg';

export const styleUFVExpansaoPlanejada19 = {
  pane: 'pane_UFV__Expanso_Planejada_19',
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: UFVExpansao,
    iconSize: [22.8, 22.8],
  }),
  interactive: false,
};

export const styleEOLExpansaoPlanejada16 = {
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: EOLPlanejada,
    iconSize: [19.0, 19.0],
  }),
  interactive: false,
};

// @ts-ignore
// export function popLinhasDeTransmissoExpansoPlanejada(feature, layer) {
//   layer.on({
//     mouseout: function (e) {
//       for (const i in e.target._eventParents) {
//         e.target._eventParents[i].resetStyle(e.target);
//       }
//     },
//     mouseover: highlightFeature,
//   });
// }

export const styleLinhasDeTranmissaoExpansaoPlanejada17 = {
  opacity: 1,
  color: 'rgba(247,153,0,0.40784313725490196)',
  dashArray: '',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 5.0,
  fillOpacity: 0,
  interactive: false,
};

// eslint-disable-next-line camelcase
export const styleLinhasDeTransmissaoExpansaoPlanejada17_1 = {
  opacity: 1,
  color: 'rgba(227,87,5,1.0)',
  dashArray: '10,5',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 2.0,
  fillOpacity: 0,
  interactive: false,
};

// @ts-ignore
// export const highlightFeature = (e) => {
//   const layer = e.target;

//   if (e.target.feature.geometry.type === 'LineString') {
//     layer.setStyle({
//       color: 'rgba(255, 165, 0, 1)',
//     });
//   } else {
//     layer.setStyle({
//       fillColor: '#ffff00',
//       fillOpacity: 0.0,
//     });
//   }
//   layer.openPopup();
// };

// @ts-ignore
export const styleSubestaesExpansoPlanejada18 = {
  pane: 'pane_Subestaes__Expanso_Planejada_18',
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: substacaoExpansao,
    iconSize: [22.799999999999997, 22.799999999999997],
  }),
  interactive: false,
};
