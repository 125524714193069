export function styleGLOPEAnual6(feature: any) {
  console.log(feature);
  if (
    feature.properties.ANNUAL >= 750.0 &&
    feature.properties.ANNUAL <= 1000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(3,53,42,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(3,53,42,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 1000.0 &&
    feature.properties.ANNUAL <= 1250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(11,75,62,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(11,75,62,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 1250.0 &&
    feature.properties.ANNUAL <= 1500.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(15,94,82,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(15,94,82,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 1500.0 &&
    feature.properties.ANNUAL <= 1750.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(29,116,96,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(29,116,96,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 1750.0 &&
    feature.properties.ANNUAL <= 2000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(66,147,96,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(66,147,96,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 2000.0 &&
    feature.properties.ANNUAL <= 2250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(105,177,96,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(105,177,96,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 2250.0 &&
    feature.properties.ANNUAL <= 2500.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(142,207,97,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(142,207,97,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 2500.0 &&
    feature.properties.ANNUAL <= 2750.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(181,241,95,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(181,241,95,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 2750.0 &&
    feature.properties.ANNUAL <= 3000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(209,255,108,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(209,255,108,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 3000.0 &&
    feature.properties.ANNUAL <= 3250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(223,255,141,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(223,255,141,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 3250.0 &&
    feature.properties.ANNUAL <= 3500.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(233,250,171,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(233,250,171,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 3500.0 &&
    feature.properties.ANNUAL <= 3750.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(253,253,198,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(253,253,198,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 3750.0 &&
    feature.properties.ANNUAL <= 4000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(250,232,166,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(250,232,166,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 4000.0 &&
    feature.properties.ANNUAL <= 4250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(246,210,129,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(246,210,129,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 4250.0 &&
    feature.properties.ANNUAL <= 4500.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(244,188,95,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(244,188,95,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 4500.0 &&
    feature.properties.ANNUAL <= 4750.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(242,167,58,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(242,167,58,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 4750.0 &&
    feature.properties.ANNUAL <= 5000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(237,146,33,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(237,146,33,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 5000.0 &&
    feature.properties.ANNUAL <= 5250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(225,126,28,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(225,126,28,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 5250.0 &&
    feature.properties.ANNUAL <= 5500.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(214,105,21,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(214,105,21,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 5500.0 &&
    feature.properties.ANNUAL <= 5750.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(203,85,14,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(203,85,14,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 5750.0 &&
    feature.properties.ANNUAL <= 6000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(192,64,5,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(192,64,5,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 6000.0 &&
    feature.properties.ANNUAL <= 6250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(181,45,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(181,45,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 6250.0 &&
    feature.properties.ANNUAL <= 6500.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(168,35,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(168,35,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 6500.0 &&
    feature.properties.ANNUAL <= 6750.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(155,26,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(155,26,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 6750.0 &&
    feature.properties.ANNUAL <= 7000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(143,16,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(143,16,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 7000.0 &&
    feature.properties.ANNUAL <= 7250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(131,5,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(131,5,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 7250.0 &&
    feature.properties.ANNUAL <= 7500.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(116,0,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(116,0,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 7500.0 &&
    feature.properties.ANNUAL <= 7750.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(97,0,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(97,0,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 7750.0 &&
    feature.properties.ANNUAL <= 8000.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(79,0,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(79,0,0,1.0)',
      interactive: true,
    };
  }
  if (
    feature.properties.ANNUAL >= 8000.0 &&
    feature.properties.ANNUAL <= 8250.0
  ) {
    return {
      opacity: 1,
      color: 'rgba(60,0,0,1.0)',
      dashArray: '',
      lineCap: 'butt',
      lineJoin: 'miter',
      weight: 1,
      fill: true,
      fillOpacity: 1,
      fillColor: 'rgba(60,0,0,1.0)',
      interactive: true,
    };
  }
}
