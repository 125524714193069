export interface Layer {
  title: string;
  children?: Layer[];
}

export const mockLayers: Layer[] = [
  {
    title: 'Informações Adicionais',
    children: [
      // { title: 'Rede Copergás (Aço)' },
      // { title: 'Rede Copergás (PEAD)' },
      { title: 'Rede Copergás (Cobre Revestido)' },
      { title: 'Aterros Sanitários Ativos' },
      { title: 'Consumo de Energia Elétrica 2022 (Mwh)' },
      // { title: 'Empresas com resíduos para Biomassa' },
      { title: 'Estações solarimétricas' },
    ],
  },
  // {
  //   title: 'Infraestrutura Hídrica',
  //   children: [
  //     { title: 'Principais Rios' },
  //     { title: 'Transposição Rio São Francisco (Eixo Norte)' },
  //     { title: 'Transposição Rio São Francisco (Eixo Leste)' },
  //     { title: 'Instalações portuárias' },
  //     { title: 'Fluxos de Cabotagem' },
  //     { title: 'Navegação Interior' },
  //     { title: 'Instalações Portuárias' },
  //   ],
  // },
  // {
  //   title: 'Suape',
  //   children: [
  //     { title: 'Área total porto de suape' },
  //     { title: 'Área porto organizado de suape' },
  //   ],
  // },
  // {
  //   title: "APL'S (setores)",
  //   children: [
  //     { title: 'Caprino;Ovicultura' },
  //     { title: 'Caprinovinocultura' },
  //     { title: 'Ovinocaprinocultura;Pecuária' },
  //     { title: 'Confecção de Roupas' },
  //     { title: 'Fruticultura' },
  //     { title: 'Pecuária' },
  //     { title: 'Vinhos' },
  //     { title: 'Agropecuária' },
  //     { title: 'Renda Renascença' },
  //     { title: 'Tilapicultura' },
  //     { title: 'Apicultura' },
  //     { title: 'Avicultura' },
  //     { title: 'Avicultura;Softwares' },
  //   ],
  // },
  {
    title: 'Socioambiental',
    children: [
      { title: 'Unidade de Conservação' },
      { title: 'Territórios quilombolas' },
      { title: 'Polígono das secas' },
      { title: 'Área prioritária caatinga' },
      { title: 'Área prioritária Mata Atlântica' },
      { title: 'Assentamentos públicos' },
      { title: 'Terras indígenas (Homologadas)' },
      // { title: 'Reserva da Biosfera' },
      { title: 'Sítios Arqueológicos' },
    ],
  },
  // {
  //   title: 'Terminais de petróleo e gás',
  //   children: [
  //     { title: 'Petróleo e derivados' },
  //     { title: 'Regaseificação Gás Natural Liquefeito' },
  //     {
  //       title: 'Oleodutos e Terminais do Plano de Investimento em Logística',
  //     },
  //   ],
  // },
  // {
  //   title: 'Bases de Combustíveis',
  //   children: [
  //     { title: 'Gás Liquefeito de Petróleo (GLP)' },
  //     { title: 'Exploração e Produção de Hidrocarbonetos' },
  //     { title: 'Bases de Combustíveis' },
  //     { title: 'Plantas de Etanol' },
  //   ],
  // },
  {
    title: 'Sistema Elétrico Planejado',
    children: [
      { title: 'Parques Eólicos - Sistema Elétrico Planejado' },
      { title: 'Linhas de Transmissão - Sistema Elétrico Planejado' },
      { title: 'Subestações - Sistema Elétrico Planejado' },
      { title: 'Usinas Fotovoltaicas - Sistema Elétrico Planejado' },
    ],
  },
  {
    title: 'Sistema Elétrico Existente',
    children: [
      { title: 'Usinas Fotovotaicas' },
      { title: 'Usinas de combustível fóssil (Termelétricas)' },
      { title: 'Centrais Geradoras Hidrelétricas' },
      { title: 'Linhas de Transmissão' },
      // { title: 'Subestações' },
      { title: 'Parques Eólicos' },
      { title: 'Usinas Hidrelétricas' },
    ],
  },
  // {
  //   title: 'Infraestrutura de Transportes',
  //   children: [
  //     { title: 'Ferrovias planejadas' },
  //     { title: 'Pátios Ferroviários' },
  //     { title: 'Gasodutos de distribuição' },
  //     { title: 'Gasodutos de transporte' },
  //     { title: 'Rodovias Federais' },
  //     { title: 'Rodovias Estaduais' },
  //     { title: 'Outros trechos Rodoviários' },
  //     { title: 'Aeródromo e Heliponto' },
  //     { title: 'Aeroportos' },
  //     { title: 'AeroportosFerrovias planejadas' },
  //   ],
  // },
  {
    title: 'Potencial Eólico',
    children: [
      { title: 'Velocidade do Vento (50m)' },
      { title: 'Velocidade do Vento (100m)' },
      // { title: 'Velocidade do Vento (150m)' },
      // { title: 'Velocidade do Vento (200m)' },
    ],
  },
  {
    title: 'Potencial Solar',
    children: [
      { title: 'Radiação Global Anual' },
      // { title: 'Radiação Direta Anual' },
      // { title: 'Insolação Média Anual' },
    ],
  },
];
