import ExpandMoreIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import ExpandLessIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOnOutlined';
import { LegendContainer, LegendWrapper, IconWrapper } from './styles';
import ZoomControl from '../ZoomControl';

interface LegendActionBar {
  handleLegendModal: () => void;
  showLegendModal: boolean;
  onPositionChangeMiniMap: (position: string) => void;
  handleMiniMapModal: () => void;
  showMiniMapModal: boolean;
}

export default function LegendActionBar({
  handleLegendModal,
  showLegendModal,
  onPositionChangeMiniMap,
  handleMiniMapModal,
  showMiniMapModal,
}: LegendActionBar) {
  const modalRightMiniMap = showLegendModal ? '540px' : '20px';
  onPositionChangeMiniMap(modalRightMiniMap);

  const toggleMiniMapModal = () => {
    handleMiniMapModal();
    if (!showMiniMapModal) {
    }
  };

  return (
    <div
      id="legendActionBar"
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        borderRadius: '4px',
        zIndex: '1004',
        marginRight: '20px',
      }}
    >
      <LegendContainer
        style={{
          marginRight: showLegendModal ? '535px' : '20px',
          transition: 'margin-left 0.3s ease',
        }}
      >
        <LegendWrapper onClick={handleLegendModal} aria-label="Legend Modal">
          {showLegendModal ? (
            <ExpandMoreIcon fontSize="large" />
          ) : (
            <ExpandLessIcon fontSize="large" />
          )}
        </LegendWrapper>
        <div onClick={(e) => e.stopPropagation()}>
          <ZoomControl />
        </div>
        <IconWrapper
          className={showMiniMapModal ? 'active' : ''}
          onClick={toggleMiniMapModal}
        >
          <LocationOnIcon fontSize="large" />
        </IconWrapper>
      </LegendContainer>
    </div>
  );
}
