export var jsonReasPrioritariasMataAtlanticaPE4 = {
  type: 'FeatureCollection',
  name: 'AREAS_PRIORITARIAS_MATA_ATLANTICA_PE_4',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        OBJECTID: '217',
        AcaoPriori: 12.3,
        ImportBio_: 'Extremamente Alta',
        Prioridade: 'Muito Alta',
        COD_area: 'MA262',
        Shape_Leng: 1.0567,
        Area_ha: 39370.47499,
        Shape_Area: 0.03237,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.128941309, -8.960488601999934],
              [-36.129806164999898, -8.965793261999975],
              [-36.129588632, -8.971327036999973],
              [-36.129570185, -8.971424224999968],
              [-36.129511895, -8.97173133499996],
              [-36.128485564, -8.977138677999966],
              [-36.12681049, -8.981276315999931],
              [-36.124610732999898, -8.98340088499998],
              [-36.116158404, -8.985978955999942],
              [-36.112188079, -8.987788069999961],
              [-36.110555345, -8.989193962999922],
              [-36.106231806, -8.994483346999971],
              [-36.104329164, -8.998926995999973],
              [-36.104328162999899, -8.998929333999973],
              [-36.10652679, -9.001016412999952],
              [-36.109793173, -9.005441417999977],
              [-36.109889154, -9.006133499999988],
              [-36.109071021, -9.007290242999941],
              [-36.109466085, -9.008422088999955],
              [-36.109469184, -9.008421713999944],
              [-36.111153412, -9.008218328999924],
              [-36.113773766, -9.009213782999952],
              [-36.115088127, -9.013400680999949],
              [-36.114236532999897, -9.014587846999975],
              [-36.111670080773898, -9.016612963320265],
              [-36.112437499490966, -9.016612963817181],
              [-36.112534902291841, -9.018429851175814],
              [-36.114532912, -9.01988251499994],
              [-36.115088482, -9.022119009999935],
              [-36.117916078999897, -9.023833936999951],
              [-36.120662656, -9.027446595999956],
              [-36.120665624, -9.027451460999943],
              [-36.121210855, -9.027867307999937],
              [-36.123831432, -9.029866018999943],
              [-36.127278573, -9.033669513999939],
              [-36.128901673, -9.034234849999965],
              [-36.133215388999901, -9.035290367999949],
              [-36.133218917, -9.035291027999957],
              [-36.134505014, -9.035427159999935],
              [-36.138392987, -9.035839073999966],
              [-36.138888326, -9.03600982299996],
              [-36.139799384999897, -9.036592172999965],
              [-36.140129649, -9.037045573999928],
              [-36.140760590890856, -9.03887629903827],
              [-36.142472262516129, -9.040202878198507],
              [-36.1428059039999, -9.040323624999925],
              [-36.142809531999902, -9.040323863999962],
              [-36.1467537, -9.040660041999956],
              [-36.147883170898886, -9.041121947267673],
              [-36.15202054061541, -9.037915212317159],
              [-36.154737776461722, -9.040021289003414],
              [-36.154783735, -9.039960209999949],
              [-36.155634845999899, -9.039948779999964],
              [-36.155635828999898, -9.039949791999957],
              [-36.155858107, -9.040178627999973],
              [-36.155861522999899, -9.040179479999955],
              [-36.155978854999901, -9.040203729999972],
              [-36.156216806, -9.040252907999964],
              [-36.156220382999898, -9.040253353999955],
              [-36.156652011474868, -9.040784424160007],
              [-36.160226635750575, -9.038013891327978],
              [-36.175683706437837, -9.036879319225136],
              [-36.181059901293395, -9.041046053632442],
              [-36.222980832599319, -9.042180736351838],
              [-36.226893194665138, -9.045212887864409],
              [-36.232419587094228, -9.046630977537518],
              [-36.23417606827087, -9.053475857924468],
              [-36.252147510299324, -9.054681041197057],
              [-36.256059868767863, -9.057713205300161],
              [-36.266603677817443, -9.0582805417136],
              [-36.291603640895289, -9.058280627149202],
              [-36.292515424846954, -9.054727963324808],
              [-36.318814077307763, -9.053546650757255],
              [-36.32285357293398, -9.050415820233866],
              [-36.326893084748008, -9.053546674139625],
              [-36.338183526248713, -9.054681222860097],
              [-36.343559651856424, -9.050514358950466],
              [-36.353216008112213, -9.049238528535227],
              [-36.354670657025167, -9.043569557588967],
              [-36.357702714108939, -9.039657057926831],
              [-36.358837276319207, -9.036699814544761],
              [-36.354670632743478, -9.031323570226562],
              [-36.354103343994097, -9.029113035737964],
              [-36.354881180222264, -9.021486493406883],
              [-36.378617157855501, -9.020270442726712],
              [-36.37846969152298, -9.019871482782662],
              [-36.378189965494471, -9.019524558511534],
              [-36.378574039859984, -9.019056854390271],
              [-36.379879104536997, -9.017373964736635],
              [-36.380855033532953, -9.016417528546071],
              [-36.381288933037808, -9.015750901582805],
              [-36.381395266178572, -9.015355510148652],
              [-36.381381798830887, -9.015351213187898],
              [-36.380511550067922, -9.014706552165592],
              [-36.38051193317915, -9.014703189600482],
              [-36.380479774322112, -9.014679366559449],
              [-36.380793706963971, -9.011927271126638],
              [-36.380894432831781, -9.010528227295822],
              [-36.381108321291947, -9.009528442883266],
              [-36.37868031994077, -9.007855917916231],
              [-36.378681814614026, -9.007851585881951],
              [-36.378648544194903, -9.007828667558897],
              [-36.378951613925778, -9.006950231368023],
              [-36.381232339600729, -9.00853956454921],
              [-36.381521184754831, -9.007553257376856],
              [-36.38151906865005, -9.006819779309296],
              [-36.381666089818168, -9.006077682139278],
              [-36.382571646863994, -9.004162913987216],
              [-36.382694333276788, -9.004027346584735],
              [-36.38297910090472, -9.003890516534057],
              [-36.383085505991289, -9.003840009708654],
              [-36.384800071566019, -9.003819192201888],
              [-36.385025562179919, -9.003301838309142],
              [-36.385192739853039, -9.002765233528635],
              [-36.38484701797546, -9.002675460504065],
              [-36.382163235944518, -9.001575043753235],
              [-36.380988460548963, -9.001187717439166],
              [-36.37987452159183, -9.000884859948258],
              [-36.379405140235008, -9.000775616601402],
              [-36.379365246309021, -9.00077479192305],
              [-36.377124472116634, -9.000055840604489],
              [-36.375625013583488, -8.999624446610881],
              [-36.375633118273754, -8.999608600556428],
              [-36.375593243233538, -8.999597128804396],
              [-36.375624864296014, -8.99953531300423],
              [-36.375679726538124, -8.999187235802253],
              [-36.375715108565373, -8.998680564956089],
              [-36.375608229536113, -8.998284331756452],
              [-36.375666296062661, -8.997899844602102],
              [-36.375656798322552, -8.997744022668485],
              [-36.375686263710065, -8.997424498941427],
              [-36.375754949431325, -8.997123038997017],
              [-36.37574840146749, -8.996822632158683],
              [-36.375930625697777, -8.996395556709501],
              [-36.376258222638796, -8.996052392503884],
              [-36.376486400426984, -8.995388855611168],
              [-36.376402160930922, -8.995334109381588],
              [-36.376403837267219, -8.995328510202512],
              [-36.376370387883071, -8.995306772689389],
              [-36.376396968245444, -8.995218027589829],
              [-36.376528787273685, -8.995214708192179],
              [-36.376705424915372, -8.994737875952183],
              [-36.377045080865742, -8.994355397883282],
              [-36.377834466187608, -8.993977470383925],
              [-36.377921552037947, -8.993680874872439],
              [-36.377551381191267, -8.993279454383242],
              [-36.378355487515705, -8.993234555730112],
              [-36.379267274165329, -8.992681489758922],
              [-36.379524752765064, -8.992084250887444],
              [-36.379644834741271, -8.992085102545389],
              [-36.379791100478883, -8.992035738758318],
              [-36.380009003512669, -8.992172846699475],
              [-36.38080230178474, -8.991829997256502],
              [-36.380949449757281, -8.991604469770436],
              [-36.381262671035415, -8.99130282546497],
              [-36.381403671242367, -8.990849073425977],
              [-36.381331710190238, -8.990554184827715],
              [-36.381481828623464, -8.990286520506459],
              [-36.381513614261848, -8.990313912956651],
              [-36.382356987684773, -8.991041027421431],
              [-36.382394022665949, -8.991015905759411],
              [-36.38244761506536, -8.990926828810075],
              [-36.382573470689067, -8.990886701959653],
              [-36.382953395582717, -8.990680368704034],
              [-36.383090314666219, -8.990394027262482],
              [-36.383234530848881, -8.990373564987976],
              [-36.383444224871027, -8.990326394647354],
              [-36.383556796608843, -8.990268991820471],
              [-36.383624174715862, -8.99027517376021],
              [-36.383637490078058, -8.9902748787826],
              [-36.383641037004224, -8.990275609931416],
              [-36.383676503567756, -8.990284899928156],
              [-36.383776067511462, -8.990305104996537],
              [-36.3838755586101, -8.990319308888957],
              [-36.38390559326848, -8.99032223618218],
              [-36.383927275922986, -8.990327977454172],
              [-36.384021119279168, -8.990346672560861],
              [-36.384148431804647, -8.990365938737057],
              [-36.384342469529315, -8.990372951650329],
              [-36.384483246704406, -8.990350995601943],
              [-36.384563932979063, -8.990338212638335],
              [-36.384570413493748, -8.990338107417699],
              [-36.384636917459716, -8.990336926607824],
              [-36.384748955000248, -8.990334395915568],
              [-36.384889151213315, -8.990313728595652],
              [-36.384990078529256, -8.990295957992032],
              [-36.385016041057384, -8.990291001828211],
              [-36.385069433807303, -8.990311066602374],
              [-36.385160749169188, -8.990372702538139],
              [-36.385655432052488, -8.990527104442265],
              [-36.386130064550798, -8.990361141753226],
              [-36.386483957668474, -8.990094045803517],
              [-36.386667684665895, -8.989584401798652],
              [-36.386638192298676, -8.989261630619694],
              [-36.386593240585569, -8.989080449603023],
              [-36.386568739455697, -8.989014900717052],
              [-36.386635370226088, -8.98898026962371],
              [-36.386955688054513, -8.988677085678887],
              [-36.387038149590865, -8.988353487123675],
              [-36.387045545615422, -8.988318978338157],
              [-36.387106750775899, -8.988140300134717],
              [-36.387115950840439, -8.988121618517823],
              [-36.387154853713469, -8.988081198488487],
              [-36.38737125577876, -8.98778219189461],
              [-36.387453088689028, -8.987433461784804],
              [-36.38745128195103, -8.987233534400048],
              [-36.387432764910102, -8.98705530226033],
              [-36.387372493245948, -8.986836918988558],
              [-36.387312538143078, -8.986696899941933],
              [-36.387263507105104, -8.986580771385434],
              [-36.387203471063287, -8.986491324814722],
              [-36.387114143203121, -8.986348464809737],
              [-36.386940511095645, -8.986207452911572],
              [-36.386841550597012, -8.986154396508141],
              [-36.386843913116081, -8.986145280979883],
              [-36.386809744274217, -8.986126961789807],
              [-36.386866686648204, -8.985907177374315],
              [-36.38688660393359, -8.985728729397295],
              [-36.38692444380797, -8.98560742164517],
              [-36.386964432162756, -8.985248135293148],
              [-36.386856982064046, -8.984914903200149],
              [-36.386775543956162, -8.984580867113266],
              [-36.386433862732531, -8.984278550114936],
              [-36.386338713560747, -8.984190933664593],
              [-36.386255743907384, -8.98412735339457],
              [-36.386245116618795, -8.984118228873115],
              [-36.38624183948923, -8.984113633337472],
              [-36.386223940282548, -8.984099917776916],
              [-36.386213312993959, -8.984090790557502],
              [-36.38614257322115, -8.983991626812099],
              [-36.386065159579402, -8.983896981260614],
              [-36.385963432766175, -8.98377997856312],
              [-36.385959482943747, -8.983776643876979],
              [-36.385959637627138, -8.983776191517961],
              [-36.385927679318911, -8.983749206460686],
              [-36.385941151163138, -8.983709961845193],
              [-36.385950978954497, -8.983682179988591],
              [-36.386005243147395, -8.98358089744039],
              [-36.386102797106332, -8.983321537458949],
              [-36.386161296206808, -8.982980603571946],
              [-36.386131848805746, -8.982586824622217],
              [-36.386058664675602, -8.982395706996215],
              [-36.386065238719766, -8.982339033519509],
              [-36.385842549494043, -8.981937849552082],
              [-36.385551592731758, -8.981637767369023],
              [-36.384735368042755, -8.980592592374194],
              [-36.384449665119917, -8.979682382236092],
              [-36.384554535063671, -8.979474080364469],
              [-36.384739531004527, -8.979355912145593],
              [-36.385205485944368, -8.97949371526272],
              [-36.385237192442446, -8.979521132893922],
              [-36.385237286871245, -8.97952116077289],
              [-36.385437358147499, -8.979694163354907],
              [-36.385969996917765, -8.980090095281639],
              [-36.385988330497014, -8.980107531337467],
              [-36.386001799643282, -8.980117543489769],
              [-36.38650647219589, -8.98059751886035],
              [-36.387442099872942, -8.98077477163838],
              [-36.388412104135227, -8.980767817180947],
              [-36.389525755309307, -8.98007489763836],
              [-36.389818113215313, -8.979940796130791],
              [-36.390164155251512, -8.979782069386943],
              [-36.390466656610897, -8.979643314787097],
              [-36.391475988226205, -8.978630376890351],
              [-36.391477065614026, -8.978629292307971],
              [-36.391508613831434, -8.978657054379482],
              [-36.391508888124633, -8.978656780985602],
              [-36.39223610511209, -8.979296728661041],
              [-36.392590233852161, -8.980130256306211],
              [-36.393609885188027, -8.981772590132891],
              [-36.397155580165872, -8.979966735276548],
              [-36.39716314526288, -8.979998264608184],
              [-36.397165427742266, -8.980005454687955],
              [-36.397187419763554, -8.979994254531164],
              [-36.397194984860619, -8.98002578476212],
              [-36.397287130296775, -8.980315887168388],
              [-36.397383921630649, -8.980590946614825],
              [-36.397549174754545, -8.98089955886752],
              [-36.397886792841177, -8.981547189451192],
              [-36.398202299296656, -8.982315518945995],
              [-36.398308828489576, -8.982603537623106],
              [-36.398342386691752, -8.982887987790377],
              [-36.398456123051574, -8.983191085400222],
              [-36.398548237910802, -8.983268263419575],
              [-36.398820650652453, -8.983349730305747],
              [-36.39913988120054, -8.983386894789305],
              [-36.39988091267395, -8.983453541747508],
              [-36.400452258265091, -8.983574909754225],
              [-36.400933030434942, -8.98365289176752],
              [-36.401416699321146, -8.983788326969659],
              [-36.401841882596784, -8.983988935141213],
              [-36.401850015166019, -8.984005281218685],
              [-36.401873740180918, -8.984016475979558],
              [-36.402084186036291, -8.984439440627625],
              [-36.402343422810645, -8.985123011717576],
              [-36.402621476100194, -8.985744231910701],
              [-36.40274072710281, -8.986163366346261],
              [-36.402765266903486, -8.98625625372307],
              [-36.402792170122495, -8.986376079392528],
              [-36.402813547906874, -8.986508352578369],
              [-36.402823571750389, -8.986652110106775],
              [-36.402816479696753, -8.986752628231159],
              [-36.402809481172596, -8.986849975346047],
              [-36.402812763698023, -8.986905811553925],
              [-36.402828281499922, -8.986957610705133],
              [-36.402863473770253, -8.98701236772655],
              [-36.403758930530159, -8.987232269953267],
              [-36.405025693980576, -8.987185042955332],
              [-36.405327585599537, -8.987215193626241],
              [-36.405753828276488, -8.987197572310095],
              [-36.405930315731439, -8.987228503592519],
              [-36.406035306184378, -8.987275440109443],
              [-36.406223623321523, -8.987405643955242],
              [-36.406360840080652, -8.987497343327675],
              [-36.406504442026346, -8.987562037857742],
              [-36.40671583216988, -8.98762531685486],
              [-36.406866278855659, -8.987699907524643],
              [-36.406886545077953, -8.987721712486973],
              [-36.406898156224884, -8.987727470846039],
              [-36.407033974538251, -8.98787360348399],
              [-36.407101062164259, -8.988009713177689],
              [-36.407133061841307, -8.988126365139578],
              [-36.407146747724198, -8.988277578947077],
              [-36.407126181128319, -8.988427604750086],
              [-36.407067766564126, -8.988604283760651],
              [-36.407044207024512, -8.988711049475285],
              [-36.407056305603987, -8.988814935560754],
              [-36.407117999995648, -8.988890446237065],
              [-36.407210220075569, -8.988938766810577],
              [-36.407365525798355, -8.989014776610588],
              [-36.407652930238498, -8.989258986612867],
              [-36.40765308582121, -8.989259592755957],
              [-36.407684811205002, -8.989286549934263],
              [-36.408180556187688, -8.991195478788256],
              [-36.409708776838556, -8.991039574116996],
              [-36.410030962558835, -8.990207749787771],
              [-36.410653886967282, -8.990156606242351],
              [-36.410841685195578, -8.990165335961422],
              [-36.41131178690938, -8.990258030883354],
              [-36.415861951767624, -8.98669469660291],
              [-36.41732172321889, -8.967649249113492],
              [-36.422904370939932, -8.965968334371098],
              [-36.424501949105604, -8.964523139131529],
              [-36.424240941566154, -8.963895699227066],
              [-36.423849158212022, -8.962446432756622],
              [-36.423290734080069, -8.959020079612912],
              [-36.422336737750186, -8.956562815221673],
              [-36.421091293628251, -8.954271959583195],
              [-36.420504093888781, -8.953542833335064],
              [-36.41980060821146, -8.952917779331983],
              [-36.418747109991102, -8.95232727188602],
              [-36.417191789169692, -8.951851506241951],
              [-36.415227583486171, -8.95179531749983],
              [-36.413778640771625, -8.952112263769891],
              [-36.412380139231971, -8.952840315328217],
              [-36.41104626657193, -8.953833013982376],
              [-36.407426435981165, -8.957405195793115],
              [-36.405727159780554, -8.958844452809217],
              [-36.403570090886944, -8.959957409706647],
              [-36.401818520008646, -8.960604135572339],
              [-36.400016965710336, -8.961104893376103],
              [-36.398112969637793, -8.961257204356457],
              [-36.397166926912121, -8.961144087629521],
              [-36.396336883240508, -8.960883166424935],
              [-36.39511588898705, -8.960111580484977],
              [-36.394274095672927, -8.958932034185977],
              [-36.393850665175989, -8.957438487795912],
              [-36.393802930061156, -8.956331824354152],
              [-36.394166784069796, -8.954893445975699],
              [-36.394740479589416, -8.953764753640428],
              [-36.395761271265599, -8.952525049891108],
              [-36.397357933022136, -8.951046713734797],
              [-36.398637892224883, -8.950070729880224],
              [-36.400399021097883, -8.948950855397584],
              [-36.401137690052337, -8.948333031943832],
              [-36.402051740295519, -8.947245601403324],
              [-36.402436550306504, -8.946383083212652],
              [-36.402500724129084, -8.945320372337278],
              [-36.40223656986177, -8.944386780725324],
              [-36.401586173862768, -8.943007725226266],
              [-36.400158125996654, -8.940789154395929],
              [-36.399210396142848, -8.938838874714236],
              [-36.399059516883142, -8.937606633539247],
              [-36.399404514806463, -8.936469559522493],
              [-36.399793381659208, -8.935993566349964],
              [-36.400389795852334, -8.935620403458813],
              [-36.40162465765178, -8.935466462906902],
              [-36.402450373785655, -8.935627255393456],
              [-36.405051896627697, -8.936727048914122],
              [-36.406390298273607, -8.937138734265147],
              [-36.407464285748119, -8.937258132756597],
              [-36.408657809310398, -8.936955675463992],
              [-36.409508543684296, -8.936386597963065],
              [-36.410503923217448, -8.935201208768717],
              [-36.411513310590749, -8.932782134987519],
              [-36.411821242056646, -8.931305890654301],
              [-36.411804023636762, -8.929826114683408],
              [-36.41148237121439, -8.928388830779852],
              [-36.408687086739633, -8.923409631749053],
              [-36.407989865739694, -8.921205431185626],
              [-36.407901146720519, -8.919855344648397],
              [-36.407971011452958, -8.918856769824004],
              [-36.408660932655891, -8.915763253970965],
              [-36.408880792614468, -8.914097050338569],
              [-36.408861655940598, -8.912401888536294],
              [-36.40843572083179, -8.91055204154344],
              [-36.407983719772801, -8.909550221965105],
              [-36.407031809870091, -8.908305193329966],
              [-36.406264812271218, -8.90760359622891],
              [-36.403736608457677, -8.905716062246427],
              [-36.402276250648413, -8.90417940145818],
              [-36.401996414003236, -8.903777370329351],
              [-36.389178628690729, -8.904464608854596],
              [-36.385694215315766, -8.907924676682455],
              [-36.374936481833117, -8.908278105749957],
              [-36.374369197580336, -8.910488615956808],
              [-36.371238449794589, -8.914528182629454],
              [-36.375503766085899, -8.920031512843991],
              [-36.374369195781696, -8.922988697770165],
              [-36.37133711981221, -8.926901107500044],
              [-36.36963255289993, -8.933542812151018],
              [-36.364647026239254, -8.929678974804801],
              [-36.351892782525852, -8.928544521412505],
              [-36.347265661780796, -8.924958386620176],
              [-36.342170613882672, -8.931068107207352],
              [-36.340683742260865, -8.936862441848177],
              [-36.331313879431946, -8.94521152379275],
              [-36.314392931888278, -8.946346139962316],
              [-36.309765799452066, -8.949932332311221],
              [-36.304670728171516, -8.943822672878014],
              [-36.303135694256866, -8.937841520269899],
              [-36.297980607618911, -8.933846068940909],
              [-36.293559636667851, -8.932711492341468],
              [-36.289520135645716, -8.929580710381458],
              [-36.285480633724205, -8.932711508529223],
              [-36.281059663672522, -8.933846101316476],
              [-36.272980658930237, -8.941044930579039],
              [-36.270770172105699, -8.941612228321617],
              [-36.269861090616814, -8.938069111700088],
              [-36.25827019911037, -8.93744555326964],
              [-36.25750353426065, -8.934191001213662],
              [-36.253162927626988, -8.932749211706721],
              [-36.248018349567133, -8.936915920932961],
              [-36.2364193164982, -8.937975250357454],
              [-36.229862127435183, -8.932332860672943],
              [-36.229103604147952, -8.929112217554746],
              [-36.218559804990946, -8.928544946791817],
              [-36.214647466307497, -8.925512842943306],
              [-36.201893184822552, -8.924378285229636],
              [-36.196517025040635, -8.920211601184405],
              [-36.189393219921101, -8.921346200266896],
              [-36.185480868647119, -8.924378319403843],
              [-36.179954484311963, -8.925796392889197],
              [-36.178194622583703, -8.932655435799688],
              [-36.166603732875956, -8.933279037397597],
              [-36.165445080526013, -8.937331797124671],
              [-36.160430736888941, -8.940994946259707],
              [-36.151943530769302, -8.942229896192714],
              [-36.14376419765955, -8.949328327840021],
              [-36.139443622525107, -8.950563278672348],
              [-36.131264293912011, -8.95766170672232],
              [-36.128289707103825, -8.957880137891172],
              [-36.128941309, -8.960488601999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID: '218',
        AcaoPriori: 12.3,
        ImportBio_: 'Muito Alta',
        Prioridade: 'Alta',
        COD_area: 'MA263',
        Shape_Leng: 2.25384,
        Area_ha: 116230.15163,
        Shape_Area: 0.09551,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.754535754, -8.912252498999983],
              [-35.75882361, -8.910011842999943],
              [-35.760917123, -8.908080695999963],
              [-35.760921663, -8.908080817999972],
              [-35.763105661999901, -8.908095974999974],
              [-35.763111111999898, -8.908096120999966],
              [-35.766592932, -8.907147511999941],
              [-35.769022799, -8.907437363999971],
              [-35.771586442999897, -8.906260286999958],
              [-35.771590962999902, -8.906260227999942],
              [-35.773241219999903, -8.906139212999966],
              [-35.778188397, -8.898888191999955],
              [-35.780205043, -8.898240597999964],
              [-35.780207962, -8.898241330999953],
              [-35.781730663, -8.898622686999932],
              [-35.782980726999902, -8.897542131999963],
              [-35.780288288999898, -8.891341104999974],
              [-35.779177729, -8.89007685199994],
              [-35.779138175999897, -8.888641739999963],
              [-35.780613804, -8.888016266999943],
              [-35.782082381, -8.883464002999967],
              [-35.783677143, -8.882001459999966],
              [-35.782202378, -8.878918779999932],
              [-35.781465204, -8.878292021999938],
              [-35.779091752999904, -8.873126366999939],
              [-35.778836198, -8.871569118999952],
              [-35.776915682, -8.869265270999966],
              [-35.776873540999901, -8.869056345999983],
              [-35.775768909, -8.863584244999972],
              [-35.776965431999898, -8.861697818999975],
              [-35.776863877, -8.860635854999931],
              [-35.775958233, -8.859527921999934],
              [-35.775964622999901, -8.859213134999946],
              [-35.775979954, -8.858457890999944],
              [-35.77598449, -8.858457940999928],
              [-35.777043754, -8.858431631999968],
              [-35.779014312, -8.85958793199995],
              [-35.780487735, -8.861074954999944],
              [-35.78049192200001, -8.861074808999934],
              [-35.781324312, -8.860965480999942],
              [-35.783247785999897, -8.855649401999937],
              [-35.785462537999898, -8.854587957999975],
              [-35.787596138, -8.851130423999951],
              [-35.787600558, -8.851130827999945],
              [-35.788888556, -8.851310025999958],
              [-35.789786541999902, -8.853088766999971],
              [-35.790616482, -8.853413579999945],
              [-35.790617567, -8.853412439999937],
              [-35.790621022, -8.853413791999976],
              [-35.793610567, -8.850269195999934],
              [-35.795075238, -8.847685124999943],
              [-35.7962097079999, -8.84739971399995],
              [-35.796835993999899, -8.847871936999976],
              [-35.797752004, -8.850067897999965],
              [-35.797756521999901, -8.850068283999967],
              [-35.799564486, -8.850294027999954],
              [-35.799953834, -8.851802563999975],
              [-35.801796284, -8.854251103999966],
              [-35.805170003999898, -8.856871591999942],
              [-35.806565285, -8.859396947999928],
              [-35.807895677, -8.860387544999975],
              [-35.807898974, -8.860387723999956],
              [-35.807900173, -8.860388615999971],
              [-35.810249279999901, -8.860514928999974],
              [-35.810344617999903, -8.860520072999973],
              [-35.811638269999897, -8.860138474999939],
              [-35.815864841999897, -8.857403575999973],
              [-35.819911052, -8.856874305999948],
              [-35.819915594, -8.856874439999959],
              [-35.822150485999899, -8.856922074999936],
              [-35.8229761609999, -8.857419121999953],
              [-35.825497441, -8.863833518999968],
              [-35.824468872, -8.868652426999963],
              [-35.825748201, -8.870343746999936],
              [-35.825752732999902, -8.870344052999947],
              [-35.826571849999901, -8.870417219999979],
              [-35.827389449, -8.869714286999969],
              [-35.830583861, -8.868486597999953],
              [-35.830588356, -8.868486825999987],
              [-35.831684863, -8.868547114999949],
              [-35.832890595, -8.869914190999955],
              [-35.833896283999898, -8.870220466999969],
              [-35.833897204, -8.870219573999972],
              [-35.835444426, -8.868717977999953],
              [-35.835448966999898, -8.868718115999974],
              [-35.837050806, -8.86874164899996],
              [-35.840179253, -8.870206377999981],
              [-35.842515098, -8.872925008999962],
              [-35.842519232, -8.872924746999947],
              [-35.844267636, -8.872702601999951],
              [-35.845002539999903, -8.872480459999966],
              [-35.847369993, -8.871763757999927],
              [-35.847374534, -8.87176392499993],
              [-35.849138808, -8.871812126999947],
              [-35.8502341989999, -8.868899543999934],
              [-35.853201918, -8.866307455999966],
              [-35.855400883, -8.862719506999946],
              [-35.858394168, -8.860678818999927],
              [-35.859459490999903, -8.860525061999979],
              [-35.859756660999899, -8.860482171999923],
              [-35.8608852099999, -8.858902970999955],
              [-35.861930748, -8.858368983999981],
              [-35.863865953, -8.857970160999963],
              [-35.86386801, -8.857970653999923],
              [-35.86691804, -8.858701385999948],
              [-35.866922461999899, -8.858701567999969],
              [-35.871912342, -8.858879664999959],
              [-35.871916773999899, -8.858879834999936],
              [-35.874093252, -8.859437190999934],
              [-35.874095693, -8.859437815999975],
              [-35.8755210919999, -8.858891638999973],
              [-35.879059329, -8.859608536999932],
              [-35.880457577999898, -8.860736986999932],
              [-35.884333081, -8.862679674999972],
              [-35.884337022, -8.862680360999947],
              [-35.88554721, -8.862860398999947],
              [-35.886947482, -8.863068597999929],
              [-35.886951989999901, -8.863069034999974],
              [-35.888808476999898, -8.862625030999936],
              [-35.890787663, -8.861392853999963],
              [-35.892671187, -8.859514258999976],
              [-35.894318271, -8.855380393999951],
              [-35.8952310089999, -8.854374995999924],
              [-35.89620057, -8.854268296999981],
              [-35.899027873, -8.858559152999936],
              [-35.899821717999899, -8.861237962999944],
              [-35.900988367, -8.86241186399997],
              [-35.900992883999898, -8.862412252999956],
              [-35.906830731, -8.863151695999926],
              [-35.909724444999902, -8.86121958499996],
              [-35.909728959999903, -8.861219498999958],
              [-35.911079479, -8.861104701999976],
              [-35.911417499, -8.861289080999937],
              [-35.916392980999902, -8.867583546999985],
              [-35.9163960019999, -8.867581833999957],
              [-35.916397521, -8.867583755999938],
              [-35.917200013, -8.869386180999971],
              [-35.917197041999898, -8.869389499999954],
              [-35.917731838, -8.870590671999931],
              [-35.919701602, -8.876761329999969],
              [-35.921016069, -8.878270154999932],
              [-35.921146458, -8.880729660999977],
              [-35.921831109, -8.88266852199995],
              [-35.924276034999899, -8.884627504999969],
              [-35.924280408, -8.884627580999963],
              [-35.933218933, -8.884505015999927],
              [-35.933220591999898, -8.884505101999924],
              [-35.93322255, -8.884505202999947],
              [-35.936230689, -8.884858609999977],
              [-35.942535334999903, -8.885599302999942],
              [-35.9425374959999, -8.885599827999947],
              [-35.9425397649999, -8.885600370999946],
              [-35.944496408, -8.886329171999922],
              [-35.94971943, -8.890557149999953],
              [-35.950620962, -8.892294837999946],
              [-35.953103778, -8.892913627999974],
              [-35.953440063999899, -8.894039159999986],
              [-35.953443725999897, -8.894037438999927],
              [-35.954518876999899, -8.893532284999937],
              [-35.954520744, -8.893533284999927],
              [-35.954522509, -8.893532452999978],
              [-35.95596498, -8.894304102999973],
              [-35.955967132999902, -8.894305254999942],
              [-35.959466909, -8.892923954999954],
              [-35.961014481999896, -8.894017953999933],
              [-35.961253767, -8.894370057999945],
              [-35.961961659999901, -8.89541170899996],
              [-35.962271088, -8.898094079999961],
              [-35.962791009999904, -8.898954526999939],
              [-35.962632379, -8.901207860999934],
              [-35.963546563, -8.902407647999954],
              [-35.965302547, -8.903143984999929],
              [-35.968046704, -8.903745293999988],
              [-35.969519319, -8.901104052999928],
              [-35.970655611, -8.900396928999943],
              [-35.970658043999897, -8.900397843999944],
              [-35.974113569999901, -8.901696145999949],
              [-35.97523921, -8.902118333999962],
              [-35.975242783999903, -8.902119238999937],
              [-35.978041636999897, -8.902501033999945],
              [-35.979741284999911, -8.902732883999931],
              [-35.981577586, -8.904563382999978],
              [-35.984158393, -8.905216558999941],
              [-35.984912230710421, -8.904532994073165],
              [-35.98327197402233, -8.90411211688064],
              [-35.983271966827772, -8.887445375303798],
              [-35.989863018963092, -8.88779873872079],
              [-35.9933474161503, -8.891258787662878],
              [-35.99993846828562, -8.891612151979189],
              [-35.999371158851829, -8.860234845361788],
              [-35.996339098170779, -8.856322458114903],
              [-35.995204530564592, -8.851901453888956],
              [-35.992172472581501, -8.847989067541391],
              [-35.991037903176675, -8.838660135556779],
              [-35.99927721387229, -8.836545635172854],
              [-35.999938407131765, -8.829111819354921],
              [-35.99958507968762, -8.826687332554286],
              [-35.987451570533494, -8.81452840676053],
              [-35.995418411830201, -8.806535944678046],
              [-35.996125045134704, -8.802398553350486],
              [-35.991958415948091, -8.798202486655384],
              [-35.991251761059857, -8.79335351665145],
              [-35.983625148581723, -8.785702299621391],
              [-35.982918494592809, -8.78085333051672],
              [-35.979458511301232, -8.777368848793287],
              [-35.978751855513678, -8.772519880587993],
              [-35.971125244834127, -8.76486867524909],
              [-35.97041859084527, -8.760019710641075],
              [-35.966958607553636, -8.75653523431356],
              [-35.966251944571525, -8.748227230702071],
              [-35.982918375882321, -8.731534926795405],
              [-35.983625006488808, -8.726685958590053],
              [-35.989150475314148, -8.721132700713497],
              [-35.99819594458711, -8.720423659923938],
              [-36.003749903035555, -8.714897360125008],
              [-36.004104736844113, -8.708276820119124],
              [-36.003537448094733, -8.70606629912038],
              [-36.000505375722469, -8.702153887591805],
              [-35.999038501425673, -8.696437868612691],
              [-35.99548908415386, -8.696122153514523],
              [-35.99247080288518, -8.700679997683721],
              [-35.990967246141452, -8.702557119315543],
              [-35.988667294759807, -8.705025358133696],
              [-35.986455170269835, -8.706426879596791],
              [-35.98176242779374, -8.707815446325753],
              [-35.979892614952178, -8.707985162785008],
              [-35.977385879754479, -8.707844375717343],
              [-35.975683919077539, -8.707395082516996],
              [-35.973752028733088, -8.706526420158127],
              [-35.968771120990425, -8.703697235738787],
              [-35.967132773858395, -8.703299265948317],
              [-35.966816632481539, -8.703286163725409],
              [-35.965438345003577, -8.703229047782258],
              [-35.963765664453831, -8.703464268161383],
              [-35.962287482081592, -8.704083060185042],
              [-35.960991040104375, -8.704960451363718],
              [-35.958348304030949, -8.707363825679977],
              [-35.956555214151763, -8.707665693916567],
              [-35.954925076930692, -8.707598381460116],
              [-35.953142821184201, -8.707105931593446],
              [-35.951260954729207, -8.706215699894585],
              [-35.949884778859428, -8.705398979679103],
              [-35.946330143721127, -8.702945718170326],
              [-35.945837978939551, -8.702479875645679],
              [-35.94468511822356, -8.701388669751282],
              [-35.943959897731077, -8.700524664981231],
              [-35.943350861552972, -8.699585883381758],
              [-35.942646577277685, -8.697837575243852],
              [-35.942103089086231, -8.694914149967985],
              [-35.942068626166133, -8.693254529373007],
              [-35.942101840827263, -8.692937284528],
              [-35.942322815045827, -8.690826701590936],
              [-35.94234170350677, -8.688555967383934],
              [-35.94215332701441, -8.687049062464212],
              [-35.941551163455358, -8.684541694143832],
              [-35.940177838436455, -8.681728062599973],
              [-35.938483492319278, -8.679342761958878],
              [-35.936949464745965, -8.677398534714541],
              [-35.934478674551144, -8.674267056679298],
              [-35.932681357858314, -8.671337414390109],
              [-35.931771641448051, -8.669423337816738],
              [-35.931323308723677, -8.668096778443498],
              [-35.930752992856299, -8.665617983383186],
              [-35.930238539277127, -8.6625946137504],
              [-35.929799825701366, -8.658591578438575],
              [-35.92856088277847, -8.653487091256238],
              [-35.927540693648041, -8.650684614903014],
              [-35.925953375847598, -8.647606932513895],
              [-35.925361990663305, -8.644862568552639],
              [-35.925068387196291, -8.643976877530804],
              [-35.924264116295888, -8.642878404214912],
              [-35.923492750689832, -8.642369667625985],
              [-35.922639350725433, -8.642159715498451],
              [-35.921772080517087, -8.642224711301367],
              [-35.920894775673332, -8.642614264336771],
              [-35.920501558799856, -8.642935546238448],
              [-35.919690424273597, -8.644066156827648],
              [-35.918910694972567, -8.646123786783278],
              [-35.91837321245373, -8.648439997907531],
              [-35.917970114728973, -8.652645207110595],
              [-35.917987254008494, -8.654371057378341],
              [-35.918300389851083, -8.656696466768494],
              [-35.917907606450854, -8.65964474570842],
              [-35.917837372996303, -8.661071798924354],
              [-35.917927675721614, -8.66250940118789],
              [-35.918357253084707, -8.665669811312682],
              [-35.91903768374209, -8.668425093942858],
              [-35.920770602681102, -8.67334996043229],
              [-35.925127000510599, -8.68336644210757],
              [-35.925864505742311, -8.686226544319538],
              [-35.926009628441534, -8.687877441490684],
              [-35.925789310728078, -8.689510310852143],
              [-35.925322085945481, -8.690489360495519],
              [-35.924996028643761, -8.690896589705801],
              [-35.924179669056457, -8.691560451253793],
              [-35.923299203095723, -8.692025768574297],
              [-35.922344733722412, -8.692349214244814],
              [-35.921797426110288, -8.692422006270533],
              [-35.920701336897139, -8.69233312177613],
              [-35.919742073238069, -8.692000422981096],
              [-35.918352481281943, -8.69126510849793],
              [-35.917525038449696, -8.690616200876946],
              [-35.916796131636147, -8.689854034436109],
              [-35.916173198234446, -8.689005803774762],
              [-35.915151321076564, -8.687152169736805],
              [-35.910290996232447, -8.677219945543982],
              [-35.909271667753217, -8.674791226533841],
              [-35.908391903263691, -8.671640026366049],
              [-35.90734648545191, -8.669259831176248],
              [-35.906551708694337, -8.668018452889271],
              [-35.905882663454122, -8.66733536023861],
              [-35.904847216425878, -8.666884404191876],
              [-35.903979261833399, -8.666852368541925],
              [-35.903117866896025, -8.667094796987271],
              [-35.902302511851417, -8.667703544482947],
              [-35.901808052899298, -8.668406405131464],
              [-35.901286787854929, -8.669746932774672],
              [-35.90072979724232, -8.673146413276299],
              [-35.900702068445639, -8.674601450696343],
              [-35.900954493754853, -8.677242260421906],
              [-35.900972969426959, -8.678571616686725],
              [-35.900602196933789, -8.683798072622892],
              [-35.90039113414349, -8.685469224325175],
              [-35.898142802048653, -8.692587036362568],
              [-35.897477549249459, -8.694330187787898],
              [-35.896294180831717, -8.696682801669908],
              [-35.895382623509249, -8.697947427431757],
              [-35.894323768926768, -8.699035141258719],
              [-35.893461838892733, -8.699650034721287],
              [-35.892245212646401, -8.700123361403939],
              [-35.891155540995385, -8.700274067993803],
              [-35.889493175669486, -8.700221576364584],
              [-35.885406558266027, -8.699627317846421],
              [-35.883834541530803, -8.699036707877724],
              [-35.882490453471632, -8.698080030668848],
              [-35.881246851161222, -8.69695816688585],
              [-35.878042767259842, -8.693764008801907],
              [-35.876547815229458, -8.692108233707984],
              [-35.875901200879696, -8.691222717154631],
              [-35.875389308569709, -8.690242523573545],
              [-35.875167656262306, -8.689609632878216],
              [-35.874946462609103, -8.688978051595768],
              [-35.874735817104238, -8.687863789782],
              [-35.874637713659524, -8.684979433753824],
              [-35.874947752236949, -8.682145714952753],
              [-35.874955670767577, -8.682119236213737],
              [-35.876019817860254, -8.678561017277104],
              [-35.879333601964106, -8.669044494807565],
              [-35.879338040118398, -8.667398947703248],
              [-35.879863362004528, -8.663743608280868],
              [-35.879839420253006, -8.662059557602447],
              [-35.879121193788023, -8.657994569793516],
              [-35.877461177491284, -8.65370759423007],
              [-35.877364346587285, -8.652640261738668],
              [-35.877569660011773, -8.651570317616006],
              [-35.878385901787908, -8.65026480777459],
              [-35.879561165515327, -8.649275817924604],
              [-35.88141016085018, -8.648321977177432],
              [-35.88384259765769, -8.647302835757216],
              [-35.886004930283264, -8.645964912550653],
              [-35.887420273426812, -8.644592346559477],
              [-35.888566793023017, -8.64299737283045],
              [-35.889190306487421, -8.641266220159878],
              [-35.889399300836999, -8.639597896825421],
              [-35.889356988634063, -8.63789481379456],
              [-35.888675412240445, -8.632341473180418],
              [-35.888687491934149, -8.631434289260994],
              [-35.888900364160406, -8.630421995278823],
              [-35.889649672094663, -8.628501067670072],
              [-35.892400450920036, -8.622870627277564],
              [-35.893325445013261, -8.620339475486333],
              [-35.894412138109658, -8.617943467516966],
              [-35.897485716892277, -8.611869568725581],
              [-35.898064885685869, -8.610096567902247],
              [-35.898223482028015, -8.608433558661773],
              [-35.898091015487921, -8.607323536252125],
              [-35.897519418086631, -8.605932288644169],
              [-35.896337831225878, -8.604448614112187],
              [-35.895208060603466, -8.603571016988781],
              [-35.894402138547832, -8.603133723442511],
              [-35.893435020209949, -8.60284356168097],
              [-35.892364259502926, -8.602770392839318],
              [-35.889561704009395, -8.603075197362443],
              [-35.888341689117624, -8.603100410755303],
              [-35.887135105600578, -8.602840538160251],
              [-35.88619229683701, -8.602407739425587],
              [-35.884609429781335, -8.60108741595144],
              [-35.881338653056332, -8.597524091563514],
              [-35.878084212516342, -8.594651138938843],
              [-35.876396306444349, -8.593344802620493],
              [-35.874230798312624, -8.592104279588796],
              [-35.872823808072269, -8.591557777769196],
              [-35.871706200780523, -8.591361072655332],
              [-35.869987901220611, -8.591268356149669],
              [-35.868262005087445, -8.591333861868179],
              [-35.866193878244815, -8.591668479816462],
              [-35.859892511230385, -8.59346619221094],
              [-35.858212828559147, -8.593635905972235],
              [-35.856520505017272, -8.59355534919996],
              [-35.854155251163888, -8.592971295288976],
              [-35.852419179201718, -8.592281179832526],
              [-35.85150305152456, -8.591795057595789],
              [-35.849642123085459, -8.590642250839153],
              [-35.84658390112611, -8.58797963615126],
              [-35.84527585249856, -8.587189948657169],
              [-35.845116293881802, -8.58709362047415],
              [-35.84403213507494, -8.58682781325291],
              [-35.842375591060659, -8.586887952716779],
              [-35.841354563762081, -8.587220690182676],
              [-35.839805051661756, -8.588061529316064],
              [-35.839104688430609, -8.588441580114761],
              [-35.837030843698415, -8.589566948555785],
              [-35.83505187924078, -8.590928324282686],
              [-35.833929324778467, -8.591971854417523],
              [-35.832448147663854, -8.593645903735421],
              [-35.831735333318932, -8.594669216211287],
              [-35.829683944658257, -8.597614174854243],
              [-35.828972254465953, -8.598354302508994],
              [-35.828139100938699, -8.59899765681638],
              [-35.823464717222862, -8.601862424711101],
              [-35.82162505325357, -8.602866580728062],
              [-35.819853396916699, -8.60343567711476],
              [-35.818197487823738, -8.603542028241975],
              [-35.816609951488033, -8.603200682465456],
              [-35.815456564668693, -8.602675048514641],
              [-35.814694154511585, -8.602327596341468],
              [-35.813802566635104, -8.601784211572067],
              [-35.812487961050522, -8.600854791915083],
              [-35.809632067665746, -8.598449041589959],
              [-35.807925193093126, -8.597213534976618],
              [-35.803402976466145, -8.593266698299431],
              [-35.801607147252014, -8.592149960652137],
              [-35.79982068267833, -8.591526741266023],
              [-35.798705294913361, -8.591398044683956],
              [-35.797024471901807, -8.591459618566489],
              [-35.794041046557027, -8.591887263286537],
              [-35.792406377652185, -8.592270378974774],
              [-35.788363929551679, -8.594195181762188],
              [-35.786581938205813, -8.595328513699883],
              [-35.78313572083681, -8.597779276892084],
              [-35.781352387702498, -8.59869241252477],
              [-35.776803150045282, -8.600430112259801],
              [-35.775702817830734, -8.600640553618632],
              [-35.774009371934937, -8.600702262399466],
              [-35.772337117663824, -8.600489127571109],
              [-35.771065772167617, -8.600117163476284],
              [-35.761487713587087, -8.596374087993695],
              [-35.757948804893374, -8.595206624985735],
              [-35.752893199461539, -8.594036610601052],
              [-35.749332277162807, -8.592920924261193],
              [-35.74680370912381, -8.591728183109126],
              [-35.744681514140495, -8.590409776989588],
              [-35.741501309937462, -8.58807962367564],
              [-35.739597144792356, -8.587135639498115],
              [-35.738560381156617, -8.586812931271709],
              [-35.737444226269986, -8.586666568407338],
              [-35.736316104104901, -8.586669904892176],
              [-35.734659748948218, -8.586961294228274],
              [-35.732249138686768, -8.588017943673094],
              [-35.727960604598195, -8.590405400888471],
              [-35.726590724881987, -8.590848204580936],
              [-35.725147423614658, -8.590953129383365],
              [-35.723372880454008, -8.590587547777034],
              [-35.721651984551386, -8.589941222109644],
              [-35.717300219129754, -8.587573731642351],
              [-35.71391526987378, -8.586029441905168],
              [-35.714111699795069, -8.584364312962634],
              [-35.714613928889662, -8.582550127686204],
              [-35.715272719160623, -8.581206406550393],
              [-35.716406176104101, -8.579618681357033],
              [-35.717520634869345, -8.578511390188453],
              [-35.718811608968508, -8.577598043215062],
              [-35.720710068814583, -8.576671185726582],
              [-35.722232479845047, -8.5762138948553],
              [-35.723899878676434, -8.576167321664514],
              [-35.72499027068443, -8.576364747135301],
              [-35.726217008907781, -8.576827141659237],
              [-35.728748302791871, -8.578092041714285],
              [-35.730476547054934, -8.578767975761423],
              [-35.732276972704085, -8.57927506838962],
              [-35.733947095581925, -8.579453149443225],
              [-35.735626186499246, -8.579348067259446],
              [-35.738555561689793, -8.578740769470869],
              [-35.740693850940431, -8.578022987271027],
              [-35.743976733439467, -8.576664843707533],
              [-35.745242919525083, -8.576283355792214],
              [-35.746911663742253, -8.576064608294985],
              [-35.748602051043804, -8.576139441781777],
              [-35.749698733809453, -8.576379292770071],
              [-35.751614256492701, -8.57722910174158],
              [-35.75468794769057, -8.579238943522398],
              [-35.756997226840497, -8.58049688822075],
              [-35.759230645477885, -8.581290784542034],
              [-35.761020459126826, -8.581526074168949],
              [-35.762518985962402, -8.581248001993629],
              [-35.76452868025433, -8.580641685365435],
              [-35.76551344059277, -8.580230789619179],
              [-35.76781581025142, -8.578911988697257],
              [-35.770722000020214, -8.576647831232378],
              [-35.771647391613783, -8.576138285253592],
              [-35.773054240660656, -8.575591172794361],
              [-35.774555124619269, -8.575450643832085],
              [-35.776460578492845, -8.575815209204507],
              [-35.778065060753931, -8.575914007825133],
              [-35.779143595200708, -8.575794493321155],
              [-35.780361860011794, -8.57540427029079],
              [-35.780841981971832, -8.575156339793182],
              [-35.78163413270903, -8.574496269786948],
              [-35.782212412073136, -8.573609612893279],
              [-35.782515431441936, -8.572766528152727],
              [-35.782633062765569, -8.571300533392844],
              [-35.782379322647557, -8.570227349912216],
              [-35.781860786146297, -8.569271145746711],
              [-35.779799393427197, -8.566732245396736],
              [-35.779199043800816, -8.565834547526322],
              [-35.778442118608837, -8.564103002751324],
              [-35.777360003061631, -8.560190711731934],
              [-35.776265099154955, -8.557209174963418],
              [-35.775427014644947, -8.555785658781076],
              [-35.773583477295574, -8.553669252733755],
              [-35.773215029549931, -8.553323167530095],
              [-35.771784794532607, -8.554676732645021],
              [-35.764396059030844, -8.553542186622565],
              [-35.760483732038551, -8.550510101659825],
              [-35.756062797060395, -8.549375543946155],
              [-35.747983833686931, -8.542176798320554],
              [-35.743562900507413, -8.541042241506204],
              [-35.739650579810359, -8.538010161939383],
              [-35.729994249634842, -8.536734407966492],
              [-35.728539571044337, -8.531065757178908],
              [-35.721340867686877, -8.522986759631181],
              [-35.720206256913173, -8.5102324178917],
              [-35.717174191735523, -8.506320076510292],
              [-35.715639155122858, -8.500338795299115],
              [-35.710484106256445, -8.496343526532485],
              [-35.701896565474101, -8.495209005691038],
              [-35.697984245676366, -8.492176908137765],
              [-35.695773780435559, -8.491609630180221],
              [-35.69334936288277, -8.491962980107417],
              [-35.689864998970506, -8.495423063223768],
              [-35.68327396751954, -8.495776443727834],
              [-35.682920688638831, -8.506534290525053],
              [-35.678754085532603, -8.510730321247308],
              [-35.679460747615394, -8.519034324674294],
              [-35.682920729108332, -8.522518733552658],
              [-35.683627383097189, -8.527367637906138],
              [-35.687794010485163, -8.53156361376972],
              [-35.68708622514913, -8.548228267120805],
              [-35.677365322751427, -8.557923249258522],
              [-35.672484090468345, -8.558634672352184],
              [-35.666621906475768, -8.564526284860108],
              [-35.670420976446849, -8.56835215822548],
              [-35.670774311984871, -8.574943275111991],
              [-35.6752055900659, -8.576080379705729],
              [-35.67134160613017, -8.581066119505749],
              [-35.670774329072003, -8.583276612625525],
              [-35.6707743380652, -8.587443279583624],
              [-35.671341633109819, -8.59382043696354],
              [-35.674373702784123, -8.597732781942284],
              [-35.67564951701155, -8.607389222734412],
              [-35.68287839082501, -8.609244339754412],
              [-35.686873626316753, -8.614399432687605],
              [-35.687440926757347, -8.62077659546344],
              [-35.686226011021574, -8.625510555667574],
              [-35.672730573956756, -8.624375996155266],
              [-35.668682275766344, -8.621238455790035],
              [-35.660484939128594, -8.628542634335076],
              [-35.647730618073524, -8.629677140787351],
              [-35.643818265000903, -8.632709213159615],
              [-35.638292036248458, -8.634127403556761],
              [-35.636590178094195, -8.640758869426236],
              [-35.629958701432884, -8.642460700600793],
              [-35.628540486753991, -8.647986925755959],
              [-35.625508394596693, -8.651899266238104],
              [-35.624941107645952, -8.654109756659921],
              [-35.620774435291935, -8.65410973597551],
              [-35.613212506551918, -8.654881117769321],
              [-35.612441091483163, -8.6624430402141],
              [-35.611590161057052, -8.665758776746088],
              [-35.608274420927728, -8.666609694581666],
              [-35.608632479705136, -8.669066536291552],
              [-35.614893298986487, -8.675296401807429],
              [-35.619032173296091, -8.674589768502983],
              [-35.623228207615625, -8.670423111437401],
              [-35.631532222733824, -8.671129799600521],
              [-35.635016635209524, -8.674589838650093],
              [-35.639865544958866, -8.67529651242404],
              [-35.643349956535246, -8.678756548775652],
              [-35.648198868083227, -8.679463224348297],
              [-35.655849934027174, -8.687089952839017],
              [-35.664892986821826, -8.687798849737078],
              [-35.682920890986281, -8.705852271265258],
              [-35.683627513498891, -8.710701188209214],
              [-35.687087494092509, -8.714185632161161],
              [-35.687794117504495, -8.719034548205798],
              [-35.691254099896753, -8.722518992157688],
              [-35.691607314925591, -8.745776833533967],
              [-35.69895439293083, -8.74649402038267],
              [-35.700912228716334, -8.753342019618458],
              [-35.710434018515116, -8.754727889280616],
              [-35.714447056986046, -8.757659293341646],
              [-35.718767312861132, -8.758894594909577],
              [-35.722780353130759, -8.761825999869927],
              [-35.732538657433054, -8.763246292882059],
              [-35.733273768669449, -8.770777041227404],
              [-35.749027076038658, -8.77138456834308],
              [-35.750791313867978, -8.778259534649749],
              [-35.757612484528522, -8.780010088394818],
              [-35.758273684982555, -8.787443864642569],
              [-35.760698134910967, -8.787797201979231],
              [-35.776682530274513, -8.803757352519767],
              [-35.779106979303606, -8.804110690755749],
              [-35.779460295056538, -8.819035210806305],
              [-35.782920309824419, -8.822519673643967],
              [-35.783273633671229, -8.824944139760191],
              [-35.76322035643426, -8.825629670472892],
              [-35.761797521938036, -8.839579127574609],
              [-35.749297447319293, -8.85339694641192],
              [-35.750582902279064, -8.856245606549464],
              [-35.753464087297743, -8.860309215697725],
              [-35.754106790495939, -8.866610775167089],
              [-35.756317280917756, -8.867178076507003],
              [-35.761703450154073, -8.871669964397313],
              [-35.763007362799499, -8.879857517310541],
              [-35.758840666163792, -8.885233687884352],
              [-35.757706067980621, -8.889654671425887],
              [-35.75371077852958, -8.894809760761802],
              [-35.746511998729716, -8.896657057277309],
              [-35.745205974476107, -8.904857481287706],
              [-35.750335787466554, -8.911476448379346],
              [-35.754106576457275, -8.912444217826362],
              [-35.754106575492024, -8.912667824418875],
              [-35.754535754, -8.912252498999983],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID: '220',
        AcaoPriori: 1.1,
        ImportBio_: 'Alta',
        Prioridade: 'Alta',
        COD_area: 'MA264',
        Shape_Leng: 0.86109,
        Area_ha: 26707.86775,
        Shape_Area: 0.02194,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.471673927609629, -8.629770164861156],
              [-35.469923383757134, -8.636591385883719],
              [-35.463291912491741, -8.638293237742687],
              [-35.461473209920655, -8.64537979654034],
              [-35.454854268010024, -8.650509645503689],
              [-35.451897120855392, -8.649375054515133],
              [-35.443699794110159, -8.642070772548095],
              [-35.439651454550926, -8.645208328201761],
              [-35.416607639231131, -8.645775557595869],
              [-35.416040274938666, -8.660486164005192],
              [-35.413008163895597, -8.66439854225888],
              [-35.411553439439615, -8.670067208334956],
              [-35.401896978862453, -8.671342998280693],
              [-35.393817856308999, -8.678541758295466],
              [-35.391607356893985, -8.679109043447568],
              [-35.384109870873772, -8.678344223107388],
              [-35.382355271078836, -8.666828383622942],
              [-35.376190744906921, -8.662457534587531],
              [-35.373492729305951, -8.671418394743057],
              [-35.368639093619208, -8.670132913702957],
              [-35.35890796413122, -8.661799501545715],
              [-35.353729452851951, -8.664203961343674],
              [-35.350583554187608, -8.668640569706497],
              [-35.349169189506426, -8.682504221592524],
              [-35.334109745068645, -8.684040417431277],
              [-35.332437879304621, -8.695010818994035],
              [-35.318638580206255, -8.696418249002932],
              [-35.314575266035661, -8.699299421431135],
              [-35.304933328610161, -8.70076859979099],
              [-35.303308770783076, -8.711427899777732],
              [-35.299939934680197, -8.712442079638208],
              [-35.300563331232695, -8.724032866823279],
              [-35.307422165500498, -8.725793106266792],
              [-35.308840304636306, -8.731319356602967],
              [-35.313006908641853, -8.736695578438173],
              [-35.311872215130563, -8.743819411436618],
              [-35.308840046530861, -8.747731755516043],
              [-35.307705392589753, -8.752152741755594],
              [-35.30467322578869, -8.756065089432298],
              [-35.3032549121844, -8.761591321782021],
              [-35.296168323709139, -8.763409965897154],
              [-35.292172964110989, -8.768565057931028],
              [-35.291038311069144, -8.772986045969219],
              [-35.287907637027786, -8.777025553286592],
              [-35.291038185164098, -8.781065093878908],
              [-35.291605440638534, -8.783275595991938],
              [-35.291605375887343, -8.787442275540513],
              [-35.295772062630533, -8.787442295325604],
              [-35.296623050613277, -8.784126554297018],
              [-35.302149327029781, -8.782708357604577],
              [-35.306061747551553, -8.779676278937075],
              [-35.335482896518442, -8.778541844430549],
              [-35.339395317040214, -8.775509758568433],
              [-35.34254306471064, -8.774375187364967],
              [-35.351895258559296, -8.782708561750667],
              [-35.355042974753417, -8.783843147343305],
              [-35.364513764898277, -8.775404365219231],
              [-35.368562062189369, -8.778541927168135],
              [-35.374939238455056, -8.779109233004647],
              [-35.375292600972728, -8.776684776780996],
              [-35.378752680491743, -8.773200349916181],
              [-35.379459403728447, -8.768351435670183],
              [-35.384982677308642, -8.7628006284462],
              [-35.389863991429991, -8.762089239526745],
              [-35.397855665706345, -8.754123134774829],
              [-35.401681854733738, -8.75792259145436],
              [-35.4058191965986, -8.758629254436471],
              [-35.410015264193021, -8.754462587478372],
              [-35.412439730309245, -8.754109266329522],
              [-35.423197546529479, -8.754462619853996],
              [-35.42702306106537, -8.758261397545425],
              [-35.430848670929379, -8.754462636941071],
              [-35.435697598665229, -8.75375599464337],
              [-35.439182083086621, -8.750295979875546],
              [-35.445773226952838, -8.749942666820573],
              [-35.454106588748004, -8.749942686605664],
              [-35.456317092659674, -8.749375401453563],
              [-35.464396218810407, -8.742176643237428],
              [-35.472983903484305, -8.741042084624439],
              [-35.486536760463082, -8.728463580130096],
              [-35.488291256835964, -8.721626947425648],
              [-35.493817508970835, -8.720208736344034],
              [-35.497865873711078, -8.717071189683622],
              [-35.506181526955856, -8.724480909965962],
              [-35.510357054934104, -8.721244866857091],
              [-35.514396546963042, -8.724375485140456],
              [-35.517353698614272, -8.725510076129069],
              [-35.522729935737914, -8.721343420862127],
              [-35.527150938165221, -8.720208859551178],
              [-35.531063331707344, -8.717176776387078],
              [-35.547984373679867, -8.716042266337467],
              [-35.553139519673039, -8.712047018255248],
              [-35.554107302609907, -8.708276227465888],
              [-35.551947165229421, -8.707658574883283],
              [-35.547934117765294, -8.704727167224917],
              [-35.543613843903756, -8.703491860261067],
              [-35.537474583285359, -8.698581712975624],
              [-35.540989751675681, -8.693769601685744],
              [-35.542225085619179, -8.688442410888513],
              [-35.529842564982346, -8.686640118246828],
              [-35.529107497812731, -8.679109373498761],
              [-35.529107535584274, -8.674942695748769],
              [-35.52689703616926, -8.675509974605575],
              [-35.521911265792255, -8.679373998111487],
              [-35.520774184580887, -8.674942658876546],
              [-35.517458450746858, -8.674091709564721],
              [-35.516040281034122, -8.668565463725145],
              [-35.51300820326594, -8.664653081874178],
              [-35.511873632062418, -8.661454482937984],
              [-35.524373677902872, -8.647986405947847],
              [-35.525508261696871, -8.645029245303363],
              [-35.52134159923537, -8.639653024367533],
              [-35.520774323076523, -8.633275841806608],
              [-35.514397161200009, -8.632708517983644],
              [-35.509020985230222, -8.628541804260806],
              [-35.506063825485114, -8.629676368269713],
              [-35.502024304677889, -8.632806955976207],
              [-35.497984803655697, -8.629676328699588],
              [-35.493563830906055, -8.628541732315057],
              [-35.489524332581823, -8.625411108635717],
              [-35.485484817170516, -8.628541704436088],
              [-35.471673927609629, -8.629770164861156],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID: '230',
        AcaoPriori: 12.3,
        ImportBio_: 'Muito Alta',
        Prioridade: 'Alta',
        COD_area: 'MA261',
        Shape_Leng: 1.65846,
        Area_ha: 64014.43534,
        Shape_Area: 0.05268,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.353916480999899, -9.201193640999962],
              [-36.354827106999899, -9.203302459999971],
              [-36.354420601, -9.205211474999942],
              [-36.353782943515213, -9.205955547248953],
              [-36.354103202800502, -9.216617233398551],
              [-36.352278199849131, -9.217166866373802],
              [-36.352834791, -9.219509677999961],
              [-36.353602464999902, -9.220121747999936],
              [-36.353604041, -9.220121173999921],
              [-36.354724967, -9.219712471999969],
              [-36.355929998, -9.218385842999957],
              [-36.355932722, -9.218385890999976],
              [-36.358345856, -9.21828818399996],
              [-36.360213816999902, -9.221555325999935],
              [-36.360217401999897, -9.221555756999987],
              [-36.365703956999901, -9.222627319999956],
              [-36.369692334, -9.221105953999942],
              [-36.370890749, -9.22044150799996],
              [-36.374078528, -9.216991278999963],
              [-36.3740812099999, -9.21699112899995],
              [-36.375873172999903, -9.216700211999985],
              [-36.380341627, -9.219711934999967],
              [-36.380345226, -9.219712319999935],
              [-36.382258518999897, -9.220034232999982],
              [-36.385844757, -9.220637261999975],
              [-36.3873836349999, -9.223557452999962],
              [-36.390039514, -9.224875686999948],
              [-36.390042222, -9.224875943999965],
              [-36.391092367999903, -9.225028031999946],
              [-36.391734407999898, -9.225121015999951],
              [-36.391737103, -9.225121107999938],
              [-36.396526735027962, -9.224759780688204],
              [-36.396479398805184, -9.224633243329492],
              [-36.399579923074953, -9.223060106938362],
              [-36.398324339096916, -9.22188533334139],
              [-36.40099423289746, -9.218196955525059],
              [-36.402405701761666, -9.218829730207858],
              [-36.403240218661097, -9.218792278840567],
              [-36.404019664675729, -9.218552703944113],
              [-36.404283071606415, -9.218420794983672],
              [-36.404564241946161, -9.218186352518103],
              [-36.404879754696879, -9.2178180414694],
              [-36.405080498666052, -9.217537107651367],
              [-36.405276406081214, -9.217253646738357],
              [-36.405484669282032, -9.216997349847759],
              [-36.405669451184167, -9.216771748617248],
              [-36.405731525989097, -9.216509199639802],
              [-36.405708385533501, -9.216192283946725],
              [-36.405518864204168, -9.215784470177084],
              [-36.405208679936607, -9.215423607313596],
              [-36.4049131690075, -9.215102767878363],
              [-36.404474819657139, -9.214869486437578],
              [-36.403994809213032, -9.21469866651023],
              [-36.403145181005243, -9.214415577916554],
              [-36.403140150197714, -9.214399337959037],
              [-36.403113267663116, -9.21439038161077],
              [-36.402972220691368, -9.213935102522896],
              [-36.403483515851519, -9.213486109696134],
              [-36.403623598750016, -9.211743084175964],
              [-36.403657507687797, -9.211669641940375],
              [-36.404273073843228, -9.210507749333146],
              [-36.40428844595499, -9.210480054710729],
              [-36.404711571581743, -9.209717703908836],
              [-36.405863549163428, -9.208885615178929],
              [-36.405900203731392, -9.20852836848934],
              [-36.405977035511739, -9.207779547987684],
              [-36.406001113060881, -9.207544890584188],
              [-36.406229756697883, -9.207291718837723],
              [-36.406307360995868, -9.207203544807726],
              [-36.406734340217611, -9.206705084771784],
              [-36.406748047684232, -9.206072636542899],
              [-36.407064160282744, -9.205126094693469],
              [-36.407444125645839, -9.204152897837901],
              [-36.407502818100511, -9.204073529969378],
              [-36.407656449285639, -9.204044813717132],
              [-36.407799589879119, -9.204070329282217],
              [-36.408139305184761, -9.203994079363213],
              [-36.408499899150968, -9.204124231947674],
              [-36.412163898145366, -9.201764923512371],
              [-36.414636202798476, -9.197721280212875],
              [-36.417764268805911, -9.192755541335146],
              [-36.417779988055941, -9.192738220392528],
              [-36.417800312734187, -9.190930849279198],
              [-36.417807541484819, -9.189933897731123],
              [-36.417977716598273, -9.189361300283736],
              [-36.417651091824382, -9.18896146979597],
              [-36.421469691473021, -9.183703741734234],
              [-36.427820165068511, -9.186415238172685],
              [-36.428535471434827, -9.185492225285941],
              [-36.42855165743299, -9.185429156730152],
              [-36.428442856552522, -9.184617440342549],
              [-36.428770634257319, -9.184575877274881],
              [-36.429670056128145, -9.181071109544007],
              [-36.434229357199058, -9.175603810667269],
              [-36.44797976195639, -9.174380379251033],
              [-36.453469829868709, -9.169801808406703],
              [-36.45466984293887, -9.151903206967404],
              [-36.457701900921961, -9.147990711801867],
              [-36.459119937535036, -9.142464186273116],
              [-36.464646276004771, -9.141045985983396],
              [-36.470755832915302, -9.135950681778468],
              [-36.467169717908121, -9.131323538550419],
              [-36.466602438151938, -9.1207795433412],
              [-36.460275526794078, -9.12016219472946],
              [-36.456262842656031, -9.117230505583336],
              [-36.439442395250296, -9.1159958937954],
              [-36.435352739037626, -9.11300825063023],
              [-36.431263084623595, -9.115996065565923],
              [-36.428633512826309, -9.117231089243319],
              [-36.422929829847703, -9.113064459157442],
              [-36.414442632721261, -9.111829666605786],
              [-36.410429946784575, -9.108897966667769],
              [-36.395300489226145, -9.107663305417191],
              [-36.389596812542834, -9.111830182816618],
              [-36.378633988667616, -9.113065383660455],
              [-36.372930301192412, -9.108898738286086],
              [-36.359072737716644, -9.107546384557963],
              [-36.357652646152644, -9.097787672862751],
              [-36.354720943516668, -9.09377491138298],
              [-36.354103463603906, -9.087447795879029],
              [-36.349936835316669, -9.087447876818032],
              [-36.330845869541179, -9.087801507333666],
              [-36.327361456166216, -9.091261590450017],
              [-36.324937010734402, -9.091614910699548],
              [-36.325290364258876, -9.102372967938095],
              [-36.328750379026701, -9.10585751081544],
              [-36.329103764926742, -9.129115887288322],
              [-36.329103774819316, -9.133282661265753],
              [-36.329457105860683, -9.135707177743996],
              [-36.332917121527885, -9.139191730513915],
              [-36.33362379260393, -9.148207539246471],
              [-36.341589836201933, -9.156199094812337],
              [-36.337083802875156, -9.160736940757147],
              [-36.337790323764409, -9.181541471161893],
              [-36.341250271982403, -9.185025875543658],
              [-36.341956871112643, -9.189874836554395],
              [-36.345416822927973, -9.19335924543276],
              [-36.345770121593773, -9.195783728636115],
              [-36.349936751679707, -9.195783641401874],
              [-36.353504895534456, -9.196699108976645],
              [-36.353640468519394, -9.201212441398816],
              [-36.353916480999899, -9.201193640999962],
            ],
          ],
          [
            [
              [-36.461696692, -9.238422622999959],
              [-36.462517278999897, -9.239251109999936],
              [-36.462519947, -9.23925148399996],
              [-36.466681247273108, -9.240239077601332],
              [-36.463002955621846, -9.235492919462615],
              [-36.461584926203329, -9.229966387638626],
              [-36.461244441078065, -9.229879031991686],
              [-36.461967650686461, -9.235496876479601],
              [-36.461155665218421, -9.237264706348348],
              [-36.461696692, -9.238422622999959],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID: '254',
        AcaoPriori: 11.0,
        ImportBio_: 'Muito Alta',
        Prioridade: 'Alta',
        COD_area: 'MA267',
        Shape_Leng: 2.47941,
        Area_ha: 65931.64153,
        Shape_Area: 0.05407,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.088942744511996, -7.889314289922481],
              [-35.088771233005957, -7.889324149190088],
              [-35.088470062643239, -7.889260032924142],
              [-35.08818954970792, -7.88918918793064],
              [-35.088024725560615, -7.889170101618845],
              [-35.087855281595921, -7.889180888087481],
              [-35.087648358384968, -7.889221256855478],
              [-35.087437095045857, -7.889295923068346],
              [-35.086405012381363, -7.889814500039108],
              [-35.085150242289785, -7.890519898574439],
              [-35.084713884937798, -7.890781027522337],
              [-35.084471823415868, -7.89093719659428],
              [-35.084337717411699, -7.89105226035349],
              [-35.084255061621775, -7.891156340692532],
              [-35.084153831234289, -7.891419773703547],
              [-35.084090077395103, -7.891557784564043],
              [-35.083951693315953, -7.891729707959598],
              [-35.083237245802195, -7.892434827705074],
              [-35.082411476608343, -7.893252023661944],
              [-35.081776780973598, -7.893867185122474],
              [-35.081198900009213, -7.894415492780695],
              [-35.080998121865775, -7.894612875983398],
              [-35.080153164736714, -7.8954445735082],
              [-35.079045932024087, -7.896521178909211],
              [-35.0788069713646, -7.896742582104423],
              [-35.0779521828469, -7.897593888447147],
              [-35.078916600520643, -7.898156651807994],
              [-35.082381032758406, -7.898830720661181],
              [-35.084134979645569, -7.899171971109524],
              [-35.087297504975822, -7.899784022714414],
              [-35.087313109112642, -7.899812516834118],
              [-35.087324307470794, -7.899814684200294],
              [-35.0875140680198, -7.900161195682585],
              [-35.08777383359552, -7.900671746204068],
              [-35.089374407402943, -7.901510950370039],
              [-35.089387811798019, -7.901534579157442],
              [-35.089401208099218, -7.901541602862608],
              [-35.090836251791529, -7.904071296852806],
              [-35.092357750010137, -7.905764239128303],
              [-35.092358676311846, -7.905766091731721],
              [-35.092384545310495, -7.905794877231756],
              [-35.093466987311558, -7.907957247628815],
              [-35.09455405272729, -7.910464183375325],
              [-35.09485521679477, -7.911651187752057],
              [-35.094651646256409, -7.911743308906523],
              [-35.094058574843302, -7.91175858209283],
              [-35.093694693855014, -7.911889514389543],
              [-35.092905256372433, -7.91217628121035],
              [-35.092337853409163, -7.912382013718911],
              [-35.091700976019183, -7.912882151889733],
              [-35.090510189993211, -7.915325129650967],
              [-35.089832754977635, -7.917052258754666],
              [-35.089460250390175, -7.918464345453117],
              [-35.089134059988851, -7.919355190489625],
              [-35.087894730357505, -7.92085030080068],
              [-35.087022637984319, -7.922007803817053],
              [-35.085762855071664, -7.922887694211681],
              [-35.084185613275395, -7.923489622148281],
              [-35.083452036282381, -7.924262048055027],
              [-35.082301101914254, -7.925840262917745],
              [-35.081459992084945, -7.927089092987217],
              [-35.080389636072823, -7.930728583657185],
              [-35.080116404948114, -7.932427576571342],
              [-35.080165929713814, -7.935292714087439],
              [-35.079721881359717, -7.937775935610887],
              [-35.0777808125344, -7.943214828501482],
              [-35.075725698881854, -7.947542126967562],
              [-35.074861606877619, -7.950306999068687],
              [-35.074690861593922, -7.951512565452504],
              [-35.074783589790798, -7.955984910175403],
              [-35.075123945413679, -7.958748273214212],
              [-35.075242623548206, -7.95926713706865],
              [-35.075626269836448, -7.959941367799843],
              [-35.076198051598737, -7.960554619999641],
              [-35.076197327644536, -7.960555749548178],
              [-35.076224872979481, -7.960585291378038],
              [-35.075729985950034, -7.961357557205474],
              [-35.075159570257938, -7.962264300457036],
              [-35.074010565834897, -7.962454509767781],
              [-35.072583072850477, -7.962590767849633],
              [-35.071792906017777, -7.963402306171474],
              [-35.070636450711561, -7.964346757996452],
              [-35.070315153521449, -7.96539534322045],
              [-35.069269623294417, -7.966957112180921],
              [-35.068259058708577, -7.966904391224602],
              [-35.067468334296166, -7.967316599081755],
              [-35.066845662597188, -7.967561935035235],
              [-35.066032135874309, -7.967550956111666],
              [-35.065472282719611, -7.967577968148646],
              [-35.065059600919824, -7.967822256391969],
              [-35.064501324276648, -7.96816416424474],
              [-35.063966055888727, -7.968239498653872],
              [-35.063159796587286, -7.968027051806928],
              [-35.063134304404571, -7.967996661017025],
              [-35.06313297340796, -7.967996309382102],
              [-35.062988236517867, -7.967823753763184],
              [-35.062966784989101, -7.967835408077576],
              [-35.06286117130594, -7.967826367193027],
              [-35.062755270739103, -7.967759921683012],
              [-35.062746344068501, -7.967740404595929],
              [-35.062728447559778, -7.967729177459546],
              [-35.062641115295207, -7.967538263979634],
              [-35.062640606278933, -7.967436919378258],
              [-35.062108152768985, -7.967087560642369],
              [-35.06210547998387, -7.967072658876077],
              [-35.06208132869034, -7.967056812821625],
              [-35.06186334741551, -7.965841699235],
              [-35.061693170503361, -7.964856118714863],
              [-35.06157066395491, -7.964587280778346],
              [-35.061483189597482, -7.964367665435361],
              [-35.061462799268725, -7.964128583367369],
              [-35.061463003414815, -7.964122778243564],
              [-35.061156101672736, -7.963498257538504],
              [-35.060699183120789, -7.962890488505195],
              [-35.060622207448887, -7.962372812655133],
              [-35.060473830103149, -7.9621147881669],
              [-35.060279599923547, -7.961656519732571],
              [-35.060257535956453, -7.961590085913713],
              [-35.060114982620291, -7.961317846741167],
              [-35.058670497845128, -7.960139009107934],
              [-35.058490856467813, -7.959967055135451],
              [-35.058330489360571, -7.959974956578947],
              [-35.058090236375278, -7.95990917566786],
              [-35.057897919953007, -7.959833590347841],
              [-35.057705172755448, -7.959671901237186],
              [-35.057697863065869, -7.95965750309125],
              [-35.057678343280884, -7.959641129134752],
              [-35.057600808230688, -7.95948843504317],
              [-35.057617100348864, -7.959317410070355],
              [-35.05637116339841, -7.959210401538769],
              [-35.056244609001453, -7.95930600756617],
              [-35.056033815108435, -7.959374022393149],
              [-35.055832473989426, -7.959413288592373],
              [-35.055640682771241, -7.959442940139525],
              [-35.05540076343442, -7.959444127244637],
              [-35.055179752343633, -7.959387817094012],
              [-35.054987387357983, -7.959302662088135],
              [-35.054727370871433, -7.959122169052819],
              [-35.054467496477741, -7.958970378779895],
              [-35.054208097825438, -7.958914255688285],
              [-35.053901098956487, -7.958934909518405],
              [-35.053603359507406, -7.958888544970307],
              [-35.053468624877098, -7.958812672766612],
              [-35.053391421676736, -7.958726947590492],
              [-35.053391314657404, -7.958725826135947],
              [-35.053364591302795, -7.958696153904327],
              [-35.053354470332522, -7.958590962003257],
              [-35.053401977918895, -7.958495054702951],
              [-35.053545405396108, -7.958389103774095],
              [-35.053736958293939, -7.958311619985238],
              [-35.053875461982955, -7.958163962996423],
              [-35.053819360475075, -7.958138047233035],
              [-35.05101079751023, -7.957188325380855],
              [-35.049285531801786, -7.956501938513611],
              [-35.048371173091141, -7.956435019060848],
              [-35.046998261760336, -7.956366995240614],
              [-35.046452791463139, -7.956385136364929],
              [-35.045344834796254, -7.95604990148172],
              [-35.044368059538272, -7.955609189612346],
              [-35.043919376078293, -7.955427949240402],
              [-35.042664188701281, -7.955259481939493],
              [-35.041810448793115, -7.955295144555237],
              [-35.040640135833542, -7.955269426642701],
              [-35.03998952939321, -7.95537266521626],
              [-35.039332535967731, -7.955844456756211],
              [-35.038705517845301, -7.956425060867844],
              [-35.038199056541202, -7.95676459181243],
              [-35.037707382789449, -7.956981486706184],
              [-35.037279571694853, -7.957657679758086],
              [-35.036635278495282, -7.958151089201351],
              [-35.036280371841656, -7.957999623583703],
              [-35.036269829988612, -7.957975694422714],
              [-35.036253533373838, -7.957968739965338],
              [-35.036010688542376, -7.957417460946999],
              [-35.035861146574632, -7.957296944598284],
              [-35.035507598796585, -7.957421239898281],
              [-35.035017209276759, -7.957898573060675],
              [-35.034973584962927, -7.958419678025678],
              [-35.034146958715155, -7.959015903361205],
              [-35.0333370760452, -7.959443042662258],
              [-35.033029457543364, -7.959790780819844],
              [-35.032528609807457, -7.960158700663442],
              [-35.032256813101412, -7.959929208965775],
              [-35.032255808558659, -7.959920113222609],
              [-35.032229976432234, -7.959898301065721],
              [-35.032166799957793, -7.959326012985059],
              [-35.032259289834315, -7.958776197163729],
              [-35.03210289773051, -7.958445318596546],
              [-35.031960768874342, -7.958099059823724],
              [-35.031870003897495, -7.957267588028742],
              [-35.031671507334124, -7.956973588859967],
              [-35.031250410577968, -7.957568313227],
              [-35.031033517482797, -7.957893107880579],
              [-35.03054543562331, -7.958037333056438],
              [-35.030179366584434, -7.958140443026991],
              [-35.029730612078026, -7.958000790004405],
              [-35.029404353328175, -7.957921334002322],
              [-35.028935851508322, -7.957903358353235],
              [-35.028122305899672, -7.958130227627862],
              [-35.027530296585894, -7.957849433204728],
              [-35.026998995107476, -7.957487283411353],
              [-35.026428363578077, -7.957409018111662],
              [-35.02608193932997, -7.957370180889086],
              [-35.025102285342143, -7.956949433969157],
              [-35.02471454084332, -7.956789213451373],
              [-35.024104060354034, -7.956893501533841],
              [-35.023495568265844, -7.957403048411891],
              [-35.021841237483102, -7.958249348228776],
              [-35.020175289258134, -7.959088250222521],
              [-35.01936294154649, -7.959558249413647],
              [-35.018894616893022, -7.959580790021391],
              [-35.018567579330295, -7.959339224925259],
              [-35.018302526640241, -7.959279718584071],
              [-35.017936530446491, -7.959403080387801],
              [-35.017654169404352, -7.959971825139576],
              [-35.017000962124655, -7.960977879625375],
              [-35.016545886283609, -7.961506561378656],
              [-35.016352898967057, -7.961730764163406],
              [-35.015786917831974, -7.962604835944376],
              [-35.015507234071549, -7.96413816564376],
              [-35.015681462429484, -7.966447329680477],
              [-35.015689642662835, -7.968267221155259],
              [-35.015631330621375, -7.968834875928735],
              [-35.015025091334849, -7.969810456887046],
              [-35.014538374646236, -7.970238356115203],
              [-35.014091095027993, -7.970402629877412],
              [-35.013418308608038, -7.970264056940607],
              [-35.012928813913561, -7.970124584681798],
              [-35.012479747342411, -7.969924126697038],
              [-35.011951410029496, -7.970169852056983],
              [-35.011700920061344, -7.97072743172555],
              [-35.011478564484094, -7.971072992624443],
              [-35.01044115603446, -7.971341439355911],
              [-35.009507344088604, -7.971974135797666],
              [-35.00879899307904, -7.972619922771173],
              [-35.008322259764441, -7.972759042495795],
              [-35.00800323606046, -7.973147796933233],
              [-35.007852891897414, -7.973876740619062],
              [-35.007772659780358, -7.974091296376173],
              [-35.007203274711287, -7.974710758394735],
              [-35.006457587447642, -7.975470783549781],
              [-35.006135553712795, -7.976564163105593],
              [-35.005731232010703, -7.979845298221903],
              [-35.00607901423507, -7.982149096903242],
              [-35.00595629994325, -7.98305352080331],
              [-35.005844759727836, -7.983997595812355],
              [-35.005654939823557, -7.984675442717446],
              [-35.004916396774263, -7.985067842107185],
              [-35.003994269719726, -7.985996708681114],
              [-35.003652531838839, -7.987788126720602],
              [-35.002868900379383, -7.989509547827311],
              [-35.001470799937408, -7.991776681148849],
              [-35.00090631977082, -7.992577168601429],
              [-35.000518124711618, -7.992852353952912],
              [-34.999855378322991, -7.992456338389275],
              [-34.998852606362675, -7.992269454771474],
              [-34.998002536587762, -7.99280076164581],
              [-34.997356234302742, -7.993846704661621],
              [-34.9967724924565, -7.994276300011165],
              [-34.996460622859388, -7.994920850416861],
              [-34.996063666604869, -7.99582549644947],
              [-34.994816802453443, -7.996803411148449],
              [-34.993989508009349, -7.997006745165095],
              [-34.993451094692205, -7.997408077520731],
              [-34.992889977090726, -7.997911186152294],
              [-34.991988949930033, -7.998295446677503],
              [-34.991756494966239, -7.998685555493921],
              [-34.991735985027617, -7.998643853930673],
              [-34.991729710457719, -7.998654381394545],
              [-34.990568636233547, -7.996293719479524],
              [-34.989961646912434, -7.995482650603776],
              [-34.988777470004209, -7.993303984491263],
              [-34.988183331995117, -7.991908029831677],
              [-34.987381533331074, -7.990447802424114],
              [-34.986077859005945, -7.988724377627875],
              [-34.985461274817908, -7.986009542006684],
              [-34.985127040880172, -7.984515729417353],
              [-34.982465155542457, -7.983685374580318],
              [-34.981449902790757, -7.98336866842925],
              [-34.979853966787118, -7.982692943024858],
              [-34.978977474930502, -7.982451555095167],
              [-34.978552833046763, -7.983960505972505],
              [-34.978457544480023, -7.984104476640255],
              [-34.978290275076063, -7.984248794446273],
              [-34.977202018957939, -7.984963037813884],
              [-34.97340849690454, -7.986998919464497],
              [-34.973121593386793, -7.987215560749462],
              [-34.971931735461169, -7.989278310545558],
              [-34.971837938869726, -7.989733227206614],
              [-34.971959637826956, -7.990091438868774],
              [-34.972268867014577, -7.990723887996978],
              [-34.974368841548767, -7.992655826005546],
              [-34.974389670746689, -7.99268152593163],
              [-34.974395659332174, -7.992687036077825],
              [-34.974781880379453, -7.99316357963545],
              [-34.974831368272987, -7.993474295402336],
              [-34.974833553625558, -7.993928756107152],
              [-34.974762490996113, -7.994120450198579],
              [-34.97466743085721, -7.994312259403216],
              [-34.974499925830855, -7.994408737772972],
              [-34.973015565116214, -7.995109488611945],
              [-34.97287297400851, -7.995397202418928],
              [-34.972879758494003, -7.996808418573607],
              [-34.973003762413668, -7.99764500661172],
              [-34.973028792344849, -7.997860163116002],
              [-34.97298183874085, -7.998075661362634],
              [-34.972074385924145, -7.99898893279294],
              [-34.971956250080837, -7.999372209459864],
              [-34.971957629640826, -7.999659237084074],
              [-34.972070357860673, -7.999881305777592],
              [-34.972173056841029, -7.999963236713938],
              [-34.972160960060194, -7.999963432766151],
              [-34.972199859336001, -7.999994467470628],
              [-34.972032347115089, -7.999997183423204],
              [-34.971624200596352, -7.999922500123262],
              [-34.971207550987629, -7.999679265885391],
              [-34.970945329363417, -7.999550243748729],
              [-34.970622038376348, -7.999521132694099],
              [-34.970061523320624, -7.999761364994924],
              [-34.969309506628406, -8.000178764638235],
              [-34.968708984332125, -8.00054292261774],
              [-34.968148024111997, -8.000691197440744],
              [-34.967701405495404, -8.000616692206506],
              [-34.967368802028545, -8.000250442403967],
              [-34.96736182328948, -8.000241036394641],
              [-34.967341991439696, -8.000219199056744],
              [-34.966918933262093, -7.999648950638516],
              [-34.966655343769048, -7.999493134100817],
              [-34.965854291542257, -7.999313027773951],
              [-34.965141910670582, -7.998823894607028],
              [-34.964763990365839, -7.998634110675596],
              [-34.964185330588521, -7.998299679786328],
              [-34.964168670647609, -7.998274300918183],
              [-34.964158512805056, -7.998268431043186],
              [-34.963763465811269, -7.997666668581815],
              [-34.963687839122429, -7.997580589972188],
              [-34.963249577905628, -7.997643976887957],
              [-34.963064613441134, -7.997724573230414],
              [-34.962911743082429, -7.997791184215714],
              [-34.962220719310722, -7.998093332141536],
              [-34.961194797901555, -7.998122491759545],
              [-34.960725392262987, -7.998109393133916],
              [-34.960302410527788, -7.998149716036494],
              [-34.960033488054989, -7.998227623406024],
              [-34.959573426372458, -7.99855932575224],
              [-34.959344876264993, -7.999035531164793],
              [-34.959175893652514, -7.99929314016623],
              [-34.959528087051524, -8.000115566580064],
              [-34.959685900084196, -8.000679824614167],
              [-34.95988071752106, -8.001863477217626],
              [-34.959519770121233, -8.002334409905075],
              [-34.958738494885608, -8.003095972001461],
              [-34.957937651301506, -8.004153875101224],
              [-34.957273916557938, -8.005091055907485],
              [-34.956442122805697, -8.006213904448089],
              [-34.955508045559839, -8.007050007751616],
              [-34.954802764835676, -8.007360428540892],
              [-34.954421352463385, -8.007377430224153],
              [-34.951779415887302, -8.011435792727582],
              [-34.950072447785203, -8.012287198895081],
              [-34.949681554759877, -8.013056770353614],
              [-34.949144709860434, -8.013660675899416],
              [-34.948157129248045, -8.014061988469962],
              [-34.947696591824183, -8.01449919658063],
              [-34.947455660750109, -8.015178472508467],
              [-34.947055039758254, -8.015843453712364],
              [-34.946315639654983, -8.015867399061165],
              [-34.945675350237195, -8.015134096361408],
              [-34.945063814843138, -8.01478541481498],
              [-34.943510525590227, -8.015560168167156],
              [-34.943492781966256, -8.015524278022951],
              [-34.943483620572579, -8.015528847478265],
              [-34.943112423599416, -8.01477801879048],
              [-34.942736152651264, -8.014779793152911],
              [-34.941556710673638, -8.014102079347424],
              [-34.94048476825742, -8.01330943847978],
              [-34.940483716050608, -8.013297223887719],
              [-34.940457870434329, -8.013278112394914],
              [-34.940392667787364, -8.012521637763314],
              [-34.940694183489711, -8.011150417157978],
              [-34.940452707426459, -8.010875621212904],
              [-34.93968610462997, -8.010336903924951],
              [-34.939680117843125, -8.010320263769131],
              [-34.939659217598773, -8.010305576940766],
              [-34.939409434497747, -8.009611331797487],
              [-34.939569566881971, -8.00877322120715],
              [-34.939266281313792, -8.00843669939411],
              [-34.938174495356634, -8.007787314233099],
              [-34.93775211256991, -8.007774639188199],
              [-34.937755838461158, -8.007744226814566],
              [-34.937725234531911, -8.007743307707415],
              [-34.938280299696032, -8.00321361591557],
              [-34.938722229247446, -8.000245470052334],
              [-34.93882226983186, -7.999895837023189],
              [-34.93838328825808, -7.999921495580509],
              [-34.9311228428449, -8.000567286151295],
              [-34.918877155856023, -8.011932711325528],
              [-34.892578463824975, -8.013114250522221],
              [-34.891099379432774, -8.018877155656185],
              [-34.888067287275419, -8.022789509628126],
              [-34.886932712474675, -8.039710488648097],
              [-34.882766045516519, -8.045086670013745],
              [-34.88390062031732, -8.056377155581231],
              [-34.886932712474675, -8.060289510452492],
              [-34.887500000324735, -8.074999999949966],
              [-34.890948561326979, -8.078915223658612],
              [-34.897989360820304, -8.078536442702614],
              [-34.901901748067189, -8.075504375726325],
              [-34.910489421049874, -8.074369841395026],
              [-34.918568522019655, -8.067171130842951],
              [-34.92822499248939, -8.065895375970797],
              [-34.929679718743955, -8.060226747666206],
              [-34.932711849572172, -8.056314414378676],
              [-34.934160130385692, -8.050671388873468],
              [-34.942549782546394, -8.049370028503972],
              [-34.953192893836956, -8.058853578230014],
              [-34.9546797312845, -8.064647848119648],
              [-34.958675122358954, -8.069802959039293],
              [-34.959613154823217, -8.070043705752369],
              [-34.959871204492458, -8.069870463051359],
              [-34.960094037609679, -8.070042813624923],
              [-34.960264219018427, -8.070210802486486],
              [-34.961810557411241, -8.07060767420478],
              [-34.961860237759709, -8.070494811985952],
              [-34.961979229757617, -8.070284946193283],
              [-34.962074363640909, -8.070105093475263],
              [-34.962223469437504, -8.06991900395883],
              [-34.962414632928926, -8.069744672178842],
              [-34.962635822085474, -8.069576175200268],
              [-34.962791389510983, -8.069485733080057],
              [-34.962851646786021, -8.069539261627597],
              [-34.963110854782087, -8.069789176029587],
              [-34.963502666015188, -8.070164031444847],
              [-34.964231973027097, -8.070848223067003],
              [-34.964230523319941, -8.070852711583314],
              [-34.964259102875246, -8.070879523970802],
              [-34.964152733761637, -8.071208796348799],
              [-34.96465622730301, -8.071338020832968],
              [-34.964961113664401, -8.07157430960666],
              [-34.965416939540091, -8.070438063865481],
              [-34.964230602460304, -8.069698273456481],
              [-34.964227694052795, -8.069682073968465],
              [-34.964203478907393, -8.069666972552682],
              [-34.964183747781703, -8.069557039425774],
              [-34.964154441574181, -8.069451932960305],
              [-34.964086479807179, -8.069294391522931],
              [-34.964008989723027, -8.069151244634213],
              [-34.963933416094221, -8.06905801641426],
              [-34.963804561231484, -8.068944459918839],
              [-34.963640902605505, -8.068849567053803],
              [-34.963482065245046, -8.068759435199752],
              [-34.963484596836622, -8.068744958812772],
              [-34.96345494528947, -8.068728132497256],
              [-34.963543699382171, -8.06822061269105],
              [-34.963601160665007, -8.068191633836761],
              [-34.963701844264676, -8.068167232531721],
              [-34.964550485016844, -8.067967023659151],
              [-34.966501449082557, -8.06940718988983],
              [-34.966500610015089, -8.069417845956764],
              [-34.966528569038189, -8.069438484498392],
              [-34.966519522757721, -8.069553341413553],
              [-34.966505560782934, -8.069644302442555],
              [-34.966525384538841, -8.069773373142596],
              [-34.966578878912117, -8.069916634245203],
              [-34.96665669365143, -8.070126751848079],
              [-34.966753571320226, -8.070308074957666],
              [-34.966838658876952, -8.070429778411494],
              [-34.966943669114983, -8.070529282999985],
              [-34.967064082041702, -8.070614815721115],
              [-34.967237180851157, -8.070676175564984],
              [-34.967429384858178, -8.070718307903519],
              [-34.967602276823584, -8.07073661180516],
              [-34.967837543967448, -8.070749835436516],
              [-34.968000764623525, -8.070753835620962],
              [-34.96815904710229, -8.070729154626747],
              [-34.968288437961007, -8.070685479051576],
              [-34.968412958990825, -8.07062747277962],
              [-34.968542163689847, -8.070545526554781],
              [-34.968695231000083, -8.070434761554964],
              [-34.968833988297888, -8.07034320157743],
              [-34.96899670083701, -8.070241957700148],
              [-34.969116307071886, -8.070160058240049],
              [-34.969250450847596, -8.070106788697274],
              [-34.969423015459768, -8.070058120985436],
              [-34.969581158543576, -8.070004739027411],
              [-34.96972483333434, -8.069937073137396],
              [-34.96982523904353, -8.069855264508817],
              [-34.969930421052027, -8.06976864990321],
              [-34.970054802686946, -8.069681941768124],
              [-34.970174360358385, -8.069590472622167],
              [-34.970327194744186, -8.069431870884102],
              [-34.970475322078414, -8.069292424705566],
              [-34.970585325352431, -8.069210568412927],
              [-34.970705115048986, -8.069166938703233],
              [-34.970839281307747, -8.069118455352395],
              [-34.971035709430396, -8.069040967966259],
              [-34.97117940490557, -8.068978085570166],
              [-34.971255903037445, -8.06891552872861],
              [-34.971308215701526, -8.068814815451333],
              [-34.971346428794618, -8.068776359541289],
              [-34.971404029472353, -8.06877608255013],
              [-34.971466565629441, -8.068804485838314],
              [-34.971487584584338, -8.068832999743108],
              [-34.971493679289836, -8.068835767856342],
              [-34.971532333949995, -8.068888204626887],
              [-34.97157112530715, -8.068969345059202],
              [-34.971648435526845, -8.069074217700916],
              [-34.971711194715795, -8.069160897057714],
              [-34.971814404511122, -8.069266847087249],
              [-34.971963966264013, -8.069423995520935],
              [-34.972142395355206, -8.069595357739502],
              [-34.972311273646312, -8.069776331912124],
              [-34.972320974633249, -8.069788948501127],
              [-34.972338390903985, -8.069807613030889],
              [-34.972444661092197, -8.069945833433394],
              [-34.972526908589941, -8.070079386354564],
              [-34.972614096063637, -8.070241619555077],
              [-34.972706198332332, -8.070427746842995],
              [-34.97275473024655, -8.070537541474323],
              [-34.972784018467621, -8.070637862647175],
              [-34.972789373930425, -8.070752649415169],
              [-34.972775436237328, -8.070848394837526],
              [-34.9727424419101, -8.070972934753115],
              [-34.972699635979382, -8.071054466390478],
              [-34.972632807358139, -8.071131330546393],
              [-34.972532310817428, -8.071194005199118],
              [-34.972431791793724, -8.071251896357865],
              [-34.972369671123374, -8.071309601356973],
              [-34.972322134758656, -8.071405507757902],
              [-34.972289045103309, -8.071510912798317],
              [-34.972294587625072, -8.071663971115356],
              [-34.972271468753263, -8.071845869790991],
              [-34.972252709794702, -8.07193685420242],
              [-34.972214727827406, -8.072023146850711],
              [-34.972157406838789, -8.072080829366712],
              [-34.972090347091807, -8.072109855885117],
              [-34.972013547687027, -8.072110225506435],
              [-34.971883788106311, -8.072077362480229],
              [-34.971648148643112, -8.071987604744095],
              [-34.971398157798717, -8.071907481445066],
              [-34.971138450678893, -8.071803485642306],
              [-34.970912458243333, -8.071723248129331],
              [-34.970696044486829, -8.071638179458375],
              [-34.970508547531381, -8.071576890660936],
              [-34.970383540867658, -8.071534438163724],
              [-34.97021067138536, -8.071520918655438],
              [-34.969999452112972, -8.07151714959673],
              [-34.969793124253215, -8.071532494728785],
              [-34.969620535359354, -8.071576380745341],
              [-34.969414623885768, -8.071677831466729],
              [-34.969261465744012, -8.071769462490636],
              [-34.969113199914204, -8.071880205906723],
              [-34.968955426451714, -8.072010130063404],
              [-34.968768667839697, -8.072101921166677],
              [-34.968553273015118, -8.072227337021957],
              [-34.968529920319497, -8.072361398959345],
              [-34.968357701047012, -8.072481823577277],
              [-34.968142629079011, -8.072674213743937],
              [-34.967941955257004, -8.072866535562127],
              [-34.967879973082233, -8.072952941524989],
              [-34.967832320704986, -8.073024929556823],
              [-34.96779445295158, -8.073135141473529],
              [-34.967780653754119, -8.073259588758958],
              [-34.967762357946413, -8.073446248445578],
              [-34.967749067765226, -8.073675939792736],
              [-34.967744557665185, -8.07373151339857],
              [-34.968568560590541, -8.074370125580799],
              [-34.975692384595845, -8.075504734555807],
              [-34.981068613625553, -8.071338100872595],
              [-34.997989638510944, -8.070203609708756],
              [-35.00190202395919, -8.067171558920279],
              [-35.006323006601406, -8.06603704707203],
              [-35.010371353355254, -8.062899565162752],
              [-35.018686973325089, -8.070309339404446],
              [-35.022735321877576, -8.067171854797209],
              [-35.024945814097975, -8.066604601121412],
              [-35.031322966081973, -8.066037403203552],
              [-35.035235339839005, -8.06300537040147],
              [-35.040761738563333, -8.061587229467023],
              [-35.042179839028279, -8.056061033989465],
              [-35.049378659297645, -8.047982120078757],
              [-35.050513275467267, -8.043561159919534],
              [-35.059290887964039, -8.03388416602138],
              [-35.084026036672185, -8.032704830163709],
              [-35.089402168575191, -8.036871569966934],
              [-35.099945971329475, -8.037439001708492],
              [-35.100663062850003, -8.044786117485273],
              [-35.107352825472447, -8.046698815397974],
              [-35.109491693886525, -8.054180398706364],
              [-35.116143205644391, -8.049321584723316],
              [-35.121052436520699, -8.051627935680642],
              [-35.124328280026077, -8.056112541760342],
              [-35.125872074236781, -8.061512988633467],
              [-35.132510635757171, -8.063411106737135],
              [-35.134073028847183, -8.074145354424502],
              [-35.14377250129985, -8.075557271151069],
              [-35.147785187236536, -8.078488713883019],
              [-35.15375168539191, -8.080194734998997],
              [-35.165994335370215, -8.09361316491561],
              [-35.167316241651179, -8.11161517849763],
              [-35.17278492998048, -8.115989048355857],
              [-35.181271827632713, -8.117224360715625],
              [-35.185284849915831, -8.120155762078696],
              [-35.202105055403933, -8.121391103216808],
              [-35.206118079485748, -8.12432250188192],
              [-35.210438329065596, -8.125557802550475],
              [-35.218617976938049, -8.132655889757473],
              [-35.227104883583479, -8.1338912048152],
              [-35.231117913061212, -8.136822606178271],
              [-35.23744469491669, -8.137440269552769],
              [-35.23779815276248, -8.118349123013559],
              [-35.241258180120894, -8.114864698846702],
              [-35.241964855693482, -8.110727358780537],
              [-35.237798233701483, -8.106531342447454],
              [-35.23744501327667, -8.091606866463678],
              [-35.237798469323877, -8.072515718125828],
              [-35.245425162741014, -8.064864634195487],
              [-35.245778502775636, -8.06244017797178],
              [-35.248202942811531, -8.062086858621569],
              [-35.251687375971585, -8.058626848350343],
              [-35.25411181510816, -8.058273529899452],
              [-35.253544544345175, -8.056063034981037],
              [-35.249549340329736, -8.050907916866834],
              [-35.243568113077913, -8.049372863167036],
              [-35.231322585269027, -8.038007386731465],
              [-35.225606693094392, -8.036540491750259],
              [-35.224378303715696, -8.022729568015109],
              [-35.221346264618376, -8.018817200553315],
              [-35.220211849896884, -7.999193345096728],
              [-35.224378524948918, -7.993817152039867],
              [-35.225513162702271, -7.985229469164608],
              [-35.23271193710616, -7.977150435644035],
              [-35.233279239345393, -7.97493993802766],
              [-35.233279277116878, -7.970773253982429],
              [-35.235489758545498, -7.970205971528344],
              [-35.24356879476403, -7.96300721421153],
              [-35.249094999234785, -7.961589178497718],
              [-35.250513266074336, -7.956062769880873],
              [-35.257712066558554, -7.947983748051513],
              [-35.258279504595407, -7.933273209990602],
              [-35.256069028562763, -7.932705909550066],
              [-35.250692911948306, -7.928539202122408],
              [-35.247735758498436, -7.929673767930012],
              [-35.2423595600456, -7.933840424995594],
              [-35.214402526255526, -7.932705714397173],
              [-35.209026397050536, -7.928539000674277],
              [-35.193569293088387, -7.929673508925248],
              [-35.188583564979524, -7.933537372351793],
              [-35.186478754790642, -7.925335547197676],
              [-35.181323696931031, -7.921340068889037],
              [-35.166613252399657, -7.920772701898613],
              [-35.166259991505342, -7.90584815037181],
              [-35.158633314276017, -7.898196954026162],
              [-35.158279997623765, -7.895772483413339],
              [-35.154113315377174, -7.89577245643369],
              [-35.153546033822352, -7.893561949824061],
              [-35.149379369562212, -7.888185713599739],
              [-35.15051397583926, -7.881061861715523],
              [-35.153546094976264, -7.877149504146246],
              [-35.154680692260115, -7.872728503517578],
              [-35.16281268775532, -7.863602040401815],
              [-35.156069628610453, -7.861871650356306],
              [-35.152157265645258, -7.858839524924065],
              [-35.135236195793766, -7.857704867385678],
              [-35.1271571469847, -7.850506046216992],
              [-35.123362365880439, -7.850168461405303],
              [-35.123355311598289, -7.850186772501502],
              [-35.1229891292449, -7.850858146086523],
              [-35.122576117393862, -7.851569928009724],
              [-35.122208934094999, -7.852176963196257],
              [-35.121675407693886, -7.852976387650131],
              [-35.121099276810128, -7.853754356078639],
              [-35.12063097284107, -7.854165127718431],
              [-35.119988093375753, -7.854603560705698],
              [-35.119391770913467, -7.854948024431735],
              [-35.118384877360143, -7.855584080740698],
              [-35.117512809268703, -7.855883065750845],
              [-35.116882005000491, -7.856053625774166],
              [-35.11651958001454, -7.856142487785519],
              [-35.116144024926768, -7.856291677219076],
              [-35.115728587604167, -7.856528100891069],
              [-35.115243433037961, -7.856843239523812],
              [-35.114580617401543, -7.857328635108388],
              [-35.11405868146295, -7.857766454758007],
              [-35.113723811704517, -7.857989083729194],
              [-35.113307997565983, -7.858151861019508],
              [-35.112832216633421, -7.858415367774967],
              [-35.112504539652718, -7.858731694412143],
              [-35.112502705935071, -7.858733314990445],
              [-35.112235850104355, -7.858969269216345],
              [-35.11192855985513, -7.859295107982803],
              [-35.111690119903074, -7.85977044465028],
              [-35.111063950740686, -7.861018730629894],
              [-35.11104272494174, -7.861079289177781],
              [-35.110967348264467, -7.861298944090947],
              [-35.110911155025747, -7.861494032223789],
              [-35.110848317595753, -7.861685120172126],
              [-35.110669233798035, -7.862196179709883],
              [-35.110404843908384, -7.862961781560898],
              [-35.110319487454319, -7.863250771505875],
              [-35.110255763292798, -7.863384466519847],
              [-35.110172358367606, -7.863503141057095],
              [-35.110013283586113, -7.863677941383855],
              [-35.109812987479359, -7.863879688996406],
              [-35.109364938940757, -7.864388636026661],
              [-35.109022154248976, -7.864757890464205],
              [-35.108723922870695, -7.865153801706526],
              [-35.108512128931579, -7.865524158712901],
              [-35.10841857155981, -7.86578958372229],
              [-35.108253703345667, -7.866315406530759],
              [-35.108065790903481, -7.867047162396602],
              [-35.107934234477284, -7.867617551109049],
              [-35.107868901428617, -7.867839867116118],
              [-35.107272837971038, -7.869238219368299],
              [-35.106559059552922, -7.870389857006273],
              [-35.10635808176005, -7.87074005660827],
              [-35.103956873910533, -7.874071859528669],
              [-35.103378975859016, -7.874467384062484],
              [-35.103097072571813, -7.874596564479816],
              [-35.102692126740237, -7.874760436245083],
              [-35.10249741272537, -7.874877329225342],
              [-35.10236098647033, -7.875005578844366],
              [-35.10222788051226, -7.875223120350654],
              [-35.101965050946319, -7.875979335977547],
              [-35.101810512345253, -7.876618871763469],
              [-35.101665470584976, -7.877283047174842],
              [-35.10150274635464, -7.878297561583167],
              [-35.101422645538605, -7.878707623657874],
              [-35.101265571748684, -7.879180589712405],
              [-35.101105567967522, -7.879583289936818],
              [-35.100520365622344, -7.881087470810087],
              [-35.100068117249748, -7.8817833536188],
              [-35.099261781505959, -7.882757977698475],
              [-35.099190976082639, -7.882835297810686],
              [-35.099027754527185, -7.883004525938077],
              [-35.098697590921063, -7.883414260659549],
              [-35.098320168840701, -7.883881103230294],
              [-35.09797233265698, -7.884366072536181],
              [-35.09761603385283, -7.884811156909336],
              [-35.097550238552628, -7.884920153841961],
              [-35.097362842321274, -7.885083578644185],
              [-35.097089496083356, -7.885330416465479],
              [-35.096953313544532, -7.885420069880297],
              [-35.096856277595066, -7.885483950523849],
              [-35.096581856667285, -7.885702207890517],
              [-35.096325477039102, -7.885922195552837],
              [-35.096104824777797, -7.886064186812746],
              [-35.095768482829158, -7.886236353025197],
              [-35.095032826603642, -7.886535948675032],
              [-35.094344654582073, -7.886816331208649],
              [-35.093512416564749, -7.88709278190629],
              [-35.092985096385064, -7.887272576168414],
              [-35.092271456462527, -7.887529704931865],
              [-35.091893305931308, -7.887660683093998],
              [-35.091309074853882, -7.887864496449311],
              [-35.091025663403627, -7.887961868745208],
              [-35.090595740700849, -7.888132929690926],
              [-35.090285597802051, -7.888313780656404],
              [-35.090071917984574, -7.888460613866187],
              [-35.089802710426682, -7.88869786671313],
              [-35.089463933113962, -7.888991034008939],
              [-35.089237580949543, -7.889181032878298],
              [-35.0890867358641, -7.889268634939526],
              [-35.088942744511996, -7.889314289922481],
            ],
            [
              [-35.194316823962197, -8.03256362491129],
              [-35.194129800949497, -8.032588537930621],
              [-35.19396651374359, -8.032565492803201],
              [-35.193836639948927, -8.032508787850247],
              [-35.19375480164274, -8.032461391779748],
              [-35.19365384824647, -8.032428447814596],
              [-35.193500056981975, -8.032386217449925],
              [-35.193413371329996, -8.032329283169815],
              [-35.193404541786151, -8.032311297628212],
              [-35.19338624058247, -8.032299276390461],
              [-35.193318314788371, -8.032160923787558],
              [-35.193269606607032, -8.032027252155899],
              [-35.193220106122965, -8.031957560992623],
              [-35.193161680766934, -8.031928829451886],
              [-35.192964726540879, -8.031891614606309],
              [-35.192739056062521, -8.031868902227984],
              [-35.192494164374125, -8.031841507079832],
              [-35.192306654828201, -8.031775541807747],
              [-35.192152502935642, -8.03166634792359],
              [-35.192060967239797, -8.031599870937271],
              [-35.191969687850758, -8.031581223494641],
              [-35.191873738282368, -8.031586518702852],
              [-35.191782767360792, -8.03162526869113],
              [-35.191639342581539, -8.031726481991484],
              [-35.191452907725477, -8.031861406378766],
              [-35.191271323812487, -8.032005871673618],
              [-35.191113604309351, -8.032126293593535],
              [-35.19097012736944, -8.032217939905934],
              [-35.190807277234001, -8.032276206081974],
              [-35.190644349756894, -8.032320123574777],
              [-35.190404565318374, -8.032350098877941],
              [-35.19009754486575, -8.032370867821271],
              [-35.189891402266369, -8.032410231147196],
              [-35.189723829790864, -8.032482872986179],
              [-35.189618818653514, -8.032588662936405],
              [-35.189557023537759, -8.032699007053509],
              [-35.189548091471181, -8.032823420164675],
              [-35.189548758768183, -8.032947781115183],
              [-35.189597668397653, -8.033119718000535],
              [-35.189738242325973, -8.033382048442718],
              [-35.189970635236591, -8.033763470707584],
              [-35.19018352365066, -8.034087599862801],
              [-35.190328693114679, -8.034311638970905],
              [-35.190391719402271, -8.034430884577603],
              [-35.190392206834815, -8.034521763768339],
              [-35.190368571752117, -8.034588856790265],
              [-35.190287299119518, -8.034646687694419],
              [-35.190138919075821, -8.034719227909989],
              [-35.190014483481605, -8.034782071635277],
              [-35.189861331635086, -8.034859419626457],
              [-35.189703328845496, -8.034927226710124],
              [-35.189554796816367, -8.034971067760523],
              [-35.189468520355888, -8.034990658591994],
              [-35.189362870699881, -8.034976873783648],
              [-35.189242643932801, -8.034929681859296],
              [-35.189170584854537, -8.034915713589271],
              [-35.189113151450726, -8.034944718523889],
              [-35.189075068759394, -8.035002321000263],
              [-35.189013299723968, -8.035117448611345],
              [-35.188941598575866, -8.035170445659503],
              [-35.188874517245154, -8.0351899366662],
              [-35.188802534609238, -8.035190319777371],
              [-35.188706174949971, -8.035119081780181],
              [-35.188688860302591, -8.035096326234395],
              [-35.188679047799781, -8.03508907140349],
              [-35.188563057738861, -8.034936624625459],
              [-35.188490102036553, -8.034755249355214],
              [-35.188436292900576, -8.034564205473657],
              [-35.188372960843424, -8.034387560637356],
              [-35.188330180993034, -8.034307202615253],
              [-35.188269724068448, -8.034269985971036],
              [-35.188202488054344, -8.03426077601398],
              [-35.188111439791044, -8.034285176419701],
              [-35.187996805907801, -8.034386236835303],
              [-35.187868056265756, -8.034539985831657],
              [-35.187758476572412, -8.034688849710619],
              [-35.187658495343214, -8.034837663227506],
              [-35.187606040586275, -8.034900123841624],
              [-35.187558155284592, -8.034919511426267],
              [-35.187500492553625, -8.034905467613214],
              [-35.187423379285462, -8.034843695879829],
              [-35.187201787232652, -8.034687028583448],
              [-35.186970626393247, -8.034535194243745],
              [-35.186792353784028, -8.034402209694179],
              [-35.186575561413008, -8.034245515418149],
              [-35.186411839834477, -8.034141153591293],
              [-35.186420458037674, -8.034133917646159],
              [-35.186384723476181, -8.03411113961738],
              [-35.186614044302644, -8.033918589371353],
              [-35.186833740584632, -8.033721306892744],
              [-35.187029621919464, -8.033557634776969],
              [-35.187192192365728, -8.033446755563261],
              [-35.187445326340708, -8.033220595853777],
              [-35.187688657705337, -8.032956223051201],
              [-35.18795105559667, -8.032667829356797],
              [-35.188199263085494, -8.032417780955768],
              [-35.188480752684541, -8.032110153460792],
              [-35.188685770232041, -8.031860333487543],
              [-35.188852522525849, -8.031634632432315],
              [-35.189023974676672, -8.031389774018805],
              [-35.189176357602776, -8.031168932899902],
              [-35.189285884236142, -8.031010502932304],
              [-35.189390688529386, -8.030866446829009],
              [-35.189524006727481, -8.030669623004655],
              [-35.189633403858465, -8.030487277365921],
              [-35.189757021069624, -8.030271372625691],
              [-35.189842475549767, -8.030098720779279],
              [-35.189904015258037, -8.029940546219166],
              [-35.189980000776359, -8.029791859506645],
              [-35.190036664360548, -8.029619361444304],
              [-35.190083731279117, -8.029446913744039],
              [-35.190125693645825, -8.029217093793818],
              [-35.190172452096931, -8.028987247763212],
              [-35.190181074796726, -8.028805438120401],
              [-35.190204096541777, -8.028623552035242],
              [-35.190212850542594, -8.028465658962944],
              [-35.190245903325717, -8.028365033819227],
              [-35.190331948660457, -8.02830239244139],
              [-35.190518661407054, -8.02822008378979],
              [-35.190714973517174, -8.028137722977476],
              [-35.190901792383784, -8.028074547402412],
              [-35.191098283458984, -8.028025668349869],
              [-35.191271012647064, -8.028019965748797],
              [-35.191453414043792, -8.028028560569567],
              [-35.191664685476837, -8.028051352987518],
              [-35.191895278844015, -8.028097957654552],
              [-35.192149867022692, -8.02814443371858],
              [-35.192260466547225, -8.028186895208989],
              [-35.192351643413588, -8.028186409575085],
              [-35.192351739641026, -8.028186369105583],
              [-35.192423470466736, -8.028157326399423],
              [-35.192523884269804, -8.028089826883956],
              [-35.192672182475178, -8.02800293798515],
              [-35.192791971272413, -8.027968816807402],
              [-35.192979121988856, -8.027967818559944],
              [-35.193089746694397, -8.028015061745634],
              [-35.193219851614799, -8.028114818144275],
              [-35.193240491055747, -8.028139862464627],
              [-35.193246963476554, -8.028144825823006],
              [-35.1933725511023, -8.028297220440322],
              [-35.193517896833441, -8.028554740408879],
              [-35.193711617097392, -8.028883752882848],
              [-35.194041036064903, -8.029460769598813],
              [-35.194195979360927, -8.029718236507335],
              [-35.19433596243465, -8.029889703047331],
              [-35.194487316536367, -8.030011795008306],
              [-35.194641285866624, -8.030087504434732],
              [-35.194799721230083, -8.030101009553903],
              [-35.194948223581605, -8.030052385908846],
              [-35.195048356796178, -8.029932270657696],
              [-35.195139173933683, -8.029864819705608],
              [-35.195278156061988, -8.029830595105807],
              [-35.19548930069061, -8.029829469154606],
              [-35.195738808599117, -8.029823355563337],
              [-35.195853872358271, -8.029803609149212],
              [-35.195891905586905, -8.029736440584202],
              [-35.195896038871069, -8.029612053553365],
              [-35.195876097303994, -8.029473446442353],
              [-35.195788720972644, -8.029287365919174],
              [-35.195667701902892, -8.02909189917176],
              [-35.195510500409227, -8.028899342630496],
              [-35.195361634731626, -8.028736237986948],
              [-35.195360446727193, -8.028734647985573],
              [-35.195334512977354, -8.028706232106856],
              [-35.195199171304694, -8.028525190485084],
              [-35.195097477766353, -8.028353536886186],
              [-35.194995626846662, -8.028153184122118],
              [-35.194860013578705, -8.027955049086188],
              [-35.1947811430353, -8.027873566911524],
              [-35.194781131344087, -8.027871565020689],
              [-35.194754025777627, -8.027843561031489],
              [-35.194753794651831, -8.027800514082401],
              [-35.194777736403353, -8.02779081759212],
              [-35.194864139668255, -8.027795141532522],
              [-35.195114081049951, -8.027870341043354],
              [-35.195407417418323, -8.027983573782876],
              [-35.195777867054858, -8.028158577356407],
              [-35.196085880359021, -8.028324347590512],
              [-35.196263946124191, -8.028419061490467],
              [-35.196427718964003, -8.028532987607264],
              [-35.196449117432792, -8.028559007692024],
              [-35.196454844315554, -8.028562991688659],
              [-35.196599754774809, -8.028739195857156],
              [-35.196686903577699, -8.028882228531984],
              [-35.196730682574923, -8.028992009673459],
              [-35.196740844914018, -8.029097188084734],
              [-35.196736739508879, -8.029226356810852],
              [-35.196704098615214, -8.029403510663428],
              [-35.196647465608009, -8.029580793119067],
              [-35.196533785905444, -8.029858827522844],
              [-35.196391417829602, -8.030156147887851],
              [-35.19622990228811, -8.030463138662867],
              [-35.19614452784765, -8.030650140091836],
              [-35.195968438792363, -8.030923724650108],
              [-35.195821012929343, -8.031173238853853],
              [-35.195673741749715, -8.031451454920671],
              [-35.195516793864897, -8.03171537086763],
              [-35.195364698721846, -8.03198882682284],
              [-35.195250581948756, -8.032185548124517],
              [-35.195121939325986, -8.032358432895307],
              [-35.195007128276302, -8.032426009752498],
              [-35.194896986506706, -8.032469647556127],
              [-35.194777120367746, -8.032489420050638],
              [-35.194523016923654, -8.032533824975985],
              [-35.194316823962197, -8.03256362491129],
            ],
            [
              [-35.138489721723261, -8.034781304513558],
              [-35.138751214896615, -8.03526155058006],
              [-35.138927461333253, -8.03560055452192],
              [-35.139110056084178, -8.036431456146715],
              [-35.139122209522327, -8.0370137977485],
              [-35.139162869670656, -8.037709172440316],
              [-35.139163299546567, -8.038014487778355],
              [-35.139169699122249, -8.038540291701054],
              [-35.139102155540002, -8.03889657881399],
              [-35.13913077106821, -8.039088772029174],
              [-35.13916412242628, -8.039169222681437],
              [-35.139223005537247, -8.03921104295523],
              [-35.139750282549471, -8.039176449633374],
              [-35.140390873240165, -8.039085158553178],
              [-35.141666352020479, -8.038868671052285],
              [-35.143083607122719, -8.038674617139804],
              [-35.143485586990209, -8.038244397660776],
              [-35.143694742318473, -8.03779180664651],
              [-35.143688043268526, -8.037056807825365],
              [-35.143596816040201, -8.036689423078371],
              [-35.143579525674511, -8.036491556939779],
              [-35.143613218774988, -8.036259701823724],
              [-35.143703778706367, -8.036146507755063],
              [-35.14389072257876, -8.036027533743663],
              [-35.144162805269218, -8.035964993989239],
              [-35.144718353369285, -8.035885121601041],
              [-35.145733133976933, -8.035770726038152],
              [-35.146464478852579, -8.03570193149892],
              [-35.146804624034132, -8.03566191346647],
              [-35.147093776756321, -8.03565022947447],
              [-35.147450974882531, -8.035638460946188],
              [-35.147638099518645, -8.035643865871691],
              [-35.148103177619532, -8.035739376570916],
              [-35.148210986547724, -8.035795781150341],
              [-35.148223747927602, -8.035818476441477],
              [-35.148238028262369, -8.035825948908382],
              [-35.148346026947536, -8.036018032406218],
              [-35.148397375538423, -8.036238472427499],
              [-35.148312828474218, -8.036594776627567],
              [-35.148233711516525, -8.036781456099277],
              [-35.148080935586677, -8.037007814558933],
              [-35.147865837538347, -8.037262521448156],
              [-35.1476336478745, -8.037460712242023],
              [-35.147509073784704, -8.037585264748145],
              [-35.14744121184242, -8.037709739912543],
              [-35.147464158044443, -8.037896283586576],
              [-35.147520654354651, -8.038008566641963],
              [-35.147618804564161, -8.03805244996056],
              [-35.147868323264447, -8.038074744154073],
              [-35.14815182104968, -8.038068728588883],
              [-35.148588194589479, -8.037909844463684],
              [-35.148928207570691, -8.037773707790336],
              [-35.149245607099033, -8.037688483536613],
              [-35.149557440723243, -8.037671117627951],
              [-35.149846620425137, -8.037676389453736],
              [-35.150028132392379, -8.037727040170694],
              [-35.150152987070669, -8.037806034820619],
              [-35.150164766390787, -8.037826525873413],
              [-35.150180040476471, -8.037836189088807],
              [-35.150225512897066, -8.037915280865491],
              [-35.150248313408895, -8.038000059954754],
              [-35.150248409636333, -8.038067899413988],
              [-35.149977014927231, -8.038611032373183],
              [-35.149869625083113, -8.038848643150288],
              [-35.149643623654242, -8.039414327509178],
              [-35.14955304843437, -8.039516214401715],
              [-35.149445356418028, -8.039544626683153],
              [-35.149031520788014, -8.039601713847958],
              [-35.147846368115324, -8.039529767184945],
              [-35.147058186086383, -8.039508179858558],
              [-35.146553604365295, -8.039548416426214],
              [-35.145396891853011, -8.039538615614561],
              [-35.144268543958106, -8.039545745439739],
              [-35.143140308478394, -8.039626363365926],
              [-35.142403166572819, -8.039610360829442],
              [-35.141694440545962, -8.039639551923756],
              [-35.139585336094342, -8.0397723152401],
              [-35.138309720617087, -8.039892692193916],
              [-35.136983043432679, -8.039990515050192],
              [-35.135611151933063, -8.040195814085507],
              [-35.134052144790587, -8.04040700188159],
              [-35.132266316936921, -8.040607171183979],
              [-35.130588230562353, -8.040812847035227],
              [-35.128927185441228, -8.041052414737123],
              [-35.127316998780429, -8.041156220782909],
              [-35.125910781956975, -8.041152338409631],
              [-35.124980892853898, -8.04117046784279],
              [-35.124982041288149, -8.041139590519663],
              [-35.124953841246736, -8.041140140904758],
              [-35.12495930912479, -8.040993128729838],
              [-35.124981790377262, -8.040846094971243],
              [-35.125021271514413, -8.040693391886464],
              [-35.125123171896803, -8.040568870856646],
              [-35.125259206946794, -8.040534777557866],
              [-35.125395306747976, -8.040545917460065],
              [-35.125537145123133, -8.040602279771349],
              [-35.125690334741137, -8.040675595202515],
              [-35.125826483105641, -8.040720648539036],
              [-35.1259568128566, -8.040658299440906],
              [-35.125945285346575, -8.04052261242856],
              [-35.125871406939837, -8.040398320725899],
              [-35.125795728989658, -8.040305348812751],
              [-35.125682703094242, -8.040219711770249],
              [-35.125569190665601, -8.040140707227806],
              [-35.125432951469463, -8.040027791949001],
              [-35.125319408463895, -8.039926159564573],
              [-35.125313811982778, -8.039915034051546],
              [-35.125292362252651, -8.039895834425181],
              [-35.125229827894202, -8.039771529232667],
              [-35.125184262843391, -8.039624581808937],
              [-35.12518406049594, -8.039477583123926],
              [-35.125166843874695, -8.039324948387616],
              [-35.125121259038849, -8.039161032555569],
              [-35.125058663526431, -8.038991494162076],
              [-35.125013121858046, -8.038861503455564],
              [-35.124978886466408, -8.038703238063931],
              [-35.124950298817168, -8.038528006062563],
              [-35.124950105462972, -8.038380996585659],
              [-35.124955558951854, -8.038222678134048],
              [-35.125006377841999, -8.038069963358055],
              [-35.125108279123765, -8.037951100862585],
              [-35.125261329346813, -8.037916984181436],
              [-35.125425786570759, -8.037933743947065],
              [-35.125573233118189, -8.037950519001186],
              [-35.125726306723607, -8.037933368929771],
              [-35.125862316592588, -8.037882307222674],
              [-35.125964265538357, -8.037791719412269],
              [-35.126071872119155, -8.037701122608667],
              [-35.126207893679293, -8.037655719435861],
              [-35.126332719579295, -8.037717755569929],
              [-35.126350825630027, -8.037743635360414],
              [-35.126359756797285, -8.037748073514706],
              [-35.126450653075153, -8.037877998570707],
              [-35.126507556778279, -8.038024931605264],
              [-35.126547430919175, -8.038154923211096],
              [-35.126593011258421, -8.038318837244503],
              [-35.126644251930657, -8.038471428813352],
              [-35.126684153051201, -8.038624038368653],
              [-35.126741055855007, -8.038770970503833],
              [-35.126780920103329, -8.038900959411706],
              [-35.126848034708985, -8.03901899543024],
              [-35.126958344651825, -8.039119161919757],
              [-35.127117199998736, -8.039186808924057],
              [-35.127253246739883, -8.03915837236093],
              [-35.127287072040701, -8.039016972854995],
              [-35.127286866995291, -8.038864322830193],
              [-35.127286639466774, -8.038694707994352],
              [-35.127275089473756, -8.03854206066751],
              [-35.127263563762426, -8.038406374554484],
              [-35.127218006805549, -8.038259435224688],
              [-35.1271611156929, -8.038123811164894],
              [-35.127104224580251, -8.037982526772169],
              [-35.127047331668962, -8.037841254070599],
              [-35.126996096392702, -8.03769430754619],
              [-35.127007242590139, -8.03755294671106],
              [-35.127097831299807, -8.037451063415801],
              [-35.127262293919671, -8.037473470923885],
              [-35.127387144101363, -8.037552475466327],
              [-35.127512005974268, -8.037637127751225],
              [-35.12763117603788, -8.037710483651836],
              [-35.127761692847798, -8.037783816170133],
              [-35.127875198081881, -8.037862831504412],
              [-35.127971721417794, -8.037958830535558],
              [-35.127990048701747, -8.037980472720619],
              [-35.127998760434423, -8.037989136789179],
              [-35.128089632430544, -8.03809644479503],
              [-35.128174837798497, -8.03820941583183],
              [-35.128260040468433, -8.038322393163867],
              [-35.128350937645621, -8.038446659685576],
              [-35.128430488975823, -8.038570946891639],
              [-35.128476043234741, -8.038712245673537],
              [-35.128532937944669, -8.038853517475786],
              [-35.128589830855958, -8.038994792875371],
              [-35.128660756788463, -8.039096177946192],
              [-35.128789817595987, -8.039128168630043],
              [-35.128920159937365, -8.039077120412742],
              [-35.128999388410989, -8.038963941632574],
              [-35.129004864382921, -8.038816932155669],
              [-35.128993303598065, -8.038658636187051],
              [-35.128942051234617, -8.038494734744177],
              [-35.128873849348622, -8.038376088985274],
              [-35.128789329264066, -8.03825284139549],
              [-35.128719122789221, -8.038174805422898],
              [-35.128622595856086, -8.038078809989031],
              [-35.128497680923203, -8.037948924503212],
              [-35.128490026793258, -8.037938776553233],
              [-35.128470642805894, -8.03791862184687],
              [-35.128368395285179, -8.037783058940988],
              [-35.128294502489268, -8.037647451068949],
              [-35.128288633513591, -8.037506112716869],
              [-35.128373518722867, -8.037381615069421],
              [-35.128458415623356, -8.037268434490613],
              [-35.128486573396629, -8.037127047575154],
              [-35.128486369250481, -8.036974387657835],
              [-35.128622390810619, -8.036928982686334],
              [-35.128639328642066, -8.03696265600172],
              [-35.128649423532011, -8.036959286241995],
              [-35.128711959689156, -8.037083600427763],
              [-35.128787930818305, -8.037171739384235],
              [-35.128923200545216, -8.037143466497753],
              [-35.12905352669884, -8.037081106607729],
              [-35.129178351699522, -8.037143142741797],
              [-35.129314447903425, -8.037148620512369],
              [-35.129410707737918, -8.037052385858885],
              [-35.129387831683061, -8.036911062795241],
              [-35.129301985098493, -8.036801982225654],
              [-35.129204762090069, -8.036715464746862],
              [-35.129091219983763, -8.036613841355631],
              [-35.128989000342074, -8.036500889204603],
              [-35.128988402292862, -8.036499799226306],
              [-35.128961969419322, -8.036470589246278],
              [-35.128893755842114, -8.036346285852346],
              [-35.12889356248786, -8.036199285368696],
              [-35.128995501541112, -8.03610870115557],
              [-35.12909201678309, -8.036204687596182],
              [-35.129112640935602, -8.036228614958588],
              [-35.129119047705842, -8.036234986655245],
              [-35.129199055891661, -8.036327811079559],
              [-35.129296440778091, -8.036419287420131],
              [-35.129432468633468, -8.0363795328891],
              [-35.12943227887655, -8.036238182845807],
              [-35.129426409001553, -8.036091185060116],
              [-35.129460223510478, -8.035944145006226],
              [-35.129539440292888, -8.035825310389725],
              [-35.129652724293749, -8.035734708190205],
              [-35.129777384718466, -8.035678010431809],
              [-35.129913466533196, -8.035677841359245],
              [-35.130060937362316, -8.035705922690113],
              [-35.130208469345348, -8.035790547995362],
              [-35.130310653913511, -8.035880885794199],
              [-35.130429869842601, -8.035982507386734],
              [-35.130554747003941, -8.036084116388793],
              [-35.130577329879827, -8.036110786683423],
              [-35.130581777027317, -8.036114405555338],
              [-35.13064895908218, -8.036193743746253],
              [-35.130776149299834, -8.036281731616555],
              [-35.130790642773889, -8.036303345922647],
              [-35.130803181121848, -8.036312019883781],
              [-35.130875879618088, -8.036420434955062],
              [-35.131009000864651, -8.036552826851448],
              [-35.131122542071637, -8.036654459235876],
              [-35.131219068105452, -8.036756105110101],
              [-35.131219797455628, -8.036758690660974],
              [-35.131246102625482, -8.036786389779991],
              [-35.13128599295419, -8.036927688561946],
              [-35.131240820007804, -8.037069092564536],
              [-35.131178642680197, -8.037204872207042],
              [-35.131059680359897, -8.037289824865468],
              [-35.130917977782417, -8.03732393255342],
              [-35.130781920249376, -8.037341064638383],
              [-35.130691331539651, -8.037442947034322],
              [-35.130730418773737, -8.037564174746819],
              [-35.130834678077861, -8.03761598918652],
              [-35.13085677172262, -8.037643819606558],
              [-35.130861715295907, -8.03764627565505],
              [-35.13092782805677, -8.0377295483799],
              [-35.131027727447645, -8.037807982752156],
              [-35.131101509626944, -8.037864424203747],
              [-35.131119098567524, -8.037887481022381],
              [-35.131128549542893, -8.037894709773013],
              [-35.131367053346764, -8.038207333901312],
              [-35.131737535358923, -8.038570365030296],
              [-35.132140591714915, -8.03891474601869],
              [-35.132310899928029, -8.039067192796665],
              [-35.132336855261599, -8.039096495406909],
              [-35.132337947038593, -8.039097469372678],
              [-35.132548068238691, -8.039334672756922],
              [-35.132678739732, -8.039515425696266],
              [-35.13279193649862, -8.039694473521081],
              [-35.132873201037341, -8.039756265938877],
              [-35.13295257160388, -8.039750513875049],
              [-35.133037584516842, -8.039722133069915],
              [-35.133179243926918, -8.039654107451042],
              [-35.133230074508276, -8.03950704401484],
              [-35.13334865012007, -8.039145039012283],
              [-35.133495337639658, -8.038613380503079],
              [-35.133675953882118, -8.038008179034193],
              [-35.133800031546173, -8.0375217825042],
              [-35.13384517931155, -8.03736905873501],
              [-35.134043103906095, -8.03698434495152],
              [-35.134235706312779, -8.036854052972103],
              [-35.134366056748092, -8.036808660591191],
              [-35.134519130353567, -8.036797155564216],
              [-35.134683586678136, -8.036808253198274],
              [-35.134825362100742, -8.036825039943608],
              [-35.134967064678222, -8.03679093945027],
              [-35.13505200834345, -8.036711675003744],
              [-35.135210492270346, -8.036513584034651],
              [-35.13531237826362, -8.036383413463739],
              [-35.135590056535818, -8.036269981974044],
              [-35.13560139069159, -8.036264310849219],
              [-35.135691955119569, -8.036151117679879],
              [-35.135708723878452, -8.035975827222614],
              [-35.135583629081168, -8.035721552007999],
              [-35.13531657450028, -8.035309163387126],
              [-35.135219714817936, -8.03497004163404],
              [-35.135213777493789, -8.034777820539944],
              [-35.135366165815867, -8.034263117848582],
              [-35.13548483405782, -8.03397461173887],
              [-35.13573938986093, -8.03353893697232],
              [-35.135812937317155, -8.033420111349017],
              [-35.136016796537888, -8.033227615062344],
              [-35.136175380289501, -8.033097377042282],
              [-35.1364075879398, -8.032904843883387],
              [-35.136622986361715, -8.032859343583766],
              [-35.137008576984215, -8.032887120044506],
              [-35.137150362299337, -8.032909552733599],
              [-35.137275046106424, -8.032864160352688],
              [-35.137416666845638, -8.032773519482305],
              [-35.137620611501973, -8.032643225704248],
              [-35.137818931798222, -8.032552506592879],
              [-35.138142100477523, -8.032529478552533],
              [-35.138272566026046, -8.032574541781628],
              [-35.138414507823256, -8.03271005792277],
              [-35.138415618485965, -8.032715560874408],
              [-35.138441527954114, -8.032740294928601],
              [-35.138475779533564, -8.032909868395677],
              [-35.138442227626683, -8.033243491693725],
              [-35.138380079976685, -8.033401881191821],
              [-35.138255672261437, -8.033645163093752],
              [-35.138199300057636, -8.03388269742851],
              [-35.138205392065174, -8.0341823542322],
              [-35.138319208464679, -8.034481862647795],
              [-35.138489721723261, -8.034781304513558],
            ],
            [
              [-35.199478430684962, -8.063244952492539],
              [-35.199322718468636, -8.063293620204377],
              [-35.19911868927602, -8.063282756394074],
              [-35.198974522556114, -8.063247655854582],
              [-35.198806133496248, -8.063170833966751],
              [-35.198649387958824, -8.063028178107913],
              [-35.198636643666134, -8.063011430033441],
              [-35.198622104326603, -8.06299819920747],
              [-35.198458974502046, -8.062783827811359],
              [-35.198265010521766, -8.062414166880274],
              [-35.19816212807973, -8.062247307567134],
              [-35.198032491706101, -8.062104742539816],
              [-35.197851790028039, -8.061968188580977],
              [-35.197689594599069, -8.061927203777373],
              [-35.197569810298489, -8.061963719849757],
              [-35.197462281958678, -8.062048002513279],
              [-35.19730718308, -8.062210268988679],
              [-35.197205459864051, -8.062258647118824],
              [-35.197085479511202, -8.062259291033399],
              [-35.196959052817988, -8.062176262024821],
              [-35.196937641758723, -8.062150129524809],
              [-35.196931779078284, -8.062146279527155],
              [-35.196804964777243, -8.061991503505055],
              [-35.196695760101193, -8.061764885141315],
              [-35.196573977507001, -8.061430711458115],
              [-35.196150096449742, -8.060697559844471],
              [-35.195962326999734, -8.060363737796195],
              [-35.195865155252648, -8.060143033374231],
              [-35.19574969579179, -8.059868616943049],
              [-35.195694514290381, -8.059647687690585],
              [-35.195633239882056, -8.059408852936144],
              [-35.195619761742591, -8.059133889717202],
              [-35.195606604661009, -8.058918713427829],
              [-35.195611541039739, -8.058721379687881],
              [-35.195622283441594, -8.058488138716598],
              [-35.195651215531143, -8.058290676373588],
              [-35.195674052015818, -8.058075307629281],
              [-35.195720851835802, -8.057853831589],
              [-35.195785582338715, -8.057620301935344],
              [-35.195850184238566, -8.057362855711176],
              [-35.195897016434117, -8.057147361961142],
              [-35.195907953089545, -8.056949994046931],
              [-35.195912823817707, -8.056740701122408],
              [-35.195893670056762, -8.056525557208658],
              [-35.195850359606368, -8.05628064843313],
              [-35.19580056594333, -8.05594608804148],
              [-35.195751033083639, -8.055659360790798],
              [-35.195695722979167, -8.055414514967822],
              [-35.195676728398269, -8.055229264518914],
              [-35.195681890506819, -8.055073782529007],
              [-35.19567505745789, -8.054918363491652],
              [-35.195698053122612, -8.054732890010825],
              [-35.195691122047606, -8.054559534894565],
              [-35.195660194362461, -8.054386308381368],
              [-35.195653101409448, -8.054183058901003],
              [-35.195663942736701, -8.053967755807264],
              [-35.19571099886349, -8.053794111109312],
              [-35.195770116697474, -8.053632359945425],
              [-35.195883483435978, -8.053518150542175],
              [-35.196026937892839, -8.053421718937159],
              [-35.196194547240509, -8.053355052193865],
              [-35.196380252746451, -8.053306226201357],
              [-35.196542154097074, -8.053293400969608],
              [-35.196770235657823, -8.053316097160121],
              [-35.197040339839759, -8.053344547213044],
              [-35.197232397257267, -8.053361454467506],
              [-35.197358434544071, -8.053372737361883],
              [-35.19757400293787, -8.053299835618873],
              [-35.197729553276247, -8.053221273542874],
              [-35.197843141247915, -8.053148917688361],
              [-35.197945087495725, -8.053142393106896],
              [-35.198071287559856, -8.053183569466114],
              [-35.198324071698494, -8.053337670996655],
              [-35.198637486330881, -8.053611027127147],
              [-35.198818478489954, -8.053801386624627],
              [-35.199029628514495, -8.05402147860832],
              [-35.199234615485068, -8.054211709502738],
              [-35.199427607298219, -8.054402006047667],
              [-35.199626533737501, -8.054580310133133],
              [-35.199855711571786, -8.054806282783659],
              [-35.199861800881365, -8.054815400110556],
              [-35.19988296282844, -8.0548362661807],
              [-35.20003415775011, -8.05506265971394],
              [-35.200125203315451, -8.055259479041695],
              [-35.200197995341171, -8.05540856235524],
              [-35.200264918391213, -8.055581596413504],
              [-35.2003196493323, -8.05571881946787],
              [-35.200350288335073, -8.055838236845091],
              [-35.200345031797724, -8.05597578275615],
              [-35.200309749595249, -8.056107510053494],
              [-35.200280660124349, -8.056275079831039],
              [-35.20023950804682, -8.056430753376503],
              [-35.20023434773691, -8.05658623716505],
              [-35.200283176427433, -8.056741428673945],
              [-35.200338005394599, -8.056896590505175],
              [-35.200393415323788, -8.057159370608417],
              [-35.20044234204039, -8.057332499994857],
              [-35.200455244613806, -8.057499844042525],
              [-35.200462080360694, -8.057655260381921],
              [-35.200474983833374, -8.057822604429589],
              [-35.200500011965914, -8.058013798497939],
              [-35.200506850410761, -8.058169216635974],
              [-35.200513654681401, -8.058318656081042],
              [-35.200520333946201, -8.058444179854973],
              [-35.200515302239353, -8.058623578415393],
              [-35.200510498960341, -8.058844827826476],
              [-35.200535332839308, -8.059000148837754],
              [-35.200536236657967, -8.059167555837973],
              [-35.200543172229573, -8.059340912752873],
              [-35.200555880549416, -8.059472382844149],
              [-35.200556558638255, -8.059597937195008],
              [-35.200533304868088, -8.059735581132145],
              [-35.200486090460686, -8.059879328767977],
              [-35.200432843400961, -8.060017133683743],
              [-35.200379563965669, -8.060148958107902],
              [-35.200368373701451, -8.060298492881088],
              [-35.200363731401012, -8.060549638455029],
              [-35.20033541534707, -8.060860698662282],
              [-35.20029484603026, -8.061123994976356],
              [-35.200260014388107, -8.061339424874518],
              [-35.200237084373896, -8.0615368566406],
              [-35.20018435352506, -8.061770321543065],
              [-35.2001378154078, -8.062039628026355],
              [-35.200055317898546, -8.062315104758227],
              [-35.199978784693144, -8.062584573119523],
              [-35.199883934096249, -8.062794348979992],
              [-35.199788918923446, -8.06297422867766],
              [-35.199639820321408, -8.063136462777493],
              [-35.199478430684962, -8.063244952492539],
            ],
            [
              [-35.176933673450208, -8.053325396150058],
              [-35.176938253697415, -8.053368711097107],
              [-35.176960848264514, -8.053355412821986],
              [-35.176967166001873, -8.053415169174684],
              [-35.176974124056528, -8.053594505681872],
              [-35.176991766956462, -8.053680058188093],
              [-35.177019698999857, -8.053707602623717],
              [-35.177163828847597, -8.053736734362758],
              [-35.177224072632782, -8.053784246445787],
              [-35.17729618656972, -8.053807780804391],
              [-35.177332083908482, -8.053789653169929],
              [-35.177373628989756, -8.05370572573861],
              [-35.177439360438086, -8.053657541862037],
              [-35.177505349092542, -8.053657193824392],
              [-35.177571652509698, -8.053716632716373],
              [-35.177573535690101, -8.053723971184297],
              [-35.177598831820603, -8.053746647589662],
              [-35.177635591609203, -8.05388995185973],
              [-35.177654610471848, -8.054081181900983],
              [-35.177613671533663, -8.054278709894504],
              [-35.177566831244235, -8.054494207241817],
              [-35.177573787500251, -8.054673544648324],
              [-35.177628927632838, -8.054888499704475],
              [-35.177629726230862, -8.055037972424429],
              [-35.177600370560583, -8.055157710859646],
              [-35.177499282266012, -8.055325662848986],
              [-35.177421840745296, -8.055427717914824],
              [-35.177350620734501, -8.055571595053038],
              [-35.177273944536864, -8.055817146843822],
              [-35.17725068447146, -8.055954789881582],
              [-35.177257608351908, -8.056128145897162],
              [-35.177258373674988, -8.056271642622164],
              [-35.177199343075188, -8.056451328965636],
              [-35.177128252566774, -8.056619121774986],
              [-35.177098958949728, -8.056750817596082],
              [-35.177105724549506, -8.056894280146878],
              [-35.177162492455011, -8.056967457981727],
              [-35.177271261859232, -8.057006743066665],
              [-35.177421331729022, -8.057023881446923],
              [-35.177607039932923, -8.056975064447613],
              [-35.177864193877383, -8.056824221160809],
              [-35.178079162423387, -8.056637726949475],
              [-35.178216405262845, -8.056499478667945],
              [-35.178318192330664, -8.056463065118294],
              [-35.178390211838746, -8.056468658901451],
              [-35.178480673744048, -8.05655786715181],
              [-35.178496888520556, -8.056577050590363],
              [-35.178507867444068, -8.05658787842782],
              [-35.17862861311994, -8.056730735734845],
              [-35.178671148354795, -8.05683215408061],
              [-35.178707844291523, -8.056963500065422],
              [-35.178732541473551, -8.057094909002785],
              [-35.178811231253292, -8.057226031955736],
              [-35.178871955276463, -8.05736322892983],
              [-35.178884848856626, -8.057530574776138],
              [-35.178921608645283, -8.057673879046206],
              [-35.178994043640159, -8.057757201233812],
              [-35.179042482024897, -8.057840651125105],
              [-35.179073115631752, -8.057960069401645],
              [-35.179120937980883, -8.058069666182121],
              [-35.179184877979708, -8.058114674552542],
              [-35.179280859923722, -8.058114163737628],
              [-35.17940648442169, -8.058047725422171],
              [-35.179502051778229, -8.057969489800087],
              [-35.179604000723998, -8.0579629688159],
              [-35.179724170833765, -8.057998205153012],
              [-35.179802508079263, -8.05806355978541],
              [-35.179899031415118, -8.058164690348121],
              [-35.180031454787752, -8.058247695075011],
              [-35.180175843640257, -8.058324655458421],
              [-35.180332070268776, -8.058371658525175],
              [-35.180506102574554, -8.058382691408042],
              [-35.180656011465715, -8.058369935424139],
              [-35.180758183443345, -8.05840526708937],
              [-35.180866706433335, -8.05850633380021],
              [-35.181095560511665, -8.058672533010963],
              [-35.181300257900546, -8.058808962863282],
              [-35.181528951000246, -8.058945265911234],
              [-35.181685594014937, -8.059069993785158],
              [-35.181702979708689, -8.059092172865462],
              [-35.181712809298688, -8.059099999665193],
              [-35.181815494789191, -8.059230994015138],
              [-35.181858062399613, -8.059338391053871],
              [-35.181858833118611, -8.059481885080913],
              [-35.181829669903323, -8.059637495673826],
              [-35.181753285086018, -8.059936858399226],
              [-35.181694544967229, -8.060170355677315],
              [-35.181695183485886, -8.060289934033165],
              [-35.181719726883841, -8.060327924094395],
              [-35.181800418554417, -8.060348910673667],
              [-35.181956453627379, -8.060360038884653],
              [-35.182250337682888, -8.060346515779031],
              [-35.182544252315324, -8.060338973165017],
              [-35.182916376489516, -8.060372866814305],
              [-35.183234765272175, -8.060454878689654],
              [-35.183391184355628, -8.060537752115522],
              [-35.183469555775332, -8.060609084541568],
              [-35.183474494852021, -8.06061879991762],
              [-35.183496783649616, -8.0606390877237],
              [-35.183545288584185, -8.060734494101553],
              [-35.183533707114861, -8.060812283659914],
              [-35.18344407528383, -8.060878532218453],
              [-35.183276618820855, -8.060975087929933],
              [-35.182941671720755, -8.061162225156522],
              [-35.18270241878156, -8.061295039734262],
              [-35.182510998084069, -8.061397703641092],
              [-35.182200077271716, -8.061590690058267],
              [-35.182002655398207, -8.061693385441345],
              [-35.181871255454041, -8.061801707882466],
              [-35.18172198688012, -8.061934044020859],
              [-35.181512438548168, -8.062012887584672],
              [-35.181327047804984, -8.062121497808846],
              [-35.180328999083997, -8.062832342638444],
              [-35.179563560909571, -8.063290826910077],
              [-35.179067437312312, -8.063628294809916],
              [-35.178613241907556, -8.063953581392639],
              [-35.177938008432307, -8.064453438075645],
              [-35.176844250261922, -8.065218597460273],
              [-35.175780129249517, -8.065917828444981],
              [-35.174841730761216, -8.066568557193136],
              [-35.174821955568746, -8.066533373916002],
              [-35.174814499289653, -8.066538545017806],
              [-35.174657024402109, -8.066258361234361],
              [-35.174541958844259, -8.066055684622086],
              [-35.174492881940921, -8.065852653676984],
              [-35.174455899120403, -8.065667496757499],
              [-35.174436912633382, -8.065482244509951],
              [-35.174447763853152, -8.065266936919556],
              [-35.1744586114757, -8.065051632926497],
              [-35.17442772246136, -8.064884378810973],
              [-35.174385092797763, -8.064765023486984],
              [-35.174330272823795, -8.064609858058475],
              [-35.17428751365776, -8.064466584365334],
              [-35.174244787766725, -8.064329292063121],
              [-35.174159685820825, -8.064120474879985],
              [-35.174092644060238, -8.06392351885529],
              [-35.174025570823403, -8.063720585036947],
              [-35.173946276699269, -8.063475859723098],
              [-35.173879235838001, -8.063278903698404],
              [-35.173836414618791, -8.063123673518646],
              [-35.173817556734832, -8.062962338740874],
              [-35.173774766092549, -8.062813084556183],
              [-35.173695950407762, -8.062658045932039],
              [-35.173532733348907, -8.06242572586649],
              [-35.173345618605367, -8.062211469583588],
              [-35.173179053370575, -8.06202669577533],
              [-35.172986849363554, -8.061860868883969],
              [-35.172824238447788, -8.061742147581981],
              [-35.172589545970709, -8.061605870614414],
              [-35.172337140446643, -8.061523502607486],
              [-35.172096991782723, -8.061488898493849],
              [-35.171856840420787, -8.061454297977434],
              [-35.171694517288074, -8.06138938761012],
              [-35.171561998587322, -8.061288445005687],
              [-35.17138129690926, -8.061151881154331],
              [-35.171212654240549, -8.061027211736359],
              [-35.171008021602859, -8.060902734773322],
              [-35.170833572911022, -8.060813972586743],
              [-35.170701148639068, -8.060730966061215],
              [-35.170677296819747, -8.060703037615042],
              [-35.170673956737687, -8.060700944892631],
              [-35.170601154819394, -8.060615702652512],
              [-35.170502226696385, -8.060552643989297],
              [-35.17032771595126, -8.060451922618086],
              [-35.170307587325226, -8.060425975378394],
              [-35.170300526747894, -8.060421900550239],
              [-35.170221997946783, -8.060320670162753],
              [-35.170209394847632, -8.060207134351742],
              [-35.170274525548848, -8.060045352610871],
              [-35.170363618685997, -8.059877464473345],
              [-35.170488863670073, -8.059739281842326],
              [-35.170608527461525, -8.059678856394044],
              [-35.17073447211817, -8.059672210404131],
              [-35.170926851492936, -8.05974892077603],
              [-35.171095522939993, -8.05987956978629],
              [-35.171222041364047, -8.05998054566561],
              [-35.171237579850413, -8.06000126604556],
              [-35.171249231466788, -8.060010565934817],
              [-35.171340271636211, -8.060131970813757],
              [-35.171433277723565, -8.060218592613921],
              [-35.171547511408505, -8.060265820511177],
              [-35.171661651563909, -8.060295111430207],
              [-35.171733925580156, -8.060348541052292],
              [-35.171824100601725, -8.060383937468714],
              [-35.171902056534634, -8.06037754598691],
              [-35.171979787637042, -8.060329300956425],
              [-35.1721409308592, -8.060172989791624],
              [-35.172421320587432, -8.059878526572675],
              [-35.172713549890375, -8.059554104238487],
              [-35.172987907865604, -8.059253693802873],
              [-35.173160666731349, -8.059025571772679],
              [-35.173297653264058, -8.05883949214882],
              [-35.173422514237586, -8.05862955980632],
              [-35.173529444528185, -8.058431683775154],
              [-35.173648181118608, -8.058197868137086],
              [-35.173749618350143, -8.058095684468242],
              [-35.173845507663998, -8.058077239373063],
              [-35.173935746537438, -8.058124594974004],
              [-35.17403814064761, -8.058201781087291],
              [-35.174122348667424, -8.058243187672929],
              [-35.174206270702769, -8.05823078242463],
              [-35.174236010383538, -8.05818279460027],
              [-35.174223598839944, -8.058105130947013],
              [-35.174150910236278, -8.057973975618438],
              [-35.174072032498259, -8.057806977809719],
              [-35.174083297406185, -8.057669398623773],
              [-35.174118685728729, -8.057555606506014],
              [-35.174219963780274, -8.057423528473009],
              [-35.174297312670774, -8.057303536429004],
              [-35.174398338912169, -8.057123627053727],
              [-35.174571159831146, -8.056907464208052],
              [-35.17469021298291, -8.056733435499609],
              [-35.174856938297012, -8.056499367152014],
              [-35.175029567660374, -8.056247328551308],
              [-35.175166394113774, -8.056031353663968],
              [-35.175333055576004, -8.055785325232534],
              [-35.175493973068342, -8.055587161418316],
              [-35.175672760989073, -8.055364984806147],
              [-35.175881222040744, -8.055082862084362],
              [-35.176071877415154, -8.054836706848505],
              [-35.176238664782488, -8.054614593188887],
              [-35.176327819972869, -8.054458664235938],
              [-35.176393011827997, -8.054308839881003],
              [-35.176505806597675, -8.054087013105118],
              [-35.176618477260888, -8.053841273356056],
              [-35.176725340102337, -8.053631435442355],
              [-35.176802720469141, -8.053517422990637],
              [-35.176861909349611, -8.053367630112007],
              [-35.176933673450208, -8.053325396150058],
            ],
            [
              [-34.977393758914786, -8.061874167159033],
              [-34.977403954528825, -8.0619058080066],
              [-34.977420834803638, -8.061905428492707],
              [-34.977464694739808, -8.062041557072178],
              [-34.977546832520261, -8.062176510237691],
              [-34.977647732856553, -8.062281963841485],
              [-34.977777851266751, -8.062388972373128],
              [-34.977972835078219, -8.062510020221225],
              [-34.978196478484676, -8.062602226811293],
              [-34.978391254552719, -8.062680221415064],
              [-34.978409267073971, -8.06270785308493],
              [-34.97841833224021, -8.062711482748682],
              [-34.978548903009425, -8.062911774358838],
              [-34.978677507860652, -8.063109050542209],
              [-34.978963690122157, -8.063430918801828],
              [-34.979224310053837, -8.063723868461693],
              [-34.979230813051515, -8.063731999232346],
              [-34.979251390439288, -8.063755127996728],
              [-34.979468708014394, -8.064026756529529],
              [-34.979599278783667, -8.064227049938381],
              [-34.979672357693119, -8.064449146510867],
              [-34.979716601639836, -8.064664205888334],
              [-34.979739354487549, -8.064900897558346],
              [-34.979733199527516, -8.065116202450724],
              [-34.979691187698108, -8.065360381876076],
              [-34.979613245254995, -8.065619087151106],
              [-34.979470994990379, -8.065978564159366],
              [-34.979328254595202, -8.066237582398458],
              [-34.9791710468063, -8.066482318504143],
              [-34.978949491625713, -8.06682065065246],
              [-34.978828245926763, -8.067058038397704],
              [-34.97873562474922, -8.067259407395682],
              [-34.978686028937034, -8.067424690197242],
              [-34.978650657701621, -8.067554027096605],
              [-34.978658554648518, -8.067697504036516],
              [-34.97868116540343, -8.067905492944078],
              [-34.978783707901755, -8.068263786444561],
              [-34.978922701721274, -8.068715192152354],
              [-34.978988545584855, -8.068930147208505],
              [-34.979003923092534, -8.069130994599675],
              [-34.978976030619265, -8.069317701950354],
              [-34.978904974285115, -8.06951179093636],
              [-34.978753637270472, -8.069483818423407],
              [-34.978349993657218, -8.069392482377111],
              [-34.97801831289479, -8.069293622602572],
              [-34.977571225731424, -8.069152265364721],
              [-34.977030541626164, -8.069011359586511],
              [-34.976504116271883, -8.068841681798119],
              [-34.976013936294066, -8.06872205667753],
              [-34.975725767430163, -8.068687567677046],
              [-34.975596170626716, -8.068688192705906],
              [-34.975531580418078, -8.068731559813614],
              [-34.975496035613503, -8.06882501556197],
              [-34.975460697653034, -8.068961526353291],
              [-34.975425535060367, -8.069133915597661],
              [-34.975368352567386, -8.069220301775488],
              [-34.975289503607655, -8.069292439994058],
              [-34.975131663595334, -8.069408014568182],
              [-34.975002414829532, -8.069480394704385],
              [-34.974923738539644, -8.069588413174699],
              [-34.974866801561575, -8.069725026488754],
              [-34.974831462701786, -8.069861539078715],
              [-34.974853828841105, -8.070019298152033],
              [-34.974897620428806, -8.070141075350307],
              [-34.974968062526045, -8.070226635051142],
              [-34.975101943699713, -8.070323959682923],
              [-34.975238950916832, -8.070366354623502],
              [-34.975505519863816, -8.070400947945359],
              [-34.975707220711627, -8.070421502850024],
              [-34.975815358791749, -8.070449684904986],
              [-34.975825622754257, -8.070476564741625],
              [-34.975842474250726, -8.070480957929817],
              [-34.975864352957501, -8.070538258233967],
              [-34.975864736967992, -8.07061718993134],
              [-34.975800494796943, -8.070732313045767],
              [-34.975693959308728, -8.071034210960022],
              [-34.975274427372881, -8.072119783501137],
              [-34.975217036237211, -8.072163114635998],
              [-34.975144933991487, -8.072141933803152],
              [-34.975043750368741, -8.07206348773974],
              [-34.974964413077146, -8.072035169887158],
              [-34.974906917620103, -8.072056974849488],
              [-34.974849736925762, -8.072143360127939],
              [-34.974807164818742, -8.072272729402869],
              [-34.974764835528674, -8.07245232761278],
              [-34.974744107954166, -8.072631824199277],
              [-34.974751863707525, -8.072746598376739],
              [-34.974795689469431, -8.07287555396357],
              [-34.974889986083951, -8.073018614517423],
              [-34.974717709254833, -8.073127081749362],
              [-34.974698693090147, -8.073090696978056],
              [-34.974690580306003, -8.073095805127252],
              [-34.973549587943069, -8.070912681075299],
              [-34.972662583011754, -8.069137350108576],
              [-34.971681327331339, -8.067226127084041],
              [-34.970627325490625, -8.065157379709262],
              [-34.97035843539345, -8.064642012418915],
              [-34.970609733852086, -8.064497289917938],
              [-34.970897136493591, -8.06437391912101],
              [-34.97114853837428, -8.064250720993925],
              [-34.97130631093745, -8.064120797736564],
              [-34.97145691680322, -8.063998086142703],
              [-34.971571833073597, -8.063940125736167],
              [-34.971679727437447, -8.063918078856204],
              [-34.971795097865424, -8.063953405125517],
              [-34.971939439053813, -8.064024467754962],
              [-34.972119711755226, -8.06408100813195],
              [-34.972306975786239, -8.064094456593864],
              [-34.972508534541191, -8.064086314132055],
              [-34.972760561450684, -8.064092276637211],
              [-34.973041316303636, -8.064083751963551],
              [-34.973249834911883, -8.064025340097317],
              [-34.973515841782614, -8.063945125966768],
              [-34.973810651240512, -8.063864774239903],
              [-34.974055061791603, -8.063784662632031],
              [-34.97404744453388, -8.063698589418323],
              [-34.974032419560444, -8.063569498033871],
              [-34.974031585888952, -8.063397281459345],
              [-34.974081184399097, -8.063231999557104],
              [-34.974166643375838, -8.063037840423931],
              [-34.974273875838662, -8.062879457221129],
              [-34.974417037116552, -8.062706549067912],
              [-34.974589066632063, -8.062547852901048],
              [-34.974746728578623, -8.062396400773252],
              [-34.974897125801647, -8.062230633237107],
              [-34.975083414068251, -8.062043165959267],
              [-34.975241355703986, -8.061949119356314],
              [-34.975356412268582, -8.061919861712227],
              [-34.977393758914786, -8.061874167159033],
            ],
          ],
          [
            [
              [-34.891205385220303, -7.80667056169699],
              [-34.891316319292685, -7.806679516246618],
              [-34.891282697238637, -7.806605490351046],
              [-34.891055684971832, -7.806340955670521],
              [-34.891205385220303, -7.80667056169699],
            ],
          ],
          [
            [
              [-35.125652900460921, -7.797762551100789],
              [-35.126761495646463, -7.803307046882651],
              [-35.127493044668256, -7.803019320485191],
              [-35.130120522843811, -7.802090181416702],
              [-35.130913379548758, -7.801898841658158],
              [-35.130923743336041, -7.801933476348836],
              [-35.130940008474568, -7.801929551707417],
              [-35.131150820354037, -7.802634068007762],
              [-35.131195006744122, -7.802781737587054],
              [-35.131431755071389, -7.804154427684637],
              [-35.131510774002948, -7.805411046782353],
              [-35.131852862619496, -7.807714024382676],
              [-35.132060203115827, -7.807750511676716],
              [-35.132851274666564, -7.807205296786947],
              [-35.134038199003612, -7.806449920227578],
              [-35.135559986803912, -7.805817747191213],
              [-35.136645728417591, -7.805708184585001],
              [-35.138025089686096, -7.805826114483523],
              [-35.140155955033265, -7.80583615541417],
              [-35.141226456735524, -7.805619831589922],
              [-35.142059605766121, -7.80513683899386],
              [-35.142277682369127, -7.804577308695798],
              [-35.142288176558054, -7.804649297626952],
              [-35.142304272624017, -7.804607998959909],
              [-35.144039175467526, -7.816510840103149],
              [-35.144800093649337, -7.820588888766167],
              [-35.143981552306741, -7.823883110816837],
              [-35.143290579796371, -7.824806008590372],
              [-35.14037392911348, -7.827601564660426],
              [-35.13938055147122, -7.828574646402785],
              [-35.139657276462117, -7.82853808716294],
              [-35.144812410764075, -7.824542656518361],
              [-35.146347460866593, -7.818561529990575],
              [-35.149379575506941, -7.814649169723339],
              [-35.149946876846855, -7.804105304915822],
              [-35.149379586298835, -7.801894804601488],
              [-35.14103060238034, -7.792524832954655],
              [-35.133015612280644, -7.790467920657989],
              [-35.136879508082814, -7.785482353527755],
              [-35.137446804026752, -7.779105174564108],
              [-35.122522241708054, -7.77945846873331],
              [-35.119290158314129, -7.782668066378108],
              [-35.120247497424032, -7.783752300728054],
              [-35.120256391719067, -7.783762932513298],
              [-35.120274161423367, -7.783783057542053],
              [-35.122729226972183, -7.7867177639136],
              [-35.125995932466253, -7.788657040390035],
              [-35.129516825940698, -7.790542365637634],
              [-35.12950711775926, -7.790553643136093],
              [-35.129543455765827, -7.790573099968526],
              [-35.129273474790978, -7.790886702559249],
              [-35.128007860674188, -7.792600009083117],
              [-35.12659664272087, -7.794438941004273],
              [-35.125874488916509, -7.796137755852726],
              [-35.125652900460921, -7.797762551100789],
            ],
          ],
          [
            [
              [-34.935295145854241, -7.769178109781876],
              [-34.935251330884171, -7.77143789654167],
              [-34.935004934630001, -7.772897690475986],
              [-34.934667989236289, -7.77420205548043],
              [-34.934308903433077, -7.775189980533128],
              [-34.933835484120266, -7.776249449352576],
              [-34.933044208423439, -7.777854029639684],
              [-34.932456592297854, -7.778915755757453],
              [-34.931950198442848, -7.779790175576068],
              [-34.928481538492179, -7.793498721388858],
              [-34.928074473857862, -7.795537663432356],
              [-34.927033890801397, -7.799364185208958],
              [-34.927027264596518, -7.799388502877036],
              [-34.929166666308561, -7.799999999600232],
              [-34.956377156680389, -7.800567286550972],
              [-34.960289508853691, -7.803599378708327],
              [-34.969945948746499, -7.804875013071353],
              [-34.971400621041766, -7.810543823038984],
              [-34.978599377459261, -7.818622843069704],
              [-34.979733954058702, -7.823043823013961],
              [-34.98276604531668, -7.826956176086583],
              [-34.983900621016801, -7.831377156030896],
              [-34.986932711375459, -7.835289509103518],
              [-34.9880672879749, -7.843877155106554],
              [-34.990063948392446, -7.846453496931304],
              [-34.990162211916527, -7.846580287849974],
              [-34.990149311141749, -7.846583939097457],
              [-34.990384305791054, -7.84694302669925],
              [-34.990427570376028, -7.846930782429638],
              [-34.990859135240839, -7.846808645502563],
              [-34.995096479918573, -7.84506529622638],
              [-34.99507927858582, -7.845016712151505],
              [-34.994933614494641, -7.844660220892422],
              [-34.994773555854863, -7.844289603082643],
              [-34.994481904815984, -7.843547869238705],
              [-34.994321666311805, -7.843177249630344],
              [-34.994175753108493, -7.842806384507014],
              [-34.994098868268111, -7.842006862027006],
              [-34.994177215406125, -7.841572919354689],
              [-34.994384861671961, -7.841239433652902],
              [-34.994735414707577, -7.84095056241847],
              [-34.99509439079344, -7.840792376167201],
              [-34.995453398355608, -7.840659954593207],
              [-34.995620161441252, -7.840536126041343],
              [-34.995827565789511, -7.840113774730867],
              [-34.995987258405194, -7.839743540931636],
              [-34.996132428768533, -7.839388293434922],
              [-34.996277577548142, -7.83904778043069],
              [-34.996407972949555, -7.838692782945543],
              [-34.99653820377506, -7.838323051867292],
              [-34.996929112088822, -7.837258152042011],
              [-34.9970447972795, -7.83691822809368],
              [-34.997145570811426, -7.836548906206986],
              [-34.99727587268336, -7.836194089485616],
              [-34.997377384558604, -7.835942652531344],
              [-34.99752325999043, -7.835735118680759],
              [-34.997845461898521, -7.835452256716223],
              [-34.998153027340322, -7.835199118244645],
              [-34.998166525264878, -7.835007490703106],
              [-34.998076069654871, -7.83466954166596],
              [-34.997929284109148, -7.83434201397273],
              [-34.99765413562983, -7.834409249087514],
              [-34.996845195449339, -7.834452446223395],
              [-34.9962983725718, -7.834047486002646],
              [-34.996154931604792, -7.834084753908201],
              [-34.996007159502767, -7.834027144237268],
              [-34.995755576858357, -7.833852771987779],
              [-34.995384919478397, -7.833517502031043],
              [-34.995251392637613, -7.833386236085857],
              [-34.995245390562275, -7.833376119612183],
              [-34.995224294265711, -7.833355381245781],
              [-34.995015746879176, -7.833003883022741],
              [-34.99507355530028, -7.832826600567103],
              [-34.995219470302288, -7.83258950240355],
              [-34.99537918630034, -7.832205075503794],
              [-34.99535895425231, -7.832051657457953],
              [-34.99455733994921, -7.831859324847926],
              [-34.993766266599891, -7.831508805087253],
              [-34.99311473835445, -7.831452842074953],
              [-34.992008749404192, -7.831280935766529],
              [-34.991060638237798, -7.831108283920116],
              [-34.99107201646035, -7.831084426704876],
              [-34.99103352727542, -7.831077416489507],
              [-34.991141189614154, -7.830851692051908],
              [-34.991118051856574, -7.830851961848509],
              [-34.990764638976884, -7.830824609867818],
              [-34.990527763845193, -7.83078821070734],
              [-34.990532798250001, -7.830762282353419],
              [-34.990500651084119, -7.83075734237741],
              [-34.990519765274883, -7.830658893593011],
              [-34.990910801292387, -7.830381137979089],
              [-34.992102131408217, -7.829528068065827],
              [-34.992336953387621, -7.829304566752285],
              [-34.992527148309193, -7.829037519365954],
              [-34.992702495423771, -7.828755807634366],
              [-34.992863068475799, -7.828474435846488],
              [-34.993023498535592, -7.828163502443658],
              [-34.99319868557086, -7.827867236084046],
              [-34.993315232312057, -7.827630550709273],
              [-34.993402015090908, -7.827349980217377],
              [-34.993444466688743, -7.827069800031211],
              [-34.993457230766523, -7.826760469220233],
              [-34.993544825633137, -7.826612425522967],
              [-34.993765289936164, -7.826477854569305],
              [-34.994353207334598, -7.826177922573038],
              [-34.99464726405995, -7.826057472774096],
              [-34.994941247040856, -7.825922374817708],
              [-34.99522059355553, -7.8258168195905],
              [-34.995414164532008, -7.825648206599396],
              [-34.99541650456797, -7.825626257745569],
              [-34.995085128675726, -7.825664566166722],
              [-34.993980280965161, -7.825728830820822],
              [-34.993505622386522, -7.825514580833214],
              [-34.993505532454321, -7.825495910008215],
              [-34.993478515920742, -7.825483715201244],
              [-34.993477651672265, -7.825302183448969],
              [-34.993091835319888, -7.825367158567474],
              [-34.992840056623265, -7.825207701574129],
              [-34.99283454557775, -7.825190512831853],
              [-34.992812947459527, -7.825176834143519],
              [-34.992737676902209, -7.824942049036281],
              [-34.993131778708573, -7.824261076087737],
              [-34.993145512255523, -7.82409900656387],
              [-34.993231763534993, -7.823745029809231],
              [-34.993422606867796, -7.8235960481191],
              [-34.993717052999557, -7.823519713663813],
              [-34.994365163821215, -7.823410879508458],
              [-34.994540840087666, -7.823218031586862],
              [-34.994613356021546, -7.82302612705405],
              [-34.994758900502802, -7.822730539682595],
              [-34.995097099551458, -7.822609972072485],
              [-34.995653486718993, -7.822276465686286],
              [-34.996001967716609, -7.822126196166948],
              [-34.996265863878477, -7.822069440851976],
              [-34.996542565486948, -7.822075634482871],
              [-34.996904206264048, -7.822148959806611],
              [-34.996984300784789, -7.822118457500778],
              [-34.997852608810831, -7.822114351196319],
              [-34.998721107493054, -7.822149602821867],
              [-34.999531808546067, -7.82248034469211],
              [-35.000540322677693, -7.822908555985009],
              [-35.003191323520696, -7.821321672557076],
              [-35.005819267545121, -7.819516415749945],
              [-35.007129708369291, -7.817876765500273],
              [-35.007602779644515, -7.81655981358449],
              [-35.008083757759152, -7.816595888089694],
              [-35.008615964854869, -7.816815830785913],
              [-35.009406044453328, -7.817520082685633],
              [-35.009886742878848, -7.817728285631802],
              [-35.010670236741987, -7.817816429984191],
              [-35.012497723890817, -7.818022022198534],
              [-35.012765891832373, -7.817925067188071],
              [-35.013014962670354, -7.817847338783622],
              [-35.013186825811317, -7.817674302027342],
              [-35.013568558342229, -7.817251503753823],
              [-35.013855728958674, -7.817116189960132],
              [-35.014239113544193, -7.817037819439747],
              [-35.017827270012447, -7.817039833021852],
              [-35.01918994975631, -7.817109864128895],
              [-35.021245127260784, -7.817540147459795],
              [-35.021282305234138, -7.817579925373195],
              [-35.021476575883241, -7.817787799167547],
              [-35.021688374318956, -7.81793986643163],
              [-35.021700127558802, -7.817959738750915],
              [-35.021715390852535, -7.817970696990017],
              [-35.022392479629161, -7.819115569028213],
              [-35.023132578505624, -7.821389111818007],
              [-35.023311228830096, -7.821688676890858],
              [-35.023645222648838, -7.821853216852446],
              [-35.024452580921832, -7.822155511367725],
              [-35.025490559541652, -7.822533234720936],
              [-35.025779392105221, -7.822742337888542],
              [-35.026659501035112, -7.823611609088459],
              [-35.02682596734445, -7.823757839752432],
              [-35.027033824051671, -7.823907160487011],
              [-35.027059283858819, -7.823936874986771],
              [-35.027060819001576, -7.823937976656225],
              [-35.02713033659569, -7.824019111692621],
              [-35.028130088632622, -7.824759379641591],
              [-35.028283590315425, -7.824758642197537],
              [-35.028514121629371, -7.82481493885831],
              [-35.028800571888837, -7.824764549844076],
              [-35.029185135485591, -7.824696903739095],
              [-35.029626453497997, -7.8246947804397],
              [-35.030108319243482, -7.824876947113353],
              [-35.030126258919665, -7.824883729800263],
              [-35.030606970834981, -7.825091903968143],
              [-35.030895898726726, -7.825320134816309],
              [-35.031126707931207, -7.825433833404645],
              [-35.031395428056499, -7.825451675954014],
              [-35.031855656113578, -7.825392055398993],
              [-35.032110590531261, -7.825281642933419],
              [-35.032123727827752, -7.825275953822143],
              [-35.032601564610502, -7.824890950456961],
              [-35.03267305082062, -7.824829247072046],
              [-35.033644784479179, -7.824389210593552],
              [-35.034111612660752, -7.824207570922624],
              [-35.034674786112419, -7.824109179694858],
              [-35.035118440563565, -7.824095081922451],
              [-35.036749504086288, -7.824111133921633],
              [-35.037097163103567, -7.824085537417545],
              [-35.037564451738035, -7.82399956762589],
              [-35.03802027311707, -7.824021285353979],
              [-35.038416366022432, -7.824091129402063],
              [-35.03862064072996, -7.824173858037057],
              [-35.038690050405478, -7.824218960836333],
              [-35.040100396016442, -7.825135419464004],
              [-35.040268634889571, -7.825206362483584],
              [-35.04037674059407, -7.825241718430505],
              [-35.040484670930766, -7.825241196823754],
              [-35.040580432540878, -7.825204855219852],
              [-35.040910920802332, -7.825022039235648],
              [-35.041692743725093, -7.824589548069127],
              [-35.041859994243282, -7.824457185850406],
              [-35.042074632738093, -7.824205000660186],
              [-35.042265869074527, -7.824072521529615],
              [-35.042636986907382, -7.823939173653912],
              [-35.042924507360055, -7.823877985580509],
              [-35.043247714710219, -7.823898389399119],
              [-35.043452337455392, -7.823911308160291],
              [-35.044317174098239, -7.824194145843137],
              [-35.0450731478075, -7.824286159078952],
              [-35.045216997066746, -7.824273502919766],
              [-35.045324692680367, -7.824225143675392],
              [-35.045420396733903, -7.824176841088274],
              [-35.045428293680743, -7.824160897907063],
              [-35.045479775371291, -7.824056960560256],
              [-35.04563264033402, -7.823434333827379],
              [-35.045762337861561, -7.822979248993079],
              [-35.046637508614083, -7.820463543957317],
              [-35.046803532457034, -7.82008004156063],
              [-35.046910876435732, -7.819959927208799],
              [-35.047078006444792, -7.819803645721606],
              [-35.047353239460392, -7.819682717483374],
              [-35.047694485412137, -7.819560746031584],
              [-35.048370813362283, -7.819319003768953],
              [-35.049064249115759, -7.818885105163417],
              [-35.049267177538184, -7.818692770754694],
              [-35.049398331967382, -7.818536662836664],
              [-35.049422677514485, -7.818475100645344],
              [-35.049682636444459, -7.817817725007956],
              [-35.049943599017809, -7.8172304497254],
              [-35.050050826084714, -7.817086417004475],
              [-35.05020619655869, -7.816978030711482],
              [-35.050337815038063, -7.816917593572043],
              [-35.050529510928129, -7.816880787018647],
              [-35.050950574409455, -7.816942172942902],
              [-35.051561576505492, -7.817031247194279],
              [-35.051741513759794, -7.817042334036444],
              [-35.052005042998303, -7.816981256579709],
              [-35.052208321256956, -7.816860675479745],
              [-35.0523994316884, -7.816704277080703],
              [-35.052554684351151, -7.816571969720599],
              [-35.052696422901533, -7.816359530967532],
              [-35.053018977343868, -7.815876075220672],
              [-35.053436414758664, -7.815407632755978],
              [-35.053986871796667, -7.815165771782915],
              [-35.054489651276356, -7.814983938757734],
              [-35.055325920055111, -7.814334070660777],
              [-35.055684268414211, -7.814045304647038],
              [-35.055755929092811, -7.813985159787251],
              [-35.055791259858722, -7.813853436986506],
              [-35.05599172683668, -7.813158822221794],
              [-35.056051217889376, -7.813062857364912],
              [-35.056611729347765, -7.812426290241035],
              [-35.056970250376708, -7.812173400881704],
              [-35.057161766402373, -7.812100713177301],
              [-35.058575565409967, -7.81184268509179],
              [-35.059450490647578, -7.811742750627388],
              [-35.059762509532163, -7.811789067511427],
              [-35.06012799401168, -7.811957510530647],
              [-35.060327412380161, -7.812049418545769],
              [-35.06065183291571, -7.81217938766855],
              [-35.060783917243953, -7.812214623106343],
              [-35.062295990567463, -7.812434473172345],
              [-35.062620176379994, -7.812516606456199],
              [-35.062739916613793, -7.812480145242489],
              [-35.062895517314246, -7.812419588493185],
              [-35.063039123756539, -7.812359091998474],
              [-35.063177458372934, -7.812279359904494],
              [-35.063290245048734, -7.812214355108381],
              [-35.063349910569855, -7.81215426690585],
              [-35.063420248345722, -7.812118766168055],
              [-35.063938544727989, -7.81191614711247],
              [-35.065223302713207, -7.811756793541122],
              [-35.067120806579908, -7.811345691850136],
              [-35.067293015959876, -7.811249173910198],
              [-35.067541501339178, -7.811056611973015],
              [-35.068208231724498, -7.810077480491259],
              [-35.069181094032217, -7.808924639560416],
              [-35.069448198975181, -7.808617175742029],
              [-35.069601029763646, -7.808482486977141],
              [-35.069773237344975, -7.808385969936523],
              [-35.069984100485783, -7.808346668663773],
              [-35.070466173075374, -7.808282631538191],
              [-35.070731899357611, -7.808247333147847],
              [-35.070942763397795, -7.808208032774473],
              [-35.07111478032283, -7.80807324688277],
              [-35.071168483338795, -7.808010141454815],
              [-35.07134370095099, -7.807804240773066],
              [-35.071457497565405, -7.807535797638877],
              [-35.071551638597214, -7.80717177635637],
              [-35.071527550256178, -7.80617689145032],
              [-35.071545228229638, -7.805870650709892],
              [-35.071774145260576, -7.805601643700868],
              [-35.072691599437746, -7.804889167501017],
              [-35.073284487389117, -7.804503569683959],
              [-35.07359033692444, -7.804272455609294],
              [-35.074678572358209, -7.803176443737868],
              [-35.075156244564994, -7.802772274920528],
              [-35.075251514245963, -7.802637864945439],
              [-35.075346784826195, -7.802503455869726],
              [-35.075925749473697, -7.800878926820985],
              [-35.076747201022442, -7.798573986899953],
              [-35.076750593265217, -7.798562938728594],
              [-35.077105974760855, -7.797405014829508],
              [-35.077233874543651, -7.797081517897709],
              [-35.077447072324503, -7.796542283499548],
              [-35.077694411967514, -7.796120108456194],
              [-35.077880880098519, -7.795969829943658],
              [-35.077813995719282, -7.793766323656882],
              [-35.079267751605414, -7.792693500804376],
              [-35.080019619010159, -7.791643951507126],
              [-35.080607691991361, -7.789131497520543],
              [-35.079123387034656, -7.78709554662214],
              [-35.077958078802226, -7.787220862652646],
              [-35.076198415824194, -7.786653843699867],
              [-35.076192958738034, -7.786629924431395],
              [-35.076171592644869, -7.786623040121185],
              [-35.075995741010615, -7.785852361597165],
              [-35.076381832555512, -7.783895905371708],
              [-35.078660916068372, -7.783586865941061],
              [-35.080851609713989, -7.783576126237165],
              [-35.084115683791708, -7.782237944025837],
              [-35.088861357665508, -7.781015446105926],
              [-35.091553865818184, -7.780756953070863],
              [-35.093995024250944, -7.781482857947481],
              [-35.096811333076516, -7.783190818001799],
              [-35.096816603103719, -7.783208577813582],
              [-35.096838080712814, -7.783221602694766],
              [-35.097329651042514, -7.784878175487336],
              [-35.097373495690249, -7.785336924159651],
              [-35.097494215285735, -7.786600018376078],
              [-35.098249443457007, -7.785896687382149],
              [-35.101447442545407, -7.784694522234361],
              [-35.102731214873643, -7.784362876545458],
              [-35.103152903383716, -7.784284248819006],
              [-35.105571067950393, -7.784444459444217],
              [-35.10693396263224, -7.784590766550593],
              [-35.107566875810619, -7.784549354569037],
              [-35.108218013750275, -7.784316506601442],
              [-35.109752041323588, -7.784155804946408],
              [-35.111017573602112, -7.784015569163159],
              [-35.111380839454114, -7.783765016242455],
              [-35.112399854969283, -7.783120840854053],
              [-35.109993664875674, -7.782916093103154],
              [-35.100299856353217, -7.773196189852229],
              [-35.099588454843285, -7.768314712953611],
              [-35.094037606250481, -7.762791680391672],
              [-35.080855266357389, -7.762085001221806],
              [-35.064870715411132, -7.746124903741247],
              [-35.058279546363906, -7.745771564606002],
              [-35.056069038855014, -7.745204270460647],
              [-35.051083444745132, -7.741340386349691],
              [-35.048978395336633, -7.749542173732266],
              [-35.043823259235978, -7.753537604376902],
              [-35.034166746498101, -7.754813223451436],
              [-35.032359755798041, -7.761855340451575],
              [-35.022989776057273, -7.770204272209355],
              [-35.020779269447701, -7.770771555562817],
              [-35.018618948605535, -7.770154077448694],
              [-35.014606193421059, -7.767222340638455],
              [-34.984091440967177, -7.765936813732935],
              [-34.982447196081182, -7.754639867435912],
              [-34.972939191090745, -7.746388915016382],
              [-34.968618544909873, -7.745153965083375],
              [-34.964605785228741, -7.742222239065029],
              [-34.954647141652231, -7.740772988782339],
              [-34.94639612178355, -7.731265065630225],
              [-34.945161157461428, -7.726944457220895],
              [-34.942229403564056, -7.722931737109946],
              [-34.940244033235501, -7.715987296376568],
              [-34.937231446474016, -7.716737502733508],
              [-34.941005615116978, -7.720454675942506],
              [-34.941006766249245, -7.720459799380194],
              [-34.941032990480096, -7.720485626110701],
              [-34.941116153487656, -7.72085509098963],
              [-34.941473167252809, -7.72147047997862],
              [-34.941687056612352, -7.722362666803576],
              [-34.941944939906989, -7.723321977227442],
              [-34.941355228361033, -7.734614500658608],
              [-34.941372329869068, -7.735242486451568],
              [-34.941595149496493, -7.73617223346173],
              [-34.942064972420496, -7.737227167899448],
              [-34.943215405866226, -7.739905746452962],
              [-34.94338074712374, -7.740352235567173],
              [-34.943289828362822, -7.741003683772931],
              [-34.942932641028506, -7.741698657367124],
              [-34.942484273230605, -7.742333236089905],
              [-34.942027988700659, -7.742757989489576],
              [-34.941572434420266, -7.742947152888746],
              [-34.940898151528415, -7.743185770007244],
              [-34.940104944987183, -7.743380280775284],
              [-34.939437429493751, -7.743586533091957],
              [-34.939021766441329, -7.743992761356822],
              [-34.938666450596202, -7.74445549852237],
              [-34.938242613605667, -7.745235037167163],
              [-34.937775754847166, -7.746432324392231],
              [-34.936597113266146, -7.748924754074096],
              [-34.936309235782574, -7.749461830099335],
              [-34.936257660562546, -7.750230508531104],
              [-34.935295145854241, -7.769178109781876],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID: '268',
        AcaoPriori: 11.0,
        ImportBio_: 'Extremamente Alta',
        Prioridade: 'Extremamente Alta',
        COD_area: 'MA268',
        Shape_Leng: 5.15244,
        Area_ha: 282273.83432000002,
        Shape_Area: 0.23127,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.521378042, -7.541176702999962],
              [-35.521162204999897, -7.539068729999945],
              [-35.519706846, -7.535446036999935],
              [-35.518798573789738, -7.534166658966552],
              [-35.518333331917688, -7.534166658464471],
              [-35.518333332624913, -7.53351132753642],
              [-35.5182069641127, -7.533333326826055],
              [-35.51583333264216, -7.533333325972194],
              [-35.51583333174284, -7.53249999258054],
              [-35.514166666758229, -7.53249999347986],
              [-35.514166667657548, -7.531666659188943],
              [-35.513333332467255, -7.531666659188943],
              [-35.513333332467255, -7.5314027046526],
              [-35.512435276, -7.531470732999944],
              [-35.511406462, -7.530121538999944],
              [-35.510755708, -7.528250918999964],
              [-35.510605972, -7.526895330999935],
              [-35.510546017, -7.526352545999937],
              [-35.508996473, -7.523448449999924],
              [-35.50879342, -7.522080461999956],
              [-35.5091839419999, -7.517491597999943],
              [-35.506990684999899, -7.515182326999925],
              [-35.50506544148935, -7.514166658864156],
              [-35.504166666958042, -7.514166658864156],
              [-35.504166666702218, -7.513692555897742],
              [-35.501926873999899, -7.512511109999924],
              [-35.503019063, -7.511133066999953],
              [-35.504007913, -7.507973717999954],
              [-35.503829202134526, -7.507499993529848],
              [-35.503333331767806, -7.507499993529848],
              [-35.503333332713488, -7.506185549228443],
              [-35.502886086149019, -7.504999993771601],
              [-35.502499999275528, -7.504999993354943],
              [-35.502500000379996, -7.503976562566906],
              [-35.502483473, -7.503932751999977],
              [-35.502149367231226, -7.503333326571692],
              [-35.501666665883874, -7.503333326571692],
              [-35.501666665883874, -7.502467303027915],
              [-35.501428389, -7.502039805999974],
              [-35.500755924791825, -7.500833326396844],
              [-35.5, -7.500833326396844],
              [-35.5, -7.499999993005247],
              [-35.499166665709026, -7.499999993005247],
              [-35.499166666273887, -7.498429747763912],
              [-35.499085592, -7.49817221799998],
              [-35.49906806, -7.497850794999974],
              [-35.49906723, -7.497835579999952],
              [-35.499049847, -7.497516880999965],
              [-35.499047163826184, -7.497499992830342],
              [-35.498333332317372, -7.497499992830342],
              [-35.498333333801789, -7.494748998787895],
              [-35.4980995479483, -7.49416665926384],
              [-35.497499998925775, -7.49416665926384],
              [-35.497499999402294, -7.492400446099713],
              [-35.497430078, -7.492142346999978],
              [-35.497342858, -7.49200166199995],
              [-35.497318133, -7.491961780999929],
              [-35.496858851999903, -7.491220961999933],
              [-35.495636423999898, -7.490871696999919],
              [-35.493657255999899, -7.490813485999976],
              [-35.492958726, -7.489969428999925],
              [-35.493053169416449, -7.48916665966422],
              [-35.492499998576022, -7.489166659813463],
              [-35.492499999475342, -7.486666659638558],
              [-35.493333331967676, -7.486666660537935],
              [-35.493333331967676, -7.485833326246961],
              [-35.494166667157913, -7.485833326246961],
              [-35.494166668956552, -7.484166658564391],
              [-35.494900696915714, -7.484166659356545],
              [-35.494926452999898, -7.483911513999941],
              [-35.494312127999898, -7.482205558999957],
              [-35.491983694999902, -7.480983131999951],
              [-35.490412002, -7.479295017999958],
              [-35.488977927, -7.478205119999933],
              [-35.488956732, -7.478189011999967],
              [-35.486919353, -7.478014378999944],
              [-35.48601638, -7.477224277999943],
              [-35.485987979, -7.477199427999959],
              [-35.484314417999897, -7.477439546999959],
              [-35.482753753999901, -7.478746890999965],
              [-35.480859914999897, -7.478061517999942],
              [-35.48018837, -7.477818488999957],
              [-35.476081848, -7.476230143999945],
              [-35.475575082, -7.475622498999932],
              [-35.474210621999902, -7.473986422999932],
              [-35.4720901439999, -7.47144383199998],
              [-35.471713408, -7.470189420999926],
              [-35.471229552999901, -7.468578337999932],
              [-35.463516234999901, -7.462353228999967],
              [-35.458235966, -7.464109266999967],
              [-35.453811942999899, -7.462595785999954],
              [-35.45036981, -7.453411423999975],
              [-35.448686473, -7.453869563999933],
              [-35.447864952, -7.454228266999964],
              [-35.441572939, -7.456975565999924],
              [-35.438962466, -7.458971430999945],
              [-35.437660302, -7.460553185999972],
              [-35.437073575999896, -7.462591070999962],
              [-35.437570580999903, -7.466763680999976],
              [-35.434412369, -7.470790146999946],
              [-35.434396652, -7.470803651999979],
              [-35.432603442, -7.472344622999971],
              [-35.42982469, -7.47305932699993],
              [-35.42982254, -7.473058265999942],
              [-35.428857187999903, -7.472581867999961],
              [-35.428853267, -7.472581929999927],
              [-35.427516963999899, -7.472603082999979],
              [-35.424714329, -7.474422954999963],
              [-35.424390259999903, -7.474324125999933],
              [-35.422358508999899, -7.47370451399996],
              [-35.421643366, -7.473486420999961],
              [-35.420996895, -7.473464094999939],
              [-35.420871173, -7.473459752999934],
              [-35.420802543, -7.473457987999948],
              [-35.419625126, -7.473416720999989],
              [-35.415737664999902, -7.472155681999935],
              [-35.415732659, -7.472154549999965],
              [-35.414605434999899, -7.471899595999963],
              [-35.4128750839999, -7.470578708999946],
              [-35.409832061, -7.46280200499996],
              [-35.408239349, -7.461132327999959],
              [-35.404242484999898, -7.459844785999958],
              [-35.399988204, -7.457372866999954],
              [-35.397233564, -7.456418050999964],
              [-35.392215811, -7.456000618999952],
              [-35.39001482, -7.453229064999962],
              [-35.385495455999902, -7.449583068999967],
              [-35.382631898999897, -7.449129719999977],
              [-35.380687726, -7.448822351999974],
              [-35.380683983, -7.448822401999958],
              [-35.375960031, -7.448885232999948],
              [-35.373270251, -7.443647688999923],
              [-35.370548223999897, -7.441595264999925],
              [-35.368954429, -7.440802286999941],
              [-35.368351657999902, -7.440631997999954],
              [-35.368347631999903, -7.440632399999972],
              [-35.365741734, -7.440892450999964],
              [-35.365465690999898, -7.440920029999973],
              [-35.362906543, -7.442798215999968],
              [-35.362903065999902, -7.442797264999973],
              [-35.358184654, -7.441506931999984],
              [-35.3581802429999, -7.441507204999942],
              [-35.355405350999902, -7.441678707999931],
              [-35.355402977, -7.441678301999957],
              [-35.352991629, -7.44126571499993],
              [-35.351103357999897, -7.440261473999956],
              [-35.349688402, -7.438800642999972],
              [-35.348598588999899, -7.434941650999961],
              [-35.351513651999902, -7.429065385999933],
              [-35.352632499, -7.424162072999932],
              [-35.352355866, -7.422397656999987],
              [-35.351234173, -7.421902307999968],
              [-35.351229606999901, -7.421902835999959],
              [-35.348150470999897, -7.422259090999941],
              [-35.346266327, -7.422739390999937],
              [-35.346261195999901, -7.422738830999958],
              [-35.34383174700001, -7.422473680999926],
              [-35.342964907, -7.422156788999931],
              [-35.342621560999902, -7.422031271999963],
              [-35.341017043, -7.421444705999934],
              [-35.3386311249999, -7.421308227999921],
              [-35.333517897, -7.422756658999954],
              [-35.333517085, -7.422754840999971],
              [-35.331907446999899, -7.419148135999936],
              [-35.332893522999903, -7.415332944999929],
              [-35.332987547, -7.413302278999944],
              [-35.335042223, -7.410864046999956],
              [-35.335020606999898, -7.406803897999954],
              [-35.330830702, -7.406604261999965],
              [-35.327541932, -7.410108401999929],
              [-35.324098267, -7.411320060999963],
              [-35.324093145999903, -7.411319380999938],
              [-35.320961342, -7.410903443999932],
              [-35.319320220999899, -7.410053857999968],
              [-35.317826543, -7.406946419999942],
              [-35.316156931, -7.405689933999949],
              [-35.316151155999897, -7.405690102999934],
              [-35.315070308, -7.405721682999973],
              [-35.3140412939999, -7.405752],
              [-35.312158044999897, -7.406744386999949],
              [-35.311102687, -7.408075269999931],
              [-35.309048598999901, -7.409043879999964],
              [-35.309044669999899, -7.40904249299997],
              [-35.306957498, -7.408305319999948],
              [-35.306516626, -7.407916330999979],
              [-35.301874371999901, -7.403810014999974],
              [-35.299105213, -7.403152774999971],
              [-35.295882708, -7.401374509999925],
              [-35.290800223, -7.397550943999923],
              [-35.284710005, -7.391264987999935],
              [-35.280175749, -7.387655348999934],
              [-35.276628784, -7.384918954999933],
              [-35.272881025999901, -7.383108107999926],
              [-35.264393492, -7.379908575999931],
              [-35.257307400999899, -7.378210649999973],
              [-35.249104948, -7.374941898999961],
              [-35.248594676, -7.374779551999942],
              [-35.246538309, -7.37552028699997],
              [-35.246493479, -7.375536434999963],
              [-35.246233654, -7.375943117999953],
              [-35.24603782, -7.375950797999949],
              [-35.244410804, -7.376014601999934],
              [-35.242200711, -7.376101272999968],
              [-35.241933157999902, -7.376004845999936],
              [-35.240607941, -7.375527229999927],
              [-35.240223779, -7.375388774999973],
              [-35.239855867, -7.37554542499992],
              [-35.238753480999897, -7.376014800999945],
              [-35.238568133999898, -7.376093717999931],
              [-35.237331809, -7.376620121999954],
              [-35.235925994, -7.377045637999963],
              [-35.235696355, -7.377115145999935],
              [-35.2346847049999, -7.377421353999984],
              [-35.234433123, -7.377507761999948],
              [-35.233400537, -7.377862764999964],
              [-35.230759238, -7.378190158999928],
              [-35.2293110049999, -7.378369669999921],
              [-35.228962112, -7.378436325999929],
              [-35.227356600999897, -7.37874306099996],
              [-35.224574533, -7.379274577999976],
              [-35.22195971, -7.380193641999939],
              [-35.219232557999902, -7.380582785999938],
              [-35.216741548, -7.380938233999935],
              [-35.209325754, -7.385497666999974],
              [-35.200102608999899, -7.387606097999941],
              [-35.199552805999907, -7.387731782999943],
              [-35.196404083999902, -7.391976554999932],
              [-35.195156578, -7.395637477999963],
              [-35.1939889659999, -7.396792713999968],
              [-35.190741325, -7.397891204999942],
              [-35.190738506999899, -7.397889417999981],
              [-35.189744262, -7.397258814999939],
              [-35.189409114999897, -7.397046246999922],
              [-35.186215411, -7.395020630999974],
              [-35.185889742, -7.39499384699997],
              [-35.184157571, -7.394851389999932],
              [-35.180806869, -7.395901841999944],
              [-35.180804252, -7.395900420999954],
              [-35.173911449999899, -7.392156333999934],
              [-35.1720666699999, -7.388040369999945],
              [-35.172060386999902, -7.388042213999934],
              [-35.158503261, -7.392020469999979],
              [-35.153788768, -7.39245671199996],
              [-35.153735082, -7.392729439999926],
              [-35.15058758, -7.393789649999962],
              [-35.148380232, -7.394533175999926],
              [-35.144244683999901, -7.395925313999955],
              [-35.144238393, -7.39592588899995],
              [-35.144045697, -7.395943518999962],
              [-35.134863297, -7.396783632999925],
              [-35.132734608, -7.398713468999963],
              [-35.132291869387281, -7.398973941080091],
              [-35.127162766848187, -7.403543922813753],
              [-35.119897304771868, -7.403762280778292],
              [-35.119602922, -7.403963828999967],
              [-35.119001033558249, -7.40378921753136],
              [-35.115162961008991, -7.403904567897352],
              [-35.114916999999899, -7.403965],
              [-35.114464365999901, -7.404087490999933],
              [-35.113876456, -7.404247265999968],
              [-35.113869931, -7.404249038999986],
              [-35.113107, -7.404456],
              [-35.112080999999897, -7.404696],
              [-35.111117, -7.404716],
              [-35.110172, -7.404977],
              [-35.110121278, -7.405025488999977],
              [-35.110013, -7.405129],
              [-35.109653, -7.405182],
              [-35.109045, -7.40531],
              [-35.108488, -7.405449],
              [-35.107964, -7.405526],
              [-35.106992997, -7.405517227999945],
              [-35.106856999999899, -7.405516],
              [-35.106287, -7.4054],
              [-35.105752, -7.405366],
              [-35.10518, -7.405267],
              [-35.104641, -7.405248],
              [-35.104564791, -7.405235274999939],
              [-35.10409, -7.405156],
              [-35.102629704, -7.404789456999931],
              [-35.102621878, -7.404787492999954],
              [-35.1026062789999, -7.404783576999922],
              [-35.102102, -7.404657],
              [-35.102091431, -7.404653700999966],
              [-35.101729904, -7.404540848999937],
              [-35.100465626, -7.404146201999946],
              [-35.100009768999897, -7.404003904999964],
              [-35.094168003842903, -7.40218038307927],
              [-35.091638294999903, -7.401390727999971],
              [-35.0884905529999, -7.401431757999943],
              [-35.083899932999898, -7.401491592999958],
              [-35.082009421, -7.401267280999959],
              [-35.079015829, -7.401174695999937],
              [-35.076423438999903, -7.400650045999948],
              [-35.070520761999902, -7.398760992999931],
              [-35.070340716, -7.398875659999932],
              [-35.067253251, -7.400853021999977],
              [-35.065521536999903, -7.401962094999932],
              [-35.063043056, -7.406711467999962],
              [-35.054491614, -7.412298829999944],
              [-35.048100037, -7.417811528999946],
              [-35.040473603, -7.421762800999931],
              [-35.034824838, -7.423378033999938],
              [-35.029556317999898, -7.425820611999938],
              [-35.020321748999898, -7.435923973999935],
              [-35.018400659999898, -7.439134509999979],
              [-35.016100583, -7.441935566999963],
              [-35.014600281, -7.443453157999953],
              [-35.011625963, -7.445165707999935],
              [-35.00979725, -7.446218360999981],
              [-35.006788968, -7.448588628999963],
              [-35.00274452, -7.453529395999967],
              [-34.996129762, -7.457259617999975],
              [-34.992768254999909, -7.458822844999935],
              [-34.989146854, -7.459769441999979],
              [-34.987030853, -7.461039262999973],
              [-34.985418675999902, -7.462006737999964],
              [-34.985416785, -7.462009440999963],
              [-34.984350111, -7.463533736999973],
              [-34.98384949, -7.466834755999973],
              [-34.98420959, -7.469321254999956],
              [-34.98826446, -7.476165196999943],
              [-34.990178471, -7.488878387999928],
              [-34.9874859, -7.490879496999986],
              [-34.98621186, -7.493419303999934],
              [-34.98542787, -7.498211355999955],
              [-34.985133676, -7.500009585999987],
              [-34.985160388999901, -7.500804855999956],
              [-34.9851619679999, -7.500851857999976],
              [-34.985173275, -7.501186205999944],
              [-34.9852654439999, -7.50391157399997],
              [-34.9842275, -7.507888275999954],
              [-34.983592697999903, -7.508431241999973],
              [-34.979065561, -7.509697464999935],
              [-34.976734844, -7.511990193999965],
              [-34.976393503999908, -7.513586224999919],
              [-34.973479722, -7.516035969999962],
              [-34.972021250999902, -7.518450239999938],
              [-34.96944086, -7.521065455999971],
              [-34.9660448509999, -7.527336394999962],
              [-34.962768894, -7.535575582999975],
              [-34.960786894, -7.538766049999948],
              [-34.959346334, -7.539133100999952],
              [-34.954675016, -7.539043227999973],
              [-34.954671888999897, -7.539042130999976],
              [-34.950620914999902, -7.53762209299991],
              [-34.939539209, -7.530049232999941],
              [-34.93566571, -7.528646888999962],
              [-34.935660255, -7.52864665699998],
              [-34.932702013, -7.528521164999976],
              [-34.932677639, -7.528520133999955],
              [-34.92687907, -7.531011845999959],
              [-34.924447605, -7.53261028199994],
              [-34.923028772999899, -7.533977667999977],
              [-34.919579046101433, -7.53868276145247],
              [-34.919837870121569, -7.53873767680227],
              [-34.920991731782976, -7.539811023959544],
              [-34.921003392392606, -7.539827490546202],
              [-34.921018125086334, -7.539841195314864],
              [-34.921939484119832, -7.541142419886739],
              [-34.922688988106245, -7.542487136572049],
              [-34.923372689597898, -7.544236582352369],
              [-34.925076306826043, -7.547329536129155],
              [-34.926016112753359, -7.548853816850112],
              [-34.927202122479912, -7.549624249859221],
              [-34.928060055027458, -7.550477502334843],
              [-34.928930834390428, -7.550423742661565],
              [-34.929288872483426, -7.549883183562031],
              [-34.929450255824577, -7.54961431235057],
              [-34.930127070308004, -7.548961922554611],
              [-34.930801852323555, -7.548689351532289],
              [-34.931680616766982, -7.548685464662412],
              [-34.932290474925423, -7.549019763351339],
              [-34.933372639935328, -7.549149775641581],
              [-34.934858873038536, -7.548940977344159],
              [-34.935803419292313, -7.548532408144069],
              [-34.936552012265508, -7.54810509797187],
              [-34.93728907323208, -7.548188800572632],
              [-34.937311672295777, -7.548218570830329],
              [-34.937315480025347, -7.548219002504936],
              [-34.937723445780364, -7.548756399588115],
              [-34.938471509952194, -7.549764093538045],
              [-34.938612409435109, -7.55104408151908],
              [-34.93934306543008, -7.552093736936342],
              [-34.940554105087813, -7.552757528337224],
              [-34.9420696560897, -7.55225850622503],
              [-34.943967190533385, -7.552050824885612],
              [-34.945868715268944, -7.552739654311551],
              [-34.947972540700164, -7.553975423526936],
              [-34.950258755836842, -7.554584648562638],
              [-34.952162807667378, -7.554565627901354],
              [-34.953775862266184, -7.554558412640574],
              [-34.955165671858254, -7.554730893615783],
              [-34.956288645404641, -7.555351316110318],
              [-34.957320818900655, -7.555704101263188],
              [-34.958582645072966, -7.555527408762885],
              [-34.958906565585494, -7.555398818300773],
              [-34.959269978926329, -7.555148527982112],
              [-34.959910117258062, -7.554740492979306],
              [-34.960503778626446, -7.554489154950488],
              [-34.960829795458665, -7.554449434593664],
              [-34.961252503799983, -7.554581448774798],
              [-34.961579610610499, -7.554790389165078],
              [-34.962041223623544, -7.55503698057214],
              [-34.962713529805512, -7.555206110673396],
              [-34.963481346686763, -7.555279148214083],
              [-34.964056612422667, -7.55520005553808],
              [-34.964535416875947, -7.555006623956501],
              [-34.964936944384419, -7.554698753644516],
              [-34.965223197692353, -7.554353164866598],
              [-34.965605122678198, -7.553949741587246],
              [-34.966024817391371, -7.553412278853557],
              [-34.966043865931624, -7.553451825641218],
              [-34.966051160332768, -7.553442482584444],
              [-34.967528046781865, -7.556508843408324],
              [-34.967865386977962, -7.557371691650189],
              [-34.968318630900058, -7.557755526796086],
              [-34.968793329048879, -7.558090382165346],
              [-34.969604200073718, -7.558019323133237],
              [-34.970144052306125, -7.557814668911817],
              [-34.971696346009537, -7.557268476459001],
              [-34.973181325457745, -7.556789967882651],
              [-34.973855457263483, -7.556382516539884],
              [-34.975474718083717, -7.555701190157777],
              [-34.97642016905553, -7.555494717507145],
              [-34.9773668026358, -7.555557815740542],
              [-34.978248629270809, -7.556227806168295],
              [-34.978268107686972, -7.556252818113023],
              [-34.978274950628418, -7.556258017993116],
              [-34.978954887557961, -7.557131133794769],
              [-34.978958559489911, -7.557939893211483],
              [-34.979111544961768, -7.559410733518519],
              [-34.96755737007652, -7.561171632165042],
              [-34.967892132815621, -7.561980323233286],
              [-34.968016062990898, -7.562759982387206],
              [-34.96782531948287, -7.563470931538234],
              [-34.967644773387519, -7.564419553519542],
              [-34.967701648312357, -7.565314438310622],
              [-34.967864855478638, -7.566366811479099],
              [-34.968028645405639, -7.567550850791065],
              [-34.967927566104265, -7.56855176655074],
              [-34.967139439833261, -7.569450470762547],
              [-34.966585154381335, -7.569505629780963],
              [-34.965978551768728, -7.569666351221088],
              [-34.965715565720757, -7.569904491698878],
              [-34.965455204793841, -7.570721820755352],
              [-34.965352682979869, -7.571406809176835],
              [-34.964774016907313, -7.571909654307035],
              [-34.963981124229463, -7.571755264993442],
              [-34.963504145399952, -7.571388835326445],
              [-34.963132801837276, -7.571021916428265],
              [-34.963131495122354, -7.571016489919032],
              [-34.96310629701793, -7.570991591288873],
              [-34.962998640974433, -7.570544513118762],
              [-34.962889941717378, -7.569860459992185],
              [-34.962991866381458, -7.569043834205559],
              [-34.963068710752339, -7.568516943002464],
              [-34.963145556022539, -7.567990026618361],
              [-34.963090961778448, -7.567595355541187],
              [-34.962982958596626, -7.567069302506241],
              [-34.96287521082229, -7.566595865206978],
              [-34.962688228279092, -7.566122826307321],
              [-34.962606396268143, -7.565543965981192],
              [-34.96260426127759, -7.565070089812764],
              [-34.962708114987493, -7.564674697479234],
              [-34.962944330016796, -7.564357700847154],
              [-34.962995952900883, -7.564094183299858],
              [-34.962941127530939, -7.563646850621637],
              [-34.962763131912936, -7.56319557621481],
              [-34.96230192539349, -7.563248762120622],
              [-34.961561866087209, -7.563094139882594],
              [-34.961112381331304, -7.562964521495417],
              [-34.960557282892239, -7.562835371655012],
              [-34.960160954364483, -7.562784497906193],
              [-34.959765692432711, -7.562970577530109],
              [-34.959502729767109, -7.563208715309941],
              [-34.958100091346068, -7.563516843727314],
              [-34.957441843366325, -7.563941037748634],
              [-34.957153173580025, -7.564337271847592],
              [-34.95702338791898, -7.564838089905891],
              [-34.957025874544456, -7.565390969717441],
              [-34.957161238700166, -7.566127554942682],
              [-34.95726994335314, -7.566811588284111],
              [-34.957272197953557, -7.567311802897336],
              [-34.957115545046349, -7.567707428155245],
              [-34.956138992820229, -7.567817138250291],
              [-34.955636701672404, -7.567687754586132],
              [-34.955186961509014, -7.567505471899949],
              [-34.954447481366117, -7.56748246724203],
              [-34.953919368883703, -7.567484842351519],
              [-34.953206175924208, -7.567435388632248],
              [-34.952770687317297, -7.566997923315455],
              [-34.952769757418309, -7.566993314289959],
              [-34.952744177102034, -7.566967618860474],
              [-34.952620412402041, -7.566354462888114],
              [-34.952459275475121, -7.565749639134481],
              [-34.952297401104147, -7.564986843168242],
              [-34.95200387587812, -7.564303628209814],
              [-34.951716782603398, -7.563688004497749],
              [-34.951196946581774, -7.56357086060666],
              [-34.950540820102731, -7.563573816678229],
              [-34.950090285837973, -7.563698474405044],
              [-34.949662731050182, -7.564078012590187],
              [-34.949135454937277, -7.564264672276749],
              [-34.948529641929383, -7.564609659408234],
              [-34.948029954318883, -7.565059465222078],
              [-34.947503839230762, -7.565509411330197],
              [-34.946949902715801, -7.56564352273034],
              [-34.946263229864144, -7.565620262664936],
              [-34.945734303495271, -7.565438327117079],
              [-34.945721295701219, -7.565412676653636],
              [-34.945707777991515, -7.565408026259377],
              [-34.945521282880861, -7.565040271891007],
              [-34.945387735355666, -7.564698595163975],
              [-34.945386204709564, -7.56435633477696],
              [-34.945358014560668, -7.563961549485896],
              [-34.945307035591156, -7.56378653691911],
              [-34.945065783459086, -7.563571999148394],
              [-34.944643050836078, -7.563521244110063],
              [-34.944141596957081, -7.563576122539985],
              [-34.94377285693173, -7.563788393119808],
              [-34.943457038411566, -7.564026766522034],
              [-34.943036188968904, -7.564397246735496],
              [-34.942641997230339, -7.56482025455108],
              [-34.941798881912803, -7.565245263358179],
              [-34.940717551473767, -7.565539701396062],
              [-34.939475874611901, -7.565413577774336],
              [-34.938127771079735, -7.565103655209498],
              [-34.937413880246311, -7.564896203197179],
              [-34.936805720007953, -7.564714595002499],
              [-34.936144869390148, -7.564559576163447],
              [-34.93573200772596, -7.564589238502492],
              [-34.934993807318335, -7.564855807449533],
              [-34.934043194237972, -7.564860022571963],
              [-34.933171790745519, -7.56486390854252],
              [-34.931772042745536, -7.564817459458141],
              [-34.930478626986996, -7.564928496952518],
              [-34.929872465941514, -7.565194482239519],
              [-34.92913426463457, -7.565461027804133],
              [-34.928793089729254, -7.565936445410557],
              [-34.928268462119775, -7.566728630321961],
              [-34.927821790443204, -7.567230869309128],
              [-34.927110224357307, -7.567549942475807],
              [-34.926397240939878, -7.567553108988761],
              [-34.925525837447424, -7.567556973375588],
              [-34.924627565809487, -7.567455622478974],
              [-34.923122412769089, -7.567462258576313],
              [-34.922065813686288, -7.567387937703131],
              [-34.921378188452593, -7.567154036629461],
              [-34.920769447252155, -7.566840761391973],
              [-34.919896300873518, -7.56644967411313],
              [-34.918679864384217, -7.566060125574268],
              [-34.917966091361961, -7.565878959846088],
              [-34.917305124731683, -7.565697560294097],
              [-34.916934850462951, -7.565567548003912],
              [-34.916512233852472, -7.565543077451025],
              [-34.915984468508384, -7.565624381559928],
              [-34.915244385819733, -7.56546966669174],
              [-34.91458248839109, -7.565077647715214],
              [-34.913924039862479, -7.564598410688063],
              [-34.913209336941293, -7.564206624635972],
              [-34.91262586039511, -7.563629949662413],
              [-34.912254772239919, -7.563315627614088],
              [-34.911779116313141, -7.563238746370985],
              [-34.911118383506562, -7.563109991332965],
              [-34.910112870412604, -7.562640486768998],
              [-34.908949497521235, -7.562303300356973],
              [-34.908077746890456, -7.562228141315643],
              [-34.907152723119623, -7.562047883902665],
              [-34.906385328919725, -7.561682641340781],
              [-34.906370831848392, -7.561658112331997],
              [-34.906358684705481, -7.561652330590562],
              [-34.906172446800952, -7.561337195555097],
              [-34.906171192246688, -7.561047577883357],
              [-34.906170265944979, -7.560836959358141],
              [-34.906221240417892, -7.560415467799544],
              [-34.906246020337505, -7.560046758351177],
              [-34.906270939652075, -7.559704381951633],
              [-34.906142207996481, -7.55929985790209],
              [-34.906004782594607, -7.559209369916459],
              [-34.905502724371217, -7.559132580404196],
              [-34.905265419363559, -7.559212605677146],
              [-34.90500215272715, -7.559398054876283],
              [-34.904580698940038, -7.559636870744953],
              [-34.904397013311495, -7.559900970153592],
              [-34.904028830865798, -7.560244838528433],
              [-34.903503715823774, -7.560931691244491],
              [-34.903109106799775, -7.561275698114912],
              [-34.902714403346977, -7.561593374634469],
              [-34.902319678310448, -7.561911048456068],
              [-34.901713145844951, -7.562097988731125],
              [-34.901422685508464, -7.562099245983347],
              [-34.901185148475804, -7.562126626742383],
              [-34.900975152281433, -7.562417153628701],
              [-34.900845320754911, -7.562917974384959],
              [-34.900584355483602, -7.563629995527833],
              [-34.900375056263783, -7.564078497324772],
              [-34.900298032927822, -7.564579084257275],
              [-34.90043502125917, -7.565710644530554],
              [-34.900696342662059, -7.566455690577584],
              [-34.901120074431844, -7.566722828795491],
              [-34.901569558288429, -7.566852514631819],
              [-34.901887143976467, -7.567009091995942],
              [-34.902178647526512, -7.567244786317815],
              [-34.902417462495862, -7.567507023230519],
              [-34.902418261993148, -7.567508915404062],
              [-34.902444175957896, -7.567537369953641],
              [-34.902577585886831, -7.567852739712123],
              [-34.902824471372185, -7.568169864947265],
              [-34.903081420271292, -7.568372988522583],
              [-34.903425964936275, -7.568661092635296],
              [-34.903429049610907, -7.568671681253022],
              [-34.903452684693605, -7.568691443855016],
              [-34.903559947733356, -7.569059573240679],
              [-34.903481875787918, -7.569323208599144],
              [-34.903165918772117, -7.569535224670858],
              [-34.902876829901743, -7.569852434442225],
              [-34.902587276981194, -7.57006433360209],
              [-34.902166379874473, -7.570434791332502],
              [-34.901903344363802, -7.570672885944873],
              [-34.901481194501457, -7.570753723305643],
              [-34.900609767626634, -7.570757542726369],
              [-34.899606300490348, -7.570761919726749],
              [-34.898603879265579, -7.571003248301224],
              [-34.897495588950221, -7.571192378425451],
              [-34.896254470567328, -7.571197802236725],
              [-34.894881310124333, -7.571203786325611],
              [-34.893772229304886, -7.571208606691812],
              [-34.892742801439113, -7.571318386933967],
              [-34.891847039809022, -7.571796226414676],
              [-34.891215566445169, -7.572325544887917],
              [-34.890690521550312, -7.573038707270484],
              [-34.890659472456662, -7.574411201315854],
              [-34.890540371640782, -7.575311163679203],
              [-34.890010970429898, -7.576172012728136],
              [-34.889481567420376, -7.577032882461538],
              [-34.888868908773077, -7.577607904481169],
              [-34.888050098533199, -7.577938526741548],
              [-34.887298743742008, -7.578073917876964],
              [-34.88669241901988, -7.578313525149042],
              [-34.886113730464274, -7.578842611597224],
              [-34.885799353557275, -7.579423222903358],
              [-34.885303569004463, -7.580794482179556],
              [-34.88462052131996, -7.581613653946931],
              [-34.883789832834452, -7.582436014710254],
              [-34.882710315428653, -7.583177908633502],
              [-34.881892719273537, -7.583418424220838],
              [-34.880678862039815, -7.583634305578698],
              [-34.879544353788845, -7.583876170149097],
              [-34.878645919373639, -7.583748416955814],
              [-34.878116599101702, -7.583487412114323],
              [-34.877560222726061, -7.58306854927406],
              [-34.877162614463032, -7.582727986807072],
              [-34.876844357880771, -7.582413408451998],
              [-34.8763424849177, -7.582389240970656],
              [-34.875894346446898, -7.582575482472464],
              [-34.875076169329702, -7.582684331916312],
              [-34.874204463665023, -7.582635437575334],
              [-34.873675747737536, -7.582506076394282],
              [-34.87317420122838, -7.582245631830403],
              [-34.872562501258358, -7.582013521306919],
              [-34.871874200633783, -7.581895476295131],
              [-34.871327902960331, -7.581867560439491],
              [-34.870838416359788, -7.581887491214729],
              [-34.870213908245205, -7.581968049785644],
              [-34.86903362899875, -7.582049681247724],
              [-34.867819073891098, -7.582107564312594],
              [-34.866789040781555, -7.582085654129571],
              [-34.866206703676369, -7.581772217913453],
              [-34.865676172917006, -7.58122157111859],
              [-34.864776158392942, -7.580725174127451],
              [-34.863929276714714, -7.580307522673991],
              [-34.863003765511337, -7.580021861119974],
              [-34.862000158980095, -7.579999835823742],
              [-34.861102283043863, -7.580003677727518],
              [-34.860601198786242, -7.580163796621889],
              [-34.860206450367343, -7.580481448859757],
              [-34.859996315677392, -7.580745642697195],
              [-34.859311500825072, -7.581169861000262],
              [-34.858125627795459, -7.581754176613913],
              [-34.856912096116275, -7.582048987870451],
              [-34.856067815277413, -7.582236904809236],
              [-34.855197365066317, -7.582477584972537],
              [-34.853981787429518, -7.582298467000612],
              [-34.853082680321393, -7.582012666950959],
              [-34.852485299357056, -7.581906147650557],
              [-34.85195590893801, -7.581618762995447],
              [-34.851136010518417, -7.581332635592616],
              [-34.850211033512323, -7.581178596115251],
              [-34.849391600941601, -7.580997779324036],
              [-34.848413816644268, -7.58084394759004],
              [-34.847701598549918, -7.581031284466178],
              [-34.846884162474055, -7.58132439600405],
              [-34.846620757342009, -7.581483489671257],
              [-34.846147425263439, -7.581959443273661],
              [-34.845621287692211, -7.582435607317393],
              [-34.844961296826341, -7.582491068507977],
              [-34.844326268442501, -7.582204150601001],
              [-34.843532775017536, -7.581917884702591],
              [-34.842712225488754, -7.581473758107393],
              [-34.841971885593978, -7.581266259330334],
              [-34.841548688920795, -7.581110075869276],
              [-34.841099064769992, -7.58095398593764],
              [-34.840754753029387, -7.580718476876143],
              [-34.840169292578764, -7.580660339303165],
              [-34.839888520638681, -7.580494003395472],
              [-34.839867285846537, -7.580467023734116],
              [-34.839861438454591, -7.580463559545592],
              [-34.839487586682708, -7.579988560123923],
              [-34.839044877419099, -7.579687870898454],
              [-34.8385398163594, -7.579466657460216],
              [-34.837838105943774, -7.57910665434855],
              [-34.837303710797585, -7.578578430350206],
              [-34.837022474807327, -7.578300428322052],
              [-34.837021106039174, -7.578295401111802],
              [-34.836995400717115, -7.578269992566049],
              [-34.836881598706782, -7.577851664822447],
              [-34.836739786411954, -7.577433477373063],
              [-34.836710384876312, -7.577098550957317],
              [-34.836793492125935, -7.576765788310411],
              [-34.836791033379427, -7.576186552966988],
              [-34.836787602465847, -7.575370341767837],
              [-34.836758186541033, -7.574659556293454],
              [-34.836648329856473, -7.573659479601247],
              [-34.836645339610698, -7.572948578114335],
              [-34.836484089369208, -7.572291017216628],
              [-34.836480868896956, -7.571527471215802],
              [-34.836504839426766, -7.570948118960473],
              [-34.836419742876785, -7.570641876421405],
              [-34.836417841709988, -7.570190581330223],
              [-34.836474964847696, -7.569739052415287],
              [-34.83653220399799, -7.569307129620313],
              [-34.836549721892084, -7.568796903754048],
              [-34.836567169639125, -7.568267027701097],
              [-34.836612929842772, -7.567853705584014],
              [-34.83684288469135, -7.567545346940165],
              [-34.83697006501643, -7.56723742716548],
              [-34.837251402630102, -7.566928857180983],
              [-34.837424219951743, -7.566705416122034],
              [-34.836909468697002, -7.566354236258974],
              [-34.836514330871637, -7.565916768244222],
              [-34.836512874869243, -7.565914611669939],
              [-34.83648737549197, -7.565886380152278],
              [-34.836107423618671, -7.56532337217584],
              [-34.835790406302181, -7.564760107892596],
              [-34.8355473312443, -7.564325228126734],
              [-34.835224634709107, -7.564098516233514],
              [-34.834870072495789, -7.564524506200939],
              [-34.834280361849153, -7.56591050446616],
              [-34.834109805423111, -7.566503829488113],
              [-34.833751672001995, -7.567916838890937],
              [-34.833472589888061, -7.568885930341366],
              [-34.833196060949433, -7.570459917491178],
              [-34.832918511280241, -7.571958536956856],
              [-34.832762478905238, -7.573163789477235],
              [-34.832566675811393, -7.574697102988864],
              [-34.832370709940278, -7.57619011158431],
              [-34.832174396031519, -7.577602443797673],
              [-34.830664357274536, -7.577611208599043],
              [-34.830654394, -7.577641916999933],
              [-34.830451728, -7.578101486999969],
              [-34.830376181, -7.578259376999933],
              [-34.830297529999903, -7.578342322999956],
              [-34.830007604999899, -7.57887152399995],
              [-34.829859034, -7.579205436999924],
              [-34.829745674, -7.579607710999945],
              [-34.829744479999903, -7.579618721999956],
              [-34.829744002, -7.579623125999936],
              [-34.829704501, -7.579821620999956],
              [-34.829595782, -7.580119663999941],
              [-34.829593083, -7.580123830999985],
              [-34.82951616, -7.580273797999943],
              [-34.829451228, -7.580437792999933],
              [-34.829290494149319, -7.580694711198407],
              [-34.829585205592707, -7.580791456860197],
              [-34.830421747765399, -7.581247999495531],
              [-34.830926164011203, -7.58114993292304],
              [-34.83125912540828, -7.580880891739696],
              [-34.831538573546311, -7.58010309598103],
              [-34.83151156150933, -7.579910003443842],
              [-34.831501990024776, -7.57974208293075],
              [-34.831516943951783, -7.579646002960601],
              [-34.831545449762643, -7.579557008748907],
              [-34.831764753040886, -7.579762858169317],
              [-34.832102872949179, -7.580100531114567],
              [-34.832392949275118, -7.580298879289785],
              [-34.832682725227528, -7.580433900803826],
              [-34.83298431557364, -7.58054343283311],
              [-34.833279318385792, -7.580623728801982],
              [-34.83359309274698, -7.580671330817211],
              [-34.833916575289663, -7.580688170622523],
              [-34.834187782939807, -7.580628095909844],
              [-34.834463227296055, -7.580549436707088],
              [-34.834756652697308, -7.580449371840984],
              [-34.83506741882627, -7.580291360957517],
              [-34.835375859308442, -7.580006425156341],
              [-34.835772681563981, -7.579699364234216],
              [-34.836281926269862, -7.579578046589518],
              [-34.836817639822186, -7.579793441414154],
              [-34.83683765423433, -7.579821041607715],
              [-34.836844727402195, -7.579823884364714],
              [-34.837222258300528, -7.580344469521606],
              [-34.837366241558755, -7.580948180813834],
              [-34.837500659627722, -7.581632354449539],
              [-34.837657154254202, -7.582340975255761],
              [-34.837796485319473, -7.583049705779274],
              [-34.838239123536653, -7.583668143469993],
              [-34.838794136540116, -7.584144276936797],
              [-34.839394374650624, -7.584518564879829],
              [-34.84008465108576, -7.584704925991502],
              [-34.840749031542543, -7.584906822891526],
              [-34.841363912414579, -7.585195696823916],
              [-34.841969258674283, -7.585524742572773],
              [-34.842498626610279, -7.586069027563497],
              [-34.842505609846, -7.586078783409107],
              [-34.842525754659789, -7.586099495695123],
              [-34.842949369517726, -7.586691286473638],
              [-34.843576457787265, -7.587092523501099],
              [-34.843751661909607, -7.587166567383122],
              [-34.843936511260949, -7.587251615369723],
              [-34.844497291616676, -7.587464517273588],
              [-34.845458180350704, -7.58768495279827],
              [-34.845785992229708, -7.587338605891887],
              [-34.846923492525093, -7.58593104098469],
              [-34.849786792726263, -7.584176356653472],
              [-34.850037534504565, -7.584437732914978],
              [-34.850043007778538, -7.584445663136819],
              [-34.850064614890016, -7.584468188456071],
              [-34.850595827335496, -7.585238060288191],
              [-34.851082003531587, -7.585729443558932],
              [-34.851087102687586, -7.58573768134886],
              [-34.851109091111596, -7.585759905395264],
              [-34.851548051101645, -7.586469142237092],
              [-34.851990481575399, -7.587187052140678],
              [-34.852688821331981, -7.587657514482657],
              [-34.853302064538582, -7.5883032268124],
              [-34.853810356862425, -7.588237667134592],
              [-34.854495515255792, -7.588061512428794],
              [-34.85504605503138, -7.587859293571512],
              [-34.855752371774486, -7.587722208113348],
              [-34.856285534849519, -7.587687639972557],
              [-34.857025243420196, -7.587530170480932],
              [-34.857739392359008, -7.587395086913659],
              [-34.858389136349501, -7.58719644915675],
              [-34.859075015999167, -7.587264648344785],
              [-34.859793603092271, -7.587243413552642],
              [-34.860476637286922, -7.587154023639243],
              [-34.861150319431658, -7.587155015591463],
              [-34.86187341752418, -7.587157610135534],
              [-34.862570352539706, -7.587204256171447],
              [-34.863247616684134, -7.587273093878139],
              [-34.863938555020241, -7.587341746324512],
              [-34.864038068601928, -7.587371141564859],
              [-34.864604808764852, -7.587538286862411],
              [-34.865164698791773, -7.58795495265889],
              [-34.865754803341474, -7.588370280264201],
              [-34.866515954447721, -7.588653372455155],
              [-34.867202958249891, -7.588815584971201],
              [-34.867897802341645, -7.588929738616514],
              [-34.868576703252188, -7.589159054946379],
              [-34.869271982615885, -7.589206408748737],
              [-34.869970798113798, -7.589253244541567],
              [-34.870653018422047, -7.589345363897394],
              [-34.871327295917922, -7.589346337863162],
              [-34.872007946909207, -7.589524801128618],
              [-34.872754654004041, -7.589756292918537],
              [-34.873804941644721, -7.589933437777859],
              [-34.874108877422714, -7.589984670356216],
              [-34.874693961956723, -7.590176112637437],
              [-34.874964630013608, -7.590479194958846],
              [-34.874965362061744, -7.590480219286633],
              [-34.874991663634319, -7.59050967118435],
              [-34.875248367018457, -7.590869088837337],
              [-34.875686585067797, -7.591112906735191],
              [-34.876207082091128, -7.591423239390963],
              [-34.876691775305119, -7.591621000308862],
              [-34.877168791006852, -7.591769587196666],
              [-34.877611957126078, -7.591913959861301],
              [-34.878083951912799, -7.592129428430326],
              [-34.878564032504073, -7.592278947915077],
              [-34.879028791345604, -7.592423622751937],
              [-34.879467528303735, -7.592633540705265],
              [-34.880001891973677, -7.592916437743384],
              [-34.880511834553488, -7.593200146869208],
              [-34.880847512802404, -7.593386942353447],
              [-34.881266717385074, -7.593665517249747],
              [-34.88166896255251, -7.593947323410191],
              [-34.882078131600451, -7.594210699763948],
              [-34.882554812754336, -7.594451454570901],
              [-34.883182422630682, -7.59476089779713],
              [-34.883745196783366, -7.59504724283596],
              [-34.884241752954495, -7.595264146722968],
              [-34.884664164519506, -7.59545373999805],
              [-34.885108854090277, -7.595689168120543],
              [-34.885548475082032, -7.595921917162684],
              [-34.88595043966103, -7.596226563405139],
              [-34.886381991035989, -7.596443503264993],
              [-34.886837642443197, -7.596718024916868],
              [-34.88718103687529, -7.597033033147909],
              [-34.887502006712168, -7.597106768562469],
              [-34.887886765461815, -7.597355756662807],
              [-34.888390300372009, -7.597596041124348],
              [-34.88911948687479, -7.597822174753503],
              [-34.895097017013654, -7.598413424140176],
              [-34.902507329949287, -7.600553576786069],
              [-34.902489296743624, -7.60057109198226],
              [-34.902534341986268, -7.600584100675633],
              [-34.900776465061767, -7.602291531928586],
              [-34.898572191653273, -7.603280000171821],
              [-34.897913719742348, -7.604067948377065],
              [-34.896747210915009, -7.604188324431561],
              [-34.896727611090341, -7.606145432665528],
              [-34.896164323424728, -7.607138431693215],
              [-34.895057236402295, -7.607683564744661],
              [-34.895223239560835, -7.609207655708701],
              [-34.895540570740707, -7.610417811332866],
              [-34.896350078393368, -7.611736487249061],
              [-34.896966230007479, -7.612591449335866],
              [-34.897226476720505, -7.613144266194865],
              [-34.897533919854482, -7.613724852320047],
              [-34.897968597272893, -7.614387641876135],
              [-34.898263623467415, -7.615084576891661],
              [-34.898515394969479, -7.615760066673715],
              [-34.898676968067605, -7.616479833677317],
              [-34.898839857773169, -7.61725535774849],
              [-34.899007512086996, -7.617900786791836],
              [-34.899245747892905, -7.618576765805074],
              [-34.899557648066946, -7.619141638975407],
              [-34.900188225706017, -7.619733749912598],
              [-34.900209456001562, -7.61975877354854],
              [-34.900215424801956, -7.619764376324838],
              [-34.900544472349452, -7.62015221615178],
              [-34.90063868532701, -7.620618929220143],
              [-34.900716215880607, -7.621220099927598],
              [-34.900792294928408, -7.621409917133917],
              [-34.901466211796219, -7.621911955572273],
              [-34.901474565598676, -7.621928547164657],
              [-34.901493422583314, -7.621942594575046],
              [-34.901650536842737, -7.622254616157591],
              [-34.901763673354822, -7.622579678809075],
              [-34.901724865809854, -7.622735435991558],
              [-34.901514042239228, -7.623152518174152],
              [-34.901401176423121, -7.623425027143242],
              [-34.90130117271093, -7.623520023430274],
              [-34.900808917097777, -7.623656603469442],
              [-34.900397688602425, -7.623732710396212],
              [-34.900126674306534, -7.623703630817886],
              [-34.899749571485472, -7.623769319998075],
              [-34.899553066920475, -7.623875188189345],
              [-34.899405471085572, -7.624043399183506],
              [-34.89931803000303, -7.624334637433549],
              [-34.899334251974096, -7.624645068115342],
              [-34.899329359662147, -7.624843503524801],
              [-34.899311759929731, -7.625316575699344],
              [-34.899179427388617, -7.626172945225221],
              [-34.899036885743612, -7.627017775549859],
              [-34.898789193566358, -7.627628484466925],
              [-34.898102032382837, -7.628789373430777],
              [-34.897598771765843, -7.629614423167027],
              [-34.896477176880182, -7.631045178891839],
              [-34.895690329445131, -7.632062261763906],
              [-34.895374252819465, -7.632630361701786],
              [-34.89532172341876, -7.63310538900248],
              [-34.895197020725902, -7.634150597272196],
              [-34.895182363575202, -7.635583129158022],
              [-34.895188243342716, -7.637208733796001],
              [-34.895055081626651, -7.639072849432409],
              [-34.895229172388326, -7.640038962328219],
              [-34.895421788284864, -7.641173447196763],
              [-34.895700311020505, -7.642570447767923],
              [-34.895748011061812, -7.645567934912208],
              [-34.895757042053788, -7.646056124690347],
              [-34.896635930603679, -7.647506457756776],
              [-34.897095945521414, -7.648747258679009],
              [-34.897782542830043, -7.650431477530617],
              [-34.898838250684662, -7.652923137392804],
              [-34.898979669076425, -7.653764015197112],
              [-34.898922134948521, -7.654554193721026],
              [-34.898991250545748, -7.655150456828096],
              [-34.899478043676709, -7.656348040829357],
              [-34.899594331413198, -7.657189575138716],
              [-34.899540936864696, -7.658105692923357],
              [-34.899195404744091, -7.65986848374348],
              [-34.898828363538087, -7.660919847872663],
              [-34.898894369279674, -7.661845356378024],
              [-34.898819396397982, -7.662472884416047],
              [-34.898886875229039, -7.66347827700082],
              [-34.899263094016533, -7.664890327726368],
              [-34.900241381034846, -7.667416598896864],
              [-34.900850586285458, -7.668855975522831],
              [-34.901880209304181, -7.671182337294965],
              [-34.902668769048432, -7.673211205132986],
              [-34.903584105322182, -7.675421323235412],
              [-34.904499993779666, -7.677227232950372],
              [-34.90559289759409, -7.67914386359837],
              [-34.906089491536761, -7.680001274539165],
              [-34.907189474814345, -7.681088070158239],
              [-34.908214107495041, -7.681846976556699],
              [-34.908877614710093, -7.68197618125572],
              [-34.910104273793138, -7.682154019492373],
              [-34.911103364828364, -7.682149571445507],
              [-34.911918289997061, -7.682020876662079],
              [-34.912816352092932, -7.681760772941288],
              [-34.91353851668913, -7.6812323807697],
              [-34.914070849689892, -7.680562966807429],
              [-34.914379865738169, -7.679370981985187],
              [-34.91484412365736, -7.677483808630825],
              [-34.915476774233696, -7.676325102321584],
              [-34.916882846266276, -7.674320511682197],
              [-34.919065048513119, -7.672422225405342],
              [-34.921757299459784, -7.670435673367592],
              [-34.923733057834283, -7.668749340209843],
              [-34.925709855825119, -7.667233839569974],
              [-34.927256653771508, -7.666156125304894],
              [-34.927669106244196, -7.665896552184108],
              [-34.928047894394751, -7.665894853364762],
              [-34.928762873407834, -7.666055998385559],
              [-34.930054802587051, -7.666809197686291],
              [-34.933404798792367, -7.668707575694043],
              [-34.933398499041459, -7.668719369403334],
              [-34.933432265886324, -7.668738504278508],
              [-34.932936941786409, -7.669665836608999],
              [-34.929228433545347, -7.67528442663496],
              [-34.928023577625936, -7.677784050893194],
              [-34.923163760898831, -7.685466377271439],
              [-34.922923219231166, -7.685888369752433],
              [-34.922679360863754, -7.686933163434674],
              [-34.922669770493485, -7.687869983612813],
              [-34.921930411759035, -7.698136219610774],
              [-34.921947140048417, -7.698821653196717],
              [-34.922446045248762, -7.6999380509003],
              [-34.922873944476919, -7.700744304805767],
              [-34.927927867277219, -7.70797526684504],
              [-34.928461296551575, -7.708791758632685],
              [-34.929242483653638, -7.709496857693807],
              [-34.935596782065772, -7.714943871462424],
              [-34.935616150764702, -7.714967928327212],
              [-34.935624188005818, -7.714974818932717],
              [-34.93632279935764, -7.715842586466124],
              [-34.936989090873794, -7.716498807374023],
              [-34.937231446474016, -7.716737502733508],
              [-34.940244033235501, -7.715987296376568],
              [-34.942229403564056, -7.722931737109946],
              [-34.945161157461428, -7.726944457220895],
              [-34.94639612178355, -7.731265065630225],
              [-34.954647141652231, -7.740772988782339],
              [-34.964605785228741, -7.742222239065029],
              [-34.968618544909873, -7.745153965083375],
              [-34.972939191090745, -7.746388915016382],
              [-34.982447196081182, -7.754639867435912],
              [-34.984091440967177, -7.765936813732935],
              [-35.014606193421059, -7.767222340638455],
              [-35.018618948605535, -7.770154077448694],
              [-35.020779269447701, -7.770771555562817],
              [-35.022989776057273, -7.770204272209355],
              [-35.032359755798041, -7.761855340451575],
              [-35.034166746498101, -7.754813223451436],
              [-35.043823259235978, -7.753537604376902],
              [-35.048978395336633, -7.749542173732266],
              [-35.051083444745132, -7.741340386349691],
              [-35.056069038855014, -7.745204270460647],
              [-35.058279546363906, -7.745771564606002],
              [-35.064870715411132, -7.746124903741247],
              [-35.080855266357389, -7.762085001221806],
              [-35.094037606250481, -7.762791680391672],
              [-35.099588454843285, -7.768314712953611],
              [-35.100299856353217, -7.773196189852229],
              [-35.109993664875674, -7.782916093103154],
              [-35.112399854969283, -7.783120840854053],
              [-35.112662199800639, -7.782954998674199],
              [-35.112968677062725, -7.782857801746104],
              [-35.114674202652282, -7.782466620937782],
              [-35.1165730150326, -7.782361478499411],
              [-35.116745194734904, -7.78226494976758],
              [-35.116802364637351, -7.782188127879749],
              [-35.117218858663364, -7.781076265457216],
              [-35.11737118583153, -7.780845896920539],
              [-35.117600536335658, -7.780672544502295],
              [-35.118426524964093, -7.780427142898304],
              [-35.118438615449691, -7.780462238941141],
              [-35.118453192560764, -7.780457907806181],
              [-35.118796269532197, -7.781453745993531],
              [-35.119105907911262, -7.78245939218715],
              [-35.119290158314129, -7.782668066378108],
              [-35.122522241708054, -7.77945846873331],
              [-35.137446804026752, -7.779105174564108],
              [-35.138026647311904, -7.772740539344341],
              [-35.14103366996784, -7.768803086193429],
              [-35.142193350242849, -7.764407182945092],
              [-35.145200373798161, -7.760469727995485],
              [-35.146360064865064, -7.740925854644445],
              [-35.142193370927259, -7.73546962282478],
              [-35.141033699645504, -7.72821725871944],
              [-35.149367105507451, -7.71880290013371],
              [-35.1506770058391, -7.709132519849845],
              [-35.157028104463393, -7.707691754670691],
              [-35.158280348359369, -7.712438275705836],
              [-35.159131285980038, -7.715754195699503],
              [-35.162447047693036, -7.716604966945624],
              [-35.16244705398833, -7.708271597955843],
              [-35.162800385929017, -7.705847136336217],
              [-35.176658521373611, -7.691963398991618],
              [-35.18082673966228, -7.691251708799257],
              [-35.185022755995362, -7.695418442307243],
              [-35.187447222111587, -7.69577179942894],
              [-35.187093889271523, -7.698196256551967],
              [-35.183633849322632, -7.701680656437077],
              [-35.183280507489371, -7.712438479851926],
              [-35.183280502093453, -7.716605161199197],
              [-35.187447190635282, -7.716605208863257],
              [-35.189607337908285, -7.71722270856111],
              [-35.197787096397349, -7.724321215751445],
              [-35.210440763530357, -7.725556306877991],
              [-35.226953873022751, -7.740988252305954],
              [-35.231274163072101, -7.742223245406421],
              [-35.243620583123345, -7.753488455527759],
              [-35.256274241263156, -7.754723532265132],
              [-35.26028731210971, -7.757655301450939],
              [-35.264607598561781, -7.758890291853447],
              [-35.268620666710376, -7.761822057441975],
              [-35.275416617682708, -7.763057072126117],
              [-35.281197533539284, -7.758834512302826],
              [-35.285287403790619, -7.761822225615163],
              [-35.302107742378382, -7.763057341922718],
              [-35.314454139946577, -7.774322518769168],
              [-35.318774422801312, -7.775557504675078],
              [-35.323788803310606, -7.779220676293107],
              [-35.325565244040718, -7.785433730782017],
              [-35.32849661842414, -7.789446476973296],
              [-35.330085883256857, -7.795005094809255],
              [-35.334214441443294, -7.795606018203102],
              [-35.334283978822498, -7.794777443124474],
              [-35.334813105740182, -7.793130922953708],
              [-35.335386928064224, -7.792315276528768],
              [-35.335785986034409, -7.79200091850754],
              [-35.336670391924997, -7.791589253840925],
              [-35.337350052762702, -7.791405003438115],
              [-35.338376187311155, -7.791322492439008],
              [-35.339764925810641, -7.791505897479112],
              [-35.343154091088365, -7.792367234859967],
              [-35.344022114928634, -7.792444427268435],
              [-35.345235015283663, -7.792289070284312],
              [-35.346643936368537, -7.79178699677243],
              [-35.349380043654037, -7.789809581846669],
              [-35.352276219471889, -7.788150494549654],
              [-35.353228108690246, -7.788407884116509],
              [-35.354653768856281, -7.789173516544508],
              [-35.358548149877151, -7.791854076304503],
              [-35.359049112726325, -7.788412391518591],
              [-35.36460772156903, -7.786823193235705],
              [-35.370747031650126, -7.781913021668572],
              [-35.367231948695405, -7.777101025491902],
              [-35.365996705583427, -7.770304746266959],
              [-35.370894984515871, -7.763599442460247],
              [-35.377107863636979, -7.761823421713473],
              [-35.381120942577411, -7.758891767640932],
              [-35.383750207705873, -7.757656866271304],
              [-35.389454254010559, -7.761823622262284],
              [-35.3947814250227, -7.763058652234974],
              [-35.396549324891396, -7.750913037306759],
              [-35.39994780264891, -7.749941277567871],
              [-35.400608963532818, -7.757375024138014],
              [-35.406324894378315, -7.758841992863609],
              [-35.410237224068567, -7.761874144376236],
              [-35.415763434834616, -7.763292282612724],
              [-35.417181612641286, -7.768818694826848],
              [-35.424380299810935, -7.776897821876958],
              [-35.425514839538153, -7.781318814411691],
              [-35.428546894823285, -7.785231213349789],
              [-35.429681364403393, -7.79798553080758],
              [-35.431749757445289, -7.800654474924045],
              [-35.432046420405925, -7.799447119788681],
              [-35.432458960112854, -7.798481267696275],
              [-35.433591846886202, -7.796827352400328],
              [-35.436090427031559, -7.793815010254491],
              [-35.437049607952986, -7.792108536779494],
              [-35.438466648117299, -7.789092426474269],
              [-35.439070801875971, -7.788290891311533],
              [-35.440190746505721, -7.787242740460044],
              [-35.4427207993254, -7.785498774248993],
              [-35.445690052254065, -7.783920670902205],
              [-35.447458214724804, -7.783338142241405],
              [-35.448678334837211, -7.783125934614134],
              [-35.451839885302434, -7.782984134909839],
              [-35.460856490834317, -7.783065836519086],
              [-35.463974371120287, -7.782764039328924],
              [-35.465055653895263, -7.782440681791968],
              [-35.4669732324287, -7.781606804310513],
              [-35.472334824992117, -7.778627068884077],
              [-35.474163577487047, -7.778091282486628],
              [-35.475570989509492, -7.778100137211538],
              [-35.476600832862118, -7.77852886291663],
              [-35.477047626846456, -7.778886245403839],
              [-35.477721971791539, -7.779819046511761],
              [-35.47801634777619, -7.780857130352217],
              [-35.477958088794708, -7.782444973356803],
              [-35.476833467690312, -7.785775535212736],
              [-35.476261242562259, -7.786658508483356],
              [-35.475517667806059, -7.787355854489022],
              [-35.473704596789673, -7.788304279518854],
              [-35.472064616488808, -7.788693579844789],
              [-35.467951157020252, -7.788883045416185],
              [-35.466833757471875, -7.789056971601894],
              [-35.465794578257146, -7.7894593678555],
              [-35.464681101551548, -7.790423188379407],
              [-35.464113323570984, -7.791254599919796],
              [-35.463364446412015, -7.79285473665675],
              [-35.462270074004664, -7.795959946394362],
              [-35.462054300565455, -7.796970496591086],
              [-35.462009892942149, -7.798060828344489],
              [-35.46220225522984, -7.799370171995804],
              [-35.462611958475065, -7.800765250715699],
              [-35.463198190544006, -7.802079083782644],
              [-35.464064379767251, -7.80330497574397],
              [-35.465190655624212, -7.804320212307914],
              [-35.466097013066644, -7.804771672874381],
              [-35.468063940998036, -7.80536223877624],
              [-35.46899615394932, -7.805788883450191],
              [-35.47005810040099, -7.806609127209413],
              [-35.470199866830342, -7.806718626863073],
              [-35.470867641328539, -7.80749019481658],
              [-35.471630884257877, -7.808823036853596],
              [-35.471934835324362, -7.809825281811243],
              [-35.472025178519118, -7.810889119537194],
              [-35.471821489270269, -7.813771328883661],
              [-35.471879536911047, -7.815422479663653],
              [-35.472239945616934, -7.816998488489389],
              [-35.472856475845617, -7.818431540183383],
              [-35.474048288897393, -7.820287870388881],
              [-35.475895599802072, -7.822335274153545],
              [-35.477477071109888, -7.823727053260825],
              [-35.478804182666863, -7.824426008153694],
              [-35.479867702932097, -7.824653489866591],
              [-35.480953302452917, -7.824654488114106],
              [-35.48202302804026, -7.824438907129831],
              [-35.482958230337999, -7.824039510115256],
              [-35.484717062342497, -7.822971223443176],
              [-35.488080149079565, -7.820513961749896],
              [-35.496548490120688, -7.815323957134865],
              [-35.497732361259409, -7.814302446900342],
              [-35.498756380602458, -7.8131197053101],
              [-35.499849476871816, -7.811347953645111],
              [-35.500542185073755, -7.809644785178648],
              [-35.502209066794933, -7.803242460271292],
              [-35.50331133974646, -7.798580932364075],
              [-35.503815078802802, -7.795890332572753],
              [-35.505085881108471, -7.790717980750173],
              [-35.506190890696985, -7.787219576622135],
              [-35.507378976958194, -7.784329824661654],
              [-35.508730800086312, -7.782106860643069],
              [-35.510712627985356, -7.779618131671384],
              [-35.515056054892966, -7.77499001267887],
              [-35.516130921904505, -7.773670979731889],
              [-35.517257125815661, -7.771906254470025],
              [-35.518370303946313, -7.769561317200953],
              [-35.520214936669959, -7.764403993949088],
              [-35.520398243683928, -7.76389148920083],
              [-35.521887364510235, -7.757986603598852],
              [-35.522184061645078, -7.754405502312011],
              [-35.522083193684409, -7.748924865590027],
              [-35.521451835433822, -7.744228354954544],
              [-35.520641932079457, -7.740834385498431],
              [-35.518860092719081, -7.735976515803543],
              [-35.517153681297316, -7.730403610438202],
              [-35.513622877293926, -7.721488869315806],
              [-35.512381600630306, -7.717456266993054],
              [-35.511781358922519, -7.713969102591989],
              [-35.511681127681868, -7.710534680730859],
              [-35.511860110755435, -7.70711472176697],
              [-35.512601690815359, -7.702941694803314],
              [-35.512597190607835, -7.701316425612504],
              [-35.511991564658956, -7.697498018418742],
              [-35.510943502840348, -7.693801030493091],
              [-35.510308567985987, -7.692076047171781],
              [-35.509209311360564, -7.689727141194567],
              [-35.508333332117559, -7.688119644406015],
              [-35.508333333016878, -7.689999992805383],
              [-35.507499999625225, -7.689999992805383],
              [-35.507499999625225, -7.69083332619698],
              [-35.504166666058723, -7.6908333270963],
              [-35.504166666058723, -7.689999992805383],
              [-35.502499999275528, -7.689999992805383],
              [-35.502500001074168, -7.69083332619698],
              [-35.5, -7.69083332619698],
              [-35.5, -7.689166659413729],
              [-35.497499998925775, -7.689166659413729],
              [-35.497499999825095, -7.686666659238881],
              [-35.494166667157913, -7.686666658339561],
              [-35.494166668057233, -7.685833325847284],
              [-35.491666666983065, -7.685833325847284],
              [-35.491666666083745, -7.68499999335495],
              [-35.490833331792771, -7.68499999335495],
              [-35.490833331792771, -7.683333326571699],
              [-35.489999999300494, -7.683333326571699],
              [-35.490000000199814, -7.681666657989808],
              [-35.488333334315882, -7.681666658889128],
              [-35.488333331617923, -7.680833325497531],
              [-35.486666665733992, -7.68083332729617],
              [-35.486666667532631, -7.681666657989808],
              [-35.485833333241715, -7.681666657989808],
              [-35.485833331443075, -7.6791666596136],
              [-35.478333333616433, -7.679166660512919],
              [-35.478333332717114, -7.678333326222003],
              [-35.472500000774346, -7.678333327121322],
              [-35.472499998975707, -7.67916665781496],
              [-35.471666666483429, -7.6791666596136],
              [-35.471666668282069, -7.679999993005254],
              [-35.469166668107221, -7.679999993005254],
              [-35.469166667207901, -7.6791666596136],
              [-35.468333332017608, -7.67916665781496],
              [-35.468333332916927, -7.679999993005254],
              [-35.46749999952533, -7.679999993005254],
              [-35.46749999862601, -7.678333326222003],
              [-35.465833332742079, -7.678333327121322],
              [-35.465833332742079, -7.676666659438752],
              [-35.466666666133676, -7.676666659438752],
              [-35.466666667032996, -7.67499999355482],
              [-35.465833331842759, -7.67499999355482],
              [-35.46583333094344, -7.67333332587225],
              [-35.463333332567174, -7.67333332587225],
              [-35.463333331667855, -7.669999993205067],
              [-35.46166666758262, -7.669999993205067],
              [-35.46166666578398, -7.669166658014831],
              [-35.460833333291646, -7.669166658914151],
              [-35.460833335090285, -7.667499993030219],
              [-35.4616666666833, -7.667499992130899],
              [-35.46166666578398, -7.664999992855314],
              [-35.462500000074897, -7.664999992855314],
              [-35.462500000074897, -7.662499993579786],
              [-35.46166666758262, -7.662499994479106],
              [-35.46166666488466, -7.659166659113964],
              [-35.459166666508395, -7.659166659113964],
              [-35.459166664709755, -7.658333326621687],
              [-35.457499999725144, -7.658333326621687],
              [-35.457499999725144, -7.657499993230033],
              [-35.455833333841213, -7.657499993230033],
              [-35.455833333841213, -7.658333324823047],
              [-35.454166666158699, -7.658333326621687],
              [-35.454166667058018, -7.659166660013284],
              [-35.453333332767045, -7.659166659113964],
              [-35.451666665983794, -7.659166659113964],
              [-35.451666666883114, -7.658333326621687],
              [-35.450833333491516, -7.658333326621687],
              [-35.450833331692877, -7.657499993230033],
              [-35.444166668157152, -7.657499993230033],
              [-35.444166665459193, -7.656666658939116],
              [-35.4350000008493, -7.656666658939116],
              [-35.43499999994998, -7.654999993055185],
              [-35.434166665659063, -7.654999992155865],
              [-35.434166666558383, -7.654166659663588],
              [-35.433333333166729, -7.654166659663588],
              [-35.433333332267409, -7.653333326271934],
              [-35.432499998875812, -7.653333325372614],
              [-35.432499999775132, -7.652499992880337],
              [-35.430833332092561, -7.652499992880337],
              [-35.430833332991881, -7.651666659488683],
              [-35.429999999600284, -7.651666659488683],
              [-35.429999999600284, -7.649999993604752],
              [-35.428333333716353, -7.649999993604752],
              [-35.428333333716353, -7.649166659313835],
              [-35.426666666033782, -7.649166659313835],
              [-35.426666666933102, -7.648333325022861],
              [-35.42333333156796, -7.648333325922181],
              [-35.42333333246728, -7.649166659313835],
              [-35.421666667482668, -7.649166660213155],
              [-35.420833333191752, -7.649166659313835],
              [-35.420833334091071, -7.648333325922181],
              [-35.419999998900778, -7.648333325922181],
              [-35.419999998900778, -7.639999992005983],
              [-35.418333332117527, -7.639999992905302],
              [-35.418333333016847, -7.639166658614329],
              [-35.41749999872593, -7.639166659513705],
              [-35.41749999872593, -7.638333326122051],
              [-35.416666668032235, -7.638333326122051],
              [-35.416666666233596, -7.639166659513705],
              [-35.414166667857387, -7.639166659513705],
              [-35.414166667857387, -7.639999992905302],
              [-35.41333333356647, -7.639999992905302],
              [-35.413333331767831, -7.64249999308015],
              [-35.408333333216717, -7.64249999308015],
              [-35.408333333216717, -7.643333326471804],
              [-35.407500000724383, -7.643333325572485],
              [-35.407500000724383, -7.644166658964082],
              [-35.406666665534146, -7.644166658964082],
              [-35.406666666433466, -7.646666659138987],
              [-35.405833333041869, -7.646666659138987],
              [-35.405833332142493, -7.647499993429903],
              [-35.404166666258618, -7.647499993429903],
              [-35.404166666258618, -7.648333325022861],
              [-35.403333331967644, -7.648333325922181],
              [-35.403333332866964, -7.649166660213155],
              [-35.402500001274007, -7.649166660213155],
              [-35.402499998576047, -7.649999991806112],
              [-35.401666667882353, -7.649999993604752],
              [-35.401666666983033, -7.650833326097086],
              [-35.402500001274007, -7.650833325197766],
              [-35.402500001274007, -7.653333326271934],
              [-35.401666666083713, -7.653333326271934],
              [-35.401666665184393, -7.654166659663588],
              [-35.399166665908865, -7.654166659663588],
              [-35.399166667707505, -7.655833326446839],
              [-35.394999999850086, -7.655833326446839],
              [-35.394999998051446, -7.654999993055185],
              [-35.389999999500333, -7.654999993055185],
              [-35.389999999500333, -7.654166659663588],
              [-35.388333332717082, -7.654166659663588],
              [-35.388333331817762, -7.654999993055185],
              [-35.385000000049899, -7.654999993954505],
              [-35.385000000949219, -7.655833326446839],
              [-35.384166665758983, -7.655833326446839],
              [-35.384166666658302, -7.656666658939116],
              [-35.383333334165968, -7.656666658939116],
              [-35.383333331468009, -7.658333326621687],
              [-35.382499998076412, -7.658333326621687],
              [-35.382499998076412, -7.659166659113964],
              [-35.381666666483454, -7.659166660013284],
              [-35.381666666483454, -7.658333326621687],
              [-35.379999998800884, -7.658333326621687],
              [-35.379999999700203, -7.657499994129353],
              [-35.379166666308549, -7.657499993230033],
              [-35.379166668107189, -7.658333326621687],
              [-35.378333332017633, -7.658333326621687],
              [-35.378333332916952, -7.659166660013284],
              [-35.375833331842728, -7.659166659113964],
              [-35.375833331842728, -7.658333324823047],
              [-35.37333333076856, -7.658333326621687],
              [-35.373333332567199, -7.657499993230033],
              [-35.371666666683268, -7.657499992330713],
              [-35.371666666683268, -7.656666658939116],
              [-35.370833333291671, -7.656666658939116],
              [-35.370833333291671, -7.655833326446839],
              [-35.371666665783948, -7.655833326446839],
              [-35.371666667582588, -7.654166659663588],
              [-35.372499999175602, -7.654166659663588],
              [-35.372499999175602, -7.652499992880337],
              [-35.373333333466519, -7.652499992880337],
              [-35.37333333166788, -7.651666658589363],
              [-35.370833334190991, -7.651666660388003],
              [-35.370833334190991, -7.650833326097086],
              [-35.369999999900017, -7.650833326097086],
              [-35.369999999000697, -7.651666659488683],
              [-35.367500000624489, -7.651666660388003],
              [-35.367499999725169, -7.652499992880337],
              [-35.36500000224828, -7.652499992880337],
              [-35.364999999550321, -7.654166660562908],
              [-35.365833332042598, -7.654166659663588],
              [-35.365833332042598, -7.654999993055185],
              [-35.366666668132211, -7.654999993055185],
              [-35.366666668132211, -7.656666658939116],
              [-35.365833333841238, -7.656666658939116],
              [-35.365833332042598, -7.658333326621687],
              [-35.365000000449641, -7.658333326621687],
              [-35.364999998651001, -7.659999993404938],
              [-35.364166666158667, -7.659999993404938],
              [-35.364166666158667, -7.662499993579786],
              [-35.364999998651001, -7.662499993579786],
              [-35.364999998651001, -7.664999992855314],
              [-35.365833332941918, -7.664999992855314],
              [-35.365833334740557, -7.667499993030219],
              [-35.366666665434195, -7.667499993030219],
              [-35.366666669031531, -7.674166659263847],
              [-35.365833332941918, -7.674166659263847],
              [-35.365833333841238, -7.67499999445414],
              [-35.362500001174055, -7.67499999445414],
              [-35.362500001174055, -7.675833325147778],
              [-35.361666667782458, -7.675833326047098],
              [-35.361666665983819, -7.674999992655501],
              [-35.360000000999207, -7.67499999355482],
              [-35.359999999200568, -7.674166659263847],
              [-35.358333331517997, -7.674166660163166],
              [-35.358333331517997, -7.67499999355482],
              [-35.356666665634066, -7.674999992655501],
              [-35.356666666533386, -7.676666659438752],
              [-35.352499999575286, -7.676666660338071],
              [-35.352499998675967, -7.677499991931029],
              [-35.347500001024173, -7.677499992830349],
              [-35.347499999225533, -7.676666659438752],
              [-35.346666666733256, -7.676666659438752],
              [-35.346666666733256, -7.675833325147778],
              [-35.345000000849325, -7.675833326946417],
              [-35.344999998151366, -7.67499999355482],
              [-35.343333332267434, -7.67499999355482],
              [-35.343333333166754, -7.674166659263847],
              [-35.3424999997751, -7.674166659263847],
              [-35.34250000067442, -7.67333332587225],
              [-35.341666667282823, -7.673333327670889],
              [-35.341666665484183, -7.671666659088999],
              [-35.339999999600252, -7.671666659088999],
              [-35.339999998700932, -7.67333332497293],
              [-35.339166666208655, -7.67333332587225],
              [-35.339166666208655, -7.674166659263847],
              [-35.333333330668609, -7.674166659263847],
              [-35.333333334265944, -7.67333332587225],
              [-35.332499999075651, -7.67333332587225],
              [-35.332499999075651, -7.672499993379915],
              [-35.331666666583374, -7.672499993379915],
              [-35.331666666583374, -7.671666658189679],
              [-35.329999999800123, -7.671666659088999],
              [-35.330000000699442, -7.672499993379915],
              [-35.328333332117552, -7.672499993379915],
              [-35.328333333916191, -7.671666659088999],
              [-35.32666666713294, -7.671666659088999],
              [-35.326666666233621, -7.669999993205067],
              [-35.324166666058773, -7.669999993205067],
              [-35.324166666058773, -7.669166658914151],
              [-35.321666665883868, -7.669166658014831],
              [-35.321666665883868, -7.668333326421816],
              [-35.319999999100617, -7.668333326421816],
              [-35.319999998201297, -7.667499992130899],
              [-35.318333333216685, -7.667499993030219],
              [-35.318333333216685, -7.666666659638565],
              [-35.316666668232131, -7.666666660537885],
              [-35.316666666433491, -7.667499993030219],
              [-35.314166668057226, -7.667499993030219],
              [-35.314166667157906, -7.668333327321136],
              [-35.311666666083738, -7.668333326421816],
              [-35.311666666083738, -7.667499992130899],
              [-35.310833331792765, -7.667499993030219],
              [-35.310833332692084, -7.666666659638565],
              [-35.30916666770753, -7.666666659638565],
              [-35.30916666680821, -7.664166658564397],
              [-35.308333334315876, -7.664166659463717],
              [-35.308333332517236, -7.662499993579786],
              [-35.305833331443068, -7.662499993579786],
              [-35.305833333241708, -7.661666659288869],
              [-35.302499999675206, -7.661666659288869],
              [-35.302499998775886, -7.66333332607212],
              [-35.301666666283552, -7.66333332607212],
              [-35.301666666283552, -7.664166659463717],
              [-35.300833333791275, -7.664166660363037],
              [-35.300000001298997, -7.664166659463717],
              [-35.299999999500358, -7.66333332607212],
              [-35.299166665209384, -7.66333332607212],
              [-35.299166666108704, -7.661666659288869],
              [-35.295833333441522, -7.661666658389549],
              [-35.295833333441522, -7.660833326796535],
              [-35.294166665758951, -7.660833325897215],
              [-35.29416666755759, -7.659999993404938],
              [-35.292499999875076, -7.659999993404938],
              [-35.292500000774396, -7.658333326621687],
              [-35.291666666483422, -7.658333326621687],
              [-35.291666665584103, -7.656666658939116],
              [-35.286666667032989, -7.656666658039796],
              [-35.286666667032989, -7.652499992880337],
              [-35.284999998451099, -7.652499992880337],
              [-35.285000000249795, -7.650833326097086],
              [-35.28250000007489, -7.650833326097086],
              [-35.28250000097421, -7.651666658589363],
              [-35.281666666683293, -7.651666659488683],
              [-35.281666665783973, -7.652499992880337],
              [-35.279166666508388, -7.652499992880337],
              [-35.279166668307028, -7.653333326271934],
              [-35.278333334016111, -7.653333326271934],
              [-35.278333333116791, -7.654166659663588],
              [-35.27666666543422, -7.654166657864948],
              [-35.27666666543422, -7.653333326271934],
              [-35.274999998650969, -7.653333326271934],
              [-35.275000000449609, -7.654166659663588],
              [-35.274166667957331, -7.654166660562908],
              [-35.274166667058012, -7.655833325547462],
              [-35.27083333259219, -7.655833325547462],
              [-35.27083333349151, -7.654999993954505],
              [-35.269999999200536, -7.654999993055185],
              [-35.270000000999175, -7.654166659663588],
              [-35.269166666708259, -7.654166657864948],
              [-35.269166667607578, -7.653333326271934],
              [-35.266666666533411, -7.653333326271934],
              [-35.266666665634091, -7.654166659663588],
              [-35.264166665459186, -7.654166659663588],
              [-35.264166665459186, -7.652499992880337],
              [-35.263333332966909, -7.652499992880337],
              [-35.263333332067589, -7.651666658589363],
              [-35.261666666183658, -7.651666659488683],
              [-35.261666666183658, -7.650833326097086],
              [-35.260833331892684, -7.650833326097086],
              [-35.260833332792004, -7.649999993604752],
              [-35.260000000299726, -7.649999993604752],
              [-35.259999998501087, -7.648333325022861],
              [-35.259166666908129, -7.648333325922181],
              [-35.259166667807449, -7.646666660038306],
              [-35.258333333516475, -7.646666658239667],
              [-35.258333333516475, -7.645833327545972],
              [-35.257499997426919, -7.645833324848013],
              [-35.257500001024198, -7.643333326471804],
              [-35.250833332991874, -7.643333325572485],
              [-35.250833332991874, -7.644166658964082],
              [-35.249999999600277, -7.644166658964082],
              [-35.250000000499597, -7.644999994154375],
              [-35.249166667107943, -7.644999993255055],
              [-35.249166668007263, -7.645833326646652],
              [-35.248333332817026, -7.645833326646652],
              [-35.248333331917706, -7.644999993255055],
              [-35.247499999425372, -7.644999994154375],
              [-35.247499997626733, -7.643333326471804],
              [-35.246666666933095, -7.643333326471804],
              [-35.246666667832415, -7.64249999308015],
              [-35.245833333541441, -7.64249999308015],
              [-35.245833332642121, -7.641666659688553],
              [-35.241666667482662, -7.641666659688553],
              [-35.241666666583342, -7.64083332539758],
              [-35.239999999800091, -7.640833326296899],
              [-35.240000000699411, -7.639999992905302],
              [-35.239166668207133, -7.639999992905302],
              [-35.239166667307813, -7.639166659513705],
              [-35.23833333211752, -7.639166659513705],
              [-35.23833333211752, -7.638333326122051],
              [-35.239166665509174, -7.638333326122051],
              [-35.239166668207133, -7.632499993280021],
              [-35.240000000699411, -7.632499993280021],
              [-35.240000000699411, -7.631666658989047],
              [-35.240833333191745, -7.631666658989047],
              [-35.240833333191745, -7.629999993105173],
              [-35.239999998900771, -7.629999993105173],
              [-35.24000000159873, -7.628333326321922],
              [-35.239166668207133, -7.628333326321922],
              [-35.239166668207133, -7.625833326147017],
              [-35.23833333391616, -7.625833326147017],
              [-35.23833333301684, -7.621666659188918],
              [-35.236666667132965, -7.621666659188918],
              [-35.236666667132965, -7.620833325797321],
              [-35.235833333741311, -7.620833325797321],
              [-35.235833333741311, -7.619999993304987],
              [-35.23416666695806, -7.619999994204306],
              [-35.234166666058741, -7.618333326521736],
              [-35.233333330868504, -7.618333326521736],
              [-35.233333333566463, -7.617499993130139],
              [-35.235833331942672, -7.617499994029458],
              [-35.235833332841992, -7.616666658839165],
              [-35.236666666233589, -7.616666658839165],
              [-35.236666667132965, -7.615833326346888],
              [-35.237499999625243, -7.615833326346888],
              [-35.237499999625243, -7.614166659563637],
              [-35.23833333301684, -7.614166659563637],
              [-35.23833333301684, -7.611666659388789],
              [-35.236666668032285, -7.611666659388789],
              [-35.236666666233589, -7.609999993504857],
              [-35.235833332841992, -7.609999993504857],
              [-35.235833333741311, -7.609166659213884],
              [-35.234999998551075, -7.609166658314564],
              [-35.234999998551075, -7.608333325822286],
              [-35.233333331767824, -7.608333325822286],
              [-35.233333333566463, -7.609166659213884],
              [-35.23249999927549, -7.609166659213884],
              [-35.23249999927549, -7.608333325822286],
              [-35.231666664984573, -7.608333325822286],
              [-35.231666666783212, -7.607499993330009],
              [-35.230000000899281, -7.607499994229329],
              [-35.230000000899281, -7.603333327271173],
              [-35.229999999100642, -7.599999992805351],
              [-35.230833334290878, -7.599999992805351],
              [-35.230833334290878, -7.596666659238906],
              [-35.231666666783212, -7.596666660138226],
              [-35.231666665883893, -7.594999993354975],
              [-35.234999999450395, -7.594999993354975],
              [-35.234999999450395, -7.594166659064001],
              [-35.235833331942672, -7.594166658164681],
              [-35.235833332841992, -7.593333327471044],
              [-35.236666666233589, -7.593333326571724],
              [-35.236666668032285, -7.59249999318007],
              [-35.235833333741311, -7.59249999318007],
              [-35.235833331043352, -7.591666658889153],
              [-35.234166666058741, -7.591666660687793],
              [-35.234166666058741, -7.589999993005222],
              [-35.233333332667144, -7.589999993005222],
              [-35.233333333566463, -7.585833326047123],
              [-35.23249999927549, -7.585833326047123],
              [-35.23249999927549, -7.584999993554788],
              [-35.233333331767824, -7.584999993554788],
              [-35.233333332667144, -7.584166659263872],
              [-35.23416666695806, -7.584166659263872],
              [-35.2341666687567, -7.581666659089024],
              [-35.234999999450395, -7.581666659089024],
              [-35.235000001249034, -7.580833326596689],
              [-35.235833331942672, -7.580833327496009],
              [-35.235833331942672, -7.579999993205092],
              [-35.237500000524562, -7.579999993205092],
              [-35.237500000524562, -7.580833326596689],
              [-35.241666665684022, -7.580833326596689],
              [-35.241666667482662, -7.578333326421841],
              [-35.240833332292425, -7.578333326421841],
              [-35.240833333191745, -7.577499993929507],
              [-35.241666666583342, -7.577499993030187],
              [-35.241666665684022, -7.573333326072088],
              [-35.242499999075676, -7.573333326072088],
              [-35.242499999974996, -7.572499993579811],
              [-35.242499999075676, -7.567499993230058],
              [-35.243333334265913, -7.567499993230058],
              [-35.243333331567953, -7.56499999305521],
              [-35.244166665858927, -7.56499999305521],
              [-35.244166665858927, -7.562499991980985],
              [-35.243333331567953, -7.562499991980985],
              [-35.243333332467273, -7.559999993604777],
              [-35.244166667657566, -7.559999993604777],
              [-35.244166666758247, -7.559166659313803],
              [-35.245000001049164, -7.559166659313803],
              [-35.244999999250524, -7.557499993429929],
              [-35.245833332642121, -7.557499993429929],
              [-35.245833331742801, -7.555833326646678],
              [-35.246666666033775, -7.555833326646678],
              [-35.246666666933095, -7.554166658964107],
              [-35.245833331742801, -7.554166658964107],
              [-35.245833332642121, -7.553333326471773],
              [-35.244999999250524, -7.553333326471773],
              [-35.245000000149844, -7.551666659688522],
              [-35.245833332642121, -7.551666659688522],
              [-35.245833332642121, -7.550833326296924],
              [-35.246666666933095, -7.550833326296924],
              [-35.246666668731734, -7.549999992905327],
              [-35.249166667107943, -7.549999992905327],
              [-35.249166667107943, -7.549166659513673],
              [-35.249999999600277, -7.549166659513673],
              [-35.250000000499597, -7.548333326122076],
              [-35.250833332092554, -7.548333327021396],
              [-35.250833332991874, -7.546666659338825],
              [-35.251666668182168, -7.546666659338825],
              [-35.251666667282848, -7.544999993454894],
              [-35.252499998875805, -7.544999993454894],
              [-35.252500000674445, -7.543333324873004],
              [-35.253333333166722, -7.543333327570963],
              [-35.253333334066099, -7.541666658989072],
              [-35.254166665659056, -7.541666658989072],
              [-35.254166667457696, -7.540833326496795],
              [-35.254999999050654, -7.540833326496795],
              [-35.255000001748613, -7.539999993105141],
              [-35.255833332442307, -7.539999993105141],
              [-35.255833334240947, -7.539166663310823],
              [-35.257499999225558, -7.539166658814224],
              [-35.257499999225558, -7.536666658639319],
              [-35.258333331717836, -7.536666659538639],
              [-35.258333332617156, -7.534166658464471],
              [-35.261666666183658, -7.534166659363791],
              [-35.261666667082977, -7.533333325072874],
              [-35.262500000474574, -7.533333325972194],
              [-35.262500000474574, -7.532499994379179],
              [-35.263333332067589, -7.53249999347986],
              [-35.263333332966909, -7.531666659188943],
              [-35.26499999885084, -7.531666659188943],
              [-35.265000000649479, -7.530833325797289],
              [-35.271666666883107, -7.530833327595928],
              [-35.271666665983787, -7.531666659188943],
              [-35.273333332767038, -7.531666659188943],
              [-35.273333331867718, -7.53249999347986],
              [-35.274999999550289, -7.53249999347986],
              [-35.274999999550289, -7.533333325972194],
              [-35.277499999725137, -7.533333325972194],
              [-35.277500001523833, -7.534166659363791],
              [-35.279166665609068, -7.534166659363791],
              [-35.279166665609068, -7.534999994554084],
              [-35.280833333291639, -7.534999993654765],
              [-35.280833331493, -7.535833326147042],
              [-35.286666667932309, -7.535833325247722],
              [-35.286666667032989, -7.534999991856068],
              [-35.288333332017601, -7.534999993654765],
              [-35.28833333291692, -7.534166659363791],
              [-35.289166668107214, -7.534166659363791],
              [-35.289166668107214, -7.533333325972194],
              [-35.290833332192506, -7.533333325972194],
              [-35.290833333091825, -7.53249999347986],
              [-35.294999999150605, -7.53249999347986],
              [-35.295000000049924, -7.531666659188943],
              [-35.296666665933856, -7.531666659188943],
              [-35.296666667732495, -7.532499994379179],
              [-35.298333331817787, -7.53249999258054],
              [-35.298333333616426, -7.534166660263111],
              [-35.299166666108704, -7.534166659363791],
              [-35.299166667907343, -7.534999993654765],
              [-35.299999998601038, -7.534999993654765],
              [-35.299999999500358, -7.536666660437959],
              [-35.300833332891955, -7.536666659538639],
              [-35.300833331992635, -7.539999993105141],
              [-35.299999999500358, -7.539999993105141],
              [-35.300000001298997, -7.540833326496795],
              [-35.303333332167483, -7.540833326496795],
              [-35.303333332167483, -7.539999993105141],
              [-35.304999998950734, -7.539999992205821],
              [-35.304999998950734, -7.539166657914905],
              [-35.306666666633305, -7.539166658814224],
              [-35.306666665733985, -7.53833332632189],
              [-35.310000000199807, -7.53833332632189],
              [-35.310000001099127, -7.537499992930293],
              [-35.311666666983058, -7.537499992030973],
              [-35.311666666083738, -7.536666659538639],
              [-35.313333332866989, -7.536666659538639],
              [-35.313333331967669, -7.535833326147042],
              [-35.31499999965024, -7.535833326147042],
              [-35.31500000054956, -7.536666659538639],
              [-35.315833332142518, -7.536666659538639],
              [-35.315833332142518, -7.539999993105141],
              [-35.317500000724408, -7.539999993105141],
              [-35.317499998925769, -7.542499993280046],
              [-35.318333332317366, -7.542499993280046],
              [-35.318333332317366, -7.544999992555574],
              [-35.31916666570902, -7.544999994354214],
              [-35.32, -7.544999993454894],
              [-35.32, -7.541666658989072],
              [-35.32083333429091, -7.541666658989072],
              [-35.32083333429091, -7.540833326496795],
              [-35.321666665883868, -7.540833325597475],
              [-35.321666666783187, -7.539999993105141],
              [-35.323333333566438, -7.539999993105141],
              [-35.323333333566438, -7.539166658814224],
              [-35.329166667307788, -7.539166658814224],
              [-35.329166667307788, -7.53833332632189],
              [-35.329999999800123, -7.53833332632189],
              [-35.329999998900803, -7.539166658814224],
              [-35.33083333319172, -7.539166658814224],
              [-35.330833334091039, -7.53833332632189],
              [-35.335833333541473, -7.53833332722121],
              [-35.335833333541473, -7.539166657914905],
              [-35.337499999425404, -7.539166658814224],
              [-35.337499998526084, -7.53833332632189],
              [-35.338333331917681, -7.53833332542257],
              [-35.338333331917681, -7.537499992030973],
              [-35.339166668007294, -7.537499992930293],
              [-35.340833332991906, -7.537499992930293],
              [-35.340833332991906, -7.53833332632189],
              [-35.341666669081462, -7.53833332632189],
              [-35.341666665484183, -7.539999993105141],
              [-35.343333333166754, -7.539999993105141],
              [-35.343333333166754, -7.539166658814224],
              [-35.344166665659031, -7.539166658814224],
              [-35.344166667457671, -7.541666658989072],
              [-35.346666667632576, -7.541666658989072],
              [-35.346666667632576, -7.542499993280046],
              [-35.347500001024173, -7.542499994179366],
              [-35.347499999225533, -7.544166658264601],
              [-35.348333331717868, -7.54416665916392],
              [-35.348333332617187, -7.544999993454894],
              [-35.349166666008784, -7.544999992555574],
              [-35.349166667807424, -7.548333326122076],
              [-35.349999998501062, -7.548333326122076],
              [-35.349999998501062, -7.550833325397605],
              [-35.351666667082952, -7.550833326296924],
              [-35.351666666183633, -7.551666659688522],
              [-35.353333332067564, -7.551666659688522],
              [-35.353333332966884, -7.552499993080175],
              [-35.355833333141788, -7.552499993080175],
              [-35.355833334041108, -7.554166658964107],
              [-35.356666667432705, -7.554166658964107],
              [-35.358333331517997, -7.554166658964107],
              [-35.358333333316637, -7.553333326471773],
              [-35.360833335290124, -7.553333326471773],
              [-35.360833331692845, -7.551666659688522],
              [-35.362500001174055, -7.551666659688522],
              [-35.362500000274736, -7.550833326296924],
              [-35.36333333276707, -7.550833326296924],
              [-35.36333333186775, -7.547499993629742],
              [-35.364166666158667, -7.547499993629742],
              [-35.364166667057987, -7.546666659338825],
              [-35.365000000449641, -7.546666659338825],
              [-35.364999999550321, -7.54416665916392],
              [-35.364166667057987, -7.54416666006324],
              [-35.364166667057987, -7.543333326671643],
              [-35.36333333276707, -7.543333326671643],
              [-35.36333333276707, -7.542499993280046],
              [-35.362500001174055, -7.542499993280046],
              [-35.362500000274736, -7.540833326496795],
              [-35.364166667057987, -7.540833325597475],
              [-35.364166667957306, -7.539999993105141],
              [-35.365833333841238, -7.539999993105141],
              [-35.365833332941918, -7.539166658814224],
              [-35.366666667232835, -7.539166658814224],
              [-35.366666667232835, -7.53833332632189],
              [-35.367500000624489, -7.53833332632189],
              [-35.367499999725169, -7.537499992930293],
              [-35.368333334016086, -7.537499992930293],
              [-35.368333332217446, -7.536666660437959],
              [-35.36916666650842, -7.536666659538639],
              [-35.36916666650842, -7.535833326147042],
              [-35.370833331493031, -7.535833325247722],
              [-35.370833333291671, -7.536666658639319],
              [-35.37500000024977, -7.536666659538639],
              [-35.37500000024977, -7.535833327046362],
              [-35.379999999700203, -7.535833326147042],
              [-35.379999998800884, -7.536666659538639],
              [-35.38083333399112, -7.536666659538639],
              [-35.380833332192481, -7.537499993829613],
              [-35.381666668282094, -7.537499992930293],
              [-35.381666665584078, -7.539166658814224],
              [-35.383333334165968, -7.539166660612864],
              [-35.383333333266648, -7.53833332632189],
              [-35.384166665758983, -7.53833332542257],
              [-35.384166666658302, -7.537499992030973],
              [-35.386666665933831, -7.537499992030973],
              [-35.38666666683315, -7.536666659538639],
              [-35.388333332717082, -7.536666659538639],
              [-35.388333331817762, -7.537499993829613],
              [-35.389166667008055, -7.537499992930293],
              [-35.389166666108736, -7.53833332632189],
              [-35.389999999500333, -7.53833332632189],
              [-35.389999998601013, -7.537499992930293],
              [-35.390833333791306, -7.537499992930293],
              [-35.390833333791306, -7.53833332632189],
              [-35.392500000574501, -7.53833332632189],
              [-35.392499998775861, -7.539166658814224],
              [-35.393333333066835, -7.539166658814224],
              [-35.393333333066835, -7.539999993105141],
              [-35.395000000749405, -7.539999993105141],
              [-35.395000000749405, -7.539166658814224],
              [-35.395833334141003, -7.539166658814224],
              [-35.395833331443043, -7.53833332542257],
              [-35.398333331617891, -7.53833332632189],
              [-35.398333331617891, -7.537499992930293],
              [-35.401666666083713, -7.537499992930293],
              [-35.401666667882353, -7.535833326147042],
              [-35.402499999475367, -7.535833327046362],
              [-35.402499998576047, -7.536666660437959],
              [-35.405833333941189, -7.536666659538639],
              [-35.405833333041869, -7.535833327046362],
              [-35.406666666433466, -7.535833326147042],
              [-35.406666668232106, -7.534999993654765],
              [-35.407499999825063, -7.534999991856068],
              [-35.407499998925744, -7.534166658464471],
              [-35.408333332317397, -7.534166659363791],
              [-35.408333332317397, -7.534999991856068],
              [-35.409999999100648, -7.534999993654765],
              [-35.41, -7.536666658639319],
              [-35.411666666783219, -7.536666659538639],
              [-35.411666667682539, -7.535833326147042],
              [-35.41333333356647, -7.535833327046362],
              [-35.41333333356647, -7.534999993654765],
              [-35.414166666058748, -7.534999993654765],
              [-35.414166667857387, -7.533333325972194],
              [-35.415833332841999, -7.533333325972194],
              [-35.415833333741318, -7.53249999347986],
              [-35.416666667132915, -7.53249999347986],
              [-35.416666667132915, -7.530833327595928],
              [-35.422499999075626, -7.530833325797289],
              [-35.422499999075626, -7.531666659188943],
              [-35.42333333246728, -7.531666659188943],
              [-35.42333333246728, -7.53249999347986],
              [-35.425833333541448, -7.532499994379179],
              [-35.425833330843489, -7.533333325972194],
              [-35.428333332817033, -7.533333325972194],
              [-35.428333333716353, -7.534166659363791],
              [-35.42916666620863, -7.534166659363791],
              [-35.42916666710795, -7.534999993654765],
              [-35.431666665484158, -7.534999994554084],
              [-35.431666665484158, -7.53249999347986],
              [-35.432499998875812, -7.53249999258054],
              [-35.432499999775132, -7.530833325797289],
              [-35.433333334066049, -7.530833325797289],
              [-35.433333333166729, -7.529999993305012],
              [-35.434166666558383, -7.529999993305012],
              [-35.434166667457703, -7.528333326521761],
              [-35.43499999905066, -7.528333326521761],
              [-35.43499999905066, -7.527499994928803],
              [-35.436666665833911, -7.527499993130107],
              [-35.436666666733231, -7.52666665883919],
              [-35.437500001024205, -7.52666665883919],
              [-35.437500000124885, -7.527499994029426],
              [-35.439166667807456, -7.527499993130107],
              [-35.439166666008759, -7.528333326521761],
              [-35.440000000299733, -7.528333326521761],
              [-35.440000001199053, -7.529166658114718],
              [-35.44083333279201, -7.529166658114718],
              [-35.44083333369133, -7.537499992930293],
              [-35.441666666183664, -7.537499992930293],
              [-35.441666666183664, -7.53833332542257],
              [-35.443333333866235, -7.53833332722121],
              [-35.443333333866235, -7.539166658814224],
              [-35.444166667257832, -7.539166658814224],
              [-35.444166665459193, -7.539999994004461],
              [-35.445833333141763, -7.539999993105141],
              [-35.445833333141763, -7.540833326496795],
              [-35.449166665808946, -7.540833326496795],
              [-35.449166667607585, -7.539999993105141],
              [-35.450833331692877, -7.539999993105141],
              [-35.450833331692877, -7.536666659538639],
              [-35.451666665983794, -7.536666659538639],
              [-35.451666665983794, -7.529166658114718],
              [-35.453333333666365, -7.529166659014038],
              [-35.453333333666365, -7.528333326521761],
              [-35.454166665259379, -7.528333326521761],
              [-35.454166666158699, -7.527499992230787],
              [-35.455833332941893, -7.527499993130107],
              [-35.455833332941893, -7.52666665883919],
              [-35.457500000624464, -7.52666665883919],
              [-35.457500000624464, -7.525833327246232],
              [-35.459166666508395, -7.525833326346913],
              [-35.459166665609075, -7.52666665883919],
              [-35.459999999000729, -7.52666665883919],
              [-35.459999999900049, -7.527499993130107],
              [-35.460833333291646, -7.527499993130107],
              [-35.460833333291646, -7.525833326346913],
              [-35.464999998451106, -7.525833326346913],
              [-35.464999998451106, -7.524999992955259],
              [-35.46583333094344, -7.524999992955259],
              [-35.465833332742079, -7.524166658664342],
              [-35.471666666483429, -7.524166659563662],
              [-35.471666668282069, -7.524999992055939],
              [-35.474166665758958, -7.524999992955259],
              [-35.474166666658277, -7.524166658664342],
              [-35.476666665933863, -7.524166659563662],
              [-35.476666666833182, -7.523333326172008],
              [-35.477500001124099, -7.523333326172008],
              [-35.47749999842614, -7.519999992605506],
              [-35.478333333616433, -7.519999993504825],
              [-35.478333331817794, -7.518333325822255],
              [-35.477500000224779, -7.518333325822255],
              [-35.477500001124099, -7.517499993329977],
              [-35.476666665933863, -7.517499994229297],
              [-35.476666665933863, -7.514999993155129],
              [-35.475833332542209, -7.514999993155129],
              [-35.475833331642889, -7.513333326371878],
              [-35.476666665933863, -7.513333326371878],
              [-35.476666665933863, -7.512499992980224],
              [-35.477500000224779, -7.512499992980224],
              [-35.47749999842614, -7.511666659588627],
              [-35.479166666108711, -7.511666659588627],
              [-35.479166666108711, -7.510833326196973],
              [-35.480000001299004, -7.510833326196973],
              [-35.479999998600988, -7.512499993879544],
              [-35.480833332891962, -7.512499992980224],
              [-35.480833331992642, -7.513333326371878],
              [-35.481666668082198, -7.513333326371878],
              [-35.481666666283559, -7.514166658864156],
              [-35.482500000574532, -7.514166658864156],
              [-35.482499998775893, -7.527499994029426],
              [-35.481666668082198, -7.527499993130107],
              [-35.481666666283559, -7.528333326521761],
              [-35.480833331992642, -7.528333326521761],
              [-35.480833331992642, -7.529999993305012],
              [-35.480000001299004, -7.529999992405692],
              [-35.479999998600988, -7.530833325797289],
              [-35.479166666108711, -7.530833325797289],
              [-35.47916666700803, -7.531666659188943],
              [-35.478333333616433, -7.531666660088263],
              [-35.478333331817794, -7.529999993305012],
              [-35.47749999842614, -7.529999994204331],
              [-35.477500000224779, -7.530833327595928],
              [-35.475833332542209, -7.530833325797289],
              [-35.475833330743569, -7.531666659188943],
              [-35.472500000774346, -7.531666659188943],
              [-35.472499998076387, -7.53249999347986],
              [-35.471666666483429, -7.53249999347986],
              [-35.471666668282069, -7.534166659363791],
              [-35.464166666858148, -7.534166659363791],
              [-35.464166665958828, -7.534999993654765],
              [-35.462500000074897, -7.534999994554084],
              [-35.462499999175577, -7.534166659363791],
              [-35.460833331493006, -7.534166659363791],
              [-35.460833331493006, -7.535833326147042],
              [-35.46166666758262, -7.535833326147042],
              [-35.4616666666833, -7.539166658814224],
              [-35.460833330593687, -7.539166657914905],
              [-35.460833334190966, -7.539999993105141],
              [-35.459999999000729, -7.539999993105141],
              [-35.459999999000729, -7.540833326496795],
              [-35.459166666508395, -7.540833326496795],
              [-35.459166665609075, -7.541666658989072],
              [-35.458333333116798, -7.541666658989072],
              [-35.458333333116798, -7.54416665916392],
              [-35.459166666508395, -7.544166658264601],
              [-35.459166666508395, -7.544999993454894],
              [-35.458333334016118, -7.544999993454894],
              [-35.458333333116798, -7.545833325947171],
              [-35.456666665434227, -7.545833325047852],
              [-35.456666668132186, -7.549166659513673],
              [-35.457499998825824, -7.549166660412993],
              [-35.457499999725144, -7.551666659688522],
              [-35.455833332042573, -7.551666659688522],
              [-35.455833332941893, -7.552499993979495],
              [-35.454166666158699, -7.552499993080175],
              [-35.454166667058018, -7.553333326471773],
              [-35.452500001174087, -7.553333326471773],
              [-35.452500001174087, -7.559166659313803],
              [-35.453333333666365, -7.559166659313803],
              [-35.453333332767045, -7.560833326097054],
              [-35.454166666158699, -7.560833326097054],
              [-35.454166667058018, -7.56499999305521],
              [-35.454999998650976, -7.56499999305521],
              [-35.454999999550296, -7.565833326446807],
              [-35.454166666158699, -7.565833326446807],
              [-35.454166666158699, -7.566666658939084],
              [-35.451666666883114, -7.566666658939084],
              [-35.451666667782433, -7.567499993230058],
              [-35.449166666708265, -7.567499993230058],
              [-35.449166667607585, -7.569166660013309],
              [-35.449166665808946, -7.569999992505586],
              [-35.450833332592197, -7.569999993404906],
              [-35.450833332592197, -7.57083332679656],
              [-35.451666665983794, -7.57083332589724],
              [-35.451666666883114, -7.571666659288837],
              [-35.452499998476128, -7.571666659288837],
              [-35.452499997576808, -7.573333326072088],
              [-35.453333333666365, -7.573333326072088],
              [-35.453333331867725, -7.57083332499792],
              [-35.454999999550296, -7.57083332589724],
              [-35.455000000449616, -7.571666659288837],
              [-35.455833332042573, -7.571666659288837],
              [-35.455833332042573, -7.57083332589724],
              [-35.456666665434227, -7.57083332589724],
              [-35.456666668132186, -7.57000000149884],
              [-35.464166665958828, -7.569999993404906],
              [-35.464166667757468, -7.569166659113989],
              [-35.464999998451106, -7.569166659113989],
              [-35.464999998451106, -7.569999993404906],
              [-35.466666667932316, -7.569999993404906],
              [-35.466666667032996, -7.571666659288837],
              [-35.46749999862601, -7.571666659288837],
              [-35.46750000042465, -7.572499994479131],
              [-35.468333332916927, -7.572499993579811],
              [-35.468333332017608, -7.573333326072088],
              [-35.469166666308581, -7.573333326072088],
              [-35.469166666308581, -7.574166660363062],
              [-35.469999998800859, -7.574166659463742],
              [-35.469999997901539, -7.574999994653979],
              [-35.47166666558411, -7.574999992855339],
              [-35.471666666483429, -7.575833326246936],
              [-35.474166667557597, -7.575833327146256],
              [-35.474166665758958, -7.57666665963859],
              [-35.476666665933863, -7.57666665963859],
              [-35.476666667732502, -7.575833326246936],
              [-35.478333333616433, -7.575833326246936],
              [-35.478333332717114, -7.57666665963859],
              [-35.479999999500308, -7.57666665963859],
              [-35.479999998600988, -7.579166658914119],
              [-35.482499999675213, -7.579166658914119],
              [-35.482499998775893, -7.579999993205092],
              [-35.483333333966129, -7.579999993205092],
              [-35.48333333216749, -7.580833326596689],
              [-35.485833334141034, -7.58083332479805],
              [-35.485833331443075, -7.579999994104412],
              [-35.486666666633312, -7.579999993205092],
              [-35.486666665733992, -7.579166658914119],
              [-35.48916666770748, -7.579166659813438],
              [-35.48916666770748, -7.579999993205092],
              [-35.490000000199814, -7.579999994104412],
              [-35.490000001099133, -7.580833326596689],
              [-35.493333332866996, -7.58083332479805],
              [-35.493333333766316, -7.578333327321161],
              [-35.494166668057233, -7.578333326421841],
              [-35.494166666258593, -7.57666666053791],
              [-35.493333332866996, -7.576666657839951],
              [-35.493333333766316, -7.574999992855339],
              [-35.492499998576022, -7.574999994653979],
              [-35.492500001273982, -7.574166659463742],
              [-35.491666666083745, -7.574166659463742],
              [-35.491666666983065, -7.572499993579811],
              [-35.490000001099133, -7.572499993579811],
              [-35.490000001099133, -7.567499993230058],
              [-35.487500000024966, -7.567499993230058],
              [-35.487500000924285, -7.566666658939084],
              [-35.486666665733992, -7.566666658939084],
              [-35.486666666633312, -7.565833326446807],
              [-35.484166665559144, -7.565833326446807],
              [-35.484166665559144, -7.56499999305521],
              [-35.481666667182878, -7.56499999305521],
              [-35.481666667182878, -7.564166659663556],
              [-35.480833334690601, -7.564166659663556],
              [-35.480833331992642, -7.562499992880305],
              [-35.479999998600988, -7.562499992880305],
              [-35.479999998600988, -7.561666660388028],
              [-35.478333331817794, -7.561666658589388],
              [-35.478333330918417, -7.560833326097054],
              [-35.475000000049931, -7.560833325197734],
              [-35.474999999150612, -7.559999993604777],
              [-35.472499999875026, -7.559999993604777],
              [-35.472499999875026, -7.558333325922206],
              [-35.471666668282069, -7.558333325922206],
              [-35.471666668282069, -7.557499993429929],
              [-35.470000001498818, -7.557499993429929],
              [-35.469999998800859, -7.556666659138955],
              [-35.470833333091832, -7.556666659138955],
              [-35.470833333991152, -7.551666660587841],
              [-35.477500001124099, -7.551666659688522],
              [-35.477500000224779, -7.550833326296924],
              [-35.478333333616433, -7.550833327196244],
              [-35.478333333616433, -7.551666659688522],
              [-35.481666667182878, -7.551666657889882],
              [-35.481666667182878, -7.550833326296924],
              [-35.48500000074938, -7.550833327196244],
              [-35.484999998051421, -7.551666659688522],
              [-35.485833331443075, -7.551666660587841],
              [-35.485833331443075, -7.550833326296924],
              [-35.490000001099133, -7.550833326296924],
              [-35.490000001099133, -7.549999992905327],
              [-35.492500001273982, -7.549999992905327],
              [-35.492499999475342, -7.549166659513673],
              [-35.494999999650247, -7.549166660412993],
              [-35.494999999650247, -7.549999992905327],
              [-35.495833332142524, -7.549999993804647],
              [-35.495833332142524, -7.550833325397605],
              [-35.496666666433441, -7.550833327196244],
              [-35.496666666433441, -7.551666657889882],
              [-35.500000000899263, -7.551666659688522],
              [-35.499999999100623, -7.550833326296924],
              [-35.506666664434988, -7.550833326296924],
              [-35.506666667132947, -7.549999992905327],
              [-35.507499999625225, -7.549999993804647],
              [-35.507499998725905, -7.546666658439506],
              [-35.508333332117559, -7.546666659338825],
              [-35.508333333016878, -7.544999993454894],
              [-35.509166665509156, -7.544999992555574],
              [-35.509166664609836, -7.544166658264601],
              [-35.5116666674827, -7.54416665916392],
              [-35.511666665684004, -7.543333324873004],
              [-35.514166667657548, -7.543333326671643],
              [-35.514166667657548, -7.542499993280046],
              [-35.51583333354148, -7.542499993280046],
              [-35.51583333084352, -7.543333324873004],
              [-35.516666666933077, -7.543333326671643],
              [-35.516666666933077, -7.544999993454894],
              [-35.517499999425411, -7.544999993454894],
              [-35.517499998526091, -7.546666659338825],
              [-35.518333331917688, -7.546666659338825],
              [-35.518333332817008, -7.547499993629742],
              [-35.520805004853507, -7.547499993629742],
              [-35.521378042, -7.541176702999962],
            ],
          ],
          [
            [
              [-34.878862042649132, -7.553197237462484],
              [-34.878853815651041, -7.553166459964132],
              [-34.878835370555862, -7.553166945598036],
              [-34.878281866614827, -7.551096105500847],
              [-34.878399015901834, -7.549220495629299],
              [-34.878757044102258, -7.547307460470904],
              [-34.876554058522913, -7.547292774541859],
              [-34.875628925934166, -7.549208579612184],
              [-34.874601955915466, -7.550291455985871],
              [-34.872632156448844, -7.55057907176672],
              [-34.870722804913498, -7.550700259909036],
              [-34.868953178346487, -7.550438206458011],
              [-34.867182064300778, -7.549833030170134],
              [-34.865491540302287, -7.548528382778613],
              [-34.865470082478282, -7.548502125272819],
              [-34.865464861913779, -7.548498096310027],
              [-34.864020290803751, -7.54673046174139],
              [-34.862497348273962, -7.545661644469249],
              [-34.861318789430527, -7.545764717567636],
              [-34.859901074774712, -7.547388460608943],
              [-34.856473950012742, -7.550123763900558],
              [-34.853603383289453, -7.550895784213026],
              [-34.850043493412443, -7.551303052993433],
              [-34.84736679084466, -7.551461446988128],
              [-34.847341094515855, -7.55143106339284],
              [-34.847340015329394, -7.551431127244655],
              [-34.845340638059952, -7.549067058697744],
              [-34.842286672591797, -7.54765150511281],
              [-34.841505365879868, -7.548942369494682],
              [-34.844916971041357, -7.552991497759365],
              [-34.842743557868857, -7.555055849247992],
              [-34.844635708071451, -7.556687927206042],
              [-34.846840792668388, -7.555354998834105],
              [-34.848442880034838, -7.556647302131239],
              [-34.853526663025036, -7.556454175419788],
              [-34.857551339622603, -7.555579202518118],
              [-34.861748011561417, -7.554727957329305],
              [-34.861751197859462, -7.554763054271518],
              [-34.861774762795051, -7.554758275274139],
              [-34.862211936731455, -7.559573788699367],
              [-34.857662764724751, -7.561867782971774],
              [-34.857079929395184, -7.565274363620915],
              [-34.855173071679872, -7.567608289590851],
              [-34.854771090013742, -7.569456924297583],
              [-34.853531153339986, -7.569930877807735],
              [-34.852991437804576, -7.571192706678005],
              [-34.85606624236317, -7.571396492154349],
              [-34.85750257748191, -7.570458227665029],
              [-34.857661713417315, -7.570354275929049],
              [-34.85834852926115, -7.569070936174739],
              [-34.858678202736826, -7.567629180841948],
              [-34.859855615843969, -7.565552357555191],
              [-34.863272207746149, -7.565476993468451],
              [-34.866231858613332, -7.565587356471269],
              [-34.866233579915729, -7.56561678318792],
              [-34.866258695282511, -7.565617721180843],
              [-34.866345653429107, -7.567105030772495],
              [-34.868316399881905, -7.567155472846764],
              [-34.868411511282204, -7.56590612746578],
              [-34.870874232759604, -7.565581307631192],
              [-34.874489428244487, -7.565698144853457],
              [-34.877440121864083, -7.565520008941235],
              [-34.881086620051406, -7.565206458511227],
              [-34.885227531325484, -7.564195863348345],
              [-34.885580717576033, -7.561513986980856],
              [-34.890017014773377, -7.559807182555403],
              [-34.892253711439594, -7.557427544945199],
              [-34.891961013589878, -7.55355325118154],
              [-34.892927805473789, -7.55136772414113],
              [-34.893022636285593, -7.548762425945142],
              [-34.891592461522862, -7.546862599129611],
              [-34.891829994958243, -7.544794567615156],
              [-34.891228767593532, -7.544988519004903],
              [-34.88953382612516, -7.545652366163722],
              [-34.889198396988377, -7.546606834637657],
              [-34.88885075056163, -7.549636808891591],
              [-34.887053238715964, -7.551127045776468],
              [-34.884299529000032, -7.552346079507913],
              [-34.881418043607766, -7.553129904321565],
              [-34.878862042649132, -7.553197237462484],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        OBJECTID: '269',
        AcaoPriori: 12.3,
        ImportBio_: 'Muito Alta',
        Prioridade: 'Muito Alta',
        COD_area: 'MA266',
        Shape_Leng: 6.14657,
        Area_ha: 288533.83353,
        Shape_Area: 0.23683,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.9458676369999, -8.303347090999981],
              [-34.945787461999899, -8.30339109499994],
              [-34.945669868, -8.303432787999958],
              [-34.945367599, -8.303362865999938],
              [-34.945105812, -8.303342672999969],
              [-34.944733658, -8.303352419999957],
              [-34.944472306, -8.303328478999953],
              [-34.944241077, -8.303273665999944],
              [-34.943985664, -8.303265496999932],
              [-34.943848024, -8.303300106999925],
              [-34.943622322, -8.303370792999937],
              [-34.943410945, -8.30334457699996],
              [-34.943274258, -8.303299791999967],
              [-34.943057270999901, -8.303244894999979],
              [-34.943019440999898, -8.303346875999981],
              [-34.943044803740243, -8.303500897331416],
              [-34.943134873375641, -8.303379363067108],
              [-34.943634001607847, -8.303379363966428],
              [-34.944988774512296, -8.303379363067108],
              [-34.945951377354163, -8.303661590110266],
              [-34.946004602830101, -8.303591367447609],
              [-34.945878351002733, -8.303341210967032],
              [-34.9458676369999, -8.303347090999981],
            ],
          ],
          [
            [
              [-34.942861514999898, -8.303674596999937],
              [-34.942673632, -8.303749289999926],
              [-34.942515463999896, -8.30379685299993],
              [-34.942330266999903, -8.304023246999984],
              [-34.94218978, -8.304190036999966],
              [-34.942185187, -8.304197120999968],
              [-34.942141542999899, -8.304423397999937],
              [-34.942176113999899, -8.304563339999957],
              [-34.9422597189999, -8.304674416999951],
              [-34.942477052, -8.304716401999944],
              [-34.942552146848605, -8.304700469813577],
              [-34.942207921458362, -8.304578821255575],
              [-34.942243573282212, -8.304261319204443],
              [-34.942421832401578, -8.303979093060661],
              [-34.942742701514419, -8.303908534950892],
              [-34.943044537427568, -8.30350125667688],
              [-34.942861514999898, -8.303674596999937],
            ],
          ],
          [
            [
              [-34.943172972, -8.30524708899997],
              [-34.943807159999899, -8.306571291999944],
              [-34.944261522, -8.307520013999977],
              [-34.944622071, -8.308352104999926],
              [-34.945240770999902, -8.309779969999967],
              [-34.945318339, -8.309958986999954],
              [-34.946261315999905, -8.312141997999957],
              [-34.946533469, -8.312772040999958],
              [-34.946783201, -8.313350175999965],
              [-34.947610542999897, -8.315683726999952],
              [-34.947746072, -8.316065988999982],
              [-34.948128009999898, -8.317019694999935],
              [-34.948176363, -8.317147193999972],
              [-34.948297219, -8.317465868999937],
              [-34.948534152, -8.318090621999943],
              [-34.94870791200001, -8.319372883999957],
              [-34.949060626999902, -8.320373882999949],
              [-34.949388579999898, -8.320887411999928],
              [-34.9493992129999, -8.320892217999926],
              [-34.949803225, -8.321511959999953],
              [-34.949906274999897, -8.321670035999931],
              [-34.949995771, -8.322052437999954],
              [-34.950054839290942, -8.322304826476193],
              [-34.950051353287279, -8.322111655828223],
              [-34.949873093268593, -8.321088633833313],
              [-34.949480921407371, -8.320488933517424],
              [-34.949053099520881, -8.320030334132582],
              [-34.948874839502196, -8.319360075706868],
              [-34.948910490426726, -8.318830924507495],
              [-34.94869657858419, -8.318337048316096],
              [-34.948625274936433, -8.317560954974056],
              [-34.948161798528133, -8.316044044197213],
              [-34.947484410277241, -8.314315461789079],
              [-34.946878328372122, -8.312163544212126],
              [-34.944881819040688, -8.307189394874626],
              [-34.943990519846579, -8.305037436828172],
              [-34.943348783419594, -8.304861046500037],
              [-34.942717007482159, -8.304756854564994],
              [-34.943172972, -8.30524708899997],
            ],
          ],
          [
            [
              [-34.949985990999899, -8.324400616999974],
              [-34.949928192, -8.324968249999927],
              [-34.949968338, -8.325764522999973],
              [-34.950240741999899, -8.327368963999968],
              [-34.950363212, -8.328126043999923],
              [-34.950501562999897, -8.329319857999966],
              [-34.950417444, -8.330336423999944],
              [-34.950315272999902, -8.331348293999955],
              [-34.950180155999902, -8.332595826999981],
              [-34.950190226, -8.333395129999932],
              [-34.950215243999899, -8.334422762999953],
              [-34.950223654, -8.334768194999988],
              [-34.950257555, -8.335827620999954],
              [-34.95025415, -8.336785258999946],
              [-34.950161722, -8.337570048999961],
              [-34.950058379, -8.338172143999937],
              [-34.95001086, -8.338448998999981],
              [-34.949864283, -8.339080956999954],
              [-34.949701972999897, -8.339652364999948],
              [-34.949395044, -8.340605922999936],
              [-34.949216873999902, -8.341186694999989],
              [-34.949169722, -8.34127553899998],
              [-34.949048664, -8.341503638999935],
              [-34.948827428999898, -8.341819792999964],
              [-34.948614313999897, -8.342036320999965],
              [-34.948388982, -8.342262584999958],
              [-34.948173518, -8.342397918999952],
              [-34.947918248999898, -8.342488553999933],
              [-34.947712576, -8.342484019999965],
              [-34.94769939, -8.342483728999982],
              [-34.947662671195303, -8.34247608912337],
              [-34.947662671195303, -8.342536242453605],
              [-34.947805280289401, -8.342888989834989],
              [-34.948340059446139, -8.342783164811181],
              [-34.94916005499249, -8.34218349597154],
              [-34.949837440545423, -8.340984151996963],
              [-34.950336568777573, -8.33992590535621],
              [-34.950514828796258, -8.338761830993633],
              [-34.950764393362022, -8.337209727047195],
              [-34.950657436991094, -8.335481238168541],
              [-34.950693088814944, -8.334246599401013],
              [-34.950657436991094, -8.333258884789643],
              [-34.950693088814944, -8.331988963819583],
              [-34.950728741538171, -8.330930692897141],
              [-34.950800043387233, -8.329413832482317],
              [-34.95087134883363, -8.328038071199956],
              [-34.950514827896939, -8.326732854537568],
              [-34.950158308758887, -8.325886228266768],
              [-34.950087006010449, -8.324969045684838],
              [-34.95008700331249, -8.324087138110883],
              [-34.950081201702659, -8.323765652295082],
              [-34.949985990999899, -8.324400616999974],
            ],
          ],
          [
            [
              [-34.946956233, -8.342139231999973],
              [-34.946873883, -8.34228133899995],
              [-34.94683613766248, -8.342366337848777],
              [-34.946913979296653, -8.342289319196652],
              [-34.946990482373366, -8.342083866322398],
              [-34.946956233, -8.342139231999973],
            ],
          ],
          [
            [
              [-34.945937144, -8.342151428999946],
              [-34.945845135999903, -8.342108547999938],
              [-34.945709656, -8.342123603999946],
              [-34.945642157999899, -8.342197387999931],
              [-34.945552995999897, -8.342368202999973],
              [-34.945483794, -8.342526415999936],
              [-34.945419306, -8.342743993999926],
              [-34.945391291, -8.343002230999964],
              [-34.945395556679394, -8.343173502773329],
              [-34.945559207291524, -8.34260678977148],
              [-34.94597913407474, -8.342191308386058],
              [-34.945937144, -8.342151428999946],
            ],
          ],
          [
            [
              [-34.944556136, -8.344077939999989],
              [-34.944495453999899, -8.344048945999928],
              [-34.94440313, -8.344059451999957],
              [-34.944324305, -8.344134552999956],
              [-34.944265239999901, -8.344216267999979],
              [-34.944258979, -8.344294555999975],
              [-34.944257931364703, -8.344308774658614],
              [-34.944525299902637, -8.344088327514271],
              [-34.944553861803598, -8.344095392227004],
              [-34.944556136, -8.344077939999989],
            ],
          ],
          [
            [
              [-34.944132382, -8.344407764999962],
              [-34.944131281172986, -8.344413198587141],
              [-34.944140181746995, -8.344405860004457],
              [-34.944132382, -8.344407764999962],
            ],
          ],
          [
            [
              [-34.94284397, -8.343450679999922],
              [-34.942654321, -8.343430564999958],
              [-34.9424976459999, -8.34350579099994],
              [-34.942454593417864, -8.343658459079789],
              [-34.942635746042811, -8.34348865777531],
              [-34.942903239189206, -8.34351651695399],
              [-34.94284397, -8.343450679999922],
            ],
          ],
          [
            [
              [-34.942250252999898, -8.343846909999977],
              [-34.942063458999897, -8.343857702999971],
              [-34.941953113, -8.343962322999968],
              [-34.9418169919999, -8.344076448999941],
              [-34.941722526, -8.344148831999977],
              [-34.941583108, -8.344293255999967],
              [-34.941521985326496, -8.344370317143371],
              [-34.941958358691238, -8.344123598924909],
              [-34.94225406539941, -8.34384642168386],
              [-34.942250252999898, -8.343846909999977],
            ],
          ],
          [
            [
              [-34.941301258, -8.344495998999944],
              [-34.94126017100001, -8.344589611999931],
              [-34.941169935999902, -8.344622897999953],
              [-34.940981393, -8.344706952999957],
              [-34.940829103999903, -8.344856093999976],
              [-34.940703468, -8.345044787999949],
              [-34.940611911, -8.345261585999944],
              [-34.9406106, -8.345273690999925],
              [-34.940553979, -8.34573910199992],
              [-34.940539321, -8.345988559999963],
              [-34.940538009, -8.346000665999954],
              [-34.940499326, -8.346186347999957],
              [-34.940498014, -8.346198452999944],
              [-34.940401650999902, -8.346402503999977],
              [-34.940383714999903, -8.346682220999972],
              [-34.940379719, -8.34677619299994],
              [-34.940398711999897, -8.346943529999969],
              [-34.940454191999898, -8.347059650999947],
              [-34.940434511, -8.347241251999947],
              [-34.940307124, -8.347503216999931],
              [-34.940143244999902, -8.347816426999946],
              [-34.940126711659531, -8.34784655173579],
              [-34.940282716674005, -8.347651039463869],
              [-34.94060358398815, -8.347051377818786],
              [-34.940496626717902, -8.346628085817485],
              [-34.940639235812057, -8.346451713475744],
              [-34.94060358398815, -8.345710951798935],
              [-34.940888799478444, -8.344970190122126],
              [-34.941410701215048, -8.344453813562728],
              [-34.941301258, -8.344495998999944],
            ],
          ],
          [
            [
              [-34.939613802999901, -8.348647925999956],
              [-34.939564620999903, -8.348930451999927],
              [-34.939515007, -8.349102732999947],
              [-34.939470851, -8.349167528999935],
              [-34.939422552999901, -8.349327704999945],
              [-34.939421239999902, -8.349339810999934],
              [-34.939425151, -8.349646347999961],
              [-34.939481068, -8.349872690999966],
              [-34.939491991999901, -8.349886094999929],
              [-34.939685777, -8.350096461999955],
              [-34.939832956616264, -8.350219081331701],
              [-34.939712284794041, -8.350014407438891],
              [-34.939462722026974, -8.349626393143467],
              [-34.939462722026974, -8.349238378848042],
              [-34.93967663297019, -8.348709266319531],
              [-34.939722745141864, -8.348458332587883],
              [-34.939613802999901, -8.348647925999956],
            ],
          ],
          [
            [
              [-34.939892649999898, -8.350528619999977],
              [-34.9397768339999, -8.350797952999935],
              [-34.939775521999898, -8.350810057999986],
              [-34.939703398999903, -8.351133009999954],
              [-34.939655533, -8.351403433999959],
              [-34.939653565, -8.35142159099996],
              [-34.939651798999897, -8.351666288999986],
              [-34.939651144, -8.351672341999963],
              [-34.939743106, -8.351908615999944],
              [-34.939783388068761, -8.351965769046243],
              [-34.939747936617948, -8.351883926202163],
              [-34.939712284794041, -8.351319544061653],
              [-34.939961847561165, -8.35043769584297],
              [-34.939929473585096, -8.350382785694224],
              [-34.939892649999898, -8.350528619999977],
            ],
          ],
          [
            [
              [-34.940378166, -8.352900432999949],
              [-34.940451445999898, -8.353036423999981],
              [-34.940688282999901, -8.353135013999974],
              [-34.940699862, -8.353142363999947],
              [-34.940973841, -8.353183670999943],
              [-34.941180750999898, -8.353101545999953],
              [-34.941349014413476, -8.353047961893878],
              [-34.941031405874639, -8.353047961893878],
              [-34.940532279441072, -8.353012687785281],
              [-34.940352803728743, -8.35286243497776],
              [-34.940378166, -8.352900432999949],
            ],
          ],
          [
            [
              [-34.941469181437611, -8.353063178823264],
              [-34.941459229559769, -8.353047961893878],
              [-34.94144416547141, -8.353047961893878],
              [-34.941469181437611, -8.353063178823264],
            ],
          ],
          [
            [
              [-34.941519598999903, -8.353229319999977],
              [-34.941445309999899, -8.35334387899997],
              [-34.941424991, -8.353360089999976],
              [-34.941401164999903, -8.353522858999954],
              [-34.941479152999904, -8.353659695999967],
              [-34.941501655, -8.353680448999976],
              [-34.941664198999902, -8.353722174999964],
              [-34.941840941, -8.353747038999924],
              [-34.941851403035351, -8.353763261400243],
              [-34.94185140321963, -8.353647615445027],
              [-34.941527876343144, -8.353152926330909],
              [-34.941519598999903, -8.353229319999977],
            ],
          ],
          [
            [
              [-34.94251137, -8.35472603499994],
              [-34.942524261, -8.354721282999954],
              [-34.942653811211628, -8.354634232481088],
              [-34.942285698487616, -8.354735399821783],
              [-34.94251137, -8.35472603499994],
            ],
          ],
          [
            [
              [-34.943090771, -8.354689641999927],
              [-34.94308946, -8.354701743999959],
              [-34.943039859, -8.354873976999951],
              [-34.943038547999898, -8.354886079999968],
              [-34.942890186999897, -8.355170268999938],
              [-34.942662276, -8.355617455999949],
              [-34.942626215, -8.355778925999976],
              [-34.942678160223075, -8.355823020500463],
              [-34.94306357062726, -8.355164380531676],
              [-34.943206177023399, -8.354705825213614],
              [-34.943082375512084, -8.354675203326162],
              [-34.943090771, -8.354689641999927],
            ],
          ],
          [
            [
              [-34.942807549999898, -8.355932854999931],
              [-34.94298567687639, -8.356107743193006],
              [-34.942956614256275, -8.355834577803478],
              [-34.942691774240672, -8.355834576968407],
              [-34.942807549999898, -8.355932854999931],
            ],
          ],
          [
            [
              [-34.943004592, -8.356455776999942],
              [-34.942878723, -8.356760780999926],
              [-34.942944038999897, -8.356957501999938],
              [-34.9429556179999, -8.356964851999971],
              [-34.943229802, -8.357232701999973],
              [-34.943356397999899, -8.357409562999981],
              [-34.943426679466349, -8.357934091200653],
              [-34.943491392513693, -8.357421883912764],
              [-34.943170524300228, -8.3569986027033],
              [-34.943027917904033, -8.35650477507528],
              [-34.943016375895986, -8.356396289429105],
              [-34.943004592, -8.356455776999942],
            ],
          ],
          [
            [
              [-34.9434487669999, -8.358098935999976],
              [-34.943649103, -8.358420181999966],
              [-34.943821487, -8.358542544999972],
              [-34.9438453019999, -8.358551193999972],
              [-34.943856224999898, -8.358564596999937],
              [-34.944049155, -8.358554445999966],
              [-34.9442794409999, -8.358542125999966],
              [-34.944433468999897, -8.358662541999928],
              [-34.944548389, -8.358686970999939],
              [-34.944684501, -8.358744258999939],
              [-34.94486323, -8.358869104999945],
              [-34.94503736, -8.358918190999933],
              [-34.945178687157224, -8.358791604818984],
              [-34.944632254474925, -8.358656448036584],
              [-34.944489648978049, -8.35844480878086],
              [-34.943812261626533, -8.358409535571582],
              [-34.943435965016285, -8.358003391587328],
              [-34.9434487669999, -8.358098935999976],
            ],
          ],
          [
            [
              [-34.945962664, -8.358887720999961],
              [-34.946124118999897, -8.358996689999977],
              [-34.946205393999897, -8.359103287999966],
              [-34.946384554, -8.359220266999953],
              [-34.9466231399999, -8.35924556099997],
              [-34.947191268, -8.359269400999949],
              [-34.947628903, -8.358942319999933],
              [-34.948123967999898, -8.358713221999942],
              [-34.9484015479999, -8.358856658999976],
              [-34.948659776999897, -8.358871826999964],
              [-34.949042775999899, -8.358820624999964],
              [-34.949273282, -8.358692017999934],
              [-34.949637068999898, -8.358418363999938],
              [-34.949892475, -8.358059720999963],
              [-34.950111175, -8.357868657999969],
              [-34.9503414449999, -8.357684934999947],
              [-34.950474843009609, -8.357668795478503],
              [-34.950122656935037, -8.357668795478503],
              [-34.949801790520155, -8.357986256160757],
              [-34.949359643332855, -8.358397974786669],
              [-34.949195706816397, -8.35855062930807],
              [-34.948874839502196, -8.358797540873752],
              [-34.948090495779695, -8.358691722145238],
              [-34.947627019371396, -8.358762266765154],
              [-34.9469496329192, -8.359220822083216],
              [-34.946450505586313, -8.359079726548089],
              [-34.946272245567627, -8.358868088191684],
              [-34.945844421882498, -8.358656448036584],
              [-34.945544761728172, -8.35847856254385],
              [-34.945962664, -8.358887720999961],
            ],
          ],
          [
            [
              [-34.9508806649999, -8.357619695999965],
              [-34.951184532297702, -8.357339717446051],
              [-34.950817290090178, -8.357627363585985],
              [-34.9508806649999, -8.357619695999965],
            ],
          ],
          [
            [
              [-34.953395222182806, -8.355587663617378],
              [-34.952939161812822, -8.355587664439099],
              [-34.952741543874986, -8.35581984409648],
              [-34.953395222182806, -8.355587663617378],
            ],
          ],
          [
            [
              [-34.954222334999898, -8.355311021999976],
              [-34.95425835, -8.355325529999957],
              [-34.954989769, -8.355620176999928],
              [-34.955229975, -8.355855147999938],
              [-34.955605792, -8.356222771999978],
              [-34.955615141, -8.356240600999975],
              [-34.956302718, -8.357551814999963],
              [-34.956460476, -8.357852660999976],
              [-34.957022842, -8.35920577799993],
              [-34.9575021859999, -8.361286239999959],
              [-34.957684123, -8.362570224999963],
              [-34.957856487746703, -8.363198771063304],
              [-34.957787829644587, -8.362077936747312],
              [-34.957573916902675, -8.360490654020396],
              [-34.957253047789891, -8.359185547075299],
              [-34.956718269532473, -8.357880436532866],
              [-34.956361748595782, -8.356716415230323],
              [-34.955684363042849, -8.355905125121353],
              [-34.954472196534596, -8.35509383411312],
              [-34.954008721025616, -8.355234928748928],
              [-34.953491971673024, -8.35555446867753],
              [-34.954222334999898, -8.355311021999976],
            ],
          ],
          [
            [
              [-34.958018567, -8.363789809999959],
              [-34.958130104, -8.364196539999975],
              [-34.958553185, -8.364910473999936],
              [-34.959623445, -8.365519046999966],
              [-34.960920782, -8.366674841999952],
              [-34.96288404381913, -8.36842390998982],
              [-34.962912047952614, -8.367675863949898],
              [-34.962947699776464, -8.367040960571842],
              [-34.963375522562274, -8.366194418837324],
              [-34.963553782580959, -8.365488967242015],
              [-34.963767695322815, -8.364748241538052],
              [-34.964266821756382, -8.364219149693952],
              [-34.964730296366042, -8.363372602563516],
              [-34.96526507552278, -8.362526055433079],
              [-34.965657247384001, -8.362067508208895],
              [-34.966370286559425, -8.36175005112392],
              [-34.967297237577384, -8.361608959186071],
              [-34.968010278551446, -8.361432594038945],
              [-34.969115488688828, -8.36164423149603],
              [-34.970113743354545, -8.361820597542476],
              [-34.971504169881484, -8.361503140457501],
              [-34.973201848886163, -8.361281974683322],
              [-34.974772292707087, -8.361067822721793],
              [-34.975271978518947, -8.360639519698054],
              [-34.974915060981232, -8.358498005478509],
              [-34.972345242838628, -8.359211844151218],
              [-34.969561272734495, -8.359354611526044],
              [-34.967734724477907, -8.359926283571099],
              [-34.966558211592144, -8.359714645214638],
              [-34.964918219600122, -8.359714645214638],
              [-34.963919964934405, -8.360208469245435],
              [-34.962315627464193, -8.361407752066043],
              [-34.961424327370764, -8.362324849212371],
              [-34.960604333623053, -8.363312489179975],
              [-34.95989129444763, -8.363947398853327],
              [-34.959106951624449, -8.364476490697427],
              [-34.958429564272933, -8.364264855038982],
              [-34.957877223370403, -8.363274385682281],
              [-34.958018567, -8.363789809999959],
            ],
          ],
          [
            [
              [-34.964076045, -8.370479759999968],
              [-34.964739708, -8.371112722999948],
              [-34.965426325, -8.37218847099996],
              [-34.9655455669999, -8.372363933999969],
              [-34.965693165305439, -8.372399558673608],
              [-34.965657247384001, -8.372367069484596],
              [-34.965086815504094, -8.37155581175125],
              [-34.964480733598919, -8.370568192468056],
              [-34.963789289550625, -8.369884114848061],
              [-34.964076045, -8.370479759999968],
            ],
          ],
          [
            [
              [-34.967515551, -8.368913565999954],
              [-34.967736368, -8.368455018999946],
              [-34.968064093999899, -8.368346476999925],
              [-34.970892501, -8.369460374999957],
              [-34.969998668999899, -8.372034724999924],
              [-34.969727772999903, -8.371889546999975],
              [-34.969029319, -8.372802568999923],
              [-34.969528829, -8.373647828999946],
              [-34.968501860999901, -8.374305570999981],
              [-34.968903995999902, -8.374864304999953],
              [-34.968969161, -8.375125888999946],
              [-34.968861998, -8.375446693999955],
              [-34.968514969, -8.375794129999969],
              [-34.968431746, -8.376137016999966],
              [-34.968139568, -8.376464255999965],
              [-34.968105152, -8.37672113499997],
              [-34.968289449999908, -8.377268226999934],
              [-34.968647874999903, -8.377970532999944],
              [-34.96905329, -8.378664806999943],
              [-34.969587368, -8.379679608999945],
              [-34.969778877, -8.379952968999939],
              [-34.970182414, -8.380270260999955],
              [-34.970453643, -8.380502447999955],
              [-34.971203881999898, -8.380740661999939],
              [-34.971495742999899, -8.380866509999974],
              [-34.971696114999901, -8.380497436999965],
              [-34.972846671, -8.381082610999941],
              [-34.972642611, -8.381485599999955],
              [-34.972745632999903, -8.381535120999956],
              [-34.979391248999903, -8.38093551699995],
              [-34.9793842509999, -8.380806173999931],
              [-34.979714387999898, -8.380793909999969],
              [-34.9800906299999, -8.380881040999958],
              [-34.980621948, -8.381141737999943],
              [-34.9805550889999, -8.382872018999938],
              [-34.980047453999902, -8.383107795999925],
              [-34.97986416, -8.383369412999967],
              [-34.979521573999897, -8.383750408999969],
              [-34.979152880999898, -8.38373688799993],
              [-34.978855591999903, -8.383721402999976],
              [-34.977855328, -8.383896425999977],
              [-34.977203071, -8.383878391999929],
              [-34.976613417, -8.384156551999975],
              [-34.97605286, -8.384207826999949],
              [-34.975411153, -8.384250552999955],
              [-34.975206876999898, -8.384228910999983],
              [-34.974576478, -8.384485721999972],
              [-34.974240606, -8.384646001999945],
              [-34.974174504, -8.385814226999974],
              [-34.974079077999903, -8.386298069999945],
              [-34.973880565999899, -8.386302576999977],
              [-34.973292643, -8.385848508999965],
              [-34.9711458519999, -8.388045822999972],
              [-34.9709622, -8.388435530999972],
              [-34.970276344, -8.389890908999975],
              [-34.96980452, -8.390892111999964],
              [-34.9620584319999, -8.394310823999936],
              [-34.962089789999901, -8.394482091999974],
              [-34.961981625, -8.394537971999977],
              [-34.962295595999898, -8.395253174999931],
              [-34.961392363, -8.395578354999945],
              [-34.9610828369999, -8.395482238999934],
              [-34.961021888999902, -8.395463312999931],
              [-34.960552650999901, -8.39567],
              [-34.961602750999901, -8.398127681999936],
              [-34.961781491999901, -8.398574532999929],
              [-34.960731391, -8.399010212999942],
              [-34.960038772, -8.398898499999977],
              [-34.958832274, -8.395993966999985],
              [-34.958117312, -8.396172707999932],
              [-34.959457864999898, -8.399390035999946],
              [-34.959636605999897, -8.399702831999948],
              [-34.959923516999901, -8.400170718999956],
              [-34.961975261, -8.399324124999959],
              [-34.962433417999897, -8.40030019799997],
              [-34.963230211999907, -8.402272262999926],
              [-34.965212236999903, -8.406903627999952],
              [-34.966456349999902, -8.409537298999963],
              [-34.966850802, -8.410352509999937],
              [-34.967536579, -8.411867362999937],
              [-34.969960731999898, -8.417049338999965],
              [-34.971497312999901, -8.420355402999967],
              [-34.973683331, -8.425058776999956],
              [-34.975505692999903, -8.428979718999928],
              [-34.977536278999899, -8.433348668999948],
              [-34.978557924, -8.435546810999936],
              [-34.979018294999896, -8.436537331999938],
              [-34.97988635, -8.438408470999949],
              [-34.980289187, -8.439276807999937],
              [-34.9809751929999, -8.441043283999925],
              [-34.981341578999903, -8.441986733999949],
              [-34.982220345, -8.444497729999968],
              [-34.982602229, -8.445644534999923],
              [-34.982914037999898, -8.446580902999921],
              [-34.983239463, -8.447760832999965],
              [-34.983436407999903, -8.448474920999953],
              [-34.983464399999903, -8.448627031999926],
              [-34.983878881, -8.450879290999973],
              [-34.98388139, -8.450892926999925],
              [-34.984106198999903, -8.452868771999922],
              [-34.984105357, -8.452887002999942],
              [-34.984066659999897, -8.453724593999933],
              [-34.984057027999903, -8.453933073999966],
              [-34.983817299, -8.455036589999962],
              [-34.983630406, -8.455429053999985],
              [-34.983428671, -8.455852686999956],
              [-34.983184175, -8.456391185999966],
              [-34.983126075, -8.456872260999933],
              [-34.983277953, -8.457411235999936],
              [-34.983440835, -8.457737467999944],
              [-34.983577269, -8.457925256999941],
              [-34.983707406, -8.458104380999936],
              [-34.984469429999898, -8.459018011999929],
              [-34.986478, -8.461327925999974],
              [-34.988386928, -8.463778374999945],
              [-34.98943215, -8.465637142999984],
              [-34.991150702, -8.468817404999971],
              [-34.991810298, -8.469921233999965],
              [-34.993043305, -8.471984663999926],
              [-34.993162775, -8.472194340999973],
              [-34.994615492, -8.474743950999946],
              [-34.9960215629999, -8.477466128999941],
              [-34.996885185999901, -8.479345857999936],
              [-34.997500855999903, -8.480685899999962],
              [-34.998494363, -8.483128455999973],
              [-34.999630066999899, -8.485696819999932],
              [-35.000442639, -8.48787021299994],
              [-35.001020771, -8.489855060999952],
              [-35.001443169, -8.491502801999957],
              [-35.001490639999901, -8.491770680999924],
              [-35.001808663, -8.493565271999968],
              [-35.002211535999898, -8.495449249999981],
              [-35.002438911, -8.497178334999942],
              [-35.002413873, -8.498852729999944],
              [-35.002227869, -8.500571104999951],
              [-35.001831013, -8.501794560999945],
              [-35.001491452, -8.502489432999935],
              [-35.001034155999903, -8.503106476999957],
              [-35.000700939, -8.503576231999944],
              [-35.000348215, -8.504115212999977],
              [-35.000220714, -8.504575338999937],
              [-35.000180640999901, -8.504719952999956],
              [-35.00006, -8.505427304999968],
              [-35.000036824999903, -8.505563188999924],
              [-34.999896046999901, -8.506364476999977],
              [-34.999844709999905, -8.507473000999932],
              [-34.999983315999906, -8.508450935999976],
              [-35.000261, -8.509411806999948],
              [-35.00039607, -8.509879190999913],
              [-35.000757826, -8.510640250999925],
              [-35.001063234, -8.510952115999945],
              [-35.001882533999897, -8.511389030999958],
              [-35.002150785, -8.511532083999949],
              [-35.003031264999898, -8.512038048999953],
              [-35.003751655, -8.512832991999964],
              [-35.004637584, -8.514160020999942],
              [-35.005149958999901, -8.515334540999968],
              [-35.00548079, -8.517770871999966],
              [-35.006019423999902, -8.520620145999935],
              [-35.0065524299999, -8.523822915999965],
              [-35.006615794, -8.52551245399996],
              [-35.006655123, -8.526561104999937],
              [-35.006552118999906, -8.528808890999933],
              [-35.006535502, -8.529171505999949],
              [-35.006206348, -8.531404512999938],
              [-35.006167075999898, -8.531670937999925],
              [-35.005626748999902, -8.533804399999944],
              [-35.004707263999897, -8.535978122999941],
              [-35.003682999, -8.538336890999965],
              [-35.003319474, -8.538950593999973],
              [-35.00300335, -8.539750386999975],
              [-35.003125188999903, -8.540298137],
              [-35.00338106, -8.540490064999979],
              [-35.003709833999899, -8.540484775999969],
              [-35.004010961999903, -8.540916652999949],
              [-35.004868771, -8.541375540999976],
              [-35.004995977, -8.542161781999937],
              [-35.005101225, -8.542812307999956],
              [-35.0044058679999, -8.543244459999928],
              [-35.003572054, -8.543430519999957],
              [-35.002918083999901, -8.543613012999971],
              [-35.002833054, -8.543636741999933],
              [-35.002666313, -8.544001738999949],
              [-35.003037350999897, -8.54494164399995],
              [-35.003168789, -8.546690618999955],
              [-35.003105326, -8.548319333999927],
              [-35.003578395, -8.549243552999938],
              [-35.00437076, -8.550120830999955],
              [-35.005199248999901, -8.551014131999983],
              [-35.005676756, -8.551364181999926],
              [-35.005967784999903, -8.551768043999974],
              [-35.006755831, -8.552282316999936],
              [-35.008124841, -8.553400738999924],
              [-35.008744308, -8.554401608999965],
              [-35.008853534, -8.554851520999927],
              [-35.009115673999901, -8.555931298999955],
              [-35.009223505, -8.557193217999947],
              [-35.00907905, -8.557937797999951],
              [-35.008672472999898, -8.558945531999949],
              [-35.008385496, -8.559482618999944],
              [-35.008239368, -8.559756101999938],
              [-35.008014977, -8.560012989999958],
              [-35.007903374, -8.56025862899997],
              [-35.007749775, -8.560794052999942],
              [-35.007572161, -8.561305913999956],
              [-35.007375115999899, -8.561555531999948],
              [-35.007121105, -8.561644232999983],
              [-35.006913806999897, -8.561743084999932],
              [-35.006806895, -8.561994485999946],
              [-35.006920986999901, -8.562216770999955],
              [-35.006925678, -8.562222522999948],
              [-35.007288925999902, -8.562744364999952],
              [-35.007405463999902, -8.562993097999934],
              [-35.007545650999901, -8.563317911999956],
              [-35.007643268, -8.56379060799998],
              [-35.007685347999903, -8.564236422999945],
              [-35.007733768, -8.564378158999938],
              [-35.007910706, -8.564559766999924],
              [-35.0082745, -8.564635051999968],
              [-35.008416143, -8.564602751999924],
              [-35.008570982, -8.564447],
              [-35.008803688999897, -8.564212919999921],
              [-35.009384691, -8.563607133999932],
              [-35.010115624, -8.56297101699994],
              [-35.010770815999898, -8.562448905999934],
              [-35.010921034, -8.562329199999967],
              [-35.01179662, -8.561631460999919],
              [-35.013823448, -8.560632749999964],
              [-35.015611288999899, -8.56016334799995],
              [-35.016384, -8.560073791999971],
              [-35.017998553, -8.559886667999933],
              [-35.019508975, -8.559993958999945],
              [-35.020972621999903, -8.560398182999966],
              [-35.021250455, -8.560505337999928],
              [-35.022428754000011, -8.560959787999925],
              [-35.022643023999898, -8.561042428999938],
              [-35.02285633, -8.561228549999939],
              [-35.023842164, -8.562088743999938],
              [-35.025333784999901, -8.563449],
              [-35.026512661, -8.564534656999967],
              [-35.0274254569999, -8.565375274999946],
              [-35.028121568, -8.566121592999924],
              [-35.029047580999901, -8.567114395999958],
              [-35.030274683, -8.568337737999967],
              [-35.031662716, -8.569694138999985],
              [-35.032139687, -8.570049345999966],
              [-35.032823065999899, -8.570657543999976],
              [-35.033777121, -8.571612129999945],
              [-35.033796782, -8.571642572999965],
              [-35.034411725, -8.572594775999956],
              [-35.034825725, -8.573237575999935],
              [-35.035196718999899, -8.573786432999951],
              [-35.035467855, -8.574030518999953],
              [-35.036058997999902, -8.574876746999951],
              [-35.036519744, -8.575823979999949],
              [-35.036693919, -8.576671682999972],
              [-35.036628391, -8.577473632999949],
              [-35.036578348, -8.57778878699996],
              [-35.0366463, -8.577995579999939],
              [-35.036889298999903, -8.578205187999973],
              [-35.037505123, -8.578701181999968],
              [-35.037864575999897, -8.579148000999959],
              [-35.0383077279999, -8.580013149999955],
              [-35.038418756999903, -8.580986499999938],
              [-35.038252058, -8.582043736999948],
              [-35.0381933849999, -8.58245716899995],
              [-35.038750819, -8.58389129999995],
              [-35.038740659, -8.584820226999968],
              [-35.038916302, -8.585929780999944],
              [-35.039323997999901, -8.586697702999976],
              [-35.040413688999898, -8.587831854999926],
              [-35.041423494, -8.588763537999968],
              [-35.042977468999901, -8.590154850999975],
              [-35.044083784999899, -8.591422504999978],
              [-35.044728952, -8.592466682999937],
              [-35.045377125999899, -8.593843603999975],
              [-35.045935321999899, -8.595708131999967],
              [-35.046222326, -8.59762312599997],
              [-35.046206248, -8.598803574999973],
              [-35.0459969, -8.599904817999974],
              [-35.04581635800001, -8.600542494999956],
              [-35.045452828, -8.601691527999941],
              [-35.045195151, -8.602706057999971],
              [-35.044950736, -8.603358091999956],
              [-35.04487053, -8.604405875999921],
              [-35.044945100999897, -8.605171155999935],
              [-35.045149968, -8.605574012999964],
              [-35.045752440999898, -8.60647395699992],
              [-35.046609222, -8.606525386999971],
              [-35.047398745999899, -8.606227357999966],
              [-35.048012066999902, -8.606036919999944],
              [-35.04918595, -8.605672426999947],
              [-35.049191987, -8.606210813999944],
              [-35.049020965, -8.607051425999938],
              [-35.048968676068569, -8.607367167547482],
              [-35.051169069197158, -8.607351470993535],
              [-35.05128296383765, -8.607350650811838],
              [-35.051323242673448, -8.607368677722263],
              [-35.055187211320685, -8.607349633678609],
              [-35.05151625067441, -8.601827010308227],
              [-35.05189266641338, -8.600682720131374],
              [-35.054173352518148, -8.59374951732633],
              [-35.05643141527753, -8.586885088376107],
              [-35.063476137613634, -8.582469669839327],
              [-35.07679746323231, -8.574120256944241],
              [-35.097223771160088, -8.572653814322052],
              [-35.098863754158856, -8.573945061815095],
              [-35.115466688836591, -8.587017431709057],
              [-35.120452258664784, -8.586889022910043],
              [-35.18266275328341, -8.585286904966665],
              [-35.182681062580912, -8.585317292159232],
              [-35.18269231759632, -8.58531700257754],
              [-35.183274194248611, -8.586282665812348],
              [-35.183274225724915, -8.583273119658713],
              [-35.183274404689996, -8.566606237787596],
              [-35.18409468712008, -8.563409658728744],
              [-35.188429230524548, -8.561872273986182],
              [-35.201897236468255, -8.574372567140188],
              [-35.209021071265397, -8.57550722827591],
              [-35.214397312885637, -8.571340563116451],
              [-35.231318366549317, -8.570206141200401],
              [-35.240688224881637, -8.561857200449367],
              [-35.242175341119037, -8.556062871204517],
              [-35.245207476443852, -8.552150500145444],
              [-35.246398555648796, -8.530015670986359],
              [-35.25215216718874, -8.528539168547752],
              [-35.256064558932223, -8.525507080887053],
              [-35.261818169572848, -8.524030579347709],
              [-35.263009239784537, -8.501895752886639],
              [-35.266041365216779, -8.497983413303814],
              [-35.26717596429927, -8.494835708800849],
              [-35.258737219946681, -8.485365003192271],
              [-35.261874798083397, -8.481316711297154],
              [-35.263009430440832, -8.472729080582553],
              [-35.266140055019491, -8.468689605640748],
              [-35.262910979857793, -8.464522907206344],
              [-35.266041602637813, -8.460483431365219],
              [-35.266608932756014, -8.454106284777197],
              [-35.266608957937024, -8.44993961961768],
              [-35.270775624895123, -8.449939661885821],
              [-35.274318721731561, -8.449030445498636],
              [-35.274942365597667, -8.437439710474223],
              [-35.29069570624182, -8.43683240189381],
              [-35.291609048718556, -8.43327321528659],
              [-35.294033496848385, -8.433626564314466],
              [-35.297517906626069, -8.437086614155874],
              [-35.308275686873401, -8.437440049518614],
              [-35.308843039474652, -8.422729569913656],
              [-35.311875144222483, -8.418817246518643],
              [-35.313009737909056, -8.414396278265599],
              [-35.317568911276169, -8.408929441640396],
              [-35.331319604715929, -8.407706333980059],
              [-35.343565278214953, -8.396341018523117],
              [-35.35322167583962, -8.395065300523129],
              [-35.354109066580122, -8.391607158143813],
              [-35.356533504817378, -8.391960506272312],
              [-35.366251238903487, -8.401652127643501],
              [-35.366608963133103, -8.404107268735402],
              [-35.365991315946417, -8.406267398921273],
              [-35.363003814874105, -8.410357236796983],
              [-35.365991288067448, -8.414447126833409],
              [-35.367411501039896, -8.424205473403845],
              [-35.382289250948133, -8.425657902789908],
              [-35.383893091992547, -8.431267557152012],
              [-35.386824445691559, -8.43528064598496],
              [-35.388368439551812, -8.440681084764208],
              [-35.396392988545529, -8.44297540099393],
              [-35.39515767888372, -8.452100993565978],
              [-35.39222629820506, -8.456114028439629],
              [-35.390990962462922, -8.472934291484307],
              [-35.388059579985566, -8.476947324559319],
              [-35.386824272122396, -8.485434253687799],
              [-35.383892889645097, -8.48944728856145],
              [-35.382657582681247, -8.497934216790611],
              [-35.37849090852859, -8.503638283779708],
              [-35.379726142647314, -8.510434285114115],
              [-35.38271360594814, -8.514524214720666],
              [-35.379726092285296, -8.518614093965198],
              [-35.379108360562327, -8.533274366218961],
              [-35.383275009534032, -8.533274397695266],
              [-35.38448991627655, -8.538008405563403],
              [-35.393818771818815, -8.536873889218555],
              [-35.401897800842789, -8.529675131901797],
              [-35.407424011608782, -8.528256942403914],
              [-35.409125903037932, -8.521625429769699],
              [-35.416025397454064, -8.519854774378302],
              [-35.424374338205098, -8.510485027561913],
              [-35.42615659125363, -8.503540544560451],
              [-35.439652069687213, -8.504675250662217],
              [-35.443564391283587, -8.507707398577509],
              [-35.449941516287879, -8.508274745782842],
              [-35.457288590695839, -8.507557642571101],
              [-35.459246478642058, -8.500709643335313],
              [-35.468768261246225, -8.499323889685741],
              [-35.472858115309748, -8.496336424586275],
              [-35.477895512168118, -8.500016212977812],
              [-35.479725731457279, -8.493614827861961],
              [-35.482657113934636, -8.489601790290351],
              [-35.483892406509312, -8.48528152811997],
              [-35.492023129463803, -8.475911854148706],
              [-35.48111468129747, -8.474323994956364],
              [-35.476100343056316, -8.470660990612203],
              [-35.474941542318277, -8.466608244374925],
              [-35.47715202284752, -8.466040976309955],
              [-35.482307108586156, -8.462045762401942],
              [-35.484171918499214, -8.454779541542564],
              [-35.496786470519169, -8.453541149004764],
              [-35.51439752092881, -8.470207950836198],
              [-35.520774647731741, -8.470775282753038],
              [-35.524324167526288, -8.469864353157334],
              [-35.525508635814845, -8.445028597291866],
              [-35.521342004829592, -8.439652386248554],
              [-35.520207457008496, -8.431064721359746],
              [-35.517175397226765, -8.427152347602714],
              [-35.516608117470582, -8.424941852684299],
              [-35.517459051493972, -8.421626116152311],
              [-35.522985240676292, -8.420207913164631],
              [-35.526897572165183, -8.417175828201891],
              [-35.531318520633192, -8.416041264192927],
              [-35.540998295235113, -8.407261361122892],
              [-35.542175276667649, -8.381064652211876],
              [-35.549373971031912, -8.372985608798786],
              [-35.550508537738779, -8.368564612666717],
              [-35.553540599319149, -8.364652242506963],
              [-35.554958805004787, -8.359125994868748],
              [-35.55827450376529, -8.358275057248022],
              [-35.558898074786271, -8.346684261969074],
              [-35.564651589199457, -8.345207703772473],
              [-35.57273051030478, -8.338008894295001],
              [-35.579854243478508, -8.33687429791047],
              [-35.5852303636903, -8.341040978358421],
              [-35.589651291473899, -8.342175553159223],
              [-35.593563604976339, -8.345207657907054],
              [-35.616607163090066, -8.34577491338149],
              [-35.616039859951513, -8.339397706538875],
              [-35.613007793874544, -8.335485327385925],
              [-35.611732023713898, -8.32582889918433],
              [-35.60481581899478, -8.324054154575549],
              [-35.603540068619225, -8.314397744360349],
              [-35.600409534872085, -8.310358251432092],
              [-35.603540099196152, -8.306318746812678],
              [-35.604674673996954, -8.301897776760939],
              [-35.611873342280887, -8.293818763924776],
              [-35.612440651714678, -8.283274970163689],
              [-35.624940435852409, -8.28327494318404],
              [-35.625840004312693, -8.286780383607777],
              [-35.634999998650983, -8.287595136106575],
              [-35.634999999550303, -8.288333326621682],
              [-35.63583333204258, -8.288333325722363],
              [-35.635833333841219, -8.28916665911396],
              [-35.636666667232873, -8.28916665821464],
              [-35.636666667232873, -8.289999992505614],
              [-35.638333334016124, -8.289999992505614],
              [-35.638333333116805, -8.290833325897211],
              [-35.639166666508402, -8.290833325897211],
              [-35.639166665609082, -8.292499992680462],
              [-35.641835709550321, -8.292499992680462],
              [-35.642526565148842, -8.295191985521683],
              [-35.653169407542066, -8.304675440818869],
              [-35.668816896182761, -8.303540822850664],
              [-35.672729202490643, -8.300508710908218],
              [-35.686466549991223, -8.299286549334738],
              [-35.695205474552949, -8.289651786700574],
              [-35.695322757838937, -8.288333324823043],
              [-35.695833331742847, -8.288333324823043],
              [-35.695833332642167, -8.284166657864944],
              [-35.697499998526041, -8.284166658764264],
              [-35.697499999425418, -8.28333332537261],
              [-35.700000000499585, -8.28333332537261],
              [-35.699999998700946, -8.27916665841451],
              [-35.699166667107932, -8.27916665841451],
              [-35.699166666208612, -8.276666658239662],
              [-35.699999999600266, -8.276666658239662],
              [-35.700005958508143, -8.275846061046821],
              [-35.700833332092543, -8.275833325747328],
              [-35.700833332991863, -8.274999992355731],
              [-35.699999998700946, -8.274999992355731],
              [-35.699999998700946, -8.27333332557248],
              [-35.700833332092543, -8.27333332557248],
              [-35.700833332092543, -8.27222774311582],
              [-35.702018044996748, -8.271239722734947],
              [-35.706062100185704, -8.274374004856384],
              [-35.710483036962557, -8.275508546382241],
              [-35.714395361256891, -8.278540594472815],
              [-35.722982919126366, -8.279675105421745],
              [-35.731061888795068, -8.286873763813105],
              [-35.745772227206487, -8.287440942845194],
              [-35.748020504442707, -8.28797040251203],
              [-35.751857220933971, -8.291078059304937],
              [-35.760520400096368, -8.292136948961002],
              [-35.764522080129836, -8.295378200943162],
              [-35.769772719735158, -8.291125399617442],
              [-35.774166027540105, -8.29249373070104],
              [-35.774938644103088, -8.295773998870914],
              [-35.783271892583741, -8.295773935918362],
              [-35.789648988809745, -8.296341169809068],
              [-35.793561322996595, -8.29937320351047],
              [-35.79908750408498, -8.300791367827344],
              [-35.80050575113944, -8.306317528231318],
              [-35.804501009114233, -8.311472544722108],
              [-35.808271766628707, -8.312440249417932],
              [-35.808650467545021, -8.31669679552067],
              [-35.808813788925193, -8.316512986684927],
              [-35.809240993876699, -8.315793716107066],
              [-35.809770351920179, -8.314405433563877],
              [-35.811574530440168, -8.305078521456551],
              [-35.81167315459237, -8.303056121542738],
              [-35.811464953444784, -8.301054551726111],
              [-35.810944070612322, -8.299249129443695],
              [-35.809948587657175, -8.297075384421419],
              [-35.805790206153063, -8.290615904900051],
              [-35.803447612517289, -8.287729858146406],
              [-35.802018650939942, -8.285736314779058],
              [-35.79980699589612, -8.283513226654065],
              [-35.795838587181038, -8.278988627722924],
              [-35.793338455705111, -8.275670783180033],
              [-35.789323539449754, -8.271429042616205],
              [-35.787386319722827, -8.269546914458488],
              [-35.785652565313569, -8.268294663367953],
              [-35.784259431827252, -8.267718583745591],
              [-35.783325467895963, -8.26747286558026],
              [-35.78161850518984, -8.267281704786797],
              [-35.778738531557963, -8.267361647322105],
              [-35.775802781073423, -8.268021419652769],
              [-35.77478778103125, -8.268514394723468],
              [-35.77391615001028, -8.269210645354974],
              [-35.772963204088569, -8.270531349242333],
              [-35.77278702959768, -8.271046525876443],
              [-35.772748671713771, -8.272092499469238],
              [-35.773069331284546, -8.273005016368359],
              [-35.774658771485065, -8.275163370393159],
              [-35.774929009666039, -8.276133296414457],
              [-35.774952289516477, -8.276645170737993],
              [-35.77472047666862, -8.277676743486893],
              [-35.774455247711444, -8.278122211870539],
              [-35.773682919830833, -8.278808325343903],
              [-35.77318274029119, -8.279034008412737],
              [-35.772127789665717, -8.279236369362934],
              [-35.771038563179104, -8.279224410178358],
              [-35.769199254442015, -8.278830445968254],
              [-35.766440075049388, -8.27772040916949],
              [-35.762576614519105, -8.275607644477191],
              [-35.761669476465102, -8.27529904121775],
              [-35.760819613534295, -8.275187554961633],
              [-35.759762289597916, -8.275354224517798],
              [-35.75886168961523, -8.275781150679506],
              [-35.756164036441078, -8.277964766660602],
              [-35.754961883883766, -8.27857986876586],
              [-35.75410198721687, -8.27872823891704],
              [-35.753590132678426, -8.278683217956086],
              [-35.752655413316631, -8.278275019276691],
              [-35.752027317806437, -8.277708683808669],
              [-35.751286791752023, -8.27661787091796],
              [-35.749695253433174, -8.272183427223354],
              [-35.749009907081529, -8.270747317834378],
              [-35.748122331080651, -8.269360460716655],
              [-35.746650289279387, -8.267621113423615],
              [-35.74510549052593, -8.266061066664861],
              [-35.743091088283052, -8.264406379750596],
              [-35.737674123561931, -8.260913392239218],
              [-35.734041007286635, -8.258353984648636],
              [-35.733049147699944, -8.257401850814745],
              [-35.732554339810918, -8.256626041658421],
              [-35.732167447869358, -8.25510657231041],
              [-35.732172864486074, -8.254262655696948],
              [-35.732387755690354, -8.253461993775943],
              [-35.733169578613115, -8.252308979275938],
              [-35.735415558980833, -8.25058449148105],
              [-35.736090143145532, -8.249891010761473],
              [-35.736577333776893, -8.248967977190375],
              [-35.736798178493075, -8.247900504404697],
              [-35.736675798749047, -8.246244899282601],
              [-35.735879809705409, -8.244341255744303],
              [-35.734942765596088, -8.243056211774729],
              [-35.73369150915579, -8.242136201724293],
              [-35.732663862846948, -8.24182417294719],
              [-35.731589790836153, -8.241841142254884],
              [-35.730613143281914, -8.24213409011611],
              [-35.728446332931867, -8.243200467527515],
              [-35.726869135202378, -8.243443104615551],
              [-35.725288813228133, -8.243200202227513],
              [-35.723116367726163, -8.242124744361433],
              [-35.722146710602146, -8.241824662178374],
              [-35.721090667300416, -8.241792206545028],
              [-35.720767709961819, -8.241883100124937],
              [-35.720093108709932, -8.2420729622973],
              [-35.71887989988744, -8.242926581696338],
              [-35.718278660831515, -8.243722955649844],
              [-35.717866296492332, -8.244622700377249],
              [-35.717684046181716, -8.245660410999108],
              [-35.717763650571953, -8.249000438219582],
              [-35.717428357232848, -8.250264646696053],
              [-35.716612228771282, -8.2516332295898],
              [-35.715425435735256, -8.252690873684799],
              [-35.714409445539445, -8.253159968157945],
              [-35.71273203275706, -8.253422538719121],
              [-35.711043570904053, -8.253301488173122],
              [-35.709951973804493, -8.252958108130144],
              [-35.70865071865569, -8.252015880328543],
              [-35.707735841036197, -8.250710502687525],
              [-35.707389750436562, -8.249645906833109],
              [-35.707046140167165, -8.247097669721654],
              [-35.70702138992516, -8.245604395826149],
              [-35.707276562663196, -8.24414147494906],
              [-35.708364587655581, -8.24078662690124],
              [-35.708544392709484, -8.239121937727191],
              [-35.708496037961766, -8.237999393157395],
              [-35.708282180078527, -8.236898014132009],
              [-35.707449986127926, -8.23496934425981],
              [-35.706876677316757, -8.234005361857896],
              [-35.705797185091967, -8.232672104334085],
              [-35.701652312304304, -8.228573935138968],
              [-35.699822667681929, -8.22725970187372],
              [-35.698143277290399, -8.226348538454261],
              [-35.697282465113631, -8.226017768704992],
              [-35.696204342556314, -8.22585453995498],
              [-35.695127346849517, -8.225922810189502],
              [-35.693887002782901, -8.226227011267497],
              [-35.691958917470004, -8.226888533678846],
              [-35.690325629024528, -8.227178462516008],
              [-35.68863414904672, -8.227198119897253],
              [-35.687521022177407, -8.227061122572707],
              [-35.683928373595506, -8.226028847453279],
              [-35.67263745905143, -8.222149541767919],
              [-35.670838964246741, -8.221724092292959],
              [-35.66974355402175, -8.221644543660716],
              [-35.668649548537815, -8.221744529386456],
              [-35.666869641820483, -8.222234500721584],
              [-35.665174662580569, -8.222968651482006],
              [-35.66248337930449, -8.224482313907629],
              [-35.660845676087035, -8.224882988858781],
              [-35.659133013477742, -8.224997534608463],
              [-35.656849708547156, -8.224858239516095],
              [-35.655749702786522, -8.224634704927666],
              [-35.653759763901917, -8.223990708504346],
              [-35.6495983660717, -8.222874512248893],
              [-35.647336323812226, -8.222605684204893],
              [-35.645022168538219, -8.22260012189804],
              [-35.64331349262352, -8.222766133150458],
              [-35.642377475540002, -8.222968110989484],
              [-35.640581265912658, -8.223515959993506],
              [-35.636013560677213, -8.22525146088617],
              [-35.634215680109492, -8.225704392743467],
              [-35.631626559817846, -8.226095216521003],
              [-35.630666754766878, -8.226443422326042],
              [-35.629919512575384, -8.227091474691747],
              [-35.629657906087459, -8.227532640718721],
              [-35.629473358908342, -8.228596129507707],
              [-35.629565652732651, -8.229130328601741],
              [-35.630011095935288, -8.230022090946704],
              [-35.630662579214629, -8.230770988790027],
              [-35.631437755248157, -8.231427388663008],
              [-35.634566020905083, -8.233374919117182],
              [-35.637447766201433, -8.234726003901926],
              [-35.64097037288434, -8.235967284162768],
              [-35.645426392214176, -8.236919126616328],
              [-35.646378681630836, -8.237337477742358],
              [-35.647532814887427, -8.238294596518358],
              [-35.64801835796078, -8.239041931340012],
              [-35.648337203598999, -8.239897164122794],
              [-35.648515119177375, -8.240966129783033],
              [-35.648740972218036, -8.244884114410297],
              [-35.64896264071325, -8.245980846638702],
              [-35.649327089173767, -8.246956859271563],
              [-35.650291013119727, -8.248868155141224],
              [-35.653767710311513, -8.25357776620092],
              [-35.655222743234958, -8.255833325247693],
              [-35.655388966727401, -8.256091001698337],
              [-35.656571240670189, -8.257613483775231],
              [-35.657576630557003, -8.258367198387475],
              [-35.658751493186799, -8.258829749393385],
              [-35.661357796824802, -8.259267440439999],
              [-35.66237940598478, -8.259589434604663],
              [-35.66316750077948, -8.260072093552253],
              [-35.664107829212924, -8.261095960010493],
              [-35.664828030589092, -8.2627984899583],
              [-35.665010541703111, -8.263900908600021],
              [-35.665041055700101, -8.265028006437319],
              [-35.66488616726275, -8.266715824375751],
              [-35.664065761625579, -8.270400255067671],
              [-35.663976250303676, -8.272095014873003],
              [-35.664069365209002, -8.273224627214745],
              [-35.664496982949345, -8.274801315927959],
              [-35.666011580669874, -8.277998317668164],
              [-35.666453830379908, -8.279571350637298],
              [-35.666332869766109, -8.280509217626218],
              [-35.665990639956021, -8.2810767716756],
              [-35.665630927325367, -8.281386583623885],
              [-35.664887163711569, -8.281622110671833],
              [-35.663228895696932, -8.281438647175776],
              [-35.660895066853811, -8.280834667885586],
              [-35.659841458916162, -8.280828277303101],
              [-35.658834093218786, -8.281036306680164],
              [-35.655472204378668, -8.28227743765359],
              [-35.654389213791148, -8.282494602344002],
              [-35.653276069834703, -8.282551572596958],
              [-35.652166580723076, -8.282455087931908],
              [-35.650879796565334, -8.282164352402901],
              [-35.648602508099202, -8.281366160020241],
              [-35.646968270868967, -8.280493898574491],
              [-35.64479196637609, -8.278831384860496],
              [-35.643620004059869, -8.277577318938086],
              [-35.642047956747774, -8.275313441162325],
              [-35.640675322408185, -8.273659399061955],
              [-35.639119497067099, -8.272157396346699],
              [-35.637813753401929, -8.27123590421354],
              [-35.636088839328409, -8.270488631445119],
              [-35.63427169618177, -8.269998946094461],
              [-35.633155508020252, -8.269846207936098],
              [-35.628033958900062, -8.269552742964663],
              [-35.62710512290306, -8.26932897275384],
              [-35.625709748306178, -8.268781747879302],
              [-35.624757625264124, -8.268186110700412],
              [-35.623449458825405, -8.267087622995348],
              [-35.621666667982311, -8.265274257001295],
              [-35.617300170766214, -8.260832874137805],
              [-35.616034589924368, -8.259648065905481],
              [-35.614696368142859, -8.258602641798461],
              [-35.612524267980518, -8.257534214832106],
              [-35.60801351950829, -8.255891656174924],
              [-35.602808176056442, -8.253777878845995],
              [-35.601426448671646, -8.252590421210925],
              [-35.596959387465688, -8.2496621234082],
              [-35.595597786008966, -8.248540169693058],
              [-35.592147945271392, -8.245156720506202],
              [-35.587721796024027, -8.240379707957118],
              [-35.586553796120768, -8.239364493876224],
              [-35.585206700728634, -8.238619832739062],
              [-35.584211841902913, -8.238341784845431],
              [-35.583176939863847, -8.238296557939748],
              [-35.580845468143821, -8.238622193459435],
              [-35.580008158849409, -8.238536205681328],
              [-35.578752344644954, -8.238134405678238],
              [-35.57794482988993, -8.237587888570147],
              [-35.574975737040575, -8.234513414962066],
              [-35.573687408746878, -8.233422087659164],
              [-35.572310499030266, -8.232575681722267],
              [-35.570725131859319, -8.232048259919225],
              [-35.569046944760657, -8.231886986295308],
              [-35.567416589004324, -8.232016199987584],
              [-35.566409630699866, -8.232333370188826],
              [-35.565569089242047, -8.232948742990061],
              [-35.565128634578798, -8.23351682224353],
              [-35.564678406982864, -8.23442007252828],
              [-35.56351952980242, -8.237822373303857],
              [-35.562791595155943, -8.239523957164863],
              [-35.56179168663698, -8.240882412793042],
              [-35.560507427775519, -8.241976713254644],
              [-35.559022189322548, -8.242658516277459],
              [-35.555408352713243, -8.243747956802736],
              [-35.552405957968517, -8.244516707180253],
              [-35.551228533170217, -8.244605378535368],
              [-35.550180018093329, -8.244314321948366],
              [-35.549716497618249, -8.244071493304716],
              [-35.548945930610159, -8.243434414466606],
              [-35.548250833808879, -8.242461717634114],
              [-35.545434781290055, -8.237085119882352],
              [-35.544378918752045, -8.234630694650775],
              [-35.543123939118459, -8.232286151284825],
              [-35.541483722295879, -8.230209241663147],
              [-35.54004984772223, -8.22855674999397],
              [-35.539536577650892, -8.228110063928284],
              [-35.538096747766645, -8.227277083970193],
              [-35.536622573672787, -8.226846306911511],
              [-35.53077069501154, -8.226570938997611],
              [-35.527568539256663, -8.226099204114917],
              [-35.526174124236377, -8.225779338645509],
              [-35.522329886714829, -8.224181653460505],
              [-35.518846999489369, -8.223000327403156],
              [-35.516463280755772, -8.221778112769641],
              [-35.515097253735235, -8.221227718684247],
              [-35.511268937811167, -8.220097982236041],
              [-35.50987988275034, -8.219559676837548],
              [-35.507409011616573, -8.218022094244191],
              [-35.505126262467002, -8.215906748497616],
              [-35.504096607072711, -8.214739458159443],
              [-35.503144801491374, -8.213444281528325],
              [-35.501494864796143, -8.210751568330181],
              [-35.500392183552378, -8.207819602192842],
              [-35.500260162176687, -8.206446442649167],
              [-35.500383754206837, -8.205524485566514],
              [-35.50073920764828, -8.204584321709035],
              [-35.501319068020507, -8.203695841889555],
              [-35.502392640008281, -8.202499303799812],
              [-35.50359787094493, -8.20141400285388],
              [-35.504906651620615, -8.200485359311813],
              [-35.506792221483806, -8.199476814603258],
              [-35.511659865010699, -8.197070921285956],
              [-35.506534828319616, -8.191962059801199],
              [-35.495777217144791, -8.191608704478085],
              [-35.475859602638479, -8.192207012643451],
              [-35.474092883579658, -8.199090899325483],
              [-35.470777142551071, -8.199941799174667],
              [-35.470419102659434, -8.197484967357298],
              [-35.464158345431258, -8.19125510274074],
              [-35.460019481014172, -8.191961716260153],
              [-35.452368298158376, -8.199588314349171],
              [-35.447519392006313, -8.200294921573345],
              [-35.444034924671996, -8.203754898569684],
              [-35.435019361454351, -8.204461468921579],
              [-35.431534895918674, -8.207921446817295],
              [-35.429110444191622, -8.208274750879013],
              [-35.425794725646028, -8.207423791674614],
              [-35.42409294393417, -8.200792321308541],
              [-35.418566746657973, -8.19937405536831],
              [-35.414654436752812, -8.196341937130626],
              [-35.408277295560708, -8.195774595321268],
              [-35.407634636429293, -8.189472892859669],
              [-35.404134334731737, -8.184536145426705],
              [-35.39780908801896, -8.182631796819919],
              [-35.388243118125331, -8.174439795859826],
              [-35.386801615502122, -8.160306066771511],
              [-35.375587141533288, -8.147909349436873],
              [-35.374301691969492, -8.143639237960429],
              [-35.36618574847347, -8.134161737973784],
              [-35.356142755683607, -8.132631366144608],
              [-35.352079519754, -8.129750129864533],
              [-35.347809436156524, -8.128464624542744],
              [-35.343746196629638, -8.125583386464086],
              [-35.329882582515154, -8.124169143191352],
              [-35.329111192627408, -8.116607207256777],
              [-35.329678484974067, -8.114396714137001],
              [-35.332809078975743, -8.110357205920252],
              [-35.329678510155077, -8.106317675220509],
              [-35.328543952441407, -8.101896682685719],
              [-35.321345249983267, -8.093817622185441],
              [-35.320210696766196, -8.088655389534551],
              [-35.32837259382012, -8.086560717403131],
              [-35.329678594691359, -8.078360461565978],
              [-35.325511967303385, -8.07298425771728],
              [-35.324944702835751, -8.066607084149553],
              [-35.318353621022766, -8.066253738719013],
              [-35.314157663145579, -8.062087050177183],
              [-35.310020348260423, -8.062793692474884],
              [-35.301660408800785, -8.071127016498565],
              [-35.289157766617564, -8.070418631314737],
              [-35.283631599918351, -8.064864744812098],
              [-35.282920229884667, -8.059983447777824],
              [-35.276659449274121, -8.053753592154521],
              [-35.260020684706774, -8.054460198479319],
              [-35.256536255143999, -8.057920209649922],
              [-35.25411181510816, -8.058273529899452],
              [-35.251687375971585, -8.058626848350343],
              [-35.248202942811531, -8.062086858621569],
              [-35.245778502775636, -8.06244017797178],
              [-35.245425162741014, -8.064864634195487],
              [-35.237798469323877, -8.072515718125828],
              [-35.23744501327667, -8.091606866463678],
              [-35.237798233701483, -8.106531342447454],
              [-35.241964855693482, -8.110727358780537],
              [-35.241258180120894, -8.114864698846702],
              [-35.23779815276248, -8.118349123013559],
              [-35.23744469491669, -8.137440269552769],
              [-35.231117913061212, -8.136822606178271],
              [-35.227104883583479, -8.1338912048152],
              [-35.218617976938049, -8.132655889757473],
              [-35.210438329065596, -8.125557802550475],
              [-35.206118079485748, -8.12432250188192],
              [-35.202105055403933, -8.121391103216808],
              [-35.185284849915831, -8.120155762078696],
              [-35.181271827632713, -8.117224360715625],
              [-35.17278492998048, -8.115989048355857],
              [-35.167316241651179, -8.11161517849763],
              [-35.165994335370215, -8.09361316491561],
              [-35.15375168539191, -8.080194734998997],
              [-35.147785187236536, -8.078488713883019],
              [-35.14377250129985, -8.075557271151069],
              [-35.134073028847183, -8.074145354424502],
              [-35.132510635757171, -8.063411106737135],
              [-35.125872074236781, -8.061512988633467],
              [-35.124328280026077, -8.056112541760342],
              [-35.121052436520699, -8.051627935680642],
              [-35.116143205644391, -8.049321584723316],
              [-35.109491693886525, -8.054180398706364],
              [-35.107352825472447, -8.046698815397974],
              [-35.100663062850003, -8.044786117485273],
              [-35.099945971329475, -8.037439001708492],
              [-35.089402168575191, -8.036871569966934],
              [-35.084026036672185, -8.032704830163709],
              [-35.059290887964039, -8.03388416602138],
              [-35.050513275467267, -8.043561159919534],
              [-35.049378659297645, -8.047982120078757],
              [-35.042179839028279, -8.056061033989465],
              [-35.040761738563333, -8.061587229467023],
              [-35.035235339839005, -8.06300537040147],
              [-35.031322966081973, -8.066037403203552],
              [-35.024945814097975, -8.066604601121412],
              [-35.02459137059526, -8.077391018480682],
              [-35.01903679251393, -8.082917833591125],
              [-35.016612341686198, -8.083271123263671],
              [-35.015244522316834, -8.078486802823647],
              [-34.99658641912788, -8.079806621778118],
              [-34.995832919453619, -8.084984118622117],
              [-34.996005658534273, -8.085046013562646],
              [-34.996450073811786, -8.085127560488502],
              [-34.997159135285813, -8.085339371514749],
              [-34.998012725906506, -8.085658109234316],
              [-34.998986437174267, -8.086000175367758],
              [-34.999876031253791, -8.086318733222924],
              [-35.000633270309152, -8.086566182583226],
              [-35.001726980815477, -8.086907666855268],
              [-35.002965048698002, -8.087320197568999],
              [-35.004215116234604, -8.08773266353154],
              [-35.005044592433933, -8.088027592599246],
              [-35.005825837092686, -8.088274916953822],
              [-35.006630901195081, -8.088486244144804],
              [-35.007507967718482, -8.088697217902222],
              [-35.008397155304408, -8.088932047975561],
              [-35.009058161504925, -8.089132116553913],
              [-35.00949122913687, -8.08934526127473],
              [-35.009996295592543, -8.08955805256204],
              [-35.010657777534334, -8.089853792818246],
              [-35.011223083278651, -8.090114124966874],
              [-35.01192021164843, -8.090337928452584],
              [-35.012400922664426, -8.090479080645025],
              [-35.012856868149925, -8.090464881249204],
              [-35.01337316623858, -8.09052213928527],
              [-35.013661405249593, -8.090568560490624],
              [-35.013997583521643, -8.090602785989745],
              [-35.014238000183525, -8.090685319471845],
              [-35.014334414701409, -8.090768560720448],
              [-35.014331760802065, -8.090773915283933],
              [-35.014361611099446, -8.090799687155766],
              [-35.014266559953739, -8.090991504454337],
              [-35.01395621920409, -8.091327897664314],
              [-35.013287181158432, -8.091929162800568],
              [-35.013257409102096, -8.093185047152133],
              [-35.014067593044956, -8.094424834538415],
              [-35.015265114993042, -8.096332435993077],
              [-35.014759679815313, -8.098463699739909],
              [-35.01336687278291, -8.098315093966391],
              [-35.011829336054973, -8.100439483295474],
              [-35.008350467899731, -8.100707752860444],
              [-35.007699151894315, -8.102468992350055],
              [-35.005119758669878, -8.102625177609809],
              [-35.005095037206161, -8.102593820948016],
              [-35.005092518205117, -8.102593973832768],
              [-35.004942592226826, -8.102403813984779],
              [-35.004769911602068, -8.102256152499365],
              [-35.004553015808995, -8.102077826830225],
              [-35.004420656288232, -8.10200671923468],
              [-35.004252529830353, -8.101983625543824],
              [-35.001540952452956, -8.102104558278711],
              [-35.000336180170507, -8.101153701482076],
              [-35.00033589688411, -8.101143731597915],
              [-35.00030894959832, -8.101122462631508],
              [-35.000236392295619, -8.098575447698693],
              [-35.000355335730092, -8.098359594219858],
              [-35.000629992280267, -8.098083183991719],
              [-35.001095822214381, -8.097638406287388],
              [-35.00146565401667, -8.09719409533119],
              [-35.001800189227311, -8.096893472655609],
              [-35.001835187244069, -8.096689990250809],
              [-35.001786183185743, -8.096486919735526],
              [-35.001666029263788, -8.096226884363148],
              [-35.001486966150537, -8.096029401335784],
              [-35.001197364666609, -8.095707914388697],
              [-35.000871526799472, -8.095338765171846],
              [-35.00087012925303, -8.095336772274152],
              [-35.000844323206934, -8.095307536213795],
              [-35.000518016793023, -8.094842711721697],
              [-35.000335717919029, -8.094377180362528],
              [-35.00022529915816, -8.093887382596563],
              [-35.000175234799201, -8.093469046759026],
              [-35.000197763715789, -8.093169948434308],
              [-35.000132179756235, -8.093068345727545],
              [-34.999972126512375, -8.093034985376221],
              [-34.999732004828104, -8.093012241521706],
              [-34.999120107906606, -8.093039156431871],
              [-34.998628391886712, -8.093101360739183],
              [-34.998484093865784, -8.093042269884791],
              [-34.998466881741138, -8.09301512115087],
              [-34.998456899266444, -8.093011034631502],
              [-34.998415525056373, -8.092945776226543],
              [-34.998339561121782, -8.092935341392831],
              [-34.998207556833222, -8.092935986206726],
              [-34.997955904941023, -8.093008974284714],
              [-34.997308891292278, -8.093215450532568],
              [-34.995942503643562, -8.093556997757219],
              [-34.994672057469415, -8.093886112753864],
              [-34.993389784311034, -8.094251158364216],
              [-34.992634642474741, -8.094434238749045],
              [-34.991939737229075, -8.094664860894568],
              [-34.991926990238369, -8.094669649784464],
              [-34.990453548192647, -8.099823547518668],
              [-34.985439129012548, -8.103486475420482],
              [-34.97278514891542, -8.104721698747369],
              [-34.968772391932305, -8.107653059640938],
              [-34.964451761039925, -8.108888320739368],
              [-34.960439004956072, -8.111819680733618],
              [-34.950917151305418, -8.113205473953428],
              [-34.949327811828994, -8.118764066608321],
              [-34.942229335215586, -8.126943753151636],
              [-34.940994275565345, -8.139597337547059],
              [-34.933895803448536, -8.147777025889013],
              [-34.933278310945241, -8.149937157873467],
              [-34.933881403503904, -8.165577909692729],
              [-34.947734266223392, -8.178536596286847],
              [-34.954678710553992, -8.180318851134018],
              [-34.953543922613903, -8.206314440159247],
              [-34.949377198099171, -8.211690619726255],
              [-34.95068328800329, -8.219890879160687],
              [-34.954111097149394, -8.220770641851573],
              [-34.95411106297513, -8.224937316004286],
              [-34.958277746121041, -8.224937322299525],
              [-34.958277712846154, -8.229103996452238],
              [-34.957924368314877, -8.231528450877249],
              [-34.954124542913291, -8.235354846748749],
              [-34.966257614097572, -8.247512911891306],
              [-34.966610923555209, -8.249937367215693],
              [-34.960196100769394, -8.249407881468528],
              [-34.956359029945247, -8.246300165320349],
              [-34.947696074714031, -8.245241194725281],
              [-34.947531569826026, -8.245107959264885],
              [-34.947534033968452, -8.245115112472433],
              [-34.947536136583381, -8.245129124809239],
              [-34.947751871351727, -8.245303853190251],
              [-34.956414794207319, -8.246362812094048],
              [-34.960251871326761, -8.24947052104767],
              [-34.966666666233607, -8.249999998700957],
              [-34.966313341487478, -8.264924452201683],
              [-34.962853325820276, -8.268408879965818],
              [-34.962499999275508, -8.270833334390829],
              [-34.957715860498524, -8.272201197826973],
              [-34.958950805934876, -8.277160135132078],
              [-34.961882528355943, -8.281173196985378],
              [-34.963117471993712, -8.289660136006432],
              [-34.966049193515403, -8.293673196960412],
              [-34.967825316784911, -8.299886067088266],
              [-34.972839693696869, -8.303549025567008],
              [-34.978398301640254, -8.305138311983455],
              [-34.978763452471298, -8.307647262211333],
              [-34.978469233867997, -8.307758828507076],
              [-34.97845689157225, -8.307764490638704],
              [-34.978449082758914, -8.307833570263028],
              [-34.978404094173584, -8.308182948784008],
              [-34.978471216873118, -8.308232573374539],
              [-34.978734772191956, -8.308111666719981],
              [-34.978781492871576, -8.308093950975035],
              [-34.979118277286602, -8.307966245445812],
              [-34.979574660741946, -8.30798789482543],
              [-34.979983732663072, -8.308153292740201],
              [-34.980911417527864, -8.308510298411477],
              [-34.980882312768472, -8.308453038576829],
              [-34.980903601519969, -8.308388349442737],
              [-34.98098252242545, -8.308323374324232],
              [-34.981090011195022, -8.308208029976583],
              [-34.981276133087022, -8.307970304985531],
              [-34.981411786824481, -8.307725653416128],
              [-34.981532854457612, -8.307445196238746],
              [-34.981632167490488, -8.307136146915582],
              [-34.981702985504342, -8.306891820900773],
              [-34.981773481560879, -8.306582912771148],
              [-34.981794121901146, -8.306389063904135],
              [-34.982074423495817, -8.303381043900004],
              [-34.982232026986424, -8.301765721211041],
              [-34.982252382241597, -8.301514468617768],
              [-34.982265424209913, -8.301241726724243],
              [-34.982278467077549, -8.300968984830718],
              [-34.982269787720497, -8.30067482378405],
              [-34.982246736297782, -8.30038790857509],
              [-34.982230785022693, -8.300079432120071],
              [-34.982200530929731, -8.299792554682597],
              [-34.982163144313631, -8.299520063699958],
              [-34.982104470744673, -8.299312259153396],
              [-34.981988021130235, -8.299076040526813],
              [-34.981871606589323, -8.298846995792189],
              [-34.981762468463103, -8.298632267365178],
              [-34.981631109887758, -8.298295662814553],
              [-34.981558098427456, -8.298102281594993],
              [-34.981506449463041, -8.297858564421176],
              [-34.981512540571259, -8.297636083838142],
              [-34.981518522861506, -8.297392081579233],
              [-34.981495868939135, -8.297184096269007],
              [-34.981437377033274, -8.297012171974131],
              [-34.98136425855364, -8.29679726368272],
              [-34.981341859139434, -8.296639510005321],
              [-34.981391280483138, -8.296438342455474],
              [-34.981527186930066, -8.296243921619634],
              [-34.981785304947834, -8.295998663907142],
              [-34.981978914595175, -8.295818306669446],
              [-34.982230152799275, -8.295637664346714],
              [-34.982539310041091, -8.295514138866395],
              [-34.982776715772786, -8.29544837684108],
              [-34.983064729953298, -8.295418241458606],
              [-34.983345789676434, -8.295438368286],
              [-34.983857807891468, -8.295543454966435],
              [-34.984319216758422, -8.295612915903291],
              [-34.984751813145635, -8.295682518933063],
              [-34.98496072475757, -8.295681476618824],
              [-34.985090000503078, -8.295601900107556],
              [-34.985204760291424, -8.295500868470242],
              [-34.985247443015055, -8.295393021770508],
              [-34.985213794880679, -8.295268194971186],
              [-34.985122988535068, -8.295195456005445],
              [-34.984891780930923, -8.295060268116856],
              [-34.984530938751789, -8.2949329025314],
              [-34.984227585734459, -8.294776545501122],
              [-34.984212824262386, -8.294752062357759],
              [-34.984199604228309, -8.294745248194602],
              [-34.98406157987796, -8.294516313177269],
              [-34.984017061638042, -8.294258209548673],
              [-34.984008958746415, -8.29407885775305],
              [-34.9840875540973, -8.293949303217744],
              [-34.984209662246087, -8.293876939269296],
              [-34.984396819257768, -8.293847303909899],
              [-34.984555590967716, -8.293903917132013],
              [-34.984902240046324, -8.294074409706184],
              [-34.985140401208525, -8.294159330888363],
              [-34.985291574546466, -8.294137050184645],
              [-34.985427800252751, -8.294007209664926],
              [-34.985470231166175, -8.293849133130948],
              [-34.985418834011909, -8.293655644892056],
              [-34.985295396665208, -8.293462515482645],
              [-34.985178983023616, -8.29323347254666],
              [-34.985141814043459, -8.293004035707611],
              [-34.985097366849971, -8.292760282560948],
              [-34.985132091472792, -8.292501784129968],
              [-34.985217639482414, -8.292321965586211],
              [-34.985317881514959, -8.292199479722171],
              [-34.985497437456729, -8.292090949537624],
              [-34.985749248528975, -8.29202511376792],
              [-34.986073240987253, -8.291987621931185],
              [-34.986282224544993, -8.292000931897462],
              [-34.986419457491934, -8.292072003520104],
              [-34.986431058746348, -8.292094827414303],
              [-34.986447422810272, -8.292103302625264],
              [-34.986520180661785, -8.29224645580922],
              [-34.986571723506188, -8.292468643213283],
              [-34.98661656820002, -8.292791327158],
              [-34.98668922173016, -8.292884988851085],
              [-34.986827336912029, -8.292959757586686],
              [-34.986949873138087, -8.292973499227571],
              [-34.987064846965097, -8.292915521733903],
              [-34.98713641681212, -8.292821879825851],
              [-34.987164404613566, -8.292656698647704],
              [-34.987084225556544, -8.292470530890228],
              [-34.986989207685724, -8.292198328589961],
              [-34.986952036906928, -8.291968891750912],
              [-34.98701575567253, -8.291746125183465],
              [-34.987151584777791, -8.291537354765069],
              [-34.987323901177035, -8.291421684862826],
              [-34.987460773495854, -8.291421000478749],
              [-34.987576358861816, -8.291485005228765],
              [-34.98759014816676, -8.291508457749103],
              [-34.987604317884916, -8.291516304333925],
              [-34.987684245131788, -8.291652245854436],
              [-34.98771367094912, -8.291774086005205],
              [-34.987751092638746, -8.291847709903834],
              [-34.987823233555332, -8.29187126224889],
              [-34.987895163131213, -8.291849377246933],
              [-34.987952540777087, -8.291798861428276],
              [-34.988016943027446, -8.291712432982365],
              [-34.988102632230664, -8.291561315402362],
              [-34.988245629831908, -8.291345332421088],
              [-34.988439305129759, -8.291179324766006],
              [-34.98858309323515, -8.291121201582143],
              [-34.988698317972933, -8.29111345122476],
              [-34.988835514047707, -8.291177348955443],
              [-34.988857054609355, -8.291205660512787],
              [-34.988863467674832, -8.291208647161284],
              [-34.9889504896733, -8.291323024737778],
              [-34.989022996613983, -8.291415946288851],
              [-34.989059772590394, -8.291566452329846],
              [-34.989067771160649, -8.291724277952994],
              [-34.989047168591924, -8.291925300712023],
              [-34.98897603311741, -8.292105047310031],
              [-34.988833289124955, -8.292371258326853],
              [-34.988754877235749, -8.292536692214526],
              [-34.988705098861203, -8.292666102858334],
              [-34.988691772707114, -8.292881440126337],
              [-34.988736581428043, -8.293196946581759],
              [-34.988781140137462, -8.293462225900953],
              [-34.988753621782166, -8.293720687459711],
              [-34.988690157524729, -8.293993680264066],
              [-34.988576338427208, -8.294281276259824],
              [-34.988390337943713, -8.294540528322671],
              [-34.988233257858496, -8.294821165364453],
              [-34.988104814885276, -8.295065780960954],
              [-34.98801941526375, -8.29527430046852],
              [-34.98795598518052, -8.29555446896353],
              [-34.98794265902643, -8.295769806231533],
              [-34.987994238743056, -8.295999171124777],
              [-34.988060190525346, -8.29622128838173],
              [-34.988176643737063, -8.296457505209673],
              [-34.988320278058325, -8.296663388804291],
              [-34.988481816982244, -8.29677615299704],
              [-34.988705642951004, -8.296875494808205],
              [-34.989087773881636, -8.296938170360249],
              [-34.989527537865513, -8.297000554531905],
              [-34.989859457847672, -8.297106533339786],
              [-34.990112320227354, -8.297248783604459],
              [-34.990394027462344, -8.297398065668176],
              [-34.990762185626295, -8.297546915157966],
              [-34.991202636692265, -8.297745634753198],
              [-34.991636207045303, -8.298008964342159],
              [-34.991657890599129, -8.298036449422568],
              [-34.991664174162281, -8.298040264346696],
              [-34.991867183523652, -8.298297574773187],
              [-34.991976255999361, -8.298497947322346],
              [-34.992035113929319, -8.29874162672462],
              [-34.992036416147641, -8.2989999452912],
              [-34.991994854878612, -8.299330233903106],
              [-34.991916950207042, -8.299596123861988],
              [-34.991796109203051, -8.299919632485],
              [-34.991667558311121, -8.300142722808346],
              [-34.991481701719124, -8.300430679432282],
              [-34.991331863874393, -8.30071845529244],
              [-34.991174857533622, -8.301013443715419],
              [-34.991054017428894, -8.301336952338431],
              [-34.99089744366205, -8.301718046350686],
              [-34.990747678662387, -8.302020175390737],
              [-34.990648557185125, -8.302365101368309],
              [-34.990613766911792, -8.302609249317356],
              [-34.990579083657735, -8.302874922539615],
              [-34.990587695565637, -8.303154731305767],
              [-34.99060358568687, -8.30344885458095],
              [-34.990590478068043, -8.303707245992541],
              [-34.990591453832508, -8.303900983343681],
              [-34.990527231446549, -8.304023290242583],
              [-34.990362008899524, -8.304117400697464],
              [-34.990023817944802, -8.30419802491889],
              [-34.989642403773871, -8.304278863178922],
              [-34.989260950932078, -8.304352527547053],
              [-34.98885057319535, -8.304404807835567],
              [-34.988281744806613, -8.304465054318769],
              [-34.987734129626347, -8.304446262984584],
              [-34.987496539533652, -8.304476150154187],
              [-34.987396151810913, -8.304569935953737],
              [-34.987195342191228, -8.304750332761614],
              [-34.986987579912864, -8.304980990879983],
              [-34.986744371755321, -8.305326637214478],
              [-34.986515284752556, -8.30561480967566],
              [-34.986322759687539, -8.306010435832889],
              [-34.986123281963842, -8.306456322401289],
              [-34.986030855039871, -8.306700757234125],
              [-34.985937959569128, -8.306851910787032],
              [-34.985830366478183, -8.306945732559484],
              [-34.985679910799206, -8.307111523478],
              [-34.985522755170962, -8.307377809138529],
              [-34.985401473499167, -8.307615212172266],
              [-34.985323346695054, -8.307838049786142],
              [-34.985259988557573, -8.308132568762971],
              [-34.985260961624022, -8.30832630881207],
              [-34.985319531770926, -8.308512584488142],
              [-34.985443013184465, -8.308712887789511],
              [-34.985638820774909, -8.308970235987545],
              [-34.985747931022161, -8.309177787824581],
              [-34.985813849529507, -8.309392728491559],
              [-34.98588708222303, -8.309629160257487],
              [-34.985953144621874, -8.309872804586234],
              [-34.98601924389294, -8.310123624605524],
              [-34.986128283093763, -8.310316824162044],
              [-34.985709755700611, -8.310182574266321],
              [-34.985334743803321, -8.310105514957513],
              [-34.985644941560736, -8.310931116877498],
              [-34.985863473220661, -8.311408405973111],
              [-34.985889531976284, -8.311814898638602],
              [-34.985917904687483, -8.312756516699835],
              [-34.986251614320508, -8.312953685863874],
              [-34.986781608184629, -8.313285903521603],
              [-34.987335978172837, -8.313689756676865],
              [-34.987355845995467, -8.313715102270123],
              [-34.987364011839645, -8.313721051285427],
              [-34.987774898592647, -8.314245214844732],
              [-34.988066561322682, -8.314937406835838],
              [-34.988116879290487, -8.315391615730448],
              [-34.988023231986517, -8.315870463351132],
              [-34.987764083345724, -8.316372981128154],
              [-35.006326971712326, -8.317284307324883],
              [-35.010339695420555, -8.320215690701502],
              [-35.018826971687361, -8.321450974282982],
              [-35.022839695395589, -8.324382356760282],
              [-35.041666666983076, -8.325000001249009],
              [-35.0410698777726, -8.332231988515389],
              [-35.031414794956788, -8.341137186931576],
              [-35.013628472975597, -8.342245484441491],
              [-34.996362982565529, -8.360251702354105],
              [-34.995303683717964, -8.364748297296046],
              [-34.99219631560743, -8.368585035371041],
              [-34.990894232183052, -8.374113632937735],
              [-34.986500718433376, -8.37548200808817],
              [-34.981414795056708, -8.371362814542238],
              [-34.976918538259895, -8.370303852940424],
              [-34.971773953005481, -8.366137186881645],
              [-34.967229885550751, -8.367646280751217],
              [-34.967151604447636, -8.369826618849624],
              [-34.967515551, -8.368913565999954],
            ],
            [
              [-35.124566962795029, -8.107581577927419],
              [-35.124684844130229, -8.107594370783545],
              [-35.124804915314542, -8.107598128151039],
              [-35.124925062941259, -8.107610909315952],
              [-35.125027031672118, -8.107617032799794],
              [-35.125124423753107, -8.107616426656705],
              [-35.125237635808219, -8.107611200696283],
              [-35.125337204248524, -8.107597034575463],
              [-35.125443579657428, -8.107585081686125],
              [-35.125577174846683, -8.107579730719976],
              [-35.125695022906939, -8.107588013476004],
              [-35.125799337969056, -8.107605403666412],
              [-35.125906019147408, -8.107636325955639],
              [-35.12601952707945, -8.107671713378807],
              [-35.126112675259719, -8.107711740404454],
              [-35.126194648464207, -8.107772143369687],
              [-35.126267526824847, -8.107828098288053],
              [-35.126365498968539, -8.107908709919002],
              [-35.126458875576589, -8.107980322933486],
              [-35.126570424785257, -8.108058592729833],
              [-35.126668252138074, -8.108118903964225],
              [-35.126781889572499, -8.108172340780868],
              [-35.126888615717007, -8.108210030468513],
              [-35.126977143180568, -8.108236553274253],
              [-35.12709290931025, -8.108271919113747],
              [-35.127229032493801, -8.10830265074668],
              [-35.127337863051878, -8.108317757558439],
              [-35.127446668428945, -8.1083283542701],
              [-35.127560023476235, -8.108343439498071],
              [-35.127666476226864, -8.108342767704528],
              [-35.127800112784939, -8.10834192863706],
              [-35.127892911128924, -8.108332312186405],
              [-35.127985712170812, -8.108322709225604],
              [-35.128132855646754, -8.108310500928837],
              [-35.128266485010215, -8.108309649270836],
              [-35.128382084765349, -8.108320200117078],
              [-35.128531841671133, -8.10835762270608],
              [-35.128615917490549, -8.108395447292025],
              [-35.128702318057492, -8.108440032981093],
              [-35.12881153082742, -8.108509294268458],
              [-35.128893439280773, -8.108560674335592],
              [-35.128995782129607, -8.10861869949332],
              [-35.129118492824148, -8.108674329756468],
              [-35.12921614660786, -8.10870981700441],
              [-35.12931822685465, -8.108731738878589],
              [-35.129413512723374, -8.108753695826351],
              [-35.129526825502523, -8.108762009159364],
              [-35.129621978271643, -8.108763664811249],
              [-35.129719368553992, -8.108763047876323],
              [-35.129825841089655, -8.108764636978378],
              [-35.129927780142907, -8.108766255758042],
              [-35.130031963903946, -8.108765595655655],
              [-35.130124893548953, -8.108774030397115],
              [-35.130215634244109, -8.108793772314698],
              [-35.130299742439092, -8.108836107900004],
              [-35.130315334884756, -8.10886044625255],
              [-35.130327128594047, -8.108866382677377],
              [-35.130377525702215, -8.108945045477412],
              [-35.130391811432901, -8.109041974407546],
              [-35.130376758580496, -8.109154891484991],
              [-35.130325404593691, -8.109259004199657],
              [-35.130269344454632, -8.109338339692556],
              [-35.130190704137647, -8.109426830283951],
              [-35.130123239695706, -8.109494950331623],
              [-35.130044424910238, -8.109560882328822],
              [-35.129947438423528, -8.109617903843116],
              [-35.129852648081226, -8.109665882674278],
              [-35.129744332834662, -8.109722980630977],
              [-35.129636017588098, -8.109780071393118],
              [-35.129543439578015, -8.109821276530624],
              [-35.129453084692045, -8.10985569178672],
              [-35.129353490171411, -8.109865342411581],
              [-35.129246820684159, -8.109836684615232],
              [-35.129155981962981, -8.109803413296845],
              [-35.129062831984072, -8.109763384472558],
              [-35.128956006914166, -8.109712163585414],
              [-35.128858309962993, -8.109669905341832],
              [-35.128771942670994, -8.109629840544642],
              [-35.128672031588962, -8.109594369484512],
              [-35.128585730846737, -8.109563323988141],
              [-35.128465436630563, -8.109527976135155],
              [-35.128372420650635, -8.109508257599941],
              [-35.128268158648552, -8.10949763840523],
              [-35.128159403633504, -8.109493810890626],
              [-35.128053031821935, -8.109505764679227],
              [-35.127964909952595, -8.109535648251494],
              [-35.127885971060664, -8.109583536251137],
              [-35.12778680821458, -8.109654108750078],
              [-35.127701207144924, -8.109720084814001],
              [-35.12762012247066, -8.109783773002675],
              [-35.127529821543988, -8.109827219250747],
              [-35.127432685769804, -8.109863927778008],
              [-35.127333267516235, -8.10989839969136],
              [-35.127245113271329, -8.109923781257464],
              [-35.127136504845794, -8.109940256837319],
              [-35.127043692112636, -8.109947609694359],
              [-35.126953063832786, -8.109943671563144],
              [-35.126855531457579, -8.109926236406636],
              [-35.126762545155316, -8.109908768874561],
              [-35.126667248494698, -8.109886811926799],
              [-35.126578755205344, -8.109864800120363],
              [-35.126478810848425, -8.109824815362913],
              [-35.126353830265032, -8.109769195891658],
              [-35.126265170601073, -8.109724632685584],
              [-35.126174290511074, -8.109684589472181],
              [-35.126081118049115, -8.109642302450254],
              [-35.125951657043288, -8.109593475558427],
              [-35.12584264931877, -8.109553545659537],
              [-35.125742795793371, -8.109527102893423],
              [-35.125631553253527, -8.109491704678362],
              [-35.125540825148903, -8.109474224555754],
              [-35.125445559964589, -8.109454518611074],
              [-35.125345781082899, -8.109439352444099],
              [-35.125234735494587, -8.10943102831925],
              [-35.125141784265907, -8.109420330883495],
              [-35.125028493969751, -8.109414276647499],
              [-35.124933394260665, -8.109419392890629],
              [-35.124811085563124, -8.109420153717053],
              [-35.124700097531445, -8.109420858785541],
              [-35.124579958897925, -8.109408079419268],
              [-35.124471096863601, -8.109388450816311],
              [-35.124350748688073, -8.109346339162187],
              [-35.124259846114967, -8.109304045844965],
              [-35.12416434081166, -8.109252746716834],
              [-35.124068858890666, -8.109203706685662],
              [-35.123977942827764, -8.109159154271481],
              [-35.123887082522799, -8.109123625654775],
              [-35.123775876855177, -8.109092736640434],
              [-35.123671493444647, -8.109066315458051],
              [-35.123562597236003, -8.109042186647571],
              [-35.123462776086228, -8.109020242290285],
              [-35.123342592486608, -8.10900070092157],
              [-35.123236051602476, -8.108990083525498],
              [-35.123145552824951, -8.109004193888381],
              [-35.123059868118389, -8.109058886158607],
              [-35.123028768662721, -8.109144823574695],
              [-35.122995433492406, -8.109235293573875],
              [-35.122959800554327, -8.109321263365587],
              [-35.122908343145468, -8.109409586683057],
              [-35.12285237203929, -8.109502452476136],
              [-35.122794077084961, -8.109586296270493],
              [-35.122719893807982, -8.109665743279379],
              [-35.122634240577725, -8.109724946549022],
              [-35.122532609991936, -8.109766205645826],
              [-35.12244218585812, -8.109791591708529],
              [-35.122338045264542, -8.109799021907293],
              [-35.122242770187654, -8.109779312365333],
              [-35.122151965640683, -8.109750551146988],
              [-35.122061117926194, -8.109717277130585],
              [-35.121963352626551, -8.10966598969361],
              [-35.121870225130749, -8.109628219966339],
              [-35.121781689573254, -8.109601707952436],
              [-35.12167510552166, -8.109584325855906],
              [-35.12154833708604, -8.109594142855372],
              [-35.121432945074332, -8.109612920699703],
              [-35.12134480971514, -8.109640552369569],
              [-35.12125213188034, -8.109668216415002],
              [-35.12116405677574, -8.109704868285007],
              [-35.121076120166776, -8.109761830444086],
              [-35.121013168522893, -8.109827668911748],
              [-35.120957074209571, -8.10990248261345],
              [-35.120919183973115, -8.109988464995638],
              [-35.120913077576404, -8.110085527924753],
              [-35.120929568444808, -8.110175673268657],
              [-35.120973046169127, -8.110223919198461],
              [-35.121054854797649, -8.110272988907241],
              [-35.121143436220564, -8.110306273715537],
              [-35.121234097775357, -8.110314732738686],
              [-35.121338338193709, -8.110320843631996],
              [-35.121431116752603, -8.110308981574178],
              [-35.121526106744398, -8.110288077732548],
              [-35.121616409469652, -8.110246895977411],
              [-35.121720253287037, -8.110196601391976],
              [-35.121803750842389, -8.110153203707341],
              [-35.121914411520834, -8.110107376953863],
              [-35.122004867130897, -8.110086503689161],
              [-35.122097797675167, -8.110094943826539],
              [-35.122195580061941, -8.110148484964554],
              [-35.122210033965871, -8.110171738734721],
              [-35.122222966216896, -8.11017881999652],
              [-35.122280243138675, -8.110270967231315],
              [-35.122324039222974, -8.110376747289024],
              [-35.122340552574371, -8.110469153528584],
              [-35.122291319189003, -8.11055295325616],
              [-35.122221595650103, -8.110623336897447],
              [-35.122147225314166, -8.110675701722187],
              [-35.12206143988351, -8.110716860994273],
              [-35.12194176979682, -8.110769515400762],
              [-35.121849112646373, -8.110799430449333],
              [-35.121742835263603, -8.110824914538114],
              [-35.121652327492882, -8.110839025800374],
              [-35.121555045129185, -8.110855432132439],
              [-35.121439692687602, -8.110878720976132],
              [-35.121335629435691, -8.110897431371313],
              [-35.121231795510937, -8.110947724158109],
              [-35.121116936797137, -8.111040959572563],
              [-35.121047300492478, -8.111122623410267],
              [-35.120998021241633, -8.111199652142204],
              [-35.120937558921185, -8.111297061310268],
              [-35.120892883299859, -8.111385339661638],
              [-35.12083680247639, -8.111462411560979],
              [-35.120771593534187, -8.111530512722936],
              [-35.120697300539916, -8.111594164938708],
              [-35.120609516815705, -8.111671432890262],
              [-35.120523861786751, -8.111730632562569],
              [-35.120447201776869, -8.111780760773456],
              [-35.120341281424942, -8.11185588564058],
              [-35.12025559581906, -8.111910588702642],
              [-35.120156285484143, -8.111960847315231],
              [-35.120065884732753, -8.111988490676254],
              [-35.119970927116526, -8.112013914510499],
              [-35.119880404956689, -8.112025766675742],
              [-35.11977167242469, -8.11202419016422],
              [-35.119662776216103, -8.112000060454363],
              [-35.119556060863488, -8.111964627165776],
              [-35.119480945888881, -8.111913202132541],
              [-35.119408035152674, -8.111852736214757],
              [-35.119328262589192, -8.111783294163672],
              [-35.119216518227688, -8.111677937686636],
              [-35.119138984976132, -8.111603965750362],
              [-35.1190750602658, -8.111534423874502],
              [-35.118988429472381, -8.111455993998902],
              [-35.118892693942655, -8.111370850684182],
              [-35.118803871501427, -8.111303722588673],
              [-35.118724197863401, -8.111247810837767],
              [-35.118592444485671, -8.111194482839039],
              [-35.118497083073862, -8.111163493999982],
              [-35.118401796305818, -8.111141524461743],
              [-35.118293028700293, -8.111135443246042],
              [-35.118182074842821, -8.111140648522053],
              [-35.118066690025671, -8.111159427265704],
              [-35.117921958531497, -8.11119192696583],
              [-35.11782931397164, -8.111224099312665],
              [-35.117745782242025, -8.111262986897259],
              [-35.117641945619255, -8.111313280583374],
              [-35.117551678866846, -8.111361234233527],
              [-35.117468246062685, -8.111413654816261],
              [-35.117378096222126, -8.111477398762872],
              [-35.11722500283156, -8.111609231280966],
              [-35.117141846119239, -8.111700012445567],
              [-35.117069872476577, -8.111772667774346],
              [-35.117000301822429, -8.111863361704707],
              [-35.116928463977388, -8.111954076319535],
              [-35.116865695795184, -8.112046975387557],
              [-35.116818773667433, -8.112137533520297],
              [-35.11677406567054, -8.112221301771626],
              [-35.116736170937486, -8.112307282355175],
              [-35.116691571758565, -8.112406840902963],
              [-35.116653764259752, -8.112504103481569],
              [-35.116613569960236, -8.112585585656234],
              [-35.116569005854899, -8.11268966419658],
              [-35.116531190262151, -8.112786928573826],
              [-35.116484286120851, -8.112879738609024],
              [-35.116421421711209, -8.112959105578227],
              [-35.116335853017176, -8.113029589943551],
              [-35.116238668679557, -8.113059533770411],
              [-35.116134582045277, -8.113075983269937],
              [-35.116010037633089, -8.113081276679509],
              [-35.115914912742994, -8.113081879225319],
              [-35.115772265877297, -8.113089543247781],
              [-35.115665920146057, -8.113106004438521],
              [-35.11556640206777, -8.113126939756398],
              [-35.11546229384976, -8.113138871961326],
              [-35.115360201012436, -8.113116955483065],
              [-35.115276091918076, -8.113074608206546],
              [-35.115203180282492, -8.113014141389442],
              [-35.115139286149088, -8.112946855912639],
              [-35.115068556268795, -8.112875086416068],
              [-35.115059401170356, -8.11286320367384],
              [-35.115041162919283, -8.112844698324125],
              [-35.11497715097471, -8.112761611758913],
              [-35.11494017355011, -8.112658055724637],
              [-35.114903094502154, -8.11254095140373],
              [-35.114877359502486, -8.112426037831312],
              [-35.114851731522151, -8.112324655458451],
              [-35.114848537130229, -8.11219380769802],
              [-35.114856821684953, -8.112085451981955],
              [-35.11486747685251, -8.111990606781035],
              [-35.114909556131067, -8.111854968332068],
              [-35.114931495092378, -8.1117555455819],
              [-35.114953470026592, -8.111660641925027],
              [-35.114975498920103, -8.111572500270597],
              [-35.115008801714794, -8.111477520171377],
              [-35.115028284627613, -8.111351036820849],
              [-35.115036679798891, -8.1112562150023],
              [-35.115035798463282, -8.111132122949073],
              [-35.115023509227512, -8.11099681095402],
              [-35.115006996775435, -8.110904401117182],
              [-35.114975065446856, -8.110819874737388],
              [-35.11493814467957, -8.110779699323643],
              [-35.114855983516804, -8.110692215972961],
              [-35.114836184042588, -8.110669897497701],
              [-35.114828603657088, -8.110661825183058],
              [-35.114772671221772, -8.110598777311679],
              [-35.114705320993721, -8.110528450327649],
              [-35.114614372555195, -8.110479376122271],
              [-35.114523525740083, -8.110446103005188],
              [-35.114423721677383, -8.110426423140893],
              [-35.114299003696033, -8.110406894362654],
              [-35.114167679294894, -8.110414488238007],
              [-35.114079521452709, -8.110439860810857],
              [-35.11398213117036, -8.110440473249184],
              [-35.113889198827394, -8.110432033111806],
              [-35.113803432282509, -8.110475443386974],
              [-35.113722453728201, -8.110554923670747],
              [-35.113641693709155, -8.110665994440069],
              [-35.113576743771659, -8.110770195288239],
              [-35.113557072001242, -8.110869605447874],
              [-35.113551022261845, -8.110975689476447],
              [-35.113569832481744, -8.111072596822851],
              [-35.113606703786331, -8.111160370654545],
              [-35.113643584084116, -8.111250393690682],
              [-35.113703496918788, -8.111394426411607],
              [-35.113763334210489, -8.111527180734754],
              [-35.113807140187305, -8.111635212694864],
              [-35.11382816273948, -8.111725336455095],
              [-35.113842471852536, -8.111826785377787],
              [-35.113831787007314, -8.111917110586148],
              [-35.113766924304059, -8.112032594328696],
              [-35.113726903573706, -8.112138897791851],
              [-35.113675336447557, -8.112213686312543],
              [-35.113612413581961, -8.11228402498773],
              [-35.113520171019047, -8.112372604611949],
              [-35.113421078320073, -8.112452202706947],
              [-35.113319454928842, -8.112495714605473],
              [-35.113222184256358, -8.112514378235915],
              [-35.113104487282214, -8.112526396775763],
              [-35.112988982855256, -8.112529380726301],
              [-35.112886846850472, -8.112500684259146],
              [-35.11279144946576, -8.112462923525072],
              [-35.11269139808951, -8.112409394977533],
              [-35.112582249171396, -8.112349159286225],
              [-35.112495772162106, -8.11229329070278],
              [-35.112416112913252, -8.112239638948154],
              [-35.112345470267201, -8.112179157741878],
              [-35.112249756321148, -8.112098523627935],
              [-35.112135853586722, -8.112006727128687],
              [-35.112042476079353, -8.111935112315507],
              [-35.111942300596638, -8.111863530777271],
              [-35.11184894287436, -8.111794164269213],
              [-35.111766982260406, -8.111736015005022],
              [-35.111691675730185, -8.111657514982312],
              [-35.111679265985288, -8.111642707644819],
              [-35.111664294071886, -8.111627100809983],
              [-35.111600330690692, -8.111550784341205],
              [-35.111549940777081, -8.111472118843153],
              [-35.111497316947577, -8.111397987726889],
              [-35.111453693533065, -8.111314775256631],
              [-35.111398342959092, -8.111175230152696],
              [-35.111354459640552, -8.111055909002971],
              [-35.111337897725775, -8.110956739861592],
              [-35.111307614854525, -8.110839587876626],
              [-35.111288631065406, -8.110717859241788],
              [-35.111281029096176, -8.1106028323548],
              [-35.111275772558827, -8.110501327674058],
              [-35.1112750207256, -8.110395278719068],
              [-35.11127425810048, -8.110286978760996],
              [-35.111273506267253, -8.110180930705326],
              [-35.111274989249296, -8.110070359959082],
              [-35.111285306271839, -8.109928139372073],
              [-35.111298124308973, -8.109819754877719],
              [-35.111304261282612, -8.109727199350743],
              [-35.111317118889872, -8.109623323157791],
              [-35.111336636876274, -8.109501351705944],
              [-35.111344750559738, -8.109368174701444],
              [-35.111350574569315, -8.109230496590101],
              [-35.111354436258182, -8.109135697254601],
              [-35.111358262873466, -8.109036395912881],
              [-35.111357587482644, -8.108941628952948],
              [-35.111372342659422, -8.108785843891553],
              [-35.111398787224232, -8.108681887658918],
              [-35.11141832049907, -8.108562174404767],
              [-35.111433367056179, -8.108446995532347],
              [-35.111439441976643, -8.108345422503191],
              [-35.11145918119621, -8.108255041536893],
              [-35.111544675246535, -8.108173279673053],
              [-35.111630375241589, -8.10812083840591],
              [-35.111720734624157, -8.108086429445109],
              [-35.111811177643688, -8.108063300680726],
              [-35.111931196667342, -8.108058036948819],
              [-35.112037648518594, -8.108057366953858],
              [-35.112150972089637, -8.108067934887231],
              [-35.112280270318195, -8.108094205882821],
              [-35.112373331264166, -8.108120708004208],
              [-35.112489031743394, -8.108147055442203],
              [-35.112579760747337, -8.10816453916209],
              [-35.112686343899611, -8.108181922157939],
              [-35.112806415983243, -8.108185685820672],
              [-35.112944579728548, -8.108184816176276],
              [-35.11305101089539, -8.10817964057793],
              [-35.113143875789206, -8.108179052421292],
              [-35.113236719099291, -8.108176219556867],
              [-35.113340911853584, -8.108175564850399],
              [-35.113456461246642, -8.108179352794821],
              [-35.113549337831614, -8.10818102013792],
              [-35.113660337554506, -8.108182590354204],
              [-35.11375558115509, -8.108197779903549],
              [-35.114136399075505, -8.108238268281411],
              [-35.114254235444548, -8.108244294638439],
              [-35.11435159245201, -8.108239171200751],
              [-35.11445363043066, -8.108254328374528],
              [-35.114587541281935, -8.108291853486207],
              [-35.114707710492382, -8.108309148348496],
              [-35.114800609560461, -8.108313081983113],
              [-35.114898033117697, -8.108316986839441],
              [-35.115009078705953, -8.10832530826633],
              [-35.115108758662245, -8.108326941435166],
              [-35.115210684225644, -8.108326305614469],
              [-35.115314967811457, -8.108339190201434],
              [-35.115423721027867, -8.108343021313317],
              [-35.115523511600713, -8.108360448375947],
              [-35.115650476088547, -8.108377698272136],
              [-35.115759196030012, -8.108377015686699],
              [-35.115858855301894, -8.108376391557158],
              [-35.115972113222426, -8.108377939290392],
              [-35.116078609140459, -8.108381782992865],
              [-35.116182793800874, -8.108381134581634],
              [-35.116289300510743, -8.108387234683107],
              [-35.116391183806059, -8.108382078869795],
              [-35.116484050498514, -8.108381498807091],
              [-35.116576872224812, -8.108374147748691],
              [-35.116690040213143, -8.108362152591212],
              [-35.116782873630655, -8.108357052535894],
              [-35.11688931469007, -8.108354134235867],
              [-35.116982124725212, -8.108346782278147],
              [-35.117081686870335, -8.108332617955909],
              [-35.117181312867274, -8.108327480129049],
              [-35.117290032808739, -8.10832680114089],
              [-35.117403123455347, -8.108303524887731],
              [-35.117493611440977, -8.108287165320348],
              [-35.117638363619562, -8.10825691932132],
              [-35.117731161963491, -8.108247307367321],
              [-35.117821649949121, -8.108230946001299],
              [-35.117946128710855, -8.108216624297711],
              [-35.118045709741693, -8.10820471817317],
              [-35.118149752309193, -8.108183759472865],
              [-35.118242441835207, -8.108158355423711],
              [-35.118328295614333, -8.108128489837895],
              [-35.118463907083594, -8.108087015803108],
              [-35.118554360895018, -8.108066146135684],
              [-35.118669624303664, -8.108029317998557],
              [-35.118784898504202, -8.107994748958447],
              [-35.118900205080365, -8.107964691817017],
              [-35.119029034762093, -8.107925522744608],
              [-35.11914884154578, -8.107890920429611],
              [-35.119259690182503, -8.107872178558125],
              [-35.119359293696391, -8.107862520738706],
              [-35.119463475658847, -8.107861864233598],
              [-35.119560812881161, -8.107852227997853],
              [-35.119689893473776, -8.107849164907009],
              [-35.119785102900153, -8.107859841658296],
              [-35.119900735930173, -8.107874919691767],
              [-35.120002795492553, -8.10789458156961],
              [-35.120095692761993, -8.107898513405644],
              [-35.120195338544022, -8.107895625682545],
              [-35.120283449621468, -8.107863486610597],
              [-35.120373598562708, -8.107799741764609],
              [-35.120457018776335, -8.107745063883556],
              [-35.120533677886897, -8.107694936572045],
              [-35.120605758548891, -8.107638075137004],
              [-35.120684575132998, -8.107574397740223],
              [-35.120758954462133, -8.107522034714066],
              [-35.120842407950704, -8.107471866933054],
              [-35.12092817179763, -8.107428466550459],
              [-35.121016294566289, -8.107398574884257],
              [-35.121104469495606, -8.107375454213809],
              [-35.121204062217657, -8.107365809884186],
              [-35.121319466819898, -8.107349282143616],
              [-35.121421242196504, -8.107328333335886],
              [-35.121518509271766, -8.107309676900002],
              [-35.121624774963323, -8.107281933714205],
              [-35.121712895034022, -8.10725204294738],
              [-35.12181241131367, -8.107231105830806],
              [-35.121925419222691, -8.107196549381229],
              [-35.122031772148546, -8.107182347287448],
              [-35.122135822809923, -8.107161388587144],
              [-35.12224207681038, -8.107133635508831],
              [-35.122336969675359, -8.107099198669061],
              [-35.122427313769492, -8.107062526114646],
              [-35.122520060852082, -8.107046143164951],
              [-35.122631025501391, -8.107043190690661],
              [-35.122728459850521, -8.107047095546989],
              [-35.122828183873537, -8.107055491617587],
              [-35.122930218254908, -8.107070644294765],
              [-35.123021066868716, -8.107106175609488],
              [-35.123107507905104, -8.107157522401678],
              [-35.123191695240507, -8.107211153471894],
              [-35.123289577451999, -8.107278233004024],
              [-35.123371404066972, -8.107318331975421],
              [-35.123462178936393, -8.107342581295029],
              [-35.123564214217083, -8.10735772677765],
              [-35.123654984589848, -8.107381979694537],
              [-35.123743512952728, -8.107408500701695],
              [-35.123843300827616, -8.107425927764325],
              [-35.123934047818011, -8.107445656192056],
              [-35.124027068294538, -8.107467636522188],
              [-35.124122385639566, -8.107494117959106],
              [-35.124276712899928, -8.107536016473944],
              [-35.124372007761906, -8.107557985112862],
              [-35.124476278757243, -8.107568605206893],
              [-35.124566962795029, -8.107581577927419],
            ],
            [
              [-35.080911563917482, -8.180009459169185],
              [-35.0809057462032, -8.180016307506548],
              [-35.080939222566997, -8.180040139540779],
              [-35.078253043842835, -8.183201665724255],
              [-35.078292294753567, -8.183704545927981],
              [-35.078331667972066, -8.184066174114605],
              [-35.078618151506419, -8.187140015499267],
              [-35.078803069206231, -8.189626165215316],
              [-35.079032922431395, -8.19260955278861],
              [-35.079397449132955, -8.197214585284826],
              [-35.079643945211842, -8.200627376652051],
              [-35.079851415210612, -8.203305637744961],
              [-35.079878716829285, -8.204475200669947],
              [-35.077206670951057, -8.204455850856789],
              [-35.076298160531621, -8.205325123855346],
              [-35.075929053582911, -8.20570898957817],
              [-35.075162632449462, -8.206295889843375],
              [-35.074629019713427, -8.206651357673991],
              [-35.0735903351258, -8.207181511617421],
              [-35.072909252459965, -8.207497283372902],
              [-35.071921503674389, -8.208146135235893],
              [-35.071081348925077, -8.208699068107478],
              [-35.070564852985513, -8.208941538820909],
              [-35.069804344894578, -8.20925724222792],
              [-35.068754042864725, -8.210052929998653],
              [-35.06808405243703, -8.210639902209664],
              [-35.067442354680907, -8.211289070634052],
              [-35.066783300009263, -8.212305458330206],
              [-35.066379723845159, -8.213124327925357],
              [-35.066226206873921, -8.213485795133295],
              [-35.066050123214552, -8.213711626590225],
              [-35.065908205699088, -8.213801892443314],
              [-35.065766334948364, -8.213841310627913],
              [-35.065652817123748, -8.213897713408699],
              [-35.06560168976614, -8.213959808898039],
              [-35.065590248591036, -8.214067151977417],
              [-35.065659247276415, -8.214204912826403],
              [-35.065817498278875, -8.214352933141299],
              [-35.065993210518229, -8.214533894723388],
              [-35.065998072253194, -8.21454117113808],
              [-35.066020961797904, -8.214564744167546],
              [-35.066179587817658, -8.214802192167383],
              [-35.066275808981345, -8.215045232151681],
              [-35.066366200739537, -8.215452119619556],
              [-35.066467980612799, -8.215813812557371],
              [-35.066586870087917, -8.216085119132913],
              [-35.066688775866282, -8.21631686633026],
              [-35.06673967569543, -8.216486408321032],
              [-35.066745193036184, -8.216661562980676],
              [-35.066705286519721, -8.216864927574306],
              [-35.066585810686547, -8.21722076862352],
              [-35.066557242822455, -8.217435438594578],
              [-35.066590997976107, -8.217740575866856],
              [-35.06674357695448, -8.218390458352928],
              [-35.066873670183668, -8.21880867637924],
              [-35.066992378895122, -8.219277739376139],
              [-35.067048825742688, -8.219582884742294],
              [-35.067156145439697, -8.220097131477416],
              [-35.067229720774947, -8.220289302209494],
              [-35.067337299476719, -8.220455699271042],
              [-35.067428614838661, -8.220546805990239],
              [-35.067593099042256, -8.22060346417851],
              [-35.068024196259614, -8.220688604795214],
              [-35.068546222130408, -8.220621289640746],
              [-35.069329353566786, -8.220401655412047],
              [-35.070197607633418, -8.22016515619697],
              [-35.070731042303692, -8.220030045650049],
              [-35.071162341868558, -8.219900487517464],
              [-35.071548281428022, -8.219742639411209],
              [-35.071543064460798, -8.219786953505036],
              [-35.071576055190747, -8.219773460976398],
              [-35.071518869100487, -8.220259309819255],
              [-35.071450341659897, -8.2207451424743],
              [-35.071467104123485, -8.221022008658736],
              [-35.071517782719468, -8.221440153839978],
              [-35.071546043914736, -8.221558824779891],
              [-35.071669701595397, -8.221811304947721],
              [-35.071784540524106, -8.221935049862623],
              [-35.07189229819096, -8.221974694676362],
              [-35.071988745983788, -8.22198042515646],
              [-35.072147666081889, -8.221912768259585],
              [-35.072301022074441, -8.22172081876073],
              [-35.072482812831481, -8.221449785579068],
              [-35.072602176249404, -8.221218239829852],
              [-35.074700222637148, -8.222440530905715],
              [-35.074686646471491, -8.22244723715022],
              [-35.074728009889725, -8.222471335383773],
              [-35.072639238210229, -8.223503399162496],
              [-35.073448305195086, -8.225956220003525],
              [-35.074019782986568, -8.2276630163351],
              [-35.074240326429901, -8.228454210193604],
              [-35.074274250656117, -8.228584192806181],
              [-35.074421618063184, -8.22874817159078],
              [-35.074568978275693, -8.228912153972658],
              [-35.074693653988902, -8.229076111173526],
              [-35.074755864591452, -8.229290865680866],
              [-35.074761160698984, -8.229708964996689],
              [-35.074732583841637, -8.229934941244494],
              [-35.074794697317373, -8.23026834241],
              [-35.075337489433537, -8.232331057132512],
              [-35.075869088587524, -8.23424121176248],
              [-35.07544907911165, -8.234427294983618],
              [-35.074699722614071, -8.234912519696991],
              [-35.074097869321179, -8.235409180189492],
              [-35.073547052554375, -8.23592849010771],
              [-35.073155314166399, -8.236210637111185],
              [-35.072451258318893, -8.236791951687167],
              [-35.071542712825874, -8.23762733733173],
              [-35.070628348719254, -8.238615264183068],
              [-35.070060427746512, -8.239224948772346],
              [-35.069288005437045, -8.240094361165802],
              [-35.068805236772221, -8.240647623189204],
              [-35.068435956254348, -8.241184038212737],
              [-35.06817454312062, -8.241641450492523],
              [-35.067975632869093, -8.241997214200012],
              [-35.067895953835148, -8.242268347206391],
              [-35.067833352926868, -8.242471686619012],
              [-35.067595280797548, -8.242228520729611],
              [-35.06709069727782, -8.241815623991783],
              [-35.066869573771783, -8.241645919223743],
              [-35.066637019882535, -8.241572256654251],
              [-35.066393049999192, -8.241572040817005],
              [-35.065854025143096, -8.241588500209048],
              [-35.06564410449181, -8.24157701496722],
              [-35.065462554753083, -8.241571194554922],
              [-35.065218665808743, -8.241474927525815],
              [-35.061617819007097, -8.239341596237637],
              [-35.061609277246305, -8.239322103432301],
              [-35.061589994882297, -8.239310679344328],
              [-35.061510733133787, -8.239129799600505],
              [-35.061499498802789, -8.239011137653847],
              [-35.061475164946842, -8.238923584156055],
              [-35.06065988094872, -8.238351959775059],
              [-35.05715573554869, -8.236003958515823],
              [-35.054144906062504, -8.234001031622256],
              [-35.052690574610267, -8.235948924503191],
              [-35.052815109129938, -8.236248493173377],
              [-35.052996202013048, -8.236734565947415],
              [-35.05310341828806, -8.237344875565498],
              [-35.053108744073256, -8.237717779451941],
              [-35.053000428826692, -8.238248783254676],
              [-35.052784375698309, -8.238723177432632],
              [-35.052700514816877, -8.238878711583254],
              [-35.053328852244704, -8.239489405211827],
              [-35.053348197561206, -8.239512140972465],
              [-35.053356668275569, -8.239520373366474],
              [-35.054280461773828, -8.240606042135084],
              [-35.053764133108189, -8.240622516815677],
              [-35.052992389786937, -8.240734787280587],
              [-35.051970838183593, -8.241010678599878],
              [-35.050733648938717, -8.241331569296449],
              [-35.049553197921682, -8.241641202279595],
              [-35.048571310917225, -8.241967979938295],
              [-35.04803781149576, -8.242136969745331],
              [-35.047759668274011, -8.242266656480979],
              [-35.047702968716976, -8.242227052136684],
              [-35.047701626029152, -8.242214538969733],
              [-35.047675151786791, -8.242196045311175],
              [-35.047472721588804, -8.240308734360553],
              [-35.047355530033656, -8.238291556809827],
              [-35.047290891261582, -8.237646919169947],
              [-35.045545913313219, -8.237422443890637],
              [-35.044036866208387, -8.2372401971773],
              [-35.043214739268763, -8.236663092327774],
              [-35.04271582597454, -8.236278405523933],
              [-35.042718808126438, -8.236271103928232],
              [-35.042688032426724, -8.236247374416735],
              [-35.042847274482085, -8.235857676590456],
              [-35.043478045475354, -8.234858228524388],
              [-35.043898530692616, -8.234220170324534],
              [-35.044125837037711, -8.233853145306341],
              [-35.044324680739408, -8.233587779652282],
              [-35.046010939253392, -8.232391605787939],
              [-35.048549005931818, -8.230410882256365],
              [-35.048391007638827, -8.229518031731743],
              [-35.04766268718322, -8.225856087688271],
              [-35.052669514286606, -8.223035889294806],
              [-35.053359701688862, -8.21922842595103],
              [-35.056230352049056, -8.219355467780588],
              [-35.05656519572716, -8.216830852261978],
              [-35.054400595910749, -8.215870315162874],
              [-35.054402232676864, -8.21585242135211],
              [-35.054372853624272, -8.215839384779713],
              [-35.054773707540505, -8.211461137921106],
              [-35.052511904500761, -8.209450059572532],
              [-35.052515575533334, -8.209447036951133],
              [-35.052484184697335, -8.209419124692829],
              [-35.056788000066092, -8.205875052991246],
              [-35.057833957471132, -8.200507647210088],
              [-35.052404055103011, -8.197895614890683],
              [-35.052406521044077, -8.197879198666044],
              [-35.052376371272487, -8.197864693500776],
              [-35.052924596193122, -8.194215307590298],
              [-35.05971879618545, -8.196464929312981],
              [-35.057825413012381, -8.18916329135277],
              [-35.063322684494267, -8.189044271475893],
              [-35.069439239928954, -8.187920059563055],
              [-35.073031717639594, -8.186341423817623],
              [-35.077197747877733, -8.184079653153447],
              [-35.077272039972627, -8.177418427818168],
              [-35.080911563917482, -8.180009459169185],
            ],
            [
              [-35.024149833148215, -8.194025191809089],
              [-35.02397129703769, -8.194325068946739],
              [-35.023780395248991, -8.194553251231525],
              [-35.02336161694501, -8.194818453208995],
              [-35.02314622481839, -8.194951085224375],
              [-35.02300297270898, -8.195107273182089],
              [-35.022979872722885, -8.195286779661103],
              [-35.023053099121171, -8.195525596429093],
              [-35.023090195256259, -8.19574067918893],
              [-35.023091584708823, -8.196015735937408],
              [-35.023153235933023, -8.19614708192222],
              [-35.023269078505109, -8.196198845999902],
              [-35.023508780205987, -8.196125891196857],
              [-35.023808435211095, -8.196040674137748],
              [-35.023916584083054, -8.196064053812961],
              [-35.024048557794686, -8.196051432727415],
              [-35.02410821162465, -8.195979377246431],
              [-35.024083599878168, -8.195859908607872],
              [-35.024082814770054, -8.19570444280572],
              [-35.024178238235038, -8.195584373420047],
              [-35.024357617010367, -8.195451923967084],
              [-35.024597256658012, -8.195367008180824],
              [-35.024944562241728, -8.195209798593282],
              [-35.025483673432745, -8.195003790892201],
              [-35.026022844878355, -8.194809743274959],
              [-35.026597842616297, -8.194579636441006],
              [-35.02722055118744, -8.194289493565236],
              [-35.02761549925583, -8.194060289650622],
              [-35.027855076850301, -8.193963413780466],
              [-35.028071131777267, -8.193962331896046],
              [-35.028251783992573, -8.19408101722513],
              [-35.028261647756779, -8.194100427292824],
              [-35.028279429152292, -8.194112109486241],
              [-35.028424916976292, -8.19439840056566],
              [-35.028558708217759, -8.194744547822552],
              [-35.028633027292358, -8.195198624516877],
              [-35.028647089991182, -8.195605169343025],
              [-35.02861295712222, -8.195976076734496],
              [-35.028650237618308, -8.196227033450782],
              [-35.028761441487291, -8.196424111783301],
              [-35.029008804512671, -8.196612577308542],
              [-35.029370169197932, -8.19686190894987],
              [-35.029707407770616, -8.197087440932592],
              [-35.029721532522672, -8.197109475222078],
              [-35.029735063722171, -8.197118524200505],
              [-35.029880252971282, -8.19734502295438],
              [-35.030037627134732, -8.19760733181289],
              [-35.030027018731857, -8.197882448815903],
              [-35.029908622984522, -8.19820594394912],
              [-35.029633642678448, -8.198422592428642],
              [-35.029226624808871, -8.198639903708568],
              [-35.028819607838614, -8.198857216787076],
              [-35.028568330064331, -8.199013949733967],
              [-35.028472787888802, -8.199110103448447],
              [-35.028402041820755, -8.199361602455951],
              [-35.028440111921611, -8.199768026772972],
              [-35.028404950228207, -8.199935632523363],
              [-35.02830928844287, -8.200007868768068],
              [-35.028189134520915, -8.199984553844047],
              [-35.028020605166773, -8.199889726629522],
              [-35.027598614484418, -8.199521110710634],
              [-35.027284777170678, -8.199175869970361],
              [-35.026898797141712, -8.198807069690474],
              [-35.026404487477066, -8.198379017577565],
              [-35.026248264445826, -8.198343924232688],
              [-35.026128837176032, -8.198464115926186],
              [-35.026021897892235, -8.198679919942322],
              [-35.025987400797874, -8.198979074924296],
              [-35.026024983466186, -8.199289825764822],
              [-35.026146346976304, -8.199552321682233],
              [-35.02635173414518, -8.199814393119709],
              [-35.026436664320556, -8.199993357307562],
              [-35.026426054119042, -8.200268473411256],
              [-35.026355669578436, -8.20059172572752],
              [-35.026201078816712, -8.200879524970048],
              [-35.025879836485217, -8.201443223625802],
              [-35.025439527512162, -8.202198866384549],
              [-35.025033897296453, -8.202691232614256],
              [-35.024663064548747, -8.202944238885493],
              [-35.024279380488963, -8.203029878625955],
              [-35.023931042483582, -8.202983788371114],
              [-35.02359398197666, -8.202794132143424],
              [-35.0235713892082, -8.202765856558983],
              [-35.023566313434571, -8.202763001211508],
              [-35.023252234203198, -8.202369916538373],
              [-35.023057944668381, -8.201928396178516],
              [-35.022947801100088, -8.201510371506401],
              [-35.022945566284818, -8.201067890670572],
              [-35.023063483592864, -8.200648725658141],
              [-35.023253120035406, -8.200169405893348],
              [-35.023670151855981, -8.199557393858584],
              [-35.023920104928834, -8.199137568743765],
              [-35.024038204799297, -8.198754279486366],
              [-35.023988740288075, -8.198467505470944],
              [-35.023833837461609, -8.198174326483922],
              [-35.023653700557816, -8.197986200902392],
              [-35.023484990439897, -8.197855494335556],
              [-35.023364959725086, -8.19785609598199],
              [-35.023257475452112, -8.197964268236319],
              [-35.023067055700039, -8.198288122199017],
              [-35.022650567969265, -8.199007766894852],
              [-35.022293370742432, -8.199583603700319],
              [-35.021971156243808, -8.19995595540297],
              [-35.021720474720098, -8.200232275698909],
              [-35.02138534955418, -8.200425303484906],
              [-35.020918186824815, -8.20061899047397],
              [-35.020631012611091, -8.200799817157815],
              [-35.020356688810125, -8.201148011271641],
              [-35.020118554627572, -8.201531901276155],
              [-35.019928311142621, -8.201891633691901],
              [-35.019738370729158, -8.20231115843319],
              [-35.019584378915908, -8.202718547722725],
              [-35.019549817070356, -8.20300574352018],
              [-35.019540107989542, -8.203460246493137],
              [-35.019603324034108, -8.204093772109843],
              [-35.019570210097072, -8.204667985638991],
              [-35.019441553984507, -8.205338350184661],
              [-35.019360186023732, -8.205864967993818],
              [-35.019242203065176, -8.20627217562037],
              [-35.019016672881094, -8.206775595417355],
              [-35.018838552257364, -8.207159185947603],
              [-35.018612536439377, -8.207566933167413],
              [-35.018338508515285, -8.2079749223048],
              [-35.017956205814187, -8.208335615196461],
              [-35.017442225278444, -8.208768722398588],
              [-35.016868647569993, -8.209285843366956],
              [-35.01631787127269, -8.20956366283275],
              [-35.016006197727791, -8.209648937448435],
              [-35.015634268706492, -8.209686674800139],
              [-35.015297925858533, -8.209640519794107],
              [-35.014937154725885, -8.209510768307268],
              [-35.014563659085582, -8.209237570457447],
              [-35.01454402508665, -8.209212256340493],
              [-35.014535981550239, -8.2092063729757],
              [-35.014221840265691, -8.208801321923431],
              [-35.013980024258672, -8.208455708863823],
              [-35.013845876885682, -8.208037799304975],
              [-35.013795515750417, -8.207571636621708],
              [-35.013853066066076, -8.207081016875406],
              [-35.013839461122188, -8.206758707947984],
              [-35.013673967879242, -8.206575079875961],
              [-35.013397224901951, -8.206444909305048],
              [-35.013096594132492, -8.206338775813833],
              [-35.012796386943648, -8.206316355715217],
              [-35.012496537685024, -8.206365688925359],
              [-35.012221117610466, -8.206498615918349],
              [-35.011982070616, -8.206703118154337],
              [-35.011803460761087, -8.206991034308714],
              [-35.011649280090182, -8.207362543346676],
              [-35.011483875880117, -8.207889580239907],
              [-35.011378309861072, -8.208380441004522],
              [-35.011332643186904, -8.208847084825038],
              [-35.011310679044584, -8.209253811314284],
              [-35.011312305018805, -8.209576706599705],
              [-35.011182730698408, -8.210067688772767],
              [-35.011015821922626, -8.210295748749786],
              [-35.010788055124578, -8.210356682314966],
              [-35.010583869449988, -8.21033378197842],
              [-35.010463292846623, -8.210226748265768],
              [-35.010459184743524, -8.210216442934438],
              [-35.010435617109977, -8.210195521106357],
              [-35.010326201992598, -8.209921001253178],
              [-35.010240613513474, -8.209610483337087],
              [-35.010154010599081, -8.209408396680089],
              [-35.009894493236231, -8.209308712227255],
              [-35.009879112131273, -8.209282203810631],
              [-35.009866820197544, -8.209277481470565],
              [-35.009769949723363, -8.209110531325905],
              [-35.009732977694739, -8.208919366935163],
              [-35.009684302788287, -8.208788055124614],
              [-35.009623202848502, -8.208573091974586],
              [-35.009502468863786, -8.208424265867109],
              [-35.009241310238224, -8.208319339266041],
              [-35.008964926090414, -8.208260919305928],
              [-35.008676658301113, -8.208226476170864],
              [-35.008304905546936, -8.208300086579698],
              [-35.007861795185647, -8.208505601452316],
              [-35.007490461515545, -8.208662924354428],
              [-35.007274577459725, -8.208699875698642],
              [-35.007022319424436, -8.208665254497873],
              [-35.006781766964934, -8.208570776220313],
              [-35.006661131905673, -8.208451782423765],
              [-35.006658243283255, -8.208444971857944],
              [-35.006633465162224, -8.208420530982664],
              [-35.00657272674988, -8.208277318443436],
              [-35.006571947937005, -8.208121851742021],
              [-35.006643069921722, -8.207942105144014],
              [-35.006881883991696, -8.20768977156564],
              [-35.007288324496528, -8.207352888225159],
              [-35.007671120026146, -8.207087879601943],
              [-35.008078041668227, -8.206846668838637],
              [-35.008365167318573, -8.20665389196347],
              [-35.008747778487134, -8.206353001289926],
              [-35.009046486506122, -8.206076451666888],
              [-35.009369201927086, -8.205799779736026],
              [-35.009811951660197, -8.205522510655271],
              [-35.01030295181971, -8.205292840891843],
              [-35.010877800270237, -8.205026872691974],
              [-35.011368920039558, -8.204821116801043],
              [-35.011992199680208, -8.204638624572794],
              [-35.012603353761733, -8.204432269733388],
              [-35.012986563878769, -8.204250969106909],
              [-35.013333219252615, -8.203962216582966],
              [-35.013512307546932, -8.203769975703779],
              [-35.013643022207646, -8.203506219836129],
              [-35.013833272887211, -8.20314648921908],
              [-35.014011399806236, -8.202762904984013],
              [-35.014237419221502, -8.202355159562899],
              [-35.01435564769497, -8.201995788674651],
              [-35.014389972119488, -8.20166075434031],
              [-35.014364402595049, -8.201349940547288],
              [-35.01430270190815, -8.201015386450877],
              [-35.0141451550748, -8.200717188347539],
              [-35.013927350966412, -8.200371452980107],
              [-35.013805815685828, -8.20007307411305],
              [-35.013696282757223, -8.199774636789982],
              [-35.013525488910147, -8.199564101002409],
              [-35.013337958679813, -8.199437022300685],
              [-35.012941790231366, -8.199427038926672],
              [-35.012269423794805, -8.199394512246897],
              [-35.011980923081069, -8.199312235970865],
              [-35.011860468785585, -8.199229120627365],
              [-35.011852895594586, -8.199211756517286],
              [-35.011832825424506, -8.199197907857126],
              [-35.011759965050317, -8.199030839001978],
              [-35.011710990669656, -8.198839732167869],
              [-35.011710030193683, -8.198648387912726],
              [-35.01176902661922, -8.198444783200159],
              [-35.011959817791308, -8.198192687942139],
              [-35.012234870942507, -8.197988010338349],
              [-35.01253339100316, -8.197675581362944],
              [-35.01273624478182, -8.197435383236325],
              [-35.012951222321021, -8.197219045922168],
              [-35.013177840684762, -8.196930892346757],
              [-35.013369291959236, -8.196810346320376],
              [-35.013621358438911, -8.196809090866793],
              [-35.013957449476663, -8.196807417228456],
              [-35.014173508000965, -8.196806340740011],
              [-35.014305183137651, -8.196733928228184],
              [-35.014340351126236, -8.196566322477736],
              [-35.014302959114218, -8.196291442895699],
              [-35.014241499445575, -8.196004724638271],
              [-35.014120144928711, -8.195742223324885],
              [-35.014059227551286, -8.195563138627904],
              [-35.014154590761734, -8.195431108259015],
              [-35.01434640266433, -8.195382316440714],
              [-35.014550517292491, -8.195393258492061],
              [-35.014767655902574, -8.195607445527116],
              [-35.014773256880233, -8.195616902797781],
              [-35.014795291169719, -8.195638636713682],
              [-35.014952732782376, -8.195904473612529],
              [-35.015141917765277, -8.196036117272968],
              [-35.015454002300316, -8.196034562345119],
              [-35.015657573737997, -8.195937874433298],
              [-35.015824594929086, -8.195733732825488],
              [-35.015895352688347, -8.195482232918664],
              [-35.015834253647824, -8.195267269768635],
              [-35.015689193001833, -8.195064684887257],
              [-35.015447206123611, -8.194683191575962],
              [-35.015035851723155, -8.19403943617101],
              [-35.014757255243069, -8.19353853087847],
              [-35.01457516501182, -8.193132820623134],
              [-35.014332759948843, -8.192667612120545],
              [-35.014066350181849, -8.19220252322782],
              [-35.013534550479051, -8.191475650680673],
              [-35.013135629205863, -8.190915544816505],
              [-35.012784719139461, -8.190355201531304],
              [-35.012747928773877, -8.190199912895594],
              [-35.012795280777539, -8.190068123545018],
              [-35.01299831172264, -8.18986380477071],
              [-35.013452265209821, -8.189431010532644],
              [-35.013893736106979, -8.188902601273981],
              [-35.014431046855293, -8.188337839619578],
              [-35.014800313883313, -8.187773911636668],
              [-35.015015045008283, -8.187860188996467],
              [-35.015329526236599, -8.187986547341268],
              [-35.01567851175389, -8.188164198518905],
              [-35.016051625182286, -8.188365648455886],
              [-35.016304528930846, -8.188531819787613],
              [-35.016508337789503, -8.188482968614039],
              [-35.016519740293745, -8.188363317413121],
              [-35.016507076040682, -8.188231828436074],
              [-35.016602437452491, -8.188099800765144],
              [-35.016889907543145, -8.187978773601458],
              [-35.017153731759265, -8.187929623852995],
              [-35.017489271512602, -8.187820317553587],
              [-35.017777100432795, -8.18777104639662],
              [-35.017945623491698, -8.187865880805703],
              [-35.017946795308319, -8.18788216483],
              [-35.017973238074433, -8.187897044113242],
              [-35.017986144245128, -8.188076369828536],
              [-35.017879501737582, -8.188351965270954],
              [-35.017772978839844, -8.188651481780425],
              [-35.017798068126297, -8.188866623895535],
              [-35.017880189718937, -8.189008156501188],
              [-35.017999652062258, -8.189061541157173],
              [-35.018143566972014, -8.18903690692764],
              [-35.01825050985309, -8.188821104710144],
              [-35.01847657963043, -8.188425319372925],
              [-35.018691128192984, -8.18812526776685],
              [-35.018930283106044, -8.187944684799334],
              [-35.019277344973432, -8.187739646567366],
              [-35.019684660518635, -8.187582143800853],
              [-35.019972189964562, -8.187473074922423],
              [-35.020152291794773, -8.187484135684258],
              [-35.020296687841835, -8.187555170434734],
              [-35.020314489921702, -8.187581489993704],
              [-35.020324304223209, -8.187586317554462],
              [-35.02046942422453, -8.187800860721097],
              [-35.020638851102035, -8.188075077502788],
              [-35.020820220077042, -8.18833727484531],
              [-35.021001710460553, -8.188623391456304],
              [-35.021315597236992, -8.18898060037435],
              [-35.021552813211713, -8.189303557712947],
              [-35.021879191571372, -8.189556430884522],
              [-35.022312625227414, -8.189817369176183],
              [-35.022685922117546, -8.190054689472277],
              [-35.023202954053033, -8.190231495287208],
              [-35.023504052470003, -8.190433295959792],
              [-35.023518836425126, -8.190455812285848],
              [-35.023531681441909, -8.19046442149579],
              [-35.02366474063524, -8.190667063933802],
              [-35.023653643900502, -8.190846508359641],
              [-35.023582592962271, -8.191038211444265],
              [-35.023415154485747, -8.191158643256756],
              [-35.023236195693812, -8.191374807901013],
              [-35.023093246655947, -8.191590790882231],
              [-35.023082489864976, -8.191764726960514],
              [-35.023223342583151, -8.191893732909421],
              [-35.023512320836915, -8.192071677266028],
              [-35.023532508818164, -8.192098218957597],
              [-35.02353995430542, -8.192102803701403],
              [-35.02382995868561, -8.19248404790045],
              [-35.024048009208229, -8.192877610113555],
              [-35.024194158933255, -8.19329545312263],
              [-35.024231979921865, -8.193654042500043],
              [-35.024149833148215, -8.194025191809089],
            ],
            [
              [-35.021485341575215, -8.227584361628942],
              [-35.021492781666495, -8.22778139769332],
              [-35.021504794810369, -8.227860167512688],
              [-35.021546582708595, -8.228101959237961],
              [-35.021614441952863, -8.22820895697771],
              [-35.021639167013916, -8.228233096580084],
              [-35.021719291212264, -8.228322550345354],
              [-35.021844545189538, -8.228400388467094],
              [-35.021934783163658, -8.228354647149217],
              [-35.021985191063663, -8.228292198226313],
              [-35.022097583836398, -8.228195629924357],
              [-35.022204381027336, -8.228104601446148],
              [-35.022227269672726, -8.228126904632916],
              [-35.022266717534933, -8.228104108617686],
              [-35.022294851026516, -8.228086971136747],
              [-35.022351089231336, -8.228047092499253],
              [-35.022446950666222, -8.228001231571511],
              [-35.022542787819361, -8.227949764270136],
              [-35.022554099492027, -8.227944101239245],
              [-35.022548590245208, -8.22796664184699],
              [-35.022616288510847, -8.227932309328594],
              [-35.022704244904958, -8.227633092293331],
              [-35.022794772460713, -8.227626763764079],
              [-35.023179627437798, -8.227595450269746],
              [-35.02327015769157, -8.227589028211014],
              [-35.023372056275321, -8.227588247599499],
              [-35.023496637559731, -8.227587166614398],
              [-35.023723266715365, -8.227596607697251],
              [-35.023780092177446, -8.227618648281918],
              [-35.024013342141984, -8.22773499717232],
              [-35.024126964287916, -8.227773478263373],
              [-35.024263327590461, -8.227823056988427],
              [-35.024485030259882, -8.227916953404645],
              [-35.024507829872391, -8.227939346523556],
              [-35.024730091920162, -8.228089561184277],
              [-35.024855551842222, -8.228189817605937],
              [-35.024980749162182, -8.228256443879729],
              [-35.025099996567519, -8.228294807159557],
              [-35.025350040471949, -8.228394165158534],
              [-35.02544088998502, -8.228415946738494],
              [-35.025610751235149, -8.228414550990692],
              [-35.025701278790962, -8.228408127133321],
              [-35.025842922013169, -8.228406962511258],
              [-35.026018408522702, -8.228405538884488],
              [-35.026296320618655, -8.2284539493902],
              [-35.026557389312018, -8.228508142536725],
              [-35.026705178501118, -8.22857447563149],
              [-35.026796236656935, -8.228618765443628],
              [-35.027132470686922, -8.228858170368198],
              [-35.027456305763849, -8.228979394483417],
              [-35.027694921083707, -8.229061903683885],
              [-35.02788743805479, -8.229054694718343],
              [-35.028102781618031, -8.229075486144723],
              [-35.028272967523378, -8.229102205002732],
              [-35.028431897513997, -8.229145968711464],
              [-35.028726956983462, -8.229211107506558],
              [-35.028885446306276, -8.229204161143059],
              [-35.029066358425666, -8.229163293251361],
              [-35.029196154878605, -8.229117167922936],
              [-35.02923003503804, -8.229105606238704],
              [-35.029286008842178, -8.229031920286843],
              [-35.0293570795655, -8.22894347016495],
              [-35.029260958226587, -8.22884950270236],
              [-35.029232499180466, -8.22883852647675],
              [-35.029181183864523, -8.22879385535208],
              [-35.029067181305322, -8.228715964170362],
              [-35.02906170803135, -8.228700076747089],
              [-35.029039425529049, -8.228684852124161],
              [-35.02902201915083, -8.22863431741979],
              [-35.028952972801392, -8.228510995186241],
              [-35.02895279653427, -8.228494090629738],
              [-35.02895245569124, -8.228493098677518],
              [-35.028860743728274, -8.228430437514646],
              [-35.028735516730649, -8.22835829569874],
              [-35.02873539802016, -8.228343101652797],
              [-35.028707763652335, -8.228327181853899],
              [-35.028707500150972, -8.228293374539533],
              [-35.028655479766542, -8.228181179617707],
              [-35.028653986891925, -8.228012228481532],
              [-35.028669842838951, -8.227882607396396],
              [-35.028702695073264, -8.227758411921116],
              [-35.028735871063532, -8.227662421883224],
              [-35.028876159906815, -8.227520508864359],
              [-35.029034387526906, -8.227479753387854],
              [-35.029221158729456, -8.227466971323622],
              [-35.029272062155883, -8.227466533353777],
              [-35.029351749283762, -8.227505366979074],
              [-35.029476798215626, -8.2275606033391],
              [-35.029539400023225, -8.227593915127045],
              [-35.029749788322022, -8.227693562707714],
              [-35.029852038540696, -8.227726494981709],
              [-35.030119021175835, -8.227819977709771],
              [-35.03019867862605, -8.227853115928497],
              [-35.0303065280238, -8.22788041484921],
              [-35.030431371010934, -8.227913143876435],
              [-35.030493824430437, -8.227935155682815],
              [-35.030646599460965, -8.227911425271998],
              [-35.030736952548295, -8.227888098656763],
              [-35.030951855443732, -8.22785817731301],
              [-35.03113874715541, -8.227851088856596],
              [-35.031240876864956, -8.227878325724134],
              [-35.031604514136973, -8.228010552145236],
              [-35.031724172532506, -8.228094023620258],
              [-35.031895030231397, -8.228199560861015],
              [-35.031917331619525, -8.228227283362401],
              [-35.031922786907046, -8.228230653122125],
              [-35.031978419868153, -8.228299813685396],
              [-35.032015250703239, -8.228339434217446],
              [-35.032015569962539, -8.228341012527665],
              [-35.032043006479512, -8.228370526478557],
              [-35.032072604067366, -8.228516643828016],
              [-35.032072632845711, -8.22852224930233],
              [-35.032113810104192, -8.228696513633167],
              [-35.032194022436158, -8.228802870156301],
              [-35.032209538439417, -8.228828015200691],
              [-35.032235019830239, -8.228855683742779],
              [-35.032235366069244, -8.228857009343471],
              [-35.032262779203847, -8.22888677600389],
              [-35.032286195751283, -8.228976695617973],
              [-35.032327491720309, -8.229156563624485],
              [-35.032339683829321, -8.22925214537014],
              [-35.032340299864927, -8.229319761797512],
              [-35.032318114489328, -8.229381886964461],
              [-35.032257186320066, -8.229534428171291],
              [-35.03219514479008, -8.229557431929891],
              [-35.032161736774697, -8.229625309160667],
              [-35.032145440159866, -8.229704222871533],
              [-35.032106816076634, -8.229817236176473],
              [-35.032102657611517, -8.229980520684421],
              [-35.032058140270919, -8.230076659110466],
              [-35.031912692916421, -8.230274915554844],
              [-35.031817623784264, -8.23040521113154],
              [-35.031705555666804, -8.230546892125346],
              [-35.031417529795135, -8.230633775628178],
              [-35.031213612118449, -8.230629833000364],
              [-35.030901555462378, -8.2305591786631],
              [-35.030748542111496, -8.23055480166272],
              [-35.030573141037564, -8.230573134342649],
              [-35.030409052535674, -8.23058580399163],
              [-35.030221813685728, -8.230542271408638],
              [-35.030063642722894, -8.23059423873309],
              [-35.030019244992104, -8.230695981734129],
              [-35.030021793670812, -8.23098316853833],
              [-35.030012712316761, -8.23123100190918],
              [-35.030009430690654, -8.231495709259548],
              [-35.030056440052647, -8.231692362212698],
              [-35.030138119178787, -8.231962066196331],
              [-35.030132959768252, -8.232018411420484],
              [-35.030133781748589, -8.232108445248514],
              [-35.030219917015529, -8.23223728122548],
              [-35.030266429052404, -8.232372015855788],
              [-35.030269065864672, -8.232676107216548],
              [-35.030437439636103, -8.233130872791492],
              [-35.030484038907218, -8.233282508381023],
              [-35.030530782969208, -8.233445444851327],
              [-35.030560474086542, -8.233591652132986],
              [-35.030567417752081, -8.233743580002169],
              [-35.03057984728207, -8.233867363587933],
              [-35.03060379263087, -8.234007995972206],
              [-35.030599427321647, -8.234148770449337],
              [-35.030505059660754, -8.23436358521127],
              [-35.030121226313497, -8.234507541489791],
              [-35.02991282281846, -8.234638769663434],
              [-35.02978891422697, -8.234718587193015],
              [-35.029608146898397, -8.234770758663558],
              [-35.029518234478871, -8.234844706318086],
              [-35.029394322290102, -8.234924522948347],
              [-35.02926514097345, -8.235038264704087],
              [-35.029022241283371, -8.235113440832606],
              [-35.028943112634522, -8.235131014484693],
              [-35.028875177847112, -8.235137232397335],
              [-35.028665985646683, -8.235167036829239],
              [-35.028564172498534, -8.235184816426113],
              [-35.028439850218888, -8.235219616591962],
              [-35.028355298658084, -8.235259728153892],
              [-35.028208441166555, -8.235305942515197],
              [-35.028067270987719, -8.235346519925884],
              [-35.027920909022669, -8.235454745240247],
              [-35.02781411363037, -8.235545773718457],
              [-35.027583467203215, -8.235710924319676],
              [-35.027397804864734, -8.235853246530041],
              [-35.027296401807462, -8.235916042591271],
              [-35.02726826651724, -8.235933181870791],
              [-35.027081902707607, -8.235990980399379],
              [-35.026855475899424, -8.236004148272798],
              [-35.026798853684113, -8.236004614121612],
              [-35.026691238110118, -8.23600551883959],
              [-35.026571988006822, -8.235967066526825],
              [-35.026543499283036, -8.235950397592717],
              [-35.026536170707686, -8.235931228543336],
              [-35.026515724620936, -8.235919264862162],
              [-35.026457786697449, -8.235767682332607],
              [-35.026438735459237, -8.235536987342073],
              [-35.02643067753371, -8.235272337548281],
              [-35.026499200477701, -8.234703014532442],
              [-35.026605789925213, -8.234589568653632],
              [-35.026695150161004, -8.234442396399402],
              [-35.026756312154021, -8.23431796979844],
              [-35.027015771960237, -8.234197606334419],
              [-35.027072216109843, -8.234180234130463],
              [-35.027207907618788, -8.234150984580253],
              [-35.027360771682197, -8.234144067894363],
              [-35.027507866594704, -8.234125969937509],
              [-35.027649418086128, -8.234124805315446],
              [-35.027660762134417, -8.234124747758869],
              [-35.027667335279261, -8.234156012689766],
              [-35.027677189150893, -8.2341559308515],
              [-35.027688530501223, -8.234155872395547],
              [-35.027694023560286, -8.23418199860032],
              [-35.02776887413421, -8.234185764961012],
              [-35.027977979999719, -8.234138969637684],
              [-35.028142010945032, -8.234115092637353],
              [-35.028232659009973, -8.234114363287176],
              [-35.028328756966516, -8.234096703300168],
              [-35.028419284522329, -8.234090370274316],
              [-35.028605443286551, -8.234009970883392],
              [-35.028689495723597, -8.233907938300661],
              [-35.028727831124456, -8.233755510408457],
              [-35.028599492472551, -8.233339864443167],
              [-35.028519017538599, -8.233199788739228],
              [-35.028483937683518, -8.233081814773868],
              [-35.028368175151115, -8.23280116154433],
              [-35.028360851971684, -8.232609823584426],
              [-35.028359413056421, -8.232452082497559],
              [-35.028363574219554, -8.232288798888931],
              [-35.028362843070681, -8.2321986751287],
              [-35.028394845445689, -8.231978841250452],
              [-35.028342154167035, -8.231787821650585],
              [-35.028336173675427, -8.231754038617964],
              [-35.028333857921155, -8.231495055452399],
              [-35.028269238034852, -8.23136920522461],
              [-35.028156385708598, -8.231370185485616],
              [-35.028122742070821, -8.23140985997702],
              [-35.028094988093187, -8.231466409347263],
              [-35.028005833802126, -8.231636087135655],
              [-35.027928349113949, -8.231833821973282],
              [-35.027872226022396, -8.231896208842898],
              [-35.027708839890977, -8.231993310442874],
              [-35.027402965174645, -8.231978852941666],
              [-35.027210004837798, -8.231935438169899],
              [-35.027198605031572, -8.231924285677223],
              [-35.027096792782743, -8.23194197444252],
              [-35.026932117922854, -8.231892632239123],
              [-35.026778139599401, -8.231770013275423],
              [-35.026572934992885, -8.231619719474452],
              [-35.026544475946764, -8.231608652417322],
              [-35.026436421503604, -8.231558843465791],
              [-35.026322594312262, -8.231497854142617],
              [-35.026220019438313, -8.231419814572746],
              [-35.026134531683226, -8.231364197799451],
              [-35.025986122861241, -8.231230337310194],
              [-35.025917573836921, -8.231168942392742],
              [-35.025809254993078, -8.231085322529566],
              [-35.025792200249782, -8.231062449172668],
              [-35.025781496518789, -8.231054188000314],
              [-35.025757856040173, -8.231022482401613],
              [-35.025695251534614, -8.231007430448528],
              [-35.025593118227789, -8.23098010274947],
              [-35.025496051701396, -8.230896430725636],
              [-35.025267899993537, -8.23071253015911],
              [-35.025205478050339, -8.230696118431069],
              [-35.025080863491041, -8.230691504908975],
              [-35.024968032748461, -8.230737453970278],
              [-35.02495132784145, -8.230771436652446],
              [-35.024946021841401, -8.230816484593049],
              [-35.024896229077683, -8.230946458212429],
              [-35.024960851661945, -8.231087714726186],
              [-35.025097648437679, -8.231211853544153],
              [-35.025115373175879, -8.231233886934263],
              [-35.025125406911968, -8.231242992570003],
              [-35.025251426212321, -8.231399654470408],
              [-35.025345429647814, -8.231520573715443],
              [-35.025463837086363, -8.231631184931189],
              [-35.02553223862185, -8.231681375195251],
              [-35.025594895288123, -8.231725897931824],
              [-35.025609699927656, -8.23174782969852],
              [-35.025622654661731, -8.231757034259658],
              [-35.025762611655182, -8.231964347776398],
              [-35.025789754093807, -8.231983279404801],
              [-35.02579479389459, -8.231998566980224],
              [-35.025817515266056, -8.232014415732635],
              [-35.025858250957469, -8.232137967293284],
              [-35.025871115759344, -8.232312467246516],
              [-35.025770212725092, -8.232420280671363],
              [-35.025658053776112, -8.232545056209347],
              [-35.025561782250406, -8.232545901572053],
              [-35.025431421922519, -8.232535711353933],
              [-35.025334915673795, -8.232508355775963],
              [-35.025204409655714, -8.232486863777694],
              [-35.02511349898873, -8.232453873947065],
              [-35.024908851961925, -8.232359801263783],
              [-35.024857622980846, -8.232332036494256],
              [-35.024830675695057, -8.232301335438251],
              [-35.024829861808655, -8.232300894770447],
              [-35.024761134718574, -8.232222593497852],
              [-35.024726172674661, -8.232110311341728],
              [-35.024548838058365, -8.231909067349534],
              [-35.024468149085749, -8.231690403489267],
              [-35.024438301486384, -8.231670974535803],
              [-35.024428132851995, -8.231651282980295],
              [-35.024410543012095, -8.231639830114034],
              [-35.024405340434043, -8.231629754109804],
              [-35.024369694905431, -8.231598364173124],
              [-35.024318260878999, -8.231548089372779],
              [-35.024314457646085, -8.231540361498446],
              [-35.02429050330403, -8.231516944951011],
              [-35.024227057032988, -8.231387992961459],
              [-35.024175356807234, -8.231303908148789],
              [-35.024148351964811, -8.23122576785488],
              [-35.024111182085335, -8.231189428948937],
              [-35.024107414825266, -8.231181735248811],
              [-35.024083424510366, -8.231158282728529],
              [-35.024025750987562, -8.231040511110677],
              [-35.023996854870916, -8.230978731283358],
              [-35.023939296461322, -8.230866654172701],
              [-35.023882641870387, -8.230767325851332],
              [-35.023863748912845, -8.230746538921608],
              [-35.023766830774605, -8.230674164181266],
              [-35.023760618257882, -8.230659103234984],
              [-35.023739076796915, -8.230643016162219],
              [-35.023692775201368, -8.230530790663408],
              [-35.023610237222613, -8.230379560668155],
              [-35.023509140834165, -8.23035526278511],
              [-35.023384407564322, -8.230345041990063],
              [-35.023271137952634, -8.230340370911335],
              [-35.023118096722726, -8.230330290410507],
              [-35.022976545231359, -8.23033145413325],
              [-35.022834936183301, -8.230321410504644],
              [-35.022705015623956, -8.230361838627914],
              [-35.022576413470688, -8.230554406860335],
              [-35.022493326905476, -8.230757772353286],
              [-35.022422078116392, -8.23102309663858],
              [-35.022413020144711, -8.231276533685048],
              [-35.022457993441606, -8.231485415619375],
              [-35.022603594580175, -8.231603980439274],
              [-35.022611173167093, -8.231618706837821],
              [-35.022631351255825, -8.231635136552256],
              [-35.022653294713734, -8.231677770712508],
              [-35.022763693689456, -8.23177159518292],
              [-35.022843468051576, -8.2318273324654],
              [-35.022864592227108, -8.231853851673861],
              [-35.022871224727169, -8.231858486779686],
              [-35.022911319201967, -8.231908819136663],
              [-35.023031443446314, -8.232048606158173],
              [-35.023089117868437, -8.232166377776082],
              [-35.023278176946292, -8.232406975201684],
              [-35.023301123148258, -8.2324405801686],
              [-35.023368533630901, -8.232571686933738],
              [-35.023451022146901, -8.23263887438452],
              [-35.023456645607666, -8.232638755673975],
              [-35.023461529825681, -8.232655973194539],
              [-35.02347878152051, -8.232670026000847],
              [-35.023484406779914, -8.232669906391038],
              [-35.023554535013716, -8.232917161497767],
              [-35.023578508241542, -8.233063397557714],
              [-35.023613645653199, -8.233192585169604],
              [-35.023649605045193, -8.233411893843765],
              [-35.023650686929614, -8.233535828515642],
              [-35.023641042599991, -8.233727344541251],
              [-35.023665657943695, -8.233946802502885],
              [-35.02366568672204, -8.233952407977199],
              [-35.023666711949147, -8.234065042667567],
              [-35.023667327984754, -8.234132568263362],
              [-35.023696310436321, -8.234211159117649],
              [-35.023697279905491, -8.234312493826508],
              [-35.023586844956867, -8.234634422340662],
              [-35.023575942475702, -8.234685191768165],
              [-35.023576379546228, -8.234735906336937],
              [-35.02351858191696, -8.235237555368826],
              [-35.023513453083353, -8.235299507865932],
              [-35.023440918263702, -8.23541838744859],
              [-35.023396573592947, -8.235531339599618],
              [-35.023369316041055, -8.235649811789358],
              [-35.023358970240224, -8.235756897662668],
              [-35.023359644731784, -8.235835722340653],
              [-35.023361079150447, -8.235993463427576],
              [-35.023342610672898, -8.236466621936984],
              [-35.02329984970828, -8.236748524324867],
              [-35.023171774557682, -8.236991714495957],
              [-35.023059878210745, -8.237150296448988],
              [-35.022964159768094, -8.237207459156878],
              [-35.022828318072357, -8.237225410524218],
              [-35.022766069698321, -8.237225903352737],
              [-35.022408869773471, -8.237183859147763],
              [-35.022369160208541, -8.237172848747946],
              [-35.02219894282689, -8.237140528912221],
              [-35.02211336603898, -8.237068006683103],
              [-35.022109469276529, -8.237057077222289],
              [-35.022085593175518, -8.237036844274883],
              [-35.022027507763255, -8.236873964461836],
              [-35.021976016180133, -8.236812479612183],
              [-35.021878484704246, -8.236672486645887],
              [-35.021805688181928, -8.236535802285402],
              [-35.021420491462493, -8.236217752547191],
              [-35.021408881214825, -8.236199928883536],
              [-35.021392721296991, -8.236186586541692],
              [-35.02131242263016, -8.236063320965457],
              [-35.021232092487026, -8.23595135537073],
              [-35.021157710459931, -8.23586747740211],
              [-35.021094029465871, -8.235710319975226],
              [-35.021058921731822, -8.23558673783765],
              [-35.021040871439027, -8.235462980332215],
              [-35.020931063317846, -8.235210501963024],
              [-35.020885226671851, -8.235154591111439],
              [-35.020804866851108, -8.235036934606796],
              [-35.020684742606761, -8.23489714578659],
              [-35.020613881425447, -8.234576760509071],
              [-35.020538653136271, -8.234397149708627],
              [-35.020479691784317, -8.234133025118979],
              [-35.020484005832145, -8.233980948861642],
              [-35.020610794052857, -8.233591320283153],
              [-35.020715485031587, -8.233263814173654],
              [-35.020826798617804, -8.233043312998461],
              [-35.020876507744617, -8.232896432124619],
              [-35.020932075055157, -8.232777728809083],
              [-35.021027706263567, -8.23270375327553],
              [-35.021133507905006, -8.23250578911086],
              [-35.021273569219773, -8.232335766882102],
              [-35.021362811644394, -8.232182901919316],
              [-35.02137368804523, -8.232126526118236],
              [-35.021344557205509, -8.232036635282441],
              [-35.021241254780023, -8.231694117689358],
              [-35.021164414905797, -8.231619010808629],
              [-35.021101965083574, -8.23159699720361],
              [-35.020868448919714, -8.231446930931043],
              [-35.020749491096069, -8.231447888709056],
              [-35.02062490621438, -8.231448878862636],
              [-35.020472420765543, -8.231495205639192],
              [-35.020342884216689, -8.231575137382663],
              [-35.020269557094366, -8.231609498679347],
              [-35.020202205966996, -8.231677635814208],
              [-35.020112145159317, -8.231740283487227],
              [-35.019971264562173, -8.231820272787331],
              [-35.019892779827899, -8.231911068341105],
              [-35.019819860997757, -8.231990444303506],
              [-35.019786158904083, -8.232018908745601],
              [-35.019651984551388, -8.23222271220834],
              [-35.019574053799488, -8.232369825107298],
              [-35.019461922729533, -8.2325002043209],
              [-35.019315209129559, -8.232557712368475],
              [-35.019258971824058, -8.23259758920733],
              [-35.019041148829899, -8.232948534347315],
              [-35.018980245841647, -8.23310677096066],
              [-35.018901907696829, -8.233208772966464],
              [-35.018711845874918, -8.233486264179703],
              [-35.018576939474144, -8.233599943882268],
              [-35.018520551981851, -8.233628610671815],
              [-35.018333778081342, -8.233641392736047],
              [-35.018209835315588, -8.233715599395339],
              [-35.018170562821183, -8.233755303564351],
              [-35.017945625290338, -8.233937413580747],
              [-35.017866086550612, -8.233909880836279],
              [-35.017781124898931, -8.233904972336575],
              [-35.017468712111338, -8.233800501691746],
              [-35.017202073916508, -8.233757632808477],
              [-35.017174524984284, -8.233726475796175],
              [-35.017174317240915, -8.233726441621911],
              [-35.01706561798386, -8.233603499801632],
              [-35.01672745670669, -8.233144420178803],
              [-35.016703023026025, -8.232941955806552],
              [-35.016679799832843, -8.232904217555529],
              [-35.01661073909429, -8.232850266326693],
              [-35.016584290032938, -8.232820090474775],
              [-35.016582986015976, -8.232819071542849],
              [-35.016541788073027, -8.232772068476152],
              [-35.016423524525976, -8.232812332922776],
              [-35.016265731278452, -8.232903703143336],
              [-35.016198558216843, -8.232988747532602],
              [-35.016102953988081, -8.233068418472669],
              [-35.016018811618835, -8.233153545599578],
              [-35.015951370559264, -8.233221684533078],
              [-35.015760198074645, -8.233369635600184],
              [-35.015693286715759, -8.233488487303816],
              [-35.015670807261927, -8.233511197883445],
              [-35.015507970616284, -8.23366452150043],
              [-35.015446860783982, -8.233800156352061],
              [-35.015407674624441, -8.233856853211137],
              [-35.015295306133453, -8.233959119617623],
              [-35.015261632818067, -8.233993187735393],
              [-35.015114742051651, -8.234033791226409],
              [-35.014979398580351, -8.234096847191722],
              [-35.014804608146108, -8.234182791802368],
              [-35.014589902202204, -8.234235213284421],
              [-35.014392045956129, -8.234281858420957],
              [-35.014301690170839, -8.234305090607393],
              [-35.014291752662189, -8.234269300287906],
              [-35.014273935293829, -8.234273880535113],
              [-35.014250551122018, -8.234189655428224],
              [-35.014260959875344, -8.234093868637103],
              [-35.014288746228601, -8.234026020184615],
              [-35.01433346591665, -8.233969384479451],
              [-35.014383559953274, -8.233861918192929],
              [-35.014472861733111, -8.233720447640508],
              [-35.014482949428555, -8.233579552654192],
              [-35.01458272021631, -8.233336597206176],
              [-35.014704299563732, -8.23299210560117],
              [-35.014799142066693, -8.232833610882381],
              [-35.014905066915276, -8.232641338526889],
              [-35.014927370101987, -8.232601722491438],
              [-35.014942878011368, -8.232438290494656],
              [-35.014992238201216, -8.232257692238647],
              [-35.01502375494232, -8.231975845608758],
              [-35.015028912554214, -8.231919500384606],
              [-35.01486794110258, -8.231650460999901],
              [-35.014849157862329, -8.231453571525037],
              [-35.014837787733654, -8.231448024506676],
              [-35.014820326496817, -8.231386185324141],
              [-35.014785601873996, -8.231302017773828],
              [-35.014733526630891, -8.231178517474575],
              [-35.014699061012834, -8.231128156339309],
              [-35.014389244567951, -8.23067453829924],
              [-35.0143188762151, -8.23057834051798],
              [-35.014296746597552, -8.23056035047972],
              [-35.014287551029611, -8.23054422383683],
              [-35.014269001613116, -8.230529140407441],
              [-35.014153919867454, -8.230327312755207],
              [-35.014106542682782, -8.230091238919499],
              [-35.014043410275121, -8.229838432297754],
              [-35.01404199024563, -8.229832744985174],
              [-35.013984376078042, -8.229726179819295],
              [-35.013966792533381, -8.229658739659044],
              [-35.013955247036961, -8.22963628718486],
              [-35.013908712517036, -8.229495946180919],
              [-35.013885507310249, -8.229428533000373],
              [-35.013792787207308, -8.229198384797598],
              [-35.013741064498504, -8.229108694510614],
              [-35.013666481922542, -8.229002302913898],
              [-35.013591723079514, -8.228879098491575],
              [-35.013545747038563, -8.22879498579988],
              [-35.013482535490596, -8.228694141221581],
              [-35.013419294264963, -8.228587604834047],
              [-35.013355471177988, -8.228419236458535],
              [-35.013348795510467, -8.228301025971518],
              [-35.013347595814878, -8.228171486724705],
              [-35.013357832797681, -8.228041892619217],
              [-35.013379318500711, -8.227912237359817],
              [-35.01343524194283, -8.227827253225087],
              [-35.013620436633801, -8.227645525420598],
              [-35.013749045082307, -8.227453052516296],
              [-35.013765342596457, -8.22737404887323],
              [-35.01381537278121, -8.227272279791805],
              [-35.013972319766708, -8.227085179437438],
              [-35.014078968569493, -8.226982944507256],
              [-35.014253145666089, -8.226829478797356],
              [-35.014393617071732, -8.226704475730912],
              [-35.014584703221431, -8.226539530175103],
              [-35.014764830232707, -8.226414237526967],
              [-35.015006733473911, -8.226232046571624],
              [-35.015164059973358, -8.226084358106618],
              [-35.015602879669132, -8.225759703747258],
              [-35.015642152163537, -8.225720001376942],
              [-35.015698362489388, -8.225674520862412],
              [-35.015844989754441, -8.225600108258334],
              [-35.016009224845789, -8.225598744886099],
              [-35.016167742047628, -8.22559740849357],
              [-35.016247279888034, -8.225624944835317],
              [-35.016547233468032, -8.22559993828645],
              [-35.016768554824921, -8.225654517242106],
              [-35.016848063886982, -8.225676358177338],
              [-35.017046616208347, -8.225714146790381],
              [-35.017438150450232, -8.225801052776319],
              [-35.017649531600568, -8.226007640540161],
              [-35.01773490064511, -8.22605765813438],
              [-35.017842804901477, -8.22609617070168],
              [-35.017995436040508, -8.226061142107994],
              [-35.01811354580343, -8.225964456894133],
              [-35.01816378013433, -8.225885194246246],
              [-35.018286900020371, -8.22572086292746],
              [-35.018365356875677, -8.225624464597331],
              [-35.018483143782021, -8.225499663878338],
              [-35.018612244159669, -8.225369110196254],
              [-35.018791521311641, -8.225147996582734],
              [-35.018903650582956, -8.225017528336195],
              [-35.018971057468264, -8.224960691182901],
              [-35.019100302636787, -8.224841347550125],
              [-35.019162081564787, -8.224784537376479],
              [-35.019224123994093, -8.224761535416462],
              [-35.019405065791091, -8.224726275697037],
              [-35.019541278906843, -8.224764555339902],
              [-35.019706099456869, -8.224825114787166],
              [-35.019721778237454, -8.224851855228906],
              [-35.019733830951509, -8.224856284389944],
              [-35.019791117765806, -8.224953995730289],
              [-35.019814996564776, -8.2249706556712],
              [-35.020202276114105, -8.225220928902672],
              [-35.020247905016731, -8.225254328824178],
              [-35.020261266244404, -8.225274975459683],
              [-35.020275639209331, -8.225285496628317],
              [-35.020330077870767, -8.225369618313209],
              [-35.020550402778838, -8.225516512676847],
              [-35.020567546555014, -8.225533239167589],
              [-35.020581860164668, -8.225551308346155],
              [-35.020595282546196, -8.225564404273769],
              [-35.020711644027131, -8.225711301335423],
              [-35.020875902500848, -8.225823595182703],
              [-35.020878362146675, -8.225837479815766],
              [-35.020903638492086, -8.225854758490243],
              [-35.020915594079383, -8.225922229227479],
              [-35.020985661159273, -8.226158182554059],
              [-35.021008813306025, -8.226214296652415],
              [-35.021071820707903, -8.226292626703298],
              [-35.021123807817446, -8.226416034372448],
              [-35.021130137246018, -8.226483532988595],
              [-35.021165095692595, -8.2265959041776],
              [-35.021199823912696, -8.226680074425872],
              [-35.021320150504494, -8.226842460511079],
              [-35.021361620042683, -8.227039146739173],
              [-35.021361744149146, -8.227055259892268],
              [-35.021379624470057, -8.22706398241678],
              [-35.021376535298828, -8.227080102764489],
              [-35.021407365857215, -8.227095143925681],
              [-35.021401973522245, -8.227123286410517],
              [-35.021390834519366, -8.227145941232152],
              [-35.021414658459662, -8.227280881807189],
              [-35.021461164201298, -8.227415615538177],
              [-35.021485341575215, -8.227584361628942],
            ],
            [
              [-35.203106489173081, -8.242458892863567],
              [-35.203265237500659, -8.242486628854806],
              [-35.203361639428067, -8.242508924846959],
              [-35.203446673025496, -8.242519946937932],
              [-35.203531671549342, -8.242519669946773],
              [-35.203576938924527, -8.242496923394242],
              [-35.2036333749802, -8.242417612183033],
              [-35.203661485089413, -8.242344053934914],
              [-35.203660701779938, -8.242072797721391],
              [-35.203637571216916, -8.241914635751812],
              [-35.203563278222646, -8.241700132155302],
              [-35.203540145860984, -8.241541969286402],
              [-35.203528357547611, -8.24138376954528],
              [-35.203539341867099, -8.241265056337227],
              [-35.203612526896563, -8.241100931862491],
              [-35.203719878069876, -8.240993202973868],
              [-35.203804659857099, -8.240919458566111],
              [-35.203889429953165, -8.240840066415899],
              [-35.20391185454838, -8.240755218978109],
              [-35.203905953197136, -8.240676115510269],
              [-35.203871658450225, -8.24057450650821],
              [-35.203778041723183, -8.240450817351302],
              [-35.203581319522243, -8.240260971366695],
              [-35.203371184832292, -8.240097758804495],
              [-35.203217870208505, -8.239990893265087],
              [-35.203126957742882, -8.239906416347992],
              [-35.203113605508463, -8.239889875117569],
              [-35.203098960948239, -8.239876267475722],
              [-35.203030717693423, -8.239791720411517],
              [-35.202928286711028, -8.23964511832753],
              [-35.202876972294405, -8.239537909247076],
              [-35.202859496668395, -8.23937408244791],
              [-35.202858983155522, -8.239198891815363],
              [-35.202943355751245, -8.238983863914143],
              [-35.203016675679009, -8.238864949257959],
              [-35.203174943768602, -8.238728805389997],
              [-35.203350164978076, -8.238575645449657],
              [-35.203474556505512, -8.238484816620939],
              [-35.203621479647552, -8.238343057386146],
              [-35.203774013659768, -8.238184319850461],
              [-35.204005487463235, -8.237889700149481],
              [-35.20422567027839, -8.237612062346727],
              [-35.204282137810367, -8.237544068204159],
              [-35.204377974963506, -8.237374214148645],
              [-35.204473368750939, -8.237051777517479],
              [-35.20448917613453, -8.236644829795011],
              [-35.20446568314469, -8.236362337451851],
              [-35.204391416230806, -8.236153483396492],
              [-35.204254603267259, -8.23587701651104],
              [-35.203953119041159, -8.235476753449348],
              [-35.203560809583678, -8.235025926904939],
              [-35.20330487691848, -8.234704639607401],
              [-35.20315683861719, -8.234462113135976],
              [-35.203099782029255, -8.234326661746024],
              [-35.203076530057785, -8.234123295353754],
              [-35.203075967981476, -8.233931148903366],
              [-35.203126310230971, -8.233704933435888],
              [-35.203193773773592, -8.233523874726984],
              [-35.203244246424788, -8.233342864581516],
              [-35.203294899839705, -8.233224020971761],
              [-35.203396434198055, -8.233065454307223],
              [-35.203486949163334, -8.23301429637263],
              [-35.203673847170251, -8.232979782191137],
              [-35.20379845363567, -8.232962420779074],
              [-35.203940085166778, -8.23295066124399],
              [-35.204138462120284, -8.232966964154002],
              [-35.204234874839585, -8.232994908787987],
              [-35.204331299250043, -8.23302850116437],
              [-35.204439245774552, -8.233124219607021],
              [-35.204445065287473, -8.233134697608136],
              [-35.204467220086087, -8.233154342398961],
              [-35.204592535217273, -8.233379991293532],
              [-35.20468972764877, -8.233673541700568],
              [-35.204815128215557, -8.233927444595849],
              [-35.204917668915243, -8.234113601561432],
              [-35.204988630820594, -8.23420267581281],
              [-35.20513976189045, -8.234336965278658],
              [-35.205259006597828, -8.234421349565594],
              [-35.20528598985652, -8.234450768188367],
              [-35.205286987204659, -8.234451474156174],
              [-35.205514373589438, -8.234699394761208],
              [-35.205616809967751, -8.234851645487026],
              [-35.205708548011046, -8.235218684894335],
              [-35.205737161740558, -8.235314656945832],
              [-35.205760209565995, -8.235444564914701],
              [-35.205772084214288, -8.235631020455173],
              [-35.205772469124099, -8.235760995873193],
              [-35.205767397847069, -8.235964464788196],
              [-35.205728174815363, -8.236117178664813],
              [-35.205683145760531, -8.236219051168234],
              [-35.205592825948088, -8.236338020683036],
              [-35.205519524006775, -8.236462591175609],
              [-35.205485741873417, -8.236536165611483],
              [-35.20545770460933, -8.236637981457648],
              [-35.205452467857071, -8.236784937874518],
              [-35.205486945166342, -8.236948707117108],
              [-35.205538344119248, -8.237084171996912],
              [-35.205669427502016, -8.237343712742074],
              [-35.205863132477532, -8.237699109526261],
              [-35.206045388184066, -8.238014986502378],
              [-35.206199210025488, -8.23829705166753],
              [-35.206387169232414, -8.23862421513536],
              [-35.206580841832363, -8.238968313736621],
              [-35.206672115825427, -8.239177108436763],
              [-35.206741518306387, -8.239657246584613],
              [-35.206776501034653, -8.239990557817919],
              [-35.206743588545748, -8.24036365146128],
              [-35.206620464163052, -8.240889627154502],
              [-35.206531038276751, -8.241313766317205],
              [-35.206509034564249, -8.24153989994636],
              [-35.206492703775211, -8.241765999401309],
              [-35.206510334084612, -8.241980695452696],
              [-35.206550728033619, -8.242229224898779],
              [-35.206579571090288, -8.242404315706551],
              [-35.206608199208972, -8.242505955285537],
              [-35.206773632197326, -8.242684385276107],
              [-35.207034658622547, -8.242864252383299],
              [-35.207278684263827, -8.242987783259537],
              [-35.207403545237355, -8.243055197339459],
              [-35.207630609664875, -8.243190083955142],
              [-35.207646844226417, -8.243213231605296],
              [-35.207658624445912, -8.243220230129452],
              [-35.207891934664985, -8.243552889354305],
              [-35.208119753623691, -8.243947734000699],
              [-35.208347932311199, -8.244461255882186],
              [-35.208736351301525, -8.245511123539472],
              [-35.208941892254472, -8.246036024542832],
              [-35.209078969618702, -8.246402908367486],
              [-35.209222346733895, -8.246984528712971],
              [-35.209423333519965, -8.247888076673348],
              [-35.209440872098526, -8.248068858391036],
              [-35.20941346615848, -8.248385426945845],
              [-35.209363165277807, -8.248622941495512],
              [-35.209284578020799, -8.248877513486434],
              [-35.209239693756842, -8.249030247148141],
              [-35.209212044999845, -8.249262042908924],
              [-35.209223837809816, -8.249420238153448],
              [-35.209298074146773, -8.249612134592269],
              [-35.209451869907809, -8.249882899775969],
              [-35.209571408693535, -8.250063343348586],
              [-35.209617089756819, -8.250181872195583],
              [-35.209634484443882, -8.25031744229608],
              [-35.209641039602275, -8.250616938121141],
              [-35.209557587912286, -8.251142788808636],
              [-35.209518877494133, -8.25147069061984],
              [-35.209520559226405, -8.252041473235352],
              [-35.209527225900729, -8.252380524841328],
              [-35.209528557796659, -8.252832626624354],
              [-35.209569173878208, -8.253154614493837],
              [-35.209592708236869, -8.253448415811704],
              [-35.209707573245851, -8.253967955956341],
              [-35.209771059986394, -8.254357690654842],
              [-35.209846123699606, -8.254832147785351],
              [-35.210006841542452, -8.255526725677782],
              [-35.210075639678962, -8.255797766953322],
              [-35.21013316211571, -8.256085792825047],
              [-35.210184940582508, -8.256351233122871],
              [-35.210299058254918, -8.256616477368482],
              [-35.210418761616495, -8.256853430741216],
              [-35.210510030213698, -8.257056575900208],
              [-35.210601261938621, -8.257248419279108],
              [-35.210868972125297, -8.257710949600607],
              [-35.210977279277984, -8.25792534168113],
              [-35.21102297562976, -8.258049518270582],
              [-35.21104035772629, -8.258179440628624],
              [-35.210973148691835, -8.258445274829512],
              [-35.210821004985348, -8.258739647216885],
              [-35.21037989921291, -8.259046269269845],
              [-35.210198696612508, -8.25909207443965],
              [-35.210006123883488, -8.259126619198071],
              [-35.209926783893934, -8.259126875504876],
              [-35.209824584037278, -8.259059404767697],
              [-35.209802556942407, -8.259033207516495],
              [-35.209796507203009, -8.259029213627286],
              [-35.209682776239106, -8.258893947497654],
              [-35.209608550694043, -8.25870770329783],
              [-35.209545865249424, -8.258589233806049],
              [-35.209392332989751, -8.258408895454124],
              [-35.209267250782943, -8.258268026548137],
              [-35.209125076960675, -8.25809894860754],
              [-35.20899984726509, -8.257907219442586],
              [-35.208937001741219, -8.257732238352048],
              [-35.208959112472996, -8.257545669497006],
              [-35.208958744650317, -8.257421341821441],
              [-35.208952531234274, -8.257234874589756],
              [-35.208929365597726, -8.257065405444052],
              [-35.20882116906165, -8.25688492499927],
              [-35.208668473171429, -8.25670333928764],
              [-35.208241152207393, -8.256329368805268],
              [-35.208229060822475, -8.256313160324055],
              [-35.2082130879636, -8.256299180362817],
              [-35.208019657281341, -8.256039863548835],
              [-35.207814894241949, -8.255780569217848],
              [-35.207576085567837, -8.255510093615896],
              [-35.207479389562138, -8.255386088796911],
              [-35.20744504085593, -8.255267522178372],
              [-35.207422023607478, -8.255148914191011],
              [-35.207438523469023, -8.25497932453618],
              [-35.207455129450636, -8.254843640221793],
              [-35.207477580126181, -8.254770103557405],
              [-35.207477410154297, -8.25471358386477],
              [-35.207301034215334, -8.254471152721464],
              [-35.206823312545794, -8.253890647535286],
              [-35.206464905730797, -8.253411457272875],
              [-35.206362489137518, -8.253270508327262],
              [-35.206310988561256, -8.253101141704292],
              [-35.206265191485329, -8.25294305617706],
              [-35.206253157657045, -8.252705741276884],
              [-35.206342622214208, -8.252292902995009],
              [-35.206613181453122, -8.251800346109064],
              [-35.206658099891342, -8.251658919623424],
              [-35.206674757134294, -8.251540191126878],
              [-35.206606050728624, -8.251303053393144],
              [-35.206423824699698, -8.250998484492413],
              [-35.206031279619822, -8.25047419952466],
              [-35.205815018748069, -8.250158436762376],
              [-35.205661144745989, -8.249859415779383],
              [-35.20559272792201, -8.249718359814381],
              [-35.20548437760192, -8.249487007419418],
              [-35.205375858209266, -8.249193494783924],
              [-35.205295698038015, -8.248911198492976],
              [-35.205272585461387, -8.248758682467155],
              [-35.205277355465512, -8.248453502926793],
              [-35.205451002861423, -8.247763471107248],
              [-35.205507027027636, -8.247542882697815],
              [-35.20564774754547, -8.247220297678552],
              [-35.20575478755336, -8.247005196932264],
              [-35.205918189872534, -8.246688187709651],
              [-35.2059855544897, -8.246473224559622],
              [-35.206040951828413, -8.246037892434742],
              [-35.205915308444673, -8.245704875279728],
              [-35.205630653231992, -8.245253696201075],
              [-35.205391779806746, -8.244954961202438],
              [-35.205239869024695, -8.244776514124794],
              [-35.205124156854311, -8.244652654996003],
              [-35.205118651204771, -8.244646592666072],
              [-35.205096140274634, -8.244622495331839],
              [-35.204968147861678, -8.24448154099025],
              [-35.204800248932315, -8.244342900604352],
              [-35.204510760762957, -8.244174305599699],
              [-35.204317944317609, -8.244124077564095],
              [-35.203989064044038, -8.244051681240023],
              [-35.203841550047457, -8.243989996740936],
              [-35.203659774578909, -8.243838001422603],
              [-35.20363630497144, -8.243811639595435],
              [-35.203631762495775, -8.24380784085912],
              [-35.203546487880089, -8.243712054967318],
              [-35.203511668828412, -8.243670642985762],
              [-35.203415435973568, -8.243607096889946],
              [-35.203171242159044, -8.243427051716992],
              [-35.20299516389565, -8.243286350984249],
              [-35.202836005477252, -8.243117329700965],
              [-35.202835679023337, -8.243116564377885],
              [-35.202807997890773, -8.243087167338842],
              [-35.202745233305848, -8.242940436651793],
              [-35.202727758579158, -8.242776602658012],
              [-35.20278399048874, -8.242629482564496],
              [-35.202868627485145, -8.242504877897773],
              [-35.203010160090798, -8.24245920312967],
              [-35.203106489173081, -8.242458892863567],
            ],
            [
              [-35.250704782999264, -8.245889567249662],
              [-35.250766705818762, -8.245929353256997],
              [-35.250817700076766, -8.245929608664483],
              [-35.250931022748432, -8.245913337230661],
              [-35.251027292475499, -8.245913880421199],
              [-35.251146332137466, -8.24589757751113],
              [-35.251254177038618, -8.245858827522852],
              [-35.251401524660594, -8.245842727859554],
              [-35.251605083507741, -8.245883251310943],
              [-35.251769171110311, -8.245906645375328],
              [-35.251899200487685, -8.245924265792212],
              [-35.252029231663641, -8.245958785369623],
              [-35.252193403802494, -8.245965275776825],
              [-35.252345970190333, -8.246005547418008],
              [-35.252430433617576, -8.2460679801531],
              [-35.252509386898623, -8.246119058947386],
              [-35.252576938574805, -8.246175803470521],
              [-35.252583556685749, -8.246187775245573],
              [-35.252605031596886, -8.246205814746531],
              [-35.252711188470528, -8.246397833493177],
              [-35.252767254904825, -8.246476969336641],
              [-35.252794810132343, -8.24658410377333],
              [-35.252799941663909, -8.246657377835675],
              [-35.252782041557907, -8.246781128146495],
              [-35.252747209016434, -8.246910486629588],
              [-35.252695051035744, -8.247073476159926],
              [-35.252683011811484, -8.247174778493161],
              [-35.252659777826466, -8.247253545614569],
              [-35.252618354153697, -8.247501076813194],
              [-35.252576868427695, -8.247754213486132],
              [-35.252576086017484, -8.24787253458976],
              [-35.252581101536578, -8.247957017802094],
              [-35.252641951464852, -8.248160046948556],
              [-35.25266883759673, -8.248357300648877],
              [-35.252679654642293, -8.248424939559243],
              [-35.252724354545308, -8.248515257573047],
              [-35.252780938989076, -8.248629803322729],
              [-35.252881388765047, -8.248717218324941],
              [-35.252971508028622, -8.248785225957363],
              [-35.253027898218875, -8.248824950810786],
              [-35.253118046260795, -8.248881750192595],
              [-35.253143792052299, -8.248910280285145],
              [-35.253146150074713, -8.248911766864524],
              [-35.253258546444727, -8.249036319370589],
              [-35.253297470002224, -8.249132273435634],
              [-35.253408576744391, -8.249259411492574],
              [-35.253466020940095, -8.249317266678474],
              [-35.253467941891984, -8.249320910731399],
              [-35.253494127451972, -8.249347282451083],
              [-35.253538794080043, -8.249431995889836],
              [-35.253577980239527, -8.249494231673395],
              [-35.253628362059203, -8.249578913635901],
              [-35.253656120533492, -8.249657936164795],
              [-35.253672537657451, -8.249736841781782],
              [-35.253700178320514, -8.249827074359928],
              [-35.253722220703878, -8.249906036634229],
              [-35.25372735403414, -8.249979312495213],
              [-35.253735500093228, -8.250056095712182],
              [-35.253746845940157, -8.250152836684038],
              [-35.253794358023185, -8.25020395055185],
              [-35.253856926555898, -8.250236112106847],
              [-35.253875172001528, -8.250261058401065],
              [-35.253885038463693, -8.250266128778776],
              [-35.253922021284211, -8.250316693160812],
              [-35.253972905824924, -8.250351786505689],
              [-35.254047215906269, -8.250383908490562],
              [-35.254127976824634, -8.250449707388043],
              [-35.254133928537954, -8.250461670169898],
              [-35.254156090531126, -8.250479725858611],
              [-35.254239242746792, -8.2506468369819],
              [-35.254234106718627, -8.250669459427968],
              [-35.254220756282848, -8.250827440633827],
              [-35.254211193791491, -8.250900888265335],
              [-35.254151378083577, -8.251009238585425],
              [-35.254114735206826, -8.251139601611271],
              [-35.254072342964207, -8.251264484168587],
              [-35.254017294562459, -8.251333305687467],
              [-35.25398960353732, -8.251362115469192],
              [-35.253935136097596, -8.251453441622971],
              [-35.253897298921174, -8.251533103569841],
              [-35.253781578656913, -8.251670872512705],
              [-35.253749087050664, -8.251733601124727],
              [-35.253732857885041, -8.251767680933654],
              [-35.253691446802804, -8.251937659994951],
              [-35.253682372643311, -8.252033609563398],
              [-35.253673453167266, -8.252140768281777],
              [-35.253675473943929, -8.252225180447681],
              [-35.253681926579588, -8.252258857360346],
              [-35.253682539917236, -8.252287055603063],
              [-35.253688716461056, -8.2523449665469],
              [-35.25369049172275, -8.252346311932683],
              [-35.253770766107948, -8.252389609792601],
              [-35.253781891620974, -8.252383671569135],
              [-35.253872309459496, -8.252376023734485],
              [-35.254035812502707, -8.252344189532664],
              [-35.254137203868822, -8.252319397921838],
              [-35.254182015287711, -8.252301518500246],
              [-35.254243270810264, -8.25225508020776],
              [-35.25433237204129, -8.252191126719083],
              [-35.254377274291755, -8.252173246398172],
              [-35.254382654036192, -8.252161829504757],
              [-35.254431006086008, -8.252048121923224],
              [-35.254463709932224, -8.251991085120437],
              [-35.254513409166464, -8.25193928327127],
              [-35.254556261861978, -8.251831303471818],
              [-35.254578114488368, -8.251797013221562],
              [-35.254605593273482, -8.251762601562746],
              [-35.254666478275283, -8.251699261411716],
              [-35.25477731252289, -8.251595414896428],
              [-35.254799043740832, -8.251555523668458],
              [-35.25483749605354, -8.251503965535562],
              [-35.254853846627668, -8.251475494798171],
              [-35.254942457728191, -8.251388946742452],
              [-35.254969444584162, -8.251332033146753],
              [-35.254968619905867, -8.25129832475784],
              [-35.254984481248755, -8.251247256755448],
              [-35.255011714518957, -8.251201549611778],
              [-35.255027819578174, -8.251161779792255],
              [-35.255049306180467, -8.251110588582776],
              [-35.255075713873055, -8.251031170352178],
              [-35.255085491302339, -8.250963328194985],
              [-35.255107346626687, -8.25092912787693],
              [-35.255122750214696, -8.250861160713953],
              [-35.255166546299051, -8.250792582911345],
              [-35.255227464575796, -8.25073493456955],
              [-35.255277258238834, -8.250683134519022],
              [-35.255355262735122, -8.250630632997286],
              [-35.255506936096197, -8.250576541474175],
              [-35.255555899684964, -8.250491031236038],
              [-35.255618348607868, -8.250495199593729],
              [-35.255714513114242, -8.25049312215981],
              [-35.255782340882263, -8.250485961757704],
              [-35.255827611854784, -8.25048489336308],
              [-35.255901309497801, -8.250488907936699],
              [-35.255986712716549, -8.250509487123111],
              [-35.25602672895036, -8.250525533726375],
              [-35.256060903188086, -8.250536006331572],
              [-35.25611201165998, -8.250540508337735],
              [-35.256197172961095, -8.250549881971438],
              [-35.256242934064119, -8.250571408143912],
              [-35.256299791002505, -8.250581391517983],
              [-35.256373733261114, -8.250596612543575],
              [-35.256436303592466, -8.250606474509141],
              [-35.256521251754293, -8.250610244467168],
              [-35.256549311501487, -8.250598336543931],
              [-35.256577371248625, -8.25058642862075],
              [-35.256638747280306, -8.250545680338803],
              [-35.256688906068121, -8.250510690415979],
              [-35.256705164911409, -8.250482215182046],
              [-35.25666786642887, -8.250336631130551],
              [-35.256666797134926, -8.250291536425209],
              [-35.256660221292123, -8.25025225583687],
              [-35.256648021988553, -8.250213097556355],
              [-35.256641449743086, -8.250173815169376],
              [-35.256634997107369, -8.250140224591632],
              [-35.256628300755438, -8.250095251294738],
              [-35.256615599630152, -8.250063960283512],
              [-35.256598231023474, -8.249976381604654],
              [-35.256598491826878, -8.249942576988303],
              [-35.256592774836633, -8.249919278252037],
              [-35.256536542027732, -8.249898881628042],
              [-35.256521504463819, -8.249873617873106],
              [-35.256508429220617, -8.24986887484863],
              [-35.256474809864528, -8.249812392927538],
              [-35.256396236097373, -8.249716209535393],
              [-35.256293721478016, -8.24956928459477],
              [-35.256289763561711, -8.249565297900119],
              [-35.256250313001487, -8.249536956665168],
              [-35.256160310649761, -8.249457652648516],
              [-35.256087012305727, -8.249412147852297],
              [-35.255952105904896, -8.249270639528334],
              [-35.255890150709831, -8.24922525254334],
              [-35.25581691172107, -8.249174142272807],
              [-35.255721224754666, -8.249100473408078],
              [-35.255642210319706, -8.249055001886802],
              [-35.255546522453983, -8.248981242190553],
              [-35.255473137775027, -8.248952641950837],
              [-35.255349315518458, -8.24884496342429],
              [-35.255264791836623, -8.248788222498433],
              [-35.255245764880101, -8.248764310424519],
              [-35.255236684425427, -8.248758213920382],
              [-35.255214204072217, -8.248729961718311],
              [-35.255169362975664, -8.248662152836062],
              [-35.255148075123543, -8.248476164942986],
              [-35.255137401969478, -8.248386020498401],
              [-35.255132590596531, -8.248273334546695],
              [-35.255134126638609, -8.248048082253149],
              [-35.255135378494856, -8.247884836416006],
              [-35.255135989134544, -8.247800322626745],
              [-35.255154554738851, -8.247569549395166],
              [-35.255290797532211, -8.247530912721402],
              [-35.255292247239367, -8.247322562286399],
              [-35.255293671765514, -8.247125422799968],
              [-35.255294284203785, -8.247040995345628],
              [-35.255294834588881, -8.246962085231985],
              [-35.255297189913335, -8.246641200830709],
              [-35.255297886887945, -8.246539782484945],
              [-35.255298033477402, -8.246517274252767],
              [-35.255305489756495, -8.246263879474384],
              [-35.255306276663305, -8.2461625501615],
              [-35.255313384005433, -8.245959864556085],
              [-35.255320436488887, -8.245762779928327],
              [-35.255344308992619, -8.245588381598509],
              [-35.255357075768416, -8.245391357225287],
              [-35.25536334224438, -8.245312504668334],
              [-35.25538733255928, -8.245126804558311],
              [-35.255411410108422, -8.244924203489177],
              [-35.255435225055521, -8.244755408834976],
              [-35.255506034076177, -8.244361556140859],
              [-35.255535648751163, -8.244175918983444],
              [-35.255559757776552, -8.243978920690608],
              [-35.255631090202598, -8.243500643240054],
              [-35.255643448686158, -8.243359932614737],
              [-35.255685075605697, -8.243084292206277],
              [-35.255690699965783, -8.243084260730029],
              [-35.255657988025689, -8.242915149514488],
              [-35.255659032138567, -8.242763113726653],
              [-35.255667159311884, -8.242419688717632],
              [-35.255678965611708, -8.242357796475062],
              [-35.255726566727617, -8.242031414518124],
              [-35.25576744091461, -8.241862703500942],
              [-35.255774057226915, -8.241733232602542],
              [-35.255786765546759, -8.241541812804371],
              [-35.255799557503508, -8.241333491147657],
              [-35.255823695307242, -8.241125285503472],
              [-35.255828267460515, -8.241082637853424],
              [-35.255632261119899, -8.240973923307877],
              [-35.255627311251374, -8.240956761545249],
              [-35.25560418518495, -8.240943935414236],
              [-35.255577672271727, -8.240852022003139],
              [-35.255469661895347, -8.240747785182066],
              [-35.255454710666299, -8.240730463340185],
              [-35.255441586859718, -8.240717797288482],
              [-35.255329251643616, -8.240587639308046],
              [-35.25524637192251, -8.2404695421356],
              [-35.255132597791089, -8.240379818573729],
              [-35.255087611903718, -8.240334604258521],
              [-35.255079061149729, -8.24032423687396],
              [-35.255059538666728, -8.240304616364881],
              [-35.255052326103907, -8.240295871357318],
              [-35.254755217079548, -8.240101828236732],
              [-35.254745663581446, -8.240083934425968],
              [-35.254727145641198, -8.240071839443772],
              [-35.254687990058642, -8.239998484442481],
              [-35.25461495791393, -8.239919174130534],
              [-35.254558888781673, -8.23984003828707],
              [-35.254480639669737, -8.2397044466029],
              [-35.254447080568298, -8.239642359207437],
              [-35.25439122097805, -8.239535022423297],
              [-35.254355700455164, -8.239442198898303],
              [-35.254149662177099, -8.239265083716589],
              [-35.254104676289728, -8.239219781267821],
              [-35.253980885509407, -8.239117797248525],
              [-35.253973618987288, -8.239104942339168],
              [-35.253952821265671, -8.239087808455565],
              [-35.25393045872363, -8.23904825267465],
              [-35.253885792095559, -8.238963539235897],
              [-35.253824331527653, -8.238844935745135],
              [-35.253785148965392, -8.238782789893776],
              [-35.253701095629026, -8.238664041612196],
              [-35.253653698659207, -8.238589733329491],
              [-35.253639390445471, -8.238575312700448],
              [-35.253638275286107, -8.238572484332622],
              [-35.253611328899638, -8.238545324806864],
              [-35.253604957202981, -8.238529178378826],
              [-35.253414284741382, -8.238382605972447],
              [-35.25340484096057, -8.238366927191862],
              [-35.253386224094925, -8.238352616280167],
              [-35.253307915627772, -8.238222628271615],
              [-35.253252547966667, -8.238042165813283],
              [-35.253174786287332, -8.237911259596899],
              [-35.253146088021481, -8.237885536288445],
              [-35.252971857864907, -8.237704383150742],
              [-35.252971184272667, -8.237702867793075],
              [-35.252943799017089, -8.237674394357782],
              [-35.252893622242823, -8.237561512353864],
              [-35.2528717570259, -8.237448742765196],
              [-35.252816681644504, -8.237240078466812],
              [-35.252749828741571, -8.237082002832096],
              [-35.252744876175029, -8.236991917742785],
              [-35.252729100267743, -8.236817286488531],
              [-35.252506181714864, -8.236320503688262],
              [-35.252495102966577, -8.236286582160005],
              [-35.252495568815391, -8.236224663837106],
              [-35.252495919550995, -8.236174044596453],
              [-35.252496069737788, -8.236153167734471],
              [-35.252496009483195, -8.236153120969732],
              [-35.252490351848223, -8.236141190563444],
              [-35.252467957829936, -8.236123134874731],
              [-35.252334077555588, -8.235840881751244],
              [-35.252317492258442, -8.235795692617046],
              [-35.252239356461075, -8.235631985427744],
              [-35.25225842118914, -8.23534490294486],
              [-35.252163088555676, -8.235220429579101],
              [-35.252129615789102, -8.235141440325151],
              [-35.252090636473667, -8.235051180767357],
              [-35.25214845119001, -8.234893765235029],
              [-35.252154339051458, -8.234860016376615],
              [-35.251941422758421, -8.234543501781161],
              [-35.251879937908768, -8.234436193775252],
              [-35.25187995409658, -8.234429689878255],
              [-35.251649295978211, -8.23422258950086],
              [-35.251440781866563, -8.2340749819748],
              [-35.251266120035382, -8.233961439868494],
              [-35.251018540273378, -8.233740474643128],
              [-35.251003046753169, -8.233721686906279],
              [-35.250990501210595, -8.233710489447503],
              [-35.25096245495331, -8.233676479785686],
              [-35.250928930026078, -8.233619994267258],
              [-35.250889570297375, -8.233574751173762],
              [-35.250867691590656, -8.233549961361575],
              [-35.250799504093777, -8.23349711450021],
              [-35.250596418290002, -8.233394578297123],
              [-35.250577540620952, -8.233369218314749],
              [-35.250568381025914, -8.233364594000761],
              [-35.25042890516977, -8.233177215755859],
              [-35.250405541682369, -8.233157025975913],
              [-35.250360646626518, -8.233111722627825],
              [-35.250359537762392, -8.233108907749852],
              [-35.250332612060333, -8.233081737432201],
              [-35.250277017770088, -8.232940684165214],
              [-35.250232729756647, -8.232794050604923],
              [-35.250204947000668, -8.232726326258899],
              [-35.250182672592246, -8.232669869518816],
              [-35.250160778597035, -8.232568397213697],
              [-35.250133226067476, -8.232461261877688],
              [-35.250106315653909, -8.232258312770853],
              [-35.250198268635131, -8.232073046134133],
              [-35.250278649140284, -8.231927074474868],
              [-35.250330511244044, -8.231792197751702],
              [-35.25035902245088, -8.23176419915842],
              [-35.25042759395825, -8.231680115245069],
              [-35.250684674158322, -8.231366118751339],
              [-35.250736013756011, -8.231315754918114],
              [-35.25094087751944, -8.231170439763957],
              [-35.251076761483318, -8.231165520472359],
              [-35.251184806033905, -8.231098572241251],
              [-35.251253026805671, -8.231065200198771],
              [-35.251292967496397, -8.231020324928011],
              [-35.25142996392168, -8.230874667132127],
              [-35.251573368016466, -8.230610748487209],
              [-35.251591270820427, -8.230486996377749],
              [-35.251597446464928, -8.230408146518698],
              [-35.251581151648793, -8.230317939121562],
              [-35.25152086559541, -8.230195981159568],
              [-35.251300466043631, -8.22996893291986],
              [-35.251292098751321, -8.229959207651291],
              [-35.25127244226934, -8.229938958516072],
              [-35.251199471278539, -8.229854132662069],
              [-35.251137749907173, -8.229769332888338],
              [-35.251043498258809, -8.229498520939956],
              [-35.251026879686719, -8.229447723633484],
              [-35.251027493923687, -8.229363210743543],
              [-35.251091308017408, -8.229143935344268],
              [-35.251218741052014, -8.228750490942332],
              [-35.25129314106556, -8.228638264544202],
              [-35.251327657945012, -8.228565217111054],
              [-35.251346258622902, -8.228340051152372],
              [-35.251386522170208, -8.228255856622411],
              [-35.25144403921098, -8.228126643829512],
              [-35.251461473468169, -8.228064811841534],
              [-35.251473250989704, -8.227997225991146],
              [-35.251519169474022, -8.227918692693493],
              [-35.251587678028898, -8.227840215153776],
              [-35.251701787607374, -8.227722614407071],
              [-35.25174752982457, -8.22766089753236],
              [-35.251850324133102, -8.227531970723817],
              [-35.251879159095893, -8.22746447030903],
              [-35.251902160156533, -8.227408303150639],
              [-35.251931223547103, -8.227301482577332],
              [-35.251931896239967, -8.2272113633137],
              [-35.251949270242562, -8.227155135001453],
              [-35.25194988178157, -8.227070710245016],
              [-35.25188119785895, -8.226394550468058],
              [-35.251865425548885, -8.226219919213861],
              [-35.251877785831084, -8.226079206789905],
              [-35.251884201594578, -8.225977847799413],
              [-35.251890525627232, -8.225876488808865],
              [-35.251930786476578, -8.225792293379584],
              [-35.251931663315588, -8.225674063107476],
              [-35.251915597826553, -8.225544448317578],
              [-35.251899712201919, -8.225397927172537],
              [-35.251900293163942, -8.225307809707601],
              [-35.251895219188953, -8.225228932868902],
              [-35.251896531299849, -8.225043086169421],
              [-35.251858430622008, -8.224834507306639],
              [-35.251847964312049, -8.224716248256186],
              [-35.251848516495784, -8.224637426276161],
              [-35.251861025166136, -8.224474120184425],
              [-35.251890266622468, -8.22435039685331],
              [-35.251926239504314, -8.224069092514583],
              [-35.251939710449221, -8.223770648896277],
              [-35.251934638272871, -8.223691768460299],
              [-35.251908519262713, -8.2233875808721],
              [-35.251875661632425, -8.223224074231609],
              [-35.251839891997349, -8.222972708323766],
              [-35.251775822496143, -8.22293233595849],
              [-35.251774952851747, -8.222919473854574],
              [-35.251747826600877, -8.222902380440473],
              [-35.25173753386008, -8.22275022684147],
              [-35.25167045342863, -8.222631653927635],
              [-35.251631357201347, -8.222552602620453],
              [-35.251564189535657, -8.222450840733643],
              [-35.25146992529676, -8.222318749210842],
              [-35.251345079611667, -8.22219791270345],
              [-35.251334625892241, -8.222184931889046],
              [-35.25131708911232, -8.222167957185434],
              [-35.251251703902994, -8.222086778981634],
              [-35.251227184786728, -8.222062086296205],
              [-35.251153979972173, -8.221999766875683],
              [-35.250996233489332, -8.221891915679294],
              [-35.250967923730627, -8.221891712432523],
              [-35.250820787449356, -8.221879609356449],
              [-35.250752744743352, -8.221896169472586],
              [-35.250695868019875, -8.221929660225612],
              [-35.250644525724226, -8.221980021360878],
              [-35.25058146975897, -8.222092364670857],
              [-35.25054712644868, -8.222148415816662],
              [-35.250524095710432, -8.222198980198755],
              [-35.250501003818215, -8.222255150954425],
              [-35.25046051274245, -8.222378845507251],
              [-35.250391098570276, -8.222586853300584],
              [-35.250362059461452, -8.222682375691022],
              [-35.250338474740772, -8.222811760254501],
              [-35.250326172015207, -8.222946869902103],
              [-35.25030217900229, -8.22313256821343],
              [-35.250272558931385, -8.223318298900324],
              [-35.250192007555086, -8.223497988841075],
              [-35.250082535780336, -8.223762078357197],
              [-35.250019097603229, -8.223919437232212],
              [-35.249915309543837, -8.224177892495675],
              [-35.249801671210093, -8.224233574919481],
              [-35.249750653569777, -8.224244617694922],
              [-35.249483042308498, -8.224299263200407],
              [-35.24921303525332, -8.22442767649602],
              [-35.248995221252414, -8.224512683113801],
              [-35.248955946959313, -8.22454680069427],
              [-35.248770057092429, -8.224661093734426],
              [-35.248713603949625, -8.224689702967396],
              [-35.248453414793232, -8.2247255931116],
              [-35.248368530483276, -8.224731944123903],
              [-35.248334539707173, -8.224737828388015],
              [-35.248158554073996, -8.224694253536939],
              [-35.248062296937405, -8.224694971195902],
              [-35.247988718004876, -8.224695563849139],
              [-35.247830011945439, -8.224691208432489],
              [-35.247609751788559, -8.224760681959879],
              [-35.247524927733195, -8.224778238524834],
              [-35.247417580157162, -8.224807312707242],
              [-35.247355468480066, -8.224830348841465],
              [-35.247208597498741, -8.224865333368371],
              [-35.247095595885014, -8.224905737209951],
              [-35.246977246003041, -8.224963071688364],
              [-35.246779826827492, -8.225060435890384],
              [-35.246751803053201, -8.225094489618982],
              [-35.246649951234247, -8.225101024992284],
              [-35.246605522027153, -8.225202776986521],
              [-35.246605676710544, -8.22521407427007],
              [-35.246522412978948, -8.225411948502597],
              [-35.24647807550275, -8.225513790429034],
              [-35.24645101670103, -8.225654944420114],
              [-35.246379186050547, -8.225852754800769],
              [-35.246346379681654, -8.225988246660222],
              [-35.246324286036952, -8.226044771748775],
              [-35.246269765537193, -8.226287586902572],
              [-35.246106802087183, -8.226429863247517],
              [-35.246056716144494, -8.2265260457404],
              [-35.246028723846507, -8.226565699547336],
              [-35.246000699172896, -8.226599751477295],
              [-35.245967328929055, -8.226667640399228],
              [-35.245911498117096, -8.226758247994724],
              [-35.245889470122904, -8.226826071266146],
              [-35.245789176829021, -8.227012921609003],
              [-35.245727782810889, -8.227114859762935],
              [-35.24570597245264, -8.227205187669256],
              [-35.245690753225631, -8.227402589757673],
              [-35.245636288483865, -8.227639709504956],
              [-35.24560351449054, -8.227780897670243],
              [-35.245570395157586, -8.227876982136991],
              [-35.245554329668551, -8.227972884940641],
              [-35.245504188867187, -8.228074759242702],
              [-35.24547613001937, -8.228103115766146],
              [-35.245420015920956, -8.228160011375394],
              [-35.245374962584435, -8.228182861349921],
              [-35.245290171803958, -8.228206114220768],
              [-35.245131646508241, -8.228218659763286],
              [-35.244984338456391, -8.228208633221811],
              [-35.24487670759396, -8.228203813754931],
              [-35.244797258786434, -8.228193229633803],
              [-35.244751955438346, -8.228187968599798],
              [-35.244660949443244, -8.228154853763442],
              [-35.244649514563378, -8.22813801395813],
              [-35.244645218502001, -8.228129331903119],
              [-35.244632941856764, -8.228124864970482],
              [-35.244621506976898, -8.22810802426585],
              [-35.244546526900649, -8.227956502890265],
              [-35.244556967130279, -8.227860633361502],
              [-35.244606741907546, -8.227724954443033],
              [-35.24466285510664, -8.227668150564625],
              [-35.244685162790006, -8.22763413280893],
              [-35.244741399196187, -8.227582930807557],
              [-35.244814602212102, -8.227531630780106],
              [-35.244882117016061, -8.227485969501856],
              [-35.244904679207536, -8.227480149988878],
              [-35.244938665486984, -8.227474267523405],
              [-35.244989438511709, -8.227451297939012],
              [-35.245006338571613, -8.227439903528705],
              [-35.245023244027436, -8.22742851091698],
              [-35.245073858771548, -8.227394241351135],
              [-35.245113287747984, -8.227371425550814],
              [-35.245158399540458, -8.227342881968355],
              [-35.245163994222935, -8.227337155984912],
              [-35.245186023116446, -8.22726933451213],
              [-35.245230451424163, -8.227167578920557],
              [-35.245230267063107, -8.22715067976003],
              [-35.245234860800167, -8.227032244442455],
              [-35.245228616807196, -8.226970363891155],
              [-35.245022112680317, -8.226678934984818],
              [-35.245011652665596, -8.226667013571785],
              [-35.244924618076595, -8.226608476699766],
              [-35.244816270454464, -8.226524838850196],
              [-35.244685275205256, -8.226447023211506],
              [-35.244605481957365, -8.226391247258221],
              [-35.244580465516037, -8.226363349389032],
              [-35.24457748066618, -8.226361263861179],
              [-35.244563326236459, -8.226345476262679],
              [-35.244468673490474, -8.226296562136611],
              [-35.244366233514825, -8.226246698326406],
              [-35.244351881234309, -8.226223355523416],
              [-35.244338234022223, -8.226216713130782],
              [-35.244304083166867, -8.226161176397113],
              [-35.244274821026124, -8.226157272440105],
              [-35.244235110561874, -8.226146285422715],
              [-35.244211542928326, -8.226117526902271],
              [-35.244207111968592, -8.226116300227034],
              [-35.244138321925959, -8.226032354809263],
              [-35.244023385870491, -8.225841645475498],
              [-35.243966090062941, -8.225768841758565],
              [-35.243948439069186, -8.225695726876268],
              [-35.24394782123494, -8.225633726715046],
              [-35.243957979077436, -8.225504050771292],
              [-35.243985441674738, -8.225402393205854],
              [-35.243990971606024, -8.225385459871006],
              [-35.244040560223652, -8.225232879993371],
              [-35.24410701922352, -8.225063215694774],
              [-35.244145731440312, -8.224961494277466],
              [-35.244190255076148, -8.224876551511613],
              [-35.244212404478844, -8.224814421848066],
              [-35.244251435055617, -8.224752109622102],
              [-35.244273462150488, -8.22468428635068],
              [-35.244301172960718, -8.224610827927336],
              [-35.244502537462097, -8.224327332840119],
              [-35.244525067278005, -8.224315909651466],
              [-35.24455890157202, -8.224298725405788],
              [-35.24459255060566, -8.224264644697541],
              [-35.244637696572397, -8.224241704790813],
              [-35.24466578509788, -8.224218949245085],
              [-35.244727894976336, -8.22419591760746],
              [-35.244755982602499, -8.22417316565901],
              [-35.244908260307909, -8.224098647834296],
              [-35.244919536007728, -8.224087287598195],
              [-35.244958748247598, -8.224041875432135],
              [-35.245009115678101, -8.223979497555717],
              [-35.245031297456364, -8.223922971567845],
              [-35.245081132488224, -8.223798501799365],
              [-35.245069667031487, -8.223776060117018],
              [-35.245059754703846, -8.223747362750544],
              [-35.244977566561431, -8.223683054928983],
              [-35.244875278571214, -8.223644399369505],
              [-35.244846788948109, -8.223627744824512],
              [-35.244818295727725, -8.223611093876855],
              [-35.244641998929069, -8.22352801630484],
              [-35.24448857368867, -8.223478527511986],
              [-35.244471544126441, -8.223467323757916],
              [-35.244346327920653, -8.223400773926528],
              [-35.244331440543476, -8.223377758476715],
              [-35.244318340119264, -8.223370795925462],
              [-35.244262136088651, -8.223283905228016],
              [-35.244203275460734, -8.223244118321361],
              [-35.244180266306216, -8.223217504684044],
              [-35.244175287659345, -8.223214141219614],
              [-35.244112369290349, -8.22314136628097],
              [-35.244055005134328, -8.223046303444107],
              [-35.244019954956912, -8.223008866465989],
              [-35.243945757290817, -8.222947454461405],
              [-35.243933049870293, -8.22293012362627],
              [-35.243917769489428, -8.222917476460339],
              [-35.24388906223038, -8.222878319978463],
              [-35.243831173769593, -8.222732215219537],
              [-35.243830740296403, -8.2226872041511],
              [-35.243830240273326, -8.22263080586697],
              [-35.243829276200074, -8.222523701107889],
              [-35.24382890388074, -8.222489898290121],
              [-35.243839778482936, -8.222439130661314],
              [-35.243872619925412, -8.222309242477536],
              [-35.24387821190993, -8.222303516494037],
              [-35.243915432151425, -8.222032688357842],
              [-35.243988134244944, -8.221925083575684],
              [-35.244032690256404, -8.221845746284089],
              [-35.244093957470113, -8.221721302595995],
              [-35.244194096979925, -8.221523246700428],
              [-35.244227652484142, -8.221472260536359],
              [-35.244260867145272, -8.221393079726795],
              [-35.244366658894137, -8.221183692373472],
              [-35.244445361264354, -8.221126672657761],
              [-35.244507343439125, -8.221081042855815],
              [-35.244535465239494, -8.221063981817281],
              [-35.244585547584904, -8.220967711190895],
              [-35.244646228440672, -8.220786869218614],
              [-35.244650979559026, -8.220679735681188],
              [-35.244656603019791, -8.220679702406301],
              [-35.244695223505744, -8.220561078231128],
              [-35.244706094510605, -8.220510222468761],
              [-35.24470538044892, -8.220431315952396],
              [-35.244714075993784, -8.22013823319287],
              [-35.244724703282373, -8.22005926462333],
              [-35.244785660229979, -8.2199122272674],
              [-35.24479681631999, -8.219895172524161],
              [-35.244943062272455, -8.219781280581572],
              [-35.244993581688391, -8.219730202686662],
              [-35.245032980987219, -8.219701690580507],
              [-35.24506100386219, -8.219667639549868],
              [-35.245117181812475, -8.21962213295501],
              [-35.245229715778692, -8.219531026235813],
              [-35.245487223156772, -8.219196423576022],
              [-35.245532118212623, -8.219145282728562],
              [-35.245582670903502, -8.219099807610007],
              [-35.245638966664956, -8.219059904690823],
              [-35.245723230442707, -8.218991553517355],
              [-35.245897469592478, -8.218838006868452],
              [-35.245959144199105, -8.218769870632968],
              [-35.245969520576864, -8.218662705619295],
              [-35.245968774139556, -8.218578197225952],
              [-35.245962309812683, -8.218493722107553],
              [-35.245961314263184, -8.218381014572117],
              [-35.245977003835606, -8.218234317159954],
              [-35.245987875739843, -8.218183547732508],
              [-35.245987192255086, -8.218110248489154],
              [-35.246002380905111, -8.217907245422964],
              [-35.246001634467802, -8.217822736130302],
              [-35.245988895571031, -8.21767068775199],
              [-35.245982740610941, -8.217608715469794],
              [-35.245987087933713, -8.217462173640342],
              [-35.245997373479952, -8.217355006828029],
              [-35.246024867553558, -8.217258951139627],
              [-35.246023839628435, -8.217140549996316],
              [-35.246029154621738, -8.217101112926002],
              [-35.246051212293594, -8.217038893330198],
              [-35.246067399191077, -8.216948596900181],
              [-35.246131742086163, -8.216547910257816],
              [-35.246131181808551, -8.216480303723017],
              [-35.246169111615188, -8.216288379405114],
              [-35.246168460505999, -8.216220776467594],
              [-35.246167810296186, -8.216153169932795],
              [-35.246173277274863, -8.216124936616495],
              [-35.246173091115224, -8.216108036556591],
              [-35.246172094666406, -8.215995330819794],
              [-35.246171846453535, -8.215967128979798],
              [-35.246165598863229, -8.215905157596922],
              [-35.246164291248988, -8.215753043568043],
              [-35.246168952435141, -8.215645909131354],
              [-35.246196043612485, -8.215510448748148],
              [-35.246212762009293, -8.215482153378616],
              [-35.246229193522424, -8.215419966158493],
              [-35.246257188518371, -8.21538030965354],
              [-35.246248050507063, -8.214997120220858],
              [-35.246247955178944, -8.214980216563674],
              [-35.246201034849832, -8.214800248732445],
              [-35.246177453726489, -8.214704657993536],
              [-35.246141378321909, -8.21446822442897],
              [-35.246140134559539, -8.21432731775144],
              [-35.246139170486288, -8.214220303823936],
              [-35.24613867226185, -8.214163904640429],
              [-35.246159455594352, -8.213955265523055],
              [-35.246158585949956, -8.213865066219796],
              [-35.246157804439065, -8.213774862419939],
              [-35.246157154229252, -8.213707258583042],
              [-35.246166565634439, -8.213493077843225],
              [-35.246177129071214, -8.213402810191496],
              [-35.24618243956786, -8.213363281390286],
              [-35.246187569300844, -8.213306852529172],
              [-35.246192599209053, -8.213233609943074],
              [-35.246225313847106, -8.213098026352782],
              [-35.246224972104756, -8.213053014385082],
              [-35.246230377929578, -8.213030389240998],
              [-35.246268375185309, -8.212849764005341],
              [-35.246272909567097, -8.212720031404274],
              [-35.246272413141298, -8.212663722153025],
              [-35.246271881641974, -8.212601722891179],
              [-35.24627141489384, -8.212551018214867],
              [-35.246248303216589, -8.212506131252894],
              [-35.246269832087023, -8.212381911495982],
              [-35.246308698987207, -8.212291486462902],
              [-35.246325072044385, -8.212218093690012],
              [-35.246329948168523, -8.212133462988902],
              [-35.246340822770719, -8.212082693561399],
              [-35.246362664605272, -8.211997968431433],
              [-35.246373692092163, -8.211958409952615],
              [-35.246372978030479, -8.211879505234947],
              [-35.246389600199848, -8.211834308906191],
              [-35.246456335291612, -8.211698536458243],
              [-35.24648948700019, -8.211608054767851],
              [-35.246512045594386, -8.21160223345629],
              [-35.246539850833415, -8.211545677790753],
              [-35.24656778017885, -8.21149472490157],
              [-35.246607176779719, -8.211466213694735],
              [-35.246680252091892, -8.211409224556007],
              [-35.246714023433412, -8.211380835656996],
              [-35.246736549651985, -8.211369320737504],
              [-35.246832589152632, -8.211346003115523],
              [-35.246894630682618, -8.211311673295029],
              [-35.246917189276814, -8.211305854681427],
              [-35.247001728247142, -8.211254495298647],
              [-35.247058088759729, -8.211225799730812],
              [-35.247097702996541, -8.211219882191756],
              [-35.2471317549265, -8.211225207976895],
              [-35.247245658560246, -8.211297606998926],
              [-35.247251435805083, -8.211308779276692],
              [-35.247251985290859, -8.211313805587622],
              [-35.247273600496214, -8.211327544530491],
              [-35.247279377741052, -8.211338718606953],
              [-35.24729171464088, -8.211451363189838],
              [-35.247314893767282, -8.211507545636664],
              [-35.247320798715862, -8.211541317877447],
              [-35.24737302954162, -8.211681759605483],
              [-35.247384771989573, -8.211721102246997],
              [-35.24741364112657, -8.211788551400502],
              [-35.247459810521775, -8.211883923604148],
              [-35.247494360676171, -8.211945646774154],
              [-35.247534692571946, -8.212018632154127],
              [-35.247583313519044, -8.212169693976136],
              [-35.247616270074786, -8.212201939168096],
              [-35.247623742541634, -8.212211847898402],
              [-35.247644215608034, -8.212231879397677],
              [-35.247724471107404, -8.212338270994337],
              [-35.247764550293709, -8.212383058131593],
              [-35.247816067957103, -8.212444686872743],
              [-35.247831416686495, -8.212461803669271],
              [-35.247896614836861, -8.212509591844139],
              [-35.2479934025734, -8.212570874346341],
              [-35.248001049508787, -8.212587960565884],
              [-35.248021350804663, -8.212600814575865],
              [-35.248079142138636, -8.212729927544046],
              [-35.248229658971525, -8.212902418411829],
              [-35.248268653575451, -8.212940560458435],
              [-35.248348534057584, -8.213013236471625],
              [-35.248374070307079, -8.213040979657421],
              [-35.24837648498675, -8.213043176701206],
              [-35.248530938152214, -8.21321097760449],
              [-35.248605350756293, -8.213294892445276],
              [-35.248623173520571, -8.213318137222188],
              [-35.248657406214306, -8.213343234602576],
              [-35.248682780585796, -8.213371287155155],
              [-35.248685358042792, -8.2133731748321],
              [-35.24881690997239, -8.213518595206949],
              [-35.248908973570224, -8.21367562672873],
              [-35.249012036776037, -8.213804485188803],
              [-35.249086635539754, -8.213905301888133],
              [-35.24915693014816, -8.213986899176007],
              [-35.249235753926882, -8.214048640332464],
              [-35.249257056168176, -8.2140733671921],
              [-35.249263711151286, -8.214078580561988],
              [-35.249341038458113, -8.214168343694041],
              [-35.249373055222293, -8.214199631108045],
              [-35.249401576321645, -8.214221979260913],
              [-35.249413406003839, -8.214239283116399],
              [-35.249429533546106, -8.214251919490437],
              [-35.249452495036564, -8.214285505571581],
              [-35.249492638974061, -8.214341591790969],
              [-35.249607229689843, -8.214487198325514],
              [-35.249745424911396, -8.214648665303628],
              [-35.249853877754219, -8.214647616694094],
              [-35.249984132861414, -8.21464272977812],
              [-35.250000952881635, -8.214665325244539],
              [-35.250005250741708, -8.214672927213769],
              [-35.250012091884514, -8.214672669108324],
              [-35.250028911904735, -8.214695264574743],
              [-35.250051272648079, -8.214734817657643],
              [-35.250090424633356, -8.21480826528915],
              [-35.250168670147957, -8.214943770638456],
              [-35.250202226551494, -8.215005949764702],
              [-35.250241286805874, -8.21507939739621],
              [-35.250274988000228, -8.215118980156774],
              [-35.250308661315614, -8.215169859301511],
              [-35.250342066633038, -8.215237554869191],
              [-35.250386992265817, -8.215288554523113],
              [-35.250438835483806, -8.215350269599242],
              [-35.250443307812361, -8.21535476531011],
              [-35.250471412525599, -8.215383079565413],
              [-35.250522078531048, -8.215422749560219],
              [-35.250589685065847, -8.215473800475479],
              [-35.250629157209801, -8.215490934359082],
              [-35.25071407749266, -8.21549145326793],
              [-35.250742385452668, -8.2154915665825],
              [-35.250776407705018, -8.215491738353023],
              [-35.250872988597507, -8.215435971392935],
              [-35.25091814985268, -8.21546437198316],
              [-35.250946950641207, -8.215391359623482],
              [-35.250969983178152, -8.215340795241445],
              [-35.251015871085542, -8.215256568335917],
              [-35.25104994909583, -8.215234232773582],
              [-35.251112542809551, -8.21520079957719],
              [-35.251180499180634, -8.215201145816195],
              [-35.251214429702145, -8.215201314888702],
              [-35.251361154993276, -8.21526973351132],
              [-35.251411967588183, -8.215286893475309],
              [-35.251609504574958, -8.215372469363899],
              [-35.251615221565203, -8.215372527819852],
              [-35.251626470285316, -8.215372555698821],
              [-35.251677520301257, -8.215367208329951],
              [-35.251746027956756, -8.215288731689554],
              [-35.251831621831855, -8.215199130435451],
              [-35.251928468923666, -8.215109559758332],
              [-35.25202569193209, -8.214974880886018],
              [-35.252122422112052, -8.214913508451616],
              [-35.252190608709554, -8.214874441002621],
              [-35.252304158010418, -8.214835663135318],
              [-35.252372526271017, -8.21477969112982],
              [-35.252457736135568, -8.214735108138655],
              [-35.252520180561817, -8.214707371248153],
              [-35.252667782691958, -8.214657465169807],
              [-35.252730081428069, -8.214652144780587],
              [-35.252769788295041, -8.214646770432068],
              [-35.252826284605305, -8.214658294344758],
              [-35.252860187247791, -8.214669764298151],
              [-35.252922315112698, -8.21469825032392],
              [-35.25298988927193, -8.214743699362202],
              [-35.253023648922237, -8.214777675749076],
              [-35.253136408618332, -8.214840226295337],
              [-35.25322086395164, -8.214902662627708],
              [-35.253248130496786, -8.214932077653202],
              [-35.253248829270035, -8.214932592964772],
              [-35.25329960319408, -8.214987368871903],
              [-35.253316920539419, -8.214931321323377],
              [-35.253345228499427, -8.214931524570147],
              [-35.253508980654885, -8.214994329624574],
              [-35.253615910945484, -8.215085021756295],
              [-35.253630510539608, -8.215103616138947],
              [-35.253643877163142, -8.215114952992622],
              [-35.253730435111436, -8.215225196385632],
              [-35.253824032952707, -8.21527764574671],
              [-35.25385776112671, -8.215305929425028],
              [-35.253920875547919, -8.215188075968911],
              [-35.253937754923413, -8.21520506146436],
              [-35.253944893741789, -8.215225377149409],
              [-35.253948841765578, -8.215218005406598],
              [-35.253965721141071, -8.215234991801367],
              [-35.254021229096395, -8.215392949624857],
              [-35.254100882949331, -8.215523620218846],
              [-35.254105325600278, -8.215526986381292],
              [-35.254116548240063, -8.21553831154381],
              [-35.254121966655418, -8.215548337185965],
              [-35.254133293616576, -8.215556917617619],
              [-35.25414451625636, -8.215568241880817],
              [-35.254178072659897, -8.215630332873502],
              [-35.254239410920036, -8.215760236345773],
              [-35.254256086149439, -8.21580533644709],
              [-35.254294799265551, -8.215929402419931],
              [-35.254317160908215, -8.215968954603511],
              [-35.254406309803301, -8.21617218879544],
              [-35.254439894085806, -8.216223069738817],
              [-35.25447122646591, -8.216334983172885],
              [-35.254495677233649, -8.216356901449785],
              [-35.254501078561873, -8.216366600638082],
              [-35.254523648847282, -8.216386833585432],
              [-35.254573971311686, -8.216477211853771],
              [-35.254607230938916, -8.21656750018991],
              [-35.254646327166199, -8.216646551497092],
              [-35.254663087831204, -8.216674839672066],
              [-35.254679442002612, -8.216759349864049],
              [-35.254769144880072, -8.216883763874534],
              [-35.254830866251439, -8.21696855915161],
              [-35.254881185118563, -8.217058937420006],
              [-35.254947689084531, -8.217198883621563],
              [-35.255004478573767, -8.217243820945555],
              [-35.255029851146674, -8.217271690935718],
              [-35.255032455583319, -8.217273751282562],
              [-35.25509426328955, -8.217341648298373],
              [-35.25518472969145, -8.217465048772908],
              [-35.255217851722421, -8.21749841811743],
              [-35.25523002764362, -8.217512431353555],
              [-35.255245828731972, -8.217528351152453],
              [-35.255324454659785, -8.217618840936723],
              [-35.255418969809568, -8.217855937301636],
              [-35.255490197914241, -8.217936093875608],
              [-35.255542788468858, -8.21797320979573],
              [-35.255604739167325, -8.21801859678078],
              [-35.255626087274038, -8.218043670778741],
              [-35.255632718874836, -8.218048529815746],
              [-35.25566642096851, -8.218088112576311],
              [-35.2557786905341, -8.218223966862524],
              [-35.255829272003268, -8.218280534219218],
              [-35.25591346383527, -8.218376687933755],
              [-35.25601356197626, -8.218504147948011],
              [-35.256020229549904, -8.21851086498441],
              [-35.256098882457422, -8.218590055686491],
              [-35.256109297506043, -8.21860230085548],
              [-35.256126865762212, -8.218619987822137],
              [-35.256194268150921, -8.218699239678131],
              [-35.256205434133449, -8.218716171214282],
              [-35.256227707642552, -8.218772627954365],
              [-35.256294439137037, -8.218941911839693],
              [-35.256333155850427, -8.219066067744791],
              [-35.256355312447681, -8.219133730936903],
              [-35.256411110884017, -8.219246673195357],
              [-35.256433414970104, -8.219291832651891],
              [-35.256466419189849, -8.219432833758219],
              [-35.256504846321548, -8.219602002530337],
              [-35.256515863915922, -8.219641438701387],
              [-35.256520850656671, -8.219737218297951],
              [-35.256525660230977, -8.219849814317456],
              [-35.256518725558692, -8.220035690694544],
              [-35.256455348535439, -8.220187353263668],
              [-35.25638115716464, -8.220271377821803],
              [-35.256369554111586, -8.220305156357824],
              [-35.256352528146635, -8.220310675497217],
              [-35.256324074496376, -8.220333071314144],
              [-35.256227489107289, -8.220371936415688],
              [-35.256079306914444, -8.220511873624048],
              [-35.256033684307056, -8.220562384946049],
              [-35.255993568248527, -8.220624073941792],
              [-35.255902291557447, -8.220719309448498],
              [-35.25585107156968, -8.220758463232471],
              [-35.255810692009788, -8.22085395954258],
              [-35.255775886447964, -8.220972017144845],
              [-35.255753088634094, -8.221000076891983],
              [-35.255741019732227, -8.221095682919383],
              [-35.255717436810244, -8.221225158314382],
              [-35.255716126497987, -8.221410911484327],
              [-35.255691352873612, -8.221714932697921],
              [-35.25569117660649, -8.221731834556465],
              [-35.255690970661703, -8.221759946464317],
              [-35.255690095621389, -8.221878178535064],
              [-35.25570074089643, -8.221979623860534],
              [-35.255722608811311, -8.222092393449145],
              [-35.255777966579842, -8.22225595314967],
              [-35.255794414280729, -8.222340554173172],
              [-35.255822110701786, -8.22242518127706],
              [-35.255883189057897, -8.22258880213144],
              [-35.255978696159843, -8.222696367343417],
              [-35.256022747651627, -8.222865507337247],
              [-35.256072338067895, -8.223051606746196],
              [-35.256083450091126, -8.223091133748767],
              [-35.256082981544296, -8.223153052970986],
              [-35.256116218688476, -8.223254550457114],
              [-35.256160209925724, -8.223429381360802],
              [-35.256259951035872, -8.223739664553818],
              [-35.256315546225437, -8.223880807753005],
              [-35.256359948452825, -8.223999236775285],
              [-35.256398839634699, -8.22410648812388],
              [-35.256460154512467, -8.224247599846819],
              [-35.256521315606165, -8.224394317943393],
              [-35.256560151030101, -8.224507173866925],
              [-35.256626391494706, -8.224749670660742],
              [-35.25659767614178, -8.22480586929538],
              [-35.256584969620633, -8.22499729269083],
              [-35.256583831978219, -8.225149329377984],
              [-35.256616982787477, -8.225267731420615],
              [-35.256667041750518, -8.225391911607403],
              [-35.256884183957879, -8.225911141485938],
              [-35.256895436275329, -8.225911258397787],
              [-35.256911705011134, -8.226012670448313],
              [-35.256922408742128, -8.226108510299468],
              [-35.256933021641601, -8.226204264715022],
              [-35.256932003609052, -8.226345091352812],
              [-35.256931069213408, -8.226468926199914],
              [-35.256929762498487, -8.226654769302115],
              [-35.256921982463496, -8.226947577768442],
              [-35.25688709056675, -8.22708253812857],
              [-35.256869657208881, -8.227144372814507],
              [-35.256811963900986, -8.227290488365327],
              [-35.256765756734239, -8.227414127160216],
              [-35.256685210753858, -8.22759390793243],
              [-35.256638798541701, -8.227745654138573],
              [-35.256615011473571, -8.227903243240064],
              [-35.25661411215151, -8.22803277349368],
              [-35.256590238748458, -8.228207172722819],
              [-35.25656073648878, -8.22836470156966],
              [-35.256543218594629, -8.228443438114141],
              [-35.256547882478742, -8.228578629600008],
              [-35.256511971650127, -8.228854332961077],
              [-35.256505210547004, -8.22900640022516],
              [-35.256504251869671, -8.229141533255131],
              [-35.256497837904874, -8.229242802313422],
              [-35.256462888451495, -8.229383459878704],
              [-35.256331492104607, -8.229540387978489],
              [-35.256222925947213, -8.229674951737593],
              [-35.256171297667208, -8.229770417470718],
              [-35.256142495979361, -8.229843430729716],
              [-35.256108122092144, -8.229893970830062],
              [-35.256011074451465, -8.230011656112993],
              [-35.255913906301714, -8.230140642276751],
              [-35.255799656428962, -8.230280840288515],
              [-35.255764817592194, -8.230393297812384],
              [-35.255678465588687, -8.230589920188606],
              [-35.255575470731344, -8.230746960703641],
              [-35.255529407456152, -8.230848089467713],
              [-35.255483231765709, -8.230977422769797],
              [-35.255465479148484, -8.231078665747759],
              [-35.255447637498435, -8.231196810584322],
              [-35.255439075952552, -8.231607847524117],
              [-35.255494469693986, -8.231777104429796],
              [-35.255643035897322, -8.232039810788592],
              [-35.255701795801201, -8.232086302141056],
              [-35.255705520793128, -8.232097031952435],
              [-35.255729833964608, -8.232116269350286],
              [-35.255763129564741, -8.232212162261419],
              [-35.25601531925156, -8.232574012580585],
              [-35.256031555611742, -8.232669821854756],
              [-35.256142844017006, -8.23295192479145],
              [-35.256169607841059, -8.232981956751871],
              [-35.256238562459657, -8.233035331515282],
              [-35.256356723483975, -8.233137348809578],
              [-35.256363204897923, -8.233148697354466],
              [-35.25638476794262, -8.233167315119431],
              [-35.256467853608513, -8.233312836218374],
              [-35.256710278456524, -8.233567230143535],
              [-35.256839841985084, -8.233663668943109],
              [-35.256873689768952, -8.233680742572176],
              [-35.256896371570235, -8.233680885564354],
              [-35.257026427027938, -8.233704108757593],
              [-35.257071557706183, -8.233726903873446],
              [-35.257150333820789, -8.233794882727636],
              [-35.257177038289626, -8.233823690710722],
              [-35.25717838187677, -8.233824849936809],
              [-35.257247387756706, -8.233899288621217],
              [-35.257308986820249, -8.233773202771033],
              [-35.257328051548313, -8.233821551223571],
              [-35.25733703397691, -8.233803168181623],
              [-35.257437185177935, -8.234057135828095],
              [-35.257535573707742, -8.234219254814718],
              [-35.257616745616247, -8.234287400942776],
              [-35.25766178995957, -8.234327007984973],
              [-35.257672433436028, -8.234341666934313],
              [-35.257689842512207, -8.234356974294883],
              [-35.257751473951316, -8.234441864000758],
              [-35.257850734823535, -8.234615925984144],
              [-35.257863454834535, -8.234626591943595],
              [-35.257886488270799, -8.234652348526993],
              [-35.257891508286434, -8.234656557354185],
              [-35.257917212709174, -8.234685302384776],
              [-35.257930887800171, -8.23469454291876],
              [-35.257944028693942, -8.234714434123816],
              [-35.257958939453431, -8.23472450922867],
              [-35.258126960690618, -8.234978818617549],
              [-35.258115097733537, -8.235020961747921],
              [-35.258138007063337, -8.235027902715501],
              [-35.258154915217119, -8.235033681758978],
              [-35.258312523204324, -8.23516403669089],
              [-35.258329522189626, -8.235169725802166],
              [-35.258340866237916, -8.235169842714015],
              [-35.258350684136701, -8.235197384451681],
              [-35.258357576540902, -8.235199691212756],
              [-35.258368919689872, -8.235199808124605],
              [-35.258391045710141, -8.235261869439682],
              [-35.258413465808758, -8.235295729814027],
              [-35.258460233253118, -8.235363853459035],
              [-35.258508910857529, -8.235412941154266],
              [-35.258514181783994, -8.235419929785905],
              [-35.258536967906707, -8.235442907464176],
              [-35.258643560052235, -8.235584215239271],
              [-35.258679919642532, -8.235668490708235],
              [-35.258738857612173, -8.235723844879487],
              [-35.258745553064784, -8.235733746415235],
              [-35.258766914661351, -8.235753810290078],
              [-35.25885100127266, -8.235878160448692],
              [-35.258867559590158, -8.235934559632199],
              [-35.258940303951817, -8.236058885509124],
              [-35.259068821568803, -8.23629045374139],
              [-35.259096781491223, -8.23634127622887],
              [-35.259146088621037, -8.236572479336417],
              [-35.259145679429537, -8.236628793983584],
              [-35.259149915236378, -8.236831506568706],
              [-35.259148170551612, -8.237068057944498],
              [-35.259153685194349, -8.237096228308246],
              [-35.259153133010614, -8.237175047590313],
              [-35.259169345988482, -8.237282154148033],
              [-35.259190285803015, -8.237518759483123],
              [-35.25918993776537, -8.237569469555353],
              [-35.259188252435877, -8.237800327323214],
              [-35.259176447035372, -8.237862217767088],
              [-35.259136100750425, -8.237963315954232],
              [-35.259113041233832, -8.238025180317777],
              [-35.259112924321983, -8.238036479399966],
              [-35.259032520434403, -8.238193662907179],
              [-35.2590093458046, -8.238266736420712],
              [-35.258980486560176, -8.238345442388209],
              [-35.258940141174548, -8.238446542373993],
              [-35.25891168572565, -8.23846893639228],
              [-35.25887722910079, -8.238536380149753],
              [-35.258848368957047, -8.238614996185106],
              [-35.258842016146104, -8.238710751499923],
              [-35.258835661536523, -8.238806417781916],
              [-35.258829513770991, -8.238873971256737],
              [-35.258800475561429, -8.238969582680056],
              [-35.258765670898924, -8.239087641181641],
              [-35.258753691929314, -8.239183248108361],
              [-35.258741684181359, -8.239273342190927],
              [-35.258735388027674, -8.239363402998606],
              [-35.258723349702791, -8.239464703533201],
              [-35.258716302615255, -8.239661696430119],
              [-35.258715692874887, -8.2397462111187],
              [-35.258691876129149, -8.23989810211566],
              [-35.258691120698643, -8.240005124137099],
              [-35.258689755527769, -8.240196573612934],
              [-35.258722561896661, -8.240365682130459],
              [-35.258761631144296, -8.240439129761967],
              [-35.258828485845925, -8.240597108269867],
              [-35.25883857893723, -8.240760561850379],
              [-35.258866131466732, -8.240867697186445],
              [-35.258870512963767, -8.241047902438709],
              [-35.258869552487795, -8.241183037267319],
              [-35.258862448742946, -8.241385724671431],
              [-35.25886169421176, -8.241492745793551],
              [-35.258860735534483, -8.241627879722841],
              [-35.258859804736176, -8.241751714569943],
              [-35.258859195895127, -8.241836229258524],
              [-35.258830248517086, -8.241931840681843],
              [-35.258806057653374, -8.242145651800342],
              [-35.258811015615834, -8.242235829519871],
              [-35.258827261868589, -8.242348539753266],
              [-35.258832595747663, -8.242393614673517],
              [-35.258837756956893, -8.242455588754353],
              [-35.258871172166835, -8.242540184381937],
              [-35.258915839694225, -8.242624896921427],
              [-35.2589823778344, -8.242822379948848],
              [-35.258998938849913, -8.242878777333658],
              [-35.259043841100322, -8.24294098164097],
              [-35.259082482270685, -8.242991686317225],
              [-35.259105666793062, -8.243007205917763],
              [-35.25916202460769, -8.243041327994831],
              [-35.259170190451869, -8.243059247885924],
              [-35.259190114032492, -8.243071309593233],
              [-35.259256996613033, -8.243218081649104],
              [-35.259323620188866, -8.243398659220702],
              [-35.259356631603225, -8.243539567696871],
              [-35.259406779599146, -8.243646845125738],
              [-35.259467978464386, -8.243799254132227],
              [-35.259484744525309, -8.243827542307201],
              [-35.259534026474114, -8.2440699509674],
              [-35.259532747638161, -8.244244496786052],
              [-35.259497767607854, -8.24437945984414],
              [-35.259445934282382, -8.244503040183133],
              [-35.259382964652048, -8.244598480735249],
              [-35.259268683303048, -8.244749890594903],
              [-35.259239850138954, -8.244817299278907],
              [-35.25920545107067, -8.244879047629922],
              [-35.259119017228841, -8.245092576361287],
              [-35.259078289631304, -8.245238778246971],
              [-35.259038060258206, -8.245328669982086],
              [-35.258917253428478, -8.245592647982278],
              [-35.258894281146127, -8.245637608688639],
              [-35.258882735649706, -8.245665692717523],
              [-35.25884795706753, -8.245772454834878],
              [-35.258789915721991, -8.24596928225651],
              [-35.258772136125117, -8.246081823417398],
              [-35.25871415323553, -8.246272957231156],
              [-35.258707946114782, -8.246346112582955],
              [-35.25865547696867, -8.246565417659838],
              [-35.258654054241163, -8.246762558944909],
              [-35.258670470465802, -8.246841466360536],
              [-35.258697704635324, -8.246988008189987],
              [-35.258741881132892, -8.247145935436549],
              [-35.258809087469388, -8.247253299200338],
              [-35.258823678969577, -8.247585659850301],
              [-35.258834061642574, -8.247720817161962],
              [-35.258912027468057, -8.247901512544729],
              [-35.259007106492732, -8.248059697896679],
              [-35.259023235833638, -8.248183618179382],
              [-35.259072806464815, -8.248381014871882],
              [-35.259116866050476, -8.248550241200633],
              [-35.259144335842393, -8.248674278395185],
              [-35.259217372483704, -8.248784927382474],
              [-35.259323927757009, -8.248887518444178],
              [-35.25932841447468, -8.248894764281886],
              [-35.259352041463501, -8.248917513532376],
              [-35.25943601565956, -8.24905316097454],
              [-35.25949117018132, -8.249112652926556],
              [-35.259503475604845, -8.249125035691804],
              [-35.259503466611648, -8.249125916128094],
              [-35.259508633216797, -8.249131489226784],
              [-35.259610851959224, -8.249148112295472],
              [-35.259701118711632, -8.249193700728654],
              [-35.25980264047945, -8.249256218899347],
              [-35.259982968039481, -8.249375416841985],
              [-35.260411467115489, -8.249625540786042],
              [-35.260428407644838, -8.249649008594815],
              [-35.260439586217899, -8.24965553497492],
              [-35.260484487568988, -8.249717737483536],
              [-35.260522786097681, -8.249765970822864],
              [-35.260579902940151, -8.249823533729057],
              [-35.260586064195479, -8.24983139830033],
              [-35.260608022941881, -8.249853526119296],
              [-35.260692197686751, -8.249960971721407],
              [-35.260708992525963, -8.249994862672736],
              [-35.260759318587645, -8.250085237343797],
              [-35.260767752429786, -8.250093721547955],
              [-35.260785733474791, -8.250151249380622],
              [-35.260803648869285, -8.250190287152009],
              [-35.260891938911811, -8.250334771332575],
              [-35.260899616424126, -8.250424661269051],
              [-35.260912152074127, -8.250475117732435],
              [-35.260930556699805, -8.250536655642122],
              [-35.260963551926352, -8.250738663158756],
              [-35.260965239054485, -8.250811869771951],
              [-35.2609632641433, -8.250975208239197],
              [-35.260949180760065, -8.251099479257505],
              [-35.260903713735331, -8.25133700100173],
              [-35.26084296543047, -8.251648182617487],
              [-35.260843578768117, -8.251676288230044],
              [-35.260844407943011, -8.251710090148492],
              [-35.260831795850663, -8.251901870574841],
              [-35.260799182835967, -8.251958906478308],
              [-35.260693731930132, -8.252051425133118],
              [-35.260655160906879, -8.252097288758819],
              [-35.260605001219801, -8.252132188749442],
              [-35.260560712306983, -8.252178264615168],
              [-35.260511258587655, -8.252241363747885],
              [-35.260461714936127, -8.252304371149762],
              [-35.260370025456268, -8.252503557493128],
              [-35.260365259049422, -8.252543083596379],
              [-35.260328247450616, -8.252656546562321],
              [-35.260297447469156, -8.252792482686857],
              [-35.260297816191212, -8.252809290116602],
              [-35.260260316260542, -8.252900249346965],
              [-35.260229053128228, -8.253019284512334],
              [-35.260224746274957, -8.253075709776112],
              [-35.260218953741685, -8.253317979940789],
              [-35.260216334016548, -8.253447609119803],
              [-35.260220197504054, -8.253616519786533],
              [-35.260221392703045, -8.25366712823535],
              [-35.260233592905934, -8.253706287415184],
              [-35.260298709218034, -8.253828755292773],
              [-35.260306480259828, -8.253918738758728],
              [-35.260308164690002, -8.253991850943123],
              [-35.260304472073699, -8.254076473550356],
              [-35.260297120115979, -8.254251233407672],
              [-35.260300734491238, -8.254408847690115],
              [-35.260287603490042, -8.254572431672329],
              [-35.260314516601625, -8.254757751369084],
              [-35.260327541482809, -8.254830619837207],
              [-35.260336049069338, -8.254954402523595],
              [-35.260329276275058, -8.255151662519154],
              [-35.260325123205803, -8.255219295134339],
              [-35.260327268988249, -8.255309397310782],
              [-35.260323945093944, -8.255410831844358],
              [-35.260326123251957, -8.255506539495173],
              [-35.260334995063943, -8.255647131409944],
              [-35.260337018538507, -8.255731630810089],
              [-35.26032465375971, -8.25593461858773],
              [-35.260270922864777, -8.256059744861318],
              [-35.260230467761858, -8.256269038685161],
              [-35.260157183806939, -8.256529765636117],
              [-35.260069524189134, -8.25665562575648],
              [-35.259981405017811, -8.256764586716258],
              [-35.259892428792512, -8.25683423381281],
              [-35.259814545704671, -8.25689225177598],
              [-35.259809779297825, -8.256931775181215],
              [-35.259783404880181, -8.257016888818328],
              [-35.259780448808613, -8.257135221613169],
              [-35.259857857054442, -8.257296847771272],
              [-35.259910805539221, -8.257385758346004],
              [-35.259947005949527, -8.257480733948682],
              [-35.260006285661518, -8.257597628727524],
              [-35.260036153045974, -8.257664529294573],
              [-35.260062196513104, -8.257697440884158],
              [-35.260076689987216, -8.257666755116588],
              [-35.26010340254993, -8.257699815993647],
              [-35.260104841465193, -8.257696769989877],
              [-35.260238983442264, -8.257862777645016],
              [-35.260290676473517, -8.257936228873803],
              [-35.260315628163653, -8.257959977271071],
              [-35.26033461824801, -8.257981269619847],
              [-35.260343780541007, -8.25798999124504],
              [-35.260407795183539, -8.258061758942972],
              [-35.260468556078933, -8.258132796391408],
              [-35.260536280424958, -8.258197173460758],
              [-35.260714745489054, -8.258322796160087],
              [-35.260933097284578, -8.258458681922605],
              [-35.260984820892702, -8.258491290440645],
              [-35.26105925687915, -8.258529102436057],
              [-35.261122196831877, -8.258555864461528],
              [-35.261196631919006, -8.258593588323379],
              [-35.261265903098888, -8.258648332754319],
              [-35.261380599035363, -8.258713396905705],
              [-35.261519876141335, -8.258856685887281],
              [-35.261526518533969, -8.258864561250448],
              [-35.261548034813927, -8.258886696263971],
              [-35.261571329053538, -8.258914314443984],
              [-35.261637565920864, -8.258998200506483],
              [-35.261676976910906, -8.259039011740924],
              [-35.261678851098054, -8.259041801437945],
              [-35.261705136482817, -8.259069022117615],
              [-35.261746132977635, -8.259130074393397],
              [-35.261807628619124, -8.25921476355046],
              [-35.261921538548108, -8.25933211068832],
              [-35.261996553697941, -8.259392334688471],
              [-35.262012575120195, -8.259412597313485],
              [-35.262024717766451, -8.259422346863744],
              [-35.262038372173095, -8.259439616545023],
              [-35.262128799004813, -8.2594795347527],
              [-35.262203570438373, -8.259528553200141],
              [-35.262342052543659, -8.25964373656916],
              [-35.262451091744424, -8.259703228521118],
              [-35.26246280001817, -8.259719882166792],
              [-35.262595165834171, -8.259812595974495],
              [-35.262709002918086, -8.259838255431077],
              [-35.262765983962936, -8.259853841581503],
              [-35.26281174506596, -8.259875365955338],
              [-35.262971311776596, -8.259916854379298],
              [-35.263039171020864, -8.25991529765281],
              [-35.263061852822204, -8.259914805723668],
              [-35.263135797778773, -8.259930117580836],
              [-35.263250001786105, -8.259972585366484],
              [-35.263599741834582, -8.260156262901148],
              [-35.263725901429154, -8.260226595281097],
              [-35.263972350743359, -8.260356261332333],
              [-35.264041375509009, -8.260399800210564],
              [-35.264127767082755, -8.260465471404359],
              [-35.264130767221104, -8.260476345107179],
              [-35.264155936547184, -8.260495478183714],
              [-35.264173902303696, -8.260560576509363],
              [-35.264233359182128, -8.260620793314899],
              [-35.264241109539512, -8.260630117485903],
              [-35.264261529545934, -8.260650799194991],
              [-35.264348871703021, -8.260755875982852],
              [-35.264430955524119, -8.260877885206185],
              [-35.264543280847704, -8.261083783189974],
              [-35.264567067915834, -8.261133992339808],
              [-35.264626070636609, -8.261233989756761],
              [-35.264726809094896, -8.26142892500485],
              [-35.264849525185411, -8.261595082846725],
              [-35.264885232767256, -8.261667553814505],
              [-35.26493449223301, -8.261840996165006],
              [-35.265045007221261, -8.261968086557886],
              [-35.265052780061694, -8.262058066426562],
              [-35.265090083940152, -8.262203649578737],
              [-35.2651319087106, -8.262298408444735],
              [-35.265175881961341, -8.262483361218074],
              [-35.265219149244274, -8.262640026715815],
              [-35.265260974914042, -8.26273487641339],
              [-35.265291090511369, -8.262813070666652],
              [-35.265317392083944, -8.262970194818593],
              [-35.265381000232935, -8.263272900324068],
              [-35.265342478672437, -8.26356660271648],
              [-35.265209633517713, -8.263946975472493],
              [-35.265011145947597, -8.264176685705422],
              [-35.264949643111493, -8.264211832110334],
              [-35.264888972148299, -8.264280779534317],
              [-35.264866291246278, -8.26428127056414],
              [-35.264833312207543, -8.264321499937239],
              [-35.264761788225883, -8.264413192115057],
              [-35.264722970788455, -8.264447850188105],
              [-35.264689775013039, -8.264482383255313],
              [-35.26463411687098, -8.264523100960275],
              [-35.2645168632626, -8.264598875137949],
              [-35.264494763322602, -8.264621958936232],
              [-35.26442357928471, -8.264724950196239],
              [-35.264341300310718, -8.264839393423244],
              [-35.264275771209839, -8.264947867849799],
              [-35.264260614036061, -8.265027039666109],
              [-35.26424296394157, -8.265241444337164],
              [-35.26418114904078, -8.265507621179722],
              [-35.264177087702365, -8.265575253794907],
              [-35.264157291825541, -8.265699647120982],
              [-35.264154458961059, -8.265823584490761],
              [-35.26412703323598, -8.266105834916289],
              [-35.264112247482217, -8.266201907691823],
              [-35.264107725690963, -8.266252730179303],
              [-35.26410867897232, -8.26629213217609],
              [-35.264109750964167, -8.266337140546568],
              [-35.264111683607268, -8.266421550913776],
              [-35.264116870896828, -8.266646764536517],
              [-35.264097073221308, -8.266771068829712],
              [-35.264041534689056, -8.26681738661307],
              [-35.263997702631855, -8.266880364337283],
              [-35.263959129809962, -8.266926225265024],
              [-35.263613635460842, -8.267170507213109],
              [-35.263399479002715, -8.267214744864532],
              [-35.263365545783245, -8.267215567744188],
              [-35.263303309100365, -8.267216914928611],
              [-35.263173118744419, -8.267219819738841],
              [-35.263076858909869, -8.267221991601559],
              [-35.262974730099643, -8.267212988488609],
              [-35.262900660137291, -8.267192165585925],
              [-35.262684937959477, -8.267168802997787],
              [-35.262491313922965, -8.267122441147706],
              [-35.262389183314099, -8.267113440732658],
              [-35.262270086994874, -8.267104893575947],
              [-35.262116970221939, -8.267097082963971],
              [-35.262015912504296, -8.267133086422746],
              [-35.261865802165005, -8.267254780883377],
              [-35.261704368461778, -8.267618775186293],
              [-35.261655895902834, -8.267726881790111],
              [-35.261639913151441, -8.267772345217509],
              [-35.261593339960655, -8.267959257613597],
              [-35.261588695861576, -8.268004387392523],
              [-35.261569726461687, -8.268162490906207],
              [-35.261539538019235, -8.268326530844661],
              [-35.261531080794725, -8.268450679555144],
              [-35.261528001516012, -8.26856331874211],
              [-35.261506392605895, -8.268608904477333],
              [-35.261378013484489, -8.268690616878416],
              [-35.261264787939581, -8.26869315656387],
              [-35.261247822229166, -8.268693523487286],
              [-35.261179377526219, -8.268672577377515],
              [-35.261128111672974, -8.268656778987122],
              [-35.261110565000536, -8.268634642174959],
              [-35.261102733704149, -8.268627613973138],
              [-35.261099911631561, -8.268626743429422],
              [-35.261095342176247, -8.268620976976479],
              [-35.261006499949985, -8.268541228694687],
              [-35.260891341761976, -8.268459356214294],
              [-35.260775447928552, -8.268343684513411],
              [-35.260672366736287, -8.26829527850424],
              [-35.260397482657652, -8.268160619417017],
              [-35.260368594634883, -8.268138728119766],
              [-35.26036429767413, -8.268126802210134],
              [-35.260340398190806, -8.268108691662746],
              [-35.260304167203515, -8.26800811418309],
              [-35.260298328804765, -8.268002633714502],
              [-35.260224913548882, -8.267920578671749],
              [-35.260089971175205, -8.267840852873007],
              [-35.259867302633893, -8.267761304240764],
              [-35.259791822534567, -8.267684178382069],
              [-35.259763024443998, -8.267662286185498],
              [-35.259751634530289, -8.267645021900194],
              [-35.259734830697823, -8.267632247929839],
              [-35.259705544275391, -8.267587852897066],
              [-35.259646049625474, -8.267465264510292],
              [-35.259662400199545, -8.26743669844484],
              [-35.259586431768355, -8.267337070649262],
              [-35.259540024952173, -8.26728192242274],
              [-35.259533364573088, -8.26727880896982],
              [-35.25938295655817, -8.267141366480814],
              [-35.259319646984068, -8.267097705294816],
              [-35.259302435758741, -8.267086864866883],
              [-35.259284889086302, -8.267064729853359],
              [-35.25928216324121, -8.267061812452653],
              [-35.259274245609902, -8.267056826611224],
              [-35.259256698937463, -8.267034690698381],
              [-35.259163854728058, -8.266935339893962],
              [-35.259128851315381, -8.266891066269636],
              [-35.259047379033291, -8.266797161759541],
              [-35.258964343729417, -8.266635658808582],
              [-35.258922152934986, -8.266523998983303],
              [-35.258836114794804, -8.266233075496018],
              [-35.258821980150231, -8.266109417815358],
              [-35.258801185126572, -8.265946569478558],
              [-35.258787421901957, -8.265839899991363],
              [-35.258785857980911, -8.265772298852482],
              [-35.258749167440101, -8.265654823111561],
              [-35.258713550689777, -8.265582442975301],
              [-35.258710912978188, -8.265469838861918],
              [-35.25870910354223, -8.265391030371688],
              [-35.258719954762057, -8.265368281121255],
              [-35.258736427643953, -8.265345412260899],
              [-35.258791724258629, -8.265287795395409],
              [-35.258847876128527, -8.265269672257546],
              [-35.258926589290638, -8.265245365381247],
              [-35.258988210837231, -8.265215822652067],
              [-35.259027488727554, -8.265198067336883],
              [-35.259200806072272, -8.265104083686481],
              [-35.259285358532452, -8.26508525637945],
              [-35.259493676591831, -8.265035542756038],
              [-35.25963997380569, -8.264992866327646],
              [-35.259768263894216, -8.264911157523841],
              [-35.259912168911399, -8.264767082534775],
              [-35.259942603768081, -8.264614341678453],
              [-35.259977070285515, -8.264388271001849],
              [-35.259985283793753, -8.264252915839336],
              [-35.259964820619928, -8.264101362987446],
              [-35.259929816307931, -8.26405699943092],
              [-35.259948300073972, -8.263876395778993],
              [-35.259936957824323, -8.263876638595946],
              [-35.259934902873454, -8.263786624553006],
              [-35.259931896439809, -8.263657118581136],
              [-35.259918503735889, -8.263567259221588],
              [-35.259762088249715, -8.263170893821666],
              [-35.259728601993345, -8.262946382569453],
              [-35.259708999470718, -8.26283414268147],
              [-35.259698194116311, -8.262609140399491],
              [-35.259633469908692, -8.262013498723945],
              [-35.259621638427802, -8.261991241402598],
              [-35.259610298876169, -8.26199157325243],
              [-35.2596238921289, -8.261844802995199],
              [-35.259631859222907, -8.261698148750497],
              [-35.259567847278277, -8.261626380153302],
              [-35.259521225524111, -8.261565454681943],
              [-35.259404507911654, -8.261415979263973],
              [-35.259357550710376, -8.261343844642681],
              [-35.259357213464568, -8.261332547359132],
              [-35.25926288627312, -8.261185605331377],
              [-35.259256583824254, -8.261179633832967],
              [-35.259257021794099, -8.261176718230899],
              [-35.259228418856424, -8.261149608167841],
              [-35.25925298293879, -8.260985690537154],
              [-35.259295126968482, -8.260849602427186],
              [-35.259426204955332, -8.260396118386097],
              [-35.25944670769934, -8.260299922403476],
              [-35.259455901468641, -8.260209572913425],
              [-35.259486339922603, -8.260056830258463],
              [-35.259511270928385, -8.259909810889042],
              [-35.259524497257701, -8.259746227806147],
              [-35.259549430961442, -8.259599208436669],
              [-35.259560772311772, -8.259598963821077],
              [-35.259522526843114, -8.259488386779594],
              [-35.2594945561288, -8.259467723956277],
              [-35.259363631926021, -8.259436828646699],
              [-35.259346668014246, -8.259437286401578],
              [-35.259280157753039, -8.259500658928232],
              [-35.259248401792263, -8.259597191257342],
              [-35.259200052440406, -8.259710899738195],
              [-35.259168790207468, -8.259829844971307],
              [-35.259105160474746, -8.260017123391492],
              [-35.259041038812825, -8.260181899874738],
              [-35.259030526637446, -8.260215942811442],
              [-35.258999968573676, -8.260363084488688],
              [-35.258956751652704, -8.260454165127555],
              [-35.258923312161073, -8.26047740270991],
              [-35.258896202098015, -8.260528716227213],
              [-35.258759239846995, -8.260728881932323],
              [-35.258711378827002, -8.260865183181636],
              [-35.258642125633571, -8.261052584808908],
              [-35.258636990504669, -8.261075210852255],
              [-35.258621130960364, -8.261126276156688],
              [-35.258631981280871, -8.261103528704837],
              [-35.258601666933316, -8.261261877733432],
              [-35.258602649892339, -8.261306884305213],
              [-35.258604795674728, -8.261396986481714],
              [-35.258617361901713, -8.261453046620773],
              [-35.258641975446778, -8.261536967756797],
              [-35.258671657570915, -8.261629561954692],
              [-35.258742849702685, -8.261701175868552],
              [-35.258751304229236, -8.26171137418055],
              [-35.258771016469211, -8.261731203332317],
              [-35.258835154318888, -8.261808574705924],
              [-35.25884664945329, -8.261819625575185],
              [-35.258941024308797, -8.261980885709249],
              [-35.258970188423405, -8.262019587134205],
              [-35.259017238254899, -8.262091811687696],
              [-35.259026844812979, -8.262103245668186],
              [-35.259035445029724, -8.26211141690834],
              [-35.259037966728727, -8.262116485487354],
              [-35.25904044076367, -8.262119429867766],
              [-35.259063613594833, -8.262141444372105],
              [-35.259105191051674, -8.26222499858477],
              [-35.259128731705573, -8.262263912249693],
              [-35.259164809808055, -8.262353189747841],
              [-35.259206140850665, -8.262425449374916],
              [-35.259235429971056, -8.262469932541308],
              [-35.259248335242432, -8.262537198233076],
              [-35.259279645139486, -8.262666003633115],
              [-35.259316949017943, -8.262811676717433],
              [-35.259329393836424, -8.262862043248674],
              [-35.25936128829278, -8.263013441417115],
              [-35.259368967603734, -8.263103332252911],
              [-35.259356936473409, -8.263317615515518],
              [-35.259359116430062, -8.263413324964915],
              [-35.259365445858577, -8.263441398201905],
              [-35.259367713948791, -8.2635371049534],
              [-35.259369645692573, -8.263621515320608],
              [-35.25937145602785, -8.263700322911518],
              [-35.259368624062688, -8.263824257583394],
              [-35.259363488034523, -8.263846883626741],
              [-35.259363611241611, -8.263852576335296],
              [-35.259359916826668, -8.263937108111008],
              [-35.259350968572278, -8.264038664952352],
              [-35.259305286609674, -8.264270674751799],
              [-35.25929621514814, -8.264366625219509],
              [-35.259296828485788, -8.264394732630763],
              [-35.259276662088268, -8.264502225896933],
              [-35.259168218238642, -8.26470738283939],
              [-35.25907435060077, -8.264810861531998],
              [-35.259034828094798, -8.264817413992432],
              [-35.258949906912619, -8.264819338541599],
              [-35.258824606170606, -8.264788319125557],
              [-35.25880405306458, -8.264760172144179],
              [-35.258796425014964, -8.264758284467234],
              [-35.258747961449217, -8.264691911802231],
              [-35.258685051174155, -8.264628129184757],
              [-35.258683578983948, -8.264625172213869],
              [-35.258656871817152, -8.264598094526377],
              [-35.258615290763032, -8.264514540313712],
              [-35.258607521519878, -8.264424557747077],
              [-35.258606204912383, -8.264368255690385],
              [-35.258609284191095, -8.264255525671899],
              [-35.258612609883983, -8.264154093836339],
              [-35.258638860195219, -8.264063380120831],
              [-35.258670983978675, -8.263983839582465],
              [-35.258669664673221, -8.263927534827815],
              [-35.258649940742146, -8.263809691264214],
              [-35.258637619130752, -8.263764928408648],
              [-35.258629171798816, -8.263741460599874],
              [-35.258572859849551, -8.263695699496907],
              [-35.258326164121115, -8.263554734363481],
              [-35.25824625755871, -8.263528338362107],
              [-35.25808751372773, -8.26352064646062],
              [-35.257997215499074, -8.26353390156828],
              [-35.25785577912086, -8.263537141825566],
              [-35.25773622324806, -8.263511604676751],
              [-35.257667533030201, -8.263479359484791],
              [-35.257461219559616, -8.263371337417254],
              [-35.25730641655781, -8.263290319292764],
              [-35.257279211166633, -8.26326078825474],
              [-35.257278246194062, -8.263260282835745],
              [-35.257196900716337, -8.263171982900644],
              [-35.257161283966013, -8.263099603663704],
              [-35.257148134978422, -8.263021039789123],
              [-35.257130432723272, -8.262987606592731],
              [-35.257066297571555, -8.262910144387604],
              [-35.257042631911872, -8.262865626147686],
              [-35.25699974863943, -8.262808742229652],
              [-35.256931738309049, -8.262757976399428],
              [-35.256764121766764, -8.262609692583226],
              [-35.256649950135056, -8.262572825775237],
              [-35.25657050222685, -8.262563332531727],
              [-35.256502793169318, -8.262576095710187],
              [-35.256458718295164, -8.262627775251588],
              [-35.256415255859338, -8.262707559506225],
              [-35.256388756435911, -8.262787069467663],
              [-35.256250107956077, -8.262914030358161],
              [-35.256040471490508, -8.262907530957762],
              [-35.255948978062861, -8.26287008138911],
              [-35.255817684238707, -8.262822375951828],
              [-35.255664076435949, -8.262791966276154],
              [-35.255475218806282, -8.262706079222141],
              [-35.255373704233023, -8.262725270754572],
              [-35.255272892030291, -8.262772568798937],
              [-35.25525605042634, -8.262778628430908],
              [-35.255207820684348, -8.262897941486756],
              [-35.255145459895004, -8.262893680498905],
              [-35.255032113840912, -8.262890614710045],
              [-35.254981095301275, -8.262886112703882],
              [-35.25492423746357, -8.262876127531229],
              [-35.254839688600725, -8.26289495124098],
              [-35.254794662243796, -8.262907227886217],
              [-35.254487782085448, -8.262857706717739],
              [-35.254194162430622, -8.262650295174922],
              [-35.25419342768447, -8.262639625618192],
              [-35.254166000160751, -8.262620249724705],
              [-35.254148988584916, -8.262372774284074],
              [-35.254136577041379, -8.262328013227204],
              [-35.254090200802068, -8.262278383240755],
              [-35.253991184545498, -8.262162339220538],
              [-35.253909686183022, -8.262062740203248],
              [-35.253879816100607, -8.261995842334215],
              [-35.253877548909713, -8.261900134683401],
              [-35.253852079210048, -8.26177681065127],
              [-35.25383893112172, -8.261698248575271],
              [-35.25392499893951, -8.261499182741034],
              [-35.254001690425639, -8.261390467296167],
              [-35.254083359659262, -8.261247830323043],
              [-35.254142807544497, -8.261122581741688],
              [-35.254165028892942, -8.26110519334992],
              [-35.254181751786348, -8.26109353094165],
              [-35.254203849927706, -8.261070537974888],
              [-35.25446960768619, -8.261053313259765],
              [-35.254520379811652, -8.261046522478978],
              [-35.254664991695961, -8.260930644833365],
              [-35.254725664457794, -8.260861700107341],
              [-35.25479181229224, -8.260781424822824],
              [-35.254925233912388, -8.260677001842112],
              [-35.255002262644268, -8.260579583680794],
              [-35.255085125278242, -8.260487553357848],
              [-35.25521649644412, -8.260293114535557],
              [-35.255227224456803, -8.260264765206728],
              [-35.25525203855068, -8.260112141262255],
              [-35.255239836549151, -8.260010052921587],
              [-35.255225955513367, -8.260002566964829],
              [-35.255208570718935, -8.259978338329631],
              [-35.25519780313607, -8.25997253050781],
              [-35.255098386681141, -8.25983398275207],
              [-35.254938252498334, -8.259658070863225],
              [-35.25489454454754, -8.259626976803474],
              [-35.254878258724602, -8.25960538228253],
              [-35.254866393069562, -8.259596939447135],
              [-35.254845828272323, -8.259569670204087],
              [-35.254795866436041, -8.259522142832566],
              [-35.254732433654851, -8.259472875272934],
              [-35.254727004447659, -8.259460486212447],
              [-35.254704283076137, -8.259442839715234],
              [-35.254650509013743, -8.259320123624775],
              [-35.254573971311686, -8.259216495644694],
              [-35.254486677717978, -8.259129162480804],
              [-35.254485307151185, -8.259125916827543],
              [-35.254458528937903, -8.259099125124465],
              [-35.254392585249548, -8.258942948857964],
              [-35.254312382810156, -8.258831101973726],
              [-35.254189935616978, -8.258786639491746],
              [-35.254098198473059, -8.258737985269761],
              [-35.254006796776196, -8.258700628331269],
              [-35.25382952781041, -8.258625699516358],
              [-35.253715815732278, -8.258605731869011],
              [-35.253664460846153, -8.258590023410818],
              [-35.253550871075788, -8.25857565584181],
              [-35.253511101256265, -8.258570910119374],
              [-35.253403347186691, -8.258562025716856],
              [-35.253329770052801, -8.258563704751111],
              [-35.253139031041428, -8.258641154365762],
              [-35.252870893676743, -8.258799213812608],
              [-35.252674562680852, -8.258882480242221],
              [-35.252646839280146, -8.258905594617431],
              [-35.252601690615506, -8.25891226668773],
              [-35.252511146871882, -8.258914312645345],
              [-35.252438795513967, -8.258972204703412],
              [-35.25230647466401, -8.259127240629596],
              [-35.252267409912974, -8.259150688653335],
              [-35.252087550899716, -8.259210995391072],
              [-35.252009666013237, -8.259269100588483],
              [-35.251950095820234, -8.259388655561963],
              [-35.251874264085984, -8.259536865633777],
              [-35.251862107050556, -8.259745456187829],
              [-35.251821065589638, -8.259932246276094],
              [-35.251684559294915, -8.260149399275292],
              [-35.25158530921459, -8.260264207627017],
              [-35.251390784057378, -8.26042628254686],
              [-35.251216392922174, -8.26047525872616],
              [-35.251096930578854, -8.260449717980066],
              [-35.250880259616224, -8.260387032535505],
              [-35.250738239578027, -8.260367674628412],
              [-35.250619145057442, -8.260359032143583],
              [-35.250550946768726, -8.260349292485785],
              [-35.250448942064963, -8.260345981182013],
              [-35.250318262477776, -8.260326376860746],
              [-35.250204180778269, -8.260289509153495],
              [-35.250196866592091, -8.260266186135539],
              [-35.25017603379689, -8.260259454710024],
              [-35.250129367975887, -8.260110666374089],
              [-35.250115256713684, -8.259893727413555],
              [-35.250164661869633, -8.259548624269542],
              [-35.250252444694524, -8.259203305288281],
              [-35.250288802486182, -8.258845529797327],
              [-35.250312602144845, -8.258513405669078],
              [-35.250273118309735, -8.258334841679527],
              [-35.250157667842075, -8.25815317862623],
              [-35.250017597534054, -8.258072816107529],
              [-35.249885090524458, -8.258050962581819],
              [-35.249382589834624, -8.25843422485957],
              [-35.249030598783065, -8.259038633126352],
              [-35.248669181937146, -8.259468376864049],
              [-35.248207652561064, -8.260246130354574],
              [-35.2481105176862, -8.26084747912779],
              [-35.248116789558139, -8.261827062968507],
              [-35.248141881542551, -8.262637875537507],
              [-35.248249263292848, -8.263173720390853],
              [-35.248502473710175, -8.263840529916479],
              [-35.248867988766619, -8.264350919459332],
              [-35.249273920255177, -8.264730541281381],
              [-35.249582371529186, -8.265009461517366],
              [-35.249880213501001, -8.265209530995037],
              [-35.250004301956949, -8.265314403636751],
              [-35.250014979607556, -8.26532969031291],
              [-35.250032468723418, -8.265344471570074],
              [-35.250147366108024, -8.265508962968227],
              [-35.250210689171922, -8.265715050708991],
              [-35.250273952880605, -8.265910746783504],
              [-35.250327342033188, -8.26618919757334],
              [-35.250349199156176, -8.266395429205659],
              [-35.250319161799837, -8.266571132451759],
              [-35.250175481613155, -8.266819600743929],
              [-35.250020754154434, -8.266944389771709],
              [-35.249837618910931, -8.267051444168771],
              [-35.249343036751725, -8.267232199806131],
              [-35.249111678960787, -8.267156671143425],
              [-35.248722594472099, -8.267012067352994],
              [-35.248260120807913, -8.266838501795348],
              [-35.247479414843099, -8.266774284805365],
              [-35.247134785641776, -8.266708791677331],
              [-35.24670578024751, -8.266580591521063],
              [-35.246379624919712, -8.266380316997981],
              [-35.245958130663155, -8.266088923165228],
              [-35.245935494727235, -8.266062661162835],
              [-35.245929966594645, -8.266058838144829],
              [-35.245716999040212, -8.265811747614009],
              [-35.245262691220205, -8.265473943367738],
              [-35.244605517930268, -8.264983121273985],
              [-35.244162024457808, -8.26463526620455],
              [-35.244139698788047, -8.264609751538728],
              [-35.244133871181191, -8.264605180284775],
              [-35.243820711956232, -8.264247283385373],
              [-35.243645021300665, -8.263744539878587],
              [-35.24356660761282, -8.263147068981993],
              [-35.243460387786627, -8.262509889419789],
              [-35.243244616146058, -8.262063638625989],
              [-35.242686455515468, -8.261839444834493],
              [-35.242119085827085, -8.261422682810519],
              [-35.242106507908943, -8.261404027274011],
              [-35.242090947838904, -8.26139259779012],
              [-35.241494815133592, -8.260508418528673],
              [-35.24134726156683, -8.260022778328505],
              [-35.241350481139762, -8.259724480400394],
              [-35.241571995850904, -8.259664851751438],
              [-35.242195283585431, -8.259620510677962],
              [-35.242614149123654, -8.259641665430479],
              [-35.242952968704515, -8.259723919223461],
              [-35.243649552085117, -8.259708448186302],
              [-35.244018543021298, -8.259622142947478],
              [-35.244368470128734, -8.259727020985167],
              [-35.244593629792121, -8.259847509454971],
              [-35.245060154002886, -8.260161884563274],
              [-35.245765042622565, -8.260428010144494],
              [-35.246267278911773, -8.260590691207369],
              [-35.246737371632378, -8.26057296377121],
              [-35.24728341929432, -8.260347728564795],
              [-35.247495741135481, -8.260090924456563],
              [-35.247531982015289, -8.259888704699904],
              [-35.247491181572741, -8.259477310729267],
              [-35.247387720866584, -8.259363194855496],
              [-35.2470986068152, -8.25914285196103],
              [-35.246907825535686, -8.258988905113824],
              [-35.246908058460065, -8.258981734819145],
              [-35.246879690245464, -8.258958843475796],
              [-35.246888118691686, -8.258700017691638],
              [-35.246951872530872, -8.258565527676922],
              [-35.247117350485269, -8.258448993526201],
              [-35.247424560694867, -8.258317023411848],
              [-35.24759599126196, -8.258178039484903],
              [-35.247809698958406, -8.257797397831609],
              [-35.248099413756904, -8.257186833705362],
              [-35.248408162706482, -8.25639620282243],
              [-35.248687539798084, -8.255706701602946],
              [-35.248944585823949, -8.254983155648063],
              [-35.24908276215973, -8.254258380319925],
              [-35.249290260037412, -8.254047609809277],
              [-35.249571410592068, -8.253784541923039],
              [-35.249712205753667, -8.253651878431413],
              [-35.249755790497318, -8.25357769695313],
              [-35.249800572238598, -8.253554126621623],
              [-35.249882732502044, -8.253433992484702],
              [-35.249920451867297, -8.253348726862214],
              [-35.249969295846256, -8.253257521217563],
              [-35.250023027640566, -8.253132396742672],
              [-35.250049404756169, -8.253047284004879],
              [-35.250076147895868, -8.252979163957207],
              [-35.250117345838817, -8.252803582119554],
              [-35.250146224868331, -8.25258332825797],
              [-35.250165414602179, -8.252430826621321],
              [-35.250218077102545, -8.252260694675272],
              [-35.25022724569078, -8.252164741509546],
              [-35.250224242854472, -8.252035235537619],
              [-35.250226131430793, -8.251871894372357],
              [-35.250225306752441, -8.251838096950564],
              [-35.25022264296058, -8.251719883765588],
              [-35.250208546986812, -8.251601915196204],
              [-35.250219846968321, -8.251353833612484],
              [-35.250272967223623, -8.251200599927699],
              [-35.250286687280777, -8.251059427950167],
              [-35.25028392995938, -8.250941217463151],
              [-35.250246633275481, -8.250795632512393],
              [-35.250211631661443, -8.250751358888067],
              [-35.250175437546375, -8.250656383285389],
              [-35.250140192216065, -8.25060090320909],
              [-35.250122400928035, -8.250567470912017],
              [-35.250116531053038, -8.250556385868492],
              [-35.250086051230255, -8.250461287958046],
              [-35.250083908145825, -8.250371185781546],
              [-35.250082804677675, -8.250320573735451],
              [-35.250079804539325, -8.250191066864204],
              [-35.250078121907734, -8.250117862948969],
              [-35.250076559785384, -8.250050351742289],
              [-35.250074510230434, -8.249960248666468],
              [-35.250145620523938, -8.249747619257164],
              [-35.250180748043022, -8.249428990355568],
              [-35.250202429798264, -8.248914385690284],
              [-35.250175211816554, -8.248449133120971],
              [-35.250136208219374, -8.24808183920544],
              [-35.250035573183141, -8.247678192894227],
              [-35.249911179857008, -8.247421651388038],
              [-35.249724303433823, -8.246993992278874],
              [-35.249674367677869, -8.246859595793637],
              [-35.249663110863821, -8.246649690430843],
              [-35.249726198305382, -8.246526139769514],
              [-35.249851797622341, -8.246386056870961],
              [-35.249869026834062, -8.246352427622412],
              [-35.249914711494682, -8.246296311725359],
              [-35.249943285654069, -8.246262707657763],
              [-35.25007433845991, -8.246156400597386],
              [-35.25014281913576, -8.246072317583355],
              [-35.250262003588546, -8.246033598171948],
              [-35.250352880980643, -8.245994675513828],
              [-35.250449616556523, -8.245933209549946],
              [-35.250551836198213, -8.245894402904298],
              [-35.250704782999264, -8.245889567249662],
            ],
            [
              [-35.552708124780111, -8.264366881526314],
              [-35.552703627270546, -8.264377997146823],
              [-35.552170801441264, -8.263506120610941],
              [-35.552014442612403, -8.263172757216921],
              [-35.55167242684098, -8.262180891334992],
              [-35.551534040063871, -8.261820303664024],
              [-35.55237823906441, -8.261905283302156],
              [-35.553391653601864, -8.261501194524442],
              [-35.55345245406744, -8.261252167753298],
              [-35.55288069748616, -8.260783680322561],
              [-35.552506802546873, -8.260478815544843],
              [-35.552508227072963, -8.260474575241403],
              [-35.552477749948139, -8.260449725174624],
              [-35.553198758915528, -8.258303403179468],
              [-35.553438646776101, -8.258411845230398],
              [-35.553443672187655, -8.258430071790315],
              [-35.553467701173417, -8.258440934701355],
              [-35.553492151941214, -8.258529613251028],
              [-35.553650076489816, -8.25910696091745],
              [-35.55358081879973, -8.25962250087764],
              [-35.553703158973576, -8.260308517224246],
              [-35.553822711249097, -8.260560784252732],
              [-35.554215678111007, -8.261010113425925],
              [-35.554444318150729, -8.261297832628827],
              [-35.554527527023708, -8.261541294395158],
              [-35.554384944009939, -8.261939822664658],
              [-35.554308824492637, -8.262798804721001],
              [-35.553942856177855, -8.263190129420877],
              [-35.553866872458173, -8.263131702266207],
              [-35.553865811258163, -8.263124151558316],
              [-35.55383779377911, -8.263102608298709],
              [-35.553809366209236, -8.262900509950555],
              [-35.553623301873813, -8.262941816711418],
              [-35.553621719067053, -8.262991571703651],
              [-35.553751139603378, -8.262942671966698],
              [-35.553754601093942, -8.262981442639443],
              [-35.553780216483744, -8.262971765934196],
              [-35.553799554605689, -8.263188403621882],
              [-35.553852279159287, -8.263226792982039],
              [-35.553846981253116, -8.263230858817053],
              [-35.55388135693903, -8.263255887848914],
              [-35.553172235210468, -8.263800110786462],
              [-35.552919168684639, -8.263946316269426],
              [-35.552732699654314, -8.264407092912961],
              [-35.552708124780111, -8.264366881526314],
            ],
            [
              [-35.18356925012074, -8.219029979749735],
              [-35.183421886310953, -8.219002100766318],
              [-35.18324598611332, -8.218872592995751],
              [-35.183236848102013, -8.218856270300591],
              [-35.183218088244132, -8.218842458512597],
              [-35.183104366273426, -8.218639335836656],
              [-35.182996122073348, -8.21835144935983],
              [-35.182859589698239, -8.218080591546027],
              [-35.182762952148494, -8.217922634621857],
              [-35.182670306689261, -8.217803064359885],
              [-35.182512783238337, -8.21773310519859],
              [-35.18233123529825, -8.217609258660332],
              [-35.182317470274995, -8.217588763110882],
              [-35.18230334282498, -8.217579126875137],
              [-35.182189686504785, -8.21740990864032],
              [-35.181932890490486, -8.217097743166278],
              [-35.181632942306408, -8.216910398196262],
              [-35.181321101487583, -8.21679253754553],
              [-35.181139568835988, -8.216674340548309],
              [-35.18090680990133, -8.216443270540481],
              [-35.180899172858517, -8.21643324220031],
              [-35.180878921924659, -8.216413139654605],
              [-35.180702815782297, -8.216181922157944],
              [-35.18052077411437, -8.215820767914067],
              [-35.180480670646375, -8.215606161794881],
              [-35.180468902118093, -8.215391480132666],
              [-35.180474194628346, -8.215210661542756],
              [-35.180524737426651, -8.21499582519715],
              [-35.180586574810548, -8.214764009651276],
              [-35.180688266550192, -8.214622503125952],
              [-35.180756128492476, -8.214565830548622],
              [-35.180891936014007, -8.214486381741096],
              [-35.181055908503311, -8.2143277629159],
              [-35.181134753865763, -8.214101557340939],
              [-35.181128714018882, -8.213920761234078],
              [-35.18107029585741, -8.213815375978754],
              [-35.180946446621192, -8.21369715559922],
              [-35.180827302637852, -8.213612705661774],
              [-35.180818162827904, -8.213595865856462],
              [-35.180799427251713, -8.213582584668529],
              [-35.18075961426473, -8.213509229667181],
              [-35.180759402024762, -8.213407525337004],
              [-35.180804336650795, -8.21321530873945],
              [-35.180917398519057, -8.213096368002937],
              [-35.180979631604657, -8.213056664733244],
              [-35.181018974246228, -8.212904007513885],
              [-35.181018793482508, -8.21281733894898],
              [-35.180865082257696, -8.212702920903041],
              [-35.180695015962158, -8.212652499513183],
              [-35.180508047808132, -8.212647320317558],
              [-35.180326756174793, -8.212647777173117],
              [-35.180162478815305, -8.212653847596926],
              [-35.180009603060682, -8.212699425238213],
              [-35.179862373249875, -8.212733705596008],
              [-35.179664074537357, -8.212728551581336],
              [-35.179533635968482, -8.212661085340756],
              [-35.179465591463838, -8.21263299771465],
              [-35.179408939570862, -8.212633143404787],
              [-35.17934664353271, -8.212644596271048],
              [-35.17925053478433, -8.212740897474418],
              [-35.179103527106065, -8.212882520911592],
              [-35.178906473055235, -8.213481942437625],
              [-35.178810665579704, -8.213725143400609],
              [-35.178749665464636, -8.214363774468552],
              [-35.178722128223569, -8.214748058376131],
              [-35.178632062020029, -8.215030795334883],
              [-35.178519233076088, -8.215262735886483],
              [-35.178434464778661, -8.21536465875198],
              [-35.178264688964191, -8.2154554902786],
              [-35.178122918038184, -8.215388037527873],
              [-35.17809730894362, -8.2153589831305],
              [-35.178095038155448, -8.215357902145399],
              [-35.178015529992649, -8.215267698345542],
              [-35.177962451106168, -8.215207479741366],
              [-35.177912906555378, -8.21520210899007],
              [-35.177788352250616, -8.215241978634367],
              [-35.177720460630724, -8.215287351230245],
              [-35.177550592186037, -8.215332976535592],
              [-35.17743155162475, -8.21529938236057],
              [-35.177266935220814, -8.215141588213669],
              [-35.177254846533913, -8.215126587521922],
              [-35.177239057136717, -8.215111452831252],
              [-35.177011856911633, -8.214829513571146],
              [-35.176903945460651, -8.214699825936179],
              [-35.176813086055006, -8.214592697794728],
              [-35.176779152835593, -8.214552273268737],
              [-35.176716095971017, -8.214515797665911],
              [-35.176591424754406, -8.214499160208049],
              [-35.176438691092699, -8.214612543134308],
              [-35.176393894962246, -8.214866920871657],
              [-35.176389008046215, -8.215245498580828],
              [-35.176395266428358, -8.215533642263722],
              [-35.176389857006257, -8.21565795734881],
              [-35.176288335238439, -8.215878575435852],
              [-35.176192330811375, -8.216031376546766],
              [-35.175983099940083, -8.216218355492686],
              [-35.175666088019511, -8.216343460182486],
              [-35.17541683731713, -8.216355383394159],
              [-35.17525254197119, -8.216355792585716],
              [-35.174861549121147, -8.216317230555717],
              [-35.174629042895958, -8.216210458545788],
              [-35.174447381641301, -8.216030106704011],
              [-35.174445822216853, -8.216026087633793],
              [-35.174419504456523, -8.215999959630381],
              [-35.174362551290642, -8.215853192970428],
              [-35.174350790856295, -8.215644160849308],
              [-35.174373116526056, -8.215480252211819],
              [-35.174457854246555, -8.215361382521735],
              [-35.174621965231495, -8.215270574377485],
              [-35.17466725598905, -8.215253508842295],
              [-35.17491083376791, -8.21523594957938],
              [-35.175165691743246, -8.215195757977824],
              [-35.175324101026376, -8.215088006606152],
              [-35.175476692595225, -8.214906815696963],
              [-35.175485781143834, -8.214880846873541],
              [-35.175549917194871, -8.214697570436499],
              [-35.175622973621273, -8.214409225305474],
              [-35.175638148781502, -8.214313929544176],
              [-35.175701282987802, -8.2139174607222],
              [-35.175683624799376, -8.213595448571027],
              [-35.175682972790923, -8.213279038296946],
              [-35.175681906194995, -8.212759221161093],
              [-35.175743626666986, -8.212465252569302],
              [-35.175873382650423, -8.212199370704354],
              [-35.176104908614604, -8.21183151920917],
              [-35.176285736197713, -8.211605062723436],
              [-35.17677250684568, -8.211383477865184],
              [-35.176874357765371, -8.211326721650835],
              [-35.176998832030449, -8.211247307916892],
              [-35.177128860508446, -8.211111381684873],
              [-35.17720786055429, -8.210958626439435],
              [-35.177235900516337, -8.210817292583954],
              [-35.177207313766473, -8.210693069229706],
              [-35.177144689475767, -8.210540663820552],
              [-35.177076343698275, -8.210365684528654],
              [-35.177076055915222, -8.210224423518298],
              [-35.177104174118313, -8.21012265353761],
              [-35.177188884859106, -8.209992482966641],
              [-35.177313279983821, -8.209873515250479],
              [-35.177420672525955, -8.209754594299056],
              [-35.177658065667174, -8.209488437241532],
              [-35.177759326631588, -8.20913787071612],
              [-35.17779835720836, -8.208832661498093],
              [-35.177837450737741, -8.208555698186899],
              [-35.177848507902297, -8.208425720970183],
              [-35.17789908217685, -8.208222184606086],
              [-35.178000614736561, -8.208007218758098],
              [-35.178079666043686, -8.207877071569555],
              [-35.178175657880274, -8.207724270458641],
              [-35.178446773799521, -8.207328077728562],
              [-35.178576316643671, -8.206954835697047],
              [-35.178660524663428, -8.206695628600357],
              [-35.178750838180576, -8.206417630169426],
              [-35.178851902193514, -8.205976662892624],
              [-35.178907976721689, -8.205694015866072],
              [-35.178964194242155, -8.205484807477831],
              [-35.179099186977851, -8.20500985841818],
              [-35.179200395781606, -8.204636680238536],
              [-35.179199804927009, -8.204348527562445],
              [-35.179142802298429, -8.204249531990286],
              [-35.179080006237257, -8.204209481582268],
              [-35.178972314220857, -8.204181499176798],
              [-35.178881501580008, -8.204102626834697],
              [-35.178873635210039, -8.204089887937926],
              [-35.178853658569437, -8.20407253641838],
              [-35.178774037092069, -8.203943595220721],
              [-35.178745667078829, -8.203897654253296],
              [-35.178642882662871, -8.203502393220845],
              [-35.178597032526966, -8.203248246609235],
              [-35.178562694612594, -8.203078831422886],
              [-35.178545051712717, -8.202762467913544],
              [-35.178556213198647, -8.202677682528986],
              [-35.178629436898973, -8.202474086809616],
              [-35.178730973055906, -8.202259131753465],
              [-35.178775922071111, -8.202078205244902],
              [-35.178786902793263, -8.201908669549425],
              [-35.178718449996438, -8.201677187652024],
              [-35.17858191672201, -8.201400668605913],
              [-35.17847347467108, -8.201011073302368],
              [-35.17845590821355, -8.200728604341577],
              [-35.178455282285427, -8.200423501243563],
              [-35.178454501673855, -8.200044938822828],
              [-35.178487765797684, -8.199688888231549],
              [-35.178492774122162, -8.19936681492652],
              [-35.178452862209781, -8.199242607760084],
              [-35.178373259618184, -8.199101558989696],
              [-35.178219778619848, -8.198847683973383],
              [-35.178043757913031, -8.198650368219887],
              [-35.177873426317547, -8.198464331763432],
              [-35.177748460123269, -8.198300792747318],
              [-35.177640502806923, -8.198148506947973],
              [-35.177543781620159, -8.197945339305932],
              [-35.177503696138615, -8.197736383627159],
              [-35.177480552985116, -8.197499131679535],
              [-35.177542337308978, -8.19723906662955],
              [-35.177751360436901, -8.196950386950732],
              [-35.177870068249035, -8.196825781384632],
              [-35.177937933788542, -8.196769110605942],
              [-35.178101899982664, -8.196610493579385],
              [-35.178322349896462, -8.196372632790712],
              [-35.178480608093537, -8.196191429290991],
              [-35.178627572604341, -8.196032858129854],
              [-35.17874068213672, -8.195936514658399],
              [-35.178825458528024, -8.195840249427931],
              [-35.178870539743571, -8.195721487656499],
              [-35.178915466275726, -8.195529262965067],
              [-35.17891516590214, -8.195382359608175],
              [-35.178863798425539, -8.195196034469348],
              [-35.178772556807985, -8.194902448988785],
              [-35.178670047584603, -8.194637141790622],
              [-35.178601471580578, -8.194349156388398],
              [-35.178578209716534, -8.194055402734591],
              [-35.178594914623545, -8.193914103952693],
              [-35.178651199593162, -8.193733161256318],
              [-35.178780897120646, -8.19343902359202],
              [-35.178956148007785, -8.193263425566613],
              [-35.179086221451939, -8.193150094801013],
              [-35.17932941072371, -8.192951731337303],
              [-35.17941415563871, -8.192838517483551],
              [-35.179560980754616, -8.192612135641525],
              [-35.179623037573094, -8.192482026224468],
              [-35.179673801604622, -8.192374549146052],
              [-35.179730294317608, -8.192300955824408],
              [-35.179775541907702, -8.192261291225577],
              [-35.179866171086871, -8.192255414155966],
              [-35.180013619432941, -8.192334144405208],
              [-35.180291449690628, -8.192452105780035],
              [-35.180523097063258, -8.192472762308114],
              [-35.180784404976293, -8.192496066440242],
              [-35.181163917081051, -8.192478169931576],
              [-35.181492499679052, -8.192477343454584],
              [-35.181792893926854, -8.192550040152184],
              [-35.181957302587421, -8.192611782207962],
              [-35.182150103744277, -8.192701700922726],
              [-35.182308906930473, -8.192791704173771],
              [-35.182558324906722, -8.192864530373754],
              [-35.18276803691532, -8.192914855536117],
              [-35.183028700014461, -8.192953751214588],
              [-35.183244068758711, -8.19299841424538],
              [-35.183465347847459, -8.193161711343862],
              [-35.183484263288051, -8.193185185447931],
              [-35.183493157583086, -8.193191749599521],
              [-35.183629467825654, -8.193360911177081],
              [-35.183743232963764, -8.193586636513999],
              [-35.183874304655376, -8.193964872480819],
              [-35.183965970752922, -8.194461855829957],
              [-35.184023624490635, -8.194947629129729],
              [-35.184109331680247, -8.195303379347422],
              [-35.184274450805219, -8.195704130740978],
              [-35.18441684496139, -8.196076683891818],
              [-35.184508027223615, -8.196342011774391],
              [-35.184627594787628, -8.19662987576811],
              [-35.184713092435231, -8.196883917159028],
              [-35.184844652458708, -8.197493805894396],
              [-35.184879184626652, -8.197753628127373],
              [-35.184885160621604, -8.197906166636244],
              [-35.184885607584704, -8.198120870781509],
              [-35.184868813644812, -8.198222626373081],
              [-35.184761457075524, -8.198358496847106],
              [-35.184682209716186, -8.19838694600071],
              [-35.184591577839001, -8.198392824868904],
              [-35.184421377544481, -8.198274602690731],
              [-35.184296484195329, -8.198144960921184],
              [-35.184188615012545, -8.198037881343112],
              [-35.183984493189769, -8.197947988708677],
              [-35.183842929107868, -8.197982254677243],
              [-35.183746965150306, -8.198146346776412],
              [-35.18373034118224, -8.198327200439905],
              [-35.183742303964095, -8.198632278356911],
              [-35.183816694085124, -8.198993704196027],
              [-35.183856836223981, -8.19923091657347],
              [-35.183896907316353, -8.199428566874815],
              [-35.18392573148725, -8.199671453974361],
              [-35.18392047674854, -8.199869222986194],
              [-35.183791544544079, -8.200530626686998],
              [-35.183740899223096, -8.200694608169613],
              [-35.183684592669749, -8.200864259877619],
              [-35.183662832673519, -8.201299377963892],
              [-35.183668786185478, -8.201440616491197],
              [-35.183669020908553, -8.201553623500899],
              [-35.18362411056421, -8.201751498632689],
              [-35.183590444443439, -8.201909783809413],
              [-35.183545392006181, -8.202039856354247],
              [-35.183443602240402, -8.20213051970768],
              [-35.183352941584928, -8.202119439160754],
              [-35.183331169897542, -8.202090110470181],
              [-35.183325101272374, -8.202089368529471],
              [-35.183245567029246, -8.20198221790497],
              [-35.183205646123611, -8.201852369291373],
              [-35.183153990863957, -8.201530431783965],
              [-35.183068325043109, -8.201197288723847],
              [-35.182971391616377, -8.200892419449531],
              [-35.18285823082266, -8.200657240439227],
              [-35.182715190953274, -8.200544645319098],
              [-35.182556412048712, -8.200471589792016],
              [-35.182408896253492, -8.200364606441383],
              [-35.182391892771591, -8.200342395884832],
              [-35.182381060437535, -8.200334539407436],
              [-35.182273137295397, -8.20019355269028],
              [-35.182227336622248, -8.199962012336925],
              [-35.182198266936439, -8.19960046958596],
              [-35.182197940482524, -8.199442269844837],
              [-35.182214568047812, -8.199261415282024],
              [-35.182259583612847, -8.19911440310716],
              [-35.18231592613904, -8.198961698223741],
              [-35.182406243253467, -8.198803269155519],
              [-35.18244574057843, -8.19872971360536],
              [-35.182485095810478, -8.198582716718931],
              [-35.18248504185118, -8.198554470812155],
              [-35.182454125857191, -8.198504613297246],
              [-35.182399341856126, -8.19847745826803],
              [-35.182348339504244, -8.198466282392985],
              [-35.182240692453945, -8.198466558484824],
              [-35.182155615689055, -8.19841591945908],
              [-35.182148888760139, -8.198398418652062],
              [-35.182127787966976, -8.198385859619691],
              [-35.182082226513501, -8.198267313685506],
              [-35.182076105727674, -8.198046975287639],
              [-35.182058843240952, -8.19791706641945],
              [-35.182013188257997, -8.19775331876059],
              [-35.181934328506429, -8.197602061785688],
              [-35.181887602430891, -8.197563407125472],
              [-35.181666611125138, -8.197541363842845],
              [-35.181485479571165, -8.197615271927191],
              [-35.180914199630593, -8.198051779466027],
              [-35.180772920633729, -8.19822163891746],
              [-35.180665664788535, -8.198408359757934],
              [-35.180553363746697, -8.198894569228912],
              [-35.180486233852548, -8.199307197968778],
              [-35.18045258122163, -8.199476791220889],
              [-35.180373608155435, -8.199640846447835],
              [-35.180311496478339, -8.199737059517645],
              [-35.180249636611393, -8.19996321742849],
              [-35.180284179571174, -8.200228692799897],
              [-35.180375201754089, -8.200414920811909],
              [-35.180534325098961, -8.200657485954139],
              [-35.180653737080263, -8.200871887927235],
              [-35.180643020758737, -8.201171377457058],
              [-35.180575746973091, -8.20151621350243],
              [-35.180452637878886, -8.202256706281901],
              [-35.180442064549595, -8.202623996600096],
              [-35.180442876637414, -8.203019509442754],
              [-35.180415939244142, -8.203691949623703],
              [-35.180467816636394, -8.204126885147559],
              [-35.180621756288986, -8.204601121944165],
              [-35.180855173527391, -8.205154256263882],
              [-35.181037368080013, -8.205594516673557],
              [-35.181156994099979, -8.205910627473429],
              [-35.181282634785703, -8.20640187494655],
              [-35.181362758984108, -8.206791540397205],
              [-35.18138575374951, -8.206955333921485],
              [-35.181403456004659, -8.207299955928193],
              [-35.181494516858436, -8.207497480324434],
              [-35.181650104068979, -8.207671268913884],
              [-35.182442369919386, -8.208092099470775],
              [-35.182901784989326, -8.208345199271491],
              [-35.183191131965145, -8.208547881279628],
              [-35.183469110610929, -8.208733638946228],
              [-35.183690504812887, -8.208947784612519],
              [-35.183694277468874, -8.208954577191946],
              [-35.183718368507868, -8.208977880424811],
              [-35.183871901666862, -8.209254349108903],
              [-35.183934840720269, -8.20955365517699],
              [-35.183913050147112, -8.209977473281697],
              [-35.183812517633555, -8.210672702283318],
              [-35.183694658781462, -8.211209767516721],
              [-35.183582032184972, -8.211537767354059],
              [-35.183221146838434, -8.212357961650582],
              [-35.183170785703169, -8.212657547407844],
              [-35.18316605167189, -8.213109574547161],
              [-35.183342780145153, -8.213640252795358],
              [-35.18345652459891, -8.213849028609729],
              [-35.183627080125632, -8.214136748711951],
              [-35.183769298913944, -8.214418901111344],
              [-35.183877664522583, -8.214768940634031],
              [-35.184009393618567, -8.215457935535255],
              [-35.184021413057735, -8.215785611616639],
              [-35.184028061745607, -8.216260219833259],
              [-35.184000596450346, -8.216678400987348],
              [-35.183995502690266, -8.216949620328649],
              [-35.183985071453833, -8.217384711435216],
              [-35.184008411558921, -8.217712367731565],
              [-35.184042934733611, -8.217966542222086],
              [-35.184128676996806, -8.218333590622649],
              [-35.184027700218167, -8.218814116378326],
              [-35.183931644529707, -8.218938662589153],
              [-35.183761825547776, -8.219006888756837],
              [-35.18356925012074, -8.219029979749735],
            ],
            [
              [-35.177210156523472, -8.204055970906268],
              [-35.176960728654649, -8.203977490668592],
              [-35.176620398212776, -8.203774935464878],
              [-35.176359361894981, -8.203560879730787],
              [-35.176340227919127, -8.203537923636247],
              [-35.176331522481746, -8.203530785717135],
              [-35.175922627727061, -8.203040244211877],
              [-35.175672643177904, -8.202696208563111],
              [-35.175388403452018, -8.202216642384087],
              [-35.175144028873774, -8.201838685207122],
              [-35.175058693104177, -8.201663750881323],
              [-35.174939267633079, -8.201438037235562],
              [-35.174893743051769, -8.201336446219955],
              [-35.174842546446314, -8.20123487139216],
              [-35.174781346681755, -8.20114375388107],
              [-35.17469445058839, -8.201118490126191],
              [-35.174552904492884, -8.201158402937892],
              [-35.174422983034219, -8.20134518313364],
              [-35.174366881526339, -8.201616531977322],
              [-35.174367917545339, -8.20211940588581],
              [-35.174612686925911, -8.202689468144399],
              [-35.174732097108574, -8.202903873714774],
              [-35.17499404084225, -8.20356430075185],
              [-35.175153382722385, -8.203914214369433],
              [-35.175216095146652, -8.204106167465625],
              [-35.175273523154544, -8.204484588692765],
              [-35.175262582901837, -8.204676722552676],
              [-35.175223146730787, -8.204784174450026],
              [-35.175161046744847, -8.204891688400664],
              [-35.175138551103203, -8.204970846727122],
              [-35.17516089925607, -8.205037256264291],
              [-35.175252640896645, -8.205087297240937],
              [-35.175383087559396, -8.205160426512407],
              [-35.175496630565021, -8.205273146638376],
              [-35.175499181941632, -8.205278140573682],
              [-35.175524473575535, -8.205303249645226],
              [-35.175734923927564, -8.205715185907081],
              [-35.176030294562395, -8.206093006387107],
              [-35.176195170870415, -8.20627655082211],
              [-35.17630903223602, -8.206384208664247],
              [-35.176433857236702, -8.206474296451574],
              [-35.176547351678892, -8.206570065256244],
              [-35.176556434831582, -8.206584336597814],
              [-35.176575200984701, -8.206600171860373],
              [-35.176643412763269, -8.20670734946458],
              [-35.176728947283038, -8.206978353867896],
              [-35.176758133880753, -8.207402041570901],
              [-35.176872930541265, -8.208124986778671],
              [-35.176879180829474, -8.208413132260205],
              [-35.176761304890249, -8.208944548851775],
              [-35.176637471841786, -8.209334724218081],
              [-35.176411700639449, -8.209747758552169],
              [-35.176157496471262, -8.210104361327183],
              [-35.175790268206242, -8.210602505701047],
              [-35.175513465873735, -8.210993065977163],
              [-35.175259197853677, -8.211321413852147],
              [-35.174971303282973, -8.211825012614213],
              [-35.174768428819903, -8.212350993703353],
              [-35.174616283314776, -8.212752540996917],
              [-35.174464236735105, -8.213199291813794],
              [-35.174266826552753, -8.213629202825416],
              [-35.174086208511653, -8.213957369936622],
              [-35.173956479507865, -8.214240209418108],
              [-35.173815673554429, -8.214641730631342],
              [-35.173714475542567, -8.215020549358826],
              [-35.173669642539949, -8.215263618121412],
              [-35.173607747599419, -8.215467191357732],
              [-35.173534406987244, -8.215619924120176],
              [-35.17341010179473, -8.215784095359652],
              [-35.173313962469365, -8.21586908668894],
              [-35.173268681604327, -8.215886152224073],
              [-35.173200597529501, -8.21584112676652],
              [-35.173185117499145, -8.215819174315413],
              [-35.173172723042683, -8.215810976994931],
              [-35.173094571956881, -8.215700155337856],
              [-35.17304156231819, -8.215643763348965],
              [-35.172899716748475, -8.21554241335167],
              [-35.172758024962832, -8.215514515482482],
              [-35.17244094289515, -8.215600065290744],
              [-35.172084180940146, -8.215680063584045],
              [-35.171823622162378, -8.215703316454835],
              [-35.17162532255054, -8.215698157943564],
              [-35.171472274126131, -8.21565899516645],
              [-35.171268040787425, -8.215523893612726],
              [-35.171241159152146, -8.215494395849646],
              [-35.171240168099246, -8.215493740243858],
              [-35.171024404552611, -8.215256965836261],
              [-35.170814201514133, -8.214974986106654],
              [-35.170706262184183, -8.214828345351805],
              [-35.170431749525562, -8.214486346667513],
              [-35.170285539545944, -8.214356746266787],
              [-35.170081241456103, -8.214187749265193],
              [-35.169820295969828, -8.214024542998231],
              [-35.169616139073526, -8.213923346784952],
              [-35.169332760898158, -8.213873205983646],
              [-35.168839733666744, -8.213806632769831],
              [-35.168652689070313, -8.213761890598732],
              [-35.168499420311946, -8.213615367655052],
              [-35.168261072990106, -8.213418208383587],
              [-35.168022895640092, -8.213305794926441],
              [-35.167643247737658, -8.213272839270076],
              [-35.167478981170007, -8.213284544845806],
              [-35.167314730790167, -8.213313206239434],
              [-35.16719575947667, -8.213313501217044],
              [-35.167122010572314, -8.21326283790961],
              [-35.167120081526491, -8.213250495613863],
              [-35.167094150474611, -8.213232681842726],
              [-35.167071190782792, -8.213085828847852],
              [-35.167087614201989, -8.212803275350836],
              [-35.167075275503521, -8.212306087855609],
              [-35.16733483963111, -8.211791264655119],
              [-35.167532725554793, -8.2115930109087],
              [-35.167809902904651, -8.211383264725839],
              [-35.167990939130505, -8.211258510771586],
              [-35.168132344032415, -8.211145157522935],
              [-35.168290713745421, -8.211014810684901],
              [-35.168448838842778, -8.210765796504234],
              [-35.168493831924764, -8.210601829410848],
              [-35.168527288503526, -8.210341834508029],
              [-35.168503976277464, -8.21001983135011],
              [-35.168486750662964, -8.209906872004467],
              [-35.168486403524639, -8.209737361489999],
              [-35.168502971734711, -8.209528261919729],
              [-35.168525327981456, -8.20937564876715],
              [-35.168626695065882, -8.209075934406826],
              [-35.168677428520482, -8.208951508705127],
              [-35.16873955548607, -8.208855295635317],
              [-35.168750761038723, -8.208793117408391],
              [-35.168742737287459, -8.208741779609397],
              [-35.168597055209887, -8.208693748617577],
              [-35.168319443487462, -8.208688790655117],
              [-35.167962747182969, -8.208797028559957],
              [-35.167679836655111, -8.208972890986104],
              [-35.167363318462321, -8.209340943929362],
              [-35.167205219445293, -8.209601246400382],
              [-35.167103832575776, -8.209889664376476],
              [-35.167002319801156, -8.210121578847748],
              [-35.166838625202331, -8.210415798350368],
              [-35.166714238171494, -8.210540412909666],
              [-35.166317485163745, -8.210450996915938],
              [-35.166152947900173, -8.21033274416078],
              [-35.165977120547609, -8.210237132737461],
              [-35.165704893965653, -8.210090892180858],
              [-35.165551888708649, -8.210074325769483],
              [-35.16545546609683, -8.210018059685638],
              [-35.16544099150849, -8.209995710633507],
              [-35.165427616791021, -8.20998790451813],
              [-35.165376473245601, -8.209908932351311],
              [-35.165387496235894, -8.209756346178381],
              [-35.165483508756893, -8.209609205400454],
              [-35.165579647182938, -8.209524211373207],
              [-35.165681485512096, -8.209456159674005],
              [-35.165749353749618, -8.209399491593217],
              [-35.165817229181755, -8.209342812720593],
              [-35.165867987817364, -8.209229684302443],
              [-35.165879232040879, -8.209190105139214],
              [-35.165860688020302, -8.209166437680892],
              [-35.165697202064166, -8.209090817287347],
              [-35.165453467803275, -8.209029267686503],
              [-35.165221071295377, -8.208973343345065],
              [-35.165090560780698, -8.208871955576228],
              [-35.165069074178405, -8.208846741284049],
              [-35.165062716870864, -8.208841804905319],
              [-35.164937787548809, -8.20869520731793],
              [-35.164841042979731, -8.208480738795004],
              [-35.164818200199761, -8.208390386606993],
              [-35.164801118476817, -8.208350878490194],
              [-35.164777182121213, -8.208324469898344],
              [-35.164675826728001, -8.20829665926334],
              [-35.164585201146053, -8.208302532735672],
              [-35.164471956715374, -8.208336712369317],
              [-35.164375597955427, -8.208314352525292],
              [-35.164350284737793, -8.208284783715726],
              [-35.164347755844233, -8.208284196458465],
              [-35.164251220817164, -8.208171434963731],
              [-35.164182978461668, -8.20804164660467],
              [-35.164120299312344, -8.207866638534483],
              [-35.164159439606408, -8.20760663463841],
              [-35.164328736082268, -8.207278499003507],
              [-35.164498308649968, -8.207085966743932],
              [-35.164702086931698, -8.207000710114642],
              [-35.165002262644236, -8.20696042138627],
              [-35.165296749245556, -8.206903187631951],
              [-35.165579791973755, -8.206795134987431],
              [-35.165743848100078, -8.206676076439749],
              [-35.165839957747835, -8.206574131990578],
              [-35.165969927769879, -8.206409960751046],
              [-35.166048829789645, -8.206206356937798],
              [-35.166099537163859, -8.206070621362016],
              [-35.166178630739182, -8.205957424595397],
              [-35.166303094212367, -8.205872356823761],
              [-35.166404929843566, -8.205804307822518],
              [-35.166512415015916, -8.20572493545734],
              [-35.166580230193404, -8.205645669212231],
              [-35.166676239117123, -8.20549852034037],
              [-35.166772132927576, -8.205289226516527],
              [-35.166907605001938, -8.205045924829506],
              [-35.167065832622029, -8.20484777450514],
              [-35.167212833105737, -8.204700506922791],
              [-35.167410795471767, -8.204541809856607],
              [-35.167501313135062, -8.204479431980133],
              [-35.167699371728588, -8.204365937537943],
              [-35.167784076174144, -8.204230118325256],
              [-35.167874308752289, -8.204026487532303],
              [-35.167890827499662, -8.203794789797655],
              [-35.167884766968371, -8.203597045966831],
              [-35.167827704984575, -8.203393770406137],
              [-35.167742398892528, -8.203230131565249],
              [-35.167605999617138, -8.203021410609551],
              [-35.167514695047089, -8.202911659145684],
              [-35.167332959148723, -8.202747154257736],
              [-35.16723081774802, -8.202668304398685],
              [-35.167207020787316, -8.202641302254222],
              [-35.167202991824581, -8.202638191499318],
              [-35.167163247186068, -8.202593091398001],
              [-35.167134831307351, -8.202547956223157],
              [-35.16710623556429, -8.202412425692842],
              [-35.167111664771483, -8.202299403394704],
              [-35.167196312559724, -8.202135331979946],
              [-35.167303807624592, -8.202061617249797],
              [-35.167445299760743, -8.201993461229222],
              [-35.167615154715577, -8.201942190879379],
              [-35.167870012690912, -8.201907655114155],
              [-35.168062696036543, -8.20193542797756],
              [-35.168215728273196, -8.201974605143846],
              [-35.16839708285903, -8.202008050930772],
              [-35.168470734636628, -8.202007871965691],
              [-35.168617886206448, -8.201934053813488],
              [-35.168702561873715, -8.201786936417932],
              [-35.16875317661777, -8.201600347777799],
              [-35.168820769662773, -8.201408076321627],
              [-35.16887708610858, -8.201244071456699],
              [-35.168990085024348, -8.201091236171578],
              [-35.169137159252443, -8.200983519873489],
              [-35.169272941592965, -8.200892779178389],
              [-35.169504856064236, -8.200717047153944],
              [-35.169691481576592, -8.200552723929036],
              [-35.16979322817491, -8.200445117348238],
              [-35.170075940851973, -8.200173199233689],
              [-35.170177376284869, -8.199907389314546],
              [-35.170261647257178, -8.199556867755177],
              [-35.170351440966158, -8.199138522924443],
              [-35.170481030575047, -8.198787882654642],
              [-35.170559998245267, -8.198618181483823],
              [-35.170627730685169, -8.198493707218745],
              [-35.170769219224042, -8.19842555119817],
              [-35.171205358940142, -8.198384914432211],
              [-35.171397903790194, -8.198350542343633],
              [-35.171686721964591, -8.198298966224286],
              [-35.171845179811157, -8.19821947334998],
              [-35.172003556718721, -8.198094773355081],
              [-35.172076926109185, -8.197958980222722],
              [-35.172150035595621, -8.197693242249272],
              [-35.172154893733307, -8.19729770872226],
              [-35.172108852941165, -8.196941860478432],
              [-35.17205742970657, -8.19672728493623],
              [-35.172017269581261, -8.196478771677903],
              [-35.171926108003447, -8.196224735682961],
              [-35.17191437904529, -8.196027005341989],
              [-35.171936572514767, -8.195795293218168],
              [-35.171992627257907, -8.195501344411468],
              [-35.172037581669031, -8.195320419701545],
              [-35.172065572168378, -8.19515649757426],
              [-35.172059498147291, -8.19495310600098],
              [-35.172036572629679, -8.194823198931431],
              [-35.171917092299907, -8.194569236680877],
              [-35.171860136436123, -8.194422475416843],
              [-35.171695094652875, -8.194049964534202],
              [-35.171649327254613, -8.193829729558331],
              [-35.171659370883219, -8.193196880231767],
              [-35.17171355413717, -8.191976282579333],
              [-35.171712546896458, -8.191479070802416],
              [-35.171672480300685, -8.191275762866098],
              [-35.171627498909913, -8.191206299231283],
              [-35.171518576620997, -8.191102862806815],
              [-35.171297313720004, -8.190939549520522],
              [-35.171161135677835, -8.190832535593017],
              [-35.170945525015895, -8.190669218709445],
              [-35.170930259024203, -8.190648647616968],
              [-35.170917735065359, -8.190639162467335],
              [-35.170758683666293, -8.19042484772848],
              [-35.170639321147689, -8.190227377291592],
              [-35.170587937483276, -8.190035400813031],
              [-35.170542197064719, -8.189826454127513],
              [-35.170513320733107, -8.189555315725215],
              [-35.170512934024657, -8.189363207945632],
              [-35.17058594098836, -8.189046617807094],
              [-35.170625224274659, -8.18886005434797],
              [-35.170630599522497, -8.188718788840958],
              [-35.170567666764384, -8.188413828735179],
              [-35.170476624796379, -8.188216298943019],
              [-35.170419706704081, -8.188080833163895],
              [-35.170356721785254, -8.187753277591639],
              [-35.170310880642603, -8.187493475143697],
              [-35.170315920443329, -8.187182699122218],
              [-35.170343975693868, -8.187047026499044],
              [-35.170354886268967, -8.186843595355583],
              [-35.170360350549686, -8.186741875736914],
              [-35.170310144997131, -8.186625719301446],
              [-35.170200657933947, -8.186512469474849],
              [-35.169945415948121, -8.18635489566185],
              [-35.169384319031053, -8.186226326783526],
              [-35.168812057930097, -8.186182541491064],
              [-35.1686420788688, -8.186166016448453],
              [-35.168381370803559, -8.186110153260927],
              [-35.168114979023017, -8.186043012574942],
              [-35.168035617449732, -8.186014957324346],
              [-35.168010083898196, -8.185985704176858],
              [-35.168007843687008, -8.185984912773449],
              [-35.167860210979882, -8.185815776376955],
              [-35.167843016841687, -8.185719765654596],
              [-35.167871197997329, -8.185646234386184],
              [-35.167933250319209, -8.185516125868446],
              [-35.168029396839131, -8.185436786778268],
              [-35.168085975887038, -8.185402738445589],
              [-35.168182200648005, -8.185362956934853],
              [-35.168284211647006, -8.18538530598704],
              [-35.16842596728452, -8.185447104700074],
              [-35.168516661214881, -8.185475136568243],
              [-35.168663962971493, -8.185480421883938],
              [-35.168726280593319, -8.185480266301226],
              [-35.168867855467113, -8.185457316501925],
              [-35.168952773951332, -8.18542884666391],
              [-35.169145399740387, -8.185434024960216],
              [-35.169349359685157, -8.185444822220745],
              [-35.16954192252166, -8.185421744717701],
              [-35.169660861459533, -8.185410149758582],
              [-35.169791093184358, -8.185375922460821],
              [-35.16992703470487, -8.185369942868533],
              [-35.170051696028906, -8.185380933483259],
              [-35.170165080753804, -8.185425854619439],
              [-35.170369332078963, -8.185577896702512],
              [-35.17055083415363, -8.185690455849794],
              [-35.170783603880182, -8.185938495165374],
              [-35.170784976245614, -8.185940398130811],
              [-35.170811378542226, -8.185968533421033],
              [-35.171368115546045, -8.18674122912438],
              [-35.171464818746358, -8.186938750822662],
              [-35.171595627835927, -8.187192689690846],
              [-35.171732139526569, -8.187463564591781],
              [-35.171772128780674, -8.187627322143157],
              [-35.171931176582518, -8.187841635982693],
              [-35.172039234622957, -8.188050429783459],
              [-35.172090428530396, -8.188152006409894],
              [-35.172133834308966, -8.188206850665551],
              [-35.172215886653817, -8.188262837959542],
              [-35.172329203929564, -8.188268202415543],
              [-35.172499065179693, -8.188222579808155],
              [-35.172583955784887, -8.188182821679845],
              [-35.17270292080309, -8.188182524004219],
              [-35.172821941579286, -8.188210476732081],
              [-35.172867292591434, -8.188227316537393],
              [-35.173116850861959, -8.18837359936208],
              [-35.173275677430524, -8.188474914285791],
              [-35.173417458249048, -8.188548012980391],
              [-35.173593069764308, -8.188547574111226],
              [-35.173774198620322, -8.188473670523422],
              [-35.173842125313797, -8.188445255544082],
              [-35.174006339720734, -8.188410943710039],
              [-35.174164997216792, -8.188427500228897],
              [-35.174318225505658, -8.188562723191069],
              [-35.174327961566121, -8.188576835352649],
              [-35.174346012758235, -8.188592765943326],
              [-35.174630100498689, -8.189004521441461],
              [-35.174783735281096, -8.189337502623573],
              [-35.174880517621773, -8.189574577404755],
              [-35.174944064616852, -8.190178992866208],
              [-35.174950075685388, -8.190348490790143],
              [-35.174950356273882, -8.190484093266264],
              [-35.174882688585171, -8.190636813438118],
              [-35.174746979989095, -8.190761463970318],
              [-35.174396015963339, -8.190892289247699],
              [-35.174220586111119, -8.190988778409348],
              [-35.174107509853627, -8.191096421862312],
              [-35.174022991567767, -8.19132263732979],
              [-35.173961075942884, -8.191520549333859],
              [-35.17395576814414, -8.191695720181315],
              [-35.173978909499056, -8.191932974826898],
              [-35.173996109932489, -8.192034636888934],
              [-35.174054921097706, -8.192161006924891],
              [-35.174121659786749, -8.192196307113818],
              [-35.174410719878779, -8.192263394739825],
              [-35.174677065793901, -8.192307930966194],
              [-35.174920593210743, -8.19227342038198],
              [-35.175079147284748, -8.192239120239151],
              [-35.175192465459872, -8.192244493688406],
              [-35.175328618321032, -8.192340208533778],
              [-35.17533989671881, -8.192358645535023],
              [-35.175356418164142, -8.192370261178553],
              [-35.175453046720691, -8.192528222599321],
              [-35.175492974820884, -8.192663731545906],
              [-35.17556750793409, -8.193098610412505],
              [-35.175642702948323, -8.193855561684757],
              [-35.175620974428398, -8.194313278834727],
              [-35.175678297215597, -8.194640855990656],
              [-35.175746754509078, -8.194877989227791],
              [-35.175763964835085, -8.194979651289827],
              [-35.175775728866768, -8.195194332952042],
              [-35.175764708574377, -8.195346918225653],
              [-35.175578606467468, -8.195759853634286],
              [-35.175398955197579, -8.196556984816937],
              [-35.175366064292405, -8.19709948465345],
              [-35.175355173502396, -8.197314226570256],
              [-35.175361404904891, -8.197591068473002],
              [-35.175435639443151, -8.197879044881972],
              [-35.175555180027516, -8.198161263831196],
              [-35.17574406823411, -8.198368545871631],
              [-35.176117499123222, -8.198598682383249],
              [-35.176446453141239, -8.198784316842705],
              [-35.177019420210058, -8.19916709528519],
              [-35.177342520540833, -8.199256692941958],
              [-35.177455892675255, -8.199290313197366],
              [-35.177563710596701, -8.199380446850114],
              [-35.177570035528674, -8.199392548127548],
              [-35.17759153652014, -8.199410521977939],
              [-35.177671162494107, -8.199562870729835],
              [-35.177711168835344, -8.199732284117545],
              [-35.177723235938572, -8.200093865539429],
              [-35.177781147781729, -8.200709600767425],
              [-35.177788424196365, -8.201494964321114],
              [-35.177794786000504, -8.201833958370457],
              [-35.17782945126811, -8.202161587687101],
              [-35.177869768774769, -8.202483552174158],
              [-35.177927149118602, -8.202839364445083],
              [-35.177956451728846, -8.20331391780303],
              [-35.177917254777469, -8.203540025351856],
              [-35.177804491484039, -8.203800223501446],
              [-35.177719767253393, -8.203924732840107],
              [-35.177612300067551, -8.204009757444283],
              [-35.177419757915459, -8.204049792563808],
              [-35.177210156523472, -8.204055970906268],
            ],
            [
              [-35.106680548068823, -8.188746523932878],
              [-35.106657509236641, -8.188713514317158],
              [-35.106652833661315, -8.188716017130446],
              [-35.106441573020163, -8.188413344899914],
              [-35.106011770826512, -8.188017125190129],
              [-35.105745325086616, -8.187802214200758],
              [-35.105495837862577, -8.187666401283366],
              [-35.105104613886795, -8.187428781513006],
              [-35.104719115894454, -8.187129011394688],
              [-35.104705939027838, -8.187109793781929],
              [-35.104691408681504, -8.18709849469974],
              [-35.10454410062971, -8.186883676340528],
              [-35.104345928721614, -8.186448465624153],
              [-35.104312269795344, -8.186007755553419],
              [-35.10432952778541, -8.185730927140526],
              [-35.104307370288836, -8.18511507679932],
              [-35.104267814507978, -8.184928587983904],
              [-35.10422821016374, -8.184815563887128],
              [-35.104148906147032, -8.184685545301647],
              [-35.104061394018061, -8.184585142290416],
              [-35.103966859083243, -8.184540743660364],
              [-35.103864763547961, -8.184523701507544],
              [-35.103773972490785, -8.184546220531615],
              [-35.103660419592643, -8.184659127716543],
              [-35.103558181964502, -8.18481159068233],
              [-35.103461618159088, -8.184952747371369],
              [-35.103404821475294, -8.185037446420949],
              [-35.103348031086739, -8.185110852683636],
              [-35.103251562609501, -8.18514466629324],
              [-35.103109775495682, -8.185104997197811],
              [-35.102962404491336, -8.184969275111882],
              [-35.10260533047159, -8.184596077147205],
              [-35.102401293185096, -8.184381210224615],
              [-35.102395193083623, -8.184373425692968],
              [-35.102373596764039, -8.184350683637092],
              [-35.10214695052133, -8.184061434687408],
              [-35.101993237497879, -8.183934524158929],
              [-35.101857142193296, -8.183877912735454],
              [-35.101738042276793, -8.183849559809289],
              [-35.101669961799246, -8.183855146397832],
              [-35.101596185915184, -8.183888985188446],
              [-35.101556397209947, -8.183979354463588],
              [-35.101544869699921, -8.184188382987429],
              [-35.101573085929147, -8.184357899797192],
              [-35.10156717108805, -8.184640392140352],
              [-35.101532978863816, -8.184821162166827],
              [-35.101527187229863, -8.184956750253775],
              [-35.101544097182284, -8.185081066238183],
              [-35.101583708721137, -8.185194098428838],
              [-35.101738652916481, -8.185398380330923],
              [-35.102111397622878, -8.185796822265502],
              [-35.102122633752515, -8.185809757214486],
              [-35.102139097641214, -8.185827355148263],
              [-35.102337000652028, -8.18605516961037],
              [-35.102451458268149, -8.186153051821861],
              [-35.102638549629319, -8.186283164836141],
              [-35.102746290209097, -8.186328451996417],
              [-35.102956146109193, -8.186379482227267],
              [-35.103097928726413, -8.186424804461126],
              [-35.103324777316573, -8.186492795905735],
              [-35.103625250704738, -8.186707751861206],
              [-35.103643238944301, -8.186731323991353],
              [-35.103652956118992, -8.18673827485145],
              [-35.104032613913944, -8.187235782505297],
              [-35.104417721600555, -8.187981883457098],
              [-35.104672702782977, -8.188332394224574],
              [-35.104723371486386, -8.188390713460592],
              [-35.105002102864717, -8.188629861179095],
              [-35.105291206124207, -8.188873062142079],
              [-35.105671050078854, -8.189150224203445],
              [-35.105767402543563, -8.189251997781412],
              [-35.105772301150751, -8.189258411746266],
              [-35.105795117850391, -8.189282511778458],
              [-35.105885782103144, -8.189401235778348],
              [-35.105925379252824, -8.189536866133437],
              [-35.105936525450261, -8.18977416844308],
              [-35.105862627258375, -8.18994360341452],
              [-35.105692331635737, -8.190073405263377],
              [-35.105187409970938, -8.19011817891078],
              [-35.105005937573935, -8.190044582891119],
              [-35.104722462271752, -8.189846592646063],
              [-35.10421794979851, -8.189416784157231],
              [-35.103690600840537, -8.189145141235258],
              [-35.103475098097249, -8.189071513739293],
              [-35.103253855880723, -8.189071319485777],
              [-35.103180048520358, -8.189139058220917],
              [-35.10312321856162, -8.189252006774666],
              [-35.103043533232437, -8.189568335210481],
              [-35.102986674495412, -8.189720831451154],
              [-35.10281627814868, -8.1899579853727],
              [-35.102549486169835, -8.190155497178409],
              [-35.10216918805753, -8.190398120776592],
              [-35.102015906708687, -8.190533589253675],
              [-35.101925001437621, -8.190697361194225],
              [-35.101788592269656, -8.190996685248763],
              [-35.101748628196617, -8.191296095638279],
              [-35.101742698966348, -8.19158422762996],
              [-35.101782120748226, -8.191923261249485],
              [-35.101821687320978, -8.192092792448364],
              [-35.101912186098446, -8.192403610737983],
              [-35.102042384548383, -8.192725762284056],
              [-35.102127330911571, -8.192900979896251],
              [-35.102161259634386, -8.193019654433442],
              [-35.10215550127532, -8.193115693934089],
              [-35.102098671316583, -8.193245590211745],
              [-35.101871608687759, -8.193409249737044],
              [-35.101519764225714, -8.193550199581978],
              [-35.10111120581746, -8.193674153139625],
              [-35.100838803867646, -8.193792560578174],
              [-35.10054366795589, -8.193956158949504],
              [-35.100276940728179, -8.194068931236131],
              [-35.100038633875783, -8.194119571161252],
              [-35.099658475158435, -8.194203998615592],
              [-35.099068264488778, -8.194457743230203],
              [-35.098676687978696, -8.194621254367348],
              [-35.098483787896441, -8.194643689754457],
              [-35.098330614466249, -8.19464355395678],
              [-35.098143496125374, -8.19454170123845],
              [-35.098124367545495, -8.194515796266955],
              [-35.098115772724668, -8.19451111889299],
              [-35.098036448023606, -8.194403696673191],
              [-35.098019592929802, -8.194211591591625],
              [-35.098042509454217, -8.193963011783524],
              [-35.098411659570388, -8.193488741712031],
              [-35.098491174927744, -8.193375811144733],
              [-35.098627576001775, -8.193087788870287],
              [-35.09870147689162, -8.192918357496183],
              [-35.098752925307224, -8.192472066232824],
              [-35.098775795066842, -8.192268682753479],
              [-35.09886682804165, -8.191958026341808],
              [-35.099060128322208, -8.191477952945149],
              [-35.099310155139506, -8.190992276772135],
              [-35.099457826517437, -8.190794664242333],
              [-35.09962242943152, -8.190693108300252],
              [-35.099883455856741, -8.190602931480043],
              [-35.099991260288391, -8.190580423247866],
              [-35.100082083721134, -8.190524006977284],
              [-35.100155911765853, -8.190422369196938],
              [-35.100155975617724, -8.190354573804484],
              [-35.100008001168248, -8.190161119739855],
              [-35.09957151161592, -8.189808323795035],
              [-35.099344771843676, -8.189610386610013],
              [-35.099191689245004, -8.189514201419229],
              [-35.099038554485674, -8.189468870192172],
              [-35.098817308671812, -8.189479979517387],
              [-35.098624388804467, -8.189519364427099],
              [-35.09842568359835, -8.18969434551758],
              [-35.098402950535672, -8.189745165307102],
              [-35.098368806874873, -8.189869439922688],
              [-35.098357362102547, -8.189988081184936],
              [-35.098255058823838, -8.19019703146779],
              [-35.09818122808116, -8.190293018807722],
              [-35.098078923003868, -8.190513274468003],
              [-35.098129538647242, -8.191027458250574],
              [-35.098248280633584, -8.191473903297947],
              [-35.098298961927526, -8.191903340366821],
              [-35.098082720840864, -8.192671536761964],
              [-35.097878222202155, -8.192987757279127],
              [-35.097344640942367, -8.193360185424183],
              [-35.097146031064426, -8.193422162202978],
              [-35.096947458058708, -8.193438950746952],
              [-35.096760308241585, -8.193370986281934],
              [-35.096533553180905, -8.193189996820877],
              [-35.096520463548529, -8.193171081380228],
              [-35.096505835176117, -8.193159404582786],
              [-35.096341523642366, -8.192921961978925],
              [-35.096256770633431, -8.192537702353036],
              [-35.096273873940106, -8.192447316890139],
              [-35.096404614681205, -8.192142337898531],
              [-35.096575020920454, -8.191893888492132],
              [-35.096790828533869, -8.191622883189439],
              [-35.096949816980441, -8.191453524660403],
              [-35.097080456098126, -8.191272839170153],
              [-35.097120276279668, -8.191142936597203],
              [-35.097114897434551, -8.190977886720077],
              [-35.097029100312682, -8.190896562826083],
              [-35.09666606288846, -8.190868001257229],
              [-35.096274471989261, -8.191042807879228],
              [-35.095939550969433, -8.191296769230462],
              [-35.095667052792237, -8.191516878301229],
              [-35.095411553600286, -8.191770900806375],
              [-35.095207102625636, -8.192030619617356],
              [-35.095047901039777, -8.192431629116356],
              [-35.095019173995638, -8.192844040220223],
              [-35.095053023578146, -8.193064414590992],
              [-35.095178777578496, -8.193271752389421],
              [-35.095472997980437, -8.193565292903884],
              [-35.095801745154347, -8.193887621616398],
              [-35.096311899974182, -8.194368309250024],
              [-35.09631810799425, -8.194378634366444],
              [-35.096339621576249, -8.194398905984656],
              [-35.096526554656748, -8.194709810609197],
              [-35.096758308149333, -8.195670488901897],
              [-35.096854550896751, -8.195896566773115],
              [-35.096939504454497, -8.196054834862707],
              [-35.097018844444051, -8.196156605742715],
              [-35.097047138914263, -8.196230075857272],
              [-35.097041402138927, -8.196309169432595],
              [-35.096933551841914, -8.196376874892792],
              [-35.096536478675489, -8.196336982765501],
              [-35.096145067640691, -8.196314047355372],
              [-35.095640209827764, -8.196262764414996],
              [-35.095538099903365, -8.196257021344422],
              [-35.095481385057838, -8.19624002415776],
              [-35.09547021727667, -8.196214380888932],
              [-35.095453658959173, -8.196209418429873],
              [-35.095397044837682, -8.196079422327443],
              [-35.095431417825637, -8.195695259828312],
              [-35.095471341429231, -8.195458006981369],
              [-35.09556803743493, -8.195158636162091],
              [-35.095579504690363, -8.195023057967717],
              [-35.095579566743595, -8.194955258977984],
              [-35.095540393174588, -8.194849090413129],
              [-35.095425985021166, -8.194725808649082],
              [-35.095424279906524, -8.1947232383867],
              [-35.095398264318419, -8.194695205619212],
              [-35.095120648998716, -8.194276872479634],
              [-35.095069688914975, -8.194175132176554],
              [-35.09501601197934, -8.194067981552053],
              [-35.094955751106966, -8.194007863671914],
              [-35.09483101693786, -8.193928662177996],
              [-35.094626810578802, -8.19390588324984],
              [-35.094371598270584, -8.193820915302979],
              [-35.093929250534416, -8.19366232885335],
              [-35.093317001977994, -8.193170257601253],
              [-35.093152631089026, -8.193006270722776],
              [-35.093149658829645, -8.193000337895228],
              [-35.093124917580838, -8.192975654203053],
              [-35.093051294581528, -8.192828689692249],
              [-35.093000472094047, -8.192568756842604],
              [-35.093046092003476, -8.192303252692909],
              [-35.093102974122928, -8.192128148395284],
              [-35.093227991578487, -8.191885311657757],
              [-35.093409785932806, -8.191591686607012],
              [-35.093500610264925, -8.191529611802082],
              [-35.09381840999157, -8.191399946650165],
              [-35.094039725053221, -8.191326691473591],
              [-35.094266724729493, -8.191225187692226],
              [-35.094368916492272, -8.191140534508008],
              [-35.094527902240827, -8.190982476859745],
              [-35.094573354876388, -8.190892118376496],
              [-35.094573431318736, -8.19081451857511],
              [-35.094493451911205, -8.19073052369464],
              [-35.094255370788687, -8.190515627993818],
              [-35.094045602122776, -8.190374195212883],
              [-35.09403947863899, -8.1903581432137],
              [-35.094017895809202, -8.190343591283636],
              [-35.093972622138779, -8.190224902357329],
              [-35.09396712278442, -8.190027152231266],
              [-35.094029738081929, -8.189784257937106],
              [-35.094137696297594, -8.189586607635761],
              [-35.094296736005504, -8.18936075369578],
              [-35.094381978245679, -8.189202635792981],
              [-35.09438781844301, -8.189004887465558],
              [-35.094376907867968, -8.188895176471192],
              [-35.094245576272272, -8.188764146148401],
              [-35.094035748251144, -8.188679212375689],
              [-35.09394499856279, -8.188667841347751],
              [-35.09385994697891, -8.188622559583393],
              [-35.093791923158676, -8.188566008414512],
              [-35.093788068664367, -8.18855523183845],
              [-35.09376422403966, -8.188535406283904],
              [-35.093735928670128, -8.188456285728932],
              [-35.09374735455674, -8.188354592190649],
              [-35.093860971306754, -8.188185197688654],
              [-35.094036958738627, -8.188032800373378],
              [-35.094700907520803, -8.187790439377238],
              [-35.094990296764763, -8.187705949869667],
              [-35.095222962169942, -8.187615749667088],
              [-35.095438681649796, -8.187446440600695],
              [-35.095654518041556, -8.187141539850131],
              [-35.095824904495714, -8.186915697601307],
              [-35.096034906086004, -8.18680287944926],
              [-35.09630176371536, -8.186531919112724],
              [-35.096517698133198, -8.186114020345713],
              [-35.096784908296854, -8.185458864235159],
              [-35.096796389042083, -8.185295025744779],
              [-35.096762511580607, -8.185119848602085],
              [-35.09665686282392, -8.184982821599874],
              [-35.096501174889283, -8.184822479673642],
              [-35.09649406125186, -8.184813098845382],
              [-35.096473483864145, -8.184791907220699],
              [-35.096314834462021, -8.184582729409385],
              [-35.096253006970642, -8.183927289113058],
              [-35.096309974525639, -8.183656139918867],
              [-35.096366963664423, -8.183368050195327],
              [-35.09642966169946, -8.183029108306641],
              [-35.096515205212484, -8.182509395492161],
              [-35.096651681829599, -8.182136617510821],
              [-35.096754007591358, -8.181899412327994],
              [-35.096827787972018, -8.181854285247027],
              [-35.096924367965187, -8.181701820482601],
              [-35.097043629759639, -8.18154937460389],
              [-35.097213968549738, -8.181368731381781],
              [-35.097327518749921, -8.181267133171616],
              [-35.097446732880371, -8.181171184502489],
              [-35.097707927478837, -8.180888927781723],
              [-35.097872559171208, -8.180747815159521],
              [-35.098014530646026, -8.180578446737854],
              [-35.09820192687738, -8.180352616180244],
              [-35.098536925238875, -8.180013922504486],
              [-35.098735598968688, -8.179861540477646],
              [-35.098934243920155, -8.179748719627639],
              [-35.099087478504259, -8.179675409592448],
              [-35.099280414559416, -8.179602120241611],
              [-35.099456283280801, -8.179585327201039],
              [-35.10040916714928, -8.179744357915752],
              [-35.101685430138446, -8.17986410264615],
              [-35.102746085163631, -8.180034520576612],
              [-35.104493110868304, -8.180239415816345],
              [-35.106064329005562, -8.180387654666504],
              [-35.107476714278903, -8.180535753222387],
              [-35.107697892643614, -8.180598086132704],
              [-35.1078169826676, -8.180643388581473],
              [-35.108219498531014, -8.180931869510118],
              [-35.108246516863233, -8.180961861900357],
              [-35.108247190455415, -8.180962343936983],
              [-35.108649575917127, -8.181409015613553],
              [-35.108893227440433, -8.181731263387064],
              [-35.109012172673602, -8.181946064659087],
              [-35.109125368540901, -8.182262544181015],
              [-35.109176255779516, -8.182449029399152],
              [-35.109181738946063, -8.182680685764979],
              [-35.109101890839554, -8.183177797717121],
              [-35.108840470511268, -8.183736920622323],
              [-35.108698231937808, -8.184233980413808],
              [-35.108652603934502, -8.184522082727881],
              [-35.108658043034211, -8.184798937221103],
              [-35.108714524955303, -8.185081471832405],
              [-35.108771115694367, -8.185251020118415],
              [-35.108827712728726, -8.185403611687263],
              [-35.108872953124262, -8.18557313928892],
              [-35.108901189138521, -8.185720065128862],
              [-35.10888978213768, -8.185793497471877],
              [-35.108861366259021, -8.185855623538146],
              [-35.108702431771746, -8.185968489354252],
              [-35.108549250247677, -8.185990963412166],
              [-35.108333618901327, -8.186058573544301],
              [-35.108242800864502, -8.18612065194651],
              [-35.108151962143268, -8.186216619501351],
              [-35.108044111846255, -8.186295627641073],
              [-35.107896578963903, -8.18633505212091],
              [-35.107658321574263, -8.186340505609792],
              [-35.107266816110609, -8.186430574511348],
              [-35.107045513639491, -8.186498187341442],
              [-35.106858209138977, -8.186622325260032],
              [-35.106619757495764, -8.186853770285211],
              [-35.106540219655358, -8.186989301714846],
              [-35.106471973702583, -8.187192638429451],
              [-35.106454681538253, -8.187509015428645],
              [-35.106697795266996, -8.188475344161759],
              [-35.10675431765759, -8.18870703290321],
              [-35.106680548068823, -8.188746523932878],
            ],
            [
              [-35.094643734021076, -8.208451347151879],
              [-35.094509252999558, -8.208604546662457],
              [-35.094503687095425, -8.208615873623614],
              [-35.09446448474813, -8.208666880472094],
              [-35.09443730993388, -8.208796558214544],
              [-35.094392480528484, -8.208847684672889],
              [-35.094313542535929, -8.208882084640436],
              [-35.094303632906303, -8.209034182481503],
              [-35.094304527731708, -8.209135602625906],
              [-35.094294199917329, -8.209242685801257],
              [-35.09423790955185, -8.209288175308984],
              [-35.094238059738643, -8.209299385358293],
              [-35.094227347014396, -8.209367057543602],
              [-35.09417680421609, -8.209418212780236],
              [-35.094166741701713, -8.209559011539113],
              [-35.094156237620211, -8.20964928368744],
              [-35.094100397815055, -8.209728578710894],
              [-35.094089769627146, -8.209813065520507],
              [-35.09408498073725, -8.209903214461747],
              [-35.094057567602647, -8.210004781195664],
              [-35.093985265707431, -8.210146176205001],
              [-35.093952524988993, -8.210287274438144],
              [-35.0939529116975, -8.210326684528809],
              [-35.093989021276286, -8.210568489743935],
              [-35.094052217535761, -8.210663709962205],
              [-35.094129230979206, -8.210798669423014],
              [-35.09427615322187, -8.210912523594061],
              [-35.094390246612534, -8.211007300446511],
              [-35.09446418797188, -8.211040452155089],
              [-35.094492675796289, -8.211057119290558],
              [-35.094725327711672, -8.211111415859136],
              [-35.094917943608152, -8.211126776279627],
              [-35.094929286757122, -8.211126627891531],
              [-35.095002839609322, -8.211120369509388],
              [-35.095070736625132, -8.211108535330595],
              [-35.095149942615706, -8.211107944475998],
              [-35.095251923037779, -8.211107054147192],
              [-35.095387715270817, -8.211100291245373],
              [-35.09550663352428, -8.211093617376491],
              [-35.095636832873481, -8.211092578659532],
              [-35.09614565309937, -8.211003881224087],
              [-35.096360207057842, -8.210928823806114],
              [-35.09649040370914, -8.210927783290515],
              [-35.096563957460603, -8.210921524908429],
              [-35.096614947222008, -8.210921080643288],
              [-35.09671120975446, -8.210920310823667],
              [-35.096773601120731, -8.210931014554603],
              [-35.096790458013174, -8.210959772175727],
              [-35.096801374883512, -8.210961645463499],
              [-35.096847335635971, -8.211040050158147],
              [-35.096825845436399, -8.211164187177474],
              [-35.096679426814035, -8.211255434190889],
              [-35.096640226265379, -8.211306440140049],
              [-35.096528307435392, -8.211453827332207],
              [-35.096522711853652, -8.211459551517066],
              [-35.096505983564271, -8.211487840591303],
              [-35.09638808064534, -8.211601449247439],
              [-35.096371266920414, -8.211612836463189],
              [-35.096326886276756, -8.211714582162188],
              [-35.096304470674738, -8.211748507287723],
              [-35.096259434425292, -8.21177703558169],
              [-35.096175556456728, -8.211890375340488],
              [-35.096147663983459, -8.211935630125197],
              [-35.096131026525597, -8.211980824655313],
              [-35.096114505979642, -8.212031622861048],
              [-35.096092392549849, -8.212088145251641],
              [-35.096065396700681, -8.212234817482795],
              [-35.096060456724672, -8.212313670939125],
              [-35.096060934264642, -8.212369985586292],
              [-35.096061680701951, -8.212443199394102],
              [-35.096091270195927, -8.212583704973952],
              [-35.096125889598056, -8.212662258956016],
              [-35.096183549631064, -8.212774411609757],
              [-35.096212274876564, -8.212819189753759],
              [-35.096235253454154, -8.212858391201735],
              [-35.09627574003332, -8.2129482154877],
              [-35.096276426216036, -8.213027038367102],
              [-35.096317715889825, -8.213201197477247],
              [-35.096317923633251, -8.213223705709424],
              [-35.096318012666131, -8.213240609366608],
              [-35.096318162852867, -8.2132519066501],
              [-35.096327179455727, -8.213286647460791],
              [-35.096370573543084, -8.213321912576134],
              [-35.096373934309554, -8.213332704440688],
              [-35.096398354500423, -8.213352552478284],
              [-35.09643333363141, -8.213464915573354],
              [-35.096433542274099, -8.213487422906212],
              [-35.096434376844968, -8.213577542169787],
              [-35.09641818365219, -8.213673355940557],
              [-35.096403128101826, -8.213881889837296],
              [-35.096392563765789, -8.213960770273275],
              [-35.096365208187763, -8.214073636988701],
              [-35.096331782185928, -8.214135822410242],
              [-35.096270616595632, -8.214254648932865],
              [-35.096255257973723, -8.214440584665226],
              [-35.096173111200073, -8.214745374799179],
              [-35.096162812163982, -8.214858063448844],
              [-35.096164156650445, -8.215010102833958],
              [-35.096165228642349, -8.215128335804025],
              [-35.096166243976938, -8.215235269691902],
              [-35.096184225921263, -8.21534211364758],
              [-35.096169077740683, -8.215550647544319],
              [-35.096153991613392, -8.215753483336528],
              [-35.096120982896991, -8.215860683423728],
              [-35.096082674475781, -8.216013112215308],
              [-35.09607728753673, -8.216041252901448],
              [-35.09592212300754, -8.21641982611402],
              [-35.095860269435832, -8.216459833354577],
              [-35.09579853367535, -8.216522257995791],
              [-35.095742812580681, -8.216624061251366],
              [-35.095620414850202, -8.216867229838726],
              [-35.095592554752557, -8.21691818092927],
              [-35.095491827086107, -8.217054115255166],
              [-35.095413722765045, -8.217178727116504],
              [-35.095319215709196, -8.217376555483611],
              [-35.095296893636714, -8.217410570541347],
              [-35.095274986151651, -8.217489601164118],
              [-35.095252930278491, -8.217557329107422],
              [-35.095214562502008, -8.217698368884555],
              [-35.095197804535019, -8.217721052484535],
              [-35.095170212435335, -8.217805809990068],
              [-35.095131966067356, -8.217952539777855],
              [-35.095087406458617, -8.21803738271899],
              [-35.095026116761858, -8.218150517432377],
              [-35.094914644894914, -8.218348521167286],
              [-35.094886934084684, -8.218410677810482],
              [-35.094794126747502, -8.218794350179053],
              [-35.094749807257756, -8.21890739675888],
              [-35.094728736142201, -8.219076456713083],
              [-35.094707485162246, -8.219228703841566],
              [-35.094692784844085, -8.219487856978958],
              [-35.09465477679646, -8.219662700473179],
              [-35.094697676256715, -8.220022706282805],
              [-35.094675442317794, -8.220073624997724],
              [-35.094648625433649, -8.22023711095386],
              [-35.09463279017109, -8.220366729341038],
              [-35.094592259525086, -8.22089078857897],
              [-35.094536507853491, -8.220986989957566],
              [-35.094503647525244, -8.221105488227636],
              [-35.094431675681221, -8.221291988734265],
              [-35.094409587432494, -8.221354116599173],
              [-35.094387891288136, -8.221455652756106],
              [-35.094354910450704, -8.221568548249877],
              [-35.09430556015343, -8.221743451998691],
              [-35.094217095642421, -8.221986356185369],
              [-35.094145480829241, -8.222206572275468],
              [-35.094091580861743, -8.222499826805517],
              [-35.094087175982338, -8.222646292192564],
              [-35.09407208895567, -8.222849129783469],
              [-35.094073223000805, -8.222978661835725],
              [-35.09407513226148, -8.223187014968744],
              [-35.094100288997083, -8.223468289629807],
              [-35.094233236674484, -8.223765633377241],
              [-35.094296673952272, -8.223888963704667],
              [-35.094377316160148, -8.224040319605024],
              [-35.094428993003589, -8.224118788151486],
              [-35.094492043572927, -8.224202708388248],
              [-35.094507678286675, -8.224225544872922],
              [-35.094623694427924, -8.224266389382308],
              [-35.09494687929498, -8.224314425770103],
              [-35.095020552656308, -8.224313771962954],
              [-35.095133578551781, -8.224290312248058],
              [-35.095296759637677, -8.224176289903824],
              [-35.095459703302595, -8.224034150255761],
              [-35.095561146829425, -8.223982640686245],
              [-35.095628479071024, -8.223914494558244],
              [-35.095638697168113, -8.223907107526941],
              [-35.09574662390753, -8.2238290895408],
              [-35.095841816246775, -8.223709994120895],
              [-35.095914506649137, -8.223608008302904],
              [-35.095959276699205, -8.223545674493209],
              [-35.096160613321615, -8.223268106837622],
              [-35.096239287812864, -8.223199899555652],
              [-35.096413838128058, -8.223091421531819],
              [-35.096442147886762, -8.223091183211466],
              [-35.096634743098889, -8.22310084642686],
              [-35.096719816266443, -8.223111344213123],
              [-35.096810934676853, -8.223166915120999],
              [-35.096816680445386, -8.223172488219689],
              [-35.096952865682169, -8.223205136307968],
              [-35.096998351592617, -8.22322731898555],
              [-35.097191061917954, -8.223242585876562],
              [-35.097259139697485, -8.223264560810776],
              [-35.09735615496254, -8.223336914866707],
              [-35.097464240881948, -8.223392306809444],
              [-35.097481419731707, -8.223417512108426],
              [-35.09749205511423, -8.223422962899349],
              [-35.097519347739649, -8.223463005213489],
              [-35.097561461192413, -8.22350416088824],
              [-35.097612932091124, -8.223560030371061],
              [-35.097744504705076, -8.223699638427547],
              [-35.09774568821291, -8.223702033322127],
              [-35.097772321635318, -8.223730294517452],
              [-35.097830012245254, -8.223847107457971],
              [-35.097846010285082, -8.223870476341347],
              [-35.097852815455042, -8.223870439469181],
              [-35.097887286469074, -8.223920792510512],
              [-35.098014078287065, -8.22409897518753],
              [-35.098054156574051, -8.224125059124106],
              [-35.098059319581864, -8.22414096813111],
              [-35.098081973504236, -8.224155713415371],
              [-35.098111117833753, -8.224245503527129],
              [-35.098146156320013, -8.224369162107109],
              [-35.098136011067993, -8.224493059906763],
              [-35.098119311556957, -8.224527044387514],
              [-35.098024775722763, -8.224719267280307],
              [-35.098014302218246, -8.224815051373469],
              [-35.09800329091911, -8.224843313468114],
              [-35.097998439076662, -8.22493906878293],
              [-35.097976952474312, -8.225063205802257],
              [-35.097870865747836, -8.225232978918768],
              [-35.097831783909669, -8.22528958944298],
              [-35.097759096205266, -8.225391573462275],
              [-35.097714327953838, -8.225453909969929],
              [-35.097563593485006, -8.225691713201968],
              [-35.097524628558745, -8.22577083285762],
              [-35.097429406541835, -8.22588423377033],
              [-35.097368507150861, -8.226036780373079],
              [-35.097267688652892, -8.226155904571272],
              [-35.097132905459148, -8.226286594950352],
              [-35.096986603748746, -8.226383540967618],
              [-35.096856936798133, -8.226452194313254],
              [-35.096710424646346, -8.226526630299702],
              [-35.096535512803655, -8.226601306405144],
              [-35.096258679894106, -8.22667117743282],
              [-35.096157052905596, -8.226705787841809],
              [-35.095998659810277, -8.226729665741459],
              [-35.095891618903067, -8.226786900395098],
              [-35.095762312580632, -8.226889359256461],
              [-35.095542720620017, -8.22702627923934],
              [-35.095469645307844, -8.22708885226865],
              [-35.095345724125821, -8.227163081411049],
              [-35.095244724864131, -8.227265302851379],
              [-35.095200135577727, -8.227344541217576],
              [-35.095155933899207, -8.227463100641558],
              [-35.095156589504938, -8.227536318945909],
              [-35.095214493254218, -8.227676673439703],
              [-35.095252881715055, -8.227729158773627],
              [-35.095346430992947, -8.227779761826525],
              [-35.095517301282371, -8.227885267590977],
              [-35.095642293556978, -8.227929272318022],
              [-35.095656626052403, -8.227955200671943],
              [-35.095670121279056, -8.227959950890977],
              [-35.095710428893142, -8.228032873318398],
              [-35.09578529745346, -8.228173048847054],
              [-35.09585436178935, -8.228296348597553],
              [-35.095888835501341, -8.228346701638941],
              [-35.09596379399386, -8.228503780824781],
              [-35.096034498693143, -8.22880162999121],
              [-35.096002087126578, -8.228970749300572],
              [-35.096100088048559, -8.22916142625877],
              [-35.096134858536857, -8.229251191189462],
              [-35.096210147979889, -8.229436381383834],
              [-35.096263378851802, -8.229689394849629],
              [-35.096339055003341, -8.229913995134723],
              [-35.09636778204748, -8.229958772379405],
              [-35.096442155980696, -8.230141712470015],
              [-35.09662682996418, -8.230336726858411],
              [-35.096627225665884, -8.230338427476397],
              [-35.096654665780136, -8.230367404532046],
              [-35.096666456791525, -8.230418054349684],
              [-35.096697213605466, -8.230693693858825],
              [-35.096760891901624, -8.230845226925624],
              [-35.096807633265598, -8.231002453600297],
              [-35.096842584517617, -8.231109210321733],
              [-35.096849613618758, -8.231255523723405],
              [-35.096845331946497, -8.231407592786127],
              [-35.096840155448774, -8.231458331636645],
              [-35.096927262882843, -8.231699686291449],
              [-35.096951558967248, -8.231885325247504],
              [-35.096959035930695, -8.232082349620669],
              [-35.096964812276212, -8.232093620823889],
              [-35.09700059989774, -8.23229031514586],
              [-35.097018614217632, -8.232402855407429],
              [-35.097099499242461, -8.232582321416942],
              [-35.097175207769567, -8.23281261710855],
              [-35.097181221536118, -8.232851908488726],
              [-35.097199653141445, -8.233009462516634],
              [-35.097202521978772, -8.233330444944045],
              [-35.097125671312654, -8.233590101701793],
              [-35.09705300878926, -8.23369769029614],
              [-35.096980110643528, -8.233777168781273],
              [-35.096855800055096, -8.233811985134935],
              [-35.096725147447557, -8.2337624073092],
              [-35.096242305038288, -8.23360311758978],
              [-35.096122904748199, -8.233553479509453],
              [-35.096060270564976, -8.233514574837727],
              [-35.095934856508393, -8.233425555444967],
              [-35.095889312141992, -8.233392075483835],
              [-35.095683667766991, -8.233196716655129],
              [-35.095376190458751, -8.233013548136682],
              [-35.095166234733881, -8.232964563863504],
              [-35.095007184234134, -8.232915224358067],
              [-35.094841878050204, -8.232798387135801],
              [-35.094607658616496, -8.232569459313027],
              [-35.094368619715965, -8.23244198131232],
              [-35.094226505248969, -8.232386858266864],
              [-35.093936324601657, -8.232231621791868],
              [-35.093718953067196, -8.231996909529698],
              [-35.093616461830209, -8.231935884233621],
              [-35.093601519594415, -8.231912862488571],
              [-35.093588625114933, -8.231905183177616],
              [-35.093493420185155, -8.231758483966814],
              [-35.093125608260152, -8.231512040947905],
              [-35.092949261998797, -8.231417770413714],
              [-35.092898031219136, -8.231390015536761],
              [-35.092676824076136, -8.231358082409542],
              [-35.092506253260922, -8.231275081279932],
              [-35.092420996631631, -8.231247590803605],
              [-35.092429605841573, -8.231228634893512],
              [-35.092393163513634, -8.231216883452362],
              [-35.09240650045956, -8.231187513392968],
              [-35.0923300544884, -8.231208923552913],
              [-35.092228161300568, -8.231209816579735],
              [-35.092109000230153, -8.231188287709244],
              [-35.091966617765138, -8.231099356450102],
              [-35.091875735876442, -8.231071987382279],
              [-35.091756456994858, -8.23102795117893],
              [-35.091483750174859, -8.230934545792593],
              [-35.091398586175785, -8.230907054417003],
              [-35.091313448256983, -8.230885261145829],
              [-35.091290763757684, -8.230885468889255],
              [-35.09122875909992, -8.230914087115366],
              [-35.091201778539187, -8.23088298046514],
              [-35.091200927780562, -8.230883373468885],
              [-35.091163462923419, -8.23084017993034],
              [-35.091125908134131, -8.230819253605603],
              [-35.090972307525931, -8.230736073510911],
              [-35.090749965438533, -8.230574605633478],
              [-35.09057323426731, -8.230441014940823],
              [-35.090442313661811, -8.230374530759946],
              [-35.090209559223752, -8.2303032433],
              [-35.090188998923111, -8.230274750978936],
              [-35.090181729703033, -8.230272524257543],
              [-35.090091116711619, -8.230146946524314],
              [-35.089969655175423, -8.230080034266109],
              [-35.089912887269861, -8.230069212723947],
              [-35.089878803863655, -8.230058269773281],
              [-35.089799591577844, -8.230058861527198],
              [-35.089714098426839, -8.230003350873915],
              [-35.089611604491893, -8.229942234746318],
              [-35.089469643808968, -8.229898405387075],
              [-35.089389893728594, -8.229848378799602],
              [-35.089236291321754, -8.229765198704911],
              [-35.08916213772244, -8.229709446133995],
              [-35.089099507136496, -8.22967063049515],
              [-35.088997223642934, -8.229632020801091],
              [-35.088844756180492, -8.229678373657975],
              [-35.088668738171691, -8.2296291177895],
              [-35.088503494040992, -8.229523577850784],
              [-35.088423837490041, -8.229473551263311],
              [-35.088201914486717, -8.229357096252954],
              [-35.087997023743583, -8.229251854889128],
              [-35.087808443105132, -8.229056312598686],
              [-35.087805237022053, -8.229051104624716],
              [-35.087780621678291, -8.229025580965754],
              [-35.087694413566282, -8.228885550227915],
              [-35.087681553261007, -8.228716755573771],
              [-35.087680570302041, -8.228615427160207],
              [-35.087742665791325, -8.228586719901159],
              [-35.087804433927431, -8.228529901633578],
              [-35.087877572192156, -8.228478629485096],
              [-35.087905196667464, -8.228399568285397],
              [-35.08792710415247, -8.228320540360585],
              [-35.087926269581658, -8.228230421996329],
              [-35.087931330066795, -8.228157171316354],
              [-35.087936153130897, -8.228072717781629],
              [-35.087901739673498, -8.228016668434464],
              [-35.087821577703608, -8.227921625382635],
              [-35.087701313165041, -8.22777616813562],
              [-35.087656063776251, -8.227701187159994],
              [-35.087580500040019, -8.227650470792526],
              [-35.087363970270985, -8.227505873297389],
              [-35.087324020587062, -8.227466849915118],
              [-35.087261300068917, -8.22742794164617],
              [-35.087119101964959, -8.227355911346194],
              [-35.087073615155191, -8.227333728668611],
              [-35.086977136785379, -8.227312082886272],
              [-35.086874856889096, -8.227273471393517],
              [-35.086704617024395, -8.227235483130983],
              [-35.086642372247638, -8.227235986751339],
              [-35.086568696188351, -8.227236639659168],
              [-35.086472428259924, -8.227237409478846],
              [-35.086381933079679, -8.227249450501688],
              [-35.086313974909956, -8.227249985598291],
              [-35.086212018769629, -8.227239575945646],
              [-35.0861321247977, -8.227178338409544],
              [-35.086121687266029, -8.227160917642209],
              [-35.086104310565418, -8.227147600481317],
              [-35.08606403262894, -8.22708037346041],
              [-35.086046114536487, -8.226967921332459],
              [-35.086045368998555, -8.226894704826748],
              [-35.086032838744472, -8.226754022979719],
              [-35.085997891089733, -8.226647352593204],
              [-35.085955821703749, -8.226583475546931],
              [-35.08591121622959, -8.22654390447758],
              [-35.085820275884998, -8.226505235428249],
              [-35.085718234309013, -8.226494824876227],
              [-35.085616576743575, -8.226523826213565],
              [-35.085582700181419, -8.226535393293716],
              [-35.085244361737864, -8.226695884507421],
              [-35.085176581633903, -8.226713319663929],
              [-35.085041287625302, -8.226781999989271],
              [-35.084872325697233, -8.226884753828301],
              [-35.084827109583387, -8.226896469296548],
              [-35.084758970649943, -8.226880097138746],
              [-35.084577178993584, -8.22681966089857],
              [-35.084469480681946, -8.226803674549899],
              [-35.084248037017232, -8.22674362411891],
              [-35.084071724930141, -8.226654954562434],
              [-35.084044382841967, -8.226624438766805],
              [-35.084043916093833, -8.226624203144411],
              [-35.084003847699364, -8.226579483456362],
              [-35.083848611224369, -8.226321752147101],
              [-35.083761692647954, -8.226097294854242],
              [-35.083720404772805, -8.225923042214561],
              [-35.08366119790594, -8.225636340144945],
              [-35.083655006972947, -8.225580143308946],
              [-35.083642744716826, -8.225473178844084],
              [-35.083641644846011, -8.225349339500383],
              [-35.083674301028111, -8.225208333897456],
              [-35.08382587726237, -8.225060652626951],
              [-35.083887555466276, -8.224986934299523],
              [-35.083943102991782, -8.224868136555187],
              [-35.083985193961496, -8.224829850617084],
              [-35.083743692717178, -8.2248276535733],
              [-35.083781969662141, -8.22479750560035],
              [-35.083715890176165, -8.224796903953916],
              [-35.083766494128383, -8.224757047799471],
              [-35.083868060862301, -8.224711144603589],
              [-35.083981057979372, -8.224681993978777],
              [-35.084161809120133, -8.22462980452184],
              [-35.084303142076294, -8.224606111882508],
              [-35.084450043634547, -8.2245710913827],
              [-35.084681608269534, -8.224501644835016],
              [-35.084771892109075, -8.224467095579939],
              [-35.085014765718824, -8.224391804338268],
              [-35.08513934160726, -8.224390798896195],
              [-35.085241177238515, -8.2243786994174],
              [-35.08539968724574, -8.224377337843805],
              [-35.08555257289288, -8.224376093182116],
              [-35.085648842619946, -8.224375324261757],
              [-35.085818813587309, -8.22437950880726],
              [-35.085954612115586, -8.224372746804761],
              [-35.086124016510098, -8.22432061580372],
              [-35.086429106118317, -8.224239216366698],
              [-35.086468725751047, -8.224233315914773],
              [-35.086530911172588, -8.224221512312909],
              [-35.086581574480022, -8.224192956139973],
              [-35.086767887028316, -8.224129436124542],
              [-35.086892167939141, -8.224089019692485],
              [-35.087067436812731, -8.224064970921631],
              [-35.08714099326221, -8.224058710740849],
              [-35.087180642572548, -8.224058415763238],
              [-35.087271282543611, -8.224057674721848],
              [-35.087384519779732, -8.224056728635048],
              [-35.087469447257149, -8.224056015472684],
              [-35.087537404527552, -8.224055393141839],
              [-35.087628075974862, -8.224060256675443],
              [-35.087718628711684, -8.22405951653343],
              [-35.08779798758701, -8.224070133929501],
              [-35.08783778798346, -8.224081044504544],
              [-35.087883094928827, -8.224086324424263],
              [-35.087945605904963, -8.22410272446109],
              [-35.088019217213059, -8.224107676128313],
              [-35.088070213269702, -8.224107321795429],
              [-35.088172104658838, -8.224106430567247],
              [-35.088234318858667, -8.224100322371953],
              [-35.088336151791907, -8.224088133860221],
              [-35.088426615495848, -8.224070490960344],
              [-35.088466028284529, -8.224041992344041],
              [-35.088516721269627, -8.224019038947461],
              [-35.088567534763854, -8.224001781856714],
              [-35.088590156310602, -8.223990273232459],
              [-35.088607095940688, -8.223984580523904],
              [-35.088657550605433, -8.223933427985287],
              [-35.088730478428772, -8.223859648503947],
              [-35.088826449580949, -8.223819375963387],
              [-35.088899974554124, -8.223807514804889],
              [-35.088967546015397, -8.223767571416261],
              [-35.089035148053597, -8.22373322990461],
              [-35.089096913491744, -8.22367641163703],
              [-35.089181365227773, -8.223619386525399],
              [-35.089231939502383, -8.223573836763137],
              [-35.089417595545569, -8.223437187476179],
              [-35.089580809906408, -8.223328860538459],
              [-35.089603341520956, -8.223317353712844],
              [-35.089761078111223, -8.223220352836961],
              [-35.089845351781491, -8.223146422269508],
              [-35.089884792449141, -8.223123530926159],
              [-35.089924263693774, -8.223106331392046],
              [-35.08996930084254, -8.223077801299439],
              [-35.090019933573046, -8.223043642350149],
              [-35.090042200786911, -8.222998329109544],
              [-35.090194848113697, -8.222968970741363],
              [-35.090234495625452, -8.222968583133536],
              [-35.090319214460123, -8.222945365336273],
              [-35.09041530252415, -8.222927690960148],
              [-35.090505825583364, -8.222921255411563],
              [-35.090596287488609, -8.22290360981367],
              [-35.090714880187534, -8.222868826734896],
              [-35.090816741899062, -8.222862330931775],
              [-35.09094126023092, -8.222850023709555],
              [-35.091093992993365, -8.222837569897877],
              [-35.091184605085402, -8.222831133450029],
              [-35.091314807132619, -8.222830006599452],
              [-35.091546812435411, -8.222811175695142],
              [-35.091681835748091, -8.222708686256794],
              [-35.091681417563336, -8.222663670691816],
              [-35.091629533875846, -8.222562788341975],
              [-35.091562753817982, -8.222469742684439],
              [-35.091502905734444, -8.222420180147139],
              [-35.091482653901323, -8.222395741969876],
              [-35.091475099596153, -8.222389486285692],
              [-35.091396888255758, -8.222295105134947],
              [-35.091348410300839, -8.222252572598109],
              [-35.091246040472356, -8.222197061944826],
              [-35.091148998227652, -8.222119098817302],
              [-35.091069013424203, -8.222040870389833],
              [-35.09097765579412, -8.221957185775466],
              [-35.090968577138085, -8.221943640186794],
              [-35.090949853253107, -8.221926490115379],
              [-35.090852275012423, -8.221780917755098],
              [-35.090805926652138, -8.221663008540986],
              [-35.090748477060515, -8.221573451353663],
              [-35.090656912586383, -8.221467169474238],
              [-35.090513046240062, -8.221214985183394],
              [-35.090463539460757, -8.221159234411118],
              [-35.09037509923138, -8.221078134448305],
              [-35.090366806582836, -8.221065326303744],
              [-35.090347300287647, -8.221047436989579],
              [-35.09029187057331, -8.220961815235512],
              [-35.090169437769305, -8.22087716654795],
              [-35.0900558462003, -8.220844309816982],
              [-35.089999198803923, -8.220839180983376],
              [-35.089972358537466, -8.220808567161612],
              [-35.089971400759509, -8.22080848082669],
              [-35.08993707993227, -8.220769336935291],
              [-35.089898150978854, -8.22072047676852],
              [-35.089878967540301, -8.220699417344179],
              [-35.089878413557869, -8.22069862594077],
              [-35.08985117129447, -8.220668717187493],
              [-35.089776665160912, -8.220562347174564],
              [-35.089673400506967, -8.220422404570286],
              [-35.089616158658771, -8.22035535741378],
              [-35.089575555167698, -8.220243023996318],
              [-35.089546471992037, -8.220164440336589],
              [-35.089511526135993, -8.220057772648033],
              [-35.08948208503017, -8.219928477117492],
              [-35.089469703164241, -8.219815996211196],
              [-35.089468214786223, -8.219652659542533],
              [-35.089494975013054, -8.219477874504264],
              [-35.089500301697512, -8.219438434735935],
              [-35.089521792796461, -8.219314388548128],
              [-35.089565461177074, -8.219128216294109],
              [-35.089598265747327, -8.21899842074049],
              [-35.089636274694271, -8.218823485515372],
              [-35.089641424212289, -8.21876714029122],
              [-35.089651635114819, -8.218637550682388],
              [-35.089678246953497, -8.218451559192033],
              [-35.089705096213152, -8.218293587878748],
              [-35.089726706022645, -8.218175146265935],
              [-35.089759358607466, -8.218034140663008],
              [-35.08983630550108, -8.21777448210662],
              [-35.089869673946225, -8.217700999401529],
              [-35.089897355978167, -8.217633148251139],
              [-35.08998010799553, -8.217390365472966],
              [-35.090109385539677, -8.21728230563383],
              [-35.090160226912872, -8.217270561387238],
              [-35.090335465209535, -8.217240996175008],
              [-35.090363770471583, -8.217240761451933],
              [-35.090460036601371, -8.217239900800735],
              [-35.090477123720234, -8.217245415443529],
              [-35.090488465070564, -8.217245357886895],
              [-35.090783157616613, -8.21727664170362],
              [-35.090817178969587, -8.217276375504298],
              [-35.090948034823896, -8.217348467857448],
              [-35.091056087468417, -8.217398258822527],
              [-35.091077363629381, -8.217425942653108],
              [-35.091083876519633, -8.217428943690777],
              [-35.091118962669952, -8.217474598673732],
              [-35.091141878294991, -8.217493271297371],
              [-35.0911875422712, -8.217532267699937],
              [-35.091261689575276, -8.217588016673574],
              [-35.091286099873571, -8.217616160956993],
              [-35.091289478626493, -8.217618700642447],
              [-35.091326490225299, -8.217661372574241],
              [-35.091392872782819, -8.217688217337297],
              [-35.091461335472218, -8.217749605959511],
              [-35.09148370161148, -8.217775424596141],
              [-35.091489127221394, -8.217780288129745],
              [-35.091526417610055, -8.217823336877473],
              [-35.091603922083266, -8.217861046350151],
              [-35.091768951276038, -8.217944169787586],
              [-35.091842592261798, -8.217954816861265],
              [-35.09196153929355, -8.217953747567321],
              [-35.092040690425506, -8.217941857630592],
              [-35.092079506064294, -8.217851439792071],
              [-35.092112012059658, -8.217682229651132],
              [-35.092111235944742, -8.217603407671106],
              [-35.092064917262064, -8.217491193863452],
              [-35.092018599478763, -8.217378892821614],
              [-35.091984009754185, -8.217306030648729],
              [-35.091954629802274, -8.217188034200376],
              [-35.091924681478815, -8.217013727601397],
              [-35.091894437278427, -8.216800005515779],
              [-35.091887381197637, -8.21664799580833],
              [-35.091846598741483, -8.216518848665885],
              [-35.091834365263708, -8.216417577808897],
              [-35.091821504958432, -8.216248786752033],
              [-35.091768784901433, -8.216057689810498],
              [-35.091756074783007, -8.215900193339223],
              [-35.091755211433792, -8.215804468601277],
              [-35.091714726653265, -8.215714645214632],
              [-35.091668560855339, -8.215613731388544],
              [-35.09164947724156, -8.215382955459006],
              [-35.091607236085054, -8.215096168853051],
              [-35.091662630725807, -8.214966162858104],
              [-35.091717698912646, -8.214791138600106],
              [-35.09172865175583, -8.214751669154168],
              [-35.091750025942872, -8.2146051174322],
              [-35.091701620833078, -8.214267646834401],
              [-35.091683848430819, -8.214183309312205],
              [-35.091677331943288, -8.214082009676986],
              [-35.091676259951385, -8.213963775807542],
              [-35.091714478440394, -8.213811350613298],
              [-35.091764011300029, -8.213653352320307],
              [-35.091768256999444, -8.213556638328157],
              [-35.09168819665291, -8.213498979194469],
              [-35.09157454752733, -8.21345491241425],
              [-35.091438573631251, -8.213444770759509],
              [-35.091348145000836, -8.213468110864596],
              [-35.091196128098773, -8.213565079364855],
              [-35.091083164256588, -8.213599836363301],
              [-35.091038128007142, -8.213628366455907],
              [-35.090908373822344, -8.213680112547138],
              [-35.090846428519797, -8.213720027157478],
              [-35.090638392847495, -8.213879483251503],
              [-35.090463663567164, -8.213971058517473],
              [-35.0904017488416, -8.214016577702864],
              [-35.090317213468609, -8.214056699157311],
              [-35.09020430808232, -8.214102665305688],
              [-35.090074316476489, -8.214126298589747],
              [-35.089984036234227, -8.214160847844823],
              [-35.089859967563427, -8.214223864239898],
              [-35.089746675468632, -8.214213514841788],
              [-35.089627491915167, -8.21418637959772],
              [-35.089473507296475, -8.214063787613668],
              [-35.089471491915788, -8.214053609986081],
              [-35.089445730835791, -8.214033099148139],
              [-35.089427930554507, -8.213943157950325],
              [-35.089421472522872, -8.213853066565719],
              [-35.089431830914236, -8.213751676998243],
              [-35.089442515759458, -8.213678309406419],
              [-35.08949764420089, -8.213514587827945],
              [-35.089513956104156, -8.213441280490656],
              [-35.089536192741036, -8.213390363574376],
              [-35.089575010178521, -8.213299947534495],
              [-35.089602691311086, -8.213232186316304],
              [-35.089686154692231, -8.21307383279111],
              [-35.089765036926849, -8.213028044708437],
              [-35.089843264455055, -8.212909220883773],
              [-35.089876036649741, -8.212773727225681],
              [-35.089948936594112, -8.212694342270026],
              [-35.090038323809551, -8.212558375568506],
              [-35.090105655151831, -8.212490320272025],
              [-35.090229186028068, -8.212376595603359],
              [-35.090414658609575, -8.212223043558595],
              [-35.090493512965224, -8.212171743531087],
              [-35.090544116018123, -8.212131888275962],
              [-35.090679253544693, -8.212051909767752],
              [-35.090989090673929, -8.211874752317897],
              [-35.091045618460498, -8.211857375617342],
              [-35.091158284627113, -8.211800112185358],
              [-35.091372898840177, -8.21173635654759],
              [-35.09141826514076, -8.211736030992995],
              [-35.091469132594341, -8.211729981253598],
              [-35.091576381244977, -8.211695256630719],
              [-35.09164963732087, -8.211649588157911],
              [-35.091672201310985, -8.211643775839548],
              [-35.091886636558968, -8.21156320647674],
              [-35.091982632892154, -8.211528538511175],
              [-35.092152421297158, -8.211515906633736],
              [-35.0922428805045, -8.211498262834539],
              [-35.092366441058346, -8.211390231773748],
              [-35.092450676957128, -8.211310698429941],
              [-35.092573938936084, -8.211163255479789],
              [-35.092652284275459, -8.211066847257143],
              [-35.092764770577674, -8.21097577651085],
              [-35.092820909857039, -8.210919077853134],
              [-35.092848473178435, -8.210845624825652],
              [-35.092835791838297, -8.210693642997171],
              [-35.09283430076232, -8.210530393562749],
              [-35.09284481023974, -8.210440216742541],
              [-35.092860167063009, -8.210254282808876],
              [-35.092852844782897, -8.210068465787003],
              [-35.092851800670019, -8.209955836492611],
              [-35.092834683873491, -8.209944716375503],
              [-35.092879066315788, -8.209843060608705],
              [-35.09290070670221, -8.209730222671567],
              [-35.092921689684204, -8.209544261758253],
              [-35.092966308648158, -8.209470626168411],
              [-35.092993961002435, -8.209397175838944],
              [-35.093004856289042, -8.209346404612859],
              [-35.093133266886696, -8.209142619136514],
              [-35.093177884951388, -8.209069074378249],
              [-35.093239441746789, -8.208989751475826],
              [-35.093300966166623, -8.208904728670234],
              [-35.093367821767515, -8.208780269693648],
              [-35.093474026305216, -8.208633004809258],
              [-35.093518763979716, -8.208565066424626],
              [-35.093574721596099, -8.208491374177527],
              [-35.093782815724296, -8.20834322166229],
              [-35.093844520907851, -8.208275195144097],
              [-35.094024338552344, -8.208115977370426],
              [-35.094153402957147, -8.20798540929917],
              [-35.094265827206129, -8.207899944926453],
              [-35.094530117271063, -8.207689395649027],
              [-35.094608850218208, -8.207632488348622],
              [-35.094732737226025, -8.207552568296364],
              [-35.094760862623673, -8.207535430815426],
              [-35.094834149276551, -8.207495453252534],
              [-35.094918831238999, -8.207466541847396],
              [-35.095003755119137, -8.207465828684974],
              [-35.095077334051666, -8.207465266608722],
              [-35.095088733857949, -8.207476416403438],
              [-35.095117221682415, -8.207493083538907],
              [-35.095139979026783, -8.207520789852538],
              [-35.095144982854606, -8.207523718944401],
              [-35.09516790117766, -8.207551620410925],
              [-35.095179689491033, -8.207602182994322],
              [-35.095180374774429, -8.207681094906548],
              [-35.09516984101532, -8.20776557991752],
              [-35.095119657046496, -8.207850449838361],
              [-35.095035657669428, -8.207958188619443],
              [-35.094991069282401, -8.208037425187001],
              [-35.094800629745237, -8.208264224314462],
              [-35.094755802138536, -8.208315262639189],
              [-35.094643734021076, -8.208451347151879],
            ],
            [
              [-35.099731688066925, -8.222399158494341],
              [-35.099720107496921, -8.222371106841081],
              [-35.099679980646556, -8.222315089869483],
              [-35.099662445665274, -8.222258954187339],
              [-35.099634303180494, -8.222181759980174],
              [-35.09942773700044, -8.22206090548633],
              [-35.099393533085049, -8.222044268927846],
              [-35.099308309730645, -8.222005572898809],
              [-35.099211416773414, -8.221938823417929],
              [-35.099137473615485, -8.221905672608671],
              [-35.099046174441355, -8.221833197144292],
              [-35.098972142250545, -8.22178314267785],
              [-35.098909571019817, -8.221755538886953],
              [-35.098801458120761, -8.221694540570525],
              [-35.098776869756705, -8.221665716399627],
              [-35.098773645687174, -8.221663897970473],
              [-35.098708465523202, -8.221587481676863],
              [-35.098572866644417, -8.221465583969461],
              [-35.09843587561511, -8.221348423890618],
              [-35.09832177772779, -8.221253740567647],
              [-35.098311439121574, -8.22123759144165],
              [-35.098293969790802, -8.221223094370259],
              [-35.098284932503589, -8.221208979510777],
              [-35.098281711132017, -8.221209021778861],
              [-35.098281669763196, -8.221203883952057],
              [-35.09826524364604, -8.221178227193377],
              [-35.09825390319503, -8.22117837648085],
              [-35.098253634297748, -8.221144571864443],
              [-35.098219160585757, -8.221094128890911],
              [-35.098201506893986, -8.22101548497659],
              [-35.098245319166097, -8.220857424630424],
              [-35.098378876583865, -8.220597294829247],
              [-35.098401199555667, -8.220563278872191],
              [-35.098479843469931, -8.220489467914547],
              [-35.098507942787307, -8.220466723160712],
              [-35.098586436514779, -8.220381612221558],
              [-35.098625846605501, -8.220353114504576],
              [-35.09873839496089, -8.220273341941152],
              [-35.098822839502418, -8.220216313232243],
              [-35.098907347895761, -8.220170493673322],
              [-35.099053769216027, -8.22007924576053],
              [-35.099138453876492, -8.220050330758113],
              [-35.099353578904527, -8.220048578878789],
              [-35.099415552985363, -8.220014265246107],
              [-35.09961852997111, -8.219911152577595],
              [-35.099680533729611, -8.219882534351427],
              [-35.09981046508085, -8.219847596589261],
              [-35.099928876116678, -8.219795906256024],
              [-35.100007698096761, -8.21973899985494],
              [-35.100069461736211, -8.219682180688039],
              [-35.100091515810789, -8.219614359215257],
              [-35.100063095435473, -8.219572893274346],
              [-35.100050586765121, -8.21956679317293],
              [-35.100039186059519, -8.219555643378214],
              [-35.099907969577089, -8.219449750905255],
              [-35.099817061608064, -8.219416688229558],
              [-35.099817032829776, -8.219411082755244],
              [-35.09976577417109, -8.219377723303239],
              [-35.099640303457193, -8.219277404828347],
              [-35.099510011477776, -8.219278448941225],
              [-35.099447771197617, -8.219279042493781],
              [-35.099340162818237, -8.219279875266011],
              [-35.099289173056832, -8.219280321329734],
              [-35.099243957842361, -8.219291945966461],
              [-35.099142302974883, -8.219320953599095],
              [-35.098995585777629, -8.219372880454046],
              [-35.098871487429165, -8.219430296770724],
              [-35.098792548537233, -8.219464697637648],
              [-35.098646128116286, -8.219556035482583],
              [-35.098550250493645, -8.219596220788901],
              [-35.098386743853098, -8.219682046689059],
              [-35.098268063919988, -8.219700021438769],
              [-35.098132234814727, -8.219717994389896],
              [-35.098007782133379, -8.219724698835762],
              [-35.097922945487483, -8.219742317453949],
              [-35.097911753424626, -8.219753673193395],
              [-35.097832845109679, -8.219793769466833],
              [-35.097747681110548, -8.219766281688521],
              [-35.09766236872332, -8.219727582961525],
              [-35.09760575190387, -8.219728060501552],
              [-35.097492547942693, -8.219734615659945],
              [-35.097413428287041, -8.219752203701205],
              [-35.097345707538352, -8.219780939738541],
              [-35.097283763135124, -8.219820856147521],
              [-35.097188362153133, -8.219917354302424],
              [-35.097166427688421, -8.219990779450882],
              [-35.097117405643701, -8.220210788696875],
              [-35.097106843106303, -8.220289758165734],
              [-35.097101723265894, -8.22035170796488],
              [-35.097091036621975, -8.220424984725241],
              [-35.09709106809828, -8.220430587501539],
              [-35.097091220983032, -8.220441885684409],
              [-35.097092711159632, -8.220605133320191],
              [-35.097099172788546, -8.220695225604118],
              [-35.097117214088144, -8.220813279609104],
              [-35.097117870593195, -8.220886497913511],
              [-35.097118887726424, -8.22099352083427],
              [-35.097119781652566, -8.221094850147153],
              [-35.097121006529164, -8.22122438130009],
              [-35.097121662134953, -8.221297509672297],
              [-35.097111366696197, -8.221410286455523],
              [-35.097100860816056, -8.221500465973691],
              [-35.097085028251399, -8.221630087958147],
              [-35.097058271621904, -8.221804870298456],
              [-35.097014279485393, -8.221946028786135],
              [-35.09698130314456, -8.222058924279906],
              [-35.096975765119396, -8.222075857614698],
              [-35.096897092426843, -8.222144062198652],
              [-35.096846460595657, -8.222178317375381],
              [-35.096750609952664, -8.222224194490934],
              [-35.096564539322003, -8.222315832709455],
              [-35.096406298212059, -8.222350916161815],
              [-35.096276304807589, -8.222374554841792],
              [-35.096231030237789, -8.222374882195027],
              [-35.096151788274369, -8.222386863862653],
              [-35.096044359759333, -8.22240459759405],
              [-35.09594830317161, -8.222427967376746],
              [-35.095790031484739, -8.222457448952071],
              [-35.095642503098986, -8.222424861118384],
              [-35.095443331144793, -8.222319592774966],
              [-35.095340750874925, -8.222241571191489],
              [-35.095306488503581, -8.222213726382336],
              [-35.095306459725293, -8.222208122706661],
              [-35.095272463553329, -8.222213995279617],
              [-35.095249836610606, -8.222182432673094],
              [-35.095244655616341, -8.222183327498499],
              [-35.095164401915554, -8.222071381688863],
              [-35.09514701981908, -8.222026545988285],
              [-35.095111653080266, -8.221874772802494],
              [-35.09510007251032, -8.221846720249914],
              [-35.0950828954592, -8.221824300151297],
              [-35.095093223273579, -8.221717218774586],
              [-35.09511488974033, -8.221610076243962],
              [-35.095114653218616, -8.221581965235487],
              [-35.095108401131768, -8.221514380284361],
              [-35.095107535983971, -8.221418657345112],
              [-35.095106669037477, -8.221322931707903],
              [-35.095111372491829, -8.22121596814236],
              [-35.095075350147226, -8.220990978450857],
              [-35.095074067713995, -8.220850239946571],
              [-35.095089543247752, -8.220686812446388],
              [-35.095094003885151, -8.22055164524221],
              [-35.095092721451863, -8.220410816805725],
              [-35.095090844566755, -8.220208157280581],
              [-35.095095753965836, -8.220123611115696],
              [-35.095190261021685, -8.219925786345925],
              [-35.095217911577322, -8.21985232972122],
              [-35.095217673256968, -8.219824219612008],
              [-35.095217019449819, -8.2197510013076],
              [-35.095216660620338, -8.219717196691249],
              [-35.095232941047357, -8.219638195746086],
              [-35.09524297028679, -8.219491700681374],
              [-35.095253686608316, -8.219424117528945],
              [-35.095303929033037, -8.21935045765747],
              [-35.09535923284227, -8.219203546206643],
              [-35.095358547558874, -8.219124726924576],
              [-35.095357503445996, -8.219012096730808],
              [-35.095373517673636, -8.218899382000814],
              [-35.095399738307208, -8.218673886890315],
              [-35.095403752880827, -8.218488103143386],
              [-35.095436970239916, -8.218403317758884],
              [-35.095491441276977, -8.218166379674642],
              [-35.095524212572286, -8.21803097684807],
              [-35.095551296555072, -8.21790120557614],
              [-35.095579185431006, -8.217855953489391],
              [-35.095595856163811, -8.217816365332965],
              [-35.095684228943981, -8.217573461146287],
              [-35.095717448101709, -8.217488771989167],
              [-35.095772930876024, -8.217358763296261],
              [-35.095828740104253, -8.217273861899173],
              [-35.095912771856888, -8.217171729491668],
              [-35.096076130109282, -8.217074699837497],
              [-35.096222581107213, -8.216988964768859],
              [-35.096420316844103, -8.216942289954659],
              [-35.096499646041764, -8.216947209246257],
              [-35.096595911272232, -8.21694643672862],
              [-35.09671485920336, -8.216945457366933],
              [-35.096765849864028, -8.216945014001169],
              [-35.09682246668342, -8.216944537360462],
              [-35.097003627015738, -8.216937355374625],
              [-35.097155939794732, -8.216879796965031],
              [-35.097262890769741, -8.216805747687033],
              [-35.097488251881259, -8.216679919942294],
              [-35.097584249113766, -8.21664534011029],
              [-35.097713610294818, -8.216554182129755],
              [-35.097781300466579, -8.216519840618105],
              [-35.097831783010349, -8.216474379888666],
              [-35.097899264539365, -8.216417441111957],
              [-35.097949806438351, -8.216366375807524],
              [-35.098135753861925, -8.216269047578464],
              [-35.09826544779213, -8.216206088739966],
              [-35.098361294837844, -8.216160211624413],
              [-35.098524949866487, -8.216102500330067],
              [-35.098654788587567, -8.216050750641557],
              [-35.098739565878248, -8.216038739296323],
              [-35.098875689061742, -8.216060177335294],
              [-35.09893272226725, -8.216104716259622],
              [-35.0989955596973, -8.216166130962108],
              [-35.099047027897996, -8.21622200044493],
              [-35.0991441600749, -8.216316951765862],
              [-35.099165375981272, -8.216341148924812],
              [-35.099171954522035, -8.216347580876118],
              [-35.099180408149266, -8.216357223407101],
              [-35.099292336871827, -8.216422667971699],
              [-35.099314183202921, -8.2164498158063],
              [-35.099320133117601, -8.216453294383996],
              [-35.099383152210635, -8.216531609146386],
              [-35.09941470672328, -8.216570084841521],
              [-35.099509730889338, -8.216662983909544],
              [-35.099515261719887, -8.216671842231676],
              [-35.099537527135112, -8.216693609422464],
              [-35.09961795980098, -8.216822455292004],
              [-35.099675383312274, -8.216906404307053],
              [-35.099824400975308, -8.217102239776523],
              [-35.09984545950033, -8.217126977427995],
              [-35.099939605928, -8.217228157453462],
              [-35.099985331058122, -8.217278450240258],
              [-35.100087760241877, -8.217328269983625],
              [-35.100167386215844, -8.217372600265264],
              [-35.100218615196866, -8.217400355142217],
              [-35.100383523880453, -8.217477780475122],
              [-35.100434543319466, -8.217483027119954],
              [-35.100548192445103, -8.217527093000911],
              [-35.100650652205786, -8.217582511923354],
              [-35.100883280738799, -8.217631289352482],
              [-35.10090039843459, -8.21764240946959],
              [-35.100934301077075, -8.217636448763074],
              [-35.101092806587701, -8.217635168128481],
              [-35.101115520764608, -8.21764056496005],
              [-35.10129117274937, -8.217656013514159],
              [-35.101331002823486, -8.217672525966236],
              [-35.101422359554192, -8.217756299613484],
              [-35.101570478794542, -8.217850714938493],
              [-35.10164445073076, -8.217889559355626],
              [-35.101684128819443, -8.217894867154371],
              [-35.101735360498424, -8.217922531199804],
              [-35.101792246215155, -8.217955864571422],
              [-35.101860440906592, -8.217983441382671],
              [-35.101894704177198, -8.218011283493865],
              [-35.101984953842475, -8.217971131462491],
              [-35.1019905800012, -8.217971100885563],
              [-35.102041182154778, -8.217931245630439],
              [-35.102063477247611, -8.217891624199069],
              [-35.102108632207546, -8.217868791311616],
              [-35.102249394094201, -8.217788689596318],
              [-35.102339767865942, -8.217754228474803],
              [-35.102373521221011, -8.217736968686097],
              [-35.102424451627087, -8.217725314371705],
              [-35.102520745635843, -8.217730144630423],
              [-35.102554650976344, -8.217724272956787],
              [-35.102628023064767, -8.217701112716156],
              [-35.102650435968826, -8.217733419961291],
              [-35.102655824706517, -8.217731719343305],
              [-35.102787850578807, -8.217922036572702],
              [-35.102850929027113, -8.218011650417282],
              [-35.102868314720922, -8.218056486117916],
              [-35.102937585900804, -8.218202293201216],
              [-35.102960986260484, -8.218286601945067],
              [-35.102984474753669, -8.218370909789598],
              [-35.10298474275163, -8.218404624473806],
              [-35.102991655840185, -8.218545334199746],
              [-35.103004102457305, -8.218669112389591],
              [-35.103016729838146, -8.218809791538604],
              [-35.103006466774957, -8.218928174695463],
              [-35.102984739154351, -8.219024106277459],
              [-35.102962864944232, -8.219108742374488],
              [-35.102890266272709, -8.219227541917462],
              [-35.10281823867075, -8.219402741543263],
              [-35.102734808564549, -8.21956670414005],
              [-35.102619240285662, -8.219933772325703],
              [-35.102625253152894, -8.219973062806559],
              [-35.102625522050175, -8.220006869221606],
              [-35.102620373431421, -8.220063211747799],
              [-35.102615018867994, -8.220097049639094],
              [-35.102610498875379, -8.220221004995324],
              [-35.102605500443474, -8.220288647503025],
              [-35.102606638085831, -8.220418088723761],
              [-35.102607085948193, -8.220468798795991],
              [-35.102618492049714, -8.220543481196671],
              [-35.102659887843515, -8.220583933601631],
              [-35.10266256242727, -8.220589984240348],
              [-35.102687701176421, -8.220614548322715],
              [-35.102739766526952, -8.22073233163178],
              [-35.102818493178859, -8.221002721798129],
              [-35.102827998113582, -8.221010445175864],
              [-35.102845756126726, -8.22103288865685],
              [-35.102855810547169, -8.221041060796267],
              [-35.102953183742386, -8.221164123125732],
              [-35.103022248078219, -8.22128742377555],
              [-35.103039633772028, -8.221332349408328],
              [-35.103080089774267, -8.221416478287779],
              [-35.103091850208671, -8.221461434497542],
              [-35.103115908872041, -8.221618869814961],
              [-35.103121953215521, -8.221663854803012],
              [-35.103110940117745, -8.221692114199641],
              [-35.103094541879557, -8.22176542063761],
              [-35.103050163933858, -8.221867166336608],
              [-35.103044597130406, -8.221878496895044],
              [-35.103033376289261, -8.221884250757512],
              [-35.102976937535573, -8.221901629256763],
              [-35.102920441225365, -8.221924613230271],
              [-35.10280723726413, -8.221931172885263],
              [-35.102756335636343, -8.221931618948986],
              [-35.102619794268037, -8.221865166244356],
              [-35.102438003510997, -8.221804739896754],
              [-35.102392518499869, -8.221782647151372],
              [-35.102256096741428, -8.221721801719752],
              [-35.102056502105825, -8.221571429677681],
              [-35.10204663564366, -8.221554330867605],
              [-35.102028687873599, -8.221540808661359],
              [-35.101976949876303, -8.221451134562187],
              [-35.101942267521565, -8.221378274187941],
              [-35.101924220826106, -8.221354767708306],
              [-35.101901466179697, -8.221336215593851],
              [-35.101787874610693, -8.221303454191002],
              [-35.101753938693264, -8.221303721289644],
              [-35.101720096305371, -8.221320893844108],
              [-35.101641004528688, -8.221344083762403],
              [-35.101556497933984, -8.221389814288443],
              [-35.101483511654692, -8.221452388217074],
              [-35.101410618005559, -8.221531864004248],
              [-35.101332033446567, -8.221616976742041],
              [-35.101298816986798, -8.221701668597063],
              [-35.101220535499294, -8.221809379499234],
              [-35.101164815303946, -8.221911184553505],
              [-35.101002623472255, -8.222126540707222],
              [-35.100947409595221, -8.222290263185073],
              [-35.100858413584888, -8.222465555440976],
              [-35.100774385429474, -8.222567688747802],
              [-35.100729529943806, -8.222613122497535],
              [-35.10049821082373, -8.222710780777845],
              [-35.100328420620087, -8.22272341715194],
              [-35.10025483629164, -8.222724074556368],
              [-35.100113115727652, -8.222708361601576],
              [-35.100067690071796, -8.222697478905502],
              [-35.100022204161348, -8.222675298925878],
              [-35.100056946770621, -8.222759545616498],
              [-35.100039949583959, -8.222754029175064],
              [-35.100012646166647, -8.222723558345479],
              [-35.100012133553093, -8.22272339287025],
              [-35.099993960952474, -8.222703110460145],
              [-35.099919864909793, -8.222625481880414],
              [-35.099907747444547, -8.222611290578527],
              [-35.099892049778248, -8.22259484377696],
              [-35.099834806131355, -8.222527798419094],
              [-35.099731688066925, -8.222399158494341],
            ],
            [
              [-35.097695189481385, -8.21460872821018],
              [-35.097576154316016, -8.21459280571338],
              [-35.097445805679342, -8.214582636179671],
              [-35.097310276947667, -8.214623207295119],
              [-35.097265093209444, -8.214640437406217],
              [-35.097129179567958, -8.214641597531681],
              [-35.09703282350597, -8.214625466392135],
              [-35.096987370870409, -8.214608979121067],
              [-35.096924800539057, -8.21458137263221],
              [-35.096873514001402, -8.214542408605212],
              [-35.096850649637702, -8.214525711792135],
              [-35.096799213812631, -8.214475449582267],
              [-35.096753551635061, -8.21443636594546],
              [-35.096751098284528, -8.214427408697929],
              [-35.096725766181123, -8.214405724244727],
              [-35.096696533717989, -8.214299028677203],
              [-35.096691149476896, -8.214263769857041],
              [-35.096661122912451, -8.214234447461706],
              [-35.096661669700268, -8.214231474303062],
              [-35.096633339257153, -8.214203807559613],
              [-35.096660333307682, -8.214057137127156],
              [-35.096800082557706, -8.21385328959758],
              [-35.09688458915241, -8.213807562668819],
              [-35.096918464815246, -8.213795995588669],
              [-35.096935370271069, -8.213784607473599],
              [-35.096985792560247, -8.213727849460611],
              [-35.097052855904508, -8.213625895118923],
              [-35.097096609720722, -8.213456626522031],
              [-35.097123783635652, -8.213326857948061],
              [-35.097115740998618, -8.213073609759249],
              [-35.097092462047442, -8.212994906489712],
              [-35.097028640759106, -8.212832162474285],
              [-35.096987648760944, -8.212680420764741],
              [-35.096979908296078, -8.212449680808049],
              [-35.097007350209026, -8.212353717749806],
              [-35.097023181874306, -8.212224098463309],
              [-35.097122954460758, -8.211992347668684],
              [-35.097161976943653, -8.21192443716302],
              [-35.097268984575919, -8.21186159883365],
              [-35.097336703526025, -8.211832861896994],
              [-35.097504999056412, -8.211656888854293],
              [-35.097561375756868, -8.211628304802389],
              [-35.097668620810168, -8.211593575682912],
              [-35.097736340659537, -8.211564838746256],
              [-35.097803880644506, -8.211519288983936],
              [-35.097927852188604, -8.211456273488182],
              [-35.098057725083891, -8.211410125676707],
              [-35.098097193630565, -8.211392928840553],
              [-35.098221378313895, -8.211352505213881],
              [-35.098311866299525, -8.211340466888998],
              [-35.098368481320335, -8.211339991147611],
              [-35.098442124104679, -8.211333732765524],
              [-35.098442153782344, -8.211339337340519],
              [-35.098532908866616, -8.211361101833347],
              [-35.098561397590402, -8.211377768968816],
              [-35.098595478298648, -8.211388711919483],
              [-35.098714692429041, -8.211421536274827],
              [-35.098743210830492, -8.211443805287331],
              [-35.098868259762355, -8.211499110895204],
              [-35.098981698446607, -8.211520666745344],
              [-35.099174765802729, -8.211569742749361],
              [-35.099243049527047, -8.211614225915753],
              [-35.099351339592602, -8.211692124292028],
              [-35.099408493307294, -8.211742358622928],
              [-35.099448411514913, -8.211775776530828],
              [-35.099522617274943, -8.211842737352413],
              [-35.099585217283902, -8.211875947516944],
              [-35.099636687283237, -8.211931906931909],
              [-35.099693868876898, -8.211987654106906],
              [-35.099733814064223, -8.212026767421321],
              [-35.099785042145925, -8.212054523197651],
              [-35.099899081577291, -8.212137999169272],
              [-35.099923048509822, -8.212165820596113],
              [-35.09992686253463, -8.212168611192396],
              [-35.099999536749181, -8.212252967600307],
              [-35.100030592138069, -8.212283303531592],
              [-35.100150044588815, -8.212344242492748],
              [-35.100212850542562, -8.212400051720977],
              [-35.100270034834182, -8.212455888828174],
              [-35.100344005871136, -8.212494645111747],
              [-35.100400917668196, -8.21253358125972],
              [-35.100457951773024, -8.212578211914888],
              [-35.100515135165324, -8.212634050820725],
              [-35.100617861125329, -8.212723277956854],
              [-35.100680729132307, -8.212790293637113],
              [-35.100737761438495, -8.212834832561441],
              [-35.10083480188456, -8.21291288112451],
              [-35.100844821231476, -8.21292920112171],
              [-35.100862586439177, -8.212943489550355],
              [-35.100865676509727, -8.212948522156523],
              [-35.100891866566371, -8.212963116354672],
              [-35.100948870094214, -8.213002052502702],
              [-35.100968730722286, -8.21302724790911],
              [-35.100976655548152, -8.213032659129851],
              [-35.101033927973333, -8.213105310861351],
              [-35.101114627737843, -8.213267963145938],
              [-35.101143326003637, -8.213307135815626],
              [-35.101195241167375, -8.213413713571981],
              [-35.101224176854259, -8.213480996350881],
              [-35.101226355911535, -8.21372315769753],
              [-35.101226982739036, -8.213790681494686],
              [-35.101227552909222, -8.213846992544632],
              [-35.101205466459078, -8.21390911951022],
              [-35.101188858678825, -8.213960009446851],
              [-35.101172460440637, -8.21401641042894],
              [-35.101150675263398, -8.214117950183208],
              [-35.101139483200541, -8.214129306822031],
              [-35.101117637768709, -8.214219638325574],
              [-35.101101595662044, -8.21432674937995],
              [-35.101085286456737, -8.214400054918542],
              [-35.101074274258281, -8.214428316113867],
              [-35.101040938188703, -8.214507404293215],
              [-35.100979534278054, -8.214598031673802],
              [-35.10095149521527, -8.214632075509826],
              [-35.100866958043639, -8.214672202360191],
              [-35.100821951471858, -8.214706334329833],
              [-35.10077114247423, -8.214723684950059],
              [-35.100675145241723, -8.214758264782063],
              [-35.100607307581129, -8.214764494385861],
              [-35.100550781593256, -8.214781782952855],
              [-35.100511282469654, -8.214793377912031],
              [-35.100409421657446, -8.214799874614471],
              [-35.100347090545768, -8.214800380932786],
              [-35.100307560845238, -8.214806371316911],
              [-35.100267912434163, -8.214806760723377],
              [-35.100234009791677, -8.214812633296333],
              [-35.100183009238435, -8.214813070366858],
              [-35.100171617526087, -8.21480192956534],
              [-35.100103540645819, -8.214796859187629],
              [-35.100029987793675, -8.214803119368412],
              [-35.099962029623953, -8.214803654465015],
              [-35.099905355247927, -8.214792831124214],
              [-35.099854185622121, -8.214776375329393],
              [-35.099774829444755, -8.214765852362177],
              [-35.099689904665297, -8.214766564625222],
              [-35.099655973244523, -8.214766831723864],
              [-35.099576644046863, -8.214761821600746],
              [-35.099480350038107, -8.214756990442709],
              [-35.099395334427072, -8.214757705403713],
              [-35.099299221182093, -8.2147697752049],
              [-35.099214595876845, -8.214809901155945],
              [-35.099023080750555, -8.214918559943555],
              [-35.098922026630191, -8.215009482301696],
              [-35.098860111005251, -8.215055002386407],
              [-35.09876432421413, -8.215112180382789],
              [-35.098690952125708, -8.215135252489858],
              [-35.098668418712521, -8.215146760214793],
              [-35.098668449289448, -8.215152364789731],
              [-35.098578017961131, -8.215175616761258],
              [-35.098425286098006, -8.215188163203095],
              [-35.098158726144277, -8.215139741905546],
              [-35.097982477908999, -8.215062377726497],
              [-35.097966891758631, -8.215037101381085],
              [-35.097954688857783, -8.215031745918282],
              [-35.097920187266766, -8.214975788301956],
              [-35.097885538186972, -8.214908443469824],
              [-35.097856484688975, -8.214818649760844],
              [-35.097810492460212, -8.214751453316865],
              [-35.097781499216751, -8.214672868757816],
              [-35.097768359222357, -8.214658202613919],
              [-35.097695189481385, -8.21460872821018],
            ],
            [
              [-35.081521241312203, -8.322745166156437],
              [-35.083328297662774, -8.321949464895852],
              [-35.091447176545898, -8.325152765487701],
              [-35.095498500954989, -8.326751213297825],
              [-35.098237811625665, -8.327832006841618],
              [-35.098237811625665, -8.327851801819179],
              [-35.098265957707724, -8.327862906647795],
              [-35.098265892057213, -8.335946007801965],
              [-35.094568737757015, -8.336771409173139],
              [-35.087322388317546, -8.336592201269013],
              [-35.088596269901871, -8.339856725907111],
              [-35.090367631261131, -8.341528038588081],
              [-35.093184606484385, -8.343898567864585],
              [-35.093175059281521, -8.343897691924894],
              [-35.093212831706751, -8.343929477563279],
              [-35.089116955782856, -8.343554099641949],
              [-35.089113564439401, -8.345577097604917],
              [-35.069269190720547, -8.355181022486136],
              [-35.061764211549075, -8.358812806865501],
              [-35.060976278632324, -8.359515610856704],
              [-35.058612507760984, -8.360418782001148],
              [-35.053233070849501, -8.365493768722388],
              [-35.054923895221521, -8.36813484284869],
              [-35.060865692597588, -8.36617364090074],
              [-35.061606553199852, -8.365175015714385],
              [-35.061621695085137, -8.364812358703318],
              [-35.068664268940836, -8.364977586646262],
              [-35.068667415668642, -8.365007925275506],
              [-35.068692611974427, -8.365008516130047],
              [-35.068767755727322, -8.365732876870709],
              [-35.069167864105623, -8.366460276420582],
              [-35.069026415136932, -8.366963291522666],
              [-35.068313436216044, -8.368390132498575],
              [-35.067691981299845, -8.368955454430647],
              [-35.066375314469155, -8.369763586120541],
              [-35.065735864118778, -8.369192898833148],
              [-35.065735389276767, -8.369186869778162],
              [-35.065707495004858, -8.369161974745282],
              [-35.065673559087429, -8.368731113150318],
              [-35.065315393290746, -8.369159219222524],
              [-35.06495006619258, -8.370476612705488],
              [-35.064712986914799, -8.371063838525288],
              [-35.063857003198109, -8.37086177614998],
              [-35.063910265546269, -8.371414840322586],
              [-35.06322334897834, -8.370952027613953],
              [-35.062757296012364, -8.371384983729968],
              [-35.062580379580822, -8.371995814954857],
              [-35.061405588896775, -8.372384631445527],
              [-35.061040347234211, -8.373189737816119],
              [-35.061419138982046, -8.37398184178852],
              [-35.062299393602075, -8.374336512819752],
              [-35.06330125814651, -8.374679619468793],
              [-35.064308102237078, -8.374322767581589],
              [-35.065778357085492, -8.373502713579342],
              [-35.066424564941656, -8.37417163651179],
              [-35.066797181944366, -8.373782218374629],
              [-35.067600246853885, -8.373724202210099],
              [-35.068408946915326, -8.373368379147337],
              [-35.068688896875074, -8.37237411297491],
              [-35.069605359100024, -8.372264805776126],
              [-35.070045454933734, -8.371877908438705],
              [-35.07027338720701, -8.370510519844572],
              [-35.0705842738451, -8.369346483253537],
              [-35.0705943732317, -8.368528439236059],
              [-35.072232603451823, -8.367673168681733],
              [-35.073488691949535, -8.365975612884199],
              [-35.075066084831917, -8.366371797520401],
              [-35.076491881694949, -8.36672989946527],
              [-35.077434533077223, -8.366966653188456],
              [-35.079070560857645, -8.367377544438057],
              [-35.084435542066558, -8.367706134230673],
              [-35.086412894039768, -8.367827224346854],
              [-35.088051139548384, -8.3685123413714],
              [-35.088052006494877, -8.368531701976394],
              [-35.088079521252837, -8.36854320790269],
              [-35.088338904616705, -8.374332694298346],
              [-35.088384737665478, -8.375355642548811],
              [-35.088470867536444, -8.377277871476565],
              [-35.088156693876272, -8.380528575333415],
              [-35.083118881531107, -8.380718421318022],
              [-35.070687016892293, -8.381384099496529],
              [-35.065189060126954, -8.384108102806636],
              [-35.06417512398275, -8.383157021179557],
              [-35.061504577274377, -8.382554736212057],
              [-35.058479026785619, -8.383358914482244],
              [-35.056758017568427, -8.381555965335508],
              [-35.054722093649673, -8.381674251365553],
              [-35.054711049075593, -8.381642335325466],
              [-35.05469364359675, -8.381643345264138],
              [-35.054498452941232, -8.381079265295796],
              [-35.053870225230639, -8.381118248208566],
              [-35.053857927900992, -8.381086337564398],
              [-35.053841778775052, -8.381087338509815],
              [-35.053525578042922, -8.380266724229898],
              [-35.051865405264209, -8.379222171565914],
              [-35.049372067267029, -8.377958325516261],
              [-35.04530177255873, -8.375648700127385],
              [-35.045297489087829, -8.375631436741344],
              [-35.045273369270546, -8.375617749959133],
              [-35.044721596524425, -8.37339392169207],
              [-35.044087129317518, -8.371980261180056],
              [-35.042573595495014, -8.370719325336552],
              [-35.042573434516328, -8.370711845675089],
              [-35.042545229978316, -8.370688346390011],
              [-35.042509105111094, -8.369006440594717],
              [-35.041984506279903, -8.365680431106966],
              [-35.041741607489143, -8.363449062923848],
              [-35.037861692962736, -8.358419740178817],
              [-35.037861650694595, -8.358419682622184],
              [-35.045450892744441, -8.352427588862099],
              [-35.045919823540999, -8.351387501332056],
              [-35.047115438026992, -8.34822820816521],
              [-35.047226630204761, -8.347748465719064],
              [-35.046231336107212, -8.345376416588294],
              [-35.047501713033569, -8.344066733892589],
              [-35.049162750060816, -8.342863991380057],
              [-35.049926764608472, -8.342671981626609],
              [-35.050506492780357, -8.342139751148522],
              [-35.05188826603063, -8.341639879177023],
              [-35.055079030972479, -8.340197533889011],
              [-35.054746234151366, -8.338700443270795],
              [-35.054737134810864, -8.337458900407853],
              [-35.054115456862803, -8.334984223343213],
              [-35.055073187177811, -8.33572708943359],
              [-35.056716472487153, -8.334761786826959],
              [-35.057955629448713, -8.333310177622536],
              [-35.058028049155098, -8.332702678385829],
              [-35.059263562962997, -8.332471886268479],
              [-35.05920032713334, -8.330590848988436],
              [-35.059790598956909, -8.330214570845726],
              [-35.06121305753652, -8.3289259089035],
              [-35.063491294787354, -8.324341086871641],
              [-35.065467491131756, -8.323975327199548],
              [-35.06673460531772, -8.322901416167383],
              [-35.066613696864579, -8.322330409620633],
              [-35.068415050614078, -8.322089796906539],
              [-35.0688387482096, -8.32045608757835],
              [-35.068907804451555, -8.320189813608977],
              [-35.070507657002736, -8.316449425300675],
              [-35.07591755575055, -8.316345322478583],
              [-35.077663136244723, -8.317953295907841],
              [-35.079986508670402, -8.319424501339597],
              [-35.079995002767134, -8.319443045360231],
              [-35.080014608887041, -8.319455459601727],
              [-35.081521241312203, -8.322745166156437],
            ],
            [
              [-35.004452200908304, -8.401460313942266],
              [-35.00470419454291, -8.401460312143627],
              [-35.004803200906963, -8.401520314910556],
              [-35.00481676268339, -8.401542111779008],
              [-35.004831784059547, -8.401551214716733],
              [-35.004983233489384, -8.401794620725127],
              [-35.005223200490263, -8.4018803261161],
              [-35.006340207034896, -8.401860326992448],
              [-35.007031211021513, -8.401900326139071],
              [-35.008251216020767, -8.402090342994882],
              [-35.008252739472312, -8.402117018685431],
              [-35.00827980366995, -8.402121233807861],
              [-35.008291805122667, -8.402331228203593],
              [-35.008338289280573, -8.402391859596605],
              [-35.008492217242008, -8.402430341586921],
              [-35.008683215258145, -8.402320344608142],
              [-35.009022219200006, -8.402480353785279],
              [-35.009392225470776, -8.402410345161229],
              [-35.00940581062963, -8.402444074234609],
              [-35.009420816717295, -8.402441234175569],
              [-35.009710811204911, -8.40316124579482],
              [-35.009550803826471, -8.403535246854119],
              [-35.009390795548711, -8.403891257875216],
              [-35.009332786578796, -8.405318271520969],
              [-35.009222777009484, -8.406741294874791],
              [-35.009248766517317, -8.407461305594722],
              [-35.009981768843488, -8.408261317305914],
              [-35.009939766007335, -8.408551321686105],
              [-35.009882766976091, -8.408721322331132],
              [-35.00951276070532, -8.409281329269902],
              [-35.009458752819171, -8.409461332174715],
              [-35.009290750467699, -8.409661334203179],
              [-35.009370747861681, -8.410241346560838],
              [-35.008501736565847, -8.410431341832918],
              [-35.008379738134352, -8.410501348658272],
              [-35.008340739033827, -8.41061134833501],
              [-35.00842416104615, -8.410769856543595],
              [-35.009342093662667, -8.410720510742919],
              [-35.009461091056437, -8.410760510788919],
              [-35.009470364865422, -8.410784837450251],
              [-35.009489746154827, -8.410791352139142],
              [-35.009550740873976, -8.410951348725689],
              [-35.009458746523876, -8.411081354720693],
              [-35.009142740045377, -8.411241351307297],
              [-35.009149741267493, -8.411451354696226],
              [-35.009420731281693, -8.412001370167047],
              [-35.009431730889617, -8.412221368621204],
              [-35.00932172491764, -8.412621376275467],
              [-35.009112381631041, -8.412970284451035],
              [-35.009300052155652, -8.413160554915635],
              [-35.009302318447169, -8.413164610858075],
              [-35.009328726139756, -8.413191382776063],
              [-35.009451719221431, -8.413411386626137],
              [-35.009592722126399, -8.413851388930425],
              [-35.009592723925039, -8.414061399513912],
              [-35.009538717837529, -8.414141400505173],
              [-35.009601883520077, -8.414279845738236],
              [-35.009644032945744, -8.414300575111383],
              [-35.009640142478531, -8.414315378851597],
              [-35.009672715923045, -8.414331398475213],
              [-35.009580717975723, -8.414681403823693],
              [-35.009500712487863, -8.414821399487948],
              [-35.009409714586639, -8.414801407558855],
              [-35.009359709582895, -8.414891403615343],
              [-35.009248709860003, -8.414881404952837],
              [-35.009100711128895, -8.414971404606604],
              [-35.008321697688132, -8.414911408134913],
              [-35.00811269524462, -8.415051404698545],
              [-35.008174611768879, -8.415187977543098],
              [-35.008282005210333, -8.415270591964202],
              [-35.008541005462973, -8.415300591548998],
              [-35.008721005669827, -8.415460597128799],
              [-35.008718999282337, -8.415464125169194],
              [-35.008749696741063, -8.415491413298014],
              [-35.008459691461553, -8.416001419729696],
              [-35.008459690562233, -8.41612141716962],
              [-35.008642691806756, -8.416591426253262],
              [-35.00858168629577, -8.416951434760847],
              [-35.008630680461465, -8.417321440132298],
              [-35.008508681130706, -8.41769144370511],
              [-35.00807067981799, -8.417761445134545],
              [-35.00770067174858, -8.41791144935388],
              [-35.007578671518445, -8.418054444257166],
              [-35.007459673225299, -8.418111445986369],
              [-35.00731867121965, -8.418102447370018],
              [-35.007181663103211, -8.418031450391084],
              [-35.007024670252292, -8.41790144259744],
              [-35.006864665571811, -8.417853442182547],
              [-35.006658665964608, -8.417672445526875],
              [-35.006502665965229, -8.417541435888495],
              [-35.006281664766959, -8.417451440731384],
              [-35.00602566015607, -8.417487434297584],
              [-35.005930658473062, -8.417587440707791],
              [-35.005785658980926, -8.417731440153773],
              [-35.005640654092872, -8.417881441675149],
              [-35.005472653540096, -8.417971444926195],
              [-35.005312646161656, -8.418182443864623],
              [-35.005159647199889, -8.418271446170252],
              [-35.004980649737149, -8.41828344312637],
              [-35.004781645056823, -8.418251443449321],
              [-35.002939623065117, -8.418561448751802],
              [-35.00237859449652, -8.421546487480384],
              [-35.0002425670408, -8.42293150998114],
              [-35.000352944432791, -8.423165361592112],
              [-35.00046181096377, -8.423240733772786],
              [-35.000463744506135, -8.423252943868192],
              [-35.000490564987558, -8.423271511271196],
              [-35.000612558922398, -8.424041525196174],
              [-35.000742558622164, -8.424471528837955],
              [-35.000372555049353, -8.425031534877405],
              [-35.000341550921803, -8.425321536559636],
              [-35.000421550114424, -8.425621541400972],
              [-35.000269543104878, -8.425991552168341],
              [-34.999208524753556, -8.426541555948006],
              [-34.99942715084228, -8.426879354798359],
              [-34.99953073655422, -8.426940804574429],
              [-34.999942751057063, -8.426860807180446],
              [-35.000190753500419, -8.426740799848005],
              [-35.000211973903447, -8.426775216003364],
              [-35.000219534503856, -8.426771556661947],
              [-35.000330532428109, -8.426951567660694],
              [-35.000482632067758, -8.42749343257583],
              [-35.000701745589026, -8.427520822328063],
              [-35.001083748815915, -8.427440821336802],
              [-35.001292748561468, -8.427570821935831],
              [-35.001373755894065, -8.427550818315581],
              [-35.001380752619582, -8.427450815502709],
              [-35.00147275596288, -8.427370821706006],
              [-35.001670757899092, -8.427310813543158],
              [-35.001689499770578, -8.427344612763648],
              [-35.001699542499864, -8.427341567659198],
              [-35.00176054711153, -8.427451573631174],
              [-35.001718540678041, -8.427651576558958],
              [-35.002102429783292, -8.428070878268386],
              [-35.002242748304127, -8.428080833763431],
              [-35.002261528846418, -8.42811087111977],
              [-35.002271539200137, -8.428111582483496],
              [-35.0028289479975, -8.429003054347447],
              [-35.002952745972379, -8.429090849762872],
              [-35.003062745649174, -8.429060854674674],
              [-35.003432751020625, -8.428790852116094],
              [-35.003450404712339, -8.428829727110156],
              [-35.003461547312497, -8.42882159633956],
              [-35.00367954027854, -8.42930159868979],
              [-35.003770540877724, -8.429981610263042],
              [-35.003770535481806, -8.430681615564083],
              [-35.00393153121513, -8.431021624947959],
              [-35.003958156543661, -8.431216872260791],
              [-35.004115716866806, -8.431253894651491],
              [-35.004279715436496, -8.431369902698805],
              [-35.004294059623135, -8.431390394650919],
              [-35.004308531513459, -8.431400631633778],
              [-35.004427534303204, -8.431570627782207],
              [-35.004615515993237, -8.431827921121624],
              [-35.004901705449299, -8.432086917776985],
              [-35.005069712297313, -8.432144911458465],
              [-35.00527171082075, -8.432181919459993],
              [-35.005496714901426, -8.432181917661296],
              [-35.006213714691114, -8.432362923310222],
              [-35.00670272285231, -8.432409927276296],
              [-35.006919724765453, -8.432447922733388],
              [-35.007121721490194, -8.432540924324144],
              [-35.007135976643951, -8.432564939820054],
              [-35.007150545661091, -8.432571646963879],
              [-35.007318548012563, -8.432854646524675],
              [-35.007414546144332, -8.433151655724259],
              [-35.007490546951146, -8.433438661764797],
              [-35.007543537704066, -8.433788668911916],
              [-35.007631539064221, -8.434117665198016],
              [-35.007761540562626, -8.434425673106261],
              [-35.007914454988054, -8.434588845199016],
              [-35.008094698911179, -8.434776971679867],
              [-35.008331694551998, -8.434978973800526],
              [-35.008341031313478, -8.434993053586481],
              [-35.008360536709347, -8.435009680252506],
              [-35.008543540651829, -8.435285685785743],
              [-35.008680536177735, -8.435454685485297],
              [-35.00883354143474, -8.435624686130325],
              [-35.009006884859787, -8.435819022429882],
              [-35.009219687838254, -8.436028991644605],
              [-35.009223877779675, -8.436035361542679],
              [-35.009248538089537, -8.43605969359993],
              [-35.009744533982996, -8.43681370768644],
              [-35.009927536126838, -8.437301712204203],
              [-35.010034533866587, -8.437556716768995],
              [-35.010088532759539, -8.437726719212662],
              [-35.010179527962805, -8.437901720987611],
              [-35.010610527154086, -8.438821735534646],
              [-35.010728581159071, -8.438972437627854],
              [-35.010762196917824, -8.438980328279456],
              [-35.010754165971946, -8.439002350877729],
              [-35.010791068752781, -8.439011014946345],
              [-35.010600255996962, -8.439534307961878],
              [-35.010406232661467, -8.439984890790015],
              [-35.01016440766125, -8.440644322277592],
              [-35.010057838898092, -8.441098511387167],
              [-35.009973225284057, -8.441596153939315],
              [-35.009914034604947, -8.442146109155544],
              [-35.009820318053187, -8.442971402608123],
              [-35.009733537073032, -8.443240031901894],
              [-35.009623718160015, -8.443411014606568],
              [-35.009409918732729, -8.443668823257553],
              [-35.009174951962393, -8.443856058510221],
              [-35.0089811939269, -8.443968658127005],
              [-35.008589147970781, -8.444152402211557],
              [-35.008322743599706, -8.44427366859486],
              [-35.008098742263087, -8.444386538907622],
              [-35.007832063598812, -8.444470253199597],
              [-35.007574078680761, -8.444541313131026],
              [-35.007311643017886, -8.444553641936977],
              [-35.00708758412469, -8.444528446530512],
              [-35.006774994170598, -8.444469827820228],
              [-35.006382512043274, -8.44444046135817],
              [-35.00603650867788, -8.444436293899798],
              [-35.005685917870778, -8.44444884843557],
              [-35.005200371200146, -8.444448777389141],
              [-35.00476997455462, -8.444386154897074],
              [-35.004491555241032, -8.444323551290836],
              [-35.004197648702473, -8.444252082167793],
              [-35.003890630947808, -8.444197414179257],
              [-35.003636521208421, -8.444227478515302],
              [-35.003316455190202, -8.444323844469807],
              [-35.002746813814383, -8.444653878573604],
              [-35.002204010906325, -8.444915445491347],
              [-35.00178528386374, -8.445122442446689],
              [-35.001287360723097, -8.445355171703739],
              [-35.000746484162846, -8.44550590887053],
              [-35.00038399712372, -8.44554757895753],
              [-35.000007325977265, -8.445548030417228],
              [-34.999601911598916, -8.44557623675388],
              [-34.999137293051604, -8.445605432344792],
              [-34.998716927444207, -8.445688851659156],
              [-34.998726909918901, -8.445650483882673],
              [-34.998687990858059, -8.445658206361088],
              [-34.998847395690689, -8.445045504546329],
              [-34.998683603965105, -8.44465670784075],
              [-34.998797836750725, -8.444606413255315],
              [-34.999176841637905, -8.444771351616509],
              [-34.999259054062065, -8.444001438415626],
              [-34.998742621075053, -8.443318098451414],
              [-34.998126584574152, -8.443827475357637],
              [-34.997774026949742, -8.443737786869349],
              [-34.997201874666757, -8.443953510845802],
              [-34.996360094842487, -8.443663995696795],
              [-34.995534904811961, -8.443882452712955],
              [-34.995883248213318, -8.444973443669426],
              [-34.99583519653703, -8.446260736843499],
              [-34.995221101672485, -8.446655214566476],
              [-34.994409206319744, -8.446882452563102],
              [-34.992652601036639, -8.445178291245099],
              [-34.992246933049501, -8.447020017359876],
              [-34.993750169634552, -8.447357391730179],
              [-34.993708315186552, -8.447372127121923],
              [-34.993779143992242, -8.447388024437714],
              [-34.992804235726851, -8.447731270481711],
              [-34.990912289670405, -8.450270873202214],
              [-34.990909664549349, -8.450204840480978],
              [-34.990883278440492, -8.450240258481074],
              [-34.990672794813577, -8.444944047529702],
              [-34.991452769629575, -8.442291480967015],
              [-34.991178527666875, -8.440894901278625],
              [-34.991907901228615, -8.440653887466908],
              [-34.99192610980208, -8.440688101274816],
              [-34.991936831519524, -8.440684558845248],
              [-34.992798853284398, -8.442304144320758],
              [-34.994052268097676, -8.443713581616464],
              [-34.994412857567283, -8.443416654255145],
              [-34.994172360865718, -8.441983115128608],
              [-34.992553654027859, -8.439203894344189],
              [-34.991570198605757, -8.436523057592979],
              [-34.990475872963145, -8.434403444563259],
              [-34.988350505171638, -8.429410832144129],
              [-34.986896091881079, -8.427557417540754],
              [-34.984145228519424, -8.430271643421577],
              [-34.983246222135392, -8.432412477753587],
              [-34.983958795462001, -8.433793294125167],
              [-34.983719823111301, -8.434148148618135],
              [-34.984582408751123, -8.435520721803812],
              [-34.985318089258385, -8.435546791351271],
              [-34.985336833827773, -8.435577128181876],
              [-34.985347008757401, -8.435577488809997],
              [-34.985730744977843, -8.436198490467916],
              [-34.986512562504686, -8.436617336221047],
              [-34.986515023049833, -8.436633851371084],
              [-34.986541485601037, -8.436648028283855],
              [-34.986748995169933, -8.438041128495229],
              [-34.987591201272892, -8.438778177770644],
              [-34.987591649135254, -8.438783927136456],
              [-34.9876201360604, -8.438808857242918],
              [-34.987656080163902, -8.439268912630155],
              [-34.987228684556101, -8.439351176315711],
              [-34.98662984758937, -8.438965031710779],
              [-34.985936678035216, -8.438704659992027],
              [-34.983846451252703, -8.436887049197935],
              [-34.983669367547236, -8.436915338272172],
              [-34.98366225480919, -8.436881162235807],
              [-34.983640429162449, -8.4368846498067],
              [-34.983611100471876, -8.436743725142776],
              [-34.983532516812147, -8.436587488621683],
              [-34.983462295948129, -8.436447262730951],
              [-34.983373392567955, -8.43632238646893],
              [-34.983300851453066, -8.43624849367302],
              [-34.983296138106255, -8.43624247451055],
              [-34.983271915766238, -8.436217799811573],
              [-34.983177255825638, -8.436096917438761],
              [-34.983109789585058, -8.435952908999525],
              [-34.983001479734469, -8.435808618173155],
              [-34.982812295650888, -8.435558610241628],
              [-34.982639874030895, -8.435351613286286],
              [-34.982407213122372, -8.435024398557118],
              [-34.982304364854485, -8.43483750594612],
              [-34.98225246407992, -8.434643277565158],
              [-34.982225153467994, -8.434463786374579],
              [-34.982178876154194, -8.434216894593987],
              [-34.982116902972678, -8.434030563159922],
              [-34.982078633222329, -8.433910824724762],
              [-34.9818537550467, -8.432766816934986],
              [-34.981832134445426, -8.432647383370011],
              [-34.981786947109867, -8.432598181460833],
              [-34.981713494082442, -8.432544546793338],
              [-34.981697232541194, -8.432523072781521],
              [-34.981684580878664, -8.432513833146857],
              [-34.981625207637194, -8.43243542485493],
              [-34.981529904681338, -8.432164027447868],
              [-34.981551675469404, -8.432093110508617],
              [-34.98161326284179, -8.432009408807119],
              [-34.98166228758447, -8.431902438946338],
              [-34.981676973513459, -8.431793548133669],
              [-34.98165855000201, -8.431641496158079],
              [-34.981651902213457, -8.431417172864201],
              [-34.981656348461684, -8.431228028350802],
              [-34.981790132508593, -8.430077223485057],
              [-34.980176630047367, -8.430985083694623],
              [-34.979998871850398, -8.430930862669129],
              [-34.9798594652421, -8.430827162743299],
              [-34.979715999094026, -8.430678504809123],
              [-34.979715560224861, -8.430677281731107],
              [-34.979687091286166, -8.430647781270068],
              [-34.979618812957824, -8.430457563865446],
              [-34.979555600510537, -8.430226183591458],
              [-34.9794730679277, -8.430022474557518],
              [-34.979468708014394, -8.430018153315075],
              [-34.979397914282288, -8.429900401482314],
              [-34.979346257223938, -8.429837494804474],
              [-34.979276629013157, -8.429769246153739],
              [-34.979268983876466, -8.429759355409885],
              [-34.979247726601272, -8.429738518118086],
              [-34.979162910639786, -8.42962878464067],
              [-34.979091360577797, -8.429450042585358],
              [-34.97904474421955, -8.429274256601616],
              [-34.979013553032985, -8.429138114532293],
              [-34.978976448804019, -8.429040083932705],
              [-34.97896830724153, -8.428879945253243],
              [-34.979016330139473, -8.4287610728652],
              [-34.979348797808711, -8.428155324608497],
              [-34.979388492984526, -8.428006090208839],
              [-34.979365676284885, -8.427812603768587],
              [-34.979304534976279, -8.427699408800606],
              [-34.97920202755148, -8.427535826617031],
              [-34.979116569474058, -8.427346064269386],
              [-34.979035098990607, -8.427242749253423],
              [-34.97891714121306, -8.427177760645066],
              [-34.978805726902749, -8.427098625700978],
              [-34.978803305927784, -8.427096225410423],
              [-34.978701601597606, -8.426995407811773],
              [-34.978679243552222, -8.426971132411779],
              [-34.978672718970756, -8.426964663588308],
              [-34.978570934600896, -8.426854147700737],
              [-34.978483624819376, -8.426654478421369],
              [-34.978393707003931, -8.426463569438113],
              [-34.978328266036613, -8.426304863378675],
              [-34.978267607663895, -8.426232660408914],
              [-34.97816077899671, -8.426107416324157],
              [-34.978078168172885, -8.425960453611992],
              [-34.978068442904259, -8.425955616158717],
              [-34.977968476064291, -8.425745771949778],
              [-34.977926644998604, -8.425545187160594],
              [-34.977907253816682, -8.425265245294781],
              [-34.977913455541511, -8.424919108829783],
              [-34.977875075174552, -8.424611796996771],
              [-34.977756577803802, -8.424329511497717],
              [-34.977606089749202, -8.424111410613079],
              [-34.97760289265932, -8.424105097372319],
              [-34.977414449617129, -8.423895653361683],
              [-34.977197896465725, -8.423609595206585],
              [-34.977195265049374, -8.423296381123066],
              [-34.977278428956311, -8.423132119951333],
              [-34.977281629643471, -8.423122649190873],
              [-34.977415582762887, -8.423040591450103],
              [-34.977434710443504, -8.423031125186242],
              [-34.977453843520038, -8.423015351976858],
              [-34.977463410507937, -8.423005886612316],
              [-34.977606793019049, -8.422905030342804],
              [-34.97761317191032, -8.422895566776958],
              [-34.977641900753099, -8.422760027253389],
              [-34.977641906149017, -8.422747427751517],
              [-34.977600418624377, -8.422662739493774],
              [-34.977486117490287, -8.422573473686839],
              [-34.977470187798872, -8.422570316167139],
              [-34.977311036575031, -8.422422039545438],
              [-34.976989380555437, -8.422188873217863],
              [-34.97675085426846, -8.422000148687857],
              [-34.976744485269762, -8.42199384623899],
              [-34.97672798900544, -8.421974114213981],
              [-34.976722002218594, -8.421969377484743],
              [-34.97671563321984, -8.421963074136499],
              [-34.976578588231234, -8.42179914211664],
              [-34.976569035632451, -8.42178969383923],
              [-34.976457458544814, -8.421562790390396],
              [-34.976451112029167, -8.421559657152386],
              [-34.976355864831248, -8.421396025506112],
              [-34.976304982988552, -8.421194358832565],
              [-34.976279513288887, -8.420945368933587],
              [-34.976273254906744, -8.420702662597762],
              [-34.976266902995121, -8.420526152659761],
              [-34.976212822263903, -8.420346760394636],
              [-34.976209636865178, -8.420340470536246],
              [-34.97620327056444, -8.420331045641205],
              [-34.976168403848703, -8.420057194883611],
              [-34.976171600039265, -8.420044581891887],
              [-34.976136742316783, -8.419726112170281],
              [-34.976108233807906, -8.41934354416918],
              [-34.976076503927459, -8.419042606730898],
              [-34.976031704199727, -8.418843665902386],
              [-34.975916187182236, -8.418618000820061],
              [-34.975861689165583, -8.418443246358663],
              [-34.975797634053549, -8.418221021183115],
              [-34.975746426656201, -8.418059257428752],
              [-34.975714532199845, -8.417843759182119],
              [-34.975705096512968, -8.417631701741584],
              [-34.975701939892588, -8.417529788768718],
              [-34.975711551846587, -8.417336690835555],
              [-34.975730766761444, -8.417086612756918],
              [-34.975730765862124, -8.417083446244021],
              [-34.975727575067481, -8.416937830716279],
              [-34.975657210311965, -8.416659252222644],
              [-34.975583482991283, -8.416352134643262],
              [-34.975503321021392, -8.416112999515292],
              [-34.975503321021392, -8.416103754484652],
              [-34.97540493878688, -8.415845133745904],
              [-34.975400253318981, -8.415831270696515],
              [-34.975254854527861, -8.415558596252197],
              [-34.975086445682905, -8.415290186392951],
              [-34.974918635786366, -8.415017277225502],
              [-34.974783639453335, -8.414841710676342],
              [-34.974611773614413, -8.414587929189508],
              [-34.974468751731479, -8.414270265260484],
              [-34.974292730125342, -8.413970086849986],
              [-34.974146504857288, -8.413812680310912],
              [-34.974027307813969, -8.413806381459267],
              [-34.974013409691054, -8.413776352196862],
              [-34.973998507924762, -8.413775565290052],
              [-34.973830505573346, -8.413412560241397],
              [-34.973804519662792, -8.413125553301597],
              [-34.973830519962462, -8.412830554986101],
              [-34.973880524966205, -8.41267055120494],
              [-34.973857526603524, -8.412520543388325],
              [-34.973785529578493, -8.412313537439729],
              [-34.973731526188942, -8.412091533497403],
              [-34.973689529648027, -8.411926531284337],
              [-34.973685532161539, -8.411661525359079],
              [-34.973712541500561, -8.411472527435137],
              [-34.973727538594972, -8.411382519687493],
              [-34.9737125432992, -8.411280519480329],
              [-34.973661918662572, -8.411206017843426],
              [-34.973631323726579, -8.411174346418932],
              [-34.973482324949316, -8.411041351976792],
              [-34.973230323220776, -8.410911342384509],
              [-34.97323175494148, -8.410896095278531],
              [-34.973201543116659, -8.410880509128162],
              [-34.97326255222498, -8.410230501636306],
              [-34.975471738629778, -8.401010313874849],
              [-34.975761746607304, -8.401020320631233],
              [-34.976100748750525, -8.40113032120729],
              [-34.976409756704868, -8.401350321460143],
              [-34.976680757510906, -8.401420324688161],
              [-34.977561777454014, -8.400970319224825],
              [-34.979202820753528, -8.400300299119465],
              [-34.980136839543491, -8.399991295661721],
              [-34.980590851486568, -8.399858289528368],
              [-34.981277861583976, -8.399567286900719],
              [-34.983390088481769, -8.398666134734299],
              [-34.983636084537636, -8.398106292371438],
              [-34.983918219849897, -8.397464214202103],
              [-34.984619964439787, -8.39692581797209],
              [-34.985660983667401, -8.397140228938383],
              [-34.987828012552711, -8.397571237122861],
              [-34.989975040515674, -8.398059242539944],
              [-34.991951068686831, -8.398430251554828],
              [-34.994072103544681, -8.398690248256287],
              [-35.002922195103167, -8.40061028913351],
              [-35.003872198443162, -8.401190303289809],
              [-35.004452200908304, -8.401460313942266],
            ],
          ],
          [
            [
              [-34.956687518, -8.379628307999949],
              [-34.956709793999899, -8.380482639999968],
              [-34.956836806, -8.381253408999955],
              [-34.956871921999898, -8.382165911999946],
              [-34.956994755, -8.383113488999971],
              [-34.957057025, -8.383569253999948],
              [-34.9572162649999, -8.384160474999925],
              [-34.957348966, -8.384653158999978],
              [-34.957443570999899, -8.384810454999986],
              [-34.957571286, -8.385074347999925],
              [-34.957569709, -8.385088906999956],
              [-34.95768849, -8.38536658],
              [-34.957787562, -8.385620072999931],
              [-34.957896885, -8.385778930999951],
              [-34.958125783999897, -8.386002009999974],
              [-34.958327615, -8.386200128999965],
              [-34.958565452, -8.386409430999947],
              [-34.958885903999899, -8.386689279999924],
              [-34.959057247, -8.386825256999947],
              [-34.959208353999898, -8.387010632999926],
              [-34.959214135, -8.38702597199995],
              [-34.959377069, -8.387102151999956],
              [-34.959384428, -8.387102931999944],
              [-34.959714767, -8.387145315999934],
              [-34.960067442, -8.387256336999934],
              [-34.960240362, -8.387377754999925],
              [-34.9602461429999, -8.38739309499994],
              [-34.960476352999898, -8.387535312999937],
              [-34.960743356, -8.387681431999965],
              [-34.960771212999902, -8.387699110999963],
              [-34.960924763528659, -8.387827624065084],
              [-34.962442721454408, -8.387438081901905],
              [-34.96217258437968, -8.388490843576671],
              [-34.962335402, -8.388512911999944],
              [-34.962372194999901, -8.388516811999976],
              [-34.962658388, -8.388554516999925],
              [-34.962945372, -8.388584941999964],
              [-34.963127759, -8.388619004999956],
              [-34.963319081999899, -8.388639287999982],
              [-34.963341157999899, -8.388641627999954],
              [-34.963547725, -8.388589893999953],
              [-34.963801335, -8.388447421999967],
              [-34.964032607, -8.388236310999968],
              [-34.964292525, -8.388035599999961],
              [-34.964576886, -8.387815389999963],
              [-34.964819319999897, -8.387752253999956],
              [-34.965039026999897, -8.38771663799997],
              [-34.965303674, -8.387678423999942],
              [-34.965548875, -8.387682328999967],
              [-34.965696572999903, -8.387624352999978],
              [-34.966009576, -8.38741454099994],
              [-34.9662303389999, -8.387115300999934],
              [-34.966426299, -8.386719526999975],
              [-34.966656053, -8.386365205999935],
              [-34.966702661999904, -8.385934662999944],
              [-34.966749955999902, -8.385674601999943],
              [-34.967087166999903, -8.385388479999961],
              [-34.967235154, -8.385082294999968],
              [-34.967378405, -8.384643069999981],
              [-34.967385137, -8.384227235999958],
              [-34.967366066, -8.38369502899997],
              [-34.967426939999903, -8.38313349799995],
              [-34.9676262, -8.382529800999977],
              [-34.967826282, -8.382096621999953],
              [-34.967765474, -8.381597909999932],
              [-34.967476910999899, -8.381434786999927],
              [-34.967266729999899, -8.381431438999925],
              [-34.966988976, -8.381345194999943],
              [-34.966880187, -8.380936065999952],
              [-34.966636209999905, -8.380891268999953],
              [-34.966496322, -8.380592442999951],
              [-34.966153047, -8.380404589999953],
              [-34.9657881219999, -8.38059310399996],
              [-34.96544955, -8.380538279999932],
              [-34.965132637999901, -8.380107093999925],
              [-34.964876534, -8.379644487999943],
              [-34.9644318799999, -8.379332293999942],
              [-34.9647116209999, -8.378869692999956],
              [-34.965123831, -8.378951251999982],
              [-34.965744173, -8.379054870999941],
              [-34.966298283999897, -8.378886408999961],
              [-34.9663889079999, -8.37840374199993],
              [-34.966464527, -8.377881642999972],
              [-34.966380742999903, -8.377418366999958],
              [-34.966347003, -8.376846782999962],
              [-34.966157779999897, -8.376296604999945],
              [-34.9659766669999, -8.37567155799996],
              [-34.965980725999898, -8.375634118999924],
              [-34.965678001, -8.375071906999947],
              [-34.965411070999899, -8.374532433999946],
              [-34.965045517, -8.374020354999979],
              [-34.964768502, -8.373574446999944],
              [-34.96475907, -8.373308361999932],
              [-34.964919928, -8.373060318999933],
              [-34.965063895999897, -8.372791549999922],
              [-34.964896328999899, -8.372395113999971],
              [-34.964546984, -8.372263412999933],
              [-34.964126661, -8.372256747999936],
              [-34.963393482, -8.372311588999926],
              [-34.962778552, -8.372511498999932],
              [-34.961574395999904, -8.373027615999947],
              [-34.960695949, -8.373540372999969],
              [-34.959918193, -8.374006994999947],
              [-34.959087741, -8.374430148999975],
              [-34.958225528999897, -8.374793152999922],
              [-34.957708610999902, -8.375325253999961],
              [-34.9572470909999, -8.376052561999927],
              [-34.956987601, -8.376858093999946],
              [-34.956892302, -8.377737837999966],
              [-34.956837542999899, -8.378773318999956],
              [-34.956687518, -8.379628307999949],
            ],
          ],
          [
            [
              [-35.083666818668689, -8.606179411550556],
              [-35.083810053690968, -8.60620711786413],
              [-35.084376879289493, -8.606501993871859],
              [-35.084875641497604, -8.606774276211809],
              [-35.085352475536297, -8.606390219832747],
              [-35.085693241250056, -8.605938107257771],
              [-35.086169956578203, -8.605599336240346],
              [-35.086692026515777, -8.60528328119841],
              [-35.087327405635222, -8.605125800914948],
              [-35.087894421890041, -8.60523969555544],
              [-35.088370452834113, -8.605557115768249],
              [-35.088937163319372, -8.605897363472479],
              [-35.088947334651721, -8.605915528878484],
              [-35.088967056784213, -8.605927368453251],
              [-35.089284051617653, -8.606493514164299],
              [-35.089328748822652, -8.607059239891953],
              [-35.089418893267293, -8.607647768829395],
              [-35.089486330729528, -8.60823615027806],
              [-35.089644472014754, -8.608802073856566],
              [-35.089939012575314, -8.609265039449951],
              [-35.090408357059971, -8.60893156543932],
              [-35.090681169100606, -8.608411479406186],
              [-35.090659148301029, -8.607823119541308],
              [-35.091135801575945, -8.607507034821765],
              [-35.091635226584401, -8.607236114954674],
              [-35.092134620116553, -8.606942683258183],
              [-35.092701769471034, -8.606898197393832],
              [-35.093268673310604, -8.607057372899419],
              [-35.093835666182997, -8.607216548404949],
              [-35.094402677041842, -8.607262546928951],
              [-35.094947398203828, -8.607037023939483],
              [-35.095424183679086, -8.606630357704773],
              [-35.095764995258264, -8.606155550738038],
              [-35.095947027932937, -8.60561272444761],
              [-35.095766211141665, -8.605069435905705],
              [-35.095403749283548, -8.604593758395197],
              [-35.095064051964357, -8.604095447646728],
              [-35.095495422575652, -8.603688756231065],
              [-35.095949533444184, -8.603304632402796],
              [-35.096471598885159, -8.603056452792998],
              [-35.096971030188854, -8.602672442279356],
              [-35.097447760805494, -8.602356261332375],
              [-35.097777923512297, -8.601896364225809],
              [-35.097274248307883, -8.601707171148973],
              [-35.096752025485557, -8.602091123206719],
              [-35.09616217994062, -8.602135647741875],
              [-35.095572497172952, -8.602044305400341],
              [-35.09505044432251, -8.602360368536154],
              [-35.094551005824201, -8.602676487429903],
              [-35.094051623083885, -8.602970007259955],
              [-35.093869532852636, -8.603535427218162],
              [-35.093370184286528, -8.603851546111912],
              [-35.092848173704226, -8.604077120362774],
              [-35.09241754683228, -8.603691844502976],
              [-35.091986977516967, -8.603283971378119],
              [-35.091419784995026, -8.603418941630821],
              [-35.090784604625753, -8.603395455835596],
              [-35.090781144933828, -8.603366411330796],
              [-35.090754731845379, -8.603365435566332],
              [-35.09068733125531, -8.602799650483462],
              [-35.091050760783958, -8.602324902871942],
              [-35.091210243857631, -8.60175942175988],
              [-35.091664300766809, -8.601397992323427],
              [-35.092254196673821, -8.601263077828719],
              [-35.092776229739172, -8.600992215518261],
              [-35.092958359540603, -8.60044939012721],
              [-35.092865652927514, -8.600046604467195],
              [-35.083666818668689, -8.606179411550556],
            ],
          ],
          [
            [
              [-35.125113713726876, -8.619603314741141],
              [-35.12692856989753, -8.619559281235809],
              [-35.12896623850105, -8.619127751444523],
              [-35.129353898463592, -8.619137171842965],
              [-35.130850072672672, -8.619173532332582],
              [-35.132692645948794, -8.619604700596426],
              [-35.135443261996897, -8.622199447946116],
              [-35.13730973385708, -8.622743628615524],
              [-35.139197954921656, -8.623196924698277],
              [-35.142681121836233, -8.624265568401199],
              [-35.144033455779322, -8.625540429347268],
              [-35.145344392129971, -8.626833321700417],
              [-35.145344610665234, -8.62683397370887],
              [-35.145374272104903, -8.626863224158399],
              [-35.145989689872238, -8.628693129584235],
              [-35.146899587945597, -8.630250746374827],
              [-35.147605945158205, -8.632193486140409],
              [-35.148837349963685, -8.63377137275063],
              [-35.150415132252533, -8.634597811939443],
              [-35.152388401303654, -8.634660141252482],
              [-35.154270408952186, -8.634406146626304],
              [-35.155541074560915, -8.63364167252513],
              [-35.155856340897344, -8.633451997411726],
              [-35.157693408523926, -8.63349210537632],
              [-35.159463861567929, -8.63427850045241],
              [-35.160894757586959, -8.63547293053125],
              [-35.162529678301951, -8.636621549145104],
              [-35.163937995941694, -8.637883793502226],
              [-35.163942856777396, -8.637891189526727],
              [-35.163967891205175, -8.637913627611795],
              [-35.164980615063314, -8.639454821882453],
              [-35.165787704438969, -8.640026568571216],
              [-35.166550925784577, -8.64056723918668],
              [-35.169978242102161, -8.642049917269844],
              [-35.171906993812684, -8.642632340709895],
              [-35.172909391655082, -8.64278052290274],
              [-35.109603218813447, -8.588887760761509],
              [-35.108894864206547, -8.589360016351634],
              [-35.109751187867005, -8.58950404547528],
              [-35.109741695522814, -8.589527550156276],
              [-35.109780933842956, -8.58953414938145],
              [-35.109073386827276, -8.59128625895363],
              [-35.108162110992566, -8.593086034392911],
              [-35.1076811967298, -8.594812857727163],
              [-35.108606640484027, -8.596500616310323],
              [-35.109707666975225, -8.597977335485609],
              [-35.111148669575414, -8.599361940700931],
              [-35.112657530520551, -8.600431352424948],
              [-35.114343167603067, -8.601045732374587],
              [-35.115852998017374, -8.602205698634066],
              [-35.117159327141167, -8.60343601885711],
              [-35.117159775003586, -8.603438395765295],
              [-35.117189138767685, -8.603466050817531],
              [-35.117525738821769, -8.605251204179694],
              [-35.118857591604524, -8.608881787964094],
              [-35.119894646620594, -8.610386920320082],
              [-35.1203898106412, -8.612147597733383],
              [-35.120477827289847, -8.614026315661874],
              [-35.1210629900649, -8.61571797550414],
              [-35.121966377945967, -8.617451175930967],
              [-35.12333963551572, -8.618923241114658],
              [-35.125113713726876, -8.619603314741141],
            ],
          ],
        ],
      },
    },
  ],
};
