import { CheckboxTreeView } from '../CheckboxTreeView';
import { ModalContent, ModalTitle } from './styles';
import { geoLayers } from './layers';

interface ScenariosModal {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export default function ScenariosModal() {
  return (
    <ModalContent>
      <ModalTitle>Cenários (GeoServer)</ModalTitle>
      <CheckboxTreeView data={geoLayers} />
    </ModalContent>
  );
}
