export var jsonGLOPEAnual6 = {
  type: 'FeatureCollection',
  name: 'GLO_PE_Anual_6',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        fid: '1',
        ID: 35974.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -9.5005,
        ANNUAL: 5811.0,
        JAN: 6408.0,
        FEB: 6089.0,
        MAR: 6010.0,
        APR: 5246.0,
        MAY: 4944.0,
        JUN: 4709.0,
        JUL: 4979.0,
        AUG: 5681.0,
        SEP: 6334.0,
        OCT: 6425.0,
        NOV: 6581.0,
        DEC: 6333.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.701366826, -9.457650101999956],
              [-40.708194127999903, -9.45324220499997],
              [-40.713671545999908, -9.450469765999969],
              [-40.713679427892188, -9.450461434175612],
              [-40.699045178706342, -9.450461434175612],
              [-40.699045178706342, -9.458758620134617],
              [-40.701366826, -9.457650101999956],
            ],
          ],
          [
            [
              [-40.767456465999899, -9.452650075999932],
              [-40.776716174999898, -9.454444766999931],
              [-40.776750542999899, -9.454451427999965],
              [-40.772675693763276, -9.450461434175612],
              [-40.763058668714663, -9.450461434175612],
              [-40.767456465999899, -9.452650075999932],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '2',
        ID: 35975.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -9.5005,
        ANNUAL: 5766.0,
        JAN: 6347.0,
        FEB: 6088.0,
        MAR: 5983.0,
        APR: 5213.0,
        MAY: 4887.0,
        JUN: 4624.0,
        JUL: 4900.0,
        AUG: 5587.0,
        SEP: 6318.0,
        OCT: 6408.0,
        NOV: 6540.0,
        DEC: 6296.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599618028999899, -9.469606354999934],
              [-40.608562578, -9.476791531999941],
              [-40.616510256, -9.480975365999949],
              [-40.6206768549999, -9.48252546399992],
              [-40.623165672, -9.482897442999958],
              [-40.624600161124896, -9.482837074707263],
              [-40.628100649, -9.482689761999945],
              [-40.635862727, -9.481022795999921],
              [-40.643492819999899, -9.478168795999979],
              [-40.6459624, -9.47703808899996],
              [-40.649334951999897, -9.474937881999947],
              [-40.652152188999899, -9.473794419999923],
              [-40.653677542, -9.473501509999947],
              [-40.656768273999901, -9.47290800199994],
              [-40.662313749, -9.471842794999933],
              [-40.662869391999898, -9.471736063999977],
              [-40.662949038, -9.47172076499993],
              [-40.681321824999898, -9.467220998999956],
              [-40.699045178706342, -9.458758620134617],
              [-40.699045178706342, -9.450461434175612],
              [-40.599045178706341, -9.450461434175612],
              [-40.599045178706341, -9.469271735152891],
              [-40.599618028999899, -9.469606354999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '3',
        ID: 35976.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -9.5005,
        ANNUAL: 5722.0,
        JAN: 6339.0,
        FEB: 6068.0,
        MAR: 5967.0,
        APR: 5187.0,
        MAY: 4811.0,
        JUN: 4553.0,
        JUL: 4795.0,
        AUG: 5537.0,
        SEP: 6284.0,
        OCT: 6353.0,
        NOV: 6488.0,
        DEC: 6283.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.550377834, -9.451784882999963],
              [-40.550662496, -9.452179992999959],
              [-40.552700861999902, -9.454742240999963],
              [-40.555758513999898, -9.457938111999976],
              [-40.565185479, -9.46400946799997],
              [-40.572708716, -9.466858348999949],
              [-40.574864213, -9.467405691999947],
              [-40.576626883, -9.467654671999981],
              [-40.581506412999893, -9.467646210999934],
              [-40.5837811439999, -9.467205814999932],
              [-40.5874541909999, -9.465740721999953],
              [-40.5936426499999, -9.466901645999945],
              [-40.595123309, -9.467369153999925],
              [-40.597629799999901, -9.468444967999966],
              [-40.599045178706341, -9.469271735152891],
              [-40.599045178706341, -9.450461434175612],
              [-40.549424340042187, -9.450461434175612],
              [-40.550377834, -9.451784882999963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '4',
        ID: 36352.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -9.4005,
        ANNUAL: 5813.0,
        JAN: 6402.0,
        FEB: 6089.0,
        MAR: 6006.0,
        APR: 5258.0,
        MAY: 4940.0,
        JUN: 4712.0,
        JUL: 4990.0,
        AUG: 5640.0,
        SEP: 6336.0,
        OCT: 6442.0,
        NOV: 6578.0,
        DEC: 6357.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.715245307, -9.448806167999985],
              [-40.715318711999899, -9.448728542999959],
              [-40.717104016999897, -9.448005038999957],
              [-40.717918853999898, -9.447674821999943],
              [-40.722178590999903, -9.44594853999997],
              [-40.729783061, -9.444803801999965],
              [-40.730527160999898, -9.444691787999936],
              [-40.7453177209999, -9.443350582999926],
              [-40.749835436, -9.443847168999975],
              [-40.755039983, -9.446482359999944],
              [-40.758049657999898, -9.448805964999957],
              [-40.7627623119999, -9.450313946999925],
              [-40.763058668714663, -9.450461434175612],
              [-40.772675693763276, -9.450461434175612],
              [-40.769593432, -9.447443357999935],
              [-40.767288935, -9.445704125999955],
              [-40.767159283, -9.445411926999951],
              [-40.767071905, -9.445215363999976],
              [-40.764668797, -9.439804987999919],
              [-40.760628043999908, -9.432236281999959],
              [-40.759595295999901, -9.42907629699994],
              [-40.7600836, -9.425767506999929],
              [-40.7647885969999, -9.415949936999937],
              [-40.764975564999901, -9.413216385999924],
              [-40.763229990999903, -9.411423492999972],
              [-40.760788433999899, -9.41054506199993],
              [-40.756701227999898, -9.410352341999943],
              [-40.750595061999903, -9.406464660999971],
              [-40.747430052, -9.405870102999929],
              [-40.745231524, -9.404623216999942],
              [-40.74308888, -9.402945492999946],
              [-40.742671533, -9.400712881999933],
              [-40.743492784999901, -9.398281368999951],
              [-40.7441132719999, -9.396445546999935],
              [-40.743753522, -9.391075504999955],
              [-40.743409960999898, -9.388877681999929],
              [-40.74318558600001, -9.387442309999926],
              [-40.742540415999898, -9.383315037999974],
              [-40.742648953, -9.382880755999958],
              [-40.742771401, -9.382390814999956],
              [-40.74351688, -9.37940798899996],
              [-40.745867265, -9.374813099999937],
              [-40.746478937999903, -9.371052246999964],
              [-40.744528913999893, -9.364831664999933],
              [-40.73973969, -9.358291735999954],
              [-40.738102645, -9.355244273999975],
              [-40.73820883, -9.353199255999932],
              [-40.739753770545164, -9.350461434175612],
              [-40.699045178706342, -9.350461434175612],
              [-40.699045178706342, -9.450461434175612],
              [-40.713679427892188, -9.450461434175612],
              [-40.715245307, -9.448806167999985],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '5',
        ID: 36353.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -9.4005,
        ANNUAL: 5790.0,
        JAN: 6384.0,
        FEB: 6085.0,
        MAR: 5980.0,
        APR: 5225.0,
        MAY: 4865.0,
        JUN: 4648.0,
        JUL: 4896.0,
        AUG: 5598.0,
        SEP: 6389.0,
        OCT: 6473.0,
        NOV: 6578.0,
        DEC: 6362.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -9.450461434175612],
              [-40.699045178706342, -9.350461434175612],
              [-40.599045178706341, -9.350461434175612],
              [-40.599045178706341, -9.450461434175612],
              [-40.699045178706342, -9.450461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '6',
        ID: 36354.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -9.4005,
        ANNUAL: 5767.0,
        JAN: 6381.0,
        FEB: 6165.0,
        MAR: 6027.0,
        APR: 5242.0,
        MAY: 4828.0,
        JUN: 4576.0,
        JUL: 4820.0,
        AUG: 5553.0,
        SEP: 6323.0,
        OCT: 6401.0,
        NOV: 6503.0,
        DEC: 6384.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499162466, -9.408909007999972],
              [-40.501159781, -9.409251014999947],
              [-40.501450125, -9.409300731999966],
              [-40.502549879999904, -9.409093852999941],
              [-40.5050571949999, -9.408622191999939],
              [-40.505098658999898, -9.408614391999954],
              [-40.506269538, -9.408394133999929],
              [-40.510726616999904, -9.407178859999933],
              [-40.511110515, -9.407293507999954],
              [-40.513752444, -9.408082500999962],
              [-40.518391134999902, -9.411143936999958],
              [-40.520068148999897, -9.412250730999963],
              [-40.522031752, -9.412823376999942],
              [-40.524122674, -9.413433151999929],
              [-40.529114735, -9.413901645999939],
              [-40.531695472, -9.414956981999984],
              [-40.53242915, -9.41558896799995],
              [-40.534474621999898, -9.417350926999964],
              [-40.535130573999908, -9.417915960999942],
              [-40.538221959999902, -9.420578864999975],
              [-40.540815236999897, -9.424043299999946],
              [-40.541785721999901, -9.42602813699995],
              [-40.542637833999898, -9.428905530999932],
              [-40.543714649, -9.432541697999975],
              [-40.545011030999902, -9.441131177999983],
              [-40.545187274999897, -9.442298925999975],
              [-40.546859536999897, -9.446308128999924],
              [-40.548367206, -9.448994132999927],
              [-40.549424340042187, -9.450461434175612],
              [-40.599045178706341, -9.450461434175612],
              [-40.599045178706341, -9.350461434175612],
              [-40.499045178706339, -9.350461434175612],
              [-40.499045178706339, -9.408888924476406],
              [-40.499162466, -9.408909007999972],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '7',
        ID: 36355.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -9.4005,
        ANNUAL: 5726.0,
        JAN: 6393.0,
        FEB: 6124.0,
        MAR: 6025.0,
        APR: 5236.0,
        MAY: 4773.0,
        JUN: 4527.0,
        JUL: 4754.0,
        AUG: 5478.0,
        SEP: 6232.0,
        OCT: 6302.0,
        NOV: 6492.0,
        DEC: 6374.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.401992218, -9.365188764999971],
              [-40.404726453, -9.362907324999977],
              [-40.409842956, -9.355676342999962],
              [-40.411646192, -9.354170947999922],
              [-40.414198696, -9.352954082999933],
              [-40.418145291, -9.352053270999926],
              [-40.420889727, -9.352571993999968],
              [-40.423659468, -9.353465009999974],
              [-40.430788194999899, -9.356694495999934],
              [-40.433694022999902, -9.359171503999958],
              [-40.435898257, -9.362158710999946],
              [-40.439306017, -9.368280893999943],
              [-40.440689316, -9.371442917999966],
              [-40.447643110999898, -9.38733834299995],
              [-40.448694311999901, -9.389384826999954],
              [-40.449387467999898, -9.390734317999934],
              [-40.450421757, -9.392747821999921],
              [-40.450920847, -9.393719475999934],
              [-40.451860703, -9.395549262999955],
              [-40.452726248, -9.397234322999964],
              [-40.452774755999897, -9.398303539999972],
              [-40.452888277999897, -9.400804884999964],
              [-40.45329243, -9.401983377999946],
              [-40.453818543, -9.403517362999921],
              [-40.456076061, -9.40642253099997],
              [-40.459204342, -9.409072195999951],
              [-40.464265881999907, -9.413359335999921],
              [-40.465170068, -9.415553081999974],
              [-40.465214316, -9.415660437999971],
              [-40.46616275, -9.417961539999967],
              [-40.467755118, -9.420568129999939],
              [-40.4707300869999, -9.421213941999957],
              [-40.474174739, -9.420792208999956],
              [-40.474264466, -9.42075550099997],
              [-40.475067812, -9.420426850999947],
              [-40.477834207999898, -9.419295113999956],
              [-40.481811686999897, -9.416278140999959],
              [-40.483924169, -9.413967422999963],
              [-40.487242748, -9.412250286999955],
              [-40.487282276, -9.412229832999971],
              [-40.495007921, -9.409261525999971],
              [-40.495186449, -9.409205306999983],
              [-40.495682529, -9.409049190999953],
              [-40.497196437999897, -9.408572356999972],
              [-40.497747142, -9.408666656999964],
              [-40.499045178706339, -9.408888924476406],
              [-40.499045178706339, -9.350461434175612],
              [-40.399045178706338, -9.350461434175612],
              [-40.399045178706338, -9.3671082125074],
              [-40.401992218, -9.365188764999971],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '8',
        ID: 36356.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -9.4005,
        ANNUAL: 5752.0,
        JAN: 6377.0,
        FEB: 6108.0,
        MAR: 6084.0,
        APR: 5301.0,
        MAY: 4776.0,
        JUN: 4554.0,
        JUL: 4761.0,
        AUG: 5534.0,
        SEP: 6309.0,
        OCT: 6309.0,
        NOV: 6526.0,
        DEC: 6395.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.333754074, -9.350538338999973],
              [-40.33504333699991, -9.353901723999975],
              [-40.338865537999901, -9.359891761999949],
              [-40.348707541, -9.368664075999954],
              [-40.351102461, -9.371444061999973],
              [-40.351359628999901, -9.371742577999942],
              [-40.352611496, -9.373195724999968],
              [-40.353414766999897, -9.374128148999944],
              [-40.353794658, -9.374569119999924],
              [-40.3541982, -9.375037544999937],
              [-40.356397113, -9.37699426299997],
              [-40.359031017999897, -9.378129186999955],
              [-40.361261538999898, -9.378681442999943],
              [-40.36369945, -9.379285005999979],
              [-40.371415334999902, -9.378716737999975],
              [-40.391278397, -9.372166824999963],
              [-40.399045178706338, -9.3671082125074],
              [-40.399045178706338, -9.350461434175612],
              [-40.333766017140491, -9.350461434175612],
              [-40.333754074, -9.350538338999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '9',
        ID: 36389.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -9.4005,
        ANNUAL: 5415.0,
        JAN: 6185.0,
        FEB: 5924.0,
        MAR: 5955.0,
        APR: 5239.0,
        MAY: 4381.0,
        JUN: 3964.0,
        JUL: 4119.0,
        AUG: 4744.0,
        SEP: 5595.0,
        OCT: 5979.0,
        NOV: 6487.0,
        DEC: 6412.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.003364641, -9.352613537999957],
              [-37.004651173781482, -9.350461434175612],
              [-36.99904517870629, -9.350461434175612],
              [-36.99904517870629, -9.353998984344679],
              [-37.003364641, -9.352613537999957],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '10',
        ID: 36390.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -9.4005,
        ANNUAL: 5381.0,
        JAN: 6101.0,
        FEB: 5881.0,
        MAR: 5913.0,
        APR: 5183.0,
        MAY: 4355.0,
        JUN: 3949.0,
        JUL: 4113.0,
        AUG: 4707.0,
        SEP: 5570.0,
        OCT: 5929.0,
        NOV: 6472.0,
        DEC: 6399.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.937877042, -9.352821311999945],
              [-36.938402712, -9.35567699399996],
              [-36.936767593999903, -9.361214151999954],
              [-36.930437174, -9.370036326999987],
              [-36.931099563, -9.371581502999952],
              [-36.933253733, -9.374418742999975],
              [-36.936849923999901, -9.377425375999955],
              [-36.940269573, -9.380284451999964],
              [-36.944007321, -9.382340202999957],
              [-36.944008891, -9.382340834999976],
              [-36.946485849999902, -9.383022284999974],
              [-36.946487608, -9.383022447999965],
              [-36.952431869, -9.382543473999922],
              [-36.952433679, -9.382543443999969],
              [-36.9554646469999, -9.381251128999953],
              [-36.958528738, -9.378700567999948],
              [-36.961286825999899, -9.375635194999978],
              [-36.969287387, -9.369048670999977],
              [-36.976059273, -9.362183768999961],
              [-36.976172812999899, -9.362068734999983],
              [-36.976952914, -9.361388411999947],
              [-36.979081182999899, -9.35953071299997],
              [-36.979640393, -9.359328250999974],
              [-36.982430452, -9.358317710999927],
              [-36.982432117, -9.358317275999987],
              [-36.987501466, -9.357271009999984],
              [-36.991265772, -9.356494091999922],
              [-36.991267449, -9.356493652999967],
              [-36.99904517870629, -9.353998984344679],
              [-36.99904517870629, -9.350461434175612],
              [-36.936811227386997, -9.350461434175612],
              [-36.937877042, -9.352821311999945],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '11',
        ID: 36730.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -9.3005,
        ANNUAL: 5817.0,
        JAN: 6416.0,
        FEB: 6063.0,
        MAR: 6001.0,
        APR: 5282.0,
        MAY: 4880.0,
        JUN: 4699.0,
        JUL: 4951.0,
        AUG: 5687.0,
        SEP: 6399.0,
        OCT: 6484.0,
        NOV: 6585.0,
        DEC: 6354.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.740935801, -9.348366732999983],
              [-40.741403080999902, -9.346783581999938],
              [-40.740672731, -9.343158205999933],
              [-40.737648502, -9.338753834999983],
              [-40.737286465, -9.336870765999958],
              [-40.739347395, -9.33134388599996],
              [-40.742306503999899, -9.327209877999959],
              [-40.751629823, -9.318284733999963],
              [-40.755011796, -9.317053968999975],
              [-40.756421944, -9.31503225899996],
              [-40.755592024999899, -9.313548823999954],
              [-40.755589203999897, -9.313543781999954],
              [-40.755487113999898, -9.313361301999974],
              [-40.753746062999909, -9.310249265999975],
              [-40.751588655, -9.308027819999952],
              [-40.749063706999898, -9.307458517999981],
              [-40.7474589609999, -9.306621901999961],
              [-40.743981936999901, -9.306225285999966],
              [-40.742897102, -9.304277262999955],
              [-40.742450394999899, -9.302243926999944],
              [-40.742619815999902, -9.300508855999965],
              [-40.741840967, -9.298870812999958],
              [-40.739441826, -9.296588726999977],
              [-40.737448876, -9.296780168999925],
              [-40.734140812, -9.299866732999932],
              [-40.730431128, -9.301476967999964],
              [-40.72864847599989, -9.303118202999936],
              [-40.725013531999899, -9.304463901999942],
              [-40.723247386, -9.305673692999958],
              [-40.720088161, -9.305896696999982],
              [-40.716820213999902, -9.303875731999941],
              [-40.714066072, -9.30038276799996],
              [-40.708290917999904, -9.29607768899996],
              [-40.707314852, -9.294550765999922],
              [-40.707063311999903, -9.292941709999978],
              [-40.707408766, -9.291654020999946],
              [-40.708689664, -9.286875976999976],
              [-40.70758889, -9.285346332999953],
              [-40.706038554, -9.285041556999936],
              [-40.701288838000011, -9.282332458999974],
              [-40.699045178706342, -9.282057852192683],
              [-40.699045178706342, -9.350461434175612],
              [-40.739753770545164, -9.350461434175612],
              [-40.740935801, -9.348366732999983],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '12',
        ID: 36731.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -9.3005,
        ANNUAL: 5804.0,
        JAN: 6429.0,
        FEB: 6056.0,
        MAR: 6025.0,
        APR: 5286.0,
        MAY: 4831.0,
        JUN: 4646.0,
        JUL: 4901.0,
        AUG: 5643.0,
        SEP: 6382.0,
        OCT: 6475.0,
        NOV: 6609.0,
        DEC: 6373.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.692252475, -9.281226476999963],
              [-40.687233981, -9.278333138999935],
              [-40.685987868, -9.276368183999976],
              [-40.685623346999897, -9.274578739999971],
              [-40.685444117, -9.273698894999939],
              [-40.684900367, -9.271029606999953],
              [-40.683271737, -9.267259788999979],
              [-40.681147918, -9.263482650999944],
              [-40.680088543999901, -9.259211747999919],
              [-40.680483785, -9.257691528999942],
              [-40.682703284999903, -9.257310028999942],
              [-40.686661474999902, -9.258019182999925],
              [-40.689236525999902, -9.256985901999929],
              [-40.690258139, -9.252638582999969],
              [-40.6900952189999, -9.25144627299994],
              [-40.689545270394177, -9.250461434175612],
              [-40.599045178706341, -9.250461434175612],
              [-40.599045178706341, -9.350461434175612],
              [-40.699045178706342, -9.350461434175612],
              [-40.699045178706342, -9.282057852192683],
              [-40.692252475, -9.281226476999963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '13',
        ID: 36732.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -9.3005,
        ANNUAL: 5785.0,
        JAN: 6379.0,
        FEB: 6130.0,
        MAR: 6047.0,
        APR: 5279.0,
        MAY: 4822.0,
        JUN: 4576.0,
        JUL: 4835.0,
        AUG: 5574.0,
        SEP: 6345.0,
        OCT: 6439.0,
        NOV: 6553.0,
        DEC: 6445.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -9.350461434175612],
              [-40.599045178706341, -9.250461434175612],
              [-40.499045178706339, -9.250461434175612],
              [-40.499045178706339, -9.350461434175612],
              [-40.599045178706341, -9.350461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '14',
        ID: 36733.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -9.3005,
        ANNUAL: 5763.0,
        JAN: 6340.0,
        FEB: 6073.0,
        MAR: 6029.0,
        APR: 5283.0,
        MAY: 4803.0,
        JUN: 4553.0,
        JUL: 4808.0,
        AUG: 5581.0,
        SEP: 6356.0,
        OCT: 6411.0,
        NOV: 6509.0,
        DEC: 6408.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -9.350461434175612],
              [-40.499045178706339, -9.250461434175612],
              [-40.399045178706338, -9.250461434175612],
              [-40.399045178706338, -9.350461434175612],
              [-40.499045178706339, -9.350461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '15',
        ID: 36734.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -9.3005,
        ANNUAL: 5718.0,
        JAN: 6269.0,
        FEB: 6030.0,
        MAR: 6032.0,
        APR: 5295.0,
        MAY: 4757.0,
        JUN: 4504.0,
        JUL: 4759.0,
        AUG: 5500.0,
        SEP: 6321.0,
        OCT: 6317.0,
        NOV: 6475.0,
        DEC: 6354.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.3022067859999, -9.251703299999971],
              [-40.305283043, -9.25776121699994],
              [-40.305288689, -9.258869719999947],
              [-40.305294696, -9.260049150999983],
              [-40.305309178999899, -9.262892556999924],
              [-40.307787122, -9.271320671999934],
              [-40.309535371, -9.275506664999973],
              [-40.311837941, -9.27903298399997],
              [-40.314254112999897, -9.282404272999941],
              [-40.319031351, -9.287269243999965],
              [-40.325613057999902, -9.305856004999955],
              [-40.331612350999897, -9.316014756999953],
              [-40.332531933, -9.317571898999965],
              [-40.336119588, -9.327168681999972],
              [-40.337119524999899, -9.33140228799993],
              [-40.337337557999902, -9.335067964999944],
              [-40.336586161, -9.341810020999961],
              [-40.334285490999903, -9.347116413999972],
              [-40.333766017140491, -9.350461434175612],
              [-40.399045178706338, -9.350461434175612],
              [-40.399045178706338, -9.250461434175612],
              [-40.302222160033303, -9.250461434175612],
              [-40.3022067859999, -9.251703299999971],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '16',
        ID: 36755.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -9.3005,
        ANNUAL: 5596.0,
        JAN: 6298.0,
        FEB: 6308.0,
        MAR: 6111.0,
        APR: 5481.0,
        MAY: 4638.0,
        JUN: 4154.0,
        JUL: 4359.0,
        AUG: 4987.0,
        SEP: 5824.0,
        OCT: 6055.0,
        NOV: 6496.0,
        DEC: 6441.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.202032386, -9.296805308999973],
              [-38.202251447, -9.296825411999981],
              [-38.203080645999897, -9.296901595999932],
              [-38.220575858, -9.312456360999928],
              [-38.2297810909999, -9.321836897999964],
              [-38.237588926999898, -9.329793446999926],
              [-38.237590046, -9.329794523999968],
              [-38.239541247, -9.327128872999946],
              [-38.240443908, -9.324937342999924],
              [-38.24134657, -9.322745811999942],
              [-38.247465653999903, -9.302862210999931],
              [-38.24859155, -9.299203678999964],
              [-38.249226189, -9.296946879999947],
              [-38.24934501, -9.296364567999941],
              [-38.250545642, -9.290480556999977],
              [-38.253059386, -9.286261864999974],
              [-38.253486623999898, -9.285544851999926],
              [-38.257428283, -9.277232715999956],
              [-38.257787791999903, -9.276474586999941],
              [-38.258912473999899, -9.274102865999964],
              [-38.259203563, -9.273489019999946],
              [-38.259275378, -9.273337613999953],
              [-38.259323486, -9.273241802999953],
              [-38.259470611, -9.272948826999933],
              [-38.259512050999902, -9.272866305999973],
              [-38.259530497999897, -9.27282957099993],
              [-38.259546319, -9.27279806599995],
              [-38.259578099999899, -9.272734779999942],
              [-38.270175665, -9.251631420999958],
              [-38.270763202254599, -9.250461434175612],
              [-38.199045178706307, -9.250461434175612],
              [-38.199045178706307, -9.296531483960417],
              [-38.202032386, -9.296805308999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '17',
        ID: 36756.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -9.3005,
        ANNUAL: 5528.0,
        JAN: 6276.0,
        FEB: 6134.0,
        MAR: 5975.0,
        APR: 5379.0,
        MAY: 4537.0,
        JUN: 4065.0,
        JUL: 4285.0,
        AUG: 4883.0,
        SEP: 5773.0,
        OCT: 6064.0,
        NOV: 6569.0,
        DEC: 6400.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.156419480999901, -9.255239557999964],
              [-38.157416909999903, -9.260171405999928],
              [-38.157377789999899, -9.26261921199994],
              [-38.157982654, -9.263829618999983],
              [-38.158473955, -9.264487467999974],
              [-38.158965254999899, -9.265145316999957],
              [-38.160740931, -9.267522937999964],
              [-38.165817121, -9.271318567999961],
              [-38.173559215999902, -9.285137776999935],
              [-38.177007534, -9.288653489999941],
              [-38.179553978999898, -9.29125019499998],
              [-38.180679027999901, -9.291676943999954],
              [-38.191698543, -9.295858047999968],
              [-38.199045178706307, -9.296531483960417],
              [-38.199045178706307, -9.250461434175612],
              [-38.156377414610645, -9.250461434175612],
              [-38.156419480999901, -9.255239557999964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '18',
        ID: 36766.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -9.3005,
        ANNUAL: 5422.0,
        JAN: 6168.0,
        FEB: 5949.0,
        MAR: 5981.0,
        APR: 5266.0,
        MAY: 4369.0,
        JUN: 3942.0,
        JUL: 4097.0,
        AUG: 4647.0,
        SEP: 5674.0,
        OCT: 6074.0,
        NOV: 6508.0,
        DEC: 6393.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.126262148, -9.252952405999961],
              [-37.129426871, -9.259104227999954],
              [-37.129918932, -9.260060848999961],
              [-37.129920328, -9.260063411999969],
              [-37.14239394, -9.268079276999966],
              [-37.142395713, -9.268079538999933],
              [-37.143032650999899, -9.268241903999989],
              [-37.143064033, -9.268249808999936],
              [-37.150986181, -9.270268163999967],
              [-37.152582068999898, -9.270674813999959],
              [-37.156528250999898, -9.273415488999941],
              [-37.156530008999901, -9.273415778999956],
              [-37.164333948, -9.275705204999946],
              [-37.164335706, -9.275705494999944],
              [-37.1693881489999, -9.272434048999978],
              [-37.169102634999902, -9.265710216999933],
              [-37.16908508400001, -9.265701508999946],
              [-37.169080182999899, -9.265591970999939],
              [-37.169061347, -9.265487806999937],
              [-37.168612025, -9.263003023999945],
              [-37.169721595999903, -9.257963413999954],
              [-37.168282607, -9.251825936999975],
              [-37.16714121169349, -9.250461434175612],
              [-37.124234239998593, -9.250461434175612],
              [-37.126262148, -9.252952405999961],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '19',
        ID: 36767.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -9.3005,
        ANNUAL: 5370.0,
        JAN: 6135.0,
        FEB: 5837.0,
        MAR: 5890.0,
        APR: 5191.0,
        MAY: 4346.0,
        JUN: 3908.0,
        JUL: 4103.0,
        AUG: 4644.0,
        SEP: 5571.0,
        OCT: 5958.0,
        NOV: 6483.0,
        DEC: 6376.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.006827320999903, -9.346821188999971],
              [-37.01283976, -9.339330458999939],
              [-37.016742173, -9.329875972999965],
              [-37.018377979, -9.323927492999937],
              [-37.020597338, -9.319854901999976],
              [-37.024289812999903, -9.315715039999933],
              [-37.028511455999897, -9.31401695999994],
              [-37.028513274, -9.314017018999948],
              [-37.032282364, -9.314090313999932],
              [-37.032284127, -9.314090571999943],
              [-37.0397908529999, -9.316212151999924],
              [-37.039792615, -9.31621242399997],
              [-37.043408004, -9.316335015999929],
              [-37.043409774999901, -9.316334862999947],
              [-37.045529266, -9.31588183099996],
              [-37.047524886, -9.314891291999967],
              [-37.0508491759999, -9.311623384999963],
              [-37.053063204999901, -9.308322951999978],
              [-37.054452641999909, -9.307133003999972],
              [-37.054454184, -9.307133409999954],
              [-37.055340165, -9.307447565999951],
              [-37.0572547149999, -9.312943215999953],
              [-37.057584409999897, -9.317540183999942],
              [-37.057585951, -9.317536393999934],
              [-37.059368144, -9.313151156999938],
              [-37.060788783, -9.307958225999931],
              [-37.061519049, -9.304816674999937],
              [-37.061537933999901, -9.304735434999937],
              [-37.061671224, -9.304162028999933],
              [-37.061925537, -9.297368755999958],
              [-37.058332154, -9.291345623999963],
              [-37.057199610999902, -9.281788382999936],
              [-37.063254502999897, -9.274130427999982],
              [-37.0673033099999, -9.267240253999942],
              [-37.072557226, -9.262539685],
              [-37.078113426999899, -9.256264756999931],
              [-37.080034305, -9.25470764399995],
              [-37.080035951, -9.254706626999955],
              [-37.080236860999896, -9.25454312599993],
              [-37.080904819, -9.254001641999931],
              [-37.085431240232985, -9.250461434175612],
              [-36.99904517870629, -9.250461434175612],
              [-36.99904517870629, -9.350461434175612],
              [-37.004651173781482, -9.350461434175612],
              [-37.006827320999903, -9.346821188999971],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '20',
        ID: 36768.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -9.3005,
        ANNUAL: 5427.0,
        JAN: 6135.0,
        FEB: 5897.0,
        MAR: 5913.0,
        APR: 5227.0,
        MAY: 4381.0,
        JUN: 3973.0,
        JUL: 4145.0,
        AUG: 4754.0,
        SEP: 5682.0,
        OCT: 6042.0,
        NOV: 6528.0,
        DEC: 6445.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.900827734, -9.282908411999935],
              [-36.913692247, -9.296055646999951],
              [-36.913778521, -9.29617584799996],
              [-36.915820466, -9.299020803999953],
              [-36.918058425, -9.304786600999927],
              [-36.921414952, -9.309557829999962],
              [-36.921818566, -9.311254774999949],
              [-36.921763347, -9.312217614999941],
              [-36.921752711, -9.312403077999935],
              [-36.921683367, -9.313612214999976],
              [-36.921616318, -9.314770575999944],
              [-36.922320408, -9.318165834999945],
              [-36.926762623999899, -9.322891870999966],
              [-36.927315468, -9.325411127999926],
              [-36.926788174, -9.327205856999967],
              [-36.924970296, -9.328838840999934],
              [-36.92276681, -9.329982170999928],
              [-36.920943432999898, -9.332487430999956],
              [-36.921028491, -9.335517704999976],
              [-36.922270216999898, -9.339761860999941],
              [-36.925347001, -9.343632643999973],
              [-36.930029444999903, -9.34699894799996],
              [-36.930031178, -9.346999276999947],
              [-36.935058279, -9.348749364999945],
              [-36.936551463999898, -9.34988627799993],
              [-36.936811227386997, -9.350461434175612],
              [-36.99904517870629, -9.350461434175612],
              [-36.99904517870629, -9.250461434175612],
              [-36.899045178706288, -9.250461434175612],
              [-36.899045178706288, -9.281850669422255],
              [-36.900827734, -9.282908411999935],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '21',
        ID: 36769.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -9.3005,
        ANNUAL: 5405.0,
        JAN: 6084.0,
        FEB: 5924.0,
        MAR: 5917.0,
        APR: 5264.0,
        MAY: 4374.0,
        JUN: 3951.0,
        JUL: 4085.0,
        AUG: 4728.0,
        SEP: 5631.0,
        OCT: 6000.0,
        NOV: 6480.0,
        DEC: 6416.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.825441465, -9.28641029599993],
              [-36.836041247, -9.28862856899997],
              [-36.837147731999899, -9.288503567999948],
              [-36.837899106, -9.288418684999943],
              [-36.839710636999897, -9.288213993999932],
              [-36.839712439, -9.288213935999986],
              [-36.840246372, -9.287578687999947],
              [-36.840837087999901, -9.285155590999981],
              [-36.844201014, -9.282342401999937],
              [-36.844569395999898, -9.281679399999966],
              [-36.8467767389999, -9.277706692999976],
              [-36.848370028, -9.276695205999944],
              [-36.850391139, -9.275872501999963],
              [-36.85319928, -9.276151401999938],
              [-36.856013935, -9.272908555999953],
              [-36.856015738999901, -9.272908505999965],
              [-36.85678899, -9.272828990999928],
              [-36.857672841, -9.273361474999939],
              [-36.858352225, -9.272668200999934],
              [-36.86444784, -9.269809814999968],
              [-36.864449657, -9.269809861999931],
              [-36.865814212, -9.269817907999933],
              [-36.867469375, -9.268531503999947],
              [-36.867471174999899, -9.268531439999945],
              [-36.871259435999903, -9.268084577999963],
              [-36.871261150999899, -9.268084761999944],
              [-36.872962696999899, -9.26868258799993],
              [-36.875077997999902, -9.274348034999949],
              [-36.879781559, -9.276374665999976],
              [-36.874772472, -9.276960806999965],
              [-36.879746877999899, -9.278803450999924],
              [-36.8797484729999, -9.278804056999943],
              [-36.884133431999899, -9.280914977999938],
              [-36.884922549, -9.281294779999939],
              [-36.884997352, -9.281330717999937],
              [-36.885081734, -9.281371322999973],
              [-36.889793684, -9.283638738999976],
              [-36.897945372, -9.28119805999995],
              [-36.899045178706288, -9.281850669422255],
              [-36.899045178706288, -9.250461434175612],
              [-36.799045178706287, -9.250461434175612],
              [-36.799045178706287, -9.280886204702417],
              [-36.825441465, -9.28641029599993],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '22',
        ID: 36770.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -9.3005,
        ANNUAL: 5403.0,
        JAN: 6044.0,
        FEB: 5956.0,
        MAR: 5863.0,
        APR: 5257.0,
        MAY: 4332.0,
        JUN: 3922.0,
        JUL: 4108.0,
        AUG: 4726.0,
        SEP: 5658.0,
        OCT: 5978.0,
        NOV: 6566.0,
        DEC: 6422.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699747484999897, -9.293005799999944],
              [-36.700148727999903, -9.29286639999998],
              [-36.721289902, -9.285521511999955],
              [-36.729663624999901, -9.282612303999946],
              [-36.760779532, -9.271801982999927],
              [-36.761772536, -9.271456992999966],
              [-36.774083377, -9.274605335999983],
              [-36.774552549, -9.274725320999941],
              [-36.776829542, -9.275307632999942],
              [-36.796832644999903, -9.280423175999937],
              [-36.799045178706287, -9.280886204702417],
              [-36.799045178706287, -9.250461434175612],
              [-36.699045178706285, -9.250461434175612],
              [-36.699045178706285, -9.293235321253455],
              [-36.699747484999897, -9.293005799999944],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '23',
        ID: 36771.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -9.3005,
        ANNUAL: 5338.0,
        JAN: 6058.0,
        FEB: 5895.0,
        MAR: 5859.0,
        APR: 5169.0,
        MAY: 4215.0,
        JUN: 3772.0,
        JUL: 3933.0,
        AUG: 4540.0,
        SEP: 5540.0,
        OCT: 5989.0,
        NOV: 6615.0,
        DEC: 6470.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.6008671559999, -9.338757888999963],
              [-36.603139375, -9.338158801999953],
              [-36.603579911999901, -9.338865380999948],
              [-36.603087199999898, -9.340764992999937],
              [-36.603624979, -9.341512509999971],
              [-36.603627705999905, -9.341512569999967],
              [-36.605047015, -9.341508204999977],
              [-36.606553084, -9.340716084999936],
              [-36.607270024999899, -9.33872647299995],
              [-36.608302075, -9.337846357999979],
              [-36.607599985, -9.334731895999935],
              [-36.607719443, -9.333464325999962],
              [-36.608570629, -9.332858931999979],
              [-36.608573116999899, -9.332859312999965],
              [-36.610472165, -9.333226702999969],
              [-36.610473958999897, -9.33322691699993],
              [-36.611793379999902, -9.333981482999945],
              [-36.612845114, -9.333928731999949],
              [-36.614747775999902, -9.331026056999978],
              [-36.615073337999902, -9.330291444999935],
              [-36.615624346999901, -9.329048123999941],
              [-36.615653874, -9.32714835299994],
              [-36.613723427, -9.324847726999964],
              [-36.613824011, -9.323805291999973],
              [-36.613826711999899, -9.323805197999945],
              [-36.614278673999898, -9.323751940999946],
              [-36.614996229, -9.323667386999945],
              [-36.617937856, -9.325968085999929],
              [-36.619322605, -9.326568107999954],
              [-36.6196287409999, -9.328802350999981],
              [-36.619631179, -9.328800369999955],
              [-36.622356563, -9.326586095999977],
              [-36.62235929, -9.326586168999935],
              [-36.623431584999899, -9.32659390999993],
              [-36.624953785999899, -9.327751238999946],
              [-36.624535320999897, -9.331665802999954],
              [-36.624855448999902, -9.331831900999939],
              [-36.625796981, -9.332320678999961],
              [-36.627879688999897, -9.332335035999966],
              [-36.628517401, -9.331750679999979],
              [-36.628344001, -9.329218515999967],
              [-36.628729157, -9.328278521999952],
              [-36.628731883999897, -9.328278593999929],
              [-36.631888776, -9.328298921999936],
              [-36.633726313, -9.327722959999953],
              [-36.634616576, -9.326903547999951],
              [-36.635483656, -9.324294024999972],
              [-36.635697261, -9.323651169999957],
              [-36.635896505999902, -9.323051531999969],
              [-36.634844266, -9.321255253999935],
              [-36.635454234, -9.320784797999975],
              [-36.635456029, -9.320784910999976],
              [-36.639271353, -9.32104612699993],
              [-36.641807110999899, -9.319768151999938],
              [-36.641884892999897, -9.31764950699994],
              [-36.642590756999901, -9.316064309999932],
              [-36.644686394, -9.314371535999948],
              [-36.644689118, -9.314371666999941],
              [-36.646078216, -9.314439037999938],
              [-36.648789211999897, -9.315574353999979],
              [-36.6487908739999, -9.315574527999967],
              [-36.6497364169999, -9.315406008999968],
              [-36.651600045999899, -9.313570979999954],
              [-36.65161156100001, -9.313559635999983],
              [-36.651652563, -9.313519246999931],
              [-36.651694662999901, -9.313477775999962],
              [-36.65217975, -9.312999949999943],
              [-36.652239296999902, -9.312941295999964],
              [-36.652263628, -9.31291732899995],
              [-36.652275457, -9.312905676999948],
              [-36.652280270999903, -9.312900935999943],
              [-36.652285586999902, -9.312895699999951],
              [-36.652290364999899, -9.312890993999932],
              [-36.652300399999902, -9.312881109999989],
              [-36.652303774, -9.312877785999945],
              [-36.652341363999902, -9.312840758999936],
              [-36.652357337, -9.312825025999935],
              [-36.652366185999902, -9.312816310999946],
              [-36.652379197, -9.312803493999976],
              [-36.652410137999901, -9.312773017999969],
              [-36.654621887, -9.312669214999969],
              [-36.654624483, -9.312669177999963],
              [-36.655140284, -9.313583710999984],
              [-36.656146184, -9.314628299999981],
              [-36.657461591, -9.315595510999977],
              [-36.658931752999898, -9.316059772999948],
              [-36.660479290999902, -9.315672887999936],
              [-36.661794699, -9.314821741999943],
              [-36.663071418, -9.314125349999932],
              [-36.665895675999899, -9.313931906999921],
              [-36.668333049, -9.313506333999953],
              [-36.670422226, -9.313235514999963],
              [-36.671737633, -9.311997483999964],
              [-36.672163205999901, -9.310449945999952],
              [-36.672627468, -9.309598799999947],
              [-36.673439926, -9.309560110999934],
              [-36.673788122, -9.310256503999947],
              [-36.674484514, -9.310449945999958],
              [-36.674871399, -9.310217814999923],
              [-36.675761232999911, -9.308979783999972],
              [-36.677656968, -9.307084049999963],
              [-36.679127128999909, -9.306155526999934],
              [-36.681525813999897, -9.304956184999977],
              [-36.683808433, -9.30317651499996],
              [-36.685626790999898, -9.302286680999938],
              [-36.687445147999902, -9.300816518999977],
              [-36.688064164, -9.298611276999964],
              [-36.689224818, -9.29644472299997],
              [-36.689392213, -9.296390016999961],
              [-36.698627885999898, -9.293371696999939],
              [-36.699045178706285, -9.293235321253455],
              [-36.699045178706285, -9.250461434175612],
              [-36.599045178706284, -9.250461434175612],
              [-36.599045178706284, -9.33735357311128],
              [-36.6008671559999, -9.338757888999963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '24',
        ID: 36772.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -9.3005,
        ANNUAL: 5231.0,
        JAN: 5966.0,
        FEB: 5764.0,
        MAR: 5813.0,
        APR: 5111.0,
        MAY: 4173.0,
        JUN: 3672.0,
        JUL: 3819.0,
        AUG: 4430.0,
        SEP: 5425.0,
        OCT: 5800.0,
        NOV: 6429.0,
        DEC: 6365.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.500373141, -9.262785593999977],
              [-36.500765567, -9.263684271999978],
              [-36.499585937999903, -9.264569191999955],
              [-36.499635942999902, -9.265230617999981],
              [-36.501822792, -9.265939253999937],
              [-36.5049054569999, -9.266938172999966],
              [-36.551606184, -9.282071263999963],
              [-36.553216313, -9.282593016999943],
              [-36.571839564, -9.292582841999938],
              [-36.571836488, -9.295216860999977],
              [-36.573026851, -9.297464163999967],
              [-36.572997615, -9.298731993999972],
              [-36.572980234999903, -9.299485683999924],
              [-36.573718563999897, -9.302268692999972],
              [-36.574448801, -9.309493657999946],
              [-36.574246698, -9.310809968999937],
              [-36.573191117, -9.311974629999957],
              [-36.57290202, -9.314475799999967],
              [-36.571431091, -9.316045294999981],
              [-36.572688395999897, -9.318619732999929],
              [-36.572758099999902, -9.32000169799994],
              [-36.571908661999899, -9.320033513999926],
              [-36.571906161, -9.320033550999947],
              [-36.568208086, -9.318274327999971],
              [-36.568205798999898, -9.318273826999929],
              [-36.566788728999903, -9.319483811999934],
              [-36.567384035, -9.32097684499996],
              [-36.56680432, -9.32174459599997],
              [-36.564264092, -9.32224813599993],
              [-36.563906851, -9.322782109999929],
              [-36.564046631999901, -9.323505635999938],
              [-36.566911597999898, -9.325187334999942],
              [-36.566949812, -9.325776222999931],
              [-36.565828208, -9.327105644999962],
              [-36.566649624, -9.328257738999975],
              [-36.566576126, -9.331437425999979],
              [-36.567469122, -9.332134823999978],
              [-36.567471423, -9.33213461899993],
              [-36.569285905999898, -9.331613304999964],
              [-36.569287642999903, -9.331613071999923],
              [-36.570985247, -9.331622667999968],
              [-36.5725076169999, -9.332165096999965],
              [-36.572510279, -9.332166054999957],
              [-36.5738850709999, -9.33356421299993],
              [-36.574562317999899, -9.333652041999926],
              [-36.575118435, -9.331993075999941],
              [-36.575121135, -9.331992979999946],
              [-36.576078325, -9.331879940999945],
              [-36.577688555999899, -9.334628368999967],
              [-36.5776903739999, -9.334628404999926],
              [-36.581567801999903, -9.334606013999977],
              [-36.5826807299999, -9.335599427999972],
              [-36.582682548, -9.335599476999956],
              [-36.584623185999909, -9.335613511999947],
              [-36.585009575999898, -9.335969290999975],
              [-36.584873592999898, -9.337130286999978],
              [-36.585282778, -9.337967848999936],
              [-36.585285493999898, -9.337968073999951],
              [-36.586265671, -9.338084889999944],
              [-36.586267482, -9.338085040999948],
              [-36.591507082, -9.334701247999929],
              [-36.593070176, -9.334826350999958],
              [-36.593956245999898, -9.33535722999993],
              [-36.594548566999897, -9.337318862999959],
              [-36.595108184, -9.337543131999952],
              [-36.596639087, -9.336842648999953],
              [-36.596641814, -9.336842701999961],
              [-36.598363303, -9.336830017999949],
              [-36.598365934999897, -9.336830035999974],
              [-36.599045178706284, -9.33735357311128],
              [-36.599045178706284, -9.250461434175612],
              [-36.499045178706282, -9.250461434175612],
              [-36.499045178706282, -9.264426332233437],
              [-36.500373141, -9.262785593999977],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '25',
        ID: 36773.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -9.3005,
        ANNUAL: 5273.0,
        JAN: 5955.0,
        FEB: 5861.0,
        MAR: 5834.0,
        APR: 5181.0,
        MAY: 4246.0,
        JUN: 3754.0,
        JUL: 3908.0,
        AUG: 4536.0,
        SEP: 5438.0,
        OCT: 5828.0,
        NOV: 6433.0,
        DEC: 6301.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.489065962, -9.250483505999975],
              [-36.489792744999903, -9.251454862999944],
              [-36.4898088039999, -9.252640578999936],
              [-36.489811501, -9.252640472999923],
              [-36.491486403, -9.25242757099994],
              [-36.492689891999902, -9.254178267999976],
              [-36.491616424, -9.256948177999952],
              [-36.491986212, -9.259040258999967],
              [-36.490405462, -9.261214272999949],
              [-36.491337718, -9.262408215999926],
              [-36.491340132999902, -9.262407943999957],
              [-36.492912401, -9.26190618099997],
              [-36.494908554999903, -9.261932095999958],
              [-36.495617191999898, -9.262256862999953],
              [-36.497412940999901, -9.26445721499994],
              [-36.498652095999901, -9.264911997999945],
              [-36.499045178706282, -9.264426332233437],
              [-36.499045178706282, -9.250461434175612],
              [-36.489063069283652, -9.250461434175612],
              [-36.489065962, -9.250483505999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '26',
        ID: 37109.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -9.2005,
        ANNUAL: 5764.0,
        JAN: 6350.0,
        FEB: 5953.0,
        MAR: 5939.0,
        APR: 5234.0,
        MAY: 4812.0,
        JUN: 4639.0,
        JUL: 4886.0,
        AUG: 5665.0,
        SEP: 6424.0,
        OCT: 6456.0,
        NOV: 6519.0,
        DEC: 6293.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.6874317689999, -9.246676610999945],
              [-40.686455630999902, -9.24355456899997],
              [-40.687056873, -9.242263852999931],
              [-40.689457263, -9.241125387999944],
              [-40.690298251999899, -9.238873216999927],
              [-40.690350480999903, -9.238733348999974],
              [-40.690849183999902, -9.237397819999961],
              [-40.69100664, -9.234062851999964],
              [-40.694500379, -9.233455570999974],
              [-40.693445282, -9.231275584999935],
              [-40.689872924, -9.225926901999969],
              [-40.6807039729999, -9.212198763999931],
              [-40.681564174, -9.210845960999965],
              [-40.682207580999901, -9.208013392999932],
              [-40.681978953999902, -9.204741324999921],
              [-40.680561691, -9.200305342999973],
              [-40.678224778999905, -9.196298164999977],
              [-40.677057320999893, -9.193311451999934],
              [-40.675472315, -9.193926037999974],
              [-40.673362509999897, -9.192803050999942],
              [-40.670643783999907, -9.188708240999972],
              [-40.669873159, -9.186132510999935],
              [-40.670132649, -9.184543237999943],
              [-40.67018989, -9.184192662999976],
              [-40.67024713, -9.183842087999944],
              [-40.671102029999901, -9.182180606999953],
              [-40.671956931, -9.180519124999927],
              [-40.672042003999898, -9.178856404999982],
              [-40.670532564, -9.175354288999925],
              [-40.672092107, -9.17238723599996],
              [-40.669695786, -9.16500092799993],
              [-40.667224702999903, -9.160493181999925],
              [-40.667395665999898, -9.159059198999961],
              [-40.672319052, -9.156501790999933],
              [-40.677728787, -9.151246124999938],
              [-40.67812278066598, -9.150461434175613],
              [-40.599045178706341, -9.150461434175613],
              [-40.599045178706341, -9.250461434175612],
              [-40.689545270394177, -9.250461434175612],
              [-40.6874317689999, -9.246676610999945],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '27',
        ID: 37110.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -9.2005,
        ANNUAL: 5773.0,
        JAN: 6267.0,
        FEB: 6028.0,
        MAR: 5944.0,
        APR: 5309.0,
        MAY: 4824.0,
        JUN: 4599.0,
        JUL: 4902.0,
        AUG: 5682.0,
        SEP: 6410.0,
        OCT: 6433.0,
        NOV: 6519.0,
        DEC: 6353.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -9.250461434175612],
              [-40.599045178706341, -9.150461434175613],
              [-40.499045178706339, -9.150461434175613],
              [-40.499045178706339, -9.250461434175612],
              [-40.599045178706341, -9.250461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '28',
        ID: 37111.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -9.2005,
        ANNUAL: 5705.0,
        JAN: 6217.0,
        FEB: 5964.0,
        MAR: 5921.0,
        APR: 5260.0,
        MAY: 4764.0,
        JUN: 4499.0,
        JUL: 4812.0,
        AUG: 5566.0,
        SEP: 6346.0,
        OCT: 6366.0,
        NOV: 6465.0,
        DEC: 6283.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -9.250461434175612],
              [-40.499045178706339, -9.150461434175613],
              [-40.399045178706338, -9.150461434175613],
              [-40.399045178706338, -9.250461434175612],
              [-40.499045178706339, -9.250461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '29',
        ID: 37112.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -9.2005,
        ANNUAL: 5687.0,
        JAN: 6223.0,
        FEB: 5959.0,
        MAR: 5970.0,
        APR: 5234.0,
        MAY: 4716.0,
        JUN: 4436.0,
        JUL: 4764.0,
        AUG: 5513.0,
        SEP: 6349.0,
        OCT: 6324.0,
        NOV: 6480.0,
        DEC: 6278.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.300506812, -9.231208023999951],
              [-40.301372393, -9.234923450999927],
              [-40.301502905999897, -9.236033551999979],
              [-40.302315237, -9.242942970999932],
              [-40.302222160033303, -9.250461434175612],
              [-40.399045178706338, -9.250461434175612],
              [-40.399045178706338, -9.150461434175613],
              [-40.299045178706336, -9.150461434175613],
              [-40.299045178706336, -9.227322120520467],
              [-40.300506812, -9.231208023999951],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '30',
        ID: 37113.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -9.2005,
        ANNUAL: 5609.0,
        JAN: 6147.0,
        FEB: 5896.0,
        MAR: 5828.0,
        APR: 5195.0,
        MAY: 4654.0,
        JUN: 4378.0,
        JUL: 4669.0,
        AUG: 5446.0,
        SEP: 6285.0,
        OCT: 6247.0,
        NOV: 6415.0,
        DEC: 6149.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.294011983, -9.152067785999982],
              [-40.293752661, -9.155236220999948],
              [-40.29357721, -9.155752894999974],
              [-40.292834989, -9.157938610999961],
              [-40.289473812, -9.165198620999934],
              [-40.287320671, -9.17108256699993],
              [-40.285815172999889, -9.181813931999949],
              [-40.286172656, -9.185611403999925],
              [-40.290079503, -9.195612707999945],
              [-40.290215152, -9.195959960999975],
              [-40.295725396, -9.213566766999966],
              [-40.297852045, -9.224150050999976],
              [-40.299045178706336, -9.227322120520467],
              [-40.299045178706336, -9.150461434175613],
              [-40.293806304702755, -9.150461434175613],
              [-40.294011983, -9.152067785999982],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '31',
        ID: 37132.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -9.2005,
        ANNUAL: 5634.0,
        JAN: 6361.0,
        FEB: 6257.0,
        MAR: 6119.0,
        APR: 5492.0,
        MAY: 4622.0,
        JUN: 4175.0,
        JUL: 4347.0,
        AUG: 5071.0,
        SEP: 5986.0,
        OCT: 6200.0,
        NOV: 6474.0,
        DEC: 6503.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.301293750999911, -9.160787243999946],
              [-38.304073708, -9.15551277899992],
              [-38.3058448049999, -9.153551255999952],
              [-38.305924417, -9.153462977999936],
              [-38.310017124559046, -9.150461434175613],
              [-38.299045178706308, -9.150461434175613],
              [-38.299045178706308, -9.163436522309835],
              [-38.301293750999911, -9.160787243999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '32',
        ID: 37133.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -9.2005,
        ANNUAL: 5580.0,
        JAN: 6344.0,
        FEB: 6151.0,
        MAR: 6081.0,
        APR: 5426.0,
        MAY: 4624.0,
        JUN: 4092.0,
        JUL: 4293.0,
        AUG: 4939.0,
        SEP: 5888.0,
        OCT: 6110.0,
        NOV: 6580.0,
        DEC: 6431.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.272581853, -9.246839880999977],
              [-38.284157510999897, -9.22378880499997],
              [-38.285574921, -9.219309614999933],
              [-38.286751927999902, -9.217985329999985],
              [-38.28751276, -9.217129295999936],
              [-38.2865891289999, -9.214093709999933],
              [-38.285734291, -9.213191460999957],
              [-38.284096763, -9.211463112999979],
              [-38.280075616, -9.207218947999937],
              [-38.280075892999903, -9.207110632999951],
              [-38.280077123, -9.206636729999966],
              [-38.280077639999902, -9.206437828999984],
              [-38.280077853, -9.206355560999958],
              [-38.280078038999903, -9.206284050999944],
              [-38.280078107, -9.206258993999946],
              [-38.280078128, -9.206249854999944],
              [-38.280078164, -9.206231271999963],
              [-38.28007832799991, -9.206155785999943],
              [-38.280079386, -9.205668211999983],
              [-38.280079868, -9.205446170999954],
              [-38.280086711, -9.202291542999944],
              [-38.280089277999899, -9.201125275999964],
              [-38.281488845, -9.194851778999979],
              [-38.282301084, -9.19329304399997],
              [-38.283943307999898, -9.190141516999976],
              [-38.283144951999901, -9.188762149999945],
              [-38.284237359, -9.186375573999955],
              [-38.284211058, -9.185274322999922],
              [-38.284184755999902, -9.18417307199997],
              [-38.284738504, -9.18213115899994],
              [-38.285517895999902, -9.17925719699997],
              [-38.287600983999901, -9.173883921999961],
              [-38.288707583, -9.172126236999926],
              [-38.298659594, -9.163890819999944],
              [-38.299045178706308, -9.163436522309835],
              [-38.299045178706308, -9.150461434175613],
              [-38.199045178706307, -9.150461434175613],
              [-38.199045178706307, -9.250461434175612],
              [-38.270763202254599, -9.250461434175612],
              [-38.272581853, -9.246839880999977],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '33',
        ID: 37134.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -9.2005,
        ANNUAL: 5562.0,
        JAN: 6308.0,
        FEB: 6092.0,
        MAR: 6029.0,
        APR: 5399.0,
        MAY: 4582.0,
        JUN: 4065.0,
        JUL: 4277.0,
        AUG: 4929.0,
        SEP: 5868.0,
        OCT: 6151.0,
        NOV: 6614.0,
        DEC: 6430.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099119269, -9.179408843999969],
              [-38.102578765, -9.180207653999965],
              [-38.10273774599991, -9.180537821999962],
              [-38.104692870999898, -9.184598610999961],
              [-38.11405464300001, -9.194001140999934],
              [-38.114366318999899, -9.195034279999954],
              [-38.114450501, -9.195313331999964],
              [-38.1153695799999, -9.19836020499997],
              [-38.117793854999903, -9.199975372999971],
              [-38.117859384, -9.200018940999938],
              [-38.119247085, -9.201803245999944],
              [-38.122343643, -9.203749579999956],
              [-38.125201294, -9.206294548999942],
              [-38.1283575369999, -9.209105489999958],
              [-38.128457469, -9.209175964999924],
              [-38.131289625, -9.211172611999924],
              [-38.135237643, -9.213955847999951],
              [-38.139615238, -9.215300166999947],
              [-38.140658904, -9.216280909999973],
              [-38.140938326, -9.216543484999924],
              [-38.143908671, -9.218204693999949],
              [-38.144989805, -9.221164082999966],
              [-38.145976759, -9.222437650999954],
              [-38.147095277999902, -9.225955931999984],
              [-38.148627400999899, -9.230774081999929],
              [-38.15023108, -9.232581324999972],
              [-38.152246416, -9.234851956999927],
              [-38.152977373999903, -9.236204846999955],
              [-38.154167006, -9.238405968999926],
              [-38.156306061, -9.242363766999979],
              [-38.156365663, -9.249126716999964],
              [-38.156367932, -9.249385451999968],
              [-38.156371258, -9.249762197999935],
              [-38.156373969999905, -9.25007017699993],
              [-38.156377414610645, -9.250461434175612],
              [-38.199045178706307, -9.250461434175612],
              [-38.199045178706307, -9.150461434175613],
              [-38.099045178706305, -9.150461434175613],
              [-38.099045178706305, -9.17939172448879],
              [-38.099119269, -9.179408843999969],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '34',
        ID: 37135.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -9.2005,
        ANNUAL: 5526.0,
        JAN: 6267.0,
        FEB: 6052.0,
        MAR: 5991.0,
        APR: 5370.0,
        MAY: 4534.0,
        JUN: 4049.0,
        JUL: 4239.0,
        AUG: 4897.0,
        SEP: 5808.0,
        OCT: 6121.0,
        NOV: 6580.0,
        DEC: 6409.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999482273999902, -9.152243597999934],
              [-38.005374714, -9.154436191999924],
              [-38.006653602, -9.154153239999969],
              [-38.009043501999898, -9.154865074999973],
              [-38.011012514999898, -9.156027893999978],
              [-38.012107247, -9.156043089999963],
              [-38.014925682999902, -9.156081945999963],
              [-38.020783617999903, -9.155215182999948],
              [-38.025787419, -9.155506332999945],
              [-38.027343227, -9.156573875999987],
              [-38.029211185, -9.158721698999946],
              [-38.029510549, -9.164513208999956],
              [-38.030292030999902, -9.164948102999968],
              [-38.032154816999899, -9.164860982999933],
              [-38.044541211, -9.168815417999953],
              [-38.046176761, -9.168908525999949],
              [-38.0527450229999, -9.167818934999957],
              [-38.053504405999902, -9.167693246999926],
              [-38.056981037, -9.167625641999951],
              [-38.058787201, -9.169275858999985],
              [-38.060537197, -9.171517170999946],
              [-38.063904519999902, -9.173780517999933],
              [-38.066097989999903, -9.174219911999963],
              [-38.0749286839999, -9.17131715899996],
              [-38.079262904, -9.170533215999962],
              [-38.082566583, -9.170585292999931],
              [-38.092287331, -9.172724977999957],
              [-38.095077047, -9.174980489999944],
              [-38.095156319999901, -9.175044683999943],
              [-38.097817393, -9.178699481999953],
              [-38.098678548999899, -9.179307009999958],
              [-38.099045178706305, -9.17939172448879],
              [-38.099045178706305, -9.150461434175613],
              [-37.999045178706304, -9.150461434175613],
              [-37.999045178706304, -9.152475471800104],
              [-37.999482273999902, -9.152243597999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '35',
        ID: 37136.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -9.2005,
        ANNUAL: 5577.0,
        JAN: 6327.0,
        FEB: 6052.0,
        MAR: 6092.0,
        APR: 5392.0,
        MAY: 4529.0,
        JUN: 4114.0,
        JUL: 4258.0,
        AUG: 4957.0,
        SEP: 5837.0,
        OCT: 6179.0,
        NOV: 6700.0,
        DEC: 6487.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.985514221, -9.151623727999946],
              [-37.987540247, -9.153685917999944],
              [-37.990986232, -9.15537622399995],
              [-37.993658389, -9.155333098999954],
              [-37.999045178706304, -9.152475471800104],
              [-37.999045178706304, -9.150461434175613],
              [-37.984967884013486, -9.150461434175613],
              [-37.985514221, -9.151623727999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '36',
        ID: 37143.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -9.2005,
        ANNUAL: 5477.0,
        JAN: 6238.0,
        FEB: 5994.0,
        MAR: 6034.0,
        APR: 5303.0,
        MAY: 4406.0,
        JUN: 4004.0,
        JUL: 4192.0,
        AUG: 4752.0,
        SEP: 5728.0,
        OCT: 6122.0,
        NOV: 6527.0,
        DEC: 6428.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.200645096, -9.217413158999923],
              [-37.202310071999896, -9.217860277999938],
              [-37.206527778, -9.217651628999931],
              [-37.207423502, -9.218241954999939],
              [-37.207534325, -9.218314857999923],
              [-37.208919969, -9.220179478999976],
              [-37.212170509, -9.221116791999972],
              [-37.216166963, -9.226264316999957],
              [-37.220306686999898, -9.228031392999974],
              [-37.222811893, -9.230853812999953],
              [-37.230448248999899, -9.237595879999954],
              [-37.234341489, -9.240175430999949],
              [-37.234008703, -9.236853827999937],
              [-37.234422367, -9.234136127999923],
              [-37.233891368999899, -9.232148913999938],
              [-37.2310064119999, -9.230977024999957],
              [-37.230398810999901, -9.230350317999973],
              [-37.229235622, -9.226882346999963],
              [-37.229226003999898, -9.226853685999973],
              [-37.228783856999897, -9.225536017999964],
              [-37.230400167, -9.223600987999921],
              [-37.232356006, -9.222317100999931],
              [-37.233033656, -9.217526351999945],
              [-37.232613761, -9.211910971999945],
              [-37.233559296, -9.210850938999942],
              [-37.233555204, -9.206585761999975],
              [-37.234811661, -9.204531968999966],
              [-37.236749618, -9.203670004999941],
              [-37.23917109, -9.203318723999931],
              [-37.24086414, -9.199780507999954],
              [-37.240618535, -9.198475092999962],
              [-37.243001863, -9.196397898999976],
              [-37.24461482399991, -9.193240823999925],
              [-37.246785315, -9.190382331999961],
              [-37.245169271, -9.187567034999963],
              [-37.24323149, -9.186037979999975],
              [-37.245221429, -9.183660327999963],
              [-37.247081593, -9.179018423999922],
              [-37.248590958, -9.178206495999966],
              [-37.249251031, -9.176420315999957],
              [-37.252993638, -9.173450092999923],
              [-37.25525124, -9.170257925999975],
              [-37.259837875999899, -9.165163415999917],
              [-37.259839629999902, -9.165162470999965],
              [-37.259934448, -9.165098843999941],
              [-37.260043176, -9.165025868999978],
              [-37.262008602, -9.163711884999934],
              [-37.262615632999903, -9.160951787999988],
              [-37.262400092, -9.160017885999922],
              [-37.262230517, -9.159283229999978],
              [-37.262559525, -9.15888170499994],
              [-37.266558620999902, -9.159048645999963],
              [-37.266995134, -9.158834234999977],
              [-37.268200064, -9.158242386999978],
              [-37.270975097, -9.155823469999961],
              [-37.276313538, -9.153531500999964],
              [-37.276315166, -9.153531029999957],
              [-37.277808150999903, -9.153320379999959],
              [-37.278487997999903, -9.152500070999954],
              [-37.278489795, -9.152497283999944],
              [-37.278863975, -9.152045561999964],
              [-37.278925885, -9.151136065999944],
              [-37.280470552520519, -9.150461434175613],
              [-37.199045178706292, -9.150461434175613],
              [-37.199045178706292, -9.216087405901837],
              [-37.200645096, -9.217413158999923],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '37',
        ID: 37144.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -9.2005,
        ANNUAL: 5509.0,
        JAN: 6244.0,
        FEB: 6015.0,
        MAR: 6072.0,
        APR: 5367.0,
        MAY: 4452.0,
        JUN: 4052.0,
        JUL: 4232.0,
        AUG: 4794.0,
        SEP: 5737.0,
        OCT: 6121.0,
        NOV: 6566.0,
        DEC: 6458.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.106271004, -9.239783441999986],
              [-37.108173747, -9.23871558399998],
              [-37.108175296, -9.238715895999972],
              [-37.116475556999902, -9.240930649999935],
              [-37.122520118, -9.248355899999977],
              [-37.124234239998593, -9.250461434175612],
              [-37.16714121169349, -9.250461434175612],
              [-37.164169425, -9.246908754999938],
              [-37.160750912, -9.243969414999974],
              [-37.158587687, -9.240127569999975],
              [-37.158946681, -9.239101720999948],
              [-37.159863904, -9.238248608999973],
              [-37.181254560999903, -9.225583929999969],
              [-37.182245479, -9.224997219999976],
              [-37.19831522, -9.215482533999928],
              [-37.199045178706292, -9.216087405901837],
              [-37.199045178706292, -9.150461434175613],
              [-37.099045178706291, -9.150461434175613],
              [-37.099045178706291, -9.243838660969994],
              [-37.106271004, -9.239783441999986],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '38',
        ID: 37145.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -9.2005,
        ANNUAL: 5416.0,
        JAN: 6090.0,
        FEB: 5918.0,
        MAR: 5998.0,
        APR: 5246.0,
        MAY: 4340.0,
        JUN: 3928.0,
        JUL: 4107.0,
        AUG: 4675.0,
        SEP: 5654.0,
        OCT: 6045.0,
        NOV: 6583.0,
        DEC: 6404.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.090672549, -9.246362097999953],
              [-37.098608406, -9.244083782999956],
              [-37.099045178706291, -9.243838660969994],
              [-37.099045178706291, -9.150461434175613],
              [-36.99904517870629, -9.150461434175613],
              [-36.99904517870629, -9.250461434175612],
              [-37.085431240232985, -9.250461434175612],
              [-37.090672549, -9.246362097999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '39',
        ID: 37146.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -9.2005,
        ANNUAL: 5395.0,
        JAN: 6045.0,
        FEB: 5868.0,
        MAR: 5970.0,
        APR: 5247.0,
        MAY: 4347.0,
        JUN: 3907.0,
        JUL: 4101.0,
        AUG: 4708.0,
        SEP: 5639.0,
        OCT: 5980.0,
        NOV: 6535.0,
        DEC: 6386.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -9.250461434175612],
              [-36.99904517870629, -9.150461434175613],
              [-36.899045178706288, -9.150461434175613],
              [-36.899045178706288, -9.250461434175612],
              [-36.99904517870629, -9.250461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '40',
        ID: 37147.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -9.2005,
        ANNUAL: 5408.0,
        JAN: 6042.0,
        FEB: 5860.0,
        MAR: 5941.0,
        APR: 5268.0,
        MAY: 4404.0,
        JUN: 3960.0,
        JUL: 4136.0,
        AUG: 4792.0,
        SEP: 5644.0,
        OCT: 5970.0,
        NOV: 6493.0,
        DEC: 6381.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -9.250461434175612],
              [-36.899045178706288, -9.150461434175613],
              [-36.799045178706287, -9.150461434175613],
              [-36.799045178706287, -9.250461434175612],
              [-36.899045178706288, -9.250461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '41',
        ID: 37148.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -9.2005,
        ANNUAL: 5380.0,
        JAN: 6034.0,
        FEB: 5899.0,
        MAR: 5903.0,
        APR: 5224.0,
        MAY: 4286.0,
        JUN: 3873.0,
        JUL: 4035.0,
        AUG: 4673.0,
        SEP: 5654.0,
        OCT: 6004.0,
        NOV: 6559.0,
        DEC: 6421.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -9.250461434175612],
              [-36.799045178706287, -9.150461434175613],
              [-36.699045178706285, -9.150461434175613],
              [-36.699045178706285, -9.250461434175612],
              [-36.799045178706287, -9.250461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '42',
        ID: 37149.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -9.2005,
        ANNUAL: 5356.0,
        JAN: 6063.0,
        FEB: 5901.0,
        MAR: 5895.0,
        APR: 5207.0,
        MAY: 4247.0,
        JUN: 3810.0,
        JUL: 3986.0,
        AUG: 4614.0,
        SEP: 5569.0,
        OCT: 5996.0,
        NOV: 6554.0,
        DEC: 6430.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -9.250461434175612],
              [-36.699045178706285, -9.150461434175613],
              [-36.599045178706284, -9.150461434175613],
              [-36.599045178706284, -9.250461434175612],
              [-36.699045178706285, -9.250461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '43',
        ID: 37150.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -9.2005,
        ANNUAL: 5228.0,
        JAN: 5958.0,
        FEB: 5738.0,
        MAR: 5745.0,
        APR: 5135.0,
        MAY: 4187.0,
        JUN: 3760.0,
        JUL: 3907.0,
        AUG: 4529.0,
        SEP: 5408.0,
        OCT: 5745.0,
        NOV: 6367.0,
        DEC: 6261.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -9.250461434175612],
              [-36.599045178706284, -9.150461434175613],
              [-36.499045178706282, -9.150461434175613],
              [-36.499045178706282, -9.250461434175612],
              [-36.599045178706284, -9.250461434175612],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '44',
        ID: 37151.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -9.2005,
        ANNUAL: 5243.0,
        JAN: 5971.0,
        FEB: 5814.0,
        MAR: 5798.0,
        APR: 5125.0,
        MAY: 4196.0,
        JUN: 3765.0,
        JUL: 3857.0,
        AUG: 4509.0,
        SEP: 5412.0,
        OCT: 5793.0,
        NOV: 6380.0,
        DEC: 6293.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399611322999903, -9.224527080999962],
              [-36.402913065, -9.223424286999947],
              [-36.404350902, -9.22101535299992],
              [-36.404353597, -9.221015237999955],
              [-36.406925035, -9.220672217999953],
              [-36.407470936, -9.221007249999962],
              [-36.407471814, -9.221006205999963],
              [-36.407948045999902, -9.221298639999929],
              [-36.408662986, -9.221923990999922],
              [-36.408664939999909, -9.221925698999934],
              [-36.408785101, -9.221814880999935],
              [-36.410161274, -9.223043451999962],
              [-36.411297363, -9.224239543999934],
              [-36.413883629999908, -9.228472971999956],
              [-36.415299203, -9.229673529999967],
              [-36.415302836, -9.229673572999966],
              [-36.419799119999901, -9.229568708999979],
              [-36.419803214, -9.229568430999953],
              [-36.422149874999903, -9.228605203999969],
              [-36.424370898999904, -9.226024078999961],
              [-36.425185225999897, -9.221762531999955],
              [-36.424659459999901, -9.218478445999954],
              [-36.423338606, -9.21679522499994],
              [-36.423303023, -9.215653129999959],
              [-36.424775508, -9.214934214999969],
              [-36.428218855999901, -9.214411040999954],
              [-36.42964796, -9.213577567999955],
              [-36.430251358, -9.212714464999976],
              [-36.431829951999902, -9.212130488999952],
              [-36.431832676999903, -9.212130543999923],
              [-36.437003784, -9.212100240999971],
              [-36.439380562999901, -9.213622368999953],
              [-36.439494083999904, -9.216865781999964],
              [-36.440672140999901, -9.218903266999963],
              [-36.441236241, -9.21907874899995],
              [-36.444567140999901, -9.220117405999932],
              [-36.447566711, -9.22431341199996],
              [-36.448712338, -9.224960432999975],
              [-36.448714466999903, -9.224961505999939],
              [-36.452748024999899, -9.225103925999967],
              [-36.454258416999899, -9.225661613999932],
              [-36.454261100999901, -9.225662605999965],
              [-36.455263219, -9.226719311999947],
              [-36.455260145999901, -9.227494579999924],
              [-36.453771194, -9.230017847999934],
              [-36.4536629629999, -9.232390075999938],
              [-36.454898845999899, -9.23290042899993],
              [-36.457696955, -9.233078611999924],
              [-36.457704645, -9.233079117999976],
              [-36.457908566, -9.233091995999931],
              [-36.459526389, -9.233777693999972],
              [-36.461060094999901, -9.237060164999948],
              [-36.461696692, -9.238422622999959],
              [-36.462517278999897, -9.239251109999936],
              [-36.462519947, -9.23925148399996],
              [-36.468478794, -9.240665685999943],
              [-36.470436188, -9.241928355999953],
              [-36.471396024999898, -9.243171023999922],
              [-36.472952962, -9.243729294999975],
              [-36.4729550919999, -9.243729207999939],
              [-36.474795948999898, -9.243368255999945],
              [-36.476123738, -9.243772285999965],
              [-36.477188554, -9.242593369999943],
              [-36.478799594999899, -9.242054940999937],
              [-36.480292601, -9.240531267999934],
              [-36.481333186, -9.239469308999954],
              [-36.481615741, -9.239181290999973],
              [-36.481617174999897, -9.239182737999945],
              [-36.4822535549999, -9.239824719999943],
              [-36.482256198999899, -9.23982514299996],
              [-36.484590098999902, -9.240480483999923],
              [-36.484592721, -9.240480696999946],
              [-36.48716526, -9.240201386999962],
              [-36.487888569999903, -9.241621439999955],
              [-36.488618006, -9.244579346999965],
              [-36.488252095999897, -9.245608730999949],
              [-36.489759399, -9.24887477299994],
              [-36.4890167679999, -9.250108148999963],
              [-36.489063069283652, -9.250461434175612],
              [-36.499045178706282, -9.250461434175612],
              [-36.499045178706282, -9.150461434175613],
              [-36.399045178706281, -9.150461434175613],
              [-36.399045178706281, -9.224569790626605],
              [-36.399611322999903, -9.224527080999962],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '45',
        ID: 37152.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -9.2005,
        ANNUAL: 5139.0,
        JAN: 5872.0,
        FEB: 5701.0,
        MAR: 5730.0,
        APR: 5092.0,
        MAY: 4143.0,
        JUN: 3686.0,
        JUL: 3748.0,
        AUG: 4393.0,
        SEP: 5251.0,
        OCT: 5623.0,
        NOV: 6239.0,
        DEC: 6189.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.300003214999897, -9.175502765999973],
              [-36.300520291999902, -9.175780409999961],
              [-36.3015241539999, -9.176319151999964],
              [-36.301599177, -9.176359599999952],
              [-36.301881253, -9.176511677999938],
              [-36.30188398, -9.176511776999966],
              [-36.304004764, -9.176566133999936],
              [-36.306053587, -9.178684619999949],
              [-36.306055129999898, -9.178684429999972],
              [-36.306056269, -9.178685609999945],
              [-36.3078227949999, -9.178469927999979],
              [-36.308562412, -9.17708284599995],
              [-36.308852681, -9.172545983999948],
              [-36.309791612, -9.172362802999942],
              [-36.311789156, -9.174481171999957],
              [-36.311885515, -9.178280366999958],
              [-36.312323761999899, -9.179918025999946],
              [-36.316167284, -9.182126676999921],
              [-36.316169949, -9.182127055999956],
              [-36.31797155, -9.182563682999961],
              [-36.319162584, -9.184069965999981],
              [-36.321519749999901, -9.185380641999927],
              [-36.322883474, -9.189638055999987],
              [-36.322665328, -9.191319468999946],
              [-36.324956045, -9.192522475999981],
              [-36.326353309, -9.197813355999921],
              [-36.3276442109999, -9.200767654999932],
              [-36.329479442, -9.202503814999938],
              [-36.3318500399999, -9.203784211999965],
              [-36.335223033, -9.204811471999957],
              [-36.338887655, -9.207011386999968],
              [-36.33889038, -9.207011432999927],
              [-36.341462233, -9.206978140999974],
              [-36.344450380999902, -9.204845853999933],
              [-36.348592614, -9.203375653999958],
              [-36.351410472999902, -9.201364179999935],
              [-36.351413189, -9.20136415099995],
              [-36.353916480999899, -9.201193640999962],
              [-36.354827106999899, -9.203302459999971],
              [-36.354420601, -9.205211474999942],
              [-36.351184363, -9.20898778899994],
              [-36.349229229999899, -9.212990067999948],
              [-36.348888286, -9.214736877999936],
              [-36.349162887, -9.21625910599994],
              [-36.35035234, -9.216890066999946],
              [-36.350355064, -9.216890232999953],
              [-36.352246466999901, -9.217033295999956],
              [-36.352834791, -9.219509677999961],
              [-36.353602464999902, -9.220121747999936],
              [-36.353604041, -9.220121173999921],
              [-36.354724967, -9.219712471999969],
              [-36.355929998, -9.218385842999957],
              [-36.355932722, -9.218385890999976],
              [-36.358345856, -9.21828818399996],
              [-36.360213816999902, -9.221555325999935],
              [-36.360217401999897, -9.221555756999987],
              [-36.365703956999901, -9.222627319999956],
              [-36.369692334, -9.221105953999942],
              [-36.370890749, -9.22044150799996],
              [-36.374078528, -9.216991278999963],
              [-36.3740812099999, -9.21699112899995],
              [-36.375873172999903, -9.216700211999985],
              [-36.380341627, -9.219711934999967],
              [-36.380345226, -9.219712319999935],
              [-36.382258518999897, -9.220034232999982],
              [-36.385844757, -9.220637261999975],
              [-36.3873836349999, -9.223557452999962],
              [-36.390039514, -9.224875686999948],
              [-36.390042222, -9.224875943999965],
              [-36.391092367999903, -9.225028031999946],
              [-36.391734407999898, -9.225121015999951],
              [-36.391737103, -9.225121107999938],
              [-36.399045178706281, -9.224569790626605],
              [-36.399045178706281, -9.150461434175613],
              [-36.29904517870628, -9.150461434175613],
              [-36.29904517870628, -9.176128576643727],
              [-36.300003214999897, -9.175502765999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '46',
        ID: 37153.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -9.2005,
        ANNUAL: 5130.0,
        JAN: 5843.0,
        FEB: 5724.0,
        MAR: 5696.0,
        APR: 5092.0,
        MAY: 4165.0,
        JUN: 3687.0,
        JUL: 3790.0,
        AUG: 4361.0,
        SEP: 5270.0,
        OCT: 5600.0,
        NOV: 6164.0,
        DEC: 6165.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.264719559, -9.16586255999993],
              [-36.26390785, -9.165908062999957],
              [-36.263141864, -9.166320516999972],
              [-36.262582105, -9.167263269999982],
              [-36.262847254, -9.168647937999939],
              [-36.262558816, -9.170531325999958],
              [-36.2624445659999, -9.171277333999964],
              [-36.262842368999898, -9.171359447999979],
              [-36.262845947999899, -9.171359896999951],
              [-36.263498153999898, -9.172014493999949],
              [-36.267069751, -9.171582255999965],
              [-36.267072449999901, -9.171582157999977],
              [-36.26806942, -9.170099336999945],
              [-36.268433715, -9.170101006999969],
              [-36.268304392999902, -9.170865196999957],
              [-36.268878317, -9.171060682999954],
              [-36.268880821, -9.17106073399998],
              [-36.27109497, -9.171038290999947],
              [-36.271141303, -9.170311592999949],
              [-36.270449094999897, -9.169579701999963],
              [-36.270618476, -9.169047253999961],
              [-36.271074346, -9.168399394999943],
              [-36.271077960999897, -9.168399330999932],
              [-36.272347844, -9.168294122999949],
              [-36.273058230999901, -9.166731066999947],
              [-36.274170182, -9.166203825999951],
              [-36.274172684, -9.166204059999933],
              [-36.275523597, -9.166405382999924],
              [-36.2766646379999, -9.167178304999942],
              [-36.276668273, -9.167178477999924],
              [-36.279498077, -9.167314387999962],
              [-36.28575712, -9.170078531999922],
              [-36.285941810999901, -9.172717517999956],
              [-36.287045701, -9.173415977999987],
              [-36.287048109, -9.173415706999949],
              [-36.287760235, -9.173302283999922],
              [-36.28864793, -9.173160965999941],
              [-36.289778135999903, -9.17403544799993],
              [-36.289995253, -9.174203438999935],
              [-36.289996668999898, -9.174203450999933],
              [-36.289997935, -9.174204430999966],
              [-36.290945039, -9.17421079099995],
              [-36.291506384999899, -9.174088425999978],
              [-36.292130705, -9.173952332999923],
              [-36.293497036, -9.172584326999987],
              [-36.293499743, -9.17258426099993],
              [-36.294975607, -9.172442806999985],
              [-36.295977450999899, -9.175116418999956],
              [-36.297201309999899, -9.176782086999944],
              [-36.297204002999898, -9.176782392999947],
              [-36.297858413, -9.176901730999935],
              [-36.297861107999907, -9.176902037999984],
              [-36.29904517870628, -9.176128576643727],
              [-36.29904517870628, -9.150461434175613],
              [-36.260250192616631, -9.150461434175613],
              [-36.264719559, -9.16586255999993],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '47',
        ID: 37485.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -9.1005,
        ANNUAL: 5774.0,
        JAN: 6252.0,
        FEB: 5878.0,
        MAR: 5787.0,
        APR: 5233.0,
        MAY: 4875.0,
        JUN: 4716.0,
        JUL: 4959.0,
        AUG: 5800.0,
        SEP: 6512.0,
        OCT: 6485.0,
        NOV: 6509.0,
        DEC: 6279.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.804104037999899, -9.098335856999931],
              [-40.808871452999909, -9.093689452999971],
              [-40.809815252, -9.092276575999957],
              [-40.810760925999901, -9.085947689999953],
              [-40.811958235, -9.084398203999967],
              [-40.813194570999897, -9.083169291999983],
              [-40.818402402999901, -9.082182451999927],
              [-40.820067001, -9.081258671999931],
              [-40.821008041, -9.079891425999964],
              [-40.82132396, -9.07807713599993],
              [-40.821062771999898, -9.076164944999952],
              [-40.819147183, -9.069094966999968],
              [-40.821144967, -9.065395327999974],
              [-40.823589539, -9.062113431999931],
              [-40.824202472, -9.057365056999968],
              [-40.823072162, -9.05198337899998],
              [-40.823607958536734, -9.050461434175613],
              [-40.799045178706344, -9.050461434175613],
              [-40.799045178706344, -9.099177014602388],
              [-40.804104037999899, -9.098335856999931],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '48',
        ID: 37486.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -9.1005,
        ANNUAL: 5768.0,
        JAN: 6289.0,
        FEB: 5928.0,
        MAR: 5868.0,
        APR: 5209.0,
        MAY: 4840.0,
        JUN: 4664.0,
        JUL: 4916.0,
        AUG: 5716.0,
        SEP: 6502.0,
        OCT: 6471.0,
        NOV: 6507.0,
        DEC: 6299.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.700164115, -9.142234058999962],
              [-40.706195143999899, -9.142006078999945],
              [-40.709889302, -9.140880973999966],
              [-40.72227230499989, -9.133816609999945],
              [-40.728869339, -9.128851416999961],
              [-40.730487269, -9.127249937999984],
              [-40.730921743, -9.125961589999964],
              [-40.732537487999899, -9.12400561099996],
              [-40.735394959, -9.123235378999944],
              [-40.743136201, -9.122400672999968],
              [-40.744805215, -9.121764779999983],
              [-40.744827277, -9.121756373999975],
              [-40.745078158, -9.121581135999966],
              [-40.745130716999903, -9.121545038999951],
              [-40.750368356, -9.11789720499995],
              [-40.750385603, -9.117885192999953],
              [-40.750414206999899, -9.117897204999961],
              [-40.755343532, -9.119967262999978],
              [-40.757060567999901, -9.119843067999977],
              [-40.757704175, -9.118629878999965],
              [-40.75770356, -9.117897204999949],
              [-40.7577012839999, -9.115187849999939],
              [-40.756289521, -9.111687066999931],
              [-40.757367766999899, -9.108008340999953],
              [-40.7567849449999, -9.104976912999971],
              [-40.763171025, -9.106940025999965],
              [-40.766618888, -9.105346449999955],
              [-40.769483754, -9.103178472999966],
              [-40.772234259999898, -9.102049314999936],
              [-40.772791627999901, -9.10182049999997],
              [-40.775086241999901, -9.100878046999979],
              [-40.777260597, -9.100668408999985],
              [-40.779791523, -9.101415670999929],
              [-40.783677621, -9.104386931999953],
              [-40.786096028, -9.105187417999961],
              [-40.787454377, -9.10407344999993],
              [-40.787590341999909, -9.100628923999986],
              [-40.789463715, -9.098947527999947],
              [-40.793778980999903, -9.098715528999946],
              [-40.798915956, -9.099198500999922],
              [-40.799045178706344, -9.099177014602388],
              [-40.799045178706344, -9.050461434175613],
              [-40.699045178706342, -9.050461434175613],
              [-40.699045178706342, -9.14188905281685],
              [-40.700164115, -9.142234058999962],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '49',
        ID: 37487.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -9.1005,
        ANNUAL: 5751.0,
        JAN: 6288.0,
        FEB: 5904.0,
        MAR: 5879.0,
        APR: 5226.0,
        MAY: 4832.0,
        JUN: 4612.0,
        JUL: 4899.0,
        AUG: 5683.0,
        SEP: 6459.0,
        OCT: 6448.0,
        NOV: 6508.0,
        DEC: 6280.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.68281167, -9.141122886999938],
              [-40.683648882999897, -9.139834331999953],
              [-40.685093997, -9.137610157999962],
              [-40.687290792999903, -9.136984429999933],
              [-40.690290602999902, -9.137947472999945],
              [-40.6946997269999, -9.140549201999935],
              [-40.699045178706342, -9.14188905281685],
              [-40.699045178706342, -9.050461434175613],
              [-40.599045178706341, -9.050461434175613],
              [-40.599045178706341, -9.150461434175613],
              [-40.67812278066598, -9.150461434175613],
              [-40.68281167, -9.141122886999938],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '50',
        ID: 37488.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -9.1005,
        ANNUAL: 5730.0,
        JAN: 6244.0,
        FEB: 5939.0,
        MAR: 5871.0,
        APR: 5251.0,
        MAY: 4790.0,
        JUN: 4522.0,
        JUL: 4836.0,
        AUG: 5678.0,
        SEP: 6436.0,
        OCT: 6450.0,
        NOV: 6470.0,
        DEC: 6266.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -9.150461434175613],
              [-40.599045178706341, -9.050461434175613],
              [-40.499045178706339, -9.050461434175613],
              [-40.499045178706339, -9.150461434175613],
              [-40.599045178706341, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '51',
        ID: 37489.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -9.1005,
        ANNUAL: 5694.0,
        JAN: 6169.0,
        FEB: 5923.0,
        MAR: 5864.0,
        APR: 5218.0,
        MAY: 4751.0,
        JUN: 4472.0,
        JUL: 4767.0,
        AUG: 5608.0,
        SEP: 6409.0,
        OCT: 6419.0,
        NOV: 6474.0,
        DEC: 6259.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -9.150461434175613],
              [-40.499045178706339, -9.050461434175613],
              [-40.399045178706338, -9.050461434175613],
              [-40.399045178706338, -9.150461434175613],
              [-40.499045178706339, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '52',
        ID: 37490.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -9.1005,
        ANNUAL: 5701.0,
        JAN: 6187.0,
        FEB: 5941.0,
        MAR: 5931.0,
        APR: 5235.0,
        MAY: 4744.0,
        JUN: 4453.0,
        JUL: 4723.0,
        AUG: 5568.0,
        SEP: 6400.0,
        OCT: 6403.0,
        NOV: 6525.0,
        DEC: 6297.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -9.150461434175613],
              [-40.399045178706338, -9.050461434175613],
              [-40.299045178706336, -9.050461434175613],
              [-40.299045178706336, -9.150461434175613],
              [-40.399045178706338, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '53',
        ID: 37491.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -9.1005,
        ANNUAL: 5677.0,
        JAN: 6210.0,
        FEB: 5951.0,
        MAR: 5915.0,
        APR: 5214.0,
        MAY: 4710.0,
        JUN: 4427.0,
        JUL: 4684.0,
        AUG: 5499.0,
        SEP: 6330.0,
        OCT: 6352.0,
        NOV: 6518.0,
        DEC: 6317.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.201119252999902, -9.068465766999955],
              [-40.208827534, -9.063782297999976],
              [-40.213436714999908, -9.06098110699998],
              [-40.218519527, -9.058824327999957],
              [-40.22360234, -9.056667548999947],
              [-40.231569519999901, -9.054722496999934],
              [-40.238983527999906, -9.054365347999976],
              [-40.243399584, -9.055572926999956],
              [-40.247864133, -9.057737842999929],
              [-40.255790976, -9.063223432999964],
              [-40.256673824426798, -9.064034858166126],
              [-40.264206083, -9.070957749999927],
              [-40.266301365999901, -9.073216659999959],
              [-40.269948671999892, -9.077847572999937],
              [-40.2708540369999, -9.079124574999977],
              [-40.270860094999897, -9.079133120999931],
              [-40.273192198999901, -9.082421579999954],
              [-40.276665797, -9.089113477999941],
              [-40.278846686999898, -9.094538501999979],
              [-40.282920632999897, -9.107778119999983],
              [-40.286706137, -9.11735184499997],
              [-40.286813251999902, -9.117897204999952],
              [-40.2886810319999, -9.127406769999943],
              [-40.289057574, -9.129324086999926],
              [-40.289269109, -9.130401205999926],
              [-40.293330176, -9.146742858999962],
              [-40.293806304702755, -9.150461434175613],
              [-40.299045178706336, -9.150461434175613],
              [-40.299045178706336, -9.050461434175613],
              [-40.199045178706335, -9.050461434175613],
              [-40.199045178706335, -9.070003754231756],
              [-40.201119252999902, -9.068465766999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '54',
        ID: 37492.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -9.1005,
        ANNUAL: 5723.0,
        JAN: 6277.0,
        FEB: 6025.0,
        MAR: 5988.0,
        APR: 5314.0,
        MAY: 4750.0,
        JUN: 4469.0,
        JUL: 4715.0,
        AUG: 5535.0,
        SEP: 6305.0,
        OCT: 6369.0,
        NOV: 6545.0,
        DEC: 6386.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.100031413000011, -9.092301145999954],
              [-40.100390885, -9.092688629999945],
              [-40.100600712999899, -9.092914808999984],
              [-40.101729685, -9.094131757999952],
              [-40.103054943, -9.094909340999946],
              [-40.107420552999898, -9.097470645999985],
              [-40.111282101, -9.098110658999925],
              [-40.119867566, -9.102096899999943],
              [-40.124054212, -9.107356496999964],
              [-40.127221398, -9.109764923999924],
              [-40.12902302, -9.110128601999959],
              [-40.12989492, -9.110304605999932],
              [-40.134291135, -9.109488796999935],
              [-40.13625209, -9.109124899999921],
              [-40.1363827489999, -9.109062465999944],
              [-40.137424833, -9.108564514999955],
              [-40.138597575999903, -9.108004128999937],
              [-40.140049421, -9.107476487999969],
              [-40.144333677, -9.106548768999973],
              [-40.151104030999903, -9.107131608999961],
              [-40.152135143999899, -9.107220364999938],
              [-40.159720784999898, -9.107873317999967],
              [-40.163086733999897, -9.107308969999956],
              [-40.1650015869999, -9.106487774999957],
              [-40.166343486999899, -9.105372710999974],
              [-40.167647316999897, -9.103161073999964],
              [-40.171082725, -9.093449032999963],
              [-40.174137050999903, -9.090559379999956],
              [-40.180583236, -9.087784014999945],
              [-40.184088698, -9.085295144999989],
              [-40.194313437999902, -9.074253593999968],
              [-40.19440804, -9.074151434999971],
              [-40.196503774999897, -9.071888279999941],
              [-40.199045178706335, -9.070003754231756],
              [-40.199045178706335, -9.050461434175613],
              [-40.099045178706334, -9.050461434175613],
              [-40.099045178706334, -9.091238058282926],
              [-40.100031413000011, -9.092301145999954],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '55',
        ID: 37493.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -9.1005,
        ANNUAL: 5702.0,
        JAN: 6301.0,
        FEB: 6014.0,
        MAR: 6026.0,
        APR: 5328.0,
        MAY: 4728.0,
        JUN: 4437.0,
        JUL: 4638.0,
        AUG: 5469.0,
        SEP: 6258.0,
        OCT: 6311.0,
        NOV: 6504.0,
        DEC: 6405.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.002557995, -9.060038777999972],
              [-40.004931836, -9.05979868099995],
              [-40.0049350849999, -9.059798351999966],
              [-40.023644587, -9.060100225999962],
              [-40.035723570999899, -9.062393746999932],
              [-40.038309324, -9.06175465899997],
              [-40.042469774, -9.059821718999958],
              [-40.046477222, -9.060087176999955],
              [-40.049622596999903, -9.060861982999945],
              [-40.050531384, -9.061085845999964],
              [-40.065563055, -9.063065192999938],
              [-40.068612936, -9.064975354999943],
              [-40.0701167839999, -9.067196724999979],
              [-40.07183997, -9.07296102199996],
              [-40.073539727, -9.075362060999955],
              [-40.077395979999899, -9.07822641399996],
              [-40.08527624, -9.082585303999958],
              [-40.092469718, -9.085915494999938],
              [-40.094472319, -9.087508647999922],
              [-40.0987211079999, -9.090888733999977],
              [-40.099045178706334, -9.091238058282926],
              [-40.099045178706334, -9.050461434175613],
              [-39.999045178706332, -9.050461434175613],
              [-39.999045178706332, -9.060872297475072],
              [-40.002557995, -9.060038777999972],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '56',
        ID: 37494.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -9.1005,
        ANNUAL: 5668.0,
        JAN: 6302.0,
        FEB: 6045.0,
        MAR: 5947.0,
        APR: 5275.0,
        MAY: 4709.0,
        JUN: 4417.0,
        JUL: 4626.0,
        AUG: 5437.0,
        SEP: 6220.0,
        OCT: 6276.0,
        NOV: 6454.0,
        DEC: 6301.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.968091037, -9.053197058999968],
              [-39.974668134999902, -9.053989441999933],
              [-39.978444425, -9.054861946999957],
              [-39.978449874, -9.054863205999938],
              [-39.9806945079999, -9.055381823999946],
              [-39.9897822739999, -9.059466171999933],
              [-39.996030574, -9.06095820099995],
              [-39.997981072999899, -9.061124787999976],
              [-39.999045178706332, -9.060872297475072],
              [-39.999045178706332, -9.050461434175613],
              [-39.962117693426158, -9.050461434175613],
              [-39.968091037, -9.053197058999968],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '57',
        ID: 37510.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -9.1005,
        ANNUAL: 5707.0,
        JAN: 6388.0,
        FEB: 6306.0,
        MAR: 6207.0,
        APR: 5594.0,
        MAY: 4744.0,
        JUN: 4283.0,
        JUL: 4480.0,
        AUG: 5135.0,
        SEP: 6051.0,
        OCT: 6228.0,
        NOV: 6502.0,
        DEC: 6567.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.312873328, -9.14836672799993],
              [-38.315942256, -9.144939411999935],
              [-38.316321899999899, -9.144515433999972],
              [-38.318658749, -9.141001717999924],
              [-38.319493381, -9.138229251999967],
              [-38.320068163, -9.133410313999947],
              [-38.3212952229999, -9.129902994999947],
              [-38.321012713, -9.127855181999962],
              [-38.321401830999903, -9.116880574999934],
              [-38.320736403, -9.115491167999959],
              [-38.3177315, -9.111824874999966],
              [-38.316472806, -9.108795996999968],
              [-38.314963045, -9.107237139999938],
              [-38.306123866999897, -9.10167448799996],
              [-38.304792636999899, -9.099392547999974],
              [-38.3044229569999, -9.098211150999939],
              [-38.304593062, -9.096787562999957],
              [-38.306054437999897, -9.095656826999967],
              [-38.307515813, -9.094526090999972],
              [-38.310042327, -9.093959765999953],
              [-38.314952938, -9.09409988199997],
              [-38.317761826, -9.093009223999967],
              [-38.318860936, -9.092253116999926],
              [-38.320260994, -9.091290328999953],
              [-38.323287267999902, -9.08755933799995],
              [-38.325406257999902, -9.08336465699996],
              [-38.326185462999902, -9.080956264999937],
              [-38.325866715, -9.077992612999937],
              [-38.324573062999903, -9.074245772999967],
              [-38.321042115999909, -9.070033667999958],
              [-38.316102105, -9.067533555999947],
              [-38.3124588969999, -9.066358046999937],
              [-38.303737141999903, -9.061643336999966],
              [-38.299045178706308, -9.057487481439566],
              [-38.299045178706308, -9.150461434175613],
              [-38.310017124559046, -9.150461434175613],
              [-38.312873328, -9.14836672799993],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '58',
        ID: 37511.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -9.1005,
        ANNUAL: 5640.0,
        JAN: 6335.0,
        FEB: 6123.0,
        MAR: 6094.0,
        APR: 5461.0,
        MAY: 4679.0,
        JUN: 4165.0,
        JUL: 4389.0,
        AUG: 5132.0,
        SEP: 6013.0,
        OCT: 6181.0,
        NOV: 6639.0,
        DEC: 6470.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.297046013999903, -9.055716742999943],
              [-38.292692604709679, -9.050461434175613],
              [-38.199045178706307, -9.050461434175613],
              [-38.199045178706307, -9.150461434175613],
              [-38.299045178706308, -9.150461434175613],
              [-38.299045178706308, -9.057487481439566],
              [-38.297046013999903, -9.055716742999943],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '59',
        ID: 37512.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -9.1005,
        ANNUAL: 5637.0,
        JAN: 6382.0,
        FEB: 6117.0,
        MAR: 6056.0,
        APR: 5397.0,
        MAY: 4620.0,
        JUN: 4135.0,
        JUL: 4321.0,
        AUG: 5085.0,
        SEP: 6071.0,
        OCT: 6292.0,
        NOV: 6704.0,
        DEC: 6471.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -9.150461434175613],
              [-38.199045178706307, -9.050461434175613],
              [-38.099045178706305, -9.050461434175613],
              [-38.099045178706305, -9.150461434175613],
              [-38.199045178706307, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '60',
        ID: 37513.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -9.1005,
        ANNUAL: 5495.0,
        JAN: 6301.0,
        FEB: 6060.0,
        MAR: 5983.0,
        APR: 5326.0,
        MAY: 4450.0,
        JUN: 3951.0,
        JUL: 4118.0,
        AUG: 4799.0,
        SEP: 5795.0,
        OCT: 6150.0,
        NOV: 6584.0,
        DEC: 6418.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -9.150461434175613],
              [-38.099045178706305, -9.050461434175613],
              [-37.999045178706304, -9.050461434175613],
              [-37.999045178706304, -9.150461434175613],
              [-38.099045178706305, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '61',
        ID: 37514.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -9.1005,
        ANNUAL: 5542.0,
        JAN: 6282.0,
        FEB: 6077.0,
        MAR: 6070.0,
        APR: 5341.0,
        MAY: 4476.0,
        JUN: 4007.0,
        JUL: 4200.0,
        AUG: 4879.0,
        SEP: 5796.0,
        OCT: 6213.0,
        NOV: 6686.0,
        DEC: 6472.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.907093577, -9.051157955999948],
              [-37.907875116999897, -9.052218406999943],
              [-37.912375923, -9.055930466999937],
              [-37.917286563999902, -9.060799781999949],
              [-37.918460169999904, -9.061414855999944],
              [-37.920291969999901, -9.063793838999961],
              [-37.929791693, -9.071279864999948],
              [-37.930638793, -9.072324524999932],
              [-37.935704036999901, -9.078571358999971],
              [-37.93767665, -9.080115110999943],
              [-37.9394469759999, -9.083917287999949],
              [-37.942544423999898, -9.088116363999962],
              [-37.942731239999901, -9.089178711999978],
              [-37.942128516, -9.090232855999936],
              [-37.942165641, -9.090365860999944],
              [-37.942684336999903, -9.092218947999985],
              [-37.944563709, -9.092732617999962],
              [-37.947767718, -9.095769406999983],
              [-37.949222907, -9.096157871999948],
              [-37.950644467, -9.096537285999938],
              [-37.951596247999902, -9.097309474999971],
              [-37.951640825, -9.097426371999962],
              [-37.951685299, -9.097542439999936],
              [-37.951952503, -9.098241511999959],
              [-37.951764039, -9.099783857999963],
              [-37.953374051, -9.103374651999964],
              [-37.953267039, -9.106130942999936],
              [-37.954303708999902, -9.109525337999967],
              [-37.954850037, -9.111314602999936],
              [-37.957568223, -9.115629754999929],
              [-37.961582402, -9.120129921999924],
              [-37.962337331999898, -9.120577673999948],
              [-37.968790068, -9.122704769999929],
              [-37.969618162, -9.123267304999965],
              [-37.971200216999897, -9.124341775999945],
              [-37.971459637999899, -9.125692536999965],
              [-37.9710387599999, -9.128274463999956],
              [-37.971758384999902, -9.131041882999966],
              [-37.975540983, -9.134942284999966],
              [-37.976202312999902, -9.136189887999924],
              [-37.977049141999899, -9.140379745999944],
              [-37.977294902, -9.141595692999948],
              [-37.978038548, -9.143231917999973],
              [-37.978138101, -9.146093209999943],
              [-37.978562914, -9.147661992999927],
              [-37.979351312999903, -9.148363014999978],
              [-37.981340068999899, -9.149289671999952],
              [-37.9846521649999, -9.149789763999923],
              [-37.984967884013486, -9.150461434175613],
              [-37.999045178706304, -9.150461434175613],
              [-37.999045178706304, -9.050461434175613],
              [-37.907046467829694, -9.050461434175613],
              [-37.907093577, -9.051157955999948],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '62',
        ID: 37520.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -9.1005,
        ANNUAL: 5532.0,
        JAN: 6254.0,
        FEB: 6111.0,
        MAR: 6088.0,
        APR: 5368.0,
        MAY: 4423.0,
        JUN: 4008.0,
        JUL: 4236.0,
        AUG: 4876.0,
        SEP: 5751.0,
        OCT: 6194.0,
        NOV: 6612.0,
        DEC: 6462.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.303653036999897, -9.116981539999921],
              [-37.3110581299999, -9.107544854999954],
              [-37.311453604999897, -9.104981585999948],
              [-37.310676731999898, -9.102557892999922],
              [-37.310699493, -9.100418767999944],
              [-37.313432243, -9.097749911999923],
              [-37.314391943, -9.096812651999933],
              [-37.314804138999897, -9.094965190999957],
              [-37.3155192299999, -9.094271933999945],
              [-37.317172904, -9.092672891999936],
              [-37.317453122, -9.092401931999966],
              [-37.322117357, -9.093285362999946],
              [-37.323868112, -9.094040052999967],
              [-37.327703326999902, -9.093225689999946],
              [-37.330889617, -9.09070596299995],
              [-37.332369478, -9.089124695999937],
              [-37.333457081999903, -9.087006626999948],
              [-37.334006013, -9.084596104999944],
              [-37.334148266999897, -9.082906238999948],
              [-37.334442678, -9.081862559999923],
              [-37.336080876, -9.079968640999937],
              [-37.336114466, -9.07992980599994],
              [-37.336212133999901, -9.079816892999931],
              [-37.33676787, -9.079174406999925],
              [-37.338617639999903, -9.077035887999946],
              [-37.338961831999903, -9.076637968999929],
              [-37.358639083999897, -9.053889107999957],
              [-37.362814323128674, -9.050461434175613],
              [-37.299045178706294, -9.050461434175613],
              [-37.299045178706294, -9.119543771467052],
              [-37.303653036999897, -9.116981539999921],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '63',
        ID: 37521.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -9.1005,
        ANNUAL: 5506.0,
        JAN: 6243.0,
        FEB: 6076.0,
        MAR: 6068.0,
        APR: 5347.0,
        MAY: 4440.0,
        JUN: 3975.0,
        JUL: 4220.0,
        AUG: 4796.0,
        SEP: 5690.0,
        OCT: 6170.0,
        NOV: 6602.0,
        DEC: 6450.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.280896301, -9.150275488999968],
              [-37.282285808, -9.148841808999981],
              [-37.287928111, -9.136349345999976],
              [-37.2965721959999, -9.120917267999964],
              [-37.296802398, -9.120789296999922],
              [-37.296948831999899, -9.120709458999956],
              [-37.297013089, -9.120673728999977],
              [-37.299045178706294, -9.119543771467052],
              [-37.299045178706294, -9.050461434175613],
              [-37.199045178706292, -9.050461434175613],
              [-37.199045178706292, -9.150461434175613],
              [-37.280470552520519, -9.150461434175613],
              [-37.280896301, -9.150275488999968],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '64',
        ID: 37522.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -9.1005,
        ANNUAL: 5473.0,
        JAN: 6227.0,
        FEB: 6010.0,
        MAR: 6015.0,
        APR: 5299.0,
        MAY: 4403.0,
        JUN: 3952.0,
        JUL: 4174.0,
        AUG: 4795.0,
        SEP: 5722.0,
        OCT: 6112.0,
        NOV: 6560.0,
        DEC: 6407.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -9.150461434175613],
              [-37.199045178706292, -9.050461434175613],
              [-37.099045178706291, -9.050461434175613],
              [-37.099045178706291, -9.150461434175613],
              [-37.199045178706292, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '65',
        ID: 37523.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -9.1005,
        ANNUAL: 5435.0,
        JAN: 6144.0,
        FEB: 5934.0,
        MAR: 5993.0,
        APR: 5303.0,
        MAY: 4341.0,
        JUN: 3889.0,
        JUL: 4051.0,
        AUG: 4723.0,
        SEP: 5716.0,
        OCT: 6104.0,
        NOV: 6584.0,
        DEC: 6439.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -9.150461434175613],
              [-37.099045178706291, -9.050461434175613],
              [-36.99904517870629, -9.050461434175613],
              [-36.99904517870629, -9.150461434175613],
              [-37.099045178706291, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '66',
        ID: 37524.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -9.1005,
        ANNUAL: 5420.0,
        JAN: 6106.0,
        FEB: 5895.0,
        MAR: 5967.0,
        APR: 5322.0,
        MAY: 4391.0,
        JUN: 3917.0,
        JUL: 4088.0,
        AUG: 4741.0,
        SEP: 5638.0,
        OCT: 6000.0,
        NOV: 6560.0,
        DEC: 6410.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -9.150461434175613],
              [-36.99904517870629, -9.050461434175613],
              [-36.899045178706288, -9.050461434175613],
              [-36.899045178706288, -9.150461434175613],
              [-36.99904517870629, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '67',
        ID: 37525.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -9.1005,
        ANNUAL: 5395.0,
        JAN: 6124.0,
        FEB: 5877.0,
        MAR: 5925.0,
        APR: 5285.0,
        MAY: 4371.0,
        JUN: 3894.0,
        JUL: 4030.0,
        AUG: 4693.0,
        SEP: 5601.0,
        OCT: 5992.0,
        NOV: 6537.0,
        DEC: 6417.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -9.150461434175613],
              [-36.899045178706288, -9.050461434175613],
              [-36.799045178706287, -9.050461434175613],
              [-36.799045178706287, -9.150461434175613],
              [-36.899045178706288, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '68',
        ID: 37526.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -9.1005,
        ANNUAL: 5379.0,
        JAN: 6050.0,
        FEB: 5906.0,
        MAR: 5890.0,
        APR: 5248.0,
        MAY: 4262.0,
        JUN: 3869.0,
        JUL: 4006.0,
        AUG: 4660.0,
        SEP: 5629.0,
        OCT: 6016.0,
        NOV: 6593.0,
        DEC: 6420.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -9.150461434175613],
              [-36.799045178706287, -9.050461434175613],
              [-36.699045178706285, -9.050461434175613],
              [-36.699045178706285, -9.150461434175613],
              [-36.799045178706287, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '69',
        ID: 37527.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -9.1005,
        ANNUAL: 5398.0,
        JAN: 6062.0,
        FEB: 5916.0,
        MAR: 5936.0,
        APR: 5258.0,
        MAY: 4282.0,
        JUN: 3925.0,
        JUL: 4053.0,
        AUG: 4699.0,
        SEP: 5623.0,
        OCT: 6005.0,
        NOV: 6577.0,
        DEC: 6435.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -9.150461434175613],
              [-36.699045178706285, -9.050461434175613],
              [-36.599045178706284, -9.050461434175613],
              [-36.599045178706284, -9.150461434175613],
              [-36.699045178706285, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '70',
        ID: 37528.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -9.1005,
        ANNUAL: 5258.0,
        JAN: 5952.0,
        FEB: 5787.0,
        MAR: 5771.0,
        APR: 5156.0,
        MAY: 4196.0,
        JUN: 3759.0,
        JUL: 3891.0,
        AUG: 4473.0,
        SEP: 5464.0,
        OCT: 5861.0,
        NOV: 6465.0,
        DEC: 6317.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -9.150461434175613],
              [-36.599045178706284, -9.050461434175613],
              [-36.499045178706282, -9.050461434175613],
              [-36.499045178706282, -9.150461434175613],
              [-36.599045178706284, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '71',
        ID: 37529.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -9.1005,
        ANNUAL: 5277.0,
        JAN: 6014.0,
        FEB: 5843.0,
        MAR: 5836.0,
        APR: 5193.0,
        MAY: 4246.0,
        JUN: 3777.0,
        JUL: 3873.0,
        AUG: 4524.0,
        SEP: 5471.0,
        OCT: 5841.0,
        NOV: 6384.0,
        DEC: 6315.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -9.150461434175613],
              [-36.499045178706282, -9.050461434175613],
              [-36.399045178706281, -9.050461434175613],
              [-36.399045178706281, -9.150461434175613],
              [-36.499045178706282, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '72',
        ID: 37530.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -9.1005,
        ANNUAL: 5244.0,
        JAN: 6004.0,
        FEB: 5835.0,
        MAR: 5860.0,
        APR: 5191.0,
        MAY: 4241.0,
        JUN: 3751.0,
        JUL: 3827.0,
        AUG: 4509.0,
        SEP: 5401.0,
        OCT: 5748.0,
        NOV: 6320.0,
        DEC: 6245.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -9.150461434175613],
              [-36.399045178706281, -9.050461434175613],
              [-36.29904517870628, -9.050461434175613],
              [-36.29904517870628, -9.150461434175613],
              [-36.399045178706281, -9.150461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '73',
        ID: 37531.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -9.1005,
        ANNUAL: 5160.0,
        JAN: 5859.0,
        FEB: 5779.0,
        MAR: 5756.0,
        APR: 5128.0,
        MAY: 4214.0,
        JUN: 3694.0,
        JUL: 3825.0,
        AUG: 4393.0,
        SEP: 5293.0,
        OCT: 5622.0,
        NOV: 6210.0,
        DEC: 6145.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.2002748539999, -9.053392899999949],
              [-36.203370026999899, -9.06164524999997],
              [-36.20279704, -9.066844917999955],
              [-36.204359075, -9.068649075999934],
              [-36.20454878, -9.068868185999976],
              [-36.204547029, -9.068870671999941],
              [-36.204552297, -9.068876757999927],
              [-36.20460379, -9.068936246999945],
              [-36.205926179, -9.070463973999944],
              [-36.2059619939999, -9.070804481999968],
              [-36.206002917, -9.071193548999926],
              [-36.206902984, -9.071581987999927],
              [-36.206904305, -9.071581158999951],
              [-36.207686608, -9.071090339999964],
              [-36.207688902, -9.071090736999952],
              [-36.208669555, -9.071442873999954],
              [-36.210338282999899, -9.073138458999944],
              [-36.210341915, -9.073138654999987],
              [-36.217436088999897, -9.073576484999922],
              [-36.217438657999899, -9.073576251999944],
              [-36.220642542, -9.073027510999964],
              [-36.220644072, -9.073027533999948],
              [-36.221912468, -9.073302841999981],
              [-36.221559630999899, -9.076516864999967],
              [-36.223401040999903, -9.082536389999975],
              [-36.222467783999903, -9.086433638999958],
              [-36.228271085, -9.094933616999981],
              [-36.231819567999899, -9.098097505999927],
              [-36.232547628, -9.09952140099996],
              [-36.23645771, -9.103489751999971],
              [-36.23645891000001, -9.103488357999938],
              [-36.239274549999898, -9.100221969999948],
              [-36.240961644, -9.099392853999964],
              [-36.2409652609999, -9.099392800999961],
              [-36.245522401, -9.09904430199998],
              [-36.24552464300001, -9.099044340999978],
              [-36.251837716999901, -9.099743389999963],
              [-36.255674943, -9.102641833999936],
              [-36.255678413999902, -9.102641879999954],
              [-36.266843784999899, -9.102381842999927],
              [-36.268390882999903, -9.103924266999964],
              [-36.267713998, -9.106559982999952],
              [-36.267864512, -9.108435328999978],
              [-36.266997099, -9.11109068899998],
              [-36.266145455, -9.111953524999933],
              [-36.266071301999901, -9.112028651999934],
              [-36.266074045, -9.112028997999975],
              [-36.26583038, -9.11227585099993],
              [-36.265824144, -9.112279057999958],
              [-36.262182800999902, -9.115968246999961],
              [-36.260685516, -9.118522381999973],
              [-36.263642253999897, -9.123595089999986],
              [-36.263543104999897, -9.126004323999952],
              [-36.260310147, -9.129921235999969],
              [-36.2580671609999, -9.133463195999983],
              [-36.258038070999902, -9.137137004999941],
              [-36.257719639, -9.138360156999962],
              [-36.257718998, -9.138362618999954],
              [-36.257386949, -9.139640300999927],
              [-36.257333936999899, -9.139844283999922],
              [-36.257254800999902, -9.140148789999952],
              [-36.257255163, -9.140150012999978],
              [-36.257355644, -9.140489155999942],
              [-36.257385853, -9.140591118999957],
              [-36.260250192616631, -9.150461434175613],
              [-36.29904517870628, -9.150461434175613],
              [-36.29904517870628, -9.050461434175613],
              [-36.199603431744215, -9.050461434175613],
              [-36.2002748539999, -9.053392899999949],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '74',
        ID: 37864.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -9.0005,
        ANNUAL: 5771.0,
        JAN: 6222.0,
        FEB: 5904.0,
        MAR: 5798.0,
        APR: 5228.0,
        MAY: 4850.0,
        JUN: 4682.0,
        JUL: 4990.0,
        AUG: 5807.0,
        SEP: 6511.0,
        OCT: 6502.0,
        NOV: 6508.0,
        DEC: 6252.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.825304742, -9.045641673999972],
              [-40.82490825, -9.039975168999941],
              [-40.825231056999897, -9.038186631999961],
              [-40.8263724769999, -9.036805058999956],
              [-40.829963995, -9.033993296999947],
              [-40.832208215, -9.031163416999958],
              [-40.832036455, -9.029825795999955],
              [-40.830238071999901, -9.026695812999957],
              [-40.831452997, -9.023960420999975],
              [-40.832073260999898, -9.023555441999967],
              [-40.832443897, -9.023313448999939],
              [-40.832814533, -9.023071455999972],
              [-40.834222806, -9.020989176999922],
              [-40.83495647, -9.014616827999928],
              [-40.834370422999896, -9.010515971999947],
              [-40.835012708, -9.008693507999961],
              [-40.836844154999902, -9.005616202999933],
              [-40.841352183, -9.001989971999933],
              [-40.841956539, -9.000577071999935],
              [-40.844655529, -8.997610878999982],
              [-40.845961965, -8.996852942999954],
              [-40.846843768, -8.99554049199998],
              [-40.847781357, -8.991643334999935],
              [-40.849590624, -8.988812702999951],
              [-40.850957401, -8.985217899999943],
              [-40.851958502999899, -8.982584878999948],
              [-40.852105168, -8.98047093099996],
              [-40.851045093, -8.95755881499996],
              [-40.851011601, -8.954244312999947],
              [-40.851595865, -8.952498958999973],
              [-40.852739353999901, -8.951211266999957],
              [-40.853947104473086, -8.950461434175613],
              [-40.799045178706344, -8.950461434175613],
              [-40.799045178706344, -9.050461434175613],
              [-40.823607958536734, -9.050461434175613],
              [-40.825304742, -9.045641673999972],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '75',
        ID: 37865.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -9.0005,
        ANNUAL: 5752.0,
        JAN: 6219.0,
        FEB: 5907.0,
        MAR: 5795.0,
        APR: 5206.0,
        MAY: 4844.0,
        JUN: 4629.0,
        JUL: 4941.0,
        AUG: 5742.0,
        SEP: 6485.0,
        OCT: 6491.0,
        NOV: 6498.0,
        DEC: 6266.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.799045178706344, -9.050461434175613],
              [-40.799045178706344, -8.950461434175613],
              [-40.699045178706342, -8.950461434175613],
              [-40.699045178706342, -9.050461434175613],
              [-40.799045178706344, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '76',
        ID: 37866.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -9.0005,
        ANNUAL: 5729.0,
        JAN: 6209.0,
        FEB: 5896.0,
        MAR: 5826.0,
        APR: 5203.0,
        MAY: 4795.0,
        JUN: 4576.0,
        JUL: 4876.0,
        AUG: 5669.0,
        SEP: 6456.0,
        OCT: 6473.0,
        NOV: 6506.0,
        DEC: 6263.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -9.050461434175613],
              [-40.699045178706342, -8.950461434175613],
              [-40.599045178706341, -8.950461434175613],
              [-40.599045178706341, -9.050461434175613],
              [-40.699045178706342, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '77',
        ID: 37867.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -9.0005,
        ANNUAL: 5710.0,
        JAN: 6161.0,
        FEB: 5916.0,
        MAR: 5852.0,
        APR: 5232.0,
        MAY: 4788.0,
        JUN: 4512.0,
        JUL: 4812.0,
        AUG: 5643.0,
        SEP: 6454.0,
        OCT: 6465.0,
        NOV: 6472.0,
        DEC: 6212.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -9.050461434175613],
              [-40.599045178706341, -8.950461434175613],
              [-40.499045178706339, -8.950461434175613],
              [-40.499045178706339, -9.050461434175613],
              [-40.599045178706341, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '78',
        ID: 37868.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -9.0005,
        ANNUAL: 5689.0,
        JAN: 6145.0,
        FEB: 5928.0,
        MAR: 5852.0,
        APR: 5229.0,
        MAY: 4769.0,
        JUN: 4484.0,
        JUL: 4759.0,
        AUG: 5605.0,
        SEP: 6414.0,
        OCT: 6448.0,
        NOV: 6458.0,
        DEC: 6183.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -9.050461434175613],
              [-40.499045178706339, -8.950461434175613],
              [-40.399045178706338, -8.950461434175613],
              [-40.399045178706338, -9.050461434175613],
              [-40.499045178706339, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '79',
        ID: 37869.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -9.0005,
        ANNUAL: 5705.0,
        JAN: 6157.0,
        FEB: 5966.0,
        MAR: 5870.0,
        APR: 5251.0,
        MAY: 4764.0,
        JUN: 4501.0,
        JUL: 4760.0,
        AUG: 5612.0,
        SEP: 6412.0,
        OCT: 6432.0,
        NOV: 6497.0,
        DEC: 6236.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -9.050461434175613],
              [-40.399045178706338, -8.950461434175613],
              [-40.299045178706336, -8.950461434175613],
              [-40.299045178706336, -9.050461434175613],
              [-40.399045178706338, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '80',
        ID: 37870.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -9.0005,
        ANNUAL: 5695.0,
        JAN: 6217.0,
        FEB: 5919.0,
        MAR: 5923.0,
        APR: 5273.0,
        MAY: 4758.0,
        JUN: 4513.0,
        JUL: 4755.0,
        AUG: 5574.0,
        SEP: 6352.0,
        OCT: 6365.0,
        NOV: 6462.0,
        DEC: 6223.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -9.050461434175613],
              [-40.299045178706336, -8.950461434175613],
              [-40.199045178706335, -8.950461434175613],
              [-40.199045178706335, -9.050461434175613],
              [-40.299045178706336, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '81',
        ID: 37871.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -9.0005,
        ANNUAL: 5669.0,
        JAN: 6197.0,
        FEB: 5903.0,
        MAR: 5898.0,
        APR: 5264.0,
        MAY: 4721.0,
        JUN: 4471.0,
        JUL: 4675.0,
        AUG: 5527.0,
        SEP: 6335.0,
        OCT: 6359.0,
        NOV: 6446.0,
        DEC: 6238.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -9.050461434175613],
              [-40.199045178706335, -8.950461434175613],
              [-40.099045178706334, -8.950461434175613],
              [-40.099045178706334, -9.050461434175613],
              [-40.199045178706335, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '82',
        ID: 37872.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -9.0005,
        ANNUAL: 5657.0,
        JAN: 6209.0,
        FEB: 5927.0,
        MAR: 5908.0,
        APR: 5254.0,
        MAY: 4697.0,
        JUN: 4412.0,
        JUL: 4641.0,
        AUG: 5481.0,
        SEP: 6263.0,
        OCT: 6334.0,
        NOV: 6450.0,
        DEC: 6306.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -9.050461434175613],
              [-40.099045178706334, -8.950461434175613],
              [-39.999045178706332, -8.950461434175613],
              [-39.999045178706332, -9.050461434175613],
              [-40.099045178706334, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '83',
        ID: 37873.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -9.0005,
        ANNUAL: 5664.0,
        JAN: 6268.0,
        FEB: 5965.0,
        MAR: 5950.0,
        APR: 5304.0,
        MAY: 4713.0,
        JUN: 4411.0,
        JUL: 4647.0,
        AUG: 5442.0,
        SEP: 6257.0,
        OCT: 6320.0,
        NOV: 6437.0,
        DEC: 6255.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899350312, -8.972461265999927],
              [-39.906695316, -8.981351595999968],
              [-39.906923935, -8.981628313999984],
              [-39.912249946, -8.991768764999966],
              [-39.913603138, -8.994345172999934],
              [-39.915017903, -8.996432097999966],
              [-39.915388258, -8.996978409999947],
              [-39.91633143, -8.998369686999977],
              [-39.916552278, -8.998695460999942],
              [-39.916690649, -8.999462176999947],
              [-39.917006349, -9.001211468999943],
              [-39.918531473, -9.001539197999936],
              [-39.919849116, -9.001822341999969],
              [-39.927243166, -9.009128908999971],
              [-39.928832449, -9.012951797999955],
              [-39.933544776, -9.017112280999983],
              [-39.940035877, -9.025944052999922],
              [-39.949126266, -9.035611139999959],
              [-39.952644698, -9.040227812999939],
              [-39.955498679, -9.046248047999942],
              [-39.958034747999903, -9.048591558999933],
              [-39.962117693426158, -9.050461434175613],
              [-39.999045178706332, -9.050461434175613],
              [-39.999045178706332, -8.950461434175613],
              [-39.899045178706331, -8.950461434175613],
              [-39.899045178706331, -8.972301829480609],
              [-39.899350312, -8.972461265999927],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '84',
        ID: 37874.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -9.0005,
        ANNUAL: 5627.0,
        JAN: 6178.0,
        FEB: 5941.0,
        MAR: 5898.0,
        APR: 5275.0,
        MAY: 4705.0,
        JUN: 4407.0,
        JUL: 4624.0,
        AUG: 5393.0,
        SEP: 6204.0,
        OCT: 6277.0,
        NOV: 6407.0,
        DEC: 6215.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.88274401, -8.952102800999961],
              [-39.886148714999898, -8.961110018999932],
              [-39.888819125999909, -8.96583675799997],
              [-39.890024586, -8.966815443999963],
              [-39.891225258, -8.967790630999961],
              [-39.892692974999903, -8.968982711999967],
              [-39.899045178706331, -8.972301829480609],
              [-39.899045178706331, -8.950461434175613],
              [-39.881949041796666, -8.950461434175613],
              [-39.88274401, -8.952102800999961],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '85',
        ID: 37886.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -9.0005,
        ANNUAL: 5643.0,
        JAN: 6384.0,
        FEB: 6143.0,
        MAR: 6170.0,
        APR: 5484.0,
        MAY: 4641.0,
        JUN: 4175.0,
        JUL: 4384.0,
        AUG: 5027.0,
        SEP: 5941.0,
        OCT: 6253.0,
        NOV: 6573.0,
        DEC: 6540.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.609239816999903, -8.955120998999963],
              [-38.610276939, -8.957328344999956],
              [-38.6154342049999, -8.964057211999942],
              [-38.621791478, -8.976559361999932],
              [-38.624098519, -8.980046885999966],
              [-38.627712473, -8.983220855999953],
              [-38.630684936, -8.985167251999936],
              [-38.635376999, -8.986729250999927],
              [-38.640710322, -8.987268156999965],
              [-38.644912053, -8.986693336999961],
              [-38.653726692, -8.983900038999927],
              [-38.657597119999899, -8.982477732999941],
              [-38.663904053, -8.979118635999955],
              [-38.665756458, -8.978132037999952],
              [-38.666226289, -8.977881772999988],
              [-38.66898606, -8.976411896999929],
              [-38.672515768, -8.973975470999964],
              [-38.674382253, -8.971756770999956],
              [-38.676797173, -8.966346477999927],
              [-38.681022946, -8.961192056999957],
              [-38.687408271502896, -8.950461434175613],
              [-38.608444883642278, -8.950461434175613],
              [-38.609239816999903, -8.955120998999963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '86',
        ID: 37887.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -9.0005,
        ANNUAL: 5759.0,
        JAN: 6515.0,
        FEB: 6303.0,
        MAR: 6244.0,
        APR: 5626.0,
        MAY: 4765.0,
        JUN: 4327.0,
        JUL: 4543.0,
        AUG: 5177.0,
        SEP: 6084.0,
        OCT: 6353.0,
        NOV: 6588.0,
        DEC: 6583.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.50351271800001, -8.981411129999975],
              [-38.507907026999902, -8.974171701999989],
              [-38.511130959999903, -8.963627863999928],
              [-38.511871444, -8.962554207999975],
              [-38.512604035, -8.961491996999943],
              [-38.512606307999903, -8.961488700999954],
              [-38.514037953999903, -8.960042755999947],
              [-38.51404208, -8.960038587999973],
              [-38.514061881, -8.960018589999953],
              [-38.514081680999901, -8.959998591999973],
              [-38.514167223, -8.959912045999946],
              [-38.516154938999897, -8.957901003999959],
              [-38.516376576, -8.957414156999967],
              [-38.516386973999907, -8.957391316999963],
              [-38.51720324, -8.955598196999963],
              [-38.518874511663142, -8.950461434175613],
              [-38.499045178706311, -8.950461434175613],
              [-38.499045178706311, -8.984461401455068],
              [-38.50351271800001, -8.981411129999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '87',
        ID: 37888.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -9.0005,
        ANNUAL: 5807.0,
        JAN: 6523.0,
        FEB: 6351.0,
        MAR: 6274.0,
        APR: 5705.0,
        MAY: 4854.0,
        JUN: 4435.0,
        JUL: 4645.0,
        AUG: 5292.0,
        SEP: 6139.0,
        OCT: 6307.0,
        NOV: 6565.0,
        DEC: 6598.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.403515806, -9.037233121999975],
              [-38.408458245, -9.038501284999938],
              [-38.414016585999903, -9.038675159999968],
              [-38.415451415999897, -9.038569838999926],
              [-38.418404074, -9.038045585999949],
              [-38.420517186, -9.037401299999933],
              [-38.423098551, -9.036377096999956],
              [-38.430043623, -9.034495888999974],
              [-38.441162549, -9.030211530999967],
              [-38.449099651, -9.02635884699993],
              [-38.451345148, -9.024394692999977],
              [-38.456117611, -9.021621875999919],
              [-38.462405899, -9.017239716999937],
              [-38.468198473, -9.01320247199992],
              [-38.48404277, -9.001021725999978],
              [-38.485140133999899, -8.998556650999937],
              [-38.485680752999897, -8.99734237899998],
              [-38.488213007, -8.993963037999952],
              [-38.498402945, -8.984899894999963],
              [-38.499045178706311, -8.984461401455068],
              [-38.499045178706311, -8.950461434175613],
              [-38.399045178706309, -8.950461434175613],
              [-38.399045178706309, -9.034485426736364],
              [-38.403515806, -9.037233121999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '88',
        ID: 37889.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -9.0005,
        ANNUAL: 5775.0,
        JAN: 6459.0,
        FEB: 6341.0,
        MAR: 6275.0,
        APR: 5676.0,
        MAY: 4839.0,
        JUN: 4364.0,
        JUL: 4566.0,
        AUG: 5242.0,
        SEP: 6137.0,
        OCT: 6279.0,
        NOV: 6546.0,
        DEC: 6570.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.301506721, -9.015673419999933],
              [-38.302790345999902, -9.013868273999945],
              [-38.316935016999899, -9.00129419899997],
              [-38.316950316, -8.997157198999966],
              [-38.3174112519999, -8.995107458999932],
              [-38.319175043, -8.992060264999967],
              [-38.320127200999899, -8.991089435999978],
              [-38.320749647999897, -8.99074226399995],
              [-38.3235400249999, -8.990009184999961],
              [-38.331124458, -8.989900379999936],
              [-38.34023733, -8.990408375999959],
              [-38.34426759, -8.991470058999933],
              [-38.348287368999898, -8.993142389999948],
              [-38.356160718, -8.997142034999962],
              [-38.362441819, -9.000941237999941],
              [-38.376243006, -9.015922066999957],
              [-38.3858988429999, -9.024825678999955],
              [-38.396695752, -9.033041443999929],
              [-38.399045178706309, -9.034485426736364],
              [-38.399045178706309, -8.950461434175613],
              [-38.299045178706308, -8.950461434175613],
              [-38.299045178706308, -9.019135058055978],
              [-38.301506721, -9.015673419999933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '89',
        ID: 37890.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -9.0005,
        ANNUAL: 5639.0,
        JAN: 6378.0,
        FEB: 6190.0,
        MAR: 6178.0,
        APR: 5511.0,
        MAY: 4659.0,
        JUN: 4142.0,
        JUL: 4350.0,
        AUG: 5049.0,
        SEP: 5974.0,
        OCT: 6160.0,
        NOV: 6581.0,
        DEC: 6503.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.289959274, -9.047161836999974],
              [-38.288880434, -9.040343033999934],
              [-38.289675766, -9.036334421999982],
              [-38.29113259, -9.033414332999946],
              [-38.296402488, -9.022851442999984],
              [-38.299045178706308, -9.019135058055978],
              [-38.299045178706308, -8.950461434175613],
              [-38.199045178706307, -8.950461434175613],
              [-38.199045178706307, -9.050461434175613],
              [-38.292692604709679, -9.050461434175613],
              [-38.289959274, -9.047161836999974],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '90',
        ID: 37891.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -9.0005,
        ANNUAL: 5659.0,
        JAN: 6422.0,
        FEB: 6167.0,
        MAR: 6127.0,
        APR: 5472.0,
        MAY: 4630.0,
        JUN: 4151.0,
        JUL: 4382.0,
        AUG: 5089.0,
        SEP: 6052.0,
        OCT: 6271.0,
        NOV: 6642.0,
        DEC: 6496.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -9.050461434175613],
              [-38.199045178706307, -8.950461434175613],
              [-38.099045178706305, -8.950461434175613],
              [-38.099045178706305, -9.050461434175613],
              [-38.199045178706307, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '91',
        ID: 37892.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -9.0005,
        ANNUAL: 5680.0,
        JAN: 6462.0,
        FEB: 6198.0,
        MAR: 6129.0,
        APR: 5464.0,
        MAY: 4635.0,
        JUN: 4177.0,
        JUL: 4395.0,
        AUG: 5102.0,
        SEP: 6068.0,
        OCT: 6340.0,
        NOV: 6700.0,
        DEC: 6488.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -9.050461434175613],
              [-38.099045178706305, -8.950461434175613],
              [-37.999045178706304, -8.950461434175613],
              [-37.999045178706304, -9.050461434175613],
              [-38.099045178706305, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '92',
        ID: 37893.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -9.0005,
        ANNUAL: 5583.0,
        JAN: 6339.0,
        FEB: 6099.0,
        MAR: 6018.0,
        APR: 5371.0,
        MAY: 4530.0,
        JUN: 4018.0,
        JUL: 4242.0,
        AUG: 4967.0,
        SEP: 5904.0,
        OCT: 6266.0,
        NOV: 6722.0,
        DEC: 6518.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.900285788, -9.038279529999953],
              [-37.90493262, -9.040235850999979],
              [-37.905735766, -9.040992758999955],
              [-37.906538650999899, -9.042953224999954],
              [-37.907046467829694, -9.050461434175613],
              [-37.999045178706304, -9.050461434175613],
              [-37.999045178706304, -8.950461434175613],
              [-37.899045178706302, -8.950461434175613],
              [-37.899045178706302, -9.038150784166472],
              [-37.900285788, -9.038279529999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '93',
        ID: 37894.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -9.0005,
        ANNUAL: 5538.0,
        JAN: 6345.0,
        FEB: 6057.0,
        MAR: 6024.0,
        APR: 5373.0,
        MAY: 4454.0,
        JUN: 3952.0,
        JUL: 4131.0,
        AUG: 4848.0,
        SEP: 5827.0,
        OCT: 6217.0,
        NOV: 6705.0,
        DEC: 6520.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.831515671, -8.952943198999952],
              [-37.834205079999897, -8.959841911999934],
              [-37.839098619, -8.978381217999985],
              [-37.846008388, -8.984770909999956],
              [-37.848163633, -8.988311632999968],
              [-37.848166745999897, -8.98831529199998],
              [-37.850319768, -8.991852242999931],
              [-37.851779717999896, -8.996214465999966],
              [-37.852276076, -8.997696803999936],
              [-37.853093272, -9.000139771999953],
              [-37.863802742, -9.008660457999952],
              [-37.867151925, -9.01163109599997],
              [-37.8725415769999, -9.01184130099994],
              [-37.874486357, -9.01151501799994],
              [-37.877618321999897, -9.013286444999949],
              [-37.879598101999903, -9.015413861999955],
              [-37.879458056, -9.01709015299997],
              [-37.878527978, -9.018962191999945],
              [-37.875599243999901, -9.022669776999974],
              [-37.875227587, -9.024621980999939],
              [-37.877208612999901, -9.031100830999945],
              [-37.877569374, -9.03537603199992],
              [-37.878261114999908, -9.036955960999922],
              [-37.880147205, -9.039305402999952],
              [-37.880983441999902, -9.040346788999942],
              [-37.882707706, -9.040663518999949],
              [-37.883790646, -9.040055361999986],
              [-37.885565587, -9.037292001999964],
              [-37.887706554, -9.035073891999961],
              [-37.889767741999904, -9.034794266999942],
              [-37.89137357, -9.035323816999956],
              [-37.89282351, -9.036875038999932],
              [-37.896317849, -9.037867751999942],
              [-37.899045178706302, -9.038150784166472],
              [-37.899045178706302, -8.950461434175613],
              [-37.83082613758328, -8.950461434175613],
              [-37.831515671, -8.952943198999952],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '94',
        ID: 37895.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -9.0005,
        ANNUAL: 5564.0,
        JAN: 6408.0,
        FEB: 6052.0,
        MAR: 6078.0,
        APR: 5414.0,
        MAY: 4511.0,
        JUN: 3994.0,
        JUL: 4175.0,
        AUG: 4882.0,
        SEP: 5835.0,
        OCT: 6249.0,
        NOV: 6680.0,
        DEC: 6485.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699388876999897, -8.986321954999941],
              [-37.699553019999897, -8.985566899999972],
              [-37.699559526, -8.985542101999949],
              [-37.699585661999897, -8.985442488999981],
              [-37.699743231999904, -8.984841937999928],
              [-37.699746343, -8.984830082999959],
              [-37.6998484759999, -8.984330359999944],
              [-37.699830555999903, -8.984055555999927],
              [-37.699622324, -8.984027608999957],
              [-37.699071535, -8.984220931999973],
              [-37.699045178706299, -8.984239548706151],
              [-37.699045178706299, -8.986787611399233],
              [-37.699388876999897, -8.986321954999941],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '95',
        ID: 37896.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -9.0005,
        ANNUAL: 5578.0,
        JAN: 6380.0,
        FEB: 6116.0,
        MAR: 6066.0,
        APR: 5457.0,
        MAY: 4504.0,
        JUN: 3976.0,
        JUL: 4173.0,
        AUG: 4889.0,
        SEP: 5879.0,
        OCT: 6265.0,
        NOV: 6721.0,
        DEC: 6504.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599524694, -8.97177143499994],
              [-37.6003484189999, -8.972846853999958],
              [-37.601392005, -8.974412231999956],
              [-37.601973105, -8.975283882999969],
              [-37.604123425, -8.978103190999944],
              [-37.605472221999896, -8.98016666699993],
              [-37.605534717999902, -8.980285565999951],
              [-37.608280709, -8.985509846999957],
              [-37.610166666999902, -8.987861110999965],
              [-37.611812304, -8.989013559999927],
              [-37.613632616, -8.990288335999933],
              [-37.614767338, -8.990836719999953],
              [-37.6184303759999, -8.992606981999927],
              [-37.618805556, -8.992972221999928],
              [-37.619045188, -8.99306094099995],
              [-37.62073688, -8.994310036999929],
              [-37.622518209, -8.995849461999962],
              [-37.622853133, -8.996128778999941],
              [-37.623128189999903, -8.996005284999969],
              [-37.623521127999901, -8.99592669699997],
              [-37.623880385999897, -8.995971603999976],
              [-37.624598901, -8.996179299999939],
              [-37.6265860459999, -8.997453541999962],
              [-37.627832220999899, -8.998318005999977],
              [-37.6282881, -8.998753624999942],
              [-37.628379880999901, -8.998831280999971],
              [-37.628507978, -8.998984636999984],
              [-37.628583332999902, -8.999138888999941],
              [-37.628544061999897, -8.99927511099996],
              [-37.628414786, -8.999530311999925],
              [-37.628376272, -8.999708115999963],
              [-37.628437560999899, -9.000028177999981],
              [-37.628546413, -9.000263610999975],
              [-37.628708242999899, -9.000451441999928],
              [-37.629252924, -9.000565349999933],
              [-37.629468587, -9.00066897199997],
              [-37.629721392, -9.000707945999975],
              [-37.630085853, -9.001006045999921],
              [-37.6308990429999, -9.001461094999968],
              [-37.631337238, -9.001593817999956],
              [-37.6320514129999, -9.00189718399997],
              [-37.632487501999897, -9.002196336999926],
              [-37.632637078, -9.00237540699993],
              [-37.632854838999897, -9.002430798999962],
              [-37.63305065, -9.002461278999926],
              [-37.633515240999898, -9.002625686999984],
              [-37.633822814, -9.002758690999938],
              [-37.633844981, -9.002761461999976],
              [-37.634059266, -9.002862138999934],
              [-37.634235681, -9.002898160999962],
              [-37.634420409, -9.002896312999956],
              [-37.634544177, -9.002906473999923],
              [-37.634687341, -9.002984982999989],
              [-37.634871146, -9.003064415999964],
              [-37.635081736, -9.003111521999928],
              [-37.635444725999903, -9.003081041999961],
              [-37.6356554, -9.003147102999945],
              [-37.635792938999899, -9.003147543999939],
              [-37.636000913, -9.003085815999956],
              [-37.636103227, -9.003066757999926],
              [-37.636160869, -9.003059771999965],
              [-37.636266933999899, -9.003059126999961],
              [-37.636507434999899, -9.003158744999945],
              [-37.637650650999902, -9.003762345999966],
              [-37.637666667, -9.003777777999975],
              [-37.638356874, -9.003802870999955],
              [-37.639043396, -9.003538823999961],
              [-37.639597894, -9.003089944999944],
              [-37.639941155, -9.002244993999961],
              [-37.640363631, -9.001479257999968],
              [-37.640654082, -9.00100397299996],
              [-37.6409181289999, -9.00060790299996],
              [-37.640970938999907, -9.000159022999989],
              [-37.640342108, -8.999033076999977],
              [-37.639976454, -8.997082924999972],
              [-37.639895198, -8.995132772999975],
              [-37.640463992, -8.994482722999985],
              [-37.641560953, -8.994523350999941],
              [-37.642454772999898, -8.994117068999973],
              [-37.64318608, -8.993020107999939],
              [-37.64383613, -8.993263876999944],
              [-37.644079899, -8.992776338999931],
              [-37.644851834, -8.992573198999935],
              [-37.645989422999897, -8.993507645999966],
              [-37.64716764, -8.993507645999964],
              [-37.648874023, -8.994482722999981],
              [-37.650417893999901, -8.994726491999929],
              [-37.652327417, -8.995010888999964],
              [-37.653668147, -8.994726491999938],
              [-37.65492762, -8.99427958199993],
              [-37.656105837, -8.993101364999967],
              [-37.657777778, -8.992666666999984],
              [-37.657784046, -8.992621900999943],
              [-37.657879129, -8.991942829999973],
              [-37.657592641, -8.991109408999934],
              [-37.657462419, -8.990328076999965],
              [-37.657794112, -8.989702255999935],
              [-37.657800995999899, -8.988452880999946],
              [-37.657706813999901, -8.988223312999933],
              [-37.657384286, -8.987437148999954],
              [-37.657540551999901, -8.986603727999974],
              [-37.658087484, -8.985796351999973],
              [-37.658966483, -8.985854951999952],
              [-37.659259482, -8.985874484999947],
              [-37.660944443999902, -8.986694443999966],
              [-37.661572684, -8.987310279999965],
              [-37.6616477009999, -8.987248114999941],
              [-37.666884215, -8.988950618999983],
              [-37.668922745, -8.99062492399997],
              [-37.67454393700001, -8.992980201999956],
              [-37.677678537, -8.994967194999962],
              [-37.678016451, -8.995145825999943],
              [-37.678021371999897, -8.995148427999929],
              [-37.682152836999897, -8.997332444999932],
              [-37.682270048, -8.997072426999974],
              [-37.683523572, -8.994291665999977],
              [-37.683537322999904, -8.994261162999969],
              [-37.683670513, -8.99395420199993],
              [-37.683958256, -8.993291046999964],
              [-37.68400079700001, -8.993193005999959],
              [-37.684214702, -8.992641323999978],
              [-37.684428608, -8.992300922999958],
              [-37.684642516999901, -8.99214832799993],
              [-37.6849515, -8.992089632999988],
              [-37.685246371999902, -8.992025241999952],
              [-37.685519703999901, -8.991661350999953],
              [-37.685923752999898, -8.991121388999941],
              [-37.686102005999899, -8.990769246999953],
              [-37.686102002, -8.990546227999962],
              [-37.685995041, -8.990182359999952],
              [-37.685908038, -8.989885972999964],
              [-37.685993141, -8.98952870399995],
              [-37.686000233, -8.989514694],
              [-37.68613498, -8.989101382999936],
              [-37.686138526, -8.989094377999944],
              [-37.686142071999903, -8.989087372999967],
              [-37.686347743999903, -8.988786143999965],
              [-37.686365827, -8.988765977999941],
              [-37.686567599999897, -8.988540957999929],
              [-37.686858368999907, -8.988008558999974],
              [-37.686858367999903, -8.988001553999933],
              [-37.686858367999903, -8.987994548999952],
              [-37.686929285, -8.987805407999936],
              [-37.687071128999897, -8.98773535299995],
              [-37.687085313, -8.987735352999959],
              [-37.687241345, -8.987798394999963],
              [-37.687482486, -8.98795250099994],
              [-37.687515412, -8.987976295999941],
              [-37.687773273999902, -8.988162646999973],
              [-37.688085338999898, -8.988393805999971],
              [-37.68846832, -8.988456839999976],
              [-37.689042793999903, -8.988610932999951],
              [-37.689071162999902, -8.988617936999958],
              [-37.689425776, -8.988680970999953],
              [-37.689482518, -8.988793050999963],
              [-37.689546358, -8.989059243999984],
              [-37.690035737, -8.989416488999948],
              [-37.6900521009999, -8.989426419999976],
              [-37.690531979999903, -8.989717642999954],
              [-37.690871479999899, -8.989853567999951],
              [-37.69118345, -8.989944180999935],
              [-37.691220126, -8.990069863999961],
              [-37.691220157, -8.990089178999943],
              [-37.691073356999901, -8.990243245999974],
              [-37.691057268999899, -8.990254722999955],
              [-37.690908201999903, -8.990361064999957],
              [-37.690981614999899, -8.990623874999933],
              [-37.691385345, -8.990859479999925],
              [-37.691706483, -8.990868527999963],
              [-37.691825756999897, -8.990723523999975],
              [-37.691945022, -8.990342896999953],
              [-37.691963359999903, -8.990261374999934],
              [-37.692000064, -8.990098208999934],
              [-37.692156038999897, -8.990007577999956],
              [-37.692486341999903, -8.989889752999932],
              [-37.692752418999902, -8.989808179999953],
              [-37.692963452, -8.989898794999929],
              [-37.693146969999901, -8.99021596899996],
              [-37.693578203, -8.990270323999944],
              [-37.693743348999902, -8.990152504999971],
              [-37.693789217, -8.990007505999923],
              [-37.6938075589999, -8.989826257999948],
              [-37.693789207999899, -8.989817196999978],
              [-37.693878259, -8.989650733999941],
              [-37.694081750999899, -8.989560276999947],
              [-37.694285249, -8.989595440999944],
              [-37.694366653, -8.989716034999963],
              [-37.694352076, -8.98987929399993],
              [-37.694351399, -8.989886881999956],
              [-37.694366671, -8.99008285199994],
              [-37.694475101, -8.990199930999943],
              [-37.694475101, -8.990214735999931],
              [-37.694317742, -8.99063666999996],
              [-37.694310248, -8.990658877999977],
              [-37.694370212, -8.990851332999972],
              [-37.694669989, -8.990932742999981],
              [-37.694677621, -8.990932741999934],
              [-37.694699966, -8.990932740999932],
              [-37.694962265, -8.990903118999945],
              [-37.69501472000001, -8.990799484999972],
              [-37.69499972499991, -8.990666245999922],
              [-37.695067168, -8.990555208999979],
              [-37.69526202, -8.990562600999974],
              [-37.695736827, -8.990789729999957],
              [-37.695741669, -8.990792045999969],
              [-37.695997433999899, -8.990905053999944],
              [-37.696002824999901, -8.990905832999934],
              [-37.696458838999902, -8.990971723999968],
              [-37.697077808, -8.991182887999971],
              [-37.697741784999899, -8.991205088999946],
              [-37.697927555, -8.991097141999933],
              [-37.697933093, -8.991093923999937],
              [-37.698248170999904, -8.990671516999949],
              [-37.698411425, -8.99023928799993],
              [-37.698230192, -8.989791859999961],
              [-37.69796994, -8.989633693999952],
              [-37.6979717729999, -8.989633838999952],
              [-37.697973605, -8.989633983999978],
              [-37.697725567, -8.989458898999933],
              [-37.697667205999899, -8.989225451999971],
              [-37.698104918999903, -8.988204120999967],
              [-37.698484269999902, -8.98798526499996],
              [-37.698921344, -8.986970124999967],
              [-37.69892988, -8.986950299999938],
              [-37.698933226999898, -8.986942526999945],
              [-37.698936574, -8.986934752999964],
              [-37.698977326, -8.986879540999952],
              [-37.699045178706299, -8.986787611399233],
              [-37.699045178706299, -8.984239548706151],
              [-37.698734497, -8.984458997999983],
              [-37.698619128, -8.984540488999931],
              [-37.698611936, -8.984545568999973],
              [-37.698603379, -8.984550489999954],
              [-37.698206432999903, -8.98477873299993],
              [-37.697882415, -8.984965043999985],
              [-37.697515482, -8.984938209999941],
              [-37.697482207, -8.984935776999979],
              [-37.697146667, -8.984870181999945],
              [-37.6967404869999, -8.984575007999988],
              [-37.695968493, -8.983808059999948],
              [-37.69581863, -8.983498069999936],
              [-37.695784325, -8.98342710899993],
              [-37.695819645, -8.982700525999975],
              [-37.695703593, -8.982309482999931],
              [-37.695352915999898, -8.98189068899995],
              [-37.695297412999899, -8.981532442999939],
              [-37.694974488, -8.98132304599994],
              [-37.694611196, -8.981179242999932],
              [-37.694328636, -8.981143922999928],
              [-37.693853078, -8.981186811999972],
              [-37.693730719, -8.981352058999965],
              [-37.693552858, -8.981430267999931],
              [-37.693516277, -8.98143152899995],
              [-37.693485693999897, -8.981390468999964],
              [-37.69348151, -8.981385686999936],
              [-37.693364798999902, -8.981252302999954],
              [-37.693174821, -8.981162494999973],
              [-37.693067743, -8.981176311999949],
              [-37.692746507, -8.981328293999976],
              [-37.692632521, -8.981338655999931],
              [-37.692480851, -8.981261068999974],
              [-37.692342372, -8.98114176999997],
              [-37.6922974679999, -8.981013966999972],
              [-37.692183482, -8.980951791999928],
              [-37.691869154, -8.981031237999954],
              [-37.691738749, -8.981097443999941],
              [-37.691420116, -8.98125921099996],
              [-37.691185233999903, -8.981293752999925],
              [-37.690905448, -8.981387013999948],
              [-37.690772098, -8.981400717999971],
              [-37.690638748, -8.981414421999936],
              [-37.690533484999904, -8.981275495999963],
              [-37.690140649999897, -8.980651619999943],
              [-37.690013279, -8.980531364999925],
              [-37.689861874, -8.980566059999935],
              [-37.689601992999897, -8.980627426999936],
              [-37.689582781, -8.980621022999969],
              [-37.689434080999902, -8.980521210999937],
              [-37.689285686999902, -8.980443260999948],
              [-37.689263286, -8.980429610999984],
              [-37.689233401, -8.980430322999958],
              [-37.688969409, -8.98045807399996],
              [-37.68852564, -8.980356301999958],
              [-37.688250013, -8.980172734999949],
              [-37.688091207, -8.979973012999947],
              [-37.68798104, -8.979872452999931],
              [-37.687829475999898, -8.979863202999971],
              [-37.687687163, -8.979834027999969],
              [-37.68762759, -8.979746179999951],
              [-37.687445941, -8.979708080999961],
              [-37.687296299, -8.979690322999936],
              [-37.687221179999902, -8.979603647999967],
              [-37.687186509999897, -8.979482302999932],
              [-37.687240441, -8.979197237999928],
              [-37.6872828159999, -8.979083596999942],
              [-37.687308818, -8.978848610999934],
              [-37.6872221429999, -8.978734970999939],
              [-37.687078648, -8.978675260999976],
              [-37.6870738329999, -8.978367082999966],
              [-37.687023753999902, -8.978192769999959],
              [-37.686906128, -8.978083925999952],
              [-37.686873536999897, -8.978083479999947],
              [-37.686759720999902, -8.978107159999972],
              [-37.68660542, -8.978272154999969],
              [-37.686602842, -8.978273187999946],
              [-37.686405287, -8.978352360999963],
              [-37.686375063, -8.978355382999943],
              [-37.68636693, -8.978351889999939],
              [-37.686157575, -8.978257274999972],
              [-37.685944827999897, -8.978099684999968],
              [-37.685901491, -8.97794603499996],
              [-37.685767539, -8.977768745999972],
              [-37.685227793, -8.977445685999946],
              [-37.685029816999901, -8.977251192999967],
              [-37.684972221999899, -8.977111110999942],
              [-37.684871597, -8.976968101999944],
              [-37.684776972999899, -8.976742458999972],
              [-37.684645981, -8.976504846999944],
              [-37.684345101, -8.976402271999968],
              [-37.683996992999901, -8.976374968999945],
              [-37.683819527, -8.976470527999945],
              [-37.683710317, -8.976647994999949],
              [-37.683334906, -8.976968799999945],
              [-37.6830891829999, -8.977043881999988],
              [-37.682857111, -8.977173568999946],
              [-37.68268647, -8.977166743999931],
              [-37.682495351999897, -8.977057532999932],
              [-37.682433922, -8.976859589999956],
              [-37.68231106, -8.976620691999932],
              [-37.682106291, -8.976572912999936],
              [-37.681744275, -8.976861184999954],
              [-37.681740991, -8.976863799999933],
              [-37.681737706, -8.976866415999947],
              [-37.68151246, -8.976982450999976],
              [-37.681504732999898, -8.976980417999933],
              [-37.681253086, -8.976914194999951],
              [-37.680782117, -8.977064358999947],
              [-37.680747370999903, -8.977076302999935],
              [-37.680563696, -8.977139440999961],
              [-37.6804203579999, -8.97709848699998],
              [-37.68007225, -8.977016579999933],
              [-37.679845239, -8.977018443999954],
              [-37.679826479, -8.977027102999944],
              [-37.679642235, -8.977112138999921],
              [-37.678775378, -8.977159917999938],
              [-37.678743644999898, -8.977152594999946],
              [-37.678509178, -8.97709848699998],
              [-37.678479739, -8.977087781999954],
              [-37.6782839319999, -8.97701657999994],
              [-37.678147419, -8.976777681999982],
              [-37.677840264999901, -8.976702599999944],
              [-37.677511028, -8.976674461999938],
              [-37.677473839, -8.976677702999947],
              [-37.677436649, -8.976680944999945],
              [-37.6771099219999, -8.976709425999955],
              [-37.677071337, -8.976698778999953],
              [-37.676889581, -8.976648627999962],
              [-37.676222589, -8.976265758999931],
              [-37.675635583, -8.97603368699993],
              [-37.6751714399999, -8.97596543099996],
              [-37.674825598, -8.975838541999964],
              [-37.674798058, -8.97583290099992],
              [-37.674298291, -8.97573052899997],
              [-37.673772184, -8.975528589999955],
              [-37.673533286, -8.975508112999931],
              [-37.672519413, -8.975083622999923],
              [-37.671916666999905, -8.9745],
              [-37.678406977999899, -8.967494584999933],
              [-37.689416666999897, -8.955611110999945],
              [-37.689467904, -8.955461138999958],
              [-37.690028675, -8.953819744999976],
              [-37.690299124, -8.952679997999942],
              [-37.690183217, -8.952158418999927],
              [-37.689990039999898, -8.951675475999934],
              [-37.689700274, -8.951501615999966],
              [-37.68926792, -8.951456889999962],
              [-37.689105626999897, -8.951307387999988],
              [-37.689062787999909, -8.951037989999971],
              [-37.689179825999901, -8.950730280999943],
              [-37.689347855350043, -8.950461434175613],
              [-37.599045178706298, -8.950461434175613],
              [-37.599045178706298, -8.971145401159218],
              [-37.599524694, -8.97177143499994],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '96',
        ID: 37897.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -9.0005,
        ANNUAL: 5553.0,
        JAN: 6352.0,
        FEB: 6123.0,
        MAR: 6086.0,
        APR: 5429.0,
        MAY: 4460.0,
        JUN: 3958.0,
        JUL: 4120.0,
        AUG: 4834.0,
        SEP: 5823.0,
        OCT: 6242.0,
        NOV: 6715.0,
        DEC: 6489.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499184761, -8.968952048999938],
              [-37.499487762999898, -8.968773811999942],
              [-37.499719470999899, -8.968399514999925],
              [-37.499862059999899, -8.968132159999934],
              [-37.50015022, -8.967970069999986],
              [-37.500432417999903, -8.967811333999974],
              [-37.501430543, -8.96688450299996],
              [-37.50194743, -8.966456734999941],
              [-37.50248214, -8.966385439999955],
              [-37.5029099079999, -8.966171555999949],
              [-37.503266380999897, -8.966046790999938],
              [-37.503694149, -8.965975495999935],
              [-37.504389273, -8.965387314999928],
              [-37.50495963, -8.965387314999933],
              [-37.505423044999901, -8.965797258999947],
              [-37.505922107999901, -8.96679538499995],
              [-37.506064697999904, -8.967044915999965],
              [-37.507419296, -8.967543978999975],
              [-37.50797183, -8.967543978999966],
              [-37.509023426999903, -8.967454859999981],
              [-37.509255133999901, -8.967294446999963],
              [-37.509451194999897, -8.96706273999996],
              [-37.509950257999897, -8.966741913999952],
              [-37.510912736, -8.966688442999944],
              [-37.511786096, -8.966670618999967],
              [-37.512320805999899, -8.966421087999944],
              [-37.512605983999897, -8.966046790999942],
              [-37.513390226, -8.965725963999947],
              [-37.5150300029999, -8.965476432999948],
              [-37.516239135, -8.965612452999949],
              [-37.5169477009999, -8.965848640999972],
              [-37.517318855, -8.966523466999934],
              [-37.517512693, -8.966911440999981],
              [-37.517744010999898, -8.96711721199995],
              [-37.519111111, -8.968333332999986],
              [-37.520490216, -8.966724799999952],
              [-37.522260156, -8.965637550999931],
              [-37.522810886999899, -8.965299244999928],
              [-37.522836793, -8.965281378999981],
              [-37.523772307999899, -8.964636195999955],
              [-37.52446851, -8.964006298999941],
              [-37.524484658999903, -8.963993821999956],
              [-37.525305290999903, -8.963359771999938],
              [-37.526172173, -8.962689986999981],
              [-37.526317886, -8.962577403999935],
              [-37.526651382, -8.962319732999957],
              [-37.526927566, -8.961937868999943],
              [-37.52739768, -8.961287867999946],
              [-37.527519142, -8.961119928999953],
              [-37.528027778, -8.960416666999954],
              [-37.528277919999901, -8.960354522999973],
              [-37.528486362, -8.960302739999971],
              [-37.52861162300001, -8.960271620999956],
              [-37.529144120999902, -8.960139331999928],
              [-37.529325343, -8.960094309999924],
              [-37.53082921, -8.959979729999933],
              [-37.531065847, -8.959961700999949],
              [-37.532203839, -8.959906635999973],
              [-37.533121299, -8.959862242999979],
              [-37.534309712, -8.960040504999935],
              [-37.537162221, -8.960105606999942],
              [-37.537828947, -8.960144038999946],
              [-37.537902941, -8.960146020999964],
              [-37.539685484, -8.960193767999955],
              [-37.541142949999902, -8.960422783999951],
              [-37.541145892, -8.960423101999941],
              [-37.541292836, -8.960438974999931],
              [-37.545689056, -8.960913856999985],
              [-37.54571407, -8.960916558999939],
              [-37.545972221999897, -8.960944443999947],
              [-37.546518963, -8.961352351999953],
              [-37.546581734, -8.961399182999976],
              [-37.546665971, -8.961462029999952],
              [-37.546791272999897, -8.961555513999949],
              [-37.546796479, -8.961559397999965],
              [-37.5483884349999, -8.962747109999956],
              [-37.548411339, -8.962764197999952],
              [-37.550009786, -8.964355169999974],
              [-37.552186874, -8.966522076999977],
              [-37.554761641, -8.968832922999972],
              [-37.555944444, -8.970472221999959],
              [-37.556566983, -8.97071033999994],
              [-37.556568929, -8.970711083999962],
              [-37.559779053999897, -8.97193894099996],
              [-37.562980641, -8.971938940999959],
              [-37.569049321, -8.971938940999953],
              [-37.572442048, -8.972034510999956],
              [-37.577316106, -8.971317736999948],
              [-37.580094244, -8.970882070999954],
              [-37.583682074, -8.969979275999957],
              [-37.584833332999899, -8.97013888899993],
              [-37.587975059, -8.969571184999944],
              [-37.589868704, -8.969523881999978],
              [-37.592879829, -8.969156110999958],
              [-37.59575, -8.968805555999943],
              [-37.597051262, -8.969071847999935],
              [-37.598628162999901, -8.970600963999978],
              [-37.599045178706298, -8.971145401159218],
              [-37.599045178706298, -8.950461434175613],
              [-37.499045178706297, -8.950461434175613],
              [-37.499045178706297, -8.969017187055616],
              [-37.499184761, -8.968952048999938],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '97',
        ID: 37898.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -9.0005,
        ANNUAL: 5554.0,
        JAN: 6341.0,
        FEB: 6151.0,
        MAR: 6181.0,
        APR: 5439.0,
        MAY: 4436.0,
        JUN: 3967.0,
        JUL: 4119.0,
        AUG: 4815.0,
        SEP: 5785.0,
        OCT: 6250.0,
        NOV: 6673.0,
        DEC: 6490.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.39990396, -9.018203013999937],
              [-37.40074852, -9.018088884999942],
              [-37.401752861, -9.018499750999984],
              [-37.402118075, -9.018522576999944],
              [-37.402391986999902, -9.018203013999939],
              [-37.402574593999901, -9.017906276999948],
              [-37.402848505, -9.017198672999932],
              [-37.403305024, -9.01644541799994],
              [-37.403729649999903, -9.015538261999945],
              [-37.404012628, -9.01489325399996],
              [-37.404195235, -9.014276953999973],
              [-37.405499748, -9.013003231999951],
              [-37.405526123999898, -9.012977477999929],
              [-37.406181091, -9.012336749999964],
              [-37.406888695, -9.011948708999968],
              [-37.4076876029999, -9.011674797999941],
              [-37.408346131, -9.011371973999932],
              [-37.409274384999897, -9.010815020999926],
              [-37.409895221999903, -9.010337453999977],
              [-37.410481116, -9.009886766999948],
              [-37.410991656, -9.009283400999951],
              [-37.411873498, -9.009144162999979],
              [-37.413126642, -9.009190575999982],
              [-37.413637181999903, -9.008772860999947],
              [-37.413915658, -9.008215907999949],
              [-37.41467195, -9.007682781999957],
              [-37.416502459, -9.006564137999922],
              [-37.417366865, -9.00651328999993],
              [-37.418739747, -9.006869221999986],
              [-37.420468561, -9.007021764999935],
              [-37.422858391, -9.006665832999944],
              [-37.423926187999903, -9.006665832999932],
              [-37.42509568, -9.007377696999987],
              [-37.426366866, -9.007530238999943],
              [-37.427332968, -9.007174306999961],
              [-37.42829907, -9.007479391999931],
              [-37.429926188, -9.007631933999965],
              [-37.431197375, -9.007682781999964],
              [-37.4329261889999, -9.007072611999938],
              [-37.434044833, -9.006716679999954],
              [-37.4346550019999, -9.006767527999955],
              [-37.435773647, -9.006767527999958],
              [-37.43811263, -9.006259052999948],
              [-37.439485510999901, -9.005877696999963],
              [-37.440909239999897, -9.005292950999937],
              [-37.441570257, -9.004784476999935],
              [-37.442155003, -9.004174306999975],
              [-37.442993986, -9.004021764999948],
              [-37.443756698, -9.004174306999968],
              [-37.444544833999899, -9.004326848999931],
              [-37.445205851, -9.004199730999968],
              [-37.447036359, -9.003437018999922],
              [-37.448027884999902, -9.002903119999932],
              [-37.449044833999899, -9.002648882999946],
              [-37.450493987, -9.002521764999928],
              [-37.451816021, -9.002470916999929],
              [-37.4526041559999, -9.002242102999963],
              [-37.453265173, -9.001555662999989],
              [-37.453875342999901, -9.001428543999966],
              [-37.454307545999903, -9.001631933999931],
              [-37.4548414449999, -9.001581085999925],
              [-37.455400767, -9.001682780999939],
              [-37.45603636, -9.001682780999941],
              [-37.4565956819999, -9.001453967999963],
              [-37.457099457, -9.001541562999936],
              [-37.4601950959999, -9.00114355199997],
              [-37.461123787, -9.00087821199998],
              [-37.462096701999897, -9.000347530999928],
              [-37.464661659999898, -8.999330391999926],
              [-37.466165256, -8.998799710999956],
              [-37.466585379, -8.99879971099995],
              [-37.468199533, -8.998578593999984],
              [-37.468617617999897, -8.998323089999925],
              [-37.477416594, -8.992970486999981],
              [-37.477809948999898, -8.992624744999933],
              [-37.478398754999901, -8.992532329999959],
              [-37.479231262, -8.992767944999967],
              [-37.479890984, -8.992893605999942],
              [-37.480425044999897, -8.992548037999933],
              [-37.481288967, -8.991432792999946],
              [-37.482121473999896, -8.990380377999944],
              [-37.48268695, -8.989594994999942],
              [-37.483048227, -8.989312256999938],
              [-37.483060936, -8.989312186999937],
              [-37.483477897999897, -8.989309913999938],
              [-37.483979586, -8.988975774999963],
              [-37.484509484999897, -8.989051474999936],
              [-37.485493584, -8.989682306999942],
              [-37.486225348999902, -8.990313138999976],
              [-37.486755248, -8.990943970999979],
              [-37.487638413, -8.99112060399994],
              [-37.488420645, -8.990943970999984],
              [-37.48968231, -8.991372936999932],
              [-37.49018754, -8.991685138999959],
              [-37.490295053, -8.991751575999956],
              [-37.491297239999902, -8.992029002999979],
              [-37.491702865, -8.992422490999967],
              [-37.491798844, -8.992515598999933],
              [-37.492102028, -8.992721762999951],
              [-37.492478984999899, -8.992970312999947],
              [-37.492729875999899, -8.993123685999924],
              [-37.492886461999902, -8.993334723999947],
              [-37.493085231999899, -8.993404292999964],
              [-37.493205390999897, -8.993359600999947],
              [-37.493290172999899, -8.993267445999953],
              [-37.4934228669999, -8.993126675999974],
              [-37.493522401999897, -8.993005728999952],
              [-37.493633014999901, -8.992847185999945],
              [-37.493633014999901, -8.992518641999942],
              [-37.493451749, -8.992201425999944],
              [-37.493213838, -8.991782248999929],
              [-37.4929872559999, -8.991272438999944],
              [-37.4930039179999, -8.99111692499997],
              [-37.493018558, -8.990980279999976],
              [-37.493021242999902, -8.990955222999961],
              [-37.492944692, -8.990769313999973],
              [-37.492941938999898, -8.990762627999967],
              [-37.492670803, -8.990646426999941],
              [-37.4925056659999, -8.990520474999933],
              [-37.492443458, -8.990275475999965],
              [-37.492582464999899, -8.990034529999948],
              [-37.492630511999899, -8.989815987999984],
              [-37.492602065, -8.989607057999931],
              [-37.492482547999899, -8.989341462999958],
              [-37.492398141, -8.989153893999969],
              [-37.49242133, -8.988449159999956],
              [-37.492344709, -8.987555246999928],
              [-37.492510721, -8.987006128999948],
              [-37.492893826999904, -8.986546402999986],
              [-37.493340783, -8.986520861999963],
              [-37.493596185999898, -8.986405930999979],
              [-37.493661218999897, -8.986048253999964],
              [-37.4935195649999, -8.985831272999967],
              [-37.493251391999898, -8.985397086999965],
              [-37.49313646, -8.984950129999961],
              [-37.49326416199991, -8.984720266999942],
              [-37.493711118, -8.984375471999943],
              [-37.493966521999901, -8.983902975999971],
              [-37.493978194, -8.983766793999973],
              [-37.494004832, -8.983456018999959],
              [-37.494477328999899, -8.982230081999942],
              [-37.495115837999897, -8.981451100999951],
              [-37.495703266, -8.981208466999933],
              [-37.495856508, -8.980761510999978],
              [-37.495814079999903, -8.980167190999962],
              [-37.495805428, -8.979727126999926],
              [-37.495639415, -8.979203548999974],
              [-37.495690496, -8.978858753999925],
              [-37.4959459, -8.978475648999963],
              [-37.496060831, -8.977913760999979],
              [-37.496354545, -8.977415723999965],
              [-37.496507788, -8.976841065999949],
              [-37.496558868, -8.976240866999945],
              [-37.496941974, -8.97539803499996],
              [-37.497159067, -8.975168171999947],
              [-37.497133526, -8.974938308999983],
              [-37.496980284, -8.974861687999983],
              [-37.496788732, -8.97483614799995],
              [-37.49666103, -8.974644594999962],
              [-37.496750420999902, -8.974414731999969],
              [-37.496801502, -8.973916694999962],
              [-37.4969547439999, -8.973737911999924],
              [-37.497044135, -8.973405886999954],
              [-37.497005825, -8.973099402999937],
              [-37.4968908929999, -8.972907849999968],
              [-37.496852582, -8.972703526999966],
              [-37.496993054, -8.97251197499998],
              [-37.497111065, -8.97226474199994],
              [-37.497093142, -8.972037268999946],
              [-37.49689992, -8.971951664999946],
              [-37.49670349, -8.971927678999972],
              [-37.496456706999901, -8.971732993999973],
              [-37.496507788, -8.971515900999989],
              [-37.496852582, -8.971413738999964],
              [-37.497082446, -8.971260496999944],
              [-37.497402393999899, -8.970877004999975],
              [-37.497794515, -8.970645296999974],
              [-37.498186635, -8.970680944999971],
              [-37.498507461, -8.970556178999978],
              [-37.498721346, -8.970288823999979],
              [-37.498846111, -8.969771937999951],
              [-37.498756993, -8.969272874999934],
              [-37.498917406, -8.969076813999949],
              [-37.499045178706297, -8.969017187055616],
              [-37.499045178706297, -8.950461434175613],
              [-37.399045178706295, -8.950461434175613],
              [-37.399045178706295, -9.018459366901494],
              [-37.39990396, -9.018203013999937],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '98',
        ID: 37899.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -9.0005,
        ANNUAL: 5574.0,
        JAN: 6315.0,
        FEB: 6150.0,
        MAR: 6151.0,
        APR: 5427.0,
        MAY: 4436.0,
        JUN: 3948.0,
        JUL: 4210.0,
        AUG: 4873.0,
        SEP: 5841.0,
        OCT: 6293.0,
        NOV: 6737.0,
        DEC: 6510.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.391413127, -9.026983168999951],
              [-37.392417994, -9.026759864999976],
              [-37.393869469, -9.026983168999948],
              [-37.395568285, -9.028097527999941],
              [-37.395866124999898, -9.026575835999955],
              [-37.39630926800001, -9.025785005999978],
              [-37.396815778, -9.025514866999963],
              [-37.396883312999897, -9.024907054999968],
              [-37.396613174, -9.023927802999948],
              [-37.395937827, -9.022475806999985],
              [-37.39590406, -9.021125113999974],
              [-37.396140430999907, -9.020112093999957],
              [-37.396984615, -9.019402979999935],
              [-37.397862566, -9.018795166999944],
              [-37.398374622999903, -9.018659532999951],
              [-37.399045178706295, -9.018459366901494],
              [-37.399045178706295, -8.950461434175613],
              [-37.299045178706294, -8.950461434175613],
              [-37.299045178706294, -9.050461434175613],
              [-37.362814323128674, -9.050461434175613],
              [-37.391413127, -9.026983168999951],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '99',
        ID: 37900.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -9.0005,
        ANNUAL: 5587.0,
        JAN: 6322.0,
        FEB: 6136.0,
        MAR: 6111.0,
        APR: 5438.0,
        MAY: 4467.0,
        JUN: 4006.0,
        JUL: 4273.0,
        AUG: 4945.0,
        SEP: 5857.0,
        OCT: 6274.0,
        NOV: 6705.0,
        DEC: 6505.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -9.050461434175613],
              [-37.299045178706294, -8.950461434175613],
              [-37.199045178706292, -8.950461434175613],
              [-37.199045178706292, -9.050461434175613],
              [-37.299045178706294, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '100',
        ID: 37901.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -9.0005,
        ANNUAL: 5549.0,
        JAN: 6300.0,
        FEB: 6072.0,
        MAR: 6070.0,
        APR: 5399.0,
        MAY: 4435.0,
        JUN: 4001.0,
        JUL: 4220.0,
        AUG: 4908.0,
        SEP: 5823.0,
        OCT: 6207.0,
        NOV: 6653.0,
        DEC: 6501.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -9.050461434175613],
              [-37.199045178706292, -8.950461434175613],
              [-37.099045178706291, -8.950461434175613],
              [-37.099045178706291, -9.050461434175613],
              [-37.199045178706292, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '101',
        ID: 37902.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -9.0005,
        ANNUAL: 5564.0,
        JAN: 6280.0,
        FEB: 6078.0,
        MAR: 6083.0,
        APR: 5433.0,
        MAY: 4498.0,
        JUN: 4071.0,
        JUL: 4237.0,
        AUG: 4981.0,
        SEP: 5856.0,
        OCT: 6216.0,
        NOV: 6608.0,
        DEC: 6431.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -9.050461434175613],
              [-37.099045178706291, -8.950461434175613],
              [-36.99904517870629, -8.950461434175613],
              [-36.99904517870629, -9.050461434175613],
              [-37.099045178706291, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '102',
        ID: 37903.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -9.0005,
        ANNUAL: 5496.0,
        JAN: 6198.0,
        FEB: 5999.0,
        MAR: 6019.0,
        APR: 5392.0,
        MAY: 4477.0,
        JUN: 4024.0,
        JUL: 4206.0,
        AUG: 4894.0,
        SEP: 5736.0,
        OCT: 6100.0,
        NOV: 6520.0,
        DEC: 6386.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -9.050461434175613],
              [-36.99904517870629, -8.950461434175613],
              [-36.899045178706288, -8.950461434175613],
              [-36.899045178706288, -9.050461434175613],
              [-36.99904517870629, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '103',
        ID: 37904.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -9.0005,
        ANNUAL: 5439.0,
        JAN: 6157.0,
        FEB: 5904.0,
        MAR: 5882.0,
        APR: 5313.0,
        MAY: 4403.0,
        JUN: 3950.0,
        JUL: 4113.0,
        AUG: 4785.0,
        SEP: 5711.0,
        OCT: 6104.0,
        NOV: 6552.0,
        DEC: 6396.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -9.050461434175613],
              [-36.899045178706288, -8.950461434175613],
              [-36.799045178706287, -8.950461434175613],
              [-36.799045178706287, -9.050461434175613],
              [-36.899045178706288, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '104',
        ID: 37905.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -9.0005,
        ANNUAL: 5417.0,
        JAN: 6101.0,
        FEB: 5892.0,
        MAR: 5900.0,
        APR: 5279.0,
        MAY: 4312.0,
        JUN: 3873.0,
        JUL: 4018.0,
        AUG: 4729.0,
        SEP: 5726.0,
        OCT: 6122.0,
        NOV: 6621.0,
        DEC: 6434.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -9.050461434175613],
              [-36.799045178706287, -8.950461434175613],
              [-36.699045178706285, -8.950461434175613],
              [-36.699045178706285, -9.050461434175613],
              [-36.799045178706287, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '105',
        ID: 37906.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -9.0005,
        ANNUAL: 5349.0,
        JAN: 6079.0,
        FEB: 5850.0,
        MAR: 5897.0,
        APR: 5233.0,
        MAY: 4252.0,
        JUN: 3803.0,
        JUL: 3953.0,
        AUG: 4606.0,
        SEP: 5569.0,
        OCT: 5987.0,
        NOV: 6562.0,
        DEC: 6403.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -9.050461434175613],
              [-36.699045178706285, -8.950461434175613],
              [-36.599045178706284, -8.950461434175613],
              [-36.599045178706284, -9.050461434175613],
              [-36.699045178706285, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '106',
        ID: 37907.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -9.0005,
        ANNUAL: 5304.0,
        JAN: 6108.0,
        FEB: 5855.0,
        MAR: 5858.0,
        APR: 5192.0,
        MAY: 4242.0,
        JUN: 3732.0,
        JUL: 3870.0,
        AUG: 4531.0,
        SEP: 5510.0,
        OCT: 5901.0,
        NOV: 6509.0,
        DEC: 6346.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -9.050461434175613],
              [-36.599045178706284, -8.950461434175613],
              [-36.499045178706282, -8.950461434175613],
              [-36.499045178706282, -9.050461434175613],
              [-36.599045178706284, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '107',
        ID: 37908.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -9.0005,
        ANNUAL: 5263.0,
        JAN: 6041.0,
        FEB: 5757.0,
        MAR: 5856.0,
        APR: 5166.0,
        MAY: 4246.0,
        JUN: 3760.0,
        JUL: 3868.0,
        AUG: 4520.0,
        SEP: 5428.0,
        OCT: 5805.0,
        NOV: 6398.0,
        DEC: 6314.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -9.050461434175613],
              [-36.499045178706282, -8.950461434175613],
              [-36.399045178706281, -8.950461434175613],
              [-36.399045178706281, -9.050461434175613],
              [-36.499045178706282, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '108',
        ID: 37909.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -9.0005,
        ANNUAL: 5206.0,
        JAN: 5974.0,
        FEB: 5727.0,
        MAR: 5800.0,
        APR: 5143.0,
        MAY: 4209.0,
        JUN: 3696.0,
        JUL: 3795.0,
        AUG: 4452.0,
        SEP: 5363.0,
        OCT: 5702.0,
        NOV: 6348.0,
        DEC: 6268.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -9.050461434175613],
              [-36.399045178706281, -8.950461434175613],
              [-36.29904517870628, -8.950461434175613],
              [-36.29904517870628, -9.050461434175613],
              [-36.399045178706281, -9.050461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '109',
        ID: 37910.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -9.0005,
        ANNUAL: 5154.0,
        JAN: 5852.0,
        FEB: 5695.0,
        MAR: 5727.0,
        APR: 5116.0,
        MAY: 4218.0,
        JUN: 3706.0,
        JUL: 3823.0,
        AUG: 4409.0,
        SEP: 5301.0,
        OCT: 5604.0,
        NOV: 6227.0,
        DEC: 6169.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199233453, -9.048846086999955],
              [-36.199603431744215, -9.050461434175613],
              [-36.29904517870628, -9.050461434175613],
              [-36.29904517870628, -8.950461434175613],
              [-36.199045178706278, -8.950461434175613],
              [-36.199045178706278, -9.048284839597308],
              [-36.199233453, -9.048846086999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '110',
        ID: 37911.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -9.0005,
        ANNUAL: 5122.0,
        JAN: 5809.0,
        FEB: 5690.0,
        MAR: 5682.0,
        APR: 5085.0,
        MAY: 4220.0,
        JUN: 3719.0,
        JUL: 3822.0,
        AUG: 4453.0,
        SEP: 5270.0,
        OCT: 5548.0,
        NOV: 6113.0,
        DEC: 6059.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.1132593029999, -8.950695173999971],
              [-36.113262854, -8.950695768999946],
              [-36.122451634, -8.952734340999942],
              [-36.1242602949999, -8.953587774999958],
              [-36.127029342, -8.955900887999974],
              [-36.127142243, -8.955995199999967],
              [-36.127280331999899, -8.956110551999929],
              [-36.128249, -8.957717180999968],
              [-36.128941309, -8.960488601999934],
              [-36.129806164999898, -8.965793261999975],
              [-36.129588632, -8.971327036999973],
              [-36.129570185, -8.971424224999968],
              [-36.129511895, -8.97173133499996],
              [-36.128485564, -8.977138677999966],
              [-36.12681049, -8.981276315999931],
              [-36.124610732999898, -8.98340088499998],
              [-36.116158404, -8.985978955999942],
              [-36.112188079, -8.987788069999961],
              [-36.110555345, -8.989193962999922],
              [-36.106231806, -8.994483346999971],
              [-36.104329164, -8.998926995999973],
              [-36.104328162999899, -8.998929333999973],
              [-36.10652679, -9.001016412999952],
              [-36.109793173, -9.005441417999977],
              [-36.109889154, -9.006133499999988],
              [-36.109071021, -9.007290242999941],
              [-36.109466085, -9.008422088999955],
              [-36.109469184, -9.008421713999944],
              [-36.111153412, -9.008218328999924],
              [-36.113773766, -9.009213782999952],
              [-36.115088127, -9.013400680999949],
              [-36.114236532999897, -9.014587846999975],
              [-36.11159276, -9.016673974999943],
              [-36.111491891999897, -9.017673103999924],
              [-36.111495628999897, -9.017672681999946],
              [-36.111495482, -9.017674134999938],
              [-36.114532912, -9.01988251499994],
              [-36.115088482, -9.022119009999935],
              [-36.117916078999897, -9.023833936999951],
              [-36.120662656, -9.027446595999956],
              [-36.120665624, -9.027451460999943],
              [-36.121210855, -9.027867307999937],
              [-36.123831432, -9.029866018999943],
              [-36.127278573, -9.033669513999939],
              [-36.128901673, -9.034234849999965],
              [-36.133215388999901, -9.035290367999949],
              [-36.133218917, -9.035291027999957],
              [-36.134505014, -9.035427159999935],
              [-36.138392987, -9.035839073999966],
              [-36.138888326, -9.03600982299996],
              [-36.139799384999897, -9.036592172999965],
              [-36.140129649, -9.037045573999928],
              [-36.141039018999898, -9.039684178999945],
              [-36.1428059039999, -9.040323624999925],
              [-36.142809531999902, -9.040323863999962],
              [-36.1467537, -9.040660041999956],
              [-36.15172824, -9.042694415999962],
              [-36.153202585, -9.042061561999963],
              [-36.154783735, -9.039960209999949],
              [-36.155634845999899, -9.039948779999964],
              [-36.155635828999898, -9.039949791999957],
              [-36.155858107, -9.040178627999973],
              [-36.155861522999899, -9.040179479999955],
              [-36.155978854999901, -9.040203729999972],
              [-36.156216806, -9.040252907999964],
              [-36.156220382999898, -9.040253353999955],
              [-36.157664569, -9.042030261999974],
              [-36.158912992, -9.040883088999976],
              [-36.158916319999904, -9.040883657999936],
              [-36.1610975729999, -9.041358351999975],
              [-36.162621478999903, -9.04058877399995],
              [-36.162625099, -9.040588738999928],
              [-36.1641351439999, -9.040489134999971],
              [-36.168854364, -9.041869425999957],
              [-36.173423854, -9.043950779999928],
              [-36.173427472999897, -9.04395107599993],
              [-36.175128658999903, -9.044150760999969],
              [-36.176945750999899, -9.04333227799998],
              [-36.179464669, -9.041016596999942],
              [-36.179466964, -9.041017119999946],
              [-36.180726431, -9.041454533999966],
              [-36.183444243999901, -9.044351257999946],
              [-36.188946008999899, -9.04629530499993],
              [-36.18894826, -9.046294984999969],
              [-36.192285775999899, -9.04582030499995],
              [-36.194825181999903, -9.044463762999946],
              [-36.194827545999907, -9.044464610999972],
              [-36.195724917999897, -9.04478638599994],
              [-36.195727609, -9.044786217999958],
              [-36.198505731999902, -9.04667674399998],
              [-36.199045178706278, -9.048284839597308],
              [-36.199045178706278, -8.950461434175613],
              [-36.111231261543352, -8.950461434175613],
              [-36.1132593029999, -8.950695173999971],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '111',
        ID: 38243.0,
        COUNTRY: 'Brasil',
        LON: -40.949,
        LAT: -8.9005,
        ANNUAL: 5745.0,
        JAN: 6109.0,
        FEB: 5840.0,
        MAR: 5785.0,
        APR: 5196.0,
        MAY: 4821.0,
        JUN: 4665.0,
        JUL: 5024.0,
        AUG: 5824.0,
        SEP: 6530.0,
        OCT: 6492.0,
        NOV: 6468.0,
        DEC: 6184.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.900044043, -8.867613212999968],
              [-40.900775209, -8.867055170999949],
              [-40.90150627, -8.866497155999976],
              [-40.902561399, -8.861072365999968],
              [-40.902663831, -8.860545272999955],
              [-40.903038194, -8.858618872999951],
              [-40.906901902999898, -8.856864024999929],
              [-40.909603177999898, -8.854408762999983],
              [-40.913946342553757, -8.850461434175614],
              [-40.899045178706345, -8.850461434175614],
              [-40.899045178706345, -8.868375541313844],
              [-40.900044043, -8.867613212999968],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '112',
        ID: 38244.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -8.9005,
        ANNUAL: 5758.0,
        JAN: 6185.0,
        FEB: 5929.0,
        MAR: 5840.0,
        APR: 5197.0,
        MAY: 4825.0,
        JUN: 4611.0,
        JUL: 4981.0,
        AUG: 5782.0,
        SEP: 6498.0,
        OCT: 6493.0,
        NOV: 6496.0,
        DEC: 6259.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.855537983, -8.949473735999954],
              [-40.858782866999903, -8.949219125999955],
              [-40.861322569, -8.948017577999952],
              [-40.863317281, -8.942724304999965],
              [-40.865516347, -8.932267102999957],
              [-40.865401815, -8.926817085999964],
              [-40.866278091, -8.923389163999968],
              [-40.868973554, -8.921072568999936],
              [-40.872535684, -8.919065350999965],
              [-40.87831335, -8.917428029999934],
              [-40.881233705999897, -8.915627843999962],
              [-40.882017457, -8.913636785999921],
              [-40.882177113999902, -8.909538307999982],
              [-40.883442325, -8.904856011999923],
              [-40.885392642999904, -8.90240312099996],
              [-40.888881895999901, -8.898979879999962],
              [-40.893009266, -8.891168226999969],
              [-40.895041178, -8.889092771999973],
              [-40.895457704, -8.883887112999956],
              [-40.896096948, -8.882295788999958],
              [-40.896736225, -8.880704382999966],
              [-40.896570220999898, -8.875863307999962],
              [-40.896404326, -8.871025371999952],
              [-40.896851583999897, -8.870049681999935],
              [-40.899045178706345, -8.868375541313844],
              [-40.899045178706345, -8.850461434175614],
              [-40.799045178706344, -8.850461434175614],
              [-40.799045178706344, -8.950461434175613],
              [-40.853947104473086, -8.950461434175613],
              [-40.855537983, -8.949473735999954],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '113',
        ID: 38245.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.9005,
        ANNUAL: 5729.0,
        JAN: 6163.0,
        FEB: 5910.0,
        MAR: 5825.0,
        APR: 5184.0,
        MAY: 4794.0,
        JUN: 4578.0,
        JUL: 4906.0,
        AUG: 5720.0,
        SEP: 6473.0,
        OCT: 6473.0,
        NOV: 6475.0,
        DEC: 6248.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.799045178706344, -8.950461434175613],
              [-40.799045178706344, -8.850461434175614],
              [-40.699045178706342, -8.850461434175614],
              [-40.699045178706342, -8.950461434175613],
              [-40.799045178706344, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '114',
        ID: 38246.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.9005,
        ANNUAL: 5721.0,
        JAN: 6152.0,
        FEB: 5894.0,
        MAR: 5819.0,
        APR: 5197.0,
        MAY: 4782.0,
        JUN: 4565.0,
        JUL: 4869.0,
        AUG: 5701.0,
        SEP: 6475.0,
        OCT: 6466.0,
        NOV: 6497.0,
        DEC: 6243.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -8.950461434175613],
              [-40.699045178706342, -8.850461434175614],
              [-40.599045178706341, -8.850461434175614],
              [-40.599045178706341, -8.950461434175613],
              [-40.699045178706342, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '115',
        ID: 38247.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.9005,
        ANNUAL: 5709.0,
        JAN: 6072.0,
        FEB: 5892.0,
        MAR: 5819.0,
        APR: 5212.0,
        MAY: 4817.0,
        JUN: 4539.0,
        JUL: 4850.0,
        AUG: 5670.0,
        SEP: 6471.0,
        OCT: 6455.0,
        NOV: 6469.0,
        DEC: 6236.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.950461434175613],
              [-40.599045178706341, -8.850461434175614],
              [-40.499045178706339, -8.850461434175614],
              [-40.499045178706339, -8.950461434175613],
              [-40.599045178706341, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '116',
        ID: 38248.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.9005,
        ANNUAL: 5700.0,
        JAN: 6106.0,
        FEB: 5900.0,
        MAR: 5807.0,
        APR: 5235.0,
        MAY: 4796.0,
        JUN: 4534.0,
        JUL: 4830.0,
        AUG: 5665.0,
        SEP: 6423.0,
        OCT: 6444.0,
        NOV: 6458.0,
        DEC: 6199.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.950461434175613],
              [-40.499045178706339, -8.850461434175614],
              [-40.399045178706338, -8.850461434175614],
              [-40.399045178706338, -8.950461434175613],
              [-40.499045178706339, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '117',
        ID: 38249.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.9005,
        ANNUAL: 5687.0,
        JAN: 6129.0,
        FEB: 5907.0,
        MAR: 5821.0,
        APR: 5273.0,
        MAY: 4783.0,
        JUN: 4516.0,
        JUL: 4795.0,
        AUG: 5641.0,
        SEP: 6359.0,
        OCT: 6393.0,
        NOV: 6429.0,
        DEC: 6199.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.950461434175613],
              [-40.399045178706338, -8.850461434175614],
              [-40.299045178706336, -8.850461434175614],
              [-40.299045178706336, -8.950461434175613],
              [-40.399045178706338, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '118',
        ID: 38250.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.9005,
        ANNUAL: 5647.0,
        JAN: 6099.0,
        FEB: 5847.0,
        MAR: 5849.0,
        APR: 5266.0,
        MAY: 4752.0,
        JUN: 4455.0,
        JUL: 4701.0,
        AUG: 5539.0,
        SEP: 6335.0,
        OCT: 6338.0,
        NOV: 6417.0,
        DEC: 6163.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.950461434175613],
              [-40.299045178706336, -8.850461434175614],
              [-40.199045178706335, -8.850461434175614],
              [-40.199045178706335, -8.950461434175613],
              [-40.299045178706336, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '119',
        ID: 38251.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.9005,
        ANNUAL: 5646.0,
        JAN: 6114.0,
        FEB: 5839.0,
        MAR: 5833.0,
        APR: 5246.0,
        MAY: 4708.0,
        JUN: 4443.0,
        JUL: 4686.0,
        AUG: 5542.0,
        SEP: 6362.0,
        OCT: 6362.0,
        NOV: 6427.0,
        DEC: 6193.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.950461434175613],
              [-40.199045178706335, -8.850461434175614],
              [-40.099045178706334, -8.850461434175614],
              [-40.099045178706334, -8.950461434175613],
              [-40.199045178706335, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '120',
        ID: 38252.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.9005,
        ANNUAL: 5628.0,
        JAN: 6143.0,
        FEB: 5830.0,
        MAR: 5830.0,
        APR: 5210.0,
        MAY: 4669.0,
        JUN: 4372.0,
        JUL: 4633.0,
        AUG: 5504.0,
        SEP: 6309.0,
        OCT: 6351.0,
        NOV: 6445.0,
        DEC: 6237.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.950461434175613],
              [-40.099045178706334, -8.850461434175614],
              [-39.999045178706332, -8.850461434175614],
              [-39.999045178706332, -8.950461434175613],
              [-40.099045178706334, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '121',
        ID: 38253.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.9005,
        ANNUAL: 5639.0,
        JAN: 6183.0,
        FEB: 5852.0,
        MAR: 5901.0,
        APR: 5263.0,
        MAY: 4697.0,
        JUN: 4379.0,
        JUL: 4628.0,
        AUG: 5432.0,
        SEP: 6298.0,
        OCT: 6339.0,
        NOV: 6459.0,
        DEC: 6237.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.902736874, -8.850577871999963],
              [-39.902849489999902, -8.851582403999942],
              [-39.903112533999902, -8.853928763999928],
              [-39.902938175, -8.858743592999929],
              [-39.901259274, -8.864158782999946],
              [-39.899045178706331, -8.868260669345618],
              [-39.899045178706331, -8.950461434175613],
              [-39.999045178706332, -8.950461434175613],
              [-39.999045178706332, -8.850461434175614],
              [-39.902707016391822, -8.850461434175614],
              [-39.902736874, -8.850577871999963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '122',
        ID: 38254.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.9005,
        ANNUAL: 5625.0,
        JAN: 6177.0,
        FEB: 5912.0,
        MAR: 5913.0,
        APR: 5259.0,
        MAY: 4690.0,
        JUN: 4345.0,
        JUL: 4614.0,
        AUG: 5359.0,
        SEP: 6217.0,
        OCT: 6306.0,
        NOV: 6455.0,
        DEC: 6259.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.884236312, -8.895695932999967],
              [-39.882322611999903, -8.898163727999936],
              [-39.879418611999903, -8.901908555999968],
              [-39.877927493, -8.90996909699993],
              [-39.877469923, -8.921573260999921],
              [-39.873442214, -8.930207607999931],
              [-39.873456421, -8.934038717999949],
              [-39.8744286209999, -8.936738523999965],
              [-39.879883306999901, -8.946196321999937],
              [-39.881949041796666, -8.950461434175613],
              [-39.899045178706331, -8.950461434175613],
              [-39.899045178706331, -8.868260669345618],
              [-39.884236312, -8.895695932999967],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '123',
        ID: 38265.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.9005,
        ANNUAL: 5796.0,
        JAN: 6491.0,
        FEB: 6305.0,
        MAR: 6291.0,
        APR: 5626.0,
        MAY: 4827.0,
        JUN: 4368.0,
        JUL: 4597.0,
        AUG: 5295.0,
        SEP: 6142.0,
        OCT: 6373.0,
        NOV: 6583.0,
        DEC: 6649.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699104528, -8.879883608999936],
              [-38.699466175999902, -8.878945235999932],
              [-38.702374219, -8.873861926999957],
              [-38.704987119999899, -8.865409498999954],
              [-38.706543498, -8.862260616999949],
              [-38.708715285, -8.860155452999955],
              [-38.711509217, -8.85839460999995],
              [-38.711510253, -8.858393806999965],
              [-38.711511291, -8.858393003999943],
              [-38.711514986999902, -8.858390673999962],
              [-38.717096424, -8.853151762999969],
              [-38.724546940356852, -8.850461434175614],
              [-38.699045178706314, -8.850461434175614],
              [-38.699045178706314, -8.880037667451488],
              [-38.699104528, -8.879883608999936],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '124',
        ID: 38266.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.9005,
        ANNUAL: 5746.0,
        JAN: 6473.0,
        FEB: 6310.0,
        MAR: 6293.0,
        APR: 5639.0,
        MAY: 4825.0,
        JUN: 4380.0,
        JUL: 4586.0,
        AUG: 5239.0,
        SEP: 6028.0,
        OCT: 6248.0,
        NOV: 6397.0,
        DEC: 6535.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.601825947, -8.877634064999931],
              [-38.603824327, -8.880590640999966],
              [-38.606636750999897, -8.889707715999977],
              [-38.60758571400001, -8.893264037999929],
              [-38.607823442, -8.904378659999967],
              [-38.607158075, -8.910351331999953],
              [-38.60701231, -8.917507765999963],
              [-38.608938021, -8.934570917999963],
              [-38.6082880169999, -8.945391476999939],
              [-38.60833582, -8.949822148999937],
              [-38.608444883642278, -8.950461434175613],
              [-38.687408271502896, -8.950461434175613],
              [-38.689232623999899, -8.947395585999967],
              [-38.69014600500001, -8.945277011999963],
              [-38.692875436, -8.936228874999925],
              [-38.693800710999902, -8.931889167999946],
              [-38.6941689249999, -8.924670983999928],
              [-38.693628389, -8.918704380999941],
              [-38.693204814999902, -8.914022875999935],
              [-38.693123466, -8.913123142999952],
              [-38.693658807, -8.902968832999939],
              [-38.695616948999906, -8.89286711299996],
              [-38.69607078, -8.890525878999934],
              [-38.698381018, -8.881761951999975],
              [-38.698381533, -8.881760353999946],
              [-38.699045178706314, -8.880037667451488],
              [-38.699045178706314, -8.850461434175614],
              [-38.599045178706312, -8.850461434175614],
              [-38.599045178706312, -8.872243612353394],
              [-38.601825947, -8.877634064999931],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '125',
        ID: 38267.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.9005,
        ANNUAL: 5780.0,
        JAN: 6511.0,
        FEB: 6304.0,
        MAR: 6284.0,
        APR: 5695.0,
        MAY: 4841.0,
        JUN: 4455.0,
        JUL: 4685.0,
        AUG: 5288.0,
        SEP: 6075.0,
        OCT: 6234.0,
        NOV: 6451.0,
        DEC: 6539.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.519811095, -8.947582783999954],
              [-38.51990725, -8.943532637999965],
              [-38.519907281, -8.943531333999942],
              [-38.519897747, -8.94345647599993],
              [-38.519374683, -8.939349696999948],
              [-38.516798714999901, -8.933235430999956],
              [-38.513353842999898, -8.927872522999968],
              [-38.510960511, -8.925110808999928],
              [-38.505159638, -8.919976463999939],
              [-38.502046661, -8.916620727999943],
              [-38.499045178706311, -8.91540491554067],
              [-38.499045178706311, -8.950461434175613],
              [-38.518874511663142, -8.950461434175613],
              [-38.519811095, -8.947582783999954],
            ],
          ],
          [
            [
              [-38.589950413999901, -8.856539234999964],
              [-38.592486067, -8.861535077999966],
              [-38.597297274, -8.868855340999962],
              [-38.599045178706312, -8.872243612353394],
              [-38.599045178706312, -8.850461434175614],
              [-38.585681014618444, -8.850461434175614],
              [-38.589950413999901, -8.856539234999964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '126',
        ID: 38268.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.9005,
        ANNUAL: 5776.0,
        JAN: 6509.0,
        FEB: 6289.0,
        MAR: 6262.0,
        APR: 5656.0,
        MAY: 4803.0,
        JUN: 4393.0,
        JUL: 4613.0,
        AUG: 5266.0,
        SEP: 6076.0,
        OCT: 6305.0,
        NOV: 6574.0,
        DEC: 6563.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.492126207, -8.912602242999979],
              [-38.4895746239999, -8.911040746999966],
              [-38.484408093, -8.907047027999972],
              [-38.479318185, -8.901743714999954],
              [-38.476623973999899, -8.89480321299993],
              [-38.47329286, -8.889545678999985],
              [-38.469871425, -8.87468894199997],
              [-38.46980712, -8.865742274999956],
              [-38.469798349, -8.864417242999929],
              [-38.469797183, -8.864241142999965],
              [-38.469797516, -8.864239752999943],
              [-38.470792248, -8.861625256999949],
              [-38.473239759, -8.858707018999944],
              [-38.474240063, -8.856909956999973],
              [-38.475240388, -8.855113191999973],
              [-38.479493829804419, -8.850461434175614],
              [-38.399045178706309, -8.850461434175614],
              [-38.399045178706309, -8.950461434175613],
              [-38.499045178706311, -8.950461434175613],
              [-38.499045178706311, -8.91540491554067],
              [-38.492126207, -8.912602242999979],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '127',
        ID: 38269.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.9005,
        ANNUAL: 5787.0,
        JAN: 6496.0,
        FEB: 6257.0,
        MAR: 6201.0,
        APR: 5621.0,
        MAY: 4787.0,
        JUN: 4342.0,
        JUL: 4561.0,
        AUG: 5285.0,
        SEP: 6163.0,
        OCT: 6416.0,
        NOV: 6747.0,
        DEC: 6571.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.950461434175613],
              [-38.399045178706309, -8.850461434175614],
              [-38.299045178706308, -8.850461434175614],
              [-38.299045178706308, -8.950461434175613],
              [-38.399045178706309, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '128',
        ID: 38270.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.9005,
        ANNUAL: 5677.0,
        JAN: 6369.0,
        FEB: 6142.0,
        MAR: 6110.0,
        APR: 5494.0,
        MAY: 4696.0,
        JUN: 4172.0,
        JUL: 4375.0,
        AUG: 5121.0,
        SEP: 6058.0,
        OCT: 6365.0,
        NOV: 6707.0,
        DEC: 6514.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.950461434175613],
              [-38.299045178706308, -8.850461434175614],
              [-38.199045178706307, -8.850461434175614],
              [-38.199045178706307, -8.950461434175613],
              [-38.299045178706308, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '129',
        ID: 38271.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.9005,
        ANNUAL: 5658.0,
        JAN: 6399.0,
        FEB: 6120.0,
        MAR: 6099.0,
        APR: 5466.0,
        MAY: 4644.0,
        JUN: 4145.0,
        JUL: 4366.0,
        AUG: 5095.0,
        SEP: 6050.0,
        OCT: 6338.0,
        NOV: 6699.0,
        DEC: 6469.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.950461434175613],
              [-38.199045178706307, -8.850461434175614],
              [-38.099045178706305, -8.850461434175614],
              [-38.099045178706305, -8.950461434175613],
              [-38.199045178706307, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '130',
        ID: 38272.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.9005,
        ANNUAL: 5649.0,
        JAN: 6404.0,
        FEB: 6120.0,
        MAR: 6121.0,
        APR: 5444.0,
        MAY: 4610.0,
        JUN: 4101.0,
        JUL: 4347.0,
        AUG: 5068.0,
        SEP: 6016.0,
        OCT: 6335.0,
        NOV: 6732.0,
        DEC: 6489.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.950461434175613],
              [-38.099045178706305, -8.850461434175614],
              [-37.999045178706304, -8.850461434175614],
              [-37.999045178706304, -8.950461434175613],
              [-38.099045178706305, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '131',
        ID: 38273.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.9005,
        ANNUAL: 5653.0,
        JAN: 6439.0,
        FEB: 6111.0,
        MAR: 6124.0,
        APR: 5496.0,
        MAY: 4586.0,
        JUN: 4067.0,
        JUL: 4298.0,
        AUG: 5027.0,
        SEP: 5986.0,
        OCT: 6363.0,
        NOV: 6768.0,
        DEC: 6574.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.950461434175613],
              [-37.999045178706304, -8.850461434175614],
              [-37.899045178706302, -8.850461434175614],
              [-37.899045178706302, -8.950461434175613],
              [-37.999045178706304, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '132',
        ID: 38274.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.9005,
        ANNUAL: 5622.0,
        JAN: 6423.0,
        FEB: 6097.0,
        MAR: 6125.0,
        APR: 5488.0,
        MAY: 4549.0,
        JUN: 4042.0,
        JUL: 4245.0,
        AUG: 4968.0,
        SEP: 5946.0,
        OCT: 6323.0,
        NOV: 6689.0,
        DEC: 6565.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.80090201, -8.875695098999925],
              [-37.802205385, -8.875910880999978],
              [-37.803546446, -8.876496962999965],
              [-37.804377065, -8.876932347999972],
              [-37.806286499, -8.877208732999971],
              [-37.808298594999897, -8.877118248999968],
              [-37.80974793, -8.877106581999946],
              [-37.811405997, -8.87811580199997],
              [-37.813415221999897, -8.880056913999976],
              [-37.814050062, -8.880857038999975],
              [-37.816765556, -8.882584022999939],
              [-37.818995327, -8.883145],
              [-37.821824433, -8.884043469999932],
              [-37.8233045429999, -8.884678976999952],
              [-37.824628783, -8.885957269999953],
              [-37.825640161, -8.888567338999966],
              [-37.8253819769999, -8.889855163999936],
              [-37.824832173999901, -8.891319725999947],
              [-37.824066276999901, -8.893979738999972],
              [-37.823467478999902, -8.895535514999953],
              [-37.823174825, -8.896036509999925],
              [-37.822960291, -8.896761523999936],
              [-37.822434972999901, -8.896877614999935],
              [-37.822338375999898, -8.896877626999974],
              [-37.8223151239999, -8.896924130999926],
              [-37.822290106, -8.896974166999943],
              [-37.822290106, -8.897070706999953],
              [-37.822290106, -8.897215516999941],
              [-37.822048756, -8.897360326999939],
              [-37.822000486, -8.897505136999941],
              [-37.821903946, -8.897649946999932],
              [-37.821807405999898, -8.897649946999923],
              [-37.821807405999898, -8.898036106999934],
              [-37.821710865999897, -8.898084376999973],
              [-37.821662595, -8.898132646999953],
              [-37.821566054999899, -8.89822918699997],
              [-37.821517785, -8.898229186999966],
              [-37.821324704999903, -8.898277456999946],
              [-37.821228165, -8.898373996999966],
              [-37.821208997, -8.898450672999958],
              [-37.821179894999901, -8.898567076999939],
              [-37.821083355, -8.898711886999923],
              [-37.821083355, -8.898808427999944],
              [-37.820986815, -8.898953237999939],
              [-37.820890275, -8.899098047999924],
              [-37.820890275, -8.899194587999935],
              [-37.820745465, -8.899242857999978],
              [-37.820745465, -8.899339397999944],
              [-37.820745465, -8.899532477999959],
              [-37.820504114999899, -8.899677287999966],
              [-37.820504114999899, -8.899918637999971],
              [-37.820359305, -8.900208257999953],
              [-37.820359305, -8.900497877999943],
              [-37.820359305, -8.900690957999922],
              [-37.820359305, -8.901028847999942],
              [-37.820407574999898, -8.901318467999937],
              [-37.820407574999898, -8.901463277999934],
              [-37.820359305, -8.901463277999946],
              [-37.820359305, -8.901656357999977],
              [-37.820455845, -8.901801167999965],
              [-37.820552385, -8.90194597799996],
              [-37.820600655, -8.902090787999942],
              [-37.820697195, -8.902187327999972],
              [-37.820697195, -8.902235597999946],
              [-37.820697195, -8.902283867999923],
              [-37.820853570999901, -8.902508458999925],
              [-37.820938544999898, -8.902621757999951],
              [-37.820938544999898, -8.902863107999956],
              [-37.820938544999898, -8.903007917999954],
              [-37.820938544999898, -8.903152727999949],
              [-37.820890275, -8.903490617999974],
              [-37.820793734999903, -8.903780237999969],
              [-37.820793734999903, -8.904021587999985],
              [-37.820793734999903, -8.904359477999954],
              [-37.820793734999903, -8.904552557999976],
              [-37.820648924999901, -8.904745637999957],
              [-37.820600655, -8.90498698899995],
              [-37.820600655, -8.905373148999956],
              [-37.820552385, -8.905517958999948],
              [-37.820552385, -8.905614498999974],
              [-37.820648924999901, -8.90585584899998],
              [-37.820648924999901, -8.906145468999974],
              [-37.820648924999901, -8.906483358999942],
              [-37.820648924999901, -8.906676438999977],
              [-37.820552385, -8.906821248999952],
              [-37.820552385, -8.907014328999944],
              [-37.820455845, -8.90720740899997],
              [-37.820359305, -8.907497028999959],
              [-37.820359305, -8.907738378999962],
              [-37.820359305, -8.907979728999974],
              [-37.820359305, -8.908269348999971],
              [-37.820359305, -8.908462428999949],
              [-37.820359305, -8.908703778999955],
              [-37.820455845, -8.908896858999974],
              [-37.820600655, -8.908993398999948],
              [-37.820697195, -8.909041668999981],
              [-37.820793734999903, -8.909089938999953],
              [-37.820890275, -8.909331288999978],
              [-37.820986815, -8.909524368999932],
              [-37.821131625, -8.909813988999931],
              [-37.821324704999903, -8.910151878999949],
              [-37.821759136, -8.910538038999961],
              [-37.821903946, -8.910682848999954],
              [-37.822048756, -8.910779388999968],
              [-37.822290106, -8.910972468999939],
              [-37.822358182, -8.911074583999946],
              [-37.822386646, -8.911117278999933],
              [-37.822483185999907, -8.911262088999937],
              [-37.822579726, -8.911406898999935],
              [-37.822772805999897, -8.911648249999935],
              [-37.822869345999898, -8.91174478999994],
              [-37.822917616, -8.911841329999962],
              [-37.823207236, -8.912082679999971],
              [-37.823255506, -8.912179219999924],
              [-37.823303775999896, -8.912275759999943],
              [-37.823379307, -8.912401644999926],
              [-37.823448586, -8.91251710999995],
              [-37.823641666, -8.912565379999922],
              [-37.823738206, -8.912758459999965],
              [-37.823883016, -8.912951539999939],
              [-37.823979556, -8.913096349999922],
              [-37.824076095999899, -8.913337699999941],
              [-37.824124366, -8.913434239999956],
              [-37.824317446, -8.913627319999931],
              [-37.824462255999897, -8.913675589999974],
              [-37.824592353, -8.913805686999982],
              [-37.824607065999899, -8.913820399999967],
              [-37.824751875999901, -8.913916939999972],
              [-37.824751875999901, -8.913965209999965],
              [-37.824944956, -8.914061749999968],
              [-37.825138035999906, -8.914206559999979],
              [-37.825379386, -8.914447909999977],
              [-37.825572466, -8.914592719999971],
              [-37.825813815999901, -8.914785799999954],
              [-37.825958625999903, -8.914882339999954],
              [-37.826103436, -8.91502714999995],
              [-37.826103436, -8.915171959999945],
              [-37.826151706, -8.915268499999977],
              [-37.8263447859999, -8.915413309999964],
              [-37.826441326, -8.915509849999978],
              [-37.826586135999896, -8.915558119999965],
              [-37.826779216, -8.915606389999947],
              [-37.826803351, -8.915678794999955],
              [-37.826827486, -8.915751199999928],
              [-37.826819688999898, -8.915931926999974],
              [-37.826797369, -8.916027641999962],
              [-37.826864328999903, -8.916150400999983],
              [-37.826897808999902, -8.916250839999982],
              [-37.826931289, -8.916351279999958],
              [-37.827020568, -8.916418239999937],
              [-37.827098688, -8.916485198999956],
              [-37.827154488, -8.916574478999964],
              [-37.827187966999901, -8.916708397999976],
              [-37.827210287, -8.916842316999981],
              [-37.827243767, -8.91695391699994],
              [-37.827247704999898, -8.917106533999977],
              [-37.827279748, -8.917137922999927],
              [-37.827439636, -8.917294543999954],
              [-37.827419295, -8.917482674999976],
              [-37.827906613, -8.917509261999946],
              [-37.829488547, -8.919052773999972],
              [-37.830102347, -8.921232132999933],
              [-37.829335097999902, -8.922393294999946],
              [-37.832321051, -8.924558103999969],
              [-37.833637043, -8.926411767999982],
              [-37.832143883999898, -8.928255660999987],
              [-37.826233659, -8.932716848999968],
              [-37.825333841, -8.932428260999925],
              [-37.825998289, -8.934237176999934],
              [-37.826001057, -8.934244715999965],
              [-37.827313741, -8.937819635999965],
              [-37.83082613758328, -8.950461434175613],
              [-37.899045178706302, -8.950461434175613],
              [-37.899045178706302, -8.850461434175614],
              [-37.799045178706301, -8.850461434175614],
              [-37.799045178706301, -8.875995590886323],
              [-37.80090201, -8.875695098999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '133',
        ID: 38275.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.9005,
        ANNUAL: 5609.0,
        JAN: 6407.0,
        FEB: 6051.0,
        MAR: 6126.0,
        APR: 5467.0,
        MAY: 4521.0,
        JUN: 4055.0,
        JUL: 4232.0,
        AUG: 4954.0,
        SEP: 5960.0,
        OCT: 6327.0,
        NOV: 6718.0,
        DEC: 6493.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699159186, -8.941100858999958],
              [-37.6995073269999, -8.940966958999972],
              [-37.6998166609999, -8.940616631999934],
              [-37.699895639, -8.940284066999936],
              [-37.699668007999897, -8.939788634999966],
              [-37.6994939369999, -8.93915930299994],
              [-37.699045178706299, -8.938507534565137],
              [-37.699045178706299, -8.941085312861944],
              [-37.699159186, -8.941100858999958],
            ],
          ],
          [
            [
              [-37.699172576, -8.936695535999947],
              [-37.699259809999901, -8.936171624999925],
              [-37.699079013, -8.935914702999987],
              [-37.699045178706299, -8.935866622378022],
              [-37.699045178706299, -8.936842236158512],
              [-37.699172576, -8.936695535999947],
            ],
          ],
          [
            [
              [-37.699128698, -8.934837788999973],
              [-37.699149668, -8.934115646999933],
              [-37.699210858, -8.933626128999954],
              [-37.699529045, -8.933430321999939],
              [-37.699751160999902, -8.933281665999976],
              [-37.699786042, -8.932842898999981],
              [-37.6997615659999, -8.931863861999942],
              [-37.699932897999901, -8.930958252999975],
              [-37.700104229, -8.930737969999941],
              [-37.700373463999902, -8.930713493999971],
              [-37.700948648999898, -8.930627827999968],
              [-37.701217884, -8.93039530699996],
              [-37.701205645999899, -8.929918025999948],
              [-37.700960887, -8.929391793999978],
              [-37.700985363, -8.928975702999939],
              [-37.701279074, -8.928571850999964],
              [-37.701768592, -8.928302614999952],
              [-37.702050065, -8.927788620999927],
              [-37.702030448999899, -8.927715061999944],
              [-37.701952161999898, -8.927421481999945],
              [-37.702147969, -8.927078818999963],
              [-37.702588536, -8.926491396999941],
              [-37.702710915, -8.925842784999922],
              [-37.70298015, -8.925132982999967],
              [-37.703278353, -8.924685678999943],
              [-37.703396241, -8.924508846999974],
              [-37.703689951999898, -8.924092756999929],
              [-37.703775618, -8.923627713999959],
              [-37.703677714, -8.923040291999945],
              [-37.703714428, -8.922306013999957],
              [-37.703922473, -8.921926636999956],
              [-37.704316583, -8.921825583999984],
              [-37.704399754, -8.921804257999959],
              [-37.704705702999902, -8.921816495999963],
              [-37.7048770339999, -8.921620687999955],
              [-37.704955213999902, -8.921288283999962],
              [-37.705195220999897, -8.92077626899993],
              [-37.705611312, -8.920396891999927],
              [-37.705856071, -8.919993038999959],
              [-37.70616202, -8.919748279999963],
              [-37.706516920999903, -8.919711565999936],
              [-37.707006439999901, -8.919479044999949],
              [-37.707068712, -8.919406940999986],
              [-37.707238961, -8.919209809999924],
              [-37.707557148, -8.918818194999963],
              [-37.707936525, -8.918671338999959],
              [-37.708217998, -8.918255248999969],
              [-37.708169046, -8.917814681999962],
              [-37.708083379999898, -8.917276211999919],
              [-37.708413805, -8.916798930999962],
              [-37.708854372, -8.916419553999956],
              [-37.709239442, -8.91596163299994],
              [-37.709307177, -8.915881083999983],
              [-37.709784457, -8.915428278999977],
              [-37.7101393579999, -8.915220233999944],
              [-37.710494259, -8.915501706999985],
              [-37.710947064, -8.915587372999942],
              [-37.711485534, -8.915207995999948],
              [-37.712087234, -8.914670424999933],
              [-37.712378905, -8.914008675999975],
              [-37.712954088999901, -8.913751678999967],
              [-37.713857187, -8.912823721999926],
              [-37.713891428999901, -8.912788536999924],
              [-37.714917688, -8.912169242999944],
              [-37.715034024, -8.912099039999925],
              [-37.716176619, -8.911320893999971],
              [-37.716747917, -8.911094343999928],
              [-37.716984316, -8.910848095999945],
              [-37.716915366, -8.910670795999986],
              [-37.716728217, -8.910493496999944],
              [-37.716393318, -8.910188147999968],
              [-37.716147068999902, -8.909971448999951],
              [-37.716156919, -8.909744899999959],
              [-37.716344068999902, -8.909666100999978],
              [-37.716678967, -8.90961685099994],
              [-37.717230565, -8.909784299999961],
              [-37.717604863, -8.909754749999932],
              [-37.717979161, -8.909449401999945],
              [-37.718511059, -8.908917503999934],
              [-37.718954306999898, -8.908375755999927],
              [-37.719387705, -8.908099956999923],
              [-37.719525604999902, -8.907735508999954],
              [-37.719555155, -8.907513884999965],
              [-37.719584704999903, -8.907292260999954],
              [-37.719732453999903, -8.906632313999976],
              [-37.720087051999897, -8.905913266999958],
              [-37.720168504999897, -8.905469801999978],
              [-37.720175701999899, -8.905430618999965],
              [-37.720215724, -8.905284382999982],
              [-37.720431801, -8.904494872999978],
              [-37.720632866, -8.903441676999929],
              [-37.72063865, -8.903411377999932],
              [-37.720894479, -8.902200621999947],
              [-37.721293611, -8.900311660999938],
              [-37.721633496, -8.898703097999931],
              [-37.721505265, -8.898089421999934],
              [-37.721495596, -8.898043150999966],
              [-37.721387246999903, -8.897363503999935],
              [-37.721377397, -8.897068004999937],
              [-37.721525146, -8.896959655999986],
              [-37.721860045, -8.896979355999964],
              [-37.722451041999904, -8.897038454999963],
              [-37.7225264799999, -8.897033854999954],
              [-37.723258739, -8.896989205999944],
              [-37.723719387, -8.896860223999964],
              [-37.723751235999899, -8.896851305999965],
              [-37.724214183999898, -8.896644456999985],
              [-37.724716531999903, -8.896339107999943],
              [-37.725149929999901, -8.896043609999944],
              [-37.725662128, -8.895531411999963],
              [-37.726065976, -8.895078313999925],
              [-37.726243275999899, -8.894654765999967],
              [-37.726538773999899, -8.894260766999929],
              [-37.726578173999897, -8.893758418999946],
              [-37.726676674, -8.89316742199998],
              [-37.726893372999903, -8.892586274999925],
              [-37.727218421, -8.892152875999944],
              [-37.727257821, -8.891758877999971],
              [-37.72745781, -8.891528637999945],
              [-37.727720769, -8.891039830999945],
              [-37.728075368, -8.89104968099997],
              [-37.728361016999898, -8.891236829999974],
              [-37.728883063999902, -8.891315629999943],
              [-37.729217962999897, -8.891187580999938],
              [-37.729720310999902, -8.891138330999983],
              [-37.730124159, -8.890990580999929],
              [-37.730537856999902, -8.891029980999971],
              [-37.730774256, -8.890882231999974],
              [-37.730833355999899, -8.890596582999931],
              [-37.730665906999903, -8.890389733999935],
              [-37.730764405999899, -8.890054835999926],
              [-37.730951554999898, -8.889729786999967],
              [-37.731158404, -8.889020589999969],
              [-37.731178104, -8.888321242999943],
              [-37.73108945500001, -8.887966644999951],
              [-37.730803806, -8.887769644999924],
              [-37.730232508, -8.887355946999957],
              [-37.729740010999897, -8.887149097999956],
              [-37.7293164619999, -8.886774799999955],
              [-37.728971714, -8.886390650999944],
              [-37.729030813999898, -8.885750403999955],
              [-37.729149013, -8.885021506999973],
              [-37.729375562, -8.884361559999954],
              [-37.729907459999907, -8.883484913999945],
              [-37.730202958999897, -8.882716616999973],
              [-37.730665906999903, -8.882184719999941],
              [-37.730920814999898, -8.881627674999955],
              [-37.731453902999903, -8.88057917699996],
              [-37.732005500999897, -8.879663129999939],
              [-37.7322222, -8.87949568099998],
              [-37.732429049, -8.879564630999937],
              [-37.732478299, -8.879810879999983],
              [-37.732310848999902, -8.88006697899992],
              [-37.732369949, -8.880401876999935],
              [-37.732606347999898, -8.880687525999965],
              [-37.733108695999903, -8.880904224999949],
              [-37.734182341, -8.880923924999932],
              [-37.735206736999899, -8.880776175999932],
              [-37.735709084999897, -8.880510226999954],
              [-37.736122783, -8.880145777999937],
              [-37.736250831999897, -8.879761629999953],
              [-37.736536481, -8.87964343099998],
              [-37.737048678999898, -8.879712379999946],
              [-37.737767726, -8.879860129999942],
              [-37.738122324, -8.879850279999966],
              [-37.738299624, -8.879584330999926],
              [-37.738319323, -8.879229731999937],
              [-37.738496622999897, -8.878559934999942],
              [-37.738900471, -8.878284135999925],
              [-37.739255069, -8.877998487999955],
              [-37.739452069, -8.877515839999944],
              [-37.739738831999901, -8.876925514999936],
              [-37.739946497999902, -8.876581258999977],
              [-37.740190814999899, -8.876176245999938],
              [-37.740476464, -8.876038345999977],
              [-37.740949262, -8.876156545999946],
              [-37.74143191, -8.876235344999936],
              [-37.741668309, -8.876077745999961],
              [-37.741697858999899, -8.875742846999971],
              [-37.741731575, -8.87541052699992],
              [-37.742062307, -8.874876050999934],
              [-37.7422199069999, -8.874472202999929],
              [-37.742190357, -8.874176704],
              [-37.742121407, -8.873733455999975],
              [-37.742220919, -8.873280972999963],
              [-37.742436606, -8.872925759999925],
              [-37.742584355, -8.872236262999929],
              [-37.742899554, -8.871389165999968],
              [-37.743007903, -8.870945917999968],
              [-37.742998053, -8.870384470999973],
              [-37.742919254, -8.86960632399996],
              [-37.742850303999901, -8.869153225999973],
              [-37.742554804999898, -8.868818326999936],
              [-37.742357806, -8.868414478999959],
              [-37.7423676559999, -8.867981080999932],
              [-37.742578276, -8.867853889999944],
              [-37.742909403999903, -8.867892430999969],
              [-37.743323102, -8.868050030999946],
              [-37.743667907, -8.867914424999981],
              [-37.744313614, -8.867288895999927],
              [-37.744495218999901, -8.866683545999935],
              [-37.744333792, -8.865654450999948],
              [-37.744010938, -8.864847316999938],
              [-37.744333792, -8.863656794999939],
              [-37.7453427089999, -8.862365380999961],
              [-37.745757249999897, -8.860398484999962],
              [-37.746492874, -8.859076310999964],
              [-37.748329102999897, -8.858854349999945],
              [-37.750225868, -8.858370069999978],
              [-37.750124975999903, -8.856957585999965],
              [-37.751113715, -8.85594866899994],
              [-37.752223522999898, -8.853164056999955],
              [-37.75240647, -8.852823397999931],
              [-37.752808694999899, -8.852074426999934],
              [-37.754362426999897, -8.850641764999979],
              [-37.754394485861674, -8.850461434175614],
              [-37.699045178706299, -8.850461434175614],
              [-37.699045178706299, -8.935341358622326],
              [-37.699128698, -8.934837788999973],
            ],
          ],
          [
            [
              [-37.7637051909999, -8.850716810999934],
              [-37.764150789, -8.851604865999946],
              [-37.764591862, -8.852677516999954],
              [-37.765263075999897, -8.853894202999976],
              [-37.765903414, -8.854463660999947],
              [-37.767548346, -8.854087453999968],
              [-37.769530384999896, -8.853303722999955],
              [-37.77113084, -8.852834085999962],
              [-37.77262655, -8.85282369899994],
              [-37.773649551999902, -8.853032875999936],
              [-37.774472165, -8.853791373999966],
              [-37.775251464999897, -8.85441030399994],
              [-37.776591623999899, -8.85624947499997],
              [-37.778289333, -8.85753691999996],
              [-37.778737213999896, -8.858332662999947],
              [-37.779137254, -8.859173433999956],
              [-37.780203621999902, -8.859522181999978],
              [-37.780297036, -8.859524414999951],
              [-37.781651543, -8.859556804999951],
              [-37.783610203, -8.859557261999948],
              [-37.784828043999902, -8.859447854999921],
              [-37.785521920999898, -8.859741515999938],
              [-37.786198872999897, -8.860727436999973],
              [-37.786636622999907, -8.861938507999984],
              [-37.786726656, -8.86207919199995],
              [-37.786925217, -8.863515420999938],
              [-37.786532356999899, -8.864291042999984],
              [-37.786134981, -8.865251263999943],
              [-37.786439367, -8.866182068999928],
              [-37.787460192, -8.866483508999988],
              [-37.788950348999897, -8.866703797999952],
              [-37.790016738, -8.867052491999976],
              [-37.791347567999907, -8.868053961999975],
              [-37.791850073, -8.868527717999937],
              [-37.792297966, -8.869323403999946],
              [-37.79203169399991, -8.870656168999972],
              [-37.792141344, -8.871905556999932],
              [-37.792933569, -8.873910077999945],
              [-37.794702863999902, -8.874993098999942],
              [-37.796601186, -8.875731054999966],
              [-37.798415067999898, -8.876097561999932],
              [-37.799045178706301, -8.875995590886323],
              [-37.799045178706301, -8.850461434175614],
              [-37.763151376351935, -8.850461434175614],
              [-37.7637051909999, -8.850716810999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '134',
        ID: 38276.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.9005,
        ANNUAL: 5630.0,
        JAN: 6410.0,
        FEB: 6142.0,
        MAR: 6104.0,
        APR: 5501.0,
        MAY: 4575.0,
        JUN: 4069.0,
        JUL: 4282.0,
        AUG: 5018.0,
        SEP: 5932.0,
        OCT: 6323.0,
        NOV: 6707.0,
        DEC: 6497.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.689427157999901, -8.950334549999985],
              [-37.689517846999898, -8.949765686999969],
              [-37.689719592, -8.949492570999951],
              [-37.690047993, -8.949183486999965],
              [-37.6904923009999, -8.948797131999982],
              [-37.691632048, -8.948275552999977],
              [-37.692462711, -8.948178964999954],
              [-37.693100195999897, -8.947947151999939],
              [-37.693537718, -8.947409363999952],
              [-37.693563821999902, -8.947377277999976],
              [-37.693814953, -8.947077852999932],
              [-37.694336532, -8.946556273999931],
              [-37.694800158, -8.946401731999963],
              [-37.695341054, -8.946479002999988],
              [-37.695727409, -8.946556273999926],
              [-37.696036493, -8.946440367999973],
              [-37.696055811, -8.94618923699994],
              [-37.695823998, -8.945918788999956],
              [-37.695881950999897, -8.94566765799993],
              [-37.696210353, -8.945609704999976],
              [-37.6965194369999, -8.945629022999976],
              [-37.696712614, -8.945474480999971],
              [-37.696766606, -8.945231517999956],
              [-37.696789884999902, -8.945126760999935],
              [-37.697060333, -8.944894948999945],
              [-37.697581912, -8.944798359999934],
              [-37.698238715999899, -8.944798359999929],
              [-37.698605753, -8.944875630999954],
              [-37.698841667, -8.944722221999942],
              [-37.698730704999903, -8.944368028999964],
              [-37.698476293999903, -8.944100227999961],
              [-37.698529854, -8.94337716599995],
              [-37.698717315, -8.943122754999919],
              [-37.698797655, -8.942721053999943],
              [-37.698735182, -8.942184021999946],
              [-37.698731967999898, -8.942156398999931],
              [-37.698663755, -8.941315099999942],
              [-37.698864605, -8.941060688999926],
              [-37.698892702, -8.941064520999921],
              [-37.699045178706299, -8.941085312861944],
              [-37.699045178706299, -8.938507534565137],
              [-37.698931555999899, -8.938342510999968],
              [-37.6986369749999, -8.937766738999935],
              [-37.698730704999903, -8.937204357999976],
              [-37.699045178706299, -8.936842236158512],
              [-37.699045178706299, -8.935866622378022],
              [-37.699027289, -8.93584119999997],
              [-37.699015050999897, -8.935523012999976],
              [-37.699044254999897, -8.935346927999932],
              [-37.699045178706299, -8.935341358622326],
              [-37.699045178706299, -8.850461434175614],
              [-37.599045178706298, -8.850461434175614],
              [-37.599045178706298, -8.950461434175613],
              [-37.689347855350043, -8.950461434175613],
              [-37.689427157999901, -8.950334549999985],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '135',
        ID: 38277.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.9005,
        ANNUAL: 5626.0,
        JAN: 6391.0,
        FEB: 6135.0,
        MAR: 6128.0,
        APR: 5507.0,
        MAY: 4553.0,
        JUN: 4085.0,
        JUL: 4271.0,
        AUG: 5002.0,
        SEP: 5944.0,
        OCT: 6322.0,
        NOV: 6697.0,
        DEC: 6480.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.950461434175613],
              [-37.599045178706298, -8.850461434175614],
              [-37.499045178706297, -8.850461434175614],
              [-37.499045178706297, -8.950461434175613],
              [-37.599045178706298, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '136',
        ID: 38278.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.9005,
        ANNUAL: 5628.0,
        JAN: 6387.0,
        FEB: 6147.0,
        MAR: 6179.0,
        APR: 5506.0,
        MAY: 4526.0,
        JUN: 4049.0,
        JUL: 4231.0,
        AUG: 4984.0,
        SEP: 5961.0,
        OCT: 6336.0,
        NOV: 6730.0,
        DEC: 6501.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.950461434175613],
              [-37.499045178706297, -8.850461434175614],
              [-37.399045178706295, -8.850461434175614],
              [-37.399045178706295, -8.950461434175613],
              [-37.499045178706297, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '137',
        ID: 38279.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.9005,
        ANNUAL: 5559.0,
        JAN: 6285.0,
        FEB: 6100.0,
        MAR: 6142.0,
        APR: 5457.0,
        MAY: 4437.0,
        JUN: 3926.0,
        JUL: 4134.0,
        AUG: 4860.0,
        SEP: 5838.0,
        OCT: 6288.0,
        NOV: 6746.0,
        DEC: 6494.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.950461434175613],
              [-37.399045178706295, -8.850461434175614],
              [-37.299045178706294, -8.850461434175614],
              [-37.299045178706294, -8.950461434175613],
              [-37.399045178706295, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '138',
        ID: 38280.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.9005,
        ANNUAL: 5544.0,
        JAN: 6291.0,
        FEB: 6083.0,
        MAR: 6111.0,
        APR: 5459.0,
        MAY: 4415.0,
        JUN: 3938.0,
        JUL: 4144.0,
        AUG: 4855.0,
        SEP: 5770.0,
        OCT: 6232.0,
        NOV: 6717.0,
        DEC: 6507.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.950461434175613],
              [-37.299045178706294, -8.850461434175614],
              [-37.199045178706292, -8.850461434175614],
              [-37.199045178706292, -8.950461434175613],
              [-37.299045178706294, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '139',
        ID: 38281.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.9005,
        ANNUAL: 5524.0,
        JAN: 6278.0,
        FEB: 6032.0,
        MAR: 6071.0,
        APR: 5427.0,
        MAY: 4416.0,
        JUN: 3977.0,
        JUL: 4139.0,
        AUG: 4831.0,
        SEP: 5752.0,
        OCT: 6194.0,
        NOV: 6668.0,
        DEC: 6504.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -8.950461434175613],
              [-37.199045178706292, -8.850461434175614],
              [-37.099045178706291, -8.850461434175614],
              [-37.099045178706291, -8.950461434175613],
              [-37.199045178706292, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '140',
        ID: 38282.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.9005,
        ANNUAL: 5535.0,
        JAN: 6262.0,
        FEB: 6049.0,
        MAR: 6047.0,
        APR: 5431.0,
        MAY: 4456.0,
        JUN: 4033.0,
        JUL: 4204.0,
        AUG: 4943.0,
        SEP: 5770.0,
        OCT: 6187.0,
        NOV: 6632.0,
        DEC: 6403.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -8.950461434175613],
              [-37.099045178706291, -8.850461434175614],
              [-36.99904517870629, -8.850461434175614],
              [-36.99904517870629, -8.950461434175613],
              [-37.099045178706291, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '141',
        ID: 38283.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.9005,
        ANNUAL: 5523.0,
        JAN: 6213.0,
        FEB: 6019.0,
        MAR: 6028.0,
        APR: 5422.0,
        MAY: 4462.0,
        JUN: 4037.0,
        JUL: 4225.0,
        AUG: 4921.0,
        SEP: 5764.0,
        OCT: 6178.0,
        NOV: 6602.0,
        DEC: 6400.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -8.950461434175613],
              [-36.99904517870629, -8.850461434175614],
              [-36.899045178706288, -8.850461434175614],
              [-36.899045178706288, -8.950461434175613],
              [-36.99904517870629, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '142',
        ID: 38284.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.9005,
        ANNUAL: 5462.0,
        JAN: 6133.0,
        FEB: 5932.0,
        MAR: 5909.0,
        APR: 5347.0,
        MAY: 4409.0,
        JUN: 3979.0,
        JUL: 4156.0,
        AUG: 4823.0,
        SEP: 5749.0,
        OCT: 6156.0,
        NOV: 6594.0,
        DEC: 6363.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -8.950461434175613],
              [-36.899045178706288, -8.850461434175614],
              [-36.799045178706287, -8.850461434175614],
              [-36.799045178706287, -8.950461434175613],
              [-36.899045178706288, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '143',
        ID: 38285.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.9005,
        ANNUAL: 5509.0,
        JAN: 6190.0,
        FEB: 5922.0,
        MAR: 5972.0,
        APR: 5327.0,
        MAY: 4419.0,
        JUN: 3992.0,
        JUL: 4185.0,
        AUG: 4878.0,
        SEP: 5877.0,
        OCT: 6231.0,
        NOV: 6644.0,
        DEC: 6473.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -8.950461434175613],
              [-36.799045178706287, -8.850461434175614],
              [-36.699045178706285, -8.850461434175614],
              [-36.699045178706285, -8.950461434175613],
              [-36.799045178706287, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '144',
        ID: 38286.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.9005,
        ANNUAL: 5412.0,
        JAN: 6149.0,
        FEB: 5887.0,
        MAR: 5938.0,
        APR: 5259.0,
        MAY: 4344.0,
        JUN: 3904.0,
        JUL: 4068.0,
        AUG: 4762.0,
        SEP: 5699.0,
        OCT: 6042.0,
        NOV: 6496.0,
        DEC: 6390.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -8.950461434175613],
              [-36.699045178706285, -8.850461434175614],
              [-36.599045178706284, -8.850461434175614],
              [-36.599045178706284, -8.950461434175613],
              [-36.699045178706285, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '145',
        ID: 38287.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.9005,
        ANNUAL: 5313.0,
        JAN: 6085.0,
        FEB: 5795.0,
        MAR: 5859.0,
        APR: 5208.0,
        MAY: 4288.0,
        JUN: 3801.0,
        JUL: 3944.0,
        AUG: 4662.0,
        SEP: 5557.0,
        OCT: 5872.0,
        NOV: 6409.0,
        DEC: 6277.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.950461434175613],
              [-36.599045178706284, -8.850461434175614],
              [-36.499045178706282, -8.850461434175614],
              [-36.499045178706282, -8.950461434175613],
              [-36.599045178706284, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '146',
        ID: 38288.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.9005,
        ANNUAL: 5252.0,
        JAN: 6000.0,
        FEB: 5706.0,
        MAR: 5817.0,
        APR: 5130.0,
        MAY: 4272.0,
        JUN: 3812.0,
        JUL: 3942.0,
        AUG: 4587.0,
        SEP: 5448.0,
        OCT: 5763.0,
        NOV: 6319.0,
        DEC: 6226.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.950461434175613],
              [-36.499045178706282, -8.850461434175614],
              [-36.399045178706281, -8.850461434175614],
              [-36.399045178706281, -8.950461434175613],
              [-36.499045178706282, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '147',
        ID: 38289.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.9005,
        ANNUAL: 5219.0,
        JAN: 5941.0,
        FEB: 5679.0,
        MAR: 5788.0,
        APR: 5109.0,
        MAY: 4281.0,
        JUN: 3809.0,
        JUL: 3922.0,
        AUG: 4555.0,
        SEP: 5400.0,
        OCT: 5704.0,
        NOV: 6262.0,
        DEC: 6177.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.950461434175613],
              [-36.399045178706281, -8.850461434175614],
              [-36.29904517870628, -8.850461434175614],
              [-36.29904517870628, -8.950461434175613],
              [-36.399045178706281, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '148',
        ID: 38290.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.9005,
        ANNUAL: 5198.0,
        JAN: 5827.0,
        FEB: 5699.0,
        MAR: 5757.0,
        APR: 5127.0,
        MAY: 4264.0,
        JUN: 3810.0,
        JUL: 3928.0,
        AUG: 4546.0,
        SEP: 5384.0,
        OCT: 5673.0,
        NOV: 6208.0,
        DEC: 6150.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.950461434175613],
              [-36.29904517870628, -8.850461434175614],
              [-36.199045178706278, -8.850461434175614],
              [-36.199045178706278, -8.950461434175613],
              [-36.29904517870628, -8.950461434175613],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '149',
        ID: 38291.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.9005,
        ANNUAL: 5155.0,
        JAN: 5808.0,
        FEB: 5679.0,
        MAR: 5688.0,
        APR: 5088.0,
        MAY: 4237.0,
        JUN: 3778.0,
        JUL: 3851.0,
        AUG: 4508.0,
        SEP: 5341.0,
        OCT: 5645.0,
        NOV: 6148.0,
        DEC: 6088.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.1009495489999, -8.949276552999946],
              [-36.100953166999901, -8.949276842999941],
              [-36.111231261543352, -8.950461434175613],
              [-36.199045178706278, -8.950461434175613],
              [-36.199045178706278, -8.850461434175614],
              [-36.099045178706277, -8.850461434175614],
              [-36.099045178706277, -8.94871123767477],
              [-36.1009495489999, -8.949276552999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '150',
        ID: 38292.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.9005,
        ANNUAL: 5076.0,
        JAN: 5711.0,
        FEB: 5627.0,
        MAR: 5624.0,
        APR: 4997.0,
        MAY: 4152.0,
        JUN: 3718.0,
        JUL: 3762.0,
        AUG: 4406.0,
        SEP: 5234.0,
        OCT: 5588.0,
        NOV: 6059.0,
        DEC: 6037.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999105297, -8.907729629999944],
              [-36.000407539, -8.91006044999995],
              [-36.005383915, -8.913000354999944],
              [-36.005385594, -8.913000254999938],
              [-36.014878617, -8.912437407999965],
              [-36.017945047999902, -8.911249153999954],
              [-36.026410959, -8.90796911999996],
              [-36.026414512, -8.907969317999964],
              [-36.033110301, -8.90840968599996],
              [-36.033256089, -8.908419113999967],
              [-36.046585166999897, -8.912005112999951],
              [-36.046588791, -8.912005368999933],
              [-36.057275161999897, -8.913026920999927],
              [-36.063935003, -8.915337347999984],
              [-36.066703119, -8.917778517999979],
              [-36.073449235999902, -8.925639407999933],
              [-36.075301736, -8.929106078999951],
              [-36.076376841, -8.932535232999944],
              [-36.078614203, -8.936293559999973],
              [-36.082284993, -8.940942951999935],
              [-36.086229187999898, -8.943930036999969],
              [-36.086410568, -8.944067402999961],
              [-36.090792527, -8.946261424999935],
              [-36.099045178706277, -8.94871123767477],
              [-36.099045178706277, -8.850461434175614],
              [-35.999045178706275, -8.850461434175614],
              [-35.999045178706275, -8.907723744698643],
              [-35.999105297, -8.907729629999944],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '151',
        ID: 38293.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.9005,
        ANNUAL: 5086.0,
        JAN: 5765.0,
        FEB: 5674.0,
        MAR: 5656.0,
        APR: 5016.0,
        MAY: 4118.0,
        JUN: 3705.0,
        JUL: 3789.0,
        AUG: 4433.0,
        SEP: 5256.0,
        OCT: 5555.0,
        NOV: 6040.0,
        DEC: 6020.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899821717999899, -8.861237962999944],
              [-35.900988367, -8.86241186399997],
              [-35.900992883999898, -8.862412252999956],
              [-35.906830731, -8.863151695999926],
              [-35.909724444999902, -8.86121958499996],
              [-35.909728959999903, -8.861219498999958],
              [-35.911079479, -8.861104701999976],
              [-35.911417499, -8.861289080999937],
              [-35.916392980999902, -8.867583546999985],
              [-35.9163960019999, -8.867581833999957],
              [-35.916397521, -8.867583755999938],
              [-35.917200013, -8.869386180999971],
              [-35.917197041999898, -8.869389499999954],
              [-35.917731838, -8.870590671999931],
              [-35.919701602, -8.876761329999969],
              [-35.921016069, -8.878270154999932],
              [-35.921146458, -8.880729660999977],
              [-35.921831109, -8.88266852199995],
              [-35.924276034999899, -8.884627504999969],
              [-35.924280408, -8.884627580999963],
              [-35.933218933, -8.884505015999927],
              [-35.933220591999898, -8.884505101999924],
              [-35.93322255, -8.884505202999947],
              [-35.936230689, -8.884858609999977],
              [-35.942535334999903, -8.885599302999942],
              [-35.9425374959999, -8.885599827999947],
              [-35.9425397649999, -8.885600370999946],
              [-35.944496408, -8.886329171999922],
              [-35.94971943, -8.890557149999953],
              [-35.950620962, -8.892294837999946],
              [-35.953103778, -8.892913627999974],
              [-35.953440063999899, -8.894039159999986],
              [-35.953443725999897, -8.894037438999927],
              [-35.954518876999899, -8.893532284999937],
              [-35.954520744, -8.893533284999927],
              [-35.954522509, -8.893532452999978],
              [-35.95596498, -8.894304102999973],
              [-35.955967132999902, -8.894305254999942],
              [-35.959466909, -8.892923954999954],
              [-35.961014481999896, -8.894017953999933],
              [-35.961253767, -8.894370057999945],
              [-35.961961659999901, -8.89541170899996],
              [-35.962271088, -8.898094079999961],
              [-35.962791009999904, -8.898954526999939],
              [-35.962632379, -8.901207860999934],
              [-35.963546563, -8.902407647999954],
              [-35.965302547, -8.903143984999929],
              [-35.968046704, -8.903745293999988],
              [-35.969519319, -8.901104052999928],
              [-35.970655611, -8.900396928999943],
              [-35.970658043999897, -8.900397843999944],
              [-35.974113569999901, -8.901696145999949],
              [-35.97523921, -8.902118333999962],
              [-35.975242783999903, -8.902119238999937],
              [-35.978041636999897, -8.902501033999945],
              [-35.979741284999911, -8.902732883999931],
              [-35.981577586, -8.904563382999978],
              [-35.984158393, -8.905216558999941],
              [-35.986132786, -8.903426218999938],
              [-35.987050548999903, -8.903287580999963],
              [-35.988161718, -8.903986090999982],
              [-35.988165298999903, -8.903986519999926],
              [-35.988360664, -8.904024688999923],
              [-35.991911425, -8.904720121999956],
              [-35.992522956, -8.90516246399994],
              [-35.995599811, -8.907386552999986],
              [-35.995603435, -8.907386813999954],
              [-35.999045178706275, -8.907723744698643],
              [-35.999045178706275, -8.850461434175614],
              [-35.899045178706274, -8.850461434175614],
              [-35.899045178706274, -8.858617550670798],
              [-35.899821717999899, -8.861237962999944],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '152',
        ID: 38294.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.9005,
        ANNUAL: 5047.0,
        JAN: 5663.0,
        FEB: 5638.0,
        MAR: 5621.0,
        APR: 4951.0,
        MAY: 4145.0,
        JUN: 3740.0,
        JUL: 3824.0,
        AUG: 4426.0,
        SEP: 5218.0,
        OCT: 5480.0,
        NOV: 5936.0,
        DEC: 5926.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799953834, -8.851802563999975],
              [-35.801796284, -8.854251103999966],
              [-35.805170003999898, -8.856871591999942],
              [-35.806565285, -8.859396947999928],
              [-35.807895677, -8.860387544999975],
              [-35.807898974, -8.860387723999956],
              [-35.807900173, -8.860388615999971],
              [-35.810249279999901, -8.860514928999974],
              [-35.810344617999903, -8.860520072999973],
              [-35.811638269999897, -8.860138474999939],
              [-35.815864841999897, -8.857403575999973],
              [-35.819911052, -8.856874305999948],
              [-35.819915594, -8.856874439999959],
              [-35.822150485999899, -8.856922074999936],
              [-35.8229761609999, -8.857419121999953],
              [-35.825497441, -8.863833518999968],
              [-35.824468872, -8.868652426999963],
              [-35.825748201, -8.870343746999936],
              [-35.825752732999902, -8.870344052999947],
              [-35.826571849999901, -8.870417219999979],
              [-35.827389449, -8.869714286999969],
              [-35.830583861, -8.868486597999953],
              [-35.830588356, -8.868486825999987],
              [-35.831684863, -8.868547114999949],
              [-35.832890595, -8.869914190999955],
              [-35.833896283999898, -8.870220466999969],
              [-35.833897204, -8.870219573999972],
              [-35.835444426, -8.868717977999953],
              [-35.835448966999898, -8.868718115999974],
              [-35.837050806, -8.86874164899996],
              [-35.840179253, -8.870206377999981],
              [-35.842515098, -8.872925008999962],
              [-35.842519232, -8.872924746999947],
              [-35.844267636, -8.872702601999951],
              [-35.845002539999903, -8.872480459999966],
              [-35.847369993, -8.871763757999927],
              [-35.847374534, -8.87176392499993],
              [-35.849138808, -8.871812126999947],
              [-35.8502341989999, -8.868899543999934],
              [-35.853201918, -8.866307455999966],
              [-35.855400883, -8.862719506999946],
              [-35.858394168, -8.860678818999927],
              [-35.859459490999903, -8.860525061999979],
              [-35.859756660999899, -8.860482171999923],
              [-35.8608852099999, -8.858902970999955],
              [-35.861930748, -8.858368983999981],
              [-35.863865953, -8.857970160999963],
              [-35.86386801, -8.857970653999923],
              [-35.86691804, -8.858701385999948],
              [-35.866922461999899, -8.858701567999969],
              [-35.871912342, -8.858879664999959],
              [-35.871916773999899, -8.858879834999936],
              [-35.874093252, -8.859437190999934],
              [-35.874095693, -8.859437815999975],
              [-35.8755210919999, -8.858891638999973],
              [-35.879059329, -8.859608536999932],
              [-35.880457577999898, -8.860736986999932],
              [-35.884333081, -8.862679674999972],
              [-35.884337022, -8.862680360999947],
              [-35.88554721, -8.862860398999947],
              [-35.886947482, -8.863068597999929],
              [-35.886951989999901, -8.863069034999974],
              [-35.888808476999898, -8.862625030999936],
              [-35.890787663, -8.861392853999963],
              [-35.892671187, -8.859514258999976],
              [-35.894318271, -8.855380393999951],
              [-35.8952310089999, -8.854374995999924],
              [-35.89620057, -8.854268296999981],
              [-35.899027873, -8.858559152999936],
              [-35.899045178706274, -8.858617550670798],
              [-35.899045178706274, -8.850461434175614],
              [-35.799607692963363, -8.850461434175614],
              [-35.799953834, -8.851802563999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '153',
        ID: 38295.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.9005,
        ANNUAL: 5008.0,
        JAN: 5574.0,
        FEB: 5600.0,
        MAR: 5598.0,
        APR: 4919.0,
        MAY: 4144.0,
        JUN: 3749.0,
        JUL: 3820.0,
        AUG: 4413.0,
        SEP: 5170.0,
        OCT: 5411.0,
        NOV: 5854.0,
        DEC: 5839.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.700275202999897, -8.906590480999974],
              [-35.700280493, -8.906590621999946],
              [-35.707998857999897, -8.906634355999934],
              [-35.711682240999899, -8.905017965999955],
              [-35.7137008, -8.904797820999924],
              [-35.713702149, -8.904798380999985],
              [-35.718266748, -8.906697884999963],
              [-35.721492339999898, -8.908472998999978],
              [-35.7216797, -8.908576199999969],
              [-35.7221591119999, -8.908840142999958],
              [-35.722332660999903, -8.908935713999934],
              [-35.722544035999903, -8.909052155999966],
              [-35.7233013479999, -8.909469997999967],
              [-35.723305372, -8.909470749999976],
              [-35.723307177, -8.90947128199997],
              [-35.723309389, -8.909472960999953],
              [-35.723861106, -8.909576135999949],
              [-35.723994449, -8.909600882999943],
              [-35.724857248, -8.909761994999945],
              [-35.728525783, -8.910446975999948],
              [-35.736403679, -8.911917763999945],
              [-35.736408162, -8.911918283999965],
              [-35.742917471999903, -8.915207942999929],
              [-35.744927886, -8.916724936999975],
              [-35.748398992, -8.917151743999963],
              [-35.752024148, -8.914683034999943],
              [-35.754535754, -8.912252498999983],
              [-35.75882361, -8.910011842999943],
              [-35.760917123, -8.908080695999963],
              [-35.760921663, -8.908080817999972],
              [-35.763105661999901, -8.908095974999974],
              [-35.763111111999898, -8.908096120999966],
              [-35.766592932, -8.907147511999941],
              [-35.769022799, -8.907437363999971],
              [-35.771586442999897, -8.906260286999958],
              [-35.771590962999902, -8.906260227999942],
              [-35.773241219999903, -8.906139212999966],
              [-35.778188397, -8.898888191999955],
              [-35.780205043, -8.898240597999964],
              [-35.780207962, -8.898241330999953],
              [-35.781730663, -8.898622686999932],
              [-35.782980726999902, -8.897542131999963],
              [-35.780288288999898, -8.891341104999974],
              [-35.779177729, -8.89007685199994],
              [-35.779138175999897, -8.888641739999963],
              [-35.780613804, -8.888016266999943],
              [-35.782082381, -8.883464002999967],
              [-35.783677143, -8.882001459999966],
              [-35.782202378, -8.878918779999932],
              [-35.781465204, -8.878292021999938],
              [-35.779091752999904, -8.873126366999939],
              [-35.778836198, -8.871569118999952],
              [-35.776915682, -8.869265270999966],
              [-35.776873540999901, -8.869056345999983],
              [-35.775768909, -8.863584244999972],
              [-35.776965431999898, -8.861697818999975],
              [-35.776863877, -8.860635854999931],
              [-35.775958233, -8.859527921999934],
              [-35.775964622999901, -8.859213134999946],
              [-35.775979954, -8.858457890999944],
              [-35.77598449, -8.858457940999928],
              [-35.777043754, -8.858431631999968],
              [-35.779014312, -8.85958793199995],
              [-35.780487735, -8.861074954999944],
              [-35.78049192200001, -8.861074808999934],
              [-35.781324312, -8.860965480999942],
              [-35.783247785999897, -8.855649401999937],
              [-35.785462537999898, -8.854587957999975],
              [-35.787596138, -8.851130423999951],
              [-35.787600558, -8.851130827999945],
              [-35.788888556, -8.851310025999958],
              [-35.789786541999902, -8.853088766999971],
              [-35.790616482, -8.853413579999945],
              [-35.790617567, -8.853412439999937],
              [-35.790621022, -8.853413791999976],
              [-35.793427807536816, -8.850461434175614],
              [-35.699045178706271, -8.850461434175614],
              [-35.699045178706271, -8.905712439748964],
              [-35.700275202999897, -8.906590480999974],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '154',
        ID: 38296.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.9005,
        ANNUAL: 5082.0,
        JAN: 5661.0,
        FEB: 5635.0,
        MAR: 5639.0,
        APR: 4984.0,
        MAY: 4197.0,
        JUN: 3880.0,
        JUL: 3912.0,
        AUG: 4533.0,
        SEP: 5267.0,
        OCT: 5513.0,
        NOV: 5928.0,
        DEC: 5837.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.600771393999899, -8.862118417999971],
              [-35.604261145999899, -8.864700682999962],
              [-35.6054912439999, -8.865152951999951],
              [-35.605496452999901, -8.865153118999951],
              [-35.606466200999897, -8.865168573999934],
              [-35.609326095, -8.863541365999946],
              [-35.60933154, -8.863541452999936],
              [-35.610326875999903, -8.863527287999938],
              [-35.613782347, -8.864394518999971],
              [-35.620284031, -8.868889471999976],
              [-35.620919933, -8.870913178999952],
              [-35.622051594, -8.871837452999962],
              [-35.622569009, -8.872260047999925],
              [-35.622618941, -8.872300829999963],
              [-35.622569507, -8.872793127999955],
              [-35.622574906, -8.872790564999944],
              [-35.622489049, -8.873639827999963],
              [-35.622915229999897, -8.874541366999944],
              [-35.6229123439999, -8.874545075999951],
              [-35.623430927, -8.875642589999924],
              [-35.624471441, -8.876316865999971],
              [-35.624477657999897, -8.876318501999942],
              [-35.625031030999899, -8.876677096999966],
              [-35.626256859, -8.877471453999931],
              [-35.629112256, -8.879321798999964],
              [-35.629112219, -8.879324191999963],
              [-35.632127759999896, -8.881278329999924],
              [-35.637888555, -8.882592005999976],
              [-35.637894, -8.882592299999946],
              [-35.637993337, -8.882598377999949],
              [-35.644180515, -8.882976928999938],
              [-35.644273988, -8.882982647999935],
              [-35.646629428, -8.882281276999946],
              [-35.646634876999897, -8.88228146599994],
              [-35.650944454, -8.882380115999975],
              [-35.650949904999898, -8.88238030699995],
              [-35.65198578, -8.882256475999958],
              [-35.65339875, -8.881703021999959],
              [-35.653786451, -8.881488833999972],
              [-35.653871872, -8.881441643999949],
              [-35.655764863999899, -8.880649181999964],
              [-35.65635473, -8.880402244999971],
              [-35.656911157, -8.88052480099998],
              [-35.657517766, -8.880658429999931],
              [-35.660884746, -8.882871534999945],
              [-35.663113732999904, -8.889674340999923],
              [-35.66472682900001, -8.890982410999973],
              [-35.669987463, -8.895245370999934],
              [-35.66999186, -8.895250388999951],
              [-35.671204082, -8.89663404199996],
              [-35.671209513, -8.896634461999948],
              [-35.674754557, -8.897017509999964],
              [-35.674759987999899, -8.897017929999953],
              [-35.677380528999898, -8.89848425399998],
              [-35.679402081, -8.898442366999969],
              [-35.67940662, -8.898442425999963],
              [-35.680922001999903, -8.896991626999979],
              [-35.684485673, -8.897513126999973],
              [-35.687502298, -8.898506311999935],
              [-35.689237175, -8.899483191999938],
              [-35.692123953, -8.900771787999931],
              [-35.699045178706271, -8.905712439748964],
              [-35.699045178706271, -8.850461434175614],
              [-35.59904517870627, -8.850461434175614],
              [-35.59904517870627, -8.861891377828707],
              [-35.600771393999899, -8.862118417999971],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '155',
        ID: 38297.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.9005,
        ANNUAL: 5147.0,
        JAN: 5721.0,
        FEB: 5663.0,
        MAR: 5731.0,
        APR: 5006.0,
        MAY: 4245.0,
        JUN: 3940.0,
        JUL: 3976.0,
        AUG: 4594.0,
        SEP: 5352.0,
        OCT: 5605.0,
        NOV: 5992.0,
        DEC: 5946.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.589652591999901, -8.851711377999953],
              [-35.594111951999899, -8.854854654999937],
              [-35.594958638, -8.856089289999941],
              [-35.595428558, -8.859852181999944],
              [-35.59619365, -8.860991696999971],
              [-35.597758923, -8.861722433999944],
              [-35.597764341999898, -8.861722915999961],
              [-35.59904517870627, -8.861891377828707],
              [-35.59904517870627, -8.850461434175614],
              [-35.588921389594866, -8.850461434175614],
              [-35.589652591999901, -8.851711377999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '156',
        ID: 38299.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.9005,
        ANNUAL: 5247.0,
        JAN: 5828.0,
        FEB: 5785.0,
        MAR: 5893.0,
        APR: 5020.0,
        MAY: 4294.0,
        JUN: 4028.0,
        JUL: 4068.0,
        AUG: 4735.0,
        SEP: 5399.0,
        OCT: 5732.0,
        NOV: 6113.0,
        DEC: 6073.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299292345, -8.873635790999964],
              [-35.300947928, -8.873220168999987],
              [-35.30632947, -8.871869169999977],
              [-35.307172464, -8.871657472999972],
              [-35.343479156999898, -8.862539975999951],
              [-35.35075466, -8.860712919999964],
              [-35.39205085414865, -8.850461434175614],
              [-35.299045178706265, -8.850461434175614],
              [-35.299045178706265, -8.873697804280889],
              [-35.299292345, -8.873635790999964],
            ],
          ],
          [
            [
              [-35.397945943, -8.85051391299992],
              [-35.3979492, -8.850513590999983],
              [-35.397952298999897, -8.850514212999947],
              [-35.398486720727654, -8.850461434175614],
              [-35.39768463140129, -8.850461434175614],
              [-35.397945943, -8.85051391299992],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '157',
        ID: 38300.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.9005,
        ANNUAL: 5283.0,
        JAN: 5824.0,
        FEB: 5863.0,
        MAR: 5968.0,
        APR: 5045.0,
        MAY: 4329.0,
        JUN: 4063.0,
        JUL: 4115.0,
        AUG: 4804.0,
        SEP: 5418.0,
        OCT: 5768.0,
        NOV: 6085.0,
        DEC: 6118.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.20058756000001, -8.888218964999961],
              [-35.200594348, -8.888219066999973],
              [-35.203074746, -8.888227486999947],
              [-35.204985264, -8.889789944999965],
              [-35.206788253999903, -8.890848820999969],
              [-35.206795252999903, -8.890848856999988],
              [-35.207985191, -8.890841981999923],
              [-35.207992083, -8.890842017999931],
              [-35.209093982999903, -8.891763713999982],
              [-35.210928576, -8.89077807299998],
              [-35.211447204, -8.888851890999939],
              [-35.212803762, -8.888572919999946],
              [-35.212806253999901, -8.888574018999986],
              [-35.212810117999901, -8.888573223999973],
              [-35.214155334999901, -8.889166483999928],
              [-35.215114624, -8.888650916999939],
              [-35.217491184, -8.888576864999951],
              [-35.218125955999902, -8.889083183999954],
              [-35.218133203, -8.889083703999976],
              [-35.220106971999897, -8.889273267999959],
              [-35.222115913, -8.88739274399995],
              [-35.225455595, -8.887139828999976],
              [-35.227135303999901, -8.885548506999953],
              [-35.227142557, -8.885548545999933],
              [-35.22838124, -8.885502345999956],
              [-35.229589626999903, -8.884614219999944],
              [-35.231560992, -8.884034315999932],
              [-35.2315646489999, -8.884035722999954],
              [-35.231568255999903, -8.884034663999968],
              [-35.232326507, -8.884326808999957],
              [-35.23501599, -8.883198548999921],
              [-35.237837538, -8.882722869999959],
              [-35.237841692, -8.882723543999926],
              [-35.240811229, -8.883206080999972],
              [-35.240934371, -8.88322611299998],
              [-35.240941544, -8.883227288999938],
              [-35.242623341, -8.884033026999932],
              [-35.242622788999903, -8.884035869999934],
              [-35.242679028999902, -8.884062841999933],
              [-35.246474937, -8.884981315999937],
              [-35.246480496999901, -8.884981250999939],
              [-35.246482198, -8.884981374999946],
              [-35.248124007999898, -8.884962352999928],
              [-35.248129983999902, -8.884962378999941],
              [-35.252782131999901, -8.887233014999936],
              [-35.255126189, -8.885525485999949],
              [-35.257085447, -8.885105638999962],
              [-35.260775053, -8.883304566999923],
              [-35.262906703999903, -8.881441057999947],
              [-35.265188936999898, -8.880591],
              [-35.26519372, -8.88059188899997],
              [-35.266009066, -8.88074324299993],
              [-35.266440944, -8.880823722999931],
              [-35.266488598, -8.880832586999928],
              [-35.26714248, -8.88095424899995],
              [-35.267992051, -8.880043849999938],
              [-35.268660817999901, -8.879327198999926],
              [-35.268962761999902, -8.879159261999966],
              [-35.2715128849999, -8.878615199999963],
              [-35.273053225, -8.877521481999956],
              [-35.275037332, -8.877354633999966],
              [-35.277580182999898, -8.875162951999938],
              [-35.280102314, -8.875012237999961],
              [-35.280104090999899, -8.875012814999923],
              [-35.282619038, -8.875831524999967],
              [-35.282623961999903, -8.875832233999976],
              [-35.282625365999898, -8.87583256199996],
              [-35.28395804, -8.876024322999964],
              [-35.283960942, -8.876024739999933],
              [-35.287648577, -8.876555008999958],
              [-35.287654071, -8.876555798999968],
              [-35.299045178706265, -8.873697804280889],
              [-35.299045178706265, -8.850461434175614],
              [-35.199045178706264, -8.850461434175614],
              [-35.199045178706264, -8.88768534788557],
              [-35.20058756000001, -8.888218964999961],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '158',
        ID: 38301.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.9005,
        ANNUAL: 5417.0,
        JAN: 5967.0,
        FEB: 5998.0,
        MAR: 6061.0,
        APR: 5128.0,
        MAY: 4428.0,
        JUN: 4162.0,
        JUL: 4212.0,
        AUG: 4928.0,
        SEP: 5636.0,
        OCT: 5994.0,
        NOV: 6213.0,
        DEC: 6273.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.133005067999903, -8.85069862199998],
              [-35.132413168, -8.852643735999946],
              [-35.1321314489999, -8.853480974999973],
              [-35.131743668, -8.854342841999939],
              [-35.131425614, -8.854877250999946],
              [-35.131227431, -8.85543877299995],
              [-35.131108976, -8.855924852999973],
              [-35.131177191999903, -8.856250532999976],
              [-35.131209238999901, -8.856458957999958],
              [-35.131452242, -8.858039359999959],
              [-35.131705395, -8.859580296999987],
              [-35.131528789, -8.859808059999983],
              [-35.131288038, -8.860122733999958],
              [-35.131071075, -8.860610555999935],
              [-35.130898993, -8.860890094999942],
              [-35.13044658, -8.861744040999952],
              [-35.130195794, -8.862312592999958],
              [-35.130049002, -8.862645383999965],
              [-35.130045832, -8.862674710999945],
              [-35.129602027, -8.863448548999939],
              [-35.129273105, -8.864214719999953],
              [-35.129124733, -8.864810161999962],
              [-35.129082497, -8.865701503999954],
              [-35.129205622, -8.86656283499997],
              [-35.129442532, -8.867043308999937],
              [-35.129829432999898, -8.867735536999923],
              [-35.13025189, -8.868378443999978],
              [-35.130531071, -8.868533189999937],
              [-35.130539429999899, -8.868534071999935],
              [-35.130934204, -8.868659602999973],
              [-35.130952164, -8.868665314999921],
              [-35.131370014, -8.868905666999977],
              [-35.131905651, -8.869472654999987],
              [-35.132525334, -8.870193705999958],
              [-35.133666191, -8.871048427999934],
              [-35.134583563, -8.871907787999923],
              [-35.135340291, -8.872902546999978],
              [-35.135846259999902, -8.873710602999941],
              [-35.136154641, -8.874377971999927],
              [-35.1363614959999, -8.875084738999936],
              [-35.136408203, -8.875333904999927],
              [-35.136466625, -8.87564556499996],
              [-35.136513172, -8.876193393999934],
              [-35.136619057, -8.876622216999985],
              [-35.136693169, -8.876929678999939],
              [-35.136694981999902, -8.876937200999985],
              [-35.136932903999899, -8.877513612999957],
              [-35.137136363, -8.87786085399995],
              [-35.137349652, -8.87823417799995],
              [-35.137604194999902, -8.878578454999968],
              [-35.137770894, -8.878796517999934],
              [-35.13809952, -8.879283308999968],
              [-35.138359905, -8.87969084699994],
              [-35.138557245, -8.88003366299994],
              [-35.138783557, -8.88040419699996],
              [-35.139001338, -8.880736237999939],
              [-35.139221959999901, -8.881081102999936],
              [-35.139399011, -8.881336680999942],
              [-35.139781791, -8.881889231999928],
              [-35.140117026, -8.882229464999977],
              [-35.140509583, -8.882921905999982],
              [-35.141000195999901, -8.883859028999954],
              [-35.141446980999902, -8.88491204799992],
              [-35.141819648, -8.885728199999956],
              [-35.141826669, -8.88574147099996],
              [-35.141879527999897, -8.886152184999952],
              [-35.141786087, -8.886313158999945],
              [-35.1417303549999, -8.88651612099993],
              [-35.141733643, -8.886524820999968],
              [-35.1417788319999, -8.886771837999964],
              [-35.141794463999901, -8.886813723999976],
              [-35.141942722, -8.887210973999963],
              [-35.142322752, -8.888296629999957],
              [-35.142291315, -8.888627405999966],
              [-35.142409105999903, -8.889024050999984],
              [-35.14236722, -8.889098985999954],
              [-35.14260834, -8.889974990999965],
              [-35.142812404, -8.891168514999952],
              [-35.142966552, -8.892230521999979],
              [-35.143037041, -8.892716160999949],
              [-35.143043067999898, -8.892811856999966],
              [-35.143099274999898, -8.893704156999958],
              [-35.143128095, -8.895271339999965],
              [-35.143315673, -8.896461983999927],
              [-35.143654042, -8.897757750999974],
              [-35.143941663, -8.898656747999953],
              [-35.144112252999903, -8.899073925999977],
              [-35.144286117, -8.899499111999942],
              [-35.144764489, -8.900102459999962],
              [-35.144956701, -8.900269090999927],
              [-35.145406159999901, -8.900658731999947],
              [-35.146466913, -8.901514101999961],
              [-35.146482294999899, -8.901528253],
              [-35.146842169, -8.901758304999928],
              [-35.1470355599999, -8.901865186999931],
              [-35.147731738999902, -8.902249947999968],
              [-35.148209746, -8.90263861699998],
              [-35.148425157, -8.902828311999938],
              [-35.1487000119999, -8.903070352999977],
              [-35.149246674, -8.903762764999952],
              [-35.149831208, -8.904438959999943],
              [-35.150284373, -8.904979546999927],
              [-35.150573256, -8.905436018999973],
              [-35.150789126999904, -8.905863878999979],
              [-35.151120675999898, -8.906658168999966],
              [-35.151382656, -8.907324815999976],
              [-35.151557974, -8.908011502999951],
              [-35.151674194, -8.908645864999928],
              [-35.151720114999897, -8.909161810999933],
              [-35.151849415999898, -8.909646972999955],
              [-35.151978180999897, -8.91016551199994],
              [-35.1521325759999, -8.910845836999956],
              [-35.152188388999903, -8.911155908999934],
              [-35.152203891999903, -8.911525609999966],
              [-35.1522090879999, -8.911933757999972],
              [-35.152208255999902, -8.912266594999947],
              [-35.152207994, -8.912371655999953],
              [-35.152206174, -8.912759319999957],
              [-35.152250917999901, -8.913086452999943],
              [-35.152418323999903, -8.913475191999963],
              [-35.152466911, -8.913543086999942],
              [-35.1526537519999, -8.913804180999927],
              [-35.152651354999897, -8.913856027999937],
              [-35.1528918079999, -8.913892333999977],
              [-35.15320387, -8.913825462999979],
              [-35.153404479999899, -8.913624852999934],
              [-35.153671962, -8.913502256999944],
              [-35.154021057, -8.913665167999971],
              [-35.154173489, -8.913736302999956],
              [-35.154407535, -8.913981493999927],
              [-35.154663871, -8.913959203999987],
              [-35.154788688999901, -8.913745971999962],
              [-35.154931351999899, -8.913502256999944],
              [-35.155009366999899, -8.913167905999954],
              [-35.155321429, -8.912889278999961],
              [-35.155421734, -8.912599506999971],
              [-35.155276848, -8.91244347799994],
              [-35.155014939999901, -8.912345201999932],
              [-35.154877231999897, -8.912261047999948],
              [-35.154793078, -8.912165417999976],
              [-35.1547318739999, -8.912146290999933],
              [-35.154628594, -8.91219219499993],
              [-35.1544641099999, -8.912253397999958],
              [-35.154391431, -8.912261047999943],
              [-35.154238423, -8.912188368999976],
              [-35.154070114, -8.912065962999973],
              [-35.154005084999902, -8.911970332999948],
              [-35.1539400569999, -8.911683441999978],
              [-35.153848251999896, -8.911457754999962],
              [-35.153863552, -8.911339171999938],
              [-35.15400126, -8.911197640999934],
              [-35.154062462999903, -8.91111730999995],
              [-35.154062462999903, -8.910998728999974],
              [-35.154108365999903, -8.910906924999955],
              [-35.15419252, -8.910841895999974],
              [-35.154372305, -8.910876322999963],
              [-35.154525313, -8.910914573999953],
              [-35.154685972, -8.910872497999948],
              [-35.154766300999903, -8.910753916999978],
              [-35.154781602, -8.910524403999942],
              [-35.154708922999902, -8.910298716999923],
              [-35.1547318739999, -8.910023300999976],
              [-35.1549116589999, -8.90985881799998],
              [-35.155087619, -8.909767012999961],
              [-35.155225325999901, -8.909560451999933],
              [-35.155267403, -8.90935389099997],
              [-35.155225325999901, -8.909128202999979],
              [-35.155232977, -8.90893311799994],
              [-35.155281083, -8.908816870999937],
              [-35.155290419, -8.908681496999971],
              [-35.155295087999903, -8.908487772999921],
              [-35.155365108, -8.908338394999928],
              [-35.155411789, -8.90815867599996],
              [-35.155649858999901, -8.907897263999923],
              [-35.155645246999903, -8.907523436999959],
              [-35.155687077, -8.907042394999968],
              [-35.155682893999902, -8.906661742999962],
              [-35.155813480999903, -8.906286550999939],
              [-35.1559149939999, -8.906169418999978],
              [-35.155875951, -8.906047082999978],
              [-35.155766628, -8.905721717999942],
              [-35.155891568, -8.905633217999929],
              [-35.155938421, -8.905505675999963],
              [-35.155904582999902, -8.905344294999955],
              [-35.155766628, -8.905190721999935],
              [-35.155674225, -8.905035328999924],
              [-35.155633827, -8.904858590999943],
              [-35.155507585999899, -8.904686901999975],
              [-35.155482338, -8.904560661999932],
              [-35.155506637, -8.904430061999955],
              [-35.155506637, -8.904363118999983],
              [-35.155622501, -8.904211207999946],
              [-35.155668847, -8.904059297999936],
              [-35.155673996, -8.903752901999944],
              [-35.155761538, -8.903554645999973],
              [-35.155934047, -8.903322917999956],
              [-35.1559829669999, -8.902769344999964],
              [-35.156559711999897, -8.902293016999982],
              [-35.156853234, -8.902027816999983],
              [-35.157426539, -8.901742123999947],
              [-35.158865279999901, -8.90069603699993],
              [-35.160308357, -8.899610592999975],
              [-35.161217386, -8.898885409999924],
              [-35.16191176, -8.898406933999972],
              [-35.16204866, -8.898312598999944],
              [-35.1625223899999, -8.897843521999956],
              [-35.162648923999903, -8.897562944999947],
              [-35.162783406, -8.897428462999931],
              [-35.162809637999899, -8.897415745999986],
              [-35.164834244, -8.896434197999948],
              [-35.166063049999899, -8.894581346999932],
              [-35.168251303, -8.892471078999968],
              [-35.171231817, -8.89056126299994],
              [-35.174240499999897, -8.890389303999941],
              [-35.176742157, -8.888839806999949],
              [-35.176749419, -8.888839941999953],
              [-35.178768793, -8.888818397999955],
              [-35.181589183, -8.888075052999975],
              [-35.181592324, -8.888076488999964],
              [-35.18587973, -8.890035571999926],
              [-35.185885116, -8.890035060999935],
              [-35.188442414, -8.889793083999951],
              [-35.190095971999902, -8.88852856599993],
              [-35.190102947, -8.888529243999924],
              [-35.192143678, -8.888763204999977],
              [-35.192145778999901, -8.888763203999927],
              [-35.195882020999903, -8.888339944999922],
              [-35.198095096, -8.887356647999983],
              [-35.19810068000001, -8.887358579999951],
              [-35.199045178706264, -8.88768534788557],
              [-35.199045178706264, -8.850461434175614],
              [-35.13305559527776, -8.850461434175614],
              [-35.133005067999903, -8.85069862199998],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '159',
        ID: 38622.0,
        COUNTRY: 'Brasil',
        LON: -41.149,
        LAT: -8.8005,
        ANNUAL: 5763.0,
        JAN: 6122.0,
        FEB: 5845.0,
        MAR: 5709.0,
        APR: 5197.0,
        MAY: 4875.0,
        JUN: 4775.0,
        JUL: 5129.0,
        AUG: 5914.0,
        SEP: 6564.0,
        OCT: 6516.0,
        NOV: 6439.0,
        DEC: 6080.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.10081371, -8.782181609999951],
              [-41.101760799, -8.781964295999966],
              [-41.103351091, -8.78044295199993],
              [-41.10354391, -8.777001209999977],
              [-41.105067345, -8.774354453999939],
              [-41.105956508, -8.770551362999983],
              [-41.108963805, -8.77058093299997],
              [-41.111156302, -8.770090780999967],
              [-41.113097141, -8.768711261999947],
              [-41.115823530999897, -8.764867137999941],
              [-41.115888533, -8.763663101999946],
              [-41.1130163, -8.759726772999956],
              [-41.113289771, -8.758730764999937],
              [-41.113344621, -8.758530998999962],
              [-41.114874782999898, -8.756929399999928],
              [-41.117819341, -8.755109959999965],
              [-41.122459235, -8.754243656999956],
              [-41.124569291999897, -8.752673559999947],
              [-41.12541225141225, -8.750461434175614],
              [-41.099045178706348, -8.750461434175614],
              [-41.099045178706348, -8.781751188564821],
              [-41.10081371, -8.782181609999951],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '160',
        ID: 38623.0,
        COUNTRY: 'Brasil',
        LON: -41.049,
        LAT: -8.8005,
        ANNUAL: 5759.0,
        JAN: 6104.0,
        FEB: 5837.0,
        MAR: 5742.0,
        APR: 5195.0,
        MAY: 4868.0,
        JUN: 4718.0,
        JUL: 5080.0,
        AUG: 5878.0,
        SEP: 6594.0,
        OCT: 6522.0,
        NOV: 6425.0,
        DEC: 6149.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.000349524999898, -8.828798044999925],
              [-41.006036517, -8.832287707999962],
              [-41.009926331, -8.833603331999937],
              [-41.012285604999903, -8.837312422999959],
              [-41.013093161, -8.837393541999969],
              [-41.01460018800001, -8.838598680999949],
              [-41.017314848, -8.842341047999978],
              [-41.01987065, -8.844031714999971],
              [-41.021240914, -8.84356091599993],
              [-41.024599006, -8.840090730999973],
              [-41.029101375, -8.838100134999937],
              [-41.031319702, -8.834958375999971],
              [-41.02987531, -8.830581985999963],
              [-41.029240308999903, -8.825490770999984],
              [-41.026647749999903, -8.820505703999975],
              [-41.026627990999899, -8.814194560999971],
              [-41.024872291999898, -8.808716284999948],
              [-41.024960111, -8.807727362999962],
              [-41.0263267999999, -8.805067741999959],
              [-41.027627294999903, -8.804274917999974],
              [-41.028708239, -8.80218276999994],
              [-41.032019294999898, -8.793793213999951],
              [-41.034515692, -8.792150915999979],
              [-41.0362345039999, -8.785875894999954],
              [-41.038120608, -8.784944540999954],
              [-41.038306904, -8.784852547999948],
              [-41.039790664, -8.784119869999921],
              [-41.042318853, -8.784019308999975],
              [-41.043926723, -8.784406797999951],
              [-41.04837602, -8.786846960999981],
              [-41.054797973999889, -8.786916556999934],
              [-41.058568701999903, -8.785804646999964],
              [-41.064488745999903, -8.781355219999977],
              [-41.066678591, -8.781048092999983],
              [-41.069592042999901, -8.781730707999943],
              [-41.071462035, -8.783158626999983],
              [-41.076461126, -8.787874832999922],
              [-41.078542180999897, -8.788772349999922],
              [-41.082068756, -8.784338557999943],
              [-41.08655210900001, -8.783104408999979],
              [-41.092646801999898, -8.782728088999923],
              [-41.09486027, -8.781827560999934],
              [-41.095093259999899, -8.781732770999943],
              [-41.095588177, -8.781531418999977],
              [-41.097400668, -8.781350950999981],
              [-41.099045178706348, -8.781751188564821],
              [-41.099045178706348, -8.750461434175614],
              [-40.999045178706346, -8.750461434175614],
              [-40.999045178706346, -8.828997080056952],
              [-41.000349524999898, -8.828798044999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '161',
        ID: 38624.0,
        COUNTRY: 'Brasil',
        LON: -40.949,
        LAT: -8.8005,
        ANNUAL: 5754.0,
        JAN: 6111.0,
        FEB: 5871.0,
        MAR: 5806.0,
        APR: 5214.0,
        MAY: 4826.0,
        JUN: 4676.0,
        JUL: 5035.0,
        AUG: 5831.0,
        SEP: 6528.0,
        OCT: 6494.0,
        NOV: 6451.0,
        DEC: 6199.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.914979130999903, -8.849522773999974],
              [-40.916960119, -8.844532183999947],
              [-40.91845756, -8.838875923999975],
              [-40.919237103999897, -8.837177041999952],
              [-40.921337803, -8.835430568999932],
              [-40.924238464, -8.835390625999935],
              [-40.927276763, -8.835890900999969],
              [-40.931034648, -8.837200739999957],
              [-40.9463831959999, -8.84499690999996],
              [-40.948398674, -8.844851029999971],
              [-40.951597912, -8.843527009999955],
              [-40.95486005, -8.843875130999955],
              [-40.959039292999897, -8.841980827999981],
              [-40.95937223, -8.841370146999967],
              [-40.959867502999899, -8.834592777999946],
              [-40.960905008999902, -8.830759304999958],
              [-40.962266004999897, -8.829911716999961],
              [-40.967123826, -8.829816062999951],
              [-40.9717550559999, -8.828449240999934],
              [-40.976013353, -8.824582497999931],
              [-40.979093748, -8.823417083999974],
              [-40.982123281, -8.823856597999958],
              [-40.984677567999903, -8.825278817999965],
              [-40.9885650699999, -8.82895286299993],
              [-40.989758325, -8.829440568999935],
              [-40.995809737999899, -8.829490787999928],
              [-40.999045178706346, -8.828997080056952],
              [-40.999045178706346, -8.750461434175614],
              [-40.899045178706345, -8.750461434175614],
              [-40.899045178706345, -8.850461434175614],
              [-40.913946342553757, -8.850461434175614],
              [-40.914979130999903, -8.849522773999974],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '162',
        ID: 38625.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -8.8005,
        ANNUAL: 5757.0,
        JAN: 6189.0,
        FEB: 5906.0,
        MAR: 5876.0,
        APR: 5240.0,
        MAY: 4840.0,
        JUN: 4577.0,
        JUL: 4953.0,
        AUG: 5765.0,
        SEP: 6516.0,
        OCT: 6486.0,
        NOV: 6488.0,
        DEC: 6247.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.899045178706345, -8.850461434175614],
              [-40.899045178706345, -8.750461434175614],
              [-40.799045178706344, -8.750461434175614],
              [-40.799045178706344, -8.850461434175614],
              [-40.899045178706345, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '163',
        ID: 38626.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.8005,
        ANNUAL: 5717.0,
        JAN: 6120.0,
        FEB: 5886.0,
        MAR: 5823.0,
        APR: 5196.0,
        MAY: 4782.0,
        JUN: 4554.0,
        JUL: 4903.0,
        AUG: 5737.0,
        SEP: 6499.0,
        OCT: 6439.0,
        NOV: 6439.0,
        DEC: 6221.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.799045178706344, -8.850461434175614],
              [-40.799045178706344, -8.750461434175614],
              [-40.699045178706342, -8.750461434175614],
              [-40.699045178706342, -8.850461434175614],
              [-40.799045178706344, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '164',
        ID: 38627.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.8005,
        ANNUAL: 5703.0,
        JAN: 6104.0,
        FEB: 5873.0,
        MAR: 5769.0,
        APR: 5168.0,
        MAY: 4765.0,
        JUN: 4560.0,
        JUL: 4896.0,
        AUG: 5725.0,
        SEP: 6513.0,
        OCT: 6443.0,
        NOV: 6420.0,
        DEC: 6204.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -8.850461434175614],
              [-40.699045178706342, -8.750461434175614],
              [-40.599045178706341, -8.750461434175614],
              [-40.599045178706341, -8.850461434175614],
              [-40.699045178706342, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '165',
        ID: 38628.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.8005,
        ANNUAL: 5708.0,
        JAN: 6027.0,
        FEB: 5878.0,
        MAR: 5772.0,
        APR: 5211.0,
        MAY: 4823.0,
        JUN: 4570.0,
        JUL: 4877.0,
        AUG: 5728.0,
        SEP: 6512.0,
        OCT: 6453.0,
        NOV: 6429.0,
        DEC: 6222.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.850461434175614],
              [-40.599045178706341, -8.750461434175614],
              [-40.499045178706339, -8.750461434175614],
              [-40.499045178706339, -8.850461434175614],
              [-40.599045178706341, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '166',
        ID: 38629.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.8005,
        ANNUAL: 5702.0,
        JAN: 6077.0,
        FEB: 5888.0,
        MAR: 5803.0,
        APR: 5241.0,
        MAY: 4799.0,
        JUN: 4541.0,
        JUL: 4842.0,
        AUG: 5695.0,
        SEP: 6428.0,
        OCT: 6452.0,
        NOV: 6435.0,
        DEC: 6217.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.850461434175614],
              [-40.499045178706339, -8.750461434175614],
              [-40.399045178706338, -8.750461434175614],
              [-40.399045178706338, -8.850461434175614],
              [-40.499045178706339, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '167',
        ID: 38630.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.8005,
        ANNUAL: 5675.0,
        JAN: 6101.0,
        FEB: 5859.0,
        MAR: 5780.0,
        APR: 5298.0,
        MAY: 4762.0,
        JUN: 4501.0,
        JUL: 4787.0,
        AUG: 5655.0,
        SEP: 6368.0,
        OCT: 6386.0,
        NOV: 6399.0,
        DEC: 6200.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.850461434175614],
              [-40.399045178706338, -8.750461434175614],
              [-40.299045178706336, -8.750461434175614],
              [-40.299045178706336, -8.850461434175614],
              [-40.399045178706338, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '168',
        ID: 38631.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.8005,
        ANNUAL: 5624.0,
        JAN: 6006.0,
        FEB: 5828.0,
        MAR: 5781.0,
        APR: 5247.0,
        MAY: 4752.0,
        JUN: 4426.0,
        JUL: 4704.0,
        AUG: 5530.0,
        SEP: 6330.0,
        OCT: 6363.0,
        NOV: 6376.0,
        DEC: 6150.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.850461434175614],
              [-40.299045178706336, -8.750461434175614],
              [-40.199045178706335, -8.750461434175614],
              [-40.199045178706335, -8.850461434175614],
              [-40.299045178706336, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '169',
        ID: 38632.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.8005,
        ANNUAL: 5633.0,
        JAN: 6068.0,
        FEB: 5832.0,
        MAR: 5790.0,
        APR: 5227.0,
        MAY: 4720.0,
        JUN: 4400.0,
        JUL: 4666.0,
        AUG: 5526.0,
        SEP: 6378.0,
        OCT: 6377.0,
        NOV: 6419.0,
        DEC: 6185.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.850461434175614],
              [-40.199045178706335, -8.750461434175614],
              [-40.099045178706334, -8.750461434175614],
              [-40.099045178706334, -8.850461434175614],
              [-40.199045178706335, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '170',
        ID: 38633.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.8005,
        ANNUAL: 5654.0,
        JAN: 6184.0,
        FEB: 5834.0,
        MAR: 5818.0,
        APR: 5250.0,
        MAY: 4724.0,
        JUN: 4376.0,
        JUL: 4643.0,
        AUG: 5543.0,
        SEP: 6373.0,
        OCT: 6396.0,
        NOV: 6468.0,
        DEC: 6242.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.850461434175614],
              [-40.099045178706334, -8.750461434175614],
              [-39.999045178706332, -8.750461434175614],
              [-39.999045178706332, -8.850461434175614],
              [-40.099045178706334, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '171',
        ID: 38634.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.8005,
        ANNUAL: 5656.0,
        JAN: 6147.0,
        FEB: 5838.0,
        MAR: 5884.0,
        APR: 5277.0,
        MAY: 4726.0,
        JUN: 4394.0,
        JUL: 4614.0,
        AUG: 5434.0,
        SEP: 6346.0,
        OCT: 6416.0,
        NOV: 6530.0,
        DEC: 6271.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.900542746, -8.842021275999972],
              [-39.902707016391822, -8.850461434175614],
              [-39.999045178706332, -8.850461434175614],
              [-39.999045178706332, -8.750461434175614],
              [-39.899045178706331, -8.750461434175614],
              [-39.899045178706331, -8.838983295682464],
              [-39.900542746, -8.842021275999972],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '172',
        ID: 38635.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.8005,
        ANNUAL: 5670.0,
        JAN: 6193.0,
        FEB: 5934.0,
        MAR: 5962.0,
        APR: 5325.0,
        MAY: 4708.0,
        JUN: 4408.0,
        JUL: 4603.0,
        AUG: 5391.0,
        SEP: 6271.0,
        OCT: 6396.0,
        NOV: 6539.0,
        DEC: 6317.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.803592750999897, -8.818685814999984],
              [-39.814820281, -8.814604047999985],
              [-39.819970395, -8.813488400999974],
              [-39.822731892, -8.813515767999951],
              [-39.8304657689999, -8.814400654999924],
              [-39.844786772, -8.81824875099994],
              [-39.862900146999898, -8.819500632999963],
              [-39.886816634, -8.824991179999927],
              [-39.890415399999895, -8.826770844999936],
              [-39.891382157436198, -8.827540178076967],
              [-39.892957721999899, -8.828793991999934],
              [-39.89604694, -8.83290103799993],
              [-39.899045178706331, -8.838983295682464],
              [-39.899045178706331, -8.750461434175614],
              [-39.799045178706329, -8.750461434175614],
              [-39.799045178706329, -8.819484632870928],
              [-39.803592750999897, -8.818685814999984],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '173',
        ID: 38636.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.8005,
        ANNUAL: 5682.0,
        JAN: 6235.0,
        FEB: 5987.0,
        MAR: 5967.0,
        APR: 5341.0,
        MAY: 4736.0,
        JUN: 4426.0,
        JUL: 4585.0,
        AUG: 5367.0,
        SEP: 6257.0,
        OCT: 6387.0,
        NOV: 6574.0,
        DEC: 6327.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.700476889999898, -8.799952050999929],
              [-39.704185978999902, -8.800006252999934],
              [-39.7056649949999, -8.799460147999984],
              [-39.710163943999902, -8.797798977999943],
              [-39.713751373, -8.797358665999983],
              [-39.716864487999899, -8.797579530999936],
              [-39.721768380999897, -8.799015603999985],
              [-39.730277562, -8.796576993999954],
              [-39.738725248, -8.79487071299997],
              [-39.747785345, -8.794041011999974],
              [-39.749239293999899, -8.794846067999966],
              [-39.750351388999903, -8.796766060999969],
              [-39.757560084, -8.799187497999988],
              [-39.758576389, -8.799618103999933],
              [-39.758748470999898, -8.799691014999944],
              [-39.761751391, -8.800963346999941],
              [-39.762851816, -8.803439752999955],
              [-39.764421741, -8.804793576999936],
              [-39.775484352, -8.809112162999957],
              [-39.780841693, -8.813135615999943],
              [-39.782422627, -8.814322923999955],
              [-39.788796213, -8.819109593999933],
              [-39.792662682, -8.819890968999976],
              [-39.798402213, -8.819597574999928],
              [-39.799045178706329, -8.819484632870928],
              [-39.799045178706329, -8.750461434175614],
              [-39.699045178706328, -8.750461434175614],
              [-39.699045178706328, -8.79985013963802],
              [-39.700476889999898, -8.799952050999929],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '174',
        ID: 38637.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.8005,
        ANNUAL: 5677.0,
        JAN: 6203.0,
        FEB: 5970.0,
        MAR: 5940.0,
        APR: 5362.0,
        MAY: 4735.0,
        JUN: 4407.0,
        JUL: 4615.0,
        AUG: 5407.0,
        SEP: 6244.0,
        OCT: 6367.0,
        NOV: 6528.0,
        DEC: 6351.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.677469335, -8.755205696999942],
              [-39.678124655, -8.756957441999932],
              [-39.677501268, -8.760889045999933],
              [-39.676705274, -8.765909256999977],
              [-39.674903373999904, -8.772678649999936],
              [-39.671713559999901, -8.777581461999949],
              [-39.671838211, -8.780738319999955],
              [-39.673300375999901, -8.784903966999927],
              [-39.674629179, -8.787157204999966],
              [-39.675960352999901, -8.78828252699998],
              [-39.676795868999903, -8.78851710199995],
              [-39.679945050999898, -8.789401247999933],
              [-39.682954478999903, -8.791117787999957],
              [-39.686382030999901, -8.793072821999933],
              [-39.687024248999897, -8.793458826999936],
              [-39.688574358, -8.794390518999931],
              [-39.690650282, -8.795638251999957],
              [-39.693196365999903, -8.798675740999947],
              [-39.695128745, -8.799571361999934],
              [-39.699045178706328, -8.79985013963802],
              [-39.699045178706328, -8.750461434175614],
              [-39.675694525821022, -8.750461434175614],
              [-39.677469335, -8.755205696999942],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '175',
        ID: 38643.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.8005,
        ANNUAL: 5735.0,
        JAN: 6358.0,
        FEB: 6147.0,
        MAR: 6184.0,
        APR: 5512.0,
        MAY: 4717.0,
        JUN: 4251.0,
        JUL: 4499.0,
        AUG: 5229.0,
        SEP: 6151.0,
        OCT: 6449.0,
        NOV: 6711.0,
        DEC: 6618.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999377572999897, -8.761092341999932],
              [-39.005905035, -8.757887742999966],
              [-39.010713021999898, -8.756155034999946],
              [-39.01465775, -8.753867353999951],
              [-39.019137260440587, -8.750461434175614],
              [-38.999045178706318, -8.750461434175614],
              [-38.999045178706318, -8.761379918614681],
              [-38.999377572999897, -8.761092341999932],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '176',
        ID: 38644.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.8005,
        ANNUAL: 5746.0,
        JAN: 6383.0,
        FEB: 6281.0,
        MAR: 6225.0,
        APR: 5562.0,
        MAY: 4768.0,
        JUN: 4302.0,
        JUL: 4535.0,
        AUG: 5250.0,
        SEP: 6107.0,
        OCT: 6299.0,
        NOV: 6594.0,
        DEC: 6639.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.89938455, -8.791001131999964],
              [-38.903461496, -8.791941639999946],
              [-38.910239573999903, -8.793505268999972],
              [-38.916180928, -8.79319027999998],
              [-38.924937546, -8.794747505999965],
              [-38.927969141999903, -8.795767116999965],
              [-38.937162585, -8.80156637999994],
              [-38.940427741999898, -8.802900954999927],
              [-38.943692899, -8.804235530999955],
              [-38.9452309489999, -8.80446135499994],
              [-38.946768998, -8.804687178999933],
              [-38.9480918799999, -8.804682328999947],
              [-38.951933208999897, -8.804668246999942],
              [-38.952363877, -8.804538615999949],
              [-38.952844835, -8.804393824999973],
              [-38.953942742, -8.804063271999951],
              [-38.956467311, -8.802374672999971],
              [-38.962795719, -8.796014783999965],
              [-38.965132307, -8.794281896999957],
              [-38.967040800999897, -8.793664143999925],
              [-38.974526266999902, -8.794175437999966],
              [-38.977432044, -8.793086673999943],
              [-38.982496796, -8.785053230999948],
              [-38.982793281999903, -8.783839215999921],
              [-38.985691882, -8.780002305999968],
              [-38.988870692, -8.771689976999934],
              [-38.991911344999899, -8.767551876999962],
              [-38.999045178706318, -8.761379918614681],
              [-38.999045178706318, -8.750461434175614],
              [-38.899045178706317, -8.750461434175614],
              [-38.899045178706317, -8.790968710555356],
              [-38.89938455, -8.791001131999964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '177',
        ID: 38645.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.8005,
        ANNUAL: 5842.0,
        JAN: 6543.0,
        FEB: 6432.0,
        MAR: 6375.0,
        APR: 5710.0,
        MAY: 4895.0,
        JUN: 4413.0,
        JUL: 4682.0,
        AUG: 5388.0,
        SEP: 6116.0,
        OCT: 6306.0,
        NOV: 6554.0,
        DEC: 6684.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.804069855, -8.789181096999926],
              [-38.807078391999909, -8.787778213999966],
              [-38.807629574, -8.787682790999945],
              [-38.816304332, -8.786180976999958],
              [-38.821073903999903, -8.786066155999951],
              [-38.821403694, -8.786058216999949],
              [-38.821623955999897, -8.786052913999987],
              [-38.822274605, -8.786037250999936],
              [-38.8228635729999, -8.786023071999979],
              [-38.823126046, -8.786016752999961],
              [-38.823323812, -8.786011991999942],
              [-38.823523345, -8.786007188999969],
              [-38.830216431999901, -8.786383129999926],
              [-38.836976692999897, -8.785428437999947],
              [-38.849252271, -8.784812335999961],
              [-38.8522272659999, -8.784663022999958],
              [-38.85477586, -8.784535110999968],
              [-38.854895014, -8.784529130999948],
              [-38.854933414, -8.784527204999955],
              [-38.854998716999901, -8.784523927999937],
              [-38.855065001999897, -8.78452059199998],
              [-38.855381286999901, -8.784504698999971],
              [-38.856050206, -8.784471086999924],
              [-38.856994911, -8.784423616999957],
              [-38.867552968, -8.783893092999959],
              [-38.86810442, -8.783865383999974],
              [-38.876405516, -8.786177023999926],
              [-38.884769562, -8.789604905999978],
              [-38.899045178706317, -8.790968710555356],
              [-38.899045178706317, -8.750461434175614],
              [-38.799045178706315, -8.750461434175614],
              [-38.799045178706315, -8.79222080205399],
              [-38.804069855, -8.789181096999926],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '178',
        ID: 38646.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.8005,
        ANNUAL: 5821.0,
        JAN: 6515.0,
        FEB: 6396.0,
        MAR: 6334.0,
        APR: 5679.0,
        MAY: 4898.0,
        JUN: 4505.0,
        JUL: 4754.0,
        AUG: 5389.0,
        SEP: 6106.0,
        OCT: 6217.0,
        NOV: 6458.0,
        DEC: 6603.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.725072739, -8.850271571999931],
              [-38.730187656999902, -8.847439933999951],
              [-38.739683651, -8.844847688999957],
              [-38.745437701, -8.838945277999926],
              [-38.750526078, -8.836342359999954],
              [-38.756512078999897, -8.831323650999924],
              [-38.756653723999896, -8.831204893999939],
              [-38.756734372, -8.831137260999988],
              [-38.756736126, -8.831135790999943],
              [-38.757725435999902, -8.830306143999966],
              [-38.758114725, -8.829979680999932],
              [-38.758393678999902, -8.829727476999958],
              [-38.762552859, -8.825967217999962],
              [-38.765605187999903, -8.821696540999945],
              [-38.771465006, -8.817291274999954],
              [-38.774690418, -8.812072427999963],
              [-38.781128786999901, -8.807027215999938],
              [-38.7840754859999, -8.804718133999936],
              [-38.784984912, -8.803848667999935],
              [-38.787212967999899, -8.801718513999958],
              [-38.788715499, -8.799658086999953],
              [-38.796989456999903, -8.793464421999932],
              [-38.799045178706315, -8.79222080205399],
              [-38.799045178706315, -8.750461434175614],
              [-38.699045178706314, -8.750461434175614],
              [-38.699045178706314, -8.850461434175614],
              [-38.724546940356852, -8.850461434175614],
              [-38.725072739, -8.850271571999931],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '179',
        ID: 38647.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.8005,
        ANNUAL: 5816.0,
        JAN: 6528.0,
        FEB: 6330.0,
        MAR: 6318.0,
        APR: 5665.0,
        MAY: 4879.0,
        JUN: 4520.0,
        JUL: 4765.0,
        AUG: 5366.0,
        SEP: 6098.0,
        OCT: 6238.0,
        NOV: 6510.0,
        DEC: 6576.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.850461434175614],
              [-38.699045178706314, -8.750461434175614],
              [-38.599045178706312, -8.750461434175614],
              [-38.599045178706312, -8.850461434175614],
              [-38.699045178706314, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '180',
        ID: 38648.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.8005,
        ANNUAL: 5830.0,
        JAN: 6537.0,
        FEB: 6258.0,
        MAR: 6310.0,
        APR: 5730.0,
        MAY: 4849.0,
        JUN: 4479.0,
        JUL: 4731.0,
        AUG: 5374.0,
        SEP: 6168.0,
        OCT: 6362.0,
        NOV: 6587.0,
        DEC: 6571.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.50243949, -8.835356244999959],
              [-38.506442444999898, -8.833235180999953],
              [-38.512406703, -8.830585949999941],
              [-38.51685308, -8.829283344999956],
              [-38.530932966, -8.826409947999926],
              [-38.541139577, -8.823486705999924],
              [-38.548214567999899, -8.82273510299996],
              [-38.55184273, -8.822629404999921],
              [-38.555067822, -8.823357782999949],
              [-38.562550944999899, -8.826346159999961],
              [-38.570428631, -8.830369085999953],
              [-38.572845387, -8.832348964999939],
              [-38.576965312, -8.837128043999943],
              [-38.580237982, -8.842712880999949],
              [-38.585681014618444, -8.850461434175614],
              [-38.599045178706312, -8.850461434175614],
              [-38.599045178706312, -8.750461434175614],
              [-38.499045178706311, -8.750461434175614],
              [-38.499045178706311, -8.837871228102516],
              [-38.50243949, -8.835356244999959],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '181',
        ID: 38649.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.8005,
        ANNUAL: 5778.0,
        JAN: 6485.0,
        FEB: 6244.0,
        MAR: 6240.0,
        APR: 5628.0,
        MAY: 4749.0,
        JUN: 4329.0,
        JUL: 4601.0,
        AUG: 5288.0,
        SEP: 6189.0,
        OCT: 6410.0,
        NOV: 6651.0,
        DEC: 6523.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.479834465, -8.850088899999955],
              [-38.480809824999902, -8.849326121999979],
              [-38.495600881, -8.839827217999927],
              [-38.4986584379999, -8.838157779999952],
              [-38.499045178706311, -8.837871228102516],
              [-38.499045178706311, -8.750461434175614],
              [-38.399045178706309, -8.750461434175614],
              [-38.399045178706309, -8.850461434175614],
              [-38.479493829804419, -8.850461434175614],
              [-38.479834465, -8.850088899999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '182',
        ID: 38650.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.8005,
        ANNUAL: 5666.0,
        JAN: 6353.0,
        FEB: 6119.0,
        MAR: 6113.0,
        APR: 5482.0,
        MAY: 4636.0,
        JUN: 4185.0,
        JUL: 4428.0,
        AUG: 5135.0,
        SEP: 6077.0,
        OCT: 6358.0,
        NOV: 6649.0,
        DEC: 6455.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.850461434175614],
              [-38.399045178706309, -8.750461434175614],
              [-38.299045178706308, -8.750461434175614],
              [-38.299045178706308, -8.850461434175614],
              [-38.399045178706309, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '183',
        ID: 38651.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.8005,
        ANNUAL: 5674.0,
        JAN: 6283.0,
        FEB: 6071.0,
        MAR: 6071.0,
        APR: 5467.0,
        MAY: 4669.0,
        JUN: 4209.0,
        JUL: 4435.0,
        AUG: 5191.0,
        SEP: 6111.0,
        OCT: 6391.0,
        NOV: 6721.0,
        DEC: 6466.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.850461434175614],
              [-38.299045178706308, -8.750461434175614],
              [-38.199045178706307, -8.750461434175614],
              [-38.199045178706307, -8.850461434175614],
              [-38.299045178706308, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '184',
        ID: 38652.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.8005,
        ANNUAL: 5667.0,
        JAN: 6297.0,
        FEB: 6089.0,
        MAR: 6086.0,
        APR: 5440.0,
        MAY: 4660.0,
        JUN: 4171.0,
        JUL: 4397.0,
        AUG: 5155.0,
        SEP: 6110.0,
        OCT: 6371.0,
        NOV: 6763.0,
        DEC: 6466.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.850461434175614],
              [-38.199045178706307, -8.750461434175614],
              [-38.099045178706305, -8.750461434175614],
              [-38.099045178706305, -8.850461434175614],
              [-38.199045178706307, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '185',
        ID: 38653.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.8005,
        ANNUAL: 5659.0,
        JAN: 6322.0,
        FEB: 6125.0,
        MAR: 6117.0,
        APR: 5443.0,
        MAY: 4629.0,
        JUN: 4099.0,
        JUL: 4334.0,
        AUG: 5102.0,
        SEP: 6080.0,
        OCT: 6398.0,
        NOV: 6781.0,
        DEC: 6471.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.850461434175614],
              [-38.099045178706305, -8.750461434175614],
              [-37.999045178706304, -8.750461434175614],
              [-37.999045178706304, -8.850461434175614],
              [-38.099045178706305, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '186',
        ID: 38654.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.8005,
        ANNUAL: 5645.0,
        JAN: 6433.0,
        FEB: 6062.0,
        MAR: 6172.0,
        APR: 5532.0,
        MAY: 4605.0,
        JUN: 4064.0,
        JUL: 4283.0,
        AUG: 5026.0,
        SEP: 6018.0,
        OCT: 6333.0,
        NOV: 6708.0,
        DEC: 6510.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.850461434175614],
              [-37.999045178706304, -8.750461434175614],
              [-37.899045178706302, -8.750461434175614],
              [-37.899045178706302, -8.850461434175614],
              [-37.999045178706304, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '187',
        ID: 38655.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.8005,
        ANNUAL: 5621.0,
        JAN: 6403.0,
        FEB: 6052.0,
        MAR: 6138.0,
        APR: 5542.0,
        MAY: 4554.0,
        JUN: 4039.0,
        JUL: 4214.0,
        AUG: 4951.0,
        SEP: 5971.0,
        OCT: 6342.0,
        NOV: 6720.0,
        DEC: 6524.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.850461434175614],
              [-37.899045178706302, -8.750461434175614],
              [-37.799045178706301, -8.750461434175614],
              [-37.799045178706301, -8.850461434175614],
              [-37.899045178706302, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '188',
        ID: 38656.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.8005,
        ANNUAL: 5614.0,
        JAN: 6348.0,
        FEB: 6092.0,
        MAR: 6199.0,
        APR: 5534.0,
        MAY: 4501.0,
        JUN: 4037.0,
        JUL: 4202.0,
        AUG: 4932.0,
        SEP: 5937.0,
        OCT: 6345.0,
        NOV: 6731.0,
        DEC: 6516.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.754685281, -8.848825713999918],
              [-37.75536189, -8.846363684999977],
              [-37.755488107, -8.846384126999956],
              [-37.757052726, -8.847066475999942],
              [-37.7584493479999, -8.847284645999929],
              [-37.759417692999897, -8.84781578399998],
              [-37.760300555999898, -8.848021637999986],
              [-37.760999995, -8.848084568999974],
              [-37.761584515, -8.849022093999967],
              [-37.762039111, -8.849540920999972],
              [-37.762965763999901, -8.850375843999929],
              [-37.763151376351935, -8.850461434175614],
              [-37.799045178706301, -8.850461434175614],
              [-37.799045178706301, -8.750461434175614],
              [-37.699045178706299, -8.750461434175614],
              [-37.699045178706299, -8.850461434175614],
              [-37.754394485861674, -8.850461434175614],
              [-37.754685281, -8.848825713999918],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '189',
        ID: 38657.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.8005,
        ANNUAL: 5675.0,
        JAN: 6394.0,
        FEB: 6221.0,
        MAR: 6203.0,
        APR: 5570.0,
        MAY: 4603.0,
        JUN: 4122.0,
        JUL: 4331.0,
        AUG: 5056.0,
        SEP: 6000.0,
        OCT: 6385.0,
        NOV: 6705.0,
        DEC: 6503.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.850461434175614],
              [-37.699045178706299, -8.750461434175614],
              [-37.599045178706298, -8.750461434175614],
              [-37.599045178706298, -8.850461434175614],
              [-37.699045178706299, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '190',
        ID: 38658.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.8005,
        ANNUAL: 5675.0,
        JAN: 6379.0,
        FEB: 6149.0,
        MAR: 6169.0,
        APR: 5544.0,
        MAY: 4610.0,
        JUN: 4155.0,
        JUL: 4386.0,
        AUG: 5125.0,
        SEP: 6016.0,
        OCT: 6385.0,
        NOV: 6715.0,
        DEC: 6462.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.850461434175614],
              [-37.599045178706298, -8.750461434175614],
              [-37.499045178706297, -8.750461434175614],
              [-37.499045178706297, -8.850461434175614],
              [-37.599045178706298, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '191',
        ID: 38659.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.8005,
        ANNUAL: 5704.0,
        JAN: 6384.0,
        FEB: 6138.0,
        MAR: 6141.0,
        APR: 5562.0,
        MAY: 4659.0,
        JUN: 4161.0,
        JUL: 4416.0,
        AUG: 5200.0,
        SEP: 6092.0,
        OCT: 6412.0,
        NOV: 6781.0,
        DEC: 6508.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.850461434175614],
              [-37.499045178706297, -8.750461434175614],
              [-37.399045178706295, -8.750461434175614],
              [-37.399045178706295, -8.850461434175614],
              [-37.499045178706297, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '192',
        ID: 38660.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.8005,
        ANNUAL: 5634.0,
        JAN: 6343.0,
        FEB: 6147.0,
        MAR: 6199.0,
        APR: 5550.0,
        MAY: 4515.0,
        JUN: 4015.0,
        JUL: 4180.0,
        AUG: 4956.0,
        SEP: 5986.0,
        OCT: 6361.0,
        NOV: 6779.0,
        DEC: 6579.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.850461434175614],
              [-37.399045178706295, -8.750461434175614],
              [-37.299045178706294, -8.750461434175614],
              [-37.299045178706294, -8.850461434175614],
              [-37.399045178706295, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '193',
        ID: 38661.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.8005,
        ANNUAL: 5563.0,
        JAN: 6278.0,
        FEB: 6124.0,
        MAR: 6170.0,
        APR: 5503.0,
        MAY: 4457.0,
        JUN: 3956.0,
        JUL: 4108.0,
        AUG: 4858.0,
        SEP: 5851.0,
        OCT: 6239.0,
        NOV: 6691.0,
        DEC: 6515.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.850461434175614],
              [-37.299045178706294, -8.750461434175614],
              [-37.199045178706292, -8.750461434175614],
              [-37.199045178706292, -8.850461434175614],
              [-37.299045178706294, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '194',
        ID: 38662.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.8005,
        ANNUAL: 5503.0,
        JAN: 6197.0,
        FEB: 6045.0,
        MAR: 6102.0,
        APR: 5402.0,
        MAY: 4424.0,
        JUN: 3923.0,
        JUL: 4084.0,
        AUG: 4808.0,
        SEP: 5772.0,
        OCT: 6185.0,
        NOV: 6639.0,
        DEC: 6452.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -8.850461434175614],
              [-37.199045178706292, -8.750461434175614],
              [-37.099045178706291, -8.750461434175614],
              [-37.099045178706291, -8.850461434175614],
              [-37.199045178706292, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '195',
        ID: 38663.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.8005,
        ANNUAL: 5513.0,
        JAN: 6216.0,
        FEB: 6060.0,
        MAR: 6034.0,
        APR: 5408.0,
        MAY: 4433.0,
        JUN: 3951.0,
        JUL: 4177.0,
        AUG: 4895.0,
        SEP: 5759.0,
        OCT: 6180.0,
        NOV: 6648.0,
        DEC: 6401.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -8.850461434175614],
              [-37.099045178706291, -8.750461434175614],
              [-36.99904517870629, -8.750461434175614],
              [-36.99904517870629, -8.850461434175614],
              [-37.099045178706291, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '196',
        ID: 38664.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.8005,
        ANNUAL: 5502.0,
        JAN: 6169.0,
        FEB: 6004.0,
        MAR: 6029.0,
        APR: 5409.0,
        MAY: 4436.0,
        JUN: 3973.0,
        JUL: 4188.0,
        AUG: 4867.0,
        SEP: 5766.0,
        OCT: 6169.0,
        NOV: 6648.0,
        DEC: 6364.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -8.850461434175614],
              [-36.99904517870629, -8.750461434175614],
              [-36.899045178706288, -8.750461434175614],
              [-36.899045178706288, -8.850461434175614],
              [-36.99904517870629, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '197',
        ID: 38665.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.8005,
        ANNUAL: 5464.0,
        JAN: 6152.0,
        FEB: 5965.0,
        MAR: 5946.0,
        APR: 5345.0,
        MAY: 4394.0,
        JUN: 3939.0,
        JUL: 4117.0,
        AUG: 4786.0,
        SEP: 5768.0,
        OCT: 6164.0,
        NOV: 6648.0,
        DEC: 6344.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -8.850461434175614],
              [-36.899045178706288, -8.750461434175614],
              [-36.799045178706287, -8.750461434175614],
              [-36.799045178706287, -8.850461434175614],
              [-36.899045178706288, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '198',
        ID: 38666.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.8005,
        ANNUAL: 5543.0,
        JAN: 6253.0,
        FEB: 5979.0,
        MAR: 6018.0,
        APR: 5368.0,
        MAY: 4439.0,
        JUN: 4029.0,
        JUL: 4251.0,
        AUG: 4925.0,
        SEP: 5887.0,
        OCT: 6228.0,
        NOV: 6669.0,
        DEC: 6473.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -8.850461434175614],
              [-36.799045178706287, -8.750461434175614],
              [-36.699045178706285, -8.750461434175614],
              [-36.699045178706285, -8.850461434175614],
              [-36.799045178706287, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '199',
        ID: 38667.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.8005,
        ANNUAL: 5442.0,
        JAN: 6165.0,
        FEB: 5947.0,
        MAR: 5986.0,
        APR: 5328.0,
        MAY: 4379.0,
        JUN: 3918.0,
        JUL: 4122.0,
        AUG: 4804.0,
        SEP: 5691.0,
        OCT: 6049.0,
        NOV: 6501.0,
        DEC: 6411.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -8.850461434175614],
              [-36.699045178706285, -8.750461434175614],
              [-36.599045178706284, -8.750461434175614],
              [-36.599045178706284, -8.850461434175614],
              [-36.699045178706285, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '200',
        ID: 38668.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.8005,
        ANNUAL: 5288.0,
        JAN: 5965.0,
        FEB: 5768.0,
        MAR: 5821.0,
        APR: 5227.0,
        MAY: 4321.0,
        JUN: 3792.0,
        JUL: 3988.0,
        AUG: 4681.0,
        SEP: 5495.0,
        OCT: 5820.0,
        NOV: 6337.0,
        DEC: 6245.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.850461434175614],
              [-36.599045178706284, -8.750461434175614],
              [-36.499045178706282, -8.750461434175614],
              [-36.499045178706282, -8.850461434175614],
              [-36.599045178706284, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '201',
        ID: 38669.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.8005,
        ANNUAL: 5300.0,
        JAN: 6037.0,
        FEB: 5817.0,
        MAR: 5856.0,
        APR: 5191.0,
        MAY: 4360.0,
        JUN: 3891.0,
        JUL: 4023.0,
        AUG: 4638.0,
        SEP: 5487.0,
        OCT: 5783.0,
        NOV: 6288.0,
        DEC: 6229.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.850461434175614],
              [-36.499045178706282, -8.750461434175614],
              [-36.399045178706281, -8.750461434175614],
              [-36.399045178706281, -8.850461434175614],
              [-36.499045178706282, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '202',
        ID: 38670.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.8005,
        ANNUAL: 5274.0,
        JAN: 6009.0,
        FEB: 5809.0,
        MAR: 5823.0,
        APR: 5155.0,
        MAY: 4348.0,
        JUN: 3886.0,
        JUL: 4016.0,
        AUG: 4627.0,
        SEP: 5477.0,
        OCT: 5750.0,
        NOV: 6233.0,
        DEC: 6155.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.850461434175614],
              [-36.399045178706281, -8.750461434175614],
              [-36.29904517870628, -8.750461434175614],
              [-36.29904517870628, -8.850461434175614],
              [-36.399045178706281, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '203',
        ID: 38671.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.8005,
        ANNUAL: 5258.0,
        JAN: 5816.0,
        FEB: 5780.0,
        MAR: 5814.0,
        APR: 5176.0,
        MAY: 4288.0,
        JUN: 3879.0,
        JUL: 4021.0,
        AUG: 4669.0,
        SEP: 5491.0,
        OCT: 5774.0,
        NOV: 6238.0,
        DEC: 6152.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.850461434175614],
              [-36.29904517870628, -8.750461434175614],
              [-36.199045178706278, -8.750461434175614],
              [-36.199045178706278, -8.850461434175614],
              [-36.29904517870628, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '204',
        ID: 38672.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.8005,
        ANNUAL: 5190.0,
        JAN: 5803.0,
        FEB: 5743.0,
        MAR: 5768.0,
        APR: 5083.0,
        MAY: 4243.0,
        JUN: 3814.0,
        JUL: 3885.0,
        AUG: 4515.0,
        SEP: 5387.0,
        OCT: 5720.0,
        NOV: 6173.0,
        DEC: 6149.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.850461434175614],
              [-36.199045178706278, -8.750461434175614],
              [-36.099045178706277, -8.750461434175614],
              [-36.099045178706277, -8.850461434175614],
              [-36.199045178706278, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '205',
        ID: 38673.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.8005,
        ANNUAL: 5067.0,
        JAN: 5717.0,
        FEB: 5618.0,
        MAR: 5657.0,
        APR: 4981.0,
        MAY: 4133.0,
        JUN: 3716.0,
        JUL: 3736.0,
        AUG: 4344.0,
        SEP: 5223.0,
        OCT: 5580.0,
        NOV: 6042.0,
        DEC: 6063.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.850461434175614],
              [-36.099045178706277, -8.750461434175614],
              [-35.999045178706275, -8.750461434175614],
              [-35.999045178706275, -8.850461434175614],
              [-36.099045178706277, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '206',
        ID: 38674.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.8005,
        ANNUAL: 5095.0,
        JAN: 5765.0,
        FEB: 5656.0,
        MAR: 5705.0,
        APR: 5046.0,
        MAY: 4131.0,
        JUN: 3725.0,
        JUL: 3822.0,
        AUG: 4421.0,
        SEP: 5240.0,
        OCT: 5543.0,
        NOV: 6022.0,
        DEC: 6060.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.850461434175614],
              [-35.999045178706275, -8.750461434175614],
              [-35.899045178706274, -8.750461434175614],
              [-35.899045178706274, -8.850461434175614],
              [-35.999045178706275, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '207',
        ID: 38675.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.8005,
        ANNUAL: 5063.0,
        JAN: 5648.0,
        FEB: 5616.0,
        MAR: 5656.0,
        APR: 5031.0,
        MAY: 4181.0,
        JUN: 3739.0,
        JUL: 3839.0,
        AUG: 4425.0,
        SEP: 5234.0,
        OCT: 5481.0,
        NOV: 5966.0,
        DEC: 5940.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799564486, -8.850294027999954],
              [-35.799607692963363, -8.850461434175614],
              [-35.899045178706274, -8.850461434175614],
              [-35.899045178706274, -8.750461434175614],
              [-35.799045178706272, -8.750461434175614],
              [-35.799045178706272, -8.850229186827397],
              [-35.799564486, -8.850294027999954],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '208',
        ID: 38676.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.8005,
        ANNUAL: 5078.0,
        JAN: 5612.0,
        FEB: 5642.0,
        MAR: 5646.0,
        APR: 4996.0,
        MAY: 4227.0,
        JUN: 3805.0,
        JUL: 3888.0,
        AUG: 4483.0,
        SEP: 5253.0,
        OCT: 5499.0,
        NOV: 5971.0,
        DEC: 5916.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.793610567, -8.850269195999934],
              [-35.795075238, -8.847685124999943],
              [-35.7962097079999, -8.84739971399995],
              [-35.796835993999899, -8.847871936999976],
              [-35.797752004, -8.850067897999965],
              [-35.797756521999901, -8.850068283999967],
              [-35.799045178706272, -8.850229186827397],
              [-35.799045178706272, -8.750461434175614],
              [-35.699045178706271, -8.750461434175614],
              [-35.699045178706271, -8.850461434175614],
              [-35.793427807536816, -8.850461434175614],
              [-35.793610567, -8.850269195999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '209',
        ID: 38677.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.8005,
        ANNUAL: 5087.0,
        JAN: 5634.0,
        FEB: 5591.0,
        MAR: 5647.0,
        APR: 5002.0,
        MAY: 4223.0,
        JUN: 3880.0,
        JUL: 3917.0,
        AUG: 4547.0,
        SEP: 5288.0,
        OCT: 5496.0,
        NOV: 5926.0,
        DEC: 5894.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.850461434175614],
              [-35.699045178706271, -8.750461434175614],
              [-35.59904517870627, -8.750461434175614],
              [-35.59904517870627, -8.850461434175614],
              [-35.699045178706271, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '210',
        ID: 38678.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.8005,
        ANNUAL: 5155.0,
        JAN: 5686.0,
        FEB: 5648.0,
        MAR: 5735.0,
        APR: 5008.0,
        MAY: 4232.0,
        JUN: 3930.0,
        JUL: 3961.0,
        AUG: 4608.0,
        SEP: 5397.0,
        OCT: 5633.0,
        NOV: 6058.0,
        DEC: 5967.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499293813999898, -8.826578290999981],
              [-35.500349991, -8.824963289999976],
              [-35.5003750549999, -8.820167155999968],
              [-35.500381408, -8.820167766999932],
              [-35.501021889, -8.820260935999958],
              [-35.501027297999897, -8.820261454999983],
              [-35.502665403, -8.822431096999942],
              [-35.504257923, -8.822298783999935],
              [-35.505960251, -8.821230680999962],
              [-35.507144771999897, -8.818623521999944],
              [-35.511520162999901, -8.817535038999955],
              [-35.511522641999903, -8.81753603299995],
              [-35.511525612, -8.817535294999967],
              [-35.513992882, -8.818525075999951],
              [-35.514596013999899, -8.817523227999967],
              [-35.514600752, -8.817524662999965],
              [-35.514601462, -8.817523482999956],
              [-35.515918853, -8.817922263999952],
              [-35.516695844, -8.816935194999932],
              [-35.517770961999901, -8.816757845999961],
              [-35.518627707999897, -8.818692073999962],
              [-35.52049413, -8.820394242999948],
              [-35.526984657, -8.823975194999949],
              [-35.528825606999909, -8.827322106999926],
              [-35.530121645, -8.828082164999953],
              [-35.530127056, -8.828082676999937],
              [-35.531445315, -8.828270527999965],
              [-35.532797982, -8.827081011999944],
              [-35.533852853, -8.823878261999941],
              [-35.534110145999897, -8.821936944999953],
              [-35.534623369, -8.819920614999942],
              [-35.5346700979999, -8.819881359999956],
              [-35.5350460679999, -8.819505452999987],
              [-35.535173203999904, -8.819458730999946],
              [-35.535177909, -8.819454778999953],
              [-35.535182636999899, -8.819455262999947],
              [-35.536795765, -8.819620650999983],
              [-35.536802086, -8.819621213999945],
              [-35.537137946, -8.820203549999963],
              [-35.538705222, -8.820223728999959],
              [-35.538710549, -8.820223842999944],
              [-35.539816829, -8.821081778999933],
              [-35.539818692, -8.821086945999978],
              [-35.5423749509999, -8.823068790999969],
              [-35.542633332, -8.82386725699997],
              [-35.543692054, -8.824092493999952],
              [-35.543697454, -8.824093627999959],
              [-35.544109635, -8.825657343999985],
              [-35.544959859, -8.824641074999931],
              [-35.545350674, -8.824527583999949],
              [-35.545352659999899, -8.824528843999925],
              [-35.546734436999898, -8.825405433999947],
              [-35.546857012, -8.825765175999946],
              [-35.5463664829999, -8.827110004999945],
              [-35.546640297, -8.827590522999968],
              [-35.54832005, -8.828322906999965],
              [-35.552030773999903, -8.829010824999957],
              [-35.552032637, -8.829010413999978],
              [-35.552036222, -8.829011079999933],
              [-35.553702875999903, -8.828645221999984],
              [-35.554982217, -8.829296255999958],
              [-35.555901258999903, -8.830822546999965],
              [-35.556967605, -8.831484034999963],
              [-35.557879566999901, -8.832050439999929],
              [-35.557883624999903, -8.832049832999939],
              [-35.558134728, -8.832012256999965],
              [-35.559239245, -8.83184750899995],
              [-35.559244295, -8.83184728999993],
              [-35.560019536, -8.832774914999963],
              [-35.561619086999897, -8.831382159999988],
              [-35.5616243119999, -8.831382607999965],
              [-35.561771746, -8.831401526999969],
              [-35.5618136269999, -8.831406902999966],
              [-35.561911279, -8.831419434999935],
              [-35.5636788219999, -8.831646261999946],
              [-35.563797373999897, -8.831783],
              [-35.563823729, -8.832881664999949],
              [-35.565505959999903, -8.834504863999939],
              [-35.565951674, -8.834934938999941],
              [-35.566795398999901, -8.835200120999962],
              [-35.566799333999903, -8.835199899999964],
              [-35.566800826, -8.835200003999937],
              [-35.567331423, -8.83517047599997],
              [-35.567481847, -8.835444159999952],
              [-35.568051576, -8.839356849999941],
              [-35.568103816, -8.839715620999929],
              [-35.568114932, -8.839791963999964],
              [-35.568169671, -8.84016788899994],
              [-35.5681858519999, -8.840279014999961],
              [-35.568211327, -8.840453967999958],
              [-35.568381815999899, -8.841624823999942],
              [-35.568766175999897, -8.842155614999971],
              [-35.568769733, -8.842153258999923],
              [-35.568930284, -8.842374975999977],
              [-35.570171068, -8.842789473999977],
              [-35.571398733, -8.841984053999974],
              [-35.571404161, -8.841984013999927],
              [-35.572277713999902, -8.841929971999949],
              [-35.575503962, -8.843339252999954],
              [-35.575509406, -8.843339566999934],
              [-35.577680686999898, -8.843488497999941],
              [-35.580648324, -8.84459408499993],
              [-35.58252341, -8.846467753999951],
              [-35.582524035999903, -8.846466193999955],
              [-35.585914223, -8.847469712999951],
              [-35.58799857, -8.848883932999968],
              [-35.588921389594866, -8.850461434175614],
              [-35.59904517870627, -8.850461434175614],
              [-35.59904517870627, -8.750461434175614],
              [-35.499045178706268, -8.750461434175614],
              [-35.499045178706268, -8.826715786614761],
              [-35.499293813999898, -8.826578290999981],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '211',
        ID: 38679.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.8005,
        ANNUAL: 5185.0,
        JAN: 5706.0,
        FEB: 5691.0,
        MAR: 5754.0,
        APR: 5012.0,
        MAY: 4236.0,
        JUN: 3971.0,
        JUL: 3996.0,
        AUG: 4640.0,
        SEP: 5436.0,
        OCT: 5663.0,
        NOV: 6113.0,
        DEC: 5998.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399991622, -8.850312811999967],
              [-35.401785887, -8.848784671999971],
              [-35.405555901999897, -8.844002525999946],
              [-35.405662139, -8.84391480299996],
              [-35.405802821999899, -8.843798636999955],
              [-35.407510133, -8.842388856999944],
              [-35.40784847, -8.841341839999934],
              [-35.406887943999898, -8.838419133999929],
              [-35.409021641, -8.83798844399996],
              [-35.409525033, -8.837886832999965],
              [-35.411225583, -8.836230962999929],
              [-35.414077563, -8.836397020999925],
              [-35.414331702, -8.836411852999959],
              [-35.415616182999898, -8.838390218999951],
              [-35.415748989, -8.838444932999947],
              [-35.415751293, -8.838443786999969],
              [-35.416694529, -8.838834248999939],
              [-35.418474865999897, -8.838568946999931],
              [-35.418811672999901, -8.836918387999958],
              [-35.419384818, -8.836589915999973],
              [-35.419391167999905, -8.836589998999957],
              [-35.422087431, -8.836533399999952],
              [-35.423165303, -8.838299488999958],
              [-35.423864529, -8.838523550999986],
              [-35.423866743, -8.838522523999933],
              [-35.426792001, -8.837164636999939],
              [-35.426798349, -8.83716469399997],
              [-35.429165698, -8.837095093999945],
              [-35.429171139999902, -8.837095142999937],
              [-35.43191562, -8.836009115999966],
              [-35.434808255999897, -8.840248037999972],
              [-35.435871447999901, -8.840930867999932],
              [-35.435876848, -8.840931423999962],
              [-35.439818439, -8.841561643999968],
              [-35.439824395, -8.841562237999948],
              [-35.441522318, -8.840315169999942],
              [-35.444173034999899, -8.84139755399997],
              [-35.446598713, -8.841284337999985],
              [-35.446600429999897, -8.841284427999938],
              [-35.447461048, -8.841498413999938],
              [-35.447465473, -8.841499803999971],
              [-35.449478546, -8.842000695999966],
              [-35.449483184, -8.842001852999932],
              [-35.449733538, -8.841877014999968],
              [-35.450570887, -8.841459473999972],
              [-35.450814568999903, -8.841337961999953],
              [-35.451561356999903, -8.84096527199995],
              [-35.452097163, -8.840697872999955],
              [-35.45345176, -8.841224558999949],
              [-35.45345609000001, -8.841224069999956],
              [-35.453458116, -8.841224857999988],
              [-35.453522069, -8.841217651999955],
              [-35.455518454999897, -8.840992706999932],
              [-35.455818316, -8.840958919999935],
              [-35.455935216999897, -8.840884283999962],
              [-35.45651337, -8.840515162999957],
              [-35.458528504, -8.839228604999967],
              [-35.458966548, -8.838948936999943],
              [-35.459039538, -8.838902336999974],
              [-35.459091132, -8.838869396999936],
              [-35.459187588, -8.838726012999924],
              [-35.459295042, -8.838566280999943],
              [-35.459431718, -8.838363108999939],
              [-35.459785915999902, -8.837836584999936],
              [-35.459827679999897, -8.83777450099995],
              [-35.459971919999902, -8.837111624999984],
              [-35.460499905999903, -8.834685190999952],
              [-35.460217719999903, -8.832292253999979],
              [-35.461377872999897, -8.830607694999966],
              [-35.461842374, -8.83016117799998],
              [-35.4632330049999, -8.828824057999958],
              [-35.463886331, -8.828589664999987],
              [-35.4638886299999, -8.828588840999947],
              [-35.465202382, -8.829039566999935],
              [-35.466756883999899, -8.828263897999928],
              [-35.46541479, -8.827411311999985],
              [-35.465137246, -8.826229934999963],
              [-35.463557221, -8.825737258999933],
              [-35.462876940999898, -8.823877478999973],
              [-35.464226207, -8.821365735999958],
              [-35.464463108, -8.820214563999977],
              [-35.462339364999899, -8.818830283999944],
              [-35.464294138999897, -8.816364163999941],
              [-35.464297278999901, -8.816367330999981],
              [-35.465163554999897, -8.817240970999936],
              [-35.466584754999907, -8.817636576999963],
              [-35.466587741999902, -8.817635937999972],
              [-35.46752527, -8.817435348999936],
              [-35.467687379, -8.816974588999923],
              [-35.466898778, -8.815289674999974],
              [-35.467585549, -8.814026140999946],
              [-35.470124692, -8.81312673199994],
              [-35.470125159, -8.813129116999953],
              [-35.470700795, -8.816056144999957],
              [-35.472226753, -8.817155778999965],
              [-35.472231049999898, -8.817156093999929],
              [-35.472232156, -8.817156892999956],
              [-35.4743646269999, -8.817313449999965],
              [-35.474794419, -8.817345074999926],
              [-35.47479986, -8.817345402999923],
              [-35.476014622, -8.818138762999979],
              [-35.476014157, -8.818141776999939],
              [-35.476211942, -8.818270952999971],
              [-35.4781982229999, -8.819568722999964],
              [-35.480582055999903, -8.819934588999958],
              [-35.481102923, -8.821023268999975],
              [-35.480832722999899, -8.823667743999982],
              [-35.482123422, -8.826152161999973],
              [-35.482128847, -8.826152373999946],
              [-35.48614894, -8.826277506999928],
              [-35.486155193999899, -8.826277726999932],
              [-35.4881932669999, -8.827356071999933],
              [-35.489622724999897, -8.827231820999938],
              [-35.493473347, -8.825328710999944],
              [-35.493477248, -8.825330221999934],
              [-35.49454322, -8.825743154999927],
              [-35.49575369, -8.828302315999961],
              [-35.495758405999901, -8.828301021999952],
              [-35.496327000999898, -8.828144944999954],
              [-35.497320163, -8.826322943999978],
              [-35.497325625, -8.826324883999968],
              [-35.498802908999899, -8.82684976199994],
              [-35.498804758999903, -8.826848738999958],
              [-35.499045178706268, -8.826715786614761],
              [-35.499045178706268, -8.750461434175614],
              [-35.399045178706267, -8.750461434175614],
              [-35.399045178706267, -8.850406281561078],
              [-35.399991622, -8.850312811999967],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '212',
        ID: 38680.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.8005,
        ANNUAL: 5233.0,
        JAN: 5767.0,
        FEB: 5824.0,
        MAR: 5840.0,
        APR: 5014.0,
        MAY: 4279.0,
        JUN: 3979.0,
        JUL: 4046.0,
        AUG: 4715.0,
        SEP: 5436.0,
        OCT: 5715.0,
        NOV: 6109.0,
        DEC: 6071.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.394570326999897, -8.849835992999935],
              [-35.39768463140129, -8.850461434175614],
              [-35.398486720727654, -8.850461434175614],
              [-35.399045178706267, -8.850406281561078],
              [-35.399045178706267, -8.750461434175614],
              [-35.299045178706265, -8.750461434175614],
              [-35.299045178706265, -8.850461434175614],
              [-35.39205085414865, -8.850461434175614],
              [-35.394570326999897, -8.849835992999935],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '213',
        ID: 38681.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.8005,
        ANNUAL: 5277.0,
        JAN: 5798.0,
        FEB: 5884.0,
        MAR: 5956.0,
        APR: 5037.0,
        MAY: 4280.0,
        JUN: 4035.0,
        JUL: 4106.0,
        AUG: 4779.0,
        SEP: 5460.0,
        OCT: 5790.0,
        NOV: 6080.0,
        DEC: 6127.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.850461434175614],
              [-35.299045178706265, -8.750461434175614],
              [-35.199045178706264, -8.750461434175614],
              [-35.199045178706264, -8.850461434175614],
              [-35.299045178706265, -8.850461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '214',
        ID: 38682.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.8005,
        ANNUAL: 5337.0,
        JAN: 5832.0,
        FEB: 5917.0,
        MAR: 6001.0,
        APR: 5075.0,
        MAY: 4332.0,
        JUN: 4095.0,
        JUL: 4173.0,
        AUG: 4855.0,
        SEP: 5566.0,
        OCT: 5894.0,
        NOV: 6129.0,
        DEC: 6176.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099082127, -8.760446068999945],
              [-35.099986289999897, -8.761018914999973],
              [-35.100018502, -8.761039323999977],
              [-35.100058617999906, -8.761072491999938],
              [-35.101081985999897, -8.761918621999945],
              [-35.102090215, -8.762988418999942],
              [-35.102822911, -8.763983753999982],
              [-35.103006316999902, -8.764287874999981],
              [-35.103707085999901, -8.765449879999947],
              [-35.104390603999903, -8.76691521399994],
              [-35.105138197, -8.768888334999929],
              [-35.105669809999902, -8.770916397999935],
              [-35.105752428999899, -8.771231585999946],
              [-35.105864648, -8.771825073999931],
              [-35.1059900569999, -8.77248832199996],
              [-35.106117568999899, -8.773162692999968],
              [-35.106116841999899, -8.77319410999996],
              [-35.106084845999902, -8.774576984999953],
              [-35.1059169, -8.776131437999942],
              [-35.105663439, -8.777140615999947],
              [-35.1055538869999, -8.777576806999948],
              [-35.104905196999901, -8.779813363999942],
              [-35.104331639999899, -8.781539818999935],
              [-35.103758808, -8.783074881999939],
              [-35.103703895, -8.783190708999939],
              [-35.1034704959999, -8.783683017999987],
              [-35.103261378, -8.784124113999956],
              [-35.10287075, -8.784803467999954],
              [-35.102808536999902, -8.784889875999975],
              [-35.102441810999899, -8.78539922799996],
              [-35.10243904, -8.785600135999976],
              [-35.102709475, -8.785911984999927],
              [-35.103404719, -8.786713703999965],
              [-35.103366197999897, -8.786969136999964],
              [-35.103328582, -8.787570721999947],
              [-35.103543741, -8.789073440999966],
              [-35.103839708, -8.790131690999935],
              [-35.104064641, -8.790580048999972],
              [-35.104312315, -8.791073735999928],
              [-35.10486155, -8.791904170999933],
              [-35.105535679999903, -8.792887588999974],
              [-35.106020639, -8.793614594999944],
              [-35.106571431, -8.794137715999968],
              [-35.106933352999903, -8.794332711999978],
              [-35.107260749, -8.794431774999968],
              [-35.107701527, -8.794565144999975],
              [-35.109083849999898, -8.795104949999933],
              [-35.109795018999897, -8.795457333999925],
              [-35.109853578999903, -8.795486351999951],
              [-35.112300936999901, -8.796872159999952],
              [-35.113222682, -8.797456024999965],
              [-35.114238593, -8.798265731999946],
              [-35.11568619, -8.799579952999986],
              [-35.116765202, -8.800683306999925],
              [-35.11770682, -8.801869897999955],
              [-35.118249117999902, -8.802775987999953],
              [-35.118753429, -8.803790985999962],
              [-35.119111911999902, -8.804524569999959],
              [-35.11935363, -8.804888387999934],
              [-35.119370751, -8.804914157999967],
              [-35.119671135, -8.805475711999961],
              [-35.119776803, -8.806016673999977],
              [-35.119765227999899, -8.806529056999983],
              [-35.119652042, -8.806917274999929],
              [-35.119505916, -8.807106971999923],
              [-35.119356634, -8.807172268999981],
              [-35.11910434, -8.807178042999963],
              [-35.118927544, -8.807143171999941],
              [-35.118837673, -8.80706339999996],
              [-35.118644434, -8.806978129999946],
              [-35.118539912, -8.806983325999976],
              [-35.118417275, -8.807105566999951],
              [-35.118343329, -8.807232943999953],
              [-35.118349377, -8.807379573999983],
              [-35.118353054, -8.807396182999979],
              [-35.118377064, -8.807528485999965],
              [-35.118365513999898, -8.807635409999925],
              [-35.118348554, -8.807741762999964],
              [-35.118413108, -8.807802642999954],
              [-35.118423349999901, -8.807809129999939],
              [-35.118612911, -8.807877790999955],
              [-35.118758403, -8.80789854499994],
              [-35.118878364999901, -8.807851721999953],
              [-35.118977840999897, -8.807791922999966],
              [-35.118995590999901, -8.807728910999959],
              [-35.118930459, -8.807673375999977],
              [-35.118949363999903, -8.807599670999931],
              [-35.119020059, -8.807553056999943],
              [-35.119189862, -8.807500681999944],
              [-35.119381701, -8.80754799699997],
              [-35.119392519, -8.807549140999983],
              [-35.119599051, -8.807662887999982],
              [-35.119614122, -8.80767529399998],
              [-35.119771629999903, -8.807837892999947],
              [-35.119793703, -8.807882646999925],
              [-35.120005080999903, -8.808311235999955],
              [-35.120337962999898, -8.80928174599995],
              [-35.120697126, -8.810107641999926],
              [-35.121053645, -8.810909897999984],
              [-35.121476947, -8.811397913999935],
              [-35.122054127, -8.812319668999979],
              [-35.122660246, -8.813394720999952],
              [-35.122880033999898, -8.813810201999956],
              [-35.122941968, -8.813927281999955],
              [-35.123374839, -8.814282038999988],
              [-35.123480086, -8.81436829399996],
              [-35.124218020999905, -8.814991519999976],
              [-35.1250528919999, -8.815881087999969],
              [-35.125058765999903, -8.816011218999957],
              [-35.124981689, -8.816197328999973],
              [-35.124936278999897, -8.816327649999954],
              [-35.124921545999896, -8.816464021999934],
              [-35.124848507, -8.816639263999944],
              [-35.124838555999901, -8.81665228699995],
              [-35.124703555999901, -8.816821000999942],
              [-35.124685773, -8.816906634999951],
              [-35.124656077999902, -8.817049634999929],
              [-35.124657692, -8.81706106499996],
              [-35.124700607, -8.81721760499993],
              [-35.12481725, -8.817377266999939],
              [-35.124843047, -8.817481322999972],
              [-35.124860541, -8.817495234999978],
              [-35.1249165, -8.817539740999953],
              [-35.124952585, -8.817594231999982],
              [-35.124982633999906, -8.817639606999936],
              [-35.124987666, -8.817645766999936],
              [-35.125091057, -8.817822752999975],
              [-35.125341841, -8.818085671999944],
              [-35.125642566, -8.818402736999936],
              [-35.125873175, -8.818773313999941],
              [-35.125887213999903, -8.818907105999921],
              [-35.125886013, -8.818918239999965],
              [-35.125920559, -8.819020413999974],
              [-35.125982448999906, -8.819080428999936],
              [-35.126058085999901, -8.819065881999965],
              [-35.126130307, -8.819056606999942],
              [-35.126198315999901, -8.819086298999936],
              [-35.127739209, -8.821070255999924],
              [-35.129091437999904, -8.823067616999937],
              [-35.130222831, -8.824901888999932],
              [-35.13142088, -8.827372082999949],
              [-35.132583003, -8.830502743999945],
              [-35.133050802999897, -8.832175188999933],
              [-35.133582949999898, -8.834473021999935],
              [-35.133833861, -8.835584486999952],
              [-35.1339484169999, -8.836091940999943],
              [-35.134176866, -8.838112809999929],
              [-35.134270602999898, -8.839446773999958],
              [-35.134321532999898, -8.841310755999931],
              [-35.13430943, -8.84269057899996],
              [-35.134186709999902, -8.843959069999935],
              [-35.134028381, -8.845359109999952],
              [-35.133836062999897, -8.846605989999944],
              [-35.133713128999901, -8.847184246999976],
              [-35.133601879, -8.847707548999956],
              [-35.133297254, -8.849327026999962],
              [-35.13305559527776, -8.850461434175614],
              [-35.199045178706264, -8.850461434175614],
              [-35.199045178706264, -8.750461434175614],
              [-35.099045178706263, -8.750461434175614],
              [-35.099045178706263, -8.760426946097731],
              [-35.099082127, -8.760446068999945],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '215',
        ID: 38683.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.8005,
        ANNUAL: 5649.0,
        JAN: 6168.0,
        FEB: 6177.0,
        MAR: 6265.0,
        APR: 5375.0,
        MAY: 4554.0,
        JUN: 4287.0,
        JUL: 4367.0,
        AUG: 5161.0,
        SEP: 5904.0,
        OCT: 6312.0,
        NOV: 6618.0,
        DEC: 6604.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.089854398999897, -8.751140114999933],
              [-35.0911734109999, -8.752626091999957],
              [-35.091804338, -8.75337026699998],
              [-35.0920415269999, -8.753650030999953],
              [-35.092514762, -8.754607881999956],
              [-35.093123983999902, -8.756371330999961],
              [-35.093237912999903, -8.756629665999975],
              [-35.093499016, -8.757221715999947],
              [-35.093688621, -8.757651645999943],
              [-35.094136678, -8.758384426999946],
              [-35.09441357, -8.758620016999936],
              [-35.094679879999902, -8.75874255699995],
              [-35.09596047, -8.759179192999973],
              [-35.096566191, -8.759366774999929],
              [-35.097088148999902, -8.759528417999945],
              [-35.0973109, -8.759597400999947],
              [-35.097638242, -8.759698774999945],
              [-35.097844348, -8.759805445999962],
              [-35.099045178706263, -8.760426946097731],
              [-35.099045178706263, -8.750461434175614],
              [-35.088980813159431, -8.750461434175614],
              [-35.089854398999897, -8.751140114999933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '216',
        ID: 39004.0,
        COUNTRY: 'Brasil',
        LON: -41.349,
        LAT: -8.7005,
        ANNUAL: 5860.0,
        JAN: 6079.0,
        FEB: 5822.0,
        MAR: 5703.0,
        APR: 5314.0,
        MAY: 5058.0,
        JUN: 5009.0,
        JUL: 5355.0,
        AUG: 6200.0,
        SEP: 6576.0,
        OCT: 6622.0,
        NOV: 6448.0,
        DEC: 6130.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.299401225999908, -8.733052097999973],
              [-41.305100093, -8.727380098999959],
              [-41.309934883, -8.726131718999973],
              [-41.3130426929999, -8.725790622999968],
              [-41.315403542, -8.726151251999973],
              [-41.318486588, -8.726982435999957],
              [-41.320907884999897, -8.728231950999938],
              [-41.329815923, -8.728451015999946],
              [-41.334351609, -8.727661531999955],
              [-41.343464754, -8.722009315999967],
              [-41.354382804999901, -8.712442044999927],
              [-41.358336183999903, -8.707620177999965],
              [-41.352404099, -8.708773088999978],
              [-41.345418423, -8.709865107999976],
              [-41.342399833999899, -8.709543451999926],
              [-41.33691677, -8.708357127999923],
              [-41.335402692999899, -8.706943399999941],
              [-41.332553511999897, -8.704283054999951],
              [-41.329580721999903, -8.702569713999944],
              [-41.321281106, -8.699652198999967],
              [-41.317655766, -8.698145576999938],
              [-41.314261551999898, -8.696102325999954],
              [-41.308305899999901, -8.690982081999952],
              [-41.307943017999904, -8.690586891999947],
              [-41.300184687, -8.682137229999974],
              [-41.299045178706351, -8.680095684404554],
              [-41.299045178706351, -8.73332818028387],
              [-41.299401225999908, -8.733052097999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '217',
        ID: 39005.0,
        COUNTRY: 'Brasil',
        LON: -41.249,
        LAT: -8.7005,
        ANNUAL: 5820.0,
        JAN: 6095.0,
        FEB: 5816.0,
        MAR: 5690.0,
        APR: 5261.0,
        MAY: 4979.0,
        JUN: 4885.0,
        JUL: 5262.0,
        AUG: 6100.0,
        SEP: 6597.0,
        OCT: 6587.0,
        NOV: 6456.0,
        DEC: 6117.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.19987514, -8.706937745999937],
              [-41.202153743999901, -8.708488993999939],
              [-41.204931555, -8.709487107999959],
              [-41.209678782, -8.707353324999938],
              [-41.215080666999903, -8.706191936999961],
              [-41.219066419999898, -8.706439271999955],
              [-41.221592938, -8.706024977999959],
              [-41.225265706, -8.704669785999952],
              [-41.226978499, -8.704702090999939],
              [-41.229105155, -8.705415573999966],
              [-41.23016717, -8.706364390999967],
              [-41.232688106, -8.708617694999926],
              [-41.234682253, -8.709667731999959],
              [-41.239841043, -8.710863],
              [-41.240125317, -8.711927659999962],
              [-41.238240197, -8.716101352999946],
              [-41.237681923, -8.718763332999972],
              [-41.238567804999903, -8.719731159999974],
              [-41.240432755, -8.72017826799993],
              [-41.244343979999897, -8.719638006999965],
              [-41.246902913, -8.720000105999922],
              [-41.249176736, -8.721036415999956],
              [-41.251511761, -8.721431787999961],
              [-41.257739917, -8.720122998999944],
              [-41.260624595, -8.72071650099997],
              [-41.262989660999899, -8.721876776999972],
              [-41.265040043999903, -8.723857857999963],
              [-41.268421769, -8.729477303999941],
              [-41.269982846999902, -8.731013168999938],
              [-41.273652644, -8.731703105999946],
              [-41.278264836999902, -8.735313038999958],
              [-41.281537594, -8.736100377999948],
              [-41.284075784, -8.73603866499997],
              [-41.289120609999898, -8.734797667999935],
              [-41.294983553, -8.735545166999934],
              [-41.297234780999901, -8.734731978999948],
              [-41.299045178706351, -8.73332818028387],
              [-41.299045178706351, -8.680095684404554],
              [-41.29775933, -8.677791954999982],
              [-41.296151635999898, -8.672482198999937],
              [-41.296131258999907, -8.672415013999968],
              [-41.296107591999899, -8.672336832999976],
              [-41.295455433, -8.670182466999957],
              [-41.291427644, -8.666463673999941],
              [-41.288997036, -8.661897642999973],
              [-41.287434022, -8.660839067999966],
              [-41.278906823999897, -8.660562970999932],
              [-41.2766184709999, -8.661257915999947],
              [-41.272007053, -8.662039824999967],
              [-41.268725533, -8.66322437999998],
              [-41.266237965, -8.662140135999952],
              [-41.264545256, -8.661951707999954],
              [-41.259471120999898, -8.662439281999978],
              [-41.253845382999899, -8.662175781999956],
              [-41.253213622, -8.662033996999975],
              [-41.252581861, -8.661892211999938],
              [-41.251318339, -8.661608642999973],
              [-41.249407544, -8.65955843799998],
              [-41.247406956, -8.658378762999975],
              [-41.243465821, -8.65670934999997],
              [-41.241632727, -8.656586067999969],
              [-41.239490503, -8.655826576999969],
              [-41.239186313999902, -8.654514950999953],
              [-41.2392352909999, -8.653748230999955],
              [-41.238200506999902, -8.652426949999951],
              [-41.237434188, -8.650805327999931],
              [-41.237041330758238, -8.650461434175615],
              [-41.199045178706349, -8.650461434175615],
              [-41.199045178706349, -8.70678787073876],
              [-41.19987514, -8.706937745999937],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '218',
        ID: 39006.0,
        COUNTRY: 'Brasil',
        LON: -41.149,
        LAT: -8.7005,
        ANNUAL: 5792.0,
        JAN: 6159.0,
        FEB: 5851.0,
        MAR: 5740.0,
        APR: 5202.0,
        MAY: 4900.0,
        JUN: 4763.0,
        JUL: 5180.0,
        AUG: 6005.0,
        SEP: 6569.0,
        OCT: 6544.0,
        NOV: 6433.0,
        DEC: 6159.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.126820871999897, -8.74676487899996],
              [-41.128989163, -8.743393089999929],
              [-41.129070783, -8.740376689999948],
              [-41.12606199, -8.735314889999927],
              [-41.126840275999903, -8.728927350999976],
              [-41.125634939999905, -8.727219890999946],
              [-41.123017806, -8.725972402999956],
              [-41.121714991, -8.724322651999964],
              [-41.121372987, -8.722499039999956],
              [-41.122927853, -8.719941080999931],
              [-41.123201775, -8.718707222999962],
              [-41.121999886, -8.717344210999949],
              [-41.118259397, -8.715064797999961],
              [-41.114675414, -8.712234471999981],
              [-41.111921865999904, -8.708717540999938],
              [-41.111376138, -8.707259779999969],
              [-41.111845897, -8.705309503999962],
              [-41.112757959, -8.703974089999962],
              [-41.113709722999907, -8.703797227999939],
              [-41.116505002999901, -8.705044225999936],
              [-41.121429177, -8.705758662999934],
              [-41.126290952, -8.705809812999977],
              [-41.131324977, -8.704252868999951],
              [-41.132900029, -8.704324282999947],
              [-41.135843247, -8.705685585999952],
              [-41.142011735, -8.707147149999976],
              [-41.152614781, -8.707767032999932],
              [-41.162898742, -8.709985657999939],
              [-41.165757608, -8.710033070999929],
              [-41.16787643, -8.709133454999975],
              [-41.168629696, -8.708334519999948],
              [-41.172431162999899, -8.707208616999939],
              [-41.176404738, -8.711194276999949],
              [-41.177766061999897, -8.711058098999954],
              [-41.181429228999896, -8.708122818999938],
              [-41.182436289999899, -8.707818667999968],
              [-41.185992696, -8.710646339999933],
              [-41.188156229, -8.710266322999933],
              [-41.191206849, -8.707004293999939],
              [-41.197561918, -8.706520021999957],
              [-41.199045178706349, -8.70678787073876],
              [-41.199045178706349, -8.650461434175615],
              [-41.099045178706348, -8.650461434175615],
              [-41.099045178706348, -8.750461434175614],
              [-41.12541225141225, -8.750461434175614],
              [-41.126820871999897, -8.74676487899996],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '219',
        ID: 39007.0,
        COUNTRY: 'Brasil',
        LON: -41.049,
        LAT: -8.7005,
        ANNUAL: 5795.0,
        JAN: 6151.0,
        FEB: 5919.0,
        MAR: 5812.0,
        APR: 5220.0,
        MAY: 4897.0,
        JUN: 4731.0,
        JUL: 5149.0,
        AUG: 5935.0,
        SEP: 6567.0,
        OCT: 6542.0,
        NOV: 6456.0,
        DEC: 6165.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.099045178706348, -8.750461434175614],
              [-41.099045178706348, -8.650461434175615],
              [-40.999045178706346, -8.650461434175615],
              [-40.999045178706346, -8.750461434175614],
              [-41.099045178706348, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '220',
        ID: 39008.0,
        COUNTRY: 'Brasil',
        LON: -40.949,
        LAT: -8.7005,
        ANNUAL: 5767.0,
        JAN: 6116.0,
        FEB: 5910.0,
        MAR: 5824.0,
        APR: 5228.0,
        MAY: 4857.0,
        JUN: 4690.0,
        JUL: 5100.0,
        AUG: 5867.0,
        SEP: 6546.0,
        OCT: 6492.0,
        NOV: 6417.0,
        DEC: 6156.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.999045178706346, -8.750461434175614],
              [-40.999045178706346, -8.650461434175615],
              [-40.899045178706345, -8.650461434175615],
              [-40.899045178706345, -8.750461434175614],
              [-40.999045178706346, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '221',
        ID: 39009.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -8.7005,
        ANNUAL: 5730.0,
        JAN: 6021.0,
        FEB: 5884.0,
        MAR: 5794.0,
        APR: 5203.0,
        MAY: 4796.0,
        JUN: 4617.0,
        JUL: 4992.0,
        AUG: 5812.0,
        SEP: 6547.0,
        OCT: 6470.0,
        NOV: 6452.0,
        DEC: 6175.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.899045178706345, -8.750461434175614],
              [-40.899045178706345, -8.650461434175615],
              [-40.799045178706344, -8.650461434175615],
              [-40.799045178706344, -8.750461434175614],
              [-40.899045178706345, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '222',
        ID: 39010.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.7005,
        ANNUAL: 5690.0,
        JAN: 6030.0,
        FEB: 5836.0,
        MAR: 5755.0,
        APR: 5151.0,
        MAY: 4744.0,
        JUN: 4571.0,
        JUL: 4926.0,
        AUG: 5750.0,
        SEP: 6514.0,
        OCT: 6438.0,
        NOV: 6403.0,
        DEC: 6162.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.799045178706344, -8.750461434175614],
              [-40.799045178706344, -8.650461434175615],
              [-40.699045178706342, -8.650461434175615],
              [-40.699045178706342, -8.750461434175614],
              [-40.799045178706344, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '223',
        ID: 39011.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.7005,
        ANNUAL: 5656.0,
        JAN: 6091.0,
        FEB: 5785.0,
        MAR: 5696.0,
        APR: 5112.0,
        MAY: 4725.0,
        JUN: 4535.0,
        JUL: 4867.0,
        AUG: 5689.0,
        SEP: 6479.0,
        OCT: 6407.0,
        NOV: 6350.0,
        DEC: 6141.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -8.750461434175614],
              [-40.699045178706342, -8.650461434175615],
              [-40.599045178706341, -8.650461434175615],
              [-40.599045178706341, -8.750461434175614],
              [-40.699045178706342, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '224',
        ID: 39012.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.7005,
        ANNUAL: 5680.0,
        JAN: 5985.0,
        FEB: 5815.0,
        MAR: 5725.0,
        APR: 5201.0,
        MAY: 4797.0,
        JUN: 4554.0,
        JUL: 4899.0,
        AUG: 5730.0,
        SEP: 6468.0,
        OCT: 6446.0,
        NOV: 6380.0,
        DEC: 6167.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.750461434175614],
              [-40.599045178706341, -8.650461434175615],
              [-40.499045178706339, -8.650461434175615],
              [-40.499045178706339, -8.750461434175614],
              [-40.599045178706341, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '225',
        ID: 39013.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.7005,
        ANNUAL: 5669.0,
        JAN: 5995.0,
        FEB: 5764.0,
        MAR: 5746.0,
        APR: 5217.0,
        MAY: 4822.0,
        JUN: 4539.0,
        JUL: 4858.0,
        AUG: 5689.0,
        SEP: 6420.0,
        OCT: 6440.0,
        NOV: 6379.0,
        DEC: 6157.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.750461434175614],
              [-40.499045178706339, -8.650461434175615],
              [-40.399045178706338, -8.650461434175615],
              [-40.399045178706338, -8.750461434175614],
              [-40.499045178706339, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '226',
        ID: 39014.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.7005,
        ANNUAL: 5632.0,
        JAN: 5998.0,
        FEB: 5725.0,
        MAR: 5729.0,
        APR: 5205.0,
        MAY: 4766.0,
        JUN: 4489.0,
        JUL: 4748.0,
        AUG: 5612.0,
        SEP: 6373.0,
        OCT: 6405.0,
        NOV: 6370.0,
        DEC: 6165.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.750461434175614],
              [-40.399045178706338, -8.650461434175615],
              [-40.299045178706336, -8.650461434175615],
              [-40.299045178706336, -8.750461434175614],
              [-40.399045178706338, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '227',
        ID: 39015.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.7005,
        ANNUAL: 5638.0,
        JAN: 6070.0,
        FEB: 5765.0,
        MAR: 5748.0,
        APR: 5216.0,
        MAY: 4727.0,
        JUN: 4454.0,
        JUL: 4705.0,
        AUG: 5588.0,
        SEP: 6342.0,
        OCT: 6401.0,
        NOV: 6412.0,
        DEC: 6232.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.750461434175614],
              [-40.299045178706336, -8.650461434175615],
              [-40.199045178706335, -8.650461434175615],
              [-40.199045178706335, -8.750461434175614],
              [-40.299045178706336, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '228',
        ID: 39016.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.7005,
        ANNUAL: 5638.0,
        JAN: 6080.0,
        FEB: 5771.0,
        MAR: 5771.0,
        APR: 5247.0,
        MAY: 4722.0,
        JUN: 4436.0,
        JUL: 4669.0,
        AUG: 5543.0,
        SEP: 6352.0,
        OCT: 6388.0,
        NOV: 6440.0,
        DEC: 6238.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.750461434175614],
              [-40.199045178706335, -8.650461434175615],
              [-40.099045178706334, -8.650461434175615],
              [-40.099045178706334, -8.750461434175614],
              [-40.199045178706335, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '229',
        ID: 39017.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.7005,
        ANNUAL: 5647.0,
        JAN: 6122.0,
        FEB: 5787.0,
        MAR: 5820.0,
        APR: 5258.0,
        MAY: 4717.0,
        JUN: 4426.0,
        JUL: 4626.0,
        AUG: 5509.0,
        SEP: 6369.0,
        OCT: 6416.0,
        NOV: 6472.0,
        DEC: 6240.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.750461434175614],
              [-40.099045178706334, -8.650461434175615],
              [-39.999045178706332, -8.650461434175615],
              [-39.999045178706332, -8.750461434175614],
              [-40.099045178706334, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '230',
        ID: 39018.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.7005,
        ANNUAL: 5657.0,
        JAN: 6173.0,
        FEB: 5829.0,
        MAR: 5910.0,
        APR: 5256.0,
        MAY: 4736.0,
        JUN: 4414.0,
        JUL: 4612.0,
        AUG: 5478.0,
        SEP: 6338.0,
        OCT: 6384.0,
        NOV: 6489.0,
        DEC: 6271.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.750461434175614],
              [-39.999045178706332, -8.650461434175615],
              [-39.899045178706331, -8.650461434175615],
              [-39.899045178706331, -8.750461434175614],
              [-39.999045178706332, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '231',
        ID: 39019.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.7005,
        ANNUAL: 5659.0,
        JAN: 6164.0,
        FEB: 5849.0,
        MAR: 5930.0,
        APR: 5268.0,
        MAY: 4728.0,
        JUN: 4409.0,
        JUL: 4594.0,
        AUG: 5459.0,
        SEP: 6300.0,
        OCT: 6388.0,
        NOV: 6520.0,
        DEC: 6300.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.750461434175614],
              [-39.899045178706331, -8.650461434175615],
              [-39.799045178706329, -8.650461434175615],
              [-39.799045178706329, -8.750461434175614],
              [-39.899045178706331, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '232',
        ID: 39020.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.7005,
        ANNUAL: 5711.0,
        JAN: 6220.0,
        FEB: 5913.0,
        MAR: 6022.0,
        APR: 5350.0,
        MAY: 4778.0,
        JUN: 4459.0,
        JUL: 4594.0,
        AUG: 5478.0,
        SEP: 6296.0,
        OCT: 6415.0,
        NOV: 6607.0,
        DEC: 6403.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.750461434175614],
              [-39.799045178706329, -8.650461434175615],
              [-39.699045178706328, -8.650461434175615],
              [-39.699045178706328, -8.750461434175614],
              [-39.799045178706329, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '233',
        ID: 39021.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.7005,
        ANNUAL: 5668.0,
        JAN: 6207.0,
        FEB: 5914.0,
        MAR: 5942.0,
        APR: 5335.0,
        MAY: 4759.0,
        JUN: 4360.0,
        JUL: 4545.0,
        AUG: 5403.0,
        SEP: 6226.0,
        OCT: 6340.0,
        NOV: 6588.0,
        DEC: 6396.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.600180368999901, -8.652209902999978],
              [-39.604675041, -8.654827793999971],
              [-39.606645401, -8.655975416999981],
              [-39.610026263999899, -8.657210710999957],
              [-39.614497866, -8.657541532999966],
              [-39.622584823999901, -8.655498603999943],
              [-39.629289479, -8.655994925999956],
              [-39.633517864999902, -8.657792890999925],
              [-39.642687453, -8.659348764999953],
              [-39.646005653, -8.659692609999986],
              [-39.650624557999897, -8.660171327999937],
              [-39.652364040999899, -8.660351606999946],
              [-39.660871448, -8.657353931999978],
              [-39.67162317, -8.659475980999956],
              [-39.685569476, -8.660295430999941],
              [-39.688835922, -8.660850399999958],
              [-39.691131733, -8.662312924999981],
              [-39.691978084, -8.664225821999921],
              [-39.693144516, -8.676238017999935],
              [-39.693036592999903, -8.680511979999958],
              [-39.689783690999903, -8.688869388999928],
              [-39.687987998, -8.693482913999961],
              [-39.6858891319999, -8.701204357999936],
              [-39.684956069, -8.703860500999944],
              [-39.6845592, -8.704990266999976],
              [-39.684376519, -8.705510124999938],
              [-39.682282533, -8.711469004999975],
              [-39.67964603, -8.722517648999938],
              [-39.678994278999902, -8.729334774999929],
              [-39.67797229, -8.732576047999938],
              [-39.675020002999901, -8.736667356999925],
              [-39.675147872, -8.740502480999956],
              [-39.673824037, -8.745461408999963],
              [-39.675694525821022, -8.750461434175614],
              [-39.699045178706328, -8.750461434175614],
              [-39.699045178706328, -8.650461434175615],
              [-39.599045178706326, -8.650461434175615],
              [-39.599045178706326, -8.651979839387034],
              [-39.600180368999901, -8.652209902999978],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '234',
        ID: 39022.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.7005,
        ANNUAL: 5702.0,
        JAN: 6244.0,
        FEB: 5930.0,
        MAR: 6015.0,
        APR: 5343.0,
        MAY: 4742.0,
        JUN: 4345.0,
        JUL: 4580.0,
        AUG: 5396.0,
        SEP: 6279.0,
        OCT: 6450.0,
        NOV: 6652.0,
        DEC: 6443.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.595226292999897, -8.651205883999978],
              [-39.599045178706326, -8.651979839387034],
              [-39.599045178706326, -8.650461434175615],
              [-39.594405002569893, -8.650461434175615],
              [-39.595226292999897, -8.651205883999978],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '235',
        ID: 39025.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.7005,
        ANNUAL: 5766.0,
        JAN: 6372.0,
        FEB: 6152.0,
        MAR: 6180.0,
        APR: 5494.0,
        MAY: 4778.0,
        JUN: 4397.0,
        JUL: 4577.0,
        AUG: 5343.0,
        SEP: 6207.0,
        OCT: 6431.0,
        NOV: 6688.0,
        DEC: 6580.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.200781027999902, -8.710987629999975],
              [-39.217631423999897, -8.708459670999966],
              [-39.218742485999897, -8.708851093999952],
              [-39.221193524999897, -8.711226686999961],
              [-39.2222407739999, -8.711667409999952],
              [-39.224382318, -8.711580691999925],
              [-39.228521864, -8.710315319999957],
              [-39.23055382, -8.708811100999984],
              [-39.234268020999899, -8.705336400999954],
              [-39.234808401, -8.704185042999937],
              [-39.2355823669999, -8.702535993999962],
              [-39.240856516, -8.691298642999923],
              [-39.244852185, -8.685999990999953],
              [-39.245492563, -8.684105867999955],
              [-39.245661250999902, -8.676259161999948],
              [-39.248017848, -8.668767715999923],
              [-39.247773128999903, -8.66758436799995],
              [-39.245713823, -8.663995296999929],
              [-39.245499089, -8.662355681999943],
              [-39.246885956, -8.654267104999967],
              [-39.246758525767909, -8.650461434175615],
              [-39.199045178706321, -8.650461434175615],
              [-39.199045178706321, -8.710670555701853],
              [-39.200781027999902, -8.710987629999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '236',
        ID: 39026.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.7005,
        ANNUAL: 5823.0,
        JAN: 6467.0,
        FEB: 6203.0,
        MAR: 6264.0,
        APR: 5619.0,
        MAY: 4863.0,
        JUN: 4464.0,
        JUL: 4648.0,
        AUG: 5388.0,
        SEP: 6188.0,
        OCT: 6467.0,
        NOV: 6683.0,
        DEC: 6617.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.102180867999898, -8.720047717999936],
              [-39.10702448, -8.719283468999961],
              [-39.109550934, -8.71888483299996],
              [-39.112963179, -8.718346431999981],
              [-39.113223556, -8.718279462999931],
              [-39.114034730999897, -8.718070832999951],
              [-39.114870362, -8.717855830999946],
              [-39.115494445, -8.717695212999951],
              [-39.117800885999898, -8.717101649999965],
              [-39.121799563, -8.716072582999963],
              [-39.126152126, -8.715713723999951],
              [-39.1293672749999, -8.715448515999983],
              [-39.1351909649999, -8.713714269999965],
              [-39.143946196999892, -8.712519070999964],
              [-39.14863523, -8.710919281999953],
              [-39.151133883999897, -8.709645605999976],
              [-39.15380440699991, -8.708574592999975],
              [-39.155245146, -8.707691952999943],
              [-39.156685823, -8.706809429999961],
              [-39.161581043999902, -8.701797802999979],
              [-39.167474173999899, -8.692543895999966],
              [-39.167477231, -8.692538828999938],
              [-39.168712829999897, -8.691504456999956],
              [-39.169569829999901, -8.692038681999961],
              [-39.169794270999901, -8.692178558999956],
              [-39.170257549, -8.692914794999977],
              [-39.171379734, -8.69541221099996],
              [-39.17404541, -8.70134494999996],
              [-39.175492516, -8.702966716999972],
              [-39.178523891999902, -8.704202380999961],
              [-39.180995841, -8.706300861999985],
              [-39.184886176, -8.705920250999954],
              [-39.190383078, -8.706702253999934],
              [-39.191858913, -8.707181045999969],
              [-39.195131929999903, -8.709555978999969],
              [-39.198901400999901, -8.710644292999973],
              [-39.198903854, -8.71064474099995],
              [-39.199045178706321, -8.710670555701853],
              [-39.199045178706321, -8.650461434175615],
              [-39.099045178706319, -8.650461434175615],
              [-39.099045178706319, -8.721054248412273],
              [-39.102180867999898, -8.720047717999936],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '237',
        ID: 39027.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.7005,
        ANNUAL: 5907.0,
        JAN: 6569.0,
        FEB: 6275.0,
        MAR: 6295.0,
        APR: 5691.0,
        MAY: 4949.0,
        JUN: 4534.0,
        JUL: 4778.0,
        AUG: 5521.0,
        SEP: 6295.0,
        OCT: 6542.0,
        NOV: 6769.0,
        DEC: 6666.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.023632087, -8.747043868999972],
              [-39.025768017, -8.742783320999928],
              [-39.028367451, -8.740020833999944],
              [-39.030206439, -8.738484643999932],
              [-39.033548352, -8.736313340999974],
              [-39.037577239, -8.734690862999965],
              [-39.042803379, -8.733348633999976],
              [-39.051848672999903, -8.733653567999962],
              [-39.052639778, -8.733590503999945],
              [-39.052747462, -8.733581919999947],
              [-39.053430335, -8.733527481999941],
              [-39.056233289, -8.733179414999945],
              [-39.05630031, -8.73317109199996],
              [-39.058571980999901, -8.732745608999949],
              [-39.060419258, -8.732399612999924],
              [-39.064538206, -8.731628134999946],
              [-39.064685695, -8.731600509999966],
              [-39.070198266, -8.730312641999948],
              [-39.070202762, -8.730311591999968],
              [-39.088499401, -8.724439259999931],
              [-39.088502780999903, -8.724438174999925],
              [-39.090140987, -8.723912478999983],
              [-39.096160918999892, -8.721980071999941],
              [-39.099045178706319, -8.721054248412273],
              [-39.099045178706319, -8.650461434175615],
              [-38.999045178706318, -8.650461434175615],
              [-38.999045178706318, -8.750461434175614],
              [-39.019137260440587, -8.750461434175614],
              [-39.023632087, -8.747043868999972],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '238',
        ID: 39028.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.7005,
        ANNUAL: 5954.0,
        JAN: 6593.0,
        FEB: 6330.0,
        MAR: 6336.0,
        APR: 5705.0,
        MAY: 4997.0,
        JUN: 4602.0,
        JUL: 4871.0,
        AUG: 5606.0,
        SEP: 6323.0,
        OCT: 6566.0,
        NOV: 6814.0,
        DEC: 6708.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.750461434175614],
              [-38.999045178706318, -8.650461434175615],
              [-38.899045178706317, -8.650461434175615],
              [-38.899045178706317, -8.750461434175614],
              [-38.999045178706318, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '239',
        ID: 39029.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.7005,
        ANNUAL: 5899.0,
        JAN: 6540.0,
        FEB: 6287.0,
        MAR: 6274.0,
        APR: 5675.0,
        MAY: 4925.0,
        JUN: 4563.0,
        JUL: 4852.0,
        AUG: 5519.0,
        SEP: 6247.0,
        OCT: 6472.0,
        NOV: 6772.0,
        DEC: 6664.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.750461434175614],
              [-38.899045178706317, -8.650461434175615],
              [-38.799045178706315, -8.650461434175615],
              [-38.799045178706315, -8.750461434175614],
              [-38.899045178706317, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '240',
        ID: 39030.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.7005,
        ANNUAL: 5901.0,
        JAN: 6533.0,
        FEB: 6328.0,
        MAR: 6263.0,
        APR: 5684.0,
        MAY: 4893.0,
        JUN: 4508.0,
        JUL: 4777.0,
        AUG: 5452.0,
        SEP: 6293.0,
        OCT: 6547.0,
        NOV: 6838.0,
        DEC: 6695.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.750461434175614],
              [-38.799045178706315, -8.650461434175615],
              [-38.699045178706314, -8.650461434175615],
              [-38.699045178706314, -8.750461434175614],
              [-38.799045178706315, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '241',
        ID: 39031.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.7005,
        ANNUAL: 5875.0,
        JAN: 6515.0,
        FEB: 6354.0,
        MAR: 6267.0,
        APR: 5667.0,
        MAY: 4870.0,
        JUN: 4482.0,
        JUL: 4739.0,
        AUG: 5404.0,
        SEP: 6257.0,
        OCT: 6482.0,
        NOV: 6797.0,
        DEC: 6670.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.750461434175614],
              [-38.699045178706314, -8.650461434175615],
              [-38.599045178706312, -8.650461434175615],
              [-38.599045178706312, -8.750461434175614],
              [-38.699045178706314, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '242',
        ID: 39032.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.7005,
        ANNUAL: 5826.0,
        JAN: 6442.0,
        FEB: 6267.0,
        MAR: 6254.0,
        APR: 5643.0,
        MAY: 4821.0,
        JUN: 4401.0,
        JUL: 4641.0,
        AUG: 5325.0,
        SEP: 6239.0,
        OCT: 6500.0,
        NOV: 6806.0,
        DEC: 6576.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.750461434175614],
              [-38.599045178706312, -8.650461434175615],
              [-38.499045178706311, -8.650461434175615],
              [-38.499045178706311, -8.750461434175614],
              [-38.599045178706312, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '243',
        ID: 39033.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.7005,
        ANNUAL: 5729.0,
        JAN: 6343.0,
        FEB: 6165.0,
        MAR: 6212.0,
        APR: 5547.0,
        MAY: 4703.0,
        JUN: 4228.0,
        JUL: 4462.0,
        AUG: 5179.0,
        SEP: 6148.0,
        OCT: 6472.0,
        NOV: 6753.0,
        DEC: 6541.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.750461434175614],
              [-38.499045178706311, -8.650461434175615],
              [-38.399045178706309, -8.650461434175615],
              [-38.399045178706309, -8.750461434175614],
              [-38.499045178706311, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '244',
        ID: 39034.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.7005,
        ANNUAL: 5684.0,
        JAN: 6301.0,
        FEB: 6104.0,
        MAR: 6191.0,
        APR: 5525.0,
        MAY: 4641.0,
        JUN: 4157.0,
        JUL: 4402.0,
        AUG: 5125.0,
        SEP: 6099.0,
        OCT: 6437.0,
        NOV: 6747.0,
        DEC: 6477.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.750461434175614],
              [-38.399045178706309, -8.650461434175615],
              [-38.299045178706308, -8.650461434175615],
              [-38.299045178706308, -8.750461434175614],
              [-38.399045178706309, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '245',
        ID: 39035.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.7005,
        ANNUAL: 5685.0,
        JAN: 6319.0,
        FEB: 6100.0,
        MAR: 6124.0,
        APR: 5506.0,
        MAY: 4654.0,
        JUN: 4182.0,
        JUL: 4479.0,
        AUG: 5153.0,
        SEP: 6103.0,
        OCT: 6426.0,
        NOV: 6739.0,
        DEC: 6436.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.750461434175614],
              [-38.299045178706308, -8.650461434175615],
              [-38.199045178706307, -8.650461434175615],
              [-38.199045178706307, -8.750461434175614],
              [-38.299045178706308, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '246',
        ID: 39036.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.7005,
        ANNUAL: 5692.0,
        JAN: 6331.0,
        FEB: 6052.0,
        MAR: 6105.0,
        APR: 5497.0,
        MAY: 4686.0,
        JUN: 4221.0,
        JUL: 4506.0,
        AUG: 5207.0,
        SEP: 6160.0,
        OCT: 6401.0,
        NOV: 6711.0,
        DEC: 6422.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.750461434175614],
              [-38.199045178706307, -8.650461434175615],
              [-38.099045178706305, -8.650461434175615],
              [-38.099045178706305, -8.750461434175614],
              [-38.199045178706307, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '247',
        ID: 39037.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.7005,
        ANNUAL: 5715.0,
        JAN: 6372.0,
        FEB: 6079.0,
        MAR: 6176.0,
        APR: 5520.0,
        MAY: 4705.0,
        JUN: 4225.0,
        JUL: 4528.0,
        AUG: 5263.0,
        SEP: 6149.0,
        OCT: 6408.0,
        NOV: 6730.0,
        DEC: 6420.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.750461434175614],
              [-38.099045178706305, -8.650461434175615],
              [-37.999045178706304, -8.650461434175615],
              [-37.999045178706304, -8.750461434175614],
              [-38.099045178706305, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '248',
        ID: 39038.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.7005,
        ANNUAL: 5669.0,
        JAN: 6402.0,
        FEB: 6142.0,
        MAR: 6144.0,
        APR: 5513.0,
        MAY: 4627.0,
        JUN: 4077.0,
        JUL: 4347.0,
        AUG: 5133.0,
        SEP: 6063.0,
        OCT: 6372.0,
        NOV: 6796.0,
        DEC: 6416.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.750461434175614],
              [-37.999045178706304, -8.650461434175615],
              [-37.899045178706302, -8.650461434175615],
              [-37.899045178706302, -8.750461434175614],
              [-37.999045178706304, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '249',
        ID: 39039.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.7005,
        ANNUAL: 5665.0,
        JAN: 6392.0,
        FEB: 6146.0,
        MAR: 6126.0,
        APR: 5525.0,
        MAY: 4628.0,
        JUN: 4081.0,
        JUL: 4318.0,
        AUG: 5093.0,
        SEP: 6057.0,
        OCT: 6364.0,
        NOV: 6809.0,
        DEC: 6443.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.750461434175614],
              [-37.899045178706302, -8.650461434175615],
              [-37.799045178706301, -8.650461434175615],
              [-37.799045178706301, -8.750461434175614],
              [-37.899045178706302, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '250',
        ID: 39040.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.7005,
        ANNUAL: 5634.0,
        JAN: 6348.0,
        FEB: 6111.0,
        MAR: 6154.0,
        APR: 5564.0,
        MAY: 4582.0,
        JUN: 4053.0,
        JUL: 4196.0,
        AUG: 4990.0,
        SEP: 5992.0,
        OCT: 6357.0,
        NOV: 6786.0,
        DEC: 6479.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.750461434175614],
              [-37.799045178706301, -8.650461434175615],
              [-37.699045178706299, -8.650461434175615],
              [-37.699045178706299, -8.750461434175614],
              [-37.799045178706301, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '251',
        ID: 39041.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.7005,
        ANNUAL: 5663.0,
        JAN: 6357.0,
        FEB: 6207.0,
        MAR: 6181.0,
        APR: 5594.0,
        MAY: 4589.0,
        JUN: 4053.0,
        JUL: 4302.0,
        AUG: 5027.0,
        SEP: 5996.0,
        OCT: 6359.0,
        NOV: 6766.0,
        DEC: 6525.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.750461434175614],
              [-37.699045178706299, -8.650461434175615],
              [-37.599045178706298, -8.650461434175615],
              [-37.599045178706298, -8.750461434175614],
              [-37.699045178706299, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '252',
        ID: 39042.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.7005,
        ANNUAL: 5653.0,
        JAN: 6333.0,
        FEB: 6135.0,
        MAR: 6120.0,
        APR: 5553.0,
        MAY: 4628.0,
        JUN: 4110.0,
        JUL: 4334.0,
        AUG: 5066.0,
        SEP: 6011.0,
        OCT: 6361.0,
        NOV: 6711.0,
        DEC: 6474.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.750461434175614],
              [-37.599045178706298, -8.650461434175615],
              [-37.499045178706297, -8.650461434175615],
              [-37.499045178706297, -8.750461434175614],
              [-37.599045178706298, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '253',
        ID: 39043.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.7005,
        ANNUAL: 5666.0,
        JAN: 6340.0,
        FEB: 6100.0,
        MAR: 6114.0,
        APR: 5520.0,
        MAY: 4636.0,
        JUN: 4192.0,
        JUL: 4350.0,
        AUG: 5116.0,
        SEP: 6025.0,
        OCT: 6368.0,
        NOV: 6774.0,
        DEC: 6463.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.750461434175614],
              [-37.499045178706297, -8.650461434175615],
              [-37.399045178706295, -8.650461434175615],
              [-37.399045178706295, -8.750461434175614],
              [-37.499045178706297, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '254',
        ID: 39044.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.7005,
        ANNUAL: 5738.0,
        JAN: 6414.0,
        FEB: 6187.0,
        MAR: 6248.0,
        APR: 5630.0,
        MAY: 4667.0,
        JUN: 4188.0,
        JUL: 4364.0,
        AUG: 5184.0,
        SEP: 6138.0,
        OCT: 6457.0,
        NOV: 6797.0,
        DEC: 6575.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.750461434175614],
              [-37.399045178706295, -8.650461434175615],
              [-37.299045178706294, -8.650461434175615],
              [-37.299045178706294, -8.750461434175614],
              [-37.399045178706295, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '255',
        ID: 39045.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.7005,
        ANNUAL: 5651.0,
        JAN: 6356.0,
        FEB: 6158.0,
        MAR: 6233.0,
        APR: 5529.0,
        MAY: 4572.0,
        JUN: 4041.0,
        JUL: 4227.0,
        AUG: 5014.0,
        SEP: 6028.0,
        OCT: 6372.0,
        NOV: 6752.0,
        DEC: 6531.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.750461434175614],
              [-37.299045178706294, -8.650461434175615],
              [-37.199045178706292, -8.650461434175615],
              [-37.199045178706292, -8.750461434175614],
              [-37.299045178706294, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '256',
        ID: 39046.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.7005,
        ANNUAL: 5545.0,
        JAN: 6254.0,
        FEB: 6100.0,
        MAR: 6128.0,
        APR: 5421.0,
        MAY: 4472.0,
        JUN: 3925.0,
        JUL: 4107.0,
        AUG: 4818.0,
        SEP: 5871.0,
        OCT: 6226.0,
        NOV: 6713.0,
        DEC: 6511.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -8.750461434175614],
              [-37.199045178706292, -8.650461434175615],
              [-37.099045178706291, -8.650461434175615],
              [-37.099045178706291, -8.750461434175614],
              [-37.199045178706292, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '257',
        ID: 39047.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.7005,
        ANNUAL: 5519.0,
        JAN: 6171.0,
        FEB: 6072.0,
        MAR: 6082.0,
        APR: 5444.0,
        MAY: 4471.0,
        JUN: 3954.0,
        JUL: 4099.0,
        AUG: 4817.0,
        SEP: 5798.0,
        OCT: 6172.0,
        NOV: 6687.0,
        DEC: 6456.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -8.750461434175614],
              [-37.099045178706291, -8.650461434175615],
              [-36.99904517870629, -8.650461434175615],
              [-36.99904517870629, -8.750461434175614],
              [-37.099045178706291, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '258',
        ID: 39048.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.7005,
        ANNUAL: 5492.0,
        JAN: 6154.0,
        FEB: 6016.0,
        MAR: 6032.0,
        APR: 5380.0,
        MAY: 4466.0,
        JUN: 3951.0,
        JUL: 4105.0,
        AUG: 4798.0,
        SEP: 5782.0,
        OCT: 6123.0,
        NOV: 6666.0,
        DEC: 6435.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -8.750461434175614],
              [-36.99904517870629, -8.650461434175615],
              [-36.899045178706288, -8.650461434175615],
              [-36.899045178706288, -8.750461434175614],
              [-36.99904517870629, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '259',
        ID: 39049.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.7005,
        ANNUAL: 5477.0,
        JAN: 6178.0,
        FEB: 5949.0,
        MAR: 5972.0,
        APR: 5361.0,
        MAY: 4478.0,
        JUN: 3955.0,
        JUL: 4074.0,
        AUG: 4789.0,
        SEP: 5777.0,
        OCT: 6131.0,
        NOV: 6679.0,
        DEC: 6384.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -8.750461434175614],
              [-36.899045178706288, -8.650461434175615],
              [-36.799045178706287, -8.650461434175615],
              [-36.799045178706287, -8.750461434175614],
              [-36.899045178706288, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '260',
        ID: 39050.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.7005,
        ANNUAL: 5506.0,
        JAN: 6247.0,
        FEB: 5928.0,
        MAR: 5957.0,
        APR: 5349.0,
        MAY: 4378.0,
        JUN: 4005.0,
        JUL: 4129.0,
        AUG: 4949.0,
        SEP: 5846.0,
        OCT: 6202.0,
        NOV: 6654.0,
        DEC: 6433.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -8.750461434175614],
              [-36.799045178706287, -8.650461434175615],
              [-36.699045178706285, -8.650461434175615],
              [-36.699045178706285, -8.750461434175614],
              [-36.799045178706287, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '261',
        ID: 39051.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.7005,
        ANNUAL: 5478.0,
        JAN: 6211.0,
        FEB: 5931.0,
        MAR: 5942.0,
        APR: 5365.0,
        MAY: 4394.0,
        JUN: 4003.0,
        JUL: 4113.0,
        AUG: 4900.0,
        SEP: 5752.0,
        OCT: 6135.0,
        NOV: 6575.0,
        DEC: 6418.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -8.750461434175614],
              [-36.699045178706285, -8.650461434175615],
              [-36.599045178706284, -8.650461434175615],
              [-36.599045178706284, -8.750461434175614],
              [-36.699045178706285, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '262',
        ID: 39052.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.7005,
        ANNUAL: 5348.0,
        JAN: 6042.0,
        FEB: 5835.0,
        MAR: 5833.0,
        APR: 5270.0,
        MAY: 4394.0,
        JUN: 3932.0,
        JUL: 3989.0,
        AUG: 4764.0,
        SEP: 5597.0,
        OCT: 5929.0,
        NOV: 6319.0,
        DEC: 6268.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.750461434175614],
              [-36.599045178706284, -8.650461434175615],
              [-36.499045178706282, -8.650461434175615],
              [-36.499045178706282, -8.750461434175614],
              [-36.599045178706284, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '263',
        ID: 39053.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.7005,
        ANNUAL: 5307.0,
        JAN: 6026.0,
        FEB: 5872.0,
        MAR: 5843.0,
        APR: 5210.0,
        MAY: 4339.0,
        JUN: 3888.0,
        JUL: 3990.0,
        AUG: 4717.0,
        SEP: 5515.0,
        OCT: 5799.0,
        NOV: 6284.0,
        DEC: 6208.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.750461434175614],
              [-36.499045178706282, -8.650461434175615],
              [-36.399045178706281, -8.650461434175615],
              [-36.399045178706281, -8.750461434175614],
              [-36.499045178706282, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '264',
        ID: 39054.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.7005,
        ANNUAL: 5295.0,
        JAN: 5981.0,
        FEB: 5838.0,
        MAR: 5823.0,
        APR: 5188.0,
        MAY: 4352.0,
        JUN: 3915.0,
        JUL: 4014.0,
        AUG: 4722.0,
        SEP: 5523.0,
        OCT: 5756.0,
        NOV: 6242.0,
        DEC: 6185.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.750461434175614],
              [-36.399045178706281, -8.650461434175615],
              [-36.29904517870628, -8.650461434175615],
              [-36.29904517870628, -8.750461434175614],
              [-36.399045178706281, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '265',
        ID: 39055.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.7005,
        ANNUAL: 5242.0,
        JAN: 5838.0,
        FEB: 5751.0,
        MAR: 5722.0,
        APR: 5117.0,
        MAY: 4350.0,
        JUN: 3946.0,
        JUL: 4026.0,
        AUG: 4713.0,
        SEP: 5441.0,
        OCT: 5709.0,
        NOV: 6189.0,
        DEC: 6099.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.750461434175614],
              [-36.29904517870628, -8.650461434175615],
              [-36.199045178706278, -8.650461434175615],
              [-36.199045178706278, -8.750461434175614],
              [-36.29904517870628, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '266',
        ID: 39056.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.7005,
        ANNUAL: 5278.0,
        JAN: 5915.0,
        FEB: 5768.0,
        MAR: 5755.0,
        APR: 5126.0,
        MAY: 4379.0,
        JUN: 3955.0,
        JUL: 4045.0,
        AUG: 4725.0,
        SEP: 5492.0,
        OCT: 5800.0,
        NOV: 6232.0,
        DEC: 6137.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.750461434175614],
              [-36.199045178706278, -8.650461434175615],
              [-36.099045178706277, -8.650461434175615],
              [-36.099045178706277, -8.750461434175614],
              [-36.199045178706278, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '267',
        ID: 39057.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.7005,
        ANNUAL: 5227.0,
        JAN: 5889.0,
        FEB: 5720.0,
        MAR: 5712.0,
        APR: 5078.0,
        MAY: 4332.0,
        JUN: 3928.0,
        JUL: 3986.0,
        AUG: 4656.0,
        SEP: 5401.0,
        OCT: 5729.0,
        NOV: 6186.0,
        DEC: 6101.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.750461434175614],
              [-36.099045178706277, -8.650461434175615],
              [-35.999045178706275, -8.650461434175615],
              [-35.999045178706275, -8.750461434175614],
              [-36.099045178706277, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '268',
        ID: 39058.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.7005,
        ANNUAL: 5162.0,
        JAN: 5836.0,
        FEB: 5685.0,
        MAR: 5702.0,
        APR: 5051.0,
        MAY: 4237.0,
        JUN: 3874.0,
        JUL: 3921.0,
        AUG: 4559.0,
        SEP: 5304.0,
        OCT: 5640.0,
        NOV: 6104.0,
        DEC: 6030.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.750461434175614],
              [-35.999045178706275, -8.650461434175615],
              [-35.899045178706274, -8.650461434175615],
              [-35.899045178706274, -8.750461434175614],
              [-35.999045178706275, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '269',
        ID: 39059.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.7005,
        ANNUAL: 5077.0,
        JAN: 5652.0,
        FEB: 5637.0,
        MAR: 5630.0,
        APR: 5025.0,
        MAY: 4234.0,
        JUN: 3796.0,
        JUL: 3864.0,
        AUG: 4478.0,
        SEP: 5175.0,
        OCT: 5497.0,
        NOV: 5979.0,
        DEC: 5954.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.750461434175614],
              [-35.899045178706274, -8.650461434175615],
              [-35.799045178706272, -8.650461434175615],
              [-35.799045178706272, -8.750461434175614],
              [-35.899045178706274, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '270',
        ID: 39060.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.7005,
        ANNUAL: 5093.0,
        JAN: 5638.0,
        FEB: 5670.0,
        MAR: 5665.0,
        APR: 5039.0,
        MAY: 4253.0,
        JUN: 3817.0,
        JUL: 3853.0,
        AUG: 4492.0,
        SEP: 5258.0,
        OCT: 5545.0,
        NOV: 5990.0,
        DEC: 5903.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.750461434175614],
              [-35.799045178706272, -8.650461434175615],
              [-35.699045178706271, -8.650461434175615],
              [-35.699045178706271, -8.750461434175614],
              [-35.799045178706272, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '271',
        ID: 39061.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.7005,
        ANNUAL: 5118.0,
        JAN: 5684.0,
        FEB: 5658.0,
        MAR: 5695.0,
        APR: 5029.0,
        MAY: 4272.0,
        JUN: 3854.0,
        JUL: 3945.0,
        AUG: 4547.0,
        SEP: 5301.0,
        OCT: 5550.0,
        NOV: 5961.0,
        DEC: 5921.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.750461434175614],
              [-35.699045178706271, -8.650461434175615],
              [-35.59904517870627, -8.650461434175615],
              [-35.59904517870627, -8.750461434175614],
              [-35.699045178706271, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '272',
        ID: 39062.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.7005,
        ANNUAL: 5145.0,
        JAN: 5711.0,
        FEB: 5636.0,
        MAR: 5733.0,
        APR: 5001.0,
        MAY: 4289.0,
        JUN: 3929.0,
        JUL: 3968.0,
        AUG: 4560.0,
        SEP: 5357.0,
        OCT: 5612.0,
        NOV: 5981.0,
        DEC: 5961.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.750461434175614],
              [-35.59904517870627, -8.650461434175615],
              [-35.499045178706268, -8.650461434175615],
              [-35.499045178706268, -8.750461434175614],
              [-35.59904517870627, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '273',
        ID: 39063.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.7005,
        ANNUAL: 5175.0,
        JAN: 5753.0,
        FEB: 5641.0,
        MAR: 5748.0,
        APR: 5002.0,
        MAY: 4289.0,
        JUN: 3994.0,
        JUL: 3978.0,
        AUG: 4592.0,
        SEP: 5394.0,
        OCT: 5637.0,
        NOV: 6046.0,
        DEC: 6021.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.750461434175614],
              [-35.499045178706268, -8.650461434175615],
              [-35.399045178706267, -8.650461434175615],
              [-35.399045178706267, -8.750461434175614],
              [-35.499045178706268, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '274',
        ID: 39064.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.7005,
        ANNUAL: 5215.0,
        JAN: 5718.0,
        FEB: 5820.0,
        MAR: 5757.0,
        APR: 5034.0,
        MAY: 4287.0,
        JUN: 4029.0,
        JUL: 4047.0,
        AUG: 4684.0,
        SEP: 5391.0,
        OCT: 5670.0,
        NOV: 6071.0,
        DEC: 6076.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.750461434175614],
              [-35.399045178706267, -8.650461434175615],
              [-35.299045178706265, -8.650461434175615],
              [-35.299045178706265, -8.750461434175614],
              [-35.399045178706267, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '275',
        ID: 39065.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.7005,
        ANNUAL: 5274.0,
        JAN: 5809.0,
        FEB: 5892.0,
        MAR: 5923.0,
        APR: 5061.0,
        MAY: 4328.0,
        JUN: 4028.0,
        JUL: 4109.0,
        AUG: 4757.0,
        SEP: 5422.0,
        OCT: 5735.0,
        NOV: 6098.0,
        DEC: 6132.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.750461434175614],
              [-35.299045178706265, -8.650461434175615],
              [-35.199045178706264, -8.650461434175615],
              [-35.199045178706264, -8.750461434175614],
              [-35.299045178706265, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '276',
        ID: 39066.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.7005,
        ANNUAL: 5302.0,
        JAN: 5836.0,
        FEB: 5895.0,
        MAR: 5952.0,
        APR: 5091.0,
        MAY: 4384.0,
        JUN: 4054.0,
        JUL: 4149.0,
        AUG: 4824.0,
        SEP: 5444.0,
        OCT: 5756.0,
        NOV: 6094.0,
        DEC: 6140.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.750461434175614],
              [-35.199045178706264, -8.650461434175615],
              [-35.099045178706263, -8.650461434175615],
              [-35.099045178706263, -8.750461434175614],
              [-35.199045178706264, -8.750461434175614],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '277',
        ID: 39067.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.7005,
        ANNUAL: 5602.0,
        JAN: 6157.0,
        FEB: 6139.0,
        MAR: 6189.0,
        APR: 5330.0,
        MAY: 4587.0,
        JUN: 4241.0,
        JUL: 4357.0,
        AUG: 5135.0,
        SEP: 5826.0,
        OCT: 6188.0,
        NOV: 6525.0,
        DEC: 6553.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.0717213369999, -8.652648650999934],
              [-35.072241732, -8.654180802999969],
              [-35.072672612, -8.655781738999966],
              [-35.072890618, -8.657458727999936],
              [-35.0731362349999, -8.658654868999969],
              [-35.073421317999902, -8.660167027999934],
              [-35.073444058, -8.661724862999964],
              [-35.073276426999897, -8.663139746999946],
              [-35.0728870579999, -8.664428775999971],
              [-35.072377853, -8.665806814999945],
              [-35.071853050999898, -8.666784705999936],
              [-35.071326500999902, -8.667506777999963],
              [-35.070644677, -8.668373081999965],
              [-35.070538579, -8.668674394999927],
              [-35.070667704, -8.66904419399998],
              [-35.070795771, -8.669355726999971],
              [-35.070922169, -8.67022670299998],
              [-35.071061562, -8.671113620999957],
              [-35.071135886, -8.671704681999932],
              [-35.071354353999901, -8.672200198999962],
              [-35.071805946, -8.672647673999961],
              [-35.07255266100001, -8.673017326999966],
              [-35.073592463999901, -8.673396099999922],
              [-35.074323149, -8.673738562999969],
              [-35.076023251, -8.674470532999985],
              [-35.076567591, -8.674823687999945],
              [-35.077116794999903, -8.675252382999929],
              [-35.077550393, -8.67545809899997],
              [-35.078031263999897, -8.675770552999946],
              [-35.078828468, -8.676421674999968],
              [-35.079424387, -8.676826224999949],
              [-35.08062231600001, -8.677890399999972],
              [-35.081780044, -8.679128330999951],
              [-35.082675643999899, -8.680585855999944],
              [-35.083226474999897, -8.681203686999934],
              [-35.083607142999902, -8.681419426999975],
              [-35.083966512000011, -8.681631850999967],
              [-35.084150485999899, -8.682174549999955],
              [-35.084307996999897, -8.682758087999968],
              [-35.084388114, -8.684125720999935],
              [-35.084589810999901, -8.68501588899994],
              [-35.084435143999897, -8.68610670699997],
              [-35.084515141999901, -8.687067306999941],
              [-35.08456954, -8.687516412999981],
              [-35.084860512, -8.688157674999966],
              [-35.085190435, -8.688805414999923],
              [-35.085624013, -8.689006308999977],
              [-35.086713447, -8.688996735999977],
              [-35.087787733, -8.690774079999926],
              [-35.090328902, -8.694978292999959],
              [-35.089631848, -8.695580726999939],
              [-35.088582222999896, -8.69651457199997],
              [-35.087164484, -8.697528064999972],
              [-35.086120475, -8.698339162999963],
              [-35.085019965999898, -8.699050666999932],
              [-35.084256803999899, -8.699577874999937],
              [-35.0832706489999, -8.700296054999972],
              [-35.0826091, -8.700894681999955],
              [-35.081970581999897, -8.701535984999961],
              [-35.081499650999902, -8.701927627999956],
              [-35.080713589, -8.702515472999943],
              [-35.079950181999898, -8.702894702999977],
              [-35.079472828, -8.703103930999932],
              [-35.0791745, -8.703332078999951],
              [-35.079016491999901, -8.703629209999962],
              [-35.079012368999898, -8.703887244999981],
              [-35.079010794999903, -8.703985727999962],
              [-35.079091561, -8.70460562599993],
              [-35.079125548, -8.705810283999948],
              [-35.079215835999896, -8.706646008999941],
              [-35.079269872999902, -8.707057497999935],
              [-35.079100777999898, -8.70806481399995],
              [-35.078767656, -8.709070479999971],
              [-35.078540721, -8.709830787999985],
              [-35.078535949, -8.709846777999923],
              [-35.078535071, -8.709875988999944],
              [-35.078525418, -8.710197343999935],
              [-35.078666955, -8.710407057999928],
              [-35.079248911, -8.710592975999932],
              [-35.080047314999902, -8.710837462999963],
              [-35.080846929, -8.711186915999964],
              [-35.081368488, -8.711577422999936],
              [-35.082004899, -8.712213632999983],
              [-35.082551886, -8.712926040999943],
              [-35.083004678, -8.713627934999979],
              [-35.083471798999902, -8.714218322999944],
              [-35.08367557, -8.714582820999953],
              [-35.083880006, -8.714948513999966],
              [-35.084238805, -8.715368835999925],
              [-35.084452687, -8.715885424999955],
              [-35.084533955, -8.716081711999955],
              [-35.084648070999897, -8.716342633999936],
              [-35.084808134999903, -8.716433960999947],
              [-35.085055353, -8.716575016999965],
              [-35.085420982, -8.717166645999948],
              [-35.085459519, -8.717229004999972],
              [-35.085684261, -8.71759770099993],
              [-35.086354672, -8.718697528999934],
              [-35.086531444999899, -8.719027895999933],
              [-35.087051379999899, -8.719999590999976],
              [-35.087629889, -8.721375990999951],
              [-35.0880217079999, -8.722663073999975],
              [-35.088109272999901, -8.722934540999971],
              [-35.088234894, -8.723323987999956],
              [-35.088460315, -8.724024047999924],
              [-35.088770984, -8.724744821999929],
              [-35.089112467999897, -8.725635709999947],
              [-35.089144121999901, -8.726152987999964],
              [-35.0892088149999, -8.726871727999931],
              [-35.089319287, -8.727250246999965],
              [-35.0894205869999, -8.727597337999956],
              [-35.089512683999899, -8.728923555999936],
              [-35.089433176999897, -8.730419110999948],
              [-35.089339809, -8.73133383399993],
              [-35.089316751999903, -8.731494725999942],
              [-35.089206144999899, -8.732266540999941],
              [-35.089027632, -8.732895498999945],
              [-35.088923063, -8.733263930999941],
              [-35.08847256, -8.734445427999967],
              [-35.088428334999897, -8.734532997999954],
              [-35.088096423, -8.735190234999955],
              [-35.0876623569999, -8.735863973999923],
              [-35.087138884, -8.736491102999926],
              [-35.0869340469999, -8.736749278999975],
              [-35.08676008, -8.737049185999982],
              [-35.086679558, -8.737302998999949],
              [-35.0866917869999, -8.737713490999967],
              [-35.086692494, -8.737718051999929],
              [-35.086781824, -8.73829404699995],
              [-35.086700678999897, -8.740223554999949],
              [-35.086649516, -8.740894233999946],
              [-35.086564852, -8.74200409199995],
              [-35.08666088, -8.743210359999976],
              [-35.08646671, -8.744122674999973],
              [-35.086239534999898, -8.745274924999929],
              [-35.086189636, -8.745676823999929],
              [-35.0860804289999, -8.746556403999936],
              [-35.086061222999902, -8.746711089999959],
              [-35.08638919, -8.747856151999946],
              [-35.086763550999898, -8.748517506999971],
              [-35.086894201999897, -8.748748318999969],
              [-35.086920095, -8.748775783999976],
              [-35.087373199, -8.749256409999933],
              [-35.087538664, -8.749431925999948],
              [-35.087763014, -8.749589524999978],
              [-35.088338314999902, -8.749993656999953],
              [-35.088759889999899, -8.750289800999942],
              [-35.088980813159431, -8.750461434175614],
              [-35.099045178706263, -8.750461434175614],
              [-35.099045178706263, -8.650461434175615],
              [-35.070830644125174, -8.650461434175615],
              [-35.0717213369999, -8.652648650999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '278',
        ID: 39389.0,
        COUNTRY: 'Brasil',
        LON: -41.249,
        LAT: -8.6005,
        ANNUAL: 5883.0,
        JAN: 6103.0,
        FEB: 5888.0,
        MAR: 5797.0,
        APR: 5337.0,
        MAY: 5102.0,
        JUN: 4981.0,
        JUL: 5325.0,
        AUG: 6191.0,
        SEP: 6597.0,
        OCT: 6632.0,
        NOV: 6481.0,
        DEC: 6166.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.233788846, -8.647614319999953],
              [-41.23106667, -8.647146845999933],
              [-41.22699025, -8.647785912999952],
              [-41.225160869, -8.64705121499998],
              [-41.223331488, -8.646316516999955],
              [-41.221711721, -8.646177209999957],
              [-41.220932244, -8.646374345999964],
              [-41.217796197, -8.646020489999952],
              [-41.215043643999898, -8.645246425999972],
              [-41.213542855, -8.644155528999939],
              [-41.210292409, -8.640083918999951],
              [-41.206544139, -8.633295033999961],
              [-41.206649265, -8.62259902299996],
              [-41.206826373, -8.620237434999989],
              [-41.207994089, -8.618370056999929],
              [-41.208005801, -8.618351182999961],
              [-41.208186037, -8.618063517999934],
              [-41.20860061, -8.616013389999974],
              [-41.207603787, -8.611931504999966],
              [-41.207746825, -8.610271676999963],
              [-41.209419006, -8.607406736999955],
              [-41.210571946, -8.606384543999985],
              [-41.212344436, -8.604253939999973],
              [-41.212628627999891, -8.602637511999934],
              [-41.212288360999899, -8.601820272999927],
              [-41.209944957999902, -8.60136896699993],
              [-41.209395263, -8.601015587999939],
              [-41.207485555999902, -8.600934200999966],
              [-41.204655299, -8.600259578999953],
              [-41.202878163, -8.599276527999956],
              [-41.200738084, -8.594410693999979],
              [-41.199703902, -8.592991745999937],
              [-41.199045178706349, -8.592693805505279],
              [-41.199045178706349, -8.650461434175615],
              [-41.237041330758238, -8.650461434175615],
              [-41.233788846, -8.647614319999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '279',
        ID: 39390.0,
        COUNTRY: 'Brasil',
        LON: -41.149,
        LAT: -8.6005,
        ANNUAL: 5867.0,
        JAN: 6109.0,
        FEB: 5868.0,
        MAR: 5821.0,
        APR: 5355.0,
        MAY: 5048.0,
        JUN: 4919.0,
        JUL: 5269.0,
        AUG: 6127.0,
        SEP: 6580.0,
        OCT: 6610.0,
        NOV: 6482.0,
        DEC: 6212.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.198566168, -8.592477148999929],
              [-41.185246929999906, -8.592779500999944],
              [-41.181647105, -8.593641757999924],
              [-41.179906511, -8.593267364999962],
              [-41.179205165, -8.592591223999927],
              [-41.178628268, -8.588376880999938],
              [-41.177396496, -8.587476046999939],
              [-41.176248649999899, -8.586912562999935],
              [-41.175301141, -8.586950660999943],
              [-41.172921707999905, -8.586306567999939],
              [-41.171555917, -8.585630943999936],
              [-41.169326122, -8.583615204999944],
              [-41.168745879, -8.583090561999933],
              [-41.166739381, -8.58068225099993],
              [-41.165261459, -8.577886774999962],
              [-41.163411716, -8.571736745999933],
              [-41.1631724329999, -8.570304929999965],
              [-41.157012052, -8.560439719999986],
              [-41.156723469, -8.558880102999973],
              [-41.157129386, -8.556436323999957],
              [-41.156410396, -8.552516193999956],
              [-41.155093258, -8.550894143999926],
              [-41.154172025, -8.550468435999958],
              [-41.154156873014799, -8.550461434175615],
              [-41.099045178706348, -8.550461434175615],
              [-41.099045178706348, -8.650461434175615],
              [-41.199045178706349, -8.650461434175615],
              [-41.199045178706349, -8.592693805505279],
              [-41.198566168, -8.592477148999929],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '280',
        ID: 39391.0,
        COUNTRY: 'Brasil',
        LON: -41.049,
        LAT: -8.6005,
        ANNUAL: 5802.0,
        JAN: 6095.0,
        FEB: 5839.0,
        MAR: 5786.0,
        APR: 5245.0,
        MAY: 4958.0,
        JUN: 4806.0,
        JUL: 5148.0,
        AUG: 6003.0,
        SEP: 6587.0,
        OCT: 6555.0,
        NOV: 6423.0,
        DEC: 6186.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.099045178706348, -8.650461434175615],
              [-41.099045178706348, -8.550461434175615],
              [-40.999045178706346, -8.550461434175615],
              [-40.999045178706346, -8.650461434175615],
              [-41.099045178706348, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '281',
        ID: 39392.0,
        COUNTRY: 'Brasil',
        LON: -40.949,
        LAT: -8.6005,
        ANNUAL: 5779.0,
        JAN: 6096.0,
        FEB: 5854.0,
        MAR: 5788.0,
        APR: 5202.0,
        MAY: 4889.0,
        JUN: 4726.0,
        JUL: 5079.0,
        AUG: 5930.0,
        SEP: 6590.0,
        OCT: 6566.0,
        NOV: 6431.0,
        DEC: 6195.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.999045178706346, -8.650461434175615],
              [-40.999045178706346, -8.550461434175615],
              [-40.899045178706345, -8.550461434175615],
              [-40.899045178706345, -8.650461434175615],
              [-40.999045178706346, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '282',
        ID: 39393.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -8.6005,
        ANNUAL: 5741.0,
        JAN: 6110.0,
        FEB: 5744.0,
        MAR: 5795.0,
        APR: 5209.0,
        MAY: 4790.0,
        JUN: 4665.0,
        JUL: 4998.0,
        AUG: 5872.0,
        SEP: 6560.0,
        OCT: 6517.0,
        NOV: 6455.0,
        DEC: 6175.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.899045178706345, -8.650461434175615],
              [-40.899045178706345, -8.550461434175615],
              [-40.799045178706344, -8.550461434175615],
              [-40.799045178706344, -8.650461434175615],
              [-40.899045178706345, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '283',
        ID: 39394.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.6005,
        ANNUAL: 5706.0,
        JAN: 6064.0,
        FEB: 5787.0,
        MAR: 5789.0,
        APR: 5184.0,
        MAY: 4763.0,
        JUN: 4601.0,
        JUL: 4941.0,
        AUG: 5805.0,
        SEP: 6501.0,
        OCT: 6463.0,
        NOV: 6425.0,
        DEC: 6145.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.799045178706344, -8.650461434175615],
              [-40.799045178706344, -8.550461434175615],
              [-40.699045178706342, -8.550461434175615],
              [-40.699045178706342, -8.650461434175615],
              [-40.799045178706344, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '284',
        ID: 39395.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.6005,
        ANNUAL: 5654.0,
        JAN: 6012.0,
        FEB: 5794.0,
        MAR: 5744.0,
        APR: 5169.0,
        MAY: 4738.0,
        JUN: 4513.0,
        JUL: 4863.0,
        AUG: 5718.0,
        SEP: 6421.0,
        OCT: 6401.0,
        NOV: 6372.0,
        DEC: 6099.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -8.650461434175615],
              [-40.699045178706342, -8.550461434175615],
              [-40.599045178706341, -8.550461434175615],
              [-40.599045178706341, -8.650461434175615],
              [-40.699045178706342, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '285',
        ID: 39396.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.6005,
        ANNUAL: 5633.0,
        JAN: 5938.0,
        FEB: 5793.0,
        MAR: 5739.0,
        APR: 5107.0,
        MAY: 4694.0,
        JUN: 4527.0,
        JUL: 4826.0,
        AUG: 5716.0,
        SEP: 6438.0,
        OCT: 6410.0,
        NOV: 6367.0,
        DEC: 6040.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.650461434175615],
              [-40.599045178706341, -8.550461434175615],
              [-40.499045178706339, -8.550461434175615],
              [-40.499045178706339, -8.650461434175615],
              [-40.599045178706341, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '286',
        ID: 39397.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.6005,
        ANNUAL: 5652.0,
        JAN: 5981.0,
        FEB: 5797.0,
        MAR: 5795.0,
        APR: 5165.0,
        MAY: 4726.0,
        JUN: 4511.0,
        JUL: 4802.0,
        AUG: 5689.0,
        SEP: 6404.0,
        OCT: 6417.0,
        NOV: 6406.0,
        DEC: 6131.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.650461434175615],
              [-40.499045178706339, -8.550461434175615],
              [-40.399045178706338, -8.550461434175615],
              [-40.399045178706338, -8.650461434175615],
              [-40.499045178706339, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '287',
        ID: 39398.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.6005,
        ANNUAL: 5668.0,
        JAN: 6022.0,
        FEB: 5801.0,
        MAR: 5815.0,
        APR: 5229.0,
        MAY: 4755.0,
        JUN: 4478.0,
        JUL: 4780.0,
        AUG: 5649.0,
        SEP: 6404.0,
        OCT: 6440.0,
        NOV: 6449.0,
        DEC: 6187.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.650461434175615],
              [-40.399045178706338, -8.550461434175615],
              [-40.299045178706336, -8.550461434175615],
              [-40.299045178706336, -8.650461434175615],
              [-40.399045178706338, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '288',
        ID: 39399.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.6005,
        ANNUAL: 5669.0,
        JAN: 6040.0,
        FEB: 5889.0,
        MAR: 5805.0,
        APR: 5247.0,
        MAY: 4742.0,
        JUN: 4492.0,
        JUL: 4765.0,
        AUG: 5626.0,
        SEP: 6389.0,
        OCT: 6427.0,
        NOV: 6445.0,
        DEC: 6162.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.650461434175615],
              [-40.299045178706336, -8.550461434175615],
              [-40.199045178706335, -8.550461434175615],
              [-40.199045178706335, -8.650461434175615],
              [-40.299045178706336, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '289',
        ID: 39400.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.6005,
        ANNUAL: 5639.0,
        JAN: 6004.0,
        FEB: 5838.0,
        MAR: 5775.0,
        APR: 5229.0,
        MAY: 4696.0,
        JUN: 4429.0,
        JUL: 4711.0,
        AUG: 5559.0,
        SEP: 6360.0,
        OCT: 6427.0,
        NOV: 6456.0,
        DEC: 6178.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.650461434175615],
              [-40.199045178706335, -8.550461434175615],
              [-40.099045178706334, -8.550461434175615],
              [-40.099045178706334, -8.650461434175615],
              [-40.199045178706335, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '290',
        ID: 39401.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.6005,
        ANNUAL: 5593.0,
        JAN: 5946.0,
        FEB: 5781.0,
        MAR: 5763.0,
        APR: 5187.0,
        MAY: 4623.0,
        JUN: 4355.0,
        JUL: 4653.0,
        AUG: 5487.0,
        SEP: 6325.0,
        OCT: 6385.0,
        NOV: 6421.0,
        DEC: 6188.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.650461434175615],
              [-40.099045178706334, -8.550461434175615],
              [-39.999045178706332, -8.550461434175615],
              [-39.999045178706332, -8.650461434175615],
              [-40.099045178706334, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '291',
        ID: 39402.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.6005,
        ANNUAL: 5621.0,
        JAN: 6090.0,
        FEB: 5815.0,
        MAR: 5827.0,
        APR: 5238.0,
        MAY: 4646.0,
        JUN: 4325.0,
        JUL: 4645.0,
        AUG: 5445.0,
        SEP: 6296.0,
        OCT: 6394.0,
        NOV: 6465.0,
        DEC: 6264.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.650461434175615],
              [-39.999045178706332, -8.550461434175615],
              [-39.899045178706331, -8.550461434175615],
              [-39.899045178706331, -8.650461434175615],
              [-39.999045178706332, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '292',
        ID: 39403.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.6005,
        ANNUAL: 5657.0,
        JAN: 6129.0,
        FEB: 5836.0,
        MAR: 5887.0,
        APR: 5281.0,
        MAY: 4706.0,
        JUN: 4368.0,
        JUL: 4620.0,
        AUG: 5434.0,
        SEP: 6310.0,
        OCT: 6432.0,
        NOV: 6544.0,
        DEC: 6337.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.650461434175615],
              [-39.899045178706331, -8.550461434175615],
              [-39.799045178706329, -8.550461434175615],
              [-39.799045178706329, -8.650461434175615],
              [-39.899045178706331, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '293',
        ID: 39404.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.6005,
        ANNUAL: 5698.0,
        JAN: 6161.0,
        FEB: 5864.0,
        MAR: 5928.0,
        APR: 5344.0,
        MAY: 4775.0,
        JUN: 4411.0,
        JUL: 4613.0,
        AUG: 5432.0,
        SEP: 6314.0,
        OCT: 6472.0,
        NOV: 6629.0,
        DEC: 6430.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.650461434175615],
              [-39.799045178706329, -8.550461434175615],
              [-39.699045178706328, -8.550461434175615],
              [-39.699045178706328, -8.650461434175615],
              [-39.799045178706329, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '294',
        ID: 39405.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.6005,
        ANNUAL: 5755.0,
        JAN: 6251.0,
        FEB: 6005.0,
        MAR: 6039.0,
        APR: 5456.0,
        MAY: 4805.0,
        JUN: 4423.0,
        JUL: 4671.0,
        AUG: 5437.0,
        SEP: 6320.0,
        OCT: 6494.0,
        NOV: 6675.0,
        DEC: 6482.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -8.650461434175615],
              [-39.699045178706328, -8.550461434175615],
              [-39.599045178706326, -8.550461434175615],
              [-39.599045178706326, -8.650461434175615],
              [-39.699045178706328, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '295',
        ID: 39406.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.6005,
        ANNUAL: 5753.0,
        JAN: 6272.0,
        FEB: 6025.0,
        MAR: 6100.0,
        APR: 5467.0,
        MAY: 4785.0,
        JUN: 4403.0,
        JUL: 4658.0,
        AUG: 5415.0,
        SEP: 6287.0,
        OCT: 6437.0,
        NOV: 6659.0,
        DEC: 6526.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.503013375, -8.600592346999973],
              [-39.514982947, -8.602510112999937],
              [-39.518612349, -8.603911062999934],
              [-39.520029735, -8.605291811999962],
              [-39.521259492999903, -8.608485123999976],
              [-39.523676077999902, -8.61084806999996],
              [-39.5262143859999, -8.612534739999935],
              [-39.529114155999899, -8.612873103999956],
              [-39.530721895999903, -8.613394420999947],
              [-39.531525775, -8.613655081999982],
              [-39.533887917, -8.615735398999965],
              [-39.535940771, -8.615735780999966],
              [-39.540532121, -8.618544097999964],
              [-39.545490511, -8.620680672999983],
              [-39.5506818429999, -8.62100878099994],
              [-39.555026771, -8.622133310999976],
              [-39.556658044, -8.623088631999963],
              [-39.557267348, -8.624889306999934],
              [-39.557515227, -8.628834270999956],
              [-39.56077506, -8.628378588999952],
              [-39.563146613, -8.629586833999948],
              [-39.564522193, -8.630287655999945],
              [-39.567544465, -8.634341114999938],
              [-39.568574941999898, -8.636651253999958],
              [-39.569057302, -8.636835323999955],
              [-39.56966698, -8.637067979999925],
              [-39.5773927709999, -8.640016172999932],
              [-39.580897632, -8.640465273999954],
              [-39.588575160999902, -8.646368442999977],
              [-39.590264047999902, -8.646708267999937],
              [-39.5928832929999, -8.649082096999964],
              [-39.594405002569893, -8.650461434175615],
              [-39.599045178706326, -8.650461434175615],
              [-39.599045178706326, -8.550461434175615],
              [-39.499045178706325, -8.550461434175615],
              [-39.499045178706325, -8.599642150329021],
              [-39.503013375, -8.600592346999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '296',
        ID: 39407.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -8.6005,
        ANNUAL: 5742.0,
        JAN: 6256.0,
        FEB: 6020.0,
        MAR: 6127.0,
        APR: 5449.0,
        MAY: 4770.0,
        JUN: 4384.0,
        JUL: 4639.0,
        AUG: 5380.0,
        SEP: 6265.0,
        OCT: 6443.0,
        NOV: 6658.0,
        DEC: 6514.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.424802761, -8.551396095999955],
              [-39.425338299, -8.55230568099995],
              [-39.426051469, -8.556435540999926],
              [-39.427842246999901, -8.558658097999979],
              [-39.431985128999898, -8.560611457999935],
              [-39.435490356999907, -8.563301842999982],
              [-39.439325883, -8.563410366999966],
              [-39.444453451, -8.561801597999935],
              [-39.452109575, -8.563199149999951],
              [-39.456782819, -8.568472799999942],
              [-39.457548308999897, -8.569336637999982],
              [-39.458702333, -8.572439518999932],
              [-39.45958024999991, -8.579185977999943],
              [-39.461246570999897, -8.582040166999953],
              [-39.469556387999909, -8.588732220999933],
              [-39.470672916, -8.589125609999932],
              [-39.471789444, -8.589518998999944],
              [-39.476111293, -8.592793202999934],
              [-39.482384470999904, -8.59395308099994],
              [-39.483315945999898, -8.594654088999967],
              [-39.485696748, -8.596445827999963],
              [-39.499045178706325, -8.599642150329021],
              [-39.499045178706325, -8.550461434175615],
              [-39.423726082570752, -8.550461434175615],
              [-39.424802761, -8.551396095999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '297',
        ID: 39408.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -8.6005,
        ANNUAL: 5803.0,
        JAN: 6318.0,
        FEB: 6114.0,
        MAR: 6155.0,
        APR: 5509.0,
        MAY: 4821.0,
        JUN: 4453.0,
        JUL: 4743.0,
        AUG: 5478.0,
        SEP: 6293.0,
        OCT: 6503.0,
        NOV: 6694.0,
        DEC: 6558.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.3024468, -8.560584520999955],
              [-39.312460680999898, -8.558311543999935],
              [-39.320398008, -8.560037511999951],
              [-39.324431391, -8.560014070999971],
              [-39.332336653, -8.558286050999927],
              [-39.335928622, -8.558787077999966],
              [-39.338428006, -8.559135830999949],
              [-39.342307018, -8.559677089999985],
              [-39.344678721, -8.55879206699996],
              [-39.344911219, -8.558646324999927],
              [-39.345143717, -8.558500582999953],
              [-39.350264302, -8.553149899999934],
              [-39.352837153983096, -8.550461434175615],
              [-39.299045178706322, -8.550461434175615],
              [-39.299045178706322, -8.560600974547688],
              [-39.3024468, -8.560584520999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '298',
        ID: 39409.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.6005,
        ANNUAL: 5821.0,
        JAN: 6359.0,
        FEB: 6159.0,
        MAR: 6172.0,
        APR: 5549.0,
        MAY: 4862.0,
        JUN: 4488.0,
        JUL: 4763.0,
        AUG: 5484.0,
        SEP: 6281.0,
        OCT: 6499.0,
        NOV: 6704.0,
        DEC: 6529.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.246662393, -8.647590453999953],
              [-39.245610583, -8.645413141999946],
              [-39.241646201, -8.641752476999953],
              [-39.241644536, -8.641751407999951],
              [-39.241197176999897, -8.640615764999945],
              [-39.241076407, -8.640309375999951],
              [-39.241799347, -8.636708953999976],
              [-39.24187999, -8.636307386999919],
              [-39.243064194999903, -8.633556838999949],
              [-39.243871133, -8.631682311999946],
              [-39.243978679999898, -8.631483930999932],
              [-39.244373568, -8.630754741999965],
              [-39.245247305, -8.62914571799995],
              [-39.246494302, -8.626848579999946],
              [-39.246784563999903, -8.626462034999962],
              [-39.247883295, -8.624998835999966],
              [-39.252117059999897, -8.621022244999949],
              [-39.253222693999902, -8.619983771999967],
              [-39.253224076, -8.619983124999971],
              [-39.263405126999899, -8.615218475999944],
              [-39.264466424, -8.614128984999979],
              [-39.264691110999898, -8.613898327999955],
              [-39.2664791669999, -8.610939261999924],
              [-39.267743879, -8.608845788999924],
              [-39.268259002, -8.60666505599994],
              [-39.268969964, -8.603655246999947],
              [-39.272698332999902, -8.596493627999955],
              [-39.271858549999898, -8.589803258999956],
              [-39.272623703, -8.583791018999923],
              [-39.275159824999903, -8.576825035999944],
              [-39.281392728, -8.570530860999948],
              [-39.284105871999898, -8.565867949999934],
              [-39.28642129, -8.563759797999987],
              [-39.293215612, -8.560629171999956],
              [-39.299045178706322, -8.560600974547688],
              [-39.299045178706322, -8.550461434175615],
              [-39.199045178706321, -8.550461434175615],
              [-39.199045178706321, -8.650461434175615],
              [-39.246758525767909, -8.650461434175615],
              [-39.246662393, -8.647590453999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '299',
        ID: 39410.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.6005,
        ANNUAL: 5812.0,
        JAN: 6358.0,
        FEB: 6124.0,
        MAR: 6095.0,
        APR: 5544.0,
        MAY: 4877.0,
        JUN: 4464.0,
        JUL: 4701.0,
        AUG: 5481.0,
        SEP: 6337.0,
        OCT: 6549.0,
        NOV: 6753.0,
        DEC: 6465.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -8.650461434175615],
              [-39.199045178706321, -8.550461434175615],
              [-39.099045178706319, -8.550461434175615],
              [-39.099045178706319, -8.650461434175615],
              [-39.199045178706321, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '300',
        ID: 39411.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.6005,
        ANNUAL: 5837.0,
        JAN: 6418.0,
        FEB: 6138.0,
        MAR: 6134.0,
        APR: 5595.0,
        MAY: 4879.0,
        JUN: 4478.0,
        JUL: 4735.0,
        AUG: 5496.0,
        SEP: 6337.0,
        OCT: 6561.0,
        NOV: 6784.0,
        DEC: 6486.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.099045178706319, -8.650461434175615],
              [-39.099045178706319, -8.550461434175615],
              [-38.999045178706318, -8.550461434175615],
              [-38.999045178706318, -8.650461434175615],
              [-39.099045178706319, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '301',
        ID: 39412.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.6005,
        ANNUAL: 5833.0,
        JAN: 6424.0,
        FEB: 6131.0,
        MAR: 6153.0,
        APR: 5588.0,
        MAY: 4851.0,
        JUN: 4460.0,
        JUL: 4726.0,
        AUG: 5458.0,
        SEP: 6320.0,
        OCT: 6569.0,
        NOV: 6797.0,
        DEC: 6522.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.650461434175615],
              [-38.999045178706318, -8.550461434175615],
              [-38.899045178706317, -8.550461434175615],
              [-38.899045178706317, -8.650461434175615],
              [-38.999045178706318, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '302',
        ID: 39413.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.6005,
        ANNUAL: 5807.0,
        JAN: 6366.0,
        FEB: 6171.0,
        MAR: 6172.0,
        APR: 5560.0,
        MAY: 4802.0,
        JUN: 4375.0,
        JUL: 4653.0,
        AUG: 5403.0,
        SEP: 6306.0,
        OCT: 6549.0,
        NOV: 6776.0,
        DEC: 6553.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.650461434175615],
              [-38.899045178706317, -8.550461434175615],
              [-38.799045178706315, -8.550461434175615],
              [-38.799045178706315, -8.650461434175615],
              [-38.899045178706317, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '303',
        ID: 39414.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.6005,
        ANNUAL: 5789.0,
        JAN: 6347.0,
        FEB: 6164.0,
        MAR: 6159.0,
        APR: 5552.0,
        MAY: 4768.0,
        JUN: 4344.0,
        JUL: 4636.0,
        AUG: 5356.0,
        SEP: 6290.0,
        OCT: 6544.0,
        NOV: 6740.0,
        DEC: 6565.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.650461434175615],
              [-38.799045178706315, -8.550461434175615],
              [-38.699045178706314, -8.550461434175615],
              [-38.699045178706314, -8.650461434175615],
              [-38.799045178706315, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '304',
        ID: 39415.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.6005,
        ANNUAL: 5765.0,
        JAN: 6343.0,
        FEB: 6160.0,
        MAR: 6169.0,
        APR: 5574.0,
        MAY: 4744.0,
        JUN: 4278.0,
        JUL: 4575.0,
        AUG: 5282.0,
        SEP: 6237.0,
        OCT: 6530.0,
        NOV: 6719.0,
        DEC: 6563.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.650461434175615],
              [-38.699045178706314, -8.550461434175615],
              [-38.599045178706312, -8.550461434175615],
              [-38.599045178706312, -8.650461434175615],
              [-38.699045178706314, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '305',
        ID: 39416.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.6005,
        ANNUAL: 5734.0,
        JAN: 6367.0,
        FEB: 6161.0,
        MAR: 6139.0,
        APR: 5610.0,
        MAY: 4742.0,
        JUN: 4189.0,
        JUL: 4464.0,
        AUG: 5213.0,
        SEP: 6208.0,
        OCT: 6476.0,
        NOV: 6735.0,
        DEC: 6510.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.650461434175615],
              [-38.599045178706312, -8.550461434175615],
              [-38.499045178706311, -8.550461434175615],
              [-38.499045178706311, -8.650461434175615],
              [-38.599045178706312, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '306',
        ID: 39417.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.6005,
        ANNUAL: 5721.0,
        JAN: 6339.0,
        FEB: 6106.0,
        MAR: 6179.0,
        APR: 5563.0,
        MAY: 4711.0,
        JUN: 4213.0,
        JUL: 4458.0,
        AUG: 5212.0,
        SEP: 6191.0,
        OCT: 6465.0,
        NOV: 6703.0,
        DEC: 6509.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.650461434175615],
              [-38.499045178706311, -8.550461434175615],
              [-38.399045178706309, -8.550461434175615],
              [-38.399045178706309, -8.650461434175615],
              [-38.499045178706311, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '307',
        ID: 39418.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.6005,
        ANNUAL: 5719.0,
        JAN: 6334.0,
        FEB: 6065.0,
        MAR: 6204.0,
        APR: 5555.0,
        MAY: 4686.0,
        JUN: 4223.0,
        JUL: 4470.0,
        AUG: 5209.0,
        SEP: 6200.0,
        OCT: 6456.0,
        NOV: 6714.0,
        DEC: 6508.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.650461434175615],
              [-38.399045178706309, -8.550461434175615],
              [-38.299045178706308, -8.550461434175615],
              [-38.299045178706308, -8.650461434175615],
              [-38.399045178706309, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '308',
        ID: 39419.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.6005,
        ANNUAL: 5712.0,
        JAN: 6314.0,
        FEB: 6049.0,
        MAR: 6225.0,
        APR: 5561.0,
        MAY: 4673.0,
        JUN: 4229.0,
        JUL: 4481.0,
        AUG: 5205.0,
        SEP: 6173.0,
        OCT: 6428.0,
        NOV: 6685.0,
        DEC: 6520.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.650461434175615],
              [-38.299045178706308, -8.550461434175615],
              [-38.199045178706307, -8.550461434175615],
              [-38.199045178706307, -8.650461434175615],
              [-38.299045178706308, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '309',
        ID: 39420.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.6005,
        ANNUAL: 5709.0,
        JAN: 6354.0,
        FEB: 6067.0,
        MAR: 6166.0,
        APR: 5522.0,
        MAY: 4666.0,
        JUN: 4224.0,
        JUL: 4519.0,
        AUG: 5220.0,
        SEP: 6187.0,
        OCT: 6409.0,
        NOV: 6704.0,
        DEC: 6471.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.650461434175615],
              [-38.199045178706307, -8.550461434175615],
              [-38.099045178706305, -8.550461434175615],
              [-38.099045178706305, -8.650461434175615],
              [-38.199045178706307, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '310',
        ID: 39421.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.6005,
        ANNUAL: 5743.0,
        JAN: 6403.0,
        FEB: 6105.0,
        MAR: 6171.0,
        APR: 5555.0,
        MAY: 4712.0,
        JUN: 4236.0,
        JUL: 4559.0,
        AUG: 5286.0,
        SEP: 6229.0,
        OCT: 6429.0,
        NOV: 6746.0,
        DEC: 6487.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.650461434175615],
              [-38.099045178706305, -8.550461434175615],
              [-37.999045178706304, -8.550461434175615],
              [-37.999045178706304, -8.650461434175615],
              [-38.099045178706305, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '311',
        ID: 39422.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.6005,
        ANNUAL: 5756.0,
        JAN: 6451.0,
        FEB: 6095.0,
        MAR: 6197.0,
        APR: 5595.0,
        MAY: 4726.0,
        JUN: 4254.0,
        JUL: 4520.0,
        AUG: 5275.0,
        SEP: 6219.0,
        OCT: 6458.0,
        NOV: 6786.0,
        DEC: 6496.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.650461434175615],
              [-37.999045178706304, -8.550461434175615],
              [-37.899045178706302, -8.550461434175615],
              [-37.899045178706302, -8.650461434175615],
              [-37.999045178706304, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '312',
        ID: 39423.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.6005,
        ANNUAL: 5728.0,
        JAN: 6437.0,
        FEB: 6086.0,
        MAR: 6155.0,
        APR: 5573.0,
        MAY: 4693.0,
        JUN: 4228.0,
        JUL: 4459.0,
        AUG: 5220.0,
        SEP: 6160.0,
        OCT: 6408.0,
        NOV: 6804.0,
        DEC: 6516.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.650461434175615],
              [-37.899045178706302, -8.550461434175615],
              [-37.799045178706301, -8.550461434175615],
              [-37.799045178706301, -8.650461434175615],
              [-37.899045178706302, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '313',
        ID: 39424.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.6005,
        ANNUAL: 5705.0,
        JAN: 6404.0,
        FEB: 6062.0,
        MAR: 6139.0,
        APR: 5582.0,
        MAY: 4668.0,
        JUN: 4194.0,
        JUL: 4390.0,
        AUG: 5189.0,
        SEP: 6104.0,
        OCT: 6403.0,
        NOV: 6791.0,
        DEC: 6531.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.650461434175615],
              [-37.799045178706301, -8.550461434175615],
              [-37.699045178706299, -8.550461434175615],
              [-37.699045178706299, -8.650461434175615],
              [-37.799045178706301, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '314',
        ID: 39425.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.6005,
        ANNUAL: 5704.0,
        JAN: 6381.0,
        FEB: 6199.0,
        MAR: 6256.0,
        APR: 5588.0,
        MAY: 4713.0,
        JUN: 4171.0,
        JUL: 4371.0,
        AUG: 5149.0,
        SEP: 6058.0,
        OCT: 6363.0,
        NOV: 6731.0,
        DEC: 6469.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.650461434175615],
              [-37.699045178706299, -8.550461434175615],
              [-37.599045178706298, -8.550461434175615],
              [-37.599045178706298, -8.650461434175615],
              [-37.699045178706299, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '315',
        ID: 39426.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.6005,
        ANNUAL: 5667.0,
        JAN: 6345.0,
        FEB: 6130.0,
        MAR: 6163.0,
        APR: 5537.0,
        MAY: 4679.0,
        JUN: 4143.0,
        JUL: 4335.0,
        AUG: 5144.0,
        SEP: 6041.0,
        OCT: 6347.0,
        NOV: 6695.0,
        DEC: 6449.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.650461434175615],
              [-37.599045178706298, -8.550461434175615],
              [-37.499045178706297, -8.550461434175615],
              [-37.499045178706297, -8.650461434175615],
              [-37.599045178706298, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '316',
        ID: 39427.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.6005,
        ANNUAL: 5654.0,
        JAN: 6330.0,
        FEB: 6033.0,
        MAR: 6089.0,
        APR: 5495.0,
        MAY: 4686.0,
        JUN: 4188.0,
        JUL: 4346.0,
        AUG: 5162.0,
        SEP: 6041.0,
        OCT: 6342.0,
        NOV: 6697.0,
        DEC: 6437.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.650461434175615],
              [-37.499045178706297, -8.550461434175615],
              [-37.399045178706295, -8.550461434175615],
              [-37.399045178706295, -8.650461434175615],
              [-37.499045178706297, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '317',
        ID: 39428.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.6005,
        ANNUAL: 5691.0,
        JAN: 6305.0,
        FEB: 6054.0,
        MAR: 6143.0,
        APR: 5537.0,
        MAY: 4657.0,
        JUN: 4225.0,
        JUL: 4448.0,
        AUG: 5200.0,
        SEP: 6109.0,
        OCT: 6382.0,
        NOV: 6752.0,
        DEC: 6476.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.650461434175615],
              [-37.399045178706295, -8.550461434175615],
              [-37.299045178706294, -8.550461434175615],
              [-37.299045178706294, -8.650461434175615],
              [-37.399045178706295, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '318',
        ID: 39429.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.6005,
        ANNUAL: 5699.0,
        JAN: 6320.0,
        FEB: 6104.0,
        MAR: 6192.0,
        APR: 5541.0,
        MAY: 4669.0,
        JUN: 4199.0,
        JUL: 4397.0,
        AUG: 5168.0,
        SEP: 6117.0,
        OCT: 6402.0,
        NOV: 6761.0,
        DEC: 6516.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.650461434175615],
              [-37.299045178706294, -8.550461434175615],
              [-37.199045178706292, -8.550461434175615],
              [-37.199045178706292, -8.650461434175615],
              [-37.299045178706294, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '319',
        ID: 39430.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.6005,
        ANNUAL: 5650.0,
        JAN: 6309.0,
        FEB: 6108.0,
        MAR: 6178.0,
        APR: 5543.0,
        MAY: 4612.0,
        JUN: 4100.0,
        JUL: 4259.0,
        AUG: 5043.0,
        SEP: 6012.0,
        OCT: 6354.0,
        NOV: 6746.0,
        DEC: 6535.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -8.650461434175615],
              [-37.199045178706292, -8.550461434175615],
              [-37.099045178706291, -8.550461434175615],
              [-37.099045178706291, -8.650461434175615],
              [-37.199045178706292, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '320',
        ID: 39431.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.6005,
        ANNUAL: 5558.0,
        JAN: 6204.0,
        FEB: 6044.0,
        MAR: 6111.0,
        APR: 5483.0,
        MAY: 4504.0,
        JUN: 3941.0,
        JUL: 4190.0,
        AUG: 4909.0,
        SEP: 5896.0,
        OCT: 6226.0,
        NOV: 6688.0,
        DEC: 6501.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -8.650461434175615],
              [-37.099045178706291, -8.550461434175615],
              [-36.99904517870629, -8.550461434175615],
              [-36.99904517870629, -8.650461434175615],
              [-37.099045178706291, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '321',
        ID: 39432.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.6005,
        ANNUAL: 5535.0,
        JAN: 6217.0,
        FEB: 6035.0,
        MAR: 6089.0,
        APR: 5453.0,
        MAY: 4505.0,
        JUN: 3966.0,
        JUL: 4132.0,
        AUG: 4875.0,
        SEP: 5807.0,
        OCT: 6192.0,
        NOV: 6667.0,
        DEC: 6477.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -8.650461434175615],
              [-36.99904517870629, -8.550461434175615],
              [-36.899045178706288, -8.550461434175615],
              [-36.899045178706288, -8.650461434175615],
              [-36.99904517870629, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '322',
        ID: 39433.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.6005,
        ANNUAL: 5508.0,
        JAN: 6247.0,
        FEB: 6008.0,
        MAR: 6034.0,
        APR: 5433.0,
        MAY: 4473.0,
        JUN: 3994.0,
        JUL: 4021.0,
        AUG: 4843.0,
        SEP: 5806.0,
        OCT: 6182.0,
        NOV: 6629.0,
        DEC: 6433.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -8.650461434175615],
              [-36.899045178706288, -8.550461434175615],
              [-36.799045178706287, -8.550461434175615],
              [-36.799045178706287, -8.650461434175615],
              [-36.899045178706288, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '323',
        ID: 39434.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.6005,
        ANNUAL: 5464.0,
        JAN: 6268.0,
        FEB: 5919.0,
        MAR: 5938.0,
        APR: 5352.0,
        MAY: 4386.0,
        JUN: 3938.0,
        JUL: 4053.0,
        AUG: 4802.0,
        SEP: 5756.0,
        OCT: 6150.0,
        NOV: 6580.0,
        DEC: 6422.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -8.650461434175615],
              [-36.799045178706287, -8.550461434175615],
              [-36.699045178706285, -8.550461434175615],
              [-36.699045178706285, -8.650461434175615],
              [-36.799045178706287, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '324',
        ID: 39435.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.6005,
        ANNUAL: 5420.0,
        JAN: 6186.0,
        FEB: 5895.0,
        MAR: 5916.0,
        APR: 5333.0,
        MAY: 4372.0,
        JUN: 3953.0,
        JUL: 4060.0,
        AUG: 4795.0,
        SEP: 5685.0,
        OCT: 6030.0,
        NOV: 6505.0,
        DEC: 6310.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -8.650461434175615],
              [-36.699045178706285, -8.550461434175615],
              [-36.599045178706284, -8.550461434175615],
              [-36.599045178706284, -8.650461434175615],
              [-36.699045178706285, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '325',
        ID: 39436.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.6005,
        ANNUAL: 5327.0,
        JAN: 5991.0,
        FEB: 5824.0,
        MAR: 5867.0,
        APR: 5265.0,
        MAY: 4312.0,
        JUN: 3901.0,
        JUL: 4026.0,
        AUG: 4727.0,
        SEP: 5565.0,
        OCT: 5870.0,
        NOV: 6380.0,
        DEC: 6199.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.650461434175615],
              [-36.599045178706284, -8.550461434175615],
              [-36.499045178706282, -8.550461434175615],
              [-36.499045178706282, -8.650461434175615],
              [-36.599045178706284, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '326',
        ID: 39437.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.6005,
        ANNUAL: 5312.0,
        JAN: 5929.0,
        FEB: 5821.0,
        MAR: 5876.0,
        APR: 5268.0,
        MAY: 4362.0,
        JUN: 3949.0,
        JUL: 4046.0,
        AUG: 4778.0,
        SEP: 5503.0,
        OCT: 5809.0,
        NOV: 6257.0,
        DEC: 6144.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.650461434175615],
              [-36.499045178706282, -8.550461434175615],
              [-36.399045178706281, -8.550461434175615],
              [-36.399045178706281, -8.650461434175615],
              [-36.499045178706282, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '327',
        ID: 39438.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.6005,
        ANNUAL: 5290.0,
        JAN: 5885.0,
        FEB: 5796.0,
        MAR: 5850.0,
        APR: 5244.0,
        MAY: 4350.0,
        JUN: 3953.0,
        JUL: 4057.0,
        AUG: 4780.0,
        SEP: 5459.0,
        OCT: 5760.0,
        NOV: 6240.0,
        DEC: 6102.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.650461434175615],
              [-36.399045178706281, -8.550461434175615],
              [-36.29904517870628, -8.550461434175615],
              [-36.29904517870628, -8.650461434175615],
              [-36.399045178706281, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '328',
        ID: 39439.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.6005,
        ANNUAL: 5257.0,
        JAN: 5854.0,
        FEB: 5727.0,
        MAR: 5788.0,
        APR: 5193.0,
        MAY: 4362.0,
        JUN: 3923.0,
        JUL: 4022.0,
        AUG: 4697.0,
        SEP: 5416.0,
        OCT: 5764.0,
        NOV: 6247.0,
        DEC: 6097.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.650461434175615],
              [-36.29904517870628, -8.550461434175615],
              [-36.199045178706278, -8.550461434175615],
              [-36.199045178706278, -8.650461434175615],
              [-36.29904517870628, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '329',
        ID: 39440.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.6005,
        ANNUAL: 5235.0,
        JAN: 5887.0,
        FEB: 5699.0,
        MAR: 5752.0,
        APR: 5146.0,
        MAY: 4321.0,
        JUN: 3867.0,
        JUL: 4014.0,
        AUG: 4627.0,
        SEP: 5395.0,
        OCT: 5741.0,
        NOV: 6275.0,
        DEC: 6101.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.650461434175615],
              [-36.199045178706278, -8.550461434175615],
              [-36.099045178706277, -8.550461434175615],
              [-36.099045178706277, -8.650461434175615],
              [-36.199045178706278, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '330',
        ID: 39441.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.6005,
        ANNUAL: 5270.0,
        JAN: 5947.0,
        FEB: 5762.0,
        MAR: 5801.0,
        APR: 5148.0,
        MAY: 4314.0,
        JUN: 3875.0,
        JUL: 4031.0,
        AUG: 4657.0,
        SEP: 5455.0,
        OCT: 5782.0,
        NOV: 6309.0,
        DEC: 6158.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.650461434175615],
              [-36.099045178706277, -8.550461434175615],
              [-35.999045178706275, -8.550461434175615],
              [-35.999045178706275, -8.650461434175615],
              [-36.099045178706277, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '331',
        ID: 39442.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.6005,
        ANNUAL: 5283.0,
        JAN: 6007.0,
        FEB: 5825.0,
        MAR: 5932.0,
        APR: 5180.0,
        MAY: 4352.0,
        JUN: 3943.0,
        JUL: 3987.0,
        AUG: 4641.0,
        SEP: 5429.0,
        OCT: 5763.0,
        NOV: 6220.0,
        DEC: 6120.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.650461434175615],
              [-35.999045178706275, -8.550461434175615],
              [-35.899045178706274, -8.550461434175615],
              [-35.899045178706274, -8.650461434175615],
              [-35.999045178706275, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '332',
        ID: 39443.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.6005,
        ANNUAL: 5256.0,
        JAN: 5912.0,
        FEB: 5839.0,
        MAR: 5886.0,
        APR: 5175.0,
        MAY: 4354.0,
        JUN: 3907.0,
        JUL: 3955.0,
        AUG: 4624.0,
        SEP: 5386.0,
        OCT: 5736.0,
        NOV: 6191.0,
        DEC: 6112.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.650461434175615],
              [-35.899045178706274, -8.550461434175615],
              [-35.799045178706272, -8.550461434175615],
              [-35.799045178706272, -8.650461434175615],
              [-35.899045178706274, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '333',
        ID: 39444.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.6005,
        ANNUAL: 5163.0,
        JAN: 5811.0,
        FEB: 5741.0,
        MAR: 5797.0,
        APR: 5121.0,
        MAY: 4299.0,
        JUN: 3806.0,
        JUL: 3869.0,
        AUG: 4510.0,
        SEP: 5247.0,
        OCT: 5585.0,
        NOV: 6130.0,
        DEC: 6046.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.650461434175615],
              [-35.799045178706272, -8.550461434175615],
              [-35.699045178706271, -8.550461434175615],
              [-35.699045178706271, -8.650461434175615],
              [-35.799045178706272, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '334',
        ID: 39445.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.6005,
        ANNUAL: 5005.0,
        JAN: 5672.0,
        FEB: 5540.0,
        MAR: 5591.0,
        APR: 4960.0,
        MAY: 4146.0,
        JUN: 3739.0,
        JUL: 3699.0,
        AUG: 4357.0,
        SEP: 5128.0,
        OCT: 5410.0,
        NOV: 5933.0,
        DEC: 5888.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.650461434175615],
              [-35.699045178706271, -8.550461434175615],
              [-35.59904517870627, -8.550461434175615],
              [-35.59904517870627, -8.650461434175615],
              [-35.699045178706271, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '335',
        ID: 39446.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.6005,
        ANNUAL: 5045.0,
        JAN: 5652.0,
        FEB: 5570.0,
        MAR: 5620.0,
        APR: 4937.0,
        MAY: 4179.0,
        JUN: 3821.0,
        JUL: 3826.0,
        AUG: 4432.0,
        SEP: 5202.0,
        OCT: 5473.0,
        NOV: 5928.0,
        DEC: 5898.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.650461434175615],
              [-35.59904517870627, -8.550461434175615],
              [-35.499045178706268, -8.550461434175615],
              [-35.499045178706268, -8.650461434175615],
              [-35.59904517870627, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '336',
        ID: 39447.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.6005,
        ANNUAL: 5093.0,
        JAN: 5671.0,
        FEB: 5631.0,
        MAR: 5673.0,
        APR: 4931.0,
        MAY: 4206.0,
        JUN: 3885.0,
        JUL: 3911.0,
        AUG: 4500.0,
        SEP: 5272.0,
        OCT: 5555.0,
        NOV: 5935.0,
        DEC: 5945.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.650461434175615],
              [-35.499045178706268, -8.550461434175615],
              [-35.399045178706267, -8.550461434175615],
              [-35.399045178706267, -8.650461434175615],
              [-35.499045178706268, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '337',
        ID: 39448.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.6005,
        ANNUAL: 5201.0,
        JAN: 5689.0,
        FEB: 5759.0,
        MAR: 5732.0,
        APR: 5029.0,
        MAY: 4342.0,
        JUN: 3954.0,
        JUL: 4038.0,
        AUG: 4667.0,
        SEP: 5375.0,
        OCT: 5682.0,
        NOV: 6050.0,
        DEC: 6091.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.650461434175615],
              [-35.399045178706267, -8.550461434175615],
              [-35.299045178706265, -8.550461434175615],
              [-35.299045178706265, -8.650461434175615],
              [-35.399045178706267, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '338',
        ID: 39449.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.6005,
        ANNUAL: 5243.0,
        JAN: 5715.0,
        FEB: 5874.0,
        MAR: 5873.0,
        APR: 5024.0,
        MAY: 4345.0,
        JUN: 3994.0,
        JUL: 4087.0,
        AUG: 4718.0,
        SEP: 5433.0,
        OCT: 5729.0,
        NOV: 6064.0,
        DEC: 6066.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.650461434175615],
              [-35.299045178706265, -8.550461434175615],
              [-35.199045178706264, -8.550461434175615],
              [-35.199045178706264, -8.650461434175615],
              [-35.299045178706265, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '339',
        ID: 39450.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.6005,
        ANNUAL: 5277.0,
        JAN: 5736.0,
        FEB: 5915.0,
        MAR: 5952.0,
        APR: 5055.0,
        MAY: 4398.0,
        JUN: 4031.0,
        JUL: 4146.0,
        AUG: 4794.0,
        SEP: 5449.0,
        OCT: 5775.0,
        NOV: 6039.0,
        DEC: 6037.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.650461434175615],
              [-35.199045178706264, -8.550461434175615],
              [-35.099045178706263, -8.550461434175615],
              [-35.099045178706263, -8.650461434175615],
              [-35.199045178706264, -8.650461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '340',
        ID: 39451.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.6005,
        ANNUAL: 5476.0,
        JAN: 5962.0,
        FEB: 6015.0,
        MAR: 6111.0,
        APR: 5195.0,
        MAY: 4544.0,
        JUN: 4208.0,
        JUL: 4301.0,
        AUG: 5072.0,
        SEP: 5686.0,
        OCT: 6005.0,
        NOV: 6290.0,
        DEC: 6323.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.047561330999898, -8.611300778999984],
              [-35.047717686999903, -8.611737585999954],
              [-35.047625675999903, -8.612166817999922],
              [-35.04764999, -8.612542462999949],
              [-35.047662058, -8.612728889999977],
              [-35.048294752999901, -8.613225982999941],
              [-35.048935324, -8.613330096999963],
              [-35.050025640999898, -8.613790026999943],
              [-35.050035915, -8.613794361999963],
              [-35.051402212, -8.615084109999941],
              [-35.052358458999898, -8.616222907999935],
              [-35.053968321, -8.618140099999948],
              [-35.054540221, -8.618858037999928],
              [-35.054952102, -8.619482613999933],
              [-35.055363933, -8.620271432999969],
              [-35.058009104999897, -8.625534174999935],
              [-35.060187756999902, -8.629861094999967],
              [-35.061802496, -8.633077125999931],
              [-35.06338167, -8.636050876999951],
              [-35.065671043, -8.640232248999949],
              [-35.067051202999899, -8.642686313999947],
              [-35.068219003, -8.644950939999942],
              [-35.0685026109999, -8.645500918999977],
              [-35.069791204, -8.648210648999962],
              [-35.070302958999903, -8.649309542999955],
              [-35.070769702999897, -8.650311784999921],
              [-35.070830644125174, -8.650461434175615],
              [-35.099045178706263, -8.650461434175615],
              [-35.099045178706263, -8.550461434175615],
              [-35.00468665126661, -8.550461434175615],
              [-35.005199248999901, -8.551014131999983],
              [-35.005676756, -8.551364181999926],
              [-35.005967784999903, -8.551768043999974],
              [-35.006755831, -8.552282316999936],
              [-35.008124841, -8.553400738999924],
              [-35.008744308, -8.554401608999965],
              [-35.008853534, -8.554851520999927],
              [-35.009115673999901, -8.555931298999955],
              [-35.009223505, -8.557193217999947],
              [-35.00907905, -8.557937797999951],
              [-35.008672472999898, -8.558945531999949],
              [-35.008385496, -8.559482618999944],
              [-35.008239368, -8.559756101999938],
              [-35.008014977, -8.560012989999958],
              [-35.007903374, -8.56025862899997],
              [-35.007749775, -8.560794052999942],
              [-35.007572161, -8.561305913999956],
              [-35.007375115999899, -8.561555531999948],
              [-35.007121105, -8.561644232999983],
              [-35.006913806999897, -8.561743084999932],
              [-35.006806895, -8.561994485999946],
              [-35.006920986999901, -8.562216770999955],
              [-35.006925678, -8.562222522999948],
              [-35.007288925999902, -8.562744364999952],
              [-35.007405463999902, -8.562993097999934],
              [-35.007545650999901, -8.563317911999956],
              [-35.007643268, -8.56379060799998],
              [-35.007685347999903, -8.564236422999945],
              [-35.007733768, -8.564378158999938],
              [-35.007910706, -8.564559766999924],
              [-35.0082745, -8.564635051999968],
              [-35.008416143, -8.564602751999924],
              [-35.008570982, -8.564447],
              [-35.008803688999897, -8.564212919999921],
              [-35.009384691, -8.563607133999932],
              [-35.010115624, -8.56297101699994],
              [-35.010770815999898, -8.562448905999934],
              [-35.010921034, -8.562329199999967],
              [-35.01179662, -8.561631460999919],
              [-35.013823448, -8.560632749999964],
              [-35.015611288999899, -8.56016334799995],
              [-35.016384, -8.560073791999971],
              [-35.017998553, -8.559886667999933],
              [-35.019508975, -8.559993958999945],
              [-35.020972621999903, -8.560398182999966],
              [-35.021250455, -8.560505337999928],
              [-35.022428754000011, -8.560959787999925],
              [-35.022643023999898, -8.561042428999938],
              [-35.02285633, -8.561228549999939],
              [-35.023842164, -8.562088743999938],
              [-35.025333784999901, -8.563449],
              [-35.026512661, -8.564534656999967],
              [-35.0274254569999, -8.565375274999946],
              [-35.028121568, -8.566121592999924],
              [-35.029047580999901, -8.567114395999958],
              [-35.030274683, -8.568337737999967],
              [-35.031662716, -8.569694138999985],
              [-35.032139687, -8.570049345999966],
              [-35.032823065999899, -8.570657543999976],
              [-35.033777121, -8.571612129999945],
              [-35.033796782, -8.571642572999965],
              [-35.034411725, -8.572594775999956],
              [-35.034825725, -8.573237575999935],
              [-35.035196718999899, -8.573786432999951],
              [-35.035467855, -8.574030518999953],
              [-35.036058997999902, -8.574876746999951],
              [-35.036519744, -8.575823979999949],
              [-35.036693919, -8.576671682999972],
              [-35.036628391, -8.577473632999949],
              [-35.036578348, -8.57778878699996],
              [-35.0366463, -8.577995579999939],
              [-35.036889298999903, -8.578205187999973],
              [-35.037505123, -8.578701181999968],
              [-35.037864575999897, -8.579148000999959],
              [-35.0383077279999, -8.580013149999955],
              [-35.038418756999903, -8.580986499999938],
              [-35.038252058, -8.582043736999948],
              [-35.0381933849999, -8.58245716899995],
              [-35.038750819, -8.58389129999995],
              [-35.038740659, -8.584820226999968],
              [-35.038916302, -8.585929780999944],
              [-35.039323997999901, -8.586697702999976],
              [-35.040413688999898, -8.587831854999926],
              [-35.041423494, -8.588763537999968],
              [-35.042977468999901, -8.590154850999975],
              [-35.044083784999899, -8.591422504999978],
              [-35.044728952, -8.592466682999937],
              [-35.045377125999899, -8.593843603999975],
              [-35.045935321999899, -8.595708131999967],
              [-35.046222326, -8.59762312599997],
              [-35.046206248, -8.598803574999973],
              [-35.0459969, -8.599904817999974],
              [-35.04581635800001, -8.600542494999956],
              [-35.045452828, -8.601691527999941],
              [-35.045195151, -8.602706057999971],
              [-35.044950736, -8.603358091999956],
              [-35.04487053, -8.604405875999921],
              [-35.044945100999897, -8.605171155999935],
              [-35.045149968, -8.605574012999964],
              [-35.045752440999898, -8.60647395699992],
              [-35.046609222, -8.606525386999971],
              [-35.047398745999899, -8.606227357999966],
              [-35.048012066999902, -8.606036919999944],
              [-35.04918595, -8.605672426999947],
              [-35.049191987, -8.606210813999944],
              [-35.049020965, -8.607051425999938],
              [-35.048891516, -8.607833090999977],
              [-35.048286, -8.609190815999968],
              [-35.048228978999902, -8.609356840999958],
              [-35.047561330999898, -8.611300778999984],
            ],
          ],
          [
            [
              [-35.022968394, -8.609086851999955],
              [-35.022994011, -8.60919727799995],
              [-35.022952651, -8.609371191999962],
              [-35.022911426999897, -8.609578549999977],
              [-35.022858527, -8.609754956999955],
              [-35.022749639, -8.609858582999955],
              [-35.022656783999899, -8.609848758999938],
              [-35.022623342, -8.609741218999943],
              [-35.022530885, -8.609727723999974],
              [-35.022462455, -8.609839343999971],
              [-35.022344686999901, -8.609990316999935],
              [-35.022153031999899, -8.610129758999967],
              [-35.0220087289999, -8.610248208999959],
              [-35.021965502999898, -8.610369924999933],
              [-35.022024288, -8.61052100399997],
              [-35.021972838999901, -8.610649278999963],
              [-35.021811294, -8.610788191999971],
              [-35.021735586, -8.610869151999962],
              [-35.021602822, -8.610985109999934],
              [-35.021514354, -8.611038894999986],
              [-35.021378931, -8.611109997999961],
              [-35.021291655, -8.611152765999979],
              [-35.021194566, -8.611216781999918],
              [-35.021131837, -8.611310433999956],
              [-35.021042436, -8.61133811899998],
              [-35.020927432999898, -8.611359380999936],
              [-35.020911383999902, -8.611368825999934],
              [-35.020818407, -8.611429562999939],
              [-35.020800768, -8.611453696999936],
              [-35.020739368, -8.611569775999943],
              [-35.020698801, -8.611736344999933],
              [-35.020691784999897, -8.611835889999952],
              [-35.020657164999903, -8.611843370999956],
              [-35.020617761, -8.611790914999965],
              [-35.020524905999899, -8.611781090999957],
              [-35.020435107, -8.611812448999961],
              [-35.020426884, -8.611819007999932],
              [-35.020331644, -8.611831217999953],
              [-35.020290611, -8.611897923999953],
              [-35.020263036, -8.612013867999952],
              [-35.020211586, -8.612142142999971],
              [-35.020209995999899, -8.612156831999938],
              [-35.020148994, -8.612269238999941],
              [-35.020068228, -8.612390697999954],
              [-35.020003113, -8.612506383999968],
              [-35.020000605999897, -8.612598978999932],
              [-35.020003128, -8.612610388999938],
              [-35.020079675, -8.612666773999925],
              [-35.020178109, -8.612729189999911],
              [-35.02018806, -8.612741385999939],
              [-35.020234892, -8.612794627999957],
              [-35.020197627, -8.61286125899994],
              [-35.020137145, -8.612899431999953],
              [-35.020123754999901, -8.612953730999951],
              [-35.020099757, -8.613036622999976],
              [-35.020083923, -8.613113487999954],
              [-35.020114049, -8.61321696399995],
              [-35.02017097, -8.613315846999971],
              [-35.020226301, -8.613429417999944],
              [-35.020269556, -8.613515710999931],
              [-35.020272475999903, -8.613523448999963],
              [-35.020338674, -8.613571310999927],
              [-35.020419317999902, -8.613520411999959],
              [-35.020467602999908, -8.61352552099992],
              [-35.020481542, -8.613604997999971],
              [-35.020498003999897, -8.613695884999974],
              [-35.020523620999903, -8.613806311999955],
              [-35.020522825999898, -8.613813655999936],
              [-35.020550429, -8.613905721999961],
              [-35.020552951, -8.613917130999944],
              [-35.020625126, -8.614013911999953],
              [-35.020658829, -8.614084335999964],
              [-35.020658034, -8.614091680999925],
              [-35.020703673999897, -8.614155938999946],
              [-35.0207065929999, -8.614163676999958],
              [-35.020699836999903, -8.614226105999931],
              [-35.020631926, -8.614263493999946],
              [-35.020535356, -8.614253277999985],
              [-35.020443966999899, -8.614299324999925],
              [-35.020444777, -8.614395983999946],
              [-35.0204628279999, -8.614472180999933],
              [-35.020466144999901, -8.614476245999978],
              [-35.020490966, -8.614594017999931],
              [-35.020497599999899, -8.614602147999953],
              [-35.020535949, -8.614699064999975],
              [-35.020545501999898, -8.614714932999952],
              [-35.020622583, -8.614801088999972],
              [-35.020617813999898, -8.614845156999936],
              [-35.020617415999901, -8.61484882899992],
              [-35.020572065, -8.614955462999944],
              [-35.020567158999903, -8.61496608699998],
              [-35.020518093, -8.615072326999952],
              [-35.0205637339999, -8.615136585999954],
              [-35.020580714999902, -8.615153239999927],
              [-35.020644925999903, -8.615219462999962],
              [-35.0206806159999, -8.615271525999963],
              [-35.020697078, -8.615362412999961],
              [-35.020772431999902, -8.615429814999967],
              [-35.020786095999902, -8.61544240399996],
              [-35.020882269, -8.615456291999978],
              [-35.020904157, -8.615462321999926],
              [-35.021015981999909, -8.615470437999932],
              [-35.021042775999902, -8.615465843999965],
              [-35.021111085, -8.615424783999963],
              [-35.02120819, -8.615464771999946],
              [-35.021284339, -8.615524828999988],
              [-35.021295084, -8.61552967999995],
              [-35.021349864999898, -8.615509474999955],
              [-35.021401193, -8.615451760999974],
              [-35.021450807, -8.615479295999927],
              [-35.021450288, -8.615553528999946],
              [-35.021428812, -8.615647830999924],
              [-35.02137484, -8.615764695999934],
              [-35.021374045999899, -8.615772040999968],
              [-35.0213950159999, -8.615855975999985],
              [-35.021535225, -8.615844808999949],
              [-35.021615730999898, -8.615760465999928],
              [-35.021695168, -8.615616579999935],
              [-35.021727509999899, -8.615456567999967],
              [-35.021764898, -8.615319376999926],
              [-35.021807054, -8.615138117999946],
              [-35.021803722, -8.615030048999929],
              [-35.021868959, -8.614843802999934],
              [-35.021887377, -8.614708319999986],
              [-35.021854729, -8.614593434999955],
              [-35.021792366999897, -8.614475406999929],
              [-35.021818488999898, -8.614407596999964],
              [-35.021807469, -8.614335858999931],
              [-35.021768721999898, -8.614242614999966],
              [-35.021750273999899, -8.614170089999952],
              [-35.021781302, -8.614091656999964],
              [-35.021852286999902, -8.614021128999978],
              [-35.021896843, -8.61392183999993],
              [-35.021897638, -8.61391449499995],
              [-35.021940865, -8.613792778999935],
              [-35.021955171999899, -8.613660574999933],
              [-35.021931939999902, -8.613528113999967],
              [-35.021926896, -8.613505293999939],
              [-35.021810684999899, -8.613433569999939],
              [-35.021731218999903, -8.613369447999963],
              [-35.021717936999899, -8.61324918299993],
              [-35.021691922999899, -8.613142428999934],
              [-35.021594283, -8.613072668999957],
              [-35.021528482, -8.613021135999919],
              [-35.021515199, -8.612900869999974],
              [-35.021558823, -8.612775481999956],
              [-35.021732705, -8.612626730999978],
              [-35.021982460999901, -8.61247114999998],
              [-35.022248937, -8.612369338999944],
              [-35.022572592, -8.612329291999968],
              [-35.022884308999899, -8.612295410999936],
              [-35.023154253, -8.612335110999933],
              [-35.023334002, -8.612409841999987],
              [-35.02349466, -8.612556839999966],
              [-35.023497975999902, -8.612560904999956],
              [-35.023547468999901, -8.612659],
              [-35.023423325999907, -8.612764725999972],
              [-35.023339504, -8.612845002999963],
              [-35.023272663999897, -8.612941933999929],
              [-35.023239251999897, -8.613042401999968],
              [-35.0232401989999, -8.613172503999987],
              [-35.023259588, -8.61328585199993],
              [-35.023281491, -8.613395885999982],
              [-35.023351938, -8.613473910999971],
              [-35.0233585719999, -8.613482041999935],
              [-35.023438434999903, -8.613542491999977],
              [-35.023449179999901, -8.613547342999933],
              [-35.023622557, -8.613576826999967],
              [-35.023641128, -8.613578790999957],
              [-35.023761572, -8.613576674999926],
              [-35.02387578, -8.613562755999958],
              [-35.02388732, -8.613560261999964],
              [-35.024025266, -8.613500567999951],
              [-35.0241414609999, -8.613468286999924],
              [-35.024222118999901, -8.61352139199994],
              [-35.024225435999909, -8.613525457999966],
              [-35.024312192, -8.613556920999937],
              [-35.02447655, -8.613600308999931],
              [-35.024491407, -8.613601880999974],
              [-35.024596994, -8.613598192999973],
              [-35.024716644, -8.613603421999935],
              [-35.024899435, -8.613615329999957],
              [-35.024914291999899, -8.61361690199992],
              [-35.025083679, -8.613579104999932],
              [-35.025106362, -8.613577789999972],
              [-35.025224544, -8.613527147999951],
              [-35.025298431, -8.613538678999925],
              [-35.025482951, -8.613569341999941],
              [-35.025601272, -8.613552142999945],
              [-35.025623955, -8.61355082899996],
              [-35.02569133, -8.613483668999955],
              [-35.025626582, -8.613387673999974],
              [-35.025575637, -8.613337711999975],
              [-35.025391723, -8.613236543999955],
              [-35.025384691999903, -8.613232085999927],
              [-35.025310389, -8.613120222999951],
              [-35.025236606, -8.612934126999958],
              [-35.025137359, -8.612740391999974],
              [-35.025057355999898, -8.612646495999973],
              [-35.024925751, -8.612543427999988],
              [-35.024786457999902, -8.612476689999943],
              [-35.024779426999899, -8.612472231999954],
              [-35.024634036, -8.612427135999949],
              [-35.024466619999899, -8.61234256599994],
              [-35.024419511, -8.612222435999964],
              [-35.024428115, -8.612108200999959],
              [-35.024562193, -8.611910665999966],
              [-35.02467079, -8.611636147999947],
              [-35.024780319999898, -8.611387728999947],
              [-35.024883492, -8.611198067999956],
              [-35.024892221, -8.611048017999952],
              [-35.024843642999897, -8.610872015999975],
              [-35.024840723999901, -8.610864278999939],
              [-35.024717068, -8.610687763999987],
              [-35.024703402999897, -8.610675174999933],
              [-35.024503339, -8.610475719999947],
              [-35.024346912999903, -8.610254880999939],
              [-35.024240638, -8.61009134999995],
              [-35.024131978, -8.609949851999938],
              [-35.024129059, -8.609942114999978],
              [-35.024057938999903, -8.609800873999957],
              [-35.024075302, -8.609709851999982],
              [-35.024142142, -8.609612920999954],
              [-35.024208049, -8.609489890999951],
              [-35.024338289, -8.609362522999955],
              [-35.024517608999901, -8.609232918999965],
              [-35.024679963, -8.609190663999925],
              [-35.024714184999901, -8.609186854999958],
              [-35.024934636999902, -8.609128458999972],
              [-35.0249457789999, -8.609129637999951],
              [-35.025146729, -8.609043177999983],
              [-35.025301121, -8.608970364999957],
              [-35.025440381, -8.608829091999958],
              [-35.025324306999899, -8.608790812999935],
              [-35.025065642, -8.608785737999932],
              [-35.024799288999901, -8.608816992999955],
              [-35.024578441, -8.60887906099992],
              [-35.024290080999897, -8.608974844999974],
              [-35.024157842, -8.609030673999932],
              [-35.024014453999897, -8.609071221999944],
              [-35.023950778, -8.609034770999925],
              [-35.023857248999903, -8.608961732999937],
              [-35.023854727, -8.608950322999936],
              [-35.023808017, -8.608826520999965],
              [-35.023750026999899, -8.608668096999953],
              [-35.023653840999899, -8.608550204999963],
              [-35.023544936999897, -8.608549827999983],
              [-35.023398369999903, -8.608619753999966],
              [-35.023219568999899, -8.608675126999971],
              [-35.023097947999908, -8.608792263999934],
              [-35.022969969, -8.608968158999966],
              [-35.022968394, -8.609086851999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '341',
        ID: 39776.0,
        COUNTRY: 'Brasil',
        LON: -41.149,
        LAT: -8.5005,
        ANNUAL: 5869.0,
        JAN: 6109.0,
        FEB: 5819.0,
        MAR: 5788.0,
        APR: 5335.0,
        MAY: 5043.0,
        JUN: 4961.0,
        JUL: 5308.0,
        AUG: 6165.0,
        SEP: 6667.0,
        OCT: 6602.0,
        NOV: 6455.0,
        DEC: 6175.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.152650528, -8.549765342999935],
              [-41.152045852, -8.548305877999944],
              [-41.152317277, -8.546051133999978],
              [-41.152444967, -8.54499145499994],
              [-41.152917129999899, -8.543508592999961],
              [-41.152486409999902, -8.54147652599994],
              [-41.151242576999898, -8.540157072999934],
              [-41.148286804, -8.538695715999948],
              [-41.1481163139999, -8.538177288999973],
              [-41.149120476, -8.536469767999968],
              [-41.149493546, -8.533917277999931],
              [-41.148376817, -8.533029254999963],
              [-41.147393498, -8.532975382999959],
              [-41.142989445, -8.534283343999952],
              [-41.140070823, -8.532853251999931],
              [-41.139173829, -8.532666207999979],
              [-41.134627348, -8.533538644999958],
              [-41.13250296, -8.535528443999969],
              [-41.131270551999897, -8.537050994999955],
              [-41.129605923, -8.540855540999933],
              [-41.128791304999901, -8.541504433999929],
              [-41.128269641, -8.541770881999975],
              [-41.126732859, -8.54195586399992],
              [-41.118992557999903, -8.537525184999936],
              [-41.116184846999907, -8.537269064999984],
              [-41.114417851, -8.53490438899996],
              [-41.111463993, -8.533631088999924],
              [-41.108377464999897, -8.530732018999977],
              [-41.104072204, -8.522368652999935],
              [-41.102563066999899, -8.521798095999946],
              [-41.100011007, -8.521803644999977],
              [-41.099045178706348, -8.521308614633183],
              [-41.099045178706348, -8.550461434175615],
              [-41.154156873014799, -8.550461434175615],
              [-41.152650528, -8.549765342999935],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '342',
        ID: 39777.0,
        COUNTRY: 'Brasil',
        LON: -41.049,
        LAT: -8.5005,
        ANNUAL: 5845.0,
        JAN: 6134.0,
        FEB: 5822.0,
        MAR: 5769.0,
        APR: 5266.0,
        MAY: 5022.0,
        JUN: 4894.0,
        JUL: 5254.0,
        AUG: 6117.0,
        SEP: 6635.0,
        OCT: 6604.0,
        NOV: 6453.0,
        DEC: 6166.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.097957583, -8.520751172999949],
              [-41.095584023, -8.518532964999959],
              [-41.094008394999904, -8.516314164999921],
              [-41.090489923, -8.51262553999994],
              [-41.085976629, -8.503555729999951],
              [-41.084684141, -8.500958376999963],
              [-41.083265976, -8.496622209999945],
              [-41.082254033, -8.491135101999932],
              [-41.081799697999898, -8.484692578999942],
              [-41.082194425999901, -8.480081499999928],
              [-41.079916633, -8.473650751999962],
              [-41.078926970999902, -8.472503234999982],
              [-41.07654944, -8.472490020999937],
              [-41.065403939, -8.475157954999926],
              [-41.057595173, -8.477657986999931],
              [-41.056676384, -8.478222180999984],
              [-41.055955156, -8.479389327999973],
              [-41.053232914, -8.481119140999967],
              [-41.052203630999898, -8.481218188999948],
              [-41.051098569, -8.480473777999986],
              [-41.0502605349999, -8.477944825999943],
              [-41.049967414, -8.475504121999952],
              [-41.050231704999902, -8.472383421999947],
              [-41.051595424, -8.465119737999947],
              [-41.051306347, -8.463358680999928],
              [-41.049673566, -8.459773387999961],
              [-41.048199435831833, -8.450461434175615],
              [-40.999045178706346, -8.450461434175615],
              [-40.999045178706346, -8.550461434175615],
              [-41.099045178706348, -8.550461434175615],
              [-41.099045178706348, -8.521308614633183],
              [-41.097957583, -8.520751172999949],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '343',
        ID: 39778.0,
        COUNTRY: 'Brasil',
        LON: -40.949,
        LAT: -8.5005,
        ANNUAL: 5818.0,
        JAN: 6146.0,
        FEB: 5842.0,
        MAR: 5801.0,
        APR: 5248.0,
        MAY: 4965.0,
        JUN: 4820.0,
        JUL: 5161.0,
        AUG: 6039.0,
        SEP: 6596.0,
        OCT: 6593.0,
        NOV: 6440.0,
        DEC: 6174.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.999045178706346, -8.550461434175615],
              [-40.999045178706346, -8.450461434175615],
              [-40.899045178706345, -8.450461434175615],
              [-40.899045178706345, -8.550461434175615],
              [-40.999045178706346, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '344',
        ID: 39779.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -8.5005,
        ANNUAL: 5745.0,
        JAN: 6040.0,
        FEB: 5740.0,
        MAR: 5773.0,
        APR: 5222.0,
        MAY: 4813.0,
        JUN: 4698.0,
        JUL: 5033.0,
        AUG: 5914.0,
        SEP: 6557.0,
        OCT: 6538.0,
        NOV: 6464.0,
        DEC: 6149.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.899045178706345, -8.550461434175615],
              [-40.899045178706345, -8.450461434175615],
              [-40.799045178706344, -8.450461434175615],
              [-40.799045178706344, -8.550461434175615],
              [-40.899045178706345, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '345',
        ID: 39780.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.5005,
        ANNUAL: 5710.0,
        JAN: 6029.0,
        FEB: 5767.0,
        MAR: 5780.0,
        APR: 5211.0,
        MAY: 4776.0,
        JUN: 4626.0,
        JUL: 4948.0,
        AUG: 5840.0,
        SEP: 6499.0,
        OCT: 6476.0,
        NOV: 6425.0,
        DEC: 6141.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.799045178706344, -8.550461434175615],
              [-40.799045178706344, -8.450461434175615],
              [-40.699045178706342, -8.450461434175615],
              [-40.699045178706342, -8.550461434175615],
              [-40.799045178706344, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '346',
        ID: 39781.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.5005,
        ANNUAL: 5664.0,
        JAN: 5992.0,
        FEB: 5753.0,
        MAR: 5742.0,
        APR: 5160.0,
        MAY: 4744.0,
        JUN: 4549.0,
        JUL: 4884.0,
        AUG: 5771.0,
        SEP: 6432.0,
        OCT: 6455.0,
        NOV: 6389.0,
        DEC: 6096.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -8.550461434175615],
              [-40.699045178706342, -8.450461434175615],
              [-40.599045178706341, -8.450461434175615],
              [-40.599045178706341, -8.550461434175615],
              [-40.699045178706342, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '347',
        ID: 39782.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.5005,
        ANNUAL: 5653.0,
        JAN: 5986.0,
        FEB: 5777.0,
        MAR: 5771.0,
        APR: 5122.0,
        MAY: 4719.0,
        JUN: 4530.0,
        JUL: 4848.0,
        AUG: 5742.0,
        SEP: 6462.0,
        OCT: 6413.0,
        NOV: 6393.0,
        DEC: 6079.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.550461434175615],
              [-40.599045178706341, -8.450461434175615],
              [-40.499045178706339, -8.450461434175615],
              [-40.499045178706339, -8.550461434175615],
              [-40.599045178706341, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '348',
        ID: 39783.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.5005,
        ANNUAL: 5655.0,
        JAN: 6029.0,
        FEB: 5780.0,
        MAR: 5791.0,
        APR: 5173.0,
        MAY: 4738.0,
        JUN: 4504.0,
        JUL: 4807.0,
        AUG: 5687.0,
        SEP: 6410.0,
        OCT: 6408.0,
        NOV: 6387.0,
        DEC: 6144.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.550461434175615],
              [-40.499045178706339, -8.450461434175615],
              [-40.399045178706338, -8.450461434175615],
              [-40.399045178706338, -8.550461434175615],
              [-40.499045178706339, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '349',
        ID: 39784.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.5005,
        ANNUAL: 5665.0,
        JAN: 6038.0,
        FEB: 5786.0,
        MAR: 5809.0,
        APR: 5199.0,
        MAY: 4736.0,
        JUN: 4506.0,
        JUL: 4786.0,
        AUG: 5641.0,
        SEP: 6405.0,
        OCT: 6438.0,
        NOV: 6449.0,
        DEC: 6189.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.550461434175615],
              [-40.399045178706338, -8.450461434175615],
              [-40.299045178706336, -8.450461434175615],
              [-40.299045178706336, -8.550461434175615],
              [-40.399045178706338, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '350',
        ID: 39785.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.5005,
        ANNUAL: 5651.0,
        JAN: 6057.0,
        FEB: 5818.0,
        MAR: 5762.0,
        APR: 5217.0,
        MAY: 4726.0,
        JUN: 4491.0,
        JUL: 4750.0,
        AUG: 5626.0,
        SEP: 6378.0,
        OCT: 6422.0,
        NOV: 6422.0,
        DEC: 6148.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.550461434175615],
              [-40.299045178706336, -8.450461434175615],
              [-40.199045178706335, -8.450461434175615],
              [-40.199045178706335, -8.550461434175615],
              [-40.299045178706336, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '351',
        ID: 39786.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.5005,
        ANNUAL: 5613.0,
        JAN: 5966.0,
        FEB: 5757.0,
        MAR: 5712.0,
        APR: 5185.0,
        MAY: 4694.0,
        JUN: 4443.0,
        JUL: 4711.0,
        AUG: 5564.0,
        SEP: 6346.0,
        OCT: 6424.0,
        NOV: 6431.0,
        DEC: 6121.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.550461434175615],
              [-40.199045178706335, -8.450461434175615],
              [-40.099045178706334, -8.450461434175615],
              [-40.099045178706334, -8.550461434175615],
              [-40.199045178706335, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '352',
        ID: 39787.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.5005,
        ANNUAL: 5589.0,
        JAN: 5935.0,
        FEB: 5755.0,
        MAR: 5734.0,
        APR: 5179.0,
        MAY: 4646.0,
        JUN: 4389.0,
        JUL: 4643.0,
        AUG: 5492.0,
        SEP: 6305.0,
        OCT: 6416.0,
        NOV: 6431.0,
        DEC: 6141.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.550461434175615],
              [-40.099045178706334, -8.450461434175615],
              [-39.999045178706332, -8.450461434175615],
              [-39.999045178706332, -8.550461434175615],
              [-40.099045178706334, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '353',
        ID: 39788.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.5005,
        ANNUAL: 5614.0,
        JAN: 6001.0,
        FEB: 5788.0,
        MAR: 5783.0,
        APR: 5227.0,
        MAY: 4669.0,
        JUN: 4358.0,
        JUL: 4640.0,
        AUG: 5458.0,
        SEP: 6292.0,
        OCT: 6427.0,
        NOV: 6468.0,
        DEC: 6256.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.550461434175615],
              [-39.999045178706332, -8.450461434175615],
              [-39.899045178706331, -8.450461434175615],
              [-39.899045178706331, -8.550461434175615],
              [-39.999045178706332, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '354',
        ID: 39789.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.5005,
        ANNUAL: 5671.0,
        JAN: 6122.0,
        FEB: 5833.0,
        MAR: 5885.0,
        APR: 5296.0,
        MAY: 4724.0,
        JUN: 4383.0,
        JUL: 4637.0,
        AUG: 5459.0,
        SEP: 6317.0,
        OCT: 6454.0,
        NOV: 6583.0,
        DEC: 6359.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.550461434175615],
              [-39.899045178706331, -8.450461434175615],
              [-39.799045178706329, -8.450461434175615],
              [-39.799045178706329, -8.550461434175615],
              [-39.899045178706331, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '355',
        ID: 39790.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.5005,
        ANNUAL: 5702.0,
        JAN: 6207.0,
        FEB: 5843.0,
        MAR: 5939.0,
        APR: 5371.0,
        MAY: 4764.0,
        JUN: 4374.0,
        JUL: 4600.0,
        AUG: 5447.0,
        SEP: 6304.0,
        OCT: 6493.0,
        NOV: 6653.0,
        DEC: 6434.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.550461434175615],
              [-39.799045178706329, -8.450461434175615],
              [-39.699045178706328, -8.450461434175615],
              [-39.699045178706328, -8.550461434175615],
              [-39.799045178706329, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '356',
        ID: 39791.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.5005,
        ANNUAL: 5728.0,
        JAN: 6221.0,
        FEB: 5915.0,
        MAR: 6037.0,
        APR: 5404.0,
        MAY: 4801.0,
        JUN: 4442.0,
        JUL: 4669.0,
        AUG: 5435.0,
        SEP: 6327.0,
        OCT: 6466.0,
        NOV: 6647.0,
        DEC: 6373.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -8.550461434175615],
              [-39.699045178706328, -8.450461434175615],
              [-39.599045178706326, -8.450461434175615],
              [-39.599045178706326, -8.550461434175615],
              [-39.699045178706328, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '357',
        ID: 39792.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.5005,
        ANNUAL: 5741.0,
        JAN: 6260.0,
        FEB: 5952.0,
        MAR: 6044.0,
        APR: 5416.0,
        MAY: 4794.0,
        JUN: 4428.0,
        JUL: 4663.0,
        AUG: 5424.0,
        SEP: 6313.0,
        OCT: 6479.0,
        NOV: 6691.0,
        DEC: 6428.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -8.550461434175615],
              [-39.599045178706326, -8.450461434175615],
              [-39.499045178706325, -8.450461434175615],
              [-39.499045178706325, -8.550461434175615],
              [-39.599045178706326, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '358',
        ID: 39793.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -8.5005,
        ANNUAL: 5794.0,
        JAN: 6308.0,
        FEB: 6057.0,
        MAR: 6111.0,
        APR: 5474.0,
        MAY: 4843.0,
        JUN: 4472.0,
        JUL: 4735.0,
        AUG: 5485.0,
        SEP: 6319.0,
        OCT: 6519.0,
        NOV: 6720.0,
        DEC: 6485.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.400256585, -8.538659205999986],
              [-39.407628468, -8.538351348999987],
              [-39.409523279, -8.538900110999982],
              [-39.413745198999898, -8.541670628999952],
              [-39.4160408, -8.542127927999962],
              [-39.41870704, -8.546104415999931],
              [-39.423726082570752, -8.550461434175615],
              [-39.499045178706325, -8.550461434175615],
              [-39.499045178706325, -8.450461434175615],
              [-39.399045178706324, -8.450461434175615],
              [-39.399045178706324, -8.538208504399158],
              [-39.400256585, -8.538659205999986],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '359',
        ID: 39794.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -8.5005,
        ANNUAL: 5779.0,
        JAN: 6268.0,
        FEB: 6081.0,
        MAR: 6139.0,
        APR: 5512.0,
        MAY: 4837.0,
        JUN: 4456.0,
        JUL: 4722.0,
        AUG: 5457.0,
        SEP: 6268.0,
        OCT: 6459.0,
        NOV: 6685.0,
        DEC: 6463.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.353264409, -8.550014979999952],
              [-39.353933573999903, -8.549529293999971],
              [-39.3544384919999, -8.549162820999975],
              [-39.355612575, -8.548310661999947],
              [-39.356242445, -8.547853496999947],
              [-39.356628281, -8.547573453999973],
              [-39.356786008, -8.547458973999939],
              [-39.356874196, -8.547394965999954],
              [-39.357360888999899, -8.547041721999953],
              [-39.357055124999903, -8.546980548999956],
              [-39.356809138, -8.544896546999947],
              [-39.360472737999899, -8.540991182999969],
              [-39.3644564109999, -8.537849552999939],
              [-39.373719529, -8.534092502999952],
              [-39.379793561, -8.53282057099994],
              [-39.386365637, -8.533491102999957],
              [-39.399045178706324, -8.538208504399158],
              [-39.399045178706324, -8.450461434175615],
              [-39.299045178706322, -8.450461434175615],
              [-39.299045178706322, -8.550461434175615],
              [-39.352837153983096, -8.550461434175615],
              [-39.353264409, -8.550014979999952],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '360',
        ID: 39795.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.5005,
        ANNUAL: 5737.0,
        JAN: 6231.0,
        FEB: 6011.0,
        MAR: 6078.0,
        APR: 5478.0,
        MAY: 4785.0,
        JUN: 4382.0,
        JUL: 4635.0,
        AUG: 5398.0,
        SEP: 6286.0,
        OCT: 6488.0,
        NOV: 6683.0,
        DEC: 6382.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -8.550461434175615],
              [-39.299045178706322, -8.450461434175615],
              [-39.199045178706321, -8.450461434175615],
              [-39.199045178706321, -8.550461434175615],
              [-39.299045178706322, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '361',
        ID: 39796.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.5005,
        ANNUAL: 5759.0,
        JAN: 6283.0,
        FEB: 6072.0,
        MAR: 6044.0,
        APR: 5497.0,
        MAY: 4826.0,
        JUN: 4408.0,
        JUL: 4656.0,
        AUG: 5427.0,
        SEP: 6319.0,
        OCT: 6488.0,
        NOV: 6719.0,
        DEC: 6373.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -8.550461434175615],
              [-39.199045178706321, -8.450461434175615],
              [-39.099045178706319, -8.450461434175615],
              [-39.099045178706319, -8.550461434175615],
              [-39.199045178706321, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '362',
        ID: 39797.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.5005,
        ANNUAL: 5769.0,
        JAN: 6284.0,
        FEB: 6050.0,
        MAR: 6055.0,
        APR: 5512.0,
        MAY: 4813.0,
        JUN: 4397.0,
        JUL: 4662.0,
        AUG: 5424.0,
        SEP: 6342.0,
        OCT: 6533.0,
        NOV: 6742.0,
        DEC: 6412.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.099045178706319, -8.550461434175615],
              [-39.099045178706319, -8.450461434175615],
              [-38.999045178706318, -8.450461434175615],
              [-38.999045178706318, -8.550461434175615],
              [-39.099045178706319, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '363',
        ID: 39798.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.5005,
        ANNUAL: 5752.0,
        JAN: 6285.0,
        FEB: 6040.0,
        MAR: 6078.0,
        APR: 5510.0,
        MAY: 4751.0,
        JUN: 4347.0,
        JUL: 4600.0,
        AUG: 5366.0,
        SEP: 6327.0,
        OCT: 6537.0,
        NOV: 6737.0,
        DEC: 6452.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.550461434175615],
              [-38.999045178706318, -8.450461434175615],
              [-38.899045178706317, -8.450461434175615],
              [-38.899045178706317, -8.550461434175615],
              [-38.999045178706318, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '364',
        ID: 39799.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.5005,
        ANNUAL: 5758.0,
        JAN: 6308.0,
        FEB: 6103.0,
        MAR: 6122.0,
        APR: 5516.0,
        MAY: 4758.0,
        JUN: 4301.0,
        JUL: 4557.0,
        AUG: 5345.0,
        SEP: 6318.0,
        OCT: 6551.0,
        NOV: 6748.0,
        DEC: 6473.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.550461434175615],
              [-38.899045178706317, -8.450461434175615],
              [-38.799045178706315, -8.450461434175615],
              [-38.799045178706315, -8.550461434175615],
              [-38.899045178706317, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '365',
        ID: 39800.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.5005,
        ANNUAL: 5690.0,
        JAN: 6244.0,
        FEB: 6047.0,
        MAR: 6055.0,
        APR: 5463.0,
        MAY: 4708.0,
        JUN: 4195.0,
        JUL: 4458.0,
        AUG: 5226.0,
        SEP: 6257.0,
        OCT: 6485.0,
        NOV: 6703.0,
        DEC: 6441.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.550461434175615],
              [-38.799045178706315, -8.450461434175615],
              [-38.699045178706314, -8.450461434175615],
              [-38.699045178706314, -8.550461434175615],
              [-38.799045178706315, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '366',
        ID: 39801.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.5005,
        ANNUAL: 5654.0,
        JAN: 6257.0,
        FEB: 6055.0,
        MAR: 6076.0,
        APR: 5458.0,
        MAY: 4636.0,
        JUN: 4110.0,
        JUL: 4388.0,
        AUG: 5127.0,
        SEP: 6167.0,
        OCT: 6456.0,
        NOV: 6659.0,
        DEC: 6461.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.550461434175615],
              [-38.699045178706314, -8.450461434175615],
              [-38.599045178706312, -8.450461434175615],
              [-38.599045178706312, -8.550461434175615],
              [-38.699045178706314, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '367',
        ID: 39802.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.5005,
        ANNUAL: 5718.0,
        JAN: 6332.0,
        FEB: 6114.0,
        MAR: 6128.0,
        APR: 5573.0,
        MAY: 4720.0,
        JUN: 4184.0,
        JUL: 4443.0,
        AUG: 5237.0,
        SEP: 6224.0,
        OCT: 6498.0,
        NOV: 6688.0,
        DEC: 6476.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.550461434175615],
              [-38.599045178706312, -8.450461434175615],
              [-38.499045178706311, -8.450461434175615],
              [-38.499045178706311, -8.550461434175615],
              [-38.599045178706312, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '368',
        ID: 39803.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.5005,
        ANNUAL: 5704.0,
        JAN: 6301.0,
        FEB: 6099.0,
        MAR: 6092.0,
        APR: 5531.0,
        MAY: 4695.0,
        JUN: 4226.0,
        JUL: 4485.0,
        AUG: 5252.0,
        SEP: 6220.0,
        OCT: 6450.0,
        NOV: 6664.0,
        DEC: 6434.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.550461434175615],
              [-38.499045178706311, -8.450461434175615],
              [-38.399045178706309, -8.450461434175615],
              [-38.399045178706309, -8.550461434175615],
              [-38.499045178706311, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '369',
        ID: 39804.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.5005,
        ANNUAL: 5715.0,
        JAN: 6282.0,
        FEB: 6066.0,
        MAR: 6124.0,
        APR: 5539.0,
        MAY: 4703.0,
        JUN: 4237.0,
        JUL: 4517.0,
        AUG: 5255.0,
        SEP: 6249.0,
        OCT: 6456.0,
        NOV: 6714.0,
        DEC: 6443.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.550461434175615],
              [-38.399045178706309, -8.450461434175615],
              [-38.299045178706308, -8.450461434175615],
              [-38.299045178706308, -8.550461434175615],
              [-38.399045178706309, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '370',
        ID: 39805.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.5005,
        ANNUAL: 5704.0,
        JAN: 6311.0,
        FEB: 6070.0,
        MAR: 6168.0,
        APR: 5553.0,
        MAY: 4667.0,
        JUN: 4216.0,
        JUL: 4485.0,
        AUG: 5190.0,
        SEP: 6192.0,
        OCT: 6447.0,
        NOV: 6711.0,
        DEC: 6444.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.550461434175615],
              [-38.299045178706308, -8.450461434175615],
              [-38.199045178706307, -8.450461434175615],
              [-38.199045178706307, -8.550461434175615],
              [-38.299045178706308, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '371',
        ID: 39806.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.5005,
        ANNUAL: 5710.0,
        JAN: 6364.0,
        FEB: 6091.0,
        MAR: 6187.0,
        APR: 5576.0,
        MAY: 4654.0,
        JUN: 4178.0,
        JUL: 4456.0,
        AUG: 5185.0,
        SEP: 6200.0,
        OCT: 6425.0,
        NOV: 6729.0,
        DEC: 6478.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.550461434175615],
              [-38.199045178706307, -8.450461434175615],
              [-38.099045178706305, -8.450461434175615],
              [-38.099045178706305, -8.550461434175615],
              [-38.199045178706307, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '372',
        ID: 39807.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.5005,
        ANNUAL: 5718.0,
        JAN: 6395.0,
        FEB: 6093.0,
        MAR: 6157.0,
        APR: 5593.0,
        MAY: 4691.0,
        JUN: 4152.0,
        JUL: 4471.0,
        AUG: 5210.0,
        SEP: 6190.0,
        OCT: 6405.0,
        NOV: 6739.0,
        DEC: 6513.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.550461434175615],
              [-38.099045178706305, -8.450461434175615],
              [-37.999045178706304, -8.450461434175615],
              [-37.999045178706304, -8.550461434175615],
              [-38.099045178706305, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '373',
        ID: 39808.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.5005,
        ANNUAL: 5722.0,
        JAN: 6378.0,
        FEB: 6091.0,
        MAR: 6132.0,
        APR: 5585.0,
        MAY: 4717.0,
        JUN: 4191.0,
        JUL: 4469.0,
        AUG: 5221.0,
        SEP: 6199.0,
        OCT: 6430.0,
        NOV: 6764.0,
        DEC: 6483.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.550461434175615],
              [-37.999045178706304, -8.450461434175615],
              [-37.899045178706302, -8.450461434175615],
              [-37.899045178706302, -8.550461434175615],
              [-37.999045178706304, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '374',
        ID: 39809.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.5005,
        ANNUAL: 5751.0,
        JAN: 6385.0,
        FEB: 6132.0,
        MAR: 6183.0,
        APR: 5652.0,
        MAY: 4760.0,
        JUN: 4227.0,
        JUL: 4468.0,
        AUG: 5233.0,
        SEP: 6216.0,
        OCT: 6452.0,
        NOV: 6787.0,
        DEC: 6516.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.550461434175615],
              [-37.899045178706302, -8.450461434175615],
              [-37.799045178706301, -8.450461434175615],
              [-37.799045178706301, -8.550461434175615],
              [-37.899045178706302, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '375',
        ID: 39810.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.5005,
        ANNUAL: 5781.0,
        JAN: 6424.0,
        FEB: 6211.0,
        MAR: 6240.0,
        APR: 5708.0,
        MAY: 4766.0,
        JUN: 4260.0,
        JUL: 4444.0,
        AUG: 5254.0,
        SEP: 6218.0,
        OCT: 6487.0,
        NOV: 6792.0,
        DEC: 6571.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.550461434175615],
              [-37.799045178706301, -8.450461434175615],
              [-37.699045178706299, -8.450461434175615],
              [-37.699045178706299, -8.550461434175615],
              [-37.799045178706301, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '376',
        ID: 39811.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.5005,
        ANNUAL: 5735.0,
        JAN: 6378.0,
        FEB: 6216.0,
        MAR: 6251.0,
        APR: 5696.0,
        MAY: 4785.0,
        JUN: 4216.0,
        JUL: 4421.0,
        AUG: 5202.0,
        SEP: 6125.0,
        OCT: 6338.0,
        NOV: 6663.0,
        DEC: 6533.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.550461434175615],
              [-37.699045178706299, -8.450461434175615],
              [-37.599045178706298, -8.450461434175615],
              [-37.599045178706298, -8.550461434175615],
              [-37.699045178706299, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '377',
        ID: 39812.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.5005,
        ANNUAL: 5688.0,
        JAN: 6310.0,
        FEB: 6119.0,
        MAR: 6143.0,
        APR: 5624.0,
        MAY: 4721.0,
        JUN: 4175.0,
        JUL: 4396.0,
        AUG: 5213.0,
        SEP: 6109.0,
        OCT: 6355.0,
        NOV: 6643.0,
        DEC: 6449.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.550461434175615],
              [-37.599045178706298, -8.450461434175615],
              [-37.499045178706297, -8.450461434175615],
              [-37.499045178706297, -8.550461434175615],
              [-37.599045178706298, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '378',
        ID: 39813.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.5005,
        ANNUAL: 5634.0,
        JAN: 6206.0,
        FEB: 5958.0,
        MAR: 6029.0,
        APR: 5530.0,
        MAY: 4690.0,
        JUN: 4171.0,
        JUL: 4388.0,
        AUG: 5208.0,
        SEP: 6080.0,
        OCT: 6316.0,
        NOV: 6651.0,
        DEC: 6380.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.550461434175615],
              [-37.499045178706297, -8.450461434175615],
              [-37.399045178706295, -8.450461434175615],
              [-37.399045178706295, -8.550461434175615],
              [-37.499045178706297, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '379',
        ID: 39814.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.5005,
        ANNUAL: 5665.0,
        JAN: 6270.0,
        FEB: 5997.0,
        MAR: 6044.0,
        APR: 5495.0,
        MAY: 4670.0,
        JUN: 4221.0,
        JUL: 4447.0,
        AUG: 5212.0,
        SEP: 6097.0,
        OCT: 6374.0,
        NOV: 6721.0,
        DEC: 6436.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.550461434175615],
              [-37.399045178706295, -8.450461434175615],
              [-37.299045178706294, -8.450461434175615],
              [-37.299045178706294, -8.550461434175615],
              [-37.399045178706295, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '380',
        ID: 39815.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.5005,
        ANNUAL: 5727.0,
        JAN: 6325.0,
        FEB: 6081.0,
        MAR: 6138.0,
        APR: 5549.0,
        MAY: 4730.0,
        JUN: 4264.0,
        JUL: 4481.0,
        AUG: 5261.0,
        SEP: 6158.0,
        OCT: 6456.0,
        NOV: 6763.0,
        DEC: 6520.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.550461434175615],
              [-37.299045178706294, -8.450461434175615],
              [-37.199045178706292, -8.450461434175615],
              [-37.199045178706292, -8.550461434175615],
              [-37.299045178706294, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '381',
        ID: 39816.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.5005,
        ANNUAL: 5685.0,
        JAN: 6261.0,
        FEB: 6075.0,
        MAR: 6139.0,
        APR: 5555.0,
        MAY: 4712.0,
        JUN: 4240.0,
        JUL: 4417.0,
        AUG: 5216.0,
        SEP: 6080.0,
        OCT: 6372.0,
        NOV: 6678.0,
        DEC: 6481.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -8.550461434175615],
              [-37.199045178706292, -8.450461434175615],
              [-37.099045178706291, -8.450461434175615],
              [-37.099045178706291, -8.550461434175615],
              [-37.199045178706292, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '382',
        ID: 39817.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.5005,
        ANNUAL: 5644.0,
        JAN: 6199.0,
        FEB: 6089.0,
        MAR: 6121.0,
        APR: 5550.0,
        MAY: 4642.0,
        JUN: 4106.0,
        JUL: 4315.0,
        AUG: 5105.0,
        SEP: 6074.0,
        OCT: 6352.0,
        NOV: 6720.0,
        DEC: 6463.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -8.550461434175615],
              [-37.099045178706291, -8.450461434175615],
              [-36.99904517870629, -8.450461434175615],
              [-36.99904517870629, -8.550461434175615],
              [-37.099045178706291, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '383',
        ID: 39818.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.5005,
        ANNUAL: 5570.0,
        JAN: 6192.0,
        FEB: 6085.0,
        MAR: 6103.0,
        APR: 5501.0,
        MAY: 4534.0,
        JUN: 3982.0,
        JUL: 4139.0,
        AUG: 4927.0,
        SEP: 5934.0,
        OCT: 6298.0,
        NOV: 6670.0,
        DEC: 6468.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -8.550461434175615],
              [-36.99904517870629, -8.450461434175615],
              [-36.899045178706288, -8.450461434175615],
              [-36.899045178706288, -8.550461434175615],
              [-36.99904517870629, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '384',
        ID: 39819.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.5005,
        ANNUAL: 5473.0,
        JAN: 6179.0,
        FEB: 5997.0,
        MAR: 5974.0,
        APR: 5411.0,
        MAY: 4433.0,
        JUN: 3899.0,
        JUL: 4018.0,
        AUG: 4780.0,
        SEP: 5783.0,
        OCT: 6151.0,
        NOV: 6621.0,
        DEC: 6428.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -8.550461434175615],
              [-36.899045178706288, -8.450461434175615],
              [-36.799045178706287, -8.450461434175615],
              [-36.799045178706287, -8.550461434175615],
              [-36.899045178706288, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '385',
        ID: 39820.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.5005,
        ANNUAL: 5465.0,
        JAN: 6240.0,
        FEB: 5917.0,
        MAR: 5948.0,
        APR: 5366.0,
        MAY: 4389.0,
        JUN: 3905.0,
        JUL: 4037.0,
        AUG: 4822.0,
        SEP: 5760.0,
        OCT: 6133.0,
        NOV: 6621.0,
        DEC: 6437.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -8.550461434175615],
              [-36.799045178706287, -8.450461434175615],
              [-36.699045178706285, -8.450461434175615],
              [-36.699045178706285, -8.550461434175615],
              [-36.799045178706287, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '386',
        ID: 39821.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.5005,
        ANNUAL: 5432.0,
        JAN: 6146.0,
        FEB: 5917.0,
        MAR: 5929.0,
        APR: 5386.0,
        MAY: 4402.0,
        JUN: 3950.0,
        JUL: 4057.0,
        AUG: 4818.0,
        SEP: 5684.0,
        OCT: 6043.0,
        NOV: 6524.0,
        DEC: 6327.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -8.550461434175615],
              [-36.699045178706285, -8.450461434175615],
              [-36.599045178706284, -8.450461434175615],
              [-36.599045178706284, -8.550461434175615],
              [-36.699045178706285, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '387',
        ID: 39822.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.5005,
        ANNUAL: 5366.0,
        JAN: 6008.0,
        FEB: 5864.0,
        MAR: 5861.0,
        APR: 5347.0,
        MAY: 4399.0,
        JUN: 3920.0,
        JUL: 4041.0,
        AUG: 4750.0,
        SEP: 5586.0,
        OCT: 5926.0,
        NOV: 6431.0,
        DEC: 6251.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.550461434175615],
              [-36.599045178706284, -8.450461434175615],
              [-36.499045178706282, -8.450461434175615],
              [-36.499045178706282, -8.550461434175615],
              [-36.599045178706284, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '388',
        ID: 39823.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.5005,
        ANNUAL: 5312.0,
        JAN: 5970.0,
        FEB: 5798.0,
        MAR: 5853.0,
        APR: 5285.0,
        MAY: 4395.0,
        JUN: 3886.0,
        JUL: 4033.0,
        AUG: 4707.0,
        SEP: 5490.0,
        OCT: 5838.0,
        NOV: 6307.0,
        DEC: 6182.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.550461434175615],
              [-36.499045178706282, -8.450461434175615],
              [-36.399045178706281, -8.450461434175615],
              [-36.399045178706281, -8.550461434175615],
              [-36.499045178706282, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '389',
        ID: 39824.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.5005,
        ANNUAL: 5295.0,
        JAN: 5961.0,
        FEB: 5789.0,
        MAR: 5861.0,
        APR: 5248.0,
        MAY: 4369.0,
        JUN: 3909.0,
        JUL: 4041.0,
        AUG: 4681.0,
        SEP: 5399.0,
        OCT: 5830.0,
        NOV: 6282.0,
        DEC: 6170.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.550461434175615],
              [-36.399045178706281, -8.450461434175615],
              [-36.29904517870628, -8.450461434175615],
              [-36.29904517870628, -8.550461434175615],
              [-36.399045178706281, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '390',
        ID: 39825.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.5005,
        ANNUAL: 5303.0,
        JAN: 5929.0,
        FEB: 5795.0,
        MAR: 5853.0,
        APR: 5274.0,
        MAY: 4395.0,
        JUN: 3917.0,
        JUL: 4006.0,
        AUG: 4680.0,
        SEP: 5424.0,
        OCT: 5841.0,
        NOV: 6361.0,
        DEC: 6163.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.550461434175615],
              [-36.29904517870628, -8.450461434175615],
              [-36.199045178706278, -8.450461434175615],
              [-36.199045178706278, -8.550461434175615],
              [-36.29904517870628, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '391',
        ID: 39826.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.5005,
        ANNUAL: 5299.0,
        JAN: 5929.0,
        FEB: 5790.0,
        MAR: 5879.0,
        APR: 5269.0,
        MAY: 4401.0,
        JUN: 3943.0,
        JUL: 4042.0,
        AUG: 4689.0,
        SEP: 5470.0,
        OCT: 5811.0,
        NOV: 6270.0,
        DEC: 6099.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.550461434175615],
              [-36.199045178706278, -8.450461434175615],
              [-36.099045178706277, -8.450461434175615],
              [-36.099045178706277, -8.550461434175615],
              [-36.199045178706278, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '392',
        ID: 39827.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.5005,
        ANNUAL: 5272.0,
        JAN: 5916.0,
        FEB: 5817.0,
        MAR: 5881.0,
        APR: 5210.0,
        MAY: 4377.0,
        JUN: 3934.0,
        JUL: 4015.0,
        AUG: 4670.0,
        SEP: 5441.0,
        OCT: 5752.0,
        NOV: 6202.0,
        DEC: 6047.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.550461434175615],
              [-36.099045178706277, -8.450461434175615],
              [-35.999045178706275, -8.450461434175615],
              [-35.999045178706275, -8.550461434175615],
              [-36.099045178706277, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '393',
        ID: 39828.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.5005,
        ANNUAL: 5307.0,
        JAN: 5956.0,
        FEB: 5851.0,
        MAR: 5955.0,
        APR: 5234.0,
        MAY: 4367.0,
        JUN: 3969.0,
        JUL: 4082.0,
        AUG: 4727.0,
        SEP: 5494.0,
        OCT: 5796.0,
        NOV: 6189.0,
        DEC: 6064.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.550461434175615],
              [-35.999045178706275, -8.450461434175615],
              [-35.899045178706274, -8.450461434175615],
              [-35.899045178706274, -8.550461434175615],
              [-35.999045178706275, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '394',
        ID: 39829.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.5005,
        ANNUAL: 5285.0,
        JAN: 5900.0,
        FEB: 5858.0,
        MAR: 5870.0,
        APR: 5177.0,
        MAY: 4378.0,
        JUN: 3934.0,
        JUL: 4024.0,
        AUG: 4706.0,
        SEP: 5460.0,
        OCT: 5826.0,
        NOV: 6216.0,
        DEC: 6075.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.550461434175615],
              [-35.899045178706274, -8.450461434175615],
              [-35.799045178706272, -8.450461434175615],
              [-35.799045178706272, -8.550461434175615],
              [-35.899045178706274, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '395',
        ID: 39830.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.5005,
        ANNUAL: 5174.0,
        JAN: 5796.0,
        FEB: 5738.0,
        MAR: 5791.0,
        APR: 5083.0,
        MAY: 4288.0,
        JUN: 3815.0,
        JUL: 3900.0,
        AUG: 4576.0,
        SEP: 5289.0,
        OCT: 5659.0,
        NOV: 6145.0,
        DEC: 6003.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.550461434175615],
              [-35.799045178706272, -8.450461434175615],
              [-35.699045178706271, -8.450461434175615],
              [-35.699045178706271, -8.550461434175615],
              [-35.799045178706272, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '396',
        ID: 39831.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.5005,
        ANNUAL: 5003.0,
        JAN: 5627.0,
        FEB: 5528.0,
        MAR: 5608.0,
        APR: 4934.0,
        MAY: 4157.0,
        JUN: 3721.0,
        JUL: 3761.0,
        AUG: 4382.0,
        SEP: 5096.0,
        OCT: 5419.0,
        NOV: 5907.0,
        DEC: 5899.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.550461434175615],
              [-35.699045178706271, -8.450461434175615],
              [-35.59904517870627, -8.450461434175615],
              [-35.59904517870627, -8.550461434175615],
              [-35.699045178706271, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '397',
        ID: 39832.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.5005,
        ANNUAL: 4985.0,
        JAN: 5565.0,
        FEB: 5490.0,
        MAR: 5602.0,
        APR: 4901.0,
        MAY: 4158.0,
        JUN: 3773.0,
        JUL: 3800.0,
        AUG: 4390.0,
        SEP: 5080.0,
        OCT: 5364.0,
        NOV: 5853.0,
        DEC: 5835.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.550461434175615],
              [-35.59904517870627, -8.450461434175615],
              [-35.499045178706268, -8.450461434175615],
              [-35.499045178706268, -8.550461434175615],
              [-35.59904517870627, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '398',
        ID: 39833.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.5005,
        ANNUAL: 4987.0,
        JAN: 5553.0,
        FEB: 5497.0,
        MAR: 5577.0,
        APR: 4886.0,
        MAY: 4164.0,
        JUN: 3801.0,
        JUL: 3846.0,
        AUG: 4397.0,
        SEP: 5128.0,
        OCT: 5390.0,
        NOV: 5827.0,
        DEC: 5778.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.550461434175615],
              [-35.499045178706268, -8.450461434175615],
              [-35.399045178706267, -8.450461434175615],
              [-35.399045178706267, -8.550461434175615],
              [-35.499045178706268, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '399',
        ID: 39834.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.5005,
        ANNUAL: 5158.0,
        JAN: 5666.0,
        FEB: 5675.0,
        MAR: 5698.0,
        APR: 5000.0,
        MAY: 4301.0,
        JUN: 3932.0,
        JUL: 4018.0,
        AUG: 4623.0,
        SEP: 5341.0,
        OCT: 5659.0,
        NOV: 5984.0,
        DEC: 6001.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.550461434175615],
              [-35.399045178706267, -8.450461434175615],
              [-35.299045178706265, -8.450461434175615],
              [-35.299045178706265, -8.550461434175615],
              [-35.399045178706267, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '400',
        ID: 39835.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.5005,
        ANNUAL: 5223.0,
        JAN: 5688.0,
        FEB: 5820.0,
        MAR: 5810.0,
        APR: 5049.0,
        MAY: 4335.0,
        JUN: 4017.0,
        JUL: 4094.0,
        AUG: 4742.0,
        SEP: 5403.0,
        OCT: 5696.0,
        NOV: 6000.0,
        DEC: 6021.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.550461434175615],
              [-35.299045178706265, -8.450461434175615],
              [-35.199045178706264, -8.450461434175615],
              [-35.199045178706264, -8.550461434175615],
              [-35.299045178706265, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '401',
        ID: 39836.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.5005,
        ANNUAL: 5235.0,
        JAN: 5647.0,
        FEB: 5865.0,
        MAR: 5854.0,
        APR: 5061.0,
        MAY: 4376.0,
        JUN: 4062.0,
        JUL: 4120.0,
        AUG: 4790.0,
        SEP: 5378.0,
        OCT: 5693.0,
        NOV: 5986.0,
        DEC: 5992.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.550461434175615],
              [-35.199045178706264, -8.450461434175615],
              [-35.099045178706263, -8.450461434175615],
              [-35.099045178706263, -8.550461434175615],
              [-35.199045178706264, -8.550461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '402',
        ID: 39837.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.5005,
        ANNUAL: 5378.0,
        JAN: 5842.0,
        FEB: 5953.0,
        MAR: 6014.0,
        APR: 5174.0,
        MAY: 4478.0,
        JUN: 4165.0,
        JUL: 4239.0,
        AUG: 4953.0,
        SEP: 5551.0,
        OCT: 5847.0,
        NOV: 6151.0,
        DEC: 6165.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.999630066999899, -8.485696819999932],
              [-35.000442639, -8.48787021299994],
              [-35.001020771, -8.489855060999952],
              [-35.001443169, -8.491502801999957],
              [-35.001490639999901, -8.491770680999924],
              [-35.001808663, -8.493565271999968],
              [-35.002211535999898, -8.495449249999981],
              [-35.002438911, -8.497178334999942],
              [-35.002413873, -8.498852729999944],
              [-35.002227869, -8.500571104999951],
              [-35.001831013, -8.501794560999945],
              [-35.001491452, -8.502489432999935],
              [-35.001034155999903, -8.503106476999957],
              [-35.000700939, -8.503576231999944],
              [-35.000348215, -8.504115212999977],
              [-35.000220714, -8.504575338999937],
              [-35.000180640999901, -8.504719952999956],
              [-35.00006, -8.505427304999968],
              [-35.000036824999903, -8.505563188999924],
              [-34.999896046999901, -8.506364476999977],
              [-34.999844709999905, -8.507473000999932],
              [-34.999983315999906, -8.508450935999976],
              [-35.000261, -8.509411806999948],
              [-35.00039607, -8.509879190999913],
              [-35.000757826, -8.510640250999925],
              [-35.001063234, -8.510952115999945],
              [-35.001882533999897, -8.511389030999958],
              [-35.002150785, -8.511532083999949],
              [-35.003031264999898, -8.512038048999953],
              [-35.003751655, -8.512832991999964],
              [-35.004637584, -8.514160020999942],
              [-35.005149958999901, -8.515334540999968],
              [-35.00548079, -8.517770871999966],
              [-35.006019423999902, -8.520620145999935],
              [-35.0065524299999, -8.523822915999965],
              [-35.006615794, -8.52551245399996],
              [-35.006655123, -8.526561104999937],
              [-35.006552118999906, -8.528808890999933],
              [-35.006535502, -8.529171505999949],
              [-35.006206348, -8.531404512999938],
              [-35.006167075999898, -8.531670937999925],
              [-35.005626748999902, -8.533804399999944],
              [-35.004707263999897, -8.535978122999941],
              [-35.003682999, -8.538336890999965],
              [-35.003319474, -8.538950593999973],
              [-35.00300335, -8.539750386999975],
              [-35.003125188999903, -8.540298137],
              [-35.00338106, -8.540490064999979],
              [-35.003709833999899, -8.540484775999969],
              [-35.004010961999903, -8.540916652999949],
              [-35.004868771, -8.541375540999976],
              [-35.004995977, -8.542161781999937],
              [-35.005101225, -8.542812307999956],
              [-35.0044058679999, -8.543244459999928],
              [-35.003572054, -8.543430519999957],
              [-35.002918083999901, -8.543613012999971],
              [-35.002833054, -8.543636741999933],
              [-35.002666313, -8.544001738999949],
              [-35.003037350999897, -8.54494164399995],
              [-35.003168789, -8.546690618999955],
              [-35.003105326, -8.548319333999927],
              [-35.003578395, -8.549243552999938],
              [-35.00437076, -8.550120830999955],
              [-35.00468665126661, -8.550461434175615],
              [-35.099045178706263, -8.550461434175615],
              [-35.099045178706263, -8.450461434175615],
              [-34.999045178706261, -8.450461434175615],
              [-34.999045178706261, -8.484374110880735],
              [-34.999630066999899, -8.485696819999932],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '403',
        ID: 39838.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -8.5005,
        ANNUAL: 5679.0,
        JAN: 6182.0,
        FEB: 6235.0,
        MAR: 6280.0,
        APR: 5401.0,
        MAY: 4622.0,
        JUN: 4317.0,
        JUL: 4418.0,
        AUG: 5205.0,
        SEP: 5973.0,
        OCT: 6315.0,
        NOV: 6602.0,
        DEC: 6598.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.983878881, -8.450879290999973],
              [-34.98388139, -8.450892926999925],
              [-34.984106198999903, -8.452868771999922],
              [-34.984105357, -8.452887002999942],
              [-34.984066659999897, -8.453724593999933],
              [-34.984057027999903, -8.453933073999966],
              [-34.983817299, -8.455036589999962],
              [-34.983630406, -8.455429053999985],
              [-34.983428671, -8.455852686999956],
              [-34.983184175, -8.456391185999966],
              [-34.983126075, -8.456872260999933],
              [-34.983277953, -8.457411235999936],
              [-34.983440835, -8.457737467999944],
              [-34.983577269, -8.457925256999941],
              [-34.983707406, -8.458104380999936],
              [-34.984469429999898, -8.459018011999929],
              [-34.986478, -8.461327925999974],
              [-34.988386928, -8.463778374999945],
              [-34.98943215, -8.465637142999984],
              [-34.991150702, -8.468817404999971],
              [-34.991810298, -8.469921233999965],
              [-34.993043305, -8.471984663999926],
              [-34.993162775, -8.472194340999973],
              [-34.994615492, -8.474743950999946],
              [-34.9960215629999, -8.477466128999941],
              [-34.996885185999901, -8.479345857999936],
              [-34.997500855999903, -8.480685899999962],
              [-34.998494363, -8.483128455999973],
              [-34.999045178706261, -8.484374110880735],
              [-34.999045178706261, -8.450461434175615],
              [-34.98380198322117, -8.450461434175615],
              [-34.983878881, -8.450879290999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '404',
        ID: 40164.0,
        COUNTRY: 'Brasil',
        LON: -41.049,
        LAT: -8.4005,
        ANNUAL: 5866.0,
        JAN: 6110.0,
        FEB: 5808.0,
        MAR: 5769.0,
        APR: 5294.0,
        MAY: 5058.0,
        JUN: 4945.0,
        JUL: 5299.0,
        AUG: 6147.0,
        SEP: 6653.0,
        OCT: 6618.0,
        NOV: 6478.0,
        DEC: 6214.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.047975361999903, -8.449045978999944],
              [-41.047925655, -8.448732057999964],
              [-41.047754629999908, -8.447651777999965],
              [-41.047753655, -8.447645624999943],
              [-41.047510525999897, -8.446110070999966],
              [-41.045087754999898, -8.439960640999972],
              [-41.04412726, -8.438811610999958],
              [-41.043717840999904, -8.436656713999978],
              [-41.038990571999904, -8.431829200999966],
              [-41.034029083999897, -8.430107411999925],
              [-41.023648641, -8.428475617999931],
              [-41.022106032999893, -8.427507315999945],
              [-41.020312783, -8.424686787999969],
              [-41.017680219999903, -8.424084833999933],
              [-41.015528522, -8.424172500999926],
              [-41.011042034999903, -8.422513685999949],
              [-41.007379832, -8.420929113999936],
              [-41.006925407, -8.417665742999924],
              [-41.005394789, -8.415594991999974],
              [-41.003097929, -8.413695426999938],
              [-41.002344854999897, -8.411790067999961],
              [-41.002206339999901, -8.410515964999945],
              [-41.002802318, -8.407668133999939],
              [-41.003356488999898, -8.405020079999927],
              [-41.003234693, -8.404002384999961],
              [-41.000675558999902, -8.400726034999929],
              [-40.999953209999902, -8.400299564999958],
              [-40.999045178706346, -8.400239601365605],
              [-40.999045178706346, -8.450461434175615],
              [-41.048199435831833, -8.450461434175615],
              [-41.047975361999903, -8.449045978999944],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '405',
        ID: 40165.0,
        COUNTRY: 'Brasil',
        LON: -40.949,
        LAT: -8.4005,
        ANNUAL: 5845.0,
        JAN: 6104.0,
        FEB: 5813.0,
        MAR: 5779.0,
        APR: 5294.0,
        MAY: 5032.0,
        JUN: 4910.0,
        JUL: 5259.0,
        AUG: 6127.0,
        SEP: 6601.0,
        OCT: 6598.0,
        NOV: 6448.0,
        DEC: 6175.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.986962638, -8.399441706999937],
              [-40.983610578, -8.398227331999983],
              [-40.981379424999901, -8.397929560999968],
              [-40.97923002600001, -8.398248447999949],
              [-40.978545009999898, -8.398650711999959],
              [-40.972901039, -8.406933466999936],
              [-40.969290336, -8.410283897999925],
              [-40.966183109, -8.411595872999948],
              [-40.965558094, -8.412128800999939],
              [-40.964904463, -8.413423005999968],
              [-40.965508954, -8.416458741999977],
              [-40.964302055, -8.417430686999987],
              [-40.961957115, -8.417537434999936],
              [-40.960768188, -8.419101868999974],
              [-40.957878010999899, -8.419398238999968],
              [-40.957498767999901, -8.419782933999951],
              [-40.9580918139999, -8.422457440999958],
              [-40.957094356, -8.423466969999945],
              [-40.953581433, -8.424706392999948],
              [-40.952377834999901, -8.426964495999934],
              [-40.950199046, -8.429150377999974],
              [-40.949593641, -8.429558582999963],
              [-40.94051597, -8.433234470999926],
              [-40.938094024, -8.434980017999973],
              [-40.937034291, -8.43682660599994],
              [-40.935812987, -8.438954732999946],
              [-40.930506948999898, -8.44365044399996],
              [-40.926851149999898, -8.446344417999965],
              [-40.925149037, -8.446326064999944],
              [-40.923846856999901, -8.44505674099997],
              [-40.919956328, -8.439030128999955],
              [-40.916848148, -8.436160304999927],
              [-40.915346908999901, -8.436273757999974],
              [-40.914398601999899, -8.436239590999953],
              [-40.913368143999897, -8.436202626999931],
              [-40.912736106999901, -8.436179987999948],
              [-40.912080790999902, -8.435363968999983],
              [-40.910035176, -8.428925791999964],
              [-40.9090085999999, -8.427329499999923],
              [-40.903491397, -8.423853482999965],
              [-40.902850414999897, -8.423449670999952],
              [-40.902293702, -8.423098983999978],
              [-40.90153545, -8.421627004999946],
              [-40.901290512999893, -8.420261682999971],
              [-40.902452481, -8.418165066999959],
              [-40.902516297, -8.415793737999937],
              [-40.901509820999898, -8.410126106999947],
              [-40.901849182, -8.407957766999969],
              [-40.903369141, -8.405722776999943],
              [-40.906918903, -8.402389365999973],
              [-40.907787546999899, -8.401374136999948],
              [-40.90761549, -8.398987179999951],
              [-40.906413196, -8.397452357999946],
              [-40.902428345, -8.397085055999975],
              [-40.89947075700001, -8.395020621999947],
              [-40.899045178706345, -8.394804209524084],
              [-40.899045178706345, -8.450461434175615],
              [-40.999045178706346, -8.450461434175615],
              [-40.999045178706346, -8.400239601365605],
              [-40.986962638, -8.399441706999937],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '406',
        ID: 40166.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -8.4005,
        ANNUAL: 5805.0,
        JAN: 6057.0,
        FEB: 5785.0,
        MAR: 5707.0,
        APR: 5253.0,
        MAY: 4920.0,
        JUN: 4803.0,
        JUL: 5164.0,
        AUG: 6094.0,
        SEP: 6663.0,
        OCT: 6617.0,
        NOV: 6462.0,
        DEC: 6137.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.8945190869999, -8.392502628999983],
              [-40.894108783, -8.389962552999986],
              [-40.890751466, -8.387899359999942],
              [-40.889870383999899, -8.386749867999969],
              [-40.889611531999897, -8.38259111899993],
              [-40.890398419999897, -8.380507028999945],
              [-40.892377732999897, -8.378032907999975],
              [-40.892769439999903, -8.376720914999957],
              [-40.892202751, -8.374421004999927],
              [-40.892666483, -8.371792843999966],
              [-40.897103976, -8.369047966999972],
              [-40.897746509999898, -8.367917787999939],
              [-40.897660085, -8.366532092999924],
              [-40.894833561, -8.358766962999937],
              [-40.893472304999896, -8.35731013999998],
              [-40.891634424, -8.356085759999926],
              [-40.889086082999903, -8.356174560999932],
              [-40.881525337, -8.357675590999969],
              [-40.872523037999898, -8.357410126999973],
              [-40.869981075999902, -8.358538706999976],
              [-40.867527921, -8.362207210999937],
              [-40.866179401999901, -8.363446646999931],
              [-40.864507737999901, -8.364331682999923],
              [-40.862497634, -8.364991451999964],
              [-40.861789460999901, -8.370654578999963],
              [-40.859560496, -8.373573480999937],
              [-40.857345876999901, -8.375644477999966],
              [-40.859263937, -8.377710214999977],
              [-40.859429239999905, -8.378482768999955],
              [-40.858591277, -8.379610767999967],
              [-40.856400731, -8.379910911999962],
              [-40.854011383999897, -8.379648162999962],
              [-40.850212118999899, -8.380528283999981],
              [-40.845744715, -8.379214802999961],
              [-40.843901505, -8.379133760999924],
              [-40.837401476, -8.381126045999961],
              [-40.8365659789999, -8.380799085999973],
              [-40.832208473, -8.376248704999961],
              [-40.828703640999898, -8.373780559999945],
              [-40.825978947, -8.369444764999963],
              [-40.825403126, -8.368528429999966],
              [-40.8252832599999, -8.368337690999978],
              [-40.825102608, -8.368050196999944],
              [-40.823431848, -8.365391666999933],
              [-40.820098345, -8.363493526999944],
              [-40.819318709999898, -8.362357267999933],
              [-40.819205692, -8.360906740999946],
              [-40.820760268, -8.356618399999956],
              [-40.819316367999903, -8.352083727999968],
              [-40.819223250866251, -8.350461434175616],
              [-40.799045178706344, -8.350461434175616],
              [-40.799045178706344, -8.450461434175615],
              [-40.899045178706345, -8.450461434175615],
              [-40.899045178706345, -8.394804209524084],
              [-40.8945190869999, -8.392502628999983],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '407',
        ID: 40167.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.4005,
        ANNUAL: 5719.0,
        JAN: 6003.0,
        FEB: 5744.0,
        MAR: 5722.0,
        APR: 5184.0,
        MAY: 4800.0,
        JUN: 4646.0,
        JUL: 4999.0,
        AUG: 5934.0,
        SEP: 6549.0,
        OCT: 6507.0,
        NOV: 6410.0,
        DEC: 6126.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.799045178706344, -8.450461434175615],
              [-40.799045178706344, -8.350461434175616],
              [-40.699045178706342, -8.350461434175616],
              [-40.699045178706342, -8.450461434175615],
              [-40.799045178706344, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '408',
        ID: 40168.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.4005,
        ANNUAL: 5677.0,
        JAN: 5957.0,
        FEB: 5714.0,
        MAR: 5712.0,
        APR: 5124.0,
        MAY: 4766.0,
        JUN: 4586.0,
        JUL: 4903.0,
        AUG: 5842.0,
        SEP: 6509.0,
        OCT: 6468.0,
        NOV: 6415.0,
        DEC: 6126.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -8.450461434175615],
              [-40.699045178706342, -8.350461434175616],
              [-40.599045178706341, -8.350461434175616],
              [-40.599045178706341, -8.450461434175615],
              [-40.699045178706342, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '409',
        ID: 40169.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.4005,
        ANNUAL: 5660.0,
        JAN: 5997.0,
        FEB: 5688.0,
        MAR: 5698.0,
        APR: 5143.0,
        MAY: 4746.0,
        JUN: 4552.0,
        JUL: 4874.0,
        AUG: 5759.0,
        SEP: 6476.0,
        OCT: 6432.0,
        NOV: 6417.0,
        DEC: 6140.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.450461434175615],
              [-40.599045178706341, -8.350461434175616],
              [-40.499045178706339, -8.350461434175616],
              [-40.499045178706339, -8.450461434175615],
              [-40.599045178706341, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '410',
        ID: 40170.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.4005,
        ANNUAL: 5650.0,
        JAN: 6000.0,
        FEB: 5682.0,
        MAR: 5743.0,
        APR: 5173.0,
        MAY: 4723.0,
        JUN: 4531.0,
        JUL: 4829.0,
        AUG: 5705.0,
        SEP: 6440.0,
        OCT: 6434.0,
        NOV: 6389.0,
        DEC: 6153.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.450461434175615],
              [-40.499045178706339, -8.350461434175616],
              [-40.399045178706338, -8.350461434175616],
              [-40.399045178706338, -8.450461434175615],
              [-40.499045178706339, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '411',
        ID: 40171.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.4005,
        ANNUAL: 5638.0,
        JAN: 6008.0,
        FEB: 5688.0,
        MAR: 5759.0,
        APR: 5181.0,
        MAY: 4684.0,
        JUN: 4513.0,
        JUL: 4814.0,
        AUG: 5654.0,
        SEP: 6404.0,
        OCT: 6421.0,
        NOV: 6389.0,
        DEC: 6142.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.450461434175615],
              [-40.399045178706338, -8.350461434175616],
              [-40.299045178706336, -8.350461434175616],
              [-40.299045178706336, -8.450461434175615],
              [-40.399045178706338, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '412',
        ID: 40172.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.4005,
        ANNUAL: 5608.0,
        JAN: 5994.0,
        FEB: 5712.0,
        MAR: 5727.0,
        APR: 5159.0,
        MAY: 4686.0,
        JUN: 4444.0,
        JUL: 4749.0,
        AUG: 5598.0,
        SEP: 6342.0,
        OCT: 6395.0,
        NOV: 6397.0,
        DEC: 6088.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.450461434175615],
              [-40.299045178706336, -8.350461434175616],
              [-40.199045178706335, -8.350461434175616],
              [-40.199045178706335, -8.450461434175615],
              [-40.299045178706336, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '413',
        ID: 40173.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.4005,
        ANNUAL: 5606.0,
        JAN: 5984.0,
        FEB: 5761.0,
        MAR: 5739.0,
        APR: 5147.0,
        MAY: 4667.0,
        JUN: 4415.0,
        JUL: 4705.0,
        AUG: 5556.0,
        SEP: 6324.0,
        OCT: 6409.0,
        NOV: 6442.0,
        DEC: 6129.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.450461434175615],
              [-40.199045178706335, -8.350461434175616],
              [-40.099045178706334, -8.350461434175616],
              [-40.099045178706334, -8.450461434175615],
              [-40.199045178706335, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '414',
        ID: 40174.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.4005,
        ANNUAL: 5635.0,
        JAN: 6033.0,
        FEB: 5837.0,
        MAR: 5810.0,
        APR: 5200.0,
        MAY: 4686.0,
        JUN: 4400.0,
        JUL: 4667.0,
        AUG: 5519.0,
        SEP: 6310.0,
        OCT: 6432.0,
        NOV: 6511.0,
        DEC: 6209.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.450461434175615],
              [-40.099045178706334, -8.350461434175616],
              [-39.999045178706332, -8.350461434175616],
              [-39.999045178706332, -8.450461434175615],
              [-40.099045178706334, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '415',
        ID: 40175.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.4005,
        ANNUAL: 5663.0,
        JAN: 6105.0,
        FEB: 5822.0,
        MAR: 5837.0,
        APR: 5268.0,
        MAY: 4698.0,
        JUN: 4423.0,
        JUL: 4683.0,
        AUG: 5525.0,
        SEP: 6269.0,
        OCT: 6462.0,
        NOV: 6565.0,
        DEC: 6301.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.450461434175615],
              [-39.999045178706332, -8.350461434175616],
              [-39.899045178706331, -8.350461434175616],
              [-39.899045178706331, -8.450461434175615],
              [-39.999045178706332, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '416',
        ID: 40176.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.4005,
        ANNUAL: 5715.0,
        JAN: 6183.0,
        FEB: 5871.0,
        MAR: 5907.0,
        APR: 5332.0,
        MAY: 4748.0,
        JUN: 4447.0,
        JUL: 4682.0,
        AUG: 5532.0,
        SEP: 6328.0,
        OCT: 6511.0,
        NOV: 6643.0,
        DEC: 6395.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.450461434175615],
              [-39.899045178706331, -8.350461434175616],
              [-39.799045178706329, -8.350461434175616],
              [-39.799045178706329, -8.450461434175615],
              [-39.899045178706331, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '417',
        ID: 40177.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.4005,
        ANNUAL: 5705.0,
        JAN: 6140.0,
        FEB: 5846.0,
        MAR: 5922.0,
        APR: 5315.0,
        MAY: 4760.0,
        JUN: 4437.0,
        JUL: 4657.0,
        AUG: 5504.0,
        SEP: 6358.0,
        OCT: 6488.0,
        NOV: 6635.0,
        DEC: 6398.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.450461434175615],
              [-39.799045178706329, -8.350461434175616],
              [-39.699045178706328, -8.350461434175616],
              [-39.699045178706328, -8.450461434175615],
              [-39.799045178706329, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '418',
        ID: 40178.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.4005,
        ANNUAL: 5673.0,
        JAN: 6116.0,
        FEB: 5819.0,
        MAR: 5947.0,
        APR: 5368.0,
        MAY: 4724.0,
        JUN: 4405.0,
        JUL: 4637.0,
        AUG: 5463.0,
        SEP: 6316.0,
        OCT: 6432.0,
        NOV: 6594.0,
        DEC: 6255.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -8.450461434175615],
              [-39.699045178706328, -8.350461434175616],
              [-39.599045178706326, -8.350461434175616],
              [-39.599045178706326, -8.450461434175615],
              [-39.699045178706328, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '419',
        ID: 40179.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.4005,
        ANNUAL: 5703.0,
        JAN: 6157.0,
        FEB: 5840.0,
        MAR: 5969.0,
        APR: 5375.0,
        MAY: 4748.0,
        JUN: 4414.0,
        JUL: 4666.0,
        AUG: 5476.0,
        SEP: 6343.0,
        OCT: 6492.0,
        NOV: 6657.0,
        DEC: 6307.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -8.450461434175615],
              [-39.599045178706326, -8.350461434175616],
              [-39.499045178706325, -8.350461434175616],
              [-39.499045178706325, -8.450461434175615],
              [-39.599045178706326, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '420',
        ID: 40180.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -8.4005,
        ANNUAL: 5689.0,
        JAN: 6169.0,
        FEB: 5872.0,
        MAR: 5965.0,
        APR: 5356.0,
        MAY: 4748.0,
        JUN: 4394.0,
        JUL: 4639.0,
        AUG: 5418.0,
        SEP: 6292.0,
        OCT: 6450.0,
        NOV: 6646.0,
        DEC: 6322.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -8.450461434175615],
              [-39.499045178706325, -8.350461434175616],
              [-39.399045178706324, -8.350461434175616],
              [-39.399045178706324, -8.450461434175615],
              [-39.499045178706325, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '421',
        ID: 40181.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -8.4005,
        ANNUAL: 5673.0,
        JAN: 6105.0,
        FEB: 5879.0,
        MAR: 5968.0,
        APR: 5383.0,
        MAY: 4737.0,
        JUN: 4357.0,
        JUL: 4570.0,
        AUG: 5386.0,
        SEP: 6285.0,
        OCT: 6462.0,
        NOV: 6639.0,
        DEC: 6305.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.399045178706324, -8.450461434175615],
              [-39.399045178706324, -8.350461434175616],
              [-39.299045178706322, -8.350461434175616],
              [-39.299045178706322, -8.450461434175615],
              [-39.399045178706324, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '422',
        ID: 40182.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.4005,
        ANNUAL: 5701.0,
        JAN: 6125.0,
        FEB: 5911.0,
        MAR: 5991.0,
        APR: 5440.0,
        MAY: 4801.0,
        JUN: 4371.0,
        JUL: 4603.0,
        AUG: 5401.0,
        SEP: 6325.0,
        OCT: 6492.0,
        NOV: 6655.0,
        DEC: 6301.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -8.450461434175615],
              [-39.299045178706322, -8.350461434175616],
              [-39.199045178706321, -8.350461434175616],
              [-39.199045178706321, -8.450461434175615],
              [-39.299045178706322, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '423',
        ID: 40183.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.4005,
        ANNUAL: 5725.0,
        JAN: 6185.0,
        FEB: 5963.0,
        MAR: 5959.0,
        APR: 5454.0,
        MAY: 4794.0,
        JUN: 4362.0,
        JUL: 4659.0,
        AUG: 5441.0,
        SEP: 6358.0,
        OCT: 6511.0,
        NOV: 6692.0,
        DEC: 6327.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -8.450461434175615],
              [-39.199045178706321, -8.350461434175616],
              [-39.099045178706319, -8.350461434175616],
              [-39.099045178706319, -8.450461434175615],
              [-39.199045178706321, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '424',
        ID: 40184.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.4005,
        ANNUAL: 5731.0,
        JAN: 6187.0,
        FEB: 6004.0,
        MAR: 5985.0,
        APR: 5478.0,
        MAY: 4774.0,
        JUN: 4334.0,
        JUL: 4663.0,
        AUG: 5408.0,
        SEP: 6374.0,
        OCT: 6508.0,
        NOV: 6713.0,
        DEC: 6350.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.099045178706319, -8.450461434175615],
              [-39.099045178706319, -8.350461434175616],
              [-38.999045178706318, -8.350461434175616],
              [-38.999045178706318, -8.450461434175615],
              [-39.099045178706319, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '425',
        ID: 40185.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.4005,
        ANNUAL: 5729.0,
        JAN: 6210.0,
        FEB: 6035.0,
        MAR: 6007.0,
        APR: 5472.0,
        MAY: 4758.0,
        JUN: 4311.0,
        JUL: 4626.0,
        AUG: 5387.0,
        SEP: 6382.0,
        OCT: 6515.0,
        NOV: 6702.0,
        DEC: 6345.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.450461434175615],
              [-38.999045178706318, -8.350461434175616],
              [-38.899045178706317, -8.350461434175616],
              [-38.899045178706317, -8.450461434175615],
              [-38.999045178706318, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '426',
        ID: 40186.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.4005,
        ANNUAL: 5714.0,
        JAN: 6218.0,
        FEB: 6056.0,
        MAR: 6033.0,
        APR: 5505.0,
        MAY: 4754.0,
        JUN: 4276.0,
        JUL: 4550.0,
        AUG: 5318.0,
        SEP: 6331.0,
        OCT: 6490.0,
        NOV: 6678.0,
        DEC: 6365.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.450461434175615],
              [-38.899045178706317, -8.350461434175616],
              [-38.799045178706315, -8.350461434175616],
              [-38.799045178706315, -8.450461434175615],
              [-38.899045178706317, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '427',
        ID: 40187.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.4005,
        ANNUAL: 5704.0,
        JAN: 6213.0,
        FEB: 6047.0,
        MAR: 6026.0,
        APR: 5488.0,
        MAY: 4710.0,
        JUN: 4224.0,
        JUL: 4466.0,
        AUG: 5289.0,
        SEP: 6339.0,
        OCT: 6519.0,
        NOV: 6709.0,
        DEC: 6420.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.450461434175615],
              [-38.799045178706315, -8.350461434175616],
              [-38.699045178706314, -8.350461434175616],
              [-38.699045178706314, -8.450461434175615],
              [-38.799045178706315, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '428',
        ID: 40188.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.4005,
        ANNUAL: 5702.0,
        JAN: 6237.0,
        FEB: 6058.0,
        MAR: 6050.0,
        APR: 5477.0,
        MAY: 4704.0,
        JUN: 4221.0,
        JUL: 4455.0,
        AUG: 5258.0,
        SEP: 6312.0,
        OCT: 6516.0,
        NOV: 6689.0,
        DEC: 6446.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.450461434175615],
              [-38.699045178706314, -8.350461434175616],
              [-38.599045178706312, -8.350461434175616],
              [-38.599045178706312, -8.450461434175615],
              [-38.699045178706314, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '429',
        ID: 40189.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.4005,
        ANNUAL: 5715.0,
        JAN: 6298.0,
        FEB: 6089.0,
        MAR: 6109.0,
        APR: 5548.0,
        MAY: 4699.0,
        JUN: 4223.0,
        JUL: 4481.0,
        AUG: 5285.0,
        SEP: 6266.0,
        OCT: 6487.0,
        NOV: 6669.0,
        DEC: 6426.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.450461434175615],
              [-38.599045178706312, -8.350461434175616],
              [-38.499045178706311, -8.350461434175616],
              [-38.499045178706311, -8.450461434175615],
              [-38.599045178706312, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '430',
        ID: 40190.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.4005,
        ANNUAL: 5719.0,
        JAN: 6312.0,
        FEB: 6071.0,
        MAR: 6136.0,
        APR: 5536.0,
        MAY: 4721.0,
        JUN: 4220.0,
        JUL: 4513.0,
        AUG: 5282.0,
        SEP: 6261.0,
        OCT: 6483.0,
        NOV: 6671.0,
        DEC: 6426.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.450461434175615],
              [-38.499045178706311, -8.350461434175616],
              [-38.399045178706309, -8.350461434175616],
              [-38.399045178706309, -8.450461434175615],
              [-38.499045178706311, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '431',
        ID: 40191.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.4005,
        ANNUAL: 5720.0,
        JAN: 6302.0,
        FEB: 6046.0,
        MAR: 6106.0,
        APR: 5557.0,
        MAY: 4718.0,
        JUN: 4241.0,
        JUL: 4524.0,
        AUG: 5284.0,
        SEP: 6274.0,
        OCT: 6478.0,
        NOV: 6689.0,
        DEC: 6417.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.450461434175615],
              [-38.399045178706309, -8.350461434175616],
              [-38.299045178706308, -8.350461434175616],
              [-38.299045178706308, -8.450461434175615],
              [-38.399045178706309, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '432',
        ID: 40192.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.4005,
        ANNUAL: 5724.0,
        JAN: 6284.0,
        FEB: 6054.0,
        MAR: 6102.0,
        APR: 5593.0,
        MAY: 4720.0,
        JUN: 4240.0,
        JUL: 4511.0,
        AUG: 5274.0,
        SEP: 6265.0,
        OCT: 6484.0,
        NOV: 6740.0,
        DEC: 6414.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.450461434175615],
              [-38.299045178706308, -8.350461434175616],
              [-38.199045178706307, -8.350461434175616],
              [-38.199045178706307, -8.450461434175615],
              [-38.299045178706308, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '433',
        ID: 40193.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.4005,
        ANNUAL: 5756.0,
        JAN: 6382.0,
        FEB: 6131.0,
        MAR: 6147.0,
        APR: 5636.0,
        MAY: 4709.0,
        JUN: 4229.0,
        JUL: 4509.0,
        AUG: 5269.0,
        SEP: 6249.0,
        OCT: 6521.0,
        NOV: 6789.0,
        DEC: 6505.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.450461434175615],
              [-38.199045178706307, -8.350461434175616],
              [-38.099045178706305, -8.350461434175616],
              [-38.099045178706305, -8.450461434175615],
              [-38.199045178706307, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '434',
        ID: 40194.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.4005,
        ANNUAL: 5759.0,
        JAN: 6412.0,
        FEB: 6142.0,
        MAR: 6158.0,
        APR: 5638.0,
        MAY: 4702.0,
        JUN: 4207.0,
        JUL: 4506.0,
        AUG: 5259.0,
        SEP: 6242.0,
        OCT: 6500.0,
        NOV: 6792.0,
        DEC: 6550.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.450461434175615],
              [-38.099045178706305, -8.350461434175616],
              [-37.999045178706304, -8.350461434175616],
              [-37.999045178706304, -8.450461434175615],
              [-38.099045178706305, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '435',
        ID: 40195.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.4005,
        ANNUAL: 5731.0,
        JAN: 6290.0,
        FEB: 6103.0,
        MAR: 6176.0,
        APR: 5659.0,
        MAY: 4750.0,
        JUN: 4181.0,
        JUL: 4469.0,
        AUG: 5239.0,
        SEP: 6220.0,
        OCT: 6434.0,
        NOV: 6794.0,
        DEC: 6457.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.450461434175615],
              [-37.999045178706304, -8.350461434175616],
              [-37.899045178706302, -8.350461434175616],
              [-37.899045178706302, -8.450461434175615],
              [-37.999045178706304, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '436',
        ID: 40196.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.4005,
        ANNUAL: 5756.0,
        JAN: 6317.0,
        FEB: 6193.0,
        MAR: 6193.0,
        APR: 5704.0,
        MAY: 4788.0,
        JUN: 4217.0,
        JUL: 4475.0,
        AUG: 5236.0,
        SEP: 6211.0,
        OCT: 6471.0,
        NOV: 6778.0,
        DEC: 6495.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.450461434175615],
              [-37.899045178706302, -8.350461434175616],
              [-37.799045178706301, -8.350461434175616],
              [-37.799045178706301, -8.450461434175615],
              [-37.899045178706302, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '437',
        ID: 40197.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.4005,
        ANNUAL: 5795.0,
        JAN: 6367.0,
        FEB: 6247.0,
        MAR: 6203.0,
        APR: 5741.0,
        MAY: 4823.0,
        JUN: 4281.0,
        JUL: 4527.0,
        AUG: 5302.0,
        SEP: 6276.0,
        OCT: 6503.0,
        NOV: 6772.0,
        DEC: 6501.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.450461434175615],
              [-37.799045178706301, -8.350461434175616],
              [-37.699045178706299, -8.350461434175616],
              [-37.699045178706299, -8.450461434175615],
              [-37.799045178706301, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '438',
        ID: 40198.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.4005,
        ANNUAL: 5775.0,
        JAN: 6371.0,
        FEB: 6253.0,
        MAR: 6208.0,
        APR: 5704.0,
        MAY: 4829.0,
        JUN: 4250.0,
        JUL: 4473.0,
        AUG: 5272.0,
        SEP: 6219.0,
        OCT: 6476.0,
        NOV: 6725.0,
        DEC: 6518.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.450461434175615],
              [-37.699045178706299, -8.350461434175616],
              [-37.599045178706298, -8.350461434175616],
              [-37.599045178706298, -8.450461434175615],
              [-37.699045178706299, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '439',
        ID: 40199.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.4005,
        ANNUAL: 5719.0,
        JAN: 6309.0,
        FEB: 6142.0,
        MAR: 6118.0,
        APR: 5626.0,
        MAY: 4763.0,
        JUN: 4202.0,
        JUL: 4429.0,
        AUG: 5226.0,
        SEP: 6174.0,
        OCT: 6428.0,
        NOV: 6752.0,
        DEC: 6462.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.450461434175615],
              [-37.599045178706298, -8.350461434175616],
              [-37.499045178706297, -8.350461434175616],
              [-37.499045178706297, -8.450461434175615],
              [-37.599045178706298, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '440',
        ID: 40200.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.4005,
        ANNUAL: 5687.0,
        JAN: 6272.0,
        FEB: 6073.0,
        MAR: 6078.0,
        APR: 5560.0,
        MAY: 4743.0,
        JUN: 4180.0,
        JUL: 4404.0,
        AUG: 5191.0,
        SEP: 6147.0,
        OCT: 6418.0,
        NOV: 6733.0,
        DEC: 6446.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.450461434175615],
              [-37.499045178706297, -8.350461434175616],
              [-37.399045178706295, -8.350461434175616],
              [-37.399045178706295, -8.450461434175615],
              [-37.499045178706297, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '441',
        ID: 40201.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.4005,
        ANNUAL: 5713.0,
        JAN: 6331.0,
        FEB: 6119.0,
        MAR: 6104.0,
        APR: 5602.0,
        MAY: 4727.0,
        JUN: 4186.0,
        JUL: 4431.0,
        AUG: 5199.0,
        SEP: 6172.0,
        OCT: 6417.0,
        NOV: 6773.0,
        DEC: 6491.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.450461434175615],
              [-37.399045178706295, -8.350461434175616],
              [-37.299045178706294, -8.350461434175616],
              [-37.299045178706294, -8.450461434175615],
              [-37.399045178706295, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '442',
        ID: 40202.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.4005,
        ANNUAL: 5729.0,
        JAN: 6312.0,
        FEB: 6121.0,
        MAR: 6136.0,
        APR: 5615.0,
        MAY: 4766.0,
        JUN: 4251.0,
        JUL: 4467.0,
        AUG: 5248.0,
        SEP: 6157.0,
        OCT: 6450.0,
        NOV: 6743.0,
        DEC: 6477.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.450461434175615],
              [-37.299045178706294, -8.350461434175616],
              [-37.199045178706292, -8.350461434175616],
              [-37.199045178706292, -8.450461434175615],
              [-37.299045178706294, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '443',
        ID: 40203.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.4005,
        ANNUAL: 5685.0,
        JAN: 6218.0,
        FEB: 6110.0,
        MAR: 6092.0,
        APR: 5588.0,
        MAY: 4723.0,
        JUN: 4239.0,
        JUL: 4425.0,
        AUG: 5203.0,
        SEP: 6111.0,
        OCT: 6372.0,
        NOV: 6681.0,
        DEC: 6460.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -8.450461434175615],
              [-37.199045178706292, -8.350461434175616],
              [-37.099045178706291, -8.350461434175616],
              [-37.099045178706291, -8.450461434175615],
              [-37.199045178706292, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '444',
        ID: 40204.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.4005,
        ANNUAL: 5655.0,
        JAN: 6137.0,
        FEB: 6076.0,
        MAR: 6101.0,
        APR: 5575.0,
        MAY: 4698.0,
        JUN: 4205.0,
        JUL: 4373.0,
        AUG: 5185.0,
        SEP: 6091.0,
        OCT: 6353.0,
        NOV: 6641.0,
        DEC: 6426.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.099045178706291, -8.450461434175615],
              [-37.099045178706291, -8.350461434175616],
              [-36.99904517870629, -8.350461434175616],
              [-36.99904517870629, -8.450461434175615],
              [-37.099045178706291, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '445',
        ID: 40205.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.4005,
        ANNUAL: 5609.0,
        JAN: 6135.0,
        FEB: 6043.0,
        MAR: 6072.0,
        APR: 5531.0,
        MAY: 4630.0,
        JUN: 4110.0,
        JUL: 4274.0,
        AUG: 5102.0,
        SEP: 6034.0,
        OCT: 6304.0,
        NOV: 6649.0,
        DEC: 6423.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.99904517870629, -8.450461434175615],
              [-36.99904517870629, -8.350461434175616],
              [-36.899045178706288, -8.350461434175616],
              [-36.899045178706288, -8.450461434175615],
              [-36.99904517870629, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '446',
        ID: 40206.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.4005,
        ANNUAL: 5544.0,
        JAN: 6118.0,
        FEB: 5994.0,
        MAR: 6019.0,
        APR: 5492.0,
        MAY: 4559.0,
        JUN: 4013.0,
        JUL: 4150.0,
        AUG: 4957.0,
        SEP: 5916.0,
        OCT: 6250.0,
        NOV: 6644.0,
        DEC: 6419.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -8.450461434175615],
              [-36.899045178706288, -8.350461434175616],
              [-36.799045178706287, -8.350461434175616],
              [-36.799045178706287, -8.450461434175615],
              [-36.899045178706288, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '447',
        ID: 40207.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.4005,
        ANNUAL: 5444.0,
        JAN: 6158.0,
        FEB: 5955.0,
        MAR: 5944.0,
        APR: 5391.0,
        MAY: 4442.0,
        JUN: 3860.0,
        JUL: 4003.0,
        AUG: 4777.0,
        SEP: 5734.0,
        OCT: 6125.0,
        NOV: 6549.0,
        DEC: 6392.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -8.450461434175615],
              [-36.799045178706287, -8.350461434175616],
              [-36.699045178706285, -8.350461434175616],
              [-36.699045178706285, -8.450461434175615],
              [-36.799045178706287, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '448',
        ID: 40208.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.4005,
        ANNUAL: 5402.0,
        JAN: 6104.0,
        FEB: 5922.0,
        MAR: 5931.0,
        APR: 5370.0,
        MAY: 4415.0,
        JUN: 3872.0,
        JUL: 3983.0,
        AUG: 4761.0,
        SEP: 5660.0,
        OCT: 6036.0,
        NOV: 6455.0,
        DEC: 6321.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -8.450461434175615],
              [-36.699045178706285, -8.350461434175616],
              [-36.599045178706284, -8.350461434175616],
              [-36.599045178706284, -8.450461434175615],
              [-36.699045178706285, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '449',
        ID: 40209.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.4005,
        ANNUAL: 5377.0,
        JAN: 6012.0,
        FEB: 5867.0,
        MAR: 5920.0,
        APR: 5334.0,
        MAY: 4399.0,
        JUN: 3906.0,
        JUL: 4010.0,
        AUG: 4754.0,
        SEP: 5633.0,
        OCT: 6014.0,
        NOV: 6401.0,
        DEC: 6270.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.450461434175615],
              [-36.599045178706284, -8.350461434175616],
              [-36.499045178706282, -8.350461434175616],
              [-36.499045178706282, -8.450461434175615],
              [-36.599045178706284, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '450',
        ID: 40210.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.4005,
        ANNUAL: 5330.0,
        JAN: 6024.0,
        FEB: 5780.0,
        MAR: 5820.0,
        APR: 5281.0,
        MAY: 4358.0,
        JUN: 3858.0,
        JUL: 4020.0,
        AUG: 4703.0,
        SEP: 5574.0,
        OCT: 5957.0,
        NOV: 6362.0,
        DEC: 6225.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.450461434175615],
              [-36.499045178706282, -8.350461434175616],
              [-36.399045178706281, -8.350461434175616],
              [-36.399045178706281, -8.450461434175615],
              [-36.499045178706282, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '451',
        ID: 40211.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.4005,
        ANNUAL: 5292.0,
        JAN: 5987.0,
        FEB: 5763.0,
        MAR: 5757.0,
        APR: 5226.0,
        MAY: 4335.0,
        JUN: 3851.0,
        JUL: 4009.0,
        AUG: 4666.0,
        SEP: 5542.0,
        OCT: 5902.0,
        NOV: 6310.0,
        DEC: 6160.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.450461434175615],
              [-36.399045178706281, -8.350461434175616],
              [-36.29904517870628, -8.350461434175616],
              [-36.29904517870628, -8.450461434175615],
              [-36.399045178706281, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '452',
        ID: 40212.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.4005,
        ANNUAL: 5338.0,
        JAN: 5922.0,
        FEB: 5785.0,
        MAR: 5878.0,
        APR: 5287.0,
        MAY: 4464.0,
        JUN: 3963.0,
        JUL: 4053.0,
        AUG: 4757.0,
        SEP: 5570.0,
        OCT: 5917.0,
        NOV: 6333.0,
        DEC: 6121.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.450461434175615],
              [-36.29904517870628, -8.350461434175616],
              [-36.199045178706278, -8.350461434175616],
              [-36.199045178706278, -8.450461434175615],
              [-36.29904517870628, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '453',
        ID: 40213.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.4005,
        ANNUAL: 5351.0,
        JAN: 5936.0,
        FEB: 5822.0,
        MAR: 5878.0,
        APR: 5254.0,
        MAY: 4457.0,
        JUN: 3994.0,
        JUL: 4073.0,
        AUG: 4823.0,
        SEP: 5621.0,
        OCT: 5944.0,
        NOV: 6297.0,
        DEC: 6112.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.450461434175615],
              [-36.199045178706278, -8.350461434175616],
              [-36.099045178706277, -8.350461434175616],
              [-36.099045178706277, -8.450461434175615],
              [-36.199045178706278, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '454',
        ID: 40214.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.4005,
        ANNUAL: 5250.0,
        JAN: 5848.0,
        FEB: 5760.0,
        MAR: 5803.0,
        APR: 5185.0,
        MAY: 4366.0,
        JUN: 3909.0,
        JUL: 3946.0,
        AUG: 4712.0,
        SEP: 5438.0,
        OCT: 5822.0,
        NOV: 6185.0,
        DEC: 6024.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.450461434175615],
              [-36.099045178706277, -8.350461434175616],
              [-35.999045178706275, -8.350461434175616],
              [-35.999045178706275, -8.450461434175615],
              [-36.099045178706277, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '455',
        ID: 40215.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.4005,
        ANNUAL: 5160.0,
        JAN: 5671.0,
        FEB: 5716.0,
        MAR: 5737.0,
        APR: 5104.0,
        MAY: 4301.0,
        JUN: 3875.0,
        JUL: 3957.0,
        AUG: 4634.0,
        SEP: 5323.0,
        OCT: 5644.0,
        NOV: 6057.0,
        DEC: 5906.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.450461434175615],
              [-35.999045178706275, -8.350461434175616],
              [-35.899045178706274, -8.350461434175616],
              [-35.899045178706274, -8.450461434175615],
              [-35.999045178706275, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '456',
        ID: 40216.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.4005,
        ANNUAL: 5184.0,
        JAN: 5732.0,
        FEB: 5694.0,
        MAR: 5732.0,
        APR: 5078.0,
        MAY: 4304.0,
        JUN: 3856.0,
        JUL: 3981.0,
        AUG: 4662.0,
        SEP: 5392.0,
        OCT: 5700.0,
        NOV: 6131.0,
        DEC: 5944.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.450461434175615],
              [-35.899045178706274, -8.350461434175616],
              [-35.799045178706272, -8.350461434175616],
              [-35.799045178706272, -8.450461434175615],
              [-35.899045178706274, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '457',
        ID: 40217.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.4005,
        ANNUAL: 5192.0,
        JAN: 5778.0,
        FEB: 5708.0,
        MAR: 5793.0,
        APR: 5075.0,
        MAY: 4285.0,
        JUN: 3835.0,
        JUL: 3938.0,
        AUG: 4632.0,
        SEP: 5365.0,
        OCT: 5737.0,
        NOV: 6142.0,
        DEC: 6013.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.450461434175615],
              [-35.799045178706272, -8.350461434175616],
              [-35.699045178706271, -8.350461434175616],
              [-35.699045178706271, -8.450461434175615],
              [-35.799045178706272, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '458',
        ID: 40218.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.4005,
        ANNUAL: 5122.0,
        JAN: 5690.0,
        FEB: 5658.0,
        MAR: 5735.0,
        APR: 5034.0,
        MAY: 4230.0,
        JUN: 3823.0,
        JUL: 3885.0,
        AUG: 4505.0,
        SEP: 5220.0,
        OCT: 5584.0,
        NOV: 6079.0,
        DEC: 6014.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.450461434175615],
              [-35.699045178706271, -8.350461434175616],
              [-35.59904517870627, -8.350461434175616],
              [-35.59904517870627, -8.450461434175615],
              [-35.699045178706271, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '459',
        ID: 40219.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.4005,
        ANNUAL: 4987.0,
        JAN: 5552.0,
        FEB: 5524.0,
        MAR: 5624.0,
        APR: 4900.0,
        MAY: 4135.0,
        JUN: 3747.0,
        JUL: 3752.0,
        AUG: 4365.0,
        SEP: 5073.0,
        OCT: 5406.0,
        NOV: 5896.0,
        DEC: 5870.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.450461434175615],
              [-35.59904517870627, -8.350461434175616],
              [-35.499045178706268, -8.350461434175616],
              [-35.499045178706268, -8.450461434175615],
              [-35.59904517870627, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '460',
        ID: 40220.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.4005,
        ANNUAL: 4983.0,
        JAN: 5540.0,
        FEB: 5539.0,
        MAR: 5632.0,
        APR: 4872.0,
        MAY: 4166.0,
        JUN: 3763.0,
        JUL: 3772.0,
        AUG: 4353.0,
        SEP: 5107.0,
        OCT: 5412.0,
        NOV: 5821.0,
        DEC: 5826.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.450461434175615],
              [-35.499045178706268, -8.350461434175616],
              [-35.399045178706267, -8.350461434175616],
              [-35.399045178706267, -8.450461434175615],
              [-35.499045178706268, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '461',
        ID: 40221.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.4005,
        ANNUAL: 5056.0,
        JAN: 5561.0,
        FEB: 5609.0,
        MAR: 5643.0,
        APR: 4895.0,
        MAY: 4217.0,
        JUN: 3851.0,
        JUL: 3905.0,
        AUG: 4494.0,
        SEP: 5230.0,
        OCT: 5536.0,
        NOV: 5861.0,
        DEC: 5863.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.450461434175615],
              [-35.399045178706267, -8.350461434175616],
              [-35.299045178706265, -8.350461434175616],
              [-35.299045178706265, -8.450461434175615],
              [-35.399045178706267, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '462',
        ID: 40222.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.4005,
        ANNUAL: 5198.0,
        JAN: 5651.0,
        FEB: 5772.0,
        MAR: 5793.0,
        APR: 5014.0,
        MAY: 4301.0,
        JUN: 3988.0,
        JUL: 4041.0,
        AUG: 4736.0,
        SEP: 5400.0,
        OCT: 5705.0,
        NOV: 5987.0,
        DEC: 5994.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.450461434175615],
              [-35.299045178706265, -8.350461434175616],
              [-35.199045178706264, -8.350461434175616],
              [-35.199045178706264, -8.450461434175615],
              [-35.299045178706265, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '463',
        ID: 40223.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.4005,
        ANNUAL: 5222.0,
        JAN: 5661.0,
        FEB: 5829.0,
        MAR: 5829.0,
        APR: 5041.0,
        MAY: 4316.0,
        JUN: 4043.0,
        JUL: 4097.0,
        AUG: 4804.0,
        SEP: 5378.0,
        OCT: 5712.0,
        NOV: 5978.0,
        DEC: 5978.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.450461434175615],
              [-35.199045178706264, -8.350461434175616],
              [-35.099045178706263, -8.350461434175616],
              [-35.099045178706263, -8.450461434175615],
              [-35.199045178706264, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '464',
        ID: 40224.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.4005,
        ANNUAL: 5275.0,
        JAN: 5720.0,
        FEB: 5839.0,
        MAR: 5904.0,
        APR: 5140.0,
        MAY: 4396.0,
        JUN: 4110.0,
        JUL: 4195.0,
        AUG: 4870.0,
        SEP: 5403.0,
        OCT: 5732.0,
        NOV: 5972.0,
        DEC: 6014.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -8.450461434175615],
              [-35.099045178706263, -8.350461434175616],
              [-34.999045178706261, -8.350461434175616],
              [-34.999045178706261, -8.450461434175615],
              [-35.099045178706263, -8.450461434175615],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '465',
        ID: 40225.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -8.4005,
        ANNUAL: 5573.0,
        JAN: 6063.0,
        FEB: 6158.0,
        MAR: 6139.0,
        APR: 5322.0,
        MAY: 4563.0,
        JUN: 4286.0,
        JUL: 4370.0,
        AUG: 5157.0,
        SEP: 5827.0,
        OCT: 6168.0,
        NOV: 6397.0,
        DEC: 6425.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.939892649999898, -8.350528619999977],
              [-34.9397768339999, -8.350797952999935],
              [-34.939775521999898, -8.350810057999986],
              [-34.939703398999903, -8.351133009999954],
              [-34.939655533, -8.351403433999959],
              [-34.939653565, -8.35142159099996],
              [-34.939651798999897, -8.351666288999986],
              [-34.939651144, -8.351672341999963],
              [-34.939743106, -8.351908615999944],
              [-34.939850368999899, -8.352060802999976],
              [-34.939915025999909, -8.352263572999954],
              [-34.939925948999907, -8.352276975999926],
              [-34.940032773, -8.352490341999953],
              [-34.9401400369999, -8.352642530999969],
              [-34.940277018, -8.352748891999976],
              [-34.940378166, -8.352900432999949],
              [-34.940451445999898, -8.353036423999981],
              [-34.940688282999901, -8.353135013999974],
              [-34.940699862, -8.353142363999947],
              [-34.940973841, -8.353183670999943],
              [-34.941180750999898, -8.353101545999953],
              [-34.941411468999902, -8.353028072999955],
              [-34.941533369999902, -8.353102223999933],
              [-34.941519598999903, -8.353229319999977],
              [-34.941445309999899, -8.35334387899997],
              [-34.941424991, -8.353360089999976],
              [-34.941401164999903, -8.353522858999954],
              [-34.941479152999904, -8.353659695999967],
              [-34.941501655, -8.353680448999976],
              [-34.941664198999902, -8.353722174999964],
              [-34.941840941, -8.353747038999924],
              [-34.941904512, -8.353845611999935],
              [-34.941943392, -8.354057890999965],
              [-34.941941425, -8.354076048999964],
              [-34.941964793, -8.354317299999925],
              [-34.942015908, -8.354530883999928],
              [-34.942059105999903, -8.354616788999987],
              [-34.9422627289999, -8.354736352999957],
              [-34.94251137, -8.35472603499994],
              [-34.942524261, -8.354721282999954],
              [-34.9427445009999, -8.354573293999973],
              [-34.943007323, -8.354546126999939],
              [-34.943090771, -8.354689641999927],
              [-34.94308946, -8.354701743999959],
              [-34.943039859, -8.354873976999951],
              [-34.943038547999898, -8.354886079999968],
              [-34.942890186999897, -8.355170268999938],
              [-34.942662276, -8.355617455999949],
              [-34.942626215, -8.355778925999976],
              [-34.942807549999898, -8.355932854999931],
              [-34.943059229, -8.356179957999931],
              [-34.943004592, -8.356455776999942],
              [-34.942878723, -8.356760780999926],
              [-34.942944038999897, -8.356957501999938],
              [-34.9429556179999, -8.356964851999971],
              [-34.943229802, -8.357232701999973],
              [-34.943356397999899, -8.357409562999981],
              [-34.9434487669999, -8.358098935999976],
              [-34.943649103, -8.358420181999966],
              [-34.943821487, -8.358542544999972],
              [-34.9438453019999, -8.358551193999972],
              [-34.943856224999898, -8.358564596999937],
              [-34.944049155, -8.358554445999966],
              [-34.9442794409999, -8.358542125999966],
              [-34.944433468999897, -8.358662541999928],
              [-34.944548389, -8.358686970999939],
              [-34.944684501, -8.358744258999939],
              [-34.94486323, -8.358869104999945],
              [-34.94503736, -8.358918190999933],
              [-34.945263526, -8.358715614999948],
              [-34.945492752999897, -8.358427641999981],
              [-34.945962664, -8.358887720999961],
              [-34.946124118999897, -8.358996689999977],
              [-34.946205393999897, -8.359103287999966],
              [-34.946384554, -8.359220266999953],
              [-34.9466231399999, -8.35924556099997],
              [-34.947191268, -8.359269400999949],
              [-34.947628903, -8.358942319999933],
              [-34.948123967999898, -8.358713221999942],
              [-34.9484015479999, -8.358856658999976],
              [-34.948659776999897, -8.358871826999964],
              [-34.949042775999899, -8.358820624999964],
              [-34.949273282, -8.358692017999934],
              [-34.949637068999898, -8.358418363999938],
              [-34.949892475, -8.358059720999963],
              [-34.950111175, -8.357868657999969],
              [-34.9503414449999, -8.357684934999947],
              [-34.9508806649999, -8.357619695999965],
              [-34.951695553, -8.356868870999966],
              [-34.952102593999903, -8.356367190999952],
              [-34.952589119, -8.355873983999972],
              [-34.953438488, -8.355572295999934],
              [-34.954222334999898, -8.355311021999976],
              [-34.95425835, -8.355325529999957],
              [-34.954989769, -8.355620176999928],
              [-34.955229975, -8.355855147999938],
              [-34.955605792, -8.356222771999978],
              [-34.955615141, -8.356240600999975],
              [-34.956302718, -8.357551814999963],
              [-34.956460476, -8.357852660999976],
              [-34.957022842, -8.35920577799993],
              [-34.9575021859999, -8.361286239999959],
              [-34.957684123, -8.362570224999963],
              [-34.958018567, -8.363789809999959],
              [-34.958130104, -8.364196539999975],
              [-34.958553185, -8.364910473999936],
              [-34.959623445, -8.365519046999966],
              [-34.960920782, -8.366674841999952],
              [-34.963093575999899, -8.36861058199997],
              [-34.963587034999897, -8.369463993999943],
              [-34.964076045, -8.370479759999968],
              [-34.964739708, -8.371112722999948],
              [-34.965426325, -8.37218847099996],
              [-34.9655455669999, -8.372363933999969],
              [-34.965834244999897, -8.372433609999968],
              [-34.966055285, -8.372580782999929],
              [-34.966138713, -8.372570084999937],
              [-34.966691823, -8.371353112999934],
              [-34.966638826999898, -8.371113051999947],
              [-34.967515551, -8.368913565999954],
              [-34.967736368, -8.368455018999946],
              [-34.968064093999899, -8.368346476999925],
              [-34.970892501, -8.369460374999957],
              [-34.969998668999899, -8.372034724999924],
              [-34.969727772999903, -8.371889546999975],
              [-34.969029319, -8.372802568999923],
              [-34.969528829, -8.373647828999946],
              [-34.968501860999901, -8.374305570999981],
              [-34.968903995999902, -8.374864304999953],
              [-34.968969161, -8.375125888999946],
              [-34.968861998, -8.375446693999955],
              [-34.968514969, -8.375794129999969],
              [-34.968431746, -8.376137016999966],
              [-34.968139568, -8.376464255999965],
              [-34.968105152, -8.37672113499997],
              [-34.968289449999908, -8.377268226999934],
              [-34.968647874999903, -8.377970532999944],
              [-34.96905329, -8.378664806999943],
              [-34.969587368, -8.379679608999945],
              [-34.969778877, -8.379952968999939],
              [-34.970182414, -8.380270260999955],
              [-34.970453643, -8.380502447999955],
              [-34.971203881999898, -8.380740661999939],
              [-34.971495742999899, -8.380866509999974],
              [-34.971696114999901, -8.380497436999965],
              [-34.972846671, -8.381082610999941],
              [-34.972642611, -8.381485599999955],
              [-34.972745632999903, -8.381535120999956],
              [-34.979391248999903, -8.38093551699995],
              [-34.9793842509999, -8.380806173999931],
              [-34.979714387999898, -8.380793909999969],
              [-34.9800906299999, -8.380881040999958],
              [-34.980621948, -8.381141737999943],
              [-34.9805550889999, -8.382872018999938],
              [-34.980047453999902, -8.383107795999925],
              [-34.97986416, -8.383369412999967],
              [-34.979521573999897, -8.383750408999969],
              [-34.979152880999898, -8.38373688799993],
              [-34.978855591999903, -8.383721402999976],
              [-34.977855328, -8.383896425999977],
              [-34.977203071, -8.383878391999929],
              [-34.976613417, -8.384156551999975],
              [-34.97605286, -8.384207826999949],
              [-34.975411153, -8.384250552999955],
              [-34.975206876999898, -8.384228910999983],
              [-34.974576478, -8.384485721999972],
              [-34.974240606, -8.384646001999945],
              [-34.974174504, -8.385814226999974],
              [-34.974079077999903, -8.386298069999945],
              [-34.973880565999899, -8.386302576999977],
              [-34.973292643, -8.385848508999965],
              [-34.9711458519999, -8.388045822999972],
              [-34.9709622, -8.388435530999972],
              [-34.970276344, -8.389890908999975],
              [-34.96980452, -8.390892111999964],
              [-34.9620584319999, -8.394310823999936],
              [-34.962089789999901, -8.394482091999974],
              [-34.961981625, -8.394537971999977],
              [-34.962295595999898, -8.395253174999931],
              [-34.961392363, -8.395578354999945],
              [-34.9610828369999, -8.395482238999934],
              [-34.961021888999902, -8.395463312999931],
              [-34.960552650999901, -8.39567],
              [-34.961602750999901, -8.398127681999936],
              [-34.961781491999901, -8.398574532999929],
              [-34.960731391, -8.399010212999942],
              [-34.960038772, -8.398898499999977],
              [-34.958832274, -8.395993966999985],
              [-34.958117312, -8.396172707999932],
              [-34.959457864999898, -8.399390035999946],
              [-34.959636605999897, -8.399702831999948],
              [-34.959923516999901, -8.400170718999956],
              [-34.961975261, -8.399324124999959],
              [-34.962433417999897, -8.40030019799997],
              [-34.963230211999907, -8.402272262999926],
              [-34.965212236999903, -8.406903627999952],
              [-34.966456349999902, -8.409537298999963],
              [-34.966850802, -8.410352509999937],
              [-34.967536579, -8.411867362999937],
              [-34.969960731999898, -8.417049338999965],
              [-34.971497312999901, -8.420355402999967],
              [-34.973683331, -8.425058776999956],
              [-34.975505692999903, -8.428979718999928],
              [-34.977536278999899, -8.433348668999948],
              [-34.978557924, -8.435546810999936],
              [-34.979018294999896, -8.436537331999938],
              [-34.97988635, -8.438408470999949],
              [-34.980289187, -8.439276807999937],
              [-34.9809751929999, -8.441043283999925],
              [-34.981341578999903, -8.441986733999949],
              [-34.982220345, -8.444497729999968],
              [-34.982602229, -8.445644534999923],
              [-34.982914037999898, -8.446580902999921],
              [-34.983239463, -8.447760832999965],
              [-34.983436407999903, -8.448474920999953],
              [-34.983464399999903, -8.448627031999926],
              [-34.98380198322117, -8.450461434175615],
              [-34.999045178706261, -8.450461434175615],
              [-34.999045178706261, -8.350461434175616],
              [-34.939909614615424, -8.350461434175616],
              [-34.939892649999898, -8.350528619999977],
            ],
          ],
          [
            [
              [-34.956687518, -8.379628307999949],
              [-34.956709793999899, -8.380482639999968],
              [-34.956836806, -8.381253408999955],
              [-34.956871921999898, -8.382165911999946],
              [-34.956994755, -8.383113488999971],
              [-34.957057025, -8.383569253999948],
              [-34.9572162649999, -8.384160474999925],
              [-34.957348966, -8.384653158999978],
              [-34.957443570999899, -8.384810454999986],
              [-34.957571286, -8.385074347999925],
              [-34.957569709, -8.385088906999956],
              [-34.95768849, -8.38536658],
              [-34.957787562, -8.385620072999931],
              [-34.957896885, -8.385778930999951],
              [-34.958125783999897, -8.386002009999974],
              [-34.958327615, -8.386200128999965],
              [-34.958565452, -8.386409430999947],
              [-34.958885903999899, -8.386689279999924],
              [-34.959057247, -8.386825256999947],
              [-34.959208353999898, -8.387010632999926],
              [-34.959214135, -8.38702597199995],
              [-34.959377069, -8.387102151999956],
              [-34.959384428, -8.387102931999944],
              [-34.959714767, -8.387145315999934],
              [-34.960067442, -8.387256336999934],
              [-34.960240362, -8.387377754999925],
              [-34.9602461429999, -8.38739309499994],
              [-34.960476352999898, -8.387535312999937],
              [-34.960743356, -8.387681431999965],
              [-34.960771212999902, -8.387699110999963],
              [-34.960982765, -8.387876167999929],
              [-34.9609951159999, -8.387899567999975],
              [-34.961245038, -8.388065965999941],
              [-34.961412439999897, -8.388238343999948],
              [-34.961411652, -8.388245622999928],
              [-34.96166893399991, -8.388412802999939],
              [-34.96184396, -8.388446084999936],
              [-34.962071283999897, -8.38847754799998],
              [-34.962086000999903, -8.388479107999959],
              [-34.962335402, -8.388512911999944],
              [-34.962372194999901, -8.388516811999976],
              [-34.962658388, -8.388554516999925],
              [-34.962945372, -8.388584941999964],
              [-34.963127759, -8.388619004999956],
              [-34.963319081999899, -8.388639287999982],
              [-34.963341157999899, -8.388641627999954],
              [-34.963547725, -8.388589893999953],
              [-34.963801335, -8.388447421999967],
              [-34.964032607, -8.388236310999968],
              [-34.964292525, -8.388035599999961],
              [-34.964576886, -8.387815389999963],
              [-34.964819319999897, -8.387752253999956],
              [-34.965039026999897, -8.38771663799997],
              [-34.965303674, -8.387678423999942],
              [-34.965548875, -8.387682328999967],
              [-34.965696572999903, -8.387624352999978],
              [-34.966009576, -8.38741454099994],
              [-34.9662303389999, -8.387115300999934],
              [-34.966426299, -8.386719526999975],
              [-34.966656053, -8.386365205999935],
              [-34.966702661999904, -8.385934662999944],
              [-34.966749955999902, -8.385674601999943],
              [-34.967087166999903, -8.385388479999961],
              [-34.967235154, -8.385082294999968],
              [-34.967378405, -8.384643069999981],
              [-34.967385137, -8.384227235999958],
              [-34.967366066, -8.38369502899997],
              [-34.967426939999903, -8.38313349799995],
              [-34.9676262, -8.382529800999977],
              [-34.967826282, -8.382096621999953],
              [-34.967765474, -8.381597909999932],
              [-34.967476910999899, -8.381434786999927],
              [-34.967266729999899, -8.381431438999925],
              [-34.966988976, -8.381345194999943],
              [-34.966880187, -8.380936065999952],
              [-34.966636209999905, -8.380891268999953],
              [-34.966496322, -8.380592442999951],
              [-34.966153047, -8.380404589999953],
              [-34.9657881219999, -8.38059310399996],
              [-34.96544955, -8.380538279999932],
              [-34.965132637999901, -8.380107093999925],
              [-34.964876534, -8.379644487999943],
              [-34.9644318799999, -8.379332293999942],
              [-34.9647116209999, -8.378869692999956],
              [-34.965123831, -8.378951251999982],
              [-34.965744173, -8.379054870999941],
              [-34.966298283999897, -8.378886408999961],
              [-34.9663889079999, -8.37840374199993],
              [-34.966464527, -8.377881642999972],
              [-34.966380742999903, -8.377418366999958],
              [-34.966347003, -8.376846782999962],
              [-34.966157779999897, -8.376296604999945],
              [-34.9659766669999, -8.37567155799996],
              [-34.965980725999898, -8.375634118999924],
              [-34.965678001, -8.375071906999947],
              [-34.965411070999899, -8.374532433999946],
              [-34.965045517, -8.374020354999979],
              [-34.964768502, -8.373574446999944],
              [-34.96475907, -8.373308361999932],
              [-34.964919928, -8.373060318999933],
              [-34.965063895999897, -8.372791549999922],
              [-34.964896328999899, -8.372395113999971],
              [-34.964546984, -8.372263412999933],
              [-34.964126661, -8.372256747999936],
              [-34.963393482, -8.372311588999926],
              [-34.962778552, -8.372511498999932],
              [-34.961574395999904, -8.373027615999947],
              [-34.960695949, -8.373540372999969],
              [-34.959918193, -8.374006994999947],
              [-34.959087741, -8.374430148999975],
              [-34.958225528999897, -8.374793152999922],
              [-34.957708610999902, -8.375325253999961],
              [-34.9572470909999, -8.376052561999927],
              [-34.956987601, -8.376858093999946],
              [-34.956892302, -8.377737837999966],
              [-34.956837542999899, -8.378773318999956],
              [-34.956687518, -8.379628307999949],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '466',
        ID: 40553.0,
        COUNTRY: 'Brasil',
        LON: -40.849,
        LAT: -8.3005,
        ANNUAL: 5852.0,
        JAN: 6068.0,
        FEB: 5745.0,
        MAR: 5697.0,
        APR: 5302.0,
        MAY: 4988.0,
        JUN: 4903.0,
        JUL: 5275.0,
        AUG: 6199.0,
        SEP: 6695.0,
        OCT: 6666.0,
        NOV: 6493.0,
        DEC: 6186.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.81914606, -8.349116608999958],
              [-40.819563241, -8.346714307999948],
              [-40.821985583999897, -8.341209932999959],
              [-40.822676702, -8.337723159999946],
              [-40.822203827, -8.336139308999986],
              [-40.818402251999899, -8.330144197999969],
              [-40.818369601, -8.328915838999926],
              [-40.819875146, -8.325033991999931],
              [-40.819509156, -8.321908924999954],
              [-40.817896112, -8.320490169999964],
              [-40.802420814999898, -8.321939786999964],
              [-40.799045178706344, -8.321748240106183],
              [-40.799045178706344, -8.350461434175616],
              [-40.819223250866251, -8.350461434175616],
              [-40.81914606, -8.349116608999958],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '467',
        ID: 40554.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.3005,
        ANNUAL: 5786.0,
        JAN: 6012.0,
        FEB: 5745.0,
        MAR: 5712.0,
        APR: 5224.0,
        MAY: 4899.0,
        JUN: 4764.0,
        JUL: 5128.0,
        AUG: 6066.0,
        SEP: 6639.0,
        OCT: 6605.0,
        NOV: 6474.0,
        DEC: 6159.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.798260582, -8.321703718999968],
              [-40.792787744, -8.320031299999927],
              [-40.791821276, -8.31907256499994],
              [-40.791180563, -8.317323899999968],
              [-40.7911806769999, -8.313321486999948],
              [-40.786433925, -8.306440563999967],
              [-40.783704903999904, -8.303895362999972],
              [-40.780506558999903, -8.302837182999951],
              [-40.779879761, -8.301932712999935],
              [-40.779479981, -8.298441194999944],
              [-40.780089631, -8.296272901999929],
              [-40.781678385, -8.293115396999957],
              [-40.77945197, -8.290426150999965],
              [-40.778981504999898, -8.287486871999931],
              [-40.778235369999898, -8.286137819999952],
              [-40.778762291, -8.283995054999933],
              [-40.781256889, -8.280072454999972],
              [-40.781120585999901, -8.277987601999943],
              [-40.780258161999903, -8.275808814999944],
              [-40.781642884999897, -8.270837444999923],
              [-40.781788535, -8.268443441999979],
              [-40.783982743, -8.261848675999936],
              [-40.783565053, -8.255435153999942],
              [-40.78248199, -8.253303996999929],
              [-40.781126647, -8.252590439999951],
              [-40.772842700999909, -8.25100083199993],
              [-40.771610201778195, -8.250461434175616],
              [-40.699045178706342, -8.250461434175616],
              [-40.699045178706342, -8.350461434175616],
              [-40.799045178706344, -8.350461434175616],
              [-40.799045178706344, -8.321748240106183],
              [-40.798260582, -8.321703718999968],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '468',
        ID: 40555.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.3005,
        ANNUAL: 5696.0,
        JAN: 5914.0,
        FEB: 5708.0,
        MAR: 5700.0,
        APR: 5146.0,
        MAY: 4805.0,
        JUN: 4614.0,
        JUL: 4955.0,
        AUG: 5911.0,
        SEP: 6530.0,
        OCT: 6498.0,
        NOV: 6422.0,
        DEC: 6152.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.699045178706342, -8.350461434175616],
              [-40.699045178706342, -8.250461434175616],
              [-40.599045178706341, -8.250461434175616],
              [-40.599045178706341, -8.350461434175616],
              [-40.699045178706342, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '469',
        ID: 40556.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.3005,
        ANNUAL: 5661.0,
        JAN: 5968.0,
        FEB: 5636.0,
        MAR: 5679.0,
        APR: 5150.0,
        MAY: 4751.0,
        JUN: 4570.0,
        JUL: 4898.0,
        AUG: 5801.0,
        SEP: 6475.0,
        OCT: 6437.0,
        NOV: 6413.0,
        DEC: 6159.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.350461434175616],
              [-40.599045178706341, -8.250461434175616],
              [-40.499045178706339, -8.250461434175616],
              [-40.499045178706339, -8.350461434175616],
              [-40.599045178706341, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '470',
        ID: 40557.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.3005,
        ANNUAL: 5622.0,
        JAN: 5967.0,
        FEB: 5628.0,
        MAR: 5720.0,
        APR: 5134.0,
        MAY: 4692.0,
        JUN: 4504.0,
        JUL: 4822.0,
        AUG: 5700.0,
        SEP: 6391.0,
        OCT: 6392.0,
        NOV: 6390.0,
        DEC: 6126.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.350461434175616],
              [-40.499045178706339, -8.250461434175616],
              [-40.399045178706338, -8.250461434175616],
              [-40.399045178706338, -8.350461434175616],
              [-40.499045178706339, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '471',
        ID: 40558.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.3005,
        ANNUAL: 5602.0,
        JAN: 5965.0,
        FEB: 5659.0,
        MAR: 5745.0,
        APR: 5141.0,
        MAY: 4639.0,
        JUN: 4466.0,
        JUL: 4779.0,
        AUG: 5626.0,
        SEP: 6330.0,
        OCT: 6361.0,
        NOV: 6378.0,
        DEC: 6127.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.350461434175616],
              [-40.399045178706338, -8.250461434175616],
              [-40.299045178706336, -8.250461434175616],
              [-40.299045178706336, -8.350461434175616],
              [-40.399045178706338, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '472',
        ID: 40559.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.3005,
        ANNUAL: 5601.0,
        JAN: 5929.0,
        FEB: 5682.0,
        MAR: 5732.0,
        APR: 5151.0,
        MAY: 4660.0,
        JUN: 4443.0,
        JUL: 4742.0,
        AUG: 5592.0,
        SEP: 6350.0,
        OCT: 6395.0,
        NOV: 6419.0,
        DEC: 6112.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.350461434175616],
              [-40.299045178706336, -8.250461434175616],
              [-40.199045178706335, -8.250461434175616],
              [-40.199045178706335, -8.350461434175616],
              [-40.299045178706336, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '473',
        ID: 40560.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.3005,
        ANNUAL: 5623.0,
        JAN: 5994.0,
        FEB: 5749.0,
        MAR: 5765.0,
        APR: 5180.0,
        MAY: 4670.0,
        JUN: 4428.0,
        JUL: 4718.0,
        AUG: 5568.0,
        SEP: 6340.0,
        OCT: 6431.0,
        NOV: 6463.0,
        DEC: 6175.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.350461434175616],
              [-40.199045178706335, -8.250461434175616],
              [-40.099045178706334, -8.250461434175616],
              [-40.099045178706334, -8.350461434175616],
              [-40.199045178706335, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '474',
        ID: 40561.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.3005,
        ANNUAL: 5634.0,
        JAN: 6030.0,
        FEB: 5807.0,
        MAR: 5813.0,
        APR: 5182.0,
        MAY: 4684.0,
        JUN: 4405.0,
        JUL: 4686.0,
        AUG: 5530.0,
        SEP: 6319.0,
        OCT: 6450.0,
        NOV: 6491.0,
        DEC: 6216.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.350461434175616],
              [-40.099045178706334, -8.250461434175616],
              [-39.999045178706332, -8.250461434175616],
              [-39.999045178706332, -8.350461434175616],
              [-40.099045178706334, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '475',
        ID: 40562.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.3005,
        ANNUAL: 5662.0,
        JAN: 6098.0,
        FEB: 5790.0,
        MAR: 5847.0,
        APR: 5233.0,
        MAY: 4697.0,
        JUN: 4429.0,
        JUL: 4690.0,
        AUG: 5565.0,
        SEP: 6305.0,
        OCT: 6489.0,
        NOV: 6548.0,
        DEC: 6258.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.350461434175616],
              [-39.999045178706332, -8.250461434175616],
              [-39.899045178706331, -8.250461434175616],
              [-39.899045178706331, -8.350461434175616],
              [-39.999045178706332, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '476',
        ID: 40563.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.3005,
        ANNUAL: 5658.0,
        JAN: 6069.0,
        FEB: 5798.0,
        MAR: 5846.0,
        APR: 5237.0,
        MAY: 4708.0,
        JUN: 4418.0,
        JUL: 4675.0,
        AUG: 5544.0,
        SEP: 6299.0,
        OCT: 6464.0,
        NOV: 6540.0,
        DEC: 6295.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.350461434175616],
              [-39.899045178706331, -8.250461434175616],
              [-39.799045178706329, -8.250461434175616],
              [-39.799045178706329, -8.350461434175616],
              [-39.899045178706331, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '477',
        ID: 40564.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.3005,
        ANNUAL: 5641.0,
        JAN: 6011.0,
        FEB: 5765.0,
        MAR: 5821.0,
        APR: 5242.0,
        MAY: 4717.0,
        JUN: 4409.0,
        JUL: 4643.0,
        AUG: 5507.0,
        SEP: 6322.0,
        OCT: 6427.0,
        NOV: 6532.0,
        DEC: 6298.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.350461434175616],
              [-39.799045178706329, -8.250461434175616],
              [-39.699045178706328, -8.250461434175616],
              [-39.699045178706328, -8.350461434175616],
              [-39.799045178706329, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '478',
        ID: 40565.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.3005,
        ANNUAL: 5660.0,
        JAN: 6034.0,
        FEB: 5813.0,
        MAR: 5881.0,
        APR: 5352.0,
        MAY: 4716.0,
        JUN: 4374.0,
        JUL: 4651.0,
        AUG: 5504.0,
        SEP: 6335.0,
        OCT: 6440.0,
        NOV: 6577.0,
        DEC: 6240.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -8.350461434175616],
              [-39.699045178706328, -8.250461434175616],
              [-39.599045178706326, -8.250461434175616],
              [-39.599045178706326, -8.350461434175616],
              [-39.699045178706328, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '479',
        ID: 40566.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.3005,
        ANNUAL: 5677.0,
        JAN: 6092.0,
        FEB: 5826.0,
        MAR: 5897.0,
        APR: 5361.0,
        MAY: 4739.0,
        JUN: 4387.0,
        JUL: 4633.0,
        AUG: 5475.0,
        SEP: 6356.0,
        OCT: 6467.0,
        NOV: 6620.0,
        DEC: 6266.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -8.350461434175616],
              [-39.599045178706326, -8.250461434175616],
              [-39.499045178706325, -8.250461434175616],
              [-39.499045178706325, -8.350461434175616],
              [-39.599045178706326, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '480',
        ID: 40567.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -8.3005,
        ANNUAL: 5681.0,
        JAN: 6123.0,
        FEB: 5850.0,
        MAR: 5920.0,
        APR: 5374.0,
        MAY: 4752.0,
        JUN: 4368.0,
        JUL: 4616.0,
        AUG: 5429.0,
        SEP: 6338.0,
        OCT: 6450.0,
        NOV: 6645.0,
        DEC: 6304.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -8.350461434175616],
              [-39.499045178706325, -8.250461434175616],
              [-39.399045178706324, -8.250461434175616],
              [-39.399045178706324, -8.350461434175616],
              [-39.499045178706325, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '481',
        ID: 40568.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -8.3005,
        ANNUAL: 5698.0,
        JAN: 6113.0,
        FEB: 5888.0,
        MAR: 5917.0,
        APR: 5411.0,
        MAY: 4785.0,
        JUN: 4398.0,
        JUL: 4645.0,
        AUG: 5421.0,
        SEP: 6357.0,
        OCT: 6491.0,
        NOV: 6647.0,
        DEC: 6307.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.399045178706324, -8.350461434175616],
              [-39.399045178706324, -8.250461434175616],
              [-39.299045178706322, -8.250461434175616],
              [-39.299045178706322, -8.350461434175616],
              [-39.399045178706324, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '482',
        ID: 40569.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.3005,
        ANNUAL: 5685.0,
        JAN: 6093.0,
        FEB: 5862.0,
        MAR: 5881.0,
        APR: 5425.0,
        MAY: 4777.0,
        JUN: 4376.0,
        JUL: 4645.0,
        AUG: 5426.0,
        SEP: 6352.0,
        OCT: 6473.0,
        NOV: 6629.0,
        DEC: 6279.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -8.350461434175616],
              [-39.299045178706322, -8.250461434175616],
              [-39.199045178706321, -8.250461434175616],
              [-39.199045178706321, -8.350461434175616],
              [-39.299045178706322, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '483',
        ID: 40570.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.3005,
        ANNUAL: 5711.0,
        JAN: 6158.0,
        FEB: 5939.0,
        MAR: 5907.0,
        APR: 5455.0,
        MAY: 4779.0,
        JUN: 4366.0,
        JUL: 4699.0,
        AUG: 5471.0,
        SEP: 6357.0,
        OCT: 6471.0,
        NOV: 6643.0,
        DEC: 6285.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -8.350461434175616],
              [-39.199045178706321, -8.250461434175616],
              [-39.099045178706319, -8.250461434175616],
              [-39.099045178706319, -8.350461434175616],
              [-39.199045178706321, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '484',
        ID: 40571.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.3005,
        ANNUAL: 5707.0,
        JAN: 6138.0,
        FEB: 5945.0,
        MAR: 5915.0,
        APR: 5466.0,
        MAY: 4782.0,
        JUN: 4362.0,
        JUL: 4711.0,
        AUG: 5450.0,
        SEP: 6358.0,
        OCT: 6473.0,
        NOV: 6641.0,
        DEC: 6244.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.099045178706319, -8.350461434175616],
              [-39.099045178706319, -8.250461434175616],
              [-38.999045178706318, -8.250461434175616],
              [-38.999045178706318, -8.350461434175616],
              [-39.099045178706319, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '485',
        ID: 40572.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.3005,
        ANNUAL: 5736.0,
        JAN: 6163.0,
        FEB: 6000.0,
        MAR: 5979.0,
        APR: 5495.0,
        MAY: 4810.0,
        JUN: 4375.0,
        JUL: 4712.0,
        AUG: 5443.0,
        SEP: 6385.0,
        OCT: 6485.0,
        NOV: 6681.0,
        DEC: 6305.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.350461434175616],
              [-38.999045178706318, -8.250461434175616],
              [-38.899045178706317, -8.250461434175616],
              [-38.899045178706317, -8.350461434175616],
              [-38.999045178706318, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '486',
        ID: 40573.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.3005,
        ANNUAL: 5731.0,
        JAN: 6156.0,
        FEB: 6037.0,
        MAR: 6009.0,
        APR: 5507.0,
        MAY: 4794.0,
        JUN: 4319.0,
        JUL: 4612.0,
        AUG: 5403.0,
        SEP: 6398.0,
        OCT: 6499.0,
        NOV: 6703.0,
        DEC: 6338.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.350461434175616],
              [-38.899045178706317, -8.250461434175616],
              [-38.799045178706315, -8.250461434175616],
              [-38.799045178706315, -8.350461434175616],
              [-38.899045178706317, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '487',
        ID: 40574.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.3005,
        ANNUAL: 5745.0,
        JAN: 6192.0,
        FEB: 6081.0,
        MAR: 6055.0,
        APR: 5548.0,
        MAY: 4795.0,
        JUN: 4317.0,
        JUL: 4581.0,
        AUG: 5383.0,
        SEP: 6374.0,
        OCT: 6499.0,
        NOV: 6692.0,
        DEC: 6418.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.350461434175616],
              [-38.799045178706315, -8.250461434175616],
              [-38.699045178706314, -8.250461434175616],
              [-38.699045178706314, -8.350461434175616],
              [-38.799045178706315, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '488',
        ID: 40575.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.3005,
        ANNUAL: 5722.0,
        JAN: 6162.0,
        FEB: 6060.0,
        MAR: 6072.0,
        APR: 5536.0,
        MAY: 4749.0,
        JUN: 4300.0,
        JUL: 4552.0,
        AUG: 5343.0,
        SEP: 6322.0,
        OCT: 6478.0,
        NOV: 6666.0,
        DEC: 6424.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.350461434175616],
              [-38.699045178706314, -8.250461434175616],
              [-38.599045178706312, -8.250461434175616],
              [-38.599045178706312, -8.350461434175616],
              [-38.699045178706314, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '489',
        ID: 40576.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.3005,
        ANNUAL: 5750.0,
        JAN: 6263.0,
        FEB: 6090.0,
        MAR: 6129.0,
        APR: 5581.0,
        MAY: 4769.0,
        JUN: 4283.0,
        JUL: 4545.0,
        AUG: 5357.0,
        SEP: 6341.0,
        OCT: 6516.0,
        NOV: 6683.0,
        DEC: 6444.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.350461434175616],
              [-38.599045178706312, -8.250461434175616],
              [-38.499045178706311, -8.250461434175616],
              [-38.499045178706311, -8.350461434175616],
              [-38.599045178706312, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '490',
        ID: 40577.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.3005,
        ANNUAL: 5762.0,
        JAN: 6310.0,
        FEB: 6050.0,
        MAR: 6148.0,
        APR: 5597.0,
        MAY: 4785.0,
        JUN: 4285.0,
        JUL: 4565.0,
        AUG: 5363.0,
        SEP: 6357.0,
        OCT: 6529.0,
        NOV: 6705.0,
        DEC: 6447.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.350461434175616],
              [-38.499045178706311, -8.250461434175616],
              [-38.399045178706309, -8.250461434175616],
              [-38.399045178706309, -8.350461434175616],
              [-38.499045178706311, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '491',
        ID: 40578.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.3005,
        ANNUAL: 5759.0,
        JAN: 6301.0,
        FEB: 6051.0,
        MAR: 6127.0,
        APR: 5605.0,
        MAY: 4782.0,
        JUN: 4280.0,
        JUL: 4571.0,
        AUG: 5353.0,
        SEP: 6343.0,
        OCT: 6539.0,
        NOV: 6714.0,
        DEC: 6447.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.350461434175616],
              [-38.399045178706309, -8.250461434175616],
              [-38.299045178706308, -8.250461434175616],
              [-38.299045178706308, -8.350461434175616],
              [-38.399045178706309, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '492',
        ID: 40579.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.3005,
        ANNUAL: 5752.0,
        JAN: 6261.0,
        FEB: 6055.0,
        MAR: 6101.0,
        APR: 5633.0,
        MAY: 4784.0,
        JUN: 4280.0,
        JUL: 4552.0,
        AUG: 5359.0,
        SEP: 6327.0,
        OCT: 6503.0,
        NOV: 6730.0,
        DEC: 6437.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.350461434175616],
              [-38.299045178706308, -8.250461434175616],
              [-38.199045178706307, -8.250461434175616],
              [-38.199045178706307, -8.350461434175616],
              [-38.299045178706308, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '493',
        ID: 40580.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.3005,
        ANNUAL: 5762.0,
        JAN: 6326.0,
        FEB: 6107.0,
        MAR: 6115.0,
        APR: 5636.0,
        MAY: 4761.0,
        JUN: 4274.0,
        JUL: 4558.0,
        AUG: 5323.0,
        SEP: 6311.0,
        OCT: 6506.0,
        NOV: 6749.0,
        DEC: 6475.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.350461434175616],
              [-38.199045178706307, -8.250461434175616],
              [-38.099045178706305, -8.250461434175616],
              [-38.099045178706305, -8.350461434175616],
              [-38.199045178706307, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '494',
        ID: 40581.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.3005,
        ANNUAL: 5752.0,
        JAN: 6355.0,
        FEB: 6137.0,
        MAR: 6128.0,
        APR: 5639.0,
        MAY: 4728.0,
        JUN: 4233.0,
        JUL: 4516.0,
        AUG: 5273.0,
        SEP: 6238.0,
        OCT: 6494.0,
        NOV: 6766.0,
        DEC: 6517.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.350461434175616],
              [-38.099045178706305, -8.250461434175616],
              [-37.999045178706304, -8.250461434175616],
              [-37.999045178706304, -8.350461434175616],
              [-38.099045178706305, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '495',
        ID: 40582.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.3005,
        ANNUAL: 5768.0,
        JAN: 6285.0,
        FEB: 6164.0,
        MAR: 6149.0,
        APR: 5701.0,
        MAY: 4791.0,
        JUN: 4249.0,
        JUL: 4520.0,
        AUG: 5294.0,
        SEP: 6257.0,
        OCT: 6512.0,
        NOV: 6794.0,
        DEC: 6497.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.350461434175616],
              [-37.999045178706304, -8.250461434175616],
              [-37.899045178706302, -8.250461434175616],
              [-37.899045178706302, -8.350461434175616],
              [-37.999045178706304, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '496',
        ID: 40583.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.3005,
        ANNUAL: 5773.0,
        JAN: 6282.0,
        FEB: 6198.0,
        MAR: 6172.0,
        APR: 5714.0,
        MAY: 4796.0,
        JUN: 4262.0,
        JUL: 4500.0,
        AUG: 5305.0,
        SEP: 6253.0,
        OCT: 6498.0,
        NOV: 6790.0,
        DEC: 6508.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.350461434175616],
              [-37.899045178706302, -8.250461434175616],
              [-37.799045178706301, -8.250461434175616],
              [-37.799045178706301, -8.350461434175616],
              [-37.899045178706302, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '497',
        ID: 40584.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.3005,
        ANNUAL: 5783.0,
        JAN: 6314.0,
        FEB: 6247.0,
        MAR: 6163.0,
        APR: 5709.0,
        MAY: 4826.0,
        JUN: 4279.0,
        JUL: 4505.0,
        AUG: 5305.0,
        SEP: 6249.0,
        OCT: 6507.0,
        NOV: 6793.0,
        DEC: 6505.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.350461434175616],
              [-37.799045178706301, -8.250461434175616],
              [-37.699045178706299, -8.250461434175616],
              [-37.699045178706299, -8.350461434175616],
              [-37.799045178706301, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '498',
        ID: 40585.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.3005,
        ANNUAL: 5737.0,
        JAN: 6278.0,
        FEB: 6194.0,
        MAR: 6172.0,
        APR: 5652.0,
        MAY: 4825.0,
        JUN: 4201.0,
        JUL: 4426.0,
        AUG: 5240.0,
        SEP: 6186.0,
        OCT: 6460.0,
        NOV: 6740.0,
        DEC: 6470.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.350461434175616],
              [-37.699045178706299, -8.250461434175616],
              [-37.599045178706298, -8.250461434175616],
              [-37.599045178706298, -8.350461434175616],
              [-37.699045178706299, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '499',
        ID: 40586.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.3005,
        ANNUAL: 5722.0,
        JAN: 6252.0,
        FEB: 6184.0,
        MAR: 6134.0,
        APR: 5639.0,
        MAY: 4803.0,
        JUN: 4187.0,
        JUL: 4410.0,
        AUG: 5223.0,
        SEP: 6176.0,
        OCT: 6433.0,
        NOV: 6762.0,
        DEC: 6468.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.350461434175616],
              [-37.599045178706298, -8.250461434175616],
              [-37.499045178706297, -8.250461434175616],
              [-37.499045178706297, -8.350461434175616],
              [-37.599045178706298, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '500',
        ID: 40587.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.3005,
        ANNUAL: 5732.0,
        JAN: 6286.0,
        FEB: 6151.0,
        MAR: 6089.0,
        APR: 5647.0,
        MAY: 4802.0,
        JUN: 4223.0,
        JUL: 4442.0,
        AUG: 5247.0,
        SEP: 6199.0,
        OCT: 6469.0,
        NOV: 6777.0,
        DEC: 6448.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.350461434175616],
              [-37.499045178706297, -8.250461434175616],
              [-37.399045178706295, -8.250461434175616],
              [-37.399045178706295, -8.350461434175616],
              [-37.499045178706297, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '501',
        ID: 40588.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.3005,
        ANNUAL: 5729.0,
        JAN: 6274.0,
        FEB: 6136.0,
        MAR: 6068.0,
        APR: 5641.0,
        MAY: 4797.0,
        JUN: 4261.0,
        JUL: 4467.0,
        AUG: 5242.0,
        SEP: 6190.0,
        OCT: 6458.0,
        NOV: 6775.0,
        DEC: 6444.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.350461434175616],
              [-37.399045178706295, -8.250461434175616],
              [-37.299045178706294, -8.250461434175616],
              [-37.299045178706294, -8.350461434175616],
              [-37.399045178706295, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '502',
        ID: 40589.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.3005,
        ANNUAL: 5723.0,
        JAN: 6257.0,
        FEB: 6119.0,
        MAR: 6065.0,
        APR: 5630.0,
        MAY: 4792.0,
        JUN: 4280.0,
        JUL: 4481.0,
        AUG: 5269.0,
        SEP: 6182.0,
        OCT: 6438.0,
        NOV: 6731.0,
        DEC: 6433.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.350461434175616],
              [-37.299045178706294, -8.250461434175616],
              [-37.199045178706292, -8.250461434175616],
              [-37.199045178706292, -8.350461434175616],
              [-37.299045178706294, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '503',
        ID: 40590.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.3005,
        ANNUAL: 5688.0,
        JAN: 6165.0,
        FEB: 6046.0,
        MAR: 6017.0,
        APR: 5607.0,
        MAY: 4761.0,
        JUN: 4298.0,
        JUL: 4493.0,
        AUG: 5266.0,
        SEP: 6139.0,
        OCT: 6376.0,
        NOV: 6675.0,
        DEC: 6415.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.199045178706292, -8.350461434175616],
              [-37.199045178706292, -8.250461434175616],
              [-37.099045178706291, -8.250461434175616],
              [-37.099045178706291, -8.350461434175616],
              [-37.199045178706292, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '504',
        ID: 40591.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.3005,
        ANNUAL: 5648.0,
        JAN: 6111.0,
        FEB: 6017.0,
        MAR: 6025.0,
        APR: 5532.0,
        MAY: 4701.0,
        JUN: 4243.0,
        JUL: 4405.0,
        AUG: 5217.0,
        SEP: 6124.0,
        OCT: 6363.0,
        NOV: 6642.0,
        DEC: 6390.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.0822268269999, -8.261887405999975],
              [-37.081203367999898, -8.26637351399995],
              [-37.081201576, -8.266374046999932],
              [-37.078780765, -8.267093177999929],
              [-37.075724319999907, -8.268001029999946],
              [-37.058231315999905, -8.263984309999959],
              [-37.056249507, -8.264633565999928],
              [-37.054977477, -8.265050292999945],
              [-37.049252839, -8.265277509999985],
              [-37.043333748, -8.264559861999938],
              [-37.0402836109999, -8.266179553999969],
              [-37.040112854, -8.266385152999923],
              [-37.031832650999903, -8.276311652999937],
              [-37.027857627999907, -8.283405300999956],
              [-37.023037013, -8.288589573999962],
              [-37.020648289999897, -8.289672194999984],
              [-37.020646731999904, -8.28967290099996],
              [-37.020637891, -8.289676907999922],
              [-37.015343717, -8.292076340999927],
              [-37.015208042, -8.292137831999989],
              [-37.01480409499991, -8.292320909999939],
              [-37.0119701039999, -8.294030194999944],
              [-37.003927693, -8.295932452999981],
              [-36.99904517870629, -8.298801475258946],
              [-36.99904517870629, -8.350461434175616],
              [-37.099045178706291, -8.350461434175616],
              [-37.099045178706291, -8.250461434175616],
              [-37.08172058437291, -8.250461434175616],
              [-37.0822268269999, -8.261887405999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '505',
        ID: 40592.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.3005,
        ANNUAL: 5641.0,
        JAN: 6123.0,
        FEB: 6007.0,
        MAR: 6038.0,
        APR: 5548.0,
        MAY: 4710.0,
        JUN: 4240.0,
        JUL: 4391.0,
        AUG: 5212.0,
        SEP: 6071.0,
        OCT: 6343.0,
        NOV: 6617.0,
        DEC: 6394.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.997637478999906, -8.299628655999973],
              [-36.991156283999899, -8.302955050999966],
              [-36.991153966999903, -8.302954731999934],
              [-36.990164142, -8.302818641999981],
              [-36.987544774, -8.302458505999935],
              [-36.986379806999899, -8.302029106999946],
              [-36.985170309999901, -8.301583294999936],
              [-36.981912034999901, -8.299123782999965],
              [-36.978018053, -8.297416240999956],
              [-36.97519843, -8.297401906999935],
              [-36.974971315999902, -8.297400751999925],
              [-36.960201717, -8.300467313999956],
              [-36.9573771579999, -8.300003829999977],
              [-36.955641463999903, -8.299542105999931],
              [-36.954922884, -8.299350951999942],
              [-36.954836369, -8.299327936999932],
              [-36.9546292859999, -8.299272849999969],
              [-36.954544719999902, -8.299210984999943],
              [-36.951388140999903, -8.296901852999953],
              [-36.951305370999897, -8.297109108999955],
              [-36.95130406, -8.297107770999959],
              [-36.949255818, -8.295015871999958],
              [-36.948535451999902, -8.293927547999946],
              [-36.947308714, -8.291064242999935],
              [-36.947404624999898, -8.287652497999938],
              [-36.947577222999897, -8.287304907999943],
              [-36.948385363999897, -8.283647440999973],
              [-36.947726488999898, -8.279119996999951],
              [-36.947025456999903, -8.277368303999932],
              [-36.945720074999898, -8.276317686999954],
              [-36.944946801999897, -8.275253296999942],
              [-36.942163415, -8.273890765999974],
              [-36.933043962999903, -8.273265262999937],
              [-36.922998816, -8.269829800999965],
              [-36.917095332, -8.264824757999964],
              [-36.913627516, -8.260928747999962],
              [-36.908634815, -8.253451441999974],
              [-36.908277261, -8.253106420999924],
              [-36.908263467, -8.253093110999941],
              [-36.9081718219999, -8.253004677999968],
              [-36.905536192723055, -8.250461434175616],
              [-36.899045178706288, -8.250461434175616],
              [-36.899045178706288, -8.350461434175616],
              [-36.99904517870629, -8.350461434175616],
              [-36.99904517870629, -8.298801475258946],
              [-36.997637478999906, -8.299628655999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '506',
        ID: 40593.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.3005,
        ANNUAL: 5623.0,
        JAN: 6125.0,
        FEB: 5968.0,
        MAR: 6012.0,
        APR: 5528.0,
        MAY: 4688.0,
        JUN: 4203.0,
        JUL: 4345.0,
        AUG: 5178.0,
        SEP: 6052.0,
        OCT: 6337.0,
        NOV: 6648.0,
        DEC: 6395.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.899045178706288, -8.350461434175616],
              [-36.899045178706288, -8.250461434175616],
              [-36.799045178706287, -8.250461434175616],
              [-36.799045178706287, -8.350461434175616],
              [-36.899045178706288, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '507',
        ID: 40594.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.3005,
        ANNUAL: 5567.0,
        JAN: 6201.0,
        FEB: 6038.0,
        MAR: 6006.0,
        APR: 5493.0,
        MAY: 4603.0,
        JUN: 4022.0,
        JUL: 4198.0,
        AUG: 4992.0,
        SEP: 5962.0,
        OCT: 6299.0,
        NOV: 6609.0,
        DEC: 6387.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.799045178706287, -8.350461434175616],
              [-36.799045178706287, -8.250461434175616],
              [-36.699045178706285, -8.250461434175616],
              [-36.699045178706285, -8.350461434175616],
              [-36.799045178706287, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '508',
        ID: 40595.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.3005,
        ANNUAL: 5417.0,
        JAN: 6081.0,
        FEB: 5910.0,
        MAR: 5899.0,
        APR: 5368.0,
        MAY: 4455.0,
        JUN: 3887.0,
        JUL: 4025.0,
        AUG: 4820.0,
        SEP: 5741.0,
        OCT: 6091.0,
        NOV: 6456.0,
        DEC: 6278.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.699045178706285, -8.350461434175616],
              [-36.699045178706285, -8.250461434175616],
              [-36.599045178706284, -8.250461434175616],
              [-36.599045178706284, -8.350461434175616],
              [-36.699045178706285, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '509',
        ID: 40596.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.3005,
        ANNUAL: 5380.0,
        JAN: 5980.0,
        FEB: 5849.0,
        MAR: 5891.0,
        APR: 5340.0,
        MAY: 4417.0,
        JUN: 3901.0,
        JUL: 4050.0,
        AUG: 4810.0,
        SEP: 5719.0,
        OCT: 6042.0,
        NOV: 6366.0,
        DEC: 6198.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.350461434175616],
              [-36.599045178706284, -8.250461434175616],
              [-36.499045178706282, -8.250461434175616],
              [-36.499045178706282, -8.350461434175616],
              [-36.599045178706284, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '510',
        ID: 40597.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.3005,
        ANNUAL: 5326.0,
        JAN: 6046.0,
        FEB: 5830.0,
        MAR: 5805.0,
        APR: 5262.0,
        MAY: 4341.0,
        JUN: 3826.0,
        JUL: 3974.0,
        AUG: 4663.0,
        SEP: 5610.0,
        OCT: 5972.0,
        NOV: 6382.0,
        DEC: 6206.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.350461434175616],
              [-36.499045178706282, -8.250461434175616],
              [-36.399045178706281, -8.250461434175616],
              [-36.399045178706281, -8.350461434175616],
              [-36.499045178706282, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '511',
        ID: 40598.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.3005,
        ANNUAL: 5229.0,
        JAN: 5933.0,
        FEB: 5725.0,
        MAR: 5713.0,
        APR: 5197.0,
        MAY: 4290.0,
        JUN: 3806.0,
        JUL: 3924.0,
        AUG: 4605.0,
        SEP: 5449.0,
        OCT: 5797.0,
        NOV: 6233.0,
        DEC: 6076.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.350461434175616],
              [-36.399045178706281, -8.250461434175616],
              [-36.29904517870628, -8.250461434175616],
              [-36.29904517870628, -8.350461434175616],
              [-36.399045178706281, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '512',
        ID: 40599.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.3005,
        ANNUAL: 5267.0,
        JAN: 5829.0,
        FEB: 5671.0,
        MAR: 5730.0,
        APR: 5223.0,
        MAY: 4443.0,
        JUN: 3988.0,
        JUL: 4062.0,
        AUG: 4794.0,
        SEP: 5517.0,
        OCT: 5800.0,
        NOV: 6169.0,
        DEC: 5985.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.350461434175616],
              [-36.29904517870628, -8.250461434175616],
              [-36.199045178706278, -8.250461434175616],
              [-36.199045178706278, -8.350461434175616],
              [-36.29904517870628, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '513',
        ID: 40600.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.3005,
        ANNUAL: 5263.0,
        JAN: 5830.0,
        FEB: 5689.0,
        MAR: 5735.0,
        APR: 5196.0,
        MAY: 4408.0,
        JUN: 3997.0,
        JUL: 4072.0,
        AUG: 4815.0,
        SEP: 5503.0,
        OCT: 5793.0,
        NOV: 6164.0,
        DEC: 5954.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.350461434175616],
              [-36.199045178706278, -8.250461434175616],
              [-36.099045178706277, -8.250461434175616],
              [-36.099045178706277, -8.350461434175616],
              [-36.199045178706278, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '514',
        ID: 40601.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.3005,
        ANNUAL: 5279.0,
        JAN: 5863.0,
        FEB: 5741.0,
        MAR: 5762.0,
        APR: 5214.0,
        MAY: 4416.0,
        JUN: 4007.0,
        JUL: 4054.0,
        AUG: 4816.0,
        SEP: 5549.0,
        OCT: 5829.0,
        NOV: 6143.0,
        DEC: 5957.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.350461434175616],
              [-36.099045178706277, -8.250461434175616],
              [-35.999045178706275, -8.250461434175616],
              [-35.999045178706275, -8.350461434175616],
              [-36.099045178706277, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '515',
        ID: 40602.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.3005,
        ANNUAL: 5255.0,
        JAN: 5726.0,
        FEB: 5773.0,
        MAR: 5703.0,
        APR: 5172.0,
        MAY: 4439.0,
        JUN: 4007.0,
        JUL: 4130.0,
        AUG: 4809.0,
        SEP: 5502.0,
        OCT: 5769.0,
        NOV: 6100.0,
        DEC: 5934.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.350461434175616],
              [-35.999045178706275, -8.250461434175616],
              [-35.899045178706274, -8.250461434175616],
              [-35.899045178706274, -8.350461434175616],
              [-35.999045178706275, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '516',
        ID: 40603.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.3005,
        ANNUAL: 5216.0,
        JAN: 5723.0,
        FEB: 5731.0,
        MAR: 5705.0,
        APR: 5100.0,
        MAY: 4373.0,
        JUN: 3924.0,
        JUL: 4072.0,
        AUG: 4734.0,
        SEP: 5450.0,
        OCT: 5764.0,
        NOV: 6112.0,
        DEC: 5906.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.350461434175616],
              [-35.899045178706274, -8.250461434175616],
              [-35.799045178706272, -8.250461434175616],
              [-35.799045178706272, -8.350461434175616],
              [-35.899045178706274, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '517',
        ID: 40604.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.3005,
        ANNUAL: 5212.0,
        JAN: 5748.0,
        FEB: 5725.0,
        MAR: 5767.0,
        APR: 5075.0,
        MAY: 4340.0,
        JUN: 3922.0,
        JUL: 4027.0,
        AUG: 4709.0,
        SEP: 5433.0,
        OCT: 5761.0,
        NOV: 6099.0,
        DEC: 5935.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.350461434175616],
              [-35.799045178706272, -8.250461434175616],
              [-35.699045178706271, -8.250461434175616],
              [-35.699045178706271, -8.350461434175616],
              [-35.799045178706272, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '518',
        ID: 40605.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.3005,
        ANNUAL: 5212.0,
        JAN: 5735.0,
        FEB: 5749.0,
        MAR: 5737.0,
        APR: 5080.0,
        MAY: 4350.0,
        JUN: 3961.0,
        JUL: 4015.0,
        AUG: 4684.0,
        SEP: 5419.0,
        OCT: 5722.0,
        NOV: 6109.0,
        DEC: 5986.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.350461434175616],
              [-35.699045178706271, -8.250461434175616],
              [-35.59904517870627, -8.250461434175616],
              [-35.59904517870627, -8.350461434175616],
              [-35.699045178706271, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '519',
        ID: 40606.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.3005,
        ANNUAL: 5116.0,
        JAN: 5646.0,
        FEB: 5675.0,
        MAR: 5690.0,
        APR: 4999.0,
        MAY: 4267.0,
        JUN: 3901.0,
        JUL: 3901.0,
        AUG: 4553.0,
        SEP: 5287.0,
        OCT: 5593.0,
        NOV: 5958.0,
        DEC: 5925.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.350461434175616],
              [-35.59904517870627, -8.250461434175616],
              [-35.499045178706268, -8.250461434175616],
              [-35.499045178706268, -8.350461434175616],
              [-35.59904517870627, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '520',
        ID: 40607.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.3005,
        ANNUAL: 5045.0,
        JAN: 5585.0,
        FEB: 5615.0,
        MAR: 5615.0,
        APR: 4941.0,
        MAY: 4225.0,
        JUN: 3848.0,
        JUL: 3843.0,
        AUG: 4474.0,
        SEP: 5209.0,
        OCT: 5497.0,
        NOV: 5838.0,
        DEC: 5855.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.350461434175616],
              [-35.499045178706268, -8.250461434175616],
              [-35.399045178706267, -8.250461434175616],
              [-35.399045178706267, -8.350461434175616],
              [-35.499045178706268, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '521',
        ID: 40608.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.3005,
        ANNUAL: 5002.0,
        JAN: 5485.0,
        FEB: 5556.0,
        MAR: 5565.0,
        APR: 4882.0,
        MAY: 4191.0,
        JUN: 3830.0,
        JUL: 3889.0,
        AUG: 4472.0,
        SEP: 5182.0,
        OCT: 5432.0,
        NOV: 5746.0,
        DEC: 5794.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.350461434175616],
              [-35.399045178706267, -8.250461434175616],
              [-35.299045178706265, -8.250461434175616],
              [-35.299045178706265, -8.350461434175616],
              [-35.399045178706267, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '522',
        ID: 40609.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.3005,
        ANNUAL: 5095.0,
        JAN: 5539.0,
        FEB: 5637.0,
        MAR: 5668.0,
        APR: 4970.0,
        MAY: 4237.0,
        JUN: 3944.0,
        JUL: 3990.0,
        AUG: 4636.0,
        SEP: 5292.0,
        OCT: 5550.0,
        NOV: 5819.0,
        DEC: 5852.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.350461434175616],
              [-35.299045178706265, -8.250461434175616],
              [-35.199045178706264, -8.250461434175616],
              [-35.199045178706264, -8.350461434175616],
              [-35.299045178706265, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '523',
        ID: 40610.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.3005,
        ANNUAL: 5186.0,
        JAN: 5606.0,
        FEB: 5737.0,
        MAR: 5763.0,
        APR: 5024.0,
        MAY: 4292.0,
        JUN: 4020.0,
        JUL: 4085.0,
        AUG: 4766.0,
        SEP: 5402.0,
        OCT: 5663.0,
        NOV: 5925.0,
        DEC: 5952.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.350461434175616],
              [-35.199045178706264, -8.250461434175616],
              [-35.099045178706263, -8.250461434175616],
              [-35.099045178706263, -8.350461434175616],
              [-35.199045178706264, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '524',
        ID: 40611.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.3005,
        ANNUAL: 5252.0,
        JAN: 5693.0,
        FEB: 5811.0,
        MAR: 5811.0,
        APR: 5096.0,
        MAY: 4378.0,
        JUN: 4088.0,
        JUL: 4171.0,
        AUG: 4849.0,
        SEP: 5419.0,
        OCT: 5729.0,
        NOV: 5969.0,
        DEC: 6015.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -8.350461434175616],
              [-35.099045178706263, -8.250461434175616],
              [-34.999045178706261, -8.250461434175616],
              [-34.999045178706261, -8.350461434175616],
              [-35.099045178706263, -8.350461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '525',
        ID: 40612.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -8.3005,
        ANNUAL: 5507.0,
        JAN: 5977.0,
        FEB: 6100.0,
        MAR: 6055.0,
        APR: 5257.0,
        MAY: 4560.0,
        JUN: 4274.0,
        JUL: 4353.0,
        AUG: 5120.0,
        SEP: 5721.0,
        OCT: 6052.0,
        NOV: 6305.0,
        DEC: 6318.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.94078766, -8.252148906999926],
              [-34.941682589, -8.254022748999942],
              [-34.942551764, -8.255941989999931],
              [-34.943165908, -8.257416060999956],
              [-34.943862024999902, -8.259082100999928],
              [-34.944177802, -8.260031111999977],
              [-34.944307256999906, -8.260420166999943],
              [-34.944381677, -8.260643822999954],
              [-34.944665402999902, -8.261600622999937],
              [-34.94489787, -8.262384566999978],
              [-34.945250675999901, -8.263820739999961],
              [-34.945355537, -8.265430279999984],
              [-34.945199214, -8.266484964999961],
              [-34.945003755, -8.267551320999928],
              [-34.9449043, -8.268328118999936],
              [-34.944941333, -8.268906726999942],
              [-34.945326802, -8.270674366999971],
              [-34.945498471999898, -8.272432301999963],
              [-34.945788149999899, -8.273588951999947],
              [-34.946170009999904, -8.275160773999964],
              [-34.946482422, -8.277030558999968],
              [-34.946756888, -8.278872347999942],
              [-34.946979606, -8.279604845999934],
              [-34.947754897, -8.281285729999983],
              [-34.947755555999898, -8.281287157999941],
              [-34.9484429789999, -8.283002763999942],
              [-34.949488529, -8.284448503999954],
              [-34.950334332, -8.286199863999975],
              [-34.950932201999898, -8.288541871999939],
              [-34.951533614, -8.291218066999928],
              [-34.951652657999901, -8.292038032999933],
              [-34.951845096999897, -8.293363545999973],
              [-34.951890729999903, -8.293677860999946],
              [-34.951946630999899, -8.295669474999952],
              [-34.951994164, -8.296246371999926],
              [-34.952113532999903, -8.297695129999976],
              [-34.952102023, -8.297871832999933],
              [-34.952046073, -8.298176302999929],
              [-34.951942674, -8.298388688999978],
              [-34.951881757, -8.298497221999973],
              [-34.951838407, -8.298574458999957],
              [-34.951753068999899, -8.298656246999945],
              [-34.951621981, -8.298630972999932],
              [-34.951460316, -8.298534329999942],
              [-34.951332947, -8.298509462999956],
              [-34.951178302, -8.298595261999944],
              [-34.950968116, -8.298875805999966],
              [-34.950749641999899, -8.299197103999974],
              [-34.950547158999903, -8.299406530999923],
              [-34.950486213, -8.299446835999962],
              [-34.950308995999904, -8.299564029999939],
              [-34.950168071, -8.299840566999933],
              [-34.9501091, -8.299959014999954],
              [-34.949967585, -8.300243255999934],
              [-34.9497892649999, -8.300618003999947],
              [-34.949582356, -8.300868597999976],
              [-34.949307901999902, -8.30093033599996],
              [-34.949113266, -8.300996752999945],
              [-34.94882616, -8.301084290999935],
              [-34.948548457999898, -8.301175964999965],
              [-34.948360582, -8.301250668999955],
              [-34.948203625, -8.301393006999939],
              [-34.948198627, -8.301403832999934],
              [-34.948015454, -8.301611528999965],
              [-34.947735576, -8.301793826999933],
              [-34.947472965, -8.301923235999938],
              [-34.947379213, -8.302011715999978],
              [-34.947293821999899, -8.302199508999934],
              [-34.9472930109999, -8.302206993999965],
              [-34.9471677189999, -8.302409484999934],
              [-34.947154339, -8.302426992999937],
              [-34.946977622, -8.302574804999947],
              [-34.946690846, -8.302714722999955],
              [-34.946434350999901, -8.302857855999948],
              [-34.946247260999897, -8.303031057999927],
              [-34.946007095, -8.303270553999933],
              [-34.9458676369999, -8.303347090999981],
              [-34.945787461999899, -8.30339109499994],
              [-34.945669868, -8.303432787999958],
              [-34.945367599, -8.303362865999938],
              [-34.945105812, -8.303342672999969],
              [-34.944733658, -8.303352419999957],
              [-34.944472306, -8.303328478999953],
              [-34.944241077, -8.303273665999944],
              [-34.943985664, -8.303265496999932],
              [-34.943848024, -8.303300106999925],
              [-34.943622322, -8.303370792999937],
              [-34.943410945, -8.30334457699996],
              [-34.943274258, -8.303299791999967],
              [-34.943057270999901, -8.303244894999979],
              [-34.943019440999898, -8.303346875999981],
              [-34.943044819, -8.303500989999964],
              [-34.942861514999898, -8.303674596999937],
              [-34.942673632, -8.303749289999926],
              [-34.942515463999896, -8.30379685299993],
              [-34.942330266999903, -8.304023246999984],
              [-34.94218978, -8.304190036999966],
              [-34.942185187, -8.304197120999968],
              [-34.942141542999899, -8.304423397999937],
              [-34.942176113999899, -8.304563339999957],
              [-34.9422597189999, -8.304674416999951],
              [-34.942477052, -8.304716401999944],
              [-34.942646037, -8.304680549999931],
              [-34.943172972, -8.30524708899997],
              [-34.943807159999899, -8.306571291999944],
              [-34.944261522, -8.307520013999977],
              [-34.944622071, -8.308352104999926],
              [-34.945240770999902, -8.309779969999967],
              [-34.945318339, -8.309958986999954],
              [-34.946261315999905, -8.312141997999957],
              [-34.946533469, -8.312772040999958],
              [-34.946783201, -8.313350175999965],
              [-34.947610542999897, -8.315683726999952],
              [-34.947746072, -8.316065988999982],
              [-34.948128009999898, -8.317019694999935],
              [-34.948176363, -8.317147193999972],
              [-34.948297219, -8.317465868999937],
              [-34.948534152, -8.318090621999943],
              [-34.94870791200001, -8.319372883999957],
              [-34.949060626999902, -8.320373882999949],
              [-34.949388579999898, -8.320887411999928],
              [-34.9493992129999, -8.320892217999926],
              [-34.949803225, -8.321511959999953],
              [-34.949906274999897, -8.321670035999931],
              [-34.949995771, -8.322052437999954],
              [-34.950066395, -8.322354201999932],
              [-34.9501065339999, -8.323596709999956],
              [-34.949985990999899, -8.324400616999974],
              [-34.949928192, -8.324968249999927],
              [-34.949968338, -8.325764522999973],
              [-34.950240741999899, -8.327368963999968],
              [-34.950363212, -8.328126043999923],
              [-34.950501562999897, -8.329319857999966],
              [-34.950417444, -8.330336423999944],
              [-34.950315272999902, -8.331348293999955],
              [-34.950180155999902, -8.332595826999981],
              [-34.950190226, -8.333395129999932],
              [-34.950215243999899, -8.334422762999953],
              [-34.950223654, -8.334768194999988],
              [-34.950257555, -8.335827620999954],
              [-34.95025415, -8.336785258999946],
              [-34.950161722, -8.337570048999961],
              [-34.950058379, -8.338172143999937],
              [-34.95001086, -8.338448998999981],
              [-34.949864283, -8.339080956999954],
              [-34.949701972999897, -8.339652364999948],
              [-34.949395044, -8.340605922999936],
              [-34.949216873999902, -8.341186694999989],
              [-34.949169722, -8.34127553899998],
              [-34.949048664, -8.341503638999935],
              [-34.948827428999898, -8.341819792999964],
              [-34.948614313999897, -8.342036320999965],
              [-34.948388982, -8.342262584999958],
              [-34.948173518, -8.342397918999952],
              [-34.947918248999898, -8.342488553999933],
              [-34.947712576, -8.342484019999965],
              [-34.94769939, -8.342483728999982],
              [-34.947572337999901, -8.342457293999928],
              [-34.947544778, -8.342402870999923],
              [-34.947509497999903, -8.342248303999959],
              [-34.947462287, -8.342066715999977],
              [-34.947403382999902, -8.341924348999953],
              [-34.947320696, -8.341864071999955],
              [-34.947218569, -8.341845879999934],
              [-34.947144797, -8.341909059999981],
              [-34.947037389999899, -8.342008037999967],
              [-34.946956233, -8.342139231999973],
              [-34.946873883, -8.34228133899995],
              [-34.946809914, -8.342425390999948],
              [-34.946805667, -8.342601524999957],
              [-34.946806946, -8.34272673699996],
              [-34.946767774, -8.342847660999956],
              [-34.94669507, -8.342832587999952],
              [-34.946643111, -8.342797643999971],
              [-34.946595733, -8.342788937999961],
              [-34.946539933999901, -8.342823483999945],
              [-34.946500928, -8.342874526999941],
              [-34.946429011, -8.342852180999959],
              [-34.946378909, -8.342765928999942],
              [-34.946334438999898, -8.342661880999973],
              [-34.946273166, -8.342541331999939],
              [-34.946189213, -8.342432051999934],
              [-34.946071761, -8.342349680999977],
              [-34.946011289, -8.342221846999951],
              [-34.945937144, -8.342151428999946],
              [-34.945845135999903, -8.342108547999938],
              [-34.945709656, -8.342123603999946],
              [-34.945642157999899, -8.342197387999931],
              [-34.945552995999897, -8.342368202999973],
              [-34.945483794, -8.342526415999936],
              [-34.945419306, -8.342743993999926],
              [-34.945391291, -8.343002230999964],
              [-34.945397538, -8.343253054999934],
              [-34.945381265, -8.343368853999959],
              [-34.945362316999905, -8.343543441999934],
              [-34.945334421, -8.343731799999942],
              [-34.945271336, -8.344004701999948],
              [-34.945270052, -8.344221616999958],
              [-34.945263849, -8.344449032999933],
              [-34.945254339, -8.344536301999939],
              [-34.945193586999899, -8.344548245999922],
              [-34.945060445, -8.344508370999966],
              [-34.944915873999904, -8.34447095999997],
              [-34.944777563999899, -8.34447835399993],
              [-34.944661375, -8.344521206999962],
              [-34.944545809, -8.344548264999959],
              [-34.944484432, -8.344525968999969],
              [-34.944463788, -8.344485440999961],
              [-34.944483819, -8.34440637499994],
              [-34.944518772, -8.344295050999953],
              [-34.944542218, -8.344184746999922],
              [-34.944556136, -8.344077939999989],
              [-34.944495453999899, -8.344048945999928],
              [-34.94440313, -8.344059451999957],
              [-34.944324305, -8.344134552999956],
              [-34.944265239999901, -8.344216267999979],
              [-34.944258979, -8.344294555999975],
              [-34.944254197, -8.344359457999936],
              [-34.944239052999897, -8.344393937999934],
              [-34.944202280999903, -8.344390692999973],
              [-34.944132382, -8.344407764999962],
              [-34.944119025, -8.34447369399993],
              [-34.944130978, -8.344591283999929],
              [-34.944133991999898, -8.344677311999932],
              [-34.944085453999897, -8.34478235799997],
              [-34.94390365, -8.344861021999975],
              [-34.943864436, -8.344866134999965],
              [-34.943696534, -8.344888026999973],
              [-34.943472199, -8.344845867999936],
              [-34.94331907, -8.344774528999956],
              [-34.943268867, -8.344610033999976],
              [-34.943253845999898, -8.344406412999943],
              [-34.943252578999903, -8.344247099999968],
              [-34.943256371, -8.343984235999937],
              [-34.943239166, -8.343743628999952],
              [-34.943045163, -8.343649579999969],
              [-34.943020691999898, -8.34364698499996],
              [-34.94284397, -8.343450679999922],
              [-34.942654321, -8.343430564999958],
              [-34.9424976459999, -8.34350579099994],
              [-34.942407116, -8.343826817999975],
              [-34.942250252999898, -8.343846909999977],
              [-34.942063458999897, -8.343857702999971],
              [-34.941953113, -8.343962322999968],
              [-34.9418169919999, -8.344076448999941],
              [-34.941722526, -8.344148831999977],
              [-34.941583108, -8.344293255999967],
              [-34.941475599999897, -8.344428797999974],
              [-34.941301258, -8.344495998999944],
              [-34.94126017100001, -8.344589611999931],
              [-34.941169935999902, -8.344622897999953],
              [-34.940981393, -8.344706952999957],
              [-34.940829103999903, -8.344856093999976],
              [-34.940703468, -8.345044787999949],
              [-34.940611911, -8.345261585999944],
              [-34.9406106, -8.345273690999925],
              [-34.940553979, -8.34573910199992],
              [-34.940539321, -8.345988559999963],
              [-34.940538009, -8.346000665999954],
              [-34.940499326, -8.346186347999957],
              [-34.940498014, -8.346198452999944],
              [-34.940401650999902, -8.346402503999977],
              [-34.940383714999903, -8.346682220999972],
              [-34.940379719, -8.34677619299994],
              [-34.940398711999897, -8.346943529999969],
              [-34.940454191999898, -8.347059650999947],
              [-34.940434511, -8.347241251999947],
              [-34.940307124, -8.347503216999931],
              [-34.940143244999902, -8.347816426999946],
              [-34.939981331, -8.348111443999919],
              [-34.939766324999901, -8.348382489999942],
              [-34.939613802999901, -8.348647925999956],
              [-34.939564620999903, -8.348930451999927],
              [-34.939515007, -8.349102732999947],
              [-34.939470851, -8.349167528999935],
              [-34.939422552999901, -8.349327704999945],
              [-34.939421239999902, -8.349339810999934],
              [-34.939425151, -8.349646347999961],
              [-34.939481068, -8.349872690999966],
              [-34.939491991999901, -8.349886094999929],
              [-34.939685777, -8.350096461999955],
              [-34.93994685, -8.350313968999965],
              [-34.939909614615424, -8.350461434175616],
              [-34.999045178706261, -8.350461434175616],
              [-34.999045178706261, -8.250461434175616],
              [-34.93993234616989, -8.250461434175616],
              [-34.94078766, -8.252148906999926],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '526',
        ID: 40942.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -8.2005,
        ANNUAL: 5834.0,
        JAN: 5992.0,
        FEB: 5727.0,
        MAR: 5782.0,
        APR: 5338.0,
        MAY: 5006.0,
        JUN: 4869.0,
        JUL: 5210.0,
        AUG: 6109.0,
        SEP: 6633.0,
        OCT: 6612.0,
        NOV: 6515.0,
        DEC: 6211.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.769318621, -8.249458533999926],
              [-40.76683844, -8.247984210999963],
              [-40.764292686, -8.243993790999983],
              [-40.764011431, -8.24379809099997],
              [-40.763730176, -8.243602390999973],
              [-40.763167666, -8.24321099099997],
              [-40.75961309, -8.243054345999942],
              [-40.755703561, -8.241713914999929],
              [-40.75480292, -8.241741919999987],
              [-40.752095395, -8.241826108999939],
              [-40.74969391, -8.240443409999957],
              [-40.744504746, -8.240071892999937],
              [-40.743207196, -8.239021780999964],
              [-40.742397097, -8.234948257999973],
              [-40.739343674999901, -8.232357392999971],
              [-40.738558982, -8.230321675999962],
              [-40.736868910999902, -8.228232169999954],
              [-40.733683049, -8.227756860999923],
              [-40.731088269999901, -8.226560204999938],
              [-40.729197942, -8.224914689999931],
              [-40.724593125, -8.223436571999963],
              [-40.722726221, -8.223245179999935],
              [-40.718091611, -8.22479169499996],
              [-40.714706658999901, -8.224457966999973],
              [-40.711061098, -8.225061465999948],
              [-40.707255784, -8.223493552999974],
              [-40.70557516, -8.22070297199997],
              [-40.705488833999901, -8.220559656999969],
              [-40.705471893, -8.220531531999937],
              [-40.703423294999894, -8.217129741999949],
              [-40.703642381, -8.208598099999959],
              [-40.701895283999903, -8.204198997999983],
              [-40.700587981, -8.202607696999964],
              [-40.699045178706342, -8.20217782803422],
              [-40.699045178706342, -8.250461434175616],
              [-40.771610201778195, -8.250461434175616],
              [-40.769318621, -8.249458533999926],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '527',
        ID: 40943.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.2005,
        ANNUAL: 5771.0,
        JAN: 5929.0,
        FEB: 5709.0,
        MAR: 5733.0,
        APR: 5260.0,
        MAY: 4899.0,
        JUN: 4749.0,
        JUL: 5083.0,
        AUG: 6043.0,
        SEP: 6635.0,
        OCT: 6571.0,
        NOV: 6467.0,
        DEC: 6178.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.694373853999899, -8.200876262999946],
              [-40.692396113, -8.196745747999962],
              [-40.691203927999901, -8.195456070999962],
              [-40.688624108999903, -8.19425221899996],
              [-40.686818676, -8.192274885999954],
              [-40.684412065999901, -8.187776500999966],
              [-40.677261081, -8.186045137999931],
              [-40.675256304, -8.184861602999934],
              [-40.673003341, -8.180815113999932],
              [-40.670252233, -8.179255174999978],
              [-40.669029257, -8.177480883999976],
              [-40.667813067999901, -8.175344672999925],
              [-40.667253261, -8.164449811999924],
              [-40.666684245999903, -8.162350233999963],
              [-40.666645962, -8.162209033999932],
              [-40.666478203999901, -8.16158942799996],
              [-40.658505714999897, -8.156556256999977],
              [-40.652840811, -8.157694424999926],
              [-40.6494825259999, -8.156856231999951],
              [-40.645199176999903, -8.158083524999947],
              [-40.643566118, -8.157536811999933],
              [-40.642703572999899, -8.155038618999978],
              [-40.642774482346738, -8.150461434175616],
              [-40.599045178706341, -8.150461434175616],
              [-40.599045178706341, -8.250461434175616],
              [-40.699045178706342, -8.250461434175616],
              [-40.699045178706342, -8.20217782803422],
              [-40.694373853999899, -8.200876262999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '528',
        ID: 40944.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.2005,
        ANNUAL: 5657.0,
        JAN: 5881.0,
        FEB: 5636.0,
        MAR: 5655.0,
        APR: 5108.0,
        MAY: 4738.0,
        JUN: 4578.0,
        JUL: 4933.0,
        AUG: 5824.0,
        SEP: 6506.0,
        OCT: 6463.0,
        NOV: 6409.0,
        DEC: 6155.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -8.250461434175616],
              [-40.599045178706341, -8.150461434175616],
              [-40.499045178706339, -8.150461434175616],
              [-40.499045178706339, -8.250461434175616],
              [-40.599045178706341, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '529',
        ID: 40945.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.2005,
        ANNUAL: 5607.0,
        JAN: 5870.0,
        FEB: 5581.0,
        MAR: 5643.0,
        APR: 5092.0,
        MAY: 4685.0,
        JUN: 4512.0,
        JUL: 4853.0,
        AUG: 5738.0,
        SEP: 6424.0,
        OCT: 6416.0,
        NOV: 6360.0,
        DEC: 6110.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.250461434175616],
              [-40.499045178706339, -8.150461434175616],
              [-40.399045178706338, -8.150461434175616],
              [-40.399045178706338, -8.250461434175616],
              [-40.499045178706339, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '530',
        ID: 40946.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.2005,
        ANNUAL: 5591.0,
        JAN: 5870.0,
        FEB: 5596.0,
        MAR: 5666.0,
        APR: 5101.0,
        MAY: 4664.0,
        JUN: 4457.0,
        JUL: 4827.0,
        AUG: 5694.0,
        SEP: 6378.0,
        OCT: 6382.0,
        NOV: 6361.0,
        DEC: 6103.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.250461434175616],
              [-40.399045178706338, -8.150461434175616],
              [-40.299045178706336, -8.150461434175616],
              [-40.299045178706336, -8.250461434175616],
              [-40.399045178706338, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '531',
        ID: 40947.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.2005,
        ANNUAL: 5570.0,
        JAN: 5811.0,
        FEB: 5581.0,
        MAR: 5645.0,
        APR: 5075.0,
        MAY: 4662.0,
        JUN: 4437.0,
        JUL: 4697.0,
        AUG: 5658.0,
        SEP: 6360.0,
        OCT: 6376.0,
        NOV: 6415.0,
        DEC: 6128.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.250461434175616],
              [-40.299045178706336, -8.150461434175616],
              [-40.199045178706335, -8.150461434175616],
              [-40.199045178706335, -8.250461434175616],
              [-40.299045178706336, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '532',
        ID: 40948.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.2005,
        ANNUAL: 5589.0,
        JAN: 5907.0,
        FEB: 5671.0,
        MAR: 5709.0,
        APR: 5136.0,
        MAY: 4651.0,
        JUN: 4397.0,
        JUL: 4669.0,
        AUG: 5583.0,
        SEP: 6307.0,
        OCT: 6404.0,
        NOV: 6446.0,
        DEC: 6191.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.250461434175616],
              [-40.199045178706335, -8.150461434175616],
              [-40.099045178706334, -8.150461434175616],
              [-40.099045178706334, -8.250461434175616],
              [-40.199045178706335, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '533',
        ID: 40949.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.2005,
        ANNUAL: 5630.0,
        JAN: 5999.0,
        FEB: 5786.0,
        MAR: 5805.0,
        APR: 5193.0,
        MAY: 4665.0,
        JUN: 4363.0,
        JUL: 4686.0,
        AUG: 5539.0,
        SEP: 6291.0,
        OCT: 6457.0,
        NOV: 6499.0,
        DEC: 6277.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.250461434175616],
              [-40.099045178706334, -8.150461434175616],
              [-39.999045178706332, -8.150461434175616],
              [-39.999045178706332, -8.250461434175616],
              [-40.099045178706334, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '534',
        ID: 40950.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.2005,
        ANNUAL: 5672.0,
        JAN: 6044.0,
        FEB: 5786.0,
        MAR: 5840.0,
        APR: 5301.0,
        MAY: 4745.0,
        JUN: 4451.0,
        JUL: 4674.0,
        AUG: 5603.0,
        SEP: 6314.0,
        OCT: 6477.0,
        NOV: 6536.0,
        DEC: 6293.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.250461434175616],
              [-39.999045178706332, -8.150461434175616],
              [-39.899045178706331, -8.150461434175616],
              [-39.899045178706331, -8.250461434175616],
              [-39.999045178706332, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '535',
        ID: 40951.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.2005,
        ANNUAL: 5611.0,
        JAN: 5956.0,
        FEB: 5712.0,
        MAR: 5783.0,
        APR: 5212.0,
        MAY: 4684.0,
        JUN: 4379.0,
        JUL: 4622.0,
        AUG: 5550.0,
        SEP: 6287.0,
        OCT: 6433.0,
        NOV: 6498.0,
        DEC: 6216.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.250461434175616],
              [-39.899045178706331, -8.150461434175616],
              [-39.799045178706329, -8.150461434175616],
              [-39.799045178706329, -8.250461434175616],
              [-39.899045178706331, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '536',
        ID: 40952.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.2005,
        ANNUAL: 5614.0,
        JAN: 5934.0,
        FEB: 5740.0,
        MAR: 5801.0,
        APR: 5194.0,
        MAY: 4681.0,
        JUN: 4362.0,
        JUL: 4604.0,
        AUG: 5516.0,
        SEP: 6310.0,
        OCT: 6443.0,
        NOV: 6525.0,
        DEC: 6252.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.250461434175616],
              [-39.799045178706329, -8.150461434175616],
              [-39.699045178706328, -8.150461434175616],
              [-39.699045178706328, -8.250461434175616],
              [-39.799045178706329, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '537',
        ID: 40953.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.2005,
        ANNUAL: 5654.0,
        JAN: 5992.0,
        FEB: 5840.0,
        MAR: 5842.0,
        APR: 5318.0,
        MAY: 4707.0,
        JUN: 4359.0,
        JUL: 4660.0,
        AUG: 5495.0,
        SEP: 6342.0,
        OCT: 6448.0,
        NOV: 6553.0,
        DEC: 6295.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -8.250461434175616],
              [-39.699045178706328, -8.150461434175616],
              [-39.599045178706326, -8.150461434175616],
              [-39.599045178706326, -8.250461434175616],
              [-39.699045178706328, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '538',
        ID: 40954.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.2005,
        ANNUAL: 5672.0,
        JAN: 6047.0,
        FEB: 5856.0,
        MAR: 5867.0,
        APR: 5384.0,
        MAY: 4749.0,
        JUN: 4357.0,
        JUL: 4665.0,
        AUG: 5483.0,
        SEP: 6349.0,
        OCT: 6440.0,
        NOV: 6558.0,
        DEC: 6303.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -8.250461434175616],
              [-39.599045178706326, -8.150461434175616],
              [-39.499045178706325, -8.150461434175616],
              [-39.499045178706325, -8.250461434175616],
              [-39.599045178706326, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '539',
        ID: 40955.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -8.2005,
        ANNUAL: 5711.0,
        JAN: 6121.0,
        FEB: 5888.0,
        MAR: 5916.0,
        APR: 5428.0,
        MAY: 4786.0,
        JUN: 4404.0,
        JUL: 4715.0,
        AUG: 5484.0,
        SEP: 6383.0,
        OCT: 6486.0,
        NOV: 6590.0,
        DEC: 6329.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -8.250461434175616],
              [-39.499045178706325, -8.150461434175616],
              [-39.399045178706324, -8.150461434175616],
              [-39.399045178706324, -8.250461434175616],
              [-39.499045178706325, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '540',
        ID: 40956.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -8.2005,
        ANNUAL: 5723.0,
        JAN: 6179.0,
        FEB: 5919.0,
        MAR: 5870.0,
        APR: 5442.0,
        MAY: 4807.0,
        JUN: 4409.0,
        JUL: 4718.0,
        AUG: 5469.0,
        SEP: 6404.0,
        OCT: 6501.0,
        NOV: 6623.0,
        DEC: 6340.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.399045178706324, -8.250461434175616],
              [-39.399045178706324, -8.150461434175616],
              [-39.299045178706322, -8.150461434175616],
              [-39.299045178706322, -8.250461434175616],
              [-39.399045178706324, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '541',
        ID: 40957.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.2005,
        ANNUAL: 5712.0,
        JAN: 6168.0,
        FEB: 5900.0,
        MAR: 5817.0,
        APR: 5468.0,
        MAY: 4812.0,
        JUN: 4422.0,
        JUL: 4735.0,
        AUG: 5481.0,
        SEP: 6400.0,
        OCT: 6440.0,
        NOV: 6627.0,
        DEC: 6274.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -8.250461434175616],
              [-39.299045178706322, -8.150461434175616],
              [-39.199045178706321, -8.150461434175616],
              [-39.199045178706321, -8.250461434175616],
              [-39.299045178706322, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '542',
        ID: 40958.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.2005,
        ANNUAL: 5707.0,
        JAN: 6133.0,
        FEB: 5908.0,
        MAR: 5842.0,
        APR: 5467.0,
        MAY: 4800.0,
        JUN: 4388.0,
        JUL: 4764.0,
        AUG: 5533.0,
        SEP: 6397.0,
        OCT: 6439.0,
        NOV: 6578.0,
        DEC: 6238.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -8.250461434175616],
              [-39.199045178706321, -8.150461434175616],
              [-39.099045178706319, -8.150461434175616],
              [-39.099045178706319, -8.250461434175616],
              [-39.199045178706321, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '543',
        ID: 40959.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.2005,
        ANNUAL: 5697.0,
        JAN: 6100.0,
        FEB: 5838.0,
        MAR: 5831.0,
        APR: 5449.0,
        MAY: 4810.0,
        JUN: 4443.0,
        JUL: 4792.0,
        AUG: 5516.0,
        SEP: 6384.0,
        OCT: 6432.0,
        NOV: 6572.0,
        DEC: 6190.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.099045178706319, -8.250461434175616],
              [-39.099045178706319, -8.150461434175616],
              [-38.999045178706318, -8.150461434175616],
              [-38.999045178706318, -8.250461434175616],
              [-39.099045178706319, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '544',
        ID: 40960.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.2005,
        ANNUAL: 5732.0,
        JAN: 6124.0,
        FEB: 5907.0,
        MAR: 5920.0,
        APR: 5474.0,
        MAY: 4835.0,
        JUN: 4433.0,
        JUL: 4747.0,
        AUG: 5486.0,
        SEP: 6402.0,
        OCT: 6497.0,
        NOV: 6666.0,
        DEC: 6292.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.250461434175616],
              [-38.999045178706318, -8.150461434175616],
              [-38.899045178706317, -8.150461434175616],
              [-38.899045178706317, -8.250461434175616],
              [-38.999045178706318, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '545',
        ID: 40961.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.2005,
        ANNUAL: 5780.0,
        JAN: 6207.0,
        FEB: 6052.0,
        MAR: 6035.0,
        APR: 5524.0,
        MAY: 4847.0,
        JUN: 4378.0,
        JUL: 4689.0,
        AUG: 5489.0,
        SEP: 6431.0,
        OCT: 6524.0,
        NOV: 6799.0,
        DEC: 6391.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.250461434175616],
              [-38.899045178706317, -8.150461434175616],
              [-38.799045178706315, -8.150461434175616],
              [-38.799045178706315, -8.250461434175616],
              [-38.899045178706317, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '546',
        ID: 40962.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.2005,
        ANNUAL: 5797.0,
        JAN: 6171.0,
        FEB: 6062.0,
        MAR: 6082.0,
        APR: 5604.0,
        MAY: 4857.0,
        JUN: 4414.0,
        JUL: 4715.0,
        AUG: 5511.0,
        SEP: 6425.0,
        OCT: 6531.0,
        NOV: 6758.0,
        DEC: 6439.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.250461434175616],
              [-38.799045178706315, -8.150461434175616],
              [-38.699045178706314, -8.150461434175616],
              [-38.699045178706314, -8.250461434175616],
              [-38.799045178706315, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '547',
        ID: 40963.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.2005,
        ANNUAL: 5790.0,
        JAN: 6164.0,
        FEB: 6068.0,
        MAR: 6122.0,
        APR: 5624.0,
        MAY: 4837.0,
        JUN: 4393.0,
        JUL: 4683.0,
        AUG: 5467.0,
        SEP: 6413.0,
        OCT: 6513.0,
        NOV: 6713.0,
        DEC: 6483.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.250461434175616],
              [-38.699045178706314, -8.150461434175616],
              [-38.599045178706312, -8.150461434175616],
              [-38.599045178706312, -8.250461434175616],
              [-38.699045178706314, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '548',
        ID: 40964.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.2005,
        ANNUAL: 5770.0,
        JAN: 6228.0,
        FEB: 6126.0,
        MAR: 6101.0,
        APR: 5603.0,
        MAY: 4840.0,
        JUN: 4317.0,
        JUL: 4618.0,
        AUG: 5384.0,
        SEP: 6375.0,
        OCT: 6469.0,
        NOV: 6663.0,
        DEC: 6518.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.250461434175616],
              [-38.599045178706312, -8.150461434175616],
              [-38.499045178706311, -8.150461434175616],
              [-38.499045178706311, -8.250461434175616],
              [-38.599045178706312, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '549',
        ID: 40965.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.2005,
        ANNUAL: 5745.0,
        JAN: 6213.0,
        FEB: 6068.0,
        MAR: 6071.0,
        APR: 5597.0,
        MAY: 4802.0,
        JUN: 4322.0,
        JUL: 4584.0,
        AUG: 5369.0,
        SEP: 6336.0,
        OCT: 6479.0,
        NOV: 6639.0,
        DEC: 6457.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.250461434175616],
              [-38.499045178706311, -8.150461434175616],
              [-38.399045178706309, -8.150461434175616],
              [-38.399045178706309, -8.250461434175616],
              [-38.499045178706311, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '550',
        ID: 40966.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.2005,
        ANNUAL: 5764.0,
        JAN: 6239.0,
        FEB: 6069.0,
        MAR: 6089.0,
        APR: 5600.0,
        MAY: 4803.0,
        JUN: 4335.0,
        JUL: 4587.0,
        AUG: 5381.0,
        SEP: 6361.0,
        OCT: 6544.0,
        NOV: 6711.0,
        DEC: 6447.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.250461434175616],
              [-38.399045178706309, -8.150461434175616],
              [-38.299045178706308, -8.150461434175616],
              [-38.299045178706308, -8.250461434175616],
              [-38.399045178706309, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '551',
        ID: 40967.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.2005,
        ANNUAL: 5759.0,
        JAN: 6226.0,
        FEB: 6068.0,
        MAR: 6083.0,
        APR: 5647.0,
        MAY: 4818.0,
        JUN: 4309.0,
        JUL: 4587.0,
        AUG: 5381.0,
        SEP: 6327.0,
        OCT: 6514.0,
        NOV: 6719.0,
        DEC: 6432.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.250461434175616],
              [-38.299045178706308, -8.150461434175616],
              [-38.199045178706307, -8.150461434175616],
              [-38.199045178706307, -8.250461434175616],
              [-38.299045178706308, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '552',
        ID: 40968.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.2005,
        ANNUAL: 5764.0,
        JAN: 6275.0,
        FEB: 6103.0,
        MAR: 6109.0,
        APR: 5630.0,
        MAY: 4823.0,
        JUN: 4314.0,
        JUL: 4616.0,
        AUG: 5355.0,
        SEP: 6304.0,
        OCT: 6502.0,
        NOV: 6665.0,
        DEC: 6477.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.250461434175616],
              [-38.199045178706307, -8.150461434175616],
              [-38.099045178706305, -8.150461434175616],
              [-38.099045178706305, -8.250461434175616],
              [-38.199045178706307, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '553',
        ID: 40969.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.2005,
        ANNUAL: 5746.0,
        JAN: 6305.0,
        FEB: 6110.0,
        MAR: 6107.0,
        APR: 5599.0,
        MAY: 4773.0,
        JUN: 4271.0,
        JUL: 4576.0,
        AUG: 5321.0,
        SEP: 6258.0,
        OCT: 6490.0,
        NOV: 6665.0,
        DEC: 6481.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.250461434175616],
              [-38.099045178706305, -8.150461434175616],
              [-37.999045178706304, -8.150461434175616],
              [-37.999045178706304, -8.250461434175616],
              [-38.099045178706305, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '554',
        ID: 40970.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.2005,
        ANNUAL: 5795.0,
        JAN: 6395.0,
        FEB: 6174.0,
        MAR: 6161.0,
        APR: 5690.0,
        MAY: 4825.0,
        JUN: 4314.0,
        JUL: 4536.0,
        AUG: 5355.0,
        SEP: 6283.0,
        OCT: 6525.0,
        NOV: 6778.0,
        DEC: 6503.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.250461434175616],
              [-37.999045178706304, -8.150461434175616],
              [-37.899045178706302, -8.150461434175616],
              [-37.899045178706302, -8.250461434175616],
              [-37.999045178706304, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '555',
        ID: 40971.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.2005,
        ANNUAL: 5802.0,
        JAN: 6355.0,
        FEB: 6211.0,
        MAR: 6164.0,
        APR: 5714.0,
        MAY: 4819.0,
        JUN: 4291.0,
        JUL: 4533.0,
        AUG: 5357.0,
        SEP: 6316.0,
        OCT: 6543.0,
        NOV: 6790.0,
        DEC: 6527.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.250461434175616],
              [-37.899045178706302, -8.150461434175616],
              [-37.799045178706301, -8.150461434175616],
              [-37.799045178706301, -8.250461434175616],
              [-37.899045178706302, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '556',
        ID: 40972.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.2005,
        ANNUAL: 5792.0,
        JAN: 6316.0,
        FEB: 6269.0,
        MAR: 6178.0,
        APR: 5708.0,
        MAY: 4833.0,
        JUN: 4271.0,
        JUL: 4517.0,
        AUG: 5338.0,
        SEP: 6255.0,
        OCT: 6514.0,
        NOV: 6785.0,
        DEC: 6518.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.250461434175616],
              [-37.799045178706301, -8.150461434175616],
              [-37.699045178706299, -8.150461434175616],
              [-37.699045178706299, -8.250461434175616],
              [-37.799045178706301, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '557',
        ID: 40973.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.2005,
        ANNUAL: 5758.0,
        JAN: 6258.0,
        FEB: 6242.0,
        MAR: 6207.0,
        APR: 5667.0,
        MAY: 4848.0,
        JUN: 4212.0,
        JUL: 4461.0,
        AUG: 5271.0,
        SEP: 6207.0,
        OCT: 6506.0,
        NOV: 6736.0,
        DEC: 6489.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.250461434175616],
              [-37.699045178706299, -8.150461434175616],
              [-37.599045178706298, -8.150461434175616],
              [-37.599045178706298, -8.250461434175616],
              [-37.699045178706299, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '558',
        ID: 40974.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.2005,
        ANNUAL: 5750.0,
        JAN: 6207.0,
        FEB: 6229.0,
        MAR: 6186.0,
        APR: 5683.0,
        MAY: 4837.0,
        JUN: 4221.0,
        JUL: 4470.0,
        AUG: 5273.0,
        SEP: 6209.0,
        OCT: 6449.0,
        NOV: 6757.0,
        DEC: 6483.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.250461434175616],
              [-37.599045178706298, -8.150461434175616],
              [-37.499045178706297, -8.150461434175616],
              [-37.499045178706297, -8.250461434175616],
              [-37.599045178706298, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '559',
        ID: 40975.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.2005,
        ANNUAL: 5743.0,
        JAN: 6176.0,
        FEB: 6192.0,
        MAR: 6105.0,
        APR: 5696.0,
        MAY: 4821.0,
        JUN: 4228.0,
        JUL: 4504.0,
        AUG: 5304.0,
        SEP: 6202.0,
        OCT: 6435.0,
        NOV: 6778.0,
        DEC: 6477.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.250461434175616],
              [-37.499045178706297, -8.150461434175616],
              [-37.399045178706295, -8.150461434175616],
              [-37.399045178706295, -8.250461434175616],
              [-37.499045178706297, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '560',
        ID: 40976.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.2005,
        ANNUAL: 5715.0,
        JAN: 6140.0,
        FEB: 6127.0,
        MAR: 6028.0,
        APR: 5581.0,
        MAY: 4849.0,
        JUN: 4328.0,
        JUL: 4457.0,
        AUG: 5246.0,
        SEP: 6195.0,
        OCT: 6438.0,
        NOV: 6764.0,
        DEC: 6428.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.250461434175616],
              [-37.399045178706295, -8.150461434175616],
              [-37.299045178706294, -8.150461434175616],
              [-37.299045178706294, -8.250461434175616],
              [-37.399045178706295, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '561',
        ID: 40977.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.2005,
        ANNUAL: 5698.0,
        JAN: 6141.0,
        FEB: 6067.0,
        MAR: 5996.0,
        APR: 5563.0,
        MAY: 4821.0,
        JUN: 4330.0,
        JUL: 4476.0,
        AUG: 5278.0,
        SEP: 6166.0,
        OCT: 6415.0,
        NOV: 6704.0,
        DEC: 6414.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.250461434175616],
              [-37.299045178706294, -8.150461434175616],
              [-37.199045178706292, -8.150461434175616],
              [-37.199045178706292, -8.250461434175616],
              [-37.299045178706294, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '562',
        ID: 40978.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.2005,
        ANNUAL: 5667.0,
        JAN: 6123.0,
        FEB: 5953.0,
        MAR: 5987.0,
        APR: 5515.0,
        MAY: 4763.0,
        JUN: 4277.0,
        JUL: 4447.0,
        AUG: 5246.0,
        SEP: 6134.0,
        OCT: 6397.0,
        NOV: 6732.0,
        DEC: 6428.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.157477383999897, -8.156462139999965],
              [-37.163421268, -8.158687713999933],
              [-37.163616030999897, -8.165761851999957],
              [-37.160225323, -8.170400441999957],
              [-37.149586089, -8.174940915999967],
              [-37.14119224, -8.177234077999977],
              [-37.133197434, -8.175880527999944],
              [-37.126451610999901, -8.17368400899994],
              [-37.118260061999898, -8.175351880999937],
              [-37.11866176, -8.18039028299995],
              [-37.118948753, -8.183989965999954],
              [-37.119843679, -8.184734229999943],
              [-37.124493103, -8.188600915999935],
              [-37.124806604, -8.189834547999965],
              [-37.126099785, -8.191496279999971],
              [-37.1251989639999, -8.19890629499997],
              [-37.123389892, -8.207394076999954],
              [-37.122914846, -8.209066410999984],
              [-37.118551248, -8.214976162999962],
              [-37.114490969, -8.220087315999931],
              [-37.112807812, -8.223110650999963],
              [-37.110394269, -8.22554909999997],
              [-37.108588219, -8.22663652999996],
              [-37.105815607, -8.227828134999978],
              [-37.104275559, -8.228980903999961],
              [-37.102664314, -8.231099205999952],
              [-37.100428483, -8.233706838999973],
              [-37.100427107, -8.233706254999957],
              [-37.099045178706291, -8.233119395258363],
              [-37.099045178706291, -8.250461434175616],
              [-37.199045178706292, -8.250461434175616],
              [-37.199045178706292, -8.150461434175616],
              [-37.151427293696379, -8.150461434175616],
              [-37.157477383999897, -8.156462139999965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '563',
        ID: 40979.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -8.2005,
        ANNUAL: 5664.0,
        JAN: 6195.0,
        FEB: 5983.0,
        MAR: 6043.0,
        APR: 5514.0,
        MAY: 4741.0,
        JUN: 4274.0,
        JUL: 4384.0,
        AUG: 5217.0,
        SEP: 6142.0,
        OCT: 6392.0,
        NOV: 6683.0,
        DEC: 6397.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.097226584999909, -8.232347097999925],
              [-37.089892692, -8.231165888999952],
              [-37.083006931999897, -8.222402679999959],
              [-37.080895352, -8.221891480999938],
              [-37.07133278, -8.229315878999969],
              [-37.067417651, -8.230102475999953],
              [-37.066530669, -8.232999167999942],
              [-37.074169880999897, -8.236317943999955],
              [-37.080620413, -8.241082029999964],
              [-37.0815081699999, -8.245667209999963],
              [-37.08172058437291, -8.250461434175616],
              [-37.099045178706291, -8.250461434175616],
              [-37.099045178706291, -8.233119395258363],
              [-37.097226584999909, -8.232347097999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '564',
        ID: 40980.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -8.2005,
        ANNUAL: 5655.0,
        JAN: 6155.0,
        FEB: 5963.0,
        MAR: 5996.0,
        APR: 5533.0,
        MAY: 4757.0,
        JUN: 4279.0,
        JUL: 4452.0,
        AUG: 5264.0,
        SEP: 6115.0,
        OCT: 6343.0,
        NOV: 6626.0,
        DEC: 6377.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.903358388, -8.248359966999933],
              [-36.902929448999899, -8.248096221999955],
              [-36.89999783, -8.243660804999928],
              [-36.899045178706288, -8.242992248626027],
              [-36.899045178706288, -8.250461434175616],
              [-36.905536192723055, -8.250461434175616],
              [-36.903358388, -8.248359966999933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '565',
        ID: 40981.0,
        COUNTRY: 'Brasil',
        LON: -36.849,
        LAT: -8.2005,
        ANNUAL: 5678.0,
        JAN: 6170.0,
        FEB: 5981.0,
        MAR: 6007.0,
        APR: 5534.0,
        MAY: 4742.0,
        JUN: 4306.0,
        JUL: 4527.0,
        AUG: 5327.0,
        SEP: 6171.0,
        OCT: 6378.0,
        NOV: 6624.0,
        DEC: 6374.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.895950008999897, -8.240820104999944],
              [-36.892307172, -8.239503000999944],
              [-36.890446471, -8.238830245999962],
              [-36.886284345, -8.237855633999978],
              [-36.882109656999901, -8.236878080999922],
              [-36.87922903, -8.235644729999922],
              [-36.871920229, -8.230191948999957],
              [-36.86861096, -8.227067163999951],
              [-36.86170234, -8.222426827999925],
              [-36.852286929999899, -8.219092754999954],
              [-36.843290706999902, -8.216942456999961],
              [-36.843288957, -8.216942577999928],
              [-36.843014506, -8.216961451999952],
              [-36.838602253, -8.214626699999933],
              [-36.833254838999899, -8.212545802999941],
              [-36.824349266, -8.208207653999979],
              [-36.821143355999901, -8.207412],
              [-36.818451641, -8.20698485299995],
              [-36.815992179, -8.206810695999925],
              [-36.813349850999899, -8.205659480999939],
              [-36.812511174, -8.205294084999927],
              [-36.805779072999897, -8.195444742999937],
              [-36.803355431999897, -8.193538238999965],
              [-36.799045178706287, -8.19134764364061],
              [-36.799045178706287, -8.250461434175616],
              [-36.899045178706288, -8.250461434175616],
              [-36.899045178706288, -8.242992248626027],
              [-36.895950008999897, -8.240820104999944],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '566',
        ID: 40982.0,
        COUNTRY: 'Brasil',
        LON: -36.749,
        LAT: -8.2005,
        ANNUAL: 5671.0,
        JAN: 6250.0,
        FEB: 6067.0,
        MAR: 6047.0,
        APR: 5530.0,
        MAY: 4708.0,
        JUN: 4223.0,
        JUL: 4404.0,
        AUG: 5243.0,
        SEP: 6155.0,
        OCT: 6392.0,
        NOV: 6645.0,
        DEC: 6393.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.797395290999901, -8.190509122999973],
              [-36.794346054999899, -8.18952162399995],
              [-36.7932000119999, -8.189451448999932],
              [-36.7908594949999, -8.189618161999935],
              [-36.787836655, -8.189833564999956],
              [-36.780810532, -8.190333943999971],
              [-36.775373934, -8.190721190999964],
              [-36.770831755, -8.192719633999959],
              [-36.767285423, -8.195980249999929],
              [-36.764880153, -8.196818348999955],
              [-36.764878759999903, -8.196818127999956],
              [-36.761569720999901, -8.196293867999936],
              [-36.754854192, -8.192444570999957],
              [-36.753508186, -8.191478134999949],
              [-36.750863368, -8.185694842999952],
              [-36.75014242, -8.175703988999945],
              [-36.744214294, -8.173569198999928],
              [-36.742607327, -8.172990510999966],
              [-36.740889857, -8.172372003999957],
              [-36.730695646, -8.172829359999971],
              [-36.730694066999909, -8.172829274999966],
              [-36.723971983999903, -8.17246867399996],
              [-36.722404949, -8.168333628999953],
              [-36.721298357, -8.168073583999965],
              [-36.715781319, -8.167316204999954],
              [-36.717424935999901, -8.163553346999944],
              [-36.718874711, -8.161622196999925],
              [-36.725781346, -8.156448307999939],
              [-36.72281043, -8.150964664999952],
              [-36.722807487, -8.15096577699995],
              [-36.708676839, -8.156304205999955],
              [-36.708660408, -8.156309954999939],
              [-36.707892586, -8.156578597999941],
              [-36.707802742, -8.156610031999957],
              [-36.703694040999899, -8.158047570999937],
              [-36.703692788, -8.158046946999946],
              [-36.699045178706285, -8.155731881604332],
              [-36.699045178706285, -8.250461434175616],
              [-36.799045178706287, -8.250461434175616],
              [-36.799045178706287, -8.19134764364061],
              [-36.797395290999901, -8.190509122999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '567',
        ID: 40983.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.2005,
        ANNUAL: 5492.0,
        JAN: 6134.0,
        FEB: 5968.0,
        MAR: 5910.0,
        APR: 5415.0,
        MAY: 4493.0,
        JUN: 3997.0,
        JUL: 4145.0,
        AUG: 4968.0,
        SEP: 5889.0,
        OCT: 6183.0,
        NOV: 6499.0,
        DEC: 6309.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.696975779, -8.154701072999961],
              [-36.696974388999898, -8.154701209999926],
              [-36.6870359179999, -8.155679492999976],
              [-36.687393863, -8.152284169999966],
              [-36.687433991, -8.151903523999986],
              [-36.688741764451244, -8.150461434175616],
              [-36.599045178706284, -8.150461434175616],
              [-36.599045178706284, -8.250461434175616],
              [-36.699045178706285, -8.250461434175616],
              [-36.699045178706285, -8.155731881604332],
              [-36.696975779, -8.154701072999961],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '568',
        ID: 40984.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.2005,
        ANNUAL: 5397.0,
        JAN: 6000.0,
        FEB: 5846.0,
        MAR: 5819.0,
        APR: 5366.0,
        MAY: 4433.0,
        JUN: 4001.0,
        JUL: 4124.0,
        AUG: 4843.0,
        SEP: 5743.0,
        OCT: 6022.0,
        NOV: 6374.0,
        DEC: 6196.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.250461434175616],
              [-36.599045178706284, -8.150461434175616],
              [-36.499045178706282, -8.150461434175616],
              [-36.499045178706282, -8.250461434175616],
              [-36.599045178706284, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '569',
        ID: 40985.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.2005,
        ANNUAL: 5449.0,
        JAN: 6066.0,
        FEB: 5916.0,
        MAR: 5837.0,
        APR: 5355.0,
        MAY: 4545.0,
        JUN: 4072.0,
        JUL: 4180.0,
        AUG: 4902.0,
        SEP: 5808.0,
        OCT: 6077.0,
        NOV: 6392.0,
        DEC: 6236.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.250461434175616],
              [-36.499045178706282, -8.150461434175616],
              [-36.399045178706281, -8.150461434175616],
              [-36.399045178706281, -8.250461434175616],
              [-36.499045178706282, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '570',
        ID: 40986.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.2005,
        ANNUAL: 5299.0,
        JAN: 5917.0,
        FEB: 5746.0,
        MAR: 5731.0,
        APR: 5275.0,
        MAY: 4427.0,
        JUN: 3966.0,
        JUL: 4052.0,
        AUG: 4756.0,
        SEP: 5591.0,
        OCT: 5840.0,
        NOV: 6227.0,
        DEC: 6065.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.250461434175616],
              [-36.399045178706281, -8.150461434175616],
              [-36.29904517870628, -8.150461434175616],
              [-36.29904517870628, -8.250461434175616],
              [-36.399045178706281, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '571',
        ID: 40987.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.2005,
        ANNUAL: 5273.0,
        JAN: 5860.0,
        FEB: 5639.0,
        MAR: 5647.0,
        APR: 5209.0,
        MAY: 4422.0,
        JUN: 4015.0,
        JUL: 4164.0,
        AUG: 4856.0,
        SEP: 5521.0,
        OCT: 5819.0,
        NOV: 6134.0,
        DEC: 5991.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.250461434175616],
              [-36.29904517870628, -8.150461434175616],
              [-36.199045178706278, -8.150461434175616],
              [-36.199045178706278, -8.250461434175616],
              [-36.29904517870628, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '572',
        ID: 40988.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.2005,
        ANNUAL: 5303.0,
        JAN: 5876.0,
        FEB: 5761.0,
        MAR: 5738.0,
        APR: 5245.0,
        MAY: 4429.0,
        JUN: 4078.0,
        JUL: 4181.0,
        AUG: 4877.0,
        SEP: 5559.0,
        OCT: 5831.0,
        NOV: 6107.0,
        DEC: 5956.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.250461434175616],
              [-36.199045178706278, -8.150461434175616],
              [-36.099045178706277, -8.150461434175616],
              [-36.099045178706277, -8.250461434175616],
              [-36.199045178706278, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '573',
        ID: 40989.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.2005,
        ANNUAL: 5260.0,
        JAN: 5785.0,
        FEB: 5762.0,
        MAR: 5700.0,
        APR: 5212.0,
        MAY: 4454.0,
        JUN: 4083.0,
        JUL: 4166.0,
        AUG: 4862.0,
        SEP: 5503.0,
        OCT: 5732.0,
        NOV: 6023.0,
        DEC: 5837.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.250461434175616],
              [-36.099045178706277, -8.150461434175616],
              [-35.999045178706275, -8.150461434175616],
              [-35.999045178706275, -8.250461434175616],
              [-36.099045178706277, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '574',
        ID: 40990.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.2005,
        ANNUAL: 5239.0,
        JAN: 5707.0,
        FEB: 5694.0,
        MAR: 5675.0,
        APR: 5196.0,
        MAY: 4452.0,
        JUN: 4022.0,
        JUL: 4148.0,
        AUG: 4828.0,
        SEP: 5461.0,
        OCT: 5739.0,
        NOV: 6054.0,
        DEC: 5894.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.250461434175616],
              [-35.999045178706275, -8.150461434175616],
              [-35.899045178706274, -8.150461434175616],
              [-35.899045178706274, -8.250461434175616],
              [-35.999045178706275, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '575',
        ID: 40991.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.2005,
        ANNUAL: 5191.0,
        JAN: 5640.0,
        FEB: 5663.0,
        MAR: 5650.0,
        APR: 5117.0,
        MAY: 4387.0,
        JUN: 3973.0,
        JUL: 4110.0,
        AUG: 4739.0,
        SEP: 5422.0,
        OCT: 5723.0,
        NOV: 6015.0,
        DEC: 5859.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.250461434175616],
              [-35.899045178706274, -8.150461434175616],
              [-35.799045178706272, -8.150461434175616],
              [-35.799045178706272, -8.250461434175616],
              [-35.899045178706274, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '576',
        ID: 40992.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.2005,
        ANNUAL: 5161.0,
        JAN: 5637.0,
        FEB: 5641.0,
        MAR: 5641.0,
        APR: 5025.0,
        MAY: 4331.0,
        JUN: 3931.0,
        JUL: 4042.0,
        AUG: 4699.0,
        SEP: 5401.0,
        OCT: 5705.0,
        NOV: 6009.0,
        DEC: 5863.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.250461434175616],
              [-35.799045178706272, -8.150461434175616],
              [-35.699045178706271, -8.150461434175616],
              [-35.699045178706271, -8.250461434175616],
              [-35.799045178706272, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '577',
        ID: 40993.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.2005,
        ANNUAL: 5198.0,
        JAN: 5691.0,
        FEB: 5681.0,
        MAR: 5688.0,
        APR: 5055.0,
        MAY: 4421.0,
        JUN: 4048.0,
        JUL: 4140.0,
        AUG: 4858.0,
        SEP: 5432.0,
        OCT: 5660.0,
        NOV: 5944.0,
        DEC: 5761.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.250461434175616],
              [-35.699045178706271, -8.150461434175616],
              [-35.59904517870627, -8.150461434175616],
              [-35.59904517870627, -8.250461434175616],
              [-35.699045178706271, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '578',
        ID: 40994.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.2005,
        ANNUAL: 5242.0,
        JAN: 5752.0,
        FEB: 5707.0,
        MAR: 5708.0,
        APR: 5089.0,
        MAY: 4435.0,
        JUN: 4126.0,
        JUL: 4178.0,
        AUG: 4934.0,
        SEP: 5505.0,
        OCT: 5711.0,
        NOV: 5922.0,
        DEC: 5838.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.250461434175616],
              [-35.59904517870627, -8.150461434175616],
              [-35.499045178706268, -8.150461434175616],
              [-35.499045178706268, -8.250461434175616],
              [-35.59904517870627, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '579',
        ID: 40995.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.2005,
        ANNUAL: 5247.0,
        JAN: 5769.0,
        FEB: 5726.0,
        MAR: 5721.0,
        APR: 5125.0,
        MAY: 4422.0,
        JUN: 4119.0,
        JUL: 4157.0,
        AUG: 4916.0,
        SEP: 5490.0,
        OCT: 5680.0,
        NOV: 5939.0,
        DEC: 5897.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.250461434175616],
              [-35.499045178706268, -8.150461434175616],
              [-35.399045178706267, -8.150461434175616],
              [-35.399045178706267, -8.250461434175616],
              [-35.499045178706268, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '580',
        ID: 40996.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.2005,
        ANNUAL: 5159.0,
        JAN: 5587.0,
        FEB: 5677.0,
        MAR: 5626.0,
        APR: 5008.0,
        MAY: 4344.0,
        JUN: 3989.0,
        JUL: 4091.0,
        AUG: 4724.0,
        SEP: 5404.0,
        OCT: 5661.0,
        NOV: 5882.0,
        DEC: 5913.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.250461434175616],
              [-35.399045178706267, -8.150461434175616],
              [-35.299045178706265, -8.150461434175616],
              [-35.299045178706265, -8.250461434175616],
              [-35.399045178706267, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '581',
        ID: 40997.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.2005,
        ANNUAL: 5136.0,
        JAN: 5504.0,
        FEB: 5661.0,
        MAR: 5673.0,
        APR: 4996.0,
        MAY: 4309.0,
        JUN: 3987.0,
        JUL: 4092.0,
        AUG: 4728.0,
        SEP: 5357.0,
        OCT: 5610.0,
        NOV: 5806.0,
        DEC: 5912.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.250461434175616],
              [-35.299045178706265, -8.150461434175616],
              [-35.199045178706264, -8.150461434175616],
              [-35.199045178706264, -8.250461434175616],
              [-35.299045178706265, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '582',
        ID: 40998.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.2005,
        ANNUAL: 5096.0,
        JAN: 5411.0,
        FEB: 5603.0,
        MAR: 5670.0,
        APR: 4987.0,
        MAY: 4304.0,
        JUN: 3989.0,
        JUL: 4094.0,
        AUG: 4697.0,
        SEP: 5290.0,
        OCT: 5523.0,
        NOV: 5737.0,
        DEC: 5843.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.250461434175616],
              [-35.199045178706264, -8.150461434175616],
              [-35.099045178706263, -8.150461434175616],
              [-35.099045178706263, -8.250461434175616],
              [-35.199045178706264, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '583',
        ID: 40999.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.2005,
        ANNUAL: 5274.0,
        JAN: 5749.0,
        FEB: 5860.0,
        MAR: 5881.0,
        APR: 5101.0,
        MAY: 4421.0,
        JUN: 4076.0,
        JUL: 4170.0,
        AUG: 4832.0,
        SEP: 5416.0,
        OCT: 5725.0,
        NOV: 6001.0,
        DEC: 6050.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -8.250461434175616],
              [-35.099045178706263, -8.150461434175616],
              [-34.999045178706261, -8.150461434175616],
              [-34.999045178706261, -8.250461434175616],
              [-35.099045178706263, -8.250461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '584',
        ID: 41000.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -8.2005,
        ANNUAL: 5445.0,
        JAN: 5922.0,
        FEB: 6009.0,
        MAR: 5975.0,
        APR: 5226.0,
        MAY: 4552.0,
        JUN: 4252.0,
        JUL: 4327.0,
        AUG: 5064.0,
        SEP: 5614.0,
        OCT: 5956.0,
        NOV: 6196.0,
        DEC: 6253.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.906979882, -8.150630432999954],
              [-34.907310138999897, -8.151495500999946],
              [-34.907744261999902, -8.152632632999932],
              [-34.908799277999897, -8.155147784999933],
              [-34.908811709, -8.155177418999939],
              [-34.90888036, -8.155365189999936],
              [-34.909967575999907, -8.158338869999969],
              [-34.911196854, -8.161559554999936],
              [-34.911589155999899, -8.162655994999964],
              [-34.911837953, -8.163351357999939],
              [-34.912205706, -8.164379186999948],
              [-34.9130387, -8.167055936999937],
              [-34.913195963, -8.16777717899993],
              [-34.913267108, -8.16810346499993],
              [-34.913459142, -8.16898417199997],
              [-34.913491066999903, -8.169130586999925],
              [-34.913546943, -8.169386846999942],
              [-34.913788208, -8.170418173999964],
              [-34.914122769999899, -8.171848306999959],
              [-34.914318384999902, -8.172314463999955],
              [-34.914855284999902, -8.17340147199997],
              [-34.915031843, -8.173758929999963],
              [-34.915447878, -8.174719525999933],
              [-34.915491782999901, -8.175858173999931],
              [-34.915644205, -8.176854502999959],
              [-34.915770619999897, -8.17768083299994],
              [-34.9157893719999, -8.177829589999922],
              [-34.9159032439999, -8.178732924999963],
              [-34.916111713, -8.180386689999983],
              [-34.916332908999898, -8.18121265399998],
              [-34.916374864, -8.181369315999962],
              [-34.916596292999898, -8.182196153999939],
              [-34.91712632, -8.18386043899994],
              [-34.917531962999902, -8.185134155999956],
              [-34.917719786, -8.185723919999933],
              [-34.917657708, -8.186809312999962],
              [-34.917602691, -8.187771245999951],
              [-34.917044458, -8.190172475999928],
              [-34.9172310719999, -8.191622767999945],
              [-34.9172153109999, -8.191794831999964],
              [-34.917062137999899, -8.193467108999927],
              [-34.916990249, -8.194251958999924],
              [-34.916779697, -8.195736517999933],
              [-34.916677993999897, -8.196140807999948],
              [-34.916380445, -8.197323641999954],
              [-34.916372630999902, -8.197354700999931],
              [-34.916347422999898, -8.198765465999935],
              [-34.916343495, -8.198985314999966],
              [-34.916330877, -8.199691477999988],
              [-34.916327738999897, -8.199867074999926],
              [-34.916072375999903, -8.200902630999952],
              [-34.915993189999902, -8.201223747999956],
              [-34.916042675, -8.202288587999925],
              [-34.916108978, -8.202760052999952],
              [-34.916435744, -8.205083609999971],
              [-34.916694578, -8.206366978999936],
              [-34.916863677, -8.207205418999923],
              [-34.917407388, -8.209984912999973],
              [-34.917715406999903, -8.211491016999965],
              [-34.91781594, -8.211982585999971],
              [-34.917981827999903, -8.212458963999945],
              [-34.918169322, -8.212997389999936],
              [-34.918800424, -8.214733178999943],
              [-34.918870387, -8.214886852999943],
              [-34.919440203, -8.216138458999989],
              [-34.919563555, -8.216305089999935],
              [-34.920344552, -8.217360096999931],
              [-34.921230379999898, -8.218556716999958],
              [-34.921680704, -8.218935428999936],
              [-34.921908965999897, -8.219273783999943],
              [-34.9222391489999, -8.219763216999981],
              [-34.922817406, -8.219942877999928],
              [-34.923240275, -8.220451780999959],
              [-34.923547219999897, -8.221156926999985],
              [-34.923902788, -8.221852701999982],
              [-34.923949991, -8.221945068999965],
              [-34.9244662, -8.221598973999951],
              [-34.924825088, -8.221614689999969],
              [-34.925327056999897, -8.22202751299994],
              [-34.925494204, -8.222236435999953],
              [-34.925640594, -8.222472753999964],
              [-34.925867366, -8.222894895999955],
              [-34.925954437, -8.223056980999967],
              [-34.926095588, -8.223606808999925],
              [-34.926344108, -8.223719218999967],
              [-34.926708972, -8.223698765999934],
              [-34.927142128, -8.223882247999935],
              [-34.927759957, -8.224071469999952],
              [-34.92777596, -8.224076370999974],
              [-34.92854139, -8.22424985299995],
              [-34.928571566999899, -8.224679894999944],
              [-34.928614599999896, -8.225293134999959],
              [-34.928652360999898, -8.225831248999945],
              [-34.927950779999897, -8.226034848999971],
              [-34.927364908999898, -8.226476568999949],
              [-34.926670342, -8.227020410999968],
              [-34.925737583999897, -8.227236258999946],
              [-34.925247495999898, -8.227374784999943],
              [-34.924801223, -8.227945827999967],
              [-34.924462248999902, -8.229066040999955],
              [-34.924412141999902, -8.229907187999965],
              [-34.924477098, -8.23063680299998],
              [-34.924564953999898, -8.231289054999925],
              [-34.924863398999904, -8.232004731999954],
              [-34.925437777999903, -8.232966026999966],
              [-34.9264932419999, -8.234060550999967],
              [-34.927595004999901, -8.23529572599996],
              [-34.929166564999903, -8.236838244999937],
              [-34.930786677, -8.238597945999972],
              [-34.932277914999901, -8.239834822999979],
              [-34.933831724, -8.241412807999954],
              [-34.9351924549999, -8.243031515999974],
              [-34.936257930999901, -8.244430544999931],
              [-34.937451622, -8.246151135999973],
              [-34.938564792, -8.247988057999985],
              [-34.939601183, -8.249808072999944],
              [-34.93993234616989, -8.250461434175616],
              [-34.999045178706261, -8.250461434175616],
              [-34.999045178706261, -8.150461434175616],
              [-34.906915363345412, -8.150461434175616],
              [-34.906979882, -8.150630432999954],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '585',
        ID: 41332.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -8.1005,
        ANNUAL: 5776.0,
        JAN: 5915.0,
        FEB: 5654.0,
        MAR: 5761.0,
        APR: 5222.0,
        MAY: 4892.0,
        JUN: 4770.0,
        JUL: 5169.0,
        AUG: 6087.0,
        SEP: 6578.0,
        OCT: 6583.0,
        NOV: 6485.0,
        DEC: 6188.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.642780476, -8.150074544999931],
              [-40.639063001, -8.148152641999962],
              [-40.63831471, -8.147216751999961],
              [-40.638180163, -8.146380104999928],
              [-40.638168076999897, -8.146304952999959],
              [-40.637870495999898, -8.144454526999942],
              [-40.636841577, -8.142095581999962],
              [-40.633951189999898, -8.139448788999966],
              [-40.632840367, -8.139301282999943],
              [-40.62870227, -8.141073329999928],
              [-40.624482148, -8.140353829999983],
              [-40.621875426, -8.141218125999952],
              [-40.617808957, -8.143608061999934],
              [-40.61442262, -8.146502507999971],
              [-40.610534957, -8.146609259999925],
              [-40.604702911999901, -8.14962747899995],
              [-40.601317944999899, -8.150170463999931],
              [-40.601007317, -8.149262811999966],
              [-40.602039804, -8.146013711999959],
              [-40.601140265, -8.144050283999951],
              [-40.599045178706341, -8.142365806888542],
              [-40.599045178706341, -8.150461434175616],
              [-40.642774482346738, -8.150461434175616],
              [-40.642780476, -8.150074544999931],
            ],
          ],
          [
            [
              [-40.599344671, -8.115624593999945],
              [-40.601181107999899, -8.107497120999932],
              [-40.602255844999902, -8.10542613699994],
              [-40.603867672, -8.103687685999938],
              [-40.601533505, -8.099622471999963],
              [-40.603123170999901, -8.095330389999956],
              [-40.601089953, -8.091194718999937],
              [-40.599045178706341, -8.082135460669049],
              [-40.599045178706341, -8.115877349994063],
              [-40.599344671, -8.115624593999945],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '586',
        ID: 41333.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.1005,
        ANNUAL: 5659.0,
        JAN: 5833.0,
        FEB: 5587.0,
        MAR: 5655.0,
        APR: 5138.0,
        MAY: 4696.0,
        JUN: 4571.0,
        JUL: 4916.0,
        AUG: 5876.0,
        SEP: 6495.0,
        OCT: 6494.0,
        NOV: 6465.0,
        DEC: 6187.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.597558916, -8.141170831999938],
              [-40.591175457, -8.139616039999963],
              [-40.589698422, -8.138808337999929],
              [-40.589502701999898, -8.137804889999925],
              [-40.590922858999903, -8.128810057999944],
              [-40.591235275, -8.123516301999922],
              [-40.594146248, -8.120011285999952],
              [-40.596499986, -8.118025267999945],
              [-40.596728366, -8.117832619999939],
              [-40.599045178706341, -8.115877349994063],
              [-40.599045178706341, -8.082135460669049],
              [-40.59875677, -8.080857681999927],
              [-40.592008173999908, -8.066083356999972],
              [-40.588985784704803, -8.059466610064586],
              [-40.584872411853496, -8.050461434175617],
              [-40.499045178706339, -8.050461434175617],
              [-40.499045178706339, -8.150461434175616],
              [-40.599045178706341, -8.150461434175616],
              [-40.599045178706341, -8.142365806888542],
              [-40.597558916, -8.141170831999938],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '587',
        ID: 41334.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.1005,
        ANNUAL: 5585.0,
        JAN: 5826.0,
        FEB: 5542.0,
        MAR: 5613.0,
        APR: 5033.0,
        MAY: 4640.0,
        JUN: 4451.0,
        JUL: 4792.0,
        AUG: 5723.0,
        SEP: 6390.0,
        OCT: 6384.0,
        NOV: 6443.0,
        DEC: 6186.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.150461434175616],
              [-40.499045178706339, -8.050461434175617],
              [-40.399045178706338, -8.050461434175617],
              [-40.399045178706338, -8.150461434175616],
              [-40.499045178706339, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '588',
        ID: 41335.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.1005,
        ANNUAL: 5596.0,
        JAN: 5861.0,
        FEB: 5572.0,
        MAR: 5628.0,
        APR: 5054.0,
        MAY: 4669.0,
        JUN: 4433.0,
        JUL: 4778.0,
        AUG: 5717.0,
        SEP: 6376.0,
        OCT: 6398.0,
        NOV: 6461.0,
        DEC: 6212.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.150461434175616],
              [-40.399045178706338, -8.050461434175617],
              [-40.299045178706336, -8.050461434175617],
              [-40.299045178706336, -8.150461434175616],
              [-40.399045178706338, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '589',
        ID: 41336.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.1005,
        ANNUAL: 5647.0,
        JAN: 5995.0,
        FEB: 5654.0,
        MAR: 5729.0,
        APR: 5185.0,
        MAY: 4678.0,
        JUN: 4489.0,
        JUL: 4790.0,
        AUG: 5665.0,
        SEP: 6378.0,
        OCT: 6425.0,
        NOV: 6502.0,
        DEC: 6271.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.150461434175616],
              [-40.299045178706336, -8.050461434175617],
              [-40.199045178706335, -8.050461434175617],
              [-40.199045178706335, -8.150461434175616],
              [-40.299045178706336, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '590',
        ID: 41337.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.1005,
        ANNUAL: 5643.0,
        JAN: 5959.0,
        FEB: 5691.0,
        MAR: 5785.0,
        APR: 5199.0,
        MAY: 4697.0,
        JUN: 4446.0,
        JUL: 4760.0,
        AUG: 5628.0,
        SEP: 6360.0,
        OCT: 6448.0,
        NOV: 6502.0,
        DEC: 6238.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.150461434175616],
              [-40.199045178706335, -8.050461434175617],
              [-40.099045178706334, -8.050461434175617],
              [-40.099045178706334, -8.150461434175616],
              [-40.199045178706335, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '591',
        ID: 41338.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.1005,
        ANNUAL: 5639.0,
        JAN: 5921.0,
        FEB: 5722.0,
        MAR: 5852.0,
        APR: 5193.0,
        MAY: 4731.0,
        JUN: 4432.0,
        JUL: 4728.0,
        AUG: 5594.0,
        SEP: 6344.0,
        OCT: 6451.0,
        NOV: 6482.0,
        DEC: 6210.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.150461434175616],
              [-40.099045178706334, -8.050461434175617],
              [-39.999045178706332, -8.050461434175617],
              [-39.999045178706332, -8.150461434175616],
              [-40.099045178706334, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '592',
        ID: 41339.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.1005,
        ANNUAL: 5600.0,
        JAN: 5888.0,
        FEB: 5669.0,
        MAR: 5816.0,
        APR: 5214.0,
        MAY: 4682.0,
        JUN: 4369.0,
        JUL: 4666.0,
        AUG: 5578.0,
        SEP: 6297.0,
        OCT: 6414.0,
        NOV: 6435.0,
        DEC: 6173.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.150461434175616],
              [-39.999045178706332, -8.050461434175617],
              [-39.899045178706331, -8.050461434175617],
              [-39.899045178706331, -8.150461434175616],
              [-39.999045178706332, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '593',
        ID: 41340.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.1005,
        ANNUAL: 5598.0,
        JAN: 5909.0,
        FEB: 5644.0,
        MAR: 5790.0,
        APR: 5209.0,
        MAY: 4687.0,
        JUN: 4363.0,
        JUL: 4628.0,
        AUG: 5548.0,
        SEP: 6300.0,
        OCT: 6414.0,
        NOV: 6476.0,
        DEC: 6208.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.150461434175616],
              [-39.899045178706331, -8.050461434175617],
              [-39.799045178706329, -8.050461434175617],
              [-39.799045178706329, -8.150461434175616],
              [-39.899045178706331, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '594',
        ID: 41341.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.1005,
        ANNUAL: 5627.0,
        JAN: 5910.0,
        FEB: 5698.0,
        MAR: 5833.0,
        APR: 5274.0,
        MAY: 4707.0,
        JUN: 4398.0,
        JUL: 4647.0,
        AUG: 5557.0,
        SEP: 6315.0,
        OCT: 6448.0,
        NOV: 6492.0,
        DEC: 6241.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.150461434175616],
              [-39.799045178706329, -8.050461434175617],
              [-39.699045178706328, -8.050461434175617],
              [-39.699045178706328, -8.150461434175616],
              [-39.799045178706329, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '595',
        ID: 41342.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.1005,
        ANNUAL: 5667.0,
        JAN: 6012.0,
        FEB: 5768.0,
        MAR: 5869.0,
        APR: 5323.0,
        MAY: 4682.0,
        JUN: 4415.0,
        JUL: 4666.0,
        AUG: 5537.0,
        SEP: 6376.0,
        OCT: 6471.0,
        NOV: 6602.0,
        DEC: 6282.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -8.150461434175616],
              [-39.699045178706328, -8.050461434175617],
              [-39.599045178706326, -8.050461434175617],
              [-39.599045178706326, -8.150461434175616],
              [-39.699045178706328, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '596',
        ID: 41343.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.1005,
        ANNUAL: 5704.0,
        JAN: 6061.0,
        FEB: 5866.0,
        MAR: 5893.0,
        APR: 5396.0,
        MAY: 4753.0,
        JUN: 4450.0,
        JUL: 4693.0,
        AUG: 5535.0,
        SEP: 6388.0,
        OCT: 6497.0,
        NOV: 6585.0,
        DEC: 6331.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -8.150461434175616],
              [-39.599045178706326, -8.050461434175617],
              [-39.499045178706325, -8.050461434175617],
              [-39.499045178706325, -8.150461434175616],
              [-39.599045178706326, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '597',
        ID: 41344.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -8.1005,
        ANNUAL: 5726.0,
        JAN: 6110.0,
        FEB: 5930.0,
        MAR: 5901.0,
        APR: 5451.0,
        MAY: 4804.0,
        JUN: 4450.0,
        JUL: 4715.0,
        AUG: 5524.0,
        SEP: 6418.0,
        OCT: 6541.0,
        NOV: 6556.0,
        DEC: 6317.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -8.150461434175616],
              [-39.499045178706325, -8.050461434175617],
              [-39.399045178706324, -8.050461434175617],
              [-39.399045178706324, -8.150461434175616],
              [-39.499045178706325, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '598',
        ID: 41345.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -8.1005,
        ANNUAL: 5701.0,
        JAN: 6027.0,
        FEB: 5908.0,
        MAR: 5878.0,
        APR: 5452.0,
        MAY: 4786.0,
        JUN: 4434.0,
        JUL: 4701.0,
        AUG: 5512.0,
        SEP: 6402.0,
        OCT: 6454.0,
        NOV: 6571.0,
        DEC: 6284.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.399045178706324, -8.150461434175616],
              [-39.399045178706324, -8.050461434175617],
              [-39.299045178706322, -8.050461434175617],
              [-39.299045178706322, -8.150461434175616],
              [-39.399045178706324, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '599',
        ID: 41346.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.1005,
        ANNUAL: 5707.0,
        JAN: 6019.0,
        FEB: 5917.0,
        MAR: 5902.0,
        APR: 5476.0,
        MAY: 4788.0,
        JUN: 4445.0,
        JUL: 4730.0,
        AUG: 5523.0,
        SEP: 6390.0,
        OCT: 6412.0,
        NOV: 6590.0,
        DEC: 6294.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -8.150461434175616],
              [-39.299045178706322, -8.050461434175617],
              [-39.199045178706321, -8.050461434175617],
              [-39.199045178706321, -8.150461434175616],
              [-39.299045178706322, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '600',
        ID: 41347.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.1005,
        ANNUAL: 5704.0,
        JAN: 6066.0,
        FEB: 5903.0,
        MAR: 5885.0,
        APR: 5460.0,
        MAY: 4765.0,
        JUN: 4437.0,
        JUL: 4738.0,
        AUG: 5530.0,
        SEP: 6363.0,
        OCT: 6436.0,
        NOV: 6599.0,
        DEC: 6264.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -8.150461434175616],
              [-39.199045178706321, -8.050461434175617],
              [-39.099045178706319, -8.050461434175617],
              [-39.099045178706319, -8.150461434175616],
              [-39.199045178706321, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '601',
        ID: 41348.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.1005,
        ANNUAL: 5702.0,
        JAN: 6054.0,
        FEB: 5922.0,
        MAR: 5907.0,
        APR: 5491.0,
        MAY: 4769.0,
        JUN: 4402.0,
        JUL: 4711.0,
        AUG: 5507.0,
        SEP: 6377.0,
        OCT: 6425.0,
        NOV: 6617.0,
        DEC: 6243.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.099045178706319, -8.150461434175616],
              [-39.099045178706319, -8.050461434175617],
              [-38.999045178706318, -8.050461434175617],
              [-38.999045178706318, -8.150461434175616],
              [-39.099045178706319, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '602',
        ID: 41349.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.1005,
        ANNUAL: 5747.0,
        JAN: 6104.0,
        FEB: 5981.0,
        MAR: 5917.0,
        APR: 5553.0,
        MAY: 4811.0,
        JUN: 4428.0,
        JUL: 4725.0,
        AUG: 5526.0,
        SEP: 6455.0,
        OCT: 6516.0,
        NOV: 6675.0,
        DEC: 6270.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.150461434175616],
              [-38.999045178706318, -8.050461434175617],
              [-38.899045178706317, -8.050461434175617],
              [-38.899045178706317, -8.150461434175616],
              [-38.999045178706318, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '603',
        ID: 41350.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.1005,
        ANNUAL: 5768.0,
        JAN: 6170.0,
        FEB: 6011.0,
        MAR: 5977.0,
        APR: 5528.0,
        MAY: 4828.0,
        JUN: 4446.0,
        JUL: 4692.0,
        AUG: 5494.0,
        SEP: 6449.0,
        OCT: 6538.0,
        NOV: 6721.0,
        DEC: 6363.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.150461434175616],
              [-38.899045178706317, -8.050461434175617],
              [-38.799045178706315, -8.050461434175617],
              [-38.799045178706315, -8.150461434175616],
              [-38.899045178706317, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '604',
        ID: 41351.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.1005,
        ANNUAL: 5775.0,
        JAN: 6228.0,
        FEB: 6006.0,
        MAR: 6015.0,
        APR: 5544.0,
        MAY: 4826.0,
        JUN: 4404.0,
        JUL: 4670.0,
        AUG: 5482.0,
        SEP: 6424.0,
        OCT: 6526.0,
        NOV: 6750.0,
        DEC: 6425.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.150461434175616],
              [-38.799045178706315, -8.050461434175617],
              [-38.699045178706314, -8.050461434175617],
              [-38.699045178706314, -8.150461434175616],
              [-38.799045178706315, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '605',
        ID: 41352.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.1005,
        ANNUAL: 5800.0,
        JAN: 6284.0,
        FEB: 6026.0,
        MAR: 6077.0,
        APR: 5585.0,
        MAY: 4835.0,
        JUN: 4414.0,
        JUL: 4686.0,
        AUG: 5480.0,
        SEP: 6458.0,
        OCT: 6542.0,
        NOV: 6744.0,
        DEC: 6471.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.150461434175616],
              [-38.699045178706314, -8.050461434175617],
              [-38.599045178706312, -8.050461434175617],
              [-38.599045178706312, -8.150461434175616],
              [-38.699045178706314, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '606',
        ID: 41353.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.1005,
        ANNUAL: 5828.0,
        JAN: 6305.0,
        FEB: 6106.0,
        MAR: 6107.0,
        APR: 5646.0,
        MAY: 4875.0,
        JUN: 4428.0,
        JUL: 4684.0,
        AUG: 5497.0,
        SEP: 6491.0,
        OCT: 6559.0,
        NOV: 6725.0,
        DEC: 6515.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.150461434175616],
              [-38.599045178706312, -8.050461434175617],
              [-38.499045178706311, -8.050461434175617],
              [-38.499045178706311, -8.150461434175616],
              [-38.599045178706312, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '607',
        ID: 41354.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.1005,
        ANNUAL: 5804.0,
        JAN: 6273.0,
        FEB: 6127.0,
        MAR: 6140.0,
        APR: 5647.0,
        MAY: 4850.0,
        JUN: 4336.0,
        JUL: 4637.0,
        AUG: 5453.0,
        SEP: 6417.0,
        OCT: 6556.0,
        NOV: 6744.0,
        DEC: 6471.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.150461434175616],
              [-38.499045178706311, -8.050461434175617],
              [-38.399045178706309, -8.050461434175617],
              [-38.399045178706309, -8.150461434175616],
              [-38.499045178706311, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '608',
        ID: 41355.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.1005,
        ANNUAL: 5797.0,
        JAN: 6260.0,
        FEB: 6131.0,
        MAR: 6098.0,
        APR: 5634.0,
        MAY: 4845.0,
        JUN: 4342.0,
        JUL: 4660.0,
        AUG: 5471.0,
        SEP: 6426.0,
        OCT: 6540.0,
        NOV: 6744.0,
        DEC: 6411.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.150461434175616],
              [-38.399045178706309, -8.050461434175617],
              [-38.299045178706308, -8.050461434175617],
              [-38.299045178706308, -8.150461434175616],
              [-38.399045178706309, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '609',
        ID: 41356.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.1005,
        ANNUAL: 5788.0,
        JAN: 6271.0,
        FEB: 6155.0,
        MAR: 6043.0,
        APR: 5708.0,
        MAY: 4851.0,
        JUN: 4356.0,
        JUL: 4628.0,
        AUG: 5449.0,
        SEP: 6382.0,
        OCT: 6510.0,
        NOV: 6692.0,
        DEC: 6408.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.150461434175616],
              [-38.299045178706308, -8.050461434175617],
              [-38.199045178706307, -8.050461434175617],
              [-38.199045178706307, -8.150461434175616],
              [-38.299045178706308, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '610',
        ID: 41357.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.1005,
        ANNUAL: 5764.0,
        JAN: 6268.0,
        FEB: 6159.0,
        MAR: 6080.0,
        APR: 5639.0,
        MAY: 4825.0,
        JUN: 4327.0,
        JUL: 4599.0,
        AUG: 5402.0,
        SEP: 6306.0,
        OCT: 6509.0,
        NOV: 6652.0,
        DEC: 6408.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.150461434175616],
              [-38.199045178706307, -8.050461434175617],
              [-38.099045178706305, -8.050461434175617],
              [-38.099045178706305, -8.150461434175616],
              [-38.199045178706307, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '611',
        ID: 41358.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.1005,
        ANNUAL: 5735.0,
        JAN: 6234.0,
        FEB: 6144.0,
        MAR: 6070.0,
        APR: 5590.0,
        MAY: 4796.0,
        JUN: 4311.0,
        JUL: 4579.0,
        AUG: 5379.0,
        SEP: 6284.0,
        OCT: 6437.0,
        NOV: 6620.0,
        DEC: 6371.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.150461434175616],
              [-38.099045178706305, -8.050461434175617],
              [-37.999045178706304, -8.050461434175617],
              [-37.999045178706304, -8.150461434175616],
              [-38.099045178706305, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '612',
        ID: 41359.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.1005,
        ANNUAL: 5753.0,
        JAN: 6226.0,
        FEB: 6138.0,
        MAR: 6097.0,
        APR: 5641.0,
        MAY: 4781.0,
        JUN: 4331.0,
        JUL: 4601.0,
        AUG: 5369.0,
        SEP: 6324.0,
        OCT: 6461.0,
        NOV: 6706.0,
        DEC: 6363.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.150461434175616],
              [-37.999045178706304, -8.050461434175617],
              [-37.899045178706302, -8.050461434175617],
              [-37.899045178706302, -8.150461434175616],
              [-37.999045178706304, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '613',
        ID: 41360.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.1005,
        ANNUAL: 5789.0,
        JAN: 6226.0,
        FEB: 6197.0,
        MAR: 6149.0,
        APR: 5702.0,
        MAY: 4825.0,
        JUN: 4343.0,
        JUL: 4618.0,
        AUG: 5403.0,
        SEP: 6330.0,
        OCT: 6526.0,
        NOV: 6753.0,
        DEC: 6402.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.150461434175616],
              [-37.899045178706302, -8.050461434175617],
              [-37.799045178706301, -8.050461434175617],
              [-37.799045178706301, -8.150461434175616],
              [-37.899045178706302, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '614',
        ID: 41361.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.1005,
        ANNUAL: 5807.0,
        JAN: 6258.0,
        FEB: 6310.0,
        MAR: 6217.0,
        APR: 5758.0,
        MAY: 4850.0,
        JUN: 4319.0,
        JUL: 4562.0,
        AUG: 5368.0,
        SEP: 6301.0,
        OCT: 6530.0,
        NOV: 6804.0,
        DEC: 6412.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.150461434175616],
              [-37.799045178706301, -8.050461434175617],
              [-37.699045178706299, -8.050461434175617],
              [-37.699045178706299, -8.150461434175616],
              [-37.799045178706301, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '615',
        ID: 41362.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.1005,
        ANNUAL: 5793.0,
        JAN: 6317.0,
        FEB: 6249.0,
        MAR: 6125.0,
        APR: 5728.0,
        MAY: 4862.0,
        JUN: 4280.0,
        JUL: 4542.0,
        AUG: 5331.0,
        SEP: 6261.0,
        OCT: 6526.0,
        NOV: 6797.0,
        DEC: 6490.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.150461434175616],
              [-37.699045178706299, -8.050461434175617],
              [-37.599045178706298, -8.050461434175617],
              [-37.599045178706298, -8.150461434175616],
              [-37.699045178706299, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '616',
        ID: 41363.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.1005,
        ANNUAL: 5772.0,
        JAN: 6284.0,
        FEB: 6205.0,
        MAR: 6108.0,
        APR: 5714.0,
        MAY: 4852.0,
        JUN: 4255.0,
        JUL: 4518.0,
        AUG: 5323.0,
        SEP: 6267.0,
        OCT: 6476.0,
        NOV: 6786.0,
        DEC: 6480.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.150461434175616],
              [-37.599045178706298, -8.050461434175617],
              [-37.499045178706297, -8.050461434175617],
              [-37.499045178706297, -8.150461434175616],
              [-37.599045178706298, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '617',
        ID: 41364.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.1005,
        ANNUAL: 5755.0,
        JAN: 6283.0,
        FEB: 6152.0,
        MAR: 6073.0,
        APR: 5679.0,
        MAY: 4833.0,
        JUN: 4258.0,
        JUL: 4497.0,
        AUG: 5312.0,
        SEP: 6238.0,
        OCT: 6463.0,
        NOV: 6773.0,
        DEC: 6497.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.150461434175616],
              [-37.499045178706297, -8.050461434175617],
              [-37.399045178706295, -8.050461434175617],
              [-37.399045178706295, -8.150461434175616],
              [-37.499045178706297, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '618',
        ID: 41365.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.1005,
        ANNUAL: 5771.0,
        JAN: 6346.0,
        FEB: 6164.0,
        MAR: 6104.0,
        APR: 5629.0,
        MAY: 4843.0,
        JUN: 4308.0,
        JUL: 4518.0,
        AUG: 5305.0,
        SEP: 6214.0,
        OCT: 6492.0,
        NOV: 6791.0,
        DEC: 6542.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -8.150461434175616],
              [-37.399045178706295, -8.050461434175617],
              [-37.299045178706294, -8.050461434175617],
              [-37.299045178706294, -8.150461434175616],
              [-37.399045178706295, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '619',
        ID: 41366.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.1005,
        ANNUAL: 5729.0,
        JAN: 6268.0,
        FEB: 6127.0,
        MAR: 6039.0,
        APR: 5581.0,
        MAY: 4802.0,
        JUN: 4300.0,
        JUL: 4475.0,
        AUG: 5262.0,
        SEP: 6184.0,
        OCT: 6434.0,
        NOV: 6757.0,
        DEC: 6522.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -8.150461434175616],
              [-37.299045178706294, -8.050461434175617],
              [-37.199045178706292, -8.050461434175617],
              [-37.199045178706292, -8.150461434175616],
              [-37.299045178706294, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '620',
        ID: 41367.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.1005,
        ANNUAL: 5686.0,
        JAN: 6187.0,
        FEB: 6042.0,
        MAR: 5992.0,
        APR: 5513.0,
        MAY: 4737.0,
        JUN: 4314.0,
        JUL: 4463.0,
        AUG: 5219.0,
        SEP: 6166.0,
        OCT: 6425.0,
        NOV: 6725.0,
        DEC: 6447.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.158092428, -8.051224338999926],
              [-37.156920989, -8.058252762999937],
              [-37.156845222, -8.064534517999959],
              [-37.1522007579999, -8.070417214999955],
              [-37.150989517, -8.072193290999966],
              [-37.146637091999899, -8.078575375999954],
              [-37.145509531, -8.082189073999924],
              [-37.1437071289999, -8.087965554999926],
              [-37.145784116, -8.09520274499994],
              [-37.147817862, -8.100093270999926],
              [-37.146867533, -8.109159990999956],
              [-37.142557823999901, -8.117944797999932],
              [-37.14417227, -8.124996433999968],
              [-37.1532285709999, -8.127467242999952],
              [-37.154842365, -8.132184475999958],
              [-37.158613838, -8.138869383999966],
              [-37.1524542619999, -8.144873452999942],
              [-37.151003957999897, -8.150041553999937],
              [-37.151427293696379, -8.150461434175616],
              [-37.199045178706292, -8.150461434175616],
              [-37.199045178706292, -8.050461434175617],
              [-37.157523491691592, -8.050461434175617],
              [-37.158092428, -8.051224338999926],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '621',
        ID: 41372.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.1005,
        ANNUAL: 5663.0,
        JAN: 6156.0,
        FEB: 6017.0,
        MAR: 5979.0,
        APR: 5546.0,
        MAY: 4748.0,
        JUN: 4294.0,
        JUL: 4489.0,
        AUG: 5293.0,
        SEP: 6130.0,
        OCT: 6304.0,
        NOV: 6623.0,
        DEC: 6378.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.690332133, -8.148707724999946],
              [-36.69446554, -8.143866561999968],
              [-36.694525752999901, -8.142218358999971],
              [-36.6938622119999, -8.140772718999937],
              [-36.689825339, -8.138263834999989],
              [-36.689751355999903, -8.138217854999937],
              [-36.689030013, -8.13776954599995],
              [-36.686433656, -8.136155930999951],
              [-36.672460719999897, -8.127471864999968],
              [-36.671956897, -8.127302373999942],
              [-36.6678453379999, -8.124265875999981],
              [-36.665228942999903, -8.123256893999955],
              [-36.65721753, -8.122030461999938],
              [-36.653737798999899, -8.120747300999943],
              [-36.644190690999899, -8.120342147999956],
              [-36.640320932999899, -8.119910599999978],
              [-36.639913776, -8.119629911999937],
              [-36.637532106, -8.118551752999956],
              [-36.636408596, -8.116871297999975],
              [-36.635180906999899, -8.115595253999969],
              [-36.634109587, -8.114684896999961],
              [-36.628612516, -8.111050372999959],
              [-36.627325460999899, -8.106599905999925],
              [-36.627852359, -8.102858025999977],
              [-36.628688483, -8.101898613999937],
              [-36.633773914, -8.096063322999951],
              [-36.636984948, -8.093246960999984],
              [-36.640333459, -8.092108783999956],
              [-36.645016166999902, -8.092785797999969],
              [-36.647238327, -8.092490088999968],
              [-36.647659615, -8.09125611499995],
              [-36.646950558, -8.086142351999969],
              [-36.648634606, -8.082731056999931],
              [-36.650362175999902, -8.081330583999945],
              [-36.653611156999901, -8.082561735999954],
              [-36.657273926999899, -8.082077521999969],
              [-36.658682308, -8.079808992999968],
              [-36.658555247, -8.078919792999947],
              [-36.65783148, -8.076132616999937],
              [-36.657578249, -8.075560254999965],
              [-36.656474408999898, -8.073065317999987],
              [-36.653790887, -8.070384073999943],
              [-36.646616359999904, -8.065548489999969],
              [-36.641210951999902, -8.06223761799993],
              [-36.634208836, -8.059308],
              [-36.628824166999898, -8.05705610499996],
              [-36.627851888999899, -8.05574927299995],
              [-36.627774191999897, -8.051523997999945],
              [-36.627884688837177, -8.050461434175617],
              [-36.599045178706284, -8.050461434175617],
              [-36.599045178706284, -8.150461434175616],
              [-36.688741764451244, -8.150461434175616],
              [-36.690332133, -8.148707724999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '622',
        ID: 41373.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.1005,
        ANNUAL: 5455.0,
        JAN: 5998.0,
        FEB: 5819.0,
        MAR: 5887.0,
        APR: 5429.0,
        MAY: 4597.0,
        JUN: 4094.0,
        JUL: 4238.0,
        AUG: 4929.0,
        SEP: 5851.0,
        OCT: 6097.0,
        NOV: 6350.0,
        DEC: 6167.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.599045178706284, -8.150461434175616],
              [-36.599045178706284, -8.050461434175617],
              [-36.499045178706282, -8.050461434175617],
              [-36.499045178706282, -8.150461434175616],
              [-36.599045178706284, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '623',
        ID: 41374.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.1005,
        ANNUAL: 5446.0,
        JAN: 5993.0,
        FEB: 5901.0,
        MAR: 5895.0,
        APR: 5411.0,
        MAY: 4583.0,
        JUN: 4139.0,
        JUL: 4238.0,
        AUG: 4958.0,
        SEP: 5752.0,
        OCT: 6022.0,
        NOV: 6309.0,
        DEC: 6153.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.150461434175616],
              [-36.499045178706282, -8.050461434175617],
              [-36.399045178706281, -8.050461434175617],
              [-36.399045178706281, -8.150461434175616],
              [-36.499045178706282, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '624',
        ID: 41375.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.1005,
        ANNUAL: 5452.0,
        JAN: 5957.0,
        FEB: 5893.0,
        MAR: 5853.0,
        APR: 5401.0,
        MAY: 4602.0,
        JUN: 4193.0,
        JUL: 4315.0,
        AUG: 5050.0,
        SEP: 5760.0,
        OCT: 5991.0,
        NOV: 6283.0,
        DEC: 6129.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.150461434175616],
              [-36.399045178706281, -8.050461434175617],
              [-36.29904517870628, -8.050461434175617],
              [-36.29904517870628, -8.150461434175616],
              [-36.399045178706281, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '625',
        ID: 41376.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.1005,
        ANNUAL: 5310.0,
        JAN: 5817.0,
        FEB: 5750.0,
        MAR: 5743.0,
        APR: 5277.0,
        MAY: 4519.0,
        JUN: 4028.0,
        JUL: 4113.0,
        AUG: 4847.0,
        SEP: 5611.0,
        OCT: 5855.0,
        NOV: 6165.0,
        DEC: 5991.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.150461434175616],
              [-36.29904517870628, -8.050461434175617],
              [-36.199045178706278, -8.050461434175617],
              [-36.199045178706278, -8.150461434175616],
              [-36.29904517870628, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '626',
        ID: 41377.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.1005,
        ANNUAL: 5297.0,
        JAN: 5807.0,
        FEB: 5737.0,
        MAR: 5790.0,
        APR: 5289.0,
        MAY: 4496.0,
        JUN: 4013.0,
        JUL: 4087.0,
        AUG: 4819.0,
        SEP: 5502.0,
        OCT: 5830.0,
        NOV: 6189.0,
        DEC: 6009.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.150461434175616],
              [-36.199045178706278, -8.050461434175617],
              [-36.099045178706277, -8.050461434175617],
              [-36.099045178706277, -8.150461434175616],
              [-36.199045178706278, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '627',
        ID: 41378.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.1005,
        ANNUAL: 5301.0,
        JAN: 5777.0,
        FEB: 5727.0,
        MAR: 5804.0,
        APR: 5295.0,
        MAY: 4499.0,
        JUN: 4015.0,
        JUL: 4130.0,
        AUG: 4837.0,
        SEP: 5530.0,
        OCT: 5812.0,
        NOV: 6194.0,
        DEC: 6001.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.150461434175616],
              [-36.099045178706277, -8.050461434175617],
              [-35.999045178706275, -8.050461434175617],
              [-35.999045178706275, -8.150461434175616],
              [-36.099045178706277, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '628',
        ID: 41379.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.1005,
        ANNUAL: 5252.0,
        JAN: 5672.0,
        FEB: 5724.0,
        MAR: 5708.0,
        APR: 5159.0,
        MAY: 4435.0,
        JUN: 4010.0,
        JUL: 4172.0,
        AUG: 4807.0,
        SEP: 5477.0,
        OCT: 5786.0,
        NOV: 6141.0,
        DEC: 5931.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.150461434175616],
              [-35.999045178706275, -8.050461434175617],
              [-35.899045178706274, -8.050461434175617],
              [-35.899045178706274, -8.150461434175616],
              [-35.999045178706275, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '629',
        ID: 41380.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.1005,
        ANNUAL: 5254.0,
        JAN: 5755.0,
        FEB: 5755.0,
        MAR: 5739.0,
        APR: 5125.0,
        MAY: 4467.0,
        JUN: 4028.0,
        JUL: 4182.0,
        AUG: 4766.0,
        SEP: 5455.0,
        OCT: 5755.0,
        NOV: 6074.0,
        DEC: 5943.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.150461434175616],
              [-35.899045178706274, -8.050461434175617],
              [-35.799045178706272, -8.050461434175617],
              [-35.799045178706272, -8.150461434175616],
              [-35.899045178706274, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '630',
        ID: 41381.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.1005,
        ANNUAL: 5248.0,
        JAN: 5809.0,
        FEB: 5761.0,
        MAR: 5785.0,
        APR: 5107.0,
        MAY: 4473.0,
        JUN: 4039.0,
        JUL: 4154.0,
        AUG: 4742.0,
        SEP: 5399.0,
        OCT: 5725.0,
        NOV: 6032.0,
        DEC: 5953.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.150461434175616],
              [-35.799045178706272, -8.050461434175617],
              [-35.699045178706271, -8.050461434175617],
              [-35.699045178706271, -8.150461434175616],
              [-35.799045178706272, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '631',
        ID: 41382.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.1005,
        ANNUAL: 5136.0,
        JAN: 5613.0,
        FEB: 5547.0,
        MAR: 5644.0,
        APR: 5033.0,
        MAY: 4380.0,
        JUN: 3988.0,
        JUL: 4070.0,
        AUG: 4683.0,
        SEP: 5321.0,
        OCT: 5597.0,
        NOV: 5904.0,
        DEC: 5845.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.150461434175616],
              [-35.699045178706271, -8.050461434175617],
              [-35.59904517870627, -8.050461434175617],
              [-35.59904517870627, -8.150461434175616],
              [-35.699045178706271, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '632',
        ID: 41383.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.1005,
        ANNUAL: 5188.0,
        JAN: 5671.0,
        FEB: 5652.0,
        MAR: 5633.0,
        APR: 5076.0,
        MAY: 4400.0,
        JUN: 4055.0,
        JUL: 4190.0,
        AUG: 4776.0,
        SEP: 5394.0,
        OCT: 5613.0,
        NOV: 5925.0,
        DEC: 5870.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.150461434175616],
              [-35.59904517870627, -8.050461434175617],
              [-35.499045178706268, -8.050461434175617],
              [-35.499045178706268, -8.150461434175616],
              [-35.59904517870627, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '633',
        ID: 41384.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.1005,
        ANNUAL: 5267.0,
        JAN: 5752.0,
        FEB: 5739.0,
        MAR: 5692.0,
        APR: 5113.0,
        MAY: 4446.0,
        JUN: 4128.0,
        JUL: 4286.0,
        AUG: 4871.0,
        SEP: 5528.0,
        OCT: 5727.0,
        NOV: 5981.0,
        DEC: 5947.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.150461434175616],
              [-35.499045178706268, -8.050461434175617],
              [-35.399045178706267, -8.050461434175617],
              [-35.399045178706267, -8.150461434175616],
              [-35.499045178706268, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '634',
        ID: 41385.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.1005,
        ANNUAL: 5252.0,
        JAN: 5675.0,
        FEB: 5729.0,
        MAR: 5746.0,
        APR: 5076.0,
        MAY: 4434.0,
        JUN: 4092.0,
        JUL: 4212.0,
        AUG: 4862.0,
        SEP: 5511.0,
        OCT: 5746.0,
        NOV: 5940.0,
        DEC: 6006.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.150461434175616],
              [-35.399045178706267, -8.050461434175617],
              [-35.299045178706265, -8.050461434175617],
              [-35.299045178706265, -8.150461434175616],
              [-35.399045178706267, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '635',
        ID: 41386.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.1005,
        ANNUAL: 5261.0,
        JAN: 5727.0,
        FEB: 5770.0,
        MAR: 5775.0,
        APR: 5058.0,
        MAY: 4416.0,
        JUN: 4096.0,
        JUL: 4194.0,
        AUG: 4881.0,
        SEP: 5530.0,
        OCT: 5731.0,
        NOV: 5938.0,
        DEC: 6012.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.150461434175616],
              [-35.299045178706265, -8.050461434175617],
              [-35.199045178706264, -8.050461434175617],
              [-35.199045178706264, -8.150461434175616],
              [-35.299045178706265, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '636',
        ID: 41387.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.1005,
        ANNUAL: 5288.0,
        JAN: 5803.0,
        FEB: 5832.0,
        MAR: 5842.0,
        APR: 5107.0,
        MAY: 4459.0,
        JUN: 4116.0,
        JUL: 4226.0,
        AUG: 4914.0,
        SEP: 5481.0,
        OCT: 5693.0,
        NOV: 5949.0,
        DEC: 6036.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.150461434175616],
              [-35.199045178706264, -8.050461434175617],
              [-35.099045178706263, -8.050461434175617],
              [-35.099045178706263, -8.150461434175616],
              [-35.199045178706264, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '637',
        ID: 41388.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.1005,
        ANNUAL: 5297.0,
        JAN: 5812.0,
        FEB: 5889.0,
        MAR: 5891.0,
        APR: 5131.0,
        MAY: 4389.0,
        JUN: 4124.0,
        JUL: 4230.0,
        AUG: 4900.0,
        SEP: 5471.0,
        OCT: 5723.0,
        NOV: 5998.0,
        DEC: 6002.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -8.150461434175616],
              [-35.099045178706263, -8.050461434175617],
              [-34.999045178706261, -8.050461434175617],
              [-34.999045178706261, -8.150461434175616],
              [-35.099045178706263, -8.150461434175616],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '638',
        ID: 41389.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -8.1005,
        ANNUAL: 5343.0,
        JAN: 5838.0,
        FEB: 5936.0,
        MAR: 5881.0,
        APR: 5149.0,
        MAY: 4471.0,
        JUN: 4181.0,
        JUL: 4302.0,
        AUG: 5026.0,
        SEP: 5448.0,
        OCT: 5783.0,
        NOV: 6045.0,
        DEC: 6055.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.899355073, -8.133163547999967],
              [-34.899862814999899, -8.134310047999971],
              [-34.900524318999899, -8.135803745999974],
              [-34.900601498, -8.135978019999916],
              [-34.901007869, -8.137292304999962],
              [-34.901332109999899, -8.138340966999984],
              [-34.901798348999897, -8.139303831999944],
              [-34.902701524, -8.141169048999926],
              [-34.903784151, -8.143329827999928],
              [-34.904222718, -8.144205146999971],
              [-34.904237281, -8.144234213999937],
              [-34.904677311999897, -8.145229751999985],
              [-34.905284298, -8.14660301299994],
              [-34.90540764, -8.146891373999958],
              [-34.905874282999903, -8.14798233099998],
              [-34.906754868, -8.150041035999942],
              [-34.906915363345412, -8.150461434175616],
              [-34.999045178706261, -8.150461434175616],
              [-34.999045178706261, -8.050461434175617],
              [-34.89904517870626, -8.050461434175617],
              [-34.89904517870626, -8.132387762961438],
              [-34.899355073, -8.133163547999967],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '639',
        ID: 41390.0,
        COUNTRY: 'Brasil',
        LON: -34.849,
        LAT: -8.1005,
        ANNUAL: 5575.0,
        JAN: 6059.0,
        FEB: 6151.0,
        MAR: 6056.0,
        APR: 5319.0,
        MAY: 4581.0,
        JUN: 4278.0,
        JUL: 4412.0,
        AUG: 5216.0,
        SEP: 5787.0,
        OCT: 6205.0,
        NOV: 6421.0,
        DEC: 6413.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.863801295, -8.051708104999936],
              [-34.865406817, -8.055261540999943],
              [-34.865683235999903, -8.055882256999952],
              [-34.866274953, -8.057210994999936],
              [-34.866637042, -8.058277561999937],
              [-34.867705467, -8.06086372199997],
              [-34.868365642, -8.062695486999928],
              [-34.868703363999899, -8.063191068999965],
              [-34.869370304, -8.064611497999975],
              [-34.869659340999902, -8.065230584999936],
              [-34.8698207099999, -8.065576218999976],
              [-34.87045969, -8.066944842999936],
              [-34.870773044, -8.067786170999975],
              [-34.87115123, -8.068801571999931],
              [-34.871177717, -8.068871626999959],
              [-34.871244261999898, -8.069047630999926],
              [-34.871824472999897, -8.070582239999959],
              [-34.873643463, -8.074701045999966],
              [-34.87471005, -8.077116158999951],
              [-34.875241181, -8.078498696999935],
              [-34.875292296999902, -8.078891778999949],
              [-34.875428457999909, -8.079351183999957],
              [-34.875605532999899, -8.079948630999974],
              [-34.875636514, -8.08000309199997],
              [-34.875702221, -8.080118598999954],
              [-34.87604361, -8.080718730999925],
              [-34.876139221, -8.080947652999956],
              [-34.876437012999901, -8.081660661999923],
              [-34.876488676, -8.081778965999941],
              [-34.876775199, -8.082435074999987],
              [-34.876982026999897, -8.082908689999945],
              [-34.877027645999902, -8.083013152999964],
              [-34.877207687999899, -8.083866574999949],
              [-34.877220719, -8.083928343999965],
              [-34.877257476, -8.084102572999948],
              [-34.877488738, -8.084652002999968],
              [-34.877785710999902, -8.085357540999949],
              [-34.877792627, -8.085379462999974],
              [-34.877962176, -8.085916820999964],
              [-34.878165755999902, -8.086645567999943],
              [-34.878498459, -8.087836526999977],
              [-34.879086276999899, -8.089784220999956],
              [-34.879239169, -8.090290819999955],
              [-34.879328201, -8.090541829999943],
              [-34.879959927, -8.092322874999981],
              [-34.880182727, -8.092951022999953],
              [-34.880352052, -8.093526994999928],
              [-34.880478712, -8.093957836999955],
              [-34.880700520999902, -8.094712336999971],
              [-34.881148878, -8.096237453999947],
              [-34.881182436, -8.096340967999945],
              [-34.881505468999897, -8.097337420999967],
              [-34.881864562, -8.098445107999963],
              [-34.882034833, -8.098818477999938],
              [-34.882675799, -8.100223977999967],
              [-34.8827644859999, -8.100452010999968],
              [-34.883163104999902, -8.101476935999926],
              [-34.8840303489999, -8.102426990999961],
              [-34.884086861999897, -8.102488900999964],
              [-34.884655977, -8.103750433999954],
              [-34.884871876, -8.104229007999946],
              [-34.884986301, -8.104482646999932],
              [-34.886362076, -8.107308468999978],
              [-34.886374208999896, -8.107332800999934],
              [-34.887439508999897, -8.109469057999947],
              [-34.887971093, -8.11053504899996],
              [-34.888814903, -8.112438418999945],
              [-34.889294645999897, -8.113520568999943],
              [-34.890565454, -8.115292288999967],
              [-34.890908082, -8.115861357999956],
              [-34.891878317, -8.11747281999993],
              [-34.891940752, -8.117593050999975],
              [-34.892793658999899, -8.119235497999973],
              [-34.893219686, -8.120055899999956],
              [-34.893710360999897, -8.120832576999934],
              [-34.8944886109999, -8.122064445999973],
              [-34.894533042, -8.122134772999969],
              [-34.895626713999903, -8.124245969999947],
              [-34.895645835, -8.124282879999944],
              [-34.896040862999897, -8.125454317999981],
              [-34.896455500999899, -8.126683908999949],
              [-34.896513625999901, -8.126815661999959],
              [-34.897343641, -8.128697101999924],
              [-34.897667214, -8.129340646999937],
              [-34.898441518, -8.130880640999976],
              [-34.8984494339999, -8.130896383999938],
              [-34.89904517870626, -8.132387762961438],
              [-34.89904517870626, -8.050461434175617],
              [-34.863176955705136, -8.050461434175617],
              [-34.863801295, -8.051708104999936],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '640',
        ID: 41723.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -8.0005,
        ANNUAL: 5756.0,
        JAN: 5901.0,
        FEB: 5633.0,
        MAR: 5716.0,
        APR: 5205.0,
        MAY: 4880.0,
        JUN: 4738.0,
        JUL: 5076.0,
        AUG: 6013.0,
        SEP: 6551.0,
        OCT: 6569.0,
        NOV: 6555.0,
        DEC: 6240.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.582236423, -8.044690611999959],
              [-40.582219379, -8.044691698999948],
              [-40.580467018, -8.040816949999966],
              [-40.575857278999898, -8.030725106999965],
              [-40.575747344, -8.030484430999939],
              [-40.57573996, -8.030468264999966],
              [-40.575697843999897, -8.030374815999947],
              [-40.562316420999906, -8.000683084999928],
              [-40.560663046999899, -7.997286539999952],
              [-40.554120532999903, -7.983846180999927],
              [-40.549450769, -7.975029543999948],
              [-40.544222057, -7.964597073999933],
              [-40.543939168999898, -7.96157204499997],
              [-40.543645575, -7.958432529999932],
              [-40.544011349, -7.953019907999933],
              [-40.54444266816882, -7.950461434175617],
              [-40.499045178706339, -7.950461434175617],
              [-40.499045178706339, -8.050461434175617],
              [-40.584872411853496, -8.050461434175617],
              [-40.582236423, -8.044690611999959],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '641',
        ID: 41724.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -8.0005,
        ANNUAL: 5628.0,
        JAN: 5847.0,
        FEB: 5544.0,
        MAR: 5646.0,
        APR: 5102.0,
        MAY: 4727.0,
        JUN: 4531.0,
        JUL: 4879.0,
        AUG: 5798.0,
        SEP: 6399.0,
        OCT: 6393.0,
        NOV: 6471.0,
        DEC: 6199.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -8.050461434175617],
              [-40.499045178706339, -7.950461434175617],
              [-40.399045178706338, -7.950461434175617],
              [-40.399045178706338, -8.050461434175617],
              [-40.499045178706339, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '642',
        ID: 41725.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -8.0005,
        ANNUAL: 5618.0,
        JAN: 5905.0,
        FEB: 5575.0,
        MAR: 5673.0,
        APR: 5100.0,
        MAY: 4707.0,
        JUN: 4457.0,
        JUL: 4814.0,
        AUG: 5729.0,
        SEP: 6357.0,
        OCT: 6411.0,
        NOV: 6475.0,
        DEC: 6212.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -8.050461434175617],
              [-40.399045178706338, -7.950461434175617],
              [-40.299045178706336, -7.950461434175617],
              [-40.299045178706336, -8.050461434175617],
              [-40.399045178706338, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '643',
        ID: 41726.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -8.0005,
        ANNUAL: 5634.0,
        JAN: 5940.0,
        FEB: 5621.0,
        MAR: 5708.0,
        APR: 5186.0,
        MAY: 4716.0,
        JUN: 4435.0,
        JUL: 4795.0,
        AUG: 5669.0,
        SEP: 6383.0,
        OCT: 6421.0,
        NOV: 6470.0,
        DEC: 6257.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -8.050461434175617],
              [-40.299045178706336, -7.950461434175617],
              [-40.199045178706335, -7.950461434175617],
              [-40.199045178706335, -8.050461434175617],
              [-40.299045178706336, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '644',
        ID: 41727.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -8.0005,
        ANNUAL: 5614.0,
        JAN: 5928.0,
        FEB: 5629.0,
        MAR: 5760.0,
        APR: 5177.0,
        MAY: 4714.0,
        JUN: 4396.0,
        JUL: 4727.0,
        AUG: 5619.0,
        SEP: 6318.0,
        OCT: 6433.0,
        NOV: 6440.0,
        DEC: 6229.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -8.050461434175617],
              [-40.199045178706335, -7.950461434175617],
              [-40.099045178706334, -7.950461434175617],
              [-40.099045178706334, -8.050461434175617],
              [-40.199045178706335, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '645',
        ID: 41728.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -8.0005,
        ANNUAL: 5594.0,
        JAN: 5900.0,
        FEB: 5640.0,
        MAR: 5797.0,
        APR: 5167.0,
        MAY: 4734.0,
        JUN: 4387.0,
        JUL: 4666.0,
        AUG: 5576.0,
        SEP: 6273.0,
        OCT: 6411.0,
        NOV: 6407.0,
        DEC: 6173.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -8.050461434175617],
              [-40.099045178706334, -7.950461434175617],
              [-39.999045178706332, -7.950461434175617],
              [-39.999045178706332, -8.050461434175617],
              [-40.099045178706334, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '646',
        ID: 41729.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -8.0005,
        ANNUAL: 5583.0,
        JAN: 5862.0,
        FEB: 5607.0,
        MAR: 5759.0,
        APR: 5216.0,
        MAY: 4689.0,
        JUN: 4380.0,
        JUL: 4658.0,
        AUG: 5582.0,
        SEP: 6269.0,
        OCT: 6407.0,
        NOV: 6398.0,
        DEC: 6165.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -8.050461434175617],
              [-39.999045178706332, -7.950461434175617],
              [-39.899045178706331, -7.950461434175617],
              [-39.899045178706331, -8.050461434175617],
              [-39.999045178706332, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '647',
        ID: 41730.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -8.0005,
        ANNUAL: 5614.0,
        JAN: 5927.0,
        FEB: 5636.0,
        MAR: 5779.0,
        APR: 5238.0,
        MAY: 4689.0,
        JUN: 4398.0,
        JUL: 4662.0,
        AUG: 5595.0,
        SEP: 6322.0,
        OCT: 6446.0,
        NOV: 6464.0,
        DEC: 6211.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -8.050461434175617],
              [-39.899045178706331, -7.950461434175617],
              [-39.799045178706329, -7.950461434175617],
              [-39.799045178706329, -8.050461434175617],
              [-39.899045178706331, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '648',
        ID: 41731.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -8.0005,
        ANNUAL: 5622.0,
        JAN: 5931.0,
        FEB: 5686.0,
        MAR: 5799.0,
        APR: 5277.0,
        MAY: 4679.0,
        JUN: 4380.0,
        JUL: 4649.0,
        AUG: 5569.0,
        SEP: 6326.0,
        OCT: 6448.0,
        NOV: 6482.0,
        DEC: 6233.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -8.050461434175617],
              [-39.799045178706329, -7.950461434175617],
              [-39.699045178706328, -7.950461434175617],
              [-39.699045178706328, -8.050461434175617],
              [-39.799045178706329, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '649',
        ID: 41732.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -8.0005,
        ANNUAL: 5670.0,
        JAN: 6014.0,
        FEB: 5758.0,
        MAR: 5807.0,
        APR: 5320.0,
        MAY: 4685.0,
        JUN: 4405.0,
        JUL: 4698.0,
        AUG: 5556.0,
        SEP: 6399.0,
        OCT: 6498.0,
        NOV: 6590.0,
        DEC: 6309.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -8.050461434175617],
              [-39.699045178706328, -7.950461434175617],
              [-39.599045178706326, -7.950461434175617],
              [-39.599045178706326, -8.050461434175617],
              [-39.699045178706328, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '650',
        ID: 41733.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -8.0005,
        ANNUAL: 5703.0,
        JAN: 6046.0,
        FEB: 5877.0,
        MAR: 5863.0,
        APR: 5394.0,
        MAY: 4729.0,
        JUN: 4432.0,
        JUL: 4737.0,
        AUG: 5549.0,
        SEP: 6408.0,
        OCT: 6471.0,
        NOV: 6577.0,
        DEC: 6359.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -8.050461434175617],
              [-39.599045178706326, -7.950461434175617],
              [-39.499045178706325, -7.950461434175617],
              [-39.499045178706325, -8.050461434175617],
              [-39.599045178706326, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '651',
        ID: 41734.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -8.0005,
        ANNUAL: 5716.0,
        JAN: 6079.0,
        FEB: 5922.0,
        MAR: 5863.0,
        APR: 5402.0,
        MAY: 4739.0,
        JUN: 4448.0,
        JUL: 4757.0,
        AUG: 5551.0,
        SEP: 6454.0,
        OCT: 6486.0,
        NOV: 6562.0,
        DEC: 6328.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -8.050461434175617],
              [-39.499045178706325, -7.950461434175617],
              [-39.399045178706324, -7.950461434175617],
              [-39.399045178706324, -8.050461434175617],
              [-39.499045178706325, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '652',
        ID: 41735.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -8.0005,
        ANNUAL: 5708.0,
        JAN: 6048.0,
        FEB: 5939.0,
        MAR: 5866.0,
        APR: 5454.0,
        MAY: 4755.0,
        JUN: 4437.0,
        JUL: 4735.0,
        AUG: 5518.0,
        SEP: 6415.0,
        OCT: 6432.0,
        NOV: 6569.0,
        DEC: 6320.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.399045178706324, -8.050461434175617],
              [-39.399045178706324, -7.950461434175617],
              [-39.299045178706322, -7.950461434175617],
              [-39.299045178706322, -8.050461434175617],
              [-39.399045178706324, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '653',
        ID: 41736.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -8.0005,
        ANNUAL: 5700.0,
        JAN: 6018.0,
        FEB: 5931.0,
        MAR: 5835.0,
        APR: 5439.0,
        MAY: 4771.0,
        JUN: 4432.0,
        JUL: 4736.0,
        AUG: 5519.0,
        SEP: 6409.0,
        OCT: 6429.0,
        NOV: 6585.0,
        DEC: 6290.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -8.050461434175617],
              [-39.299045178706322, -7.950461434175617],
              [-39.199045178706321, -7.950461434175617],
              [-39.199045178706321, -8.050461434175617],
              [-39.299045178706322, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '654',
        ID: 41737.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -8.0005,
        ANNUAL: 5710.0,
        JAN: 6034.0,
        FEB: 5919.0,
        MAR: 5849.0,
        APR: 5444.0,
        MAY: 4780.0,
        JUN: 4435.0,
        JUL: 4753.0,
        AUG: 5515.0,
        SEP: 6412.0,
        OCT: 6467.0,
        NOV: 6616.0,
        DEC: 6292.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -8.050461434175617],
              [-39.199045178706321, -7.950461434175617],
              [-39.099045178706319, -7.950461434175617],
              [-39.099045178706319, -8.050461434175617],
              [-39.199045178706321, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '655',
        ID: 41738.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -8.0005,
        ANNUAL: 5714.0,
        JAN: 6020.0,
        FEB: 5928.0,
        MAR: 5883.0,
        APR: 5485.0,
        MAY: 4772.0,
        JUN: 4426.0,
        JUL: 4732.0,
        AUG: 5521.0,
        SEP: 6457.0,
        OCT: 6472.0,
        NOV: 6625.0,
        DEC: 6250.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.099045178706319, -8.050461434175617],
              [-39.099045178706319, -7.950461434175617],
              [-38.999045178706318, -7.950461434175617],
              [-38.999045178706318, -8.050461434175617],
              [-39.099045178706319, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '656',
        ID: 41739.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -8.0005,
        ANNUAL: 5750.0,
        JAN: 6064.0,
        FEB: 5948.0,
        MAR: 5934.0,
        APR: 5512.0,
        MAY: 4830.0,
        JUN: 4482.0,
        JUL: 4766.0,
        AUG: 5552.0,
        SEP: 6469.0,
        OCT: 6488.0,
        NOV: 6672.0,
        DEC: 6286.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -8.050461434175617],
              [-38.999045178706318, -7.950461434175617],
              [-38.899045178706317, -7.950461434175617],
              [-38.899045178706317, -8.050461434175617],
              [-38.999045178706318, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '657',
        ID: 41740.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -8.0005,
        ANNUAL: 5757.0,
        JAN: 6114.0,
        FEB: 5954.0,
        MAR: 5925.0,
        APR: 5512.0,
        MAY: 4864.0,
        JUN: 4500.0,
        JUL: 4756.0,
        AUG: 5573.0,
        SEP: 6445.0,
        OCT: 6496.0,
        NOV: 6646.0,
        DEC: 6301.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -8.050461434175617],
              [-38.899045178706317, -7.950461434175617],
              [-38.799045178706315, -7.950461434175617],
              [-38.799045178706315, -8.050461434175617],
              [-38.899045178706317, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '658',
        ID: 41741.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -8.0005,
        ANNUAL: 5769.0,
        JAN: 6173.0,
        FEB: 5998.0,
        MAR: 5978.0,
        APR: 5547.0,
        MAY: 4842.0,
        JUN: 4468.0,
        JUL: 4729.0,
        AUG: 5531.0,
        SEP: 6448.0,
        OCT: 6507.0,
        NOV: 6665.0,
        DEC: 6348.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -8.050461434175617],
              [-38.799045178706315, -7.950461434175617],
              [-38.699045178706314, -7.950461434175617],
              [-38.699045178706314, -8.050461434175617],
              [-38.799045178706315, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '659',
        ID: 41742.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -8.0005,
        ANNUAL: 5793.0,
        JAN: 6210.0,
        FEB: 6044.0,
        MAR: 6047.0,
        APR: 5609.0,
        MAY: 4849.0,
        JUN: 4444.0,
        JUL: 4723.0,
        AUG: 5528.0,
        SEP: 6456.0,
        OCT: 6527.0,
        NOV: 6686.0,
        DEC: 6394.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -8.050461434175617],
              [-38.699045178706314, -7.950461434175617],
              [-38.599045178706312, -7.950461434175617],
              [-38.599045178706312, -8.050461434175617],
              [-38.699045178706314, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '660',
        ID: 41743.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -8.0005,
        ANNUAL: 5781.0,
        JAN: 6206.0,
        FEB: 6050.0,
        MAR: 6038.0,
        APR: 5596.0,
        MAY: 4844.0,
        JUN: 4412.0,
        JUL: 4671.0,
        AUG: 5479.0,
        SEP: 6435.0,
        OCT: 6548.0,
        NOV: 6697.0,
        DEC: 6396.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -8.050461434175617],
              [-38.599045178706312, -7.950461434175617],
              [-38.499045178706311, -7.950461434175617],
              [-38.499045178706311, -8.050461434175617],
              [-38.599045178706312, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '661',
        ID: 41744.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -8.0005,
        ANNUAL: 5799.0,
        JAN: 6252.0,
        FEB: 6119.0,
        MAR: 6093.0,
        APR: 5636.0,
        MAY: 4837.0,
        JUN: 4395.0,
        JUL: 4655.0,
        AUG: 5473.0,
        SEP: 6405.0,
        OCT: 6563.0,
        NOV: 6746.0,
        DEC: 6413.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -8.050461434175617],
              [-38.499045178706311, -7.950461434175617],
              [-38.399045178706309, -7.950461434175617],
              [-38.399045178706309, -8.050461434175617],
              [-38.499045178706311, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '662',
        ID: 41745.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -8.0005,
        ANNUAL: 5814.0,
        JAN: 6264.0,
        FEB: 6156.0,
        MAR: 6128.0,
        APR: 5645.0,
        MAY: 4837.0,
        JUN: 4390.0,
        JUL: 4651.0,
        AUG: 5493.0,
        SEP: 6413.0,
        OCT: 6577.0,
        NOV: 6773.0,
        DEC: 6441.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -8.050461434175617],
              [-38.399045178706309, -7.950461434175617],
              [-38.299045178706308, -7.950461434175617],
              [-38.299045178706308, -8.050461434175617],
              [-38.399045178706309, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '663',
        ID: 41746.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -8.0005,
        ANNUAL: 5831.0,
        JAN: 6281.0,
        FEB: 6205.0,
        MAR: 6121.0,
        APR: 5706.0,
        MAY: 4850.0,
        JUN: 4408.0,
        JUL: 4658.0,
        AUG: 5513.0,
        SEP: 6419.0,
        OCT: 6592.0,
        NOV: 6770.0,
        DEC: 6453.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -8.050461434175617],
              [-38.299045178706308, -7.950461434175617],
              [-38.199045178706307, -7.950461434175617],
              [-38.199045178706307, -8.050461434175617],
              [-38.299045178706308, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '664',
        ID: 41747.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -8.0005,
        ANNUAL: 5817.0,
        JAN: 6284.0,
        FEB: 6187.0,
        MAR: 6137.0,
        APR: 5678.0,
        MAY: 4847.0,
        JUN: 4381.0,
        JUL: 4648.0,
        AUG: 5479.0,
        SEP: 6397.0,
        OCT: 6565.0,
        NOV: 6752.0,
        DEC: 6443.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -8.050461434175617],
              [-38.199045178706307, -7.950461434175617],
              [-38.099045178706305, -7.950461434175617],
              [-38.099045178706305, -8.050461434175617],
              [-38.199045178706307, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '665',
        ID: 41748.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -8.0005,
        ANNUAL: 5793.0,
        JAN: 6251.0,
        FEB: 6172.0,
        MAR: 6140.0,
        APR: 5639.0,
        MAY: 4815.0,
        JUN: 4345.0,
        JUL: 4617.0,
        AUG: 5444.0,
        SEP: 6374.0,
        OCT: 6555.0,
        NOV: 6715.0,
        DEC: 6444.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -8.050461434175617],
              [-38.099045178706305, -7.950461434175617],
              [-37.999045178706304, -7.950461434175617],
              [-37.999045178706304, -8.050461434175617],
              [-38.099045178706305, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '666',
        ID: 41749.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -8.0005,
        ANNUAL: 5791.0,
        JAN: 6216.0,
        FEB: 6207.0,
        MAR: 6134.0,
        APR: 5689.0,
        MAY: 4835.0,
        JUN: 4394.0,
        JUL: 4633.0,
        AUG: 5425.0,
        SEP: 6349.0,
        OCT: 6520.0,
        NOV: 6688.0,
        DEC: 6405.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -8.050461434175617],
              [-37.999045178706304, -7.950461434175617],
              [-37.899045178706302, -7.950461434175617],
              [-37.899045178706302, -8.050461434175617],
              [-37.999045178706304, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '667',
        ID: 41750.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -8.0005,
        ANNUAL: 5798.0,
        JAN: 6215.0,
        FEB: 6221.0,
        MAR: 6144.0,
        APR: 5692.0,
        MAY: 4864.0,
        JUN: 4403.0,
        JUL: 4638.0,
        AUG: 5440.0,
        SEP: 6361.0,
        OCT: 6509.0,
        NOV: 6717.0,
        DEC: 6376.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -8.050461434175617],
              [-37.899045178706302, -7.950461434175617],
              [-37.799045178706301, -7.950461434175617],
              [-37.799045178706301, -8.050461434175617],
              [-37.899045178706302, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '668',
        ID: 41751.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -8.0005,
        ANNUAL: 5818.0,
        JAN: 6245.0,
        FEB: 6236.0,
        MAR: 6186.0,
        APR: 5746.0,
        MAY: 4900.0,
        JUN: 4410.0,
        JUL: 4647.0,
        AUG: 5458.0,
        SEP: 6354.0,
        OCT: 6516.0,
        NOV: 6727.0,
        DEC: 6388.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -8.050461434175617],
              [-37.799045178706301, -7.950461434175617],
              [-37.699045178706299, -7.950461434175617],
              [-37.699045178706299, -8.050461434175617],
              [-37.799045178706301, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '669',
        ID: 41752.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -8.0005,
        ANNUAL: 5795.0,
        JAN: 6266.0,
        FEB: 6191.0,
        MAR: 6130.0,
        APR: 5755.0,
        MAY: 4888.0,
        JUN: 4383.0,
        JUL: 4613.0,
        AUG: 5426.0,
        SEP: 6315.0,
        OCT: 6481.0,
        NOV: 6709.0,
        DEC: 6378.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -8.050461434175617],
              [-37.699045178706299, -7.950461434175617],
              [-37.599045178706298, -7.950461434175617],
              [-37.599045178706298, -8.050461434175617],
              [-37.699045178706299, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '670',
        ID: 41753.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -8.0005,
        ANNUAL: 5769.0,
        JAN: 6236.0,
        FEB: 6152.0,
        MAR: 6095.0,
        APR: 5701.0,
        MAY: 4854.0,
        JUN: 4374.0,
        JUL: 4574.0,
        AUG: 5395.0,
        SEP: 6311.0,
        OCT: 6457.0,
        NOV: 6711.0,
        DEC: 6372.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -8.050461434175617],
              [-37.599045178706298, -7.950461434175617],
              [-37.499045178706297, -7.950461434175617],
              [-37.499045178706297, -8.050461434175617],
              [-37.599045178706298, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '671',
        ID: 41754.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -8.0005,
        ANNUAL: 5761.0,
        JAN: 6203.0,
        FEB: 6093.0,
        MAR: 6075.0,
        APR: 5688.0,
        MAY: 4855.0,
        JUN: 4371.0,
        JUL: 4554.0,
        AUG: 5397.0,
        SEP: 6290.0,
        OCT: 6461.0,
        NOV: 6744.0,
        DEC: 6406.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -8.050461434175617],
              [-37.499045178706297, -7.950461434175617],
              [-37.399045178706295, -7.950461434175617],
              [-37.399045178706295, -8.050461434175617],
              [-37.499045178706297, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '672',
        ID: 41755.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -8.0005,
        ANNUAL: 5760.0,
        JAN: 6277.0,
        FEB: 6078.0,
        MAR: 6068.0,
        APR: 5626.0,
        MAY: 4839.0,
        JUN: 4358.0,
        JUL: 4519.0,
        AUG: 5341.0,
        SEP: 6278.0,
        OCT: 6484.0,
        NOV: 6791.0,
        DEC: 6467.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.347665691, -7.954361794999949],
              [-37.347923502999897, -7.955411546999925],
              [-37.350175142999902, -7.958287660999932],
              [-37.351174135999898, -7.96098785899994],
              [-37.351286877999904, -7.966470275999939],
              [-37.352010435999901, -7.968522273999951],
              [-37.356179198999897, -7.972730445999963],
              [-37.357145726999903, -7.974003072999956],
              [-37.357368462999901, -7.974955981999932],
              [-37.35683985, -7.975683564999935],
              [-37.355870556999903, -7.975864926999985],
              [-37.353924299, -7.975076754999986],
              [-37.344815512, -7.974959652999932],
              [-37.341284171, -7.974528842999965],
              [-37.340596822999899, -7.975144219999954],
              [-37.340288958, -7.979683276999935],
              [-37.339784629, -7.979804510999934],
              [-37.339782629999902, -7.979803925999922],
              [-37.338528117, -7.97943710299995],
              [-37.337148751, -7.979033772999974],
              [-37.333755287, -7.976291477999956],
              [-37.327170298999903, -7.974503873999938],
              [-37.320535204999899, -7.969669957999948],
              [-37.3169936809999, -7.968065221999947],
              [-37.312426821, -7.967758458999985],
              [-37.309390519, -7.968474031999967],
              [-37.306065139999902, -7.970092072999932],
              [-37.303336805, -7.970212320999951],
              [-37.300818127999896, -7.96619712899997],
              [-37.299045178706294, -7.965493395932554],
              [-37.299045178706294, -8.050461434175617],
              [-37.399045178706295, -8.050461434175617],
              [-37.399045178706295, -7.950461434175617],
              [-37.349625921573804, -7.950461434175617],
              [-37.347665691, -7.954361794999949],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '673',
        ID: 41756.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -8.0005,
        ANNUAL: 5726.0,
        JAN: 6245.0,
        FEB: 6049.0,
        MAR: 6048.0,
        APR: 5616.0,
        MAY: 4812.0,
        JUN: 4321.0,
        JUL: 4470.0,
        AUG: 5267.0,
        SEP: 6221.0,
        OCT: 6453.0,
        NOV: 6760.0,
        DEC: 6445.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.298379392999898, -7.965229126999938],
              [-37.295575481, -7.96492095499997],
              [-37.291779346999903, -7.965310854999927],
              [-37.285003488, -7.970389609999923],
              [-37.284005319999899, -7.970793862999924],
              [-37.281724708, -7.970801072999965],
              [-37.277402462999902, -7.971978796999965],
              [-37.275727044, -7.971647911999979],
              [-37.2672279129999, -7.969969383999967],
              [-37.266751593, -7.96961543199995],
              [-37.265564777999899, -7.968733511999969],
              [-37.263535460999897, -7.968177322999963],
              [-37.257974686999901, -7.970445111999942],
              [-37.255674602999903, -7.970110521999977],
              [-37.249982549, -7.969282538999951],
              [-37.246983115, -7.969356329999927],
              [-37.244584034, -7.971240307999943],
              [-37.241112805999897, -7.972777847999956],
              [-37.237341936999897, -7.973306824999952],
              [-37.235059095999901, -7.972750293999977],
              [-37.230761249999901, -7.969397110999976],
              [-37.229898240999901, -7.969849701999972],
              [-37.229686455, -7.972218638999955],
              [-37.228783823, -7.972594236999954],
              [-37.228781911999903, -7.972593795999957],
              [-37.227451926, -7.972286968999986],
              [-37.225665797, -7.97187490999993],
              [-37.2212644909999, -7.972837729999973],
              [-37.219588775, -7.972793434999972],
              [-37.21951153899991, -7.972728012999946],
              [-37.218835734, -7.972155579999932],
              [-37.218394857, -7.970740698999981],
              [-37.221160973999901, -7.964973249999965],
              [-37.221516962, -7.963452138999973],
              [-37.221437178, -7.962408968999971],
              [-37.2208941069999, -7.959855431999934],
              [-37.217279418, -7.956390142999965],
              [-37.217277501, -7.956388304999964],
              [-37.215374552999897, -7.955752837999973],
              [-37.214246848999899, -7.95537625499998],
              [-37.212871187, -7.954916868999923],
              [-37.2128684629999, -7.954915189999943],
              [-37.211459084999902, -7.954046744999969],
              [-37.208078998, -7.951963968999959],
              [-37.20791994, -7.951866132999944],
              [-37.207460655, -7.952157828999928],
              [-37.207393211, -7.952200662999926],
              [-37.207107377999897, -7.952382466999959],
              [-37.206948901, -7.952483265999938],
              [-37.206925251, -7.952498307999974],
              [-37.206900404, -7.952514111999966],
              [-37.2066218309999, -7.952691297999932],
              [-37.205696928999899, -7.953279579999967],
              [-37.205019858999897, -7.953710228999975],
              [-37.203591980999903, -7.955726962999963],
              [-37.202762467999897, -7.956243394999949],
              [-37.200757635999899, -7.957274965999944],
              [-37.199045178706292, -7.957811761821915],
              [-37.199045178706292, -8.050461434175617],
              [-37.299045178706294, -8.050461434175617],
              [-37.299045178706294, -7.965493395932554],
              [-37.298379392999898, -7.965229126999938],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '674',
        ID: 41757.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -8.0005,
        ANNUAL: 5714.0,
        JAN: 6226.0,
        FEB: 6043.0,
        MAR: 6046.0,
        APR: 5601.0,
        MAY: 4794.0,
        JUN: 4327.0,
        JUL: 4474.0,
        AUG: 5230.0,
        SEP: 6215.0,
        OCT: 6432.0,
        NOV: 6754.0,
        DEC: 6428.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.194201113, -7.95933020799993],
              [-37.192675587999901, -7.960598180999973],
              [-37.191712756, -7.964876708999951],
              [-37.191572977, -7.968402509999973],
              [-37.192362204999903, -7.970231828999938],
              [-37.1925666449999, -7.977434979999941],
              [-37.192589168, -7.978228538999925],
              [-37.193394607999899, -7.98286672899997],
              [-37.192755785, -7.983572739999945],
              [-37.1905629359999, -7.982980332999933],
              [-37.189560152, -7.982709495999924],
              [-37.188275256999901, -7.98295884899994],
              [-37.187514913, -7.987545450999955],
              [-37.185469199999901, -7.990444739999925],
              [-37.185353215, -7.990612083999967],
              [-37.18415089, -7.991289095999944],
              [-37.184149678, -7.991288686999948],
              [-37.179980239, -7.989880889999948],
              [-37.178392215, -7.990586013999972],
              [-37.177683596, -7.991093904999953],
              [-37.176996252, -7.992217166999969],
              [-37.176141351999902, -7.99647515799995],
              [-37.175309953, -7.996858439999924],
              [-37.17530857500001, -7.996858168999945],
              [-37.167757193999897, -7.99537248299993],
              [-37.163198579, -7.996081221999924],
              [-37.160418939, -7.997152917999931],
              [-37.1589449169999, -7.998801165999966],
              [-37.157703382, -8.000189446999965],
              [-37.155015580999901, -8.006412212999985],
              [-37.152022611999897, -8.009025394999988],
              [-37.151794607999904, -8.009414826999938],
              [-37.150337825, -8.01526376899994],
              [-37.149653976, -8.022814703999929],
              [-37.149570341999897, -8.023738172999968],
              [-37.149532495, -8.024105688999951],
              [-37.148398719999904, -8.035115384999933],
              [-37.153651306, -8.045269096999952],
              [-37.157523491691592, -8.050461434175617],
              [-37.199045178706292, -8.050461434175617],
              [-37.199045178706292, -7.957811761821915],
              [-37.194201113, -7.95933020799993],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '675',
        ID: 41762.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -8.0005,
        ANNUAL: 5690.0,
        JAN: 6177.0,
        FEB: 6084.0,
        MAR: 6057.0,
        APR: 5591.0,
        MAY: 4789.0,
        JUN: 4328.0,
        JUL: 4515.0,
        AUG: 5270.0,
        SEP: 6101.0,
        OCT: 6353.0,
        NOV: 6617.0,
        DEC: 6402.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.628259182, -8.046860218999946],
              [-36.629144041000011, -8.038351217999946],
              [-36.62933092, -8.028159709999954],
              [-36.629265239999903, -8.02544995299996],
              [-36.628197826, -8.014070096999932],
              [-36.627972459, -8.012171811999959],
              [-36.627970535, -8.012155607999947],
              [-36.627163738, -8.005360344999932],
              [-36.626545198, -8.002704563999943],
              [-36.627851495999899, -7.986406648999954],
              [-36.6274279739999, -7.985916955999939],
              [-36.623171942999903, -7.982081468999985],
              [-36.622976508, -7.981169849999953],
              [-36.622534356, -7.980357394999935],
              [-36.62333418, -7.976144241999974],
              [-36.622654178999902, -7.969508388999935],
              [-36.622457391999902, -7.969232135999963],
              [-36.621443625, -7.966010139999975],
              [-36.619413639, -7.961542442999936],
              [-36.618492908, -7.961001700999928],
              [-36.61796906, -7.960871144999937],
              [-36.617371314, -7.960722170999923],
              [-36.613463453, -7.956516951999964],
              [-36.612950283, -7.954830055999925],
              [-36.612601460203628, -7.950461434175617],
              [-36.606648618162183, -7.950461434175617],
              [-36.604524335999898, -7.952388185999953],
              [-36.60146822, -7.952535517999936],
              [-36.600426802999898, -7.952585722999963],
              [-36.599622704, -7.952894525999964],
              [-36.599045178706284, -7.953276931965095],
              [-36.599045178706284, -8.050461434175617],
              [-36.627884688837177, -8.050461434175617],
              [-36.628259182, -8.046860218999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '676',
        ID: 41763.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -8.0005,
        ANNUAL: 5593.0,
        JAN: 6089.0,
        FEB: 5957.0,
        MAR: 6000.0,
        APR: 5544.0,
        MAY: 4736.0,
        JUN: 4272.0,
        JUL: 4418.0,
        AUG: 5153.0,
        SEP: 5984.0,
        OCT: 6219.0,
        NOV: 6478.0,
        DEC: 6272.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.595051130999899, -7.955921573999934],
              [-36.593144223, -7.956685524999948],
              [-36.586860959, -7.958656057999965],
              [-36.581078085999899, -7.959786177999931],
              [-36.5767803009999, -7.959645582999938],
              [-36.573482333999898, -7.957434271999936],
              [-36.573208292999901, -7.955951173999945],
              [-36.572859319, -7.954062529999927],
              [-36.574187857, -7.951674401999983],
              [-36.5758230879999, -7.95072829699996],
              [-36.576617542641195, -7.950461434175617],
              [-36.499045178706282, -7.950461434175617],
              [-36.499045178706282, -8.050461434175617],
              [-36.599045178706284, -8.050461434175617],
              [-36.599045178706284, -7.953276931965095],
              [-36.595051130999899, -7.955921573999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '677',
        ID: 41764.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -8.0005,
        ANNUAL: 5515.0,
        JAN: 6018.0,
        FEB: 5898.0,
        MAR: 5905.0,
        APR: 5489.0,
        MAY: 4668.0,
        JUN: 4254.0,
        JUL: 4378.0,
        AUG: 5082.0,
        SEP: 5842.0,
        OCT: 6125.0,
        NOV: 6362.0,
        DEC: 6159.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.499045178706282, -8.050461434175617],
              [-36.499045178706282, -7.950461434175617],
              [-36.399045178706281, -7.950461434175617],
              [-36.399045178706281, -8.050461434175617],
              [-36.499045178706282, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '678',
        ID: 41765.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -8.0005,
        ANNUAL: 5474.0,
        JAN: 5988.0,
        FEB: 5882.0,
        MAR: 5878.0,
        APR: 5445.0,
        MAY: 4642.0,
        JUN: 4196.0,
        JUL: 4327.0,
        AUG: 5035.0,
        SEP: 5765.0,
        OCT: 6060.0,
        NOV: 6350.0,
        DEC: 6119.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -8.050461434175617],
              [-36.399045178706281, -7.950461434175617],
              [-36.29904517870628, -7.950461434175617],
              [-36.29904517870628, -8.050461434175617],
              [-36.399045178706281, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '679',
        ID: 41766.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -8.0005,
        ANNUAL: 5408.0,
        JAN: 5914.0,
        FEB: 5930.0,
        MAR: 5832.0,
        APR: 5332.0,
        MAY: 4605.0,
        JUN: 4116.0,
        JUL: 4228.0,
        AUG: 4915.0,
        SEP: 5684.0,
        OCT: 5983.0,
        NOV: 6276.0,
        DEC: 6085.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -8.050461434175617],
              [-36.29904517870628, -7.950461434175617],
              [-36.199045178706278, -7.950461434175617],
              [-36.199045178706278, -8.050461434175617],
              [-36.29904517870628, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '680',
        ID: 41767.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -8.0005,
        ANNUAL: 5376.0,
        JAN: 5877.0,
        FEB: 5875.0,
        MAR: 5869.0,
        APR: 5353.0,
        MAY: 4587.0,
        JUN: 4088.0,
        JUL: 4199.0,
        AUG: 4869.0,
        SEP: 5584.0,
        OCT: 5927.0,
        NOV: 6222.0,
        DEC: 6060.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -8.050461434175617],
              [-36.199045178706278, -7.950461434175617],
              [-36.099045178706277, -7.950461434175617],
              [-36.099045178706277, -8.050461434175617],
              [-36.199045178706278, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '681',
        ID: 41768.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -8.0005,
        ANNUAL: 5335.0,
        JAN: 5851.0,
        FEB: 5807.0,
        MAR: 5869.0,
        APR: 5328.0,
        MAY: 4543.0,
        JUN: 4028.0,
        JUL: 4127.0,
        AUG: 4798.0,
        SEP: 5509.0,
        OCT: 5872.0,
        NOV: 6216.0,
        DEC: 6070.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -8.050461434175617],
              [-36.099045178706277, -7.950461434175617],
              [-35.999045178706275, -7.950461434175617],
              [-35.999045178706275, -8.050461434175617],
              [-36.099045178706277, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '682',
        ID: 41769.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -8.0005,
        ANNUAL: 5238.0,
        JAN: 5675.0,
        FEB: 5716.0,
        MAR: 5753.0,
        APR: 5168.0,
        MAY: 4483.0,
        JUN: 3965.0,
        JUL: 4129.0,
        AUG: 4737.0,
        SEP: 5397.0,
        OCT: 5750.0,
        NOV: 6114.0,
        DEC: 5967.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -8.050461434175617],
              [-35.999045178706275, -7.950461434175617],
              [-35.899045178706274, -7.950461434175617],
              [-35.899045178706274, -8.050461434175617],
              [-35.999045178706275, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '683',
        ID: 41770.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -8.0005,
        ANNUAL: 5262.0,
        JAN: 5748.0,
        FEB: 5744.0,
        MAR: 5793.0,
        APR: 5141.0,
        MAY: 4529.0,
        JUN: 4020.0,
        JUL: 4191.0,
        AUG: 4762.0,
        SEP: 5392.0,
        OCT: 5755.0,
        NOV: 6108.0,
        DEC: 5962.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -8.050461434175617],
              [-35.899045178706274, -7.950461434175617],
              [-35.799045178706272, -7.950461434175617],
              [-35.799045178706272, -8.050461434175617],
              [-35.899045178706274, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '684',
        ID: 41771.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -8.0005,
        ANNUAL: 5265.0,
        JAN: 5771.0,
        FEB: 5766.0,
        MAR: 5791.0,
        APR: 5164.0,
        MAY: 4530.0,
        JUN: 4034.0,
        JUL: 4204.0,
        AUG: 4776.0,
        SEP: 5407.0,
        OCT: 5727.0,
        NOV: 6071.0,
        DEC: 5935.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -8.050461434175617],
              [-35.799045178706272, -7.950461434175617],
              [-35.699045178706271, -7.950461434175617],
              [-35.699045178706271, -8.050461434175617],
              [-35.799045178706272, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '685',
        ID: 41772.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -8.0005,
        ANNUAL: 5206.0,
        JAN: 5703.0,
        FEB: 5668.0,
        MAR: 5710.0,
        APR: 5123.0,
        MAY: 4428.0,
        JUN: 4032.0,
        JUL: 4129.0,
        AUG: 4771.0,
        SEP: 5416.0,
        OCT: 5649.0,
        NOV: 5926.0,
        DEC: 5916.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -8.050461434175617],
              [-35.699045178706271, -7.950461434175617],
              [-35.59904517870627, -7.950461434175617],
              [-35.59904517870627, -8.050461434175617],
              [-35.699045178706271, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '686',
        ID: 41773.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -8.0005,
        ANNUAL: 5191.0,
        JAN: 5678.0,
        FEB: 5634.0,
        MAR: 5667.0,
        APR: 5092.0,
        MAY: 4396.0,
        JUN: 4075.0,
        JUL: 4190.0,
        AUG: 4796.0,
        SEP: 5408.0,
        OCT: 5616.0,
        NOV: 5886.0,
        DEC: 5852.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -8.050461434175617],
              [-35.59904517870627, -7.950461434175617],
              [-35.499045178706268, -7.950461434175617],
              [-35.499045178706268, -8.050461434175617],
              [-35.59904517870627, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '687',
        ID: 41774.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -8.0005,
        ANNUAL: 5227.0,
        JAN: 5701.0,
        FEB: 5684.0,
        MAR: 5665.0,
        APR: 5094.0,
        MAY: 4405.0,
        JUN: 4113.0,
        JUL: 4243.0,
        AUG: 4868.0,
        SEP: 5459.0,
        OCT: 5679.0,
        NOV: 5923.0,
        DEC: 5895.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -8.050461434175617],
              [-35.499045178706268, -7.950461434175617],
              [-35.399045178706267, -7.950461434175617],
              [-35.399045178706267, -8.050461434175617],
              [-35.499045178706268, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '688',
        ID: 41775.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -8.0005,
        ANNUAL: 5293.0,
        JAN: 5745.0,
        FEB: 5795.0,
        MAR: 5783.0,
        APR: 5147.0,
        MAY: 4463.0,
        JUN: 4120.0,
        JUL: 4251.0,
        AUG: 4918.0,
        SEP: 5546.0,
        OCT: 5752.0,
        NOV: 5992.0,
        DEC: 6010.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -8.050461434175617],
              [-35.399045178706267, -7.950461434175617],
              [-35.299045178706265, -7.950461434175617],
              [-35.299045178706265, -8.050461434175617],
              [-35.399045178706267, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '689',
        ID: 41776.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -8.0005,
        ANNUAL: 5326.0,
        JAN: 5781.0,
        FEB: 5841.0,
        MAR: 5832.0,
        APR: 5179.0,
        MAY: 4489.0,
        JUN: 4146.0,
        JUL: 4274.0,
        AUG: 4959.0,
        SEP: 5567.0,
        OCT: 5790.0,
        NOV: 6007.0,
        DEC: 6050.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -8.050461434175617],
              [-35.299045178706265, -7.950461434175617],
              [-35.199045178706264, -7.950461434175617],
              [-35.199045178706264, -8.050461434175617],
              [-35.299045178706265, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '690',
        ID: 41777.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -8.0005,
        ANNUAL: 5330.0,
        JAN: 5797.0,
        FEB: 5869.0,
        MAR: 5858.0,
        APR: 5172.0,
        MAY: 4475.0,
        JUN: 4156.0,
        JUL: 4265.0,
        AUG: 4984.0,
        SEP: 5557.0,
        OCT: 5754.0,
        NOV: 6012.0,
        DEC: 6060.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -8.050461434175617],
              [-35.199045178706264, -7.950461434175617],
              [-35.099045178706263, -7.950461434175617],
              [-35.099045178706263, -8.050461434175617],
              [-35.199045178706264, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '691',
        ID: 41778.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -8.0005,
        ANNUAL: 5318.0,
        JAN: 5802.0,
        FEB: 5911.0,
        MAR: 5876.0,
        APR: 5159.0,
        MAY: 4429.0,
        JUN: 4137.0,
        JUL: 4250.0,
        AUG: 4945.0,
        SEP: 5480.0,
        OCT: 5754.0,
        NOV: 6024.0,
        DEC: 6050.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -8.050461434175617],
              [-35.099045178706263, -7.950461434175617],
              [-34.999045178706261, -7.950461434175617],
              [-34.999045178706261, -8.050461434175617],
              [-35.099045178706263, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '692',
        ID: 41779.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -8.0005,
        ANNUAL: 5284.0,
        JAN: 5731.0,
        FEB: 5880.0,
        MAR: 5805.0,
        APR: 5148.0,
        MAY: 4438.0,
        JUN: 4138.0,
        JUL: 4263.0,
        AUG: 4976.0,
        SEP: 5375.0,
        OCT: 5722.0,
        NOV: 5956.0,
        DEC: 5975.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.999045178706261, -8.050461434175617],
              [-34.999045178706261, -7.950461434175617],
              [-34.89904517870626, -7.950461434175617],
              [-34.89904517870626, -8.050461434175617],
              [-34.999045178706261, -8.050461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '693',
        ID: 41780.0,
        COUNTRY: 'Brasil',
        LON: -34.849,
        LAT: -8.0005,
        ANNUAL: 5462.0,
        JAN: 5886.0,
        FEB: 6014.0,
        MAR: 5930.0,
        APR: 5251.0,
        MAY: 4538.0,
        JUN: 4246.0,
        JUL: 4397.0,
        AUG: 5169.0,
        SEP: 5659.0,
        OCT: 6044.0,
        NOV: 6199.0,
        DEC: 6204.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.825379751999897, -7.950549333999964],
              [-34.825972947, -7.951389766999974],
              [-34.826381946, -7.951299366999928],
              [-34.826592623, -7.951368981999969],
              [-34.826729864, -7.951476517999938],
              [-34.826863905, -7.951615668999983],
              [-34.82686854, -7.951622260999946],
              [-34.826937299, -7.95172002499994],
              [-34.826966004, -7.951811721999946],
              [-34.82699149, -7.951998267999957],
              [-34.82698636, -7.952040195999925],
              [-34.826956292, -7.952285970999979],
              [-34.826898789, -7.952428229999943],
              [-34.827071141, -7.952557906999961],
              [-34.827132855999899, -7.952559847999961],
              [-34.827176672999897, -7.952561225999935],
              [-34.827310003, -7.952657196999947],
              [-34.827412900999903, -7.952695403999936],
              [-34.827507739999902, -7.952729513999936],
              [-34.827518982, -7.952736661999946],
              [-34.827739462999901, -7.952864280999957],
              [-34.828012715999897, -7.95317008299997],
              [-34.82832308, -7.953794964999934],
              [-34.8284555, -7.954374396999975],
              [-34.828540388, -7.954992673999923],
              [-34.828510955999903, -7.955822888999925],
              [-34.828421928999902, -7.957211042999963],
              [-34.828243068999903, -7.958133309999937],
              [-34.828168858, -7.958406901999973],
              [-34.828052602, -7.958594625999977],
              [-34.827944715999898, -7.958705234999973],
              [-34.827964941999902, -7.959113361999936],
              [-34.827977991999902, -7.959376692999975],
              [-34.828062336, -7.959514546999966],
              [-34.828169064999898, -7.959603914999953],
              [-34.828377095999898, -7.959737980999932],
              [-34.828524414999897, -7.959926619999973],
              [-34.828741701999903, -7.96022783999995],
              [-34.828995968999898, -7.96056691399997],
              [-34.829218050999899, -7.960885632999974],
              [-34.829330421, -7.961228919999945],
              [-34.829532688, -7.962132039999972],
              [-34.829611481, -7.962350147999929],
              [-34.829829124, -7.962755518999939],
              [-34.830051496, -7.963066714999977],
              [-34.830205474, -7.963441623999925],
              [-34.830240557, -7.96359152499997],
              [-34.8304580779999, -7.964520931999973],
              [-34.830686344, -7.965760541999932],
              [-34.830866263, -7.96677644599994],
              [-34.8310229269999, -7.967605594999941],
              [-34.831002379, -7.968455783999961],
              [-34.831115164, -7.96925112499996],
              [-34.831119805, -7.969274974999951],
              [-34.831296165, -7.970181180999922],
              [-34.831654993, -7.971525851999954],
              [-34.831843831999898, -7.972061664999956],
              [-34.8319796769999, -7.972603796999987],
              [-34.831982176999901, -7.972611004999979],
              [-34.832169349, -7.973150644999984],
              [-34.832365308, -7.973516516999942],
              [-34.832482523, -7.973884150999938],
              [-34.832650047999898, -7.974169304999986],
              [-34.832918010999897, -7.974438023999947],
              [-34.832920660999903, -7.97444507099993],
              [-34.833069969, -7.97467407599992],
              [-34.833295209, -7.974863827999965],
              [-34.833498263, -7.975522504999964],
              [-34.833795359, -7.976485702999969],
              [-34.833958819, -7.977234755999976],
              [-34.834162553999903, -7.978092062999963],
              [-34.834229440999898, -7.978994047999953],
              [-34.834353268, -7.97984783499993],
              [-34.834596616999903, -7.980815256999959],
              [-34.834915022, -7.981507493999968],
              [-34.834920312, -7.98151899599998],
              [-34.835336953, -7.982439486999975],
              [-34.8356333629999, -7.983221429999962],
              [-34.835635581, -7.983235478999969],
              [-34.835725445, -7.98380452799995],
              [-34.835924632999898, -7.984325737999936],
              [-34.836252563999899, -7.984686904999922],
              [-34.836494586, -7.985007065999967],
              [-34.837107884, -7.985818371999935],
              [-34.837218135999898, -7.986277539999956],
              [-34.837376412, -7.986690403999934],
              [-34.837728343, -7.987608420999946],
              [-34.837946173, -7.98864544199995],
              [-34.8381184039999, -7.989400177999922],
              [-34.838294781999899, -7.990057393999956],
              [-34.838348077, -7.990289695999925],
              [-34.838447781, -7.990724286999975],
              [-34.838518534999899, -7.99141692399996],
              [-34.838424529, -7.992196403999971],
              [-34.838363555999898, -7.992931002999969],
              [-34.838082941, -7.993479041999921],
              [-34.837902964, -7.993769612999936],
              [-34.837426391999898, -7.99437132399993],
              [-34.837182345, -7.994943391999935],
              [-34.837071911, -7.995247879999974],
              [-34.83704032, -7.995334981999975],
              [-34.836939281, -7.995531970999936],
              [-34.837067684, -7.995779034999943],
              [-34.837170039999897, -7.995883560999959],
              [-34.837269216, -7.995984838999965],
              [-34.837348452999905, -7.996072674999938],
              [-34.837457148999903, -7.996193164999979],
              [-34.837616553, -7.996431721999955],
              [-34.837726198, -7.996599846999968],
              [-34.837910743999899, -7.996882823999958],
              [-34.838097988, -7.997416099999943],
              [-34.838479795, -7.998416664999985],
              [-34.838559992999897, -7.998800715999941],
              [-34.8386016279999, -7.999264233999948],
              [-34.838644135, -7.999728536999954],
              [-34.838706407, -8.000235821999977],
              [-34.838774819, -8.000572941999968],
              [-34.838895083999901, -8.001165586999948],
              [-34.838895453, -8.001573066999971],
              [-34.839169816, -8.002564888999935],
              [-34.839327935, -8.003579158999962],
              [-34.839472167999901, -8.003980267999964],
              [-34.839567487999901, -8.004394232999966],
              [-34.839836357, -8.005602024999968],
              [-34.839975146, -8.006225480999937],
              [-34.840235204, -8.007116032999923],
              [-34.840357912999899, -8.007978391999934],
              [-34.840374187, -8.008092754999943],
              [-34.8404309229999, -8.00819188499997],
              [-34.840497695, -8.008308547999954],
              [-34.84078081, -8.008803199999932],
              [-34.841047777, -8.009196065999951],
              [-34.841423242, -8.010067401999947],
              [-34.841471575999897, -8.010366689999955],
              [-34.841752254, -8.010964367999977],
              [-34.84186263, -8.011366158999971],
              [-34.841978133999902, -8.011787105999932],
              [-34.842151951999902, -8.012151321999978],
              [-34.842354314, -8.012533568999965],
              [-34.842511346, -8.012636066999967],
              [-34.842682034999903, -8.012800562999939],
              [-34.842829331, -8.012992839999978],
              [-34.842842819999902, -8.01300941299996],
              [-34.843013847999899, -8.013264767999935],
              [-34.843076558, -8.013419255999965],
              [-34.843122090999898, -8.013531425999926],
              [-34.843189697, -8.013538624999976],
              [-34.843191976, -8.013538867999964],
              [-34.843333683, -8.01355395999996],
              [-34.843364070999897, -8.013602602999981],
              [-34.843452909, -8.013723057999927],
              [-34.843457405, -8.013728581999937],
              [-34.843542629, -8.013833290999974],
              [-34.843547327, -8.013839063999967],
              [-34.843699113, -8.014036859999974],
              [-34.843805233, -8.014138976999956],
              [-34.843951567, -8.014174383999942],
              [-34.8439542039999, -8.014175021999955],
              [-34.844118286, -8.014212676999932],
              [-34.844228739, -8.014274892999936],
              [-34.844324409, -8.014426354999966],
              [-34.844342936, -8.014443463999946],
              [-34.844494729, -8.01464127099997],
              [-34.844756269999898, -8.014860853999958],
              [-34.844774255999901, -8.014882951999944],
              [-34.845214609, -8.015192208999954],
              [-34.84640803, -8.016506385999941],
              [-34.84633586, -8.016578064999987],
              [-34.84612371, -8.016788773999961],
              [-34.845952376999897, -8.017210068999928],
              [-34.846065129, -8.017395249999947],
              [-34.846298894, -8.01744528999995],
              [-34.846539118, -8.017110482999945],
              [-34.846749958, -8.017072696999945],
              [-34.846905112999899, -8.017044890999943],
              [-34.847109019, -8.017169522999957],
              [-34.847290936, -8.017574245999981],
              [-34.847641656, -8.018005142999924],
              [-34.8482779019999, -8.018755179999964],
              [-34.8483154749999, -8.018799471999952],
              [-34.848794047999903, -8.01946097199993],
              [-34.849017564, -8.019842995999973],
              [-34.849119641, -8.020217135999927],
              [-34.84913313, -8.020233707999981],
              [-34.849145391, -8.020262089999951],
              [-34.849226804999901, -8.020450553999973],
              [-34.84923076, -8.020461065999948],
              [-34.849356493, -8.020570315999981],
              [-34.849393032, -8.020584066999966],
              [-34.849562112999898, -8.020647696999964],
              [-34.849728679, -8.020756241999949],
              [-34.8498358269999, -8.020878902999929],
              [-34.849927154999897, -8.021035791999955],
              [-34.850170851, -8.021454428999954],
              [-34.850757713999904, -8.02214193899993],
              [-34.850916362, -8.022327796999949],
              [-34.8511693, -8.022485895999985],
              [-34.851321520999903, -8.02242121699993],
              [-34.851340256, -8.022413256999924],
              [-34.851620958, -8.022362397999929],
              [-34.852597607, -8.022784210999937],
              [-34.853075831, -8.02283948299992],
              [-34.853146194, -8.022847615999973],
              [-34.854215182, -8.023336291999952],
              [-34.857072466, -8.025718793999944],
              [-34.85882398, -8.027824693999959],
              [-34.859231544, -8.028314719999964],
              [-34.860946349999899, -8.031275083999965],
              [-34.862354126999897, -8.034131485999971],
              [-34.863308224, -8.037095266999934],
              [-34.863722164, -8.038759735999967],
              [-34.863769719999901, -8.038950964999971],
              [-34.86390764, -8.039505545999923],
              [-34.864086449, -8.040675124999943],
              [-34.863925224999903, -8.040794964999989],
              [-34.861057414999898, -8.043555157999947],
              [-34.860549246999902, -8.044457174999938],
              [-34.860506439999902, -8.044533160999944],
              [-34.859002808, -8.047202162999943],
              [-34.861497753, -8.047810745999925],
              [-34.862235940999902, -8.048467415999935],
              [-34.863001188999903, -8.05011046599998],
              [-34.863176955705136, -8.050461434175617],
              [-34.89904517870626, -8.050461434175617],
              [-34.89904517870626, -7.950461434175617],
              [-34.825432523419053, -7.950461434175617],
              [-34.825379751999897, -7.950549333999964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '694',
        ID: 42113.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -7.9005,
        ANNUAL: 5805.0,
        JAN: 5975.0,
        FEB: 5686.0,
        MAR: 5813.0,
        APR: 5298.0,
        MAY: 4912.0,
        JUN: 4748.0,
        JUL: 5103.0,
        AUG: 6031.0,
        SEP: 6575.0,
        OCT: 6603.0,
        NOV: 6570.0,
        DEC: 6342.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.54547329199989, -7.944348039999965],
              [-40.545506062999891, -7.943788374999936],
              [-40.545908144999899, -7.936921642999954],
              [-40.543669759, -7.911674549999932],
              [-40.541475009, -7.886933345999985],
              [-40.538354782999903, -7.873890038999941],
              [-40.53794296, -7.869194733999961],
              [-40.538015184, -7.864311131999955],
              [-40.538060684999905, -7.861229874999937],
              [-40.538124011, -7.856937304999975],
              [-40.539505544523486, -7.850461434175617],
              [-40.499045178706339, -7.850461434175617],
              [-40.499045178706339, -7.950461434175617],
              [-40.54444266816882, -7.950461434175617],
              [-40.54547329199989, -7.944348039999965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '695',
        ID: 42114.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -7.9005,
        ANNUAL: 5684.0,
        JAN: 5910.0,
        FEB: 5607.0,
        MAR: 5738.0,
        APR: 5196.0,
        MAY: 4781.0,
        JUN: 4592.0,
        JUL: 4936.0,
        AUG: 5846.0,
        SEP: 6413.0,
        OCT: 6439.0,
        NOV: 6497.0,
        DEC: 6258.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -7.950461434175617],
              [-40.499045178706339, -7.850461434175617],
              [-40.399045178706338, -7.850461434175617],
              [-40.399045178706338, -7.950461434175617],
              [-40.499045178706339, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '696',
        ID: 42115.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -7.9005,
        ANNUAL: 5633.0,
        JAN: 5904.0,
        FEB: 5542.0,
        MAR: 5688.0,
        APR: 5117.0,
        MAY: 4732.0,
        JUN: 4493.0,
        JUL: 4847.0,
        AUG: 5756.0,
        SEP: 6385.0,
        OCT: 6436.0,
        NOV: 6486.0,
        DEC: 6213.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -7.950461434175617],
              [-40.399045178706338, -7.850461434175617],
              [-40.299045178706336, -7.850461434175617],
              [-40.299045178706336, -7.950461434175617],
              [-40.399045178706338, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '697',
        ID: 42116.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -7.9005,
        ANNUAL: 5639.0,
        JAN: 5900.0,
        FEB: 5620.0,
        MAR: 5727.0,
        APR: 5178.0,
        MAY: 4749.0,
        JUN: 4459.0,
        JUL: 4748.0,
        AUG: 5701.0,
        SEP: 6367.0,
        OCT: 6451.0,
        NOV: 6474.0,
        DEC: 6288.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -7.950461434175617],
              [-40.299045178706336, -7.850461434175617],
              [-40.199045178706335, -7.850461434175617],
              [-40.199045178706335, -7.950461434175617],
              [-40.299045178706336, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '698',
        ID: 42117.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -7.9005,
        ANNUAL: 5616.0,
        JAN: 5919.0,
        FEB: 5626.0,
        MAR: 5768.0,
        APR: 5191.0,
        MAY: 4730.0,
        JUN: 4395.0,
        JUL: 4680.0,
        AUG: 5623.0,
        SEP: 6284.0,
        OCT: 6432.0,
        NOV: 6471.0,
        DEC: 6277.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -7.950461434175617],
              [-40.199045178706335, -7.850461434175617],
              [-40.099045178706334, -7.850461434175617],
              [-40.099045178706334, -7.950461434175617],
              [-40.199045178706335, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '699',
        ID: 42118.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -7.9005,
        ANNUAL: 5592.0,
        JAN: 5924.0,
        FEB: 5621.0,
        MAR: 5760.0,
        APR: 5205.0,
        MAY: 4680.0,
        JUN: 4358.0,
        JUL: 4616.0,
        AUG: 5562.0,
        SEP: 6267.0,
        OCT: 6417.0,
        NOV: 6456.0,
        DEC: 6233.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -7.950461434175617],
              [-40.099045178706334, -7.850461434175617],
              [-39.999045178706332, -7.850461434175617],
              [-39.999045178706332, -7.950461434175617],
              [-40.099045178706334, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '700',
        ID: 42119.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -7.9005,
        ANNUAL: 5624.0,
        JAN: 5919.0,
        FEB: 5685.0,
        MAR: 5775.0,
        APR: 5264.0,
        MAY: 4708.0,
        JUN: 4376.0,
        JUL: 4692.0,
        AUG: 5612.0,
        SEP: 6276.0,
        OCT: 6434.0,
        NOV: 6486.0,
        DEC: 6256.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -7.950461434175617],
              [-39.999045178706332, -7.850461434175617],
              [-39.899045178706331, -7.850461434175617],
              [-39.899045178706331, -7.950461434175617],
              [-39.999045178706332, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '701',
        ID: 42120.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -7.9005,
        ANNUAL: 5655.0,
        JAN: 5982.0,
        FEB: 5742.0,
        MAR: 5824.0,
        APR: 5310.0,
        MAY: 4721.0,
        JUN: 4398.0,
        JUL: 4694.0,
        AUG: 5605.0,
        SEP: 6312.0,
        OCT: 6468.0,
        NOV: 6513.0,
        DEC: 6287.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -7.950461434175617],
              [-39.899045178706331, -7.850461434175617],
              [-39.799045178706329, -7.850461434175617],
              [-39.799045178706329, -7.950461434175617],
              [-39.899045178706331, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '702',
        ID: 42121.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -7.9005,
        ANNUAL: 5648.0,
        JAN: 5985.0,
        FEB: 5749.0,
        MAR: 5790.0,
        APR: 5289.0,
        MAY: 4697.0,
        JUN: 4377.0,
        JUL: 4678.0,
        AUG: 5583.0,
        SEP: 6351.0,
        OCT: 6481.0,
        NOV: 6526.0,
        DEC: 6265.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -7.950461434175617],
              [-39.799045178706329, -7.850461434175617],
              [-39.699045178706328, -7.850461434175617],
              [-39.699045178706328, -7.950461434175617],
              [-39.799045178706329, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '703',
        ID: 42122.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -7.9005,
        ANNUAL: 5683.0,
        JAN: 5990.0,
        FEB: 5789.0,
        MAR: 5799.0,
        APR: 5323.0,
        MAY: 4753.0,
        JUN: 4436.0,
        JUL: 4730.0,
        AUG: 5583.0,
        SEP: 6393.0,
        OCT: 6537.0,
        NOV: 6572.0,
        DEC: 6290.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -7.950461434175617],
              [-39.699045178706328, -7.850461434175617],
              [-39.599045178706326, -7.850461434175617],
              [-39.599045178706326, -7.950461434175617],
              [-39.699045178706328, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '704',
        ID: 42123.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -7.9005,
        ANNUAL: 5692.0,
        JAN: 5988.0,
        FEB: 5846.0,
        MAR: 5843.0,
        APR: 5358.0,
        MAY: 4757.0,
        JUN: 4443.0,
        JUL: 4738.0,
        AUG: 5583.0,
        SEP: 6429.0,
        OCT: 6468.0,
        NOV: 6554.0,
        DEC: 6294.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -7.950461434175617],
              [-39.599045178706326, -7.850461434175617],
              [-39.499045178706325, -7.850461434175617],
              [-39.499045178706325, -7.950461434175617],
              [-39.599045178706326, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '705',
        ID: 42124.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -7.9005,
        ANNUAL: 5707.0,
        JAN: 6013.0,
        FEB: 5856.0,
        MAR: 5835.0,
        APR: 5355.0,
        MAY: 4741.0,
        JUN: 4449.0,
        JUL: 4758.0,
        AUG: 5589.0,
        SEP: 6473.0,
        OCT: 6516.0,
        NOV: 6580.0,
        DEC: 6317.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -7.950461434175617],
              [-39.499045178706325, -7.850461434175617],
              [-39.399045178706324, -7.850461434175617],
              [-39.399045178706324, -7.950461434175617],
              [-39.499045178706325, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '706',
        ID: 42125.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -7.9005,
        ANNUAL: 5719.0,
        JAN: 6068.0,
        FEB: 5919.0,
        MAR: 5831.0,
        APR: 5417.0,
        MAY: 4748.0,
        JUN: 4465.0,
        JUL: 4744.0,
        AUG: 5580.0,
        SEP: 6452.0,
        OCT: 6486.0,
        NOV: 6608.0,
        DEC: 6312.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.399045178706324, -7.950461434175617],
              [-39.399045178706324, -7.850461434175617],
              [-39.299045178706322, -7.850461434175617],
              [-39.299045178706322, -7.950461434175617],
              [-39.399045178706324, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '707',
        ID: 42126.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -7.9005,
        ANNUAL: 5694.0,
        JAN: 6029.0,
        FEB: 5894.0,
        MAR: 5781.0,
        APR: 5426.0,
        MAY: 4718.0,
        JUN: 4443.0,
        JUL: 4701.0,
        AUG: 5522.0,
        SEP: 6437.0,
        OCT: 6468.0,
        NOV: 6623.0,
        DEC: 6282.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -7.950461434175617],
              [-39.299045178706322, -7.850461434175617],
              [-39.199045178706321, -7.850461434175617],
              [-39.199045178706321, -7.950461434175617],
              [-39.299045178706322, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '708',
        ID: 42127.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -7.9005,
        ANNUAL: 5704.0,
        JAN: 5945.0,
        FEB: 5920.0,
        MAR: 5820.0,
        APR: 5436.0,
        MAY: 4743.0,
        JUN: 4433.0,
        JUL: 4728.0,
        AUG: 5516.0,
        SEP: 6476.0,
        OCT: 6484.0,
        NOV: 6648.0,
        DEC: 6302.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.199045178706321, -7.950461434175617],
              [-39.199045178706321, -7.850461434175617],
              [-39.099045178706319, -7.850461434175617],
              [-39.099045178706319, -7.950461434175617],
              [-39.199045178706321, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '709',
        ID: 42128.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -7.9005,
        ANNUAL: 5735.0,
        JAN: 6004.0,
        FEB: 5961.0,
        MAR: 5893.0,
        APR: 5472.0,
        MAY: 4834.0,
        JUN: 4485.0,
        JUL: 4770.0,
        AUG: 5554.0,
        SEP: 6483.0,
        OCT: 6461.0,
        NOV: 6620.0,
        DEC: 6288.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.095435906, -7.853264144999977],
              [-39.09299192, -7.857557757999924],
              [-39.091297788, -7.858182470999961],
              [-39.091295762999898, -7.858181520999958],
              [-39.089651917, -7.857409806999944],
              [-39.088009699999901, -7.854771193999968],
              [-39.086984610999899, -7.853123820999946],
              [-39.085350248999902, -7.851712495999947],
              [-39.082997076142775, -7.850461434175617],
              [-38.999045178706318, -7.850461434175617],
              [-38.999045178706318, -7.950461434175617],
              [-39.099045178706319, -7.950461434175617],
              [-39.099045178706319, -7.850461434175617],
              [-39.096438204709479, -7.850461434175617],
              [-39.095435906, -7.853264144999977],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '710',
        ID: 42129.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -7.9005,
        ANNUAL: 5733.0,
        JAN: 5996.0,
        FEB: 5930.0,
        MAR: 5922.0,
        APR: 5463.0,
        MAY: 4858.0,
        JUN: 4509.0,
        JUL: 4796.0,
        AUG: 5566.0,
        SEP: 6458.0,
        OCT: 6444.0,
        NOV: 6598.0,
        DEC: 6253.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.999045178706318, -7.950461434175617],
              [-38.999045178706318, -7.850461434175617],
              [-38.899045178706317, -7.850461434175617],
              [-38.899045178706317, -7.950461434175617],
              [-38.999045178706318, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '711',
        ID: 42130.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -7.9005,
        ANNUAL: 5740.0,
        JAN: 6045.0,
        FEB: 5932.0,
        MAR: 5878.0,
        APR: 5469.0,
        MAY: 4843.0,
        JUN: 4506.0,
        JUL: 4777.0,
        AUG: 5583.0,
        SEP: 6467.0,
        OCT: 6482.0,
        NOV: 6616.0,
        DEC: 6289.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -7.950461434175617],
              [-38.899045178706317, -7.850461434175617],
              [-38.799045178706315, -7.850461434175617],
              [-38.799045178706315, -7.950461434175617],
              [-38.899045178706317, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '712',
        ID: 42131.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -7.9005,
        ANNUAL: 5790.0,
        JAN: 6163.0,
        FEB: 6045.0,
        MAR: 5946.0,
        APR: 5566.0,
        MAY: 4866.0,
        JUN: 4518.0,
        JUL: 4794.0,
        AUG: 5595.0,
        SEP: 6501.0,
        OCT: 6503.0,
        NOV: 6622.0,
        DEC: 6359.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -7.950461434175617],
              [-38.799045178706315, -7.850461434175617],
              [-38.699045178706314, -7.850461434175617],
              [-38.699045178706314, -7.950461434175617],
              [-38.799045178706315, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '713',
        ID: 42132.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -7.9005,
        ANNUAL: 5774.0,
        JAN: 6151.0,
        FEB: 6022.0,
        MAR: 5965.0,
        APR: 5574.0,
        MAY: 4850.0,
        JUN: 4475.0,
        JUL: 4763.0,
        AUG: 5578.0,
        SEP: 6455.0,
        OCT: 6506.0,
        NOV: 6616.0,
        DEC: 6334.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -7.950461434175617],
              [-38.699045178706314, -7.850461434175617],
              [-38.599045178706312, -7.850461434175617],
              [-38.599045178706312, -7.950461434175617],
              [-38.699045178706314, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '714',
        ID: 42133.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -7.9005,
        ANNUAL: 5785.0,
        JAN: 6188.0,
        FEB: 6050.0,
        MAR: 6031.0,
        APR: 5577.0,
        MAY: 4841.0,
        JUN: 4444.0,
        JUL: 4698.0,
        AUG: 5528.0,
        SEP: 6457.0,
        OCT: 6552.0,
        NOV: 6663.0,
        DEC: 6385.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.599045178706312, -7.950461434175617],
              [-38.599045178706312, -7.850461434175617],
              [-38.499045178706311, -7.850461434175617],
              [-38.499045178706311, -7.950461434175617],
              [-38.599045178706312, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '715',
        ID: 42134.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -7.9005,
        ANNUAL: 5810.0,
        JAN: 6280.0,
        FEB: 6156.0,
        MAR: 6086.0,
        APR: 5653.0,
        MAY: 4844.0,
        JUN: 4415.0,
        JUL: 4666.0,
        AUG: 5518.0,
        SEP: 6428.0,
        OCT: 6567.0,
        NOV: 6704.0,
        DEC: 6401.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.499045178706311, -7.950461434175617],
              [-38.499045178706311, -7.850461434175617],
              [-38.399045178706309, -7.850461434175617],
              [-38.399045178706309, -7.950461434175617],
              [-38.499045178706311, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '716',
        ID: 42135.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -7.9005,
        ANNUAL: 5835.0,
        JAN: 6275.0,
        FEB: 6174.0,
        MAR: 6138.0,
        APR: 5691.0,
        MAY: 4878.0,
        JUN: 4439.0,
        JUL: 4692.0,
        AUG: 5578.0,
        SEP: 6445.0,
        OCT: 6584.0,
        NOV: 6740.0,
        DEC: 6388.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.399045178706309, -7.950461434175617],
              [-38.399045178706309, -7.850461434175617],
              [-38.299045178706308, -7.850461434175617],
              [-38.299045178706308, -7.950461434175617],
              [-38.399045178706309, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '717',
        ID: 42136.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -7.9005,
        ANNUAL: 5840.0,
        JAN: 6194.0,
        FEB: 6128.0,
        MAR: 6140.0,
        APR: 5725.0,
        MAY: 4879.0,
        JUN: 4476.0,
        JUL: 4725.0,
        AUG: 5615.0,
        SEP: 6454.0,
        OCT: 6601.0,
        NOV: 6734.0,
        DEC: 6403.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.299045178706308, -7.950461434175617],
              [-38.299045178706308, -7.850461434175617],
              [-38.199045178706307, -7.850461434175617],
              [-38.199045178706307, -7.950461434175617],
              [-38.299045178706308, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '718',
        ID: 42137.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -7.9005,
        ANNUAL: 5823.0,
        JAN: 6220.0,
        FEB: 6112.0,
        MAR: 6143.0,
        APR: 5718.0,
        MAY: 4836.0,
        JUN: 4432.0,
        JUL: 4676.0,
        AUG: 5557.0,
        SEP: 6441.0,
        OCT: 6590.0,
        NOV: 6732.0,
        DEC: 6418.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.199045178706307, -7.950461434175617],
              [-38.199045178706307, -7.850461434175617],
              [-38.099045178706305, -7.850461434175617],
              [-38.099045178706305, -7.950461434175617],
              [-38.199045178706307, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '719',
        ID: 42138.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -7.9005,
        ANNUAL: 5776.0,
        JAN: 6192.0,
        FEB: 6125.0,
        MAR: 6142.0,
        APR: 5695.0,
        MAY: 4788.0,
        JUN: 4330.0,
        JUL: 4555.0,
        AUG: 5440.0,
        SEP: 6358.0,
        OCT: 6541.0,
        NOV: 6736.0,
        DEC: 6415.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.099045178706305, -7.950461434175617],
              [-38.099045178706305, -7.850461434175617],
              [-37.999045178706304, -7.850461434175617],
              [-37.999045178706304, -7.950461434175617],
              [-38.099045178706305, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '720',
        ID: 42139.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -7.9005,
        ANNUAL: 5783.0,
        JAN: 6214.0,
        FEB: 6189.0,
        MAR: 6139.0,
        APR: 5740.0,
        MAY: 4829.0,
        JUN: 4347.0,
        JUL: 4577.0,
        AUG: 5409.0,
        SEP: 6332.0,
        OCT: 6510.0,
        NOV: 6710.0,
        DEC: 6402.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.999045178706304, -7.950461434175617],
              [-37.999045178706304, -7.850461434175617],
              [-37.899045178706302, -7.850461434175617],
              [-37.899045178706302, -7.950461434175617],
              [-37.999045178706304, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '721',
        ID: 42140.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -7.9005,
        ANNUAL: 5804.0,
        JAN: 6221.0,
        FEB: 6196.0,
        MAR: 6161.0,
        APR: 5708.0,
        MAY: 4875.0,
        JUN: 4417.0,
        JUL: 4626.0,
        AUG: 5449.0,
        SEP: 6349.0,
        OCT: 6507.0,
        NOV: 6728.0,
        DEC: 6415.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -7.950461434175617],
              [-37.899045178706302, -7.850461434175617],
              [-37.799045178706301, -7.850461434175617],
              [-37.799045178706301, -7.950461434175617],
              [-37.899045178706302, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '722',
        ID: 42141.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -7.9005,
        ANNUAL: 5818.0,
        JAN: 6223.0,
        FEB: 6173.0,
        MAR: 6141.0,
        APR: 5710.0,
        MAY: 4929.0,
        JUN: 4490.0,
        JUL: 4699.0,
        AUG: 5520.0,
        SEP: 6326.0,
        OCT: 6509.0,
        NOV: 6692.0,
        DEC: 6401.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -7.950461434175617],
              [-37.799045178706301, -7.850461434175617],
              [-37.699045178706299, -7.850461434175617],
              [-37.699045178706299, -7.950461434175617],
              [-37.799045178706301, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '723',
        ID: 42142.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -7.9005,
        ANNUAL: 5803.0,
        JAN: 6200.0,
        FEB: 6136.0,
        MAR: 6093.0,
        APR: 5717.0,
        MAY: 4935.0,
        JUN: 4520.0,
        JUL: 4703.0,
        AUG: 5535.0,
        SEP: 6342.0,
        OCT: 6446.0,
        NOV: 6685.0,
        DEC: 6325.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -7.950461434175617],
              [-37.699045178706299, -7.850461434175617],
              [-37.599045178706298, -7.850461434175617],
              [-37.599045178706298, -7.950461434175617],
              [-37.699045178706299, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '724',
        ID: 42143.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -7.9005,
        ANNUAL: 5781.0,
        JAN: 6179.0,
        FEB: 6117.0,
        MAR: 6066.0,
        APR: 5663.0,
        MAY: 4895.0,
        JUN: 4499.0,
        JUL: 4653.0,
        AUG: 5478.0,
        SEP: 6337.0,
        OCT: 6446.0,
        NOV: 6714.0,
        DEC: 6333.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -7.950461434175617],
              [-37.599045178706298, -7.850461434175617],
              [-37.499045178706297, -7.850461434175617],
              [-37.499045178706297, -7.950461434175617],
              [-37.599045178706298, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '725',
        ID: 42144.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -7.9005,
        ANNUAL: 5783.0,
        JAN: 6181.0,
        FEB: 6068.0,
        MAR: 6036.0,
        APR: 5665.0,
        MAY: 4918.0,
        JUN: 4497.0,
        JUL: 4651.0,
        AUG: 5486.0,
        SEP: 6324.0,
        OCT: 6476.0,
        NOV: 6733.0,
        DEC: 6360.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -7.950461434175617],
              [-37.499045178706297, -7.850461434175617],
              [-37.399045178706295, -7.850461434175617],
              [-37.399045178706295, -7.950461434175617],
              [-37.499045178706297, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '726',
        ID: 42145.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -7.9005,
        ANNUAL: 5796.0,
        JAN: 6287.0,
        FEB: 6044.0,
        MAR: 6051.0,
        APR: 5663.0,
        MAY: 4897.0,
        JUN: 4443.0,
        JUL: 4601.0,
        AUG: 5455.0,
        SEP: 6360.0,
        OCT: 6519.0,
        NOV: 6826.0,
        DEC: 6408.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.303651421, -7.904175725999947],
              [-37.307278057, -7.907307631999972],
              [-37.3080088, -7.907939212999955],
              [-37.310734067999903, -7.907048529999945],
              [-37.315704744, -7.907561428999934],
              [-37.322172533, -7.910502036999952],
              [-37.323120447, -7.912162632999985],
              [-37.322768229, -7.912998587999937],
              [-37.321809784, -7.913345668999957],
              [-37.318118071, -7.911954207999945],
              [-37.313259359, -7.91138563299995],
              [-37.309859104999902, -7.912405009999929],
              [-37.309335302, -7.912824371999927],
              [-37.307984248, -7.914986343999938],
              [-37.308092798, -7.916267231999945],
              [-37.309646565, -7.91863805499997],
              [-37.310622005, -7.919412042999969],
              [-37.311400732, -7.921648900999959],
              [-37.310985668999898, -7.922510164999949],
              [-37.309113005, -7.924048424999975],
              [-37.308483490999897, -7.925839321999935],
              [-37.308054216, -7.92706056099995],
              [-37.307858952999901, -7.929143575999944],
              [-37.307887748, -7.931316851999948],
              [-37.308066922, -7.932183583999966],
              [-37.3089763629999, -7.934176241999986],
              [-37.310646555999902, -7.934831136999954],
              [-37.312286856999897, -7.934831806999965],
              [-37.3122885189999, -7.934831806999971],
              [-37.314702618, -7.935933978999966],
              [-37.315599114999898, -7.937122793999958],
              [-37.316589204, -7.941218811999924],
              [-37.317624049, -7.942769965999951],
              [-37.318979155, -7.943100102999948],
              [-37.322059097, -7.941696967999963],
              [-37.322975798999899, -7.941707956999957],
              [-37.326338004, -7.943818657999945],
              [-37.3303104129999, -7.94474198199993],
              [-37.33250491, -7.943534031999955],
              [-37.334730396999902, -7.943046732999966],
              [-37.334731738999899, -7.943046438999946],
              [-37.337229624, -7.944975398999923],
              [-37.339869587999907, -7.945207294999928],
              [-37.343453352, -7.947020859999952],
              [-37.345451877, -7.947168110999946],
              [-37.348592298, -7.946298794999956],
              [-37.349903668, -7.946644454999964],
              [-37.350218723999909, -7.948597486999974],
              [-37.350271839, -7.948926744999942],
              [-37.350281482999897, -7.94898652699993],
              [-37.350302305, -7.949115602999935],
              [-37.349625921573804, -7.950461434175617],
              [-37.399045178706295, -7.950461434175617],
              [-37.399045178706295, -7.850461434175617],
              [-37.299045178706294, -7.850461434175617],
              [-37.299045178706294, -7.905127296182565],
              [-37.303651421, -7.904175725999947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '727',
        ID: 42146.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -7.9005,
        ANNUAL: 5805.0,
        JAN: 6290.0,
        FEB: 6086.0,
        MAR: 6091.0,
        APR: 5697.0,
        MAY: 4904.0,
        JUN: 4434.0,
        JUL: 4604.0,
        AUG: 5412.0,
        SEP: 6342.0,
        OCT: 6557.0,
        NOV: 6813.0,
        DEC: 6432.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.2476192089999, -7.850840971999963],
              [-37.249312249, -7.85206047299993],
              [-37.250370018, -7.853655097999921],
              [-37.250202048999903, -7.85489028699993],
              [-37.247287242, -7.856913828999956],
              [-37.245836849999897, -7.858855294999961],
              [-37.244925598999899, -7.861585763999927],
              [-37.244726748999902, -7.864029191999982],
              [-37.246013513, -7.865812357999975],
              [-37.246867795, -7.868178935999937],
              [-37.246699753, -7.869413226999948],
              [-37.244093514, -7.873845561999929],
              [-37.244648, -7.874900032999954],
              [-37.248951211, -7.875743156999929],
              [-37.25233165399991, -7.877833335999972],
              [-37.253383738, -7.880242732999923],
              [-37.254033837999899, -7.88173153999998],
              [-37.254034664999899, -7.881733433999955],
              [-37.254108917999901, -7.881903481999926],
              [-37.254337162999903, -7.882426188999943],
              [-37.259529275, -7.882844174999946],
              [-37.261790714, -7.883946841999934],
              [-37.263510595999897, -7.887567727999961],
              [-37.269893946, -7.890960396999961],
              [-37.271729888, -7.892454955999933],
              [-37.273977085, -7.895372943999964],
              [-37.277124780999898, -7.897847948999986],
              [-37.282364894999901, -7.899940482999973],
              [-37.286145890999897, -7.902465096999954],
              [-37.287394449, -7.903876463999953],
              [-37.290733921, -7.904986020999959],
              [-37.295727889, -7.905812590999976],
              [-37.299045178706294, -7.905127296182565],
              [-37.299045178706294, -7.850461434175617],
              [-37.247639420068701, -7.850461434175617],
              [-37.2476192089999, -7.850840971999963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '728',
        ID: 42152.0,
        COUNTRY: 'Brasil',
        LON: -36.649,
        LAT: -7.9005,
        ANNUAL: 5682.0,
        JAN: 6177.0,
        FEB: 6065.0,
        MAR: 6080.0,
        APR: 5627.0,
        MAY: 4810.0,
        JUN: 4360.0,
        JUL: 4478.0,
        AUG: 5207.0,
        SEP: 6072.0,
        OCT: 6366.0,
        NOV: 6593.0,
        DEC: 6348.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.612519823, -7.949439017999953],
              [-36.611876869999897, -7.947896706999931],
              [-36.610898060999901, -7.947429194999924],
              [-36.6096591149999, -7.947730873999935],
              [-36.606648618162183, -7.950461434175617],
              [-36.612601460203628, -7.950461434175617],
              [-36.612519823, -7.949439017999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '729',
        ID: 42153.0,
        COUNTRY: 'Brasil',
        LON: -36.549,
        LAT: -7.9005,
        ANNUAL: 5641.0,
        JAN: 6113.0,
        FEB: 5997.0,
        MAR: 5994.0,
        APR: 5601.0,
        MAY: 4778.0,
        JUN: 4349.0,
        JUL: 4491.0,
        AUG: 5247.0,
        SEP: 6009.0,
        OCT: 6311.0,
        NOV: 6524.0,
        DEC: 6279.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.579443564, -7.949512153999927],
              [-36.579979836999897, -7.948640484999941],
              [-36.579991359, -7.946684088999975],
              [-36.577892203, -7.944834347999967],
              [-36.574435593, -7.940088918999944],
              [-36.57258819, -7.935974962999977],
              [-36.5725343, -7.930567219999938],
              [-36.5776221469999, -7.921272382999964],
              [-36.578199909, -7.915247600999981],
              [-36.576039393, -7.909464866999946],
              [-36.574811876, -7.907500622999974],
              [-36.57170601, -7.904495611999956],
              [-36.56935306, -7.903444073999943],
              [-36.568705774, -7.903154800999971],
              [-36.563878911, -7.900997665999965],
              [-36.55201964, -7.89871410199993],
              [-36.549535036999899, -7.899216196999932],
              [-36.543042526, -7.902317800999976],
              [-36.543040123, -7.902317541999923],
              [-36.536464330999898, -7.901609339999948],
              [-36.5336306, -7.902177878999963],
              [-36.531473389, -7.902798559999943],
              [-36.526224538, -7.906811047999943],
              [-36.512854532999903, -7.914871524999977],
              [-36.49938876800001, -7.920314876999956],
              [-36.499386928, -7.920314412999963],
              [-36.499045178706282, -7.920228136690169],
              [-36.499045178706282, -7.950461434175617],
              [-36.576617542641195, -7.950461434175617],
              [-36.579443564, -7.949512153999927],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '730',
        ID: 42154.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -7.9005,
        ANNUAL: 5564.0,
        JAN: 6061.0,
        FEB: 5912.0,
        MAR: 5948.0,
        APR: 5512.0,
        MAY: 4767.0,
        JUN: 4300.0,
        JUL: 4460.0,
        AUG: 5142.0,
        SEP: 5896.0,
        OCT: 6196.0,
        NOV: 6420.0,
        DEC: 6159.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.497791494999902, -7.919911637999975],
              [-36.495951286, -7.918263107999965],
              [-36.492479752999898, -7.915153173999945],
              [-36.490132834, -7.914779719999952],
              [-36.488116318, -7.915384662999939],
              [-36.486664398999899, -7.916354962999955],
              [-36.482872707, -7.920297068999955],
              [-36.480136377999898, -7.924763255999955],
              [-36.478122153, -7.924804254999974],
              [-36.478119754, -7.924803275999975],
              [-36.476576556999902, -7.924173787999958],
              [-36.470446836999898, -7.917801336999957],
              [-36.46768202, -7.916467125999937],
              [-36.464099883, -7.915350070999975],
              [-36.461251800999896, -7.914656085999972],
              [-36.461250422, -7.914656118999973],
              [-36.45271501, -7.914859579999926],
              [-36.446301436999903, -7.916379678999927],
              [-36.446296428, -7.916377172999947],
              [-36.4441095889999, -7.915283271999946],
              [-36.443697308, -7.914330874999961],
              [-36.4440001749999, -7.912154014999935],
              [-36.445557622, -7.910618307999925],
              [-36.445755254999902, -7.908168211999956],
              [-36.4435238619999, -7.905614467999951],
              [-36.439690801, -7.903384752999957],
              [-36.436918429999899, -7.900268845999956],
              [-36.434379274, -7.896318087999934],
              [-36.430386940999902, -7.889017093999944],
              [-36.429695221, -7.886559187999977],
              [-36.429859027, -7.885068750999958],
              [-36.431140924, -7.881744741999967],
              [-36.432721324999903, -7.879986754999949],
              [-36.435331893, -7.879250738999924],
              [-36.439504121, -7.879107400999943],
              [-36.440383616, -7.878250953999952],
              [-36.439920937, -7.876199130999964],
              [-36.436026656, -7.874124094999961],
              [-36.436024593, -7.874123451999938],
              [-36.43530529, -7.873899288999947],
              [-36.432721412, -7.873094051999933],
              [-36.432256618999901, -7.871975125999946],
              [-36.430832361999897, -7.868546418999928],
              [-36.430051211, -7.867494670999968],
              [-36.429057289999903, -7.867037189999966],
              [-36.428692481, -7.864402214999939],
              [-36.428945015, -7.85849874599995],
              [-36.430798431052338, -7.850461434175617],
              [-36.399045178706281, -7.850461434175617],
              [-36.399045178706281, -7.950461434175617],
              [-36.499045178706282, -7.950461434175617],
              [-36.499045178706282, -7.920228136690169],
              [-36.497791494999902, -7.919911637999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '731',
        ID: 42155.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -7.9005,
        ANNUAL: 5504.0,
        JAN: 6037.0,
        FEB: 5900.0,
        MAR: 5892.0,
        APR: 5450.0,
        MAY: 4712.0,
        JUN: 4242.0,
        JUL: 4369.0,
        AUG: 5066.0,
        SEP: 5797.0,
        OCT: 6099.0,
        NOV: 6387.0,
        DEC: 6105.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.399045178706281, -7.950461434175617],
              [-36.399045178706281, -7.850461434175617],
              [-36.29904517870628, -7.850461434175617],
              [-36.29904517870628, -7.950461434175617],
              [-36.399045178706281, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '732',
        ID: 42156.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -7.9005,
        ANNUAL: 5429.0,
        JAN: 5889.0,
        FEB: 5886.0,
        MAR: 5862.0,
        APR: 5366.0,
        MAY: 4630.0,
        JUN: 4199.0,
        JUL: 4337.0,
        AUG: 5027.0,
        SEP: 5714.0,
        OCT: 6015.0,
        NOV: 6248.0,
        DEC: 5982.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.29904517870628, -7.950461434175617],
              [-36.29904517870628, -7.850461434175617],
              [-36.199045178706278, -7.850461434175617],
              [-36.199045178706278, -7.950461434175617],
              [-36.29904517870628, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '733',
        ID: 42157.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -7.9005,
        ANNUAL: 5396.0,
        JAN: 5858.0,
        FEB: 5860.0,
        MAR: 5845.0,
        APR: 5329.0,
        MAY: 4598.0,
        JUN: 4160.0,
        JUL: 4296.0,
        AUG: 4972.0,
        SEP: 5617.0,
        OCT: 5965.0,
        NOV: 6230.0,
        DEC: 6019.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.199045178706278, -7.950461434175617],
              [-36.199045178706278, -7.850461434175617],
              [-36.099045178706277, -7.850461434175617],
              [-36.099045178706277, -7.950461434175617],
              [-36.199045178706278, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '734',
        ID: 42158.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -7.9005,
        ANNUAL: 5402.0,
        JAN: 5898.0,
        FEB: 5894.0,
        MAR: 5913.0,
        APR: 5296.0,
        MAY: 4613.0,
        JUN: 4103.0,
        JUL: 4226.0,
        AUG: 4916.0,
        SEP: 5637.0,
        OCT: 5988.0,
        NOV: 6268.0,
        DEC: 6070.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.099045178706277, -7.950461434175617],
              [-36.099045178706277, -7.850461434175617],
              [-35.999045178706275, -7.850461434175617],
              [-35.999045178706275, -7.950461434175617],
              [-36.099045178706277, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '735',
        ID: 42159.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -7.9005,
        ANNUAL: 5302.0,
        JAN: 5722.0,
        FEB: 5773.0,
        MAR: 5842.0,
        APR: 5192.0,
        MAY: 4562.0,
        JUN: 4044.0,
        JUL: 4261.0,
        AUG: 4874.0,
        SEP: 5473.0,
        OCT: 5795.0,
        NOV: 6106.0,
        DEC: 5983.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.999045178706275, -7.950461434175617],
              [-35.999045178706275, -7.850461434175617],
              [-35.899045178706274, -7.850461434175617],
              [-35.899045178706274, -7.950461434175617],
              [-35.999045178706275, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '736',
        ID: 42160.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -7.9005,
        ANNUAL: 5314.0,
        JAN: 5800.0,
        FEB: 5770.0,
        MAR: 5875.0,
        APR: 5220.0,
        MAY: 4567.0,
        JUN: 4073.0,
        JUL: 4256.0,
        AUG: 4885.0,
        SEP: 5470.0,
        OCT: 5774.0,
        NOV: 6108.0,
        DEC: 5964.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.899045178706274, -7.950461434175617],
              [-35.899045178706274, -7.850461434175617],
              [-35.799045178706272, -7.850461434175617],
              [-35.799045178706272, -7.950461434175617],
              [-35.899045178706274, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '737',
        ID: 42161.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -7.9005,
        ANNUAL: 5304.0,
        JAN: 5800.0,
        FEB: 5781.0,
        MAR: 5831.0,
        APR: 5242.0,
        MAY: 4560.0,
        JUN: 4081.0,
        JUL: 4250.0,
        AUG: 4884.0,
        SEP: 5490.0,
        OCT: 5750.0,
        NOV: 6055.0,
        DEC: 5929.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -7.950461434175617],
              [-35.799045178706272, -7.850461434175617],
              [-35.699045178706271, -7.850461434175617],
              [-35.699045178706271, -7.950461434175617],
              [-35.799045178706272, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '738',
        ID: 42162.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -7.9005,
        ANNUAL: 5269.0,
        JAN: 5703.0,
        FEB: 5713.0,
        MAR: 5810.0,
        APR: 5181.0,
        MAY: 4464.0,
        JUN: 4094.0,
        JUL: 4250.0,
        AUG: 4862.0,
        SEP: 5518.0,
        OCT: 5705.0,
        NOV: 6002.0,
        DEC: 5927.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -7.950461434175617],
              [-35.699045178706271, -7.850461434175617],
              [-35.59904517870627, -7.850461434175617],
              [-35.59904517870627, -7.950461434175617],
              [-35.699045178706271, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '739',
        ID: 42163.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -7.9005,
        ANNUAL: 5163.0,
        JAN: 5610.0,
        FEB: 5540.0,
        MAR: 5661.0,
        APR: 5100.0,
        MAY: 4392.0,
        JUN: 4056.0,
        JUL: 4166.0,
        AUG: 4798.0,
        SEP: 5395.0,
        OCT: 5562.0,
        NOV: 5880.0,
        DEC: 5794.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -7.950461434175617],
              [-35.59904517870627, -7.850461434175617],
              [-35.499045178706268, -7.850461434175617],
              [-35.499045178706268, -7.950461434175617],
              [-35.59904517870627, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '740',
        ID: 42164.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -7.9005,
        ANNUAL: 5191.0,
        JAN: 5669.0,
        FEB: 5590.0,
        MAR: 5647.0,
        APR: 5111.0,
        MAY: 4376.0,
        JUN: 4068.0,
        JUL: 4168.0,
        AUG: 4809.0,
        SEP: 5430.0,
        OCT: 5654.0,
        NOV: 5937.0,
        DEC: 5839.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -7.950461434175617],
              [-35.499045178706268, -7.850461434175617],
              [-35.399045178706267, -7.850461434175617],
              [-35.399045178706267, -7.950461434175617],
              [-35.499045178706268, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '741',
        ID: 42165.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -7.9005,
        ANNUAL: 5288.0,
        JAN: 5753.0,
        FEB: 5798.0,
        MAR: 5747.0,
        APR: 5202.0,
        MAY: 4456.0,
        JUN: 4127.0,
        JUL: 4253.0,
        AUG: 4891.0,
        SEP: 5541.0,
        OCT: 5747.0,
        NOV: 5977.0,
        DEC: 5959.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -7.950461434175617],
              [-35.399045178706267, -7.850461434175617],
              [-35.299045178706265, -7.850461434175617],
              [-35.299045178706265, -7.950461434175617],
              [-35.399045178706267, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '742',
        ID: 42166.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -7.9005,
        ANNUAL: 5324.0,
        JAN: 5751.0,
        FEB: 5844.0,
        MAR: 5804.0,
        APR: 5229.0,
        MAY: 4486.0,
        JUN: 4152.0,
        JUL: 4277.0,
        AUG: 4999.0,
        SEP: 5539.0,
        OCT: 5777.0,
        NOV: 6008.0,
        DEC: 6020.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -7.950461434175617],
              [-35.299045178706265, -7.850461434175617],
              [-35.199045178706264, -7.850461434175617],
              [-35.199045178706264, -7.950461434175617],
              [-35.299045178706265, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '743',
        ID: 42167.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -7.9005,
        ANNUAL: 5341.0,
        JAN: 5753.0,
        FEB: 5885.0,
        MAR: 5840.0,
        APR: 5217.0,
        MAY: 4504.0,
        JUN: 4175.0,
        JUL: 4290.0,
        AUG: 5031.0,
        SEP: 5550.0,
        OCT: 5778.0,
        NOV: 6031.0,
        DEC: 6041.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -7.950461434175617],
              [-35.199045178706264, -7.850461434175617],
              [-35.099045178706263, -7.850461434175617],
              [-35.099045178706263, -7.950461434175617],
              [-35.199045178706264, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '744',
        ID: 42168.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -7.9005,
        ANNUAL: 5300.0,
        JAN: 5743.0,
        FEB: 5859.0,
        MAR: 5844.0,
        APR: 5141.0,
        MAY: 4440.0,
        JUN: 4114.0,
        JUL: 4271.0,
        AUG: 4988.0,
        SEP: 5465.0,
        OCT: 5748.0,
        NOV: 5966.0,
        DEC: 6027.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -7.950461434175617],
              [-35.099045178706263, -7.850461434175617],
              [-34.999045178706261, -7.850461434175617],
              [-34.999045178706261, -7.950461434175617],
              [-35.099045178706263, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '745',
        ID: 42169.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -7.9005,
        ANNUAL: 5292.0,
        JAN: 5716.0,
        FEB: 5881.0,
        MAR: 5834.0,
        APR: 5136.0,
        MAY: 4471.0,
        JUN: 4161.0,
        JUL: 4269.0,
        AUG: 4998.0,
        SEP: 5406.0,
        OCT: 5686.0,
        NOV: 5985.0,
        DEC: 5966.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.999045178706261, -7.950461434175617],
              [-34.999045178706261, -7.850461434175617],
              [-34.89904517870626, -7.850461434175617],
              [-34.89904517870626, -7.950461434175617],
              [-34.999045178706261, -7.950461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '746',
        ID: 42170.0,
        COUNTRY: 'Brasil',
        LON: -34.849,
        LAT: -7.9005,
        ANNUAL: 5404.0,
        JAN: 5816.0,
        FEB: 5966.0,
        MAR: 5913.0,
        APR: 5220.0,
        MAY: 4532.0,
        JUN: 4234.0,
        JUL: 4345.0,
        AUG: 5117.0,
        SEP: 5544.0,
        OCT: 5918.0,
        NOV: 6150.0,
        DEC: 6093.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.835368778, -7.851316619999984],
              [-34.835328419999897, -7.851472522999925],
              [-34.834947697, -7.852943217999951],
              [-34.834549852999899, -7.854235640999954],
              [-34.8343190309999, -7.855356955999981],
              [-34.834314123, -7.855400982999982],
              [-34.834313667999901, -7.85540801899998],
              [-34.834155673999902, -7.856822445999967],
              [-34.83403193, -7.858365477999937],
              [-34.834140592, -7.859698680999939],
              [-34.834214648999897, -7.860873904999949],
              [-34.834232067, -7.861150307999946],
              [-34.834352024999902, -7.862169860999929],
              [-34.834393385999903, -7.862521399999926],
              [-34.834417426999899, -7.864030707999971],
              [-34.834375169999902, -7.865587642999967],
              [-34.834162439, -7.866916981999952],
              [-34.834089317, -7.867791792999989],
              [-34.833935856, -7.869145295999944],
              [-34.833523930999903, -7.870695389999981],
              [-34.832873691, -7.872289498999986],
              [-34.832713039999902, -7.872612559999943],
              [-34.832091835, -7.873861772999934],
              [-34.831820084, -7.874336112999964],
              [-34.831733347999901, -7.874487508999946],
              [-34.831434027999897, -7.87500997199993],
              [-34.830939491, -7.875980348999971],
              [-34.830403965999899, -7.876689125999977],
              [-34.829422977, -7.877815753999932],
              [-34.8289056899999, -7.878410788999936],
              [-34.82846253, -7.878920554999982],
              [-34.827566496, -7.879828068999936],
              [-34.8269890669999, -7.880392906999925],
              [-34.826485090999903, -7.881145],
              [-34.825780247999901, -7.882097669999952],
              [-34.8251728729999, -7.883024469999957],
              [-34.824777072, -7.883857618999975],
              [-34.824502876999901, -7.884498128999955],
              [-34.824319434999907, -7.885320246999978],
              [-34.824256745999897, -7.885739276999963],
              [-34.8241182209999, -7.886665217999962],
              [-34.823871896999897, -7.88788734199994],
              [-34.8237478899999, -7.888221696999946],
              [-34.823729285999903, -7.88860267199993],
              [-34.82375489, -7.889175005999958],
              [-34.823789474, -7.889394325999941],
              [-34.823850994999901, -7.88978447599993],
              [-34.823907018999897, -7.89028603099996],
              [-34.823807954, -7.891138944999966],
              [-34.823746948999897, -7.891730994999925],
              [-34.82370709, -7.892457464999948],
              [-34.823812575, -7.893221708999936],
              [-34.823820161999897, -7.893717465999941],
              [-34.823730902, -7.894090902999951],
              [-34.823593839999901, -7.894365918999947],
              [-34.8235784459999, -7.894747225999935],
              [-34.823678528999899, -7.895232013999926],
              [-34.823897029, -7.896421661999973],
              [-34.824131812, -7.897497009999938],
              [-34.824201278, -7.897768552999938],
              [-34.824378173999897, -7.898460035999964],
              [-34.824416856, -7.898702299999969],
              [-34.824448613999898, -7.899397453999941],
              [-34.824530146, -7.899933296999952],
              [-34.824776561, -7.901135088999972],
              [-34.824846044, -7.901948182999936],
              [-34.824891024, -7.902474549999964],
              [-34.824875281999901, -7.903997038999924],
              [-34.824602748, -7.905207218999986],
              [-34.824262608, -7.906166879999934],
              [-34.823903188, -7.907397156999923],
              [-34.823447592, -7.908392370999923],
              [-34.822854275999902, -7.909003082999952],
              [-34.822387459, -7.909701463999967],
              [-34.822144758, -7.910259247999927],
              [-34.822033889999901, -7.910836360999952],
              [-34.822068314, -7.911232465999944],
              [-34.821844147, -7.911681152999954],
              [-34.821574862, -7.912202822999965],
              [-34.821331201, -7.912375177999929],
              [-34.821138215, -7.912629252999984],
              [-34.821091964999901, -7.912772156999952],
              [-34.821066125, -7.912851998999936],
              [-34.821075337, -7.913080298999944],
              [-34.820695552, -7.914616616999975],
              [-34.820398036, -7.916020414999928],
              [-34.820377349, -7.916118024999947],
              [-34.8201774079999, -7.917449534999954],
              [-34.820162906999897, -7.91772391599995],
              [-34.8201423309999, -7.918113269999949],
              [-34.820080646999898, -7.919464691999968],
              [-34.820049373, -7.920603705999957],
              [-34.820048632999899, -7.920873788999928],
              [-34.820045831, -7.921896262999943],
              [-34.82004958200001, -7.922249058999964],
              [-34.820058042, -7.923044783999966],
              [-34.820121540999899, -7.92378747999993],
              [-34.820212553, -7.924447317999988],
              [-34.820362227, -7.925312062999988],
              [-34.820238515, -7.925668666999973],
              [-34.820238122, -7.925672285999947],
              [-34.820285783, -7.925948299999964],
              [-34.820410419, -7.926714994999946],
              [-34.820523797, -7.927292047999963],
              [-34.820745250999899, -7.928168445999972],
              [-34.820924219999903, -7.929027805999967],
              [-34.821082788999902, -7.929663236999943],
              [-34.821115571999897, -7.92988621399995],
              [-34.821077482, -7.930120446999975],
              [-34.821004041, -7.930272400999974],
              [-34.820979485999899, -7.93032320899994],
              [-34.820864932999903, -7.930561838999952],
              [-34.82086709, -7.930775280999971],
              [-34.820840083, -7.930991241999957],
              [-34.820873896, -7.931218778999947],
              [-34.820917403, -7.931474729999937],
              [-34.821012916, -7.931573134999955],
              [-34.821060151, -7.931700688999973],
              [-34.821067050999901, -7.931719320999957],
              [-34.821074328999899, -7.931853719999947],
              [-34.8211633129999, -7.931983959999966],
              [-34.821370328999897, -7.932039773999981],
              [-34.821487557, -7.932092818999936],
              [-34.821552797, -7.932122340999972],
              [-34.8216958249999, -7.932157038999955],
              [-34.821908129, -7.932282982999942],
              [-34.822132438, -7.932397893999966],
              [-34.822152259999903, -7.932586572999981],
              [-34.822208804, -7.932880391999966],
              [-34.8222199759999, -7.932894124999958],
              [-34.822301681999903, -7.933191287999921],
              [-34.822382041, -7.933500843999979],
              [-34.8225084379999, -7.933852919999938],
              [-34.8225126789999, -7.933872182999942],
              [-34.822569561, -7.934223129999962],
              [-34.822577469, -7.934675482999978],
              [-34.822418994, -7.934910702999965],
              [-34.822275035, -7.93505828799994],
              [-34.822169641, -7.935166336999937],
              [-34.821798536, -7.935292316999948],
              [-34.82179719, -7.935487819999927],
              [-34.821930305, -7.935764323999927],
              [-34.821935214999904, -7.936184158999942],
              [-34.822532889999898, -7.936029310999971],
              [-34.822548824, -7.936025182999976],
              [-34.822900741999902, -7.936118791999947],
              [-34.823100839999903, -7.936285775999974],
              [-34.823241736999897, -7.936533580999944],
              [-34.823266578999899, -7.936901110999933],
              [-34.823308961, -7.937269321999961],
              [-34.823258148, -7.937621353999924],
              [-34.823250824999903, -7.938097175999937],
              [-34.823432999, -7.938693491999967],
              [-34.823437238999901, -7.938712755999966],
              [-34.823557513, -7.939296208999954],
              [-34.823407352999901, -7.940038262999956],
              [-34.823030984, -7.940367748999966],
              [-34.823015719999901, -7.94038111199995],
              [-34.822761922, -7.940403075999932],
              [-34.822669442999903, -7.941181963999952],
              [-34.823105809, -7.941407903999951],
              [-34.823172623999902, -7.941442499999941],
              [-34.823326992, -7.941733393999936],
              [-34.823234060999901, -7.942003509999951],
              [-34.823156879, -7.942117831999957],
              [-34.823011596999898, -7.942333022999957],
              [-34.822755936, -7.942464360999958],
              [-34.823075006999908, -7.943081192999958],
              [-34.823019965, -7.943298977999981],
              [-34.8231220179999, -7.943462357999972],
              [-34.823261276, -7.943569079999966],
              [-34.823710930999901, -7.943913684999921],
              [-34.823988374, -7.944180986999982],
              [-34.823993593999901, -7.944186015999954],
              [-34.824189846, -7.944520551999966],
              [-34.824244684, -7.945002839999971],
              [-34.824118826, -7.945671764999927],
              [-34.823889229, -7.945928037999979],
              [-34.823749569, -7.946083926999964],
              [-34.823687139999898, -7.946172954999958],
              [-34.823399598, -7.946431197999976],
              [-34.823513525, -7.946798989999934],
              [-34.824243484, -7.94671956999997],
              [-34.824319664, -7.946711282999963],
              [-34.824504526999903, -7.946854653999936],
              [-34.824608957999899, -7.947125396999974],
              [-34.8248258899999, -7.947571337999934],
              [-34.82477757, -7.947857961999938],
              [-34.824771930999901, -7.947900312999931],
              [-34.824697, -7.948463059999937],
              [-34.824825562999898, -7.948693997999948],
              [-34.825090845, -7.948757769999928],
              [-34.825363750999898, -7.948885496999933],
              [-34.825380224, -7.948893207999965],
              [-34.825613302, -7.949124175999942],
              [-34.825719632, -7.949352511999961],
              [-34.825757923, -7.949434739999957],
              [-34.825741759, -7.949737296999961],
              [-34.825709526, -7.950000038999964],
              [-34.825432523419053, -7.950461434175617],
              [-34.89904517870626, -7.950461434175617],
              [-34.89904517870626, -7.850461434175617],
              [-34.835495499135568, -7.850461434175617],
              [-34.835368778, -7.851316619999984],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '747',
        ID: 42502.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -7.8005,
        ANNUAL: 5783.0,
        JAN: 5827.0,
        FEB: 5554.0,
        MAR: 5715.0,
        APR: 5287.0,
        MAY: 4966.0,
        JUN: 4889.0,
        JUL: 5242.0,
        AUG: 6121.0,
        SEP: 6578.0,
        OCT: 6550.0,
        NOV: 6517.0,
        DEC: 6152.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.604380722, -7.798676162999929],
              [-40.628643895, -7.792564089999935],
              [-40.63902761, -7.789239424999953],
              [-40.645166515, -7.786798962999965],
              [-40.653734807, -7.782192744999974],
              [-40.663785042, -7.774761814999971],
              [-40.665499327, -7.77287559799993],
              [-40.670017158999897, -7.767904655999923],
              [-40.672072316, -7.764829004999966],
              [-40.673569476, -7.761671945999979],
              [-40.675657000999898, -7.752683238999963],
              [-40.675555217171684, -7.750461434175618],
              [-40.599045178706341, -7.750461434175618],
              [-40.599045178706341, -7.800020225721399],
              [-40.604380722, -7.798676162999929],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '748',
        ID: 42503.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -7.8005,
        ANNUAL: 5681.0,
        JAN: 5842.0,
        FEB: 5580.0,
        MAR: 5667.0,
        APR: 5171.0,
        MAY: 4798.0,
        JUN: 4657.0,
        JUL: 5004.0,
        AUG: 5924.0,
        SEP: 6403.0,
        OCT: 6437.0,
        NOV: 6437.0,
        DEC: 6254.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.540458788, -7.845993151999954],
              [-40.542025512, -7.838646940999983],
              [-40.5420273309999, -7.838642272999956],
              [-40.542061793999899, -7.838480743999985],
              [-40.544288302, -7.832841502999947],
              [-40.545569326, -7.830707731999947],
              [-40.545944022999898, -7.830083494999942],
              [-40.547514760999903, -7.827466590999958],
              [-40.551296008, -7.822431068999956],
              [-40.554860071999897, -7.818430509999934],
              [-40.559093525, -7.814845991999969],
              [-40.567956664, -7.809051574999955],
              [-40.568404775, -7.808758614999929],
              [-40.57845141, -7.805207947999963],
              [-40.599045178706341, -7.800020225721399],
              [-40.599045178706341, -7.750461434175618],
              [-40.499045178706339, -7.750461434175618],
              [-40.499045178706339, -7.850461434175617],
              [-40.539505544523486, -7.850461434175617],
              [-40.540458788, -7.845993151999954],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '749',
        ID: 42504.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -7.8005,
        ANNUAL: 5639.0,
        JAN: 5841.0,
        FEB: 5545.0,
        MAR: 5694.0,
        APR: 5135.0,
        MAY: 4741.0,
        JUN: 4549.0,
        JUL: 4891.0,
        AUG: 5791.0,
        SEP: 6358.0,
        OCT: 6413.0,
        NOV: 6459.0,
        DEC: 6248.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -7.850461434175617],
              [-40.499045178706339, -7.750461434175618],
              [-40.399045178706338, -7.750461434175618],
              [-40.399045178706338, -7.850461434175617],
              [-40.499045178706339, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '750',
        ID: 42505.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -7.8005,
        ANNUAL: 5617.0,
        JAN: 5843.0,
        FEB: 5490.0,
        MAR: 5700.0,
        APR: 5118.0,
        MAY: 4703.0,
        JUN: 4497.0,
        JUL: 4820.0,
        AUG: 5732.0,
        SEP: 6363.0,
        OCT: 6425.0,
        NOV: 6482.0,
        DEC: 6234.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -7.850461434175617],
              [-40.399045178706338, -7.750461434175618],
              [-40.299045178706336, -7.750461434175618],
              [-40.299045178706336, -7.850461434175617],
              [-40.399045178706338, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '751',
        ID: 42506.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -7.8005,
        ANNUAL: 5636.0,
        JAN: 5870.0,
        FEB: 5580.0,
        MAR: 5761.0,
        APR: 5199.0,
        MAY: 4740.0,
        JUN: 4484.0,
        JUL: 4745.0,
        AUG: 5730.0,
        SEP: 6344.0,
        OCT: 6421.0,
        NOV: 6489.0,
        DEC: 6268.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -7.850461434175617],
              [-40.299045178706336, -7.750461434175618],
              [-40.199045178706335, -7.750461434175618],
              [-40.199045178706335, -7.850461434175617],
              [-40.299045178706336, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '752',
        ID: 42507.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -7.8005,
        ANNUAL: 5615.0,
        JAN: 5882.0,
        FEB: 5594.0,
        MAR: 5767.0,
        APR: 5188.0,
        MAY: 4688.0,
        JUN: 4419.0,
        JUL: 4687.0,
        AUG: 5659.0,
        SEP: 6340.0,
        OCT: 6392.0,
        NOV: 6493.0,
        DEC: 6265.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -7.850461434175617],
              [-40.199045178706335, -7.750461434175618],
              [-40.099045178706334, -7.750461434175618],
              [-40.099045178706334, -7.850461434175617],
              [-40.199045178706335, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '753',
        ID: 42508.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -7.8005,
        ANNUAL: 5635.0,
        JAN: 5957.0,
        FEB: 5656.0,
        MAR: 5810.0,
        APR: 5239.0,
        MAY: 4711.0,
        JUN: 4396.0,
        JUL: 4671.0,
        AUG: 5609.0,
        SEP: 6323.0,
        OCT: 6437.0,
        NOV: 6515.0,
        DEC: 6297.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -7.850461434175617],
              [-40.099045178706334, -7.750461434175618],
              [-39.999045178706332, -7.750461434175618],
              [-39.999045178706332, -7.850461434175617],
              [-40.099045178706334, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '754',
        ID: 42509.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -7.8005,
        ANNUAL: 5647.0,
        JAN: 5931.0,
        FEB: 5724.0,
        MAR: 5817.0,
        APR: 5269.0,
        MAY: 4742.0,
        JUN: 4382.0,
        JUL: 4717.0,
        AUG: 5608.0,
        SEP: 6304.0,
        OCT: 6460.0,
        NOV: 6536.0,
        DEC: 6276.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -7.850461434175617],
              [-39.999045178706332, -7.750461434175618],
              [-39.899045178706331, -7.750461434175618],
              [-39.899045178706331, -7.850461434175617],
              [-39.999045178706332, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '755',
        ID: 42510.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -7.8005,
        ANNUAL: 5642.0,
        JAN: 5936.0,
        FEB: 5731.0,
        MAR: 5796.0,
        APR: 5302.0,
        MAY: 4705.0,
        JUN: 4359.0,
        JUL: 4693.0,
        AUG: 5592.0,
        SEP: 6339.0,
        OCT: 6460.0,
        NOV: 6524.0,
        DEC: 6272.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -7.850461434175617],
              [-39.899045178706331, -7.750461434175618],
              [-39.799045178706329, -7.750461434175618],
              [-39.799045178706329, -7.850461434175617],
              [-39.899045178706331, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '756',
        ID: 42511.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -7.8005,
        ANNUAL: 5658.0,
        JAN: 5947.0,
        FEB: 5756.0,
        MAR: 5799.0,
        APR: 5310.0,
        MAY: 4711.0,
        JUN: 4378.0,
        JUL: 4678.0,
        AUG: 5582.0,
        SEP: 6389.0,
        OCT: 6500.0,
        NOV: 6536.0,
        DEC: 6303.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -7.850461434175617],
              [-39.799045178706329, -7.750461434175618],
              [-39.699045178706328, -7.750461434175618],
              [-39.699045178706328, -7.850461434175617],
              [-39.799045178706329, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '757',
        ID: 42512.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -7.8005,
        ANNUAL: 5692.0,
        JAN: 5965.0,
        FEB: 5780.0,
        MAR: 5826.0,
        APR: 5322.0,
        MAY: 4790.0,
        JUN: 4489.0,
        JUL: 4736.0,
        AUG: 5636.0,
        SEP: 6395.0,
        OCT: 6528.0,
        NOV: 6549.0,
        DEC: 6296.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -7.850461434175617],
              [-39.699045178706328, -7.750461434175618],
              [-39.599045178706326, -7.750461434175618],
              [-39.599045178706326, -7.850461434175617],
              [-39.699045178706328, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '758',
        ID: 42513.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -7.8005,
        ANNUAL: 5686.0,
        JAN: 5962.0,
        FEB: 5787.0,
        MAR: 5808.0,
        APR: 5328.0,
        MAY: 4768.0,
        JUN: 4460.0,
        JUL: 4729.0,
        AUG: 5612.0,
        SEP: 6422.0,
        OCT: 6508.0,
        NOV: 6572.0,
        DEC: 6272.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -7.850461434175617],
              [-39.599045178706326, -7.750461434175618],
              [-39.499045178706325, -7.750461434175618],
              [-39.499045178706325, -7.850461434175617],
              [-39.599045178706326, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '759',
        ID: 42514.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -7.8005,
        ANNUAL: 5697.0,
        JAN: 5982.0,
        FEB: 5794.0,
        MAR: 5813.0,
        APR: 5352.0,
        MAY: 4769.0,
        JUN: 4454.0,
        JUL: 4743.0,
        AUG: 5598.0,
        SEP: 6438.0,
        OCT: 6523.0,
        NOV: 6612.0,
        DEC: 6289.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -7.850461434175617],
              [-39.499045178706325, -7.750461434175618],
              [-39.399045178706324, -7.750461434175618],
              [-39.399045178706324, -7.850461434175617],
              [-39.499045178706325, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '760',
        ID: 42515.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -7.8005,
        ANNUAL: 5717.0,
        JAN: 6051.0,
        FEB: 5858.0,
        MAR: 5823.0,
        APR: 5386.0,
        MAY: 4748.0,
        JUN: 4451.0,
        JUL: 4714.0,
        AUG: 5584.0,
        SEP: 6469.0,
        OCT: 6517.0,
        NOV: 6664.0,
        DEC: 6341.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.399045178706324, -7.850461434175617],
              [-39.399045178706324, -7.750461434175618],
              [-39.299045178706322, -7.750461434175618],
              [-39.299045178706322, -7.850461434175617],
              [-39.399045178706324, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '761',
        ID: 42516.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -7.8005,
        ANNUAL: 5685.0,
        JAN: 6026.0,
        FEB: 5896.0,
        MAR: 5816.0,
        APR: 5397.0,
        MAY: 4681.0,
        JUN: 4405.0,
        JUL: 4670.0,
        AUG: 5499.0,
        SEP: 6408.0,
        OCT: 6465.0,
        NOV: 6645.0,
        DEC: 6309.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.299045178706322, -7.850461434175617],
              [-39.299045178706322, -7.750461434175618],
              [-39.199045178706321, -7.750461434175618],
              [-39.199045178706321, -7.850461434175617],
              [-39.299045178706322, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '762',
        ID: 42517.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -7.8005,
        ANNUAL: 5708.0,
        JAN: 5960.0,
        FEB: 5992.0,
        MAR: 5917.0,
        APR: 5460.0,
        MAY: 4716.0,
        JUN: 4417.0,
        JUL: 4726.0,
        AUG: 5500.0,
        SEP: 6413.0,
        OCT: 6441.0,
        NOV: 6630.0,
        DEC: 6329.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.109385204, -7.756830375999921],
              [-39.106353896, -7.760226285999973],
              [-39.104795262, -7.765612016999969],
              [-39.103446609999899, -7.769342517999963],
              [-39.102617137, -7.771038248999952],
              [-39.1003332619999, -7.775707695999937],
              [-39.100559313, -7.779926549999973],
              [-39.102114813999897, -7.784809606999942],
              [-39.101905958, -7.786527803999943],
              [-39.101817949999898, -7.787251818999952],
              [-39.101373426, -7.788421748999946],
              [-39.101227466, -7.788805898999949],
              [-39.099045178706319, -7.791179354147591],
              [-39.099045178706319, -7.850461434175617],
              [-39.199045178706321, -7.850461434175617],
              [-39.199045178706321, -7.750461434175618],
              [-39.116932153948575, -7.750461434175618],
              [-39.109385204, -7.756830375999921],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '763',
        ID: 42518.0,
        COUNTRY: 'Brasil',
        LON: -39.049,
        LAT: -7.8005,
        ANNUAL: 5743.0,
        JAN: 5999.0,
        FEB: 6003.0,
        MAR: 5982.0,
        APR: 5477.0,
        MAY: 4816.0,
        JUN: 4496.0,
        JUL: 4777.0,
        AUG: 5535.0,
        SEP: 6445.0,
        OCT: 6439.0,
        NOV: 6617.0,
        DEC: 6324.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.097892126, -7.792433413999952],
              [-39.096409520999899, -7.795098714999937],
              [-39.094484145, -7.802349574999941],
              [-39.092705588999898, -7.805015181999977],
              [-39.09055512, -7.809604599999942],
              [-39.090555461999898, -7.811671606999937],
              [-39.091371185999897, -7.815745041999943],
              [-39.09241113, -7.818113725999972],
              [-39.092857218999903, -7.821295595999962],
              [-39.092559467999898, -7.82306999599996],
              [-39.091937255999902, -7.824362779999939],
              [-39.091720589, -7.824812953999979],
              [-39.089283887, -7.829876611999958],
              [-39.087965042, -7.832616480999943],
              [-39.087964691, -7.832618004999972],
              [-39.087964082, -7.83263267499995],
              [-39.087817253, -7.836171780999963],
              [-39.088188018, -7.837726147999931],
              [-39.088327449, -7.837909051999924],
              [-39.088686278, -7.838379692999982],
              [-39.0895238539999, -7.839478303999942],
              [-39.089597802999897, -7.839575299999979],
              [-39.093143671, -7.842488722999971],
              [-39.093602034, -7.843032351999967],
              [-39.095767259999903, -7.846436082999953],
              [-39.095917953, -7.847154900999946],
              [-39.096547227, -7.85015657699995],
              [-39.096438204709479, -7.850461434175617],
              [-39.099045178706319, -7.850461434175617],
              [-39.099045178706319, -7.791179354147591],
              [-39.097892126, -7.792433413999952],
            ],
          ],
          [
            [
              [-39.071852319, -7.844536335999979],
              [-39.067626022999903, -7.841874493999976],
              [-39.064879828999899, -7.839504232999957],
              [-39.06280387, -7.83684274799998],
              [-39.061691762, -7.834470925999939],
              [-39.061173201, -7.833364974999943],
              [-39.060968229, -7.83273823599995],
              [-39.06096099, -7.832716100999959],
              [-39.060799109, -7.832221119999938],
              [-39.060761035, -7.832104701999983],
              [-39.060267801, -7.830596545999976],
              [-39.060232654999901, -7.830489079999947],
              [-39.06021978, -7.83044971399993],
              [-39.060216282999903, -7.830439137999971],
              [-39.059989819, -7.829746696999967],
              [-39.057762128, -7.822934984999955],
              [-39.057760818, -7.822930976999931],
              [-39.056350262, -7.821005023999926],
              [-39.053755678, -7.81930463899994],
              [-39.050788936999901, -7.819378948999946],
              [-39.047889644999898, -7.819843313999966],
              [-39.044524886, -7.820101931999942],
              [-39.040415515999896, -7.820417779999965],
              [-39.039443848, -7.820563056999966],
              [-39.03832605, -7.820820884999933],
              [-39.033659583, -7.82189723499994],
              [-39.028913588, -7.824858144999948],
              [-39.02639314, -7.824635071999976],
              [-39.0252069699999, -7.823376893999979],
              [-39.023275879, -7.819011482999947],
              [-39.022759812999908, -7.816718180999946],
              [-39.021593, -7.814854204999953],
              [-39.019314062, -7.813035232999936],
              [-39.018041944999908, -7.812666020999929],
              [-39.016370336, -7.81218070799997],
              [-39.01369138, -7.812872971999983],
              [-39.013569484, -7.812960385999923],
              [-39.011829064, -7.814208482999964],
              [-39.010612081, -7.81618145099998],
              [-39.0104694629999, -7.816412662999942],
              [-39.007519843999901, -7.823593746999955],
              [-39.005353525, -7.83450341799993],
              [-39.004587702, -7.835935549999923],
              [-39.003414333, -7.838129816999986],
              [-39.001408642999898, -7.840126080999931],
              [-38.999685629999902, -7.840182956999971],
              [-38.999045178706318, -7.840204098012],
              [-38.999045178706318, -7.850461434175617],
              [-39.082997076142775, -7.850461434175617],
              [-39.071852319, -7.844536335999979],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '764',
        ID: 42519.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -7.8005,
        ANNUAL: 5752.0,
        JAN: 6015.0,
        FEB: 5982.0,
        MAR: 5974.0,
        APR: 5488.0,
        MAY: 4891.0,
        JUN: 4528.0,
        JUL: 4797.0,
        AUG: 5573.0,
        SEP: 6479.0,
        OCT: 6454.0,
        NOV: 6580.0,
        DEC: 6267.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.994780837999905, -7.840344861999949],
              [-38.991078856999899, -7.843589911999969],
              [-38.990357854, -7.844001720999939],
              [-38.988945439, -7.844371465999934],
              [-38.982244611999903, -7.846125620999944],
              [-38.979322314999898, -7.846130596999964],
              [-38.977495975, -7.845523259999944],
              [-38.975491744, -7.843248043999956],
              [-38.974638921, -7.842974461999965],
              [-38.970299462999897, -7.845807607999964],
              [-38.9702860209999, -7.845807034999958],
              [-38.970262541, -7.845806032999974],
              [-38.966165541, -7.845631319999949],
              [-38.966018745, -7.845625059999962],
              [-38.965650317, -7.845609348999976],
              [-38.96285947, -7.844816396999926],
              [-38.96211364, -7.844174620999985],
              [-38.959774245999903, -7.842158288999934],
              [-38.959213882, -7.840271697999975],
              [-38.959361147, -7.838263432999955],
              [-38.960149210999901, -7.837211783999978],
              [-38.964517631, -7.833709451999943],
              [-38.965496116999901, -7.831791680999968],
              [-38.964940356999897, -7.829828496999941],
              [-38.9580744249999, -7.825610477999933],
              [-38.954049186, -7.821517674999946],
              [-38.953250258, -7.819378844999962],
              [-38.952855273, -7.810546508999945],
              [-38.951441393, -7.809045400999929],
              [-38.947798651, -7.807141221999932],
              [-38.947795753, -7.807138126999972],
              [-38.944769812999901, -7.803907319999956],
              [-38.939965104, -7.795105699999966],
              [-38.939798251, -7.791555718999977],
              [-38.940724598, -7.790213082999973],
              [-38.945079314999901, -7.786161351999961],
              [-38.94536872, -7.785366296999941],
              [-38.946295354, -7.782820641999929],
              [-38.946381765999902, -7.781397475999948],
              [-38.9446786829999, -7.776830556999921],
              [-38.941022592, -7.771412818999988],
              [-38.941186559999899, -7.768034306999939],
              [-38.942186310999901, -7.764372084999924],
              [-38.941964368, -7.763143111999973],
              [-38.941848263999901, -7.76250207699997],
              [-38.941797721999897, -7.762223020999953],
              [-38.9417889979999, -7.76217485499996],
              [-38.941774494, -7.762094774999976],
              [-38.941634303999898, -7.76132075299996],
              [-38.93957806, -7.758772626999926],
              [-38.937301788999903, -7.758231871999947],
              [-38.935026566, -7.758957977999964],
              [-38.934051516, -7.758830096999926],
              [-38.927629633, -7.753806302999971],
              [-38.923436914, -7.753656597999965],
              [-38.9202188249999, -7.754567286999939],
              [-38.920217554, -7.75456956199996],
              [-38.918131483, -7.758302090999955],
              [-38.917360320999904, -7.758852155999938],
              [-38.917345012999903, -7.758848307999936],
              [-38.916110851, -7.758538125999958],
              [-38.915161301, -7.756939667999961],
              [-38.915332673, -7.753706141999934],
              [-38.916779129188946, -7.750461434175618],
              [-38.899045178706317, -7.750461434175618],
              [-38.899045178706317, -7.850461434175617],
              [-38.999045178706318, -7.850461434175617],
              [-38.999045178706318, -7.840204098012],
              [-38.994780837999905, -7.840344861999949],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '765',
        ID: 42520.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -7.8005,
        ANNUAL: 5784.0,
        JAN: 6061.0,
        FEB: 5995.0,
        MAR: 5992.0,
        APR: 5545.0,
        MAY: 4899.0,
        JUN: 4549.0,
        JUL: 4832.0,
        AUG: 5622.0,
        SEP: 6532.0,
        OCT: 6495.0,
        NOV: 6613.0,
        DEC: 6273.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.899045178706317, -7.850461434175617],
              [-38.899045178706317, -7.750461434175618],
              [-38.799045178706315, -7.750461434175618],
              [-38.799045178706315, -7.850461434175617],
              [-38.899045178706317, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '766',
        ID: 42521.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -7.8005,
        ANNUAL: 5791.0,
        JAN: 6099.0,
        FEB: 6015.0,
        MAR: 5947.0,
        APR: 5579.0,
        MAY: 4892.0,
        JUN: 4539.0,
        JUL: 4848.0,
        AUG: 5658.0,
        SEP: 6532.0,
        OCT: 6511.0,
        NOV: 6605.0,
        DEC: 6271.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.799045178706315, -7.850461434175617],
              [-38.799045178706315, -7.750461434175618],
              [-38.699045178706314, -7.750461434175618],
              [-38.699045178706314, -7.850461434175617],
              [-38.799045178706315, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '767',
        ID: 42522.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -7.8005,
        ANNUAL: 5809.0,
        JAN: 6116.0,
        FEB: 6050.0,
        MAR: 5974.0,
        APR: 5616.0,
        MAY: 4890.0,
        JUN: 4534.0,
        JUL: 4854.0,
        AUG: 5692.0,
        SEP: 6557.0,
        OCT: 6539.0,
        NOV: 6617.0,
        DEC: 6276.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.699045178706314, -7.850461434175617],
              [-38.699045178706314, -7.750461434175618],
              [-38.599045178706312, -7.750461434175618],
              [-38.599045178706312, -7.850461434175617],
              [-38.699045178706314, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '768',
        ID: 42523.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -7.8005,
        ANNUAL: 5831.0,
        JAN: 6177.0,
        FEB: 6077.0,
        MAR: 6006.0,
        APR: 5623.0,
        MAY: 4937.0,
        JUN: 4564.0,
        JUL: 4817.0,
        AUG: 5665.0,
        SEP: 6542.0,
        OCT: 6562.0,
        NOV: 6663.0,
        DEC: 6337.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.596409752999897, -7.751144276999981],
              [-38.596024095, -7.752048946999926],
              [-38.593627441, -7.754349396999932],
              [-38.589957431999899, -7.755681464999955],
              [-38.586046075, -7.754905312999944],
              [-38.584322341, -7.753449106999949],
              [-38.582944464890041, -7.750461434175618],
              [-38.576159307934866, -7.750461434175618],
              [-38.576183267, -7.752284814999943],
              [-38.575713009, -7.75368083299998],
              [-38.574827635, -7.754337306999959],
              [-38.574315753999898, -7.753178130999962],
              [-38.573966882, -7.752388097999927],
              [-38.573239147, -7.75166383799996],
              [-38.572104101, -7.751474760999931],
              [-38.568492459, -7.753208916999949],
              [-38.567700745, -7.754070670999962],
              [-38.5674374459999, -7.754357262999969],
              [-38.566961594, -7.754875212999927],
              [-38.565152401, -7.755960752999949],
              [-38.563932786999899, -7.756692537999981],
              [-38.562031244, -7.75689232299992],
              [-38.560129565999901, -7.757092122999942],
              [-38.558977784, -7.75682860299997],
              [-38.557826018999897, -7.756565085999972],
              [-38.552826677, -7.753699755999951],
              [-38.549212659, -7.752313670999975],
              [-38.547489631, -7.751084687999934],
              [-38.546993973347647, -7.750461434175618],
              [-38.542124429559685, -7.750461434175618],
              [-38.541218188, -7.751773244999982],
              [-38.538526504999901, -7.753257940999956],
              [-38.534249498, -7.754416238999979],
              [-38.53011636, -7.755043405999978],
              [-38.529682341999901, -7.75510926399994],
              [-38.524957108, -7.754644759999961],
              [-38.519246162516616, -7.750461434175618],
              [-38.505139098345978, -7.750461434175618],
              [-38.500548695, -7.751482147999984],
              [-38.499045178706311, -7.750933358184176],
              [-38.499045178706311, -7.850461434175617],
              [-38.599045178706312, -7.850461434175617],
              [-38.599045178706312, -7.750461434175618],
              [-38.596559998702631, -7.750461434175618],
              [-38.596409752999897, -7.751144276999981],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '769',
        ID: 42524.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -7.8005,
        ANNUAL: 5820.0,
        JAN: 6221.0,
        FEB: 6106.0,
        MAR: 6035.0,
        APR: 5626.0,
        MAY: 4903.0,
        JUN: 4500.0,
        JUL: 4745.0,
        AUG: 5631.0,
        SEP: 6498.0,
        OCT: 6553.0,
        NOV: 6696.0,
        DEC: 6325.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.497752251221485, -7.750461434175618],
              [-38.399045178706309, -7.750461434175618],
              [-38.399045178706309, -7.850461434175617],
              [-38.499045178706311, -7.850461434175617],
              [-38.499045178706311, -7.750933358184176],
              [-38.497752251221485, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '770',
        ID: 42525.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -7.8005,
        ANNUAL: 5832.0,
        JAN: 6219.0,
        FEB: 6118.0,
        MAR: 6079.0,
        APR: 5695.0,
        MAY: 4956.0,
        JUN: 4512.0,
        JUL: 4763.0,
        AUG: 5664.0,
        SEP: 6460.0,
        OCT: 6513.0,
        NOV: 6708.0,
        DEC: 6292.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.305702129999901, -7.752598775999952],
              [-38.31675985, -7.764607493999967],
              [-38.315621666, -7.765123643999967],
              [-38.314483458, -7.765639804999971],
              [-38.313671859, -7.765726521999965],
              [-38.312860096999898, -7.765813213999927],
              [-38.310693375, -7.765372279999955],
              [-38.303339122999901, -7.76208393599995],
              [-38.301264911, -7.762342433999947],
              [-38.299817233999903, -7.763195613999937],
              [-38.299454446, -7.763980107999941],
              [-38.300984495, -7.767041933999965],
              [-38.301252362999897, -7.768703900999984],
              [-38.29984795, -7.771802516999974],
              [-38.299045178706308, -7.772458862448071],
              [-38.299045178706308, -7.782446136820281],
              [-38.300235425, -7.783415556999956],
              [-38.3025747, -7.788398809999928],
              [-38.302524323999897, -7.791501231999972],
              [-38.299045178706308, -7.797347344846893],
              [-38.299045178706308, -7.850461434175617],
              [-38.399045178706309, -7.850461434175617],
              [-38.399045178706309, -7.750461434175618],
              [-38.304316371755895, -7.750461434175618],
              [-38.305702129999901, -7.752598775999952],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '771',
        ID: 42526.0,
        COUNTRY: 'Brasil',
        LON: -38.249,
        LAT: -7.8005,
        ANNUAL: 5823.0,
        JAN: 6072.0,
        FEB: 6007.0,
        MAR: 6046.0,
        APR: 5715.0,
        MAY: 4954.0,
        JUN: 4595.0,
        JUL: 4853.0,
        AUG: 5722.0,
        SEP: 6454.0,
        OCT: 6528.0,
        NOV: 6654.0,
        DEC: 6276.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.295688513999899, -7.775203269999955],
              [-38.294601411, -7.777970216999964],
              [-38.295535861, -7.779587901999951],
              [-38.299045178706308, -7.782446136820281],
              [-38.299045178706308, -7.772458862448071],
              [-38.295688513999899, -7.775203269999955],
            ],
          ],
          [
            [
              [-38.298992461, -7.79743592799997],
              [-38.298220445, -7.800519464999986],
              [-38.297764040999901, -7.804363712999926],
              [-38.297746525, -7.813946935999978],
              [-38.293351102, -7.823045897999975],
              [-38.290595229, -7.824307083999942],
              [-38.2878828, -7.826137724999962],
              [-38.286526572, -7.82762008899994],
              [-38.286343839, -7.828999781999925],
              [-38.28679313, -7.830224215999985],
              [-38.285707432, -7.830922380999937],
              [-38.282321239999902, -7.830873934999929],
              [-38.280514971999899, -7.83148073199993],
              [-38.276227304, -7.833819755999964],
              [-38.275454221, -7.834241173999938],
              [-38.274417281, -7.834074032999985],
              [-38.271887267999901, -7.833666228999966],
              [-38.26755936, -7.829202866999933],
              [-38.264943453999898, -7.827976472999978],
              [-38.263092082, -7.82810439299993],
              [-38.259205231, -7.830456700999948],
              [-38.2570368619999, -7.831064261999933],
              [-38.245162616, -7.831173883999945],
              [-38.238570689, -7.830726116999932],
              [-38.236636452, -7.826834348999962],
              [-38.235464336999897, -7.825958282999984],
              [-38.228288439, -7.824066968999934],
              [-38.22359168, -7.821352437999963],
              [-38.221922827, -7.820388064999972],
              [-38.218611258, -7.818473960999945],
              [-38.215284053999902, -7.815436797999953],
              [-38.212674175, -7.810712701999932],
              [-38.207839479, -7.808565543999983],
              [-38.205861563, -7.807687122999935],
              [-38.2057066039999, -7.80768076399994],
              [-38.203604126999906, -7.807594491999962],
              [-38.199045178706307, -7.804854740343989],
              [-38.199045178706307, -7.850461434175617],
              [-38.299045178706308, -7.850461434175617],
              [-38.299045178706308, -7.797347344846893],
              [-38.298992461, -7.79743592799997],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '772',
        ID: 42527.0,
        COUNTRY: 'Brasil',
        LON: -38.149,
        LAT: -7.8005,
        ANNUAL: 5864.0,
        JAN: 6137.0,
        FEB: 6070.0,
        MAR: 6101.0,
        APR: 5707.0,
        MAY: 4953.0,
        JUN: 4617.0,
        JUL: 4876.0,
        AUG: 5751.0,
        SEP: 6529.0,
        OCT: 6587.0,
        NOV: 6713.0,
        DEC: 6332.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.184278724, -7.795980671999925],
              [-38.183989588, -7.79554913699996],
              [-38.183932554, -7.795464013999952],
              [-38.182432354, -7.793224966999964],
              [-38.179906057, -7.791822566999937],
              [-38.178916838, -7.789897382999918],
              [-38.177383715, -7.788497729999965],
              [-38.174672015999903, -7.78989022899997],
              [-38.17165467, -7.786608584999944],
              [-38.166335838999899, -7.78301488899996],
              [-38.164983175, -7.781528416999946],
              [-38.164218992, -7.77990879999993],
              [-38.162672238, -7.779953426999952],
              [-38.162403393, -7.779961178999938],
              [-38.162123741, -7.779969242999935],
              [-38.155731373, -7.780153571999964],
              [-38.153382974999907, -7.780848502999953],
              [-38.150031472, -7.782874800999935],
              [-38.148473829, -7.783816541999952],
              [-38.146247229, -7.784127797999931],
              [-38.1457463469999, -7.784197762999946],
              [-38.144754193, -7.783801978999976],
              [-38.143761944999902, -7.783406157999935],
              [-38.142316305, -7.784146110999929],
              [-38.140870628, -7.784886082999943],
              [-38.139196311, -7.78623808199995],
              [-38.138107648, -7.788682638999938],
              [-38.131425491, -7.795757673999967],
              [-38.131563153999899, -7.796327567999978],
              [-38.131101088, -7.799057],
              [-38.1306390149999, -7.801786476999957],
              [-38.130898628999901, -7.802827190999952],
              [-38.131158243999899, -7.803867907999975],
              [-38.12943648200001, -7.805900569999956],
              [-38.125656385, -7.805344532999914],
              [-38.1224361989999, -7.807434479999929],
              [-38.124153970999899, -7.812980930999963],
              [-38.120055982999901, -7.818974455999975],
              [-38.118709773, -7.820943358999952],
              [-38.1161781509999, -7.822560182999952],
              [-38.106996502999898, -7.822833644999952],
              [-38.106123097, -7.822859657999968],
              [-38.102416521999899, -7.82498813599993],
              [-38.1014211359999, -7.825559729999943],
              [-38.100713449999901, -7.825966113999934],
              [-38.100711723, -7.825966790999935],
              [-38.099045178706305, -7.826619897792239],
              [-38.099045178706305, -7.850461434175617],
              [-38.199045178706307, -7.850461434175617],
              [-38.199045178706307, -7.804854740343989],
              [-38.184278724, -7.795980671999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '773',
        ID: 42528.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -7.8005,
        ANNUAL: 5876.0,
        JAN: 6190.0,
        FEB: 6123.0,
        MAR: 6143.0,
        APR: 5735.0,
        MAY: 4963.0,
        JUN: 4592.0,
        JUL: 4827.0,
        AUG: 5707.0,
        SEP: 6519.0,
        OCT: 6605.0,
        NOV: 6760.0,
        DEC: 6355.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.097240713, -7.827327054999956],
              [-38.097161887, -7.827326036999977],
              [-38.095366116, -7.82730284299992],
              [-38.095244728999901, -7.827301274999972],
              [-38.090764513, -7.827243409999935],
              [-38.083867594, -7.828004732999938],
              [-38.083338931999904, -7.828063238999951],
              [-38.082732452, -7.828306681999948],
              [-38.0805520219999, -7.829181913999953],
              [-38.077069438, -7.830579561999969],
              [-38.073515776, -7.828124095999958],
              [-38.069376533, -7.826117067999973],
              [-38.065350005, -7.819747762999943],
              [-38.066305196, -7.817666328999955],
              [-38.068541262999901, -7.812793769999923],
              [-38.075741143, -7.805383615999965],
              [-38.075739844999902, -7.799876952999979],
              [-38.07492338, -7.797578752999921],
              [-38.07348139, -7.793519811999938],
              [-38.075214356999901, -7.789124789999964],
              [-38.075765401, -7.787727273999963],
              [-38.075263315, -7.782418676999957],
              [-38.071398519999903, -7.774234443999934],
              [-38.0694782959999, -7.772324340999933],
              [-38.065736050999902, -7.770023346999943],
              [-38.061027689999896, -7.766923178999948],
              [-38.051872284, -7.758024881999977],
              [-38.046994874999903, -7.754441371999971],
              [-38.04232816068086, -7.750461434175618],
              [-38.029556222113634, -7.750461434175618],
              [-38.028085679, -7.753024649999923],
              [-38.027788088, -7.753222088999959],
              [-38.027701824, -7.753279320999976],
              [-38.0257067269999, -7.754602983999972],
              [-38.022803597999904, -7.754699314999923],
              [-38.018535701, -7.758366400999937],
              [-38.018384545999901, -7.758496287999948],
              [-38.015998327, -7.762108708999962],
              [-38.01499874, -7.763154032999973],
              [-38.012463759, -7.765804953999973],
              [-38.009429156, -7.766553071999976],
              [-38.008146778, -7.766869214999936],
              [-38.006873051, -7.769206040999964],
              [-38.006863646999903, -7.773396627999944],
              [-38.005453823, -7.777762056999953],
              [-38.0039709549999, -7.778986407999979],
              [-38.003582326999897, -7.778983312999968],
              [-38.000719140999898, -7.778960512999959],
              [-37.999045178706304, -7.778947183193746],
              [-37.999045178706304, -7.850461434175617],
              [-38.099045178706305, -7.850461434175617],
              [-38.099045178706305, -7.826619897792239],
              [-38.097240713, -7.827327054999956],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '774',
        ID: 42529.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -7.8005,
        ANNUAL: 5805.0,
        JAN: 6179.0,
        FEB: 6131.0,
        MAR: 6135.0,
        APR: 5724.0,
        MAY: 4879.0,
        JUN: 4445.0,
        JUL: 4676.0,
        AUG: 5528.0,
        SEP: 6368.0,
        OCT: 6506.0,
        NOV: 6747.0,
        DEC: 6341.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.998893829999901, -7.778945977999976],
              [-37.990267483, -7.778877285999948],
              [-37.988476252999902, -7.777696163999956],
              [-37.984790175999898, -7.772936982999968],
              [-37.982967856, -7.772648640999929],
              [-37.976416257, -7.774780236999965],
              [-37.972713749, -7.777530071999928],
              [-37.971363379, -7.777559985999972],
              [-37.971328131999897, -7.777560766999954],
              [-37.970599837999899, -7.777576900999962],
              [-37.970034819, -7.777128913999945],
              [-37.968206925999901, -7.775679631999938],
              [-37.966674455, -7.774332005999943],
              [-37.964802032, -7.77268601099996],
              [-37.961410649, -7.770394781999921],
              [-37.959088431999902, -7.768711126999956],
              [-37.957899719999901, -7.766543795999954],
              [-37.957805606, -7.766325886999937],
              [-37.957762241, -7.766225479999956],
              [-37.957700663, -7.766082901999948],
              [-37.956796439, -7.763989275999955],
              [-37.957296196999899, -7.763299954999988],
              [-37.957141099999902, -7.761809297999949],
              [-37.956434545, -7.760706383999952],
              [-37.9557796899999, -7.760499586999963],
              [-37.955348301, -7.760635924999974],
              [-37.954694009, -7.760689149999965],
              [-37.953194735, -7.758741817999981],
              [-37.952759601, -7.758358382999976],
              [-37.953104262, -7.757505347999934],
              [-37.952574345999899, -7.756630770999957],
              [-37.947545653, -7.756221899999953],
              [-37.946053288, -7.751121444999976],
              [-37.945860172417483, -7.750461434175618],
              [-37.899045178706302, -7.750461434175618],
              [-37.899045178706302, -7.850461434175617],
              [-37.999045178706304, -7.850461434175617],
              [-37.999045178706304, -7.778947183193746],
              [-37.998893829999901, -7.778945977999976],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '775',
        ID: 42530.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -7.8005,
        ANNUAL: 5820.0,
        JAN: 6228.0,
        FEB: 6162.0,
        MAR: 6186.0,
        APR: 5741.0,
        MAY: 4898.0,
        JUN: 4446.0,
        JUL: 4656.0,
        AUG: 5496.0,
        SEP: 6368.0,
        OCT: 6508.0,
        NOV: 6760.0,
        DEC: 6387.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.899045178706302, -7.850461434175617],
              [-37.899045178706302, -7.750461434175618],
              [-37.799045178706301, -7.750461434175618],
              [-37.799045178706301, -7.850461434175617],
              [-37.899045178706302, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '776',
        ID: 42531.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -7.8005,
        ANNUAL: 5843.0,
        JAN: 6262.0,
        FEB: 6168.0,
        MAR: 6132.0,
        APR: 5740.0,
        MAY: 4968.0,
        JUN: 4534.0,
        JUL: 4705.0,
        AUG: 5523.0,
        SEP: 6379.0,
        OCT: 6536.0,
        NOV: 6753.0,
        DEC: 6417.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.799045178706301, -7.850461434175617],
              [-37.799045178706301, -7.750461434175618],
              [-37.699045178706299, -7.750461434175618],
              [-37.699045178706299, -7.850461434175617],
              [-37.799045178706301, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '777',
        ID: 42532.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -7.8005,
        ANNUAL: 5815.0,
        JAN: 6198.0,
        FEB: 6150.0,
        MAR: 6076.0,
        APR: 5703.0,
        MAY: 4966.0,
        JUN: 4540.0,
        JUL: 4676.0,
        AUG: 5511.0,
        SEP: 6359.0,
        OCT: 6498.0,
        NOV: 6717.0,
        DEC: 6388.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -7.850461434175617],
              [-37.699045178706299, -7.750461434175618],
              [-37.599045178706298, -7.750461434175618],
              [-37.599045178706298, -7.850461434175617],
              [-37.699045178706299, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '778',
        ID: 42533.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -7.8005,
        ANNUAL: 5837.0,
        JAN: 6215.0,
        FEB: 6156.0,
        MAR: 6085.0,
        APR: 5711.0,
        MAY: 4986.0,
        JUN: 4562.0,
        JUL: 4731.0,
        AUG: 5558.0,
        SEP: 6369.0,
        OCT: 6518.0,
        NOV: 6742.0,
        DEC: 6412.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -7.850461434175617],
              [-37.599045178706298, -7.750461434175618],
              [-37.499045178706297, -7.750461434175618],
              [-37.499045178706297, -7.850461434175617],
              [-37.599045178706298, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '779',
        ID: 42534.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -7.8005,
        ANNUAL: 5809.0,
        JAN: 6225.0,
        FEB: 6107.0,
        MAR: 6058.0,
        APR: 5685.0,
        MAY: 4968.0,
        JUN: 4508.0,
        JUL: 4674.0,
        AUG: 5496.0,
        SEP: 6334.0,
        OCT: 6529.0,
        NOV: 6726.0,
        DEC: 6402.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -7.850461434175617],
              [-37.499045178706297, -7.750461434175618],
              [-37.399045178706295, -7.750461434175618],
              [-37.399045178706295, -7.850461434175617],
              [-37.499045178706297, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '780',
        ID: 42535.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -7.8005,
        ANNUAL: 5803.0,
        JAN: 6328.0,
        FEB: 6077.0,
        MAR: 6065.0,
        APR: 5693.0,
        MAY: 4919.0,
        JUN: 4419.0,
        JUL: 4629.0,
        AUG: 5451.0,
        SEP: 6306.0,
        OCT: 6534.0,
        NOV: 6817.0,
        DEC: 6401.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -7.850461434175617],
              [-37.399045178706295, -7.750461434175618],
              [-37.299045178706294, -7.750461434175618],
              [-37.299045178706294, -7.850461434175617],
              [-37.399045178706295, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '781',
        ID: 42536.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -7.8005,
        ANNUAL: 5810.0,
        JAN: 6310.0,
        FEB: 6105.0,
        MAR: 6098.0,
        APR: 5693.0,
        MAY: 4936.0,
        JUN: 4446.0,
        JUL: 4666.0,
        AUG: 5413.0,
        SEP: 6318.0,
        OCT: 6543.0,
        NOV: 6795.0,
        DEC: 6391.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.217416027999903, -7.806685878999933],
              [-37.222996784, -7.804063285999974],
              [-37.223000655, -7.804061466999937],
              [-37.230561764, -7.806740967999928],
              [-37.230102937, -7.812793706999967],
              [-37.2301771039999, -7.813109070999926],
              [-37.24365613, -7.819001766999963],
              [-37.240461415, -7.82309426399997],
              [-37.231862748, -7.820758523999927],
              [-37.228105426, -7.821153514999927],
              [-37.228154862999901, -7.821518289999966],
              [-37.226543933, -7.828476750999924],
              [-37.224926426, -7.832721897999931],
              [-37.222813675999902, -7.836209264999983],
              [-37.222204540999897, -7.838660185999968],
              [-37.222092905, -7.842250017999958],
              [-37.222484612, -7.842830157999961],
              [-37.225867665, -7.844678460999946],
              [-37.227536061, -7.844690195999937],
              [-37.229530983, -7.844088439999954],
              [-37.238339928999899, -7.843931168999979],
              [-37.247150250999901, -7.846803934999972],
              [-37.247783474, -7.847756286999984],
              [-37.247639420068701, -7.850461434175617],
              [-37.299045178706294, -7.850461434175617],
              [-37.299045178706294, -7.750461434175618],
              [-37.199045178706292, -7.750461434175618],
              [-37.199045178706292, -7.799350279183365],
              [-37.217416027999903, -7.806685878999933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '782',
        ID: 42537.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -7.8005,
        ANNUAL: 5804.0,
        JAN: 6269.0,
        FEB: 6112.0,
        MAR: 6141.0,
        APR: 5712.0,
        MAY: 4961.0,
        JUN: 4480.0,
        JUL: 4663.0,
        AUG: 5382.0,
        SEP: 6291.0,
        OCT: 6513.0,
        NOV: 6740.0,
        DEC: 6390.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.143573266, -7.75427833299995],
              [-37.148328604, -7.758147135999923],
              [-37.149753819999901, -7.759678306999941],
              [-37.151668201999897, -7.762379688999943],
              [-37.153810925999899, -7.768120274999948],
              [-37.157032285999897, -7.772999460999932],
              [-37.157176529, -7.775454893999941],
              [-37.1567810069999, -7.776868321999939],
              [-37.151950733, -7.779844202999934],
              [-37.157199255, -7.790586797999945],
              [-37.158481261, -7.792332350999964],
              [-37.159812303, -7.793216263999963],
              [-37.163763059, -7.795839527999934],
              [-37.168254131, -7.795120340999975],
              [-37.168395603, -7.795122617999931],
              [-37.171366137999897, -7.795170425999965],
              [-37.171589461999901, -7.796536362999979],
              [-37.169728167, -7.797889630999984],
              [-37.1694540149999, -7.798797422999947],
              [-37.170667083, -7.800228535999954],
              [-37.17066807, -7.800229699999924],
              [-37.172486073999899, -7.799981010999939],
              [-37.17438297, -7.799721418999981],
              [-37.183035259, -7.790717242999966],
              [-37.187392545, -7.790734715999963],
              [-37.189032595999898, -7.791141725999988],
              [-37.191536767999899, -7.791169404999955],
              [-37.191538539, -7.791169424999954],
              [-37.197957773, -7.798916070999947],
              [-37.199045178706292, -7.799350279183365],
              [-37.199045178706292, -7.750461434175618],
              [-37.140937581738001, -7.750461434175618],
              [-37.143573266, -7.75427833299995],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '783',
        ID: 42544.0,
        COUNTRY: 'Brasil',
        LON: -36.449,
        LAT: -7.8005,
        ANNUAL: 5582.0,
        JAN: 6057.0,
        FEB: 5945.0,
        MAR: 5974.0,
        APR: 5506.0,
        MAY: 4806.0,
        JUN: 4330.0,
        JUL: 4491.0,
        AUG: 5196.0,
        SEP: 5923.0,
        OCT: 6179.0,
        NOV: 6423.0,
        DEC: 6157.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.430940049, -7.849847309999964],
              [-36.42834909, -7.833406028999951],
              [-36.429109976999897, -7.830440703999948],
              [-36.429964235999897, -7.827111488999957],
              [-36.428033303999896, -7.819603427999939],
              [-36.424047567999899, -7.815778198999954],
              [-36.407422625, -7.814075605999969],
              [-36.399428401, -7.81042668299995],
              [-36.399045178706281, -7.810205818751781],
              [-36.399045178706281, -7.850461434175617],
              [-36.430798431052338, -7.850461434175617],
              [-36.430940049, -7.849847309999964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '784',
        ID: 42545.0,
        COUNTRY: 'Brasil',
        LON: -36.349,
        LAT: -7.8005,
        ANNUAL: 5538.0,
        JAN: 6003.0,
        FEB: 5906.0,
        MAR: 5926.0,
        APR: 5460.0,
        MAY: 4788.0,
        JUN: 4321.0,
        JUL: 4457.0,
        AUG: 5170.0,
        SEP: 5855.0,
        OCT: 6122.0,
        NOV: 6350.0,
        DEC: 6091.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.3964276889999, -7.808697268999947],
              [-36.394538501999897, -7.807608754999929],
              [-36.39259636, -7.807647293999922],
              [-36.373453465, -7.812620559999974],
              [-36.373451714999902, -7.812620199999965],
              [-36.368247329, -7.811550202999974],
              [-36.366423706, -7.809066336999931],
              [-36.3631717069999, -7.806079500999937],
              [-36.360720678, -7.805718224999959],
              [-36.357544194, -7.80688481199995],
              [-36.353145427999898, -7.807646512999945],
              [-36.348547693999897, -7.807470054999949],
              [-36.340871557, -7.808755608999945],
              [-36.336884724, -7.809423299999935],
              [-36.330293084999902, -7.809504629999944],
              [-36.328996413, -7.809520628999954],
              [-36.328993785, -7.809520518999933],
              [-36.328947392000011, -7.809518583999929],
              [-36.320737959, -7.809176042999971],
              [-36.314185811, -7.806721567999949],
              [-36.313397848, -7.806426402999932],
              [-36.309131458, -7.806687193999951],
              [-36.302013325, -7.808337717999965],
              [-36.29904517870628, -7.809718918879161],
              [-36.29904517870628, -7.850461434175617],
              [-36.399045178706281, -7.850461434175617],
              [-36.399045178706281, -7.810205818751781],
              [-36.3964276889999, -7.808697268999947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '785',
        ID: 42546.0,
        COUNTRY: 'Brasil',
        LON: -36.249,
        LAT: -7.8005,
        ANNUAL: 5441.0,
        JAN: 5835.0,
        FEB: 5811.0,
        MAR: 5843.0,
        APR: 5336.0,
        MAY: 4680.0,
        JUN: 4268.0,
        JUL: 4411.0,
        AUG: 5129.0,
        SEP: 5746.0,
        OCT: 6029.0,
        NOV: 6253.0,
        DEC: 5948.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.2943619979999, -7.811898195999961],
              [-36.285242474, -7.817890885999936],
              [-36.279369741, -7.825065644999937],
              [-36.271110193, -7.830565300999986],
              [-36.271108077999898, -7.830565627999931],
              [-36.267830994999898, -7.831072403999948],
              [-36.263506026999899, -7.831741225999962],
              [-36.26133458, -7.832077022999957],
              [-36.261332895, -7.832076577999937],
              [-36.255261403, -7.830472146999969],
              [-36.253293717, -7.829952015999945],
              [-36.247495334, -7.826101512999962],
              [-36.24340675, -7.822178784999949],
              [-36.240012084999897, -7.817680998999953],
              [-36.239538361, -7.811211507999927],
              [-36.242305509999902, -7.807333511999955],
              [-36.251232683, -7.804574061999966],
              [-36.254336355, -7.801693300999944],
              [-36.254658439, -7.799650650999985],
              [-36.254196025999903, -7.79638681399996],
              [-36.252000231, -7.792144682999942],
              [-36.246305441999901, -7.785310667999974],
              [-36.238790208, -7.779869695999925],
              [-36.233942355999901, -7.776359888999924],
              [-36.2272181169999, -7.769974604999952],
              [-36.220480121, -7.765620959999976],
              [-36.216634255, -7.764104163999955],
              [-36.216632843, -7.764104889999941],
              [-36.2113059939999, -7.766845652999951],
              [-36.206484965, -7.770377746999935],
              [-36.204667125999897, -7.773558162999969],
              [-36.20434868, -7.782962722999966],
              [-36.20095837799991, -7.789903159999941],
              [-36.199045178706278, -7.79019924240864],
              [-36.199045178706278, -7.850461434175617],
              [-36.29904517870628, -7.850461434175617],
              [-36.29904517870628, -7.809718918879161],
              [-36.2943619979999, -7.811898195999961],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '786',
        ID: 42547.0,
        COUNTRY: 'Brasil',
        LON: -36.149,
        LAT: -7.8005,
        ANNUAL: 5428.0,
        JAN: 5865.0,
        FEB: 5865.0,
        MAR: 5874.0,
        APR: 5343.0,
        MAY: 4658.0,
        JUN: 4209.0,
        JUL: 4356.0,
        AUG: 5039.0,
        SEP: 5679.0,
        OCT: 6016.0,
        NOV: 6253.0,
        DEC: 5988.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.197873156, -7.790380621999925],
              [-36.197871952, -7.790380101999969],
              [-36.192504153999899, -7.788063690999931],
              [-36.189080013, -7.785970801999951],
              [-36.183526916999902, -7.783902269999946],
              [-36.182988235, -7.78387437999993],
              [-36.182272961, -7.783837346999974],
              [-36.179851789999901, -7.783711992999937],
              [-36.178045654999899, -7.783618495999971],
              [-36.175869308999907, -7.783804975999939],
              [-36.172217374999903, -7.784117890999948],
              [-36.172213875999901, -7.784117734999941],
              [-36.16237012900001, -7.783678558999957],
              [-36.155097301, -7.782510807999929],
              [-36.143370235, -7.781562211999938],
              [-36.126819704, -7.784202341999955],
              [-36.118507737999906, -7.785796852999968],
              [-36.117277627, -7.78578529599996],
              [-36.115058573999903, -7.78515462999997],
              [-36.1134615409999, -7.784096540999935],
              [-36.107950154, -7.781467598999947],
              [-36.104073376, -7.779618367999947],
              [-36.104069909, -7.779617416999934],
              [-36.104017606, -7.779603068999953],
              [-36.103371247, -7.779425757999957],
              [-36.102913075999901, -7.779300071999968],
              [-36.100665209999903, -7.778683431999972],
              [-36.099045178706277, -7.778631230302727],
              [-36.099045178706277, -7.850461434175617],
              [-36.199045178706278, -7.850461434175617],
              [-36.199045178706278, -7.79019924240864],
              [-36.197873156, -7.790380621999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '787',
        ID: 42548.0,
        COUNTRY: 'Brasil',
        LON: -36.049,
        LAT: -7.8005,
        ANNUAL: 5396.0,
        JAN: 5842.0,
        FEB: 5865.0,
        MAR: 5854.0,
        APR: 5328.0,
        MAY: 4651.0,
        JUN: 4172.0,
        JUL: 4321.0,
        AUG: 4985.0,
        SEP: 5642.0,
        OCT: 5936.0,
        NOV: 6202.0,
        DEC: 5957.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.094328735, -7.778479253999924],
              [-36.094111106999897, -7.778472240999969],
              [-36.08093159, -7.779055910999945],
              [-36.071831671, -7.78022141099997],
              [-36.068992007, -7.780973591999945],
              [-36.064065074, -7.785077955999945],
              [-36.059374616, -7.79226197799994],
              [-36.056179089, -7.799159965999935],
              [-36.053872496999901, -7.801360964999952],
              [-36.050935161, -7.801150458999927],
              [-36.050497359, -7.801119083999938],
              [-36.047331797, -7.798385154999955],
              [-36.045442476999902, -7.795107362999945],
              [-36.043245592999902, -7.79366253899996],
              [-36.043243604999901, -7.793662581999961],
              [-36.041992114, -7.793689520999973],
              [-36.036164520999897, -7.796015420999942],
              [-36.035409898, -7.796192019999981],
              [-36.033639408999903, -7.79660454499998],
              [-36.030071929, -7.799512774999934],
              [-36.027624585999902, -7.802514595999982],
              [-36.025889102, -7.806225273999925],
              [-36.021587325, -7.810239122999972],
              [-36.018731964, -7.811274527999957],
              [-36.015025237, -7.811798945999958],
              [-36.009207198, -7.81175862699996],
              [-36.003290487999898, -7.812834395999963],
              [-35.999045178706275, -7.813606273461918],
              [-35.999045178706275, -7.850461434175617],
              [-36.099045178706277, -7.850461434175617],
              [-36.099045178706277, -7.778631230302727],
              [-36.094328735, -7.778479253999924],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '788',
        ID: 42549.0,
        COUNTRY: 'Brasil',
        LON: -35.949,
        LAT: -7.8005,
        ANNUAL: 5379.0,
        JAN: 5738.0,
        FEB: 5815.0,
        MAR: 5875.0,
        APR: 5253.0,
        MAY: 4613.0,
        JUN: 4126.0,
        JUL: 4368.0,
        AUG: 5022.0,
        SEP: 5635.0,
        OCT: 5907.0,
        NOV: 6189.0,
        DEC: 6009.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.9982205969999, -7.81375619799996],
              [-35.9982191779999, -7.813755649999956],
              [-35.997241787999897, -7.813378151999926],
              [-35.996228252, -7.809867163999971],
              [-35.997097505999903, -7.805950293999954],
              [-35.996184839, -7.803870712999974],
              [-35.99274897, -7.800465149999981],
              [-35.991039289999897, -7.799869397999942],
              [-35.991037575999897, -7.799869915999979],
              [-35.983246021999904, -7.802226199999947],
              [-35.981367378999899, -7.803193952999942],
              [-35.981365539, -7.803192885999989],
              [-35.979294291, -7.801992358999947],
              [-35.978116196, -7.800437129999979],
              [-35.975924923, -7.795197063999973],
              [-35.97401646, -7.791995338999932],
              [-35.9699596, -7.789460599999923],
              [-35.965827702999903, -7.789055673999942],
              [-35.961417926, -7.787998418999956],
              [-35.960615091, -7.787581910999956],
              [-35.959336548, -7.787325166999945],
              [-35.959026631, -7.787262932999974],
              [-35.954995348, -7.789385545999943],
              [-35.952830196, -7.78917908899996],
              [-35.952190297, -7.789118136999949],
              [-35.951643937, -7.789327961999954],
              [-35.945358277, -7.789259866999932],
              [-35.943358734, -7.789986966999949],
              [-35.943354574, -7.789987871999929],
              [-35.942370062, -7.79020214799992],
              [-35.942368003, -7.790201426999935],
              [-35.938662470999901, -7.788904144999942],
              [-35.93724587, -7.786465658999925],
              [-35.935727782999898, -7.785194861999968],
              [-35.935610428, -7.785096622999967],
              [-35.935607057, -7.785095794999957],
              [-35.932881467999898, -7.784425912999949],
              [-35.928466187, -7.785176426999951],
              [-35.926615177, -7.785919458999957],
              [-35.926044010999902, -7.786148736999956],
              [-35.921517719, -7.787965679999966],
              [-35.914791266999899, -7.790083107999975],
              [-35.914787208999897, -7.790084919999979],
              [-35.909357776, -7.792509103999979],
              [-35.907477002999897, -7.79272492399996],
              [-35.907474941, -7.792724597999956],
              [-35.906027057, -7.79249516299996],
              [-35.905367054, -7.792390577999974],
              [-35.904414954999901, -7.792239706999966],
              [-35.899045178706274, -7.789347800643293],
              [-35.899045178706274, -7.850461434175617],
              [-35.999045178706275, -7.850461434175617],
              [-35.999045178706275, -7.813606273461918],
              [-35.9982205969999, -7.81375619799996],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '789',
        ID: 42550.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -7.8005,
        ANNUAL: 5341.0,
        JAN: 5791.0,
        FEB: 5811.0,
        MAR: 5870.0,
        APR: 5260.0,
        MAY: 4573.0,
        JUN: 4091.0,
        JUL: 4288.0,
        AUG: 4967.0,
        SEP: 5544.0,
        OCT: 5821.0,
        NOV: 6123.0,
        DEC: 5953.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.898245521, -7.788917142999942],
              [-35.895466663, -7.785158332999962],
              [-35.894029893, -7.784796262999981],
              [-35.893497875, -7.783670000999966],
              [-35.891744429, -7.77995802399994],
              [-35.890449576, -7.778757752999984],
              [-35.890446445, -7.778754850999967],
              [-35.889513081, -7.777889663999924],
              [-35.8838261349999, -7.774779558999971],
              [-35.882903201, -7.771976273999937],
              [-35.881044487999901, -7.766341085999946],
              [-35.881018446, -7.766262130999964],
              [-35.881617572, -7.766133831999984],
              [-35.883072195, -7.764124024999948],
              [-35.883357449999899, -7.761840746999948],
              [-35.881996295, -7.756399327999929],
              [-35.881930925999903, -7.756138002999934],
              [-35.881939928, -7.755333766999975],
              [-35.881940832999902, -7.75525295599994],
              [-35.8818291649999, -7.754448425999954],
              [-35.881774060999902, -7.754391025999943],
              [-35.88106074, -7.754215997999957],
              [-35.880522447, -7.754189578999954],
              [-35.880060108999899, -7.754192880999931],
              [-35.879766194, -7.754229207999968],
              [-35.879696017999898, -7.754210218999957],
              [-35.8796258409999, -7.754191229999943],
              [-35.879368500999902, -7.753845953999931],
              [-35.879297251, -7.753750357999933],
              [-35.879137084, -7.753589364999928],
              [-35.878885021, -7.753437342999971],
              [-35.878292184, -7.753079794999965],
              [-35.878288364, -7.753077490999944],
              [-35.878128086999901, -7.75295660399996],
              [-35.878093522, -7.752930533999972],
              [-35.877632834999901, -7.752682852999945],
              [-35.877213428999902, -7.752441776999945],
              [-35.876826220999902, -7.752250236999942],
              [-35.876380395, -7.751966228999945],
              [-35.876230135, -7.75191834399992],
              [-35.875368206, -7.751812666999976],
              [-35.875217724, -7.751781567999956],
              [-35.8751205249999, -7.751761479999975],
              [-35.87499235, -7.751677887999961],
              [-35.874864072, -7.751534541999936],
              [-35.874859677, -7.751529880999941],
              [-35.874790404999899, -7.751456410999938],
              [-35.874646111999901, -7.751303372999925],
              [-35.874487697999903, -7.751091645999957],
              [-35.874474387, -7.751073855999944],
              [-35.874443228, -7.75105355699998],
              [-35.873534329085444, -7.750461434175618],
              [-35.864625734686207, -7.750461434175618],
              [-35.862286088, -7.751034435999934],
              [-35.862283728, -7.751033720999946],
              [-35.860395675120792, -7.750461434175618],
              [-35.832622174030192, -7.750461434175618],
              [-35.83238356, -7.750726692999924],
              [-35.831911209999902, -7.750912579999977],
              [-35.829792257, -7.751746463999948],
              [-35.829788902, -7.751746210999949],
              [-35.8284990249999, -7.751649038999967],
              [-35.826671499111967, -7.750461434175618],
              [-35.799045178706272, -7.750461434175618],
              [-35.799045178706272, -7.850461434175617],
              [-35.899045178706274, -7.850461434175617],
              [-35.899045178706274, -7.789347800643293],
              [-35.898245521, -7.788917142999942],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '790',
        ID: 42551.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -7.8005,
        ANNUAL: 5300.0,
        JAN: 5793.0,
        FEB: 5727.0,
        MAR: 5807.0,
        APR: 5241.0,
        MAY: 4520.0,
        JUN: 4082.0,
        JUL: 4244.0,
        AUG: 4932.0,
        SEP: 5539.0,
        OCT: 5750.0,
        NOV: 6061.0,
        DEC: 5904.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.799045178706272, -7.850461434175617],
              [-35.799045178706272, -7.750461434175618],
              [-35.699045178706271, -7.750461434175618],
              [-35.699045178706271, -7.850461434175617],
              [-35.799045178706272, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '791',
        ID: 42552.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -7.8005,
        ANNUAL: 5287.0,
        JAN: 5686.0,
        FEB: 5682.0,
        MAR: 5813.0,
        APR: 5213.0,
        MAY: 4482.0,
        JUN: 4083.0,
        JUL: 4274.0,
        AUG: 4902.0,
        SEP: 5567.0,
        OCT: 5789.0,
        NOV: 6038.0,
        DEC: 5917.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.699045178706271, -7.850461434175617],
              [-35.699045178706271, -7.750461434175618],
              [-35.59904517870627, -7.750461434175618],
              [-35.59904517870627, -7.850461434175617],
              [-35.699045178706271, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '792',
        ID: 42553.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -7.8005,
        ANNUAL: 5192.0,
        JAN: 5629.0,
        FEB: 5586.0,
        MAR: 5659.0,
        APR: 5149.0,
        MAY: 4407.0,
        JUN: 4034.0,
        JUL: 4149.0,
        AUG: 4814.0,
        SEP: 5444.0,
        OCT: 5659.0,
        NOV: 5950.0,
        DEC: 5824.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59904517870627, -7.850461434175617],
              [-35.59904517870627, -7.750461434175618],
              [-35.499045178706268, -7.750461434175618],
              [-35.499045178706268, -7.850461434175617],
              [-35.59904517870627, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '793',
        ID: 42554.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -7.8005,
        ANNUAL: 5159.0,
        JAN: 5628.0,
        FEB: 5545.0,
        MAR: 5603.0,
        APR: 5107.0,
        MAY: 4365.0,
        JUN: 4007.0,
        JUL: 4105.0,
        AUG: 4789.0,
        SEP: 5423.0,
        OCT: 5650.0,
        NOV: 5909.0,
        DEC: 5784.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -7.850461434175617],
              [-35.499045178706268, -7.750461434175618],
              [-35.399045178706267, -7.750461434175618],
              [-35.399045178706267, -7.850461434175617],
              [-35.499045178706268, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '794',
        ID: 42555.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -7.8005,
        ANNUAL: 5199.0,
        JAN: 5622.0,
        FEB: 5673.0,
        MAR: 5688.0,
        APR: 5157.0,
        MAY: 4405.0,
        JUN: 4071.0,
        JUL: 4186.0,
        AUG: 4835.0,
        SEP: 5436.0,
        OCT: 5644.0,
        NOV: 5891.0,
        DEC: 5776.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -7.850461434175617],
              [-35.399045178706267, -7.750461434175618],
              [-35.299045178706265, -7.750461434175618],
              [-35.299045178706265, -7.850461434175617],
              [-35.399045178706267, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '795',
        ID: 42556.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -7.8005,
        ANNUAL: 5265.0,
        JAN: 5653.0,
        FEB: 5774.0,
        MAR: 5774.0,
        APR: 5172.0,
        MAY: 4471.0,
        JUN: 4129.0,
        JUL: 4230.0,
        AUG: 4947.0,
        SEP: 5482.0,
        OCT: 5696.0,
        NOV: 5967.0,
        DEC: 5880.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -7.850461434175617],
              [-35.299045178706265, -7.750461434175618],
              [-35.199045178706264, -7.750461434175618],
              [-35.199045178706264, -7.850461434175617],
              [-35.299045178706265, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '796',
        ID: 42557.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -7.8005,
        ANNUAL: 5342.0,
        JAN: 5741.0,
        FEB: 5893.0,
        MAR: 5824.0,
        APR: 5212.0,
        MAY: 4518.0,
        JUN: 4173.0,
        JUL: 4300.0,
        AUG: 5047.0,
        SEP: 5547.0,
        OCT: 5757.0,
        NOV: 6056.0,
        DEC: 6040.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -7.850461434175617],
              [-35.199045178706264, -7.750461434175618],
              [-35.099045178706263, -7.750461434175618],
              [-35.099045178706263, -7.850461434175617],
              [-35.199045178706264, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '797',
        ID: 42558.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -7.8005,
        ANNUAL: 5313.0,
        JAN: 5733.0,
        FEB: 5874.0,
        MAR: 5903.0,
        APR: 5150.0,
        MAY: 4456.0,
        JUN: 4116.0,
        JUL: 4291.0,
        AUG: 5022.0,
        SEP: 5449.0,
        OCT: 5732.0,
        NOV: 5994.0,
        DEC: 6038.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -7.850461434175617],
              [-35.099045178706263, -7.750461434175618],
              [-34.999045178706261, -7.750461434175618],
              [-34.999045178706261, -7.850461434175617],
              [-35.099045178706263, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '798',
        ID: 42559.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -7.8005,
        ANNUAL: 5333.0,
        JAN: 5770.0,
        FEB: 5931.0,
        MAR: 5969.0,
        APR: 5162.0,
        MAY: 4483.0,
        JUN: 4170.0,
        JUL: 4290.0,
        AUG: 5019.0,
        SEP: 5405.0,
        OCT: 5729.0,
        NOV: 6037.0,
        DEC: 6034.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.999045178706261, -7.850461434175617],
              [-34.999045178706261, -7.750461434175618],
              [-34.89904517870626, -7.750461434175618],
              [-34.89904517870626, -7.850461434175617],
              [-34.999045178706261, -7.850461434175617],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '799',
        ID: 42560.0,
        COUNTRY: 'Brasil',
        LON: -34.849,
        LAT: -7.8005,
        ANNUAL: 5518.0,
        JAN: 5940.0,
        FEB: 6082.0,
        MAR: 6077.0,
        APR: 5329.0,
        MAY: 4629.0,
        JUN: 4306.0,
        JUL: 4399.0,
        AUG: 5217.0,
        SEP: 5680.0,
        OCT: 6058.0,
        NOV: 6243.0,
        DEC: 6256.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.823283502, -7.750473870999947],
              [-34.82317539, -7.75169797499996],
              [-34.823392737999903, -7.752817121999944],
              [-34.823409661, -7.752901360999935],
              [-34.823619085, -7.753943858999957],
              [-34.823835276, -7.755158549999984],
              [-34.824209208999903, -7.756495362999945],
              [-34.8245064729999, -7.757215296999965],
              [-34.824578527, -7.757389801999974],
              [-34.824747112999901, -7.757841146999976],
              [-34.824938212999903, -7.758352762999957],
              [-34.825493421, -7.759615847999954],
              [-34.826125721, -7.760814998999925],
              [-34.826803891999901, -7.761887139999948],
              [-34.827413889999903, -7.762922824999974],
              [-34.8278309579999, -7.763897137999947],
              [-34.828087777999897, -7.764394649999933],
              [-34.828304784999901, -7.764815034999968],
              [-34.828820549999897, -7.765740186999956],
              [-34.829120845, -7.766410608999931],
              [-34.829281626999901, -7.766769562999974],
              [-34.829379447999898, -7.766971169999983],
              [-34.829515656, -7.767251888999977],
              [-34.829604179999897, -7.767908492999941],
              [-34.8295887049999, -7.768067836999928],
              [-34.829545203999899, -7.768515781999954],
              [-34.829996074999897, -7.769183657999971],
              [-34.830054587999904, -7.769270331999957],
              [-34.830303133, -7.769761992999968],
              [-34.830683797999903, -7.770515009999938],
              [-34.830932753999903, -7.771341711999977],
              [-34.831079071, -7.771781938999935],
              [-34.831016028, -7.772320089999947],
              [-34.831122083, -7.772938141999933],
              [-34.831124674, -7.772953241999973],
              [-34.831146061, -7.773011814999961],
              [-34.831531296, -7.774066852999964],
              [-34.831539402, -7.774089059999937],
              [-34.831858140999898, -7.774962173999932],
              [-34.832475455, -7.77626197699992],
              [-34.833239431, -7.778031324999968],
              [-34.833251808999897, -7.778059992999938],
              [-34.83362295099991, -7.77918989399996],
              [-34.833847268999897, -7.77987280499997],
              [-34.834202288, -7.781141167999976],
              [-34.834573419, -7.78230128899996],
              [-34.834875172, -7.783418894999947],
              [-34.835077305, -7.783925206999972],
              [-34.8351807069999, -7.784184210999974],
              [-34.835431893, -7.784813386999922],
              [-34.835580777, -7.785509190999961],
              [-34.835765218999903, -7.786371169999934],
              [-34.835831377, -7.787023386999982],
              [-34.835735083, -7.787354383999975],
              [-34.835964835, -7.787617324999935],
              [-34.836046995999901, -7.78779497299996],
              [-34.8361722349999, -7.788065755999983],
              [-34.836436415, -7.789527685999923],
              [-34.836445969, -7.789580552999942],
              [-34.836488782999901, -7.789844724999968],
              [-34.836626871, -7.790696763999965],
              [-34.836772697, -7.792084965999954],
              [-34.8367914029999, -7.792505077999975],
              [-34.836815267, -7.793041031999962],
              [-34.836833793, -7.793457124999962],
              [-34.836870937, -7.794556735999955],
              [-34.836893312, -7.795792587999936],
              [-34.836956007, -7.797012744999956],
              [-34.83696212, -7.797131722999947],
              [-34.83692374, -7.798851955999959],
              [-34.836962941, -7.800008281999959],
              [-34.836992301999899, -7.800876043999959],
              [-34.836959688, -7.801315825999948],
              [-34.836929930999901, -7.801717087999982],
              [-34.836791902999899, -7.802686013999963],
              [-34.836765264, -7.802860761999971],
              [-34.8366596239999, -7.803553744999933],
              [-34.836656256, -7.803727621999935],
              [-34.836642371, -7.804444620999965],
              [-34.836632854999898, -7.805036442999949],
              [-34.836588306, -7.805657705999978],
              [-34.836448197, -7.806294680999943],
              [-34.836415880999901, -7.806441597999936],
              [-34.836504686, -7.806875214999925],
              [-34.836648817, -7.807481930999938],
              [-34.836675770999904, -7.807901057999968],
              [-34.836747752999898, -7.808265899999982],
              [-34.836813889, -7.808642444999979],
              [-34.836877285, -7.808931294999987],
              [-34.836967127, -7.809279379999964],
              [-34.8369658149999, -7.809291475999946],
              [-34.837071095999903, -7.809535159999938],
              [-34.83707836, -7.809544089999966],
              [-34.837303249999898, -7.809747486999975],
              [-34.837565576, -7.81002402299993],
              [-34.837882012, -7.810408421999975],
              [-34.838122756, -7.810731761999936],
              [-34.838368919999901, -7.810929263999981],
              [-34.838690353999901, -7.811060261999967],
              [-34.839083629, -7.811191863999966],
              [-34.839579044999901, -7.811444447999962],
              [-34.840023437999903, -7.811711997999967],
              [-34.840588033, -7.811906106999975],
              [-34.841153861, -7.812003040999973],
              [-34.841785665, -7.812113007999937],
              [-34.842197204999898, -7.812171959999942],
              [-34.84251191, -7.812217040999945],
              [-34.843080449, -7.812283831999922],
              [-34.843215755, -7.81229885599993],
              [-34.843483206, -7.812328552999931],
              [-34.844115571, -7.812655864999949],
              [-34.845040228, -7.813065895999967],
              [-34.845040191, -7.81307825199996],
              [-34.845098771, -7.816362014999979],
              [-34.844892625999897, -7.819320618999938],
              [-34.844660995, -7.819437409999983],
              [-34.844466462, -7.819694264999947],
              [-34.844301319, -7.820147188999943],
              [-34.8442038069999, -7.821020778999981],
              [-34.844243945999899, -7.821977865999936],
              [-34.844157625, -7.822773529999956],
              [-34.844008532, -7.823497640999956],
              [-34.844005506, -7.823512336999944],
              [-34.843855229, -7.824176855999949],
              [-34.843701861999897, -7.824662555999967],
              [-34.8435590039999, -7.82512177899997],
              [-34.843478287, -7.825433951999964],
              [-34.843441146, -7.825776377999933],
              [-34.843361154, -7.826134620999947],
              [-34.843267215, -7.826384408999957],
              [-34.8432677179999, -7.82652199399996],
              [-34.843271791999904, -7.826863707999963],
              [-34.843078637, -7.827696518999974],
              [-34.843008143, -7.828080507999974],
              [-34.842973061, -7.828349914999933],
              [-34.842977128, -7.828697605999922],
              [-34.843040325999901, -7.829396653999932],
              [-34.843147506, -7.830271893999954],
              [-34.84324537, -7.830870952999924],
              [-34.843131721, -7.83136079099995],
              [-34.843163789999899, -7.831586501999938],
              [-34.843067120999898, -7.831687645999979],
              [-34.843239347, -7.832045331999954],
              [-34.8435599539999, -7.832644863999967],
              [-34.844032802999898, -7.833642630999976],
              [-34.844710875, -7.835165682999953],
              [-34.84500602, -7.836427627999963],
              [-34.845007576999897, -7.838025652999928],
              [-34.844713226, -7.839933362999933],
              [-34.844345707999899, -7.841117655999943],
              [-34.843401131, -7.843488677999972],
              [-34.843114736, -7.844204380999946],
              [-34.842882115, -7.844695339999924],
              [-34.8426801049999, -7.844895925999945],
              [-34.842285607, -7.845351960999948],
              [-34.841102259, -7.845122387999942],
              [-34.840198680999897, -7.845037831999951],
              [-34.840170016999899, -7.844345337999939],
              [-34.8403877719999, -7.84349054299998],
              [-34.8407436419999, -7.842580701999967],
              [-34.840785197999899, -7.842051020999975],
              [-34.840814036, -7.841337545999973],
              [-34.841011415, -7.840787877999953],
              [-34.840923115, -7.840509926999976],
              [-34.840443596999897, -7.840113171999967],
              [-34.839587282, -7.83961738199997],
              [-34.838768984, -7.839578065999937],
              [-34.837902368999906, -7.839961680999987],
              [-34.837555812999902, -7.84069170299995],
              [-34.837435752, -7.84154996399997],
              [-34.836953208999901, -7.843143],
              [-34.836750945999903, -7.843647890999931],
              [-34.83650947, -7.844102418999962],
              [-34.836468838, -7.84432399199993],
              [-34.836466669999901, -7.844335818999976],
              [-34.836378112, -7.844839757999974],
              [-34.836352497999897, -7.844985514999956],
              [-34.836280524, -7.845367210999941],
              [-34.8361347069999, -7.846147724999981],
              [-34.835857351, -7.84749778099996],
              [-34.83577043, -7.849023166999929],
              [-34.835564427, -7.849996269999966],
              [-34.835495499135568, -7.850461434175617],
              [-34.89904517870626, -7.850461434175617],
              [-34.89904517870626, -7.750461434175618],
              [-34.823283423911398, -7.750461434175618],
              [-34.823283502, -7.750473870999947],
            ],
          ],
          [
            [
              [-34.837475577, -7.816507718999962],
              [-34.837465785999903, -7.816506675999964],
              [-34.837268993, -7.816616548999946],
              [-34.837090621999899, -7.816708760999969],
              [-34.836975661999901, -7.816794641999932],
              [-34.837018195, -7.816828607999986],
              [-34.837118211, -7.816819630999935],
              [-34.8372780239999, -7.816807206999945],
              [-34.837484926999906, -7.81678671399993],
              [-34.837606093, -7.816770177999953],
              [-34.837752039999906, -7.816733386999943],
              [-34.837969787, -7.816704238999929],
              [-34.838194167, -7.816705232999944],
              [-34.838197431, -7.816705579999963],
              [-34.838213749, -7.816707316999942],
              [-34.8384226169999, -7.816729552999959],
              [-34.838601409, -7.816755128999946],
              [-34.838607936, -7.816755823999931],
              [-34.838780796, -7.816744792999932],
              [-34.838925335999903, -7.816720935999968],
              [-34.839091671999903, -7.816709210999937],
              [-34.839320726, -7.81669762299992],
              [-34.839466331, -7.816664065999927],
              [-34.83958432, -7.81661121499998],
              [-34.839775161, -7.81655630599994],
              [-34.839941505, -7.816544578999973],
              [-34.840142344999897, -7.816549607999958],
              [-34.840302865, -7.816530718999959],
              [-34.840426223999906, -7.816458811999943],
              [-34.840619785999898, -7.816348583999962],
              [-34.840679028, -7.816289473999962],
              [-34.840549317999901, -7.816206976999983],
              [-34.840398163, -7.816109112999921],
              [-34.840195245, -7.816031905999977],
              [-34.840022263, -7.816013489999923],
              [-34.839882568, -7.816001888999956],
              [-34.839751396999908, -7.816010814999975],
              [-34.839563702, -7.816036626999967],
              [-34.839338095999899, -7.816107466999945],
              [-34.838963162, -7.816185304999971],
              [-34.838684305, -7.816260280999934],
              [-34.838417701, -7.816313664999933],
              [-34.838136892, -7.81634590999994],
              [-34.837873454, -7.816370192999954],
              [-34.837702097, -7.81639773799997],
              [-34.837475577, -7.816507718999962],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '800',
        ID: 42893.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -7.7005,
        ANNUAL: 5792.0,
        JAN: 5781.0,
        FEB: 5579.0,
        MAR: 5696.0,
        APR: 5328.0,
        MAY: 5068.0,
        JUN: 4956.0,
        JUL: 5322.0,
        AUG: 6098.0,
        SEP: 6468.0,
        OCT: 6471.0,
        NOV: 6539.0,
        DEC: 6197.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.675470135999909, -7.748604225999943],
              [-40.675597793999898, -7.747817770999972],
              [-40.675274693999903, -7.746860748999949],
              [-40.675114569999899, -7.745543918999937],
              [-40.674415064, -7.742687254999963],
              [-40.673484366, -7.739373332999951],
              [-40.671864334, -7.735605848999972],
              [-40.671735297999902, -7.735363627999959],
              [-40.669553273, -7.73126759999996],
              [-40.666552911999901, -7.726361320999965],
              [-40.661025720999902, -7.720549281999974],
              [-40.658035692999903, -7.718273437999924],
              [-40.654089338999903, -7.715932156999942],
              [-40.651139509, -7.714182089999946],
              [-40.648724473999899, -7.712361669999943],
              [-40.643777575999898, -7.708148227999964],
              [-40.639289596, -7.704162028999975],
              [-40.635603488999898, -7.699829394999946],
              [-40.633758331, -7.696861827999942],
              [-40.630871393999897, -7.691725523999933],
              [-40.627749729999906, -7.684989488999963],
              [-40.625875599, -7.678356699999939],
              [-40.625541915, -7.677175747999976],
              [-40.625512535, -7.677170319999965],
              [-40.624809594999903, -7.674713487999951],
              [-40.624408883999898, -7.673312971999961],
              [-40.623665531, -7.669913052999958],
              [-40.622720573, -7.664496570999976],
              [-40.622449011, -7.662939984999984],
              [-40.622262732, -7.661550122999927],
              [-40.622169815999897, -7.660996196999964],
              [-40.621830323, -7.658323847999941],
              [-40.621717987, -7.655379127999939],
              [-40.62178291, -7.653533844999978],
              [-40.621989729999903, -7.651359867999936],
              [-40.622141146249326, -7.650461434175618],
              [-40.599045178706341, -7.650461434175618],
              [-40.599045178706341, -7.750461434175618],
              [-40.675555217171684, -7.750461434175618],
              [-40.675470135999909, -7.748604225999943],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '801',
        ID: 42894.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -7.7005,
        ANNUAL: 5675.0,
        JAN: 5816.0,
        FEB: 5517.0,
        MAR: 5656.0,
        APR: 5148.0,
        MAY: 4798.0,
        JUN: 4667.0,
        JUL: 5030.0,
        AUG: 5911.0,
        SEP: 6411.0,
        OCT: 6432.0,
        NOV: 6500.0,
        DEC: 6218.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -7.750461434175618],
              [-40.599045178706341, -7.650461434175618],
              [-40.499045178706339, -7.650461434175618],
              [-40.499045178706339, -7.750461434175618],
              [-40.599045178706341, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '802',
        ID: 42895.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -7.7005,
        ANNUAL: 5647.0,
        JAN: 5771.0,
        FEB: 5530.0,
        MAR: 5669.0,
        APR: 5140.0,
        MAY: 4779.0,
        JUN: 4581.0,
        JUL: 4939.0,
        AUG: 5849.0,
        SEP: 6405.0,
        OCT: 6450.0,
        NOV: 6462.0,
        DEC: 6186.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -7.750461434175618],
              [-40.499045178706339, -7.650461434175618],
              [-40.399045178706338, -7.650461434175618],
              [-40.399045178706338, -7.750461434175618],
              [-40.499045178706339, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '803',
        ID: 42896.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -7.7005,
        ANNUAL: 5615.0,
        JAN: 5727.0,
        FEB: 5516.0,
        MAR: 5668.0,
        APR: 5143.0,
        MAY: 4750.0,
        JUN: 4512.0,
        JUL: 4854.0,
        AUG: 5800.0,
        SEP: 6390.0,
        OCT: 6403.0,
        NOV: 6454.0,
        DEC: 6161.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -7.750461434175618],
              [-40.399045178706338, -7.650461434175618],
              [-40.299045178706336, -7.650461434175618],
              [-40.299045178706336, -7.750461434175618],
              [-40.399045178706338, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '804',
        ID: 42897.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -7.7005,
        ANNUAL: 5587.0,
        JAN: 5762.0,
        FEB: 5489.0,
        MAR: 5659.0,
        APR: 5133.0,
        MAY: 4681.0,
        JUN: 4433.0,
        JUL: 4750.0,
        AUG: 5706.0,
        SEP: 6356.0,
        OCT: 6389.0,
        NOV: 6482.0,
        DEC: 6202.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -7.750461434175618],
              [-40.299045178706336, -7.650461434175618],
              [-40.199045178706335, -7.650461434175618],
              [-40.199045178706335, -7.750461434175618],
              [-40.299045178706336, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '805',
        ID: 42898.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -7.7005,
        ANNUAL: 5603.0,
        JAN: 5814.0,
        FEB: 5587.0,
        MAR: 5721.0,
        APR: 5180.0,
        MAY: 4684.0,
        JUN: 4392.0,
        JUL: 4721.0,
        AUG: 5654.0,
        SEP: 6334.0,
        OCT: 6405.0,
        NOV: 6496.0,
        DEC: 6253.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -7.750461434175618],
              [-40.199045178706335, -7.650461434175618],
              [-40.099045178706334, -7.650461434175618],
              [-40.099045178706334, -7.750461434175618],
              [-40.199045178706335, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '806',
        ID: 42899.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -7.7005,
        ANNUAL: 5638.0,
        JAN: 5872.0,
        FEB: 5652.0,
        MAR: 5761.0,
        APR: 5240.0,
        MAY: 4719.0,
        JUN: 4427.0,
        JUL: 4756.0,
        AUG: 5662.0,
        SEP: 6350.0,
        OCT: 6466.0,
        NOV: 6492.0,
        DEC: 6264.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -7.750461434175618],
              [-40.099045178706334, -7.650461434175618],
              [-39.999045178706332, -7.650461434175618],
              [-39.999045178706332, -7.750461434175618],
              [-40.099045178706334, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '807',
        ID: 42900.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -7.7005,
        ANNUAL: 5633.0,
        JAN: 5876.0,
        FEB: 5628.0,
        MAR: 5765.0,
        APR: 5202.0,
        MAY: 4705.0,
        JUN: 4440.0,
        JUL: 4739.0,
        AUG: 5650.0,
        SEP: 6379.0,
        OCT: 6488.0,
        NOV: 6479.0,
        DEC: 6244.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -7.750461434175618],
              [-39.999045178706332, -7.650461434175618],
              [-39.899045178706331, -7.650461434175618],
              [-39.899045178706331, -7.750461434175618],
              [-39.999045178706332, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '808',
        ID: 42901.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -7.7005,
        ANNUAL: 5601.0,
        JAN: 5883.0,
        FEB: 5630.0,
        MAR: 5724.0,
        APR: 5189.0,
        MAY: 4661.0,
        JUN: 4343.0,
        JUL: 4662.0,
        AUG: 5599.0,
        SEP: 6340.0,
        OCT: 6444.0,
        NOV: 6494.0,
        DEC: 6241.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -7.750461434175618],
              [-39.899045178706331, -7.650461434175618],
              [-39.799045178706329, -7.650461434175618],
              [-39.799045178706329, -7.750461434175618],
              [-39.899045178706331, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '809',
        ID: 42902.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -7.7005,
        ANNUAL: 5646.0,
        JAN: 5943.0,
        FEB: 5721.0,
        MAR: 5797.0,
        APR: 5275.0,
        MAY: 4723.0,
        JUN: 4329.0,
        JUL: 4686.0,
        AUG: 5593.0,
        SEP: 6395.0,
        OCT: 6477.0,
        NOV: 6542.0,
        DEC: 6269.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -7.750461434175618],
              [-39.799045178706329, -7.650461434175618],
              [-39.699045178706328, -7.650461434175618],
              [-39.699045178706328, -7.750461434175618],
              [-39.799045178706329, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '810',
        ID: 42903.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -7.7005,
        ANNUAL: 5674.0,
        JAN: 5974.0,
        FEB: 5797.0,
        MAR: 5809.0,
        APR: 5343.0,
        MAY: 4772.0,
        JUN: 4395.0,
        JUL: 4701.0,
        AUG: 5596.0,
        SEP: 6385.0,
        OCT: 6488.0,
        NOV: 6536.0,
        DEC: 6290.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -7.750461434175618],
              [-39.699045178706328, -7.650461434175618],
              [-39.599045178706326, -7.650461434175618],
              [-39.599045178706326, -7.750461434175618],
              [-39.699045178706328, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '811',
        ID: 42904.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -7.7005,
        ANNUAL: 5690.0,
        JAN: 5967.0,
        FEB: 5789.0,
        MAR: 5806.0,
        APR: 5362.0,
        MAY: 4781.0,
        JUN: 4429.0,
        JUL: 4722.0,
        AUG: 5593.0,
        SEP: 6424.0,
        OCT: 6550.0,
        NOV: 6592.0,
        DEC: 6269.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -7.750461434175618],
              [-39.599045178706326, -7.650461434175618],
              [-39.499045178706325, -7.650461434175618],
              [-39.499045178706325, -7.750461434175618],
              [-39.599045178706326, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '812',
        ID: 42905.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -7.7005,
        ANNUAL: 5694.0,
        JAN: 5969.0,
        FEB: 5778.0,
        MAR: 5815.0,
        APR: 5363.0,
        MAY: 4763.0,
        JUN: 4426.0,
        JUL: 4722.0,
        AUG: 5577.0,
        SEP: 6436.0,
        OCT: 6549.0,
        NOV: 6649.0,
        DEC: 6277.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.499045178706325, -7.750461434175618],
              [-39.499045178706325, -7.650461434175618],
              [-39.399045178706324, -7.650461434175618],
              [-39.399045178706324, -7.750461434175618],
              [-39.499045178706325, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '813',
        ID: 42906.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -7.7005,
        ANNUAL: 5703.0,
        JAN: 6002.0,
        FEB: 5789.0,
        MAR: 5854.0,
        APR: 5360.0,
        MAY: 4744.0,
        JUN: 4420.0,
        JUL: 4692.0,
        AUG: 5566.0,
        SEP: 6469.0,
        OCT: 6539.0,
        NOV: 6663.0,
        DEC: 6337.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.325046449, -7.65124967699995],
              [-39.324067736, -7.651678255999967],
              [-39.321440342, -7.654225797999975],
              [-39.31479024, -7.654693406],
              [-39.311388609999902, -7.655236583999975],
              [-39.309648296, -7.657336467999924],
              [-39.309294667, -7.658629698999959],
              [-39.309259953, -7.658756647999954],
              [-39.3085885, -7.661212168999954],
              [-39.306893849999902, -7.665086127999929],
              [-39.305566451999901, -7.665931624999924],
              [-39.30227416, -7.663946747999936],
              [-39.300412944, -7.663543321999956],
              [-39.299045178706322, -7.663246864643646],
              [-39.299045178706322, -7.750461434175618],
              [-39.399045178706324, -7.750461434175618],
              [-39.399045178706324, -7.650461434175618],
              [-39.32684649727468, -7.650461434175618],
              [-39.325046449, -7.65124967699995],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '814',
        ID: 42907.0,
        COUNTRY: 'Brasil',
        LON: -39.249,
        LAT: -7.7005,
        ANNUAL: 5698.0,
        JAN: 6042.0,
        FEB: 5841.0,
        MAR: 5905.0,
        APR: 5416.0,
        MAY: 4725.0,
        JUN: 4390.0,
        JUL: 4656.0,
        AUG: 5506.0,
        SEP: 6439.0,
        OCT: 6464.0,
        NOV: 6637.0,
        DEC: 6355.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.297709039, -7.662957261999955],
              [-39.295914265, -7.66335080799996],
              [-39.289438774, -7.664770707999923],
              [-39.288845135, -7.664900876999981],
              [-39.284901648, -7.664364733999954],
              [-39.283751751, -7.664208380999978],
              [-39.272401672999898, -7.660133537999968],
              [-39.271894182, -7.660075909999936],
              [-39.270321408, -7.659897314999964],
              [-39.266395889, -7.664765420999974],
              [-39.26533804, -7.669831011999973],
              [-39.264180186, -7.67052040699998],
              [-39.252815972999898, -7.677286750999938],
              [-39.25203742, -7.678871062999973],
              [-39.251030995999898, -7.680919076999936],
              [-39.2497403529999, -7.683544575999964],
              [-39.250044332999899, -7.687764311999967],
              [-39.250062391, -7.688015186999955],
              [-39.24988574, -7.688194224999958],
              [-39.247463747, -7.690648563999962],
              [-39.2474703399999, -7.690654636999969],
              [-39.249908288, -7.692750495999976],
              [-39.250120072999898, -7.692932563999925],
              [-39.248422336, -7.696709178999973],
              [-39.250256457, -7.698327055999925],
              [-39.250387991, -7.698443043999929],
              [-39.252564870999898, -7.704930898999982],
              [-39.244172431, -7.705343054999966],
              [-39.24405488, -7.705297880999971],
              [-39.24272461, -7.704786660999955],
              [-39.240173849, -7.701924612999966],
              [-39.239978746, -7.701614261999966],
              [-39.238053810999901, -7.698552255999975],
              [-39.234928466, -7.69690146699998],
              [-39.234738182999898, -7.696800959999956],
              [-39.23460951, -7.696800651999985],
              [-39.23224785, -7.696794995999939],
              [-39.230308111999904, -7.695789620999953],
              [-39.23019047, -7.69572864699995],
              [-39.228638542999903, -7.695685604999937],
              [-39.228636179, -7.695685539999943],
              [-39.2286345159999, -7.695685493999962],
              [-39.22863165, -7.695685412999985],
              [-39.224082681, -7.69654920999993],
              [-39.222742509999904, -7.696803727999952],
              [-39.22050161, -7.695001597999987],
              [-39.214945455, -7.693109026999931],
              [-39.208335245999898, -7.689480337999949],
              [-39.204553463, -7.692132847999968],
              [-39.204521946999897, -7.692154954999976],
              [-39.200776143, -7.695508772999977],
              [-39.199045178706321, -7.696071396758418],
              [-39.199045178706321, -7.750461434175618],
              [-39.299045178706322, -7.750461434175618],
              [-39.299045178706322, -7.663246864643646],
              [-39.297709039, -7.662957261999955],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '815',
        ID: 42908.0,
        COUNTRY: 'Brasil',
        LON: -39.149,
        LAT: -7.7005,
        ANNUAL: 5721.0,
        JAN: 6021.0,
        FEB: 5966.0,
        MAR: 5934.0,
        APR: 5482.0,
        MAY: 4778.0,
        JUN: 4423.0,
        JUL: 4705.0,
        AUG: 5511.0,
        SEP: 6457.0,
        OCT: 6399.0,
        NOV: 6596.0,
        DEC: 6380.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.195940667999906, -7.697080470999951],
              [-39.192023145999897, -7.697344490999973],
              [-39.191231925999901, -7.698037896999955],
              [-39.190223959, -7.698921268999919],
              [-39.189999335, -7.699228649999955],
              [-39.189352606999897, -7.700113645999981],
              [-39.188018913999898, -7.701938700999955],
              [-39.187555792, -7.702572445999975],
              [-39.183386272999897, -7.705559576999972],
              [-39.181133674, -7.709477719999949],
              [-39.180924549999901, -7.709535206999928],
              [-39.179887221999898, -7.709820362999946],
              [-39.177721118, -7.709565644999944],
              [-39.17444059, -7.712748538999961],
              [-39.171001361, -7.715240281999977],
              [-39.168688496999906, -7.715366705999942],
              [-39.167381908, -7.713877879999928],
              [-39.165803030999903, -7.71562802099993],
              [-39.16288573500001, -7.716807749999926],
              [-39.158556604, -7.717161495999965],
              [-39.15842058, -7.717123417999966],
              [-39.1559971369999, -7.716445003999985],
              [-39.154274669, -7.71740006999994],
              [-39.154164078, -7.717461389999932],
              [-39.151577701999898, -7.717016314999974],
              [-39.146552893, -7.718219100999934],
              [-39.1464583859999, -7.718241722999942],
              [-39.145495851, -7.718472123999967],
              [-39.143712682999897, -7.718547006999928],
              [-39.143674690999902, -7.718559879999926],
              [-39.142299675999901, -7.719025746999931],
              [-39.138220783999898, -7.721970751999948],
              [-39.136980068, -7.722500774999956],
              [-39.135640873999897, -7.723375095999926],
              [-39.134289731, -7.72489817099995],
              [-39.133952877999903, -7.72527788899992],
              [-39.132756340999897, -7.726626307999942],
              [-39.130714452999896, -7.731243753999935],
              [-39.127859328999904, -7.735491320999928],
              [-39.126882377, -7.738480030999944],
              [-39.125786975, -7.740203559999948],
              [-39.123853314, -7.743246022999938],
              [-39.119943869999901, -7.746782583999963],
              [-39.118871202, -7.748469634999933],
              [-39.11838356, -7.749236578999962],
              [-39.116932153948575, -7.750461434175618],
              [-39.199045178706321, -7.750461434175618],
              [-39.199045178706321, -7.696071396758418],
              [-39.195940667999906, -7.697080470999951],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '816',
        ID: 42910.0,
        COUNTRY: 'Brasil',
        LON: -38.949,
        LAT: -7.7005,
        ANNUAL: 5756.0,
        JAN: 5955.0,
        FEB: 5975.0,
        MAR: 5888.0,
        APR: 5506.0,
        MAY: 4911.0,
        JUN: 4574.0,
        JUL: 4843.0,
        AUG: 5650.0,
        SEP: 6499.0,
        OCT: 6458.0,
        NOV: 6584.0,
        DEC: 6227.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.918247726999901, -7.747167057999956],
              [-38.916517869, -7.745038746999974],
              [-38.916440935, -7.744944431999941],
              [-38.915577688, -7.744487105999942],
              [-38.912718681999898, -7.74458169799993],
              [-38.9126880439999, -7.744594242999939],
              [-38.912150996, -7.744814142999929],
              [-38.910804272, -7.745365573999954],
              [-38.910073441999906, -7.746179465999933],
              [-38.910041915, -7.746183538999953],
              [-38.907022993999902, -7.746573611999964],
              [-38.902410540999902, -7.744875154999922],
              [-38.900353179, -7.744875551999948],
              [-38.900335172, -7.744888392999933],
              [-38.899186285, -7.745707664999942],
              [-38.899045178706317, -7.745904290258525],
              [-38.899045178706317, -7.750461434175618],
              [-38.916779129188946, -7.750461434175618],
              [-38.918247726999901, -7.747167057999956],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '817',
        ID: 42911.0,
        COUNTRY: 'Brasil',
        LON: -38.849,
        LAT: -7.7005,
        ANNUAL: 5804.0,
        JAN: 5996.0,
        FEB: 5987.0,
        MAR: 5907.0,
        APR: 5562.0,
        MAY: 4990.0,
        JUN: 4678.0,
        JUL: 4963.0,
        AUG: 5753.0,
        SEP: 6547.0,
        OCT: 6514.0,
        NOV: 6536.0,
        DEC: 6213.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.897132590999902, -7.748569394999946],
              [-38.894810596999911, -7.750453542999936],
              [-38.893789198, -7.750375312999943],
              [-38.893287213999898, -7.750336864999957],
              [-38.892680288999898, -7.750290379999975],
              [-38.891670921999903, -7.749437899999979],
              [-38.887659449999902, -7.748175432999972],
              [-38.887525965, -7.748177899999975],
              [-38.885190434, -7.748221063999956],
              [-38.881397546999899, -7.747432295999938],
              [-38.880626248999903, -7.745947753999925],
              [-38.880852551, -7.743971761999942],
              [-38.881121393999898, -7.741624614999973],
              [-38.881143334999898, -7.741434312999964],
              [-38.881148997, -7.741385207999948],
              [-38.881185077999902, -7.741072255999939],
              [-38.881243787, -7.740556360999964],
              [-38.881104224999902, -7.739247767999926],
              [-38.8810533609999, -7.738770838999931],
              [-38.880964605, -7.737938622999937],
              [-38.880258652999899, -7.736171793999967],
              [-38.880154954, -7.735912259999962],
              [-38.880241976, -7.735565373999983],
              [-38.879330019999898, -7.733832031999948],
              [-38.879485389112297, -7.732500659009921],
              [-38.879632081, -7.73124364199998],
              [-38.881190515999897, -7.728050422999955],
              [-38.881027238999899, -7.726235853999986],
              [-38.880912822, -7.724964633999956],
              [-38.879804134, -7.723301574999947],
              [-38.874161172, -7.719093016999976],
              [-38.873327683, -7.717095698999965],
              [-38.873080347, -7.714866491999945],
              [-38.869744563, -7.711541745999961],
              [-38.868851653, -7.711179763999949],
              [-38.8680317, -7.711217241999973],
              [-38.86666292, -7.711279846999959],
              [-38.864436408, -7.713642115999956],
              [-38.860936388, -7.714607762999946],
              [-38.857740822, -7.714708349999968],
              [-38.857575294999897, -7.714847550999925],
              [-38.855521517999897, -7.716574687999977],
              [-38.854541864999902, -7.716000674999987],
              [-38.8544390509999, -7.715940415999971],
              [-38.853771863, -7.715549373999981],
              [-38.8518321, -7.715920704999974],
              [-38.851745005999902, -7.71593737799997],
              [-38.849688092, -7.717125422999973],
              [-38.846102215, -7.720415029999963],
              [-38.838572939, -7.719930836999934],
              [-38.838022942999899, -7.719895467999942],
              [-38.8344821479999, -7.719667765999939],
              [-38.833551881999902, -7.719607881999938],
              [-38.831823762999903, -7.720458076999921],
              [-38.830650251, -7.720401196999938],
              [-38.828109321999897, -7.719614470999935],
              [-38.824579843, -7.715889773999945],
              [-38.821962184, -7.714794375999937],
              [-38.819083686999903, -7.709462236999973],
              [-38.817408245, -7.705858962999977],
              [-38.818439125999902, -7.702983322999962],
              [-38.81816112, -7.702124733999939],
              [-38.818064020999898, -7.702008177999978],
              [-38.816908882999897, -7.700621563999966],
              [-38.816908528999903, -7.700609780999972],
              [-38.816907176, -7.700564778999924],
              [-38.816880183, -7.699666589999937],
              [-38.815023921, -7.69739078899994],
              [-38.815406058, -7.691328483999934],
              [-38.815031791, -7.688528001999943],
              [-38.815104345, -7.687979614999963],
              [-38.815831554999903, -7.686663995999943],
              [-38.818543507999898, -7.685363758999933],
              [-38.81914253, -7.683074736999967],
              [-38.819173227, -7.682957433999957],
              [-38.819271317, -7.682582603999952],
              [-38.819853991999899, -7.681762932999954],
              [-38.819914600999901, -7.681677678999958],
              [-38.824241257, -7.678344280999961],
              [-38.824326183, -7.677254143999956],
              [-38.821197164999901, -7.672308305999936],
              [-38.819689922, -7.664827729999952],
              [-38.817933968, -7.663734391999978],
              [-38.816394104, -7.663970593999981],
              [-38.815826215999898, -7.664057760999984],
              [-38.815650916, -7.664253813999958],
              [-38.813132191, -7.66707086599996],
              [-38.812244253, -7.668700609999973],
              [-38.812191642, -7.668797172999978],
              [-38.810055163, -7.668939395999931],
              [-38.807741818999901, -7.667708026999947],
              [-38.805572987999902, -7.667441666999932],
              [-38.804982004, -7.667560053999989],
              [-38.802850550999899, -7.667987029999949],
              [-38.802199257, -7.668389735999945],
              [-38.799844932, -7.669049711999952],
              [-38.799379820999903, -7.669180062999942],
              [-38.799045178706315, -7.669157514671324],
              [-38.799045178706315, -7.750461434175618],
              [-38.899045178706317, -7.750461434175618],
              [-38.899045178706317, -7.745904290258525],
              [-38.897132590999902, -7.748569394999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '818',
        ID: 42912.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -7.7005,
        ANNUAL: 5814.0,
        JAN: 6053.0,
        FEB: 5988.0,
        MAR: 5904.0,
        APR: 5549.0,
        MAY: 4967.0,
        JUN: 4660.0,
        JUL: 4934.0,
        AUG: 5756.0,
        SEP: 6542.0,
        OCT: 6507.0,
        NOV: 6641.0,
        DEC: 6272.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.7954334799999, -7.668914156999969],
              [-38.794244931, -7.668226560999933],
              [-38.793002564999902, -7.668134607999943],
              [-38.792490821999898, -7.668096731999976],
              [-38.791550991, -7.670732144999933],
              [-38.790747785, -7.67114966299993],
              [-38.788848023, -7.670465801999969],
              [-38.788594276, -7.670291587999943],
              [-38.786115572, -7.668589724999951],
              [-38.785055793, -7.668055483999983],
              [-38.784912752, -7.667983375999944],
              [-38.783710017, -7.667377068999937],
              [-38.783074494999902, -7.666647191999967],
              [-38.781824383, -7.661865884999932],
              [-38.780363823999899, -7.66155285199994],
              [-38.780211677, -7.661566673999968],
              [-38.780070877999897, -7.661579468999943],
              [-38.779475437, -7.661633576999987],
              [-38.778832404999903, -7.661692009999967],
              [-38.777464025, -7.66283513599996],
              [-38.775428931, -7.662433370999959],
              [-38.773156269, -7.661034757999973],
              [-38.771295403, -7.659085471999963],
              [-38.7692390879999, -7.658769544999922],
              [-38.768472163999903, -7.657960898999939],
              [-38.763912698, -7.656401158999925],
              [-38.760612449, -7.656000815999965],
              [-38.760377241999898, -7.656113576999938],
              [-38.758430494, -7.657046936999966],
              [-38.755674068, -7.658921911999923],
              [-38.755419386, -7.659095151999949],
              [-38.754467116999898, -7.659742902999942],
              [-38.751158436, -7.659724150999976],
              [-38.747851083999905, -7.660389870999944],
              [-38.745355001999897, -7.65993652299994],
              [-38.743659457, -7.660665927999965],
              [-38.742802041999902, -7.660322],
              [-38.742855622, -7.656110521999942],
              [-38.742267679999898, -7.655295843999962],
              [-38.742629677, -7.652326615999922],
              [-38.742248687768416, -7.650461434175618],
              [-38.699045178706314, -7.650461434175618],
              [-38.699045178706314, -7.750461434175618],
              [-38.799045178706315, -7.750461434175618],
              [-38.799045178706315, -7.669157514671324],
              [-38.7954334799999, -7.668914156999969],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '819',
        ID: 42913.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -7.7005,
        ANNUAL: 5847.0,
        JAN: 6126.0,
        FEB: 6062.0,
        MAR: 5967.0,
        APR: 5624.0,
        MAY: 4979.0,
        JUN: 4642.0,
        JUL: 4923.0,
        AUG: 5751.0,
        SEP: 6547.0,
        OCT: 6520.0,
        NOV: 6709.0,
        DEC: 6318.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.676409072, -7.651578750999925],
              [-38.675073327999897, -7.653088381999964],
              [-38.674271389, -7.657186362999939],
              [-38.672783197999898, -7.658795568999954],
              [-38.6699397739999, -7.660482998999949],
              [-38.669324236, -7.659760259999929],
              [-38.665748473, -7.65787043599994],
              [-38.663596363999901, -7.65752294399994],
              [-38.658666433999898, -7.662313229999929],
              [-38.658663028, -7.662316539999946],
              [-38.658185771, -7.662853500999965],
              [-38.654409041, -7.667102693999943],
              [-38.650710375, -7.668564701999968],
              [-38.651412547999897, -7.677082051999948],
              [-38.648407023999908, -7.678656897999923],
              [-38.642083924, -7.677636629999936],
              [-38.641991318, -7.686146320999976],
              [-38.641317598999898, -7.68646398599997],
              [-38.6382171119999, -7.687925893999933],
              [-38.634075824, -7.690780463999949],
              [-38.628158633999902, -7.693361121999944],
              [-38.626438503, -7.694724889999978],
              [-38.625933659999902, -7.695601134999956],
              [-38.625492535, -7.699001981999963],
              [-38.626268044, -7.702957839999941],
              [-38.6262329199999, -7.704499732999983],
              [-38.626229927999901, -7.70463105999994],
              [-38.626190665, -7.706354696999933],
              [-38.625328980999903, -7.708318580999952],
              [-38.623664818, -7.709685840999949],
              [-38.62074101, -7.710187235999973],
              [-38.616716693999898, -7.713490663999943],
              [-38.614073071, -7.715659865999956],
              [-38.612822233, -7.719530341999925],
              [-38.611156966, -7.721512500999953],
              [-38.608942066999901, -7.724148886999956],
              [-38.607968285, -7.725947882999955],
              [-38.607827801, -7.727045512999949],
              [-38.609025532, -7.729725564999975],
              [-38.609434093999901, -7.731916011999932],
              [-38.608962036, -7.735787245999967],
              [-38.6075758429999, -7.739478960999977],
              [-38.607072794, -7.740298361999982],
              [-38.606073936, -7.740570181999948],
              [-38.604301563, -7.739931798999987],
              [-38.601299339, -7.740142487999943],
              [-38.599662982, -7.741482306999956],
              [-38.599045178706312, -7.741659763417581],
              [-38.599045178706312, -7.750461434175618],
              [-38.699045178706314, -7.750461434175618],
              [-38.699045178706314, -7.650461434175618],
              [-38.67693512985111, -7.650461434175618],
              [-38.676409072, -7.651578750999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '820',
        ID: 42914.0,
        COUNTRY: 'Brasil',
        LON: -38.549,
        LAT: -7.7005,
        ANNUAL: 5901.0,
        JAN: 6189.0,
        FEB: 6097.0,
        MAR: 6033.0,
        APR: 5721.0,
        MAY: 5066.0,
        JUN: 4741.0,
        JUL: 5004.0,
        AUG: 5816.0,
        SEP: 6568.0,
        OCT: 6570.0,
        NOV: 6692.0,
        DEC: 6319.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.597737461999898, -7.742035388999965],
              [-38.597162714, -7.742854856999934],
              [-38.596960524999901, -7.744900738999949],
              [-38.597382579999902, -7.746722932999941],
              [-38.596559998702631, -7.750461434175618],
              [-38.599045178706312, -7.750461434175618],
              [-38.599045178706312, -7.741659763417581],
              [-38.597737461999898, -7.742035388999965],
            ],
          ],
          [
            [
              [-38.582299573999897, -7.749063105999946],
              [-38.580967769, -7.747556885999925],
              [-38.579030401, -7.746599928999959],
              [-38.578018669, -7.746607372999952],
              [-38.576157028, -7.750287921999933],
              [-38.576159307934866, -7.750461434175618],
              [-38.582944464890041, -7.750461434175618],
              [-38.582299573999897, -7.749063105999946],
            ],
          ],
          [
            [
              [-38.546189958999911, -7.749450443999929],
              [-38.543133327, -7.749001025999963],
              [-38.542124429559685, -7.750461434175618],
              [-38.546993973347647, -7.750461434175618],
              [-38.546189958999911, -7.749450443999929],
            ],
          ],
          [
            [
              [-38.518381818, -7.749828292999956],
              [-38.516604867, -7.749329334999974],
              [-38.507429731999899, -7.749952092999932],
              [-38.505139098345978, -7.750461434175618],
              [-38.519246162516616, -7.750461434175618],
              [-38.518381818, -7.749828292999956],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '821',
        ID: 42915.0,
        COUNTRY: 'Brasil',
        LON: -38.449,
        LAT: -7.7005,
        ANNUAL: 5899.0,
        JAN: 6197.0,
        FEB: 6087.0,
        MAR: 6051.0,
        APR: 5718.0,
        MAY: 5065.0,
        JUN: 4756.0,
        JUL: 4996.0,
        AUG: 5832.0,
        SEP: 6554.0,
        OCT: 6556.0,
        NOV: 6684.0,
        DEC: 6290.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.496575436999898, -7.750031891999976],
              [-38.495740094, -7.744483906999964],
              [-38.495799287, -7.738808436999926],
              [-38.490519933, -7.73466155099993],
              [-38.482080631, -7.733315893999925],
              [-38.480700984, -7.732175176999922],
              [-38.479653685, -7.732697230999978],
              [-38.47622318, -7.733370490999953],
              [-38.471563279, -7.733226958999979],
              [-38.468117145, -7.732428415999952],
              [-38.463975698, -7.730892146999961],
              [-38.462255281999902, -7.729539969999955],
              [-38.460952448999898, -7.728515995999934],
              [-38.457788272999899, -7.723436345999968],
              [-38.457083419, -7.718106981999944],
              [-38.456702236, -7.718009997999961],
              [-38.455017717, -7.717581408999924],
              [-38.450072011, -7.717372165999952],
              [-38.449728351, -7.717357626999957],
              [-38.449698861999899, -7.717356381999928],
              [-38.445454991, -7.717952981999925],
              [-38.443868366999901, -7.719264392999949],
              [-38.440018435, -7.721232207999951],
              [-38.436735321999898, -7.721774209999975],
              [-38.435475685, -7.721112764999932],
              [-38.434215993999899, -7.720451401999973],
              [-38.427625511, -7.730036702999943],
              [-38.418740505999899, -7.731116175999947],
              [-38.413966086999899, -7.729916322999931],
              [-38.412485878, -7.73010789999995],
              [-38.409507846999901, -7.730493282999941],
              [-38.406033453, -7.726880372999974],
              [-38.400824526, -7.720060750999956],
              [-38.403930934, -7.714442538999955],
              [-38.4017054729999, -7.713172843999926],
              [-38.399045178706309, -7.712319810563106],
              [-38.399045178706309, -7.750461434175618],
              [-38.497752251221485, -7.750461434175618],
              [-38.496575436999898, -7.750031891999976],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '822',
        ID: 42916.0,
        COUNTRY: 'Brasil',
        LON: -38.349,
        LAT: -7.7005,
        ANNUAL: 5867.0,
        JAN: 6176.0,
        FEB: 6057.0,
        MAR: 6057.0,
        APR: 5674.0,
        MAY: 5029.0,
        JUN: 4703.0,
        JUL: 4953.0,
        AUG: 5777.0,
        SEP: 6476.0,
        OCT: 6514.0,
        NOV: 6691.0,
        DEC: 6295.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.388350948, -7.708890664999953],
              [-38.382253324, -7.707475195999967],
              [-38.379835910999901, -7.704942083999975],
              [-38.379063932999898, -7.703253229999964],
              [-38.375775738999899, -7.70067210499997],
              [-38.372581918, -7.699636432999976],
              [-38.370937042, -7.698415257999952],
              [-38.37016611, -7.69691589399996],
              [-38.370074065, -7.692649511999957],
              [-38.369853244, -7.692291808999927],
              [-38.369206176, -7.691243635999961],
              [-38.367472482, -7.69005109199998],
              [-38.366885131999901, -7.689647075999973],
              [-38.362821473999901, -7.687766770999934],
              [-38.361616542, -7.686302853999953],
              [-38.357495875, -7.67716191199997],
              [-38.354977875, -7.67842522899997],
              [-38.35293576, -7.684701032999944],
              [-38.350609936999902, -7.685915996999936],
              [-38.350802595999902, -7.686993480999945],
              [-38.35031665, -7.688678879999944],
              [-38.348378419, -7.689425728999937],
              [-38.344691544999897, -7.694996722999969],
              [-38.341203264999898, -7.695928797999946],
              [-38.339075702999899, -7.695175905999974],
              [-38.338786199, -7.694143045999975],
              [-38.337197511999896, -7.693576387999945],
              [-38.335884316, -7.693108089999944],
              [-38.333075042, -7.693432295999976],
              [-38.330357917999898, -7.696147039999962],
              [-38.326685642, -7.693704509999982],
              [-38.323834548, -7.69575603799996],
              [-38.320773024999902, -7.697958981999925],
              [-38.321057246, -7.702644797999943],
              [-38.318628753, -7.706670313999954],
              [-38.316204018999898, -7.709150677999966],
              [-38.316200039, -7.711587416999974],
              [-38.317552401999897, -7.713463976999951],
              [-38.317257289999901, -7.715524316999954],
              [-38.315803748999897, -7.716365150999921],
              [-38.312029692, -7.716313306999949],
              [-38.315882148, -7.72847294799993],
              [-38.312779909, -7.731562462999926],
              [-38.310929877, -7.737369559999936],
              [-38.3110237879999, -7.738841278999927],
              [-38.310876941999908, -7.739077745999966],
              [-38.308958280999903, -7.74216628299996],
              [-38.302157348, -7.745204358999954],
              [-38.303809688999898, -7.748993678999969],
              [-38.304175858, -7.750244710999939],
              [-38.304316371755895, -7.750461434175618],
              [-38.399045178706309, -7.750461434175618],
              [-38.399045178706309, -7.712319810563106],
              [-38.388350948, -7.708890664999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '823',
        ID: 42919.0,
        COUNTRY: 'Brasil',
        LON: -38.049,
        LAT: -7.7005,
        ANNUAL: 5923.0,
        JAN: 6192.0,
        FEB: 6163.0,
        MAR: 6154.0,
        APR: 5790.0,
        MAY: 5109.0,
        JUN: 4718.0,
        JUL: 4968.0,
        AUG: 5802.0,
        SEP: 6498.0,
        OCT: 6586.0,
        NOV: 6766.0,
        DEC: 6328.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.039581585, -7.748119057999926],
              [-38.029915447999898, -7.749835288999973],
              [-38.029556222113634, -7.750461434175618],
              [-38.04232816068086, -7.750461434175618],
              [-38.039581585, -7.748119057999926],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '824',
        ID: 42920.0,
        COUNTRY: 'Brasil',
        LON: -37.949,
        LAT: -7.7005,
        ANNUAL: 5879.0,
        JAN: 6182.0,
        FEB: 6137.0,
        MAR: 6126.0,
        APR: 5768.0,
        MAY: 5054.0,
        JUN: 4626.0,
        JUL: 4890.0,
        AUG: 5727.0,
        SEP: 6445.0,
        OCT: 6554.0,
        NOV: 6687.0,
        DEC: 6350.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.945631036, -7.749678314999924],
              [-37.945186382, -7.748158618999988],
              [-37.944677278, -7.746418656999941],
              [-37.944656212, -7.746346658999925],
              [-37.944286899, -7.74508451099996],
              [-37.943176794, -7.741290668999967],
              [-37.943117507, -7.741088051999943],
              [-37.943102021, -7.741035128999941],
              [-37.94299812, -7.740971618999938],
              [-37.933982865, -7.735460995999969],
              [-37.926850514, -7.729932159999975],
              [-37.922350493, -7.723637581999986],
              [-37.923308412, -7.716153473999952],
              [-37.923396603, -7.716039898999953],
              [-37.923449112, -7.715949376999966],
              [-37.923912042, -7.715151319999954],
              [-37.923934897, -7.715111920999959],
              [-37.923152225, -7.714710677999957],
              [-37.922684933, -7.714292921999967],
              [-37.922213456, -7.713923385999983],
              [-37.922201157, -7.713913746999945],
              [-37.921490021999901, -7.71336988699995],
              [-37.921392039, -7.713226242999956],
              [-37.918736899, -7.712493106999945],
              [-37.918736899, -7.712374220999953],
              [-37.9176438, -7.711429730999944],
              [-37.916513868, -7.710946671999984],
              [-37.914195502, -7.709955543999976],
              [-37.913832649, -7.709800419999973],
              [-37.91306665, -7.709472946999933],
              [-37.904986074, -7.710154185999952],
              [-37.899045178706302, -7.712090568760986],
              [-37.899045178706302, -7.750461434175618],
              [-37.945860172417483, -7.750461434175618],
              [-37.945631036, -7.749678314999924],
            ],
          ],
          [
            [
              [-37.89922662, -7.706044269999956],
              [-37.901901274, -7.69938232499993],
              [-37.903326078, -7.698099673999931],
              [-37.903234658, -7.697568909999969],
              [-37.902927405, -7.695785072999971],
              [-37.902746924, -7.694737247999973],
              [-37.9018566, -7.693835963999963],
              [-37.900071523390501, -7.692642998183197],
              [-37.899045178706302, -7.691957092537488],
              [-37.899045178706302, -7.70622707183679],
              [-37.89922662, -7.706044269999956],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '825',
        ID: 42921.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -7.7005,
        ANNUAL: 5875.0,
        JAN: 6190.0,
        FEB: 6146.0,
        MAR: 6160.0,
        APR: 5780.0,
        MAY: 5035.0,
        JUN: 4593.0,
        JUL: 4852.0,
        AUG: 5692.0,
        SEP: 6428.0,
        OCT: 6543.0,
        NOV: 6713.0,
        DEC: 6370.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.894334877999903, -7.713625849999965],
              [-37.892297938, -7.710937416999967],
              [-37.896941238999901, -7.708346787999963],
              [-37.899045178706302, -7.70622707183679],
              [-37.899045178706302, -7.691957092537488],
              [-37.897353402, -7.690826478999956],
              [-37.8914676059999, -7.689487861999963],
              [-37.88323675, -7.685628049999935],
              [-37.881916729, -7.684639052999964],
              [-37.880596709, -7.683650055999982],
              [-37.876742094, -7.679552410999967],
              [-37.872887469, -7.675454754999985],
              [-37.869649105999905, -7.667557927999946],
              [-37.868439759999902, -7.664608721999971],
              [-37.867013498999903, -7.662625180999948],
              [-37.857158855, -7.651968881999946],
              [-37.852994844806446, -7.650461434175618],
              [-37.799045178706301, -7.650461434175618],
              [-37.799045178706301, -7.750461434175618],
              [-37.899045178706302, -7.750461434175618],
              [-37.899045178706302, -7.712090568760986],
              [-37.894334877999903, -7.713625849999965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '826',
        ID: 42922.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -7.7005,
        ANNUAL: 5868.0,
        JAN: 6191.0,
        FEB: 6173.0,
        MAR: 6160.0,
        APR: 5776.0,
        MAY: 5007.0,
        JUN: 4554.0,
        JUL: 4783.0,
        AUG: 5649.0,
        SEP: 6431.0,
        OCT: 6534.0,
        NOV: 6756.0,
        DEC: 6402.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.769062042, -7.650541781999944],
              [-37.768913268999903, -7.650691508999953],
              [-37.768768311, -7.65081977799997],
              [-37.76865387, -7.650939940999927],
              [-37.768562317, -7.651034831999932],
              [-37.768466949, -7.651134967999954],
              [-37.768302917, -7.651315211999928],
              [-37.768192290999899, -7.651438235999936],
              [-37.768108368, -7.651543139999933],
              [-37.768009186, -7.651656626999961],
              [-37.767887115, -7.651794909999981],
              [-37.76776123, -7.651938437999947],
              [-37.767726898, -7.652121543999958],
              [-37.767791748, -7.652306555999925],
              [-37.76789093, -7.652491568999956],
              [-37.7679901119999, -7.652691840999976],
              [-37.768074036, -7.65290355699995],
              [-37.76815033, -7.653116702999966],
              [-37.768264771, -7.653326510999924],
              [-37.768398284999897, -7.653553485999958],
              [-37.768531799, -7.653768538999934],
              [-37.768638611, -7.653966902999967],
              [-37.768737793, -7.65414810199997],
              [-37.768810271999897, -7.654311656999942],
              [-37.768878937, -7.654438494999973],
              [-37.768962859999903, -7.654558181999965],
              [-37.769062042, -7.654709815999986],
              [-37.76914978, -7.654884814999948],
              [-37.769161224, -7.655116557999964],
              [-37.769126892, -7.655354975999959],
              [-37.769031524999903, -7.655633448999938],
              [-37.768932343, -7.655806540999944],
              [-37.768836974999907, -7.655948161999965],
              [-37.768756865999897, -7.656054972999932],
              [-37.768676758, -7.656165122999938],
              [-37.768577576, -7.656301497999951],
              [-37.768451690999903, -7.656449794999966],
              [-37.768318176, -7.656599997999933],
              [-37.768184662, -7.656741618999932],
              [-37.768054962, -7.656848429999965],
              [-37.767959595, -7.656903266999952],
              [-37.767871856999903, -7.656913279999977],
              [-37.767780304, -7.656931876999972],
              [-37.767662048, -7.656949996999964],
              [-37.767532349, -7.656981467999976],
              [-37.767375946, -7.65705346999994],
              [-37.767304391, -7.657113143999942],
              [-37.767246413999899, -7.657161493999987],
              [-37.767222429999897, -7.657181495999961],
              [-37.767120361, -7.657266616999951],
              [-37.766986846999899, -7.657364844999955],
              [-37.766904831, -7.657437323999942],
              [-37.766822815, -7.657509803999977],
              [-37.766677855999902, -7.657673358999933],
              [-37.766559601, -7.657826899999971],
              [-37.766395568999897, -7.658031462999938],
              [-37.766269684, -7.658176898999947],
              [-37.766151427999901, -7.658331870999964],
              [-37.7660369869999, -7.658488272999939],
              [-37.765926361, -7.658653258999949],
              [-37.765834808, -7.658823489999962],
              [-37.765735626, -7.658989905999925],
              [-37.765647887999897, -7.659156798999949],
              [-37.765579224, -7.659305094999988],
              [-37.765537262, -7.659438132999978],
              [-37.765522002999901, -7.659510850999932],
              [-37.7655067439999, -7.659583567999965],
              [-37.765464783, -7.659718512999973],
              [-37.765392302999899, -7.659831523999966],
              [-37.765319824, -7.659951686999922],
              [-37.765296457, -7.659991442999972],
              [-37.765259569, -7.660052668999981],
              [-37.765206985, -7.660139948999936],
              [-37.764965630999903, -7.660540548999967],
              [-37.76411313099991, -7.661955529999935],
              [-37.764084144, -7.662003642999931],
              [-37.763641342, -7.662738606999943],
              [-37.76359193, -7.662820619999927],
              [-37.763323655, -7.663229569999939],
              [-37.7615310219999, -7.66596220699995],
              [-37.760974530999903, -7.666810503999955],
              [-37.758499222999902, -7.666634593999964],
              [-37.756933221999901, -7.666523304999942],
              [-37.750647957999902, -7.662889424999949],
              [-37.744180271999909, -7.654495194999976],
              [-37.743843661027725, -7.650461434175618],
              [-37.699045178706299, -7.650461434175618],
              [-37.699045178706299, -7.750461434175618],
              [-37.799045178706301, -7.750461434175618],
              [-37.799045178706301, -7.650461434175618],
              [-37.769141153799659, -7.650461434175618],
              [-37.769062042, -7.650541781999944],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '827',
        ID: 42923.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -7.7005,
        ANNUAL: 5844.0,
        JAN: 6231.0,
        FEB: 6202.0,
        MAR: 6117.0,
        APR: 5752.0,
        MAY: 4961.0,
        JUN: 4526.0,
        JUL: 4741.0,
        AUG: 5594.0,
        SEP: 6389.0,
        OCT: 6523.0,
        NOV: 6742.0,
        DEC: 6351.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -7.750461434175618],
              [-37.699045178706299, -7.650461434175618],
              [-37.599045178706298, -7.650461434175618],
              [-37.599045178706298, -7.750461434175618],
              [-37.699045178706299, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '828',
        ID: 42924.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -7.7005,
        ANNUAL: 5843.0,
        JAN: 6207.0,
        FEB: 6194.0,
        MAR: 6138.0,
        APR: 5757.0,
        MAY: 4979.0,
        JUN: 4533.0,
        JUL: 4729.0,
        AUG: 5578.0,
        SEP: 6354.0,
        OCT: 6527.0,
        NOV: 6749.0,
        DEC: 6369.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -7.750461434175618],
              [-37.599045178706298, -7.650461434175618],
              [-37.499045178706297, -7.650461434175618],
              [-37.499045178706297, -7.750461434175618],
              [-37.599045178706298, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '829',
        ID: 42925.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -7.7005,
        ANNUAL: 5813.0,
        JAN: 6182.0,
        FEB: 6136.0,
        MAR: 6118.0,
        APR: 5728.0,
        MAY: 4952.0,
        JUN: 4507.0,
        JUL: 4679.0,
        AUG: 5510.0,
        SEP: 6343.0,
        OCT: 6506.0,
        NOV: 6734.0,
        DEC: 6366.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -7.750461434175618],
              [-37.499045178706297, -7.650461434175618],
              [-37.399045178706295, -7.650461434175618],
              [-37.399045178706295, -7.750461434175618],
              [-37.499045178706297, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '830',
        ID: 42926.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -7.7005,
        ANNUAL: 5787.0,
        JAN: 6168.0,
        FEB: 6033.0,
        MAR: 6070.0,
        APR: 5671.0,
        MAY: 4900.0,
        JUN: 4479.0,
        JUL: 4679.0,
        AUG: 5463.0,
        SEP: 6339.0,
        OCT: 6525.0,
        NOV: 6729.0,
        DEC: 6390.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -7.750461434175618],
              [-37.399045178706295, -7.650461434175618],
              [-37.299045178706294, -7.650461434175618],
              [-37.299045178706294, -7.750461434175618],
              [-37.399045178706295, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '831',
        ID: 42927.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -7.7005,
        ANNUAL: 5798.0,
        JAN: 6204.0,
        FEB: 6060.0,
        MAR: 6099.0,
        APR: 5700.0,
        MAY: 4948.0,
        JUN: 4488.0,
        JUL: 4662.0,
        AUG: 5439.0,
        SEP: 6333.0,
        OCT: 6525.0,
        NOV: 6718.0,
        DEC: 6398.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -7.750461434175618],
              [-37.299045178706294, -7.650461434175618],
              [-37.199045178706292, -7.650461434175618],
              [-37.199045178706292, -7.750461434175618],
              [-37.299045178706294, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '832',
        ID: 42928.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -7.7005,
        ANNUAL: 5811.0,
        JAN: 6247.0,
        FEB: 6125.0,
        MAR: 6120.0,
        APR: 5748.0,
        MAY: 4983.0,
        JUN: 4515.0,
        JUL: 4644.0,
        AUG: 5427.0,
        SEP: 6297.0,
        OCT: 6531.0,
        NOV: 6708.0,
        DEC: 6383.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.195131247999903, -7.652062741999947],
              [-37.19274002, -7.654839340999962],
              [-37.189168638999902, -7.656626498999966],
              [-37.1877720969999, -7.657631386999983],
              [-37.184872809999902, -7.660766314999933],
              [-37.180116992, -7.667105336999945],
              [-37.176812663, -7.672780755999954],
              [-37.176351372999903, -7.674542641999948],
              [-37.176312962999901, -7.679236547999952],
              [-37.175757196999903, -7.681381084999935],
              [-37.1740581339999, -7.684294992999983],
              [-37.165273562, -7.694075292999966],
              [-37.161038946, -7.697966927999966],
              [-37.154542917, -7.706403779999967],
              [-37.153720064, -7.708036055999973],
              [-37.151948607, -7.711550057999942],
              [-37.150497894, -7.715932715999942],
              [-37.150189054, -7.716865731999969],
              [-37.150179154999897, -7.71980287999997],
              [-37.150966887, -7.72404674699993],
              [-37.150599344999897, -7.729032296999955],
              [-37.1487246249999, -7.733732519999931],
              [-37.146280813, -7.736636445999983],
              [-37.14256405, -7.739030369999972],
              [-37.140003055, -7.741934013999989],
              [-37.138944680999899, -7.745517658999969],
              [-37.13919851, -7.746189067999983],
              [-37.140663805, -7.750064960999961],
              [-37.140937581738001, -7.750461434175618],
              [-37.199045178706292, -7.750461434175618],
              [-37.199045178706292, -7.650461434175618],
              [-37.195714197125689, -7.650461434175618],
              [-37.195131247999903, -7.652062741999947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '833',
        ID: 42941.0,
        COUNTRY: 'Brasil',
        LON: -35.849,
        LAT: -7.7005,
        ANNUAL: 5339.0,
        JAN: 5725.0,
        FEB: 5763.0,
        MAR: 5777.0,
        APR: 5234.0,
        MAY: 4597.0,
        JUN: 4149.0,
        JUL: 4332.0,
        AUG: 5001.0,
        SEP: 5616.0,
        OCT: 5871.0,
        NOV: 6100.0,
        DEC: 5906.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.872118271, -7.749538910999953],
              [-35.871085709999903, -7.749430749999923],
              [-35.869544549999901, -7.750266650999948],
              [-35.869540961, -7.750265957999946],
              [-35.86723956, -7.749821283999943],
              [-35.867237667, -7.749821746999938],
              [-35.864625734686207, -7.750461434175618],
              [-35.873534329085444, -7.750461434175618],
              [-35.872118271, -7.749538910999953],
            ],
          ],
          [
            [
              [-35.850658022999902, -7.747509858999926],
              [-35.842368577999899, -7.747020019999987],
              [-35.841747143, -7.746983339999937],
              [-35.835812741, -7.747608733999934],
              [-35.834607271, -7.74825467399995],
              [-35.833128628999901, -7.749898425999958],
              [-35.832622174030192, -7.750461434175618],
              [-35.860395675120792, -7.750461434175618],
              [-35.850658022999902, -7.747509858999926],
            ],
          ],
          [
            [
              [-35.825294836999902, -7.749566819999925],
              [-35.82050921, -7.748894578999963],
              [-35.820321116999899, -7.748776241999949],
              [-35.820218927, -7.748711949999965],
              [-35.817333087, -7.746896338999932],
              [-35.815853871999899, -7.746675831999942],
              [-35.813693244, -7.746352605999959],
              [-35.812790939, -7.745706409999965],
              [-35.810567423, -7.745317131999974],
              [-35.808110519, -7.746672936999967],
              [-35.806873305, -7.746679794999982],
              [-35.806096571999902, -7.746684099999927],
              [-35.804177794999902, -7.745675008999974],
              [-35.8003199139999, -7.741824212999965],
              [-35.799045178706272, -7.741379497030061],
              [-35.799045178706272, -7.750461434175618],
              [-35.826671499111967, -7.750461434175618],
              [-35.825294836999902, -7.749566819999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '834',
        ID: 42942.0,
        COUNTRY: 'Brasil',
        LON: -35.749,
        LAT: -7.7005,
        ANNUAL: 5320.0,
        JAN: 5692.0,
        FEB: 5769.0,
        MAR: 5785.0,
        APR: 5204.0,
        MAY: 4558.0,
        JUN: 4119.0,
        JUL: 4326.0,
        AUG: 4988.0,
        SEP: 5588.0,
        OCT: 5819.0,
        NOV: 6075.0,
        DEC: 5911.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.797486525999901, -7.74083573099995],
              [-35.7963893209999, -7.740452469999973],
              [-35.795318035, -7.74007826299993],
              [-35.794779486, -7.73989045899998],
              [-35.791867523, -7.740420954999926],
              [-35.790949832, -7.740428071999923],
              [-35.789906851, -7.740436159999939],
              [-35.789216089, -7.740441443999948],
              [-35.786570711, -7.739043490999962],
              [-35.780577512, -7.738351392999959],
              [-35.775326691, -7.734159907999983],
              [-35.774351205999899, -7.732853201999942],
              [-35.772942846, -7.732100345999982],
              [-35.7675737759999, -7.726708873999947],
              [-35.764850351999897, -7.724900236999937],
              [-35.758999528999901, -7.722413760999935],
              [-35.758998086, -7.722414011999946],
              [-35.751810733999903, -7.72366407299995],
              [-35.748215109999897, -7.723301642999953],
              [-35.747251884, -7.723204551999963],
              [-35.746454241, -7.721387333999936],
              [-35.745384385, -7.718953312999927],
              [-35.743105747999905, -7.713769206999928],
              [-35.741381208, -7.711922153999978],
              [-35.736549437, -7.70959899299994],
              [-35.732245561999903, -7.708466704999974],
              [-35.728219187, -7.707999032999966],
              [-35.721401962, -7.709462315999934],
              [-35.721399830999907, -7.709461616999931],
              [-35.719159992999899, -7.708727473999939],
              [-35.716915356999898, -7.708584483999972],
              [-35.713620455999902, -7.70736996999995],
              [-35.711606643, -7.705214980999936],
              [-35.709385778999902, -7.698972718999928],
              [-35.708187671, -7.697347281999956],
              [-35.706770404999901, -7.695774133999984],
              [-35.70007702, -7.691856850999945],
              [-35.700072204, -7.69185699099995],
              [-35.699045178706271, -7.691887029175958],
              [-35.699045178706271, -7.750461434175618],
              [-35.799045178706272, -7.750461434175618],
              [-35.799045178706272, -7.741379497030061],
              [-35.797486525999901, -7.74083573099995],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '835',
        ID: 42943.0,
        COUNTRY: 'Brasil',
        LON: -35.649,
        LAT: -7.7005,
        ANNUAL: 5310.0,
        JAN: 5664.0,
        FEB: 5769.0,
        MAR: 5832.0,
        APR: 5203.0,
        MAY: 4510.0,
        JUN: 4037.0,
        JUL: 4246.0,
        AUG: 4960.0,
        SEP: 5571.0,
        OCT: 5822.0,
        NOV: 6131.0,
        DEC: 5979.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.697240909, -7.691939799999943],
              [-35.695354361999897, -7.693973955999971],
              [-35.695412147999903, -7.696966753999961],
              [-35.693717686, -7.699781457999965],
              [-35.689912127999897, -7.70067083399993],
              [-35.686483344, -7.704886050999953],
              [-35.685409504999903, -7.706206186999968],
              [-35.684113916999898, -7.707798933999928],
              [-35.683950960999908, -7.707735085999958],
              [-35.681855067, -7.706913886999934],
              [-35.681247422999903, -7.706226984999942],
              [-35.681148349999901, -7.705526873999936],
              [-35.681062488, -7.704962160999971],
              [-35.680933694, -7.70448991599994],
              [-35.6804977749999, -7.704139859999943],
              [-35.680443548, -7.704099986999955],
              [-35.680160929, -7.703892179999976],
              [-35.679888908, -7.703581297999968],
              [-35.679837292999899, -7.703522308999942],
              [-35.679655835999903, -7.703352077],
              [-35.679516958, -7.70322178999993],
              [-35.679351837999903, -7.703013737999974],
              [-35.677059963, -7.701814961999966],
              [-35.67509833, -7.700698745999935],
              [-35.674073432999897, -7.700268760999953],
              [-35.67394909, -7.700216593999929],
              [-35.673392025999902, -7.699950419999936],
              [-35.672981482999901, -7.699754255999954],
              [-35.671632448, -7.699562715999943],
              [-35.670549256, -7.699361268999949],
              [-35.670132649, -7.699154363999985],
              [-35.66969505, -7.699147954999947],
              [-35.669152336, -7.699140006999926],
              [-35.668656973999902, -7.698945164999947],
              [-35.668251343999898, -7.698426335999942],
              [-35.66823096199991, -7.698400266999944],
              [-35.6676819629999, -7.697696115999973],
              [-35.667471406999901, -7.697426054999939],
              [-35.667311239999897, -7.697419449999937],
              [-35.667030947999898, -7.697364134999932],
              [-35.666258184, -7.697179199999943],
              [-35.665929592999902, -7.697065265999987],
              [-35.665900668999903, -7.697113756999955],
              [-35.665873452, -7.697159384999964],
              [-35.665653635, -7.697326019999935],
              [-35.665566328, -7.697392204999965],
              [-35.665526536999899, -7.697413132999947],
              [-35.664866216999897, -7.697760423999968],
              [-35.664644955, -7.697575488999974],
              [-35.664654862, -7.69739385699995],
              [-35.664182616999902, -7.697403763999944],
              [-35.663540297999901, -7.697299737999972],
              [-35.662992097, -7.697266713999979],
              [-35.662597459, -7.697152779999946],
              [-35.662343173, -7.697061963999939],
              [-35.662102096999902, -7.696999217999972],
              [-35.661966698, -7.696984356999959],
              [-35.661719016999903, -7.696908401999959],
              [-35.661104768, -7.696921611999931],
              [-35.6604641, -7.697027288999951],
              [-35.6603130989999, -7.69704802099994],
              [-35.658966615999901, -7.697232892999983],
              [-35.657577790999902, -7.697423577999936],
              [-35.657267506, -7.697538857999976],
              [-35.657102243999901, -7.697600256999986],
              [-35.656798422, -7.697684468999969],
              [-35.655558734, -7.697048869999941],
              [-35.655117361999899, -7.697017969999933],
              [-35.653597026999897, -7.69667718899995],
              [-35.6522337809999, -7.696371618999929],
              [-35.651074287, -7.694617173999971],
              [-35.645267122999897, -7.692409021999935],
              [-35.639407951, -7.686330706999976],
              [-35.636379819, -7.683855240999942],
              [-35.633547773, -7.682663360999919],
              [-35.631523595, -7.681237389999979],
              [-35.628465283, -7.677506586999932],
              [-35.628354596, -7.677371560999964],
              [-35.62610791, -7.674630851999959],
              [-35.6258403369999, -7.674182850999951],
              [-35.625244901, -7.673185905999954],
              [-35.62206335, -7.669487364999947],
              [-35.621311275, -7.668613004999938],
              [-35.621975716, -7.66859049099997],
              [-35.621983972, -7.668590210999978],
              [-35.622413286, -7.66843169599997],
              [-35.623474754, -7.667965870999982],
              [-35.623605456999897, -7.667929728999975],
              [-35.623960467, -7.667104125999963],
              [-35.623552619, -7.66683002499996],
              [-35.623904325999902, -7.666484922999925],
              [-35.624010003, -7.66639328099996],
              [-35.624101644999897, -7.666276870999979],
              [-35.624695073999902, -7.665925507999924],
              [-35.624769558, -7.665881406999977],
              [-35.624701625999897, -7.665811849999957],
              [-35.622421542, -7.663477250999959],
              [-35.621773016, -7.66272696299994],
              [-35.619694118, -7.663970299999962],
              [-35.619647455999903, -7.663998206999964],
              [-35.619507202, -7.664082088999976],
              [-35.619496956, -7.662513420999967],
              [-35.618045246, -7.661700028999962],
              [-35.61603473, -7.659393034999947],
              [-35.615828258, -7.658943217999935],
              [-35.614619274999903, -7.656309350999955],
              [-35.611870769999904, -7.654544295999925],
              [-35.60966215, -7.653779467999937],
              [-35.607868679, -7.65341363099998],
              [-35.606218677, -7.653654273999959],
              [-35.602430085, -7.654354648999973],
              [-35.59904517870627, -7.654341596893637],
              [-35.59904517870627, -7.750461434175618],
              [-35.699045178706271, -7.750461434175618],
              [-35.699045178706271, -7.691887029175958],
              [-35.697240909, -7.691939799999943],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '836',
        ID: 42944.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -7.7005,
        ANNUAL: 5237.0,
        JAN: 5633.0,
        FEB: 5733.0,
        MAR: 5777.0,
        APR: 5185.0,
        MAY: 4457.0,
        JUN: 3984.0,
        JUL: 4115.0,
        AUG: 4857.0,
        SEP: 5442.0,
        OCT: 5721.0,
        NOV: 6027.0,
        DEC: 5914.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.59748866, -7.654335594999965],
              [-35.593493134, -7.653586841999988],
              [-35.591965703, -7.653264540999943],
              [-35.590746914, -7.652408975999955],
              [-35.589323002999897, -7.651452068999955],
              [-35.588469388, -7.650727080999956],
              [-35.588055473143775, -7.650461434175618],
              [-35.586112941291873, -7.650461434175618],
              [-35.584213002999903, -7.651206507999976],
              [-35.582657786, -7.651264974999951],
              [-35.577208678, -7.654854837999951],
              [-35.5734434149999, -7.65471451799992],
              [-35.572484559, -7.657170123999951],
              [-35.571923276999897, -7.657965272999951],
              [-35.570327133, -7.658368693999932],
              [-35.569676649, -7.658277716999972],
              [-35.569300375999902, -7.658225091999953],
              [-35.568654981999899, -7.658134826999976],
              [-35.568555832, -7.658087481999926],
              [-35.568370033, -7.657998760999929],
              [-35.567810135999899, -7.657731405999932],
              [-35.567614272999897, -7.657272440999982],
              [-35.567134844999899, -7.657026880999983],
              [-35.566486525999899, -7.657107542999947],
              [-35.565255136, -7.657260747999933],
              [-35.565018345999903, -7.657500461999974],
              [-35.564878025, -7.657804488999946],
              [-35.564462911, -7.658219603999939],
              [-35.5640927009999, -7.659000917999943],
              [-35.564092243999902, -7.659002962999974],
              [-35.563960096, -7.659280775999955],
              [-35.563247797, -7.659960542999953],
              [-35.562743985999901, -7.660441342999945],
              [-35.561761744, -7.660862303999972],
              [-35.559563391, -7.66133003899995],
              [-35.557471113, -7.659604232999921],
              [-35.555073138999902, -7.659318779999978],
              [-35.553692505, -7.66029820399996],
              [-35.552336892, -7.661259877999949],
              [-35.550302246999898, -7.661189717999947],
              [-35.547729707, -7.658547017999983],
              [-35.547899581, -7.657337502999952],
              [-35.534612056, -7.655484049999932],
              [-35.532811955999897, -7.654817569999976],
              [-35.532109122999898, -7.653854941999942],
              [-35.531664758742018, -7.650461434175618],
              [-35.499045178706268, -7.650461434175618],
              [-35.499045178706268, -7.750461434175618],
              [-35.59904517870627, -7.750461434175618],
              [-35.59904517870627, -7.654341596893637],
              [-35.59748866, -7.654335594999965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '837',
        ID: 42945.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -7.7005,
        ANNUAL: 5127.0,
        JAN: 5519.0,
        FEB: 5586.0,
        MAR: 5674.0,
        APR: 5078.0,
        MAY: 4376.0,
        JUN: 3919.0,
        JUL: 4012.0,
        AUG: 4756.0,
        SEP: 5340.0,
        OCT: 5556.0,
        NOV: 5927.0,
        DEC: 5786.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -7.750461434175618],
              [-35.499045178706268, -7.650461434175618],
              [-35.399045178706267, -7.650461434175618],
              [-35.399045178706267, -7.750461434175618],
              [-35.499045178706268, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '838',
        ID: 42946.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -7.7005,
        ANNUAL: 5141.0,
        JAN: 5467.0,
        FEB: 5615.0,
        MAR: 5695.0,
        APR: 5083.0,
        MAY: 4379.0,
        JUN: 4019.0,
        JUL: 4096.0,
        AUG: 4821.0,
        SEP: 5354.0,
        OCT: 5546.0,
        NOV: 5886.0,
        DEC: 5726.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -7.750461434175618],
              [-35.399045178706267, -7.650461434175618],
              [-35.299045178706265, -7.650461434175618],
              [-35.299045178706265, -7.750461434175618],
              [-35.399045178706267, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '839',
        ID: 42947.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -7.7005,
        ANNUAL: 5217.0,
        JAN: 5528.0,
        FEB: 5682.0,
        MAR: 5760.0,
        APR: 5146.0,
        MAY: 4432.0,
        JUN: 4066.0,
        JUL: 4179.0,
        AUG: 4896.0,
        SEP: 5431.0,
        OCT: 5643.0,
        NOV: 5980.0,
        DEC: 5860.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -7.750461434175618],
              [-35.299045178706265, -7.650461434175618],
              [-35.199045178706264, -7.650461434175618],
              [-35.199045178706264, -7.750461434175618],
              [-35.299045178706265, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '840',
        ID: 42948.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -7.7005,
        ANNUAL: 5311.0,
        JAN: 5644.0,
        FEB: 5806.0,
        MAR: 5854.0,
        APR: 5192.0,
        MAY: 4500.0,
        JUN: 4119.0,
        JUL: 4235.0,
        AUG: 4982.0,
        SEP: 5521.0,
        OCT: 5762.0,
        NOV: 6098.0,
        DEC: 6018.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -7.750461434175618],
              [-35.199045178706264, -7.650461434175618],
              [-35.099045178706263, -7.650461434175618],
              [-35.099045178706263, -7.750461434175618],
              [-35.199045178706264, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '841',
        ID: 42949.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -7.7005,
        ANNUAL: 5397.0,
        JAN: 5711.0,
        FEB: 6010.0,
        MAR: 5988.0,
        APR: 5244.0,
        MAY: 4569.0,
        JUN: 4193.0,
        JUL: 4296.0,
        AUG: 5063.0,
        SEP: 5544.0,
        OCT: 5851.0,
        NOV: 6140.0,
        DEC: 6152.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -7.750461434175618],
              [-35.099045178706263, -7.650461434175618],
              [-34.999045178706261, -7.650461434175618],
              [-34.999045178706261, -7.750461434175618],
              [-35.099045178706263, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '842',
        ID: 42950.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -7.7005,
        ANNUAL: 5400.0,
        JAN: 5749.0,
        FEB: 6030.0,
        MAR: 6021.0,
        APR: 5248.0,
        MAY: 4601.0,
        JUN: 4239.0,
        JUL: 4356.0,
        AUG: 5073.0,
        SEP: 5488.0,
        OCT: 5833.0,
        NOV: 6069.0,
        DEC: 6089.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.999045178706261, -7.750461434175618],
              [-34.999045178706261, -7.650461434175618],
              [-34.89904517870626, -7.650461434175618],
              [-34.89904517870626, -7.750461434175618],
              [-34.999045178706261, -7.750461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '843',
        ID: 42951.0,
        COUNTRY: 'Brasil',
        LON: -34.849,
        LAT: -7.7005,
        ANNUAL: 5532.0,
        JAN: 5881.0,
        FEB: 6127.0,
        MAR: 6088.0,
        APR: 5337.0,
        MAY: 4694.0,
        JUN: 4347.0,
        JUL: 4489.0,
        AUG: 5245.0,
        SEP: 5698.0,
        OCT: 6071.0,
        NOV: 6207.0,
        DEC: 6200.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.822792873, -7.650708386999951],
              [-34.822987614999903, -7.651429863999978],
              [-34.8232643549999, -7.652155738999974],
              [-34.823575307999903, -7.653128940999986],
              [-34.823760767, -7.653547771999968],
              [-34.824365176999898, -7.65546187299998],
              [-34.824626436, -7.65612580499993],
              [-34.825165649, -7.657551998999966],
              [-34.825391, -7.658093126999973],
              [-34.825654286, -7.65872534399994],
              [-34.826043526, -7.659623893999949],
              [-34.826532299999897, -7.660741733999941],
              [-34.826602011, -7.660941497999931],
              [-34.826700156, -7.661222742999942],
              [-34.826812683999897, -7.66154520299995],
              [-34.8271214009999, -7.662442292999968],
              [-34.827456507, -7.663386883999975],
              [-34.827786805, -7.66427828699995],
              [-34.828064165999898, -7.664996638999982],
              [-34.828277017999902, -7.665558641999957],
              [-34.8284816, -7.666098809999936],
              [-34.828649019, -7.666624202999976],
              [-34.828719216, -7.666844494999957],
              [-34.828836125, -7.667397446999932],
              [-34.828882715, -7.667584751999923],
              [-34.829000348, -7.668057663999951],
              [-34.829005376999902, -7.668554810999973],
              [-34.828993786, -7.668933334999959],
              [-34.829160247999901, -7.669436488999959],
              [-34.829411580999903, -7.670244749999935],
              [-34.829697687, -7.671621171999953],
              [-34.829894993000011, -7.672927595999973],
              [-34.830055186, -7.673771213999942],
              [-34.830150131, -7.674271221999954],
              [-34.830212999, -7.675322337999932],
              [-34.830254817, -7.676373965999963],
              [-34.830557464, -7.677048917999964],
              [-34.8308789989999, -7.678025318999974],
              [-34.831192578, -7.678817587999952],
              [-34.831627524999902, -7.679442247999925],
              [-34.832187598, -7.680075346999948],
              [-34.832777516, -7.680758998999972],
              [-34.833128809999899, -7.681173656999928],
              [-34.833344145999902, -7.68142783499996],
              [-34.833391375, -7.681716790999937],
              [-34.833475813999897, -7.682233406999959],
              [-34.833480134, -7.68253329699996],
              [-34.8335198629999, -7.682778537999972],
              [-34.833641532999899, -7.683083628999951],
              [-34.833927404, -7.683369708999979],
              [-34.834178404999903, -7.683637455999934],
              [-34.834324038, -7.68385744899996],
              [-34.834651804, -7.684096857999974],
              [-34.834974143999901, -7.684182327999965],
              [-34.835254339999899, -7.684248685999925],
              [-34.835485105, -7.684295175999978],
              [-34.835535001, -7.684280647999964],
              [-34.835683106, -7.68423752499996],
              [-34.836341443, -7.683954242999953],
              [-34.836867398, -7.68344482699997],
              [-34.837189856, -7.682970298999984],
              [-34.837297948, -7.682881125999931],
              [-34.837675520999902, -7.68256964299997],
              [-34.837932904999903, -7.682204140999939],
              [-34.839234775, -7.682128350999959],
              [-34.83948029, -7.682114057999973],
              [-34.839627414999903, -7.682105492999939],
              [-34.841367233, -7.68200420899996],
              [-34.842311131, -7.681949258999965],
              [-34.842957428, -7.682696778999977],
              [-34.843331889, -7.683189069999975],
              [-34.843764797999903, -7.683800604999922],
              [-34.8442446829999, -7.68437467199993],
              [-34.84456797, -7.684550451999935],
              [-34.848209031999907, -7.687789269999941],
              [-34.848112141999898, -7.687818951999935],
              [-34.846796458, -7.688222018999969],
              [-34.84624567, -7.688407235999929],
              [-34.844573684999901, -7.68896948899992],
              [-34.844098432, -7.689242302999959],
              [-34.843641219999903, -7.689649061999943],
              [-34.843213766, -7.690206602999982],
              [-34.842289791, -7.691911061999961],
              [-34.841720005, -7.692597823999955],
              [-34.841108957, -7.693627123999918],
              [-34.8404480579999, -7.694426909999945],
              [-34.839501068999901, -7.695062340999924],
              [-34.838589445, -7.695470238999968],
              [-34.838554541999898, -7.695485855999947],
              [-34.838497025, -7.695500622999927],
              [-34.837848193, -7.695667206999929],
              [-34.837259509, -7.695767737999962],
              [-34.836803104999902, -7.696031506999926],
              [-34.836324773999898, -7.696393357999976],
              [-34.835949494, -7.696531894999951],
              [-34.835482082999903, -7.696482119999928],
              [-34.835145011, -7.696580082999954],
              [-34.834406720999901, -7.697159639999932],
              [-34.833594631, -7.697985251999979],
              [-34.832391591, -7.699035073999949],
              [-34.832353220999899, -7.699084293999933],
              [-34.831812925999898, -7.699777368999949],
              [-34.831611143, -7.700474945999932],
              [-34.831683159, -7.701140036999963],
              [-34.831751821, -7.701857387999946],
              [-34.831984186999897, -7.702776542999968],
              [-34.832133145999897, -7.703361884999937],
              [-34.832272195, -7.703917963999962],
              [-34.832401333, -7.704444780999944],
              [-34.832570053, -7.705059364999955],
              [-34.832679127999903, -7.70541052999994],
              [-34.832760490999902, -7.705756673999923],
              [-34.83287750599991, -7.706254493999948],
              [-34.833095, -7.707986049999934],
              [-34.8331180019999, -7.708418815999951],
              [-34.833165365999903, -7.709309898999947],
              [-34.8331486189999, -7.710507346999976],
              [-34.833145159, -7.710613428999972],
              [-34.8331065409999, -7.711797662999961],
              [-34.833003693, -7.712986787999963],
              [-34.832973368, -7.713499654999955],
              [-34.832438979, -7.715072180999927],
              [-34.832395993, -7.715227786999947],
              [-34.832290755, -7.715608748999949],
              [-34.832170974999897, -7.716087879999977],
              [-34.831917018999903, -7.716837443999956],
              [-34.831758213999898, -7.71750450899998],
              [-34.831464809, -7.718241607999962],
              [-34.831258686999902, -7.719006844999972],
              [-34.831036594999901, -7.719664332999971],
              [-34.830990839, -7.719799792999931],
              [-34.83080854, -7.720381006999958],
              [-34.830775424, -7.720569628999942],
              [-34.830701754, -7.720989228999946],
              [-34.830641877999902, -7.721993164999953],
              [-34.830709054, -7.722629887999976],
              [-34.830719206, -7.722726109999943],
              [-34.830747744999897, -7.722996612999961],
              [-34.830857852, -7.723867091999977],
              [-34.830726442, -7.724526404999953],
              [-34.829912697999902, -7.726057563999972],
              [-34.829240812, -7.725600396999931],
              [-34.828488885, -7.725088766999935],
              [-34.828317722999898, -7.725056947999966],
              [-34.828121697, -7.725020506999952],
              [-34.827908998999902, -7.724980967999945],
              [-34.827432938, -7.724778277999963],
              [-34.827340745, -7.72481109899996],
              [-34.826788232999903, -7.725007794999951],
              [-34.826470123, -7.725178212999933],
              [-34.826286715, -7.725276468999942],
              [-34.825735333, -7.72552732899993],
              [-34.825726126999903, -7.725718374999929],
              [-34.825664329999903, -7.725888807999981],
              [-34.825663687, -7.725894349999974],
              [-34.825525195999901, -7.727087613999962],
              [-34.825475302, -7.727517513999932],
              [-34.825305409999899, -7.728969712999963],
              [-34.82519683, -7.729439415999937],
              [-34.824982011, -7.730205544999926],
              [-34.824913991, -7.730642856999953],
              [-34.824817061, -7.731460470999933],
              [-34.824799578999901, -7.731521263999983],
              [-34.824610456, -7.732178982999936],
              [-34.824474407, -7.732706556999975],
              [-34.824299626, -7.733373330999944],
              [-34.824103141, -7.734213639999959],
              [-34.823782983999898, -7.734803533999983],
              [-34.823694429999897, -7.734960310999951],
              [-34.823535053999898, -7.735242472999975],
              [-34.823500796, -7.735579719999973],
              [-34.82354864, -7.735829933999976],
              [-34.823578884, -7.736196149999969],
              [-34.823605248, -7.736446339999926],
              [-34.823339642, -7.737574892999937],
              [-34.823233049999899, -7.739501899999937],
              [-34.823221144999899, -7.741083885999958],
              [-34.823201003, -7.742702146999925],
              [-34.823122994999899, -7.743375234999967],
              [-34.823072575, -7.743810283999954],
              [-34.823075549999899, -7.743834366999973],
              [-34.82316242, -7.744537647999945],
              [-34.823177401, -7.745992908999939],
              [-34.823167994, -7.746708575999945],
              [-34.823162751, -7.74710753799997],
              [-34.823210074, -7.748150830999986],
              [-34.823276058, -7.749288033999964],
              [-34.823279288, -7.749802725999927],
              [-34.823283423911398, -7.750461434175618],
              [-34.89904517870626, -7.750461434175618],
              [-34.89904517870626, -7.650461434175618],
              [-34.822687460051377, -7.650461434175618],
              [-34.822792873, -7.650708386999951],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '844',
        ID: 43285.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -7.6005,
        ANNUAL: 5794.0,
        JAN: 5751.0,
        FEB: 5570.0,
        MAR: 5652.0,
        APR: 5318.0,
        MAY: 5055.0,
        JUN: 5000.0,
        JUL: 5345.0,
        AUG: 6115.0,
        SEP: 6527.0,
        OCT: 6524.0,
        NOV: 6483.0,
        DEC: 6191.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.622645411999898, -7.647469354999931],
              [-40.623197153, -7.644856493999946],
              [-40.624031316999897, -7.640906171999974],
              [-40.624820148, -7.637672373999984],
              [-40.626561121999899, -7.631683567999975],
              [-40.626887533999898, -7.630873714999955],
              [-40.632104130999899, -7.617930953999965],
              [-40.636734998999898, -7.608517187999941],
              [-40.644376475999898, -7.59664278299994],
              [-40.645581503, -7.594769895999949],
              [-40.649118153, -7.59076423399995],
              [-40.651213887, -7.58855398099996],
              [-40.653826233, -7.586203188999951],
              [-40.653883569, -7.586155330999931],
              [-40.653921274, -7.586121453999967],
              [-40.654033668, -7.586027691999963],
              [-40.654073314, -7.58599461799998],
              [-40.659086347, -7.581812595999967],
              [-40.662657446, -7.579499928999946],
              [-40.665628618, -7.576227789999963],
              [-40.667603352999897, -7.573984344999929],
              [-40.667812077999898, -7.573747216999951],
              [-40.66786259, -7.573689831999952],
              [-40.66791896, -7.573625790999936],
              [-40.677320847, -7.562943215999952],
              [-40.6813362, -7.557738265999935],
              [-40.686212225326379, -7.550461434175618],
              [-40.599045178706341, -7.550461434175618],
              [-40.599045178706341, -7.650461434175618],
              [-40.622141146249326, -7.650461434175618],
              [-40.622645411999898, -7.647469354999931],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '845',
        ID: 43286.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -7.6005,
        ANNUAL: 5669.0,
        JAN: 5740.0,
        FEB: 5446.0,
        MAR: 5554.0,
        APR: 5129.0,
        MAY: 4861.0,
        JUN: 4755.0,
        JUL: 5120.0,
        AUG: 5988.0,
        SEP: 6421.0,
        OCT: 6437.0,
        NOV: 6428.0,
        DEC: 6153.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -7.650461434175618],
              [-40.599045178706341, -7.550461434175618],
              [-40.499045178706339, -7.550461434175618],
              [-40.499045178706339, -7.650461434175618],
              [-40.599045178706341, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '846',
        ID: 43287.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -7.6005,
        ANNUAL: 5636.0,
        JAN: 5701.0,
        FEB: 5442.0,
        MAR: 5545.0,
        APR: 5141.0,
        MAY: 4840.0,
        JUN: 4659.0,
        JUL: 5048.0,
        AUG: 5919.0,
        SEP: 6435.0,
        OCT: 6439.0,
        NOV: 6400.0,
        DEC: 6064.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -7.650461434175618],
              [-40.499045178706339, -7.550461434175618],
              [-40.399045178706338, -7.550461434175618],
              [-40.399045178706338, -7.650461434175618],
              [-40.499045178706339, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '847',
        ID: 43288.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -7.6005,
        ANNUAL: 5607.0,
        JAN: 5670.0,
        FEB: 5423.0,
        MAR: 5498.0,
        APR: 5099.0,
        MAY: 4786.0,
        JUN: 4616.0,
        JUL: 4976.0,
        AUG: 5870.0,
        SEP: 6481.0,
        OCT: 6428.0,
        NOV: 6404.0,
        DEC: 6032.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -7.650461434175618],
              [-40.399045178706338, -7.550461434175618],
              [-40.299045178706336, -7.550461434175618],
              [-40.299045178706336, -7.650461434175618],
              [-40.399045178706338, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '848',
        ID: 43289.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -7.6005,
        ANNUAL: 5593.0,
        JAN: 5708.0,
        FEB: 5557.0,
        MAR: 5560.0,
        APR: 5083.0,
        MAY: 4709.0,
        JUN: 4462.0,
        JUL: 4816.0,
        AUG: 5760.0,
        SEP: 6430.0,
        OCT: 6452.0,
        NOV: 6470.0,
        DEC: 6109.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -7.650461434175618],
              [-40.299045178706336, -7.550461434175618],
              [-40.199045178706335, -7.550461434175618],
              [-40.199045178706335, -7.650461434175618],
              [-40.299045178706336, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '849',
        ID: 43290.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -7.6005,
        ANNUAL: 5589.0,
        JAN: 5745.0,
        FEB: 5580.0,
        MAR: 5617.0,
        APR: 5149.0,
        MAY: 4725.0,
        JUN: 4446.0,
        JUL: 4774.0,
        AUG: 5699.0,
        SEP: 6380.0,
        OCT: 6385.0,
        NOV: 6439.0,
        DEC: 6127.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -7.650461434175618],
              [-40.199045178706335, -7.550461434175618],
              [-40.099045178706334, -7.550461434175618],
              [-40.099045178706334, -7.650461434175618],
              [-40.199045178706335, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '850',
        ID: 43291.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -7.6005,
        ANNUAL: 5587.0,
        JAN: 5690.0,
        FEB: 5529.0,
        MAR: 5572.0,
        APR: 5187.0,
        MAY: 4742.0,
        JUN: 4495.0,
        JUL: 4815.0,
        AUG: 5710.0,
        SEP: 6405.0,
        OCT: 6432.0,
        NOV: 6373.0,
        DEC: 6092.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -7.650461434175618],
              [-40.099045178706334, -7.550461434175618],
              [-39.999045178706332, -7.550461434175618],
              [-39.999045178706332, -7.650461434175618],
              [-40.099045178706334, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '851',
        ID: 43292.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -7.6005,
        ANNUAL: 5609.0,
        JAN: 5798.0,
        FEB: 5587.0,
        MAR: 5641.0,
        APR: 5130.0,
        MAY: 4678.0,
        JUN: 4471.0,
        JUL: 4777.0,
        AUG: 5699.0,
        SEP: 6441.0,
        OCT: 6491.0,
        NOV: 6470.0,
        DEC: 6128.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -7.650461434175618],
              [-39.999045178706332, -7.550461434175618],
              [-39.899045178706331, -7.550461434175618],
              [-39.899045178706331, -7.650461434175618],
              [-39.999045178706332, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '852',
        ID: 43293.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -7.6005,
        ANNUAL: 5621.0,
        JAN: 5882.0,
        FEB: 5639.0,
        MAR: 5706.0,
        APR: 5183.0,
        MAY: 4671.0,
        JUN: 4420.0,
        JUL: 4738.0,
        AUG: 5686.0,
        SEP: 6404.0,
        OCT: 6485.0,
        NOV: 6486.0,
        DEC: 6157.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -7.650461434175618],
              [-39.899045178706331, -7.550461434175618],
              [-39.799045178706329, -7.550461434175618],
              [-39.799045178706329, -7.650461434175618],
              [-39.899045178706331, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '853',
        ID: 43294.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -7.6005,
        ANNUAL: 5642.0,
        JAN: 5933.0,
        FEB: 5706.0,
        MAR: 5783.0,
        APR: 5286.0,
        MAY: 4711.0,
        JUN: 4369.0,
        JUL: 4706.0,
        AUG: 5643.0,
        SEP: 6420.0,
        OCT: 6495.0,
        NOV: 6463.0,
        DEC: 6183.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -7.650461434175618],
              [-39.799045178706329, -7.550461434175618],
              [-39.699045178706328, -7.550461434175618],
              [-39.699045178706328, -7.650461434175618],
              [-39.799045178706329, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '854',
        ID: 43295.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -7.6005,
        ANNUAL: 5685.0,
        JAN: 5851.0,
        FEB: 5805.0,
        MAR: 5832.0,
        APR: 5384.0,
        MAY: 4806.0,
        JUN: 4458.0,
        JUL: 4739.0,
        AUG: 5613.0,
        SEP: 6446.0,
        OCT: 6521.0,
        NOV: 6501.0,
        DEC: 6264.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -7.650461434175618],
              [-39.699045178706328, -7.550461434175618],
              [-39.599045178706326, -7.550461434175618],
              [-39.599045178706326, -7.650461434175618],
              [-39.699045178706328, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '855',
        ID: 43296.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -7.6005,
        ANNUAL: 5676.0,
        JAN: 5826.0,
        FEB: 5735.0,
        MAR: 5774.0,
        APR: 5375.0,
        MAY: 4789.0,
        JUN: 4473.0,
        JUL: 4759.0,
        AUG: 5639.0,
        SEP: 6445.0,
        OCT: 6531.0,
        NOV: 6535.0,
        DEC: 6229.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.599045178706326, -7.650461434175618],
              [-39.599045178706326, -7.550461434175618],
              [-39.499045178706325, -7.550461434175618],
              [-39.499045178706325, -7.650461434175618],
              [-39.599045178706326, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '856',
        ID: 43297.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -7.6005,
        ANNUAL: 5659.0,
        JAN: 5785.0,
        FEB: 5624.0,
        MAR: 5689.0,
        APR: 5273.0,
        MAY: 4759.0,
        JUN: 4469.0,
        JUL: 4783.0,
        AUG: 5698.0,
        SEP: 6500.0,
        OCT: 6525.0,
        NOV: 6581.0,
        DEC: 6224.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.476526798999899, -7.553327690999942],
              [-39.47652657, -7.553329816999954],
              [-39.475456182, -7.563279293999932],
              [-39.475455124999897, -7.563289116999943],
              [-39.474379426, -7.568925777999943],
              [-39.473455934, -7.572864498999924],
              [-39.473070842, -7.574023501999931],
              [-39.472453647999899, -7.575182010999972],
              [-39.471680838999902, -7.575800613999947],
              [-39.47059845, -7.575848030999967],
              [-39.465804311999896, -7.574617990999974],
              [-39.46016229, -7.576013676999931],
              [-39.450881723, -7.572857648999951],
              [-39.447634207, -7.572243127999969],
              [-39.447600049, -7.572247107999933],
              [-39.440924805, -7.573024803999948],
              [-39.434964760999904, -7.57371917699993],
              [-39.434933472999901, -7.573722843999966],
              [-39.434680784, -7.574012651999954],
              [-39.431184581, -7.578022417999936],
              [-39.428919656999902, -7.580621612999948],
              [-39.428755957, -7.580809472999929],
              [-39.425092962999898, -7.584815305999935],
              [-39.423272819, -7.586805806999953],
              [-39.4228909939999, -7.592211124999955],
              [-39.420659789, -7.603022162999928],
              [-39.420428074, -7.603562523999931],
              [-39.419656147, -7.604412278999979],
              [-39.418573792, -7.604876712999943],
              [-39.416409358, -7.604956112999956],
              [-39.409605724, -7.604885202999986],
              [-39.406474439, -7.605181992999975],
              [-39.402377247999901, -7.605957428999938],
              [-39.399285428, -7.606423734999958],
              [-39.399045178706324, -7.606493830341215],
              [-39.399045178706324, -7.650461434175618],
              [-39.499045178706325, -7.650461434175618],
              [-39.499045178706325, -7.550461434175618],
              [-39.478222036230974, -7.550461434175618],
              [-39.476526798999899, -7.553327690999942],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '857',
        ID: 43298.0,
        COUNTRY: 'Brasil',
        LON: -39.349,
        LAT: -7.6005,
        ANNUAL: 5695.0,
        JAN: 5882.0,
        FEB: 5697.0,
        MAR: 5725.0,
        APR: 5304.0,
        MAY: 4750.0,
        JUN: 4510.0,
        JUL: 4779.0,
        AUG: 5694.0,
        SEP: 6522.0,
        OCT: 6530.0,
        NOV: 6671.0,
        DEC: 6276.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.393178502, -7.608205496999973],
              [-39.391869168, -7.608192440999971],
              [-39.386065602, -7.608134567999973],
              [-39.383974328, -7.608573680999934],
              [-39.382678271, -7.609399005999927],
              [-39.382355996999898, -7.609604271999966],
              [-39.380887838, -7.610995541999963],
              [-39.376094969999897, -7.611772362999942],
              [-39.3726950289999, -7.614167924999942],
              [-39.3700691989999, -7.617799126999964],
              [-39.369589053, -7.619281662999984],
              [-39.369551464, -7.619397772999949],
              [-39.368103097, -7.623869993999961],
              [-39.363128263, -7.625530301999953],
              [-39.359291173, -7.626810896999937],
              [-39.355427792999897, -7.629979526999937],
              [-39.354798743999901, -7.630128035999976],
              [-39.350170524, -7.631218882999978],
              [-39.342844505, -7.635617843999966],
              [-39.341531056, -7.636930419999937],
              [-39.338443708999897, -7.644646895999938],
              [-39.334656591999902, -7.647041748999926],
              [-39.334332635, -7.647183565999978],
              [-39.3323217229999, -7.648063871999971],
              [-39.332086139, -7.648167002999976],
              [-39.332026067, -7.64819329999995],
              [-39.3319923829999, -7.648208045999981],
              [-39.32684649727468, -7.650461434175618],
              [-39.399045178706324, -7.650461434175618],
              [-39.399045178706324, -7.606493830341215],
              [-39.393178502, -7.608205496999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '858',
        ID: 43304.0,
        COUNTRY: 'Brasil',
        LON: -38.749,
        LAT: -7.6005,
        ANNUAL: 5858.0,
        JAN: 6100.0,
        FEB: 6045.0,
        MAR: 5945.0,
        APR: 5569.0,
        MAY: 5059.0,
        JUN: 4739.0,
        JUL: 5023.0,
        AUG: 5801.0,
        SEP: 6563.0,
        OCT: 6519.0,
        NOV: 6664.0,
        DEC: 6273.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.741822156, -7.648373292999956],
              [-38.738664328, -7.644184150999936],
              [-38.73857793299991, -7.643458116999978],
              [-38.738407300999903, -7.642024192999944],
              [-38.736602988999898, -7.640966988999925],
              [-38.736490239, -7.640900924999937],
              [-38.735414838999901, -7.640270812999976],
              [-38.734965569999908, -7.639457421999952],
              [-38.735632871, -7.63751524399992],
              [-38.737272896, -7.636281058999931],
              [-38.737215786, -7.634932120999962],
              [-38.736551415999898, -7.634197932999946],
              [-38.736078846999902, -7.634161871999944],
              [-38.7355982199999, -7.63412519699995],
              [-38.734024127999902, -7.634005274999939],
              [-38.733879536, -7.633994258999962],
              [-38.732189671999897, -7.633865516999942],
              [-38.728399484, -7.630729322999967],
              [-38.725365885, -7.629012313999975],
              [-38.723726500999902, -7.626884001999944],
              [-38.720883887, -7.626586874999927],
              [-38.717777828, -7.626871197999947],
              [-38.717594253999899, -7.626887998999968],
              [-38.716600501, -7.625110414999946],
              [-38.715274227999899, -7.622001731999937],
              [-38.715211927, -7.621970036999933],
              [-38.715112948999902, -7.621919510999931],
              [-38.714103099999903, -7.623311249999965],
              [-38.709104466, -7.623003796999968],
              [-38.70896371, -7.622995139999944],
              [-38.706327146, -7.621926317999953],
              [-38.70540719, -7.622043457999953],
              [-38.702101937, -7.625830245999963],
              [-38.70041291, -7.626740892999987],
              [-38.699045178706314, -7.629414673580489],
              [-38.699045178706314, -7.650461434175618],
              [-38.742248687768416, -7.650461434175618],
              [-38.741822156, -7.648373292999956],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '859',
        ID: 43305.0,
        COUNTRY: 'Brasil',
        LON: -38.649,
        LAT: -7.6005,
        ANNUAL: 5905.0,
        JAN: 6186.0,
        FEB: 6119.0,
        MAR: 5989.0,
        APR: 5628.0,
        MAY: 5096.0,
        JUN: 4724.0,
        JUL: 5002.0,
        AUG: 5806.0,
        SEP: 6585.0,
        OCT: 6571.0,
        NOV: 6766.0,
        DEC: 6381.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.698548194999901, -7.630386227999963],
              [-38.695993563999899, -7.632802732999953],
              [-38.695047859999903, -7.633296497999945],
              [-38.690655805999903, -7.632900669999976],
              [-38.690133572, -7.634814165999963],
              [-38.686741605, -7.635040255999968],
              [-38.683418397, -7.642765718999957],
              [-38.682720946, -7.643562401999925],
              [-38.680075628999901, -7.645299013999936],
              [-38.678973121999903, -7.646558045999934],
              [-38.678300758, -7.647560917999942],
              [-38.67693512985111, -7.650461434175618],
              [-38.699045178706314, -7.650461434175618],
              [-38.699045178706314, -7.629414673580489],
              [-38.698548194999901, -7.630386227999963],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '860',
        ID: 43313.0,
        COUNTRY: 'Brasil',
        LON: -37.849,
        LAT: -7.6005,
        ANNUAL: 5882.0,
        JAN: 6129.0,
        FEB: 6142.0,
        MAR: 6120.0,
        APR: 5778.0,
        MAY: 5063.0,
        JUN: 4731.0,
        JUL: 4992.0,
        AUG: 5765.0,
        SEP: 6427.0,
        OCT: 6506.0,
        NOV: 6646.0,
        DEC: 6278.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.845746506, -7.647837402999927],
              [-37.843909104, -7.647172229999968],
              [-37.841999054, -7.647956847999985],
              [-37.841991424999897, -7.647961615999979],
              [-37.841884612999898, -7.647963523999971],
              [-37.841705321999903, -7.647901534999956],
              [-37.841472626, -7.647759913999947],
              [-37.841304779, -7.647651671999935],
              [-37.841117859, -7.647550105999922],
              [-37.840938568, -7.647426604999965],
              [-37.840782165999897, -7.647274970999935],
              [-37.840625762999899, -7.647116660999928],
              [-37.840465546, -7.646951674999965],
              [-37.840293883999898, -7.646783351999941],
              [-37.840122223, -7.646616458999968],
              [-37.839962006, -7.646434783999944],
              [-37.839828490999899, -7.646240233999945],
              [-37.839694977, -7.646051882999924],
              [-37.839553833, -7.645883559999956],
              [-37.839416504, -7.645728110999922],
              [-37.83928299, -7.645578383999985],
              [-37.839130402, -7.64542484299994],
              [-37.838951111, -7.645261763999941],
              [-37.838756561, -7.645090102999965],
              [-37.838554382, -7.644936560999957],
              [-37.838336945, -7.644801615999939],
              [-37.838134765999897, -7.644670008999974],
              [-37.837871552, -7.644503115999946],
              [-37.83763504, -7.644454955999949],
              [-37.837390899999903, -7.644450186999967],
              [-37.837158202999902, -7.644456862999956],
              [-37.836921692, -7.644476889999961],
              [-37.836696624999902, -7.644501685999958],
              [-37.836486815999898, -7.644523143999942],
              [-37.836277008, -7.644543170999953],
              [-37.836254612999902, -7.644545052999939],
              [-37.836055756, -7.644561766999978],
              [-37.835826874, -7.64456987399995],
              [-37.835613251, -7.644546508999954],
              [-37.8354110719999, -7.64449500999996],
              [-37.835220337, -7.644446849999946],
              [-37.8350257869999, -7.644414900999945],
              [-37.834846496999901, -7.644420146999948],
              [-37.834712982, -7.644453524999957],
              [-37.834617615, -7.644491671999956],
              [-37.834579468, -7.644503115999944],
              [-37.834575652999902, -7.644505023999947],
              [-37.834526062, -7.644535063999966],
              [-37.834434508999898, -7.644601821999967],
              [-37.834377109999899, -7.644645740999972],
              [-37.834308624, -7.644698142999976],
              [-37.834182739, -7.644836901999955],
              [-37.834068298, -7.644991873999976],
              [-37.833965302, -7.645148276999981],
              [-37.8338699339999, -7.645306586999947],
              [-37.833778380999902, -7.645458220999967],
              [-37.8336753849999, -7.645606517999977],
              [-37.833572387999901, -7.645748137999941],
              [-37.833461761, -7.645881652999956],
              [-37.833357428999904, -7.645985984999985],
              [-37.833343505999899, -7.645999907999953],
              [-37.833229065, -7.646101474999966],
              [-37.8331222529999, -7.646180152999923],
              [-37.833023071, -7.646261691999938],
              [-37.832923889, -7.646351813999961],
              [-37.832824706999901, -7.646421431999958],
              [-37.8327102659999, -7.64649343499996],
              [-37.832603455, -7.64656686799998],
              [-37.832504271999902, -7.646638392999935],
              [-37.832401276, -7.64671850199993],
              [-37.832229614, -7.646834849999956],
              [-37.832096099999902, -7.646913527999974],
              [-37.831970215, -7.646975039999976],
              [-37.83184433, -7.647025107999975],
              [-37.831729889, -7.647096633999974],
              [-37.831607818999899, -7.647178172999924],
              [-37.8314552309999, -7.647243499999949],
              [-37.831295012999902, -7.647303103999943],
              [-37.831127167, -7.647348403999965],
              [-37.830921173, -7.647383212999951],
              [-37.830669402999902, -7.647416590999977],
              [-37.830410003999901, -7.647461890999974],
              [-37.830286026, -7.647495030999949],
              [-37.830162048, -7.647528170999987],
              [-37.830137783999902, -7.647533004999962],
              [-37.829917908, -7.647576808999931],
              [-37.829741253, -7.647598890999964],
              [-37.829666137999901, -7.647608279999932],
              [-37.829418182, -7.647644995999939],
              [-37.829181671, -7.647675036999938],
              [-37.828945159999897, -7.647693156999932],
              [-37.82869339, -7.647703170999923],
              [-37.82843399, -7.647685050999935],
              [-37.8281784059999, -7.647661685999935],
              [-37.827838898, -7.647598265999922],
              [-37.827610015999902, -7.647523402999973],
              [-37.827384949, -7.647441863999976],
              [-37.827171326, -7.647349833999946],
              [-37.826961517, -7.647246836999942],
              [-37.826755524, -7.647133349999931],
              [-37.826541901, -7.647028445999982],
              [-37.826324462999899, -7.646924971999962],
              [-37.826095580999898, -7.646808146999934],
              [-37.82585144, -7.646701811999946],
              [-37.825614929, -7.646601676999984],
              [-37.825393677, -7.646501540999965],
              [-37.825191498, -7.646386622999968],
              [-37.825008392, -7.646263121999939],
              [-37.824836730999898, -7.646136759999925],
              [-37.824649811, -7.646009921999953],
              [-37.824447631999902, -7.645876883999954],
              [-37.824226379, -7.645756720999945],
              [-37.823974608999897, -7.645673274999973],
              [-37.823692321999907, -7.645645140999932],
              [-37.82339859, -7.645646571999984],
              [-37.823032379, -7.645633219999972],
              [-37.8227767939999, -7.645613192999971],
              [-37.822517395, -7.645598410999972],
              [-37.822261809999901, -7.645591735999962],
              [-37.82201004, -7.645586489999964],
              [-37.821773529, -7.645608424999978],
              [-37.821540833, -7.645643233999972],
              [-37.821308136, -7.645693301999926],
              [-37.821090698, -7.645786761999941],
              [-37.820884705, -7.645899771999948],
              [-37.820682526, -7.645995139999976],
              [-37.820503234999897, -7.646090029999939],
              [-37.820320129, -7.64617490699992],
              [-37.820133208999906, -7.64625501599994],
              [-37.819953918, -7.646351813999959],
              [-37.819770812999899, -7.646448134999953],
              [-37.819606780999898, -7.646573542999934],
              [-37.819454193, -7.646715163999946],
              [-37.819286346, -7.646828173999957],
              [-37.819107056, -7.646853446999961],
              [-37.818916321, -7.646753310999943],
              [-37.8187713619999, -7.646661757999933],
              [-37.8186264039999, -7.646563529999985],
              [-37.818504333, -7.646484851999968],
              [-37.818389893, -7.646396636999957],
              [-37.818229674999898, -7.646275042999961],
              [-37.818054199, -7.646154879999936],
              [-37.817878723, -7.646039962999952],
              [-37.817718505999899, -7.645938395999953],
              [-37.817546843999899, -7.645854949999942],
              [-37.817371368, -7.645781516999933],
              [-37.817184448, -7.645711898999923],
              [-37.816997527999902, -7.645631789999915],
              [-37.816825867, -7.645536898999986],
              [-37.81671524, -7.645394801999942],
              [-37.816635131999902, -7.645221709999936],
              [-37.81652832, -7.645034789999946],
              [-37.816417693999902, -7.644855021999946],
              [-37.816326140999898, -7.64468812899997],
              [-37.816249846999902, -7.644555091999961],
              [-37.816143036, -7.644424914999955],
              [-37.815979004, -7.644278525999944],
              [-37.815868377999898, -7.644196509999969],
              [-37.815719604, -7.644114970999956],
              [-37.815540314, -7.644023417999953],
              [-37.815341949, -7.643919944999946],
              [-37.8151321409999, -7.643826483999926],
              [-37.814907074, -7.643750189999923],
              [-37.814647674999897, -7.643684863999964],
              [-37.81438446, -7.643630027999955],
              [-37.814117431999897, -7.643573283999959],
              [-37.813846587999898, -7.643538474999954],
              [-37.813575745, -7.643515109999956],
              [-37.813304901, -7.64347314799994],
              [-37.813034058, -7.643439768999943],
              [-37.812759399, -7.643414973999939],
              [-37.8125, -7.643431662999938],
              [-37.812255858999897, -7.643549918999942],
              [-37.812015533, -7.643718241999975],
              [-37.811782837, -7.64388513499995],
              [-37.81155777, -7.644011496999953],
              [-37.811328887999899, -7.64405822699995],
              [-37.811092377, -7.64407825399996],
              [-37.810855865, -7.644076823999974],
              [-37.810604095, -7.644034861999963],
              [-37.810268402, -7.64397144299994],
              [-37.81003952, -7.643891810999945],
              [-37.809841155999898, -7.643754958999978],
              [-37.809677124, -7.64359521799997],
              [-37.809547424, -7.643481730999955],
              [-37.809398651, -7.643343447999936],
              [-37.809268951, -7.643151759999967],
              [-37.809173584, -7.642923354999937],
              [-37.809066771999902, -7.642684935999977],
              [-37.808963776, -7.642458438999932],
              [-37.808868408, -7.642250060999966],
              [-37.808757781999908, -7.642055033999943],
              [-37.8086433409999, -7.641863345999978],
              [-37.808567046999897, -7.641681670999967],
              [-37.808528899999899, -7.641523360999944],
              [-37.808448792, -7.641413211999963],
              [-37.808391571, -7.641274928999954],
              [-37.808311461999899, -7.641111849999969],
              [-37.808238983, -7.640924929999928],
              [-37.808170318999899, -7.640763282999953],
              [-37.808094024999903, -7.640653132999939],
              [-37.808094024999903, -7.640655039999954],
              [-37.8080635069999, -7.640594958999933],
              [-37.807960509999901, -7.640418528999931],
              [-37.807872771999897, -7.640306472999942],
              [-37.80777359, -7.640193461999957],
              [-37.807666778999902, -7.640059947999929],
              [-37.807611465, -7.639984130999955],
              [-37.807556152, -7.63990831399997],
              [-37.807445526, -7.639739989999957],
              [-37.807331084999909, -7.63956499099993],
              [-37.807189940999898, -7.639358519999969],
              [-37.807041168, -7.639140128999939],
              [-37.806900024, -7.638926505999964],
              [-37.806777954, -7.638716696999947],
              [-37.806663512999897, -7.638518332999976],
              [-37.806545258, -7.638344763999952],
              [-37.806427002, -7.638175009999946],
              [-37.8063316349999, -7.638004779999956],
              [-37.806247711, -7.63787174199996],
              [-37.806179046999901, -7.637793540999946],
              [-37.806121826, -7.637718199999934],
              [-37.806049346999899, -7.637629985999984],
              [-37.805973052999903, -7.637549876999969],
              [-37.805923462, -7.637505053999977],
              [-37.805828093999899, -7.637424945999954],
              [-37.805706024, -7.637328147999936],
              [-37.805580139, -7.637229918999941],
              [-37.805465698, -7.637136458999983],
              [-37.805347443, -7.637056826999977],
              [-37.805217743, -7.636973380999961],
              [-37.805076599, -7.636888503999964],
              [-37.804916381999902, -7.636789797999941],
              [-37.804759979, -7.636673449999922],
              [-37.804595946999903, -7.636553286999975],
              [-37.804443358999897, -7.636436461999957],
              [-37.804298401, -7.636303424999934],
              [-37.804180145, -7.636129855999972],
              [-37.80406189, -7.635916709999946],
              [-37.80393219, -7.635703562999931],
              [-37.803817749, -7.635515212999958],
              [-37.803722381999897, -7.635358332999932],
              [-37.803600311, -7.635210036999974],
              [-37.803474426, -7.635019778999947],
              [-37.803401946999898, -7.63471507999997],
              [-37.803390502999903, -7.63446521699997],
              [-37.803421021, -7.634218215999977],
              [-37.803462982, -7.633983134999931],
              [-37.803504943999897, -7.633761882999982],
              [-37.803558349999903, -7.633513449999957],
              [-37.803607940999903, -7.633251666999972],
              [-37.803665161, -7.632993220999936],
              [-37.803707123, -7.632744788999966],
              [-37.803752899, -7.632533549999957],
              [-37.803821564, -7.632319926999971],
              [-37.803924561, -7.632120131999955],
              [-37.804027557, -7.631959914999914],
              [-37.804107665999901, -7.631806849999982],
              [-37.804191589, -7.631631850999955],
              [-37.804260254, -7.631476878999924],
              [-37.804340362999902, -7.631299971999964],
              [-37.804416655999901, -7.631118296999944],
              [-37.804405211999899, -7.630958556999926],
              [-37.804340362999902, -7.630831717999971],
              [-37.804298401, -7.630772589999948],
              [-37.804256439, -7.630713462999949],
              [-37.804149627999898, -7.630595206999943],
              [-37.804031371999898, -7.630473136999967],
              [-37.803962708, -7.630416869999975],
              [-37.803901671999903, -7.630361556999968],
              [-37.80381012, -7.630278109999955],
              [-37.803718567, -7.630169867999947],
              [-37.803623199, -7.630060195999934],
              [-37.803512573, -7.629938124999962],
              [-37.803386687999897, -7.629826544999952],
              [-37.803279877, -7.629743098999932],
              [-37.803207396999902, -7.629670142999935],
              [-37.803134918, -7.629590033999937],
              [-37.803062439, -7.629495143999971],
              [-37.802986145, -7.629381655999955],
              [-37.802944183, -7.629244803999942],
              [-37.802944183, -7.629078387999927],
              [-37.802894592, -7.628878115999956],
              [-37.802822112999898, -7.628706454999934],
              [-37.802768707, -7.628568171999976],
              [-37.802726746, -7.628431796999964],
              [-37.80267334, -7.628260134999942],
              [-37.802600861, -7.628053187999977],
              [-37.802524567, -7.627836703999941],
              [-37.802452086999899, -7.627616881999926],
              [-37.802379608, -7.627361773999952],
              [-37.802307129, -7.627196788999971],
              [-37.802253723, -7.627061843999969],
              [-37.802188873, -7.626961707999949],
              [-37.802101134999909, -7.626861571999933],
              [-37.801975249999899, -7.626741885999932],
              [-37.80185318, -7.626621722999969],
              [-37.801750183, -7.626504897999956],
              [-37.801635742, -7.626389979999932],
              [-37.801528931, -7.626305102999944],
              [-37.801425934, -7.626231669999981],
              [-37.801353455, -7.626186846999931],
              [-37.8012428279999, -7.626121520999955],
              [-37.801063537999902, -7.626051902999964],
              [-37.800865173, -7.626048564999967],
              [-37.800655365, -7.626073359999955],
              [-37.800495148, -7.62613153399997],
              [-37.800361633, -7.626208304999969],
              [-37.8002052309999, -7.626249789999974],
              [-37.800052643, -7.626266478999982],
              [-37.799915314, -7.626291751999935],
              [-37.799755096, -7.626329898999925],
              [-37.799468993999902, -7.626358508999937],
              [-37.799266815, -7.626383303999932],
              [-37.799049377, -7.626410006999953],
              [-37.799045178706301, -7.626410875919156],
              [-37.799045178706301, -7.650461434175618],
              [-37.852994844806446, -7.650461434175618],
              [-37.845746506, -7.647837402999927],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '861',
        ID: 43314.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -7.6005,
        ANNUAL: 5908.0,
        JAN: 6166.0,
        FEB: 6147.0,
        MAR: 6116.0,
        APR: 5814.0,
        MAY: 5081.0,
        JUN: 4726.0,
        JUL: 4928.0,
        AUG: 5832.0,
        SEP: 6469.0,
        OCT: 6560.0,
        NOV: 6702.0,
        DEC: 6349.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.798816680999899, -7.626458167999933],
              [-37.79858017, -7.626500128999954],
              [-37.798332214, -7.626498221999951],
              [-37.798091887999902, -7.626483439999962],
              [-37.797851562999902, -7.626464843999949],
              [-37.797615051, -7.626473426999941],
              [-37.797389983999899, -7.626516818999958],
              [-37.797176361, -7.626568316999956],
              [-37.796966552999898, -7.626636504999967],
              [-37.79675293, -7.626706599999975],
              [-37.796546936, -7.626764773999985],
              [-37.796333312999899, -7.626843451999947],
              [-37.796100615999897, -7.626943110999944],
              [-37.79586792, -7.627063273999966],
              [-37.795650482, -7.627206801999924],
              [-37.795452118, -7.627343177999934],
              [-37.795351028, -7.627379893999934],
              [-37.795249939, -7.627416609999943],
              [-37.795043945, -7.627388476999955],
              [-37.794769287, -7.627285002999938],
              [-37.794563293, -7.627178191999975],
              [-37.794384002999898, -7.627045153999976],
              [-37.794235229, -7.626911639999949],
              [-37.794067383, -7.626780032999927],
              [-37.793876648, -7.626666545999972],
              [-37.793674468999903, -7.626564978999975],
              [-37.793487549, -7.626456736999963],
              [-37.793312073, -7.626329898999933],
              [-37.793132781999901, -7.626181601999971],
              [-37.792922974, -7.626060008999957],
              [-37.792690277, -7.625976561999948],
              [-37.792446136, -7.625915049999947],
              [-37.792201996, -7.625843524999947],
              [-37.791965484999899, -7.625763415999927],
              [-37.791728973, -7.625685214999983],
              [-37.791496277, -7.625606536999949],
              [-37.79126358, -7.625546454999943],
              [-37.791038512999897, -7.625529765999945],
              [-37.790817261, -7.62555503799995],
              [-37.790596008, -7.625591754999954],
              [-37.790363312, -7.62563180899997],
              [-37.790119171, -7.625686644999973],
              [-37.789787292, -7.625743388999975],
              [-37.789527893, -7.625768183999985],
              [-37.789287567, -7.625781535999978],
              [-37.789066315, -7.625791548999926],
              [-37.788879395, -7.625800132999938],
              [-37.788734436, -7.625803469999947],
              [-37.788547515999902, -7.625798224999933],
              [-37.788314818999901, -7.625801562999924],
              [-37.788108826, -7.625798224999931],
              [-37.787879943999897, -7.625786780999923],
              [-37.787624358999899, -7.625781535999986],
              [-37.787364959999898, -7.625781535999984],
              [-37.78711319, -7.625783442999924],
              [-37.786869049, -7.625786780999923],
              [-37.7866325379999, -7.625790118999934],
              [-37.7864112849999, -7.625790118999932],
              [-37.786212921, -7.625790118999928],
              [-37.786026001, -7.625790118999927],
              [-37.785827637, -7.625798224999937],
              [-37.785598755, -7.625833510999936],
              [-37.785369873, -7.625893115999929],
              [-37.785140990999906, -7.625946521999946],
              [-37.784954071, -7.626021861999953],
              [-37.784702301, -7.626093386999976],
              [-37.784473418999902, -7.626190184999927],
              [-37.784259796, -7.626325129999938],
              [-37.784061431999902, -7.626454829999934],
              [-37.783855437999897, -7.626581668999951],
              [-37.783660889, -7.626701831999978],
              [-37.7834701539999, -7.62680816599993],
              [-37.783283234, -7.626923560999963],
              [-37.7830886839999, -7.627036570999955],
              [-37.78288269, -7.627161502999964],
              [-37.782684326, -7.627281665999932],
              [-37.782543879999899, -7.627381656999937],
              [-37.7825088499999, -7.62740659699995],
              [-37.782329558999898, -7.627544879999961],
              [-37.782232285, -7.627619981999938],
              [-37.782135009999898, -7.627695082999947],
              [-37.781921387, -7.627860068999942],
              [-37.781719208, -7.62801647199995],
              [-37.781555176, -7.628196715999938],
              [-37.781410217, -7.628413199999954],
              [-37.781280518, -7.628641604999933],
              [-37.781150818, -7.628863333999964],
              [-37.780986786, -7.629059790999959],
              [-37.780841827, -7.629271506999938],
              [-37.780693054, -7.629584788999984],
              [-37.780643462999898, -7.629851817999957],
              [-37.780597687, -7.630129813999928],
              [-37.780548096, -7.630406855999981],
              [-37.780490874999899, -7.630666732999942],
              [-37.780422211, -7.630908488999973],
              [-37.780330658, -7.631134986999932],
              [-37.780231476, -7.631353377999978],
              [-37.780136108, -7.631573199999932],
              [-37.780082702999898, -7.631804942999957],
              [-37.78004837, -7.632044791999928],
              [-37.779979705999899, -7.632275103999963],
              [-37.779891968, -7.632501601999929],
              [-37.779838562, -7.632736682999968],
              [-37.779769896999902, -7.632951735999936],
              [-37.779651642, -7.633153437999961],
              [-37.779502868999899, -7.633349894999979],
              [-37.779388427999898, -7.633556842999943],
              [-37.779296874999901, -7.633775233999931],
              [-37.779163361, -7.633971690999947],
              [-37.778991699, -7.634138106999962],
              [-37.778736115, -7.634338378999922],
              [-37.778537749999906, -7.634479998999948],
              [-37.778343201, -7.634620188999964],
              [-37.778171539, -7.63477182399993],
              [-37.778064727999897, -7.634968280999937],
              [-37.778026580999899, -7.635183333999979],
              [-37.778045654, -7.635383128999931],
              [-37.778072357, -7.635581492999957],
              [-37.778068542, -7.635799884999933],
              [-37.77804184, -7.636014937999961],
              [-37.778015137, -7.636225222999939],
              [-37.777999877999903, -7.63644313799995],
              [-37.778015137, -7.636650084999934],
              [-37.778053284, -7.636848448999958],
              [-37.778091431, -7.637046813999974],
              [-37.778125762999899, -7.637266635999942],
              [-37.778186798, -7.637493132999957],
              [-37.778289795, -7.637704848999935],
              [-37.7783851619999, -7.637899874999954],
              [-37.778438568, -7.638090132999924],
              [-37.778465271, -7.638278483999954],
              [-37.778465271, -7.638464926999978],
              [-37.778469086, -7.638643264999934],
              [-37.77848053, -7.638921736999979],
              [-37.778484343999907, -7.639074801999928],
              [-37.778446198, -7.639355181999965],
              [-37.778404236, -7.639571665999941],
              [-37.778369903999902, -7.639800070999969],
              [-37.778327942, -7.640038489999944],
              [-37.778293609999899, -7.640289782999964],
              [-37.778266906999903, -7.640548228999927],
              [-37.778263092, -7.640796660999968],
              [-37.778263092, -7.641039847999926],
              [-37.778263092, -7.641271590999967],
              [-37.778270721, -7.64148998199993],
              [-37.778293609999899, -7.641699790999957],
              [-37.778335571, -7.641918181999931],
              [-37.778358458999897, -7.642148493999963],
              [-37.778316498, -7.642373561999926],
              [-37.778240204, -7.642596720999969],
              [-37.778156281, -7.642823218999979],
              [-37.778091431, -7.64305162399995],
              [-37.7780227659999, -7.643266677999965],
              [-37.777900696, -7.643438338999944],
              [-37.77772522, -7.643563269999951],
              [-37.77747344999991, -7.643691539999963],
              [-37.777305602999903, -7.643768309999934],
              [-37.777133942, -7.643843173999922],
              [-37.776931762999901, -7.643961428999943],
              [-37.776760101, -7.644114970999961],
              [-37.776596068999901, -7.644305228999934],
              [-37.776412964, -7.644503115999952],
              [-37.77620697, -7.644690036999983],
              [-37.775993346999897, -7.64484977699992],
              [-37.775753021, -7.644985198999955],
              [-37.775531768999898, -7.645120143999977],
              [-37.775295258, -7.645243168],
              [-37.77507019, -7.645351886999958],
              [-37.774871826, -7.645451544999955],
              [-37.774665833, -7.64553356099998],
              [-37.774459839, -7.645599841999974],
              [-37.774265289, -7.645689963999923],
              [-37.774082184, -7.645796775999933],
              [-37.773891449, -7.645884989999962],
              [-37.773693084999898, -7.645941733999964],
              [-37.77349472, -7.646009921999965],
              [-37.77325058, -7.646114825999968],
              [-37.773071289, -7.64618158299992],
              [-37.772884368999897, -7.646256446999923],
              [-37.7726936339999, -7.646329879999939],
              [-37.772518158, -7.646395205999965],
              [-37.772373199, -7.646471499999953],
              [-37.772228240999901, -7.646538256999965],
              [-37.772106171, -7.646583556999928],
              [-37.771980286, -7.646656512999932],
              [-37.771850586, -7.646738528999949],
              [-37.771709442, -7.646810054999951],
              [-37.771579742, -7.646900176999959],
              [-37.771457671999897, -7.646991728999983],
              [-37.771335602, -7.647069930999978],
              [-37.771224976, -7.647153376999931],
              [-37.771141052, -7.64726352699994],
              [-37.771060943999899, -7.647385119999964],
              [-37.770980834999897, -7.647518157999972],
              [-37.770896912, -7.647663115999927],
              [-37.770820618, -7.647809981999964],
              [-37.770748137999902, -7.647938250999962],
              [-37.770679474, -7.648063182999983],
              [-37.7705955509999, -7.648181437999942],
              [-37.770519256999897, -7.64832687399996],
              [-37.770458221, -7.648448466999977],
              [-37.770381927, -7.64857149099993],
              [-37.770313262999906, -7.648694991999943],
              [-37.77022934, -7.648828505999976],
              [-37.770137787, -7.648964881999964],
              [-37.770046233999899, -7.64911174699995],
              [-37.769950867, -7.649274825999951],
              [-37.769859314, -7.649428366999934],
              [-37.76978302, -7.649563311999952],
              [-37.769733429, -7.649680136999952],
              [-37.769680023, -7.649761676999959],
              [-37.769645690999901, -7.649816512999957],
              [-37.7695808409999, -7.649928569999937],
              [-37.769493102999903, -7.65008163399995],
              [-37.7693634029999, -7.650226592999961],
              [-37.76921463, -7.650386809999929],
              [-37.769141153799659, -7.650461434175618],
              [-37.799045178706301, -7.650461434175618],
              [-37.799045178706301, -7.626410875919156],
              [-37.798816680999899, -7.626458167999933],
            ],
          ],
          [
            [
              [-37.743404069, -7.645193604999973],
              [-37.743076252, -7.644210154999946],
              [-37.742984878999899, -7.64393603499996],
              [-37.742692265, -7.643058192999948],
              [-37.742033408999902, -7.641081624999977],
              [-37.741942762999898, -7.64080968599996],
              [-37.740667403, -7.636983604999952],
              [-37.740367959, -7.636085273999973],
              [-37.741598815, -7.632638878999967],
              [-37.746382036999897, -7.628306226999945],
              [-37.750696887999901, -7.627279528999956],
              [-37.753322712999903, -7.625802502999933],
              [-37.754881796999904, -7.623504905999937],
              [-37.755567527, -7.621167690999921],
              [-37.755847747, -7.619521832999965],
              [-37.745705577, -7.609331068999957],
              [-37.744220669999898, -7.604407624999953],
              [-37.74410108, -7.604011104999932],
              [-37.741452979, -7.597532831999951],
              [-37.734949496999903, -7.597214160999945],
              [-37.733179194999899, -7.591957305999926],
              [-37.732047939, -7.587034176999967],
              [-37.730581317, -7.582903921999961],
              [-37.728731947, -7.579730383999951],
              [-37.728465031, -7.579911117999919],
              [-37.721031323, -7.581892881999984],
              [-37.717259516999903, -7.580355510999938],
              [-37.715589741, -7.578591025999968],
              [-37.711011352999897, -7.573752789999968],
              [-37.710472122999903, -7.573182961999973],
              [-37.707567708999903, -7.571722966999972],
              [-37.706571989999901, -7.569466814999947],
              [-37.70756633, -7.565148875999975],
              [-37.709984235, -7.561465824999972],
              [-37.710088885999902, -7.559525019999974],
              [-37.707538405, -7.557916094999933],
              [-37.708446479, -7.555879731999948],
              [-37.708465788999902, -7.555657656999938],
              [-37.708621634999901, -7.553872738999927],
              [-37.708204251, -7.552954349999947],
              [-37.707856696, -7.552189606999943],
              [-37.706505730999901, -7.55058378099993],
              [-37.705988557, -7.55049122699994],
              [-37.705822069102133, -7.550461434175618],
              [-37.699045178706299, -7.550461434175618],
              [-37.699045178706299, -7.650461434175618],
              [-37.743843661027725, -7.650461434175618],
              [-37.743404069, -7.645193604999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '862',
        ID: 43315.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -7.6005,
        ANNUAL: 5879.0,
        JAN: 6152.0,
        FEB: 6174.0,
        MAR: 6142.0,
        APR: 5796.0,
        MAY: 5016.0,
        JUN: 4653.0,
        JUL: 4827.0,
        AUG: 5707.0,
        SEP: 6449.0,
        OCT: 6583.0,
        NOV: 6723.0,
        DEC: 6329.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.699045178706299, -7.650461434175618],
              [-37.699045178706299, -7.550461434175618],
              [-37.599045178706298, -7.550461434175618],
              [-37.599045178706298, -7.650461434175618],
              [-37.699045178706299, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '863',
        ID: 43316.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -7.6005,
        ANNUAL: 5860.0,
        JAN: 6174.0,
        FEB: 6224.0,
        MAR: 6141.0,
        APR: 5793.0,
        MAY: 5002.0,
        JUN: 4600.0,
        JUL: 4758.0,
        AUG: 5616.0,
        SEP: 6396.0,
        OCT: 6521.0,
        NOV: 6732.0,
        DEC: 6359.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.599045178706298, -7.650461434175618],
              [-37.599045178706298, -7.550461434175618],
              [-37.499045178706297, -7.550461434175618],
              [-37.499045178706297, -7.650461434175618],
              [-37.599045178706298, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '864',
        ID: 43317.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -7.6005,
        ANNUAL: 5831.0,
        JAN: 6215.0,
        FEB: 6180.0,
        MAR: 6060.0,
        APR: 5755.0,
        MAY: 4969.0,
        JUN: 4525.0,
        JUL: 4742.0,
        AUG: 5543.0,
        SEP: 6370.0,
        OCT: 6514.0,
        NOV: 6729.0,
        DEC: 6374.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -7.650461434175618],
              [-37.499045178706297, -7.550461434175618],
              [-37.399045178706295, -7.550461434175618],
              [-37.399045178706295, -7.650461434175618],
              [-37.499045178706297, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '865',
        ID: 43318.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -7.6005,
        ANNUAL: 5805.0,
        JAN: 6239.0,
        FEB: 6060.0,
        MAR: 6079.0,
        APR: 5668.0,
        MAY: 4981.0,
        JUN: 4504.0,
        JUL: 4694.0,
        AUG: 5510.0,
        SEP: 6343.0,
        OCT: 6525.0,
        NOV: 6713.0,
        DEC: 6341.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -7.650461434175618],
              [-37.399045178706295, -7.550461434175618],
              [-37.299045178706294, -7.550461434175618],
              [-37.299045178706294, -7.650461434175618],
              [-37.399045178706295, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '866',
        ID: 43319.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -7.6005,
        ANNUAL: 5799.0,
        JAN: 6215.0,
        FEB: 6080.0,
        MAR: 6080.0,
        APR: 5703.0,
        MAY: 4982.0,
        JUN: 4508.0,
        JUL: 4691.0,
        AUG: 5481.0,
        SEP: 6321.0,
        OCT: 6531.0,
        NOV: 6673.0,
        DEC: 6323.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -7.650461434175618],
              [-37.299045178706294, -7.550461434175618],
              [-37.199045178706292, -7.550461434175618],
              [-37.199045178706292, -7.650461434175618],
              [-37.299045178706294, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '867',
        ID: 43320.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -7.6005,
        ANNUAL: 5803.0,
        JAN: 6136.0,
        FEB: 6122.0,
        MAR: 6100.0,
        APR: 5747.0,
        MAY: 4982.0,
        JUN: 4527.0,
        JUL: 4720.0,
        AUG: 5465.0,
        SEP: 6310.0,
        OCT: 6525.0,
        NOV: 6668.0,
        DEC: 6335.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.134488787999899, -7.551199116999942],
              [-37.134522709999899, -7.551250983999986],
              [-37.137102825999897, -7.55519594299995],
              [-37.14171312, -7.558966889999962],
              [-37.153451412, -7.566047107999952],
              [-37.155769936999903, -7.567879974999928],
              [-37.159326396, -7.570691465999973],
              [-37.163569376999902, -7.574714880999982],
              [-37.173228488999897, -7.587897373999977],
              [-37.177325751999902, -7.594179320999959],
              [-37.178981528, -7.597085768999938],
              [-37.180120432, -7.600307186999943],
              [-37.180522847, -7.601992511999983],
              [-37.180558473, -7.602141713999968],
              [-37.180591732, -7.602281141999977],
              [-37.180630127, -7.602442096999939],
              [-37.183159707999899, -7.613046501999976],
              [-37.184103399, -7.615816433999943],
              [-37.186100501999903, -7.618416461999962],
              [-37.1927529929999, -7.62381985399998],
              [-37.1950213329999, -7.626937901999947],
              [-37.19667291, -7.629581668999951],
              [-37.196690111, -7.629652356999941],
              [-37.197660819, -7.633641568999953],
              [-37.197946187999896, -7.635297899999966],
              [-37.197321831, -7.645435155999966],
              [-37.196684951999899, -7.647489730999952],
              [-37.196048074, -7.649544304999951],
              [-37.195714197125689, -7.650461434175618],
              [-37.199045178706292, -7.650461434175618],
              [-37.199045178706292, -7.550461434175618],
              [-37.134006321635376, -7.550461434175618],
              [-37.134488787999899, -7.551199116999942],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '868',
        ID: 43336.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -7.6005,
        ANNUAL: 5376.0,
        JAN: 5709.0,
        FEB: 5925.0,
        MAR: 5900.0,
        APR: 5300.0,
        MAY: 4543.0,
        JUN: 4079.0,
        JUL: 4234.0,
        AUG: 5009.0,
        SEP: 5618.0,
        OCT: 5917.0,
        NOV: 6219.0,
        DEC: 6062.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.5876859319999, -7.650224265999951],
              [-35.586598449, -7.650271038999954],
              [-35.586112941291873, -7.650461434175618],
              [-35.588055473143775, -7.650461434175618],
              [-35.5876859319999, -7.650224265999951],
            ],
          ],
          [
            [
              [-35.531533666, -7.649460308999948],
              [-35.52971969, -7.646476053999948],
              [-35.528088673, -7.645678184999943],
              [-35.526950337, -7.645121327999962],
              [-35.52587892, -7.644597206999948],
              [-35.522074173, -7.643540175999923],
              [-35.522072136, -7.643540029999937],
              [-35.519771003, -7.643374334999979],
              [-35.519212, -7.643334139999987],
              [-35.515577662999902, -7.644078632999948],
              [-35.515576939, -7.644077446999965],
              [-35.515070382, -7.643247157999954],
              [-35.515063622999904, -7.642237246999967],
              [-35.516216429999901, -7.641222054999962],
              [-35.519631903999901, -7.640017506999917],
              [-35.530423739, -7.626179600999931],
              [-35.53168707, -7.621666103999925],
              [-35.531209939, -7.619313122999928],
              [-35.529861944999901, -7.616571901999973],
              [-35.528342304, -7.614674206999957],
              [-35.525810772999897, -7.60756487699996],
              [-35.525111592, -7.606782510999955],
              [-35.521400066, -7.606020091999945],
              [-35.5213969019999, -7.606020485999974],
              [-35.518099185, -7.606431124999969],
              [-35.517628472, -7.60520101199995],
              [-35.51821188, -7.604150264999925],
              [-35.518316464999899, -7.603961902999974],
              [-35.521481971, -7.600521532999954],
              [-35.521936152, -7.598948408999942],
              [-35.521154244999899, -7.596405965999967],
              [-35.518039918999904, -7.589870924999956],
              [-35.51791379, -7.589700251999937],
              [-35.515200181999901, -7.586028303999961],
              [-35.515193881, -7.585021017999961],
              [-35.5181439529999, -7.584946015999946],
              [-35.518147727, -7.584945919999936],
              [-35.523907095, -7.589120096999976],
              [-35.5278517859999, -7.590210549999932],
              [-35.5278534629999, -7.590211013999983],
              [-35.53096443, -7.590008839999935],
              [-35.535853814999903, -7.588579566999956],
              [-35.536367132, -7.588077784999936],
              [-35.537836845, -7.586641094999952],
              [-35.537896654999898, -7.584216022999946],
              [-35.537775294, -7.583521535999979],
              [-35.537379603, -7.581257192999941],
              [-35.537276182, -7.580665367999932],
              [-35.537272346999899, -7.580643420999932],
              [-35.5343710349999, -7.575371006999942],
              [-35.532911628, -7.573643062999952],
              [-35.528729939, -7.571759717999953],
              [-35.527211049, -7.569961436999963],
              [-35.525265744, -7.563757891999966],
              [-35.524390626999903, -7.559344884999965],
              [-35.524163062999897, -7.558804211999923],
              [-35.524139838999901, -7.558749032999967],
              [-35.523807620999897, -7.557959710999976],
              [-35.519956244750958, -7.550461434175618],
              [-35.499045178706268, -7.550461434175618],
              [-35.499045178706268, -7.650461434175618],
              [-35.531664758742018, -7.650461434175618],
              [-35.531533666, -7.649460308999948],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '869',
        ID: 43337.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -7.6005,
        ANNUAL: 5166.0,
        JAN: 5507.0,
        FEB: 5686.0,
        MAR: 5686.0,
        APR: 5154.0,
        MAY: 4409.0,
        JUN: 3950.0,
        JUL: 4064.0,
        AUG: 4792.0,
        SEP: 5338.0,
        OCT: 5580.0,
        NOV: 5997.0,
        DEC: 5827.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.499045178706268, -7.650461434175618],
              [-35.499045178706268, -7.550461434175618],
              [-35.399045178706267, -7.550461434175618],
              [-35.399045178706267, -7.650461434175618],
              [-35.499045178706268, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '870',
        ID: 43338.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -7.6005,
        ANNUAL: 5101.0,
        JAN: 5372.0,
        FEB: 5568.0,
        MAR: 5607.0,
        APR: 5088.0,
        MAY: 4379.0,
        JUN: 3988.0,
        JUL: 4081.0,
        AUG: 4804.0,
        SEP: 5296.0,
        OCT: 5503.0,
        NOV: 5848.0,
        DEC: 5673.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.399045178706267, -7.650461434175618],
              [-35.399045178706267, -7.550461434175618],
              [-35.299045178706265, -7.550461434175618],
              [-35.299045178706265, -7.650461434175618],
              [-35.399045178706267, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '871',
        ID: 43339.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -7.6005,
        ANNUAL: 5205.0,
        JAN: 5486.0,
        FEB: 5642.0,
        MAR: 5747.0,
        APR: 5135.0,
        MAY: 4479.0,
        JUN: 4080.0,
        JUL: 4189.0,
        AUG: 4915.0,
        SEP: 5399.0,
        OCT: 5623.0,
        NOV: 5937.0,
        DEC: 5823.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -7.650461434175618],
              [-35.299045178706265, -7.550461434175618],
              [-35.199045178706264, -7.550461434175618],
              [-35.199045178706264, -7.650461434175618],
              [-35.299045178706265, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '872',
        ID: 43340.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -7.6005,
        ANNUAL: 5328.0,
        JAN: 5648.0,
        FEB: 5790.0,
        MAR: 5861.0,
        APR: 5198.0,
        MAY: 4541.0,
        JUN: 4135.0,
        JUL: 4265.0,
        AUG: 5031.0,
        SEP: 5532.0,
        OCT: 5800.0,
        NOV: 6090.0,
        DEC: 6045.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -7.650461434175618],
              [-35.199045178706264, -7.550461434175618],
              [-35.099045178706263, -7.550461434175618],
              [-35.099045178706263, -7.650461434175618],
              [-35.199045178706264, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '873',
        ID: 43341.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -7.6005,
        ANNUAL: 5397.0,
        JAN: 5699.0,
        FEB: 6013.0,
        MAR: 6054.0,
        APR: 5240.0,
        MAY: 4585.0,
        JUN: 4197.0,
        JUL: 4307.0,
        AUG: 5051.0,
        SEP: 5536.0,
        OCT: 5878.0,
        NOV: 6114.0,
        DEC: 6088.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.099045178706263, -7.650461434175618],
              [-35.099045178706263, -7.550461434175618],
              [-34.999045178706261, -7.550461434175618],
              [-34.999045178706261, -7.650461434175618],
              [-35.099045178706263, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '874',
        ID: 43342.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -7.6005,
        ANNUAL: 5451.0,
        JAN: 5806.0,
        FEB: 6119.0,
        MAR: 6042.0,
        APR: 5300.0,
        MAY: 4657.0,
        JUN: 4280.0,
        JUL: 4374.0,
        AUG: 5139.0,
        SEP: 5546.0,
        OCT: 5930.0,
        NOV: 6096.0,
        DEC: 6124.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.999045178706261, -7.650461434175618],
              [-34.999045178706261, -7.550461434175618],
              [-34.89904517870626, -7.550461434175618],
              [-34.89904517870626, -7.650461434175618],
              [-34.999045178706261, -7.650461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '875',
        ID: 43343.0,
        COUNTRY: 'Brasil',
        LON: -34.849,
        LAT: -7.6005,
        ANNUAL: 5521.0,
        JAN: 5867.0,
        FEB: 6137.0,
        MAR: 6033.0,
        APR: 5338.0,
        MAY: 4721.0,
        JUN: 4361.0,
        JUL: 4471.0,
        AUG: 5279.0,
        SEP: 5677.0,
        OCT: 6043.0,
        NOV: 6150.0,
        DEC: 6170.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.836145987, -7.551638655999976],
              [-34.836391302, -7.552205469999934],
              [-34.836560977, -7.552440248999976],
              [-34.836842299, -7.552692779999968],
              [-34.836863896999901, -7.552700645999956],
              [-34.837178329, -7.552906614999925],
              [-34.837474745999899, -7.553071712999953],
              [-34.8382318439999, -7.55348867899994],
              [-34.839053088999897, -7.553940974999933],
              [-34.839000461999902, -7.55421914099998],
              [-34.838877103, -7.554528703999954],
              [-34.838582738, -7.554914636999976],
              [-34.838206114999899, -7.555230618999941],
              [-34.837730178999898, -7.555530438999939],
              [-34.837450724999897, -7.555623072999945],
              [-34.837259248, -7.555899538999935],
              [-34.837158486, -7.556186369999982],
              [-34.837195873, -7.556434779999964],
              [-34.837286650999907, -7.556635741999974],
              [-34.837442936999899, -7.556894502],
              [-34.837526615999899, -7.557148331999936],
              [-34.837591114, -7.557351137999948],
              [-34.837693552, -7.557545741999946],
              [-34.837849721999902, -7.55770014299998],
              [-34.837586154999897, -7.558507859999958],
              [-34.837552229, -7.558535908999946],
              [-34.837361749, -7.558754415999944],
              [-34.837164431, -7.558950758999965],
              [-34.837063311, -7.559114490999946],
              [-34.836945383999897, -7.559257359999954],
              [-34.836797426, -7.559519419999961],
              [-34.836743102999897, -7.559682958999923],
              [-34.836739588999897, -7.559895459999952],
              [-34.836738553, -7.559905025999965],
              [-34.836750041, -7.560046555999976],
              [-34.836889796, -7.560310176999951],
              [-34.836991899999902, -7.560628284999955],
              [-34.837040373, -7.56092371299997],
              [-34.837033036, -7.561306293999966],
              [-34.837011748, -7.561637812999942],
              [-34.836985501999898, -7.561857542999973],
              [-34.836930730999903, -7.562115360999933],
              [-34.836897683, -7.562690636999957],
              [-34.835170790999904, -7.563291454999957],
              [-34.835103005999898, -7.563315038999975],
              [-34.834885331999899, -7.563457826999977],
              [-34.834699525, -7.563597754999925],
              [-34.834478366, -7.563743303999961],
              [-34.83426236, -7.563870604999972],
              [-34.834005685, -7.563965382999979],
              [-34.833629518999899, -7.563997323999959],
              [-34.8334260829999, -7.564067095999972],
              [-34.833430555999897, -7.564212370999937],
              [-34.833430866999898, -7.564230225999948],
              [-34.833439762, -7.564438346999961],
              [-34.833439046, -7.564444953999949],
              [-34.833400481, -7.564697026999966],
              [-34.833326546, -7.564923005999954],
              [-34.8331206, -7.56551673299998],
              [-34.8329823609999, -7.565983189999947],
              [-34.832870144, -7.566378363999947],
              [-34.832759411, -7.566777559999931],
              [-34.832704847999899, -7.566969995999958],
              [-34.832605509, -7.567264584999981],
              [-34.832479368999898, -7.567516227999934],
              [-34.832347856999903, -7.567734203999984],
              [-34.832264517999903, -7.567818883999957],
              [-34.832140968, -7.567919451999958],
              [-34.832131423, -7.567927221999984],
              [-34.832126263, -7.567933355999973],
              [-34.832017257, -7.568088816999931],
              [-34.831940882, -7.568254427999936],
              [-34.831919811, -7.568365889999946],
              [-34.831924543999897, -7.568446587999966],
              [-34.831968628, -7.56871345499997],
              [-34.8319747379999, -7.568750435999965],
              [-34.832004718, -7.568966719999927],
              [-34.83204282, -7.569241600999924],
              [-34.832076462, -7.569677332999954],
              [-34.832070455, -7.569939898999966],
              [-34.832066106, -7.570104276999926],
              [-34.832029807, -7.570231830999948],
              [-34.832029091, -7.570238436999936],
              [-34.831946964999901, -7.570312096999939],
              [-34.831918409, -7.570318267999947],
              [-34.83182963, -7.570337453999964],
              [-34.831733621, -7.570394045999934],
              [-34.831732332999898, -7.570447370999933],
              [-34.831799528, -7.570677293999941],
              [-34.831834692, -7.570850336999972],
              [-34.831840171, -7.571069222999936],
              [-34.831838978, -7.571080234999939],
              [-34.831823438999898, -7.571285744999951],
              [-34.831822722, -7.571292351999948],
              [-34.8317734829999, -7.571456407999964],
              [-34.831731735999902, -7.57150988199994],
              [-34.831697846999901, -7.57155305599997],
              [-34.8317418, -7.571686492999967],
              [-34.831766689999903, -7.571995890999967],
              [-34.831783679, -7.572191507999958],
              [-34.83178706, -7.572375136999932],
              [-34.831788898, -7.572474973999927],
              [-34.831777733, -7.572521187999945],
              [-34.831735906999903, -7.572694316999958],
              [-34.831665219999898, -7.572931826999931],
              [-34.831613153, -7.573121880999953],
              [-34.831520344, -7.573314707999947],
              [-34.831386513, -7.573492030999939],
              [-34.831348421, -7.573698266999934],
              [-34.831352935, -7.573957222999976],
              [-34.831353089999901, -7.573966091999975],
              [-34.831395507, -7.574300314999962],
              [-34.831498634, -7.574571945999938],
              [-34.831537093999899, -7.574724001999932],
              [-34.83156783, -7.574845519999944],
              [-34.831573234, -7.57518916699996],
              [-34.8315305039999, -7.575396244999971],
              [-34.831503993, -7.575649401999948],
              [-34.831410854, -7.575824354999956],
              [-34.831313032, -7.575918613999923],
              [-34.831212888, -7.576054950999953],
              [-34.83111767, -7.576373851999963],
              [-34.831018785, -7.576581593999942],
              [-34.830867226999899, -7.576757004999926],
              [-34.830779016, -7.576990420999948],
              [-34.830755697, -7.577329684999938],
              [-34.830654394, -7.577641916999933],
              [-34.830451728, -7.578101486999969],
              [-34.830376181, -7.578259376999933],
              [-34.830297529999903, -7.578342322999956],
              [-34.830007604999899, -7.57887152399995],
              [-34.829859034, -7.579205436999924],
              [-34.829745674, -7.579607710999945],
              [-34.829744479999903, -7.579618721999956],
              [-34.829744002, -7.579623125999936],
              [-34.829704501, -7.579821620999956],
              [-34.829595782, -7.580119663999941],
              [-34.829593083, -7.580123830999985],
              [-34.82951616, -7.580273797999943],
              [-34.829451228, -7.580437792999933],
              [-34.829282684, -7.580707194999954],
              [-34.829103223999901, -7.581044743999941],
              [-34.828960297, -7.581313579999964],
              [-34.828622403999901, -7.582153124999925],
              [-34.828580382, -7.582398363999972],
              [-34.828475511, -7.583010400999966],
              [-34.828355432, -7.583579176999922],
              [-34.828319209, -7.584203318999935],
              [-34.828103730999899, -7.58522221899994],
              [-34.827925127999897, -7.585790631999935],
              [-34.8276654229999, -7.586212498999942],
              [-34.827413711, -7.586485432999954],
              [-34.827026068999899, -7.586584029999933],
              [-34.826680552, -7.586615950999944],
              [-34.826577514, -7.58681557699992],
              [-34.826447142999911, -7.587339589999943],
              [-34.826316108999897, -7.588107673999964],
              [-34.826211519, -7.588736150999978],
              [-34.826154937999902, -7.589242263999957],
              [-34.826006713, -7.589615621999939],
              [-34.825904812, -7.589913161999952],
              [-34.825710618, -7.590576337999948],
              [-34.825472626, -7.591195502999943],
              [-34.825139879999902, -7.591946665999957],
              [-34.82494844, -7.592299272999976],
              [-34.824797648999898, -7.592577008999975],
              [-34.824404503, -7.593116377999938],
              [-34.823818171, -7.59361892399994],
              [-34.823522716999904, -7.593870679999945],
              [-34.823078799, -7.594325493999979],
              [-34.822694344, -7.594869967999941],
              [-34.822393068999901, -7.595412270999925],
              [-34.822093360999901, -7.596009147999946],
              [-34.821634471, -7.596701472999943],
              [-34.821410858, -7.597162394999942],
              [-34.821230473, -7.59754295099997],
              [-34.821137509, -7.597856640999955],
              [-34.820875892999901, -7.598338682999943],
              [-34.820400994, -7.598223515999956],
              [-34.820242005, -7.598408998999952],
              [-34.820192263, -7.599184371999963],
              [-34.820161232, -7.599733128999953],
              [-34.82008502, -7.600176761999931],
              [-34.819794289, -7.600696747999955],
              [-34.819550503999899, -7.600860973999941],
              [-34.819528675, -7.600923857999937],
              [-34.819254940999897, -7.601548876999967],
              [-34.819102533, -7.602240460999953],
              [-34.818744011, -7.602886061999935],
              [-34.818452388, -7.603263367999931],
              [-34.818162975, -7.603563981999968],
              [-34.817679873, -7.604173296999928],
              [-34.817458772999899, -7.604295500999968],
              [-34.817361073, -7.604452179999946],
              [-34.817172543, -7.604969393999969],
              [-34.817014811, -7.605183631999986],
              [-34.816868473999897, -7.60539696299998],
              [-34.816600650999902, -7.606029291999965],
              [-34.816372515999902, -7.606528029999942],
              [-34.8161505469999, -7.607075640999935],
              [-34.81587367, -7.607726109999938],
              [-34.815606663, -7.608193409999955],
              [-34.815345485, -7.608561275999934],
              [-34.815112655, -7.608911961999983],
              [-34.814853630999899, -7.609398674999976],
              [-34.814492464999901, -7.609895418999945],
              [-34.814173766, -7.610410949999959],
              [-34.813983634, -7.61079101199994],
              [-34.813881271, -7.611002544999963],
              [-34.81380517, -7.611159809999952],
              [-34.813746540999901, -7.611280968999955],
              [-34.813414672, -7.612105079999941],
              [-34.813297738, -7.612311650999977],
              [-34.813271343999901, -7.612358280999955],
              [-34.813105705999902, -7.612721400999932],
              [-34.812913309, -7.613040415999932],
              [-34.812627404999901, -7.613402940999952],
              [-34.812353851, -7.613771104999958],
              [-34.812142971, -7.614054919999948],
              [-34.811912951, -7.61433972999993],
              [-34.811647363, -7.61463848899996],
              [-34.811459767, -7.614781785999982],
              [-34.811389049, -7.614937773999943],
              [-34.811271766999901, -7.615126131999947],
              [-34.811094082999901, -7.615325822999973],
              [-34.8108643919999, -7.615459533999974],
              [-34.81067073, -7.615498643999962],
              [-34.810306537, -7.615780492999938],
              [-34.809999026999897, -7.616047522999961],
              [-34.809633264999903, -7.616269083999943],
              [-34.809344495999902, -7.616395240999959],
              [-34.809173222, -7.616394210999936],
              [-34.809142338999898, -7.616394025999974],
              [-34.80902685, -7.616507981999968],
              [-34.808899736999898, -7.616598648999977],
              [-34.808822189999901, -7.616642490999946],
              [-34.808734273, -7.616662874999979],
              [-34.808564725999901, -7.616743010999926],
              [-34.808482179, -7.616761960999951],
              [-34.808349334999903, -7.616792892999977],
              [-34.808244649999899, -7.616854579999947],
              [-34.808163764, -7.617019409999955],
              [-34.808118844, -7.617123963999972],
              [-34.808065434, -7.617248284999954],
              [-34.807963051999899, -7.617417754999966],
              [-34.807807179999898, -7.617621809999952],
              [-34.807646598, -7.617844030999959],
              [-34.807166384, -7.618455153999944],
              [-34.80690334, -7.619074531999964],
              [-34.806733795999904, -7.619792311999954],
              [-34.806690724, -7.620428367999984],
              [-34.806747329, -7.620991205999933],
              [-34.807065029999897, -7.622174259999947],
              [-34.807201731, -7.622471641999949],
              [-34.807421118, -7.622948900999967],
              [-34.807339366999898, -7.623428211999957],
              [-34.807310598, -7.623596883999938],
              [-34.807292953999898, -7.624005409999937],
              [-34.807313711999903, -7.624091081999949],
              [-34.807438342999902, -7.624605421999956],
              [-34.807488255, -7.62486542699997],
              [-34.807598461999902, -7.625112850999923],
              [-34.807737718999903, -7.625668082999942],
              [-34.807874582, -7.626116305999971],
              [-34.80798399, -7.626301420999947],
              [-34.8081109949999, -7.626679193999959],
              [-34.808135498999903, -7.626756557999946],
              [-34.808217885, -7.627016662999957],
              [-34.808315831999899, -7.627206789999949],
              [-34.808399052, -7.627368327999926],
              [-34.808411659999898, -7.627671470999924],
              [-34.808470364999899, -7.627969121999966],
              [-34.808610730999902, -7.628320575999959],
              [-34.808686211999898, -7.62868938999998],
              [-34.808740955, -7.629145320999944],
              [-34.8087755889999, -7.629374491999953],
              [-34.808724801, -7.629681272999957],
              [-34.808697259, -7.629871933999943],
              [-34.808696671, -7.629876001999947],
              [-34.808732683, -7.630060225999961],
              [-34.808825913, -7.630233203999986],
              [-34.808990203, -7.630396409999954],
              [-34.809121046, -7.630448511999924],
              [-34.809298949, -7.630550723999929],
              [-34.810032452, -7.631283769999919],
              [-34.810748138999898, -7.632009059999976],
              [-34.811215536, -7.632482726999967],
              [-34.811788196999899, -7.633043282999954],
              [-34.812618047999898, -7.634002629999944],
              [-34.813553215999903, -7.635145894999931],
              [-34.813921008, -7.63566788399993],
              [-34.814182542, -7.636039065999976],
              [-34.814600090999903, -7.63673921999998],
              [-34.815056049, -7.637436375999926],
              [-34.815476184, -7.638209673999941],
              [-34.815485970999902, -7.638219955999942],
              [-34.815692977, -7.638437450999958],
              [-34.815850352, -7.638651059999976],
              [-34.816059074999899, -7.638934365999944],
              [-34.816460462, -7.639535119999959],
              [-34.81652494, -7.639631622999954],
              [-34.817305921, -7.64086787599996],
              [-34.817787433, -7.641867420999971],
              [-34.8181506459999, -7.642585092999986],
              [-34.81854448, -7.643342585999964],
              [-34.818865765999902, -7.643671694999979],
              [-34.819199497999897, -7.644121718999949],
              [-34.819514292, -7.644672117999963],
              [-34.819893792, -7.64525296299996],
              [-34.8202008539999, -7.645681124999945],
              [-34.820465753999898, -7.646142966999942],
              [-34.820768388, -7.646806342999985],
              [-34.821165392999902, -7.647646313999938],
              [-34.821364743999901, -7.647938130999933],
              [-34.821636124, -7.648275786999928],
              [-34.821885626, -7.648621514999953],
              [-34.82211391, -7.649229873999962],
              [-34.822257782999898, -7.649484564999973],
              [-34.822358650999902, -7.649748542999929],
              [-34.822511531, -7.650049281999947],
              [-34.822687460051377, -7.650461434175618],
              [-34.89904517870626, -7.650461434175618],
              [-34.89904517870626, -7.550461434175618],
              [-34.833114325620336, -7.550461434175618],
              [-34.836145987, -7.551638655999976],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '876',
        ID: 43676.0,
        COUNTRY: 'Brasil',
        LON: -40.749,
        LAT: -7.5005,
        ANNUAL: 5791.0,
        JAN: 5609.0,
        FEB: 5488.0,
        MAR: 5492.0,
        APR: 5330.0,
        MAY: 5236.0,
        JUN: 5198.0,
        JUL: 5522.0,
        AUG: 6151.0,
        SEP: 6514.0,
        OCT: 6543.0,
        NOV: 6404.0,
        DEC: 6006.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.701203865, -7.525785803999957],
              [-40.704294057, -7.518708050999985],
              [-40.708625377, -7.50588895599998],
              [-40.709330797999897, -7.503800972999944],
              [-40.710142183, -7.501399080999927],
              [-40.710590638, -7.500878293999952],
              [-40.713786044, -7.49391055999996],
              [-40.715293978, -7.487954017999934],
              [-40.715536484, -7.484437274999953],
              [-40.715141028999902, -7.481414650999959],
              [-40.714948276, -7.480568446999937],
              [-40.714936727, -7.480517744999939],
              [-40.71322638, -7.473003989999946],
              [-40.706089295, -7.460045226999968],
              [-40.704897763, -7.458737062999945],
              [-40.704629406, -7.458564339999944],
              [-40.7020271499999, -7.456888191999946],
              [-40.701201426999901, -7.456356332999968],
              [-40.699045178706342, -7.456087331222229],
              [-40.699045178706342, -7.529780282445121],
              [-40.701203865, -7.525785803999957],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '877',
        ID: 43677.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -7.5005,
        ANNUAL: 5732.0,
        JAN: 5672.0,
        FEB: 5542.0,
        MAR: 5553.0,
        APR: 5307.0,
        MAY: 5086.0,
        JUN: 5016.0,
        JUL: 5349.0,
        AUG: 5972.0,
        SEP: 6392.0,
        OCT: 6415.0,
        NOV: 6421.0,
        DEC: 6059.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.69393903, -7.53893018599996],
              [-40.69408059700001, -7.538718915999934],
              [-40.695247775, -7.536807077999974],
              [-40.699045178706342, -7.529780282445121],
              [-40.699045178706342, -7.456087331222229],
              [-40.67486193, -7.453070360999953],
              [-40.672010632, -7.45271464899996],
              [-40.667656714, -7.451493063999975],
              [-40.667577224999903, -7.451470761999927],
              [-40.667573632, -7.451469753999958],
              [-40.667291061999897, -7.451390472999943],
              [-40.666869114999905, -7.451272086999942],
              [-40.666812439999902, -7.451256185999975],
              [-40.665705353, -7.45094556799995],
              [-40.664977831320137, -7.450461434175619],
              [-40.599045178706341, -7.450461434175619],
              [-40.599045178706341, -7.550461434175618],
              [-40.686212225326379, -7.550461434175618],
              [-40.69393903, -7.53893018599996],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '878',
        ID: 43678.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -7.5005,
        ANNUAL: 5683.0,
        JAN: 5596.0,
        FEB: 5426.0,
        MAR: 5445.0,
        APR: 5209.0,
        MAY: 4989.0,
        JUN: 4882.0,
        JUL: 5215.0,
        AUG: 6062.0,
        SEP: 6504.0,
        OCT: 6482.0,
        NOV: 6367.0,
        DEC: 6018.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.599045178706341, -7.550461434175618],
              [-40.599045178706341, -7.450461434175619],
              [-40.499045178706339, -7.450461434175619],
              [-40.499045178706339, -7.550461434175618],
              [-40.599045178706341, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '879',
        ID: 43679.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -7.5005,
        ANNUAL: 5654.0,
        JAN: 5564.0,
        FEB: 5424.0,
        MAR: 5424.0,
        APR: 5154.0,
        MAY: 4910.0,
        JUN: 4804.0,
        JUL: 5142.0,
        AUG: 6052.0,
        SEP: 6538.0,
        OCT: 6481.0,
        NOV: 6367.0,
        DEC: 5989.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.499045178706339, -7.550461434175618],
              [-40.499045178706339, -7.450461434175619],
              [-40.399045178706338, -7.450461434175619],
              [-40.399045178706338, -7.550461434175618],
              [-40.499045178706339, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '880',
        ID: 43680.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -7.5005,
        ANNUAL: 5652.0,
        JAN: 5625.0,
        FEB: 5473.0,
        MAR: 5444.0,
        APR: 5166.0,
        MAY: 4878.0,
        JUN: 4748.0,
        JUL: 5085.0,
        AUG: 5976.0,
        SEP: 6538.0,
        OCT: 6507.0,
        NOV: 6376.0,
        DEC: 6011.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.399045178706338, -7.550461434175618],
              [-40.399045178706338, -7.450461434175619],
              [-40.299045178706336, -7.450461434175619],
              [-40.299045178706336, -7.550461434175618],
              [-40.399045178706338, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '881',
        ID: 43681.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -7.5005,
        ANNUAL: 5623.0,
        JAN: 5655.0,
        FEB: 5492.0,
        MAR: 5481.0,
        APR: 5160.0,
        MAY: 4857.0,
        JUN: 4667.0,
        JUL: 4981.0,
        AUG: 5875.0,
        SEP: 6473.0,
        OCT: 6449.0,
        NOV: 6364.0,
        DEC: 6025.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.299045178706336, -7.550461434175618],
              [-40.299045178706336, -7.450461434175619],
              [-40.199045178706335, -7.450461434175619],
              [-40.199045178706335, -7.550461434175618],
              [-40.299045178706336, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '882',
        ID: 43682.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -7.5005,
        ANNUAL: 5614.0,
        JAN: 5647.0,
        FEB: 5494.0,
        MAR: 5508.0,
        APR: 5155.0,
        MAY: 4813.0,
        JUN: 4632.0,
        JUL: 4950.0,
        AUG: 5878.0,
        SEP: 6521.0,
        OCT: 6464.0,
        NOV: 6311.0,
        DEC: 6002.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -7.550461434175618],
              [-40.199045178706335, -7.450461434175619],
              [-40.099045178706334, -7.450461434175619],
              [-40.099045178706334, -7.550461434175618],
              [-40.199045178706335, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '883',
        ID: 43683.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -7.5005,
        ANNUAL: 5571.0,
        JAN: 5624.0,
        FEB: 5467.0,
        MAR: 5463.0,
        APR: 5053.0,
        MAY: 4739.0,
        JUN: 4549.0,
        JUL: 4888.0,
        AUG: 5816.0,
        SEP: 6485.0,
        OCT: 6429.0,
        NOV: 6336.0,
        DEC: 6002.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.099045178706334, -7.550461434175618],
              [-40.099045178706334, -7.450461434175619],
              [-39.999045178706332, -7.450461434175619],
              [-39.999045178706332, -7.550461434175618],
              [-40.099045178706334, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '884',
        ID: 43684.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -7.5005,
        ANNUAL: 5671.0,
        JAN: 5765.0,
        FEB: 5635.0,
        MAR: 5634.0,
        APR: 5210.0,
        MAY: 4797.0,
        JUN: 4568.0,
        JUL: 4940.0,
        AUG: 5847.0,
        SEP: 6523.0,
        OCT: 6518.0,
        NOV: 6452.0,
        DEC: 6158.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.999045178706332, -7.550461434175618],
              [-39.999045178706332, -7.450461434175619],
              [-39.899045178706331, -7.450461434175619],
              [-39.899045178706331, -7.550461434175618],
              [-39.999045178706332, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '885',
        ID: 43685.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -7.5005,
        ANNUAL: 5639.0,
        JAN: 5796.0,
        FEB: 5653.0,
        MAR: 5693.0,
        APR: 5225.0,
        MAY: 4747.0,
        JUN: 4481.0,
        JUL: 4823.0,
        AUG: 5774.0,
        SEP: 6456.0,
        OCT: 6476.0,
        NOV: 6446.0,
        DEC: 6100.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -7.550461434175618],
              [-39.899045178706331, -7.450461434175619],
              [-39.799045178706329, -7.450461434175619],
              [-39.799045178706329, -7.550461434175618],
              [-39.899045178706331, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '886',
        ID: 43686.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -7.5005,
        ANNUAL: 5650.0,
        JAN: 5851.0,
        FEB: 5692.0,
        MAR: 5719.0,
        APR: 5270.0,
        MAY: 4756.0,
        JUN: 4483.0,
        JUL: 4787.0,
        AUG: 5709.0,
        SEP: 6459.0,
        OCT: 6507.0,
        NOV: 6466.0,
        DEC: 6095.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -7.550461434175618],
              [-39.799045178706329, -7.450461434175619],
              [-39.699045178706328, -7.450461434175619],
              [-39.699045178706328, -7.550461434175618],
              [-39.799045178706329, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '887',
        ID: 43687.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -7.5005,
        ANNUAL: 5619.0,
        JAN: 5703.0,
        FEB: 5586.0,
        MAR: 5593.0,
        APR: 5240.0,
        MAY: 4735.0,
        JUN: 4497.0,
        JUL: 4794.0,
        AUG: 5719.0,
        SEP: 6474.0,
        OCT: 6518.0,
        NOV: 6467.0,
        DEC: 6110.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.699045178706328, -7.550461434175618],
              [-39.699045178706328, -7.450461434175619],
              [-39.599045178706326, -7.450461434175619],
              [-39.599045178706326, -7.550461434175618],
              [-39.699045178706328, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '888',
        ID: 43688.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -7.5005,
        ANNUAL: 5613.0,
        JAN: 5681.0,
        FEB: 5541.0,
        MAR: 5579.0,
        APR: 5204.0,
        MAY: 4747.0,
        JUN: 4520.0,
        JUL: 4818.0,
        AUG: 5710.0,
        SEP: 6445.0,
        OCT: 6460.0,
        NOV: 6507.0,
        DEC: 6136.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.564696168999902, -7.453658079999976],
              [-39.562540666, -7.460378035999953],
              [-39.557454839999899, -7.472544857999944],
              [-39.552827912999902, -7.480656838999945],
              [-39.548425348, -7.483133297999929],
              [-39.548001537999902, -7.483266482999976],
              [-39.545852808999904, -7.483941731999948],
              [-39.543678035, -7.484625649999938],
              [-39.543249069, -7.484760549999986],
              [-39.536443944, -7.48253032799994],
              [-39.5305657279999, -7.478831667999933],
              [-39.521615852, -7.47261841299996],
              [-39.512102667, -7.467147861999929],
              [-39.507695278, -7.465377450999943],
              [-39.502360546, -7.464148441999953],
              [-39.499271248999896, -7.465850452999962],
              [-39.499045178706325, -7.46607622379136],
              [-39.499045178706325, -7.550461434175618],
              [-39.599045178706326, -7.550461434175618],
              [-39.599045178706326, -7.450461434175619],
              [-39.565587030181952, -7.450461434175619],
              [-39.564696168999902, -7.453658079999976],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '889',
        ID: 43689.0,
        COUNTRY: 'Brasil',
        LON: -39.449,
        LAT: -7.5005,
        ANNUAL: 5628.0,
        JAN: 5706.0,
        FEB: 5555.0,
        MAR: 5550.0,
        APR: 5210.0,
        MAY: 4788.0,
        JUN: 4571.0,
        JUL: 4886.0,
        AUG: 5766.0,
        SEP: 6439.0,
        OCT: 6413.0,
        NOV: 6505.0,
        DEC: 6143.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.498730043, -7.466390941999978],
              [-39.497534206, -7.468013731999975],
              [-39.492436604, -7.471262496999934],
              [-39.489732515, -7.472423251999936],
              [-39.487337114, -7.472735355999939],
              [-39.475511884, -7.472670423999944],
              [-39.472109020999902, -7.470898551999943],
              [-39.4678565849999, -7.469590608999967],
              [-39.460747612, -7.471218998999971],
              [-39.458492818, -7.474210407999982],
              [-39.458277418, -7.474495487999951],
              [-39.457968442, -7.475688714999925],
              [-39.456737511, -7.480442416999956],
              [-39.455677481, -7.50186092199993],
              [-39.457305801, -7.506182958999932],
              [-39.469688420999901, -7.520568636999944],
              [-39.476652831, -7.527046713999934],
              [-39.481447941999903, -7.529280635999942],
              [-39.482530504, -7.530051096999964],
              [-39.484698022, -7.532828493999944],
              [-39.485164214999898, -7.534449021999938],
              [-39.485013976999902, -7.538386600999956],
              [-39.484243858, -7.541089773999942],
              [-39.483318389, -7.542789179999946],
              [-39.481233233, -7.545370191999953],
              [-39.478222036230974, -7.550461434175618],
              [-39.499045178706325, -7.550461434175618],
              [-39.499045178706325, -7.46607622379136],
              [-39.498730043, -7.466390941999978],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '890',
        ID: 43706.0,
        COUNTRY: 'Brasil',
        LON: -37.749,
        LAT: -7.5005,
        ANNUAL: 5893.0,
        JAN: 6123.0,
        FEB: 6071.0,
        MAR: 6044.0,
        APR: 5783.0,
        MAY: 5111.0,
        JUN: 4753.0,
        JUL: 4991.0,
        AUG: 5809.0,
        SEP: 6501.0,
        OCT: 6563.0,
        NOV: 6666.0,
        DEC: 6297.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.700996734999897, -7.549597945999946],
              [-37.699045178706299, -7.549685168665748],
              [-37.699045178706299, -7.550461434175618],
              [-37.705822069102133, -7.550461434175618],
              [-37.700996734999897, -7.549597945999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '891',
        ID: 43707.0,
        COUNTRY: 'Brasil',
        LON: -37.649,
        LAT: -7.5005,
        ANNUAL: 5899.0,
        JAN: 6136.0,
        FEB: 6077.0,
        MAR: 6081.0,
        APR: 5805.0,
        MAY: 5096.0,
        JUN: 4763.0,
        JUL: 5014.0,
        AUG: 5808.0,
        SEP: 6494.0,
        OCT: 6557.0,
        NOV: 6679.0,
        DEC: 6281.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.691820113999903, -7.550008084999933],
              [-37.682150919, -7.548376042999964],
              [-37.679855582, -7.544970504999985],
              [-37.678462198, -7.541665250999926],
              [-37.677972935999897, -7.537295508999923],
              [-37.676634905999897, -7.535613829999938],
              [-37.665276389, -7.529413986999945],
              [-37.663103008, -7.526112387999936],
              [-37.659529410999902, -7.523137356999943],
              [-37.655818832, -7.523416622999951],
              [-37.654107092999901, -7.526030811999926],
              [-37.6504744819999, -7.529663716999942],
              [-37.649827198, -7.530311052999925],
              [-37.649427511999903, -7.530710300999944],
              [-37.644602201999902, -7.529888682999976],
              [-37.644541019, -7.529878264999982],
              [-37.644501001, -7.529871448999931],
              [-37.6413448919999, -7.529333888999937],
              [-37.638001007, -7.530746285999946],
              [-37.630378123999897, -7.532511021999936],
              [-37.630284342, -7.53239346999993],
              [-37.626894374, -7.528144225999933],
              [-37.626883021, -7.528129994999963],
              [-37.619943667, -7.525879523999959],
              [-37.615914987999901, -7.524943370999943],
              [-37.611372418, -7.518568280999946],
              [-37.610644045, -7.511819837999952],
              [-37.609770781, -7.508030827999956],
              [-37.606622711999897, -7.506431732999937],
              [-37.599982049999902, -7.504506518999961],
              [-37.599045178706298, -7.504124914760986],
              [-37.599045178706298, -7.550461434175618],
              [-37.699045178706299, -7.550461434175618],
              [-37.699045178706299, -7.549685168665748],
              [-37.691820113999903, -7.550008084999933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '892',
        ID: 43708.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -7.5005,
        ANNUAL: 5872.0,
        JAN: 6135.0,
        FEB: 6070.0,
        MAR: 6048.0,
        APR: 5766.0,
        MAY: 5048.0,
        JUN: 4654.0,
        JUL: 4940.0,
        AUG: 5743.0,
        SEP: 6468.0,
        OCT: 6579.0,
        NOV: 6704.0,
        DEC: 6314.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.586729593, -7.499108558999986],
              [-37.580259129, -7.495520987999948],
              [-37.573608689, -7.490476876999947],
              [-37.571791683, -7.489098744999979],
              [-37.571300865, -7.488790089999949],
              [-37.55693042, -7.479753373999938],
              [-37.556891543999903, -7.479752145999945],
              [-37.552536221, -7.47961455599994],
              [-37.551610619, -7.47958531499995],
              [-37.54879359800001, -7.479496321999926],
              [-37.548761358, -7.479495301999973],
              [-37.5425911419999, -7.478289928999975],
              [-37.533066898, -7.472953231999946],
              [-37.5279585769999, -7.465310691999951],
              [-37.528590263, -7.460067957999966],
              [-37.528840185, -7.457993702999946],
              [-37.529090107999899, -7.455919446999937],
              [-37.528900977, -7.453549956999941],
              [-37.528006317055187, -7.450461434175619],
              [-37.499045178706297, -7.450461434175619],
              [-37.499045178706297, -7.550461434175618],
              [-37.599045178706298, -7.550461434175618],
              [-37.599045178706298, -7.504124914760986],
              [-37.586729593, -7.499108558999986],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '893',
        ID: 43709.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -7.5005,
        ANNUAL: 5812.0,
        JAN: 6159.0,
        FEB: 6038.0,
        MAR: 5992.0,
        APR: 5703.0,
        MAY: 4972.0,
        JUN: 4545.0,
        JUL: 4833.0,
        AUG: 5623.0,
        SEP: 6383.0,
        OCT: 6504.0,
        NOV: 6691.0,
        DEC: 6299.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.499045178706297, -7.550461434175618],
              [-37.499045178706297, -7.450461434175619],
              [-37.399045178706295, -7.450461434175619],
              [-37.399045178706295, -7.550461434175618],
              [-37.499045178706297, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '894',
        ID: 43710.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -7.5005,
        ANNUAL: 5795.0,
        JAN: 6170.0,
        FEB: 6037.0,
        MAR: 6047.0,
        APR: 5700.0,
        MAY: 4964.0,
        JUN: 4511.0,
        JUL: 4734.0,
        AUG: 5551.0,
        SEP: 6350.0,
        OCT: 6487.0,
        NOV: 6683.0,
        DEC: 6299.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.399045178706295, -7.550461434175618],
              [-37.399045178706295, -7.450461434175619],
              [-37.299045178706294, -7.450461434175619],
              [-37.299045178706294, -7.550461434175618],
              [-37.399045178706295, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '895',
        ID: 43711.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -7.5005,
        ANNUAL: 5769.0,
        JAN: 6148.0,
        FEB: 6040.0,
        MAR: 6025.0,
        APR: 5661.0,
        MAY: 4954.0,
        JUN: 4478.0,
        JUL: 4699.0,
        AUG: 5507.0,
        SEP: 6281.0,
        OCT: 6493.0,
        NOV: 6657.0,
        DEC: 6289.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -7.550461434175618],
              [-37.299045178706294, -7.450461434175619],
              [-37.199045178706292, -7.450461434175619],
              [-37.199045178706292, -7.550461434175618],
              [-37.299045178706294, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '896',
        ID: 43712.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -7.5005,
        ANNUAL: 5751.0,
        JAN: 6102.0,
        FEB: 6035.0,
        MAR: 6025.0,
        APR: 5665.0,
        MAY: 4926.0,
        JUN: 4499.0,
        JUL: 4717.0,
        AUG: 5472.0,
        SEP: 6260.0,
        OCT: 6457.0,
        NOV: 6586.0,
        DEC: 6273.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.106104775999903, -7.531127284999974],
              [-37.110636362, -7.532775862999925],
              [-37.118343211, -7.534430853999936],
              [-37.121524004, -7.53611889199993],
              [-37.128775069, -7.542728771999977],
              [-37.133731985999901, -7.550042071999939],
              [-37.133733704999905, -7.550044607999951],
              [-37.134006321635376, -7.550461434175618],
              [-37.199045178706292, -7.550461434175618],
              [-37.199045178706292, -7.450461434175619],
              [-37.099045178706291, -7.450461434175619],
              [-37.099045178706291, -7.525652972580104],
              [-37.106104775999903, -7.531127284999974],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '897',
        ID: 43713.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -7.5005,
        ANNUAL: 5744.0,
        JAN: 6134.0,
        FEB: 6032.0,
        MAR: 6007.0,
        APR: 5649.0,
        MAY: 4931.0,
        JUN: 4499.0,
        JUL: 4725.0,
        AUG: 5454.0,
        SEP: 6213.0,
        OCT: 6449.0,
        NOV: 6579.0,
        DEC: 6257.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.01099957, -7.452685483999962],
              [-37.010730958999901, -7.453635474999943],
              [-37.003338675, -7.454889453999948],
              [-36.99904517870629, -7.458097037117124],
              [-36.99904517870629, -7.493470431578154],
              [-37.003253266999899, -7.496388479999952],
              [-37.007284226, -7.494439240999947],
              [-37.010561661999901, -7.489557039999968],
              [-37.01389753, -7.492098935999924],
              [-37.021674296, -7.496687837999962],
              [-37.024553394999899, -7.496695974999977],
              [-37.025201373999899, -7.496697801999975],
              [-37.025688942246497, -7.497015394148572],
              [-37.025991512, -7.497212481999957],
              [-37.026163015, -7.497324053999922],
              [-37.026237841, -7.497343652999961],
              [-37.0263555659999, -7.497374488999974],
              [-37.027366781999902, -7.497639358999955],
              [-37.030414879, -7.498437752999962],
              [-37.035104276, -7.499666628999933],
              [-37.037370976, -7.50121440499993],
              [-37.043156918, -7.503914036999958],
              [-37.045730163, -7.504412493999955],
              [-37.053822162, -7.504568612999948],
              [-37.058432404, -7.507708859999983],
              [-37.063332456, -7.514211498999938],
              [-37.065155186, -7.515638998999972],
              [-37.068308222, -7.517108799999953],
              [-37.069887732, -7.517438658999947],
              [-37.079280896999897, -7.516589158999987],
              [-37.08505865, -7.517304104999937],
              [-37.086195264, -7.51779287299995],
              [-37.087454857999901, -7.518335636999945],
              [-37.094561229, -7.522175926999979],
              [-37.099045178706291, -7.525652972580104],
              [-37.099045178706291, -7.450461434175619],
              [-37.011628422464106, -7.450461434175619],
              [-37.01099957, -7.452685483999962],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '898',
        ID: 43714.0,
        COUNTRY: 'Brasil',
        LON: -36.949,
        LAT: -7.5005,
        ANNUAL: 5706.0,
        JAN: 6051.0,
        FEB: 6014.0,
        MAR: 5973.0,
        APR: 5613.0,
        MAY: 4895.0,
        JUN: 4474.0,
        JUL: 4685.0,
        AUG: 5414.0,
        SEP: 6166.0,
        OCT: 6392.0,
        NOV: 6558.0,
        DEC: 6236.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.997310400999901, -7.459393053999965],
              [-36.994062739, -7.464710780999947],
              [-36.995452746, -7.472590122999973],
              [-36.993006211, -7.474148948999924],
              [-36.987589359, -7.474026844999962],
              [-36.985423180999902, -7.475601986999957],
              [-36.984671928, -7.478072169999965],
              [-36.984225594, -7.482261691999942],
              [-36.993588234999898, -7.488478181999948],
              [-36.997677356999901, -7.492521931999928],
              [-36.99904517870629, -7.493470431578154],
              [-36.99904517870629, -7.458097037117124],
              [-36.997310400999901, -7.459393053999965],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '899',
        ID: 43728.0,
        COUNTRY: 'Brasil',
        LON: -35.549,
        LAT: -7.5005,
        ANNUAL: 5331.0,
        JAN: 5660.0,
        FEB: 5792.0,
        MAR: 5782.0,
        APR: 5230.0,
        MAY: 4541.0,
        JUN: 4123.0,
        JUL: 4271.0,
        AUG: 5072.0,
        SEP: 5616.0,
        OCT: 5817.0,
        NOV: 6118.0,
        DEC: 5944.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.519915346999902, -7.550381809999974],
              [-35.519909323, -7.54926005099998],
              [-35.520673874, -7.547987506999958],
              [-35.520680238, -7.547976913999958],
              [-35.520776269999899, -7.547817073999976],
              [-35.521378042, -7.541176702999962],
              [-35.521162204999897, -7.539068729999945],
              [-35.519706846, -7.535446036999935],
              [-35.516657580999897, -7.531150889999936],
              [-35.516652574, -7.531151269999946],
              [-35.512435276, -7.531470732999944],
              [-35.511406462, -7.530121538999944],
              [-35.510755708, -7.528250918999964],
              [-35.510605972, -7.526895330999935],
              [-35.510546017, -7.526352545999937],
              [-35.508996473, -7.523448449999924],
              [-35.50879342, -7.522080461999956],
              [-35.5091839419999, -7.517491597999943],
              [-35.506990684999899, -7.515182326999925],
              [-35.504829674, -7.514042278999966],
              [-35.501926873999899, -7.512511109999924],
              [-35.503019063, -7.511133066999953],
              [-35.504007913, -7.507973717999954],
              [-35.502483473, -7.503932751999977],
              [-35.501428389, -7.502039805999974],
              [-35.5005043049999, -7.500381890999964],
              [-35.4997859489999, -7.499715309999942],
              [-35.499740929, -7.499673534999946],
              [-35.499323423, -7.498882470999938],
              [-35.499288151, -7.498815639999976],
              [-35.499085592, -7.49817221799998],
              [-35.49906806, -7.497850794999974],
              [-35.49906723, -7.497835579999952],
              [-35.499049847, -7.497516880999965],
              [-35.499045178706268, -7.4974874982832],
              [-35.499045178706268, -7.550461434175618],
              [-35.519956244750958, -7.550461434175618],
              [-35.519915346999902, -7.550381809999974],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '900',
        ID: 43729.0,
        COUNTRY: 'Brasil',
        LON: -35.449,
        LAT: -7.5005,
        ANNUAL: 5269.0,
        JAN: 5607.0,
        FEB: 5757.0,
        MAR: 5744.0,
        APR: 5195.0,
        MAY: 4508.0,
        JUN: 4104.0,
        JUL: 4239.0,
        AUG: 5042.0,
        SEP: 5514.0,
        OCT: 5675.0,
        NOV: 5998.0,
        DEC: 5839.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.498847288, -7.496241953999962],
              [-35.498382595, -7.494871703999934],
              [-35.497871922999899, -7.493599665999972],
              [-35.497727257999898, -7.493239319999926],
              [-35.497430078, -7.492142346999978],
              [-35.497342858, -7.49200166199995],
              [-35.497318133, -7.491961780999929],
              [-35.496858851999903, -7.491220961999933],
              [-35.495636423999898, -7.490871696999919],
              [-35.493657255999899, -7.490813485999976],
              [-35.492958726, -7.489969428999925],
              [-35.493118317, -7.488612904999968],
              [-35.4932497799999, -7.487495467999957],
              [-35.494792366999903, -7.485239798999945],
              [-35.494926452999898, -7.483911513999941],
              [-35.494312127999898, -7.482205558999957],
              [-35.491983694999902, -7.480983131999951],
              [-35.490412002, -7.479295017999958],
              [-35.488977927, -7.478205119999933],
              [-35.488956732, -7.478189011999967],
              [-35.486919353, -7.478014378999944],
              [-35.48601638, -7.477224277999943],
              [-35.485987979, -7.477199427999959],
              [-35.484314417999897, -7.477439546999959],
              [-35.482753753999901, -7.478746890999965],
              [-35.480859914999897, -7.478061517999942],
              [-35.48018837, -7.477818488999957],
              [-35.476081848, -7.476230143999945],
              [-35.475575082, -7.475622498999932],
              [-35.474210621999902, -7.473986422999932],
              [-35.4720901439999, -7.47144383199998],
              [-35.471713408, -7.470189420999926],
              [-35.471229552999901, -7.468578337999932],
              [-35.463516234999901, -7.462353228999967],
              [-35.458235966, -7.464109266999967],
              [-35.453811942999899, -7.462595785999954],
              [-35.45036981, -7.453411423999975],
              [-35.448686473, -7.453869563999933],
              [-35.447864952, -7.454228266999964],
              [-35.441572939, -7.456975565999924],
              [-35.438962466, -7.458971430999945],
              [-35.437660302, -7.460553185999972],
              [-35.437073575999896, -7.462591070999962],
              [-35.437570580999903, -7.466763680999976],
              [-35.434412369, -7.470790146999946],
              [-35.434396652, -7.470803651999979],
              [-35.432603442, -7.472344622999971],
              [-35.42982469, -7.47305932699993],
              [-35.42982254, -7.473058265999942],
              [-35.428857187999903, -7.472581867999961],
              [-35.428853267, -7.472581929999927],
              [-35.427516963999899, -7.472603082999979],
              [-35.424714329, -7.474422954999963],
              [-35.424390259999903, -7.474324125999933],
              [-35.422358508999899, -7.47370451399996],
              [-35.421643366, -7.473486420999961],
              [-35.420996895, -7.473464094999939],
              [-35.420871173, -7.473459752999934],
              [-35.420802543, -7.473457987999948],
              [-35.419625126, -7.473416720999989],
              [-35.415737664999902, -7.472155681999935],
              [-35.415732659, -7.472154549999965],
              [-35.414605434999899, -7.471899595999963],
              [-35.4128750839999, -7.470578708999946],
              [-35.409832061, -7.46280200499996],
              [-35.408239349, -7.461132327999959],
              [-35.404242484999898, -7.459844785999958],
              [-35.399988204, -7.457372866999954],
              [-35.399045178706267, -7.457045994654074],
              [-35.399045178706267, -7.550461434175618],
              [-35.499045178706268, -7.550461434175618],
              [-35.499045178706268, -7.4974874982832],
              [-35.498847288, -7.496241953999962],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '901',
        ID: 43730.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -7.5005,
        ANNUAL: 5222.0,
        JAN: 5528.0,
        FEB: 5706.0,
        MAR: 5718.0,
        APR: 5157.0,
        MAY: 4485.0,
        JUN: 4138.0,
        JUL: 4243.0,
        AUG: 4985.0,
        SEP: 5427.0,
        OCT: 5615.0,
        NOV: 5885.0,
        DEC: 5773.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.397233564, -7.456418050999964],
              [-35.392215811, -7.456000618999952],
              [-35.39001482, -7.453229064999962],
              [-35.386584226243691, -7.450461434175619],
              [-35.299045178706265, -7.450461434175619],
              [-35.299045178706265, -7.550461434175618],
              [-35.399045178706267, -7.550461434175618],
              [-35.399045178706267, -7.457045994654074],
              [-35.397233564, -7.456418050999964],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '902',
        ID: 43731.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -7.5005,
        ANNUAL: 5295.0,
        JAN: 5609.0,
        FEB: 5787.0,
        MAR: 5837.0,
        APR: 5208.0,
        MAY: 4553.0,
        JUN: 4143.0,
        JUL: 4251.0,
        AUG: 4969.0,
        SEP: 5496.0,
        OCT: 5718.0,
        NOV: 6033.0,
        DEC: 5935.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.299045178706265, -7.550461434175618],
              [-35.299045178706265, -7.450461434175619],
              [-35.199045178706264, -7.450461434175619],
              [-35.199045178706264, -7.550461434175618],
              [-35.299045178706265, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '903',
        ID: 43732.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -7.5005,
        ANNUAL: 5365.0,
        JAN: 5695.0,
        FEB: 5907.0,
        MAR: 5925.0,
        APR: 5254.0,
        MAY: 4569.0,
        JUN: 4164.0,
        JUL: 4283.0,
        AUG: 5005.0,
        SEP: 5529.0,
        OCT: 5851.0,
        NOV: 6126.0,
        DEC: 6067.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.199045178706264, -7.550461434175618],
              [-35.199045178706264, -7.450461434175619],
              [-35.099045178706263, -7.450461434175619],
              [-35.099045178706263, -7.550461434175618],
              [-35.199045178706264, -7.550461434175618],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '904',
        ID: 43733.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -7.5005,
        ANNUAL: 5382.0,
        JAN: 5734.0,
        FEB: 5986.0,
        MAR: 6008.0,
        APR: 5303.0,
        MAY: 4609.0,
        JUN: 4233.0,
        JUL: 4278.0,
        AUG: 5042.0,
        SEP: 5531.0,
        OCT: 5806.0,
        NOV: 6035.0,
        DEC: 6016.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.00274452, -7.453529395999967],
              [-34.999045178706261, -7.455615544016183],
              [-34.999045178706261, -7.550461434175618],
              [-35.099045178706263, -7.550461434175618],
              [-35.099045178706263, -7.450461434175619],
              [-35.005255913891787, -7.450461434175619],
              [-35.00274452, -7.453529395999967],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '905',
        ID: 43734.0,
        COUNTRY: 'Brasil',
        LON: -34.949,
        LAT: -7.5005,
        ANNUAL: 5410.0,
        JAN: 5746.0,
        FEB: 6018.0,
        MAR: 6004.0,
        APR: 5306.0,
        MAY: 4648.0,
        JUN: 4264.0,
        JUL: 4333.0,
        AUG: 5109.0,
        SEP: 5549.0,
        OCT: 5871.0,
        NOV: 6031.0,
        DEC: 6045.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.996129762, -7.457259617999975],
              [-34.992768254999909, -7.458822844999935],
              [-34.989146854, -7.459769441999979],
              [-34.987030853, -7.461039262999973],
              [-34.985418675999902, -7.462006737999964],
              [-34.985416785, -7.462009440999963],
              [-34.984350111, -7.463533736999973],
              [-34.98384949, -7.466834755999973],
              [-34.98420959, -7.469321254999956],
              [-34.98826446, -7.476165196999943],
              [-34.990178471, -7.488878387999928],
              [-34.9874859, -7.490879496999986],
              [-34.98621186, -7.493419303999934],
              [-34.98542787, -7.498211355999955],
              [-34.985133676, -7.500009585999987],
              [-34.985160388999901, -7.500804855999956],
              [-34.9851619679999, -7.500851857999976],
              [-34.985173275, -7.501186205999944],
              [-34.9852654439999, -7.50391157399997],
              [-34.9842275, -7.507888275999954],
              [-34.983592697999903, -7.508431241999973],
              [-34.979065561, -7.509697464999935],
              [-34.976734844, -7.511990193999965],
              [-34.976393503999908, -7.513586224999919],
              [-34.973479722, -7.516035969999962],
              [-34.972021250999902, -7.518450239999938],
              [-34.96944086, -7.521065455999971],
              [-34.9660448509999, -7.527336394999962],
              [-34.962768894, -7.535575582999975],
              [-34.960786894, -7.538766049999948],
              [-34.959346334, -7.539133100999952],
              [-34.954675016, -7.539043227999973],
              [-34.954671888999897, -7.539042130999976],
              [-34.950620914999902, -7.53762209299991],
              [-34.939539209, -7.530049232999941],
              [-34.93566571, -7.528646888999962],
              [-34.935660255, -7.52864665699998],
              [-34.932702013, -7.528521164999976],
              [-34.932677639, -7.528520133999955],
              [-34.92687907, -7.531011845999959],
              [-34.924447605, -7.53261028199994],
              [-34.923028772999899, -7.533977667999977],
              [-34.919547869, -7.538725283999946],
              [-34.917315750999897, -7.54176967599995],
              [-34.913976671999897, -7.543836481999954],
              [-34.913969397, -7.543835603999988],
              [-34.913918402, -7.543829450999967],
              [-34.911086569, -7.543487768999968],
              [-34.911025917, -7.543443181999969],
              [-34.911018125999902, -7.543437454999944],
              [-34.909868394999897, -7.54259225999993],
              [-34.910017786, -7.541400748999926],
              [-34.910814908, -7.539487048999938],
              [-34.910947141999898, -7.538572796999973],
              [-34.910486259, -7.538136766999973],
              [-34.910334928999902, -7.537994092999948],
              [-34.909446892999902, -7.537637341999927],
              [-34.906551259, -7.538009995999976],
              [-34.904553488, -7.537824380999942],
              [-34.901473424, -7.537146144999947],
              [-34.8998950659999, -7.537430849999954],
              [-34.8994831709999, -7.538650890999976],
              [-34.899680944, -7.539507539999931],
              [-34.901870241, -7.542322890999964],
              [-34.9020424449999, -7.543561982999966],
              [-34.901015152, -7.544751390999975],
              [-34.89904517870626, -7.545118528631102],
              [-34.89904517870626, -7.550461434175618],
              [-34.999045178706261, -7.550461434175618],
              [-34.999045178706261, -7.455615544016183],
              [-34.996129762, -7.457259617999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '906',
        ID: 43735.0,
        COUNTRY: 'Brasil',
        LON: -34.849,
        LAT: -7.5005,
        ANNUAL: 5504.0,
        JAN: 5810.0,
        FEB: 6081.0,
        MAR: 6054.0,
        APR: 5352.0,
        MAY: 4782.0,
        JUN: 4366.0,
        JUL: 4423.0,
        AUG: 5242.0,
        SEP: 5668.0,
        OCT: 6037.0,
        NOV: 6073.0,
        DEC: 6164.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.897960842, -7.545320612999933],
              [-34.895542234999901, -7.545074557999948],
              [-34.894068701, -7.543333778999966],
              [-34.891372791, -7.537241072999952],
              [-34.888738078, -7.535904403999957],
              [-34.888733515, -7.535905883999924],
              [-34.887747165, -7.536225949999964],
              [-34.887211814, -7.536399667999931],
              [-34.884177716999901, -7.539456570999964],
              [-34.881544807999902, -7.540711415999961],
              [-34.875734473, -7.541852505999937],
              [-34.873593946, -7.543247721999928],
              [-34.871823105, -7.548689767999977],
              [-34.870716064, -7.549158484999946],
              [-34.86739219, -7.547959470999962],
              [-34.866289991, -7.546750613999959],
              [-34.866036462999901, -7.54598527499995],
              [-34.865188095, -7.544400984999982],
              [-34.8580230909999, -7.537950454999931],
              [-34.85384178, -7.539645939999965],
              [-34.849287003, -7.541494771999961],
              [-34.840358482999903, -7.543837290999926],
              [-34.838593563, -7.544300341999928],
              [-34.835871797, -7.545728385999948],
              [-34.834093192999902, -7.548324150999963],
              [-34.833880631999897, -7.548634371999974],
              [-34.833304977999902, -7.549918274999924],
              [-34.83291288, -7.550383210999947],
              [-34.833114325620336, -7.550461434175618],
              [-34.89904517870626, -7.550461434175618],
              [-34.89904517870626, -7.545118528631102],
              [-34.897960842, -7.545320612999933],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '907',
        ID: 44070.0,
        COUNTRY: 'Brasil',
        LON: -40.649,
        LAT: -7.4005,
        ANNUAL: 5768.0,
        JAN: 5540.0,
        FEB: 5492.0,
        MAR: 5452.0,
        APR: 5345.0,
        MAY: 5223.0,
        JUN: 5184.0,
        JUL: 5515.0,
        AUG: 6066.0,
        SEP: 6530.0,
        OCT: 6513.0,
        NOV: 6370.0,
        DEC: 5980.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.663430993, -7.449432080999939],
              [-40.663399277999901, -7.449410975999969],
              [-40.662542022, -7.448197875999934],
              [-40.662534144999903, -7.44818672999997],
              [-40.662467717, -7.448092727999946],
              [-40.662466932, -7.448091618999966],
              [-40.66246574, -7.44808993099997],
              [-40.662464096999905, -7.448087605999929],
              [-40.662462578, -7.448085456999934],
              [-40.662461025, -7.448083259999978],
              [-40.662459118999898, -7.448080560999933],
              [-40.662457489, -7.448078254999929],
              [-40.66245588, -7.448075978999952],
              [-40.662454533, -7.448074071999941],
              [-40.662452725, -7.448070402999975],
              [-40.662451506, -7.448067927999937],
              [-40.662450376, -7.448065634999982],
              [-40.662449185999897, -7.448063220999936],
              [-40.662448158, -7.448061135999983],
              [-40.662447088, -7.448058963999929],
              [-40.662446057, -7.448056870999941],
              [-40.662444997, -7.448054719999962],
              [-40.6624436439999, -7.44805197399995],
              [-40.662442755, -7.448050170999985],
              [-40.662441311, -7.448047239999942],
              [-40.662440153, -7.448044889999945],
              [-40.662438892, -7.448042330999951],
              [-40.662437586999907, -7.448039682999934],
              [-40.662436235, -7.448036939999941],
              [-40.662435006, -7.448034444999965],
              [-40.662416895, -7.447997689999975],
              [-40.662364088999901, -7.447890524999925],
              [-40.662318958, -7.447798935999962],
              [-40.662212811, -7.447583518999985],
              [-40.662152754, -7.447461637999936],
              [-40.662151501, -7.447459095999934],
              [-40.661994899, -7.447228797999975],
              [-40.661848469999903, -7.447013461999974],
              [-40.661847542, -7.447011360999965],
              [-40.661846614999902, -7.447009260999953],
              [-40.661711002999901, -7.446702138999966],
              [-40.661612292, -7.446478588999979],
              [-40.661545438, -7.446327183999925],
              [-40.661528608, -7.446298033999946],
              [-40.661511776999902, -7.446268882999959],
              [-40.661384583, -7.446173190999955],
              [-40.661342389, -7.446086623999963],
              [-40.661067220999897, -7.445522082999963],
              [-40.661059818999902, -7.445506896999964],
              [-40.66103363, -7.445453166999932],
              [-40.660703515, -7.444876488999972],
              [-40.660605059, -7.444704495999938],
              [-40.660603465999898, -7.444701711999934],
              [-40.660572052, -7.444646834999959],
              [-40.660253941, -7.444188947999979],
              [-40.659919249, -7.443707193999964],
              [-40.659792624, -7.443524930999964],
              [-40.659743566, -7.443463801999932],
              [-40.659662247, -7.443362473999976],
              [-40.659633636, -7.443346499999964],
              [-40.659499953, -7.443162192999976],
              [-40.659319592, -7.442913533999954],
              [-40.659266164, -7.442839873999965],
              [-40.6592445369999, -7.442810057999956],
              [-40.658902554, -7.442306094999942],
              [-40.658891678, -7.442290066999924],
              [-40.658886944, -7.44228309099998],
              [-40.658873784, -7.442263697999972],
              [-40.658869833, -7.442257875999931],
              [-40.658538818, -7.441770076999937],
              [-40.658492199, -7.441694435999977],
              [-40.658153534, -7.441144942999931],
              [-40.657776549999909, -7.440593107999968],
              [-40.657749441, -7.44055342599995],
              [-40.657746614, -7.440549287999938],
              [-40.657718658, -7.440508364999967],
              [-40.657219727, -7.439870936999979],
              [-40.657165527, -7.439801692999944],
              [-40.656703949, -7.439358233999945],
              [-40.656143187999902, -7.439026831999967],
              [-40.655932499999899, -7.438929680999935],
              [-40.655814897, -7.438875452999982],
              [-40.655764851999898, -7.438852376999937],
              [-40.655594602999898, -7.438773872999944],
              [-40.655456543, -7.438710211999929],
              [-40.65414116999991, -7.438121997999925],
              [-40.65405577, -7.438083807999934],
              [-40.652323226, -7.433342164999932],
              [-40.639567359, -7.428349392999957],
              [-40.599045178706341, -7.41248858970746],
              [-40.599045178706341, -7.450461434175619],
              [-40.664977831320137, -7.450461434175619],
              [-40.663430993, -7.449432080999939],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '908',
        ID: 44071.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -7.4005,
        ANNUAL: 5722.0,
        JAN: 5493.0,
        FEB: 5430.0,
        MAR: 5404.0,
        APR: 5241.0,
        MAY: 5097.0,
        JUN: 5067.0,
        JUL: 5385.0,
        AUG: 6142.0,
        SEP: 6565.0,
        OCT: 6513.0,
        NOV: 6365.0,
        DEC: 5966.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.597794947, -7.411999235999919],
              [-40.589242657999897, -7.408651780999958],
              [-40.589161030999897, -7.408619836999954],
              [-40.587321511, -7.407899936999975],
              [-40.548463712999897, -7.392691465999966],
              [-40.542670481999899, -7.360373092999957],
              [-40.541310489377764, -7.350461434175619],
              [-40.499045178706339, -7.350461434175619],
              [-40.499045178706339, -7.450461434175619],
              [-40.599045178706341, -7.450461434175619],
              [-40.599045178706341, -7.41248858970746],
              [-40.597794947, -7.411999235999919],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '909',
        ID: 44072.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -7.4005,
        ANNUAL: 5697.0,
        JAN: 5537.0,
        FEB: 5424.0,
        MAR: 5405.0,
        APR: 5210.0,
        MAY: 5020.0,
        JUN: 4961.0,
        JUL: 5311.0,
        AUG: 6126.0,
        SEP: 6563.0,
        OCT: 6504.0,
        NOV: 6345.0,
        DEC: 5962.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.466737117, -7.350467591999973],
              [-40.45930838, -7.354951707999923],
              [-40.459073063999909, -7.355093748999936],
              [-40.449055047999899, -7.361125528999931],
              [-40.448672319, -7.361275337999929],
              [-40.447942188, -7.361561126999963],
              [-40.433997631, -7.367019339999961],
              [-40.433591706999898, -7.367178227999941],
              [-40.43358491, -7.367178643999972],
              [-40.411026141, -7.368558363999968],
              [-40.400349563, -7.368532298999974],
              [-40.399045178706338, -7.368458948466698],
              [-40.399045178706338, -7.450461434175619],
              [-40.499045178706339, -7.450461434175619],
              [-40.499045178706339, -7.350461434175619],
              [-40.466747318532008, -7.350461434175619],
              [-40.466737117, -7.350467591999973],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '910',
        ID: 44073.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -7.4005,
        ANNUAL: 5677.0,
        JAN: 5544.0,
        FEB: 5419.0,
        MAR: 5381.0,
        APR: 5217.0,
        MAY: 5005.0,
        JUN: 4919.0,
        JUL: 5256.0,
        AUG: 6097.0,
        SEP: 6554.0,
        OCT: 6497.0,
        NOV: 6314.0,
        DEC: 5918.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.393878139, -7.368168385999975],
              [-40.387480495, -7.367034784999936],
              [-40.374522269, -7.362987701999947],
              [-40.372769567, -7.362440374999945],
              [-40.360983897, -7.355600699999974],
              [-40.358223607, -7.352492069999924],
              [-40.357145972928087, -7.350461434175619],
              [-40.299045178706336, -7.350461434175619],
              [-40.299045178706336, -7.450461434175619],
              [-40.399045178706338, -7.450461434175619],
              [-40.399045178706338, -7.368458948466698],
              [-40.393878139, -7.368168385999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '911',
        ID: 44074.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -7.4005,
        ANNUAL: 5669.0,
        JAN: 5560.0,
        FEB: 5416.0,
        MAR: 5422.0,
        APR: 5181.0,
        MAY: 4966.0,
        JUN: 4848.0,
        JUL: 5180.0,
        AUG: 6062.0,
        SEP: 6586.0,
        OCT: 6484.0,
        NOV: 6364.0,
        DEC: 5960.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.199045178706335, -7.355903298828632],
              [-40.199045178706335, -7.450461434175619],
              [-40.299045178706336, -7.450461434175619],
              [-40.299045178706336, -7.350461434175619],
              [-40.207189603692321, -7.350461434175619],
              [-40.199045178706335, -7.355903298828632],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '912',
        ID: 44075.0,
        COUNTRY: 'Brasil',
        LON: -40.149,
        LAT: -7.4005,
        ANNUAL: 5649.0,
        JAN: 5531.0,
        FEB: 5408.0,
        MAR: 5412.0,
        APR: 5156.0,
        MAY: 4917.0,
        JUN: 4838.0,
        JUL: 5177.0,
        AUG: 6071.0,
        SEP: 6619.0,
        OCT: 6474.0,
        NOV: 6303.0,
        DEC: 5882.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.195684671, -7.358148690999936],
              [-40.187652978, -7.361207379999934],
              [-40.187638727, -7.361210928999983],
              [-40.180499650999899, -7.362988933999928],
              [-40.180469069, -7.363000020999942],
              [-40.171026774, -7.36642315999995],
              [-40.161817923999898, -7.369582630999957],
              [-40.157133418999898, -7.371456986999936],
              [-40.15094585, -7.373992697999935],
              [-40.137651698999903, -7.379440736999917],
              [-40.137632552, -7.379445523999956],
              [-40.127754320999898, -7.38191498999993],
              [-40.110006817999903, -7.382547248999976],
              [-40.099045178706334, -7.382812545840379],
              [-40.099045178706334, -7.450461434175619],
              [-40.199045178706335, -7.450461434175619],
              [-40.199045178706335, -7.355903298828632],
              [-40.195684671, -7.358148690999936],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '913',
        ID: 44076.0,
        COUNTRY: 'Brasil',
        LON: -40.049,
        LAT: -7.4005,
        ANNUAL: 5647.0,
        JAN: 5556.0,
        FEB: 5461.0,
        MAR: 5436.0,
        APR: 5135.0,
        MAY: 4904.0,
        JUN: 4787.0,
        JUL: 5147.0,
        AUG: 6016.0,
        SEP: 6606.0,
        OCT: 6489.0,
        NOV: 6325.0,
        DEC: 5900.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.087454495, -7.383093066999947],
              [-40.076450633, -7.382825740999986],
              [-40.060366184, -7.381688692999938],
              [-40.057307769, -7.381533237999976],
              [-40.057159618, -7.381525707999972],
              [-40.051146785999897, -7.381220083999946],
              [-40.045163712, -7.380744980999952],
              [-40.014538828, -7.376504340999923],
              [-40.009089531, -7.375567322999922],
              [-40.006102290999898, -7.375053659999933],
              [-40.000707028, -7.374478339999936],
              [-39.999045178706332, -7.373744866168033],
              [-39.999045178706332, -7.450461434175619],
              [-40.099045178706334, -7.450461434175619],
              [-40.099045178706334, -7.382812545840379],
              [-40.087454495, -7.383093066999947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '914',
        ID: 44077.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -7.4005,
        ANNUAL: 5690.0,
        JAN: 5623.0,
        FEB: 5545.0,
        MAR: 5527.0,
        APR: 5251.0,
        MAY: 4948.0,
        JUN: 4753.0,
        JUL: 5113.0,
        AUG: 6005.0,
        SEP: 6574.0,
        OCT: 6535.0,
        NOV: 6384.0,
        DEC: 6025.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.995406386999903, -7.372138848999943],
              [-39.994031132, -7.370770222999961],
              [-39.975971120999901, -7.363462374999926],
              [-39.9613860219999, -7.357078558999936],
              [-39.95294568, -7.35386545399995],
              [-39.946591511649238, -7.350461434175619],
              [-39.899045178706331, -7.350461434175619],
              [-39.899045178706331, -7.450461434175619],
              [-39.999045178706332, -7.450461434175619],
              [-39.999045178706332, -7.373744866168033],
              [-39.995406386999903, -7.372138848999943],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '915',
        ID: 44078.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -7.4005,
        ANNUAL: 5671.0,
        JAN: 5664.0,
        FEB: 5539.0,
        MAR: 5530.0,
        APR: 5222.0,
        MAY: 4868.0,
        JUN: 4689.0,
        JUL: 5053.0,
        AUG: 5970.0,
        SEP: 6551.0,
        OCT: 6534.0,
        NOV: 6392.0,
        DEC: 6038.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.899045178706331, -7.450461434175619],
              [-39.899045178706331, -7.350461434175619],
              [-39.799045178706329, -7.350461434175619],
              [-39.799045178706329, -7.450461434175619],
              [-39.899045178706331, -7.450461434175619],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '916',
        ID: 44079.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -7.4005,
        ANNUAL: 5642.0,
        JAN: 5686.0,
        FEB: 5515.0,
        MAR: 5484.0,
        APR: 5188.0,
        MAY: 4793.0,
        JUN: 4653.0,
        JUL: 5004.0,
        AUG: 5905.0,
        SEP: 6535.0,
        OCT: 6515.0,
        NOV: 6405.0,
        DEC: 6018.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.799045178706329, -7.450461434175619],
              [-39.799045178706329, -7.350461434175619],
              [-39.699045178706328, -7.350461434175619],
              [-39.699045178706328, -7.450461434175619],
              [-39.799045178706329, -7.450461434175619],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '917',
        ID: 44080.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -7.4005,
        ANNUAL: 5638.0,
        JAN: 5622.0,
        FEB: 5506.0,
        MAR: 5467.0,
        APR: 5151.0,
        MAY: 4802.0,
        JUN: 4683.0,
        JUL: 4982.0,
        AUG: 5900.0,
        SEP: 6538.0,
        OCT: 6495.0,
        NOV: 6459.0,
        DEC: 6048.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.671348436, -7.358537169999982],
              [-39.65308842, -7.372262173999959],
              [-39.645718304, -7.380878057999952],
              [-39.643894761, -7.385341664999934],
              [-39.63961928, -7.395804765999975],
              [-39.639422795, -7.396201754999939],
              [-39.639296999, -7.396455918999955],
              [-39.638113788999902, -7.398846538999988],
              [-39.634154969999898, -7.406845141999948],
              [-39.631353929999896, -7.415271521999951],
              [-39.630132412, -7.421941635999933],
              [-39.628907180999903, -7.425628510999957],
              [-39.626624579, -7.427035212999956],
              [-39.626608041999901, -7.427034367999965],
              [-39.623284016, -7.426864507999936],
              [-39.615547089, -7.425646759999942],
              [-39.608688195999903, -7.423726642999946],
              [-39.605524159, -7.424081643999951],
              [-39.60550949, -7.424092249999945],
              [-39.599201885, -7.42865257099993],
              [-39.599045178706326, -7.428747217432863],
              [-39.599045178706326, -7.450461434175619],
              [-39.699045178706328, -7.450461434175619],
              [-39.699045178706328, -7.350461434175619],
              [-39.684830962506254, -7.350461434175619],
              [-39.671348436, -7.358537169999982],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '918',
        ID: 44081.0,
        COUNTRY: 'Brasil',
        LON: -39.549,
        LAT: -7.4005,
        ANNUAL: 5668.0,
        JAN: 5693.0,
        FEB: 5582.0,
        MAR: 5529.0,
        APR: 5185.0,
        MAY: 4830.0,
        JUN: 4678.0,
        JUL: 4985.0,
        AUG: 5886.0,
        SEP: 6517.0,
        OCT: 6441.0,
        NOV: 6547.0,
        DEC: 6135.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.59307791, -7.43235128899994],
              [-39.587977608999907, -7.432357335999949],
              [-39.583414699999899, -7.429970561999943],
              [-39.576533888, -7.427895018999942],
              [-39.575451524999899, -7.427818812999931],
              [-39.573057553999902, -7.428671421999982],
              [-39.571590504, -7.429831282999932],
              [-39.57127453, -7.430745850999936],
              [-39.57028157, -7.433616185999933],
              [-39.569057302, -7.438009182999958],
              [-39.5660177829999, -7.44891577899996],
              [-39.565587030181952, -7.450461434175619],
              [-39.599045178706326, -7.450461434175619],
              [-39.599045178706326, -7.428747217432863],
              [-39.59307791, -7.43235128899994],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '919',
        ID: 44101.0,
        COUNTRY: 'Brasil',
        LON: -37.549,
        LAT: -7.4005,
        ANNUAL: 5885.0,
        JAN: 6097.0,
        FEB: 5992.0,
        MAR: 6033.0,
        APR: 5764.0,
        MAY: 5097.0,
        JUN: 4727.0,
        JUL: 5054.0,
        AUG: 5814.0,
        SEP: 6509.0,
        OCT: 6570.0,
        NOV: 6670.0,
        DEC: 6296.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.527404088, -7.44838243399994],
              [-37.527226785, -7.448190566999923],
              [-37.522957215, -7.443570280999951],
              [-37.521706305, -7.43947832899994],
              [-37.519730291, -7.436659185999935],
              [-37.51417542, -7.433034309999928],
              [-37.514207907, -7.429134408999976],
              [-37.515639837, -7.42755745099993],
              [-37.510668614999901, -7.42052552399996],
              [-37.506951850999897, -7.419748923999979],
              [-37.505694443, -7.41915997699994],
              [-37.502644518999901, -7.412943563999924],
              [-37.50134601, -7.411462302999941],
              [-37.500340781, -7.408593029999952],
              [-37.500205943, -7.408387126999975],
              [-37.499534776, -7.407362224999932],
              [-37.499045178706297, -7.406614588912669],
              [-37.499045178706297, -7.450461434175619],
              [-37.528006317055187, -7.450461434175619],
              [-37.527404088, -7.44838243399994],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '920',
        ID: 44102.0,
        COUNTRY: 'Brasil',
        LON: -37.449,
        LAT: -7.4005,
        ANNUAL: 5865.0,
        JAN: 6101.0,
        FEB: 6012.0,
        MAR: 6009.0,
        APR: 5739.0,
        MAY: 5052.0,
        JUN: 4658.0,
        JUL: 4996.0,
        AUG: 5794.0,
        SEP: 6477.0,
        OCT: 6563.0,
        NOV: 6680.0,
        DEC: 6301.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.4969817429999, -7.403463633999934],
              [-37.494784868000011, -7.400109654999938],
              [-37.494674104, -7.399022774999934],
              [-37.494672329, -7.399021861999928],
              [-37.494512173, -7.398939496999959],
              [-37.486957851999897, -7.395054462999945],
              [-37.487626196, -7.389419525999924],
              [-37.4919064589999, -7.386302069999934],
              [-37.492428792999902, -7.382586894999979],
              [-37.4980949609999, -7.371128575999935],
              [-37.498039525, -7.366842633999976],
              [-37.496038045, -7.364822268999937],
              [-37.486308809, -7.362138845999945],
              [-37.485854955, -7.362061175999942],
              [-37.485190293, -7.361947058999926],
              [-37.483236177, -7.361611554999977],
              [-37.482237751999897, -7.361440134999956],
              [-37.479170971, -7.360049601999942],
              [-37.478704126, -7.359978916999983],
              [-37.46592214, -7.358043609999976],
              [-37.45228542, -7.360288501999944],
              [-37.450139801, -7.360066438999977],
              [-37.448569463999902, -7.360655497999971],
              [-37.447549663, -7.361038042999957],
              [-37.444753407, -7.360676229999964],
              [-37.443107181999899, -7.360463220999972],
              [-37.43887784, -7.359915977999956],
              [-37.433700062999897, -7.358698127999955],
              [-37.432317639, -7.357444466999936],
              [-37.432127511, -7.357272010999965],
              [-37.431822259999898, -7.356656260999959],
              [-37.430338252999903, -7.353662729999946],
              [-37.428762634999899, -7.352567715999943],
              [-37.427609541, -7.352691356999969],
              [-37.425512354, -7.352916227999961],
              [-37.415474877999898, -7.361719476999942],
              [-37.412651925, -7.361873576999933],
              [-37.410317171, -7.360736739999947],
              [-37.408140054999905, -7.358104249999933],
              [-37.406877827, -7.358134619999939],
              [-37.406712145, -7.35813860599995],
              [-37.401926784999908, -7.359022160999932],
              [-37.399045178706295, -7.358867499017485],
              [-37.399045178706295, -7.450461434175619],
              [-37.499045178706297, -7.450461434175619],
              [-37.499045178706297, -7.406614588912669],
              [-37.4969817429999, -7.403463633999934],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '921',
        ID: 44103.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -7.4005,
        ANNUAL: 5840.0,
        JAN: 6127.0,
        FEB: 6041.0,
        MAR: 6052.0,
        APR: 5696.0,
        MAY: 5018.0,
        JUN: 4621.0,
        JUL: 4914.0,
        AUG: 5700.0,
        SEP: 6420.0,
        OCT: 6522.0,
        NOV: 6685.0,
        DEC: 6284.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.395394998, -7.358671585999952],
              [-37.385713734, -7.356693826999957],
              [-37.37241562, -7.350561195999945],
              [-37.372242844258146, -7.350461434175619],
              [-37.299045178706294, -7.350461434175619],
              [-37.299045178706294, -7.450461434175619],
              [-37.399045178706295, -7.450461434175619],
              [-37.399045178706295, -7.358867499017485],
              [-37.395394998, -7.358671585999952],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '922',
        ID: 44104.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -7.4005,
        ANNUAL: 5793.0,
        JAN: 6111.0,
        FEB: 6011.0,
        MAR: 6028.0,
        APR: 5666.0,
        MAY: 4973.0,
        JUN: 4546.0,
        JUL: 4824.0,
        AUG: 5602.0,
        SEP: 6341.0,
        OCT: 6506.0,
        NOV: 6644.0,
        DEC: 6260.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.299045178706294, -7.450461434175619],
              [-37.299045178706294, -7.350461434175619],
              [-37.199045178706292, -7.350461434175619],
              [-37.199045178706292, -7.450461434175619],
              [-37.299045178706294, -7.450461434175619],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '923',
        ID: 44105.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -7.4005,
        ANNUAL: 5754.0,
        JAN: 6078.0,
        FEB: 5987.0,
        MAR: 6022.0,
        APR: 5652.0,
        MAY: 4930.0,
        JUN: 4522.0,
        JUL: 4766.0,
        AUG: 5522.0,
        SEP: 6279.0,
        OCT: 6461.0,
        NOV: 6589.0,
        DEC: 6236.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.121563964, -7.350914280999978],
              [-37.113787198999901, -7.351292872999988],
              [-37.106704018, -7.353890596999979],
              [-37.101862486999899, -7.356630738999966],
              [-37.099045178706291, -7.354970178794001],
              [-37.099045178706291, -7.450461434175619],
              [-37.199045178706292, -7.450461434175619],
              [-37.199045178706292, -7.350461434175619],
              [-37.121984182078556, -7.350461434175619],
              [-37.121563964, -7.350914280999978],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '924',
        ID: 44106.0,
        COUNTRY: 'Brasil',
        LON: -37.049,
        LAT: -7.4005,
        ANNUAL: 5731.0,
        JAN: 6074.0,
        FEB: 5991.0,
        MAR: 6030.0,
        APR: 5641.0,
        MAY: 4947.0,
        JUN: 4482.0,
        JUL: 4717.0,
        AUG: 5441.0,
        SEP: 6236.0,
        OCT: 6422.0,
        NOV: 6567.0,
        DEC: 6228.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.0973458039999, -7.353968543999946],
              [-37.092145525, -7.357381225999978],
              [-37.085622682999904, -7.358256355999961],
              [-37.082813447, -7.362403279999964],
              [-37.081397056, -7.363534256999968],
              [-37.079217266999898, -7.365274223999966],
              [-37.0729492789999, -7.368275391999929],
              [-37.070714259999903, -7.374254652999986],
              [-37.070324271999901, -7.380215825999923],
              [-37.069430881, -7.382383882999935],
              [-37.06741314, -7.383379996999953],
              [-37.067411262999897, -7.383379484999982],
              [-37.054260525, -7.379791765999924],
              [-37.053184657, -7.379498252999945],
              [-37.049988179, -7.377588449999966],
              [-37.04998671499991, -7.377589097999934],
              [-37.048809334999902, -7.378110183999985],
              [-37.048214199, -7.378146647999938],
              [-37.041476106999902, -7.381354084999936],
              [-37.039468217, -7.381727629999944],
              [-37.031077888, -7.385617696999925],
              [-37.02852333, -7.385817425999961],
              [-37.027923621, -7.385864313999951],
              [-37.026972375, -7.385938687999952],
              [-37.025623399, -7.386044157999952],
              [-37.022538988, -7.392727782999931],
              [-37.019473143, -7.394323350999972],
              [-37.016821743, -7.401670220999957],
              [-37.013701438999902, -7.403792545999976],
              [-37.013360491, -7.404024216999963],
              [-37.012971206, -7.404288732999929],
              [-37.018369323999899, -7.410867553999942],
              [-37.023693325999901, -7.415807568999979],
              [-37.025973978, -7.422450915999947],
              [-37.025978065999901, -7.42246282499997],
              [-37.02205973, -7.423445250999977],
              [-37.017564492, -7.424572677999943],
              [-37.0145081759999, -7.430368145999982],
              [-37.02361568, -7.433867463999951],
              [-37.022800505, -7.436027214999972],
              [-37.021225843, -7.440196954999977],
              [-37.013177251999899, -7.44498371999992],
              [-37.011628422464106, -7.450461434175619],
              [-37.099045178706291, -7.450461434175619],
              [-37.099045178706291, -7.354970178794001],
              [-37.0973458039999, -7.353968543999946],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '925',
        ID: 44123.0,
        COUNTRY: 'Brasil',
        LON: -35.349,
        LAT: -7.4005,
        ANNUAL: 5362.0,
        JAN: 5663.0,
        FEB: 5855.0,
        MAR: 5843.0,
        APR: 5300.0,
        MAY: 4636.0,
        JUN: 4239.0,
        JUL: 4343.0,
        AUG: 5135.0,
        SEP: 5571.0,
        OCT: 5792.0,
        NOV: 6042.0,
        DEC: 5921.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.385495455999902, -7.449583068999967],
              [-35.382631898999897, -7.449129719999977],
              [-35.380687726, -7.448822351999974],
              [-35.380683983, -7.448822401999958],
              [-35.375960031, -7.448885232999948],
              [-35.373270251, -7.443647688999923],
              [-35.370548223999897, -7.441595264999925],
              [-35.368954429, -7.440802286999941],
              [-35.368351657999902, -7.440631997999954],
              [-35.368347631999903, -7.440632399999972],
              [-35.365741734, -7.440892450999964],
              [-35.365465690999898, -7.440920029999973],
              [-35.362906543, -7.442798215999968],
              [-35.362903065999902, -7.442797264999973],
              [-35.358184654, -7.441506931999984],
              [-35.3581802429999, -7.441507204999942],
              [-35.355405350999902, -7.441678707999931],
              [-35.355402977, -7.441678301999957],
              [-35.352991629, -7.44126571499993],
              [-35.351103357999897, -7.440261473999956],
              [-35.349688402, -7.438800642999972],
              [-35.348598588999899, -7.434941650999961],
              [-35.351513651999902, -7.429065385999933],
              [-35.352632499, -7.424162072999932],
              [-35.352355866, -7.422397656999987],
              [-35.351234173, -7.421902307999968],
              [-35.351229606999901, -7.421902835999959],
              [-35.348150470999897, -7.422259090999941],
              [-35.346266327, -7.422739390999937],
              [-35.346261195999901, -7.422738830999958],
              [-35.34383174700001, -7.422473680999926],
              [-35.342964907, -7.422156788999931],
              [-35.342621560999902, -7.422031271999963],
              [-35.341017043, -7.421444705999934],
              [-35.3386311249999, -7.421308227999921],
              [-35.333517897, -7.422756658999954],
              [-35.333517085, -7.422754840999971],
              [-35.331907446999899, -7.419148135999936],
              [-35.332893522999903, -7.415332944999929],
              [-35.332987547, -7.413302278999944],
              [-35.335042223, -7.410864046999956],
              [-35.335020606999898, -7.406803897999954],
              [-35.330830702, -7.406604261999965],
              [-35.327541932, -7.410108401999929],
              [-35.324098267, -7.411320060999963],
              [-35.324093145999903, -7.411319380999938],
              [-35.320961342, -7.410903443999932],
              [-35.319320220999899, -7.410053857999968],
              [-35.317826543, -7.406946419999942],
              [-35.316156931, -7.405689933999949],
              [-35.316151155999897, -7.405690102999934],
              [-35.315070308, -7.405721682999973],
              [-35.3140412939999, -7.405752],
              [-35.312158044999897, -7.406744386999949],
              [-35.311102687, -7.408075269999931],
              [-35.309048598999901, -7.409043879999964],
              [-35.309044669999899, -7.40904249299997],
              [-35.306957498, -7.408305319999948],
              [-35.306516626, -7.407916330999979],
              [-35.301874371999901, -7.403810014999974],
              [-35.299105213, -7.403152774999971],
              [-35.299045178706265, -7.403119646460727],
              [-35.299045178706265, -7.450461434175619],
              [-35.386584226243691, -7.450461434175619],
              [-35.385495455999902, -7.449583068999967],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '926',
        ID: 44124.0,
        COUNTRY: 'Brasil',
        LON: -35.249,
        LAT: -7.4005,
        ANNUAL: 5385.0,
        JAN: 5697.0,
        FEB: 5882.0,
        MAR: 5872.0,
        APR: 5327.0,
        MAY: 4635.0,
        JUN: 4244.0,
        JUL: 4374.0,
        AUG: 5091.0,
        SEP: 5602.0,
        OCT: 5818.0,
        NOV: 6102.0,
        DEC: 5973.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.295882708, -7.401374509999925],
              [-35.290800223, -7.397550943999923],
              [-35.284710005, -7.391264987999935],
              [-35.280175749, -7.387655348999934],
              [-35.276628784, -7.384918954999933],
              [-35.272881025999901, -7.383108107999926],
              [-35.264393492, -7.379908575999931],
              [-35.257307400999899, -7.378210649999973],
              [-35.249104948, -7.374941898999961],
              [-35.248594676, -7.374779551999942],
              [-35.246538309, -7.37552028699997],
              [-35.246493479, -7.375536434999963],
              [-35.246233654, -7.375943117999953],
              [-35.24603782, -7.375950797999949],
              [-35.244410804, -7.376014601999934],
              [-35.242200711, -7.376101272999968],
              [-35.241933157999902, -7.376004845999936],
              [-35.240607941, -7.375527229999927],
              [-35.240223779, -7.375388774999973],
              [-35.239855867, -7.37554542499992],
              [-35.238753480999897, -7.376014800999945],
              [-35.238568133999898, -7.376093717999931],
              [-35.237331809, -7.376620121999954],
              [-35.235925994, -7.377045637999963],
              [-35.235696355, -7.377115145999935],
              [-35.2346847049999, -7.377421353999984],
              [-35.234433123, -7.377507761999948],
              [-35.233400537, -7.377862764999964],
              [-35.230759238, -7.378190158999928],
              [-35.2293110049999, -7.378369669999921],
              [-35.228962112, -7.378436325999929],
              [-35.227356600999897, -7.37874306099996],
              [-35.224574533, -7.379274577999976],
              [-35.22195971, -7.380193641999939],
              [-35.219232557999902, -7.380582785999938],
              [-35.216741548, -7.380938233999935],
              [-35.209325754, -7.385497666999974],
              [-35.200102608999899, -7.387606097999941],
              [-35.199552805999907, -7.387731782999943],
              [-35.199045178706264, -7.388416112109494],
              [-35.199045178706264, -7.450461434175619],
              [-35.299045178706265, -7.450461434175619],
              [-35.299045178706265, -7.403119646460727],
              [-35.295882708, -7.401374509999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '927',
        ID: 44125.0,
        COUNTRY: 'Brasil',
        LON: -35.149,
        LAT: -7.4005,
        ANNUAL: 5403.0,
        JAN: 5740.0,
        FEB: 5940.0,
        MAR: 5925.0,
        APR: 5335.0,
        MAY: 4628.0,
        JUN: 4243.0,
        JUL: 4356.0,
        AUG: 5058.0,
        SEP: 5592.0,
        OCT: 5849.0,
        NOV: 6132.0,
        DEC: 6035.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.196404083999902, -7.391976554999932],
              [-35.195156578, -7.395637477999963],
              [-35.1939889659999, -7.396792713999968],
              [-35.190741325, -7.397891204999942],
              [-35.190738506999899, -7.397889417999981],
              [-35.189744262, -7.397258814999939],
              [-35.189409114999897, -7.397046246999922],
              [-35.186215411, -7.395020630999974],
              [-35.185889742, -7.39499384699997],
              [-35.184157571, -7.394851389999932],
              [-35.180806869, -7.395901841999944],
              [-35.180804252, -7.395900420999954],
              [-35.173911449999899, -7.392156333999934],
              [-35.1720666699999, -7.388040369999945],
              [-35.172060386999902, -7.388042213999934],
              [-35.158503261, -7.392020469999979],
              [-35.153788768, -7.39245671199996],
              [-35.153735082, -7.392729439999926],
              [-35.15058758, -7.393789649999962],
              [-35.148380232, -7.394533175999926],
              [-35.144244683999901, -7.395925313999955],
              [-35.144238393, -7.39592588899995],
              [-35.144045697, -7.395943518999962],
              [-35.134863297, -7.396783632999925],
              [-35.132734608, -7.398713468999963],
              [-35.129827737, -7.400423639999925],
              [-35.128276989, -7.401300382999985],
              [-35.127994332999897, -7.401460187999938],
              [-35.127602, -7.401682],
              [-35.127413069999903, -7.401676138999957],
              [-35.127054, -7.401665],
              [-35.126569, -7.401616],
              [-35.125906439999902, -7.401344435999929],
              [-35.125192762, -7.401070536999948],
              [-35.124884144, -7.401035817999974],
              [-35.12458975, -7.40110392399993],
              [-35.123934049999903, -7.401255615999955],
              [-35.123359992, -7.401388419999921],
              [-35.12335825, -7.401388823999974],
              [-35.123333339999903, -7.401394585999972],
              [-35.122318758999903, -7.40200024799998],
              [-35.121462344999905, -7.402690780999929],
              [-35.119602922, -7.403963828999967],
              [-35.118173, -7.403549],
              [-35.117747064999897, -7.403573722999965],
              [-35.116343522, -7.403655192999953],
              [-35.11618312, -7.403677452999937],
              [-35.115963, -7.403708],
              [-35.114916999999899, -7.403965],
              [-35.114464365999901, -7.404087490999933],
              [-35.113876456, -7.404247265999968],
              [-35.113869931, -7.404249038999986],
              [-35.113107, -7.404456],
              [-35.112080999999897, -7.404696],
              [-35.111117, -7.404716],
              [-35.110172, -7.404977],
              [-35.110121278, -7.405025488999977],
              [-35.110013, -7.405129],
              [-35.109653, -7.405182],
              [-35.109045, -7.40531],
              [-35.108488, -7.405449],
              [-35.107964, -7.405526],
              [-35.106992997, -7.405517227999945],
              [-35.106856999999899, -7.405516],
              [-35.106287, -7.4054],
              [-35.105752, -7.405366],
              [-35.10518, -7.405267],
              [-35.104641, -7.405248],
              [-35.104564791, -7.405235274999939],
              [-35.10409, -7.405156],
              [-35.102629704, -7.404789456999931],
              [-35.102621878, -7.404787492999954],
              [-35.1026062789999, -7.404783576999922],
              [-35.102102, -7.404657],
              [-35.102091431, -7.404653700999966],
              [-35.101729904, -7.404540848999937],
              [-35.100465626, -7.404146201999946],
              [-35.100009768999897, -7.404003904999964],
              [-35.099045178706263, -7.403702805675075],
              [-35.099045178706263, -7.450461434175619],
              [-35.199045178706264, -7.450461434175619],
              [-35.199045178706264, -7.388416112109494],
              [-35.196404083999902, -7.391976554999932],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '928',
        ID: 44126.0,
        COUNTRY: 'Brasil',
        LON: -35.049,
        LAT: -7.4005,
        ANNUAL: 5377.0,
        JAN: 5725.0,
        FEB: 5954.0,
        MAR: 5956.0,
        APR: 5273.0,
        MAY: 4630.0,
        JUN: 4253.0,
        JUL: 4306.0,
        AUG: 5048.0,
        SEP: 5555.0,
        OCT: 5802.0,
        NOV: 6007.0,
        DEC: 6011.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.094168003842903, -7.40218038307927],
              [-35.091638294999903, -7.401390727999971],
              [-35.0884905529999, -7.401431757999943],
              [-35.083899932999898, -7.401491592999958],
              [-35.082009421, -7.401267280999959],
              [-35.079015829, -7.401174695999937],
              [-35.076423438999903, -7.400650045999948],
              [-35.070520761999902, -7.398760992999931],
              [-35.070340716, -7.398875659999932],
              [-35.067253251, -7.400853021999977],
              [-35.065521536999903, -7.401962094999932],
              [-35.063043056, -7.406711467999962],
              [-35.054491614, -7.412298829999944],
              [-35.048100037, -7.417811528999946],
              [-35.040473603, -7.421762800999931],
              [-35.034824838, -7.423378033999938],
              [-35.029556317999898, -7.425820611999938],
              [-35.020321748999898, -7.435923973999935],
              [-35.018400659999898, -7.439134509999979],
              [-35.016100583, -7.441935566999963],
              [-35.014600281, -7.443453157999953],
              [-35.011625963, -7.445165707999935],
              [-35.00979725, -7.446218360999981],
              [-35.006788968, -7.448588628999963],
              [-35.005255913891787, -7.450461434175619],
              [-35.099045178706263, -7.450461434175619],
              [-35.099045178706263, -7.403702805675075],
              [-35.094168003842903, -7.40218038307927],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '929',
        ID: 44464.0,
        COUNTRY: 'Brasil',
        LON: -40.549,
        LAT: -7.3005,
        ANNUAL: 5797.0,
        JAN: 5487.0,
        FEB: 5454.0,
        MAR: 5456.0,
        APR: 5322.0,
        MAY: 5220.0,
        JUN: 5228.0,
        JUL: 5572.0,
        AUG: 6222.0,
        SEP: 6629.0,
        OCT: 6531.0,
        NOV: 6436.0,
        DEC: 6003.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.540540502999903, -7.344849754999937],
              [-40.5404222559999, -7.34140328599995],
              [-40.541894108, -7.335826422999953],
              [-40.541508194, -7.332269118999928],
              [-40.540224834999897, -7.330621165999951],
              [-40.536273395, -7.327125583999928],
              [-40.523723229, -7.318450455999936],
              [-40.523678614999902, -7.318461854999947],
              [-40.516593123999897, -7.320268766999963],
              [-40.516572002, -7.320277345999943],
              [-40.510286472, -7.322830193999943],
              [-40.506750446999902, -7.324919358999975],
              [-40.500468612, -7.327995335999955],
              [-40.499045178706339, -7.328890827120919],
              [-40.499045178706339, -7.350461434175619],
              [-40.541310489377764, -7.350461434175619],
              [-40.540540502999903, -7.344849754999937],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '930',
        ID: 44465.0,
        COUNTRY: 'Brasil',
        LON: -40.449,
        LAT: -7.3005,
        ANNUAL: 5713.0,
        JAN: 5456.0,
        FEB: 5402.0,
        MAR: 5392.0,
        APR: 5281.0,
        MAY: 5141.0,
        JUN: 5132.0,
        JUL: 5455.0,
        AUG: 6148.0,
        SEP: 6509.0,
        OCT: 6419.0,
        NOV: 6324.0,
        DEC: 5897.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.487013777, -7.336459859999925],
              [-40.486940487, -7.336505966999943],
              [-40.482868072, -7.339290209999936],
              [-40.476246986, -7.344727266999946],
              [-40.476154498, -7.344783094999964],
              [-40.466747318532008, -7.350461434175619],
              [-40.499045178706339, -7.350461434175619],
              [-40.499045178706339, -7.328890827120919],
              [-40.487013777, -7.336459859999925],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '931',
        ID: 44466.0,
        COUNTRY: 'Brasil',
        LON: -40.349,
        LAT: -7.3005,
        ANNUAL: 5736.0,
        JAN: 5462.0,
        FEB: 5403.0,
        MAR: 5405.0,
        APR: 5267.0,
        MAY: 5109.0,
        JUN: 5104.0,
        JUL: 5447.0,
        AUG: 6256.0,
        SEP: 6614.0,
        OCT: 6514.0,
        NOV: 6361.0,
        DEC: 5890.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.355455359, -7.347275731999953],
              [-40.354790400999903, -7.345784383999952],
              [-40.354125442999901, -7.344293035999953],
              [-40.3523435399999, -7.337686699999946],
              [-40.350793595, -7.334171399999956],
              [-40.346820148999903, -7.330237012999935],
              [-40.346085778, -7.329699633999949],
              [-40.3453514079999, -7.329162254999971],
              [-40.33801539400001, -7.32379409199996],
              [-40.337982355999898, -7.323774024999979],
              [-40.3293943749999, -7.318557856999961],
              [-40.326520748, -7.31483493799993],
              [-40.325523646, -7.312277783999948],
              [-40.321431444, -7.305891928999923],
              [-40.31856315999989, -7.303340395999944],
              [-40.318144554999897, -7.303399400999984],
              [-40.317021597, -7.303558],
              [-40.310740829999901, -7.300697384999978],
              [-40.308760812999893, -7.301234590999974],
              [-40.303584402, -7.300902009999962],
              [-40.303566781, -7.300900877999934],
              [-40.303549159999903, -7.300899745999965],
              [-40.300836145999909, -7.300725435999945],
              [-40.299045178706336, -7.300819794078062],
              [-40.299045178706336, -7.350461434175619],
              [-40.357145972928087, -7.350461434175619],
              [-40.355455359, -7.347275731999953],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '932',
        ID: 44467.0,
        COUNTRY: 'Brasil',
        LON: -40.249,
        LAT: -7.3005,
        ANNUAL: 5716.0,
        JAN: 5458.0,
        FEB: 5370.0,
        MAR: 5395.0,
        APR: 5197.0,
        MAY: 5081.0,
        JUN: 5042.0,
        JUL: 5396.0,
        AUG: 6220.0,
        SEP: 6633.0,
        OCT: 6500.0,
        NOV: 6395.0,
        DEC: 5909.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.296544513, -7.300951542999947],
              [-40.293899435999897, -7.299999905999932],
              [-40.283880817999901, -7.299174491999955],
              [-40.279003283999899, -7.299391057999979],
              [-40.278968607, -7.299395518999978],
              [-40.268044523999897, -7.300800858999928],
              [-40.262995606999901, -7.301020938999951],
              [-40.262973471, -7.301033814999922],
              [-40.260163739999903, -7.30266817999996],
              [-40.255695761, -7.306199346999954],
              [-40.247533437, -7.313718633999941],
              [-40.241971725, -7.317995221999983],
              [-40.23238206, -7.329796965999949],
              [-40.232318649, -7.329875004999962],
              [-40.229366763, -7.333507811999946],
              [-40.220759992, -7.341270308999978],
              [-40.2139384829999, -7.345952031999948],
              [-40.207189603692321, -7.350461434175619],
              [-40.299045178706336, -7.350461434175619],
              [-40.299045178706336, -7.300819794078062],
              [-40.296544513, -7.300951542999947],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '933',
        ID: 44470.0,
        COUNTRY: 'Brasil',
        LON: -39.949,
        LAT: -7.3005,
        ANNUAL: 5724.0,
        JAN: 5515.0,
        FEB: 5430.0,
        MAR: 5388.0,
        APR: 5240.0,
        MAY: 5129.0,
        JUN: 4983.0,
        JUL: 5318.0,
        AUG: 6192.0,
        SEP: 6670.0,
        OCT: 6558.0,
        NOV: 6349.0,
        DEC: 5916.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.944209035, -7.349185106999922],
              [-39.938419477, -7.347239663999973],
              [-39.931546904, -7.343435870999945],
              [-39.926344968, -7.340998905999926],
              [-39.915442313, -7.338089806999952],
              [-39.910385313999903, -7.337210297999953],
              [-39.90455965, -7.338343358999965],
              [-39.899045178706331, -7.339415388753427],
              [-39.899045178706331, -7.350461434175619],
              [-39.946591511649238, -7.350461434175619],
              [-39.944209035, -7.349185106999922],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '934',
        ID: 44471.0,
        COUNTRY: 'Brasil',
        LON: -39.849,
        LAT: -7.3005,
        ANNUAL: 5681.0,
        JAN: 5558.0,
        FEB: 5443.0,
        MAR: 5394.0,
        APR: 5172.0,
        MAY: 4960.0,
        JUN: 4865.0,
        JUL: 5219.0,
        AUG: 6138.0,
        SEP: 6590.0,
        OCT: 6478.0,
        NOV: 6372.0,
        DEC: 5988.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.897669854, -7.339682755999958],
              [-39.890345513999897, -7.339824601999966],
              [-39.890130613999901, -7.339828763999948],
              [-39.890119671, -7.339827288999964],
              [-39.890090385, -7.339823341999923],
              [-39.886281706, -7.339309973999942],
              [-39.883503937999897, -7.338056244999964],
              [-39.874831607, -7.332469467999934],
              [-39.866611007, -7.329152820999975],
              [-39.863953226, -7.327692083999986],
              [-39.857696806, -7.325428691999945],
              [-39.851448074, -7.325209904999946],
              [-39.848139064, -7.325093969999951],
              [-39.848110372, -7.325107932999968],
              [-39.839834851, -7.32913523299993],
              [-39.839804786999899, -7.329143963999968],
              [-39.828695769, -7.332370194999948],
              [-39.824090997, -7.331831521999959],
              [-39.818334136, -7.329144125999954],
              [-39.815367519999903, -7.328694211999969],
              [-39.815333949, -7.328708814999969],
              [-39.81202041400001, -7.330150218999965],
              [-39.812010638999901, -7.330150661999944],
              [-39.806462259999897, -7.330402300999943],
              [-39.803723106999897, -7.330119956999972],
              [-39.800805219, -7.33043353699998],
              [-39.8007685749999, -7.330446759999966],
              [-39.799045178706329, -7.331068661573588],
              [-39.799045178706329, -7.350461434175619],
              [-39.899045178706331, -7.350461434175619],
              [-39.899045178706331, -7.339415388753427],
              [-39.897669854, -7.339682755999958],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '935',
        ID: 44472.0,
        COUNTRY: 'Brasil',
        LON: -39.749,
        LAT: -7.3005,
        ANNUAL: 5679.0,
        JAN: 5600.0,
        FEB: 5485.0,
        MAR: 5389.0,
        APR: 5177.0,
        MAY: 4940.0,
        JUN: 4845.0,
        JUL: 5246.0,
        AUG: 6079.0,
        SEP: 6567.0,
        OCT: 6480.0,
        NOV: 6374.0,
        DEC: 5962.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.797008656999907, -7.331803556999941],
              [-39.797005397, -7.331803874999934],
              [-39.794136242, -7.332083341999972],
              [-39.78922697, -7.331995101999957],
              [-39.780366269999902, -7.329745310999952],
              [-39.7762454269999, -7.328030755999973],
              [-39.77126441, -7.327472613999927],
              [-39.770227416999901, -7.327356414999945],
              [-39.760426755, -7.326258214999937],
              [-39.753045449999902, -7.326622230999926],
              [-39.741854582, -7.326807784999966],
              [-39.741582068999897, -7.326812302999944],
              [-39.741322101, -7.326816612999954],
              [-39.736052164999897, -7.328405612999965],
              [-39.727621284, -7.331229540999964],
              [-39.715496938999898, -7.334924503999956],
              [-39.706188883, -7.337761186999965],
              [-39.7061818399999, -7.337764752999924],
              [-39.699045178706328, -7.341387395051338],
              [-39.699045178706328, -7.350461434175619],
              [-39.799045178706329, -7.350461434175619],
              [-39.799045178706329, -7.331068661573588],
              [-39.797008656999907, -7.331803556999941],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '936',
        ID: 44473.0,
        COUNTRY: 'Brasil',
        LON: -39.649,
        LAT: -7.3005,
        ANNUAL: 5703.0,
        JAN: 5602.0,
        FEB: 5537.0,
        MAR: 5469.0,
        APR: 5157.0,
        MAY: 4945.0,
        JUN: 4873.0,
        JUL: 5177.0,
        AUG: 6046.0,
        SEP: 6592.0,
        OCT: 6505.0,
        NOV: 6501.0,
        DEC: 6037.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.697512026, -7.342165638999975],
              [-39.684870020999902, -7.350438038999927],
              [-39.684830962506254, -7.350461434175619],
              [-39.699045178706328, -7.350461434175619],
              [-39.699045178706328, -7.341387395051338],
              [-39.697512026, -7.342165638999975],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '937',
        ID: 44496.0,
        COUNTRY: 'Brasil',
        LON: -37.349,
        LAT: -7.3005,
        ANNUAL: 5866.0,
        JAN: 6074.0,
        FEB: 6011.0,
        MAR: 6040.0,
        APR: 5710.0,
        MAY: 5057.0,
        JUN: 4693.0,
        JUL: 5057.0,
        AUG: 5800.0,
        SEP: 6459.0,
        OCT: 6556.0,
        NOV: 6696.0,
        DEC: 6243.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.364638779, -7.346070797999976],
              [-37.357786925, -7.340666798999965],
              [-37.3514894099999, -7.338520645999944],
              [-37.349511778, -7.337028507999955],
              [-37.349376338999903, -7.335118159999983],
              [-37.355857838, -7.325726944999934],
              [-37.3572843009999, -7.323113764999963],
              [-37.357008236, -7.320989055999976],
              [-37.355457279999897, -7.319143085999937],
              [-37.352136933999901, -7.316807050999965],
              [-37.352121538999903, -7.316787254999946],
              [-37.351785913, -7.316355700999963],
              [-37.351780332, -7.316348524999964],
              [-37.346038732, -7.308965860999928],
              [-37.345819215999903, -7.308683602999962],
              [-37.345403012, -7.308148438999982],
              [-37.335197533, -7.295026029999977],
              [-37.3325932119999, -7.289731873999921],
              [-37.330585745, -7.288283504999977],
              [-37.328215406, -7.288058103999967],
              [-37.322886103, -7.289131237999924],
              [-37.320482216999899, -7.290493665999966],
              [-37.319390854999902, -7.290304210999972],
              [-37.313959359, -7.289361328999973],
              [-37.307811607, -7.289241888999918],
              [-37.307612291999902, -7.289238016999949],
              [-37.305656419999899, -7.289200007999964],
              [-37.30307297199991, -7.288697747999951],
              [-37.299045178706294, -7.288519703908307],
              [-37.299045178706294, -7.350461434175619],
              [-37.372242844258146, -7.350461434175619],
              [-37.364638779, -7.346070797999976],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '938',
        ID: 44497.0,
        COUNTRY: 'Brasil',
        LON: -37.249,
        LAT: -7.3005,
        ANNUAL: 5833.0,
        JAN: 6072.0,
        FEB: 5998.0,
        MAR: 6036.0,
        APR: 5708.0,
        MAY: 5026.0,
        JUN: 4648.0,
        JUL: 4989.0,
        AUG: 5717.0,
        SEP: 6387.0,
        OCT: 6545.0,
        NOV: 6647.0,
        DEC: 6217.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.296827751, -7.288421684999943],
              [-37.288332759999903, -7.288288892999963],
              [-37.286847198, -7.285918863999933],
              [-37.282022708999897, -7.278827148999943],
              [-37.277762794, -7.275184261999925],
              [-37.276930512, -7.274885441999968],
              [-37.275267753, -7.274288447999941],
              [-37.273955762999897, -7.274276793999944],
              [-37.260818184999899, -7.274160097999915],
              [-37.260731823999897, -7.27416805499996],
              [-37.255329326, -7.274665792999947],
              [-37.2529528929999, -7.274884698999933],
              [-37.249558502, -7.274099350999953],
              [-37.241906465999897, -7.272953783999984],
              [-37.234178764, -7.273886872999978],
              [-37.233612411, -7.274396520999965],
              [-37.232843395, -7.275088541999923],
              [-37.232512813, -7.275385901999929],
              [-37.227930715999896, -7.279507518999935],
              [-37.222703024999902, -7.286067629999927],
              [-37.216781977, -7.289701213999925],
              [-37.214175478, -7.291300749999957],
              [-37.212473049, -7.291397791999946],
              [-37.206753861, -7.291723796999962],
              [-37.205162454999908, -7.291814509999932],
              [-37.2019665609999, -7.292750734999964],
              [-37.199045178706292, -7.293810161014213],
              [-37.199045178706292, -7.350461434175619],
              [-37.299045178706294, -7.350461434175619],
              [-37.299045178706294, -7.288519703908307],
              [-37.296827751, -7.288421684999943],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        fid: '939',
        ID: 44498.0,
        COUNTRY: 'Brasil',
        LON: -37.149,
        LAT: -7.3005,
        ANNUAL: 5777.0,
        JAN: 6059.0,
        FEB: 5967.0,
        MAR: 6045.0,
        APR: 5667.0,
        MAY: 4974.0,
        JUN: 4559.0,
        JUL: 4846.0,
        AUG: 5590.0,
        SEP: 6306.0,
        OCT: 6490.0,
        NOV: 6606.0,
        DEC: 6219.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.196673984, -7.294670063999931],
              [-37.190950654999909, -7.300229255999968],
              [-37.1829815789999, -7.306417668999922],
              [-37.176924954999897, -7.309552488999937],
              [-37.174794162999902, -7.313472941999975],
              [-37.173511081, -7.315833688999938],
              [-37.168530901999901, -7.319951566999983],
              [-37.16764799, -7.324066948999936],
              [-37.168107473, -7.331890753999974],
              [-37.168111659, -7.331962031999973],
              [-37.165341412, -7.336595286999977],
              [-37.157067321999897, -7.338213699999924],
              [-37.150757712, -7.334534295999965],
              [-37.14494388, -7.335045376999966],
              [-37.14128885, -7.341265142999922],
              [-37.140336487, -7.342125400999984],
              [-37.135314771, -7.346664324999942],
              [-37.127978222, -7.344001973999987],
              [-37.121984182078556, -7.350461434175619],
              [-37.199045178706292, -7.350461434175619],
              [-37.199045178706292, -7.293810161014213],
              [-37.196673984, -7.294670063999931],
            ],
          ],
        ],
      },
    },
  ],
};
