export var jsonPoligonoDasSecasPE0 = {
  type: 'FeatureCollection',
  name: 'Poligono_das_Secas_PE_0',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        NM_MUNICIP: 'AFOGADOS DA INGAZEIRA',
        CD_GEOCMU: '2600104',
        UF: 'PE',
        Semiarido: 'SIM',
        Pop_Est_17: '37017',
        area_ha: 8614520.963,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.671623169999975, -8.659475980999957],
              [-39.685569475999955, -8.660295430999952],
              [-39.688835921999953, -8.660850399999958],
              [-39.691131732999963, -8.662312924999981],
              [-39.69197808399997, -8.664225821999935],
              [-39.693144515999961, -8.676238017999935],
              [-39.693036592999931, -8.680511979999949],
              [-39.687987997999983, -8.693482913999958],
              [-39.685889131999943, -8.701204357999927],
              [-39.684956068999952, -8.703860500999951],
              [-39.684559199999967, -8.704990266999971],
              [-39.682282532999977, -8.711469005999959],
              [-39.679646029999958, -8.72251764899994],
              [-39.678994278999937, -8.729334774999927],
              [-39.677972289999957, -8.732576047999942],
              [-39.675020002999929, -8.736667356999931],
              [-39.67514786299995, -8.740502214999935],
              [-39.675147884999944, -8.740502879999951],
              [-39.673824036999974, -8.745461408999972],
              [-39.677469333999966, -8.755205696999951],
              [-39.678124654999976, -8.756957442999976],
              [-39.677501267999958, -8.760889045999932],
              [-39.676705273999971, -8.765909257999965],
              [-39.674903373999939, -8.772678649999932],
              [-39.671713559999944, -8.777581461999944],
              [-39.671838210999965, -8.780738319999955],
              [-39.673300375999929, -8.784903966999934],
              [-39.674629178999965, -8.787157205999961],
              [-39.675960352999937, -8.788282526999978],
              [-39.676795868999932, -8.788517101999958],
              [-39.679945050999947, -8.789401247999933],
              [-39.686382030999937, -8.793072821999942],
              [-39.687024248999933, -8.793458826999938],
              [-39.690650281999979, -8.795638251999947],
              [-39.693196365999938, -8.798675741999944],
              [-39.695128744999977, -8.799571361999938],
              [-39.700476889999948, -8.799952050999934],
              [-39.704185978999931, -8.800006252999935],
              [-39.70566499499995, -8.79946014799998],
              [-39.71016394399993, -8.797798977999946],
              [-39.71375137299998, -8.79735866599998],
              [-39.716864487999942, -8.79757953099994],
              [-39.721768380999947, -8.799015603999976],
              [-39.730277561999969, -8.796576994999953],
              [-39.73872524799998, -8.794870712999966],
              [-39.747785344999954, -8.794041012999969],
              [-39.749239293999949, -8.79484606799997],
              [-39.750351388999945, -8.796766061999961],
              [-39.757560083999977, -8.799187497999981],
              [-39.758748470999933, -8.799691014999951],
              [-39.761751390999962, -8.800963346999936],
              [-39.762851815999966, -8.803439752999964],
              [-39.764421740999978, -8.804793576999941],
              [-39.775484351999978, -8.809112162999952],
              [-39.780841692999957, -8.813135615999954],
              [-39.78242262699996, -8.814322923999953],
              [-39.788796212999955, -8.81910959399994],
              [-39.792662681999957, -8.819890968999971],
              [-39.798402212999974, -8.819597575999978],
              [-39.80359275099994, -8.81868581599997],
              [-39.814820280999982, -8.814604047999978],
              [-39.819970394999984, -8.813488401999962],
              [-39.822731891999979, -8.813515767999945],
              [-39.830465768999943, -8.814400654999929],
              [-39.844786771999964, -8.818248750999942],
              [-39.862900146999948, -8.819500633999951],
              [-39.886816633999956, -8.824991179999927],
              [-39.890415399999938, -8.826770845999931],
              [-39.892957721999949, -8.82879399199993],
              [-39.896046939999962, -8.832901037999932],
              [-39.900542745999985, -8.842021275999969],
              [-39.90273687399997, -8.850577871999974],
              [-39.902849489999937, -8.851582403999942],
              [-39.903112533999945, -8.853928763999932],
              [-39.902938174999974, -8.858743592999929],
              [-39.901259273999983, -8.864158783999926],
              [-39.884236311999985, -8.895695932999956],
              [-39.882322611999939, -8.898163727999929],
              [-39.879418611999938, -8.901908555999967],
              [-39.877927492999959, -8.90996909699993],
              [-39.877469922999978, -8.921573260999935],
              [-39.873442213999965, -8.930207608999979],
              [-39.873456420999958, -8.934038717999954],
              [-39.874428620999936, -8.936738523999963],
              [-39.879883306999943, -8.946196321999935],
              [-39.882744009999954, -8.952102800999967],
              [-39.886148714999933, -8.961110019999978],
              [-39.88881912599993, -8.965836757999966],
              [-39.890024585999981, -8.966815443999963],
              [-39.891225257999963, -8.967790630999957],
              [-39.892692974999932, -8.968982711999956],
              [-39.899350311999967, -8.972461266999971],
              [-39.906695315999968, -8.981351595999968],
              [-39.906923934999952, -8.981628313999977],
              [-39.912249945999974, -8.991768764999961],
              [-39.913603137999985, -8.994345172999942],
              [-39.915017902999978, -8.996432098999946],
              [-39.916552277999983, -8.998695460999954],
              [-39.916690648999975, -8.999462176999941],
              [-39.917006348999962, -9.001211468999941],
              [-39.918531472999973, -9.001539197999932],
              [-39.919849115999966, -9.001822342999958],
              [-39.927243165999982, -9.009128909999959],
              [-39.92883244899997, -9.01295179899995],
              [-39.933544775999962, -9.017112281999971],
              [-39.940035876999957, -9.025944052999932],
              [-39.949126265999951, -9.035611139999958],
              [-39.952644697999972, -9.040227812999944],
              [-39.955498678999959, -9.046248047999939],
              [-39.958034747999932, -9.048591558999931],
              [-39.968091036999965, -9.053197058999956],
              [-39.974668134999945, -9.053989441999931],
              [-39.978444424999964, -9.054861946999949],
              [-39.978449873999978, -9.054863205999936],
              [-39.980694507999942, -9.055381823999937],
              [-39.989782273999936, -9.059466171999929],
              [-39.996030573999974, -9.060958200999949],
              [-39.997981072999949, -9.061124788999962],
              [-40.002557994999961, -9.060038777999978],
              [-40.004931835999969, -9.059798680999961],
              [-40.004935084999943, -9.059798351999973],
              [-40.023644586999978, -9.060100225999975],
              [-40.035723570999949, -9.06239374699993],
              [-40.038309323999954, -9.061754658999973],
              [-40.042469773999983, -9.059821718999956],
              [-40.046477221999965, -9.060087176999957],
              [-40.050531383999953, -9.061085845999969],
              [-40.065563054999984, -9.063065192999943],
              [-40.068612935999965, -9.064975355999934],
              [-40.070116783999936, -9.067196724999974],
              [-40.071839969999985, -9.072961021999959],
              [-40.073539726999968, -9.075362060999964],
              [-40.077395979999949, -9.078226413999971],
              [-40.085276239999985, -9.082585304999952],
              [-40.092469717999961, -9.085915495999927],
              [-40.094472318999976, -9.087508647999925],
              [-40.09872110799995, -9.090888733999975],
              [-40.101729684999952, -9.094131757999946],
              [-40.107420957999977, -9.097470727999962],
              [-40.111282100999972, -9.098110658999929],
              [-40.119867565999982, -9.102096899999935],
              [-40.124054211999976, -9.107356496999955],
              [-40.127221397999961, -9.109764923999933],
              [-40.12989491999997, -9.110304605999943],
              [-40.136252089999971, -9.109124900999973],
              [-40.136382748999949, -9.109062466999944],
              [-40.137424832999955, -9.108564514999955],
              [-40.138597575999938, -9.108004128999937],
              [-40.140049420999958, -9.107476488999964],
              [-40.144333676999963, -9.106548768999971],
              [-40.151104025999985, -9.10713166499994],
              [-40.152056281999933, -9.107850757999927],
              [-40.152518450999935, -9.107852117999926],
              [-40.159720784999934, -9.107873318999964],
              [-40.163086733999933, -9.107308969999963],
              [-40.165001586999949, -9.106487775999938],
              [-40.166343486999949, -9.105372710999973],
              [-40.167647316999933, -9.103161074999946],
              [-40.171082724999962, -9.093449032999956],
              [-40.174137050999946, -9.090559380999935],
              [-40.180583235999961, -9.087784014999954],
              [-40.184088697999982, -9.085295145999964],
              [-40.194313437999938, -9.07425359399997],
              [-40.194408039999985, -9.074151434999976],
              [-40.19650377499994, -9.071888280999929],
              [-40.201119252999945, -9.068465766999964],
              [-40.208827533999965, -9.063782297999978],
              [-40.213436714999943, -9.060981107999964],
              [-40.218519526999955, -9.058824328999947],
              [-40.223602339999957, -9.05666754899994],
              [-40.231569519999937, -9.054722497999933],
              [-40.238983527999949, -9.054365347999976],
              [-40.243399583999974, -9.055572926999957],
              [-40.24786413299995, -9.057737842999927],
              [-40.255790975999957, -9.063223432999962],
              [-40.264206082999976, -9.07095775099998],
              [-40.266301365999936, -9.073216660999947],
              [-40.269948671999941, -9.077847573999975],
              [-40.270854035999946, -9.07912457499998],
              [-40.270860094999932, -9.079133120999927],
              [-40.273192198999936, -9.082421579999959],
              [-40.276665796999964, -9.089113477999945],
              [-40.278846686999941, -9.094538502999967],
              [-40.282920632999947, -9.107778119999978],
              [-40.286706136999953, -9.117351844999973],
              [-40.288681031999943, -9.127406769999936],
              [-40.289057573999969, -9.129324086999929],
              [-40.289269108999974, -9.130401205999931],
              [-40.293330175999984, -9.146742858999971],
              [-40.294011982999962, -9.152067785999975],
              [-40.293752660999985, -9.155236220999939],
              [-40.293577209999967, -9.155752894999978],
              [-40.292834988999971, -9.157938610999963],
              [-40.289473811999983, -9.165198620999945],
              [-40.287320670999975, -9.171082567999974],
              [-40.285815172999946, -9.181813931999955],
              [-40.286172655999962, -9.185611404999975],
              [-40.290079502999959, -9.195612707999942],
              [-40.290215151999973, -9.195959961999961],
              [-40.295725395999966, -9.213566767999964],
              [-40.29785203299997, -9.224149629999943],
              [-40.300506811999981, -9.231208024999944],
              [-40.301372392999951, -9.23492344999994],
              [-40.301502905999939, -9.236033551999981],
              [-40.302315236999959, -9.242942969999945],
              [-40.302206785999942, -9.251703299999974],
              [-40.305283042999974, -9.257761216999938],
              [-40.305294695999976, -9.260049150999976],
              [-40.305309178999948, -9.262892557999976],
              [-40.307787121999979, -9.271320672999934],
              [-40.309535370999981, -9.275506665999956],
              [-40.311837940999965, -9.279032983999969],
              [-40.314254112999947, -9.282404272999941],
              [-40.319031350999978, -9.287269243999958],
              [-40.325613049999959, -9.305855598999926],
              [-40.331612377999932, -9.316014741999936],
              [-40.332531932999984, -9.317571898999972],
              [-40.336119587999974, -9.327168681999979],
              [-40.337119524999935, -9.331402288999925],
              [-40.337337557999945, -9.335067964999951],
              [-40.336586160999957, -9.341810020999958],
              [-40.334285490999946, -9.34711641399997],
              [-40.333754073999955, -9.350538338999968],
              [-40.335043336999945, -9.353901723999968],
              [-40.338865537999936, -9.359891761999961],
              [-40.348707540999953, -9.368664076999949],
              [-40.354198199999985, -9.37503754599993],
              [-40.356397112999957, -9.376994262999972],
              [-40.359031451999954, -9.37812923499996],
              [-40.363699449999956, -9.379285006999964],
              [-40.371415334999938, -9.37871673799998],
              [-40.391278396999951, -9.372166825999955],
              [-40.401992217999975, -9.365188764999971],
              [-40.404726452999967, -9.362907324999981],
              [-40.409842955999977, -9.355676342999971],
              [-40.411646191999978, -9.354170948999979],
              [-40.414198695999971, -9.352954083999975],
              [-40.418145290999973, -9.352053271999978],
              [-40.420889726999974, -9.352571993999959],
              [-40.423659467999983, -9.353465009999979],
              [-40.430788194999934, -9.356694496999978],
              [-40.43369402299993, -9.35917150399996],
              [-40.435898256999963, -9.36215871099995],
              [-40.439306016999979, -9.368280893999952],
              [-40.440689340999938, -9.37144290699996],
              [-40.447643119999952, -9.387337913999943],
              [-40.448694447999969, -9.389384757999949],
              [-40.451860735999958, -9.395549245999973],
              [-40.452726247999976, -9.397234322999964],
              [-40.452888237999957, -9.400804470999958],
              [-40.453292500999964, -9.401983353999981],
              [-40.453818542999954, -9.403517362999935],
              [-40.456076060999976, -9.406422530999976],
              [-40.46426588199995, -9.413359336999974],
              [-40.465170066999974, -9.415553081999974],
              [-40.465214315999958, -9.415660437999975],
              [-40.466162749999967, -9.417961539999965],
              [-40.467755117999957, -9.420568130999982],
              [-40.470730086999936, -9.421213942999941],
              [-40.474174738999977, -9.420792208999956],
              [-40.475067811999963, -9.420426850999945],
              [-40.477834207999933, -9.419295113999965],
              [-40.481811686999947, -9.416278140999964],
              [-40.483924168999977, -9.413967422999974],
              [-40.487242747999971, -9.412250286999949],
              [-40.487282275999974, -9.412229833999959],
              [-40.495007920999967, -9.409261526999956],
              [-40.495186448999959, -9.409205307999969],
              [-40.495682579999936, -9.409049074999928],
              [-40.497196437999946, -9.408572357999958],
              [-40.49774714199998, -9.408666656999969],
              [-40.499162465999973, -9.408909007999966],
              [-40.501159780999956, -9.409251014999938],
              [-40.501450124999963, -9.409300731999963],
              [-40.502549879999947, -9.409093852999945],
              [-40.505057194999949, -9.408622191999939],
              [-40.50626953799997, -9.408394133999934],
              [-40.510726616999932, -9.407178859999931],
              [-40.511110514999984, -9.407293507999952],
              [-40.513752443999977, -9.408082500999967],
              [-40.518391134999945, -9.411143936999963],
              [-40.52006814899994, -9.412250730999972],
              [-40.522031751999975, -9.412823376999938],
              [-40.524122673999955, -9.413433151999925],
              [-40.529114734999951, -9.413901645999943],
              [-40.531695471999967, -9.414956981999978],
              [-40.532429149999984, -9.415588967999952],
              [-40.535130573999936, -9.417915960999949],
              [-40.53822195999993, -9.420578864999982],
              [-40.540815236999947, -9.424043299999937],
              [-40.541785721999929, -9.426028136999946],
              [-40.54371464899998, -9.432541697999966],
              [-40.545011030999945, -9.441131178999967],
              [-40.545187274999932, -9.442298926999968],
              [-40.546859536999932, -9.446308128999931],
              [-40.548367205999966, -9.448994132999928],
              [-40.550662495999973, -9.452179993999948],
              [-40.552700861999938, -9.454742241999952],
              [-40.555758513999933, -9.457938112999955],
              [-40.565185478999979, -9.464009468999961],
              [-40.572708715999966, -9.466858348999949],
              [-40.574864212999955, -9.467405692999932],
              [-40.57662688299996, -9.467654671999981],
              [-40.581506412999943, -9.467646210999931],
              [-40.583781143999943, -9.467205814999943],
              [-40.587454190999949, -9.465740722999954],
              [-40.593642649999936, -9.466901646999929],
              [-40.595123308999973, -9.467369153999925],
              [-40.597629799999936, -9.468444967999972],
              [-40.599618028999942, -9.469606354999939],
              [-40.608562577999976, -9.476791531999936],
              [-40.61651025599997, -9.480975365999939],
              [-40.620676854999942, -9.482525463999934],
              [-40.623165671999971, -9.482897443999946],
              [-40.628100648999975, -9.48268976199995],
              [-40.63586272699996, -9.48102279699998],
              [-40.643492819999949, -9.478168795999977],
              [-40.645962399999974, -9.477038088999961],
              [-40.649334951999947, -9.474937881999949],
              [-40.652152188999935, -9.473794419999933],
              [-40.653677541999969, -9.473501509999949],
              [-40.656768272999955, -9.47290800299993],
              [-40.662313748999964, -9.471842795999976],
              [-40.662869391999948, -9.471736064999959],
              [-40.662949037999965, -9.471720765999976],
              [-40.681321823999951, -9.467220998999949],
              [-40.701366825999969, -9.457650101999945],
              [-40.708194126999956, -9.453242204999981],
              [-40.713448152999945, -9.450706607999962],
              [-40.713671545999944, -9.450469765999969],
              [-40.715318711999942, -9.448728542999959],
              [-40.71710401699994, -9.448005038999952],
              [-40.717918853999947, -9.447674821999954],
              [-40.722178590999931, -9.445948539999961],
              [-40.729783060999978, -9.444803801999967],
              [-40.730527160999941, -9.444691789999979],
              [-40.745317720999935, -9.443350582999926],
              [-40.749835435999955, -9.44384716899998],
              [-40.755039982999961, -9.446482359999948],
              [-40.758049657999948, -9.448805964999963],
              [-40.76276231199995, -9.450313947999973],
              [-40.767456465999942, -9.452650075999941],
              [-40.776716174999933, -9.454444766999927],
              [-40.776750542999935, -9.45445142799997],
              [-40.769593431999965, -9.44744335799993],
              [-40.767289064999943, -9.445703780999963],
              [-40.767159398999979, -9.445411874999934],
              [-40.767072073999941, -9.445215288999975],
              [-40.764668796999956, -9.439804988999981],
              [-40.76062804399993, -9.432236282999952],
              [-40.759595295999929, -9.429076297999927],
              [-40.760083599999973, -9.425767507999979],
              [-40.764788596999949, -9.415949936999937],
              [-40.764975564999929, -9.413216386999977],
              [-40.763229990999946, -9.411423493999962],
              [-40.760788433999949, -9.410545061999926],
              [-40.75670122799994, -9.410352342999943],
              [-40.750595061999945, -9.406464660999973],
              [-40.74743005199997, -9.405870104999963],
              [-40.745231523999962, -9.404623216999937],
              [-40.743088879999959, -9.402945492999947],
              [-40.742671532999964, -9.400712882999926],
              [-40.743492784999944, -9.39828136999995],
              [-40.744113271999936, -9.396445547999974],
              [-40.743753521999963, -9.391075504999947],
              [-40.74254041599994, -9.383315038999967],
              [-40.743516879999959, -9.379407988999958],
              [-40.745867264999958, -9.374813099999926],
              [-40.746478937999939, -9.371052246999966],
              [-40.744528913999943, -9.36483166599993],
              [-40.739739689999965, -9.358291735999956],
              [-40.738102644999969, -9.355244273999972],
              [-40.738208829999962, -9.353199256999972],
              [-40.740935800999978, -9.348366732999978],
              [-40.74140308099993, -9.346783581999944],
              [-40.740672730999961, -9.343158205999941],
              [-40.737648501999956, -9.338753835999967],
              [-40.737286464999954, -9.336870765999947],
              [-40.739347394999982, -9.331343885999956],
              [-40.742306503999941, -9.327209878999952],
              [-40.751629822999973, -9.318284734999963],
              [-40.755011795999962, -9.317053969999961],
              [-40.756421943999953, -9.315032258999963],
              [-40.755592024999942, -9.313548823999952],
              [-40.755589203999932, -9.313543781999954],
              [-40.755487113999948, -9.313361301999976],
              [-40.753746062999937, -9.310249266999961],
              [-40.751588654999978, -9.30802781999995],
              [-40.749063706999948, -9.307458517999976],
              [-40.74745896099995, -9.306621901999961],
              [-40.743981936999944, -9.306225285999972],
              [-40.742897101999972, -9.30427726399995],
              [-40.742450394999935, -9.30224392699995],
              [-40.742619815999944, -9.300508855999965],
              [-40.741840966999973, -9.298870812999951],
              [-40.739441825999961, -9.296588726999971],
              [-40.737448875999974, -9.296780169999977],
              [-40.734140811999964, -9.299866732999931],
              [-40.730431127999964, -9.301476967999974],
              [-40.728648475999933, -9.303118203999929],
              [-40.725013531999934, -9.304463901999952],
              [-40.723247385999969, -9.30567369299996],
              [-40.720088160999978, -9.30589669699998],
              [-40.716820213999938, -9.303875731999938],
              [-40.71406607199998, -9.300382767999963],
              [-40.708290917999932, -9.296077688999958],
              [-40.707314851999968, -9.294550765999929],
              [-40.707063311999946, -9.29294170999998],
              [-40.707408765999958, -9.291654020999943],
              [-40.708689663999962, -9.286875976999966],
              [-40.707588889999954, -9.28534633299995],
              [-40.706038553999974, -9.285041556999943],
              [-40.701288837999982, -9.282332458999974],
              [-40.692252474999975, -9.281226476999962],
              [-40.687233980999963, -9.278333138999926],
              [-40.685987867999984, -9.276368183999978],
              [-40.685623346999932, -9.274578739999981],
              [-40.685444116999975, -9.273698894999939],
              [-40.684900366999955, -9.271029606999946],
              [-40.683271736999984, -9.267259788999979],
              [-40.681147917999965, -9.263482650999947],
              [-40.680088543999943, -9.259211747999927],
              [-40.680483784999979, -9.257691529999931],
              [-40.682703284999945, -9.257310028999939],
              [-40.686661474999937, -9.258019182999931],
              [-40.689236525999945, -9.25698590199994],
              [-40.69025813899998, -9.252638582999964],
              [-40.690095218999943, -9.251446273999932],
              [-40.687431768999943, -9.246676610999941],
              [-40.686455630999944, -9.243554569999958],
              [-40.68705687299996, -9.24226385399993],
              [-40.689457262999952, -9.241125388999933],
              [-40.690298251999934, -9.238873216999934],
              [-40.690350480999939, -9.238733348999972],
              [-40.690849183999944, -9.237397819999956],
              [-40.691006639999955, -9.234062851999965],
              [-40.694500378999976, -9.233455570999979],
              [-40.693445281999971, -9.231275585999981],
              [-40.689872923999985, -9.225926901999969],
              [-40.680703972999936, -9.212198764999926],
              [-40.681564173999959, -9.210845960999961],
              [-40.682207580999943, -9.208013392999931],
              [-40.681978953999931, -9.204741324999929],
              [-40.68056169099998, -9.200305342999968],
              [-40.678224778999947, -9.196298165999963],
              [-40.67705732099995, -9.193311451999932],
              [-40.675472314999979, -9.193926038999962],
              [-40.673362509999947, -9.192803050999942],
              [-40.670643783999935, -9.188708240999972],
              [-40.669873158999962, -9.186132510999926],
              [-40.670132648999981, -9.184543237999947],
              [-40.670189889999961, -9.184192662999976],
              [-40.67024712999995, -9.183842088999938],
              [-40.671102030999975, -9.182180606999964],
              [-40.671956930999954, -9.180519124999933],
              [-40.672042003999934, -9.178856404999976],
              [-40.670532563999984, -9.175354289999973],
              [-40.672092106999969, -9.172387236999953],
              [-40.669695785999977, -9.165000927999927],
              [-40.667224702999931, -9.160493181999925],
              [-40.667395665999948, -9.159059198999955],
              [-40.672319051999978, -9.156501790999926],
              [-40.677728786999978, -9.151246124999943],
              [-40.682811669999978, -9.141122886999938],
              [-40.683648882999933, -9.139834331999964],
              [-40.685093996999967, -9.137610157999973],
              [-40.687290792999931, -9.136984429999927],
              [-40.690290602999937, -9.13794747399993],
              [-40.694699726999943, -9.140549202999978],
              [-40.70016411499995, -9.142234058999975],
              [-40.706195143999935, -9.142006079999931],
              [-40.709889301999965, -9.140880974999959],
              [-40.722272304999933, -9.13381660999994],
              [-40.728869338999971, -9.128851417999954],
              [-40.73048726899998, -9.127249937999977],
              [-40.730921742999953, -9.125961589999974],
              [-40.732537487999934, -9.124005610999973],
              [-40.735394958999962, -9.123235378999937],
              [-40.74313620099997, -9.122400672999959],
              [-40.744805214999985, -9.121764779999978],
              [-40.744827276999956, -9.121756373999972],
              [-40.745078665999984, -9.121581290999927],
              [-40.745130716999938, -9.121545038999955],
              [-40.750385602999984, -9.117885193999939],
              [-40.755343531999983, -9.119967263999968],
              [-40.757060567999929, -9.119843067999966],
              [-40.757704151999974, -9.118629921999968],
              [-40.757704185999955, -9.118629857999963],
              [-40.75770128399995, -9.115187849999927],
              [-40.756289520999985, -9.111687066999934],
              [-40.757367766999948, -9.108008340999959],
              [-40.756784944999936, -9.104976913999963],
              [-40.763171024999963, -9.106940025999961],
              [-40.766618887999982, -9.105346449999956],
              [-40.769483753999964, -9.103178472999957],
              [-40.772234259999948, -9.102049314999931],
              [-40.772791627999936, -9.101820499999974],
              [-40.775086241999929, -9.100878046999981],
              [-40.777260596999952, -9.100668409999969],
              [-40.779791522999972, -9.101415671999973],
              [-40.783677620999981, -9.104386931999954],
              [-40.786095757999931, -9.105187327999943],
              [-40.786096433999944, -9.105187551999961],
              [-40.78745437699996, -9.10407344999993],
              [-40.78759034199993, -9.100628923999977],
              [-40.789463714999954, -9.09894752799994],
              [-40.793778980999946, -9.098715528999946],
              [-40.798915955999973, -9.099198500999933],
              [-40.804104037999934, -9.098335856999938],
              [-40.808871452999938, -9.093689452999968],
              [-40.809815251999964, -9.09227657699995],
              [-40.810760925999944, -9.085947690999944],
              [-40.811958234999963, -9.084398204999957],
              [-40.81319457099994, -9.08316929199998],
              [-40.818402402999936, -9.082182452999973],
              [-40.820067000999984, -9.081258671999933],
              [-40.82100804099997, -9.07989142699995],
              [-40.821323959999972, -9.078077135999933],
              [-40.821062771999948, -9.07616494499996],
              [-40.819147182999984, -9.069094966999955],
              [-40.821144966999952, -9.065395328999955],
              [-40.823589538999954, -9.062113431999933],
              [-40.824202471999968, -9.05736505699997],
              [-40.823072230999969, -9.051983707999966],
              [-40.823072126999932, -9.051983213999961],
              [-40.825304741999958, -9.045641673999967],
              [-40.824908249999964, -9.039975168999945],
              [-40.825231056999939, -9.038186632999953],
              [-40.82637247699995, -9.036805059999949],
              [-40.829963994999957, -9.033993296999938],
              [-40.83220821499998, -9.031163416999959],
              [-40.832036454999979, -9.029825796999944],
              [-40.830238071999929, -9.026695812999947],
              [-40.831452996999985, -9.02396042099997],
              [-40.832073260999948, -9.023555441999974],
              [-40.832443896999962, -9.023313448999943],
              [-40.832814532999976, -9.023071455999968],
              [-40.834222805999957, -9.020989176999933],
              [-40.834956469999952, -9.01461682799993],
              [-40.834370422999939, -9.010515971999951],
              [-40.835012707999965, -9.008693507999965],
              [-40.836844084999939, -9.005616319999945],
              [-40.836844189999965, -9.00561614399993],
              [-40.84135218299997, -9.001989971999933],
              [-40.841956538999966, -9.000577071999942],
              [-40.844655528999965, -8.997610879999968],
              [-40.845961964999958, -8.996852943999954],
              [-40.846843767999985, -8.995540492999964],
              [-40.847781356999974, -8.991643334999935],
              [-40.849590623999973, -8.988812702999951],
              [-40.850957401999949, -8.985217899999952],
              [-40.851958502999935, -8.982584878999944],
              [-40.852105138999946, -8.980471351999938],
              [-40.852105182999935, -8.980470719999971],
              [-40.851045092999982, -8.95755881499997],
              [-40.851011600999982, -8.954244313999936],
              [-40.851595864999979, -8.952498958999968],
              [-40.852739353999937, -8.951211267999952],
              [-40.855537982999977, -8.949473736999948],
              [-40.858782866999945, -8.949219125999946],
              [-40.861322568999981, -8.948017578999952],
              [-40.863317280999979, -8.942724304999956],
              [-40.865516346999982, -8.932267102999958],
              [-40.865401814999984, -8.926817085999971],
              [-40.86627809099997, -8.923389163999957],
              [-40.868973553999979, -8.921072569999978],
              [-40.872535683999956, -8.919065350999972],
              [-40.878313349999985, -8.91742802999994],
              [-40.881233705999932, -8.915627843999971],
              [-40.882017456999961, -8.913636785999927],
              [-40.882177113999944, -8.909538307999981],
              [-40.883442324999976, -8.904856012999971],
              [-40.885392642999932, -8.902403120999963],
              [-40.88888189599993, -8.898979879999956],
              [-40.893009265999979, -8.89116822699998],
              [-40.895041177999985, -8.88909277199997],
              [-40.895457703999966, -8.883887112999957],
              [-40.896096947999979, -8.882295788999954],
              [-40.896736224999984, -8.880704382999966],
              [-40.896570220999934, -8.875863308999953],
              [-40.896404335999932, -8.871025673999952],
              [-40.89640430999998, -8.871024918999979],
              [-40.896851583999933, -8.870049682999934],
              [-40.900044057999935, -8.867613232999929],
              [-40.900775236999948, -8.867055207999954],
              [-40.90150626999997, -8.866497156999969],
              [-40.902561398999978, -8.861072365999973],
              [-40.902663830999984, -8.860545272999957],
              [-40.903038193999976, -8.858618872999955],
              [-40.906901902999948, -8.856864024999936],
              [-40.909603177999941, -8.854408762999981],
              [-40.914978855999948, -8.849522664999938],
              [-40.916960118999953, -8.844532183999945],
              [-40.918457559999979, -8.838875924999968],
              [-40.919237103999933, -8.837177041999951],
              [-40.921338160999937, -8.835430478999967],
              [-40.924238463999984, -8.835390626999981],
              [-40.927276762999952, -8.835890900999971],
              [-40.931034647999979, -8.837200740999947],
              [-40.946383195999942, -8.844996909999963],
              [-40.948398673999975, -8.844851029999973],
              [-40.951597911999954, -8.843527009999946],
              [-40.954860049999979, -8.843875130999947],
              [-40.959039292999933, -8.841980828999965],
              [-40.959372229999985, -8.841370146999964],
              [-40.959867502999941, -8.834592778999934],
              [-40.96090500899993, -8.830759304999958],
              [-40.962266004999947, -8.829911716999959],
              [-40.967123825999977, -8.829816063999942],
              [-40.971755055999949, -8.82844924099993],
              [-40.976013352999985, -8.824582498999973],
              [-40.979093747999968, -8.82341708499996],
              [-40.982123280999986, -8.823856597999963],
              [-40.984677567999938, -8.825278817999958],
              [-40.988565069999936, -8.828952862999927],
              [-40.989758324999968, -8.829440569999974],
              [-40.995809737999934, -8.82949078799993],
              [-41.000349524999933, -8.828798044999928],
              [-41.006036516999984, -8.832287707999967],
              [-41.009926330999974, -8.833603331999939],
              [-41.012285604999931, -8.837312423999947],
              [-41.013093160999972, -8.837393542999962],
              [-41.014600187999974, -8.83859868199994],
              [-41.017314847999955, -8.84234104799998],
              [-41.019870649999973, -8.844031714999971],
              [-41.021240913999975, -8.843560915999944],
              [-41.02459900599996, -8.840090731999965],
              [-41.029101374999982, -8.83810013599998],
              [-41.031319701999962, -8.834958376999964],
              [-41.029875309999966, -8.83058198599997],
              [-41.029240308999931, -8.825490770999977],
              [-41.026647749999938, -8.82050570399997],
              [-41.026627990999941, -8.814194561999955],
              [-41.024872291999941, -8.808716285999935],
              [-41.024960110999984, -8.807727363999959],
              [-41.026326799999936, -8.805067741999949],
              [-41.027627294999945, -8.804274917999976],
              [-41.028708238999968, -8.802182769999945],
              [-41.032019294999941, -8.793793213999948],
              [-41.034515691999957, -8.792150916999958],
              [-41.036234503999935, -8.785875895999936],
              [-41.038120607999986, -8.784944540999959],
              [-41.039790663999952, -8.784119869999927],
              [-41.042318852999983, -8.784019308999973],
              [-41.04392672299997, -8.784406798999953],
              [-41.048376019999978, -8.786846960999981],
              [-41.054797973999939, -8.786916556999927],
              [-41.058568701999945, -8.785804646999964],
              [-41.064488745999938, -8.781355219999966],
              [-41.06667859099997, -8.781048092999981],
              [-41.069592042999943, -8.781730707999941],
              [-41.071462034999968, -8.783158626999978],
              [-41.07646112599997, -8.787874832999933],
              [-41.078542180999932, -8.788772350999977],
              [-41.082068755999956, -8.784338558999934],
              [-41.086552108999967, -8.783104408999975],
              [-41.092646801999933, -8.782728088999932],
              [-41.09486026999997, -8.781827560999943],
              [-41.095093259999942, -8.781732770999952],
              [-41.095588176999968, -8.781531418999975],
              [-41.097400667999977, -8.781350950999979],
              [-41.100813709999954, -8.782181609999952],
              [-41.101760798999976, -8.781964295999956],
              [-41.103351208999982, -8.780442621999953],
              [-41.103543909999985, -8.77700120999998],
              [-41.105067344999952, -8.774354453999933],
              [-41.105956507999963, -8.770551363999971],
              [-41.108963804999973, -8.770580932999962],
              [-41.111156301999983, -8.770090780999965],
              [-41.113097140999969, -8.768711262999943],
              [-41.115823640999963, -8.764866767999933],
              [-41.115888532999975, -8.763663101999953],
              [-41.11301629999997, -8.759726773999944],
              [-41.113289770999984, -8.758730765999928],
              [-41.113344620999953, -8.758530998999959],
              [-41.114874782999948, -8.756929399999933],
              [-41.117819340999972, -8.75510995999997],
              [-41.122459234999951, -8.754243656999961],
              [-41.124569291999933, -8.752673560999938],
              [-41.126820871999939, -8.746764879999944],
              [-41.128989162999972, -8.743393090999973],
              [-41.129070782999975, -8.740376689999948],
              [-41.126061989999982, -8.735314890999973],
              [-41.126840275999939, -8.728927350999982],
              [-41.125634939999941, -8.727219890999947],
              [-41.12301780599995, -8.725972403999947],
              [-41.121714990999976, -8.724322651999955],
              [-41.121372986999972, -8.722499039999946],
              [-41.122927852999965, -8.719941080999945],
              [-41.123201774999984, -8.718707223999957],
              [-41.121999885999969, -8.717344211999944],
              [-41.118259396999974, -8.715064797999958],
              [-41.114675413999976, -8.712234472999967],
              [-41.111921865999932, -8.708717540999942],
              [-41.111376137999969, -8.707259779999958],
              [-41.111845896999966, -8.705309503999956],
              [-41.112757958999964, -8.703974090999964],
              [-41.113709722999943, -8.703797228999974],
              [-41.116505002999929, -8.705044225999927],
              [-41.121429176999982, -8.705758663999973],
              [-41.126290951999977, -8.705809813999963],
              [-41.131324976999963, -8.704252869999948],
              [-41.132900028999984, -8.704324282999949],
              [-41.135843246999968, -8.705685585999959],
              [-41.142011734999983, -8.707147150999958],
              [-41.152614780999954, -8.707767032999925],
              [-41.162898741999982, -8.709985658999926],
              [-41.165757607999979, -8.710033070999941],
              [-41.167876429999978, -8.709133454999971],
              [-41.168629695999982, -8.708334519999937],
              [-41.172431162999942, -8.707208616999935],
              [-41.176404737999974, -8.711194277999937],
              [-41.177766061999932, -8.711058098999956],
              [-41.181429228999946, -8.708122818999925],
              [-41.182436289999941, -8.707818667999959],
              [-41.185992695999971, -8.710646339999926],
              [-41.188156228999958, -8.710266322999928],
              [-41.191206848999968, -8.707004293999944],
              [-41.197561917999963, -8.70652002199995],
              [-41.199875139999961, -8.706937745999937],
              [-41.202153743999929, -8.708488993999936],
              [-41.204931554999973, -8.709487107999962],
              [-41.209678781999969, -8.707353324999929],
              [-41.215080666999938, -8.706191936999971],
              [-41.219066419999933, -8.706439271999955],
              [-41.221592937999958, -8.706024977999959],
              [-41.225265705999959, -8.704669786999943],
              [-41.226978498999983, -8.704702090999945],
              [-41.229105154999957, -8.705415573999971],
              [-41.230167169999959, -8.706364390999966],
              [-41.232688105999955, -8.708617695999976],
              [-41.23468225299996, -8.709667731999957],
              [-41.239841042999956, -8.710863],
              [-41.240125316999979, -8.711927659999958],
              [-41.238240196999982, -8.716101352999942],
              [-41.237681922999968, -8.71876333299997],
              [-41.238567804999946, -8.719731159999981],
              [-41.240432754999972, -8.720178267999927],
              [-41.24434397999994, -8.719638007999947],
              [-41.246902912999985, -8.720000105999929],
              [-41.249176735999981, -8.721036415999947],
              [-41.251511760999961, -8.721431787999961],
              [-41.257739916999981, -8.720122998999955],
              [-41.260624594999967, -8.72071650099997],
              [-41.262989660999949, -8.721876777999967],
              [-41.265040043999932, -8.723857858999963],
              [-41.268421768999985, -8.729477303999943],
              [-41.269982846999937, -8.731013168999937],
              [-41.273652643999981, -8.731703105999941],
              [-41.278264836999938, -8.735313038999948],
              [-41.281537593999985, -8.736100377999946],
              [-41.284075783999981, -8.736038664999967],
              [-41.289120609999941, -8.734797667999942],
              [-41.294983552999952, -8.735545166999941],
              [-41.297234780999929, -8.734731978999946],
              [-41.299401225999929, -8.733052097999973],
              [-41.305100092999965, -8.727380098999959],
              [-41.309934882999983, -8.726131718999966],
              [-41.313042692999943, -8.725790622999966],
              [-41.315403541999956, -8.726151251999966],
              [-41.318486587999985, -8.726982435999957],
              [-41.32090788499994, -8.728231950999941],
              [-41.329815922999956, -8.728451015999951],
              [-41.33435121399998, -8.727661577999982],
              [-41.343464753999967, -8.722009315999969],
              [-41.354382804999943, -8.712442044999932],
              [-41.358336183999938, -8.707620177999956],
              [-41.352404098999955, -8.708773089999966],
              [-41.345418422999956, -8.709865107999974],
              [-41.342399833999934, -8.709543451999934],
              [-41.336916769999959, -8.708357127999932],
              [-41.332553511999947, -8.704283054999962],
              [-41.329580721999946, -8.702569713999935],
              [-41.321281105999958, -8.699652198999956],
              [-41.317655765999973, -8.698145576999934],
              [-41.314261551999948, -8.696102325999959],
              [-41.308305899999937, -8.690982081999948],
              [-41.30794300499997, -8.690586903999929],
              [-41.307943030999979, -8.690586879999955],
              [-41.300184686999955, -8.682137230999956],
              [-41.297759329999963, -8.677791954999975],
              [-41.296151492999968, -8.672482241999944],
              [-41.296131154999955, -8.672415046999959],
              [-41.296131359999947, -8.672414970999966],
              [-41.295455432999972, -8.670182466999961],
              [-41.291427643999953, -8.666463674999932],
              [-41.288997035999955, -8.661897643999964],
              [-41.287434021999957, -8.660839067999973],
              [-41.278906823999932, -8.66056297199998],
              [-41.276618470999949, -8.661257915999954],
              [-41.272007052999982, -8.662039824999965],
              [-41.268725532999952, -8.663224379999974],
              [-41.266237964999959, -8.662140136999938],
              [-41.264545255999963, -8.661951708999936],
              [-41.259471120999933, -8.662439282999969],
              [-41.253845382999941, -8.662175782999952],
              [-41.253213621999976, -8.66203399799997],
              [-41.252581860999953, -8.661892212999931],
              [-41.251318338999965, -8.661608642999965],
              [-41.249407543999951, -8.659558438999966],
              [-41.247406955999963, -8.658378762999973],
              [-41.243465820999972, -8.656709350999961],
              [-41.241632726999967, -8.656586068999957],
              [-41.239490502999956, -8.65582657799996],
              [-41.239186313999937, -8.654514950999953],
              [-41.239235290999943, -8.653748231999941],
              [-41.238200506999931, -8.652426949999949],
              [-41.237434187999952, -8.650805327999933],
              [-41.233788845999982, -8.647614319999946],
              [-41.231066669999962, -8.647146846999931],
              [-41.226990249999972, -8.64778591399994],
              [-41.225160868999978, -8.647051214999976],
              [-41.223331487999985, -8.646316516999946],
              [-41.221711720999963, -8.646177209999962],
              [-41.220932243999982, -8.646374345999959],
              [-41.217796196999984, -8.646020489999955],
              [-41.215043643999934, -8.645246426999961],
              [-41.213542854999957, -8.644155529999978],
              [-41.210292408999976, -8.640083919999938],
              [-41.206544138999959, -8.633295033999957],
              [-41.206649264999953, -8.622599022999964],
              [-41.206826372999956, -8.620237434999979],
              [-41.20799376399998, -8.618369854999969],
              [-41.20800557299998, -8.618351040999926],
              [-41.208006334999936, -8.618350837999969],
              [-41.208186036999962, -8.618063518999975],
              [-41.208600609999962, -8.616013389999978],
              [-41.207603786999982, -8.611931504999973],
              [-41.207746824999958, -8.610271676999957],
              [-41.209419005999962, -8.607406736999963],
              [-41.210571945999959, -8.606384544999969],
              [-41.212344435999967, -8.604253940999968],
              [-41.212628627999948, -8.602637512999934],
              [-41.212288359999945, -8.60182027299993],
              [-41.209944956999948, -8.601368967999974],
              [-41.209395262999976, -8.601015587999939],
              [-41.207485554999948, -8.600934200999973],
              [-41.204655298999967, -8.600259578999953],
              [-41.20287816299998, -8.599276527999962],
              [-41.200738083999966, -8.594410694999965],
              [-41.199703901999953, -8.592991746999928],
              [-41.198566167999957, -8.592477149999979],
              [-41.185246929999948, -8.592779501999928],
              [-41.181647104999968, -8.593641758999979],
              [-41.179906510999956, -8.593267364999974],
              [-41.179205164999985, -8.592591223999932],
              [-41.178628267999954, -8.588376880999931],
              [-41.177396495999972, -8.587476046999939],
              [-41.176248648999945, -8.586912562999942],
              [-41.175301140999977, -8.586950661999936],
              [-41.172921706999944, -8.58630656899993],
              [-41.171555916999978, -8.585630943999945],
              [-41.168745878999971, -8.583090561999938],
              [-41.166739380999957, -8.580682251999974],
              [-41.165261458999964, -8.577886774999968],
              [-41.163411715999985, -8.571736746999932],
              [-41.163172431999953, -8.570304930999953],
              [-41.15701205199997, -8.560439719999977],
              [-41.156723468999985, -8.558880102999979],
              [-41.157129385999951, -8.556436324999936],
              [-41.156410395999956, -8.552516193999963],
              [-41.155093257999965, -8.550894143999926],
              [-41.15417202499998, -8.55046843699995],
              [-41.152650527999981, -8.549765342999933],
              [-41.152045851999958, -8.548305877999951],
              [-41.152317104999952, -8.546051112999976],
              [-41.152444966999951, -8.544991454999945],
              [-41.152917128999945, -8.543508592999956],
              [-41.152486408999948, -8.54147652599994],
              [-41.151242576999948, -8.540157072999932],
              [-41.148286803999952, -8.53869571599995],
              [-41.148116312999946, -8.538177288999975],
              [-41.14912047599995, -8.536469767999961],
              [-41.149493545999974, -8.533917278999979],
              [-41.148376816999985, -8.533029254999974],
              [-41.147393497999985, -8.53297538299995],
              [-41.142989444999955, -8.534283343999959],
              [-41.140070822999974, -8.532853251999938],
              [-41.139173828999958, -8.532666208999956],
              [-41.134627347999981, -8.533538644999965],
              [-41.132502959999954, -8.535528443999965],
              [-41.131270550999943, -8.537050994999959],
              [-41.129605922999986, -8.540855541999974],
              [-41.128791303999947, -8.541504433999933],
              [-41.128269640999974, -8.54177088199998],
              [-41.126732858999958, -8.541955863999931],
              [-41.118992556999956, -8.537525184999936],
              [-41.116184846999943, -8.537269064999975],
              [-41.114417850999985, -8.534904388999962],
              [-41.111463992999973, -8.533631088999925],
              [-41.108377464999933, -8.530732019999959],
              [-41.104072203999976, -8.522368652999944],
              [-41.102563066999949, -8.521798095999941],
              [-41.100011006999978, -8.521803644999977],
              [-41.097957582999982, -8.520751172999951],
              [-41.095584022999958, -8.518532964999963],
              [-41.094008394999946, -8.516314164999926],
              [-41.09048992299995, -8.512625540999977],
              [-41.085976628999958, -8.50355572999996],
              [-41.084684140999968, -8.500958376999961],
              [-41.08326597599995, -8.496622209999941],
              [-41.08225403299997, -8.491135102999976],
              [-41.081799697999941, -8.484692578999955],
              [-41.082194425999944, -8.480081499999926],
              [-41.079916632999982, -8.473650751999969],
              [-41.07892697099993, -8.472503234999976],
              [-41.076549439999951, -8.472490020999942],
              [-41.065403938999964, -8.475157954999929],
              [-41.057595172999982, -8.477657987999976],
              [-41.056676383999957, -8.478222181999968],
              [-41.055955155999982, -8.479389328999957],
              [-41.053232913999977, -8.481119140999965],
              [-41.052203630999941, -8.481218188999947],
              [-41.051098568999976, -8.480473777999975],
              [-41.05026053499995, -8.477944825999941],
              [-41.04996741399998, -8.475504121999961],
              [-41.050231704999931, -8.47238342199995],
              [-41.05159542399997, -8.465119738999931],
              [-41.051306346999979, -8.463358680999931],
              [-41.049673565999967, -8.459773388999963],
              [-41.047975171999951, -8.449046009999961],
              [-41.047754521999934, -8.447651794999956],
              [-41.047753548999935, -8.44764564999997],
              [-41.04751052599994, -8.446110071999954],
              [-41.04508775499994, -8.439960640999971],
              [-41.044127259999982, -8.438811610999949],
              [-41.043717840999932, -8.43665671399998],
              [-41.038990571999932, -8.431829200999971],
              [-41.03402908399994, -8.430107412999973],
              [-41.023648640999966, -8.428475618999926],
              [-41.022106032999943, -8.427507315999947],
              [-41.020312782999952, -8.424686787999974],
              [-41.017680219999932, -8.424084834999974],
              [-41.015528521999954, -8.42417250099993],
              [-41.011042034999946, -8.422513685999945],
              [-41.00737983199997, -8.420929114999979],
              [-41.006925406999983, -8.417665743999976],
              [-41.005394788999979, -8.415594991999967],
              [-41.003097928999978, -8.413695426999936],
              [-41.002344854999933, -8.411790067999959],
              [-41.00220633999993, -8.410515964999945],
              [-41.002802317999965, -8.407668133999948],
              [-41.003356488999941, -8.405020079999929],
              [-41.003234692999968, -8.404002385999945],
              [-41.000675558999944, -8.400726034999934],
              [-40.999953209999944, -8.400299564999955],
              [-40.986962637999966, -8.399441706999937],
              [-40.983610577999968, -8.398227331999976],
              [-40.981379424999943, -8.397929560999955],
              [-40.979230025999982, -8.398248448999936],
              [-40.978545009999948, -8.398650711999949],
              [-40.972901038999964, -8.406933466999931],
              [-40.969290335999972, -8.410283898999978],
              [-40.966183108999985, -8.411595872999953],
              [-40.96555809399996, -8.412128800999938],
              [-40.964904462999982, -8.41342300599996],
              [-40.965508953999972, -8.416458741999975],
              [-40.964302054999962, -8.417430686999978],
              [-40.961957114999962, -8.417537434999929],
              [-40.960768187999975, -8.419101868999974],
              [-40.957878010999934, -8.419398238999975],
              [-40.957498767999937, -8.419782933999954],
              [-40.958091813999943, -8.422457440999949],
              [-40.957094355999971, -8.423466969999936],
              [-40.953581432999954, -8.424706392999951],
              [-40.952377834999936, -8.426964496999972],
              [-40.950199045999966, -8.429150377999974],
              [-40.949593640999979, -8.42955858299996],
              [-40.940515969999979, -8.433234470999935],
              [-40.938094023999952, -8.434980017999976],
              [-40.937034290999975, -8.436826605999954],
              [-40.935812986999963, -8.438954732999946],
              [-40.930506948999948, -8.443650443999957],
              [-40.926851149999948, -8.446344418999956],
              [-40.925149036999983, -8.446326065999926],
              [-40.923846856999944, -8.445056741999963],
              [-40.919956327999955, -8.439030128999946],
              [-40.916848147999985, -8.436160305999977],
              [-40.915346908999936, -8.43627375899996],
              [-40.914398609999978, -8.436239376999936],
              [-40.913368145999982, -8.436202565999963],
              [-40.912736106999944, -8.436179987999935],
              [-40.912080790999937, -8.43536396899998],
              [-40.910035175999951, -8.428925792999962],
              [-40.909008599999936, -8.427329500999974],
              [-40.903491370999973, -8.423853538999936],
              [-40.903491429999974, -8.423853432999977],
              [-40.902850462999936, -8.423449593999976],
              [-40.902293701999952, -8.423098983999978],
              [-40.901535449999983, -8.421627003999959],
              [-40.901290512999935, -8.420261682999978],
              [-40.902452480999955, -8.418165066999961],
              [-40.902516296999977, -8.415793736999944],
              [-40.901509820999934, -8.41012610599995],
              [-40.901849181999978, -8.407957766999971],
              [-40.903369140999985, -8.405722776999937],
              [-40.906918902999962, -8.402389366999955],
              [-40.907787546999941, -8.401374135999959],
              [-40.907615489999955, -8.398987179999949],
              [-40.90641319599996, -8.397452356999963],
              [-40.902428344999976, -8.39708505599998],
              [-40.89947075699996, -8.395020621999947],
              [-40.894519086999935, -8.392502628999978],
              [-40.894108782999979, -8.389962552999975],
              [-40.890751465999983, -8.38789935899996],
              [-40.889870383999948, -8.386749867999981],
              [-40.889611531999947, -8.382591119999972],
              [-40.89039841999994, -8.380507027999954],
              [-40.892377732999933, -8.378032907999966],
              [-40.892769439999938, -8.376720915999954],
              [-40.892202750999957, -8.374421005999977],
              [-40.892666482999971, -8.37179284399997],
              [-40.897103975999983, -8.369047967999961],
              [-40.897746509999934, -8.367917786999953],
              [-40.897660084999984, -8.366532091999943],
              [-40.894833560999984, -8.358766961999947],
              [-40.893472304999932, -8.357310139999981],
              [-40.89163442399996, -8.356085758999939],
              [-40.889086082999938, -8.356174559999943],
              [-40.881525336999971, -8.357675590999975],
              [-40.87252303799994, -8.35741012699998],
              [-40.869981075999931, -8.35853870699998],
              [-40.867527920999976, -8.362207210999941],
              [-40.866179401999943, -8.363446647999979],
              [-40.864507737999929, -8.364331681999943],
              [-40.862497633999965, -8.36499145199997],
              [-40.861789460999944, -8.370654578999961],
              [-40.859560495999972, -8.373573480999937],
              [-40.857345876999943, -8.37564447799997],
              [-40.85926393699998, -8.377710214999979],
              [-40.85942923999994, -8.378482768999959],
              [-40.85859127699996, -8.379610767999964],
              [-40.856400730999951, -8.379910912999947],
              [-40.854011383999932, -8.379648162999956],
              [-40.850212118999934, -8.380528283999979],
              [-40.845744714999967, -8.379214803999957],
              [-40.843901504999963, -8.379133761999981],
              [-40.837401475999968, -8.381126045999963],
              [-40.836565978999943, -8.380799086999957],
              [-40.83220847299998, -8.376248705999956],
              [-40.828703640999947, -8.373780560999933],
              [-40.825978910999936, -8.369444787999953],
              [-40.825978964999933, -8.369444662999967],
              [-40.825283320999972, -8.368337652999969],
              [-40.825102646999937, -8.368050172999972],
              [-40.825102504999961, -8.36805018299998],
              [-40.823431847999984, -8.365391666999926],
              [-40.820098344999963, -8.363493526999946],
              [-40.819318709999948, -8.362357267999926],
              [-40.819205691999969, -8.360906740999951],
              [-40.820760267999958, -8.356618400999935],
              [-40.819316367999932, -8.352083727999968],
              [-40.81914605999998, -8.349116608999964],
              [-40.819563240999969, -8.346714307999946],
              [-40.821985583999947, -8.341209932999959],
              [-40.822676701999967, -8.337723160999928],
              [-40.822203826999953, -8.336139308999975],
              [-40.818402251999942, -8.33014419899996],
              [-40.818369600999972, -8.328915839999979],
              [-40.819875145999958, -8.325033992999977],
              [-40.819509155999981, -8.321908924999946],
              [-40.817896111999971, -8.320490169999971],
              [-40.802420814999948, -8.321939786999963],
              [-40.798260581999955, -8.32170371899997],
              [-40.792787743999952, -8.320031299999926],
              [-40.791821275999951, -8.319072564999942],
              [-40.791180562999955, -8.317323899999963],
              [-40.791180676999943, -8.313321487999929],
              [-40.786433924999983, -8.306440563999956],
              [-40.783704903999933, -8.30389536399997],
              [-40.780506558999946, -8.302837183999941],
              [-40.779879760999961, -8.301932713999975],
              [-40.779479980999952, -8.298441194999953],
              [-40.780089630999953, -8.296272901999941],
              [-40.781678384999964, -8.293115397999941],
              [-40.779451969999968, -8.290426150999963],
              [-40.778981504999933, -8.287486871999931],
              [-40.778235369999948, -8.286137819999965],
              [-40.778762290999964, -8.283995054999934],
              [-40.781256888999962, -8.280072455999971],
              [-40.781120585999929, -8.277987602999929],
              [-40.780258161999939, -8.275808814999948],
              [-40.78164288499994, -8.270837445999973],
              [-40.781788534999976, -8.268443442999967],
              [-40.783982742999967, -8.261848675999943],
              [-40.783565052999961, -8.25543515499993],
              [-40.782481989999951, -8.253303996999932],
              [-40.781126646999951, -8.252590439999949],
              [-40.772842700999945, -8.251000831999932],
              [-40.769318620999968, -8.249458534999974],
              [-40.766838439999958, -8.247984210999959],
              [-40.764292685999976, -8.243993791999969],
              [-40.764011430999972, -8.243798091999963],
              [-40.763730175999967, -8.243602391999957],
              [-40.763167665999958, -8.243210991999945],
              [-40.759613089999959, -8.243054346999941],
              [-40.755703560999962, -8.241713914999934],
              [-40.754802919999975, -8.241741919999981],
              [-40.752095394999969, -8.241826108999931],
              [-40.749693909999962, -8.240443409999955],
              [-40.744504745999961, -8.240071892999936],
              [-40.743207195999958, -8.239021780999963],
              [-40.74239709699998, -8.234948258999964],
              [-40.73934367499993, -8.232357392999972],
              [-40.738558981999972, -8.23032167599996],
              [-40.736868910999931, -8.228232170999945],
              [-40.733683048999978, -8.227756860999932],
              [-40.73108826999993, -8.226560204999942],
              [-40.729197941999985, -8.224914689999935],
              [-40.724593124999956, -8.223436571999969],
              [-40.722726220999959, -8.223245179999935],
              [-40.718091610999977, -8.224791694999965],
              [-40.714706658999944, -8.224457967999967],
              [-40.711061097999959, -8.225061465999943],
              [-40.707255783999983, -8.223493552999969],
              [-40.705471943999953, -8.22053150399995],
              [-40.705471843999931, -8.220531562999952],
              [-40.703423294999936, -8.21712974299993],
              [-40.703642380999952, -8.208598099999961],
              [-40.701895283999932, -8.204198997999981],
              [-40.700587980999956, -8.202607696999962],
              [-40.694373853999934, -8.200876262999941],
              [-40.692396112999973, -8.196745748999945],
              [-40.691203927999936, -8.195456070999967],
              [-40.688624108999932, -8.194252218999964],
              [-40.686818675999973, -8.19227488699994],
              [-40.684412065999936, -8.187776500999973],
              [-40.677261080999983, -8.18604513799994],
              [-40.675256303999959, -8.184861603999934],
              [-40.673003340999969, -8.180815113999927],
              [-40.670252232999985, -8.17925517499998],
              [-40.669029256999977, -8.177480884999966],
              [-40.66781306799993, -8.175344672999927],
              [-40.667253260999985, -8.16444981199993],
              [-40.666684044999954, -8.16235028899996],
              [-40.666645838999955, -8.162209067999981],
              [-40.66647820399993, -8.161589427999957],
              [-40.658505714999933, -8.156556256999977],
              [-40.652840810999976, -8.157694424999931],
              [-40.649482525999929, -8.156856231999939],
              [-40.645199176999938, -8.158083525999928],
              [-40.643566117999967, -8.157536811999933],
              [-40.642703572999949, -8.155038618999981],
              [-40.642780475999984, -8.150074545999928],
              [-40.639063000999954, -8.148152641999957],
              [-40.638314709999975, -8.147216751999963],
              [-40.638168076999932, -8.146304952999969],
              [-40.637870495999948, -8.144454526999937],
              [-40.636841576999984, -8.142095581999968],
              [-40.633951189999948, -8.13944878999996],
              [-40.632840366999972, -8.139301283999941],
              [-40.628702269999962, -8.141073330999973],
              [-40.62448214799997, -8.14035383099997],
              [-40.621875425999974, -8.141218126999945],
              [-40.617808956999966, -8.143608062999931],
              [-40.614422619999971, -8.146502507999969],
              [-40.61053495699997, -8.146609259999934],
              [-40.604702911999937, -8.149627479999936],
              [-40.601317944999948, -8.150170463999928],
              [-40.601007316999983, -8.149262811999961],
              [-40.602039803999958, -8.146013711999956],
              [-40.60114026499997, -8.144050283999945],
              [-40.597558915999969, -8.141170831999943],
              [-40.591175456999963, -8.139616039999964],
              [-40.589698421999969, -8.138808338999979],
              [-40.589502701999947, -8.137804889999927],
              [-40.590922858999932, -8.128810058999932],
              [-40.591235274999974, -8.123516301999928],
              [-40.594146247999959, -8.120011286999954],
              [-40.596500168999967, -8.118025484999976],
              [-40.596728491999954, -8.11783276999995],
              [-40.596728397999982, -8.117832337999971],
              [-40.599344670999983, -8.115624594999929],
              [-40.601181107999935, -8.107497121999927],
              [-40.602255844999945, -8.105426136999938],
              [-40.603867671999978, -8.103687685999944],
              [-40.601533504999963, -8.099622472999954],
              [-40.603123170999936, -8.095330389999958],
              [-40.601089952999985, -8.09119471899993],
              [-40.598756769999966, -8.080857682999977],
              [-40.592008173999943, -8.066083356999968],
              [-40.582236423999973, -8.044690611999954],
              [-40.58221937899998, -8.04469169999993],
              [-40.580467017999979, -8.040816949999964],
              [-40.57585727999998, -8.030725107999956],
              [-40.575739959999964, -8.030468264999968],
              [-40.575788315999944, -8.030471593999948],
              [-40.575766863999945, -8.030424160999928],
              [-40.575752361999946, -8.030392093999978],
              [-40.575752039999941, -8.030391382999937],
              [-40.575749143999985, -8.030384978999962],
              [-40.57554321899994, -8.029929644999982],
              [-40.562316420999935, -8.000683084999935],
              [-40.560663046999935, -7.997286539999948],
              [-40.554120532999946, -7.983846180999933],
              [-40.549450768999975, -7.975029543999938],
              [-40.544222056999956, -7.964597073999926],
              [-40.543645574999971, -7.958432530999971],
              [-40.544011348999959, -7.953019908999977],
              [-40.54547329199994, -7.944348039999966],
              [-40.545506062999948, -7.94378837499994],
              [-40.545908144999942, -7.936921643999938],
              [-40.543670377999945, -7.911674603999927],
              [-40.543669139999963, -7.911674495999932],
              [-40.541475008999953, -7.886933345999978],
              [-40.538354782999932, -7.873890038999946],
              [-40.537942959999953, -7.869194734999951],
              [-40.538060604999941, -7.86122998999997],
              [-40.538060763999965, -7.861229875999925],
              [-40.538124010999979, -7.856937304999974],
              [-40.540458503999957, -7.84599312499995],
              [-40.540458981999961, -7.84599319299997],
              [-40.542027329999939, -7.838642273999938],
              [-40.54205449899996, -7.838572570999929],
              [-40.544288301999984, -7.83284150399993],
              [-40.545569148999959, -7.830707758999949],
              [-40.545569444999956, -7.830707804999975],
              [-40.545944227999939, -7.830083617999946],
              [-40.547514760999945, -7.827466590999961],
              [-40.551296007999952, -7.822431068999947],
              [-40.55486007199994, -7.818430510999974],
              [-40.55909352499998, -7.81484599199996],
              [-40.567956663999951, -7.809051574999955],
              [-40.568404774999976, -7.808758615999977],
              [-40.578451409999957, -7.805207947999975],
              [-40.604380721999974, -7.798676162999925],
              [-40.628643894999982, -7.792564090999974],
              [-40.639027609999971, -7.789239424999948],
              [-40.645166514999971, -7.786798962999967],
              [-40.653734014999941, -7.782193005999943],
              [-40.653734338999982, -7.782193180999968],
              [-40.663785041999972, -7.774761814999977],
              [-40.67001715899994, -7.767904655999928],
              [-40.672072315999969, -7.764829004999967],
              [-40.673569475999955, -7.761671946999968],
              [-40.675657000999934, -7.752683238999964],
              [-40.675470135999944, -7.74860422699993],
              [-40.675597793999941, -7.747817770999973],
              [-40.675274693999938, -7.74686074899995],
              [-40.675114569999948, -7.745543918999942],
              [-40.674415063999959, -7.742687254999964],
              [-40.673484365999968, -7.739373333999936],
              [-40.671864333999963, -7.735605848999967],
              [-40.67173529799993, -7.735363627999959],
              [-40.669553272999963, -7.731267599999967],
              [-40.666552911999929, -7.726361321999946],
              [-40.66102572099993, -7.720549281999979],
              [-40.658035692999931, -7.718273437999926],
              [-40.654089338999938, -7.715932156999941],
              [-40.651139508999961, -7.714182089999952],
              [-40.648724473999948, -7.71236166999995],
              [-40.643777575999934, -7.70814822799997],
              [-40.639289595999969, -7.704162028999974],
              [-40.635603488999948, -7.699829394999938],
              [-40.633758330999967, -7.696861827999953],
              [-40.630871393999939, -7.691725523999935],
              [-40.627749729999948, -7.684989488999975],
              [-40.625541914999985, -7.677175747999968],
              [-40.624408883999934, -7.673312971999962],
              [-40.623665530999972, -7.669913052999959],
              [-40.622262731999967, -7.661550124999962],
              [-40.622169815999939, -7.660996196999974],
              [-40.621830322999983, -7.658323847999952],
              [-40.621717986999954, -7.655379127999936],
              [-40.621782909999979, -7.653533845999959],
              [-40.621989729999939, -7.651359868999975],
              [-40.62264541199994, -7.647469354999942],
              [-40.623197152999978, -7.644856493999953],
              [-40.624031316999947, -7.640906172999962],
              [-40.624820147999969, -7.637672373999976],
              [-40.626561121999941, -7.631683568999961],
              [-40.626887533999934, -7.63087371499995],
              [-40.632104130999949, -7.617930953999974],
              [-40.636734998999941, -7.608517187999951],
              [-40.644375994999962, -7.596643230999973],
              [-40.644376612999963, -7.596642871999961],
              [-40.64558150299996, -7.594769895999946],
              [-40.649118152999961, -7.590764233999948],
              [-40.651213887999972, -7.588553980999961],
              [-40.653826232999961, -7.586203189999935],
              [-40.653883568999959, -7.586155331999976],
              [-40.65392127399997, -7.586121454999954],
              [-40.654073313999959, -7.585994617999972],
              [-40.65908634699997, -7.581812595999963],
              [-40.662657445999969, -7.579499928999951],
              [-40.665628440999967, -7.576227444999972],
              [-40.667602786999964, -7.57398498799995],
              [-40.667603351999958, -7.573984344999928],
              [-40.667812106999975, -7.573747242999957],
              [-40.667918980999957, -7.573625809999953],
              [-40.677320846999976, -7.56294321699994],
              [-40.681336199999976, -7.557738265999944],
              [-40.693939029999967, -7.538930185999959],
              [-40.694080596999981, -7.538718915999937],
              [-40.695247774999984, -7.536807077999981],
              [-40.701203864999968, -7.525785804999941],
              [-40.70429405699997, -7.518708051999965],
              [-40.708625533999964, -7.50588900899993],
              [-40.709331040999984, -7.50380105499994],
              [-40.710142182999959, -7.501399080999931],
              [-40.710590637999985, -7.500878293999961],
              [-40.71378604399996, -7.493910559999961],
              [-40.715293977999977, -7.487954017999925],
              [-40.715536483999983, -7.484437275999937],
              [-40.715141028999938, -7.481414650999966],
              [-40.714936855999952, -7.480517758999952],
              [-40.714936608999949, -7.480517772999974],
              [-40.713226379999981, -7.473003989999938],
              [-40.70608929499997, -7.460045226999966],
              [-40.704897762999963, -7.458737062999944],
              [-40.704629603999933, -7.458564346999935],
              [-40.704629350999937, -7.458564426999942],
              [-40.701201426999944, -7.456356332999974],
              [-40.674861929999963, -7.453070360999959],
              [-40.672010631999967, -7.452714648999972],
              [-40.667656713999975, -7.451493063999976],
              [-40.667291061999947, -7.451390472999947],
              [-40.665705352999964, -7.450945567999952],
              [-40.663399277999929, -7.449410975999967],
              [-40.662534144999938, -7.448186729999975],
              [-40.66246794999995, -7.448093057999927],
              [-40.662467706999962, -7.448092713999927],
              [-40.662467636999963, -7.44809261499995],
              [-40.662467548999985, -7.448092490999954],
              [-40.662467362999962, -7.44809222799995],
              [-40.662467274999983, -7.448092102999965],
              [-40.662467208999942, -7.448092009999925],
              [-40.66246709099994, -7.448091842999929],
              [-40.662467066999966, -7.448091808999948],
              [-40.662466973999983, -7.448091677999969],
              [-40.662466954999957, -7.44809165099997],
              [-40.662466797999969, -7.448091427999941],
              [-40.662466711999969, -7.44809130699997],
              [-40.662466365999933, -7.448090816999979],
              [-40.662465963999978, -7.448090247999971],
              [-40.662465537999935, -7.448089644999925],
              [-40.662464356999976, -7.448087972999929],
              [-40.662463857999967, -7.448087265999959],
              [-40.662462578999964, -7.448085456999934],
              [-40.66246102599996, -7.44808325899993],
              [-40.66245915899998, -7.44808061699996],
              [-40.662458811999954, -7.448080125999979],
              [-40.662457489999952, -7.448078254999928],
              [-40.662455880999971, -7.448075977999963],
              [-40.662454532999959, -7.44807407199994],
              [-40.66245272499998, -7.448070402999974],
              [-40.662451505999968, -7.448067928999933],
              [-40.662450374999935, -7.448065634999978],
              [-40.662449185999947, -7.448063220999927],
              [-40.662448157999961, -7.448061135999978],
              [-40.662447087999965, -7.448058963999927],
              [-40.662446265999961, -7.448057294999956],
              [-40.66244585499993, -7.448056461999954],
              [-40.662445138999942, -7.448055007999926],
              [-40.662444925999978, -7.448054576999937],
              [-40.66244364399995, -7.448051974999942],
              [-40.662442754999972, -7.448050170999977],
              [-40.662441310999952, -7.448047239999937],
              [-40.662440152999977, -7.448044889999949],
              [-40.662438891999955, -7.448042330999954],
              [-40.662437586999943, -7.448039682999934],
              [-40.662436234999973, -7.448036939999952],
              [-40.662435005999953, -7.448034444999962],
              [-40.662416894999978, -7.447997689999966],
              [-40.662364089999983, -7.447890525999981],
              [-40.662318958999947, -7.44779893599997],
              [-40.662212810999961, -7.447583519999966],
              [-40.662152753999976, -7.447461637999936],
              [-40.662151500999983, -7.447459095999932],
              [-40.661994898999978, -7.447228798999959],
              [-40.661848469999939, -7.447013461999973],
              [-40.661847541999975, -7.447011360999966],
              [-40.661846614999945, -7.447009260999948],
              [-40.661711002999937, -7.446702139999957],
              [-40.661612291999973, -7.44647858899998],
              [-40.661545437999962, -7.446327184999973],
              [-40.661528607999969, -7.446298033999938],
              [-40.66151177699993, -7.446268882999959],
              [-40.661384582999972, -7.446173190999957],
              [-40.661342388999969, -7.446086623999975],
              [-40.661067220999939, -7.445522082999958],
              [-40.661059818999945, -7.445506896999973],
              [-40.661033629999963, -7.445453166999926],
              [-40.660703515999955, -7.444876489999956],
              [-40.660605058999977, -7.444704495999929],
              [-40.660603465999941, -7.444701711999926],
              [-40.660572051999964, -7.444646834999958],
              [-40.660253940999951, -7.444188947999976],
              [-40.659919248999984, -7.443707194999945],
              [-40.659792623999977, -7.443524930999956],
              [-40.659743565999975, -7.44346380199994],
              [-40.659662246999972, -7.443362473999969],
              [-40.659633635999967, -7.443346499999961],
              [-40.659499952999965, -7.443162193999967],
              [-40.659319591999974, -7.442913534999946],
              [-40.659266163999973, -7.442839874999947],
              [-40.65924453699995, -7.442810058999953],
              [-40.658891677999975, -7.442290067999977],
              [-40.65888694399996, -7.442283090999979],
              [-40.658873783999979, -7.442263697999977],
              [-40.658869832999983, -7.442257875999928],
              [-40.658538817999954, -7.441770076999944],
              [-40.65815353399995, -7.441144942999927],
              [-40.657749440999964, -7.440553425999951],
              [-40.657746614999951, -7.440549288999932],
              [-40.657718657999965, -7.44050836599996],
              [-40.657219726999983, -7.439870936999966],
              [-40.657165526999961, -7.439801692999936],
              [-40.656703948999962, -7.43935823399994],
              [-40.65614318799993, -7.439026832999957],
              [-40.655814896999971, -7.43887545399997],
              [-40.655764851999947, -7.438852377999979],
              [-40.655456542999957, -7.438710212999979],
              [-40.654141169999946, -7.438121997999929],
              [-40.654055769999957, -7.438083808999977],
              [-40.652323225999965, -7.433342164999942],
              [-40.639567376999935, -7.428349348999973],
              [-40.639566551999962, -7.428349116999925],
              [-40.58924258199994, -7.408651973999952],
              [-40.587321460999931, -7.407900063999932],
              [-40.548463712999933, -7.392691465999974],
              [-40.542670481999949, -7.360373092999964],
              [-40.540540502999932, -7.344849755999974],
              [-40.540422255999943, -7.341403285999945],
              [-40.541894107999951, -7.335826422999958],
              [-40.54150819399996, -7.332269118999932],
              [-40.540224834999947, -7.330621166999947],
              [-40.536273394999967, -7.32712558399993],
              [-40.523723210999947, -7.318450482999936],
              [-40.523678614999938, -7.318461855999942],
              [-40.51659312399994, -7.320268766999959],
              [-40.516572001999975, -7.320277345999955],
              [-40.510286471999962, -7.322830193999948],
              [-40.506750446999945, -7.324919358999978],
              [-40.500468611999963, -7.327995336999948],
              [-40.487013776999959, -7.336459859999934],
              [-40.48694048699997, -7.336505966999937],
              [-40.482868071999974, -7.339290209999945],
              [-40.476247497999964, -7.344726958999956],
              [-40.476246474999982, -7.344727575999968],
              [-40.476154497999971, -7.344783094999968],
              [-40.46673711699998, -7.350467591999973],
              [-40.459308379999982, -7.35495170799993],
              [-40.459073063999938, -7.355093748999934],
              [-40.449055047999934, -7.361125528999935],
              [-40.448672318999968, -7.361275337999928],
              [-40.447942187999956, -7.361561127999948],
              [-40.433997630999954, -7.367019340999946],
              [-40.433591706999948, -7.367178228999933],
              [-40.433584909999979, -7.367178644999967],
              [-40.411026140999979, -7.368558363999966],
              [-40.400349562999963, -7.36853229899998],
              [-40.39387813899998, -7.368168385999979],
              [-40.387480494999977, -7.36703478499993],
              [-40.374522268999954, -7.362987701999941],
              [-40.372769566999978, -7.362440374999949],
              [-40.360983979999958, -7.355600792999951],
              [-40.360983841999939, -7.355600637999942],
              [-40.358223606999957, -7.352492069999926],
              [-40.355455358999961, -7.347275731999957],
              [-40.354790400999946, -7.345784384999945],
              [-40.35412544299993, -7.344293036999943],
              [-40.352343539999936, -7.337686699999949],
              [-40.350793594999971, -7.334171400999935],
              [-40.346820148999939, -7.33023701299993],
              [-40.346085777999974, -7.329699633999951],
              [-40.345351407999942, -7.329162254999972],
              [-40.338015393999967, -7.323794091999957],
              [-40.337982355999941, -7.323774024999977],
              [-40.329394374999936, -7.318557856999973],
              [-40.32652074799995, -7.314834937999933],
              [-40.325523645999965, -7.312277783999946],
              [-40.321431443999984, -7.305891928999927],
              [-40.31856315999994, -7.303340395999953],
              [-40.318144551999978, -7.303399485999932],
              [-40.317021600999965, -7.303557998999963],
              [-40.317021594999972, -7.303558],
              [-40.310740829999929, -7.300697384999978],
              [-40.308760812999935, -7.301234590999968],
              [-40.303584401999956, -7.300902009999959],
              [-40.303566780999972, -7.300900877999936],
              [-40.303549159999932, -7.30089974599997],
              [-40.300836145999938, -7.300725435999937],
              [-40.296544512999958, -7.30095154299994],
              [-40.293899435999947, -7.299999905999925],
              [-40.283880817999943, -7.299174491999963],
              [-40.279003283999941, -7.299391057999969],
              [-40.278968606999968, -7.299395518999972],
              [-40.268044523999947, -7.300800858999935],
              [-40.262995606999937, -7.301020938999955],
              [-40.262973470999952, -7.301033814999926],
              [-40.260163739999939, -7.302668180999945],
              [-40.25569576099997, -7.306199346999961],
              [-40.247533436999959, -7.31371863399994],
              [-40.241971724999985, -7.317995221999979],
              [-40.232318648999978, -7.329875004999963],
              [-40.229366762999973, -7.333507812999926],
              [-40.220759991999955, -7.34127030999997],
              [-40.213938482999936, -7.345952031999957],
              [-40.195684670999981, -7.358148691999929],
              [-40.18765297799996, -7.361207379999939],
              [-40.187638726999978, -7.361210928999981],
              [-40.180499650999934, -7.362988933999929],
              [-40.180469068999969, -7.363000020999948],
              [-40.171026773999984, -7.366423159999954],
              [-40.161817923999934, -7.369582631999947],
              [-40.157133418999933, -7.371456986999931],
              [-40.150945849999971, -7.373992697999938],
              [-40.137651698999946, -7.379440737999971],
              [-40.137632551999957, -7.379445524999937],
              [-40.127754320999941, -7.381914990999974],
              [-40.110006817999931, -7.38254724899997],
              [-40.087454494999974, -7.383093066999947],
              [-40.076450632999979, -7.382825740999976],
              [-40.060366183999975, -7.381688692999944],
              [-40.057307768999976, -7.381533237999975],
              [-40.051146785999947, -7.381220084999939],
              [-40.045163711999976, -7.380744980999964],
              [-40.014538844999947, -7.376504344999944],
              [-40.014538778999963, -7.376504333999947],
              [-40.009089530999972, -7.375567322999927],
              [-40.006102315999954, -7.37505366399995],
              [-40.006102278999947, -7.375053657999956],
              [-40.000707027999965, -7.374478339999939],
              [-39.995406386999946, -7.372138848999953],
              [-39.994031131999975, -7.370770222999965],
              [-39.97597112099993, -7.363462374999926],
              [-39.961386021999942, -7.357078559999934],
              [-39.952945679999971, -7.353865453999958],
              [-39.944209034999972, -7.349185106999926],
              [-39.938419476999968, -7.347239663999972],
              [-39.931546903999958, -7.34343587199993],
              [-39.926344967999967, -7.340998906999971],
              [-39.915442312999971, -7.33808980799995],
              [-39.910385313999939, -7.337210297999945],
              [-39.904559649999953, -7.338343358999964],
              [-39.897669853999957, -7.339682755999945],
              [-39.890345513999932, -7.339824601999965],
              [-39.890130613999929, -7.339828763999947],
              [-39.890119670999979, -7.33982728899997],
              [-39.890090524999948, -7.339823359999968],
              [-39.890090106999935, -7.339823303999935],
              [-39.886281705999977, -7.339309973999946],
              [-39.883503937999933, -7.338056244999962],
              [-39.874831606999976, -7.332469467999942],
              [-39.86661100699996, -7.32915282099998],
              [-39.863953225999978, -7.327692084999967],
              [-39.857696789999977, -7.325428691999946],
              [-39.851448073999961, -7.325209905999941],
              [-39.848139063999952, -7.325093969999955],
              [-39.848110371999951, -7.32510793299997],
              [-39.839834850999978, -7.329135232999931],
              [-39.839804786999935, -7.329143963999968],
              [-39.828695768999978, -7.332370195999943],
              [-39.824090996999985, -7.331831521999959],
              [-39.818334135999976, -7.32914412599996],
              [-39.815367519999938, -7.328694211999959],
              [-39.815333948999978, -7.32870881499997],
              [-39.81202041399996, -7.330150218999961],
              [-39.812010638999936, -7.330150661999937],
              [-39.806462259999932, -7.330402300999936],
              [-39.803723106999939, -7.330119956999965],
              [-39.800805218999983, -7.330433536999976],
              [-39.80076857499995, -7.330446759999973],
              [-39.797008656999935, -7.331803556999944],
              [-39.797005396999964, -7.331803874999935],
              [-39.794136241999979, -7.332083342999965],
              [-39.789226969999959, -7.33199510299994],
              [-39.780366269999945, -7.329745311999943],
              [-39.776245444999972, -7.328030757999954],
              [-39.776245391999964, -7.328030751999961],
              [-39.771264409999958, -7.32747261399993],
              [-39.770227416999944, -7.32735641499994],
              [-39.76042683299994, -7.326258222999968],
              [-39.760426715999984, -7.326258209999935],
              [-39.753045449999945, -7.326622230999931],
              [-39.741582068999946, -7.32681230299994],
              [-39.74132210099998, -7.326816613999938],
              [-39.736052164999933, -7.328405612999973],
              [-39.727621339999985, -7.33122952399998],
              [-39.727621172999932, -7.331229574999952],
              [-39.715496938999934, -7.334924503999957],
              [-39.70618897099996, -7.337761159999957],
              [-39.706188838999935, -7.337761199999932],
              [-39.706181839999942, -7.337764752999931],
              [-39.69751202599997, -7.342165638999973],
              [-39.684870020999938, -7.350438038999926],
              [-39.67134843599996, -7.358537169999977],
              [-39.65308841999996, -7.372262173999957],
              [-39.645718303999956, -7.38087805899994],
              [-39.643894760999956, -7.385341664999942],
              [-39.639619323999966, -7.395804676999944],
              [-39.639619147999952, -7.395805032999931],
              [-39.639422794999973, -7.396201754999936],
              [-39.638113788999931, -7.398846538999976],
              [-39.63415496999994, -7.406845142999941],
              [-39.631353929999932, -7.415271522999944],
              [-39.630132411999966, -7.421941635999929],
              [-39.628907180999931, -7.425628510999957],
              [-39.626624578999952, -7.427035212999954],
              [-39.62660804199993, -7.427034367999966],
              [-39.623284015999957, -7.426864507999937],
              [-39.615547088999961, -7.425646760999939],
              [-39.608688195999946, -7.423726642999952],
              [-39.60552415899997, -7.424081643999955],
              [-39.605509489999974, -7.424092249999944],
              [-39.599201884999957, -7.42865257099993],
              [-39.593077945999937, -7.432351266999945],
              [-39.59307789199994, -7.432351299999937],
              [-39.58797760899995, -7.432357335999939],
              [-39.583414699999935, -7.429970561999937],
              [-39.576533887999972, -7.427895019999937],
              [-39.575451524999949, -7.427818812999931],
              [-39.573057553999945, -7.428671421999979],
              [-39.571590503999971, -7.429831282999942],
              [-39.571274529999982, -7.430745850999926],
              [-39.570281569999963, -7.433616185999938],
              [-39.56601778299995, -7.448915778999947],
              [-39.564696168999944, -7.453658079999968],
              [-39.562540665999961, -7.460378036999941],
              [-39.557454839999934, -7.472544858999981],
              [-39.552827912999931, -7.480656839999938],
              [-39.548425366999936, -7.483133291999934],
              [-39.54842532899994, -7.483133303999978],
              [-39.548001537999937, -7.483266482999966],
              [-39.545852808999939, -7.483941731999948],
              [-39.543678034999971, -7.484625649999941],
              [-39.543249068999955, -7.484760549999976],
              [-39.536443943999984, -7.482530327999939],
              [-39.530565727999942, -7.478831667999941],
              [-39.521615851999968, -7.472618412999964],
              [-39.512102666999965, -7.467147861999933],
              [-39.507695277999971, -7.465377450999938],
              [-39.502360545999977, -7.464148441999953],
              [-39.499271248999946, -7.465850453999963],
              [-39.498730042999966, -7.466390941999975],
              [-39.497534205999955, -7.468013731999974],
              [-39.492436603999977, -7.471262496999941],
              [-39.489732514999957, -7.472423252999931],
              [-39.487337113999956, -7.47273535599993],
              [-39.475511883999957, -7.472670423999944],
              [-39.472109020999937, -7.470898551999937],
              [-39.467856584999936, -7.469590608999965],
              [-39.460747611999977, -7.471218998999973],
              [-39.458492817999968, -7.474210407999976],
              [-39.458277417999966, -7.474495487999945],
              [-39.457968441999981, -7.475688714999933],
              [-39.456737510999972, -7.480442416999949],
              [-39.455677480999952, -7.501860921999935],
              [-39.457305800999961, -7.506182958999943],
              [-39.469688420999944, -7.520568637999929],
              [-39.476652830999967, -7.527046713999937],
              [-39.481447941999932, -7.529280636999943],
              [-39.482530503999953, -7.530051097999944],
              [-39.484698021999975, -7.532828493999943],
              [-39.485164214999941, -7.534449022999979],
              [-39.485013976999937, -7.538386601999946],
              [-39.484243857999957, -7.541089773999943],
              [-39.483318388999976, -7.542789179999943],
              [-39.481233232999955, -7.54537019199995],
              [-39.476526798999942, -7.553327690999936],
              [-39.476526569999976, -7.553329816999963],
              [-39.475456181999959, -7.563279293999926],
              [-39.475455124999939, -7.563289116999954],
              [-39.474379425999985, -7.568925777999937],
              [-39.473455933999958, -7.572864498999934],
              [-39.47307084199997, -7.574023501999932],
              [-39.472453647999941, -7.575182010999981],
              [-39.47168083899993, -7.575800614999935],
              [-39.470598449999954, -7.575848030999964],
              [-39.465804311999932, -7.574617991999958],
              [-39.460162289999971, -7.576013676999935],
              [-39.450881722999952, -7.572857648999957],
              [-39.447634206999965, -7.572243128999958],
              [-39.447600048999959, -7.572247108999932],
              [-39.440924804999952, -7.573024804999932],
              [-39.434964760999947, -7.57371917699993],
              [-39.434933493999949, -7.573722819999944],
              [-39.434933429999944, -7.573722892999967],
              [-39.434680783999966, -7.574012651999965],
              [-39.431184580999968, -7.578022417999932],
              [-39.428919656999938, -7.580621612999948],
              [-39.428755984999952, -7.580809440999928],
              [-39.428755942999942, -7.580809488999932],
              [-39.423272818999976, -7.586805807999951],
              [-39.422890993999943, -7.592211124999949],
              [-39.420659788999956, -7.603022162999935],
              [-39.420428073999972, -7.603562523999926],
              [-39.419656146999955, -7.604412278999973],
              [-39.418573791999961, -7.604876713999943],
              [-39.416409357999953, -7.604956112999957],
              [-39.40960572399996, -7.604885202999981],
              [-39.406474438999965, -7.605181992999974],
              [-39.402377247999937, -7.605957429999933],
              [-39.399285427999985, -7.606423735999954],
              [-39.393178501999955, -7.608205497999961],
              [-39.391869167999971, -7.608192440999971],
              [-39.386065601999974, -7.608134567999969],
              [-39.383974558999967, -7.608573632999935],
              [-39.383974432999935, -7.608573658999944],
              [-39.383973927999932, -7.60857376499996],
              [-39.382678270999975, -7.609399006999979],
              [-39.382356037999955, -7.609604245999947],
              [-39.382355975999985, -7.609604285999978],
              [-39.380887837999978, -7.610995542999945],
              [-39.37609496999994, -7.611772362999943],
              [-39.372695028999942, -7.614167925999936],
              [-39.370069198999943, -7.617799126999955],
              [-39.36958905399996, -7.619281704999935],
              [-39.369551463999983, -7.619397772999946],
              [-39.36810309699996, -7.623869993999961],
              [-39.359291172999974, -7.626810897999974],
              [-39.355427792999933, -7.629979527999978],
              [-39.354798743999936, -7.630128035999974],
              [-39.350170523999964, -7.631218882999974],
              [-39.34284450499996, -7.635617844999956],
              [-39.341531055999951, -7.636930419999942],
              [-39.338443708999932, -7.644646895999927],
              [-39.334656607999932, -7.647041741999942],
              [-39.334656558999939, -7.647041763999937],
              [-39.334332634999953, -7.647183565999967],
              [-39.332086138999955, -7.648167002999969],
              [-39.332026066999958, -7.648193299999946],
              [-39.331992382999942, -7.648208045999979],
              [-39.325046448999956, -7.651249677999942],
              [-39.324067836999973, -7.651678212999968],
              [-39.324067685999978, -7.651678278999952],
              [-39.32144034199996, -7.65422579799997],
              [-39.314790239999979, -7.654693405999978],
              [-39.311388609999938, -7.655236583999965],
              [-39.309648295999978, -7.657336467999926],
              [-39.309294666999961, -7.65862969899996],
              [-39.30925995299998, -7.658756647999951],
              [-39.308588499999985, -7.661212169999942],
              [-39.306893849999938, -7.665086127999928],
              [-39.305566451999937, -7.665931624999928],
              [-39.302274159999968, -7.663946747999944],
              [-39.300412943999959, -7.663543321999953],
              [-39.297709089999955, -7.662957250999966],
              [-39.297708935999935, -7.662957284999948],
              [-39.295914264999965, -7.663350807999961],
              [-39.289438773999962, -7.664770708999981],
              [-39.28884536299995, -7.664900827999929],
              [-39.28884523399995, -7.664900855999974],
              [-39.288844971999936, -7.66490091299994],
              [-39.284901647999959, -7.664364733999946],
              [-39.283751792999965, -7.664208386999974],
              [-39.283751665999944, -7.664208369999926],
              [-39.272401672999933, -7.660133537999968],
              [-39.270321407999973, -7.659897315999956],
              [-39.266395888999966, -7.66476542099997],
              [-39.265338120999957, -7.669830963999971],
              [-39.265337878999958, -7.669831107999926],
              [-39.264180185999976, -7.670520407999959],
              [-39.25281597299994, -7.677286751999929],
              [-39.252037419999965, -7.678871062999974],
              [-39.25103099599994, -7.680919076999942],
              [-39.249740352999936, -7.683544575999974],
              [-39.250044332999948, -7.687764311999956],
              [-39.250062398999944, -7.688015096999948],
              [-39.250062405999984, -7.688015199999938],
              [-39.250062353999965, -7.688015252999946],
              [-39.249885739999968, -7.688194224999961],
              [-39.247463962999973, -7.690648345999932],
              [-39.24747033999995, -7.690654636999966],
              [-39.249908287999972, -7.692750494999927],
              [-39.250120035999942, -7.69293253099994],
              [-39.250120091999975, -7.692932579999933],
              [-39.248422451999943, -7.696708920999981],
              [-39.24842216199994, -7.696709566999971],
              [-39.250256455999931, -7.698327056999972],
              [-39.250387990999968, -7.698443044999976],
              [-39.252564870999947, -7.704930899999965],
              [-39.244172430999981, -7.705343055999947],
              [-39.244054879999965, -7.705297880999979],
              [-39.242724609999982, -7.704786660999957],
              [-39.240173848999973, -7.70192461299996],
              [-39.239978745999963, -7.701614261999964],
              [-39.238053810999929, -7.69855225699996],
              [-39.234928465999985, -7.696901466999975],
              [-39.234738182999934, -7.696800959999962],
              [-39.234609509999984, -7.696800651999979],
              [-39.232247849999965, -7.696794995999937],
              [-39.230308111999932, -7.69578962199995],
              [-39.230190469999968, -7.695728647999942],
              [-39.228631105999966, -7.695685396999977],
              [-39.22408268099997, -7.696549209999944],
              [-39.222742582999956, -7.696803713999941],
              [-39.222742473999972, -7.696803734999946],
              [-39.220501652999985, -7.695001611999942],
              [-39.220501523999985, -7.695001567999952],
              [-39.214945454999963, -7.693109026999934],
              [-39.208335344999966, -7.68948039299994],
              [-39.20833519599995, -7.689480310999954],
              [-39.204521946999932, -7.692154954999978],
              [-39.200776142999985, -7.695508773999961],
              [-39.195940667999935, -7.697080470999936],
              [-39.19202314599994, -7.697344491999957],
              [-39.191231925999944, -7.698037897999939],
              [-39.190223985999978, -7.698921232999965],
              [-39.190223905999972, -7.698921341999949],
              [-39.189999334999982, -7.699228649999952],
              [-39.189352606999933, -7.700113645999977],
              [-39.18801891399994, -7.70193870099996],
              [-39.187555873999941, -7.702572333999967],
              [-39.18755570999997, -7.702572558999975],
              [-39.18338627299994, -7.705559576999974],
              [-39.181133673999966, -7.709477720999928],
              [-39.180924549999929, -7.709535206999931],
              [-39.179887363999967, -7.709820323999963],
              [-39.179887247999943, -7.709820355999966],
              [-39.179887053999948, -7.709820408999974],
              [-39.177721117999965, -7.709565644999941],
              [-39.174440589999961, -7.712748538999961],
              [-39.171001360999981, -7.715240281999968],
              [-39.168688496999948, -7.715366705999941],
              [-39.167381907999982, -7.713877879999927],
              [-39.165803030999939, -7.71562802099993],
              [-39.162885734999975, -7.716807750999976],
              [-39.158556603999955, -7.71716149599996],
              [-39.158420579999984, -7.717123417999971],
              [-39.155997136999929, -7.716445004999969],
              [-39.15427466899996, -7.71740006999994],
              [-39.154164077999951, -7.717461390999972],
              [-39.15157770199994, -7.717016315999956],
              [-39.146552892999978, -7.718219100999931],
              [-39.146458385999949, -7.718241722999949],
              [-39.145495850999964, -7.718472123999959],
              [-39.143712682999933, -7.718547007999973],
              [-39.143674690999944, -7.71855987999993],
              [-39.142299675999936, -7.719025746999932],
              [-39.138220783999941, -7.721970752999937],
              [-39.136980067999957, -7.722500775999947],
              [-39.135640873999932, -7.723375095999927],
              [-39.134289730999967, -7.724898171999939],
              [-39.133952877999945, -7.725277888999926],
              [-39.132756340999947, -7.726626307999936],
              [-39.130714452999939, -7.731243753999934],
              [-39.127859328999932, -7.735491320999927],
              [-39.126882376999959, -7.738480030999938],
              [-39.125786974999983, -7.740203559999941],
              [-39.123853313999973, -7.743246022999926],
              [-39.119943869999929, -7.746782583999959],
              [-39.11887120199998, -7.748469634999935],
              [-39.118383559999984, -7.749236578999955],
              [-39.109385203999977, -7.756830375999925],
              [-39.106353895999973, -7.760226286999966],
              [-39.104795261999982, -7.765612016999967],
              [-39.103446609999935, -7.769342517999974],
              [-39.102617136999982, -7.771038248999957],
              [-39.100333261999936, -7.775707696999973],
              [-39.100559312999962, -7.77992655099996],
              [-39.102114813999947, -7.784809606999943],
              [-39.101905957999975, -7.786527803999945],
              [-39.101817949999941, -7.787251818999948],
              [-39.101373425999952, -7.788421748999951],
              [-39.101227465999955, -7.788805898999954],
              [-39.097892125999977, -7.792433413999959],
              [-39.096409520999941, -7.795098714999938],
              [-39.094484144999967, -7.802349575999926],
              [-39.092705588999934, -7.805015181999977],
              [-39.090555119999976, -7.809604599999943],
              [-39.090555461999941, -7.811671606999937],
              [-39.091371185999947, -7.815745042999936],
              [-39.092411129999959, -7.818113725999979],
              [-39.092857218999939, -7.82129559699996],
              [-39.092559467999934, -7.823069995999958],
              [-39.091912048999973, -7.824415152999961],
              [-39.091720588999976, -7.824812953999981],
              [-39.089283886999965, -7.829876611999964],
              [-39.08796541199996, -7.832616505999965],
              [-39.087964690999968, -7.832618004999972],
              [-39.087964081999985, -7.832632674999957],
              [-39.087817252999969, -7.836171780999962],
              [-39.088188017999983, -7.837726147999945],
              [-39.088686277999955, -7.838379693999968],
              [-39.089523853999935, -7.839478303999954],
              [-39.089597802999947, -7.839575299999979],
              [-39.09314367099995, -7.842488722999974],
              [-39.093602033999957, -7.843032352999955],
              [-39.095767259999946, -7.846436082999958],
              [-39.095917952999969, -7.847154900999953],
              [-39.096547226999974, -7.85015657799994],
              [-39.095435905999977, -7.853264144999969],
              [-39.092991919999974, -7.857557757999928],
              [-39.091297787999963, -7.858182471999953],
              [-39.091295762999948, -7.858181520999949],
              [-39.089651916999969, -7.857409806999954],
              [-39.087789417999943, -7.854417033999937],
              [-39.086984610999934, -7.85312382099994],
              [-39.08535024899993, -7.851712496999937],
              [-39.071852318999959, -7.844536335999976],
              [-39.067626022999946, -7.841874494999956],
              [-39.064879828999949, -7.839504233999946],
              [-39.062803869999982, -7.836842747999981],
              [-39.061691761999953, -7.834470925999938],
              [-39.061173200999974, -7.833364974999938],
              [-39.060968228999968, -7.832738235999955],
              [-39.060960989999955, -7.83271610099996],
              [-39.060799108999959, -7.832221119999929],
              [-39.060761034999985, -7.832104701999981],
              [-39.060267800999952, -7.830596545999981],
              [-39.060232654999936, -7.83048907999995],
              [-39.060219779999954, -7.83044971399994],
              [-39.060216276999938, -7.830439000999945],
              [-39.057762127999979, -7.822934984999961],
              [-39.057760817999963, -7.822930977999931],
              [-39.056350261999967, -7.82100502399993],
              [-39.053755677999959, -7.819304638999938],
              [-39.050788936999936, -7.81937894999993],
              [-39.047889644999941, -7.819843314999957],
              [-39.044524885999977, -7.820101931999943],
              [-39.040415515999939, -7.820417779999957],
              [-39.039443847999962, -7.820563057999948],
              [-39.038326049999966, -7.820820884999932],
              [-39.033659582999974, -7.821897235999927],
              [-39.028913587999966, -7.824858144999951],
              [-39.026393139999982, -7.824635072999968],
              [-39.025206969999942, -7.823376893999978],
              [-39.023275878999982, -7.81901148299994],
              [-39.022759812999936, -7.816718180999942],
              [-39.021593, -7.814854204999961],
              [-39.019314061999978, -7.813035233999926],
              [-39.018041944999936, -7.812666021999974],
              [-39.016370335999966, -7.812180707999971],
              [-39.013691379999955, -7.81287297199998],
              [-39.013569483999959, -7.812960385999929],
              [-39.011829063999983, -7.814208482999959],
              [-39.010612080999977, -7.816181450999977],
              [-39.010469462999936, -7.816412662999937],
              [-39.007519843999944, -7.823593747999951],
              [-39.005353524999975, -7.83450341799994],
              [-39.004587701999981, -7.835935549999931],
              [-39.003414332999967, -7.838129816999981],
              [-39.001408642999934, -7.840126081999927],
              [-38.999685629999931, -7.840182956999968],
              [-38.99478083799994, -7.840344861999938],
              [-38.991078856999934, -7.84358991299996],
              [-38.990357853999967, -7.844001721999973],
              [-38.988945438999963, -7.844371466999974],
              [-38.982244611999931, -7.846125620999942],
              [-38.979322718999981, -7.846130595999966],
              [-38.979321910999943, -7.846130597999945],
              [-38.977495974999954, -7.845523259999936],
              [-38.975491743999953, -7.843248043999949],
              [-38.974638920999951, -7.842974461999972],
              [-38.970299462999947, -7.845807607999973],
              [-38.970286020999936, -7.84580703499995],
              [-38.970262540999954, -7.845806033999963],
              [-38.966165540999953, -7.845631319999939],
              [-38.966018744999985, -7.845625059999975],
              [-38.965650316999984, -7.845609348999972],
              [-38.962859469999955, -7.844816397999978],
              [-38.962113639999984, -7.844174620999979],
              [-38.959774245999938, -7.84215828899994],
              [-38.959213881999972, -7.840271697999981],
              [-38.959361146999981, -7.838263432999952],
              [-38.96014921099993, -7.837211783999976],
              [-38.964517630999978, -7.833709451999937],
              [-38.965496190999943, -7.831791941999938],
              [-38.965496043999963, -7.831791419999945],
              [-38.964940356999932, -7.829828497999927],
              [-38.95807442499995, -7.82561047799993],
              [-38.954049185999963, -7.821517674999939],
              [-38.953250257999969, -7.81937884499996],
              [-38.952855272999955, -7.810546508999948],
              [-38.951441392999982, -7.809045401999981],
              [-38.947798650999971, -7.807141221999927],
              [-38.94779575299998, -7.807138126999973],
              [-38.944769812999937, -7.803907319999951],
              [-38.939965103999953, -7.795105700999954],
              [-38.939798250999957, -7.791555718999973],
              [-38.940724597999974, -7.790213082999969],
              [-38.94507931499993, -7.786161351999965],
              [-38.945368719999976, -7.785366296999939],
              [-38.946295353999972, -7.782820641999933],
              [-38.946381765999945, -7.781397475999938],
              [-38.94467868299995, -7.776830556999926],
              [-38.941022591999968, -7.771412818999977],
              [-38.941186559999949, -7.768034307999926],
              [-38.942186310999944, -7.764372084999934],
              [-38.941964367999958, -7.763143111999966],
              [-38.94184826399993, -7.762502076999965],
              [-38.941797721999933, -7.762223020999954],
              [-38.941788997999936, -7.762174854999955],
              [-38.941634303999933, -7.76132075299995],
              [-38.939578059999974, -7.758772626999928],
              [-38.937301788999946, -7.758231871999953],
              [-38.935026565999976, -7.758957977999955],
              [-38.934051515999954, -7.75883009699993],
              [-38.927629632999981, -7.753806303999966],
              [-38.923436913999979, -7.753656597999964],
              [-38.920218824999949, -7.754567286999929],
              [-38.920217553999976, -7.754569561999972],
              [-38.918131482999968, -7.758302090999962],
              [-38.917360320999933, -7.758852155999932],
              [-38.917345012999931, -7.758848308999973],
              [-38.916110850999985, -7.758538125999962],
              [-38.915161300999955, -7.756939667999973],
              [-38.915332672999966, -7.753706141999942],
              [-38.918247726999937, -7.747167058999935],
              [-38.916440934999969, -7.744944431999954],
              [-38.915577687999985, -7.744487106999941],
              [-38.912718681999934, -7.744581697999934],
              [-38.912688043999935, -7.744594242999938],
              [-38.91080427199995, -7.745365574999937],
              [-38.910073441999941, -7.746179465999944],
              [-38.910041914999965, -7.746183539999947],
              [-38.907022993999931, -7.746573611999963],
              [-38.902410540999938, -7.744875155999978],
              [-38.900353178999978, -7.744875552999929],
              [-38.900335171999984, -7.74488839299994],
              [-38.899186284999985, -7.745707664999941],
              [-38.897132590999945, -7.748569395999937],
              [-38.894810596999946, -7.750453542999935],
              [-38.893789197999979, -7.75037531299995],
              [-38.892680288999941, -7.750290380999957],
              [-38.891670921999946, -7.749437900999965],
              [-38.887659449999944, -7.748175433999961],
              [-38.887525964999952, -7.748177900999963],
              [-38.885190433999981, -7.748221063999949],
              [-38.881397546999949, -7.747432296999932],
              [-38.880626248999931, -7.745947753999928],
              [-38.880852550999975, -7.743971761999944],
              [-38.881121393999933, -7.741624614999978],
              [-38.881143334999933, -7.741434312999957],
              [-38.881148996999968, -7.74138520799994],
              [-38.88118507799993, -7.741072256999928],
              [-38.881243797999957, -7.740556463999951],
              [-38.881243764999965, -7.740556155999968],
              [-38.881104224999945, -7.739247767999927],
              [-38.881053360999942, -7.738770838999926],
              [-38.880964604999974, -7.73793862399998],
              [-38.880258652999942, -7.736171793999972],
              [-38.880154953999977, -7.735912259999964],
              [-38.880241975999979, -7.735565374999965],
              [-38.879330019999941, -7.733832031999953],
              [-38.879632028999936, -7.731243749999976],
              [-38.879632133999962, -7.73124353399993],
              [-38.881190459999971, -7.728050537999934],
              [-38.881190497999967, -7.728050459999963],
              [-38.881190552999954, -7.728050346999964],
              [-38.881027238999934, -7.726235853999981],
              [-38.880912821999971, -7.724964633999946],
              [-38.879804133999983, -7.723301574999937],
              [-38.874161171999958, -7.719093016999977],
              [-38.873327682999957, -7.717095698999969],
              [-38.873080397999956, -7.714866542999971],
              [-38.873080296999944, -7.714866441999959],
              [-38.869744562999983, -7.711541745999966],
              [-38.868851757999948, -7.711179806999951],
              [-38.868851657999983, -7.71117976599993],
              [-38.868851597999935, -7.711179741999956],
              [-38.86803169999996, -7.711217241999975],
              [-38.866662919999953, -7.711279846999957],
              [-38.86443640799996, -7.71364211599996],
              [-38.860936387999971, -7.714607763999936],
              [-38.857740854999975, -7.714708322999968],
              [-38.85774078999998, -7.714708377999955],
              [-38.857575294999947, -7.714847551999981],
              [-38.855521750999969, -7.71657449199995],
              [-38.855521400999976, -7.716574785999967],
              [-38.854541864999931, -7.716000675999965],
              [-38.854439050999929, -7.715940415999967],
              [-38.853771862999963, -7.715549373999977],
              [-38.851832099999967, -7.715920705999963],
              [-38.851745005999931, -7.715937378999968],
              [-38.849688091999951, -7.71712542299997],
              [-38.846102372999951, -7.720415039999978],
              [-38.846102248999955, -7.720415031999948],
              [-38.846102038999959, -7.720415018999972],
              [-38.838022942999942, -7.719895467999947],
              [-38.83448214799995, -7.71966776599993],
              [-38.833551881999938, -7.719607881999934],
              [-38.831823762999932, -7.720458076999932],
              [-38.830650294999941, -7.720401210999967],
              [-38.830650207999952, -7.720401183999968],
              [-38.828109321999932, -7.719614470999943],
              [-38.824579966999977, -7.715889883999978],
              [-38.824579854999968, -7.715889764999929],
              [-38.824579767999978, -7.715889728999969],
              [-38.821962183999972, -7.714794376999976],
              [-38.819083747999969, -7.709462348999978],
              [-38.819083656999965, -7.709462180999935],
              [-38.817408244999967, -7.705858962999969],
              [-38.81843912599993, -7.702983322999955],
              [-38.818161119999957, -7.702124733999938],
              [-38.818064020999941, -7.702008177999971],
              [-38.81690888299994, -7.70062156399996],
              [-38.816908528999932, -7.700609780999969],
              [-38.816907175999972, -7.700564778999933],
              [-38.816880182999967, -7.699666589999936],
              [-38.815023959999962, -7.697390836999944],
              [-38.815023900999961, -7.697390764999966],
              [-38.815406050999968, -7.69132858699993],
              [-38.815406060999976, -7.691328432999967],
              [-38.815031789999978, -7.688528011999949],
              [-38.815031791999957, -7.688527996999937],
              [-38.815104344999952, -7.687979614999961],
              [-38.815831510999942, -7.686664032999943],
              [-38.815831525999954, -7.686664005999944],
              [-38.815831605999961, -7.686663967999948],
              [-38.818543507999948, -7.685363758999927],
              [-38.819142529999965, -7.68307473699997],
              [-38.819173226999965, -7.682957433999945],
              [-38.819271316999959, -7.682582603999947],
              [-38.819853991999935, -7.681762932999959],
              [-38.819914589999939, -7.681677686999933],
              [-38.819914622999931, -7.68167766199997],
              [-38.824241255999937, -7.678344297999956],
              [-38.824241258999962, -7.678344264999964],
              [-38.824326182999982, -7.677254144999949],
              [-38.821197229999939, -7.672308408999982],
              [-38.821197131999952, -7.672308254999962],
              [-38.81968995099993, -7.664827747999936],
              [-38.819689893999964, -7.664827711999976],
              [-38.817934091999973, -7.663734468999962],
              [-38.817934032999972, -7.663734432999945],
              [-38.817933872999959, -7.663734332999979],
              [-38.816394103999983, -7.663970593999977],
              [-38.81582629299993, -7.664057717999981],
              [-38.815826236999953, -7.664057726999943],
              [-38.815826156999947, -7.664057816999957],
              [-38.815650915999981, -7.664253813999949],
              [-38.813132190999966, -7.66707086599996],
              [-38.81224425299996, -7.668700609999973],
              [-38.812191660999929, -7.668797137999945],
              [-38.812191631999951, -7.668797190999953],
              [-38.810055162999959, -7.668939395999928],
              [-38.807741818999943, -7.667708026999946],
              [-38.805573025999934, -7.667441659999952],
              [-38.805572950999931, -7.667441673999974],
              [-38.804982003999953, -7.667560053999978],
              [-38.802850550999949, -7.667987029999949],
              [-38.802199256999984, -7.668389735999938],
              [-38.799844931999985, -7.669049711999946],
              [-38.799379975999955, -7.669180050999955],
              [-38.799379895999948, -7.66918007299995],
              [-38.799379667999972, -7.669180057999938],
              [-38.795433479999929, -7.668914156999961],
              [-38.794244977999938, -7.668226564999941],
              [-38.794244883999966, -7.668226557999958],
              [-38.793002564999938, -7.668134608999935],
              [-38.792491127999938, -7.668096754999965],
              [-38.79249108099998, -7.66809675199994],
              [-38.792490234999946, -7.668096688999981],
              [-38.791550990999951, -7.670732144999931],
              [-38.790748008999969, -7.671149545999981],
              [-38.790747672999942, -7.671149720999949],
              [-38.788848065999957, -7.670465816999979],
              [-38.788848000999963, -7.670465793999938],
              [-38.788594275999969, -7.670291587999941],
              [-38.786115571999972, -7.668589724999947],
              [-38.78505579299997, -7.668055483999979],
              [-38.784912751999968, -7.667983375999938],
              [-38.783710016999976, -7.66737706899994],
              [-38.78307450899996, -7.666647207999972],
              [-38.783074487999954, -7.666647183999942],
              [-38.781824382999957, -7.661865884999941],
              [-38.78036386399998, -7.661552858999926],
              [-38.780363813999941, -7.661552849999964],
              [-38.780363807999947, -7.661552849999964],
              [-38.780211676999954, -7.661566673999971],
              [-38.780070877999947, -7.661579468999946],
              [-38.779475436999974, -7.661633576999975],
              [-38.778832404999946, -7.661692009999967],
              [-38.777464025999961, -7.662835133999977],
              [-38.775428968999961, -7.662433393999947],
              [-38.775428892999969, -7.662433347999979],
              [-38.773156268999969, -7.661034757999971],
              [-38.771295443999975, -7.659085514999958],
              [-38.771295382999938, -7.659085450999953],
              [-38.769239087999949, -7.658769544999927],
              [-38.768472206999945, -7.657960913999943],
              [-38.768472077999945, -7.657960869999954],
              [-38.763912695999977, -7.656401157999937],
              [-38.760612472999981, -7.656000828999936],
              [-38.760612418999983, -7.656000822999943],
              [-38.760377241999947, -7.656113576999928],
              [-38.758430493999981, -7.657046936999961],
              [-38.755674067999962, -7.658921911999926],
              [-38.755419385999971, -7.659095151999963],
              [-38.754467116999933, -7.659742903999927],
              [-38.751158435999969, -7.659724151999967],
              [-38.747851132999983, -7.660389879999968],
              [-38.747851035999929, -7.660389861999931],
              [-38.745355244999985, -7.659936566999932],
              [-38.745355163999932, -7.659936551999976],
              [-38.745354556999985, -7.659936441999946],
              [-38.743659700999956, -7.660665823999977],
              [-38.743659641999955, -7.66066584899994],
              [-38.743659242999968, -7.66066602099994],
              [-38.742802062999942, -7.66032200799998],
              [-38.742802031999929, -7.660321995999936],
              [-38.74285563199993, -7.656110535999971],
              [-38.742855611999971, -7.656110508999973],
              [-38.742267679999941, -7.655295843999966],
              [-38.742629668999939, -7.652326680999977],
              [-38.742629680999983, -7.652326583999979],
              [-38.741822155999955, -7.648373292999963],
              [-38.73866432799997, -7.644184150999934],
              [-38.738577932999931, -7.643458116999966],
              [-38.738407300999938, -7.642024192999941],
              [-38.736602988999948, -7.640966988999935],
              [-38.736490238999977, -7.640900924999926],
              [-38.73541483899993, -7.640270813999962],
              [-38.734965569999929, -7.639457422999953],
              [-38.73563287099995, -7.637515243999928],
              [-38.737272895999979, -7.636281059999931],
              [-38.737215785999979, -7.634932120999963],
              [-38.736551415999941, -7.634197932999939],
              [-38.736078846999931, -7.634161871999936],
              [-38.735598219999929, -7.634125196999946],
              [-38.73402412799993, -7.634005274999936],
              [-38.733879535999961, -7.633994258999962],
              [-38.732189671999947, -7.633865517999936],
              [-38.728399536999973, -7.630729352999936],
              [-38.728399430999957, -7.630729292999945],
              [-38.725365884999974, -7.629012313999965],
              [-38.723726500999931, -7.62688400199994],
              [-38.720884011999942, -7.62658689899996],
              [-38.720883780999941, -7.62658687499993],
              [-38.720883430999947, -7.62658683799998],
              [-38.717777872999932, -7.626871186999949],
              [-38.717777805999958, -7.626871214999937],
              [-38.717594310999971, -7.626887993999958],
              [-38.71759422599996, -7.62688800199993],
              [-38.716600500999959, -7.625110414999938],
              [-38.715274226999952, -7.622001730999955],
              [-38.715211926999984, -7.621970036999926],
              [-38.715112857999941, -7.621919636999962],
              [-38.714103099999932, -7.623311249999972],
              [-38.709104465999985, -7.623003797999957],
              [-38.708963709999978, -7.622995139999944],
              [-38.706327355999974, -7.621926290999966],
              [-38.70632672499994, -7.621926370999972],
              [-38.70540718999996, -7.622043457999951],
              [-38.702101936999952, -7.625830246999953],
              [-38.700412909999955, -7.626740893999965],
              [-38.698548194999944, -7.630386227999963],
              [-38.695993563999934, -7.632802732999949],
              [-38.695047859999931, -7.633296497999936],
              [-38.690655805999938, -7.63290066999997],
              [-38.690133571999979, -7.634814165999956],
              [-38.68674181199998, -7.635039774999939],
              [-38.686741466999933, -7.635040576999927],
              [-38.683418396999969, -7.642765719999943],
              [-38.682720945999961, -7.643562402999976],
              [-38.68007562899993, -7.645299013999932],
              [-38.678973121999945, -7.646558045999939],
              [-38.678300757999978, -7.647560917999954],
              [-38.676409071999956, -7.651578750999931],
              [-38.675073327999939, -7.653088382999954],
              [-38.674271388999955, -7.657186363999926],
              [-38.672783197999934, -7.658795568999949],
              [-38.669939880999948, -7.660483124999928],
              [-38.669939558999943, -7.660482746999946],
              [-38.669324235999966, -7.659760260999974],
              [-38.665748472999951, -7.657870435999939],
              [-38.663596363999943, -7.657522944999926],
              [-38.65866643399994, -7.662313230999928],
              [-38.658663027999978, -7.662316539999949],
              [-38.658185771999968, -7.662853500999972],
              [-38.654409040999951, -7.667102694999926],
              [-38.65071037499996, -7.668564701999969],
              [-38.651412547999939, -7.677082051999946],
              [-38.648407023999937, -7.678656897999929],
              [-38.642083923999962, -7.677636592999932],
              [-38.642083922999973, -7.677636706999976],
              [-38.641991317999953, -7.686146321999956],
              [-38.641317598999933, -7.686463986999968],
              [-38.63821711199995, -7.687925894999978],
              [-38.634075823999979, -7.690780463999943],
              [-38.628158633999931, -7.693361122999931],
              [-38.626438536999956, -7.694724829999927],
              [-38.626438433999965, -7.694725008999967],
              [-38.62593365999993, -7.695601134999947],
              [-38.625492534999978, -7.69900198299996],
              [-38.626268043999971, -7.702957839999954],
              [-38.62623291999995, -7.704499733999967],
              [-38.62622992799993, -7.70463105999994],
              [-38.626190664999967, -7.706354696999938],
              [-38.625328980999939, -7.708318580999958],
              [-38.623664817999952, -7.709685840999953],
              [-38.620741009999961, -7.710187235999967],
              [-38.616716693999933, -7.713490663999949],
              [-38.61407307099995, -7.715659866999943],
              [-38.612822232999974, -7.719530341999928],
              [-38.611156965999953, -7.721512501999939],
              [-38.60894206699993, -7.724148886999956],
              [-38.60796828499997, -7.725947883999936],
              [-38.607827800999985, -7.72704551399994],
              [-38.609025531999976, -7.729725565999956],
              [-38.609434093999937, -7.731916011999942],
              [-38.60896203599998, -7.735787245999973],
              [-38.60757584299995, -7.739478960999975],
              [-38.607072793999976, -7.740298361999976],
              [-38.606073935999973, -7.740570181999942],
              [-38.604301562999979, -7.739931798999976],
              [-38.601299338999979, -7.740142487999947],
              [-38.599662981999984, -7.741482307999945],
              [-38.597737461999941, -7.742035388999966],
              [-38.597162713999978, -7.742854856999941],
              [-38.596960524999929, -7.744900738999945],
              [-38.59738257999993, -7.746722932999944],
              [-38.596409752999932, -7.75114427699998],
              [-38.596024094999962, -7.752048946999935],
              [-38.593627909999952, -7.754349226999977],
              [-38.593627252999966, -7.754349464999962],
              [-38.58995781699997, -7.755681540999944],
              [-38.589957174999938, -7.75568141399998],
              [-38.586046074999956, -7.754905312999938],
              [-38.584322340999961, -7.753449106999938],
              [-38.582299573999933, -7.749063105999937],
              [-38.580967768999983, -7.747556885999927],
              [-38.579030400999955, -7.746599929999945],
              [-38.57801866899996, -7.746607372999961],
              [-38.576157027999955, -7.750287921999927],
              [-38.576183266999976, -7.752284814999939],
              [-38.575713008999969, -7.753680832999976],
              [-38.574827634999963, -7.754337306999957],
              [-38.57431575399994, -7.75317813199996],
              [-38.573966881999979, -7.752388098999972],
              [-38.573239146999981, -7.751663837999956],
              [-38.572104100999979, -7.75147476199993],
              [-38.56849245899997, -7.753208916999938],
              [-38.567700744999968, -7.754070670999965],
              [-38.567437445999929, -7.754357263999964],
              [-38.566961593999963, -7.754875212999934],
              [-38.565152400999978, -7.755960752999954],
              [-38.563932786999942, -7.756692537999982],
              [-38.562031243999968, -7.756892323999978],
              [-38.560129565999944, -7.757092123999939],
              [-38.558977783999978, -7.756828602999974],
              [-38.557826018999947, -7.756565085999966],
              [-38.552826676999985, -7.753699755999946],
              [-38.549212658999977, -7.752313670999968],
              [-38.547489630999962, -7.751084687999935],
              [-38.546189958999946, -7.749450443999933],
              [-38.543133347999969, -7.74900099499996],
              [-38.543133283999964, -7.749001086999954],
              [-38.541218187999959, -7.751773244999981],
              [-38.538526504999936, -7.753257941999948],
              [-38.534249497999951, -7.754416239999955],
              [-38.530116359999965, -7.75504340599997],
              [-38.529682341999944, -7.755109263999941],
              [-38.524957107999967, -7.754644759999962],
              [-38.518381817999966, -7.74982829399994],
              [-38.516605249999941, -7.749329308999961],
              [-38.516604611999981, -7.749329351999961],
              [-38.507429731999935, -7.74995209399998],
              [-38.500548826999932, -7.751482195999927],
              [-38.500548429999981, -7.751482050999925],
              [-38.496575436999933, -7.750031892999971],
              [-38.495740093999984, -7.74448390699996],
              [-38.495799286999954, -7.738808437999978],
              [-38.490519932999973, -7.734661550999931],
              [-38.482080630999974, -7.733315893999929],
              [-38.480700983999952, -7.732175176999931],
              [-38.47965368499996, -7.732697231999964],
              [-38.476223179999977, -7.73337049099996],
              [-38.471563278999952, -7.733226958999978],
              [-38.468117144999951, -7.732428416999937],
              [-38.463975697999956, -7.730892147999953],
              [-38.462255281999944, -7.729539969999962],
              [-38.460952448999933, -7.728515995999942],
              [-38.457788272999949, -7.723436346999961],
              [-38.457083418999957, -7.718106981999938],
              [-38.456702235999956, -7.718009997999957],
              [-38.455017716999976, -7.717581408999934],
              [-38.450072010999975, -7.717372166999951],
              [-38.449728350999976, -7.717357626999956],
              [-38.449698873999978, -7.717356379999956],
              [-38.44969883899995, -7.71735638499996],
              [-38.445454990999963, -7.717952981999929],
              [-38.443868366999936, -7.719264392999946],
              [-38.440018434999956, -7.721232207999947],
              [-38.436735321999947, -7.721774210999968],
              [-38.435475684999972, -7.721112765999976],
              [-38.434216049999975, -7.720451321999974],
              [-38.434215825999956, -7.720451647999937],
              [-38.427625510999974, -7.730036702999939],
              [-38.41874063299997, -7.731116208999936],
              [-38.418740251999964, -7.731116112999928],
              [-38.413966258999949, -7.729916300999946],
              [-38.413965743999938, -7.729916367999977],
              [-38.412485877999984, -7.73010789999995],
              [-38.409507889999929, -7.730493327999966],
              [-38.409507761999976, -7.730493194999951],
              [-38.406033452999964, -7.726880372999972],
              [-38.400824525999951, -7.720060750999949],
              [-38.403930933999959, -7.714442538999947],
              [-38.401705472999936, -7.713172844999974],
              [-38.388350947999982, -7.708890664999956],
              [-38.382253323999976, -7.707475196999951],
              [-38.37983591099993, -7.704942084999971],
              [-38.379063932999941, -7.703253229999973],
              [-38.375775738999948, -7.700672104999967],
              [-38.37258191799998, -7.699636433999956],
              [-38.37093704199998, -7.698415257999955],
              [-38.370166109999957, -7.696915893999972],
              [-38.370074064999983, -7.692649511999946],
              [-38.369853243999955, -7.692291808999926],
              [-38.369206175999977, -7.691243635999967],
              [-38.367472481999982, -7.690051092999965],
              [-38.366885131999936, -7.689647076999961],
              [-38.362821473999929, -7.687766770999929],
              [-38.361616541999979, -7.686302853999962],
              [-38.357495874999984, -7.677161911999974],
              [-38.354977874999975, -7.67842522899997],
              [-38.35293575999998, -7.684701032999953],
              [-38.350609936999945, -7.685915997999928],
              [-38.350802595999937, -7.686993480999945],
              [-38.350316649999968, -7.688678879999941],
              [-38.348378418999971, -7.689425728999936],
              [-38.344691544999932, -7.694996722999974],
              [-38.34120341199997, -7.695928849999973],
              [-38.341202970999973, -7.695928693999974],
              [-38.339075702999935, -7.695175906999964],
              [-38.338786198999969, -7.694143045999965],
              [-38.337197511999932, -7.69357638799994],
              [-38.335884520999969, -7.693108066999969],
              [-38.335883904999946, -7.693108137999957],
              [-38.333075041999962, -7.693432295999969],
              [-38.330357917999947, -7.696147040999961],
              [-38.326685641999973, -7.693704509999975],
              [-38.323834547999979, -7.695756037999956],
              [-38.320773024999937, -7.697958982999978],
              [-38.321057245999953, -7.702644797999938],
              [-38.318628752999984, -7.70667031399995],
              [-38.31620401899994, -7.709150678999947],
              [-38.316200038999966, -7.711587416999976],
              [-38.317552401999933, -7.713463976999947],
              [-38.317257825999945, -7.715524006999942],
              [-38.317256932999953, -7.715524523999932],
              [-38.31580374899994, -7.716365150999934],
              [-38.312029528999972, -7.716312792999929],
              [-38.312029799999948, -7.716313648999972],
              [-38.315882147999957, -7.728472948999979],
              [-38.312779908999971, -7.731562462999932],
              [-38.310929876999978, -7.737369559999934],
              [-38.311023787999943, -7.738841278999928],
              [-38.310876941999936, -7.739077745999964],
              [-38.308958280999946, -7.74216628299996],
              [-38.30215734799998, -7.745204358999956],
              [-38.303809688999934, -7.748993679999955],
              [-38.304175857999951, -7.750244710999937],
              [-38.305702129999929, -7.752598775999957],
              [-38.316759849999983, -7.76460749499995],
              [-38.31562166599997, -7.76512364499996],
              [-38.314483457999984, -7.765639805999967],
              [-38.313671858999953, -7.765726522999955],
              [-38.31286023499996, -7.765813241999979],
              [-38.312859819999971, -7.765813157999958],
              [-38.310693374999971, -7.765372280999941],
              [-38.303339513999958, -7.76208388699996],
              [-38.303338861999976, -7.762083967999956],
              [-38.301264910999976, -7.762342433999947],
              [-38.299817233999931, -7.763195613999926],
              [-38.29945444599997, -7.763980107999942],
              [-38.300984494999966, -7.767041934999952],
              [-38.301252362999946, -7.768703900999981],
              [-38.299847949999958, -7.771802516999969],
              [-38.295688513999949, -7.775203269999963],
              [-38.294601410999974, -7.777970216999961],
              [-38.295535860999962, -7.779587901999946],
              [-38.300235424999983, -7.783415556999955],
              [-38.30257469999998, -7.788398809999933],
              [-38.302524323999933, -7.791501231999973],
              [-38.298992460999955, -7.79743592799997],
              [-38.29822044499997, -7.800519464999979],
              [-38.297764040999937, -7.80436371299993],
              [-38.29774652499998, -7.813946935999979],
              [-38.293351101999974, -7.823045897999975],
              [-38.290595228999962, -7.82430708499993],
              [-38.287882799999977, -7.826137724999967],
              [-38.286526571999957, -7.827620088999936],
              [-38.286343838999983, -7.828999781999926],
              [-38.286793129999978, -7.830224216999966],
              [-38.285707431999981, -7.830922380999937],
              [-38.282321239999931, -7.830873935999932],
              [-38.280514971999935, -7.831480732999978],
              [-38.276227303999974, -7.833819755999968],
              [-38.275454668999942, -7.834241245999976],
              [-38.27545392299993, -7.834241125999938],
              [-38.274417280999955, -7.834074032999979],
              [-38.271887267999944, -7.833666228999959],
              [-38.26755935999995, -7.829202866999935],
              [-38.264943453999933, -7.827976472999978],
              [-38.263092081999957, -7.828104392999933],
              [-38.259205230999953, -7.830456700999946],
              [-38.25703686199995, -7.831064261999927],
              [-38.245162615999959, -7.831173883999952],
              [-38.238570688999971, -7.830726116999926],
              [-38.236636451999971, -7.826834348999967],
              [-38.235464336999939, -7.825958283999967],
              [-38.228288438999982, -7.824066969999933],
              [-38.22359167999997, -7.821352437999963],
              [-38.22192292699998, -7.820387967999977],
              [-38.218611257999953, -7.818473960999938],
              [-38.215284053999937, -7.815436797999951],
              [-38.212674174999961, -7.810712701999933],
              [-38.207839478999972, -7.808565543999975],
              [-38.205861562999985, -7.807687122999937],
              [-38.205706603999943, -7.80768076399994],
              [-38.203604126999949, -7.807594491999964],
              [-38.184278723999967, -7.795980672999974],
              [-38.183989587999974, -7.795549136999966],
              [-38.183932553999966, -7.795464013999947],
              [-38.182432353999957, -7.793224966999958],
              [-38.179906056999982, -7.79182256699994],
              [-38.178916837999964, -7.789897383999971],
              [-38.177383900999985, -7.788497634999942],
              [-38.177383343999963, -7.78849792099993],
              [-38.174672015999931, -7.78989022899998],
              [-38.171654669999953, -7.786608585999943],
              [-38.166335838999942, -7.783014888999958],
              [-38.164983174999975, -7.781528416999947],
              [-38.164218991999974, -7.77990879999993],
              [-38.162672237999971, -7.779953427999942],
              [-38.162403392999977, -7.77996117999993],
              [-38.162123740999959, -7.779969243999972],
              [-38.155731372999981, -7.780153571999961],
              [-38.153382974999943, -7.780848502999959],
              [-38.150031471999966, -7.782874800999934],
              [-38.148473828999954, -7.783816542999944],
              [-38.146247228999982, -7.784127797999929],
              [-38.145746444999929, -7.784197802999927],
              [-38.145746149999979, -7.784197684999981],
              [-38.144754192999983, -7.783801979999964],
              [-38.143761944999937, -7.783406157999934],
              [-38.142316304999952, -7.784146110999927],
              [-38.140870627999959, -7.784886082999947],
              [-38.139196310999978, -7.786238081999954],
              [-38.138107647999959, -7.788682638999944],
              [-38.131425490999959, -7.795757674999948],
              [-38.131563153999934, -7.79632756899997],
              [-38.13110108799998, -7.799057000999937],
              [-38.130639014999929, -7.801786476999951],
              [-38.130898628999944, -7.802827190999949],
              [-38.131158243999948, -7.803867907999972],
              [-38.12943648199996, -7.805900569999949],
              [-38.125656384999957, -7.805344532999925],
              [-38.122436198999935, -7.807434480999973],
              [-38.124153970999942, -7.812980931999959],
              [-38.12005598199994, -7.818974456999968],
              [-38.118709772999978, -7.820943358999955],
              [-38.116178150999929, -7.82256018299995],
              [-38.106996502999948, -7.822833644999946],
              [-38.106123096999966, -7.82285965899996],
              [-38.101421135999942, -7.825559729999952],
              [-38.100713449999944, -7.825966113999925],
              [-38.100711722999961, -7.825966790999928],
              [-38.097240712999962, -7.827327054999955],
              [-38.09716188699997, -7.827326036999978],
              [-38.09536611599998, -7.827302843999973],
              [-38.095244728999944, -7.827301274999968],
              [-38.090764512999954, -7.827243410999927],
              [-38.083867593999969, -7.828004732999943],
              [-38.083339445999968, -7.828063032999978],
              [-38.083338588999936, -7.828063376999978],
              [-38.082732451999959, -7.828306682999937],
              [-38.080552021999949, -7.829181914999936],
              [-38.077069558999938, -7.830579645999933],
              [-38.077069195999968, -7.830579394999972],
              [-38.073515775999965, -7.828124095999954],
              [-38.069376532999968, -7.826117067999974],
              [-38.065350004999971, -7.819747762999953],
              [-38.066305195999973, -7.817666328999962],
              [-38.068541262999929, -7.812793769999928],
              [-38.075741142999959, -7.805383615999972],
              [-38.07573984499993, -7.799876953999956],
              [-38.074923379999973, -7.797578752999925],
              [-38.073481389999984, -7.793519811999943],
              [-38.075214356999936, -7.789124790999949],
              [-38.075765400999956, -7.787727273999963],
              [-38.075263314999972, -7.782418676999953],
              [-38.071398519999946, -7.774234443999944],
              [-38.069478295999943, -7.772324340999944],
              [-38.065736050999931, -7.770023346999949],
              [-38.061027689999946, -7.766923179999935],
              [-38.051872283999955, -7.758024882999962],
              [-38.046994874999939, -7.754441372999963],
              [-38.039581584999951, -7.748119057999929],
              [-38.02991544799994, -7.749835288999975],
              [-38.028085678999958, -7.753024649999929],
              [-38.027788087999966, -7.753222088999962],
              [-38.027701823999962, -7.75327932099998],
              [-38.025706726999942, -7.754602984999963],
              [-38.022803597999939, -7.754699315999972],
              [-38.018535700999962, -7.758366400999932],
              [-38.018384560999948, -7.758496264999962],
              [-38.01838451499998, -7.758496333999972],
              [-38.015998326999977, -7.76210870999995],
              [-38.014998739999953, -7.763154032999978],
              [-38.012463819999937, -7.765804938999963],
              [-38.012463636999939, -7.765804983999942],
              [-38.009429155999953, -7.766553071999965],
              [-38.008146777999968, -7.766869214999929],
              [-38.006873050999957, -7.769206040999961],
              [-38.006863646999932, -7.773396627999944],
              [-38.005453822999982, -7.777762057999951],
              [-38.003970954999943, -7.77898640799998],
              [-38.003582326999947, -7.778983312999969],
              [-38.000719140999934, -7.778960512999959],
              [-37.998893829999929, -7.778945977999967],
              [-37.990267482999968, -7.778877285999954],
              [-37.988476252999931, -7.777696163999963],
              [-37.984790175999933, -7.772936982999965],
              [-37.982967855999959, -7.772648640999932],
              [-37.976416256999983, -7.774780236999959],
              [-37.972713748999979, -7.77753007299998],
              [-37.971363378999968, -7.777559986999961],
              [-37.97132813199994, -7.777560767999944],
              [-37.970599837999941, -7.777576900999975],
              [-37.970034818999977, -7.777128914999935],
              [-37.968206925999937, -7.775679632999982],
              [-37.966674454999975, -7.774332005999952],
              [-37.964802488999965, -7.772686319999934],
              [-37.964801575999957, -7.772685702999979],
              [-37.961410648999959, -7.770394781999926],
              [-37.95908843199993, -7.76871112699996],
              [-37.957899719999943, -7.766543795999951],
              [-37.957805605999965, -7.766325886999937],
              [-37.957762240999955, -7.766225479999946],
              [-37.957700662999969, -7.766082901999937],
              [-37.956796438999959, -7.763989275999961],
              [-37.957296196999948, -7.763299954999979],
              [-37.957141099999944, -7.761809297999946],
              [-37.956434544999979, -7.760706383999946],
              [-37.955779689999929, -7.76049958699997],
              [-37.955348300999958, -7.760635925999964],
              [-37.954694008999979, -7.760689150999951],
              [-37.953194734999954, -7.758741817999976],
              [-37.952759600999968, -7.758358382999972],
              [-37.953104261999954, -7.757505347999938],
              [-37.952574345999949, -7.756630771999937],
              [-37.947545652999963, -7.756221900999947],
              [-37.946053287999973, -7.751121445999956],
              [-37.945631036999941, -7.749678314999926],
              [-37.945186381999974, -7.74815861899998],
              [-37.944677277999972, -7.746418656999936],
              [-37.944656211999984, -7.746346658999926],
              [-37.944286898999962, -7.745084511999949],
              [-37.943176793999953, -7.741290668999966],
              [-37.943117506999954, -7.741088052999942],
              [-37.943102020999959, -7.741035128999954],
              [-37.94299811999997, -7.740971618999936],
              [-37.933982864999962, -7.735460995999972],
              [-37.926850513999966, -7.729932159999976],
              [-37.922350492999954, -7.723637581999981],
              [-37.923308411999983, -7.716153473999952],
              [-37.923396602999958, -7.716039898999952],
              [-37.923912041999984, -7.715151319999961],
              [-37.923934896999981, -7.715111920999959],
              [-37.923152224999967, -7.71471067799996],
              [-37.922684932999971, -7.71429292299996],
              [-37.922213455999952, -7.713923386999966],
              [-37.922201156999961, -7.713913746999935],
              [-37.921490021999944, -7.713369886999942],
              [-37.921392038999954, -7.713226242999951],
              [-37.918736898999953, -7.712493107999933],
              [-37.918736898999953, -7.712374220999948],
              [-37.917643799999951, -7.711429730999953],
              [-37.916513867999981, -7.710946671999977],
              [-37.914195501999984, -7.709955544999957],
              [-37.913066649999962, -7.709472947999927],
              [-37.904986073999964, -7.710154185999954],
              [-37.894335238999929, -7.71362632599994],
              [-37.894334517999937, -7.713625373999946],
              [-37.892297937999956, -7.710937417999958],
              [-37.896941238999943, -7.708346787999972],
              [-37.899226619999979, -7.706044270999939],
              [-37.901901273999954, -7.699382325999977],
              [-37.903326077999964, -7.698099673999934],
              [-37.903234657999974, -7.697568910999962],
              [-37.902927403999968, -7.695785072999968],
              [-37.902746923999985, -7.694737247999967],
              [-37.901856599999974, -7.693835963999959],
              [-37.897353401999965, -7.690826478999952],
              [-37.891467605999935, -7.689487861999964],
              [-37.88323674999998, -7.685628049999934],
              [-37.881916728999954, -7.684639052999955],
              [-37.880596708999974, -7.683650055999976],
              [-37.876742093999951, -7.679552411999964],
              [-37.872887468999977, -7.675454754999976],
              [-37.86964910599994, -7.667557927999951],
              [-37.868439759999944, -7.664608721999969],
              [-37.867013498999938, -7.662625180999953],
              [-37.857158854999966, -7.651968881999949],
              [-37.845746505999955, -7.647837402999926],
              [-37.843909103999977, -7.647172229999967],
              [-37.841999053999984, -7.647956847999978],
              [-37.841991424999947, -7.647961616999964],
              [-37.841884612999934, -7.647963523999977],
              [-37.841705321999939, -7.64790153499996],
              [-37.841472625999984, -7.647759913999948],
              [-37.841304778999984, -7.647651671999938],
              [-37.841117858999951, -7.647550105999926],
              [-37.840938567999956, -7.647426604999964],
              [-37.84078216599994, -7.647274970999945],
              [-37.840625762999935, -7.647116660999927],
              [-37.840465545999962, -7.646951674999968],
              [-37.840293883999948, -7.646783351999943],
              [-37.84012222299998, -7.646616458999972],
              [-37.839962005999951, -7.64643478399995],
              [-37.839828490999935, -7.646240233999947],
              [-37.839694976999965, -7.646051883999974],
              [-37.839553832999968, -7.645883559999959],
              [-37.839416503999985, -7.645728110999926],
              [-37.839282989999958, -7.645578383999975],
              [-37.839130401999967, -7.645424842999944],
              [-37.838951110999972, -7.64526176499993],
              [-37.838756560999968, -7.645090102999973],
              [-37.838554381999984, -7.644936561999941],
              [-37.83833694499998, -7.644801616999928],
              [-37.838134765999939, -7.64467000999997],
              [-37.837871551999967, -7.644503116999942],
              [-37.837635039999952, -7.644454955999947],
              [-37.837390899999946, -7.644450187999951],
              [-37.837158202999944, -7.644456862999959],
              [-37.836921691999976, -7.644476890999954],
              [-37.836696624999945, -7.644501685999955],
              [-37.836486815999933, -7.644523143999947],
              [-37.836277007999968, -7.644543170999953],
              [-37.836254612999937, -7.644545052999945],
              [-37.836055755999951, -7.64456176799996],
              [-37.835826873999963, -7.644569873999956],
              [-37.835613250999984, -7.644546508999952],
              [-37.835411071999943, -7.644495009999957],
              [-37.835220336999953, -7.644446849999952],
              [-37.83502578699995, -7.644414901999937],
              [-37.834846496999944, -7.644420146999948],
              [-37.834712981999985, -7.64445352599995],
              [-37.834617614999956, -7.644491672999948],
              [-37.834579467999959, -7.644503116999942],
              [-37.834575652999945, -7.644505023999955],
              [-37.834526061999952, -7.644535064999957],
              [-37.834434508999948, -7.64460182199997],
              [-37.834308623999959, -7.644698142999971],
              [-37.834182738999971, -7.644836902999941],
              [-37.834068297999977, -7.64499187499996],
              [-37.833965301999967, -7.645148276999976],
              [-37.833869933999949, -7.645306586999936],
              [-37.833778380999945, -7.645458220999956],
              [-37.833675384999935, -7.645606517999965],
              [-37.833572387999936, -7.645748137999931],
              [-37.833461760999967, -7.645881652999947],
              [-37.833357427999943, -7.645985984999982],
              [-37.833343505999949, -7.645999907999965],
              [-37.833229064999955, -7.646101474999966],
              [-37.833122252999942, -7.646180152999932],
              [-37.833023070999957, -7.646261691999939],
              [-37.832923888999971, -7.646351813999956],
              [-37.832824706999929, -7.646421431999954],
              [-37.832710265999935, -7.646493434999968],
              [-37.832603454999969, -7.646566867999979],
              [-37.832504271999937, -7.646638392999932],
              [-37.832401275999985, -7.646718501999942],
              [-37.832229613999971, -7.646834849999948],
              [-37.832096099999944, -7.64691352799997],
              [-37.831970214999956, -7.646975039999973],
              [-37.831844329999967, -7.64702510799998],
              [-37.831729888999973, -7.647096633999979],
              [-37.831607818999942, -7.647178172999929],
              [-37.83145523099995, -7.647243499999945],
              [-37.831295012999931, -7.647303103999946],
              [-37.831127166999977, -7.647348403999956],
              [-37.830921172999979, -7.647383212999955],
              [-37.830669402999945, -7.647416591999956],
              [-37.83041000399993, -7.647461890999978],
              [-37.830286025999953, -7.647495030999949],
              [-37.830162047999977, -7.647528171999966],
              [-37.83013778399993, -7.647533005999946],
              [-37.82991790799997, -7.647576808999929],
              [-37.829666137999936, -7.647608279999929],
              [-37.829418181999984, -7.647644996999929],
              [-37.829181670999958, -7.647675036999942],
              [-37.828945159999932, -7.647693156999935],
              [-37.828693389999955, -7.647703170999932],
              [-37.828433989999951, -7.647685050999939],
              [-37.82817840599995, -7.647661685999935],
              [-37.827838897999982, -7.647598266999978],
              [-37.827610015999937, -7.64752340299998],
              [-37.827384948999963, -7.647441863999973],
              [-37.827171325999984, -7.647349833999954],
              [-37.826961516999972, -7.647246837999944],
              [-37.826755523999964, -7.647133349999933],
              [-37.826541900999985, -7.647028445999979],
              [-37.826324462999935, -7.646924972999955],
              [-37.826095580999947, -7.646808146999945],
              [-37.825851439999951, -7.646701812999936],
              [-37.825614928999983, -7.646601676999978],
              [-37.825393676999965, -7.646501540999964],
              [-37.825191497999981, -7.646386622999955],
              [-37.825008391999972, -7.646263122999926],
              [-37.824836730999948, -7.64613676099998],
              [-37.824649810999972, -7.646009921999962],
              [-37.824447631999931, -7.645876883999961],
              [-37.824226378999981, -7.645756720999941],
              [-37.823974608999947, -7.645673274999979],
              [-37.823692321999943, -7.645645141999978],
              [-37.823398589999954, -7.645646571999976],
              [-37.823032378999983, -7.645633220999969],
              [-37.822776793999935, -7.645613193999964],
              [-37.822517394999977, -7.64559841199997],
              [-37.822261809999929, -7.645591735999972],
              [-37.822010039999952, -7.64558649099996],
              [-37.821773528999984, -7.645608424999978],
              [-37.821540832999972, -7.645643233999976],
              [-37.821308135999971, -7.645693301999927],
              [-37.821090697999978, -7.645786761999943],
              [-37.820884704999969, -7.64589977299994],
              [-37.820682525999985, -7.645995139999968],
              [-37.820503234999933, -7.646090030999972],
              [-37.820320128999981, -7.646174907999978],
              [-37.820133208999948, -7.646255015999941],
              [-37.819953917999953, -7.646351813999956],
              [-37.819770812999934, -7.646448134999957],
              [-37.819606780999948, -7.646573543999978],
              [-37.819454192999956, -7.646715163999943],
              [-37.819286345999956, -7.646828174999939],
              [-37.81910705599995, -7.646853446999955],
              [-37.818916320999961, -7.646753310999941],
              [-37.81877136199995, -7.646661757999937],
              [-37.818626403999929, -7.64656352999998],
              [-37.818504332999964, -7.646484851999958],
              [-37.81838989299996, -7.646396636999953],
              [-37.818229674999941, -7.646275042999946],
              [-37.81805419899996, -7.646154880999973],
              [-37.817878722999978, -7.646039962999964],
              [-37.817718505999949, -7.645938395999963],
              [-37.817546843999935, -7.645854949999944],
              [-37.817371367999954, -7.645781516999932],
              [-37.817184447999978, -7.645711898999934],
              [-37.816997527999945, -7.645631789999925],
              [-37.816825866999977, -7.645536899999968],
              [-37.816715239999951, -7.645394801999942],
              [-37.816635131999931, -7.64522170999993],
              [-37.816528319999975, -7.645034789999954],
              [-37.816417693999938, -7.644855021999945],
              [-37.816326140999934, -7.644688128999974],
              [-37.816249846999938, -7.644555091999962],
              [-37.816143035999971, -7.644424914999945],
              [-37.815979003999985, -7.644278525999937],
              [-37.815868377999948, -7.644196509999972],
              [-37.81571960399998, -7.644114970999965],
              [-37.815540313999975, -7.644023417999961],
              [-37.815341948999958, -7.643919944999936],
              [-37.815132140999935, -7.643826484999977],
              [-37.814907073999962, -7.643750190999981],
              [-37.814647674999947, -7.643684863999965],
              [-37.814384459999985, -7.643630027999961],
              [-37.814117431999932, -7.643573283999956],
              [-37.813846587999933, -7.643538474999957],
              [-37.81357574499998, -7.643515109999953],
              [-37.813304900999981, -7.643473147999941],
              [-37.813034057999971, -7.643439769999929],
              [-37.812759398999958, -7.643414973999938],
              [-37.8125, -7.643431663999934],
              [-37.812255858999947, -7.643549918999952],
              [-37.812015532999965, -7.643718242999967],
              [-37.811782836999953, -7.643885135999938],
              [-37.811557769999979, -7.644011496999951],
              [-37.811328887999935, -7.644058227999949],
              [-37.811092376999966, -7.644078254999954],
              [-37.810855864999951, -7.644076823999967],
              [-37.810604094999974, -7.644034862999945],
              [-37.810268401999963, -7.643971442999941],
              [-37.810039519999975, -7.643891810999946],
              [-37.809841155999948, -7.643754958999978],
              [-37.809677123999961, -7.643595218999963],
              [-37.809547423999959, -7.643481730999952],
              [-37.809398650999981, -7.643343448999929],
              [-37.809268950999979, -7.643151759999967],
              [-37.80917358399995, -7.642923354999937],
              [-37.809066771999937, -7.642684936999956],
              [-37.808963775999985, -7.642458438999938],
              [-37.808868407999967, -7.64225006099997],
              [-37.80875778199993, -7.642055034999942],
              [-37.808643340999936, -7.64186334599998],
              [-37.80856704699994, -7.641681670999958],
              [-37.808528899999942, -7.64152336099994],
              [-37.808448791999979, -7.641413211999975],
              [-37.808391570999959, -7.641274928999962],
              [-37.808311461999949, -7.641111850999948],
              [-37.808238982999967, -7.640924930999972],
              [-37.808170318999942, -7.640763282999956],
              [-37.808094024999946, -7.640653132999944],
              [-37.808094024999946, -7.640655040999945],
              [-37.808063506999929, -7.640594958999941],
              [-37.80796050999993, -7.640418529999977],
              [-37.807872771999939, -7.640306472999953],
              [-37.807773589999954, -7.640193461999957],
              [-37.807666778999931, -7.64005994799993],
              [-37.807611464999979, -7.639984130999949],
              [-37.807556151999961, -7.639908313999968],
              [-37.807445525999981, -7.639739989999953],
              [-37.80733108499993, -7.639564990999929],
              [-37.807189940999933, -7.639358520999963],
              [-37.807041167999955, -7.639140128999941],
              [-37.806900023999958, -7.638926505999962],
              [-37.806777953999983, -7.638716697999939],
              [-37.806663512999933, -7.638518332999979],
              [-37.806545257999971, -7.638344764999943],
              [-37.806427001999964, -7.63817501099993],
              [-37.806331634999935, -7.63800477999996],
              [-37.806247710999969, -7.637871741999959],
              [-37.806179046999944, -7.637793540999951],
              [-37.806121825999981, -7.637718200999927],
              [-37.806049346999941, -7.637629985999979],
              [-37.805973052999946, -7.637549876999969],
              [-37.805923461999953, -7.637505053999973],
              [-37.805828093999935, -7.637424945999953],
              [-37.80570602399996, -7.637328147999938],
              [-37.805580138999971, -7.637229918999935],
              [-37.805465697999978, -7.637136458999976],
              [-37.80534744299996, -7.63705682799997],
              [-37.805217742999957, -7.636973380999962],
              [-37.80507659899996, -7.636888503999955],
              [-37.804916381999931, -7.636789798999928],
              [-37.804759978999982, -7.636673449999932],
              [-37.804595946999939, -7.636553287999959],
              [-37.804443358999947, -7.636436461999949],
              [-37.804298400999983, -7.636303424999937],
              [-37.804180144999975, -7.636129855999968],
              [-37.804061889999957, -7.635916709999947],
              [-37.803932189999955, -7.635703563999925],
              [-37.803817748999961, -7.635515212999962],
              [-37.803722381999933, -7.635358333999932],
              [-37.803600310999968, -7.635210036999979],
              [-37.80347442599998, -7.635019778999947],
              [-37.803401946999941, -7.634715079999978],
              [-37.803390502999946, -7.634465217999946],
              [-37.803421020999963, -7.634218215999965],
              [-37.803462981999985, -7.633983134999937],
              [-37.80350494399994, -7.633761882999977],
              [-37.803558349999946, -7.633513450999942],
              [-37.803607940999939, -7.633251666999968],
              [-37.803665160999969, -7.632993220999936],
              [-37.803707122999981, -7.632744788999958],
              [-37.80375289899996, -7.632533549999948],
              [-37.803821563999975, -7.632319926999969],
              [-37.803924560999974, -7.632120131999955],
              [-37.804027556999984, -7.631959914999925],
              [-37.804107665999936, -7.631806849999975],
              [-37.80419158899997, -7.631631850999952],
              [-37.804260253999985, -7.631476878999933],
              [-37.804340362999937, -7.631299972999955],
              [-37.804416655999944, -7.631118297999933],
              [-37.804405211999949, -7.630958556999929],
              [-37.804340362999937, -7.630831717999968],
              [-37.804298400999983, -7.630772590999925],
              [-37.804256438999971, -7.63071346299995],
              [-37.804149627999948, -7.630595206999942],
              [-37.80403137199994, -7.630473136999967],
              [-37.803962707999972, -7.630416869999976],
              [-37.803901671999938, -7.630361556999958],
              [-37.80381011999998, -7.630278110999939],
              [-37.803718566999976, -7.630169867999939],
              [-37.803623198999958, -7.630060195999931],
              [-37.803512572999978, -7.629938125999956],
              [-37.803386687999932, -7.629826545999947],
              [-37.803279876999966, -7.629743098999938],
              [-37.803207396999937, -7.629670142999942],
              [-37.803134917999955, -7.629590033999932],
              [-37.803062438999973, -7.629495143999975],
              [-37.802986144999977, -7.629381656999954],
              [-37.802944182999966, -7.629244803999939],
              [-37.802944182999966, -7.629078387999925],
              [-37.802894591999973, -7.628878116999942],
              [-37.802822112999934, -7.628706454999929],
              [-37.802768706999984, -7.628568171999973],
              [-37.802726745999962, -7.628431796999962],
              [-37.802673339999956, -7.628260135999938],
              [-37.802600860999974, -7.628053187999967],
              [-37.802524566999978, -7.627836703999947],
              [-37.802452086999949, -7.627616881999927],
              [-37.802379607999967, -7.627361773999951],
              [-37.802307128999985, -7.627196788999981],
              [-37.802253722999978, -7.627061843999968],
              [-37.802188872999977, -7.626961707999953],
              [-37.80210113499993, -7.626861571999939],
              [-37.801975249999941, -7.626741885999934],
              [-37.801853179999966, -7.626621722999971],
              [-37.801750182999967, -7.626504897999951],
              [-37.801635741999974, -7.626389979999942],
              [-37.80152893099995, -7.626305102999936],
              [-37.801425933999951, -7.626231669999981],
              [-37.801353454999969, -7.626186847999975],
              [-37.801242827999943, -7.626121520999959],
              [-37.801063537999937, -7.626051902999961],
              [-37.800865172999977, -7.626048564999962],
              [-37.800655364999955, -7.626073359999964],
              [-37.800495147999982, -7.626131534999956],
              [-37.800361632999966, -7.626208304999977],
              [-37.80020523099995, -7.626249789999974],
              [-37.800052642999958, -7.62626647899998],
              [-37.799915313999975, -7.626291751999929],
              [-37.799755095999956, -7.626329898999927],
              [-37.799468993999938, -7.626358508999942],
              [-37.799266814999953, -7.626383304999933],
              [-37.79904937699996, -7.626410006999947],
              [-37.798816680999948, -7.626458167999942],
              [-37.79858016999998, -7.626500129999954],
              [-37.79833221399997, -7.626498221999952],
              [-37.798091887999931, -7.626483439999959],
              [-37.797851562999938, -7.626464843999941],
              [-37.79761505099998, -7.626473426999951],
              [-37.797389983999949, -7.62651681899996],
              [-37.79717636099997, -7.626568316999965],
              [-37.796966552999947, -7.626636504999965],
              [-37.796752929999968, -7.626706599999977],
              [-37.79654693599997, -7.62676477399998],
              [-37.796333312999934, -7.626843451999946],
              [-37.796100615999933, -7.626943110999946],
              [-37.795867919999978, -7.627063273999966],
              [-37.795650481999985, -7.627206801999932],
              [-37.795452117999957, -7.627343177999933],
              [-37.79535102799997, -7.627379893999944],
              [-37.795249938999973, -7.627416610999944],
              [-37.795043944999975, -7.627388476999954],
              [-37.794769286999951, -7.62728500399993],
              [-37.794563292999953, -7.627178191999974],
              [-37.794384002999948, -7.627045154999962],
              [-37.79423522899998, -7.626911639999946],
              [-37.79406738299997, -7.626780032999932],
              [-37.79387664799998, -7.626666545999967],
              [-37.793674468999939, -7.626564979999955],
              [-37.793487548999963, -7.626456737999945],
              [-37.793312072999981, -7.626329898999927],
              [-37.79313278199993, -7.626181601999974],
              [-37.792922973999964, -7.626060008999957],
              [-37.792690276999963, -7.625976562999938],
              [-37.792446135999967, -7.625915050999936],
              [-37.79220199599996, -7.625843524999937],
              [-37.791965484999935, -7.625763415999927],
              [-37.791728972999977, -7.625685214999976],
              [-37.791496276999965, -7.625606536999953],
              [-37.791263579999963, -7.625546454999949],
              [-37.791038512999933, -7.625529765999943],
              [-37.790817260999972, -7.625555037999959],
              [-37.790596007999966, -7.625591754999959],
              [-37.790363311999954, -7.62563180899997],
              [-37.790119170999958, -7.625686645999963],
              [-37.789787291999971, -7.625743388999979],
              [-37.789527892999956, -7.62576818499997],
              [-37.789287566999974, -7.625781535999977],
              [-37.789066314999957, -7.625791549999974],
              [-37.788879394999981, -7.625800132999927],
              [-37.78873443599997, -7.625803470999927],
              [-37.788547515999937, -7.625798224999926],
              [-37.788314818999936, -7.625801562999925],
              [-37.788108825999984, -7.625798224999926],
              [-37.78787994399994, -7.625786780999931],
              [-37.787624358999949, -7.625781535999977],
              [-37.787364959999934, -7.625781535999977],
              [-37.787113189999957, -7.625783442999932],
              [-37.786869048999961, -7.625786780999931],
              [-37.786632537999935, -7.62579011899993],
              [-37.786411284999929, -7.62579011899993],
              [-37.786212920999958, -7.62579011899993],
              [-37.786026000999982, -7.62579011899993],
              [-37.785827636999954, -7.625798224999926],
              [-37.785598754999967, -7.625833510999939],
              [-37.785369872999979, -7.625893115999929],
              [-37.785140990999935, -7.625946521999936],
              [-37.784954070999959, -7.626021861999959],
              [-37.784702300999982, -7.626093387999958],
              [-37.784473418999937, -7.626190185999974],
              [-37.784259795999958, -7.626325129999941],
              [-37.78406143199993, -7.626454829999943],
              [-37.783855437999932, -7.626581668999961],
              [-37.783660888999975, -7.626701831999981],
              [-37.783470153999929, -7.626808166999979],
              [-37.783283233999953, -7.626923560999955],
              [-37.783088683999949, -7.627036571999952],
              [-37.782882689999951, -7.627161502999968],
              [-37.782684325999981, -7.627281665999931],
              [-37.782543879999935, -7.627381656999944],
              [-37.782508849999942, -7.627406596999947],
              [-37.782329558999947, -7.627544879999959],
              [-37.782232284999964, -7.627619981999942],
              [-37.782135009999934, -7.627695083999981],
              [-37.781921386999954, -7.627860068999951],
              [-37.78171920799997, -7.628016471999956],
              [-37.781555175999983, -7.628196715999934],
              [-37.781410216999973, -7.628413199999954],
              [-37.78128051799996, -7.628641604999927],
              [-37.781150817999958, -7.628863334999949],
              [-37.780986785999971, -7.629059791999964],
              [-37.780841826999961, -7.629271506999942],
              [-37.780693053999983, -7.629584788999978],
              [-37.780643462999933, -7.629851817999963],
              [-37.780597686999954, -7.630129813999929],
              [-37.780548095999961, -7.630406856999969],
              [-37.780490874999941, -7.630666732999941],
              [-37.780422210999973, -7.630908488999978],
              [-37.780330657999968, -7.631134986999939],
              [-37.780231475999983, -7.631353377999972],
              [-37.780136107999965, -7.631573199999934],
              [-37.780082702999948, -7.631804942999963],
              [-37.780048369999975, -7.632044791999931],
              [-37.779979705999949, -7.632275104999962],
              [-37.779891967999959, -7.632501601999934],
              [-37.779838561999952, -7.632736682999962],
              [-37.779769896999937, -7.632951735999939],
              [-37.779651641999976, -7.633153438999955],
              [-37.779502868999941, -7.633349894999981],
              [-37.779388427999947, -7.633556842999951],
              [-37.779296874999943, -7.633775233999927],
              [-37.779163360999974, -7.633971690999942],
              [-37.77899169899996, -7.634138106999956],
              [-37.778736114999958, -7.634338378999928],
              [-37.778537749999941, -7.63448],
              [-37.778343200999984, -7.634620189999964],
              [-37.77817153899997, -7.634771823999927],
              [-37.778064727999947, -7.634968280999942],
              [-37.778026580999949, -7.635183333999976],
              [-37.778045653999982, -7.635383128999933],
              [-37.778072356999985, -7.635581492999961],
              [-37.778068541999971, -7.635799884999926],
              [-37.778041839999958, -7.63601493799996],
              [-37.778015136999954, -7.636225223999929],
              [-37.777999877999946, -7.636443137999947],
              [-37.778015136999954, -7.636650084999928],
              [-37.778053283999952, -7.636848449999945],
              [-37.77809143099995, -7.637046813999973],
              [-37.778125762999935, -7.637266635999936],
              [-37.778186797999979, -7.637493133999953],
              [-37.778289794999978, -7.637704848999931],
              [-37.77838516199995, -7.637899875999949],
              [-37.778438567999956, -7.638090133999981],
              [-37.778465270999959, -7.638278483999954],
              [-37.778465270999959, -7.638464927999962],
              [-37.778469085999973, -7.638643264999928],
              [-37.778480529999968, -7.638921737999965],
              [-37.778484343999935, -7.639074801999925],
              [-37.778446197999983, -7.639355182999964],
              [-37.778404235999972, -7.639571666999927],
              [-37.778369903999931, -7.639800071999957],
              [-37.778327941999976, -7.640038489999938],
              [-37.778293609999935, -7.640289782999957],
              [-37.778266906999932, -7.640548228999933],
              [-37.778263091999975, -7.640796660999968],
              [-37.778263091999975, -7.641039847999934],
              [-37.778263091999975, -7.641271590999963],
              [-37.778270720999956, -7.641489982999929],
              [-37.778293609999935, -7.641699790999951],
              [-37.778335570999957, -7.641918181999927],
              [-37.778358458999946, -7.642148494999958],
              [-37.778316497999981, -7.642373561999932],
              [-37.778240203999985, -7.642596721999951],
              [-37.778156280999951, -7.642823218999979],
              [-37.77809143099995, -7.643051623999952],
              [-37.778022765999935, -7.643266677999975],
              [-37.777900695999961, -7.643438338999943],
              [-37.777725219999979, -7.643563270999948],
              [-37.777473449999945, -7.643691539999963],
              [-37.777305602999945, -7.643768310999974],
              [-37.777133941999978, -7.643843173999926],
              [-37.776931762999936, -7.643961429999933],
              [-37.776760100999979, -7.644114970999965],
              [-37.776596068999936, -7.64430522899994],
              [-37.776412963999974, -7.644503116999942],
              [-37.776206969999976, -7.644690036999975],
              [-37.77599334699994, -7.644849776999934],
              [-37.775753020999957, -7.644985198999962],
              [-37.77553176899994, -7.645120143999975],
              [-37.775295257999971, -7.645243167999979],
              [-37.775070189999951, -7.645351886999947],
              [-37.774871825999981, -7.645451545999947],
              [-37.774665832999972, -7.645533561999969],
              [-37.774459838999974, -7.645599841999967],
              [-37.77426528899997, -7.645689963999928],
              [-37.774082183999951, -7.645796775999941],
              [-37.773891448999962, -7.645884990999946],
              [-37.773693084999934, -7.645941733999962],
              [-37.773494719999974, -7.646009921999962],
              [-37.773250579999967, -7.646114825999973],
              [-37.773071288999972, -7.64618158299993],
              [-37.772884368999939, -7.646256446999928],
              [-37.77269363399995, -7.646329879999939],
              [-37.772518157999968, -7.646395205999966],
              [-37.772373198999958, -7.646471499999961],
              [-37.772228240999937, -7.646538257999964],
              [-37.772106170999962, -7.646583556999929],
              [-37.771980285999973, -7.646656512999925],
              [-37.771850585999971, -7.646738528999947],
              [-37.771709441999974, -7.646810054999946],
              [-37.771579741999972, -7.646900176999964],
              [-37.77145767199994, -7.646991729999968],
              [-37.771335601999965, -7.647069930999976],
              [-37.771224975999985, -7.647153377999928],
              [-37.771141051999962, -7.64726352699995],
              [-37.771060943999942, -7.647385119999967],
              [-37.770980834999932, -7.647518157999968],
              [-37.770896911999955, -7.647663115999933],
              [-37.770820617999959, -7.647809981999956],
              [-37.770748137999931, -7.647938250999971],
              [-37.770679473999962, -7.648063182999977],
              [-37.770595550999928, -7.648181437999938],
              [-37.770519256999933, -7.648326873999963],
              [-37.770458220999956, -7.64844846699998],
              [-37.77038192699996, -7.648571490999927],
              [-37.770313262999935, -7.648694991999946],
              [-37.770229339999958, -7.648828505999973],
              [-37.770137786999953, -7.648964881999973],
              [-37.770046233999949, -7.649111747999939],
              [-37.769950866999977, -7.649274825999953],
              [-37.769859313999973, -7.649428367999974],
              [-37.769783019999977, -7.649563312999931],
              [-37.769733428999984, -7.649680137999951],
              [-37.769680022999978, -7.649761676999958],
              [-37.769645690999937, -7.649816512999962],
              [-37.769580840999936, -7.649928569999929],
              [-37.769493102999945, -7.650081634999935],
              [-37.769363402999943, -7.650226592999957],
              [-37.769214629999965, -7.650386809999929],
              [-37.769062041999973, -7.650541781999948],
              [-37.768913268999938, -7.650691508999955],
              [-37.768768310999974, -7.65081977799997],
              [-37.76865386999998, -7.650939940999933],
              [-37.768562316999976, -7.651034831999937],
              [-37.768466948999958, -7.651134967999951],
              [-37.768302916999971, -7.651315211999929],
              [-37.768192290999934, -7.651438235999933],
              [-37.768108367999957, -7.651543139999944],
              [-37.768009185999972, -7.651656627999955],
              [-37.767887114999951, -7.651794909999978],
              [-37.767761229999962, -7.651938437999945],
              [-37.767726897999978, -7.652121543999954],
              [-37.767791747999979, -7.652306556999974],
              [-37.767890929999965, -7.652491569999938],
              [-37.76799011199995, -7.652691840999978],
              [-37.768074035999973, -7.652903556999945],
              [-37.768150329999969, -7.653116702999966],
              [-37.768264770999963, -7.653326510999932],
              [-37.768398284999932, -7.653553485999964],
              [-37.768531798999959, -7.653768538999941],
              [-37.768638610999972, -7.653966903999958],
              [-37.768737792999957, -7.654148101999965],
              [-37.768810271999939, -7.654311656999937],
              [-37.768878936999954, -7.654438495999955],
              [-37.768962859999931, -7.65455818199996],
              [-37.769062041999973, -7.654709815999979],
              [-37.769149779999964, -7.654884814999946],
              [-37.769161223999959, -7.655116557999975],
              [-37.769126891999974, -7.655354976999945],
              [-37.769031524999946, -7.655633449999925],
              [-37.76893234299996, -7.655806540999947],
              [-37.768836974999942, -7.655948161999959],
              [-37.768756865999933, -7.656054973999971],
              [-37.76867675799997, -7.656165122999937],
              [-37.768577575999984, -7.656301497999948],
              [-37.768451690999939, -7.656449794999958],
              [-37.76831817599998, -7.656599997999933],
              [-37.768184661999953, -7.656741618999945],
              [-37.768054961999951, -7.656848430999958],
              [-37.767959594999979, -7.656903266999961],
              [-37.767871856999932, -7.656913279999969],
              [-37.767780303999984, -7.656931876999977],
              [-37.767662047999977, -7.65694999699997],
              [-37.767532348999964, -7.656981467999969],
              [-37.767375945999959, -7.657053470999927],
              [-37.767304390999982, -7.657113144999926],
              [-37.767246413999942, -7.657161493999979],
              [-37.767222429999947, -7.657181495999964],
              [-37.767120360999968, -7.657266616999948],
              [-37.766986846999941, -7.657364844999961],
              [-37.766904830999977, -7.657437324999933],
              [-37.766822814999955, -7.657509803999972],
              [-37.766677855999944, -7.657673358999944],
              [-37.766559600999983, -7.657826899999975],
              [-37.76639556899994, -7.658031463999976],
              [-37.766269683999951, -7.658176898999955],
              [-37.766151427999944, -7.658331870999973],
              [-37.76603698699995, -7.658488273999978],
              [-37.76592636099997, -7.658653258999948],
              [-37.765834807999966, -7.658823489999975],
              [-37.76573562599998, -7.658989905999931],
              [-37.765647887999933, -7.659156798999959],
              [-37.765579223999964, -7.659305095999969],
              [-37.765537261999953, -7.659438132999981],
              [-37.765522002999944, -7.659510850999936],
              [-37.765506743999936, -7.659583568999949],
              [-37.76546478299997, -7.659718512999973],
              [-37.765392302999942, -7.659831523999969],
              [-37.76531982399996, -7.659951686999932],
              [-37.765296456999977, -7.659991442999967],
              [-37.765259568999966, -7.66005266999997],
              [-37.765207024999938, -7.660139882999943],
              [-37.765206923999983, -7.660140049999939],
              [-37.764965630999939, -7.660540548999961],
              [-37.764113130999931, -7.661955529999943],
              [-37.76408414399998, -7.662003642999935],
              [-37.763641341999971, -7.662738606999937],
              [-37.763591929999961, -7.66282062099998],
              [-37.763323654999965, -7.663229570999931],
              [-37.761531021999929, -7.66596220699995],
              [-37.760974530999931, -7.666810503999955],
              [-37.758499222999944, -7.666634593999959],
              [-37.75693322199993, -7.666523304999942],
              [-37.750647957999945, -7.662889425999936],
              [-37.744180271999937, -7.654495194999981],
              [-37.743404068999951, -7.645193604999974],
              [-37.74307625199998, -7.644210154999939],
              [-37.742984878999948, -7.643936034999967],
              [-37.74269226499996, -7.643058192999945],
              [-37.74203340899993, -7.64108162499997],
              [-37.74194276299994, -7.640809685999955],
              [-37.740667401999929, -7.636983604999955],
              [-37.740367958999968, -7.636085274999971],
              [-37.741598814999975, -7.632638878999956],
              [-37.746382036999933, -7.628306226999939],
              [-37.750696887999936, -7.627279529999953],
              [-37.753322712999932, -7.625802502999932],
              [-37.754881796999939, -7.623504905999937],
              [-37.755567526999982, -7.621167690999926],
              [-37.755847746999962, -7.619521832999965],
              [-37.745705576999967, -7.60933106899995],
              [-37.744220669999947, -7.604407624999965],
              [-37.74410107999995, -7.604011104999927],
              [-37.741452978999973, -7.597532831999956],
              [-37.734949496999945, -7.597214160999954],
              [-37.733179194999934, -7.591957305999927],
              [-37.732047938999983, -7.587034176999964],
              [-37.730581316999974, -7.582903921999957],
              [-37.728732264999962, -7.579730169999948],
              [-37.728731628999981, -7.579730599999948],
              [-37.728465030999985, -7.579911118999973],
              [-37.721031525999933, -7.581892965999941],
              [-37.721031119999964, -7.581892799999935],
              [-37.717259516999945, -7.580355510999937],
              [-37.71558977799998, -7.578591021999955],
              [-37.711011352999947, -7.573752789999958],
              [-37.710472122999931, -7.573182961999976],
              [-37.707567708999932, -7.571722966999971],
              [-37.706571989999929, -7.569466815999931],
              [-37.707566329999963, -7.565148875999967],
              [-37.709984234999979, -7.561465824999971],
              [-37.710088885999937, -7.559525019999967],
              [-37.70753840499998, -7.557916094999939],
              [-37.708446478999974, -7.555879731999937],
              [-37.708621634999929, -7.553872738999928],
              [-37.707856695999965, -7.552189606999946],
              [-37.706505730999936, -7.550583781999933],
              [-37.705988556999955, -7.550491226999952],
              [-37.700997024999936, -7.549597932999973],
              [-37.700996445999976, -7.549597958999925],
              [-37.691820284999949, -7.550008113999979],
              [-37.691819943999974, -7.550008056999957],
              [-37.68215091899998, -7.548376042999962],
              [-37.679855581999959, -7.544970504999981],
              [-37.678462197999977, -7.541665251999973],
              [-37.677972935999946, -7.537295508999932],
              [-37.676634905999947, -7.535613829999932],
              [-37.665276388999985, -7.529413986999941],
              [-37.66310300799995, -7.526112387999945],
              [-37.659529808999935, -7.523137327999962],
              [-37.659529013999929, -7.523137387999952],
              [-37.655818831999966, -7.523416622999946],
              [-37.654107092999936, -7.526030811999931],
              [-37.650474481999936, -7.529663716999949],
              [-37.649827197999969, -7.530311052999934],
              [-37.649427912999954, -7.530710369999952],
              [-37.649427111999955, -7.530710233999969],
              [-37.64460220199993, -7.529888682999967],
              [-37.644541018999973, -7.529878264999979],
              [-37.644501000999981, -7.529871448999927],
              [-37.641344959999969, -7.529333859999952],
              [-37.641344754999977, -7.529333946999941],
              [-37.638001047999978, -7.530746269999952],
              [-37.638000986999941, -7.530746295999961],
              [-37.630378177999944, -7.532511089999957],
              [-37.630378014999962, -7.532510885999955],
              [-37.630284341999982, -7.532393469999931],
              [-37.62689437399996, -7.528144225999938],
              [-37.626883020999969, -7.528129995999961],
              [-37.619943666999973, -7.525879523999947],
              [-37.615914987999929, -7.524943370999949],
              [-37.611372417999974, -7.518568280999943],
              [-37.610644044999958, -7.511819837999951],
              [-37.609770780999952, -7.50803082799996],
              [-37.606622711999933, -7.506431732999943],
              [-37.599982049999937, -7.50450651899996],
              [-37.586729592999973, -7.499108558999978],
              [-37.580259128999955, -7.495520988999942],
              [-37.573608688999968, -7.490476876999935],
              [-37.571791682999958, -7.489098745999968],
              [-37.571300864999955, -7.488790089999952],
              [-37.556930869999974, -7.479753387999949],
              [-37.556929970999931, -7.479753359999961],
              [-37.556891543999939, -7.479752145999953],
              [-37.552536220999968, -7.479614555999945],
              [-37.551610618999973, -7.479585314999952],
              [-37.548793597999975, -7.479496321999932],
              [-37.548761365999951, -7.479495303999954],
              [-37.548761342999967, -7.47949529999994],
              [-37.542591141999935, -7.47828992899997],
              [-37.533066897999959, -7.472953231999952],
              [-37.527958576999936, -7.465310691999946],
              [-37.528590262999955, -7.460067958999957],
              [-37.528840185999968, -7.457993702999943],
              [-37.529090107999934, -7.455919446999928],
              [-37.528900976999978, -7.45354995699995],
              [-37.527404087999969, -7.448382434999928],
              [-37.527226784999982, -7.448190567999973],
              [-37.522957214999963, -7.443570281999939],
              [-37.521706304999952, -7.43947832899994],
              [-37.519730290999973, -7.436659186999975],
              [-37.514175419999958, -7.433034309999925],
              [-37.514207906999957, -7.429134409999961],
              [-37.515639836999981, -7.427557450999927],
              [-37.510668614999929, -7.42052552499996],
              [-37.50695185099994, -7.419748923999975],
              [-37.505694442999982, -7.419159976999936],
              [-37.502644518999944, -7.412943563999931],
              [-37.501346009999963, -7.411462302999951],
              [-37.500340780999977, -7.408593029999963],
              [-37.499534775999962, -7.407362224999929],
              [-37.496981742999935, -7.403463633999934],
              [-37.494784867999954, -7.400109654999937],
              [-37.494674103999955, -7.399022774999935],
              [-37.494672328999968, -7.399021861999927],
              [-37.494512172999976, -7.398939496999958],
              [-37.486957851999932, -7.395054462999951],
              [-37.487626195999951, -7.389419525999926],
              [-37.491906458999949, -7.386302069999942],
              [-37.492428792999931, -7.382586895999964],
              [-37.498094960999936, -7.371128575999933],
              [-37.498039524999967, -7.366842633999966],
              [-37.496038044999978, -7.364822269999934],
              [-37.486308808999979, -7.362138845999937],
              [-37.485854936999942, -7.362060947999964],
              [-37.482237751999946, -7.361440134999953],
              [-37.479170970999974, -7.360049601999947],
              [-37.478704125999968, -7.359978916999978],
              [-37.465922139999975, -7.358043610999971],
              [-37.452285824999933, -7.360288543999957],
              [-37.452285149999966, -7.360288473999958],
              [-37.450139886999978, -7.360066406999977],
              [-37.450139629999967, -7.360066502999928],
              [-37.448569463999945, -7.360655498999961],
              [-37.447549662999961, -7.361038042999951],
              [-37.444753406999951, -7.360676229999967],
              [-37.443107181999949, -7.360463220999975],
              [-37.438877839999975, -7.359915977999947],
              [-37.433700062999947, -7.358698127999958],
              [-37.432317638999962, -7.357444467999926],
              [-37.432127542999979, -7.357272077999937],
              [-37.432127444999935, -7.357271879999928],
              [-37.431822259999933, -7.356656260999955],
              [-37.430338252999945, -7.35366272999994],
              [-37.428763081999932, -7.352567667999949],
              [-37.428762336999966, -7.352567747999956],
              [-37.427609540999981, -7.352691356999969],
              [-37.425512353999977, -7.35291622799997],
              [-37.415474877999941, -7.361719476999951],
              [-37.412652025999932, -7.361873625999976],
              [-37.412651723999943, -7.361873478999939],
              [-37.410317170999974, -7.360736739999936],
              [-37.408140054999933, -7.358104250999929],
              [-37.40687782699996, -7.35813461999993],
              [-37.406712144999972, -7.358138605999955],
              [-37.401927286999978, -7.359022187999926],
              [-37.401926449999962, -7.359022142999947],
              [-37.395394997999972, -7.358671585999957],
              [-37.385713733999978, -7.356693826999958],
              [-37.37241561999997, -7.350561195999944],
              [-37.364638778999961, -7.346070797999971],
              [-37.357786924999971, -7.340666798999962],
              [-37.351489409999942, -7.338520645999949],
              [-37.349511777999965, -7.337028507999946],
              [-37.349376338999946, -7.335118159999979],
              [-37.355857837999963, -7.325726944999928],
              [-37.357284300999936, -7.32311376499996],
              [-37.357008235999956, -7.320989055999974],
              [-37.355457279999939, -7.31914308599994],
              [-37.35213693399993, -7.316807050999955],
              [-37.352121538999938, -7.316787254999952],
              [-37.351785912999958, -7.316355700999964],
              [-37.351780331999976, -7.316348524999967],
              [-37.346038731999954, -7.308965860999933],
              [-37.345819215999938, -7.308683602999963],
              [-37.345403011999963, -7.308148439999968],
              [-37.335197532999985, -7.295026029999974],
              [-37.332593211999949, -7.289731873999926],
              [-37.330585744999951, -7.288283504999981],
              [-37.32821552799993, -7.288058079999928],
              [-37.328215162999982, -7.288058153999941],
              [-37.322886102999973, -7.289131237999925],
              [-37.320482658999936, -7.290493742999956],
              [-37.320481922999932, -7.290493614999946],
              [-37.319390854999938, -7.290304210999977],
              [-37.313959358999966, -7.289361328999973],
              [-37.307811606999962, -7.289241888999925],
              [-37.307612291999931, -7.289238016999946],
              [-37.305656475999967, -7.289200018999964],
              [-37.305656308999971, -7.289199986999961],
              [-37.303072971999939, -7.288697747999947],
              [-37.296827935999943, -7.288421687999971],
              [-37.296827381999947, -7.288421678999953],
              [-37.288332767999975, -7.288288904999945],
              [-37.288332744999934, -7.288288868999928],
              [-37.286847197999975, -7.285918863999939],
              [-37.282022708999932, -7.278827149999927],
              [-37.277762793999955, -7.275184261999925],
              [-37.276930511999979, -7.27488544199997],
              [-37.27526775299998, -7.274288448999926],
              [-37.273955762999947, -7.274276794999935],
              [-37.260818184999948, -7.274160097999925],
              [-37.260731823999947, -7.274168054999961],
              [-37.25532932599998, -7.27466579399993],
              [-37.252953011999978, -7.274884726999971],
              [-37.252952654999945, -7.27488464399994],
              [-37.249558501999957, -7.274099350999961],
              [-37.241906923999977, -7.272953729999927],
              [-37.241906159999985, -7.272953821999977],
              [-37.234178763999978, -7.27388687399997],
              [-37.233612410999967, -7.274396521999961],
              [-37.232843394999975, -7.275088541999935],
              [-37.232512812999971, -7.275385901999925],
              [-37.227930715999946, -7.279507519999981],
              [-37.22270302499993, -7.28606763099998],
              [-37.216781976999982, -7.289701213999933],
              [-37.214175477999959, -7.291300749999948],
              [-37.212473048999982, -7.291397791999941],
              [-37.206753860999981, -7.291723796999975],
              [-37.205162454999936, -7.291814509999938],
              [-37.201966560999949, -7.292750735999959],
              [-37.196673983999972, -7.294670063999945],
              [-37.190950654999938, -7.300229256999955],
              [-37.182981578999943, -7.306417668999927],
              [-37.176924954999947, -7.309552488999941],
              [-37.174794161999955, -7.313472942999965],
              [-37.173511080999958, -7.31583368899993],
              [-37.168530901999929, -7.319951567999965],
              [-37.167647989999978, -7.324066949999974],
              [-37.168107472999964, -7.331890753999971],
              [-37.168111658999976, -7.331962031999979],
              [-37.165341411999975, -7.336595286999966],
              [-37.157067500999972, -7.338213805999942],
              [-37.157066962999977, -7.338213491999966],
              [-37.150757763999934, -7.334534290999954],
              [-37.150757607999935, -7.334534304999977],
              [-37.144943879999971, -7.335045376999972],
              [-37.141288849999967, -7.34126514299993],
              [-37.140336486999956, -7.342125400999976],
              [-37.13531517399997, -7.34666447099994],
              [-37.135314502999961, -7.346664227999952],
              [-37.12797828999993, -7.344001900999956],
              [-37.127978084999938, -7.344002121999949],
              [-37.121563963999961, -7.350914280999973],
              [-37.113787198999944, -7.351292872999977],
              [-37.106704483999977, -7.353890333999971],
              [-37.106703784999979, -7.353890729999932],
              [-37.101862958999959, -7.356631016999927],
              [-37.101862015999984, -7.356630460999952],
              [-37.097345937999933, -7.353968456999951],
              [-37.097345535999978, -7.353968720999944],
              [-37.092145524999978, -7.357381225999973],
              [-37.08562268299994, -7.35825635599997],
              [-37.082813446999978, -7.362403280999956],
              [-37.081397055999958, -7.363534256999969],
              [-37.079217266999933, -7.365274223999961],
              [-37.072949278999943, -7.36827539199993],
              [-37.070714259999932, -7.37425465299998],
              [-37.070324271999937, -7.380215826999972],
              [-37.06943088099996, -7.382383882999932],
              [-37.067413139999985, -7.383379997999953],
              [-37.06741126299994, -7.383379485999967],
              [-37.054260524999961, -7.379791765999926],
              [-37.053184656999974, -7.379498252999952],
              [-37.049988178999968, -7.377588449999962],
              [-37.049986714999932, -7.37758909799993],
              [-37.04880933499993, -7.378110183999979],
              [-37.048214198999972, -7.378146647999927],
              [-37.041476106999937, -7.381354084999941],
              [-37.039468216999978, -7.381727630999933],
              [-37.03107788799997, -7.38561769699993],
              [-37.028523329999985, -7.38581742599996],
              [-37.027923620999957, -7.385864313999946],
              [-37.026972374999957, -7.385938687999953],
              [-37.025623398999983, -7.386044158999937],
              [-37.022538987999951, -7.392727782999941],
              [-37.019473142999971, -7.394323350999969],
              [-37.01682174299998, -7.401670220999961],
              [-37.013701438999931, -7.403792545999977],
              [-37.013360490999958, -7.404024217999961],
              [-37.012971205999975, -7.404288733999977],
              [-37.018369323999934, -7.410867553999935],
              [-37.023693325999943, -7.415807568999981],
              [-37.025973976999978, -7.422450915999946],
              [-37.025978065999936, -7.422462825999958],
              [-37.022059729999967, -7.423445250999976],
              [-37.017564709999931, -7.424572264999938],
              [-37.017564346999961, -7.424572952999938],
              [-37.014508175999936, -7.430368145999978],
              [-37.023615679999978, -7.433867463999945],
              [-37.022800504999964, -7.436027215999957],
              [-37.021225842999968, -7.440196954999976],
              [-37.013177251999934, -7.444983719999925],
              [-37.010999569999967, -7.452685483999971],
              [-37.010730958999943, -7.453635474999942],
              [-37.003338674999952, -7.454889453999954],
              [-36.997310400999936, -7.459393054999964],
              [-36.994062738999958, -7.464710780999951],
              [-36.995452745999955, -7.472590122999975],
              [-36.993006210999965, -7.474148948999925],
              [-36.987589358999969, -7.474026844999969],
              [-36.985423180999931, -7.475601986999948],
              [-36.984671927999955, -7.478072170999951],
              [-36.984225593999952, -7.482261691999952],
              [-36.993588234999947, -7.488478182999927],
              [-36.99767735699993, -7.492521931999931],
              [-37.003253266999934, -7.496388479999951],
              [-37.007284225999967, -7.494439240999952],
              [-37.01056166199993, -7.489557040999955],
              [-37.013897529999952, -7.492098935999934],
              [-37.021674225999959, -7.496687797999925],
              [-37.021674330999929, -7.496687859999952],
              [-37.024553394999941, -7.496695975999955],
              [-37.025201005999975, -7.496697800999925],
              [-37.025201863999939, -7.496697802999961],
              [-37.025991511999962, -7.497212482999942],
              [-37.026162472999943, -7.497323911999956],
              [-37.026163557999951, -7.497324195999965],
              [-37.026237840999954, -7.497343652999973],
              [-37.02635556599995, -7.497374488999981],
              [-37.027366781999945, -7.497639358999947],
              [-37.030414878999977, -7.498437752999962],
              [-37.03510427599997, -7.499666628999933],
              [-37.037370975999977, -7.501214404999928],
              [-37.043156917999966, -7.503914037999948],
              [-37.045730039999967, -7.504412469999977],
              [-37.045730223999954, -7.504412505999937],
              [-37.053821809999931, -7.504568605999964],
              [-37.053822690999937, -7.504568622999955],
              [-37.058432403999973, -7.50770885999998],
              [-37.063332455999955, -7.514211498999941],
              [-37.06515518599997, -7.515638998999975],
              [-37.068308221999985, -7.517108800999949],
              [-37.069887423999944, -7.517438594999931],
              [-37.069888194999976, -7.517438755999933],
              [-37.079280602999972, -7.51658918499993],
              [-37.079281336999941, -7.516589118999946],
              [-37.085058649999951, -7.517304104999937],
              [-37.086195263999969, -7.517792872999962],
              [-37.087454857999944, -7.51833563699995],
              [-37.094561228999964, -7.522175926999978],
              [-37.106104775999938, -7.531127284999968],
              [-37.11063636199998, -7.532775863999973],
              [-37.118343210999967, -7.534430853999936],
              [-37.12152400399998, -7.53611889299998],
              [-37.128775068999971, -7.542728772999965],
              [-37.133731986999976, -7.550042072999929],
              [-37.133733704999941, -7.550044607999951],
              [-37.134522709999942, -7.551250983999978],
              [-37.137102825999932, -7.555195942999944],
              [-37.141713119999963, -7.558966889999965],
              [-37.153451411999981, -7.566047107999964],
              [-37.155769936999945, -7.567879974999926],
              [-37.159326395999983, -7.570691465999971],
              [-37.163569376999931, -7.574714881999967],
              [-37.173228488999939, -7.587897373999965],
              [-37.177325751999945, -7.594179320999956],
              [-37.178981527999952, -7.597085768999932],
              [-37.180120431999967, -7.600307186999942],
              [-37.180522846999963, -7.601992511999981],
              [-37.180558472999962, -7.60214171399997],
              [-37.180591732999972, -7.602281142999971],
              [-37.180630126999972, -7.602442096999937],
              [-37.183159707999948, -7.613046501999975],
              [-37.18410339899998, -7.615816434999942],
              [-37.186100501999931, -7.618416461999971],
              [-37.192752992999942, -7.623819853999976],
              [-37.195021332999943, -7.626937901999952],
              [-37.196672909999961, -7.629581669999936],
              [-37.196690110999953, -7.629652356999941],
              [-37.197660818999964, -7.633641568999963],
              [-37.197946187999946, -7.635297899999955],
              [-37.197321830999954, -7.645435156999952],
              [-37.196684951999941, -7.647489730999951],
              [-37.196048073999975, -7.649544304999949],
              [-37.195131247999939, -7.652062741999941],
              [-37.192740019999974, -7.654839340999956],
              [-37.189168638999945, -7.656626498999969],
              [-37.187772096999936, -7.65763138799997],
              [-37.184872809999945, -7.660766314999933],
              [-37.180116991999967, -7.667105336999953],
              [-37.176812662999964, -7.672780755999952],
              [-37.176351372999932, -7.674542641999949],
              [-37.17631296299993, -7.679236547999949],
              [-37.175757196999939, -7.681381084999941],
              [-37.174058133999949, -7.68429499299998],
              [-37.165273561999982, -7.694075292999969],
              [-37.161038945999962, -7.697966927999971],
              [-37.154542916999958, -7.70640377999996],
              [-37.15372006399997, -7.708036055999969],
              [-37.151948606999952, -7.711550057999943],
              [-37.150497892999965, -7.71593271699993],
              [-37.150189053999952, -7.716865731999974],
              [-37.150179154999933, -7.719802879999975],
              [-37.150966886999981, -7.724046746999932],
              [-37.150599344999932, -7.729032296999947],
              [-37.148724624999943, -7.733732519999933],
              [-37.146280812999976, -7.736636445999977],
              [-37.142564049999976, -7.739030369999966],
              [-37.140003054999966, -7.74193401399998],
              [-37.138944680999941, -7.745517658999972],
              [-37.139198509999972, -7.746189067999978],
              [-37.140663804999974, -7.750064960999964],
              [-37.143573265999976, -7.754278332999945],
              [-37.148328603999971, -7.758147135999934],
              [-37.149753819999944, -7.759678306999945],
              [-37.151668201999939, -7.762379688999943],
              [-37.153810925999949, -7.768120275999934],
              [-37.157032285999946, -7.772999460999927],
              [-37.157176528999969, -7.775454893999949],
              [-37.15678100699995, -7.776868322999974],
              [-37.151950732999978, -7.779844202999925],
              [-37.157199254999966, -7.790586797999936],
              [-37.158481260999963, -7.79233235099997],
              [-37.159812302999967, -7.793216263999966],
              [-37.163763058999962, -7.795839528999977],
              [-37.168254130999969, -7.795120340999972],
              [-37.168395602999965, -7.795122617999937],
              [-37.171366137999939, -7.79517042699996],
              [-37.171589461999929, -7.796536363999962],
              [-37.16972816699996, -7.797889630999975],
              [-37.169454014999928, -7.798797422999939],
              [-37.170667082999955, -7.800228535999963],
              [-37.170667908999974, -7.800229510999941],
              [-37.170668149999983, -7.80022979499995],
              [-37.172486073999949, -7.799981010999943],
              [-37.174382969999954, -7.79972141899998],
              [-37.183035258999951, -7.79071724299996],
              [-37.187392544999966, -7.790734715999974],
              [-37.189032457999929, -7.79114169199994],
              [-37.189032664999957, -7.791141742999969],
              [-37.191536767999935, -7.791169405999938],
              [-37.191538538999964, -7.791169424999964],
              [-37.197957772999985, -7.798916070999951],
              [-37.217416027999946, -7.806685878999929],
              [-37.222996783999974, -7.804063285999973],
              [-37.223000654999964, -7.80406146699994],
              [-37.230561763999958, -7.806740967999929],
              [-37.23010293699997, -7.812793706999969],
              [-37.230177103999949, -7.813109070999928],
              [-37.243656129999977, -7.819001766999975],
              [-37.240461875999983, -7.823094389999937],
              [-37.240461106999931, -7.823094180999931],
              [-37.231862934999981, -7.820758503999969],
              [-37.231862373999945, -7.82075856299997],
              [-37.228105425999956, -7.821153514999935],
              [-37.228154862999929, -7.821518290999961],
              [-37.226543932999959, -7.828476751999972],
              [-37.224926425999968, -7.832721897999932],
              [-37.22281367599993, -7.836209265999969],
              [-37.22220454099994, -7.83866018599997],
              [-37.222092904999954, -7.842250017999959],
              [-37.22248461199996, -7.84283015799997],
              [-37.225867593999965, -7.844678421999959],
              [-37.22586770099997, -7.84467847999997],
              [-37.227536060999967, -7.844690195999931],
              [-37.229530982999961, -7.84408844099994],
              [-37.238339928999949, -7.843931169999962],
              [-37.247150250999937, -7.846803934999969],
              [-37.247783473999959, -7.847756287999971],
              [-37.247619208999936, -7.850840971999958],
              [-37.249312248999956, -7.852060472999938],
              [-37.250370017999956, -7.85365509899998],
              [-37.250202048999938, -7.854890286999932],
              [-37.24728724199997, -7.856913829999939],
              [-37.245836849999932, -7.858855295999945],
              [-37.244925598999941, -7.861585763999926],
              [-37.244726748999938, -7.864029191999975],
              [-37.24601351299998, -7.86581235899996],
              [-37.246867794999957, -7.868178935999936],
              [-37.246699752999973, -7.869413226999939],
              [-37.244093513999985, -7.873845561999929],
              [-37.244648, -7.87490003399995],
              [-37.248951210999962, -7.875743156999931],
              [-37.252331653999931, -7.877833335999981],
              [-37.253383737999968, -7.880242732999932],
              [-37.254033837999941, -7.881731539999976],
              [-37.254034664999949, -7.881733433999955],
              [-37.254108917999929, -7.881903481999927],
              [-37.254337162999946, -7.882426188999943],
              [-37.259529274999977, -7.882844174999946],
              [-37.261790713999972, -7.883946841999943],
              [-37.263510595999946, -7.887567727999965],
              [-37.269893945999968, -7.890960397999947],
              [-37.271729887999982, -7.892454955999938],
              [-37.273977084999956, -7.895372944999963],
              [-37.277124780999941, -7.897847949999971],
              [-37.282364894999944, -7.899940482999966],
              [-37.286145890999933, -7.902465096999947],
              [-37.287394448999976, -7.90387646399995],
              [-37.290733920999969, -7.904986020999957],
              [-37.295727888999977, -7.905812591999961],
              [-37.303651140999932, -7.90417578399996],
              [-37.30365183999993, -7.904175639999949],
              [-37.307278056999962, -7.907307632999959],
              [-37.308008614999949, -7.90793905299995],
              [-37.308008892999965, -7.90793929299997],
              [-37.310734067999931, -7.90704853099993],
              [-37.315704743999959, -7.90756142999993],
              [-37.322172532999957, -7.910502037999947],
              [-37.323120446999951, -7.91216263299998],
              [-37.322768228999962, -7.912998588999926],
              [-37.321809947999952, -7.913345730999936],
              [-37.321809456999972, -7.91334554599996],
              [-37.318118070999958, -7.911954207999941],
              [-37.31325947299996, -7.911385598999971],
              [-37.313259130999938, -7.911385701999961],
              [-37.30985910499993, -7.912405009999929],
              [-37.309335301999965, -7.912824371999932],
              [-37.307984247999968, -7.914986343999942],
              [-37.308092797999961, -7.916267231999939],
              [-37.30964656499998, -7.918638054999974],
              [-37.310622004999971, -7.919412042999966],
              [-37.311400731999981, -7.921648900999969],
              [-37.310985668999933, -7.922510164999949],
              [-37.309113004999972, -7.924048424999967],
              [-37.308483490999947, -7.925839321999945],
              [-37.30805421599996, -7.927060560999962],
              [-37.307858952999936, -7.929143575999944],
              [-37.307887747999985, -7.931316852999942],
              [-37.308066921999966, -7.932183583999972],
              [-37.308976362999942, -7.934176241999978],
              [-37.310646555999938, -7.934831136999946],
              [-37.312286856999947, -7.934831806999966],
              [-37.312288518999935, -7.934831806999966],
              [-37.314702617999956, -7.935933979999959],
              [-37.315599114999941, -7.937122793999947],
              [-37.316589203999968, -7.941218811999931],
              [-37.31762404899996, -7.942769966999947],
              [-37.318979154999965, -7.943100102999949],
              [-37.322059096999965, -7.941696967999974],
              [-37.32297545199998, -7.941707952999934],
              [-37.322976318999963, -7.941707962999942],
              [-37.326338003999979, -7.943818657999941],
              [-37.330310139999938, -7.944741918999966],
              [-37.330310821999944, -7.944742076999944],
              [-37.332504909999955, -7.943534031999945],
              [-37.33473039699993, -7.94304673299996],
              [-37.334731738999949, -7.943046438999943],
              [-37.337229623999974, -7.944975399999976],
              [-37.339869587999942, -7.945207294999932],
              [-37.343453351999983, -7.947020859999952],
              [-37.34545155099994, -7.947168087999955],
              [-37.345452365999961, -7.947168147999946],
              [-37.348591995999982, -7.946298879999972],
              [-37.348592750999956, -7.946298670999965],
              [-37.349903667999968, -7.946644455999945],
              [-37.350218723999944, -7.948597486999972],
              [-37.350271838999959, -7.948926745999927],
              [-37.350281482999947, -7.948986526999931],
              [-37.350302304999957, -7.949115602999939],
              [-37.347665690999975, -7.954361794999954],
              [-37.347923502999947, -7.955411546999926],
              [-37.350175142999944, -7.958287660999929],
              [-37.351174135999941, -7.960987858999943],
              [-37.351286877999939, -7.966470275999939],
              [-37.352010435999944, -7.968522273999952],
              [-37.356179198999939, -7.972730446999947],
              [-37.357145726999931, -7.974003072999949],
              [-37.357368462999943, -7.974955982999973],
              [-37.356839849999972, -7.97568356599993],
              [-37.355870971999934, -7.97586509599995],
              [-37.355870280999966, -7.975864815999955],
              [-37.353924806999942, -7.975076761999958],
              [-37.353923959999975, -7.97507675099996],
              [-37.344815654999934, -7.974959670999965],
              [-37.34481522699997, -7.974959618999947],
              [-37.341284195999947, -7.974528820999979],
              [-37.341284121999934, -7.974528886999963],
              [-37.340596822999942, -7.975144220999937],
              [-37.340288957999974, -7.979683276999936],
              [-37.339784628999951, -7.979804510999941],
              [-37.339782629999945, -7.979803926999978],
              [-37.338528116999953, -7.979437103999942],
              [-37.337148750999972, -7.979033772999969],
              [-37.333755286999974, -7.976291477999951],
              [-37.327170298999931, -7.974503873999936],
              [-37.320535204999942, -7.96966995799994],
              [-37.316993680999929, -7.96806522199995],
              [-37.312426820999974, -7.967758458999981],
              [-37.309390518999976, -7.968474031999961],
              [-37.30606513999993, -7.970092072999932],
              [-37.303336835999971, -7.970212369999956],
              [-37.303336743999978, -7.970212223999965],
              [-37.300818127999946, -7.966197128999966],
              [-37.298379392999948, -7.96522912699993],
              [-37.295575480999958, -7.964920954999968],
              [-37.291779346999931, -7.965310854999927],
              [-37.285003487999973, -7.970389609999927],
              [-37.284005319999949, -7.970793862999926],
              [-37.281724707999956, -7.970801073999951],
              [-37.277402662999975, -7.971978836999938],
              [-37.277402062999954, -7.971978717999946],
              [-37.27572704399995, -7.97164791199998],
              [-37.267227912999942, -7.969969383999967],
              [-37.266751592999981, -7.969615431999955],
              [-37.265564777999941, -7.968733512999961],
              [-37.263535564999984, -7.968177279999964],
              [-37.26353525199994, -7.968177407999974],
              [-37.257975125999963, -7.970445176999931],
              [-37.257974393999973, -7.970445070999972],
              [-37.255674602999932, -7.97011052199997],
              [-37.249982737999972, -7.969282533999944],
              [-37.249982169999953, -7.969282547999967],
              [-37.246983114999978, -7.969356329999925],
              [-37.244584033999956, -7.971240307999949],
              [-37.241112805999933, -7.972777847999964],
              [-37.237342443999978, -7.973306948999948],
              [-37.237341598999933, -7.973306742999966],
              [-37.235059095999929, -7.972750293999979],
              [-37.230761421999944, -7.969397020999963],
              [-37.230760906999933, -7.96939729099995],
              [-37.229898240999944, -7.969849701999976],
              [-37.229686454999978, -7.972218638999948],
              [-37.228783822999958, -7.972594237999942],
              [-37.228781911999931, -7.972593796999945],
              [-37.227451925999958, -7.972286969999971],
              [-37.225665796999976, -7.97187490999994],
              [-37.221264490999943, -7.97283773099997],
              [-37.219588774999977, -7.972793435999961],
              [-37.219511538999939, -7.972728013999927],
              [-37.218835733999981, -7.972155579999935],
              [-37.218394856999964, -7.970740698999975],
              [-37.221160973999929, -7.964973249999957],
              [-37.221516961999953, -7.963452138999969],
              [-37.221437177999974, -7.962408968999966],
              [-37.220894106999936, -7.959855432999973],
              [-37.217279417999976, -7.956390142999965],
              [-37.217277500999955, -7.956388304999962],
              [-37.215374552999947, -7.955752837999967],
              [-37.214246848999949, -7.955376254999976],
              [-37.212871186999962, -7.954916868999931],
              [-37.21286846299995, -7.954915190999941],
              [-37.211459084999944, -7.95404674599996],
              [-37.208078997999962, -7.951963969999952],
              [-37.207920078999962, -7.951866044999974],
              [-37.207919660999949, -7.951866310999947],
              [-37.207460654999977, -7.95215782899993],
              [-37.207393210999953, -7.952200662999928],
              [-37.207107377999932, -7.95238246699995],
              [-37.206948900999976, -7.952483265999945],
              [-37.206925250999973, -7.952498307999974],
              [-37.206900403999953, -7.95251411199996],
              [-37.20662183099995, -7.952691297999934],
              [-37.205696928999942, -7.953279580999947],
              [-37.205019858999947, -7.953710229999956],
              [-37.203591980999931, -7.955726962999961],
              [-37.202762467999946, -7.956243394999944],
              [-37.200757635999935, -7.957274965999943],
              [-37.194201112999963, -7.959330207999926],
              [-37.192675587999929, -7.960598180999966],
              [-37.191712755999959, -7.964876708999952],
              [-37.191572976999964, -7.968402509999976],
              [-37.192362204999938, -7.970231829999932],
              [-37.192566644999943, -7.977434979999941],
              [-37.192589167999984, -7.978228539999975],
              [-37.193394607999949, -7.982866728999966],
              [-37.192755784999974, -7.983572740999932],
              [-37.190562935999935, -7.982980332999944],
              [-37.189560301999961, -7.982709467999939],
              [-37.189559850999956, -7.982709555999975],
              [-37.18827525699993, -7.982958848999942],
              [-37.187514912999973, -7.98754545099996],
              [-37.185469199999943, -7.99044473999993],
              [-37.185353214999964, -7.990612084999952],
              [-37.184150889999955, -7.991289095999946],
              [-37.184149677999983, -7.991288686999951],
              [-37.179980654999952, -7.989880704999962],
              [-37.179979961999948, -7.989881012999945],
              [-37.178392214999974, -7.990586013999973],
              [-37.177683595999952, -7.991093904999957],
              [-37.176996251999981, -7.992217166999978],
              [-37.176141351999945, -7.996475157999953],
              [-37.175309952999953, -7.996858439999926],
              [-37.175308574999974, -7.99685816899995],
              [-37.167757680999955, -7.99537240799998],
              [-37.167756868999959, -7.995372533999955],
              [-37.163198578999982, -7.99608122199993],
              [-37.160418938999953, -7.997152917999927],
              [-37.158944916999928, -7.998801165999964],
              [-37.157703381999966, -8.000189447999958],
              [-37.15501558099993, -8.006412212999976],
              [-37.152022611999939, -8.00902539599997],
              [-37.151794607999932, -8.009414827999933],
              [-37.150337824999951, -8.015263768999944],
              [-37.149653975999968, -8.022814703999927],
              [-37.149570341999947, -8.02373817299997],
              [-37.14953249499996, -8.024105688999953],
              [-37.148398719999932, -8.035115384999926],
              [-37.153651305999972, -8.045269097999949],
              [-37.158092427999975, -8.05122433899993],
              [-37.156920988999957, -8.058252762999928],
              [-37.156845221999959, -8.064534518999949],
              [-37.152200757999935, -8.070417214999964],
              [-37.150989516999971, -8.072193290999962],
              [-37.146637091999935, -8.078575376999936],
              [-37.145509530999959, -8.082189073999928],
              [-37.143707128999949, -8.087965554999926],
              [-37.145784115999959, -8.095202745999927],
              [-37.147817861999954, -8.100093270999935],
              [-37.146867532999977, -8.109159990999956],
              [-37.142557823999937, -8.11794479799994],
              [-37.144172269999956, -8.124996433999968],
              [-37.153228570999943, -8.127467243999945],
              [-37.154842364999979, -8.132184475999964],
              [-37.158613837999951, -8.138869384999964],
              [-37.152454261999935, -8.144873452999946],
              [-37.151003957999933, -8.150041553999927],
              [-37.157477383999947, -8.156462139999974],
              [-37.163421267999979, -8.158687714999928],
              [-37.163616030999947, -8.165761851999946],
              [-37.160225322999963, -8.170400441999959],
              [-37.149586088999968, -8.174940915999969],
              [-37.141192624999974, -8.177234143999954],
              [-37.141191982999942, -8.177234034999969],
              [-37.133197433999953, -8.175880528999926],
              [-37.126452197999981, -8.17368388999995],
              [-37.126451219999979, -8.173684088999948],
              [-37.118260061999933, -8.175351880999926],
              [-37.118661759999952, -8.180390282999952],
              [-37.118948752999984, -8.183989965999956],
              [-37.119843678999985, -8.184734229999947],
              [-37.124493102999963, -8.188600915999928],
              [-37.124806603999957, -8.189834547999965],
              [-37.126099784999951, -8.191496279999967],
              [-37.125198963999935, -8.198906295999961],
              [-37.123389891999977, -8.207394077999936],
              [-37.122914845999958, -8.20906641199997],
              [-37.11855124799996, -8.21497616299996],
              [-37.114490968999974, -8.220087315999933],
              [-37.112807811999971, -8.223110650999956],
              [-37.110394268999983, -8.225549099999967],
              [-37.108588218999955, -8.226636529999951],
              [-37.105815606999954, -8.227828135999971],
              [-37.104275558999973, -8.228980903999968],
              [-37.102664313999981, -8.231099205999953],
              [-37.100428482999973, -8.233706838999979],
              [-37.100427106999973, -8.233706254999959],
              [-37.09722658499993, -8.232347098999981],
              [-37.089892691999978, -8.231165888999953],
              [-37.083006931999932, -8.222402679999959],
              [-37.080895411999961, -8.221891434999975],
              [-37.080895232999978, -8.221891573999926],
              [-37.071332779999977, -8.229315878999955],
              [-37.067417650999971, -8.230102475999956],
              [-37.066530668999974, -8.232999168999982],
              [-37.074169880999932, -8.23631794399995],
              [-37.080620412999963, -8.241082029999973],
              [-37.08150816999995, -8.245667209999965],
              [-37.082226826999943, -8.261887406999961],
              [-37.081203367999933, -8.266373513999952],
              [-37.081201575999955, -8.266374046999942],
              [-37.078780764999976, -8.267093177999925],
              [-37.075724513999944, -8.268001074999972],
              [-37.075723932999949, -8.268000941999958],
              [-37.058231315999933, -8.263984309999955],
              [-37.056249506999961, -8.264633565999929],
              [-37.054977476999966, -8.265050292999945],
              [-37.049252989999957, -8.265277527999956],
              [-37.049252537999962, -8.265277472999969],
              [-37.043333822999955, -8.264559821999967],
              [-37.043333597999947, -8.264559940999959],
              [-37.04028361099995, -8.266179553999962],
              [-37.040112853999972, -8.266385152999931],
              [-37.031832650999945, -8.276311652999937],
              [-37.027857627999936, -8.283405300999959],
              [-37.023037012999964, -8.288589573999957],
              [-37.02064828999994, -8.28967219499998],
              [-37.020646731999932, -8.28967290099996],
              [-37.020637890999978, -8.289676907999933],
              [-37.015343716999951, -8.292076340999927],
              [-37.015208041999983, -8.29213783199998],
              [-37.014804094999931, -8.292320909999944],
              [-37.011970103999943, -8.294030194999948],
              [-37.00392769299998, -8.295932453999967],
              [-36.997637478999934, -8.299628656999971],
              [-36.991156283999942, -8.302955050999969],
              [-36.991153966999946, -8.302954731999932],
              [-36.990164141999969, -8.302818641999977],
              [-36.987544773999957, -8.302458506999926],
              [-36.986379806999935, -8.302029106999953],
              [-36.985170309999944, -8.301583294999944],
              [-36.981912034999937, -8.29912378299997],
              [-36.978018052999971, -8.297416240999951],
              [-36.975198429999978, -8.297401906999937],
              [-36.974971315999937, -8.29740075199993],
              [-36.960202323999965, -8.300467414999957],
              [-36.960201110999947, -8.300467215999959],
              [-36.957377157999929, -8.30000382999998],
              [-36.955641463999939, -8.299542106999979],
              [-36.954922883999984, -8.299350951999941],
              [-36.954836368999963, -8.299327937999976],
              [-36.954629285999943, -8.299272849999966],
              [-36.954544719999944, -8.299210984999945],
              [-36.951388168999983, -8.296901783999942],
              [-36.951388057999964, -8.296902061999958],
              [-36.951305370999933, -8.297109109999951],
              [-36.951304059999984, -8.297107770999958],
              [-36.949255817999983, -8.295015871999965],
              [-36.948535451999931, -8.293927547999942],
              [-36.947308713999973, -8.291064242999937],
              [-36.947404624999933, -8.287652497999943],
              [-36.947577222999939, -8.287304907999953],
              [-36.948385363999932, -8.283647440999971],
              [-36.947726488999933, -8.279119997999942],
              [-36.947025456999938, -8.277368304999925],
              [-36.94572007499994, -8.27631768699996],
              [-36.944946801999947, -8.275253296999949],
              [-36.942163414999982, -8.273890765999965],
              [-36.933043962999932, -8.273265263999974],
              [-36.922998815999961, -8.269829800999958],
              [-36.917095331999974, -8.264824757999975],
              [-36.913627515999963, -8.260928747999969],
              [-36.908634814999971, -8.25345144299996],
              [-36.908277260999967, -8.253106421999973],
              [-36.908263466999983, -8.253093110999941],
              [-36.908171821999929, -8.253004677999968],
              [-36.903358387999958, -8.248359967999932],
              [-36.902929448999942, -8.248096222999948],
              [-36.899997829999961, -8.243660804999934],
              [-36.895950008999932, -8.240820105999944],
              [-36.89230717199996, -8.239503001999935],
              [-36.890446470999962, -8.238830245999964],
              [-36.886284344999979, -8.23785563499996],
              [-36.882109656999944, -8.236878081999976],
              [-36.879229029999976, -8.235644730999979],
              [-36.871920228999954, -8.230191949999949],
              [-36.868610959999955, -8.227067164999937],
              [-36.861702339999965, -8.222426828999971],
              [-36.852286929999934, -8.219092754999963],
              [-36.843290706999937, -8.216942456999959],
              [-36.84328895699997, -8.21694257799993],
              [-36.843014616999938, -8.216961511999955],
              [-36.843014282999945, -8.216961334999951],
              [-36.838602252999976, -8.21462669999994],
              [-36.833254838999949, -8.212545803999944],
              [-36.824349265999956, -8.208207653999978],
              [-36.821143355999936, -8.207412],
              [-36.818451640999967, -8.206984852999938],
              [-36.815992178999977, -8.206810695999934],
              [-36.813349850999941, -8.20565948199993],
              [-36.812511173999951, -8.205294084999935],
              [-36.80577907299994, -8.195444742999939],
              [-36.803355431999933, -8.193538238999963],
              [-36.79739529099993, -8.190509123999959],
              [-36.794346054999949, -8.189521623999951],
              [-36.793200104999983, -8.18945144199995],
              [-36.793199825999977, -8.189451461999965],
              [-36.79085949499995, -8.189618161999931],
              [-36.787836626999933, -8.189833478999958],
              [-36.780810531999975, -8.190333944999963],
              [-36.775373933999958, -8.190721190999966],
              [-36.770831754999961, -8.192719634999946],
              [-36.767285422999976, -8.195980249999934],
              [-36.764880152999979, -8.196818348999955],
              [-36.764878759999931, -8.196818127999961],
              [-36.761569720999944, -8.196293868999931],
              [-36.754854191999982, -8.192444571999943],
              [-36.753508185999976, -8.191478134999954],
              [-36.750863367999955, -8.185694843999954],
              [-36.750142419999975, -8.175703988999942],
              [-36.74421429399996, -8.173569198999928],
              [-36.742607326999973, -8.172990510999966],
              [-36.740889856999956, -8.172372003999953],
              [-36.730695645999958, -8.17282936099997],
              [-36.730694066999945, -8.172829275999959],
              [-36.723971983999945, -8.172468673999958],
              [-36.722404948999952, -8.16833362999995],
              [-36.721298356999966, -8.168073583999956],
              [-36.715781318999973, -8.167316205999953],
              [-36.717424935999929, -8.163553346999947],
              [-36.718874710999955, -8.161622196999929],
              [-36.725781345999962, -8.156448308999927],
              [-36.722810429999981, -8.150964664999947],
              [-36.722807486999955, -8.150965776999954],
              [-36.708676838999963, -8.156304205999959],
              [-36.708660407999957, -8.156309954999927],
              [-36.707892585999957, -8.156578598999943],
              [-36.707802741999956, -8.156610031999946],
              [-36.703694040999949, -8.15804757099994],
              [-36.703692787999955, -8.158046946999946],
              [-36.696975778999956, -8.154701073999945],
              [-36.696974388999934, -8.154701210999974],
              [-36.687035917999935, -8.15567949299998],
              [-36.687393862999954, -8.152284169999973],
              [-36.687433990999978, -8.151903524999966],
              [-36.69033213299997, -8.148707725999941],
              [-36.694465539999953, -8.143866561999971],
              [-36.694525752999937, -8.142218358999969],
              [-36.693862211999942, -8.140772719999973],
              [-36.689825338999981, -8.138263835999965],
              [-36.689751355999931, -8.138217855999926],
              [-36.689030012999979, -8.137769546999948],
              [-36.672460719999947, -8.127471864999961],
              [-36.671956896999973, -8.127302374999942],
              [-36.66784533799995, -8.124265875999981],
              [-36.665228942999931, -8.123256893999951],
              [-36.657217529999969, -8.12203046299993],
              [-36.653737798999941, -8.120747300999938],
              [-36.644190690999949, -8.120342147999963],
              [-36.640320932999941, -8.119910600999958],
              [-36.639913775999958, -8.119629912999926],
              [-36.637532105999981, -8.118551752999963],
              [-36.636408595999967, -8.116871298999968],
              [-36.635180906999949, -8.11559525399997],
              [-36.634109586999955, -8.114684896999961],
              [-36.628612515999976, -8.111050372999955],
              [-36.627325460999941, -8.106599906999975],
              [-36.627852358999974, -8.102858026999968],
              [-36.628688482999962, -8.101898613999936],
              [-36.63377391399996, -8.096063322999953],
              [-36.636984947999963, -8.093246961999967],
              [-36.640333458999976, -8.092108783999947],
              [-36.645015599999965, -8.09278571699997],
              [-36.645016734999956, -8.092785880999941],
              [-36.647238326999968, -8.092490089999956],
              [-36.647659614999952, -8.091256115999954],
              [-36.646950557999958, -8.086142351999968],
              [-36.648634605999973, -8.082731057999979],
              [-36.650361865999969, -8.08133083499996],
              [-36.650362639999969, -8.08133020799994],
              [-36.653611156999943, -8.082561735999946],
              [-36.657273926999949, -8.082077521999963],
              [-36.658682307999982, -8.079808993999961],
              [-36.658555246999981, -8.078919792999955],
              [-36.657831502999954, -8.076132667999957],
              [-36.657831457999976, -8.076132567999935],
              [-36.657578248999982, -8.075560254999971],
              [-36.656474408999941, -8.073065318999966],
              [-36.653790886999957, -8.070384073999946],
              [-36.646616359999939, -8.065548489999969],
              [-36.641210951999938, -8.062237617999926],
              [-36.634208835999971, -8.059308000999977],
              [-36.628824166999948, -8.057056104999958],
              [-36.627851888999942, -8.055749272999947],
              [-36.627774191999947, -8.051523998999926],
              [-36.629144040999961, -8.038351217999946],
              [-36.629330919999973, -8.028159709999954],
              [-36.629265239999938, -8.025449953999953],
              [-36.628197883999974, -8.014070715999935],
              [-36.62819776799995, -8.014069478999943],
              [-36.627163737999979, -8.005360344999929],
              [-36.626545197999974, -8.002704563999941],
              [-36.627851495999948, -7.986406648999946],
              [-36.627427973999943, -7.985916955999926],
              [-36.623171942999932, -7.982081469999969],
              [-36.622976507999965, -7.981169849999958],
              [-36.62253435599996, -7.980357395999931],
              [-36.623334179999972, -7.976144241999975],
              [-36.622654178999937, -7.969508388999941],
              [-36.622457391999944, -7.96923213599996],
              [-36.621443624999984, -7.96601014099997],
              [-36.619413638999958, -7.961542442999928],
              [-36.618493013999966, -7.961001727999928],
              [-36.618492694999929, -7.961001648999968],
              [-36.61796905999995, -7.960871144999942],
              [-36.617371313999968, -7.960722171999976],
              [-36.613463452999952, -7.956516951999959],
              [-36.612950282999975, -7.954830055999935],
              [-36.612519822999957, -7.949439017999964],
              [-36.611876869999946, -7.947896706999927],
              [-36.610898611999971, -7.947429061999969],
              [-36.610897510999962, -7.947429329999977],
              [-36.609659114999943, -7.947730873999944],
              [-36.604524955999977, -7.952388156999973],
              [-36.604523922999931, -7.952388206999956],
              [-36.601468219999958, -7.952535517999934],
              [-36.600426802999948, -7.95258572299997],
              [-36.599622703999955, -7.952894525999966],
              [-36.595051130999934, -7.955921573999944],
              [-36.593144222999967, -7.956685524999955],
              [-36.586860958999978, -7.958656057999974],
              [-36.581078085999934, -7.959786177999945],
              [-36.576780307999968, -7.959645587999944],
              [-36.576780286999963, -7.959645573999978],
              [-36.573482333999948, -7.957434271999944],
              [-36.573208292999936, -7.955951173999949],
              [-36.572859318999974, -7.954062530999977],
              [-36.57418785699997, -7.95167440299997],
              [-36.575823087999936, -7.950728296999955],
              [-36.579443563999973, -7.949512153999933],
              [-36.579979836999939, -7.948640484999942],
              [-36.579991358999962, -7.946684088999973],
              [-36.577892202999976, -7.944834347999972],
              [-36.574435592999976, -7.940088918999948],
              [-36.572588189999976, -7.935974962999978],
              [-36.572534299999973, -7.930567220999933],
              [-36.577622146999943, -7.921272383999963],
              [-36.578199908999977, -7.915247600999976],
              [-36.576039392999974, -7.90946486699994],
              [-36.574811875999956, -7.907500622999976],
              [-36.571706009999957, -7.904495612999938],
              [-36.569353059999969, -7.903444074999925],
              [-36.568705773999966, -7.903154800999971],
              [-36.563878910999961, -7.900997665999967],
              [-36.552020165999977, -7.898713995999969],
              [-36.55201911499995, -7.898714207999944],
              [-36.549535036999941, -7.89921619699993],
              [-36.543042525999965, -7.902317800999981],
              [-36.543040122999969, -7.902317541999935],
              [-36.53646447999995, -7.901609310999959],
              [-36.536463882999954, -7.90160943099994],
              [-36.533630599999981, -7.902177878999964],
              [-36.531473388999984, -7.902798559999951],
              [-36.526224537999951, -7.906811048999941],
              [-36.512854532999938, -7.914871524999967],
              [-36.49938876799996, -7.920314876999953],
              [-36.499386927999979, -7.920314412999971],
              [-36.497791494999944, -7.919911638999963],
              [-36.495951285999979, -7.918263107999962],
              [-36.492479752999941, -7.915153174999944],
              [-36.490133256999968, -7.914779592999935],
              [-36.490132551999977, -7.914779804999966],
              [-36.488116317999982, -7.91538466399993],
              [-36.486664398999949, -7.916354962999947],
              [-36.482872706999956, -7.920297068999957],
              [-36.480136377999941, -7.924763255999949],
              [-36.478122152999958, -7.92480425499997],
              [-36.478119753999977, -7.924803276999967],
              [-36.47657655699993, -7.924173787999962],
              [-36.47044683699994, -7.917801336999958],
              [-36.467682019999984, -7.91646712599993],
              [-36.46409988299996, -7.915350071999967],
              [-36.461251800999946, -7.914656085999979],
              [-36.461250421999978, -7.914656118999972],
              [-36.452715009999963, -7.914859579999927],
              [-36.446301948999974, -7.916379934999952],
              [-36.446300925999935, -7.916379422999967],
              [-36.446296427999982, -7.916377172999944],
              [-36.444109588999936, -7.915283271999954],
              [-36.443697307999969, -7.914330874999962],
              [-36.444000174999928, -7.912154015999931],
              [-36.445557621999967, -7.910618308999972],
              [-36.445755254999938, -7.908168212999954],
              [-36.44352386199995, -7.905614467999953],
              [-36.439690800999983, -7.903384753999944],
              [-36.436918429999935, -7.90026884599996],
              [-36.434379384999943, -7.896318289999954],
              [-36.434379163999949, -7.896317886999952],
              [-36.430386940999938, -7.889017093999939],
              [-36.429695220999974, -7.886559187999978],
              [-36.429859026999964, -7.885068751999938],
              [-36.431140923999976, -7.881744741999967],
              [-36.432721324999932, -7.879986754999948],
              [-36.435331892999955, -7.879250738999929],
              [-36.43950412099997, -7.879107400999942],
              [-36.440383615999963, -7.878250953999952],
              [-36.439920936999954, -7.876199131999954],
              [-36.436026655999967, -7.874124094999956],
              [-36.436024592999956, -7.874123451999935],
              [-36.435305289999974, -7.873899288999951],
              [-36.432721411999978, -7.873094051999942],
              [-36.430832361999933, -7.868546418999927],
              [-36.430051210999977, -7.867494670999974],
              [-36.429057289999946, -7.867037190999952],
              [-36.428692480999985, -7.864402215999974],
              [-36.428945014999954, -7.858498745999952],
              [-36.430940048999958, -7.849847309999973],
              [-36.428349089999983, -7.833406028999946],
              [-36.429109976999939, -7.830440703999955],
              [-36.429964235999932, -7.827111488999947],
              [-36.428033303999939, -7.819603428999926],
              [-36.424047567999935, -7.815778198999965],
              [-36.407422624999981, -7.81407560599996],
              [-36.399428400999966, -7.810426683999935],
              [-36.39642768899995, -7.808697268999936],
              [-36.394539079999959, -7.807608743999936],
              [-36.394538116999968, -7.807608762999962],
              [-36.39259635999997, -7.807647293999935],
              [-36.373453464999955, -7.812620559999971],
              [-36.373451714999931, -7.812620199999969],
              [-36.368247328999985, -7.811550202999967],
              [-36.366423705999978, -7.809066336999933],
              [-36.363171706999935, -7.806079500999942],
              [-36.360720866999941, -7.805718154999965],
              [-36.360720298999979, -7.805718363999972],
              [-36.357544193999956, -7.806884811999964],
              [-36.353145427999948, -7.807646512999952],
              [-36.348547693999933, -7.807470054999953],
              [-36.340871556999957, -7.808755608999945],
              [-36.336884723999958, -7.809423299999935],
              [-36.330293084999937, -7.809504629999935],
              [-36.32899641299997, -7.809520628999962],
              [-36.328993784999966, -7.809520519999978],
              [-36.328947391999975, -7.809518583999932],
              [-36.320737958999985, -7.809176042999979],
              [-36.314185810999959, -7.806721567999944],
              [-36.313397872999928, -7.806426401999943],
              [-36.313397797999983, -7.806426406999947],
              [-36.309131457999968, -7.806687194999938],
              [-36.302013324999962, -7.808337717999962],
              [-36.294361997999943, -7.811898195999959],
              [-36.285242473999972, -7.81789088599993],
              [-36.279369740999982, -7.825065645999928],
              [-36.27111019299997, -7.830565301999968],
              [-36.271108077999941, -7.830565628999977],
              [-36.26783099499994, -7.83107240399994],
              [-36.263506205999931, -7.831741197999975],
              [-36.263505577999979, -7.831741294999972],
              [-36.261334579999982, -7.832077022999954],
              [-36.261332894999953, -7.832076577999942],
              [-36.255261402999963, -7.830472147999956],
              [-36.253294111999935, -7.82995227899994],
              [-36.253293453999959, -7.829951841999957],
              [-36.24749533399995, -7.826101512999969],
              [-36.243406749999963, -7.822178784999949],
              [-36.240012084999933, -7.817680998999947],
              [-36.239538360999973, -7.811211507999928],
              [-36.242305509999937, -7.807333511999957],
              [-36.251232682999955, -7.804574061999972],
              [-36.254336354999964, -7.801693300999943],
              [-36.254658438999968, -7.799650650999979],
              [-36.254196025999931, -7.796386813999959],
              [-36.252000230999954, -7.792144682999947],
              [-36.246305441999937, -7.785310668999955],
              [-36.233942355999943, -7.776359889999981],
              [-36.22721811699995, -7.769974604999959],
              [-36.22048012099998, -7.765620959999978],
              [-36.216634254999974, -7.764104163999946],
              [-36.216632842999957, -7.764104889999942],
              [-36.211305993999929, -7.766845652999962],
              [-36.206484964999959, -7.770377746999941],
              [-36.204667125999947, -7.773558163999951],
              [-36.204348679999953, -7.782962722999969],
              [-36.200958377999939, -7.789903159999938],
              [-36.197873155999957, -7.79038062199993],
              [-36.197871951999957, -7.790380102999961],
              [-36.192504153999948, -7.788063690999934],
              [-36.189080012999966, -7.785970801999952],
              [-36.183526916999938, -7.783902269999942],
              [-36.182988234999982, -7.783874379999929],
              [-36.182272960999967, -7.783837346999974],
              [-36.17985178999993, -7.78371199299994],
              [-36.17804575699995, -7.783618486999956],
              [-36.178045450999946, -7.783618512999965],
              [-36.17586930899995, -7.783804975999942],
              [-36.172217374999946, -7.784117891999927],
              [-36.172213875999944, -7.784117735999928],
              [-36.162370128999953, -7.783678558999952],
              [-36.155097300999955, -7.782510807999927],
              [-36.143370388999983, -7.781562187999953],
              [-36.14336992799997, -7.781562261999966],
              [-36.126819703999956, -7.784202342999947],
              [-36.118507737999948, -7.785796852999965],
              [-36.117277762999947, -7.785785334999957],
              [-36.117277355999931, -7.785785218999933],
              [-36.115058573999931, -7.785154629999965],
              [-36.113461540999936, -7.78409654099994],
              [-36.104073375999974, -7.779618367999944],
              [-36.104069908999975, -7.77961741699994],
              [-36.104017605999957, -7.779603068999961],
              [-36.103371246999984, -7.779425757999945],
              [-36.102913075999936, -7.779300071999955],
              [-36.100665209999931, -7.77868343199998],
              [-36.094328734999976, -7.778479253999933],
              [-36.094111106999947, -7.778472241999964],
              [-36.080931589999977, -7.779055911999933],
              [-36.071831670999984, -7.780221410999957],
              [-36.068992006999963, -7.780973591999953],
              [-36.064065073999984, -7.785077955999952],
              [-36.059374615999957, -7.792261977999942],
              [-36.056179088999954, -7.799159965999934],
              [-36.053872496999929, -7.801360965999947],
              [-36.050935160999984, -7.801150459999974],
              [-36.050497358999962, -7.801119083999936],
              [-36.04733179699997, -7.798385155999938],
              [-36.045442476999938, -7.795107362999943],
              [-36.043245592999938, -7.793662539999957],
              [-36.04324360499993, -7.793662582999957],
              [-36.041992113999981, -7.793689520999976],
              [-36.036164520999932, -7.796015420999936],
              [-36.035409897999955, -7.796192020999968],
              [-36.033639408999932, -7.79660454499998],
              [-36.030071928999973, -7.799512774999926],
              [-36.027624585999945, -7.80251459599998],
              [-36.025889101999951, -7.806225273999928],
              [-36.021587324999985, -7.810239122999974],
              [-36.018731963999983, -7.811274527999956],
              [-36.015025236999975, -7.811798945999954],
              [-36.009207197999956, -7.811758626999961],
              [-36.003290487999948, -7.812834395999971],
              [-35.998220596999943, -7.813756197999965],
              [-35.998219177999943, -7.813755649999962],
              [-35.99724178799994, -7.813378152999974],
              [-35.99622825199998, -7.809867163999968],
              [-35.997097505999932, -7.805950293999956],
              [-35.996184838999966, -7.803870712999981],
              [-35.99274896999998, -7.80046514999998],
              [-35.991039289999947, -7.799869398999931],
              [-35.99103757599994, -7.799869916999967],
              [-35.983246021999946, -7.802226200999939],
              [-35.981367378999948, -7.803193952999948],
              [-35.981365538999967, -7.803192886999966],
              [-35.979294290999974, -7.801992358999939],
              [-35.978116195999974, -7.800437130999967],
              [-35.97592492299998, -7.795197063999979],
              [-35.974016459999973, -7.791995338999925],
              [-35.969959599999981, -7.789460599999927],
              [-35.965827702999945, -7.789055674999929],
              [-35.961417925999967, -7.787998419999951],
              [-35.960615090999966, -7.787581910999961],
              [-35.959336547999953, -7.787325167999938],
              [-35.959026630999972, -7.787262933999955],
              [-35.954995347999954, -7.789385545999949],
              [-35.952830195999979, -7.789179088999958],
              [-35.952190433999931, -7.789118084999927],
              [-35.952190021999968, -7.789118242999962],
              [-35.951643936999972, -7.789327962999948],
              [-35.945358276999968, -7.789259866999942],
              [-35.943358733999958, -7.789986966999948],
              [-35.943354573999954, -7.789987871999926],
              [-35.942370061999952, -7.790202147999935],
              [-35.942368002999956, -7.790201426999943],
              [-35.93866247099993, -7.788904144999947],
              [-35.93724586999997, -7.78646565899993],
              [-35.935727782999948, -7.785194861999969],
              [-35.935610427999961, -7.785096622999959],
              [-35.93560705699997, -7.785095794999961],
              [-35.932881467999948, -7.784425913999939],
              [-35.92846618699997, -7.785176427999943],
              [-35.926615176999974, -7.785919459999946],
              [-35.926044010999931, -7.786148736999962],
              [-35.921517718999951, -7.787965679999957],
              [-35.914791266999941, -7.790083108999966],
              [-35.91478720899994, -7.790084920999959],
              [-35.909357775999979, -7.792509103999976],
              [-35.90747700299994, -7.792724924999959],
              [-35.907474940999975, -7.79272459799995],
              [-35.90602705699996, -7.792495162999955],
              [-35.905367053999953, -7.792390577999981],
              [-35.904414954999936, -7.792239706999965],
              [-35.898245520999978, -7.788917142999935],
              [-35.895466662999979, -7.78515833299997],
              [-35.894029892999981, -7.784796262999976],
              [-35.89349787499998, -7.783670001999951],
              [-35.891744428999971, -7.779958024999928],
              [-35.89044957599998, -7.778757753999969],
              [-35.890446444999952, -7.778754850999974],
              [-35.889513080999961, -7.777889663999929],
              [-35.883826134999936, -7.774779559999956],
              [-35.882903200999976, -7.77197627399994],
              [-35.88104448799993, -7.766341085999954],
              [-35.881018445999985, -7.766262131999952],
              [-35.881617571999982, -7.766133832999969],
              [-35.883072194999954, -7.764124024999944],
              [-35.883357449999949, -7.761840746999951],
              [-35.881996294999965, -7.756399327999929],
              [-35.881930925999939, -7.756138002999933],
              [-35.881939927999952, -7.755333766999968],
              [-35.881940832999931, -7.755252955999936],
              [-35.881829164999942, -7.754448426999943],
              [-35.88177406099993, -7.754391025999951],
              [-35.881060739999953, -7.75421599799995],
              [-35.880522446999976, -7.754189578999956],
              [-35.880060108999942, -7.754192881999927],
              [-35.879766193999956, -7.75422920799997],
              [-35.879696017999947, -7.754210218999958],
              [-35.87962584099995, -7.754191229999947],
              [-35.879368500999931, -7.753845954999974],
              [-35.879297250999969, -7.753750357999934],
              [-35.879137083999979, -7.753589365999972],
              [-35.878885020999974, -7.753437342999973],
              [-35.878292183999974, -7.753079795999952],
              [-35.878288363999957, -7.753077491999932],
              [-35.878128086999936, -7.752956604999952],
              [-35.878093521999972, -7.752930533999972],
              [-35.877632834999929, -7.752682852999953],
              [-35.877213428999937, -7.752441776999945],
              [-35.876826220999931, -7.752250236999942],
              [-35.876380394999956, -7.751966229999937],
              [-35.876230134999957, -7.751918344999979],
              [-35.875368205999962, -7.751812666999967],
              [-35.875217723999981, -7.751781567999956],
              [-35.875120524999943, -7.751761479999971],
              [-35.874992349999957, -7.751677887999961],
              [-35.87486407199998, -7.751534541999945],
              [-35.874859676999961, -7.751529880999954],
              [-35.874790404999942, -7.751456410999936],
              [-35.874646111999937, -7.751303373999974],
              [-35.874487697999939, -7.751091646999953],
              [-35.874474386999964, -7.751073855999948],
              [-35.874443227999961, -7.751053556999977],
              [-35.872118270999977, -7.749538910999945],
              [-35.87108593499994, -7.74943062899996],
              [-35.871085484999981, -7.749430871999948],
              [-35.869544549999944, -7.750266651999937],
              [-35.869540960999984, -7.750265957999943],
              [-35.867239559999973, -7.74982128399995],
              [-35.867237666999984, -7.749821746999942],
              [-35.862286087999962, -7.751034435999941],
              [-35.862283727999966, -7.751033720999942],
              [-35.850658022999937, -7.74750985999998],
              [-35.842368577999935, -7.747020019999979],
              [-35.841747396999949, -7.746983313999976],
              [-35.841746888999978, -7.746983366999928],
              [-35.835812740999984, -7.747608733999925],
              [-35.834607270999982, -7.748254673999952],
              [-35.83312862899993, -7.749898426999948],
              [-35.832383559999982, -7.750726693999979],
              [-35.83191120999993, -7.750912579999977],
              [-35.829792256999951, -7.75174646399995],
              [-35.829788901999962, -7.751746210999954],
              [-35.828499024999928, -7.751649039999961],
              [-35.825294836999944, -7.749566820999974],
              [-35.820509209999955, -7.748894578999966],
              [-35.820321116999935, -7.748776241999963],
              [-35.820218926999985, -7.748711949999972],
              [-35.817333086999952, -7.746896339999978],
              [-35.815853871999934, -7.746675831999937],
              [-35.813693243999978, -7.746352606999949],
              [-35.812790938999967, -7.745706409999968],
              [-35.810567925999976, -7.745316854999942],
              [-35.810567087999971, -7.745317317999934],
              [-35.808110518999968, -7.746672936999971],
              [-35.806873304999954, -7.746679794999977],
              [-35.806096571999944, -7.746684099999925],
              [-35.80417779499993, -7.745675008999967],
              [-35.800319913999942, -7.741824212999973],
              [-35.797486525999943, -7.740835730999947],
              [-35.796389320999936, -7.74045246999998],
              [-35.79531803499998, -7.74007826299993],
              [-35.794780077999974, -7.739890350999929],
              [-35.794779091999942, -7.739890530999958],
              [-35.791867522999951, -7.740420954999934],
              [-35.790950016999943, -7.74042806999995],
              [-35.79094981399993, -7.740428071999929],
              [-35.790949497999975, -7.740428073999965],
              [-35.789906850999955, -7.740436159999945],
              [-35.789216224999961, -7.740441515999976],
              [-35.789215816999956, -7.740441300999976],
              [-35.786570710999968, -7.739043490999961],
              [-35.780577511999979, -7.738351392999959],
              [-35.775326690999975, -7.73415990899997],
              [-35.774351205999949, -7.732853202999934],
              [-35.772942845999978, -7.732100345999982],
              [-35.767573775999949, -7.726708873999939],
              [-35.764850351999939, -7.724900236999929],
              [-35.758999528999937, -7.722413761999974],
              [-35.758998085999963, -7.722414012999934],
              [-35.751810733999946, -7.723664072999952],
              [-35.748215109999933, -7.723301642999957],
              [-35.747251883999979, -7.72320455199997],
              [-35.746454240999981, -7.721387333999928],
              [-35.745384384999966, -7.718953313999975],
              [-35.743105747999948, -7.713769206999928],
              [-35.74138120799995, -7.711922153999978],
              [-35.736549436999951, -7.709598993999975],
              [-35.732245561999946, -7.708466704999978],
              [-35.728219808999938, -7.70799889999995],
              [-35.728218772999981, -7.707999121999933],
              [-35.721401961999959, -7.709462315999929],
              [-35.721399830999928, -7.709461617999978],
              [-35.719159992999948, -7.708727473999943],
              [-35.716915356999948, -7.70858448499996],
              [-35.713620455999944, -7.707369969999945],
              [-35.711606642999982, -7.705214981999973],
              [-35.709385778999945, -7.698972718999926],
              [-35.708187670999962, -7.697347281999953],
              [-35.706770404999929, -7.695774133999976],
              [-35.700077019999981, -7.691856850999955],
              [-35.70007220399998, -7.691856991999941],
              [-35.697240908999959, -7.691939800999933],
              [-35.695354361999932, -7.693973955999979],
              [-35.695412147999946, -7.696966753999959],
              [-35.693717685999957, -7.699781457999961],
              [-35.689912127999946, -7.700670833999936],
              [-35.686483343999953, -7.70488605099996],
              [-35.685409504999939, -7.706206186999964],
              [-35.684113916999934, -7.707798933999925],
              [-35.683950960999937, -7.707735085999957],
              [-35.681855066999958, -7.706913887999974],
              [-35.681247422999945, -7.706226985999933],
              [-35.681148349999944, -7.705526873999929],
              [-35.681062487999952, -7.704962161999958],
              [-35.680933693999975, -7.704489916999933],
              [-35.680497774999935, -7.704139860999931],
              [-35.680443547999971, -7.704099987999939],
              [-35.680160928999953, -7.703892179999968],
              [-35.679888907999953, -7.70358129799996],
              [-35.679837292999935, -7.703522309999926],
              [-35.679655835999938, -7.703352076999977],
              [-35.679516957999965, -7.70322178999993],
              [-35.679351837999945, -7.703013737999981],
              [-35.677059962999977, -7.701814961999958],
              [-35.675098329999969, -7.700698746999933],
              [-35.674073432999933, -7.700268760999961],
              [-35.673949089999951, -7.700216593999926],
              [-35.672981482999944, -7.699754255999949],
              [-35.671632447999968, -7.699562715999946],
              [-35.670549255999958, -7.699361268999951],
              [-35.670132648999981, -7.69915436399998],
              [-35.669695049999973, -7.699147954999944],
              [-35.669152335999968, -7.699140007999972],
              [-35.66865697399993, -7.698945164999941],
              [-35.668251343999941, -7.698426336999944],
              [-35.668230961999939, -7.698400266999954],
              [-35.66768196299995, -7.697696115999975],
              [-35.667471406999937, -7.697426054999937],
              [-35.667311239999947, -7.697419450999973],
              [-35.667030947999933, -7.69736413499993],
              [-35.666258183999958, -7.697179199999937],
              [-35.66592959299993, -7.697065266999971],
              [-35.665900668999939, -7.697113757999944],
              [-35.665873451999971, -7.697159385999953],
              [-35.665653635999945, -7.697326020999981],
              [-35.665566327999954, -7.697392205999961],
              [-35.665526536999948, -7.69741313399993],
              [-35.66486621699994, -7.697760424999956],
              [-35.664644954999972, -7.697575489999963],
              [-35.664654861999963, -7.697393856999952],
              [-35.664182616999938, -7.697403763999944],
              [-35.66354029799993, -7.69729973799997],
              [-35.662992096999972, -7.697266713999966],
              [-35.662597458999983, -7.697152780999943],
              [-35.662343172999954, -7.697061963999943],
              [-35.662102096999945, -7.696999217999974],
              [-35.661966697999958, -7.696984357999952],
              [-35.661719016999939, -7.696908401999963],
              [-35.661104767999973, -7.696921611999926],
              [-35.660464099999956, -7.697027288999948],
              [-35.66031309899995, -7.697048020999944],
              [-35.65896661599993, -7.697232892999978],
              [-35.657577790999937, -7.697423578999974],
              [-35.65710224399993, -7.697600257999966],
              [-35.656798421999952, -7.697684468999967],
              [-35.655558733999953, -7.697048870999936],
              [-35.655117361999942, -7.697017969999933],
              [-35.65223378099995, -7.696371618999933],
              [-35.651074286999972, -7.694617173999973],
              [-35.64526712299994, -7.692409021999936],
              [-35.639407950999953, -7.686330706999968],
              [-35.636379818999956, -7.683855241999936],
              [-35.633547772999975, -7.682663360999925],
              [-35.631523594999976, -7.681237390999968],
              [-35.628465282999969, -7.677506586999925],
              [-35.628354595999951, -7.677371560999973],
              [-35.626107909999973, -7.67463085199995],
              [-35.625840336999943, -7.674182850999955],
              [-35.625244900999974, -7.673185906999947],
              [-35.622063122999975, -7.669487040999968],
              [-35.621311274999982, -7.66861300599993],
              [-35.621975715999952, -7.668590490999975],
              [-35.621983971999953, -7.66859021199997],
              [-35.622413285999983, -7.668431695999971],
              [-35.623474753999972, -7.667965870999979],
              [-35.62360545699994, -7.667929728999979],
              [-35.623960466999961, -7.66710412599997],
              [-35.623552618999952, -7.666830025999957],
              [-35.623904325999945, -7.666484922999928],
              [-35.624010002999967, -7.666393281999945],
              [-35.624101644999939, -7.66627687099998],
              [-35.624695073999931, -7.66592550799993],
              [-35.624769557999969, -7.665881407999962],
              [-35.624701624999943, -7.665811850999944],
              [-35.622421541999984, -7.663477250999961],
              [-35.621773015999963, -7.662726962999955],
              [-35.619694117999984, -7.66397029999996],
              [-35.619647455999939, -7.663998206999963],
              [-35.619507201999966, -7.664082089999965],
              [-35.619496955999978, -7.662513420999971],
              [-35.618045245999951, -7.661700028999974],
              [-35.616034729999967, -7.659393034999937],
              [-35.615828257999965, -7.65894321899998],
              [-35.614619274999939, -7.656309350999948],
              [-35.611870769999939, -7.654544295999926],
              [-35.609662149999963, -7.653779467999925],
              [-35.607868678999978, -7.653413630999978],
              [-35.606218747999947, -7.653654263999954],
              [-35.606218641999931, -7.653654278999966],
              [-35.60243008499998, -7.65435464899997],
              [-35.597488659999954, -7.654335594999964],
              [-35.593493133999971, -7.653586841999982],
              [-35.591965702999971, -7.653264540999942],
              [-35.590746913999965, -7.652408975999947],
              [-35.589323002999947, -7.651452068999959],
              [-35.588469387999965, -7.650727080999957],
              [-35.587685931999943, -7.650224265999952],
              [-35.586598448999951, -7.65027103999995],
              [-35.584213002999945, -7.651206508999962],
              [-35.58265778599997, -7.651264975999936],
              [-35.577208677999977, -7.654854837999949],
              [-35.573443414999929, -7.654714517999935],
              [-35.572484558999975, -7.657170123999947],
              [-35.571923276999939, -7.657965272999945],
              [-35.570327132999978, -7.658368693999932],
              [-35.568654981999941, -7.658134826999969],
              [-35.568555831999959, -7.658087481999928],
              [-35.568370032999951, -7.657998761999977],
              [-35.567810135999935, -7.657731405999925],
              [-35.567614272999947, -7.657272440999975],
              [-35.567134844999941, -7.657026880999979],
              [-35.566486525999949, -7.657107542999938],
              [-35.565255135999962, -7.657260747999942],
              [-35.565018345999931, -7.657500461999973],
              [-35.564878024999985, -7.657804488999943],
              [-35.564462910999964, -7.658219603999953],
              [-35.564092700999936, -7.659000918999936],
              [-35.56396009599996, -7.659280776999935],
              [-35.563247796999974, -7.659960542999954],
              [-35.56274398599993, -7.660441342999945],
              [-35.561761743999966, -7.660862303999977],
              [-35.559563390999983, -7.661330038999949],
              [-35.557471112999963, -7.659604232999925],
              [-35.555073138999944, -7.659318779999978],
              [-35.553692504999958, -7.660298203999957],
              [-35.552336891999971, -7.661259878999942],
              [-35.550302246999934, -7.661189717999946],
              [-35.547729706999974, -7.658547017999979],
              [-35.547899580999967, -7.657337503999941],
              [-35.534612055999958, -7.655484049999927],
              [-35.532811955999932, -7.654817570999967],
              [-35.532109122999941, -7.653854941999953],
              [-35.531533665999973, -7.649460308999949],
              [-35.529719689999979, -7.646476054999937],
              [-35.528088672999957, -7.645678185999941],
              [-35.52695033699996, -7.645121328999949],
              [-35.526938143999985, -7.652878916999953],
              [-35.525133022999967, -7.653975128999946],
              [-35.524720023999976, -7.658153720999962],
              [-35.523789762999968, -7.659261460999971],
              [-35.520793758999957, -7.66236362099994],
              [-35.520586438999942, -7.662067002999947],
              [-35.518047820999982, -7.658434945999943],
              [-35.515682097999957, -7.656944859999953],
              [-35.514655269999935, -7.656756626999936],
              [-35.514601669999934, -7.65826115699997],
              [-35.514265801999954, -7.659403108999925],
              [-35.513594065999939, -7.662291576999962],
              [-35.512653633999946, -7.665213630999972],
              [-35.512663198999974, -7.665392963999977],
              [-35.512787981999963, -7.667732642999965],
              [-35.5129, -7.67009],
              [-35.513306191999959, -7.671252212999946],
              [-35.514261479999959, -7.671597692999967],
              [-35.516922521999959, -7.671756411999979],
              [-35.517626151999934, -7.67192720099996],
              [-35.518055140999934, -7.672275267999964],
              [-35.518758808999962, -7.67254676899995],
              [-35.520090795999977, -7.672887856999978],
              [-35.5213, -7.67271],
              [-35.521755664999944, -7.672871427999951],
              [-35.522763269999984, -7.674013379999963],
              [-35.523770874999968, -7.674080553999943],
              [-35.524744892999934, -7.674450008999941],
              [-35.525853257999984, -7.675424026999963],
              [-35.526659341999959, -7.677405649999969],
              [-35.5281, -7.67878],
              [-35.528506617999938, -7.678917056999978],
              [-35.529346287999942, -7.679622379999955],
              [-35.529373143999976, -7.679891189999978],
              [-35.5294, -7.68016],
              [-35.5292, -7.68082],
              [-35.5285, -7.6814],
              [-35.52663679099993, -7.684257258999935],
              [-35.526635529999965, -7.684259191999956],
              [-35.5266, -7.68431],
              [-35.526584680999974, -7.684350976999951],
              [-35.526569362999965, -7.684391952999931],
              [-35.526453122999953, -7.684702896999966],
              [-35.526238004999982, -7.685278335999953],
              [-35.5254, -7.68752],
              [-35.5248, -7.68796],
              [-35.5243, -7.68889],
              [-35.52356935399996, -7.690135057999953],
              [-35.523233485999981, -7.690437338999971],
              [-35.5226, -7.69119],
              [-35.521078399999965, -7.692726633999939],
              [-35.520615735999968, -7.694268846999933],
              [-35.520521106999979, -7.694584275999944],
              [-35.520884984999952, -7.695686328999955],
              [-35.521141325999963, -7.695998592999956],
              [-35.523605447999955, -7.697900557999958],
              [-35.526314849999949, -7.70003324399994],
              [-35.528698098999939, -7.701063042999976],
              [-35.53049187299996, -7.701695117999975],
              [-35.532590593999942, -7.703625190999958],
              [-35.534286433999966, -7.705855081999971],
              [-35.535601935999978, -7.706536774999961],
              [-35.537993440999969, -7.706728948999967],
              [-35.539730360999954, -7.707665516999953],
              [-35.5399, -7.70863],
              [-35.5402, -7.70954],
              [-35.5406, -7.71132],
              [-35.5413, -7.71215],
              [-35.54146760499998, -7.712596789999964],
              [-35.5415, -7.71317],
              [-35.5411, -7.71382],
              [-35.5405, -7.7153],
              [-35.5408, -7.71559],
              [-35.5413, -7.7157],
              [-35.542045129999963, -7.716026962999933],
              [-35.544068211999956, -7.71558747499995],
              [-35.544977203999963, -7.716480518999958],
              [-35.548335943999973, -7.71684827699994],
              [-35.549369236999951, -7.720127721999972],
              [-35.549275325999929, -7.722179720999975],
              [-35.548638595999932, -7.722821628999952],
              [-35.546891186999972, -7.72302101799994],
              [-35.545018738999943, -7.722812360999967],
              [-35.544128239999964, -7.723427460999972],
              [-35.544131576999973, -7.725979274999929],
              [-35.543624532999957, -7.726500788999942],
              [-35.54325264299996, -7.726558673999932],
              [-35.54128004699993, -7.72603421599996],
              [-35.538712089999933, -7.728722996999977],
              [-35.541467161999947, -7.735031215999925],
              [-35.541414218999932, -7.735381136999933],
              [-35.53893936999998, -7.737044900999933],
              [-35.538858811999944, -7.737100983999937],
              [-35.538730443999953, -7.737187308999978],
              [-35.538654584999961, -7.737238321999939],
              [-35.529737459999978, -7.743234905999941],
              [-35.529713532999949, -7.744027605999975],
              [-35.533383814999979, -7.746828624999978],
              [-35.533598558999984, -7.747902353999962],
              [-35.533143189999976, -7.74842246299994],
              [-35.53199653799993, -7.747872430999962],
              [-35.52943176499997, -7.748043818999975],
              [-35.527053185999932, -7.748673941999925],
              [-35.526856574999954, -7.748838422999938],
              [-35.526659962999929, -7.749002904999941],
              [-35.526307392999968, -7.74897372199996],
              [-35.523401392999972, -7.744176181999933],
              [-35.52319487699998, -7.744147865999935],
              [-35.522582928999952, -7.746248801999968],
              [-35.520261428999959, -7.749153731999968],
              [-35.517831059999935, -7.749020693999967],
              [-35.517458766999937, -7.74917241299994],
              [-35.516471262999971, -7.749574844999927],
              [-35.514520660999949, -7.747959557999934],
              [-35.514093055999979, -7.747944974999939],
              [-35.511645002999956, -7.747861488999945],
              [-35.504907206999974, -7.743748729999936],
              [-35.504382647999932, -7.742946403999952],
              [-35.50445728699998, -7.742197215999965],
              [-35.502277177999929, -7.739012121999963],
              [-35.50094981899997, -7.737862537999945],
              [-35.500799791999953, -7.737754652999968],
              [-35.500749194999969, -7.737718267999981],
              [-35.496056280999937, -7.734343557999978],
              [-35.492527712999959, -7.732609208999975],
              [-35.490700384999968, -7.72991056099994],
              [-35.484358089999944, -7.729947616999937],
              [-35.483818606999932, -7.729428442999961],
              [-35.483808722999981, -7.729418930999941],
              [-35.483704809999949, -7.729318929999977],
              [-35.480564970999978, -7.72629729199997],
              [-35.48033423399994, -7.726075240999933],
              [-35.472527113999945, -7.718562025999972],
              [-35.453077358999963, -7.699844470999949],
              [-35.452700671999935, -7.699481964999961],
              [-35.44567262299995, -7.692718491999926],
              [-35.427728401999957, -7.713373928999943],
              [-35.426499639999975, -7.714502311999979],
              [-35.425993457999937, -7.714967142999967],
              [-35.425443786999949, -7.715471909999962],
              [-35.422520219999967, -7.718156644999965],
              [-35.419118071999947, -7.721279301999971],
              [-35.42779341499994, -7.719628028999978],
              [-35.436244657999964, -7.718018776999941],
              [-35.451338878999934, -7.715144595999959],
              [-35.453317780999953, -7.714767780999978],
              [-35.457266432999972, -7.714015893999942],
              [-35.457307195999931, -7.715687799999955],
              [-35.457902538999974, -7.716731178999964],
              [-35.45925082499997, -7.718119699999932],
              [-35.463616779999938, -7.720476867999935],
              [-35.465314684999953, -7.722301524999978],
              [-35.468086674999938, -7.725599031999934],
              [-35.470140107999953, -7.729055427999981],
              [-35.471410225999932, -7.730406444999971],
              [-35.473369261999949, -7.734485544999927],
              [-35.475016667999967, -7.735719666999955],
              [-35.476584518999971, -7.737690627999939],
              [-35.477175061999958, -7.738176299999964],
              [-35.477866270999982, -7.738924312999927],
              [-35.479093410999951, -7.740523232999976],
              [-35.481011515999967, -7.742092200999934],
              [-35.481675200999973, -7.743210481999938],
              [-35.481939869999962, -7.74365643699997],
              [-35.481750606999981, -7.747763230999965],
              [-35.482022628999971, -7.750115366999978],
              [-35.481807759999981, -7.750592729999937],
              [-35.481559721999929, -7.752224531999957],
              [-35.482108454999945, -7.754676771999925],
              [-35.482086154999934, -7.755329922999977],
              [-35.48208217399997, -7.755457990999957],
              [-35.481255863999934, -7.756352398999979],
              [-35.480944249999936, -7.757362774999933],
              [-35.481147360999955, -7.758132062999948],
              [-35.481321476999938, -7.758791531999975],
              [-35.482413028999929, -7.760937518999981],
              [-35.482437022999932, -7.761750083999971],
              [-35.481751484999961, -7.763001435999968],
              [-35.481744259999971, -7.763481365999951],
              [-35.481934097999954, -7.763797764999936],
              [-35.482123936999983, -7.764114164999967],
              [-35.482050934999961, -7.765244585999937],
              [-35.481542286999968, -7.766176217999941],
              [-35.481527339999957, -7.767046550999964],
              [-35.481653435999931, -7.767667985999935],
              [-35.482125193999934, -7.768271047999974],
              [-35.48211277799993, -7.769025303999967],
              [-35.482295491999935, -7.769638761999943],
              [-35.484049863999928, -7.770993849999968],
              [-35.484569486999931, -7.771721981999974],
              [-35.484420242999931, -7.775152329999969],
              [-35.485185178999984, -7.777113629999974],
              [-35.484900532999973, -7.778386759999933],
              [-35.484856240999932, -7.77858286999998],
              [-35.48439194599996, -7.779195310999967],
              [-35.483952718999944, -7.779775223999934],
              [-35.482938880999939, -7.781306513999937],
              [-35.482182154999975, -7.782185952999953],
              [-35.482141127999967, -7.782215592999933],
              [-35.480932879999955, -7.783088482999972],
              [-35.47969136599994, -7.783632359999956],
              [-35.477886590999958, -7.78394794899998],
              [-35.476356941999938, -7.784531431999937],
              [-35.476402348999954, -7.785391327999946],
              [-35.476411586999973, -7.78556784999995],
              [-35.476450915999976, -7.786319361999972],
              [-35.476816505999977, -7.79325369999998],
              [-35.477054519999967, -7.797768240999972],
              [-35.493425382999931, -7.804188440999951],
              [-35.493683942999951, -7.804289839999967],
              [-35.502031322999983, -7.807563417999972],
              [-35.502937423999981, -7.813256169999931],
              [-35.50759663499997, -7.842525087999945],
              [-35.507619878999947, -7.842671290999931],
              [-35.508734832999949, -7.849684243999945],
              [-35.508737709999934, -7.849702339999965],
              [-35.508823852999967, -7.850244169999939],
              [-35.508870514999956, -7.850533620999954],
              [-35.510507571999938, -7.860831693999955],
              [-35.513696308999954, -7.863524401999939],
              [-35.51828194199993, -7.872534774999963],
              [-35.527864837999971, -7.878687157999934],
              [-35.527937884999972, -7.878734055999928],
              [-35.528743790999954, -7.879251666999949],
              [-35.525066079999931, -7.882785344999945],
              [-35.522898746999942, -7.893469706999952],
              [-35.522870818999934, -7.893608763999964],
              [-35.520719305999933, -7.904321222999954],
              [-35.519926648999956, -7.908267562999981],
              [-35.519882661999929, -7.908492681999974],
              [-35.517603892999944, -7.920155235999971],
              [-35.51563445599993, -7.930233485999963],
              [-35.514002739999967, -7.938583506999976],
              [-35.513612289999969, -7.938192536999964],
              [-35.511869007999962, -7.935390022999968],
              [-35.510875616999954, -7.932508196999947],
              [-35.509541657999932, -7.930296359999943],
              [-35.507531399999948, -7.926963630999978],
              [-35.506819493999956, -7.92620102799998],
              [-35.504405628999962, -7.925001585999951],
              [-35.503232560999948, -7.92370822099997],
              [-35.499985376999973, -7.91963827099994],
              [-35.499979163999967, -7.919642714999952],
              [-35.499051247999944, -7.920306385999936],
              [-35.497766479999939, -7.921225806999928],
              [-35.497690854999973, -7.921279926999944],
              [-35.497615228999962, -7.921334046999959],
              [-35.471802410999942, -7.939806518999944],
              [-35.47133192299998, -7.940143029999945],
              [-35.442045828999937, -7.961089525999967],
              [-35.442582086999948, -7.962343748999956],
              [-35.443340585999977, -7.96254984899997],
              [-35.444189199999983, -7.963511026999981],
              [-35.444495908999954, -7.965482650999945],
              [-35.444248498999968, -7.966115798999965],
              [-35.445133324999972, -7.966572542999927],
              [-35.445350775999941, -7.967789993999929],
              [-35.446113879999984, -7.968540171999962],
              [-35.446475973999952, -7.969147641999939],
              [-35.447785842999963, -7.969854846999965],
              [-35.448083921999967, -7.97060847299997],
              [-35.449055142999953, -7.971023308999975],
              [-35.449115784999947, -7.971518456999945],
              [-35.449230062999959, -7.972451536999927],
              [-35.450039200999981, -7.973538462999954],
              [-35.450172185999975, -7.974502792999942],
              [-35.451338990999943, -7.975065392999966],
              [-35.45215417199995, -7.975458114999981],
              [-35.452413687999979, -7.976337486999967],
              [-35.452860805999933, -7.976776495999957],
              [-35.452905219999934, -7.977576140999929],
              [-35.453163439999969, -7.978288513999928],
              [-35.452745954999955, -7.979004793999934],
              [-35.452034076999951, -7.979764461999935],
              [-35.452121903999966, -7.980353394999952],
              [-35.453259320999962, -7.980514610999933],
              [-35.454404181999962, -7.981901043999926],
              [-35.454691929999967, -7.982249507999938],
              [-35.455716911999957, -7.982893891999936],
              [-35.456394688999978, -7.98360437499997],
              [-35.455998271999931, -7.984257875999958],
              [-35.456380715999956, -7.984759555999972],
              [-35.456298229999959, -7.985223340999937],
              [-35.456005495999932, -7.985308965999934],
              [-35.456386470999973, -7.985725387999935],
              [-35.455801735999955, -7.986444863999964],
              [-35.456610478999949, -7.987908803999971],
              [-35.457290390999958, -7.988787215999935],
              [-35.457237925999948, -7.988957958999947],
              [-35.456702827999948, -7.98943083599994],
              [-35.456438077999962, -7.990297348999945],
              [-35.456086329999948, -7.990460483999925],
              [-35.45595463899997, -7.991083097999933],
              [-35.456027095999957, -7.991825440999946],
              [-35.455705944999977, -7.992557869999928],
              [-35.455416710999941, -7.99396722399996],
              [-35.455718529999956, -7.994503348999956],
              [-35.456776579999939, -7.994983979999972],
              [-35.456943162999949, -7.995577159999925],
              [-35.457160940999984, -7.995657887999926],
              [-35.457431209999982, -7.995548081999971],
              [-35.457839510999975, -7.995923523999977],
              [-35.458208431999935, -7.996636364999972],
              [-35.459050966999939, -7.996793683999954],
              [-35.459244852999973, -7.997521432999974],
              [-35.459627995999938, -7.99814366399994],
              [-35.460255593999932, -7.999111116999927],
              [-35.460937337999951, -7.999755572999959],
              [-35.461050514999954, -8.000403289999952],
              [-35.461348051999948, -8.000428906999957],
              [-35.461564475999978, -8.000616298999944],
              [-35.461231433999956, -8.001388682999959],
              [-35.461215357999947, -8.003227144999926],
              [-35.460738768999931, -8.005068304999952],
              [-35.460416967999947, -8.005502934999981],
              [-35.460052527999949, -8.007721524999965],
              [-35.460200637999947, -8.007893955999975],
              [-35.460634432999939, -8.007918525999969],
              [-35.461099947999969, -8.008700938999937],
              [-35.462104679999982, -8.00923445199993],
              [-35.461983587999953, -8.011452277999979],
              [-35.46182362199994, -8.011940297999956],
              [-35.460513931999969, -8.012664005999966],
              [-35.460057347999964, -8.013261540999963],
              [-35.460496512999953, -8.01425713499998],
              [-35.46150790799993, -8.015791872999955],
              [-35.462376445999951, -8.015976204999959],
              [-35.462922216999971, -8.01645999699997],
              [-35.463477133999959, -8.016496651999944],
              [-35.463667551999947, -8.016715655999974],
              [-35.463868056999956, -8.018657231999953],
              [-35.463711438999951, -8.019441455999981],
              [-35.463937168999962, -8.021386699999937],
              [-35.464160671999934, -8.021655321999958],
              [-35.465528515999949, -8.021823185999949],
              [-35.46566302399998, -8.021956320999948],
              [-35.465671352999948, -8.02303765299996],
              [-35.466158657999983, -8.024284086999955],
              [-35.466195854999967, -8.024710841999934],
              [-35.465471211999954, -8.025795209999956],
              [-35.464404644999945, -8.026463859999978],
              [-35.464434337999933, -8.027166557999976],
              [-35.463735175999943, -8.027900853999938],
              [-35.46365586099995, -8.028197819999946],
              [-35.463901309999983, -8.028385629999946],
              [-35.46444343099995, -8.028463080999927],
              [-35.465145185999972, -8.028080037999928],
              [-35.466116082999974, -8.027263564999942],
              [-35.466360186999964, -8.027344595999978],
              [-35.466512573999978, -8.02759985299997],
              [-35.466484935999972, -8.028034160999937],
              [-35.466031162999968, -8.028899470999932],
              [-35.46622107099995, -8.029032402999974],
              [-35.466708279999978, -8.028867377999973],
              [-35.466710747999969, -8.029221212999971],
              [-35.465614592999941, -8.031139111999948],
              [-35.439836903999947, -8.039496979999967],
              [-35.43905673699993, -8.039750022999954],
              [-35.438848945999951, -8.040639687999942],
              [-35.429421399999967, -8.081004062999966],
              [-35.429633581999951, -8.081222332999971],
              [-35.429340668999941, -8.081347360999928],
              [-35.396042577999935, -8.095450688999961],
              [-35.387472424999942, -8.099080556999979],
              [-35.377006390999952, -8.103513419999956],
              [-35.365198859999964, -8.108514471999968],
              [-35.362431150999953, -8.109686728999975],
              [-35.355528478999929, -8.112610338999957],
              [-35.343338161999952, -8.117773518999968],
              [-35.335483789999955, -8.136977631999969],
              [-35.317927832999942, -8.179902331999926],
              [-35.317841444999942, -8.180113552999956],
              [-35.321815203999961, -8.198927652999942],
              [-35.338545092999937, -8.278136727999936],
              [-35.364951360999953, -8.297939222999958],
              [-35.366408953999951, -8.299001997999937],
              [-35.366953407999972, -8.298701869999945],
              [-35.369077477999951, -8.299347783999963],
              [-35.370624172999953, -8.298416850999956],
              [-35.371014210999931, -8.296302486999934],
              [-35.371128215999931, -8.295684472999937],
              [-35.372179446999951, -8.294272058999979],
              [-35.37282705399997, -8.292507885999953],
              [-35.373190131999934, -8.290129687999979],
              [-35.374028474999932, -8.288496990999931],
              [-35.373376070999939, -8.285771721999936],
              [-35.372426495999946, -8.284501877999958],
              [-35.370819779999977, -8.283719937999933],
              [-35.369312548999972, -8.283507650999979],
              [-35.368783177999944, -8.281707601999926],
              [-35.367580475999944, -8.281362586999933],
              [-35.36535738799995, -8.279746362999958],
              [-35.36530182499996, -8.278517109999939],
              [-35.366191108999942, -8.277235996999934],
              [-35.367330641999956, -8.274807993999957],
              [-35.368320825999945, -8.274888891999979],
              [-35.373113988999933, -8.277546463999954],
              [-35.37494378699995, -8.276171038999962],
              [-35.375520113999983, -8.275064375999932],
              [-35.376759926999966, -8.272683679999943],
              [-35.376531968999984, -8.270175991999963],
              [-35.377231481999956, -8.268851926999957],
              [-35.380282722999937, -8.267470233999973],
              [-35.382122855999967, -8.267768514999943],
              [-35.384304591999978, -8.266346529999964],
              [-35.384436093999966, -8.264629869999965],
              [-35.387566531999937, -8.26069614499994],
              [-35.389710588999947, -8.261209974999929],
              [-35.395546238999941, -8.260118853999927],
              [-35.399346173999959, -8.257720965999965],
              [-35.402076174999934, -8.257000052999956],
              [-35.402753622999967, -8.256820885999957],
              [-35.40460193499996, -8.258525365999958],
              [-35.404763221999929, -8.261647387999972],
              [-35.406562035999968, -8.263397925999925],
              [-35.408142888999976, -8.263871359999939],
              [-35.409237878999932, -8.265669991999971],
              [-35.410793287999979, -8.266012381999928],
              [-35.414501133999977, -8.268233753999937],
              [-35.419865472999959, -8.266969216999939],
              [-35.421721972999933, -8.266451866999944],
              [-35.423578473999953, -8.265934516999948],
              [-35.425042083999983, -8.26271268499994],
              [-35.424551604999976, -8.259461354999928],
              [-35.425048612999944, -8.256025565999948],
              [-35.426124079999965, -8.254699555999935],
              [-35.429011042999946, -8.25336133199994],
              [-35.430902900999968, -8.254449914999952],
              [-35.434215891999941, -8.257248366999931],
              [-35.435439161999966, -8.256886646999931],
              [-35.438229773999979, -8.25528589299995],
              [-35.439390818999982, -8.252639030999944],
              [-35.442494527999941, -8.250254144999928],
              [-35.445227578999948, -8.250237690999938],
              [-35.447202188999938, -8.251100109999982],
              [-35.449784465999983, -8.253208867999945],
              [-35.450086240999951, -8.25320666999994],
              [-35.452157130999979, -8.253205660999981],
              [-35.453580197999941, -8.253524827999968],
              [-35.454621594999935, -8.254008719999945],
              [-35.454853542999956, -8.256075954999972],
              [-35.455847995999932, -8.257707074999928],
              [-35.45782662299996, -8.259328749999952],
              [-35.45837940499996, -8.260417089999976],
              [-35.45933191499995, -8.264171279999971],
              [-35.460655259999953, -8.265689343999952],
              [-35.462073046999933, -8.265135449999946],
              [-35.464296950999938, -8.262234041999932],
              [-35.466162519999955, -8.261290978999966],
              [-35.466508543999964, -8.261121337999953],
              [-35.467130519999955, -8.261676377999947],
              [-35.467941942999971, -8.262824217999935],
              [-35.468009919999929, -8.263693130999968],
              [-35.467332081999984, -8.264219304999926],
              [-35.467148382999937, -8.264974692999942],
              [-35.467591414999958, -8.265960843999949],
              [-35.469837007999956, -8.267741752999939],
              [-35.470213198999943, -8.268437479999932],
              [-35.470773253999937, -8.269013921999942],
              [-35.470656700999939, -8.269827372999941],
              [-35.47146924599997, -8.270519106999927],
              [-35.472774926999932, -8.270915824999975],
              [-35.474080029999982, -8.271080107999978],
              [-35.475267219999978, -8.272292794999942],
              [-35.476078077999944, -8.272869126999979],
              [-35.476949360999981, -8.273210547999952],
              [-35.477949653999929, -8.274248020999948],
              [-35.478514735999966, -8.275058854999941],
              [-35.480017648999933, -8.275229293999928],
              [-35.48131264999995, -8.275678074999973],
              [-35.483434378999959, -8.277521699999966],
              [-35.484311699999978, -8.278734567999948],
              [-35.485497305999957, -8.279773382999963],
              [-35.486001149999936, -8.280986148999943],
              [-35.486764701999959, -8.283592896999949],
              [-35.487069683999948, -8.284099267999977],
              [-35.487704271999974, -8.285152895999943],
              [-35.488021370999945, -8.285961455999939],
              [-35.487912440999935, -8.288175059999958],
              [-35.486989449999953, -8.290215836999948],
              [-35.487183621999975, -8.291198032999944],
              [-35.488929237999969, -8.292231542999957],
              [-35.489132796999968, -8.293562867999981],
              [-35.489671907999934, -8.294908495999948],
              [-35.491025901999933, -8.296251153999947],
              [-35.492114897999954, -8.297109150999972],
              [-35.493137637999951, -8.298129223999979],
              [-35.494049233999931, -8.299406174999945],
              [-35.494130017999964, -8.299614864999967],
              [-35.494532818999971, -8.300655433999964],
              [-35.494935804999955, -8.301501705999954],
              [-35.496076761999973, -8.302713971999935],
              [-35.496225362999951, -8.302871859999925],
              [-35.499408211999935, -8.307893613999966],
              [-35.500691639999957, -8.308556028999931],
              [-35.503424099999961, -8.309508249999965],
              [-35.506570568999962, -8.310294866999925],
              [-35.50795543299995, -8.310732725999969],
              [-35.509340297999984, -8.311170585999946],
              [-35.509513994999963, -8.306642306999947],
              [-35.509594021999931, -8.304556006999974],
              [-35.509690564999971, -8.302039126999944],
              [-35.509748418999948, -8.300530867999953],
              [-35.510817651999957, -8.300478723999959],
              [-35.52229652799997, -8.299965550999957],
              [-35.525001104999944, -8.297237524999957],
              [-35.52551379099998, -8.297234312999933],
              [-35.527722732999962, -8.297219825999946],
              [-35.52864780699997, -8.299927262999972],
              [-35.529257980999944, -8.30112175599993],
              [-35.529566813999963, -8.301726058999975],
              [-35.528746002999981, -8.315286527999945],
              [-35.548263762999966, -8.325656307999964],
              [-35.550596447999965, -8.326895662999959],
              [-35.557872428999929, -8.329559733999929],
              [-35.562923517999934, -8.339015132999975],
              [-35.562947217999977, -8.34263066099993],
              [-35.55932147599998, -8.343105689999959],
              [-35.553417817999957, -8.342238835999979],
              [-35.547954645999937, -8.339112890999957],
              [-35.549855531999981, -8.350408363999975],
              [-35.549342794999973, -8.351645489999953],
              [-35.580627065999977, -8.360644542999978],
              [-35.580783032999932, -8.360689407999928],
              [-35.584213998999928, -8.361676339999974],
              [-35.628033492999975, -8.374282122999944],
              [-35.654371117999972, -8.377721841999971],
              [-35.696509575999983, -8.370206654999947],
              [-35.699915124999961, -8.372882046999962],
              [-35.719318055999963, -8.388121497999975],
              [-35.723354739999934, -8.386672431999955],
              [-35.754304065999975, -8.384639232999973],
              [-35.754576052999937, -8.38462136499993],
              [-35.755038508999974, -8.384590984999932],
              [-35.751630285999966, -8.402806018999968],
              [-35.753014100999962, -8.405958520999945],
              [-35.75438524599997, -8.407304454999974],
              [-35.758027823999953, -8.409086127999956],
              [-35.758044016999975, -8.411345529999949],
              [-35.757187396999939, -8.412668046999954],
              [-35.743650343999946, -8.43358066199994],
              [-35.741887274999954, -8.436304082999925],
              [-35.75553053799996, -8.440727356999957],
              [-35.760975942999949, -8.441139555999939],
              [-35.763693371999977, -8.440217736999955],
              [-35.764167499999928, -8.442924300999948],
              [-35.762821096999971, -8.445191473999955],
              [-35.764227080999945, -8.45150863899994],
              [-35.765995826999983, -8.452545856999961],
              [-35.766505882999979, -8.452844385999981],
              [-35.772408539999958, -8.453709906999961],
              [-35.77467237999997, -8.452789554999981],
              [-35.781061574999967, -8.458164394999926],
              [-35.785181727999941, -8.463105923999933],
              [-35.788364585999943, -8.463986190999947],
              [-35.791076127999929, -8.462609696999948],
              [-35.79455097999994, -8.463449435999962],
              [-35.796455338999976, -8.464353987999971],
              [-35.797445301999971, -8.464823896999974],
              [-35.803346661999967, -8.465233251999962],
              [-35.808357070999932, -8.467908908999959],
              [-35.810165309999945, -8.466992279999943],
              [-35.813325273999965, -8.464710970999931],
              [-35.815597044999947, -8.46514545399998],
              [-35.819216955999934, -8.463764031999972],
              [-35.821912309999959, -8.460130374999949],
              [-35.824183991999973, -8.460566496999945],
              [-35.826012596999931, -8.462360259999969],
              [-35.827390108999964, -8.464607526999941],
              [-35.828783469999962, -8.469115082999963],
              [-35.832423799999958, -8.470444222999959],
              [-35.833419112999934, -8.47200453399995],
              [-35.837013364999962, -8.477638828999943],
              [-35.837524685999938, -8.479814094999938],
              [-35.837980965999975, -8.481755206999935],
              [-35.835854472999983, -8.484562150999977],
              [-35.83476858299997, -8.485298772999954],
              [-35.833953164999969, -8.485483349999981],
              [-35.832557875999953, -8.486487371999942],
              [-35.830633862999946, -8.48654679699996],
              [-35.82950806599996, -8.487411830999974],
              [-35.828243694999969, -8.488097344999971],
              [-35.826707044999978, -8.48838006699998],
              [-35.826035394999963, -8.48942220899994],
              [-35.823891325999966, -8.490566193999939],
              [-35.823875069999929, -8.490806947999943],
              [-35.823769353999978, -8.49232613199996],
              [-35.822366700999964, -8.493012327999963],
              [-35.820543770999961, -8.494244687999981],
              [-35.820098329999951, -8.495195932999934],
              [-35.819477285999938, -8.497550241999932],
              [-35.817865972999982, -8.49958879999997],
              [-35.817218559999958, -8.500821652999946],
              [-35.818595664999975, -8.503069137999944],
              [-35.819536933999984, -8.507580014999974],
              [-35.82182506099997, -8.510275228999944],
              [-35.82093098799993, -8.51208956399995],
              [-35.821851552999931, -8.513889920999929],
              [-35.822350712999935, -8.520207820999929],
              [-35.825188469999944, -8.525019767999936],
              [-35.825701362999951, -8.523798649999947],
              [-35.825822655999957, -8.524374383999941],
              [-35.82603269599997, -8.524731451999969],
              [-35.82626546299997, -8.524949060999973],
              [-35.826410145999944, -8.525419966999948],
              [-35.826391384999965, -8.526313562999974],
              [-35.826431771999978, -8.528260125999964],
              [-35.826350305999938, -8.528650001999949],
              [-35.826137715999948, -8.529667394999933],
              [-35.826316131999931, -8.530262113999981],
              [-35.826767836999977, -8.53078060699994],
              [-35.827650004999953, -8.530927635999944],
              [-35.827914100999976, -8.530615799999964],
              [-35.828178196999943, -8.530303963999927],
              [-35.830043490999969, -8.529273817999979],
              [-35.831933860999982, -8.528772590999949],
              [-35.83709616799996, -8.525471104999951],
              [-35.838331170999936, -8.525504629999944],
              [-35.839976332999981, -8.526114481999969],
              [-35.842846470999973, -8.528845550999961],
              [-35.844606051999961, -8.530949240999973],
              [-35.845630140999958, -8.534037058999957],
              [-35.844946600999947, -8.535893751999936],
              [-35.84348401699998, -8.536981642999933],
              [-35.842071433999934, -8.539576828999941],
              [-35.842090958999961, -8.541428652999969],
              [-35.84271735699997, -8.541488345999937],
              [-35.845106814999951, -8.54171605099998],
              [-35.847986597999977, -8.541531584999973],
              [-35.849168316999965, -8.541260909999949],
              [-35.85332451599993, -8.535793283999965],
              [-35.854849965999961, -8.537887521999949],
              [-35.855616085999941, -8.538939547999973],
              [-35.85299198599995, -8.545133775999943],
              [-35.852646869999944, -8.545947947999935],
              [-35.845465727999965, -8.562889134999978],
              [-35.845328938999955, -8.56321173799995],
              [-35.845211356999982, -8.563489043999937],
              [-35.838708302999976, -8.578825833999929],
              [-35.840543691999983, -8.57941535599997],
              [-35.841949844999931, -8.57979340199995],
              [-35.844244438999965, -8.580410306999966],
              [-35.845207234999975, -8.58108316299996],
              [-35.846171521999963, -8.58189237199997],
              [-35.84699956999998, -8.582975802999954],
              [-35.848598806999973, -8.586503841999956],
              [-35.848460467999985, -8.587801819999981],
              [-35.849942939999949, -8.593142847999957],
              [-35.850066839999954, -8.594085588999974],
              [-35.850066839999954, -8.594999486999939],
              [-35.850364850999938, -8.595873650999977],
              [-35.850464187999933, -8.596827283999971],
              [-35.85019317299998, -8.597210442999938],
              [-35.849888033999946, -8.597641845999931],
              [-35.849848298999973, -8.598198131999936],
              [-35.849895273999948, -8.59832898999997],
              [-35.850404584999978, -8.599747785999966],
              [-35.850444319999951, -8.600701418999961],
              [-35.850679831999969, -8.601485235999974],
              [-35.851405606999947, -8.602816047999966],
              [-35.85180343199994, -8.604429657999958],
              [-35.851865808999946, -8.604682663999938],
              [-35.853020426999933, -8.606414983999969],
              [-35.853587054999934, -8.607518634999963],
              [-35.853861973999983, -8.608585087999927],
              [-35.85431811899997, -8.609739057999946],
              [-35.854941593999968, -8.610191509999936],
              [-35.855687117999935, -8.611065227999973],
              [-35.855517794999969, -8.612490385999934],
              [-35.854991269999971, -8.613651895999965],
              [-35.852233201999979, -8.615542732999927],
              [-35.850815783999963, -8.617155759999946],
              [-35.849570974999949, -8.618145129999959],
              [-35.848681179999971, -8.618685553999967],
              [-35.847526616999971, -8.619762633999926],
              [-35.846276866999972, -8.620040125999935],
              [-35.84474722799996, -8.621910451999952],
              [-35.844637199999966, -8.622044985999935],
              [-35.843739089999929, -8.623121152999943],
              [-35.843399265999949, -8.623530091999953],
              [-35.84246798199996, -8.624697850999951],
              [-35.841475326999955, -8.62523667499994],
              [-35.84133187599997, -8.625316577999968],
              [-35.840671105999945, -8.625684631999945],
              [-35.840330862999963, -8.625870255999928],
              [-35.839838928999939, -8.625963531999957],
              [-35.83952192199996, -8.626088915999958],
              [-35.839288152999984, -8.626723431999949],
              [-35.839132306999943, -8.627291156999945],
              [-35.839176833999943, -8.627892277999933],
              [-35.839165701999946, -8.628348683999945],
              [-35.839054383999951, -8.62870490399996],
              [-35.838764954999931, -8.628983200999926],
              [-35.838152702999935, -8.628983200999926],
              [-35.837732133999964, -8.628843718999974],
              [-35.837377605999961, -8.629028006999931],
              [-35.836444633999974, -8.629831706999937],
              [-35.836121119999973, -8.629975490999925],
              [-35.835833552999929, -8.630263058999958],
              [-35.836624362999942, -8.631197652999958],
              [-35.836453856999981, -8.631363305999969],
              [-35.836118477999946, -8.631460336999965],
              [-35.835533163999969, -8.63141365599995],
              [-35.835206988999971, -8.631434608999939],
              [-35.834786272999963, -8.63141365599995],
              [-35.834435156999973, -8.631434524999975],
              [-35.834087831999966, -8.63144747299998],
              [-35.833888287999969, -8.631417910999971],
              [-35.833740507999948, -8.631396016999929],
              [-35.833631164999929, -8.631344561999981],
              [-35.833521821999966, -8.63129310599993],
              [-35.833437468999932, -8.631081574999939],
              [-35.83316141499995, -8.63104790999995],
              [-35.83300655599993, -8.630953647999945],
              [-35.832750700999952, -8.630704525999931],
              [-35.832108846999972, -8.630542932999958],
              [-35.831698132999975, -8.630300544999955],
              [-35.831381680999982, -8.630105286999935],
              [-35.831415345999972, -8.629761902999974],
              [-35.831260486999952, -8.629418518999955],
              [-35.831058495999969, -8.629270391999967],
              [-35.83074877699994, -8.628953939999974],
              [-35.830553518999977, -8.628529759999935],
              [-35.830425591999983, -8.628206574999979],
              [-35.830338062999942, -8.62782279299995],
              [-35.83014280499998, -8.627519806999942],
              [-35.830001411999945, -8.627257218999944],
              [-35.82922263599994, -8.62653200099993],
              [-35.828890341999966, -8.626222559999974],
              [-35.828452141999946, -8.627562058999956],
              [-35.826937442999963, -8.628285430999938],
              [-35.825891902999956, -8.628441764999934],
              [-35.825687564999953, -8.628472211999963],
              [-35.824710909999965, -8.629350951999982],
              [-35.823114293999936, -8.631141764999938],
              [-35.822761358999969, -8.631767492999927],
              [-35.822410806999983, -8.632749523999962],
              [-35.82170257599995, -8.633643802999927],
              [-35.820349865999958, -8.634075678999977],
              [-35.82027564599997, -8.634099374999948],
              [-35.819834707999973, -8.634903948999977],
              [-35.819842833999928, -8.635971169999948],
              [-35.817617624999968, -8.637411752999981],
              [-35.81717892599994, -8.638482903999943],
              [-35.816924397999969, -8.640353238999978],
              [-35.816123780999931, -8.640981097999941],
              [-35.816222933999939, -8.642137802999969],
              [-35.817572509999934, -8.64337351699993],
              [-35.817322287999957, -8.645688207999967],
              [-35.816972972999963, -8.646936739999944],
              [-35.816527354999948, -8.647118377999959],
              [-35.815364586999976, -8.64721579899998],
              [-35.814116681999963, -8.647581026999944],
              [-35.813671143999954, -8.648225459999935],
              [-35.813583623999932, -8.648352049999971],
              [-35.813498391999929, -8.64847496799996],
              [-35.812339012999985, -8.649017535999974],
              [-35.811268542999983, -8.649381287999972],
              [-35.809129691999942, -8.650286523999966],
              [-35.80796567799996, -8.650207051999928],
              [-35.807274209999946, -8.649861807999969],
              [-35.810159271999964, -8.672943243999953],
              [-35.813902146999965, -8.675124671999981],
              [-35.815184029999955, -8.676093762999926],
              [-35.821687267999948, -8.681010147999928],
              [-35.819477366999934, -8.681351153999969],
              [-35.819022421999932, -8.683767750999948],
              [-35.818428497999946, -8.687127658999941],
              [-35.81808087099995, -8.688445222999974],
              [-35.817968846999975, -8.688867910999932],
              [-35.817998222999961, -8.68905127499994],
              [-35.818165239999985, -8.690093782999952],
              [-35.820452776999957, -8.692786988999956],
              [-35.821381531999975, -8.695490862999975],
              [-35.819116651999934, -8.695957882999949],
              [-35.818232848999969, -8.699579702999927],
              [-35.82008041399996, -8.703632097999957],
              [-35.821031321999953, -8.708988323999961],
              [-35.823753142999976, -8.710268980999956],
              [-35.827277468999966, -8.711274862999971],
              [-35.829356160999964, -8.713046340999938],
              [-35.829762309999978, -8.713093316999959],
              [-35.830422532999933, -8.712734406999971],
              [-35.831281044999969, -8.712576875999957],
              [-35.831791542999952, -8.712775234999981],
              [-35.832317764999971, -8.71347889499998],
              [-35.832903711999961, -8.71387907899998],
              [-35.83328497399998, -8.71395079499996],
              [-35.833762100999934, -8.713468813999953],
              [-35.834397663999937, -8.713312328999962],
              [-35.83524956499997, -8.714054274999967],
              [-35.835597391999954, -8.714357207999967],
              [-35.837406202999944, -8.71344251499994],
              [-35.839229743999965, -8.71432951099996],
              [-35.84103949699994, -8.713413964999972],
              [-35.845145392999939, -8.716094748999978],
              [-35.845198555999957, -8.71776456799995],
              [-35.845696744999941, -8.733549575999973],
              [-35.845744089999982, -8.73505956899993],
              [-35.848941640999954, -8.737745952999944],
              [-35.850327895999953, -8.740900900999975],
              [-35.856261084999971, -8.744921806999969],
              [-35.861058879999973, -8.751280723999969],
              [-35.862978921999968, -8.751410687999964],
              [-35.870234393999965, -8.751180051999938],
              [-35.888368880999963, -8.750603592999937],
              [-35.888376277999953, -8.750603357999978],
              [-35.889238797999951, -8.750575939999976],
              [-35.909123540999929, -8.749943842999926],
              [-35.90942382999998, -8.749934297999971],
              [-35.91076826799997, -8.749968829999943],
              [-35.912190633999955, -8.750078052999982],
              [-35.913598686999933, -8.750151210999945],
              [-35.916275670999937, -8.749781179999957],
              [-35.918952654999941, -8.749411148999968],
              [-35.919354449999958, -8.748339371999975],
              [-35.920667461999983, -8.747135776999926],
              [-35.922304456999939, -8.745635198999935],
              [-35.923941450999962, -8.744134620999944],
              [-35.926145485999939, -8.742963114999952],
              [-35.9276, -8.74219],
              [-35.929292952999958, -8.742032244999962],
              [-35.933975516999965, -8.744230183999946],
              [-35.940633107999929, -8.746219807999978],
              [-35.942289456999958, -8.746714808999968],
              [-35.947736521999957, -8.747288184999945],
              [-35.950795065999955, -8.746707354999955],
              [-35.950795316999972, -8.746707306999951],
              [-35.951035267999941, -8.746599230999948],
              [-35.951118465999969, -8.746561757999928],
              [-35.956130787999939, -8.744304172999932],
              [-35.96044633799994, -8.742510057999937],
              [-35.965055398999937, -8.741682501999946],
              [-35.966927797999972, -8.743142638999927],
              [-35.966674731999944, -8.745756782999933],
              [-35.966407906999962, -8.748513048999939],
              [-35.966181614999982, -8.750850624999941],
              [-35.966271279999944, -8.762140229999943],
              [-35.967223471999944, -8.763767713999925],
              [-35.96763472899994, -8.764470633999963],
              [-35.96995487099997, -8.768437154999958],
              [-35.97768687599995, -8.770182388999956],
              [-35.982680923999965, -8.776695676999964],
              [-35.985946442999932, -8.780955124999934],
              [-36.004052928999954, -8.779523108999967],
              [-36.018793019999976, -8.777647173999981],
              [-36.018812778999973, -8.777644658999975],
              [-36.024198232999936, -8.776959265999949],
              [-36.027347375999966, -8.776559319999933],
              [-36.02872800199998, -8.779867648999925],
              [-36.027558864999946, -8.782664624999938],
              [-36.027306677999945, -8.784679585999982],
              [-36.024344524999947, -8.788195293999934],
              [-36.024191389999942, -8.78946184199998],
              [-36.024984681999968, -8.790343225999948],
              [-36.04046718099994, -8.763092153999935],
              [-36.045102196999949, -8.760668708999958],
              [-36.045282357999952, -8.76057448399996],
              [-36.053998364999984, -8.756015958999967],
              [-36.055786164999972, -8.755080543999952],
              [-36.075460087999943, -8.770532247999938],
              [-36.089238513999931, -8.776256316999934],
              [-36.08948638399994, -8.776359095999965],
              [-36.09071142199997, -8.776868027999967],
              [-36.092285997999966, -8.77998651799993],
              [-36.093169207999949, -8.781735740999977],
              [-36.096209752999982, -8.787751089999972],
              [-36.115661953999961, -8.826266103999956],
              [-36.115810404999934, -8.826560043999962],
              [-36.115820076999967, -8.826579194999965],
              [-36.120982358999981, -8.836800723999943],
              [-36.12120988099997, -8.837239547999957],
              [-36.125487844999952, -8.842347955999969],
              [-36.122601690999943, -8.844713510999952],
              [-36.118324045999941, -8.848219563999976],
              [-36.116047708999929, -8.850084412999934],
              [-36.115962244999935, -8.850155667999957],
              [-36.115306629999964, -8.850691564999977],
              [-36.089560960999961, -8.871786498999938],
              [-36.089485206999939, -8.871848568999951],
              [-36.089210943999944, -8.872072670999955],
              [-36.089124013999935, -8.872143851999965],
              [-36.089120864999984, -8.872146430999976],
              [-36.06111614799994, -8.895077693999951],
              [-36.041907525999932, -8.905082526999934],
              [-36.033256785999981, -8.908418863999941],
              [-36.033255451999935, -8.908419378999952],
              [-36.046585166999932, -8.912005112999964],
              [-36.057275153999967, -8.91302701099994],
              [-36.063935002999983, -8.91533734799998],
              [-36.066703118999953, -8.917778517999977],
              [-36.073449235999931, -8.925639407999938],
              [-36.075301735999972, -8.929106078999951],
              [-36.076376840999956, -8.932535232999953],
              [-36.078614202999972, -8.936293559999967],
              [-36.082284992999973, -8.940942952999933],
              [-36.086229186999958, -8.943930036999973],
              [-36.086232137999957, -8.943929672999957],
              [-36.086413248999975, -8.944066849999956],
              [-36.086410567999962, -8.944067403999952],
              [-36.090792526999962, -8.946261425999978],
              [-36.10094954799996, -8.949276553999937],
              [-36.113259288999984, -8.95069529899996],
              [-36.122451566999985, -8.952734644999964],
              [-36.124260293999953, -8.953587775999949],
              [-36.127029341999958, -8.955900888999963],
              [-36.12714224299998, -8.955995199999961],
              [-36.127280331999941, -8.956110551999927],
              [-36.128249, -8.957717181999953],
              [-36.12894130899997, -8.960488602999931],
              [-36.129806164999934, -8.965793261999977],
              [-36.129588631999979, -8.97132703799997],
              [-36.129511894999951, -8.971731335999948],
              [-36.128485563999959, -8.97713867799996],
              [-36.126810489999968, -8.981276315999935],
              [-36.12461073299994, -8.98340088499998],
              [-36.116158403999975, -8.98597895599994],
              [-36.112188078999964, -8.987788069999965],
              [-36.110555344999966, -8.989193962999934],
              [-36.106231805999982, -8.994483346999971],
              [-36.104329163999978, -8.998926995999966],
              [-36.104328162999934, -8.998929333999968],
              [-36.106526789999975, -9.001016413999935],
              [-36.109793171999968, -9.005441417999975],
              [-36.109889152999983, -9.006133501999955],
              [-36.109071020999977, -9.007290242999943],
              [-36.109466083999962, -9.008422089999954],
              [-36.109469183999977, -9.008421713999951],
              [-36.109469673999968, -9.008423119999975],
              [-36.111153994999938, -9.008218360999933],
              [-36.113773765999952, -9.00921378299995],
              [-36.115088126999979, -9.013400680999951],
              [-36.114236532999939, -9.014587846999973],
              [-36.111592759999951, -9.016673975999936],
              [-36.111491891999947, -9.017673103999925],
              [-36.111495628999933, -9.017672681999954],
              [-36.111495481999953, -9.017674134999936],
              [-36.114532911999959, -9.019882514999949],
              [-36.115088481999976, -9.022119009999926],
              [-36.11791607899994, -9.023833937999939],
              [-36.120662655999979, -9.027446595999947],
              [-36.120665622999979, -9.027451461999931],
              [-36.123831431999974, -9.02986601899994],
              [-36.127278572999955, -9.033669513999939],
              [-36.128901672999973, -9.034234849999962],
              [-36.133215294999957, -9.03529075199998],
              [-36.134505013999956, -9.035427159999927],
              [-36.13839257099994, -9.035838981999973],
              [-36.138888325999972, -9.036009822999972],
              [-36.139799384999947, -9.036592172999974],
              [-36.140129648999959, -9.037045573999933],
              [-36.141039018999948, -9.039684178999948],
              [-36.142805903999943, -9.040323624999928],
              [-36.146753693999983, -9.040660112999944],
              [-36.151727940999933, -9.042694621999942],
              [-36.151728462999984, -9.042694397999981],
              [-36.151731530999939, -9.042695652999953],
              [-36.153206218999969, -9.042061731999979],
              [-36.154787324999972, -9.039961240999958],
              [-36.155635828999948, -9.039949791999959],
              [-36.155858106999972, -9.040178627999978],
              [-36.155861348999963, -9.040179301999956],
              [-36.155978854999944, -9.040203729999973],
              [-36.156216748999952, -9.040253185999973],
              [-36.157662566999932, -9.042032100999961],
              [-36.157664568999962, -9.042030261999969],
              [-36.157666200999984, -9.042032269999936],
              [-36.158916077999947, -9.040883760999975],
              [-36.161097109999957, -9.041358718999959],
              [-36.161097970999947, -9.041358283999955],
              [-36.161100743999953, -9.041358887999934],
              [-36.162625112999933, -9.040588943999978],
              [-36.164135704999978, -9.040489166999976],
              [-36.168854363999969, -9.041869425999948],
              [-36.173423853999964, -9.043950779999932],
              [-36.175128424999969, -9.044150927999965],
              [-36.175128878999942, -9.044150722999973],
              [-36.175132058999964, -9.04415109599995],
              [-36.176949384999944, -9.043332446999955],
              [-36.179466747999982, -9.041017317999945],
              [-36.180726430999982, -9.041454533999968],
              [-36.183444243999929, -9.044351257999949],
              [-36.188946008999949, -9.046295305999934],
              [-36.188948259999961, -9.046294984999975],
              [-36.188949642999944, -9.046295473999976],
              [-36.192289409999944, -9.045820473999981],
              [-36.194827545999942, -9.044464610999967],
              [-36.195724917999939, -9.044786385999942],
              [-36.195726705999959, -9.044786174999956],
              [-36.19572764399993, -9.044786511999973],
              [-36.198505731999944, -9.046676743999967],
              [-36.199233452999977, -9.048846086999959],
              [-36.200274853999929, -9.053392899999949],
              [-36.203370026999949, -9.06164524999997],
              [-36.20279703999995, -9.066844917999958],
              [-36.204548779999982, -9.068868185999975],
              [-36.204547028999968, -9.068870671999946],
              [-36.204552296999964, -9.068876757999931],
              [-36.204603789999965, -9.068936246999954],
              [-36.20592617899996, -9.070463973999949],
              [-36.205961993999949, -9.070804480999925],
              [-36.206002916999978, -9.071193549999975],
              [-36.206902983999953, -9.071581988999981],
              [-36.206904304999966, -9.071581158999948],
              [-36.206906617999948, -9.071582157999956],
              [-36.207688470999983, -9.071091008999929],
              [-36.208669554999972, -9.071442873999956],
              [-36.210338282999942, -9.073138458999949],
              [-36.217435998999974, -9.073576510999942],
              [-36.217436176999968, -9.073576479999929],
              [-36.217438723999976, -9.073576637999963],
              [-36.22064254199995, -9.073027510999964],
              [-36.221912403999966, -9.073303137999972],
              [-36.221559630999934, -9.076516864999974],
              [-36.223401040999931, -9.082536389999973],
              [-36.222467783999946, -9.086433639999939],
              [-36.22827108499996, -9.094933616999981],
              [-36.231819567999935, -9.098097505999931],
              [-36.232547627999963, -9.099521400999947],
              [-36.236457709999968, -9.103489751999973],
              [-36.236458910999943, -9.103488358999925],
              [-36.236461299999974, -9.103490783999973],
              [-36.239278183999943, -9.100222138999982],
              [-36.240965277999976, -9.099393022999948],
              [-36.24552418199994, -9.099044333999927],
              [-36.251837704999957, -9.099743505999982],
              [-36.255674942999974, -9.102641834999929],
              [-36.255678250999949, -9.102641756999958],
              [-36.255678577999959, -9.102642003999961],
              [-36.266843784999935, -9.102381843999979],
              [-36.268390882999938, -9.103924266999968],
              [-36.267713997999977, -9.106559982999954],
              [-36.26786451199996, -9.108435328999974],
              [-36.26699709899998, -9.11109068899998],
              [-36.266071301999943, -9.112028651999935],
              [-36.266074044999982, -9.112028997999971],
              [-36.265830378999965, -9.112275850999936],
              [-36.265824142999975, -9.112279057999956],
              [-36.262182800999938, -9.115968247999945],
              [-36.260685514999977, -9.11852238299997],
              [-36.263642253999933, -9.12359509099997],
              [-36.26354310499994, -9.12600432399995],
              [-36.260310145999938, -9.129921236999962],
              [-36.258067160999929, -9.13346319599998],
              [-36.258038069999941, -9.137137005999932],
              [-36.257719637999969, -9.138360156999966],
              [-36.257718997999973, -9.138362619999953],
              [-36.257333936999942, -9.139844284999981],
              [-36.257254800999931, -9.140148790999945],
              [-36.257255162999968, -9.140150012999982],
              [-36.25738585299996, -9.140591118999964],
              [-36.264719558999957, -9.165862559999937],
              [-36.263907850999942, -9.165908062999961],
              [-36.263141863999977, -9.166320516999974],
              [-36.262582104999979, -9.167263269999978],
              [-36.262847253999951, -9.168647937999935],
              [-36.262444565999942, -9.171277334999957],
              [-36.262842311999975, -9.171359726999981],
              [-36.263495632999934, -9.172014570999977],
              [-36.263497951999966, -9.172014289999936],
              [-36.263498358999982, -9.172014698999931],
              [-36.267072450999933, -9.171582158999968],
              [-36.268069419999961, -9.170099395999955],
              [-36.268433707999975, -9.17010105199995],
              [-36.268304392999937, -9.170865198999934],
              [-36.268878316999974, -9.171060682999951],
              [-36.268880600999978, -9.171060659999966],
              [-36.268881043999954, -9.171060810999961],
              [-36.271098561999963, -9.171039323999935],
              [-36.271144937999964, -9.170311763999962],
              [-36.270452686999931, -9.169580734999954],
              [-36.270621203999951, -9.169047381999974],
              [-36.271077981999952, -9.168399565999948],
              [-36.272351459999982, -9.168294058999948],
              [-36.273060956999984, -9.166731194999954],
              [-36.274172461999967, -9.166204165999943],
              [-36.275523577999934, -9.166405519999955],
              [-36.276664638999932, -9.167178305999926],
              [-36.276668273999974, -9.167178477999926],
              [-36.279498077999961, -9.167314386999976],
              [-36.285757120999961, -9.17007853299998],
              [-36.285941811999976, -9.172717519999935],
              [-36.287045701999944, -9.173415978999969],
              [-36.28704771799994, -9.173415656999964],
              [-36.287048427999935, -9.173416105999934],
              [-36.28776025999997, -9.173302434999925],
              [-36.288648330999933, -9.173161276999963],
              [-36.289995252999972, -9.174203440999975],
              [-36.289996670999983, -9.174203451999972],
              [-36.289997934999974, -9.174204430999964],
              [-36.290948675999971, -9.174210889999927],
              [-36.292134339999961, -9.173952502999953],
              [-36.293499761999954, -9.172584454999935],
              [-36.29497566799995, -9.172442898999975],
              [-36.295977451999931, -9.175116420999927],
              [-36.297201309999934, -9.176782087999982],
              [-36.297858381999959, -9.176901910999959],
              [-36.297858824999935, -9.176901621999946],
              [-36.29786110799995, -9.17690203799998],
              [-36.300003214999947, -9.175502766999955],
              [-36.300520292999977, -9.175780409999959],
              [-36.301523915999951, -9.176319674999945],
              [-36.301881252999976, -9.17651167799994],
              [-36.301883865999969, -9.176511744999971],
              [-36.301883979999957, -9.176511805999951],
              [-36.304004802999941, -9.176566154999932],
              [-36.306053586999951, -9.178684619999956],
              [-36.306055128999958, -9.178684431999955],
              [-36.306056268999953, -9.178685610999935],
              [-36.307822794999936, -9.17846992799997],
              [-36.308562411999958, -9.177082845999962],
              [-36.308852680999962, -9.172545983999953],
              [-36.309791961999963, -9.17236317499993],
              [-36.311789155999975, -9.174481172999947],
              [-36.311885514999972, -9.178280366999957],
              [-36.312323761999949, -9.179918025999939],
              [-36.31616728399996, -9.182126677999975],
              [-36.317971488999945, -9.182563935999951],
              [-36.319162583999969, -9.184069966999971],
              [-36.321519749999936, -9.185380642999974],
              [-36.32288347399998, -9.189638055999978],
              [-36.322665327999971, -9.191319469999939],
              [-36.324956044999965, -9.192522475999965],
              [-36.326353308999956, -9.197813355999926],
              [-36.327644210999949, -9.200767656999972],
              [-36.329479441999979, -9.202503815999933],
              [-36.331850039999949, -9.20378421199996],
              [-36.335223032999977, -9.204811473999939],
              [-36.338887654999951, -9.207011387999955],
              [-36.338890115999959, -9.207011355999953],
              [-36.338890380999942, -9.207011514999976],
              [-36.34146495799996, -9.206978187999937],
              [-36.344454015999929, -9.204846024999938],
              [-36.348595340999964, -9.203375782999956],
              [-36.351413199999968, -9.201364307999938],
              [-36.353916541999979, -9.201193715999977],
              [-36.354827106999949, -9.203302461999954],
              [-36.354420600999958, -9.205211475999931],
              [-36.351184362999959, -9.208987788999934],
              [-36.349229229999935, -9.212990067999954],
              [-36.348888285999976, -9.21473687799994],
              [-36.349162886999977, -9.216259106999928],
              [-36.350352339999972, -9.216890066999952],
              [-36.352246462999972, -9.217033348999962],
              [-36.352834790999964, -9.219509678999941],
              [-36.353602464999938, -9.22012174799994],
              [-36.353604039999937, -9.220121173999928],
              [-36.353606055999933, -9.220122781999976],
              [-36.354727693999962, -9.219712600999969],
              [-36.355932723999956, -9.218385970999975],
              [-36.358345919999977, -9.218288240999925],
              [-36.360213816999931, -9.221555326999976],
              [-36.365703918999941, -9.222627514999942],
              [-36.365704620999963, -9.222627246999934],
              [-36.365706645999978, -9.222627642999953],
              [-36.36969501599998, -9.221106944999974],
              [-36.370894384999929, -9.220441678999975],
              [-36.374081254999965, -9.216991407999956],
              [-36.375873492999972, -9.216700303999971],
              [-36.38034162699995, -9.219711936999943],
              [-36.382258491999949, -9.220034390999956],
              [-36.382258471999933, -9.220033900999965],
              [-36.385847456999954, -9.220637551999971],
              [-36.387387270999966, -9.223557623999966],
              [-36.390042240999946, -9.224875814999962],
              [-36.391735600999937, -9.225121055999978],
              [-36.399611310999944, -9.224526915999945],
              [-36.40291306499995, -9.223424286999943],
              [-36.404350901999976, -9.221015353999974],
              [-36.406925002999969, -9.220671977999928],
              [-36.406925658999967, -9.220672380999929],
              [-36.407470935999982, -9.221007249999957],
              [-36.407471813999962, -9.22100620599997],
              [-36.407948045999944, -9.221298640999976],
              [-36.408662985999968, -9.221923990999926],
              [-36.408664939999937, -9.221925699999929],
              [-36.408785100999978, -9.221814880999943],
              [-36.410161273999961, -9.223043451999956],
              [-36.41129736299996, -9.224239543999943],
              [-36.41388362899994, -9.228472971999963],
              [-36.415299202999961, -9.229673529999957],
              [-36.415302546999953, -9.229673451999929],
              [-36.415302837999945, -9.229673699999978],
              [-36.41673676399995, -9.229640158999928],
              [-36.419800531999954, -9.229568494999967],
              [-36.419802962999938, -9.229568437999944],
              [-36.419803717999969, -9.229568419999964],
              [-36.422149874999945, -9.228605204999951],
              [-36.424370898999939, -9.226024078999956],
              [-36.42518522599994, -9.221762531999957],
              [-36.42465945899994, -9.218478446999939],
              [-36.423338605999959, -9.216795225999931],
              [-36.42330302299996, -9.215653129999964],
              [-36.424775547999957, -9.214934480999943],
              [-36.42822154299995, -9.214410903999976],
              [-36.429650685999945, -9.213577695999959],
              [-36.430254083999955, -9.212714591999941],
              [-36.431832677999978, -9.212130615999968],
              [-36.437003895999965, -9.212100276999934],
              [-36.437006399999973, -9.212100261999979],
              [-36.439383288999977, -9.21362249699996],
              [-36.43949680999998, -9.216865908999978],
              [-36.440674867999974, -9.218903393999938],
              [-36.441236236999941, -9.219078382999953],
              [-36.44123624599996, -9.21907929899993],
              [-36.444567141999983, -9.220117406999975],
              [-36.447566710999979, -9.224313410999969],
              [-36.448711783999954, -9.224960514999964],
              [-36.448712779999937, -9.224960550999981],
              [-36.45274797899998, -9.225103899999965],
              [-36.452748073999942, -9.225103935999925],
              [-36.454258417999938, -9.225661614999979],
              [-36.454261100999929, -9.225662605999958],
              [-36.45526321899996, -9.226719312999933],
              [-36.455260145999944, -9.227494580999974],
              [-36.453771193999955, -9.230017847999932],
              [-36.453662962999942, -9.232390074999955],
              [-36.454898847999971, -9.232900406999931],
              [-36.455642520999959, -9.232947785999954],
              [-36.457696950999946, -9.233078673999955],
              [-36.457704641999953, -9.233079157999953],
              [-36.457854217999966, -9.233088557999963],
              [-36.457908563999979, -9.233092017999979],
              [-36.459526388999961, -9.233777692999979],
              [-36.461696691999975, -9.238422621999973],
              [-36.46251727899994, -9.23925111099993],
              [-36.468478735999952, -9.240665930999967],
              [-36.470436187999951, -9.24192835499997],
              [-36.471396024999933, -9.24317102599997],
              [-36.472952961999965, -9.24372929499998],
              [-36.472954494999954, -9.24372899399998],
              [-36.472955688999946, -9.243729420999955],
              [-36.47479594899994, -9.24336825499995],
              [-36.474797353999975, -9.24336797899997],
              [-36.476123903999962, -9.243772102999969],
              [-36.477188553999952, -9.242593368999962],
              [-36.478799594999941, -9.242054941999982],
              [-36.480292600999974, -9.240531268999973],
              [-36.481333185999972, -9.239469308999958],
              [-36.481615740999985, -9.239181290999966],
              [-36.48161717499994, -9.239182737999954],
              [-36.482253554999943, -9.239824719999945],
              [-36.48459001699996, -9.240480779999928],
              [-36.484591652999939, -9.240480601999934],
              [-36.48459274399994, -9.240480907999938],
              [-36.487165260999973, -9.240201386999956],
              [-36.487888569999939, -9.24162143999996],
              [-36.488618005999967, -9.244579346999956],
              [-36.48825209599994, -9.245608730999948],
              [-36.489759398999979, -9.248874773999944],
              [-36.489016767999942, -9.25010814999996],
              [-36.489065962999973, -9.250483506999956],
              [-36.489792744999932, -9.251454862999935],
              [-36.489808803999949, -9.252640578999944],
              [-36.489811524999936, -9.252640232999966],
              [-36.48981153099993, -9.252640706999955],
              [-36.491486537999947, -9.252427672999943],
              [-36.49268989199993, -9.254178267999976],
              [-36.491616424999961, -9.256948178999949],
              [-36.491986211999972, -9.25904025899996],
              [-36.490405461999956, -9.26121427299995],
              [-36.491337717999954, -9.262408215999926],
              [-36.49133982099994, -9.262407543999927],
              [-36.491340444999935, -9.262408343999937],
              [-36.492912401999945, -9.26190622699994],
              [-36.494908657999929, -9.261932119999926],
              [-36.495617191999941, -9.262256863999937],
              [-36.497412940999936, -9.264457214999936],
              [-36.498651732999974, -9.264912446999972],
              [-36.498652277999952, -9.264911772999938],
              [-36.498654459999955, -9.264912574999926],
              [-36.500373140999955, -9.262785593999979],
              [-36.500765567999963, -9.263684271999978],
              [-36.499585937999939, -9.264569191999954],
              [-36.49963594299993, -9.265230618999965],
              [-36.501822791999984, -9.265939253999932],
              [-36.50490545699995, -9.266938172999971],
              [-36.551606183999979, -9.282071263999967],
              [-36.55321631399994, -9.282593016999954],
              [-36.571839564999948, -9.292582841999945],
              [-36.571836487999974, -9.295216861999961],
              [-36.573026850999952, -9.297464163999962],
              [-36.572997614999963, -9.298731994999969],
              [-36.572980234999932, -9.299485683999933],
              [-36.573718563999932, -9.302268692999974],
              [-36.57444880099996, -9.309493657999951],
              [-36.574246697999968, -9.31080996999998],
              [-36.573191116999965, -9.311974629999952],
              [-36.572902019999958, -9.314475800999958],
              [-36.571431091999955, -9.31604529599997],
              [-36.572688395999933, -9.318619733999981],
              [-36.572758095999973, -9.320001582999964],
              [-36.571908439999959, -9.320033464999938],
              [-36.571906160999959, -9.320033550999938],
              [-36.568207646999952, -9.318274705999954],
              [-36.568205798999941, -9.318273826999928],
              [-36.566788728999938, -9.31948381199993],
              [-36.567384034999975, -9.320976844999961],
              [-36.56680431999996, -9.321744595999974],
              [-36.564264091999974, -9.322248135999928],
              [-36.563906851999946, -9.322782109999935],
              [-36.564046631999929, -9.323505635999936],
              [-36.566911597999933, -9.325187335999942],
              [-36.566949811999962, -9.325776222999934],
              [-36.565828207999971, -9.327105644999961],
              [-36.566649624999968, -9.32825773899998],
              [-36.566576126999962, -9.331437426999969],
              [-36.567469121999977, -9.332134824999969],
              [-36.56747099699993, -9.332134286999974],
              [-36.567471849999947, -9.332134952999979],
              [-36.569287642999939, -9.331613109999978],
              [-36.570985407999956, -9.331622697999933],
              [-36.57244798399995, -9.332143823999957],
              [-36.572507646999952, -9.332165116999931],
              [-36.573882357999935, -9.333563974999947],
              [-36.574562304999972, -9.333652152999946],
              [-36.57456237699995, -9.333651936999956],
              [-36.574565031999953, -9.333652280999956],
              [-36.575121161999959, -9.331993204999947],
              [-36.576078324999969, -9.331879940999954],
              [-36.577688555999941, -9.334628368999972],
              [-36.577690318999942, -9.334628358999964],
              [-36.577690374999975, -9.334628454999972],
              [-36.581567881999945, -9.334606049999934],
              [-36.582680729999936, -9.335599427999966],
              [-36.582682466999984, -9.335599440999943],
              [-36.58268254799998, -9.335599512999977],
              [-36.584623245999978, -9.33561353999994],
              [-36.585009576999937, -9.335969290999969],
              [-36.58487359399993, -9.337130286999979],
              [-36.585282777999964, -9.337967849999927],
              [-36.586265663999939, -9.338084954999943],
              [-36.586265835999939, -9.33808484399998],
              [-36.586267481999982, -9.338085039999953],
              [-36.591507020999984, -9.334701265999968],
              [-36.593070173999934, -9.334826396999972],
              [-36.593956245999948, -9.335357231999978],
              [-36.594548566999947, -9.33731886299995],
              [-36.595107620999954, -9.33754339099994],
              [-36.595108747999973, -9.33754287499994],
              [-36.595110348999981, -9.337543517999961],
              [-36.596641814999941, -9.336842776999958],
              [-36.598363397999947, -9.33683005499995],
              [-36.598365934999947, -9.336830035999981],
              [-36.600866745999951, -9.338757573999942],
              [-36.60086756499993, -9.338758204999976],
              [-36.603124391999984, -9.338162752999949],
              [-36.603139375999945, -9.338158800999963],
              [-36.60357991199993, -9.338865381999938],
              [-36.603087200999937, -9.340764993999926],
              [-36.60362497899996, -9.341512509999973],
              [-36.603627607999954, -9.341512501999944],
              [-36.603627705999941, -9.341512637999926],
              [-36.605049742999938, -9.341508264999959],
              [-36.605863974999977, -9.341080053999974],
              [-36.606555810999964, -9.340716212999951],
              [-36.606654738999964, -9.340441675999955],
              [-36.606763504999947, -9.340139832999967],
              [-36.606842796999956, -9.339919786999928],
              [-36.606918870999948, -9.339708672999961],
              [-36.60695554299997, -9.339606901999957],
              [-36.606971525999938, -9.339562547999947],
              [-36.607037481999953, -9.339379509999958],
              [-36.60713881099997, -9.339098307999961],
              [-36.607272751999972, -9.338726600999962],
              [-36.60830389399996, -9.337846443999979],
              [-36.607602712999949, -9.334732023999948],
              [-36.607722169999931, -9.333464453999966],
              [-36.608572914999968, -9.332859373999952],
              [-36.610472139999956, -9.333226832999969],
              [-36.611791565999965, -9.333981485999971],
              [-36.611793101999979, -9.333981408999932],
              [-36.612845107999931, -9.333928600999968],
              [-36.614747775999945, -9.331026056999974],
              [-36.615073337999945, -9.330291444999943],
              [-36.615624346999937, -9.329048123999939],
              [-36.615653873999975, -9.327148352999927],
              [-36.613723426999968, -9.324847726999963],
              [-36.613824010999963, -9.323805291999975],
              [-36.614279684999929, -9.323751821999963],
              [-36.614996705999943, -9.323666976999959],
              [-36.617939674999946, -9.325968170999943],
              [-36.619325376999939, -9.326567370999953],
              [-36.619631178999953, -9.328800370999943],
              [-36.622356562999983, -9.326586095999971],
              [-36.622493688999953, -9.326587092999944],
              [-36.623431584999935, -9.326593909999929],
              [-36.624953785999935, -9.327751238999952],
              [-36.624535320999939, -9.331665803999954],
              [-36.62485516299995, -9.331832162999945],
              [-36.625794252999981, -9.332320607999975],
              [-36.625796769999965, -9.332320624999966],
              [-36.625796980999951, -9.332320734999939],
              [-36.627882417999956, -9.332335110999963],
              [-36.628519218999941, -9.331750764999981],
              [-36.628346728999929, -9.32921864399998],
              [-36.628731883999933, -9.328278649999959],
              [-36.631891504999942, -9.328298994999955],
              [-36.633729040999981, -9.327723086999981],
              [-36.634619303999955, -9.326903675999972],
              [-36.635898324999971, -9.323051616999976],
              [-36.634846993999929, -9.321255380999958],
              [-36.63545600499998, -9.320784919999937],
              [-36.635456052999984, -9.320784882999931],
              [-36.639271453999982, -9.321046105999926],
              [-36.639274078999961, -9.321046285999955],
              [-36.640639700999941, -9.320358143999954],
              [-36.641322289999948, -9.320014051999976],
              [-36.641809837999972, -9.319768279999948],
              [-36.641887620999967, -9.317649634999952],
              [-36.642593483999974, -9.316064437999955],
              [-36.644689116999984, -9.314371666999932],
              [-36.646078215999978, -9.314439037999932],
              [-36.646080942999959, -9.314439167999979],
              [-36.648790224999971, -9.315574440999967],
              [-36.649736363999978, -9.315405712999961],
              [-36.652410137999937, -9.312773017999973],
              [-36.654621951999957, -9.312669296999957],
              [-36.654624482999964, -9.312669177999965],
              [-36.65514028399997, -9.313583711999968],
              [-36.656146183999965, -9.314628299999981],
              [-36.657461590999958, -9.315595511999959],
              [-36.658931752999933, -9.316059772999949],
              [-36.660479290999945, -9.315672887999938],
              [-36.661794698999984, -9.314821741999936],
              [-36.663071417999959, -9.314125349999927],
              [-36.665895675999934, -9.313931907999972],
              [-36.668333048999955, -9.313506334999943],
              [-36.670422225999971, -9.313235514999974],
              [-36.671737632999964, -9.311997484999949],
              [-36.672163205999937, -9.310449945999949],
              [-36.672627467999973, -9.309598799999947],
              [-36.673439925999958, -9.309560111999929],
              [-36.673788121999962, -9.310256503999938],
              [-36.674484513999971, -9.310449945999949],
              [-36.674871398999983, -9.310217815999977],
              [-36.675761232999946, -9.308979784999963],
              [-36.677656967999951, -9.307084049999958],
              [-36.679127128999937, -9.30615552699993],
              [-36.68152581399994, -9.30495618499998],
              [-36.683808432999967, -9.303176515999951],
              [-36.685626790999947, -9.302286680999941],
              [-36.687445147999938, -9.300816519999955],
              [-36.688064163999968, -9.298611276999964],
              [-36.689224817999957, -9.296444723999969],
              [-36.689392212999962, -9.296390016999965],
              [-36.69862788599994, -9.293371696999941],
              [-36.699747484999932, -9.293005799999946],
              [-36.700148727999931, -9.29286639999998],
              [-36.729663624999944, -9.28261230399994],
              [-36.760779531999958, -9.271801983999978],
              [-36.761772535999967, -9.271456992999958],
              [-36.774083376999954, -9.274605335999979],
              [-36.776829541999973, -9.275307632999954],
              [-36.796832644999938, -9.280423175999942],
              [-36.836277540999959, -9.28810307699996],
              [-36.837898252999935, -9.288418627999931],
              [-36.837899118999928, -9.288418796999963],
              [-36.839712438999982, -9.288213935999977],
              [-36.840246371999967, -9.287578688999929],
              [-36.840837087999944, -9.285155591999967],
              [-36.844201013999964, -9.282342401999927],
              [-36.84456939599994, -9.281679399999973],
              [-36.846776738999949, -9.277706692999971],
              [-36.848370027999977, -9.276695205999943],
              [-36.850391043999934, -9.275872516999925],
              [-36.85039123699994, -9.275872438999954],
              [-36.85319935299998, -9.276151360999961],
              [-36.853201092999939, -9.276151533999951],
              [-36.856015752999951, -9.272908639999969],
              [-36.856789172999981, -9.272829039999976],
              [-36.856790608999972, -9.27282889199995],
              [-36.857673047999981, -9.27336126299997],
              [-36.857674242999963, -9.273361983999962],
              [-36.858354042999963, -9.272668284999952],
              [-36.864449656999966, -9.269809898999938],
              [-36.865816029999962, -9.26981795599994],
              [-36.867471192999972, -9.268531587999973],
              [-36.871260054999937, -9.268084655999928],
              [-36.872962609999945, -9.268682835999925],
              [-36.875077997999938, -9.274348035999935],
              [-36.879781541999932, -9.27637452099998],
              [-36.879783462999967, -9.27637429899994],
              [-36.87978426899997, -9.276374645999965],
              [-36.874772546999964, -9.276960731999964],
              [-36.879748599999971, -9.278803793999941],
              [-36.884133604999931, -9.280914616999951],
              [-36.884922535999976, -9.281294352999964],
              [-36.884922556999982, -9.281295064999938],
              [-36.884960802999956, -9.281313474999934],
              [-36.889791951999939, -9.28363894499995],
              [-36.889792966999948, -9.283638640999982],
              [-36.889793770999972, -9.283639027999925],
              [-36.897945988999936, -9.281198189999941],
              [-36.900827733999961, -9.282908411999927],
              [-36.913692246999972, -9.296055648999982],
              [-36.915820465999957, -9.299020803999952],
              [-36.91805842499997, -9.304786601999979],
              [-36.921414951999964, -9.30955783099995],
              [-36.921818565999956, -9.311254776999931],
              [-36.921752710999954, -9.312403076999942],
              [-36.921750965999934, -9.312433495999926],
              [-36.921683366999957, -9.313612214999978],
              [-36.921616317999963, -9.314770575999944],
              [-36.922320407999962, -9.318165835999935],
              [-36.926762623999934, -9.322891869999978],
              [-36.927315467999961, -9.325411127999928],
              [-36.926788173999967, -9.327205857999957],
              [-36.924970295999969, -9.328838840999936],
              [-36.922766809999985, -9.329982170999926],
              [-36.920943432999934, -9.332487430999947],
              [-36.921028490999959, -9.335517704999972],
              [-36.922270216999948, -9.339761859999953],
              [-36.925347000999977, -9.343632644999957],
              [-36.930029444999946, -9.346998947999964],
              [-36.935058193999964, -9.34874960999997],
              [-36.936551463999933, -9.349886277999929],
              [-36.937877041999968, -9.352821311999946],
              [-36.93840271199997, -9.355676993999964],
              [-36.936767593999946, -9.361214151999945],
              [-36.936110562999943, -9.36212980099998],
              [-36.930437173999962, -9.37003632699998],
              [-36.931099562999975, -9.371581502999959],
              [-36.933253732999958, -9.374418743999968],
              [-36.936849385999949, -9.377425684999935],
              [-36.936850462999985, -9.377425066999933],
              [-36.940269572999966, -9.380284451999955],
              [-36.94400894499995, -9.382340642999964],
              [-36.946486968999977, -9.383022385999936],
              [-36.946487616999946, -9.38302256399993],
              [-36.952433679999956, -9.382543443999964],
              [-36.955464647999975, -9.381251128999963],
              [-36.958528737999984, -9.378700567999942],
              [-36.961286825999935, -9.375635195999962],
              [-36.969287387999941, -9.369048670999973],
              [-36.976059272999976, -9.362183768999955],
              [-36.976067367999974, -9.362175562999937],
              [-36.97611250999995, -9.36212980099998],
              [-36.976172345999942, -9.362069142999928],
              [-36.97695291399998, -9.36138841199994],
              [-36.979081182999948, -9.35953071299997],
              [-36.979640392999954, -9.359328251999955],
              [-36.982432162999942, -9.358317496999973],
              [-36.99126754599996, -9.356493955999952],
              [-37.003366361999952, -9.352613319999932],
              [-37.006829182999979, -9.346820406999939],
              [-37.01284157799995, -9.33933054299996],
              [-37.016743990999942, -9.329876056999979],
              [-37.018379797999955, -9.323927576999949],
              [-37.020599156999936, -9.319854984999949],
              [-37.024291630999983, -9.315715122999961],
              [-37.028513273999977, -9.314017042999978],
              [-37.032282545999976, -9.31409034099994],
              [-37.039790796999966, -9.316212352999969],
              [-37.039792528999953, -9.31621241199997],
              [-37.039792614999953, -9.316212435999944],
              [-37.043409822999934, -9.316335089999939],
              [-37.045531036999932, -9.315881689999969],
              [-37.047526704999939, -9.314891374999945],
              [-37.050850993999973, -9.311623468999926],
              [-37.053065023999977, -9.308323035999933],
              [-37.05445399599995, -9.307133483999962],
              [-37.054454459999931, -9.307133086999954],
              [-37.055339885999956, -9.307447092999951],
              [-37.055341924999937, -9.307447815999979],
              [-37.057256532999929, -9.312943299999972],
              [-37.057585950999965, -9.317536393999944],
              [-37.05758622899998, -9.31754026699997],
              [-37.059368621999965, -9.313150726999936],
              [-37.060788782999964, -9.307958225999926],
              [-37.061519048999969, -9.304816674999927],
              [-37.061537933999944, -9.30473543599993],
              [-37.061671223999952, -9.30416202899994],
              [-37.061925536999979, -9.297368756999958],
              [-37.058332608999933, -9.291345645999968],
              [-37.057201429999964, -9.281788466999956],
              [-37.063256320999983, -9.274130509999964],
              [-37.067303764999963, -9.267240274999949],
              [-37.072557680999978, -9.262539705999927],
              [-37.078113857999938, -9.25626492799995],
              [-37.080034765999983, -9.254708267999945],
              [-37.080206482999984, -9.254569112999945],
              [-37.080906637999931, -9.254001724999966],
              [-37.090672931999961, -9.246362071999954],
              [-37.098610143999963, -9.244083586999977],
              [-37.106271401999948, -9.239784243999964],
              [-37.108175077999931, -9.238715939999963],
              [-37.116475236999975, -9.240930699999979],
              [-37.122528717999955, -9.248366464999947],
              [-37.126262148999956, -9.252952405999963],
              [-37.129372216999968, -9.258997956999963],
              [-37.129919102999963, -9.260061029999974],
              [-37.129920951999964, -9.260062218999963],
              [-37.142395758999953, -9.268079358999955],
              [-37.142957907999971, -9.268222584999933],
              [-37.150986209999985, -9.270268052999938],
              [-37.152582580999933, -9.270674779999979],
              [-37.156530069999974, -9.27341557099993],
              [-37.164334366999981, -9.27570510299995],
              [-37.164335705999974, -9.275705495999944],
              [-37.169388148999928, -9.272434048999969],
              [-37.169102634999945, -9.265710216999935],
              [-37.169062478999933, -9.26548781799994],
              [-37.168613842999946, -9.263003105999928],
              [-37.169723413999975, -9.257963495999945],
              [-37.168284424999968, -9.251826018999964],
              [-37.164169908999952, -9.246908923999968],
              [-37.160751336999965, -9.24396928799996],
              [-37.158589504999952, -9.240127651999956],
              [-37.158948498999962, -9.239101802999926],
              [-37.15986572199995, -9.238248690999967],
              [-37.181254727999942, -9.225584211999944],
              [-37.198315247999972, -9.215482639999948],
              [-37.20064509599996, -9.217413157999943],
              [-37.202309605999972, -9.217860325999936],
              [-37.202310244999978, -9.21786029499998],
              [-37.202310514999965, -9.217860367999947],
              [-37.206527894999965, -9.217651664999948],
              [-37.207423406999965, -9.218242098999951],
              [-37.207423457999937, -9.218241512999953],
              [-37.207534844999941, -9.218315014999973],
              [-37.208920027999966, -9.220179275999953],
              [-37.212172267999961, -9.221117078999953],
              [-37.216167307999967, -9.226264231999949],
              [-37.220308504999934, -9.228031474999966],
              [-37.222812321999982, -9.230853683999953],
              [-37.230448673999945, -9.237595909999982],
              [-37.234340849999967, -9.240175057999977],
              [-37.234341818999951, -9.240175699999952],
              [-37.234010520999959, -9.236853909999979],
              [-37.234424184999966, -9.234136209999974],
              [-37.23389307399998, -9.232149277999952],
              [-37.231006526999977, -9.230976743999975],
              [-37.230399263999971, -9.230350185999953],
              [-37.229235621999976, -9.226882346999957],
              [-37.229226003999941, -9.226853686999959],
              [-37.228783856999939, -9.225536017999957],
              [-37.230400166999971, -9.223600986999941],
              [-37.232356514999935, -9.22231697999996],
              [-37.233035473999962, -9.217526433999979],
              [-37.232615578999969, -9.211911051999948],
              [-37.233559750999973, -9.210850959999959],
              [-37.233557021999957, -9.20658584399996],
              [-37.234813478999968, -9.204532049999955],
              [-37.236750036999979, -9.203669974999968],
              [-37.23917288399997, -9.203318635999949],
              [-37.240864594999948, -9.199780527999962],
              [-37.240618975999951, -9.198475264999956],
              [-37.243002330999957, -9.196397766999951],
              [-37.244615278999959, -9.193240843999945],
              [-37.246785769999974, -9.190382352999961],
              [-37.245171088999939, -9.187567116999958],
              [-37.24323190299998, -9.186037853999949],
              [-37.245223246999956, -9.183660409999959],
              [-37.247083410999949, -9.179018503999941],
              [-37.248592775999953, -9.178206576999969],
              [-37.249252848999959, -9.176420396999958],
              [-37.252994119999983, -9.173449964999975],
              [-37.255253057999937, -9.170258006999973],
              [-37.259839735999947, -9.165162628999951],
              [-37.259934453999961, -9.165098852999961],
              [-37.259942628999966, -9.16509334899996],
              [-37.260043072999963, -9.165025714999956],
              [-37.260847333999948, -9.164488204999941],
              [-37.262008198999979, -9.16371172199996],
              [-37.262613814999952, -9.160951706999981],
              [-37.262397909999947, -9.16001627299994],
              [-37.26240009199995, -9.160017886999981],
              [-37.262230516999978, -9.159283229999971],
              [-37.262559524999972, -9.158881706999978],
              [-37.262557706999985, -9.158881626999971],
              [-37.266558214999975, -9.159048723999945],
              [-37.268200063999984, -9.158242386999973],
              [-37.270975096999962, -9.155823469999973],
              [-37.276313371999947, -9.153531114999964],
              [-37.277808127999947, -9.153320214999951],
              [-37.278487721999966, -9.152499785999964],
              [-37.278487741999982, -9.152499760999945],
              [-37.278489794999984, -9.152497282999946],
              [-37.278863974999979, -9.152045561999955],
              [-37.278925884999978, -9.151136064999946],
              [-37.280896300999984, -9.150275489999956],
              [-37.282285807999983, -9.148841809999965],
              [-37.287928110999985, -9.136349345999975],
              [-37.296572195999943, -9.120917267999971],
              [-37.296802397999954, -9.120789296999931],
              [-37.296948831999941, -9.120709459999944],
              [-37.297013088999961, -9.120673728999975],
              [-37.303653036999947, -9.116981539999927],
              [-37.311058129999935, -9.107544854999958],
              [-37.31145360499994, -9.104981586999941],
              [-37.310676731999934, -9.102557892999926],
              [-37.310699492999959, -9.10041876899993],
              [-37.313432242999966, -9.097749912999973],
              [-37.314391942999976, -9.096812651999926],
              [-37.314804138999932, -9.094965190999972],
              [-37.31551922999995, -9.094271934999938],
              [-37.31717290399996, -9.092672891999939],
              [-37.317453121999961, -9.092401931999973],
              [-37.322117356999968, -9.09328536299995],
              [-37.323868111999957, -9.094040053999947],
              [-37.327703326999938, -9.09322568999994],
              [-37.330889616999968, -9.090705963999937],
              [-37.332369477999976, -9.089124695999942],
              [-37.333457081999939, -9.087006626999937],
              [-37.334006012999964, -9.084596104999946],
              [-37.334148266999932, -9.082906239999943],
              [-37.334442677999959, -9.081862559999934],
              [-37.336080715999969, -9.07996882499998],
              [-37.336081114999956, -9.079968363999967],
              [-37.336212133999936, -9.079816892999929],
              [-37.33676786999996, -9.079174406999925],
              [-37.338617639999939, -9.077035888999944],
              [-37.338961831999939, -9.076637968999933],
              [-37.358639083999947, -9.053889107999964],
              [-37.391413126999964, -9.026983168999948],
              [-37.39241799399997, -9.026759864999974],
              [-37.393869468999981, -9.026983168999948],
              [-37.395568284999968, -9.028097527999932],
              [-37.395866124999941, -9.02657583599995],
              [-37.396309267999982, -9.025785005999978],
              [-37.396815777999961, -9.025514867999959],
              [-37.396883312999933, -9.024907055999961],
              [-37.396613173999981, -9.023927802999935],
              [-37.395937826999955, -9.022475806999978],
              [-37.395904059999964, -9.021125113999972],
              [-37.396140430999935, -9.020112093999956],
              [-37.396984614999951, -9.019402979999938],
              [-37.397862565999958, -9.01879516799994],
              [-37.398374622999938, -9.018659532999948],
              [-37.399903959999961, -9.018203014999926],
              [-37.400748519999979, -9.018088884999941],
              [-37.401752860999977, -9.018499751999968],
              [-37.402118074999976, -9.018522577999931],
              [-37.40239198699993, -9.018203014999926],
              [-37.40257459399993, -9.017906276999952],
              [-37.402848504999952, -9.017198673999928],
              [-37.403305023999962, -9.016445417999932],
              [-37.403729649999946, -9.01553826199995],
              [-37.404012627999975, -9.014893254999947],
              [-37.404195234999975, -9.014276953999968],
              [-37.405499747999954, -9.013003232999949],
              [-37.405526123999948, -9.012977478999971],
              [-37.406181090999951, -9.012336749999974],
              [-37.406888694999964, -9.011948708999967],
              [-37.407687602999943, -9.011674797999945],
              [-37.408346130999973, -9.011371973999928],
              [-37.409274384999947, -9.010815020999928],
              [-37.409895221999932, -9.010337454999956],
              [-37.410481115999971, -9.009886766999955],
              [-37.410991655999965, -9.009283400999948],
              [-37.411873497999977, -9.009144162999974],
              [-37.413126641999952, -9.00919057599998],
              [-37.413637181999945, -9.008772860999954],
              [-37.413915657999951, -9.008215908999944],
              [-37.414671949999956, -9.007682781999961],
              [-37.416502458999958, -9.006564137999931],
              [-37.417366864999963, -9.006513290999976],
              [-37.418739746999961, -9.006869222999967],
              [-37.42046856099995, -9.007021764999934],
              [-37.422858390999977, -9.006665832999943],
              [-37.423926187999939, -9.006665832999943],
              [-37.42509567999997, -9.007377696999981],
              [-37.426366865999967, -9.007530239999937],
              [-37.427332967999973, -9.007174307999946],
              [-37.42829906999998, -9.007479391999937],
              [-37.429926187999968, -9.007631934999949],
              [-37.431197374999954, -9.007682781999961],
              [-37.432926188999943, -9.007072612999934],
              [-37.434044832999973, -9.006716679999954],
              [-37.434655001999943, -9.006767527999955],
              [-37.435773646999962, -9.006767527999955],
              [-37.438112629999978, -9.006259052999951],
              [-37.43948551099993, -9.00587769699996],
              [-37.440909239999939, -9.005292950999944],
              [-37.441570256999967, -9.00478447699993],
              [-37.442155002999982, -9.004174306999971],
              [-37.442993985999976, -9.004021764999948],
              [-37.443756697999959, -9.004174306999971],
              [-37.444544833999942, -9.004326849999927],
              [-37.445205850999969, -9.004199730999972],
              [-37.447036358999981, -9.003437018999932],
              [-37.448027884999931, -9.002903120999974],
              [-37.449044833999949, -9.002648882999949],
              [-37.450493986999959, -9.002521764999926],
              [-37.451816020999956, -9.002470916999926],
              [-37.45260415599995, -9.002242103999947],
              [-37.453265172999977, -9.001555662999976],
              [-37.453875342999936, -9.001428543999964],
              [-37.454307545999939, -9.001631933999931],
              [-37.454841444999943, -9.001581086999977],
              [-37.455400766999958, -9.001682781999932],
              [-37.456036359999985, -9.001682781999932],
              [-37.456595681999943, -9.001453967999964],
              [-37.45709945699997, -9.001541562999932],
              [-37.46019509599995, -9.001143552999963],
              [-37.461123786999963, -9.000878211999975],
              [-37.46209670199994, -9.000347530999932],
              [-37.464661659999933, -8.99933039299998],
              [-37.466165255999954, -8.998799711999936],
              [-37.46658537899998, -8.998799711999936],
              [-37.468199532999961, -8.998578594999969],
              [-37.468617617999939, -8.998323089999928],
              [-37.477416593999976, -8.99297048699998],
              [-37.477809948999948, -8.992624744999944],
              [-37.478398754999944, -8.99253232999996],
              [-37.479231261999985, -8.992767944999969],
              [-37.479890983999951, -8.992893606999928],
              [-37.480425044999947, -8.992548037999939],
              [-37.481288966999955, -8.991432792999944],
              [-37.482121473999939, -8.99038037899993],
              [-37.482686949999959, -8.989594994999948],
              [-37.483048226999983, -8.989312256999938],
              [-37.483060935999958, -8.989312187999928],
              [-37.483477897999933, -8.989309913999932],
              [-37.483979585999975, -8.988975774999972],
              [-37.484509484999933, -8.98905147499994],
              [-37.485493583999983, -8.989682306999953],
              [-37.486225348999938, -8.990313138999966],
              [-37.486755247999952, -8.990943971999968],
              [-37.487638412999956, -8.991120604999935],
              [-37.488420644999962, -8.990943971999968],
              [-37.489682309999978, -8.991372936999937],
              [-37.490187539999965, -8.991685138999969],
              [-37.490295052999954, -8.991751575999956],
              [-37.491297239999938, -8.992029002999971],
              [-37.491702864999979, -8.992422491999946],
              [-37.491798843999959, -8.992515598999944],
              [-37.492102027999977, -8.992721763999953],
              [-37.492478984999934, -8.992970312999944],
              [-37.492729875999942, -8.993123685999933],
              [-37.492886461999944, -8.993334723999965],
              [-37.493085231999942, -8.993404292999969],
              [-37.493205390999947, -8.993359600999952],
              [-37.493290172999934, -8.993267446999937],
              [-37.493422866999936, -8.993126675999974],
              [-37.49352240199994, -8.993005728999947],
              [-37.493633014999943, -8.992847185999949],
              [-37.493633014999943, -8.992518641999936],
              [-37.493451748999973, -8.992201426999941],
              [-37.493213837999974, -8.991782248999925],
              [-37.492987255999935, -8.991272438999943],
              [-37.493003917999943, -8.991116924999972],
              [-37.49301855799996, -8.990980279999974],
              [-37.49302124299993, -8.990955223999947],
              [-37.49294469199998, -8.990769313999976],
              [-37.492941938999934, -8.99076262899996],
              [-37.492670802999953, -8.990646427999934],
              [-37.492505665999943, -8.990520474999926],
              [-37.492443457999968, -8.990275476999955],
              [-37.492582464999941, -8.990034529999946],
              [-37.492630511999948, -8.989815987999975],
              [-37.492602064999971, -8.989607058999979],
              [-37.492482547999941, -8.989341463999949],
              [-37.492398140999967, -8.989153893999969],
              [-37.492421329999956, -8.988449159999959],
              [-37.492344708999951, -8.987555246999932],
              [-37.492510720999974, -8.987006129999941],
              [-37.492893826999932, -8.986546402999977],
              [-37.493340782999951, -8.986520862999953],
              [-37.493596185999934, -8.986405930999979],
              [-37.493661218999932, -8.986048253999968],
              [-37.493519564999929, -8.985831272999974],
              [-37.493251391999934, -8.985397086999967],
              [-37.49313645999996, -8.984950130999948],
              [-37.493264161999946, -8.984720266999943],
              [-37.493711117999965, -8.984375471999954],
              [-37.493966521999937, -8.983902975999968],
              [-37.493978193999965, -8.983766794999951],
              [-37.494004831999973, -8.983456018999959],
              [-37.494477328999949, -8.982230081999944],
              [-37.495115837999947, -8.981451100999948],
              [-37.495703265999964, -8.981208467999977],
              [-37.495856507999974, -8.980761510999969],
              [-37.495814079999946, -8.980167190999964],
              [-37.495805427999983, -8.979727126999933],
              [-37.495639414999971, -8.979203548999976],
              [-37.495690495999952, -8.978858754999976],
              [-37.495945899999981, -8.978475648999961],
              [-37.496060830999966, -8.977913760999968],
              [-37.496354544999974, -8.977415723999968],
              [-37.496507787999974, -8.976841065999963],
              [-37.496558867999966, -8.976240867999934],
              [-37.496941973999981, -8.975398035999945],
              [-37.497159066999984, -8.97516817199994],
              [-37.497133525999971, -8.974938308999981],
              [-37.49698028399996, -8.974861687999976],
              [-37.49678873199997, -8.974836147999952],
              [-37.496661029999984, -8.974644594999972],
              [-37.496750420999945, -8.974414731999957],
              [-37.496801501999983, -8.973916694999957],
              [-37.496954743999936, -8.973737911999933],
              [-37.497044134999953, -8.973405887999945],
              [-37.497005824999974, -8.973099402999935],
              [-37.496890892999943, -8.972907850999945],
              [-37.496852581999974, -8.972703527999954],
              [-37.496993053999972, -8.972511974999975],
              [-37.497111064999956, -8.972264742999926],
              [-37.497093141999983, -8.972037268999941],
              [-37.496899919999976, -8.971951664999949],
              [-37.496703489999959, -8.971927678999975],
              [-37.496456706999936, -8.971732993999979],
              [-37.496507787999974, -8.971515900999975],
              [-37.496852581999974, -8.971413739999946],
              [-37.497082445999979, -8.971260496999946],
              [-37.497402393999948, -8.970877004999977],
              [-37.497794514999953, -8.970645297999965],
              [-37.498186634999968, -8.97068094499997],
              [-37.498507460999974, -8.970556178999971],
              [-37.498721345999968, -8.970288823999965],
              [-37.498846110999978, -8.969771937999951],
              [-37.498756992999972, -8.969272874999945],
              [-37.498917405999975, -8.969076814999937],
              [-37.499184760999981, -8.968952048999938],
              [-37.499487762999934, -8.968773811999938],
              [-37.499719470999935, -8.96839951499993],
              [-37.499862059999941, -8.968132159999925],
              [-37.500150209999958, -8.967970075999972],
              [-37.500150229999974, -8.967970064999975],
              [-37.500432417999946, -8.967811333999975],
              [-37.501430542999969, -8.96688450299996],
              [-37.501947429999973, -8.966456734999952],
              [-37.502482139999984, -8.966385440999943],
              [-37.502909907999936, -8.966171555999949],
              [-37.503266380999946, -8.96604679099994],
              [-37.503694148999955, -8.965975495999942],
              [-37.504389272999958, -8.96538731499993],
              [-37.504959629999973, -8.96538731499993],
              [-37.50542304499993, -8.965797258999942],
              [-37.505922107999936, -8.966795384999955],
              [-37.506064697999932, -8.967044915999963],
              [-37.507419295999966, -8.96754397899997],
              [-37.507971829999974, -8.96754397899997],
              [-37.509023426999931, -8.967454860999965],
              [-37.509255133999943, -8.967294447999961],
              [-37.50945119499994, -8.96706273999996],
              [-37.509950257999947, -8.966741913999954],
              [-37.510912735999966, -8.966688442999953],
              [-37.51178609599998, -8.966670618999956],
              [-37.512320805999934, -8.966421087999947],
              [-37.512605983999947, -8.96604679099994],
              [-37.513390225999956, -8.965725964999933],
              [-37.515030002999936, -8.965476432999935],
              [-37.516239134999978, -8.965612452999949],
              [-37.516947700999935, -8.965848641999969],
              [-37.517318854999985, -8.966523466999945],
              [-37.517512692999958, -8.966911440999979],
              [-37.517743865999932, -8.967117083999938],
              [-37.517744156999981, -8.967117341999938],
              [-37.519111110999972, -8.968333333999965],
              [-37.520490215999985, -8.966724800999941],
              [-37.522260155999959, -8.965637550999929],
              [-37.522810886999935, -8.965299244999926],
              [-37.522836792999954, -8.965281378999975],
              [-37.523772307999934, -8.964636195999958],
              [-37.524468509999963, -8.964006299999937],
              [-37.524484658999938, -8.963993821999964],
              [-37.525305290999938, -8.963359771999933],
              [-37.526172172999964, -8.962689987999966],
              [-37.526317885999958, -8.962577403999944],
              [-37.526651381999955, -8.962319732999958],
              [-37.526927565999983, -8.961937868999939],
              [-37.527397679999979, -8.961287868999932],
              [-37.52751914199996, -8.961119928999949],
              [-37.528027777999966, -8.960416666999947],
              [-37.528277919999937, -8.960354523999968],
              [-37.528486361999967, -8.960302739999975],
              [-37.528611622999961, -8.960271620999947],
              [-37.529144120999945, -8.960139331999926],
              [-37.529325342999982, -8.960094310999978],
              [-37.530829209999979, -8.95997972999993],
              [-37.531065846999979, -8.959961700999941],
              [-37.532203838999976, -8.95990663699996],
              [-37.533121298999959, -8.959862242999975],
              [-37.534309711999981, -8.960040504999938],
              [-37.53716222099996, -8.960105606999946],
              [-37.53782894699998, -8.960144038999942],
              [-37.53790294099997, -8.960146020999957],
              [-37.539685483999961, -8.960193767999954],
              [-37.541142949999937, -8.960422783999945],
              [-37.541145891999975, -8.960423101999936],
              [-37.541292835999968, -8.960438974999931],
              [-37.545689055999958, -8.96091385699998],
              [-37.545714069999974, -8.960916558999941],
              [-37.545972221999932, -8.960944444999939],
              [-37.546518962999983, -8.961352351999949],
              [-37.546581733999972, -8.961399182999969],
              [-37.546665901999972, -8.961461978999978],
              [-37.546666039999934, -8.961462081999969],
              [-37.54679127299994, -8.96155551399994],
              [-37.546796478999966, -8.961559398999952],
              [-37.548388434999936, -8.962747109999952],
              [-37.548411338999983, -8.962764197999945],
              [-37.550009520999936, -8.964354905999926],
              [-37.550010183999973, -8.964355565999938],
              [-37.552186873999972, -8.966522076999979],
              [-37.55476164099997, -8.968832923999969],
              [-37.555944443999977, -8.970472222999945],
              [-37.556566982999982, -8.970710340999972],
              [-37.55656892899998, -8.970711084999948],
              [-37.559779053999932, -8.971938940999962],
              [-37.562980640999967, -8.971938940999962],
              [-37.569049320999966, -8.971938940999962],
              [-37.572442047999971, -8.972034510999947],
              [-37.577316105999955, -8.971317737999925],
              [-37.580094243999952, -8.970882070999949],
              [-37.583682073999967, -8.969979275999947],
              [-37.584833332999949, -8.970138888999941],
              [-37.587975058999973, -8.969571185999939],
              [-37.589868703999969, -8.969523881999976],
              [-37.592879828999969, -8.969156110999961],
              [-37.59575, -8.96880555599995],
              [-37.597051261999979, -8.969071847999942],
              [-37.598628162999944, -8.970600963999971],
              [-37.600348418999943, -8.972846853999954],
              [-37.601392003999933, -8.974412231999963],
              [-37.601973104999956, -8.975283882999975],
              [-37.604123424999955, -8.978103190999946],
              [-37.605472221999946, -8.980166666999935],
              [-37.605534432999946, -8.980285022999965],
              [-37.605535003999933, -8.980286109999952],
              [-37.608280708999985, -8.985509847999936],
              [-37.61016666699993, -8.987861110999972],
              [-37.611812303999955, -8.989013560999979],
              [-37.613632615999961, -8.990288335999935],
              [-37.614767337999979, -8.990836719999947],
              [-37.618430375999935, -8.992606981999927],
              [-37.618805555999984, -8.992972222999981],
              [-37.619045187999973, -8.993060940999953],
              [-37.620736879999981, -8.994310036999934],
              [-37.622518208999963, -8.995849462999956],
              [-37.622853132999978, -8.996128779999935],
              [-37.623128189999932, -8.996005284999967],
              [-37.623521127999936, -8.995926696999959],
              [-37.623880385999939, -8.995971603999976],
              [-37.624598900999956, -8.996179299999937],
              [-37.626586045999943, -8.997453542999949],
              [-37.627832220999949, -8.998318006999966],
              [-37.628288099999963, -8.998753624999949],
              [-37.628379880999944, -8.99883128099998],
              [-37.628507977999959, -8.998984636999978],
              [-37.628583332999938, -8.999138888999937],
              [-37.628544061999946, -8.999275110999974],
              [-37.628414785999951, -8.999530311999933],
              [-37.628376271999969, -8.999708115999965],
              [-37.628437560999942, -9.00002817799998],
              [-37.628546412999981, -9.00026361099998],
              [-37.628708242999949, -9.000451441999928],
              [-37.629252923999957, -9.000565349999931],
              [-37.629468586999963, -9.000668971999971],
              [-37.629721391999965, -9.000707945999977],
              [-37.630085852999969, -9.001006045999929],
              [-37.630899042999943, -9.001461094999968],
              [-37.631337237999958, -9.001593817999947],
              [-37.632051412999942, -9.001897183999972],
              [-37.632487501999947, -9.00219633699993],
              [-37.632637077999959, -9.002375406999931],
              [-37.632854838999947, -9.002430798999967],
              [-37.633050649999973, -9.002461278999931],
              [-37.633515240999941, -9.002625686999977],
              [-37.633822813999984, -9.00275869099994],
              [-37.633844980999982, -9.002761461999967],
              [-37.634059265999952, -9.002862138999944],
              [-37.63423568099995, -9.002898160999962],
              [-37.634420408999972, -9.002896313999941],
              [-37.634544176999952, -9.002906473999928],
              [-37.63468734099996, -9.002984982999976],
              [-37.634871145999966, -9.003064415999972],
              [-37.635081735999961, -9.003111521999926],
              [-37.635444725999946, -9.003081041999963],
              [-37.635655399999962, -9.003147102999947],
              [-37.635792938999941, -9.003147543999944],
              [-37.636000912999975, -9.003085816999942],
              [-37.636103226999978, -9.003066757999932],
              [-37.63616086899998, -9.003059772999961],
              [-37.636266933999934, -9.003059127999961],
              [-37.636507434999942, -9.003158744999951],
              [-37.637650650999944, -9.003762345999974],
              [-37.637666666999962, -9.003777777999971],
              [-37.638356873999953, -9.003802871999937],
              [-37.639043395999977, -9.003538824999964],
              [-37.639597893999962, -9.003089944999942],
              [-37.639941154999974, -9.002244993999966],
              [-37.640363630999957, -9.001479257999961],
              [-37.640654081999969, -9.001003973999957],
              [-37.640918128999942, -9.000607902999946],
              [-37.640970938999942, -9.000159022999981],
              [-37.64034210799997, -8.999033077999968],
              [-37.639976453999964, -8.997082925999962],
              [-37.639895197999977, -8.995132772999966],
              [-37.640463991999979, -8.994482722999976],
              [-37.641560952999953, -8.994523350999941],
              [-37.64245477299994, -8.994117068999969],
              [-37.643186079999964, -8.993020108999929],
              [-37.643836129999954, -8.993263877999937],
              [-37.644079898999962, -8.992776339999978],
              [-37.644851833999951, -8.992573198999935],
              [-37.645989422999946, -8.993507646999944],
              [-37.647167639999964, -8.993507646999944],
              [-37.648874022999962, -8.994482722999976],
              [-37.650417893999929, -8.994726491999927],
              [-37.652327416999981, -8.995010888999957],
              [-37.653668146999962, -8.994726491999927],
              [-37.654927619999967, -8.994279581999933],
              [-37.656105836999984, -8.993101364999973],
              [-37.657777777999968, -8.99266666699998],
              [-37.657784045999961, -8.992621901999939],
              [-37.65787912899998, -8.991942829999971],
              [-37.657592640999951, -8.991109409999979],
              [-37.657462418999955, -8.990328077999948],
              [-37.657794111999976, -8.989702256999976],
              [-37.657800995999935, -8.988452880999944],
              [-37.657706813999937, -8.988223312999935],
              [-37.657384285999967, -8.987437148999959],
              [-37.657540551999944, -8.986603728999967],
              [-37.658087483999964, -8.985796351999966],
              [-37.658966482999972, -8.985854951999954],
              [-37.659259481999982, -8.985874485999943],
              [-37.660944443999938, -8.986694444999955],
              [-37.661572683999964, -8.987310280999964],
              [-37.661647245999973, -8.987247966999973],
              [-37.661647932999983, -8.987248190999935],
              [-37.661648378999985, -8.987248335999936],
              [-37.666884214999982, -8.988950618999979],
              [-37.668922744999975, -8.990624923999974],
              [-37.674543936999953, -8.992980201999956],
              [-37.677678536999963, -8.994967194999958],
              [-37.678016450999962, -8.995145826999931],
              [-37.678021371999932, -8.995148427999936],
              [-37.682152836999933, -8.997332444999927],
              [-37.682269978999955, -8.99707258199993],
              [-37.682270117999963, -8.997072272999958],
              [-37.683523571999956, -8.99429166699997],
              [-37.683537322999939, -8.994261162999976],
              [-37.683670512999981, -8.99395420299993],
              [-37.683958255999983, -8.993291046999957],
              [-37.684000796999953, -8.993193005999956],
              [-37.684214701999963, -8.992641323999976],
              [-37.684428607999962, -8.992300923999949],
              [-37.684642516999929, -8.992148327999928],
              [-37.684951499999954, -8.992089632999978],
              [-37.685246371999938, -8.992025241999954],
              [-37.685519703999944, -8.991661351999937],
              [-37.68592375299994, -8.991121389999932],
              [-37.686102005999942, -8.990769246999946],
              [-37.686102001999984, -8.99054622899996],
              [-37.685995040999956, -8.990182359999949],
              [-37.68590803799998, -8.989885973999947],
              [-37.685993140999983, -8.989528704999941],
              [-37.686000232999959, -8.989514693999979],
              [-37.686134979999963, -8.98910138399998],
              [-37.686138525999979, -8.989094377999947],
              [-37.686142071999939, -8.989087372999961],
              [-37.686347743999931, -8.988786144999949],
              [-37.686365826999975, -8.988765977999947],
              [-37.686567599999933, -8.98854095799993],
              [-37.686858368999935, -8.988008558999979],
              [-37.686858367999946, -8.988001553999936],
              [-37.686858367999946, -8.98799454899995],
              [-37.686929284999962, -8.987805407999929],
              [-37.687071128999946, -8.987735352999948],
              [-37.687085312999955, -8.987735352999948],
              [-37.687241344999961, -8.98779839499997],
              [-37.687482485999965, -8.987952500999938],
              [-37.687515411999982, -8.987976295999943],
              [-37.687773273999937, -8.988162646999967],
              [-37.68808533899994, -8.988393805999976],
              [-37.68846831999997, -8.988456839999969],
              [-37.689042793999931, -8.988610932999961],
              [-37.689071162999937, -8.988617937999948],
              [-37.68942577599995, -8.988680971999941],
              [-37.689482517999977, -8.988793050999959],
              [-37.689546357999973, -8.989059243999975],
              [-37.69003573699996, -8.98941648899995],
              [-37.690052100999935, -8.989426419999972],
              [-37.690531979999946, -8.989717642999949],
              [-37.690871479999942, -8.989853567999944],
              [-37.691183449999983, -8.989944180999942],
              [-37.691220094999949, -8.990069758999937],
              [-37.691220156999975, -8.990069970999969],
              [-37.691220156999975, -8.990089178999938],
              [-37.691073356999937, -8.990243246999967],
              [-37.691057268999941, -8.990254722999964],
              [-37.690908201999946, -8.990361065999934],
              [-37.690981614999941, -8.990623874999926],
              [-37.691385344999958, -8.990859480999973],
              [-37.691706482999962, -8.990868527999964],
              [-37.691825756999947, -8.990723523999975],
              [-37.69194502199997, -8.990342896999948],
              [-37.691963338999983, -8.990261467999972],
              [-37.691963380999937, -8.990261282999938],
              [-37.692000063999956, -8.990098208999939],
              [-37.69215603899994, -8.990007578999951],
              [-37.692486341999938, -8.989889753999933],
              [-37.69275241899993, -8.989808180999944],
              [-37.692963451999958, -8.989898794999931],
              [-37.69314696999993, -8.990215969999952],
              [-37.693578202999959, -8.990270323999937],
              [-37.693743348999931, -8.990152505999959],
              [-37.69378921699996, -8.990007505999927],
              [-37.693807558999936, -8.989826257999937],
              [-37.693789207999941, -8.989817196999979],
              [-37.693878258999973, -8.989650733999952],
              [-37.694081750999942, -8.989560276999953],
              [-37.694285248999961, -8.989595441999938],
              [-37.694366652999975, -8.989716034999958],
              [-37.694352075999973, -8.98987929499998],
              [-37.69435139899997, -8.989886881999951],
              [-37.694366670999955, -8.990082851999944],
              [-37.694475100999966, -8.99019993099995],
              [-37.694475100999966, -8.990214735999928],
              [-37.694317741999953, -8.990636670999947],
              [-37.694310247999965, -8.990658877999977],
              [-37.694370211999967, -8.990851332999966],
              [-37.694669988999976, -8.990932742999973],
              [-37.694677620999983, -8.990932741999927],
              [-37.694699965999973, -8.990932740999938],
              [-37.694962264999958, -8.990903118999938],
              [-37.695014719999961, -8.990799484999968],
              [-37.694999724999946, -8.990666245999932],
              [-37.69506716799998, -8.990555208999979],
              [-37.695262019999973, -8.990562600999965],
              [-37.695736826999962, -8.99078973099995],
              [-37.695741668999972, -8.990792046999957],
              [-37.695997433999935, -8.990905053999938],
              [-37.696002824999937, -8.990905832999943],
              [-37.696458838999945, -8.990971723999962],
              [-37.69707780799996, -8.991182888999958],
              [-37.697741784999948, -8.991205089999937],
              [-37.697927554999978, -8.991097141999944],
              [-37.697933092999961, -8.991093923999927],
              [-37.698248170999932, -8.990671516999953],
              [-37.698411424999961, -8.990239287999941],
              [-37.698230191999983, -8.989791859999968],
              [-37.69796993999995, -8.989633693999963],
              [-37.697971772999949, -8.989633838999964],
              [-37.697973604999959, -8.989633984999955],
              [-37.697725566999964, -8.989458898999942],
              [-37.697667205999949, -8.989225451999971],
              [-37.698104918999945, -8.988204121999956],
              [-37.698484269999938, -8.987985264999963],
              [-37.698921343999984, -8.986970124999971],
              [-37.69892987999998, -8.986950300999979],
              [-37.698933226999941, -8.98694252699994],
              [-37.698936573999958, -8.986934753999947],
              [-37.698977325999977, -8.986879540999951],
              [-37.699388876999933, -8.986321954999937],
              [-37.699553019999939, -8.985566899999981],
              [-37.699559525999973, -8.985542101999954],
              [-37.699585661999947, -8.985442488999979],
              [-37.699743231999946, -8.984841938999978],
              [-37.699746342999958, -8.984830082999963],
              [-37.699848475999943, -8.984330359999944],
              [-37.699830555999938, -8.98405555599993],
              [-37.699622323999961, -8.984027608999952],
              [-37.699071534999973, -8.984220931999971],
              [-37.698734496999975, -8.984458998999969],
              [-37.698619127999962, -8.984540489999972],
              [-37.698611935999963, -8.984545568999977],
              [-37.698603378999962, -8.984550489999947],
              [-37.698206432999939, -8.984778733999974],
              [-37.697882414999981, -8.984965043999978],
              [-37.697515481999972, -8.984938209999939],
              [-37.69748220699995, -8.984935776999976],
              [-37.69714666699997, -8.984870182999941],
              [-37.696740486999943, -8.984575007999979],
              [-37.695968492999953, -8.983808059999944],
              [-37.695818629999962, -8.983498069999939],
              [-37.695784324999977, -8.983427108999933],
              [-37.695819644999972, -8.982700525999974],
              [-37.695703592999962, -8.982309482999938],
              [-37.695352915999933, -8.981890688999954],
              [-37.695297412999935, -8.981532443999981],
              [-37.694974487999957, -8.981323046999933],
              [-37.694611195999983, -8.981179243999975],
              [-37.694328635999966, -8.98114392399998],
              [-37.693853077999961, -8.981186811999976],
              [-37.693730718999973, -8.98135205899996],
              [-37.693552857999975, -8.98143026799994],
              [-37.693516276999958, -8.981431528999963],
              [-37.693485693999946, -8.981390468999962],
              [-37.69348150999997, -8.981385686999943],
              [-37.693364798999937, -8.981252302999962],
              [-37.693174820999957, -8.981162494999978],
              [-37.693067742999972, -8.981176311999945],
              [-37.692746506999981, -8.981328293999979],
              [-37.69263252099995, -8.981338656999981],
              [-37.692480850999971, -8.98126106899997],
              [-37.692342371999985, -8.981141769999965],
              [-37.692297467999936, -8.981013966999967],
              [-37.692183481999962, -8.980951791999928],
              [-37.69186915399996, -8.981031237999957],
              [-37.691738748999967, -8.981097443999943],
              [-37.691420115999961, -8.981259210999951],
              [-37.691185233999931, -8.981293752999932],
              [-37.690905447999967, -8.981387014999939],
              [-37.690772097999968, -8.981400717999975],
              [-37.690638747999969, -8.981414421999943],
              [-37.690533484999946, -8.981275496999956],
              [-37.690140649999933, -8.980651620999936],
              [-37.69001327899997, -8.98053136599998],
              [-37.689861873999973, -8.980566059999944],
              [-37.68960199299994, -8.980627426999945],
              [-37.689582780999956, -8.980621022999969],
              [-37.689434080999945, -8.980521210999939],
              [-37.689285686999938, -8.980443260999948],
              [-37.689263285999971, -8.980429610999977],
              [-37.689233400999967, -8.98043032299995],
              [-37.688969408999981, -8.980458073999955],
              [-37.688525639999966, -8.980356302999951],
              [-37.688250012999958, -8.98017273499994],
              [-37.688091206999957, -8.979973013999938],
              [-37.687981039999954, -8.979872452999928],
              [-37.687829475999933, -8.979863202999979],
              [-37.687687162999964, -8.97983402899996],
              [-37.687627589999977, -8.97974617999995],
              [-37.687445940999964, -8.979708080999956],
              [-37.687296298999968, -8.979690322999943],
              [-37.687221179999938, -8.979603647999966],
              [-37.687186509999947, -8.979482302999941],
              [-37.687240440999972, -8.979197237999927],
              [-37.687282815999936, -8.979083597999931],
              [-37.687308817999963, -8.978848611999979],
              [-37.687222142999929, -8.978734970999938],
              [-37.687078647999954, -8.978675261999967],
              [-37.687073832999943, -8.978367083999956],
              [-37.687023753999938, -8.978192769999964],
              [-37.686906127999976, -8.978083925999954],
              [-37.68687353699994, -8.978083480999942],
              [-37.68675972099993, -8.978107160999969],
              [-37.686605419999978, -8.978272154999956],
              [-37.686602841999957, -8.978273187999946],
              [-37.686405286999957, -8.978352360999963],
              [-37.686375393999981, -8.978355350999948],
              [-37.68637473299998, -8.978355416999932],
              [-37.686366929999963, -8.978351889999942],
              [-37.686157574999982, -8.978257274999976],
              [-37.685944827999947, -8.978099684999961],
              [-37.685901490999981, -8.977946034999945],
              [-37.685767538999983, -8.977768745999981],
              [-37.685227792999967, -8.977445685999953],
              [-37.685029816999929, -8.977251192999972],
              [-37.684972221999942, -8.977111110999942],
              [-37.684871596999983, -8.976968101999944],
              [-37.684776972999941, -8.976742458999979],
              [-37.68464598099996, -8.976504846999944],
              [-37.684345100999963, -8.976402271999973],
              [-37.683996992999937, -8.976374969999938],
              [-37.683819526999969, -8.976470528999926],
              [-37.683710316999964, -8.976647994999951],
              [-37.68333490599997, -8.976968799999952],
              [-37.683089182999936, -8.977043881999975],
              [-37.682857110999976, -8.977173569999934],
              [-37.682686469999965, -8.977166743999931],
              [-37.682495351999933, -8.977057533999925],
              [-37.682433921999973, -8.976859589999947],
              [-37.682311059999961, -8.976620692999973],
              [-37.682106290999968, -8.976572912999927],
              [-37.681744274999971, -8.976861184999962],
              [-37.68174099099997, -8.976863799999933],
              [-37.681737705999979, -8.97686641599995],
              [-37.681512459999965, -8.976982451999959],
              [-37.68150473299994, -8.976980417999926],
              [-37.68125308599997, -8.976914194999949],
              [-37.680782116999978, -8.977064358999939],
              [-37.680747370999939, -8.977076302999933],
              [-37.680563695999979, -8.977139440999963],
              [-37.680420357999935, -8.977098487999967],
              [-37.680072249999967, -8.97701657999994],
              [-37.679845238999974, -8.977018443999953],
              [-37.679826478999985, -8.977027102999955],
              [-37.67964223499996, -8.977112138999928],
              [-37.678775377999955, -8.977159917999927],
              [-37.67874364499994, -8.97715259499995],
              [-37.678509177999956, -8.977098487999967],
              [-37.678479738999954, -8.977087781999955],
              [-37.678283931999943, -8.97701657999994],
              [-37.67814741899997, -8.976777681999977],
              [-37.677840264999929, -8.976702599999953],
              [-37.677511027999969, -8.976674461999949],
              [-37.677473838999958, -8.97667770399994],
              [-37.677436648999958, -8.976680945999931],
              [-37.677109921999943, -8.976709425999957],
              [-37.677071336999973, -8.976698779999936],
              [-37.676889580999955, -8.976648628999953],
              [-37.676222588999963, -8.976265758999944],
              [-37.675635582999973, -8.976033687999973],
              [-37.675171439999929, -8.975965430999963],
              [-37.674825597999984, -8.975838541999963],
              [-37.674798057999965, -8.975832900999933],
              [-37.674298290999957, -8.975730528999975],
              [-37.673772183999972, -8.975528589999954],
              [-37.673533285999952, -8.975508112999933],
              [-37.672519412999975, -8.975083622999932],
              [-37.671916666999948, -8.9745],
              [-37.689416666999932, -8.955611110999939],
              [-37.689467903999969, -8.955461138999965],
              [-37.690028673999961, -8.953819744999976],
              [-37.690299122999932, -8.95267999899994],
              [-37.69018321599998, -8.952158419999932],
              [-37.689990039999941, -8.951675475999934],
              [-37.689700272999971, -8.951501615999973],
              [-37.689267918999974, -8.951456889999974],
              [-37.689105626999947, -8.951307387999975],
              [-37.689062787999944, -8.951037990999964],
              [-37.689179825999929, -8.950730280999949],
              [-37.689427156999955, -8.95033454999998],
              [-37.689517846999934, -8.949765686999967],
              [-37.689719590999971, -8.949492570999951],
              [-37.690047991999961, -8.949183486999971],
              [-37.69049229999996, -8.948797132999971],
              [-37.691632046999985, -8.948275553999963],
              [-37.692462709999973, -8.948178964999954],
              [-37.693100195999932, -8.947947151999927],
              [-37.69353771699997, -8.947409363999952],
              [-37.69356382199993, -8.947377278999966],
              [-37.693814951999968, -8.947077853999929],
              [-37.694336530999976, -8.946556274999978],
              [-37.694800156999975, -8.946401732999959],
              [-37.695341053999982, -8.946479003999968],
              [-37.695727407999982, -8.946556274999978],
              [-37.696036491999962, -8.946440367999969],
              [-37.696055809999962, -8.946189237999931],
              [-37.69582399799998, -8.945918788999961],
              [-37.695881950999933, -8.94566765899998],
              [-37.69621035199998, -8.945609704999981],
              [-37.69651943599996, -8.945629022999981],
              [-37.696712612999931, -8.945474480999962],
              [-37.696766605999983, -8.945231518999947],
              [-37.69678988499993, -8.945126761999973],
              [-37.697060331999978, -8.944894948999945],
              [-37.697581910999929, -8.944798359999936],
              [-37.698238715999935, -8.944798359999936],
              [-37.698605752999981, -8.944875630999945],
              [-37.698841665999964, -8.944722222999928],
              [-37.698730704999946, -8.944368028999975],
              [-37.698476293999931, -8.944100227999968],
              [-37.698529852999968, -8.943377165999948],
              [-37.698717313999964, -8.94312275599998],
              [-37.698797653999975, -8.942721053999946],
              [-37.698735180999961, -8.942184021999935],
              [-37.698731966999958, -8.942156398999941],
              [-37.698663754999984, -8.94131509999994],
              [-37.698864604999983, -8.941060688999926],
              [-37.698892700999977, -8.94106452099993],
              [-37.699159184999985, -8.941100859999949],
              [-37.699507326999935, -8.940966958999979],
              [-37.699816660999943, -8.940616631999944],
              [-37.69989563799993, -8.940284066999936],
              [-37.699668007999946, -8.939788635999946],
              [-37.699493935999953, -8.939159303999929],
              [-37.698931555999934, -8.938342510999973],
              [-37.698636974999943, -8.937766739999972],
              [-37.698730704999946, -8.937204357999974],
              [-37.699172574999977, -8.936695535999945],
              [-37.699259808999955, -8.936171625999975],
              [-37.69907901299996, -8.93591470399997],
              [-37.699027287999968, -8.93584120099996],
              [-37.699015049999957, -8.935523013999955],
              [-37.699044254999933, -8.935346927999944],
              [-37.699128697999981, -8.93483778999996],
              [-37.699149666999972, -8.934115647999931],
              [-37.699210856999969, -8.933626128999947],
              [-37.699529043999974, -8.933430321999936],
              [-37.699751160999938, -8.933281665999971],
              [-37.699786040999982, -8.932842899999969],
              [-37.69976156499996, -8.931863862999933],
              [-37.699932897999929, -8.930958253999961],
              [-37.70010422799993, -8.930737969999939],
              [-37.700373463999938, -8.930713494999964],
              [-37.70094864899994, -8.930627828999945],
              [-37.70121788299997, -8.930395307999959],
              [-37.701205644999959, -8.929918026999928],
              [-37.700960885999962, -8.929391794999958],
              [-37.700985361999983, -8.928975703999981],
              [-37.701279072999967, -8.928571850999958],
              [-37.701768590999961, -8.928302615999939],
              [-37.70205006499998, -8.92778862199998],
              [-37.70203044799996, -8.927715061999947],
              [-37.701952161999941, -8.927421482999932],
              [-37.702147967999963, -8.927078819999963],
              [-37.702588534999961, -8.926491397999939],
              [-37.702710913999965, -8.925842785999976],
              [-37.702980148999984, -8.925132983999958],
              [-37.703278351999984, -8.924685679999925],
              [-37.703396239999961, -8.924508847999959],
              [-37.703689951999934, -8.924092756999926],
              [-37.703775616999962, -8.923627714999952],
              [-37.70367771399998, -8.923040291999939],
              [-37.703714426999966, -8.922306014999947],
              [-37.703922471999931, -8.921926637999945],
              [-37.704316582999979, -8.921825583999976],
              [-37.704399752999961, -8.921804257999952],
              [-37.704705701999956, -8.921816495999963],
              [-37.704877033999935, -8.921620688999951],
              [-37.704955213999938, -8.921288284999946],
              [-37.70519522099994, -8.92077626899993],
              [-37.705611310999984, -8.920396892999975],
              [-37.705856069999982, -8.919993039999952],
              [-37.706162018999976, -8.919748280999954],
              [-37.706516920999945, -8.919711566999979],
              [-37.70700643999993, -8.919479044999946],
              [-37.707068710999977, -8.919406940999977],
              [-37.707238959999984, -8.919209809999927],
              [-37.707557146999932, -8.918818195999961],
              [-37.70793652499998, -8.918671339999946],
              [-37.708217996999963, -8.918255248999969],
              [-37.708169044999977, -8.91781468299996],
              [-37.708083378999959, -8.917276211999933],
              [-37.708413803999974, -8.916798931999949],
              [-37.708854370999973, -8.916419554999948],
              [-37.709239440999966, -8.915961632999938],
              [-37.709307175999982, -8.915881084999967],
              [-37.709784455999966, -8.915428279999958],
              [-37.710139357999935, -8.915220234999936],
              [-37.710494257999983, -8.915501707999965],
              [-37.710947063999981, -8.915587373999927],
              [-37.711485532999973, -8.915207996999982],
              [-37.712087232999977, -8.91467042599993],
              [-37.71237890499998, -8.91400867599998],
              [-37.712954088999936, -8.913751678999972],
              [-37.713857185999984, -8.912823722999974],
              [-37.713891428999943, -8.912788537999973],
              [-37.714917686999968, -8.912169243999926],
              [-37.71503402299993, -8.912099040999976],
              [-37.716176617999963, -8.911320893999971],
              [-37.716747915999974, -8.911094344999981],
              [-37.71698431599998, -8.910848095999938],
              [-37.716915364999977, -8.910670796999966],
              [-37.716728215999979, -8.910493497999937],
              [-37.716393316999984, -8.910188148999964],
              [-37.716147068999931, -8.909971449999944],
              [-37.716156917999967, -8.909744900999954],
              [-37.716344068999945, -8.909666100999971],
              [-37.716678965999961, -8.909616850999953],
              [-37.717230563999976, -8.909784300999945],
              [-37.717604861999973, -8.90975475099998],
              [-37.71797915999997, -8.90944940199995],
              [-37.718511058999979, -8.90891750399993],
              [-37.718954306999933, -8.908375756999931],
              [-37.719387703999985, -8.90809995799998],
              [-37.719525603999955, -8.907735509999952],
              [-37.719555153999977, -8.907513885999947],
              [-37.719584704999932, -8.907292261999942],
              [-37.719732453999939, -8.906632313999978],
              [-37.72008705199994, -8.90591326699996],
              [-37.720168503999957, -8.90546980299996],
              [-37.72017570099996, -8.905430619999947],
              [-37.720215722999967, -8.90528438399997],
              [-37.720431799999972, -8.904494873999965],
              [-37.72063286499997, -8.903441676999932],
              [-37.720638648999966, -8.90341137799993],
              [-37.72089447899998, -8.902200622999942],
              [-37.721293609999975, -8.900311660999932],
              [-37.721633495999981, -8.898703098999931],
              [-37.721505263999973, -8.898089421999941],
              [-37.721495594999965, -8.898043151999957],
              [-37.721387246999939, -8.897363503999941],
              [-37.72137739599998, -8.897068005999927],
              [-37.72152514499993, -8.896959655999979],
              [-37.721860043999982, -8.896979355999974],
              [-37.722451041999932, -8.897038455999962],
              [-37.722526478999953, -8.89703385599995],
              [-37.72325873799997, -8.896989205999944],
              [-37.723719385999971, -8.896860223999965],
              [-37.723751235999941, -8.896851306999963],
              [-37.724214182999958, -8.896644457999969],
              [-37.724716531999945, -8.896339108999939],
              [-37.72514992999993, -8.896043609999936],
              [-37.725662126999964, -8.895531411999968],
              [-37.726065974999983, -8.895078313999932],
              [-37.726243275999934, -8.894654765999974],
              [-37.726538773999948, -8.894260767999981],
              [-37.72657817399994, -8.893758419999926],
              [-37.726676672999929, -8.893167422999966],
              [-37.726893372999939, -8.892586274999928],
              [-37.727218419999929, -8.892152876999944],
              [-37.727257819999977, -8.891758878999951],
              [-37.72745780899993, -8.891528638999944],
              [-37.727720767999983, -8.891039831999933],
              [-37.728075366999974, -8.891049681999959],
              [-37.728361016999941, -8.891236830999958],
              [-37.728883063999945, -8.891315630999941],
              [-37.72921796299994, -8.89118758099994],
              [-37.729720310999937, -8.891138330999979],
              [-37.730124157999967, -8.890990581999972],
              [-37.730537855999955, -8.891029981999964],
              [-37.730774254999972, -8.890882232999957],
              [-37.730833354999959, -8.890596583999979],
              [-37.730665906999945, -8.890389734999928],
              [-37.730764405999935, -8.890054835999933],
              [-37.730951554999933, -8.889729787999954],
              [-37.731158403999984, -8.889020590999962],
              [-37.73117810399998, -8.88832124399994],
              [-37.731089453999971, -8.887966644999949],
              [-37.730803805999983, -8.887769645999981],
              [-37.730232506999982, -8.887355947999936],
              [-37.729740010999933, -8.887149098999942],
              [-37.729316461999929, -8.886774799999955],
              [-37.728971712999964, -8.886390651999932],
              [-37.729030813999941, -8.885750404999953],
              [-37.729149012999983, -8.885021507999966],
              [-37.729375560999983, -8.884361560999935],
              [-37.729907459999936, -8.88348491499994],
              [-37.730202958999939, -8.882716617999961],
              [-37.730665906999945, -8.882184719999941],
              [-37.730920814999934, -8.881627674999947],
              [-37.731453902999931, -8.880579176999959],
              [-37.732005500999946, -8.879663130999973],
              [-37.732222198999978, -8.87949568199997],
              [-37.732429047999972, -8.879564630999937],
              [-37.732478297999933, -8.87981087999998],
              [-37.73231084899993, -8.880066978999935],
              [-37.732369947999985, -8.88040187799993],
              [-37.732606347999933, -8.880687526999964],
              [-37.733108695999931, -8.880904225999927],
              [-37.734182339999961, -8.88092392599998],
              [-37.735206736999942, -8.880776175999927],
              [-37.735709084999939, -8.880510226999945],
              [-37.736122782999985, -8.880145778999974],
              [-37.736250831999939, -8.879761630999951],
              [-37.736536479999984, -8.879643430999977],
              [-37.73704867899994, -8.879712380999933],
              [-37.737767724999969, -8.87986012999994],
              [-37.73812232299997, -8.879850279999971],
              [-37.738299622999932, -8.879584330999933],
              [-37.738319322999985, -8.879229732999931],
              [-37.738496621999957, -8.878559935999931],
              [-37.738900469999976, -8.87828413699998],
              [-37.739255067999977, -8.877998487999946],
              [-37.739452068999981, -8.877515839999944],
              [-37.739738830999954, -8.876925514999925],
              [-37.739946497999938, -8.876581259999966],
              [-37.740190814999949, -8.876176245999943],
              [-37.740476463999983, -8.876038346999962],
              [-37.740949260999969, -8.876156545999947],
              [-37.741431908999971, -8.87623534599993],
              [-37.74166830799993, -8.876077746999954],
              [-37.741697858999942, -8.875742847999959],
              [-37.741731573999971, -8.875410527999975],
              [-37.74206230599998, -8.874876051999934],
              [-37.742219906999935, -8.874472203999971],
              [-37.742190355999981, -8.874176704999968],
              [-37.742121405999967, -8.873733456999958],
              [-37.74222091799993, -8.873280973999954],
              [-37.742436604999966, -8.87292575999993],
              [-37.742584353999973, -8.872236262999934],
              [-37.742899552999972, -8.871389166999961],
              [-37.743007901999931, -8.870945918999951],
              [-37.742998051999962, -8.870384470999966],
              [-37.742919252999968, -8.86960632499995],
              [-37.742850302999955, -8.86915322699997],
              [-37.742554804999941, -8.868818327999975],
              [-37.742357804999983, -8.868414479999956],
              [-37.742367654999953, -8.867981081999972],
              [-37.742578274999971, -8.867853890999982],
              [-37.742909403999931, -8.867892431999962],
              [-37.74332310099993, -8.868050030999939],
              [-37.743667905999985, -8.86791442599997],
              [-37.744313612999974, -8.86728889699998],
              [-37.74449521899993, -8.86668354699998],
              [-37.744333790999974, -8.865654450999955],
              [-37.744010936999985, -8.864847317999931],
              [-37.744333790999974, -8.86365679599993],
              [-37.745342707999953, -8.86236538199995],
              [-37.74575724999994, -8.860398485999951],
              [-37.746492872999966, -8.859076311999956],
              [-37.748329101999957, -8.858854349999945],
              [-37.750225866999983, -8.858370069999978],
              [-37.750124975999938, -8.856957585999965],
              [-37.751113713999985, -8.855948668999929],
              [-37.752223522999941, -8.853164057999948],
              [-37.752406469999983, -8.852823398999931],
              [-37.752808694999942, -8.85207442799998],
              [-37.754362426999933, -8.850641764999978],
              [-37.754685280999979, -8.848825714999975],
              [-37.755361889999961, -8.846363685999961],
              [-37.755488105999973, -8.846384126999965],
              [-37.757052725999984, -8.847066476999942],
              [-37.758449347999942, -8.847284646999981],
              [-37.759417692999932, -8.847815784999966],
              [-37.760300554999958, -8.848021637999977],
              [-37.760999993999974, -8.84808456899998],
              [-37.761584514999981, -8.849022093999963],
              [-37.762039109999932, -8.849540920999971],
              [-37.762965763999944, -8.850375844999974],
              [-37.763705190999929, -8.850716810999927],
              [-37.764150788999984, -8.851604865999946],
              [-37.764591860999928, -8.852677516999961],
              [-37.76526307599994, -8.853894203999971],
              [-37.765903412999933, -8.854463661999944],
              [-37.767548344999966, -8.854087453999966],
              [-37.769530384999939, -8.853303722999954],
              [-37.771130838999966, -8.852834086999962],
              [-37.772626549999984, -8.852823698999941],
              [-37.773649551999938, -8.853032876999976],
              [-37.774472163999974, -8.853791374999957],
              [-37.77525146499994, -8.854410304999931],
              [-37.776591623999934, -8.856249475999959],
              [-37.778289331999929, -8.857536919999973],
              [-37.778737213999932, -8.858332663999931],
              [-37.779137252999931, -8.859173433999956],
              [-37.780203621999931, -8.859522181999978],
              [-37.780297034999933, -8.859524415999942],
              [-37.781651541999963, -8.859556805999944],
              [-37.783610201999977, -8.859557261999953],
              [-37.784828043999937, -8.859447854999928],
              [-37.78552192099994, -8.859741516999975],
              [-37.786198872999933, -8.86072743699998],
              [-37.786636622999936, -8.86193850899997],
              [-37.786726655999985, -8.862079191999953],
              [-37.786925215999929, -8.863515421999978],
              [-37.786532355999952, -8.86429104299998],
              [-37.786134979999929, -8.865251264999927],
              [-37.786439365999968, -8.866182069999979],
              [-37.78746019099998, -8.866483508999977],
              [-37.788950347999958, -8.866703797999946],
              [-37.790016736999974, -8.867052491999971],
              [-37.791347567999935, -8.868053962999966],
              [-37.791850071999932, -8.868527718999928],
              [-37.792297964999932, -8.869323404999932],
              [-37.792031693999945, -8.870656169999961],
              [-37.792141342999969, -8.87190555799998],
              [-37.792933567999967, -8.873910077999938],
              [-37.79470286399993, -8.874993098999937],
              [-37.796601184999929, -8.87573105599995],
              [-37.79841506799994, -8.876097562999973],
              [-37.800902008999969, -8.875695098999927],
              [-37.802205384999979, -8.875910881999971],
              [-37.803546445999984, -8.876496962999965],
              [-37.804377064999983, -8.876932347999968],
              [-37.806286497999963, -8.877208732999975],
              [-37.80829859499994, -8.877118248999977],
              [-37.809747928999968, -8.877106582999943],
              [-37.811405995999962, -8.878115802999957],
              [-37.813415221999946, -8.880056914999955],
              [-37.814050060999932, -8.880857039999967],
              [-37.816765554999961, -8.882584022999936],
              [-37.818995326999982, -8.883145],
              [-37.821824431999971, -8.884043469999938],
              [-37.82330454199996, -8.884678976999965],
              [-37.82462878299998, -8.885957270999938],
              [-37.825640159999978, -8.888567339999952],
              [-37.82538197599996, -8.889855163999925],
              [-37.824832173999937, -8.891319725999949],
              [-37.824066276999929, -8.893979739999963],
              [-37.82346747899993, -8.895535514999949],
              [-37.823174824999967, -8.896036505999973],
              [-37.822960290999958, -8.896761523999942],
              [-37.822434914999974, -8.89687762799997],
              [-37.822338374999958, -8.89687762799997],
              [-37.822290104999979, -8.896974167999929],
              [-37.822290104999979, -8.897070707999944],
              [-37.822290104999979, -8.897215517999939],
              [-37.822048754999969, -8.897360327999934],
              [-37.822000484999933, -8.897505137999929],
              [-37.821903944999974, -8.89764994799998],
              [-37.821807404999959, -8.89764994799998],
              [-37.821807404999959, -8.898036107999928],
              [-37.821710865999933, -8.898084377999965],
              [-37.821662593999974, -8.898132647999944],
              [-37.821566053999959, -8.898229187999959],
              [-37.82151778399998, -8.898229187999959],
              [-37.821324704999938, -8.898277457999939],
              [-37.82122816499998, -8.898373997999954],
              [-37.821208996999985, -8.898450672999957],
              [-37.821179894999943, -8.898567077999928],
              [-37.821083354999985, -8.89871188799998],
              [-37.821083354999985, -8.898808427999938],
              [-37.82098681399998, -8.898953237999933],
              [-37.820890273999964, -8.899098047999928],
              [-37.820890273999964, -8.899194587999943],
              [-37.82074546399997, -8.89924285799998],
              [-37.82074546399997, -8.899339397999938],
              [-37.82074546399997, -8.899532477999969],
              [-37.820504113999959, -8.899677287999964],
              [-37.820504113999959, -8.899918637999974],
              [-37.820359303999965, -8.900208257999964],
              [-37.820359303999965, -8.900497877999953],
              [-37.820359303999965, -8.900690957999927],
              [-37.820359303999965, -8.901028847999953],
              [-37.820407574999933, -8.901318467999943],
              [-37.820407574999933, -8.901463278999927],
              [-37.820359303999965, -8.901463278999927],
              [-37.820359303999965, -8.901656358999958],
              [-37.82045584399998, -8.901801168999953],
              [-37.820552384999985, -8.901945978999947],
              [-37.820600653999975, -8.902090788999942],
              [-37.82069719499998, -8.902187328999958],
              [-37.82069719499998, -8.902235598999937],
              [-37.82069719499998, -8.902283868999973],
              [-37.82085357099993, -8.902508459999979],
              [-37.820938544999933, -8.902621758999942],
              [-37.820938544999933, -8.902863108999952],
              [-37.820938544999933, -8.903007918999947],
              [-37.820938544999933, -8.903152728999942],
              [-37.820890273999964, -8.903490618999967],
              [-37.820793734999938, -8.903780238999957],
              [-37.820793734999938, -8.904021588999967],
              [-37.820793734999938, -8.904359478999936],
              [-37.820793734999938, -8.904552558999967],
              [-37.820648924999944, -8.904745638999941],
              [-37.820600653999975, -8.904986988999951],
              [-37.820600653999975, -8.905373148999956],
              [-37.820552384999985, -8.905517958999951],
              [-37.820552384999985, -8.905614498999967],
              [-37.820648924999944, -8.905855848999977],
              [-37.820648924999944, -8.906145468999966],
              [-37.820648924999944, -8.906483358999935],
              [-37.820648924999944, -8.906676438999966],
              [-37.820552384999985, -8.906821248999961],
              [-37.820552384999985, -8.907014328999935],
              [-37.82045584399998, -8.907207408999966],
              [-37.820359303999965, -8.907497028999956],
              [-37.820359303999965, -8.907738379999955],
              [-37.820359303999965, -8.907979729999965],
              [-37.820359303999965, -8.908269349999955],
              [-37.820359303999965, -8.908462429999929],
              [-37.820359303999965, -8.908703779999939],
              [-37.82045584399998, -8.90889685999997],
              [-37.820600653999975, -8.908993399999929],
              [-37.82069719499998, -8.909041669999965],
              [-37.820793734999938, -8.909089939999944],
              [-37.820890273999964, -8.909331289999955],
              [-37.82098681399998, -8.909524369999929],
              [-37.821131623999975, -8.909813989999975],
              [-37.821324704999938, -8.910151879999944],
              [-37.821759134999979, -8.910538039999949],
              [-37.821903944999974, -8.910682849999944],
              [-37.822048754999969, -8.910779389999959],
              [-37.822290104999979, -8.910972469999933],
              [-37.822358142999974, -8.911074527999972],
              [-37.822358200999929, -8.911074612999926],
              [-37.822386645999984, -8.911117279999928],
              [-37.822483185999943, -8.91126208999998],
              [-37.822579724999969, -8.911406899999974],
              [-37.822772805999932, -8.911648249999928],
              [-37.822869344999958, -8.911744789999943],
              [-37.822917615999984, -8.911841329999959],
              [-37.823207234999984, -8.912082679999969],
              [-37.823255504999963, -8.912179219999928],
              [-37.823303775999932, -8.912275759999943],
              [-37.823448585999984, -8.912517109999953],
              [-37.823641664999968, -8.912565379999933],
              [-37.823738204999984, -8.912758459999964],
              [-37.823883014999979, -8.912951539999938],
              [-37.823979555999983, -8.913096349999932],
              [-37.824076095999942, -8.913337699999943],
              [-37.824124364999932, -8.913434239999958],
              [-37.824317444999963, -8.913627319999932],
              [-37.824462254999958, -8.913675589999968],
              [-37.824592352999957, -8.913805687999968],
              [-37.824607065999942, -8.913820399999963],
              [-37.824751875999937, -8.913916939999979],
              [-37.824751875999937, -8.913965209999958],
              [-37.824944954999978, -8.914061749999973],
              [-37.825138035999942, -8.914206560999958],
              [-37.825379384999962, -8.914447910999968],
              [-37.825572465999983, -8.914592720999963],
              [-37.825813815999936, -8.914785800999937],
              [-37.825958625999931, -8.914882340999952],
              [-37.826103435999983, -8.915027150999947],
              [-37.826103435999983, -8.915171960999942],
              [-37.826151704999972, -8.915268500999957],
              [-37.826344785999936, -8.915413310999952],
              [-37.826441324999962, -8.915509850999968],
              [-37.826586134999957, -8.915558120999947],
              [-37.826779214999931, -8.915606390999926],
              [-37.826803349999977, -8.915678795999952],
              [-37.826827484999967, -8.915751200999978],
              [-37.826819688999933, -8.915931927999964],
              [-37.826797367999973, -8.91602764199996],
              [-37.826864328999932, -8.916150401999971],
              [-37.826897808999945, -8.916250840999965],
              [-37.826931287999969, -8.916351279999958],
              [-37.827020566999977, -8.916418239999928],
              [-37.827098686999932, -8.916485199999954],
              [-37.827154486999973, -8.916574478999962],
              [-37.827187966999929, -8.916708398999958],
              [-37.827210285999968, -8.916842317999965],
              [-37.827243765999981, -8.916953916999944],
              [-37.827247704999934, -8.917106534999959],
              [-37.827279746999977, -8.917137922999927],
              [-37.827439635999951, -8.917294544999947],
              [-37.827419293999981, -8.917482674999974],
              [-37.827906612999982, -8.917509261999953],
              [-37.829488546999983, -8.919052773999965],
              [-37.830102346999979, -8.921232132999933],
              [-37.829335097999945, -8.922393295999939],
              [-37.832321049999962, -8.924558103999971],
              [-37.833637041999964, -8.92641176799998],
              [-37.832143882999958, -8.92825566199997],
              [-37.826233658999968, -8.932716848999974],
              [-37.825333839999985, -8.932428261999974],
              [-37.825998288999983, -8.934237176999943],
              [-37.826001056999985, -8.934244715999967],
              [-37.827313286999981, -8.937819615999956],
              [-37.831515669999931, -8.952943198999947],
              [-37.834204624999984, -8.959841893999965],
              [-37.83909814499998, -8.97838104799996],
              [-37.846007951999979, -8.984771041999977],
              [-37.848163631999967, -8.988311632999967],
              [-37.848166744999958, -8.988315289999946],
              [-37.850320221999937, -8.991852261999952],
              [-37.85178025099998, -8.996214466999959],
              [-37.852276357999983, -8.997696709999957],
              [-37.853093685999966, -9.00013964599998],
              [-37.863802741999962, -9.008660457999952],
              [-37.865693667999949, -9.010337551999953],
              [-37.86715228199995, -9.011631220999959],
              [-37.872542038999939, -9.011841365999942],
              [-37.874486206999961, -9.011515008999936],
              [-37.877617950999934, -9.013286551999954],
              [-37.879597647999958, -9.015413842999976],
              [-37.879457601999945, -9.017090133999943],
              [-37.878527523999935, -9.018962172999977],
              [-37.875599243999943, -9.022669776999976],
              [-37.875228041999947, -9.024622000999955],
              [-37.877208612999937, -9.031100830999947],
              [-37.877569827999935, -9.035376051999947],
              [-37.87826156999995, -9.036955979999959],
              [-37.880147678999947, -9.039305337999963],
              [-37.880146888999946, -9.039305446999947],
              [-37.880982843999959, -9.040346721999981],
              [-37.88270730499994, -9.04066363499993],
              [-37.882707477999929, -9.040663538999979],
              [-37.882708212999944, -9.040663672999926],
              [-37.883791173999953, -9.040055247999931],
              [-37.885566041999937, -9.037292020999928],
              [-37.887706553999976, -9.035073890999968],
              [-37.889767741999947, -9.034794266999938],
              [-37.891373241999929, -9.035323881999943],
              [-37.892822923999972, -9.036874944999965],
              [-37.89631738199995, -9.037867770999981],
              [-37.900285362999966, -9.038279450999937],
              [-37.904932236999969, -9.04023579699998],
              [-37.905735326999945, -9.040992889999927],
              [-37.906538196999975, -9.042953205999936],
              [-37.907093576999955, -9.05115795599994],
              [-37.907875535999949, -9.052218277999941],
              [-37.912375922999956, -9.055930466999939],
              [-37.917286046999948, -9.060799784999972],
              [-37.918459806999977, -9.061414957999943],
              [-37.920291473999953, -9.063793672999964],
              [-37.92979128099995, -9.071279990999926],
              [-37.930638219999935, -9.07232446699993],
              [-37.930639174999953, -9.072324563999928],
              [-37.935704447999967, -9.078571232999934],
              [-37.937677146999931, -9.080115274999969],
              [-37.939139051999973, -9.083253292999927],
              [-37.939447430999962, -9.083917306999979],
              [-37.942544423999948, -9.088116363999973],
              [-37.942731694999964, -9.089178731999937],
              [-37.942128969999942, -9.09023287499997],
              [-37.942166141999962, -9.090365727999938],
              [-37.942684657999962, -9.092218895999963],
              [-37.944564252999953, -9.092732578999971],
              [-37.947768081999982, -9.095769486999927],
              [-37.949222995999946, -9.096157759999926],
              [-37.94922288999993, -9.09615793599994],
              [-37.950644085999954, -9.096537209999951],
              [-37.951595830999963, -9.097309601999939],
              [-37.951640568999949, -9.097426639999981],
              [-37.951952048999942, -9.098241492999932],
              [-37.951764038999954, -9.099783858999956],
              [-37.953374505999932, -9.103374671999973],
              [-37.953267493999931, -9.106130962999941],
              [-37.954304113999967, -9.109524929999964],
              [-37.95430330399995, -9.109525747999953],
              [-37.954849582999941, -9.111314583999956],
              [-37.957567768999979, -9.115629736999949],
              [-37.961582401999976, -9.120129920999943],
              [-37.96233775099995, -9.120577726999954],
              [-37.968790569999953, -9.122704748999979],
              [-37.969618234999984, -9.123266968999928],
              [-37.969618071999946, -9.123267436999981],
              [-37.971199823999939, -9.124341895999976],
              [-37.971459409999966, -9.125692499999957],
              [-37.971038759999942, -9.128274463999958],
              [-37.971757928999978, -9.131041711999956],
              [-37.975540530999979, -9.134942415999944],
              [-37.976201858999957, -9.136189869999953],
              [-37.977294447999952, -9.141595673999973],
              [-37.978038093999942, -9.143231897999954],
              [-37.978138100999956, -9.146093209999947],
              [-37.978563344999941, -9.147661862999939],
              [-37.979351708999957, -9.148363062999977],
              [-37.981340068999941, -9.149289671999952],
              [-37.984652164999943, -9.14978976499998],
              [-37.98551422099996, -9.151623727999947],
              [-37.987539721999951, -9.15368592599998],
              [-37.990985737999949, -9.155376301999979],
              [-37.990986540999984, -9.155376288999946],
              [-37.990986646999943, -9.155376340999965],
              [-37.993658896999932, -9.155333206999956],
              [-37.999482366999985, -9.152243625999972],
              [-38.00537471399997, -9.154436190999945],
              [-38.006653601999972, -9.15415324099996],
              [-38.00904350199994, -9.154865075999965],
              [-38.01101200599993, -9.156027972999937],
              [-38.011012868999956, -9.15602798599997],
              [-38.011012914999981, -9.156028012999968],
              [-38.01210724699996, -9.156043096999952],
              [-38.014925682999944, -9.156081946999961],
              [-38.020784, -9.155215165999948],
              [-38.025787031999982, -9.155506217999971],
              [-38.027342831999931, -9.156573996999953],
              [-38.029210709999973, -9.158722115999979],
              [-38.029510007999932, -9.164513065999927],
              [-38.030291526999974, -9.164948193999976],
              [-38.030292301999964, -9.164948157999959],
              [-38.030292435999968, -9.164948231999972],
              [-38.032154602999981, -9.164860956999973],
              [-38.044540736999977, -9.168815452999979],
              [-38.044541645999971, -9.168815495999979],
              [-38.04617675999998, -9.168908526999928],
              [-38.052744995999944, -9.16781876899995],
              [-38.053503943999942, -9.167693195999959],
              [-38.056980679999981, -9.167625500999975],
              [-38.056980740999961, -9.167625556999951],
              [-38.058786765999969, -9.16927598999996],
              [-38.060536678999938, -9.17151701399996],
              [-38.063904004999983, -9.17378056299998],
              [-38.066097989999946, -9.174219911999955],
              [-38.074928683999929, -9.171317159999944],
              [-38.079263348999973, -9.170533192999926],
              [-38.082566252999982, -9.170585254999935],
              [-38.092287330999966, -9.172724977999962],
              [-38.095156308999947, -9.175044673999935],
              [-38.095156814999939, -9.175045082999929],
              [-38.097817789999965, -9.178699360999929],
              [-38.098678941999935, -9.179307089999952],
              [-38.102578763999929, -9.180207653999958],
              [-38.104693318999978, -9.184598478999931],
              [-38.114054641999985, -9.194001140999944],
              [-38.115369968999971, -9.198360087999959],
              [-38.117859901999964, -9.200019097999927],
              [-38.11924746699998, -9.201803131999952],
              [-38.122343641999976, -9.203749579999965],
              [-38.128357965999953, -9.209105515999966],
              [-38.135238041999969, -9.213955913999939],
              [-38.13961523699993, -9.215300166999953],
              [-38.140658902999974, -9.216280909999966],
              [-38.140938324999979, -9.216543485999978],
              [-38.143908299999964, -9.218204800999956],
              [-38.144989803999977, -9.221164082999962],
              [-38.14597630399993, -9.22243763299997],
              [-38.147095005999972, -9.225956017999977],
              [-38.148626945999979, -9.230774062999956],
              [-38.150230848999968, -9.232581528999958],
              [-38.152245941999979, -9.234852372999967],
              [-38.152977078999982, -9.236205006999967],
              [-38.154166815999929, -9.238406071999975],
              [-38.156306059999963, -9.242363767999962],
              [-38.156365720999929, -9.249126715999978],
              [-38.156367968999973, -9.249385451999956],
              [-38.156371284999977, -9.249762197999928],
              [-38.156373994999967, -9.250070176999941],
              [-38.156373944999984, -9.25007020399994],
              [-38.156419480999944, -9.255239558999961],
              [-38.157416455999964, -9.260171386999957],
              [-38.157377789999941, -9.262619211999947],
              [-38.157982653999966, -9.263829618999978],
              [-38.158473954999977, -9.26448746799997],
              [-38.158965255999931, -9.265145317999952],
              [-38.160740930999964, -9.267522937999956],
              [-38.165816715999938, -9.271318692999955],
              [-38.173559215999944, -9.285137776999932],
              [-38.177007035999964, -9.288653594999971],
              [-38.177008031999947, -9.288653385999964],
              [-38.179554347999954, -9.291250255999955],
              [-38.180484331999935, -9.29160311399994],
              [-38.180679035999958, -9.291676924999933],
              [-38.191698542999973, -9.295858048999946],
              [-38.202032388999953, -9.296805276999976],
              [-38.202751340999953, -9.296871281999927],
              [-38.203081194999982, -9.29690151899996],
              [-38.220576327999936, -9.312456378999968],
              [-38.237588934999962, -9.329793436999978],
              [-38.237588928999969, -9.329793443999961],
              [-38.237588924999955, -9.329793449999954],
              [-38.237589803999981, -9.329794345999971],
              [-38.239541246999977, -9.327128871999946],
              [-38.240443907999975, -9.324937341999942],
              [-38.241346569999962, -9.322745810999947],
              [-38.247465653999939, -9.302862209999944],
              [-38.248591549999958, -9.299203677999969],
              [-38.249226188999955, -9.296946878999961],
              [-38.250545641999963, -9.290480556999967],
              [-38.253486623999947, -9.285544850999941],
              [-38.257428282999967, -9.277232714999968],
              [-38.257787791999931, -9.276474585999949],
              [-38.258912473999942, -9.274102865999964],
              [-38.259203562999971, -9.273489018999953],
              [-38.259275574999947, -9.27333720799993],
              [-38.259323485999971, -9.273241802999962],
              [-38.259470610999983, -9.272948825999947],
              [-38.259512050999945, -9.272866305999969],
              [-38.259530497999947, -9.272829569999942],
              [-38.25954631899998, -9.272798064999961],
              [-38.259578099999942, -9.272734778999961],
              [-38.270175664999954, -9.251631420999956],
              [-38.272581852999963, -9.246839880999971],
              [-38.284157510999933, -9.22378880499997],
              [-38.285574851999968, -9.219309975999977],
              [-38.28751275999997, -9.21712929499995],
              [-38.286589152999966, -9.214094058999933],
              [-38.280075619999934, -9.207218950999959],
              [-38.280075611999962, -9.20721894299993],
              [-38.280075886999953, -9.20711287499995],
              [-38.280075892999946, -9.207110632999957],
              [-38.280077122999955, -9.206636729999957],
              [-38.280077639999945, -9.206437828999981],
              [-38.280077852999966, -9.206355560999953],
              [-38.280078038999932, -9.206284050999955],
              [-38.280078103999983, -9.206258993999938],
              [-38.280078163999974, -9.206231271999968],
              [-38.280078327999945, -9.206155785999954],
              [-38.280079385999954, -9.205668211999978],
              [-38.280079867999973, -9.205446169999959],
              [-38.280086710999967, -9.202291541999955],
              [-38.280089240999985, -9.201125610999952],
              [-38.281488844999956, -9.194851778999976],
              [-38.283943307999948, -9.190141516999972],
              [-38.28314495199993, -9.188762148999956],
              [-38.284237358999974, -9.186375573999953],
              [-38.284211057999983, -9.185274321999941],
              [-38.284184755999945, -9.184173071999965],
              [-38.285517856999945, -9.179257618999941],
              [-38.287600983999937, -9.173883921999959],
              [-38.288707582999962, -9.17212623599994],
              [-38.298659593999957, -9.16389081899996],
              [-38.301293750999946, -9.160787242999959],
              [-38.304073707999976, -9.155512777999945],
              [-38.305924416999972, -9.153462977999936],
              [-38.312873327999966, -9.148366726999939],
              [-38.316321899999934, -9.14451543499996],
              [-38.318658748999951, -9.141001716999938],
              [-38.319493380999972, -9.138229251999974],
              [-38.320068162999974, -9.133410313999946],
              [-38.321295253999949, -9.129903447999936],
              [-38.321295178999947, -9.129902902999959],
              [-38.321012712999959, -9.127855181999962],
              [-38.321401830999946, -9.116880573999936],
              [-38.320736402999955, -9.115491166999959],
              [-38.317731499999979, -9.111824874999968],
              [-38.316472805999979, -9.108795996999959],
              [-38.314962642999944, -9.107237106999946],
              [-38.312789541999962, -9.105869482999935],
              [-38.30612386699994, -9.101674487999958],
              [-38.304792636999935, -9.099392548999958],
              [-38.304422980999959, -9.098211226999979],
              [-38.304593061999981, -9.096787562999964],
              [-38.306054437999933, -9.095656826999971],
              [-38.307515812999952, -9.094526090999977],
              [-38.310042326999962, -9.093959765999955],
              [-38.314952937999976, -9.094099881999966],
              [-38.317761415999939, -9.09300923099994],
              [-38.318860935999965, -9.092253117999974],
              [-38.320260993999966, -9.091290327999957],
              [-38.323287267999945, -9.087559336999959],
              [-38.32540625799993, -9.083364655999958],
              [-38.326185462999945, -9.080956263999951],
              [-38.325866714999961, -9.077992613999982],
              [-38.324573062999946, -9.074245772999973],
              [-38.321042115999944, -9.070033667999951],
              [-38.31610210499997, -9.067533554999955],
              [-38.31245893199997, -9.066358056999945],
              [-38.303737141999932, -9.061643336999964],
              [-38.297046013999932, -9.055716741999959],
              [-38.289959273999955, -9.047161837999965],
              [-38.288880463999931, -9.040343222999979],
              [-38.289675698999929, -9.036334823999937],
              [-38.296402487999956, -9.022851442999979],
              [-38.301506720999953, -9.015673418999938],
              [-38.302790345999938, -9.013868272999957],
              [-38.316935144999945, -9.001293945999976],
              [-38.316950315999975, -8.997157198999957],
              [-38.317411251999943, -8.99510745799995],
              [-38.319175042999973, -8.992060264999964],
              [-38.320127200999934, -8.991089435999982],
              [-38.320749647999946, -8.990742263999948],
              [-38.323540024999943, -8.990009184999963],
              [-38.331124457999977, -8.989900378999948],
              [-38.340237329999979, -8.990408374999959],
              [-38.344267589999959, -8.991470057999948],
              [-38.348287368999934, -8.993142388999956],
              [-38.356160717999956, -8.997142034999968],
              [-38.362441818999969, -9.00094123699995],
              [-38.376243005999982, -9.015922066999963],
              [-38.38589884299995, -9.024825677999956],
              [-38.396695751999971, -9.033041442999945],
              [-38.403516213999978, -9.037233187999959],
              [-38.408458244999963, -9.038501284999938],
              [-38.414016585999946, -9.038675160999958],
              [-38.415451415999939, -9.038569837999944],
              [-38.418404073999966, -9.038045585999953],
              [-38.420517185999984, -9.037401298999953],
              [-38.423098550999953, -9.036377096999956],
              [-38.43004362299996, -9.03449588899997],
              [-38.441162548999955, -9.030211530999964],
              [-38.449099650999983, -9.026358845999937],
              [-38.451345147999973, -9.024394693999966],
              [-38.456117610999968, -9.021621876999973],
              [-38.468198472999973, -9.013202472999978],
              [-38.484042769999974, -9.001021725999976],
              [-38.485140245999958, -8.998556699999938],
              [-38.485680708999951, -8.997342772999957],
              [-38.488213006999956, -8.993963037999947],
              [-38.498402944999953, -8.984899894999955],
              [-38.503512717999968, -8.981411129999969],
              [-38.507907026999931, -8.974171701999978],
              [-38.511130959999946, -8.963627862999942],
              [-38.511871443999951, -8.962554207999972],
              [-38.512604034999981, -8.96149199599995],
              [-38.512606307999931, -8.961488700999951],
              [-38.514037953999946, -8.96004275599995],
              [-38.514042079999967, -8.960038588999964],
              [-38.514061880999975, -8.960018589999947],
              [-38.514081680999936, -8.959998591999977],
              [-38.514167222999959, -8.959912045999943],
              [-38.516154938999932, -8.95790100499994],
              [-38.516376575999971, -8.95741415699996],
              [-38.516386973999943, -8.957391316999974],
              [-38.517203111999947, -8.955598591999944],
              [-38.517203325999958, -8.955597932999979],
              [-38.519811094999966, -8.947582784999952],
              [-38.51990724999996, -8.943532637999965],
              [-38.519907280999973, -8.943531333999942],
              [-38.519897760999982, -8.943456585999968],
              [-38.519897704999948, -8.943456147999939],
              [-38.519374682999967, -8.939349697999944],
              [-38.516798714999936, -8.933235430999957],
              [-38.513353842999948, -8.927872522999962],
              [-38.510960510999951, -8.925110808999932],
              [-38.505159637999952, -8.919976463999944],
              [-38.502046660999952, -8.916620727999941],
              [-38.492126206999956, -8.912602242999981],
              [-38.489574623999943, -8.911040746999959],
              [-38.484408092999956, -8.907047027999965],
              [-38.479318184999954, -8.901743715999942],
              [-38.476623973999949, -8.894803213999978],
              [-38.473292859999958, -8.889545679999969],
              [-38.469871360999946, -8.874689390999947],
              [-38.469807119999984, -8.865742275999935],
              [-38.469798348999973, -8.864417242999934],
              [-38.469797182999969, -8.864241142999958],
              [-38.470792247999952, -8.861625256999957],
              [-38.473239793999937, -8.858706621999943],
              [-38.474240062999968, -8.856909956999971],
              [-38.475240387999975, -8.855113192999966],
              [-38.479834464999954, -8.850088899999946],
              [-38.480809824999938, -8.849326121999979],
              [-38.495600880999973, -8.839827217999925],
              [-38.49865843799995, -8.83815777999996],
              [-38.502439489999972, -8.835356244999957],
              [-38.506442444999948, -8.833235180999964],
              [-38.512406702999954, -8.830585949999943],
              [-38.516853079999976, -8.829283344999965],
              [-38.530932965999966, -8.826409947999935],
              [-38.541139111999939, -8.823486775999982],
              [-38.548214567999935, -8.822735102999957],
              [-38.551842729999976, -8.82262940499993],
              [-38.555067821999955, -8.823357782999949],
              [-38.562550944999941, -8.826346159999957],
              [-38.57042863099997, -8.830369085999962],
              [-38.572845386999973, -8.832348964999937],
              [-38.57696531199997, -8.83712804399994],
              [-38.580238454999972, -8.842712463999931],
              [-38.589950413999929, -8.856539234999957],
              [-38.592486073999964, -8.861534663999976],
              [-38.59729727399997, -8.868855340999971],
              [-38.601825946999952, -8.877634064999938],
              [-38.603824326999984, -8.880590640999969],
              [-38.60663675099994, -8.889707715999975],
              [-38.607585713999981, -8.893264037999927],
              [-38.607823441999983, -8.904378659999963],
              [-38.607158088999938, -8.910350785999981],
              [-38.607158075999962, -8.910351442999968],
              [-38.607012309999959, -8.917507765999972],
              [-38.608938020999972, -8.934570917999963],
              [-38.60828801699995, -8.945391477999976],
              [-38.608335805999957, -8.949821720999978],
              [-38.609239816999946, -8.955121],
              [-38.610276938999959, -8.95732834599994],
              [-38.615434204999929, -8.964057211999943],
              [-38.621791477999977, -8.976559361999932],
              [-38.624098518999972, -8.980046885999968],
              [-38.627712472999974, -8.983220855999946],
              [-38.630684935999966, -8.985167252999929],
              [-38.635376998999959, -8.986729250999929],
              [-38.640710321999961, -8.98726815699996],
              [-38.644912052999985, -8.986693336999963],
              [-38.653726691999964, -8.983900038999934],
              [-38.657597119999934, -8.982477732999939],
              [-38.666226288999951, -8.977881772999979],
              [-38.66898605199998, -8.976411902999928],
              [-38.668986076999943, -8.976411885999937],
              [-38.672515767999982, -8.97397547099996],
              [-38.67438230099998, -8.971756386999971],
              [-38.676797172999954, -8.966346477999934],
              [-38.681022976999941, -8.961191649999932],
              [-38.689232623999942, -8.94739558699996],
              [-38.690146004999974, -8.945277011999963],
              [-38.692875435999952, -8.936228874999927],
              [-38.693800710999938, -8.931889167999941],
              [-38.694168924999929, -8.924670983999931],
              [-38.693628088999958, -8.91870440799994],
              [-38.693204814999945, -8.914022875999933],
              [-38.693123468999943, -8.913123173999963],
              [-38.693658806999963, -8.902968832999932],
              [-38.695616948999941, -8.892867112999966],
              [-38.696070779999957, -8.890525878999938],
              [-38.698381198999982, -8.881762],
              [-38.699104527999964, -8.879883608999933],
              [-38.69946617599993, -8.878945235999936],
              [-38.702374218999978, -8.87386192799994],
              [-38.704987119999942, -8.865409499999942],
              [-38.706543497999974, -8.862260616999947],
              [-38.708715284999982, -8.860155453999937],
              [-38.711511290999965, -8.858393004999925],
              [-38.71151498699993, -8.858390674999953],
              [-38.717096423999976, -8.853151762999971],
              [-38.725072738999984, -8.85027157199994],
              [-38.730188032999934, -8.847439781999981],
              [-38.739683200999934, -8.844848128999956],
              [-38.745437438999943, -8.83894554699998],
              [-38.74543809499994, -8.838944873999935],
              [-38.75052607799995, -8.836342359999946],
              [-38.758114724999984, -8.829979680999941],
              [-38.762552883999945, -8.825966827999935],
              [-38.765605187999938, -8.821696540999937],
              [-38.771465005999971, -8.817291274999945],
              [-38.774690417999977, -8.812072427999965],
              [-38.781128786999943, -8.807027215999938],
              [-38.784075485999949, -8.804718133999927],
              [-38.78498491199997, -8.803848667999944],
              [-38.787212967999949, -8.801718513999958],
              [-38.788715498999977, -8.799658086999955],
              [-38.796989456999938, -8.793464421999943],
              [-38.804069854999966, -8.789181096999926],
              [-38.807078391999937, -8.787778214999946],
              [-38.807629573999975, -8.787682791999941],
              [-38.816304331999959, -8.786180976999958],
              [-38.821073903999945, -8.786066156999937],
              [-38.821403693999969, -8.786058217999937],
              [-38.821623955999939, -8.786052914999971],
              [-38.822274604999961, -8.786037251999971],
              [-38.82286357299995, -8.786023072999967],
              [-38.82312604599997, -8.786016753999945],
              [-38.823323811999956, -8.786011992999931],
              [-38.823523344999955, -8.786007188999974],
              [-38.830216431999929, -8.786383129999933],
              [-38.83697669299994, -8.785428438999929],
              [-38.849252270999955, -8.784812336999948],
              [-38.852227265999943, -8.78466302399994],
              [-38.854775859999961, -8.784535111999958],
              [-38.854895013999965, -8.784529130999942],
              [-38.854933413999959, -8.784527204999961],
              [-38.855065001999947, -8.784520591999978],
              [-38.855381286999943, -8.784504697999978],
              [-38.856050205999964, -8.784471086999929],
              [-38.856994910999958, -8.78442361599997],
              [-38.867552967999984, -8.783893091999971],
              [-38.868104419999952, -8.783865382999977],
              [-38.876405515999977, -8.786177023999926],
              [-38.884769561999974, -8.789604904999976],
              [-38.899384549999979, -8.791001130999973],
              [-38.903461495999977, -8.791941639999948],
              [-38.910239573999945, -8.793505268999979],
              [-38.91618092799996, -8.793190279999976],
              [-38.924937545999967, -8.794747504999975],
              [-38.927969141999938, -8.795767115999979],
              [-38.937162584999953, -8.801566378999951],
              [-38.94042774199994, -8.802900954999927],
              [-38.943692898999984, -8.804235530999961],
              [-38.945230948999949, -8.804461355999933],
              [-38.946768997999982, -8.804687178999927],
              [-38.948091879999936, -8.804682329999935],
              [-38.951933208999947, -8.804668246999938],
              [-38.952363882999975, -8.804538635999961],
              [-38.952844854999967, -8.804393888999925],
              [-38.953942663999953, -8.804063505999977],
              [-38.953943168999956, -8.804063353999936],
              [-38.956467310999983, -8.802374672999974],
              [-38.962795718999985, -8.796014782999976],
              [-38.965132306999976, -8.794281895999973],
              [-38.967040800999939, -8.793664143999933],
              [-38.974526266999931, -8.794175436999979],
              [-38.977432043999954, -8.793086674999927],
              [-38.982496795999964, -8.78505322999996],
              [-38.982793281999932, -8.783839215999933],
              [-38.985691881999969, -8.780002304999925],
              [-38.988870691999978, -8.771689976999937],
              [-38.991911344999949, -8.76755187599997],
              [-38.999377572999947, -8.761092341999927],
              [-39.005905034999955, -8.757887742999969],
              [-39.010713021999948, -8.75615503399996],
              [-39.014657749999969, -8.753867353999965],
              [-39.023632086999953, -8.747043868999981],
              [-39.025768016999962, -8.742783320999933],
              [-39.028367450999951, -8.74002083299996],
              [-39.030206438999983, -8.738484643999925],
              [-39.033548351999968, -8.736313339999981],
              [-39.037577238999972, -8.734690861999979],
              [-39.042803378999963, -8.733348633999981],
              [-39.051848672999938, -8.733653567999966],
              [-39.052639777999957, -8.733590502999959],
              [-39.052747461999957, -8.73358191899996],
              [-39.05342978699997, -8.733527525999932],
              [-39.053430882999976, -8.733527437999953],
              [-39.056233288999977, -8.733179415999928],
              [-39.056300309999983, -8.733171091999964],
              [-39.058571948999941, -8.732745613999953],
              [-39.058572045999938, -8.732745595999972],
              [-39.060419257999968, -8.732399612999927],
              [-39.064538205999952, -8.731628134999937],
              [-39.064685270999973, -8.731600588999981],
              [-39.064686119999976, -8.731600429999958],
              [-39.070198265999977, -8.730312640999955],
              [-39.070202761999951, -8.730311590999975],
              [-39.102180867999948, -8.720047717999933],
              [-39.10702447999995, -8.719283469999937],
              [-39.109550933999969, -8.718884831999958],
              [-39.112963179999952, -8.718346431999976],
              [-39.114034756999956, -8.718070931999932],
              [-39.114870359999941, -8.717855880999934],
              [-39.114870334999978, -8.71785566799997],
              [-39.11549440999994, -8.717695075999927],
              [-39.117800866999971, -8.717101559999946],
              [-39.121799562999968, -8.716072582999971],
              [-39.129366839999932, -8.715448541999933],
              [-39.135190964999936, -8.713714269999969],
              [-39.143946196999934, -8.712519071999964],
              [-39.148635229999968, -8.710919281999963],
              [-39.151133883999933, -8.709645605999981],
              [-39.153804406999939, -8.70857459399997],
              [-39.155245050999952, -8.707691797999928],
              [-39.156685694999965, -8.706809001999943],
              [-39.161581043999945, -8.701797802999977],
              [-39.167474295999966, -8.692543973999932],
              [-39.168712829999947, -8.691504456999951],
              [-39.169569818999946, -8.692038699999955],
              [-39.169569845999945, -8.692038666999963],
              [-39.169794270999944, -8.692178558999956],
              [-39.170257546999949, -8.692915206999942],
              [-39.171379591999937, -8.695412274999967],
              [-39.174045409999962, -8.701344949999964],
              [-39.175492515999963, -8.702966716999981],
              [-39.17852389199993, -8.704202381999949],
              [-39.180995840999969, -8.706300861999978],
              [-39.184886175999964, -8.705920250999952],
              [-39.190383077999968, -8.706702254999925],
              [-39.191858912999976, -8.70718104599996],
              [-39.195131929999945, -8.709555979999948],
              [-39.198901400999944, -8.710644293999962],
              [-39.19890385399998, -8.710644741999943],
              [-39.200781027999938, -8.710987629999977],
              [-39.217631423999933, -8.708459670999957],
              [-39.21874248599994, -8.708851093999954],
              [-39.221193524999933, -8.711226686999964],
              [-39.222240773999943, -8.711667409999961],
              [-39.224382317999982, -8.711580691999927],
              [-39.228521863999958, -8.71031531999995],
              [-39.230553819999955, -8.70881110199997],
              [-39.234268020999934, -8.705336401999944],
              [-39.234808400999952, -8.704185042999939],
              [-39.235582366999949, -8.702535993999959],
              [-39.240856515999951, -8.691298643999971],
              [-39.244852184999957, -8.68599999099996],
              [-39.245492562999971, -8.68410586899995],
              [-39.245661250999945, -8.676259162999941],
              [-39.248017847999961, -8.668767715999934],
              [-39.247773128999938, -8.66758436799995],
              [-39.24571382299996, -8.663995296999929],
              [-39.245499088999964, -8.662355681999941],
              [-39.246885955999971, -8.654267104999974],
              [-39.246662393999941, -8.647590453999953],
              [-39.245610583999962, -8.645413141999938],
              [-39.241646317999937, -8.64175235099998],
              [-39.241645808999976, -8.641752581999981],
              [-39.241644536999956, -8.641751407999948],
              [-39.241197177999936, -8.64061576499995],
              [-39.241076411999984, -8.64030919399994],
              [-39.241076317999955, -8.640308956999945],
              [-39.241799340999933, -8.636708951999935],
              [-39.241879990999962, -8.636307387999977],
              [-39.243064195999978, -8.633556838999937],
              [-39.243871221999939, -8.631682361999935],
              [-39.243978679999941, -8.631483931999981],
              [-39.245247505999941, -8.629145960999949],
              [-39.245247176999953, -8.629145648999952],
              [-39.246494301999974, -8.626848580999933],
              [-39.246784563999938, -8.626462034999975],
              [-39.247883294999951, -8.624998836999964],
              [-39.252117059999932, -8.621022244999949],
              [-39.25322269399993, -8.619983771999955],
              [-39.253224075999981, -8.619983124999976],
              [-39.263405126999942, -8.615218475999939],
              [-39.264466423999977, -8.614128984999979],
              [-39.264691110999934, -8.613898328999937],
              [-39.267743878999966, -8.608845788999929],
              [-39.268259001999979, -8.60666505599994],
              [-39.26896996499994, -8.603655246999949],
              [-39.272698332999937, -8.596493627999962],
              [-39.271858549999934, -8.58980325999994],
              [-39.272623702999965, -8.583791018999932],
              [-39.275159824999946, -8.576825035999946],
              [-39.281392727999958, -8.570530860999952],
              [-39.284105871999941, -8.565867949999927],
              [-39.286421289999964, -8.563759797999978],
              [-39.293215611999983, -8.560629172999938],
              [-39.302446799999984, -8.560584520999953],
              [-39.312460680999948, -8.558311544999981],
              [-39.320398007999984, -8.560037511999951],
              [-39.324431390999962, -8.560014071999944],
              [-39.33233665299997, -8.558286050999925],
              [-39.335928621999983, -8.558787077999966],
              [-39.338428005999958, -8.559135830999935],
              [-39.342307017999985, -8.559677090999969],
              [-39.34467872099998, -8.558792066999956],
              [-39.344911218999982, -8.558646324999927],
              [-39.345143716999985, -8.558500582999955],
              [-39.350264301999971, -8.553149899999937],
              [-39.353264408999962, -8.550014979999958],
              [-39.354438491999929, -8.549162820999982],
              [-39.355612574999952, -8.548310661999949],
              [-39.356242444999964, -8.547853496999949],
              [-39.356628280999985, -8.547573453999973],
              [-39.356786007999972, -8.547458973999937],
              [-39.356874195999978, -8.547394965999956],
              [-39.357360888999949, -8.54704172199996],
              [-39.357055124999931, -8.546980548999954],
              [-39.35680913799996, -8.54489654799994],
              [-39.360472737999942, -8.54099118299996],
              [-39.364456410999935, -8.537849552999944],
              [-39.37371952899997, -8.534092502999954],
              [-39.379793560999985, -8.532820570999945],
              [-39.386365636999983, -8.53349110299996],
              [-39.400256584999966, -8.538659205999977],
              [-39.407628467999984, -8.538351348999981],
              [-39.409523278999984, -8.538900110999975],
              [-39.413745198999948, -8.541670628999952],
              [-39.416040799999962, -8.542127927999957],
              [-39.418707039999958, -8.546104416999981],
              [-39.424802760999967, -8.551396095999962],
              [-39.425338298999975, -8.55230568099995],
              [-39.426051468999958, -8.556435540999928],
              [-39.427842246999944, -8.558658097999967],
              [-39.431985128999941, -8.560611457999926],
              [-39.435490356999935, -8.563301842999977],
              [-39.439325882999981, -8.56341036699996],
              [-39.444453450999958, -8.561801598999978],
              [-39.452109574999952, -8.563199150999935],
              [-39.456782818999955, -8.568472799999938],
              [-39.457548308999947, -8.569336637999982],
              [-39.458702332999962, -8.572439518999943],
              [-39.459580249999931, -8.579185978999931],
              [-39.461246570999947, -8.582040166999946],
              [-39.469556387999944, -8.58873222099993],
              [-39.470672915999955, -8.58912560999994],
              [-39.471789443999967, -8.589519],
              [-39.476111292999974, -8.592793202999928],
              [-39.482384469999943, -8.593953081999928],
              [-39.483315945999948, -8.594654089999949],
              [-39.485696747999953, -8.596445826999968],
              [-39.503013374999966, -8.600592346999974],
              [-39.514982946999964, -8.602510112999937],
              [-39.518612348999966, -8.603911063999931],
              [-39.52002973499998, -8.605291810999972],
              [-39.521259492999945, -8.608485123999969],
              [-39.523676077999937, -8.610848069999975],
              [-39.526214384999946, -8.612534740999934],
              [-39.529114155999935, -8.612873103999959],
              [-39.530721894999942, -8.613394419999963],
              [-39.531525774999977, -8.61365508199998],
              [-39.533887916999959, -8.61573539799997],
              [-39.535940770999957, -8.615735779999966],
              [-39.540532120999956, -8.618544096999926],
              [-39.545490510999969, -8.620680672999981],
              [-39.55068184299995, -8.621008780999944],
              [-39.555026770999973, -8.62213331099997],
              [-39.55665804399996, -8.623088630999973],
              [-39.557267347999982, -8.624889306999933],
              [-39.557515226999953, -8.62883426999997],
              [-39.560775059999969, -8.628378587999975],
              [-39.563146612999958, -8.629586832999962],
              [-39.564521981999974, -8.630287548999945],
              [-39.564522296999939, -8.630287708999958],
              [-39.567544464999969, -8.634341114999927],
              [-39.568574941999941, -8.636651252999968],
              [-39.569666979999965, -8.637067979999927],
              [-39.57739277099995, -8.640016172999935],
              [-39.58089763199996, -8.640465272999961],
              [-39.588575160999937, -8.646368442999972],
              [-39.590264047999938, -8.646708267999941],
              [-39.592883292999943, -8.649082095999972],
              [-39.595225782999933, -8.65120589199995],
              [-39.60018036799994, -8.652209901999981],
              [-39.60467504099995, -8.654827792999981],
              [-39.60664540099998, -8.655975416999979],
              [-39.610026263999941, -8.657210709999958],
              [-39.614497865999965, -8.657541531999925],
              [-39.622584823999944, -8.655498603999945],
              [-39.629289478999965, -8.655994926999938],
              [-39.633517863999941, -8.657792890999929],
              [-39.642687452999951, -8.659348765999937],
              [-39.646005655999943, -8.65969258299998],
              [-39.650624558999937, -8.660171315999946],
              [-39.652364040999942, -8.660351606999939],
              [-39.660871447999966, -8.657353931999978],
              [-39.671623169999975, -8.659475980999957],
            ],
          ],
        ],
      },
    },
  ],
};
