import braWindspeed50mPems0 from '../../../assets/layers/braWindspeed50mPems0.png';
import braWindspeed100mPems2 from '../../../assets/layers/braWindspeed100mPems2.png';
import { useLayers } from '../../../context/LayersContext';
import { ImageOverlay /*, Pane*/ } from 'react-leaflet';

export function RenderPotencialEolico() {
  const { /*addNewLayer,*/ layers } = useLayers();
  if (!layers) return null;
  const localStorageDict = [
    {
      name: 'Velocidade do Vento (50m)',
      data: braWindspeed50mPems0,
    },
    {
      name: 'Velocidade do Vento (100m)',
      data: braWindspeed100mPems2,
    },
  ];

  const imgBounds = [
    [-9.482897442999958, -41.3583361839999],
    [-3.804622237999986, -32.3777679409999],
  ];

  return (
    <>
      {localStorageDict.map((obj) => {
        if (layers.includes(obj.name)) {
          if (obj.data) {
            //@ts-ignore
            return <ImageOverlay url={obj.data} bounds={imgBounds} />;
          }
        }
      })}
    </>
  );
}
