import { useState, useEffect } from 'react';
import { CheckboxTreeView } from '../CheckboxTreeView';
import {
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useLayers } from '../../context/LayersContext';
import { geoLayers } from './layers';
import { ModalTitle, ModalContent } from './styles';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

interface Layer {
  title: string;
  children?: Layer[];
}

const transformGeoLayers = (data: any[]): Layer[] => {
  return data.map((item) => {
    const [groupTitle, layers] = item;
    const layerItem: Layer = {
      title: groupTitle,
    };
    if (Array.isArray(layers)) {
      layerItem.children = layers.map((layerTitle) => ({
        title: layerTitle,
      }));
    }
    return layerItem;
  });
};

const transformedGeoLayers = transformGeoLayers(geoLayers);

export default function GeoLayersContent() {
  const { cleanCheckedLayers } = useLayers();
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredLayers, setFilteredLayers] =
    useState<Layer[]>(transformedGeoLayers);

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    setSearchTerm('');
  };

  const handleCloseSearch = () => {
    setShowSearch(false);
    setSearchTerm('');
  };

  useEffect(() => {
    const filterLayers = (layers: Layer[], query: string): Layer[] => {
      return layers
        .map((layer) => {
          const matchesTitle = layer.title
            .toLowerCase()
            .includes(query.toLowerCase());

          if (layer.children) {
            const filteredChildren = filterLayers(layer.children, query);
            if (filteredChildren.length > 0 || matchesTitle) {
              return { ...layer, children: filteredChildren };
            }
          }

          if (matchesTitle) {
            return { ...layer };
          }

          return null;
        })
        .filter((layer): layer is Layer => layer !== null);
    };

    if (searchTerm) {
      const filtered = filterLayers(transformedGeoLayers, searchTerm);
      setFilteredLayers(filtered);
    } else {
      setFilteredLayers(transformedGeoLayers);
    }
  }, [searchTerm]);

  return (
    <div>
      <ModalContent>
        {showSearch && (
          <TextField
            fullWidth
            placeholder="Digite o título da camada..."
            style={{ marginBottom: '10px' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCloseSearch}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
              style: { padding: '5px 5px !important' },
            }}
            variant="outlined"
            sx={{
              '& .MuiOutlinedInput-root': {
                '&.Mui-focused fieldset': {
                  borderColor: 'gray',
                },
              },
            }}
          />
        )}
        <ModalTitle>Camadas (GeoServer)</ModalTitle>
        <CheckboxTreeView data={filteredLayers} />
        <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={cleanCheckedLayers}
            style={{
              color: 'red',
              borderColor: 'red',
              width: '170px',
              gap: '5px',
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
            Limpar Camadas
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={toggleSearch}
            style={{
              color: 'grey',
              borderColor: 'grey',
              width: '170px',
              gap: '5px',
            }}
          >
            <SearchOutlinedIcon fontSize="small" />
            Buscar Camada
          </Button>
        </Box>
      </ModalContent>
    </div>
  );
}
