import L from 'leaflet';
import UHEBaseExistente from '../../../assets/markers/UHE_Base_existente.svg';
import EOLBaseExistente from '../../../assets/markers/EOL_Base_existente.svg';
import CGHBase from '../../../assets/markers/CGH_Base.svg';
import solarEnergy from '../../../assets/markers/solar_energy.svg';
import UTEFossil from '../../../assets/markers/UTE_fossil.svg';

export const styleUHEBaseExistente25 = {
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: UHEBaseExistente,
    iconSize: [26.599999999999998, 26.599999999999998],
  }),
  interactive: false,
};

export const styleEOLBaseExistente24 = {
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: EOLBaseExistente,
    iconSize: [22.799999999999997, 22.799999999999997],
  }),
  interactive: false,
};

/* function styleSubestaesBaseExistente23() {
  return {
    rotationAngle: 0.0,
    rotationOrigin: 'center center',
    icon: L.icon({
      iconUrl: subestacoesIcon,
      iconSize: [26.599999999999998, 26.599999999999998]
    }),
    interactive: false
  };
} */

export const styleLinhasDeTransmissoBaseExistente22 = {
  opacity: 1,
  color: 'rgba(178,53,0,1.0)',
  dashArray: '',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 2.0,
  fillOpacity: 0,
  interactive: false,
};

export const styleCGHBaseExistente21 = {
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: CGHBase,
    iconSize: [22.8, 22.8],
  }),
  interactive: false,
};

export const styleUTEFssilBaseExistente20 = {
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: UTEFossil,
    iconSize: [22.8, 22.8],
  }),
  interactive: false,
};

export const styleUFVBaseExistente14 = {
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: solarEnergy,
    iconSize: [26.6, 26.6],
  }),
  interactive: true,
};
