export interface Layer {
  title: string;
  children?: Layer[];
}

export const geoLayers: Layer[] = [
  { title: 'Infraestrutura e Mobilidade' },
  { title: 'Pernambuco e Suas águas - Infraestrutura Hídrica' },
  { title: 'Informações Socioambientais' },
  { title: 'Bases de Combustíveis - Cenários' },
  { title: 'Sistema Elétrico e Potencial Eólico' },
  { title: 'Sistema Elétrico e Potencial Solar' },
];
