export var jsonEOLBaseExistente24 = {
  type: 'FeatureCollection',
  name: 'EOL__Base_Existente_24',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        Nome: 'Millennium',
        potencia: 10200.0,
        propriet: '100% para MILLENNIUM CENTRAL GERADORA EÓLICA S.A. (PIE)',
        ini_oper: '2007-11-28',
        ceg: 'EOL.CV.PB.029041-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.97194436999996, -6.493888919999961],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Albatroz',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-01-31',
        ceg: 'EOL.CV.PB.029085-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.968012169999952, -6.54751669999996],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Atlântica',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-01-17',
        ceg: 'EOL.CV.PB.029088-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.973710599999947, -6.547366039999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Camurim',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-01-31',
        ceg: 'EOL.CV.PB.029084-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.972057709999945, -6.557596389999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Caravela',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-01-31',
        ceg: 'EOL.CV.PB.029089-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.969042589999958, -6.555975629999978],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Coelhos I',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-03-05',
        ceg: 'EOL.CV.PB.029086-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.971814919999929, -6.575796569999966],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Coelhos II',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-02-10',
        ceg: 'EOL.CV.PB.029090-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.967368539999939, -6.570109789999947],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Coelhos III',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-03-05',
        ceg: 'EOL.CV.PB.029087-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.966429889999972, -6.556589839999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Coelhos IV',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-01-14',
        ceg: 'EOL.CV.PB.029091-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.974651569999935, -6.581407979999938],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Mataraca',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-01-14',
        ceg: 'EOL.CV.PB.029092-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.978461879999941, -6.582254749999947],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Presidente',
        potencia: 4500.0,
        propriet: '100% para Vale dos Ventos Geradora Eólica S.A (PIE)',
        ini_oper: '2009-01-14',
        ceg: 'EOL.CV.PB.029083-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.976039109999931, -6.574674339999945],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Xavante',
        potencia: 4250.0,
        propriet: '100% para Eólica Gravatá - Geradora de Energia S.A. (PIE)',
        ini_oper: '2010-04-24',
        ceg: 'EOL.CV.PE.029011-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.38888886999996, -8.174999919999948],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Gravatá Fruitrade',
        potencia: 4250.0,
        propriet: '100% para Eólica Gravatá - Geradora de Energia S.A. (PIE)',
        ini_oper: '2010-04-24',
        ceg: 'EOL.CV.PE.029020-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.596404959999973, -8.268160769999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Mandacaru',
        potencia: 4950.0,
        propriet: '100% para Eólica Gravatá - Geradora de Energia S.A. (PIE)',
        ini_oper: '2010-04-24',
        ceg: 'EOL.CV.PE.029015-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.596375989999956, -8.278998079999951],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Santa Maria',
        potencia: 4250.0,
        propriet: '100% para Eólica Gravatá - Geradora de Energia S.A. (PIE)',
        ini_oper: '2010-04-24',
        ceg: 'EOL.CV.PE.029019-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.595566349999956, -8.294679349999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pirauá',
        potencia: 4250.0,
        propriet: '100% para Eólica Pirauá Geradora de Energia S.A. (PIE)',
        ini_oper: '2010-05-05',
        ceg: 'EOL.CV.PE.028481-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.495521319999966, -7.495334579999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Vitória',
        potencia: 4250.0,
        propriet: '100% para CARDUS ENERGIA LTDA. (PIE)',
        ini_oper: '2010-11-28',
        ceg: 'EOL.CV.PB.029075-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.014693549999947, -6.557296339999937],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pedra do Reino',
        potencia: 30000.0,
        propriet: '100% para Eólica Pedra do Reino S.A. (PIE)',
        ini_oper: '2013-02-04',
        ceg: 'EOL.CV.BA.030288-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.886394189999976, -9.517548289999979],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pedra do Reino III',
        potencia: 18000.0,
        propriet: '100% para Gestamp Eolicatec Sobradinho S.A (PIE)',
        ini_oper: '2013-06-07',
        ceg: 'EOL.CV.BA.030608-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-40.879, -9.498583329999974] },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Sete Gameleiras',
        potencia: 30000.0,
        propriet: '100% para SETE GAMELEIRAS S/A (PIE)',
        ini_oper: '2013-03-26',
        ceg: 'EOL.CV.BA.030455-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.10417463999994, -9.863843839999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'São Pedro do Lago',
        potencia: 28000.0,
        propriet: '100% para São Pedro do Lago S.A (PIE)',
        ini_oper: '2013-03-02',
        ceg: 'EOL.CV.BA.030456-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.09301107999994, -9.874693259999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pedra Branca',
        potencia: 30000.0,
        propriet: '100% para Pedra Branca S.A. (PIE)',
        ini_oper: '2013-03-02',
        ceg: 'EOL.CV.BA.030443-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.095714789999931, -9.874591529999975],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pau Ferro',
        potencia: 30550.0,
        propriet: '100% para Enel Green Power Pau Ferro Eólica S.A (PIE)',
        ini_oper: '2015-02-04',
        ceg: 'EOL.CV.PE.030805-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.118579859999954, -9.06248187999995],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Tacaicó',
        potencia: 18800.0,
        propriet: '100% para Enel Green Power Tacaicó Eólica S.A (PIE)',
        ini_oper: '2015-02-04',
        ceg: 'EOL.CV.PE.030789-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.056014499999947, -9.018736889999957],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pedra do Gerônimo',
        potencia: 30550.0,
        propriet:
          '100% para Enel Green Power Pedra do Gerônimo Eólica S.A (PIE)',
        ini_oper: '2015-02-04',
        ceg: 'EOL.CV.PE.030806-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.144783019999977, -9.067193029999942],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Brígida I',
        potencia: 13600.0,
        propriet:
          '100% para Ventos de Santa Brígida I Energias Renováveis S.A. (PIE)',
        ini_oper: '2015-12-25',
        ceg: 'EOL.CV.PE.031365-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.748738609999975, -8.80988310999993],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Mussambê',
        potencia: 32900.0,
        propriet: '100% para Mussambê Energética S.A (PIE)',
        ini_oper: '2015-10-14',
        ceg: 'EOL.CV.BA.031352-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.083416799999952, -9.862763799999925],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Morro Branco I',
        potencia: 32900.0,
        propriet: '100% para Morro Branco I Energética S.A (PIE)',
        ini_oper: '2015-11-04',
        ceg: 'EOL.CV.BA.031336-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.056519469999955, -9.849704689999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Baraúnas I',
        potencia: 32900.0,
        propriet: '100% para Baraúnas I Energética S.A (PIE)',
        ini_oper: '2015-11-20',
        ceg: 'EOL.CV.BA.031335-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.120330829999943, -9.886793749999981],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Brígida VII',
        potencia: 27200.0,
        propriet:
          '100% para Ventos de Santa Brígida VII Energias Renováveis S.A (PIE)',
        ini_oper: '2015-12-25',
        ceg: 'EOL.CV.PE.031422-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.757917839999948, -8.802547509999954],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Brígida II',
        potencia: 27200.0,
        propriet:
          '100% para Ventos de Santa Brígida II Energias Renováveis S.A (PIE)',
        ini_oper: '2015-12-25',
        ceg: 'EOL.CV.PE.031363-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.733769379999956, -8.796904429999927],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Brígida III',
        potencia: 28900.0,
        propriet:
          '100% para Ventos de Santa Brígida III Energias Renováveis S.A. (PIE)',
        ini_oper: '2015-12-25',
        ceg: 'EOL.CV.PE.031354-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.769663069999979, -8.845085729999937],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Brígida IV',
        potencia: 27200.0,
        propriet:
          '100% para Ventos de Santa Brígida IV Energias Renováveis S.A (PIE)',
        ini_oper: '2015-12-25',
        ceg: 'EOL.CV.PE.031370-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.760183119999965, -8.833776579999949],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Brígida V',
        potencia: 28900.0,
        propriet:
          '100% para Ventos de Santa Brígida V Energias Renováveis S.A. (PIE)',
        ini_oper: '2015-12-25',
        ceg: 'EOL.CV.PE.031346-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.730237029999955, -8.846492409999939],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Brígida VI',
        potencia: 28900.0,
        propriet:
          '100% para Ventos de Santa Brígida VI Energias Renováveis S.A (PIE)',
        ini_oper: '2015-12-25',
        ceg: 'EOL.CV.PE.031353-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.747064369999975, -8.868061939999961],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Onofre I',
        potencia: 30000.0,
        propriet:
          '100% para Ventos de Santo Onofre I Energias Renováveis S.A. (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PI.031364-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.725523969999983, -7.553312329999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana IX',
        potencia: 29600.0,
        propriet:
          '100% para Ventos de Santa Joana IX Energias Renováveis S.A (PIE)',
        ini_oper: '2015-08-27',
        ceg: 'EOL.CV.PI.031417-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.744132739999941, -7.451661539999975],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Onofre III',
        potencia: 30000.0,
        propriet:
          '100% para Ventos de Santo Onofre III Energias Renováveis S.A (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PI.031886-8.02',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.725680039999929, -7.55500829999994],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana II',
        potencia: 30000.0,
        propriet:
          '100% para Ventos de Santa Joana II Energias Renováveis S.A. (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PI.031361-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.703175519999945, -7.569681939999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana VI',
        potencia: 30000.0,
        propriet:
          '100% para Ventos de Santa Joana VI Energias Renováveis S.A. (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PI.031367-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.744204219999972, -7.526589779999938],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana VIII',
        potencia: 30000.0,
        propriet:
          '100% para Ventos de Santa Joana VIII Energias Renováveis S.A (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PI.031366-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.73249200999993, -7.597686089999968],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana XIV',
        potencia: 30000.0,
        propriet:
          '100% para VENTOS DE SANTA JOANA XIV ENERGIAS RENOVÁVEIS S.A. (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PI.031368-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.734438359999956, -7.510895939999955],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Onofre II',
        potencia: 30000.0,
        propriet:
          '100% para Ventos de Santo Onofre II Energias Renováveis S.A. (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PI.031362-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.73036086999997, -7.594595109999943],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana X',
        potencia: 29600.0,
        propriet:
          '100% para Ventos de Santa Joana X Energias Renováveis S.A (PIE)',
        ini_oper: '2015-07-09',
        ceg: 'EOL.CV.PI.031393-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.708269309999935, -7.43867580999995],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana XII',
        potencia: 28900.0,
        propriet:
          '100% para Ventos de Santa Joana XII Energias Renováveis S.A (PIE)',
        ini_oper: '2015-07-09',
        ceg: 'EOL.CV.PI.031414-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.754043919999958, -7.485980649999931],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana XIII',
        potencia: 29600.0,
        propriet:
          '100% para Ventos de Santa Joana XIII Energias Renováveis S.A (PIE)',
        ini_oper: '2015-07-09',
        ceg: 'EOL.CV.PI.031394-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.710881159999985, -7.410352469999964],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana XV',
        potencia: 28900.0,
        propriet:
          '100% para Ventos de Santa Joana XV Energias Renováveis S.A (PIE)',
        ini_oper: '2015-07-09',
        ceg: 'EOL.CV.PI.031416-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.767391529999941, -7.477780829999972],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana XI',
        potencia: 29600.0,
        propriet:
          '100% para Ventos de Santa Joana XI Energias Renováveis S.A (PIE)',
        ini_oper: '2015-07-09',
        ceg: 'EOL.CV.PI.031388-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.79978172999995, -7.482959219999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana XVI',
        potencia: 28900.0,
        propriet:
          '100% para Ventos de Santa Joana XVI Energias Renováveis S.A (PIE)',
        ini_oper: '2015-07-09',
        ceg: 'EOL.CV.PI.031392-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.723669869999981, -7.458821459999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra das Vacas III',
        potencia: 22235.0,
        propriet: '100% para Eólica Serra das Vacas III S.A (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PE.031560-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.757924699999933, -8.903080989999978],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra das Vacas II',
        potencia: 22295.0,
        propriet: '100% para Eólica Serra das Vacas II S.A (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PE.031540-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.72570165999997, -8.895189989999949],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra das Vacas I',
        potencia: 23920.0,
        propriet: '100% para Eólica Serra das Vacas I S.A (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PE.031537-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.747708229999944, -8.911333929999955],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra das Vacas IV',
        potencia: 22295.0,
        propriet: '100% para Eólica Serra das Vacas IV S.A (PIE)',
        ini_oper: '2015-12-18',
        ceg: 'EOL.CV.PE.031574-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-36.729573, -8.91381] },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana I',
        potencia: 28900.0,
        propriet:
          '100% para VENTOS DE SANTA JOANA I ENERGIAS RENOVÁVEIS S.A (PIE)',
        ini_oper: '2016-01-16',
        ceg: 'EOL.CV.PI.031581-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.671528679999938, -7.42365824999996],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana III',
        potencia: 29600.0,
        propriet:
          '100% para VENTOS DE SANTA JOANA III ENERGIAS RENOVÁVEIS S.A (PIE)',
        ini_oper: '2016-03-11',
        ceg: 'EOL.CV.PI.031580-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.746450969999955, -7.437728179999965],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana IV',
        potencia: 27200.0,
        propriet:
          '100% para Ventos de Santa Joana IV Energias Renováveis S.A (PIE)',
        ini_oper: '2016-01-16',
        ceg: 'EOL.CV.PI.031520-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.686372389999974, -7.451333879999936],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana V',
        potencia: 28900.0,
        propriet:
          '100% para Ventos de Santa Joana V Energias Renováveis S.A (PIE)',
        ini_oper: '2016-01-22',
        ceg: 'EOL.CV.PI.031538-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.674174089999951, -7.370887929999981],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Joana VII',
        potencia: 28900.0,
        propriet:
          '100% para VENTOS DE SANTA JOANA VII ENERGIAS RENOVÁVEIS S.A (PIE)',
        ini_oper: '2016-01-30',
        ceg: 'EOL.CV.PI.031597-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.668571759999963, -7.413017809999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto IV',
        potencia: 28900.0,
        propriet:
          '100% para VENTOS DE SANTO AUGUSTO IV ENERGIAS RENOVÁVEIS S.A (PIE)',
        ini_oper: '2016-02-06',
        ceg: 'EOL.CV.PI.031603-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.69776190999994, -7.385817679999946],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto III',
        potencia: 29600.0,
        propriet:
          '100% para Ventos de Santo Augusto III Energias Renováveis S.A (PIE)',
        ini_oper: '2016-01-16',
        ceg: 'EOL.CV.PI.031662-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.707175379999967, -7.372345499999938],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto V',
        potencia: 29600.0,
        propriet:
          '100% para Ventos de Santo Augusto V Energias Renováveis S.A (PIE)',
        ini_oper: '2016-01-22',
        ceg: 'EOL.CV.PI.031661-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.695116119999966, -7.409291609999968],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Baraúnas II',
        potencia: 25850.0,
        propriet: '100% para Baraúnas II Energética S.A (PIE)',
        ini_oper: '2016-03-11',
        ceg: 'EOL.CV.BA.031667-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.063109809999958, -9.871746979999955],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Banda de Couro',
        potencia: 32900.0,
        propriet: '100% para BANDA DE COURO ENERGÉTICA S/A (PIE)',
        ini_oper: '2016-03-11',
        ceg: 'EOL.CV.BA.031607-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.053891449999981, -9.847750149999968],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angelina',
        potencia: 29700.0,
        propriet: '100% para Central Geradora Eólica  Amontada S.A. (PIE)',
        ini_oper: '2016-07-05',
        ceg: 'EOL.CV.PI.031275-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.530013629999985, -7.304707739999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 1',
        potencia: 31365.0,
        propriet:
          '100% para Ventos de São Clemente I Energias Renováveis S.A (PIE)',
        ini_oper: '2016-05-25',
        ceg: 'EOL.CV.PE.031818-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.699932669999953, -8.726319669999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 2',
        potencia: 31365.0,
        propriet:
          '100% para Ventos de São Clemente II Energias Renováveis S.A (PIE)',
        ini_oper: '2016-05-25',
        ceg: 'EOL.CV.PE.031819-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.733656479999979, -8.709304419999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 3',
        potencia: 31365.0,
        propriet:
          '100% para Ventos de São Clemente III Energias Renováveis S.A (PIE)',
        ini_oper: '2016-05-25',
        ceg: 'EOL.CV.PE.031820-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.714149569999961, -8.757650539999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 4',
        potencia: 31365.0,
        propriet:
          '100% para Ventos de São Clemente IV Energias Renováveis S.A (PIE)',
        ini_oper: '2016-05-25',
        ceg: 'EOL.CV.PE.031821-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.748079549999943, -8.765079679999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 5',
        potencia: 31365.0,
        propriet:
          '100% para Ventos de São Clemente V Energias Renováveis S.A (PIE)',
        ini_oper: '2016-06-14',
        ceg: 'EOL.CV.PE.031822-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.762816899999962, -8.709886679999954],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 6',
        potencia: 27675.0,
        propriet:
          '100% para Ventos de São Clemente VI Energias Renováveis S.A (PIE)',
        ini_oper: '2016-05-25',
        ceg: 'EOL.CV.PE.031823-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.758323759999939, -8.781044659999964],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 7',
        potencia: 25830.0,
        propriet:
          '100% para Ventos de São Clemente VII Energias Renováveis S.A (PIE)',
        ini_oper: '2016-05-25',
        ceg: 'EOL.CV.PE.031824-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.750124319999941, -8.750239749999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Clemente 8',
        potencia: 22140.0,
        propriet:
          '100% para Ventos de São Clemente VIII Energias Renováveis S.A (PIE)',
        ini_oper: '2016-06-21',
        ceg: 'EOL.CV.PE.031825-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.775684589999969, -8.70454105999994],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Fátima',
        potencia: 29700.0,
        propriet: '100% para Central Geradora Eólica Bartolomeu S.A. (PIE)',
        ini_oper: '2017-07-28',
        ceg: 'EOL.CV.PI.031273-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.554186349999952, -7.300020159999974],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Regina',
        potencia: 29700.0,
        propriet: '100% para Central Geradora Eólica Boreas S.A. (PIE)',
        ini_oper: '2017-07-19',
        ceg: 'EOL.CV.PI.031276-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.589990309999962, -7.330108869999947],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Bárbara',
        potencia: 29700.0,
        propriet: '100% para Central Geradora Eólica Aristarco S.A. (PIE)',
        ini_oper: '2017-05-26',
        ceg: 'EOL.CV.PI.031274-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.536354159999973, -7.303398219999963],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Adriano',
        potencia: 10800.0,
        propriet: '100% para Central Eólica Colibri Ltda. (PIE)',
        ini_oper: '2017-07-18',
        ceg: 'EOL.CV.PI.031271-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.579478379999955, -7.330579479999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Edwiges',
        potencia: 29700.0,
        propriet: '100% para Central Geradora Eólica Brite S.A. (PIE)',
        ini_oper: '2017-06-24',
        ceg: 'EOL.CV.PI.031270-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.554980539999974, -7.306960919999938],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Albano',
        potencia: 29700.0,
        propriet: '100% para Central Geradora Eólica Caiçara S.A. (PIE)',
        ini_oper: '2017-07-19',
        ceg: 'EOL.CV.PI.031272-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.60046441999998, -7.352916449999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Estevão III',
        potencia: 29900.0,
        propriet:
          '100% para Ventos de Santo Estevão III Energias Renováveis S.A (PIE)',
        ini_oper: '2016-12-09',
        ceg: 'EOL.CV.PE.031763-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.658409459999973, -7.728329659999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Estevão V',
        potencia: 27600.0,
        propriet:
          '100% para Ventos de Santo Estevão V Energias Renováveis S.A (PIE)',
        ini_oper: '2016-12-13',
        ceg: 'EOL.CV.PE.031764-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.673321459999954, -7.759876619999943],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto II',
        potencia: 27600.0,
        propriet:
          '100% para Ventos de Santo Augusto II energias Renováveis S.A (PIE)',
        ini_oper: '2016-12-29',
        ceg: 'EOL.CV.PI.031747-0.02',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.678574699999956, -7.772552059999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto VIII',
        potencia: 18400.0,
        propriet:
          '100% para Ventos de Santo Augusto VIII Energias Renováveis S.A (PIE)',
        ini_oper: '2016-12-24',
        ceg: 'EOL.CV.PI.031767-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.677723749999984, -7.770704519999981],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto I',
        potencia: 18400.0,
        propriet:
          '100% para Ventos de Santo Augusto I Energias Renováveis S.A (PIE)',
        ini_oper: '2017-02-18',
        ceg: 'EOL.CV.PI.031746-2.02',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.675217939999982, -7.742957009999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto VI',
        potencia: 29900.0,
        propriet:
          '100% para Ventos de Santo Augusto VI Energias Renováveis S.A (PIE)',
        ini_oper: '2017-03-24',
        ceg: 'EOL.CV.PI.031766-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.676155049999977, -7.713969129999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Augusto VII',
        potencia: 18400.0,
        propriet:
          '100% para Ventos de Santo Augusto VII Energias Renováveis S.A (PIE)',
        ini_oper: '2017-03-04',
        ceg: 'EOL.CV.PI.031749-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.67930223999997, -7.725210199999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Estevão I',
        potencia: 25300.0,
        propriet:
          '100% para Ventos de Santo Estevão I Energias Renováveis S.A (PIE)',
        ini_oper: '2016-06-23',
        ceg: 'EOL.CV.PE.031761-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.620813209999937, -7.744187469999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Estevão II',
        potencia: 25300.0,
        propriet:
          '100% para Ventos de Santo Estevão II Energias Renováveis S.A (PIE)',
        ini_oper: '2017-05-06',
        ceg: 'EOL.CV.PE.031762-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.629634279999948, -7.748173599999973],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Estevão IV',
        potencia: 29900.0,
        propriet:
          '100% para Ventos de Santo Estevão IV Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-10-03',
        ceg: 'EOL.CV.PE.032365-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.59414548999996, -7.726597759999947],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Onofre IV',
        potencia: 27600.0,
        propriet:
          '100% para Ventos de Santo Onofre IV Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-06-14',
        ceg: 'EOL.CV.PI.032366-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.724352219999957, -7.740924849999942],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Virgílio 01',
        potencia: 29900.0,
        propriet:
          '100% para Ventos de São Virgílio 01 Energias Renováveis S.A (PIE)',
        ini_oper: '2017-08-30',
        ceg: 'EOL.CV.PI.032367-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.688344489999963, -7.688146029999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Virgílio 03',
        potencia: 19800.0,
        propriet:
          '100% para Ventos de São Virgílio 03 Energias Renováveis S.A (PIE)',
        ini_oper: '2017-09-29',
        ceg: 'EOL.CV.PI.032368-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.657394429999954, -7.783500579999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Virgílio 02',
        potencia: 29900.0,
        propriet:
          '100% para Ventos de São Virgilio 02 Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-02-10',
        ceg: 'EOL.CV.PI.033667-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.728039749999937, -7.791699669999957],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Vicente 14',
        potencia: 29400.0,
        propriet:
          '100% para Ventos de Santo Afonso Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-08-02',
        ceg: 'EOL.CV.PI.033138-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.583424109999953, -7.989533449999954],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Vicente 08',
        potencia: 29400.0,
        propriet:
          '100% para Ventos de São Vinicius Energias Renováveis S.A (PIE)',
        ini_oper: '2017-10-06',
        ceg: 'EOL.CV.PI.033144-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.631652569999972, -8.012104649999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Vicente 09',
        potencia: 29400.0,
        propriet:
          '100% para Ventos de Santo Alberto Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-12-06',
        ceg: 'EOL.CV.PI.033143-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.658524239999963, -8.017323089999934],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Vicente 10',
        potencia: 29400.0,
        propriet:
          '100% para Ventos de Santo Agostinho Energias Renováveis S.A (PIE)',
        ini_oper: '2017-11-17',
        ceg: 'EOL.CV.PI.033136-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.637838, -8.006500799999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Vicente 11',
        potencia: 29400.0,
        propriet:
          '100% para Ventos de Santa Albertina Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-11-09',
        ceg: 'EOL.CV.PI.033141-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.634907059999932, -7.995662459999949],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Vicente 12',
        potencia: 29400.0,
        propriet:
          '100% para Ventos de São Casimiro Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-08-29',
        ceg: 'EOL.CV.PI.033140-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.617755809999949, -8.02137326999997],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Vicente 13',
        potencia: 29400.0,
        propriet:
          '100% para Ventos de São Adeodato Energias Renováveis S.A. (PIE)',
        ini_oper: '2017-09-19',
        ceg: 'EOL.CV.PI.033139-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.611469989999932, -8.003105769999934],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Casa Nova II',
        potencia: 32900.0,
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        ini_oper: '2017-12-09',
        ceg: 'EOL.CV.BA.031519-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.05009438999997, -9.270550579999963],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Casa Nova III',
        potencia: 28200.0,
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        ini_oper: '2018-02-28',
        ceg: 'EOL.CV.BA.031524-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.117484719999936, -9.314802049999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Delfina I',
        potencia: 28000.0,
        propriet: '100% para Enel Green Power Delfina a Eolica S.A (PIE)',
        ini_oper: '2017-10-31',
        ceg: 'EOL.CV.BA.032357-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.978680419999932, -10.057508779999978],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Delfina II',
        potencia: 28000.0,
        propriet: '100% para Enel Green Power Delfina a Eolica S.A (PIE)',
        ini_oper: '2017-11-02',
        ceg: 'EOL.CV.BA.032358-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.958618169999966, -10.036945639999942],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Delfina V',
        potencia: 28000.0,
        propriet: '100% para Enel Green Power Delfina a Eolica S.A (PIE)',
        ini_oper: '2017-10-31',
        ceg: 'EOL.CV.BA.032359-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.928364219999935, -10.022876379999957],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Lagoa do Barro 01',
        potencia: 27000.0,
        propriet: '100% para Lagoa do Barro I Energias Renováveis S.A (PIE)',
        ini_oper: '2018-10-27',
        ceg: 'EOL.CV.PI.033621-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.635721549999971, -8.698293159999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Delfina III',
        potencia: 28000.0,
        propriet: '100% para Enel Green Power Delfina B Eólica S.A. (PIE)',
        ini_oper: '2017-11-02',
        ceg: 'EOL.CV.BA.032521-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.933793809999941, -10.00491908999993],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Delfina IV',
        potencia: 8000.0,
        propriet: '100% para Enel Green Power Delfina C Eólica S.A. (PIE)',
        ini_oper: '2017-10-31',
        ceg: 'EOL.CV.BA.032522-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.980929129999936, -10.065802559999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Delfina VI',
        potencia: 30000.0,
        propriet: '100% para Enel Green Power Delfina D Eólica S.A. (PIE)',
        ini_oper: '2017-10-31',
        ceg: 'EOL.CV.BA.032523-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.951968779999959, -10.029237919999957],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Delfina VII',
        potencia: 30000.0,
        propriet: '100% para Enel Green Power Delfina E Eólica S.A. (PIE)',
        ini_oper: '2017-10-31',
        ceg: 'EOL.CV.BA.032524-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.910739899999953, -9.986321829999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Lagoa do Barro 03',
        potencia: 27000.0,
        propriet: '100% para Lagoa do Barro III Energias Renováveis S.A. (PIE)',
        ini_oper: '2018-12-21',
        ceg: 'EOL.CV.PI.033619-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.55139091999996, -8.613661529999945],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Lagoa do Barro 04',
        potencia: 27000.0,
        propriet: '100% para Lagoa do Barro IV Energias Renováveis S.A (PIE)',
        ini_oper: '2018-12-28',
        ceg: 'EOL.CV.PI.033620-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.54198501999997, -8.593391289999943],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Lagoa do Barro 05',
        potencia: 24000.0,
        propriet: '100% para Lagoa do Barro V Energias Renováveis S.A. (PIE)',
        ini_oper: '2018-11-02',
        ceg: 'EOL.CV.PI.033623-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.62373544999997, -8.684135069999968],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Lagoa do Barro 06',
        potencia: 27000.0,
        propriet: '100% para Lagoa do Barro VI Energias Renováveis S.A. (PIE)',
        ini_oper: '2018-12-18',
        ceg: 'EOL.CV.PI.033624-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.560773499999982, -8.632982419999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Lagoa do Barro 07',
        potencia: 27000.0,
        propriet: '100% para Lagoa do Barro VII Energias Renováveis S.A (PIE)',
        ini_oper: '2018-11-27',
        ceg: 'EOL.CV.PI.033618-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.634491499999967, -8.67560310999994],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Queimada Nova 03',
        potencia: 9000.0,
        propriet:
          '100% para Lagoa do Barro VIII Energias Renováveis S.A. (PIE)',
        ini_oper: '2018-12-01',
        ceg: 'EOL.CV.PI.033625-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.630802119999942, -8.667414769999937],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Lagoa do Barro 02',
        potencia: 27000.0,
        propriet: '100% para Lagoa do Barro II Energias Renováveis S.A (PIE)',
        ini_oper: '2018-12-13',
        ceg: 'EOL.CV.PI.033622-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.565363629999979, -8.653790089999973],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Canoas',
        potencia: 31500.0,
        propriet: '100% para CANOAS ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2017-09-30',
        ceg: 'EOL.CV.PB.033663-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.763736949999952, -6.883350569999948],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Lagoa 1',
        potencia: 31500.0,
        propriet: '100% para LAGOA 1 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2017-10-31',
        ceg: 'EOL.CV.PB.033664-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.818430689999957, -6.879040669999938],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Lagoa 2',
        potencia: 31500.0,
        propriet: '100% para LAGOA 2 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2017-09-30',
        ceg: 'EOL.CV.PB.033665-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.791067769999984, -6.840913409999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra das Vacas V',
        potencia: 25300.0,
        propriet: '100% para Eólica Serra das Vacas V S.A. (PIE)',
        ini_oper: '2017-12-01',
        ceg: 'EOL.CV.PE.032360-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.775603539999963, -8.915572379999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra das Vacas VII',
        potencia: 25300.0,
        propriet: '100% para Eólica Serra das Vacas VII S.A. (PIE)',
        ini_oper: '2017-12-01',
        ceg: 'EOL.CV.PE.032361-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.782241259999978, -8.920961199999965],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pedra do Reino IV',
        potencia: 20000.0,
        propriet: '100% para EÓLICA PEDRA DO REINO IV S.A. (PIE)',
        ini_oper: '2018-12-27',
        ceg: 'EOL.CV.BA.034777-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.914227719999985, -9.502864939999938],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 08',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 08 S.A. (PIE)',
        ini_oper: '2020-12-31',
        ceg: 'EOL.CV.PI.033012-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.498353049999935, -8.560800189999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Canoas 2',
        potencia: 34650.0,
        propriet: '100% para CANOAS 2 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-12-31',
        ceg: 'EOL.CV.PB.035181-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.791358669999966, -6.882510009999976],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Canoas 4',
        potencia: 27720.0,
        propriet: '100% para CANOAS 4 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-12-31',
        ceg: 'EOL.CV.PB.037953-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.735549289999938, -6.843582269999956],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Chafariz 1',
        potencia: 34650.0,
        propriet: '100% para CHAFARIZ 1 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-08-13',
        ceg: 'EOL.CV.PB.034639-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.879275249999978, -6.972885079999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Chafariz 2',
        potencia: 34650.0,
        propriet: '100% para CHAFARIZ 2 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-09-15',
        ceg: 'EOL.CV.PB.034640-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.893951179999931, -6.996852539999963],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Chafariz 3',
        potencia: 34660.0,
        propriet: '100% para Chafariz 3 Energia Renovável S.A. (PIE)',
        ini_oper: '2021-09-25',
        ceg: 'EOL.CV.PB.034642-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.898279009999953, -7.001597229999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Chafariz 6',
        potencia: 31185.0,
        propriet: '100% para CHAFARIZ 6 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-08-24',
        ceg: 'EOL.CV.PB.035184-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.853932399999962, -6.978607659999966],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Chafariz 7',
        potencia: 34650.0,
        propriet: '100% para CHAFARIZ 7 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-07-29',
        ceg: 'EOL.CV.PB.037958-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.860747759999981, -6.923992899999973],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Lagoa 3',
        potencia: 34650.0,
        propriet: '100% para LAGOA 3 ENERGIA RENOVÁVEL S.A (PIE)',
        ini_oper: '2021-12-18',
        ceg: 'EOL.CV.PB.038029-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.82620038999994, -6.871191919999944],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Lagoa 4',
        potencia: 20790.0,
        propriet: '100% para LAGOA 4 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-12-03',
        ceg: 'EOL.CV.PB.038030-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.841490269999952, -6.882361859999946],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 01',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 1 S.A. (PIE)',
        ini_oper: '2020-11-12',
        ceg: 'EOL.CV.PI.033005-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.558435599999939, -8.519787419999943],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 02',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 2 S.A. (PIE)',
        ini_oper: '2020-11-12',
        ceg: 'EOL.CV.PI.033006-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.575102049999941, -8.544166209999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 03',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 3 S.A. (PIE)',
        ini_oper: '2020-11-12',
        ceg: 'EOL.CV.PI.033007-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.577824739999983, -8.576417409999976],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 04',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 4 S.A. (PIE)',
        ini_oper: '2020-11-12',
        ceg: 'EOL.CV.PI.033008-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.590750849999949, -8.600177859999974],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 05',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA 5 S.A. (PIE)',
        ini_oper: '2020-12-29',
        ceg: 'EOL.CV.PI.033009-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.445060549999937, -8.462506909999945],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 06',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA 6 S.A. (PIE)',
        ini_oper: '2020-12-30',
        ceg: 'EOL.CV.PI.033010-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.451646609999955, -8.48910780999995],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 07',
        potencia: 25200.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA 7 S.A. (PIE)',
        ini_oper: '2020-12-30',
        ceg: 'EOL.CV.PI.033011-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.480508159999943, -8.530622609999966],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 09',
        potencia: 44100.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 09 S.A. (PIE)',
        ini_oper: '2020-12-31',
        ceg: 'EOL.CV.PI.033013-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.479528529999982, -8.595226439999976],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 10',
        potencia: 40950.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 10 S.A. (PIE)',
        ini_oper: '2020-12-31',
        ceg: 'EOL.CV.PI.033014-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.497109299999977, -8.632896559999949],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 11',
        potencia: 44100.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 11 S.A. (PIE)',
        ini_oper: '2020-12-31',
        ceg: 'EOL.CV.PI.033015-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.509758089999934, -8.667072909999945],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 14',
        potencia: 42600.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 14 S.A. (PIE)',
        ini_oper: '2020-12-31',
        ceg: 'EOL.CV.PI.033018-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.518100839999931, -8.708777269999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 15',
        potencia: 43500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA 15 S.A. (PIE)',
        ini_oper: '2021-03-27',
        ceg: 'EOL.CV.PI.033019-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.474073899999951, -8.722025659999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 17',
        potencia: 47250.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA 17 S.A. (PIE)',
        ini_oper: '2021-04-07',
        ceg: 'EOL.CV.PI.033021-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.420879579999962, -8.715924989999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 19',
        potencia: 30000.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA 19 S.A. (PIE)',
        ini_oper: '2021-04-07',
        ceg: 'EOL.CV.PI.033023-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.471571689999962, -8.746180159999938],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Ângela 20',
        potencia: 30000.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA 20 S.A. (PIE)',
        ini_oper: '2021-02-13',
        ceg: 'EOL.CV.PI.033024-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.451975339999933, -8.78176979999995],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Ângela 21',
        potencia: 27000.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA 21 S.A. (PIE)',
        ini_oper: '2021-01-28',
        ceg: 'EOL.CV.PI.033025-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.465112199999965, -8.806563429999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'ZEUS II',
        potencia: 29400.0,
        propriet: '100% para ENEL GREEN POWER ZEUS II - DELFINA 8 S.A. (PIE)',
        ini_oper: '2019-08-30',
        ceg: 'EOL.CV.BA.035266-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.881554549999976, -9.969845339999949],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 12',
        potencia: 30000.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA ACL 12 S.A. (PIE)',
        ini_oper: '2021-03-19',
        ceg: 'EOL.CV.PI.033016-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.430295459999968, -8.761648339999965],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Angela 13',
        potencia: 28350.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA ACL 13 S.A. (PIE)',
        ini_oper: '2021-03-19',
        ceg: 'EOL.CV.PI.033017-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.408723709999947, -8.766440799999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Ângela 18',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ÂNGELA ACL 18 S.A (PIE)',
        ini_oper: '2021-03-13',
        ceg: 'EOL.CV.PI.033022-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.399901769999985, -8.738869459999933],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santa Ângela 16',
        potencia: 31500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SANTA ANGELA ACL 16 S.A (PIE)',
        ini_oper: '2021-05-19',
        ceg: 'EOL.CV.PI.033020-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.558988019999958, -8.73258661999995],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Januário 03',
        potencia: 33600.0,
        propriet: '100% para PARQUE EÓLICO VENTOS DE SÃO JANUÁRIO 03 S.A (PIE)',
        ini_oper: '2020-11-06',
        ceg: 'EOL.CV.BA.033521-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.440364939999938, -10.310530079999978],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Januário 05',
        potencia: 42000.0,
        propriet: '100% para PARQUE EÓLICO VENTOS DE SÃO JANUÁRIO 05 S.A (PIE)',
        ini_oper: '2020-12-03',
        ceg: 'EOL.CV.BA.032644-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.434053439999957, -10.289825439999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Januário 10',
        potencia: 42000.0,
        propriet: '100% para PARQUE EÓLICO VENTOS DE SÃO JANUÁRIO 10 S.A (PIE)',
        ini_oper: '2021-01-30',
        ceg: 'EOL.CV.BA.033529-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.430618769999967, -10.265075239999931],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Januário 11',
        potencia: 42000.0,
        propriet: '100% para PARQUE EÓLICO VENTOS DE SÃO JANUÁRIO 11 S.A (PIE)',
        ini_oper: '2021-01-06',
        ceg: 'EOL.CV.BA.033530-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.424476929999969, -10.243094509999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Queimada Nova 01',
        potencia: 32200.0,
        propriet: '100% para LAGOA DO BARRO IX ENERGIAS RENOVÁVEIS S.A. (PIE)',
        ini_oper: '2021-12-08',
        ceg: 'EOL.CV.PI.032863-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.644612139999936, -8.717667859999949],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Queimada Nova 02',
        potencia: 46000.0,
        propriet: '100% para LAGOA DO BARRO X ENERGIAS RENOVÁVEIS S.A. (PIE)',
        ini_oper: '2021-12-08',
        ceg: 'EOL.CV.PI.040567-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.60169381999998, -8.635038379999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Umburana de Cheiro',
        potencia: 31185.0,
        propriet: '100% para UMBURANA DE CHEIRO ENERGÉTICA S.A. (PIE)',
        ini_oper: '2020-12-31',
        ceg: 'EOL.CV.BA.035233-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-41.042697, -9.835541] },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra do Vento',
        potencia: 31185.0,
        propriet: '100% para SERRA DO VENTO ENERGÉTICA S.A. (PIE)',
        ini_oper: '2020-11-19',
        ceg: 'EOL.CV.BA.035229-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-41.038889, -9.81603] },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Serra do Fogo',
        potencia: 31185.0,
        propriet: '100% para SERRA DO FOGO ENERGÉTICA S.A. (PIE)',
        ini_oper: '2020-12-05',
        ceg: 'EOL.CV.BA.035222-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-41.022709, -9.805084] },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Casa Nova A',
        potencia: 27000.0,
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        ini_oper: '2020-12-24',
        ceg: 'EOL.CV.BA.037209-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.016813389999982, -9.260208549999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Canoas 3',
        potencia: 10395.0,
        propriet: '100% para CANOAS 3 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-12-31',
        ceg: 'EOL.CV.PB.037952-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.75935512999996, -6.87214935999998],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Arapuá 1',
        potencia: 24255.0,
        propriet: '100% para VENTOS DE ARAPUÁ 1 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-11-11',
        ceg: 'EOL.CV.PB.035240-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.952411179999956, -7.048233549999964],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Arapuá 2',
        potencia: 34650.0,
        propriet: '100% para VENTOS DE ARAPUÁ 2 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-11-11',
        ceg: 'EOL.CV.PB.035241-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.962448289999941, -7.029193219999968],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Arapuá 3',
        potencia: 13860.0,
        propriet: '100% para VENTOS DE ARAPUÁ 3 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-11-11',
        ceg: 'EOL.CV.PB.035242-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.967892099999972, -7.050796289999937],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Chafariz 5',
        potencia: 34650.0,
        propriet: '100% para CHAFARIZ 5 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-09-25',
        ceg: 'EOL.CV.PB.034646-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.913089189999937, -7.003914169999973],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Chafariz 4',
        potencia: 34650.0,
        propriet: '100% para CHAFARIZ 4 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-10-21',
        ceg: 'EOL.CV.PB.034644-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.935487409999951, -7.033728289999942],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Tacaicó II',
        potencia: 49500.0,
        propriet: '100% para Enel Green Power Fontes dos Ventos 3 S.A. (PIE)',
        ini_oper: '2021-12-22',
        ceg: 'EOL.CV.PE.044554-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.074718, -9.016689219999932],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pau Ferro II',
        potencia: 49500.0,
        propriet: '100% para Enel Green Power Fontes Dos Ventos 2 S.A. (PIE)',
        ini_oper: '2021-12-22',
        ceg: 'EOL.CV.PE.044553-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.099877699999979, -9.036458569999979],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Aura Queimada Nova 02',
        potencia: 4600.0,
        propriet: '100% para LAGOA DO BARRO X ENERGIAS RENOVÁVEIS S.A. (PIE)',
        ini_oper: '2021-12-08',
        ceg: 'EOL.CV.PI.040567-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.60169381999998, -8.635038379999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Canoas 3',
        potencia: 24260.0,
        propriet: '100% para CANOAS 3 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-12-31',
        ceg: 'EOL.CV.PB.037952-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.75935512999996, -6.87214935999998],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Canoas 4',
        potencia: 6930.0,
        propriet: '100% para CANOAS 4 ENERGIA RENOVÁVEL S.A. (PIE)',
        ini_oper: '2021-12-31',
        ceg: 'EOL.CV.PB.037953-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.735549289999938, -6.843582269999956],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Morro Branco II',
        potencia: 31185.0,
        propriet: '100% para Morro Branco II Energética S.A. (PIE)',
        ini_oper: '2022-07-09',
        ceg: 'EOL.CV.BA.032417-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.02266979999996, -9.766024179999931],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Pedra do Reino V',
        potencia: 16000.0,
        propriet: '100% para EÓLICA PEDRA DO REINO V S.A. (PIE)',
        ini_oper: '2022-03-19',
        ceg: 'EOL.CV.BA.037069-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.895798929999955, -9.549449679999952],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'São Pedro do Lago',
        potencia: 1465.0,
        propriet: '100% para São Pedro do Lago S.A (PIE)',
        ini_oper: '2013-03-02',
        ceg: 'EOL.CV.BA.030456-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.09301107999994, -9.874693259999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de Santo Alderico',
        potencia: 39600.0,
        propriet:
          '100% para VENTOS DE SANTO ALDERICO ENERGIAS RENOVÁVEIS S/A (PIE)',
        ini_oper: '2022-09-02',
        ceg: 'EOL.CV.PI.048512-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.666452239999956, -8.041749279999976],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Roque 01',
        potencia: 49500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SÃO ROQUE 01 S.A. (PIE)',
        ini_oper: '2022-10-11',
        ceg: 'EOL.CV.PI.038103-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.657532169999968, -8.764600329999951],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Ventos de São Roque 02',
        potencia: 49500.0,
        propriet:
          '100% para ENEL GREEN POWER VENTOS DE SÃO ROQUE 02 S.A. (PIE)',
        ini_oper: '2022-10-01',
        ceg: 'EOL.CV.PI.038104-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.650384259999953, -8.805587619999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'Baraúnas XV (Antiga Massaroca I)',
        potencia: 48510.0,
        propriet: '100% para Baraúnas XV Energética S.A. (PIE)',
        ini_oper: '2022-09-17',
        ceg: 'EOL.CV.BA.038031-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.975326379999956, -9.750103259999946],
      },
    },
  ],
};
