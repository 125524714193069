export var jsonTerrasIndigenasHomologadasPE6 = {
  type: 'FeatureCollection',
  name: 'terras_indigenas_homologadas_PE_6',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        gid: 41.0,
        terrai_cod: 4501.0,
        terrai_nom: 'Atikum',
        etnia_nome: 'Atikum',
        municipio_:
          'Salgueiro,Mirandiba,Carnaubeira da Penha,Belém do São Francisco',
        uf_sigla: 'PE',
        superficie: 16290.1893,
        fase_ti: 'Regularizada',
        modalidade: 'Tradicionalmente ocupada',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        faixa_fron: 'Não',
        undadm_cod: 30202001983.0,
        undadm_nom: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        undadm_sig: 'CR-BSF',
        dominio_un: 't',
        data_atual: null,
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.804022910623061, -8.222151064816579],
              [-38.804022275372922, -8.222150431326119],
              [-38.750214714172877, -8.240337966718112],
              [-38.740561527040782, -8.261454365401795],
              [-38.752744091889674, -8.3097928207703],
              [-38.775906007965091, -8.334544655287898],
              [-38.897408578524896, -8.35635360542779],
              [-38.919248380507014, -8.337537118600595],
              [-38.804022910623061, -8.222151064816579],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1273.0,
        terrai_cod: 11301.0,
        terrai_nom: 'Entre Serras',
        etnia_nome: 'Pankararú',
        municipio_: 'Petrolândia,Tacaratu,Jatobá',
        uf_sigla: 'PE',
        superficie: 7550.0875,
        fase_ti: 'Regularizada',
        modalidade: 'Tradicionalmente ocupada',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        faixa_fron: 'Não',
        undadm_cod: 30202001983.0,
        undadm_nom: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        undadm_sig: 'CR-BSF',
        dominio_un: 't',
        data_atual: null,
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.149595908802269, -9.089934164361011],
              [-38.147472101457538, -9.097651618826887],
              [-38.155434935680717, -9.098962384187644],
              [-38.15780747477055, -9.104094829471023],
              [-38.15518377513007, -9.109968504637436],
              [-38.145995308491138, -9.108169770076131],
              [-38.144913553690742, -9.109136129965407],
              [-38.143940205919478, -9.106496048267786],
              [-38.14058696869165, -9.113995782501775],
              [-38.137648544348622, -9.120570758571603],
              [-38.134974527235102, -9.126528709566287],
              [-38.131204458450839, -9.134960928323324],
              [-38.128072936672595, -9.141954548790068],
              [-38.123846149565225, -9.151396081804762],
              [-38.118928819005873, -9.162386612718137],
              [-38.124020110243634, -9.163979916908772],
              [-38.131545070296397, -9.166330259196229],
              [-38.140587684572949, -9.169154705498897],
              [-38.148660561011354, -9.171676505521589],
              [-38.150052485608512, -9.172110399359269],
              [-38.150105464750823, -9.171946908495556],
              [-38.151850472859167, -9.16702255248947],
              [-38.154857390847781, -9.158575045111149],
              [-38.157699812330691, -9.150530354651437],
              [-38.160957853788489, -9.141342808695015],
              [-38.163629056246293, -9.133840035697574],
              [-38.165602276732386, -9.128270452128675],
              [-38.168202096126095, -9.120921390711658],
              [-38.172231044552802, -9.109540883989382],
              [-38.175006796711102, -9.101701529960792],
              [-38.177991732571229, -9.09325137109073],
              [-38.182343877004257, -9.094672191684026],
              [-38.192816310103751, -9.098085069876504],
              [-38.2022842974377, -9.101159349116585],
              [-38.212613541340147, -9.104522676366344],
              [-38.221597641405424, -9.107428454925127],
              [-38.23029374715577, -9.110261141440333],
              [-38.237984533721942, -9.112772621860302],
              [-38.247883467313443, -9.115995909613446],
              [-38.253475012460058, -9.117808906760615],
              [-38.255736464733673, -9.118549107376168],
              [-38.256014700093431, -9.118639265247566],
              [-38.256275967677709, -9.117890964970741],
              [-38.259105584986685, -9.110032945456169],
              [-38.261615153382195, -9.103007884044814],
              [-38.26451850714767, -9.094908213032795],
              [-38.265808481892748, -9.091314093739584],
              [-38.257012507112108, -9.087457370238042],
              [-38.249279415408864, -9.084063843574199],
              [-38.238912247986661, -9.079513647612828],
              [-38.23156312471184, -9.076293348256968],
              [-38.222892878257085, -9.072485879558599],
              [-38.213678145994606, -9.068437718492305],
              [-38.20685610520303, -9.065449000771991],
              [-38.197918233470887, -9.06152598022021],
              [-38.190150252439651, -9.058114155813087],
              [-38.180418269031009, -9.053835486851938],
              [-38.173043302807798, -9.050597233969029],
              [-38.163052332655809, -9.046212048046186],
              [-38.161766816911438, -9.045643846715222],
              [-38.159591568009205, -9.053543115042487],
              [-38.156957946535215, -9.063123999892696],
              [-38.154181693458838, -9.073230782055077],
              [-38.151884285712015, -9.081586725287877],
              [-38.149595908802269, -9.089934164361011],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 234.0,
        terrai_cod: 62201.0,
        terrai_nom: 'Fazenda Cristo Rei',
        etnia_nome: 'Pankararú',
        municipio_: 'Jatobá',
        uf_sigla: 'PE',
        superficie: 0.0,
        fase_ti: 'Encaminhada RI',
        modalidade: 'Reserva Indígena',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        faixa_fron: 'Não',
        undadm_cod: 30202001983.0,
        undadm_nom: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        undadm_sig: 'CR-BSF',
        dominio_un: 't',
        data_atual: '19/07/2018',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.158884281900001, -9.2626776641],
              [-38.159105665299997, -9.2634111359],
              [-38.159337847899998, -9.263909541],
              [-38.159945505400003, -9.2645635328],
              [-38.160579748700002, -9.265035471099999],
              [-38.161218393200002, -9.2654116417],
              [-38.161637731200003, -9.265603992899999],
              [-38.162091838599999, -9.265832720700001],
              [-38.1623725006, -9.2658708603],
              [-38.162811332499999, -9.265837234299999],
              [-38.162974886100002, -9.2660448481],
              [-38.163250747900001, -9.266187464],
              [-38.163835641200002, -9.2665438988],
              [-38.164548519599997, -9.267088986],
              [-38.165077659799998, -9.267469216],
              [-38.165365015200003, -9.2675599826],
              [-38.165688740299998, -9.2676523002],
              [-38.166175911300002, -9.268152872],
              [-38.1665205133, -9.2683856577],
              [-38.166645988900001, -9.2688271881],
              [-38.166921743400003, -9.2691704453],
              [-38.167185385499998, -9.269975537900001],
              [-38.167373195499998, -9.2706465434],
              [-38.167700813300002, -9.2708524379],
              [-38.168172310199999, -9.271099364499999],
              [-38.168532297, -9.2713938771],
              [-38.168683957500001, -9.2716620564],
              [-38.168874277699999, -9.2720801878],
              [-38.168994035600001, -9.272844257199999],
              [-38.169233194500002, -9.2735872506],
              [-38.169367356400002, -9.2740378835],
              [-38.169604337199999, -9.2744318378],
              [-38.170058451199999, -9.274660582799999],
              [-38.170382581, -9.274744202200001],
              [-38.170819711100002, -9.274946051700001],
              [-38.171184388500002, -9.2753367334],
              [-38.171364813099999, -9.2757718972],
              [-38.171384772800003, -9.2761304242],
              [-38.171381056599998, -9.276409427],
              [-38.17134686, -9.2767569194],
              [-38.171675051400001, -9.2773466953],
              [-38.172220379099997, -9.2777712626],
              [-38.172738143700002, -9.278002730300001],
              [-38.173289076800003, -9.278305404799999],
              [-38.1735811275, -9.2784923456],
              [-38.173688923599997, -9.278723766800001],
              [-38.173612464500003, -9.278999668200001],
              [-38.173471155199998, -9.2792989734],
              [-38.173219015699999, -9.2798290924],
              [-38.173265047199997, -9.2802149077],
              [-38.173470058299998, -9.2805115464],
              [-38.173736716100002, -9.2808544364],
              [-38.173923541699999, -9.2811502999],
              [-38.174040711499998, -9.2815740491],
              [-38.174180871700003, -9.2818940931],
              [-38.174374104, -9.2820506486],
              [-38.174482298, -9.2822733655],
              [-38.174620173, -9.2824450061],
              [-38.174871165500001, -9.2825342363],
              [-38.1753054968, -9.282797048600001],
              [-38.1757752806, -9.2832794743],
              [-38.176054516900003, -9.2837450428],
              [-38.1761823796, -9.284134357499999],
              [-38.176201128400002, -9.284519014100001],
              [-38.176152221599999, -9.2849880222],
              [-38.176226727200003, -9.2853488876],
              [-38.176494184399999, -9.285674372300001],
              [-38.1767897211, -9.2859836085],
              [-38.177100238900003, -9.2863632774],
              [-38.177363810700001, -9.2865751854],
              [-38.177824741400002, -9.286655936],
              [-38.178309345800002, -9.2868162141],
              [-38.178748085700001, -9.286983258],
              [-38.179513648099999, -9.2873736364],
              [-38.180002940500003, -9.287630081],
              [-38.180601347600003, -9.287891185199999],
              [-38.180989417500001, -9.288169481300001],
              [-38.1815573425, -9.288499078799999],
              [-38.181989395, -9.2886134936],
              [-38.182317540200003, -9.2886100564],
              [-38.182774869100001, -9.2887691715],
              [-38.183243574899997, -9.289077085400001],
              [-38.183395230899997, -9.2893452897],
              [-38.18350691, -9.289690306],
              [-38.183596517399998, -9.2899209641],
              [-38.183723297500002, -9.2901357612],
              [-38.1840030551, -9.2903919882],
              [-38.184304884, -9.2907625745],
              [-38.184819214599997, -9.2914650373],
              [-38.185596318499996, -9.2923968346],
              [-38.186204103900003, -9.2928502871],
              [-38.187053916899998, -9.293191935099999],
              [-38.187772543900003, -9.293414563],
              [-38.188380173299997, -9.293475409899999],
              [-38.189038755600002, -9.293616952600001],
              [-38.189618025100003, -9.293502096199999],
              [-38.189945894300003, -9.293306707],
              [-38.190149145, -9.2932455892],
              [-38.190288463500004, -9.292989800599999],
              [-38.1903376526, -9.2927127144],
              [-38.190245358399999, -9.2923423418],
              [-38.190187032799997, -9.2920257671],
              [-38.189978813700002, -9.291798763399999],
              [-38.189688357500003, -9.2915769738],
              [-38.189337336100003, -9.291483466800001],
              [-38.188821039600001, -9.2914178016],
              [-38.188354613599998, -9.291258295700001],
              [-38.187866115799999, -9.2909844294],
              [-38.187406098700002, -9.2906856063],
              [-38.1870887655, -9.2904539467],
              [-38.186820101099997, -9.2901545699],
              [-38.186556360799997, -9.289550049200001],
              [-38.186470873600001, -9.289031659],
              [-38.186593320599997, -9.2885483187],
              [-38.186857648599997, -9.2881495539],
              [-38.187175215099998, -9.287979895699999],
              [-38.187425527599999, -9.2878858864],
              [-38.187742977600003, -9.2879168846],
              [-38.187734685499997, -9.287899081699999],
              [-38.187956802599999, -9.28802198],
              [-38.1879367831, -9.288457349],
              [-38.187779284599998, -9.2887123604],
              [-38.187747484500001, -9.289007634800001],
              [-38.187886046599999, -9.2893625283],
              [-38.188015632899997, -9.289516376],
              [-38.188188281099997, -9.2897244091],
              [-38.188436475800003, -9.289874595900001],
              [-38.189124334900001, -9.289973755],
              [-38.1897411693, -9.289834319],
              [-38.190174943400002, -9.289713241399999],
              [-38.190705536199999, -9.289666091799999],
              [-38.191079947699997, -9.289647173800001],
              [-38.191413183, -9.2897311932],
              [-38.1917985738, -9.289869786300001],
              [-38.1921732705, -9.2900428217],
              [-38.192509994200002, -9.2902491351],
              [-38.193190648600002, -9.2905050264],
              [-38.193470127799998, -9.2905693024],
              [-38.193702941399998, -9.2906577608],
              [-38.193999005400002, -9.290757643399999],
              [-38.194233820800001, -9.2908025637],
              [-38.1944230565, -9.291046207500001],
              [-38.1944233416, -9.291238163699999],
              [-38.194301814600003, -9.2915034435],
              [-38.194295408499997, -9.291642765700001],
              [-38.194297294599998, -9.2917998913],
              [-38.194435174900001, -9.291971546199999],
              [-38.194672677699998, -9.292156178200001],
              [-38.194950156099999, -9.292263993900001],
              [-38.195171475, -9.2924043118],
              [-38.195487211500001, -9.2926708066],
              [-38.195862595199998, -9.293027095699999],
              [-38.196174728300001, -9.2933719611],
              [-38.196408628, -9.293634964500001],
              [-38.1966098758, -9.293617380300001],
              [-38.196860592699998, -9.2935146591],
              [-38.197000191299999, -9.2934508189],
              [-38.197301462200002, -9.2934375033],
              [-38.197438257899996, -9.293434617100001],
              [-38.197639505600002, -9.293417032100001],
              [-38.1978194796, -9.2932676659],
              [-38.197879242399999, -9.2931567932],
              [-38.198005458700003, -9.2929876825],
              [-38.198099706400001, -9.2927212336],
              [-38.198241019900003, -9.2924218936],
              [-38.198579176099997, -9.292200752099999],
              [-38.198875756, -9.292091259299999],
              [-38.1992087076, -9.2919833181],
              [-38.199585122599998, -9.291920855400001],
              [-38.200003800399998, -9.2919299949],
              [-38.200333949700003, -9.291883007],
              [-38.200601648800003, -9.291807179299999],
              [-38.200824683199997, -9.2917119955],
              [-38.201177820600002, -9.2915612873],
              [-38.201536849100002, -9.291480629600001],
              [-38.201868887300002, -9.291590770199999],
              [-38.202054526, -9.2919127909],
              [-38.202107371399997, -9.292150621199999],
              [-38.202134538499998, -9.292352456],
              [-38.202102744800001, -9.2926477505],
              [-38.202136603, -9.2929022211],
              [-38.2020651212, -9.2934662982],
              [-38.202206607, -9.293559585700001],
              [-38.202545624700001, -9.2939143285],
              [-38.202829797299998, -9.294074780300001],
              [-38.202893335299997, -9.2942781681],
              [-38.2030296175, -9.2944846601],
              [-38.203267924800002, -9.2946518806],
              [-38.203307099, -9.2945924766],
              [-38.204250873900001, -9.2930884025],
              [-38.205406168899998, -9.291540985299999],
              [-38.205677793500001, -9.290784756400001],
              [-38.207776763200002, -9.2877331841],
              [-38.210267541599997, -9.2844801088],
              [-38.2129828395, -9.2810969042],
              [-38.220020524200002, -9.273447475399999],
              [-38.224124130600003, -9.269381261099999],
              [-38.230437696199999, -9.263401743299999],
              [-38.235646619900002, -9.2578636016],
              [-38.236984772900001, -9.256489266599999],
              [-38.2382870446, -9.254703253],
              [-38.198519966900001, -9.2358447256],
              [-38.158884281900001, -9.2626776641],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 452.0,
        terrai_cod: 13101.0,
        terrai_nom: 'Fulni-ô',
        etnia_nome: 'Fulni-ô',
        municipio_: 'Itaíba,Águas Belas',
        uf_sigla: 'PE',
        superficie: 11505.71,
        fase_ti: 'Regularizada',
        modalidade: 'Reserva Indígena',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        faixa_fron: 'Não',
        undadm_cod: 30202001983.0,
        undadm_nom: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        undadm_sig: 'CR-BSF',
        dominio_un: 't',
        data_atual: '30/06/2022',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.0559119, -9.07174298],
              [-37.07146747, -9.16729981],
              [-37.16813459, -9.15146636],
              [-37.15285681, -9.05618731],
              [-37.0559119, -9.07174298],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 316.0,
        terrai_cod: 67501.0,
        terrai_nom: 'Ilhas da Tapera/São Félix/Porto',
        etnia_nome: 'Truká',
        municipio_: 'Orocó',
        uf_sigla: 'PE',
        superficie: 0.0,
        fase_ti: 'Encaminhada RI',
        modalidade: 'Reserva Indígena',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        faixa_fron: 'Não',
        undadm_cod: 30202001983.0,
        undadm_nom: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        undadm_sig: 'CR-BSF',
        dominio_un: 't',
        data_atual: '02/04/2020',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.59285066, -8.58864105],
              [-39.59181059, -8.59180961],
              [-39.59404327, -8.59573879],
              [-39.59540116, -8.59248055],
              [-39.59494518, -8.59139578],
              [-39.59430783, -8.59049282],
              [-39.59398854, -8.58976972],
              [-39.59321498, -8.58904733],
              [-39.59285066, -8.58864105],
            ],
          ],
          [
            [
              [-39.59147017, -8.59280326],
              [-39.58500974, -8.61122015],
              [-39.58488107, -8.61158784],
              [-39.5864823, -8.61323391],
              [-39.58670191, -8.61373502],
              [-39.58687503, -8.613313],
              [-39.59370213, -8.5965614],
              [-39.59147017, -8.59280326],
            ],
          ],
          [
            [
              [-39.55805456, -8.5884448],
              [-39.55433241, -8.58973003],
              [-39.55273959, -8.58901849],
              [-39.5528629, -8.59290057],
              [-39.55516653, -8.59745056],
              [-39.555858, -8.600116],
              [-39.55838473, -8.6011924],
              [-39.56181155, -8.60312899],
              [-39.56843092, -8.60403515],
              [-39.57464183, -8.60735933],
              [-39.57614849, -8.61171499],
              [-39.57953227, -8.61392454],
              [-39.58085121, -8.61718195],
              [-39.58220599, -8.61949258],
              [-39.5835673, -8.62059824],
              [-39.58487192, -8.62284264],
              [-39.58850526, -8.62318242],
              [-39.58744763, -8.62229393],
              [-39.5874977, -8.62087427],
              [-39.5865046, -8.61945041],
              [-39.58686443, -8.61804926],
              [-39.58611498, -8.61637666],
              [-39.58316593, -8.61120748],
              [-39.57824045, -8.60552461],
              [-39.57474159, -8.60330644],
              [-39.57275894, -8.60159087],
              [-39.57136704, -8.5989256],
              [-39.56883873, -8.59722945],
              [-39.56495948, -8.59475722],
              [-39.56155831, -8.59167652],
              [-39.55805456, -8.5884448],
            ],
          ],
          [
            [
              [-39.56817047, -8.60446671],
              [-39.56810605, -8.60465783],
              [-39.56604315, -8.6108237],
              [-39.56788904, -8.61137425],
              [-39.57017621, -8.61359048],
              [-39.57169985, -8.61377735],
              [-39.57597507, -8.61410445],
              [-39.57732848, -8.61518645],
              [-39.57894642, -8.61635541],
              [-39.57961354, -8.61632601],
              [-39.57924327, -8.61595582],
              [-39.57985373, -8.61619983],
              [-39.57951417, -8.61546762],
              [-39.57842972, -8.61489716],
              [-39.57621643, -8.61259106],
              [-39.57510661, -8.61080247],
              [-39.57464138, -8.60881067],
              [-39.57370436, -8.60739564],
              [-39.57115061, -8.60647596],
              [-39.56961505, -8.60516827],
              [-39.56817047, -8.60446671],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 389.0,
        terrai_cod: 20201.0,
        terrai_nom: 'Kambiwá',
        etnia_nome: 'Kambiwá',
        municipio_: 'Inajá,Ibimirim,Floresta',
        uf_sigla: 'PE',
        superficie: 31495.3123,
        fase_ti: 'Regularizada',
        modalidade: 'Tradicionalmente ocupada',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL NORDESTE I',
        faixa_fron: 'Não',
        undadm_cod: 30202001969.0,
        undadm_nom: 'COORDENACAO REGIONAL NORDESTE I',
        undadm_sig: 'CR-NE-I',
        dominio_un: 't',
        data_atual: '07/07/2020',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.9131044194, -8.5529430169388],
              [-37.909053218899999, -8.553104080240001],
              [-37.889215939976999, -8.553092287200499],
              [-37.876651050230002, -8.565446627315],
              [-37.86362034, -8.57813667],
              [-37.85068167, -8.59083711],
              [-37.83774216, -8.60353726],
              [-37.82480181, -8.61623631],
              [-37.81186064, -8.6289348],
              [-37.799337354243001, -8.6415994896605],
              [-37.7859755, -8.65433067],
              [-37.77303155, -8.66702749],
              [-37.76008704, -8.67972404],
              [-37.74714114, -8.6924203],
              [-37.745646231022, -8.6939248509356],
              [-37.75867096, -8.70574944],
              [-37.77210224, -8.71793283],
              [-37.78553404, -8.73010862],
              [-37.79897464, -8.74229457],
              [-37.81241538, -8.75448162],
              [-37.82584755, -8.76666069],
              [-37.836974490918998, -8.776782940053501],
              [-37.84926295, -8.76283079],
              [-37.86122551, -8.74921062],
              [-37.87318724, -8.73559017],
              [-37.88514785, -8.72196917],
              [-37.89262678, -8.71345156],
              [-37.89274928, -8.71335017],
              [-37.90549935, -8.70068057],
              [-37.91837108, -8.68790874],
              [-37.93124198, -8.67513664],
              [-37.943757722331, -8.662635709065199],
              [-37.95698128, -8.64959049],
              [-37.95711072, -8.64946049],
              [-37.9698494, -8.63681672],
              [-37.98271696, -8.62404212],
              [-37.99098997566, -8.6160340571224],
              [-37.98438663, -8.61030744],
              [-37.98434312, -8.61026212],
              [-37.97733258, -8.60415097],
              [-37.97728908, -8.60410566],
              [-37.97677192, -8.60365792],
              [-37.97269825, -8.60007861],
              [-37.9718043, -8.59930207],
              [-37.97035877, -8.59803286],
              [-37.9669763, -8.59508266],
              [-37.96269952, -8.59136473],
              [-37.96065742, -8.58959244],
              [-37.95746828, -8.58679831],
              [-37.95446601, -8.58409885],
              [-37.95129467, -8.58131425],
              [-37.94953403, -8.57976248],
              [-37.94712535, -8.57763586],
              [-37.94577594, -8.57645784],
              [-37.94206213, -8.57318118],
              [-37.94002988, -8.5713921],
              [-37.93838059, -8.57021083],
              [-37.93829283, -8.57013762],
              [-37.93696054, -8.56919558],
              [-37.93679296, -8.56907561],
              [-37.93678388, -8.56907525],
              [-37.93427894, -8.56728472],
              [-37.93113851, -8.56505058],
              [-37.92826025, -8.5630622],
              [-37.9248165, -8.56069403],
              [-37.92278597, -8.55928851],
              [-37.92100244, -8.55805843],
              [-37.91919244, -8.55680988],
              [-37.91667931, -8.55521085],
              [-37.9131044194, -8.5529430169388],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1365.0,
        terrai_cod: 20901.0,
        terrai_nom: 'Kapinawá',
        etnia_nome: 'Kapinawá',
        municipio_: 'Buíque',
        uf_sigla: 'PE',
        superficie: 12403.0917,
        fase_ti: 'Regularizada',
        modalidade: 'Tradicionalmente ocupada',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL NORDESTE I',
        faixa_fron: 'Não',
        undadm_cod: 30202001969.0,
        undadm_nom: 'COORDENACAO REGIONAL NORDESTE I',
        undadm_sig: 'CR-NE-I',
        dominio_un: 't',
        data_atual: null,
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.376314940917268, -8.571416603833768],
              [-37.375290327270648, -8.571556719024954],
              [-37.374773013530955, -8.571535323963587],
              [-37.373900036072371, -8.571334110408145],
              [-37.3735159828326, -8.571179188176927],
              [-37.373262639168047, -8.571151330175951],
              [-37.372272777682213, -8.571327633554517],
              [-37.371902386627767, -8.571477418950622],
              [-37.370324565144699, -8.572602643608661],
              [-37.369908404900741, -8.572759219710349],
              [-37.368033152299461, -8.573011833090106],
              [-37.367600389989207, -8.5731329588221],
              [-37.365961025697061, -8.573803727769587],
              [-37.36520936922426, -8.574346125753436],
              [-37.36372171631308, -8.575283833640071],
              [-37.36183131133663, -8.575874648161459],
              [-37.361335787753148, -8.575975780312035],
              [-37.360620655462412, -8.57631111883444],
              [-37.360131251450525, -8.576681870738014],
              [-37.359700718738281, -8.577159330619963],
              [-37.359176468330418, -8.577902274438486],
              [-37.35752472419, -8.578442095748471],
              [-37.357271766159897, -8.578405547823593],
              [-37.356597826569896, -8.578429760579581],
              [-37.355466746818081, -8.578713083415323],
              [-37.354530673044565, -8.579108750570185],
              [-37.353590181177537, -8.579399960094349],
              [-37.352899538400266, -8.579797100016606],
              [-37.352425159936899, -8.580237956686801],
              [-37.352072120338619, -8.580405792226884],
              [-37.350305858759327, -8.580862599236866],
              [-37.349703743888767, -8.580907147494656],
              [-37.348397597443522, -8.580835615062028],
              [-37.34794186181216, -8.580860163070838],
              [-37.347550572761463, -8.581069848311131],
              [-37.34683749065664, -8.581561607830508],
              [-37.345618507872871, -8.582588461884484],
              [-37.344945607532736, -8.582994988142218],
              [-37.343376790110426, -8.583711890508312],
              [-37.342803452282794, -8.583722858275792],
              [-37.34190091578057, -8.583572459972288],
              [-37.340720013223063, -8.583749365046629],
              [-37.339753042411985, -8.584022027595438],
              [-37.33772327906064, -8.584068119813026],
              [-37.335860123802547, -8.583643255942036],
              [-37.335523148378456, -8.583655334834191],
              [-37.334955362339997, -8.586177450972286],
              [-37.334455909659198, -8.586973416365709],
              [-37.333348625129531, -8.587535616214325],
              [-37.331647480986987, -8.588768198686239],
              [-37.331037684642183, -8.589185966019045],
              [-37.329973305804515, -8.58960222047159],
              [-37.326343284670145, -8.589442704981423],
              [-37.325592819307872, -8.589550527227914],
              [-37.323793896794086, -8.590327181801456],
              [-37.32268361911548, -8.590550356631979],
              [-37.320947871926137, -8.590730148702278],
              [-37.319621210780838, -8.590709745493029],
              [-37.318232299551312, -8.590860503400449],
              [-37.317353316909703, -8.591197523790186],
              [-37.31579417986643, -8.592505325546142],
              [-37.315662826531195, -8.592595425555885],
              [-37.314655599713319, -8.592953157367788],
              [-37.313651663129804, -8.592833207946114],
              [-37.310764931253857, -8.591714066204815],
              [-37.309683603109022, -8.591495334871126],
              [-37.307904590357509, -8.591221514700749],
              [-37.306413389512954, -8.591220282052916],
              [-37.305377761958653, -8.591602861093008],
              [-37.30445437350096, -8.592320215218592],
              [-37.302872326652675, -8.59292327477857],
              [-37.300873855582097, -8.594091010571617],
              [-37.299113686415581, -8.595216517396466],
              [-37.297005873686601, -8.596388325687201],
              [-37.295591061222979, -8.596911404787761],
              [-37.293693439797266, -8.597857376936929],
              [-37.29220843908405, -8.598525174557835],
              [-37.291504194073809, -8.598817229235181],
              [-37.289323653163478, -8.598978252891527],
              [-37.288307811998266, -8.598718745755491],
              [-37.28657065505422, -8.59852471976717],
              [-37.286092050174034, -8.598652436105908],
              [-37.285534365881574, -8.599324095554039],
              [-37.285071470138689, -8.60112024307125],
              [-37.284284070731417, -8.602650943740116],
              [-37.283475910272159, -8.603633529343881],
              [-37.282451633101346, -8.604971911527514],
              [-37.281933267370732, -8.605375906803602],
              [-37.28141959439796, -8.605675860687825],
              [-37.281012019779382, -8.605841261322155],
              [-37.279676301111351, -8.607027632845757],
              [-37.279359470480301, -8.60820440872277],
              [-37.278857114870277, -8.609260514797882],
              [-37.277861633697682, -8.610765070298726],
              [-37.277313552096416, -8.612027717763965],
              [-37.276864635153345, -8.61250407026904],
              [-37.275944521886231, -8.612943361757289],
              [-37.275448583436287, -8.613252709107087],
              [-37.274412169527352, -8.614051941841467],
              [-37.273503538342311, -8.614639356834232],
              [-37.271979063072479, -8.615774290238335],
              [-37.271424734499931, -8.616168038090791],
              [-37.270529867497828, -8.616651775215757],
              [-37.268954883555182, -8.617897470416437],
              [-37.268408502804505, -8.618517364699414],
              [-37.267791187718274, -8.619499087953955],
              [-37.267453504286379, -8.619927933093606],
              [-37.266238403448028, -8.621258118878075],
              [-37.265618370346431, -8.622500277018844],
              [-37.26490451945773, -8.624207523440077],
              [-37.264214984310627, -8.625377270046215],
              [-37.263485942812679, -8.626415070775238],
              [-37.262562050504528, -8.627939778096959],
              [-37.26174496668915, -8.628913073535273],
              [-37.261424222374387, -8.629368654000587],
              [-37.260976091863938, -8.630227113093667],
              [-37.26050343403665, -8.630823977099851],
              [-37.25971984149885, -8.6316596856567],
              [-37.259210120721093, -8.632472123539287],
              [-37.258263241201178, -8.633900264800275],
              [-37.257427163155342, -8.634890085372888],
              [-37.25710996726275, -8.635467388487712],
              [-37.256656220856541, -8.636047649009129],
              [-37.256134180911033, -8.636529504400073],
              [-37.25540179449365, -8.637037275743353],
              [-37.253926754685779, -8.638078429117002],
              [-37.252085499932377, -8.6393734363661],
              [-37.250332214218247, -8.640533144342157],
              [-37.256438101201923, -8.648666960537298],
              [-37.257532075500535, -8.648408910933767],
              [-37.262734104394497, -8.648410176219045],
              [-37.271525581566436, -8.65468560338752],
              [-37.272719650417628, -8.653024590805046],
              [-37.275251003302174, -8.652757338798835],
              [-37.281998263658579, -8.655985162890317],
              [-37.283165764694843, -8.6565119159593],
              [-37.288919960799149, -8.665387969180074],
              [-37.301340632260633, -8.669662331829329],
              [-37.328283829452801, -8.654319828514645],
              [-37.331443011797148, -8.653843854483569],
              [-37.334880390946637, -8.653249151441685],
              [-37.335542201663884, -8.65309437703587],
              [-37.340931625549899, -8.651364816265641],
              [-37.343641137068005, -8.650574450568058],
              [-37.345242477950649, -8.649954957542263],
              [-37.347135853931093, -8.649312896281913],
              [-37.349984944153675, -8.648250218283636],
              [-37.350595404702176, -8.648023734144886],
              [-37.35344767347874, -8.64709146966743],
              [-37.355014154116475, -8.646235815374439],
              [-37.356682821938705, -8.645332272155372],
              [-37.357772567466213, -8.64456095472525],
              [-37.358998855369862, -8.643786635651736],
              [-37.36040011109975, -8.643367187570334],
              [-37.363432335020036, -8.641668875344294],
              [-37.366423821286276, -8.639864516717523],
              [-37.367689313991946, -8.638622497086999],
              [-37.36891760915654, -8.637196421224701],
              [-37.370160039186601, -8.635657945825244],
              [-37.371574616280725, -8.634335180867424],
              [-37.372654983994153, -8.633163584263343],
              [-37.374205880283398, -8.632246200504426],
              [-37.375787117675713, -8.631260539853542],
              [-37.377869318531793, -8.629852510967453],
              [-37.380593118880711, -8.628332098050464],
              [-37.381318668348968, -8.627971222431825],
              [-37.382463875698704, -8.627175901552164],
              [-37.383066131299415, -8.626523102598014],
              [-37.383784543848662, -8.626118463292585],
              [-37.384473106260543, -8.62597329307323],
              [-37.38514479539208, -8.626001229216513],
              [-37.388120402776565, -8.626768082784963],
              [-37.389422522917698, -8.627135096915083],
              [-37.390158532135899, -8.627148322302446],
              [-37.391401061881936, -8.62681759617908],
              [-37.393111734271429, -8.625984875119684],
              [-37.395879597386568, -8.625100473052214],
              [-37.396158135492044, -8.624972994055838],
              [-37.397522692541258, -8.624151911054525],
              [-37.399865736632933, -8.623006438151435],
              [-37.401489605367487, -8.622282998467597],
              [-37.40200841632435, -8.622069885059659],
              [-37.403134857899943, -8.621690797851443],
              [-37.403510509439968, -8.621628176750619],
              [-37.404712928157267, -8.62158250423289],
              [-37.406077688842856, -8.621769543237122],
              [-37.408220205183113, -8.621849808627189],
              [-37.408520129066289, -8.621853569170526],
              [-37.409052030993507, -8.621753971189094],
              [-37.409820578459232, -8.621446908454573],
              [-37.410213474466616, -8.62120247274288],
              [-37.411874644410091, -8.619646099621605],
              [-37.412319662312598, -8.619455968852265],
              [-37.412943103979956, -8.619342773397818],
              [-37.413496397645872, -8.619374423554234],
              [-37.413919506023717, -8.619470204628328],
              [-37.415427562447633, -8.620315031161681],
              [-37.415988123785155, -8.620590351185285],
              [-37.417391017531941, -8.621143993104283],
              [-37.41775927830588, -8.621246191406328],
              [-37.418805967216372, -8.621428689942434],
              [-37.419635845253936, -8.621376202799638],
              [-37.420355111114418, -8.621153978007101],
              [-37.421636749074366, -8.620963771270794],
              [-37.423282486510573, -8.620562667262845],
              [-37.423439512765469, -8.620508335837883],
              [-37.423908259273844, -8.620397396388341],
              [-37.425176373127613, -8.61949385631732],
              [-37.427862640284673, -8.618397022240401],
              [-37.429585298387508, -8.61688643737449],
              [-37.43074250873309, -8.616021701841532],
              [-37.431730358720245, -8.615080398090413],
              [-37.432245881323183, -8.614736676510518],
              [-37.433350072619589, -8.614243489897035],
              [-37.434059694146299, -8.613829569383881],
              [-37.435254730159272, -8.612931583859336],
              [-37.4367078626276, -8.611548796113425],
              [-37.436982722704613, -8.611299399225402],
              [-37.438157950380685, -8.610843882729512],
              [-37.43897282546186, -8.610312593470439],
              [-37.43984626472303, -8.609488171469479],
              [-37.440787019971083, -8.607971109824973],
              [-37.44142848751585, -8.607250076517175],
              [-37.442810023609695, -8.606246712153222],
              [-37.444020747770708, -8.605809993168577],
              [-37.444886114958138, -8.605776240940543],
              [-37.445488099229976, -8.60553165760706],
              [-37.445858743258725, -8.605173190542928],
              [-37.447313194380456, -8.604572668521428],
              [-37.448214643590404, -8.60474902747444],
              [-37.448642012738674, -8.604749310949728],
              [-37.449763287276937, -8.60448271769593],
              [-37.450675413834972, -8.604216166120182],
              [-37.451506407047589, -8.604137505558924],
              [-37.453291543045395, -8.60448077986581],
              [-37.453685099272377, -8.604627444618643],
              [-37.455063298275206, -8.605327709090659],
              [-37.455896739538957, -8.605805515336158],
              [-37.456777330790317, -8.606041858644119],
              [-37.458439323750895, -8.605884519694314],
              [-37.459653684327748, -8.605569578477514],
              [-37.460981564197596, -8.60556359365313],
              [-37.461976536747386, -8.60527434930259],
              [-37.461196211825097, -8.604624868369399],
              [-37.460409520383052, -8.603914273327291],
              [-37.459993590065778, -8.603453722545339],
              [-37.459625712886293, -8.603342896587151],
              [-37.459119153461245, -8.603487144418978],
              [-37.458568199067372, -8.603403527911098],
              [-37.45834477142273, -8.603316056259132],
              [-37.457920327144166, -8.603046411791979],
              [-37.457677411938676, -8.602784267751815],
              [-37.457518507036752, -8.602473434730314],
              [-37.457249136527935, -8.602192811987493],
              [-37.455973096418859, -8.601444629433558],
              [-37.454662862326813, -8.600851517779631],
              [-37.452952882601608, -8.60025058773374],
              [-37.451809298630167, -8.599794763923853],
              [-37.450687739793693, -8.599252920908782],
              [-37.448852714085092, -8.597786224316053],
              [-37.448092140133951, -8.596694260947856],
              [-37.447197953384375, -8.595335997597488],
              [-37.446722011054533, -8.594386198052916],
              [-37.446566974434802, -8.593988614488751],
              [-37.446363493006686, -8.593250008189841],
              [-37.445559671472608, -8.591904192155312],
              [-37.445349193092284, -8.591730337156321],
              [-37.445016082121128, -8.591655732736399],
              [-37.444564772248441, -8.591784875053349],
              [-37.443806177312013, -8.592483743315881],
              [-37.443236031716367, -8.593442478988891],
              [-37.442964307514387, -8.593622496171463],
              [-37.442479784443321, -8.593680717104235],
              [-37.442082190404449, -8.593420897568876],
              [-37.441821373905839, -8.592540853741866],
              [-37.440902073088239, -8.591338066190019],
              [-37.440368900416615, -8.591263890319169],
              [-37.43993225940028, -8.591471864867856],
              [-37.439752846578529, -8.591620921742788],
              [-37.439344206972123, -8.591812665734324],
              [-37.439023444528573, -8.591868957145209],
              [-37.438611358111416, -8.591730239247056],
              [-37.437098280090311, -8.590181318354269],
              [-37.436736430217024, -8.589731748420206],
              [-37.436012553090563, -8.588632678566967],
              [-37.435065253755056, -8.587446157386182],
              [-37.434820021841347, -8.587236105312286],
              [-37.433646353928225, -8.586640019897743],
              [-37.432902293847064, -8.586809217384427],
              [-37.432511812847451, -8.587001707024854],
              [-37.431811372934135, -8.587416085665325],
              [-37.431518616535016, -8.58745614775728],
              [-37.431088382299492, -8.58731668614513],
              [-37.430772091810411, -8.587068931824206],
              [-37.430561055593273, -8.586703834061495],
              [-37.43032996624936, -8.585972835683492],
              [-37.430255102065018, -8.585204829308566],
              [-37.429575297372025, -8.583525242000121],
              [-37.428979405806842, -8.583022569534933],
              [-37.428072997396214, -8.582550539232443],
              [-37.426490535142193, -8.58194629319695],
              [-37.424682338008211, -8.581306653543436],
              [-37.422372647764178, -8.580698460547296],
              [-37.421231225119129, -8.580399264210428],
              [-37.419979167915812, -8.579930355460704],
              [-37.41843805432012, -8.579214835520474],
              [-37.417087537168243, -8.578507190704215],
              [-37.415214668997464, -8.577890970733506],
              [-37.414231801453525, -8.577502720216899],
              [-37.413157226015073, -8.576928162401089],
              [-37.4111812608582, -8.576177320841282],
              [-37.410409253184753, -8.575954228511875],
              [-37.409904103306644, -8.575863835970893],
              [-37.407955126887543, -8.575731195008302],
              [-37.406690069721492, -8.576165652366228],
              [-37.40602749627925, -8.576546769298432],
              [-37.405189210487379, -8.576790255985998],
              [-37.40409606830417, -8.576632108716012],
              [-37.403368109622306, -8.576237006588485],
              [-37.402683463505696, -8.575278776400637],
              [-37.402532098339918, -8.575003100522451],
              [-37.401938942875688, -8.574439752516525],
              [-37.399618470596977, -8.57407446959553],
              [-37.399086484693498, -8.573974272928865],
              [-37.398509721829271, -8.573654953966184],
              [-37.397962336214562, -8.573084814643835],
              [-37.397689504094387, -8.572882344464404],
              [-37.397217393154961, -8.572662951156758],
              [-37.396638482174581, -8.572595580596612],
              [-37.395710995200318, -8.572800603586245],
              [-37.395101931865725, -8.573001398201484],
              [-37.394655843752332, -8.573217615787257],
              [-37.394372947323291, -8.573240689048191],
              [-37.39290388615359, -8.572745450076923],
              [-37.390454867938104, -8.572001142911972],
              [-37.387308746847168, -8.57139314649902],
              [-37.38552647711623, -8.570989250218588],
              [-37.382975489739181, -8.570492746701461],
              [-37.380784381685146, -8.570489074098589],
              [-37.379387283240078, -8.5706224983673],
              [-37.377513764496086, -8.571040367825105],
              [-37.376314940917268, -8.571416603833768],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 409.0,
        terrai_cod: 73774.0,
        terrai_nom: 'Kariri de Serra Grande',
        etnia_nome: 'Kariri',
        municipio_: 'Queimada Nova',
        uf_sigla: 'PI',
        superficie: 2114.6769,
        fase_ti: 'Encaminhada RI',
        modalidade: 'Dominial Indígena',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL NORDESTE II',
        faixa_fron: 'Não',
        undadm_cod: 30202001976.0,
        undadm_nom: 'COORDENACAO REGIONAL NORDESTE II',
        undadm_sig: 'CR-NE-II',
        dominio_un: 't',
        data_atual: '23/06/2021',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-41.358336183999903, -8.707620177999965],
              [-41.358140396157985, -8.707658229706341],
              [-41.358332532946292, -8.707624631125988],
              [-41.358336183999903, -8.707620177999965],
            ],
          ],
          [
            [
              [-41.345418423, -8.709865107999976],
              [-41.343916473616332, -8.709705062683765],
              [-41.34542389, -8.70988194],
              [-41.346377581414146, -8.70971516986768],
              [-41.345418423, -8.709865107999976],
            ],
          ],
          [
            [
              [-41.335402692999899, -8.706943399999941],
              [-41.332553511999897, -8.704283054999951],
              [-41.329580721999903, -8.702569713999944],
              [-41.321281106, -8.699652198999967],
              [-41.321260652043073, -8.699643698726476],
              [-41.32125889, -8.69965917],
              [-41.32958083, -8.70257833],
              [-41.33256444, -8.70430167],
              [-41.33670944, -8.70829611],
              [-41.33689423029265, -8.708336082164475],
              [-41.335402692999899, -8.706943399999941],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1478.0,
        terrai_cod: 32401.0,
        terrai_nom: 'Pankararu',
        etnia_nome: 'Pankararú',
        municipio_: 'Petrolândia,Tacaratu',
        uf_sigla: 'PE',
        superficie: 8377.2819,
        fase_ti: 'Regularizada',
        modalidade: 'Tradicionalmente ocupada',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        faixa_fron: 'Não',
        undadm_cod: 30202001983.0,
        undadm_nom: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        undadm_sig: 'CR-BSF',
        dominio_un: 't',
        data_atual: null,
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.219285041312958, -9.19446193955315],
              [-38.229003754532016, -9.197664071889164],
              [-38.232163703373828, -9.18844321865646],
              [-38.235492186175634, -9.178705646205515],
              [-38.238034245099016, -9.171238407902138],
              [-38.240667182956884, -9.16356539449805],
              [-38.243983747603636, -9.153852777966108],
              [-38.250079625949297, -9.136001147110505],
              [-38.253186341651826, -9.126863634270705],
              [-38.255921159401964, -9.118897155419461],
              [-38.256014700093431, -9.118639265247566],
              [-38.255736464733673, -9.118549107376168],
              [-38.253475012460058, -9.117808906760615],
              [-38.247883467313443, -9.115995909613446],
              [-38.237984533721942, -9.112772621860302],
              [-38.23029374715577, -9.110261141440333],
              [-38.221597641405424, -9.107428454925127],
              [-38.212613541340147, -9.104522676366344],
              [-38.2022842974377, -9.101159349116585],
              [-38.192816310103751, -9.098085069876504],
              [-38.182343877004257, -9.094672191684026],
              [-38.177991732571229, -9.09325137109073],
              [-38.175006796711102, -9.101701529960792],
              [-38.172231044552802, -9.109540883989382],
              [-38.168202096126095, -9.120921390711658],
              [-38.165602276732386, -9.128270452128675],
              [-38.163629056246293, -9.133840035697574],
              [-38.160957853788489, -9.141342808695015],
              [-38.157699812330691, -9.150530354651437],
              [-38.154857390847781, -9.158575045111149],
              [-38.151850472859167, -9.16702255248947],
              [-38.150105464750823, -9.171946908495556],
              [-38.150052485608512, -9.172110399359269],
              [-38.150321622227011, -9.172200276387457],
              [-38.159790084325834, -9.17527826079106],
              [-38.168541343900074, -9.178107890073591],
              [-38.177985531174897, -9.181123827495981],
              [-38.183020288393038, -9.1827671401374],
              [-38.187929792117018, -9.184361550749145],
              [-38.194993731109719, -9.186648894634459],
              [-38.203350550414825, -9.189348528760377],
              [-38.212037879699892, -9.192193000878774],
              [-38.219285041312958, -9.19446193955315],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 299.0,
        terrai_cod: 46601.0,
        terrai_nom: 'Truká',
        etnia_nome: 'Truká',
        municipio_: 'Cabrobó',
        uf_sigla: 'PE',
        superficie: 1592.8972,
        fase_ti: 'Regularizada',
        modalidade: 'Tradicionalmente ocupada',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        faixa_fron: 'Não',
        undadm_cod: 30202001983.0,
        undadm_nom: 'COORDENACAO REGIONAL BAIXO SÃO FRANCISCO',
        undadm_sig: 'CR-BSF',
        dominio_un: 't',
        data_atual: '02/04/2020',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.42280304, -8.49886001],
              [-39.42223507, -8.49886001],
              [-39.42182561, -8.49893926],
              [-39.42141614, -8.49913739],
              [-39.42082176, -8.49908455],
              [-39.42033304, -8.49904493],
              [-39.41991037, -8.49896568],
              [-39.4194877, -8.49893926],
              [-39.41915748, -8.49891284],
              [-39.41851026, -8.49893926],
              [-39.41804796, -8.49895247],
              [-39.41761208, -8.49896568],
              [-39.41718941, -8.49892605],
              [-39.41670069, -8.49888643],
              [-39.4160931, -8.49896568],
              [-39.41584213, -8.49899209],
              [-39.41543267, -8.49903172],
              [-39.41498358, -8.49891284],
              [-39.41427032, -8.49889963],
              [-39.41318721, -8.4990053],
              [-39.41243433, -8.49921664],
              [-39.41189278, -8.49945439],
              [-39.41149652, -8.49941477],
              [-39.41127197, -8.49948081],
              [-39.41103422, -8.49969215],
              [-39.41074363, -8.49989028],
              [-39.41045304, -8.49999594],
              [-39.41000395, -8.50026012],
              [-39.4096209, -8.50060354],
              [-39.40944919, -8.50092054],
              [-39.40915861, -8.50117151],
              [-39.40890764, -8.50143568],
              [-39.40860385, -8.50176589],
              [-39.40814155, -8.50212252],
              [-39.40767925, -8.50233386],
              [-39.40703203, -8.50258482],
              [-39.40658294, -8.50262445],
              [-39.40596214, -8.50274332],
              [-39.40547342, -8.50294145],
              [-39.40495829, -8.50313958],
              [-39.40460165, -8.50319242],
              [-39.40424502, -8.50329478],
              [-39.40378272, -8.50344008],
              [-39.40345251, -8.50367783],
              [-39.40310909, -8.50382312],
              [-39.40260716, -8.50404767],
              [-39.40238262, -8.50420617],
              [-39.40210524, -8.50433826],
              [-39.40184107, -8.50457601],
              [-39.4015769, -8.5047081],
              [-39.40132593, -8.50478735],
              [-39.40087684, -8.50489302],
              [-39.40040133, -8.5050251],
              [-39.40003149, -8.50514398],
              [-39.39955599, -8.50524965],
              [-39.39917294, -8.50527606],
              [-39.39861818, -8.50539494],
              [-39.39823513, -8.50555344],
              [-39.39795775, -8.5056327],
              [-39.39746903, -8.50561618],
              [-39.39694069, -8.50568223],
              [-39.39662369, -8.50569544],
              [-39.3961746, -8.50576148],
              [-39.39573871, -8.50591998],
              [-39.39532925, -8.50615774],
              [-39.39486695, -8.50642191],
              [-39.3943254, -8.50659362],
              [-39.39382347, -8.50677854],
              [-39.39346684, -8.50683137],
              [-39.39289887, -8.50696346],
              [-39.39233091, -8.50725405],
              [-39.39194786, -8.50735971],
              [-39.39152519, -8.50754463],
              [-39.39112893, -8.51717367],
              [-39.39070625, -8.5233949],
              [-39.39139302, -8.52344827],
              [-39.3920932, -8.52338221],
              [-39.39263485, -8.52327652],
              [-39.39297834, -8.52315763],
              [-39.39336146, -8.52303873],
              [-39.39387669, -8.52297267],
              [-39.39441834, -8.52291983],
              [-39.39488072, -8.52278772],
              [-39.39534311, -8.52266882],
              [-39.39587155, -8.5226424],
              [-39.39638678, -8.5226424],
              [-39.39687558, -8.52262919],
              [-39.39732476, -8.52269524],
              [-39.39781356, -8.5227613],
              [-39.39835522, -8.52288019],
              [-39.39888366, -8.52305194],
              [-39.39931962, -8.52322368],
              [-39.39972916, -8.52338221],
              [-39.40005943, -8.52365964],
              [-39.40033687, -8.52388423],
              [-39.40057466, -8.52410882],
              [-39.40097099, -8.52432019],
              [-39.40149943, -8.52447873],
              [-39.40197503, -8.52463726],
              [-39.40239778, -8.52465047],
              [-39.40292622, -8.52466368],
              [-39.40326971, -8.52458441],
              [-39.40353393, -8.52465047],
              [-39.4038642, -8.52475616],
              [-39.40432659, -8.52479579],
              [-39.40472292, -8.52482221],
              [-39.40505319, -8.5249279],
              [-39.40529099, -8.5249279],
              [-39.40555521, -8.52494111],
              [-39.4058987, -8.5250468],
              [-39.4062686, -8.52514918],
              [-39.40694237, -8.52540019],
              [-39.40745759, -8.52550588],
              [-39.40778787, -8.52562478],
              [-39.40821062, -8.52574368],
              [-39.40868622, -8.52583616],
              [-39.40916181, -8.52590221],
              [-39.40954493, -8.52598148],
              [-39.41003374, -8.52595505],
              [-39.4102319, -8.52587579],
              [-39.41076034, -8.52566441],
              [-39.41107741, -8.52554551],
              [-39.41134163, -8.52549267],
              [-39.41160585, -8.5255323],
              [-39.41172475, -8.52567762],
              [-39.4120286, -8.52574368],
              [-39.41239851, -8.52582294],
              [-39.41279484, -8.52594184],
              [-39.41316475, -8.52614001],
              [-39.41352144, -8.52635138],
              [-39.41377245, -8.52655946],
              [-39.41389135, -8.52675762],
              [-39.41412915, -8.526969],
              [-39.41449906, -8.52712753],
              [-39.41501429, -8.52735212],
              [-39.41542383, -8.52745781],
              [-39.41574089, -8.52760313],
              [-39.41620328, -8.52777487],
              [-39.41667887, -8.52794661],
              [-39.41696951, -8.52795982],
              [-39.417313, -8.52798625],
              [-39.41763006, -8.5281712],
              [-39.41790749, -8.52839579],
              [-39.4182774, -8.52859395],
              [-39.41862089, -8.52877891],
              [-39.4188719, -8.52893744],
              [-39.41925502, -8.52930735],
              [-39.4195985, -8.52947909],
              [-39.41975703, -8.52970368],
              [-39.42014015, -8.52991505],
              [-39.42051006, -8.53002074],
              [-39.42089318, -8.53034771],
              [-39.42113098, -8.53062514],
              [-39.42161979, -8.53083322],
              [-39.42196327, -8.53092569],
              [-39.42229355, -8.53116349],
              [-39.42261061, -8.53133523],
              [-39.42290125, -8.5315334],
              [-39.42308621, -8.53174478],
              [-39.42331079, -8.53195615],
              [-39.42361465, -8.53224679],
              [-39.42374676, -8.53233927],
              [-39.4239185, -8.53239211],
              [-39.42423556, -8.5326167],
              [-39.42463189, -8.53284129],
              [-39.42489611, -8.53323762],
              [-39.42509428, -8.53362074],
              [-39.42526602, -8.53396422],
              [-39.42543776, -8.53426808],
              [-39.42558309, -8.53458514],
              [-39.42574162, -8.53487578],
              [-39.42583409, -8.53515321],
              [-39.42607189, -8.53536459],
              [-39.42646822, -8.53566844],
              [-39.42682492, -8.5359723],
              [-39.42714198, -8.53623652],
              [-39.42736657, -8.53643138],
              [-39.4275251, -8.53670881],
              [-39.42769685, -8.53697303],
              [-39.42793464, -8.53717119],
              [-39.42819886, -8.53726367],
              [-39.42855556, -8.53739578],
              [-39.42883299, -8.53762037],
              [-39.42907079, -8.5377789],
              [-39.42950675, -8.53787138],
              [-39.42995593, -8.53796385],
              [-39.43027299, -8.53799028],
              [-39.43070895, -8.53797706],
              [-39.43118455, -8.53797706],
              [-39.4314884, -8.53796385],
              [-39.43199042, -8.53783174],
              [-39.43263776, -8.53768642],
              [-39.43319262, -8.53758073],
              [-39.43358895, -8.53739578],
              [-39.43390601, -8.5373033],
              [-39.43446088, -8.53711835],
              [-39.43489684, -8.53703908],
              [-39.4352139, -8.53685413],
              [-39.43547812, -8.53673523],
              [-39.43584803, -8.53655028],
              [-39.43615188, -8.53648422],
              [-39.43642932, -8.53636532],
              [-39.43679922, -8.5363389],
              [-39.43734087, -8.53627285],
              [-39.43789574, -8.53623321],
              [-39.43826564, -8.53620679],
              [-39.43863555, -8.53619358],
              [-39.43900546, -8.53632569],
              [-39.43945463, -8.53648422],
              [-39.43983775, -8.53662954],
              [-39.44022087, -8.5368145],
              [-39.44048509, -8.53702587],
              [-39.44070968, -8.53715798],
              [-39.44110601, -8.53732973],
              [-39.4414495, -8.5375411],
              [-39.4418194, -8.53780532],
              [-39.44218931, -8.53804312],
              [-39.44251959, -8.53837339],
              [-39.4426517, -8.53865083],
              [-39.44283665, -8.53898771],
              [-39.44307445, -8.53926514],
              [-39.44332546, -8.5395822],
              [-39.44356326, -8.53984642],
              [-39.44372179, -8.54007101],
              [-39.4439728, -8.54021633],
              [-39.44419738, -8.54034844],
              [-39.44452766, -8.54049376],
              [-39.44485793, -8.54083725],
              [-39.44501647, -8.5411411],
              [-39.44504289, -8.54148458],
              [-39.445175, -8.54181486],
              [-39.44535995, -8.54203945],
              [-39.44559775, -8.5423433],
              [-39.44591481, -8.54259431],
              [-39.44620546, -8.54288495],
              [-39.44660179, -8.54293779],
              [-39.44674711, -8.54300385],
              [-39.44690564, -8.5431888],
              [-39.44719628, -8.54341339],
              [-39.44752656, -8.54355871],
              [-39.44785683, -8.54370403],
              [-39.44806821, -8.54386256],
              [-39.44829279, -8.54424568],
              [-39.44851738, -8.54448348],
              [-39.44879481, -8.54469486],
              [-39.44909866, -8.54478733],
              [-39.44916472, -8.54490293],
              [-39.44919114, -8.54508788],
              [-39.44932325, -8.54531247],
              [-39.44950821, -8.54551064],
              [-39.44982527, -8.54573522],
              [-39.45014233, -8.5458277],
              [-39.45051224, -8.54589376],
              [-39.4509482, -8.54599944],
              [-39.45135775, -8.54597302],
              [-39.45164839, -8.54581449],
              [-39.45179371, -8.54574843],
              [-39.45187297, -8.54555027],
              [-39.45187297, -8.5453521],
              [-39.45182013, -8.54507467],
              [-39.4516616, -8.54482366],
              [-39.4515427, -8.54453302],
              [-39.45133132, -8.5442688],
              [-39.45113316, -8.54420275],
              [-39.45084252, -8.54417633],
              [-39.45049903, -8.54416311],
              [-39.44995738, -8.54384605],
              [-39.44977243, -8.54371394],
              [-39.44949499, -8.54364789],
              [-39.44919114, -8.54352899],
              [-39.44888729, -8.54338367],
              [-39.44853059, -8.54293449],
              [-39.44837206, -8.5424589],
              [-39.44816068, -8.54200972],
              [-39.44789646, -8.5417455],
              [-39.44780399, -8.54126991],
              [-39.44780399, -8.54087358],
              [-39.44767188, -8.5405433],
              [-39.44755298, -8.54025266],
              [-39.44743408, -8.53990917],
              [-39.44740766, -8.53961853],
              [-39.44730197, -8.53932789],
              [-39.4471038, -8.53899761],
              [-39.4469849, -8.53862771],
              [-39.44693206, -8.53831064],
              [-39.44686601, -8.53791431],
              [-39.44670747, -8.53752459],
              [-39.44657536, -8.53710184],
              [-39.4463772, -8.53667908],
              [-39.44619224, -8.53642807],
              [-39.44594124, -8.5359789],
              [-39.44580913, -8.5355033],
              [-39.44561096, -8.53505413],
              [-39.44555812, -8.53463138],
              [-39.44546564, -8.5341822],
              [-39.44534674, -8.53374624],
              [-39.44528069, -8.53346881],
              [-39.44521463, -8.53315175],
              [-39.44502968, -8.53276863],
              [-39.44491078, -8.53244496],
              [-39.44480509, -8.53220716],
              [-39.44477867, -8.53191652],
              [-39.44476546, -8.53169193],
              [-39.44476546, -8.53142771],
              [-39.44468619, -8.53115028],
              [-39.4445805, -8.53079358],
              [-39.44438234, -8.53034441],
              [-39.44432949, -8.52998771],
              [-39.44423702, -8.52955175],
              [-39.44414454, -8.52934037],
              [-39.44401243, -8.52908936],
              [-39.44388032, -8.52879872],
              [-39.44378784, -8.52867982],
              [-39.44358968, -8.52850808],
              [-39.44329904, -8.52834955],
              [-39.44304803, -8.52817781],
              [-39.44278381, -8.5279268],
              [-39.4425328, -8.52771542],
              [-39.44228179, -8.52738515],
              [-39.44214968, -8.52716056],
              [-39.44195151, -8.52680386],
              [-39.4418194, -8.5263712],
              [-39.44164766, -8.52606735],
              [-39.44160803, -8.52573707],
              [-39.44152876, -8.52538038],
              [-39.44142307, -8.52510295],
              [-39.44123812, -8.52478588],
              [-39.4410928, -8.52449524],
              [-39.44088142, -8.52428386],
              [-39.44069647, -8.5240857],
              [-39.44056436, -8.52386441],
              [-39.44048509, -8.52361341],
              [-39.44031335, -8.52341524],
              [-39.44014161, -8.5233624],
              [-39.43981133, -8.5233624],
              [-39.4395339, -8.5233624],
              [-39.43926968, -8.52333597],
              [-39.43903188, -8.52311139],
              [-39.43892619, -8.5228868],
              [-39.43870161, -8.52275469],
              [-39.43846381, -8.52249047],
              [-39.43826564, -8.52218662],
              [-39.43817317, -8.52190919],
              [-39.43790895, -8.52145341],
              [-39.43772399, -8.52113634],
              [-39.43745977, -8.52081928],
              [-39.43720876, -8.52039653],
              [-39.43711629, -8.52010588],
              [-39.43694454, -8.51968313],
              [-39.43685207, -8.51935286],
              [-39.43670675, -8.51902258],
              [-39.43645574, -8.5186791],
              [-39.4364161, -8.51830919],
              [-39.43646895, -8.5178468],
              [-39.43644253, -8.51746368],
              [-39.43644253, -8.51708057],
              [-39.43640289, -8.51656534],
              [-39.43623115, -8.51590809],
              [-39.43605941, -8.51532681],
              [-39.43601977, -8.51491726],
              [-39.43598014, -8.51462662],
              [-39.43582161, -8.51423029],
              [-39.43575555, -8.51392644],
              [-39.43570271, -8.51346405],
              [-39.4355706, -8.51308094],
              [-39.43549133, -8.51279029],
              [-39.43529317, -8.51244681],
              [-39.43489684, -8.5119613],
              [-39.4346062, -8.51171029],
              [-39.43432877, -8.51127433],
              [-39.43419666, -8.51093085],
              [-39.43380033, -8.51080534],
              [-39.43348326, -8.5107525],
              [-39.43282271, -8.51052791],
              [-39.43242638, -8.51029011],
              [-39.43210932, -8.51009195],
              [-39.4318451, -8.50976167],
              [-39.43158088, -8.50944461],
              [-39.43142235, -8.50911433],
              [-39.43127703, -8.50878406],
              [-39.43119776, -8.5083481],
              [-39.43119776, -8.5079914],
              [-39.43119776, -8.50766112],
              [-39.43118455, -8.50710626],
              [-39.43113171, -8.50669672],
              [-39.4309996, -8.5061947],
              [-39.43089391, -8.50590406],
              [-39.43059005, -8.50557379],
              [-39.43025978, -8.50530957],
              [-39.42988987, -8.50513782],
              [-39.42963886, -8.50493966],
              [-39.42945391, -8.50475801],
              [-39.42938785, -8.50453342],
              [-39.42934822, -8.5041503],
              [-39.42924253, -8.50385966],
              [-39.42907079, -8.50358223],
              [-39.42871409, -8.50326516],
              [-39.42848951, -8.50305379],
              [-39.42807996, -8.50281599],
              [-39.42765721, -8.50248571],
              [-39.42730052, -8.50222149],
              [-39.42706272, -8.50195727],
              [-39.42690419, -8.50170627],
              [-39.42669281, -8.50142223],
              [-39.4265607, -8.50113159],
              [-39.42642859, -8.50093342],
              [-39.42624364, -8.50072205],
              [-39.42597942, -8.50056351],
              [-39.4257152, -8.50044462],
              [-39.42549061, -8.50031251],
              [-39.42525281, -8.50012755],
              [-39.42505465, -8.49986333],
              [-39.42490932, -8.49957269],
              [-39.4246451, -8.49932168],
              [-39.42440731, -8.49921599],
              [-39.42416951, -8.49917636],
              [-39.42385244, -8.4991103],
              [-39.42345611, -8.49903104],
              [-39.42321832, -8.49889893],
              [-39.42300694, -8.49884608],
              [-39.42280304, -8.49886001],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 1285.0,
        terrai_cod: 12601.0,
        terrai_nom: 'Tuxá de Inajá',
        etnia_nome: 'Tuxá',
        municipio_: 'Inajá',
        uf_sigla: 'PE',
        superficie: 140.0,
        fase_ti: 'Encaminhada RI',
        modalidade: 'Reserva Indígena',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL NORDESTE I',
        faixa_fron: 'Não',
        undadm_cod: 30202001969.0,
        undadm_nom: 'COORDENACAO REGIONAL NORDESTE I',
        undadm_sig: 'CR-NE-I',
        dominio_un: 't',
        data_atual: null,
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.686784939004099, -8.78398926055911],
              [-37.687720080719316, -8.784437230943183],
              [-37.70735754549537, -8.766662470914639],
              [-37.705297686412393, -8.762886569807051],
              [-37.684066096445122, -8.782135483206957],
              [-37.685653033597191, -8.783263305823596],
              [-37.686784939004099, -8.78398926055911],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 403.0,
        terrai_cod: 50801.0,
        terrai_nom: 'Xukuru',
        etnia_nome: 'Xucuru',
        municipio_: 'Poção,Pesqueira',
        uf_sigla: 'PE',
        superficie: 27555.0583,
        fase_ti: 'Regularizada',
        modalidade: 'Tradicionalmente ocupada',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL NORDESTE I',
        faixa_fron: 'Não',
        undadm_cod: 30202001969.0,
        undadm_nom: 'COORDENACAO REGIONAL NORDESTE I',
        undadm_sig: 'CR-NE-I',
        dominio_un: 't',
        data_atual: '23/06/2023',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.63982139, -8.3083201],
              [-36.64136378, -8.30944898],
              [-36.6418021, -8.30982941],
              [-36.64255469, -8.31043313],
              [-36.64328246, -8.31084664],
              [-36.64497785, -8.31145036],
              [-36.64701232, -8.31223603],
              [-36.64766566, -8.31274878],
              [-36.64846787, -8.31347656],
              [-36.64916257, -8.31416299],
              [-36.64996478, -8.31512233],
              [-36.65142033, -8.31604859],
              [-36.65296685, -8.31714025],
              [-36.65381041, -8.31793419],
              [-36.65445549, -8.31875294],
              [-36.65481111, -8.31919126],
              [-36.65524943, -8.31970401],
              [-36.65562985, -8.32027465],
              [-36.65629974, -8.32116784],
              [-36.65711849, -8.32201139],
              [-36.65840037, -8.32287149],
              [-36.65964917, -8.32369024],
              [-36.66079872, -8.32447591],
              [-36.66256854, -8.32559239],
              [-36.66348116, -8.32614378],
              [-36.67135725, -8.33169055],
              [-36.67283854, -8.33497144],
              [-36.67480953, -8.33844821],
              [-36.67549201, -8.33922679],
              [-36.67908203, -8.34059058],
              [-36.67979207, -8.34436115],
              [-36.68191137, -8.35046406],
              [-36.68643071, -8.35216051],
              [-36.69238864, -8.35070835],
              [-36.69318936, -8.35064049],
              [-36.69355579, -8.35054549],
              [-36.69665012, -8.34747831],
              [-36.6978037, -8.3475326],
              [-36.69883514, -8.34871332],
              [-36.699093, -8.34884904],
              [-36.70255376, -8.34971762],
              [-36.70251304, -8.35024691],
              [-36.70252661, -8.35062692],
              [-36.70464378, -8.3530698],
              [-36.70359877, -8.35653056],
              [-36.70385663, -8.3569377],
              [-36.70427735, -8.35731771],
              [-36.70526807, -8.35777914],
              [-36.70681523, -8.35849844],
              [-36.70867454, -8.35905487],
              [-36.71001813, -8.35914987],
              [-36.711036, -8.35825415],
              [-36.71759575, -8.36061869],
              [-36.72190684, -8.3625292],
              [-36.73478627, -8.36525709],
              [-36.73820771, -8.36384047],
              [-36.74777803, -8.36813609],
              [-36.74907465, -8.3683566],
              [-36.75056533, -8.36894758],
              [-36.74603689, -8.3740274],
              [-36.7426628, -8.37688369],
              [-36.74628482, -8.37877658],
              [-36.74735888, -8.37954059],
              [-36.75183997, -8.37992739],
              [-36.76350097, -8.38591689],
              [-36.76556385, -8.38511948],
              [-36.76626904, -8.38520097],
              [-36.76781366, -8.38601072],
              [-36.76826408, -8.38609888],
              [-36.76881279, -8.38662436],
              [-36.77017482, -8.38745247],
              [-36.77087382, -8.3880709],
              [-36.77117953, -8.38834362],
              [-36.77243467, -8.38912395],
              [-36.77368047, -8.3887082],
              [-36.77442322, -8.38916384],
              [-36.77629929, -8.38887842],
              [-36.77651956, -8.38802984],
              [-36.77756505, -8.3878223],
              [-36.77836414, -8.38803768],
              [-36.77865633, -8.38780729],
              [-36.77924144, -8.3887329],
              [-36.78065081, -8.38791671],
              [-36.7812345, -8.3878718],
              [-36.7821474, -8.38738142],
              [-36.78260733, -8.3876606],
              [-36.78324437, -8.3880425],
              [-36.78422921, -8.38817033],
              [-36.78518942, -8.38763859],
              [-36.78559846, -8.38783766],
              [-36.78607868, -8.38726852],
              [-36.78661623, -8.387239],
              [-36.7931708, -8.38779893],
              [-36.83670206, -8.39148247],
              [-36.83677499, -8.39160645],
              [-36.83684792, -8.39170126],
              [-36.83692814, -8.39185441],
              [-36.83699377, -8.39203673],
              [-36.83705941, -8.3922701],
              [-36.83730007, -8.39251806],
              [-36.83749612, -8.39268017],
              [-36.83767506, -8.39251317],
              [-36.83769891, -8.3922388],
              [-36.83790171, -8.39207179],
              [-36.83808041, -8.39216071],
              [-36.83815628, -8.39253133],
              [-36.83789809, -8.39297751],
              [-36.83781058, -8.39324006],
              [-36.83787621, -8.39363387],
              [-36.83805124, -8.39375056],
              [-36.83843776, -8.39369951],
              [-36.83888263, -8.39388183],
              [-36.839116, -8.39404227],
              [-36.83934938, -8.39438504],
              [-36.83960463, -8.39471322],
              [-36.83988176, -8.39488825],
              [-36.84028287, -8.39517996],
              [-36.84068397, -8.39531124],
              [-36.8407642, -8.39520184],
              [-36.84070585, -8.39501223],
              [-36.84074961, -8.39484449],
              [-36.84074961, -8.39466217],
              [-36.84099757, -8.39450173],
              [-36.84121635, -8.39430482],
              [-36.8414789, -8.39415896],
              [-36.84198211, -8.39421001],
              [-36.84232487, -8.39424647],
              [-36.8426093, -8.39429023],
              [-36.84297394, -8.39443609],
              [-36.84317085, -8.39437775],
              [-36.84333129, -8.39412979],
              [-36.84331671, -8.39375056],
              [-36.84361571, -8.39364116],
              [-36.84387826, -8.39357553],
              [-36.84411163, -8.39374326],
              [-36.84447628, -8.39395476],
              [-36.84471694, -8.39429023],
              [-36.84519827, -8.39445067],
              [-36.84545352, -8.39470593],
              [-36.84593485, -8.39496847],
              [-36.84618281, -8.39495388],
              [-36.84617552, -8.39474968],
              [-36.84622657, -8.39449443],
              [-36.84643806, -8.39438504],
              [-36.84672978, -8.3943267],
              [-36.84707254, -8.39451631],
              [-36.84737155, -8.39446526],
              [-36.84756846, -8.39438504],
              [-36.84786017, -8.39435587],
              [-36.84805708, -8.3943194],
              [-36.8482394, -8.39425377],
              [-36.84864781, -8.39408603],
              [-36.84887389, -8.39382349],
              [-36.84897599, -8.39344426],
              [-36.84910726, -8.39310878],
              [-36.84945732, -8.3927879],
              [-36.84980738, -8.39280248],
              [-36.85003345, -8.39262745],
              [-36.85030329, -8.39254723],
              [-36.85044186, -8.3927879],
              [-36.85066064, -8.39290458],
              [-36.85089402, -8.3928827],
              [-36.85117115, -8.39291917],
              [-36.85156496, -8.39280978],
              [-36.85196607, -8.39285353],
              [-36.85241094, -8.39285353],
              [-36.85284851, -8.39296293],
              [-36.8530673, -8.39286812],
              [-36.85332255, -8.39270038],
              [-36.85368719, -8.39277331],
              [-36.85400808, -8.39287541],
              [-36.85420499, -8.39289],
              [-36.85451129, -8.39285353],
              [-36.85477383, -8.39289],
              [-36.85510201, -8.3929848],
              [-36.85551771, -8.39301398],
              [-36.85579484, -8.39302127],
              [-36.85598445, -8.39316713],
              [-36.85633451, -8.39334945],
              [-36.85674291, -8.39358282],
              [-36.85722424, -8.39380161],
              [-36.85745032, -8.39402039],
              [-36.85764279, -8.3941235],
              [-36.85765682, -8.39413498],
              [-36.85779309, -8.39424647],
              [-36.85788789, -8.39441421],
              [-36.85798999, -8.39460383],
              [-36.85799729, -8.39484449],
              [-36.85799496, -8.39490026],
              [-36.85798999, -8.39501952],
              [-36.85806768, -8.39506484],
              [-36.85807022, -8.39507057],
              [-36.85830359, -8.39508516],
              [-36.85851508, -8.39505598],
              [-36.85877033, -8.3949393],
              [-36.859011, -8.39474239],
              [-36.85920061, -8.39456736],
              [-36.85917873, -8.39433399],
              [-36.88412563, -8.37106356],
              [-36.90064821, -8.34125793],
              [-36.86480764, -8.32218825],
              [-36.8562, -8.294362],
              [-36.8562, -8.294362],
              [-36.8562, -8.294362],
              [-36.8562, -8.294362],
              [-36.854949611354002, -8.2949053268607],
              [-36.853163353804, -8.295747866466799],
              [-36.849956, -8.297282],
              [-36.847728, -8.298414],
              [-36.846414758728002, -8.2990481198584],
              [-36.843397804680002, -8.3005584364886],
              [-36.841777111681999, -8.301347627517],
              [-36.841405511243998, -8.301538946554199],
              [-36.841073, -8.30164],
              [-36.840813158072002, -8.301713709136299],
              [-36.840535377545997, -8.3017247467731],
              [-36.839356190018997, -8.30156470104],
              [-36.837176256759001, -8.3012133362698],
              [-36.83633662, -8.30110314],
              [-36.835053351288003, -8.3008343774076],
              [-36.834668873608003, -8.3007497555258],
              [-36.834289914746002, -8.3006246623092],
              [-36.833627656540003, -8.3002144301429],
              [-36.832853182360999, -8.2998428297052],
              [-36.832439270982, -8.2995724076046],
              [-36.831966492207002, -8.2993921262042],
              [-36.831630304187001, -8.2994151212808],
              [-36.831313432031997, -8.2995687283923],
              [-36.831041170326003, -8.299747170186601],
              [-36.828787652819997, -8.3008067833157],
              [-36.827652615840002, -8.3010661677796],
              [-36.826340976670998, -8.3013697027906],
              [-36.825941782142003, -8.301559182221601],
              [-36.824815943192, -8.302657427079399],
              [-36.824129770107, -8.303056621608899],
              [-36.822749145708997, -8.3035413578234],
              [-36.822610255446001, -8.303460415153801],
              [-36.822396861134997, -8.3033141664667],
              [-36.821636184002003, -8.302810114387899],
              [-36.820423883563997, -8.3019363014776],
              [-36.820072518793999, -8.3017210675608],
              [-36.819772662995, -8.301415692943699],
              [-36.819551910260003, -8.301051450930601],
              [-36.818606352711001, -8.2993350984142],
              [-36.816086092317001, -8.297109175000701],
              [-36.814037, -8.295333],
              [-36.812200844176999, -8.293735337364],
              [-36.811164226125001, -8.293472273687801],
              [-36.80782111, -8.29278668],
              [-36.807224, -8.292527],
              [-36.805960440292999, -8.292214902900101],
              [-36.805075, -8.291998],
              [-36.804238, -8.291719],
              [-36.801884, -8.291466],
              [-36.80087776, -8.29162745],
              [-36.800649497404002, -8.291604153665901],
              [-36.799981, -8.291198],
              [-36.799409602875002, -8.290973168764401],
              [-36.798905, -8.290864],
              [-36.79860912, -8.29085409],
              [-36.7985608, -8.29062862],
              [-36.79845199, -8.29015416],
              [-36.79816949, -8.28954679],
              [-36.79778813, -8.28895355],
              [-36.79725139, -8.28830382],
              [-36.79605695, -8.28659703],
              [-36.79538692, -8.28533763],
              [-36.79523155, -8.28427827],
              [-36.79484573, -8.28381753],
              [-36.79444057, -8.28283755],
              [-36.79391795, -8.28207481],
              [-36.79326821, -8.28169345],
              [-36.79244898, -8.28047872],
              [-36.79212411, -8.27987136],
              [-36.79150262, -8.27902388],
              [-36.79100826, -8.27869901],
              [-36.79082464, -8.27857188],
              [-36.79027377, -8.27828939],
              [-36.79021728, -8.27802102],
              [-36.79005029, -8.27782844],
              [-36.78972291, -8.27762553],
              [-36.78928504, -8.27749841],
              [-36.78953071, -8.27690893],
              [-36.79411415, -8.26289603],
              [-36.79414702, -8.26279742],
              [-36.79496876, -8.26034864],
              [-36.79736823, -8.25292012],
              [-36.7853881, -8.25412347],
              [-36.78357732, -8.25337311],
              [-36.78231972, -8.25345997],
              [-36.78039028, -8.25435095],
              [-36.76135608, -8.25747505],
              [-36.76085213, -8.25773902],
              [-36.75288551, -8.25973241],
              [-36.74396936, -8.25900061],
              [-36.74113748, -8.25914359],
              [-36.73037829, -8.25757322],
              [-36.716307, -8.2595644],
              [-36.71588598, -8.25963361],
              [-36.71579103, -8.25972496],
              [-36.71538093, -8.26015841],
              [-36.71480071, -8.26033363],
              [-36.69941878, -8.26358616],
              [-36.6955223, -8.26315636],
              [-36.69524623, -8.26303234],
              [-36.69364906, -8.26137265],
              [-36.69349282, -8.26121029],
              [-36.69092463, -8.25851446],
              [-36.69026232, -8.25970818],
              [-36.68617735, -8.27034719],
              [-36.68497757, -8.27035807],
              [-36.68323577, -8.26519774],
              [-36.6587745, -8.27339279],
              [-36.65845916, -8.27353556],
              [-36.64664111, -8.27992826],
              [-36.64350203, -8.28123502],
              [-36.63317629, -8.28450609],
              [-36.63339959, -8.2851429],
              [-36.63395369, -8.28695407],
              [-36.6344499, -8.28853367],
              [-36.6346732, -8.28918702],
              [-36.63501228, -8.29022906],
              [-36.63529346, -8.29113051],
              [-36.63555811, -8.2920485],
              [-36.63572351, -8.29261915],
              [-36.6357814, -8.2928259],
              [-36.63574832, -8.29344616],
              [-36.6356987, -8.29433934],
              [-36.635616, -8.2955716],
              [-36.63556638, -8.29667981],
              [-36.6354506, -8.29812709],
              [-36.63536789, -8.29936762],
              [-36.63534308, -8.3008976],
              [-36.63547541, -8.30166673],
              [-36.63574832, -8.30260126],
              [-36.63597162, -8.30351925],
              [-36.63619491, -8.30431319],
              [-36.63654226, -8.30547929],
              [-36.63665804, -8.30566537],
              [-36.63679864, -8.30586799],
              [-36.63681542, -8.30588847],
              [-36.63728658, -8.30646344],
              [-36.63806397, -8.30701754],
              [-36.638279, -8.30719949],
              [-36.63982139, -8.3083201],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        gid: 400.0,
        terrai_cod: 70201.0,
        terrai_nom: 'Xukuru de Cimbres',
        etnia_nome: 'Xucuru',
        municipio_: 'Pesqueira,Pedra,Alagoinha,Venturosa',
        uf_sigla: 'PE',
        superficie: 1166.1793,
        fase_ti: 'Regularizada',
        modalidade: 'Reserva Indígena',
        reestudo_t: null,
        cr: 'COORDENACAO REGIONAL NORDESTE I',
        faixa_fron: 'Não',
        undadm_cod: 30202001969.0,
        undadm_nom: 'COORDENACAO REGIONAL NORDESTE I',
        undadm_sig: 'CR-NE-I',
        dominio_un: 't',
        data_atual: '12/04/2021',
        epsg: 0.0,
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.836866388889, -8.402348055555599],
              [-36.828276944443999, -8.4039144444444],
              [-36.820253055556002, -8.405393333333301],
              [-36.819090277778002, -8.408858333333299],
              [-36.8168625, -8.4156011111111],
              [-36.814648055555999, -8.4223308333333],
              [-36.813146388889002, -8.4268094444444],
              [-36.823206666666998, -8.425981111111099],
              [-36.828400555556001, -8.4367977777778],
              [-36.826845, -8.43904],
              [-36.825612777777998, -8.442149722222201],
              [-36.828038333332998, -8.450673055555599],
              [-36.827688055556003, -8.452578888888899],
              [-36.828455, -8.452255],
              [-36.831254722221999, -8.4569677777778],
              [-36.8356325, -8.4544761111111],
              [-36.842115, -8.4546136111111],
              [-36.837948888889002, -8.446509166666701],
              [-36.837129722222002, -8.444078611111101],
              [-36.837146666667003, -8.443577222222199],
              [-36.837474444443998, -8.4423177777778],
              [-36.838640833333002, -8.4398538888889],
              [-36.838845833332996, -8.439164444444399],
              [-36.839579722221998, -8.438513333333299],
              [-36.8390675, -8.4338477777778],
              [-36.839521944444002, -8.432037777777801],
              [-36.8448025, -8.42046],
              [-36.845623055555997, -8.4180105555556],
              [-36.845280833333, -8.4157372222222],
              [-36.844435, -8.4154622222222],
              [-36.839839722222003, -8.4053841666667],
              [-36.838923055556002, -8.4046325],
              [-36.836866388889, -8.402348055555599],
            ],
          ],
        ],
      },
    },
  ],
};
