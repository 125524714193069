export var jsonUHEBaseExistente25 = {
  type: 'FeatureCollection',
  name: 'UHE__Base_Existente_25',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        NOME: 'Apolônio Sales (Antiga Moxotó)',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'Rio São Francisco',
        potencia: 400000.0,
        ini_oper: '1977-04-15',
        ceg: 'UHE.PH.AL.001510-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.208490919999974, -9.358110159999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Paulo Afonso I',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'Rio São Francisco',
        potencia: 180001.0,
        ini_oper: '1964-12-30',
        ceg: 'UHE.PH.BA.002012-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.202872439999965, -9.398103779999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Sobradinho',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'Rio São Francisco',
        potencia: 1050000.0,
        ini_oper: '1982-03-03',
        ceg: 'UHE.PH.BA.002755-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.827199159999964, -9.430238409999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Paulo Afonso II',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'Rio São Francisco',
        potencia: 443000.0,
        ini_oper: '1961-10-24',
        ceg: 'UHE.PH.BA.027048-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.202663049999956, -9.396883119999927],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Paulo Afonso III',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'Rio São Francisco',
        potencia: 794200.0,
        ini_oper: '1971-10-21',
        ceg: 'UHE.PH.BA.027049-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.201937309999948, -9.396050309999964],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Paulo Afonso IV',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'São Francisco',
        potencia: 2462400.0,
        ini_oper: '1979-12-01',
        ceg: 'UHE.PH.BA.027050-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.207671269999935, -9.415645309999945],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Luiz Gonzaga (Itaparica)',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'Rio São Francisco',
        potencia: 1479600.0,
        ini_oper: '1988-01-31',
        ceg: 'UHE.PH.PE.001174-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.312404679999929, -9.143523029999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        NOME: 'Xingó',
        propriet: '100% para Companhia Hidro Elétrica do São Francisco (PIE)',
        RIO: 'Rio São Francisco',
        potencia: 3162000.0,
        ini_oper: '1994-12-16',
        ceg: 'UHE.PH.SE.027053-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.792559799999935, -9.619580179999957],
      },
    },
  ],
};
