export var jsonReasDeQuilombolasPE2 = {
  type: 'FeatureCollection',
  name: 'reasdeQuilombolas_PE_2',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        cd_quilomb: null,
        cd_sr: 'SR-08',
        nr_process: '54140.003112/2008-11',
        nm_comunid: 'CASTAINHO',
        nm_municip: 'GARANHUNS',
        cd_uf: 'PE',
        dt_publica: null,
        dt_public1: null,
        nr_familia: 206.0,
        dt_titulac: '20/10/2015',
        nr_area_ha: 189.7738,
        nr_perimet: null,
        cd_sipra: null,
        ob_descric: null,
        st_titulad: null,
        dt_decreto: '23/11/2009',
        tp_levanta: null,
        nr_escalao: null,
        area_calc_: 189.5524,
        perimetro_: 106660.6823,
        esfera: 'FEDERAL',
        fase: 'TITULO PARCIAL',
        responsave: 'INCRA',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.477150255095331, -8.914802655176517],
              [-36.476493835355207, -8.915523591143353],
              [-36.476153804684273, -8.915799629636536],
              [-36.474368639714207, -8.916996136102929],
              [-36.473440778191211, -8.91765348877332],
              [-36.472309416137193, -8.918142058150666],
              [-36.470072745065984, -8.919087986281824],
              [-36.468515866028312, -8.920197134537599],
              [-36.46843194217729, -8.920247339283609],
              [-36.467507723512327, -8.920790491759229],
              [-36.46732399246774, -8.921022338516757],
              [-36.466716722229641, -8.921975499959606],
              [-36.466303626808035, -8.922506655159907],
              [-36.460396782577071, -8.922279500123516],
              [-36.456598956118974, -8.922325938609598],
              [-36.456536895930292, -8.922214716202573],
              [-36.452080019594312, -8.922384712582868],
              [-36.452081436569408, -8.922391355119267],
              [-36.461290046360737, -8.922743511533314],
              [-36.461158445189284, -8.923077959373353],
              [-36.460799135242809, -8.92311584117564],
              [-36.46077735420436, -8.923179960392577],
              [-36.460806784540779, -8.923397319907311],
              [-36.460655550181343, -8.923801865414028],
              [-36.460510399488385, -8.924783637071959],
              [-36.460529931089475, -8.92566580454042],
              [-36.46055386523247, -8.925696376417728],
              [-36.460924093533855, -8.925961657352399],
              [-36.460960961835283, -8.925965605561386],
              [-36.462387018554779, -8.925510345341859],
              [-36.462669819064956, -8.925200922912902],
              [-36.462811596686265, -8.925091501063349],
              [-36.463302841379146, -8.92475664601673],
              [-36.463380461762085, -8.924861341430447],
              [-36.464482395348291, -8.925821456073765],
              [-36.466491756120305, -8.926533899610718],
              [-36.466631533942603, -8.926560111245498],
              [-36.468372373413061, -8.927778382508372],
              [-36.467120116088559, -8.928646090721324],
              [-36.464417192984257, -8.93020773289585],
              [-36.463822213502716, -8.930361137261329],
              [-36.461085680892594, -8.931052348960259],
              [-36.460904098446932, -8.931090523531365],
              [-36.456677345177582, -8.932048175522157],
              [-36.456829759606642, -8.932790489421988],
              [-36.456966729292766, -8.933424755812199],
              [-36.457098229237538, -8.933998620548008],
              [-36.457216311750244, -8.934532451729266],
              [-36.460511980700332, -8.933726900150159],
              [-36.460823045371313, -8.93365356591104],
              [-36.463983472332018, -8.93270597352535],
              [-36.465504030690333, -8.931925059661062],
              [-36.465571728034519, -8.93191417230304],
              [-36.465679227976828, -8.931697097362425],
              [-36.467475573230956, -8.931399429635162],
              [-36.46846170518765, -8.931212560956842],
              [-36.469889481868115, -8.93093255237504],
              [-36.469338510204899, -8.930546390093282],
              [-36.469526613189345, -8.929688216430046],
              [-36.469925143346153, -8.929144606296019],
              [-36.469793092205286, -8.929019054225842],
              [-36.469208490787992, -8.928525985631225],
              [-36.46855030309959, -8.927891379687669],
              [-36.46892852068806, -8.92765866395013],
              [-36.474104132231936, -8.924504592760403],
              [-36.483211915397092, -8.91899673212089],
              [-36.483167408923379, -8.918908566319725],
              [-36.481567585874821, -8.917760060792165],
              [-36.479032115703134, -8.916111229064581],
              [-36.477150255095331, -8.914802655176517],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_quilomb: '120',
        cd_sr: 'SR-29',
        nr_process: '54141002407/2006-90',
        nm_comunid: 'SANTANA III',
        nm_municip: 'SALGUEIRO',
        cd_uf: 'PE',
        dt_publica: '18/06/2012',
        dt_public1: '02/05/2014',
        nr_familia: 85.0,
        dt_titulac: null,
        nr_area_ha: 2309.2072,
        nr_perimet: 24283.709,
        cd_sipra: null,
        ob_descric: 'estudo de delimitação',
        st_titulad: 'F',
        dt_decreto: '23/06/2015',
        tp_levanta: 'gps trimble juno sb',
        nr_escalao: '1/40000',
        area_calc_: 2315.2398,
        perimetro_: 219670.9548,
        esfera: 'FEDERAL',
        fase: 'DECRETO',
        responsave: 'INCRA',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.252109816174993, -8.139143102362709],
              [-39.248740547155599, -8.140856456633744],
              [-39.234721617798364, -8.15933508016518],
              [-39.233614026858902, -8.168590400711757],
              [-39.232735835861888, -8.171545973494206],
              [-39.233507293224037, -8.185643562283945],
              [-39.224561498525141, -8.181570136645684],
              [-39.224177818909325, -8.181951731789388],
              [-39.221425446187091, -8.224503597816128],
              [-39.249694984952733, -8.21212739633088],
              [-39.24951655200276, -8.211967195471551],
              [-39.249161293838597, -8.211443388766844],
              [-39.250543489915408, -8.207278936966667],
              [-39.252425095921573, -8.19598079717643],
              [-39.252511850402698, -8.195472952373073],
              [-39.252765748071162, -8.194078309674612],
              [-39.253963406647678, -8.180434480326182],
              [-39.260033419274791, -8.165342120489401],
              [-39.259719836181702, -8.162168786130604],
              [-39.259757710410881, -8.157320205922776],
              [-39.264077198881679, -8.154275443796024],
              [-39.263381810979659, -8.151864553037587],
              [-39.263207825739045, -8.150211454559273],
              [-39.258624133889242, -8.148193332178868],
              [-39.256565330218109, -8.146031563858186],
              [-39.252109816174993, -8.139143102362709],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_quilomb: '62',
        cd_sr: 'SR-29',
        nr_process: '54141.002033/2006-11',
        nm_comunid: 'CONTENDAS',
        nm_municip: 'SALGUEIRO E TERRA NOVA',
        cd_uf: 'PE',
        dt_publica: '28/10/2008',
        dt_public1: null,
        nr_familia: 44.0,
        dt_titulac: null,
        nr_area_ha: 2087.344,
        nr_perimet: null,
        cd_sipra: null,
        ob_descric: 'Estudo de delimitação',
        st_titulad: 'F',
        dt_decreto: null,
        tp_levanta: null,
        nr_escalao: null,
        area_calc_: 2109.0138,
        perimetro_: 388602.7337,
        esfera: 'FEDERAL',
        fase: 'RTID',
        responsave: 'INCRA',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.294608706475778, -8.107397076977623],
              [-39.278244504328107, -8.105928649081678],
              [-39.278208739638551, -8.105925417125709],
              [-39.277860810120316, -8.105900688941608],
              [-39.258897238096473, -8.107872596508807],
              [-39.254742025511469, -8.108990971254945],
              [-39.254649738920527, -8.109030738474093],
              [-39.254543788695656, -8.109137993312572],
              [-39.254472450978277, -8.109292534255571],
              [-39.255859889453816, -8.110361471716349],
              [-39.255859908790569, -8.110361469419633],
              [-39.255859974674088, -8.110361520662529],
              [-39.255860040081501, -8.110361572507751],
              [-39.255860105007002, -8.110361624950917],
              [-39.255860169445313, -8.110361677987585],
              [-39.255860233390813, -8.11036173161326],
              [-39.255860296838108, -8.110361785823399],
              [-39.255860359781899, -8.110361840613374],
              [-39.255860422216792, -8.110361895978574],
              [-39.255860484137479, -8.110361951914305],
              [-39.255860545538759, -8.110362008415827],
              [-39.255860606415325, -8.11036206547832],
              [-39.255860666762203, -8.110362123096987],
              [-39.25586072657407, -8.110362181266897],
              [-39.255860785846039, -8.110362239983155],
              [-39.255860844572901, -8.110362299240768],
              [-39.255860902749845, -8.110362359034726],
              [-39.255860960371812, -8.110362419359936],
              [-39.255861017434057, -8.110362480211291],
              [-39.255861073931598, -8.110362541583642],
              [-39.255861129859731, -8.110362603471794],
              [-39.255861185213668, -8.110362665870481],
              [-39.255861239988704, -8.110362728774412],
              [-39.255861294180328, -8.110362792178281],
              [-39.255861347783743, -8.110362856076687],
              [-39.255861400794558, -8.110362920464214],
              [-39.255861453208176, -8.110362985335424],
              [-39.255861505020277, -8.110363050684796],
              [-39.255861556226385, -8.110363116506782],
              [-39.25586160682208, -8.110363182795856],
              [-39.255861656803269, -8.110363249546362],
              [-39.255861706165554, -8.110363316752608],
              [-39.255861754904835, -8.110363384408954],
              [-39.255861803016913, -8.110363452509624],
              [-39.255861850497787, -8.110363521048841],
              [-39.255861897343351, -8.110363590020826],
              [-39.255861943549718, -8.110363659419704],
              [-39.255861989112979, -8.110363729239614],
              [-39.255862034029121, -8.110363799474612],
              [-39.25586207829447, -8.110363870118798],
              [-39.25586212190521, -8.110363941166122],
              [-39.255862164857746, -8.11036401261059],
              [-39.255862207148269, -8.11036408444614],
              [-39.255862248773404, -8.110364156666652],
              [-39.255862289729414, -8.110364229266073],
              [-39.255862330012931, -8.110364302238192],
              [-39.255862369620537, -8.110364375576815],
              [-39.255862408548829, -8.110364449275782],
              [-39.255862446794524, -8.110364523328775],
              [-39.255862484354516, -8.110364597729562],
              [-39.255862521225424, -8.110364672471844],
              [-39.255862557404186, -8.110364747549234],
              [-39.255862592887766, -8.110364822955425],
              [-39.255862627673125, -8.110364898683979],
              [-39.255862661757391, -8.110364974728501],
              [-39.255862695137552, -8.110365051082564],
              [-39.2558627278109, -8.110365127739637],
              [-39.255862759774544, -8.110365204693286],
              [-39.255862791025777, -8.110365281936913],
              [-39.255862821562111, -8.110365359464025],
              [-39.25586285138084, -8.11036543726806],
              [-39.25586288047937, -8.110365515342393],
              [-39.255862908855377, -8.110365593680383],
              [-39.255862936506389, -8.110365672275424],
              [-39.25586296343009, -8.11036575112082],
              [-39.255862989624084, -8.110365830209949],
              [-39.255863015086277, -8.110365909536041],
              [-39.255863039814564, -8.110365989092408],
              [-39.255863063806636, -8.110366068872278],
              [-39.255863087060618, -8.110366148868929],
              [-39.255863109574484, -8.11036622907555],
              [-39.255863131346338, -8.11036630948534],
              [-39.255863152374403, -8.110366390091492],
              [-39.255863172656731, -8.110366470887172],
              [-39.255863192191775, -8.110366551865532],
              [-39.255863210977708, -8.110366633019691],
              [-39.255863229013144, -8.110366714342783],
              [-39.255863246296364, -8.110366795827918],
              [-39.255863262826068, -8.110366877468172],
              [-39.255863278600692, -8.110366959256654],
              [-39.255863293618994, -8.11036704118639],
              [-39.255863307879793, -8.110367123250462],
              [-39.255863321381582, -8.110367205441896],
              [-39.255863334123568, -8.110367287753741],
              [-39.255863346104427, -8.110367370178983],
              [-39.255863357323207, -8.110367452710674],
              [-39.255863367778971, -8.11036753534181],
              [-39.255863377470916, -8.11036761806538],
              [-39.255863386398076, -8.110367700874374],
              [-39.255863394559825, -8.110367783761765],
              [-39.255863401955267, -8.110367866720541],
              [-39.255863408583998, -8.110367949743658],
              [-39.255863414445415, -8.110368032824072],
              [-39.255863419538933, -8.110368115954753],
              [-39.255863423864142, -8.110368199128628],
              [-39.255863427420756, -8.110368282338666],
              [-39.25586343020835, -8.110368365577818],
              [-39.255863432226739, -8.110368448839017],
              [-39.255863433475817, -8.110368532115197],
              [-39.255863433954701, -8.110368615400711],
              [-39.255863433957593, -8.110368619961505],
              [-39.255863881019877, -8.111075765414412],
              [-39.255863881034585, -8.111075788799328],
              [-39.255863881095507, -8.111075803620151],
              [-39.255863881180815, -8.111075818440861],
              [-39.255863881290438, -8.111075833261404],
              [-39.255863881424453, -8.111075848081731],
              [-39.255863881582769, -8.111075862901842],
              [-39.255863881765471, -8.111075877721674],
              [-39.255863881972495, -8.111075892541173],
              [-39.255863882203911, -8.111075907360325],
              [-39.255863882459622, -8.111075922179067],
              [-39.255863882739739, -8.111075936997375],
              [-39.255863883044157, -8.111075951815209],
              [-39.255863883372974, -8.111075966632528],
              [-39.255863883726185, -8.111075981449288],
              [-39.255863884103704, -8.11107599626545],
              [-39.255863884505523, -8.111076011080982],
              [-39.255863884931735, -8.111076025895832],
              [-39.255863885382254, -8.111076040709982],
              [-39.255863885857174, -8.11107605552335],
              [-39.255863886356387, -8.111076070335962],
              [-39.255863886880022, -8.111076085147712],
              [-39.255863887427921, -8.111076099958582],
              [-39.255863888000142, -8.11107611476856],
              [-39.255863888596757, -8.111076129577562],
              [-39.255863889217672, -8.111076144385592],
              [-39.255863889862901, -8.111076159192564],
              [-39.255863890532524, -8.111076173998464],
              [-39.25586389122644, -8.111076188803271],
              [-39.255863891944649, -8.111076203606922],
              [-39.255863892687273, -8.111076218409362],
              [-39.255863893454084, -8.111076233210593],
              [-39.255863894245415, -8.111076248010532],
              [-39.255863895060934, -8.111076262809162],
              [-39.255863895900767, -8.111076277606474],
              [-39.255863896764971, -8.111076292402375],
              [-39.255863897653498, -8.111076307196836],
              [-39.255863898566218, -8.111076321989835],
              [-39.255863899503353, -8.111076336781315],
              [-39.255863900464753, -8.111076351571242],
              [-39.255863901450475, -8.111076366359617],
              [-39.255863902460504, -8.111076381146356],
              [-39.25586390349482, -8.111076395931384],
              [-39.255863904553543, -8.111076410714732],
              [-39.255863905636367, -8.111076425496332],
              [-39.255863906743592, -8.111076440276161],
              [-39.25586390787511, -8.11107645505416],
              [-39.255863909030836, -8.111076469830294],
              [-39.255863910210856, -8.111076484604489],
              [-39.25586391141529, -8.111076499376752],
              [-39.255863912643903, -8.111076514147049],
              [-39.255863913896732, -8.111076528915316],
              [-39.255863915173855, -8.111076543681508],
              [-39.255863916475299, -8.111076558445596],
              [-39.255863917801008, -8.111076573207546],
              [-39.255863919150933, -8.111076587967306],
              [-39.255863920525059, -8.111076602724848],
              [-39.255863921923492, -8.111076617480121],
              [-39.255863923346205, -8.111076632233113],
              [-39.255863924793132, -8.111076646983753],
              [-39.255863926264261, -8.111076661731994],
              [-39.255863927759691, -8.111076676477809],
              [-39.255863929279315, -8.111076691221198],
              [-39.255863930823139, -8.111076705962059],
              [-39.255863932391158, -8.111076720700403],
              [-39.255863933983491, -8.111076735436146],
              [-39.255863935600026, -8.111076750169275],
              [-39.255863937240747, -8.111076764899732],
              [-39.255863938905676, -8.11107677962752],
              [-39.2558639405948, -8.111076794352561],
              [-39.255863942308139, -8.11107680907484],
              [-39.255863944045657, -8.111076823794278],
              [-39.255863945807285, -8.111076838510888],
              [-39.255863947593227, -8.111076853224564],
              [-39.255863949403249, -8.111076867935353],
              [-39.255863951237465, -8.111076882643152],
              [-39.255863953095904, -8.111076897347939],
              [-39.25586395497843, -8.111076912049668],
              [-39.255863956885158, -8.111076926748286],
              [-39.255863958815986, -8.111076941443775],
              [-39.255863960771023, -8.111076956136122],
              [-39.255863962750148, -8.111076970825254],
              [-39.255863964753487, -8.111076985511129],
              [-39.255863966780915, -8.111077000193694],
              [-39.255863968832443, -8.111077014872956],
              [-39.25586397090818, -8.111077029548834],
              [-39.255863973007898, -8.111077044221323],
              [-39.255863975131838, -8.111077058890361],
              [-39.255863977279866, -8.111077073555906],
              [-39.255863979451902, -8.111077088217925],
              [-39.255863981648126, -8.111077102876381],
              [-39.255863983868359, -8.111077117531227],
              [-39.255863986112807, -8.111077132182437],
              [-39.255863988381222, -8.111077146829974],
              [-39.25586399067366, -8.111077161473784],
              [-39.255863992990292, -8.111077176113813],
              [-39.255863995330827, -8.11107719075007],
              [-39.255863997695556, -8.111077205382475],
              [-39.255864000084294, -8.111077220011003],
              [-39.255864002497034, -8.111077234635619],
              [-39.255864004933763, -8.11107724925626],
              [-39.255863972718672, -8.111077236013786],
              [-39.25601676922566, -8.111989102784772],
              [-39.256016773516421, -8.111989121330254],
              [-39.256016784248757, -8.111989184033236],
              [-39.256016795423314, -8.111989246659489],
              [-39.256016807039565, -8.111989309205844],
              [-39.256016819096828, -8.111989371669271],
              [-39.256016831594586, -8.111989434046665],
              [-39.256016844532255, -8.11198949633493],
              [-39.256016857909025, -8.111989558531002],
              [-39.256016871724498, -8.111989620631798],
              [-39.256016885977786, -8.111989682634215],
              [-39.256016900668158, -8.111989744535247],
              [-39.256016915795044, -8.111989806331787],
              [-39.256016931357642, -8.111989868020803],
              [-39.256016947355135, -8.11198992959924],
              [-39.256016963786827, -8.111989991064034],
              [-39.256016980651737, -8.111990052412178],
              [-39.256016997949231, -8.111990113640612],
              [-39.256017015678239, -8.111990174746323],
              [-39.256017033838155, -8.111990235726275],
              [-39.256017052427872, -8.111990296577472],
              [-39.256017071446486, -8.111990357296881],
              [-39.256017090893103, -8.111990417881504],
              [-39.256017110766734, -8.111990478328362],
              [-39.256017131066464, -8.111990538634466],
              [-39.256017151791305, -8.111990598796817],
              [-39.256017172940027, -8.111990658812443],
              [-39.256017194511763, -8.111990718678394],
              [-39.256017216505512, -8.1119907783917],
              [-39.256017238919952, -8.111990837949408],
              [-39.256017261754216, -8.111990897348551],
              [-39.256017285007069, -8.111990956586196],
              [-39.256017308677322, -8.11199101565947],
              [-39.256017332763783, -8.111991074565376],
              [-39.25601735726535, -8.11199113330105],
              [-39.256017382180815, -8.111991191863558],
              [-39.256017407508885, -8.111991250250023],
              [-39.256017433248353, -8.111991308457565],
              [-39.256017459397938, -8.111991366483295],
              [-39.256017485956306, -8.111991424324353],
              [-39.256017512922298, -8.111991481977832],
              [-39.256017540294287, -8.111991539440959],
              [-39.256017568071186, -8.111991596710842],
              [-39.256017596251475, -8.111991653784656],
              [-39.256017624833873, -8.111991710659565],
              [-39.256017653816777, -8.111991767332764],
              [-39.256017683198976, -8.111991823801471],
              [-39.256017712978874, -8.111991880062885],
              [-39.256017743155098, -8.111991936114194],
              [-39.256017773726107, -8.111991991952669],
              [-39.25601780469033, -8.111992047575558],
              [-39.256017836046347, -8.111992102980043],
              [-39.256017867792565, -8.111992158163439],
              [-39.256017899927294, -8.111992213123022],
              [-39.256017932449126, -8.111992267856033],
              [-39.256017965356357, -8.111992322359789],
              [-39.256017998647501, -8.111992376631616],
              [-39.25601803232064, -8.111992430668785],
              [-39.256018066374281, -8.111992484468649],
              [-39.25601810080682, -8.111992538028568],
              [-39.256018135616372, -8.111992591345853],
              [-39.256018170801319, -8.111992644417867],
              [-39.256018206359869, -8.111992697242032],
              [-39.256018242290331, -8.111992749815695],
              [-39.256018278590901, -8.111992802136275],
              [-39.256018315259752, -8.111992854201178],
              [-39.25601835229503, -8.111992906007826],
              [-39.256018389695093, -8.11199295755365],
              [-39.25601842745786, -8.111993008836127],
              [-39.256018465581647, -8.111993059852709],
              [-39.256018504064421, -8.111993110600842],
              [-39.256018542904407, -8.111993161078074],
              [-39.256018582099593, -8.111993211281877],
              [-39.256018621648074, -8.111993261209761],
              [-39.256018661547962, -8.11199331085926],
              [-39.256018701797153, -8.111993360227954],
              [-39.256018742393749, -8.111993409313355],
              [-39.256018783335648, -8.111993458113055],
              [-39.256018824620952, -8.111993506624652],
              [-39.256018866247558, -8.111993554845736],
              [-39.256018908213356, -8.111993602773907],
              [-39.256018950516363, -8.111993650406816],
              [-39.256018993154477, -8.111993697742092],
              [-39.256019036125487, -8.111993744777402],
              [-39.256019079427404, -8.111993791510438],
              [-39.256019123058024, -8.111993837938858],
              [-39.256019167015133, -8.11199388406038],
              [-39.256019211296667, -8.111993929872737],
              [-39.256019255900291, -8.111993975373649],
              [-39.256019300824029, -8.111994020560848],
              [-39.256019346065457, -8.111994065432141],
              [-39.256019391622395, -8.111994109985284],
              [-39.256019437492526, -8.111994154218062],
              [-39.256019483673768, -8.111994198128309],
              [-39.256019530163599, -8.11199424171385],
              [-39.256019576959936, -8.111994284972541],
              [-39.256019624060386, -8.111994327902218],
              [-39.256019671462532, -8.111994370500746],
              [-39.256019719164193, -8.111994412766059],
              [-39.256019767162826, -8.11199445469604],
              [-39.256019815456298, -8.111994496288631],
              [-39.256019858811293, -8.111994528116229],
              [-39.256373233237042, -8.112296695463463],
              [-39.256373201870367, -8.112296692784779],
              [-39.25637321894893, -8.112296707352822],
              [-39.256373236063688, -8.112296721878687],
              [-39.256373253214448, -8.11229673636227],
              [-39.256373270401106, -8.112296750803443],
              [-39.256373287623674, -8.112296765202185],
              [-39.256373304881933, -8.11229677955837],
              [-39.256373322175797, -8.112296793871909],
              [-39.256373339505259, -8.112296808142721],
              [-39.256373356870128, -8.112296822370713],
              [-39.256373374270289, -8.112296836555814],
              [-39.256373391705552, -8.112296850697925],
              [-39.256373409176014, -8.112296864796951],
              [-39.256373426681485, -8.112296878852836],
              [-39.256373444221744, -8.112296892865455],
              [-39.256373461796812, -8.112296906834754],
              [-39.256373479406584, -8.112296920760601],
              [-39.256373497050838, -8.112296934642968],
              [-39.256373514729603, -8.112296948481738],
              [-39.25637353244278, -8.112296962276833],
              [-39.256373550190034, -8.112296976028173],
              [-39.256373567971501, -8.112296989735658],
              [-39.256373585786868, -8.112297003399233],
              [-39.256373603636234, -8.11229701701879],
              [-39.256373621519408, -8.112297030594247],
              [-39.256373639436269, -8.112297044125512],
              [-39.256373657386639, -8.112297057612526],
              [-39.256373675370497, -8.112297071055201],
              [-39.256373693387765, -8.112297084453447],
              [-39.256373711438243, -8.11229709780719],
              [-39.256373729521805, -8.112297111116334],
              [-39.256373747638477, -8.112297124380788],
              [-39.256373765787941, -8.112297137600509],
              [-39.256373783970311, -8.112297150775381],
              [-39.25637380218528, -8.112297163905364],
              [-39.256373820432948, -8.112297176990339],
              [-39.256373838713024, -8.112297190030207],
              [-39.256373857025395, -8.112297203024951],
              [-39.256373875370059, -8.112297215974449],
              [-39.256373893746833, -8.112297228878608],
              [-39.256373912155603, -8.112297241737402],
              [-39.256373930596382, -8.112297254550699],
              [-39.256373949068852, -8.112297267318448],
              [-39.256373967573019, -8.112297280040567],
              [-39.256373986108684, -8.112297292716987],
              [-39.256374004675855, -8.112297305347596],
              [-39.256374023274333, -8.11229731793237],
              [-39.256374041904103, -8.112297330471199],
              [-39.256374060564873, -8.112297342963995],
              [-39.256374079256744, -8.112297355410712],
              [-39.256374097979432, -8.112297367811241],
              [-39.2563741167329, -8.112297380165522],
              [-39.256374135516964, -8.112297392473499],
              [-39.256374154331638, -8.112297404735052],
              [-39.25637417317671, -8.11229741695016],
              [-39.256374192052093, -8.112297429118705],
              [-39.25637421095756, -8.112297441240619],
              [-39.25637422989324, -8.112297453315833],
              [-39.256374248858712, -8.112297465344286],
              [-39.256374267854191, -8.112297477325905],
              [-39.256374286879257, -8.1122974892606],
              [-39.256374305933932, -8.112297501148293],
              [-39.256374325018221, -8.112297512988912],
              [-39.256374344131792, -8.112297524782385],
              [-39.256374363274567, -8.112297536528676],
              [-39.256374382446538, -8.112297548227673],
              [-39.25637440164752, -8.112297559879318],
              [-39.256374420877385, -8.112297571483547],
              [-39.256374440136071, -8.112297583040254],
              [-39.256374459423348, -8.112297594549403],
              [-39.256374478739225, -8.112297606010905],
              [-39.25637449808351, -8.112297617424712],
              [-39.25637451745618, -8.112297628790738],
              [-39.256374536856946, -8.112297640108908],
              [-39.256374556285742, -8.112297651379164],
              [-39.256374575742619, -8.112297662601422],
              [-39.256374595227193, -8.112297673775657],
              [-39.25637461473957, -8.112297684901742],
              [-39.256374634279545, -8.11229769597966],
              [-39.256374653846926, -8.112297707009294],
              [-39.256374673441705, -8.112297717990611],
              [-39.256374693063684, -8.112297728923531],
              [-39.256374712712756, -8.112297739807987],
              [-39.256374732388942, -8.112297750643924],
              [-39.256374752091922, -8.112297761431247],
              [-39.256374771821605, -8.112297772169899],
              [-39.256374791577983, -8.11229778285985],
              [-39.256374811360764, -8.112297793500984],
              [-39.256374831170042, -8.112297804093275],
              [-39.256374851005525, -8.112297814636614],
              [-39.256374870867205, -8.112297825130998],
              [-39.256374890754891, -8.112297835576326],
              [-39.256374910668463, -8.11229784597254],
              [-39.256374930607748, -8.112297856319538],
              [-39.256374950572834, -8.112297866617306],
              [-39.256374970563314, -8.112297876865796],
              [-39.256374990579296, -8.112297887064882],
              [-39.256375010620474, -8.112297897214562],
              [-39.256375030686861, -8.112297907314721],
              [-39.256375050778338, -8.112297917365341],
              [-39.256375049424172, -8.112297960684254],
              [-39.256781552043527, -8.112500594233142],
              [-39.256781733696961, -8.112500775027724],
              [-39.257545816132669, -8.11290715724771],
              [-39.257545997786607, -8.112907338041941],
              [-39.258256890076829, -8.113312576585548],
              [-39.258256866268923, -8.113312572381577],
              [-39.258256912969344, -8.113312599189566],
              [-39.258256959509175, -8.113312626273411],
              [-39.258257005886904, -8.113312653632113],
              [-39.258257052100724, -8.113312681264777],
              [-39.258257098149151, -8.113312709170385],
              [-39.258257144030466, -8.113312737347966],
              [-39.258257189743084, -8.113312765796517],
              [-39.258257235285413, -8.11331279451505],
              [-39.258257280655826, -8.11331282350254],
              [-39.258257325852746, -8.11331285275797],
              [-39.258257370874553, -8.11331288228031],
              [-39.258257415719576, -8.113312912068514],
              [-39.258257460386481, -8.113312942121542],
              [-39.258257504873399, -8.113312972438324],
              [-39.258257549179, -8.113313003017806],
              [-39.258257593301614, -8.113313033858885],
              [-39.258257637239623, -8.113313064960513],
              [-39.258257680991633, -8.113313096321537],
              [-39.258257724555932, -8.113313127940915],
              [-39.258257767931134, -8.113313159817494],
              [-39.258257811115641, -8.113313191950137],
              [-39.258257854107946, -8.113313224337732],
              [-39.258257896906429, -8.11331325697914],
              [-39.258257939509733, -8.113313289873227],
              [-39.258257981916337, -8.113313323018788],
              [-39.258258024124729, -8.113313356414688],
              [-39.258258066133315, -8.113313390059753],
              [-39.258258107940719, -8.113313423952771],
              [-39.258258149545497, -8.113313458092566],
              [-39.258258190946094, -8.113313492477911],
              [-39.258258232141081, -8.113313527107621],
              [-39.258258273128966, -8.113313561980478],
              [-39.258258313908449, -8.113313597095233],
              [-39.258258354477924, -8.113313632450641],
              [-39.258258394836105, -8.113313668045514],
              [-39.258258434981386, -8.113313703878525],
              [-39.258258474912559, -8.11331373994844],
              [-39.258258514628039, -8.113313776253985],
              [-39.25825855412662, -8.113313812793884],
              [-39.25825859340668, -8.113313849566843],
              [-39.258258632466955, -8.113313886571586],
              [-39.258258671306137, -8.113313923806793],
              [-39.258258709922686, -8.113313961271158],
              [-39.258258748315455, -8.113313998963319],
              [-39.25825878648282, -8.113314036881983],
              [-39.258258824423784, -8.113314075025817],
              [-39.258258862136643, -8.113314113393463],
              [-39.258258899620309, -8.113314151983598],
              [-39.258258936873361, -8.113314190794846],
              [-39.258258973894513, -8.113314229825802],
              [-39.258259010682458, -8.113314269075124],
              [-39.258259047235917, -8.113314308541431],
              [-39.258259083553568, -8.113314348223334],
              [-39.258259119634211, -8.113314388119413],
              [-39.258259155476452, -8.113314428228277],
              [-39.258259191079112, -8.113314468548518],
              [-39.258259226440842, -8.1133145090787],
              [-39.258259261560582, -8.113314549817394],
              [-39.258259296436925, -8.113314590763162],
              [-39.258259331068658, -8.113314631914559],
              [-39.258259365454585, -8.113314673270155],
              [-39.258259399593527, -8.113314714828489],
              [-39.258259433484255, -8.113314756588073],
              [-39.258259467125583, -8.113314798547444],
              [-39.25825950051631, -8.113314840705142],
              [-39.258259533655334, -8.113314883059671],
              [-39.258259566541362, -8.113314925609508],
              [-39.258259599173279, -8.11331496835319],
              [-39.25825963154989, -8.113315011289211],
              [-39.258259663670216, -8.113315054416017],
              [-39.25825969553302, -8.113315097732141],
              [-39.25825972713713, -8.113315141236018],
              [-39.258259758481451, -8.113315184926137],
              [-39.258259789564953, -8.113315228800928],
              [-39.258259820386463, -8.11331527285888],
              [-39.258259850944967, -8.113315317098424],
              [-39.258259881239361, -8.113315361517978],
              [-39.25825991126846, -8.113315406116005],
              [-39.258259941031362, -8.113315450890937],
              [-39.258259970527057, -8.113315495841164],
              [-39.258259999754351, -8.113315540965125],
              [-39.258260028712243, -8.113315586261232],
              [-39.258260057399731, -8.113315631727874],
              [-39.25826008581582, -8.113315677363461],
              [-39.258260113959501, -8.11331572316638],
              [-39.258260141829879, -8.113315769135008],
              [-39.258260169425768, -8.113315815267731],
              [-39.258260196746342, -8.113315861562951],
              [-39.258260223790614, -8.113315908018985],
              [-39.258260250557591, -8.113315954634238],
              [-39.258260277046361, -8.113316001407009],
              [-39.25826030325603, -8.113316048335701],
              [-39.258260329185589, -8.113316095418652],
              [-39.258260354834164, -8.113316142654172],
              [-39.258260380200817, -8.113316190040635],
              [-39.25826040528478, -8.113316237576349],
              [-39.258260430084938, -8.113316285259653],
              [-39.258260454600688, -8.113316333088859],
              [-39.258260478830941, -8.113316381062726],
              [-39.258260523279915, -8.113316373372399],
              [-39.258564807539102, -8.113923492012617],
              [-39.258564850663973, -8.113923485542497],
              [-39.258564858960568, -8.113923502048799],
              [-39.258564867290936, -8.113923518538185],
              [-39.25856487565504, -8.113923535010613],
              [-39.258564884052824, -8.113923551465977],
              [-39.258564892484408, -8.113923567904241],
              [-39.258564900949501, -8.113923584325319],
              [-39.258564909448289, -8.113923600729136],
              [-39.258564917980678, -8.113923617115647],
              [-39.258564926546562, -8.113923633484754],
              [-39.258564935145948, -8.113923649836417],
              [-39.258564943778843, -8.113923666170551],
              [-39.258564952445127, -8.113923682487082],
              [-39.25856496114482, -8.113923698785962],
              [-39.258564969877909, -8.113923715067106],
              [-39.258564978644301, -8.113923731330447],
              [-39.258564987443989, -8.113923747575944],
              [-39.258564996276881, -8.113923763803504],
              [-39.258565005143062, -8.11392378001306],
              [-39.258565014042368, -8.113923796204544],
              [-39.25856502297485, -8.113923812377907],
              [-39.258565031940442, -8.113923828533048],
              [-39.258565040939132, -8.113923844669941],
              [-39.258565049970827, -8.113923860788505],
              [-39.258565059035433, -8.113923876888647],
              [-39.258565068133109, -8.11392389297033],
              [-39.258565077263611, -8.113923909033472],
              [-39.258565086426998, -8.113923925078032],
              [-39.258565095623297, -8.113923941103916],
              [-39.258565104852394, -8.113923957111068],
              [-39.258565114114184, -8.113923973099398],
              [-39.258565123408779, -8.113923989068862],
              [-39.258565132735974, -8.11392400501939],
              [-39.258565142095868, -8.113924020950932],
              [-39.258565151488362, -8.11392403686337],
              [-39.25856516091347, -8.113924052756701],
              [-39.258565170371057, -8.113924068630823],
              [-39.258565179861158, -8.113924084485674],
              [-39.258565189383646, -8.113924100321205],
              [-39.258565198938655, -8.113924116137323],
              [-39.258565208525944, -8.113924131933972],
              [-39.258565218145641, -8.113924147711071],
              [-39.25856522779754, -8.113924163468592],
              [-39.25856523748174, -8.113924179206451],
              [-39.258565247198241, -8.113924194924573],
              [-39.258565256946724, -8.113924210622887],
              [-39.25856526672753, -8.113924226301355],
              [-39.258565276540324, -8.113924241959902],
              [-39.258565286385128, -8.113924257598457],
              [-39.25856529626202, -8.113924273216941],
              [-39.25856530617083, -8.11392428881531],
              [-39.258565316111628, -8.113924304393528],
              [-39.258565326084216, -8.113924319951463],
              [-39.258565336088722, -8.113924335489113],
              [-39.258565346125025, -8.11392435100635],
              [-39.258565356193031, -8.113924366503165],
              [-39.258565366292721, -8.113924381979455],
              [-39.258565376424229, -8.113924397435161],
              [-39.258565386587222, -8.113924412870256],
              [-39.25856539678194, -8.11392442828463],
              [-39.258565407008128, -8.11392444367824],
              [-39.258565417265828, -8.113924459051026],
              [-39.258565427555041, -8.113924474402932],
              [-39.258565437875646, -8.113924489733847],
              [-39.258565448227543, -8.113924505043762],
              [-39.25856545861086, -8.113924520332599],
              [-39.258565469025456, -8.113924535600274],
              [-39.258565479471258, -8.113924550846727],
              [-39.25856548994836, -8.113924566071901],
              [-39.258565500456562, -8.113924581275736],
              [-39.258565510995872, -8.113924596458173],
              [-39.258565521566275, -8.113924611619158],
              [-39.25856553216768, -8.11392462675861],
              [-39.258565542800092, -8.113924641876464],
              [-39.258565553463391, -8.113924656972646],
              [-39.258565564157699, -8.113924672047098],
              [-39.258565574882709, -8.113924687099795],
              [-39.258565585638614, -8.113924702130646],
              [-39.258565596425328, -8.113924717139579],
              [-39.25856560724273, -8.113924732126563],
              [-39.258565618090728, -8.113924747091509],
              [-39.258565628969443, -8.113924762034346],
              [-39.258565639878654, -8.113924776955027],
              [-39.258565650818461, -8.113924791853497],
              [-39.258565661788666, -8.113924806729683],
              [-39.258565672789466, -8.113924821583531],
              [-39.258565683820585, -8.113924836414991],
              [-39.258565694881987, -8.113924851223944],
              [-39.258565705973801, -8.113924866010382],
              [-39.25856571709582, -8.113924880774237],
              [-39.258565728248122, -8.113924895515446],
              [-39.258565739430523, -8.113924910233948],
              [-39.258565750643037, -8.113924924929677],
              [-39.258565761885642, -8.113924939602567],
              [-39.258565773158267, -8.113924954252546],
              [-39.25856578446097, -8.113924968879575],
              [-39.258565795793487, -8.113924983483585],
              [-39.25856580715589, -8.113924998064522],
              [-39.258565818548199, -8.113925012622301],
              [-39.258565829970316, -8.113925027156894],
              [-39.25856580698732, -8.113925029105054],
              [-39.259124040330725, -8.114633837786171],
              [-39.259124013728481, -8.114633822588875],
              [-39.259124051194959, -8.114633870494847],
              [-39.259124088337714, -8.114633918650442],
              [-39.259124125155175, -8.114633967053479],
              [-39.259124161645737, -8.114634015701801],
              [-39.259124197807594, -8.114634064593195],
              [-39.259124233639241, -8.114634113725453],
              [-39.259124269139093, -8.114634163096332],
              [-39.259124304305345, -8.114634212703628],
              [-39.259124339136683, -8.114634262545156],
              [-39.25912437363133, -8.114634312618609],
              [-39.259124407787766, -8.114634362921745],
              [-39.259124441604605, -8.114634413452301],
              [-39.259124475080149, -8.114634464208001],
              [-39.259124508212864, -8.114634515186532],
              [-39.259124541001491, -8.114634566385648],
              [-39.259124573444225, -8.114634617802995],
              [-39.259124605539952, -8.11463466943626],
              [-39.259124637286966, -8.11463472128314],
              [-39.259124668683896, -8.114634773341281],
              [-39.259124699729405, -8.114634825608324],
              [-39.259124730422009, -8.11463487808193],
              [-39.259124760760322, -8.114634930759697],
              [-39.25912479074303, -8.114634983639307],
              [-39.259124820368847, -8.114635036718328],
              [-39.259124849636237, -8.114635089994405],
              [-39.259124878544036, -8.114635143465094],
              [-39.259124907090829, -8.114635197128022],
              [-39.259124935275509, -8.114635250980738],
              [-39.259124963096603, -8.114635305020858],
              [-39.259124990552991, -8.11463535924592],
              [-39.259125017643377, -8.114635413653463],
              [-39.259125044366556, -8.114635468241046],
              [-39.259125070721332, -8.114635523006225],
              [-39.259125096706512, -8.114635577946496],
              [-39.259125122320874, -8.11463563305943],
              [-39.259125147563346, -8.114635688342515],
              [-39.259125172432803, -8.114635743793244],
              [-39.259125196927975, -8.11463579940914],
              [-39.259125221047931, -8.114635855187677],
              [-39.259125244791484, -8.114635911126356],
              [-39.259125268157533, -8.114635967222643],
              [-39.259125291145182, -8.114636023474024],
              [-39.259125313753124, -8.114636079877947],
              [-39.259125335980663, -8.114636136431869],
              [-39.259125357826605, -8.114636193133244],
              [-39.259125379289948, -8.114636249979521],
              [-39.259125400369783, -8.11463630696815],
              [-39.259125421065207, -8.114636364096537],
              [-39.25912544137514, -8.114636421362109],
              [-39.259125461298865, -8.114636478762289],
              [-39.25912548083538, -8.114636536294505],
              [-39.259125499983696, -8.114636593956124],
              [-39.259125518743112, -8.1146366517446],
              [-39.259125537112723, -8.114636709657269],
              [-39.259125555091728, -8.114636767691566],
              [-39.259125572679331, -8.114636825844858],
              [-39.259125589874635, -8.114636884114526],
              [-39.259125606676932, -8.114636942497951],
              [-39.259125623085424, -8.114637000992452],
              [-39.259125639099516, -8.114637059595445],
              [-39.259125654718311, -8.114637118304293],
              [-39.259125669941184, -8.114637177116309],
              [-39.259125684767362, -8.114637236028836],
              [-39.259125699196346, -8.114637295039286],
              [-39.259125713227419, -8.114637354144923],
              [-39.259125726859892, -8.114637413343125],
              [-39.259125740093161, -8.114637472631205],
              [-39.259125752926622, -8.114637532006499],
              [-39.259125765359677, -8.114637591466327],
              [-39.259125777391944, -8.114637651008005],
              [-39.25912578902269, -8.114637710628843],
              [-39.259125800251439, -8.11463777032618],
              [-39.259125811077688, -8.114637830097319],
              [-39.259125821500938, -8.114637889939527],
              [-39.259125831520777, -8.114637949850144],
              [-39.259125841136708, -8.114638009826432],
              [-39.259125850348248, -8.114638069865714],
              [-39.259125859155063, -8.114638129965309],
              [-39.259125867556797, -8.114638190122438],
              [-39.259125875552918, -8.114638250334464],
              [-39.259125883143248, -8.114638310598627],
              [-39.259125890327255, -8.114638370912214],
              [-39.259125897104667, -8.114638431272505],
              [-39.259125903475365, -8.114638491676804],
              [-39.259125909438779, -8.114638552122365],
              [-39.259125914994783, -8.114638612606447],
              [-39.259125920143184, -8.114638673126366],
              [-39.259125924883676, -8.114638733679364],
              [-39.259125929216069, -8.114638794262731],
              [-39.259125933140147, -8.114638854873695],
              [-39.259125936655636, -8.114638915509552],
              [-39.259125939762619, -8.114638976167594],
              [-39.259125942460791, -8.114639036845029],
              [-39.259125944749975, -8.114639097539158],
              [-39.259125946630235, -8.11463915824725],
              [-39.259125948101307, -8.114639218966534],
              [-39.259125949163376, -8.114639279694297],
              [-39.259125949816024, -8.114639340427802],
              [-39.259125950059889, -8.114639401163588],
              [-39.259125950087729, -8.114639444750971],
              [-39.259126371470202, -8.115297229677104],
              [-39.259126371497224, -8.11529727191196],
              [-39.259126371509346, -8.115297282012607],
              [-39.259126371532858, -8.115297292113222],
              [-39.259126371567568, -8.115297302213826],
              [-39.259126371613668, -8.115297312314373],
              [-39.259126371671087, -8.115297322414849],
              [-39.259126371739782, -8.115297332515262],
              [-39.259126371819796, -8.115297342615603],
              [-39.259126371911215, -8.115297352715832],
              [-39.259126372013924, -8.115297362815966],
              [-39.259126372127817, -8.115297372915975],
              [-39.259126372253135, -8.115297383015859],
              [-39.259126372389751, -8.115297393115593],
              [-39.259126372537764, -8.115297403215148],
              [-39.259126372696969, -8.115297413314556],
              [-39.259126372867584, -8.11529742341377],
              [-39.25912637304949, -8.115297433512776],
              [-39.259126373242708, -8.115297443611601],
              [-39.259126373447209, -8.115297453710175],
              [-39.25912637366303, -8.115297463808549],
              [-39.25912637389024, -8.115297473906654],
              [-39.259126374128655, -8.1152974840045],
              [-39.259126374378454, -8.115297494102085],
              [-39.259126374639571, -8.11529750419937],
              [-39.259126374911986, -8.115297514296367],
              [-39.259126375195692, -8.115297524393052],
              [-39.259126375490702, -8.115297534489409],
              [-39.259126375797116, -8.115297544585424],
              [-39.259126376114828, -8.115297554681122],
              [-39.259126376443731, -8.115297564776455],
              [-39.259126376784046, -8.115297574871407],
              [-39.259126377135658, -8.115297584965978],
              [-39.259126377498575, -8.115297595060129],
              [-39.259126377872789, -8.11529760515389],
              [-39.259126378258394, -8.11529761524722],
              [-39.259126378655203, -8.115297625340117],
              [-39.259126379063424, -8.115297635432571],
              [-39.259126379482829, -8.115297645524553],
              [-39.259126379913646, -8.115297655616072],
              [-39.25912638035566, -8.115297665707105],
              [-39.259126380809072, -8.115297675797645],
              [-39.259126381273781, -8.115297685887667],
              [-39.259126381749795, -8.115297695977164],
              [-39.25912638223712, -8.115297706066125],
              [-39.259126382735715, -8.115297716154551],
              [-39.259126383245636, -8.115297726242394],
              [-39.259126383766855, -8.11529773632968],
              [-39.259126384299371, -8.115297746416386],
              [-39.259126384843185, -8.115297756502471],
              [-39.259126385398297, -8.115297766587965],
              [-39.259126385964812, -8.11529777667282],
              [-39.259126386542526, -8.115297786757036],
              [-39.259126387131538, -8.11529779684062],
              [-39.259126387731861, -8.115297806923527],
              [-39.259126388343468, -8.115297817005761],
              [-39.259126388966379, -8.115297827087327],
              [-39.259126389600596, -8.115297837168162],
              [-39.259126390246109, -8.115297847248303],
              [-39.259126390902914, -8.115297857327707],
              [-39.25912639157103, -8.115297867406374],
              [-39.259126392250451, -8.11529787748432],
              [-39.25912639294117, -8.115297887561471],
              [-39.259126393643079, -8.11529789763788],
              [-39.259126394356393, -8.115297907713474],
              [-39.259126395080919, -8.115297917788277],
              [-39.259126395816821, -8.115297927862292],
              [-39.259126396563943, -8.115297937935457],
              [-39.259126397322362, -8.115297948007781],
              [-39.259126398092071, -8.115297958079266],
              [-39.259126398873079, -8.115297968149871],
              [-39.259126399665298, -8.115297978219639],
              [-39.259126400468922, -8.115297988288493],
              [-39.259126401283737, -8.115297998356434],
              [-39.259126402109857, -8.11529800842346],
              [-39.259126402947267, -8.115298018489574],
              [-39.259126403795996, -8.115298028554745],
              [-39.259126404655902, -8.11529803861896],
              [-39.259126405527212, -8.115298048682217],
              [-39.259126406409635, -8.115298058744491],
              [-39.259126407303455, -8.115298068805796],
              [-39.259126408208573, -8.115298078866113],
              [-39.259126409124896, -8.115298088925387],
              [-39.259126410052502, -8.115298098983649],
              [-39.259126410991421, -8.115298109040863],
              [-39.259126411941544, -8.115298119097041],
              [-39.259126412902951, -8.115298129152144],
              [-39.259126413875677, -8.115298139206166],
              [-39.259126414859594, -8.115298149259111],
              [-39.259126415854816, -8.115298159310949],
              [-39.259126416861228, -8.115298169361671],
              [-39.259126417879045, -8.115298179411276],
              [-39.259126418907954, -8.11529818945974],
              [-39.259126419948281, -8.115298199507034],
              [-39.259126420999813, -8.115298209553181],
              [-39.259126422062515, -8.115298219598133],
              [-39.259126423136529, -8.115298229641917],
              [-39.259126424221847, -8.11529823968449],
              [-39.259126425318371, -8.115298249725864],
              [-39.259126426426185, -8.115298259766007],
              [-39.259126427545205, -8.115298269804873],
              [-39.259126462877461, -8.115298224618543],
              [-39.259177586488732, -8.115754625532414],
              [-39.259177559169125, -8.115754650700396],
              [-39.259177561532475, -8.115754672023453],
              [-39.259177563845, -8.115754693352041],
              [-39.259177566106736, -8.115754714686018],
              [-39.259177568317789, -8.115754736025307],
              [-39.259177570477924, -8.115754757369745],
              [-39.259177572587348, -8.115754778719241],
              [-39.259177574645896, -8.11575480007366],
              [-39.259177576653627, -8.115754821432885],
              [-39.259177578610561, -8.115754842796786],
              [-39.259177580516599, -8.115754864165266],
              [-39.259177582371841, -8.115754885538184],
              [-39.259177584176179, -8.115754906915411],
              [-39.259177585929599, -8.11575492829687],
              [-39.259177587632145, -8.115754949682398],
              [-39.25917758928388, -8.115754971071905],
              [-39.259177590884605, -8.115754992465236],
              [-39.25917759243444, -8.115755013862293],
              [-39.259177593933273, -8.115755035262941],
              [-39.25917759538131, -8.115755056667078],
              [-39.259177596778329, -8.115755078074589],
              [-39.259177598124374, -8.115755099485339],
              [-39.259177599419488, -8.11575512089922],
              [-39.259177600663627, -8.115755142316097],
              [-39.259177601856756, -8.115755163735836],
              [-39.259177602998982, -8.115755185158354],
              [-39.259177604090105, -8.115755206583495],
              [-39.25917760513034, -8.115755228011174],
              [-39.259177606119572, -8.115755249441225],
              [-39.259177607057786, -8.115755270873562],
              [-39.259177607944928, -8.115755292308073],
              [-39.259177608781144, -8.115755313744591],
              [-39.259177609566272, -8.115755335183062],
              [-39.259177610300398, -8.11575535662331],
              [-39.259177610983521, -8.115755378065241],
              [-39.259177611615549, -8.115755399508723],
              [-39.259177612196574, -8.115755420953619],
              [-39.259177612726589, -8.115755442399839],
              [-39.259177613205523, -8.11575546384724],
              [-39.25917761363344, -8.115755485295749],
              [-39.259177614010362, -8.115755506745179],
              [-39.259177614336089, -8.115755528195461],
              [-39.259177614610905, -8.11575554964646],
              [-39.259177614834627, -8.115755571098012],
              [-39.259177615007239, -8.115755592550061],
              [-39.259177615128863, -8.11575561400244],
              [-39.259177615199384, -8.11575563545505],
              [-39.25917761521891, -8.115755656907773],
              [-39.259177615187326, -8.115755678360475],
              [-39.259177615104626, -8.115755699813041],
              [-39.259177614970959, -8.115755721265344],
              [-39.259177614786275, -8.115755742717285],
              [-39.259177614550495, -8.115755764168703],
              [-39.259177614263606, -8.115755785619539],
              [-39.259177613925729, -8.115755807069606],
              [-39.25917761353675, -8.115755828518825],
              [-39.259177613096767, -8.115755849967078],
              [-39.259177612605768, -8.115755871414208],
              [-39.259177612063688, -8.115755892860138],
              [-39.259177611470598, -8.115755914304732],
              [-39.259177610826413, -8.115755935747853],
              [-39.259177610131225, -8.115755957189403],
              [-39.259177609385041, -8.115755978629224],
              [-39.259177608587848, -8.115756000067256],
              [-39.259177607739559, -8.115756021503307],
              [-39.259177606840375, -8.115756042937319],
              [-39.259177605890088, -8.115756064369146],
              [-39.259177604888798, -8.11575608579866],
              [-39.259177603836513, -8.115756107225728],
              [-39.259177602733203, -8.115756128650272],
              [-39.259177601579026, -8.115756150072132],
              [-39.259177600373725, -8.115756171491215],
              [-39.259177599117542, -8.115756192907394],
              [-39.25917759781035, -8.115756214320536],
              [-39.259177596452261, -8.115756235730526],
              [-39.259177595043163, -8.115756257137251],
              [-39.259177593583168, -8.115756278540571],
              [-39.259177592072177, -8.115756299940402],
              [-39.25917759051039, -8.115756321336585],
              [-39.259177588897586, -8.115756342729025],
              [-39.259177587233793, -8.115756364117594],
              [-39.259177585519289, -8.115756385502165],
              [-39.259177583753797, -8.11575640688263],
              [-39.259177581937401, -8.115756428258861],
              [-39.259177580070109, -8.115756449630741],
              [-39.259177578152006, -8.115756470998134],
              [-39.259177576183113, -8.115756492360944],
              [-39.259177574163317, -8.115756513719056],
              [-39.25917757209271, -8.115756535072309],
              [-39.259177569971321, -8.115756556420608],
              [-39.259177567799114, -8.115756577763841],
              [-39.25917756557611, -8.115756599101882],
              [-39.259177563302309, -8.115756620434603],
              [-39.259177560977705, -8.115756641761893],
              [-39.25917755860241, -8.115756663083637],
              [-39.259177556176411, -8.115756684399706],
              [-39.259177553699608, -8.11575670570998],
              [-39.259177551172108, -8.115756727014331],
              [-39.259177548593996, -8.115756748312645],
              [-39.259177545965095, -8.115756769605566],
              [-39.259177587879883, -8.11575679643965],
              [-39.259127197101186, -8.116160888777745],
              [-39.259127161666491, -8.11616089829773],
              [-39.259127157053484, -8.116160935943405],
              [-39.259127152598879, -8.116160973608],
              [-39.25912714830276, -8.116161011290897],
              [-39.259127144165276, -8.116161048991401],
              [-39.259127140186372, -8.116161086708857],
              [-39.259127136366274, -8.116161124442614],
              [-39.259127132704876, -8.116161162192009],
              [-39.259127129202369, -8.116161199956343],
              [-39.259127125858882, -8.116161237734989],
              [-39.259127122674279, -8.116161275527286],
              [-39.259127119648689, -8.11616131333254],
              [-39.259127116782302, -8.116161351150105],
              [-39.259127114074907, -8.11616138897933],
              [-39.259127111526809, -8.116161426819536],
              [-39.259127109137935, -8.116161464670032],
              [-39.259127106908245, -8.116161502530177],
              [-39.259127104837972, -8.116161540399311],
              [-39.259127102926982, -8.116161578276776],
              [-39.259127101175402, -8.116161616161889],
              [-39.259127099583218, -8.116161654053986],
              [-39.259127098150437, -8.11616169195239],
              [-39.259127096877165, -8.116161729856463],
              [-39.259127095763397, -8.116161767765533],
              [-39.259127094809124, -8.116161805678892],
              [-39.259127094014339, -8.116161843595915],
              [-39.259127093379071, -8.116161881515941],
              [-39.259127092903398, -8.116161919438277],
              [-39.25912709258732, -8.116161957362261],
              [-39.259127092430766, -8.116161995287234],
              [-39.259127092433793, -8.116162033212527],
              [-39.25912709259633, -8.116162071137472],
              [-39.259127092918469, -8.116162109061436],
              [-39.25912709340021, -8.116162146983685],
              [-39.259127094041446, -8.116162184903581],
              [-39.259127094842185, -8.116162222820492],
              [-39.259127095802526, -8.116162260733716],
              [-39.259127096922377, -8.116162298642605],
              [-39.25912709820161, -8.116162336546461],
              [-39.259127099640459, -8.116162374444661],
              [-39.259127101238604, -8.116162412336497],
              [-39.259127102996253, -8.116162450221335],
              [-39.259127104913198, -8.116162488098478],
              [-39.259127106989553, -8.116162525967278],
              [-39.259127109225211, -8.116162563827091],
              [-39.25912711162016, -8.116162601677233],
              [-39.259127114174319, -8.116162639517015],
              [-39.259127116887683, -8.116162677345804],
              [-39.259127119760144, -8.116162715162913],
              [-39.259127122791696, -8.116162752967712],
              [-39.259127125982261, -8.116162790759482],
              [-39.25912712933183, -8.116162828537613],
              [-39.259127132840284, -8.116162866301401],
              [-39.259127136507651, -8.116162904050192],
              [-39.25912714033381, -8.116162941783351],
              [-39.25912714431869, -8.11616297950018],
              [-39.259127148462149, -8.116163017200025],
              [-39.259127152764229, -8.116163054882236],
              [-39.259127157224803, -8.116163092546154],
              [-39.259127161843871, -8.116163130191072],
              [-39.259127166621248, -8.11616316781638],
              [-39.25912717155682, -8.116163205421394],
              [-39.259127176650601, -8.116163243005451],
              [-39.259127181902571, -8.116163280567896],
              [-39.259127187312451, -8.116163318108054],
              [-39.259127192880143, -8.116163355625313],
              [-39.259127198605718, -8.116163393118965],
              [-39.259127204489005, -8.116163430588371],
              [-39.25912721052989, -8.116163468032866],
              [-39.259127216728281, -8.11616350545177],
              [-39.259127223083965, -8.116163542844454],
              [-39.259127229597055, -8.116163580210259],
              [-39.259127236267148, -8.116163617548541],
              [-39.259127243094433, -8.116163654858594],
              [-39.259127250078535, -8.116163692139828],
              [-39.259127257219433, -8.116163729391529],
              [-39.259127264517019, -8.11616376661307],
              [-39.25912727197111, -8.116163803803781],
              [-39.259127279581726, -8.116163840963024],
              [-39.259127287348512, -8.116163878090163],
              [-39.259127295271412, -8.116163915184526],
              [-39.259127303350425, -8.116163952245424],
              [-39.259127311585225, -8.116163989272264],
              [-39.259127319975747, -8.116164026264363],
              [-39.259127328521835, -8.116164063221101],
              [-39.259127337223347, -8.116164100141779],
              [-39.259127346080056, -8.116164137025789],
              [-39.259127355091969, -8.116164173872487],
              [-39.259127364258788, -8.116164210681184],
              [-39.259127373580391, -8.116164247451248],
              [-39.259127383056608, -8.116164284182057],
              [-39.259127392687333, -8.116164320872954],
              [-39.259127402472238, -8.116164357523308],
              [-39.25912741241136, -8.116164394132433],
              [-39.259127422504385, -8.116164430699715],
              [-39.2591274327512, -8.116164467224513],
              [-39.259127443151527, -8.116164503706177],
              [-39.259127453705354, -8.11616454014405],
              [-39.259127464412366, -8.116164576537525],
              [-39.259127475272408, -8.116164612885921],
              [-39.259127471787558, -8.116164597237612],
              [-39.259279472300804, -8.116669417528634],
              [-39.259279452074615, -8.116669405770873],
              [-39.259279465185067, -8.11666944892611],
              [-39.259279478511125, -8.116669492015694],
              [-39.25927949205267, -8.116669535038545],
              [-39.25927950580914, -8.11666957799358],
              [-39.259279519780392, -8.116669620879772],
              [-39.259279533965945, -8.116669663696008],
              [-39.259279548365519, -8.116669706441266],
              [-39.259279562978776, -8.116669749114442],
              [-39.259279577805245, -8.116669791714516],
              [-39.259279592844614, -8.116669834240405],
              [-39.259279608096577, -8.116669876691052],
              [-39.259279623560658, -8.116669919065421],
              [-39.259279639236524, -8.116669961362431],
              [-39.259279655123805, -8.116670003581062],
              [-39.259279671221968, -8.116670045720248],
              [-39.259279687530864, -8.116670087778935],
              [-39.259279704049838, -8.116670129756095],
              [-39.259279720778615, -8.116670171650664],
              [-39.259279737716703, -8.116670213461619],
              [-39.25927975486379, -8.116670255187946],
              [-39.259279772219372, -8.116670296828564],
              [-39.259279789782958, -8.116670338382443],
              [-39.25927980755425, -8.116670379848536],
              [-39.259279825532744, -8.116670421225866],
              [-39.259279843717941, -8.116670462513349],
              [-39.259279862109537, -8.116670503710015],
              [-39.259279880706934, -8.116670544814784],
              [-39.259279899509636, -8.116670585826665],
              [-39.259279918517343, -8.116670626744654],
              [-39.259279937729431, -8.116670667567709],
              [-39.259279957145537, -8.116670708294826],
              [-39.259279976765143, -8.116670748925007],
              [-39.259279996587665, -8.116670789457203],
              [-39.259280016612756, -8.116670829890408],
              [-39.259280036839769, -8.116670870223663],
              [-39.259280057268384, -8.116670910455944],
              [-39.259280077897898, -8.116670950586219],
              [-39.25928009872802, -8.116670990613525],
              [-39.259280119758039, -8.116671030536885],
              [-39.25928014098745, -8.116671070355265],
              [-39.25928016241577, -8.116671110067722],
              [-39.259280184042602, -8.116671149673209],
              [-39.259280205867121, -8.116671189170775],
              [-39.25928022788905, -8.116671228559476],
              [-39.259280250107672, -8.116671267838287],
              [-39.259280272522609, -8.116671307006211],
              [-39.259280295133024, -8.116671346062317],
              [-39.259280317938654, -8.116671385005622],
              [-39.259280340938695, -8.116671423835173],
              [-39.259280364132721, -8.116671462549972],
              [-39.259280387520164, -8.116671501149071],
              [-39.259280411100399, -8.116671539631545],
              [-39.25928043487275, -8.116671577996382],
              [-39.259280458836784, -8.116671616242668],
              [-39.259280482991826, -8.116671654369451],
              [-39.25928050733728, -8.116671692375752],
              [-39.25928053187252, -8.116671730260677],
              [-39.25928055659697, -8.116671768023215],
              [-39.259280581510019, -8.116671805662474],
              [-39.259280606611071, -8.116671843177524],
              [-39.259280631899422, -8.116671880567431],
              [-39.259280657374475, -8.116671917831225],
              [-39.259280683035627, -8.116671954968034],
              [-39.259280708882287, -8.116671991976887],
              [-39.259280734913645, -8.116672028856922],
              [-39.259280761129197, -8.116672065607162],
              [-39.259280787528262, -8.116672102226724],
              [-39.259280814110227, -8.116672138714703],
              [-39.25928084087429, -8.1166721750702],
              [-39.25928086781996, -8.116672211292277],
              [-39.259280894946421, -8.116672247380043],
              [-39.259280922253083, -8.116672283332628],
              [-39.259280949739264, -8.116672319149131],
              [-39.259280977404231, -8.116672354828625],
              [-39.259281005247402, -8.116672390370281],
              [-39.259281033267868, -8.116672425773206],
              [-39.259281061465252, -8.116672461036464],
              [-39.259281089838524, -8.116672496159239],
              [-39.259281118387207, -8.116672531140617],
              [-39.259281147110485, -8.116672565979743],
              [-39.259281176007775, -8.116672600675765],
              [-39.259281205078139, -8.116672635227818],
              [-39.25928123432103, -8.116672669635038],
              [-39.259281263735616, -8.116672703896564],
              [-39.259281293321301, -8.116672738011562],
              [-39.259281323077182, -8.116672771979147],
              [-39.25928135300267, -8.116672805798512],
              [-39.259281383096976, -8.116672839468801],
              [-39.259281413359261, -8.116672872989184],
              [-39.25928144378895, -8.116672906358815],
              [-39.259281474385041, -8.116672939576885],
              [-39.259281505147037, -8.116672972642567],
              [-39.259281536073935, -8.116673005555016],
              [-39.259281567165132, -8.116673038313436],
              [-39.259281598419832, -8.11667307091701],
              [-39.259281629837233, -8.116673103364935],
              [-39.259281661416537, -8.11667313565637],
              [-39.259281693157043, -8.116673167790541],
              [-39.25928172505774, -8.116673199766641],
              [-39.259281743991423, -8.116673215171362],
              [-39.25953415603702, -8.116924970167144],
              [-39.259534184216086, -8.116924996533987],
              [-39.259534200100944, -8.116925012338001],
              [-39.259534216024896, -8.116925028102825],
              [-39.259534231988049, -8.116925043828358],
              [-39.259534247990089, -8.116925059514513],
              [-39.259534264031053, -8.116925075161197],
              [-39.259534280110813, -8.116925090768277],
              [-39.259534296229255, -8.116925106335687],
              [-39.259534312386208, -8.116925121863343],
              [-39.259534328581672, -8.116925137351144],
              [-39.259534344815513, -8.116925152798974],
              [-39.259534361087674, -8.116925168206734],
              [-39.259534377398019, -8.116925183574379],
              [-39.259534393746378, -8.116925198901777],
              [-39.259534410132837, -8.116925214188848],
              [-39.259534426557089, -8.116925229435484],
              [-39.259534443019156, -8.116925244641605],
              [-39.25953445951891, -8.116925259807093],
              [-39.259534476056274, -8.116925274931893],
              [-39.259534492631019, -8.116925290015892],
              [-39.259534509243274, -8.11692530505899],
              [-39.259534525892732, -8.116925320061098],
              [-39.259534542579388, -8.116925335022154],
              [-39.259534559303148, -8.116925349942029],
              [-39.259534576063807, -8.116925364820643],
              [-39.259534592861371, -8.116925379657912],
              [-39.25953460969572, -8.116925394453755],
              [-39.259534626566676, -8.116925409208052],
              [-39.259534643474339, -8.116925423920746],
              [-39.259534660418296, -8.116925438591744],
              [-39.259534677398669, -8.116925453220896],
              [-39.259534694415322, -8.11692546780818],
              [-39.259534711468085, -8.116925482353478],
              [-39.259534728556943, -8.116925496856709],
              [-39.259534745681698, -8.116925511317808],
              [-39.259534762842257, -8.116925525736624],
              [-39.259534780038528, -8.116925540113117],
              [-39.25953479727049, -8.116925554447219],
              [-39.259534814537943, -8.11692556873879],
              [-39.25953483184081, -8.11692558298777],
              [-39.259534849178976, -8.116925597194053],
              [-39.259534866552329, -8.116925611357594],
              [-39.259534883960804, -8.116925625478265],
              [-39.259534901404265, -8.116925639555991],
              [-39.259534918882629, -8.116925653590704],
              [-39.259534936395788, -8.116925667582269],
              [-39.259534953943557, -8.116925681530669],
              [-39.259534971525817, -8.116925695435768],
              [-39.259534989142679, -8.116925709297497],
              [-39.259535006793762, -8.116925723115774],
              [-39.259535024479113, -8.116925736890522],
              [-39.259535042198685, -8.116925750621631],
              [-39.259535059952142, -8.116925764309038],
              [-39.259535077739613, -8.116925777952652],
              [-39.259535095560778, -8.116925791552397],
              [-39.259535113415737, -8.116925805108185],
              [-39.259535131304204, -8.116925818619926],
              [-39.259535149226281, -8.116925832087537],
              [-39.259535167181546, -8.11692584551095],
              [-39.259535185170208, -8.116925858890072],
              [-39.259535203191973, -8.116925872224828],
              [-39.25953522124675, -8.116925885515123],
              [-39.259535239334419, -8.116925898760902],
              [-39.259535257454985, -8.116925911962065],
              [-39.259535275608158, -8.11692592511853],
              [-39.259535293794023, -8.116925938230199],
              [-39.259535312012297, -8.11692595129704],
              [-39.259535330262956, -8.116925964318956],
              [-39.259535348545924, -8.116925977295836],
              [-39.259535366860995, -8.116925990227614],
              [-39.259535385208075, -8.116926003114244],
              [-39.259535403587137, -8.116926015955585],
              [-39.259535421998017, -8.116926028751623],
              [-39.259535440440487, -8.116926041502232],
              [-39.259535458914648, -8.116926054207363],
              [-39.259535477420229, -8.116926066866951],
              [-39.259535495957195, -8.116926079480876],
              [-39.259535514525368, -8.116926092049084],
              [-39.25953553312474, -8.116926104571512],
              [-39.259535551755107, -8.11692611704806],
              [-39.259535570416283, -8.116926129478658],
              [-39.259535589108353, -8.116926141863214],
              [-39.259535607831133, -8.116926154201701],
              [-39.259535626584402, -8.116926166493995],
              [-39.259535645368068, -8.116926178740028],
              [-39.259535664182138, -8.116926190939743],
              [-39.259535683026414, -8.116926203093049],
              [-39.259535701900809, -8.1169262151999],
              [-39.259535720805161, -8.116926227260183],
              [-39.259535739739334, -8.116926239273878],
              [-39.259535758703322, -8.116926251240841],
              [-39.259535777696989, -8.116926263161034],
              [-39.259535796720158, -8.116926275034384],
              [-39.259535815772637, -8.116926286860856],
              [-39.259535834854518, -8.11692629864033],
              [-39.259535853965488, -8.11692631037273],
              [-39.25953587310557, -8.11692632205801],
              [-39.259535892274556, -8.116926333696076],
              [-39.259535911472426, -8.116926345286878],
              [-39.259535930699002, -8.11692635683033],
              [-39.259535972312833, -8.116926325827068],
              [-39.260044209308099, -8.117230652335641],
              [-39.260044754162728, -8.117231013805675],
              [-39.260653383427695, -8.117534461310745],
              [-39.260653355536434, -8.117534444395156],
              [-39.260653405745089, -8.117534469625477],
              [-39.260653455796046, -8.117534495165259],
              [-39.260653505687202, -8.117534521013534],
              [-39.260653555416859, -8.117534547169308],
              [-39.260653604982807, -8.117534573631552],
              [-39.260653654383354, -8.117534600399297],
              [-39.260653703616512, -8.11753462747151],
              [-39.260653752680469, -8.117534654847105],
              [-39.260653801573298, -8.117534682525072],
              [-39.260653850293153, -8.117534710504332],
              [-39.260653898838193, -8.117534738783807],
              [-39.260653947206336, -8.117534767362425],
              [-39.260653995396083, -8.117534796239092],
              [-39.260654043405218, -8.117534825412687],
              [-39.260654091232155, -8.117534854882106],
              [-39.260654138875005, -8.117534884646188],
              [-39.260654186331934, -8.11753491470385],
              [-39.260654233600967, -8.117534945053876],
              [-39.260654280680612, -8.117534975695124],
              [-39.260654327568744, -8.11753500662644],
              [-39.260654374263673, -8.117535037846585],
              [-39.260654420763707, -8.11753506935441],
              [-39.260654467066921, -8.117535101148686],
              [-39.260654513171652, -8.117535133228182],
              [-39.260654559076087, -8.117535165591699],
              [-39.260654604778409, -8.117535198237952],
              [-39.260654650276933, -8.117535231165737],
              [-39.260654695569855, -8.117535264373775],
              [-39.260654740655468, -8.117535297860766],
              [-39.26065478553209, -8.11753533162546],
              [-39.2606548301979, -8.117535365666495],
              [-39.260654874651216, -8.117535399982643],
              [-39.260654918890431, -8.117535434572551],
              [-39.260654962913748, -8.117535469434912],
              [-39.260655006719468, -8.117535504568353],
              [-39.260655050305964, -8.117535539971563],
              [-39.260655093671573, -8.117535575643151],
              [-39.260655136814577, -8.11753561158176],
              [-39.260655179733384, -8.117535647786005],
              [-39.26065522242618, -8.117535684254511],
              [-39.260655264891582, -8.117535720985877],
              [-39.260655307127685, -8.117535757978677],
              [-39.260655349133181, -8.117535795231527],
              [-39.260655390906173, -8.117535832742973],
              [-39.260655432445162, -8.117535870511558],
              [-39.260655473748649, -8.117535908535844],
              [-39.260655514814943, -8.117535946814405],
              [-39.26065555564243, -8.117535985345748],
              [-39.260655596229711, -8.117536024128372],
              [-39.260655636575009, -8.117536063160816],
              [-39.260655676676983, -8.117536102441566],
              [-39.260655716533961, -8.117536141969126],
              [-39.26065575614443, -8.11753618174196],
              [-39.260655795506914, -8.117536221758565],
              [-39.260655834619897, -8.117536262017399],
              [-39.260655873481753, -8.117536302516937],
              [-39.260655912091224, -8.11753634325559],
              [-39.260655950446697, -8.117536384231812],
              [-39.26065598854666, -8.117536425444014],
              [-39.260656026389732, -8.117536466890609],
              [-39.260656063974395, -8.117536508570049],
              [-39.260656101299254, -8.11753655048072],
              [-39.260656138362812, -8.117536592620993],
              [-39.26065617516376, -8.117536634989259],
              [-39.260656211700621, -8.117536677583901],
              [-39.260656247971973, -8.117536720403271],
              [-39.260656283976417, -8.117536763445734],
              [-39.260656319712574, -8.117536806709646],
              [-39.260656355179208, -8.117536850193328],
              [-39.260656390374763, -8.117536893895108],
              [-39.260656425297995, -8.117536937813341],
              [-39.260656459947533, -8.11753698194633],
              [-39.260656494321978, -8.117537026292354],
              [-39.260656528420206, -8.117537070849746],
              [-39.260656562240747, -8.117537115616791],
              [-39.260656595782365, -8.117537160591748],
              [-39.260656629043709, -8.117537205772894],
              [-39.260656662023528, -8.117537251158515],
              [-39.260656694720652, -8.117537296746859],
              [-39.260656727133664, -8.117537342536174],
              [-39.260656759261487, -8.117537388524701],
              [-39.260656791102797, -8.117537434710698],
              [-39.260656822656323, -8.117537481092358],
              [-39.260656853921041, -8.117537527667917],
              [-39.260656884895447, -8.117537574435589],
              [-39.26065691557865, -8.117537621393582],
              [-39.260656945969359, -8.117537668540088],
              [-39.260656976066244, -8.117537715873306],
              [-39.260657005868453, -8.117537763391402],
              [-39.260657035374663, -8.117537811092557],
              [-39.260657064583746, -8.117537858974968],
              [-39.260657093494636, -8.117537907036761],
              [-39.260657122106231, -8.117537955276127],
              [-39.260657150417323, -8.117538003691173],
              [-39.26065717842701, -8.117538052280063],
              [-39.260657206133985, -8.117538101040946],
              [-39.260657233537366, -8.117538149971924],
              [-39.260657260636044, -8.11753819907114],
              [-39.260657287429012, -8.117538248336245],
              [-39.260657289003042, -8.117538257899229],
              [-39.261011899161062, -8.118195092121242],
              [-39.261011871334425, -8.118195087610921],
              [-39.261011891620733, -8.118195125403938],
              [-39.261011911727138, -8.118195163292333],
              [-39.261011931653151, -8.118195201275261],
              [-39.261011951398253, -8.118195239351818],
              [-39.261011970962151, -8.118195277521185],
              [-39.261011990344343, -8.118195315782517],
              [-39.261012009544359, -8.118195354134933],
              [-39.261012028561851, -8.118195392577542],
              [-39.26101204739625, -8.118195431109546],
              [-39.26101206604735, -8.118195469730036],
              [-39.26101208451454, -8.118195508438172],
              [-39.26101210279743, -8.118195547233075],
              [-39.261012120895714, -8.11819558611386],
              [-39.261012138808908, -8.118195625079686],
              [-39.261012156536594, -8.118195664129642],
              [-39.261012174078395, -8.118195703262858],
              [-39.261012191433977, -8.118195742478457],
              [-39.261012208602857, -8.118195781775585],
              [-39.261012225584729, -8.118195821153325],
              [-39.261012242379216, -8.118195860610795],
              [-39.261012258985794, -8.118195900147144],
              [-39.261012275404269, -8.118195939761465],
              [-39.261012291634152, -8.11819597945286],
              [-39.261012307675124, -8.118196019220441],
              [-39.261012323526892, -8.118196059063321],
              [-39.261012339188973, -8.118196098980626],
              [-39.26101235466114, -8.118196138971408],
              [-39.261012369942904, -8.118196179034793],
              [-39.261012385034071, -8.118196219169889],
              [-39.261012399934231, -8.118196259375788],
              [-39.261012414643005, -8.118196299651606],
              [-39.261012429160161, -8.118196339996413],
              [-39.261012443485221, -8.118196380409323],
              [-39.261012457618065, -8.118196420889406],
              [-39.261012471558239, -8.118196461435774],
              [-39.261012485305486, -8.118196502047498],
              [-39.261012498859436, -8.11819654272368],
              [-39.261012512219786, -8.118196583463414],
              [-39.261012525386334, -8.118196624265767],
              [-39.261012538358791, -8.11819666512984],
              [-39.26101255113673, -8.118196706054716],
              [-39.261012563719881, -8.118196747039459],
              [-39.261012576108122, -8.118196788083168],
              [-39.261012588300972, -8.118196829184901],
              [-39.261012600298308, -8.118196870343739],
              [-39.261012612099748, -8.118196911558769],
              [-39.261012623705192, -8.118196952829067],
              [-39.26101263511422, -8.118196994153678],
              [-39.261012646326549, -8.118197035531704],
              [-39.261012657342079, -8.118197076962215],
              [-39.261012668160518, -8.118197118444277],
              [-39.261012678781547, -8.118197159976956],
              [-39.261012689204975, -8.118197201559315],
              [-39.261012699430594, -8.118197243190421],
              [-39.261012709458115, -8.118197284869364],
              [-39.261012719287343, -8.118197326595187],
              [-39.261012728918153, -8.118197368366944],
              [-39.261012738350182, -8.118197410183697],
              [-39.261012747583187, -8.118197452044502],
              [-39.261012756617113, -8.118197493948465],
              [-39.261012765451724, -8.118197535894591],
              [-39.261012774086737, -8.118197577881949],
              [-39.261012782522052, -8.118197619909637],
              [-39.261012790757455, -8.118197661976653],
              [-39.261012798792656, -8.118197704082084],
              [-39.261012806627562, -8.118197746225007],
              [-39.261012814262081, -8.11819778840445],
              [-39.261012821695964, -8.118197830619467],
              [-39.261012828928976, -8.118197872869118],
              [-39.261012835960969, -8.118197915152438],
              [-39.261012842791978, -8.118197957468489],
              [-39.261012849421562, -8.118197999816337],
              [-39.261012855849856, -8.118198042194988],
              [-39.261012862076463, -8.118198084603529],
              [-39.261012868101339, -8.118198127040994],
              [-39.261012873924422, -8.118198169506416],
              [-39.261012879545518, -8.118198211998857],
              [-39.261012884964494, -8.11819825451739],
              [-39.261012890181284, -8.118198297061022],
              [-39.261012895195655, -8.118198339628798],
              [-39.261012900007636, -8.118198382219783],
              [-39.261012904617012, -8.118198424833016],
              [-39.261012909023691, -8.11819846746752],
              [-39.261012913227567, -8.118198510122367],
              [-39.261012917228641, -8.118198552796564],
              [-39.261012921026811, -8.118198595489188],
              [-39.261012924621873, -8.118198638199274],
              [-39.261012928013855, -8.118198680925834],
              [-39.261012931202622, -8.118198723667938],
              [-39.261012934188066, -8.118198766424603],
              [-39.261012936970239, -8.118198809194924],
              [-39.261012939549012, -8.118198851977882],
              [-39.26101294192425, -8.118198894772537],
              [-39.261012944096116, -8.118198937577915],
              [-39.26101294606427, -8.118198980393085],
              [-39.261012947828924, -8.118199023217066],
              [-39.261012949389979, -8.118199066048881],
              [-39.261012950747229, -8.118199108887612],
              [-39.26101295190027, -8.118199151734901],
              [-39.261012991035017, -8.118199161873003],
              [-39.261027068669861, -8.118774262437798],
              [-39.238426800839271, -8.12142460567576],
              [-39.239378133721466, -8.124877061343682],
              [-39.239482354289407, -8.125053929198231],
              [-39.23951289885504, -8.125129983531469],
              [-39.239579902381202, -8.125305968718889],
              [-39.239679905476677, -8.125564790857529],
              [-39.239809155241311, -8.1258536266484],
              [-39.239955847209636, -8.126165518020002],
              [-39.240078115254818, -8.126466750102489],
              [-39.240214544066681, -8.126768064230475],
              [-39.240358787503446, -8.127082760962443],
              [-39.240498667292457, -8.127386062935372],
              [-39.240618470771125, -8.127662963991561],
              [-39.240728655784828, -8.127945388426495],
              [-39.240840689305173, -8.128130753971954],
              [-39.241023120655171, -8.128397667788358],
              [-39.241218900367414, -8.128672081324023],
              [-39.241369720747919, -8.128901475068922],
              [-39.24150775781947, -8.129156836802757],
              [-39.241648839738858, -8.129494329414261],
              [-39.241736547594222, -8.129832486882284],
              [-39.241798924476221, -8.13016170438563],
              [-39.241873505372261, -8.130406610964785],
              [-39.241988095119467, -8.130614226436721],
              [-39.24212265011046, -8.130813417707602],
              [-39.242256492455091, -8.131034951626987],
              [-39.242411301530097, -8.131252945266498],
              [-39.242575559820878, -8.131485225019986],
              [-39.242689438359321, -8.131716630233797],
              [-39.242793597330795, -8.131936553487705],
              [-39.24289320303226, -8.132132056703702],
              [-39.2430321077453, -8.132317315032891],
              [-39.243194438621167, -8.132513142474837],
              [-39.243377199458202, -8.132718364884942],
              [-39.243545065092142, -8.132909575678074],
              [-39.243619098500368, -8.1329967294811],
              [-39.243791046355028, -8.133183234080789],
              [-39.243966588254466, -8.133458562791901],
              [-39.244112371198696, -8.133758241560123],
              [-39.244244416964278, -8.134007545351375],
              [-39.244377649973529, -8.134268426556929],
              [-39.24452236358939, -8.134450243551186],
              [-39.244640953370848, -8.134513761704532],
              [-39.244778227883948, -8.134552845847461],
              [-39.244936736860801, -8.134580791239147],
              [-39.245151629684081, -8.134630049823162],
              [-39.245433351122834, -8.134709932015083],
              [-39.245728894774111, -8.1348298769986],
              [-39.245992236460154, -8.134987198968922],
              [-39.246284733728025, -8.135172001261129],
              [-39.246548254853614, -8.135325161839623],
              [-39.246778815899646, -8.135463417266768],
              [-39.246995124391219, -8.135599872131628],
              [-39.247196739850992, -8.135756416713972],
              [-39.247390457243462, -8.135911609182047],
              [-39.24759708415688, -8.136098362303709],
              [-39.247810518251185, -8.136283302071199],
              [-39.248015036375136, -8.136435321727916],
              [-39.248209003799161, -8.136553246380215],
              [-39.248394707043531, -8.136665025083669],
              [-39.248618795415204, -8.136756156587754],
              [-39.249387532575255, -8.1369935807],
              [-39.249505837751691, -8.137034845754467],
              [-39.250914393690287, -8.137768106669187],
              [-39.251064850763534, -8.137836940006638],
              [-39.251272267162051, -8.137975297537265],
              [-39.25140926632092, -8.138152322600467],
              [-39.252330089626646, -8.139458999340011],
              [-39.252548112410061, -8.13971738262846],
              [-39.252741711143734, -8.139966645090587],
              [-39.252905696586794, -8.140182910023023],
              [-39.253066238128646, -8.140407770199419],
              [-39.25323158897136, -8.140629461400193],
              [-39.253402748162721, -8.140848797085818],
              [-39.253552743601247, -8.141047160478996],
              [-39.253699465341889, -8.141236118595048],
              [-39.253827667534161, -8.141422826869094],
              [-39.253906776808122, -8.141636068947305],
              [-39.253934704987259, -8.141731299992719],
              [-39.253987190653866, -8.141902678300148],
              [-39.254056878681723, -8.142146499827495],
              [-39.254135718866564, -8.142364445643866],
              [-39.254210385837027, -8.142586193138831],
              [-39.254258232062661, -8.142741563869945],
              [-39.254310320266079, -8.142858036535833],
              [-39.288884981976487, -8.139405516496444],
              [-39.288859707441581, -8.139097537973953],
              [-39.288795589369265, -8.138547350749162],
              [-39.28871200808009, -8.137944623442127],
              [-39.288681016634243, -8.137512093517069],
              [-39.288628801499044, -8.137104001290615],
              [-39.288587604426276, -8.136743751490052],
              [-39.288947398971224, -8.136693926853564],
              [-39.303081597663081, -8.134733528685269],
              [-39.3038962834476, -8.134357988050489],
              [-39.303835123357423, -8.133783740766448],
              [-39.304189006819698, -8.133726218607126],
              [-39.304270420918364, -8.133713041819661],
              [-39.328394042952546, -8.129791812760427],
              [-39.329000162632198, -8.127824221786041],
              [-39.288448404387552, -8.133509660196665],
              [-39.28843292875839, -8.13344798140602],
              [-39.288295554052269, -8.132892147262345],
              [-39.288160989659794, -8.132330612435878],
              [-39.28801962308642, -8.131776680510981],
              [-39.287879624201395, -8.131230707512186],
              [-39.287749172693687, -8.130706888967037],
              [-39.287630811048572, -8.130206489449167],
              [-39.28751018812423, -8.129716945981958],
              [-39.28738092864041, -8.129208503624776],
              [-39.287249655710184, -8.128676454067209],
              [-39.287123114512418, -8.128160140139993],
              [-39.287026225844237, -8.127728741764667],
              [-39.286936243103462, -8.127307197991604],
              [-39.286824041012274, -8.126913348854796],
              [-39.286723874549267, -8.126466937264338],
              [-39.286623605164529, -8.126003068032135],
              [-39.286509764689534, -8.12560189311964],
              [-39.286413448818287, -8.125208303980742],
              [-39.2862663550384, -8.12476301069502],
              [-39.286083803100738, -8.124364688036129],
              [-39.285844740896266, -8.123894765192061],
              [-39.285589663902435, -8.123368862218539],
              [-39.285330424880875, -8.12286105282422],
              [-39.284992548557625, -8.12218197788135],
              [-39.323866338880869, -8.117152873994796],
              [-39.323642384678209, -8.116667947243624],
              [-39.284791115779065, -8.121779506833001],
              [-39.284514698337283, -8.12123652210837],
              [-39.284292372354336, -8.120810094976068],
              [-39.284255833329659, -8.120740289773469],
              [-39.28425583326608, -8.120740199319245],
              [-39.284574882263193, -8.119951033970395],
              [-39.297501315798648, -8.11791552767113],
              [-39.321987322954072, -8.114201763948744],
              [-39.322310734087033, -8.109530100049794],
              [-39.294608706475778, -8.107397076977623],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_quilomb: null,
        cd_sr: 'SR-29',
        nr_process: '54140.000472/2005-18',
        nm_comunid: 'TIMBO',
        nm_municip: 'GARANHUS CORRENTES',
        cd_uf: 'PE',
        dt_publica: '25/04/2014',
        dt_public1: null,
        nr_familia: 42.0,
        dt_titulac: null,
        nr_area_ha: 957.0448,
        nr_perimet: null,
        cd_sipra: null,
        ob_descric: null,
        st_titulad: null,
        dt_decreto: null,
        tp_levanta: null,
        nr_escalao: null,
        area_calc_: 956.001,
        perimetro_: 207561.2375,
        esfera: 'FEDERAL',
        fase: 'RTID',
        responsave: 'INCRA',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.411441797022285, -8.990326262339755],
              [-36.410905470540364, -8.990220501409777],
              [-36.410717669964392, -8.990211770458696],
              [-36.410094742171601, -8.990262903619197],
              [-36.408887589292661, -8.993379511327527],
              [-36.408463234004778, -8.992475442059702],
              [-36.40852185468605, -8.99241447991648],
              [-36.40771669142579, -8.989314114682527],
              [-36.407429285527201, -8.989069900929051],
              [-36.407273978085023, -8.988993889594996],
              [-36.407165387667035, -8.988936991807662],
              [-36.407088183282049, -8.988842497289111],
              [-36.407076085849411, -8.988738612386555],
              [-36.407099645131325, -8.98863184660112],
              [-36.407158058380325, -8.988455168225562],
              [-36.407178626701771, -8.988305142351305],
              [-36.407164939940863, -8.988153929388961],
              [-36.407132939566353, -8.988037278185461],
              [-36.407065852219546, -8.987901167780645],
              [-36.406930032882705, -8.987755033510837],
              [-36.406779586195199, -8.987680440308823],
              [-36.406568193211108, -8.987617160258983],
              [-36.406424591060279, -8.987552464220274],
              [-36.406287373277571, -8.98746076184481],
              [-36.406099054243633, -8.987330557074872],
              [-36.405994062499495, -8.987283620240317],
              [-36.405817575465115, -8.987252686401762],
              [-36.405391328377171, -8.987270302310387],
              [-36.405089435963021, -8.987240147315447],
              [-36.403822662801943, -8.987287356804906],
              [-36.402910380325736, -8.987063314101126],
              [-36.402860143800893, -8.986985149721839],
              [-36.402844626706496, -8.986933350517756],
              [-36.402841342615424, -8.986877514461659],
              [-36.402848342419411, -8.98678016760481],
              [-36.402855433887225, -8.986679650630577],
              [-36.402845409948917, -8.98653589277836],
              [-36.402824032118936, -8.986403619522015],
              [-36.402797128234347, -8.986283793541773],
              [-36.402772589680232, -8.986190905995816],
              [-36.402653338153563, -8.985771771874882],
              [-36.402375283283298, -8.985150552526704],
              [-36.402116044995978, -8.984466981648314],
              [-36.401905597787881, -8.984044015921272],
              [-36.401480411988523, -8.983843400946999],
              [-36.400996741484803, -8.983707961435442],
              [-36.400515964584386, -8.98362997333953],
              [-36.39994461543376, -8.983508598142087],
              [-36.399203577793344, -8.983441942989693],
              [-36.398884345569293, -8.983404774519162],
              [-36.398611930804037, -8.983323304211186],
              [-36.398488425410434, -8.983219823860354],
              [-36.398374232828637, -8.982915508560756],
              [-36.398340674038089, -8.982631057987886],
              [-36.398234143998494, -8.982343039589194],
              [-36.397918635953452, -8.981574711596677],
              [-36.39758101644243, -8.980927078986607],
              [-36.397415762419882, -8.980618466658202],
              [-36.397318970737409, -8.980343408611107],
              [-36.397226825588234, -8.980053305029628],
              [-36.397219260180407, -8.980021775466614],
              [-36.393662327460952, -8.981833286592252],
              [-36.392622058411774, -8.980157746894559],
              [-36.392267927200166, -8.979324219523104],
              [-36.391540708580365, -8.978684268909499],
              [-36.391539629089742, -8.97868535223625],
              [-36.390530292035855, -8.979698271064569],
              [-36.390227787922726, -8.979837022157952],
              [-36.38988174420615, -8.979995743297614],
              [-36.38958938553246, -8.980129840119293],
              [-36.388475725956042, -8.980822739797027],
              [-36.387505713879094, -8.98082968317898],
              [-36.3865664080366, -8.980651723167371],
              [-36.386033601939396, -8.980144992044746],
              [-36.385500518694705, -8.979748728205571],
              [-36.38526908665132, -8.979548606073728],
              [-36.384803127703023, -8.979410797287954],
              [-36.384618130574736, -8.97952896284329],
              [-36.384513260483359, -8.97973726272628],
              [-36.384798966451278, -8.980647470977315],
              [-36.385615195965102, -8.981692650329842],
              [-36.38590615548032, -8.981992734111355],
              [-36.386128846214675, -8.9823939175549],
              [-36.386122272308796, -8.98245059069237],
              [-36.386195455514667, -8.982641707816274],
              [-36.386224904654341, -8.983035485836442],
              [-36.386166405678765, -8.983376415518247],
              [-36.386068851695946, -8.983635773338841],
              [-36.386014585717263, -8.983737055422054],
              [-36.386004757349248, -8.983764835534783],
              [-36.385991285837498, -8.983804080999361],
              [-36.386025134746625, -8.983832661579678],
              [-36.386128766669316, -8.983951856030259],
              [-36.386206180773456, -8.984046502076225],
              [-36.38627692116907, -8.984145666408894],
              [-36.386287548463677, -8.984154792703636],
              [-36.386382479787422, -8.984227538019521],
              [-36.386497473259197, -8.98433342762635],
              [-36.386839156740258, -8.984635747634009],
              [-36.38692059608433, -8.984969781678917],
              [-36.387028047252102, -8.985303014012493],
              [-36.386988057750017, -8.98566229722058],
              [-36.386950218225529, -8.985783604349637],
              [-36.386930301695777, -8.985962050383936],
              [-36.386873358009076, -8.986181832193221],
              [-36.38697231745018, -8.986234889743518],
              [-36.3871757235353, -8.986400081335105],
              [-36.387267088185602, -8.986546199368695],
              [-36.387327125082734, -8.986635646126915],
              [-36.387376156242567, -8.986751774114117],
              [-36.387436110744687, -8.98689179292661],
              [-36.387496383083189, -8.987110175598492],
              [-36.387514901463653, -8.987288407343851],
              [-36.387516708456211, -8.987488333598654],
              [-36.387434873918139, -8.987837059892087],
              [-36.387218470912195, -8.988136061444882],
              [-36.387179566848069, -8.988176481026995],
              [-36.387170368359378, -8.988195162669545],
              [-36.387109162703339, -8.988373839938518],
              [-36.38710176604522, -8.988408347704807],
              [-36.387019303790233, -8.98873194330721],
              [-36.386698984163502, -8.989035122415377],
              [-36.38663235407649, -8.989069750825713],
              [-36.386656855085413, -8.989135300068614],
              [-36.386701805956889, -8.989316480501969],
              [-36.386731299398832, -8.989639249810958],
              [-36.386547572161106, -8.990148888473275],
              [-36.386193675782486, -8.99041597908959],
              [-36.385719040165228, -8.990581933165272],
              [-36.385224353485079, -8.990427526679552],
              [-36.38513303859181, -8.990365889353068],
              [-36.385079644010709, -8.990345823638675],
              [-36.385053682534263, -8.990350780063078],
              [-36.384952753074579, -8.990368550217482],
              [-36.384812555023373, -8.990389214219171],
              [-36.384700518152137, -8.990391743551589],
              [-36.38463401427363, -8.990392924309722],
              [-36.384627532791399, -8.99039302850729],
              [-36.384546847443069, -8.99040580990955],
              [-36.384406067880171, -8.990427763744719],
              [-36.384212027860364, -8.990420748819048],
              [-36.384084714977149, -8.990401481303477],
              [-36.383990871558076, -8.990382784717633],
              [-36.383969189352946, -8.990377045336738],
              [-36.383939152754401, -8.990374115486047],
              [-36.383839662111448, -8.990359911119818],
              [-36.383740098593023, -8.990339703518563],
              [-36.383704629838007, -8.990330414073627],
              [-36.383701084242048, -8.990329682860295],
              [-36.383687768198996, -8.990329977634243],
              [-36.383620388957354, -8.99032379443809],
              [-36.383507816984341, -8.990381195487309],
              [-36.383298120890522, -8.990428362797664],
              [-36.383153904555698, -8.990448823646656],
              [-36.383016985599092, -8.990735160469878],
              [-36.382637057714931, -8.990941487097293],
              [-36.382511200579948, -8.990981611595574],
              [-36.382457608172146, -8.991070688112417],
              [-36.382420564060624, -8.991095815656969],
              [-36.381545407145389, -8.990341295620249],
              [-36.381395286981132, -8.990608957376795],
              [-36.381467249021838, -8.990903845207843],
              [-36.381326247579985, -8.991357591515518],
              [-36.381013026623322, -8.991659229670605],
              [-36.380865876312967, -8.991884753585147],
              [-36.380072573495035, -8.992227588666283],
              [-36.379854667110891, -8.992090480470031],
              [-36.379708401682421, -8.992139843348232],
              [-36.379588318594863, -8.992138989345799],
              [-36.379330836516822, -8.992736220149572],
              [-36.378419045708156, -8.993289271440174],
              [-36.377614932856183, -8.993334159279428],
              [-36.377985107677837, -8.993735581026314],
              [-36.377898019353601, -8.994032174120584],
              [-36.377108629292607, -8.994410088294108],
              [-36.376768971639684, -8.994792558806838],
              [-36.376592331191404, -8.995269387378979],
              [-36.37646051096506, -8.995272704433697],
              [-36.376433932088759, -8.995361447575494],
              [-36.376551824927958, -8.995438063331468],
              [-36.376321765348322, -8.9961070595027],
              [-36.375994167437234, -8.996450217503869],
              [-36.375811942301837, -8.996877289239222],
              [-36.375818489699725, -8.997177692869183],
              [-36.375749804261396, -8.997479150700794],
              [-36.375720336987996, -8.99779867085031],
              [-36.375729835272985, -8.997954492231498],
              [-36.375671769403404, -8.998338976222243],
              [-36.375778647963067, -8.998735207942913],
              [-36.375743265061288, -8.999241875848359],
              [-36.375688402960975, -8.999589949419141],
              [-36.375656782296886, -8.999651765248947],
              [-36.377156246977513, -9.000083167934115],
              [-36.379397028705078, -9.000802129229728],
              [-36.379436923172626, -9.000802955401026],
              [-36.379906307098949, -9.000912198979558],
              [-36.381020249230332, -9.001215062567228],
              [-36.382195027763707, -9.00160239124498],
              [-36.384878819264763, -9.002702815350849],
              [-36.385261850670929, -9.002802277076977],
              [-36.38508916602219, -9.003356533593072],
              [-36.38486367392241, -9.003873872782755],
              [-36.383149094610474, -9.003894665675503],
              [-36.38304268942958, -9.003945170542424],
              [-36.382757917862669, -9.004081993291008],
              [-36.382635230973833, -9.00421755605436],
              [-36.381729665371232, -9.006132267889761],
              [-36.381582640837323, -9.006874345960011],
              [-36.381584757064935, -9.007607807838202],
              [-36.381295908046972, -9.008594086952042],
              [-36.379015168399675, -9.007004759233066],
              [-36.37871209465056, -9.007883169527709],
              [-36.381172885455101, -9.009578275192039],
              [-36.380957996595654, -9.010582700203805],
              [-36.380857267222794, -9.011981710446301],
              [-36.380543325939122, -9.014733739130765],
              [-36.381413577844214, -9.015378399636861],
              [-36.38146304624911, -9.015394185641579],
              [-36.381352489899157, -9.015805261581136],
              [-36.380918587178243, -9.016471866666162],
              [-36.379942648935845, -9.017428265702968],
              [-36.378637572406696, -9.019111098551013],
              [-36.378253494398088, -9.019578785485891],
              [-36.378533222163249, -9.019925705663152],
              [-36.378790449542585, -9.020621603901311],
              [-36.378987541303012, -9.020947323535292],
              [-36.379031514410777, -9.021051951856858],
              [-36.379033728970064, -9.021188462518872],
              [-36.379010958661269, -9.021290074936289],
              [-36.378985711778945, -9.021432464210859],
              [-36.378976901358925, -9.02165572937413],
              [-36.378922512536832, -9.021925503224013],
              [-36.378837876559182, -9.022128721472519],
              [-36.378765337224706, -9.022227803487214],
              [-36.378689411528207, -9.022799331575511],
              [-36.378723596949854, -9.022895453259386],
              [-36.378760153538494, -9.022994842368634],
              [-36.378787959050221, -9.023324532684578],
              [-36.378815840850308, -9.023469940860336],
              [-36.378853963581868, -9.024281696920939],
              [-36.378815748965927, -9.024535750765306],
              [-36.378831301914495, -9.024775689894611],
              [-36.378894622294609, -9.025022035999019],
              [-36.379104363510628, -9.025112605497682],
              [-36.379180646153067, -9.025194010787795],
              [-36.379221539499667, -9.02534567440685],
              [-36.379244947083535, -9.025500454482829],
              [-36.37929651801344, -9.025857115636908],
              [-36.379404307700113, -9.026151668177748],
              [-36.379480265799302, -9.026300192417478],
              [-36.37953230394492, -9.026509403085527],
              [-36.379559485106974, -9.026630622996652],
              [-36.3795793233908, -9.026709517310723],
              [-36.379599361906521, -9.026797746754333],
              [-36.379624156764983, -9.026987361931013],
              [-36.379716333924307, -9.027390392157189],
              [-36.379870358795088, -9.02780731429271],
              [-36.379889859901724, -9.027781272811243],
              [-36.380188215844022, -9.027607814354091],
              [-36.37990442082031, -9.026958339308058],
              [-36.380608094411869, -9.026264342851299],
              [-36.381015693407527, -9.025899899980452],
              [-36.381408907920779, -9.025269007578167],
              [-36.38147931529128, -9.024668260779444],
              [-36.381836144370759, -9.024045827599116],
              [-36.382128504085621, -9.023482056956423],
              [-36.382520273304031, -9.023692281127145],
              [-36.383346761403395, -9.022860917982154],
              [-36.385285665029748, -9.02064015395379],
              [-36.385818607065197, -9.019855161473949],
              [-36.385836357751536, -9.019816620519988],
              [-36.386048838213604, -9.019380017402503],
              [-36.38630026689659, -9.019004092394967],
              [-36.387323440210501, -9.01980588547478],
              [-36.388194304068648, -9.019525119010181],
              [-36.388090562153209, -9.018768769607396],
              [-36.388301827247034, -9.018183549853994],
              [-36.388463039672274, -9.017101948851666],
              [-36.388712894494155, -9.016832108079326],
              [-36.388829415908603, -9.016651174038211],
              [-36.389021571092869, -9.016127877556899],
              [-36.388879938406099, -9.016051432384559],
              [-36.386454011159415, -9.014197293836704],
              [-36.386072474046586, -9.013739193957074],
              [-36.386259155610688, -9.013526185643443],
              [-36.38674957768729, -9.013177445899171],
              [-36.387214216040029, -9.012799154720636],
              [-36.387716148433995, -9.012353628101277],
              [-36.38837962004154, -9.012005934082174],
              [-36.388437706199298, -9.011967139877269],
              [-36.389145920715151, -9.011502192416755],
              [-36.389997698838378, -9.012144471603563],
              [-36.390586074584839, -9.012482622412838],
              [-36.391146005220762, -9.012908943875464],
              [-36.391186799971358, -9.012923871863897],
              [-36.391884547290225, -9.013448579884498],
              [-36.391981876790538, -9.013159753264109],
              [-36.391413651339526, -9.012744565578755],
              [-36.389396247425957, -9.011364474434478],
              [-36.389990743615598, -9.011037409345365],
              [-36.390763182266205, -9.010463134655577],
              [-36.391120407545358, -9.009992176982831],
              [-36.391251250296541, -9.00964092813552],
              [-36.391424577208284, -9.009340210479985],
              [-36.391597495340775, -9.009179556713793],
              [-36.391652018466409, -9.009134815266748],
              [-36.391793813231317, -9.009295042035392],
              [-36.391935225161625, -9.009455583035084],
              [-36.3921289147113, -9.009769365512911],
              [-36.392286143073179, -9.010106374769705],
              [-36.392475597351236, -9.010757813563568],
              [-36.392476450212612, -9.010879839076626],
              [-36.394434862041408, -9.008779622638492],
              [-36.395496420404257, -9.0076088285856],
              [-36.396495560711479, -9.007131744744925],
              [-36.396934559090795, -9.006980875407907],
              [-36.397239721704992, -9.00683592604279],
              [-36.397416241203551, -9.006707957877088],
              [-36.397735206424045, -9.006437774123368],
              [-36.398001752472283, -9.006075050399916],
              [-36.398236255501828, -9.005642882743329],
              [-36.398295799533507, -9.005425532308035],
              [-36.397844984090597, -9.005043367746918],
              [-36.397264313537967, -9.004657302462682],
              [-36.396713281324409, -9.004346536690573],
              [-36.396008219322063, -9.003828475547762],
              [-36.395595994486676, -9.003553841495606],
              [-36.395265448713623, -9.00330230154654],
              [-36.395208167086622, -9.003261217189261],
              [-36.395057973933454, -9.003228889057382],
              [-36.395297151143424, -9.002791335085234],
              [-36.394111334079483, -9.002421593643076],
              [-36.393121261246122, -9.002113925116406],
              [-36.392858814885571, -9.001092898926705],
              [-36.395758625009272, -9.001970411761553],
              [-36.395732680964436, -9.002036158653139],
              [-36.395771472814204, -9.002061562113761],
              [-36.398627305351795, -9.003931731467778],
              [-36.399039106807564, -9.003155182505406],
              [-36.399868167673546, -9.002466583852362],
              [-36.400248892007269, -9.002006268698073],
              [-36.402607282484297, -9.004332904107297],
              [-36.402253526720372, -9.004750960195661],
              [-36.402293091914444, -9.005032183680569],
              [-36.405250541244357, -9.007181500600211],
              [-36.405289572017615, -9.007140930236984],
              [-36.405585734623081, -9.006675363315789],
              [-36.405873555675832, -9.006231253832254],
              [-36.406072833198117, -9.005997444457183],
              [-36.406279627756675, -9.005798463230263],
              [-36.406500222257186, -9.005684481970579],
              [-36.40667304791053, -9.005487032189308],
              [-36.406917845341226, -9.005421073657722],
              [-36.407144671900106, -9.005302393663063],
              [-36.407310617417757, -9.005191933160773],
              [-36.408314249281389, -9.00459540314473],
              [-36.408975615185355, -9.004054647870259],
              [-36.409735409801755, -9.003090592700755],
              [-36.410045212912209, -9.002437551631974],
              [-36.410282227083194, -9.001085085848381],
              [-36.410717737792673, -8.998066134593667],
              [-36.411022359843727, -8.996387599734597],
              [-36.411191905459873, -8.995572354026061],
              [-36.411310547190595, -8.994694234671421],
              [-36.411310750930632, -8.993653755287387],
              [-36.411408748838191, -8.992275997837135],
              [-36.411439131026576, -8.992026563803851],
              [-36.411775678171814, -8.990400940240759],
              [-36.411742362515476, -8.990370922747482],
              [-36.411441797022285, -8.990326262339755],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_quilomb: null,
        cd_sr: 'SR-29',
        nr_process: '54141.001271/2007-81\r\n',
        nm_comunid: 'JATOBÁ',
        nm_municip: 'CABROBÓ',
        cd_uf: 'PE',
        dt_publica: '07/12/2017',
        dt_public1: null,
        nr_familia: 149.0,
        dt_titulac: null,
        nr_area_ha: 4851.83,
        nr_perimet: 31684.89,
        cd_sipra: null,
        ob_descric: null,
        st_titulad: null,
        dt_decreto: null,
        tp_levanta: 'Base cartográfica INCRA (SR-29)',
        nr_escalao: null,
        area_calc_: 4855.5726,
        perimetro_: 287172.1876,
        esfera: 'FEDERAL',
        fase: 'RTID',
        responsave: 'INCRA',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.289871644663016, -8.256387985287891],
              [-39.289295588761966, -8.259190851653338],
              [-39.287911584636298, -8.265588840446286],
              [-39.28714646052233, -8.268845926490854],
              [-39.287073894088749, -8.269131714071946],
              [-39.287090176594099, -8.269134778698561],
              [-39.287116303618106, -8.269139696880231],
              [-39.287142431550741, -8.269144615059517],
              [-39.287194384406796, -8.269154831534127],
              [-39.287215382942897, -8.269166205422351],
              [-39.287231007166596, -8.269184388871816],
              [-39.287242514516876, -8.269207851415574],
              [-39.287251164250478, -8.269235065298616],
              [-39.287258212896731, -8.269264498245102],
              [-39.287264920619897, -8.269294621594561],
              [-39.287272543950344, -8.269323903975437],
              [-39.287282341237372, -8.26935081763291],
              [-39.287295138959848, -8.269374328997012],
              [-39.287308526424127, -8.269397166964762],
              [-39.287321912987174, -8.269420012168913],
              [-39.287348710641012, -8.269465710698775],
              [-39.287362136253954, -8.269488554968737],
              [-39.28740261372787, -8.269557016173225],
              [-39.287416197302647, -8.269579799723862],
              [-39.287443544188505, -8.26962528257282],
              [-39.287471180647486, -8.269670628627029],
              [-39.287499136622806, -8.269715807110794],
              [-39.287513180401774, -8.269738335248595],
              [-39.287527259566552, -8.269760822656409],
              [-39.287555534043321, -8.269805677083976],
              [-39.287569734802922, -8.269828043195293],
              [-39.287583980936155, -8.269850367664874],
              [-39.287612622946824, -8.269894895287271],
              [-39.287627024271224, -8.269917096627077],
              [-39.287655997546601, -8.269961374356658],
              [-39.287685218602483, -8.270005483662535],
              [-39.287699929426481, -8.270027475829675],
              [-39.28772956165114, -8.270071329757892],
              [-39.287744490317486, -8.270093193322783],
              [-39.287759496137085, -8.270115012509441],
              [-39.287774582743424, -8.270136789124299],
              [-39.287789751951962, -8.27015852226149],
              [-39.287805007394219, -8.270180211013885],
              [-39.287820351794991, -8.270201856284039],
              [-39.287835787877768, -8.270223457165432],
              [-39.287851316551247, -8.270245014561931],
              [-39.287866943262408, -8.27026652666056],
              [-39.287882668011903, -8.270287994365834],
              [-39.287898495339313, -8.270309416769932],
              [-39.287914427060734, -8.270330793871546],
              [-39.287930465900978, -8.270352126573213],
              [-39.287946614582864, -8.270373413063901],
              [-39.287962879459428, -8.270394648816369],
              [-39.287979441051689, -8.27041558585873],
              [-39.287996382813724, -8.270436105637078],
              [-39.288013688417159, -8.270456230776437],
              [-39.288031338811443, -8.270475986617406],
              [-39.288049318575574, -8.270495394879831],
              [-39.288067607750946, -8.270514480904916],
              [-39.288086190009167, -8.27053326731774],
              [-39.288105048113806, -8.270551776743947],
              [-39.288124163923023, -8.270570035428053],
              [-39.288143519291005, -8.270588064187303],
              [-39.288163097891342, -8.27060588836035],
              [-39.288182880672153, -8.270623531478755],
              [-39.28820285130508, -8.270641016167529],
              [-39.288222991646933, -8.270658366862122],
              [-39.288243284461963, -8.270675607092764],
              [-39.288263711606348, -8.270692760390356],
              [-39.288284255843649, -8.2707098493806],
              [-39.288304899031381, -8.270726899403494],
              [-39.288325624841164, -8.270743933084079],
              [-39.288346414220491, -8.270760973049391],
              [-39.288367249935575, -8.270778045543304],
              [-39.288388075785576, -8.270795285381654],
              [-39.288408821111958, -8.270812925080204],
              [-39.288429718868841, -8.2708303955201],
              [-39.288451031011846, -8.270847050676272],
              [-39.288473017681689, -8.270862246333943],
              [-39.288495940833741, -8.270875336467974],
              [-39.288520060607823, -8.270885675958933],
              [-39.288545530180329, -8.270892875747206],
              [-39.288571318842351, -8.270899328159839],
              [-39.289155871138846, -8.271045582083788],
              [-39.28909367993878, -8.271309751376915],
              [-39.28848689146843, -8.273888481906514],
              [-39.288466069894106, -8.273977231540519],
              [-39.288438278395546, -8.274095113043701],
              [-39.28737121929435, -8.278478809401099],
              [-39.286829970674319, -8.281057125544914],
              [-39.286709792953481, -8.281630415562441],
              [-39.285946100837847, -8.285276151771829],
              [-39.285013328094465, -8.289706510235884],
              [-39.284575585263688, -8.291795304129256],
              [-39.284346802430044, -8.29288678926218],
              [-39.284082361833512, -8.294148894310807],
              [-39.283164623610453, -8.298575799935676],
              [-39.282234066025985, -8.303008410775135],
              [-39.279733639213127, -8.307372928619341],
              [-39.279195147483186, -8.308312844219296],
              [-39.291231770506457, -8.308745116610726],
              [-39.291877051770363, -8.308744299363388],
              [-39.292669664356517, -8.30875391236197],
              [-39.294653668948513, -8.308522811133344],
              [-39.295792411948312, -8.308674470379231],
              [-39.297925584510693, -8.308608477950656],
              [-39.299196267363541, -8.308569266926378],
              [-39.301919350000873, -8.308485077826063],
              [-39.303235595936727, -8.308444364892095],
              [-39.304642482855968, -8.30839182390139],
              [-39.305414021432846, -8.308367965507896],
              [-39.306185560770103, -8.308344105621684],
              [-39.306957099959469, -8.308320244243456],
              [-39.307728638092662, -8.30829638137393],
              [-39.308454792455883, -8.30827392073253],
              [-39.309135561258088, -8.308252863472999],
              [-39.309964830699748, -8.308300842496935],
              [-39.310777069462723, -8.308418390445247],
              [-39.311638867552809, -8.308458187143124],
              [-39.312455305729891, -8.308495927209126],
              [-39.313244532631771, -8.308532334737933],
              [-39.315131417391243, -8.308619453995636],
              [-39.316401436678611, -8.308678087547079],
              [-39.316855014725149, -8.308699026599085],
              [-39.31857740931801, -8.308650405656575],
              [-39.319616625625478, -8.308547403448573],
              [-39.321785423312001, -8.308332433643876],
              [-39.322960188498733, -8.308215987673078],
              [-39.324062658993597, -8.308106703991848],
              [-39.324948249102782, -8.3080189158928],
              [-39.327297769633809, -8.307785966710306],
              [-39.328278470359578, -8.307516344554411],
              [-39.328877086546434, -8.307232624902182],
              [-39.329418349258034, -8.306976216098073],
              [-39.330516893904864, -8.306455037887716],
              [-39.331050173837056, -8.306202971675399],
              [-39.331829222644743, -8.305833814327698],
              [-39.332403086809805, -8.305561444800109],
              [-39.333756246511136, -8.304920650459884],
              [-39.335601351951823, -8.304046323673122],
              [-39.341019950856129, -8.2997907150319],
              [-39.346300794675365, -8.296836042194998],
              [-39.356173038010191, -8.291287112311494],
              [-39.356264026350708, -8.291040793863145],
              [-39.356322537627861, -8.290863879711592],
              [-39.356368727450032, -8.290683384688624],
              [-39.356402376841579, -8.290500158342978],
              [-39.356423327682712, -8.290315064640296],
              [-39.356431481795219, -8.290128975632197],
              [-39.356426800029972, -8.289942766934486],
              [-39.356409304990713, -8.289757317724671],
              [-39.356379079207663, -8.289573499889359],
              [-39.356336265140428, -8.289392181642363],
              [-39.355194923515938, -8.285208794016141],
              [-39.355032059477871, -8.284682806896953],
              [-39.354833884659087, -8.28416898437891],
              [-39.35460130571478, -8.283669675558944],
              [-39.354434663814018, -8.283322339882895],
              [-39.354283821706311, -8.282967911278236],
              [-39.353666316021965, -8.281421355251796],
              [-39.353505729604365, -8.280982967714083],
              [-39.353370624438554, -8.28053613368899],
              [-39.353316015332148, -8.280302858526966],
              [-39.349565106571525, -8.278866175137644],
              [-39.347703196978657, -8.277060263070307],
              [-39.343131874331078, -8.272626313530132],
              [-39.349268175989678, -8.264555071026626],
              [-39.349080096805665, -8.26279533941965],
              [-39.340734827198609, -8.257558385886478],
              [-39.337684734319524, -8.25727508950156],
              [-39.333843423866554, -8.257986536531446],
              [-39.329444920719695, -8.256811213721633],
              [-39.328261429454912, -8.256329356595034],
              [-39.325463242464778, -8.254091373551038],
              [-39.323448235467971, -8.252475021524566],
              [-39.318556446571208, -8.246903803020851],
              [-39.321622288767664, -8.238939891874672],
              [-39.321720026662625, -8.238689615726921],
              [-39.32331348573522, -8.234458286922383],
              [-39.324192658655406, -8.232129398285645],
              [-39.320812020142753, -8.231455327745138],
              [-39.320213551195316, -8.23133487030235],
              [-39.319857273510067, -8.231264114953275],
              [-39.319503714622222, -8.231193698129212],
              [-39.318945873858169, -8.231082587348597],
              [-39.318659880084489, -8.23102521192927],
              [-39.318373210916306, -8.230967895632915],
              [-39.318088005696119, -8.230910897269272],
              [-39.317959713029659, -8.230887118289925],
              [-39.317897663881524, -8.230872712979124],
              [-39.317836231765185, -8.230860108997632],
              [-39.317763767492004, -8.230845688388051],
              [-39.317691786865616, -8.230830895620176],
              [-39.317613234485613, -8.230814962897774],
              [-39.317506054525261, -8.230793514338565],
              [-39.317387231449793, -8.230769213010566],
              [-39.317267236996159, -8.230744744329037],
              [-39.317149156905508, -8.23072076164245],
              [-39.316974928853881, -8.230685080594185],
              [-39.316842389768986, -8.230657806778401],
              [-39.30644044648853, -8.226987214426321],
              [-39.298736790449951, -8.224837727118802],
              [-39.296192254815566, -8.223961569918536],
              [-39.292928353396853, -8.240492273058788],
              [-39.291938546296066, -8.245522647270281],
              [-39.291236076780287, -8.249074103748214],
              [-39.29113274253978, -8.249610411342617],
              [-39.290551874200979, -8.252731576714623],
              [-39.289871644663016, -8.256387985287891],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        cd_quilomb: '91',
        cd_sr: 'SR-03',
        nr_process: '54141.001339/2004-80',
        nm_comunid: 'CONCEIÇAO DAS CRIOULAS',
        nm_municip: 'SALGUEIRO',
        cd_uf: 'PE',
        dt_publica: null,
        dt_public1: null,
        nr_familia: 750.0,
        dt_titulac: '19/11/2015',
        nr_area_ha: 16865.0678,
        nr_perimet: 59028.2402,
        cd_sipra: null,
        ob_descric: 'Estudo de delimitação',
        st_titulad: 'F',
        dt_decreto: '223/1/2009',
        tp_levanta: null,
        nr_escalao: null,
        area_calc_: 17858.9814,
        perimetro_: 526295.7636,
        esfera: 'FEDERAL',
        fase: 'TITULO PARCIAL',
        responsave: 'INCRA',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.982879025426747, -8.351904924973539],
              [-38.983401754643666, -8.346803963560429],
              [-38.984382754886283, -8.337232136468691],
              [-38.985503073481745, -8.326296166021997],
              [-38.990351375019443, -8.280105406076455],
              [-38.990480260938838, -8.278885720427091],
              [-38.990609236870597, -8.277665944208353],
              [-38.990390782360528, -8.276497628298381],
              [-38.988201041995353, -8.264785875158383],
              [-38.987967076588504, -8.26353506214463],
              [-38.986359228347879, -8.254937571257535],
              [-38.980234646792006, -8.254274283475571],
              [-38.978537564756252, -8.254090573260127],
              [-38.978529392894878, -8.254084512362104],
              [-38.97637641859091, -8.248923863536845],
              [-38.975934645401026, -8.247864794175291],
              [-38.975930105651891, -8.247860090260657],
              [-38.970842446508989, -8.245474734590166],
              [-38.97083881473602, -8.245471568412887],
              [-38.968034695337231, -8.241322289445044],
              [-38.968032879506268, -8.24132038975211],
              [-38.965279316743533, -8.239088915284416],
              [-38.962525784850719, -8.236857512260553],
              [-38.962524876928875, -8.236856878992944],
              [-38.958825361668367, -8.234563942341509],
              [-38.958824181364754, -8.234563309037567],
              [-38.95014521861367, -8.230785298924461],
              [-38.950143493599896, -8.230784303708601],
              [-38.939464081009469, -8.222941136787313],
              [-38.939458542778226, -8.222939417313734],
              [-38.927981565955641, -8.223173069693472],
              [-38.926305302667956, -8.22320722922443],
              [-38.926304213144718, -8.223207138570045],
              [-38.909894719913723, -8.22171862113438],
              [-38.909891451246466, -8.221718891766074],
              [-38.901553476530012, -8.224064506695759],
              [-38.884737386218696, -8.219416731434602],
              [-38.867921333069155, -8.214768890667084],
              [-38.86461385933751, -8.215778621568518],
              [-38.864609591079891, -8.215781514678755],
              [-38.861990855153287, -8.218905196661776],
              [-38.861990491852957, -8.218905558355301],
              [-38.86094288298937, -8.220033617941937],
              [-38.859895358992688, -8.221161674669922],
              [-38.859892543670298, -8.221163663688733],
              [-38.857099970725791, -8.222464592975042],
              [-38.857099062617785, -8.222465044924881],
              [-38.85352014163238, -8.224633941327923],
              [-38.853516600159111, -8.224635206400384],
              [-38.85124706378361, -8.224938209675395],
              [-38.848977614982587, -8.225241200106485],
              [-38.846881961697996, -8.225501006666326],
              [-38.846881598516838, -8.225501006527999],
              [-38.845224030205955, -8.225760609202442],
              [-38.845221124755987, -8.225760608083769],
              [-38.843377918448297, -8.225454248622546],
              [-38.84337719215732, -8.22545406743072],
              [-38.841888666611716, -8.225067334765573],
              [-38.840400144013323, -8.224680687090723],
              [-38.839279941070991, -8.224389610771889],
              [-38.835182621767004, -8.223325203140975],
              [-38.835180715376893, -8.223324478724258],
              [-38.833893796373331, -8.222657028814259],
              [-38.832606881734115, -8.221989574849449],
              [-38.83260597397981, -8.22198912219959],
              [-38.830466023308539, -8.221174769383799],
              [-38.829199196336212, -8.220692654202409],
              [-38.829194656825536, -8.220692200002762],
              [-38.8285953777456, -8.22078077129245],
              [-38.827996098378811, -8.220869432137803],
              [-38.826797538940497, -8.221046660677798],
              [-38.826699010996073, -8.22129328734429],
              [-38.81995909338422, -8.238166659589197],
              [-38.823233631977686, -8.241444640510316],
              [-38.832776296812021, -8.250997539088656],
              [-38.839887145781766, -8.258117748425205],
              [-38.846689906289704, -8.264929447639904],
              [-38.869409551972765, -8.28767898698654],
              [-38.900043862525891, -8.318353555770122],
              [-38.919208976634117, -8.337548948866516],
              [-38.916001218616003, -8.340312833098084],
              [-38.91626521386128, -8.340851091111832],
              [-38.91697840038421, -8.341892277834129],
              [-38.918009833620289, -8.342927649671465],
              [-38.918791582341363, -8.343612638870242],
              [-38.919813716561393, -8.344420240584093],
              [-38.920533721121807, -8.345059172701816],
              [-38.921403015543035, -8.3458261257475],
              [-38.922541496053711, -8.346681774640947],
              [-38.923426863401318, -8.347501822970331],
              [-38.924505383083705, -8.348495306737044],
              [-38.925231658816081, -8.349668272693213],
              [-38.925982718716348, -8.350439625605508],
              [-38.926564914091109, -8.351344817921614],
              [-38.926865647058463, -8.3522483293179],
              [-38.927315399569189, -8.353288634456195],
              [-38.927590761812951, -8.354375038794609],
              [-38.927736823363702, -8.355281687007396],
              [-38.928012048181905, -8.356140598871654],
              [-38.928247260237207, -8.35628265451377],
              [-38.928482381621315, -8.356424800454269],
              [-38.9289528065704, -8.35670900149605],
              [-38.929877269173375, -8.356996810379604],
              [-38.930954667322681, -8.356861409061969],
              [-38.931895501792567, -8.356502378779128],
              [-38.932731266629595, -8.356043105245154],
              [-38.933647586684877, -8.355613060108865],
              [-38.934484077100478, -8.355136415754304],
              [-38.935564410542582, -8.354231147265201],
              [-38.93599769126071, -8.353953885179539],
              [-38.936431062179587, -8.353676713079993],
              [-38.93742853059954, -8.353494697319892],
              [-38.937658857023692, -8.353529377577534],
              [-38.9378891834752, -8.353564148155558],
              [-38.938349836517446, -8.353633598456353],
              [-38.938707099452529, -8.353904835403515],
              [-38.939064453692787, -8.354176162496291],
              [-38.939820017342861, -8.354833970032727],
              [-38.940620119451061, -8.355283466947203],
              [-38.941677760133665, -8.355418672169316],
              [-38.942137903338136, -8.355269309458455],
              [-38.94259804618266, -8.355120036663429],
              [-38.94359709851414, -8.354666733659858],
              [-38.944052077455297, -8.354416511608617],
              [-38.944507055806397, -8.354166379481747],
              [-38.945732919031506, -8.354185273381862],
              [-38.946645420831679, -8.35431510862302],
              [-38.947734925422168, -8.35456517872289],
              [-38.948734274391946, -8.354566937543288],
              [-38.949651333937446, -8.354583156050047],
              [-38.950651991482204, -8.355013753302275],
              [-38.951484609940536, -8.35556065150233],
              [-38.951845604905436, -8.355853947905247],
              [-38.952206600410257, -8.356147243977725],
              [-38.952952378773844, -8.35682221165321],
              [-38.953703990060411, -8.357348743356985],
              [-38.954844594862315, -8.357224410218164],
              [-38.955674349140551, -8.356686120946858],
              [-38.956513300663325, -8.355911836050005],
              [-38.956956536332548, -8.355844948417239],
              [-38.957399862668723, -8.355778150751185],
              [-38.957885426747666, -8.355673456793898],
              [-38.95837108138624, -8.355568852702952],
              [-38.959227346423013, -8.355049011332635],
              [-38.96018048823516, -8.354838983329953],
              [-38.96066696086222, -8.354712486586816],
              [-38.961153433178531, -8.354585989243734],
              [-38.962063778311361, -8.354540669518563],
              [-38.962508923429418, -8.354425744677307],
              [-38.962954068289143, -8.354310819333808],
              [-38.9630611540939, -8.354278989472594],
              [-38.963845722823145, -8.354046051957511],
              [-38.964705142832351, -8.353675086501845],
              [-38.965569749739387, -8.35317296107268],
              [-38.966344824016417, -8.352405071774998],
              [-38.967180176231558, -8.351954408718623],
              [-38.967931524251043, -8.352489597168976],
              [-38.968818109122509, -8.354211191759559],
              [-38.969785022978435, -8.354342967987852],
              [-38.970616843709088, -8.353752183946742],
              [-38.971539470071498, -8.353392243721185],
              [-38.972395787876771, -8.353099865987261],
              [-38.97296490665385, -8.35295011313023],
              [-38.973534115824286, -8.35280035945895],
              [-38.974020210001015, -8.352800663006171],
              [-38.974506304174504, -8.35280105641095],
              [-38.976593803756607, -8.35308774412154],
              [-38.977509106546691, -8.353611436395676],
              [-38.978042478886856, -8.354374899568789],
              [-38.978344449914573, -8.354784221330668],
              [-38.978646421571213, -8.355193542855407],
              [-38.979208773864762, -8.355914311602422],
              [-38.979929806175278, -8.356527175787576],
              [-38.98038945838438, -8.356854733353209],
              [-38.980849111360996, -8.357182290381504],
              [-38.981333306982492, -8.35711248289673],
              [-38.981575450137861, -8.357077669388298],
              [-38.981817593254732, -8.357042765277438],
              [-38.982321894480314, -8.356229876533765],
              [-38.982406828634524, -8.355956618275489],
              [-38.982491853495539, -8.355683359998967],
              [-38.982519203825397, -8.3554167023125],
              [-38.982520385074857, -8.355405033776558],
              [-38.982544736831251, -8.355167592649801],
              [-38.982565272274286, -8.35496669483666],
              [-38.982607160839777, -8.354557753357048],
              [-38.982700569247605, -8.353646974337478],
              [-38.98273373458656, -8.353323421182427],
              [-38.982818328675755, -8.352497939989123],
              [-38.982879025426747, -8.351904924973539],
            ],
          ],
        ],
      },
    },
  ],
};
