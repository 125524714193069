import { useLayers } from '../../../context/LayersContext';
import { GeoJSON, CircleMarker } from 'react-leaflet';
import { jsonPEUnidadesDeConservacao30 } from '../../../LocalStore/PE_UNIDADES_DE_CONSERVACAO_30';
import { jsonReasDeQuilombolasPE2 } from '../../../LocalStore/reasdeQuilombolas_PE_2';
import { jsonPoligonoDasSecasPE0 } from '../../../LocalStore/Poligono_das_Secas_PE_0';
import { jsonReasPrioritriasDoCaaTingaPE3 } from '../../../LocalStore/REAS_PRIORITRIAS_DO_CAATINGA_PE_3';
import { jsonReasPrioritariasMataAtlanticaPE4 } from '../../../LocalStore/AREAS_PRIORITARIAS_MATA_ATLANTICA_PE_4';
import { jsonAssentamentoBrasilPE5 } from '../../../LocalStore/AssentamentoBrasil_PE_5';
import { jsonTerrasIndigenasHomologadasPE6 } from '../../../LocalStore/terras_indigenas_homologadas_PE_6';
import { jsonSitiosArqueologicosGeorreferenciadosPE8 } from '../../../LocalStore/Stios_Arqueolgicos_Georreferenciados_PE_8';

const stylePEUnidadesDeConservacao30 = {
  opacity: 1,
  color: 'rgba(188,188,188,1.0)',
  dashArray: '10,5',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(177,214,102,0.7941176470588236)',
  interactive: true,
};

const styleReasDeQuilombolasPE2 = {
  color: 'rgba(145,145,145,1.0)',
  dashArray: '10,5',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(88,65,121,1.0)',
  interactive: false,
};

const stylePoligonoDasSecasPE0 = {
  opacity: 1,
  color: 'rgba(126,126,126,1.0)',
  dashArray: '10,5',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(254,193,64,0.6666666666666666)',
  interactive: true,
};

const styleReasPrioritriasDoCaatingaPE3 = {
  opacity: 1,
  color: 'rgba(35,35,35,1.0)',
  dashArray: '10,5',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(190,200,223,0.7568627450980392)',
  interactive: false,
};

const styleAreasPrioritariasMataAtlanticaPE4 = {
  opacity: 1,
  color: 'rgba(183,52,0,1.0)',
  dashArray: '10,5',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(243,119,109,0.7607843137254902)',
  interactive: false,
};

const styleAssentamentoBrasilPE5 = {
  opacity: 1,
  color: 'rgba(159,159,159,1.0)',
  dashArray: '10,5',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(219,189,44,0.8745098039215686)',
  interactive: true,
};

const styleTerrasIndigenasHomologadasPE6 = {
  opacity: 1,
  color: 'rgba(122,122,122,1.0)',
  dashArray: '10,5',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(156,74,38,0.9568627450980393)',
  interactive: true,
};

const styleStiosArqueolgicosGeorreferenciadosPE8 = {
  shape: 'diamond',
  radius: 4.0,
  opacity: 1,
  color: 'rgba(61,128,53,1.0)',
  dashArray: '',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 2.0,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(84,176,74,1.0)',
  interactive: true,
};

export function RenderSocioambiental() {
  const { /*addNewLayer,*/ layers } = useLayers();
  if (!layers) return null;
  const localStorageDict = [
    {
      name: 'Unidade de Conservação',
      data: jsonPEUnidadesDeConservacao30,
      style: stylePEUnidadesDeConservacao30,
      layerType: 'GEOJSON',
    },
    {
      name: 'Territórios quilombolas',
      data: jsonReasDeQuilombolasPE2,
      style: styleReasDeQuilombolasPE2,
      layerType: 'GEOJSON',
    },
    {
      name: 'Polígono das secas',
      data: jsonPoligonoDasSecasPE0,
      style: stylePoligonoDasSecasPE0,
      layerType: 'GEOJSON',
    },
    {
      name: 'Área prioritária caatinga',
      data: jsonReasPrioritriasDoCaaTingaPE3,
      style: styleReasPrioritriasDoCaatingaPE3,
      layerType: 'GEOJSON',
    },
    {
      name: 'Área prioritária Mata Atlântica',
      data: jsonReasPrioritariasMataAtlanticaPE4,
      style: styleAreasPrioritariasMataAtlanticaPE4,
      layerType: 'GEOJSON',
    },
    {
      name: 'Assentamentos públicos',
      data: jsonAssentamentoBrasilPE5,
      style: styleAssentamentoBrasilPE5,
      layerType: 'GEOJSON',
    },
    {
      name: 'Terras indígenas (Homologadas)',
      data: jsonTerrasIndigenasHomologadasPE6,
      style: styleTerrasIndigenasHomologadasPE6,
      layerType: 'GEOJSON',
    },
    // {
    //   name: 'Reserva da Biosfera',
    //   data: jsonReservasDaBiosfera29,
    //   style: styleReservasDaBiosfera29,
    //   layerType: 'GEOJSON',
    // },
    {
      name: 'Sítios Arqueológicos',
      data: jsonSitiosArqueologicosGeorreferenciadosPE8,
      style: styleStiosArqueolgicosGeorreferenciadosPE8,
      layerType: 'CIRCLEMARKER',
    },
  ];

  return (
    <>
      {localStorageDict.map((obj) => {
        if (layers.includes(obj.name)) {
          if (obj.data) {
            if (obj.layerType === 'GEOJSON') {
              return (
                <GeoJSON
                  //@ts-ignore
                  data={obj.data}
                  key={obj.name}
                  //@ts-ignore
                  style={obj.style}
                />
              );
            }
            return (
              <>
                {obj.data.features.map((feature, index) => (
                  <CircleMarker
                    key={index}
                    // @ts-ignore
                    pathOptions={styleStiosArqueolgicosGeorreferenciadosPE8}
                    center={[
                      // @ts-ignore
                      feature.geometry.coordinates[1],
                      // @ts-ignore
                      feature.geometry.coordinates[0],
                    ]}
                  />
                ))}
              </>
            );
          }
        }
      })}
    </>
  );
}
