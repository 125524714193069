export var jsonSitiosArqueologicosGeorreferenciadosPE8 = {
  type: 'FeatureCollection',
  name: 'Stios_Arqueolgicos_Georreferenciados_PE_8',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        id_bem: 39940.0,
        identifica: 'Ruína Forte Santo Antônio',
        co_iphan: 'PE2605459BAST00027',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Ruína Forte Santo Antônio, localizado(a) no estado de Pernambuco, cidade(s) de Fernando de Noronha, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-05-06',
      },
      geometry: {
        type: 'Point',
        coordinates: [-32.399768029163454, -3.832805359327441],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 40118.0,
        identifica: 'Ruína Forte São Pedro Boldró',
        co_iphan: 'PE2605459BAST00029',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Ruína Forte São Pedro Boldró, localizado(a) no estado de Pernambuco, cidade(s) de Fernando de Noronha, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-05-24',
      },
      geometry: {
        type: 'Point',
        coordinates: [-32.43152327287595, -3.846420253431044],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34227.0,
        identifica: 'Reduto de Santana',
        co_iphan: 'PE2605459BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Reduto de Santana, também conhecido(a) como Reduto do Armazém, localizado(a) no estado de Pernambuco, cidade(s) de Fernando de Noronha, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2021-09-23',
      },
      geometry: {
        type: 'Point',
        coordinates: [-32.410939786108358, -3.83987753267814],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37398.0,
        identifica: 'Cangola',
        co_iphan: 'PE2613909BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Cangola, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta material lítico lascado em quartzo, silexito e arenito.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-38.41909, -8.005626] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37701.0,
        identifica: 'Borges',
        co_iphan: 'PE2615607BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Borges, localizado(a) no estado de Pernambuco, cidade(s) de Trindade, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de vidro, louça, cerâmica e grés em superfície e pela presença de material construtivo ( telhas e blocos de p',
        dt_cadastr: '2022-02-03',
      },
      geometry: { type: 'Point', coordinates: [-40.349121, -7.81669] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46550.0,
        identifica: 'Sítio Pedra Letrada',
        co_iphan: 'PE2609154BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sitio de pintura rupestre de grafismos puros,  formas geométicas, como circulos concentricos e retangulares.',
        dt_cadastr: '2023-07-31',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.571001971678598, -8.903240141621099],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37966.0,
        identifica: 'Roça Velha',
        co_iphan: 'PE2605707BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Roça Velha, localizado(a) no estado de Pernambuco, cidade(s) de Floresta, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos cerâmicos com borda reforçada e lascas em sílex, bem como estilhas e lascas  em quartzo hialino.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-38.188142, -8.669294] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24075.0,
        identifica: 'Pinturas da Fazenda Velha',
        co_iphan: 'PE2609907BAST00015',
        no_logrado: '-',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Foram identificados registros rupestres no sitio. Os registros rupestres estão em forma de pinturas, de cor vermelha e padrões geométricos.',
        dt_cadastr: '2019-10-04',
      },
      geometry: { type: 'Point', coordinates: [-40.023355, -7.955168] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37967.0,
        identifica: 'Malícia',
        co_iphan: 'PE2612208BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Malícia, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.085666, -7.88287] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37969.0,
        identifica: 'Serra do Livramento',
        co_iphan: 'PE2603009BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Serra do Livramento, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.306334, -8.214553] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14140.0,
        identifica: 'Pedra do Mel',
        co_iphan: 'PE2609907BAST00009',
        no_logrado: 'Fazenda Boa Vista',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio arqueológico com presença de material lítico lascado, cujo principal matéria-prima são seixos de sílex e material cerâmico.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.087961, -7.924305] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37972.0,
        identifica: 'Riacho da Barra',
        co_iphan: 'PE2612208BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Riacho da Barra, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.218037, -8.12781] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37974.0,
        identifica: 'Mucunã',
        co_iphan: 'PE2605707BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Mucunã, localizado(a) no estado de Pernambuco, cidade(s) de Floresta, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.915682, -8.489498] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46551.0,
        identifica: 'Sítio Pingador da Carnaúba',
        co_iphan: 'PE2609154BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sitio de pintura rupestre de grafismos puros, formas geométicas, como circulos\r\nconcentricos e retangulares.',
        dt_cadastr: '2023-07-31',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.568299441226202, -8.868606256831004],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37975.0,
        identifica: 'Cacimba Nova',
        co_iphan: 'PE2605103BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Cacimba Nova, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.875206, -8.38501] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24819.0,
        identifica: 'S.S. Salgueiro 03',
        co_iphan: 'PE2615201BAST00008',
        no_logrado: '-',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio composto por material lítico lascado, localizado em área de lagoa.',
        dt_cadastr: '2019-11-21',
      },
      geometry: { type: 'Point', coordinates: [-39.340339, -8.079831] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37977.0,
        identifica: 'Pau Ferro',
        co_iphan: 'PE2605103BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pau Ferro, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.864559, -8.361095] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37978.0,
        identifica: 'Pinica Pau',
        co_iphan: 'PE2603009BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pinica Pau, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.308591, -8.419011] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37979.0,
        identifica: 'Oficina de Severino',
        co_iphan: 'PE2603009BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Oficina de Severino, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.358736, -8.411442] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37350.0,
        identifica: 'Baixio da Formiga',
        co_iphan: 'PE2612208BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Baixio da Formiga, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos cerâmicos de produção local/regional, faiança fina, vidro e polímeros. Além de lascas e artefato',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.165649, -7.963759] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37351.0,
        identifica: 'Alto das Pedras',
        co_iphan: 'PE2612208BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Alto das Pedras, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.130755, -7.973513] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37440.0,
        identifica: 'Santo Antônio',
        co_iphan: 'PE2614105BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Santo Antônio, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta ocupação lítica com artefatos e lascas em quartzo e silexito, fragmentos de cerâmica de produção local/regiona',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-37.320785, -8.340246] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37480.0,
        identifica: 'Calumbi III',
        co_iphan: 'PE2605608BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Calumbi III, localizado(a) no estado de Pernambuco, cidade(s) de Flores, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de telha capa canal, fragmentos de borracha, tecidos e latas de óleo.',
        dt_cadastr: '2022-01-13',
      },
      geometry: { type: 'Point', coordinates: [-38.016851, -8.050591] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 33257.0,
        identifica: 'Sítio Bom Nome',
        co_iphan: 'PE2613909BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio Bom Nome, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2021-06-07',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.580141782411992, -8.003858325877495],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37087.0,
        identifica: 'SJMI 05',
        co_iphan: 'PE2609907BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'SJMI 05, localizado(a) no estado de Pernambuco, cidade(s) de Ouricuri, é um Bem Arqueológico, do tipo Sítio. O material cultural é constituído por material lítico lascado em sílex e material cerâmico.',
        dt_cadastr: '2021-12-23',
      },
      geometry: { type: 'Point', coordinates: [-40.087474, -7.923405] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24791.0,
        identifica: 'S.S. Salgueiro 01',
        co_iphan: 'PE2615201BAST00006',
        no_logrado: '-',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-11-21',
      },
      geometry: { type: 'Point', coordinates: [-39.347131, -8.083579] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37399.0,
        identifica: 'Talhado',
        co_iphan: 'PE2613909BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Talhado, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta ois fornos (caieiras) e os remanescentes da antiga estrada de ferro que passava no local na parte norte do sítio.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-38.353453, -8.013795] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46808.0,
        identifica: 'Peri-Peri I',
        co_iphan: 'PE2616001BAST00029',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico situado na baixa vertente do Morro dos Ossos. São pinturas rupestres posicionadas nos setores sudeste e sudoeste do sítio.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.833459, -8.544951] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46810.0,
        identifica: 'Peri-Peri II',
        co_iphan: 'PE2616001BAST00030',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na baixa vertente do Morro dos Ossos, contendo pinturas rupestres posicionadas no setor noroeste do sítio.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.832731, -8.545254] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46813.0,
        identifica: 'Pingadeira I',
        co_iphan: 'PE2604106BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contando pinturas rupestres.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.09943, -8.198275] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46814.0,
        identifica: 'Pingadeira II',
        co_iphan: 'PE2604106BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.084073, -8.191606] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7010.0,
        identifica: 'Fortaleza de São José do Morro (Atualização CNSA PE00057)',
        co_iphan: 'PE2605459BAST00001',
        no_logrado: 'Ilha de São José',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-32.40139, -3.826675] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19490.0,
        identifica: 'Sítio Maribondo (IC-M)',
        co_iphan: 'PE2602902BAST00019',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.963856, -8.374866] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7042.0,
        identifica: 'Furna dos Ossos',
        co_iphan: 'PE2616209BAST00001',
        no_logrado: 'Serra Taquara',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.003379, -7.916448] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14506.0,
        identifica: 'Pedra do Coração',
        co_iphan: 'PE2601508BAST00001',
        no_logrado: 'Sítio Barra do Timbó',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.813669, -8.584494] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7373.0,
        identifica: 'Furna do Estrago',
        co_iphan: 'PE2601706BAST00001',
        no_logrado: 'Serra da Boa Vista, ou Serra do Estrago',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.470867, -8.193736] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7405.0,
        identifica: 'Peri - Peri I',
        co_iphan: 'PE2616001BAST00001',
        no_logrado: 'Fazenda Oliveira',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.830558, -8.54723] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7423.0,
        identifica: 'Pedra da Lagartixa I',
        co_iphan: 'PE2602605BAST00001',
        no_logrado: 'Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.201429, -8.152939] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7424.0,
        identifica: 'Pedra da Melancia I',
        co_iphan: 'PE2604106BAST00001',
        no_logrado: 'Itaúna',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.126016, -8.184791] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37481.0,
        identifica: 'Calumbi I',
        co_iphan: 'PE2605608BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Calumbi I, localizado(a) no estado de Pernambuco, cidade(s) de Flores, é um Bem Arqueológico, do tipo Sítio. O local apresenta ocupação lítica com artefatos  em quartzo dispersos, depressão elíptica com marcas de polimento situada em afloramento',
        dt_cadastr: '2022-01-13',
      },
      geometry: { type: 'Point', coordinates: [-38.013387, -8.049552] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37352.0,
        identifica: 'Carvoeiro',
        co_iphan: 'PE2612208BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Carvoeiro, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de faiança fina, vidro, metal e cerâmica de produção local/regional, artefatos e lascas em silexito e aren',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.085594, -7.972105] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37400.0,
        identifica: 'Camaleão III',
        co_iphan: 'PE2613909BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Camaleão III, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta cerâmica de produção local/regional, louças, metais, plásticos e ossos em profusão, além de vestígios de',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-38.353878, -8.020471] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37401.0,
        identifica: 'Camaleão II',
        co_iphan: 'PE2613909BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Camaleão II, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefatos lascados esparsos e pela presença de grandes lajes de quartzito onde foram encontrados dois pilões.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-38.349758, -8.020754] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37441.0,
        identifica: 'Arcoverde II',
        co_iphan: 'PE2614105BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Arcoverde II, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta concentrações de telhas e tijolos na área correspondente a unidades domésticas, duas cacimbas próximo ao leito do',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-37.212639, -8.405457] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37442.0,
        identifica: 'Chilili',
        co_iphan: 'PE2614105BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Chilili , localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta ocupação lítica com artefatos e lascas de quartzo e arenito, um machado polido além de marcas de polimento em aflorame',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-37.206685, -8.406876] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37482.0,
        identifica: 'Calumbi II',
        co_iphan: 'PE2605608BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Calumbi II, localizado(a) no estado de Pernambuco, cidade(s) de Flores, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas unipolares e percutores em quartzitos obtidas através de seixos e um bloco com polimento por marcas de uso em aflor',
        dt_cadastr: '2022-01-13',
      },
      geometry: { type: 'Point', coordinates: [-38.009755, -8.050412] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37458.0,
        identifica: 'Caldeirão',
        co_iphan: 'PE2603108BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Caldeirão, localizado(a) no estado de Pernambuco, cidade(s) de Cachoeirinha, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas e fragmentos em quartzito e instrumentos em quartzito lascados e retocados com marcas de utilização.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.279883, -8.461909] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37443.0,
        identifica: 'Riacho do Mel',
        co_iphan: 'PE2601201BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Riacho do Mel, localizado(a) no estado de Pernambuco, cidade(s) de Arcoverde, é um Bem Arqueológico, do tipo Sítio. O local apresenta material lítico lascado e bruto que compreende lascas obtidas a partir de seixos em arenito silicificado e quartzo l',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.979867, -8.414076] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37353.0,
        identifica: 'Baixio dos Milagres I',
        co_iphan: 'PE2612208BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Baixio dos Milagres I, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de telha, tijolos e lajotas, de faianças finas importadas, grés, vidros e cerâmicas de produç�',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.076899, -7.963504] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37444.0,
        identifica: 'Flecheira Velha',
        co_iphan: 'PE2610905BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Flecheira Velha, localizado(a) no estado de Pernambuco, cidade(s) de Pesqueira, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas e artefatos em quartzito e emprego da tecnologia unipolar e um afloramento rochoso com marca de polimento po',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.928756, -8.403957] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37445.0,
        identifica: 'Climério',
        co_iphan: 'PE2610905BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Climério, localizado(a) no estado de Pernambuco, cidade(s) de Pesqueira, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas e estilhas em quartzo, fragmentos de cerâmica de produção local/regional, vidro, faiança fina e materiais cons',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.87212, -8.400491] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37354.0,
        identifica: 'Baixio dos Milagres II',
        co_iphan: 'PE2612208BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Baixio dos Milagres II, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de faianças finas importadas, grês, vidros e cerâmica de produção local/regional, além de u',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.075239, -7.962691] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37980.0,
        identifica: 'Residência de Manoel Cavalcante',
        co_iphan: 'PE2603009BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Residência de Manoel Cavalcante, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.35851, -8.413252] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37981.0,
        identifica: 'Residência de Severino',
        co_iphan: 'PE2603009BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Residência de Severino, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.359271, -8.410302] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37355.0,
        identifica: 'Baixio dos Milagres III',
        co_iphan: 'PE2616100BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Baixio dos Milagres III, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta material lascado em silexito, quartzo e arenito, com predomínio de lascas unipolares e bipolares e núcle',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.07212, -7.962646] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37446.0,
        identifica: 'Impueira II',
        co_iphan: 'PE2610905BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Impueira II , localizado(a) no estado de Pernambuco, cidade(s) de Pesqueira, é um Bem Arqueológico, do tipo Sítio. O local apresenta uma unidade doméstica em adobe, fragmentos de cerâmica de produção local/regional, vidros, louças.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.78867, -8.392881] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37449.0,
        identifica: 'Impueira I',
        co_iphan: 'PE2610905BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Impueira I, localizado(a) no estado de Pernambuco, cidade(s) de Pesqueira, é um Bem Arqueológico, do tipo Sítio. O local apresenta uma indústria lítica sobre lascas unipolares e bipolares em quartzo leitoso e quartzito obtidas através de seixos.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.786245, -8.391566] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37982.0,
        identifica: 'Tanque de Severino',
        co_iphan: 'PE2603009BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Tanque de Severino, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.358989, -8.40995] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37983.0,
        identifica: 'Jazida Pedreira',
        co_iphan: 'PE2603009BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Jazida Pedreira, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.269083, -8.494568] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37984.0,
        identifica: 'Oficina Lítica',
        co_iphan: 'PE2603009BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Oficina Lítica, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.456463, -8.52397] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37985.0,
        identifica: 'Serra do Livramento II',
        co_iphan: 'PE2603009BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Serra do Livramento II, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-39.31239, -8.215073] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37999.0,
        identifica: 'Aeroporto',
        co_iphan: 'PE2614105BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Aeroporto, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.327095, -8.053577] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38000.0,
        identifica: 'Rabo de Raposa',
        co_iphan: 'PE2614105BAST00024',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Rabo de Raposa, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.325257, -8.052683] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38001.0,
        identifica: 'Meio do Salão',
        co_iphan: 'PE2614105BAST00025',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Meio do Salão, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.267603, -8.031865] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38002.0,
        identifica: 'Meio do Eixo',
        co_iphan: 'PE2614105BAST00026',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Meio do Eixo, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.274328, -8.033232] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38003.0,
        identifica: 'Queimada Velha',
        co_iphan: 'PE2605103BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Queimada Velha, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.693038, -8.247635] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38004.0,
        identifica: 'Umbuzeiro',
        co_iphan: 'PE2605103BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Umbuzeiro, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.663348, -8.238603] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38005.0,
        identifica: 'Pereiro',
        co_iphan: 'PE2605103BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pereiro, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.661289, -8.237836] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38006.0,
        identifica: 'Fogaréu',
        co_iphan: 'PE2605103BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Fogaréu, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.655648, -8.236479] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38007.0,
        identifica: 'Areias',
        co_iphan: 'PE2605707BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Areias, localizado(a) no estado de Pernambuco, cidade(s) de Floresta, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-38.298575, -8.713624] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38008.0,
        identifica: 'Quixabeira',
        co_iphan: 'PE2605707BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Quixabeira, localizado(a) no estado de Pernambuco, cidade(s) de Floresta, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-38.248807, -8.671589] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38009.0,
        identifica: 'Braúnas',
        co_iphan: 'PE2605707BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Braúnas, localizado(a) no estado de Pernambuco, cidade(s) de Floresta, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-38.26959, -8.683089] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 9475.0,
        identifica: 'Canudama',
        co_iphan: 'PE2601102BAST00001',
        no_logrado:
          'Lagoa do Barro, próximo ao Riacho São Pedro, no município de\nAraripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.37325, -7.774278] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 9825.0,
        identifica: 'Cedro',
        co_iphan: 'PE2612208BAST00002',
        no_logrado: 'Pau Ferro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.094452, -7.860233] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 9828.0,
        identifica: 'Sêo Joza Tozinho Leite',
        co_iphan: 'CE2310605BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.087934, -7.835178] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 9875.0,
        identifica: 'Engenho do Meio',
        co_iphan: 'PE2611606BAST00002',
        no_logrado: 'Situado no campus da Universidade Federal de Pernambuco',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.955732, -8.056359] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46224.0,
        identifica: 'Caldeirão do Letreiro',
        co_iphan: 'PE2613909BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico, em situação topográfica de média vertente, contendo pinturas rupestres posicionadas na área central do sítio',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-38.562949, -8.169794] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46231.0,
        identifica: 'Caldeirão I',
        co_iphan: 'PE2604106BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado sobre embasamento rochoso, em situação topográfica de baixa vertente, contendo uma área abrigada situada no setor leste do sítio, onde jazem pinturas rupestres.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-36.077679, -8.074143] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10122.0,
        identifica: 'Divisa',
        co_iphan: 'PE2612208BAST00003',
        no_logrado: 'Perto da divisa entre Ceara e Pernambuco',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.089154, -7.859876] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46232.0,
        identifica: 'Caldeirão II',
        co_iphan: 'PE2604106BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres no setor sul do matacão.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-36.077318, -8.072641] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46233.0,
        identifica: 'Canafist',
        co_iphan: 'PE2601904BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra Negra, contendo no setor noroeste uma área abrigada, no entanto, as pinturas rupestres estão posicionadas nas fácies externas do matacão, no setor desabrigado.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-35.799015, -8.170639] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46937.0,
        identifica: 'Sítio do Moleque II',
        co_iphan: 'PE2602605BAST00058',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente. No setor norte do matacão há uma área abrigada com presença de pinturas rupestres.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-36.146787, -8.196337] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46235.0,
        identifica: 'Canyon do Salgado',
        co_iphan: 'PE2606606BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, situado no leito do Riacho do Salgado, contendo gravuras rupestres posicionadas nos setores norte e oeste do sítio.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.455789, -8.501791] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46236.0,
        identifica: 'Carrapicho',
        co_iphan: 'PE2612505BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-36.342262, -7.918819] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18715.0,
        identifica: 'Alcobaça',
        co_iphan: 'PE2602803BAST00013',
        no_logrado: 'Carneiro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica situado na média vertente da Serra do Coqueiro. São\r\npinturas e gravuras rupestres posicionadas no interior da área abrigada',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.19417, -8.540002] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46197.0,
        identifica: 'Baixão do Luís',
        co_iphan: 'PE2615805BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na baixa vertente de uma formação arenítica, de aspecto ruiniforme, posicionado no fundo de um vale aberto. São pinturas rupestres posicionadas na área central do abrigo.',
        dt_cadastr: '2023-07-10',
      },
      geometry: { type: 'Point', coordinates: [-37.257881, -8.490739] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46198.0,
        identifica: 'Balá',
        co_iphan: 'PE2608701BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente, contendo pinturas\r\nrupestres posicionadas nos setores sudeste e noroeste do sítio',
        dt_cadastr: '2023-07-10',
      },
      geometry: { type: 'Point', coordinates: [-35.950976, -8.643412] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46199.0,
        identifica: 'Bartolo',
        co_iphan: 'PE2601706BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de alta vertente, contendo pinturas\r\nrupestres posicionadas nos setores oeste e sul do sítio.',
        dt_cadastr: '2023-07-10',
      },
      geometry: { type: 'Point', coordinates: [-36.459079, -8.217006] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46200.0,
        identifica: 'Benedito',
        co_iphan: 'PE2615409BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente, contendo pinturas\r\nrupestres posicionadas nos setores sudeste e noroeste do sítio.',
        dt_cadastr: '2023-07-10',
      },
      geometry: { type: 'Point', coordinates: [-36.074762, -7.994287] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46201.0,
        identifica: 'Boa Vista',
        co_iphan: 'PE2604106BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente, contendo pinturas\r\nrupestres posicionadas nos setores sudoeste e leste do sítio',
        dt_cadastr: '2023-07-10',
      },
      geometry: { type: 'Point', coordinates: [-36.069768, -8.070021] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18765.0,
        identifica: 'Boi branco',
        co_iphan: 'PE2606507BAST00001',
        no_logrado: 'assentamento boi branco',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um conjunto de matacões graníticos posicionado no leito do Rio\r\nPedra Pintada. O conjunto gráfico contido nestes matacões é composto por\r\npinturas e gravuras rupestres, situadas nos setores sudeste, sul, oeste e nordeste\r\ndo sítio.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.864706, -8.921706] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46205.0,
        identifica: 'Brejinho I',
        co_iphan: 'PE2604106BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de alta vertente,  contendo pinturas rupestres.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-35.974861, -8.110315] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10991.0,
        identifica: 'Pedra do Gelo',
        co_iphan: 'PE2602605BAST00016',
        no_logrado: 'Sítio Lagoa do Navio (nome da propriedade)',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.284061, -8.080367] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10993.0,
        identifica: 'São Paulo',
        co_iphan: 'PE2602605BAST00018',
        no_logrado: 'São Paulo dos Caindins',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.270661, -8.095204] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10994.0,
        identifica: 'Pedra do Bojo',
        co_iphan: 'PE2602605BAST00019',
        no_logrado: 'Distrito de Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.237915, -8.168929] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10995.0,
        identifica: 'Pedra do Riacho do Pedro',
        co_iphan: 'PE2602605BAST00020',
        no_logrado: 'Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.225151, -8.134273] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10996.0,
        identifica: 'Torreiro',
        co_iphan: 'PE2602605BAST00021',
        no_logrado: 'Distrito de Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.224177, -8.130923] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10997.0,
        identifica: 'Pedra da Concha',
        co_iphan: 'PE2602605BAST00022',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.223594, -8.131245] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11000.0,
        identifica: 'Abrigo do Louro',
        co_iphan: 'PE2602605BAST00025',
        no_logrado: 'Distrito de Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.187039, -8.14245] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46207.0,
        identifica: 'Cachoeira',
        co_iphan: 'PE2605103BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio sobre um afloramento rochoso posicionado no leito do Riacho Cachoeira, contendo gravuras rupestres localizadas no setor sudeste do sítio.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.855412, -8.340115] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46939.0,
        identifica: 'Sítio do Poço',
        co_iphan: 'PE2601904BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico localizado na média vertente da Serra Negra. Nos setores sul e oeste do sítio há uma área abrigada onde foram identificadas as pinturas rupestres.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-35.797606, -8.16893] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11001.0,
        identifica: 'Pedra do Mocó',
        co_iphan: 'PE2602605BAST00026',
        no_logrado: 'Fazenda Incó (Fazenda Nova)',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.157306, -8.204275] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11002.0,
        identifica: 'Pedra da Guiné',
        co_iphan: 'PE2602605BAST00027',
        no_logrado: 'Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.146016, -8.190246] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11004.0,
        identifica: 'Pedra da Caveira',
        co_iphan: 'PE2604106BAST00002',
        no_logrado: 'Sítio Carneirinho',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.111878, -8.130683] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46208.0,
        identifica: 'Cachoeira da Dedé',
        co_iphan: 'PE2613602BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio a céu aberto, situado na margem do Riacho das Batatas, contendo grafismos rupestre. O conjunto gráfico é composto por gravuras distribuídas sobre as fácies dos blocos, matacões e afloramentos rochosos.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.349766, -7.450819] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46209.0,
        identifica: 'Cachoeira do Eustorge',
        co_iphan: 'PE2612802BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Blocos de gnaisse sobrepostos, a céu aberto, em situação topográfica de fundo de vale, contendo gravuras rupestres, posicionadas no setor leste do sítio.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.425575, -7.458176] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10979.0,
        identifica: 'Abrigo 2',
        co_iphan: 'PE2602605BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.428565, -8.146782] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10980.0,
        identifica: 'Pedra do Brás (Pedra do Letreiro)',
        co_iphan: 'PE2602605BAST00005',
        no_logrado: 'Sítio Açudinho',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.424764, -8.09711] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10981.0,
        identifica: 'Pedra Grande',
        co_iphan: 'PE2602605BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.407891, -8.135065] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10982.0,
        identifica: 'Pedra do Marmeleiro',
        co_iphan: 'PE2602605BAST00007',
        no_logrado: 'Tambor de Baixo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.395844, -8.053822] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10983.0,
        identifica: 'Pedra do Mulungu',
        co_iphan: 'PE2602605BAST00008',
        no_logrado: 'Tambor de Baixo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.395574, -8.054905] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46689.0,
        identifica: 'Pedra do Letreiro do Angico II',
        co_iphan: 'PE2605707BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico contendo pinturas rupestres, posicionadas nos setores nordeste e noroeste.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-38.447345, -8.678929] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10985.0,
        identifica: 'Pocinho Dantas',
        co_iphan: 'PE2602605BAST00010',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.354257, -8.189226] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10986.0,
        identifica: 'Pedra do Caboclo',
        co_iphan: 'PE2602605BAST00011',
        no_logrado: 'Zona brejeira. Tabocas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.350593, -8.164551] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10987.0,
        identifica: 'Pedra da Lenha',
        co_iphan: 'PE2602605BAST00012',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.343616, -8.121023] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46210.0,
        identifica: 'Cachoeira dos Chorões',
        co_iphan: 'PE2612802BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio, a céu aberto, situado à margem do Rio Cascudo, contendo gravuras rupestres posicionadas no setor oeste do sítio.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.462008, -7.444125] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10989.0,
        identifica: 'Pedra do Caboclo',
        co_iphan: 'PE2602605BAST00014',
        no_logrado: 'Sítio Urubu',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.301809, -8.104104] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10990.0,
        identifica: 'Pedra do Marimbondo',
        co_iphan: 'PE2602605BAST00015',
        no_logrado: 'Sítio Umbuzeiro - Fazenda Pé de Serra',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.293712, -8.10143] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10973.0,
        identifica: 'BALSAMO 07',
        co_iphan: 'PE2602100BAST00001',
        no_logrado: 'BALSAMO',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.654489, -9.302191] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10974.0,
        identifica: 'BALSAMO 09',
        co_iphan: 'PE2602100BAST00002',
        no_logrado: 'BALSAMO',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.647175, -9.296531] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10975.0,
        identifica: 'BALSAMO 04',
        co_iphan: 'PE2602100BAST00003',
        no_logrado: 'BALSAMO',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.644034, -9.30365] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10976.0,
        identifica: 'BALSAMO 05',
        co_iphan: 'PE2602100BAST00004',
        no_logrado: 'BALSAMO',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.642889, -9.304772] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10977.0,
        identifica: 'Pedra do Caboclo',
        co_iphan: 'PE2602605BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.429228, -8.146641] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10978.0,
        identifica: 'Matacão 3',
        co_iphan: 'PE2602605BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.429137, -8.146641] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46546.0,
        identifica: 'Papai Valério',
        co_iphan: 'PE2611101BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Papai Valério, localizado(a) no estado de Pernambuco, cidade(s) de Petrolina, é um Bem Arqueológico, do tipo Sítio. Suporte rochoso a céu aberto medindo 10 metros de comprimento por 1,40 metros de altura, repleto de Gravuras Rupestres do tipo Cúpul',
        dt_cadastr: '2023-07-30',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.769057976809002, -8.835415649469505],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11018.0,
        identifica: 'Furna do Nego',
        co_iphan: 'PE2608008BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.573302, -8.03642] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11025.0,
        identifica: 'BALSAMO 02',
        co_iphan: 'AL2706307BAST00002',
        no_logrado: 'BALSAMO',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.641754, -9.317977] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11037.0,
        identifica: 'Abrigo do Neto II',
        co_iphan: 'PE2602605BAST00031',
        no_logrado: 'Distrito de Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.153056, -8.194858] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11036.0,
        identifica: 'Abrigo do Neto III',
        co_iphan: 'PE2602605BAST00030',
        no_logrado: 'Distrito de Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.150217, -8.196338] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11038.0,
        identifica: 'Pedra do Letreiro',
        co_iphan: 'PE2602605BAST00032',
        no_logrado: 'Tambor de Baixo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.367653, -8.059886] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11039.0,
        identifica: 'Pedra do Letreiro',
        co_iphan: 'PE2602605BAST00033',
        no_logrado: 'Sítio Lagoa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.394234, -8.147529] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11040.0,
        identifica: 'Aristo I',
        co_iphan: 'PE2602605BAST00034',
        no_logrado: 'Distrito de Fazenda Nova',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.166597, -8.204864] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11041.0,
        identifica: 'Pedra da Bicuda 2',
        co_iphan: 'PE2602605BAST00035',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.32274, -8.09939] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46211.0,
        identifica: 'Cachoeira I',
        co_iphan: 'PE2608404BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de alta vertente, contendo área abrigada no setor norte do sítio. As pinturas rupestres jazem nos setores leste e oeste.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-36.17878, -8.680953] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46213.0,
        identifica: 'Cachoeira II',
        co_iphan: 'PE2608404BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento de rocha granítica contendo gravuras rupestres.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-36.17732, -8.680789] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18713.0,
        identifica: 'Casa de Farinha',
        co_iphan: 'PE2602803BAST00011',
        no_logrado: 'Serra Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizado dentro do Canion do riacho Catimbau, com pituras da Tradição Agreste. Devido ao seu ambiente privilegiado, foi ocupado, na decáda de 50, por uma família local. No sítio ainda encontra-se as ruínas de uma casade farinha.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.232229, -8.557085] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46215.0,
        identifica: 'Cacimba',
        co_iphan: 'PE2613909BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica localizado na média vertente do Serrote Pelado, contém pinturas rupestres posicionadas nos setores noroeste e sudeste do sítio.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-38.457974, -8.046029] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14145.0,
        identifica: 'Capim Grosso V',
        co_iphan: 'PE2609907BAST00014',
        no_logrado: 'Córrego Capim Grosso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.077159, -7.928773] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46237.0,
        identifica: 'Casa de Pedra',
        co_iphan: 'PE2601805BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra do Tamburil, contendo pinturas rupestres posicionadas nos setores sudeste e sul do sítio.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.910415, -8.076279] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46216.0,
        identifica: 'Cafundó',
        co_iphan: 'PE2605608BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, em situação topográfica de média vertente da Serra da Lagoinha, contendo pinturas rupestres posicionadas no setor leste do sítio.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.748571, -7.982433] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46217.0,
        identifica: 'Cafundó de Francisco Laurindo',
        co_iphan: 'PE2605608BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na baixa vertente da Serra da Lagoinha, contendo pinturas rupestres posicionadas a oeste do sítio',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.748281, -7.985236] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46941.0,
        identifica: 'Sítio do Sal',
        co_iphan: 'PE2615805BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha situado na média vertente da Serra dos Breus. São pinturas rupestres posicionadas nos setores sudoeste e nordeste do sítio.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-37.280003, -8.486895] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46218.0,
        identifica: 'Cafundó de José Luis',
        co_iphan: 'PE2605608BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica localizado na média vertente da Serra da Lagoinha, contendo pinturas e gravuras rupestres posicionadas no setor oeste do sítio.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.752332, -7.986686] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46219.0,
        identifica: 'Caiana',
        co_iphan: 'PE2602803BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na alta vertente da Serra Branca, contendo pinturas rupestres posicionadas no teto da área abrigada e  por toda extensão do suporte rochoso.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.230787, -8.548869] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46238.0,
        identifica: 'Casa de Pedra',
        co_iphan: 'PE2612208BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está situado em um matacão granítico, posicionado sobre um conjunto de blocos sobrepostos formando uma área abrigada, com abertura leste / oeste',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-38.912731, -8.290804] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46220.0,
        identifica: 'Caldeirão da Serra Branca I',
        co_iphan: 'PE2602803BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, situado na margem sul do Riacho da Serra Branca, contendo pinturas rupestres posicionadas no setor noroeste do sítio, numa área não abrigada.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.254443, -8.543976] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46239.0,
        identifica: 'Casa do Índio',
        co_iphan: 'PE2603900BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um conjunto pinturas rupestres posicionado no interior de uma gruta na média vertente da Serra da Matinha, mais especificamente no setor sudeste do sítio.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.754628, -7.721599] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46221.0,
        identifica: 'Caldeirão da Serra Branca II',
        co_iphan: 'PE2602803BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas no setor noroeste do sítio, em uma área não abrigada.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.253854, -8.543513] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46240.0,
        identifica: 'Catolezinho',
        co_iphan: 'PE2610400BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio posicionado num afloramento granítico localizado na margem esquerda do riacho; contém gravuras localizadas na porção oeste do afloramento.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-39.55021, -8.217947] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46241.0,
        identifica: 'Caverna dos Caboclos',
        co_iphan: 'PE2602605BAST00042',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica localizado na média vertente da Serra Jaracatiá, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-36.335679, -8.133429] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46694.0,
        identifica: 'Pedra do Letreiro do Azevém',
        co_iphan: 'PE2616001BAST00026',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente do Serrote do Buco, contendo no setor noroeste uma área abrigada, onde jazem pinturas rupestres. No setor sul do matacão, em uma área não abrigada, também há pinturas rupestres.',
        dt_cadastr: '2023-08-08',
      },
      geometry: { type: 'Point', coordinates: [-36.860638, -8.515462] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46243.0,
        identifica: 'Cipó',
        co_iphan: 'PE2608107BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na margem direita do Riacho Umari, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-35.661494, -7.845746] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46695.0,
        identifica: 'Pedra do Letreiro do Bom Sucesso',
        co_iphan: 'PE2607703BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres posicionadas nos setores sudeste e sudoeste.',
        dt_cadastr: '2023-08-08',
      },
      geometry: { type: 'Point', coordinates: [-37.207828, -7.355548] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46244.0,
        identifica: 'Croatá',
        co_iphan: 'PE2601904BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um matacão granítico em situação topográfica de média vertente, contendo no setor oeste uma área abrigada com pinturas rupestres.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-35.815373, -8.208177] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46222.0,
        identifica: 'Caldeirão da Velha',
        co_iphan: 'PE2606606BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha situado na baixa vertente, contendo registros rupestres. O conjunto gráfico é composto por pinturas e gravuras.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.533937, -8.502193] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46223.0,
        identifica: 'Caldeirão do Facheiro',
        co_iphan: 'PE2606606BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, situado no leito do Riacho do Salgado, contendo gravuras rupestres posicionadas nos setores sudeste e sudoeste.',
        dt_cadastr: '2023-07-11',
      },
      geometry: { type: 'Point', coordinates: [-37.533603, -8.501604] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46245.0,
        identifica: 'Cupim',
        co_iphan: 'PE2601904BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-35.732573, -8.138713] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46246.0,
        identifica: 'Dedos de Deus I',
        co_iphan: 'PE2602803BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha localizado na média vertente da Serra dos Coqueiros, contendo pinturas rupestres posicionadas nos setores sul e sudeste do sítio.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.191705, -8.536917] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46248.0,
        identifica: 'Eloia',
        co_iphan: 'PE2602803BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha localizado na alta vertente da Serra do Jerusalém, contendo pinturas rupestres posicionadas nos setores noroeste e sudeste do sítio.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.22917, -8.55472] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46249.0,
        identifica: 'Entrada do Cafundó',
        co_iphan: 'PE2605608BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na baixa vertente da Serra da Lagoinha, contendo pinturas rupestres posicionadas no setor leste.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.74917, -7.982363] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46250.0,
        identifica: 'Escadinha',
        co_iphan: 'PE2615805BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha situado na baixa vertente da Serra Branca, contendo pinturas rupestres posicionadas no setor norte do sítio.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.315158, -8.557482] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46696.0,
        identifica: 'Pedra do Letreiro do Caldeirão',
        co_iphan: 'PE2612554BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento de gnaisse, situado na calha do Riacho Caldeirão, onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-08',
      },
      geometry: { type: 'Point', coordinates: [-40.582077, -8.135281] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46251.0,
        identifica: 'Figueira',
        co_iphan: 'PE2610509BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico localizado na margem do Rio Capibaribe, contendo pinturas rupestres',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-35.606689, -7.93688] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46252.0,
        identifica: 'Fumaça',
        co_iphan: 'PE2602803BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica localizado na média vertente da Serra Branca, contendo pinturas rupestres posicionadas nos setores sudeste e sudoeste do sítio.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.246763, -8.527116] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46253.0,
        identifica: 'Furna da Lagoa do Pinheiro',
        co_iphan: 'PE2605608BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica em situação topográfica de baixa vertente, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.783382, -7.988063] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46299.0,
        identifica: 'Lajes I',
        co_iphan: 'PE2609907BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas e gravuras rupestres distribuídas por toda a extensão da área abrigada.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-40.022793, -7.95526] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7037.0,
        identifica: 'SJMI 06 Materialidade In Situ',
        co_iphan: 'PE2606309BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.5905096, -7.770819895] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46313.0,
        identifica: 'Lajes II',
        co_iphan: 'PE2609907BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre embasamento rochoso, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas na porção oeste do sítio.',
        dt_cadastr: '2023-07-18',
      },
      geometry: { type: 'Point', coordinates: [-40.022702, -7.95516] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18705.0,
        identifica: 'Furtuoso I',
        co_iphan: 'PE2602803BAST00003',
        no_logrado: 'Serra Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo situado na alta vertente da Serra da Cruz, contendo pinturas rupestres; os\r\ngrafismos estão posicionados no setor noroeste do sítio, na parte desabrigada. No\r\ninterior do abrigo não foram identificados registros rupestres.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.262016, -8.542338] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46314.0,
        identifica: 'Lajes III',
        co_iphan: 'PE2609907BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre um embasamento rochoso, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas no setor nordeste do sítio.',
        dt_cadastr: '2023-07-18',
      },
      geometry: { type: 'Point', coordinates: [-40.022675, -7.955178] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38880.0,
        identifica: 'Pedra do Sassafrás',
        co_iphan: 'PE2607703BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres posicionadas nos setores nordeste e norte.',
        dt_cadastr: '2022-03-11',
      },
      geometry: { type: 'Point', coordinates: [-37.266865, -7.340012] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46697.0,
        identifica: 'Pedra do Letreiro do Caruá',
        co_iphan: 'PE2614105BAST00032',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio localiza-se no topo do inselberg em um conjunto de matacões graníticos sobrepostos. As pinturas estão localizadas nos setores norte e sudeste do sítio, em dois matacões distintos.',
        dt_cadastr: '2023-08-08',
      },
      geometry: { type: 'Point', coordinates: [-37.285748, -8.166498] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37356.0,
        identifica: 'Juazeiro',
        co_iphan: 'PE2616100BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Juazeiro, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de faianças finas, grés, vidros e cerâmica de produção local/regional em profusão. Além de uma unidade',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.045078, -7.968023] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46424.0,
        identifica: 'Morro do Angu',
        co_iphan: 'PE2600500BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos sobrepostos, sem formar abrigos, em situação\r\ntopográfica de baixa vertente. O conjunto pictural é composto por pinturas\r\nrupestres situadas em quatro distintos matacões',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.015352, -8.970616] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46943.0,
        identifica: 'Sítio do Souza I',
        co_iphan: 'PE2610202BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de alta vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-35.974707, -8.67256] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46548.0,
        identifica: 'Sítio Pedra Letrada',
        co_iphan: 'PE2614105BAST00030',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio Pedra Letrada, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. Sitio de pintura rupestre de grafismos puros, formas geométricas, como círculos\r\nconcêntricos e retangulares.',
        dt_cadastr: '2023-07-30',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.286000627895, -8.166761663284609],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18704.0,
        identifica: 'Furtuoso II',
        co_iphan: 'PE2602803BAST00002',
        no_logrado: 'Serra Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente da Serra Branca, no setor sudoeste\r\nde um morrete testemunho, contendo gravuras rupestres. No local funcionava\r\numa antiga casa de farinha.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.262039, -8.547437] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46370.0,
        identifica: 'Lajes IV',
        co_iphan: 'PE2609907BAST00025',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas\r\nrupestres localizadas no setor noroeste da área abrigada',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-40.021732, -7.955298] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46698.0,
        identifica: 'Pedra do Letreiro do Cerecé',
        co_iphan: 'PE2614105BAST00033',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico localizado no topo do Serrote do Boqueirão, conhecida localmente como Serra do Cerecé. Na face oeste do matacão, onde as pinturas rupestres estão localizadas, há uma área abrigada com abertura voltada para o vale do Moxotó.',
        dt_cadastr: '2023-08-08',
      },
      geometry: { type: 'Point', coordinates: [-37.204106, -8.035915] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46371.0,
        identifica: 'Lajes V',
        co_iphan: 'PE2609907BAST00026',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado sobre embasamento rochoso, em situação topográfica\r\nde baixa vertente, contendo pinturas rupestres posicionadas no setor noroeste do\r\nsítio.',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-40.021613, -7.955064] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46727.0,
        identifica: 'Pedra do Letreiro do Poço Dantas',
        co_iphan: 'PE2602605BAST00052',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.35366, -8.188649] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46375.0,
        identifica: 'Lajes IX',
        co_iphan: 'PE2609907BAST00030',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre um afloramento rochoso, em situação\r\ntopográfica de baixa vertente, contendo pinturas rupestres posicionadas em uma\r\nárea abrigada, no setor leste do sítio',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-40.023103, -7.955792] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46376.0,
        identifica: 'Lamarão',
        co_iphan: 'PE2613107BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas\r\nrupestres.',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-36.120897, -8.348843] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 44014.0,
        identifica: 'PE 0743 LA/UFPE -  Pedra dos Mocós',
        co_iphan: 'PE2603306BAST00001',
        no_logrado: 'sítio dos Mocós',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'PE 0743 LA/UFPE -  Pedra dos Mocós, também conhecido(a) como Pedra dos Mocós, localizado(a) no estado de Pernambuco, cidade(s) de Calçado, é um Bem Arqueológico, do tipo Sítio. Afloramento rochoso localizado no sítio dos Mocós, no município de',
        dt_cadastr: '2022-12-19',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.269287308952002, -8.7661474316082],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 43952.0,
        identifica: 'PE 0742 LA/UFPE - Serra do Zumba',
        co_iphan: 'PE2613107BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'PE 0742 LA/UFPE - Serra do Zumba, também conhecido(a) como Serra do Zumba, localizado(a) no estado de Pernambuco, cidade(s) de São Caitano, é um Bem Arqueológico, do tipo Sítio. Abrigo sob rocha granítica identificado em área de topo em local deno',
        dt_cadastr: '2022-12-14',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.191827877213001, -8.286406394188411],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46728.0,
        identifica: 'Pedra do Letreiro do Tambor de Baixo',
        co_iphan: 'PE2602605BAST00053',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de alta vertente, contendo no setor nordeste uma área abrigada, onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.364659, -8.079436] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37357.0,
        identifica: 'São José',
        co_iphan: 'PE2616100BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'São José, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta material lascado em silexito, quartzo e arenito em profusão.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.043953, -7.967263] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46377.0,
        identifica: 'Letras',
        co_iphan: 'PE2613107BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio localizado em um afloramento de gnaisse, posicionado na baixa vertente da\r\nSerra das Letras; contendo gravuras rupestres localizadas nos setores nordeste e\r\nsudeste do sítio',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-36.155902, -8.243051] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46378.0,
        identifica: 'Letreiro Cacimba Nova I',
        co_iphan: 'PE2605103BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio posicionado num afloramento rochoso de gnaisse, em situação topográfica\r\nde fundo de vale, contendo gravuras rupestres situadas na porção sul.',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-37.872584, -8.365079] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46730.0,
        identifica: 'Pedra do Letreiro do Tambor de Cima',
        co_iphan: 'PE2602605BAST00054',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, localizado na média vertente da Serra Maria Nogueira, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.395374, -8.054539] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46379.0,
        identifica: 'Letreiro Cacimba Nova II',
        co_iphan: 'PE2605103BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio posicionado num afloramento rochoso, de gnaisse, em meio ao plaino aluvial\r\ndo Riacho Cacimba Nova, contendo gravuras rupestres localizadas no setor\r\nsudeste do sítio.',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-37.872614, -8.364193] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46254.0,
        identifica: 'Furna da Onça',
        co_iphan: 'PE2601201BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico posicionado sobre embasamento rochoso formando quatro áreas abrigadas. As pinturas rupestres estão posicionadas nos setores leste e norte do matacão.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.076443, -8.377802] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46257.0,
        identifica: 'Furna da Serra do Barreiro',
        co_iphan: 'PE2606606BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Gruta situada na baixa vertente da Serra do Barreiro, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.395556, -8.546983] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46258.0,
        identifica: 'Furna do Caetano Gomes',
        co_iphan: 'PE2613107BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Gruta, em situação topográfica de alta vertente, contendo pinturas rupestres localizadas em toda extensão do teto.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-36.113917, -8.386348] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46259.0,
        identifica: 'Furna do Campos',
        co_iphan: 'PE2606606BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Gruta situada na alta vertente da Serra da Cruz, contendo pinturas posicionadas no setor noroeste do sítio. No interior da gruta não foram identificados, macroscopicamente, registros rupestres.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.542479, -8.501348] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46732.0,
        identifica: 'Pedra do Letreiro dos Campos',
        co_iphan: 'PE2603405BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra dos Campos, contendo uma área abrigada, onde jazem pinturas rupestres posicionadas nos setores leste, sudeste e sudoeste.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-38.09847, -7.970337] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46734.0,
        identifica: 'Pedra do Letreiro dos Fenícios',
        co_iphan: 'PE2601201BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado sobre embasamento rochoso, contendo uma diminuta área abrigada, com orientação Leste/Oeste, onde jazem pinturas rupestres. As pinturas rupestres estão posicionadas nos setores oeste e leste do matacão.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-37.037851, -8.332826] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46261.0,
        identifica: 'Furna do Icó',
        co_iphan: 'PE2605608BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está posicionado em um testemunho arenítico, de aspecto ruiniforme; a base deste testemunho contém uma área abrigada onde estão posicionadas as pinturas rupestres.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.759281, -7.983695] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46262.0,
        identifica: 'Furna do Lajeiro Liso',
        co_iphan: 'PE2603207BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica em situação topográfica de média vertente, contendo pinturas rupestres localizadas na área central do abrigo.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-36.772288, -8.810522] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46737.0,
        identifica: 'Pedra do Logradouro',
        co_iphan: 'PE2606309BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento de micaxisto situado no leito do riacho Logradouro. São gravuras rupestres posicionadas a noroeste do sítio.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-39.586847, -7.814901] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46264.0,
        identifica: 'Furna do Letreiro da Mina Grande',
        co_iphan: 'PE2615805BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio a céu aberto, situado na baixa vertente da Serra da Mina Grande, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.30152, -8.635902] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46739.0,
        identifica: 'Pedra do Luiz',
        co_iphan: 'PE2603900BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de Matacões graníticos sobrepostos formando uma pequena área abrigada, no setor norte do sítio. O conjunto de matacões está situado na baixa vertente da Serra Branca.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-37.712628, -7.83601] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46284.0,
        identifica: 'Gama',
        co_iphan: 'PE2613503BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Embasamento rochoso de gnaisse, posicionado no leito (setor norte) do riacho do Gama. No entorno verificam-se conjuntos de matacões e afloramentos de gnaisse. Há a presença de um caldeirão no setor oeste do sítio.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-38.585599, -7.973173] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46266.0,
        identifica: 'Furna do Minério',
        co_iphan: 'PE2600104BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na média vertente da Serra da Furna do Minério, contendo pinturas rupestres, posicionadas nos setores nordeste e sudoeste.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.637334, -7.843794] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46267.0,
        identifica: 'Furna do Morcego',
        co_iphan: 'PE2615805BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Caverna, situada na baixa vertente da Serra do Quiri D?Álho, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.381941, -8.570308] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46285.0,
        identifica: 'Gameleira',
        co_iphan: 'PE2602803BAST00026',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizado na alta vertrente da Serra do Jerusalém, contendo pinturas rupestres posicionadas no setor sudeste.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.231219, -8.553681] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46740.0,
        identifica: 'Pedra do Meio I',
        co_iphan: 'PE2608008BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre embasamento rochoso, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas nos setores leste e oeste.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.567669, -8.025647] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46268.0,
        identifica: 'Furna do Rebeiro',
        co_iphan: 'PE2603207BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação média vertente da Serra do Rebeiro, contendo pinturas rupestres localizadas no setor nordeste.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-36.752874, -8.802353] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46286.0,
        identifica: 'Geraldo I',
        co_iphan: 'PE2600609BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente, contendo pinturas rupestres posicionadas no setor norte do sítio.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-36.701391, -8.44493] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46269.0,
        identifica: 'Furna dos Veados',
        co_iphan: 'PE2606606BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha situado na baixa vertente da Serra do Barreiro, contendo pinturas e gravuras rupestres',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.402337, -8.550094] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46741.0,
        identifica: 'Pedra do Meio II',
        co_iphan: 'PE2608008BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'MAtacão granítico, em situação de baixa vertente, contendo pinturas rupestres posicionada no setor sul.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.567371, -8.024009] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46270.0,
        identifica: 'Furna Grande',
        co_iphan: 'PE2605608BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado no leito do Riacho Cafundó, contendo pinturas rupestres posicionadas no setor leste.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.738904, -7.981147] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46271.0,
        identifica: 'Furna Preta I',
        co_iphan: 'PE2615805BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na baixa vertente da Grota da Serra Verde, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.310133, -8.580417] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46742.0,
        identifica: 'Pedra do Meio III',
        co_iphan: 'PE2608008BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas nos setores norte, nordeste e sudeste.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.567749, -8.02299] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46273.0,
        identifica: 'Furna Preta II',
        co_iphan: 'PE2615805BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na baixa vertente da Serra Furna Preta. O conjunto pictural é composto por pinturas rupestres posicionados no setor nordeste.',
        dt_cadastr: '2023-07-13',
      },
      geometry: { type: 'Point', coordinates: [-37.310923, -8.580456] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38543.0,
        identifica: 'Carnaíba',
        co_iphan: 'PE2616100BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Carnaíba, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-39.078253, -7.970713] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46744.0,
        identifica: 'Pedra do Meio IV',
        co_iphan: 'PE2608008BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre um embasamento rochoso, em situação topográfica de baixa vertente. As pinturas estão posicionadas nos setores leste e nordeste do matacão.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.569389, -8.02469] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38544.0,
        identifica: 'Mamoeiro II',
        co_iphan: 'PE2616100BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Mamoeiro II, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-38.933659, -7.9828] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46287.0,
        identifica: 'Geraldo II',
        co_iphan: 'PE2600609BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente, contendo pinturas rupestres posicionadas no setor norte do sítio.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-36.701599, -8.445121] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38545.0,
        identifica: 'Carcará I',
        co_iphan: 'PE2616100BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Carcará I, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-39.037266, -7.965328] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46745.0,
        identifica: 'Pedra do Meio V',
        co_iphan: 'PE2608008BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas no setor sudeste.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.569511, -8.025459] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46288.0,
        identifica: 'Gravura do Jatobazinho',
        co_iphan: 'PE2613503BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Bloco de gnaisse, localizado no leito do Riacho do Jatobazinho, contendo gravuras rupestres posicionadas na base do bloco, voltadas para Leste.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-38.584991, -7.946073] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38546.0,
        identifica: 'Barreiro',
        co_iphan: 'PE2613503BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Barreiro, localizado(a) no estado de Pernambuco, cidade(s) de São José do Belmonte, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-38.725959, -7.975696] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38547.0,
        identifica: 'Icó',
        co_iphan: 'PE2616100BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Icó, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-39.057709, -7.966809] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38548.0,
        identifica: 'João Gouveia',
        co_iphan: 'PE2616100BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'João Gouveia, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-38.906805, -8.001682] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46747.0,
        identifica: 'Pedra do Meio VI',
        co_iphan: 'PE2608008BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Bloco granítico, com circunferência de 7,35m, localizado sobre o embasamento rochoso, contendo pinturas rupestres posicionadas no setor sudeste do bloco rochoso.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.569511, -8.025459] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38550.0,
        identifica: 'Fazenda Barreiro',
        co_iphan: 'PE2616100BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Fazenda Barreiro, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-38.879125, -8.009843] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14138.0,
        identifica: 'Grês',
        co_iphan: 'PE2609907BAST00007',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.241087, -7.902724] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46749.0,
        identifica: 'Pedra do Milho',
        co_iphan: 'PE2607703BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de alta vertente, contendo pinturas rupestres posicionadas nos setores leste e nordeste.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-37.266487, -7.339612] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46289.0,
        identifica: 'Grota do Caldeirão',
        co_iphan: 'PE2605608BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na baixa vertente do Cânion, no leito do Riacho do Cafundó, contendo gravuras rupestres.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.738687, -7.980893] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46290.0,
        identifica: 'Homem sem Cabeça',
        co_iphan: 'PE2602803BAST00027',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado na média vertente da Serra Branca, contendo pinturas rupestres num setor desabrigado.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.244466, -8.524818] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14170.0,
        identifica: 'Quixaba I',
        co_iphan: 'PE2610400BAST00001',
        no_logrado: 'Fazenda Quixaba',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.767749, -8.02148] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46291.0,
        identifica: 'Iguana',
        co_iphan: 'PE2602803BAST00028',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente da Serra do Catimbau, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.213083, -8.55489] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38551.0,
        identifica: 'Crauaçu II',
        co_iphan: 'PE2607208BAST00054',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Crauaçu II, localizado(a) no estado de Pernambuco, cidade(s) de Ipojuca, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-35.113738, -8.378791] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38552.0,
        identifica: 'Crauaçu I',
        co_iphan: 'PE2607208BAST00055',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Crauaçu I, localizado(a) no estado de Pernambuco, cidade(s) de Ipojuca, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-22',
      },
      geometry: { type: 'Point', coordinates: [-35.109136, -8.38724] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46292.0,
        identifica: 'Imbauba',
        co_iphan: 'PE2616209BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, em situação topográfica de alta vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-36.007238, -7.911552] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46293.0,
        identifica: 'Jatobá',
        co_iphan: 'PE2601904BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra Negra, contendo no setor nordeste uma área abrigada, onde jazem as pinturas rupestres.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-35.78902, -8.162615] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11003.0,
        identifica: 'Pedra do Moinho',
        co_iphan: 'PE2602605BAST00028',
        no_logrado: 'Sítio Saco da Serra (Pedra do Cachorro)',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo uma furna formada a partir da queda de blocos, com abertura para norte / leste, com presença de pinturas rupestres.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.189271, -8.226071] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46294.0,
        identifica: 'Lagoa dos Patos',
        co_iphan: 'PE2614105BAST00027',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo posicionado no topo do Serrote Mãe D`Água, contendo grafismos pintados e gravados nos setores noroeste, nordeste e sudoeste.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.110307, -8.229463] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14441.0,
        identifica: 'Pedra de Antônio Raimundo',
        co_iphan: 'PE2609808BAST00001',
        no_logrado: 'Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.574495, -8.290958] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14442.0,
        identifica: 'Saco da Canoa',
        co_iphan: 'PE2610400BAST00012',
        no_logrado: 'Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.933656, -8.275402] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46295.0,
        identifica: 'Laje das Onças',
        co_iphan: 'PE2605103BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio a céu aberto posicionado num embasamento rochoso de gnaisse. Contém registros rupestres gravados e pintados. As gravuras estão posicionadas nos setores norte, noroeste e oeste do sítio; e as pinturas estão localizadas no setor sudeste do sít',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.753552, -8.284752] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46296.0,
        identifica: 'Lajedo da Mimita',
        co_iphan: 'PE2601201BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento rochoso de granito, posicionado na margem sudeste da Lagoa Preta. As gravuras estão posicionadas no setor noroeste do afloramento. Nos períodos de enchente as gravuras ficam submersas',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.082589, -8.379739] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14518.0,
        identifica: 'PE 0481 LA/UFPE',
        co_iphan: 'PE2605202BAST00001',
        no_logrado: 'BR-101 Norte, Km 135',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.294432, -8.410955] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14529.0,
        identifica: 'PE 0418 LA/UFPE',
        co_iphan: 'PE2606200BAST00001',
        no_logrado: 'BR-101 Norte (PE), Km 17',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.951261, -7.646708] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46752.0,
        identifica: 'Pedra do Morcego',
        co_iphan: 'PE2606507BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo localizado na calha do Rio Garanhusinho, contendo pinturas rupestres no setor noroeste. No setor norte há uma diminuta área abrigada, no entanto a análise macroscópica deste espaço não revelou a existência de registro rupestre.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.955966, -9.215237] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46297.0,
        identifica: 'Lajedo da Serra Branca',
        co_iphan: 'PE2603900BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas nos setores noroeste e sudoeste.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-37.712632, -7.834934] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14443.0,
        identifica: 'Pedra do Velho Joaquim',
        co_iphan: 'PE2610400BAST00013',
        no_logrado: 'Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.666994, -8.288173] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14444.0,
        identifica: 'Pedra de Zé Candeia',
        co_iphan: 'PE2610400BAST00014',
        no_logrado: 'Sítio dos Elvira - Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.674495, -8.294013] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14445.0,
        identifica: 'Pedra da Letra',
        co_iphan: 'PE2610400BAST00015',
        no_logrado: 'Sítio Paula - Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.837548, -8.288177] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14448.0,
        identifica: 'Pedra da Abelha',
        co_iphan: 'PE2609808BAST00002',
        no_logrado: 'Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.668656, -8.38429] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14453.0,
        identifica: 'Pedra do Caboclo',
        co_iphan: 'PE2610806BAST00002',
        no_logrado: 'Fazenda Pedra do Caboclo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.885875, -8.712069] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14454.0,
        identifica: 'Poço da Figura',
        co_iphan: 'PE2603207BAST00001',
        no_logrado: 'Fazenda Cachoeirinha',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.680873, -8.744848] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14455.0,
        identifica: 'Prata',
        co_iphan: 'PE2610806BAST00003',
        no_logrado: 'Fazenda Prata',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.858651, -8.701793] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14458.0,
        identifica: 'Xilili',
        co_iphan: 'PE2614105BAST00001',
        no_logrado: 'Fazenda Xilili',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.262821, -8.25457] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14460.0,
        identifica: 'Letras',
        co_iphan: 'PE2615201BAST00004',
        no_logrado: 'Sítio Letras',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.461155, -8.22484] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46298.0,
        identifica: 'Lajeiro do Mapa do Índio',
        co_iphan: 'PE2605707BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está posicionado no leito do Riacho Timburana, sobre um afloramento arenítico; as gravuras estão distribuídas por todo o afloramento.',
        dt_cadastr: '2023-07-14',
      },
      geometry: { type: 'Point', coordinates: [-38.212968, -8.283082] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14496.0,
        identifica: 'Sítio Usina Timbó',
        co_iphan: 'PE2600054BAST00007',
        no_logrado: 'Timbó - Município de Abreu e Lima',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.905991, -7.916281] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14499.0,
        identifica: 'Sítio São Bento III',
        co_iphan: 'PE2600054BAST00010',
        no_logrado: 'São Bento',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.872533, -7.904661] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14505.0,
        identifica: 'PE 0508 LA/UFPE',
        co_iphan: 'PE2600708BAST00001',
        no_logrado: 'Comunidade de santa Luzia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.149296, -7.608903] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14510.0,
        identifica: 'PE60-69',
        co_iphan: 'PE2602902BAST00004',
        no_logrado: 'PROXIMO AO POVOADO DO ENGENHO TABATINGA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.046425, -8.35201] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14511.0,
        identifica: 'PE60-68',
        co_iphan: 'PE2602902BAST00005',
        no_logrado: 'POVOADO DO ENGENHO TABATINGA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.045112, -8.351005] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14512.0,
        identifica: 'PE60-84',
        co_iphan: 'PE2602902BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.024095, -8.355542] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14513.0,
        identifica: 'PE60-87',
        co_iphan: 'PE2602902BAST00007',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.024039, -8.360442] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14514.0,
        identifica: 'PE60-64',
        co_iphan: 'PE2602902BAST00008',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.022823, -8.356914] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14515.0,
        identifica: 'PE60-40',
        co_iphan: 'PE2602902BAST00009',
        no_logrado: 'MARGEM OPOSTA À REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.022739, -8.353] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14521.0,
        identifica: 'Engenho Muçu',
        co_iphan: 'PE2605202BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.300818, -8.39332] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14522.0,
        identifica: 'Engenho Firmeza',
        co_iphan: 'PE2605202BAST00005',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.256808, -8.376356] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14531.0,
        identifica: 'PE60-88',
        co_iphan: 'PE2607208BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.049843, -8.387745] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14532.0,
        identifica: 'PE60-48',
        co_iphan: 'PE2607208BAST00002',
        no_logrado: 'MARGEM OPOSTA A REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.040592, -8.366667] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14534.0,
        identifica: 'Usina Ipojuca',
        co_iphan: 'PE2607208BAST00004',
        no_logrado: 'Terras da Usina Ipojuca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.159073, -8.351882] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14537.0,
        identifica: 'PE60-33',
        co_iphan: 'PE2607208BAST00007',
        no_logrado: 'MARGEM OPOSTA À REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.053636, -8.382383] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14538.0,
        identifica: 'PE60-32',
        co_iphan: 'PE2607208BAST00008',
        no_logrado: 'MARGEM OPOSTA À REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.05324, -8.38987] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14539.0,
        identifica: 'PE60-50',
        co_iphan: 'PE2607208BAST00009',
        no_logrado: 'ÁREAS DE MORROS POSTERIORES AS MARGENS DA PE-60',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.052447, -8.382263] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14540.0,
        identifica: 'PE60-37',
        co_iphan: 'PE2607208BAST00010',
        no_logrado: 'MARGEM OPOSTA À REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.050525, -8.386268] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14541.0,
        identifica: 'PE60-75',
        co_iphan: 'PE2607208BAST00011',
        no_logrado:
          'MORROS POSTERIORES ÀS MARGENS DA PE60. MARGENS OPOSTAS À REFINARIA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.049288, -8.380688] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14542.0,
        identifica: 'PE60-38',
        co_iphan: 'PE2607208BAST00012',
        no_logrado: 'MARGEM OPOSTA À REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.049251, -8.378772] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14543.0,
        identifica: 'PE60-49',
        co_iphan: 'PE2607208BAST00013',
        no_logrado: 'MARGEM OPOSTA A REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.048194, -8.379663] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14544.0,
        identifica: 'PE60-36',
        co_iphan: 'PE2607208BAST00014',
        no_logrado: 'MARGEM OPOSTA À REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.046602, -8.391152] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14545.0,
        identifica: 'PE60-41',
        co_iphan: 'PE2607208BAST00015',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.046355, -8.383939] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14546.0,
        identifica: 'PE60-79',
        co_iphan: 'PE2607208BAST00016',
        no_logrado: 'PROXIMO AO RESTAURANTE PITUZADA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.045485, -8.38586] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14547.0,
        identifica: 'PE60-35',
        co_iphan: 'PE2607208BAST00017',
        no_logrado: 'MARGEM OPOSTA À REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.044194, -8.389067] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14548.0,
        identifica: 'PE60-70',
        co_iphan: 'PE2607208BAST00018',
        no_logrado: 'MORRO POSTERIOR A PE-60',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.043224, -8.363209] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14549.0,
        identifica: 'PE60-23',
        co_iphan: 'PE2607208BAST00019',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.042036, -8.392984] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14550.0,
        identifica: 'PE60-22',
        co_iphan: 'PE2607208BAST00020',
        no_logrado: 'ENTRE A RODOVIA PE60 E A AREA DA REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.039491, -8.38882] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14551.0,
        identifica: 'PE60-19',
        co_iphan: 'PE2607208BAST00021',
        no_logrado:
          'ÁREA DO ENGENHO PENDORAMA COM DIVISA COM A VILA CALIFORNIA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.039035, -8.3832] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14552.0,
        identifica: 'PE60-16',
        co_iphan: 'PE2607208BAST00022',
        no_logrado: 'ÁREA DO ENGENHO PENDORAMA DIVISA COM A VILA CALIFORNIA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.037892, -8.381687] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14553.0,
        identifica: 'PE60-25',
        co_iphan: 'PE2607208BAST00023',
        no_logrado: 'PRÓXIMO DA REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.037364, -8.393496] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14554.0,
        identifica: 'PE60-17',
        co_iphan: 'PE2607208BAST00024',
        no_logrado:
          'ÁREA DO ENGENHO PENDORAMA COM DIVISA COM A VILA CALIFORNIA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.036763, -8.381006] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14555.0,
        identifica: 'PE60-26',
        co_iphan: 'PE2607208BAST00025',
        no_logrado: 'PRÓXIMO A REFINARIA ABREU E LIMA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.034848, -8.393183] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14568.0,
        identifica: 'PE60-13',
        co_iphan: 'PE2607208BAST00038',
        no_logrado: 'MORRO AS MARGENS DA PE 60',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.024603, -8.364272] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14582.0,
        identifica: 'Jaqueira',
        co_iphan: 'PE2607950BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.792854, -8.724877] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14618.0,
        identifica: 'Frei Caneca',
        co_iphan: 'PE2612901BAST00001',
        no_logrado: 'Frei Caneca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.918251, -8.798752] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14632.0,
        identifica: 'Pedra do Letreiro',
        co_iphan: 'PE2612109BAST00001',
        no_logrado: 'Fazenda Pedra Tapada',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.650293, -7.922346] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14633.0,
        identifica: 'Alto dos Macacos',
        co_iphan: 'PE2600054BAST00011',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.891919, -7.904719] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 47038.0,
        identifica: 'Pedra do Caldeirão de Antônio Miguel',
        co_iphan: 'PE2612208BAST00030',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado sobre embasamento rochoso, contendo nos setores noroeste e sudeste pinturas rupestres.',
        dt_cadastr: '2023-08-21',
      },
      geometry: { type: 'Point', coordinates: [-38.914876, -8.283378] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19480.0,
        identifica: 'Morro dos Ossos',
        co_iphan: 'PE2616001BAST00009',
        no_logrado: 'Fazenda Oliveira',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está situado um matacão granítico localizado na baixa vertente do Morro dos Ossos. São pinturas rupestres posicionadas no setor oeste do sítio. No setor leste do matacão verifica-se uma área abrigada com ausência de pinturas rupestres',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.824906, -8.544695] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46817.0,
        identifica: 'Pitomba',
        co_iphan: 'PE2601904BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo nos setores sudeste e noroeste duas áreas abrigadas, interligadas entre si por uma galeria; nos setores abrigados jazem pintura rupestres.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-35.767969, -8.150101] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46463.0,
        identifica: 'Mundo Novo I',
        co_iphan: 'PE2605608BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está posicionado em um testemunho arenítico, em situação topográfica de baixa vertente, contendo no setor sudeste uma área abrigada, onde jazem as gravuras rupestres.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-37.761916, -7.99139] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46818.0,
        identifica: 'Poço da Figura',
        co_iphan: 'PE2610806BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas nos setores sudeste, noroeste e nordeste do sítio.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.818842, -8.741846] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 47039.0,
        identifica: 'Pedra do Pontudo',
        co_iphan: 'PE2600609BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de alta vertente, contendo pinturas rupestres posicionadas no setor nordeste.',
        dt_cadastr: '2023-08-21',
      },
      geometry: { type: 'Point', coordinates: [-36.728828, -8.501491] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37358.0,
        identifica: 'Sítio  Pilão',
        co_iphan: 'PE2616100BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio  Pilão, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta material lascado em quartzo, silexito e arenito em baixa densidade.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.043817, -7.968937] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46819.0,
        identifica: 'Poço Escrito',
        co_iphan: 'PE2614600BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento rochoso, situado nas margens do Riacho Cachoeira Grande, contendo gravuras rupestres posicionadas no setor leste.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.543978, -7.599172] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46821.0,
        identifica: 'Poço Fundo',
        co_iphan: 'PE2602605BAST00056',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão, em situação topográfica de baixa vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.349606, -7.962167] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 23512.0,
        identifica: 'Forte de Pau Amarelo',
        co_iphan: 'PE2610707BAST00003',
        no_logrado: 'Agamenon Magalões, s/n Centro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Apresenta forma trapezoidal com 6 canhões, 2 guaritas nos vértices voltados para o mar. Foi tombado em 1938 e em 1973 foi restaurado em parceria com o Exército Brasileiro, sendo atualmente administrado pela Prefeitura do Paulista/PE.',
        dt_cadastr: '2019-08-22',
      },
      geometry: { type: 'Point', coordinates: [-34.822855, -7.910585] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46822.0,
        identifica: 'Ponta da Serra',
        co_iphan: 'PE2616100BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio Ponta da Serra configura-se por um abrigo sob rocha em quartzito, formado por uma falha estrutural que serve de suporte para os registros rupestres. As pinturas estão posicionadas nos setores nordeste e leste da área abrigada.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-38.97223, -8.037919] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46823.0,
        identifica: 'Prata I',
        co_iphan: 'PE2610806BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos situados na média vertente da Serrote Redondo. São pinturas rupestres posicionadas nos setores nordeste, noroeste e sudoeste do sítio.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.862576, -8.689623] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46824.0,
        identifica: 'Prata II',
        co_iphan: 'PE2610806BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na alta vertente do Serrote Redondo, contendo no setor noroeste uma área abrigada (15 m de comprimento por 6.50 m de largura e 4.5 m de altura), onde jazem as pinturas. No setor norte do abrigo há pinturas no teto.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.862276, -8.689793] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46825.0,
        identifica: 'Prateado',
        co_iphan: 'PE2615805BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na baixa vertente da Serra dos Breus, contendo pinturas rupestres posicionadas nos setores oeste e noroeste do sítio.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.307574, -8.484954] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46826.0,
        identifica: 'Queimada Grande',
        co_iphan: 'PE2600104BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na média vertente da Serra da Queimada Grande, contendo pinturas rupestres posicionadas no setor sudeste.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.645531, -7.815932] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46467.0,
        identifica: 'Mundo Novo II',
        co_iphan: 'PE2605608BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na média vertente do Serrote Furado, contendo pinturas e gravuras rupestres posicionadas nos setores nordeste e sudoeste do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-37.762198, -7.991093] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46827.0,
        identifica: 'Rabichinha',
        co_iphan: 'PE2612505BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-36.359257, -7.930014] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46829.0,
        identifica: 'Redentor',
        co_iphan: 'PE2601904BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres localizadas nos setores sul, sudoeste e noroeste.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-35.734615, -8.139051] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46471.0,
        identifica: 'Nezinho Machado',
        co_iphan: 'PE2616001BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente da Serra do Buco, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.811171, -8.631138] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46473.0,
        identifica: 'Olho d?Água',
        co_iphan: 'PE2602605BAST00043',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.283781, -8.079892] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37359.0,
        identifica: 'Carcará II',
        co_iphan: 'PE2616100BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Carcará II, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta vestígios cerâmicos associados à produção local/regional, vidro em sopro automático, faianças finas, metal e la',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.038691, -7.967707] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37459.0,
        identifica: 'Mucambo',
        co_iphan: 'PE2613107BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Mucambo, localizado(a) no estado de Pernambuco, cidade(s) de São Caitano, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas e percutores em quartzitos, quartzo hialino e arenito.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.216438, -8.476153] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46475.0,
        identifica: 'Olho D?Água do Palitô',
        co_iphan: 'PE2600104BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, em situação topográfica de média vertente da Serra do Minador, contendo gravuras rupestres posicionadas no setor nordeste do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-37.610601, -7.838001] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18742.0,
        identifica: 'Riacho do Leitão',
        co_iphan: 'PE2603900BAST00001',
        no_logrado: 'Leitão da Carapulsa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.652502, -7.873062] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46554.0,
        identifica: 'Sítio Estivas',
        co_iphan: 'PE2609154BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sitio de pintura rupestre de grafismos com representações antroomorfas, zoomorfas e cenas de caça.',
        dt_cadastr: '2023-07-31',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.4606770221339, -8.799070019581096],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24792.0,
        identifica: 'S.S. Salgueiro 02',
        co_iphan: 'PE2615201BAST00007',
        no_logrado: '-',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio composto por material lítico lascado, localizado em área de lagoa.',
        dt_cadastr: '2019-11-21',
      },
      geometry: { type: 'Point', coordinates: [-39.345753, -8.085417] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46478.0,
        identifica: "Olho D'água do Catimbau",
        co_iphan: 'PE2602803BAST00031',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente da Serra do Catimbau, contendo pinturas e gravuras rupestres posicionadas nos setores norte e sul do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-37.21365, -8.554143] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 25725.0,
        identifica: 'Conjunto Jesuíta de Olinda',
        co_iphan: 'PE2609600BAST00003',
        no_logrado: 'Igreja de Nossa Senhora da Divina Graça',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto Jesuíta de Olinda, localizado(a) no estado de Pernambuco, cidade(s) de Olinda, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Bom e de preservação Íntegro.\r\nEstrutura urbana religiosa\r\nIgreja e colégio construídos pel',
        dt_cadastr: '2019-12-18',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.847956450650003, -8.013027192998699],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46480.0,
        identifica: 'Palmeira',
        co_iphan: 'PE2615409BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente, contendo, no setor norte do sítio, uma diminuta área abrigada onde jazem pinturas rupestres.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.024722, -7.986134] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24800.0,
        identifica: 'Pangaio',
        co_iphan: 'PE2611903BAST00005',
        no_logrado: 'sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pangaio, localizado(a) no estado de Pernambuco, cidade(s) de Rio Formoso, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.',
        dt_cadastr: '2019-11-21',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.225596144943999, -8.659190671879593],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46482.0,
        identifica: 'Pau Ferro',
        co_iphan: 'PE2601904BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra Negra, contendo pinturas rupestres; as pinturas estão contidas numa diminuta área abrigada (13 m2), posicionada no setor noroeste do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-35.789752, -8.163523] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46830.0,
        identifica: 'Remédio',
        co_iphan: 'PE2601904BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo uma área abrigada onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-35.807684, -8.190206] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46483.0,
        identifica: 'Pedra Comprida',
        co_iphan: 'PE2609303BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Localizado em dois afloramentos areníticos ruiniformes, situados em uma planície, contendo pinturas rupestres; tais registros encontram-se nos setores sudeste e nordeste do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-38.692445, -8.102172] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46484.0,
        identifica: 'Pedra Comprida I',
        co_iphan: 'PE2613008BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de três matacões agrupados, com orientação para leste/oeste, situados sobre embasamento rochoso. Contêm pinturas rupestres posicionadas nos setores sudeste, sudoeste e oeste do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.498286, -8.457648] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46485.0,
        identifica: 'Pedra Comprida II',
        co_iphan: 'PE2613008BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres posicionadas no setor leste do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.499489, -8.456833] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46486.0,
        identifica: 'Pedra Comprida III',
        co_iphan: 'PE2613008BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres posicionadas no setor norte do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.499508, -8.456707] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46488.0,
        identifica: 'Pedra da Abelha',
        co_iphan: 'PE2603900BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Situado em abrigo sob rocha arenítico, localizado na média vertente do Serrote das Abelhas, contendo gravuras rupestres posicionadas nos setores sul, sudeste e nordeste do sítio.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-37.677845, -7.920683] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46490.0,
        identifica: 'Pedra da Baleia',
        co_iphan: 'PE2601706BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de topo, contendo no setor nordeste do sítio uma diminuta área abrigada onde jazem pinturas rupestres; também há pinturas nas fácies do matacão.',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.44932, -8.194045] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11035.0,
        identifica: 'Pedra da Bicuda',
        co_iphan: 'PE2602605BAST00029',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra Maria Nogueira, contendo no setor sudeste uma área abrigada onde jazem pinturas rupestres.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.322531, -8.096804] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46831.0,
        identifica: 'Riacho da Laje',
        co_iphan: 'PE2605707BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'No percurso do Riacho da Laje observam-se blocos e matacões contendo pinturas e gravuras; também se verificam registros gráficos no interior dos caldeirões. As manchas gráficas estão situadas nos setores: sudoeste, leste e noroeste do sítio.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-38.123234, -8.524723] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37360.0,
        identifica: 'Agaroba',
        co_iphan: 'PE2616100BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Agaroba, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta ascas em arenito silicificado e quartzo leitoso.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.036486, -7.968747] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46556.0,
        identifica: 'Sítio Imbe',
        co_iphan: 'PE2606606BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: 'Sitio de pintura rupestre de grafismos de antropomorfos.',
        dt_cadastr: '2023-07-31',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.426254356626899, -8.7989367305683],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11005.0,
        identifica: 'Pedra da Figura',
        co_iphan: 'PE2604106BAST00003',
        no_logrado: 'Síito Carneirinho, Serra do Medo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão posicionado na alta vertente da Serra do Medo, contendo no setor oeste uma área abrigada. As pinturas rupestres estão posicionadas nos setores oeste, sudoeste e sudeste da área abrigada.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.100651, -8.109844] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46559.0,
        identifica: 'Pedra da Figura',
        co_iphan: 'PE2613107BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de topo, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-36.191495, -8.285952] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46560.0,
        identifica: 'Pedra da Figura I',
        co_iphan: 'PE2615003BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 306.0,
        ds_classif: 'Pré-contato',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-36.208925, -7.826276] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46562.0,
        identifica: 'Pedra da Figura II',
        co_iphan: 'PE2615003BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-36.209133, -7.826377] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46833.0,
        identifica: 'Riacho das Letras I',
        co_iphan: 'PE2605608BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado no leito do Riacho das Letras, contendo gravuras rupestres posicionadas nos setores sul, sudeste e sudoeste.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.816399, -7.986901] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46835.0,
        identifica: 'Riacho das Letras II',
        co_iphan: 'PE2605608BAST00024',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado na margem do Riacho das Letras, contendo uma diminuta área abrigada, onde jazem as gravuras.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.816398, -7.987236] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14456.0,
        identifica: 'Pedra do Navio',
        co_iphan: 'PE2610301BAST00001',
        no_logrado: 'Fazenda Pedra do Navio',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos, posicionados no planalto, cuja sobreposição permitiu a formação de um diminuto abrigo. As pinturas estão localizadas nos setores norte, nordeste e noroeste do sítio.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.688148, -8.87892] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46836.0,
        identifica: 'Riacho das Letras III',
        co_iphan: 'PE2605608BAST00025',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado na margem sul do Riacho das Letras, contendo uma diminuta área abrigada. As gravuras rupestres encontram-se posicionadas nos setores norte, nordeste e leste, na área desabrigada do sítio.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.816044, -7.987371] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46900.0,
        identifica: 'Serra Vermelha II',
        co_iphan: 'PE2603900BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizado na alta vertente da Serra da Lagoinha, contendo\r\npinturas rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.688201, -7.981197] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46837.0,
        identifica: 'Riacho das Letras IV',
        co_iphan: 'PE2605608BAST00026',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado no leito do Riacho das Letras, contendo gravuras rupestres. O local onde os grafismos estão situados tem as seguintes dimensões: 1.90 m de comprimento por 1.60 m de profundidade e 2.50 m de altura.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.81568, -7.987632] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46901.0,
        identifica: 'Serra Vermelha III',
        co_iphan: 'PE2603900BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Gruta, localizada na alta vertente da Serra da Lagoinha, contendo pinturas\r\nrupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.688965, -7.972165] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46902.0,
        identifica: 'Serra Vermelha IV',
        co_iphan: 'PE2605103BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado no topo da Serra da Lagoinha, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.698826, -7.989343] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46903.0,
        identifica: 'Serra Vermelha V',
        co_iphan: 'PE2605608BAST00030',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na média vertente da Serra da Lagoinha, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.699418, -7.98844] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46904.0,
        identifica: 'Serra Vermelha VI',
        co_iphan: 'PE2605103BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na média vertente da Serra da lagoinha, contendo gravuras rupestres',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.697313, -7.988714] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46905.0,
        identifica: 'Serra Vermelha VII',
        co_iphan: 'PE2605103BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado no topo do planalto sedimentar, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.695298, -7.988861] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46906.0,
        identifica: 'Serra Vermelha VIII',
        co_iphan: 'PE2605103BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado no topo do planalto sedimenta, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.695085, -7.987459] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46907.0,
        identifica: 'Serra Vermelha IX',
        co_iphan: 'PE2605608BAST00031',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado no topo do Planalto Sedimentar, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.709825, -7.982296] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7044.0,
        identifica: 'Pedra do Ouro',
        co_iphan: 'PE2605103BAST00001',
        no_logrado: 'Serra do Urubu',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na baixa vertente da Serra do Urubu, contendo pinturas rupestres distribuídas por toda extensão da área abrigada.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.650489, -7.937271] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46908.0,
        identifica: 'Serra Vermelha X',
        co_iphan: 'PE2605608BAST00032',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na alta vertente do planalto sedimentar, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.711195, -7.982219] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46757.0,
        identifica: 'Pedra do Pai João',
        co_iphan: 'PE2605707BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas e gravuras rupestres localizadas nos setores e sudeste.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-38.125312, -8.525551] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46759.0,
        identifica: 'Pedra do Pará',
        co_iphan: 'PE2612505BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, localizado na média vertente da Serra do Pará, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-36.363074, -7.851187] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46909.0,
        identifica: 'Serra Vermelha XI',
        co_iphan: 'PE2605103BAST00024',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado no topo da Serra da Lagoinha, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.736348, -8.021617] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46910.0,
        identifica: 'Serra Vermelha XII',
        co_iphan: 'PE2605608BAST00033',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente do Planalto Sedimentar, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.752218, -8.015308] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46761.0,
        identifica: 'Pedra do Pilão Deitado',
        co_iphan: 'PE2605103BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, posicionado na escarpa da Serra da Lagoinha. No interior da área abrigada, no setor Noroeste, há blocos rolados dos setores mais altos da elevação e blocos que se desprenderam do suporte.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-37.696222, -7.989488] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46911.0,
        identifica: 'Serra Vermelha XIII',
        co_iphan: 'PE2605608BAST00034',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na média vertente do Planalto Sedimentar, contendo gravuras rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.756454, -8.018586] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46762.0,
        identifica: 'Pedra do Poço',
        co_iphan: 'PE2607703BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de fundo de vale, contendo pinturas e gravuras rupestres posicionadas no setor leste.',
        dt_cadastr: '2023-08-09',
      },
      geometry: { type: 'Point', coordinates: [-37.041305, -7.390625] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34026.0,
        identifica: 'Areias II',
        co_iphan: 'PE2616001BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de sítio arqueológico rupestre, com presença de pinturas vermelhas.',
        dt_cadastr: '2021-09-16',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.815192366447, -8.636277268664299],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46912.0,
        identifica: 'Serra Vermelha XIV',
        co_iphan: 'PE2605103BAST00025',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na escarpa da Serra da Lagoinha, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.760215, -8.019854] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 39832.0,
        identifica: 'Arraial Velho do Bom Jesus',
        co_iphan: 'PE2611606BAST00007',
        no_logrado: 'Parque Municipal',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Arraial Velho do Bom Jesus, também conhecido(a) como  (Atualização CNSA PE00052), localizado(a) no estado de Pernambuco, cidade(s) de Recife, é um Bem Arqueológico, do tipo Sítio.\r\nSítio histórico onde foram localizadas estruturas arquitetônicas',
        dt_cadastr: '2022-05-03',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.912374364400002, -8.029955964459999],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46913.0,
        identifica: 'Serra Vermelha XV',
        co_iphan: 'PE2605608BAST00035',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na escarpa da Serra da Lagoinha, contendo pinturas e gravuras rupestres',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.757856, -8.019874] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46914.0,
        identifica: 'Serra Vermelha XVI',
        co_iphan: 'PE2605103BAST00026',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Paredão arenítico, situado na escarpa da Serra da Lagoinha, contendo gravuras rupestres.',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.768157, -8.018657] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46944.0,
        identifica: 'Sítio do Souza II',
        co_iphan: 'PE2610202BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-35.976011, -8.669885] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24861.0,
        identifica: 'Engenho Cordeiro',
        co_iphan: 'PE2604007BAST00001',
        no_logrado: 'não informado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Engenho Cordeiro, localizado(a) no estado de Pernambuco, cidade(s) de Carpina, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Bom e de preservação Íntegro.\r\nEngenho de fabricação de açúcar, oriundo do século XVIII',
        dt_cadastr: '2019-11-25',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.332870440724001, -7.819750935779606],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24853.0,
        identifica: 'S.A. Lagoa do Carro',
        co_iphan: 'PE2608453BAST00001',
        no_logrado: 'não informado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'S.A. Lagoa do Carro, localizado(a) no estado de Pernambuco, cidade(s) de Lagoa do Carro, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.\r\nLocal no terço superior de uma vertente suave com c',
        dt_cadastr: '2019-11-25',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.336518581485002, -7.859571731491499],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24868.0,
        identifica: 'Engenho Serraria',
        co_iphan: 'PE2604007BAST00002',
        no_logrado: 'não informado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Engenho Serraria, localizado(a) no estado de Pernambuco, cidade(s) de Carpina, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Bom e de preservação Íntegro.\r\nEngenho de fabricação de açúcar, oriundo do século XVIII',
        dt_cadastr: '2019-11-25',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.356104313564003, -7.8270874355144],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24872.0,
        identifica: 'Engenho Criméia',
        co_iphan: 'PE2602704BAST00001',
        no_logrado: 'não informado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Engenho Criméia, localizado(a) no estado de Pernambuco, cidade(s) de Buenos Aires, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Bom e de preservação Íntegro.\r\nEngenho de fabricação de açúcar, oriundo do século XVIII',
        dt_cadastr: '2019-11-25',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.309067820910997, -7.729849064364988],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24875.0,
        identifica: 'Casa de Farinha Nossa Senhora do Perpétuo Socorro',
        co_iphan: 'PE2608453BAST00002',
        no_logrado: 'não informado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Casa de Farinha Nossa Senhora do Perpétuo Socorro, localizado(a) no estado de Pernambuco, cidade(s) de Lagoa do Carro, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Bom e de preservação Íntegro.\r\nAntiga casa de produção de fa',
        dt_cadastr: '2019-11-25',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.330126942866002, -7.85606420443069],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24915.0,
        identifica: 'Ponte sobre o rio Tracunhaém',
        co_iphan: 'PE2604007BAST00003',
        no_logrado: 'Ponte sobre o rio Tracunhaém',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Ponte sobre o rio Tracunhaém, localizado(a) no estado de Pernambuco, cidade(s) de Carpina, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Bom e de preservação Íntegro.\r\nPonte para acesso ao Engenho Cordeiro',
        dt_cadastr: '2019-11-26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.328209056250657, -7.824172528041952],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24790.0,
        identifica: 'Gidaí',
        co_iphan: 'PE2614204BAST00003',
        no_logrado: '--',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Gidaí, também conhecido(a) como Engenho do Ouro, localizado(a) no estado de Pernambuco, cidade(s) de Sirinhaém, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.\r\nSítio arqueológico pré-',
        dt_cadastr: '2019-11-21',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.212739260271, -8.6491328505185],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22551.0,
        identifica: 'Trapiche',
        co_iphan: 'PE2611903BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio arqueológico pré-histórico, de natureza cerâmica, implantado à céu aberto. O sítio foi identificado em área de cultivo de cana-de-açúcar.',
        dt_cadastr: '2019-06-26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.223033957181002, -8.657515342768509],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22548.0,
        identifica: 'Agnelo',
        co_iphan: 'PE2611903BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio arqueológico pré-histórico e histórico, de natureza lito-cerâmica e histórica, implantado à céu aberto, localizado na margem esquerda do Rio Sirinhaém.  Os vestígios arqueológicos encontrados estavam dispersos em superfície e em subsup',
        dt_cadastr: '2019-06-26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.218067361387, -8.653178477712805],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22550.0,
        identifica: 'Miagri',
        co_iphan: 'PE2611903BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio arqueológico pré-histórico e histórico, de natureza lito-cerâmica e histórica, implantado à céu aberto, localizado na margem esquerda do Rio Sirinhaém.  Os vestígios arqueológicos encontrados estavam dispersos em superfície e em subsup',
        dt_cadastr: '2019-06-26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.221071074248002, -8.657355015797492],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 24816.0,
        identifica: 'Abueira do Lindô',
        co_iphan: 'PE2611903BAST00006',
        no_logrado: 'sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abueira do Lindô, localizado(a) no estado de Pernambuco, cidade(s) de Rio Formoso, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.\r\nSítio arqueológico pré-histórico e histórico, de nat',
        dt_cadastr: '2019-11-21',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.217363851582, -8.650922869694693],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46838.0,
        identifica: 'Riacho das Letras V',
        co_iphan: 'PE2605608BAST00027',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado na margem leste do Riacho das Letras, contendo gravuras rupestres.',
        dt_cadastr: '2023-08-11',
      },
      geometry: { type: 'Point', coordinates: [-37.810998, -7.988061] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46853.0,
        identifica: 'Riacho do Pinga',
        co_iphan: 'PE2615805BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, situado no leito do Riacho do Pinga, contendo gravuras rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.294807, -8.490658] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46854.0,
        identifica: 'Riacho do Salgado I',
        co_iphan: 'PE2606606BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado no leito do Riacho do Salgado, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.533433, -8.503547] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18743.0,
        identifica: 'Serrote do Giz',
        co_iphan: 'PE2603900BAST00002',
        no_logrado: 'Leitão da Carapulsa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.643364, -7.89148] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46855.0,
        identifica: 'Riacho do Salgado II',
        co_iphan: 'PE2606606BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado no leito do Riacho do Salgado, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.533273, -8.502498] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46856.0,
        identifica: 'Salgado',
        co_iphan: 'PE2606606BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Paredão arenítico, localizado na baixa vertente da Serra da Cruz, contendo pinturas e gravuras rupestres posicionadas nos setores sul, sudeste e nordeste.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.536892, -8.50148] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18679.0,
        identifica: 'Aldeia do Baião',
        co_iphan: 'PE2601102BAST00006',
        no_logrado: 'Bandeira',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.566667, -7.533337] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18709.0,
        identifica: 'Serra Branca',
        co_iphan: 'PE2602803BAST00007',
        no_logrado: 'Serra Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.243564, -8.543394] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18710.0,
        identifica: 'Abrigo do Mingu',
        co_iphan: 'PE2602803BAST00008',
        no_logrado: 'Catimbau',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.241917, -8.58748] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18711.0,
        identifica: 'Gruta do Quixeu 2',
        co_iphan: 'PE2602803BAST00009',
        no_logrado: 'Catimbau',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.241089, -8.581862] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18712.0,
        identifica: 'Gruta do Quixeu 1',
        co_iphan: 'PE2602803BAST00010',
        no_logrado: 'Catimbau',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.240842, -8.582286] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46857.0,
        identifica: 'Santa Rita Velha I',
        co_iphan: 'PE2613909BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre embasamento rochoso, contendo pinturas rupestres posicionadas nos setores leste e nordeste.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-38.432402, -7.786272] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18702.0,
        identifica: 'Sítio Torre',
        co_iphan: 'PE2602605BAST00036',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.147772, -7.96464] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18706.0,
        identifica: 'Serra das Torres',
        co_iphan: 'PE2602803BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.246503, -8.566064] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18707.0,
        identifica: 'Mirante da Serrinha',
        co_iphan: 'PE2602803BAST00005',
        no_logrado: 'Serrinha, próximo a grota funda',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.245029, -8.533003] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18716.0,
        identifica: 'Pedra da Mão',
        co_iphan: 'PE2603009BAST00001',
        no_logrado: 'Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.513335, -8.297783] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18717.0,
        identifica: 'Pedra Montada',
        co_iphan: 'PE2603009BAST00002',
        no_logrado: 'Conceição das Creoulas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.514452, -8.30528] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18738.0,
        identifica: 'Fazenda Bonsucesso',
        co_iphan: 'PE2603801BAST00001',
        no_logrado: 'Barra',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.643942, -8.690909] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18740.0,
        identifica: 'Serra do Gurjão 2',
        co_iphan: 'PE2603801BAST00003',
        no_logrado: 'Serra da Gurjão',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.602303, -8.622596] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18741.0,
        identifica: 'Serra do Gurjão 3',
        co_iphan: 'PE2603801BAST00004',
        no_logrado: 'Serra do Gurjão',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.599433, -8.632601] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46858.0,
        identifica: 'Santa Rita Velha II',
        co_iphan: 'PE2613909BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre embasamento, contendo pinturas rupestres posicionadas nos setores noroeste e oeste.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-38.431948, -7.786326] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46859.0,
        identifica: 'Santa Rita Velha III',
        co_iphan: 'PE2613909BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico composto por dois blocos sobrepostos com a presença de pinturas rupestres. As manchas gráficas situam-se em distintos setores do sítio.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-38.424171, -7.776682] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46564.0,
        identifica: 'Pedra da Joaninha',
        co_iphan: 'PE2605301BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente do Serrote da Joaninha, contendo pinturas rupestres localizadas na porção central da área abrigada.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-39.781375, -7.626625] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18775.0,
        identifica: 'Rochedo',
        co_iphan: 'PE2606705BAST00001',
        no_logrado: 'Sítio Serrote',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.187922, -8.709173] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37361.0,
        identifica: 'Frade I',
        co_iphan: 'PE2616100BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Frade I, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta cascalheiras com lascas de quartzo leitoso obtidas através de seixos.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-39.012567, -7.970124] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37363.0,
        identifica: 'Pedreira',
        co_iphan: 'PE2616100BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pedreira , localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefatos em quartzo, arenitos silicificado e sílex, lascas, fragmentos de lascas e núcleos com retoques além de arte',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-38.976644, -7.975424] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18873.0,
        identifica: 'Pedra da Mão II',
        co_iphan: 'PE2610400BAST00016',
        no_logrado: 'Conceição das Creoulas - Sítio Pedra Preta',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.66834, -8.264169] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46860.0,
        identifica: 'Santa Rita Velha IV',
        co_iphan: 'PE2613909BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: 'Matacão granítico contendo pinturas no setor sudeste.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-38.433027, -7.786888] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18888.0,
        identifica: 'Açude das Pedras',
        co_iphan: 'PE2611101BAST00001',
        no_logrado: 'Rajada',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.828242, -8.809684] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19035.0,
        identifica: 'São José',
        co_iphan: 'PE2601102BAST00018',
        no_logrado:
          'Próximo ao Serrote da Cascavel, município de Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.481696, -7.709045] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18914.0,
        identifica: 'Pedra da Figura',
        co_iphan: 'PE2613503BAST00001',
        no_logrado: 'U.R. Fazenda Boa Vista',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.608336, -7.752225] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19245.0,
        identifica: 'Pedra do Caboclo',
        co_iphan: 'PE2602209BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.58298, -7.803544] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18937.0,
        identifica: 'Furna dos Letreiros',
        co_iphan: 'PE2615805BAST00001',
        no_logrado: 'Mina Grande',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.30106, -8.635141] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19017.0,
        identifica: 'Pedra do Letreiro',
        co_iphan: 'PE2600500BAST00002',
        no_logrado: 'Destrito de Curral Novo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.115358, -9.06007] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18945.0,
        identifica: 'Pedra da Taça',
        co_iphan: 'PE2616001BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.836918, -8.543479] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18946.0,
        identifica: 'Pedra do Boqueirão',
        co_iphan: 'PE2616001BAST00007',
        no_logrado: 'Sítio Boqueirão',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.817323, -8.608386] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19029.0,
        identifica: 'Torre IV',
        co_iphan: 'PE2601102BAST00012',
        no_logrado:
          'Serra da Torre, no topo da Chapada do Araripe, Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.55781, -7.602869] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46571.0,
        identifica: 'Pedra da Letra de Paula',
        co_iphan: 'PE2612208BAST00024',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre um afloramento rochoso, contendo no setor noroeste um nicho onde jazem pinturas rupestres.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-38.910773, -8.277607] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46862.0,
        identifica: 'São Domingos',
        co_iphan: 'PE2603900BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, em situação topográfica de baixa vertente, contendo pinturas rupestres, posicionadas em nichos, distribuídas por toda extensão do suporte.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.649371, -7.868232] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19066.0,
        identifica: 'Exu Velho',
        co_iphan: 'PE2605301BAST00001',
        no_logrado: 'Antiga Vila de Exu, município de Exu ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.745635, -7.423057] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37364.0,
        identifica: 'Mamoeiro I',
        co_iphan: 'PE2616100BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Mamoeiro I, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas, fragmentos de lascas em quartzo leitoso e arenito silicificado além de percutor com marcas de uso na parte per',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-38.932934, -7.979851] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46865.0,
        identifica: 'Serra da Melancia',
        co_iphan: 'PE2602605BAST00057',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de alta vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-36.145863, -8.189834] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19025.0,
        identifica: 'Minador III',
        co_iphan: 'PE2601102BAST00008',
        no_logrado: 'Serra do Minador, município de Araripina ? PE',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.598979, -7.648546] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19026.0,
        identifica: 'Minador II',
        co_iphan: 'PE2601102BAST00009',
        no_logrado: 'Serra do Minador, município de Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.594912, -7.654167] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19027.0,
        identifica: 'Torre V',
        co_iphan: 'PE2601102BAST00010',
        no_logrado:
          'Serra da Torre, no topo da Chapada do Araripe, Araripina ? PE',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.567626, -7.593104] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19028.0,
        identifica: 'Torre III',
        co_iphan: 'PE2601102BAST00011',
        no_logrado:
          'Serra da Torre, no topo da Chapada do Araripe, Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.561067, -7.596401] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19030.0,
        identifica: 'Torre I',
        co_iphan: 'PE2601102BAST00013',
        no_logrado:
          'Serra da Torre, no topo da Chapada do Araripe, Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.545283, -7.604596] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19031.0,
        identifica: 'Torre II',
        co_iphan: 'PE2601102BAST00014',
        no_logrado:
          'Serra da Torre, no topo da Chapada do Araripe, Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.533481, -7.588904] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19032.0,
        identifica: 'Jardim I',
        co_iphan: 'PE2601102BAST00015',
        no_logrado: 'Serra do Jardim, município de Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.508828, -7.514822] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19033.0,
        identifica: 'Cachoeira',
        co_iphan: 'PE2601102BAST00016',
        no_logrado:
          'Próximo do Riacho São Pedro, no perímetro urbano do município de\nAraripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.50853, -7.567397] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19034.0,
        identifica: 'Jardim II',
        co_iphan: 'PE2601102BAST00017',
        no_logrado: 'Serra do Jardim, município de Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.487139, -7.505039] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19036.0,
        identifica: 'Lagoa do Cascavel',
        co_iphan: 'PE2601102BAST00019',
        no_logrado:
          'Próximo ao açude da Lagoa do Cascavel, município de Araripina ? PE',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.46557, -7.619305] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19037.0,
        identifica: 'Santa Cruz',
        co_iphan: 'PE2601102BAST00020',
        no_logrado: 'Fazenda Santa Cruz, município de Araripina ? PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.453276, -7.615476] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19042.0,
        identifica: 'Brejão 2',
        co_iphan: 'PE2602407BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.573399, -9.018758] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46866.0,
        identifica: 'Serra das Letras I',
        co_iphan: 'PE2605608BAST00028',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizada na média vertente da Serra das Letras, contendo pinturas e gravuras rupestres distribuídas por toda a extensão da área abrigada.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.807842, -7.990557] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19079.0,
        identifica: 'Lagedo 3',
        co_iphan: 'PE2608800BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.299056, -8.646486] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19080.0,
        identifica: 'Lagedo 5',
        co_iphan: 'PE2608800BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.298338, -8.720112] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19110.0,
        identifica: 'Moxotó 2',
        co_iphan: 'AL2706422BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.051386, -9.167879] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19125.0,
        identifica: 'São João 1',
        co_iphan: 'PE2613206BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.32925, -8.896185] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19133.0,
        identifica: 'Moxotó 1',
        co_iphan: 'PE2614808BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.050545, -9.165598] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19134.0,
        identifica: 'Taquaritinga do Norte 1',
        co_iphan: 'PE2615003BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.12401, -7.881096] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19138.0,
        identifica: 'Terezinha 6',
        co_iphan: 'PE2615102BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.664295, -9.074478] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19139.0,
        identifica: 'Terezinha 1',
        co_iphan: 'PE2615102BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.642176, -9.054417] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19140.0,
        identifica: 'Terezinha 2',
        co_iphan: 'PE2615102BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.637417, -9.053654] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19141.0,
        identifica: 'Terezinha 3',
        co_iphan: 'PE2615102BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.626689, -9.045162] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19142.0,
        identifica: 'Terezinha 5',
        co_iphan: 'PE2615102BAST00005',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.61668, -9.039638] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46574.0,
        identifica: 'Pedra da Letra do Caldeirão do Zé Pinto',
        co_iphan: 'PE2610806BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos, contendo pinturas e gravuras rupestres localizadas nos setores norte e leste do sítio.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-36.825885, -8.878248] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37365.0,
        identifica: 'Sítio das Posse I',
        co_iphan: 'PE2613503BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio das Posse I, localizado(a) no estado de Pernambuco, cidade(s) de São José do Belmonte, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefatos cerâmicos de produção local/regional, louças, vidro, metais e materiais construtivos.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-38.860982, -7.992769] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19238.0,
        identifica: 'Minador I',
        co_iphan: 'PE2601102BAST00021',
        no_logrado: 'Serra do Minador, município de Araripina ? PE',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.593935, -7.657046] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19243.0,
        identifica: 'Santa Teresa 1',
        co_iphan: 'PE2600302BAST00002',
        no_logrado: 'Vila Santa Teresa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.953003, -8.495676] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46576.0,
        identifica: 'Pedra da Letra do Juazeiro',
        co_iphan: 'PE2605152BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pedra da Letra do Juazeiro, localizado(a) no estado de Pernambuco, cidade(s) de Dormentes, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-40.81349, -8.4286] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46867.0,
        identifica: 'Serra das Letras II',
        co_iphan: 'PE2605608BAST00029',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizado na média vertente da Serra das Letras, contendo pinturas e gravuras rupestres distribuídas por toda a extensão da área abrigada.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.807661, -7.990521] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19298.0,
        identifica: 'Sinal Verde',
        co_iphan: 'PE2613701BAST00001',
        no_logrado: 'Loteamento Sinal Verde',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.016991, -7.983749] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19305.0,
        identifica: 'Alto Córrego de Ouro',
        co_iphan: 'PE2600054BAST00012',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.88829, -7.901895] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46868.0,
        identifica: 'Serra do Abacaxi',
        co_iphan: 'PE2602803BAST00034',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na média vertente da Serra do Abacaxi, contendo pinturas posicionadas no setor oeste do sítio.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.202696, -8.62943] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19306.0,
        identifica: 'Alto da Belenga',
        co_iphan: 'PE2600054BAST00013',
        no_logrado: 'Jaguaribe',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.882704, -7.901812] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19307.0,
        identifica: 'Tamanduá',
        co_iphan: 'PE2600054BAST00014',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.879472, -7.906872] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19308.0,
        identifica: 'São Bento',
        co_iphan: 'PE2600054BAST00015',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.872559, -7.902057] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19309.0,
        identifica: 'Sítio Timbó',
        co_iphan: 'PE2600054BAST00016',
        no_logrado: 'São Bento',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.863996, -7.903505] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19310.0,
        identifica: 'Engenho Inhamã',
        co_iphan: 'PE2606804BAST00002',
        no_logrado: 'Inhamã',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.896313, -7.885622] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19311.0,
        identifica: 'Alto da Serpente',
        co_iphan: 'PE2606804BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.876658, -7.851925] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19312.0,
        identifica: 'Usina Mulata',
        co_iphan: 'PE2607752BAST00001',
        no_logrado: 'Rua Manoel Henrique do Nascimento',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.896275, -7.770966] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19313.0,
        identifica: 'Terra Norte I',
        co_iphan: 'PE2607752BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.890704, -7.751418] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46869.0,
        identifica: 'Serra do Catimbau',
        co_iphan: 'PE2602803BAST00035',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Paredão arenítico, parte integrante do contraforte da Serra do Catimbau, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.214768, -8.555956] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19314.0,
        identifica: 'Terra Norte II',
        co_iphan: 'PE2607752BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.889734, -7.757497] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19325.0,
        identifica: 'Forno Salinas',
        co_iphan: 'PE2610707BAST00001',
        no_logrado: 'Jaguarana',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.863809, -7.914337] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19326.0,
        identifica: 'Maranguape',
        co_iphan: 'PE2610707BAST00002',
        no_logrado: 'Maranguape II',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.844022, -7.940518] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19335.0,
        identifica: 'Sítio Engenho do Meio - KM 361',
        co_iphan: 'PE2606804BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.961604, -7.775063] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19336.0,
        identifica: "Sítio Ruínas Rio Goiabeira - KM's 401-402",
        co_iphan: 'PE2607901BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.024515, -8.083849] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46870.0,
        identifica: 'Serra do Chiqueiro',
        co_iphan: 'PE2615805BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na baixa vertente da Grota Serra Verde, contendo pinturas rupestres posicionadas nos setores sudoeste, nordeste e sudeste.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.3076, -8.580243] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19341.0,
        identifica: 'Barra do Timbó',
        co_iphan: 'PE2602308BAST00001',
        no_logrado: 'Acampamento Beth Shalom',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.807776, -8.584452] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46577.0,
        identifica: 'Pedra da Letra do Sabá I',
        co_iphan: 'PE2605103BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, em situação topográfica de média vertente, contendo pinturas rupestres localizadas nos setores noroeste, norte e nordeste do sítio.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-37.707423, -8.010531] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46871.0,
        identifica: 'Serra do Medo I',
        co_iphan: 'PE2604106BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões sobrepostos, em situação topográfica de baixa vertente, contendo no setor sudeste uma diminuta área abrigada; no entanto, as pinturas encontram-se fora da área abrigada.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-36.103928, -8.097358] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46872.0,
        identifica: 'Serra do Medo II',
        co_iphan: 'PE2604106BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de topo, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-36.098279, -8.102612] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46578.0,
        identifica: 'Pedra da Letra do Sabá II',
        co_iphan: 'PE2605103BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, em situação topográfica de média vertente, contendo pinturas e gravuras situadas em nichos, posicionados nos setores sul e sudeste do suporte.',
        dt_cadastr: '2023-07-31',
      },
      geometry: { type: 'Point', coordinates: [-37.707377, -8.010594] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46873.0,
        identifica: 'Serra do Sangue I',
        co_iphan: 'PE2604106BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-35.97176, -8.102758] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46874.0,
        identifica: 'Serra do Sangue II',
        co_iphan: 'PE2604106BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-35.969328, -8.102441] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46875.0,
        identifica: 'Serra do Sangue III',
        co_iphan: 'PE2604106BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de topo, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-35.968487, -8.106449] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46876.0,
        identifica: 'Serra Vermelha I',
        co_iphan: 'PE2603900BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Paredão arenítico, localizado na média vertente da Serra da Lagoinha, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-14',
      },
      geometry: { type: 'Point', coordinates: [-37.685684, -7.979634] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19696.0,
        identifica: 'Engenho Jaguaribe',
        co_iphan: 'PE2600054BAST00017',
        no_logrado: 'Jaguaribe',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.876622, -7.91032] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19697.0,
        identifica: 'igreja de nossa senhora de boa virgem',
        co_iphan: 'PE2606804BAST00005',
        no_logrado: 'pasmado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.935938, -7.768057] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19871.0,
        identifica: 'Oficinas do Presídio',
        co_iphan: 'PE2605459BAST00004',
        no_logrado: 'Vila dos Remédios',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-32.402316, -3.841336] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19873.0,
        identifica: 'Manuel Ferreira',
        co_iphan: 'PE2601508BAST00002',
        no_logrado: 'Sítio Barra do Timbó',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.812148, -8.585065] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19874.0,
        identifica: 'Engenho Mulatinha',
        co_iphan: 'PE2604205BAST00003',
        no_logrado: 'Engenho Mulatinha',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.722395, -8.590038] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19875.0,
        identifica: 'Capinzal',
        co_iphan: 'PE2610004BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.552945, -8.643557] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19970.0,
        identifica: 'Juá de Cima',
        co_iphan: 'PE2608404BAST00002',
        no_logrado: 'Laje dos Caroços',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.165009, -8.6891] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20331.0,
        identifica: 'Abrigo do Neto IV',
        co_iphan: 'PE2602605BAST00037',
        no_logrado: 'Fazenda do Aristo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.147181, -8.197355] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20416.0,
        identifica: 'Serra do Quilombo 1',
        co_iphan: 'PE2604205BAST00004',
        no_logrado: 'Assentamento Serra do Quilombo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.7109, -8.582005] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20417.0,
        identifica: 'Serra do Quilombo 2',
        co_iphan: 'PE2604205BAST00005',
        no_logrado: 'Serra do Quilombo (Assentamento)',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.706257, -8.585372] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20418.0,
        identifica: 'Estreliana 2',
        co_iphan: 'PE2605905BAST00007',
        no_logrado: 'Alegrezinho',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.463336, -8.555416] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20419.0,
        identifica: 'Estreliana 1',
        co_iphan: 'PE2605905BAST00008',
        no_logrado: 'Alegrezinho',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.46245, -8.554554] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20420.0,
        identifica: 'PE60-59',
        co_iphan: 'PE2607208BAST00050',
        no_logrado: 'TOPO E VERTENTE DE MORRO',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.029984, -8.351525] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20456.0,
        identifica: 'Serrote',
        co_iphan: 'PE2615201BAST00005',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.264478, -8.073507] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20566.0,
        identifica: 'Castelo',
        co_iphan: 'PE2612000BAST00003',
        no_logrado: 'Fazenda São José',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.748541, -8.281012] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37366.0,
        identifica: 'Capela',
        co_iphan: 'PE2613503BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Capela, localizado(a) no estado de Pernambuco, cidade(s) de São José do Belmonte, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefatos cerâmicos de fabricação regional, louças brasileiras, vidro, metais e moedas.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-38.656507, -7.965676] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20592.0,
        identifica: 'Pedra do Letreiro',
        co_iphan: 'PE2602506BAST00001',
        no_logrado: 'Sítio Belém',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.276392, -7.351947] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20593.0,
        identifica: 'Sítio Arqueológico Serra da Batinga',
        co_iphan: 'PI2202091BAST00017',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.551328, -7.395068] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20612.0,
        identifica: 'Furna da Janela',
        co_iphan: 'PE2602605BAST00038',
        no_logrado: 'Sítio Barra do jucá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.194256, -8.23543] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20613.0,
        identifica: 'Sítio da Serra do Cachorro',
        co_iphan: 'PE2602605BAST00039',
        no_logrado: 'Serra do Jaracatiá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.146116, -8.196395] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46513.0,
        identifica: 'Pedra da Bola',
        co_iphan: 'PE2616001BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na baixa vertente do Morro dos Ossos, contendo pinturas rupestres posicionadas no setor oeste do sítio.',
        dt_cadastr: '2023-07-26',
      },
      geometry: { type: 'Point', coordinates: [-36.83663, -8.543034] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46519.0,
        identifica: 'Pedra da Borracha',
        co_iphan: 'PE2612307BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico, em situação topográfica de baixa vertente da Serra do Lajeirão, contendo gravuras rupestres.',
        dt_cadastr: '2023-07-26',
      },
      geometry: { type: 'Point', coordinates: [-36.822574, -8.944745] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46515.0,
        identifica: 'Pedra da Bola',
        co_iphan: 'PE2603900BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo situado em um matacão granítico, em situação topográfica de baixa vertente. São pinturas rupestres posicionadas no setor leste do sítio, no teto da área abrigada.',
        dt_cadastr: '2023-07-26',
      },
      geometry: { type: 'Point', coordinates: [-37.712857, -7.835369] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46520.0,
        identifica: 'Pedra da Botija',
        co_iphan: 'PE2600500BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico, localizado no topo do Morro do Sino, contendo pinturas rupestres posicionadas no setor oeste do sítio.',
        dt_cadastr: '2023-07-26',
      },
      geometry: { type: 'Point', coordinates: [-37.048158, -9.148789] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20953.0,
        identifica: 'Parazinho',
        co_iphan: 'PE2612505BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.363736, -7.852015] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20954.0,
        identifica: 'Cachorro II',
        co_iphan: 'PE2602605BAST00040',
        no_logrado: 'Serra do Cachorro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.153645, -8.203055] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20960.0,
        identifica: 'Alcobaça 2',
        co_iphan: 'PE2602803BAST00014',
        no_logrado: 'Sítio Serrote Preto',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.191667, -8.537224] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20968.0,
        identifica: 'Furna do Estrago',
        co_iphan: 'PE2602605BAST00041',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.383762, -8.144088] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20975.0,
        identifica: 'Cacimba Cercada',
        co_iphan: 'PE2600500BAST00003',
        no_logrado: 'Lajeiro dos Linos',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.93279, -9.032716] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20996.0,
        identifica: 'PE 0771 LA/UFPE',
        co_iphan: 'PE2603801BAST00005',
        no_logrado:
          'Sítio Lagoa do Tanque, próximo a comunidade quilombola do Fidelão, na zona rural do municí...',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.661811, -8.637695] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20997.0,
        identifica: 'PE 0772 LA/UFPE',
        co_iphan: 'PE2603801BAST00006',
        no_logrado:
          'Sítio Fidelão, na zona rural do município de Capoeiras-PE.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.655634, -8.623187] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20998.0,
        identifica: 'PE 0773 LA/UFPE',
        co_iphan: 'PE2603801BAST00007',
        no_logrado: 'Serra do Gurjão, próximo ao Sítio Una dos Cordeiros.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.597541, -8.624455] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 21058.0,
        identifica: 'Pedra das Letras',
        co_iphan: 'PE2614303BAST00001',
        no_logrado: 'Varjota',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.595464, -7.620507] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 21059.0,
        identifica: 'Pedra do Caboclo',
        co_iphan: 'PE2605301BAST00002',
        no_logrado: 'Pamonha',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.792917, -7.622488] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20955.0,
        identifica: 'Estrada do Porto do Cachorro (FN0004 LA/UFPE)',
        co_iphan: 'PE2605459BAST00007',
        no_logrado: 'Vila dos Remédios, estrada velha do Porto',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-32.410162, -3.839825] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37986.0,
        identifica: 'Serrotinho',
        co_iphan: 'PE2614105BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Serrotinho, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.470823, -8.131432] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37987.0,
        identifica: 'Entroncamento do Xiquexique',
        co_iphan: 'PE2614105BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Entroncamento do Xiquexique, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.468701, -8.131117] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37368.0,
        identifica: 'Riacho Grande',
        co_iphan: 'PE2613503BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Riacho Grande, localizado(a) no estado de Pernambuco, cidade(s) de São José do Belmonte, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefatos em quartzo, arenito silicificado e silexito.',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-38.654948, -7.964544] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22099.0,
        identifica: 'Cachorro I',
        co_iphan: 'PE2614709BAST00001',
        no_logrado: 'Fazenda Pedra do Cachorro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-36.204665, -8.234527] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37450.0,
        identifica: 'União',
        co_iphan: 'PE2610905BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'União , localizado(a) no estado de Pernambuco, cidade(s) de Pesqueira, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de cerâmica de produção local/regional, faiança fina importada, vidro, grés e metais.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.691509, -8.4199] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46588.0,
        identifica: 'Pedra da Lua',
        co_iphan: 'PE2602605BAST00044',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, situado na média vertente da Serra da Boa Vista, contendo duas áreas abrigadas onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-01',
      },
      geometry: { type: 'Point', coordinates: [-36.244489, -8.158237] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46592.0,
        identifica: 'Pedra da Mão de Itapetim',
        co_iphan: 'PE2607703BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente do Serrote do Venturino, contendo pinturas rupestres no setor sudoeste.',
        dt_cadastr: '2023-08-01',
      },
      geometry: { type: 'Point', coordinates: [-37.193636, -7.371359] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14446.0,
        identifica: 'Pedra da Buquinha I',
        co_iphan: 'PE2616001BAST00002',
        no_logrado: 'Boqueirão',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio com pinturas vermelhas e amarelas é formado por grandes blocos de granito arredondado que, emergem no meio do vale, na localidade Boqueirão. Matacão granítico, situado na baixa vertente da Serra do Buco, contendo pinturas\r\nrupestres.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.802288, -8.598123] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46539.0,
        identifica: 'Pedra da Buquinha II',
        co_iphan: 'PE2616001BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente da Serra do Buco, contendo pinturas rupestres localizadas nos setores noroeste e sudeste',
        dt_cadastr: '2023-07-28',
      },
      geometry: { type: 'Point', coordinates: [-36.801182, -8.597836] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46541.0,
        identifica: 'Pedra da Caveira',
        co_iphan: 'PE2616001BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas\r\nrupestres posicionadas no setor sul, onde há uma área abrigada.',
        dt_cadastr: '2023-07-28',
      },
      geometry: { type: 'Point', coordinates: [-36.829191, -8.443246] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10998.0,
        identifica: 'Pedra da Compadecida',
        co_iphan: 'PE2602605BAST00023',
        no_logrado: 'Pocinho d´água',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo no setor\r\nleste uma área abrigada onde jazem pinturas rupestres',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.202504, -8.137512] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46542.0,
        identifica: 'Pedra da Concha I',
        co_iphan: 'PE2602803BAST00032',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Testemunho arenítico, localizado no altiplano da Serra do Jerusalém, contendo pinturas rupestres posicionadas em uma cavidade do suporte rochoso.',
        dt_cadastr: '2023-07-28',
      },
      geometry: { type: 'Point', coordinates: [-37.248018, -8.556488] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22544.0,
        identifica: 'SANTO ESTEVÃO I',
        co_iphan: 'PE2601102BAST00022',
        no_logrado: 'SERRA DA RANCHARIA',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-40.60488, -7.750041] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22545.0,
        identifica: 'Sítios dos Capitão',
        co_iphan: 'PE2601102BAST00023',
        no_logrado: 'Serra dos Capit?o',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-40.534949, -7.509143] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22549.0,
        identifica: 'Cucaú',
        co_iphan: 'PE2611903BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio arqueológico pré-histórico e histórico, de natureza lítica e histórica, implantado à céu aberto, localizado na margem esquerda do Rio Sirinhaém.  Os vestígios arqueológicos encontrados estavam dispersos em superfície e em subsuperfíci',
        dt_cadastr: '2019-06-26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.227813336209998, -8.667059486449702],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46543.0,
        identifica: 'Pedra da Concha II',
        co_iphan: 'PE2602803BAST00033',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Morro testemunho arenítico, localizado no altiplano da Serra do Jerusalém, contendo pinturas rupestres posicionadas na média vertente do testemunho.',
        dt_cadastr: '2023-07-28',
      },
      geometry: { type: 'Point', coordinates: [-37.245622, -8.556151] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46799.0,
        identifica: 'Pedra Grande',
        co_iphan: 'PE2610905BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões e blocos graníticos, situados a 30 m do Riacho Brígida, contendo gravuras rupestres posicionadas no setor noroeste.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-36.646613, -8.522344] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46800.0,
        identifica: 'Pedra Gravada',
        co_iphan: 'PE2609303BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento de gnaisse, localizado no leito do Riacho do Brejo, contendo gravuras rupestres posicionadas no setor sudeste.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-38.652246, -8.288428] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22572.0,
        identifica: 'Riacho da Forquilha',
        co_iphan: 'PE2608057BAST00013',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-38.235262, -9.19644] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46544.0,
        identifica: 'Pedra da Cuia',
        co_iphan: 'PE2603926BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico, situado na baixa vertente da Serra do Arapuá, contendo pinturas rupestres posicionadas no setor sudeste do sítio',
        dt_cadastr: '2023-07-28',
      },
      geometry: { type: 'Point', coordinates: [-38.667921, -8.383353] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22577.0,
        identifica: 'Sítio Açu',
        co_iphan: 'PE2606903BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-37.280599, -7.847707] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14172.0,
        identifica: 'Pedra da Ema I',
        co_iphan: 'PE2610400BAST00003',
        no_logrado: 'Fazenda Pedra da Ema',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio posicionado no leito do Riacho da Ema, num afloramento de gnaisse, contendo gravuras rupestres posicionadas nos setores nordeste e noroeste.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.676115, -8.060492] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18947.0,
        identifica: 'Pedra Furada',
        co_iphan: 'PE2616001BAST00008',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, posicionado no topo da Serra do Buco, contendo pinturas rupestres localizadas nos setores nordeste e sudoeste.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.813057, -8.579446] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46778.0,
        identifica: 'Pedra do Pote',
        co_iphan: 'PE2616001BAST00027',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em posição topográfica de alta vertente, contendo pinturas rupestres posicionadas no setor sudoeste.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-36.824478, -8.56898] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14173.0,
        identifica: 'Pedra da Ema II',
        co_iphan: 'PE2610400BAST00004',
        no_logrado: 'Fazenda Pedra da Ema',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento de gnaisse situado no leito do Riacho da Ema (margem sudeste),\r\ncontendo gravuras rupestres posicionadas no setor noroeste e sudeste.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.675306, -8.059761] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22628.0,
        identifica: 'Pedra do Urubu / Figura',
        co_iphan: 'PE2613107BAST00001',
        no_logrado: 'Sítio do Zumba',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-36.191828, -8.286406] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22629.0,
        identifica: 'Pedra Grande',
        co_iphan: 'PE2604106BAST00004',
        no_logrado: 'Sítio Carneirinho',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-36.108079, -8.126686] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22632.0,
        identifica: 'SÍTIO CONVIDA',
        co_iphan: 'PE2602902BAST00020',
        no_logrado: 'Área rural, perto do engenho Santa Amélia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-35.049563, -8.308124] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22633.0,
        identifica: 'TÚNEL DO PAVÃO',
        co_iphan: 'PE2602902BAST00021',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-35.070576, -8.308648] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22634.0,
        identifica: 'Ocorrência 49',
        co_iphan: 'PE2607208BAST00051',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-35.057779, -8.382784] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22635.0,
        identifica: 'Arataca II',
        co_iphan: 'PE2606804BAST00006',
        no_logrado: 'Vila da Foice, Parcela 082, 083, 084',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.927368, -7.703897] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22636.0,
        identifica: 'Pepino',
        co_iphan: 'PE2606804BAST00007',
        no_logrado: 'Usina s?o José, Parcela 014',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.950306, -7.707826] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22637.0,
        identifica: 'RUÍNAS DA IGREJA DE IGARASSU',
        co_iphan: 'PE2606804BAST00008',
        no_logrado: 'Terras da Usina s?o José',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.961865, -7.775179] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22638.0,
        identifica: 'Sítio Campo do Oriente I',
        co_iphan: 'PE2606804BAST00009',
        no_logrado: 'Pau de Légua, Cruz de Rebouças',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.928735, -7.883621] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22639.0,
        identifica: 'Sítio Campo do Oriente II',
        co_iphan: 'PE2606804BAST00010',
        no_logrado: 'Pau de Légua, Cruz de Rebouças',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.92091, -7.883657] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22640.0,
        identifica: 'Sítio são José',
        co_iphan: 'PE2606804BAST00011',
        no_logrado: 'Pau de Légua - Cruz de Rebouças',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.924732, -7.876967] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22641.0,
        identifica: 'Vila da Foice',
        co_iphan: 'PE2606804BAST00012',
        no_logrado: 'Rua da Foice, parcela 082, 083, 084',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.928734, -7.705247] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22642.0,
        identifica: 'Alphaville 1',
        co_iphan: 'PE2607901BAST00002',
        no_logrado: 'Rodovia BR 232, Duas Unas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-35.040255, -8.100804] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22645.0,
        identifica: 'Arataca I',
        co_iphan: 'PE2606200BAST00002',
        no_logrado: 'Vila Ubu',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.933218, -7.69483] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22646.0,
        identifica: 'Mereré I',
        co_iphan: 'PE2606200BAST00003',
        no_logrado: 'Alto do Mereré, Parcela 15, 108 e 109',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.947747, -7.701093] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22647.0,
        identifica: 'Mereré II',
        co_iphan: 'PE2606200BAST00004',
        no_logrado: 'Alto Mereré, Parcela 119 e 120',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.946587, -7.695222] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22648.0,
        identifica: 'Sítio Empresarial Armazem do Porto',
        co_iphan: 'PE2611606BAST00003',
        no_logrado: 'Bairro do Recife',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-34.872158, -8.059742] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37451.0,
        identifica: 'Bálsamo',
        co_iphan: 'PE2610905BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Bálsamo, localizado(a) no estado de Pernambuco, cidade(s) de Pesqueira, é um Bem Arqueológico, do tipo Sítio. O local apresenta uma ocupação lítica, de baixa densidade, com uma indústria sobre lascas unipolares e artefatos em quartzitos. Além de',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.681624, -8.375572] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7009.0,
        identifica: 'Fortim do Queijo',
        co_iphan: 'PE2609600BAST00002',
        no_logrado: 'Avenida do Farol, s/n, Carmo.',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.846119, -8.015838] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7038.0,
        identifica: 'São Bernardo',
        co_iphan: 'PE2609907BAST00001',
        no_logrado: 'Sítio Santo Antônio',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.506297, -7.980743] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7039.0,
        identifica: 'Santo Antero',
        co_iphan: 'PE2609907BAST00002',
        no_logrado: 'Cara Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.53259, -7.974742] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7040.0,
        identifica: 'Cara Branca',
        co_iphan: 'PE2609907BAST00003',
        no_logrado: 'Cara Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.512468, -7.998688] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7041.0,
        identifica: 'Cacimba dos Caboclos',
        co_iphan: 'PE2609907BAST00004',
        no_logrado: 'Cara Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.542546, -7.971513] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46545.0,
        identifica: 'Pedra da Escritura',
        co_iphan: 'PE2601706BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de topo, contendo pinturas rupestres posicionadas no setor norte dos sítio.',
        dt_cadastr: '2023-07-28',
      },
      geometry: { type: 'Point', coordinates: [-36.448871, -8.193428] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46779.0,
        identifica: 'Pedra do Riacho da Velha',
        co_iphan: 'PE2605608BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento de gnaisse, localizado na margem direita do Rio Pajeú, contendo gravuras rupestres.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-37.979606, -7.864995] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46782.0,
        identifica: 'Pedra do Riacho do Tapa Olho',
        co_iphan: 'PE2603926BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões em gnaisse situados na margem leste do Riacho Tapa Olho, contendo pinturas rupestres posicionadas nos setores norte e oeste do sítio.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-38.624195, -8.543658] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46783.0,
        identifica: 'Pedra do Risco',
        co_iphan: 'PE2613909BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico contendo pinturas rupestres em sua porção oeste.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-38.402918, -8.173642] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46784.0,
        identifica: 'Pedra do Serrote Preto I',
        co_iphan: 'PE2605707BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pedra do Serrote Preto I, localizado(a) no estado de Pernambuco, cidade(s) de Floresta, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-38.318087, -8.388901] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46785.0,
        identifica: 'Pedra do Serrote Preto II',
        co_iphan: 'PE2605707BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está localizado num afloramento de gnaisse, posicionado no plano aluvial, contendo uma área abrigada na base do afloramento, onde jazem as pinturas.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-38.318396, -8.389073] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46787.0,
        identifica: 'Pedra do Tamanduá',
        co_iphan: 'PE2605103BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, localizado na baixa vertente da Serra do tamanduá, contendo pinturas e gravuras rupestres posicionadas no setor leste do sítio.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-37.702581, -8.003878] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14297.0,
        identifica: 'Trindade',
        co_iphan: 'PE2615607BAST00001',
        no_logrado: 'área da periferia de Trindade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.307924, -7.762628] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 13732.0,
        identifica: 'Gravatá VI',
        co_iphan: 'PE2602001BAST00002',
        no_logrado: 'Fazenda Gravatá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.9081, -7.957124] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 13653.0,
        identifica: 'Poço Preto',
        co_iphan: 'PE2600807BAST00001',
        no_logrado: 'Bairro Poço Preto',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.024377, -8.479341] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 13686.0,
        identifica: 'Pitombeira',
        co_iphan: 'PE2601102BAST00002',
        no_logrado: 'Sítio Pitombeira',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.470545, -7.837803] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 13687.0,
        identifica: 'Serrinha',
        co_iphan: 'PE2601102BAST00003',
        no_logrado: 'Sítio Serrinha',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.383893, -7.833711] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 13733.0,
        identifica: 'Gravatá VII',
        co_iphan: 'PE2602001BAST00003',
        no_logrado: 'Fazenda Gravatá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.907797, -7.959575] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 13734.0,
        identifica: 'Gravatá IX',
        co_iphan: 'PE2602001BAST00004',
        no_logrado: 'Fazenda Gravatá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.905248, -7.964058] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 13731.0,
        identifica: 'Gravatá I',
        co_iphan: 'PE2602001BAST00001',
        no_logrado:
          'Margem esquerda da BR-316 (sentido Ouricuri-Parnamirim), próximo à ponte de concreto sobre...',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.909582, -7.954434] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14136.0,
        identifica: 'Barra de São Pedro II',
        co_iphan: 'PE2609907BAST00005',
        no_logrado: 'Comunidade Barra de São Pedro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.334523, -7.855755] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14137.0,
        identifica: 'Barra de São Pedro I',
        co_iphan: 'PE2609907BAST00006',
        no_logrado: 'Povoado de Barra de São Pedro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.332396, -7.857245] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14139.0,
        identifica: 'Chapada do Tamburi',
        co_iphan: 'PE2609907BAST00008',
        no_logrado: 'Chapada do Tamburi',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.174548, -7.915939] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14141.0,
        identifica: 'Capim Grosso I',
        co_iphan: 'PE2609907BAST00010',
        no_logrado: 'Córrego Capim Grosso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.08429, -7.92899] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14142.0,
        identifica: 'Capim Grosso II',
        co_iphan: 'PE2609907BAST00011',
        no_logrado: 'Córrego Capim Grosso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.080842, -7.92871] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14143.0,
        identifica: 'Capim Grosso III',
        co_iphan: 'PE2609907BAST00012',
        no_logrado: 'Córrego Capim Grosso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.079518, -7.928695] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14144.0,
        identifica: 'Capim Grosso IV',
        co_iphan: 'PE2609907BAST00013',
        no_logrado: 'Córrego Capim Grosso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.078928, -7.928687] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14171.0,
        identifica: 'Fazenda Favela',
        co_iphan: 'PE2610400BAST00002',
        no_logrado: 'Fazenda Favela',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.728732, -8.038103] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14174.0,
        identifica: 'Piomonte I',
        co_iphan: 'PE2610400BAST00005',
        no_logrado: 'Fazenda Piomonte',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.601959, -8.100113] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14175.0,
        identifica: 'Piomonte II',
        co_iphan: 'PE2610400BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.592768, -8.0965] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14176.0,
        identifica: 'Fazenda Olinda I',
        co_iphan: 'PE2610400BAST00007',
        no_logrado: 'Fazenda Olinda',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.586103, -8.100652] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14177.0,
        identifica: 'Fazenda Olinda II',
        co_iphan: 'PE2610400BAST00008',
        no_logrado: 'Fazenda Olinda',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.584531, -8.099135] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14178.0,
        identifica: 'Cacheiro',
        co_iphan: 'PE2610400BAST00009',
        no_logrado: 'Saibreira Cacheiro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.558959, -8.122823] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14179.0,
        identifica: 'Quixaba II',
        co_iphan: 'PE2610400BAST00010',
        no_logrado: 'Fazenda Quixaba',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.765603, -8.024107] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14180.0,
        identifica: 'Quixaba III',
        co_iphan: 'PE2610400BAST00011',
        no_logrado: 'Fazenda Quixaba',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.763452, -8.028733] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14233.0,
        identifica: 'Fazenda Simoa',
        co_iphan: 'PE2612208BAST00004',
        no_logrado: 'Fazenda Simoa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.233516, -8.067176] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14234.0,
        identifica: 'Aparício Sampaio',
        co_iphan: 'PE2612208BAST00005',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.139815, -7.984606] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14269.0,
        identifica: 'Fazenda Gama 2',
        co_iphan: 'PE2613909BAST00001',
        no_logrado: 'Fazenda Gama',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.21365, -8.045314] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14270.0,
        identifica: 'Fazenda Gama 1',
        co_iphan: 'PE2613909BAST00002',
        no_logrado: 'Fazenda Gama',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.211101, -8.045029] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14280.0,
        identifica: 'Fazenda Veado III',
        co_iphan: 'PE2615201BAST00001',
        no_logrado: 'Fazenda Veado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.40078, -8.090564] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14281.0,
        identifica: 'Fazenda Veado II',
        co_iphan: 'PE2615201BAST00002',
        no_logrado: 'Fazenda Veado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.399428, -8.090358] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14282.0,
        identifica: 'Fazenda Veado I',
        co_iphan: 'PE2615201BAST00003',
        no_logrado: 'Fazenda Veado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.396078, -8.089746] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14490.0,
        identifica: 'Sítio Arco Iris III',
        co_iphan: 'PE2600054BAST00001',
        no_logrado: 'Desterro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.924869, -7.890573] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14491.0,
        identifica: 'Sítio Arco Iris II',
        co_iphan: 'PE2600054BAST00002',
        no_logrado: 'Desterro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.921679, -7.890931] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14492.0,
        identifica: 'Sítio Arco-Iris 1',
        co_iphan: 'PE2600054BAST00003',
        no_logrado: 'Desterro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.920811, -7.889616] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14493.0,
        identifica: 'Sítio Arco Iris V',
        co_iphan: 'PE2600054BAST00004',
        no_logrado: 'Desterro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.919911, -7.89499] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14494.0,
        identifica: 'Sítio Arco Iris IV',
        co_iphan: 'PE2600054BAST00005',
        no_logrado: 'Desterro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.916444, -7.896181] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14495.0,
        identifica: 'Sítio Arco Iris VI',
        co_iphan: 'PE2600054BAST00006',
        no_logrado: 'Desterro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.908414, -7.895061] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14497.0,
        identifica: 'Sítio Jatobá',
        co_iphan: 'PE2600054BAST00008',
        no_logrado: 'Porto Jatobá - Município de Abreu e Lima',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.882099, -7.898579] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14498.0,
        identifica: 'Sítio São Bento II',
        co_iphan: 'PE2600054BAST00009',
        no_logrado: 'São Bento /Jaguaribe',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.874221, -7.899049] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14507.0,
        identifica: 'Palmeira',
        co_iphan: 'PE2602902BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.052275, -8.326876] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14508.0,
        identifica: 'Gaibu',
        co_iphan: 'PE2602902BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.035759, -8.325723] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14509.0,
        identifica: 'Lascado',
        co_iphan: 'PE2602902BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.032304, -8.326265] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14516.0,
        identifica: 'Jussaral',
        co_iphan: 'PE2604205BAST00001',
        no_logrado: 'Laje Grande',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.763172, -8.601087] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14517.0,
        identifica: 'Granito',
        co_iphan: 'PE2604205BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.731476, -8.596678] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14519.0,
        identifica: 'Cachaça',
        co_iphan: 'PE2605202BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.253329, -8.437197] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14520.0,
        identifica: 'Macaxeira',
        co_iphan: 'PE2605202BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.184512, -8.35327] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14523.0,
        identifica: 'Carroça',
        co_iphan: 'PE2605905BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.463321, -8.606558] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14524.0,
        identifica: 'Cuiambuca',
        co_iphan: 'PE2605905BAST00002',
        no_logrado: 'CuIambuca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.447354, -8.594595] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14525.0,
        identifica: 'Água',
        co_iphan: 'PE2605905BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.445469, -8.593803] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14526.0,
        identifica: 'Garapa',
        co_iphan: 'PE2605905BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.412691, -8.595483] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14527.0,
        identifica: 'Riacho',
        co_iphan: 'PE2605905BAST00005',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.399733, -8.56658] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14528.0,
        identifica: 'Aguardente',
        co_iphan: 'PE2605905BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.376507, -8.562277] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14533.0,
        identifica: 'Lamaçal',
        co_iphan: 'PE2607208BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.139081, -8.320723] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14535.0,
        identifica: 'Crauaçú 1',
        co_iphan: 'PE2607208BAST00005',
        no_logrado: 'Fazenda Crauaçú',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.109427, -8.387594] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14536.0,
        identifica: 'Chico Lapada',
        co_iphan: 'PE2607208BAST00006',
        no_logrado: 'Bairro da Pedra Velha',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.06668, -8.387341] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14556.0,
        identifica: 'RNEST- 22',
        co_iphan: 'PE2607208BAST00026',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.032448, -8.387428] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14557.0,
        identifica: 'RNEST- 09',
        co_iphan: 'PE2607208BAST00027',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.031647, -8.374641] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14558.0,
        identifica: 'RNEST- 08',
        co_iphan: 'PE2607208BAST00028',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.030991, -8.370811] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14559.0,
        identifica: 'RNEST- 04',
        co_iphan: 'PE2607208BAST00029',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.03025, -8.369631] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14560.0,
        identifica: 'RNEST- 24',
        co_iphan: 'PE2607208BAST00030',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.030257, -8.39017] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14561.0,
        identifica: 'RNEST- 21',
        co_iphan: 'PE2607208BAST00031',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.030146, -8.381103] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14562.0,
        identifica: 'RNEST- 20',
        co_iphan: 'PE2607208BAST00032',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.029948, -8.386763] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14563.0,
        identifica: 'RNEST- 01',
        co_iphan: 'PE2607208BAST00033',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.027099, -8.374646] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14564.0,
        identifica: 'RNEST- 05',
        co_iphan: 'PE2607208BAST00034',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.026829, -8.373409] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14565.0,
        identifica: 'RNEST- 28',
        co_iphan: 'PE2607208BAST00035',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.026496, -8.38453] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14566.0,
        identifica: 'RNEST- 02',
        co_iphan: 'PE2607208BAST00036',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.025935, -8.37788] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14567.0,
        identifica: 'RNEST- 29',
        co_iphan: 'PE2607208BAST00037',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.02493, -8.387105] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14569.0,
        identifica: 'RNEST- 07',
        co_iphan: 'PE2607208BAST00039',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.023355, -8.37575] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14570.0,
        identifica: 'RNEST- 10',
        co_iphan: 'PE2607208BAST00040',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.02298, -8.375029] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14571.0,
        identifica: 'RNEST- 30',
        co_iphan: 'PE2607208BAST00041',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.022598, -8.394241] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14572.0,
        identifica: 'RNEST- 18',
        co_iphan: 'PE2607208BAST00042',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.021828, -8.385847] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14573.0,
        identifica: 'RNEST- 11',
        co_iphan: 'PE2607208BAST00043',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.016164, -8.375299] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14574.0,
        identifica: 'RNEST- 12',
        co_iphan: 'PE2607208BAST00044',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.016133, -8.378138] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14575.0,
        identifica: 'RNEST- 13',
        co_iphan: 'PE2607208BAST00045',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.015907, -8.381918] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14576.0,
        identifica: 'RNEST- 15',
        co_iphan: 'PE2607208BAST00046',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.015445, -8.385662] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14577.0,
        identifica: 'RNEST- 14',
        co_iphan: 'PE2607208BAST00047',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.014526, -8.383407] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14578.0,
        identifica: 'RNEST- 17',
        co_iphan: 'PE2607208BAST00048',
        no_logrado: 'Área Portuária de Suape',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.013858, -8.373485] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14579.0,
        identifica: 'Crauaçú 2',
        co_iphan: 'PE2607208BAST00049',
        no_logrado: 'Usina Ipojuca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.114066, -8.379316] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14583.0,
        identifica: 'Curva',
        co_iphan: 'PE2608206BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.51761, -8.633669] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14589.0,
        identifica: 'Torres',
        co_iphan: 'PE2610004BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.579558, -8.647327] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14590.0,
        identifica: 'Vento',
        co_iphan: 'PE2610004BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.563741, -8.646168] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14609.0,
        identifica: 'Canabrava',
        co_iphan: 'PE2611804BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.337096, -8.554193] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14610.0,
        identifica: 'Amontoado',
        co_iphan: 'PE2611804BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.298271, -8.50889] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14611.0,
        identifica: 'Caninha',
        co_iphan: 'PE2611804BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.288671, -8.483802] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14612.0,
        identifica: 'Fazenda Vitoso 2',
        co_iphan: 'PE2611804BAST00004',
        no_logrado: 'Fazenda Vitoso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.416897, -8.484827] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14613.0,
        identifica: 'Fazenda Vitoso 1',
        co_iphan: 'PE2611804BAST00005',
        no_logrado: 'Fazenda Vitoso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.416706, -8.483265] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14614.0,
        identifica: 'Fazenda Segredo',
        co_iphan: 'PE2611804BAST00006',
        no_logrado: 'Fazenda Segredo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.414753, -8.484452] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14620.0,
        identifica: 'CONVENTO DE SÃO FRANCISCO DE SIRINHAÉM',
        co_iphan: 'PE2614204BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.119111, -8.589256] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46622.0,
        identifica: 'Pedra da Mão I',
        co_iphan: 'PE2612208BAST00025',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado a 14 m dos olhos d?água que originam a lagoa, contendo pinturas rupestres. O matacão possui duas pequenas áreas abrigadas, nos setores leste e oeste do sítio; as pinturas jazem no setor oeste.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-38.93685, -8.296498] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46623.0,
        identifica: 'Pedra da Mão II',
        co_iphan: 'PE2612208BAST00026',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio é formado por um conjunto de blocos graníticos, assentados sobre o embasamento rochso; no setor sudoeste jazem as pinturas ruprestres.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-38.94783, -8.286704] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37452.0,
        identifica: 'Alto do Mulungu',
        co_iphan: 'PE2612406BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Alto do Mulungu, localizado(a) no estado de Pernambuco, cidade(s) de Sanharó, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de cerâmica de produção local/regional, vidros e louças.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.525208, -8.433265] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37453.0,
        identifica: 'Palma I',
        co_iphan: 'PE2613008BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Palma I, localizado(a) no estado de Pernambuco, cidade(s) de São Bento do Una, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de cerâmica de produção local/regional, faiança fina e vidros.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.481748, -8.418529] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37461.0,
        identifica: 'Travessia',
        co_iphan: 'PE2600807BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Travessia, localizado(a) no estado de Pernambuco, cidade(s) de Altinho, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas, artefato polido e percutores de quartzito e quartzo leitoso, fragmentos de cerâmica de produção local/regional.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.171585, -8.473111] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37463.0,
        identifica: 'São Francisco',
        co_iphan: 'PE2600807BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'São Francisco , localizado(a) no estado de Pernambuco, cidade(s) de Altinho, é um Bem Arqueológico, do tipo Sítio. O local apresenta material lascado em quartzo leitoso, quartzito e arenito e percutores em quartzito, fragmentos cerâmicos e de faian�',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.114815, -8.496212] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37402.0,
        identifica: 'Camaleão I',
        co_iphan: 'PE2613909BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Camaleão I, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta uma lâmina de machado polida e uma lasca em quartzito, assim como um bloco com polimento por marcas de uso em aren',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-38.347943, -8.02105] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37454.0,
        identifica: 'Palma II',
        co_iphan: 'PE2613008BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Palma II, localizado(a) no estado de Pernambuco, cidade(s) de São Bento do Una, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de cerâmica de produção local/regional, vidro e louça.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.480521, -8.418747] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46625.0,
        identifica: 'Pedra da Moça',
        co_iphan: 'PE2608107BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra Negra, contendo no setor noroeste uma área abrigada, onde jazem as pinturas rupestres.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-35.663168, -7.845356] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46801.0,
        identifica: 'Pedra Pintada',
        co_iphan: 'PE2614105BAST00034',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos, localizados sobre um afloramento rochoso, em situação topográfica de alta vertente. No setor oeste do sítio verifica-se uma área abrigada.  A superfície pictural é composta por gravuras rupestres.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-37.296155, -8.069887] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10988.0,
        identifica: 'Pedra da Pintura',
        co_iphan: 'PE2602605BAST00013',
        no_logrado: 'Logradouro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na margem do Riacho Quixabeira, contendo nos setores noroeste e sudoeste áreas abrigadas, onde jazem pinturas rupestres.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.307909, -8.019222] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14449.0,
        identifica: 'Pedra Pintada',
        co_iphan: 'PE2600609BAST00001',
        no_logrado: 'Socorro - Fazenda Pedra Pintada',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está formado por um único matacão de granito de três metros de altura, com paredes verticais, sem formar abrigo. Apresenta um único painel de pinturas executadas nas cores vermelho e amarelo.As pinturas estão localizadas na face mais prote',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.751171, -8.563562] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14450.0,
        identifica: 'Pedra do Tubarão',
        co_iphan: 'PE2616001BAST00003',
        no_logrado: 'Sítio Pedrinhas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na base do vale entre duas serras, no qual encontram-se pinturas rupestres em várias tonalidades de vermelho e na cor amarela. Matacão granítico situado na baixa vertente da Serra do Buco. O conjunto pictural é composto por',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.797585, -8.629334] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46633.0,
        identifica: 'Pedra da Várzea do Exú I',
        co_iphan: 'PE2613008BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento rochoso, posicionado na alta vertente da Serra do Poço da Areia, contendo pinturas rupestres posicionadas no setor noroeste.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-36.464616, -8.659824] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46788.0,
        identifica: 'Pedra do Velho Joaquim',
        co_iphan: 'PE2612208BAST00029',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio contém dois matacões granpiticos, cujo arranjo natural forma uma área abrigada no setor oeste. As pinturas rupestres estão localizadas nos setores oeste e leste do sítio.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-38.941972, -8.290782] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46635.0,
        identifica: 'Pedra da Várzea do Exú II',
        co_iphan: 'PE2605707BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio está posicionado no topo da Serra do Poço da Areia, condicionado por blocos graníticos cuja disposição forma uma área abrigada de 32m². As pinturas estão situadas em três blocos distintos, localizados nos setores leste e nordeste do sí',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-38.464307, -8.659868] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46637.0,
        identifica: 'Pedra da Velha Chiquinha',
        co_iphan: 'PE2600609BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão situado na baixa vertente da Serra do Gavião, contendo uma gruta. As pinturas rupestres encontram-se nas faces do paredão rochoso, na parte externa da gruta.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-36.822036, -8.428907] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46790.0,
        identifica: 'Pedra dos Índios',
        co_iphan: 'PE2607703BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente do Serrote da Cacimba Salgada, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-37.243083, -7.323906] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46791.0,
        identifica: 'Pedra dos Namorados',
        co_iphan: 'PE2605608BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico, localizado no leito do Rio Pajeú, contendo gravuras rupestres. Dimensões do afloramento rochoso: 9.20 m de comprimento por 7 m de largura e 6 m de altura',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-37.967303, -7.870392] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46638.0,
        identifica: 'Pedra das Abelhas',
        co_iphan: 'PE2601607BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo situado num testemunho arenítico, posicionado na baixa vertente da Serra da Jiboia, contendo uma área abrigada. As pinturas e as gravuras encontram-se nos setores nordeste e noroeste da área abrigada.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-38.939763, -8.366782] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46792.0,
        identifica: 'Pedra dos Primatas',
        co_iphan: 'PE2602605BAST00055',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-36.431176, -8.13934] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46802.0,
        identifica: 'Pedra Pintada do Pedrinho I',
        co_iphan: 'PE2607505BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na baixa vertente de um Inselberg denominado localmente como Serrote do Mamoeiro, contendo pinturas rupestres posicionadas no setor leste.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-37.15252, -9.002924] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46803.0,
        identifica: 'Pedra Pintada do Pedrinho II',
        co_iphan: 'PE2607505BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na alta vertente do Serrote do Mamoeiro, contendo pinturas rupestres posicionadas no setor sudoeste.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-37.152335, -9.003529] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46794.0,
        identifica: 'Pedra Escrevida',
        co_iphan: 'PE2611101BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, localizado na média vertente da Serra da Santa, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-40.394843, -9.220996] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14452.0,
        identifica: 'Pedra Redonda',
        co_iphan: 'PE2610806BAST00001',
        no_logrado: 'Fazenda Pedra Redonda',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de baixa vertente no Riacho Pedra Redonda. São pinturas rupestres posicionadas nos setores oeste e norte do sítio.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.847536, -8.674016] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46805.0,
        identifica: 'Pedreira',
        co_iphan: 'PE2602209BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos, localizados na área urbana do Povoado de Ramos, contendo pinturas rupestres posicionadas no setor leste do sítio, em uma área abrigada.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-35.658246, -7.835086] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7415.0,
        identifica: 'Residência de Funcionário do Presídio I',
        co_iphan: 'PE2605459BAST00002',
        no_logrado: 'Vila dos Remédios',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-32.401002, -3.840068] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22553.0,
        identifica: 'Pedra das Emas',
        co_iphan: 'PE2612604BAST00001',
        no_logrado: 'Manga',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento rochoso no leito do Riacho Manga, contendo gravuras rupestres.',
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-39.703199, -8.723805] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19491.0,
        identifica: 'Arado',
        co_iphan: 'PE2605202BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.174413, -8.352078] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18661.0,
        identifica: 'Curral Novo',
        co_iphan: 'PE2600500BAST00001',
        no_logrado: 'Garcia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.993619, -8.901245] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18677.0,
        identifica: 'Cavaco II',
        co_iphan: 'PE2601102BAST00004',
        no_logrado: 'Serra do Cavaco',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.559024, -7.506617] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18678.0,
        identifica: 'Torre VIII',
        co_iphan: 'PE2601102BAST00005',
        no_logrado: 'SERRA DA TORRE',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.552325, -7.59211] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18680.0,
        identifica: 'Cavaco I',
        co_iphan: 'PE2601102BAST00007',
        no_logrado: 'Serra do Cavaco',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.604168, -7.505514] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18697.0,
        identifica: 'Fazenda Juazeiro',
        co_iphan: 'PE2601706BAST00002',
        no_logrado: 'Sítio Trapiá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.216364, -8.222917] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18714.0,
        identifica: 'Morro do Frade',
        co_iphan: 'PE2602803BAST00012',
        no_logrado: 'Sítio Barra do Caldeirão',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.053055, -8.91446] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18790.0,
        identifica: 'Itaíba',
        co_iphan: 'PE2607505BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.386829, -8.982588] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18804.0,
        identifica: 'Claudionor',
        co_iphan: 'PE2608057BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.262549, -9.183584] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18805.0,
        identifica: 'Jatobá 6',
        co_iphan: 'PE2608057BAST00005',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.256961, -9.179132] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18806.0,
        identifica: 'Jatobá 12',
        co_iphan: 'PE2608057BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.178069, -9.186151] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18807.0,
        identifica: 'Jatobá 11',
        co_iphan: 'PE2608057BAST00007',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.153096, -9.177565] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18808.0,
        identifica: 'Jatobá 10',
        co_iphan: 'PE2608057BAST00008',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.146148, -9.17517] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18809.0,
        identifica: 'Jatobá 1',
        co_iphan: 'PE2608057BAST00009',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.141065, -9.173394] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18810.0,
        identifica: 'Jatobá 8',
        co_iphan: 'PE2608057BAST00010',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.138673, -9.172556] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18811.0,
        identifica: 'Jatobá 9',
        co_iphan: 'PE2608057BAST00011',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.135999, -9.171655] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18859.0,
        identifica: 'Cruzes',
        co_iphan: 'PE2610202BAST00001',
        no_logrado: 'Cruzes',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.108164, -8.639066] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18860.0,
        identifica: 'Serra d Água',
        co_iphan: 'PE2610202BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.093883, -8.624946] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18861.0,
        identifica: 'Panelas 2',
        co_iphan: 'PE2610202BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.043468, -8.579275] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18862.0,
        identifica: 'Grota',
        co_iphan: 'PE2610202BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.026196, -8.562963] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18884.0,
        identifica: 'Sítio 15/2',
        co_iphan: 'PE2611002BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.238805, -9.027403] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18885.0,
        identifica: 'Sítio PL 07',
        co_iphan: 'PE2611002BAST00003',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.231137, -9.013712] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18886.0,
        identifica: 'Sítio 43/1',
        co_iphan: 'PE2611002BAST00004',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.281847, -8.828006] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18887.0,
        identifica: 'PETROLÂNDIA I SPE I',
        co_iphan: 'PE2611002BAST00005',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.219196, -8.96521] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18913.0,
        identifica: 'Brejinho',
        co_iphan: 'PE2613206BAST00001',
        no_logrado: 'Sítio Brejinho',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.403139, -8.876792] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18935.0,
        identifica: 'Sítio PL 09',
        co_iphan: 'PE2614808BAST00001',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.189081, -8.959682] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18938.0,
        identifica: 'Tupanatinga',
        co_iphan: 'PE2615805BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.081856, -8.919564] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20974.0,
        identifica: 'Pedra das Pinturas',
        co_iphan: 'PE2613602BAST00001',
        no_logrado: 'Lagoa da Serra',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico situado na alta vertente do Serrote das Pinturas. São pinturas rupestres posicionadas nos setores sudeste, nordeste e noroeste do sítio.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.143352, -7.524654] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19077.0,
        identifica: 'Jatobá 13',
        co_iphan: 'PE2608057BAST00012',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.180606, -9.187124] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46639.0,
        identifica: 'Pedra de Antônio Raimundo',
        co_iphan: 'PE2612208BAST00027',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, assentado sobre embasamento rochoso, contendo pinturas rupestres situadas no setor norte.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-38.943688, -8.29299] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46640.0,
        identifica: 'Pedra do Barbeiro',
        co_iphan: 'PE2616001BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na baixa vertente do Serrote do Barbado, contendo pinturas rupestres posicionadas nos setores sudeste e nordeste do sítio.',
        dt_cadastr: '2023-08-03',
      },
      geometry: { type: 'Point', coordinates: [-36.819442, -8.568301] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19242.0,
        identifica: 'Santa Teresa 2',
        co_iphan: 'PE2600302BAST00001',
        no_logrado: 'Vila Santa Teresa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.957803, -8.500391] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19252.0,
        identifica: 'Casa de Dona Sinhá',
        co_iphan: 'PE2606408BAST00001',
        no_logrado: 'Fazenda Generosa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.634297, -8.152998] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19291.0,
        identifica: 'Sairé I',
        co_iphan: 'PE2612000BAST00001',
        no_logrado: 'Fazenda Riacho do Mel',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.718998, -8.254668] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19292.0,
        identifica: 'Sairé II',
        co_iphan: 'PE2612000BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.712198, -8.24444] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19302.0,
        identifica: 'Engenho Monjope',
        co_iphan: 'PE2606804BAST00001',
        no_logrado: 'Estrada do Monjope, Bairro Cruz das Rebouças',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.917077, -7.863866] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19458.0,
        identifica: 'Gravatá II',
        co_iphan: 'PE2602001BAST00005',
        no_logrado:
          'Margem esquerda da BR-316 (sentido Ouricuri-Parnamirim), próximo à ponte de concreto sobre...',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.910788, -7.954305] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19459.0,
        identifica: 'Gravatá IV',
        co_iphan: 'PE2602001BAST00006',
        no_logrado: 'Fazenda Gravatá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.908918, -7.962087] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19460.0,
        identifica: 'Gravatá III',
        co_iphan: 'PE2602001BAST00007',
        no_logrado: 'Fazenda Gravatá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.908283, -7.962306] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19461.0,
        identifica: 'Gravatá VIII',
        co_iphan: 'PE2602001BAST00008',
        no_logrado: 'Fazenda Gravatá',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.906458, -7.965512] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19482.0,
        identifica: 'Sítio Olaria (IC-OL)',
        co_iphan: 'PE2602902BAST00011',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.964952, -8.376235] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19483.0,
        identifica: 'Sítio Cambôa da Pedrinha (IC-CP)',
        co_iphan: 'PE2602902BAST00012',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.964711, -8.377023] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19484.0,
        identifica: 'Sítio Armazém (IC-AR)',
        co_iphan: 'PE2602902BAST00013',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.96348, -8.375881] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19485.0,
        identifica: 'Sítio Casa do Francês (IC-CFR)',
        co_iphan: 'PE2602902BAST00014',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.963075, -8.37478] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19486.0,
        identifica: 'Sítio Casa de Farinha (IC-CF) / Casa de Joaquim',
        co_iphan: 'PE2602902BAST00015',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.962799, -8.375884] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19487.0,
        identifica: 'Sítio Forte do Pontal (IC-FP)',
        co_iphan: 'PE2602902BAST00016',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.962793, -8.376571] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19488.0,
        identifica: 'Sítio IC01-27',
        co_iphan: 'PE2602902BAST00017',
        no_logrado: 'Ilha de Cocaia',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.960915, -8.375071] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19492.0,
        identifica: 'Rio Sapucaí 2',
        co_iphan: 'PE2605202BAST00007',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.231011, -8.422004] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19493.0,
        identifica: 'Rio Sapucaí 1',
        co_iphan: 'PE2605202BAST00008',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.224932, -8.408354] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46795.0,
        identifica: 'Pedra Escrevida',
        co_iphan: 'PE2603926BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na baixa vertente da Serra do Arapuá, contendo uma área abrigada de 22,80 m². As pinturas rupestres estão localizadas nas porções noroeste e sudeste da área abrigada.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-38.773918, -8.435674] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19681.0,
        identifica: 'Sítio 28/2',
        co_iphan: 'PE2611002BAST00006',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.20986, -8.933019] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19699.0,
        identifica: 'Socorro',
        co_iphan: 'PE2606408BAST00002',
        no_logrado: 'Socorro',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.48983, -8.06823] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46797.0,
        identifica: 'Pedra Fish',
        co_iphan: 'PE2616001BAST00028',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente da Serra do Buco, contendo pinturas rupestres posicionadas nos setores sudoeste, leste e nordeste.',
        dt_cadastr: '2023-08-10',
      },
      geometry: { type: 'Point', coordinates: [-36.775271, -8.61346] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19870.0,
        identifica: 'Aldeia dos Sentenciados',
        co_iphan: 'PE2605459BAST00003',
        no_logrado: 'Vila dos Remédios',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-32.402974, -3.841246] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 27282.0,
        identifica: 'Casa 100',
        co_iphan: 'PE2609600BAST00005',
        no_logrado: 'Liberdade n 100 - Carmo',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Casa 100, localizado(a) no estado de Pernambuco, cidade(s) de Olinda, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.\r\nEdificação do século 16/17 Torreão quadricular com telhado quatro �',
        dt_cadastr: '2020-03-23',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.848786140439998, -8.015988918649988],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 27273.0,
        identifica: 'PE 0770 LA/UFPE',
        co_iphan: 'PE2611606BAST00004',
        no_logrado: '-',
        nu_logrado: '-',
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'PE 0770 LA/UFPE, também conhecido(a) como Cemitério do Engenho Madalena ou do Túnel da Abolição, localizado(a) no estado de Pernambuco, cidade(s) de Recife, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Péssimo e de preserva�',
        dt_cadastr: '2020-03-16',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.909365804023999, -8.0568115911873],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37455.0,
        identifica: 'Riachão',
        co_iphan: 'PE2603108BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Riachão, localizado(a) no estado de Pernambuco, cidade(s) de Cachoeirinha, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas bipolares e unipolares de quartzito produzidas a partir de seixos.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.324726, -8.436638] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37370.0,
        identifica: 'Canafístula I',
        co_iphan: 'PE2613909BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Canafístula I, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefatos cerâmicos de produção local/regional, louças, vidro, metais e telhas capa canal, artefatos lítico',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-38.552937, -8.029039] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 25049.0,
        identifica: 'S.A. Barro Preto',
        co_iphan: 'PE2608453BAST00004',
        no_logrado: 'nas próximidades do Quilombo Barro Preto',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'S.A. Barro Preto , localizado(a) no estado de Pernambuco, cidade(s) de Lagoa do Carro, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.\r\n Local no terço superior de uma vertente suave com co',
        dt_cadastr: '2019-11-27',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.33852269178, -7.861359200835298],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 40565.0,
        identifica: 'Engenho São Bartolomeu',
        co_iphan: 'PE2607901BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio à céu aberto, com estruturas das edificações condizentes com a configuração de engenho.',
        dt_cadastr: '2022-06-20',
      },
      geometry: { type: 'Point', coordinates: [-34.965897, -8.178553] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7008.0,
        identifica: 'Ruínas do Forte do Buraco',
        co_iphan: 'PE2609600BAST00001',
        no_logrado: 'Istmo de Recife e Olinda',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.866879, -8.041376] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34479.0,
        identifica: 'Amargosa I',
        co_iphan: 'PE2611101BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O material estar distribuído na superficie do sítio, com maior concentração sob o embasamento identificado, sendo a única estrutura presente no local. Entre os materiais reconhecidos podemos citar: faiança fina, grés, moeda seculo XIX e vidro arte',
        dt_cadastr: '2021-10-13',
      },
      geometry: { type: 'Point', coordinates: [-40.554985, -8.992874] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34480.0,
        identifica: 'Amargosa II',
        co_iphan: 'PE2611101BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O material estar distribuído na superficie do sítio. Entre os materiais reconhecidos podemos citar: faiança fina, grés, cerâmica e vidro artesanal.',
        dt_cadastr: '2021-10-13',
      },
      geometry: { type: 'Point', coordinates: [-40.554121, -8.992842] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34478.0,
        identifica: 'Antiga Casa de Farinha da Amargosa',
        co_iphan: 'PE2611101BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O material estar distribuído na superficie do sítio, Entre os materiais reconhecidos podemos citar: faiança fina, grés e vidro artesanal.',
        dt_cadastr: '2021-10-13',
      },
      geometry: { type: 'Point', coordinates: [-40.554351, -8.993492] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 11019.0,
        identifica: 'Gravura do Meio',
        co_iphan: 'PE2608008BAST00002',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo gravuras rupestres posicionadas no setor leste.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.568245, -8.0254] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37465.0,
        identifica: 'Barragem',
        co_iphan: 'PE2600807BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Barragem, localizado(a) no estado de Pernambuco, cidade(s) de Altinho, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas grandes unipolares em quartzo leitoso e quartzito.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.075349, -8.474751] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37988.0,
        identifica: 'Residência de Miguel Caboclo',
        co_iphan: 'PE2614105BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Residência de Miguel Caboclo, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.46902, -8.130612] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37989.0,
        identifica: 'Casa de Josefa Salvador',
        co_iphan: 'PE2614105BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Casa de Josefa Salvador, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.46709, -8.130008] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 23119.0,
        identifica: 'Incó',
        co_iphan: 'PE2608057BAST00014',
        no_logrado: 'triunfo',
        nu_logrado: '20',
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Incó, localizado(a) no estado de Pernambuco, cidade(s) de Jatobá, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.\r\nTRATA-SE DE SUM SÍTIO MULTICOMPONECIAL COM MATERIAL CERÂMICO, LOUÇA E',
        dt_cadastr: '2019-07-25',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.266662919200002, -9.166100437640001],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37991.0,
        identifica: 'Cerca de Pedra',
        co_iphan: 'PE2614105BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Cerca de Pedra, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.463987, -8.127301] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37992.0,
        identifica: 'Cisterna do Dedé',
        co_iphan: 'PE2614105BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Cisterna do Dedé, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.455667, -8.127161] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37993.0,
        identifica: 'Muro do Dedé',
        co_iphan: 'PE2614105BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Muro do Dedé, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.454913, -8.127303] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 30971.0,
        identifica: 'Sítio Arqueológico São Bernardo',
        co_iphan: 'PE2609907BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um sítio com fragmentos de cerâmica produzida com técnica roletada e queima irregular (característica de grupos pré-históricos) e telhas de produção artesanal (histórica recente).',
        dt_cadastr: '2020-10-26',
      },
      geometry: { type: 'Point', coordinates: [-40.506324, -7.980743] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 30970.0,
        identifica: 'Sítio Arqueológico Santo Antero',
        co_iphan: 'PE2609907BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um sítio com fragmentos de cerâmica produzida com técnica de manufatura roletada e queima irregular (característica de grupos pré-históricos).',
        dt_cadastr: '2020-10-26',
      },
      geometry: { type: 'Point', coordinates: [-40.532599, -7.97476] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37994.0,
        identifica: 'Usina de Asafalto',
        co_iphan: 'PE2614105BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Usina de Asafalto, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.474275, -8.132693] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37995.0,
        identifica: 'Casa Rural Abadonada',
        co_iphan: 'PE2614105BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Casa Rural Abadonada, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.223805, -7.988669] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37996.0,
        identifica: 'Barragem',
        co_iphan: 'PE2614105BAST00020',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Barragem, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.228359, -7.994728] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37997.0,
        identifica: 'Casa',
        co_iphan: 'PE2614105BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Casa, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.228744, -7.995869] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37998.0,
        identifica: 'São Cristóvão',
        co_iphan: 'PE2614105BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'São Cristóvão, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-02-14',
      },
      geometry: { type: 'Point', coordinates: [-37.332305, -8.055009] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34017.0,
        identifica: 'Barrios',
        co_iphan: 'PE2600609BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'sítio arqueológico histórico, com presença de vestígios cerâmicos históricos, faianças finas e porcelanas em superfície, e vestígios cerâmicos, de louças, ósseos, têxteis, metálicos e plásticos a uma profundidade de até 20 cm, além de r',
        dt_cadastr: '2021-09-16',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.759743635168, -8.541801643926499],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37371.0,
        identifica: 'Canafístula II',
        co_iphan: 'PE2613909BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Canafístula II, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos cerâmicos de produção local/regional e faiança fina, material lítico em lascas e artefatos em s',
        dt_cadastr: '2022-01-10',
      },
      geometry: { type: 'Point', coordinates: [-38.551302, -8.029761] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 31031.0,
        identifica: 'Sítio Arqueológico Cara Branca',
        co_iphan: 'PE2609907BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um sítio com fragmentos de cerâmica produzida com técnica de manufatura roletada e queima irregular (característica de grupos pré-históricos). Também foram identificadas telhas artesanais, que são de cronologia histórica recente.',
        dt_cadastr: '2020-10-28',
      },
      geometry: { type: 'Point', coordinates: [-40.51245, -7.998733] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 20568.0,
        identifica: 'Engenho são joão',
        co_iphan: 'PE2613701BAST00002',
        no_logrado:
          'prospecção arqueológica intensiva na área da duplicação da rodovia br 408',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-35.088565, -7.968795] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37467.0,
        identifica: 'Santo Inácio',
        co_iphan: 'PE2600807BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Santo Inácio , localizado(a) no estado de Pernambuco, cidade(s) de Altinho, é um Bem Arqueológico, do tipo Sítio. O local apresenta vestígios e ruína de edificação, fragmentos cerâmicos de produção local/regional, vidros e louças.',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.035091, -8.474429] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37457.0,
        identifica: 'Camará',
        co_iphan: 'PE2603108BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Camará, localizado(a) no estado de Pernambuco, cidade(s) de Cachoeirinha, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefato bruto em hematita com marcas de utilização, uma lâmina de machado polida em granito e um fragmento de lasca',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-36.321375, -8.435585] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 32042.0,
        identifica: 'Sítio Ponta da Pedra',
        co_iphan: 'PE2601607BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio Ponta da Pedra, também conhecido(a) como SPP, localizado(a) no estado de Pernambuco, cidade(s) de Belém de São Francisco, é um Bem Arqueológico, do tipo Sítio.\r\nSítio arqueológico à céu aberto, com artefatos líticos e cerâmicos. Os art',
        dt_cadastr: '2020-12-22',
      },
      geometry: { type: 'Point', coordinates: [-38.966016, -8.737353] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38886.0,
        identifica: 'Aroeira',
        co_iphan: 'PE2600807BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de alta vertente, contendo \r\npinturas rupestres',
        dt_cadastr: '2022-03-11',
      },
      geometry: { type: 'Point', coordinates: [-36.064641, -8.559345] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37942.0,
        identifica: 'Engenho Coronel Alexandre',
        co_iphan: 'PE2603009BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Engenho Coronel Alexandre, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio. O local apresenta ruínas de tanque de garapa, alicerces de edificação em taipa, cerâmica e louça.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.350843, -8.248961] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38885.0,
        identifica: 'Alto da Pedra da Guia',
        co_iphan: 'PE2612208BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na alta vertente do Serrote da Guia, contendo uma área abrigada no setor oeste, onde jazem pinturas rupestres.',
        dt_cadastr: '2022-03-11',
      },
      geometry: { type: 'Point', coordinates: [-39.206216, -8.066751] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37943.0,
        identifica: 'Mulungu-Landim',
        co_iphan: 'PE2612208BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Mulungu-Landim, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas, fragmentos e instrumentos líticos.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.230846, -8.144935] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 39941.0,
        identifica: 'Ruína Forte Santo Antônio',
        co_iphan: 'PE2605459BAST00028',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Ruína Forte Santo Antônio, localizado(a) no estado de Pernambuco, cidade(s) de Fernando de Noronha, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-05-06',
      },
      geometry: {
        type: 'Point',
        coordinates: [-32.399768029163454, -3.832805359327441],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46202.0,
        identifica: 'Boca Torta',
        co_iphan: 'PE2601904BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente da Serra Negra, contendo no\r\nsetor norte uma área abrigada onde jazem pinturas rupestres',
        dt_cadastr: '2023-07-10',
      },
      geometry: { type: 'Point', coordinates: [-35.799978, -8.168136] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37948.0,
        identifica: 'Lagoa do Uri de Cima',
        co_iphan: 'PE2612208BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Lagoa do Uri de Cima, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos cerâmicos e materiais líticos (artefatos, lascas e estilhas).',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.179155, -8.077781] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37949.0,
        identifica: 'Engenhoca do Riachinho',
        co_iphan: 'PE2612208BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Engenhoca do Riachinho, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta a estrutura de um engenho',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.081937, -7.924155] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18739.0,
        identifica: 'Barra dos Nicos',
        co_iphan: 'PE2603801BAST00002',
        no_logrado: 'Barra',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.643377, -8.688348] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 44994.0,
        identifica: 'Sítio Antônio de Pádua 2',
        co_iphan: 'PE2605707BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio lítico com sílex, quartzo e quartzito, nas proximidades do Rio São Francisco.',
        dt_cadastr: '2023-03-09',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.592876501733009, -8.667850838110793],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37950.0,
        identifica: 'Pilões',
        co_iphan: 'PE2616100BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pilões, localizado(a) no estado de Pernambuco, cidade(s) de Verdejante, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos cerâmicos utilitários com decoração plástica vermelha, louças comuns, brancas e do tipo blue edge. Além',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.054967, -7.882025] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37951.0,
        identifica: 'Fazenda São Joaquim',
        co_iphan: 'PE2612208BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Fazenda São Joaquim, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas em quartzo e sílex.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.039533, -7.86115] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 45379.0,
        identifica: 'Sítio Arqueológico do Pititi',
        co_iphan: 'PE2602803BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio Arqueológico do Pititi, também conhecido(a) como Morro do Pititi  - antigo Pedra do Cachorro, localizado(a) no estado de Pernambuco, cidade(s) de Buíque, é um Bem Arqueológico, do tipo Sítio. O Sítio Arqueológico do Pititi (antigo pedra do',
        dt_cadastr: '2023-04-20',
      },
      geometry: { type: 'Point', coordinates: [-37.246758, -8.575261] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 3291.0,
        identifica: 'Ruínas do Forte do Buraco',
        co_iphan: 'PE2611606BAST00001',
        no_logrado: 'Istmo de Olinda e Recife',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Ruínas do Forte do Buraco, também conhecido(a) como Forte do Buraco, localizado(a) no estado de Pernambuco, cidade(s) de Recife, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Péssimo e de preservação Descaracterizado.',
        dt_cadastr: '2018-01-31',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.866966889435517, -8.04125064377175],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 27280.0,
        identifica: 'Alto da Sé',
        co_iphan: 'PE2609600BAST00004',
        no_logrado: 'BPO. Coutinho , Bonsucesso',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Alto da Sé, localizado(a) no estado de Pernambuco, cidade(s) de Olinda, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado.\r\n\r\nPrimeiro núcleo urbano de Olinda,  sede da capitania hereditária',
        dt_cadastr: '2020-03-23',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.850443103560004, -8.013341513789999],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46642.0,
        identifica: 'Pedra do Bidó',
        co_iphan: 'PE2603900BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, em situação topográfica de baixa vertente, contendo gravuras e pinturas rupestres posicionadas nos setores sul e sudoeste do sítio.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-37.679993, -7.929643] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 32997.0,
        identifica: 'Porteirão 01',
        co_iphan: 'PE2614808BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Porteirão 01, localizado(a) no estado de Pernambuco, cidade(s) de Tacaratu, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2021-04-30',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.148613946784998, -9.079534390027511],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 32998.0,
        identifica: 'Quixaba 01',
        co_iphan: 'PE2614808BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Quixaba 01, localizado(a) no estado de Pernambuco, cidade(s) de Tacaratu, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2021-04-30',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.048889029510001, -9.031274415862001],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 32999.0,
        identifica: 'Quixaba 02',
        co_iphan: 'PE2614808BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Quixaba 02, localizado(a) no estado de Pernambuco, cidade(s) de Tacaratu, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2021-04-30',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.029353888145998, -9.022052839241891],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46643.0,
        identifica: 'Pedra do Boi',
        co_iphan: 'PE2602209BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacões graníticos, em situação topográfica de média vertente, contendo pinturas rupestres posicionadas nos setores sudeste e nordeste do sítio, em uma área abrigada. Sítio também conhecido como Pedra do Urubu.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-35.655757, -7.83559] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37403.0,
        identifica: 'Varzinha',
        co_iphan: 'PE2613909BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Varzinha, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio. O local apresenta cinco áreas com marcas de utilização em afloramentos rochosos em fundo de riacho intermitente.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-38.110033, -8.039526] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 32696.0,
        identifica: 'Sítio Arqueológico Histórico Casa Velha da Fazenda Tapuio',
        co_iphan: 'PE2605707BAST00001',
        no_logrado: 'Fazenda Tapuio',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio Arqueológico Histórico Casa Velha da Fazenda Tapuio, também conhecido(a) como Casa Velha, localizado(a) no estado de Pernambuco, cidade(s) de Floresta, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2021-03-17',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.348612103679002, -8.637375534312003],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37404.0,
        identifica: 'Bom Jesus',
        co_iphan: 'PE2603405BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Bom Jesus, localizado(a) no estado de Pernambuco, cidade(s) de Calumbi, é um Bem Arqueológico, do tipo Sítio. O local apresenta cinco barramentos, construído em pedra seca e uma cacimba escavada no solo, associados aos cursos de riacho intermitente.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-38.050534, -8.046845] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37405.0,
        identifica: 'Reino da Pedra',
        co_iphan: 'PE2605608BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Reino da Pedra, localizado(a) no estado de Pernambuco, cidade(s) de Flores, é um Bem Arqueológico, do tipo Sítio. O local apresenta gravuras rupestres em afloramentos rochosos no leito de rio intermitente.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.954413, -8.053422] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34473.0,
        identifica: 'Lagedo do Pontal',
        co_iphan: 'PE2611101BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Os artefatos mantêm-se dispersos na superfície do sítio, indicando um possível acampamento temporário de caçadores que aproveitaram a matéria prima disponível. O material lítico é composto exclusivamente por quartzo.',
        dt_cadastr: '2021-10-13',
      },
      geometry: { type: 'Point', coordinates: [-40.519545, -8.934429] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 33865.0,
        identifica: 'Matacão do Luiz Gonzaga',
        co_iphan: 'PE2601201BAST00001',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um matacão com pinturas rupestres sobre afloramento rochoso. Todo o suporte rochoso é granítico.',
        dt_cadastr: '2021-08-26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.038130425375002, -8.333171220423798],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 33866.0,
        identifica: 'Tigre',
        co_iphan: 'PE2614105BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O Sítio Tigre trata-se de um sítio a céu aberto, com presença de vestígios pré-históricos de natureza lítica.',
        dt_cadastr: '2021-08-26',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.149951813130997, -8.27310741590451],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46915.0,
        identifica: 'Serra Vermelha XVII',
        co_iphan: 'PE2605608BAST00036',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na escarpa do Planalto Sedimentar, contendo pinturas e gravuras rupestres',
        dt_cadastr: '2023-08-15',
      },
      geometry: { type: 'Point', coordinates: [-37.747487, -8.013648] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 27299.0,
        identifica: 'Pátio Ferroviário das Cinco Pontas',
        co_iphan: 'PE2611606BAST00005',
        no_logrado: '-',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Área a céu aberto, formada por aterro de dragagem da Bacia do Pina. Possui estrutura ferroviária, contendo edificações relacionadas à este tipo de atividade como oficinas e galpões.',
        dt_cadastr: '2020-03-26',
      },
      geometry: { type: 'Point', coordinates: [-34.886844, -8.07469] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 33414.0,
        identifica: 'Areias I',
        co_iphan: 'PE2616001BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Areias I, localizado(a) no estado de Pernambuco, cidade(s) de Venturosa, é um Bem Arqueológico, do tipo Sítio.Sítio arqueológico histórico, com presença de ruínas de alicerces de pedra e uma\r\nestrutura de aproximadamente 150 m de cercas de pedra.',
        dt_cadastr: '2021-06-30',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.818127785656003, -8.634404849735214],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14451.0,
        identifica: 'Cemitério dos Caboclos',
        co_iphan: 'PE2616001BAST00004',
        no_logrado: 'Sítio Pedrinhas',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um abrigo no qual existe em seu teto uma figura antropomorfa pintada em vermelho.Neste sítio foram encontradas ossadas humanas durante as escavações realizadas na década de 1980. Em 03/2021 também foram encontrados ossaas humanas em supe',
        dt_cadastr: '2019-05-10',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.795697483321, -8.627470272688999],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34020.0,
        identifica: 'Serra dos Negos',
        co_iphan: 'PE2600609BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'sítio arqueológico histórico, com presença de vestígios cerâmicos históricos em superfície e a uma profundidade de até 15 cm, faianças finas em superfície, além de ruínas de alicerces de pedra, parcialmente recobertos',
        dt_cadastr: '2021-09-16',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.779057705216999, -8.577751573177913],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18708.0,
        identifica: 'Serrinha',
        co_iphan: 'PE2602803BAST00006',
        no_logrado: 'Serra Branca',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio localizado na alta vertente da Serra Branca, contendo pinturas e gravuras rupestres posicionadas nos setores leste e oeste do sítio.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-37.244348, -8.524863] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34030.0,
        identifica: 'Serrote do Urubu',
        co_iphan: 'PE2616001BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de sítio arqueológico rupestre, com presença de dois painéis de pinturas vermelhas ocupando uma área de 688 m',
        dt_cadastr: '2021-09-16',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.790950077192001, -8.617626816693404],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14459.0,
        identifica: 'Pedra Fixa',
        co_iphan: 'PE2616001BAST00005',
        no_logrado: 'Sítio Pedra Fixa',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio rupestre com dois painéis de pinturas vermelhas, dispostas sobre suporte rochoso de matacão granítico. Apresenta também fragmentos de cerâmica pré-colonial em sua superfície.',
        dt_cadastr: '2019-05-10',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.775605545006997, -8.6137871729191],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46921.0,
        identifica: 'Serrote da Barriguda',
        co_iphan: 'PE2614105BAST00035',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão, em situação topográfica de alta vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-37.255437, -8.017528] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 40055.0,
        identifica: 'Tará - 1',
        co_iphan: 'PE2616001BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Tará - 1, localizado(a) no estado de Pernambuco, cidade(s) de Venturosa, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-05-19',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.831713485949997, -8.644316416290909],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18660.0,
        identifica: 'Sítio Chapada do Alegre',
        co_iphan: 'PE2600203BAST00001',
        no_logrado: 'Rajada',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.769738192388445, -8.619117212626803],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38882.0,
        identifica: 'Pedra dos Ossos',
        co_iphan: 'PE2616001BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico situado na média vertente da Serra do Buco. No setor sudeste do matacão há uma área abrigada com as dimensões de 9 m de comprimento por 6 m de largura. As pinturas rupestres estão posicionadas no setor sul da área abrigada',
        dt_cadastr: '2022-03-11',
      },
      geometry: { type: 'Point', coordinates: [-36.795701, -8.626919] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37407.0,
        identifica: 'Sítio dos Nunes',
        co_iphan: 'PE2605608BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio dos Nunes, localizado(a) no estado de Pernambuco, cidade(s) de Flores, é um Bem Arqueológico, do tipo Sítio. O local apresenta afloramento rochoso granítico com polimento por marcas de uso e por refugo construtivo, bastante recente, pertencent',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.846424, -8.065667] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46380.0,
        identifica: 'Letreiro da Lagoinha do Sabá',
        co_iphan: 'PE2605608BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, posicionado na média vertente da Serra da Lagoinha, contendo pinturas e gravuras rupestres; os grafismos estão situados na porção leste enquanto que as gravuras encontram-se distribuídas por toda extensão do abrigo.',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-37.757397, -8.018489] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46396.0,
        identifica: 'Letreiro da Moderna',
        co_iphan: 'PE2614105BAST00028',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico, situado na média vertente da Serra dos Letreiros, contendo pinturas rupestres posicionadas no setor nordeste do sítio.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.431294, -8.427629] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46397.0,
        identifica: 'Letreiro do Agreste',
        co_iphan: 'PE2613503BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacões posicionados sobre embasamento rochoso, formando uma área abrigada no setor noroeste; situada a 5 m de altura do solo atual, em meio ao plaino aluvial do Riacho do Jatobazinho.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-38.581107, -7.94662] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34952.0,
        identifica: 'Divisa 02',
        co_iphan: 'PE2601102BAST00025',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'ítio arqueológico pré-colonial com evidências lito-cerâmicas passíveis de mais de um grupo fazendo uso da mesma área, logo, multicomponencial.',
        dt_cadastr: '2021-11-12',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.647040990769, -7.777241335540688],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46398.0,
        identifica: 'Letreiro do Angico',
        co_iphan: 'PE2613503BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacões posicionados sobre embasamento rochoso, formando uma área abrigada no setor nordeste, posicionada a uma altura de 3,80m do solo atual. As pinturas rupestres localizam-se nos setores sul e oeste da área abrigada.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-38.581035, -7.946611] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46399.0,
        identifica: 'Letreiro do Jucá',
        co_iphan: 'PE2603801BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado na alta vertente da Serra do Gurjão, contendo uma área abrigada localiza-se no setor oeste do matacão. As manchas gráficas estão posicionadas nos setores nordeste e sudoeste da área abrigada.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-36.599091, -8.632246] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37409.0,
        identifica: 'Santa Rita',
        co_iphan: 'PE2605608BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Santa Rita, localizado(a) no estado de Pernambuco, cidade(s) de Flores, é um Bem Arqueológico, do tipo Sítio. O local apresenta uma pequena construção desmoronada de tijolos maciços intercalados com tijolos de adobe e taipa em seu alicerces, fragme',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.840372, -8.065722] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 36882.0,
        identifica: 'Sítio Logradouro',
        co_iphan: 'PE2613503BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio Logradouro, localizado(a) no estado de Pernambuco, cidade(s) de São José do Belmonte, é um Bem Arqueológico, do tipo Sítio.\r\nSítio arqueológico pré-colonial, com presença de peças líticas dispersas em superfície.',
        dt_cadastr: '2021-12-16',
      },
      geometry: { type: 'Point', coordinates: [-38.62428, -8.003693] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14447.0,
        identifica: 'Letreiro do Sobrado',
        co_iphan: 'PE2611002BAST00001',
        no_logrado: 'Fazenda Experimental do Sobrado',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado a 150 m do lago de Itaparica, contendo gravuras rupestres localizadas nos setores nordeste, norte e noroeste da área abrigada.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-38.459031, -8.999538] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46400.0,
        identifica: 'Linhas Fortes',
        co_iphan: 'PE2603801BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado sobre embasamento rochoso, contendo pinturas rupestres no setor nordeste.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-36.597235, -8.624046] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37410.0,
        identifica: 'Bela Vista',
        co_iphan: 'PE2605608BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Bela Vista, localizado(a) no estado de Pernambuco, cidade(s) de Flores, é um Bem Arqueológico, do tipo Sítio. O local apresenta afloramentos rochoso graníticos, implantados em topo como seis marcas de polimento, além de lascas, artefatos e fragmento',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.835922, -8.066732] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46402.0,
        identifica: 'Loca da Cinza',
        co_iphan: 'PE2602803BAST00029',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na baixa vertente da Serra de Jerusalém, contendo pinturas e gravuras rupestres, posicionadas nos setores norte e sul da área abrigada.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.233943, -8.553838] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37412.0,
        identifica: 'Fazenda Mulungu',
        co_iphan: 'PE2605103BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Fazenda Mulungu , localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio. O local apresenta louças, vidros, metais, cerâmica de produção local/regional, uma grande barragem em pedra, uma cacimba e um fo',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.781778, -8.087754] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46403.0,
        identifica: 'Loca dos Cablocos',
        co_iphan: 'PE2615805BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          "Caverna situado no sopé da Serra do Quiri D'Alho. O conjunto pictural é composto por pinturas posicionadas nos setores nordeste, noroeste e sudoeste do sítio. Dimensões da caverna: 5,3 m de abertura x 1,30m de altura do piso atual x 30m de profundida",
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.368643, -8.569945] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37413.0,
        identifica: 'Lambedor',
        co_iphan: 'PE2605103BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Lambedor, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio. O local apresenta material lítico lascado e cinco afloramentos rochosos com sete áreas contendo marcas de polimento, sendo uma delas conve',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.705382, -8.147069] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 30972.0,
        identifica: 'Sítio Arqueológico São Bernardo 2',
        co_iphan: 'PE2609907BAST00018',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Trata-se de um sítio com fragmentos de cerâmica histórica (manufaturada em torno de produção regional), cerâmica produzida por técnica acordelada e queima irregular (característica de grupos pré-históricos), lítico e olaria em contexto superfi',
        dt_cadastr: '2020-10-26',
      },
      geometry: { type: 'Point', coordinates: [-40.50559, -7.985873] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10984.0,
        identifica: 'Pedra do Dinheiro',
        co_iphan: 'PE2602605BAST00009',
        no_logrado: 'Mata do Bituri Grande',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de média vertente, contendo\r\npinturas rupestres',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.373727, -8.192042] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46405.0,
        identifica: 'Macambira',
        co_iphan: 'PE2602803BAST00030',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Paredão arenítico, parte integrante dos contrafortes da Serra Branca, contendo pinturas rupestres posicionadas a 2 m do solo atual.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.243365, -8.543158] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46662.0,
        identifica: 'Pedra do Donato',
        co_iphan: 'PE2616001BAST00024',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na baixa vertente do Serrote da Pedra Furada, contendo pinturas rupestres posicionadas nos setores noroeste e sudoeste.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.826434, -8.576304] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37471.0,
        identifica: 'Estação Ferroviária Felipe Camarão',
        co_iphan: 'PE2613909BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Estação Ferroviária Felipe Camarão, localizado(a) no estado de Pernambuco, cidade(s) de Serra Talhada, é um Bem Arqueológico, do tipo Sítio, com estado de conservação Regular e de preservação Pouco Alterado. O local apresenta fragmentos de vid',
        dt_cadastr: '2022-01-12',
      },
      geometry: { type: 'Point', coordinates: [-38.47114, -8.040095] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46663.0,
        identifica: 'Pedra do Giz',
        co_iphan: 'PE2603900BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na média vertente da Serra do Giz, contendo pinturas rupestres posicionadas nos setores noroeste, oeste e sudoeste do sítio.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-37.639966, -7.876503] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46407.0,
        identifica: "Mãe D'Água I",
        co_iphan: 'PE2605707BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Situado no leito do rio Pajeú em matacões e afloramentos de gnaisse. São gravuras rupestres posicionadas nos setores norte, leste e sudoeste do sítio. As gravuras estão situadas nos setores norte, leste e sudoeste.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-38.504121, -8.517211] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37415.0,
        identifica: 'Soledade',
        co_iphan: 'PE2605103BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Soledade, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio. O local apresenta faianças finas, iron stones, vidros, cerâmicas, metais e vestígios poliméricos.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.654769, -8.144246] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46664.0,
        identifica: 'Pedra do Imbé',
        co_iphan: 'PE2616001BAST00025',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de fundo de vale, localizado sobre afloramento rochoso com as dimensões de 50 m de comprimento por 30 m de largura e 2.65 m de altura.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.818756, -8.549993] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46665.0,
        identifica: 'Pedra do Índio',
        co_iphan: 'PE2614709BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de média vertente. No setor norte há uma área abrigada onde foram identificadas pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.204378, -8.234109] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46666.0,
        identifica: 'Pedra do Lajedo',
        co_iphan: 'PE2604106BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Dois blocos graníticos sobrepostos, posicionados sobre o embasamento rochoso, formando no setor sudeste um área abrigada, onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.100291, -8.112835] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46408.0,
        identifica: "Mãe D'Água II",
        co_iphan: 'PE2605707BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio é formado por um conjunto de blocos de gnaisse, situados sobre o embasamento rochoso, localizado na margem esquerda do Rio Pajeú. As gravuras estão voltadas para o rio, situadas no setor oeste do sítio.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-38.504853, -8.527858] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37419.0,
        identifica: 'Fazendinha',
        co_iphan: 'PE2605103BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Fazendinha, localizado(a) no estado de Pernambuco, cidade(s) de Custódia, é um Bem Arqueológico, do tipo Sítio. O local apresenta estruturas arruinadas e edificações em uso além de fragmentos de cerâmica de produção local/regional, louça branc',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.617793, -8.134545] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37421.0,
        identifica: 'Caroalina',
        co_iphan: 'PE2614105BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Caroalina, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas em quartzo, cerâmicas de produção regional/local, faiança fina e vidros, fragmentos de tijolos e telhas indica',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.478819, -8.198087] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46667.0,
        identifica: 'Pedra do Lajeiro Grande',
        co_iphan: 'PE2607703BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: 'Cruzeiro',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-37.277045, -7.351814] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37422.0,
        identifica: 'Coxi I',
        co_iphan: 'PE2614105BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Coxi I, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta artefatos em sílex, arenito e quartzo, cerâmicas de produção local/regional e vestígios de dois fornos para fabricaçã',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.446626, -8.240517] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37424.0,
        identifica: 'Coxi II',
        co_iphan: 'PE2614105BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Coxi II, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta cerâmica de produção local/regional e faiança fina, material lítico em lascas e artefatos unipolares em quartzo leitos',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.442904, -8.240656] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10992.0,
        identifica: 'Mão de Sangue',
        co_iphan: 'PE2602605BAST00017',
        no_logrado: 'Sem localidade',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo no setor sul uma área abrigada; a mancha gráfica está posicionada no setor sudoeste da área abrigada.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.27211, -8.000575] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37425.0,
        identifica: 'Lajedo do Pilão',
        co_iphan: 'PE2614105BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Lajedo do Pilão, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta afloramentos rochosos contendo cacimbas naturais e pilões, lascas com instrumentos, lascas e estilhas em sua maio',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.392285, -8.294103] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37427.0,
        identifica: 'Pau a Pique',
        co_iphan: 'PE2614105BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pau a Pique, localizado(a) no estado de Pernambuco, cidade(s) de Sertânia, é um Bem Arqueológico, do tipo Sítio. O local apresenta uma unidade doméstica e áreas de descarte.',
        dt_cadastr: '2022-01-11',
      },
      geometry: { type: 'Point', coordinates: [-37.356224, -8.319182] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46410.0,
        identifica: 'Mão de Sangue',
        co_iphan: 'PE2600609BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo no setor sul uma área abrigada; a mancha gráfica está posicionada no setor sudoeste da área abrigada.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-36.703735, -8.452347] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46668.0,
        identifica: 'Pedra do Letreiro da Boa Vista',
        co_iphan: 'PE2607703BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão situado na média vertente da Serra da Boa Vista, contendo uma área abrigada no setor leste, onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-37.232745, -7.373269] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46411.0,
        identifica: 'Mãos de Sangue',
        co_iphan: 'PE2600500BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos e de blocos abatidos situados na média vertente do Serrote do Bode. O conjunto pictural é composto por pinturas rupestres posicionadas no setor norte do sítio.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.010653, -9.124105] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37937.0,
        identifica: 'Engenho Pau de Ferro',
        co_iphan: 'PE2603009BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Engenho Pau de Ferro, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio. O local apresenta uma estrutura de moenda de cana de tração animal, tanque de garapa e forno, telhas com padrão decorativo com',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.333265, -8.378703] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37938.0,
        identifica: 'D. Antônia',
        co_iphan: 'PE2603009BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'D. Antônia, localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio. O local apresenta ruínas de edificações, lascas de sílex e cerâmica, vidro, grês, louça e uma pedra de pilão.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.347897, -8.327368] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37939.0,
        identifica: 'Riacho Grande',
        co_iphan: 'PE2612208BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Riacho Grande, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta instrumentos líticos em calcedônia e aplito.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.219957, -8.169038] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37941.0,
        identifica: 'Córrego Baixio',
        co_iphan: 'PE2603009BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Córrego Baixio , localizado(a) no estado de Pernambuco, cidade(s) de Cabrobó, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas, fragmentos e instrumentos líticos em sílex, arenito silicificado e aplito.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.357283, -8.272346] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37944.0,
        identifica: 'Riacho da Barra',
        co_iphan: 'PE2612208BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Riacho da Barra , localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas, fragmentos e instrumentos líticos em sílex, arenito silicificado, aplito e quartzo leitos e hialino, lâ',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.217728, -8.128154] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37945.0,
        identifica: 'Pedro Campina',
        co_iphan: 'PE2612208BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pedro Campina, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta lascas, fragmentos e pontas de pro jétil em quartzo leitoso e hialino, mão de pilão em arenito silicificado.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.224656, -8.130231] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37946.0,
        identifica: 'Riacho do Mulungu',
        co_iphan: 'PE2612208BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Riacho do Mulungu, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta fragmentos de cerâmica pré-histórica, grés e louça, além de fragmentos de quartzo leitoso.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.224328, -8.144921] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 37952.0,
        identifica: 'Montevidéu',
        co_iphan: 'PE2612208BAST00019',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Montevidéu, localizado(a) no estado de Pernambuco, cidade(s) de Salgueiro, é um Bem Arqueológico, do tipo Sítio. O local apresenta louça inglesa de padrões variados, grés e fragmentos de vidro e cerâmica.',
        dt_cadastr: '2022-02-11',
      },
      geometry: { type: 'Point', coordinates: [-39.051514, -7.840344] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46415.0,
        identifica: 'Marias Pretas',
        co_iphan: 'PE2600500BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos e de blocos abatidos situados na média vertente do Serrote do Bode. O conjunto pictural é composto por pinturas rupestres posicionadas no setor norte do sítio.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.115395, -9.059935] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46416.0,
        identifica: 'Matacão',
        co_iphan: 'PE2608404BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de alta vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-36.174389, -8.684119] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46417.0,
        identifica: 'Matacão do Inácio',
        co_iphan: 'PE2614105BAST00029',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio encontra-se em situação topográfica elevada, apresentando solo cascalhoso, com ocorrência de seixos e afloramentos de gnaisse e de granito.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-37.253545, -8.029301] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46419.0,
        identifica: 'Milonesa',
        co_iphan: 'PE2615003BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente; neste matacão há uma área abrigada contendo pinturas rupestres.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-36.011338, -7.884454] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46421.0,
        identifica: 'Monte Santo',
        co_iphan: 'PE2603009BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento granítico posicionado no leito do Riacho dos Jatobás. Foram identificados materiais históricos e pré-históricos. Há presença de gravuras rupestres.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-39.333283, -8.293678] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46423.0,
        identifica: 'Moreira',
        co_iphan: 'PE2612505BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-07-21',
      },
      geometry: { type: 'Point', coordinates: [-36.397686, -7.873841] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46670.0,
        identifica: 'Pedra do Letreiro da Caeira',
        co_iphan: 'PE2603009BAST00017',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de blocos sobrepostos no leito do Riacho do Letreiro, assentados sobre o afloramento rochoso. O conjunto de gravuras está situado em dois blocos distintos posicionados nos setores noroeste (Bloco 1 ) e Sudeste (Bloco 2).',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-39.268119, -8.334132] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46671.0,
        identifica: 'Pedra do Letreiro da Caiçarinha',
        co_iphan: 'PE2613909BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na borda da Barragem do Letreiro, contendo com pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-38.134942, -8.128758] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46644.0,
        identifica: 'Pedra do Cabedal',
        co_iphan: 'PE2603900BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Situado em um matacão granítico na baixa vertente da Serra da Matinha. São pinturas rupestres posicionadas no setor nordeste do sítio.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-37.754181, -7.728471] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46646.0,
        identifica: 'Pedra do Caboclo',
        co_iphan: 'PE2604106BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de alta vertente da Serra do Cachorro, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-35.983988, -8.284572] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46672.0,
        identifica: 'Pedra do Letreiro da Generosa',
        co_iphan: 'PE2606408BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão posicionado na média vertente da Serra das Caíbras, contendo no setor sudoeste uma área abrigada, onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-35.638898, -8.146453] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46673.0,
        identifica: 'Pedra do Letreiro da Lagoa de Pedra',
        co_iphan: 'PE2602605BAST00047',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Pedra do Letreiro da Lagoa de Pedra , localizado(a) no estado de Pernambuco, cidade(s) de Brejo da Madre de Deus, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.287484, -8.052104] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46674.0,
        identifica: 'Pedra do Letreiro da Panela',
        co_iphan: 'PE2602605BAST00048',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.174779, -7.99502] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46647.0,
        identifica: 'Pedra do Caboclo da Gritadeira',
        co_iphan: 'PE2605301BAST00004',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente do Serrote da Gritadeira, contendo pinturas rupestres posicionadas no setor norte do sítio.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-39.792617, -7.622055] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46675.0,
        identifica: 'Pedra do Letreiro da Rabeca',
        co_iphan: 'PE2605707BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'O sítio encontra-se na baixa vertente da Serra do Olho d?água, uma formação sedimentar de feições ruiniforme. Foram verificadas três áreas abrigadas no interior do sítio, havendo registros rupestres apenas em uma.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-38.191852, -8.281032] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46677.0,
        identifica: 'Pedra do Letreiro da Serra dos Cavalos',
        co_iphan: 'PE2607505BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente da Serra dos Cavalos, contendo pinturas rupestres localizadas nos setores norte, leste e oeste.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-37.190294, -9.037837] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46679.0,
        identifica: 'Pedra do Letreiro das Araras',
        co_iphan: 'PE2602605BAST00049',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.428983, -8.146221] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46681.0,
        identifica: 'Pedra do Letreiro das Pretas',
        co_iphan: 'PE2614105BAST00031',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de matacões graníticos localizado sobre afloramento rochoso, posicionado num pedimento, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-37.126517, -8.194063] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46682.0,
        identifica: 'Pedra do Letreiro de Antônio Pequenino',
        co_iphan: 'PE2605707BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, assentado sobre afloramento rochoso, contendo gravuras e pinturas rupestres setor leste.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-38.504065, -8.483011] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46683.0,
        identifica: 'Pedra do Letreiro de Belém',
        co_iphan: 'PE2607703BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas nos setores sudeste e sudoeste.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-37.277729, -7.35518] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18889.0,
        identifica: 'Sítio Manteiga I',
        co_iphan: 'PE2611101BAST00002',
        no_logrado: 'Rajada',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-40.764981, -8.83437] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46684.0,
        identifica: 'Pedra do Letreiro de Gleber Espinheiro',
        co_iphan: 'PE2612604BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizado na baixa vertente da Serra do Cavalete, contendo pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-40.151628, -8.488654] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46685.0,
        identifica: 'Pedra do Letreiro de Queimadas',
        co_iphan: 'PE2602605BAST00050',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.265531, -8.040655] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46686.0,
        identifica: 'Pedra do Letreiro de Varjota',
        co_iphan: 'PE2614303BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado no plaino aluvial do Riacho da Varjota, contendo gravuras rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-39.595146, -7.620128] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46687.0,
        identifica: 'Pedra do Letreiro do Açudinho',
        co_iphan: 'PE2602605BAST00051',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-36.42442, -8.096716] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46688.0,
        identifica: 'Pedra do Letreiro do Angico I',
        co_iphan: 'PE2605707BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico contendo pinturas e gravuras rupestres situadas nos setores sudeste, noroeste, nordeste e norte.',
        dt_cadastr: '2023-08-07',
      },
      geometry: { type: 'Point', coordinates: [-38.448027, -8.679102] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 39997.0,
        identifica: 'Ruína Fortim Pontal',
        co_iphan: 'PE2607604BAST00001',
        no_logrado: 'Barqueiro Lourenço',
        nu_logrado: '220',
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Ruína Fortim Pontal, localizado(a) no estado de Pernambuco, cidade(s) de Ilha de Itamaracá, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-05-12',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.83785785063683, -7.696840346913585],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46648.0,
        identifica: 'Pedra do Caboclo de Pedra',
        co_iphan: 'PE2610806BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico situado na baixa vertente da Serra do Caboclo. São pinturas rupestres posicionadas nos setores nordeste e noroeste do sítio.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-36.883155, -8.706903] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 7043.0,
        identifica: 'Serrote da Guia',
        co_iphan: 'PE2612208BAST00001',
        no_logrado: 'Campo Grande',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacões graníticos, posicionados sobre embasamento rochoso, em situação topográfica de alta vertente, contendo pinturas rupestres.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-39.205009, -8.069176] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19872.0,
        identifica: 'Armazém Agrícola',
        co_iphan: 'PE2605459BAST00005',
        no_logrado: 'Vila dos Remédios',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-32.401488, -3.841154] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46922.0,
        identifica: 'Serrote da Pitombeira',
        co_iphan: 'PE2605608BAST00037',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento arenítico, localizado na baixa vertente do Serrote da Pitombeira, contendo gravuras rupestres posicionadas no setor sudeste.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-37.729247, -7.941471] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46923.0,
        identifica: 'Serrote do Letreiro',
        co_iphan: 'PE2605707BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Conjunto de blocos graníticos sobrepostos ao embasamento rochoso, contendo pinturas no setor nordeste.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-38.219819, -8.544031] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 40020.0,
        identifica: 'Terminal Marítimo de Passageiros/Sala Pernambuco',
        co_iphan: 'PE2611606BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Terminal Marítimo de Passageiros/sala Pernambuco, localizado(a) no estado de Pernambuco, cidade(s) de Recife, é um Bem Arqueológico, do tipo Sítio.\r\nÁrea a céu aberto, composta de aterros, sobre planície fluvio-marinha, com sedimento arqueológico',
        dt_cadastr: '2022-05-13',
      },
      geometry: { type: 'Point', coordinates: [-34.868989, -8.05671] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46649.0,
        identifica: 'Pedra do Caboclo de São Paulo',
        co_iphan: 'PE2602605BAST00045',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na margem do Riacho Quixabeira, contendo nos setores noroeste e sudoeste uma área abrigada, onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-36.273808, -8.096126] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 40080.0,
        identifica: 'Tará - 2',
        co_iphan: 'PE2616001BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Tará - 2, localizado(a) no estado de Pernambuco, cidade(s) de Venturosa, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-05-20',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.818083058816001, -8.651650927352808],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 40082.0,
        identifica: 'Capoeira',
        co_iphan: 'PE2603801BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 283.0,
        ds_classif: 'Pré-colonial e Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Capoeira, localizado(a) no estado de Pernambuco, cidade(s) de Capoeiras, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-05-20',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.648706555681997, -8.7090324327225],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46924.0,
        identifica: 'Sítio da Ema',
        co_iphan: 'PE2606606BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha arenítica, situado na média vertente da Serra dos Letreiros, contendo pinturas e gravuras rupestres localizadas no interior da área abrigada.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-37.464193, -8.746414] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 18890.0,
        identifica: 'Sítio Manteiga II',
        co_iphan: 'PE2611101BAST00003',
        no_logrado: 'Rajada',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.76443326856716, -8.833781579272108],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46651.0,
        identifica: 'Pedra do Camaleão',
        co_iphan: 'PE2603900BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica situado na média vertente do Serrote do Gavião. São pinturas rupestres posicionadas nos setores sudoeste e sul do sítio.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-37.7865, -7.707429] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46653.0,
        identifica: 'Pedra do Camaleão',
        co_iphan: 'PE2613503BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado na média vertente da Serra dos Canudos, contendo no setor sul uma área abrigada onde jazem pinturas rupestres.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-38.642066, -7.82422] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46654.0,
        identifica: 'Pedra do Catolé',
        co_iphan: 'PE2603926BAST00002',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na alta vertente da Serra do Arapuá, contendo pinturas rupestres posicionadas nos setores noroeste e nordeste',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-38.691893, -8.393458] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46655.0,
        identifica: 'Pedra do Coração',
        co_iphan: 'PE2612208BAST00028',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado na média vertente do Serrote do Saco da Canoa,\r\ncontendo pinturas rupestres posicionadas nos setores oeste e sul.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-38.923924, -8.267343] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46656.0,
        identifica: 'Pedra do Cumbe',
        co_iphan: 'PE2603926BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na alta vertente da Serra do Arapuá, contendo pinturas\r\nrupestres posicionadas no setor sul.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-38.679694, -8.404665] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46657.0,
        identifica: 'Pedra do Defunto',
        co_iphan: 'PE2616001BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico situado na média vertente da Serra do Lajeiro Preto. São\r\npinturas rupestres posicionadas nos setores noroeste e sudeste do sítio.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-36.797588, -8.608364] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 34951.0,
        identifica: 'Sítio Araripina',
        co_iphan: 'PE2601102BAST00024',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio arqueológico pré-histórico, com materiais identificados  em superfície, situados em área de plantio de mandioca. Solo bastante revolvido e desgastado pela atividade agrícola.',
        dt_cadastr: '2021-11-12',
      },
      geometry: { type: 'Point', coordinates: [-40.616121, -7.60602] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46658.0,
        identifica: 'Pedra do Desenho',
        co_iphan: 'PE2616001BAST00023',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de baixa vertente, posicionado sobre\r\nembasamento rochoso, contendo pinturas rupestres nos diversos setores do sítio.',
        dt_cadastr: '2023-08-04',
      },
      geometry: { type: 'Point', coordinates: [-36.802117, -8.561577] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46925.0,
        identifica: 'Sítio da Escada',
        co_iphan: 'PE2602803BAST00036',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas no setor sudoeste do sítio.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-37.239209, -8.502634] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46926.0,
        identifica: 'Sítio da Porteira',
        co_iphan: 'PE2612505BAST00006',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Maciço residual granítico posicionado na margem do Rio Pará. No sítio há presença de pinturas rupestres.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-36.302643, -7.919522] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46927.0,
        identifica: 'Sítio do Barbado',
        co_iphan: 'PE2616001BAST00031',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico situado na baixa vertente de um Inselberg, conhecido localmente como Morro do Barbado. São pinturas rupestres posicionadas nos setores leste, nordeste e noroeste do sítio.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-36.807431, -8.566037] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46928.0,
        identifica: 'Sítio do Erasmo',
        co_iphan: 'PE2613909BAST00022',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico em situação topográfica de baixa vertente com presença de pinturas rupestres no setor nordeste do sítio.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-38.542948, -8.13947] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46929.0,
        identifica: 'Sítio do Guardião',
        co_iphan: 'PE2606606BAST00012',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha situado na baixa vertente da Serra do Barreiro. O conjunto pictural é composto por pinturas e gravuras rupestres posicionadas nos setores leste, sul e sudeste do sítio.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-37.4027, -8.550141] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46931.0,
        identifica: 'Sítio do João',
        co_iphan: 'PE2602803BAST00037',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha localizado na baixa vertente da Serra de Jerusalém onde foram identificadas pinturas e gravuras rupestres.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-37.240836, -8.587665] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46932.0,
        identifica: 'Sítio do Juazeiro',
        co_iphan: 'PE2606606BAST00013',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Afloramento de arenito silicificado localizado, no leito do Riacho do Salgado onde foram identificadas gravuras rupestres.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-37.456249, -8.502805] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46933.0,
        identifica: 'Sítio do Letreiro',
        co_iphan: 'PE2600807BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica em situação topográfica de alta vertente, com a presença de pinturas rupestres.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-36.074059, -8.560925] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46934.0,
        identifica: 'Sítio do Moleque I',
        co_iphan: 'PE2613107BAST00008',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha em situação topográfica de média vertente. São pinturas rupestres posicionadas no teto da área abrigada, nos setores sul e norte do abrigo.',
        dt_cadastr: '2023-08-16',
      },
      geometry: { type: 'Point', coordinates: [-36.146918, -8.196997] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 43092.0,
        identifica: 'Cemitério Nossa Senhora Conceição',
        co_iphan: 'PE2607604BAST00002',
        no_logrado: 'João Paulo II',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Cemitério Nossa Senhora Conceição , localizado(a) no estado de Pernambuco, cidade(s) de Ilha de Itamaracá, é um Bem Arqueológico, do tipo Sítio.',
        dt_cadastr: '2022-10-25',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.857926856421813, -7.809295725693342],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46952.0,
        identifica: 'Sítio do Veado',
        co_iphan: 'PE2602803BAST00038',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizado na escarpa da Serra Branca, contendo pinturas rupestres posicionadas nos setores sul, sudoeste e oeste do sítio.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-37.2433, -8.53361] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46963.0,
        identifica: 'Sítio do Zezé',
        co_iphan: 'PE2615805BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-37.308743, -8.572318] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 38883.0,
        identifica: 'Almas',
        co_iphan: 'PE2603900BAST00003',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente da Serra da Matinha, contendo gravuras rupestres posicionadas nos setores leste e oeste do sítio.',
        dt_cadastr: '2022-03-11',
      },
      geometry: { type: 'Point', coordinates: [-37.78774, -7.708228] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46247.0,
        identifica: 'Dedos de Deus II',
        co_iphan: 'PE2602803BAST00021',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, localizado na média vertente da Serra dos Coqueiros, contendo pinturas e gravuras rupestres posicionadas nos setores nordeste e oeste.',
        dt_cadastr: '2023-07-12',
      },
      geometry: { type: 'Point', coordinates: [-37.191287, -8.537005] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46964.0,
        identifica: 'Tauá I',
        co_iphan: 'PE2606606BAST00014',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente da Chapada de São José, contendo pinturas rupestres posicionadas em toda extensão do paredão.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-37.422836, -8.488801] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 43998.0,
        identifica: 'Belém de São Francisco 01',
        co_iphan: 'PE2601607BAST00005',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Belém de São Francisco 01, localizado(a) no estado de Pernambuco, cidade(s) de Belém de São Francisco, é um Bem Arqueológico, do tipo Sítio. Sítio pré-colonial localizado em área de baixa vertente com a presença de material lascado em silexito',
        dt_cadastr: '2022-12-15',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.048863632272003, -8.640123845780098],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46966.0,
        identifica: 'Toca das Abelhas',
        co_iphan: 'PE2615805BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Caverna posicionada na base de um maciço arenítico, escavada por paleocorrentes hídricas de grande energia, contendo no seu interior pinturas rupestres, posicionadas no setor oeste.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-37.310002, -8.579205] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46965.0,
        identifica: 'Tauá II',
        co_iphan: 'PE2606606BAST00015',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na média vertente da Chapada de São José, contendo pintura rupestre em toda extensão do paredão.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-37.422936, -8.488729] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46487.0,
        identifica: 'Pedra Corcuva',
        co_iphan: 'PE2603801BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado na alta vertente da Serra do Gurjão, contendo uma diminuta área abrigada que ocupa os setores nordeste e norte do matacão. As manchas gráficas estão posicionadas na área abrigada e no setor noroeste do matacão',
        dt_cadastr: '2023-07-25',
      },
      geometry: { type: 'Point', coordinates: [-36.602014, -8.622232] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19969.0,
        identifica: 'São José',
        co_iphan: 'PE2608404BAST00001',
        no_logrado: 'São José',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.177507, -8.672786] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46967.0,
        identifica: 'Toca de Cima do Levi',
        co_iphan: 'PE2610806BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente da Serra do Caboclo, contendo no setor sudoeste uma área abrigada, onde jazem as pinturas rupestres.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-36.882778, -8.710915] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 45701.0,
        identifica: 'Aqueduto Bobocão',
        co_iphan: 'PE2604106BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: 'Aqueduto. Estrutura de alvenaria',
        dt_cadastr: '2023-05-11',
      },
      geometry: { type: 'Point', coordinates: [-36.035669, -8.353972] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46968.0,
        identifica: 'Toca do Gato',
        co_iphan: 'PE2606606BAST00016',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha, situado na baixa vertente da Serra do Barreiro, contendo pinturas rupestres posicionadas no setor nordeste.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-37.403262, -8.550496] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46970.0,
        identifica: 'Toca do Lula',
        co_iphan: 'PE2616001BAST00032',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente do Serrote do Brinco, contendo pinturas rupestres posicionadas nos setores noroeste, sul, sudeste e nordeste.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-36.81699, -8.607968] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46973.0,
        identifica: 'Toca do Vale do Levi',
        co_iphan: 'PE2610806BAST00010',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, situado na média vertente da Serra do Caboclo, contendo uma área abrigada onde jazem pinturas rupestres, posicionadas no setor sudoeste.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-36.880588, -8.709483] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46974.0,
        identifica: 'Umburana',
        co_iphan: 'PE2601904BAST00011',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, em situação topográfica de baixa vertente, contendo pinturas posicionadas no setor sudoeste.',
        dt_cadastr: '2023-08-17',
      },
      geometry: { type: 'Point', coordinates: [-35.727324, -8.141522] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 44991.0,
        identifica: 'Sítio Antônio de Pádua 1',
        co_iphan: 'PE2605707BAST00007',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio lítico com sílex, quartzo e quartzito, nas proximidades do Rio São Francisco..',
        dt_cadastr: '2023-03-09',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.601800679184002, -8.660995056787403],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 44995.0,
        identifica: 'Sítio Antônio de Pádua 3',
        co_iphan: 'PE2605707BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Sítio lítico com sílex, quartzo e quartzito, nas proximidades do Rio São Francisco.',
        dt_cadastr: '2023-03-09',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.584025927505998, -8.673612026925204],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 22578.0,
        identifica: 'Pititi',
        co_iphan: 'PE2602803BAST00015',
        no_logrado: 'Catimbau, Morro Dois Irm?os',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Morro testemunho arenítico, situado topograficamente no altiplano, contendo pinturas e gravuras rupestres posicionadas em uma cavidade localizada no setor leste do testemunho.',
        dt_cadastr: '2019-06-26',
      },
      geometry: { type: 'Point', coordinates: [-37.242299, -8.557755] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 44214.0,
        identifica: 'Aqueduto do Menino',
        co_iphan: 'PE2604106BAST00009',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 40.0,
        ds_classif: 'Histórico',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'AQUEDUTO DO MENINO, também conhecido(a) como AQUEDUTO DA VILA MURICI, localizado(a) no estado de Pernambuco, cidade(s) de Caruaru, é um Bem Arqueológico, do tipo Sítio. Aqueduto. Estrutura de alvenaria. O patrimônio se insere como elemento constitut',
        dt_cadastr: '2023-01-11',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.030139044009999, -8.345625310840296],
      },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 19299.0,
        identifica: 'Sítio Forte Real de Nossa Senhora de Nazaré',
        co_iphan: 'PE2602902BAST00010',
        no_logrado: 'Vila Nazaré',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Atualização: Os bens arqueológicos móveis associados a este sítio estão cadastrados no SICG. \r\n\r\nBens Arqueológicos - Código IPHAN\r\n\r\nForte Real Nossa Senhora de Nazaré - Cerâmica -PE-2611606-BA-AR-00004\r\n\r\nForte Real Nossa Senhora de Nazaré -',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-34.94865, -8.350684] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 10999.0,
        identifica: 'Furna do Estrago (Abrigo II)',
        co_iphan: 'PE2602605BAST00024',
        no_logrado: 'Fazenda Velha',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Abrigo sob rocha granítica em situação topográfica de média vertente, contendo pinturas rupestres.',
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.197164, -8.212921] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46372.0,
        identifica: 'Lajes VI',
        co_iphan: 'PE2609907BAST00027',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, posicionado sobre um afloramento rochoso, em situação  topográfica de baixa vertente, contendo pinturas rupestres localizadas no setor leste do sítio.',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-40.022683, -7.954799] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46374.0,
        identifica: 'Lajes VIII',
        co_iphan: 'PE2609907BAST00029',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre um embasamento rochoso, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas em um nicho natural, no setor oeste',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-40.022803, -7.955603] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 46373.0,
        identifica: 'Lajes VII',
        co_iphan: 'PE2609907BAST00028',
        no_logrado: null,
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 41.0,
        ds_classif: 'Pré-colonial',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be:
          'Matacão granítico, localizado sobre um embasamento rochoso, em situação topográfica de baixa vertente, contendo pinturas rupestres posicionadas no setor nordeste do sítio.',
        dt_cadastr: '2023-07-20',
      },
      geometry: { type: 'Point', coordinates: [-40.023002, -7.955612] },
    },
    {
      type: 'Feature',
      properties: {
        id_bem: 14457.0,
        identifica: 'Pedra Comprida',
        co_iphan: 'PE2613008BAST00001',
        no_logrado: 'Fazenda Pedra Comprida',
        nu_logrado: null,
        id_naturez: 3.0,
        ds_naturez: 'Bem Arqueológico',
        codigo_iph: 'BA',
        id_classif: 286.0,
        ds_classif: 'Sem classificação',
        id_tipo_be: 4.0,
        ds_tipo_be: 'Sítio',
        sg_tipo_be: 'ST',
        sintese_be: null,
        dt_cadastr: '2019-05-10',
      },
      geometry: { type: 'Point', coordinates: [-36.361982, -8.403184] },
    },
  ],
};
