import styled from 'styled-components';
import { Button } from '@mui/material';

export const ModalContainerUploadFile = styled.div`
  position: absolute;
  top: 160px;
  margin-left: 250px;
  transform: translate(-50%, -50%);
  width: 320px;
  gap: 32px;
  box-shadow: 24px;
  padding: 20px;
  z-index: 1001;
  border-radius: 4px;
  z-index: 1000;
`;

export const ModalPaper = styled.div`
  position: absolute;
  top: 160px;
  margin-left: 190px;
  transform: translate(-50%, -50%);
  width: 320px;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 24px;
  padding: 20px;
  z-index: 1001;
  border-radius: 4px;
  text-align: left;
`;

export const ModalHeaderUploadFile = styled.h1`
  font-size: ${({ theme }) => theme.typography.size.header};
  font-weight: ${({ theme }) => theme.typography.weight.bold};
  margin-bottom: 10px;
`;

export const ModalDescription = styled.p`
  font-size: 12px;
  color: black;
  margin-bottom: 20px;
`;

export const UploadButton = styled(Button)`
  width: 100%;
  height: 50px;
  background-color: #9fc131;
`;
