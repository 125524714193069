import React, { useState, useEffect } from 'react';
import { CheckboxTreeView } from '../CheckboxTreeView';
import { CleanLayersButton, ModalContent, ModalTitle } from './styles';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import {
  Box,
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from '@mui/material';
import { mockLayers } from './layers';
import { useLayers } from '../../context/LayersContext';

interface Layer {
  title: string;
  children?: Layer[];
}

interface LayersModalProps {
  show: boolean;
  onClose: (value: boolean | ((prevVar: boolean) => boolean)) => void;
}

export default function LayersModal() {
  const { cleanCheckedLayers } = useLayers();
  const [filteredLayers, setFilteredLayers] = useState<Layer[]>(mockLayers);
  const [showSearch, setShowSearch] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const toggleSearch = () => {
    setShowSearch(!showSearch);
    setSearchTerm('');
  };

  const handleCloseSearch = () => {
    setShowSearch(false);
    setSearchTerm('');
  };

  const filterLayers = (layers: Layer[], query: string): Layer[] => {
    return layers
      .map((layer) => {
        const matchesTitle = layer.title
          .toLowerCase()
          .includes(query.toLowerCase());

        if (layer.children) {
          const filteredChildren = filterLayers(layer.children, query);

          if (filteredChildren.length > 0 || matchesTitle) {
            return { ...layer, children: filteredChildren };
          }
        }

        if (matchesTitle) {
          return { ...layer };
        }

        return null;
      })
      .filter((layer): layer is Layer => layer !== null);
  };

  useEffect(() => {
    if (searchTerm) {
      const filtered = filterLayers(mockLayers, searchTerm);
      setFilteredLayers(filtered);
    } else {
      setFilteredLayers(mockLayers);
    }
  }, [searchTerm]);

  return (
    <div>
      <ModalContent>
        {showSearch && (
          <TextField
            fullWidth
            placeholder="Digite o título da camada..."
            style={{ marginBottom: '10px' }}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            variant="outlined"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleCloseSearch}>
                    <CloseIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}

        <ModalTitle>Camadas</ModalTitle>

        <CheckboxTreeView data={filteredLayers} />

        <Box display="flex" justifyContent="space-between" mt={2} mb={2}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={cleanCheckedLayers}
            style={{
              color: 'red',
              borderColor: 'red',
              width: '170px',
              gap: '5px',
            }}
          >
            <DeleteOutlineOutlinedIcon fontSize="small" />
            Limpar Camadas
          </Button>

          <Button
            variant="outlined"
            color="primary"
            onClick={toggleSearch}
            style={{
              color: 'grey',
              borderColor: 'grey',
              width: '170px',
              gap: '5px',
            }}
          >
            <SearchOutlinedIcon fontSize="small" />
            Buscar Camada
          </Button>
        </Box>
      </ModalContent>
    </div>
  );
}
