import { jsonGLOPEAnual6 } from '../../../LocalStore/GLO_PE_Anual_6';
import { useLayers } from '../../../context/LayersContext';
import { GeoJSON } from 'react-leaflet';
import { styleGLOPEAnual6 } from './layerStyles';
export function RenderPotencialSolar() {
  const { /*addNewLayer,*/ layers } = useLayers();
  if (!layers) return null;
  const localStorageDict = [
    {
      name: 'Radiação Global Anual',
      data: jsonGLOPEAnual6,
    },
  ];

  return (
    <>
      {localStorageDict.map((obj) => {
        if (layers.includes(obj.name)) {
          if (obj.data) {
            return (
              <GeoJSON
                //@ts-ignore
                data={obj.data}
                key={obj.name}
                //@ts-ignore
                style={styleGLOPEAnual6}
              />
            );
          }
        }
      })}
    </>
  );
}
