export var jsonPEUnidadesDeConservacao30 = {
  type: 'FeatureCollection',
  name: 'PE_UNIDADES_DE_CONSERVACAO_30',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3751',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL MARINHA RECIFES SERRAMBI',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2018',
        GID7: '15435',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Decreto nº 46.052/18 de 24/05/2018',
        DT_ULTIM10: '28/11/2018',
        CODIGO_U11: '0000.26.3751',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.005664491554448, -8.534579263656569],
              [-34.70947392288457, -8.535998567944677],
              [-34.70939344046311, -8.536563595324575],
              [-34.709251117790416, -8.538065213202763],
              [-34.70882265265891, -8.541334115877781],
              [-34.708053615774659, -8.54340551912688],
              [-34.706210423375175, -8.549287645457415],
              [-34.706132520922459, -8.549515859369532],
              [-34.706091072832805, -8.549624722895445],
              [-34.705982209167466, -8.549920851663762],
              [-34.703232654556672, -8.554948048760233],
              [-34.702067114555447, -8.557697601752441],
              [-34.700998452646679, -8.560895592788828],
              [-34.700418678076581, -8.563261127553826],
              [-34.699653136356517, -8.565879345476461],
              [-34.699503322912889, -8.567582211125444],
              [-34.699387464257271, -8.572474079973661],
              [-34.699263618329482, -8.574061090847621],
              [-34.699463366368668, -8.575724006851601],
              [-34.700144009031945, -8.57989927447897],
              [-34.700399186971033, -8.582242837464891],
              [-34.701476831974546, -8.585919230665612],
              [-34.701662598389973, -8.586562424692882],
              [-34.702809656156163, -8.590424585128694],
              [-34.703219639534488, -8.593187123137843],
              [-34.703788423305454, -8.596412581094357],
              [-34.704129992979453, -8.598606331918312],
              [-34.704051589753917, -8.600536912235336],
              [-34.704343220089122, -8.605149131925819],
              [-34.704260322740588, -8.606788077749567],
              [-34.704490033324682, -8.608280207834207],
              [-34.705691021333422, -8.614678689531988],
              [-34.705738461576608, -8.614969824688339],
              [-34.705769921932557, -8.61518205871894],
              [-34.705982154157375, -8.617020754437547],
              [-34.706561922222122, -8.62257179773885],
              [-34.706667289553415, -8.623151571189871],
              [-34.706809110946701, -8.623978534865191],
              [-34.707428329219269, -8.629887130082107],
              [-34.707718963404858, -8.631333317802032],
              [-34.708015589587973, -8.633366769504976],
              [-34.708135435932313, -8.637361762651256],
              [-34.708547916667605, -8.639515064242348],
              [-34.710015572058893, -8.643548510302526],
              [-34.710042538155349, -8.643636400171683],
              [-34.711810816370694, -8.647696812684231],
              [-34.712975853768597, -8.648884824697909],
              [-34.714163862208324, -8.650241125813487],
              [-34.7165493667381, -8.653493052117131],
              [-34.717996049799964, -8.655878563534049],
              [-34.719750846139057, -8.658473312966587],
              [-34.722345586608874, -8.663602886191233],
              [-34.722471927830703, -8.663933971345347],
              [-34.722477420834664, -8.664060313005599],
              [-34.722475922606137, -8.664190649653223],
              [-34.723290394899536, -8.671297243620103],
              [-34.723280906019866, -8.672242059474069],
              [-34.723794760151883, -8.673691243633376],
              [-34.72517852089161, -8.677590857385358],
              [-34.72618275865343, -8.6804238076767],
              [-34.727525571508799, -8.683425546705356],
              [-34.729142539756758, -8.687220791462712],
              [-34.729744283676439, -8.688605556435242],
              [-34.729877116487813, -8.689255741668987],
              [-34.730527299948903, -8.690264977331909],
              [-34.732958746130592, -8.694355852253787],
              [-34.735040632239446, -8.696787306636807],
              [-34.736351985095588, -8.699144353640584],
              [-34.738709026112964, -8.701443973481654],
              [-34.740097782044913, -8.703580297183526],
              [-34.740859325479732, -8.704969057286416],
              [-34.741157948794701, -8.707417988301339],
              [-34.741340217538976, -8.710519601218982],
              [-34.7417102511114, -8.713150803855132],
              [-34.742309995685517, -8.716751791257769],
              [-34.742419357756695, -8.717622200470579],
              [-34.743101498865684, -8.721332550880168],
              [-34.743995874392446, -8.724227423499258],
              [-34.746019831510495, -8.728643390680359],
              [-34.746358406017762, -8.729514299458513],
              [-34.746534185192807, -8.729870852739024],
              [-34.746890737384554, -8.730456119524815],
              [-34.748788851603436, -8.735797926262453],
              [-34.749788096394823, -8.737696048193939],
              [-34.75147897206881, -8.741289545903722],
              [-34.752836267280657, -8.743641599227448],
              [-34.754052739261361, -8.745877797635321],
              [-34.75436135149058, -8.746588906667],
              [-34.755072457901484, -8.748328726761851],
              [-34.756451224497006, -8.752680773541506],
              [-34.75701451665261, -8.754059546011842],
              [-34.75684372943028, -8.75583082594871],
              [-34.756906646654848, -8.760407090631904],
              [-34.756762326174339, -8.762241291733854],
              [-34.756875682446598, -8.764044531853402],
              [-34.757214753499866, -8.768280722980249],
              [-34.757345088185367, -8.770423038157826],
              [-34.757742087878007, -8.773092692652162],
              [-34.758140585879964, -8.775536630036429],
              [-34.75858103073088, -8.778604785134723],
              [-34.759149813722281, -8.782682175484466],
              [-34.759154308064879, -8.78270464732455],
              [-34.759657173748522, -8.786786531989318],
              [-34.760356793587249, -8.789683901351964],
              [-34.761026950253964, -8.792741070417506],
              [-34.761545797447518, -8.794968279531245],
              [-34.762036181200969, -8.796186253477163],
              [-34.76325415164866, -8.797995986390566],
              [-34.764971494227439, -8.801432680683961],
              [-34.765588219055203, -8.803150028881589],
              [-34.765602065575351, -8.80389110613792],
              [-34.795688364953158, -8.794253025546613],
              [-35.036523450376649, -8.716995439191274],
              [-35.035969958715896, -8.71614757656376],
              [-35.032369706518139, -8.711912785543255],
              [-35.030714416349142, -8.711146125381402],
              [-35.029731515326993, -8.709721038786054],
              [-35.030202157131868, -8.707938751522676],
              [-35.030240868679478, -8.706065281135622],
              [-35.029680068733448, -8.704246224535199],
              [-35.029743580145187, -8.702389913741117],
              [-35.030127573925292, -8.700684047205701],
              [-35.034749230645531, -8.690366195130334],
              [-35.035413653891545, -8.687897323478598],
              [-35.035203639908111, -8.686156137211988],
              [-35.035507296152957, -8.684232492206441],
              [-35.035203271452076, -8.682480678833192],
              [-35.032918621749381, -8.679532810625988],
              [-35.031795008438856, -8.677832023413174],
              [-35.027081299605662, -8.674443520789435],
              [-35.023582604203348, -8.673204887923344],
              [-35.022412887250532, -8.67182261744245],
              [-35.021943240914496, -8.670063635270807],
              [-35.021240964111286, -8.668329535850333],
              [-35.022775095602697, -8.667044788258531],
              [-35.023791575091167, -8.665446316129627],
              [-35.024359829764215, -8.663816166925578],
              [-35.024606591810944, -8.661140820533445],
              [-35.024286600995211, -8.656945156662658],
              [-35.022147840149763, -8.650025876500983],
              [-35.017766391315533, -8.641241968873144],
              [-35.015732721047485, -8.637900851390912],
              [-35.013973071909646, -8.63424225010586],
              [-35.013011784637634, -8.632659438056042],
              [-35.012251771326987, -8.630977049495803],
              [-35.010473673223295, -8.627487139151439],
              [-35.007503825388049, -8.621905391076353],
              [-35.006903393997312, -8.620506663543294],
              [-35.005908962974814, -8.61913462979574],
              [-35.004553457668635, -8.61751303772726],
              [-35.003609907490201, -8.615962266051389],
              [-35.00225841840772, -8.614779202083898],
              [-35.000770943813066, -8.613775462383435],
              [-35.000119250887771, -8.606608158152049],
              [-35.0454731644761, -8.606286062704916],
              [-35.045470455839357, -8.606072208007596],
              [-35.045470275650608, -8.606072204861043],
              [-35.045321416771401, -8.605828356211404],
              [-35.045163447116721, -8.605618156351195],
              [-35.045062846190184, -8.605408311200831],
              [-35.044906963772249, -8.605236627123215],
              [-35.044881984742801, -8.605072228950547],
              [-35.044953100987634, -8.604757130032379],
              [-35.044934737991994, -8.60452233070049],
              [-35.044784828149702, -8.604309345528184],
              [-35.044740040589652, -8.604094303921713],
              [-35.044732482304063, -8.603986568647812],
              [-35.044724924029907, -8.603878833372187],
              [-35.044735702332815, -8.603666750629841],
              [-35.044741515250237, -8.603428117655575],
              [-35.044739545691471, -8.603177891995557],
              [-35.0447776728655, -8.602950730340355],
              [-35.044802383041493, -8.602753687870646],
              [-35.044862417657548, -8.602587463695649],
              [-35.044953966735534, -8.602403869507194],
              [-35.045035412402946, -8.602129941381449],
              [-35.045138193342787, -8.601847583349482],
              [-35.045382945952369, -8.601255684583212],
              [-35.04549995918849, -8.600942895695768],
              [-35.045618215530205, -8.600641752332351],
              [-35.045739292292772, -8.600361837189213],
              [-35.045875580576975, -8.600051142466727],
              [-35.046008005177654, -8.599743198264051],
              [-35.046106679145552, -8.599445494662048],
              [-35.046171650224586, -8.59916521794579],
              [-35.046209644925042, -8.598898282579929],
              [-35.046235669615228, -8.598673806883754],
              [-35.046250307960882, -8.598482079152568],
              [-35.046260652882395, -8.598277302896719],
              [-35.046259181970171, -8.598057402827669],
              [-35.04624930446397, -8.597357595550259],
              [-35.046230576936395, -8.597125863889948],
              [-35.046210595448201, -8.596943097707644],
              [-35.046170173363464, -8.596735716691649],
              [-35.046045834042836, -8.595862368539199],
              [-35.046011925790744, -8.595684015356126],
              [-35.045956211330143, -8.595348370341915],
              [-35.045919119233716, -8.595141974921349],
              [-35.045872175400255, -8.594914072230889],
              [-35.045811966960066, -8.594683681774923],
              [-35.045742780489405, -8.594452425985725],
              [-35.045664815051452, -8.59423878991832],
              [-35.045584510622383, -8.594017464441221],
              [-35.04559116655868, -8.594016145129853],
              [-35.045528948212741, -8.593827966449943],
              [-35.04544111051964, -8.593596361783399],
              [-35.045371572343932, -8.593415420538467],
              [-35.045276414809059, -8.593205528574225],
              [-35.045199556806587, -8.593028205965092],
              [-35.045115379004351, -8.592821932625766],
              [-35.045027541275225, -8.592633753332189],
              [-35.044932383734334, -8.592431098603356],
              [-35.044844546014218, -8.592232062608158],
              [-35.044738408716697, -8.592054739519755],
              [-35.044650570993561, -8.591859322166542],
              [-35.044562733246423, -8.591692855442185],
              [-35.044467575678546, -8.591522769796107],
              [-35.044365098289894, -8.59134906522346],
              [-35.044255301068475, -8.59118621710298],
              [-35.044112564619184, -8.591048700816875],
              [-35.044061325891875, -8.59100165575825],
              [-35.04402838677192, -8.590896709134672],
              [-35.043914929632635, -8.590741098517318],
              [-35.043808792290108, -8.59061805750545],
              [-35.043735594172468, -8.590473303365092],
              [-35.043658736124996, -8.590350262290396],
              [-35.043567238402993, -8.590259790857719],
              [-35.043376923117243, -8.590100561059758],
              [-35.04324882631245, -8.589966663249665],
              [-35.04303289169453, -8.589745912698747],
              [-35.042897475050978, -8.589630109061629],
              [-35.04288649541428, -8.589510686629342],
              [-35.042849896385434, -8.589402120727305],
              [-35.042871855895683, -8.589355075524464],
              [-35.042703499997266, -8.589297173568051],
              [-35.042622981863992, -8.589384026226071],
              [-35.042403387247255, -8.589268222409295],
              [-35.042216731903366, -8.589072803539322],
              [-35.042081315226831, -8.588996807233425],
              [-35.041938578765539, -8.588873765625678],
              [-35.041689704916713, -8.588681965371205],
              [-35.041612846824243, -8.588613206775952],
              [-35.041463705507773, -8.588501474012284],
              [-35.041352078267451, -8.588414620994506],
              [-35.041166337863309, -8.588265342328857],
              [-35.041075755102035, -8.588195678928212],
              [-35.041017196550968, -8.588148633509698],
              [-35.040960467954328, -8.588103397524542],
              [-35.040925962955015, -8.588076815998077],
              [-35.040925962965574, -8.588076815998459],
              [-35.040916891775687, -8.588063712843789],
              [-35.040872674375883, -8.588028900147204],
              [-35.040744826039536, -8.58790903292887],
              [-35.040682903874995, -8.587836229723958],
              [-35.040643634816263, -8.58780281905555],
              [-35.040491347168292, -8.587659379053585],
              [-35.040311529616083, -8.587473191356846],
              [-35.040124301258849, -8.587278545450248],
              [-35.039926603565398, -8.587026561759256],
              [-35.039708940536705, -8.586791081600571],
              [-35.039510412064594, -8.586555192641127],
              [-35.039318629761112, -8.58631378072147],
              [-35.039328757136197, -8.586131956729433],
              [-35.039265769402824, -8.585937764587916],
              [-35.039123076749576, -8.585752328735145],
              [-35.039012573621953, -8.58549747834328],
              [-35.038887560855578, -8.585231414040843],
              [-35.038771852052342, -8.584969404470954],
              [-35.038733831952015, -8.584662906307695],
              [-35.038692794261614, -8.584376374721613],
              [-35.038652430555096, -8.584112294189207],
              [-35.038613056720635, -8.583851580213992],
              [-35.038572266461863, -8.583601133781304],
              [-35.038617007009826, -8.583371686286446],
              [-35.038558562651659, -8.583133464479939],
              [-35.038500253190826, -8.582879657469416],
              [-35.038411139220344, -8.58259735740736],
              [-35.038293745073531, -8.582320485988367],
              [-35.038176540456391, -8.58201441521437],
              [-35.038139324104442, -8.581693195872571],
              [-35.038176310986579, -8.58138245907802],
              [-35.038217165987106, -8.581068149236524],
              [-35.038263834465049, -8.580750599576021],
              [-35.038304438376549, -8.580453918516731],
              [-35.038333408867338, -8.580205064796688],
              [-35.038349060438946, -8.57999793647447],
              [-35.038325843515651, -8.579767573601913],
              [-35.038253417274582, -8.579518071555324],
              [-35.038139680115819, -8.579318641800507],
              [-35.038025600612706, -8.57907543444929],
              [-35.037896778156636, -8.578838515207142],
              [-35.037761557547782, -8.578597344857959],
              [-35.037589032755378, -8.578352014330546],
              [-35.0374017022258, -8.578136954535101],
              [-35.03679646794923, -8.577362012708443],
              [-35.036711362059407, -8.577005704698767],
              [-35.036722957311902, -8.576216198710055],
              [-35.036725887009617, -8.575704128082855],
              [-35.036723644274247, -8.575692953613041],
              [-35.03667577689108, -8.575454453247957],
              [-35.036561360930691, -8.575214094233923],
              [-35.035929201022483, -8.574040367043972],
              [-35.03565804207755, -8.573599275505543],
              [-35.035173442839465, -8.572938983723672],
              [-35.034996349387171, -8.572718272217251],
              [-35.034607249776585, -8.572237194718905],
              [-35.034432809803931, -8.571982497476656],
              [-35.034268268731623, -8.571761809670882],
              [-35.034121888026021, -8.571536985294037],
              [-35.033974065929897, -8.571317745934385],
              [-35.033826313370561, -8.571155953668301],
              [-35.03367124132302, -8.570975460487368],
              [-35.033127851112283, -8.57037297600532],
              [-35.032951093817339, -8.570211625238978],
              [-35.032754305937267, -8.570001483819219],
              [-35.032385519382387, -8.569650305489027],
              [-35.031878239337921, -8.569166826438972],
              [-35.031481511000095, -8.568783485803142],
              [-35.03137163414484, -8.568683632140578],
              [-35.031263375095492, -8.568597682108388],
              [-35.031138656499586, -8.568485485795311],
              [-35.031021122213069, -8.568380212253349],
              [-35.030887283791557, -8.568264842161263],
              [-35.026543829797554, -8.563503623472752],
              [-35.024858521859478, -8.562095146732364],
              [-35.024859813860914, -8.562093560374608],
              [-35.024819573580409, -8.562092526452304],
              [-35.024710128102875, -8.562013450255177],
              [-35.024615271011903, -8.56189886159348],
              [-35.024531868561048, -8.561789880840211],
              [-35.024426887208385, -8.561689383945835],
              [-35.024377141856462, -8.561647356357023],
              [-35.024306160251761, -8.561582433711562],
              [-35.024187126154473, -8.56148303182778],
              [-35.024082307612808, -8.561392685638848],
              [-35.023957213716002, -8.561282530913068],
              [-35.023850522743231, -8.561198739255671],
              [-35.023774570845298, -8.561145332084036],
              [-35.023770540697043, -8.561142306774528],
              [-35.023771156273725, -8.561142537567584],
              [-35.023780267991398, -8.561145871759472],
              [-35.023786493969894, -8.561146806416884],
              [-35.023786972712571, -8.561146315760849],
              [-35.023786936290797, -8.56114629787198],
              [-35.023750535682545, -8.561130695945238],
              [-35.023615504334927, -8.561040760895073],
              [-35.023456985160301, -8.560943508993933],
              [-35.023299268474453, -8.560836553100145],
              [-35.023178618005055, -8.560778353633347],
              [-35.023096251532408, -8.560726100799521],
              [-35.022938253132743, -8.56063283245215],
              [-35.022812673232856, -8.56052512955104],
              [-35.022715697009097, -8.560444170328962],
              [-35.022629334499101, -8.560398763329211],
              [-35.022571623220685, -8.560385677367698],
              [-35.02256425699953, -8.560383790434239],
              [-35.022562572855506, -8.560383030863303],
              [-35.022545511909264, -8.560378582154774],
              [-35.022416384339415, -8.560347139377322],
              [-35.022095325844909, -8.560240730120974],
              [-35.021900701885706, -8.560156240664606],
              [-35.02173975356822, -8.560097609610891],
              [-35.021652681072339, -8.560058750884711],
              [-35.021582783745814, -8.560025334674883],
              [-35.021550202935934, -8.560010734016567],
              [-35.021538977289318, -8.560005468275575],
              [-35.021462152558826, -8.559979311078429],
              [-35.021254999361219, -8.559907515375254],
              [-35.021045396355262, -8.559830977409073],
              [-35.020829739809592, -8.559753467537133],
              [-35.02060263423251, -8.559692026887447],
              [-35.020409318974757, -8.559621396778978],
              [-35.020223197206448, -8.559573274272461],
              [-35.020040150535529, -8.559531345952598],
              [-35.01986652213413, -8.559494665659871],
              [-35.019741175026823, -8.55946534455234],
              [-35.019615819631674, -8.559441338858676],
              [-35.01958976336045, -8.559437777701495],
              [-35.019524569690901, -8.559419686097002],
              [-35.019292663159369, -8.559369162652407],
              [-35.019065128805316, -8.55933314321828],
              [-35.018899502160011, -8.559315530848455],
              [-35.01873879194752, -8.559279568999651],
              [-35.01854874963346, -8.559278464140652],
              [-35.018368503708075, -8.559247521801439],
              [-35.018262425219724, -8.559240030191448],
              [-35.018135403276084, -8.55921342016854],
              [-35.018001732485786, -8.559205584378409],
              [-35.017973140976274, -8.559205200280395],
              [-35.017815336187866, -8.559208735957128],
              [-35.017571698410983, -8.559218993193324],
              [-35.017364259626859, -8.559241129553318],
              [-35.017172380382434, -8.559248214648585],
              [-35.016999527138793, -8.559269031330125],
              [-35.016860296052435, -8.559274585034887],
              [-35.016669677411535, -8.559293424138913],
              [-35.016551241317003, -8.559305441214425],
              [-35.016421095624537, -8.559320312645092],
              [-35.016302395124015, -8.559330414580858],
              [-35.015985784361924, -8.559406174475114],
              [-35.015829005472845, -8.559430405252236],
              [-35.015773395960387, -8.559438731655598],
              [-35.015773178098065, -8.559438750871342],
              [-35.015773087330025, -8.559438760384504],
              [-35.015773395151598, -8.559438577982474],
              [-35.015773694367368, -8.559438486023767],
              [-35.015731391332075, -8.55944539609723],
              [-35.015587181827939, -8.559464489887898],
              [-35.015425625672272, -8.559492144403007],
              [-35.015252530317056, -8.559515348129571],
              [-35.01508504464104, -8.559551820155573],
              [-35.014859419901285, -8.559597454208498],
              [-35.014601955561382, -8.559653704145431],
              [-35.014346322442876, -8.559723160646628],
              [-35.014108655336322, -8.559772328359305],
              [-35.013866495718133, -8.559841081586876],
              [-35.013640822087794, -8.55991550718875],
              [-35.013523161864626, -8.559961183777471],
              [-35.01352317969512, -8.559961120405617],
              [-35.013523198095925, -8.55996116550903],
              [-35.013522999013908, -8.559961302143885],
              [-35.013522608838358, -8.559961367455305],
              [-35.013522536375767, -8.559961403992187],
              [-35.013522427206624, -8.559961368401432],
              [-35.013522345424818, -8.559961359787575],
              [-35.013522354506414, -8.559961359740273],
              [-35.013522381608588, -8.559961332479546],
              [-35.013522290364989, -8.559961251596128],
              [-35.013522290460038, -8.559961269675345],
              [-35.013522308528159, -8.559961251501516],
              [-35.013522290079834, -8.559961197358492],
              [-35.013522299446578, -8.559961251548826],
              [-35.013522180958219, -8.559961170807341],
              [-35.013522090522557, -8.559961243597275],
              [-35.013522026571245, -8.559961171611548],
              [-35.013521899381502, -8.559961163234227],
              [-35.013521889824659, -8.559961072885462],
              [-35.013521807947804, -8.55996104619239],
              [-35.013521762159662, -8.559960974112071],
              [-35.013521662119622, -8.559960947513613],
              [-35.013521662072101, -8.559960938474021],
              [-35.013521580337823, -8.559960938899779],
              [-35.01352156188949, -8.559960884756736],
              [-35.013521534454625, -8.559960848740221],
              [-35.0135214247627, -8.559960713713796],
              [-35.013521487811012, -8.559960613946972],
              [-35.013521396852568, -8.559960587301207],
              [-35.013521450866833, -8.559960496621294],
              [-35.013521459663252, -8.559960442336333],
              [-35.013521359338078, -8.559960361500247],
              [-35.013521413162231, -8.559960234661899],
              [-35.013521385917478, -8.559960234803816],
              [-35.013521394761426, -8.559960189558485],
              [-35.01352143983668, -8.559960126044693],
              [-35.013521375932889, -8.559960063098584],
              [-35.013521411831505, -8.559959981552899],
              [-35.013521330192276, -8.559960000057874],
              [-35.013521257587108, -8.55996000947593],
              [-35.013521176042936, -8.559960046060109],
              [-35.013521175995407, -8.559960037020501],
              [-35.013521184934419, -8.559960009854384],
              [-35.013521084989435, -8.559960001335147],
              [-35.013521030785064, -8.559960055856617],
              [-35.013521030547444, -8.559960010658592],
              [-35.013521003397734, -8.55996002887971],
              [-35.013520958179903, -8.559960065274678],
              [-35.013520858282455, -8.559960065795046],
              [-35.013520885432158, -8.559960047573909],
              [-35.013520812969567, -8.559960084110795],
              [-35.01352084886819, -8.559960002565111],
              [-35.01352083018223, -8.559959903224039],
              [-35.013520793808347, -8.559959894373657],
              [-35.013520685066943, -8.559959940139363],
              [-35.013520712359231, -8.559959949037051],
              [-35.013520603522764, -8.559959976723551],
              [-35.013520639563957, -8.559959922296672],
              [-35.013520657679607, -8.559959913162469],
              [-35.013520567434057, -8.559960022110822],
              [-35.013520476380549, -8.559959977385837],
              [-35.013520394646271, -8.559959977811598],
              [-35.013520376197945, -8.559959923668554],
              [-35.013520385374584, -8.55995994170047],
              [-35.01352031248426, -8.559959896880894],
              [-35.013520439341313, -8.55995984198095],
              [-35.013520376435579, -8.559959968866599],
              [-35.013520277013377, -8.559960059783045],
              [-35.013520168699706, -8.559960186905224],
              [-35.013520150441472, -8.559960168920618],
              [-35.013520068944828, -8.55996021454442],
              [-35.013520023394321, -8.559960187662119],
              [-35.01351999610204, -8.559960178764433],
              [-35.013519942135297, -8.559960278483951],
              [-35.013519860828751, -8.559960360266173],
              [-35.013519933481454, -8.55996035988772],
              [-35.013519824787565, -8.55996041469305],
              [-35.013519879182027, -8.559960396329995],
              [-35.013519834154309, -8.559960468883379],
              [-35.013519788841428, -8.559960487199135],
              [-35.013519707154671, -8.559960496664496],
              [-35.013519716378823, -8.559960523736015],
              [-35.013519743766167, -8.559960550712905],
              [-35.01351966236458, -8.559960614415925],
              [-35.013519462759774, -8.559960651615084],
              [-35.013519599126148, -8.55996067802432],
              [-35.013519698690914, -8.559960614226702],
              [-35.013519689419233, -8.55996057811557],
              [-35.013519725745574, -8.559960577926349],
              [-35.013519825405396, -8.559960532207949],
              [-35.013519879847394, -8.559960522884504],
              [-35.013519852602627, -8.559960523026419],
              [-35.013519816466392, -8.559960559374067],
              [-35.013519807147176, -8.559960514223341],
              [-35.013519861826801, -8.559960550097925],
              [-35.013519889024032, -8.559960540916416],
              [-35.013519870718284, -8.559960513892202],
              [-35.013519816418864, -8.559960550334456],
              [-35.013519861589167, -8.559960504899898],
              [-35.013519888453715, -8.559960432441125],
              [-35.013519861541646, -8.55996049586029],
              [-35.013519806957063, -8.559960478064912],
              [-35.013519843425989, -8.559960504994509],
              [-35.013519752515066, -8.559960487388356],
              [-35.013519815943603, -8.559960459938383],
              [-35.013519788698844, -8.559960460080305],
              [-35.0135196615091, -8.559960451702981],
              [-35.013519697930498, -8.559960469592975],
              [-35.013519661414051, -8.559960433623782],
              [-35.013519552767697, -8.559960497468703],
              [-35.013519707012094, -8.559960469545667],
              [-35.013519715808521, -8.559960415260724],
              [-35.013519670543161, -8.559960442616068],
              [-35.013519624945125, -8.559960406694179],
              [-35.013519570360558, -8.559960388898796],
              [-35.013519552007281, -8.559960352834979],
              [-35.013519579156991, -8.559960334613857],
              [-35.013519533796583, -8.559960343889982],
              [-35.013519424579904, -8.559960299259629],
              [-35.013519497042495, -8.55996026272274],
              [-35.013519388396148, -8.559960326567674],
              [-35.013519388586239, -8.559960362726098],
              [-35.013519307042067, -8.55996039931029],
              [-35.013519252362443, -8.559960363435689],
              [-35.013519324634935, -8.559960290740388],
              [-35.013519297675323, -8.559960345119942],
              [-35.013519279702251, -8.559960381372992],
              [-35.013519297722858, -8.559960354159552],
              [-35.013519216178679, -8.559960390743743],
              [-35.013519071491125, -8.559960509015543],
              [-35.013519071063392, -8.559960427659082],
              [-35.013519134301816, -8.559960364050676],
              [-35.013519107104578, -8.559960373232203],
              [-35.01351903449941, -8.559960382650258],
              [-35.013518979867314, -8.559960355815269],
              [-35.013518971070887, -8.559960410100233],
              [-35.013519016383768, -8.559960391784481],
              [-35.01351904348595, -8.559960364523731],
              [-35.013518989234065, -8.559960410005621],
              [-35.013519016478824, -8.559960409863701],
              [-35.013518989139008, -8.559960391926401],
              [-35.01351900768239, -8.559960464148647],
              [-35.01351899864833, -8.559960473235558],
              [-35.013518998838421, -8.559960509393992],
              [-35.013519053375482, -8.559960518149765],
              [-35.013519089511725, -8.559960481802104],
              [-35.013519098308151, -8.55996042751716],
              [-35.013519025845561, -8.559960464054033],
              [-35.013519008157644, -8.559960554544714],
              [-35.013519080667763, -8.559960527047455],
              [-35.013519107769937, -8.559960499786708],
              [-35.013519107722423, -8.559960490747102],
              [-35.013519198395706, -8.55996046315521],
              [-35.013519161974308, -8.559960445265217],
              [-35.01351909849825, -8.559960463675578],
              [-35.013519071348554, -8.559960481896715],
              [-35.013519198443234, -8.55996047219482],
              [-35.013519198490762, -8.559960481234432],
              [-35.013519171578679, -8.559960544653611],
              [-35.013519198728382, -8.559960526432471],
              [-35.013519298530788, -8.559960507832884],
              [-35.013519307517321, -8.559960489706361],
              [-35.013519289401678, -8.559960498840587],
              [-35.013519298293161, -8.559960462634841],
              [-35.013519298625845, -8.559960525912105],
              [-35.013519344128831, -8.559960543754794],
              [-35.013519425863109, -8.559960543329037],
              [-35.013519408127664, -8.559960624780109],
              [-35.013519444358963, -8.559960606511666],
              [-35.0135194897669, -8.559960606275135],
              [-35.013519426385898, -8.559960642764713],
              [-35.013519371563696, -8.559960579771294],
              [-35.013519380835383, -8.559960615882419],
              [-35.013519453773235, -8.559960669741606],
              [-35.013519390392233, -8.559960706231186],
              [-35.013519317549431, -8.559960670451201],
              [-35.013519308230222, -8.559960625300482],
              [-35.013519335427453, -8.55996061611895],
              [-35.013519262727236, -8.559960607457793],
              [-35.013519262679708, -8.559960598418186],
              [-35.013519253455542, -8.559960571346664],
              [-35.01351932596566, -8.559960543849405],
              [-35.013519299006049, -8.559960598228962],
              [-35.013519335142291, -8.559960561881299],
              [-35.013519298768422, -8.559960553030916],
              [-35.013519262442081, -8.559960553220138],
              [-35.013519298815957, -8.55996056207052],
              [-35.013519280605244, -8.559960553125526],
              [-35.013519307612377, -8.559960507785577],
              [-35.013519343938725, -8.559960507596355],
              [-35.013519325585442, -8.559960471532532],
              [-35.013519298055542, -8.559960417436818],
              [-35.013519343273359, -8.559960381041847],
              [-35.013519370565653, -8.559960389939535],
              [-35.013519361816748, -8.559960453264088],
              [-35.013519343510993, -8.559960426239892],
              [-35.013519334667031, -8.559960471485224],
              [-35.013519243661065, -8.559960435799868],
              [-35.013519343748627, -8.55996047143792],
              [-35.013519370945851, -8.559960462256392],
              [-35.013519398048047, -8.559960434995663],
              [-35.013519370613167, -8.559960398979147],
              [-35.01351933452446, -8.5599604443664],
              [-35.013519379789813, -8.559960417011055],
              [-35.013519389013972, -8.559960444082561],
              [-35.013519307422271, -8.559960471627139],
              [-35.01351931636129, -8.559960444461012],
              [-35.013519361721691, -8.559960435184889],
              [-35.013519325870604, -8.559960525770185],
              [-35.013519307184644, -8.559960426429118],
              [-35.013519307422271, -8.559960471627139],
              [-35.013519298388225, -8.559960480714059],
              [-35.013519325252766, -8.559960408255286],
              [-35.013519379742291, -8.559960407971447],
              [-35.013519406891994, -8.559960389750314],
              [-35.013519370660703, -8.559960408018759],
              [-35.013519306947011, -8.559960381231074],
              [-35.013519252647598, -8.559960417673345],
              [-35.013519334191777, -8.559960381089155],
              [-35.013519325015139, -8.559960363057238],
              [-35.013519179899859, -8.55996039997258],
              [-35.013519088798837, -8.559960346207989],
              [-35.013519070540603, -8.559960328223401],
              [-35.01351914309825, -8.559960309765732],
              [-35.013519125077657, -8.55996033697917],
              [-35.013519188458652, -8.559960300489591],
              [-35.013519188316081, -8.559960273370764],
              [-35.013519079241988, -8.559960255859224],
              [-35.013519115473287, -8.559960237590778],
              [-35.013519079337037, -8.559960273938438],
              [-35.013519007064545, -8.559960346633742],
              [-35.013518970595626, -8.559960319704157],
              [-35.013518970785725, -8.559960355862579],
              [-35.013518916153636, -8.559960329027605],
              [-35.013518843358362, -8.559960302287227],
              [-35.013519287167966, -8.559960073979033],
              [-35.013519487343075, -8.559960145255149],
              [-35.013519405561269, -8.559960136641298],
              [-35.013519423676918, -8.559960127507093],
              [-35.013519306186595, -8.559960236597345],
              [-35.013510202483765, -8.559962986935364],
              [-35.013510247796646, -8.559962968619615],
              [-35.01350559716456, -8.559964556739697],
              [-35.013501047000176, -8.559966252814661],
              [-35.013410185538127, -8.559999513656237],
              [-35.013230693008659, -8.560051514699403],
              [-35.01304159630876, -8.56011991878958],
              [-35.012858067314085, -8.56019708055099],
              [-35.012689142082053, -8.560279472596472],
              [-35.01248925810664, -8.560391504759613],
              [-35.012075846061478, -8.560578748201195],
              [-35.011469905402997, -8.560910907954435],
              [-35.011271600362484, -8.561041860901634],
              [-35.01106996442433, -8.561181898095912],
              [-35.010874849355638, -8.561306415994457],
              [-35.010704779059289, -8.561442483036149],
              [-35.010526772009129, -8.561571811415524],
              [-35.010198772695261, -8.561859403556095],
              [-35.010102159162997, -8.561947574767007],
              [-35.009982598998526, -8.562058880828342],
              [-35.009850782606954, -8.562179679198755],
              [-35.009671662857066, -8.5623809072308],
              [-35.009540568555771, -8.562468019210399],
              [-35.009436761399662, -8.562582063694393],
              [-35.009337958030741, -8.562701243914361],
              [-35.009210012850183, -8.562834677855642],
              [-35.008933888898653, -8.563032550035208],
              [-35.008811202195346, -8.563150462207572],
              [-35.008673175169044, -8.56328552139566],
              [-35.008539209082464, -8.563424310996284],
              [-35.008388131056563, -8.563578403636834],
              [-35.008368572108765, -8.563596395225714],
              [-35.008368779660003, -8.563596141030226],
              [-35.008369358280547, -8.563595640829199],
              [-35.008369874277676, -8.563595321751295],
              [-35.008370064614006, -8.563595248442915],
              [-35.008370363456514, -8.563595084171716],
              [-35.008370616321422, -8.563594811660941],
              [-35.008370707043575, -8.563594793109612],
              [-35.00837081512293, -8.563594620790104],
              [-35.008370831721315, -8.563594322387758],
              [-35.008370856879729, -8.56359392450223],
              [-35.00837074671368, -8.563593699077829],
              [-35.008370609492232, -8.563593509953582],
              [-35.008370229910334, -8.563593864481936],
              [-35.008369929645085, -8.563593757564092],
              [-35.008369765889903, -8.563593704175991],
              [-35.008369610362777, -8.563593488027252],
              [-35.008369428207068, -8.563593389535374],
              [-35.008369174630801, -8.563593526451639],
              [-35.008369010401374, -8.563593382667179],
              [-35.008368855443351, -8.563593274994052],
              [-35.008368728204672, -8.563593257575652],
              [-35.008368574811669, -8.563593448210456],
              [-35.008368402069635, -8.563593412948828],
              [-35.008368319622953, -8.563593277779153],
              [-35.008368236322617, -8.563592979896066],
              [-35.00836813580738, -8.563592862900071],
              [-35.008368106712702, -8.563592510495939],
              [-35.008368196344101, -8.563592284033033],
              [-35.008368133388714, -8.563592401878724],
              [-35.008367997495164, -8.563592465864232],
              [-35.008367906725567, -8.563592475375922],
              [-35.00836785209308, -8.56359244854025],
              [-35.008367789374816, -8.563592611584113],
              [-35.008367744962221, -8.56359280165246],
              [-35.008367673304541, -8.56359299186242],
              [-35.00836769194219, -8.563593082164353],
              [-35.00836768333474, -8.5635931726079],
              [-35.008367701023893, -8.563593082117151],
              [-35.008367728458694, -8.563593118134078],
              [-35.008367800163811, -8.563592936963733],
              [-35.008367890269461, -8.563592800897176],
              [-35.008367999392156, -8.563592827449606],
              [-35.008367998965319, -8.563592746092899],
              [-35.008368062821781, -8.563592800000281],
              [-35.008368044800662, -8.563592827213581],
              [-35.008368009422348, -8.563593008195101],
              [-35.008367955406392, -8.563593098874673],
              [-35.008367928303556, -8.563593126135199],
              [-35.008367982651492, -8.563593098733058],
              [-35.008368009754321, -8.563593071472535],
              [-35.008368028344542, -8.563593152734832],
              [-35.00836805611133, -8.563593252029197],
              [-35.008367993819881, -8.563593496429768],
              [-35.008367976273007, -8.56359361403943],
              [-35.008367931006774, -8.563593641394348],
              [-35.00836799438899, -8.563593604905382],
              [-35.008368111502605, -8.563593423499034],
              [-35.008368238883556, -8.563593468036323],
              [-35.008368293089219, -8.563593413515289],
              [-35.008368275115508, -8.563593449768232],
              [-35.008368274783543, -8.563593386490796],
              [-35.00836819314307, -8.563593404994913],
              [-35.008368211353904, -8.56359341394014],
              [-35.008368166087664, -8.563593441295057],
              [-35.008368111550034, -8.563593432538671],
              [-35.00836809324435, -8.563593405514167],
              [-35.00836816589797, -8.563593405136528],
              [-35.008368102468324, -8.563593432585874],
              [-35.008368102231202, -8.563593387387694],
              [-35.00836806618895, -8.563593441814314],
              [-35.008368020875295, -8.56359346012961],
              [-35.008367903002863, -8.563593496901822],
              [-35.008367911894865, -8.563593460696069],
              [-35.008367893921161, -8.563593496949025],
              [-35.008367885171445, -8.563593560273665],
              [-35.008367912321702, -8.563593542052777],
              [-35.00836778574697, -8.563593651189263],
              [-35.008367767820694, -8.563593696481854],
              [-35.008367831914271, -8.563593795587396],
              [-35.008367804621741, -8.563593786689379],
              [-35.008367741429232, -8.563593859336885],
              [-35.008367677145948, -8.563593724072813],
              [-35.008367668016824, -8.563593715080383],
              [-35.008367740575586, -8.563593696623469],
              [-35.008367767725836, -8.563593678402583],
              [-35.008367867577135, -8.563593668843689],
              [-35.008367876516566, -8.563593641677578],
              [-35.008367849318873, -8.563593650858829],
              [-35.008367903524544, -8.563593596337801],
              [-35.008368012315259, -8.563593559612793],
              [-35.008367984880458, -8.563593523595882],
              [-35.008367984785608, -8.563593505516609],
              [-35.008367984690757, -8.563593487437338],
              [-35.008368030099263, -8.563593487201313],
              [-35.00836803019412, -8.56359350528059],
              [-35.008367985165009, -8.563593577833679],
              [-35.008368039418094, -8.563593532352288],
              [-35.00836809376603, -8.56359350495015],
              [-35.00836806661578, -8.563593523171036],
              [-35.008368039418094, -8.563593532352288],
              [-35.008368012267837, -8.563593550573158],
              [-35.008367984927879, -8.563593532635519],
              [-35.008368012267837, -8.563593550573158],
              [-35.008367939329673, -8.563593496712997],
              [-35.008368057818615, -8.563593577456038],
              [-35.008368075934591, -8.563593568321995],
              [-35.008368094287697, -8.56359360438613],
              [-35.008368021586669, -8.563593595724134],
              [-35.008368030573514, -8.563593577597656],
              [-35.008368094240275, -8.563593595346488],
              [-35.008368121532804, -8.563593604244515],
              [-35.008368057866043, -8.563593586495676],
              [-35.008368185104715, -8.563593603914073],
              [-35.008368103559107, -8.563593640497452],
              [-35.008368085206008, -8.563593604433329],
              [-35.008368203078419, -8.563593567661119],
              [-35.008368248534353, -8.563593576464728],
              [-35.008368275874311, -8.563593594402388],
              [-35.00836830326169, -8.563593621379678],
              [-35.008368321472517, -8.563593630324908],
              [-35.008368476145996, -8.563593683760221],
              [-35.00836848133887, -8.563592942462986],
              [-35.008368718364174, -8.563593112988713],
              [-35.008368773044083, -8.56359314886401],
              [-35.008368827629141, -8.563593166660056],
              [-35.008368864145652, -8.563593202629773],
              [-35.008368864050802, -8.563593184550502],
              [-35.008337900831179, -8.563627263124859],
              [-35.008196615597001, -8.563769733774839],
              [-35.008094998909648, -8.563860009864998],
              [-35.008056999050538, -8.563892723816428],
              [-35.008025974995057, -8.563941088728782],
              [-35.008006947263674, -8.563949645927488],
              [-35.007999394171676, -8.563950236614485],
              [-35.007958779917892, -8.563958945178133],
              [-35.007552689739214, -8.563818794736648],
              [-35.007563550569024, -8.563766614327376],
              [-35.007580923142491, -8.563594938002327],
              [-35.007558697954245, -8.563457809940568],
              [-35.007533457044268, -8.563303259602133],
              [-35.007513769075175, -8.563145977481113],
              [-35.007488742950663, -8.562989075642665],
              [-35.007469119821742, -8.562851075249453],
              [-35.007458996066248, -8.562721667633616],
              [-35.007363654403342, -8.56236666033252],
              [-35.007267307058683, -8.562145457559861],
              [-35.007145643874182, -8.561937222860282],
              [-35.007034666279182, -8.56172996314476],
              [-35.006995199313103, -8.56154687531509],
              [-35.007002169215696, -8.561521663028778],
              [-35.007002366880073, -8.561521255207264],
              [-35.007002384948635, -8.56152123703367],
              [-35.007002448709777, -8.561521272862096],
              [-35.007008876995194, -8.561508818671934],
              [-35.00706093224575, -8.561493643848024],
              [-35.00753753064042, -8.560967406685872],
              [-35.007659512029555, -8.560605069449712],
              [-35.007790010333821, -8.560292011200731],
              [-35.007998351570109, -8.559860391568396],
              [-35.00813395146087, -8.559677981965674],
              [-35.008993099787553, -8.558205965632974],
              [-35.009253827921491, -8.557651020369331],
              [-35.00927871589014, -8.557549590155929],
              [-35.009312340474317, -8.55742390574785],
              [-35.009336195391136, -8.557295207591823],
              [-35.009366636323705, -8.557010998345266],
              [-35.009375980688617, -8.556863663049436],
              [-35.009385256235014, -8.556692815388047],
              [-35.009381823435781, -8.556507551878191],
              [-35.009371126535186, -8.556334656505795],
              [-35.009388529257357, -8.556149754801543],
              [-35.009402593399066, -8.555965521317363],
              [-35.009402494589899, -8.555792552793617],
              [-35.009407176217664, -8.555578672058894],
              [-35.009382566235544, -8.5553642203219],
              [-35.009342660688567, -8.555144198117137],
              [-35.009287131130321, -8.554868083252522],
              [-35.009238672553181, -8.55461202727947],
              [-35.009186644793751, -8.554399245650472],
              [-35.009124262471104, -8.554189645582467],
              [-35.009044351387018, -8.553969433310966],
              [-35.008924253923979, -8.553726712485568],
              [-35.00882041211176, -8.553515031485771],
              [-35.008703354253093, -8.553295979232539],
              [-35.008573973885184, -8.553066757743348],
              [-35.008462177812021, -8.552872573873266],
              [-35.008339122293037, -8.552704718302678],
              [-35.008249937829433, -8.552572548071639],
              [-35.008218583690521, -8.552545753890078],
              [-35.008218511275793, -8.552545799465411],
              [-35.008218474950034, -8.552545799653981],
              [-35.00821846567915, -8.552545763542554],
              [-35.008217477732323, -8.552544403648096],
              [-35.008202966114162, -8.552530711229821],
              [-35.008202893652069, -8.552530747765514],
              [-35.008202757572548, -8.552530775591553],
              [-35.008202748538459, -8.552530784678337],
              [-35.008196364062172, -8.552525574685301],
              [-35.008184820121336, -8.552516694160547],
              [-35.008184792734937, -8.55251666718304],
              [-35.008184647431904, -8.552516667937281],
              [-35.008184656608051, -8.552516685969429],
              [-35.008184520339093, -8.552516677636891],
              [-35.00818444711927, -8.552516569538328],
              [-35.008156846997366, -8.552492133353352],
              [-35.008156719336284, -8.552492034577254],
              [-35.008156655766207, -8.552492034907239],
              [-35.008156591864626, -8.552491971959734],
              [-35.008156510273736, -8.552491999502918],
              [-35.008156464724472, -8.552491972619686],
              [-35.008156392167663, -8.552491991076083],
              [-35.008156301211187, -8.552491964428555],
              [-35.008156237641117, -8.552491964758527],
              [-35.008156156050234, -8.552491992301711],
              [-35.008156047072958, -8.552491992867383],
              [-35.008156092622222, -8.552492019750593],
              [-35.008156019733917, -8.55249197492952],
              [-35.008155965197929, -8.55249196617271],
              [-35.008155901675195, -8.552491975542333],
              [-35.008155820084312, -8.552492003085517],
              [-35.008155902480269, -8.552492129216215],
              [-35.008145410461722, -8.552481218294963],
              [-35.008143175895547, -8.552479394796952],
              [-35.008143011624568, -8.55247924197157],
              [-35.008142966406787, -8.552479278365821],
              [-35.008142884531772, -8.552479251671143],
              [-35.008142811927613, -8.552479261087901],
              [-35.008142784541228, -8.552479234110407],
              [-35.008142748357535, -8.552479261417874],
              [-35.008142675706026, -8.552479261794987],
              [-35.008142584891623, -8.552479262266377],
              [-35.008142684834816, -8.552479270787487],
              [-35.008142602959786, -8.552479244092829],
              [-35.008142593973062, -8.552479262219231],
              [-35.008142448717386, -8.552479272013102],
              [-35.008142421378352, -8.552479254075237],
              [-35.008142348774193, -8.552479263491993],
              [-35.008142339787469, -8.552479281618416],
              [-35.008142258101863, -8.552479291082308],
              [-35.00814220366059, -8.552479300404787],
              [-35.00814217655833, -8.552479327665131],
              [-35.008142167571613, -8.552479345791555],
              [-35.008142158490173, -8.552479345838693],
              [-35.008142113367121, -8.552479400312228],
              [-35.008142149740237, -8.552479409163309],
              [-35.008142076993998, -8.552479391461141],
              [-35.008141995024268, -8.552479346687196],
              [-35.008142151303019, -8.552479707471457],
              [-35.00814217849998, -8.552479698290401],
              [-35.008142719230051, -8.55248063563185],
              [-35.008151736598194, -8.552490894296005],
              [-35.00814879778855, -8.552488125265491],
              [-35.008119981806601, -8.552450027079175],
              [-35.007936183063684, -8.552211821793742],
              [-35.007887755227635, -8.552173978545326],
              [-35.005880240277719, -8.550605704410346],
              [-35.00574586529406, -8.550488806260985],
              [-35.005570020116394, -8.550319207068661],
              [-35.005385184381034, -8.55014268458619],
              [-35.005183699056438, -8.549953411584024],
              [-35.004975034093455, -8.549804800958904],
              [-35.004801640644423, -8.549633281141791],
              [-35.004604259191296, -8.549462816574232],
              [-35.004420470149846, -8.5492813612587],
              [-35.004259427341729, -8.549109677680475],
              [-35.004034715068236, -8.548912243546622],
              [-35.003821731763168, -8.548680884996344],
              [-35.003619586665238, -8.548474546879925],
              [-35.003432469245759, -8.548271186313842],
              [-35.00327342949025, -8.548039457682371],
              [-35.003183596452757, -8.547787763216293],
              [-35.003136502138986, -8.547527485613987],
              [-35.003117980164248, -8.547270983461093],
              [-35.003127471168796, -8.547022454190186],
              [-35.003137608243279, -8.54679329411519],
              [-35.003155044800948, -8.54658111843151],
              [-35.003150552498532, -8.546362583634735],
              [-35.003145472025551, -8.546130537203631],
              [-35.003149645489316, -8.545912364360088],
              [-35.003142449135879, -8.545711634099156],
              [-35.003115827312385, -8.545526543950915],
              [-35.003089910441496, -8.54533557420077],
              [-35.0030627015202, -8.545179584475518],
              [-35.003075883598179, -8.545080675920019],
              [-35.003061307780087, -8.545011107805617],
              [-35.003046019990336, -8.544949543698772],
              [-35.00303662549905, -8.544891339072672],
              [-35.00302980717214, -8.544832560644242],
              [-35.003002055065906, -8.544721431864259],
              [-35.002990323368458, -8.54465738145722],
              [-35.002983889111434, -8.544595626905322],
              [-35.002980991169537, -8.544541519910366],
              [-35.002967039089775, -8.544483564849777],
              [-35.002952449989927, -8.544430575997364],
              [-35.002948209288888, -8.544397593193446],
              [-35.002946882515189, -8.544390467561225],
              [-35.002939297258422, -8.544353515452949],
              [-35.002927660923163, -8.544295548409446],
              [-35.002914364985315, -8.544238023864725],
              [-35.002898697812007, -8.544187273441816],
              [-35.002883545814676, -8.544129089539982],
              [-35.002867488077783, -8.54406962665259],
              [-35.002855971849584, -8.544005086960029],
              [-35.002844347574317, -8.543942464287559],
              [-35.002832849455189, -8.543883131491549],
              [-35.002815454961748, -8.543826803322609],
              [-35.002797583717353, -8.543766129416372],
              [-35.002782772488423, -8.543705367363069],
              [-35.002776091162524, -8.543641498730672],
              [-35.002761288882134, -8.543573748770763],
              [-35.002752440115593, -8.543503500122478],
              [-35.002741108895648, -8.543437856589755],
              [-35.002735028744667, -8.543367367623553],
              [-35.002729501635834, -8.543298439702584],
              [-35.002738202493973, -8.543232466577063],
              [-35.002738707195689, -8.543219545922582],
              [-35.002927535443057, -8.539577182438233],
              [-35.002916883100823, -8.539555613145946],
              [-35.003019297282769, -8.53930730854545],
              [-35.003164892733963, -8.539072801616163],
              [-35.003326050463741, -8.538841188342355],
              [-35.003433877253379, -8.538612119806954],
              [-35.003551416826433, -8.538443161694389],
              [-35.003706292022137, -8.538210233903733],
              [-35.003877965894652, -8.537983944919766],
              [-35.004038245938325, -8.537751359765705],
              [-35.004170425521608, -8.537542640647212],
              [-35.004297326627274, -8.537349181067528],
              [-35.004423818884142, -8.537117430517705],
              [-35.004567107489954, -8.536884915089857],
              [-35.004694195379734, -8.536633364031164],
              [-35.00482310888998, -8.536390590315557],
              [-35.004947415379362, -8.536162964192552],
              [-35.005063071904281, -8.535925791462702],
              [-35.005273253881235, -8.535469119848861],
              [-35.005390676548515, -8.535196320730847],
              [-35.00561960383547, -8.534701801466005],
              [-35.005664491554448, -8.534579263656569],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3304',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA SÃO JOÃO DA VÁRZEA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12260',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '11/03/2016',
        CODIGO_U11: '0000.26.3304',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.977126488807542, -8.061523216971269],
              [-34.975089165026723, -8.061568920248044],
              [-34.974974109867254, -8.061598177983194],
              [-34.974816169982873, -8.061692224281298],
              [-34.974629884244436, -8.061879691972747],
              [-34.974479492252627, -8.062045458825843],
              [-34.97432183132544, -8.062196909763621],
              [-34.974149805500609, -8.062355605645836],
              [-34.974006647449599, -8.062528513459158],
              [-34.973899418139951, -8.062686897389021],
              [-34.973813961641874, -8.062881055109566],
              [-34.973764363895825, -8.063046336036695],
              [-34.97376519976735, -8.063218550955893],
              [-34.973780225563189, -8.063347642155891],
              [-34.973787842445063, -8.063433714620817],
              [-34.973543436217945, -8.063513825945416],
              [-34.973248631367937, -8.063594179232961],
              [-34.972982626302354, -8.06367439354173],
              [-34.972774112821909, -8.063732804027923],
              [-34.972493359917685, -8.063741331776434],
              [-34.972241335808697, -8.063735369636124],
              [-34.972039779311899, -8.06374351544288],
              [-34.971852517723335, -8.063730065395319],
              [-34.971672247113112, -8.06367352662323],
              [-34.971527905244173, -8.063602463967685],
              [-34.971412536132966, -8.063567140108152],
              [-34.971304644925027, -8.063589186893649],
              [-34.971189728744193, -8.063647146448663],
              [-34.971039125784586, -8.063769858571895],
              [-34.970881357873509, -8.063899781644823],
              [-34.970629958668219, -8.064022979693913],
              [-34.970342560732234, -8.06414635071658],
              [-34.970091266510863, -8.064291074630573],
              [-34.970360157131907, -8.064806437583973],
              [-34.971414165772011, -8.066875166337283],
              [-34.972395424068353, -8.068786372029235],
              [-34.97328243316646, -8.070561688508695],
              [-34.974423432073046, -8.072744793608015],
              [-34.974595705860374, -8.072636326410583],
              [-34.974501410668005, -8.072493266242139],
              [-34.974457584087737, -8.072364314020774],
              [-34.974449827227396, -8.072249538735891],
              [-34.974470555199758, -8.072070044877407],
              [-34.974512883010512, -8.071890447034788],
              [-34.974555453783751, -8.071761077877069],
              [-34.974612634417113, -8.07167469245975],
              [-34.974670129002142, -8.071652887637205],
              [-34.974749466388104, -8.07168120751542],
              [-34.974850647574847, -8.071759653094379],
              [-34.974922750019992, -8.07178083301228],
              [-34.974980139621145, -8.071737501324812],
              [-34.975399664251881, -8.07065193643631],
              [-34.97550619666886, -8.07035004040684],
              [-34.975570437269752, -8.070234918174943],
              [-34.975570053328681, -8.070155986670214],
              [-34.975548175533191, -8.070098686014555],
              [-34.97544003936278, -8.070070505115169],
              [-34.975238339887326, -8.070049951101867],
              [-34.974971773907527, -8.070015359130911],
              [-34.974834767963294, -8.069972965300396],
              [-34.974697484061963, -8.069873166830094],
              [-34.974603329848087, -8.06975880848163],
              [-34.974559538262461, -8.06963703221529],
              [-34.974537172541986, -8.069479273190005],
              [-34.974572509374404, -8.06934276307202],
              [-34.974629446045228, -8.069206148969895],
              [-34.974708120532966, -8.069098132703585],
              [-34.974837367616807, -8.06902575224923],
              [-34.974995205517281, -8.068910178086234],
              [-34.975074054978258, -8.068838040594775],
              [-34.975131234611574, -8.068751655177529],
              [-34.975166396470762, -8.068579267284676],
              [-34.97520173330323, -8.068442757166698],
              [-34.975237279103503, -8.068349301778541],
              [-34.975301867650764, -8.068305935096346],
              [-34.975431463681019, -8.068305310191723],
              [-34.975719628499249, -8.06833979817849],
              [-34.976209804744691, -8.068459420773346],
              [-34.976736224683265, -8.068629097079466],
              [-34.977276904535593, -8.068770001576359],
              [-34.977723987088211, -8.068911357004344],
              [-34.978055665535052, -8.069010215617727],
              [-34.97845930544846, -8.069101549329206],
              [-34.978610640295734, -8.069129521260386],
              [-34.978681694901688, -8.068935432529011],
              [-34.978709586825538, -8.068748728720875],
              [-34.978694209083343, -8.068547881970959],
              [-34.978628364728976, -8.068332927274055],
              [-34.97848937009018, -8.067881527010002],
              [-34.978386828112306, -8.067523235187108],
              [-34.978364217429281, -8.067315247476994],
              [-34.978356319590347, -8.06717177137223],
              [-34.978391689417414, -8.067042436209217],
              [-34.978441285163534, -8.066877155281905],
              [-34.978533904613251, -8.066675786611519],
              [-34.978655147874242, -8.066438401187501],
              [-34.978876698455991, -8.066100070468005],
              [-34.979033904453011, -8.065855335115476],
              [-34.97917664256839, -8.065596318842404],
              [-34.979318890758833, -8.065236844199712],
              [-34.979396830359093, -8.06497814087902],
              [-34.979438841218006, -8.064733962441721],
              [-34.979444994324844, -8.064518660797862],
              [-34.979422242663226, -8.064281970267935],
              [-34.979377998147164, -8.064066912587048],
              [-34.979304918851867, -8.06384481692999],
              [-34.979174347971529, -8.063644527095489],
              [-34.978957031791168, -8.063372898647705],
              [-34.978696413950161, -8.063079952303323],
              [-34.978385119529023, -8.062729843282147],
              [-34.978254548648614, -8.062529553447705],
              [-34.978123978768231, -8.062329262613293],
              [-34.977929204281658, -8.062251268965653],
              [-34.97770556202132, -8.062159064386945],
              [-34.977510579566868, -8.062038017009561],
              [-34.977380462616857, -8.061931009589705],
              [-34.977257441629085, -8.061802439310339],
              [-34.977170348382593, -8.061659344147419],
              [-34.977126488807542, -8.061523216971269],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3395',
        NOME_UC1: 'REFÚGIO DA VIDA SILVESTRE MATA DO TORÓ',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12555',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '13/04/2016',
        CODIGO_U11: '0000.26.3395',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.176456466254606, -8.053278216495785],
              [-35.176349605903113, -8.053488051105955],
              [-35.176236937569442, -8.053733790487145],
              [-35.176124142253677, -8.053955614018003],
              [-35.176058953633735, -8.054105437033526],
              [-35.175969801188444, -8.05426136599384],
              [-35.175803015275726, -8.054483476484712],
              [-35.175612362524141, -8.054729629808111],
              [-35.175403903879882, -8.055011751893584],
              [-35.17522512005673, -8.055233926375571],
              [-35.175064204117291, -8.055432089020551],
              [-35.174897545186546, -8.055678115361715],
              [-35.174760721054476, -8.055894087912241],
              [-35.174588095164076, -8.056146124211534],
              [-35.174421372242371, -8.056380192627589],
              [-35.174302320007392, -8.056554217453369],
              [-35.174129502143977, -8.05677038097719],
              [-35.174028478770204, -8.056950288778983],
              [-35.173951131262328, -8.057070279972688],
              [-35.173849852924597, -8.057202357073836],
              [-35.173814466107721, -8.057316147336127],
              [-35.173803202093779, -8.05745372546655],
              [-35.173882081385585, -8.057620721476585],
              [-35.173954770749155, -8.057751875706654],
              [-35.173967183601107, -8.057829538229091],
              [-35.173937443789001, -8.05787752790747],
              [-35.173853523406919, -8.057889931770369],
              [-35.173769316065325, -8.057848523970033],
              [-35.173666919885136, -8.057771339380883],
              [-35.173576680592831, -8.057723985613526],
              [-35.173480794295834, -8.057742430430125],
              [-35.173379355980586, -8.05784461271837],
              [-35.173260622700639, -8.058078427169963],
              [-35.173153695358153, -8.058276302855006],
              [-35.173028835140975, -8.058486233451816],
              [-35.172891850031398, -8.058672311189456],
              [-35.172719094158957, -8.058900431638472],
              [-35.172444738993832, -8.059200839562823],
              [-35.172152514945012, -8.059525259324127],
              [-35.171872128829129, -8.059819720281416],
              [-35.17171098692117, -8.059976030188379],
              [-35.171633256467345, -8.060024274831138],
              [-35.171555302045014, -8.06003066773591],
              [-35.171465126743747, -8.059995270893667],
              [-35.171392852321681, -8.059941842177082],
              [-35.171278713195001, -8.059912551279686],
              [-35.171164478081941, -8.059865323494499],
              [-35.171055955964505, -8.059764254050615],
              [-35.170953240829398, -8.059627278835654],
              [-35.170826720846343, -8.059526305378373],
              [-35.170658050199314, -8.059395658077122],
              [-35.17046566969087, -8.059318949421266],
              [-35.170339724626828, -8.059325595290408],
              [-35.170220064477427, -8.059386019827242],
              [-35.17009482031434, -8.059524201873119],
              [-35.170005727860051, -8.059692087758615],
              [-35.169940600231094, -8.05985386869926],
              [-35.169953204055986, -8.059967404997151],
              [-35.170031732397277, -8.060068633418812],
              [-35.170206243022037, -8.060169353911677],
              [-35.170308575211173, -8.060234581575655],
              [-35.170405164409097, -8.060347672935826],
              [-35.170537587360862, -8.060430678509823],
              [-35.170712034994601, -8.060519441077536],
              [-35.17091666837748, -8.060643917442958],
              [-35.17108530902901, -8.060768584781716],
              [-35.171266010581931, -8.060905147054534],
              [-35.171398529520182, -8.061006089516285],
              [-35.171560852261493, -8.061070999224791],
              [-35.171801001443193, -8.061105600178237],
              [-35.172041150624899, -8.061140202131723],
              [-35.172293552680991, -8.061222570794722],
              [-35.172528245830932, -8.061358846107591],
              [-35.172690855531755, -8.061477565479404],
              [-35.172883715972645, -8.061643956574063],
              [-35.173076831377472, -8.06185817936939],
              [-35.173263947827081, -8.062072434160278],
              [-35.17342716544232, -8.062304751821163],
              [-35.173505981743091, -8.062459789906152],
              [-35.173548773316988, -8.062609040001849],
              [-35.173567632061058, -8.06277037500495],
              [-35.17361045563046, -8.062925603063235],
              [-35.17367749798921, -8.063122556877467],
              [-35.173756794222363, -8.063367278401218],
              [-35.173823868576548, -8.063570211178028],
              [-35.173890910935285, -8.063767164992226],
              [-35.1739760151507, -8.063975980744964],
              [-35.174018742733594, -8.064113272915579],
              [-35.174061502311979, -8.064256544048757],
              [-35.174116323791836, -8.064411709116023],
              [-35.174158955388236, -8.06453106339886],
              [-35.174189845038171, -8.064698314373464],
              [-35.174178997965534, -8.064913618017647],
              [-35.174168149892893, -8.065128922661769],
              [-35.17418713761883, -8.065314172515269],
              [-35.174224122210454, -8.065499328382147],
              [-35.174273200698991, -8.065702356145644],
              [-35.174388268694926, -8.065905032958145],
              [-35.174545744318813, -8.06618521331543],
              [-35.175484135774781, -8.065534490055247],
              [-35.176548246326583, -8.064835264187836],
              [-35.177641994704118, -8.064070109754182],
              [-35.178317221018048, -8.06357025736267],
              [-35.17877141285814, -8.063244972721375],
              [-35.179267532393844, -8.062907507186074],
              [-35.18003296300467, -8.062449026599399],
              [-35.181031003058465, -8.061738186757673],
              [-35.18121639275148, -8.061629577569137],
              [-35.181425938242846, -8.061550734211298],
              [-35.181575206222568, -8.061418400145705],
              [-35.18170660531856, -8.061310077917081],
              [-35.181904024917522, -8.061207382700022],
              [-35.182214941733328, -8.061014397128755],
              [-35.182406362377073, -8.060911733907243],
              [-35.182645612685171, -8.060778920908357],
              [-35.182980556317339, -8.060591786317501],
              [-35.183148013135714, -8.060495230040752],
              [-35.183237643513621, -8.060428982519079],
              [-35.183249224482665, -8.06035119401437],
              [-35.183200719912954, -8.060255787577381],
              [-35.183122349549073, -8.060184455968482],
              [-35.182965929776323, -8.060101583376548],
              [-35.182647540212507, -8.060019572664423],
              [-35.182275417015639, -8.059985679612936],
              [-35.181981503205193, -8.059993224357445],
              [-35.181687621390196, -8.060006747064532],
              [-35.18153158656343, -8.059995619023663],
              [-35.181441475253081, -8.059972183106565],
              [-35.181399130616072, -8.059906638486941],
              [-35.181398489706119, -8.059787061235289],
              [-35.181457228434027, -8.059553565738987],
              [-35.181533612077146, -8.059254206667589],
              [-35.181562773970406, -8.059098595662693],
              [-35.181562005078497, -8.058955103560615],
              [-35.181519436472989, -8.058847707202986],
              [-35.181416750333291, -8.058716713950476],
              [-35.181260107592038, -8.058591987620506],
              [-35.181031413397108, -8.058455686312003],
              [-35.180826715023109, -8.058319258021282],
              [-35.180597861850707, -8.058153061899873],
              [-35.180489337733221, -8.05805199545587],
              [-35.180387165521481, -8.058016664604741],
              [-35.180237257631887, -8.058029420418659],
              [-35.180063225939413, -8.058018387364498],
              [-35.179906999139661, -8.057971385548129],
              [-35.179762610272846, -8.057894425927772],
              [-35.17963018732096, -8.057811423353753],
              [-35.179533662113947, -8.057710292918664],
              [-35.179455324745547, -8.057644939272411],
              [-35.179335154668117, -8.05760970440795],
              [-35.179233206424833, -8.05761622529489],
              [-35.179137640082779, -8.057694460737308],
              [-35.179012015973562, -8.057760898232257],
              [-35.178916034689983, -8.05776140916106],
              [-35.178831730361871, -8.057702066472983],
              [-35.178777068859461, -8.057576796218735],
              [-35.178746434173377, -8.057457377944873],
              [-35.178697995594675, -8.057373928433112],
              [-35.178625561194991, -8.057290606903623],
              [-35.178588800571724, -8.057147304774972],
              [-35.178575907787369, -8.056979959813795],
              [-35.178515183338668, -8.056842763629939],
              [-35.178436494019827, -8.056711642395367],
              [-35.178411795297983, -8.056580235200776],
              [-35.178375098665697, -8.056448889997338],
              [-35.17833256305564, -8.056347472602345],
              [-35.178211819059229, -8.056204616411447],
              [-35.178121356798428, -8.056115410906047],
              [-35.178049338340244, -8.056109814890094],
              [-35.177947550074478, -8.056146229589917],
              [-35.177810311000819, -8.056284477626978],
              [-35.177595343473307, -8.056470970306817],
              [-35.177338192286214, -8.056621812180024],
              [-35.177152483638295, -8.056670630742802],
              [-35.177002415771284, -8.056653490743848],
              [-35.176888213653584, -8.056612243921261],
              [-35.176809685312215, -8.056511016499584],
              [-35.176802919465011, -8.056367555393244],
              [-35.176832211340177, -8.056235860238758],
              [-35.176903301928874, -8.056068068339906],
              [-35.17696233161611, -8.055888383506984],
              [-35.176961564724181, -8.055744889405114],
              [-35.176954639899442, -8.055571533485884],
              [-35.176977899823896, -8.055433891364345],
              [-35.177054574426322, -8.055188342956441],
              [-35.177125793997, -8.0550444669079],
              [-35.177203235491355, -8.054942411601873],
              [-35.17730432085618, -8.054774461725291],
              [-35.177333675722288, -8.054654724495935],
              [-35.177332876834825, -8.054505251431525],
              [-35.17727773739982, -8.054290298738691],
              [-35.177270779579629, -8.054110962856889],
              [-35.177317619383402, -8.053895468239647],
              [-35.177358557218433, -8.053697941505714],
              [-35.177339537496806, -8.053506712689909],
              [-35.177302777873464, -8.053363410561355],
              [-35.177236471411021, -8.053303971886693],
              [-35.177170484903542, -8.053304321837777],
              [-35.177104753360112, -8.053352503489483],
              [-35.177063209610544, -8.053436430934424],
              [-35.177027311865693, -8.053454558795501],
              [-35.176955198421055, -8.053431024891857],
              [-35.176894954904832, -8.053383512146754],
              [-35.176750824001992, -8.053354382227182],
              [-35.176696546445491, -8.053300858523968],
              [-35.176678071647331, -8.053211270071957],
              [-35.176671113827076, -8.053031935190228],
              [-35.176664795916871, -8.052972178559981],
              [-35.176593033422712, -8.053014412244707],
              [-35.176533844757991, -8.053164203264663],
              [-35.176456466254606, -8.053278216495785],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '1928',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL ALDEIA-BEBERIBE',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2010',
        GID7: '4145',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 34.692 de 17/03/2010',
        DT_ULTIM10: '19/01/2016',
        CODIGO_U11: '0000.26.1928',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.111940085056112, -7.858588146788073],
              [-35.112206937226325, -7.858352194457257],
              [-35.112208771213737, -7.858350573468758],
              [-35.112536444985309, -7.858034249685752],
              [-35.113012220613889, -7.857770743672472],
              [-35.113428029620614, -7.857607966983802],
              [-35.113762895273489, -7.857385340615991],
              [-35.114284824678265, -7.856947523731652],
              [-35.11494763206364, -7.856462133243885],
              [-35.115432780658118, -7.856146996561979],
              [-35.115848212718831, -7.855910573336589],
              [-35.11622376401688, -7.855761386534555],
              [-35.116586184371251, -7.855672523343833],
              [-35.11721698177751, -7.855501965852317],
              [-35.118089038470757, -7.855202982335491],
              [-35.119095920390684, -7.854566931033193],
              [-35.119692235175279, -7.854222469612416],
              [-35.120335106677281, -7.853784038815208],
              [-35.120803405464123, -7.853373269723495],
              [-35.121379528700189, -7.852595310017263],
              [-35.121913048269342, -7.85179589041671],
              [-35.12228022794762, -7.851188861490589],
              [-35.122693235356131, -7.850477087255395],
              [-35.123059411086402, -7.849805719733575],
              [-35.123441128922067, -7.848814789233906],
              [-35.124663546130783, -7.845438987325506],
              [-35.127957084094795, -7.840294266979485],
              [-35.130161855435979, -7.837683092935182],
              [-35.133567369570095, -7.833973118634447],
              [-35.136980509653277, -7.830254759391545],
              [-35.140077812621996, -7.827220721622922],
              [-35.142994429775449, -7.824425191228255],
              [-35.143685394253858, -7.82350230151362],
              [-35.144503925418128, -7.820208112816861],
              [-35.143743018916147, -7.816130104972061],
              [-35.142008143081711, -7.804227383742756],
              [-35.141790068324369, -7.804786906069041],
              [-35.140956932212298, -7.805269894455672],
              [-35.139886448056444, -7.805486218359148],
              [-35.137755617974513, -7.805476181961428],
              [-35.136376280355684, -7.805358255758618],
              [-35.135290554387673, -7.805467820324131],
              [-35.133768791315696, -7.806099987298768],
              [-35.132581886658286, -7.806855356726826],
              [-35.131790827189832, -7.80740056675009],
              [-35.131560829935317, -7.807360093847326],
              [-35.131214780125447, -7.805030428285415],
              [-35.131135762581472, -7.803773822147351],
              [-35.130899018295267, -7.802401146632668],
              [-35.130854831726964, -7.802253477532702],
              [-35.13064402378658, -7.801548968826614],
              [-35.129851177575631, -7.80174030707799],
              [-35.127223744557099, -7.802669440424143],
              [-35.126473197958902, -7.802964642050122],
              [-35.125356984139565, -7.797382011459471],
              [-35.125578569603135, -7.795757231849085],
              [-35.126300716379198, -7.794058434047971],
              [-35.127711917105721, -7.792219518601491],
              [-35.128977515833093, -7.790506229263715],
              [-35.129247493032679, -7.79019262942465],
              [-35.125726658622185, -7.788307330897338],
              [-35.122458155365649, -7.786366982891321],
              [-35.119978320922897, -7.783402657880501],
              [-35.11881034655454, -7.782079866420541],
              [-35.118500448560283, -7.781073368553473],
              [-35.118157376806408, -7.780077540596611],
              [-35.117331401806716, -7.780322942485443],
              [-35.117102054400576, -7.780496292236002],
              [-35.116949731380018, -7.780726659679743],
              [-35.11653324372697, -7.781838508386307],
              [-35.116476074101207, -7.78191532986297],
              [-35.116303895320861, -7.782011858136863],
              [-35.114405117430159, -7.782117000176865],
              [-35.11269961789975, -7.782508180545768],
              [-35.112393146122088, -7.782605376723671],
              [-35.111111807136155, -7.78341538674193],
              [-35.110748545677332, -7.78366593791425],
              [-35.109483035033421, -7.783806174165543],
              [-35.107949033381068, -7.783966876104401],
              [-35.107297905084771, -7.784199722191617],
              [-35.106665002783807, -7.784241135498142],
              [-35.105302130064452, -7.784094832489406],
              [-35.102884005237385, -7.783934626847555],
              [-35.102462324333302, -7.784013254063763],
              [-35.101178571695158, -7.784344899095912],
              [-35.097980625758851, -7.785547055331817],
              [-35.097203550066816, -7.786270735238206],
              [-35.097077944907831, -7.784956518438503],
              [-35.097034101550399, -7.784497775300999],
              [-35.096542538360104, -7.782841217410374],
              [-35.093726278571552, -7.781133282257122],
              [-35.091285160306768, -7.78040738916821],
              [-35.088592697244749, -7.780665882700259],
              [-35.083847099861693, -7.781888375751326],
              [-35.080583078331138, -7.783226547083768],
              [-35.078392419692257, -7.783237290520597],
              [-35.076113374509539, -7.783546330015802],
              [-35.075727288051695, -7.785502765412975],
              [-35.075903135208655, -7.786273433672543],
              [-35.077662774670856, -7.786840447298183],
              [-35.07883210801981, -7.786714696889812],
              [-35.080339160339513, -7.788781855883149],
              [-35.079751096007648, -7.791294283638183],
              [-35.078999238904458, -7.792343823506231],
              [-35.077545506029928, -7.793416635747831],
              [-35.077612388015687, -7.795620117899381],
              [-35.077425922305729, -7.795770394824374],
              [-35.077178587863656, -7.796192565993317],
              [-35.076965394086081, -7.796731795447507],
              [-35.076837494819394, -7.797055288320364],
              [-35.076482117678751, -7.79821319977644],
              [-35.076478726696486, -7.798224247704436],
              [-35.075657286249616, -7.80052916461],
              [-35.075078329458492, -7.802153675971604],
              [-35.074983061077951, -7.802288084059009],
              [-35.074887792697346, -7.802422492146467],
              [-35.07441012798877, -7.802826658271449],
              [-35.073321908366715, -7.803922657616833],
              [-35.073016064493217, -7.804153770950456],
              [-35.072423185658465, -7.804539366113645],
              [-35.071505746024194, -7.805251835993868],
              [-35.071276832549771, -7.805520840141211],
              [-35.071259154471122, -7.805827078198376],
              [-35.071283241606352, -7.806821952942621],
              [-35.071189102059321, -7.807185969583265],
              [-35.071075304724985, -7.807454409812987],
              [-35.070900091892618, -7.807660308394995],
              [-35.070846391250477, -7.807723413960356],
              [-35.070674375443232, -7.80785819799286],
              [-35.070463515991946, -7.807897499600732],
              [-35.070197791953447, -7.807932798196265],
              [-35.069715727511948, -7.807996834462537],
              [-35.069504868060733, -7.808036135070425],
              [-35.069332663281223, -7.808132651344017],
              [-35.069179833330608, -7.808267340390226],
              [-35.06891273311507, -7.80857480026901],
              [-35.06793988459188, -7.809727631334947],
              [-35.067273164900428, -7.810706753705684],
              [-35.067024683624858, -7.810899314321549],
              [-35.066852477845394, -7.81099582959514],
              [-35.064955003743364, -7.811406931703954],
              [-35.063670267235473, -7.811566284819492],
              [-35.063151977969618, -7.811768902187139],
              [-35.06308164047087, -7.811804404915135],
              [-35.063021976875355, -7.811864492495504],
              [-35.062909192673544, -7.811929497008395],
              [-35.062770859652538, -7.812009227411025],
              [-35.06262725468418, -7.812069723931265],
              [-35.062471657805411, -7.812130279442923],
              [-35.062351918675233, -7.812166741131069],
              [-35.0620277371547, -7.812084609426821],
              [-35.06051568960725, -7.811864763777415],
              [-35.060383606618743, -7.811829529909113],
              [-35.060059191132929, -7.811699561506385],
              [-35.059859776686679, -7.811607655949317],
              [-35.059494299534329, -7.811439214761043],
              [-35.059182284898299, -7.811392898839405],
              [-35.058307372368823, -7.811492834610259],
              [-35.056893598758244, -7.811750862015343],
              [-35.056702085139669, -7.8118235494259],
              [-35.056343567645271, -7.812076436585959],
              [-35.055783064396493, -7.81271299918853],
              [-35.055723574775065, -7.812808962542761],
              [-35.055523110795157, -7.813503569025943],
              [-35.055487779968537, -7.813635291171279],
              [-35.055416122462709, -7.813695435742957],
              [-35.055057777942366, -7.813984199676931],
              [-35.054221521745568, -7.814634062006823],
              [-35.053718749378426, -7.814815894516004],
              [-35.05316830132638, -7.815057754614054],
              [-35.052750872123958, -7.815526191374676],
              [-35.052428320202026, -7.81600964310559],
              [-35.052286585115112, -7.816222080669092],
              [-35.052131335184491, -7.81635438572858],
              [-35.05194022750527, -7.816510783611581],
              [-35.051736951941663, -7.816631363712058],
              [-35.05147342586951, -7.816692440146448],
              [-35.051293492222143, -7.816681355093091],
              [-35.05068249985068, -7.816592282236172],
              [-35.050261443040398, -7.816530897334734],
              [-35.05006975044801, -7.816567705971334],
              [-35.049938133390228, -7.816628141500138],
              [-35.049782765476941, -7.816736527710341],
              [-35.049675540179244, -7.816880558728744],
              [-35.049414581725429, -7.817467826847249],
              [-35.049154625215827, -7.818125196524473],
              [-35.049130280355349, -7.818186758119636],
              [-35.048999129228193, -7.81834286504553],
              [-35.04879620261265, -7.818535196693835],
              [-35.04810277749727, -7.818969093483055],
              [-35.04742646038644, -7.819210834495491],
              [-35.047085220853333, -7.819332805492639],
              [-35.046809991827487, -7.819453732541612],
              [-35.046642863969133, -7.819610012441745],
              [-35.046535520688799, -7.819730125610908],
              [-35.046369499665673, -7.82011362507918],
              [-35.0454943404742, -7.822629304618601],
              [-35.045364644130103, -7.823084384660542],
              [-35.04521177984364, -7.823707005630393],
              [-35.045160301156855, -7.823810940939853],
              [-35.045152403204924, -7.8238268848339],
              [-35.045056700887066, -7.823875186463757],
              [-35.044949005658786, -7.823923545085047],
              [-35.044805159725414, -7.823936201906651],
              [-35.044049198440263, -7.823844190968497],
              [-35.04318437610047, -7.823561358158801],
              [-35.042979756639134, -7.823548441099978],
              [-35.042656554069403, -7.823528037007073],
              [-35.042369037176698, -7.823589225424209],
              [-35.041997924859189, -7.823722573329092],
              [-35.041806693197501, -7.823855050362766],
              [-35.041592058628325, -7.824107233625631],
              [-35.041424810787419, -7.824239594676455],
              [-35.04064299833415, -7.824672081413775],
              [-35.040312517678807, -7.824854898034572],
              [-35.040216755369698, -7.824891238739799],
              [-35.040108827176262, -7.82489176066251],
              [-35.040000724008785, -7.824856405811341],
              [-35.039832486315525, -7.824785464143245],
              [-35.038422164491145, -7.823869017954696],
              [-35.038352756041164, -7.823823915191507],
              [-35.038148484625367, -7.823741187573051],
              [-35.03775239863478, -7.823671345741975],
              [-35.037296585057597, -7.823649628566446],
              [-35.036829302491938, -7.82373559970412],
              [-35.03648164907348, -7.823761196304437],
              [-35.034850612279087, -7.823745147287776],
              [-35.034406964586346, -7.823759246894799],
              [-35.033843800729144, -7.823857636888426],
              [-35.033376979094207, -7.824039276423132],
              [-35.032405261056276, -7.824479308982477],
              [-35.032333775548267, -7.824541013544407],
              [-35.03185594485555, -7.82492601378928],
              [-35.031842808949889, -7.824931702744381],
              [-35.031587876779902, -7.82504211487345],
              [-35.031127657179837, -7.825101735182053],
              [-35.030858941201288, -7.825083894110384],
              [-35.030628136005397, -7.824970195669134],
              [-35.030339213322975, -7.824741967910222],
              [-35.02985851006067, -7.824533796893452],
              [-35.029840570199468, -7.82452701492391],
              [-35.029358711927948, -7.824344851742396],
              [-35.028917402226199, -7.824346974426569],
              [-35.02853284505499, -7.82441462173642],
              [-35.02824639816204, -7.824465011222375],
              [-35.028015870924584, -7.824408714419382],
              [-35.027862372071795, -7.824409452309517],
              [-35.026861021070118, -7.823668000299012],
              [-35.02676612485601, -7.823557245932385],
              [-35.026558271513046, -7.823407925731622],
              [-35.026391808858541, -7.823261697539718],
              [-35.025511714038224, -7.8223924364186],
              [-35.025222888341936, -7.822183337539395],
              [-35.024184925363457, -7.821805618210455],
              [-35.023377580608638, -7.821503329563889],
              [-35.023031378314855, -7.821332774402402],
              [-35.022837936984736, -7.821008406315595],
              [-35.022097850084478, -7.818734884147979],
              [-35.021420769935652, -7.817590023905415],
              [-35.02120897362407, -7.817437957719522],
              [-35.021014706219816, -7.817230088897664],
              [-35.020977530525208, -7.817190310123161],
              [-35.018922386189338, -7.816760035430357],
              [-35.017559728427408, -7.816690007939395],
              [-35.013971629261185, -7.816688001496283],
              [-35.013588251074339, -7.816766370739573],
              [-35.013301085128759, -7.8169016856894],
              [-35.012919357692475, -7.817324478761092],
              [-35.012747497858619, -7.817497514551947],
              [-35.012498429667772, -7.817575241891164],
              [-35.01223026660648, -7.81767219609602],
              [-35.010402808414746, -7.817466611142168],
              [-35.009619327334839, -7.81737846916197],
              [-35.009138637073093, -7.817170269146472],
              [-35.008348571466392, -7.816466026048642],
              [-35.007816373597947, -7.816246086072017],
              [-35.007335404219909, -7.816210012970508],
              [-35.006862336851775, -7.817526953338541],
              [-35.005551914524055, -7.819166589097811],
              [-35.002924011936187, -7.820971832910456],
              [-35.000273050671623, -7.822558704084617],
              [-34.999264552509644, -7.822130502095558],
              [-34.998453862801114, -7.821799765627186],
              [-34.997585378321475, -7.821764516255048],
              [-34.996717083813373, -7.821768625634713],
              [-34.995348897688736, -7.822289689410742],
              [-34.994818509302149, -7.822803903803424],
              [-34.994385950306004, -7.823140525383539],
              [-34.993814314485213, -7.823280995105974],
              [-34.993462186670982, -7.823932132756878],
              [-34.993209288033412, -7.824700888733618],
              [-34.993211344719917, -7.825133862003448],
              [-34.993685997021849, -7.825348108976842],
              [-34.994790831801708, -7.825283843138831],
              [-34.995500891546854, -7.82520176014293],
              [-34.995757807571714, -7.825279268829883],
              [-34.995483123395118, -7.825615146522867],
              [-34.994281486067884, -7.826073491808382],
              [-34.993888576748141, -7.826449289168487],
              [-34.993775295075146, -7.827204863324318],
              [-34.993168581879473, -7.828270508186054],
              [-34.992522211007476, -7.829296976267845],
              [-34.991020408623044, -7.830194929574295],
              [-34.990766383156362, -7.830727518040427],
              [-34.991714481945024, -7.830900168600956],
              [-34.992820457553364, -7.831072070274464],
              [-34.993471977641136, -7.831128032367893],
              [-34.994263041482128, -7.83147854869895],
              [-34.995389221871747, -7.831748752766345],
              [-34.995666629634826, -7.83198361147501],
              [-34.995341150480932, -7.832837469866032],
              [-34.995679449544447, -7.833426297383836],
              [-34.996550866581181, -7.834071643910728],
              [-34.997359796560069, -7.834028446737775],
              [-34.997852599956886, -7.833908024835184],
              [-34.998149650586647, -7.834123107682241],
              [-34.998429127035635, -7.834790934661722],
              [-34.998016859805951, -7.835245551511717],
              [-34.997663698147896, -7.835680208528101],
              [-34.996508767475724, -7.839030995602705],
              [-34.996109548800838, -7.839989241285451],
              [-34.995606266355566, -7.840293633020398],
              [-34.994600416355489, -7.841053414538538],
              [-34.994302140925761, -7.842011181293625],
              [-34.995172408646646, -7.844574108728151],
              [-34.990591928610662, -7.846458667699053],
              [-34.986707126914062, -7.847558133098218],
              [-34.984094078235216, -7.847888438221045],
              [-34.981536251564812, -7.849057379092622],
              [-34.979805275082796, -7.849684253950522],
              [-34.978596947954557, -7.849928878577984],
              [-34.977061734201143, -7.850276469331598],
              [-34.976352394220065, -7.850694215209804],
              [-34.975589214255685, -7.851671180524019],
              [-34.974954312252351, -7.852762627204342],
              [-34.974687759691804, -7.853566033954362],
              [-34.974121898429232, -7.854284872032091],
              [-34.973185975238387, -7.854565541619076],
              [-34.97220859020274, -7.855070786761189],
              [-34.970091170507445, -7.855851595382088],
              [-34.96951400458979, -7.85619394882636],
              [-34.968917265687061, -7.856726638056795],
              [-34.967093494167699, -7.856968048281173],
              [-34.965655447850949, -7.857080949581094],
              [-34.963292306686569, -7.85686196133883],
              [-34.961851430807108, -7.856373225431691],
              [-34.960037016807632, -7.85576235903854],
              [-34.956825534556408, -7.854728107357099],
              [-34.954888550217873, -7.854489386532965],
              [-34.953520628512464, -7.854407271111763],
              [-34.952633131078663, -7.854517566806607],
              [-34.951922983354777, -7.854573952963288],
              [-34.951177646845302, -7.854701280648302],
              [-34.950420089428519, -7.854826974335228],
              [-34.9492587589408, -7.855087435894714],
              [-34.947985301365499, -7.85524218204413],
              [-34.947370518916699, -7.855315073162173],
              [-34.945393298107788, -7.85474777638386],
              [-34.943824545230889, -7.854196161787307],
              [-34.940145747514364, -7.85311468008621],
              [-34.938479524487029, -7.852390047515223],
              [-34.936083683599641, -7.850682664645603],
              [-34.934939074735993, -7.849859254056765],
              [-34.934222606256647, -7.849631290003977],
              [-34.933486179019745, -7.849268514788326],
              [-34.93290425465328, -7.848866484926504],
              [-34.932299275420846, -7.848520492695192],
              [-34.931390080290754, -7.84842831065354],
              [-34.929883544325023, -7.848782116387628],
              [-34.927991010141739, -7.849291860872777],
              [-34.926368187086617, -7.849588372888609],
              [-34.926059709262205, -7.849782502451842],
              [-34.925964400283362, -7.850786437050194],
              [-34.92548270463557, -7.851154806394959],
              [-34.925116042273167, -7.85131065915981],
              [-34.92513990441666, -7.852293406973762],
              [-34.925460772028174, -7.855172085026489],
              [-34.92501845397927, -7.855694446426447],
              [-34.924034738982023, -7.856219288439196],
              [-34.921497371289433, -7.857219063388498],
              [-34.918736360354664, -7.858098911941016],
              [-34.91734640142505, -7.858587053906403],
              [-34.916981497783709, -7.859128329239937],
              [-34.916949635997682, -7.86061240485209],
              [-34.916044905526896, -7.862415622851247],
              [-34.915408589023301, -7.862803962963631],
              [-34.915081027301952, -7.863055991148252],
              [-34.915399360530358, -7.863617753821734],
              [-34.91588011114235, -7.864758908950643],
              [-34.916280464732296, -7.865355703459646],
              [-34.916501436066255, -7.865372301506691],
              [-34.9169432587533, -7.865379087767377],
              [-34.917146759187645, -7.865439780524142],
              [-34.917759531447302, -7.865659862556299],
              [-34.917893039302108, -7.865870531318668],
              [-34.918256999322566, -7.866238605246165],
              [-34.918478093637404, -7.866281610126702],
              [-34.918760711540422, -7.866253907495665],
              [-34.918920037305384, -7.866314802220871],
              [-34.919009080535531, -7.866464050340303],
              [-34.919028889214047, -7.866714611772904],
              [-34.918959258577047, -7.866943813278837],
              [-34.918942275600713, -7.867093547322346],
              [-34.919085265287194, -7.867445023202879],
              [-34.919334972071489, -7.867945667215495],
              [-34.919478204719596, -7.868349961762951],
              [-34.919682479032502, -7.868577912465041],
              [-34.919984433541309, -7.868911054570605],
              [-34.920383699303578, -7.869270160579241],
              [-34.920729426545634, -7.869515068271642],
              [-34.921343831338973, -7.870392117160412],
              [-34.921672411498939, -7.870941432920591],
              [-34.92188817141534, -7.871618377798017],
              [-34.922258107441536, -7.87191327819186],
              [-34.922912080263835, -7.872319865076436],
              [-34.923415259475171, -7.872350565228714],
              [-34.924621340527956, -7.872230538815281],
              [-34.925684965468854, -7.872368771674581],
              [-34.926951808572213, -7.872964035790568],
              [-34.927487277046879, -7.873710378450712],
              [-34.928763981167229, -7.875619639285278],
              [-34.92962491741411, -7.87606389907529],
              [-34.93159483565681, -7.877521733235457],
              [-34.932911901559386, -7.879308544872254],
              [-34.933325515630067, -7.880511125572367],
              [-34.934533294192384, -7.882526097696353],
              [-34.93639324068107, -7.883373317634526],
              [-34.938522539095473, -7.884304327230338],
              [-34.939930715477203, -7.884414773504035],
              [-34.941925403470378, -7.884522495199079],
              [-34.942924385711599, -7.884927248335213],
              [-34.943559601148273, -7.886093308420614],
              [-34.945037815738608, -7.888032045215704],
              [-34.945392389920016, -7.889710983874949],
              [-34.945492774898646, -7.891541670402689],
              [-34.945615688771959, -7.893280694524314],
              [-34.977501072728828, -7.899618091597365],
              [-34.989170058845517, -7.894683234749871],
              [-34.990771412816486, -7.896191310355253],
              [-34.993161862714082, -7.897943228970719],
              [-34.994206893202254, -7.898940540410663],
              [-34.995667781809217, -7.899606977222543],
              [-34.996575329089268, -7.899506639481949],
              [-34.996702544889544, -7.899863221323828],
              [-34.998035180589341, -7.900338323252639],
              [-34.998834628769821, -7.900106414266046],
              [-34.999801906976053, -7.899470099942618],
              [-35.00051337364583, -7.899481168364818],
              [-35.001054127766466, -7.899242485242006],
              [-35.001373453320127, -7.899325120942298],
              [-35.001409186691404, -7.899844762694078],
              [-35.001568409857597, -7.900768725984741],
              [-35.002057210847219, -7.901277248119968],
              [-35.002628806431396, -7.901478185249705],
              [-35.003088569125801, -7.901285865778909],
              [-35.003831457707065, -7.90108653754804],
              [-35.004571495073527, -7.901226773176607],
              [-35.005110819166312, -7.901043832701752],
              [-35.005611889347847, -7.901144667413247],
              [-35.006057885901136, -7.901303397722038],
              [-35.006504918415118, -7.901507412746376],
              [-35.007113179779417, -7.901630509391817],
              [-35.007577503364388, -7.901546664240999],
              [-35.00774774921824, -7.901363670511188],
              [-35.008110110513769, -7.901354786817747],
              [-35.008864103705477, -7.901596558816709],
              [-35.00920637708014, -7.901689970465155],
              [-35.009791756751731, -7.901617633325603],
              [-35.010418365331752, -7.901233689176886],
              [-35.010964554304792, -7.901149447085175],
              [-35.011402755933752, -7.901283425544563],
              [-35.012032919976058, -7.901634180771692],
              [-35.01327403054416, -7.901847443287269],
              [-35.014094262335881, -7.901952437193521],
              [-35.015079707778966, -7.902219668192535],
              [-35.016529263789948, -7.902430567867452],
              [-35.017787302270975, -7.902587721748374],
              [-35.018745627860106, -7.902936879213],
              [-35.019950563510939, -7.90342092999903],
              [-35.020744813326864, -7.903770875345375],
              [-35.020070143157888, -7.905515935891329],
              [-35.019536519980882, -7.90719332896118],
              [-35.019241174430704, -7.908283259894663],
              [-35.01907955633569, -7.908719443036669],
              [-35.018507167368483, -7.909075998395559],
              [-35.017907469624411, -7.909405544905552],
              [-35.017745638512054, -7.909868869876505],
              [-35.01766681366523, -7.910495212878738],
              [-35.017808670846016, -7.911583223127405],
              [-35.017727855003677, -7.912224397035012],
              [-35.017322189410621, -7.913124495087564],
              [-35.016642204039208, -7.913780884484333],
              [-35.01568940561269, -7.914574678827023],
              [-35.015446627972565, -7.91522895553998],
              [-35.015425796935979, -7.915504886788534],
              [-35.014165759316093, -7.915569413509121],
              [-35.008219310344039, -7.91621997629287],
              [-35.004154299556525, -7.91650263769656],
              [-35.002825574412888, -7.916623028017971],
              [-35.001924159099545, -7.916704699879147],
              [-34.99784839621141, -7.917250462619005],
              [-34.995115254400652, -7.917622333383892],
              [-34.993676653042556, -7.917796647289575],
              [-34.990055399941632, -7.91807705501516],
              [-34.987468385139394, -7.918305540784175],
              [-34.986618095449352, -7.918380634722264],
              [-34.985738301063037, -7.91833682738867],
              [-34.980705176225136, -7.917619295421231],
              [-34.979568442844382, -7.917457230654615],
              [-34.972774156399787, -7.916436974375995],
              [-34.970397744376598, -7.916161174468047],
              [-34.968045435156846, -7.915909166427119],
              [-34.967030004777314, -7.9158793489121],
              [-34.966724219072155, -7.915870369757037],
              [-34.965670518979891, -7.91583942522264],
              [-34.965238101225133, -7.915826725003338],
              [-34.964445849235318, -7.915710859184911],
              [-34.961824664761096, -7.91440762058097],
              [-34.961724859539217, -7.914362954793292],
              [-34.961560203822877, -7.914289266143619],
              [-34.961295743884655, -7.914170912706263],
              [-34.960935887576937, -7.914172603446585],
              [-34.960696436301788, -7.914269407671035],
              [-34.960433107188578, -7.914390242727578],
              [-34.960241635554517, -7.914486820986644],
              [-34.960098710473943, -7.91470276852727],
              [-34.95953141746925, -7.916499401816041],
              [-34.959104672912503, -7.917577787727082],
              [-34.958746283377316, -7.917890425509476],
              [-34.958292042542666, -7.918227433072007],
              [-34.956233245296623, -7.919169961709099],
              [-34.9556580305171, -7.919292258540464],
              [-34.955370141671125, -7.919293608332658],
              [-34.953900834787291, -7.919124362381795],
              [-34.952650746245091, -7.918980362423718],
              [-34.952177560825128, -7.91892585443959],
              [-34.950881718571104, -7.918860159956559],
              [-34.949610204085211, -7.918866108039047],
              [-34.948339813424504, -7.919111242615313],
              [-34.945756657587815, -7.920797689201925],
              [-34.944514512083209, -7.9219516340717],
              [-34.943702630592142, -7.922768694361774],
              [-34.943176956209271, -7.92322562111904],
              [-34.942578410505035, -7.923491530029274],
              [-34.941380755184205, -7.923903747602853],
              [-34.939226916502626, -7.925061929814376],
              [-34.938939243622151, -7.925111108305353],
              [-34.93838721878663, -7.925065837208295],
              [-34.938128334742593, -7.925040353189603],
              [-34.937691133045917, -7.924997315158028],
              [-34.937234849527272, -7.924903757431554],
              [-34.936247736431199, -7.924166829391313],
              [-34.935838653675994, -7.923905611754019],
              [-34.933820700178124, -7.923340902915278],
              [-34.933670252316084, -7.923324396915192],
              [-34.932356447253397, -7.923180255914021],
              [-34.931660698460533, -7.923183481412109],
              [-34.930029842583103, -7.923310637482026],
              [-34.928951677436039, -7.923626588744818],
              [-34.92828169918397, -7.924012409849782],
              [-34.927852859943869, -7.924660233470664],
              [-34.92737781678305, -7.925690991646508],
              [-34.925823147702886, -7.926726742043447],
              [-34.925035760946507, -7.927663263596878],
              [-34.923128063506077, -7.928704916711993],
              [-34.922738332276246, -7.928917719101151],
              [-34.922642475977113, -7.928942080881233],
              [-34.922359564392096, -7.928516583366564],
              [-34.922135093308214, -7.928178981338555],
              [-34.921991143385725, -7.928179645234724],
              [-34.919691036136157, -7.928860016354581],
              [-34.919355704562939, -7.928981162358971],
              [-34.912176808485967, -7.933056702704155],
              [-34.911005941534462, -7.934090649377383],
              [-34.910241170809641, -7.934740006755492],
              [-34.90978653202982, -7.935005215769301],
              [-34.90935500121045, -7.935078957005621],
              [-34.907987766396211, -7.935156988566638],
              [-34.906539710224273, -7.93518237540352],
              [-34.906140462209699, -7.93518937508281],
              [-34.905779585061524, -7.934975745179113],
              [-34.905514676194898, -7.934761675344681],
              [-34.905225225595352, -7.934428116246334],
              [-34.905030862417284, -7.933902759422652],
              [-34.904784441245582, -7.932492589139425],
              [-34.904613412992674, -7.931823603237296],
              [-34.90432286456786, -7.931250844646022],
              [-34.903358005574752, -7.930131002036158],
              [-34.902707137914241, -7.929464206788434],
              [-34.901457678552113, -7.929063268450811],
              [-34.900135915781455, -7.928590893513489],
              [-34.899859104949833, -7.928454480181795],
              [-34.899462600055855, -7.928259080139107],
              [-34.899078506964955, -7.928212991163808],
              [-34.898838805726371, -7.928261924689479],
              [-34.898473090238006, -7.928589022374564],
              [-34.901319883754496, -7.931690369796603],
              [-34.901834117237804, -7.932644835136848],
              [-34.902227016837863, -7.933300849274318],
              [-34.902379306343484, -7.933808460180495],
              [-34.90293412991798, -7.937065068040774],
              [-34.904107247067166, -7.944325494102212],
              [-34.904472931452872, -7.945579634453614],
              [-34.904836411188874, -7.946355377818019],
              [-34.905107850927067, -7.946683036941834],
              [-34.906162516279338, -7.947754612922105],
              [-34.906794959147625, -7.948319816800006],
              [-34.907700504491011, -7.949571473542835],
              [-34.908757397492352, -7.951121433511912],
              [-34.91092943714191, -7.95410147024752],
              [-34.911162345010418, -7.954655346920408],
              [-34.911376500952407, -7.955304989977053],
              [-34.911533252297353, -7.955993169751192],
              [-34.911949383215315, -7.958804266334282],
              [-34.912105870602105, -7.959435038470175],
              [-34.912396901954914, -7.96010346146253],
              [-34.912629106935441, -7.960504250099692],
              [-34.913131462679253, -7.961209971004187],
              [-34.914770508284008, -7.962809841068015],
              [-34.917201199265712, -7.965401119440134],
              [-34.918514849172183, -7.967193833065454],
              [-34.919809227236136, -7.968967492798132],
              [-34.920157241255637, -7.969501689676846],
              [-34.920370353365364, -7.969921695180698],
              [-34.921694575609955, -7.973972394599845],
              [-34.921926887575772, -7.974392309118027],
              [-34.922370799890579, -7.974906921187469],
              [-34.922968009097957, -7.97536341272965],
              [-34.925020387123915, -7.976224387739405],
              [-34.925290197067163, -7.976275933602841],
              [-34.925602685644158, -7.976394080076845],
              [-34.92596383475621, -7.97665551968274],
              [-34.926204563836812, -7.976821839803495],
              [-34.926663372966217, -7.977441624221778],
              [-34.928324491709823, -7.978605967050346],
              [-34.930611165519345, -7.980126182075632],
              [-34.931310605746809, -7.980888354766138],
              [-34.931528692795474, -7.981341815064236],
              [-34.931627031706945, -7.981843673974209],
              [-34.931605958428236, -7.982465689044761],
              [-34.931395511180916, -7.983638746515367],
              [-34.931278346638258, -7.984237290666722],
              [-34.931279920391376, -7.984572162560322],
              [-34.931474689513678, -7.985169249938549],
              [-34.9316686727594, -7.985598902369974],
              [-34.933044037322354, -7.987219027134043],
              [-34.935167657629108, -7.989768515573182],
              [-34.936086351727553, -7.991223323060539],
              [-34.936522552823845, -7.992130232658802],
              [-34.936716094139882, -7.992464202692664],
              [-34.938406154943131, -7.994656898077333],
              [-34.938817805307046, -7.995444319410931],
              [-34.939010347408441, -7.996093971458341],
              [-34.939037268143025, -7.996184801905743],
              [-34.939087179483529, -7.99659120438386],
              [-34.939089892059556, -7.997165265774319],
              [-34.938949761536435, -7.997979196555969],
              [-34.93866735282586, -7.999152590976943],
              [-34.938454894894477, -7.999895102157492],
              [-34.938012991115251, -8.002863230177407],
              [-34.937457970082974, -8.007392894297189],
              [-34.937880349911069, -8.007405568512965],
              [-34.938972126278095, -8.008054946192431],
              [-34.939302525545791, -8.008421553117774],
              [-34.939142165155445, -8.009260895726531],
              [-34.939391951795969, -8.009955135535041],
              [-34.940158549675964, -8.010493847683412],
              [-34.940427039450171, -8.010799384949769],
              [-34.94012541074234, -8.012171179111402],
              [-34.940190618721019, -8.012927646399671],
              [-34.941262553135438, -8.013720280165511],
              [-34.942441984825948, -8.014397985729598],
              [-34.943592305216008, -8.014392562569265],
              [-34.944598444743008, -8.0143060028182],
              [-34.945381154567521, -8.014752281560048],
              [-34.946021439256143, -8.015485577397445],
              [-34.946760831736562, -8.015461631065939],
              [-34.947161446205676, -8.014796654527903],
              [-34.947402372880511, -8.014117381967919],
              [-34.947862904740369, -8.013680177039634],
              [-34.948850472628685, -8.013278865254739],
              [-34.949387310034723, -8.012674963428021],
              [-34.949911815835264, -8.011642325288657],
              [-34.95108572527586, -8.009896658087762],
              [-34.952073212519373, -8.008992319465763],
              [-34.953429672688188, -8.007111712241196],
              [-34.953866077494339, -8.007007286203294],
              [-34.954508445705088, -8.006978652832926],
              [-34.9552137186434, -8.006668233278562],
              [-34.956147783239096, -8.00583213518992],
              [-34.956979563802534, -8.004709291832903],
              [-34.957643288493387, -8.003772116190758],
              [-34.958444120242746, -8.002714218403657],
              [-34.95922538392999, -8.001952661739347],
              [-34.959586326559361, -8.001481729953346],
              [-34.959391505851073, -8.000298087260767],
              [-34.95923369542966, -7.999733833698929],
              [-34.958881507645117, -7.998911412624854],
              [-34.959050490561289, -7.998653805363112],
              [-34.959279039185418, -7.998177602517836],
              [-34.959739096974666, -7.997845901925579],
              [-34.960008017016236, -7.997767992603619],
              [-34.960430995878085, -7.997727669152947],
              [-34.960900394354788, -7.997740767398714],
              [-34.961926306695126, -7.997711606299426],
              [-34.962617323734712, -7.997409458682784],
              [-34.962770193637134, -7.99734284720857],
              [-34.962955156309228, -7.997262251844716],
              [-34.963393412073053, -7.997198863549783],
              [-34.963493982239591, -7.99731333290024],
              [-34.963890896842848, -7.997917948375443],
              [-34.964469550275695, -7.99825237667695],
              [-34.964847465313042, -7.99844215774776],
              [-34.965559837636036, -7.99893128717001],
              [-34.966360880512809, -7.999111389597661],
              [-34.96663093637887, -7.999271029782641],
              [-34.967074329638969, -7.999868693334497],
              [-34.967406929891375, -8.000234940264081],
              [-34.967853542495739, -8.000309443108014],
              [-34.968414497400993, -8.000161169432809],
              [-34.969015014162096, -7.999797011142688],
              [-34.969767024826766, -7.999379613293338],
              [-34.97032753480017, -7.999139381196006],
              [-34.970650822359772, -7.999168491239101],
              [-34.970913040306101, -7.999297512611242],
              [-34.971329685016109, -7.999540744373246],
              [-34.971737826908431, -7.999615427189132],
              [-34.97190533865647, -7.999612709323438],
              [-34.971784236631009, -7.999516096846172],
              [-34.97166311070567, -7.999277481261854],
              [-34.97166173191804, -7.998990457065657],
              [-34.971779868303585, -7.998607182558341],
              [-34.972687315153919, -7.99769391493564],
              [-34.972734269954159, -7.997478417323561],
              [-34.972709239292946, -7.997263262658939],
              [-34.972585239809888, -7.996426678832837],
              [-34.972578457853785, -7.995015471702196],
              [-34.972721048988888, -7.994727759611114],
              [-34.974205397371463, -7.994027009055421],
              [-34.974372901185546, -7.993930531779213],
              [-34.974467960608962, -7.993738723051849],
              [-34.974539024211971, -7.993547029306966],
              [-34.974536838548033, -7.993092572163258],
              [-34.974487353136993, -7.992781858082595],
              [-34.974101137363029, -7.992305317809413],
              [-34.971980178610956, -7.990354060598404],
              [-34.971665147422044, -7.98970974143048],
              [-34.971543451584829, -7.989351531598333],
              [-34.971637249202601, -7.988896617524919],
              [-34.972827100746507, -7.986833878329362],
              [-34.973114000751366, -7.986617236892315],
              [-34.976907489788722, -7.984581360346088],
              [-34.97799573614563, -7.983867119598121],
              [-34.978163004995146, -7.9837228036226],
              [-34.978258292383096, -7.983578832594635],
              [-34.978682934265159, -7.98206988938156],
              [-34.97955941653565, -7.982311274475308],
              [-34.981155334116572, -7.982986992339762],
              [-34.982170575295754, -7.983303695056883],
              [-34.984853767626468, -7.984140695666877],
              [-34.985193474016832, -7.985659009356046],
              [-34.985810037493636, -7.988373830715108],
              [-34.987113689525081, -7.990097242789481],
              [-34.987915470497406, -7.991557460168631],
              [-34.988509596068837, -7.992953405807134],
              [-34.989693748673524, -7.995132057937917],
              [-34.990300726560065, -7.995943120263469],
              [-34.99146177720894, -7.998303768235511],
              [-34.991694230744102, -7.997913659850528],
              [-34.992595248271975, -7.997529399896591],
              [-34.993156362427214, -7.997026292451912],
              [-34.993694771680659, -7.99662496235157],
              [-34.994522057633063, -7.996421628208592],
              [-34.995768911991114, -7.995443717228405],
              [-34.996165866657606, -7.994539076192983],
              [-34.996477733777674, -7.993894528463066],
              [-34.997061471711916, -7.993464934572011],
              [-34.997707769611935, -7.99241899559938],
              [-34.998557832624996, -7.991887690533906],
              [-34.999560592989987, -7.9920745720604],
              [-35.000223331752153, -7.992470582034457],
              [-35.000611525041151, -7.992195397035872],
              [-35.001176002380774, -7.991394910462811],
              [-35.002574096515033, -7.98912778369264],
              [-35.003357724862759, -7.98740637006243],
              [-35.003699464565891, -7.985614960563573],
              [-35.004621585319683, -7.984686094047723],
              [-35.005360123069821, -7.98429369503084],
              [-35.005549945126212, -7.983615849425107],
              [-35.005661484955489, -7.982671781438466],
              [-35.005784200673226, -7.981767362210476],
              [-35.005436426894683, -7.979463579452449],
              [-35.005840753175114, -7.976182462365699],
              [-35.006162787533782, -7.975089087465668],
              [-35.006908469488586, -7.974329064765313],
              [-35.007477850663697, -7.973709603057935],
              [-35.007558082214082, -7.973495049463121],
              [-35.007708427601166, -7.972766108151759],
              [-35.008027449487372, -7.972377353818977],
              [-35.008504178018342, -7.972238233026471],
              [-35.009212525172273, -7.971592447581669],
              [-35.010146329630267, -7.970959750211801],
              [-35.011183731057592, -7.970691300623712],
              [-35.011406083636693, -7.970345739951824],
              [-35.011656572154216, -7.969788162632785],
              [-35.012184907374085, -7.969542436546601],
              [-35.012633966873871, -7.969742890599352],
              [-35.013123457113949, -7.969882359063883],
              [-35.013796236983517, -7.970020928661931],
              [-35.014243513752746, -7.969856653006973],
              [-35.014730227412059, -7.96942875603723],
              [-35.015336462561763, -7.968453177594745],
              [-35.015394775523959, -7.967885525204895],
              [-35.015386599862467, -7.966065646643267],
              [-35.015212377786106, -7.963756499043168],
              [-35.015492062769475, -7.962223176881025],
              [-35.016058040148572, -7.961349108772592],
              [-35.016251026862172, -7.961124907317101],
              [-35.016706098828685, -7.960596225959176],
              [-35.017359303647829, -7.959590175741592],
              [-35.017641662934388, -7.959021433515286],
              [-35.018007654283068, -7.958898069546198],
              [-35.018272706258621, -7.95895757635659],
              [-35.01859973864287, -7.959199137065315],
              [-35.01906805915543, -7.959176595533429],
              [-35.019880400408383, -7.958706594055371],
              [-35.02154633553495, -7.957867691492141],
              [-35.02320065275385, -7.95702138996722],
              [-35.023809139559589, -7.956511843595709],
              [-35.024419614066368, -7.956407553676904],
              [-35.024807355053802, -7.956567770939481],
              [-35.02578699943124, -7.95698851097612],
              [-35.026133418812869, -7.957027344973238],
              [-35.026704044489833, -7.957105607878725],
              [-35.027235340261996, -7.957467753971521],
              [-35.027827344637046, -7.957748543618345],
              [-35.028640882711237, -7.957521672612],
              [-35.029109379194502, -7.957539644825446],
              [-35.029435635698512, -7.957619098553182],
              [-35.029884385244166, -7.957758748987744],
              [-35.030250450578521, -7.957655637891287],
              [-35.030738529029129, -7.957511412138357],
              [-35.030955420634704, -7.957186618331936],
              [-35.031379571882383, -7.956587579394443],
              [-35.031601847989073, -7.956916799479113],
              [-35.031692607727123, -7.957748265313959],
              [-35.031834735421313, -7.958094520235716],
              [-35.031991125019516, -7.958425394264185],
              [-35.031898634325657, -7.958975207742461],
              [-35.031961808451697, -7.95954749218801],
              [-35.032233602257875, -7.959776981934462],
              [-35.032734446770029, -7.959409062597088],
              [-35.033042061713282, -7.959061324998067],
              [-35.033851938955678, -7.958634185248399],
              [-35.034678558191544, -7.958037959573605],
              [-35.034722183230748, -7.957516858880607],
              [-35.035212568897919, -7.957039527223859],
              [-35.035566112340994, -7.956915231251821],
              [-35.035736169972992, -7.957052279508588],
              [-35.035985332712841, -7.957617904125703],
              [-35.03634023695227, -7.957769365420752],
              [-35.036984523479838, -7.957275956972251],
              [-35.037412332754592, -7.956599767522174],
              [-35.037904001229649, -7.956382871228634],
              [-35.038410458680104, -7.956043339716538],
              [-35.039037471398061, -7.955462737804261],
              [-35.039694460815554, -7.954990947228624],
              [-35.040345061022457, -7.954887706331146],
              [-35.041515362252369, -7.95491341898507],
              [-35.04236909389293, -7.954877752804279],
              [-35.04362426938826, -7.955046212619854],
              [-35.044072947905875, -7.95522745079307],
              [-35.045049713292407, -7.955668155703012],
              [-35.046157658771982, -7.956003383367642],
              [-35.046703122705779, -7.955985238862],
              [-35.048076022391676, -7.956053256391416],
              [-35.048990371507443, -7.956120170608158],
              [-35.050715621125036, -7.956806545495868],
              [-35.053524156457918, -7.957756246484063],
              [-35.0557929187582, -7.958804275478377],
              [-35.057536158618746, -7.958953985753227],
              [-35.058375243781079, -7.959757170290028],
              [-35.059838433002618, -7.960951270805457],
              [-35.060335836616602, -7.961901168182239],
              [-35.060430732458897, -7.962539375237315],
              [-35.0608876456158, -7.963147134736464],
              [-35.061354175460828, -7.964096479095634],
              [-35.061594881681742, -7.965490550502452],
              [-35.061812859198191, -7.966705652018396],
              [-35.062399117544565, -7.967090310010489],
              [-35.062864492675153, -7.967645130848893],
              [-35.063670743497788, -7.967857571076966],
              [-35.064206006548737, -7.967782234924225],
              [-35.064764277615076, -7.967440327462711],
              [-35.065176956701343, -7.967196038286041],
              [-35.065736805534655, -7.967169024846778],
              [-35.066550325444716, -7.967180000345992],
              [-35.067172990961851, -7.966934662322551],
              [-35.067963708339938, -7.96652245446502],
              [-35.068974264747759, -7.966575169839579],
              [-35.070019786028809, -7.965013409383513],
              [-35.070341081363601, -7.963964830197022],
              [-35.071497525381595, -7.963020380939324],
              [-35.072287686044596, -7.962208845590692],
              [-35.073715166468851, -7.96207258244345],
              [-35.074864161013004, -7.961882369440875],
              [-35.075434571385856, -7.960975630536904],
              [-35.075929454228806, -7.960203370735217],
              [-35.075333647131515, -7.959564356334957],
              [-35.074947214497215, -7.958885230332984],
              [-35.074828538748719, -7.958366371510841],
              [-35.074488189232966, -7.955603032639635],
              [-35.074395466064303, -7.951130726683441],
              [-35.074566209633566, -7.949925169379888],
              [-35.075430295113172, -7.947160316360848],
              [-35.077485392784396, -7.94283304599348],
              [-35.079426445085609, -7.937394192535786],
              [-35.079870491505339, -7.934910989456257],
              [-35.079820968881045, -7.932045876435226],
              [-35.080094199027677, -7.930346896307481],
              [-35.081164545573422, -7.926707432935673],
              [-35.082005648160084, -7.925458610372827],
              [-35.083156572661395, -7.9238804050966],
              [-35.083890142718616, -7.923107983463453],
              [-35.085467368351303, -7.922506056344438],
              [-35.086727138551012, -7.921626167752637],
              [-35.087599221842403, -7.920468672637028],
              [-35.088838539625073, -7.918973571899483],
              [-35.089164726809834, -7.918082733727888],
              [-35.089537229012535, -7.916670657866358],
              [-35.090214657156366, -7.914943542197828],
              [-35.091405431963487, -7.912500581387724],
              [-35.09204230255326, -7.91200044597507],
              [-35.092609700456677, -7.911794712662709],
              [-35.093399128757483, -7.911507947014085],
              [-35.093763006129656, -7.911377013090041],
              [-35.094356072708322, -7.911361739597695],
              [-35.094559640251404, -7.911269618318355],
              [-35.094258480330708, -7.910082626574158],
              [-35.093171428204002, -7.907575715586298],
              [-35.092088998801771, -7.905413366435791],
              [-35.090541993563399, -7.903692051190043],
              [-35.089105693062727, -7.901160139121007],
              [-35.087489859728933, -7.900312940330688],
              [-35.087218575127984, -7.899779749496926],
              [-35.087028816787694, -7.899433241545322],
              [-35.08386633284907, -7.898821214204586],
              [-35.082112409195133, -7.898479979136421],
              [-35.078648020557388, -7.897805937977666],
              [-35.077647269443979, -7.89722197695889],
              [-35.078511575583683, -7.896361187973608],
              [-35.078750533951904, -7.896139787532133],
              [-35.07985775542646, -7.895063188072966],
              [-35.080702703690662, -7.894231493891108],
              [-35.080903479327596, -7.894034113272115],
              [-35.081481353390473, -7.893485808122226],
              [-35.082116044075377, -7.892870650432292],
              [-35.082941804472952, -7.892053460145926],
              [-35.083656245624489, -7.891348343077251],
              [-35.083794626709974, -7.891176422254758],
              [-35.083858380329595, -7.891038412167302],
              [-35.083959609756448, -7.890774980894984],
              [-35.084042264211206, -7.890670902607107],
              [-35.084176370289079, -7.89055583842396],
              [-35.084418427588759, -7.890399669574127],
              [-35.084854780509509, -7.890138541519212],
              [-35.086109537623329, -7.88943314682613],
              [-35.087141608271452, -7.888914571803413],
              [-35.087352869744613, -7.888839906419432],
              [-35.087559790226379, -7.888799536816611],
              [-35.087729232967625, -7.888788750001728],
              [-35.0878940537226, -7.888807835995693],
              [-35.088174565576992, -7.888878679744027],
              [-35.088475731281761, -7.888942794548994],
              [-35.088647242006864, -7.888932934729744],
              [-35.088791230962634, -7.888887280116643],
              [-35.088942073896376, -7.888799677772239],
              [-35.089168424337089, -7.888609681124379],
              [-35.089507199009518, -7.888316515203481],
              [-35.089776402162926, -7.888079264882556],
              [-35.089990080668315, -7.887932430954314],
              [-35.090300219476568, -7.887751581306877],
              [-35.090730138382881, -7.887580520680705],
              [-35.091013546332796, -7.887483148489471],
              [-35.091597771108901, -7.887279336176157],
              [-35.091975917374249, -7.887148358261932],
              [-35.092689549220459, -7.886891229373575],
              [-35.093216863405196, -7.886711435869693],
              [-35.094049092378746, -7.886434985184985],
              [-35.094737256431586, -7.886154604425268],
              [-35.095472904143016, -7.885855008819274],
              [-35.09580924274956, -7.885682844135264],
              [-35.096029892199624, -7.885540854181305],
              [-35.09628627043697, -7.885320867742863],
              [-35.096560687538322, -7.885102612305954],
              [-35.096657722857699, -7.885038730775018],
              [-35.096793902902533, -7.884949078433353],
              [-35.09706724803182, -7.884702242175543],
              [-35.097254640745263, -7.884538818333463],
              [-35.0973204353295, -7.884429821064763],
              [-35.097676729976897, -7.883984741111716],
              [-35.098024562715288, -7.883499775403754],
              [-35.098401980219997, -7.883032936602238],
              [-35.098732141038084, -7.882623204408686],
              [-35.098895359936222, -7.882453977586371],
              [-35.098966164460968, -7.882376658121845],
              [-35.09977249211412, -7.881402040812004],
              [-35.10022473621904, -7.880706163503379],
              [-35.100809929892669, -7.879201994372781],
              [-35.100969932977208, -7.878799298017343],
              [-35.101127005132504, -7.878326336102012],
              [-35.101207105819121, -7.877916277737727],
              [-35.101369828308677, -7.876901772234127],
              [-35.101514868686714, -7.876237602513801],
              [-35.101669403976601, -7.875598071645035],
              [-35.101932231538491, -7.874841863585393],
              [-35.10206533669519, -7.874624323046301],
              [-35.102201760765027, -7.874496074947624],
              [-35.102396472391455, -7.874379181817795],
              [-35.102801414479814, -7.874215311128796],
              [-35.103083313463372, -7.874086132136505],
              [-35.103661206420583, -7.873690609024814],
              [-35.10606238579566, -7.870358832650514],
              [-35.106263361537444, -7.870008634993154],
              [-35.106977132004758, -7.868857009733512],
              [-35.107573188522828, -7.867458667945336],
              [-35.107638521189074, -7.867236352389558],
              [-35.107770074602342, -7.866665971070038],
              [-35.107957986706623, -7.865934220805045],
              [-35.108122851840001, -7.86540840322813],
              [-35.108216408325333, -7.865142978963193],
              [-35.108428200000198, -7.864772625440237],
              [-35.108726427047003, -7.864376718137845],
              [-35.109069208742469, -7.864007466698369],
              [-35.109517251748578, -7.863498524210716],
              [-35.109717545392293, -7.863296777618694],
              [-35.109876619325227, -7.863121977828665],
              [-35.109960021784488, -7.863003305633087],
              [-35.110023746401247, -7.862869610518533],
              [-35.110109100964173, -7.862580623396243],
              [-35.110373487520391, -7.861815029397181],
              [-35.110552569537184, -7.861303973737323],
              [-35.110615406200402, -7.861112886983357],
              [-35.110671598915964, -7.860917800249999],
              [-35.110746974505268, -7.860698147684518],
              [-35.110768199388744, -7.860637590080279],
              [-35.11139436057713, -7.859389315368968],
              [-35.111632798125662, -7.858913982525233],
              [-35.111940085056112, -7.858588146788073],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3330',
        NOME_UC1: 'REFÚGIO DA VIDA SILVESTRE MATA DO ENGENHO UCHÔA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '13712',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '01/09/2017',
        CODIGO_U11: '0000.26.3330',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.947906912245479, -8.09435907318146],
              [-34.94663767508019, -8.095301039370497],
              [-34.945912890389707, -8.095467603812212],
              [-34.944663877428489, -8.095910664147317],
              [-34.942192666684264, -8.099072647549695],
              [-34.941558307155582, -8.099465458634983],
              [-34.939892384247038, -8.100007358054352],
              [-34.938650259384104, -8.101644222900052],
              [-34.938140744428907, -8.102726703744315],
              [-34.937726829919889, -8.103581567085175],
              [-34.937422052873572, -8.104043273971234],
              [-34.936919640498246, -8.104237594395736],
              [-34.935947504904007, -8.104063564799942],
              [-34.935056607488193, -8.104188430385435],
              [-34.934488036745577, -8.104190540969636],
              [-34.933687464637529, -8.104337559480042],
              [-34.932576115018577, -8.104258574189123],
              [-34.931794942989441, -8.104091167687061],
              [-34.931050979822622, -8.103726016452727],
              [-34.930251466333679, -8.102993528485268],
              [-34.929073726647616, -8.102303790981971],
              [-34.928571717657604, -8.101953710824567],
              [-34.927740486920264, -8.101903379552356],
              [-34.927713784966173, -8.102119663175603],
              [-34.928323464294863, -8.102265896688946],
              [-34.928270534222072, -8.102924392517366],
              [-34.928175306513353, -8.103516693731478],
              [-34.928286788655093, -8.103549104606914],
              [-34.928200436966719, -8.104019492592682],
              [-34.927705413733165, -8.103937867754745],
              [-34.927672052872026, -8.104093658753124],
              [-34.928138472326665, -8.104165761630576],
              [-34.928119453368247, -8.10430726572875],
              [-34.928007864219623, -8.104285643785545],
              [-34.927938710547558, -8.104552337062312],
              [-34.928390213100919, -8.104642644814948],
              [-34.928347481286615, -8.104831208600904],
              [-34.928107228112985, -8.104793890665162],
              [-34.928060329256176, -8.105085908798683],
              [-34.928537291629482, -8.105161286663137],
              [-34.928365706334603, -8.10597518743225],
              [-34.928600297536597, -8.106032235240185],
              [-34.928523343891413, -8.106343132234009],
              [-34.927832197158608, -8.106217282534706],
              [-34.927360402439206, -8.10657316796655],
              [-34.928782020667889, -8.106743928901164],
              [-34.934115067733856, -8.108125048008171],
              [-34.934370302041962, -8.107816608124978],
              [-34.934612314525722, -8.107400803906378],
              [-34.934761753802363, -8.106845440498198],
              [-34.934873918968883, -8.106836049636529],
              [-34.935164516005102, -8.106537410716891],
              [-34.935518968333582, -8.106560583822429],
              [-34.935820209942712, -8.10674902885288],
              [-34.936831926022151, -8.107229155555739],
              [-34.936937801090139, -8.107424025407539],
              [-34.937194896872718, -8.107832922023151],
              [-34.937257768204056, -8.108110148327649],
              [-34.941622637664558, -8.107894101775436],
              [-34.941625144804284, -8.107671870172013],
              [-34.942534457084918, -8.107543865619508],
              [-34.942870646467867, -8.109084431188951],
              [-34.942976895590682, -8.109198491548366],
              [-34.943024220057893, -8.109448485012939],
              [-34.945040855205903, -8.1090949946603],
              [-34.945025199420193, -8.108958980502798],
              [-34.945316024278284, -8.108908144027893],
              [-34.945343327984368, -8.109033497260524],
              [-34.946258877464892, -8.108560250879238],
              [-34.946414526240204, -8.108642881470946],
              [-34.94730352074307, -8.107015487313971],
              [-34.947954866653731, -8.105909567716067],
              [-34.948163923475612, -8.105366150274589],
              [-34.948213626375065, -8.104985214700589],
              [-34.948201935679172, -8.104681571598025],
              [-34.948139007374863, -8.104366956528011],
              [-34.947937920314551, -8.103758771202715],
              [-34.947942636275883, -8.103763518176253],
              [-34.947781276882267, -8.103205424564745],
              [-34.947697690725541, -8.102900692418174],
              [-34.947698252006944, -8.102500160932482],
              [-34.947768190720723, -8.102166873073852],
              [-34.947918822824256, -8.101841963219792],
              [-34.948539327869398, -8.100870819754496],
              [-34.949120989148327, -8.099979839758571],
              [-34.949460419299605, -8.099007019104921],
              [-34.949570916003644, -8.098261088865168],
              [-34.949498636163305, -8.09739334926074],
              [-34.949287142297301, -8.096621245957563],
              [-34.948950892305319, -8.095933718035402],
              [-34.947906912245479, -8.09435907318146],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '967',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL DE GUADALUPE',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1997',
        GID7: '11973',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 19635 de 13/03/1997',
        DT_ULTIM10: '30/11/2015',
        CODIGO_U11: '0000.26.0967',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.106034018682934, -8.588688874099725],
              [-35.103965343827596, -8.588710836708245],
              [-35.102335836072648, -8.588709422017272],
              [-35.100499459386867, -8.588844086050559],
              [-35.09873607444775, -8.589453456054635],
              [-35.097388477066168, -8.590760118071184],
              [-35.096384226324339, -8.592357064098161],
              [-35.095059745109737, -8.593708703081552],
              [-35.09334657909708, -8.594770477894492],
              [-35.092518235186574, -8.595905361519526],
              [-35.092767130826203, -8.596426218252484],
              [-35.092811921713626, -8.596991934733932],
              [-35.092675189648702, -8.597580061093366],
              [-35.092379745678187, -8.598077480265463],
              [-35.091835052652726, -8.598370877114162],
              [-35.091199616859768, -8.598596337515769],
              [-35.09067757030197, -8.59891238954585],
              [-35.090200819627974, -8.599273754061732],
              [-35.090404279012077, -8.599839785257945],
              [-35.090994150563311, -8.599817960832533],
              [-35.092038243596562, -8.599185764574692],
              [-35.092559677899118, -8.599503289159571],
              [-35.092695146462788, -8.600091834475069],
              [-35.092513026149511, -8.600634648902835],
              [-35.091991003243422, -8.60090550570308],
              [-35.091401114889095, -8.601040415703798],
              [-35.09094706928807, -8.601401839004202],
              [-35.090787594762745, -8.601967309725325],
              [-35.090424175761207, -8.602442047283301],
              [-35.09049158172396, -8.603007822485447],
              [-35.091126755597607, -8.603031309501651],
              [-35.091693940638955, -8.602896341789597],
              [-35.092124506962094, -8.603304211768229],
              [-35.092555131793631, -8.603689482105851],
              [-35.093077133631937, -8.603463911504535],
              [-35.093576476326938, -8.603147798000137],
              [-35.093758562060145, -8.60258238478885],
              [-35.094257936301894, -8.602288869211016],
              [-35.094757367162181, -8.601972754263043],
              [-35.095279412580311, -8.601656696546931],
              [-35.095869089509208, -8.601748038756465],
              [-35.096458927728506, -8.601703516542608],
              [-35.096981142465836, -8.60131956993431],
              [-35.097525302091285, -8.601523969546902],
              [-35.097184508293239, -8.601998678554397],
              [-35.096707786501305, -8.602314853280511],
              [-35.096208367448206, -8.60269885652005],
              [-35.095686312009072, -8.602947029575239],
              [-35.095232212912968, -8.603331146759997],
              [-35.094800852157746, -8.603737828824817],
              [-35.095140549722885, -8.604236131395016],
              [-35.095503011654635, -8.604711801296583],
              [-35.095683833479207, -8.605255080568339],
              [-35.095501807897087, -8.605797895354099],
              [-35.095161009100643, -8.606272694379934],
              [-35.094684232953426, -8.606679353204946],
              [-35.09413952201345, -8.606904869596111],
              [-35.093572519541198, -8.606858871508893],
              [-35.09300553117896, -8.606699698647011],
              [-35.092438633927934, -8.606540524339918],
              [-35.091871492539454, -8.606585009208825],
              [-35.09137211037671, -8.60687843301827],
              [-35.090872694663034, -8.607149348293364],
              [-35.090396050648472, -8.607465427019132],
              [-35.090418079414597, -8.608053775965903],
              [-35.090145276361326, -8.608573852152519],
              [-35.089668482003496, -8.608912620374166],
              [-35.089351491576039, -8.608414369811962],
              [-35.089193348662128, -8.607848454435887],
              [-35.089125908912081, -8.607260080401209],
              [-35.08903576240251, -8.606671558407069],
              [-35.088991063238069, -8.606105840189633],
              [-35.088674068047389, -8.605539701878186],
              [-35.088107361668982, -8.605199458702238],
              [-35.087631333884147, -8.604882044339851],
              [-35.087064324540634, -8.604768149313083],
              [-35.086428956278084, -8.604925627642471],
              [-35.085906896788579, -8.605241676109921],
              [-35.085430191677453, -8.605580438538221],
              [-35.085089435808477, -8.606032542161358],
              [-35.084612613338045, -8.606416592440951],
              [-35.084113853632488, -8.606144313248018],
              [-35.083547033468982, -8.605849441493577],
              [-35.082957406000311, -8.605735391751834],
              [-35.082413152337217, -8.605531062009772],
              [-35.082028028695156, -8.605077916726584],
              [-35.081892605185018, -8.604512053267873],
              [-35.081643623956722, -8.603991187905496],
              [-35.081213006813428, -8.603605902799732],
              [-35.080737068637525, -8.603220590842453],
              [-35.080192935520039, -8.60297096819377],
              [-35.079648802477571, -8.602721254209879],
              [-35.079218246969873, -8.602313366907072],
              [-35.078696821837973, -8.602063709327902],
              [-35.07806172955582, -8.601972313898569],
              [-35.077471797263456, -8.602084605433904],
              [-35.076927134730468, -8.602287499190401],
              [-35.076405119501757, -8.602513049764523],
              [-35.076087000933093, -8.60298780481534],
              [-35.075655614866101, -8.603394470174557],
              [-35.075382758730022, -8.603891941316819],
              [-35.075223350207025, -8.604457410765876],
              [-35.075358855150313, -8.605023278442351],
              [-35.075834850734019, -8.605385998620722],
              [-35.076424536157504, -8.605477370381784],
              [-35.076991741702464, -8.605410309127318],
              [-35.077581536694687, -8.605388503545075],
              [-35.077762368392627, -8.60595448628191],
              [-35.077308133078297, -8.606451672505894],
              [-35.077398203035727, -8.607062710325962],
              [-35.07746553470794, -8.607651181625229],
              [-35.077487576135198, -8.608262134703097],
              [-35.077350761289921, -8.608872951417053],
              [-35.077440858819664, -8.609438791223273],
              [-35.077258804570548, -8.609981603924608],
              [-35.076782775179673, -8.609664085072387],
              [-35.07644311733101, -8.609120564026798],
              [-35.076148783216965, -8.608577068987026],
              [-35.076058830797301, -8.60792074182315],
              [-35.075900648186561, -8.607377415376213],
              [-35.075674378794695, -8.606856693374167],
              [-35.075357347698684, -8.606381025624245],
              [-35.074857805269417, -8.606765007927551],
              [-35.074268032971702, -8.60674152160172],
              [-35.07438220485971, -8.606108047387513],
              [-35.074065170329504, -8.605564581444089],
              [-35.073475569268872, -8.60540531847275],
              [-35.072885661438761, -8.605540293637095],
              [-35.072363611793868, -8.605811038240063],
              [-35.071841529744674, -8.606059273970978],
              [-35.071342126491288, -8.606352675257584],
              [-35.070752098029871, -8.606532846129515],
              [-35.070320702211468, -8.606939598122095],
              [-35.070365374537573, -8.607505325049354],
              [-35.070387431477968, -8.608071083256521],
              [-35.07009189056447, -8.608591093133601],
              [-35.069637811408668, -8.608952498065308],
              [-35.069161051211275, -8.609268646017618],
              [-35.069183107104543, -8.609834404644252],
              [-35.069772832478172, -8.609948384096059],
              [-35.070340185884568, -8.609722866474756],
              [-35.070930187253083, -8.609520096312037],
              [-35.071565319872796, -8.609566212581271],
              [-35.072041378407633, -8.609906337681132],
              [-35.071474046702349, -8.610018682232798],
              [-35.071065264633994, -8.610425404315121],
              [-35.070475347708395, -8.610560377786836],
              [-35.069908019669079, -8.610740607492925],
              [-35.069476586571227, -8.611124670645543],
              [-35.069838972018914, -8.611622969903058],
              [-35.070269500880912, -8.612008268676286],
              [-35.070700095189373, -8.612438854620365],
              [-35.070699447334427, -8.613004554003959],
              [-35.071107245242573, -8.613435081099295],
              [-35.071378897223873, -8.613933233926069],
              [-35.070970053582272, -8.614362646460719],
              [-35.070539520964026, -8.613977349313515],
              [-35.06997246972653, -8.613908629086268],
              [-35.069382881956351, -8.613704164550185],
              [-35.068815857850822, -8.613590244455255],
              [-35.068633645576348, -8.614223543252683],
              [-35.068564964214133, -8.614789157461852],
              [-35.067997655245549, -8.614924097583827],
              [-35.067839595890547, -8.614335567441325],
              [-35.067226958156724, -8.61440259173097],
              [-35.066772869849068, -8.614763995451355],
              [-35.066908252836441, -8.615375156872657],
              [-35.067043753871815, -8.615941029186475],
              [-35.067065750462177, -8.616529387675351],
              [-35.067201194114361, -8.617117949412558],
              [-35.067223132542061, -8.61772890705401],
              [-35.067154449628617, -8.618294521558528],
              [-35.067017734852563, -8.618860050132781],
              [-35.066744950511271, -8.619357519564183],
              [-35.066925693542181, -8.619991396978049],
              [-35.06721999643149, -8.620512210645639],
              [-35.067537066931997, -8.621010483183335],
              [-35.067808637972114, -8.621576616247841],
              [-35.067966761759543, -8.622142546244923],
              [-35.067875372805908, -8.622708102174066],
              [-35.067716009957124, -8.62325097323342],
              [-35.067601854422449, -8.623839160357729],
              [-35.067623914021702, -8.624404919022357],
              [-35.067600590261257, -8.624993249817363],
              [-35.067399207437646, -8.625342177200849],
              [-35.067143124136933, -8.625453567808524],
              [-35.066914320569097, -8.625436901361592],
              [-35.066823543961164, -8.625413525590107],
              [-35.066732766868341, -8.625390059401321],
              [-35.066642229780989, -8.625343902548723],
              [-35.066551453198088, -8.62532052670352],
              [-35.066460916141217, -8.625274369798907],
              [-35.066370288765306, -8.625228303749662],
              [-35.066302701353571, -8.625159785901428],
              [-35.066235263165019, -8.625068577878771],
              [-35.066213333517766, -8.62497775769361],
              [-35.06621435299612, -8.624864486228779],
              [-35.066169864002582, -8.62475082689995],
              [-35.066147934406487, -8.624660006701662],
              [-35.066126004825783, -8.624569186499286],
              [-35.066127174063055, -8.624433315261138],
              [-35.06612795306944, -8.624342644042169],
              [-35.066129213152891, -8.624206772314073],
              [-35.066129992171135, -8.624116101094312],
              [-35.066131011733113, -8.624002829619668],
              [-35.066131940965384, -8.623889649026491],
              [-35.066132960542184, -8.623776377550898],
              [-35.066156688641776, -8.623663255072485],
              [-35.066157468174254, -8.623572674245223],
              [-35.066158338539594, -8.623482092929017],
              [-35.066181826580305, -8.623391661101179],
              [-35.066228113935438, -8.623301377783918],
              [-35.066274642304734, -8.62318858460571],
              [-35.066343638591988, -8.62309854067515],
              [-35.066390075594043, -8.622985657591245],
              [-35.066436993156877, -8.622827483611326],
              [-35.066460721660398, -8.622714451537318],
              [-35.066439181992244, -8.622578340927458],
              [-35.06644035078925, -8.62244237930984],
              [-35.066441130320548, -8.622351798493202],
              [-35.066442000193383, -8.622261126794328],
              [-35.066442928960718, -8.622147855820446],
              [-35.066443799335282, -8.622057274514061],
              [-35.066399310807718, -8.621943615135184],
              [-35.066377771270055, -8.621807504501307],
              [-35.066355991693158, -8.621694084508633],
              [-35.066334302473678, -8.621580573629187],
              [-35.066312373203566, -8.621489753392522],
              [-35.066290353121204, -8.621398933640412],
              [-35.066245715508963, -8.621307964371308],
              [-35.066178128489078, -8.621239355925495],
              [-35.066088132141232, -8.621125308494557],
              [-35.066020545674377, -8.621056790399235],
              [-35.065930159555641, -8.620988033333916],
              [-35.06581667523217, -8.620964417612383],
              [-35.065702950309415, -8.620963402117086],
              [-35.065612025058712, -8.62096262556067],
              [-35.065521008975686, -8.620961849471056],
              [-35.065429843093689, -8.620983673138157],
              [-35.065338677687087, -8.621005587179294],
              [-35.065225043547187, -8.621004571056501],
              [-35.065133877621797, -8.621026394659626],
              [-35.065042861497545, -8.621025618459942],
              [-35.064951696037276, -8.621047532416654],
              [-35.064860530071066, -8.621069355960921],
              [-35.064769454917176, -8.621091178997643],
              [-35.064678048746316, -8.621135693173427],
              [-35.064564174342188, -8.621157367535179],
              [-35.06447276812159, -8.621201881671302],
              [-35.064404012227619, -8.621269325016698],
              [-35.064312605955372, -8.621313839125158],
              [-35.064221049796998, -8.621380953011212],
              [-35.064152202504495, -8.621448306423819],
              [-35.064083205811144, -8.621538350019017],
              [-35.064014209087169, -8.621628393609154],
              [-35.063922652796592, -8.621695507453158],
              [-35.063785348067327, -8.621784923732775],
              [-35.063716351229161, -8.621874967295337],
              [-35.063602086373052, -8.621941931921727],
              [-35.063487191110411, -8.62207687769725],
              [-35.063372685907268, -8.62216653296502],
              [-35.06325779000754, -8.622301388312087],
              [-35.06318894286548, -8.6223688320215],
              [-35.063097236812247, -8.622458635944266],
              [-35.062982730905475, -8.622548200749227],
              [-35.062890693670084, -8.622660695823949],
              [-35.062799137402074, -8.622727899894908],
              [-35.062707580609086, -8.622795013557976],
              [-35.06261602427157, -8.622862217599979],
              [-35.062524857628723, -8.62288404072102],
              [-35.062433541514643, -8.622928554033354],
              [-35.062342134547045, -8.622973067816742],
              [-35.06225096833807, -8.622994981275896],
              [-35.062160042413012, -8.622994204013878],
              [-35.062069116483265, -8.622993426730243],
              [-35.061955390698181, -8.622992410264297],
              [-35.061842295524492, -8.622923412526783],
              [-35.061751909404059, -8.622854654409764],
              [-35.061661373810495, -8.622808586484778],
              [-35.061593787585515, -8.622740067497762],
              [-35.061503160714729, -8.622693909618409],
              [-35.061390066183208, -8.622625002099879],
              [-35.061322480036331, -8.622556483045292],
              [-35.061255043446451, -8.622465273745773],
              [-35.061187456863713, -8.622396664261013],
              [-35.061120170370948, -8.622282855087175],
              [-35.061052974262097, -8.622168955010503],
              [-35.060985778688597, -8.622055145305504],
              [-35.060941050767873, -8.621964085235582],
              [-35.060919122670029, -8.62187326439452],
              [-35.060919994379525, -8.621782682790133],
              [-35.060920924829126, -8.621669411440408],
              [-35.060944505010994, -8.621578979171364],
              [-35.060990794131776, -8.621488786631243],
              [-35.061014283467024, -8.621398354851012],
              [-35.061083131442388, -8.621331001872912],
              [-35.061129570067251, -8.621218119099742],
              [-35.061175859115153, -8.621127926556291],
              [-35.061244947397931, -8.621037882850464],
              [-35.06131394482226, -8.620947839626909],
              [-35.061382942705805, -8.620857886791718],
              [-35.061451790524906, -8.620790533784021],
              [-35.061520637829688, -8.620723090375975],
              [-35.061589394772071, -8.620655737842082],
              [-35.061658242517645, -8.620588384813894],
              [-35.061727090238726, -8.620521031778781],
              [-35.061795847107661, -8.620453679224418],
              [-35.061910593214208, -8.620341424139372],
              [-35.061956881507037, -8.620251141173027],
              [-35.062025638287977, -8.6201837885976],
              [-35.062163093092551, -8.620071773155853],
              [-35.062254648980399, -8.620004569351941],
              [-35.062323496489164, -8.619937216257647],
              [-35.062415052312808, -8.619870012429317],
              [-35.062506998408601, -8.61975751806685],
              [-35.062598554643643, -8.619690404605409],
              [-35.062690110353948, -8.619623200736036],
              [-35.062758957713932, -8.619555847596651],
              [-35.06282819532651, -8.619443203938827],
              [-35.062896951803737, -8.619375851275219],
              [-35.062944111137, -8.619194986762711],
              [-35.063013107855141, -8.619104943398678],
              [-35.063082105032834, -8.619014990422944],
              [-35.063128392926984, -8.618924707412786],
              [-35.063151973025946, -8.618834365552802],
              [-35.063152753049501, -8.618743694151005],
              [-35.0631308248213, -8.618652873502707],
              [-35.063131605349902, -8.618562292492445],
              [-35.063087118379251, -8.618448632381018],
              [-35.063065190199637, -8.618357811719981],
              [-35.063020462973391, -8.618266842284381],
              [-35.06299853482961, -8.618176021612515],
              [-35.062953897986219, -8.618084961278504],
              [-35.062909170831162, -8.617993991814915],
              [-35.062841734995928, -8.617902782673436],
              [-35.062774149689751, -8.617834263723143],
              [-35.062706563918134, -8.617765654361557],
              [-35.062615938794103, -8.617719586880453],
              [-35.062525644864934, -8.617650828183026],
              [-35.062434629409253, -8.617650051173134],
              [-35.062343704775721, -8.617649273654157],
              [-35.062252389742781, -8.617693786644194],
              [-35.062160983857538, -8.617738300104874],
              [-35.062046719688411, -8.617805264053642],
              [-35.061954923319021, -8.617895068015351],
              [-35.061863998600806, -8.617894290396242],
              [-35.061773223442579, -8.617870822530849],
              [-35.061681667866914, -8.61793802612606],
              [-35.061590261830631, -8.617982539479756],
              [-35.061498705700394, -8.618049652651392],
              [-35.061407390435697, -8.618094165485367],
              [-35.061316374813032, -8.618093388234708],
              [-35.061225450012692, -8.618092610475125],
              [-35.061090084277673, -8.618120726665246],
              [-35.060929876013844, -8.618090037775744],
              [-35.060860788013336, -8.618180081329434],
              [-35.060792031254813, -8.618247433666006],
              [-35.060745742293662, -8.618337626114759],
              [-35.060699453314299, -8.618427818562663],
              [-35.06065316480619, -8.618518101403305],
              [-35.060584167012358, -8.61860805405828],
              [-35.060537637517776, -8.618720937230005],
              [-35.060536856378363, -8.618811518379578],
              [-35.060536075733118, -8.618902189922423],
              [-35.060512495463279, -8.618992622161862],
              [-35.060466206378962, -8.619082814610499],
              [-35.060419917276427, -8.619173007058302],
              [-35.060350919824728, -8.619263050102919],
              [-35.060281922342455, -8.619353093142397],
              [-35.060167566464088, -8.619420057205144],
              [-35.060076491149275, -8.619441879099996],
              [-35.059962525815948, -8.619463552527131],
              [-35.059827426817733, -8.619473949316719],
              [-35.059666950526662, -8.619460979103303],
              [-35.059576565650616, -8.61939222017654],
              [-35.059508980468273, -8.619323700580054],
              [-35.059441544995785, -8.619232490710651],
              [-35.059396817895006, -8.619141430283426],
              [-35.059374890468035, -8.619050609216876],
              [-35.059375762699382, -8.618960027518741],
              [-35.059347906406082, -8.61884781431946],
              [-35.059309108768289, -8.618778236386179],
              [-35.059241523269243, -8.618709626309958],
              [-35.059150898119022, -8.618663557966874],
              [-35.059060122781226, -8.61864008946432],
              [-35.058969588488786, -8.618594020582471],
              [-35.058879054214579, -8.618547951673975],
              [-35.058811468826029, -8.618479341493426],
              [-35.058721084332525, -8.618410582270798],
              [-35.058721865927389, -8.618320001021841],
              [-35.058714006093133, -8.618210302065519],
              [-35.058768546221657, -8.618184518081463],
              [-35.05885995279062, -8.618140005311318],
              [-35.058951027460317, -8.618118093367029],
              [-35.05906499253723, -8.618096420320033],
              [-35.059156158490076, -8.618074598239099],
              [-35.059247474141188, -8.61803008588006],
              [-35.059339030303214, -8.617962882759652],
              [-35.059407878168557, -8.617895530147353],
              [-35.059476726009414, -8.617828177528208],
              [-35.059545482998637, -8.617760825389007],
              [-35.059591771751911, -8.617670542638555],
              [-35.059615352735904, -8.61758020081132],
              [-35.059684350182174, -8.617490157922425],
              [-35.059707930652593, -8.617399725702878],
              [-35.059754219828207, -8.61730953334424],
              [-35.059800418158567, -8.617219341471655],
              [-35.059846706808564, -8.617129058717767],
              [-35.059870287725154, -8.617038716894577],
              [-35.059952951632731, -8.616956465203875],
              [-35.059918139127326, -8.616767271759658],
              [-35.059918920036552, -8.616676600175166],
              [-35.059919792266548, -8.616586018497102],
              [-35.05992072336916, -8.616472747054257],
              [-35.059921595609758, -8.616382165375505],
              [-35.059922376539134, -8.616291493789696],
              [-35.059945866115932, -8.616201062067889],
              [-35.059969447002238, -8.616110720253559],
              [-35.059970618647668, -8.615974758073788],
              [-35.059994830765952, -8.615816434928805],
              [-35.060041269459589, -8.615703552339433],
              [-35.060133456198294, -8.615568367858504],
              [-35.060224771147354, -8.615523855429643],
              [-35.060316327071952, -8.615456742643783],
              [-35.060453389984808, -8.615389928261864],
              [-35.060544555167567, -8.615368106019332],
              [-35.060681617992763, -8.615301291572584],
              [-35.060772542153522, -8.615302069618149],
              [-35.060863707280106, -8.615280247308952],
              [-35.061000379873065, -8.615258813731293],
              [-35.061091694605992, -8.61521430113684],
              [-35.061183100140063, -8.615169788038525],
              [-35.061252096846033, -8.615079745047181],
              [-35.061344042529583, -8.614967251128585],
              [-35.0613903311109, -8.614877058722689],
              [-35.061391112260743, -8.614786477603767],
              [-35.061392133873127, -8.614673205764349],
              [-35.061392914544207, -8.61458253425123],
              [-35.061393695709462, -8.614491953131315],
              [-35.06139447687945, -8.614401372011033],
              [-35.061327133012455, -8.614310161865358],
              [-35.061236749374316, -8.614241403095367],
              [-35.061146365766753, -8.614172644296165],
              [-35.061056073015969, -8.61410388498085],
              [-35.060965689469569, -8.614035126123319],
              [-35.060920963441617, -8.613944156186456],
              [-35.060899036265234, -8.613853335199225],
              [-35.0608998170988, -8.61376266365628],
              [-35.060878130939557, -8.613649242328897],
              [-35.060879152782384, -8.61353597044943],
              [-35.060857375342756, -8.613422459208522],
              [-35.060789940985259, -8.613331249380154],
              [-35.060768014397667, -8.613240518760641],
              [-35.060700429917503, -8.613171908753644],
              [-35.060655704096163, -8.613080938743344],
              [-35.060588269858656, -8.612989728852481],
              [-35.060543875451621, -8.612876067596934],
              [-35.060499149225876, -8.612785007156569],
              [-35.06045466405557, -8.612671346364483],
              [-35.060387470989369, -8.61255753606606],
              [-35.060342744852314, -8.612466475588452],
              [-35.060275792421983, -8.612329974515637],
              [-35.060231066837268, -8.612239004405005],
              [-35.060163873446797, -8.612125103637847],
              [-35.060074122437179, -8.611988453468165],
              [-35.059984130429619, -8.611874403609544],
              [-35.059916787433401, -8.611783193020742],
              [-35.059849353646186, -8.611691982898702],
              [-35.059759361784771, -8.611577932953455],
              [-35.059714726777166, -8.611486871849502],
              [-35.059692950420569, -8.611373450815712],
              [-35.059671023814559, -8.611282629643354],
              [-35.059626298051526, -8.611191569001171],
              [-35.059581904242862, -8.611077907507681],
              [-35.059582835821701, -8.61096463601503],
              [-35.059538592351323, -8.61082837463748],
              [-35.05953937367466, -8.610737703009269],
              [-35.05951768828929, -8.610624281452669],
              [-35.059518470117482, -8.610533700216191],
              [-35.059519251461687, -8.610443028585738],
              [-35.059520033299364, -8.610352447348657],
              [-35.059520815141802, -8.61026186611123],
              [-35.059567103410778, -8.610171583543895],
              [-35.059613783067149, -8.610036100752676],
              [-35.059637604140278, -8.609922977861217],
              [-35.059683802019556, -8.609832786174564],
              [-35.059753430497409, -8.609674852606599],
              [-35.059822908645032, -8.609539518904379],
              [-35.059892295926552, -8.609404185686763],
              [-35.059938975428388, -8.609268702900312],
              [-35.059985263521249, -8.609178420329926],
              [-35.059986285934272, -8.609065148368661],
              [-35.059987308354678, -8.60895187640695],
              [-35.059965291622781, -8.608861146104521],
              [-35.059897708219339, -8.60879253572489],
              [-35.059806935377942, -8.608769066845451],
              [-35.05971640367018, -8.608722997587215],
              [-35.059626021800391, -8.608654238039236],
              [-35.059535248995552, -8.608630769083701],
              [-35.059421377512315, -8.608652441121796],
              [-35.059330063882967, -8.608696953009119],
              [-35.059238659403299, -8.608741465365878],
              [-35.059078890081707, -8.608808038805128],
              [-35.058987335679682, -8.608875241386739],
              [-35.058896021927829, -8.608919753194856],
              [-35.0588043761474, -8.608986865838904],
              [-35.058712671776945, -8.609076758651213],
              [-35.058620876049439, -8.60916656154382],
              [-35.058551879349253, -8.609256603982541],
              [-35.058482882130079, -8.609346556022439],
              [-35.058413644662089, -8.609459289215913],
              [-35.058344647378981, -8.609549241246386],
              [-35.058298358825674, -8.609639523719501],
              [-35.058229361487797, -8.609729475742533],
              [-35.058183072892099, -8.609819758212504],
              [-35.058159491591368, -8.609910099952662],
              [-35.058136001597987, -8.610000531601274],
              [-35.058112180056888, -8.610113654505165],
              [-35.058088689562048, -8.610203995761838],
              [-35.058065108723774, -8.610294427899104],
              [-35.05801881955901, -8.610384619978353],
              [-35.05797253086444, -8.610474902450505],
              [-35.057903774541572, -8.610542254088006],
              [-35.057812610149881, -8.610564075472675],
              [-35.05772183736007, -8.610540696565247],
              [-35.057630913686694, -8.61053982713465],
              [-35.057517341743896, -8.610516208579524],
              [-35.057403710217592, -8.610515189399393],
              [-35.057313328134285, -8.610446429315022],
              [-35.057222946569745, -8.610377759595247],
              [-35.057155604470879, -8.610286548298065],
              [-35.057087930317316, -8.610217937855127],
              [-35.057020347501904, -8.610149417302324],
              [-35.056907016464322, -8.610103107745459],
              [-35.056816093340032, -8.610102328490788],
              [-35.056724928924218, -8.610124149607659],
              [-35.056611056656713, -8.610145820953521],
              [-35.056519892695817, -8.610167732420697],
              [-35.056405929076227, -8.610189313803378],
              [-35.056291665441123, -8.610256275769189],
              [-35.056199869274387, -8.610346168672793],
              [-35.056108163401817, -8.610435970684778],
              [-35.055994049641193, -8.610480242280095],
              [-35.055902885534543, -8.610502153627566],
              [-35.055811720924673, -8.610523974561996],
              [-35.055720556787413, -8.610545885871062],
              [-35.055629783525852, -8.6105224160441],
              [-35.055539101096421, -8.610498945707551],
              [-35.055448086977812, -8.610498166636058],
              [-35.055334605856444, -8.61047454691526],
              [-35.055221124253805, -8.61045083676377],
              [-35.055121044602132, -8.61038592454835],
              [-35.055086109130258, -8.610291104523316],
              [-35.055132399067858, -8.610200912636714],
              [-35.055481249544897, -8.609927768396977],
              [-35.055958023253055, -8.609543837239217],
              [-35.056344236133278, -8.609091779002645],
              [-35.056880569011881, -8.608030821180014],
              [-35.056791623774025, -8.607723316188951],
              [-35.05672428238114, -8.607632104669953],
              [-35.056656609360765, -8.607563584407053],
              [-35.056589177207094, -8.607472373335016],
              [-35.05654454358546, -8.607381311553405],
              [-35.056476870645191, -8.607312791239952],
              [-35.056432237562937, -8.607221819827723],
              [-35.056364655010363, -8.607153208603275],
              [-35.056274033133072, -8.607107138827647],
              [-35.056183502098762, -8.607061068539979],
              [-35.056092729716795, -8.607037598630228],
              [-35.055979249683404, -8.607013978863336],
              [-35.055684616409167, -8.606898128319985],
              [-35.055571135928609, -8.606874418022848],
              [-35.055480213505234, -8.606873638270272],
              [-35.055389200247475, -8.606872858077415],
              [-35.055298277819517, -8.60687207918558],
              [-35.055184406299965, -8.606893749902838],
              [-35.055093242424114, -8.606915570477545],
              [-35.054979129920348, -8.606959931953005],
              [-35.054864866768924, -8.607026893336375],
              [-35.054613932924745, -8.607115285451284],
              [-35.054522527963776, -8.60715979672969],
              [-35.05440826464821, -8.607226758009563],
              [-35.054316709472054, -8.607293959587441],
              [-35.054225304421614, -8.607338470812953],
              [-35.054134140336402, -8.607360291197493],
              [-35.05404297672321, -8.607382201956762],
              [-35.053928954233911, -8.607426472301901],
              [-35.053837549088158, -8.607470983452922],
              [-35.053768551265108, -8.607561025233265],
              [-35.053677387077791, -8.60758284552783],
              [-35.053586223362387, -8.607604756197132],
              [-35.053494818119347, -8.60764926728571],
              [-35.053403503676101, -8.60769377787258],
              [-35.053312189207617, -8.607738288442697],
              [-35.053118423717159, -8.607766441651865],
              [-35.052996210615817, -8.6078077723971],
              [-35.052853725455797, -8.607879584562275],
              [-35.052660014109783, -8.607900957526988],
              [-35.052516653804759, -8.60781033148108],
              [-35.052436439404964, -8.60775724465603],
              [-35.052431746237957, -8.607754528869771],
              [-35.052409005112253, -8.607739582889277],
              [-35.052256541975005, -8.607644706923285],
              [-35.052197993656065, -8.607601226605819],
              [-35.051963689245248, -8.607455220303017],
              [-35.051635508876821, -8.60723224450833],
              [-35.051455817838153, -8.6071247267231],
              [-35.051338849893142, -8.607094344369779],
              [-35.051229692914433, -8.607063920329066],
              [-35.051128388745546, -8.607041228503443],
              [-35.051020407004025, -8.606992899576534],
              [-35.050906513520083, -8.606993719197243],
              [-35.000967771478017, -8.607349873644385],
              [-35.001629147646952, -8.614513385347069],
              [-35.003103964647678, -8.615492953306974],
              [-35.004464441460051, -8.616698837028363],
              [-35.005414659354834, -8.61828852047036],
              [-35.006718440805869, -8.619832599806823],
              [-35.007782230276746, -8.62128662382103],
              [-35.008504167965846, -8.622988587336827],
              [-35.010334204295397, -8.626529878489077],
              [-35.011306455258143, -8.62818740010446],
              [-35.01311375082598, -8.631728604109743],
              [-35.013858664177185, -8.633385317441913],
              [-35.014831076041325, -8.63499753168912],
              [-35.015779807678904, -8.636904203531916],
              [-35.016547395307619, -8.638583657940824],
              [-35.017723976825337, -8.640355241817764],
              [-35.018605497170817, -8.641967103254961],
              [-35.019327539482987, -8.643669024534546],
              [-35.020277236610312, -8.645303751565262],
              [-35.020999173982581, -8.647051046764497],
              [-35.021812324386623, -8.648730601153671],
              [-35.022784486081406, -8.650433448240499],
              [-35.023438226905704, -8.652180461235883],
              [-35.024654252378305, -8.65581001789625],
              [-35.025148526313451, -8.657647090240545],
              [-35.025407953658558, -8.661771838467843],
              [-35.025194571703899, -8.66442207910185],
              [-35.024620319534904, -8.666164738706106],
              [-35.023591685009187, -8.667792559029785],
              [-35.02208640438915, -8.669101446195171],
              [-35.022786101752708, -8.67073530193314],
              [-35.02325760108674, -8.672572312974131],
              [-35.02441293839243, -8.673958498752762],
              [-35.026139549828741, -8.674485670902355],
              [-35.027933758633694, -8.675217045082894],
              [-35.029499620272524, -8.676242149931028],
              [-35.032675830616441, -8.678586973930946],
              [-35.033716639741662, -8.680222050873466],
              [-35.03604928946654, -8.683221014128126],
              [-35.036362040857291, -8.684966813071616],
              [-35.036060508196236, -8.686801045863735],
              [-35.036259403559065, -8.688591746642739],
              [-35.035752469247853, -8.690629205160556],
              [-35.035041800041633, -8.692371430026327],
              [-35.034194936310058, -8.694045137802092],
              [-35.033369883694085, -8.695990915158406],
              [-35.032545601431231, -8.697710062169305],
              [-35.031766112204046, -8.699633268031256],
              [-35.030987244534337, -8.70135262439044],
              [-35.030594864920552, -8.703141242366994],
              [-35.030543101110176, -8.705066984030868],
              [-35.031083428439999, -8.706790871503049],
              [-35.031054535243747, -8.708694071786649],
              [-35.030571238388049, -8.710437073665268],
              [-35.031567297895656, -8.711890676753427],
              [-35.033202376803516, -8.712644076225281],
              [-35.034403478887207, -8.714007710932185],
              [-35.035558652670503, -8.715461802108669],
              [-35.036782428122265, -8.71684817417883],
              [-35.037823573106728, -8.71841513855939],
              [-35.038705251563307, -8.72009486527036],
              [-35.039267921812652, -8.721954744478611],
              [-35.039876531280242, -8.723656270554889],
              [-35.040644656904007, -8.725267624869019],
              [-35.041253312372334, -8.726991654421482],
              [-35.041656678313267, -8.728873714796665],
              [-35.041673559167982, -8.730709039292753],
              [-35.041280968215837, -8.732565640892181],
              [-35.040752169427826, -8.734353822189876],
              [-35.039950596300585, -8.736073052141876],
              [-35.038944749228904, -8.7376557061315],
              [-35.038688736659076, -8.739467460758643],
              [-35.038546040927429, -8.741415557340947],
              [-35.038289969595084, -8.743250002318625],
              [-35.038124675902687, -8.74515266076029],
              [-35.038232663103919, -8.746965711824934],
              [-35.039045876254754, -8.748735823845578],
              [-35.040200735852146, -8.750371117740906],
              [-35.041492758955002, -8.751780307151915],
              [-35.043081462817284, -8.752873308452058],
              [-35.04414513338596, -8.754576330480019],
              [-35.044799199484551, -8.756345833004719],
              [-35.045567385789624, -8.757979844329757],
              [-35.046700433725086, -8.759320530794465],
              [-35.048609478435324, -8.759848143549656],
              [-35.050381608381301, -8.760511178002739],
              [-35.052107989304986, -8.761264756616296],
              [-35.05358215895027, -8.762651924935247],
              [-35.054714263744827, -8.764309691318356],
              [-35.055642032674783, -8.765853579859597],
              [-35.056501340022635, -8.767487871060712],
              [-35.057246398636913, -8.769280340360787],
              [-35.057717858760334, -8.771253099656271],
              [-35.058052989097725, -8.773202825988315],
              [-35.058320257133751, -8.775038932116599],
              [-35.058314181859032, -8.776987533688358],
              [-35.057853267689133, -8.778911858330375],
              [-35.057347313744273, -8.780677422892635],
              [-35.056522909788505, -8.782419322583829],
              [-35.055789553282239, -8.784161539393295],
              [-35.054851512869114, -8.785880374258896],
              [-35.054936975846999, -8.787670558591557],
              [-35.055204484390664, -8.789416179364634],
              [-35.055858436392967, -8.791253612661981],
              [-35.058260897228656, -8.7941165258281],
              [-35.059850442485022, -8.795005466161919],
              [-35.061600529361421, -8.795509791065266],
              [-35.063258310999593, -8.796444261140046],
              [-35.064893606405157, -8.797288088496034],
              [-35.066345715339445, -8.798493769463558],
              [-35.067866968741633, -8.799450386209958],
              [-35.069159399226642, -8.800814125901734],
              [-35.070292032659545, -8.802381312721197],
              [-35.071196899613163, -8.804038243901521],
              [-35.071873999369508, -8.805785102399222],
              [-35.071822113208739, -8.807892128745509],
              [-35.072226120801758, -8.809683425728274],
              [-35.07317603534127, -8.811476412588661],
              [-35.07433207037657, -8.812862385874853],
              [-35.075214325696692, -8.814496642106464],
              [-35.076361588975672, -8.815821998839837],
              [-35.125209755927138, -8.815531987889205],
              [-35.128370847123243, -8.813474866900211],
              [-35.13007125239622, -8.812678649711229],
              [-35.131748814644297, -8.81181458161735],
              [-35.133494896002752, -8.811221745024142],
              [-35.135263843115737, -8.810719253376153],
              [-35.136987270232567, -8.810171636784313],
              [-35.138802069036075, -8.80991771668757],
              [-35.140617254954883, -8.809889853911354],
              [-35.142478373021845, -8.810201138328681],
              [-35.144929274474933, -8.810374909655271],
              [-35.146744455544173, -8.810347107232458],
              [-35.148217399477232, -8.80932532854813],
              [-35.149848458921035, -8.807896066190189],
              [-35.151456987761755, -8.806580006724229],
              [-35.152997973524528, -8.805512812212301],
              [-35.154607409187612, -8.804671468072586],
              [-35.156353277701761, -8.804010749878113],
              [-35.158076179750125, -8.803214300559082],
              [-35.159548856989261, -8.802079414735395],
              [-35.160657827288219, -8.800561280922015],
              [-35.161789868173464, -8.799246480791634],
              [-35.163603589471208, -8.798495048867339],
              [-35.165440504160884, -8.79801482601812],
              [-35.167323170179301, -8.797715382100897],
              [-35.169092095176154, -8.797280548569342],
              [-35.170907182421253, -8.797252645228649],
              [-35.17276826398038, -8.797586295621505],
              [-35.175126743682853, -8.796968882611543],
              [-35.177212733743943, -8.796216544583295],
              [-35.17904964533011, -8.79575885914829],
              [-35.18070425828369, -8.794894748873761],
              [-35.181767846600742, -8.793467138591389],
              [-35.18271786346596, -8.79194934445292],
              [-35.18400853701641, -8.790588840398771],
              [-35.185298694691696, -8.789002275505558],
              [-35.187175501649961, -8.785718156676737],
              [-35.188853563922109, -8.78523831057719],
              [-35.190895273285456, -8.785164357781657],
              [-35.192663626121764, -8.78448077951143],
              [-35.194363621175327, -8.783639051674694],
              [-35.196449978277855, -8.783203259939167],
              [-35.198286704334542, -8.782655024098677],
              [-35.202187595434708, -8.781965030277963],
              [-35.20400204916659, -8.781710839862178],
              [-35.205679237238066, -8.780846515958583],
              [-35.206334494180602, -8.779487960092927],
              [-35.204925428122905, -8.778225982510454],
              [-35.204173979693806, -8.776803787392733],
              [-35.205806316096492, -8.776256176060537],
              [-35.207689436065834, -8.776295784228187],
              [-35.209663577541107, -8.776470611500994],
              [-35.211159063783114, -8.77553909311241],
              [-35.211836276966508, -8.773795989959758],
              [-35.212990535976708, -8.772413266548138],
              [-35.21437200455923, -8.771165512927233],
              [-35.216141220085028, -8.771001840107658],
              [-35.21759531873542, -8.772060139133949],
              [-35.219318671056286, -8.771670482458735],
              [-35.220222706698259, -8.769926674838608],
              [-35.22117237307522, -8.768386329786345],
              [-35.222935243843274, -8.765034558344517],
              [-35.224134861583785, -8.763651730784753],
              [-35.224811996845361, -8.761953816865093],
              [-35.224422706027475, -8.760123615614614],
              [-35.223693347621065, -8.758430088052931],
              [-35.223349405077549, -8.756599707001403],
              [-35.22402654008247, -8.754901886583212],
              [-35.224839682674897, -8.75311315160506],
              [-35.224835809281991, -8.751191290206334],
              [-35.225853389298472, -8.749560253273629],
              [-35.227257097942179, -8.748176681684585],
              [-35.22872932261992, -8.74704171782308],
              [-35.230337807902345, -8.745951502510437],
              [-35.232174751697549, -8.745697090638448],
              [-35.234056498570304, -8.745171339174409],
              [-35.235529128244842, -8.744217125618082],
              [-35.236524239376855, -8.74274447330003],
              [-35.23701961243917, -8.740911510788315],
              [-35.237628579895031, -8.73919122162474],
              [-35.238328211626957, -8.737425475782812],
              [-35.240476958863262, -8.734321329013179],
              [-35.242108458411558, -8.733479689318129],
              [-35.243899546503286, -8.732999364413937],
              [-35.24623621703202, -8.733105112611376],
              [-35.248074497517919, -8.733529063424459],
              [-35.249822739928469, -8.734314987668052],
              [-35.251659861549896, -8.734150972337549],
              [-35.253405192066609, -8.733512431943129],
              [-35.254718019612788, -8.732151752367242],
              [-35.255622135184609, -8.730566273968227],
              [-35.256525748240051, -8.728777252217649],
              [-35.258015549074621, -8.725245475257546],
              [-35.258896793410216, -8.723592163068849],
              [-35.259391732573086, -8.72164622500142],
              [-35.259115158333643, -8.719521757976885],
              [-35.257932980924252, -8.718214054339182],
              [-35.25654682656063, -8.717020085151111],
              [-35.255998881314717, -8.715280814085512],
              [-35.256380781366687, -8.713493451009523],
              [-35.257352901326229, -8.71195294945996],
              [-35.258710805933141, -8.710456432641069],
              [-35.260137411764923, -8.70929889864375],
              [-35.26158649203505, -8.708073439529741],
              [-35.263172314295616, -8.707141545010114],
              [-35.264666658606224, -8.705848111541304],
              [-35.266206681742609, -8.704690159441951],
              [-35.267860512143955, -8.703758027318912],
              [-35.269150715495336, -8.702465167136431],
              [-35.270599873439544, -8.701307564876124],
              [-35.272094757815715, -8.700308038585925],
              [-35.275425361132484, -8.698601768425846],
              [-35.275807577630061, -8.696972671340712],
              [-35.273991516529925, -8.696254938284149],
              [-35.272469536952592, -8.695106659890387],
              [-35.27176284913299, -8.693435703114599],
              [-35.270557628145006, -8.691924711224198],
              [-35.268945610500886, -8.69111579735093],
              [-35.266654109103023, -8.690783806499795],
              [-35.265902357650056, -8.689180886113498],
              [-35.264946465736315, -8.687601138871297],
              [-35.263129075230417, -8.686182414731562],
              [-35.261674885723593, -8.68492078166723],
              [-35.260719158072973, -8.68340889413197],
              [-35.260420666143361, -8.681601075703242],
              [-35.259918078443384, -8.679839024723938],
              [-35.259324624197816, -8.67800945018946],
              [-35.258663323020883, -8.67627056887005],
              [-35.257549087960463, -8.674826938706612],
              [-35.256208453616331, -8.67363274718705],
              [-35.254506375451029, -8.673072834793697],
              [-35.252555005664981, -8.67267183486535],
              [-35.250875199250196, -8.671930821449733],
              [-35.24906103733408, -8.672072117099878],
              [-35.247293375257662, -8.672778400479622],
              [-35.245684959587223, -8.673687740491593],
              [-35.244462977647501, -8.675093279657546],
              [-35.243604471670139, -8.676769102540002],
              [-35.2417228522025, -8.677181832648062],
              [-35.239840703424456, -8.67736841248206],
              [-35.238003057630898, -8.677125299381544],
              [-35.236097636448953, -8.677018062451449],
              [-35.234283817220891, -8.677317468663151],
              [-35.230839775472276, -8.679091470778834],
              [-35.226078670269573, -8.682005499493178],
              [-35.223320209951147, -8.684450187318996],
              [-35.221619307017697, -8.684590980271919],
              [-35.220119939053397, -8.683351921799007],
              [-35.219186813593026, -8.681704175651523],
              [-35.218389724503744, -8.68003330855943],
              [-35.217773920942371, -8.67824894294386],
              [-35.217497752235317, -8.676169657284598],
              [-35.217766151224687, -8.674246921415405],
              [-35.217898741510851, -8.672415090564659],
              [-35.217917716786857, -8.670493171025758],
              [-35.217729286133739, -8.666876070946449],
              [-35.217045233041425, -8.665001434851309],
              [-35.215930713147188, -8.663308890730233],
              [-35.215405491565591, -8.661456466146616],
              [-35.215106787711562, -8.659445001607766],
              [-35.214060659750089, -8.657933185566398],
              [-35.212539059438342, -8.656874967278254],
              [-35.210586945802213, -8.656021606352306],
              [-35.208318907792155, -8.656005690879862],
              [-35.206436149960261, -8.655830355605246],
              [-35.204598683360857, -8.655586958707005],
              [-35.20266998232055, -8.6551178364849],
              [-35.200899323800968, -8.654241228573555],
              [-35.199671867736811, -8.652775140241562],
              [-35.199192231955365, -8.651012886068751],
              [-35.199574559149035, -8.649248119336201],
              [-35.199865896242024, -8.64739321632864],
              [-35.19981708564093, -8.645539273157056],
              [-35.198975067612118, -8.643981566536896],
              [-35.197339796930031, -8.642742686729312],
              [-35.195569009525407, -8.641752961264549],
              [-35.194138174529755, -8.640649197217302],
              [-35.192526365783259, -8.639772071124527],
              [-35.190892094748214, -8.639030647903327],
              [-35.189257155965535, -8.637904796154453],
              [-35.187328826037039, -8.637593838483619],
              [-35.18540172789497, -8.637938545635219],
              [-35.183747771641663, -8.638825107636315],
              [-35.182185051425201, -8.640005361876812],
              [-35.180621852142465, -8.64091435635018],
              [-35.179104122653321, -8.641913567435195],
              [-35.177358757304148, -8.642483814020709],
              [-35.175545212842287, -8.642918585444541],
              [-35.173594156827974, -8.642562405779358],
              [-35.171643126741174, -8.642274007500857],
              [-35.169714395269693, -8.641691614510949],
              [-35.166287113508631, -8.640208992300007],
              [-35.164697538821024, -8.639082943513769],
              [-35.163674193894337, -8.637525601727793],
              [-35.162265882459771, -8.636263381514899],
              [-35.160630974411148, -8.635114785991981],
              [-35.159200087360588, -8.633920379510748],
              [-35.15742965020506, -8.633134000844793],
              [-35.155592607601214, -8.63309389142983],
              [-35.154006709691885, -8.634048018484823],
              [-35.152124730398015, -8.634302006906593],
              [-35.150151489412629, -8.634239677517682],
              [-35.148562547617061, -8.633407402137879],
              [-35.147312411597767, -8.631805528813247],
              [-35.146606055503014, -8.629862814457724],
              [-35.145696158802075, -8.628305217556512],
              [-35.145080739966104, -8.626475339063029],
              [-35.14376980853482, -8.625182471409232],
              [-35.142417479872428, -8.623907635199629],
              [-35.13893435073026, -8.622839008753679],
              [-35.137046152620215, -8.622385720715522],
              [-35.135179699266061, -8.621841547815585],
              [-35.132429094766046, -8.619246846931885],
              [-35.130586543421437, -8.618815684810764],
              [-35.12870273524603, -8.618769903387481],
              [-35.12666509927584, -8.619201423021703],
              [-35.124850269741621, -8.619245452957083],
              [-35.123073171418667, -8.618564225997085],
              [-35.121673070175788, -8.617063395295281],
              [-35.120769683974117, -8.615330218276521],
              [-35.120184518523907, -8.61363858023811],
              [-35.120096493868871, -8.61175988780829],
              [-35.119601326978263, -8.609999232583505],
              [-35.118564277129678, -8.608494118571857],
              [-35.117232420760061, -8.604863582687408],
              [-35.116895815063586, -8.60307845325574],
              [-35.115589491016507, -8.601848153543669],
              [-35.114079668525328, -8.600688206152729],
              [-35.112394047106477, -8.600073835265473],
              [-35.110885194994388, -8.599004440199197],
              [-35.109418154949438, -8.597594837965637],
              [-35.10831337904493, -8.596113099243754],
              [-35.10738793732444, -8.594425361362655],
              [-35.10786883661472, -8.592698561764758],
              [-35.10878009271547, -8.590898810461297],
              [-35.10948762302408, -8.58914672651323],
              [-35.10785115820223, -8.588871480871317],
              [-35.106034018682934, -8.588688874099725],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3283',
        NOME_UC1: 'PARQUE ESTADUAL MATA DO ZUMBI',
        ID_WCMC2: null,
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '13740',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '22/09/2017',
        CODIGO_U11: '0000.26.3283',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.973478948433637, -8.303884813331624],
              [-34.972931333809711, -8.314435799021574],
              [-34.972610491443064, -8.322306734627626],
              [-34.972251709879707, -8.332187051666772],
              [-34.972228892874895, -8.332426355155961],
              [-34.972277162478314, -8.332473952893904],
              [-34.972469523002829, -8.332520834741288],
              [-34.97280513557763, -8.332399571742952],
              [-34.973211592797675, -8.332038766291912],
              [-34.973475038930445, -8.331893942388087],
              [-34.973955220352565, -8.331867635902066],
              [-34.974268014922636, -8.331985674392914],
              [-34.974581530381904, -8.332247222988217],
              [-34.974913661492224, -8.331432326318279],
              [-34.975389280615971, -8.330497120504097],
              [-34.975840283011813, -8.329442443418033],
              [-34.976266188753506, -8.328172620657172],
              [-34.976664957154696, -8.326281040759206],
              [-34.977440033896265, -8.322808941005405],
              [-34.977604054264766, -8.321994882208475],
              [-34.977746700407295, -8.321707145109126],
              [-34.977914441204064, -8.321634553684531],
              [-34.978299030271948, -8.321704395528215],
              [-34.978612057805435, -8.321870268720122],
              [-34.979070612038846, -8.322322443230037],
              [-34.979792726386734, -8.322653709686822],
              [-34.980945776701262, -8.322719718113774],
              [-34.98346499717384, -8.322252690863468],
              [-34.984688522189302, -8.322007392285624],
              [-34.985119936082363, -8.32183780465855],
              [-34.985262937169679, -8.321621821111277],
              [-34.98533269098089, -8.321167016002034],
              [-34.985233863124307, -8.320617377362286],
              [-34.985350320778821, -8.319899230931494],
              [-34.985610862354562, -8.319180365610054],
              [-34.98625369934895, -8.318076888968561],
              [-34.987111213946626, -8.316685314282397],
              [-34.987729548194473, -8.315486284219862],
              [-34.987823192844118, -8.315007440278364],
              [-34.987772875553958, -8.314553235078286],
              [-34.987481215245822, -8.313861049188986],
              [-34.987070331707393, -8.313336889163713],
              [-34.986515968155473, -8.312933039282873],
              [-34.985985981368529, -8.312600822972366],
              [-34.985624693230939, -8.312387357042947],
              [-34.985595865147829, -8.311430748996548],
              [-34.985569805640857, -8.311024259516438],
              [-34.985351276627277, -8.3105469743386],
              [-34.985041079555444, -8.309721378269742],
              [-34.984492885759273, -8.309587775705801],
              [-34.983829209859202, -8.30941169432335],
              [-34.983418253983061, -8.309349161415312],
              [-34.983108227340864, -8.309300477494144],
              [-34.982812680579755, -8.309266072494285],
              [-34.982538674667872, -8.309217208600398],
              [-34.982235527021494, -8.309103908087829],
              [-34.981737077952836, -8.308833715413375],
              [-34.981267409674054, -8.308556203805621],
              [-34.980869962828223, -8.308314210028458],
              [-34.980645845621709, -8.308157460844743],
              [-34.979718175831707, -8.3078004554009],
              [-34.979309107016881, -8.307635058136951],
              [-34.978852731451227, -8.307613407940671],
              [-34.978469234217314, -8.307758828753697],
              [-34.97820568510285, -8.30787973380699],
              [-34.97810927187723, -8.307808456182206],
              [-34.978155500793854, -8.307449442458463],
              [-34.97820149074726, -8.307042592033383],
              [-34.978295987267451, -8.306731177047416],
              [-34.978294309525133, -8.306396320138003],
              [-34.97817280464497, -8.306109896839024],
              [-34.977883444986475, -8.305872146114131],
              [-34.973478948433637, -8.303884813331624],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3315',
        NOME_UC1: 'PARQUE ESTADUAL SERRA DO AREAL',
        ID_WCMC2: null,
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2014',
        GID7: '12290',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 40550/14 de 28/03/2014',
        DT_ULTIM10: '15/03/2016',
        CODIGO_U11: '0000.26.3315',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.530435817654883, -8.972475392540403],
              [-40.527263735793959, -8.973582570458891],
              [-40.526126326402235, -8.970626854126643],
              [-40.522983080240913, -8.971845524441582],
              [-40.524876363867797, -8.974234898645099],
              [-40.526837289610796, -8.977079417342054],
              [-40.524012882630046, -8.978954480236375],
              [-40.524585618702396, -8.979983258942067],
              [-40.524416371739662, -8.980142614891466],
              [-40.51735959383219, -8.984750658578593],
              [-40.515180908057573, -8.981414625459951],
              [-40.517691532032778, -8.979753686077977],
              [-40.516091862354173, -8.977369516106496],
              [-40.514892036008042, -8.97816842752518],
              [-40.51087618137344, -8.972267099254578],
              [-40.513512888551531, -8.970511447520085],
              [-40.512696158283106, -8.969370426233485],
              [-40.511558137278975, -8.970421351284344],
              [-40.50918925204909, -8.967947946136585],
              [-40.508894043643075, -8.968465386951392],
              [-40.504934685932753, -8.968856131212755],
              [-40.497313469841039, -8.961431776348789],
              [-40.50078897623716, -8.956258650667753],
              [-40.498541655660915, -8.955445119261807],
              [-40.494864921088094, -8.959046365642036],
              [-40.492625895749235, -8.956797080354859],
              [-40.491676195542674, -8.957727260253669],
              [-40.490495570860546, -8.956535551935165],
              [-40.488250767548102, -8.958742103468747],
              [-40.481967357038087, -8.952513478069738],
              [-40.484265287016349, -8.950262867833953],
              [-40.482497202784415, -8.948684404181652],
              [-40.481070850050067, -8.947234665106704],
              [-40.478835628704047, -8.949408922829054],
              [-40.47628919075995, -8.946884513832877],
              [-40.477479695494694, -8.945717974485717],
              [-40.475094228349974, -8.944389528874195],
              [-40.469286740163334, -8.938548323737622],
              [-40.461766325289631, -8.944561623052655],
              [-40.461336877982149, -8.94490355273753],
              [-40.46114737235807, -8.944845578896869],
              [-40.460949727791267, -8.944787172051464],
              [-40.460759024170578, -8.944734812177977],
              [-40.460579034466811, -8.944691523262804],
              [-40.460412572658377, -8.944659619295219],
              [-40.460258634752634, -8.944638579277381],
              [-40.460115261764464, -8.944627176214389],
              [-40.459980492708624, -8.944624181111546],
              [-40.459852369599894, -8.944628364973983],
              [-40.45972893045311, -8.944638500806967],
              [-40.459608216283058, -8.944653357615652],
              [-40.459488268104494, -8.944671708405266],
              [-40.459366872934332, -8.944691964182853],
              [-40.459240810797809, -8.94471108896265],
              [-40.459106609722163, -8.944725690760821],
              [-40.458960796734779, -8.944732372593421],
              [-40.458799899862967, -8.944727739476605],
              [-40.458620444134006, -8.944708398426481],
              [-40.45841896057523, -8.944670953459104],
              [-40.458191974213925, -8.944612009590674],
              [-40.457937498065903, -8.944529478831155],
              [-40.457659479100734, -8.944426500166415],
              [-40.457363350276424, -8.944307521575993],
              [-40.457054542551141, -8.944176990039722],
              [-40.456738489882788, -8.944039352537152],
              [-40.456420623229597, -8.943899053048051],
              [-40.456106374549471, -8.943760541552033],
              [-40.45580117680052, -8.943628264028883],
              [-40.45550973194667, -8.943505824462314],
              [-40.455233817975241, -8.943393461852528],
              [-40.454974485879511, -8.943290570203979],
              [-40.454732783652602, -8.943196544520935],
              [-40.454509762287785, -8.943110782807739],
              [-40.454306470778214, -8.943032678068805],
              [-40.454123957117226, -8.942961627308419],
              [-40.453963272297869, -8.942897024530996],
              [-40.453824610319657, -8.942837972741764],
              [-40.453704758206925, -8.942782395949569],
              [-40.45359964599028, -8.942727927164251],
              [-40.453505203700153, -8.942672198395515],
              [-40.453417364367191, -8.942612840653117],
              [-40.453332056021935, -8.942547485946932],
              [-40.453245213694885, -8.942473766286705],
              [-40.453152764416664, -8.942389312682202],
              [-40.453051622210218, -8.942292575139472],
              [-40.452942620068214, -8.942185270649469],
              [-40.452827572975636, -8.942069938199531],
              [-40.452708296917521, -8.941949111776919],
              [-40.452586602878945, -8.941825327368861],
              [-40.452464307844998, -8.941701121962664],
              [-40.452343225800632, -8.941579030545638],
              [-40.452225169730958, -8.941461590104984],
              [-40.45211168062329, -8.941350936629968],
              [-40.452003182474137, -8.941247609117962],
              [-40.451899826282364, -8.941151752568327],
              [-40.451801764046778, -8.94106350198032],
              [-40.451709145766202, -8.940983002353372],
              [-40.451622120439438, -8.940910392686721],
              [-40.451540840065327, -8.940845812979752],
              [-40.451465453642705, -8.94078940523185],
              [-40.451395886172271, -8.940740963444],
              [-40.451331153662331, -8.940698895624459],
              [-40.451270046123064, -8.940661264783021],
              [-40.451211354564698, -8.940626130929571],
              [-40.451153867997405, -8.94059155907404],
              [-40.45109637843133, -8.940555608226335],
              [-40.451037672876765, -8.94051634339627],
              [-40.450976544343824, -8.940471823593761],
              [-40.45091122984671, -8.940420041828665],
              [-40.450837760415105, -8.940358702110345],
              [-40.450751612082833, -8.940285437448182],
              [-40.450648263883522, -8.940197882851606],
              [-40.450523194850994, -8.940093670329965],
              [-40.450371883018867, -8.939970433892581],
              [-40.45018980742087, -8.939825806548914],
              [-40.449972444090811, -8.939657422308274],
              [-40.449717661044154, -8.939464523173068],
              [-40.449432874224094, -8.939252792117722],
              [-40.449127889555513, -8.939029519109768],
              [-40.448812509963382, -8.938801993116677],
              [-40.448496541372613, -8.938577508105894],
              [-40.448189788708191, -8.938363353044839],
              [-40.447902054895096, -8.938166820901024],
              [-40.447643146858184, -8.937995199641939],
              [-40.447420427541161, -8.937853944243205],
              [-40.447231509962378, -8.937741147713531],
              [-40.447071567159028, -8.937653071069752],
              [-40.446935770168061, -8.937585969328753],
              [-40.446819292026696, -8.937536099507437],
              [-40.446717305771948, -8.93749971862265],
              [-40.446624982440952, -8.937473083691366],
              [-40.446537496070711, -8.937452452730406],
              [-40.446450621693856, -8.937434436755174],
              [-40.446362545324867, -8.937417059775262],
              [-40.446272059973602, -8.937398703798763],
              [-40.446177954649933, -8.937377748833724],
              [-40.446079018363783, -8.937352573888331],
              [-40.445974043125119, -8.937321557970543],
              [-40.445861816943804, -8.937283083088554],
              [-40.445741133829713, -8.93723552825044],
              [-40.44561151078706, -8.93717799146086],
              [-40.445475387797025, -8.937112443710799],
              [-40.445335934835072, -8.937041575987861],
              [-40.445196318876583, -8.936968077279699],
              [-40.44505970889702, -8.936894637573872],
              [-40.444929274871846, -8.936823945857968],
              [-40.444808182776569, -8.93675869211962],
              [-40.444699604586475, -8.936701567346375],
              [-40.444605852283701, -8.936654633528645],
              [-40.44452582287628, -8.936617448667958],
              [-40.444457557378833, -8.936588942768642],
              [-40.444399094806123, -8.936568044834962],
              [-40.444348477172646, -8.936553685871276],
              [-40.444303745493194, -8.93654479688184],
              [-40.444262941782341, -8.936540308871001],
              [-40.444224105054708, -8.936539150843043],
              [-40.444185629322405, -8.936540435801668],
              [-40.444147313586932, -8.936544017747366],
              [-40.444109306847182, -8.936549929680185],
              [-40.444071761102045, -8.936558205600051],
              [-40.444034826350574, -8.936568880506874],
              [-40.443998652591539, -8.936581990400589],
              [-40.443963392823925, -8.936597569281133],
              [-40.443929195046657, -8.936615651148475],
              [-40.443896209258625, -8.936636260002595],
              [-40.443864589458784, -8.936659372843767],
              [-40.443834483646178, -8.93668495067236],
              [-40.443806043819748, -8.936712959488665],
              [-40.443779419978505, -8.936743363292997],
              [-40.443754764121358, -8.936776126085801],
              [-40.443732226247334, -8.936811210867287],
              [-40.443711958355379, -8.93684858463785],
              [-40.443694234443555, -8.936888266397649],
              [-40.443679834506234, -8.93693051414586],
              [-40.44366965553688, -8.936975642881521],
              [-40.443664605528753, -8.93702396460367],
              [-40.443665581475408, -8.937075795311328],
              [-40.44367348937017, -8.9371314490035],
              [-40.443689229206484, -8.937191239679239],
              [-40.443713702977767, -8.937255483337516],
              [-40.443747562679185, -8.93732456697678],
              [-40.443790462312407, -8.937399179592754],
              [-40.44384180288106, -8.937480081180643],
              [-40.443900986388549, -8.937568034735669],
              [-40.443967417838358, -8.937663800252853],
              [-40.444040496234038, -8.937768141727513],
              [-40.444119627579042, -8.937881819154684],
              [-40.444204210876862, -8.938005595529582],
              [-40.444293423133182, -8.938139603850672],
              [-40.444385524362254, -8.938281459130007],
              [-40.444478548580548, -8.938428148382805],
              [-40.444570528804434, -8.93857666262433],
              [-40.44465949805052, -8.938723986869933],
              [-40.444743490335071, -8.938867110134925],
              [-40.444820538674676, -8.939003018434521],
              [-40.444888675085679, -8.939128701784075],
              [-40.444947420572568, -8.939243018189577],
              [-40.445002238091341, -8.939352316619956],
              [-40.445060078586152, -8.939464817034924],
              [-40.445127890001025, -8.939588740394182],
              [-40.445212624280053, -8.939732307657318],
              [-40.445321229367174, -8.939903738784043],
              [-40.445460657206567, -8.94011125473401],
              [-40.445637854742259, -8.940363075466939],
              [-40.445857129939654, -8.940664161958608],
              [-40.446112208849925, -8.941006434249109],
              [-40.446394173545471, -8.941378554394701],
              [-40.446694105098857, -8.941769183451626],
              [-40.447003087582551, -8.942166982476087],
              [-40.447312200069064, -8.942560611524231],
              [-40.447612525630944, -8.942938734652357],
              [-40.44789514734056, -8.943290010916687],
              [-40.44815323425366, -8.943605637360848],
              [-40.448388324358291, -8.943886948978641],
              [-40.448604042625583, -8.944137819751489],
              [-40.44880401302683, -8.944362118660562],
              [-40.448991863533145, -8.944563717687334],
              [-40.449171219115712, -8.944746488813045],
              [-40.449345705745777, -8.944914303019013],
              [-40.449518949394466, -8.945071029286584],
              [-40.449694172036232, -8.945220045599553],
              [-40.449872983658679, -8.945362742951431],
              [-40.450056589252455, -8.94550001633826],
              [-40.450246194808372, -8.945632763756029],
              [-40.450443006317229, -8.94576188020072],
              [-40.450648228769793, -8.945888260668426],
              [-40.450863069156782, -8.946012801155048],
              [-40.451088731468992, -8.946136399656652],
              [-40.451325595703381, -8.946259580170562],
              [-40.45157072388124, -8.94638138569959],
              [-40.451820352030076, -8.946500489247953],
              [-40.452070714177445, -8.946615562819753],
              [-40.452318048350797, -8.946725278419155],
              [-40.452558587577592, -8.946828311050339],
              [-40.452788568885374, -8.946923330717446],
              [-40.45300422630163, -8.947009010424622],
              [-40.453202799846395, -8.947084541174018],
              [-40.453385549509704, -8.94715117895967],
              [-40.453554735274146, -8.947210699773457],
              [-40.45371262212236, -8.947264878607461],
              [-40.45386147203692, -8.94731549045358],
              [-40.454003548000436, -8.947364309303858],
              [-40.454141111995455, -8.947413110150185],
              [-40.454276428004626, -8.947463668984572],
              [-40.454411581011811, -8.947517712799089],
              [-40.454547959005872, -8.947576785586335],
              [-40.454686768977105, -8.947642383338803],
              [-40.454829219915659, -8.947716002049132],
              [-40.454976523811823, -8.947799137709993],
              [-40.455129887655666, -8.947893288313942],
              [-40.455290520437565, -8.947999948853655],
              [-40.455459633147527, -8.948120617321694],
              [-40.455637729781643, -8.948255869715251],
              [-40.455822489358908, -8.948402611049801],
              [-40.456010887904021, -8.948556825345268],
              [-40.456199903441664, -8.948714499621801],
              [-40.456386509996712, -8.948871616899334],
              [-40.456567683593754, -8.94902416519788],
              [-40.45674039925764, -8.94916812653744],
              [-40.456901633013089, -8.949299487938054],
              [-40.457049544875588, -8.949415323414643],
              [-40.457187019823628, -8.94951705196169],
              [-40.45731813182659, -8.949607185568661],
              [-40.457446951853761, -8.949688234224972],
              [-40.457577549874387, -8.949762704920049],
              [-40.45771399885782, -8.949833107643325],
              [-40.457860368773304, -8.949901952384227],
              [-40.458020731590217, -8.949971749132192],
              [-40.45819900927868, -8.950045213875381],
              [-40.458398522812267, -8.950125887596691],
              [-40.458460012360071, -8.95015105150912],
              [-40.45970701780805, -8.951085235336452],
              [-40.461893233061843, -8.952722998022116],
              [-40.463107491760702, -8.953632622960177],
              [-40.463583099119468, -8.953986863380859],
              [-40.464045466850074, -8.954030761544145],
              [-40.464303333536854, -8.953488484839088],
              [-40.464575908446093, -8.953695871909257],
              [-40.464841886394687, -8.953910742931306],
              [-40.465069602627963, -8.954106322028387],
              [-40.465263576113607, -8.954283362200201],
              [-40.465428324819435, -8.954442614446586],
              [-40.465568370713122, -8.954584834767321],
              [-40.465688232762417, -8.95471077516205],
              [-40.465792242936168, -8.954821545628501],
              [-40.465883990206912, -8.954919685155383],
              [-40.465966872548371, -8.955008088729432],
              [-40.46604429293415, -8.955089650337291],
              [-40.466119651337863, -8.955167265965628],
              [-40.466196346733192, -8.955243830601123],
              [-40.466277780093748, -8.955322237230394],
              [-40.466367354393185, -8.95540538184016],
              [-40.466467854609903, -8.955495657419306],
              [-40.466579626741108, -8.955593442966025],
              [-40.466702402788798, -8.955698613480859],
              [-40.46683591175492, -8.955811046964183],
              [-40.466979888641582, -8.955930619416467],
              [-40.467134063450615, -8.956057206838178],
              [-40.467298169184104, -8.956190686229771],
              [-40.467471935844038, -8.956330933591703],
              [-40.467654985433427, -8.956477511926131],
              [-40.467846492959538, -8.956628733241864],
              [-40.468045522430693, -8.95678259354939],
              [-40.468251140855315, -8.956937090859222],
              [-40.468462409241695, -8.957090221181778],
              [-40.46867839359814, -8.957239982527568],
              [-40.468898159932976, -8.957384372907109],
              [-40.469120769254666, -8.957521388330941],
              [-40.469345381570214, -8.957649631806085],
              [-40.469571513882165, -8.957770111326438],
              [-40.469798782191702, -8.957884442882602],
              [-40.470026797500104, -8.957994239465055],
              [-40.470255171808638, -8.958101117064336],
              [-40.470483520118584, -8.958206687670998],
              [-40.470711454431097, -8.958312567275582],
              [-40.470938586747486, -8.958420369868549],
              [-40.471164971065768, -8.958531888439831],
              [-40.47139243437087, -8.958649635976947],
              [-40.471623243644586, -8.958776307466541],
              [-40.471859664868695, -8.958914593895566],
              [-40.472103965024843, -8.959067190250664],
              [-40.472358413094803, -8.959236787518794],
              [-40.47262527506026, -8.959426080686663],
              [-40.472906817903052, -8.959637761741151],
              [-40.473203823616863, -8.959872756677598],
              [-40.473511127243199, -8.960124929526224],
              [-40.473822081835742, -8.960386378325664],
              [-40.474130034447988, -8.960649198114764],
              [-40.47442833513356, -8.96090548493212],
              [-40.47471033694594, -8.961147338816611],
              [-40.474969386938824, -8.961366853806918],
              [-40.475198838165625, -8.961556127941773],
              [-40.475393875665269, -8.961709394249409],
              [-40.475557045417425, -8.961829427716498],
              [-40.475692728387088, -8.961921136319148],
              [-40.47580530653925, -8.96198943103353],
              [-40.475899161838832, -8.962039220835784],
              [-40.475978677250858, -8.96207541670209],
              [-40.476048233740272, -8.962102928608564],
              [-40.476112214271993, -8.962126664531445],
              [-40.476174747813154, -8.962151143448819],
              [-40.476238953339326, -8.962179305346922],
              [-40.476307698828187, -8.962213702213781],
              [-40.476383848257392, -8.96225688103762],
              [-40.476470270604679, -8.962311392806608],
              [-40.476569831847662, -8.962379785508841],
              [-40.476685398964008, -8.962464610132482],
              [-40.476819838931462, -8.962568415665723],
              [-40.476975513731972, -8.962692925100848],
              [-40.477152767364473, -8.962836562447153],
              [-40.47735144283233, -8.962996927718015],
              [-40.477571380138784, -8.963171617926946],
              [-40.478660570815819, -8.963965616411112],
              [-40.478984134366442, -8.964175869511509],
              [-40.479326195788204, -8.964385747630514],
              [-40.479684791097263, -8.964592555781573],
              [-40.480057954309757, -8.964793602978236],
              [-40.480443715441957, -8.964986192233964],
              [-40.480840109509899, -8.965167629562131],
              [-40.481245169529849, -8.96533522097632],
              [-40.481656927517932, -8.965486273489885],
              [-40.482072634495189, -8.965618730112087],
              [-40.482486415502244, -8.965733094834798],
              [-40.482891608584595, -8.965830510645635],
              [-40.483281557787635, -8.965912119532183],
              [-40.483649604156938, -8.965979061482065],
              [-40.483989086737871, -8.966032482482905],
              [-40.484293350575953, -8.966073522522269],
              [-40.484555733716654, -8.966103323587792],
              [-40.484771589191347, -8.966123101668503],
              [-40.484944311974942, -8.966134356758896],
              [-40.485079306028297, -8.966138660854881],
              [-40.48518197931223, -8.966137586952522],
              [-40.485257733787599, -8.96613270904758],
              [-40.485311978415176, -8.966125598136106],
              [-40.485350115155846, -8.96611782721399],
              [-40.485377550970483, -8.966110969277185],
              [-40.485398977825056, -8.966106261322935],
              [-40.485416235706936, -8.966103610353249],
              [-40.485430453608565, -8.966102585371752],
              [-40.485442759522449, -8.966102758381735],
              [-40.485454278441154, -8.966103697386719],
              [-40.485466142357183, -8.966104975390028],
              [-40.485479474262988, -8.966106161395235],
              [-40.485495405151148, -8.966106827405678],
              [-40.485514771016064, -8.966106656423898],
              [-40.485537243859902, -8.966105787448821],
              [-40.485562204686779, -8.966104473478522],
              [-40.485589035500716, -8.966102966510975],
              [-40.485617117305942, -8.966101520544122],
              [-40.485645831106432, -8.966100385576139],
              [-40.485674557906357, -8.966099815604972],
              [-40.485702679709682, -8.966100063628634],
              [-40.485729685519999, -8.96610131064566],
              [-40.485755491337834, -8.96610346665654],
              [-40.485780118163248, -8.966106366662078],
              [-40.485803591996174, -8.966109849663326],
              [-40.485825933836594, -8.966113752661187],
              [-40.485847168684565, -8.966117913656605],
              [-40.485867317540027, -8.966122170650516],
              [-40.485886405402944, -8.966126358643868],
              [-40.485904496272994, -8.966130381637299],
              [-40.485921822148363, -8.966134383630108],
              [-40.485938658026996, -8.96613857662148],
              [-40.485955276906793, -8.966143170610303],
              [-40.485971952785675, -8.9661483755958],
              [-40.485988959661469, -8.966154401576912],
              [-40.486006572532055, -8.966161458552754],
              [-40.486025062395449, -8.966169757522353],
              [-40.486044595250334, -8.966179420485139],
              [-40.486064885098891, -8.966190232442177],
              [-40.486085536944152, -8.966201886394742],
              [-40.486106155789159, -8.966214081344242],
              [-40.486126347636954, -8.966226511291991],
              [-40.486145714490547, -8.96623887423941],
              [-40.486163862353038, -8.966250864187877],
              [-40.486180397227351, -8.966262179138692],
              [-40.486195044115647, -8.966272619092814],
              [-40.486208021016182, -8.966282405049061],
              [-40.486219665926349, -8.966291864006021],
              [-40.486230315843436, -8.966301318962099],
              [-40.486240312764807, -8.966311097915751],
              [-40.48624999268781, -8.966321526865508],
              [-40.486259694609771, -8.966332930809694],
              [-40.486269757528035, -8.966345634746933],
              [-40.486280443440585, -8.966359843676162],
              [-40.486291698348076, -8.966375266599105],
              [-40.486303395251745, -8.96639149351787],
              [-40.486315405152865, -8.966408114434671],
              [-40.486327598052782, -8.966424717351751],
              [-40.486339843952656, -8.966440891271294],
              [-40.486352016853829, -8.966456225195532],
              [-40.486363984757574, -8.966470307126604],
              [-40.486375634664888, -8.966482839066224],
              [-40.486386914576094, -8.966493975013382],
              [-40.486397784491153, -8.966503977966566],
              [-40.486408208410097, -8.966513113924208],
              [-40.486418146333008, -8.966521647884875],
              [-40.486427562259863, -8.966529844846965],
              [-40.486436417190674, -8.966537970808968],
              [-40.486444674125501, -8.966546289769351],
              [-40.486452332064147, -8.966555048726663],
              [-40.486459542005377, -8.966564417680164],
              [-40.486466491947695, -8.966574546629172],
              [-40.486473370889662, -8.966585589572897],
              [-40.486480366829909, -8.966597694510744],
              [-40.486487669766916, -8.966611016442029],
              [-40.486495466699189, -8.966625703365988],
              [-40.486503946625369, -8.966641909282],
              [-40.486513218544594, -8.966659672189937],
              [-40.486523074458731, -8.966678590091881],
              [-40.48653322537016, -8.966698148990387],
              [-40.486543382281504, -8.966717834888268],
              [-40.486553258195109, -8.966737132788044],
              [-40.486562565113552, -8.966755528692453],
              [-40.486571015039218, -8.966772508604059],
              [-40.486578317974605, -8.966787560525525],
              [-40.486584255921628, -8.966800347458586],
              [-40.486588892879411, -8.966811261401041],
              [-40.486592353846582, -8.966820872349828],
              [-40.486594771821693, -8.96682975030185],
              [-40.486596276803333, -8.966838467253963],
              [-40.486596997790052, -8.96684759320306],
              [-40.48659706578043, -8.96685769814604],
              [-40.486596612773099, -8.966869352079797],
              [-40.486595742766859, -8.966882993001967],
              [-40.486594482761681, -8.96689851891311],
              [-40.486592831757733, -8.96691569581462],
              [-40.486590790755201, -8.966934287707785],
              [-40.48658836075434, -8.966954059593899],
              [-40.486585541755304, -8.966974777474377],
              [-40.486582335758392, -8.966996206350478],
              [-40.486578744763726, -8.96701811022354],
              [-40.486574814771146, -8.967040329094475],
              [-40.486570783778845, -8.96706299896284],
              [-40.486566935784673, -8.967086328827596],
              [-40.486563554786457, -8.967110529687901],
              [-40.486560926781998, -8.967135811542711],
              [-40.486559333769129, -8.967162382391226],
              [-40.486559060745755, -8.967190455232405],
              [-40.486560392709535, -8.967220238065353],
              [-40.486563576658796, -8.967251889889354],
              [-40.486568715592718, -8.967285361704896],
              [-40.486575871510979, -8.967320554512446],
              [-40.486585114413252, -8.96735736731274],
              [-40.486596506299101, -8.96739570110636],
              [-40.486610113168247, -8.967435454893923],
              [-40.486626001020241, -8.96747652967607],
              [-40.486644234854751, -8.967518824453366],
              [-40.486665145669363, -8.967562433225709],
              [-40.486690126453645, -8.967608213989354],
              [-40.486720835194966, -8.967657221739856],
              [-40.486758929880907, -8.967710509472809],
              [-40.486806070498808, -8.967769129183708],
              [-40.486863913036196, -8.967834134868072],
              [-40.486934119480594, -8.967906578521461],
              [-40.487018343819372, -8.967987512139359],
              [-40.48711804704147, -8.968078038716889],
              [-40.487233886141013, -8.968179451247359],
              [-40.487366317113668, -8.968293087723508],
              [-40.48751579695508, -8.968420289138317],
              [-40.487682782660755, -8.968562395484488],
              [-40.487867730226291, -8.968720748754933],
              [-40.48807109664741, -8.968896687942435],
              [-40.48829333891959, -8.969091553039929],
              [-40.488533832047082, -8.969305545045646],
              [-40.488787620068393, -8.969534299979875],
              [-40.489048663030694, -8.969772314868305],
              [-40.489310924981105, -8.970014086736567],
              [-40.489568365966754, -8.970254111610476],
              [-40.489814948034734, -8.970486885515614],
              [-40.490044633232252, -8.970706907477755],
              [-40.490251383606392, -8.970908671522565],
              [-40.490430820191293, -8.971088284668198],
              [-40.490585210968717, -8.971248289902187],
              [-40.490718483907607, -8.971392838204626],
              [-40.490834566976737, -8.971526082555643],
              [-40.490937387144911, -8.971652175935192],
              [-40.491030874380982, -8.97177526932337],
              [-40.491118954653778, -8.971899513700217],
              [-40.491205556932094, -8.972029062045808],
              [-40.49129372819192, -8.972166982345485],
              [-40.491382984437763, -8.972311996606081],
              [-40.491471962681253, -8.972461744839716],
              [-40.491559297934053, -8.972613867058502],
              [-40.491643628207882, -8.972766001274527],
              [-40.491723587514322, -8.97291578649993],
              [-40.491797813864999, -8.973060860746799],
              [-40.491864941271686, -8.973198866027268],
              [-40.491924209741171, -8.973328101350292],
              [-40.491977279260951, -8.973449520711938],
              [-40.492026410813864, -8.973564738105036],
              [-40.492073868382583, -8.973675372522564],
              [-40.49212191194988, -8.973783035957432],
              [-40.49217280649853, -8.973889346402421],
              [-40.492228812011248, -8.973995918850505],
              [-40.492292192470721, -8.974104369294604],
              [-40.492364583864344, -8.974216143727952],
              [-40.492445111197355, -8.97433201814542],
              [-40.492532272479849, -8.974452599542246],
              [-40.492624567721734, -8.974578491913601],
              [-40.492720494932811, -8.974710304254801],
              [-40.492818553123207, -8.974848642561113],
              [-40.492917241302692, -8.974994112827664],
              [-40.493015058481333, -8.975147322049748],
              [-40.493112510654093, -8.975309793219196],
              [-40.493218144756725, -8.975486712314369],
              [-40.49334250970999, -8.97568418331017],
              [-40.493496161434642, -8.975908307181633],
              [-40.49368965085155, -8.97616519190372],
              [-40.493933531881467, -8.976460935451284],
              [-40.494238355445205, -8.976801642799405],
              [-40.494614676463577, -8.977193416923042],
              [-40.495067772897634, -8.977638545813962],
              [-40.495581830869504, -8.978124053531415],
              [-40.49613576154168, -8.978633152151291],
              [-40.496708476076506, -8.979149047749686],
              [-40.497278884636444, -8.979654954402488],
              [-40.497825897383997, -8.980134079185744],
              [-40.498328427481432, -8.980569636175375],
              [-40.498765383091353, -8.980944833447229],
              [-40.499121665330151, -8.981247441056963],
              [-40.499406140130496, -8.981483465978478],
              [-40.499633662379175, -8.981663472165463],
              [-40.499819089962834, -8.981798024571452],
              [-40.499977278768185, -8.981897687150049],
              [-40.500123084682052, -8.981973023854961],
              [-40.500271362591022, -8.98203460163981],
              [-40.500436971381923, -8.982092984458092],
              [-40.500630819970922, -8.982156586272199],
              [-40.500848017392293, -8.982225234078696],
              [-40.501079731709893, -8.982296601882744],
              [-40.501317125987455, -8.982368366689698],
              [-40.501551365288783, -8.982438204504689],
              [-40.501773614677717, -8.982503792332947],
              [-40.501894873798946, -8.982539318240754],
              [-40.503116488783022, -8.988607117079702],
              [-40.505191830963525, -8.998914729762358],
              [-40.505720837642251, -8.998503504556368],
              [-40.50841636423894, -8.998043015567463],
              [-40.511654896125414, -8.997493981564087],
              [-40.511987956139862, -8.996014506210269],
              [-40.519405887304465, -8.992755809235305],
              [-40.522425582450161, -8.991402648572025],
              [-40.529792548059177, -8.988054313053921],
              [-40.530814953485937, -8.989642467008363],
              [-40.532928362975149, -8.989377742392579],
              [-40.533774754283527, -8.991348939029702],
              [-40.534239752131505, -8.991245889027134],
              [-40.536733268660271, -8.990217323060648],
              [-40.536802669201862, -8.990188698284195],
              [-40.530435817654883, -8.972475392540403],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3319',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATAS DE ÁGUA AZUL',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2014',
        GID7: '12327',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 40.551/14 de 28/03/2014',
        DT_ULTIM10: '24/04/2018',
        CODIGO_U11: '0000.26.3319',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.379431906667975, -7.58493041087084],
              [-35.367064027228281, -7.596043531490428],
              [-35.344924087496949, -7.583487431848621],
              [-35.333862832599422, -7.591295060173429],
              [-35.326582895482652, -7.58880816798793],
              [-35.321872423575428, -7.588786954966342],
              [-35.318794758358877, -7.593447738490508],
              [-35.319742035088126, -7.601191513261026],
              [-35.309313163765623, -7.608604013491874],
              [-35.308388382054879, -7.610974684913221],
              [-35.30935495489657, -7.61387734124921],
              [-35.322685434972271, -7.613341051589298],
              [-35.32518784384397, -7.617031215995906],
              [-35.322973176747624, -7.620864671329058],
              [-35.326548751946937, -7.625556410147464],
              [-35.375256778456048, -7.622382003960005],
              [-35.40507914932364, -7.627112594260834],
              [-35.437521170494378, -7.614563477174811],
              [-35.43818131919069, -7.60036470004966],
              [-35.424243742015285, -7.601604240863714],
              [-35.423072212949428, -7.59091203344945],
              [-35.404123830289336, -7.594791658269211],
              [-35.379431906667975, -7.58493041087084],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '9',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL COSTA DOS CORAIS',
        ID_WCMC2: '31752',
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '1997',
        GID7: '1827',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº s/n de 23/10/1997',
        DT_ULTIM10: '27/09/2007',
        CODIGO_U11: '0000.00.0009',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.331702444102163, -9.193850028272017],
              [-35.331430405058846, -9.193966537299371],
              [-35.331423397113028, -9.193965513299338],
              [-35.33085053953976, -9.193880693301313],
              [-35.330845696575224, -9.193882089288163],
              [-35.330438544555363, -9.19400085617773],
              [-35.330160055625775, -9.194047585632482],
              [-35.32985872586957, -9.194094338066146],
              [-35.329848085949862, -9.194094874053086],
              [-35.32925599641527, -9.194125793318779],
              [-35.328748658221045, -9.194174408552151],
              [-35.328496874979933, -9.19433843830295],
              [-35.328317594828398, -9.194890666709593],
              [-35.328275614660377, -9.195415165414229],
              [-35.328161178419293, -9.195533419575055],
              [-35.327997148579378, -9.195624992856164],
              [-35.327814027901233, -9.19569838123272],
              [-35.327581359640703, -9.195727041841595],
              [-35.327260914987193, -9.195819522973778],
              [-35.326890899684379, -9.195939663888792],
              [-35.326501792300725, -9.196303042275883],
              [-35.326551385471198, -9.196791313289316],
              [-35.326806963231149, -9.197114622515873],
              [-35.327157932527264, -9.197157508571149],
              [-35.327688182488217, -9.19717278396214],
              [-35.328176475785384, -9.197168964433233],
              [-35.328775340243247, -9.197165170005503],
              [-35.329191130089903, -9.197162306404254],
              [-35.329561168282922, -9.197160419755022],
              [-35.330026573736227, -9.197175671086805],
              [-35.330643227018157, -9.197216090400834],
              [-35.330652166949733, -9.197216695405292],
              [-35.331731013429874, -9.19649615286772],
              [-35.331731734424856, -9.196495663871419],
              [-35.33244887698612, -9.196490890558158],
              [-35.333064436334098, -9.196469308256207],
              [-35.333074518257853, -9.196468958267566],
              [-35.333536081908605, -9.196303042720643],
              [-35.333787842217305, -9.196066510419982],
              [-35.33392516541042, -9.195812863120681],
              [-35.333990033220815, -9.195486691205302],
              [-35.333799274179292, -9.194936441447036],
              [-35.333402553850512, -9.194224956501342],
              [-35.333146974889445, -9.19411815693034],
              [-35.332658704574968, -9.19414013634657],
              [-35.33219714011809, -9.194097178190509],
              [-35.331708824056271, -9.193847304294822],
              [-35.331702444102163, -9.193850028272017],
            ],
          ],
          [
            [
              [-35.083561262793204, -8.701893161565632],
              [-34.795678127844347, -8.794245597306345],
              [-34.845207796910259, -8.954703433350263],
              [-34.868343769160141, -9.009580814963472],
              [-34.940937024339775, -9.14297053500227],
              [-34.944255773666768, -9.148828935360436],
              [-34.944438871907153, -9.149225680044784],
              [-34.945781631528526, -9.151560299659167],
              [-35.011291050157396, -9.26526422750689],
              [-35.012591853275516, -9.268457132803544],
              [-35.031855963225489, -9.307335603393502],
              [-35.03800901127952, -9.315951118449034],
              [-35.082038002548245, -9.387549306223896],
              [-35.109705867879143, -9.425520876425429],
              [-35.110270412982473, -9.426150289050266],
              [-35.1132382391378, -9.429567293638623],
              [-35.160956042466474, -9.48348243146453],
              [-35.18242122032855, -9.513520355930901],
              [-35.220098839108964, -9.550448587488075],
              [-35.230970648933372, -9.558187703045297],
              [-35.243780311133044, -9.57823300857476],
              [-35.268903799253408, -9.612079884939929],
              [-35.275331541318806, -9.619340231372034],
              [-35.346910189737237, -9.697605558919191],
              [-35.38266900182937, -9.740493332405087],
              [-35.385404136859378, -9.743727238235348],
              [-35.417087545558893, -9.774713855226709],
              [-35.417092667515043, -9.774718861201031],
              [-35.615753805389545, -9.547426493107434],
              [-35.615238806363983, -9.546318310412204],
              [-35.614517844921544, -9.54415538099053],
              [-35.614494958252195, -9.54399325996317],
              [-35.614353791854974, -9.543442987203809],
              [-35.614216468771261, -9.542539858612868],
              [-35.61391129672738, -9.541874195404734],
              [-35.613381070570156, -9.54101970513989],
              [-35.613217041073696, -9.540749807638871],
              [-35.613079741454506, -9.540397906666511],
              [-35.612660063391054, -9.539614943068843],
              [-35.612404484990051, -9.538930187025684],
              [-35.611801779160942, -9.538302707299374],
              [-35.610600160720878, -9.537823936088159],
              [-35.609741876772709, -9.537251730778586],
              [-35.60925356007035, -9.536631818115639],
              [-35.608952206953717, -9.536010998636991],
              [-35.608605056341872, -9.535227056115666],
              [-35.608116831840313, -9.534399271728672],
              [-35.607468305401433, -9.533726926234873],
              [-35.606518471172031, -9.533128038002484],
              [-35.605522839147895, -9.532675042831251],
              [-35.604366949231498, -9.532330754839876],
              [-35.603718445318989, -9.532146702349918],
              [-35.602280364834826, -9.53149724296213],
              [-35.60084603222537, -9.530947924963579],
              [-35.599896176013104, -9.530331854837678],
              [-35.599362107236402, -9.530145892469072],
              [-35.598808971402534, -9.530167823806194],
              [-35.598799890470808, -9.530168336794349],
              [-35.597557760804492, -9.530240282166574],
              [-35.596100540746754, -9.530332808207033],
              [-35.596099167757117, -9.530332808205722],
              [-35.595219369411332, -9.530339491324575],
              [-35.594292399457863, -9.530309898621011],
              [-35.594283295526274, -9.530310410609163],
              [-35.593346336569525, -9.530362354395667],
              [-35.592350727925158, -9.530550226291663],
              [-35.592349191937643, -9.530549318295769],
              [-35.591259705816547, -9.529898858248423],
              [-35.59114529178715, -9.529791127800555],
              [-35.590775231128632, -9.529234148866404],
              [-35.590656976137595, -9.529116849473589],
              [-35.590519675665917, -9.528612373439495],
              [-35.590470082645631, -9.527988666221111],
              [-35.590470082908006, -9.527717836883735],
              [-35.590470082512219, -9.527094129712774],
              [-35.590561631554408, -9.526335007460609],
              [-35.590653180553147, -9.525620681933475],
              [-35.590744730754125, -9.524697530688488],
              [-35.590767640472819, -9.523776264366482],
              [-35.590790504165874, -9.522881727880492],
              [-35.590626429439467, -9.52181647926438],
              [-35.589932219203867, -9.520258164170244],
              [-35.589813965191148, -9.519129958984955],
              [-35.5898139647947, -9.51850627381461],
              [-35.58969571056403, -9.517603146247472],
              [-35.589466814047213, -9.516827795790373],
              [-35.589138755227594, -9.516107765899072],
              [-35.588837401996329, -9.515604244703876],
              [-35.588421612989364, -9.514730662672168],
              [-35.588028687722456, -9.513946745111735],
              [-35.587727333622084, -9.513307786748546],
              [-35.587284838510875, -9.512750831747297],
              [-35.586800363664914, -9.512248242372277],
              [-35.586426530701878, -9.512034596328153],
              [-35.585896281114898, -9.511622649352917],
              [-35.585175297153626, -9.511022783350217],
              [-35.584828169155308, -9.510636540391744],
              [-35.584690822351028, -9.510475374250783],
              [-35.584202551359553, -9.510152996765644],
              [-35.583973678334885, -9.509902214087941],
              [-35.583485409015417, -9.508884625873073],
              [-35.58306957347547, -9.508562295456855],
              [-35.582489777236844, -9.508177961265408],
              [-35.582073940740237, -9.507810763124873],
              [-35.581330092978789, -9.507183283270857],
              [-35.580334461514425, -9.506151399661514],
              [-35.579918671218316, -9.505576330798295],
              [-35.579846190923746, -9.505414187725355],
              [-35.579521950690228, -9.505091879396645],
              [-35.579220597660239, -9.50437943948649],
              [-35.579106137953403, -9.503937921089983],
              [-35.578965018641021, -9.503297031892915],
              [-35.578503408874035, -9.502533136146626],
              [-35.577832039485244, -9.501986681864878],
              [-35.577366657451137, -9.501528936234322],
              [-35.576882182429877, -9.501207535747946],
              [-35.576485461640111, -9.500993913682944],
              [-35.576046761273233, -9.500671559246044],
              [-35.576428231997923, -9.500036442511298],
              [-35.576321409920098, -9.499919119130546],
              [-35.576184085028032, -9.49984854843345],
              [-35.576031511313126, -9.49971408811448],
              [-35.575718703837325, -9.49955292280711],
              [-35.575253320688972, -9.499213408450451],
              [-35.574616270935309, -9.498775662534083],
              [-35.574093659142108, -9.498517220624928],
              [-35.573422268509511, -9.498223480791177],
              [-35.573075140309228, -9.498046110550867],
              [-35.572785218625242, -9.497921150042961],
              [-35.572613552028223, -9.497814350535974],
              [-35.572182488481019, -9.49761886532719],
              [-35.571728538403605, -9.497116252983965],
              [-35.571484415643276, -9.496710963242354],
              [-35.571297475336827, -9.496422951834401],
              [-35.570740567211914, -9.495741083495084],
              [-35.570137860181731, -9.495320474506725],
              [-35.569695295861521, -9.494980052178025],
              [-35.569195569938344, -9.494676742566853],
              [-35.568745461578644, -9.494436437615759],
              [-35.568096956764613, -9.494151289751596],
              [-35.566868657553727, -9.493645859690167],
              [-35.566300247045881, -9.493451306345586],
              [-35.565571649880788, -9.493122269675263],
              [-35.564560767087144, -9.492551019225472],
              [-35.563565135221261, -9.491934925065934],
              [-35.562985291922338, -9.491613571490108],
              [-35.56242833746763, -9.491274057047702],
              [-35.56152427778688, -9.490783878201599],
              [-35.560757495189819, -9.490165897271744],
              [-35.560398936120428, -9.489942729302912],
              [-35.560086127756989, -9.489665195711741],
              [-35.559563515129568, -9.489235085859212],
              [-35.558979901007326, -9.48876018022419],
              [-35.558644181799977, -9.488500737500186],
              [-35.55862893141186, -9.487985761651546],
              [-35.558674706248063, -9.487796003861565],
              [-35.558731912902161, -9.487705386472987],
              [-35.558789142660821, -9.487506060752766],
              [-35.558812030583951, -9.487405943389966],
              [-35.558812030731936, -9.487252391333936],
              [-35.558800597957315, -9.487108384208437],
              [-35.55871666187975, -9.48681084995787],
              [-35.558495402798108, -9.486559066295575],
              [-35.558079612944326, -9.48656192988307],
              [-35.557652392176159, -9.486565772453689],
              [-35.557339559621752, -9.486486586643402],
              [-35.557106869539325, -9.486325444413087],
              [-35.556919929232713, -9.486037409006423],
              [-35.556874153883548, -9.485721735903708],
              [-35.55682458507674, -9.484872997074808],
              [-35.556797855845168, -9.484285518661371],
              [-35.556736831012053, -9.483554033100885],
              [-35.556630031455683, -9.482895052051161],
              [-35.556603325249498, -9.482280889802007],
              [-35.55657664294759, -9.481765913943063],
              [-35.556549960636481, -9.481260461025617],
              [-35.556488888760249, -9.48057379618978],
              [-35.55644311447243, -9.480194258480068],
              [-35.556382089421348, -9.479688805530094],
              [-35.556240924098333, -9.479057461278291],
              [-35.556004437496327, -9.478427071930149],
              [-35.555645854827191, -9.477788136518843],
              [-35.555306339935619, -9.477230226627499],
              [-35.555050761217359, -9.47687066559609],
              [-35.554596811253013, -9.476249821983309],
              [-35.553990286598321, -9.475468767211135],
              [-35.553536336554899, -9.474929973094026],
              [-35.553151024053221, -9.47432721943548],
              [-35.552571228136351, -9.473608144307919],
              [-35.552094390317613, -9.473015915498065],
              [-35.551758694196813, -9.472665947332134],
              [-35.551384837226529, -9.472459915244755],
              [-35.55092327406647, -9.472102287006676],
              [-35.550145082363137, -9.471683633843799],
              [-35.549633902492559, -9.471415668007296],
              [-35.549332525972432, -9.471210613982823],
              [-35.549019740617616, -9.470923556451908],
              [-35.548821369240251, -9.470798643032145],
              [-35.548279687713574, -9.470413329888709],
              [-35.547791417677729, -9.470136797126798],
              [-35.547604501170547, -9.470056657442539],
              [-35.546944564624887, -9.469582682732533],
              [-35.546296060957744, -9.46914493681054],
              [-35.545678104172971, -9.468588959644908],
              [-35.544938050337763, -9.468007208522248],
              [-35.544262840812401, -9.467632444187842],
              [-35.543030698544307, -9.46617708697357],
              [-35.542241075396987, -9.465270187804927],
              [-35.541588730409678, -9.464155322046048],
              [-35.541050890531054, -9.463065254243388],
              [-35.540677057915232, -9.462490208427436],
              [-35.540211627897968, -9.462014301914875],
              [-35.539666128512813, -9.461511736490602],
              [-35.538704839568496, -9.460705884538914],
              [-35.538125043494389, -9.460149907411079],
              [-35.537472697160403, -9.459395534435551],
              [-35.537030178865052, -9.459028383275934],
              [-35.536496157358116, -9.458561999640393],
              [-35.535717965924214, -9.457862971205541],
              [-35.535256379773358, -9.457495774028422],
              [-35.534756653980956, -9.457056141260999],
              [-35.534523962854351, -9.456940774750796],
              [-35.534234041153155, -9.456833975133673],
              [-35.534096718243767, -9.456781494326744],
              [-35.533978463217402, -9.456700422713771],
              [-35.53361988007844, -9.456549781301543],
              [-35.532971376099098, -9.456437230380534],
              [-35.532965764140506, -9.456438395368066],
              [-35.532612816743971, -9.456511597583578],
              [-35.532105454444604, -9.456660399187692],
              [-35.531689617505968, -9.456752903224892],
              [-35.531205166085357, -9.456847293185561],
              [-35.530789377180255, -9.456904522439883],
              [-35.530788049190896, -9.456903916442338],
              [-35.53062916546267, -9.456832996728492],
              [-35.530095097964399, -9.456358068146168],
              [-35.529618260067458, -9.455846887841169],
              [-35.529210083660317, -9.455325253665553],
              [-35.528756180737808, -9.45465956533346],
              [-35.528462416663437, -9.453929990546181],
              [-35.528378504662406, -9.45355138279723],
              [-35.528298387589572, -9.453217619775854],
              [-35.528294569861892, -9.452963950333652],
              [-35.528363255052511, -9.452222942959729],
              [-35.528489146316524, -9.451995956476033],
              [-35.528622651852231, -9.451425636112843],
              [-35.528782840324794, -9.450710379667115],
              [-35.528954528614172, -9.450095263615841],
              [-35.528985052833882, -9.44962510753885],
              [-35.528958323634555, -9.449001399352909],
              [-35.528618787123158, -9.448045837914268],
              [-35.528409029238958, -9.447496496097694],
              [-35.528210611130731, -9.447091184405293],
              [-35.527768139008543, -9.446542821362851],
              [-35.527188320054798, -9.445860952012623],
              [-35.526524565711298, -9.44520578941888],
              [-35.526040090577197, -9.445001713217424],
              [-35.525689144683874, -9.444534373763068],
              [-35.525361085747782, -9.443930737169522],
              [-35.52505591343818, -9.443535878312261],
              [-35.524899520813086, -9.44333752938571],
              [-35.524979615829579, -9.442687116465985],
              [-35.525536570342183, -9.441923220695438],
              [-35.525536570575184, -9.441680053192647],
              [-35.525570889523586, -9.441462588564042],
              [-35.525639575341245, -9.441109755801367],
              [-35.525982883855576, -9.440989568865611],
              [-35.526398673847289, -9.440841744168544],
              [-35.526619933258871, -9.440750171941392],
              [-35.526745825487886, -9.440559459234505],
              [-35.526791599349451, -9.440342019616585],
              [-35.526776348836798, -9.43995388999195],
              [-35.526787756889263, -9.439808951895143],
              [-35.526993766745235, -9.439374047767521],
              [-35.527062451485257, -9.439102240505955],
              [-35.527131113268368, -9.438785637520256],
              [-35.527211206887273, -9.438550059046388],
              [-35.52727986948041, -9.438431827839247],
              [-35.527577426366925, -9.438284958024546],
              [-35.527680384795261, -9.438067493460796],
              [-35.527680385089369, -9.437760436351445],
              [-35.527691863141229, -9.437615498254731],
              [-35.527886416789784, -9.437487697225357],
              [-35.528012285027174, -9.437288370571604],
              [-35.528172520030722, -9.437061384120545],
              [-35.528241158728136, -9.436834420582855],
              [-35.528241158047848, -9.436500609638298],
              [-35.528332731397988, -9.436454836006609],
              [-35.528479670439992, -9.436292832142891],
              [-35.528481486428191, -9.436290806157077],
              [-35.528611220420764, -9.436316557121016],
              [-35.528702769597508, -9.436451995373513],
              [-35.528737065199856, -9.436595977519314],
              [-35.528798113253238, -9.437101430464656],
              [-35.528996462465727, -9.437398988819755],
              [-35.529256860419196, -9.43747617259042],
              [-35.529263496366987, -9.437478150584512],
              [-35.529389387518407, -9.43736846537878],
              [-35.529480959972645, -9.437213981416454],
              [-35.529458050249438, -9.437106204058411],
              [-35.529442799650901, -9.436807690882068],
              [-35.529362704645742, -9.436402425301809],
              [-35.529187197225546, -9.436141118745075],
              [-35.528965984133301, -9.435898858027963],
              [-35.528756180894355, -9.435719555933909],
              [-35.5285844912543, -9.435657599153361],
              [-35.528580975278452, -9.435660207133971],
              [-35.528351799850768, -9.435831174864868],
              [-35.528342905918123, -9.435831243856052],
              [-35.528100038756186, -9.435833037615671],
              [-35.527867348518001, -9.435833992390128],
              [-35.5278627385525, -9.435834480382782],
              [-35.527684225886034, -9.435854062093664],
              [-35.527451535630547, -9.435873107756681],
              [-35.527325666531134, -9.435928404297368],
              [-35.527173069625, -9.435993294753748],
              [-35.527035746561175, -9.436101980954808],
              [-35.526898398479283, -9.4362297810382],
              [-35.526795417171762, -9.43632133037722],
              [-35.526646639116208, -9.436512066062285],
              [-35.526459722297496, -9.436757167376555],
              [-35.526253713631618, -9.436993699725573],
              [-35.525956201572207, -9.4373207814306],
              [-35.525658620357099, -9.437810960131305],
              [-35.525521296188224, -9.438029355656816],
              [-35.525311492430532, -9.438391756227194],
              [-35.525139849496121, -9.438636857555878],
              [-35.524899522055534, -9.438909595649552],
              [-35.524716423259022, -9.439101286296289],
              [-35.524506619708674, -9.439247224199535],
              [-35.524266290458158, -9.439321590514686],
              [-35.52426149449542, -9.439320636516003],
              [-35.524045008176543, -9.439277748575646],
              [-35.524043145188777, -9.439279751561577],
              [-35.523907684077194, -9.439423640547652],
              [-35.523781815744812, -9.439722153590674],
              [-35.523587261940101, -9.440013029615846],
              [-35.523484258555094, -9.440185650455634],
              [-35.523449939520908, -9.440492730532386],
              [-35.523533897555929, -9.440836039497777],
              [-35.523625376595163, -9.441115459863685],
              [-35.523732223561836, -9.441349129525873],
              [-35.524045008665851, -9.441898424439126],
              [-35.52404887348122, -9.442060545444573],
              [-35.524701172565251, -9.443077177801307],
              [-35.524670252830937, -9.443044348974201],
              [-35.524304404975119, -9.442655637021897],
              [-35.524117488485295, -9.442557429449995],
              [-35.523842864598734, -9.442523086402037],
              [-35.523598718506911, -9.442462038547253],
              [-35.523018852190589, -9.442158775866782],
              [-35.522393258255477, -9.441819262366337],
              [-35.521756210379749, -9.441508362678977],
              [-35.520966562755142, -9.44109830045808],
              [-35.520146439361497, -9.440688216208709],
              [-35.519646714291056, -9.440538504658663],
              [-35.51847939324022, -9.440428819231728],
              [-35.517830912157137, -9.440424999642822],
              [-35.517825487197733, -9.440425535634416],
              [-35.51706797186359, -9.440502276446468],
              [-35.516537721825436, -9.440560414587747],
              [-35.516531319873785, -9.440560577580685],
              [-35.516030383657522, -9.440572824032248],
              [-35.515439133132794, -9.44057757344464],
              [-35.514885972320307, -9.440581392898787],
              [-35.514237469298813, -9.440513685703408],
              [-35.513611875216135, -9.440328678252023],
              [-35.513020601090837, -9.439916683230996],
              [-35.512623879379909, -9.439621057677051],
              [-35.512230977691239, -9.43927199745586],
              [-35.511811323448171, -9.438669290771671],
              [-35.511265846399574, -9.437814801519492],
              [-35.51054106787285, -9.436789600149732],
              [-35.510308352110016, -9.436294647978622],
              [-35.510285464672378, -9.435888357459545],
              [-35.510468563589008, -9.435570776588415],
              [-35.510617342670727, -9.435352404073877],
              [-35.510670753707849, -9.434890816967359],
              [-35.510579181754942, -9.434521733154346],
              [-35.510052750613589, -9.433612900255717],
              [-35.509785740937957, -9.433298181942371],
              [-35.509438611972534, -9.432875687217383],
              [-35.509030436546304, -9.432373121928082],
              [-35.508599373294373, -9.431869578623283],
              [-35.508240789432129, -9.431429923993365],
              [-35.507611354811949, -9.430791920329922],
              [-35.507123085060975, -9.430217828406061],
              [-35.50681027685274, -9.429777195825613],
              [-35.50658904080462, -9.429489207391152],
              [-35.506367780842417, -9.429111531509225],
              [-35.50611217933929, -9.428525985875606],
              [-35.50585280495951, -9.427841252849547],
              [-35.505559088909244, -9.427084016237965],
              [-35.505128025906991, -9.426319166543907],
              [-35.504452816306205, -9.424977361174708],
              [-35.504147667411665, -9.424147667999245],
              [-35.503796674983775, -9.423193037550607],
              [-35.503563984246512, -9.422670425551532],
              [-35.503354179292906, -9.422192632297957],
              [-35.503270267282403, -9.421822594499037],
              [-35.503014688649195, -9.421372462031966],
              [-35.502919297647246, -9.421084450716851],
              [-35.502766724156928, -9.420714435853172],
              [-35.502762928988446, -9.419874241027092],
              [-35.502831590969244, -9.419349720324007],
              [-35.502884978858063, -9.419042638266658],
              [-35.5028544554091, -9.418707921300543],
              [-35.503064281966736, -9.418553390450445],
              [-35.503353783913141, -9.418406707627025],
              [-35.503354179910332, -9.418406520628592],
              [-35.503678443464075, -9.418395089004438],
              [-35.504017934867925, -9.418419862171534],
              [-35.504349812294869, -9.418480887108043],
              [-35.504651187910071, -9.418586779739377],
              [-35.504952541550949, -9.418665918535531],
              [-35.505325652717509, -9.418672460846647],
              [-35.505334011654028, -9.418672600853652],
              [-35.505543792169213, -9.418562959726893],
              [-35.5057421878399, -9.41838077003643],
              [-35.50586044307412, -9.418244400988131],
              [-35.506001585109416, -9.418134737796859],
              [-35.506119839335469, -9.418006936695765],
              [-35.50617706898835, -9.417916342307967],
              [-35.506272437438803, -9.417735153514281],
              [-35.50634494021832, -9.417390866704144],
              [-35.506405966041015, -9.417092353601133],
              [-35.506482240929479, -9.416604059681982],
              [-35.506528015359528, -9.415790617737859],
              [-35.506585221746683, -9.414931332087086],
              [-35.506711114544018, -9.414144550054209],
              [-35.50667295350614, -9.413439793361476],
              [-35.506623382641102, -9.412644420216573],
              [-35.50647078644284, -9.411968255240049],
              [-35.506363985993104, -9.411191972923762],
              [-35.506264776373804, -9.410532991891802],
              [-35.506180864569437, -9.409946467427726],
              [-35.506058815097632, -9.4093141912098],
              [-35.50590624077882, -9.408763918457774],
              [-35.505723118595903, -9.408313786059864],
              [-35.505372149908609, -9.407629053950021],
              [-35.505215756540366, -9.407160783689188],
              [-35.504990678753515, -9.406628625757529],
              [-35.504742737112608, -9.406125081628083],
              [-35.504517660273798, -9.405647312361305],
              [-35.504220125069565, -9.405079857579301],
              [-35.503937840568703, -9.404702205641676],
              [-35.503846291485196, -9.404468558995877],
              [-35.50377760716939, -9.40429689298945],
              [-35.503686080939296, -9.404216798397112],
              [-35.503411433328687, -9.403893513131646],
              [-35.503152013689132, -9.403479586439298],
              [-35.503026120797649, -9.403317465320297],
              [-35.502820159615645, -9.403047590790266],
              [-35.502431051957799, -9.402634642970037],
              [-35.502331865993042, -9.402337083711098],
              [-35.502251748676514, -9.402256991129509],
              [-35.501931326541857, -9.401798266656197],
              [-35.501900802057754, -9.401499754467753],
              [-35.501889347342789, -9.401291835738792],
              [-35.501908438550828, -9.400921844037656],
              [-35.502003807113702, -9.400622376973521],
              [-35.502099174675621, -9.400323886903335],
              [-35.502118266996419, -9.399835617931469],
              [-35.502087718546044, -9.399501830960546],
              [-35.501938939991597, -9.39916802187865],
              [-35.501870278657236, -9.399015447754799],
              [-35.501828322181545, -9.398798961050003],
              [-35.501786389705586, -9.398582475345293],
              [-35.501706249433717, -9.398455629052016],
              [-35.501580356584924, -9.398248688209566],
              [-35.501446851965518, -9.397861514471145],
              [-35.501366734830704, -9.397590638065873],
              [-35.501153135903635, -9.397113800805087],
              [-35.501042494069097, -9.396770513817655],
              [-35.500928082020557, -9.396681805257076],
              [-35.500828872159438, -9.396275561668576],
              [-35.500676274072617, -9.395481119423509],
              [-35.500485563256383, -9.394705768024989],
              [-35.500466470512812, -9.394588468730307],
              [-35.500466470762646, -9.394326207347417],
              [-35.500474154281271, -9.393720637088537],
              [-35.500443606710796, -9.393512719341883],
              [-35.500287214316415, -9.393071178917532],
              [-35.500252847825678, -9.392809895496397],
              [-35.500245234142298, -9.392538065165384],
              [-35.500271917163538, -9.392303465637045],
              [-35.50033298804594, -9.39194106292903],
              [-35.500256666915739, -9.391634937745017],
              [-35.50011552468996, -9.390894884175893],
              [-35.500092660018502, -9.390731809160034],
              [-35.50013077499672, -9.390451434924708],
              [-35.500382581520022, -9.389998437954558],
              [-35.500607636315934, -9.389472007412131],
              [-35.500844122981711, -9.388991350598184],
              [-35.500893738786459, -9.388801570815049],
              [-35.500950945456488, -9.388692861539175],
              [-35.501114975343455, -9.388556469534212],
              [-35.501362939664276, -9.38834668906069],
              [-35.501668110749655, -9.387928966923097],
              [-35.502072492308407, -9.38727473433717],
              [-35.502423415089758, -9.386812215518798],
              [-35.502885002257706, -9.386113164262982],
              [-35.503495322274269, -9.385439862987941],
              [-35.503750900571966, -9.385193807745221],
              [-35.50385772396136, -9.384984958133401],
              [-35.504063732856984, -9.384505256285024],
              [-35.504334585323036, -9.383960711897377],
              [-35.504635960840886, -9.383118608373401],
              [-35.504708418713868, -9.382675158176168],
              [-35.504796125488518, -9.382213571105126],
              [-35.504933497093667, -9.381534589421376],
              [-35.504937314460392, -9.381118752989545],
              [-35.504918198974416, -9.380730623365297],
              [-35.504910609384837, -9.380359630646186],
              [-35.504868606003328, -9.380043958553674],
              [-35.50490679095445, -9.379791243148071],
              [-35.505063183088836, -9.379455547365202],
              [-35.505257736651018, -9.37941741078286],
              [-35.505478996036381, -9.379352543390489],
              [-35.505677368585985, -9.379297245917606],
              [-35.505887172031805, -9.379260039343881],
              [-35.506085520548083, -9.379240016653435],
              [-35.506409761127799, -9.379201878192774],
              [-35.506676795209835, -9.379091260125481],
              [-35.507176519633305, -9.378870955952959],
              [-35.507641903325137, -9.378642082801022],
              [-35.508050056390715, -9.378476121207379],
              [-35.508355250389613, -9.378149016511021],
              [-35.50863753556208, -9.377821912793101],
              [-35.509049505839087, -9.37740325876152],
              [-35.509144920211448, -9.377303094468708],
              [-35.50941574950518, -9.376939761963541],
              [-35.509556890763314, -9.376595475219261],
              [-35.509537845354643, -9.376125320101069],
              [-35.509499638141953, -9.375601752294312],
              [-35.509499638245366, -9.37549299696509],
              [-35.509564365183891, -9.375041910807875],
              [-35.509564482183869, -9.375041001813596],
              [-35.509736170875918, -9.375048615927652],
              [-35.51001463677715, -9.375037160259385],
              [-35.510445700496952, -9.37505243456941],
              [-35.510731826262138, -9.375123029402323],
              [-35.511216278423419, -9.375300375762819],
              [-35.511483288342554, -9.375361424636701],
              [-35.511933420832698, -9.37546631441192],
              [-35.512448791897988, -9.375498305697938],
              [-35.512456032842707, -9.375498747701995],
              [-35.513028214734732, -9.375260352708843],
              [-35.513688197783011, -9.37520980463947],
              [-35.513978072648733, -9.375144938311358],
              [-35.514290904297901, -9.375124914728239],
              [-35.514660919532069, -9.375085799316484],
              [-35.514966091308203, -9.374993318172999],
              [-35.515256035328427, -9.37476539985051],
              [-35.515481066814168, -9.374565095296818],
              [-35.5156336870117, -9.374193171733618],
              [-35.515664211313656, -9.3736323972206],
              [-35.515633686853143, -9.373308157191659],
              [-35.515637482117256, -9.373000122094927],
              [-35.515652733190265, -9.372801773332553],
              [-35.515713780951785, -9.372566194842641],
              [-35.515786284634622, -9.372322072416194],
              [-35.515984609443208, -9.371994943619651],
              [-35.516278349597876, -9.371595360359459],
              [-35.516457629438307, -9.371386509815586],
              [-35.516717026930195, -9.370905854023164],
              [-35.516846713172626, -9.370669344603371],
              [-35.516919216830068, -9.370451903012404],
              [-35.516934466937521, -9.37021730247362],
              [-35.516957354910957, -9.370062819447847],
              [-35.517006947663965, -9.369927381329639],
              [-35.517006946827138, -9.369755715388429],
              [-35.516957354563552, -9.369376154682906],
              [-35.516842917695932, -9.369096734299017],
              [-35.516476721939391, -9.368602713002819],
              [-35.516270735756201, -9.368332838474307],
              [-35.516064727435463, -9.368207902051822],
              [-35.51558025236821, -9.367931344303607],
              [-35.515111051142725, -9.367699584293408],
              [-35.514660918772798, -9.367467848300992],
              [-35.514405317793347, -9.367379162608486],
              [-35.513966641181732, -9.367310477621125],
              [-35.513573714233452, -9.367231337741076],
              [-35.513032057555982, -9.367000556657066],
              [-35.512860390880896, -9.36697480565511],
              [-35.5127421358021, -9.366948098709029],
              [-35.512677292541575, -9.366686816259936],
              [-35.512646768167833, -9.366270980796418],
              [-35.512700203244044, -9.365764572970253],
              [-35.513085468675129, -9.364343605096549],
              [-35.513218973862109, -9.364134756509946],
              [-35.513501257206407, -9.363626462909862],
              [-35.513676742964002, -9.363534913638992],
              [-35.514062054322267, -9.363243082800111],
              [-35.514435841810439, -9.362906433226915],
              [-35.514767718753738, -9.362424798508835],
              [-35.515297968564703, -9.361553126382589],
              [-35.516026565908383, -9.360644292671328],
              [-35.516568271305637, -9.360116930431841],
              [-35.517155727460434, -9.359479881911749],
              [-35.517529559982663, -9.359107003562055],
              [-35.51804074127719, -9.358931472123503],
              [-35.518628173363446, -9.358366950155977],
              [-35.519135558149273, -9.357704125719962],
              [-35.519768789112206, -9.356904003248676],
              [-35.520180760406319, -9.356467188329075],
              [-35.520718669849579, -9.355921688197796],
              [-35.52134044473641, -9.355293231656754],
              [-35.521786758676235, -9.354955650157043],
              [-35.522126273380643, -9.35466477426926],
              [-35.52238185157514, -9.354527426355775],
              [-35.52307997165299, -9.354142162385864],
              [-35.523617811848069, -9.353858922636588],
              [-35.524025986948381, -9.353656709266044],
              [-35.524327339813404, -9.353501294506849],
              [-35.524735515913719, -9.353299080136264],
              [-35.524972049398201, -9.353008205152037],
              [-35.525372541744893, -9.352607688997635],
              [-35.525803605748834, -9.352324426148481],
              [-35.526223236916188, -9.351960138788463],
              [-35.526562750595396, -9.351695969735866],
              [-35.527150229655341, -9.351159061597764],
              [-35.527619384525572, -9.350712723790245],
              [-35.528019946965827, -9.350212999247852],
              [-35.528290799396679, -9.349704682637128],
              [-35.528328913399271, -9.349397601567226],
              [-35.528180181748212, -9.349163000875405],
              [-35.528065721734443, -9.349037132544794],
              [-35.527894078143866, -9.348921741096108],
              [-35.527884578215314, -9.348922230084201],
              [-35.527710980521505, -9.348931263866087],
              [-35.527710490525216, -9.348931287865513],
              [-35.527382897986428, -9.348952218429922],
              [-35.526886991687974, -9.349009448612909],
              [-35.526452109712992, -9.349292710458553],
              [-35.525845585001797, -9.34961411090829],
              [-35.525136033028538, -9.349980284985401],
              [-35.524239586512387, -9.350302662157853],
              [-35.523598742915674, -9.350777590628272],
              [-35.523091381232966, -9.351331635735392],
              [-35.522671726091232, -9.351669263259822],
              [-35.522111022008779, -9.352016414593461],
              [-35.521550271977432, -9.352310130256679],
              [-35.521328989455348, -9.352518980761145],
              [-35.520546979936171, -9.352985341152184],
              [-35.520325743472583, -9.35313221103908],
              [-35.520322251502343, -9.353128694057533],
              [-35.520165509840041, -9.352970089889347],
              [-35.519921386815795, -9.352836585484562],
              [-35.519913563873644, -9.352838028468376],
              [-35.519364431936758, -9.352939566328059],
              [-35.518666357953855, -9.353224691915784],
              [-35.518105583957215, -9.353482178802428],
              [-35.517476172206386, -9.354028656841887],
              [-35.517228206902779, -9.354220369427029],
              [-35.517136658501258, -9.354320486723708],
              [-35.5169230359122, -9.354538859176511],
              [-35.51677046094666, -9.35466666024525],
              [-35.516644569770918, -9.354803028286117],
              [-35.516278350191151, -9.355175908642821],
              [-35.515740486773133, -9.355694748938467],
              [-35.515305628704326, -9.356077173172057],
              [-35.515061458329505, -9.356313659484496],
              [-35.514710535745209, -9.356569238579265],
              [-35.514603689484915, -9.356642673026194],
              [-35.514523596031005, -9.356706562557035],
              [-35.514184105300394, -9.357025098274033],
              [-35.514073439854549, -9.357324519323162],
              [-35.513531782182909, -9.358140916779414],
              [-35.513310523608304, -9.358405063942621],
              [-35.513016806582556, -9.358669235037878],
              [-35.512501831000279, -9.359178528413725],
              [-35.512280571571438, -9.359289123524292],
              [-35.511788459910122, -9.359699232533432],
              [-35.511353601833228, -9.360090201714234],
              [-35.511082772651335, -9.360336280942503],
              [-35.510769940795832, -9.36057369918484],
              [-35.510510566544333, -9.360801640535749],
              [-35.510098549277217, -9.361210771625867],
              [-35.509980317991861, -9.361401506338428],
              [-35.509713260824817, -9.361601810852621],
              [-35.509289834591662, -9.362066215591074],
              [-35.509102940826452, -9.362256974239219],
              [-35.508786313957138, -9.362539258201165],
              [-35.508610851984898, -9.362856816077773],
              [-35.5085116663234, -9.363291698301971],
              [-35.508542166817904, -9.363580688547657],
              [-35.508618465845942, -9.363995523059909],
              [-35.508740539406759, -9.364537227832406],
              [-35.508816837580603, -9.364798510291976],
              [-35.508874044899024, -9.365059840733386],
              [-35.509064757008595, -9.36552904101757],
              [-35.509190647884623, -9.365708321029517],
              [-35.509465342548154, -9.365977264627784],
              [-35.50956832268286, -9.366066903171298],
              [-35.509705670548129, -9.366166090688081],
              [-35.509831539424262, -9.366345369700062],
              [-35.509896381787193, -9.366498921813607],
              [-35.509907814451438, -9.366761206206377],
              [-35.509823924966476, -9.366888006345555],
              [-35.509507298079811, -9.367188427195712],
              [-35.509247900803118, -9.367443051381999],
              [-35.508931274795934, -9.367870319449715],
              [-35.508671877252802, -9.368405342906266],
              [-35.508542167148526, -9.368496867220173],
              [-35.508191244684774, -9.368625600097234],
              [-35.507691496150137, -9.368963205546393],
              [-35.507432122864195, -9.369227374673821],
              [-35.507138359804337, -9.369527796545354],
              [-35.506833187667851, -9.369999838347532],
              [-35.506535652525109, -9.37041756149198],
              [-35.506268642461045, -9.370509109677021],
              [-35.506266873476505, -9.370506944688701],
              [-35.506108384862721, -9.370311715744482],
              [-35.505917673598489, -9.370005566454259],
              [-35.505746007180896, -9.369708963123085],
              [-35.505551452936672, -9.36941238277026],
              [-35.50521196203875, -9.368854519893501],
              [-35.504868630101143, -9.36836906656656],
              [-35.503998889423478, -9.367597535511326],
              [-35.503872998453396, -9.367517440887518],
              [-35.503445752816262, -9.367384867305127],
              [-35.503270291170089, -9.367359115299625],
              [-35.503087193556425, -9.367360094122056],
              [-35.503080674605364, -9.36736056011309],
              [-35.502946027615955, -9.367370593925067],
              [-35.502411983487384, -9.367554623289452],
              [-35.501889347288675, -9.367721517770214],
              [-35.501481195266955, -9.367841680646549],
              [-35.501471928336791, -9.367842077635423],
              [-35.500992901946638, -9.367862682059529],
              [-35.500992621948683, -9.36786277605872],
              [-35.500203278657679, -9.368148762554853],
              [-35.499970588350955, -9.368222219883704],
              [-35.499585299156081, -9.368342360781519],
              [-35.499016911286958, -9.368527369107625],
              [-35.498688876589519, -9.368720012611822],
              [-35.498456162188774, -9.368892633328796],
              [-35.498082304700773, -9.36923023889568],
              [-35.497601647979458, -9.369612663086027],
              [-35.4974147552742, -9.369740464122463],
              [-35.497143903144362, -9.369932154686044],
              [-35.496968418283785, -9.370132435285978],
              [-35.496667041453236, -9.370252576262413],
              [-35.496312300744343, -9.370670298352868],
              [-35.49595371761788, -9.371558153539311],
              [-35.49590032942077, -9.372191406582505],
              [-35.495976605251066, -9.372814159812011],
              [-35.496247456625049, -9.373417820341794],
              [-35.496461079535074, -9.37391375048254],
              [-35.496834889258508, -9.374381042950214],
              [-35.497307955257007, -9.374819742687354],
              [-35.497925936088599, -9.375330900113424],
              [-35.498017484335094, -9.375393834811037],
              [-35.498303563886367, -9.375689507255359],
              [-35.49862398616839, -9.375994654673418],
              [-35.499093211135261, -9.3764972440131],
              [-35.49944797494215, -9.377028425069216],
              [-35.499535705943714, -9.377379393986578],
              [-35.499566253437465, -9.377668384232697],
              [-35.499619617595194, -9.378128993441587],
              [-35.499695892776899, -9.378381707954309],
              [-35.499776033843176, -9.378725039911796],
              [-35.499852332154397, -9.378841409265581],
              [-35.499932426436018, -9.378958709617171],
              [-35.500138435644729, -9.379200924316406],
              [-35.500527519286621, -9.379632011022437],
              [-35.500584725758863, -9.379731173464434],
              [-35.500664842963197, -9.379929522316198],
              [-35.500683934629869, -9.380127894110574],
              [-35.500638160873315, -9.380236579397321],
              [-35.500394013652418, -9.380310991709342],
              [-35.500389659686462, -9.380309898712015],
              [-35.500115547829516, -9.380240420883426],
              [-35.499920971388185, -9.380151736247875],
              [-35.499669234517796, -9.379918113452739],
              [-35.499390744875548, -9.379657761797384],
              [-35.499104642264733, -9.379425023964568],
              [-35.498944431545766, -9.37935445224957],
              [-35.498589666489657, -9.379085532575587],
              [-35.498356975406089, -9.378924343351636],
              [-35.498162422067985, -9.378726926386863],
              [-35.497979299591506, -9.378583898097352],
              [-35.497704652894818, -9.378350229281136],
              [-35.497475803721301, -9.378252975666463],
              [-35.497395662422868, -9.378153789203138],
              [-35.49724690690099, -9.377784706340263],
              [-35.497079059584436, -9.377351732853718],
              [-35.496930280942124, -9.377109495208432],
              [-35.496575491997653, -9.37672327525825],
              [-35.496129201720215, -9.3763646930518],
              [-35.495820188308315, -9.376105272362405],
              [-35.49553408574095, -9.375826806812018],
              [-35.495049610778295, -9.375441517734695],
              [-35.494660550963857, -9.3751916669113],
              [-35.49435156156985, -9.374913201339554],
              [-35.494122664482894, -9.374725329284004],
              [-35.493901429260823, -9.374618529735532],
              [-35.493892185330168, -9.374619251722397],
              [-35.493657282092741, -9.374637575389171],
              [-35.493508526132977, -9.374729124684986],
              [-35.493233832825673, -9.375138254903447],
              [-35.493001140346891, -9.375392879114511],
              [-35.492558669465005, -9.375639890175922],
              [-35.492314522296105, -9.375659913823517],
              [-35.492184789235637, -9.37570568741952],
              [-35.492009350495309, -9.375779122802003],
              [-35.491974984600716, -9.375942197763779],
              [-35.49199407728468, -9.376122455669615],
              [-35.492036033683029, -9.376420944867476],
              [-35.492055124997165, -9.376990286373069],
              [-35.4920856495945, -9.377170544289656],
              [-35.492177198627004, -9.377458556598709],
              [-35.492264905507213, -9.377937302727597],
              [-35.492409888988789, -9.378378844139725],
              [-35.49262348896756, -9.378802270727936],
              [-35.492863793804752, -9.379161808735409],
              [-35.493226241631071, -9.379611009304352],
              [-35.493409317029744, -9.379836063087756],
              [-35.493638190047804, -9.38009643869623],
              [-35.493958635329406, -9.380401586114516],
              [-35.494397334681281, -9.380742055425781],
              [-35.494713938854972, -9.381191232952112],
              [-35.494908492943502, -9.381650911299209],
              [-35.495015315653255, -9.382156364281698],
              [-35.495068703845035, -9.382580744714167],
              [-35.495190776559838, -9.382959351493438],
              [-35.495408217501144, -9.383391393032671],
              [-35.495690501968355, -9.383805273744732],
              [-35.495930829830584, -9.384138105917399],
              [-35.496144452740936, -9.384633057065173],
              [-35.496312299928213, -9.385201443717108],
              [-35.496457237392484, -9.385661146017865],
              [-35.496659451293958, -9.386256215537589],
              [-35.496899779078973, -9.386670096210622],
              [-35.497265951945742, -9.387046816230979],
              [-35.497662696425635, -9.387586565274672],
              [-35.498047985846163, -9.388279912360439],
              [-35.498208196263604, -9.388667110122995],
              [-35.498177672064557, -9.38911915230668],
              [-35.498112829108273, -9.389589307346426],
              [-35.498051780200399, -9.38997841488959],
              [-35.498017484356794, -9.39008712418695],
              [-35.497903024569396, -9.390774720839319],
              [-35.497868682735167, -9.390873929195271],
              [-35.497830544799847, -9.391109461707034],
              [-35.497708471293492, -9.391562479798823],
              [-35.49767033425556, -9.391905789645952],
              [-35.497674128692779, -9.392466539191664],
              [-35.497681765342463, -9.392773621305224],
              [-35.497746608421473, -9.393224730584423],
              [-35.497876318741866, -9.393956193195882],
              [-35.497845794344528, -9.394616129097923],
              [-35.497800020804668, -9.395546917315599],
              [-35.497780974294827, -9.396233559063846],
              [-35.497769496683937, -9.396965999536954],
              [-35.497788565083226, -9.397444745602989],
              [-35.497784769655809, -9.397923469647761],
              [-35.497761882234862, -9.398547177780667],
              [-35.497849613011077, -9.399133702246839],
              [-35.498063235842402, -9.399710680890244],
              [-35.498242515045746, -9.400170335224814],
              [-35.49854007436187, -9.400620467729292],
              [-35.498776583080605, -9.401133557788292],
              [-35.498921520707746, -9.401421569148901],
              [-35.499253397669179, -9.401970887074357],
              [-35.499341151772384, -9.402214079657531],
              [-35.499386927159087, -9.402493499977959],
              [-35.499459406110624, -9.403017066818411],
              [-35.499539500349179, -9.403179164894373],
              [-35.499756941374997, -9.403521564988004],
              [-35.500054476821177, -9.403835328333502],
              [-35.500168958816381, -9.403979311553574],
              [-35.500306282621146, -9.40414145468316],
              [-35.500439788446727, -9.40431216675632],
              [-35.50058854412304, -9.404518174626268],
              [-35.500760210633068, -9.404716523564987],
              [-35.501000562382458, -9.405166656016199],
              [-35.501122611242998, -9.405391710743636],
              [-35.501282822780674, -9.405652062289462],
              [-35.501385826922807, -9.405733133886596],
              [-35.501752023728642, -9.406172765521001],
              [-35.502118220473434, -9.406676309761526],
              [-35.502286090943919, -9.406946230255654],
              [-35.502614149847631, -9.407586096620124],
              [-35.502671380320258, -9.407684351068344],
              [-35.502820158978118, -9.40790942882092],
              [-35.503049031995467, -9.408169780431523],
              [-35.503129102242262, -9.408323309560512],
              [-35.503251176034233, -9.408619911847149],
              [-35.50336184060054, -9.409243620106983],
              [-35.503335134509889, -9.409550701189122],
              [-35.503361840773259, -9.410110495763909],
              [-35.503567801868606, -9.410470989735808],
              [-35.503903497387363, -9.41145327999754],
              [-35.503934021974928, -9.411643059856623],
              [-35.503907339901261, -9.411932050050369],
              [-35.503884429255493, -9.412790334739046],
              [-35.503972160099082, -9.413305310647798],
              [-35.504151463318884, -9.413746874095244],
              [-35.504311673941942, -9.413917586193969],
              [-35.50447188448755, -9.41416934679323],
              [-35.504586345379849, -9.414421106349449],
              [-35.504662643646903, -9.414583249422025],
              [-35.504796126195622, -9.415043859709048],
              [-35.504784717015198, -9.415323302976169],
              [-35.504387972828454, -9.415525470356741],
              [-35.504380288887091, -9.415525005352343],
              [-35.504120962867169, -9.415509265205191],
              [-35.503956910169443, -9.415447262432847],
              [-35.50351057271272, -9.415278483052724],
              [-35.50317110529965, -9.415263209827248],
              [-35.503167496326206, -9.415264001818956],
              [-35.502835386772539, -9.415337599052668],
              [-35.502556896717039, -9.415511174720729],
              [-35.502263203836897, -9.415621815762375],
              [-35.501984714876173, -9.415696159041978],
              [-35.501813071132155, -9.415742911592226],
              [-35.501382031370206, -9.415772480004144],
              [-35.501229411464813, -9.41583732446081],
              [-35.501263753067064, -9.415981330605657],
              [-35.501332415400128, -9.41613485872411],
              [-35.501469739292133, -9.416205453418359],
              [-35.50160706323512, -9.416222612441894],
              [-35.501865878275716, -9.416220750697089],
              [-35.501874096213491, -9.4162207037051],
              [-35.502282249243606, -9.416091947882951],
              [-35.502492076715505, -9.416027105480117],
              [-35.502595081978711, -9.41598132985923],
              [-35.502724768031541, -9.415944147210473],
              [-35.502950939345141, -9.415915415600525],
              [-35.502957482296353, -9.415914577611845],
              [-35.503117693065974, -9.415931737656917],
              [-35.503350385218042, -9.416020423329503],
              [-35.503476253247854, -9.416037605342096],
              [-35.503651737876254, -9.4160814942369],
              [-35.503682285540975, -9.41619017959585],
              [-35.503636487706721, -9.416379960383177],
              [-35.503438115087853, -9.416507760408807],
              [-35.50315583117311, -9.416564035796142],
              [-35.503151105209362, -9.416563593794422],
              [-35.502808703835406, -9.416530648675041],
              [-35.502805303860072, -9.416531765664967],
              [-35.502637038083343, -9.416585992172305],
              [-35.502217429182899, -9.416669881260232],
              [-35.501961826979475, -9.416816751114391],
              [-35.501694792923686, -9.416899731351538],
              [-35.501685572993225, -9.416900105340575],
              [-35.501420145993961, -9.416911164022453],
              [-35.501050130812445, -9.416895913768023],
              [-35.50068009360556, -9.416907368348982],
              [-35.500679673608545, -9.416907578347283],
              [-35.500390171662275, -9.417054215171019],
              [-35.50020707297962, -9.417127650546007],
              [-35.500206560986513, -9.417124507564882],
              [-35.500138434887759, -9.416721360985328],
              [-35.500128911958932, -9.416722430969759],
              [-35.499886628768586, -9.416749998571735],
              [-35.499585274877298, -9.416933120159401],
              [-35.499104618145388, -9.417325068291213],
              [-35.498875745498133, -9.417724652613071],
              [-35.498673578390751, -9.418394135296703],
              [-35.498540073073606, -9.418738422049184],
              [-35.498078485239994, -9.419085572475039],
              [-35.497754245608746, -9.419178052599754],
              [-35.497751497630993, -9.419176585606202],
              [-35.497334591002129, -9.418954884579849],
              [-35.497120991128206, -9.418423703652341],
              [-35.497002736464282, -9.417963071379917],
              [-35.496949348368872, -9.417439504556476],
              [-35.496941712151667, -9.416680382227895],
              [-35.496983667584111, -9.415894554110702],
              [-35.496998941150345, -9.415180252527659],
              [-35.496991304890223, -9.414465948923096],
              [-35.496934098418905, -9.413318674940657],
              [-35.49681200111884, -9.412507095828129],
              [-35.496686156857152, -9.411685015776946],
              [-35.496518308593259, -9.411198655616879],
              [-35.496377144058926, -9.410783797041304],
              [-35.496026197104293, -9.410379440203601],
              [-35.495698114881719, -9.41007426777607],
              [-35.495324305125806, -9.409643205081601],
              [-35.494969517294891, -9.409139660851899],
              [-35.494614775860263, -9.408220328185461],
              [-35.49444308599562, -9.407345791415272],
              [-35.494393515957057, -9.406731630154839],
              [-35.494385879523442, -9.406198516434216],
              [-35.49440113017549, -9.405393619410765],
              [-35.49469484674448, -9.404560107825583],
              [-35.494847467424542, -9.403682731378268],
              [-35.494958062138373, -9.403103843051273],
              [-35.4950953853578, -9.402831104861876],
              [-35.495228913923235, -9.402225534720939],
              [-35.495495970711346, -9.401373908222645],
              [-35.495808755704651, -9.399943371336692],
              [-35.495938443488981, -9.399138475421328],
              [-35.49602237964546, -9.398306849627787],
              [-35.496060516148901, -9.397475246791192],
              [-35.496052903128003, -9.396508229746679],
              [-35.496045265995434, -9.395658489979123],
              [-35.495946103253793, -9.395126355167193],
              [-35.495820188145458, -9.394142155117828],
              [-35.495503561988933, -9.392625819171007],
              [-35.495186981080671, -9.390848154836156],
              [-35.494733030765452, -9.389540669473293],
              [-35.494519384976357, -9.388918871107464],
              [-35.494259988551107, -9.388278957810492],
              [-35.494019659920561, -9.387702934137462],
              [-35.493546640611385, -9.386540408863478],
              [-35.493123213479826, -9.385847084742331],
              [-35.492814247755781, -9.38486479451103],
              [-35.492222973419558, -9.383621197626894],
              [-35.491891096499657, -9.383027058980323],
              [-35.491517261940359, -9.382388100570921],
              [-35.491044219200838, -9.381677593509997],
              [-35.490529244821616, -9.380922312686025],
              [-35.490433853810245, -9.380642869320381],
              [-35.490197366909804, -9.380319583092875],
              [-35.489655662085823, -9.379193286532962],
              [-35.489136867933041, -9.378230064988749],
              [-35.488595186970791, -9.377248752534909],
              [-35.487958161867006, -9.3761243638747],
              [-35.48737068223447, -9.375161142266849],
              [-35.486829001116988, -9.374342904807584],
              [-35.48628729709479, -9.373424502966566],
              [-35.485818096196894, -9.372849434075208],
              [-35.485486241602402, -9.371911962548761],
              [-35.48466607092849, -9.370742777994941],
              [-35.483891651581786, -9.369916880365992],
              [-35.483189806684955, -9.369091005805016],
              [-35.482651919367207, -9.3684530022385],
              [-35.482323861177598, -9.368111578038254],
              [-35.481785998644959, -9.367699584077165],
              [-35.481343479304719, -9.36737725365205],
              [-35.480435601985576, -9.3665341720051],
              [-35.479405626805324, -9.365466083632853],
              [-35.478959290605317, -9.365026428928616],
              [-35.478329902005306, -9.36436267343622],
              [-35.477788196758745, -9.363679850143761],
              [-35.477414363958509, -9.363293607177907],
              [-35.476903183292421, -9.362809132689932],
              [-35.476269976807217, -9.362054782753654],
              [-35.475777863971246, -9.361596990119033],
              [-35.475358233585872, -9.361139244552021],
              [-35.474938648046397, -9.36084361898433],
              [-35.474168070464771, -9.360234208025364],
              [-35.472932110724237, -9.359293918673879],
              [-35.472207308060632, -9.358405063483236],
              [-35.471501620235827, -9.357533415204527],
              [-35.470639493612971, -9.356644557885998],
              [-35.469888009049264, -9.35586350400564],
              [-35.469140342448483, -9.355091041075935],
              [-35.468251509164404, -9.354058178622392],
              [-35.467553434904751, -9.353584204896521],
              [-35.467129985488519, -9.353189415938429],
              [-35.466428070625732, -9.352327290605887],
              [-35.465890207418248, -9.351571985767274],
              [-35.46534852615472, -9.350906299371838],
              [-35.464204115865421, -9.349812436057631],
              [-35.463967628187334, -9.349253571288012],
              [-35.463731095415596, -9.348793892905858],
              [-35.46321612098285, -9.348093001753332],
              [-35.462907130050141, -9.347327174194433],
              [-35.462365449282103, -9.346137943033259],
              [-35.461686444014966, -9.345518985222055],
              [-35.460400891076766, -9.344127563615876],
              [-35.460141471323958, -9.343830960205699],
              [-35.459885892388577, -9.343696523797494],
              [-35.459256503591007, -9.343240641025847],
              [-35.458745322685239, -9.343008926979973],
              [-35.457837421997958, -9.34255402194249],
              [-35.456929520412778, -9.341991339570862],
              [-35.45560582983159, -9.341585071845181],
              [-35.454678882912646, -9.341527866333516],
              [-35.453961693327905, -9.341550752522862],
              [-35.452966061869418, -9.341557412552673],
              [-35.452959472918884, -9.341557924543341],
              [-35.452180233769973, -9.34161748244828],
              [-35.451348632026601, -9.341668053359818],
              [-35.450490323484885, -9.341719532240901],
              [-35.450488298500154, -9.341719602238575],
              [-35.449056037323473, -9.341756714672849],
              [-35.448270210301899, -9.341734783075106],
              [-35.4473889921479, -9.341560299330856],
              [-35.446622255237912, -9.3412675144244],
              [-35.446065324611013, -9.341108211888997],
              [-35.445340520579833, -9.340607531306272],
              [-35.4449476187526, -9.340402524206374],
              [-35.443951963269882, -9.340435889071431],
              [-35.443950427281344, -9.3404361440684],
              [-35.443047903990035, -9.34057798535007],
              [-35.443041012042258, -9.340578032343364],
              [-35.442586316487017, -9.340580872901677],
              [-35.442120910311601, -9.340268063400336],
              [-35.441674620393357, -9.33952991954507],
              [-35.441201601668752, -9.338801298606137],
              [-35.441041389993508, -9.338684929175789],
              [-35.440598871695833, -9.338317779031808],
              [-35.440061008266305, -9.337797051748009],
              [-35.439637559174741, -9.337058906914585],
              [-35.439649014070419, -9.336019410349008],
              [-35.4396642648002, -9.335124850891425],
              [-35.439652832869186, -9.33408535330485],
              [-35.439645218925321, -9.333028672828114],
              [-35.439587988204352, -9.332134159303077],
              [-35.439488802519463, -9.331538111894405],
              [-35.439179813671245, -9.330681689899517],
              [-35.438844140426013, -9.329400932502638],
              [-35.438744931749284, -9.328796271147453],
              [-35.438298640949895, -9.327931304077994],
              [-35.438062131202393, -9.327444918864032],
              [-35.437547132709632, -9.326806892328076],
              [-35.436685005940667, -9.326071612070157],
              [-35.436101391882531, -9.325524226910323],
              [-35.435586392261833, -9.325021614537681],
              [-35.434815837730042, -9.324358814917433],
              [-35.4335569441117, -9.323472869222229],
              [-35.433114472703458, -9.323222040360923],
              [-35.432717728964704, -9.322954074648239],
              [-35.432603291899902, -9.322882549983881],
              [-35.431741212036997, -9.322246431114097],
              [-35.430993499051311, -9.321881189676199],
              [-35.429952092180237, -9.321635110228385],
              [-35.429303588203965, -9.321522584320533],
              [-35.428929778052932, -9.321507333066897],
              [-35.427144499903847, -9.321175455457423],
              [-35.425988633772462, -9.321065793059844],
              [-35.424596281364423, -9.321030517982344],
              [-35.423696017186202, -9.321036199109502],
              [-35.423070422969012, -9.320995221780736],
              [-35.42221211446374, -9.321010470887854],
              [-35.421239370834442, -9.321017130941556],
              [-35.419202332270366, -9.321030518963433],
              [-35.418092263707187, -9.321011403048853],
              [-35.417352210416908, -9.320908444997],
              [-35.416772368938666, -9.32077680227165],
              [-35.416120069078289, -9.320572725926802],
              [-35.415097754259897, -9.32011877578312],
              [-35.414285196826391, -9.3196905756754],
              [-35.413743515587484, -9.318998206453548],
              [-35.413712968551039, -9.318221901226316],
              [-35.41394186512332, -9.317894797462246],
              [-35.414401170727416, -9.317801827464377],
              [-35.41440347571038, -9.317801362469426],
              [-35.415029092969974, -9.31779658908072],
              [-35.415864513622275, -9.317808974781114],
              [-35.416618373935037, -9.317776937680332],
              [-35.416627430866704, -9.317776565691068],
              [-35.416974559278096, -9.317729836302894],
              [-35.417481896561696, -9.317590580635953],
              [-35.417710769913121, -9.317498076420899],
              [-35.418241043312257, -9.317051762674236],
              [-35.418747077757843, -9.316751178003809],
              [-35.418748381748699, -9.316750410009766],
              [-35.419255742887458, -9.316764682393323],
              [-35.419908066663503, -9.317058421183441],
              [-35.420072095198336, -9.317293022885135],
              [-35.420213214959084, -9.317472325907573],
              [-35.420468793758332, -9.31775081442305],
              [-35.420564159967633, -9.317822340069434],
              [-35.420865536624518, -9.317883387972245],
              [-35.420977389710373, -9.317953284644048],
              [-35.420979950689393, -9.317954913636351],
              [-35.421140184517391, -9.317909140068457],
              [-35.421349987995569, -9.317835680717865],
              [-35.421464425214069, -9.31774410939061],
              [-35.421647547090579, -9.317462779300644],
              [-35.421830644984283, -9.317163358322565],
              [-35.421895511646099, -9.31700028439136],
              [-35.422032834818744, -9.316773272922964],
              [-35.422101496494015, -9.316565378272454],
              [-35.422444806157465, -9.316282163343162],
              [-35.422860619287377, -9.315981718587823],
              [-35.423413732318558, -9.315742343582562],
              [-35.423921094533924, -9.315675591467203],
              [-35.424638260406482, -9.315345623174634],
              [-35.424840451113226, -9.315090999937286],
              [-35.425069300660049, -9.314791556001884],
              [-35.425649142462568, -9.314579819850451],
              [-35.426038250693495, -9.314387174403599],
              [-35.426616439465903, -9.314220770970273],
              [-35.426618092453772, -9.314220304974731],
              [-35.427125430609387, -9.314217417464322],
              [-35.427659499537938, -9.314240328819309],
              [-35.428262227954022, -9.314254624291374],
              [-35.428838205564865, -9.314277534685369],
              [-35.429490528520475, -9.314381447649412],
              [-35.429933023886491, -9.314677096232748],
              [-35.430024573141907, -9.314730530987458],
              [-35.430142850143469, -9.314838285431199],
              [-35.430352655162388, -9.315252165067081],
              [-35.43056627719465, -9.315621225983708],
              [-35.4307989452182, -9.315846303808405],
              [-35.43114260357229, -9.315888794865307],
              [-35.431146095545415, -9.315889237865795],
              [-35.431470360131591, -9.315841554462272],
              [-35.431863285240347, -9.315748072405771],
              [-35.432092158617273, -9.315628886355622],
              [-35.432301963155012, -9.315492493394135],
              [-35.432733351912304, -9.315462481980935],
              [-35.432740661857359, -9.315461968990931],
              [-35.433202227360226, -9.315459128437798],
              [-35.433297571595098, -9.315503925249484],
              [-35.433803140629067, -9.315644578850085],
              [-35.433804933614979, -9.315645067848731],
              [-35.434270339140944, -9.315586929641142],
              [-35.434773859709779, -9.315176821645206],
              [-35.435121011189963, -9.315057634705061],
              [-35.43532697278296, -9.314893604910834],
              [-35.435578756157149, -9.314593207002384],
              [-35.435830539402744, -9.314429154250927],
              [-35.436131892383983, -9.314146847276671],
              [-35.436383652630632, -9.313981886530801],
              [-35.436772526726642, -9.313934691184324],
              [-35.4367765546966, -9.313934202191099],
              [-35.437261029026011, -9.313931314659515],
              [-35.437863759417503, -9.313972339966492],
              [-35.438489352635678, -9.314013342294842],
              [-35.439141652344716, -9.314379584637219],
              [-35.439492645473194, -9.31460272958407],
              [-35.439908411213118, -9.314717143263442],
              [-35.440488253624466, -9.314921242541006],
              [-35.440675193055149, -9.315082431718334],
              [-35.441003229246157, -9.315423854912678],
              [-35.441052822717296, -9.315585975956527],
              [-35.441125325887029, -9.315883510184531],
              [-35.441148189432205, -9.316181999360483],
              [-35.441174896033637, -9.316389894100084],
              [-35.441296945547037, -9.316985010534479],
              [-35.441300787083492, -9.317445619690561],
              [-35.441300786971951, -9.317563874959449],
              [-35.441213033287347, -9.317934867584318],
              [-35.441102415733781, -9.318350680910816],
              [-35.440965091450131, -9.31869496765465],
              [-35.440602689589632, -9.319339630332136],
              [-35.440465366212457, -9.319783080462978],
              [-35.440263199308845, -9.320245645415303],
              [-35.43998852786946, -9.320798758740391],
              [-35.439832112645071, -9.321233640906474],
              [-35.439698606957201, -9.321975580195792],
              [-35.439595602654599, -9.323123809002063],
              [-35.440049552782448, -9.324639189057599],
              [-35.440490884047563, -9.325051020923008],
              [-35.440492095037364, -9.325052138917224],
              [-35.440747674144575, -9.325004478449834],
              [-35.440953636669413, -9.324912905207652],
              [-35.441205420026925, -9.324630644186847],
              [-35.441388518133799, -9.324106122599488],
              [-35.441495340152059, -9.323228723122321],
              [-35.441506772046409, -9.322189201558635],
              [-35.441666983310519, -9.321681863843871],
              [-35.441872991920519, -9.321499719161585],
              [-35.442239189385283, -9.3212441410823],
              [-35.442651160639905, -9.320843555942025],
              [-35.442882849944269, -9.320778991556804],
              [-35.442883874936719, -9.320778712559472],
              [-35.443345439449097, -9.320766326065739],
              [-35.444207565585359, -9.321113452722589],
              [-35.444944499791326, -9.321332826052632],
              [-35.444947618766783, -9.321333758049811],
              [-35.44538629652925, -9.321240323035765],
              [-35.445638079929083, -9.320913218292029],
              [-35.445798290081626, -9.320524111846355],
              [-35.445886020852093, -9.320062500781336],
              [-35.446137805226698, -9.319762101872559],
              [-35.446664235656883, -9.319315811121209],
              [-35.447194461075384, -9.31885136048566],
              [-35.447400470685437, -9.318669216803299],
              [-35.448094725648119, -9.318429842929163],
              [-35.448480038232759, -9.317893865600841],
              [-35.448941579145902, -9.317457051730445],
              [-35.449536671031368, -9.317036512883792],
              [-35.450066920449743, -9.316572084248049],
              [-35.450688718850337, -9.316450942575521],
              [-35.451451659182815, -9.316327938045834],
              [-35.451981909294574, -9.316188705399913],
              [-35.452466360821013, -9.315977923153648],
              [-35.452927924674789, -9.315604066894073],
              [-35.453595520814787, -9.315391398829862],
              [-35.454034220798704, -9.315063363265722],
              [-35.453980786834258, -9.314394835348274],
              [-35.453812938515, -9.313961839868604],
              [-35.453698501509223, -9.313827402593096],
              [-35.453275053108577, -9.313414453751724],
              [-35.453183503023766, -9.313179853116738],
              [-35.453317031250769, -9.312926183808965],
              [-35.453454355509308, -9.312608602899884],
              [-35.453820575254817, -9.312055442659956],
              [-35.454095245464117, -9.311746475825364],
              [-35.454392781381351, -9.311563377233963],
              [-35.454785682491185, -9.311469919177194],
              [-35.455617285482539, -9.311157087884537],
              [-35.456305720412999, -9.310999228500684],
              [-35.45630772339829, -9.310998762505465],
              [-35.456654873769587, -9.310995921845921],
              [-35.457028706852313, -9.311084630645336],
              [-35.45747122609253, -9.311514715398483],
              [-35.458287578364221, -9.31208789961504],
              [-35.458474470906246, -9.312131741517893],
              [-35.459008538623415, -9.312380637476352],
              [-35.459752410597446, -9.312791700627695],
              [-35.46056494421385, -9.313030119910129],
              [-35.46129640567505, -9.313024485625606],
              [-35.461304997609986, -9.313024391634157],
              [-35.461926772096952, -9.312812679521256],
              [-35.462155645687837, -9.312467436868046],
              [-35.462174713909334, -9.312079307284687],
              [-35.462197601060545, -9.31173502043394],
              [-35.462209079799983, -9.310858597861667],
              [-35.462251012917427, -9.310397010753722],
              [-35.462296787877108, -9.310071815806273],
              [-35.462502773487628, -9.309889672123719],
              [-35.462823218282765, -9.309652183889753],
              [-35.463284806000082, -9.309423311733765],
              [-35.463998153168895, -9.308812992169699],
              [-35.464616133966111, -9.30830276589818],
              [-35.465050992124368, -9.307821132279608],
              [-35.465260795646621, -9.307701946211436],
              [-35.465581217527415, -9.307373862537371],
              [-35.465718541554999, -9.307301382113076],
              [-35.466340363059793, -9.307070600117855],
              [-35.466870612240641, -9.306858887919605],
              [-35.468575774026924, -9.306106448156152],
              [-35.469708728814737, -9.305709703662041],
              [-35.470540308670849, -9.305541855472793],
              [-35.4710019192445, -9.30546555737366],
              [-35.471433495001229, -9.30543647595473],
              [-35.4714406199477, -9.305436010964264],
              [-35.471806723175931, -9.305433170322262],
              [-35.471810611146481, -9.305433147326042],
              [-35.472390452798116, -9.305384508165918],
              [-35.472783355933885, -9.305264344274002],
              [-35.473241124706668, -9.305007788285392],
              [-35.473447110343443, -9.304797983773621],
              [-35.473630208212136, -9.30452617762384],
              [-35.473904832592986, -9.304035974908981],
              [-35.474293963191464, -9.30345522486024],
              [-35.474450355391021, -9.303047024528617],
              [-35.474789893407575, -9.302421431710934],
              [-35.47481273456696, -9.302068575913115],
              [-35.474763164240073, -9.301752903818171],
              [-35.474400763269408, -9.300391051898814],
              [-35.474187163321112, -9.299931397541487],
              [-35.473836217386648, -9.299500333879754],
              [-35.473580638671059, -9.299131249923654],
              [-35.473183894998307, -9.298790781659463],
              [-35.472901610433865, -9.298476087342417],
              [-35.472668919433467, -9.298225257676666],
              [-35.472386635877093, -9.297901972412749],
              [-35.472127238207477, -9.297514774565251],
              [-35.471963208925622, -9.297010275531505],
              [-35.471841135267418, -9.296567781153506],
              [-35.471787747318551, -9.295881115348807],
              [-35.471761042107232, -9.295258362173822],
              [-35.471897923197993, -9.295122413141463],
              [-35.471898342195217, -9.295121994144443],
              [-35.472291267220307, -9.295119129527167],
              [-35.472405704353967, -9.295118174639359],
              [-35.472592620810893, -9.295252657981633],
              [-35.472733762614723, -9.295387118281544],
              [-35.472829130672046, -9.295620763925729],
              [-35.472947385410443, -9.296008916636151],
              [-35.473138120617477, -9.296378000531744],
              [-35.47334794766283, -9.296765198333134],
              [-35.473790443057283, -9.297033140088013],
              [-35.474388096531882, -9.297029367666676],
              [-35.474393172493471, -9.29702934467149],
              [-35.475087452296961, -9.296960683741064],
              [-35.475546220891047, -9.296885804630223],
              [-35.475548991870511, -9.296885338635715],
              [-35.475819332823711, -9.296883475898413],
              [-35.475827481761996, -9.296883429906247],
              [-35.475987692591048, -9.296837655338079],
              [-35.476311932221215, -9.296745151211177],
              [-35.476563716408471, -9.296644056070066],
              [-35.477002393240625, -9.296478117503431],
              [-35.47746398095849, -9.296249244347079],
              [-35.477624191898933, -9.296085215509848],
              [-35.47776151509813, -9.295830591211358],
              [-35.478150622560108, -9.29539475526698],
              [-35.478654142250349, -9.294857823053785],
              [-35.479111911286324, -9.29432186979202],
              [-35.479409446246436, -9.294093928477416],
              [-35.480103725101536, -9.293970923882794],
              [-35.480702659745567, -9.293777324635892],
              [-35.481187134290138, -9.29354842850087],
              [-35.481648676119846, -9.293201301075381],
              [-35.481946233156144, -9.292892310261749],
              [-35.481957689192498, -9.291699261647141],
              [-35.481812704919484, -9.291031688639114],
              [-35.481553307342736, -9.290545304404827],
              [-35.481248160078472, -9.290095171904047],
              [-35.480965852521791, -9.289771886640358],
              [-35.480546267089665, -9.289358913803724],
              [-35.480149499458562, -9.288973648817],
              [-35.479722278475492, -9.288144909543705],
              [-35.479790893168236, -9.287918877004749],
              [-35.48006561150487, -9.287474450007396],
              [-35.480134250113018, -9.287338103914019],
              [-35.480294483968017, -9.287264668516791],
              [-35.480710272990756, -9.287080616040605],
              [-35.480953862171141, -9.287052932437916],
              [-35.480962057109885, -9.287052000451306],
              [-35.481381664822912, -9.287166437133374],
              [-35.481496101828391, -9.28730185240244],
              [-35.481686835968681, -9.287742461855665],
              [-35.481946233468449, -9.288310825582851],
              [-35.48211408065368, -9.288887827171759],
              [-35.48239634197293, -9.289463874872848],
              [-35.482800723154128, -9.290265883290624],
              [-35.483033413984437, -9.290697878836339],
              [-35.48352552579977, -9.291182377298457],
              [-35.484195101586899, -9.291330737003349],
              [-35.48419687157309, -9.291331133002561],
              [-35.484799577070319, -9.291263424980903],
              [-35.485146727510113, -9.291189034763185],
              [-35.485490061107178, -9.290978253384983],
              [-35.48592494329074, -9.290469959930792],
              [-35.485879167875446, -9.2902172454504],
              [-35.485688432838479, -9.289666972674672],
              [-35.485333691000157, -9.289163429457849],
              [-35.485078113113396, -9.288975558381811],
              [-35.484818692417299, -9.288616020363447],
              [-35.484677527707575, -9.288381419682606],
              [-35.484421948769672, -9.288247914270521],
              [-35.484143506098334, -9.288015199450589],
              [-35.483792537010586, -9.28774628078712],
              [-35.483350018751977, -9.287333330929091],
              [-35.483300448297136, -9.287153072997373],
              [-35.483365269307619, -9.286619959353287],
              [-35.483502638506323, -9.286365382054623],
              [-35.483800173491602, -9.286110711905238],
              [-35.484075938446622, -9.286063517452959],
              [-35.484078639426585, -9.286063028458488],
              [-35.484448654624934, -9.28606111881381],
              [-35.484635571193465, -9.286077347886989],
              [-35.484750008259169, -9.286148873551054],
              [-35.485051383892269, -9.28623758228246],
              [-35.485425240909045, -9.286397769639274],
              [-35.485982172523485, -9.286574185065747],
              [-35.486718337925431, -9.286596001614376],
              [-35.486722225895875, -9.286596140617137],
              [-35.487252428933616, -9.28653795646907],
              [-35.487576715622957, -9.28638251873099],
              [-35.487645354281511, -9.28619273796787],
              [-35.487641559421505, -9.286074506695241],
              [-35.487500416769926, -9.285777880397864],
              [-35.487336387351831, -9.285417387474077],
              [-35.487149470962656, -9.285210447579834],
              [-35.486588720445219, -9.284961527598059],
              [-35.486077539595335, -9.284666856945202],
              [-35.485635021208203, -9.284390277244308],
              [-35.485173457812863, -9.284275840523373],
              [-35.484753803117059, -9.284143289953288],
              [-35.48419687039322, -9.284083196807881],
              [-35.483594187998108, -9.284042195502002],
              [-35.483178375171029, -9.284018353263518],
              [-35.482506983270504, -9.284005011722883],
              [-35.482045418765011, -9.284007852276922],
              [-35.48203566383846, -9.284008341264842],
              [-35.48158003727049, -9.284029807709279],
              [-35.481570258344036, -9.284030343696907],
              [-35.481072675086018, -9.28406028504997],
              [-35.480473764492963, -9.284199518633317],
              [-35.4802181852492, -9.284391232210851],
              [-35.479619273630192, -9.284558124623128],
              [-35.47939040127735, -9.284650628838794],
              [-35.479295033956873, -9.284696426467159],
              [-35.479089048448643, -9.284769837822111],
              [-35.478604574014575, -9.284881433682504],
              [-35.478028549137044, -9.28513892155588],
              [-35.477589873169464, -9.285448866232425],
              [-35.477360976706571, -9.285658646722966],
              [-35.477086352462756, -9.286003912333372],
              [-35.476815477037995, -9.286511250945205],
              [-35.476495077055795, -9.286947086953194],
              [-35.476174586217837, -9.287230326904481],
              [-35.475919054024978, -9.28736764981829],
              [-35.475411691756463, -9.28748781460436],
              [-35.475114110881321, -9.287626115473026],
              [-35.474602976598355, -9.287792053972574],
              [-35.474594524662386, -9.287792100964452],
              [-35.474187163746564, -9.287794918568862],
              [-35.473862923201743, -9.287796827256123],
              [-35.473611140107913, -9.28779871401073],
              [-35.473191532275962, -9.287811099544619],
              [-35.473077096142177, -9.287812077432349],
              [-35.47266128229046, -9.28781494202865],
              [-35.47203568906972, -9.287773916701569],
              [-35.471642764087647, -9.287731005602186],
              [-35.471154516894359, -9.287617500850789],
              [-35.470967553352395, -9.287573658948359],
              [-35.470803523704852, -9.287457313515468],
              [-35.470296208742205, -9.287252235312625],
              [-35.469968127336962, -9.287136868721477],
              [-35.469388307828659, -9.287032931826005],
              [-35.46913270578986, -9.287007180747999],
              [-35.468739803781702, -9.286991907477812],
              [-35.468045525038661, -9.286996679803947],
              [-35.467419907775273, -9.287001453193803],
              [-35.466981254096837, -9.287004316769005],
              [-35.466976109135381, -9.287004805761212],
              [-35.466473868896259, -9.287052954997362],
              [-35.465966530457678, -9.287354308663017],
              [-35.465829206386303, -9.28747351779846],
              [-35.465718541833851, -9.287889331124498],
              [-35.465581218507701, -9.28827936958521],
              [-35.46553928536612, -9.288767686526747],
              [-35.465543103972109, -9.28915581513032],
              [-35.465478260284108, -9.289346526890874],
              [-35.465344732606987, -9.290078967237925],
              [-35.465073902943466, -9.290839998280829],
              [-35.464982330551351, -9.290931547629716],
              [-35.464684795541608, -9.291212854614084],
              [-35.464295712181617, -9.291540936224214],
              [-35.463998154043722, -9.291958635365193],
              [-35.46372350671507, -9.292394494415182],
              [-35.463403107707187, -9.292857945252072],
              [-35.463105503569551, -9.293275689392713],
              [-35.46302159061856, -9.293899398458361],
              [-35.463048295982624, -9.294359983635353],
              [-35.463330604299976, -9.294936962330093],
              [-35.463567090015694, -9.295459598318347],
              [-35.463780736921528, -9.295964096397626],
              [-35.463898967703123, -9.296306474390576],
              [-35.463925674067085, -9.29676708456763],
              [-35.463906605015836, -9.296975002264439],
              [-35.463700595464964, -9.297094188336176],
              [-35.463695752503902, -9.297091813346354],
              [-35.463372560101995, -9.296933977021995],
              [-35.463345831602666, -9.296617349954767],
              [-35.463342012005981, -9.296219675409901],
              [-35.463315330573593, -9.29583154578482],
              [-35.463197052750154, -9.295533964514879],
              [-35.463101684709919, -9.295282227982723],
              [-35.462960542042687, -9.29500276057953],
              [-35.462727851043226, -9.294751023919929],
              [-35.462609596193502, -9.294481125478908],
              [-35.462445566673637, -9.294229365883206],
              [-35.462556231363628, -9.293667637459219],
              [-35.462598141258702, -9.293441628895579],
              [-35.462594346593534, -9.293116386903085],
              [-35.462571481106941, -9.292754987116464],
              [-35.462567640425043, -9.292447859011958],
              [-35.4625867095614, -9.292149392875192],
              [-35.462605800671533, -9.29187851756785],
              [-35.46265157547812, -9.291715442618729],
              [-35.462716396446197, -9.291227196697944],
              [-35.462689714014537, -9.290838089079207],
              [-35.46264012086592, -9.290332636158677],
              [-35.462590550521568, -9.290035077952613],
              [-35.462537116385889, -9.28954680792234],
              [-35.462510433063549, -9.289041355023123],
              [-35.462155669353294, -9.288402396645088],
              [-35.462064120089295, -9.288357554837393],
              [-35.461972570850428, -9.288286028194753],
              [-35.461552916138579, -9.288171615512807],
              [-35.461543812206884, -9.288172337499931],
              [-35.461182923914905, -9.28820118498661],
              [-35.460954028520547, -9.288338508924934],
              [-35.460172019008866, -9.288804845315337],
              [-35.459733342110304, -9.289042309439695],
              [-35.459386191611358, -9.289180588262422],
              [-35.459378554669549, -9.289180239257488],
              [-35.459039063257542, -9.289164383040452],
              [-35.458669048297025, -9.288914532242135],
              [-35.458150230923522, -9.288176388325546],
              [-35.457822195942533, -9.28760988752459],
              [-35.457097392103286, -9.286900357240148],
              [-35.457089290162592, -9.286902616218649],
              [-35.456864701805998, -9.286965178623371],
              [-35.456357339554607, -9.287068206515432],
              [-35.45591866177466, -9.287179778418345],
              [-35.455434187349915, -9.28728278333179],
              [-35.455132834530019, -9.287393377368209],
              [-35.454602585350017, -9.287605136566549],
              [-35.453866350679363, -9.287872124232164],
              [-35.453450560658773, -9.28805526971362],
              [-35.452947017775116, -9.288800072639974],
              [-35.452809693670723, -9.288954556557089],
              [-35.452695256486095, -9.289009877108743],
              [-35.452538841390975, -9.289308389117389],
              [-35.452451110578806, -9.289815727898224],
              [-35.452451110366226, -9.290041760500271],
              [-35.452458724654598, -9.29073699420759],
              [-35.452786805719249, -9.291213808563233],
              [-35.453503949202641, -9.291299676697875],
              [-35.453690888693295, -9.291397861264143],
              [-35.453923556744869, -9.291594347265018],
              [-35.454182954448491, -9.291945340335143],
              [-35.454369893692423, -9.292305762279769],
              [-35.45451103644492, -9.292494612242939],
              [-35.454629290251624, -9.292810260400735],
              [-35.45451867278404, -9.29313550228674],
              [-35.454331733174108, -9.293164116936198],
              [-35.453988446688236, -9.293257552039721],
              [-35.453595520511335, -9.293422536654612],
              [-35.453339942362419, -9.293514085851218],
              [-35.452973699025428, -9.293634272767923],
              [-35.452626595372912, -9.293935626582085],
              [-35.452260374951429, -9.294145429944619],
              [-35.451794992433307, -9.294194068211747],
              [-35.451264766364552, -9.294287527141453],
              [-35.451035847902325, -9.294497330631442],
              [-35.451012959897255, -9.294687111436561],
              [-35.450971003824051, -9.295102923826164],
              [-35.450856590494723, -9.29531177442839],
              [-35.450490347165911, -9.295423369398213],
              [-35.449979212944449, -9.295525396292673],
              [-35.449971484002987, -9.295525442285179],
              [-35.449609151746976, -9.295528236931458],
              [-35.449307799029476, -9.295530168639729],
              [-35.449305819044085, -9.295530611635124],
              [-35.449101813546989, -9.295576898159487],
              [-35.448846234440367, -9.295623627633141],
              [-35.448754686023072, -9.29574188281682],
              [-35.448739435492463, -9.296428547556191],
              [-35.448724161935914, -9.297142873124544],
              [-35.448472401365983, -9.297651119747712],
              [-35.448060430052671, -9.298114616498848],
              [-35.447438608498928, -9.298398833163709],
              [-35.44742966756661, -9.298398903154972],
              [-35.446904564543686, -9.298402675643946],
              [-35.446488751735387, -9.298360672517534],
              [-35.446072985012869, -9.298227143957176],
              [-35.445584667864587, -9.298067910488379],
              [-35.44511928571211, -9.297728373156005],
              [-35.444837001045904, -9.297522410167584],
              [-35.444371618919519, -9.29715521300635],
              [-35.444070265312618, -9.297039822440144],
              [-35.443723114942529, -9.297041755105866],
              [-35.443719831966995, -9.297042243099751],
              [-35.443211933746873, -9.297117099165202],
              [-35.442750370157171, -9.297211488152874],
              [-35.442749182167368, -9.297210231159529],
              [-35.442540565959519, -9.296986434349952],
              [-35.442353649545261, -9.296807154285153],
              [-35.44239942339393, -9.296599212613749],
              [-35.442513859637614, -9.296480981451204],
              [-35.442696958378768, -9.296343657470551],
              [-35.442902944107061, -9.296035621566917],
              [-35.443086065865117, -9.295880137698575],
              [-35.443337826093796, -9.295734268834501],
              [-35.443868075273201, -9.295522532636314],
              [-35.444352549910072, -9.295193519120945],
              [-35.444627197237189, -9.294758637065559],
              [-35.444642470623833, -9.294224592382747],
              [-35.444524193816548, -9.293909875219418],
              [-35.444337276411204, -9.293721049213802],
              [-35.443990172185231, -9.293569382829652],
              [-35.443734570358814, -9.293318600143495],
              [-35.443425580200937, -9.292787396142263],
              [-35.443124203721553, -9.292536591413832],
              [-35.443116286779485, -9.292538802392793],
              [-35.442525292104911, -9.292703460825829],
              [-35.442269713845953, -9.292912310296858],
              [-35.442273531578422, -9.293166002731233],
              [-35.442181983059868, -9.293392012248336],
              [-35.442071341633962, -9.293673341405567],
              [-35.441838673243488, -9.293837418174771],
              [-35.441449543038338, -9.294002378793307],
              [-35.440801061839743, -9.294124452436307],
              [-35.440293723640259, -9.294173066664666],
              [-35.44028888067696, -9.294173089660072],
              [-35.439713881032631, -9.29417688610285],
              [-35.439065377944608, -9.294181658471405],
              [-35.438649565119483, -9.294157816232916],
              [-35.438642370173433, -9.294158492222071],
              [-35.438348234377081, -9.294186454776138],
              [-35.438188023420338, -9.294368598500789],
              [-35.438096451020456, -9.294468715796494],
              [-35.438073564024407, -9.294648972660278],
              [-35.437920966337977, -9.295545419973747],
              [-35.437577656589916, -9.295918322348534],
              [-35.437230506109692, -9.296037531288899],
              [-35.437225942145545, -9.296036204292863],
              [-35.436837604196263, -9.295923071632178],
              [-35.436509567894149, -9.295698947713978],
              [-35.436116642066096, -9.295493916617406],
              [-35.435811447658061, -9.295198268162867],
              [-35.435552050971943, -9.294829231204865],
              [-35.435063756926176, -9.294561219409463],
              [-35.434506849475945, -9.29421311304594],
              [-35.434501912509916, -9.294216816018457],
              [-35.434091036337023, -9.294523081742582],
              [-35.433698133271143, -9.294570742083106],
              [-35.43353789939988, -9.294662314367951],
              [-35.433537899289547, -9.294779615642318],
              [-35.434106286971691, -9.295851545539179],
              [-35.434358070039416, -9.29587634261949],
              [-35.434617466861845, -9.296100441474117],
              [-35.434918796247025, -9.296451434582767],
              [-35.435224014722031, -9.296675534479906],
              [-35.435410909136358, -9.296854814544524],
              [-35.435689373763985, -9.297132325086507],
              [-35.436276830471854, -9.298023067122488],
              [-35.436440883007315, -9.298257667823748],
              [-35.436582024785544, -9.298418856957856],
              [-35.436883378375093, -9.298552361411897],
              [-35.437234324546488, -9.298730710634686],
              [-35.437352579301688, -9.299100725455995],
              [-35.437634862619404, -9.299676726155635],
              [-35.437756913286506, -9.300109698591168],
              [-35.43771118550486, -9.300246067705302],
              [-35.437600567926047, -9.300689564859679],
              [-35.437590859000274, -9.30068886585493],
              [-35.437253416581399, -9.300664743690747],
              [-35.436879559456223, -9.30062185660876],
              [-35.43613950610483, -9.300581786169245],
              [-35.435651259914373, -9.300467327423727],
              [-35.435376589012172, -9.300451122268854],
              [-35.434796746403947, -9.30045589570082],
              [-35.434795350414134, -9.300456360696618],
              [-35.434289408094351, -9.300621857203144],
              [-35.433900300650542, -9.30104051025242],
              [-35.433690496112874, -9.301176880214109],
              [-35.433484511452065, -9.301413389560013],
              [-35.433347188407026, -9.30150496186606],
              [-35.432980968011442, -9.30168805939349],
              [-35.432797846007603, -9.302104781645957],
              [-35.432732979886204, -9.302756148557048],
              [-35.432645272312357, -9.30300984190654],
              [-35.432397330654489, -9.303581092143093],
              [-35.431981517515418, -9.303891036839872],
              [-35.431519953927122, -9.303984518832914],
              [-35.431512292985182, -9.30398456582552],
              [-35.431008749800625, -9.303987335340592],
              [-35.430245832579949, -9.303993087596323],
              [-35.430238847632474, -9.30399357558677],
              [-35.429967366672578, -9.304013110213793],
              [-35.429505779033441, -9.30416091187085],
              [-35.429276906572227, -9.304369760366479],
              [-35.428838228827601, -9.304445104492977],
              [-35.428376642179494, -9.3046024760908],
              [-35.42796085221913, -9.304722662961154],
              [-35.427270391297562, -9.304889533287636],
              [-35.426694368337699, -9.305237637599413],
              [-35.426373946374142, -9.305655313718361],
              [-35.426217530015087, -9.306234201992575],
              [-35.42622516628451, -9.306948503581664],
              [-35.426202279237117, -9.307183105109424],
              [-35.425466045559496, -9.307459684714656],
              [-35.425050232471875, -9.307715262747573],
              [-35.424638261286923, -9.308043345335548],
              [-35.424619168827199, -9.308685143348342],
              [-35.424855701404717, -9.309352717439314],
              [-35.425607186970339, -9.310133794307049],
              [-35.426026795314002, -9.310637338582877],
              [-35.426499790930244, -9.311483236791144],
              [-35.42652651936185, -9.311871389415529],
              [-35.426480745572327, -9.312016373476375],
              [-35.426251872982576, -9.312361569128804],
              [-35.426068774088847, -9.312661059106466],
              [-35.425748328321099, -9.312870839511225],
              [-35.425309628601966, -9.312919500802396],
              [-35.425304925637697, -9.31291943179844],
              [-35.424451321122376, -9.312907068081286],
              [-35.424195742084784, -9.312881317002917],
              [-35.423757065450907, -9.312839383854431],
              [-35.423200133730035, -9.31278024570241],
              [-35.422456261630849, -9.312504619715474],
              [-35.421296600587816, -9.312331975705151],
              [-35.421289871637853, -9.312333023692419],
              [-35.420812125190835, -9.312407343788655],
              [-35.420190326646619, -9.312682967505975],
              [-35.419499890571728, -9.313012959823212],
              [-35.419290086008303, -9.313176966613771],
              [-35.418622512674744, -9.313597551391915],
              [-35.41825629339008, -9.31366334964453],
              [-35.418251148429462, -9.313662977642055],
              [-35.417676473826937, -9.313621370365132],
              [-35.416932602523232, -9.313563232033198],
              [-35.41607813604044, -9.313524093480918],
              [-35.415521181286003, -9.313501229104636],
              [-35.415032887114215, -9.313368678470546],
              [-35.414361496457836, -9.313101621498314],
              [-35.413613829710535, -9.31248364162578],
              [-35.413030168525339, -9.312071647631747],
              [-35.412541899574443, -9.311704495449032],
              [-35.412099381251359, -9.311364051143693],
              [-35.411630203172798, -9.310978738091157],
              [-35.411119022242012, -9.310774661878567],
              [-35.410886331201816, -9.310567698942585],
              [-35.410726120458278, -9.310523833065075],
              [-35.410539202985511, -9.310407487611142],
              [-35.410092889776628, -9.309976423863029],
              [-35.409837287993653, -9.309680798454329],
              [-35.409253649918725, -9.30915152718635],
              [-35.40873865138343, -9.308558320377765],
              [-35.408460185689513, -9.308352311393566],
              [-35.407620946432957, -9.307950841097716],
              [-35.406854186617707, -9.307558869810563],
              [-35.406060769124132, -9.307040052283488],
              [-35.404782829311038, -9.306515531341869],
              [-35.404668393263449, -9.306425890790228],
              [-35.40424878565625, -9.306202768781052],
              [-35.403455343086641, -9.305765023752754],
              [-35.402619899758463, -9.305409281178163],
              [-35.401387781487749, -9.305002059553866],
              [-35.400018315343281, -9.304505221356786],
              [-35.399018842351992, -9.304051247238098],
              [-35.398484797552882, -9.30389199172788],
              [-35.397649400267731, -9.303490473437069],
              [-35.396760567633734, -9.302827673713702],
              [-35.396039606599842, -9.302299357314048],
              [-35.395383466218583, -9.30161748892502],
              [-35.394822692268171, -9.300771543640074],
              [-35.394654844127103, -9.300148813338392],
              [-35.394250487016002, -9.299275231369911],
              [-35.393987294200628, -9.29801163494645],
              [-35.393907200700212, -9.297062731745333],
              [-35.393876653661685, -9.296286403522089],
              [-35.393827061386581, -9.295915457772155],
              [-35.393449409047342, -9.295068603663704],
              [-35.393094667517289, -9.294239818464833],
              [-35.392644512727905, -9.293392966289503],
              [-35.392015123194255, -9.292656731263456],
              [-35.391244545553278, -9.292110300931721],
              [-35.390473991971319, -9.29150086399024],
              [-35.389638570804458, -9.290973479480755],
              [-35.388448361539574, -9.290221994030057],
              [-35.38828433197834, -9.290015030159342],
              [-35.388002071550503, -9.289556330737602],
              [-35.387651101881161, -9.288844891817016],
              [-35.387410797458202, -9.288041880566064],
              [-35.387174287768218, -9.287492562747943],
              [-35.386800431077226, -9.286989042519183],
              [-35.385491990166713, -9.285750194977744],
              [-35.384671867112786, -9.284978686995277],
              [-35.384320897180018, -9.284547623339447],
              [-35.383878379060889, -9.283989713384313],
              [-35.383386313392748, -9.283351709879424],
              [-35.382405931689746, -9.282436172641129],
              [-35.381494212177621, -9.28182776156477],
              [-35.380796116037686, -9.281226917639486],
              [-35.380075153842206, -9.280870243180983],
              [-35.37921684558593, -9.280623233916341],
              [-35.378682801872181, -9.280373381969426],
              [-35.378053367076255, -9.27991656721608],
              [-35.377122600538954, -9.279489300000957],
              [-35.376634331418835, -9.279302405706643],
              [-35.375425077077736, -9.27878645278328],
              [-35.374471400850318, -9.278214247445034],
              [-35.373609274861195, -9.277713567748618],
              [-35.373189643516348, -9.277211001473381],
              [-35.372579323982237, -9.27632025942628],
              [-35.372014732236977, -9.275284558319717],
              [-35.371614216069702, -9.274437705195268],
              [-35.370538467242092, -9.2733600458764],
              [-35.36932539447254, -9.27226522853729],
              [-35.368715027938414, -9.27137448649148],
              [-35.36796738403855, -9.270918627624646],
              [-35.367131963736021, -9.270536202221761],
              [-35.366506322657663, -9.270350240795658],
              [-35.366063827139506, -9.270217689207911],
              [-35.365415346140772, -9.270131822140577],
              [-35.365113969663469, -9.269880084421827],
              [-35.364381553376042, -9.268646988385346],
              [-35.363858942022297, -9.266852141023936],
              [-35.363481290537095, -9.266158815971338],
              [-35.363336352017235, -9.265752573354774],
              [-35.362657324206353, -9.26464536858866],
              [-35.362302559386706, -9.26412366349404],
              [-35.361905838783649, -9.26371073868655],
              [-35.361440455675741, -9.26332544964448],
              [-35.361207741745126, -9.263001232438786],
              [-35.360364706932245, -9.262157219891025],
              [-35.359475898618406, -9.261151087305963],
              [-35.358819734397152, -9.260296597996199],
              [-35.358213232570662, -9.259686231219359],
              [-35.357885150430576, -9.259290464369496],
              [-35.357557091298339, -9.25888610757295],
              [-35.357297693780573, -9.258336789738349],
              [-35.357133665201935, -9.258147963757386],
              [-35.356874268642187, -9.257643487644964],
              [-35.356336405193971, -9.257140852264202],
              [-35.355478097149849, -9.256667856404118],
              [-35.354844867425292, -9.256165266935257],
              [-35.354192543869758, -9.255636926608561],
              [-35.353425785241491, -9.255045652566515],
              [-35.35297949414101, -9.25449728955425],
              [-35.352510293434534, -9.253713347981343],
              [-35.35222419135534, -9.252911337689516],
              [-35.351869402645882, -9.252271424329509],
              [-35.351304833957023, -9.25117278862017],
              [-35.350976775094509, -9.250479441616378],
              [-35.35057623664742, -9.249930146652732],
              [-35.350038373476877, -9.249129999117912],
              [-35.349588194819823, -9.248138161852649],
              [-35.349160972962565, -9.247173985437247],
              [-35.348691772381045, -9.246255630699302],
              [-35.348008948698549, -9.245039670610108],
              [-35.347562635808714, -9.244265298000801],
              [-35.347417674253421, -9.243896237155864],
              [-35.347348965926223, -9.243734094098045],
              [-35.347253644970117, -9.243390785139187],
              [-35.347062910762126, -9.243021724252152],
              [-35.346502159875122, -9.242103322431017],
              [-35.345842223756762, -9.241167760625203],
              [-35.34498009704479, -9.240369546781634],
              [-35.344419346895975, -9.239731543222199],
              [-35.343484740801692, -9.23886080176211],
              [-35.342992652115655, -9.238239981159589],
              [-35.342664593033426, -9.237781257702808],
              [-35.342546338123967, -9.237574316877518],
              [-35.342428083273234, -9.237304395442848],
              [-35.342359421886833, -9.237205256994574],
              [-35.342168686695565, -9.236818059220612],
              [-35.34193219921444, -9.236041753818162],
              [-35.341646096965647, -9.235419978411658],
              [-35.34131799211935, -9.234708493523003],
              [-35.341195918407699, -9.234320340818408],
              [-35.340951818809046, -9.23265904589921],
              [-35.341012843096109, -9.231854171948251],
              [-35.341215011017589, -9.231364947169126],
              [-35.341211191535415, -9.230840402419648],
              [-35.341230261693724, -9.230515207454566],
              [-35.341554524259315, -9.230486591930337],
              [-35.341832990273744, -9.230349269038385],
              [-35.342111480204522, -9.230301561590492],
              [-35.342499190390313, -9.230163771801902],
              [-35.34250056438033, -9.230163305806043],
              [-35.342779030268609, -9.230161420073866],
              [-35.342943059024584, -9.23016044223081],
              [-35.34310326974996, -9.230223376987778],
              [-35.34344208615596, -9.230247567149341],
              [-35.343450421092093, -9.230248174153248],
              [-35.343683112437212, -9.230128941106925],
              [-35.343561038725355, -9.229740810402387],
              [-35.343492377355794, -9.229623488067055],
              [-35.343374122420364, -9.229444208070467],
              [-35.343072769899472, -9.22923822207113],
              [-35.343000288542001, -9.229139036619774],
              [-35.342862941684821, -9.229031258162056],
              [-35.342607363732078, -9.228915891642721],
              [-35.342489107671369, -9.228871070811996],
              [-35.342233576827574, -9.228638356020722],
              [-35.342046613304376, -9.228576377233271],
              [-35.341836809072618, -9.228387551211778],
              [-35.341371449786728, -9.2281920659966],
              [-35.340654237503905, -9.227898326159419],
              [-35.340047735339127, -9.227649383146156],
              [-35.339609034776281, -9.227534015458565],
              [-35.338746908701829, -9.227123907210265],
              [-35.338510422899162, -9.226691912673054],
              [-35.33841503199266, -9.226295215046619],
              [-35.338415032287593, -9.225978542011442],
              [-35.338411212594572, -9.225680052859884],
              [-35.338453169620706, -9.22531003719422],
              [-35.338590515898801, -9.224965773456525],
              [-35.338842299208949, -9.224728309161295],
              [-35.339254270513706, -9.224264789415979],
              [-35.339460257144715, -9.22405598590086],
              [-35.33968912971222, -9.223728859140904],
              [-35.339845545997427, -9.223221473432824],
              [-35.339841726346101, -9.22287816355947],
              [-35.339696767235573, -9.222030356686727],
              [-35.339692925416585, -9.221867281695085],
              [-35.339647150066739, -9.221542063670938],
              [-35.33896437322894, -9.220489201576656],
              [-35.338731658271335, -9.220192598203367],
              [-35.338216659581263, -9.219761558404956],
              [-35.337613953412252, -9.219485978561302],
              [-35.336473361576196, -9.218941410892882],
              [-35.336007978273578, -9.21876399356637],
              [-35.335500639230851, -9.218649579810387],
              [-35.334966595365096, -9.218562781858527],
              [-35.33434095517687, -9.218495073704053],
              [-35.333921347502894, -9.21834347725949],
              [-35.333272866698458, -9.218049690487122],
              [-35.332967671424889, -9.217609105941129],
              [-35.332918102036274, -9.217356367464134],
              [-35.332914283578084, -9.216805139881687],
              [-35.332952396978889, -9.216063223521257],
              [-35.333219455129836, -9.214796693627028],
              [-35.33332627813499, -9.213919316170591],
              [-35.333299548934697, -9.213277518129443],
              [-35.333150769752116, -9.21253744058636],
              [-35.333101199556687, -9.212076807399976],
              [-35.333097381837462, -9.211806002077276],
              [-35.332933352552352, -9.211300549064068],
              [-35.332578588099814, -9.21038119244502],
              [-35.332155160764962, -9.209895786069467],
              [-35.331666868913601, -9.209419880575522],
              [-35.331338809762663, -9.209033660671974],
              [-35.331193848332788, -9.208528183676707],
              [-35.33098022664106, -9.207789108068591],
              [-35.330789491625822, -9.207211175481298],
              [-35.330617824759116, -9.206317593871015],
              [-35.330636893126538, -9.205766365310465],
              [-35.330675077693435, -9.204844145070634],
              [-35.330739897713435, -9.204292941552005],
              [-35.330850539478654, -9.203641549697448],
              [-35.330888676760516, -9.203026457551015],
              [-35.330953520847579, -9.202402749482667],
              [-35.331197666961558, -9.201361320172149],
              [-35.331399857216716, -9.200510671638797],
              [-35.331529544963765, -9.199722934648435],
              [-35.3315028625683, -9.199289939312306],
              [-35.331197667529274, -9.198595660343205],
              [-35.331189191588514, -9.198601131301482],
              [-35.33094211231672, -9.198759690090561],
              [-35.330644553422111, -9.198924696793366],
              [-35.330507206396426, -9.198998131211537],
              [-35.330415681007025, -9.199088725565186],
              [-35.330072348115486, -9.199624702922701],
              [-35.329866362250478, -9.200086244868285],
              [-35.329732834944338, -9.200430554608136],
              [-35.329713742534942, -9.201027555884057],
              [-35.329812952077859, -9.201785724267831],
              [-35.329957911440914, -9.20236365681258],
              [-35.330102872661271, -9.203095095404413],
              [-35.329873977011594, -9.203511886607002],
              [-35.32958025974412, -9.204046886016227],
              [-35.329557372640586, -9.204345375142104],
              [-35.32965653552737, -9.204733503823475],
              [-35.329587873703922, -9.205104497457334],
              [-35.329339931921496, -9.205819753789541],
              [-35.329393320936781, -9.206443461966501],
              [-35.329584055045061, -9.206921254175338],
              [-35.3297252197981, -9.207110080132658],
              [-35.330076143514965, -9.207776722316131],
              [-35.330312676209104, -9.208326040123119],
              [-35.330316471709665, -9.208832446983038],
              [-35.330488160727526, -9.209562931606101],
              [-35.330793332850057, -9.210166639138652],
              [-35.331075593322822, -9.210580519828536],
              [-35.331449427109355, -9.210984876661671],
              [-35.331895763926241, -9.211840321761471],
              [-35.331994926467701, -9.212599467140466],
              [-35.33202163175249, -9.21315069374363],
              [-35.331987312987799, -9.214253125869568],
              [-35.331785122874237, -9.214950245357173],
              [-35.331605843830566, -9.215385149493306],
              [-35.331472337246105, -9.216027927381298],
              [-35.331521884374169, -9.216560993118371],
              [-35.331617275247332, -9.216993965518736],
              [-35.33202163214618, -9.218103126006374],
              [-35.332563337036987, -9.219175055851396],
              [-35.33284944021927, -9.219869334805596],
              [-35.333059220392684, -9.220121072436028],
              [-35.334020532663381, -9.221660317767201],
              [-35.334604193723585, -9.222207726906573],
              [-35.335092463810611, -9.22242994097633],
              [-35.335718056981378, -9.222516740012548],
              [-35.336320787368393, -9.222557742312029],
              [-35.336809056555815, -9.222672155050716],
              [-35.337388875964898, -9.222876255317111],
              [-35.337507130857809, -9.223101333029202],
              [-35.337442310726495, -9.223770814815547],
              [-35.337282076765106, -9.223961526484931],
              [-35.336942585633267, -9.224722557450862],
              [-35.336854831826855, -9.225229943221995],
              [-35.336812851741414, -9.225663870491001],
              [-35.336915856034068, -9.226657593420047],
              [-35.337083704096585, -9.227369963154462],
              [-35.33755290500757, -9.227936464071096],
              [-35.337995447322555, -9.22828645530657],
              [-35.338483693409671, -9.228508693376707],
              [-35.338899507146195, -9.228624037043463],
              [-35.339342002639185, -9.228783340462076],
              [-35.339876069403473, -9.228978847740281],
              [-35.340387226375469, -9.229138105222342],
              [-35.340989932569087, -9.229387001232539],
              [-35.341249353087377, -9.229937274057411],
              [-35.341001388656565, -9.23027296974681],
              [-35.34099535770757, -9.230267358776068],
              [-35.340673306431057, -9.229967798338226],
              [-35.340486412916206, -9.229896272610063],
              [-35.339788315420932, -9.229674990340765],
              [-35.33918560914298, -9.229516687768584],
              [-35.338651541404509, -9.229293542661813],
              [-35.33807172302042, -9.229062737560534],
              [-35.337304987172388, -9.228706063068303],
              [-35.3366297536218, -9.228357981606997],
              [-35.335954590213795, -9.227856370098435],
              [-35.335416703738893, -9.227381441550618],
              [-35.334997073157702, -9.227130612721099],
              [-35.334554577774874, -9.226853101036161],
              [-35.334371479222469, -9.226791098252574],
              [-35.333833616604373, -9.22646972175218],
              [-35.333158429154899, -9.226012884966192],
              [-35.332578586931866, -9.225609480936381],
              [-35.332132273703316, -9.225197510082468],
              [-35.331735529318692, -9.224548981742174],
              [-35.331338808731346, -9.22411794205235],
              [-35.330682668372496, -9.223408412852368],
              [-35.330541526594772, -9.223246293728755],
              [-35.329980775471007, -9.222580606344522],
              [-35.329488663968974, -9.221761412976198],
              [-35.329317021019264, -9.220958402802014],
              [-35.329099626719291, -9.219830196604109],
              [-35.32871813284055, -9.218513188427545],
              [-35.328214589476161, -9.216563881063706],
              [-35.327669089580255, -9.215528200991164],
              [-35.327173206391869, -9.214401904526939],
              [-35.326681071973582, -9.213492093722573],
              [-35.326421721310275, -9.213096327941219],
              [-35.326166119584812, -9.212736766938567],
              [-35.325811354880749, -9.212088262638618],
              [-35.324800448977356, -9.210557609212925],
              [-35.324354136025647, -9.209847148213965],
              [-35.324117626315562, -9.209314966300823],
              [-35.323858252139644, -9.208394678776182],
              [-35.323659856844579, -9.207104352605056],
              [-35.323438620824476, -9.205704386093926],
              [-35.323244044072226, -9.204874669067015],
              [-35.323011376282174, -9.204396876820226],
              [-35.322473512940867, -9.203777011175832],
              [-35.321912762748482, -9.203183804345068],
              [-35.321321466215643, -9.20213001134629],
              [-35.320852264756759, -9.201075217465981],
              [-35.320238079338147, -9.200085289050209],
              [-35.319791765469802, -9.199284211615772],
              [-35.319318770064164, -9.19820273389837],
              [-35.318384163244069, -9.197033550303061],
              [-35.317777638230758, -9.196621579305942],
              [-35.31689644320884, -9.196310656429839],
              [-35.315992359326245, -9.196036940301711],
              [-35.315458315503491, -9.195904389635858],
              [-35.315111187152958, -9.195889115412786],
              [-35.314645781767631, -9.195801384531423],
              [-35.313020714506521, -9.195368411732408],
              [-35.311792391201735, -9.194969783083554],
              [-35.310911196036905, -9.194813390248081],
              [-35.309820196573831, -9.194539696949176],
              [-35.308820746667003, -9.193995128416718],
              [-35.307848024214131, -9.193820621610172],
              [-35.306776095201656, -9.192905084316163],
              [-35.305398992688332, -9.191793082963615],
              [-35.304906903999218, -9.191173216364117],
              [-35.303949410101872, -9.19027486106897],
              [-35.303247517407016, -9.189221975968096],
              [-35.302774475017635, -9.188122408366866],
              [-35.302164154652814, -9.18704188652204],
              [-35.301572858177053, -9.185925135919964],
              [-35.301431714481993, -9.185673352355344],
              [-35.301057881963558, -9.184979074327448],
              [-35.300447561405861, -9.184106470191489],
              [-35.299951632038692, -9.183169977557343],
              [-35.299036117516081, -9.18152202196103],
              [-35.298093873221795, -9.179846384513006],
              [-35.297571260701162, -9.178213703182163],
              [-35.297189813790666, -9.176924331847079],
              [-35.297067764160374, -9.176445584711102],
              [-35.29685027670476, -9.175480453510938],
              [-35.296659587795801, -9.174785267657615],
              [-35.296419260404925, -9.173937413707989],
              [-35.296182727734625, -9.173360434078242],
              [-35.295923330237876, -9.172783479427544],
              [-35.295476992518623, -9.171819303013226],
              [-35.295263394515295, -9.171414013337445],
              [-35.294771306042833, -9.170558546205855],
              [-35.294412722632366, -9.1696210757061],
              [-35.294408904321656, -9.168906773142998],
              [-35.294584364992517, -9.167821501049756],
              [-35.294736962620028, -9.16697082947737],
              [-35.29504977137465, -9.165748211363505],
              [-35.295087909020069, -9.164736373688765],
              [-35.295080295788061, -9.163967682460745],
              [-35.295072658521796, -9.163236244001252],
              [-35.295072658823102, -9.162910071029129],
              [-35.295042134756109, -9.162150948720893],
              [-35.29501542906727, -9.16203364842584],
              [-35.294969654497606, -9.161943985941566],
              [-35.294923879970241, -9.161808594741586],
              [-35.294801806556038, -9.161095246065393],
              [-35.294630163600928, -9.160292212901775],
              [-35.294485202593748, -9.159326150776213],
              [-35.294527182701202, -9.158864562684554],
              [-35.294660664097101, -9.158421089563637],
              [-35.294908629766347, -9.157823155507577],
              [-35.294996383734826, -9.157135559863008],
              [-35.294992541214746, -9.15664728989559],
              [-35.294962041104831, -9.155933942303454],
              [-35.294992541240305, -9.155536243804205],
              [-35.295003997279224, -9.155399874662612],
              [-35.295095545834947, -9.155128090436023],
              [-35.295160389726384, -9.154712255080906],
              [-35.295248143594279, -9.15413341376029],
              [-35.295358785263744, -9.153581231294798],
              [-35.295469379825363, -9.153146326100014],
              [-35.295465585428801, -9.152523572969114],
              [-35.295271031521949, -9.151855999943217],
              [-35.295011657998948, -9.151305751128758],
              [-35.294778920039711, -9.151009146761242],
              [-35.29431358395388, -9.150596198905404],
              [-35.293684125137965, -9.150157521060143],
              [-35.293172967199496, -9.149962012810445],
              [-35.292684675163052, -9.149684501091567],
              [-35.292497780730805, -9.14952331292382],
              [-35.2921277427305, -9.149317350867744],
              [-35.291730999015705, -9.149021701345111],
              [-35.291311390407628, -9.148798533350959],
              [-35.291032924736705, -9.148565818544753],
              [-35.290792596218473, -9.147853449756472],
              [-35.290880350994463, -9.147373723820158],
              [-35.291021470031978, -9.147255468684159],
              [-35.291181726966371, -9.147091439850279],
              [-35.291364802702837, -9.146954115871056],
              [-35.291639449727334, -9.146834906862585],
              [-35.292219291477551, -9.146668968422633],
              [-35.292955527083429, -9.146456323415542],
              [-35.293485776184774, -9.146318021758479],
              [-35.293947316875595, -9.146106285495657],
              [-35.294363153061987, -9.145733406193386],
              [-35.294794006817412, -9.145704372766268],
              [-35.294801829758491, -9.145703836776709],
              [-35.295194755777779, -9.145700996152096],
              [-35.295496108465947, -9.145726747266329],
              [-35.295797485138138, -9.145769658273826],
              [-35.295934785029715, -9.145841183953989],
              [-35.296655770368268, -9.146044306347182],
              [-35.298322770404759, -9.146377137795197],
              [-35.299394700663868, -9.147030414708629],
              [-35.300275872804079, -9.147214467366528],
              [-35.301088429456399, -9.14740806690261],
              [-35.302106925538297, -9.14760930257885],
              [-35.302595218766989, -9.147678895590806],
              [-35.30275542949348, -9.147740921351053],
              [-35.303705287112471, -9.147924975071705],
              [-35.304563595434502, -9.148100436762471],
              [-35.305399016930266, -9.14827592143229],
              [-35.306481960582786, -9.14841270856823],
              [-35.306486196550132, -9.14841324456877],
              [-35.30768781461007, -9.14821585089083],
              [-35.30851941660228, -9.147884927706041],
              [-35.309652372116872, -9.147759967515082],
              [-35.310228372980987, -9.147503434634865],
              [-35.310871522185067, -9.147409812802866],
              [-35.310876878145102, -9.147409043812532],
              [-35.311800029101349, -9.147448114410594],
              [-35.313009306545155, -9.147856289974442],
              [-35.31381289947489, -9.147824065906933],
              [-35.313817975436507, -9.147823879912691],
              [-35.314617982409075, -9.14777479894674],
              [-35.314626736343151, -9.147774263958013],
              [-35.315573195164944, -9.147767650861473],
              [-35.315576571139339, -9.147767627864704],
              [-35.316270849915057, -9.147718010805701],
              [-35.316773672150667, -9.147661293616453],
              [-35.316778212116702, -9.147660781623788],
              [-35.31793400835064, -9.147653168724169],
              [-35.319162378033873, -9.147645554890786],
              [-35.319848251832255, -9.147640827545105],
              [-35.319856633768723, -9.147640758553164],
              [-35.320432657508292, -9.147519638830991],
              [-35.321241372691198, -9.147171556731815],
              [-35.321973788862422, -9.146380025319882],
              [-35.322732911463234, -9.14598709945424],
              [-35.3232402728746, -9.145702882683398],
              [-35.323793385870957, -9.14549214649738],
              [-35.32436940971111, -9.145262319450884],
              [-35.324945410684329, -9.144888463299795],
              [-35.325429885310839, -9.144559448786513],
              [-35.325956316660331, -9.144185616590057],
              [-35.326257669416279, -9.144138887155128],
              [-35.326810829372015, -9.143971993696546],
              [-35.327501266578025, -9.143488450331443],
              [-35.327890372868602, -9.143224280328164],
              [-35.328878368551777, -9.141943500190186],
              [-35.329172108808002, -9.141417092730217],
              [-35.329191153913236, -9.141146240431368],
              [-35.329076717889038, -9.141029894050483],
              [-35.328886005627453, -9.140714222839215],
              [-35.328676178393152, -9.140526326816262],
              [-35.328466374134891, -9.140364230632947],
              [-35.328260388738592, -9.140320365718063],
              [-35.328119245850253, -9.140276476862407],
              [-35.327657682408571, -9.140216406815531],
              [-35.327402080413457, -9.140145835021556],
              [-35.326982472620898, -9.140121038793634],
              [-35.326684913869485, -9.140131539457363],
              [-35.326682795885347, -9.14013177145398],
              [-35.326269147980106, -9.140180154776537],
              [-35.32573508098853, -9.140228815987674],
              [-35.325726582052724, -9.14022916497777],
              [-35.325227718819825, -9.140249770395439],
              [-35.325217030900454, -9.140250330382212],
              [-35.324647899192257, -9.140280318677519],
              [-35.324026100798925, -9.140402391352364],
              [-35.323331821981313, -9.140496782133376],
              [-35.323324791034636, -9.140496827126674],
              [-35.322824459828531, -9.140500599647646],
              [-35.322290392879367, -9.140503463143567],
              [-35.321943264512278, -9.140505349815761],
              [-35.321550339491885, -9.14050823644004],
              [-35.321294761429982, -9.140510123195597],
              [-35.321286611491352, -9.140510658184825],
              [-35.320371632381118, -9.140570239981326],
              [-35.319517142478112, -9.140991756582419],
              [-35.318639788899191, -9.14125017617679],
              [-35.318632291956447, -9.141249780172407],
              [-35.31787682472806, -9.141209174737039],
              [-35.317018540412441, -9.141025122099999],
              [-35.316438698126717, -9.140685607683187],
              [-35.316179300410383, -9.140344206569898],
              [-35.315828331431298, -9.139957009658055],
              [-35.315526979016759, -9.139633699394111],
              [-35.315385835278825, -9.139426806552182],
              [-35.314985297083005, -9.138597997341417],
              [-35.314561870761111, -9.138094454087383],
              [-35.314168944924681, -9.137898037951171],
              [-35.313379299005653, -9.137803601819805],
              [-35.313373618047713, -9.137804742807523],
              [-35.312643086456021, -9.137953335218619],
              [-35.311769528490345, -9.138600885396615],
              [-35.310590773982078, -9.139095837245788],
              [-35.310582091048254, -9.139095511239896],
              [-35.309968999723068, -9.139072926822385],
              [-35.309431113053122, -9.138804984999185],
              [-35.309083962894107, -9.138581793071275],
              [-35.308595715748069, -9.138421581623287],
              [-35.308271452249159, -9.138378670595083],
              [-35.307874754557602, -9.138056340238734],
              [-35.307336868212943, -9.137435472610628],
              [-35.306921079533602, -9.137257147341412],
              [-35.3065739512083, -9.137214236292413],
              [-35.306062771085344, -9.137218054803586],
              [-35.305715643718841, -9.137219940476026],
              [-35.305227350589107, -9.137042570134923],
              [-35.30471619365845, -9.136837515945198],
              [-35.304578846783706, -9.13674787537779],
              [-35.304391929309865, -9.136631553931231],
              [-35.304086757924438, -9.136308220664658],
              [-35.303739606890176, -9.135949661579064],
              [-35.303342886008259, -9.135834246936069],
              [-35.303337507047125, -9.13583636591798],
              [-35.302927120012612, -9.135999254531509],
              [-35.302793590667086, -9.136389292984004],
              [-35.302332003893703, -9.136690645689658],
              [-35.301984852486164, -9.136737376083179],
              [-35.301983106500032, -9.136736700085835],
              [-35.301523288153653, -9.136560028766421],
              [-35.301054087112838, -9.136128941021013],
              [-35.300726028967958, -9.135733175184322],
              [-35.300561999420559, -9.13550809743521],
              [-35.300256828159874, -9.135049374011023],
              [-35.300001225565026, -9.13454485191691],
              [-35.299650279585023, -9.134158678999981],
              [-35.299162009522114, -9.133908804110265],
              [-35.298483005272544, -9.13326218651526],
              [-35.298475903322604, -9.133266330483021],
              [-35.298231221049711, -9.133408124378448],
              [-35.298212151728237, -9.133914532210852],
              [-35.298753856836605, -9.134760430441466],
              [-35.299036140912705, -9.135608238424416],
              [-35.299039960458565, -9.13606980255677],
              [-35.298902612417862, -9.136160419868167],
              [-35.298257902942808, -9.136561936784094],
              [-35.29773147253642, -9.136999659582452],
              [-35.297227975715138, -9.137698710776037],
              [-35.297254658304404, -9.137924720394453],
              [-35.297582740174775, -9.138618044380298],
              [-35.297891730255955, -9.139239819793943],
              [-35.298338021111896, -9.140060013098429],
              [-35.298429616225825, -9.140266930894766],
              [-35.298502050609969, -9.140338455515815],
              [-35.298898817199529, -9.140770473189821],
              [-35.299207784090015, -9.141599236316326],
              [-35.299257354246983, -9.142105643211814],
              [-35.299196327985108, -9.142892403262991],
              [-35.299081915578562, -9.143190938302112],
              [-35.299072439653301, -9.14318791231236],
              [-35.298944591661645, -9.143147049450086],
              [-35.298406705126055, -9.142734100528811],
              [-35.297620878237367, -9.142576730792292],
              [-35.296877006964252, -9.142490908648984],
              [-35.296159863488228, -9.142405087530072],
              [-35.294977291526813, -9.142340243857051],
              [-35.294332629417987, -9.142344062246602],
              [-35.294329858438594, -9.142344551241042],
              [-35.29350102659329, -9.142494727552553],
              [-35.293492923654739, -9.142494773544913],
              [-35.292665605932079, -9.142499499762538],
              [-35.292656479000854, -9.142500058750755],
              [-35.291811116373559, -9.142550071670309],
              [-35.290979513449791, -9.142808490306058],
              [-35.290174616256465, -9.14313941251509],
              [-35.288904337511731, -9.143562839725115],
              [-35.288305426564563, -9.144099793839953],
              [-35.287778996025793, -9.144681522742227],
              [-35.287553917267282, -9.145188860381548],
              [-35.287420412813411, -9.145696246103958],
              [-35.287382298211391, -9.14666324005438],
              [-35.287435663089546, -9.147440500268369],
              [-35.287790450464264, -9.148450450309564],
              [-35.288026960219867, -9.148936811499912],
              [-35.288423680826561, -9.149349737292916],
              [-35.288866176151664, -9.149691161572356],
              [-35.289400243841214, -9.149967717338724],
              [-35.290029656690621, -9.150370189408845],
              [-35.290563723404851, -9.150620039341387],
              [-35.291311391198079, -9.151194155451911],
              [-35.291845434787589, -9.151579443542262],
              [-35.292333728741184, -9.151946594703817],
              [-35.292871591301662, -9.152331906797603],
              [-35.293336997278587, -9.152862155924177],
              [-35.293573507058568, -9.153321834281092],
              [-35.293836699303363, -9.154141958420919],
              [-35.293748968176558, -9.155001220997701],
              [-35.2935925760709, -9.155318802880343],
              [-35.293359860794837, -9.155365532377674],
              [-35.293355530828379, -9.155364786378355],
              [-35.292757154468077, -9.155260618480758],
              [-35.292272680226802, -9.155173864578714],
              [-35.29171574862827, -9.15498692523366],
              [-35.29115879295415, -9.154882035378382],
              [-35.290483583227342, -9.154723709747634],
              [-35.289720666291565, -9.154429969879132],
              [-35.289068366640755, -9.154000815953635],
              [-35.288484682536698, -9.153498227547447],
              [-35.28794684317598, -9.152896474799801],
              [-35.287618785024314, -9.152509276909047],
              [-35.287244928202874, -9.152141148858025],
              [-35.286520171443549, -9.151341979168269],
              [-35.286123403854958, -9.150909960493882],
              [-35.285680908570527, -9.150524672487252],
              [-35.285402466917127, -9.150272912799547],
              [-35.28493321901081, -9.149697842949088],
              [-35.284860761669236, -9.149580542612663],
              [-35.28474630166243, -9.149446059344916],
              [-35.284532702708304, -9.14898642800936],
              [-35.28452888416291, -9.148524817877179],
              [-35.284547953251106, -9.148272125466297],
              [-35.284662413574495, -9.148063252869731],
              [-35.284729654211183, -9.147903507924648],
              [-35.2847310512036, -9.147900177946605],
              [-35.285023719901766, -9.147986279677518],
              [-35.285032427833272, -9.147988839669507],
              [-35.285307075107248, -9.147598825345556],
              [-35.285902191166684, -9.146971298790692],
              [-35.286295069527064, -9.146598396467896],
              [-35.286478192346685, -9.146370455052489],
              [-35.286726133831337, -9.145971847757592],
              [-35.286886367907357, -9.145654266878932],
              [-35.287157197494246, -9.144956169467855],
              [-35.28722585916455, -9.144748251823689],
              [-35.287222040593413, -9.144314371519101],
              [-35.28721822188087, -9.144033972259844],
              [-35.287218222072489, -9.143826054553202],
              [-35.287241108997996, -9.143718301244313],
              [-35.287283088812835, -9.143573317184378],
              [-35.287439481409173, -9.142722668618269],
              [-35.287412798970351, -9.142333561014482],
              [-35.287336500040013, -9.141801402255458],
              [-35.287290701662833, -9.14150291307066],
              [-35.287218222403858, -9.141295949292179],
              [-35.287145742337295, -9.140880136812957],
              [-35.287092354249651, -9.14032988818747],
              [-35.286924506156701, -9.139643246306386],
              [-35.286779568855501, -9.138993787214924],
              [-35.286661313260268, -9.138443537530623],
              [-35.28649346593361, -9.138010542071754],
              [-35.286329436477772, -9.137686300939777],
              [-35.286211157500581, -9.137550886674683],
              [-35.286092903863185, -9.137046365705999],
              [-35.286043333330866, -9.136947202277861],
              [-35.285951784208706, -9.136748853428644],
              [-35.285783937056657, -9.136126077150736],
              [-35.285638953664268, -9.135575804442537],
              [-35.285448240685767, -9.134953028143919],
              [-35.285047678423609, -9.134196793484993],
              [-35.284784486311388, -9.133230731256635],
              [-35.284738688832981, -9.133041905389923],
              [-35.284353423046134, -9.131642822745272],
              [-35.283834606254686, -9.130263834854615],
              [-35.283384473685281, -9.129163289293995],
              [-35.282938182645339, -9.128542444751995],
              [-35.282354499581189, -9.127995036628251],
              [-35.2820073713645, -9.127834825309954],
              [-35.281820477907388, -9.127700341977086],
              [-35.281419938586609, -9.127007041927797],
              [-35.281187224560817, -9.126781941117295],
              [-35.280374714124505, -9.126353787043898],
              [-35.279810122107357, -9.125597505237915],
              [-35.279134935737133, -9.125051052025746],
              [-35.278509318700173, -9.124820245894162],
              [-35.27813928056635, -9.124759221937964],
              [-35.2778379508783, -9.124733493824447],
              [-35.277349657732834, -9.124574213372444],
              [-35.276792725233115, -9.124279542700817],
              [-35.276231975887818, -9.123848455874843],
              [-35.276222848955314, -9.123850435854216],
              [-35.27524013922276, -9.124063032638727],
              [-35.275236064254599, -9.124062078640993],
              [-35.27447340320775, -9.123886617040695],
              [-35.273313742182097, -9.123704450121778],
              [-35.272226537748281, -9.123367800230323],
              [-35.271082150238762, -9.122498059605528],
              [-35.270311595784072, -9.121743686602125],
              [-35.269960649119788, -9.121014133825231],
              [-35.269582997808136, -9.120121484039601],
              [-35.269529586770112, -9.119515913761074],
              [-35.269106113888604, -9.118533622492192],
              [-35.268652162537158, -9.117226137220484],
              [-35.268297444291754, -9.116071226089208],
              [-35.2678473127201, -9.11497163452723],
              [-35.267324676831969, -9.113727105802216],
              [-35.266901274032534, -9.112654220098772],
              [-35.266168834860075, -9.111276164015676],
              [-35.265379211425234, -9.11065720615415],
              [-35.26458192736262, -9.109695916417719],
              [-35.263784644591368, -9.108417022659397],
              [-35.263174302201513, -9.107354637722537],
              [-35.262632643257568, -9.10632752762851],
              [-35.262140531714131, -9.105543609065014],
              [-35.261461526712608, -9.104626161164029],
              [-35.260782522578069, -9.103853697360227],
              [-35.260408665028763, -9.103187080173718],
              [-35.25993569233983, -9.102403136628356],
              [-35.259680067527889, -9.102134218071935],
              [-35.259210865826752, -9.101332161643029],
              [-35.258974380068715, -9.100845800458492],
              [-35.25826485011838, -9.09903381710339],
              [-35.257860470347531, -9.097770195609089],
              [-35.257665915842203, -9.096660104348539],
              [-35.257608686162676, -9.09569308732109],
              [-35.25750954769299, -9.094844325519079],
              [-35.257387474314555, -9.0940861351322],
              [-35.257192919759852, -9.093029477539407],
              [-35.256899179842769, -9.091006711876377],
              [-35.256773288600314, -9.090131243217259],
              [-35.256628328461623, -9.089300596261756],
              [-35.256552052694694, -9.088586294643491],
              [-35.256384182497769, -9.088009315086898],
              [-35.256105715925209, -9.087667915962438],
              [-35.255892093820002, -9.087370357623477],
              [-35.255239793531047, -9.086543550185908],
              [-35.254675225552091, -9.085742449667656],
              [-35.253950446805689, -9.084924188111737],
              [-35.253229461724217, -9.084440668473455],
              [-35.252348265809125, -9.084012468345733],
              [-35.251928658250186, -9.083734910696377],
              [-35.251062736616781, -9.082871876292607],
              [-35.250131924524212, -9.081956338157706],
              [-35.249338483157949, -9.081292583577955],
              [-35.248873101005138, -9.080951160285627],
              [-35.24807963670532, -9.080214925157868],
              [-35.247217532769092, -9.079651310892357],
              [-35.246961953857834, -9.079490144666149],
              [-35.246218081884059, -9.079079105556557],
              [-35.245638216641005, -9.078693817434623],
              [-35.244959235323378, -9.078119701400299],
              [-35.243723274944358, -9.076771214690542],
              [-35.243231163373544, -9.0760149329609],
              [-35.242784873397156, -9.075321608880341],
              [-35.242102049901256, -9.073879663253496],
              [-35.241747308221406, -9.073194930202332],
              [-35.241583255804642, -9.072825846355325],
              [-35.241438295309941, -9.072383350983319],
              [-35.241274243116123, -9.071770121658551],
              [-35.241201784839838, -9.071580316776586],
              [-35.241129328554806, -9.071400105834813],
              [-35.240873725940574, -9.070913721636732],
              [-35.240240495523196, -9.070067823339681],
              [-35.23932493458652, -9.068853797083626],
              [-35.238905349439847, -9.068124220254461],
              [-35.23866120355563, -9.066742344651001],
              [-35.238672658433622, -9.065684710256393],
              [-35.238993057652166, -9.064969453005336],
              [-35.239313526557865, -9.064596574619406],
              [-35.23956526279521, -9.06443254486916],
              [-35.239912413284209, -9.064294267044325],
              [-35.240072648199217, -9.06414930709269],
              [-35.240137469061722, -9.063760199577503],
              [-35.240087898982019, -9.063164129249872],
              [-35.239809409228052, -9.063021076890983],
              [-35.239130403968034, -9.062383097257538],
              [-35.238222527337967, -9.061864279674975],
              [-35.237268851129727, -9.061264437556561],
              [-35.236643234339823, -9.060762802121397],
              [-35.236612710008224, -9.060284056079631],
              [-35.236818718848411, -9.059839651036857],
              [-35.237322239545016, -9.059267422059223],
              [-35.237688459334123, -9.058640874296733],
              [-35.237707550312521, -9.058505483158328],
              [-35.237612160317127, -9.058198378987726],
              [-35.23744813080922, -9.057928480523335],
              [-35.237329923127071, -9.057467848289784],
              [-35.237554929904512, -9.056933803823277],
              [-35.23797074314389, -9.056497012921998],
              [-35.23826830122244, -9.05612506550975],
              [-35.238447581158631, -9.055797961711361],
              [-35.23860397449031, -9.05522764140936],
              [-35.238718433958866, -9.054856694826062],
              [-35.238829050671285, -9.054250147708782],
              [-35.238939669481795, -9.053535844263678],
              [-35.239027423186187, -9.053128623882683],
              [-35.239023628593834, -9.052712810472821],
              [-35.239019786919265, -9.052387615497757],
              [-35.238943488214709, -9.051601787330698],
              [-35.238985444398999, -9.051049581812928],
              [-35.238985444588337, -9.050841711109584],
              [-35.238932079643384, -9.050128363511183],
              [-35.238855756766029, -9.049532316160546],
              [-35.238714615256775, -9.049072637900984],
              [-35.238382738591483, -9.048179032176735],
              [-35.238073770545469, -9.047512437057103],
              [-35.237768623495526, -9.046818158113734],
              [-35.237318443974402, -9.04565467796707],
              [-35.237036136479169, -9.045258912182206],
              [-35.236547889415576, -9.045009015302137],
              [-35.236200739363412, -9.044667614119867],
              [-35.235662899906522, -9.044165001771908],
              [-35.235193698994998, -9.043589001943658],
              [-35.234705382285995, -9.042950020491244],
              [-35.234232362838107, -9.04189622664091],
              [-35.23387762113942, -9.041229608481277],
              [-35.233572427039995, -9.040589696199689],
              [-35.233267255047849, -9.039831528656141],
              [-35.233053633195482, -9.039254549064344],
              [-35.232698867562675, -9.038515451357402],
              [-35.232206780111774, -9.037623778479341],
              [-35.231691781415201, -9.037193669700564],
              [-35.230902157746762, -9.03682745027648],
              [-35.230112511119373, -9.036416387132249],
              [-35.229555579577493, -9.036166535191192],
              [-35.229090197340177, -9.035916661332502],
              [-35.229017693366856, -9.035393117534644],
              [-35.22924277120913, -9.034785638527929],
              [-35.229654765557733, -9.034259183183449],
              [-35.229811158748376, -9.033842439924744],
              [-35.229876024740932, -9.03330841831573],
              [-35.229643309819117, -9.032966972237723],
              [-35.229338138513626, -9.032553045547031],
              [-35.228937599219435, -9.031823515740466],
              [-35.228327233756637, -9.030832633376884],
              [-35.227770301502311, -9.030266132412702],
              [-35.226976860116558, -9.029620538729999],
              [-35.226274945027725, -9.028983467076458],
              [-35.225878224524173, -9.028452239036246],
              [-35.225550165449555, -9.02797540171828],
              [-35.225012301345593, -9.027083727801568],
              [-35.224565965423224, -9.026327446122005],
              [-35.22430656798354, -9.025678034943182],
              [-35.22413492593514, -9.024964663242482],
              [-35.223944167072652, -9.024206472804487],
              [-35.223753455020997, -9.023656223068468],
              [-35.223589424807329, -9.023061129636456],
              [-35.223562719346866, -9.022690160928443],
              [-35.223467352176989, -9.022573792569384],
              [-35.223398666887526, -9.022365896805686],
              [-35.223249887746512, -9.021562908685345],
              [-35.223127838403251, -9.020758944595265],
              [-35.222959990489173, -9.019865338023816],
              [-35.222578543409789, -9.018738110719658],
              [-35.222342009837362, -9.018042899848322],
              [-35.222177957394287, -9.017700521838984],
              [-35.222033042887929, -9.017267549412461],
              [-35.221838489242138, -9.016301487270125],
              [-35.22165157318544, -9.015724485705411],
              [-35.22125101079245, -9.015102711228934],
              [-35.22087720010542, -9.014581030151458],
              [-35.22015239746797, -9.013636899397618],
              [-35.219610716325988, -9.012817707027867],
              [-35.219236883729778, -9.012195908575947],
              [-35.218885936705519, -9.011854485393741],
              [-35.218233614247595, -9.011207889847446],
              [-35.217699569572986, -9.010912264215344],
              [-35.217161683131494, -9.010391561985529],
              [-35.216833625121723, -9.009842220122527],
              [-35.216646683901494, -9.009445475432985],
              [-35.216364400314944, -9.009148896032459],
              [-35.216036365157159, -9.008762675150932],
              [-35.215384042839069, -9.007961573570334],
              [-35.215311562511559, -9.007827091345373],
              [-35.214914841195267, -9.007087992606522],
              [-35.214701242234455, -9.00662836128611],
              [-35.214396048026558, -9.006105724277361],
              [-35.213835273890133, -9.005440037933438],
              [-35.212996080703206, -9.004957495196514],
              [-35.212069088134236, -9.004529296041728],
              [-35.211416788586064, -9.003981886877487],
              [-35.210993385512843, -9.003197968395604],
              [-35.21087510780346, -9.002765018994307],
              [-35.210589004581536, -9.002097445908641],
              [-35.210379177657536, -9.001565264045503],
              [-35.210142667698847, -9.001295366520036],
              [-35.209795539456351, -9.001162816037629],
              [-35.209356839894127, -9.001047448365942],
              [-35.208819000231074, -9.000770868612786],
              [-35.208403163716092, -9.000411307487164],
              [-35.207979737520247, -8.999762849159712],
              [-35.207689839064699, -8.999385174260066],
              [-35.205282787183528, -8.991833952290495],
              [-35.20461903249258, -8.991540235532876],
              [-35.204073531781582, -8.991380957040866],
              [-35.203386890305971, -8.99104144254912],
              [-35.202772705255647, -8.990728656955156],
              [-35.202017425366343, -8.990317617849213],
              [-35.201243028622237, -8.989906601726133],
              [-35.200991269591391, -8.989844597888792],
              [-35.200766214366169, -8.989773072134797],
              [-35.200216919740996, -8.989550882032777],
              [-35.199324269107628, -8.988905218270864],
              [-35.198504097784699, -8.988413152613802],
              [-35.197519921846009, -8.987768443764496],
              [-35.197020196967486, -8.987409861559506],
              [-35.196745526363976, -8.987067506454144],
              [-35.196421286266443, -8.986582077198795],
              [-35.196238187073888, -8.986121443914504],
              [-35.195936834007284, -8.985409075098229],
              [-35.195612570146665, -8.984661361482532],
              [-35.195273079896189, -8.984473489353922],
              [-35.19513575508617, -8.984311392244573],
              [-35.194403292227648, -8.983674320573208],
              [-35.193899796402569, -8.983288099538173],
              [-35.192804955296268, -8.982652914531924],
              [-35.19232432121489, -8.982358242945187],
              [-35.1919581252087, -8.982124597079066],
              [-35.191019699183109, -8.981190968078533],
              [-35.190561953969969, -8.980849544804688],
              [-35.189947770009468, -8.980437526832699],
              [-35.189375563722137, -8.98003412384432],
              [-35.188166309574932, -8.979299798356925],
              [-35.187525465857497, -8.978842983641499],
              [-35.18731945656085, -8.978690386411873],
              [-35.187086765824866, -8.9781410916388],
              [-35.186609927780914, -8.977772985515228],
              [-35.186308551379376, -8.977431562381312],
              [-35.186033904044805, -8.976790672143727],
              [-35.185869920787383, -8.976240398438293],
              [-35.185682957981562, -8.975383045632569],
              [-35.185148912423124, -8.973849526745864],
              [-35.184778898080481, -8.97291210227811],
              [-35.184363109110606, -8.971946948943216],
              [-35.183951114005119, -8.971100095872067],
              [-35.183603963260701, -8.970415339845097],
              [-35.183123330620127, -8.969631420319503],
              [-35.182295545900629, -8.968533739447231],
              [-35.181723340011686, -8.967687841228109],
              [-35.181170180856817, -8.966976402185191],
              [-35.180712457951607, -8.966292625054415],
              [-35.180342396471232, -8.965507727634256],
              [-35.180090659733231, -8.965120552831861],
              [-35.179880855758149, -8.964642760633661],
              [-35.179720621495569, -8.964065782100066],
              [-35.179598548294109, -8.963098765040208],
              [-35.179548955476484, -8.962204205591792],
              [-35.179499362097232, -8.961933352241948],
              [-35.179293376263786, -8.961265780234644],
              [-35.17894243102733, -8.960047956540571],
              [-35.178618189458192, -8.958974093969973],
              [-35.178293926750868, -8.958053829438645],
              [-35.178107056812237, -8.95734141272936],
              [-35.17785145466442, -8.95633053082633],
              [-35.177458528388662, -8.955510359608146],
              [-35.176977872681874, -8.954798898631999],
              [-35.176745205091002, -8.954086528882184],
              [-35.176558265047959, -8.953490458445462],
              [-35.176398053703885, -8.953003143352072],
              [-35.176325572807045, -8.952388981130422],
              [-35.176134862084062, -8.951468669719327],
              [-35.175951716905644, -8.95099090054601],
              [-35.175833485136934, -8.950620862756335],
              [-35.175715229742167, -8.94983503556845],
              [-35.175711388283133, -8.949265693127632],
              [-35.175730504658191, -8.948686804766995],
              [-35.175730504072135, -8.948226195649299],
              [-35.175722843999452, -8.947259155693935],
              [-35.175581701502431, -8.946780408564413],
              [-35.175444377853843, -8.946438030584957],
              [-35.175421491214586, -8.946230135865596],
              [-35.175394808685581, -8.945931622709967],
              [-35.175349033260581, -8.945678908250807],
              [-35.175184980920491, -8.945219229981761],
              [-35.175047657231502, -8.944921694721737],
              [-35.175020975076009, -8.944207393168281],
              [-35.175020975181383, -8.944090092902382],
              [-35.175085795094752, -8.943638050788909],
              [-35.17506293141507, -8.943474952789357],
              [-35.175013315043167, -8.943195532493991],
              [-35.174830193702228, -8.942897998193448],
              [-35.174624230468943, -8.942673874413225],
              [-35.174349583766237, -8.94244022963159],
              [-35.174212212931145, -8.942305745351296],
              [-35.173960476273535, -8.94182797711791],
              [-35.173750673224433, -8.94143125641458],
              [-35.17354466325957, -8.940908619502746],
              [-35.173357747215441, -8.940312573067361],
              [-35.173170830170363, -8.939717502625955],
              [-35.173006800179124, -8.938868717792928],
              [-35.17293432058181, -8.937919791668129],
              [-35.17283513415402, -8.9370080952867],
              [-35.172785541398831, -8.936041055295799],
              [-35.172713060363144, -8.935580422114832],
              [-35.172457506131302, -8.93466013464881],
              [-35.172228586203182, -8.934290142761812],
              [-35.171541968074756, -8.933562452707966],
              [-35.171103291023265, -8.932877743605175],
              [-35.170622634208726, -8.932284535892487],
              [-35.17007331610246, -8.931483434420445],
              [-35.169565954621703, -8.930744336597684],
              [-35.169085321904298, -8.930042467565629],
              [-35.168398657011181, -8.929052539154567],
              [-35.167689126976249, -8.92841451252024],
              [-35.16702539648805, -8.927893833191936],
              [-35.166270091994278, -8.927040273866901],
              [-35.16569786391652, -8.926401316360517],
              [-35.165102793825227, -8.925971207526382],
              [-35.164439039296141, -8.925496278912036],
              [-35.163779102681623, -8.925084307906996],
              [-35.163382382748267, -8.923911281900969],
              [-35.162123535525261, -8.922562771230531],
              [-35.161276681775831, -8.921654870166174],
              [-35.160818913625391, -8.921241921346919],
              [-35.160338256794937, -8.920665896528748],
              [-35.159857600955952, -8.920099418650883],
              [-35.159579134670579, -8.919431822585471],
              [-35.159369330927966, -8.918691793034599],
              [-35.158839105452635, -8.917022860018207],
              [-35.158446203117087, -8.91626567041315],
              [-35.157873997102719, -8.915555116357357],
              [-35.157484866180248, -8.915421634849126],
              [-35.157122488038198, -8.915306243251234],
              [-35.157030939774245, -8.915261424450923],
              [-35.156981346457606, -8.914919068551496],
              [-35.157027121377617, -8.91461960146786],
              [-35.157206424185304, -8.914428866821282],
              [-35.157385704146741, -8.914066465249929],
              [-35.15754209622586, -8.913766998264187],
              [-35.157542096475865, -8.913487578014465],
              [-35.157542096661878, -8.913279682316738],
              [-35.157446728839915, -8.912773275404524],
              [-35.157259788833485, -8.912133362247674],
              [-35.157236925128402, -8.911997948075728],
              [-35.15719115068709, -8.911762392510822],
              [-35.157187331982257, -8.911464858371284],
              [-35.157023303085616, -8.91050734122445],
              [-35.156790610661382, -8.909605166670337],
              [-35.156466371030113, -8.908594284716475],
              [-35.156279453919183, -8.908070694831432],
              [-35.156119220483411, -8.907683520115377],
              [-35.15581786659699, -8.906763231614535],
              [-35.15567674816171, -8.906212935937459],
              [-35.15542114674237, -8.905499588180813],
              [-35.155283822051345, -8.905203007917628],
              [-35.154890919593839, -8.904581209466162],
              [-35.154410263874979, -8.903879293439207],
              [-35.154112682491657, -8.903482573661984],
              [-35.153815171058419, -8.903141149538321],
              [-35.153265876603072, -8.902728200640421],
              [-35.152762309896353, -8.902207497458145],
              [-35.15237322607824, -8.901956691685948],
              [-35.151942139620324, -8.901660089163665],
              [-35.151118197372114, -8.901113633859945],
              [-35.150683315006894, -8.900745527782476],
              [-35.150252251507119, -8.900495653967509],
              [-35.149584678580418, -8.899377949382039],
              [-35.149237528105203, -8.898386135290956],
              [-35.149187958055165, -8.897744337269236],
              [-35.149180344992715, -8.896759159436625],
              [-35.149180344339214, -8.896371005869232],
              [-35.149153615711889, -8.896181226034992],
              [-35.149039179049232, -8.895657658215232],
              [-35.148852262921416, -8.895152205218048],
              [-35.148482223579748, -8.894205212826567],
              [-35.148432654545587, -8.893545275919001],
              [-35.148131277858838, -8.89239991083179],
              [-35.147826129249985, -8.891199224088259],
              [-35.147620143403842, -8.890540243036975],
              [-35.147158556506774, -8.889874578802191],
              [-35.146426140813197, -8.889046771345051],
              [-35.145853934796946, -8.888336288293894],
              [-35.145461032515335, -8.887516141088566],
              [-35.145117699696179, -8.88687620379733],
              [-35.144885008996603, -8.886280157328695],
              [-35.14454169928171, -8.885522967772745],
              [-35.144286097577734, -8.885127178028606],
              [-35.143942787612836, -8.884650340715249],
              [-35.143847420805415, -8.884125817919537],
              [-35.143958038464312, -8.883565045532718],
              [-35.144162301176038, -8.88326816257403],
              [-35.144164047165056, -8.883265600591667],
              [-35.144526425347252, -8.883336149468802],
              [-35.144640885292617, -8.883543112272159],
              [-35.144709524626286, -8.883705232316311],
              [-35.144960399623308, -8.883812637864112],
              [-35.144961306616096, -8.883813009862617],
              [-35.145190203893776, -8.883792987189887],
              [-35.145369482652427, -8.883656594203027],
              [-35.145297003614026, -8.883195985026815],
              [-35.144999445309637, -8.882709600813785],
              [-35.144679023292106, -8.882096393375829],
              [-35.144514970948947, -8.881636715113196],
              [-35.144377646137379, -8.881474619008392],
              [-35.14419454791517, -8.881041622561751],
              [-35.144076294143858, -8.880671607777442],
              [-35.143431607354401, -8.87920197842371],
              [-35.142905176924891, -8.878563974960187],
              [-35.142466500038822, -8.877689462057102],
              [-35.142027800169053, -8.876796810267892],
              [-35.141566258633368, -8.875723925588954],
              [-35.141062715343828, -8.874733066358884],
              [-35.140852865349352, -8.874272410062799],
              [-35.140601128623977, -8.873867121382684],
              [-35.140097608044627, -8.873201435113881],
              [-35.13980004957088, -8.872904854711768],
              [-35.139731388197298, -8.872787554386948],
              [-35.138880693882818, -8.871419043220465],
              [-35.137618050221157, -8.869464962364489],
              [-35.136427865510313, -8.868070677062178],
              [-35.135420802223877, -8.866882400629345],
              [-35.135050786766492, -8.866062230448346],
              [-35.134974488165824, -8.865141012159711],
              [-35.135016444747436, -8.864128172549854],
              [-35.135127062293009, -8.863693315374933],
              [-35.135214815955706, -8.863322321779277],
              [-35.135302546738849, -8.862815937032975],
              [-35.135436075254404, -8.86221893589533],
              [-35.135500918212387, -8.861711573135036],
              [-35.135565739249387, -8.861115526931135],
              [-35.13556194391311, -8.860401224408747],
              [-35.135581013113907, -8.860012117866461],
              [-35.135798429851675, -8.858447143875278],
              [-35.135931959271176, -8.857957896061906],
              [-35.136336316833336, -8.857241660910635],
              [-35.136736879098656, -8.85576635051822],
              [-35.137053483078823, -8.855330514530696],
              [-35.137816423326356, -8.854150829602128],
              [-35.138247486556629, -8.853579578564755],
              [-35.13844963031034, -8.853252474794562],
              [-35.138606069571559, -8.852745112115176],
              [-35.138781530728004, -8.852192929733635],
              [-35.139006585548017, -8.851594950683074],
              [-35.139048541638068, -8.851134317609976],
              [-35.139178275487431, -8.850193074629448],
              [-35.139334667917431, -8.849495932140872],
              [-35.139510152313903, -8.848672920458824],
              [-35.139620769923262, -8.848165558739383],
              [-35.139708500825961, -8.847523737843517],
              [-35.139910667739329, -8.847016375204456],
              [-35.140067083719536, -8.846825640538569],
              [-35.140059446796499, -8.845677410736657],
              [-35.140097607668963, -8.844367060992376],
              [-35.140269274775939, -8.842775382130853],
              [-35.140353163357936, -8.841400188834072],
              [-35.140528647986372, -8.840314893798665],
              [-35.14059349173445, -8.84004311056111],
              [-35.140543898623889, -8.839465177144428],
              [-35.140467623245421, -8.838290242451002],
              [-35.14009760807356, -8.837144853314733],
              [-35.139864916818766, -8.836043376023552],
              [-35.139468196322824, -8.834363989215941],
              [-35.139300347879015, -8.832919133137366],
              [-35.139136295670163, -8.832304971848423],
              [-35.138812078830107, -8.831520098490669],
              [-35.138579364310395, -8.830717111326981],
              [-35.138419153015711, -8.830166838640626],
              [-35.138094889111628, -8.829454445828283],
              [-35.1378850854733, -8.828588500080208],
              [-35.137492160114725, -8.827849401375584],
              [-35.137103098903175, -8.826910975925747],
              [-35.136733037466001, -8.826064122917904],
              [-35.136477458976174, -8.825423255717277],
              [-35.135928163948705, -8.824522991888072],
              [-35.135512351083612, -8.823422445433339],
              [-35.135050787278509, -8.822647094897158],
              [-35.134501492018764, -8.822009091421966],
              [-35.133563067252091, -8.820766449402687],
              [-35.132941291702679, -8.819948211996149],
              [-35.132437748279685, -8.819101335873087],
              [-35.131911317934509, -8.818363214047224],
              [-35.131270426267214, -8.817842487756371],
              [-35.131087328802508, -8.817680367614194],
              [-35.130972915871311, -8.817455312927281],
              [-35.130881365735156, -8.817265509039068],
              [-35.130671539728255, -8.816815376682134],
              [-35.130671539856415, -8.816670416592428],
              [-35.130621969696151, -8.816146849836873],
              [-35.130530373783678, -8.815704354535438],
              [-35.130461734480846, -8.815505981721152],
              [-35.130114607669441, -8.814884183322329],
              [-35.129473740409757, -8.813901869930802],
              [-35.128970196826188, -8.813236229670844],
              [-35.12816529973248, -8.812345487561235],
              [-35.12727264852834, -8.811202985956422],
              [-35.126788174191361, -8.810093872499037],
              [-35.126601303401344, -8.809200266948316],
              [-35.126231243043556, -8.808261864519116],
              [-35.125887933218728, -8.807621952238538],
              [-35.125655241800402, -8.806701640815987],
              [-35.125647605585108, -8.80587860697913],
              [-35.125479758120683, -8.804451911794445],
              [-35.125411094945747, -8.804108578891171],
              [-35.125315704037099, -8.803693767413664],
              [-35.12485798212137, -8.803009011315494],
              [-35.124194227058119, -8.802000014074455],
              [-35.12369068435428, -8.801469787965818],
              [-35.122637799382311, -8.80031010333227],
              [-35.121214922836465, -8.798455209744173],
              [-35.120368116067006, -8.797556853649594],
              [-35.120001896248304, -8.797106722158281],
              [-35.119315254951566, -8.796559312998706],
              [-35.118815552051103, -8.796218843702023],
              [-35.117926720131898, -8.795852624227775],
              [-35.116767058166062, -8.795606568762237],
              [-35.115649354001533, -8.795224143190381],
              [-35.114691859660105, -8.794794989052019],
              [-35.113780162978038, -8.794357267007559],
              [-35.112544226238029, -8.79337879507875],
              [-35.111743125232849, -8.792353592823099],
              [-35.111216717679241, -8.791850026528918],
              [-35.110598713988381, -8.791157680341417],
              [-35.109801453572395, -8.790565450368627],
              [-35.109412370030839, -8.789998018595869],
              [-35.109339843844452, -8.789700461402822],
              [-35.10935511778748, -8.788497888974693],
              [-35.109263567969101, -8.787946660359603],
              [-35.109282660126304, -8.78760330553443],
              [-35.109393278731922, -8.787095965819686],
              [-35.109435234858751, -8.786589583039188],
              [-35.109431392206226, -8.786228135307761],
              [-35.109316979258416, -8.786021195508857],
              [-35.109248317106328, -8.785651156775012],
              [-35.10920254361308, -8.785470899868196],
              [-35.109015626439877, -8.785010312600559],
              [-35.108946987287602, -8.784640228867101],
              [-35.108943169554998, -8.784369399566181],
              [-35.108966033508736, -8.78422446149718],
              [-35.109122426463927, -8.784060431664495],
              [-35.109439053415642, -8.783652256506095],
              [-35.109664130031611, -8.783280309040165],
              [-35.110003621857302, -8.782817790243302],
              [-35.110182924638288, -8.782653737430676],
              [-35.11043086627928, -8.782055804405328],
              [-35.110564371686316, -8.781576102537047],
              [-35.110743651730161, -8.781113559600811],
              [-35.11099163919534, -8.780714930322651],
              [-35.111460841353583, -8.779890009916874],
              [-35.11177746757626, -8.779173798694902],
              [-35.112021614532033, -8.778250623710914],
              [-35.112017772942927, -8.777816720435302],
              [-35.112105502642521, -8.777399929131867],
              [-35.112124572141767, -8.776667512752901],
              [-35.112147459150577, -8.776459641079677],
              [-35.112212326126233, -8.775926528487696],
              [-35.112254282332216, -8.77532952627743],
              [-35.112246669057669, -8.774570404043358],
              [-35.112242804032959, -8.773494630803409],
              [-35.112212326178536, -8.772455134312375],
              [-35.112204712126768, -8.77144234267351],
              [-35.112136050188617, -8.770828181475279],
              [-35.112063546985475, -8.770548737169182],
              [-35.111762217982303, -8.769745725956147],
              [-35.111506614957342, -8.768571769115374],
              [-35.111319652060935, -8.767795486833975],
              [-35.111132782028925, -8.767172734587385],
              [-35.110625396916355, -8.766000662516456],
              [-35.10987013990102, -8.764586377753568],
              [-35.109088107058156, -8.763207319746325],
              [-35.108676135648565, -8.762685684668867],
              [-35.107737710509802, -8.761858855053413],
              [-35.107325716203341, -8.761219897713991],
              [-35.106867970006988, -8.760851768631582],
              [-35.105704490554331, -8.760053553641233],
              [-35.105292495883717, -8.759829430693022],
              [-35.104449462511568, -8.759581465520403],
              [-35.103610245968156, -8.759495644331295],
              [-35.103605030006712, -8.759496925318748],
              [-35.102748119337107, -8.759708288245061],
              [-35.102744160368431, -8.759706868250566],
              [-35.101859287376975, -8.759387866488552],
              [-35.101081118774609, -8.758840457256015],
              [-35.100169399408699, -8.758041288491338],
              [-35.099551419747165, -8.757312690537923],
              [-35.09911271974741, -8.756555430920644],
              [-35.098765592154166, -8.755680940120497],
              [-35.098670247703026, -8.754741560947261],
              [-35.098441327807592, -8.754326702358417],
              [-35.098025561546308, -8.753668652137451],
              [-35.09768220662302, -8.753137494181136],
              [-35.097338919675849, -8.752632973057336],
              [-35.096766714824696, -8.751723186284709],
              [-35.09635090146746, -8.751174799374166],
              [-35.095416341338336, -8.750303150047658],
              [-35.095050097406741, -8.749979863764047],
              [-35.094455028468573, -8.749367611100181],
              [-35.093768363114158, -8.748884068547143],
              [-35.09321904507366, -8.747991440687452],
              [-35.092829961711026, -8.747217045222889],
              [-35.092482833919753, -8.746567610008556],
              [-35.09227300627483, -8.745700733281611],
              [-35.092383623734293, -8.745357400537994],
              [-35.092540063273823, -8.744524819912906],
              [-35.092921510158959, -8.743627442890668],
              [-35.093230500903154, -8.742378118020543],
              [-35.093295344012098, -8.741690521403903],
              [-35.093291525681494, -8.740959036003989],
              [-35.093196158071258, -8.740199914698779],
              [-35.093100814183657, -8.739757419401005],
              [-35.093096995529045, -8.739395972672435],
              [-35.093116110032227, -8.7386549643526],
              [-35.093337300189852, -8.737695561582344],
              [-35.093451760668295, -8.737296908190485],
              [-35.093829411541648, -8.73644528187755],
              [-35.094233746315481, -8.735475400332042],
              [-35.094596147043532, -8.734922311126963],
              [-35.095114964662343, -8.734277578634114],
              [-35.095652804357506, -8.733380178748071],
              [-35.095988500415423, -8.732682104432428],
              [-35.096122029104833, -8.731876252620207],
              [-35.096251739241069, -8.730592585812238],
              [-35.096179235274377, -8.73004135821917],
              [-35.096060979781441, -8.729346148492818],
              [-35.095828313513593, -8.728244647225067],
              [-35.095660465013644, -8.726844634892698],
              [-35.09551929964806, -8.726202789810943],
              [-35.095240856467022, -8.72539982562482],
              [-35.094805975213909, -8.724896258418759],
              [-35.094554215632499, -8.724319280833274],
              [-35.094455029665596, -8.722855424963177],
              [-35.094016328662995, -8.722098212350991],
              [-35.093966735781478, -8.721249403652036],
              [-35.093894255719057, -8.720806908375231],
              [-35.093825616398448, -8.720626650450797],
              [-35.093573832730286, -8.720148881241155],
              [-35.092841394180859, -8.719139907960733],
              [-35.092192912513376, -8.717534864506199],
              [-35.092051771091121, -8.716956931023221],
              [-35.091960222110309, -8.71658689327416],
              [-35.091567318853798, -8.715720947387284],
              [-35.090903588780698, -8.714712929161303],
              [-35.090285585179203, -8.713911850671945],
              [-35.089942275166834, -8.713479833095922],
              [-35.089392956767561, -8.712994379678573],
              [-35.088115018102876, -8.712286783031033],
              [-35.087409330922711, -8.71176701169502],
              [-35.086539567013475, -8.711220580385348],
              [-35.086016955166627, -8.711015549225404],
              [-35.08510523435622, -8.710721786288342],
              [-35.084674194994307, -8.710308837517053],
              [-35.084670377425823, -8.709847249420976],
              [-35.084735219326937, -8.709395208323999],
              [-35.085185351670454, -8.708515945250458],
              [-35.085543911686187, -8.707664319923847],
              [-35.085948293133193, -8.707065386045226],
              [-35.085917791287059, -8.706007796680215],
              [-35.085665984728948, -8.705403135271562],
              [-35.085391361232965, -8.704926321037986],
              [-35.085021345762385, -8.704105194890973],
              [-35.084861111224427, -8.703825751512973],
              [-35.08357173971644, -8.701889807595796],
              [-35.083561262793204, -8.701893161565632],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '170',
        NOME_UC1: 'PARQUE NACIONAL DO CATIMBAU',
        ID_WCMC2: '351760',
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'federal',
        ANO_CRIA6: '2002',
        GID7: '258',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº S/N de 13/12/2002',
        DT_ULTIM10: '27/09/2007',
        CODIGO_U11: '0000.00.0170',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-37.396584960296032, -8.431243720915061],
              [-37.396582865311721, -8.431243464914761],
              [-37.395890682482772, -8.431158831822087],
              [-37.395356613962342, -8.430541783117354],
              [-37.395127741257511, -8.429854209111587],
              [-37.394978961898673, -8.429239092734264],
              [-37.39482638796963, -8.428170981117914],
              [-37.394830183414641, -8.427637867374532],
              [-37.394834024140131, -8.426788127563377],
              [-37.394837843650024, -8.426181602268102],
              [-37.394917937805538, -8.425341432465785],
              [-37.395074330198838, -8.424727224351683],
              [-37.395074330407738, -8.424491668789177],
              [-37.395154470217683, -8.424040605612406],
              [-37.395154470698863, -8.423497945924211],
              [-37.39515826520789, -8.422892352623455],
              [-37.394929392374792, -8.4223487857397],
              [-37.39439150653773, -8.422119889663907],
              [-37.393624770185127, -8.422117049007307],
              [-37.393624118189777, -8.422117235005615],
              [-37.392854193660526, -8.422340170968251],
              [-37.39231633048604, -8.422491813570009],
              [-37.391698327830625, -8.422723573612306],
              [-37.391696859841808, -8.422723084613995],
              [-37.391007890124428, -8.422486109454809],
              [-37.390470003487103, -8.422032158752739],
              [-37.389935958965978, -8.421415133049575],
              [-37.389478190019332, -8.420644578350586],
              [-37.389172995812409, -8.420028460843161],
              [-37.388944123034996, -8.419421936344339],
              [-37.388715273201598, -8.418878346461465],
              [-37.388486376288263, -8.418425374025409],
              [-37.388181182017838, -8.417880782081735],
              [-37.387723435926254, -8.417273325387837],
              [-37.387185550160304, -8.41696431280182],
              [-37.386495113455041, -8.41672593965064],
              [-37.386033502855618, -8.416723984257168],
              [-37.386032547862442, -8.416724194255043],
              [-37.385343041805939, -8.416874695730707],
              [-37.384496188836152, -8.417106409572435],
              [-37.38449365085485, -8.417106409570206],
              [-37.383725611512531, -8.417103568913005],
              [-37.3830351747349, -8.41694717626144],
              [-37.382653704954372, -8.416483656756311],
              [-37.382653704562443, -8.415797014948364],
              [-37.382890214500826, -8.415029323842957],
              [-37.3830466300289, -8.414261586667587],
              [-37.382970332127705, -8.413655085303551],
              [-37.382356169924364, -8.413345140656842],
              [-37.382353817941066, -8.413345816650606],
              [-37.38158938737287, -8.413568262621459],
              [-37.381124004665431, -8.413719906287023],
              [-37.380509843053495, -8.413871571821888],
              [-37.379819359940278, -8.414094717853331],
              [-37.379201379284886, -8.414327384890193],
              [-37.378739815549416, -8.414479027559139],
              [-37.377892962588604, -8.414701265458747],
              [-37.377355052343653, -8.414933933565894],
              [-37.376813395198056, -8.415085599164344],
              [-37.376199233523124, -8.415308745262697],
              [-37.375581276803551, -8.415613915856794],
              [-37.37496709212796, -8.415838038949081],
              [-37.374349089473853, -8.416069774991556],
              [-37.373811249236155, -8.41629389915083],
              [-37.373349661501074, -8.416445564819504],
              [-37.372811775399803, -8.416516089916634],
              [-37.372810052412461, -8.416516089915119],
              [-37.372273936363143, -8.416513249461806],
              [-37.371659727024124, -8.416357764872005],
              [-37.371658121035388, -8.416358393866757],
              [-37.370503908132513, -8.41681364707352],
              [-37.369809629839438, -8.417272370662811],
              [-37.368806360687763, -8.417882691055132],
              [-37.36816928799032, -8.418322345811061],
              [-37.368167729002707, -8.418321321815917],
              [-37.367349140497652, -8.417794960311401],
              [-37.367116449484179, -8.417486947988024],
              [-37.366891372614759, -8.41695285705195],
              [-37.366971489769412, -8.416111732258797],
              [-37.367284297544622, -8.414892931976437],
              [-37.366902827508042, -8.413589264603175],
              [-37.36621236694009, -8.413197316391006],
              [-37.365216712020917, -8.412352326678082],
              [-37.36499168162338, -8.411284237004045],
              [-37.364919178037397, -8.410289560015665],
              [-37.364770398149709, -8.409140351904382],
              [-37.3642363769643, -8.40814376652326],
              [-37.363473413602442, -8.406991717891277],
              [-37.363091943092513, -8.406222095258006],
              [-37.362401482924085, -8.405378129808158],
              [-37.361478307798585, -8.405293216517599],
              [-37.361477632803172, -8.405293681514195],
              [-37.361249435349436, -8.405445813384789],
              [-37.360478857955869, -8.405524021231596],
              [-37.360478577958034, -8.405523928231874],
              [-37.359712122878072, -8.405213122458722],
              [-37.359021661237627, -8.404903200746876],
              [-37.357640741749599, -8.404517888890522],
              [-37.356103427422916, -8.404122121961107],
              [-37.355336645209213, -8.40396573024462],
              [-37.352799881968458, -8.403882772528545],
              [-37.350953601783658, -8.403639580396637],
              [-37.350186819698386, -8.4033382035663],
              [-37.349267463953701, -8.402791749099787],
              [-37.34826801371986, -8.402334912015723],
              [-37.347886565666307, -8.402180451625394],
              [-37.347884470681144, -8.40218117361918],
              [-37.347039712644516, -8.402475146082786],
              [-37.345574833627261, -8.403390638205233],
              [-37.345571783649682, -8.403390638202559],
              [-37.345422282751059, -8.403390638071588],
              [-37.34542074676218, -8.40339084706897],
              [-37.343728554035877, -8.403609056253046],
              [-37.342729103131134, -8.403912295524362],
              [-37.341573237379613, -8.404214649664052],
              [-37.34026479688805, -8.404363405608715],
              [-37.340263934894473, -8.404363288608662],
              [-37.337960725071753, -8.404127850029756],
              [-37.336961274711051, -8.403815950060379],
              [-37.335809249411398, -8.403575644519961],
              [-37.335118766629421, -8.403427843818454],
              [-37.334043040902579, -8.403034918277765],
              [-37.332662120282208, -8.402803204484592],
              [-37.329431125101515, -8.402789816736895],
              [-37.327817465998791, -8.402782226370203],
              [-37.326978203363453, -8.402578126882917],
              [-37.325513393329381, -8.40238357378953],
              [-37.324361345950287, -8.40223479369028],
              [-37.320596236190191, -8.401675953810269],
              [-37.31883002648437, -8.401361212188331],
              [-37.317754277751646, -8.400976901596291],
              [-37.31660227665342, -8.40051054143929],
              [-37.315297631544766, -8.400197732210044],
              [-37.313764113187901, -8.399811489229585],
              [-37.31376083021194, -8.399811722225264],
              [-37.313760668213142, -8.399811722225124],
              [-37.31268838804899, -8.399887764821829],
              [-37.312684918074417, -8.399887973817517],
              [-37.311379970633631, -8.399954564268377],
              [-37.311379830634671, -8.39995456426826],
              [-37.310071506010956, -8.400256871274827],
              [-37.308759271946535, -8.401091338023866],
              [-37.308758689952292, -8.401089685033492],
              [-37.308305320443047, -8.39978767059846],
              [-37.307462262474232, -8.398861631523634],
              [-37.306470426200136, -8.397257542465622],
              [-37.306088956085105, -8.397174584639277],
              [-37.305318378039352, -8.396863685866629],
              [-37.305315421060747, -8.396864081861585],
              [-37.30424265283736, -8.397012465015855],
              [-37.304242000842336, -8.397012279016399],
              [-37.303014330167542, -8.396699656854606],
              [-37.301785982697368, -8.396160816075984],
              [-37.300866650020041, -8.395541881057616],
              [-37.300023591500441, -8.395240528163592],
              [-37.299176716882002, -8.395083180385575],
              [-37.299174014901581, -8.395083530381065],
              [-37.297486829146926, -8.395301552572285],
              [-37.29748492016094, -8.395301552570613],
              [-37.296330962672052, -8.395296803590735],
              [-37.295102616067169, -8.394911513873613],
              [-37.294106984024275, -8.394211484285345],
              [-37.294103980045911, -8.394212066279199],
              [-37.293965865041052, -8.39423821399847],
              [-37.293965749042265, -8.394237771001103],
              [-37.293424160678931, -8.392372817936371],
              [-37.293042714161707, -8.391611810258562],
              [-37.29181436789986, -8.390837413922783],
              [-37.290666161838836, -8.390299551210184],
              [-37.289899401701888, -8.390061108999101],
              [-37.289052526219429, -8.389750233161264],
              [-37.288133216136671, -8.389591908326878],
              [-37.287053626100445, -8.389587158412814],
              [-37.285672704350795, -8.389508949685059],
              [-37.284677073372002, -8.388736463542124],
              [-37.283601347777235, -8.388198577893935],
              [-37.28268196783069, -8.38788669999941],
              [-37.281297252316953, -8.387572983709925],
              [-37.281294202339147, -8.3875732857054],
              [-37.280530515901582, -8.387651167561941],
              [-37.279607341512921, -8.387873405395768],
              [-37.278527797872655, -8.388555251280454],
              [-37.27790979381821, -8.389249530492011],
              [-37.277749583060405, -8.390315755827354],
              [-37.277593190402101, -8.391236999053294],
              [-37.27743677414972, -8.391697631097932],
              [-37.276895093540702, -8.392382387434502],
              [-37.276433505675598, -8.392687536163905],
              [-37.275277640067948, -8.392836315243633],
              [-37.275277640068154, -8.392836082245079],
              [-37.275281481174936, -8.392682763186672],
              [-37.274205731789081, -8.391909344979792],
              [-37.273210100469711, -8.391525010462045],
              [-37.27320802848427, -8.391525802455432],
              [-37.272054257599322, -8.39197227871526],
              [-37.27120738123471, -8.39266464773829],
              [-37.26912456752769, -8.393875811506586],
              [-37.268739279035529, -8.394254441852768],
              [-37.268045024616939, -8.39486573950518],
              [-37.26711803077955, -8.395630590014424],
              [-37.26573329152739, -8.396157974576893],
              [-37.265271727734039, -8.396382074802087],
              [-37.265115311753107, -8.396534694731361],
              [-37.26365050188511, -8.397296657788099],
              [-37.262956222538385, -8.397826907936238],
              [-37.261956773172734, -8.398663259944295],
              [-37.261182353481068, -8.399121028465892],
              [-37.260491916504677, -8.399199236383755],
              [-37.26049131150922, -8.399199120383916],
              [-37.259801433735404, -8.399042843737384],
              [-37.259725158704825, -8.398581256495724],
              [-37.259420009227966, -8.398272267120131],
              [-37.259042334225619, -8.396897074206771],
              [-37.25843196781414, -8.395663954220952],
              [-37.257669027248966, -8.394747508163825],
              [-37.257058684701036, -8.393668872233077],
              [-37.256677214121261, -8.392980321114646],
              [-37.255914272961576, -8.391603217878171],
              [-37.255300111171117, -8.390831686064924],
              [-37.255296666196202, -8.390832059059669],
              [-37.253301234723949, -8.391049126988596],
              [-37.25330041973006, -8.391049033988471],
              [-37.25207288792501, -8.390889870891167],
              [-37.251306128918607, -8.390506491568846],
              [-37.249925208445248, -8.390120247728028],
              [-37.249230929569308, -8.390117384139492],
              [-37.249230207574456, -8.390117594137632],
              [-37.248540469466242, -8.390340576170303],
              [-37.247613499631967, -8.391104447684404],
              [-37.246457679422228, -8.391940799554796],
              [-37.245606962160714, -8.392551143075277],
              [-37.244760109806101, -8.393234945149354],
              [-37.242292007208839, -8.395285347440625],
              [-37.241365037103641, -8.396357278068244],
              [-37.240281651288562, -8.397274702505143],
              [-37.239129603594222, -8.397495007150063],
              [-37.238206452273417, -8.397644740426943],
              [-37.236665320379558, -8.397945162241468],
              [-37.236663248394883, -8.39794506924024],
              [-37.234899110489437, -8.397856453241875],
              [-37.234898599492901, -8.397856802239298],
              [-37.234357429145959, -8.398242697403816],
              [-37.234048439893215, -8.398847336431835],
              [-37.233811930628413, -8.399993656206549],
              [-37.233422823417179, -8.401221955346188],
              [-37.232572151621646, -8.402438847152421],
              [-37.232034288321479, -8.402744041813829],
              [-37.231416331677067, -8.402975801854838],
              [-37.229493683672736, -8.403193219843482],
              [-37.228570531352851, -8.403342953119946],
              [-37.227338413179432, -8.40364432919794],
              [-37.225030523613711, -8.404321426035212],
              [-37.223489367451513, -8.404928927968603],
              [-37.223485549809716, -8.40568900531127],
              [-37.223485550403694, -8.406149590491065],
              [-37.223634328897958, -8.406612156788903],
              [-37.223939499967337, -8.407381779343435],
              [-37.223859405546797, -8.408529054249005],
              [-37.223313882697205, -8.409521821693728],
              [-37.223081192474929, -8.410587047968459],
              [-37.223691558361431, -8.411277531274887],
              [-37.223764038148502, -8.412046175632502],
              [-37.222756974965051, -8.413877228541676],
              [-37.22259674099967, -8.415178055437909],
              [-37.221997829344893, -8.416395924458326],
              [-37.221433237764195, -8.417242753780114],
              [-37.220357512100584, -8.417925624658331],
              [-37.220124820483754, -8.41830420713706],
              [-37.220044703532778, -8.418918392307134],
              [-37.220040907953717, -8.419605989094617],
              [-37.220571134417625, -8.421443724309329],
              [-37.220719913568267, -8.422294395232532],
              [-37.220944990964455, -8.423361576897378],
              [-37.220941148841938, -8.424664265920473],
              [-37.220933535947616, -8.425740038329343],
              [-37.221307392158067, -8.428037428595806],
              [-37.221612563432885, -8.428572427588909],
              [-37.222074104552647, -8.42910842771299],
              [-37.222531873492919, -8.429878981398423],
              [-37.222756974815546, -8.431028165562951],
              [-37.222829431374187, -8.432053367352555],
              [-37.223512255143731, -8.43339999171061],
              [-37.223817403282602, -8.434088542764769],
              [-37.225194528481396, -8.435939595645948],
              [-37.225881193739475, -8.436701581585908],
              [-37.226258846022255, -8.437752511487043],
              [-37.226716614738116, -8.438775849627483],
              [-37.226636497248826, -8.439996536088763],
              [-37.226636498177065, -8.440077584592855],
              [-37.226560199676015, -8.440150041082639],
              [-37.226399987906824, -8.441224859366178],
              [-37.226705136109267, -8.441840906865075],
              [-37.228086056711909, -8.44207362165359],
              [-37.228238677312746, -8.442381680902958],
              [-37.22823101710496, -8.442680147070297],
              [-37.227899140515369, -8.442724035510476],
              [-37.227635948327652, -8.442870905380875],
              [-37.227658812017872, -8.443030184426524],
              [-37.227609242222407, -8.443212328268705],
              [-37.227380369842223, -8.443290536589346],
              [-37.227378808854226, -8.443290023591086],
              [-37.226983625882553, -8.443163690016975],
              [-37.226491536657008, -8.443002524570918],
              [-37.226490233665778, -8.443003502563792],
              [-37.226251209270202, -8.443183714251466],
              [-37.226155841702138, -8.443490771289255],
              [-37.22615584158131, -8.443627186454679],
              [-37.226312234320659, -8.443747350856857],
              [-37.226144408980915, -8.446657924903962],
              [-37.225907875846914, -8.447650739622885],
              [-37.225289919392708, -8.448796081073777],
              [-37.224900812584977, -8.44956286304147],
              [-37.224359130639527, -8.450627134055278],
              [-37.224122574841033, -8.451241319090496],
              [-37.222962936250148, -8.452538328137717],
              [-37.222501349459158, -8.452761449367244],
              [-37.22188339340461, -8.453455751577152],
              [-37.221650678105824, -8.454602072360771],
              [-37.221410349597463, -8.456047859306073],
              [-37.221253956870385, -8.457041582090445],
              [-37.221238683991231, -8.460413766451836],
              [-37.22092207976484, -8.462175202400525],
              [-37.219995109663152, -8.463238565081596],
              [-37.219992060686074, -8.463238099081748],
              [-37.218919383744748, -8.463079309109048],
              [-37.217767336317124, -8.463002055567927],
              [-37.217610920544502, -8.462920005932121],
              [-37.217608825559083, -8.462921052923891],
              [-37.217305725661866, -8.463072602730309],
              [-37.217149356471566, -8.463460756218737],
              [-37.216916618917836, -8.463766882141655],
              [-37.216531375562624, -8.46399098143209],
              [-37.216528093586831, -8.4639909814292],
              [-37.216222362844469, -8.463990027166048],
              [-37.216222013846831, -8.463990260164303],
              [-37.215993466398629, -8.464142648031173],
              [-37.215837098216802, -8.464521230578168],
              [-37.215756981262963, -8.465135415751279],
              [-37.215600541217086, -8.465361448231233],
              [-37.21506270257175, -8.466054772517563],
              [-37.214673617562369, -8.467047540103465],
              [-37.214513406798176, -8.468113767441642],
              [-37.214429471398809, -8.469261018351528],
              [-37.21419678058821, -8.470981453625413],
              [-37.214193171615243, -8.470981034624796],
              [-37.187276489107205, -8.467878165913362],
              [-37.187276279108197, -8.467878793909341],
              [-37.183549493142579, -8.479687562382937],
              [-37.184472668055101, -8.479990802341169],
              [-37.185315701479837, -8.480383727680286],
              [-37.186235058212901, -8.480920636205992],
              [-37.187234509352834, -8.481459499790491],
              [-37.188459013484859, -8.482387425193757],
              [-37.190988164165638, -8.484233727130498],
              [-37.191754921888382, -8.484925142577824],
              [-37.192636117952695, -8.485406753409283],
              [-37.193749981327805, -8.485855931644425],
              [-37.195130925650133, -8.486395726561039],
              [-37.195973960284086, -8.486554029336375],
              [-37.196820835753989, -8.486864928182023],
              [-37.1985859977123, -8.486873519686268],
              [-37.198587045704571, -8.486873519687196],
              [-37.199582793420561, -8.486796499036322],
              [-37.199586495393476, -8.486796242041153],
              [-37.200814935382148, -8.48672969953148],
              [-37.200818614355136, -8.486729489536014],
              [-37.202046960079251, -8.486961227202443],
              [-37.203046410486735, -8.487200623620193],
              [-37.203660572748369, -8.487429496762536],
              [-37.206037125305997, -8.48843562970745],
              [-37.206807702476567, -8.488592999425176],
              [-37.206727584794749, -8.488900080477052],
              [-37.205953235694331, -8.489818459179054],
              [-37.205258934283904, -8.490421188881514],
              [-37.204412080194345, -8.490805499784607],
              [-37.203797919457244, -8.491110671376889],
              [-37.202485660266611, -8.492098712178148],
              [-37.201325999747233, -8.493313671726652],
              [-37.200475304282897, -8.49415097785667],
              [-37.197621914592332, -8.496127038256599],
              [-37.196542346884577, -8.496889955639114],
              [-37.195695493730227, -8.497347725092681],
              [-37.194844822468184, -8.497958090609904],
              [-37.193994150867212, -8.498948949800813],
              [-37.192903128776841, -8.500236388966245],
              [-37.19247588370164, -8.500494855008677],
              [-37.193955990524337, -8.522062223493281],
              [-37.193898760539625, -8.522518105656857],
              [-37.19397124111542, -8.523512783642952],
              [-37.194120019676554, -8.523893276449723],
              [-37.19426882155043, -8.525042459559618],
              [-37.193803393238326, -8.525880768025008],
              [-37.192571227728529, -8.526562660766952],
              [-37.192568619748158, -8.526562264767042],
              [-37.191495501815638, -8.52640335678735],
              [-37.191493336831229, -8.526403799782754],
              [-37.191110236589395, -8.526483450956629],
              [-37.190648625798296, -8.526707574178246],
              [-37.19026338337067, -8.527012768972074],
              [-37.189721679824082, -8.527624997751143],
              [-37.189714065172581, -8.528416553907723],
              [-37.189172360967497, -8.529765088187837],
              [-37.18893585082558, -8.530758786906583],
              [-37.189008331327962, -8.531834536397964],
              [-37.188085179607917, -8.532436310902883],
              [-37.187543498263281, -8.532822531062942],
              [-37.18646393062695, -8.533504422939572],
              [-37.185536961000132, -8.534033717883787],
              [-37.184613832612101, -8.534264546655022],
              [-37.183919531063786, -8.535020781418815],
              [-37.183377826242399, -8.535940114321381],
              [-37.183133702651361, -8.538615203760562],
              [-37.18304976717085, -8.539844457175949],
              [-37.182736982454898, -8.54099077789504],
              [-37.182195301429623, -8.54213709841118],
              [-37.182805621238906, -8.542899107297536],
              [-37.183877550628218, -8.543672502524128],
              [-37.184564216460039, -8.544896075659043],
              [-37.185250858420865, -8.545975689673741],
              [-37.186242671648941, -8.547589254698176],
              [-37.186776738810039, -8.548586796079407],
              [-37.187921150431677, -8.550734498978265],
              [-37.188149999537856, -8.550961463795318],
              [-37.188226297835747, -8.551115969919445],
              [-37.189504238353265, -8.552279448949072],
              [-37.189244841828653, -8.556115107294433],
              [-37.185636147030785, -8.558849312389265],
              [-37.184918981717395, -8.566212638788896],
              [-37.182920079444401, -8.568482392151376],
              [-37.183747863934215, -8.571147934613773],
              [-37.184121697172827, -8.573372844363332],
              [-37.184335320263202, -8.574853905511903],
              [-37.184957118135642, -8.57544806643863],
              [-37.185647578349936, -8.576210029402338],
              [-37.186414338129225, -8.576828963307301],
              [-37.187104774685032, -8.577211411587959],
              [-37.189244840939637, -8.579364796351069],
              [-37.18978270447662, -8.579909341506804],
              [-37.190999618277736, -8.582365033603873],
              [-37.191461159525048, -8.583857549906705],
              [-37.191751080418832, -8.586039548849289],
              [-37.191968544328468, -8.588797595212913],
              [-37.192269896789711, -8.590254837589727],
              [-37.192346196583323, -8.590968209304966],
              [-37.19349437922483, -8.59194188438877],
              [-37.194337459789423, -8.592172666733271],
              [-37.195489484063593, -8.592412994295426],
              [-37.196161690895664, -8.592638003522733],
              [-37.196164693872603, -8.592639004519297],
              [-37.203992448497864, -8.578827888773892],
              [-37.2047782770642, -8.575082778328486],
              [-37.204812573278303, -8.567890163256108],
              [-37.20556410416404, -8.565179823468231],
              [-37.205773909349851, -8.564361562649523],
              [-37.20770412352595, -8.562769860083074],
              [-37.208554818996191, -8.561932528951116],
              [-37.209329215240572, -8.56086059918322],
              [-37.20995101367766, -8.559715256727674],
              [-37.210263821600087, -8.558342904383023],
              [-37.210572090011532, -8.557576913333069],
              [-37.210572788007909, -8.557575214344007],
              [-37.211648537580814, -8.558114055011695],
              [-37.214414196531138, -8.558814038199392],
              [-37.21633298059286, -8.559672322667907],
              [-37.218320449920881, -8.561896300863221],
              [-37.219541158977449, -8.564044935837309],
              [-37.220380350990084, -8.566038108421489],
              [-37.221215772169046, -8.567876821945642],
              [-37.22144087340007, -8.569108031633604],
              [-37.221589629545328, -8.569949157633921],
              [-37.221814683646876, -8.571324373443666],
              [-37.222039784951171, -8.572473534632925],
              [-37.2221122655126, -8.573477734570808],
              [-37.222875182298324, -8.575235375527729],
              [-37.222791246884611, -8.576383605447955],
              [-37.222787428156586, -8.577223776319068],
              [-37.222322045422423, -8.578523670974192],
              [-37.222043579278008, -8.579856884592843],
              [-37.22177272761661, -8.580591210871722],
              [-37.215314460912204, -8.586575549604268],
              [-37.215311480934602, -8.58657513060416],
              [-37.212186423413506, -8.586128281540944],
              [-37.212186423412831, -8.586129025536442],
              [-37.212049099390789, -8.597261491509661],
              [-37.210107383098084, -8.603502316713628],
              [-37.214944212969449, -8.609492126516527],
              [-37.214944422967655, -8.609492383515159],
              [-37.221993986720406, -8.608597800277952],
              [-37.232633175344482, -8.601760950476153],
              [-37.242349189899095, -8.595913935808454],
              [-37.245225467145701, -8.58873466114591],
              [-37.244733379324053, -8.587019930160668],
              [-37.247354077397489, -8.578790681676621],
              [-37.248780773493451, -8.575875288727138],
              [-37.252891800120061, -8.57258038048615],
              [-37.2528930341118, -8.572579403493195],
              [-37.259900618316507, -8.574892089642464],
              [-37.27995477030035, -8.573841300942808],
              [-37.279958286274571, -8.573841114947053],
              [-37.30224372823578, -8.586771058073371],
              [-37.313272862115767, -8.586534618376932],
              [-37.313275819094017, -8.58653454838],
              [-37.328581825111684, -8.582249386162657],
              [-37.328584224094598, -8.58224871216887],
              [-37.329514965841348, -8.582682638359138],
              [-37.330358023937421, -8.583445602467744],
              [-37.331120964615572, -8.584218066447098],
              [-37.332890969283177, -8.584532807114183],
              [-37.333578658211209, -8.584535624712219],
              [-37.333581429190751, -8.584535624714695],
              [-37.333812513559145, -8.584456089405652],
              [-37.333814143547606, -8.584455554410393],
              [-37.33457864377035, -8.584612412139339],
              [-37.334580879753446, -8.584612878138516],
              [-37.335351456478762, -8.584163676562591],
              [-37.336277565860236, -8.583933126794626],
              [-37.336278426854079, -8.583932917796664],
              [-37.337201601045628, -8.583936735599275],
              [-37.338197233490376, -8.584176133032624],
              [-37.33919414599945, -8.584334015963385],
              [-37.339196683980376, -8.584334411963265],
              [-37.339971033760087, -8.583794638941882],
              [-37.340583797444502, -8.583572006845333],
              [-37.340585241434361, -8.583571470849927],
              [-37.341584690924549, -8.583729796780245],
              [-37.342851571633069, -8.583678457226242],
              [-37.342854970608222, -8.583678271230395],
              [-37.343583569323194, -8.583583881456819],
              [-37.344743230391728, -8.582901989645093],
              [-37.345746498891231, -8.581912060568438],
              [-37.347054939936591, -8.581149144382861],
              [-37.347981886378719, -8.580845858058089],
              [-37.348903151723867, -8.580696473791475],
              [-37.348905084709884, -8.58069614779517],
              [-37.349980832704254, -8.580781947235204],
              [-37.351209971641396, -8.580786743305506],
              [-37.351212951619416, -8.580786743308172],
              [-37.352521391665583, -8.580023825122234],
              [-37.353063073087789, -8.579565101398838],
              [-37.353833650683754, -8.579260860939929],
              [-37.354989493577506, -8.578805024748215],
              [-37.35560367917968, -8.578662905162551],
              [-37.356606738134857, -8.578278687398241],
              [-37.3566069471334, -8.578278594399013],
              [-37.358449990610374, -8.578213866441365],
              [-37.358453250586514, -8.578213727445121],
              [-37.358685942082786, -8.577980058075434],
              [-37.359300126963376, -8.577529925364416],
              [-37.359765509160795, -8.576835671006089],
              [-37.36045978852345, -8.576305443854093],
              [-37.361463033543359, -8.575848583531842],
              [-37.362382855827377, -8.575780177770747],
              [-37.362386184803007, -8.575779967775009],
              [-37.363080463896502, -8.575548184806493],
              [-37.363694648776395, -8.575099008089495],
              [-37.364255398777225, -8.574952160484619],
              [-37.365159505393621, -8.574644102167932],
              [-37.365701186817304, -8.57418444644979],
              [-37.366624337631784, -8.57350160043109],
              [-37.368089171111023, -8.57320022457494],
              [-37.369085291841323, -8.57312322693417],
              [-37.369088620817067, -8.5731229699387],
              [-37.369935497639517, -8.573054332113601],
              [-37.370477178266583, -8.572369599765088],
              [-37.371095158331521, -8.571684843484901],
              [-37.371865479207678, -8.571073754892499],
              [-37.371865735206001, -8.571073544894016],
              [-37.372015958099233, -8.571073545028302],
              [-37.372018310081934, -8.571073545030403],
              [-37.372400595336337, -8.570995011850068],
              [-37.372403575314962, -8.570994405856462],
              [-37.373017761094715, -8.571764983715962],
              [-37.373322931706134, -8.571920422042879],
              [-37.374169784463803, -8.571924216776868],
              [-37.374859220243636, -8.572080422442731],
              [-37.3748602682357, -8.572080655442232],
              [-37.376016111920293, -8.571859373822223],
              [-37.376862963956128, -8.571555179430501],
              [-37.378018829925701, -8.571018224731439],
              [-37.378251522414644, -8.570793170309004],
              [-37.380171213469808, -8.570575101352201],
              [-37.380174123448654, -8.570574774356841],
              [-37.380403019696239, -8.570648233114445],
              [-37.381325053696933, -8.570877781542048],
              [-37.38132617168845, -8.5708780605413],
              [-37.382325621526761, -8.570655846787037],
              [-37.383172474350594, -8.570587184962013],
              [-37.384402240425985, -8.570438731964853],
              [-37.384404616408752, -8.570438429968823],
              [-37.386094526815512, -8.570599571499322],
              [-37.38686510379349, -8.570982950855727],
              [-37.387631722801629, -8.571365329214791],
              [-37.38763186380055, -8.571365352214761],
              [-37.388478739624645, -8.571296737389531],
              [-37.389398654918438, -8.571219763680492],
              [-37.389401890894838, -8.571219483685089],
              [-37.390168603616566, -8.571918536117806],
              [-37.39070267127223, -8.572373417828024],
              [-37.391625822330766, -8.572530788696332],
              [-37.392472676090911, -8.572533628436585],
              [-37.393624746388674, -8.572773980004925],
              [-37.39562364745489, -8.573008557366055],
              [-37.396696462550267, -8.573012376302538],
              [-37.396699396528668, -8.573012376305163],
              [-37.402833421794021, -8.569203423961589],
              [-37.40480943495065, -8.563986798461857],
              [-37.41103645376004, -8.562145012231436],
              [-37.411038827743148, -8.562144314237798],
              [-37.421491077100377, -8.568445232261526],
              [-37.430592952487586, -8.569118534311112],
              [-37.4304975617246, -8.569633509094709],
              [-37.430093227154771, -8.570238148056697],
              [-37.429780442698245, -8.57107831866859],
              [-37.429768987429945, -8.573681881829419],
              [-37.429608776081096, -8.575372724406623],
              [-37.429681232721812, -8.576286328917467],
              [-37.430142820701384, -8.576974904144917],
              [-37.432595647968242, -8.578829822066396],
              [-37.433896475423197, -8.579901752716196],
              [-37.434354244291001, -8.580744764002429],
              [-37.434659416143873, -8.581740442224117],
              [-37.435265940386458, -8.584274318367521],
              [-37.435948810630045, -8.586185511364651],
              [-37.436177660248738, -8.586955133892747],
              [-37.436711704620045, -8.587725712688879],
              [-37.43701689875008, -8.588414263778374],
              [-37.438241427348714, -8.589946803564104],
              [-37.438855589408234, -8.590410277298837],
              [-37.439336083268678, -8.591075754686306],
              [-37.439336222267485, -8.591075963685164],
              [-37.440309758215321, -8.590950468321248],
              [-37.440312785193413, -8.590950049326507],
              [-37.440926946973953, -8.591721604189575],
              [-37.441533518122448, -8.593252259433928],
              [-37.441842507569007, -8.593560293839726],
              [-37.442453690067879, -8.593563134371044],
              [-37.442456670045949, -8.593563158373602],
              [-37.442689360537244, -8.593338056950099],
              [-37.442922028381169, -8.592723918890186],
              [-37.443086057864264, -8.591961932667013],
              [-37.444079105826695, -8.591662302378685],
              [-37.444081712808206, -8.591661534385684],
              [-37.445153643873944, -8.592814512342768],
              [-37.445687688318536, -8.59350401963316],
              [-37.446298055857305, -8.594573085686354],
              [-37.446679525630479, -8.595035605218998],
              [-37.447064814170659, -8.595724155382088],
              [-37.447369985644514, -8.596033168778888],
              [-37.447827731855355, -8.596496665374366],
              [-37.448213020534624, -8.597031664470462],
              [-37.448441915775618, -8.597113691177777],
              [-37.448689835468485, -8.597645850167812],
              [-37.44874326938772, -8.598404017610441],
              [-37.448853910936023, -8.599106840440703],
              [-37.449559621612423, -8.599249915205576],
              [-37.450177601909395, -8.599420626723822],
              [-37.451024454163964, -8.599986151049663],
              [-37.452065883960607, -8.600580265376898],
              [-37.453500192717051, -8.60133847906067],
              [-37.454629330270663, -8.601489145160278],
              [-37.45547620603574, -8.601492007903982],
              [-37.455758513829259, -8.601634104294691],
              [-37.456939478549067, -8.60228365741129],
              [-37.456941039536815, -8.602284495407606],
              [-37.457101273453709, -8.602178650194421],
              [-37.457486538660945, -8.60213192082446],
              [-37.457608611816227, -8.602072805293192],
              [-37.458054366697596, -8.601894688775502],
              [-37.458054948693508, -8.60189445677744],
              [-37.459149767622208, -8.601910685662874],
              [-37.459866933025403, -8.602262586170525],
              [-37.460626055011765, -8.602733695991983],
              [-37.461453839459963, -8.603241058655218],
              [-37.462022202788553, -8.60377987489432],
              [-37.462190073356787, -8.603996361730671],
              [-37.46224728044583, -8.604536157504414],
              [-37.46247615337991, -8.604956696156655],
              [-37.462521927575558, -8.605471672070893],
              [-37.46253338397954, -8.606035356658552],
              [-37.462602068984012, -8.606575104443083],
              [-37.462708868050974, -8.606737202554934],
              [-37.47230059623886, -8.609199040237348],
              [-37.472302832221885, -8.609199622235813],
              [-37.475876275693523, -8.606158197913569],
              [-37.475877206687386, -8.606157406919216],
              [-37.485294010009433, -8.608801458343324],
              [-37.485295662996798, -8.608801947341847],
              [-37.4936918278391, -8.604837510957422],
              [-37.511586566732298, -8.583355963419022],
              [-37.503720650524663, -8.573472054364677],
              [-37.501382282771878, -8.561252617475899],
              [-37.508946797835087, -8.556066539746176],
              [-37.507592583947854, -8.527060477766318],
              [-37.513833432743972, -8.508513393065892],
              [-37.532603405533536, -8.505448661403525],
              [-37.532605570517966, -8.505448312407573],
              [-37.541358455506753, -8.507327584779899],
              [-37.541360271493055, -8.507327980779126],
              [-37.541553894154703, -8.507234033522417],
              [-37.541554802148433, -8.507233590525939],
              [-37.543321034960712, -8.507466258685012],
              [-37.543913682621273, -8.50744916931669],
              [-37.543916080603722, -8.507449099319254],
              [-37.544629451480972, -8.507316525760141],
              [-37.545323730645151, -8.507020899174794],
              [-37.545941710654574, -8.506408624445541],
              [-37.546327022171276, -8.506021449141237],
              [-37.54724845166605, -8.505726358754856],
              [-37.547250174653911, -8.505725800759768],
              [-37.548558613693757, -8.506111112583698],
              [-37.549323930003084, -8.506186270808598],
              [-37.549325349992493, -8.506186433808915],
              [-37.550019629027609, -8.50603481334843],
              [-37.550713907330149, -8.50558563669593],
              [-37.551328069164882, -8.505199369589764],
              [-37.552331315132335, -8.504822672771926],
              [-37.553639778652688, -8.504673893841046],
              [-37.553944972547562, -8.504521296039977],
              [-37.554562953281561, -8.504216124444421],
              [-37.555257231519327, -8.503839427351346],
              [-37.555871370281558, -8.503534255752379],
              [-37.5566419478209, -8.503311110794225],
              [-37.558568391521682, -8.50236125328046],
              [-37.557378205738871, -8.497380183485948],
              [-37.557375225760595, -8.497380346482291],
              [-37.555028335911473, -8.497481324780459],
              [-37.552953136417848, -8.497191356696128],
              [-37.552377089872614, -8.496943391690468],
              [-37.550046334677972, -8.495070382999787],
              [-37.549069771479452, -8.494372262374092],
              [-37.546990776907165, -8.493087687332693],
              [-37.545495420149344, -8.491688652506776],
              [-37.544949920972634, -8.490779796546306],
              [-37.472405837656396, -8.457904688263117],
              [-37.467019502048423, -8.451425396969022],
              [-37.467018058057064, -8.451427654953987],
              [-37.466622781418188, -8.452040535871298],
              [-37.465772087001234, -8.452796794512853],
              [-37.465463095928889, -8.453183992881128],
              [-37.464147018703251, -8.45532210269365],
              [-37.463685432624857, -8.455853330049768],
              [-37.462911058501234, -8.456772662765259],
              [-37.462140482220285, -8.457837003600758],
              [-37.461984089032256, -8.45821651815073],
              [-37.46113723671639, -8.4588278386783],
              [-37.459134517245857, -8.459054803525355],
              [-37.459133795251269, -8.459054709525319],
              [-37.457982492861461, -8.458896500471367],
              [-37.456906719988552, -8.458657127978686],
              [-37.455678396504084, -8.458119264169682],
              [-37.454759087818758, -8.457500353127763],
              [-37.45115034746803, -8.456265301463493],
              [-37.449845725344787, -8.45595249221679],
              [-37.449353636961682, -8.455956310758747],
              [-37.448537284185235, -8.455712164525151],
              [-37.447076293067454, -8.455562454146696],
              [-37.446156937109365, -8.455250600234812],
              [-37.446154981123208, -8.455251206229407],
              [-37.445924222757782, -8.455322102593566],
              [-37.444234311922678, -8.455623479264338],
              [-37.444233706927406, -8.455623199265519],
              [-37.44284959566415, -8.455002635824462],
              [-37.441777642095097, -8.454392291597129],
              [-37.441775942106737, -8.454393293589499],
              [-37.440621798991266, -8.455075092415271],
              [-37.439771104842812, -8.455532883874097],
              [-37.438771654587526, -8.456216685824186],
              [-37.437924801685455, -8.456366396163808],
              [-37.437923776693147, -8.456366210164013],
              [-37.437154223494922, -8.456210003436258],
              [-37.436078497623818, -8.45597063094494],
              [-37.435235440168981, -8.455587250536857],
              [-37.434316106686722, -8.454742283874703],
              [-37.433782062713227, -8.453511096907086],
              [-37.433328112480574, -8.451908917271711],
              [-37.433099216375581, -8.451672383511392],
              [-37.433026735657911, -8.450831235574483],
              [-37.433026735205573, -8.450216142323761],
              [-37.432954255688955, -8.449148983764719],
              [-37.433343361982772, -8.447849112031696],
              [-37.433579849063328, -8.446926915861846],
              [-37.43373628865988, -8.446086721121629],
              [-37.433583691193505, -8.4456251568007],
              [-37.433354818280712, -8.445172184360136],
              [-37.432588082271565, -8.4447801900735],
              [-37.43212647187115, -8.444552248055992],
              [-37.430825645059429, -8.443859902129583],
              [-37.429517250965951, -8.443547070883042],
              [-37.429515923975138, -8.443547722877879],
              [-37.428594076352354, -8.444004862277914],
              [-37.42705294488686, -8.444911786389495],
              [-37.427049102440854, -8.445444876135985],
              [-37.427201722970629, -8.445834936892519],
              [-37.427430596012229, -8.446142996216265],
              [-37.427659469514118, -8.447057556842521],
              [-37.427270362284538, -8.448285880010912],
              [-37.425958103407915, -8.448885769196],
              [-37.425115068542574, -8.448963953975545],
              [-37.424497088891926, -8.449187122069787],
              [-37.423104737163648, -8.451406327311934],
              [-37.422563031742072, -8.451865051037347],
              [-37.421258410211436, -8.452013830978943],
              [-37.421258270212618, -8.452013666979848],
              [-37.420415375312686, -8.451006742374782],
              [-37.419957606432305, -8.45016366211093],
              [-37.419881307195013, -8.449937676421388],
              [-37.419885148915341, -8.449096503553324],
              [-37.419888921571633, -8.448327836243248],
              [-37.419892763428436, -8.447333159311343],
              [-37.419972856997056, -8.446031400319216],
              [-37.419976652579919, -8.445344781509135],
              [-37.420056768875085, -8.44435008164502],
              [-37.419908036653332, -8.443581413200915],
              [-37.419602818512075, -8.442892861130469],
              [-37.419373945534964, -8.442512322249209],
              [-37.418763602849545, -8.441587285352085],
              [-37.418378313160908, -8.441052286275005],
              [-37.41784424570465, -8.440362757009254],
              [-37.417691648173651, -8.439973674247558],
              [-37.41777176505785, -8.439440559569304],
              [-37.417771765331644, -8.439132525447866],
              [-37.417851906079186, -8.438752940833393],
              [-37.418008275401249, -8.438220805216412],
              [-37.418084574249903, -8.4377592180987],
              [-37.417855701481017, -8.437144101648528],
              [-37.417779403106969, -8.437071645023163],
              [-37.417777121122768, -8.437072715014626],
              [-37.417626828166348, -8.437143170452543],
              [-37.417088941926103, -8.437367270611956],
              [-37.416547260569686, -8.437753490779295],
              [-37.416085696832106, -8.437905156447696],
              [-37.415467670045317, -8.438282808599942],
              [-37.414853555431513, -8.438433473140041],
              [-37.414852088442558, -8.43843319314046],
              [-37.414006678803815, -8.438277080347788],
              [-37.413472611074639, -8.4378946552097],
              [-37.413087345393414, -8.437351041185973],
              [-37.412629576439777, -8.436589078430192],
              [-37.412251925039662, -8.435665903728422],
              [-37.41194673083335, -8.435049810217604],
              [-37.411565283180579, -8.434442353586999],
              [-37.41095109904947, -8.434050359437343],
              [-37.410413258146377, -8.433894944911813],
              [-37.409718956259525, -8.433892057318142],
              [-37.40925736965886, -8.433890171923224],
              [-37.409256810662811, -8.433890357921562],
              [-37.408643230972828, -8.434122839963218],
              [-37.408029069359785, -8.434273551503097],
              [-37.407411089846079, -8.434344121528492],
              [-37.406644307420976, -8.434422307376472],
              [-37.406643073430025, -8.434422307375387],
              [-37.406026326972516, -8.434419466849674],
              [-37.40503069557856, -8.43410758987571],
              [-37.404416533647527, -8.433490564099237],
              [-37.40419147905191, -8.432648460038598],
              [-37.404038881921998, -8.431807336035815],
              [-37.403966400929626, -8.431273291230236],
              [-37.403890103109276, -8.43057707941071],
              [-37.40374132387911, -8.429817002917114],
              [-37.403512450182625, -8.42911988296904],
              [-37.403359876844057, -8.428513359535419],
              [-37.402978406135375, -8.427968790522472],
              [-37.402520636843704, -8.427587297447488],
              [-37.401982751941219, -8.427431881922645],
              [-37.401673761217594, -8.427429949662669],
              [-37.401672923223551, -8.42743018266049],
              [-37.401364771420255, -8.427511020896143],
              [-37.401212197343803, -8.427736076388728],
              [-37.400746791434834, -8.428113727675031],
              [-37.400361502727058, -8.428726980594199],
              [-37.399899937789847, -8.42910463288379],
              [-37.399434531808126, -8.429564287669681],
              [-37.398972967862463, -8.429951486901036],
              [-37.398354989077674, -8.430328160058945],
              [-37.397893400204126, -8.430634309784784],
              [-37.397199121981046, -8.431011006875401],
              [-37.396584960296032, -8.431243720915061],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '1605',
        NOME_UC1: 'FLORESTA NACIONAL DE NEGREIROS',
        ID_WCMC2: null,
        CATEGORI3: 'Floresta',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '2007',
        GID7: '2288',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº S/N de 11/10/2007',
        DT_ULTIM10: '23/04/2010',
        CODIGO_U11: '0000.00.1605',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.478026270036246, -7.970524004272226],
              [-39.47474126077369, -7.966683464146587],
              [-39.474482277897636, -7.966154930764943],
              [-39.474630941585296, -7.965466065317832],
              [-39.472609136248103, -7.964609926601528],
              [-39.470909830275389, -7.965189126715015],
              [-39.469400463313782, -7.964917327611801],
              [-39.467179851877823, -7.964250866394993],
              [-39.466416776731485, -7.961342736881727],
              [-39.466784852130992, -7.959994915496935],
              [-39.465831135220931, -7.959723390358175],
              [-39.464439451476792, -7.958749292894727],
              [-39.463588929057728, -7.956725825244919],
              [-39.461128248479305, -7.953460992916444],
              [-39.460853931599473, -7.951160933896717],
              [-39.458837878836896, -7.949556599847347],
              [-39.429988460326207, -7.953214635748846],
              [-39.416523861347123, -7.954949109202618],
              [-39.416459114053154, -7.954372815948519],
              [-39.41438713115307, -7.954645904220446],
              [-39.413962476242745, -7.962465982206671],
              [-39.40619305758711, -7.963552089632],
              [-39.405920355738651, -7.963588727789782],
              [-39.402725192429678, -7.964718780588535],
              [-39.400059580520278, -7.965966738552055],
              [-39.400055142104847, -7.966348455514826],
              [-39.399460613866019, -7.966435791382894],
              [-39.366268397820932, -7.970731919343177],
              [-39.367152174023779, -7.973018246477801],
              [-39.368284858086639, -7.975746794202618],
              [-39.368359502744902, -7.975883276470325],
              [-39.369543042735245, -7.975649666275836],
              [-39.371464875804264, -7.976514763116574],
              [-39.373608862727494, -7.979341260242898],
              [-39.374567502993507, -7.979742432538475],
              [-39.37845822629621, -7.978925564352675],
              [-39.379952556029664, -7.97920515310097],
              [-39.380979714188641, -7.980376575524091],
              [-39.381429073501899, -7.980979757106549],
              [-39.382173072553911, -7.98116478069986],
              [-39.382885337809235, -7.981492585762709],
              [-39.383608667041088, -7.981608347598821],
              [-39.383997822706121, -7.984160742757609],
              [-39.384296455559493, -7.984473379764487],
              [-39.3856101053195, -7.986923749834018],
              [-39.387152420669544, -7.986660383624606],
              [-39.389358602521718, -7.991234605499374],
              [-39.398971216331141, -7.98976685684869],
              [-39.402379979297599, -7.992171207632293],
              [-39.405607412485864, -7.994239871970602],
              [-39.408048422482153, -7.995660973679913],
              [-39.409297514536028, -7.995353925718364],
              [-39.418197200245729, -7.9938259937667],
              [-39.420481826261586, -7.993393047628889],
              [-39.423025278450005, -7.992910563659656],
              [-39.428709821811879, -7.991927120155587],
              [-39.429655734009565, -7.991814437968221],
              [-39.430910761256641, -7.991606944576007],
              [-39.428260019301653, -7.984713945693712],
              [-39.427409365161481, -7.983005058112544],
              [-39.425836349060845, -7.97984496215258],
              [-39.43360993921052, -7.978482435048043],
              [-39.460163699207754, -7.973805413994414],
              [-39.478026270036246, -7.970524004272226],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '1929',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL DE SANTA CRUZ',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2008',
        GID7: '12074',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 32.488 de 17/10/2008',
        DT_ULTIM10: '19/01/2016',
        CODIGO_U11: '0000.26.1929',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.785864623816614, -7.572432570386773],
              [-34.785493762047892, -7.574770291274911],
              [-34.784232965020074, -7.57699927426808],
              [-34.783866089550983, -7.577329146928313],
              [-34.780259600457327, -7.583549559991829],
              [-34.779565361866879, -7.584746476922468],
              [-34.769684407752948, -7.59254745279228],
              [-34.765731953965435, -7.608226377494621],
              [-34.765157855526297, -7.61084688845636],
              [-34.765064873800092, -7.611483104350638],
              [-34.764216606323565, -7.614241958252202],
              [-34.763572092556139, -7.624151653841976],
              [-34.764233143176739, -7.630786809116763],
              [-34.762720471721387, -7.637974075445947],
              [-34.778044549244001, -7.661294542534076],
              [-34.781284610708973, -7.663155467880288],
              [-34.781395411751781, -7.663344471754351],
              [-34.781629087716915, -7.663767004227809],
              [-34.782314665793919, -7.664936450261228],
              [-34.78285590605978, -7.665946318211698],
              [-34.783333271981348, -7.666692201795445],
              [-34.783447376883032, -7.667054595571208],
              [-34.783643774080758, -7.667546749578224],
              [-34.783766295905295, -7.667930071226793],
              [-34.785093012718157, -7.671272560896585],
              [-34.786745522938475, -7.676327043921361],
              [-34.787038093592329, -7.678044101220215],
              [-34.787862611403455, -7.681033834799257],
              [-34.787885504013239, -7.681359172750141],
              [-34.788286497522208, -7.683569265994523],
              [-34.788698205759111, -7.686063820441967],
              [-34.789833264642432, -7.689942191595279],
              [-34.789830543652094, -7.689958177492035],
              [-34.788862821946118, -7.694356517941111],
              [-34.788197804794201, -7.703467844700953],
              [-34.788205010715657, -7.703504254474863],
              [-34.786281947583525, -7.705800663620106],
              [-34.783319615789452, -7.720622888634624],
              [-34.783309005346077, -7.721397063718624],
              [-34.782738192580702, -7.722943557529334],
              [-34.782650676944314, -7.723376445725788],
              [-34.781436833078146, -7.724806715841217],
              [-34.780219635226402, -7.739557308511266],
              [-34.780095936237203, -7.742387609488431],
              [-34.780190745610341, -7.745215909626567],
              [-34.7803420200554, -7.747312409441655],
              [-34.780545517333536, -7.74907700339594],
              [-34.780565649994685, -7.749354188652921],
              [-34.780565632991966, -7.74935840562615],
              [-34.780614689169212, -7.75002937340721],
              [-34.7806307008997, -7.750249823021012],
              [-34.780631010895505, -7.750252602003607],
              [-34.780709162584749, -7.751321510282573],
              [-34.780764016106801, -7.752894267353257],
              [-34.78092094297979, -7.754295393580671],
              [-34.781367215553679, -7.758834223122783],
              [-34.781918610367711, -7.761856116349369],
              [-34.783007810955553, -7.766633843817886],
              [-34.784831474293078, -7.771031240196125],
              [-34.784934998363639, -7.771256611838676],
              [-34.784952032207343, -7.771298632583993],
              [-34.785902900601009, -7.773470525470915],
              [-34.786304681061331, -7.774245690833225],
              [-34.786794608653601, -7.775325237327596],
              [-34.786983840976347, -7.775705121049781],
              [-34.787509210249574, -7.776862732074059],
              [-34.787679683738666, -7.777204789023202],
              [-34.787757447924612, -7.777544235926167],
              [-34.787907176421108, -7.778103923483076],
              [-34.788168756712828, -7.779201386710266],
              [-34.788929064320463, -7.781686272489647],
              [-34.789209225676991, -7.782483546630957],
              [-34.78936577614148, -7.783014738373459],
              [-34.79018399942062, -7.785343171184487],
              [-34.790283553497105, -7.785603061606444],
              [-34.790460123824175, -7.786115452481853],
              [-34.790664015939818, -7.786637373315492],
              [-34.790751390105385, -7.786900568708287],
              [-34.791881434622496, -7.789849974801171],
              [-34.792214075635606, -7.790573014449247],
              [-34.78995022256909, -7.795029634700259],
              [-34.787825647727715, -7.802019683010458],
              [-34.784920325388413, -7.813908055793751],
              [-34.849164537382656, -7.81457742028963],
              [-34.850268851220292, -7.814413920075729],
              [-34.850745751676108, -7.814371571668435],
              [-34.851380187981725, -7.814285132647179],
              [-34.85188160028509, -7.814197260544385],
              [-34.852360475694958, -7.81420034085117],
              [-34.852836899123353, -7.814203314156956],
              [-34.853358031216523, -7.814206626491063],
              [-34.853852980521445, -7.814187331950259],
              [-34.854351239894534, -7.814032293269976],
              [-34.85489958184629, -7.813944534198429],
              [-34.855378330272949, -7.81392480964931],
              [-34.855899369367016, -7.813928027983985],
              [-34.856348728029097, -7.813886046555545],
              [-34.856869287250539, -7.81370868103158],
              [-34.85739481340471, -7.813575223233404],
              [-34.857850847017346, -7.813532524814064],
              [-34.858303775652978, -7.813490207390202],
              [-34.858823532818612, -7.813403237294141],
              [-34.859298653349803, -7.813270771455308],
              [-34.859775884881124, -7.813115104764611],
              [-34.860274858233801, -7.812982468943132],
              [-34.860729283951592, -7.812804048380693],
              [-34.861209013449269, -7.812670270553335],
              [-34.861731337596069, -7.812582866461732],
              [-34.86222929595624, -7.812450497637872],
              [-34.86272804832609, -7.812295384958295],
              [-34.863228490699242, -7.812116962427212],
              [-34.863727947048567, -7.811984169606977],
              [-34.864205037596562, -7.811805912058942],
              [-34.864681899115233, -7.811673046223794],
              [-34.865136949828759, -7.811494338663563],
              [-34.865616507312204, -7.811383420691477],
              [-34.866092414807021, -7.811295940568757],
              [-34.866612285018434, -7.811141347900253],
              [-34.86711202741234, -7.810940552510043],
              [-34.86759207792354, -7.810783955827056],
              [-34.868163407703676, -7.810696338770169],
              [-34.868684936810205, -7.810676955248031],
              [-34.869180686156092, -7.81059005513515],
              [-34.869657842688362, -7.810434429443999],
              [-34.870159002994384, -7.810346580340775],
              [-34.870611107667656, -7.810259169201387],
              [-34.871201837379765, -7.810058918869704],
              [-34.871658557019117, -7.809970614739044],
              [-34.872133097508815, -7.809905866471651],
              [-34.87256290539559, -7.809750682745469],
              [-34.873019257068918, -7.809616888902116],
              [-34.873474798716963, -7.809528810769216],
              [-34.873952279200495, -7.809441048649328],
              [-34.874412458798453, -7.809375489377206],
              [-34.875987560815041, -7.809645921740906],
              [-34.876662737784152, -7.809607424444293],
              [-34.877344866794402, -7.809432890012253],
              [-34.878028909697505, -7.809393884724861],
              [-34.878757250253024, -7.809378041321303],
              [-34.879433515137194, -7.809451833315623],
              [-34.880176558720358, -7.809234997192521],
              [-34.880836435005712, -7.808902594743176],
              [-34.881521508056707, -7.808636832889761],
              [-34.882295992421604, -7.808395027945816],
              [-34.883023411123517, -7.808175940826228],
              [-34.883774626678289, -7.807911624008706],
              [-34.884455595712886, -7.807714857716325],
              [-34.885114045993284, -7.807405604119618],
              [-34.885727019614507, -7.807096027493906],
              [-34.88627297279983, -7.806695738395908],
              [-34.886841192848713, -7.806251255592407],
              [-34.887276493972273, -7.805691120429318],
              [-34.887872543892421, -7.805130376379586],
              [-34.888442676019871, -7.804550253434704],
              [-34.888852857389878, -7.803905027792315],
              [-34.889925841572406, -7.805046575307895],
              [-34.890440837347136, -7.805584515258692],
              [-34.891017138571236, -7.806256084406825],
              [-34.89120538587747, -7.806670562915503],
              [-34.896385594797884, -7.807088647803065],
              [-34.899144818968352, -7.807333040139065],
              [-34.901814008909803, -7.807436996301361],
              [-34.903722017719048, -7.807296491489709],
              [-34.908622365346673, -7.806830142776796],
              [-34.922567615636893, -7.807274191479246],
              [-34.923268478600377, -7.80696750289466],
              [-34.924954046951761, -7.80555333897782],
              [-34.925509216358677, -7.804732325543014],
              [-34.926089877715739, -7.803705548425488],
              [-34.926330908467399, -7.802894430714068],
              [-34.926352309850131, -7.80210224373347],
              [-34.926260644082269, -7.801305486704669],
              [-34.926330074121374, -7.800489895904782],
              [-34.926740466081178, -7.798983746700288],
              [-34.927781043912482, -7.795157252585928],
              [-34.928188107261271, -7.793118325746302],
              [-34.931656751673529, -7.779409881735963],
              [-34.932163139480764, -7.778535467606651],
              [-34.932750751808015, -7.777473749716391],
              [-34.933542019981715, -7.775869181395627],
              [-34.934015435161946, -7.77480972041365],
              [-34.934374519149031, -7.773821801901871],
              [-34.934711462517832, -7.772517448375273],
              [-34.934957858670302, -7.771057664769758],
              [-34.935001676884752, -7.768797896083888],
              [-34.93596421459533, -7.749850438532044],
              [-34.936015790732611, -7.749081765427886],
              [-34.936303665943726, -7.748544693019165],
              [-34.937482296819155, -7.74605228457898],
              [-34.937949151139925, -7.744855005466754],
              [-34.938372984498358, -7.744075472683518],
              [-34.938728297154135, -7.743612739850439],
              [-34.939143954319817, -7.74320651470077],
              [-34.93981146246476, -7.743000265456827],
              [-34.940604658661179, -7.742805756223726],
              [-34.941278933777575, -7.742567141189005],
              [-34.94173448249721, -7.742377979693588],
              [-34.942190762371609, -7.741953229688524],
              [-34.94263912344794, -7.741318656005056],
              [-34.942996308247686, -7.740623686641887],
              [-34.943087227010302, -7.739972245823315],
              [-34.942921889551108, -7.739525760535268],
              [-34.941771476980847, -7.736847200698287],
              [-34.941301660213533, -7.735792274053006],
              [-34.941078845512699, -7.734862534783112],
              [-34.941061746067362, -7.734234552743725],
              [-34.941651470320942, -7.722942117578254],
              [-34.941393591901424, -7.721982814473537],
              [-34.941179707107047, -7.721090633973998],
              [-34.940822698195916, -7.720475248626669],
              [-34.940739537068751, -7.720105787908227],
              [-34.936723093806407, -7.716149943230226],
              [-34.936044819334846, -7.715481911000252],
              [-34.935330816278999, -7.714595017131441],
              [-34.928976646520418, -7.709148031323773],
              [-34.928177861986484, -7.708427038349439],
              [-34.927634612614966, -7.707595508247111],
              [-34.922580772329646, -7.700364592622098],
              [-34.922152881077103, -7.699558345439009],
              [-34.921653983565633, -7.698441956172001],
              [-34.921637258154014, -7.697756525500676],
              [-34.922376628553316, -7.687490366007001],
              [-34.922386219113712, -7.686553553946414],
              [-34.922630076993642, -7.685508767727005],
              [-34.922870615478132, -7.685086779561077],
              [-34.927730381289457, -7.677404518478659],
              [-34.92893522595773, -7.674904914119262],
              [-34.932643693991665, -7.669286376194909],
              [-34.933139011909709, -7.668359050401037],
              [-34.929789080255077, -7.666460671182681],
              [-34.928497161429625, -7.665707465089124],
              [-34.92778219288833, -7.665546316631203],
              [-34.927403412721723, -7.665548013366786],
              [-34.926990971633451, -7.665807585446029],
              [-34.925444217483104, -7.666885298582218],
              [-34.923467450256304, -7.668400781656758],
              [-34.921491723906897, -7.670087095649603],
              [-34.918799516717826, -7.672073625260137],
              [-34.916617348771304, -7.673971891771433],
              [-34.915211295944516, -7.675976466129452],
              [-34.914578651898893, -7.677135162364776],
              [-34.914114397102004, -7.679022321098374],
              [-34.913805382611478, -7.68021429834016],
              [-34.913273056144568, -7.680883706742589],
              [-34.912550902193551, -7.681412092910977],
              [-34.911652854739991, -7.68167219266083],
              [-34.910837945752832, -7.681800884298933],
              [-34.909838873227969, -7.681805328600555],
              [-34.908612236529393, -7.681627488904316],
              [-34.90794874258291, -7.681498283277773],
              [-34.90692413176393, -7.680739377398329],
              [-34.905801535914492, -7.679630219672405],
              [-34.905300007252102, -7.678764278822322],
              [-34.904207122724038, -7.676847658233089],
              [-34.903291250796109, -7.675041761061725],
              [-34.902375932135868, -7.672831655452751],
              [-34.901587387404199, -7.670802799781169],
              [-34.900557783676781, -7.668476451834287],
              [-34.899948590205277, -7.667037083548524],
              [-34.898970299227422, -7.66451081790496],
              [-34.898594074993085, -7.663098770603005],
              [-34.898526588174079, -7.662093380930402],
              [-34.898601555034652, -7.66146585395809],
              [-34.898535541150785, -7.660540347780345],
              [-34.898902569109815, -7.659488986690051],
              [-34.899248081707739, -7.657726201095064],
              [-34.899301468923525, -7.656810087938045],
              [-34.899185174359225, -7.655968555194851],
              [-34.898698378807431, -7.6547709724613],
              [-34.898629257725212, -7.654174710195064],
              [-34.898686784825124, -7.653384534242876],
              [-34.89854536244826, -7.65254365947921],
              [-34.897489647023228, -7.650052003570798],
              [-34.896803045292913, -7.648367788790591],
              [-34.89634302656885, -7.647126989350732],
              [-34.895464138120644, -7.645676658960201],
              [-34.89545510151568, -7.645188471049665],
              [-34.895407377882485, -7.642190992025197],
              [-34.895128848903788, -7.640793994698334],
              [-34.894936225105937, -7.639659512764244],
              [-34.894762128056485, -7.638693401775011],
              [-34.894895274308539, -7.636829290685839],
              [-34.894889379441373, -7.635203691991678],
              [-34.89490402529097, -7.633771163087084],
              [-34.895028716057325, -7.632725957799392],
              [-34.895081240982144, -7.632250932847302],
              [-34.895397308996479, -7.63168283366111],
              [-34.896184136787461, -7.630665755636323],
              [-34.897305704349641, -7.629235005458354],
              [-34.897808951134714, -7.628409958026724],
              [-34.898496092768035, -7.627249073847718],
              [-34.898743777322657, -7.626638366886395],
              [-34.898886310820856, -7.625793538340107],
              [-34.899018633403188, -7.624937171860312],
              [-34.899036229587871, -7.624464100872832],
              [-34.899041118684011, -7.624265666134816],
              [-34.899024895013007, -7.62395523609319],
              [-34.899112333553298, -7.623663998998844],
              [-34.899259924561029, -7.623495788164103],
              [-34.899456426161038, -7.623389919966481],
              [-34.899833524382359, -7.62332423163411],
              [-34.900104535334442, -7.623353311630074],
              [-34.900515756307371, -7.623277205386508],
              [-34.901008005714232, -7.623140626580508],
              [-34.901108006029276, -7.623045631249678],
              [-34.901220868366771, -7.622773123053402],
              [-34.90143168606766, -7.622356040839435],
              [-34.901470491881362, -7.622200283853278],
              [-34.901357353945507, -7.621875223839973],
              [-34.901200239330116, -7.621563201714684],
              [-34.900505037879768, -7.621045303537422],
              [-34.900423035630318, -7.620840708780734],
              [-34.900345502612467, -7.620239540542759],
              [-34.900251286629633, -7.619772827440796],
              [-34.899922238351721, -7.619384988682316],
              [-34.899270300640488, -7.618772818132194],
              [-34.898952565403256, -7.618197379571469],
              [-34.898714327638125, -7.617521401701582],
              [-34.89854667032423, -7.616875974684847],
              [-34.898383776061564, -7.616100451496737],
              [-34.898222197751743, -7.615380686955854],
              [-34.897970426087355, -7.614705198074165],
              [-34.897675398761102, -7.614008264299923],
              [-34.897240720457248, -7.613345478216283],
              [-34.896933277146097, -7.612764893695735],
              [-34.896673027463187, -7.612212077030377],
              [-34.896056878645837, -7.611357116045857],
              [-34.895247370585238, -7.610038443875501],
              [-34.894930033768084, -7.608828291343824],
              [-34.894764020027623, -7.607304202905129],
              [-34.895871088104776, -7.606759073099682],
              [-34.896434357551108, -7.605766077775228],
              [-34.896453940709804, -7.603808974208178],
              [-34.897620435059054, -7.603688599746482],
              [-34.898278891656076, -7.602900653184019],
              [-34.900483126817221, -7.601912190920078],
              [-34.902240964799091, -7.600204764922284],
              [-34.894830744688399, -7.598064602588692],
              [-34.888853308824707, -7.597473347377224],
              [-34.888124131433571, -7.597247213329126],
              [-34.887620601362862, -7.597006928520515],
              [-34.887235844408814, -7.596757938845917],
              [-34.886914878860559, -7.596684203101181],
              [-34.886571485640879, -7.596369193873183],
              [-34.886115837234577, -7.596094671313761],
              [-34.88568428860949, -7.595877730404873],
              [-34.885282325821322, -7.595573084072305],
              [-34.884842709267168, -7.595340334258442],
              [-34.884398023752759, -7.595104904458299],
              [-34.883975616041084, -7.594915311381912],
              [-34.883479063902655, -7.594698406429807],
              [-34.882916294306156, -7.594412061874689],
              [-34.882288690210395, -7.594102616423293],
              [-34.881812012939072, -7.593861860635852],
              [-34.881402847177405, -7.593598484036827],
              [-34.881000603376208, -7.593316677559475],
              [-34.880581400699832, -7.593038102050343],
              [-34.880245725337062, -7.592851305013881],
              [-34.879735787343428, -7.592567595477293],
              [-34.879201425532031, -7.592284697919403],
              [-34.878762694956187, -7.592074778961587],
              [-34.878297940531716, -7.591930103572285],
              [-34.8778178662252, -7.591780585203603],
              [-34.877345873902094, -7.591565115259051],
              [-34.876902714315833, -7.591420743882148],
              [-34.87642570298582, -7.591272155509651],
              [-34.875941014746026, -7.591074392444314],
              [-34.875420522849133, -7.590764060070072],
              [-34.874973563360811, -7.590515377353134],
              [-34.874698567675722, -7.590130346615853],
              [-34.874427899903701, -7.589827264361158],
              [-34.873842821411337, -7.589635822189349],
              [-34.873538889720813, -7.589584589313449],
              [-34.872488616701737, -7.589407442742882],
              [-34.871741918446098, -7.589175950718468],
              [-34.87106127666069, -7.588997488401092],
              [-34.870387009709496, -7.588996512960813],
              [-34.869704799878463, -7.588904394094135],
              [-34.869005996141553, -7.588857557928885],
              [-34.868310728378624, -7.588810203769299],
              [-34.867631835614098, -7.58858088677618],
              [-34.866937001892381, -7.588466732041153],
              [-34.866250006143936, -7.588304519616545],
              [-34.865488864031114, -7.588021427910643],
              [-34.864898760725538, -7.587606099157812],
              [-34.864338872194665, -7.58718943243354],
              [-34.863772139549191, -7.587022287120011],
              [-34.863672626313829, -7.58699289224083],
              [-34.862981698532828, -7.586924239219633],
              [-34.862304446649567, -7.586855401208651],
              [-34.861607522898879, -7.586808755043726],
              [-34.860884436314876, -7.586806161581658],
              [-34.860210766359849, -7.586805168142154],
              [-34.859527744414756, -7.586894559122402],
              [-34.858809170781271, -7.586915793512045],
              [-34.858123301962365, -7.586847593491393],
              [-34.85747357269549, -7.587046231799764],
              [-34.856759438953226, -7.587181316469179],
              [-34.85601974438751, -7.587338784979472],
              [-34.855486591356879, -7.587373352407096],
              [-34.854780289554697, -7.587510439068998],
              [-34.854229763542811, -7.587712657420224],
              [-34.853544619556352, -7.587888812847909],
              [-34.853036338318979, -7.587954372095132],
              [-34.852423094340431, -7.587308658791015],
              [-34.851710529995486, -7.586828611368933],
              [-34.851255227895983, -7.586089821760775],
              [-34.850816267654402, -7.585380587975875],
              [-34.850310041785271, -7.584868937891385],
              [-34.849771794334202, -7.584088874490932],
              [-34.849521052385583, -7.583827499985624],
              [-34.846657832662117, -7.585582182944663],
              [-34.84552037824534, -7.586989749250209],
              [-34.845192578470176, -7.587336094833084],
              [-34.844231699812795, -7.587115661597812],
              [-34.843670926154047, -7.586902759579454],
              [-34.843486078594879, -7.586817710997495],
              [-34.843310876956238, -7.586743667352038],
              [-34.842673976996899, -7.586336148519796],
              [-34.84223304670838, -7.585720171141614],
              [-34.841703678034563, -7.585175885249571],
              [-34.841098334786821, -7.584846839939818],
              [-34.840483458583734, -7.584557966368652],
              [-34.839819087693662, -7.58435606821217],
              [-34.839128817987238, -7.584169706939855],
              [-34.838528583731325, -7.583795419921194],
              [-34.837959786315459, -7.583307459645622],
              [-34.837503813153546, -7.582670392392133],
              [-34.837364477667641, -7.581961666803402],
              [-34.837207979310165, -7.581253046202712],
              [-34.837073556771067, -7.580568876461379],
              [-34.836929571250145, -7.579965168202433],
              [-34.836552039423268, -7.579444585261068],
              [-34.836016332578524, -7.579229190275905],
              [-34.835507099312068, -7.579350507168564],
              [-34.835110289080347, -7.579657567955103],
              [-34.834801858201381, -7.579942503940635],
              [-34.834491099424049, -7.580100513731206],
              [-34.834197681555331, -7.580200579901454],
              [-34.833922242566175, -7.580279238219613],
              [-34.833651043557616, -7.580339313658672],
              [-34.833327563991666, -7.580322473551958],
              [-34.833013793373404, -7.580274873647116],
              [-34.832718793636225, -7.580194579962475],
              [-34.83241720596785, -7.580085047459282],
              [-34.832127431227924, -7.579950030125879],
              [-34.831837353532251, -7.579751681194727],
              [-34.831499232288905, -7.57941401011727],
              [-34.831279929068138, -7.579208162280521],
              [-34.83125142422255, -7.579297156696124],
              [-34.831236472270831, -7.579393237075686],
              [-34.831246048087657, -7.579561157014882],
              [-34.831273063757166, -7.579754248805578],
              [-34.830993633334081, -7.580532046678086],
              [-34.830660681649391, -7.580801088748406],
              [-34.830156273362491, -7.580899158791876],
              [-34.82931973393147, -7.580442619140518],
              [-34.788857650877034, -7.56716012289261],
              [-34.785864623816614, -7.572432570386773],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2560',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO CAMUCIM',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12333',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei complementar nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '16/03/2016',
        CODIGO_U11: '0000.26.2560',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.191184286496544, -8.027675544283532],
              [-35.191001884864555, -8.027666949208621],
              [-35.190829157150134, -8.027672652050954],
              [-35.190632666582523, -8.027721530606033],
              [-35.190445849932516, -8.027784707078361],
              [-35.190249539339753, -8.027867067423784],
              [-35.19006282567539, -8.027949375776295],
              [-35.189976782273327, -8.028012016323101],
              [-35.189943729448579, -8.028112639669319],
              [-35.189934977401727, -8.028270532673959],
              [-35.189911957444345, -8.028452416518151],
              [-35.189903334379544, -8.028634225373029],
              [-35.189856577565301, -8.028864068900017],
              [-35.189814618715268, -8.029093886430603],
              [-35.189767553944137, -8.029266333316997],
              [-35.189710892244584, -8.029438830196279],
              [-35.189634907706242, -8.029587514211128],
              [-35.189573369053306, -8.029745688176751],
              [-35.18948791456868, -8.029918339034765],
              [-35.189364301338173, -8.030134242594864],
              [-35.189254904025397, -8.030316586375232],
              [-35.189121589880926, -8.030513408048039],
              [-35.189016786561076, -8.030657463071492],
              [-35.188907261266245, -8.030815892001668],
              [-35.188754880247039, -8.031036731510516],
              [-35.188583431353159, -8.031281587855425],
              [-35.188416679437786, -8.031507287323729],
              [-35.188211664790991, -8.031757105613838],
              [-35.187930177674133, -8.03206472948783],
              [-35.187681974349665, -8.032314777746064],
              [-35.187419579103874, -8.032603167753996],
              [-35.187176249732858, -8.032867538925958],
              [-35.186923118462168, -8.033093697330294],
              [-35.186760548597199, -8.033204576520854],
              [-35.186564669943465, -8.033368247357123],
              [-35.18634497544366, -8.033565528966058],
              [-35.186115657019066, -8.033758077597815],
              [-35.186279379722336, -8.033862437059652],
              [-35.186496169992132, -8.034019131231144],
              [-35.186674442566407, -8.034152113524007],
              [-35.186905603732384, -8.03430394673614],
              [-35.187127193966312, -8.034460613911225],
              [-35.187204307346597, -8.034522385578716],
              [-35.187261969906061, -8.034536428531499],
              [-35.18730985556229, -8.034517040686787],
              [-35.187362309215054, -8.034454580115161],
              [-35.187462289574341, -8.034305768118021],
              [-35.187571867861955, -8.034156905127947],
              [-35.187700615009952, -8.034003157182076],
              [-35.18781524822586, -8.033902098896162],
              [-35.187906296563362, -8.033877698113328],
              [-35.187973531054794, -8.033886907103119],
              [-35.188050567445835, -8.033934328860488],
              [-35.188103891976446, -8.034034493270664],
              [-35.188167224377949, -8.034211135208869],
              [-35.188221036840247, -8.034402178050064],
              [-35.1882939921665, -8.034583551965412],
              [-35.188409983192017, -8.034735997092397],
              [-35.188506343421821, -8.034807232714229],
              [-35.188578325884663, -8.034806849767499],
              [-35.18864540639764, -8.034787358936946],
              [-35.18871710589994, -8.034734362319636],
              [-35.188778875520605, -8.034619236084458],
              [-35.188816958277201, -8.034561634472173],
              [-35.188874389869, -8.034532629694459],
              [-35.188946448321019, -8.034546595657865],
              [-35.189066674389778, -8.034593787447189],
              [-35.189172324591055, -8.034607574435471],
              [-35.189258599960837, -8.034587981619172],
              [-35.189407131882923, -8.034544141998747],
              [-35.189565132751433, -8.034476335535143],
              [-35.189718283662891, -8.034398988127883],
              [-35.189842718778422, -8.034336143609476],
              [-35.189991096722174, -8.034263605168681],
              [-35.190072368156457, -8.034205773588395],
              [-35.190096003027691, -8.034138682025377],
              [-35.190095515095948, -8.0340478035943],
              [-35.19003248965133, -8.033928559296752],
              [-35.189887320894584, -8.033704521597667],
              [-35.189674431714671, -8.033380396477733],
              [-35.18944203872109, -8.032998976702997],
              [-35.189301464957261, -8.03273664824707],
              [-35.189252554444757, -8.032564712289625],
              [-35.189251887538155, -8.032440352068205],
              [-35.189260818559902, -8.032315940853913],
              [-35.189322613176969, -8.032205597588815],
              [-35.189427621468091, -8.032099808325697],
              [-35.189595194268513, -8.032027167899145],
              [-35.189801335757316, -8.03198780429131],
              [-35.190108353479665, -8.031967035638322],
              [-35.190348136710611, -8.031937059995458],
              [-35.190511063525335, -8.031893142385645],
              [-35.1906739133508, -8.031834876865677],
              [-35.190817389344666, -8.031743231541148],
              [-35.190975106252708, -8.031622810406919],
              [-35.191156688999634, -8.031478346440171],
              [-35.191343121703547, -8.031343423417068],
              [-35.191486545704592, -8.031242211152428],
              [-35.191577516052931, -8.031203460459457],
              [-35.191673465340301, -8.031198166560381],
              [-35.191764743645521, -8.031216812508042],
              [-35.191856279914809, -8.031283290156187],
              [-35.192010429686221, -8.031392481581051],
              [-35.192197939239264, -8.03145844730027],
              [-35.192442829391332, -8.031485841301599],
              [-35.19266849869026, -8.031508554318727],
              [-35.192865452193239, -8.031545769224673],
              [-35.192932814666698, -8.031578893064776],
              [-35.193000510093519, -8.031674195515601],
              [-35.193049216634854, -8.031807865712601],
              [-35.193117144029259, -8.031946216893923],
              [-35.193203829341591, -8.032003152598511],
              [-35.193357620163297, -8.032045381442581],
              [-35.193458573386117, -8.032078325307516],
              [-35.193540409741381, -8.032125721068441],
              [-35.193670283731407, -8.032182425804928],
              [-35.193833569495844, -8.032205470775947],
              [-35.194020592117191, -8.032180557064128],
              [-35.194226783598893, -8.032150757396463],
              [-35.194480885733292, -8.032106352854083],
              [-35.194600751852363, -8.032086580062654],
              [-35.194710892061053, -8.032042943413769],
              [-35.194825702251897, -8.031975365918219],
              [-35.194954342414377, -8.031802484092051],
              [-35.195068458702245, -8.031605763404963],
              [-35.19522055276115, -8.031332309225368],
              [-35.19537749777696, -8.031068395989445],
              [-35.195524767875227, -8.030790183836267],
              [-35.195672190951967, -8.030540670503409],
              [-35.195848278831754, -8.030267087341585],
              [-35.195933653327302, -8.030080087573284],
              [-35.196095164339667, -8.029773099610475],
              [-35.196237531488116, -8.029475781573515],
              [-35.196351209837005, -8.029197749395484],
              [-35.196407842540175, -8.029020468546058],
              [-35.196440482422453, -8.028843315678847],
              [-35.196444587483626, -8.028714147490927],
              [-35.196434424634255, -8.028608971142658],
              [-35.196390645039138, -8.028499191799456],
              [-35.19630349679143, -8.028356160633901],
              [-35.196158585998582, -8.02817995663542],
              [-35.195994813302306, -8.028066034233504],
              [-35.195816747699091, -8.027971320701122],
              [-35.195508732116579, -8.027805552522095],
              [-35.195138283006742, -8.027630550356903],
              [-35.194844946277307, -8.027517318859182],
              [-35.194595004196842, -8.02744212015379],
              [-35.194508601845051, -8.027437798119875],
              [-35.194484660016876, -8.027447492042265],
              [-35.194484890984569, -8.027490539772757],
              [-35.194586228153597, -8.027595230188423],
              [-35.194726493960665, -8.027800159003617],
              [-35.194828346057875, -8.028000510820398],
              [-35.194930043176569, -8.028172163816837],
              [-35.195065383037409, -8.02835320277822],
              [-35.195239163604647, -8.028543603708119],
              [-35.19539857427565, -8.028738864597482],
              [-35.195519595233186, -8.028934329458423],
              [-35.19560697344852, -8.029120408354421],
              [-35.195626914201121, -8.029259014500202],
              [-35.19562278314357, -8.029383399718114],
              [-35.19558475337972, -8.029450567270477],
              [-35.195469687224822, -8.029470314065525],
              [-35.195220180083318, -8.029476428850986],
              [-35.195009036658959, -8.029477555694841],
              [-35.194870053672268, -8.029511780382329],
              [-35.194779238302409, -8.029579229895617],
              [-35.194679106964635, -8.029699344072464],
              [-35.194530604038782, -8.029747968662962],
              [-35.194372169231301, -8.029734463635712],
              [-35.194218199434715, -8.029658754001231],
              [-35.194059148713414, -8.029530455692667],
              [-35.193899738042397, -8.029335194803354],
              [-35.193744793382251, -8.029077728306849],
              [-35.193415374251913, -8.028500716689088],
              [-35.193221654932145, -8.028171708613456],
              [-35.193076308200311, -8.027914190124163],
              [-35.192950721246291, -8.027761796990234],
              [-35.192820617288596, -8.027662042523328],
              [-35.19270999114817, -8.027614800741199],
              [-35.192522841544751, -8.027615797602861],
              [-35.192403053414886, -8.027649918304526],
              [-35.192254755460404, -8.027736806655467],
              [-35.192154342162134, -8.027804307161681],
              [-35.192082515677754, -8.027833388928798],
              [-35.191991340358207, -8.027833874861365],
              [-35.191880739214163, -8.027791415049318],
              [-35.191626152148046, -8.027744939160735],
              [-35.191395557902894, -8.027698335289944],
              [-35.191184286496544, -8.027675544283532],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2241',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA ENGENHO MACAXEIRA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12126',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '01/02/2016',
        CODIGO_U11: '0000.26.2241',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.858873144310245, -7.73289805071217],
              [-34.858770725154116, -7.732784871359801],
              [-34.858662442021668, -7.732701618814029],
              [-34.858512060224676, -7.732588648428001],
              [-34.858385817222626, -7.732511454831745],
              [-34.858283765006973, -7.73248199794943],
              [-34.858187839725261, -7.73248241588211],
              [-34.858092044422222, -7.732512733625548],
              [-34.857984415183516, -7.732578986133412],
              [-34.857895007771937, -7.732698980313207],
              [-34.857835709114561, -7.732848745324791],
              [-34.85780023330318, -7.732962525580311],
              [-34.857752715590081, -7.733064396903163],
              [-34.85774721755422, -7.733178046179754],
              [-34.857753866403094, -7.733327524237657],
              [-34.857778450125807, -7.733464964383872],
              [-34.857791304895734, -7.733662257143133],
              [-34.857822014552312, -7.733829572104353],
              [-34.857858745159675, -7.734002840031856],
              [-34.857877176952741, -7.734104424401038],
              [-34.857877804850737, -7.734247948492587],
              [-34.857872515780869, -7.734409439466338],
              [-34.857855157813553, -7.734553041545294],
              [-34.85785578571155, -7.734696565636855],
              [-34.857898382295303, -7.734839906757885],
              [-34.857958780774126, -7.734941309156477],
              [-34.85803122315464, -7.73505461848784],
              [-34.858109713481788, -7.735179862747681],
              [-34.858187915855673, -7.735239324423922],
              [-34.858259991295768, -7.735268912285147],
              [-34.858343926667239, -7.735268546344087],
              [-34.858499676521348, -7.735237966642812],
              [-34.858625449599913, -7.735207517920456],
              [-34.858757321616551, -7.735200963050951],
              [-34.858841257988061, -7.735200597109895],
              [-34.858895320567051, -7.735224281996357],
              [-34.858979622879048, -7.735307639525367],
              [-34.859046280270448, -7.735468816549758],
              [-34.859113015649122, -7.73564793346057],
              [-34.859155664224367, -7.735803234505937],
              [-34.859192291848714, -7.735952582584913],
              [-34.85920483266964, -7.736078113798516],
              [-34.859199413620999, -7.736209702961583],
              [-34.859140166955136, -7.736371428897574],
              [-34.85906903336204, -7.736557127673685],
              [-34.859009655717394, -7.736688952798921],
              [-34.858944361104882, -7.736838743806374],
              [-34.858854900701708, -7.736946779061896],
              [-34.858729573550903, -7.737078891140795],
              [-34.858568325660897, -7.737223120118717],
              [-34.858419094676869, -7.737373278067199],
              [-34.858287770570954, -7.737505416141895],
              [-34.858192367204204, -7.737625437317572],
              [-34.858091046869518, -7.737763425375446],
              [-34.857995801477216, -7.73791932732395],
              [-34.85788835420874, -7.73802744056687],
              [-34.857751244113729, -7.738207446334586],
              [-34.857673905599491, -7.738345329409285],
              [-34.857608688974246, -7.73851306130321],
              [-34.857597299966208, -7.738650657424259],
              [-34.857615705763465, -7.73874626183133],
              [-34.857675948267776, -7.73881178245712],
              [-34.857796093331665, -7.738865082200703],
              [-34.858018265615364, -7.738941857864526],
              [-34.858222557016738, -7.739042632364267],
              [-34.858373097788089, -7.739191483523363],
              [-34.858511491674783, -7.739304504901187],
              [-34.858667687456524, -7.739375587556492],
              [-34.85879367150104, -7.739392979531418],
              [-34.858937457440661, -7.739368431783901],
              [-34.859135228971972, -7.739349628036409],
              [-34.859273229922472, -7.739372947981919],
              [-34.859429347717004, -7.739426089750827],
              [-34.859591644436904, -7.739521066259012],
              [-34.859724121355654, -7.73965205451907],
              [-34.859844633360012, -7.73978907473285],
              [-34.859923151682835, -7.739920298954948],
              [-34.859989862065653, -7.740093435903784],
              [-34.860056782414496, -7.740314413549771],
              [-34.860087547062363, -7.740493687435511],
              [-34.860106477774529, -7.74070889508568],
              [-34.860119071586894, -7.740846386223681],
              [-34.860161512196079, -7.74095384557194],
              [-34.860257806418247, -7.741037149109503],
              [-34.860444086958545, -7.741132020634624],
              [-34.860769693161771, -7.740185716845893],
              [-34.861238047545413, -7.740345140152682],
              [-34.860990121801152, -7.741231302374632],
              [-34.861170012450167, -7.741236497463218],
              [-34.861391792797704, -7.741223568694832],
              [-34.861625459072464, -7.741186666086206],
              [-34.861841217469177, -7.741167783351433],
              [-34.862056870882931, -7.741124979767999],
              [-34.862206573790431, -7.741082464138298],
              [-34.862326325918197, -7.741046059449632],
              [-34.86241599528735, -7.740985864891274],
              [-34.862511686607405, -7.740931624299247],
              [-34.862565357250254, -7.740865606753472],
              [-34.862618082046218, -7.740584303570086],
              [-34.862647796864294, -7.740524371969592],
              [-34.86270762043668, -7.740494209200929],
              [-34.862809516677785, -7.740487783310431],
              [-34.862899526991669, -7.740505331260059],
              [-34.862977783357088, -7.740576751860738],
              [-34.863062429613734, -7.740737848897916],
              [-34.863128905034813, -7.740857164187383],
              [-34.863207266383185, -7.740952505636672],
              [-34.863351525246273, -7.741035599207982],
              [-34.863501885047441, -7.741142586632172],
              [-34.863592185314523, -7.741225915165582],
              [-34.863664629694995, -7.7413392234971],
              [-34.863713172242242, -7.741470577698268],
              [-34.86376202973846, -7.741673692445351],
              [-34.863798974311628, -7.741894801070436],
              [-34.863847569850357, -7.742038114195948],
              [-34.863902001369773, -7.742145520552691],
              [-34.864016389440231, -7.742252665951604],
              [-34.864112553683704, -7.742306067678453],
              [-34.864244664662088, -7.742353331468489],
              [-34.864340776914048, -7.742394773271027],
              [-34.864425002238804, -7.742460187913765],
              [-34.864521587414146, -7.74260927103515],
              [-34.86464207642274, -7.742740310286917],
              [-34.864768296428956, -7.742811520921369],
              [-34.864888469488612, -7.74287079762725],
              [-34.865002489618696, -7.742894219555943],
              [-34.865188349227125, -7.742893406686626],
              [-34.865350175023686, -7.742880737876116],
              [-34.865500035905669, -7.742874101019332],
              [-34.865650081757934, -7.742909325897702],
              [-34.865812169511905, -7.742956458708796],
              [-34.865932369567254, -7.743021715376818],
              [-34.866166798718723, -7.743158234670904],
              [-34.866449350485532, -7.743330424771657],
              [-34.866641653976714, -7.743431246263325],
              [-34.866936275640946, -7.743621324259018],
              [-34.867254853129893, -7.743805316309453],
              [-34.867621237285263, -7.743953216620659],
              [-34.867933503870205, -7.744065473120976],
              [-34.868071636799478, -7.744118689877361],
              [-34.868173849989581, -7.744184024532863],
              [-34.868240249423408, -7.744285396935949],
              [-34.868270701122292, -7.744392908275938],
              [-34.868295077878926, -7.744482504725235],
              [-34.868337231534873, -7.744524181489898],
              [-34.868421167906455, -7.744523813548956],
              [-34.868564928850432, -7.744493281839309],
              [-34.868714446787855, -7.744408903474467],
              [-34.868863859742348, -7.74430060326096],
              [-34.868977063004458, -7.74413864036273],
              [-34.869077931411702, -7.743898987947953],
              [-34.869154870989959, -7.743671401440585],
              [-34.869207512798887, -7.743372158370445],
              [-34.869254052669739, -7.743049021447463],
              [-34.869294834547105, -7.742779732179724],
              [-34.869324073441909, -7.74261215626029],
              [-34.869377453131584, -7.742480357130725],
              [-34.869424915853372, -7.742366524883388],
              [-34.869478479513234, -7.742276585488916],
              [-34.869586028764878, -7.742192391094537],
              [-34.869651636326466, -7.742114359632793],
              [-34.869675170219338, -7.742012592292943],
              [-34.869680585268078, -7.741881003129603],
              [-34.869655812575331, -7.741701705247952],
              [-34.869679293476693, -7.741587976983748],
              [-34.869738747108705, -7.741474091744849],
              [-34.869828046537592, -7.741330174716232],
              [-34.869911403002831, -7.741198243607736],
              [-34.869928861953298, -7.741078562377187],
              [-34.869910348173057, -7.740959038121344],
              [-34.869855914653655, -7.740851632764485],
              [-34.869777657288282, -7.740780213163788],
              [-34.869657405241306, -7.740702997571449],
              [-34.86950123445515, -7.740637899878057],
              [-34.869363101525842, -7.740584683121726],
              [-34.869182895927793, -7.740507730487196],
              [-34.869056595934225, -7.740418579966309],
              [-34.868954278761166, -7.740329325462292],
              [-34.868852224545506, -7.740299871579836],
              [-34.868762266223015, -7.740294285554475],
              [-34.86864256808672, -7.740342652167431],
              [-34.868463126365242, -7.740439122435545],
              [-34.868319446408471, -7.740487594031674],
              [-34.868193726321294, -7.740530006678299],
              [-34.868079997144335, -7.740572366333289],
              [-34.867936501157772, -7.740662699664566],
              [-34.86778080429508, -7.740705243290097],
              [-34.867595155652488, -7.740753898856704],
              [-34.867433330855981, -7.740766567667235],
              [-34.867289544916247, -7.740791118414689],
              [-34.867145627997793, -7.740785768351406],
              [-34.866983566239547, -7.740744616502512],
              [-34.86680338663723, -7.740673642830201],
              [-34.866605063195145, -7.740566866372251],
              [-34.866424673626881, -7.740448051002672],
              [-34.866202236385632, -7.740311478717167],
              [-34.865943485468975, -7.740097322898286],
              [-34.86566666869976, -7.739865304180334],
              [-34.865443995496811, -7.739674910235416],
              [-34.865203362424218, -7.739490575240012],
              [-34.864932647593129, -7.739282450374994],
              [-34.864740110140175, -7.739127805224125],
              [-34.864601505287467, -7.738966944148995],
              [-34.864474865349244, -7.738800051120155],
              [-34.864390246088291, -7.738644934045188],
              [-34.864317485758825, -7.738459864167796],
              [-34.864292716066011, -7.738280564286298],
              [-34.864256007454479, -7.738113277320693],
              [-34.864231237761608, -7.737933978439189],
              [-34.863083642735205, -7.737376848192246],
              [-34.862993921374532, -7.737425082826301],
              [-34.862868254278922, -7.737479453397217],
              [-34.862718420392646, -7.737492068216234],
              [-34.862574478478464, -7.737480737190841],
              [-34.862418360683883, -7.737427596421995],
              [-34.862280124771651, -7.73735045581712],
              [-34.862123693028046, -7.737225553502381],
              [-34.862003102036439, -7.737070593402213],
              [-34.861900392927119, -7.73689163346603],
              [-34.861845725445981, -7.736730404450018],
              [-34.861838811639672, -7.736521125770497],
              [-34.861801711091893, -7.73626413637268],
              [-34.861788566368737, -7.736001062029572],
              [-34.8617879894623, -7.735869497862237],
              [-34.861799481453318, -7.735755822589772],
              [-34.861840715258126, -7.735588195679017],
              [-34.861893677015729, -7.735360714155162],
              [-34.861952633728365, -7.735133206635568],
              [-34.861969963699984, -7.734983624594369],
              [-34.861945299990118, -7.734828244561657],
              [-34.861884769532509, -7.734696943352235],
              [-34.86178842331875, -7.73460167889044],
              [-34.861650267393728, -7.734542479172098],
              [-34.861518316389791, -7.734531094155185],
              [-34.861362541539926, -7.734555694894334],
              [-34.861164929983005, -7.734610379414768],
              [-34.860937235667578, -7.734641274065553],
              [-34.860727397238698, -7.734642189918182],
              [-34.860535413696418, -7.73461312497274],
              [-34.860325234322822, -7.734536298317465],
              [-34.860133014818857, -7.734453412712632],
              [-34.859952708237785, -7.734352534229801],
              [-34.859784103613734, -7.734185821171772],
              [-34.859645528756751, -7.734030938059102],
              [-34.859524624816054, -7.73380421441326],
              [-34.859464200341449, -7.733696833052482],
              [-34.859379583080468, -7.73354171397771],
              [-34.859264859065142, -7.733356825071131],
              [-34.859174195857463, -7.733189773067866],
              [-34.85907777165648, -7.733076567719678],
              [-34.858969410536758, -7.732975375287434],
              [-34.858873144310245, -7.73289805071217],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2256',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE ENGENHO MORENINHO',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5451',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '16/03/2016',
        CODIGO_U11: '0000.26.2256',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.113868434598075, -8.107885327765858],
              [-35.113487619348874, -8.10784484384418],
              [-35.113392376294541, -8.107829655091967],
              [-35.113281378979501, -8.10782808792635],
              [-35.113188500811269, -8.107826419816773],
              [-35.113072954429448, -8.107822633806681],
              [-35.112968760985432, -8.107823292162893],
              [-35.112875917912987, -8.107826124758642],
              [-35.112783053825439, -8.107826712747984],
              [-35.112676624523708, -8.1078318928397],
              [-35.112538461521545, -8.107832764244918],
              [-35.112418389360165, -8.107829001512563],
              [-35.112311807419502, -8.107811619643892],
              [-35.112221078233013, -8.107794137053025],
              [-35.112105379213794, -8.107767791208195],
              [-35.112012317978525, -8.107741290961419],
              [-35.111883020901878, -8.107715022471655],
              [-35.111769698530068, -8.107704455753366],
              [-35.111663245642738, -8.107705125477979],
              [-35.111543229866754, -8.107710391878953],
              [-35.111452786922655, -8.107733522738746],
              [-35.11136242836826, -8.107767934479178],
              [-35.111276729196653, -8.107820374512142],
              [-35.111191236402746, -8.107902137996],
              [-35.111171498307762, -8.10799251641658],
              [-35.111165424076304, -8.10809408917688],
              [-35.111150378400751, -8.108209269168636],
              [-35.111130846753852, -8.108328980312303],
              [-35.111104403455805, -8.108432935712999],
              [-35.111089650908575, -8.108588720060377],
              [-35.111090326498541, -8.108683486732598],
              [-35.111086499876563, -8.108782786954302],
              [-35.111082640486046, -8.108877586381679],
              [-35.111076817358587, -8.109015262750917],
              [-35.111068704333086, -8.109148438451596],
              [-35.111049186806561, -8.109270410426543],
              [-35.111036329999614, -8.109374286177321],
              [-35.111030192479873, -8.109466838830048],
              [-35.111017377785629, -8.109575224584633],
              [-35.111007061796904, -8.109717443716757],
              [-35.111005579018432, -8.109828013131727],
              [-35.111006332110605, -8.109934060738873],
              [-35.111007096959987, -8.110042359923638],
              [-35.111007850067509, -8.110148407529538],
              [-35.111013105365508, -8.11024991232534],
              [-35.111020709272282, -8.110364937923917],
              [-35.111039694240205, -8.110486664081854],
              [-35.111069978210047, -8.110603814512006],
              [-35.111086541910808, -8.110702983594807],
              [-35.111130424225287, -8.110822303599656],
              [-35.11118577679931, -8.110961847400866],
              [-35.111229398718443, -8.111045056886962],
              [-35.11128202506768, -8.111119187677692],
              [-35.11133241563553, -8.111197851836566],
              [-35.1113963763041, -8.111274166228219],
              [-35.111471682887355, -8.111352664165095],
              [-35.111553643096499, -8.111410814243857],
              [-35.11164700101795, -8.11148017959481],
              [-35.111747174476115, -8.111551758900818],
              [-35.111840553491056, -8.111623373404704],
              [-35.111954454400184, -8.111715167024965],
              [-35.112050167922533, -8.111795801360639],
              [-35.112120810597659, -8.111856280123519],
              [-35.112200469448126, -8.111909931739859],
              [-35.112286946167053, -8.111965799610328],
              [-35.112396093823349, -8.112026034624447],
              [-35.11249614536883, -8.112079561874328],
              [-35.11259154222283, -8.112117321698555],
              [-35.112693677112922, -8.112146016852943],
              [-35.112809180104222, -8.112143031777167],
              [-35.112926876778651, -8.112131012543607],
              [-35.113024145445358, -8.112112348990383],
              [-35.113125768370445, -8.112068836974096],
              [-35.113224859640908, -8.111989237849395],
              [-35.113317102320707, -8.111900658449247],
              [-35.113380023087494, -8.111830320649073],
              [-35.113431590729085, -8.111755531546496],
              [-35.113471610425201, -8.111649228561095],
              [-35.113536471089262, -8.111533746155679],
              [-35.113547157083865, -8.111443421641061],
              [-35.113532847246866, -8.111341973290767],
              [-35.113511822856466, -8.111251850356313],
              [-35.113468017384371, -8.111143818662004],
              [-35.113408180624674, -8.111011067436575],
              [-35.113348266396258, -8.110867035273593],
              [-35.113311386917168, -8.110777013087741],
              [-35.113274516887842, -8.11068924016682],
              [-35.113255705038441, -8.11059233460777],
              [-35.11326175504994, -8.110486251861037],
              [-35.11328142686812, -8.110386841780292],
              [-35.113346374285953, -8.110282640306648],
              [-35.113427134500938, -8.110171569483606],
              [-35.113508110814166, -8.110092089856225],
              [-35.113593878068144, -8.110048680953597],
              [-35.113686808360029, -8.110057119675956],
              [-35.113784198649448, -8.110056505550331],
              [-35.11387235439625, -8.110031134310855],
              [-35.114003678977916, -8.110023538220227],
              [-35.114128397125469, -8.110043067730462],
              [-35.114228199333546, -8.110062745033146],
              [-35.114319046391103, -8.110096017937495],
              [-35.11440999230016, -8.110145091085061],
              [-35.11448302324883, -8.110221348551482],
              [-35.114560655158932, -8.110308854168364],
              [-35.114642815184126, -8.110396336254023],
              [-35.114706738518542, -8.110465890665784],
              [-35.114739050314313, -8.11055142615967],
              [-35.114755563266279, -8.110643833286872],
              [-35.114767853613166, -8.110779145198151],
              [-35.114768736920141, -8.110903235022937],
              [-35.114760343457604, -8.110998057806199],
              [-35.114740860014585, -8.111124539724084],
              [-35.114707557563563, -8.111219519610106],
              [-35.114685530591053, -8.111307660596207],
              [-35.114663557542549, -8.111402563147205],
              [-35.114641619728317, -8.111501984982914],
              [-35.114599540726836, -8.111637623161288],
              [-35.114588887897845, -8.111732466939442],
              [-35.114580602332673, -8.111840822115303],
              [-35.11458379905234, -8.11197167103751],
              [-35.11460942708451, -8.112073051250697],
              [-35.114635163048042, -8.112187963842388],
              [-35.11467224266017, -8.112305066843438],
              [-35.114709221266395, -8.112408621217311],
              [-35.114773234348178, -8.112491705692433],
              [-35.114843964365249, -8.11256347395857],
              [-35.114907857716645, -8.112630758246699],
              [-35.114980769566287, -8.112691224580797],
              [-35.115064879371765, -8.112733571072356],
              [-35.115166969692204, -8.112755485398004],
              [-35.115271077432027, -8.112743554555221],
              [-35.115370593567341, -8.112722617867272],
              [-35.115476939367731, -8.112706155885261],
              [-35.11561958485143, -8.112698490993717],
              [-35.115714709280148, -8.112697888017841],
              [-35.115839251916242, -8.112692594192154],
              [-35.115943338251405, -8.112676143834666],
              [-35.116040522145418, -8.112646198702016],
              [-35.116126089735019, -8.112575715119849],
              [-35.116188953437423, -8.112496347612188],
              [-35.116235856208903, -8.112403539370368],
              [-35.116273672238108, -8.11230627508615],
              [-35.116318235641344, -8.112202197702793],
              [-35.116358428523583, -8.112120714885496],
              [-35.1163962352352, -8.112023452965341],
              [-35.116440833851556, -8.111923894861571],
              [-35.116478727434952, -8.111837913788696],
              [-35.116523434200232, -8.111754146625477],
              [-35.116570355592145, -8.111663589917615],
              [-35.116633122730732, -8.111570689970016],
              [-35.116704960025039, -8.111479975998234],
              [-35.116774530972826, -8.111389283056951],
              [-35.116846502196793, -8.111316627708353],
              [-35.116929659562068, -8.111225845627636],
              [-35.1170827497264, -8.111094013329803],
              [-35.117172898880177, -8.111030269984447],
              [-35.117256331225192, -8.110977849776711],
              [-35.11734659780047, -8.110929894976016],
              [-35.117450432067741, -8.110879601797876],
              [-35.117533963044785, -8.110840713957527],
              [-35.117626608774586, -8.110808540547556],
              [-35.117771336351062, -8.110776040790165],
              [-35.117886721019239, -8.110757261190811],
              [-35.117997674092408, -8.110752056034984],
              [-35.118106440730202, -8.110758136563852],
              [-35.118201726866324, -8.110780104109235],
              [-35.118297088110332, -8.110811091639013],
              [-35.118428840703785, -8.11086441772766],
              [-35.118508514005782, -8.110920328757864],
              [-35.118597334977999, -8.110987454954902],
              [-35.118693070513615, -8.111072597522384],
              [-35.118779700582472, -8.111151025597962],
              [-35.118843624552987, -8.111220567573447],
              [-35.118921158680457, -8.11129453966752],
              [-35.1190329023609, -8.111399893567009],
              [-35.119112674595662, -8.111469334507788],
              [-35.119185584302706, -8.111529799995738],
              [-35.119260699147659, -8.111581224339636],
              [-35.119367413699095, -8.111616655725474],
              [-35.11947630991672, -8.11164078519181],
              [-35.119585041494979, -8.111642359873208],
              [-35.119675562021477, -8.111630507975983],
              [-35.119770518707412, -8.111605083870433],
              [-35.11986091929051, -8.111577441090294],
              [-35.119960227517254, -8.111527180123687],
              [-35.12004591389978, -8.111472477537697],
              [-35.120151832941787, -8.111397351755571],
              [-35.120228491499141, -8.111347224846826],
              [-35.120314144928287, -8.111288023770499],
              [-35.120401928243453, -8.111210756933163],
              [-35.12047622153591, -8.111147104784161],
              [-35.120541428124334, -8.111079003627463],
              [-35.12059750805242, -8.11100193191084],
              [-35.12064218318735, -8.110913654501477],
              [-35.120702645172685, -8.110816246739933],
              [-35.120751923689475, -8.110739217372503],
              [-35.120821559383714, -8.110657553301969],
              [-35.120936416262062, -8.11056431926821],
              [-35.121040249762956, -8.110514025312497],
              [-35.121144311537755, -8.110495313062792],
              [-35.121259662797215, -8.110472024861705],
              [-35.121356944349948, -8.110455618010057],
              [-35.12144745028651, -8.110441504992874],
              [-35.121553728966568, -8.110416021600537],
              [-35.121646383574095, -8.11038610589919],
              [-35.121766053149798, -8.110333449913348],
              [-35.12185183788695, -8.110292291012026],
              [-35.121926206216649, -8.110239926507207],
              [-35.12199592860263, -8.110169543123307],
              [-35.122045161978825, -8.110085742844655],
              [-35.12202864750482, -8.109993338748096],
              [-35.121984852027701, -8.10988756007732],
              [-35.121927576059356, -8.109795412268932],
              [-35.121829793167194, -8.109741873377301],
              [-35.121736863261319, -8.109733436539242],
              [-35.121646409207671, -8.109754308809359],
              [-35.121535748698811, -8.109800137474014],
              [-35.121452251448538, -8.109843536002792],
              [-35.121348408888473, -8.109893830055917],
              [-35.121258107713309, -8.109935014676511],
              [-35.121163118590921, -8.109955919729108],
              [-35.121070339172043, -8.109967783614914],
              [-35.120966100931447, -8.10996167331613],
              [-35.12087543956487, -8.109953215221278],
              [-35.120786858732515, -8.109919932111614],
              [-35.120704963945435, -8.109870813452444],
              [-35.120634188964928, -8.109792275500228],
              [-35.120617698277435, -8.109702129704852],
              [-35.120623802946426, -8.109605067437784],
              [-35.120661693127289, -8.109519086263481],
              [-35.120717787050104, -8.10944427308065],
              [-35.120780736520892, -8.109378435335831],
              [-35.120868672279094, -8.109321471693185],
              [-35.120956747008073, -8.109284820537539],
              [-35.121049424872169, -8.109257156529226],
              [-35.121137558452226, -8.109229523106077],
              [-35.121252949271963, -8.109210744982787],
              [-35.121379716775927, -8.109200927971241],
              [-35.121486301195176, -8.109218307257846],
              [-35.121574834759805, -8.109244819414648],
              [-35.121667962505235, -8.109282588884795],
              [-35.12176572648449, -8.109333876324289],
              [-35.121856573547085, -8.109367147429701],
              [-35.121947378387986, -8.109395908540936],
              [-35.122042652312722, -8.109415616713036],
              [-35.12214679145621, -8.109408185144765],
              [-35.122237214745276, -8.109382800446088],
              [-35.122338844973761, -8.109341540443447],
              [-35.122424497617523, -8.109282336792786],
              [-35.122498679498847, -8.109202891260308],
              [-35.122556973431266, -8.109119046080957],
              [-35.122612944326306, -8.109026181055716],
              [-35.12266440158114, -8.108937858990728],
              [-35.122700034199418, -8.108851889511861],
              [-35.122733367502455, -8.108761419511609],
              [-35.122764465143803, -8.108675482919015],
              [-35.122850150537708, -8.108620789245368],
              [-35.122940648987601, -8.108606678364703],
              [-35.123047188436267, -8.108617295690038],
              [-35.123167370800388, -8.10863683680688],
              [-35.123267191375874, -8.108658779592954],
              [-35.123376086712668, -8.108682908164475],
              [-35.123480468243883, -8.108709328050598],
              [-35.12359167475919, -8.10874021557489],
              [-35.123682533574794, -8.108775744771275],
              [-35.123773449050546, -8.108820296331896],
              [-35.123868930186205, -8.108869334250064],
              [-35.123964434819733, -8.108920632894771],
              [-35.124055338489867, -8.108962925874316],
              [-35.12417568354455, -8.109005035237931],
              [-35.124284546050944, -8.109024662772535],
              [-35.124404683713401, -8.109037441906999],
              [-35.124515671019815, -8.109036735859801],
              [-35.124637978419564, -8.109035973060177],
              [-35.124733077741851, -8.109030858142974],
              [-35.124846366922668, -8.109036911058881],
              [-35.124939317707593, -8.10904760797747],
              [-35.125050361692914, -8.109055931107166],
              [-35.1251501397916, -8.109071095600919],
              [-35.12524540429655, -8.109090800737484],
              [-35.125336131276988, -8.109108280216441],
              [-35.125447373224027, -8.109143677164202],
              [-35.12554722684478, -8.109170120078],
              [-35.125656233185424, -8.109210048059815],
              [-35.125785693195013, -8.109258873436866],
              [-35.125878865733362, -8.109301161043449],
              [-35.125969746146374, -8.109341202025028],
              [-35.126058404833223, -8.109385764755004],
              [-35.126183384067069, -8.109441382101501],
              [-35.126283327517058, -8.109481366438182],
              [-35.12637182127628, -8.109503376742351],
              [-35.126467116285191, -8.109525333009591],
              [-35.126560102723765, -8.109542800382576],
              [-35.126657633406552, -8.109560234752722],
              [-35.126748261422684, -8.109564172290913],
              [-35.126841073259826, -8.109556819495374],
              [-35.12694968103262, -8.109540343027602],
              [-35.127037835060229, -8.109514959921704],
              [-35.127137250069737, -8.109480488392061],
              [-35.127234386891473, -8.109443779436344],
              [-35.127324686738461, -8.109400332803208],
              [-35.127405770399925, -8.109336644283227],
              [-35.127491370163199, -8.109270668982216],
              [-35.127590532502573, -8.109200096466255],
              [-35.127669469926381, -8.109152208202399],
              [-35.12775759083091, -8.109122324228608],
              [-35.127863962741714, -8.109110369406292],
              [-35.127972716552101, -8.109114195821014],
              [-35.128076977933169, -8.109124814403456],
              [-35.128169992347154, -8.109144532883109],
              [-35.128290285748456, -8.10917987938875],
              [-35.128376586105738, -8.109210923362518],
              [-35.128476496541332, -8.1092463948066],
              [-35.128562862952393, -8.109286458731049],
              [-35.128660558969393, -8.109328715047164],
              [-35.128767384013344, -8.109379934715589],
              [-35.128860533222259, -8.109419963117924],
              [-35.128951371189892, -8.109453232532301],
              [-35.129058040398903, -8.109481890500856],
              [-35.129157634490767, -8.109472238561256],
              [-35.129247988526437, -8.10943782317764],
              [-35.12934056623731, -8.109396618173497],
              [-35.12944887864851, -8.109339527958147],
              [-35.129557193297053, -8.109282428453454],
              [-35.129651983090788, -8.109234450050426],
              [-35.129748968425901, -8.109177428246516],
              [-35.129827782634415, -8.109111497288296],
              [-35.129895246347722, -8.109043376364422],
              [-35.12997388600818, -8.10895488620937],
              [-35.130029944729337, -8.108875550641971],
              [-35.130081298397016, -8.108771437224066],
              [-35.130096350391156, -8.108658521041082],
              [-35.13008206294586, -8.108561593592498],
              [-35.13003166747103, -8.108482932613526],
              [-35.12994755794972, -8.108440598639568],
              [-35.129856819294069, -8.108420859393819],
              [-35.129763890008313, -8.108412424352785],
              [-35.129659706837231, -8.108413086964781],
              [-35.12955776875198, -8.108411467689395],
              [-35.129451298050967, -8.108409881334914],
              [-35.129353906832719, -8.10841049911307],
              [-35.12925875612008, -8.108408846602835],
              [-35.12914544386426, -8.108400534139076],
              [-35.129050158442467, -8.108378578418302],
              [-35.128948078680345, -8.108356658175435],
              [-35.128850425503089, -8.108321172692206],
              [-35.128727712831534, -8.108265544326104],
              [-35.128625371179091, -8.108207520846934],
              [-35.128543464357527, -8.108156143108529],
              [-35.128434250540153, -8.108086883510092],
              [-35.128347851362307, -8.108042300230718],
              [-35.128263775040317, -8.108004475838618],
              [-35.128114019643682, -8.10796705695684],
              [-35.127998420008502, -8.107956506781614],
              [-35.127864793326751, -8.107957359705773],
              [-35.12771764891118, -8.107969571339551],
              [-35.127624849337955, -8.107979175791309],
              [-35.127532052137767, -8.107988791781102],
              [-35.127398416055449, -8.107989633033512],
              [-35.127291964179335, -8.107990306857682],
              [-35.127178609379946, -8.107975222979194],
              [-35.127069803995923, -8.107964627806689],
              [-35.126960974903064, -8.107949522551255],
              [-35.12682485072434, -8.107918793794546],
              [-35.126709085795646, -8.107883430031499],
              [-35.126620559423493, -8.107856909707234],
              [-35.126513833927312, -8.107819219313125],
              [-35.126400196346353, -8.107765785121469],
              [-35.1263023688348, -8.107705476507398],
              [-35.126190820763284, -8.107627208001212],
              [-35.126097443488398, -8.107555597181586],
              [-35.125999472251422, -8.107474987629326],
              [-35.125926593403356, -8.107419035758303],
              [-35.125844620994854, -8.107358635016405],
              [-35.125751472445174, -8.107318608034182],
              [-35.125637964708268, -8.10728322286616],
              [-35.125531284254208, -8.107252302972364],
              [-35.125426969127403, -8.107234912821141],
              [-35.125309122076885, -8.107226632094203],
              [-35.125175528325208, -8.107231984827932],
              [-35.125069154309813, -8.107243938853651],
              [-35.124969586436386, -8.107258106665578],
              [-35.12485637557883, -8.107263334490167],
              [-35.12475898429453, -8.107263941843302],
              [-35.124657015603432, -8.107257820493835],
              [-35.124536869207617, -8.107245041346728],
              [-35.124416798040343, -8.107241284450934],
              [-35.124298917969064, -8.107228493414812],
              [-35.124208233667076, -8.107215523546435],
              [-35.124103963347011, -8.107204903867084],
              [-35.124008668853776, -8.107182937613697],
              [-35.123854343552871, -8.107141039876536],
              [-35.1237590253929, -8.107114561181284],
              [-35.12366600653872, -8.10709258297716],
              [-35.123575258917484, -8.107072853791159],
              [-35.123475471761246, -8.107055430252828],
              [-35.123386945613738, -8.107028909122773],
              [-35.123296174280931, -8.107004658233777],
              [-35.123194139682134, -8.106989516464752],
              [-35.123103366037114, -8.106965265539552],
              [-35.123021537690256, -8.106925169603638],
              [-35.122923656624614, -8.106858091521685],
              [-35.122839470035764, -8.106804463375903],
              [-35.122753029043864, -8.106753117086201],
              [-35.12266218004504, -8.106717587492296],
              [-35.122560145455388, -8.106702436283207],
              [-35.122460421983135, -8.10669404176415],
              [-35.122362990776637, -8.106690138528169],
              [-35.122252025040432, -8.106693093023008],
              [-35.122159279428601, -8.106709476231959],
              [-35.122068936784352, -8.106746150863394],
              [-35.121974044958357, -8.106780588287069],
              [-35.121867790742215, -8.106808341554681],
              [-35.121763741746335, -8.106829302987947],
              [-35.121657388581482, -8.106843505321786],
              [-35.121544382415493, -8.106878064741208],
              [-35.121444868351837, -8.106899002491559],
              [-35.121356747115762, -8.106928894353356],
              [-35.121250483442289, -8.10695663827283],
              [-35.121153216807421, -8.106975296437337],
              [-35.121051443308431, -8.106996245863614],
              [-35.120936038959584, -8.107012774533189],
              [-35.120836447048895, -8.107022422030393],
              [-35.120748273971586, -8.10704554297212],
              [-35.120660152615727, -8.107075434704456],
              [-35.120574389327977, -8.107118835476012],
              [-35.120490937169976, -8.107169004540799],
              [-35.12041655743765, -8.107221368753404],
              [-35.120337741321528, -8.107285046571018],
              [-35.120265663122545, -8.107341908985092],
              [-35.120189005202377, -8.107392035713062],
              [-35.120105585845899, -8.107446714749374],
              [-35.12001543798786, -8.107510460743869],
              [-35.119927328227682, -8.107542601608472],
              [-35.119827682115812, -8.107545489670173],
              [-35.119734785551394, -8.107541559944599],
              [-35.119632727040859, -8.107521897953722],
              [-35.11951709415959, -8.107506823683643],
              [-35.119421886559159, -8.10749614639383],
              [-35.119292806011387, -8.107499212877373],
              [-35.11919547090362, -8.107508850588797],
              [-35.119091287232337, -8.107509508126308],
              [-35.118991685813548, -8.107519166833425],
              [-35.118880839426922, -8.10753791133282],
              [-35.118761031350033, -8.10757251471377],
              [-35.118632204763493, -8.107611684412904],
              [-35.118516898669661, -8.107641742553776],
              [-35.118401625462084, -8.107676313009668],
              [-35.118286364050107, -8.10771314194794],
              [-35.118195910044378, -8.107734013382617],
              [-35.11806030080816, -8.107775488329473],
              [-35.117974447561934, -8.107805356208287],
              [-35.117881757791935, -8.107830760976185],
              [-35.117777717363985, -8.107851721462522],
              [-35.117678136807996, -8.107863629084301],
              [-35.117553659320428, -8.107877952416665],
              [-35.117463172310615, -8.107894313675621],
              [-35.117370374285933, -8.107903927406321],
              [-35.117225624217348, -8.107934175259988],
              [-35.117135137163366, -8.107950536452643],
              [-35.117022047758248, -8.107973813926039],
              [-35.116913326484671, -8.107974494513071],
              [-35.116813701121501, -8.107979633391649],
              [-35.116714141548464, -8.107993799257009],
              [-35.116621331613253, -8.108001152000897],
              [-35.116514890749407, -8.108004072268583],
              [-35.116422059731413, -8.108009173446277],
              [-35.116308890317917, -8.10802116985071],
              [-35.116216071056925, -8.108028522553949],
              [-35.116123204766645, -8.108029104385849],
              [-35.116021322307645, -8.108034261741057],
              [-35.115914815645098, -8.10802816178219],
              [-35.115810631580977, -8.108028811573766],
              [-35.115704136717845, -8.108024970072337],
              [-35.115590880170174, -8.108023424540828],
              [-35.115491221814587, -8.108024050751345],
              [-35.115382502753221, -8.108024733235656],
              [-35.115255537777777, -8.108007485777685],
              [-35.115155747887876, -8.107990060179915],
              [-35.115046995937057, -8.107986230219277],
              [-35.114942713228025, -8.107973347354079],
              [-35.114840788494227, -8.107973985166415],
              [-35.114741109010154, -8.107972352723509],
              [-35.114630064847248, -8.107964033591802],
              [-35.114532642209483, -8.107960128493211],
              [-35.114439742965047, -8.107956197582711],
              [-35.114319574652576, -8.107938905289352],
              [-35.114185665022575, -8.107901382519476],
              [-35.114083627555303, -8.107886226879828],
              [-35.113986270657577, -8.107891350984971],
              [-35.113868434598075, -8.107885327765858],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '975',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL DE SIRINHAÉM',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1998',
        GID7: '5009',
        QUALIDAD8:
          'Esquemático (O poligono é uma representação esquemática da dimensão da unidade).',
        ATO_LEGA9: 'Decreto nº 21229 de 28/12/1998',
        DT_ULTIM10: '29/04/2011',
        CODIGO_U11: '0000.26.0975',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.230565825497678, -8.664866097113675],
              [-35.182398166900001, -8.58492966743],
              [-35.115203018099997, -8.5866601733],
              [-35.0969601814, -8.572296774050001],
              [-35.0765341607, -8.57376312874],
              [-35.056168536800001, -8.586527687789999],
              [-35.051253623599997, -8.60146935046],
              [-35.066821255500003, -8.624888964769999],
              [-35.077185075800003, -8.60991778208],
              [-35.1093099056, -8.588500345770001],
              [-35.221117840799998, -8.6836822845],
              [-35.230565825497678, -8.664866097113675],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '8',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL CHAPADA DO ARARIPE',
        ID_WCMC2: '198356',
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '1997',
        GID7: '12',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº sn de 04/08/1997',
        DT_ULTIM10: '27/04/2011',
        CODIGO_U11: '0000.00.0008',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.24447156131243, -6.963522069748405],
              [-40.243884081910352, -6.964210620172261],
              [-40.242716784547646, -6.964946389866336],
              [-40.241496097783966, -6.965392704252483],
              [-40.238661754430943, -6.965752730911071],
              [-40.237215991605865, -6.965738410882091],
              [-40.235774045761204, -6.965715058910201],
              [-40.234328258943741, -6.965691681935519],
              [-40.232882496119338, -6.96567738590648],
              [-40.231844885426291, -6.965662113198024],
              [-40.230643267514601, -6.964833350235548],
              [-40.228442177832576, -6.965037938313528],
              [-40.22699259568693, -6.965475684574052],
              [-40.226004577198914, -6.96603929843641],
              [-40.225356074136194, -6.966845639106578],
              [-40.224818210939354, -6.968113078100044],
              [-40.224741913315938, -6.969614628047354],
              [-40.225478146322999, -6.970670819289116],
              [-40.227316835790049, -6.971452852023807],
              [-40.228404039735025, -6.971984032681346],
              [-40.229029632652647, -6.972868581866694],
              [-40.228674868705873, -6.973439343174746],
              [-40.22746179720513, -6.973478458004361],
              [-40.226195312436204, -6.973056917551566],
              [-40.224814392430453, -6.972689277687874],
              [-40.222914655211298, -6.972142823494972],
              [-40.221243837201747, -6.971830504076653],
              [-40.220263456149738, -6.971760886737329],
              [-40.21846292943048, -6.972253929395046],
              [-40.217471091837652, -6.972988744228303],
              [-40.217101078096761, -6.974726362534607],
              [-40.217665646407632, -6.975656172401023],
              [-40.218413336045899, -6.975790167175548],
              [-40.219050384560411, -6.975797291624562],
              [-40.219790437630138, -6.976736206572329],
              [-40.220065085070026, -6.977549205915159],
              [-40.221144628347282, -6.979011686989981],
              [-40.22327326190716, -6.979621074984519],
              [-40.22551630743898, -6.980519920334444],
              [-40.22687815963409, -6.982107780878565],
              [-40.228354446800346, -6.983985096778821],
              [-40.2296667044413, -6.985447089039914],
              [-40.230288503299995, -6.986440322574171],
              [-40.230341890742849, -6.986674946210815],
              [-40.230395303234395, -6.986846590224478],
              [-40.230277047841312, -6.987136511397365],
              [-40.23031900374064, -6.988176497203741],
              [-40.230303753992949, -6.989279906586291],
              [-40.230105381950651, -6.991134336331475],
              [-40.228915196403108, -6.993606255613104],
              [-40.228545181566183, -6.995461639220377],
              [-40.228629093180395, -6.997776678428141],
              [-40.228899945238105, -6.999114199632212],
              [-40.22929666704227, -6.999637766805774],
              [-40.229750617436892, -7.000170413969396],
              [-40.230658518058192, -7.001452149001609],
              [-40.230856889901503, -7.003721412574441],
              [-40.230849252505664, -7.004300300104118],
              [-40.231368046767464, -7.004416645810043],
              [-40.233042705967577, -7.004448589917041],
              [-40.233447063076333, -7.00451056985961],
              [-40.234309189643866, -7.004987896671649],
              [-40.235095040939896, -7.006505187201681],
              [-40.235949507161145, -7.007497465926916],
              [-40.237040506070983, -7.008037284543134],
              [-40.23766612217473, -7.008677662196921],
              [-40.238120073477972, -7.009327587660473],
              [-40.23817348378595, -7.00973480926551],
              [-40.237349494916884, -7.010586923527951],
              [-40.236590373809641, -7.011158663518178],
              [-40.234278642002465, -7.011237336253554],
              [-40.23121929067657, -7.011027998132188],
              [-40.229716297171684, -7.011122411401089],
              [-40.227587687613315, -7.0105130223961],
              [-40.224654181086535, -7.009426307623721],
              [-40.221663444913666, -7.008402991428498],
              [-40.218501065955387, -7.007371131152255],
              [-40.216891273835451, -7.006652056208342],
              [-40.215853662369739, -7.006347350227901],
              [-40.21487328122798, -7.006395033182491],
              [-40.213816601822252, -7.007473646906801],
              [-40.213397016964457, -7.008515076347543],
              [-40.213557205072512, -7.009500207574935],
              [-40.214125613569678, -7.010150132125547],
              [-40.214583360119356, -7.010447201702425],
              [-40.215956666633765, -7.01151102940026],
              [-40.217040028151807, -7.012620632599702],
              [-40.21823403175123, -7.013910957803852],
              [-40.218569727659869, -7.014850338442557],
              [-40.21873757521243, -7.015193647518307],
              [-40.21797845314903, -7.015711020832865],
              [-40.217055302745123, -7.015577981912477],
              [-40.216021509295238, -7.015218932258873],
              [-40.215212817995592, -7.015202727728171],
              [-40.214392624094359, -7.016064364934816],
              [-40.213694550876326, -7.016228860408489],
              [-40.212309812543033, -7.016322807771369],
              [-40.210345232519465, -7.016119686462149],
              [-40.207137078513966, -7.01428290496658],
              [-40.206793746293819, -7.013813681508957],
              [-40.20540518885462, -7.014079760838866],
              [-40.204012837344109, -7.014472197409386],
              [-40.202506024737225, -7.014738275647455],
              [-40.201289135042423, -7.015066800736609],
              [-40.200476600588068, -7.015286173792861],
              [-40.199477151640238, -7.016545487478537],
              [-40.198996495120383, -7.017704683166144],
              [-40.199046088230105, -7.018400871037107],
              [-40.200201977135713, -7.018352698222718],
              [-40.201712560849508, -7.017914487018476],
              [-40.202513661758132, -7.018500522132407],
              [-40.202784490946051, -7.019666400364044],
              [-40.202132191506159, -7.020988670943001],
              [-40.20212453215192, -7.021513192797534],
              [-40.202864608264385, -7.022388637132472],
              [-40.205519625465733, -7.022544588261465],
              [-40.207411725455842, -7.023443897349194],
              [-40.20845313203381, -7.023567879416326],
              [-40.209216071330395, -7.022715765108562],
              [-40.210379574542593, -7.02220651706006],
              [-40.211359955726074, -7.022104467432367],
              [-40.212214444893959, -7.023160192778455],
              [-40.212607347539773, -7.023918825544087],
              [-40.213236782747558, -7.024387562228315],
              [-40.21467111405633, -7.025161467712167],
              [-40.216166447020491, -7.025827132891476],
              [-40.216341955103609, -7.025419445467269],
              [-40.216410616254521, -7.024605491390854],
              [-40.216017690651171, -7.023792468950268],
              [-40.215338686249169, -7.022736277742664],
              [-40.215235682515519, -7.022040089828531],
              [-40.216280907468757, -7.02164860698313],
              [-40.217204057872408, -7.021781647904246],
              [-40.218523953211864, -7.022266588027955],
              [-40.219565358833407, -7.022336203420595],
              [-40.220438941176909, -7.021710121845436],
              [-40.221499414790991, -7.020332530912012],
              [-40.222372996993997, -7.019887171255407],
              [-40.223116845306663, -7.020473206326765],
              [-40.223906490840072, -7.021646698919055],
              [-40.224356622858544, -7.022694809998011],
              [-40.224638953556791, -7.023101053786951],
              [-40.226138105904631, -7.023241731110411],
              [-40.228041637142155, -7.023725250697296],
              [-40.228152256132226, -7.024023275000406],
              [-40.227732647229871, -7.025118558121957],
              [-40.226565349914857, -7.025790904192328],
              [-40.226267792330589, -7.026659688763775],
              [-40.226145718829393, -7.027121276907555],
              [-40.226702673870939, -7.028458332342511],
              [-40.227332131938304, -7.029107790947148],
              [-40.22853372602539, -7.029701929327986],
              [-40.230036718346717, -7.029842582655959],
              [-40.230860707449274, -7.028691978179111],
              [-40.232134804655111, -7.028480265436466],
              [-40.23415279717333, -7.028855052764643],
              [-40.235239976974398, -7.029566515355253],
              [-40.235640493975701, -7.029800626266702],
              [-40.23813532294669, -7.02914306514037],
              [-40.239642134609575, -7.028813120285908],
              [-40.24039364331324, -7.028829302773836],
              [-40.241663922278939, -7.02896187596932],
              [-40.242636688842786, -7.029727680146725],
              [-40.243079184731322, -7.031010835818008],
              [-40.243472110285346, -7.031887280883029],
              [-40.243529316798735, -7.031995524280302],
              [-40.244269368997813, -7.032762283271599],
              [-40.245009422196873, -7.03352901926326],
              [-40.246378910471094, -7.03493617991618],
              [-40.24751186618186, -7.036633238652434],
              [-40.248225213120747, -7.039254426537942],
              [-40.249186525044138, -7.040942893193647],
              [-40.250323298995639, -7.042296176990397],
              [-40.25228408396854, -7.042607519658205],
              [-40.254134180988075, -7.042575598291577],
              [-40.256331453610869, -7.042488332526468],
              [-40.257655190061769, -7.042801140689121],
              [-40.258746165929047, -7.043395279989062],
              [-40.260516193339548, -7.044864419589795],
              [-40.261820814793808, -7.04662443609007],
              [-40.261694922091479, -7.047375455504035],
              [-40.26071074687961, -7.047540905747311],
              [-40.259493856309923, -7.047698299857145],
              [-40.257933632210083, -7.047783608129769],
              [-40.25689604455787, -7.047713991736082],
              [-40.255969052128407, -7.047644352428946],
              [-40.255267159523491, -7.048333415763246],
              [-40.255538011622797, -7.049608002357621],
              [-40.255812659829864, -7.05071045698389],
              [-40.256434457682005, -7.051703714533931],
              [-40.257117281324902, -7.052416083810323],
              [-40.258261667547153, -7.053353554102331],
              [-40.259688386551645, -7.054589049834559],
              [-40.261111286215296, -7.056294232758254],
              [-40.26121426676935, -7.057216430329137],
              [-40.259646429396867, -7.05771806510693],
              [-40.257906927365362, -7.05804849877333],
              [-40.257147805168621, -7.058728947114661],
              [-40.256266609603252, -7.059698851631082],
              [-40.255209929902485, -7.061139842195769],
              [-40.252989771222609, -7.062782558645448],
              [-40.252333653798878, -7.064104828232431],
              [-40.252139099847639, -7.065787591026853],
              [-40.252520569933047, -7.067360201930291],
              [-40.253443721254108, -7.067601485211179],
              [-40.254599564074134, -7.067671100699783],
              [-40.255301479630759, -7.067045018989059],
              [-40.256178879039076, -7.066310181065258],
              [-40.257575049894228, -7.065456180259134],
              [-40.259028449070613, -7.064955034389801],
              [-40.260306389572591, -7.064336565083929],
              [-40.261469868744285, -7.063890251660123],
              [-40.262393019250275, -7.063896934342286],
              [-40.262854606999866, -7.063904546657864],
              [-40.263606091705135, -7.063920799148639],
              [-40.264395738057821, -7.065320276407149],
              [-40.264723796660661, -7.066712186350181],
              [-40.264884030620109, -7.067878040512267],
              [-40.265566854318465, -7.068518443221924],
              [-40.266940136739791, -7.069699571242997],
              [-40.267375018314581, -7.071443848166718],
              [-40.267924335765414, -7.073594857751774],
              [-40.268134141013491, -7.075221834200836],
              [-40.267939587066536, -7.0768955160545],
              [-40.267062210648909, -7.07763938792511],
              [-40.265730883017532, -7.077625091967025],
              [-40.264815344919256, -7.07703095379726],
              [-40.264418625211398, -7.076389597316242],
              [-40.263785394667906, -7.076382449862697],
              [-40.262965223941187, -7.077008531481272],
              [-40.262553230431159, -7.077525441071566],
              [-40.261630078889745, -7.077564044117613],
              [-40.260939595018939, -7.077213097672258],
              [-40.260077469276006, -7.07696229249445],
              [-40.258975038902555, -7.077118684696579],
              [-40.258215939604653, -7.077925979280473],
              [-40.257735260080672, -7.079075629038089],
              [-40.257380495941533, -7.079881479947542],
              [-40.25620176605284, -7.081376838093417],
              [-40.254748365790931, -7.081986715311755],
              [-40.25335980852104, -7.082026784983718],
              [-40.251746196975283, -7.081886597555783],
              [-40.250662812463645, -7.08077699433338],
              [-40.250182155036754, -7.077983675640357],
              [-40.249571812831626, -7.076068220603294],
              [-40.24850370103529, -7.073909574661229],
              [-40.247195238480906, -7.072321225125166],
              [-40.246104239525145, -7.071844853116909],
              [-40.244715682256491, -7.071884922790514],
              [-40.242857946931572, -7.072377966390599],
              [-40.24227428767923, -7.072831916221547],
              [-40.242201807230735, -7.074053090869049],
              [-40.242468840218159, -7.075499344437978],
              [-40.242922791381481, -7.076320935885382],
              [-40.243083001659116, -7.077080057475983],
              [-40.243124958320358, -7.07840947656725],
              [-40.242823604806404, -7.079215351517182],
              [-40.241656307386883, -7.080014053831325],
              [-40.239962579269822, -7.081321027696565],
              [-40.239432330457511, -7.082009578167837],
              [-40.238444335139569, -7.082347207376481],
              [-40.237177851291406, -7.082033933255158],
              [-40.236342430997013, -7.079702665527466],
              [-40.235617628419881, -7.078013710050067],
              [-40.235392574548996, -7.077317522033177],
              [-40.235114084918308, -7.076793954943208],
              [-40.234080316611568, -7.076254182358623],
              [-40.233508111003268, -7.075785446711206],
              [-40.232836720050265, -7.074096002280205],
              [-40.231993638261372, -7.072461389387843],
              [-40.230692859151198, -7.070239831645548],
              [-40.229781140121034, -7.069527904186802],
              [-40.228804577497229, -7.068879400298524],
              [-40.228064524161169, -7.068293365222091],
              [-40.227488500445041, -7.067996295547051],
              [-40.226504301336497, -7.068034921546402],
              [-40.225752816584688, -7.068082140676426],
              [-40.22550485128582, -7.069412513531099],
              [-40.226408933872833, -7.070757671198923],
              [-40.226580600524194, -7.0709379052561],
              [-40.226801859287555, -7.071805759242622],
              [-40.226794222929897, -7.072330281101979],
              [-40.226553894123121, -7.072964001126673],
              [-40.224989875006273, -7.073112780013436],
              [-40.22210596254633, -7.072785652711503],
              [-40.220721224481508, -7.072536267118378],
              [-40.218821510229809, -7.07204369057601],
              [-40.217493977763425, -7.071793839030706],
              [-40.215590445262578, -7.071654117376607],
              [-40.213450404248235, -7.071740916183505],
              [-40.212172441937447, -7.072124295891911],
              [-40.212050391299606, -7.072757526011109],
              [-40.212321243479671, -7.073923870251003],
              [-40.213751756582759, -7.074987209014498],
              [-40.215945209944934, -7.075252823143761],
              [-40.217967020706197, -7.075274755595291],
              [-40.219294529215247, -7.075470263465979],
              [-40.22038934623324, -7.075828847179993],
              [-40.220561012926652, -7.075955203559229],
              [-40.22067542607963, -7.076009081326825],
              [-40.220957732725466, -7.076478770740788],
              [-40.221289610476298, -7.07764464903314],
              [-40.221495596665171, -7.079380334821967],
              [-40.221068350490739, -7.08088283950862],
              [-40.221854178637237, -7.082571796031812],
              [-40.22317025563742, -7.083517857410224],
              [-40.224493968811068, -7.084174907521928],
              [-40.226157173456151, -7.085011305828405],
              [-40.227232921648856, -7.086591064233644],
              [-40.227217647938566, -7.087631539005614],
              [-40.226626374193025, -7.088781653670898],
              [-40.226263972880766, -7.089876960843167],
              [-40.226061828709291, -7.091903033581025],
              [-40.225535375005506, -7.092419941079973],
              [-40.22426507309391, -7.092224433251016],
              [-40.222556093497431, -7.090465371418914],
              [-40.221770266310145, -7.08883029257125],
              [-40.221148468464406, -7.087837059017923],
              [-40.219244936148243, -7.087462246764678],
              [-40.217337584631835, -7.087376891779048],
              [-40.216055851977195, -7.088230449673693],
              [-40.215800249367092, -7.090021921768773],
              [-40.217219330001392, -7.091781448368889],
              [-40.217497819445477, -7.092540571051915],
              [-40.217257468545156, -7.093292079373357],
              [-40.215132676198714, -7.092393235076371],
              [-40.213927241287763, -7.091618373760443],
              [-40.212206807048183, -7.090781998408379],
              [-40.210936505181024, -7.090532123905091],
              [-40.209326735794143, -7.090165438833695],
              [-40.20816702792218, -7.090204554690418],
              [-40.207858037865456, -7.091769993093194],
              [-40.208586659546334, -7.093287306597873],
              [-40.210833523217687, -7.093950618397442],
              [-40.212794262221493, -7.094208083397807],
              [-40.214861846969555, -7.09510692765016],
              [-40.215838409724775, -7.095583765567885],
              [-40.216799698811876, -7.097046223585413],
              [-40.217410064910993, -7.099088523864107],
              [-40.217795354055006, -7.100543834473177],
              [-40.218817690854728, -7.101825079622957],
              [-40.219904871646555, -7.102537006224789],
              [-40.220995870639996, -7.102959013561486],
              [-40.221850382932992, -7.103843071952773],
              [-40.222243285393589, -7.104827738380594],
              [-40.222979520289904, -7.106001717947881],
              [-40.223654729572559, -7.107230041143049],
              [-40.225371345525573, -7.10846407012362],
              [-40.227614392176122, -7.109190804548342],
              [-40.229514105376396, -7.109746805722849],
              [-40.23095225465142, -7.110520712233456],
              [-40.23261545930162, -7.111348007602729],
              [-40.234320619686308, -7.113405581660483],
              [-40.2342557766856, -7.113984446153566],
              [-40.233035066875523, -7.114485126203413],
              [-40.231009462003072, -7.114635347711722],
              [-40.228934262850927, -7.114315368988372],
              [-40.227141372571701, -7.114175181413962],
              [-40.225295046718372, -7.113972059173359],
              [-40.223856896216262, -7.113487583934232],
              [-40.221674898273136, -7.112589228581391],
              [-40.220583922323314, -7.112112391570411],
              [-40.219901052774368, -7.111291265936734],
              [-40.21933266632756, -7.110587464692688],
              [-40.218409515012027, -7.110345693410384],
              [-40.217543547116051, -7.110329976823024],
              [-40.217017139554414, -7.110665672404044],
              [-40.215678175343136, -7.111465307574959],
              [-40.214869460137571, -7.111331801736084],
              [-40.213210075649229, -7.110268928778897],
              [-40.212527252007789, -7.109565127445579],
              [-40.211836791149075, -7.109205101053026],
              [-40.211436229108784, -7.109025331811814],
              [-40.210970846388854, -7.109017695491429],
              [-40.210162132991229, -7.109128336194549],
              [-40.209349598442358, -7.109464985544689],
              [-40.208002997929739, -7.11071668600874],
              [-40.207472772117391, -7.11140523647875],
              [-40.207697826306145, -7.111694203929698],
              [-40.20821282539859, -7.112054696181549],
              [-40.209532696459419, -7.11288296827266],
              [-40.210501645945229, -7.113767004754934],
              [-40.210440598196428, -7.113993037356857],
              [-40.208884193385714, -7.113689750943553],
              [-40.2073316066055, -7.113314008966242],
              [-40.206690739892217, -7.113595827778647],
              [-40.205912549775071, -7.115614752107167],
              [-40.205264044708557, -7.116412499831821],
              [-40.202383950459748, -7.115795964247628],
              [-40.201819382258549, -7.114748340061203],
              [-40.200381231810084, -7.114200466202397],
              [-40.199748024364602, -7.114075063453293],
              [-40.199110929717847, -7.114239557969389],
              [-40.198935468859247, -7.114357813124906],
              [-40.19887442019597, -7.114475602373202],
              [-40.198813395397089, -7.114765034596189],
              [-40.198340376232586, -7.115390162489565],
              [-40.19844335677336, -7.116321440007396],
              [-40.199122385119132, -7.11743199690786],
              [-40.200198134302582, -7.119012221315622],
              [-40.200762725595951, -7.119942055206221],
              [-40.200816113756176, -7.120529999736496],
              [-40.200404119297673, -7.120982995706358],
              [-40.199591585842008, -7.121202345756274],
              [-40.198142003875311, -7.12141405935009],
              [-40.197447725538019, -7.121696343117202],
              [-40.197077710820032, -7.123370537825752],
              [-40.197173078049424, -7.124762890584615],
              [-40.198134367036666, -7.126343138903984],
              [-40.198641729731399, -7.127273437747829],
              [-40.200247749862001, -7.127992512719449],
              [-40.202147440876558, -7.128774522547106],
              [-40.204443897914587, -7.129790666289982],
              [-40.207076027762525, -7.131854900040455],
              [-40.209139795345273, -7.132988833898429],
              [-40.209879847402988, -7.133918667931077],
              [-40.211073850931818, -7.135271927794448],
              [-40.212035162968178, -7.136788752498295],
              [-40.212817148986304, -7.138658896952142],
              [-40.213149026728438, -7.139824775254951],
              [-40.213019315774524, -7.140919127620375],
              [-40.212378473016642, -7.141255755104834],
              [-40.211043327099901, -7.141647725710396],
              [-40.20919318214176, -7.141616735433828],
              [-40.206488572746331, -7.140890977629791],
              [-40.20494362369481, -7.139529638536636],
              [-40.202826445761922, -7.138051882687424],
              [-40.201506573612001, -7.137340932890217],
              [-40.201281495424446, -7.137051967437842],
              [-40.20053382902028, -7.136629006373156],
              [-40.199912031088779, -7.135753050112659],
              [-40.199397032083525, -7.135284313505124],
              [-40.198542542745642, -7.134463677731054],
              [-40.197913130595722, -7.133931542412067],
              [-40.197104393299746, -7.133915314870925],
              [-40.1962194025241, -7.135184196595524],
              [-40.19579595340867, -7.136568958992373],
              [-40.195490781553993, -7.137835885186324],
              [-40.195132199439819, -7.138641737091358],
              [-40.19396490102266, -7.13944044040061],
              [-40.192812853514567, -7.13895501138974],
              [-40.191904975875147, -7.13772764300237],
              [-40.191115329361246, -7.136553662389892],
              [-40.190668993560038, -7.135216607022591],
              [-40.190455371071764, -7.133941554468962],
              [-40.190188361144166, -7.132432366271809],
              [-40.189730591736847, -7.131963142713258],
              [-40.188582340244956, -7.131423371031689],
              [-40.186743674753735, -7.130704784874065],
              [-40.186285905303478, -7.130289948991003],
              [-40.186247767672441, -7.128897084280958],
              [-40.187430315845248, -7.127003562523827],
              [-40.188658661916747, -7.126096126912278],
              [-40.190112062176283, -7.1254776817517],
              [-40.190810135529233, -7.125141031312668],
              [-40.191286972995776, -7.124099624909027],
              [-40.19171803655528, -7.122081632303196],
              [-40.191843928176304, -7.121439345239152],
              [-40.191443389315403, -7.121033543347955],
              [-40.190230316914352, -7.120955312860062],
              [-40.187968201402022, -7.121503698802804],
              [-40.186636851555377, -7.121778370113485],
              [-40.185191088656865, -7.121872294413949],
              [-40.183981857500576, -7.121450775980144],
              [-40.183016703262723, -7.120222918556429],
              [-40.182970929319659, -7.11929166408456],
              [-40.183844510479446, -7.11890064810845],
              [-40.185873934677957, -7.11828935035818],
              [-40.186861928990268, -7.117952212150034],
              [-40.188025408246325, -7.117388597433129],
              [-40.189246117957111, -7.117005706681434],
              [-40.18994421440253, -7.116551267945889],
              [-40.190024308165555, -7.114868993059948],
              [-40.189989989683454, -7.11325011970555],
              [-40.189658111845276, -7.112202030706967],
              [-40.189433034706695, -7.11185012963262],
              [-40.18897147105389, -7.111724727018946],
              [-40.187701168006043, -7.111709454111312],
              [-40.18625538110706, -7.111803866410264],
              [-40.184924054538683, -7.111725681830664],
              [-40.183546952748536, -7.111069074671682],
              [-40.182696258703501, -7.109841217340746],
              [-40.182471203564845, -7.109489269267145],
              [-40.182711509465399, -7.108737807947013],
              [-40.183760574618105, -7.108056870841039],
              [-40.184409078639334, -7.107313487793401],
              [-40.184534970294763, -7.106625891001645],
              [-40.184138226684567, -7.10586723422409],
              [-40.181792199501935, -7.104209268290558],
              [-40.180251045371378, -7.102910839841198],
              [-40.178706095175187, -7.10172971168841],
              [-40.177279378374173, -7.100259616356507],
              [-40.175738270243684, -7.098960721912035],
              [-40.173964424750295, -7.097663248277186],
              [-40.172030368067418, -7.095497454709299],
              [-40.170260341867248, -7.09379275851443],
              [-40.169226572384346, -7.093487587528688],
              [-40.168414039024555, -7.093589147284307],
              [-40.167479432111627, -7.094215695804811],
              [-40.166949183075936, -7.095193702540589],
              [-40.166590600016811, -7.095936130819808],
              [-40.166567713990439, -7.09744676176873],
              [-40.166323566983387, -7.098369913057147],
              [-40.165343208897603, -7.098354173382137],
              [-40.164996058340854, -7.098355128104096],
              [-40.164183524803676, -7.098682721507698],
              [-40.163592251240189, -7.099606361520703],
              [-40.162531775672818, -7.100920552830186],
              [-40.16153993916312, -7.101547078305387],
              [-40.159918691410972, -7.101759257764256],
              [-40.159190093734601, -7.10024150126854],
              [-40.159087089192177, -7.099310247756707],
              [-40.159090931349937, -7.099075158165626],
              [-40.158923059851666, -7.09866842546628],
              [-40.158526339106047, -7.098081924662602],
              [-40.158297442944878, -7.097792935211294],
              [-40.158301262010546, -7.097675168918582],
              [-40.158308898411832, -7.097096281386644],
              [-40.158373742452987, -7.096463051224572],
              [-40.158045683679084, -7.095297172940143],
              [-40.156557939989767, -7.094233833133545],
              [-40.155409711633531, -7.093522395488697],
              [-40.154665840331646, -7.09293635941098],
              [-40.154265278293707, -7.092756101175204],
              [-40.153857124996115, -7.09297449754897],
              [-40.153834237878385, -7.094602428793642],
              [-40.153998267084205, -7.095415916056488],
              [-40.153704574881715, -7.095760179767105],
              [-40.151904024172886, -7.096253246406127],
              [-40.150740520880255, -7.096871226797427],
              [-40.149756297638575, -7.097090552713715],
              [-40.148486042645104, -7.097012368185353],
              [-40.148047342147727, -7.095213725600462],
              [-40.147772717905369, -7.094165171657692],
              [-40.146506211113049, -7.09379753086431],
              [-40.145586856004591, -7.09326632732165],
              [-40.144514925021674, -7.091396647667334],
              [-40.143664253933821, -7.090223133021989],
              [-40.14274871679843, -7.089691952482974],
              [-40.141482232956953, -7.089387246314199],
              [-40.139803778710544, -7.089473067486161],
              [-40.137663736757709, -7.08950503461891],
              [-40.135531309120395, -7.089067290568381],
              [-40.133856648933659, -7.089044403394044],
              [-40.132010346233194, -7.088668660196991],
              [-40.130923142492044, -7.087902855928826],
              [-40.129614702862526, -7.086431805708732],
              [-40.129458309779857, -7.085391819810797],
              [-40.129519334626686, -7.085038964970481],
              [-40.130507353016483, -7.08459362840907],
              [-40.131842498777729, -7.084382357718311],
              [-40.133341674064837, -7.08457786572131],
              [-40.135184156750555, -7.085007485592034],
              [-40.136164538741149, -7.085140525563096],
              [-40.136649013507245, -7.083638019934154],
              [-40.136481165008405, -7.083231264237132],
              [-40.136023396422843, -7.082988562331457],
              [-40.135271911771021, -7.082917990165893],
              [-40.13389099178557, -7.082550350285832],
              [-40.132407067432972, -7.081025400252328],
              [-40.131522053812212, -7.078287424948955],
              [-40.131190153056309, -7.077130603614259],
              [-40.130621789519061, -7.076544101680899],
              [-40.129706252433579, -7.075949474524955],
              [-40.128908992834241, -7.074947160902283],
              [-40.127379293213565, -7.072898201973991],
              [-40.126425618571879, -7.070802023779507],
              [-40.1256321540301, -7.069691465809416],
              [-40.125239251575287, -7.068697720453019],
              [-40.123988018448145, -7.067343994582405],
              [-40.123301376651646, -7.066875258853401],
              [-40.12237822639333, -7.066570087960405],
              [-40.121687765397454, -7.066390783495386],
              [-40.120531900417802, -7.066547153656948],
              [-40.11942183257073, -7.067400246681762],
              [-40.118944994122579, -7.068432596127251],
              [-40.118239261513253, -7.069175512127416],
              [-40.117827290011228, -7.069692420710253],
              [-40.117522095304786, -7.070787238915684],
              [-40.117396226511758, -7.0716560246148],
              [-40.117613667294002, -7.07253244753633],
              [-40.118582592733389, -7.073470872673757],
              [-40.120188613721162, -7.074362568589009],
              [-40.122317177021984, -7.075261878867381],
              [-40.124037611155259, -7.076216019499187],
              [-40.125006537637368, -7.07710007896365],
              [-40.125105723031758, -7.078257854109924],
              [-40.124914987290879, -7.079642104673885],
              [-40.124899737533667, -7.080745514056545],
              [-40.124594565867987, -7.081785965589427],
              [-40.122225628629387, -7.081692507296229],
              [-40.121481757194502, -7.081278138195175],
              [-40.120673041897348, -7.081270989605558],
              [-40.119742253105628, -7.081716326211563],
              [-40.118521544398391, -7.082108320910129],
              [-40.117125373842235, -7.082609000820696],
              [-40.115965713620426, -7.083109189919106],
              [-40.113886696469571, -7.08285219183183],
              [-40.11325726127879, -7.082374400200291],
              [-40.113085595530578, -7.082320499388776],
              [-40.112448546024993, -7.08231288593622],
              [-40.111639855735625, -7.08229665740103],
              [-40.11112103834779, -7.082351978664074],
              [-40.109278530851057, -7.081687268203561],
              [-40.107508504432118, -7.080271539297349],
              [-40.105742295397199, -7.078331289536227],
              [-40.103915061431032, -7.076852136967069],
              [-40.101965755140455, -7.07560900488986],
              [-40.099795188777151, -7.073895741456762],
              [-40.099566269524715, -7.073724075306274],
              [-40.099337420370226, -7.073426516909993],
              [-40.098311264378424, -7.072433749055706],
              [-40.096720564149074, -7.070438666765693],
              [-40.094229529450679, -7.0669262718653],
              [-40.093153780358826, -7.065228700197537],
              [-40.091849136879929, -7.063531176351884],
              [-40.090140181256253, -7.061826016237741],
              [-40.088949996019799, -7.060065044863316],
              [-40.087874247877878, -7.058430454821492],
              [-40.086970165391811, -7.056967507885332],
              [-40.08602030794804, -7.054581898444526],
              [-40.08529550551539, -7.052711707090467],
              [-40.085078064682364, -7.051898241797408],
              [-40.084624114515876, -7.051085241317685],
              [-40.083605595884784, -7.049558895675299],
              [-40.081961461162237, -7.047392123386505],
              [-40.080771275824688, -7.04575799925841],
              [-40.079394174226564, -7.044865836537097],
              [-40.078600709593189, -7.043872602875791],
              [-40.07740670619792, -7.04234672209755],
              [-40.075297165493872, -7.040515645439032],
              [-40.074316807550538, -7.04032826380118],
              [-40.073565298945461, -7.040203325966699],
              [-40.072520075051784, -7.040539976135867],
              [-40.071104812329679, -7.042722929944771],
              [-40.070719524066078, -7.045265444397906],
              [-40.071520624912615, -7.045914880126132],
              [-40.072947318800693, -7.047268164120315],
              [-40.073744602448627, -7.048207055110439],
              [-40.074183279222055, -7.049662365725548],
              [-40.074446493872699, -7.051578773439988],
              [-40.075583243810442, -7.052923931259674],
              [-40.076788725934037, -7.053409361287549],
              [-40.078570185730221, -7.054237610708765],
              [-40.079603978063979, -7.054723505600643],
              [-40.080164727110251, -7.056006684345158],
              [-40.080771275290246, -7.057986050952624],
              [-40.081316774723661, -7.060191425158679],
              [-40.082255176518963, -7.063508290010282],
              [-40.082911316845909, -7.066129942810197],
              [-40.083407223079263, -7.067756477449638],
              [-40.08368189418907, -7.068977163348962],
              [-40.083956541617574, -7.069790162691489],
              [-40.0853870547447, -7.070800113755862],
              [-40.086596307848708, -7.071276462845299],
              [-40.08900720201158, -7.072356030258571],
              [-40.090727636053948, -7.073419369230854],
              [-40.091872046473661, -7.074076930184908],
              [-40.093012638467442, -7.075313380668351],
              [-40.093809922205729, -7.076134481372246],
              [-40.094897101943793, -7.076900775631153],
              [-40.095816458183137, -7.077260313195637],
              [-40.099364126056216, -7.079974470710826],
              [-40.101420255224454, -7.081687734057214],
              [-40.102446410300573, -7.082571793565098],
              [-40.103934130848707, -7.083807288329748],
              [-40.105303618229691, -7.085043247999797],
              [-40.106161926715387, -7.0856378751106],
              [-40.107134670437432, -7.08617813563666],
              [-40.109442559832146, -7.086615858821408],
              [-40.11105998933202, -7.08674794329613],
              [-40.113772260099466, -7.08690387048486],
              [-40.115156998281812, -7.086982078100229],
              [-40.117297040179999, -7.087013068589363],
              [-40.118105755469671, -7.087029273125252],
              [-40.11902890677591, -7.087271511397606],
              [-40.119826166607467, -7.087974846811374],
              [-40.119986399748079, -7.08890612436233],
              [-40.119856713888034, -7.089892210358275],
              [-40.119498106690074, -7.090815384551709],
              [-40.119540086578219, -7.091855348359839],
              [-40.120226728365701, -7.09233314003775],
              [-40.121435981607355, -7.092637846161109],
              [-40.122530799655287, -7.092942552194945],
              [-40.124369488137688, -7.093661627331729],
              [-40.125052334547426, -7.094654884922433],
              [-40.126486642835339, -7.095429256412161],
              [-40.128252852054203, -7.097134441592472],
              [-40.129061566252581, -7.097267946427526],
              [-40.130156383443151, -7.097391929543837],
              [-40.13130079373046, -7.098221133479489],
              [-40.132159102174647, -7.098870150269153],
              [-40.132437590616426, -7.099629248945746],
              [-40.13231169891295, -7.10038026835365],
              [-40.13160978340774, -7.100951985382542],
              [-40.13097273585835, -7.100998714603328],
              [-40.129591814917376, -7.100576731045105],
              [-40.12873732653884, -7.099810437960048],
              [-40.127936224742193, -7.099097579597332],
              [-40.127306812501608, -7.098683209583298],
              [-40.126906273549842, -7.098394708995627],
              [-40.126215813604453, -7.098152005906699],
              [-40.12598691826598, -7.09808953810106],
              [-40.12556349134551, -7.099239186889683],
              [-40.12502942328944, -7.100280617239145],
              [-40.124956966939799, -7.101374945634155],
              [-40.124895918142386, -7.101664401854267],
              [-40.12408338564417, -7.101947151525157],
              [-40.122992386754781, -7.101406890902321],
              [-40.121958593547468, -7.100758409972124],
              [-40.120749316313486, -7.100444645901538],
              [-40.119128092611327, -7.100602481685829],
              [-40.118605479979678, -7.101001577887683],
              [-40.118700847170722, -7.102448320304704],
              [-40.119261597396552, -7.103495919475555],
              [-40.120459419077974, -7.104614579721246],
              [-40.121779314127025, -7.105443806795063],
              [-40.122923724412452, -7.106273034731507],
              [-40.123263238558856, -7.106860025485868],
              [-40.123820169490834, -7.10831484621879],
              [-40.12398419888018, -7.108893244887057],
              [-40.123171666290006, -7.109292852858401],
              [-40.122073030041946, -7.109277579087224],
              [-40.120512830009538, -7.109308568676964],
              [-40.118300308888905, -7.110209321550526],
              [-40.117087212312867, -7.110365738662208],
              [-40.115759703770088, -7.110233652410296],
              [-40.113863831836639, -7.109333365309048],
              [-40.113058912790997, -7.108973827828702],
              [-40.112253992653137, -7.10873161364634],
              [-40.111330841348469, -7.108489376371401],
              [-40.110007151829457, -7.108293868502551],
              [-40.108733030684171, -7.108450749563777],
              [-40.10790524761255, -7.109600888031119],
              [-40.106959184925259, -7.111321787989821],
              [-40.10677608780955, -7.111837718758586],
              [-40.1067188571202, -7.111955508008752],
              [-40.106943934349445, -7.112190108781589],
              [-40.107226218855494, -7.112831464165228],
              [-40.108363015921498, -7.114004467038729],
              [-40.109679068809825, -7.115068784703825],
              [-40.111353728991297, -7.115091671882404],
              [-40.112395134598017, -7.115161753281164],
              [-40.113318308945551, -7.11534915888513],
              [-40.114058362084066, -7.116170750550912],
              [-40.114844189352034, -7.117688040090749],
              [-40.115404939475731, -7.118862509505141],
              [-40.116438731773187, -7.119393690140011],
              [-40.11822782787187, -7.119768967301554],
              [-40.119727002247998, -7.119846686015277],
              [-40.120882845092083, -7.119862425829854],
              [-40.122561322248664, -7.119885313012645],
              [-40.12411009072742, -7.120659219601356],
              [-40.125189657104293, -7.121950034729866],
              [-40.125227818780175, -7.123288510754553],
              [-40.123835466237473, -7.123734823989888],
              [-40.122095940239831, -7.124055734702028],
              [-40.120650153351974, -7.12414968300272],
              [-40.118975494304678, -7.123954662851819],
              [-40.117190217136383, -7.12363375236702],
              [-40.11500058204539, -7.123023875292954],
              [-40.112925384005983, -7.122594720228195],
              [-40.111372797285583, -7.122164122583318],
              [-40.110045288744033, -7.122032038329658],
              [-40.108774986807497, -7.121889940181008],
              [-40.108023525015746, -7.12200058192818],
              [-40.107149919999451, -7.122219443931895],
              [-40.106036033948619, -7.123361945220483],
              [-40.105273093603877, -7.124276994145848],
              [-40.105311254329344, -7.125552535544561],
              [-40.106402253122418, -7.12621009646886],
              [-40.107786992217079, -7.126396547442823],
              [-40.108134118764482, -7.126404649667439],
              [-40.108694892843658, -7.127633462757665],
              [-40.109248005477397, -7.129495039057158],
              [-40.110167361764915, -7.129791619006739],
              [-40.111437663844711, -7.129752039243462],
              [-40.112830016335529, -7.129369170629526],
              [-40.113642548783147, -7.129149821581111],
              [-40.114977694499103, -7.128992916570582],
              [-40.115500306946124, -7.128828886963039],
              [-40.11698804975984, -7.129720582800861],
              [-40.117037642860602, -7.130417237673345],
              [-40.116858339716821, -7.130933166446497],
              [-40.116217471967062, -7.131269351931238],
              [-40.115809295799501, -7.13132467227897],
              [-40.115691041496852, -7.131496804156399],
              [-40.115858889993525, -7.131903070858777],
              [-40.117350450822471, -7.132740867022889],
              [-40.11971936510772, -7.13277090170901],
              [-40.122031072746132, -7.132864848967998],
              [-40.12318693954758, -7.132934466462157],
              [-40.12358747858373, -7.133114234702692],
              [-40.124514447997576, -7.133184341013692],
              [-40.125449053950433, -7.132503891818697],
              [-40.126429435031163, -7.132519607496982],
              [-40.12723433212652, -7.132816211357507],
              [-40.128485565150079, -7.134287260547556],
              [-40.129267598034758, -7.136320481007433],
              [-40.129710068718033, -7.137838748279142],
              [-40.131068102581146, -7.139825262474004],
              [-40.133307329003635, -7.140841383165704],
              [-40.135256660374694, -7.14197627191955],
              [-40.137614142921905, -7.143046758381066],
              [-40.13899506190414, -7.14341439927338],
              [-40.141062624536737, -7.144431032829011],
              [-40.144045723257463, -7.146033678606156],
              [-40.145190134447986, -7.146980205853514],
              [-40.146334543824324, -7.147692133503072],
              [-40.147650620763294, -7.148692537564752],
              [-40.149474036427996, -7.150578422737229],
              [-40.150103447574146, -7.151110093057954],
              [-40.151469140891372, -7.152454295106399],
              [-40.152438067087708, -7.153691187482918],
              [-40.152888175316676, -7.154449866306497],
              [-40.153105638911512, -7.155552321892697],
              [-40.15325441748746, -7.157288496639294],
              [-40.152949199854746, -7.158275070504796],
              [-40.151736150409391, -7.158260285633657],
              [-40.149386305087113, -7.156836920278039],
              [-40.14446151245069, -7.153457563574456],
              [-40.142176510130533, -7.151455799729663],
              [-40.140169974019464, -7.150501658846035],
              [-40.13809097991733, -7.150181213118878],
              [-40.13652693876648, -7.15039341461549],
              [-40.13408933932417, -7.151168277059459],
              [-40.132124781181318, -7.151145855641706],
              [-40.129946578517149, -7.149894155402545],
              [-40.12833296731867, -7.149346281403013],
              [-40.126845224548759, -7.14840021888332],
              [-40.125662676774041, -7.146006506258619],
              [-40.12465558962009, -7.14379302869629],
              [-40.123396766280486, -7.142792134686984],
              [-40.121672466997673, -7.142073059626107],
              [-40.119887189873353, -7.141697339464556],
              [-40.117285584277795, -7.141604323969117],
              [-40.115607128987008, -7.141753102755965],
              [-40.114207117227508, -7.142543214927443],
              [-40.112986454340479, -7.143161195269543],
              [-40.113200053777398, -7.144499204437341],
              [-40.114748821344683, -7.145155811733079],
              [-40.117045256123376, -7.146470468684707],
              [-40.118189666410878, -7.14729061568443],
              [-40.11818587134443, -7.147408404977282],
              [-40.118529179647027, -7.147760306144374],
              [-40.118979288782434, -7.148636262262913],
              [-40.119715546715533, -7.149747286202284],
              [-40.120741701599897, -7.150865945325244],
              [-40.122210376492994, -7.153087548205417],
              [-40.123343332030139, -7.154956739928067],
              [-40.123892649548765, -7.156990472206918],
              [-40.124037609915085, -7.159025135161364],
              [-40.124712842184088, -7.160253458354482],
              [-40.125223975938702, -7.16106599190293],
              [-40.125830524138891, -7.162990991879272],
              [-40.126326430261592, -7.16473526984865],
              [-40.126601101401498, -7.165901124099703],
              [-40.126578213371346, -7.167402698109538],
              [-40.126646875080809, -7.170938888035982],
              [-40.126616350730181, -7.172910641231757],
              [-40.126650694152453, -7.174592448211577],
              [-40.126849042678472, -7.177205999755683],
              [-40.126932978477214, -7.179231605722131],
              [-40.126898635598103, -7.181899523758768],
              [-40.126704081774385, -7.183401562632715],
              [-40.126227220450026, -7.184261780116434],
              [-40.124834892904794, -7.184708560343217],
              [-40.123450153910966, -7.184395285115762],
              [-40.122839787598515, -7.182651497047747],
              [-40.121439797970133, -7.179498150774864],
              [-40.121458867935466, -7.178114390056664],
              [-40.121718240811298, -7.17597050507045],
              [-40.122034891046326, -7.174124668349315],
              [-40.122176033431323, -7.172387562839901],
              [-40.122134077789248, -7.171049552801168],
              [-40.122084484641427, -7.170416298545638],
              [-40.121580941114395, -7.169250910110361],
              [-40.120562422408867, -7.167842328721048],
              [-40.118571112344888, -7.165441468492456],
              [-40.116751538984595, -7.163148828754756],
              [-40.115267615377633, -7.161967699640597],
              [-40.113886694536134, -7.161428392772103],
              [-40.111922114491563, -7.161288206056716],
              [-40.110609856582265, -7.160224378378364],
              [-40.110228385653059, -7.158479635506614],
              [-40.110434395035888, -7.156163618514708],
              [-40.110865458502907, -7.154263415215258],
              [-40.111243109539203, -7.152119088333394],
              [-40.111372797398651, -7.151142036277305],
              [-40.110869229919722, -7.149913224226667],
              [-40.109541744379491, -7.14978067297136],
              [-40.108435471822816, -7.150226520432325],
              [-40.107790808913897, -7.15079777150782],
              [-40.107455113744194, -7.150210781752572],
              [-40.10634120403197, -7.14712947329837],
              [-40.10629922520048, -7.146026040863706],
              [-40.10602460100759, -7.144923609239656],
              [-40.10551723923313, -7.144101528755748],
              [-40.104372828953977, -7.143272301812384],
              [-40.102080189236801, -7.141848959517433],
              [-40.100363574318401, -7.140604872605324],
              [-40.099791369764546, -7.140081795283074],
              [-40.09911615949418, -7.138852983101195],
              [-40.098727076156941, -7.137687593765762],
              [-40.098463860390176, -7.135942361998375],
              [-40.098143392204648, -7.133971587535755],
              [-40.097632258363092, -7.133267297346707],
              [-40.09689220518424, -7.132500538351322],
              [-40.096373364983037, -7.132320769018352],
              [-40.094977217336016, -7.132938750221958],
              [-40.094843735177186, -7.134332103780725],
              [-40.094427899564757, -7.135020654333827],
              [-40.093672596623257, -7.135356792728053],
              [-40.092394657328875, -7.135739683430886],
              [-40.091631717069767, -7.136546513002894],
              [-40.091151061457204, -7.137813952041498],
              [-40.090948895103296, -7.140066521406116],
              [-40.090922187869879, -7.141866118619314],
              [-40.090494943651365, -7.143422989969139],
              [-40.089613748910793, -7.144627960066227],
              [-40.088438814197531, -7.14588776160369],
              [-40.087088417499089, -7.147428427322525],
              [-40.086211041096114, -7.148172299180699],
              [-40.084517313392894, -7.145191573676263],
              [-40.084540177511059, -7.143572723379175],
              [-40.084849190383714, -7.142242349582041],
              [-40.085329846039414, -7.140920545868997],
              [-40.085688429240626, -7.139997393674759],
              [-40.08485300902106, -7.137602703344549],
              [-40.083258443509017, -7.136023410538664],
              [-40.082407772482398, -7.134786496270491],
              [-40.081850840649551, -7.133213885242871],
              [-40.08198052732741, -7.132463354836368],
              [-40.083147825721056, -7.131673708480568],
              [-40.084883509602356, -7.13152492973679],
              [-40.086096582081623, -7.131485837925367],
              [-40.086344547340119, -7.130209806756429],
              [-40.086130924851176, -7.128935220215862],
              [-40.085223024041284, -7.127933418496943],
              [-40.083868809524802, -7.125476237137843],
              [-40.082735830799635, -7.12384211202774],
              [-40.082636669367979, -7.122738725554114],
              [-40.082766355315762, -7.121644373206444],
              [-40.08404429457272, -7.12130676923146],
              [-40.085314573599156, -7.121330633086814],
              [-40.086760360395772, -7.121345395134683],
              [-40.088267148076177, -7.12096201561338],
              [-40.089838802613258, -7.120180472526105],
              [-40.091002305855802, -7.119616880813481],
              [-40.091421913760726, -7.118521573698684],
              [-40.091269315889789, -7.117183564587095],
              [-40.090288958947596, -7.116996158938753],
              [-40.087168513115692, -7.116777297797999],
              [-40.08417779886878, -7.115925182550185],
              [-40.081427391747596, -7.114032126723702],
              [-40.078448111111577, -7.112302635739974],
              [-40.077647033262885, -7.111662256945577],
              [-40.076727676077077, -7.1112392957568],
              [-40.076208882833299, -7.11111389310061],
              [-40.075518423799465, -7.110988956306983],
              [-40.075175114361649, -7.110808721116862],
              [-40.074717344908187, -7.110402920187688],
              [-40.073969655512585, -7.109979494136642],
              [-40.073740783168667, -7.109925593279875],
              [-40.072584917422724, -7.109792577169802],
              [-40.071776202137471, -7.109776348632597],
              [-40.070505900105658, -7.109761563724793],
              [-40.069517905857118, -7.110034791313607],
              [-40.069384376795789, -7.11131033257021],
              [-40.069376763351556, -7.111943585772032],
              [-40.068903744052669, -7.112749926572205],
              [-40.067972956216018, -7.113258219798],
              [-40.065501013445584, -7.112242074943743],
              [-40.064287942061405, -7.112163402463181],
              [-40.06301002585721, -7.11243758381859],
              [-40.062304292163837, -7.113289698161544],
              [-40.062014393980121, -7.11357962019775],
              [-40.061987688845285, -7.115261893101804],
              [-40.063593662641019, -7.116379598668801],
              [-40.065787116006746, -7.116591801119914],
              [-40.067797471265145, -7.117310876392319],
              [-40.069063954910504, -7.117850182157464],
              [-40.069178392053644, -7.117913605867581],
              [-40.069174572988281, -7.118030906162215],
              [-40.069346238642773, -7.118202549269313],
              [-40.069742936383612, -7.118789539066218],
              [-40.070082473669693, -7.119195782900466],
              [-40.070532605756256, -7.120135186629517],
              [-40.070631791145729, -7.12129247277859],
              [-40.069536973970052, -7.12115943271505],
              [-40.06775551519047, -7.12032256832626],
              [-40.066202928562866, -7.119783285335846],
              [-40.065336983681497, -7.11976754575001],
              [-40.063944632333602, -7.119978770391732],
              [-40.061804567357058, -7.120065080194135],
              [-40.060198593384669, -7.119172918275589],
              [-40.059344103014126, -7.118406159196466],
              [-40.058317924989062, -7.11746822400792],
              [-40.056021490169627, -7.116216057704814],
              [-40.055098338692588, -7.11620031807458],
              [-40.053129940415452, -7.116403440313126],
              [-40.05120733912517, -7.117484451329302],
              [-40.050734319877321, -7.118226902510925],
              [-40.051993143346202, -7.119056106532628],
              [-40.054747392793871, -7.1204880411191],
              [-40.056807318059093, -7.122021070556071],
              [-40.057608396033714, -7.122498397326935],
              [-40.058119575781333, -7.123320453805834],
              [-40.058855810714476, -7.124431011734184],
              [-40.059290691273183, -7.126175753628723],
              [-40.059386059325455, -7.127794162013362],
              [-40.059256372420137, -7.128834613681764],
              [-40.058211124618808, -7.129053963546625],
              [-40.057123943939665, -7.1282247356569],
              [-40.055922304110659, -7.127341165002863],
              [-40.055071633892744, -7.12634790828193],
              [-40.054102683608178, -7.125228760222172],
              [-40.053248194416717, -7.124235992495943],
              [-40.052340293609078, -7.123234143782491],
              [-40.050955555622814, -7.1229204035737],
              [-40.050612246093458, -7.122857934678168],
              [-40.050493991969951, -7.122804033908095],
              [-40.049570817401445, -7.122905618574591],
              [-40.048586641312824, -7.122944244570196],
              [-40.04789999961779, -7.122357720543795],
              [-40.046759383494241, -7.121301994971373],
              [-40.046416075057685, -7.121121759781345],
              [-40.045775207263667, -7.121521344884684],
              [-40.044138685822233, -7.122773045102115],
              [-40.043196466527149, -7.123968957198825],
              [-40.043009549161617, -7.124837765848096],
              [-40.041670584934785, -7.125691301683802],
              [-40.041659106381886, -7.12649619885372],
              [-40.041823159719122, -7.127138020138422],
              [-40.042845520533163, -7.128374445536497],
              [-40.043291833516129, -7.129476924284487],
              [-40.043333789307852, -7.130634676383729],
              [-40.042628079700208, -7.131378059376835],
              [-40.040190502371175, -7.132043723473566],
              [-40.039137618126858, -7.132896303539434],
              [-40.038885858761617, -7.134343999671274],
              [-40.039221576616832, -7.135328665038368],
              [-40.040362145730853, -7.136393448559216],
              [-40.04186132090782, -7.136706722862826],
              [-40.043410088464313, -7.137363329149714],
              [-40.044485836628681, -7.138944041531336],
              [-40.044302715235247, -7.139812385187899],
              [-40.042784471093022, -7.140892418525766],
              [-40.040629156501787, -7.141900925790144],
              [-40.038595936320981, -7.142565635209004],
              [-40.037138717884908, -7.143473536625041],
              [-40.036360527831143, -7.14542903730293],
              [-40.036856434040367, -7.14706507089774],
              [-40.038054231570584, -7.148355397116012],
              [-40.039023181993215, -7.149293822261311],
              [-40.039519087251925, -7.150866433237301],
              [-40.040076042122621, -7.152384676587387],
              [-40.040468944708017, -7.153197700030174],
              [-40.041147972903083, -7.154480854884572],
              [-40.041422619322574, -7.155293877234554],
              [-40.041479850732941, -7.155528943872619],
              [-40.040495650638533, -7.155576626810718],
              [-40.039454243998037, -7.155560887083372],
              [-40.038126713383079, -7.155536580181606],
              [-40.037142536331913, -7.155529897445235],
              [-40.037073851179898, -7.156334817572578],
              [-40.037394296538999, -7.158079560381164],
              [-40.038290765641356, -7.160067494189322],
              [-40.039362694555933, -7.16199154051932],
              [-40.040049336197093, -7.162641464171648],
              [-40.041178473751089, -7.16451067887628],
              [-40.041968119114117, -7.165856790444145],
              [-40.043909812108232, -7.167507586099616],
              [-40.04545476227338, -7.168688715252944],
              [-40.04681661313456, -7.170621351765544],
              [-40.047377362305255, -7.171723317615642],
              [-40.047373567009537, -7.172130073178972],
              [-40.047125602933392, -7.173170990755021],
              [-40.046660220129446, -7.173280676730641],
              [-40.043181192071224, -7.174039310439835],
              [-40.041907093935826, -7.174195726496174],
              [-40.040701635868452, -7.173655954772456],
              [-40.03887822017829, -7.171832980238896],
              [-40.037405751370848, -7.169557034694755],
              [-40.036833546643436, -7.169259966020335],
              [-40.036719086500867, -7.169197031306492],
              [-40.036143085800205, -7.168899962629132],
              [-40.035513650433913, -7.168656769587145],
              [-40.035052063876186, -7.16842265862351],
              [-40.033900039202891, -7.168172318211417],
              [-40.032515301128676, -7.167976321290318],
              [-40.031012308752743, -7.167952456245568],
              [-40.030588881779479, -7.169165552650159],
              [-40.030867348170055, -7.169979040001337],
              [-40.032400864861337, -7.171856822975071],
              [-40.034170868970335, -7.173625429790275],
              [-40.035540356327587, -7.174861366477287],
              [-40.036219361842043, -7.175737346772627],
              [-40.036894570967597, -7.177137336929633],
              [-40.036879321292915, -7.178123422017319],
              [-40.033976338887804, -7.179242571023506],
              [-40.033739806377049, -7.179469068481038],
              [-40.033446089133179, -7.179867721863201],
              [-40.033156168085064, -7.179985976926151],
              [-40.032519096586327, -7.179978340467599],
              [-40.031588331937144, -7.180251567096022],
              [-40.030829209703796, -7.180994973046779],
              [-40.030009039901252, -7.181738355949317],
              [-40.029066795600606, -7.182934735044558],
              [-40.028704418238249, -7.184092975827153],
              [-40.028292423785878, -7.18454597279035],
              [-40.027006872130009, -7.185462441288501],
              [-40.026362186089145, -7.186205847329693],
              [-40.026114221063075, -7.187182876287215],
              [-40.025988352304445, -7.187997318314332],
              [-40.026148587390701, -7.188982939543987],
              [-40.026602536771271, -7.189506506771141],
              [-40.026945824069124, -7.189858406937708],
              [-40.027228130840491, -7.190155964381133],
              [-40.027399773536004, -7.190273265815388],
              [-40.028147463930438, -7.190687633928847],
              [-40.028883697988853, -7.191626525895273],
              [-40.03035998598498, -7.19362209712742],
              [-40.031203019555861, -7.195483673660015],
              [-40.031412847011062, -7.196821683822833],
              [-40.031401415494706, -7.197572214324402],
              [-40.030928372054696, -7.198550221099119],
              [-40.029879329146347, -7.198941213927949],
              [-40.028486976616769, -7.199387551153269],
              [-40.026701699650935, -7.198840142011193],
              [-40.025671726435874, -7.198191149076288],
              [-40.024870624512303, -7.197659479621277],
              [-40.023314243745368, -7.197346205260787],
              [-40.022383455190365, -7.197502132589636],
              [-40.022364386080469, -7.19906664021488],
              [-40.022349112403781, -7.200052726303717],
              [-40.022162196031658, -7.200921534958025],
              [-40.020888075805836, -7.2011957163067],
              [-40.019240144893644, -7.203134546400248],
              [-40.018130030999721, -7.204050550039273],
              [-40.01590989444918, -7.205584045102913],
              [-40.01416277892843, -7.206655021308777],
              [-40.012472845227173, -7.207446111234185],
              [-40.010443444116902, -7.207993031350538],
              [-40.00794861519816, -7.208658694386711],
              [-40.005739911267753, -7.20931483570693],
              [-40.003718124515842, -7.209346313912743],
              [-40.002802587726649, -7.208407888800445],
              [-40.00231049877695, -7.206428057255917],
              [-40.003008573022555, -7.206209638117038],
              [-40.004111027507854, -7.205872546009229],
              [-40.005854348686206, -7.205262668042334],
              [-40.008352972707286, -7.204433929984335],
              [-40.009463063599647, -7.203518391343295],
              [-40.010123000274106, -7.201843266889711],
              [-40.010443444710418, -7.199708461917456],
              [-40.010409102290815, -7.198026164956543],
              [-40.010664680817513, -7.196352482174365],
              [-40.010969851584505, -7.195193752350238],
              [-40.012263041680519, -7.193653575591951],
              [-40.013502819297486, -7.191932185875702],
              [-40.014044524710805, -7.190375314621754],
              [-40.014582386834853, -7.189216584982249],
              [-40.0152919157835, -7.188012103752494],
              [-40.016066310597594, -7.186400377011414],
              [-40.016546967254072, -7.185079062299718],
              [-40.016516442855163, -7.1833333647234],
              [-40.015135523979758, -7.182857015481308],
              [-40.013697373608736, -7.182254285950593],
              [-40.013018368189421, -7.181261029358451],
              [-40.013052687877334, -7.178827734950901],
              [-40.013323540081799, -7.176158863141326],
              [-40.013479932272148, -7.173264448592293],
              [-40.013693555007727, -7.17043299071255],
              [-40.013788922447937, -7.167764607763894],
              [-40.013819446929453, -7.165621234621359],
              [-40.013846153344552, -7.163595139774133],
              [-40.013468524483613, -7.161732608628935],
              [-40.012850520860184, -7.160449918822313],
              [-40.012007464053866, -7.158877773572041],
              [-40.010744798485561, -7.15822070151112],
              [-40.009299035752363, -7.158142027841954],
              [-40.006876686246713, -7.157640859933101],
              [-40.004572614813995, -7.157257014415221],
              [-40.004168257853372, -7.157022879498755],
              [-40.003489229338996, -7.156146923210254],
              [-40.003149715156589, -7.155614299132967],
              [-40.003164965828162, -7.154628212051769],
              [-40.004973154096319, -7.153322147300476],
              [-40.006075607573649, -7.15299408813445],
              [-40.007235292418791, -7.152954972282745],
              [-40.00815844394009, -7.152907778292952],
              [-40.009028183788338, -7.152923982881381],
              [-40.010069589611142, -7.152704633016008],
              [-40.010477764952419, -7.152423303022787],
              [-40.010832528906207, -7.151843949772516],
              [-40.010954602409221, -7.151382362633449],
              [-40.011015651071375, -7.151264596386939],
              [-40.011019446180185, -7.151092930418207],
              [-40.01102326451781, -7.150631342186001],
              [-40.010924079088952, -7.149528422714257],
              [-40.010645612696756, -7.148714934367723],
              [-40.010546427132134, -7.147783679868027],
              [-40.011477216007201, -7.147220531974445],
              [-40.012976390271504, -7.14741650598126],
              [-40.014067412009254, -7.148128432576233],
              [-40.015337690939063, -7.148261471779965],
              [-40.016264660527291, -7.148096488498322],
              [-40.016676655070384, -7.14752570324163],
              [-40.017153492612842, -7.146376053503218],
              [-40.017683741278447, -7.145859635014023],
              [-40.018728966168197, -7.145522518856295],
              [-40.019831420647094, -7.145194435689566],
              [-40.020308281096774, -7.144162086248421],
              [-40.021025424314672, -7.142541792518075],
              [-40.02120852152261, -7.141908073458089],
              [-40.021738771366557, -7.141165645322453],
              [-40.02313876016072, -7.140312109536801],
              [-40.023432499352381, -7.139976879775933],
              [-40.024012318533181, -7.139631638300192],
              [-40.024306057681969, -7.139350798213524],
              [-40.024370901588512, -7.138889187029512],
              [-40.024096253217756, -7.138012787063031],
              [-40.023638484725467, -7.137660886810772],
              [-40.021902799906101, -7.137746241934028],
              [-40.020689728571291, -7.137613202776715],
              [-40.019591092336633, -7.137597464006777],
              [-40.018492479966199, -7.137753856205681],
              [-40.01750825701005, -7.137629384177179],
              [-40.017176403042974, -7.136752986166543],
              [-40.017142060561866, -7.135133645841085],
              [-40.017782927310904, -7.134788893410502],
              [-40.018824333127441, -7.134578600489185],
              [-40.019591092487616, -7.133600150954005],
              [-40.020640134571408, -7.132982613478444],
              [-40.021788364000507, -7.133576774821568],
              [-40.022887000328026, -7.133475190293882],
              [-40.023985635655961, -7.133373163768849],
              [-40.025027041387915, -7.133271115199014],
              [-40.026594878534375, -7.133005524022671],
              [-40.027117514025541, -7.132778536793318],
              [-40.027174720707549, -7.132669828489535],
              [-40.027876612162764, -7.132152454140568],
              [-40.028063552521175, -7.13129270343777],
              [-40.027739289140854, -7.129602326309254],
              [-40.026430848574599, -7.128076911419706],
              [-40.025122409823439, -7.126786119125187],
              [-40.024611228942113, -7.126136194617661],
              [-40.023867380787735, -7.125378003576209],
              [-40.022845020882677, -7.124258878478949],
              [-40.022391070683014, -7.12350022166838],
              [-40.02223849681284, -7.122161745568951],
              [-40.022188903525553, -7.121700647293078],
              [-40.023173102753297, -7.121481297380013],
              [-40.025309326562407, -7.121630543162504],
              [-40.026701701904059, -7.121419295521238],
              [-40.028731125107953, -7.120754586097048],
              [-40.031229750230274, -7.119799489780783],
              [-40.031645539788514, -7.119174361852638],
              [-40.031553990714784, -7.117546430534888],
              [-40.030581246223129, -7.116725794688948],
              [-40.028799787450474, -7.115888464308965],
              [-40.027357819735819, -7.115746833026632],
              [-40.02614474630699, -7.115731583166559],
              [-40.023947475835591, -7.115700103631888],
              [-40.022398707329913, -7.114980097732291],
              [-40.020849939959362, -7.11408840086208],
              [-40.019350764784463, -7.113775126564303],
              [-40.017725698858534, -7.114275806289251],
              [-40.016848322515266, -7.114956255522882],
              [-40.015845030463595, -7.116396292104939],
              [-40.01571916176151, -7.117147311504815],
              [-40.01431917288523, -7.118109089642173],
              [-40.013258675243875, -7.119540558230582],
              [-40.012663582581951, -7.120636330196072],
              [-40.012068489834796, -7.12184034651286],
              [-40.011706111437988, -7.123053418958077],
              [-40.010931715770681, -7.124492989722483],
              [-40.009768237403577, -7.125228293402204],
              [-40.008780219069138, -7.125619286283116],
              [-40.007643469285917, -7.124102439481885],
              [-40.006350256346749, -7.121644861197311],
              [-40.006380779816809, -7.119501466069228],
              [-40.006327392513825, -7.119094244468376],
              [-40.005808575274273, -7.118968841813118],
              [-40.004713758056454, -7.11889922437176],
              [-40.003267994280236, -7.118874894383606],
              [-40.001650540715417, -7.118851541255048],
              [-39.999914856936002, -7.118891122656474],
              [-39.998930657803484, -7.11899268327572],
              [-39.997259839893488, -7.118616451221046],
              [-39.996641837171964, -7.117451061723589],
              [-39.997236953009683, -7.116129258115381],
              [-39.999094687289784, -7.115628112576424],
              [-40.000773165650919, -7.115362032487447],
              [-40.002451642919794, -7.115213742692267],
              [-40.004244557331809, -7.115128386609515],
              [-40.004885424079717, -7.114783145181653],
              [-40.005022748247384, -7.113335960965466],
              [-40.004755714403288, -7.111717552459263],
              [-40.003615122289986, -7.110652792949522],
              [-40.002234202418144, -7.110167363778293],
              [-40.000845645096085, -7.110324245749923],
              [-39.998755197372667, -7.110934588452547],
              [-39.996203160963873, -7.111473895219402],
              [-39.993643488013255, -7.112772812425034],
              [-39.992621150201678, -7.111535898041521],
              [-39.991999352463878, -7.110424385220193],
              [-39.991835323075911, -7.109845963560643],
              [-39.991850573834263, -7.10874257718988],
              [-39.991854391984674, -7.108516545548413],
              [-39.991862005342533, -7.107992023700128],
              [-39.99280806697152, -7.106325489435798],
              [-39.99357864438425, -7.105238773556581],
              [-39.993822791569272, -7.104080555694255],
              [-39.993838041142624, -7.103212212914321],
              [-39.993792267146674, -7.102343893086584],
              [-39.993509982600017, -7.101756413389434],
              [-39.993174287472577, -7.101115079973625],
              [-39.992209157205615, -7.099941565257831],
              [-39.991747591562657, -7.099815696651824],
              [-39.990526882830757, -7.100261522022847],
              [-39.989065846249197, -7.101404513023824],
              [-39.988135058514203, -7.101795039953145],
              [-39.987959596752539, -7.101795528812967],
              [-39.987902367071378, -7.101904238116096],
              [-39.987269136682841, -7.101724468699109],
              [-39.985781394020236, -7.100660640917286],
              [-39.984694214296781, -7.099893881666996],
              [-39.984064779065442, -7.099479512660765],
              [-39.983313293518783, -7.09929117520312],
              [-39.982142177849752, -7.100487554109748],
              [-39.981012994197265, -7.102560844788131],
              [-39.979483318378335, -7.104626498199091],
              [-39.978419024635983, -7.106229632748826],
              [-39.977358527994603, -7.107669692279823],
              [-39.976942714482966, -7.108240454530338],
              [-39.976240821990999, -7.108811704553989],
              [-39.9754321075796, -7.108967165988296],
              [-39.973944364919774, -7.107903361205791],
              [-39.973848997685714, -7.106510496487648],
              [-39.973875703128535, -7.104430057990544],
              [-39.973784153916334, -7.102974259653632],
              [-39.973219585724515, -7.101926170500799],
              [-39.973120399239967, -7.100886183663483],
              [-39.973948183430693, -7.099563914244871],
              [-39.975462632447687, -7.098656500873658],
              [-39.976870257672573, -7.09717020189229],
              [-39.978399956397062, -7.095222314775955],
              [-39.978693673500942, -7.094995327367497],
              [-39.978987412738647, -7.094597162986187],
              [-39.979521479556546, -7.093845211915577],
              [-39.97970075960243, -7.093447047444847],
              [-39.979712215250991, -7.092515305044914],
              [-39.979487137201112, -7.092054205636045],
              [-39.977987962939395, -7.091858697638427],
              [-39.975786872194497, -7.092224894721847],
              [-39.974737830161104, -7.092788042522939],
              [-39.972456645792946, -7.09467395042325],
              [-39.972223954442825, -7.094665359293042],
              [-39.972223954350611, -7.094783147586165],
              [-39.970080094238448, -7.095104058985423],
              [-39.968928047662558, -7.094735464297616],
              [-39.967375461006398, -7.094250523995338],
              [-39.965937310450592, -7.093882861078824],
              [-39.964079553185378, -7.09437497167413],
              [-39.961474129298864, -7.094742122435411],
              [-39.959398931292185, -7.09431201439646],
              [-39.957785296026273, -7.093881416721115],
              [-39.955763509331483, -7.093849495333948],
              [-39.954489389208703, -7.09400588840021],
              [-39.953726447924105, -7.094866594637375],
              [-39.953589125810566, -7.096250867220009],
              [-39.953852363585177, -7.097995586952143],
              [-39.954008756524594, -7.099216296746651],
              [-39.951990764030718, -7.098894919099028],
              [-39.950506862572773, -7.097541147066565],
              [-39.948794042872962, -7.096007186937769],
              [-39.947207137644881, -7.094011591679967],
              [-39.94629541877044, -7.093136147224576],
              [-39.945666030632964, -7.092604011928384],
              [-39.945089982892689, -7.092360796939134],
              [-39.943934117070611, -7.092345058131454],
              [-39.942763001542879, -7.093369305067069],
              [-39.942343393558204, -7.094582400455311],
              [-39.943133038926149, -7.095928512989221],
              [-39.944395681491407, -7.096586074027137],
              [-39.945650732890869, -7.097524010376079],
              [-39.946451834581246, -7.098354659014856],
              [-39.947001152199974, -7.100271066938929],
              [-39.94698206024389, -7.101663908562452],
              [-39.946211482786609, -7.102814048055748],
              [-39.944925953980267, -7.103947491246625],
              [-39.944395680229533, -7.104581677025013],
              [-39.944330861283539, -7.105097141879984],
              [-39.944651282602244, -7.106905796273447],
              [-39.946455629475466, -7.109940841467308],
              [-39.947588584097183, -7.111693243836052],
              [-39.948210404874992, -7.112750389978162],
              [-39.948660537192211, -7.113391257484345],
              [-39.948946639894608, -7.113743646593478],
              [-39.949457820915235, -7.114212847177916],
              [-39.950728098744861, -7.114463676667317],
              [-39.952669791869994, -7.112117159269249],
              [-39.953787497055089, -7.110740033408565],
              [-39.954310109492184, -7.110576003801977],
              [-39.954653441882904, -7.110810605662778],
              [-39.955206554692346, -7.112446638277954],
              [-39.955195121999658, -7.113432258354487],
              [-39.95522564632887, -7.115286222253487],
              [-39.955893219194195, -7.117093898929718],
              [-39.957266501800035, -7.117977491704012],
              [-39.95891827479285, -7.119683608762148],
              [-39.959768921862143, -7.120857589385725],
              [-39.959994022817725, -7.121435987092225],
              [-39.96060818428667, -7.122954255465586],
              [-39.961340600147999, -7.124182578671478],
              [-39.961847961968004, -7.124941234518567],
              [-39.962481192169307, -7.125356070526867],
              [-39.964205444559859, -7.125904433590218],
              [-39.965059933736896, -7.126906281251491],
              [-39.965742757453036, -7.127492783269528],
              [-39.966826141970657, -7.128548974785086],
              [-39.967566195048086, -7.129433523060927],
              [-39.967718792782058, -7.13094366412408],
              [-39.967005444872186, -7.132148146340637],
              [-39.96521634953357, -7.132115736130168],
              [-39.962450690553716, -7.131497755665041],
              [-39.960970585300949, -7.129854574358682],
              [-39.959829947238028, -7.128735425176264],
              [-39.957945529890004, -7.127029775928988],
              [-39.956461605300973, -7.125848180853143],
              [-39.955427812978677, -7.125370854905828],
              [-39.955080686387639, -7.125425686304623],
              [-39.954950975578107, -7.126348860664593],
              [-39.955080685201125, -7.129477853995917],
              [-39.955912288357261, -7.13198125263206],
              [-39.956156433347772, -7.134884259411303],
              [-39.956591315624266, -7.136981857172131],
              [-39.956579860206503, -7.137615111365176],
              [-39.956049611224721, -7.138538727409183],
              [-39.955347695825864, -7.138992213137884],
              [-39.954249083598917, -7.138976473369021],
              [-39.951227823166427, -7.136151697938262],
              [-39.947706861501509, -7.131754288552589],
              [-39.946356465245323, -7.128952866299895],
              [-39.945925402939643, -7.126855734544252],
              [-39.945715575566439, -7.125399958114577],
              [-39.945334128540338, -7.123772492580852],
              [-39.944719944065262, -7.122263305155425],
              [-39.944384249033327, -7.121504183447681],
              [-39.943827317110753, -7.120049338741793],
              [-39.943262725879976, -7.119055616262969],
              [-39.94320551837658, -7.118937850924941],
              [-39.942923234739773, -7.11846816052258],
              [-39.942186953636529, -7.117583146257277],
              [-39.940989179154911, -7.116237988392582],
              [-39.94019189539015, -7.115470740373313],
              [-39.939734126857942, -7.115173205800743],
              [-39.938414254699474, -7.114515643714328],
              [-39.937552129073332, -7.114155641200333],
              [-39.936918875738115, -7.113912915161621],
              [-39.936110161500892, -7.113850934900646],
              [-39.935591367087625, -7.113951540890622],
              [-39.935057323228484, -7.114757881632274],
              [-39.934931431571357, -7.115454535351929],
              [-39.93578210155404, -7.116737201318698],
              [-39.937208773496145, -7.117982264962741],
              [-39.938643128710531, -7.11881146910939],
              [-39.940069822751894, -7.119930106513317],
              [-39.940935767443001, -7.120172878734808],
              [-39.940676369911415, -7.121909961106716],
              [-39.940371199157653, -7.12306820191675],
              [-39.94006600431851, -7.124335175074296],
              [-39.939917248641962, -7.126533378766562],
              [-39.939955386228043, -7.12798917906075],
              [-39.939894361573764, -7.128097887360595],
              [-39.93874995021941, -7.127385983734904],
              [-39.937094360023828, -7.125978356883644],
              [-39.935721076521411, -7.124967939870735],
              [-39.933485644301378, -7.123715308636219],
              [-39.930212648646581, -7.122166028370138],
              [-39.929808290602871, -7.122040135808939],
              [-39.929464958120789, -7.121923325241073],
              [-39.927725455296923, -7.122071614988018],
              [-39.926741255129691, -7.12222754228091],
              [-39.925924927542525, -7.122681027919129],
              [-39.92516960065872, -7.122963311632749],
              [-39.925043731823557, -7.123885996995501],
              [-39.926115663006321, -7.125466710347346],
              [-39.926687867458959, -7.126107577949252],
              [-39.926954878470639, -7.127509010747013],
              [-39.926146163106431, -7.127610082506255],
              [-39.924296042157195, -7.127641095869438],
              [-39.922331462152243, -7.127500419173499],
              [-39.919676445225711, -7.127107493450645],
              [-39.918581604018918, -7.12703741101302],
              [-39.916506429074104, -7.126543879349073],
              [-39.915461182204695, -7.126871472563148],
              [-39.914984343756863, -7.127912878937459],
              [-39.914671558652401, -7.129586584644629],
              [-39.914831769880109, -7.130400538884101],
              [-39.917582154043309, -7.132176759379216],
              [-39.919191970276231, -7.132671246674072],
              [-39.920515660545938, -7.133147618853401],
              [-39.922060633696759, -7.134329677971243],
              [-39.923674244138674, -7.134525187064218],
              [-39.925757079435833, -7.134503254830508],
              [-39.927492763243457, -7.134409329756467],
              [-39.929800652766879, -7.134621508294621],
              [-39.931463857176404, -7.135685337216568],
              [-39.933062241766002, -7.137102508968152],
              [-39.934149421480356, -7.137868780224272],
              [-39.935820262334389, -7.13829984295024],
              [-39.937601698315213, -7.138847718062479],
              [-39.939554846855181, -7.139685047573527],
              [-39.940935766715683, -7.140170477746341],
              [-39.942248024512729, -7.141352559686527],
              [-39.942637131937417, -7.142401138702494],
              [-39.942728680922507, -7.144137289360569],
              [-39.941675819732481, -7.144935526744823],
              [-39.940115596775357, -7.144911684661176],
              [-39.938845317763409, -7.144895944756908],
              [-39.937220251858378, -7.14538803352746],
              [-39.935873650473653, -7.146530045618184],
              [-39.935679097707151, -7.147977743781173],
              [-39.935480748789054, -7.149651448585769],
              [-39.934759764427362, -7.151498240941161],
              [-39.933634422744078, -7.153571507620112],
              [-39.931627885757905, -7.156316212578259],
              [-39.930979405704235, -7.157122529231521],
              [-39.930170667289772, -7.157287047607963],
              [-39.928606649269192, -7.157380505815915],
              [-39.92745080654327, -7.157247000706588],
              [-39.926931990266084, -7.157175940724341],
              [-39.925623550668028, -7.155713016469431],
              [-39.924616463489599, -7.15355341563165],
              [-39.924009939430405, -7.151456284735292],
              [-39.923460620823889, -7.149539875800542],
              [-39.923143994715197, -7.147442254136694],
              [-39.923124926130711, -7.144547839488341],
              [-39.923143994957506, -7.14332715282779],
              [-39.922411579094671, -7.142107397571483],
              [-39.920176170016084, -7.140682635365285],
              [-39.916506429550317, -7.138655096651482],
              [-39.914213789969232, -7.137112987107291],
              [-39.912897712967599, -7.136228926380722],
              [-39.911573999706846, -7.135743032258416],
              [-39.909727697776532, -7.135719678949736],
              [-39.908163679808744, -7.135750179539333],
              [-39.905424704014727, -7.137220763561837],
              [-39.902227982606576, -7.138502009361173],
              [-39.89983618021764, -7.140152827573225],
              [-39.897627453360798, -7.140754137229238],
              [-39.89473592660898, -7.141003988459093],
              [-39.893339733130361, -7.141449812686289],
              [-39.891565910517343, -7.140376950735289],
              [-39.890204059625269, -7.138498213947185],
              [-39.88904058064584, -7.134946237363649],
              [-39.888723954391651, -7.133029363626863],
              [-39.888754478810796, -7.130948902145227],
              [-39.888655292606437, -7.12955606043267],
              [-39.889303796654175, -7.128749742783607],
              [-39.891226396850769, -7.127742166341659],
              [-39.89302692564798, -7.127069354793268],
              [-39.897017110391488, -7.127125141584808],
              [-39.899630171567345, -7.126296868606324],
              [-39.900965317520807, -7.12579667765619],
              [-39.902765868227327, -7.125241632399976],
              [-39.904223086638027, -7.124334219990591],
              [-39.904997459201908, -7.123011949537342],
              [-39.904661740263229, -7.122135503537508],
              [-39.902353874738537, -7.121931892952877],
              [-39.900839448959985, -7.12254989705546],
              [-39.89984761248774, -7.123166921573126],
              [-39.899500484941534, -7.123167387298412],
              [-39.898588766081737, -7.122282862896608],
              [-39.897730458659758, -7.121633403125079],
              [-39.896696666251373, -7.121273400477814],
              [-39.894327750982534, -7.1212962874856],
              [-39.894228564599373, -7.120129477416398],
              [-39.894022579559596, -7.118212556769475],
              [-39.893988236980128, -7.116711006762316],
              [-39.89405690004093, -7.116014353000833],
              [-39.894301046080791, -7.115037301061126],
              [-39.894373526341425, -7.114051215041481],
              [-39.894213315204169, -7.113119937510719],
              [-39.893232934191239, -7.11304936616802],
              [-39.892187710416692, -7.113259660087258],
              [-39.890677079564199, -7.113940573815159],
              [-39.889551738172223, -7.115661474596346],
              [-39.888941395678579, -7.117968899257002],
              [-39.888571357116618, -7.119471404941359],
              [-39.887045476563067, -7.12118373646068],
              [-39.886694530909274, -7.121356357148967],
              [-39.886404609831423, -7.121519431942419],
              [-39.886282536325361, -7.121990077019574],
              [-39.886164282026506, -7.122162208892949],
              [-39.885977364766006, -7.122904171289552],
              [-39.885859109467148, -7.123076303162986],
              [-39.885561575073766, -7.123710023123172],
              [-39.885210606285419, -7.1240543327801],
              [-39.88409672035133, -7.125087613700814],
              [-39.882212256835906, -7.12743368913192],
              [-39.88150272786509, -7.128692513014444],
              [-39.881418792094564, -7.130429153517139],
              [-39.881456952815817, -7.131713285833856],
              [-39.881670575260422, -7.133042704016414],
              [-39.882006271382316, -7.13368406042748],
              [-39.882166481559665, -7.134561437283524],
              [-39.882330510717424, -7.135429268200017],
              [-39.882128344739016, -7.137220274285086],
              [-39.881483658664209, -7.138026614936709],
              [-39.88071690045949, -7.138824363544157],
              [-39.880484184834884, -7.139168161296839],
              [-39.880232425611617, -7.140444191436059],
              [-39.880091283200748, -7.14223517456977],
              [-39.880072214374543, -7.143456373221325],
              [-39.880285836902935, -7.144677059058838],
              [-39.881235694306575, -7.147063158477048],
              [-39.881834605148377, -7.149504112291337],
              [-39.882162662816988, -7.150787755842136],
              [-39.882437333912605, -7.152008442729289],
              [-39.882540314635641, -7.152704630630525],
              [-39.882647138331436, -7.153400818534914],
              [-39.882467834371049, -7.153690738653371],
              [-39.881807898768194, -7.155301975461481],
              [-39.881693462484641, -7.155419742664481],
              [-39.88111362059194, -7.155411640257062],
              [-39.880423160624112, -7.155222812847706],
              [-39.879622082616471, -7.154808444705457],
              [-39.878710362854804, -7.15380615400609],
              [-39.877741437583069, -7.152695572912335],
              [-39.876226988644028, -7.14971342762814],
              [-39.876024821676396, -7.14767876368715],
              [-39.875704377452081, -7.145761864946712],
              [-39.875097829300117, -7.143782523357216],
              [-39.873968692708601, -7.141975288324693],
              [-39.87283571515065, -7.140159532341232],
              [-39.872107116362841, -7.138813396855077],
              [-39.870916932116444, -7.137115383120993],
              [-39.870756698117226, -7.13601197362352],
              [-39.870878771481252, -7.135722541457859],
              [-39.872213916430631, -7.135222328509559],
              [-39.873385055952113, -7.134198081580507],
              [-39.873476558081997, -7.131936430238324],
              [-39.872912013713325, -7.131123897677067],
              [-39.871943088311134, -7.130175948612654],
              [-39.871435726630743, -7.129245159807106],
              [-39.87185915263067, -7.127978210750552],
              [-39.872816645713137, -7.125615465695966],
              [-39.873934349796123, -7.124356152137389],
              [-39.874693472782887, -7.123902666456368],
              [-39.876089666393099, -7.123284687262318],
              [-39.878363236331552, -7.121987213837441],
              [-39.87970983674672, -7.120790835079273],
              [-39.880243881829465, -7.11969506208075],
              [-39.880205720141966, -7.118365643038298],
              [-39.879641151828956, -7.117480140917094],
              [-39.878370849867309, -7.117410501341805],
              [-39.876921268003585, -7.117557859322408],
              [-39.875288564796683, -7.118519661265877],
              [-39.873785572396173, -7.118558752855155],
              [-39.871988861853062, -7.118878709526938],
              [-39.870642261482004, -7.120020745610755],
              [-39.870279860094286, -7.121233330040663],
              [-39.869635198298418, -7.12168681381119],
              [-39.869116380889217, -7.121787419800603],
              [-39.867964357412262, -7.121310116767265],
              [-39.866880971768857, -7.12042605723221],
              [-39.866369791937423, -7.119721301067539],
              [-39.865450458680037, -7.119415640185315],
              [-39.864466259604967, -7.119462836131722],
              [-39.863291348928648, -7.120722171644124],
              [-39.862341468206765, -7.12255988385665],
              [-39.862661913613394, -7.124250771945903],
              [-39.864031402005253, -7.125423798968891],
              [-39.865416115920766, -7.125792392838139],
              [-39.86655673115856, -7.126676429419243],
              [-39.866774172747533, -7.127788429907938],
              [-39.866236286607084, -7.128992445252244],
              [-39.864317503701351, -7.129602323084725],
              [-39.862810691967788, -7.130102513898809],
              [-39.860655376508767, -7.131001846806373],
              [-39.858641227195143, -7.1304448915752],
              [-39.857184007791872, -7.131352303980819],
              [-39.855070649035845, -7.133526689258733],
              [-39.854010173414594, -7.134957691828633],
              [-39.853712616021959, -7.135591411787323],
              [-39.853056498582802, -7.136967559003847],
              [-39.852514817326984, -7.138352298258447],
              [-39.852091367369468, -7.139565370637396],
              [-39.851763286507143, -7.142342485693796],
              [-39.851595460869234, -7.145934020983771],
              [-39.851633622553038, -7.147263439026847],
              [-39.851831971362934, -7.149533192546974],
              [-39.85186249468682, -7.151387620430903],
              [-39.851896838261617, -7.15288870544078],
              [-39.851828152156017, -7.153639725875501],
              [-39.851297926222941, -7.154509000237251],
              [-39.850359502099316, -7.15547839167676],
              [-39.849543150660814, -7.155751152396688],
              [-39.849485943972809, -7.155868942644156],
              [-39.848730616237837, -7.155970014443329],
              [-39.847750236180772, -7.155962865715674],
              [-39.846998751554388, -7.155891829551725],
              [-39.846125192471121, -7.156227990845781],
              [-39.845209655376259, -7.155686775377385],
              [-39.844366597580581, -7.154114653159362],
              [-39.844259798977504, -7.153300675965553],
              [-39.844168249764621, -7.151853958585281],
              [-39.843782960594488, -7.150452548702399],
              [-39.842695756941737, -7.149622365836573],
              [-39.841539915176405, -7.149552260350248],
              [-39.840559534127173, -7.149536055677805],
              [-39.839640201916936, -7.149175563122065],
              [-39.838663638203727, -7.148698236223574],
              [-39.83791976684455, -7.148219978513442],
              [-39.837114894824055, -7.147859952045098],
              [-39.836363362148823, -7.147852338500958],
              [-39.834970987745052, -7.14818039743659],
              [-39.834497991601111, -7.148805059311425],
              [-39.833780802401911, -7.150425353011538],
              [-39.832674552881045, -7.150870711466625],
              [-39.831118149101144, -7.150611315803379],
              [-39.828897989570521, -7.148381143463367],
              [-39.827204261769523, -7.145571129023318],
              [-39.825285478922865, -7.142300505177705],
              [-39.824205912579849, -7.141008736096564],
              [-39.823591752162962, -7.139436149068927],
              [-39.821981934944738, -7.135124584729248],
              [-39.820257706903355, -7.130342841131259],
              [-39.81848004328036, -7.125506754823098],
              [-39.817644621930214, -7.123292322488017],
              [-39.817598847892185, -7.122478369347812],
              [-39.817553072939305, -7.121555682861668],
              [-39.817564528403707, -7.120859495058017],
              [-39.816450619576145, -7.117939817749579],
              [-39.815828821798647, -7.116891728568422],
              [-39.815145998233575, -7.116124015653292],
              [-39.81378032891196, -7.114833223351829],
              [-39.812136193340066, -7.112547730818128],
              [-39.810888802591975, -7.110731439772419],
              [-39.810045767704949, -7.109268049919723],
              [-39.809240848593667, -7.109024857754958],
              [-39.806967278809644, -7.110150666206492],
              [-39.80555965274808, -7.11146436720296],
              [-39.80428553246999, -7.11184676990741],
              [-39.802553665843007, -7.111705161407852],
              [-39.800238140214105, -7.111736151414195],
              [-39.797926409615542, -7.111694660859747],
              [-39.796877389461876, -7.112438532564307],
              [-39.79656839965908, -7.113704993701411],
              [-39.796385301459757, -7.114338735744616],
              [-39.79568338689139, -7.115027287052951],
              [-39.795038700967424, -7.115652414787651],
              [-39.794733552448299, -7.116521223320965],
              [-39.794085025282449, -7.117490149983622],
              [-39.792467595840144, -7.117357599518169],
              [-39.791269774199044, -7.116237985320842],
              [-39.790525926883682, -7.115705360945619],
              [-39.789606569541853, -7.115517000361486],
              [-39.789202213454352, -7.115454532421769],
              [-39.788618576417164, -7.115672461654504],
              [-39.787844180682789, -7.117229797676928],
              [-39.787539008987189, -7.11832461584947],
              [-39.786554809694408, -7.118660800057479],
              [-39.784357538281867, -7.118628367536707],
              [-39.783373362265095, -7.11861216186565],
              [-39.782385344006904, -7.118939267125276],
              [-39.780630614321666, -7.120362144190611],
              [-39.77939460786336, -7.121911400900263],
              [-39.778421887265722, -7.12508761102352],
              [-39.777685629218993, -7.127974390074191],
              [-39.777189722880841, -7.13033571547561],
              [-39.776651859739779, -7.131548764754789],
              [-39.776171203285678, -7.132644071787351],
              [-39.775160298912994, -7.134599549228783],
              [-39.774324878474573, -7.136328599170175],
              [-39.773138511124273, -7.138564474787128],
              [-39.772417527917405, -7.14023913514558],
              [-39.772325978705254, -7.142609028814014],
              [-39.7722954543231, -7.144644157545034],
              [-39.772615899689036, -7.146380331350391],
              [-39.77311562495543, -7.147899065604892],
              [-39.773611531070536, -7.149644320494182],
              [-39.773523800049318, -7.151733791855575],
              [-39.772348866432473, -7.152929704739684],
              [-39.770952694973452, -7.153375063965258],
              [-39.7693848356949, -7.153866220780459],
              [-39.767065513697261, -7.154412675673347],
              [-39.764582140502384, -7.154145641331536],
              [-39.762892229677291, -7.155161296895126],
              [-39.761370121428584, -7.156475464794295],
              [-39.760187596454138, -7.158195900515508],
              [-39.758882975938647, -7.160432265036953],
              [-39.758039918138628, -7.162685811817574],
              [-39.757887344260865, -7.165173471732512],
              [-39.75819251581148, -7.168076455509622],
              [-39.758615941751309, -7.170690007121848],
              [-39.759096598192265, -7.173420859074764],
              [-39.759344539374013, -7.176034409551243],
              [-39.759420837549676, -7.178928802204767],
              [-39.759252990082828, -7.18229386083689],
              [-39.758291701943982, -7.184764361224226],
              [-39.757898775212489, -7.187949163764783],
              [-39.757860637330928, -7.190671889364285],
              [-39.757837750299259, -7.192182031266877],
              [-39.758108579464519, -7.193339806519847],
              [-39.758715126507497, -7.195436938390753],
              [-39.759085119182608, -7.197589391743314],
              [-39.759291150255791, -7.199442841764484],
              [-39.759443725072011, -7.200835707512449],
              [-39.75955436510187, -7.201070750187022],
              [-39.759668802198064, -7.201188050572298],
              [-39.759954903984841, -7.20142265138805],
              [-39.76017616376518, -7.20224570861564],
              [-39.760263894725497, -7.204036225923075],
              [-39.760122751443078, -7.205655565078929],
              [-39.759237738760561, -7.20686006914043],
              [-39.757265544297361, -7.207415115246059],
              [-39.754190897574624, -7.208062641924509],
              [-39.752790884821792, -7.208914756696428],
              [-39.752596331103575, -7.210299030222528],
              [-39.75254294358465, -7.21395349921641],
              [-39.752504781745806, -7.216612825204285],
              [-39.75245900772326, -7.219570662393277],
              [-39.752397981845334, -7.223740596288033],
              [-39.752356026836523, -7.226644068809302],
              [-39.752447575901343, -7.228262943155356],
              [-39.752603967959189, -7.229311985976646],
              [-39.752703154242766, -7.230587038394893],
              [-39.752230111141856, -7.231157823590772],
              [-39.750318965476872, -7.231423414480168],
              [-39.748537529727088, -7.230639495777868],
              [-39.746691181104126, -7.226499597189785],
              [-39.74667211222183, -7.224003346174942],
              [-39.746942964456771, -7.221289188699912],
              [-39.747137494226898, -7.219841492554025],
              [-39.74721379369786, -7.218566439277259],
              [-39.747583831403212, -7.216892268631794],
              [-39.747621969155865, -7.21434165199179],
              [-39.745878648106491, -7.211070073278018],
              [-39.745203461959569, -7.209723471839],
              [-39.745443766665062, -7.209207541130288],
              [-39.746397442411492, -7.207306359312578],
              [-39.746591996128885, -7.205922132787599],
              [-39.746210526121608, -7.204294667270181],
              [-39.745188188484043, -7.20288515493694],
              [-39.743483029100467, -7.200952518210593],
              [-39.743498279865534, -7.199849108857017],
              [-39.743631784878644, -7.198636525253377],
              [-39.744055233886534, -7.197360495260195],
              [-39.744890678282154, -7.195694891934529],
              [-39.745199644143788, -7.194365008175036],
              [-39.745325512752707, -7.193731311084922],
              [-39.745325512888442, -7.193559622117346],
              [-39.745386560548809, -7.193441855873643],
              [-39.745333149113243, -7.193206766245232],
              [-39.745283555872298, -7.192691302305815],
              [-39.7444328858302, -7.191516855697834],
              [-39.74191137397235, -7.189910880368625],
              [-39.740477042609321, -7.18931625381461],
              [-39.73874135884617, -7.189400653939282],
              [-39.737177316859885, -7.189493624147699],
              [-39.735269966357336, -7.189523659464203],
              [-39.733477052086805, -7.189499352197756],
              [-39.730917402393928, -7.19056173579043],
              [-39.728430210060125, -7.190520268080061],
              [-39.726236756680223, -7.190433468873995],
              [-39.72492068171718, -7.189539887212883],
              [-39.724180627613229, -7.188718296572771],
              [-39.723677085104498, -7.187551976193174],
              [-39.72410814846252, -7.185760458311393],
              [-39.726278713739219, -7.183649495721165],
              [-39.728205157111283, -7.182343896092842],
              [-39.729265630586085, -7.181085072501321],
              [-39.730101052162716, -7.179184402594564],
              [-39.731153912277733, -7.178450053841265],
              [-39.732096132590833, -7.177191206156735],
              [-39.734125556406255, -7.176934673296458],
              [-39.735987132875621, -7.176090195841565],
              [-39.73825688448774, -7.175200384980164],
              [-39.740686846423358, -7.174997287113035],
              [-39.742868845246456, -7.175898505407359],
              [-39.744371837524113, -7.175986260061713],
              [-39.746511902329978, -7.17601911254744],
              [-39.747610538580489, -7.175971940695443],
              [-39.748190333643102, -7.175754034462283],
              [-39.748831224322728, -7.175472680658888],
              [-39.75005957042476, -7.17444843378618],
              [-39.750086275469016, -7.17288392621828],
              [-39.749468296953459, -7.171482517162765],
              [-39.749025777101359, -7.170198872537056],
              [-39.748758744183789, -7.16868870741245],
              [-39.749014345690725, -7.167015025682611],
              [-39.749559845050484, -7.165458643475026],
              [-39.749464477965418, -7.163885054867791],
              [-39.749376746954297, -7.162148438247813],
              [-39.750261737723029, -7.160826144898829],
              [-39.751203980993338, -7.159621639886021],
              [-39.751223049904155, -7.158292221900322],
              [-39.751238300436768, -7.157477778813004],
              [-39.751188730243051, -7.156899380254583],
              [-39.751131500741288, -7.156781590918478],
              [-39.751085725748588, -7.155913271107682],
              [-39.750635594535574, -7.155154148322666],
              [-39.749769649770691, -7.155029212395282],
              [-39.74953693542664, -7.155020643264266],
              [-39.749308062048662, -7.155020644084783],
              [-39.748903704920728, -7.155012516816522],
              [-39.74727484407137, -7.155512732528564],
              [-39.745241600917346, -7.156230363614839],
              [-39.743330432253991, -7.156495977517115],
              [-39.741304826544699, -7.156580378420142],
              [-39.741095023176399, -7.15512460201743],
              [-39.740572410659759, -7.151588829889488],
              [-39.740541887425266, -7.149617100734204],
              [-39.740721190382182, -7.149327180619862],
              [-39.741705366620735, -7.149054442032832],
              [-39.744078122913351, -7.148914743733116],
              [-39.747202363824449, -7.148846547591927],
              [-39.749231787785796, -7.148408801817035],
              [-39.751501515449569, -7.147455568332862],
              [-39.753248654866525, -7.146439470817277],
              [-39.754652462765755, -7.14536085740881],
              [-39.755537475390426, -7.14421977696975],
              [-39.756662816858963, -7.142382040911791],
              [-39.756754343035325, -7.140056990977577],
              [-39.756193639965907, -7.138836746883422],
              [-39.754061165372043, -7.138460538478867],
              [-39.752146200648831, -7.138834371729773],
              [-39.750067208407501, -7.138801473300723],
              [-39.747946235329316, -7.137665653478663],
              [-39.747446486962914, -7.136273277470304],
              [-39.747686814848649, -7.135522258179824],
              [-39.747637222703737, -7.134879947008145],
              [-39.74638219442604, -7.133823755385674],
              [-39.745756577461584, -7.133056041518897],
              [-39.745836694070569, -7.131554491622622],
              [-39.748362025097556, -7.129099242380374],
              [-39.750002341637554, -7.127612941611725],
              [-39.751234505885108, -7.12636268710265],
              [-39.752172907909312, -7.125501980018392],
              [-39.753519508438231, -7.124133934307802],
              [-39.754702056405534, -7.122413500590862],
              [-39.755888422480325, -7.120520933913405],
              [-39.756189798116488, -7.119534848086538],
              [-39.755796873402801, -7.118893002644833],
              [-39.755163643214253, -7.118478167648678],
              [-39.75286336804156, -7.117803422916845],
              [-39.751020883477324, -7.117318017402407],
              [-39.749056303516831, -7.117167328777018],
              [-39.746862850263355, -7.116908443624891],
              [-39.744265039839846, -7.11669575287925],
              [-39.742472125572455, -7.116662364681716],
              [-39.741037794252378, -7.116004337526601],
              [-39.740122280249018, -7.115354389727736],
              [-39.739790403420791, -7.114305835785519],
              [-39.7396912169365, -7.113265849973275],
              [-39.73982472194453, -7.112044674434188],
              [-39.740595300424253, -7.110840169291354],
              [-39.740839446372583, -7.109971383715672],
              [-39.741026362719005, -7.109112121038086],
              [-39.741323898062802, -7.108532743760491],
              [-39.742090655434886, -7.107500394577556],
              [-39.742384395756361, -7.106983975917743],
              [-39.742399645460246, -7.10594401319524],
              [-39.742117361643942, -7.105709389389008],
              [-39.739820903763807, -7.10462744711941],
              [-39.736769144059799, -7.103772956891897],
              [-39.733885230898714, -7.103262265724076],
              [-39.731573523309777, -7.10322936612268],
              [-39.729376250909951, -7.103196932607673],
              [-39.727697796692695, -7.103344291413042],
              [-39.725897246023543, -7.103898848669129],
              [-39.724619330601122, -7.10451587395547],
              [-39.723448214280701, -7.105313622235643],
              [-39.722269462386322, -7.106916267658567],
              [-39.720732149538769, -7.109153122979817],
              [-39.72042697775548, -7.110365729433906],
              [-39.720407908944139, -7.11157784711398],
              [-39.720217174300132, -7.112853365278405],
              [-39.718923985307988, -7.114338734318949],
              [-39.717531609057808, -7.114494660293961],
              [-39.715807380711254, -7.113945808257808],
              [-39.71454089692957, -7.113640171112754],
              [-39.713499468418554, -7.113505688111727],
              [-39.712576316927539, -7.113552417110619],
              [-39.710604099711141, -7.113808974027347],
              [-39.709619923519014, -7.114027368943984],
              [-39.707998675807929, -7.114292470082672],
              [-39.707823190917814, -7.114464625908178],
              [-39.707128935939103, -7.114338733126011],
              [-39.705194879262059, -7.112279273033366],
              [-39.704012309242337, -7.110236973423962],
              [-39.703051020251188, -7.108709671883104],
              [-39.702997607947367, -7.108302916293812],
              [-39.702654276381722, -7.108303405023557],
              [-39.70154420881461, -7.108866064766954],
              [-39.700842293262014, -7.109545581123586],
              [-39.700998709291959, -7.110648479596077],
              [-39.701162738719283, -7.111173000561606],
              [-39.701254287707059, -7.112918720108625],
              [-39.701223763341304, -7.114944792870811],
              [-39.699873367757711, -7.11643013786435],
              [-39.698896805099047, -7.115898002311136],
              [-39.698168183501778, -7.114316312278661],
              [-39.696699532653334, -7.112093288536919],
              [-39.696077734839278, -7.111099099046862],
              [-39.695623785382914, -7.110683776197479],
              [-39.695162197797323, -7.110503517924911],
              [-39.694815070307975, -7.110441050031244],
              [-39.694063585601697, -7.110487312167129],
              [-39.693132797806896, -7.11099514038028],
              [-39.692991654543917, -7.112614479506453],
              [-39.693079386516196, -7.114414542721601],
              [-39.693624884966525, -7.116566530172162],
              [-39.693617248519296, -7.117208352296809],
              [-39.693552404576188, -7.117724282135814],
              [-39.69239274472222, -7.117825377622445],
              [-39.690832521765422, -7.117854924228186],
              [-39.689791116115501, -7.117901676134605],
              [-39.688394922494254, -7.11857304399854],
              [-39.687338265182376, -7.119605881947662],
              [-39.685556783307298, -7.122819765181836],
              [-39.685083764075571, -7.123570785285056],
              [-39.684851049733084, -7.123562217155207],
              [-39.684843436334482, -7.124141080659353],
              [-39.684412396125516, -7.125760910557461],
              [-39.6835235641705, -7.127372147150361],
              [-39.682699575300013, -7.128287195990872],
              [-39.681242357069706, -7.12901293047833],
              [-39.679682158065653, -7.129105456702825],
              [-39.678007498191739, -7.128791694287457],
              [-39.676931750926073, -7.127382157946321],
              [-39.676256564628005, -7.126216280449138],
              [-39.675974257040949, -7.125691758391561],
              [-39.674829846766762, -7.124915942176887],
              [-39.674066906549569, -7.125721816722464],
              [-39.673700709249147, -7.12687097750764],
              [-39.673517611960854, -7.127621997836361],
              [-39.673403152907198, -7.127450353781994],
              [-39.671797153915122, -7.12666594625892],
              [-39.670717611535395, -7.125437134826694],
              [-39.670217886268212, -7.123918401595528],
              [-39.669382465834545, -7.121821269591303],
              [-39.66864623087762, -7.120772715341206],
              [-39.667955771881743, -7.120638231614147],
              [-39.666971571786085, -7.120739326237331],
              [-39.665621153200618, -7.122233729171692],
              [-39.665251138701983, -7.123672345206721],
              [-39.664713275570847, -7.124884952474063],
              [-39.663484929620964, -7.12573706737704],
              [-39.66127240844191, -7.126861919867417],
              [-39.660417918155368, -7.12603078321374],
              [-39.659174322442034, -7.12416063852378],
              [-39.658323652396824, -7.122985725947456],
              [-39.658094756112845, -7.122868426476575],
              [-39.656832090538387, -7.122273310082214],
              [-39.656256067724605, -7.12214741839265],
              [-39.65431057855379, -7.12083851177244],
              [-39.652712242046668, -7.119366508407501],
              [-39.65214765064632, -7.118607385547499],
              [-39.651918753545893, -7.118254996495261],
              [-39.651464804134342, -7.117785306975495],
              [-39.649565112953852, -7.117353777099658],
              [-39.647718765218627, -7.117148257901885],
              [-39.646791795810969, -7.117131563280786],
              [-39.645574904369226, -7.117232658723238],
              [-39.644766190030843, -7.117333264486431],
              [-39.643389088319019, -7.116675238384842],
              [-39.642034874666216, -7.114451748748457],
              [-39.641584742587789, -7.113520471018273],
              [-39.641413076805911, -7.113520959881732],
              [-39.639971109244478, -7.113260608330858],
              [-39.638468115017595, -7.113127078967225],
              [-39.63736950265293, -7.113336417848944],
              [-39.63568338909819, -7.114008275481895],
              [-39.635507903301551, -7.114062641017184],
              [-39.635038703412967, -7.11434397095412],
              [-39.634050709072945, -7.11479745544821],
              [-39.63329156402407, -7.115359160467118],
              [-39.632173858927345, -7.116681430616499],
              [-39.630922649015929, -7.118980752764884],
              [-39.629572230401862, -7.120519975423513],
              [-39.628214198566035, -7.122412541943508],
              [-39.626276346853452, -7.124296541063416],
              [-39.625170074177078, -7.124976501097729],
              [-39.62481912849195, -7.125202998457302],
              [-39.62458643605914, -7.12531175361959],
              [-39.623083443743205, -7.125295991543574],
              [-39.620840398289303, -7.124503015582033],
              [-39.620039297351113, -7.12402473384478],
              [-39.619383179050423, -7.1214111821095],
              [-39.619112327975841, -7.120127096649932],
              [-39.61862405798108, -7.118155809169902],
              [-39.618017509881859, -7.116112578032876],
              [-39.616934148398705, -7.115055896569446],
              [-39.615789738123809, -7.11428818331423],
              [-39.614122692347593, -7.113793232005924],
              [-39.611524882096241, -7.113407477314651],
              [-39.609445888978279, -7.11325630061062],
              [-39.607828436526823, -7.113177137830842],
              [-39.607424078405224, -7.113168569568081],
              [-39.606558157560933, -7.113160932940857],
              [-39.604708013635509, -7.113244867995265],
              [-39.602106384230723, -7.113094178882289],
              [-39.59985572542552, -7.112825724753674],
              [-39.59900123600125, -7.112175801014692],
              [-39.597338009511276, -7.11128219511903],
              [-39.596830670755672, -7.110459649693035],
              [-39.596906969340914, -7.109012442493866],
              [-39.597570724014645, -7.107229561779008],
              [-39.598303139791646, -7.104687024706508],
              [-39.598463350864094, -7.101846532990211],
              [-39.598478624698593, -7.10063441432476],
              [-39.598501511577503, -7.099295449431749],
              [-39.598459532726665, -7.098201097010715],
              [-39.598188703557199, -7.097034754847338],
              [-39.597685160948885, -7.095986199791991],
              [-39.596021956506171, -7.095029171284541],
              [-39.594976731626879, -7.095419698114275],
              [-39.592512427265319, -7.097820116698712],
              [-39.591329855462135, -7.099367930428929],
              [-39.591303173216481, -7.101213279258404],
              [-39.591280286245819, -7.102670032439096],
              [-39.591024683850847, -7.104225948840215],
              [-39.589830680360151, -7.106760337601384],
              [-39.589361480199962, -7.107394056408401],
              [-39.589300455548788, -7.107502789704074],
              [-39.589170744794195, -7.10837110935748],
              [-39.5891707447025, -7.108488874646043],
              [-39.588991465800085, -7.108714907141218],
              [-39.588583289431696, -7.109059171744307],
              [-39.58805683591023, -7.109394867307269],
              [-39.587301533056838, -7.109676220020665],
              [-39.586492817808391, -7.109668094441455],
              [-39.58545523041802, -7.109361968484809],
              [-39.584093379373499, -7.10771736636322],
              [-39.582330989364138, -7.105829083403867],
              [-39.581358244872533, -7.105061370287533],
              [-39.580728810667608, -7.104646046308541],
              [-39.57935170891502, -7.104050463838554],
              [-39.578604019416495, -7.103807294727628],
              [-39.57797079010475, -7.103564102705806],
              [-39.576704306341355, -7.103257976572805],
              [-39.576128282532025, -7.1031320848866],
              [-39.575491234064735, -7.103123982441135],
              [-39.574903755795347, -7.103694278538677],
              [-39.574541377476258, -7.104843463312368],
              [-39.57468633791202, -7.106815192509341],
              [-39.574651995529386, -7.108905129853242],
              [-39.574682518826769, -7.110813459345484],
              [-39.574716838315148, -7.112441390535466],
              [-39.574468920223993, -7.113536208727578],
              [-39.573583883757543, -7.114505623182256],
              [-39.572065616748361, -7.115529404816136],
              [-39.57066944609732, -7.116263730293812],
              [-39.568819325313243, -7.11617551038857],
              [-39.566675465348709, -7.116431577174636],
              [-39.564821504184032, -7.116867880096936],
              [-39.561804108124775, -7.117513985846749],
              [-39.560301092735116, -7.117606023122126],
              [-39.559133795501573, -7.118285983105398],
              [-39.557798649827411, -7.118495810799326],
              [-39.556997548753138, -7.118198251974828],
              [-39.556825929247879, -7.117845396973923],
              [-39.556795381775608, -7.116163100117492],
              [-39.556818268874245, -7.114543760922445],
              [-39.556848793192664, -7.112572054863474],
              [-39.556810632414596, -7.111350855215193],
              [-39.556482573696435, -7.11013014633907],
              [-39.555578491261791, -7.108665779488865],
              [-39.554552336152611, -7.107898088332983],
              [-39.55334308207707, -7.107474638954154],
              [-39.551801929134847, -7.106056046334122],
              [-39.55038668955752, -7.104303200833473],
              [-39.549318577787538, -7.102196523742479],
              [-39.548242830571873, -7.100732644759741],
              [-39.547212856405331, -7.100073662945883],
              [-39.546636832682196, -7.099839062917938],
              [-39.546171450772135, -7.100119903858865],
              [-39.545271162560987, -7.102192704626662],
              [-39.545126202065774, -7.104155376646402],
              [-39.545446647430467, -7.10590109733951],
              [-39.545492422429831, -7.106769393125003],
              [-39.544962172748541, -7.107348770210472],
              [-39.544901148048481, -7.107520902122342],
              [-39.544897329850457, -7.107810334369433],
              [-39.544546383035517, -7.108208498689692],
              [-39.542913680986338, -7.109051556325096],
              [-39.540830868727909, -7.10913546820107],
              [-39.539327876524339, -7.108992417899439],
              [-39.537828678244736, -7.108912789217375],
              [-39.536382915536109, -7.108896584193281],
              [-39.535219412420318, -7.109395354274196],
              [-39.534620524601053, -7.110780093435866],
              [-39.534876103024331, -7.113041254961567],
              [-39.536176929312525, -7.114920946606429],
              [-39.537081010977012, -7.116086824259704],
              [-39.537828677330872, -7.116511205274801],
              [-39.538912062847487, -7.117513985054098],
              [-39.539537678850763, -7.118218765279505],
              [-39.540033586148638, -7.119728906535668],
              [-39.540521878091667, -7.12176357061567],
              [-39.541120742923979, -7.124214046269056],
              [-39.541418323184537, -7.127578617164215],
              [-39.541345843022448, -7.128446936859707],
              [-39.540292982892829, -7.129235140276394],
              [-39.538954018973371, -7.129797822833345],
              [-39.53668426727598, -7.130867843599204],
              [-39.531969302229427, -7.132881040760078],
              [-39.530748593519746, -7.133380299791848],
              [-39.52923035051645, -7.134404057421534],
              [-39.527071239815612, -7.135754477577384],
              [-39.52584289383617, -7.136669527089563],
              [-39.523855426866113, -7.138082879998192],
              [-39.522165517317653, -7.138808149297589],
              [-39.520616749938341, -7.138031843783603],
              [-39.519014594030502, -7.137138260937372],
              [-39.517019489686881, -7.13525903574442],
              [-39.514623870473798, -7.133018340426547],
              [-39.512628743045362, -7.131247870578914],
              [-39.511430967530131, -7.130010000132089],
              [-39.51041242578281, -7.128717299156847],
              [-39.509615166094505, -7.127895220508134],
              [-39.509500730999925, -7.127777919128527],
              [-39.508867476694121, -7.127534262109568],
              [-39.507890914963824, -7.127119379859185],
              [-39.506921989503695, -7.126288290132081],
              [-39.506239142910147, -7.125583510863692],
              [-39.50515196219974, -7.124869697335799],
              [-39.504457660194291, -7.124798172228691],
              [-39.503759586807078, -7.125251167946145],
              [-39.501928534955866, -7.127659198957913],
              [-39.500738350581138, -7.130021455744817],
              [-39.500349244232581, -7.132735636026795],
              [-39.500208101015907, -7.134300586452611],
              [-39.500192851221087, -7.135458362439019],
              [-39.499956341635034, -7.135802626172941],
              [-39.497816299928495, -7.135714406040622],
              [-39.495741101902134, -7.135445487051698],
              [-39.494932387845935, -7.135201804896039],
              [-39.492685500218066, -7.134706853140965],
              [-39.490896428016356, -7.134618168285121],
              [-39.490778172815396, -7.13467253386423],
              [-39.490480615438138, -7.135306252799585],
              [-39.490118237296343, -7.136229404933817],
              [-39.489801610151666, -7.138020410854671],
              [-39.490308994722781, -7.139069453904867],
              [-39.49167079979199, -7.140659689347771],
              [-39.49308988031909, -7.142422103794937],
              [-39.494219040007472, -7.144058114781441],
              [-39.494993411632564, -7.146445656947897],
              [-39.494985798411015, -7.146798511808434],
              [-39.493471349619298, -7.147523781242321],
              [-39.491331308731105, -7.147670649685804],
              [-39.489134037398919, -7.147627739232308],
              [-39.48861522113971, -7.147564782208615],
              [-39.487558564766658, -7.148705863484514],
              [-39.485857199712143, -7.150191209175798],
              [-39.485742763441422, -7.150299940429044],
              [-39.485624508191265, -7.150417241627472],
              [-39.48550625299027, -7.150471584206683],
              [-39.485449023305392, -7.150589373449755],
              [-39.48509807758311, -7.150870237477617],
              [-39.484518235715967, -7.150861646077393],
              [-39.483499717150565, -7.149343400464547],
              [-39.481348220707041, -7.146469010171239],
              [-39.480459388812172, -7.144190199261643],
              [-39.480425069228552, -7.142689114314377],
              [-39.480688284979941, -7.140608210106379],
              [-39.481126984665558, -7.138292194457627],
              [-39.4808256082095, -7.135216589828681],
              [-39.479414187811145, -7.133219132814878],
              [-39.477823441731076, -7.131167285991863],
              [-39.475954252906412, -7.128763539083049],
              [-39.474931915139692, -7.12753426172696],
              [-39.473848529539566, -7.126648782243364],
              [-39.472749894463149, -7.126514298203512],
              [-39.471285061914855, -7.12776408850196],
              [-39.47113628321172, -7.130025738700002],
              [-39.471746625550125, -7.131716604942766],
              [-39.473112295640021, -7.133243929763156],
              [-39.473737911778976, -7.133767962079039],
              [-39.474405484702409, -7.13546788431311],
              [-39.474268137600575, -7.136851691833914],
              [-39.473077952320089, -7.139096159328478],
              [-39.471838174801626, -7.140816104957847],
              [-39.471197308143246, -7.141097434756854],
              [-39.469476874043515, -7.140257730497706],
              [-39.46839351267117, -7.139082794763195],
              [-39.467378789127395, -7.137501126543542],
              [-39.466249652447289, -7.135856036619415],
              [-39.46539516317074, -7.135033513931954],
              [-39.464243116659006, -7.134663941272326],
              [-39.462808785424755, -7.133950594473689],
              [-39.461893248448668, -7.133300180697947],
              [-39.460817523204852, -7.131890179394999],
              [-39.460081265311857, -7.130778667549877],
              [-39.459573926606474, -7.129901756463098],
              [-39.459119977161741, -7.129485966626921],
              [-39.458410447542434, -7.130346207868707],
              [-39.458173915824624, -7.130861625565206],
              [-39.45689984190728, -7.130844954675839],
              [-39.45533959490033, -7.130991335577132],
              [-39.454984831157965, -7.131335134220389],
              [-39.454683477675779, -7.132140519111219],
              [-39.454614839487391, -7.133009304799292],
              [-39.454656772554536, -7.133823281905155],
              [-39.455740156968524, -7.134943850965314],
              [-39.456831155786674, -7.135476474590266],
              [-39.458437129741874, -7.136261814086458],
              [-39.459814254474374, -7.136857395553389],
              [-39.461015895314794, -7.137634166787603],
              [-39.462507432957835, -7.138582603208896],
              [-39.464784797999208, -7.140823252423485],
              [-39.465398957491502, -7.142279075093154],
              [-39.466303041047276, -7.143571729976252],
              [-39.466856176839485, -7.145199194560971],
              [-39.466722670885282, -7.146357436449871],
              [-39.465891068730073, -7.147733118479469],
              [-39.465715584844709, -7.147905250301433],
              [-39.465593511445071, -7.14824904812646],
              [-39.464769546411979, -7.149398697529431],
              [-39.463552656044932, -7.149435904355906],
              [-39.462526500132839, -7.148433101622938],
              [-39.460592422368592, -7.146553409488418],
              [-39.459406078366563, -7.144564520598283],
              [-39.458505814875892, -7.143154520428936],
              [-39.457472022700394, -7.142558472230858],
              [-39.456480187365237, -7.143065834388468],
              [-39.455248046062721, -7.14444198210168],
              [-39.453966311755835, -7.144995117756435],
              [-39.452810445997827, -7.144978423957324],
              [-39.451547781502086, -7.144318930965078],
              [-39.450872595218968, -7.143153076494836],
              [-39.449396308104717, -7.141390685011761],
              [-39.449281847969111, -7.141327261306518],
              [-39.448026820729233, -7.140270115728105],
              [-39.447179968766939, -7.138977414893478],
              [-39.446623013867594, -7.137521638271937],
              [-39.446119470270609, -7.136472595230273],
              [-39.44423118882073, -7.135054468346441],
              [-39.442171264416864, -7.133862372961672],
              [-39.440099884827013, -7.133014077487331],
              [-39.437921682223575, -7.131876347682949],
              [-39.436323322527066, -7.130692845606973],
              [-39.434446496489251, -7.128641487570557],
              [-39.432912979739648, -7.126932973725919],
              [-39.431596902849932, -7.125984536447829],
              [-39.429803989687166, -7.125895851593065],
              [-39.42888083835949, -7.125769959638919],
              [-39.427488464021152, -7.126097063576913],
              [-39.425855782859372, -7.127111787163641],
              [-39.423757698012636, -7.12812648838896],
              [-39.421598610424475, -7.129367710194652],
              [-39.420877604080658, -7.131267890125844],
              [-39.420808942118605, -7.131847267563479],
              [-39.421201843728994, -7.132606389271084],
              [-39.421423102568156, -7.133357408894556],
              [-39.421362054819866, -7.133592475423487],
              [-39.419847630084142, -7.134263401183404],
              [-39.418554440315191, -7.135521759557396],
              [-39.417497783953181, -7.13666237582823],
              [-39.417371893314126, -7.137349971566108],
              [-39.417566446159029, -7.13962018996878],
              [-39.417730475489932, -7.140262501206584],
              [-39.418184425805514, -7.140840900057017],
              [-39.419569163625653, -7.141246677676754],
              [-39.4209424473583, -7.141869430974006],
              [-39.421976238530092, -7.142465478169076],
              [-39.423910294189504, -7.144472016524701],
              [-39.424638892906671, -7.145873448606846],
              [-39.423929340112863, -7.146959675478173],
              [-39.422704836346654, -7.147584826739976],
              [-39.421308665902032, -7.148083597633276],
              [-39.419325017154634, -7.149207007287115],
              [-39.41868415045797, -7.149542236758439],
              [-39.41796698435143, -7.151098641776806],
              [-39.416807300356872, -7.151425745893127],
              [-39.414781695763963, -7.151454362142211],
              [-39.413568624376481, -7.151491544972312],
              [-39.412004606446509, -7.151583582196992],
              [-39.4112492796502, -7.151810546234423],
              [-39.410257466277415, -7.152372274060673],
              [-39.410017138586696, -7.152888203749536],
              [-39.410276535241358, -7.154805568342204],
              [-39.412264003204271, -7.157209781334745],
              [-39.412782796367537, -7.157390038647804],
              [-39.412897232460324, -7.157507804024055],
              [-39.413358820207762, -7.157462029660921],
              [-39.414693964860007, -7.15725271396884],
              [-39.416021473432352, -7.157215508228839],
              [-39.417062901957856, -7.157287034607622],
              [-39.419080870379645, -7.157547386604636],
              [-39.42213644916432, -7.158123409500019],
              [-39.424326084138194, -7.15867319387951],
              [-39.425417082986435, -7.159160531780603],
              [-39.426447056184401, -7.159756578976321],
              [-39.426901006357227, -7.160516190732987],
              [-39.427007829277962, -7.160923412351746],
              [-39.427045966966574, -7.162252854335669],
              [-39.426748431590099, -7.162886550268415],
              [-39.425291190445002, -7.163556987073902],
              [-39.423151149573144, -7.163703856515178],
              [-39.421415465006177, -7.163614658700697],
              [-39.41845148233871, -7.164612689347388],
              [-39.417349004936689, -7.164939327510093],
              [-39.416475423805359, -7.165392323086555],
              [-39.415697233131191, -7.166948729058815],
              [-39.415140278250441, -7.169310496329493],
              [-39.414983885232942, -7.172033268728685],
              [-39.415006773341176, -7.174240530388503],
              [-39.414682532751826, -7.176664766464702],
              [-39.414030210590397, -7.177696649710464],
              [-39.412119041041564, -7.177906476946831],
              [-39.409696716602383, -7.17751974439386],
              [-39.407968645253625, -7.177086772663651],
              [-39.406927239778817, -7.176952312663609],
              [-39.406290191147356, -7.177169776849594],
              [-39.406088000177064, -7.178970281794585],
              [-39.407507081734508, -7.180669249622022],
              [-39.409464002268173, -7.18133731210941],
              [-39.410959381367078, -7.181652494371561],
              [-39.412401325804282, -7.18202204526308],
              [-39.413599147434532, -7.183088247748535],
              [-39.413652557969073, -7.183196490135371],
              [-39.413652557876695, -7.183314280422636],
              [-39.41381658724702, -7.183902224993544],
              [-39.413808950847312, -7.184481112484947],
              [-39.412763727080261, -7.18475338501974],
              [-39.411832938540613, -7.184971290973029],
              [-39.411131046051217, -7.185595953644216],
              [-39.410532136281546, -7.186917270180746],
              [-39.410043843489326, -7.188537074997909],
              [-39.408983368015036, -7.189849333228056],
              [-39.40809835446796, -7.190935583962993],
              [-39.407964826509989, -7.192093824850752],
              [-39.408929957618291, -7.193386525785744],
              [-39.410364287924018, -7.193991164251357],
              [-39.411901599814101, -7.195464100545263],
              [-39.411886350013091, -7.196621875529654],
              [-39.412088517065563, -7.198539704087125],
              [-39.412061834075352, -7.200041277983466],
              [-39.411802414465562, -7.201895706563322],
              [-39.411024223515589, -7.203795887459841],
              [-39.41100897299075, -7.204601272576709],
              [-39.411680364055144, -7.206065640246841],
              [-39.413065079083573, -7.206200123520071],
              [-39.414571890462987, -7.206054208584988],
              [-39.41607488278467, -7.206016535992301],
              [-39.417860159632866, -7.206566809065716],
              [-39.419641641373303, -7.207288725098515],
              [-39.421777864900342, -7.207612523817481],
              [-39.422350069682231, -7.207792758176888],
              [-39.423154965706736, -7.208099837951988],
              [-39.423612734118826, -7.208515162800208],
              [-39.423658509201431, -7.209266182295202],
              [-39.423406748854887, -7.210713856344622],
              [-39.423162603095385, -7.211347109324171],
              [-39.423143534276512, -7.212559226980757],
              [-39.423822537656257, -7.213553439503419],
              [-39.424856330864827, -7.214095586038064],
              [-39.425897736482867, -7.214049346135917],
              [-39.427412184223662, -7.213378420381907],
              [-39.428171306308791, -7.212753291757492],
              [-39.429510270206698, -7.212200644150373],
              [-39.430895008194973, -7.212388958099933],
              [-39.432024144670564, -7.214269650618598],
              [-39.432531506458467, -7.215028773428783],
              [-39.433859014897159, -7.215163232659465],
              [-39.434785984281319, -7.215180392284365],
              [-39.435873187975176, -7.21589422882445],
              [-39.437235038843362, -7.217710985914954],
              [-39.43835274208606, -7.219989308024955],
              [-39.439195776705063, -7.221734539141509],
              [-39.440668268684618, -7.223677653558513],
              [-39.442960907230351, -7.225104837739641],
              [-39.443987039273594, -7.225926893580654],
              [-39.444090043854388, -7.226795213415501],
              [-39.443845897857322, -7.227726956593842],
              [-39.441927115049417, -7.228325868464176],
              [-39.440755998667299, -7.229240917013214],
              [-39.439993059514514, -7.229983344926646],
              [-39.440027400990992, -7.231602219172903],
              [-39.441263360337778, -7.233943963999499],
              [-39.441431207863808, -7.234296354002982],
              [-39.441255724070267, -7.234351184533426],
              [-39.440950552428404, -7.235382603061995],
              [-39.440588151143885, -7.236477420162469],
              [-39.439886235870787, -7.236821684529364],
              [-39.437456273118642, -7.236896563161136],
              [-39.435209432470749, -7.236455488054398],
              [-39.434126047930448, -7.23551561887872],
              [-39.432867201696844, -7.234503781999896],
              [-39.431776202753284, -7.234142824321061],
              [-39.430570743860656, -7.233483819353027],
              [-39.429311897527384, -7.232598339711663],
              [-39.427187106375904, -7.231696166489452],
              [-39.42493644657867, -7.231481100016349],
              [-39.422968049528585, -7.231573137909688],
              [-39.422556054148764, -7.231971768176103],
              [-39.422300476783313, -7.233473341900192],
              [-39.422563691485841, -7.235273405229475],
              [-39.423822537954017, -7.235986751910872],
              [-39.425260711187754, -7.236645734062422],
              [-39.426813272742841, -7.237132583344497],
              [-39.427900453433899, -7.237846396888515],
              [-39.428163669271591, -7.239474327260095],
              [-39.428190374335522, -7.241681611945355],
              [-39.428209443324583, -7.244051528642667],
              [-39.428068300955601, -7.245788146040067],
              [-39.428091165045316, -7.24799543072409],
              [-39.428110256846438, -7.25060036700375],
              [-39.427694467306402, -7.251234109847267],
              [-39.426176224361377, -7.252194444847177],
              [-39.423284674995116, -7.252151556822199],
              [-39.420877600138176, -7.250896504502903],
              [-39.419046548356881, -7.249414978007579],
              [-39.417005669851193, -7.246947341305662],
              [-39.415208960514526, -7.243330521742516],
              [-39.414709212133587, -7.241992511433804],
              [-39.4141370074463, -7.241694487783519],
              [-39.413671625633263, -7.241857562431078],
              [-39.413179537586302, -7.243821189176677],
              [-39.413194787416508, -7.246426149447513],
              [-39.413503800332123, -7.248804634319583],
              [-39.413881429116188, -7.250731053978012],
              [-39.413950090334104, -7.253625935542001],
              [-39.413946271996046, -7.254087033753217],
              [-39.413637282236955, -7.255299641183222],
              [-39.412641673649425, -7.256159858199625],
              [-39.411356075278775, -7.256830271133559],
              [-39.409608959017454, -7.257727206334105],
              [-39.408384455247656, -7.258352358589501],
              [-39.407919049384297, -7.258579321850431],
              [-39.406362668775365, -7.258200714907915],
              [-39.404829151050414, -7.256501258964327],
              [-39.403177379268186, -7.254675443691832],
              [-39.402151224325557, -7.253735597560644],
              [-39.401762117793027, -7.252858710552348],
              [-39.401071657956905, -7.252551606863023],
              [-39.400087481934243, -7.252597869806373],
              [-39.399088032246866, -7.25362116183322],
              [-39.399122351806156, -7.255131303734371],
              [-39.39972126260313, -7.257573188451649],
              [-39.400793192566489, -7.259336068646571],
              [-39.402044425488391, -7.260800435787668],
              [-39.402544149865761, -7.262138912095834],
              [-39.402502170048606, -7.264807294941463],
              [-39.402376302486395, -7.265386182344616],
              [-39.401949056296438, -7.266942587604045],
              [-39.400945789448045, -7.268200969208164],
              [-39.400533795068341, -7.268599110476974],
              [-39.39914141968238, -7.268989172018729],
              [-39.398157243667306, -7.269026355015313],
              [-39.396368148575505, -7.268828960792178],
              [-39.395677688826368, -7.268413636755092],
              [-39.394540891818181, -7.267293067625971],
              [-39.393766520145391, -7.26501425778235],
              [-39.393327820513022, -7.263449749888879],
              [-39.392942531277839, -7.262165151346663],
              [-39.39278232115803, -7.261233873847586],
              [-39.392683135646642, -7.260247787728078],
              [-39.392412306544465, -7.259027078891001],
              [-39.39207661044582, -7.258385256504492],
              [-39.391904944898791, -7.258095824117992],
              [-39.391733278222517, -7.257969443746172],
              [-39.391389969719825, -7.257906019858236],
              [-39.390630824771975, -7.258359016520812],
              [-39.390382882807806, -7.259282191745764],
              [-39.390199761468033, -7.260096144682029],
              [-39.389948001351719, -7.261245329538248],
              [-39.389883158355047, -7.261824192988829],
              [-39.389757265836259, -7.262349203716623],
              [-39.389574168546311, -7.263099735036389],
              [-39.389452095100779, -7.263497899533797],
              [-39.388864616835846, -7.26406866062021],
              [-39.388456463608755, -7.26424081625719],
              [-39.387090794461685, -7.262830814698425],
              [-39.386373652330249, -7.260614938523262],
              [-39.386274442962097, -7.2594481304968],
              [-39.386297330852315, -7.258118688549906],
              [-39.386377447470686, -7.256617137688705],
              [-39.385770899183775, -7.254862848813268],
              [-39.382764913768249, -7.250875990540686],
              [-39.381570911209664, -7.249701079701446],
              [-39.381399268669483, -7.249402566370738],
              [-39.38111316570869, -7.249403055142078],
              [-39.380586735196346, -7.249738238700313],
              [-39.380411250311525, -7.249909928523925],
              [-39.38033495193892, -7.251068169461387],
              [-39.381334400688203, -7.253753712015187],
              [-39.381597616479787, -7.255436009053543],
              [-39.381864672608884, -7.256657184882681],
              [-39.381914242840026, -7.257181705751304],
              [-39.381971472339103, -7.25729900608746],
              [-39.382074453960222, -7.258112983248704],
              [-39.38210879656841, -7.259560656525555],
              [-39.382249938813366, -7.261767452298958],
              [-39.382230868895178, -7.263097359246061],
              [-39.382669546570561, -7.26460750146601],
              [-39.384080989831041, -7.266722771798771],
              [-39.38550007027326, -7.268539993939775],
              [-39.386911490633551, -7.270528883038518],
              [-39.388158905435539, -7.272173973085841],
              [-39.38837252785008, -7.273512472167824],
              [-39.388448826467346, -7.275827953238936],
              [-39.388189452842774, -7.277682870827245],
              [-39.38751040148351, -7.280451395564263],
              [-39.387380714717807, -7.28131971521603],
              [-39.387312029517219, -7.282188034916209],
              [-39.387300597095255, -7.282821312081714],
              [-39.38729680284176, -7.283174143947315],
              [-39.386415584533424, -7.283916571764046],
              [-39.38542758931699, -7.284243211007697],
              [-39.384611237870125, -7.284578464335308],
              [-39.383337117830244, -7.284733413389211],
              [-39.382063020697082, -7.285005685734339],
              [-39.381010159619684, -7.285739522466555],
              [-39.379720789905129, -7.28688013755372],
              [-39.37860308500958, -7.287966830102711],
              [-39.378084291765667, -7.287894839126156],
              [-39.377336601290423, -7.287651181005211],
              [-39.376241784383502, -7.287290200317817],
              [-39.375028712003157, -7.287327406131146],
              [-39.374094106220241, -7.287889110996548],
              [-39.373560038337182, -7.288757919324159],
              [-39.373350235084246, -7.290956146877575],
              [-39.373331142255779, -7.292168265539953],
              [-39.37325868498759, -7.293154350525522],
              [-39.372957333401864, -7.29407703671259],
              [-39.37230882843491, -7.2948194887129],
              [-39.370966046425458, -7.295553325214136],
              [-39.370096307410954, -7.295825108882005],
              [-39.368234731151418, -7.296496034351288],
              [-39.366376974145595, -7.296814570952241],
              [-39.364812910132699, -7.297023932445869],
              [-39.363359557100459, -7.29752219028201],
              [-39.36283310249415, -7.297975187127338],
              [-39.36183365294405, -7.298826347191679],
              [-39.361120282885793, -7.300264939934217],
              [-39.3601818809869, -7.30100739070365],
              [-39.359888164814571, -7.301342621445146],
              [-39.358781893343654, -7.301795616829845],
              [-39.357908334306998, -7.302130824110921],
              [-39.357381904751193, -7.302520420339048],
              [-39.357084346505658, -7.30298198431421],
              [-39.357126325420815, -7.303967604393166],
              [-39.358209687800489, -7.305088662481436],
              [-39.359014583728793, -7.305503986612071],
              [-39.360227678063261, -7.305521122472546],
              [-39.361909951412471, -7.305085798439016],
              [-39.363588429508134, -7.305002818274017],
              [-39.365034192047048, -7.305191667282263],
              [-39.366415111990868, -7.305452972801358],
              [-39.367685412781476, -7.305641798670454],
              [-39.369474508815316, -7.305902616517194],
              [-39.370615100744864, -7.307077551327816],
              [-39.370775311996233, -7.307837628864836],
              [-39.370473958459755, -7.308696891435944],
              [-39.370180219366489, -7.308932446779806],
              [-39.370061964166446, -7.308986812357475],
              [-39.369711018351417, -7.309384976673828],
              [-39.369119743975546, -7.310127405719917],
              [-39.368181320031852, -7.310924199161607],
              [-39.36775407492344, -7.312371873079182],
              [-39.367216188892769, -7.313457610094494],
              [-39.366502841831192, -7.314896737836215],
              [-39.366140463815896, -7.315647758012584],
              [-39.365667421503012, -7.316507486444387],
              [-39.365198220574641, -7.316851284994875],
              [-39.363927918698039, -7.316771190467454],
              [-39.362485974327463, -7.316338217934483],
              [-39.360994413585402, -7.315561447438579],
              [-39.358759005523716, -7.314314987187962],
              [-39.357606980942094, -7.314062271797771],
              [-39.357141575217796, -7.314117103096335],
              [-39.356847835995119, -7.314515267457784],
              [-39.356073464369729, -7.315954373149703],
              [-39.355829318425187, -7.316813635765677],
              [-39.355978097063819, -7.318442032049163],
              [-39.357507795608036, -7.320376554583826],
              [-39.359060380051297, -7.320981194168747],
              [-39.360330658926891, -7.321061777693676],
              [-39.361139374101832, -7.321133301905754],
              [-39.361528504394592, -7.322299646172699],
              [-39.361517047970253, -7.322932876339926],
              [-39.361269083950305, -7.323919498184932],
              [-39.361082167502509, -7.324896503136673],
              [-39.361818401406047, -7.325954138337313],
              [-39.363771525899075, -7.326676077535008],
              [-39.365152445660819, -7.327163392693464],
              [-39.366350267178625, -7.32833832855498],
              [-39.36713991356347, -7.329568094760475],
              [-39.368429283262962, -7.332199270905368],
              [-39.368810754420444, -7.333601170747505],
              [-39.369199837762807, -7.334704114400482],
              [-39.368093588245529, -7.335211917452649],
              [-39.366262513302424, -7.333964991518289],
              [-39.364595490863863, -7.333124820260088],
              [-39.364481054730412, -7.333061886548742],
              [-39.363329008098333, -7.332872571772619],
              [-39.362230395991048, -7.332801046328092],
              [-39.361356814953034, -7.333136742604691],
              [-39.360948638596419, -7.333471482258277],
              [-39.361494138025968, -7.335570035024819],
              [-39.362447812725541, -7.337441624488024],
              [-39.363527378990554, -7.338734348546603],
              [-39.365289769871751, -7.340668406280105],
              [-39.366663075223116, -7.341734608941795],
              [-39.367460311015755, -7.342385488650338],
              [-39.368486489849616, -7.343443123087214],
              [-39.36916551729724, -7.34432860228599],
              [-39.369268498823075, -7.345251287800217],
              [-39.368845072964987, -7.346346105855098],
              [-39.36785323653725, -7.346971234290019],
              [-39.365533915779487, -7.347344159185844],
              [-39.363638020069196, -7.346386641449141],
              [-39.360033123780134, -7.343892765620508],
              [-39.358774300518988, -7.34294435133956],
              [-39.356939407374227, -7.34198685765381],
              [-39.356195536056553, -7.341517167895037],
              [-39.356081076923786, -7.341453744186535],
              [-39.356023869425833, -7.341336443848953],
              [-39.355680538019577, -7.341155720665752],
              [-39.354761204866435, -7.340794762110702],
              [-39.354009697284766, -7.340722747945441],
              [-39.35302552117421, -7.340886312172321],
              [-39.352167213991748, -7.343763544117729],
              [-39.352365561757374, -7.346033762572048],
              [-39.353941035365658, -7.348719328620766],
              [-39.354444578743909, -7.350003438272716],
              [-39.354478921425255, -7.351341937221682],
              [-39.354002083093434, -7.35225603132351],
              [-39.352434223921207, -7.352700436387661],
              [-39.350763406238585, -7.35220406304757],
              [-39.350076742375819, -7.351906505294554],
              [-39.349725795835759, -7.351960871685838],
              [-39.348844600388865, -7.352874965463953],
              [-39.348192278171027, -7.353961192386103],
              [-39.34795576853157, -7.3543684137391],
              [-39.347482750543016, -7.354820944629425],
              [-39.346902908454346, -7.35510180747044],
              [-39.346506187705529, -7.354577286319089],
              [-39.345945437641952, -7.353410942910666],
              [-39.34560974260269, -7.352705697899178],
              [-39.345567763736419, -7.351665711143238],
              [-39.345941619506007, -7.349874217256309],
              [-39.346132354073966, -7.348715975400546],
              [-39.346208653363398, -7.347676012739488],
              [-39.346227722206287, -7.346454814126894],
              [-39.345964507432342, -7.344772541072632],
              [-39.345121449808907, -7.343072617662391],
              [-39.344614088080668, -7.342258641171708],
              [-39.344591200917407, -7.340169145769371],
              [-39.344961239523315, -7.338613229459274],
              [-39.345220636063338, -7.336876100155353],
              [-39.344896395491332, -7.335483258307204],
              [-39.344205935749514, -7.335067934264438],
              [-39.343221736690928, -7.335168074874737],
              [-39.342054462525752, -7.335784146223377],
              [-39.340559082541105, -7.335351172645355],
              [-39.339761800887594, -7.334528627976894],
              [-39.338613572513694, -7.333986457335587],
              [-39.337862087882236, -7.333977912787942],
              [-39.337030508808809, -7.335236248525209],
              [-39.33695800539968, -7.336394023475235],
              [-39.337732377238801, -7.338437743750848],
              [-39.337957454213004, -7.338962265762937],
              [-39.338010842643847, -7.339197355385864],
              [-39.33806045886989, -7.339721877258019],
              [-39.33775526422162, -7.340753271786279],
              [-39.337034280981641, -7.342481808772964],
              [-39.336324751218129, -7.34351317997873],
              [-39.335916575949128, -7.343739701285006],
              [-39.33577545660787, -7.345422464011279],
              [-39.335455012415572, -7.347267836612856],
              [-39.335207047300173, -7.348371734749492],
              [-39.33485610053598, -7.348706452448149],
              [-39.334215234021372, -7.348815650276826],
              [-39.333585799708032, -7.348571992245132],
              [-39.333009776100816, -7.348219602912742],
              [-39.332731310559048, -7.347631658240442],
              [-39.331876821358811, -7.346755235850041],
              [-39.330896440513563, -7.346556863264948],
              [-39.329046296736777, -7.346531111942882],
              [-39.326440873270386, -7.346605036415836],
              [-39.325414718302866, -7.345719532944128],
              [-39.32444958803773, -7.344661921560958],
              [-39.323873564249787, -7.344535540864364],
              [-39.32254987408681, -7.344048225750926],
              [-39.322450688721851, -7.342890449664591],
              [-39.322591829873645, -7.341442776521241],
              [-39.322801634273333, -7.339072884003421],
              [-39.322763496660102, -7.33768004138649],
              [-39.322664311200434, -7.336639589592438],
              [-39.322225633546402, -7.335120413419795],
              [-39.320974401466486, -7.33388203190358],
              [-39.319189124648126, -7.333331293807857],
              [-39.317461054327609, -7.33289832204645],
              [-39.315477428544632, -7.330321953032547],
              [-39.315156984132692, -7.328694045613858],
              [-39.315053978655747, -7.327707936490551],
              [-39.314882337025203, -7.327527213445915],
              [-39.314138465666581, -7.32711142336611],
              [-39.313161903019186, -7.326632676481636],
              [-39.311834371515459, -7.326606435583244],
              [-39.311250734406258, -7.326950723037942],
              [-39.311002769395714, -7.327927751936143],
              [-39.310983700512686, -7.329203293212813],
              [-39.31097988144586, -7.329321082497967],
              [-39.311220210135104, -7.332568353067035],
              [-39.311368964909477, -7.334024129389202],
              [-39.311643636254566, -7.33489244936153],
              [-39.311697047496949, -7.335363092560411],
              [-39.312200591020691, -7.336466013297962],
              [-39.312540105358551, -7.336764526765063],
              [-39.31252483063561, -7.337804512469562],
              [-39.311746639756407, -7.339596450022765],
              [-39.310064367698359, -7.339678965182531],
              [-39.308790247713013, -7.339779082561433],
              [-39.30734830312079, -7.3396355652786],
              [-39.306787528963149, -7.338586522169828],
              [-39.305666007791778, -7.33630771104524],
              [-39.305170100633532, -7.334679780487185],
              [-39.304853474383151, -7.332816783492978],
              [-39.304300337683514, -7.331125429273741],
              [-39.303518329754525, -7.3292624319101],
              [-39.303121607918719, -7.328846619107289],
              [-39.302373919544578, -7.328485661693565],
              [-39.300645872089433, -7.328224377896895],
              [-39.299188653949713, -7.328903848629797],
              [-39.299055125028751, -7.329998200908944],
              [-39.299718901707287, -7.331978964465946],
              [-39.299798996414644, -7.334131905518059],
              [-39.299760858654821, -7.336673466128056],
              [-39.299444231663252, -7.338238905415505],
              [-39.29776575453004, -7.33838433119855],
              [-39.29632762814375, -7.337951358668652],
              [-39.294713994054121, -7.337464066327393],
              [-39.293161431572187, -7.336922362363915],
              [-39.291379949782922, -7.336308176656289],
              [-39.289705313941653, -7.336055927846521],
              [-39.287855194223319, -7.335966776911166],
              [-39.286668804182689, -7.337857899534784],
              [-39.287454631450359, -7.339259821687434],
              [-39.288644815541829, -7.341022677981588],
              [-39.289712927417284, -7.342894756519164],
              [-39.289808294715918, -7.344169808889524],
              [-39.288869893735189, -7.345020968997101],
              [-39.28672982908779, -7.344931794828741],
              [-39.28321268566453, -7.344355304507315],
              [-39.281763104943273, -7.344446387800591],
              [-39.281065008703763, -7.344727252546185],
              [-39.280950571477526, -7.344781595126437],
              [-39.280019783907058, -7.345053401741101],
              [-39.278562565769882, -7.345732895471643],
              [-39.277811057100223, -7.345778670595517],
              [-39.277471566901085, -7.345308491166676],
              [-39.27750206731173, -7.343273362492587],
              [-39.277597435620585, -7.340785702606333],
              [-39.27734185697954, -7.338867850996485],
              [-39.277063391618967, -7.338053896695246],
              [-39.276098284356927, -7.336996262320299],
              [-39.274366395934592, -7.336734955520308],
              [-39.272954951828503, -7.338219346421265],
              [-39.2731571419787, -7.343728267278011],
              [-39.273744597314973, -7.346812440102875],
              [-39.273767484341356, -7.349073601453396],
              [-39.273107548902814, -7.350512241231091],
              [-39.27169992299951, -7.351707199884856],
              [-39.270181656125686, -7.352603645254448],
              [-39.269590382652979, -7.3534724305312],
              [-39.270185474351571, -7.356032104535426],
              [-39.270559355006327, -7.358121601205718],
              [-39.270589832655993, -7.358287050230158],
              [-39.270570762697382, -7.358405327500112],
              [-39.270486850828078, -7.358989943899909],
              [-39.27018547437855, -7.359740963120349],
              [-39.269594201057814, -7.360419525547045],
              [-39.268034001266727, -7.360338895787497],
              [-39.266481415747592, -7.359851581492062],
              [-39.26412772947301, -7.358713363490967],
              [-39.262235652800342, -7.357701038098398],
              [-39.260099407233618, -7.357485505695009],
              [-39.259611136423899, -7.359104845516526],
              [-39.259550088842794, -7.362868045721393],
              [-39.259214392824745, -7.365880250247335],
              [-39.258749011129453, -7.369643451131819],
              [-39.25835990382474, -7.372239796130206],
              [-39.257932659714406, -7.373687516039687],
              [-39.257108670961664, -7.374483821568105],
              [-39.255907054214056, -7.373652707628768],
              [-39.255197525295003, -7.371147400201489],
              [-39.254598615490778, -7.368759392154939],
              [-39.254083639590625, -7.368235335910479],
              [-39.252367024667613, -7.367223010656423],
              [-39.250276553305291, -7.367594957736666],
              [-39.247377389597865, -7.36812141223619],
              [-39.24580191726686, -7.368854759543368],
              [-39.243013371530914, -7.369679679326741],
              [-39.241239527113386, -7.368549587739975],
              [-39.239728920628501, -7.365158310035096],
              [-39.238615011647795, -7.362590067673234],
              [-39.237657541751297, -7.361016503423119],
              [-39.237127293106141, -7.357814518362125],
              [-39.237050994556753, -7.355435590687595],
              [-39.236810666873936, -7.352188320134564],
              [-39.236650455670365, -7.351374342929797],
              [-39.236154549285317, -7.350035866629667],
              [-39.235704417297946, -7.34904119028696],
              [-39.235033026173895, -7.347693633902542],
              [-39.233957302119975, -7.346120069563464],
              [-39.233087515118527, -7.346391876225977],
              [-39.232370349187015, -7.347713191661534],
              [-39.23180578195668, -7.350544649084211],
              [-39.231859192418312, -7.354488573271968],
              [-39.23262213263348, -7.357401103265836],
              [-39.232713681631658, -7.359083400164961],
              [-39.233663538299744, -7.361009821273658],
              [-39.234388340626658, -7.362872817586974],
              [-39.234765992666247, -7.36444638337373],
              [-39.234636282897, -7.365314703019704],
              [-39.233541464924016, -7.365061988672918],
              [-39.232568721397513, -7.364411574828547],
              [-39.232164364338246, -7.364348151889012],
              [-39.231397604947354, -7.365434400707857],
              [-39.231538747401018, -7.367351765226896],
              [-39.232309323978498, -7.369739283406991],
              [-39.232519128361069, -7.371132614150524],
              [-39.231462448092749, -7.372154952124334],
              [-39.229440661708985, -7.371948011758541],
              [-39.226976380373799, -7.370637661710947],
              [-39.225675555267536, -7.368648284700488],
              [-39.224828702357733, -7.367355093843831],
              [-39.224378570278354, -7.366478182787182],
              [-39.223806366556857, -7.366243116751524],
              [-39.223630881818437, -7.36623403566619],
              [-39.222352965640582, -7.366623632288921],
              [-39.221303923783246, -7.367067570765843],
              [-39.220766037706639, -7.368216755386065],
              [-39.221315355329345, -7.370025385887394],
              [-39.222848871996518, -7.371734388778829],
              [-39.223749136247754, -7.373371354600395],
              [-39.224477733871268, -7.37482715338061],
              [-39.22473333527207, -7.377034437239374],
              [-39.225034687829719, -7.379883543255161],
              [-39.225229241483966, -7.382316836700382],
              [-39.225320789753823, -7.383655359682022],
              [-39.224164947942675, -7.383746396205911],
              [-39.222734435112862, -7.38257099516607],
              [-39.22115896253969, -7.379876396195832],
              [-39.219911547684241, -7.378348116437789],
              [-39.217599840288614, -7.378249885181776],
              [-39.215871770990312, -7.377816446419893],
              [-39.213788958901397, -7.37779022891189],
              [-39.212335534986359, -7.378171211445038],
              [-39.211915928158056, -7.379202604872177],
              [-39.211671805438478, -7.379781493176194],
              [-39.210721924986821, -7.38132884205898],
              [-39.209310504834932, -7.382876166572377],
              [-39.207612958062931, -7.384007701370795],
              [-39.2062320383478, -7.387228755787459],
              [-39.206045121942545, -7.388151419058619],
              [-39.205518691342789, -7.388603948900564],
              [-39.204587926866878, -7.388767023168832],
              [-39.204183546714255, -7.388821855513278],
              [-39.203256553407819, -7.388740807260725],
              [-39.202463112837215, -7.387800472311314],
              [-39.202661461626235, -7.386298432553284],
              [-39.203313783790719, -7.3852670613127],
              [-39.203618955488317, -7.384172220177954],
              [-39.203424401608615, -7.382019767039386],
              [-39.202131236823533, -7.379568825827746],
              [-39.201753584925392, -7.377823128084129],
              [-39.201795540621958, -7.375335445164767],
              [-39.201478913475221, -7.373354681893109],
              [-39.200452758472515, -7.372532136048516],
              [-39.199235868239008, -7.372451064566225],
              [-39.197935065980012, -7.374396554757337],
              [-39.1970958276384, -7.376070259961635],
              [-39.195650063860356, -7.37621570892561],
              [-39.192506732496184, -7.377266173057179],
              [-39.189893671514795, -7.378099684924893],
              [-39.186132382515588, -7.377974746672717],
              [-39.183534573269235, -7.377758725902252],
              [-39.181569992487383, -7.377551320586285],
              [-39.180017407983954, -7.377063516296491],
              [-39.179628323652175, -7.375960106662194],
              [-39.179532956219347, -7.374856697262845],
              [-39.179330766152681, -7.373002291323021],
              [-39.179243059223225, -7.371202229146228],
              [-39.179319311700212, -7.369926686926499],
              [-39.179059913903799, -7.368244389900635],
              [-39.178312247635702, -7.367765178203912],
              [-39.177160200984623, -7.367629763103632],
              [-39.176340031307276, -7.368308768339103],
              [-39.175832692848317, -7.370914216164977],
              [-39.174913336835509, -7.374143838884792],
              [-39.174299175327775, -7.376514174049309],
              [-39.173845225260166, -7.379399556225697],
              [-39.173486642178986, -7.380204919065481],
              [-39.172487192406358, -7.381354102312223],
              [-39.171838689544167, -7.381978765013574],
              [-39.170911720051883, -7.382132805339913],
              [-39.16848555272027, -7.381744629748285],
              [-39.168214724543347, -7.380641220209129],
              [-39.168126994470356, -7.379021880938936],
              [-39.168039262592927, -7.377158417146599],
              [-39.168020171680979, -7.37472510085859],
              [-39.167840891515894, -7.371541252986337],
              [-39.167692112884858, -7.369913321721422],
              [-39.165643620097541, -7.367969252854081],
              [-39.163171702607833, -7.366938813119051],
              [-39.160859995313302, -7.366731384529819],
              [-39.159425664103743, -7.366071936377748],
              [-39.157979901513002, -7.365990864714896],
              [-39.15659134437535, -7.366081948055395],
              [-39.155485073030228, -7.366407609200792],
              [-39.151372837091962, -7.366861559170645],
              [-39.150621329519971, -7.366798624952045],
              [-39.148778823059466, -7.366355641164402],
              [-39.146875292028362, -7.36592220227054],
              [-39.144922166729287, -7.365018119187718],
              [-39.143827349665465, -7.364891762079706],
              [-39.143480222156555, -7.364883170854878],
              [-39.142949973589189, -7.365335677691364],
              [-39.143556497800695, -7.367144308221641],
              [-39.145109084066853, -7.36792205775046],
              [-39.146825698015029, -7.368880017319275],
              [-39.148889464203329, -7.370182265080985],
              [-39.150422957766118, -7.37200903324432],
              [-39.151491091579274, -7.373934988436503],
              [-39.152784257585701, -7.376105555328548],
              [-39.153501422589834, -7.378430139829095],
              [-39.153707431947588, -7.379886404178321],
              [-39.153749387761771, -7.380990303529601],
              [-39.152974993243348, -7.382311129915198],
              [-39.151929768504793, -7.382582914434249],
              [-39.150545030522657, -7.38244749914668],
              [-39.149690541348356, -7.381561553826519],
              [-39.148565200092278, -7.379453897686229],
              [-39.147516158029042, -7.376424067191223],
              [-39.146898178386898, -7.375257258762129],
              [-39.145406641734802, -7.374416598660845],
              [-39.145063309242296, -7.374353641767832],
              [-39.144891642608791, -7.374181509673019],
              [-39.144777206478231, -7.374118575962666],
              [-39.144258412386662, -7.37387442902671],
              [-39.143461130607221, -7.373232140279251],
              [-39.143232257484385, -7.372933628904132],
              [-39.142724895724086, -7.37217401709881],
              [-39.142114553352691, -7.370600452140635],
              [-39.141374500221225, -7.369886149875677],
              [-39.13976470827096, -7.3692262135876],
              [-39.138093867620007, -7.368738386208754],
              [-39.136533645859181, -7.368648280509574],
              [-39.134515700606478, -7.36826872019838],
              [-39.133428472959956, -7.36755485965491],
              [-39.131757633172519, -7.367238744236829],
              [-39.130830663727991, -7.367338395893776],
              [-39.129880807469782, -7.368659247135096],
              [-39.128953838099065, -7.372413413655623],
              [-39.128789808816606, -7.37547993695129],
              [-39.128774535135683, -7.376466069966531],
              [-39.128404519769418, -7.37773250800088],
              [-39.127763653175457, -7.377959005117225],
              [-39.125257392008386, -7.375598657411582],
              [-39.124231237158625, -7.374594923672013],
              [-39.122167494750975, -7.373573540211476],
              [-39.12021055220287, -7.37302184699156],
              [-39.119398018939876, -7.373121963736543],
              [-39.118856291847457, -7.374334081961238],
              [-39.118825813580067, -7.376188511703312],
              [-39.120366943624511, -7.381081804294529],
              [-39.120847600087068, -7.383695844846366],
              [-39.120607271437457, -7.384157408858857],
              [-39.119043253424209, -7.384420182017214],
              [-39.117483031754304, -7.384221320974543],
              [-39.115575681526117, -7.384077314322328],
              [-39.114301584525101, -7.384231308371277],
              [-39.112596401413818, -7.385877866035043],
              [-39.111642726761552, -7.387723704092435],
              [-39.11160836138167, -7.389749775793486],
              [-39.111635089701707, -7.391604205583522],
              [-39.111684682881851, -7.392183093117228],
              [-39.111669432148375, -7.393232601748979],
              [-39.11054027185331, -7.394951616434803],
              [-39.108636740765796, -7.398335255419854],
              [-39.107843276455853, -7.400813857774724],
              [-39.108079786195198, -7.404006786629759],
              [-39.108785519704078, -7.407037058847289],
              [-39.109029665713862, -7.409823231174145],
              [-39.109224195544513, -7.412030025969858],
              [-39.109029664866775, -7.413359933762613],
              [-39.107202409254548, -7.415468031534449],
              [-39.105512499773305, -7.416192345793434],
              [-39.103021513408621, -7.416446038258466],
              [-39.101636775486085, -7.416247200351061],
              [-39.09993159125694, -7.414312188698407],
              [-39.099225881750669, -7.41128187048079],
              [-39.098989373057421, -7.408034598954669],
              [-39.098485829352022, -7.403448388325026],
              [-39.098188271816845, -7.400544915671536],
              [-39.09805476677905, -7.398048197686905],
              [-39.098367597744677, -7.396546625032456],
              [-39.09926020164545, -7.394935876503404],
              [-39.100519071706969, -7.392348542183018],
              [-39.101224781342616, -7.391489279952469],
              [-39.101468952145851, -7.390801683312919],
              [-39.101190439793584, -7.389987729020822],
              [-39.099759950713235, -7.389146579972574],
              [-39.098379007944104, -7.388721734442288],
              [-39.096998088320014, -7.388116140007227],
              [-39.095804084908849, -7.386822949887748],
              [-39.096006274802932, -7.385149244189958],
              [-39.097745777555332, -7.384886518171714],
              [-39.099759950637377, -7.385501169057301],
              [-39.101606229110757, -7.385880730233269],
              [-39.10363567478786, -7.385617979446993],
              [-39.106714141352718, -7.384857437514572],
              [-39.108579534851586, -7.383789302475789],
              [-39.108789338973736, -7.381763230917096],
              [-39.107931031598376, -7.38116625184798],
              [-39.106946855747104, -7.381039870827448],
              [-39.106832395573988, -7.381030813790889],
              [-39.106717959487483, -7.380913513410132],
              [-39.106607318417723, -7.380741358364707],
              [-39.105913040534226, -7.380560634905108],
              [-39.103494534258729, -7.380054716038685],
              [-39.101880899205291, -7.379566912705641],
              [-39.101716869800612, -7.379041947755407],
              [-39.101938129534823, -7.376147509470214],
              [-39.102701069621951, -7.375459913245608],
              [-39.106180074216695, -7.374825703865021],
              [-39.109193696782768, -7.37464402637098],
              [-39.111574065224268, -7.374037501945582],
              [-39.114263424323553, -7.372345193344824],
              [-39.11619748075131, -7.370752559537087],
              [-39.117662335219791, -7.369558089942495],
              [-39.119123348527367, -7.368599175917549],
              [-39.120702640176845, -7.367404706413968],
              [-39.121240502151608, -7.366373288091823],
              [-39.121045972404367, -7.364048214023823],
              [-39.120225803597947, -7.361144765962665],
              [-39.119863402195151, -7.358639456855051],
              [-39.11933315335483, -7.355681619357244],
              [-39.119130986290727, -7.353818132489621],
              [-39.119157714486334, -7.352081493035941],
              [-39.11986722113685, -7.351167398140661],
              [-39.12131680192595, -7.350959014557137],
              [-39.123216537842822, -7.351564584398919],
              [-39.125345123970192, -7.352359982272874],
              [-39.127366933382199, -7.352504454006764],
              [-39.128702078129074, -7.3521239383754],
              [-39.12902631861423, -7.349862287338541],
              [-39.128843221285948, -7.346904473117581],
              [-39.127778904578527, -7.344806385986619],
              [-39.125257393817968, -7.343314358024827],
              [-39.122922799318189, -7.341071799762253],
              [-39.122026354177692, -7.339262656016725],
              [-39.122499373245802, -7.338692848846746],
              [-39.12534894369233, -7.337633304533462],
              [-39.128019233047446, -7.337044894221547],
              [-39.13195982516757, -7.336636718826036],
              [-39.133417043271756, -7.335966771044343],
              [-39.134530952157391, -7.334890067455294],
              [-39.135530355783501, -7.333912549174031],
              [-39.135892802874103, -7.333053286670116],
              [-39.135755456564759, -7.330909425556069],
              [-39.135629564871444, -7.327888186770437],
              [-39.134954356540696, -7.3230668624636],
              [-39.134195234520313, -7.319864901275263],
              [-39.133417043570901, -7.318001902954613],
              [-39.132066625047415, -7.31572262770573],
              [-39.13105192571085, -7.313959258595466],
              [-39.130288985434674, -7.311109687272802],
              [-39.130216505021735, -7.308504727014387],
              [-39.129686256221845, -7.30547440897485],
              [-39.128900429094607, -7.303900842898147],
              [-39.127698813261539, -7.303186541280914],
              [-39.126554403155559, -7.302300106753367],
              [-39.124551685529553, -7.300989268122429],
              [-39.123517893359328, -7.300446632597374],
              [-39.12213697353743, -7.300076127753816],
              [-39.120462315681813, -7.299877756633918],
              [-39.119359861357822, -7.300149563113572],
              [-39.118242156497857, -7.301235323642513],
              [-39.117586039104509, -7.302610051782981],
              [-39.116975672959562, -7.304519334716502],
              [-39.116380580358708, -7.305604582661712],
              [-39.115785488750177, -7.306699401548837],
              [-39.114145172501892, -7.307948237674757],
              [-39.113218203008607, -7.308111334952017],
              [-39.112356076517727, -7.307686441847962],
              [-39.112256891103549, -7.306582590466831],
              [-39.110544094398733, -7.305280342013819],
              [-39.10990704600939, -7.305217384892116],
              [-39.108808387803229, -7.305308003473578],
              [-39.107305418534594, -7.305344720062514],
              [-39.105909248271971, -7.305670869979552],
              [-39.104577898732295, -7.305761465377179],
              [-39.103441124897067, -7.30446827432605],
              [-39.102826964356105, -7.303129310966332],
              [-39.103189365449673, -7.302260990522425],
              [-39.103738706979065, -7.300415641155807],
              [-39.103773002339416, -7.298389080482398],
              [-39.103387714115293, -7.297104946972042],
              [-39.101846583130907, -7.295866124274401],
              [-39.100740311743714, -7.29625523003911],
              [-39.098420991013406, -7.296681031623276],
              [-39.096502185273003, -7.297287114429252],
              [-39.094320187862124, -7.299856777107777],
              [-39.09378612091237, -7.300833805755285],
              [-39.092389950651949, -7.301159978672477],
              [-39.091352340283102, -7.30090677438987],
              [-39.091215016871665, -7.29888070258054],
              [-39.092233534448098, -7.296519400719418],
              [-39.093820464302247, -7.294981109310772],
              [-39.096719626671636, -7.291027662599506],
              [-39.097894560110618, -7.289950959063063],
              [-39.100038420054027, -7.289579500009327],
              [-39.102006795075184, -7.289434540442015],
              [-39.105253108205325, -7.288963429862734],
              [-39.107412218499313, -7.288013083334737],
              [-39.108526127290816, -7.28704462309209],
              [-39.110620393999525, -7.286157700127548],
              [-39.11298169447344, -7.286944016216948],
              [-39.115953337504855, -7.289087389552025],
              [-39.117849254411389, -7.289746860045729],
              [-39.119180581860107, -7.289774054931271],
              [-39.119821448538175, -7.289438825471419],
              [-39.12082089815037, -7.288470853134429],
              [-39.121892828888292, -7.2866884387972],
              [-39.122266660629506, -7.284897432961622],
              [-39.121480833506389, -7.283314321950116],
              [-39.120050322403316, -7.282482719869612],
              [-39.118783861804424, -7.28205784845006],
              [-39.117803480929048, -7.281922434499179],
              [-39.117284664649958, -7.28191340014503],
              [-39.116075410548788, -7.281606296055993],
              [-39.113016013763165, -7.281209086055892],
              [-39.111402403607343, -7.280838583033356],
              [-39.110834017191117, -7.280187214539796],
              [-39.110784423960688, -7.279662692685045],
              [-39.111154461405576, -7.278278443380422],
              [-39.111410040854977, -7.276659592410091],
              [-39.111547340922606, -7.275329685592518],
              [-39.111272716488472, -7.274570073988268],
              [-39.110349542197348, -7.274443227031564],
              [-39.109014397633374, -7.274597734042232],
              [-39.107614409034184, -7.275384982159858],
              [-39.10591304499944, -7.276923273479768],
              [-39.104669472544572, -7.278642265062937],
              [-39.103914144737985, -7.278913582820588],
              [-39.102235690640519, -7.279058542618221],
              [-39.100263474510641, -7.279375635139258],
              [-39.09898174120142, -7.279991240391535],
              [-39.097387199167116, -7.281828020982042],
              [-39.09555996658483, -7.283935630744544],
              [-39.093130004080194, -7.287609191523322],
              [-39.091596486479993, -7.289608580174113],
              [-39.090421553034794, -7.290694341654931],
              [-39.08936489697205, -7.29149018098932],
              [-39.088495133935432, -7.291825411267742],
              [-39.086637377039054, -7.292079080261935],
              [-39.085012310419536, -7.292404764002433],
              [-39.081758360943496, -7.293391339444641],
              [-39.080068451380946, -7.294232953000795],
              [-39.078412885563004, -7.296350108838269],
              [-39.077157833453676, -7.299000865751752],
              [-39.076726793215897, -7.300674082251693],
              [-39.075841780830984, -7.3015881770025],
              [-39.074796533016993, -7.301968227869396],
              [-39.073465206567839, -7.301950089928138],
              [-39.072397095744833, -7.300023669782468],
              [-39.071008539420006, -7.296577560613835],
              [-39.070539339308247, -7.293384190637909],
              [-39.070684299779899, -7.29147539534554],
              [-39.071599859460775, -7.288643938265315],
              [-39.072114835281639, -7.285469147955425],
              [-39.072316979299124, -7.283614741379238],
              [-39.071374760027872, -7.281108944859356],
              [-39.070974221132708, -7.280810455357269],
              [-39.070279942113139, -7.280801374865767],
              [-39.068593851754976, -7.281353556183468],
              [-39.06735025516992, -7.283244236717804],
              [-39.06620966329632, -7.285831548101229],
              [-39.065374243144049, -7.287270164703473],
              [-39.06402382473842, -7.288636300340191],
              [-39.062501763791332, -7.289713003599708],
              [-39.059919204490875, -7.29199276871451],
              [-39.05903039472809, -7.293422328329438],
              [-39.059171537292777, -7.295222391506067],
              [-39.061464175779278, -7.296597117964168],
              [-39.063764427627078, -7.297446835618048],
              [-39.065149166506259, -7.297700039172898],
              [-39.066705570105988, -7.298016177481127],
              [-39.067449441404463, -7.298486821209869],
              [-39.067590605831896, -7.300458550346871],
              [-39.06680857406559, -7.30218662323512],
              [-39.064638009081882, -7.304122565764366],
              [-39.06292900778444, -7.306067588602862],
              [-39.061975332297628, -7.307732237740524],
              [-39.061666342507415, -7.308998721805624],
              [-39.061174254519628, -7.310907470826062],
              [-39.060506681889301, -7.312689442477842],
              [-39.060533387176541, -7.314607293853841],
              [-39.061021657063215, -7.316642423883402],
              [-39.061853258345778, -7.318858765085218],
              [-39.062284321641336, -7.320893918070979],
              [-39.063131196639873, -7.322069807602927],
              [-39.064210739971372, -7.323245673319691],
              [-39.064416748295187, -7.324756304312964],
              [-39.064267969657365, -7.326900166181901],
              [-39.064142078013106, -7.327587296911364],
              [-39.064027642063778, -7.331070122681438],
              [-39.064340450160586, -7.333159617247955],
              [-39.064908835620564, -7.333747562130429],
              [-39.065698481890827, -7.335095095578851],
              [-39.065904467306147, -7.336488425286725],
              [-39.065648887942018, -7.337989976971999],
              [-39.064016209012905, -7.338777247899076],
              [-39.061689228022679, -7.339609804000179],
              [-39.060281603157804, -7.340803808637199],
              [-39.059404226860806, -7.34153671449317],
              [-39.058290341208028, -7.342333019776583],
              [-39.057233660965466, -7.3433553567337],
              [-39.056932308663633, -7.343934267981288],
              [-39.058015669963901, -7.345110133706059],
              [-39.059511024997107, -7.345426271975258],
              [-39.061429830594399, -7.344991879134984],
              [-39.062887048780631, -7.344204608069182],
              [-39.064924108848231, -7.343372540735497],
              [-39.066724636207887, -7.343046343144474],
              [-39.067128994253061, -7.343118848025629],
              [-39.067167131014109, -7.344330965701541],
              [-39.066915370851895, -7.345543107147217],
              [-39.066266867906869, -7.346285046130651],
              [-39.06614861361728, -7.346456712995195],
              [-39.065778598157713, -7.347850043247827],
              [-39.065450516618775, -7.350219913609475],
              [-39.065473426650733, -7.352481563907244],
              [-39.065206392810587, -7.35467930236277],
              [-39.06512245725672, -7.356126999514198],
              [-39.066026539703458, -7.357474531059474],
              [-39.067163335526068, -7.358767722120029],
              [-39.06854043718284, -7.35936472359489],
              [-39.069516998803479, -7.359843936465658],
              [-39.069833626003174, -7.361761789086082],
              [-39.069066866853703, -7.36256668559418],
              [-39.067430345725981, -7.363634331816134],
              [-39.066446170697837, -7.363733983428108],
              [-39.064130644223184, -7.363816009086899],
              [-39.06135732617421, -7.363716800480482],
              [-39.058126310832002, -7.363084058746195],
              [-39.055250011274161, -7.362044072765295],
              [-39.053689812563029, -7.361908657345053],
              [-39.05333884381082, -7.36224339903501],
              [-39.052968829400349, -7.363573283672885],
              [-39.052369917543579, -7.365011433471921],
              [-39.053483803508428, -7.367580164777076],
              [-39.055822217145639, -7.369588122460573],
              [-39.057588424121995, -7.371297591499959],
              [-39.05970557790193, -7.372608896234789],
              [-39.061200957607419, -7.373332721037344],
              [-39.062124107934871, -7.373404735336607],
              [-39.06416116796094, -7.372626543679496],
              [-39.066556786924892, -7.371215100147984],
              [-39.067952980268664, -7.370780706894922],
              [-39.069055411681106, -7.370391600133025],
              [-39.07027612019597, -7.370065450083668],
              [-39.072076670693981, -7.369567611537362],
              [-39.073587277131928, -7.369241949715851],
              [-39.074167118963352, -7.36926006506818],
              [-39.075139862524253, -7.369848032278474],
              [-39.075288641344279, -7.371240850952381],
              [-39.075345847959539, -7.374958742457566],
              [-39.07588373320165, -7.377337693464828],
              [-39.076780178236817, -7.379264114502393],
              [-39.077443932032594, -7.381073235066084],
              [-39.078279352366252, -7.383171810013095],
              [-39.078370924499602, -7.384682417930494],
              [-39.077813969821882, -7.386762878876441],
              [-39.077386725678736, -7.388264429434762],
              [-39.076501712148477, -7.389350190147343],
              [-39.075441213926254, -7.390372527103921],
              [-39.075265752951125, -7.390661983209444],
              [-39.074437970054362, -7.391693378297115],
              [-39.072408546390974, -7.391947047137439],
              [-39.070730069438369, -7.391920341957562],
              [-39.06952081630579, -7.391667137525554],
              [-39.068372588055418, -7.391015792555659],
              [-39.06799111791301, -7.389613893748034],
              [-39.067548599113394, -7.388329738178267],
              [-39.066877231953725, -7.387045163428638],
              [-39.065965513146118, -7.386222128689566],
              [-39.064924107673171, -7.386141079349144],
              [-39.063695761699122, -7.387109051500167],
              [-39.062631468223145, -7.388484244313421],
              [-39.059141009350583, -7.389588142832896],
              [-39.056817869614186, -7.390067845068732],
              [-39.054372634194898, -7.391244198983356],
              [-39.0537126987712, -7.392682326737281],
              [-39.054849471724722, -7.393803850846745],
              [-39.056516493197527, -7.394527233794102],
              [-39.057699064082485, -7.396571419348313],
              [-39.059861992970603, -7.398579400907212],
              [-39.059839104921835, -7.400090008733121],
              [-39.059625482592828, -7.402406024525487],
              [-39.059377517534465, -7.403445988024258],
              [-39.059079983114316, -7.404133607618835],
              [-39.058484890688298, -7.40499333593217],
              [-39.057901231507905, -7.405445376725349],
              [-39.055570455404499, -7.406450066770339],
              [-39.052965007924684, -7.406640335531146],
              [-39.051690911165558, -7.40650443233474],
              [-39.050557933419519, -7.405093476979616],
              [-39.049844587494036, -7.402651102212936],
              [-39.049657669983432, -7.399973662293449],
              [-39.049760674423169, -7.397259970826616],
              [-39.048814613032604, -7.39498069388842],
              [-39.046979744090805, -7.393850113276461],
              [-39.045423339456825, -7.393587852622724],
              [-39.044183586058814, -7.39518907792333],
              [-39.042646249561685, -7.397079735231125],
              [-39.042512744632937, -7.398183610431549],
              [-39.042432628002175, -7.399685184263618],
              [-39.043653313725358, -7.402832780157722],
              [-39.044732880042744, -7.404008669893188],
              [-39.04512198641067, -7.405058178842565],
              [-39.045266946969214, -7.406803898376643],
              [-39.045419543850507, -7.408079416767277],
              [-39.045614074638777, -7.41034060221755],
              [-39.045877289530331, -7.411860289217647],
              [-39.04710563456527, -7.414546786920045],
              [-39.047944873050696, -7.416410273299669],
              [-39.047906711460968, -7.41872580123722],
              [-39.047670201828488, -7.419132998580103],
              [-39.046918718273389, -7.419060542416551],
              [-39.045823900368163, -7.418753438399614],
              [-39.04496177483928, -7.418391524901562],
              [-39.042737798440626, -7.416329200617076],
              [-39.042020632269491, -7.414230648761172],
              [-39.040735081695985, -7.411480705399231],
              [-39.038915461303027, -7.409536170729067],
              [-39.037938899594181, -7.409174723138179],
              [-39.036500774434558, -7.408514297990351],
              [-39.035634829700726, -7.408441817736517],
              [-39.033780890875313, -7.408586778373467],
              [-39.033193412629224, -7.40915660944853],
              [-39.032934015100288, -7.410884657764636],
              [-39.033262097759341, -7.412114912569242],
              [-39.03327734751192, -7.414774238460674],
              [-39.032979789317771, -7.415181460753757],
              [-39.031286061740786, -7.416076927968435],
              [-39.027742213356326, -7.417063061150644],
              [-39.025808158993676, -7.418592271330368],
              [-39.026193447146916, -7.419939827470316],
              [-39.027322606827823, -7.421459492163756],
              [-39.028241938944873, -7.421821427707093],
              [-39.028417423679343, -7.421830484792912],
              [-39.028878987275519, -7.421947785452028],
              [-39.029340573907632, -7.42202026538283],
              [-39.029908961453934, -7.422490420988969],
              [-39.030290430451679, -7.424064475754516],
              [-39.03020269959481, -7.425909823499776],
              [-39.029474079099487, -7.428044627976881],
              [-39.028859917783095, -7.430179432546136],
              [-39.028795051876237, -7.430641018696718],
              [-39.028734026124383, -7.430876086223202],
              [-39.028718799577568, -7.431690063278094],
              [-39.028707343105523, -7.43237766010205],
              [-39.028508972310135, -7.433888289788413],
              [-39.027562933130831, -7.435082293793041],
              [-39.02551823669787, -7.436439372926769],
              [-39.024228866162822, -7.437407344025056],
              [-39.022485568313336, -7.437841736991909],
              [-39.020032695764932, -7.439252715470245],
              [-39.01862889010193, -7.440157753857469],
              [-39.018091027032391, -7.441306448464021],
              [-39.01829319326891, -7.442934378761457],
              [-39.020181474624742, -7.444300515001558],
              [-39.023031068000641, -7.443296292377912],
              [-39.026059941223409, -7.442128993886175],
              [-39.028276281090534, -7.44118819436841],
              [-39.029844116206633, -7.440744744315704],
              [-39.031061006649189, -7.440536825553782],
              [-39.032159641713918, -7.44060886400057],
              [-39.033136204324975, -7.441088542879598],
              [-39.033929668825884, -7.442092277436667],
              [-39.034284432755257, -7.445222270760549],
              [-39.032384696103797, -7.44803559118975],
              [-39.031194510876112, -7.450215680025843],
              [-39.030881703095545, -7.451482165102435],
              [-39.030797791337456, -7.453164461844847],
              [-39.029615242609722, -7.454657420849523],
              [-39.027684982309758, -7.456068399748287],
              [-39.026910609743098, -7.457452649740026],
              [-39.027528590506826, -7.458447793210513],
              [-39.030347658509854, -7.459415322622456],
              [-39.032716572348527, -7.459632274217909],
              [-39.034856636931266, -7.459722403397035],
              [-39.036348173651426, -7.46044625121602],
              [-39.036557978115177, -7.461721303664136],
              [-39.036016297783021, -7.463223343131964],
              [-39.035661531960692, -7.463675385108665],
              [-39.035543253757403, -7.463738807629204],
              [-39.035482229106876, -7.463847539921638],
              [-39.03548222996055, -7.46402823982744],
              [-39.034944367033205, -7.464996234532228],
              [-39.034631559199049, -7.466326142227243],
              [-39.035131283436471, -7.467782383813085],
              [-39.03586751839547, -7.468723184710959],
              [-39.036084958176929, -7.469537160958271],
              [-39.035791218953051, -7.469944382255706],
              [-39.032586884939079, -7.471165557275473],
              [-39.03140813323256, -7.472595116668526],
              [-39.031843013838653, -7.474168681492652],
              [-39.033342188788154, -7.474539675457356],
              [-39.034734563068625, -7.474213967553705],
              [-39.035783604818228, -7.473879249427279],
              [-39.035959066604242, -7.473824882898152],
              [-39.036073526827636, -7.473770518319664],
              [-39.03630621715191, -7.473779575452753],
              [-39.036645731387416, -7.474186796261746],
              [-39.036733461438338, -7.475814727477671],
              [-39.036939447552832, -7.477551367131496],
              [-39.038083857716117, -7.478329581345577],
              [-39.04039556401726, -7.478483110284476],
              [-39.042264776489411, -7.477406407312075],
              [-39.043187927717078, -7.477596187909418],
              [-39.04411489719638, -7.477442169590764],
              [-39.044919816227662, -7.477686315763554],
              [-39.045537795894283, -7.478799225527299],
              [-39.045568296222086, -7.480590254712492],
              [-39.045881104327414, -7.482625406649057],
              [-39.046846211497872, -7.483746907642792],
              [-39.048272928168061, -7.48510352058771],
              [-39.049466930697449, -7.486225043767458],
              [-39.050081092067337, -7.487735675124094],
              [-39.050054362935676, -7.489364072249917],
              [-39.048169923819799, -7.491363460627373],
              [-39.046983557883543, -7.493136352940272],
              [-39.046544857336841, -7.495216790998104],
              [-39.045762849397995, -7.497116504871527],
              [-39.045167756964993, -7.497976257187998],
              [-39.04417594456789, -7.498600430608166],
              [-39.042562309490073, -7.498166526925588],
              [-39.03951055012142, -7.497243817035497],
              [-39.03616889278765, -7.49637598657943],
              [-39.032766163893243, -7.495507666077717],
              [-39.028264845605477, -7.494974552658771],
              [-39.025377092682476, -7.494522046059162],
              [-39.023645226220225, -7.49437755053157],
              [-39.021505185597597, -7.494341346018113],
              [-39.019597835541553, -7.49401610344274],
              [-39.018552611722257, -7.494404745244137],
              [-39.018697548410607, -7.495969718888719],
              [-39.021959136083808, -7.498348646131627],
              [-39.024499715598367, -7.498674330218518],
              [-39.027276828645753, -7.498719150197036],
              [-39.030225583506947, -7.498710093640812],
              [-39.033284980149006, -7.499225558000504],
              [-39.035520389052763, -7.500536861877762],
              [-39.037412465609933, -7.501613099899804],
              [-39.040632072486986, -7.502888152796085],
              [-39.042302913109289, -7.503376935193955],
              [-39.042474578782944, -7.503494211623726],
              [-39.042634789971537, -7.504308165829841],
              [-39.042840799168324, -7.505936586146451],
              [-39.042875117743478, -7.507383770420432],
              [-39.042840799338428, -7.509410332134625],
              [-39.042756862665271, -7.510974839603652],
              [-39.042440259519346, -7.512711967840073],
              [-39.041879486607819, -7.515081862051597],
              [-39.040746531997577, -7.517207585275359],
              [-39.039155785060522, -7.518854120025248],
              [-39.037904575246046, -7.521043289769224],
              [-39.036893670996186, -7.522879581842668],
              [-39.036462607772712, -7.524489863754264],
              [-39.037076791408403, -7.525665753142903],
              [-39.038690425438837, -7.526154023502656],
              [-39.042795023651912, -7.522915343423809],
              [-39.044450589605354, -7.520680889280932],
              [-39.045694186091467, -7.518962363683414],
              [-39.046705091246118, -7.51724334789978],
              [-39.047311615211143, -7.515633066130129],
              [-39.04832633852979, -7.513678985771207],
              [-39.049573730275718, -7.51160760431061],
              [-39.051656564049935, -7.508287388080742],
              [-39.052835316719836, -7.50691266035131],
              [-39.054357378685886, -7.505835957097942],
              [-39.056993325404193, -7.503854705218427],
              [-39.059076160452442, -7.503881410746486],
              [-39.060033654299964, -7.505464032952656],
              [-39.060396031668049, -7.507960750149061],
              [-39.060834731356856, -7.513071506603188],
              [-39.061014034466645, -7.516264876442036],
              [-39.060991146449794, -7.517712107674074],
              [-39.061803656707085, -7.517612409937168],
              [-39.063257056606844, -7.517223325470509],
              [-39.06418023097541, -7.517240975114117],
              [-39.064687568526786, -7.518236140510573],
              [-39.06395515284931, -7.520596953644223],
              [-39.061014033507576, -7.523564803312444],
              [-39.06064781423887, -7.524659132399671],
              [-39.062311017533887, -7.525617580959416],
              [-39.064397693835488, -7.525300955570894],
              [-39.065622174465439, -7.524803138575955],
              [-39.066316452526159, -7.524757853414512],
              [-39.067228148274715, -7.525635230853498],
              [-39.067788921390814, -7.526693330914993],
              [-39.067827082171057, -7.527851105949011],
              [-39.06774312330883, -7.529642089057125],
              [-39.067308289005261, -7.531379217205373],
              [-39.067392200842768, -7.533287989739172],
              [-39.068269577075441, -7.536317820146031],
              [-39.069188932198493, -7.540334224628262],
              [-39.069158407898783, -7.542188653399705],
              [-39.068658683732075, -7.544323457095079],
              [-39.067010730047151, -7.546150691712002],
              [-39.06601128041757, -7.547119152045911],
              [-39.066007461348846, -7.547236452334158],
              [-39.065717541337854, -7.547345184440713],
              [-39.064721909822026, -7.548141489817702],
              [-39.063428721061278, -7.549398916165983],
              [-39.062520820537536, -7.551877518451589],
              [-39.062845083167169, -7.553153060011062],
              [-39.063978038695652, -7.554799104993291],
              [-39.06477913951548, -7.555332684424649],
              [-39.066526255637683, -7.554545436606821],
              [-39.06882271223251, -7.552039174620493],
              [-39.072450495084695, -7.549777523243106],
              [-39.07461724194993, -7.548076204287939],
              [-39.076021049630334, -7.547171608897527],
              [-39.078237389665965, -7.546049619483071],
              [-39.081273898195228, -7.54447512447066],
              [-39.084009031462763, -7.543416046098852],
              [-39.085252628959552, -7.54169703149576],
              [-39.089452616457599, -7.536087992798723],
              [-39.091234074899901, -7.533391949535097],
              [-39.092660769602389, -7.531039680905954],
              [-39.093614444131219, -7.529375079737696],
              [-39.094625349345783, -7.527593131324978],
              [-39.095769759358362, -7.524888030597506],
              [-39.096853121807392, -7.522183395817814],
              [-39.099515798725719, -7.518518892122058],
              [-39.100854761451473, -7.518129808561769],
              [-39.102624764560446, -7.519612779043124],
              [-39.103616600031053, -7.522579651928746],
              [-39.103441115160187, -7.526405809675666],
              [-39.102132675794394, -7.528532021768256],
              [-39.101877097396965, -7.530033572491306],
              [-39.103895066489514, -7.530585288803453],
              [-39.106210592013959, -7.530448919513749],
              [-39.108831288967536, -7.52956150700808],
              [-39.111265068786658, -7.529253472851194],
              [-39.112947341900188, -7.529054169425437],
              [-39.1144503340687, -7.529135218160654],
              [-39.115819822246237, -7.530374041796468],
              [-39.116983300336564, -7.533521684740387],
              [-39.116643786160495, -7.536705974239187],
              [-39.115205660007554, -7.539700529987984],
              [-39.112447616805056, -7.542089003318421],
              [-39.109708664563499, -7.543139000744133],
              [-39.107442731201274, -7.543863315521796],
              [-39.104703779961142, -7.544913312946889],
              [-39.101667246230875, -7.546722897542681],
              [-39.099897267271267, -7.548722777023386],
              [-39.098764288740917, -7.550731199972708],
              [-39.098668921568809, -7.552992385243954],
              [-39.098867269336097, -7.555199646081432],
              [-39.099828605439043, -7.556429413443887],
              [-39.10115611257941, -7.556854282964547],
              [-39.104524499064958, -7.556102798254448],
              [-39.106847638920463, -7.555496249814706],
              [-39.107469436649382, -7.556490927319624],
              [-39.107667807697915, -7.558354414235039],
              [-39.107751720476649, -7.560326608401331],
              [-39.106737020095274, -7.562335055450427],
              [-39.10442149466428, -7.56600815038931],
              [-39.103994250683726, -7.567274634396937],
              [-39.104040024589608, -7.568206354812029],
              [-39.104661845554737, -7.568911600065633],
              [-39.105928306132228, -7.569336469539152],
              [-39.107194788657566, -7.569824762630128],
              [-39.10776699317163, -7.570294919262063],
              [-39.108159895776723, -7.571000164328728],
              [-39.108892311428455, -7.572338638853314],
              [-39.110013833659416, -7.574446249057024],
              [-39.110971327497296, -7.576028894294253],
              [-39.112512481384911, -7.577331141702154],
              [-39.113477587458576, -7.578560908075497],
              [-39.114778413597676, -7.580433451847766],
              [-39.114934806515876, -7.581600260938431],
              [-39.114614361193645, -7.583562932838036],
              [-39.112893951488729, -7.586023886583559],
              [-39.110883596763486, -7.588602630380566],
              [-39.109712480309895, -7.589624967253217],
              [-39.108568070321454, -7.59227570132708],
              [-39.108899948122996, -7.593270402601084],
              [-39.110395303007273, -7.593758672885268],
              [-39.111795315396165, -7.59325179909223],
              [-39.113557704182234, -7.591541864851551],
              [-39.115121722143385, -7.591342050341396],
              [-39.116395842957068, -7.591414064953841],
              [-39.118001816803897, -7.592200846529157],
              [-39.118627432598444, -7.593078223752987],
              [-39.118642683435745, -7.595565417769354],
              [-39.118490108563599, -7.597944857298123],
              [-39.118226893775272, -7.599970929866892],
              [-39.118329897559178, -7.600549793461864],
              [-39.119291186514857, -7.601951715801239],
              [-39.121305359513009, -7.606275201397267],
              [-39.12177455945865, -7.609531529156764],
              [-39.120473780353826, -7.611250520724965],
              [-39.117586026368194, -7.610853310740321],
              [-39.114259595820656, -7.608891127828221],
              [-39.11129938556234, -7.605825534871697],
              [-39.109365353070288, -7.60382663333132],
              [-39.108396403699352, -7.602995031548108],
              [-39.10827814950202, -7.603048908125954],
              [-39.108274330387168, -7.603221017085104],
              [-39.10827051222018, -7.603456129664417],
              [-39.107873791450977, -7.602985974172785],
              [-39.107644919188004, -7.602868184694711],
              [-39.107240562040062, -7.602922551034235],
              [-39.10555065274314, -7.603411774694226],
              [-39.103391496285624, -7.604533787152553],
              [-39.102929932600148, -7.6045247308274],
              [-39.101976257927802, -7.602716075948881],
              [-39.100572450205412, -7.600029578994885],
              [-39.099046570983361, -7.597795588213208],
              [-39.097032421109425, -7.596954928627945],
              [-39.094266787486099, -7.596277343442122],
              [-39.092462418012381, -7.596766103011335],
              [-39.092439507937016, -7.598267652935473],
              [-39.093194833783002, -7.601587403533633],
              [-39.094079847229516, -7.604264379116799],
              [-39.094278195170908, -7.606245631331872],
              [-39.093053691437184, -7.606860747614916],
              [-39.090814464522587, -7.605603808352847],
              [-39.088788882918422, -7.605749234809425],
              [-39.087442258776605, -7.606708172918107],
              [-39.0861300012049, -7.605469325310394],
              [-39.085088595605008, -7.601914507895613],
              [-39.084833017162104, -7.599825013289641],
              [-39.084337089105169, -7.598142716030381],
              [-39.083070628443068, -7.597826112899627],
              [-39.080629211447757, -7.598423579289124],
              [-39.080087530232326, -7.599753486820802],
              [-39.078618856630939, -7.601119623372062],
              [-39.077573632995467, -7.601273640583733],
              [-39.076104982411394, -7.598985284182652],
              [-39.075731125673542, -7.597067920443862],
              [-39.074766018509081, -7.595955454363448],
              [-39.071397633034088, -7.596706497064979],
              [-39.070657580046806, -7.599484077697067],
              [-39.070173105384974, -7.600858782004295],
              [-39.068567131636144, -7.599963756085084],
              [-39.067495201813443, -7.598154636120958],
              [-39.066411816311337, -7.597268201580222],
              [-39.064565514860313, -7.596888640354378],
              [-39.062730645850024, -7.595875825959856],
              [-39.061357315531048, -7.594863034944747],
              [-39.060682130386169, -7.593632778815783],
              [-39.059606405351289, -7.592113113105301],
              [-39.058233098883072, -7.591281022000897],
              [-39.057653281144304, -7.591154665287844],
              [-39.057374792528449, -7.590684509897385],
              [-39.056402049039498, -7.590033141031295],
              [-39.055242387420819, -7.589952092569138],
              [-39.053792783643019, -7.590159987124876],
              [-39.050309983137979, -7.59073934077006],
              [-39.048646779660004, -7.590015980772797],
              [-39.047391729724907, -7.588722767551319],
              [-39.046769930958604, -7.587781990721679],
              [-39.04592687415856, -7.586380091495196],
              [-39.044557409902303, -7.585258568144707],
              [-39.04351598038528, -7.585240431400573],
              [-39.041894733085641, -7.585150325615774],
              [-39.041448420194996, -7.584046449916183],
              [-39.039338902923504, -7.582101891931273],
              [-39.038095308248479, -7.580392424237109],
              [-39.037011922792054, -7.579451623032227],
              [-39.034986318348622, -7.579415860589337],
              [-39.033242997591188, -7.579732487249184],
              [-39.03147297040185, -7.578375873994571],
              [-39.030683349076838, -7.577145641779869],
              [-39.029485527545376, -7.576078484246276],
              [-39.026201076861824, -7.575155798132748],
              [-39.023534604831781, -7.575346043801304],
              [-39.022016315979187, -7.576250615093369],
              [-39.02203540698337, -7.578511776447181],
              [-39.022634317730493, -7.58089119356198],
              [-39.02327141233279, -7.58432824432001],
              [-39.024434868299437, -7.587593629548382],
              [-39.026010363733825, -7.590343550230204],
              [-39.02719288861249, -7.592333393182006],
              [-39.028882797180231, -7.595137679632742],
              [-39.029813584864272, -7.598339665060976],
              [-39.030462088810197, -7.601252683004083],
              [-39.030072981549175, -7.603739854667492],
              [-39.029123125085846, -7.605286713547805],
              [-39.027959646162664, -7.605612373625676],
              [-39.026914422480722, -7.60582981545404],
              [-39.026277372960706, -7.605938547274111],
              [-39.026040863515547, -7.606110213043325],
              [-39.0256364843789, -7.606155499437617],
              [-39.024251745476278, -7.605956661500497],
              [-39.022538948979815, -7.604482281995978],
              [-39.019979300573326, -7.601777647226313],
              [-39.017350967189472, -7.599642842961308],
              [-39.01540166110626, -7.598512262189877],
              [-39.013391283416652, -7.597436048040614],
              [-39.012185871467388, -7.596947754001072],
              [-39.010804928770575, -7.596468075765732],
              [-39.009477421503831, -7.596214871206245],
              [-39.007741737076479, -7.596070376655393],
              [-39.004907418503009, -7.59596166798734],
              [-39.002767376888777, -7.595925416451129],
              [-39.000760841270761, -7.594731412019519],
              [-38.998765736995345, -7.593021966712504],
              [-38.997110148045202, -7.591601929936141],
              [-38.994893832131922, -7.58895119613854],
              [-38.994462768751333, -7.587096766992669],
              [-38.99436740153682, -7.585758292003969],
              [-38.994199553979421, -7.58546883561595],
              [-38.993978270954578, -7.584998214279149],
              [-38.993806628379694, -7.584763124559446],
              [-38.993520525581523, -7.584582401417517],
              [-38.99294452586421, -7.584401213040976],
              [-38.992135810824095, -7.584211408526071],
              [-38.990865510000205, -7.584138951924006],
              [-38.989587570859165, -7.584518978580711],
              [-38.988817017541528, -7.585549907718387],
              [-38.988092192322618, -7.587277490682648],
              [-38.98656251608098, -7.588760926463443],
              [-38.984937451387196, -7.589195341506527],
              [-38.983022511012997, -7.589339790064787],
              [-38.981000701554898, -7.589303584627576],
              [-38.978692789582425, -7.588742811127148],
              [-38.976621411098861, -7.587955563189934],
              [-38.975068824645611, -7.587467269870596],
              [-38.974378364803293, -7.587222657784112],
              [-38.974260109607478, -7.587277024358618],
              [-38.973974007713458, -7.5872136005078],
              [-38.972989831785966, -7.587204543756809],
              [-38.971597457707063, -7.58729513806905],
              [-38.969510804523601, -7.587493976158504],
              [-38.968007834291384, -7.587521146763724],
              [-38.966741374733431, -7.587095834298606],
              [-38.965490118780416, -7.585802156096959],
              [-38.964368596803133, -7.583404601677532],
              [-38.963418740205697, -7.581478181550538],
              [-38.962877059056794, -7.579089707552742],
              [-38.962365879068408, -7.578673894649651],
              [-38.961568620416763, -7.577913817594955],
              [-38.96081711201294, -7.577669694457125],
              [-38.960065627423418, -7.577651556952502],
              [-38.958608409284913, -7.578383973331099],
              [-38.957952269052768, -7.579532691846512],
              [-38.956922318085496, -7.582408969607313],
              [-38.955625311268655, -7.583784185228679],
              [-38.954099432155324, -7.585086409104124],
              [-38.952630757579215, -7.58645207964248],
              [-38.952081463192478, -7.588071419398946],
              [-38.953256373836631, -7.590405060247231],
              [-38.954618224650822, -7.59211504202141],
              [-38.955522306960759, -7.593571283955812],
              [-38.955793158249193, -7.594503002543672],
              [-38.955823682496316, -7.596366489300704],
              [-38.955678722062842, -7.598157495352647],
              [-38.95567108573767, -7.598619082555461],
              [-38.957162622528308, -7.599225142113787],
              [-38.959130997274961, -7.599370102851013],
              [-38.961618211333914, -7.599469777287493],
              [-38.96479965844641, -7.59952412056743],
              [-38.965783857364784, -7.599542723261959],
              [-38.9658982934913, -7.599605657975362],
              [-38.966764238214445, -7.59967813824723],
              [-38.968728794945697, -7.599877464653294],
              [-38.971036684101001, -7.600212182522652],
              [-38.973058493228443, -7.600646598555077],
              [-38.975534229941331, -7.601270771813243],
              [-38.978189245367815, -7.601894946219008],
              [-38.979448114697284, -7.60266410260357],
              [-38.980420835170541, -7.60332450541078],
              [-38.981786528204246, -7.604735484005253],
              [-38.982759271632538, -7.6054502524846],
              [-38.984178328043946, -7.607151129372312],
              [-38.986490036324525, -7.607304681342653],
              [-38.988343997987855, -7.60734090964613],
              [-38.990709092744218, -7.607666593620706],
              [-38.991113450744926, -7.607784383241163],
              [-38.991273684923421, -7.608598336455499],
              [-38.991914550725852, -7.611746421964519],
              [-38.992143422941531, -7.611918088115565],
              [-38.993940155113357, -7.611837016081711],
              [-38.994771757235355, -7.610516189743496],
              [-38.996564694248463, -7.610661150340985],
              [-38.99794940814693, -7.610859987277751],
              [-38.998819147897869, -7.610869044937704],
              [-38.999975013588134, -7.610887159778081],
              [-39.001134697243643, -7.610914353566778],
              [-39.001775541018461, -7.610461823826535],
              [-39.002198966883277, -7.609367494784061],
              [-39.003263283335926, -7.608046667636175],
              [-39.004773888854409, -7.607612694498249],
              [-39.007360266303024, -7.608815778356844],
              [-39.008615294282272, -7.610046034958246],
              [-39.0087221181277, -7.610516190206704],
              [-39.008767892127224, -7.611330144328866],
              [-39.008874691934906, -7.611846097300806],
              [-39.00938587078155, -7.612434019170639],
              [-39.009897050770356, -7.612849832079894],
              [-39.01012974209327, -7.612858889216495],
              [-39.01111394201665, -7.612877003916234],
              [-39.012208757959769, -7.613121150342113],
              [-39.013299757735794, -7.613600852342548],
              [-39.014558602964136, -7.614496342970677],
              [-39.015584781824842, -7.615436677137069],
              [-39.016443066076434, -7.616151446527497],
              [-39.016847423078062, -7.616269235148962],
              [-39.019620716950328, -7.616549144740462],
              [-39.02101688724774, -7.616169583180458],
              [-39.022176571055518, -7.616015566064219],
              [-39.023855025089588, -7.615924947992068],
              [-39.024617965034984, -7.615418563676582],
              [-39.024923136802919, -7.614260321919708],
              [-39.025419065769974, -7.612414927468072],
              [-39.02723866198761, -7.610877125248019],
              [-39.029325314273819, -7.61056960282008],
              [-39.031244096869379, -7.61013518702014],
              [-39.032247364764054, -7.608814358818382],
              [-39.032674609745278, -7.607547875815139],
              [-39.03350239280126, -7.606344814758188],
              [-39.034662076513428, -7.606308562930103],
              [-39.035375423477745, -7.608633146483728],
              [-39.035222826659307, -7.610949164378065],
              [-39.035180869852887, -7.613499756948136],
              [-39.035280056176816, -7.614667078984048],
              [-39.035245712845779, -7.616575361438266],
              [-39.03487569821327, -7.61813127774321],
              [-39.03381899517904, -7.618873241395454],
              [-39.030145460092228, -7.620610369887178],
              [-39.028905683758325, -7.622093804913215],
              [-39.028020670163968, -7.623233467306188],
              [-39.027902392959959, -7.623296866826139],
              [-39.027841413307449, -7.623405599119671],
              [-39.027765091942001, -7.624508984397582],
              [-39.027631586993685, -7.625603802680214],
              [-39.027486625543695, -7.62740386569747],
              [-39.027402713850037, -7.628968862183897],
              [-39.02778415990791, -7.630425127710382],
              [-39.028745471850904, -7.631827026043398],
              [-39.029596142774665, -7.633002915649437],
              [-39.031492060432818, -7.633898406809747],
              [-39.033387955181546, -7.634685189626416],
              [-39.035402128082104, -7.635471972541121],
              [-39.037130175327732, -7.63590589934893],
              [-39.038221174105317, -7.636385112358137],
              [-39.039361788039223, -7.637452758858855],
              [-39.040441331224145, -7.638745948949097],
              [-39.040956330140098, -7.639216616535115],
              [-39.041684927751753, -7.640618492680831],
              [-39.041906186587319, -7.641314680664427],
              [-39.041253864552672, -7.642174408940307],
              [-39.039487656606646, -7.644119431750377],
              [-39.037626104650855, -7.644490424975193],
              [-39.036348165449184, -7.644933875244991],
              [-39.036229911200024, -7.645051175439792],
              [-39.035939989287087, -7.645042118254949],
              [-39.034608663806559, -7.645078345936779],
              [-39.034196692521419, -7.645367777850669],
              [-39.033944909276329, -7.646634263003564],
              [-39.036706748654737, -7.64761131539235],
              [-39.039487656671007, -7.647656135419894],
              [-39.040883849025093, -7.647212685247998],
              [-39.042375386726754, -7.647936044118188],
              [-39.043165031898724, -7.649338432313764],
              [-39.043592277098924, -7.651427927066934],
              [-39.043893652570112, -7.654277033136548],
              [-39.044015749190606, -7.65728921607585],
              [-39.044198823222018, -7.660482608974448],
              [-39.044633705696, -7.662173940138016],
              [-39.045537787188202, -7.663404195470224],
              [-39.046686015501457, -7.663946854149902],
              [-39.048131801966903, -7.664145669148855],
              [-39.050115426636907, -7.66306896628189],
              [-39.051702331483604, -7.66159409748623],
              [-39.052751374336971, -7.661141590082479],
              [-39.054147567588004, -7.660824964147047],
              [-39.056524118613559, -7.660743402606422],
              [-39.059472874281219, -7.660968970688616],
              [-39.061376405466568, -7.661167808066543],
              [-39.063226549072759, -7.661321359673395],
              [-39.06514151256102, -7.661058609842805],
              [-39.067750777109467, -7.660750574860108],
              [-39.069837407307546, -7.660560328734711],
              [-39.072450490874807, -7.660198393079896],
              [-39.075075006091382, -7.65895861472405],
              [-39.077348552628933, -7.657953925660583],
              [-39.078172540482313, -7.657040319847866],
              [-39.078718039723441, -7.655656046640614],
              [-39.079553460119421, -7.653991398362932],
              [-39.079557279189565, -7.653874098072882],
              [-39.079801402056624, -7.653132134745897],
              [-39.07980522032264, -7.652779279875336],
              [-39.080110415947267, -7.651802251015458],
              [-39.081212845225359, -7.65158527623517],
              [-39.083001941040393, -7.652019204100829],
              [-39.084325677244095, -7.652389706963768],
              [-39.08640467007227, -7.652714902724948],
              [-39.087213385118019, -7.652904683250848],
              [-39.088411182600183, -7.65402620648514],
              [-39.088735446162659, -7.655364705689472],
              [-39.08783136482861, -7.657544795806715],
              [-39.089090209963125, -7.658557610747849],
              [-39.090593203123412, -7.658638658504579],
              [-39.092271680123801, -7.658602430113232],
              [-39.094407902490332, -7.658991048541972],
              [-39.09584605273227, -7.659533219467717],
              [-39.096719609652936, -7.659315778501207],
              [-39.097955569887453, -7.658004008426359],
              [-39.098668916829517, -7.656737524645743],
              [-39.099038954329728, -7.655353251290352],
              [-39.100095610465019, -7.654503045286646],
              [-39.100847118072593, -7.654511636857184],
              [-39.102456910935096, -7.655234996833288],
              [-39.103948471645033, -7.655958355711847],
              [-39.106546281564093, -7.656518640489253],
              [-39.10758386882101, -7.656889144117424],
              [-39.108037819131724, -7.657413665334665],
              [-39.107885221296783, -7.659729683260768],
              [-39.107797490426229, -7.661529746348174],
              [-39.107698303966849, -7.664134705579805],
              [-39.107904313231884, -7.665645312654746],
              [-39.108827463510707, -7.669371818983426],
              [-39.10906779122358, -7.672447423666299],
              [-39.109784957334007, -7.674545976628329],
              [-39.110574602506503, -7.675947409847783],
              [-39.111142988844861, -7.676653120071807],
              [-39.111196400364243, -7.676770421410056],
              [-39.111944066803296, -7.677014567561553],
              [-39.113386035215157, -7.677321647910984],
              [-39.114313004603744, -7.677285419899138],
              [-39.115434526519984, -7.676145292693281],
              [-39.116712441688897, -7.675756185096824],
              [-39.118970761479794, -7.675791482760268],
              [-39.120706445022662, -7.675818188041345],
              [-39.122732050526636, -7.67579958583602],
              [-39.125108600713652, -7.675545451339757],
              [-39.127321121461257, -7.674830170482368],
              [-39.128095516971108, -7.673563686747327],
              [-39.128927119161816, -7.672188493714024],
              [-39.130174533856895, -7.67023441250991],
              [-39.131360899838342, -7.668461056167442],
              [-39.132017017142054, -7.667257994952575],
              [-39.132852414456238, -7.665701590013074],
              [-39.13432492919177, -7.664163321493115],
              [-39.136461151718443, -7.664370727017311],
              [-39.13812817407802, -7.66521141034118],
              [-39.139325995563951, -7.666332934586005],
              [-39.140623003570155, -7.668376654364414],
              [-39.141286757163755, -7.670357441996685],
              [-39.142652403153164, -7.67183135426258],
              [-39.143857860969291, -7.672491291293341],
              [-39.144994633748645, -7.673784480457501],
              [-39.145700367413248, -7.676516286609441],
              [-39.144254580834968, -7.680026260292729],
              [-39.143888385391577, -7.681301825315177],
              [-39.14369764984253, -7.682396642570894],
              [-39.143693831575277, -7.682749033447968],
              [-39.144491090275977, -7.683454277869105],
              [-39.145349398682995, -7.683987856373757],
              [-39.147371184985118, -7.68424962848143],
              [-39.14997281217353, -7.684465649346953],
              [-39.152570622147586, -7.684971591465986],
              [-39.155389691092076, -7.686010599563959],
              [-39.157632736130957, -7.68708634896237],
              [-39.158838195000492, -7.687682862379186],
              [-39.15956679265593, -7.689030418883322],
              [-39.160291594906958, -7.690893415285717],
              [-39.160947712255258, -7.693227057802071],
              [-39.16089811908752, -7.696239238652184],
              [-39.160512829716382, -7.698781287049603],
              [-39.160352618535939, -7.701558379222559],
              [-39.159795664726438, -7.703702239871467],
              [-39.159711752870876, -7.705438879363156],
              [-39.158693233380404, -7.707565092733599],
              [-39.156785883356321, -7.707186020419933],
              [-39.154367354289086, -7.706454069800966],
              [-39.151357525887356, -7.706401146607272],
              [-39.150243664150686, -7.70725181856407],
              [-39.149530316196994, -7.708518279355689],
              [-39.148755921589121, -7.709893495442602],
              [-39.147054579627032, -7.71126012080716],
              [-39.145078567323438, -7.711803221892576],
              [-39.143686193265069, -7.711839938509256],
              [-39.141385940551793, -7.710872922402143],
              [-39.139135258892189, -7.71061112709717],
              [-39.137227908728192, -7.710403720752002],
              [-39.136064429887391, -7.710612104528023],
              [-39.135358696410982, -7.711245801128866],
              [-39.135297671514472, -7.711643499686815],
              [-39.134694941624836, -7.713082115534247],
              [-39.132467170246656, -7.714674749098765],
              [-39.130903129374396, -7.714765832245065],
              [-39.129346724842655, -7.714386272227546],
              [-39.127153271920257, -7.713952787002534],
              [-39.124841565663466, -7.713736323374126],
              [-39.123056288916452, -7.713185119198324],
              [-39.120580528321661, -7.712389745914915],
              [-39.11907374004123, -7.712480829109553],
              [-39.11767375255576, -7.713096411238976],
              [-39.116155509819478, -7.713829315563927],
              [-39.114740223616671, -7.71543100774975],
              [-39.114477031901103, -7.71733931305357],
              [-39.114213816093724, -7.719356817701043],
              [-39.114202384636371, -7.719999127828922],
              [-39.114171860108101, -7.722079565292939],
              [-39.113855234062541, -7.723644539617863],
              [-39.113489036767248, -7.724738891731461],
              [-39.112668868074017, -7.725417896963058],
              [-39.110761516817703, -7.725327790910528],
              [-39.109426371354715, -7.725364508573785],
              [-39.108438354199521, -7.725644884061905],
              [-39.108148456140043, -7.725807958838993],
              [-39.107030729382551, -7.726721586410622],
              [-39.106504321820495, -7.727119751576234],
              [-39.106153353099771, -7.727400592593995],
              [-39.105970254879807, -7.72803384663296],
              [-39.105783338588679, -7.728784375963465],
              [-39.105596421198449, -7.72965269658574],
              [-39.104886869560943, -7.73051244882256],
              [-39.103952286787155, -7.731073687666099],
              [-39.102781170610015, -7.731752226606378],
              [-39.101957182800824, -7.732602432804669],
              [-39.101079806510604, -7.733290494933265],
              [-39.100770816759258, -7.734439212767357],
              [-39.100580081251273, -7.735479664351475],
              [-39.100324502865739, -7.736926872435707],
              [-39.099779003565608, -7.738364999332015],
              [-39.099351758363944, -7.739867038943175],
              [-39.099267823549646, -7.741549335758219],
              [-39.098951197410081, -7.743223041430306],
              [-39.098508701784134, -7.745357846225128],
              [-39.098386628369596, -7.745701644056028],
              [-39.098718505007987, -7.746868453319469],
              [-39.09928689143868, -7.747456398261313],
              [-39.10066019774203, -7.74846923632366],
              [-39.101400250843469, -7.749174457704817],
              [-39.1024187693084, -7.750639780750675],
              [-39.10367761558485, -7.751471381807747],
              [-39.104833480140563, -7.751661163636354],
              [-39.10587488560838, -7.751742211022537],
              [-39.10790046711449, -7.751714550887465],
              [-39.10853753854105, -7.751723632367201],
              [-39.109231841550312, -7.751741257843555],
              [-39.109346253585223, -7.751913412904893],
              [-39.109460712711893, -7.751976346622637],
              [-39.109632356382498, -7.752094136058693],
              [-39.113065586488361, -7.751252031998964],
              [-39.114175677242777, -7.750410418987711],
              [-39.115808357298235, -7.749514437741255],
              [-39.117097728057978, -7.748329002946275],
              [-39.119233973865306, -7.748192633556933],
              [-39.120931495792128, -7.746898978754109],
              [-39.122526062135407, -7.74479993670462],
              [-39.124124421695143, -7.742411463397568],
              [-39.12597072415636, -7.739217604138338],
              [-39.126512381379982, -7.737905811475589],
              [-39.127168544783054, -7.736594041908694],
              [-39.127527127792696, -7.735906468341257],
              [-39.128362549055872, -7.734422984956553],
              [-39.129136920756579, -7.732939060523379],
              [-39.130311854336419, -7.731753647631346],
              [-39.133264429433687, -7.727890283321943],
              [-39.135038273825941, -7.725393100814921],
              [-39.136815937244009, -7.722832495692241],
              [-39.138173945998801, -7.721068195432219],
              [-39.13969600822881, -7.719720173807105],
              [-39.140748868257774, -7.719041145768454],
              [-39.141908552270557, -7.718661142021694],
              [-39.14422025987335, -7.718470384100105],
              [-39.145486743780033, -7.718497088997983],
              [-39.147046942583323, -7.71852379614139],
              [-39.148485092215722, -7.718604378859028],
              [-39.149759188212713, -7.718459394795224],
              [-39.151910684549925, -7.717572028926242],
              [-39.153734124156045, -7.715590288359854],
              [-39.155420214727627, -7.714821131391044],
              [-39.157274152816854, -7.714386272553315],
              [-39.159067066290618, -7.714014325286476],
              [-39.161443617767418, -7.713425914807906],
              [-39.164510651195307, -7.712963372149717],
              [-39.167814171219717, -7.712157055754604],
              [-39.1696109037591, -7.711676866140862],
              [-39.171178763096691, -7.711006917476046],
              [-39.172174370675528, -7.710165303364604],
              [-39.172880105147499, -7.70954064070737],
              [-39.174039764263355, -7.709042824667026],
              [-39.175493164368234, -7.708435811528068],
              [-39.175783085330806, -7.708390503042392],
              [-39.175844110992372, -7.708273201798172],
              [-39.177640842487705, -7.707847354857281],
              [-39.180601053171983, -7.706805505588877],
              [-39.182699139285461, -7.705456995442659],
              [-39.184873523574431, -7.703248756525161],
              [-39.187391215254436, -7.701221729805067],
              [-39.189332885254963, -7.698959125019288],
              [-39.191053318186967, -7.69628121954406],
              [-39.191999357561798, -7.694914594544239],
              [-39.192293096747733, -7.694570795854901],
              [-39.192354145454708, -7.694399129937231],
              [-39.193822795218466, -7.692878487298554],
              [-39.19563477833546, -7.691584365584887],
              [-39.197610789892749, -7.690751320237436],
              [-39.199350292900341, -7.690207729953488],
              [-39.202527943307558, -7.690034154645187],
              [-39.207513759908053, -7.688512091945294],
              [-39.208497958896608, -7.688475839983412],
              [-39.209985677753096, -7.68907188764288],
              [-39.212739903374356, -7.689875876109447],
              [-39.214460337415012, -7.690652623878269],
              [-39.216524079992276, -7.691438451879318],
              [-39.218706099966326, -7.691934824717892],
              [-39.222566575101624, -7.692231428156676],
              [-39.224065749355269, -7.692258133246882],
              [-39.226549122699126, -7.692129867088839],
              [-39.228574728469091, -7.691821342631316],
              [-39.229845006459676, -7.691730259237954],
              [-39.231058078715641, -7.691810865765927],
              [-39.233007407311675, -7.692361604085377],
              [-39.235906570309581, -7.695064795274911],
              [-39.238302213933089, -7.696546811377595],
              [-39.239614470804888, -7.697432780154708],
              [-39.239728907888036, -7.69755007954612],
              [-39.239900573660378, -7.697550079689464],
              [-39.240690196178598, -7.698544757378008],
              [-39.240915274128717, -7.699069279417475],
              [-39.241643871946749, -7.700226566079463],
              [-39.242261850646571, -7.701275609299105],
              [-39.243402465935972, -7.701935080293897],
              [-39.245359408623301, -7.702314151653978],
              [-39.246870015429714, -7.701589395266053],
              [-39.252424218354875, -7.70082643248589],
              [-39.254980070917242, -7.699829844602045],
              [-39.256261782407037, -7.69905116534574],
              [-39.256437290197809, -7.69899679981858],
              [-39.257081975112975, -7.698372137105729],
              [-39.258611674650176, -7.696616428919544],
              [-39.259447094968486, -7.695078160848511],
              [-39.26056098090892, -7.694000968242967],
              [-39.261552793478906, -7.693213719795486],
              [-39.263345706829426, -7.693013439494366],
              [-39.264497753480377, -7.693148387646441],
              [-39.267435078195675, -7.69349027604756],
              [-39.270483018931422, -7.694058198185241],
              [-39.272474282065261, -7.692428846624334],
              [-39.273942954903845, -7.690836189406674],
              [-39.275068274340981, -7.689071423935013],
              [-39.27625084531558, -7.687370058130629],
              [-39.277074809238592, -7.686401598629279],
              [-39.27713585789337, -7.686292889332508],
              [-39.27713585799173, -7.686175589036365],
              [-39.277257931453128, -7.685777447527256],
              [-39.2779827108129, -7.683940644153601],
              [-39.278463366234583, -7.682918306689208],
              [-39.278821926248277, -7.682230710114331],
              [-39.279001252398992, -7.681714756359979],
              [-39.279653551495521, -7.680809718334841],
              [-39.279829036385578, -7.680637586514129],
              [-39.279993064728174, -7.68121647417712],
              [-39.280603408198793, -7.682609317328338],
              [-39.281465533878674, -7.682798631911485],
              [-39.282232292210622, -7.681830171362139],
              [-39.282255180252292, -7.680392021010783],
              [-39.282266635746574, -7.679704424146353],
              [-39.282564170006971, -7.679242836164295],
              [-39.283090599669485, -7.678735986644606],
              [-39.283723829939539, -7.678980109707662],
              [-39.284391402956587, -7.680426828583357],
              [-39.284479157025423, -7.681982745320671],
              [-39.284574523328253, -7.683194397130244],
              [-39.285070453760298, -7.684415573217168],
              [-39.286100403953348, -7.684948173881152],
              [-39.287195221061538, -7.685029269308182],
              [-39.288122190566888, -7.684874762008629],
              [-39.289331443911138, -7.684891944914664],
              [-39.290781025818994, -7.684574364029516],
              [-39.292158150622576, -7.684998745632877],
              [-39.293828990464803, -7.6852595854626],
              [-39.29534341534233, -7.684426539723636],
              [-39.295755409876811, -7.683856243488467],
              [-39.294672025216393, -7.683142429866488],
              [-39.294164663425406, -7.68244624261953],
              [-39.294187573376185, -7.681116823613988],
              [-39.294549951553108, -7.680202705400237],
              [-39.294855146327535, -7.679063067491806],
              [-39.294687297822541, -7.678710189468914],
              [-39.293771737712468, -7.678294912196931],
              [-39.292562507420456, -7.678214305672199],
              [-39.29071618264306, -7.678179008344539],
              [-39.289693845637082, -7.677356951424556],
              [-39.289541247841555, -7.676027045277132],
              [-39.289560340568563, -7.67499611647903],
              [-39.289926537057916, -7.673675288709898],
              [-39.290685659005206, -7.673231350006346],
              [-39.29207803347326, -7.672733045156605],
              [-39.293237693506001, -7.67235206340892],
              [-39.293413178199003, -7.672415487174586],
              [-39.294336329641268, -7.672369689218554],
              [-39.295660043161192, -7.672395952163938],
              [-39.297166854562832, -7.672195647621329],
              [-39.297765741480752, -7.670766088697793],
              [-39.297666579014674, -7.669780003531896],
              [-39.296300909558418, -7.668840600031224],
              [-39.295850777364755, -7.668135377888075],
              [-39.296087287008071, -7.667737213474345],
              [-39.297475844307705, -7.667473532212917],
              [-39.298856764230024, -7.667726270846312],
              [-39.300451304748705, -7.669027540366472],
              [-39.301706334094163, -7.669849619479494],
              [-39.303895967368732, -7.669947874714129],
              [-39.304937420057939, -7.669793344508883],
              [-39.305517214999782, -7.669693204592743],
              [-39.305856729331992, -7.669982659139013],
              [-39.305910140753646, -7.670217702773412],
              [-39.306364091124593, -7.670678823385292],
              [-39.307336834676441, -7.671274871620031],
              [-39.308774984335336, -7.671355454335086],
              [-39.310342820492465, -7.670929164198903],
              [-39.311849632093399, -7.670493817066061],
              [-39.313238188395111, -7.670230624801935],
              [-39.314897573944826, -7.670943949905997],
              [-39.315229450748781, -7.671929569270674],
              [-39.315156970592319, -7.672734489382306],
              [-39.315134083694822, -7.674000973766893],
              [-39.315118833030709, -7.674923636220284],
              [-39.315683401407043, -7.675628881461223],
              [-39.316019096425798, -7.676325069565797],
              [-39.316297561997132, -7.676849591652286],
              [-39.31641199908902, -7.676957834098561],
              [-39.316469228581504, -7.677075623439871],
              [-39.31657984569307, -7.677192923828642],
              [-39.3166370761856, -7.677310689170123],
              [-39.316900291092324, -7.678757897710597],
              [-39.316587483173677, -7.680133090202589],
              [-39.316518821087875, -7.680820221024685],
              [-39.316629438956255, -7.68122741867511],
              [-39.316614188291339, -7.682150129129157],
              [-39.316419634671924, -7.68335318975253],
              [-39.316347131468945, -7.684212452539482],
              [-39.315980935166117, -7.685307270668827],
              [-39.31468013193129, -7.687126425674487],
              [-39.314554241306716, -7.687750575826817],
              [-39.314607651682252, -7.688040031135833],
              [-39.315988571652021, -7.688237914102768],
              [-39.317659412596761, -7.688381430637988],
              [-39.31885723434273, -7.689213033652696],
              [-39.320512801675562, -7.690215837023771],
              [-39.321149873163641, -7.690170061830547],
              [-39.32201579306291, -7.690069921154443],
              [-39.3225346112512, -7.690196279830488],
              [-39.323225070160724, -7.690385594272539],
              [-39.324037603593226, -7.690104730635221],
              [-39.3248463180459, -7.689832456943243],
              [-39.326749850277025, -7.690039398293359],
              [-39.327619612248313, -7.689821491326414],
              [-39.328783092502007, -7.689151054317541],
              [-39.32890514191908, -7.688807255480412],
              [-39.327657750905352, -7.687523612133142],
              [-39.325243038574541, -7.687019114140409],
              [-39.323110634932171, -7.68686700527115],
              [-39.321676302585736, -7.686379666994954],
              [-39.321355835089143, -7.684932482404147],
              [-39.321600004066141, -7.684073219759988],
              [-39.322992356532467, -7.683583506858723],
              [-39.323637041353244, -7.683076609436203],
              [-39.323247958004607, -7.682036623346971],
              [-39.322278986523962, -7.681322809818197],
              [-39.322286645659233, -7.681096312182694],
              [-39.323221252697493, -7.680245641063509],
              [-39.32449916789006, -7.679864636414497],
              [-39.324739495651507, -7.679294806031836],
              [-39.324075741946444, -7.677440865594729],
              [-39.323343326011205, -7.676446187948381],
              [-39.322618523301628, -7.675117235313308],
              [-39.322759665696083, -7.673443530468174],
              [-39.324495349467604, -7.673243272116386],
              [-39.32599452473886, -7.673269489209203],
              [-39.326341675252365, -7.673278080447194],
              [-39.327062659535976, -7.671568146302453],
              [-39.327772188280122, -7.670599685701694],
              [-39.328527514166787, -7.670264456341657],
              [-39.32963376278272, -7.66964838495822],
              [-39.329805429549225, -7.669657442046999],
              [-39.330953635145924, -7.669900634545727],
              [-39.331693688270811, -7.670605390936524],
              [-39.331804328328921, -7.670786579942241],
              [-39.333120405386353, -7.671436527142187],
              [-39.333581945984697, -7.671562884769332],
              [-39.334333476656496, -7.671517109670445],
              [-39.335664803469001, -7.671136152064705],
              [-39.336130186381254, -7.670855265136914],
              [-39.337919281577058, -7.670890072419935],
              [-39.33860590037154, -7.67125151982527],
              [-39.339635896669066, -7.671666843194044],
              [-39.340616277654618, -7.671684002908692],
              [-39.341703481437612, -7.672226173564929],
              [-39.342561788817356, -7.672813164761702],
              [-39.342676224947162, -7.6728765874769],
              [-39.344411909775751, -7.672612882505517],
              [-39.345113824333396, -7.671933877161502],
              [-39.346624454004157, -7.671389798683004],
              [-39.347314890067615, -7.67139838920732],
              [-39.34795193860348, -7.671298249338899],
              [-39.348302908287607, -7.671071750989386],
              [-39.34847457406206, -7.671071751132549],
              [-39.348653854017087, -7.670791375962843],
              [-39.349298540036699, -7.670048924951186],
              [-39.348970481250142, -7.669008938912093],
              [-39.348238041403071, -7.667906017913443],
              [-39.348253315021374, -7.66703772113171],
              [-39.348264747470118, -7.666404467937722],
              [-39.348901819005818, -7.66630432706906],
              [-39.349477819886538, -7.666312918497677],
              [-39.349866926328701, -7.667244149239217],
              [-39.350267464317874, -7.667424896489523],
              [-39.350954130067514, -7.667840685569396],
              [-39.35236555057574, -7.669530619615703],
              [-39.353845655971995, -7.670768955427172],
              [-39.355035840498573, -7.67193483243171],
              [-39.355657661423336, -7.672693954400474],
              [-39.35611161178862, -7.673164110961256],
              [-39.357252179904393, -7.674049590605922],
              [-39.358743741947386, -7.674419139635782],
              [-39.360021657243372, -7.673920834688595],
              [-39.359525749943387, -7.672537073567844],
              [-39.358442388320512, -7.671768894268004],
              [-39.357774792161941, -7.670485226404931],
              [-39.357854909742493, -7.669110033714332],
              [-39.359182417396056, -7.668955526747489],
              [-39.360281053585204, -7.668918343886537],
              [-39.361440737581262, -7.668591705811357],
              [-39.362588966121002, -7.668907377876946],
              [-39.364004205747101, -7.67042562195824],
              [-39.364290307508043, -7.670660711787987],
              [-39.36440474364607, -7.670714589560578],
              [-39.365312644423256, -7.671600557008527],
              [-39.366167133733548, -7.67230533749797],
              [-39.367475596606646, -7.673245158958012],
              [-39.368776421749487, -7.671543351241278],
              [-39.369657593264918, -7.670583948725612],
              [-39.371145336094685, -7.671242906018228],
              [-39.371198724524142, -7.671468961708179],
              [-39.372675034806257, -7.672878497494297],
              [-39.37376603366431, -7.673302877862098],
              [-39.375089724150698, -7.673382972487033],
              [-39.375448306214032, -7.67264103323161],
              [-39.376085331759619, -7.672531835417517],
              [-39.377988909191806, -7.672512278123424],
              [-39.378862468284829, -7.672122705186555],
              [-39.379053202743492, -7.671145187202293],
              [-39.380384529562903, -7.67076418259599],
              [-39.380560014500183, -7.670538174096489],
              [-39.380678268747538, -7.670429441846617],
              [-39.380739293456394, -7.670257309928782],
              [-39.380800342119137, -7.670140008682528],
              [-39.380918597465218, -7.669913512138186],
              [-39.381155106065812, -7.669569713395329],
              [-39.381113150184284, -7.668592661214208],
              [-39.380025946441897, -7.667996613878467],
              [-39.379457559891435, -7.667526947218463],
              [-39.378202555444787, -7.6668135754461],
              [-39.377584553680791, -7.665827955836621],
              [-39.37857254714676, -7.665211885352081],
              [-39.379613952844942, -7.665057402146134],
              [-39.379976354979256, -7.664197650600003],
              [-39.380167089535369, -7.663102832319376],
              [-39.380178545029182, -7.662415701446387],
              [-39.378633595749584, -7.661584587139064],
              [-39.37702759867053, -7.661034338098109],
              [-39.375768752054185, -7.660556032915549],
              [-39.375314801641728, -7.660140243029144],
              [-39.375616131158111, -7.659343915052768],
              [-39.376600354264028, -7.659189431798636],
              [-39.376775838155872, -7.659017299976413],
              [-39.376794907981314, -7.657868581876804],
              [-39.37565049669977, -7.657209110876027],
              [-39.374158936653004, -7.656839561848622],
              [-39.372312633902965, -7.6567508768425],
              [-39.370813459623328, -7.656725124748319],
              [-39.369600364295096, -7.656707964840945],
              [-39.368517002669108, -7.655940251540685],
              [-39.367845611391367, -7.654827784650426],
              [-39.366926279254486, -7.654466827048927],
              [-39.366014560153886, -7.653997626102735],
              [-39.365503380293937, -7.653409681201997],
              [-39.365388943163317, -7.653346723484185],
              [-39.365743708108305, -7.652776427198409],
              [-39.366556241491452, -7.652558521181068],
              [-39.367479392888235, -7.652576169843574],
              [-39.368909904173371, -7.653180785409671],
              [-39.369653775562682, -7.653541765864929],
              [-39.370687568883021, -7.653902724561712],
              [-39.371259773648305, -7.654083447954764],
              [-39.371374209733546, -7.654200748346883],
              [-39.372873383967871, -7.654280865114776],
              [-39.374425969715169, -7.654487805167034],
              [-39.376157835335377, -7.654513067457644],
              [-39.37691316212733, -7.654295649389699],
              [-39.378027049072841, -7.653227049722043],
              [-39.378156758873594, -7.652358730034561],
              [-39.378393268518948, -7.651960564617986],
              [-39.378122415419895, -7.650802812332049],
              [-39.377901133429951, -7.650287371237523],
              [-39.379175276467329, -7.650132841224149],
              [-39.379633045148694, -7.650195310230678],
              [-39.380197613386557, -7.651072686441684],
              [-39.380643926567046, -7.651831809263193],
              [-39.380701157060116, -7.651949598604841],
              [-39.380758363561398, -7.652057842003737],
              [-39.380983440758783, -7.652292931782057],
              [-39.381601420570277, -7.65322418571509],
              [-39.381883705208246, -7.65363999845808],
              [-39.38329896779414, -7.655212609211813],
              [-39.384149660892312, -7.656206796962373],
              [-39.385229204448578, -7.657092300555151],
              [-39.386812337541123, -7.659017765336326],
              [-39.387250991245402, -7.660410142358892],
              [-39.387804127087193, -7.661856860151652],
              [-39.388189439368212, -7.663014612536257],
              [-39.388856988386088, -7.664469923373757],
              [-39.389070610088758, -7.66539260902403],
              [-39.389917462122341, -7.66649552912294],
              [-39.391237357395703, -7.66686507901016],
              [-39.392450429772303, -7.66682789524491],
              [-39.394582856333201, -7.667097304410112],
              [-39.39602480102468, -7.667123055458939],
              [-39.396597006837077, -7.667248947182241],
              [-39.396764855298201, -7.667656168883092],
              [-39.396814470557658, -7.668117267162588],
              [-39.396905972744484, -7.669501051950503],
              [-39.397344696545275, -7.670776080679959],
              [-39.398241141640845, -7.672575212652952],
              [-39.398687454827183, -7.673325766530567],
              [-39.399259660495098, -7.673623790223587],
              [-39.400068397809846, -7.673523650498658],
              [-39.400201902677317, -7.672546620461375],
              [-39.400316339907022, -7.672491765885473],
              [-39.400377387615499, -7.672320122964355],
              [-39.400503256097338, -7.671867592779613],
              [-39.40067874098991, -7.671695460957032],
              [-39.401548481013641, -7.671423654310972],
              [-39.402475472583873, -7.6712057473899],
              [-39.402650935469048, -7.671042672513035],
              [-39.402784462533376, -7.669830530883866],
              [-39.402792053721981, -7.669541098623572],
              [-39.402677640583725, -7.669486732853636],
              [-39.402734870274301, -7.669368966606757],
              [-39.402917968347737, -7.668916437469728],
              [-39.404127221707526, -7.668933619376173],
              [-39.404932141905981, -7.66900514561967],
              [-39.405122876364899, -7.668028092630304],
              [-39.404577330810831, -7.6661741532784],
              [-39.404180632935919, -7.665821763057934],
              [-39.404123380435031, -7.665713519658485],
              [-39.403749570557189, -7.663976880748129],
              [-39.403577904834471, -7.663913945981879],
              [-39.403352849780696, -7.663507190230485],
              [-39.402731028754097, -7.662865391556068],
              [-39.402738688131443, -7.662349903650166],
              [-39.403387169226519, -7.661489686343602],
              [-39.405000803641784, -7.661578861155006],
              [-39.405405160849071, -7.661469663146254],
              [-39.405477641051981, -7.660610400353483],
              [-39.405672194725248, -7.659343916101782],
              [-39.40724384916772, -7.658564771079032],
              [-39.407030226509711, -7.657587718753439],
              [-39.406229125624613, -7.657108994953387],
              [-39.404672721792807, -7.657029343133381],
              [-39.402414425407223, -7.65761919771785],
              [-39.401304357710991, -7.658353056396424],
              [-39.400026419318543, -7.65896007069485],
              [-39.398752323186443, -7.659223751053241],
              [-39.397672755674741, -7.65828390578392],
              [-39.396474933907975, -7.657461849710754],
              [-39.395273318031862, -7.656802844657793],
              [-39.393793211574014, -7.655628398461463],
              [-39.393644432892131, -7.654126848334569],
              [-39.395219905547471, -7.653057758052595],
              [-39.39695940849505, -7.652631468055555],
              [-39.397321809674651, -7.651717373834387],
              [-39.396928884113116, -7.650957786058667],
              [-39.395952299283138, -7.650714593702783],
              [-39.393758868967012, -7.650671194136712],
              [-39.392324538455618, -7.650365067777104],
              [-39.391237357755706, -7.649714653769805],
              [-39.390611741566346, -7.649299330738033],
              [-39.390615559683035, -7.649127198772798],
              [-39.390287501044241, -7.647906488815495],
              [-39.390245545061958, -7.647047225930296],
              [-39.390661334670448, -7.646359629397209],
              [-39.39252291013235, -7.645471286270075],
              [-39.3943120055824, -7.645216173287417],
              [-39.395017716115575, -7.644537144943908],
              [-39.394464603224982, -7.64314479282847],
              [-39.392850969901318, -7.642947374669588],
              [-39.391931636572579, -7.642812425713814],
              [-39.39071856424107, -7.642795266807829],
              [-39.389276596549912, -7.642769514763076],
              [-39.388303852791374, -7.642408557117707],
              [-39.387220468116688, -7.641695209492793],
              [-39.386434662840664, -7.640357198860041],
              [-39.387731648061497, -7.638591945520051],
              [-39.387971975770171, -7.638085047758455],
              [-39.387601938146609, -7.636005098919791],
              [-39.38785372130949, -7.634855915018373],
              [-39.38912020510891, -7.635053797884183],
              [-39.390543080120906, -7.636056579054913],
              [-39.391927819175336, -7.636136695725233],
              [-39.391519642789532, -7.632898970795346],
              [-39.391481505921746, -7.63186804194407],
              [-39.39280137715236, -7.632291957498828],
              [-39.39489566730429, -7.634578404532118],
              [-39.396482571618797, -7.636213949047003],
              [-39.39767275605697, -7.637497593342212],
              [-39.398935421741434, -7.637867142176772],
              [-39.401155579380344, -7.639692490082753],
              [-39.401395907097061, -7.639176537374676],
              [-39.401025869421119, -7.637159522155475],
              [-39.399210091987939, -7.635225465238331],
              [-39.398927784197525, -7.634990864409929],
              [-39.398824803513676, -7.634294187500163],
              [-39.398737025627028, -7.632512262108257],
              [-39.398923988965059, -7.631707342088427],
              [-39.398771392110241, -7.630440880553373],
              [-39.398553951242029, -7.629744716545997],
              [-39.398618795209508, -7.629229228689944],
              [-39.3988591229256, -7.628713297982344],
              [-39.399034607673862, -7.628713298128036],
              [-39.399095632427525, -7.628486799536489],
              [-39.399332142027241, -7.628142978793903],
              [-39.399507649918945, -7.627970846971504],
              [-39.40029727240448, -7.629028482286963],
              [-39.401361566266729, -7.630881956060442],
              [-39.403085816652691, -7.63126007422607],
              [-39.404340845962189, -7.632145576961066],
              [-39.404840570522772, -7.633194131091672],
              [-39.405012237102092, -7.633429220825286],
              [-39.405126672240577, -7.63348356259467],
              [-39.40592395601108, -7.634133976359036],
              [-39.406961543596395, -7.634151136118357],
              [-39.406938655632302, -7.631907623545232],
              [-39.40678226352491, -7.630976345996902],
              [-39.406965360792782, -7.630288749270154],
              [-39.408529402850405, -7.630034125095242],
              [-39.409612764483313, -7.630801839393902],
              [-39.409956096936803, -7.630919139976072],
              [-39.410013303430929, -7.631036440320577],
              [-39.410127763561697, -7.631099863035583],
              [-39.410356658831354, -7.631217163522712],
              [-39.4111615320315, -7.631288688762679],
              [-39.412366991178686, -7.631595304926559],
              [-39.414262885199236, -7.632145088207033],
              [-39.415586598548828, -7.632397337795246],
              [-39.416570774705228, -7.632187998867477],
              [-39.416814920681439, -7.631328736219336],
              [-39.41634190141707, -7.628497766791265],
              [-39.415155536143892, -7.62697949890509],
              [-39.414381140355772, -7.624953893402271],
              [-39.414529918780609, -7.623171944206026],
              [-39.415403477072317, -7.622546816677809],
              [-39.416788192123505, -7.622635991292425],
              [-39.417822008548669, -7.622879205692699],
              [-39.4192639522904, -7.622850568061048],
              [-39.420419818177571, -7.62269559494914],
              [-39.421815988781141, -7.622025158125835],
              [-39.422502652682226, -7.622268350238208],
              [-39.423009991333544, -7.623145262404093],
              [-39.423921710290429, -7.623795652263293],
              [-39.424554963768053, -7.62380424373747],
              [-39.424558758839225, -7.623686455446464],
              [-39.424558758982364, -7.623514811475092],
              [-39.424619806690671, -7.6233426565574],
              [-39.42485631633631, -7.622944515139591],
              [-39.425283561293575, -7.621740965706674],
              [-39.424902091166679, -7.620357669679982],
              [-39.424505371432538, -7.619833148494315],
              [-39.424345136109977, -7.619191326207838],
              [-39.427213798988085, -7.619993359781431],
              [-39.429666648121888, -7.621646552910214],
              [-39.431223030004666, -7.621671815050588],
              [-39.431322238331866, -7.622829590195535],
              [-39.431356557940276, -7.624204783984105],
              [-39.431562567362526, -7.625534201189757],
              [-39.431940218319774, -7.627152609806839],
              [-39.434461729484532, -7.62818160673655],
              [-39.43482794883279, -7.627032445925354],
              [-39.434805061777155, -7.624897641696263],
              [-39.434309132376342, -7.623622565923961],
              [-39.433862819425123, -7.622583068781719],
              [-39.435148371106614, -7.621568344928773],
              [-39.436777256144246, -7.620789199949135],
              [-39.437761454355652, -7.620516437400259],
              [-39.438226837219403, -7.620298997089367],
              [-39.439043188777738, -7.61984600048098],
              [-39.439218649715841, -7.619619478983823],
              [-39.439573437563972, -7.619166995989339],
              [-39.440279148144825, -7.618433624968947],
              [-39.440469883697787, -7.617338806686923],
              [-39.440084594454049, -7.616127154627225],
              [-39.438482416476603, -7.615405215624027],
              [-39.437280799440842, -7.614926956493268],
              [-39.436720026410107, -7.613760614017414],
              [-39.436857373478375, -7.61250318666654],
              [-39.438894386795567, -7.611497053384743],
              [-39.43936363377464, -7.611107946105495],
              [-39.439420841464909, -7.610990180858678],
              [-39.439729831309819, -7.609732264653056],
              [-39.439512389537086, -7.608918799347736],
              [-39.43798653287341, -7.607165465591178],
              [-39.436899329164774, -7.606515052588532],
              [-39.436304237287978, -7.604254356645377],
              [-39.436422491633053, -7.604028348097964],
              [-39.437612676948518, -7.601864928050825],
              [-39.437849186646446, -7.601403364013311],
              [-39.43808187798021, -7.60141242115167],
              [-39.438310773348981, -7.60141193234411],
              [-39.438543464682816, -7.601420989482473],
              [-39.439180513330541, -7.601194049370123],
              [-39.439355998215298, -7.601030951493036],
              [-39.439703125688226, -7.601094350400419],
              [-39.440851354346954, -7.601282712222047],
              [-39.441717298205099, -7.601245994159111],
              [-39.442243728686378, -7.600965130278333],
              [-39.442140724045132, -7.600214110694442],
              [-39.441686774716601, -7.599690077459574],
              [-39.441312940824716, -7.597953903556647],
              [-39.442705292262652, -7.597518066321352],
              [-39.443681856287768, -7.59753525002656],
              [-39.44376197272431, -7.596323132358238],
              [-39.443998482272029, -7.596042291237326],
              [-39.444624098422544, -7.596511957939819],
              [-39.445585387549116, -7.597741235367382],
              [-39.446767958857698, -7.599259969243719],
              [-39.448599010064342, -7.600153551404278],
              [-39.450323262416241, -7.600585569242947],
              [-39.451929236422508, -7.601244573623626],
              [-39.453195719232468, -7.601441968489585],
              [-39.455907966056429, -7.601258403835842],
              [-39.456831140463386, -7.601275563497587],
              [-39.459875264156203, -7.602014174593418],
              [-39.461713952694552, -7.602446192528125],
              [-39.462061080161121, -7.602518206384254],
              [-39.463556459394148, -7.602652200820343],
              [-39.464769531729047, -7.602677951671108],
              [-39.466203862156199, -7.603101844320034],
              [-39.467127013662299, -7.603001237687605],
              [-39.467260518524242, -7.60202418565144],
              [-39.466306843673145, -7.60039723260836],
              [-39.465677431548571, -7.599927542900992],
              [-39.465345555590432, -7.59911358850278],
              [-39.464956448130017, -7.598191368705955],
              [-39.465082339714492, -7.597612503278494],
              [-39.466413666403021, -7.597402676637922],
              [-39.468351540363336, -7.598884227366297],
              [-39.470293210597767, -7.600003841267001],
              [-39.472177673330535, -7.601014723772293],
              [-39.472521005636402, -7.601312747271483],
              [-39.474806007267311, -7.602622142321433],
              [-39.47664851397704, -7.602819094668614],
              [-39.477670850911878, -7.603758450889627],
              [-39.47863977646773, -7.604408864797197],
              [-39.479791824095983, -7.604606747567023],
              [-39.480081745031008, -7.60460630581002],
              [-39.480539489763082, -7.604614850138324],
              [-39.481817428924387, -7.604296803102415],
              [-39.482622325131118, -7.604368328341303],
              [-39.484396170751211, -7.60526193446042],
              [-39.484911145842645, -7.605550412159875],
              [-39.486292064886605, -7.605693952445332],
              [-39.487211421180696, -7.605882778076946],
              [-39.489679521037971, -7.606558454077791],
              [-39.491369407696347, -7.605724453472964],
              [-39.494207569208072, -7.604852804045532],
              [-39.495660969363222, -7.604244835890849],
              [-39.495779224619071, -7.604127046694162],
              [-39.495893636849495, -7.604072704114354],
              [-39.495897478919872, -7.60395537982004],
              [-39.49618358192037, -7.603909582331438],
              [-39.496591757156772, -7.603737473700353],
              [-39.497293672670907, -7.603121402970966],
              [-39.497884946221269, -7.602207308934188],
              [-39.498140524546201, -7.600832091380131],
              [-39.498491470333519, -7.600487828732271],
              [-39.499368870695953, -7.599753968853445],
              [-39.500715448093949, -7.59856760107284],
              [-39.501772128458455, -7.597498999346719],
              [-39.502695278020639, -7.597335460090788],
              [-39.503259846368415, -7.598094582013384],
              [-39.503740502875445, -7.60058128652348],
              [-39.504293638829871, -7.601910239025742],
              [-39.505617352284226, -7.602053757264031],
              [-39.507829873412973, -7.600992792449861],
              [-39.508543221569553, -7.599508401927507],
              [-39.508684362807081, -7.598015907979621],
              [-39.510095808031146, -7.596485253312907],
              [-39.511835309143969, -7.595886342339715],
              [-39.512590613000285, -7.595605013649736],
              [-39.513056019010826, -7.595215441367578],
              [-39.513174273266905, -7.595097651170686],
              [-39.513178068382743, -7.594925985201572],
              [-39.513292527515389, -7.594988918919606],
              [-39.51398678375373, -7.59477101279927],
              [-39.514166086665163, -7.594545004300891],
              [-39.514337776487999, -7.594490637768577],
              [-39.514513213284602, -7.594435805242197],
              [-39.514974800998608, -7.594435293627609],
              [-39.516016206803528, -7.594171636068752],
              [-39.516943176483252, -7.593835939846288],
              [-39.518865800624994, -7.592838841407921],
              [-39.519331182643455, -7.59244023517959],
              [-39.519392231299278, -7.592331526880907],
              [-39.520384043758483, -7.59170637544473],
              [-39.521318649662739, -7.591035961232114],
              [-39.522257074780569, -7.590076068755461],
              [-39.522325736871259, -7.589379415982748],
              [-39.522302849756677, -7.587308034364186],
              [-39.522386761595975, -7.585579962779246],
              [-39.522764413381182, -7.583807094705917],
              [-39.52268813898371, -7.581328492482825],
              [-39.522695751357737, -7.580813027575442],
              [-39.522836894548007, -7.57937438830614],
              [-39.522794938612584, -7.578452190793294],
              [-39.52236005709775, -7.576778974452854],
              [-39.521806920191274, -7.575386598333444],
              [-39.520975318844293, -7.5732985231506],
              [-39.520593847715602, -7.57190568217682],
              [-39.519705039775445, -7.569817140951055],
              [-39.519430369352911, -7.569067076216601],
              [-39.519273977373736, -7.567972723642049],
              [-39.519163336303741, -7.567801057578884],
              [-39.519163336401405, -7.567683291284244],
              [-39.519167153569121, -7.567448225695337],
              [-39.519193859759184, -7.56578357668817],
              [-39.519266340955447, -7.564924313894861],
              [-39.519396050654549, -7.564173294500469],
              [-39.519460893575136, -7.563712172316094],
              [-39.519537191984305, -7.562563012262663],
              [-39.519273977101733, -7.56106192703748],
              [-39.518022743839332, -7.560059613009847],
              [-39.515947546827796, -7.55979972685533],
              [-39.513639657151082, -7.559928948177864],
              [-39.51248381515407, -7.560201708590499],
              [-39.510977003528424, -7.56062844179115],
              [-39.509584628170707, -7.560955592682737],
              [-39.508287643804643, -7.559265658738761],
              [-39.508249483034959, -7.55810790664443],
              [-39.510229312906972, -7.556938699282331],
              [-39.51203747815827, -7.55558017691285],
              [-39.512975927129482, -7.554791974409079],
              [-39.51414701872902, -7.553659973156977],
              [-39.514791727701194, -7.552980944756908],
              [-39.514818409843556, -7.551370662427774],
              [-39.513857097704062, -7.550141385002159],
              [-39.511610233727708, -7.550107996351716],
              [-39.510294157686985, -7.54939511454006],
              [-39.509794433112347, -7.548346047415644],
              [-39.51032086273856, -7.547893564560811],
              [-39.511713238275888, -7.547349018970664],
              [-39.513159001111674, -7.547193558092594],
              [-39.514486533781891, -7.54704717706275],
              [-39.515703400434106, -7.546666172347838],
              [-39.515779698698609, -7.545689167265546],
              [-39.515798767564846, -7.544477026545455],
              [-39.515855997247279, -7.544368293244206],
              [-39.516020027367006, -7.541600258968308],
              [-39.51650068293219, -7.540405766522727],
              [-39.518053269762127, -7.54054835194535],
              [-39.519342639716285, -7.542808559460792],
              [-39.520185673557386, -7.54420950275931],
              [-39.521394950883575, -7.5442891542773],
              [-39.522089228979425, -7.54424335612324],
              [-39.523107747666934, -7.541899215008417],
              [-39.523313732815268, -7.539945156887826],
              [-39.522661433512347, -7.537458451253863],
              [-39.522623272642768, -7.536418465455338],
              [-39.523546448244574, -7.536209127469012],
              [-39.52502652964295, -7.537500872944686],
              [-39.526560046616048, -7.538846986139192],
              [-39.528398735172352, -7.539279004062958],
              [-39.530000915128213, -7.540054820732337],
              [-39.530569300642419, -7.540587933005648],
              [-39.530763854448878, -7.54277663805173],
              [-39.529634693741855, -7.544885691485886],
              [-39.53070662404452, -7.546177413629467],
              [-39.532552949786073, -7.546319999295639],
              [-39.535246127626074, -7.543892900053701],
              [-39.536955106395126, -7.541556859455629],
              [-39.538576354068574, -7.541301747318357],
              [-39.539968729670164, -7.54068472216083],
              [-39.541078796414816, -7.539905575742097],
              [-39.542864073690481, -7.539930372062761],
              [-39.544195401390169, -7.539721011412585],
              [-39.547193773058467, -7.539717215903013],
              [-39.549730511922064, -7.539822595359654],
              [-39.551470061003208, -7.539268527110118],
              [-39.55148913076453, -7.538183209626697],
              [-39.550523999407893, -7.537243386462042],
              [-39.548105468986961, -7.536794209162858],
              [-39.546274417700765, -7.535964515626906],
              [-39.543062446463864, -7.534864924572606],
              [-39.541391605418951, -7.534785296675574],
              [-39.540525661408083, -7.534994610709327],
              [-39.53977417571744, -7.535040874814088],
              [-39.539022667071919, -7.535032772244626],
              [-39.538336050114353, -7.53484345781429],
              [-39.537534949112839, -7.534482965315518],
              [-39.537302256813646, -7.534428622449808],
              [-39.537187797680133, -7.534365664732928],
              [-39.537100067230817, -7.534454839126432],
              [-39.536310421176111, -7.535271191585573],
              [-39.536314239477804, -7.534873003974631],
              [-39.537164910508324, -7.53234864980017],
              [-39.538442848818889, -7.531858470787911],
              [-39.540125121394453, -7.531196136139542],
              [-39.541692958792169, -7.530524722451329],
              [-39.542330053386259, -7.530370238900634],
              [-39.542448285597295, -7.530306839377709],
              [-39.542562720827874, -7.530252472797507],
              [-39.542734387599296, -7.530261040887262],
              [-39.543024308536346, -7.530261041125552],
              [-39.543195952262991, -7.530323998889402],
              [-39.543199769333057, -7.530206208598338],
              [-39.543199769429883, -7.530088909301176],
              [-39.543611764872161, -7.529635913354064],
              [-39.544370885979077, -7.529019841669327],
              [-39.545271149320712, -7.526902198097874],
              [-39.546438446665412, -7.526113994779806],
              [-39.547651519155011, -7.525967637653364],
              [-39.548738722928221, -7.526563172978241],
              [-39.549589393249839, -7.527321829131682],
              [-39.550447701800451, -7.527737152348628],
              [-39.55122970989391, -7.529364129243745],
              [-39.551832440120506, -7.531163237960934],
              [-39.553850407593906, -7.531368734389085],
              [-39.554662964179592, -7.530924818716434],
              [-39.554838424976609, -7.530869964189325],
              [-39.554987203775568, -7.53225372502239],
              [-39.555151233231427, -7.536342168667558],
              [-39.555879831897762, -7.537734054930255],
              [-39.55701280891882, -7.538845567205514],
              [-39.558798086003321, -7.539105429118675],
              [-39.560030227360848, -7.537746931268848],
              [-39.56018282517374, -7.535449517153967],
              [-39.559190989574795, -7.532610423342954],
              [-39.557466760005887, -7.528777139887052],
              [-39.556444423098625, -7.527783882996925],
              [-39.555830238475423, -7.5265631728052],
              [-39.555616592460787, -7.52599907000921],
              [-39.555670027279305, -7.52575827649569],
              [-39.555761577291953, -7.523741237655321],
              [-39.555822625044094, -7.523515229059548],
              [-39.555654777476867, -7.523225795655759],
              [-39.55467821463197, -7.52297401236221],
              [-39.553701652735249, -7.522785186691511],
              [-39.552557242271391, -7.522306929617273],
              [-39.551367057796604, -7.521024216325936],
              [-39.550180667488902, -7.519505971449671],
              [-39.550138688548074, -7.518583285944443],
              [-39.550489658379178, -7.518185121618522],
              [-39.55175995937261, -7.518147472886763],
              [-39.552446601287151, -7.518390641993154],
              [-39.553018806873986, -7.518805965974084],
              [-39.554094554401651, -7.519799710902575],
              [-39.556104909646884, -7.520349006261792],
              [-39.557256956340815, -7.520483023404733],
              [-39.558580645806337, -7.520626075634541],
              [-39.559957747563821, -7.521167268522986],
              [-39.560961015802015, -7.523137576542923],
              [-39.562120699874654, -7.526383404051487],
              [-39.562528852306286, -7.529612071047181],
              [-39.565138117522466, -7.53230429706784],
              [-39.566156636404322, -7.53336144257436],
              [-39.56705692279855, -7.534816286602366],
              [-39.567152290118571, -7.53602842842184],
              [-39.566778433401979, -7.537693054145723],
              [-39.566347371099141, -7.539349134873949],
              [-39.567831294707993, -7.540359993042732],
              [-39.569883605937598, -7.541777631244574],
              [-39.57176043222853, -7.543421301949524],
              [-39.573305380605518, -7.544188060631904],
              [-39.575208936128639, -7.544113182648595],
              [-39.576849253773716, -7.542528185488695],
              [-39.577375683404455, -7.542075213634136],
              [-39.577493937660897, -7.541957400436824],
              [-39.577612193864816, -7.541903056859596],
              [-39.578031800778717, -7.540817296705248],
              [-39.578291198310659, -7.539152647884697],
              [-39.578268310186097, -7.537081267267187],
              [-39.578096668646076, -7.536791835858778],
              [-39.577307021986428, -7.535915389456296],
              [-39.576624176477374, -7.535147675490915],
              [-39.575826915580208, -7.534624107969737],
              [-39.575025814628404, -7.534199750851499],
              [-39.574571865147817, -7.533847803585335],
              [-39.573885223277209, -7.533550268802052],
              [-39.573091758786866, -7.532501714428153],
              [-39.572580602765512, -7.532086390494759],
              [-39.572469984694003, -7.531914747431596],
              [-39.572298318153727, -7.531625757021015],
              [-39.572183905019827, -7.531562334306683],
              [-39.571848186743551, -7.531155601466328],
              [-39.571619289565355, -7.530921000682962],
              [-39.571272162899639, -7.531084075420898],
              [-39.570757163853202, -7.530732175104728],
              [-39.570699957357355, -7.530614386763085],
              [-39.569849263131395, -7.529738429309509],
              [-39.569349538544067, -7.528698932124283],
              [-39.567827453108549, -7.526593208485004],
              [-39.566767026419228, -7.524450790446219],
              [-39.565641660960068, -7.522588724675759],
              [-39.564356109169303, -7.520084859619613],
              [-39.564085256997799, -7.518990995950428],
              [-39.565096162172772, -7.51733442770496],
              [-39.566869983909136, -7.518118858461696],
              [-39.567724496295035, -7.518769248266979],
              [-39.569502136108738, -7.519427298784602],
              [-39.569788239066753, -7.519435866968217],
              [-39.569868356638274, -7.518051617326203],
              [-39.569029116851411, -7.51654242967826],
              [-39.567896139019616, -7.515195828815719],
              [-39.567911412522015, -7.514454354270529],
              [-39.567533761497089, -7.51288984733405],
              [-39.567720654972646, -7.511912794341385],
              [-39.567919026791351, -7.510419835449349],
              [-39.567934300390284, -7.509560572610368],
              [-39.568533211359807, -7.508067591047217],
              [-39.56974628380776, -7.507975552593378],
              [-39.570879238693713, -7.509258241836952],
              [-39.571581153362843, -7.512097825399907],
              [-39.572801862297311, -7.514990774070442],
              [-39.573816561874125, -7.516454699133787],
              [-39.575407309406671, -7.517854689053766],
              [-39.576734817989689, -7.517825608317966],
              [-39.578813811167031, -7.514232141549272],
              [-39.579008364916696, -7.512856947946469],
              [-39.579126619120558, -7.512802582369146],
              [-39.579313535596746, -7.511825064378127],
              [-39.579805647706912, -7.509870983487496],
              [-39.579878127757794, -7.509183387666011],
              [-39.580133706355895, -7.507464396173558],
              [-39.581251410489017, -7.506151181957236],
              [-39.58296802559132, -7.506990398337377],
              [-39.583334245088139, -7.509296403823994],
              [-39.58294895688266, -7.511657706363604],
              [-39.584093367209398, -7.512307630409778],
              [-39.586500442395554, -7.513154483313068],
              [-39.587888998598487, -7.513061980006801],
              [-39.590284619237387, -7.511032555127489],
              [-39.590978897338765, -7.510986779971397],
              [-39.591993621067054, -7.512269492121988],
              [-39.591966914929657, -7.513879774456511],
              [-39.592291155386832, -7.515380859733426],
              [-39.593019752297565, -7.516483290730092],
              [-39.592260631082226, -7.517226208657875],
              [-39.591078083086565, -7.518928040495782],
              [-39.591573989367056, -7.520374805240024],
              [-39.592542937889206, -7.521088129764958],
              [-39.593267717587445, -7.522480016026707],
              [-39.593752191329109, -7.524677289269363],
              [-39.594747823100093, -7.527281294498384],
              [-39.595014856051449, -7.528673670382553],
              [-39.593519476995489, -7.528295995413422],
              [-39.591989802125163, -7.526778240241848],
              [-39.591051377146684, -7.527566419751329],
              [-39.590551652712023, -7.529973052932141],
              [-39.590074814250102, -7.531004914362407],
              [-39.588793080861571, -7.531612881668122],
              [-39.587644852137352, -7.53147842252835],
              [-39.58513095428399, -7.533571270930157],
              [-39.584902081107082, -7.536963967430959],
              [-39.586298273973554, -7.539630440618746],
              [-39.588945654822155, -7.540024810438813],
              [-39.589792529881116, -7.537508978195286],
              [-39.589838304746856, -7.534858732097332],
              [-39.591341298233694, -7.534648440593406],
              [-39.59271078580953, -7.535479087748667],
              [-39.594778347700029, -7.535973574491196],
              [-39.597135805522356, -7.536477118418505],
              [-39.598615935028569, -7.537650609614165],
              [-39.599932011224155, -7.538191825459118],
              [-39.601148901746335, -7.537982020717103],
              [-39.601850794318416, -7.537302527361675],
              [-39.602167421391705, -7.535701301205166],
              [-39.602300926445231, -7.534488694572283],
              [-39.602323813374127, -7.533168333493411],
              [-39.602350518612241, -7.531440750855197],
              [-39.602896018102797, -7.529784693216145],
              [-39.603945085251723, -7.529050368474554],
              [-39.604353260440305, -7.528941194463805],
              [-39.605551058336395, -7.529654029182933],
              [-39.607260037120696, -7.530945309861075],
              [-39.608812622727086, -7.53137732755352],
              [-39.610201180217388, -7.530941025307515],
              [-39.610700904511866, -7.528706594090881],
              [-39.610731428916814, -7.526743408865277],
              [-39.610681835597866, -7.526345733204544],
              [-39.610628424216586, -7.526056300892895],
              [-39.610571194720642, -7.525938512550792],
              [-39.610582650206716, -7.525251404672583],
              [-39.610601719027336, -7.524093628617816],
              [-39.610674199222018, -7.523233900823212],
              [-39.610693291989811, -7.522139548389085],
              [-39.610319435266923, -7.520177364826795],
              [-39.609823528888548, -7.518848412374249],
              [-39.609094907268457, -7.517393079487449],
              [-39.6083243545133, -7.515250195682831],
              [-39.607313448995512, -7.513678051264807],
              [-39.606825179083941, -7.511715867611822],
              [-39.606325454642295, -7.510495157510477],
              [-39.605661677746191, -7.508813839032856],
              [-39.605436622584051, -7.508524872578514],
              [-39.604452423504767, -7.508616421222696],
              [-39.603349968922814, -7.509124738274211],
              [-39.602243719375906, -7.509623508379722],
              [-39.601545623875658, -7.510185213443292],
              [-39.600492761762638, -7.510910505236093],
              [-39.599302577139312, -7.509799458912525],
              [-39.599211028974992, -7.508361308449694],
              [-39.598665528424497, -7.506453491427563],
              [-39.597540187957698, -7.504591913653718],
              [-39.597894951860795, -7.504075495037609],
              [-39.600523286001938, -7.505501234654244],
              [-39.602419182116705, -7.505996234244897],
              [-39.604357055974759, -7.504012560713592],
              [-39.603884060808511, -7.501010391305156],
              [-39.602323814570013, -7.497765029463909],
              [-39.601667697137898, -7.495450456790242],
              [-39.601465505951083, -7.493777240647324],
              [-39.601709652922366, -7.492917487997395],
              [-39.603960335896083, -7.49295087664033],
              [-39.605871504759591, -7.492405376472841],
              [-39.607668213481354, -7.491805976534333],
              [-39.60909111230557, -7.493097745962315],
              [-39.609739593651703, -7.495583962602834],
              [-39.610258410860574, -7.495709854273932],
              [-39.6104796469088, -7.496170952693677],
              [-39.610567399811572, -7.497961959039617],
              [-39.610655130766325, -7.499689540765797],
              [-39.611784290588346, -7.501089530307886],
              [-39.611891090307957, -7.501722784603517],
              [-39.611185379719664, -7.502456644630436],
              [-39.61001808236756, -7.503244847953407],
              [-39.608904172255947, -7.504494637556061],
              [-39.608175574690591, -7.506612281278096],
              [-39.609018632454379, -7.508121957930402],
              [-39.610319435002438, -7.509585371235941],
              [-39.611673648790635, -7.511509881825531],
              [-39.612749397280432, -7.512557994434112],
              [-39.614530856210429, -7.513052922934165],
              [-39.615465462128498, -7.512372963772054],
              [-39.614805527436133, -7.510411245973976],
              [-39.613737415472826, -7.508657912594033],
              [-39.613245304304016, -7.507039526879561],
              [-39.614115067294691, -7.506830188846608],
              [-39.615831682507149, -7.507542580990147],
              [-39.617632209498737, -7.510226238402037],
              [-39.618711776955109, -7.511282895963023],
              [-39.620100333304535, -7.511018748684381],
              [-39.621267631800499, -7.510058391391412],
              [-39.622740100791752, -7.508292647169911],
              [-39.624605494506945, -7.507113916756927],
              [-39.625265430085257, -7.505575136509656],
              [-39.625276885711749, -7.504715897662624],
              [-39.623407673587153, -7.502782793701583],
              [-39.621073100776144, -7.500841589408771],
              [-39.619451830782836, -7.497822726154894],
              [-39.620382618586007, -7.497314920958384],
              [-39.622507409168783, -7.497700652390348],
              [-39.624006583397886, -7.497843238765553],
              [-39.62574608658192, -7.497180904156926],
              [-39.626612031511748, -7.497079808471963],
              [-39.62739403957103, -7.498761151046997],
              [-39.628019656783621, -7.499176452073678],
              [-39.628366760317562, -7.499175986361108],
              [-39.629000013803683, -7.49919316877744],
              [-39.629465419683562, -7.498966182517893],
              [-39.629526444436038, -7.498740173920921],
              [-39.630148242296272, -7.499616596184174],
              [-39.630407640127572, -7.501226389760332],
              [-39.630247427993432, -7.503985390113674],
              [-39.629740067086296, -7.507034289447887],
              [-39.630518280170755, -7.508715608023544],
              [-39.631868676898989, -7.510748850963632],
              [-39.632032706256503, -7.511327249636802],
              [-39.631086667709198, -7.512866471648178],
              [-39.629392938764987, -7.514053817151003],
              [-39.627401676285764, -7.516028387698015],
              [-39.62645561488052, -7.517395500739048],
              [-39.626959181533415, -7.518326778579853],
              [-39.628740616615417, -7.518640518166804],
              [-39.629827820442976, -7.519181733821961],
              [-39.631586391402791, -7.521051879077705],
              [-39.633016904739527, -7.521655562642195],
              [-39.633436512467974, -7.520795812131336],
              [-39.632028863201235, -7.51869916851907],
              [-39.630781495123422, -7.517416502168932],
              [-39.63007576233899, -7.514749516548646],
              [-39.630991299535985, -7.515110009143636],
              [-39.632936763871456, -7.516129017644582],
              [-39.634245226799365, -7.517067908101916],
              [-39.636473022845315, -7.518430713779042],
              [-39.638887735370645, -7.518815979459379],
              [-39.640322065778378, -7.519302363728838],
              [-39.642092069323816, -7.520367146814396],
              [-39.643843027913995, -7.522752290986556],
              [-39.644972187585502, -7.524333492457462],
              [-39.646169986397069, -7.525155083528962],
              [-39.646684984537018, -7.525398764495286],
              [-39.647215209384271, -7.52465582337538],
              [-39.647581428874098, -7.523334974577161],
              [-39.648313845607305, -7.520991320198322],
              [-39.648554173565309, -7.52018593521355],
              [-39.647772164367332, -7.518676305600689],
              [-39.646864264443074, -7.517918114389643],
              [-39.645941113015404, -7.517900930733349],
              [-39.645067554757098, -7.518463124651857],
              [-39.644316047012907, -7.518563753431916],
              [-39.642595613830821, -7.517842769330551],
              [-39.641535138140519, -7.515691760327893],
              [-39.640638669852208, -7.514064784325583],
              [-39.640077896649117, -7.513079628759381],
              [-39.638826687462249, -7.511960014430823],
              [-39.637461041036516, -7.510903334632168],
              [-39.63700709059691, -7.510497044690641],
              [-39.636560753535605, -7.509566279894004],
              [-39.636629415614742, -7.508878683065258],
              [-39.637441949118873, -7.508542521744133],
              [-39.637796736068296, -7.507972225448335],
              [-39.638208707469964, -7.507573595172175],
              [-39.639475190451137, -7.507598857060769],
              [-39.639665925998116, -7.506504505766352],
              [-39.640249586217777, -7.506060099904972],
              [-39.641454998304901, -7.506483527360607],
              [-39.641855560407258, -7.506546484312715],
              [-39.6423209653328, -7.506265131378419],
              [-39.642496427131078, -7.506210788847671],
              [-39.642790166230952, -7.505984291444193],
              [-39.643724749241152, -7.505196064928234],
              [-39.644190155166655, -7.504914758993609],
              [-39.645517686856529, -7.504768354959365],
              [-39.646269172556572, -7.504721624855835],
              [-39.6474288566328, -7.504340155090394],
              [-39.647608135642585, -7.503996357294788],
              [-39.646486612285358, -7.501962624544383],
              [-39.644781452566384, -7.500544986629168],
              [-39.643064837444641, -7.4997152931866],
              [-39.641691531742758, -7.499057266998523],
              [-39.639738406122618, -7.498444990061452],
              [-39.638246845023012, -7.498076395044615],
              [-39.636755308109862, -7.497481302383755],
              [-39.63578638248412, -7.49688574515436],
              [-39.634634334876175, -7.496634449714236],
              [-39.634130791168118, -7.495766618496596],
              [-39.633802732354425, -7.494727098450794],
              [-39.634458873727887, -7.493468693522059],
              [-39.637922605495056, -7.493011436097694],
              [-39.640051215062435, -7.493388133587037],
              [-39.642008157894189, -7.493710954258422],
              [-39.642736755669262, -7.494985541226319],
              [-39.643976533374314, -7.496792752425836],
              [-39.645563461658071, -7.498545131239191],
              [-39.646707872224518, -7.498914214968935],
              [-39.648123111766864, -7.496976339727161],
              [-39.64721141562196, -7.492871249548929],
              [-39.647116048244534, -7.491722530346368],
              [-39.648977623990625, -7.490543800926962],
              [-39.651407586978458, -7.490241958724533],
              [-39.653143271009242, -7.489805167760911],
              [-39.655512208528506, -7.48938556021314],
              [-39.657884941066087, -7.488911608993685],
              [-39.659571032636322, -7.4882487863418],
              [-39.661543250405721, -7.487305122604873],
              [-39.663393370432452, -7.487103420327545],
              [-39.66506039238017, -7.487363260138266],
              [-39.668451667639836, -7.48816294113161],
              [-39.670355222139847, -7.488141963816902],
              [-39.671675094419264, -7.488565390364937],
              [-39.673166631428465, -7.489051284680097],
              [-39.674604781151608, -7.489130447385159],
              [-39.675299060304333, -7.48903030755329],
              [-39.67606199949104, -7.488296469568991],
              [-39.679293040695754, -7.488119542275458],
              [-39.680384039653525, -7.488479569015789],
              [-39.681700115864665, -7.489020295859793],
              [-39.683142083562096, -7.489099457568214],
              [-39.683546441729, -7.489053683173505],
              [-39.684469592161442, -7.489070377830386],
              [-39.684988386471616, -7.489078480207182],
              [-39.685560592216653, -7.489312591275866],
              [-39.68596113022479, -7.489492848526001],
              [-39.686708819902634, -7.48951000903635],
              [-39.687403099011938, -7.48946376888212],
              [-39.68809355814841, -7.489417504724926],
              [-39.688673400167126, -7.489253475181424],
              [-39.689314290893812, -7.48891777971472],
              [-39.689722467272105, -7.488582573054287],
              [-39.690191644366585, -7.488066153527736],
              [-39.69042433484897, -7.487903079693882],
              [-39.690771486429782, -7.487848224306585],
              [-39.690893535846342, -7.487504426462984],
              [-39.690901173075012, -7.487160628525563],
              [-39.690962220879833, -7.486871195306844],
              [-39.690794373450757, -7.48641009692726],
              [-39.690344242077295, -7.485886041693011],
              [-39.687826525077917, -7.484515131830521],
              [-39.686293008167581, -7.483052208325754],
              [-39.684927361731106, -7.481995994526027],
              [-39.684591665641591, -7.481354660088053],
              [-39.685076117281135, -7.480042402335759],
              [-39.685480498500098, -7.479933204320162],
              [-39.687387848125937, -7.479731014091498],
              [-39.687906641577491, -7.47956747349468],
              [-39.688719175988993, -7.479349078466562],
              [-39.689066303517812, -7.479357622699623],
              [-39.690042865571755, -7.479374340399184],
              [-39.691255936992043, -7.479336202620616],
              [-39.69240798465983, -7.479524073440017],
              [-39.693731675287772, -7.479494993697891],
              [-39.694078824868726, -7.479440161310182],
              [-39.694368746803775, -7.47944872949633],
              [-39.696447763302416, -7.479137830058492],
              [-39.69776763463814, -7.479497857985719],
              [-39.699430839285093, -7.480164475360488],
              [-39.701025426861307, -7.481509633621061],
              [-39.702055378203056, -7.481933060932309],
              [-39.702856479265577, -7.482239185757773],
              [-39.704409042040439, -7.482490015529742],
              [-39.707113651521908, -7.482883872390499],
              [-39.707872773507688, -7.482430410724271],
              [-39.707258611914625, -7.481155823843396],
              [-39.706629176767535, -7.480686576134065],
              [-39.70663299588162, -7.480514933163665],
              [-39.706640633110162, -7.480171135226013],
              [-39.706648269531954, -7.479592270694219],
              [-39.706663520227146, -7.478615218550148],
              [-39.707250976572027, -7.477990090769926],
              [-39.7091621445024, -7.477390224922603],
              [-39.711527287736509, -7.477368291990554],
              [-39.712450438171921, -7.477384986646703],
              [-39.713423182779955, -7.477980078884586],
              [-39.714556136730437, -7.479208402467266],
              [-39.715532699601269, -7.479451128815804],
              [-39.716623698794315, -7.479530756233412],
              [-39.716974668490209, -7.479304258875275],
              [-39.717058580218996, -7.477693976572877],
              [-39.716558855715974, -7.476537155081235],
              [-39.715139797898829, -7.475182475020399],
              [-39.713373589544268, -7.473828725670521],
              [-39.711775228753766, -7.472773000676333],
              [-39.710642250854804, -7.471481253475263],
              [-39.708982865267259, -7.470705902755237],
              [-39.708235175631522, -7.47063486556844],
              [-39.707369230738372, -7.470681595580473],
              [-39.706331643115462, -7.470664901831459],
              [-39.705118571693021, -7.470703038610912],
              [-39.70431367551533, -7.470569067753902],
              [-39.704275513729463, -7.469420349594436],
              [-39.705622115147712, -7.468233027798568],
              [-39.707941459367035, -7.467406198641503],
              [-39.710787211215894, -7.466135429570197],
              [-39.712767042134864, -7.464956211242916],
              [-39.714861309330992, -7.463668772656204],
              [-39.716722884993125, -7.462607295527404],
              [-39.717596443166997, -7.462153833953866],
              [-39.718756127252121, -7.461771875186455],
              [-39.719107073037137, -7.461436668478343],
              [-39.719458019733338, -7.461209681122915],
              [-39.720037860894344, -7.460874474601904],
              [-39.719366470658869, -7.459654231352363],
              [-39.718637872925392, -7.45831670975803],
              [-39.718325064798336, -7.456300160565926],
              [-39.718340315446532, -7.45537747409831],
              [-39.719496180373014, -7.45522205897194],
              [-39.720636771748147, -7.455871496018343],
              [-39.723413862549421, -7.458915132080469],
              [-39.724653639226993, -7.460776242961796],
              [-39.727041646648338, -7.462771324981622],
              [-39.729399128511403, -7.463273914903417],
              [-39.730963169863522, -7.462728880441684],
              [-39.731916845695757, -7.460782436861511],
              [-39.732042737270326, -7.460212606372151],
              [-39.731428552628287, -7.458992385167707],
              [-39.730986034672192, -7.45788944240241],
              [-39.730760980698463, -7.457365409352668],
              [-39.730776253346818, -7.456442745883544],
              [-39.730955556349819, -7.456107516035032],
              [-39.731543012746805, -7.455419453630219],
              [-39.731775703089141, -7.455428021769043],
              [-39.732122830760808, -7.45526448203071],
              [-39.733091780354556, -7.455914405935079],
              [-39.73427814660554, -7.457540428179729],
              [-39.735186047536082, -7.458307653333369],
              [-39.736032899625009, -7.459409618435547],
              [-39.735731545960526, -7.460387136343696],
              [-39.735475968503529, -7.461934484881836],
              [-39.737009462514379, -7.46328870003815],
              [-39.738737533044073, -7.463548095900374],
              [-39.739031249340051, -7.463086043903266],
              [-39.740091746888602, -7.461781887568376],
              [-39.740168045290254, -7.460633191499194],
              [-39.73989719316107, -7.459475906197753],
              [-39.738882492507258, -7.458075426742896],
              [-39.73837131365913, -7.457434094160299],
              [-39.737753332709346, -7.456621072517288],
              [-39.73695225587246, -7.456034570370289],
              [-39.736841637753287, -7.45591727098135],
              [-39.736841636849903, -7.455799480685802],
              [-39.736498305433017, -7.455619246483181],
              [-39.735868870097136, -7.455376054423437],
              [-39.735758252207866, -7.45497837971138],
              [-39.735647611140827, -7.454797655701845],
              [-39.736059605731064, -7.454172504777223],
              [-39.737680853492961, -7.453853503009245],
              [-39.739244871750238, -7.453425791844595],
              [-39.739901012180006, -7.452103988285471],
              [-39.739576771754258, -7.45054902632041],
              [-39.738268308811755, -7.449601053921945],
              [-39.737410001239198, -7.44918668469974],
              [-39.736322820438097, -7.448591592371795],
              [-39.735353894791288, -7.44800509008918],
              [-39.735411101482008, -7.447887301840426],
              [-39.733820377867133, -7.446542143588779],
              [-39.732511938064832, -7.445423018216001],
              [-39.731432371683972, -7.444249038358596],
              [-39.730646544256814, -7.443029282015042],
              [-39.730608406563917, -7.441762798561055],
              [-39.730673250429788, -7.441364656995995],
              [-39.732420388860255, -7.440358035442861],
              [-39.733995862752906, -7.439052925535437],
              [-39.734423106840417, -7.437686324059954],
              [-39.733232922435064, -7.436277253520816],
              [-39.731691769045391, -7.435447560229612],
              [-39.729677618640693, -7.435070862842917],
              [-39.728712488210107, -7.434186338349737],
              [-39.728498866523786, -7.433209775019676],
              [-39.731298866410299, -7.431070664100523],
              [-39.731794773674871, -7.42888961855628],
              [-39.730150637628071, -7.427309371675414],
              [-39.728792604736654, -7.425502626383818],
              [-39.72886126772228, -7.424923761904324],
              [-39.730085794768918, -7.424026315271851],
              [-39.733553344598512, -7.423505169211408],
              [-39.737970775076739, -7.424962855081395],
              [-39.741236158791693, -7.426341400489262],
              [-39.742384387398687, -7.426647037594966],
              [-39.743544047537803, -7.426202145200762],
              [-39.744245963063577, -7.425585607461061],
              [-39.746618695675828, -7.425048210607393],
              [-39.747999615862881, -7.425073006582799],
              [-39.748350584610826, -7.42478308660307],
              [-39.748823603811466, -7.42410359205352],
              [-39.749060113363711, -7.423822727926402],
              [-39.749407241042697, -7.423650595238787],
              [-39.751200155582843, -7.423339696557729],
              [-39.751493894876901, -7.422878109558437],
              [-39.751554920584752, -7.422705977637985],
              [-39.752916770694824, -7.420542092692628],
              [-39.753435564097934, -7.420441951713758],
              [-39.7543549204253, -7.420630312334604],
              [-39.75527043555109, -7.421099047274908],
              [-39.756132561144291, -7.421459050822426],
              [-39.757379975296594, -7.42268737448877],
              [-39.757826289416258, -7.423563820608746],
              [-39.759409398619916, -7.42548786538743],
              [-39.760309686092469, -7.426888320743262],
              [-39.761274770393811, -7.427935943262749],
              [-39.762190307556651, -7.428359347475803],
              [-39.762476410388267, -7.428530547684829],
              [-39.763723825540637, -7.42975840535701],
              [-39.764341804206019, -7.430924283887656],
              [-39.765295480159104, -7.432496405262679],
              [-39.767691099780151, -7.434202032015322],
              [-39.768949947413731, -7.4347423158107],
              [-39.76911018070814, -7.435438503778439],
              [-39.769098726086149, -7.436297766631172],
              [-39.769655680235452, -7.437400221493363],
              [-39.769918895140613, -7.438901305732914],
              [-39.771452412019947, -7.440427187860971],
              [-39.773256758038656, -7.44310129834656],
              [-39.773935763603731, -7.44385993136566],
              [-39.776899793802961, -7.44604386273081],
              [-39.777128689040126, -7.446215040894541],
              [-39.778036566879202, -7.447100054346103],
              [-39.780363548512064, -7.449384079595725],
              [-39.780477984656294, -7.44943846936416],
              [-39.780706881026838, -7.449447061499785],
              [-39.783876873518032, -7.449495675799141],
              [-39.784800047005689, -7.449458026778432],
              [-39.78670358049461, -7.449481869191222],
              [-39.788664341519272, -7.449577702220681],
              [-39.791140078895502, -7.449672627676484],
              [-39.792753690343353, -7.449814702146182],
              [-39.795172197170842, -7.449855213880614],
              [-39.79725503266598, -7.449544315440322],
              [-39.798487196953019, -7.448302649865769],
              [-39.800245768029797, -7.446427243507564],
              [-39.801676281327843, -7.443457972416859],
              [-39.802454472216162, -7.441747061274972],
              [-39.803686636747351, -7.440207372480736],
              [-39.80571224169487, -7.439841618319708],
              [-39.807863715417945, -7.438661934124388],
              [-39.808813595178748, -7.436842312771791],
              [-39.809366708949852, -7.434779034551402],
              [-39.810263178192329, -7.432832590913269],
              [-39.810617942190291, -7.432207951935093],
              [-39.811750897210167, -7.43337285189857],
              [-39.812700776719318, -7.435523860821337],
              [-39.813025017385684, -7.436789390524825],
              [-39.814234270754099, -7.436868553038788],
              [-39.815561803425709, -7.436775583677661],
              [-39.815809744490736, -7.439434396996087],
              [-39.815668602222765, -7.44098128064],
              [-39.815748719764343, -7.443287727927698],
              [-39.816202669163758, -7.443756951495691],
              [-39.817015202683322, -7.443420767167623],
              [-39.817907829903803, -7.441537746144627],
              [-39.818525808691739, -7.438895580432355],
              [-39.818911097986245, -7.436425568502264],
              [-39.819056059367057, -7.434707042887146],
              [-39.82023099230522, -7.433167332044293],
              [-39.821787374260772, -7.433191640168886],
              [-39.822477835260244, -7.433325633931686],
              [-39.823164475204877, -7.433559769093224],
              [-39.825586825102512, -7.433492061474179],
              [-39.828722521934942, -7.432219849632195],
              [-39.830744308922583, -7.431845061520421],
              [-39.833513808364245, -7.431776865187327],
              [-39.836744825532094, -7.431716306185275],
              [-39.838541558255486, -7.431170341912098],
              [-39.840059801573283, -7.429864740948419],
              [-39.842356259855968, -7.430421230498406],
              [-39.842379146804788, -7.432727677742903],
              [-39.840956246870185, -7.435181506927726],
              [-39.8394341853488, -7.436767459214176],
              [-39.838152450786104, -7.437547558509032],
              [-39.837111045077798, -7.437649120052113],
              [-39.835146464780031, -7.437906141913007],
              [-39.831663641217553, -7.439404852118314],
              [-39.830900700922847, -7.440256477409187],
              [-39.831110505302334, -7.441640262316524],
              [-39.83254101763162, -7.442297823558365],
              [-39.834204245454465, -7.442782764021424],
              [-39.835688146123992, -7.443784123254624],
              [-39.837515402310707, -7.44484795139592],
              [-39.83817531496846, -7.446881660792756],
              [-39.838324117593352, -7.448491477303184],
              [-39.838316480224286, -7.449006919219643],
              [-39.83777479888623, -7.450436967238979],
              [-39.836714301228632, -7.451859376879551],
              [-39.836176439093563, -7.453009027576118],
              [-39.835764444498253, -7.453633689509777],
              [-39.83534101841903, -7.454945948329289],
              [-39.835249468365362, -7.457017329888979],
              [-39.835932292046976, -7.45760431994397],
              [-39.837542107336425, -7.457981017013482],
              [-39.837759525224399, -7.458677205035929],
              [-39.836981334143154, -7.460614125837195],
              [-39.835642370976714, -7.461403772027088],
              [-39.836432016426208, -7.462568671720994],
              [-39.837523015545038, -7.462756543493509],
              [-39.837339917275806, -7.463444629236692],
              [-39.83600859038998, -7.463827053863243],
              [-39.833921936726469, -7.464364450945848],
              [-39.832750844088778, -7.465497407223819],
              [-39.832861462112291, -7.465732007914257],
              [-39.834524666880924, -7.466280860001623],
              [-39.835852175414168, -7.466359533620233],
              [-39.838282160602716, -7.465875547500566],
              [-39.840242898876113, -7.465681948263136],
              [-39.84219986680467, -7.465940391325008],
              [-39.845907744319561, -7.468646959214516],
              [-39.847601449496736, -7.470868515348649],
              [-39.84827665894926, -7.471798838352034],
              [-39.848654333844649, -7.473534989303987],
              [-39.849367680938769, -7.475785673462559],
              [-39.849001460345811, -7.477224755577089],
              [-39.847181841692247, -7.479091104949352],
              [-39.845137166631709, -7.48095841013081],
              [-39.845007456886023, -7.481763796219858],
              [-39.844984546817159, -7.483256288298122],
              [-39.84472899129841, -7.484866571482822],
              [-39.844183491794858, -7.4865226281564],
              [-39.843985118870201, -7.488133376385766],
              [-39.844244516600206, -7.489869085246606],
              [-39.84457259837351, -7.490962947992474],
              [-39.844320814923528, -7.492455930881125],
              [-39.844309383429085, -7.493152584716989],
              [-39.844343703042824, -7.494536368492627],
              [-39.844339883883109, -7.494762377141647],
              [-39.844591667194393, -7.49706838259708],
              [-39.844576416442784, -7.498108369382832],
              [-39.844278859130689, -7.498615707112508],
              [-39.843927913234474, -7.499077295071063],
              [-39.843748610129765, -7.499530290222123],
              [-39.843618923382735, -7.5003351883157],
              [-39.843534987503524, -7.502117159620887],
              [-39.843668516552931, -7.504540907279007],
              [-39.843428187685689, -7.505228969978451],
              [-39.842482126261729, -7.506587469098938],
              [-39.842115929806674, -7.507854442243098],
              [-39.841818371352083, -7.508533445949436],
              [-39.841745914243944, -7.509284000414868],
              [-39.84260038051211, -7.510105591221116],
              [-39.843386207839522, -7.511442645898024],
              [-39.843130630270899, -7.5131072947634],
              [-39.84166579757143, -7.514403813825894],
              [-39.841414014117738, -7.515896796718022],
              [-39.841978605535402, -7.516601086985569],
              [-39.843370958036125, -7.516164296738357],
              [-39.844008006802895, -7.515828135267895],
              [-39.845659778982885, -7.517181907561601],
              [-39.846685934087617, -7.517939562892696],
              [-39.847662472963449, -7.518190858201044],
              [-39.848288113001487, -7.518831724897879],
              [-39.848036329734121, -7.520098698138803],
              [-39.847940962459191, -7.522450454044036],
              [-39.849943679483999, -7.523405550006569],
              [-39.852713156973742, -7.52328254602762],
              [-39.855375833513037, -7.522807128060437],
              [-39.857245024496009, -7.521337987358934],
              [-39.857679905082755, -7.519329075689022],
              [-39.857531126568269, -7.517601958858238],
              [-39.857542557914655, -7.517086516939371],
              [-39.858168175140875, -7.51750135198378],
              [-39.859072279630993, -7.518837941765489],
              [-39.859331677309413, -7.520628458310448],
              [-39.859194330283984, -7.521831520028431],
              [-39.859186716910294, -7.522346983950767],
              [-39.860975812260186, -7.522325540556674],
              [-39.863356182190408, -7.521380433154444],
              [-39.865313125263441, -7.521466743256961],
              [-39.865568703675464, -7.523610115698496],
              [-39.864565436553477, -7.525086404074238],
              [-39.863386706628532, -7.526617057980984],
              [-39.863337113351022, -7.529782791080991],
              [-39.863951273891587, -7.531120312621502],
              [-39.864225921271199, -7.531933801002934],
              [-39.863691877149947, -7.533020050090717],
              [-39.861612884761641, -7.53315069259234],
              [-39.858564917857052, -7.532532711751925],
              [-39.855963289794076, -7.533116838119748],
              [-39.853182379948798, -7.533764387961289],
              [-39.850931697680977, -7.534012817619115],
              [-39.848669583103714, -7.534731893463353],
              [-39.846830893438153, -7.534355662183764],
              [-39.845797101871128, -7.534221667126908],
              [-39.845507203930985, -7.534213564935381],
              [-39.844980773185206, -7.534784350098902],
              [-39.844107191002756, -7.535229242725337],
              [-39.843821089081437, -7.535166262863901],
              [-39.843306114021154, -7.534814361534638],
              [-39.842325733023117, -7.534744280141187],
              [-39.842016742978736, -7.536228205043388],
              [-39.841887033175894, -7.537096524762191],
              [-39.842333369248614, -7.538018256639569],
              [-39.843298476632576, -7.53895714784436],
              [-39.843466325105595, -7.539363903559849],
              [-39.84340148123389, -7.539762044134058],
              [-39.843279407857878, -7.540051500308443],
              [-39.843279407759965, -7.540169265606826],
              [-39.84303526172917, -7.541083382958507],
              [-39.842203659482479, -7.542450474125165],
              [-39.841452173668443, -7.542614969522791],
              [-39.840986768783651, -7.542832898839018],
              [-39.840479407001048, -7.542082833887668],
              [-39.83923201586456, -7.540846384221733],
              [-39.837984600908968, -7.539392494852],
              [-39.836073432014295, -7.536302594682735],
              [-39.834532301709764, -7.535365123994477],
              [-39.833029308286399, -7.535449524248237],
              [-39.832262549815219, -7.536536240137524],
              [-39.832357916945149, -7.537973902648765],
              [-39.832964465303853, -7.539591821509222],
              [-39.833513806014821, -7.541273162944925],
              [-39.834703967367659, -7.542736087213233],
              [-39.835772078351233, -7.544479898707579],
              [-39.836954626569629, -7.546168854624403],
              [-39.838549169201144, -7.547459180258227],
              [-39.840105573156443, -7.547483488403055],
              [-39.841322441712165, -7.547273195664151],
              [-39.842772045808523, -7.546835938470099],
              [-39.844564960465974, -7.546407739506452],
              [-39.845079935668977, -7.546587508862276],
              [-39.844996023878345, -7.548251667879743],
              [-39.84406905307312, -7.548697050458497],
              [-39.843946979696817, -7.548986505633136],
              [-39.84393172994627, -7.550017877477024],
              [-39.84351973553246, -7.55041653076091],
              [-39.841848894386814, -7.550392688517801],
              [-39.841162253452808, -7.550149496397288],
              [-39.840872332558391, -7.550087027529026],
              [-39.840700665826716, -7.550024093761651],
              [-39.840586229682579, -7.549969727990612],
              [-39.84018569052639, -7.549961624706907],
              [-39.839312109298625, -7.550460862008844],
              [-39.838499576872323, -7.55067925603431],
              [-39.837252160775307, -7.549397522635929],
              [-39.836275622045115, -7.548974095349131],
              [-39.835363902945993, -7.548442402761199],
              [-39.83439115827953, -7.547910268125505],
              [-39.833357366901971, -7.547550706411183],
              [-39.831919217208053, -7.547408633066207],
              [-39.830530683824151, -7.547673757336133],
              [-39.829821130856558, -7.548868692628474],
              [-39.82973721805805, -7.550542398586827],
              [-39.830576457856665, -7.552051119293719],
              [-39.831877260189891, -7.553794442986234],
              [-39.832705043391215, -7.556117093836336],
              [-39.832922485135036, -7.556976380897987],
              [-39.833487076450858, -7.557797994472256],
              [-39.834848926727815, -7.559088762913714],
              [-39.835997132293976, -7.559448789722107],
              [-39.836981331452058, -7.559292862467565],
              [-39.838087605227955, -7.558558047762206],
              [-39.83895734433974, -7.558230944432156],
              [-39.840109392067987, -7.558364449592979],
              [-39.840223828204962, -7.558427407312973],
              [-39.840277239683573, -7.558599050334992],
              [-39.84026960226273, -7.559168879934921],
              [-39.840197121954141, -7.560154989001797],
              [-39.839937725358482, -7.561873980548926],
              [-39.83949138827176, -7.564570024122625],
              [-39.838785678462109, -7.565539438763586],
              [-39.838995504919623, -7.566805457398742],
              [-39.839903405705009, -7.567744325562238],
              [-39.840651072251731, -7.567932662061956],
              [-39.841623817015538, -7.56834705440273],
              [-39.842249433041637, -7.568996980052686],
              [-39.844130078500676, -7.570458483913805],
              [-39.84543472218202, -7.571749298313195],
              [-39.846861417187668, -7.572814081160307],
              [-39.847597651438178, -7.573454458960216],
              [-39.848043965310758, -7.574611280444867],
              [-39.848253768759498, -7.575886333029254],
              [-39.848291929816668, -7.576691252269559],
              [-39.848352954286469, -7.576808553622119],
              [-39.849413452788582, -7.579185571355651],
              [-39.849688100072804, -7.580107303098013],
              [-39.849901745637084, -7.581210711708437],
              [-39.850225986229304, -7.58253966406883],
              [-39.850584568276886, -7.585424045201261],
              [-39.850580750114837, -7.58565007785289],
              [-39.850561681288724, -7.586798772001025],
              [-39.850252668470205, -7.588002322581252],
              [-39.84984831118971, -7.58817492021721],
              [-39.849501183655143, -7.588166839976081],
              [-39.848597100935365, -7.587119217457311],
              [-39.847868480214878, -7.585782161807643],
              [-39.847258137845884, -7.584218608604976],
              [-39.846705001026407, -7.582708933129803],
              [-39.845915355593654, -7.581544009406165],
              [-39.845354605487181, -7.580441577501481],
              [-39.84468321430785, -7.579167456527028],
              [-39.843573146451625, -7.576438024851483],
              [-39.843176402699271, -7.575914481638219],
              [-39.842497398241072, -7.575038035291316],
              [-39.842157883985351, -7.574631769427657],
              [-39.841127910536052, -7.574326597387996],
              [-39.840151346799679, -7.573912229043017],
              [-39.838366069571549, -7.573762028452866],
              [-39.837431463525981, -7.574559777925899],
              [-39.838152447825763, -7.576466640172231],
              [-39.839041279739703, -7.578608616159277],
              [-39.839769877460959, -7.579945670805733],
              [-39.840097936215138, -7.581048591513015],
              [-39.839849994010777, -7.582197775465724],
              [-39.83881620258952, -7.581892114424766],
              [-39.836649454273378, -7.580295663125467],
              [-39.835855989671934, -7.579365340003882],
              [-39.834711579230692, -7.578825078268308],
              [-39.83350996318768, -7.57829340843421],
              [-39.831854372001921, -7.576994514790895],
              [-39.830622231572818, -7.574789604895575],
              [-39.829958453777351, -7.572990985054402],
              [-39.829329041575349, -7.572585183947853],
              [-39.828943754250261, -7.575046160972236],
              [-39.829424409813278, -7.577478476888162],
              [-39.830603162620143, -7.579682920742699],
              [-39.831102887059942, -7.580903141893216],
              [-39.83108761340447, -7.581816770440904],
              [-39.830214056214018, -7.582270721011118],
              [-39.829458776148122, -7.582769491412899],
              [-39.82914594406332, -7.584325896886036],
              [-39.831041838369494, -7.584638705602012],
              [-39.832365551781585, -7.584889487211102],
              [-39.833509963222021, -7.585429771947525],
              [-39.834471251240366, -7.586830250411091],
              [-39.835085435921776, -7.587986583039331],
              [-39.835371538698602, -7.588221182881165],
              [-39.836054362514133, -7.588636040980584],
              [-39.8367333899252, -7.589566341008865],
              [-39.836840188729653, -7.590090862975816],
              [-39.836885963731653, -7.590895759223149],
              [-39.836657090549807, -7.590660693431545],
              [-39.835840739718492, -7.591395018380528],
              [-39.834608598355388, -7.592699640588473],
              [-39.834284334728203, -7.59499800863859],
              [-39.834028780243138, -7.596544868219089],
              [-39.833769359678953, -7.598218573041685],
              [-39.833414594721781, -7.598780278402872],
              [-39.831991695687329, -7.597724553499247],
              [-39.83080532940874, -7.596153385861302],
              [-39.830027138572248, -7.594183076940139],
              [-39.829279448929171, -7.59411203973975],
              [-39.828814067044057, -7.594329946054733],
              [-39.828516508720284, -7.594845900735509],
              [-39.827425509562211, -7.594712371620915],
              [-39.826632045061309, -7.593664771195571],
              [-39.825655483183553, -7.593422533823684],
              [-39.824423341730778, -7.594835865383336],
              [-39.824373748794322, -7.595179686295264],
              [-39.824366111750209, -7.595296986590681],
              [-39.824278381311274, -7.596563470978465],
              [-39.824091463870154, -7.597477565381433],
              [-39.823553578673007, -7.598681580765958],
              [-39.822512172777934, -7.599009150947614],
              [-39.821817917703832, -7.599001537413981],
              [-39.821192277583336, -7.598468913062801],
              [-39.820227170299717, -7.597421290494393],
              [-39.819254426448836, -7.597115606503149],
              [-39.818155814247177, -7.597099912680707],
              [-39.816507837021817, -7.599372995774542],
              [-39.815443520327634, -7.600849284098452],
              [-39.814680579876693, -7.601873041367656],
              [-39.81367731184077, -7.603240621389536],
              [-39.813032649840125, -7.603920091806826],
              [-39.811991220090185, -7.604076042009557],
              [-39.810678962085902, -7.603246348854064],
              [-39.808599968704044, -7.603385582290824],
              [-39.808310048667401, -7.603494779398881],
              [-39.80797053345978, -7.603034146858029],
              [-39.807875167150897, -7.601822493992302],
              [-39.807726387508382, -7.600267067128978],
              [-39.807631020297769, -7.598937624965087],
              [-39.806360718125198, -7.599147917653889],
              [-39.805323130591646, -7.599013923586694],
              [-39.804060465849624, -7.598645817725972],
              [-39.802908418172734, -7.598457946884297],
              [-39.80209970382041, -7.59855901860831],
              [-39.8025956100415, -7.600068229034875],
              [-39.802763457609338, -7.60035766145141],
              [-39.802988535726406, -7.600700970594851],
              [-39.802446854413617, -7.602076651951806],
              [-39.800547117096471, -7.601817743909222],
              [-39.799326408096036, -7.602597845246082],
              [-39.79902123753898, -7.603457572872247],
              [-39.800795082118761, -7.604467523338297],
              [-39.803438666906558, -7.605032582179333],
              [-39.805624483000095, -7.605525625067647],
              [-39.80820704403186, -7.606317180510296],
              [-39.809637580243141, -7.607092042091413],
              [-39.809504051225979, -7.608241225139126],
              [-39.807997239691034, -7.60849726935712],
              [-39.806910035698778, -7.608137266592665],
              [-39.805647371002706, -7.607714795053783],
              [-39.804728038820812, -7.607354767429879],
              [-39.80306865218683, -7.606634272334535],
              [-39.801455018645186, -7.606609965132747],
              [-39.801046842500185, -7.606655739519605],
              [-39.801241395187446, -7.608962186950093],
              [-39.801409243702182, -7.609314576992207],
              [-39.800013072990424, -7.610040333505929],
              [-39.799654489864061, -7.610837128462833],
              [-39.798941142720949, -7.612267642350777],
              [-39.798868662513939, -7.613126928174628],
              [-39.799654489722755, -7.614581725170386],
              [-39.799879543930523, -7.614816325961781],
              [-39.799875725767535, -7.615042824610257],
              [-39.800035960948961, -7.615856311901563],
              [-39.800428861481059, -7.616669801387352],
              [-39.799967297812998, -7.616598275427481],
              [-39.798067561451646, -7.616393733057886],
              [-39.79684685268586, -7.616893458063181],
              [-39.796251759848325, -7.618151817074989],
              [-39.796289921514948, -7.619417811570612],
              [-39.798056128758851, -7.620888861290405],
              [-39.799322612668632, -7.621022390554374],
              [-39.80012750890355, -7.621092961807191],
              [-39.799765107506936, -7.622242610661431],
              [-39.799482823802244, -7.625463176257214],
              [-39.800863743855544, -7.625650581297012],
              [-39.8028397557406, -7.624588662269754],
              [-39.804003234958813, -7.62403459454033],
              [-39.80510187120337, -7.623996410686463],
              [-39.806360716879603, -7.624473247901697],
              [-39.808245180521709, -7.625663900392797],
              [-39.810389040831645, -7.625062636764268],
              [-39.812258253718646, -7.623711751366293],
              [-39.813719291361771, -7.622523941656332],
              [-39.815557979112953, -7.622782872653524],
              [-39.816130184709031, -7.623197707663824],
              [-39.817560697873645, -7.624027423923754],
              [-39.818247315849334, -7.624215761377618],
              [-39.819860950393412, -7.624240091582895],
              [-39.821653864812397, -7.624091778965369],
              [-39.822348119076153, -7.623873406845416],
              [-39.823687107289004, -7.62303893993015],
              [-39.825670755421619, -7.621624119006655],
              [-39.826197162920288, -7.621351869066711],
              [-39.827757386042265, -7.621140645628334],
              [-39.828222769072006, -7.62075107233523],
              [-39.828455482522493, -7.620632795233512],
              [-39.828699606416109, -7.619890854851557],
              [-39.829584619149955, -7.618694941706387],
              [-39.83074430335288, -7.618195216649058],
              [-39.831034225389963, -7.618086485538339],
              [-39.831675091222571, -7.617642080717998],
              [-39.834051642980718, -7.616932062928989],
              [-39.834745921154301, -7.616821887164896],
              [-39.834700147010054, -7.616189144890751],
              [-39.83449034270425, -7.614751018270884],
              [-39.834959543813646, -7.614234598735374],
              [-39.836470173713302, -7.613517433264457],
              [-39.838259269965384, -7.613604209243529],
              [-39.839579141404272, -7.613855037854623],
              [-39.842348617652611, -7.614012385233582],
              [-39.844484864781464, -7.613700554874175],
              [-39.844603096133739, -7.613474522317522],
              [-39.844503933565264, -7.612606202399662],
              [-39.84441236146661, -7.611114175198401],
              [-39.844137714193813, -7.610182897508743],
              [-39.844023277102941, -7.610065597110874],
              [-39.843969889579554, -7.60994829676407],
              [-39.844038527769271, -7.609142911612515],
              [-39.844103371689314, -7.608690869355804],
              [-39.844118644454163, -7.607650417558209],
              [-39.843618919919017, -7.606547962699493],
              [-39.843168787835296, -7.605680597483854],
              [-39.842955166076507, -7.604812743468775],
              [-39.843016190833737, -7.60458673486437],
              [-39.844339881345469, -7.604719774178021],
              [-39.846556244972497, -7.606714391162535],
              [-39.847696836204655, -7.607535027233448],
              [-39.848959501853138, -7.608020899397948],
              [-39.849947473372936, -7.60740391989329],
              [-39.850477745141553, -7.606778768054948],
              [-39.851522970066299, -7.606387753253884],
              [-39.851934963672321, -7.605763090313729],
              [-39.851103362260829, -7.603738438663181],
              [-39.849550775573668, -7.603361764607725],
              [-39.848990003273094, -7.602494375299752],
              [-39.84914641847832, -7.599735374844546],
              [-39.849978020930926, -7.598133218070604],
              [-39.851229253986638, -7.599424033434915],
              [-39.85212951848942, -7.600761065231984],
              [-39.853437957139867, -7.602051857645357],
              [-39.853952932440514, -7.602113859706416],
              [-39.855280464017433, -7.60213865666715],
              [-39.855303328970685, -7.600808749597372],
              [-39.855383445700106, -7.599261889866169],
              [-39.856028130756997, -7.598518972823732],
              [-39.857641765065921, -7.598832736303753],
              [-39.858229221578675, -7.601607918285909],
              [-39.858274995533861, -7.602467181213033],
              [-39.858488618148691, -7.603507167205485],
              [-39.858873929495054, -7.604609621969901],
              [-39.858831950469671, -7.607377656471566],
              [-39.859568184520782, -7.608253124879969],
              [-39.86188750669146, -7.607543130040344],
              [-39.862997597545309, -7.606691015035463],
              [-39.863874974092795, -7.605784557159227],
              [-39.86537414840285, -7.605872287889904],
              [-39.866530037348859, -7.605715895785544],
              [-39.867167062930015, -7.605606208970057],
              [-39.867727836223914, -7.606482166229449],
              [-39.868006301667762, -7.607178354322218],
              [-39.868696761718915, -7.607249390476764],
              [-39.869898378567044, -7.608015683924282],
              [-39.870062430973036, -7.608530658999284],
              [-39.870928351873211, -7.608492987946982],
              [-39.872137629343079, -7.608454361187382],
              [-39.87275940420502, -7.609330317498801],
              [-39.872442800957465, -7.61110367469049],
              [-39.871447170245844, -7.612010156468518],
              [-39.871077131467231, -7.613675269259299],
              [-39.870535450199768, -7.614987995001673],
              [-39.869944176618048, -7.6159025770697],
              [-39.869478770677162, -7.616183908007817],
              [-39.867949071742892, -7.618285323234747],
              [-39.867140357476067, -7.618277710603283],
              [-39.865759414374246, -7.618136079289767],
              [-39.864649369526425, -7.618979136348632],
              [-39.864458633857652, -7.620191720979866],
              [-39.865088046203915, -7.62042539011747],
              [-39.866182886384138, -7.620504528563361],
              [-39.866694043324138, -7.621028095878707],
              [-39.865931103155049, -7.621708079197647],
              [-39.864943085731554, -7.62220780439967],
              [-39.864008501679088, -7.623005552874718],
              [-39.863989386703771, -7.624325914009412],
              [-39.86385972294439, -7.625131300113097],
              [-39.862173585228412, -7.625912355057825],
              [-39.859579615686478, -7.625808884500447],
              [-39.857725677410507, -7.626291916076108],
              [-39.855391081282491, -7.628268418370076],
              [-39.854963837196514, -7.629589734156823],
              [-39.855810689072861, -7.630926789917755],
              [-39.855848849926964, -7.631966775767364],
              [-39.854162736166188, -7.632802196387868],
              [-39.850763870387702, -7.632628131574017],
              [-39.850222166020622, -7.634058179618311],
              [-39.850439606749539, -7.634926010641429],
              [-39.850592180548887, -7.636255452866189],
              [-39.849936063090638, -7.637568177512369],
              [-39.849802535068193, -7.638717361569124],
              [-39.849676666281447, -7.639522257678939],
              [-39.848276654395832, -7.64047497884126],
              [-39.846140431510243, -7.64049691191884],
              [-39.843954638219877, -7.640229900672608],
              [-39.841482673200865, -7.639673900904653],
              [-39.839460908505529, -7.639696299075898],
              [-39.837435280495711, -7.640124986828243],
              [-39.836100134533872, -7.640625177734542],
              [-39.835749189737875, -7.640960407447039],
              [-39.835684345762523, -7.641476337325102],
              [-39.835489792027715, -7.642797188308799],
              [-39.834951929777901, -7.644055570375944],
              [-39.833838020662178, -7.645251460331289],
              [-39.833349727672029, -7.646970940698822],
              [-39.833101785657924, -7.647885035056325],
              [-39.832979712133962, -7.648346156212504],
              [-39.832735566191779, -7.649142951270614],
              [-39.832666927090422, -7.649839605071447],
              [-39.83200315006048, -7.651893826302253],
              [-39.831114317150984, -7.65332480504908],
              [-39.829996612924319, -7.654683327034351],
              [-39.828875089571689, -7.656223038939293],
              [-39.827993894859858, -7.657355528466836],
              [-39.827814614676974, -7.661443925014273],
              [-39.827936665228698, -7.664500948947022],
              [-39.828585169240569, -7.667330986673118],
              [-39.82900861809118, -7.669871568931305],
              [-39.829218421633072, -7.671019798284536],
              [-39.829348108226966, -7.673959009928188],
              [-39.829317607934797, -7.675740958314283],
              [-39.829306152426206, -7.676437613165228],
              [-39.82929090160772, -7.677531964650091],
              [-39.829271809770276, -7.678680659809016],
              [-39.829489250494369, -7.679548513836001],
              [-39.830561180566804, -7.681120169402114],
              [-39.831739933537442, -7.683098557642832],
              [-39.832335025213119, -7.685584797375397],
              [-39.832197725974147, -7.687014356767774],
              [-39.831244027035794, -7.68901516607828],
              [-39.830286533024598, -7.691133276688937],
              [-39.829119235435655, -7.692148465673611],
              [-39.829096325377769, -7.69358661611216],
              [-39.83006909212466, -7.694010043418667],
              [-39.832788976353406, -7.693416860238472],
              [-39.834185147075956, -7.692690636730579],
              [-39.835737732754431, -7.693067333804349],
              [-39.83637098631408, -7.693012036667487],
              [-39.837477235845519, -7.692575711192902],
              [-39.83824781249961, -7.691262520642276],
              [-39.839067982410661, -7.690410872392535],
              [-39.839418928162658, -7.690129541359553],
              [-39.839533364397901, -7.690075176779039],
              [-39.840647250366622, -7.689059986748354],
              [-39.84122709254293, -7.688724291231471],
              [-39.841749704886936, -7.688668993000993],
              [-39.842661424830524, -7.689372328593657],
              [-39.843740991231648, -7.690491943856218],
              [-39.845003657026631, -7.690797115110144],
              [-39.848085941514157, -7.689235447985769],
              [-39.850100090972141, -7.6895568477778],
              [-39.850595998185533, -7.691057467286354],
              [-39.850576928292348, -7.692269120076149],
              [-39.849745326922964, -7.693754464554718],
              [-39.848799265272433, -7.695348053294033],
              [-39.847910456454329, -7.696661266746273],
              [-39.847837976045689, -7.697747027238843],
              [-39.84942869952625, -7.699218076850097],
              [-39.850218345995621, -7.700320042975881],
              [-39.851168203638522, -7.70223549740853],
              [-39.851442872855309, -7.703220651793045],
              [-39.851435236417302, -7.703799539350389],
              [-39.850904987742176, -7.704307367887545],
              [-39.848715353289933, -7.704266366278731],
              [-39.847166585821419, -7.703609269869343],
              [-39.845156230576883, -7.703007028744292],
              [-39.8446717786479, -7.704608742826017],
              [-39.845510994226558, -7.706343939235412],
              [-39.84715133501534, -7.708212664530822],
              [-39.848536049083762, -7.708354760859265],
              [-39.849276103241465, -7.709059075305245],
              [-39.849390562384741, -7.709112929082474],
              [-39.849562205161341, -7.70912152017674],
              [-39.84979110154012, -7.709121031373384],
              [-39.850649409052728, -7.709598824264102],
              [-39.852308794591501, -7.710427585750221],
              [-39.854208531812802, -7.710803794125894],
              [-39.855593245928453, -7.71089154877753],
              [-39.85898068019894, -7.711752720535633],
              [-39.859381265015465, -7.712158987464134],
              [-39.860868984870876, -7.71287089149993],
              [-39.862577986567054, -7.714278052598407],
              [-39.863249354672462, -7.715615573231642],
              [-39.863684236159941, -7.717296915625064],
              [-39.864573068039185, -7.719438844671485],
              [-39.866266796956019, -7.721886480588096],
              [-39.867071692248786, -7.721893628228339],
              [-39.867765971316871, -7.721910345717996],
              [-39.867998638715669, -7.721855467240834],
              [-39.868761601991224, -7.721058207616978],
              [-39.869753414584977, -7.720332449762926],
              [-39.869772507628959, -7.718948665987382],
              [-39.869425357130808, -7.715376688882281],
              [-39.868528911818579, -7.713805032446515],
              [-39.867807927556456, -7.711889090202633],
              [-39.867659148980501, -7.710279272627725],
              [-39.868380132430097, -7.708450618088082],
              [-39.869783963497532, -7.707263273321486],
              [-39.872091829125466, -7.707294752088472],
              [-39.875261868526728, -7.707468793739716],
              [-39.876410074235388, -7.707656664597287],
              [-39.878374654441615, -7.707516967089485],
              [-39.880621519203416, -7.707901276712232],
              [-39.883028594439139, -7.708746243738457],
              [-39.884932125886316, -7.708833043835759],
              [-39.88730106423214, -7.708692367676628],
              [-39.891962617572496, -7.709460546069259],
              [-39.894213300703392, -7.709383293434102],
              [-39.895422555074681, -7.709462455989136],
              [-39.896002397209507, -7.709180637151645],
              [-39.896765336479234, -7.708392434472167],
              [-39.897760991202617, -7.707485487693947],
              [-39.898935902049466, -7.706117442801918],
              [-39.89982089274821, -7.70498495326744],
              [-39.900297731296966, -7.703898726112931],
              [-39.90064488093234, -7.703789529054422],
              [-39.900763136141471, -7.703735162477],
              [-39.90099200952168, -7.703734673673677],
              [-39.901281930562249, -7.703625476566704],
              [-39.901400184771774, -7.70357062099212],
              [-39.903486838512194, -7.702969358324471],
              [-39.904234505259481, -7.702922629234576],
              [-39.905279729085862, -7.702658017688669],
              [-39.906450845760077, -7.701525015398887],
              [-39.906282997347738, -7.701055325042105],
              [-39.905603993799417, -7.70029669096628],
              [-39.90413150065816, -7.698608666731002],
              [-39.903109186792889, -7.697552476130065],
              [-39.902536981242335, -7.697083274428804],
              [-39.902201286210079, -7.696387575271151],
              [-39.902037233863027, -7.695809152563152],
              [-39.901983846340691, -7.695691853213734],
              [-39.901983845440796, -7.695574086912234],
              [-39.903494452439503, -7.691229576959143],
              [-39.903498270612126, -7.69099453435656],
              [-39.90340290326295, -7.689845815089968],
              [-39.903349491986489, -7.689439083457569],
              [-39.903650868629512, -7.688524989134701],
              [-39.904871577459765, -7.687970432455894],
              [-39.906199085142241, -7.687877439129253],
              [-39.906603443273234, -7.687885541422925],
              [-39.906836134626587, -7.687885075622304],
              [-39.907068825125741, -7.687712943840011],
              [-39.907187080381469, -7.687603723587808],
              [-39.907541868354549, -7.687033427270495],
              [-39.907606711433267, -7.686400197081503],
              [-39.907568550678597, -7.685251500863294],
              [-39.907171829884874, -7.684782277311646],
              [-39.90650425784483, -7.683327456378214],
              [-39.905714611614833, -7.681936034966112],
              [-39.905218705205918, -7.680661937106394],
              [-39.905295003740221, -7.679395452684826],
              [-39.907408339703309, -7.677129982909687],
              [-39.909964193653508, -7.675903570342777],
              [-39.91194402468799, -7.674669030337729],
              [-39.91258489253682, -7.674215568568799],
              [-39.913111323095706, -7.673879873004561],
              [-39.9137483718184, -7.673607111160259],
              [-39.914270984312232, -7.673380147947648],
              [-39.915091154176388, -7.672591456318682],
              [-39.915739658217035, -7.671848049276266],
              [-39.91686118077655, -7.670082329698003],
              [-39.918272624231783, -7.668378588996506],
              [-39.919375055853813, -7.667878863890826],
              [-39.921167970238471, -7.667793972906026],
              [-39.923014296200265, -7.667762517649292],
              [-39.924856780135528, -7.667794928010437],
              [-39.927862766162747, -7.667444935621185],
              [-39.929720523726999, -7.666608560148981],
              [-39.930761976444558, -7.666506999629484],
              [-39.931559212253781, -7.667156459449162],
              [-39.93252052441207, -7.668384292976728],
              [-39.933367377387313, -7.669603582458866],
              [-39.934397351650119, -7.670134786177014],
              [-39.936377182543936, -7.66907237914818],
              [-39.936785357894706, -7.668791049161046],
              [-39.937189715072876, -7.668745273773609],
              [-39.939829482728605, -7.669535386314293],
              [-39.940348298952124, -7.669660813008115],
              [-39.940630583664515, -7.670003656213073],
              [-39.941031122724382, -7.670129524804527],
              [-39.941889429201915, -7.6706612173756],
              [-39.942640892025629, -7.67049669898526],
              [-39.943972264878596, -7.670177209003168],
              [-39.944727568720943, -7.669958835935343],
              [-39.94490305362595, -7.669786215107061],
              [-39.945131925999732, -7.669794783249349],
              [-39.945707949918315, -7.669802908687177],
              [-39.946459434596584, -7.669810057278832],
              [-39.947561888076635, -7.669481998154251],
              [-39.948023475950336, -7.669318434513595],
              [-39.948488858751553, -7.669208771556526],
              [-39.948660524530069, -7.669217339650546],
              [-39.949175499735162, -7.669397108019075],
              [-39.949526469445097, -7.669170144660963],
              [-39.94976677427492, -7.668545482567723],
              [-39.949621836771698, -7.666809308740473],
              [-39.949064881790832, -7.665535210826122],
              [-39.948565157404971, -7.66426108896056],
              [-39.948061613846846, -7.663222057697897],
              [-39.947909016005759, -7.661947471128256],
              [-39.947935722371888, -7.660111202041326],
              [-39.948294305464216, -7.659368704747146],
              [-39.948137912550138, -7.658211441479199],
              [-39.947996747255438, -7.65619489232098],
              [-39.947741191914517, -7.654006700085189],
              [-39.947653438095884, -7.652161793954971],
              [-39.947439815486376, -7.651121830944289],
              [-39.947058369261768, -7.64984770918162],
              [-39.946718832106448, -7.649323677004642],
              [-39.946661624610272, -7.64920637665239],
              [-39.945849092238035, -7.645798406187411],
              [-39.94523111249471, -7.644750317886084],
              [-39.944613132796853, -7.643648350904724],
              [-39.943712846293856, -7.642311761078488],
              [-39.942484523141367, -7.639754951217926],
              [-39.941359181763971, -7.637785596959178],
              [-39.940409325007629, -7.635978874883626],
              [-39.939459468579223, -7.633783046118304],
              [-39.939329757785138, -7.631069843113388],
              [-39.940569536451299, -7.629367033261229],
              [-39.941973342668135, -7.628007114511299],
              [-39.943728119692835, -7.626302884099391],
              [-39.944849619951562, -7.624889530429532],
              [-39.945025103855421, -7.62471739859849],
              [-39.945955891700443, -7.624209082396451],
              [-39.946898136280026, -7.622732792949614],
              [-39.948145550235374, -7.620622320523165],
              [-39.948740643086374, -7.619363938491883],
              [-39.950030014013137, -7.618122250943386],
              [-39.950621287601955, -7.617207201870779],
              [-39.951075238996545, -7.617676402466016],
              [-39.952624006589495, -7.618215709562774],
              [-39.953547157047467, -7.618231937240223],
              [-39.95667139859188, -7.617474235356346],
              [-39.958666480299733, -7.615317498829645],
              [-39.960772203073667, -7.613340996325647],
              [-39.961592372892312, -7.612606671371379],
              [-39.961764015679016, -7.612606205517912],
              [-39.962114984388393, -7.612379218159214],
              [-39.96281305878113, -7.611988690061978],
              [-39.963396718081583, -7.6114808625651],
              [-39.963980356237407, -7.611145167046472],
              [-39.964621246034525, -7.610754639901224],
              [-39.965330775076957, -7.609495792967365],
              [-39.964895893611327, -7.607760572902963],
              [-39.96381632707925, -7.606767804892207],
              [-39.963877375791967, -7.606596137962335],
              [-39.965559647456153, -7.605931895313526],
              [-39.966715513415416, -7.605775502209348],
              [-39.967493704450995, -7.603946824715661],
              [-39.967634846806646, -7.602336565392472],
              [-39.968439720102189, -7.60235323596698],
              [-39.970228839380887, -7.602439546951603],
              [-39.97207134628254, -7.602525833981151],
              [-39.975199404905013, -7.601650389794877],
              [-39.977221192730738, -7.601510179318846],
              [-39.977686597624007, -7.601292272001603],
              [-39.979715998974392, -7.600464464612846],
              [-39.981451682974942, -7.600144042966741],
              [-39.983179753543112, -7.600402485878796],
              [-39.985010805950814, -7.6011759268209],
              [-39.98701352375015, -7.602419501117283],
              [-39.987971017904563, -7.603701724310123],
              [-39.989863117827944, -7.604538587928539],
              [-39.991724694473376, -7.603584913145779],
              [-39.992438041627658, -7.602162990179956],
              [-39.993998241824812, -7.60189737706193],
              [-39.995207519068302, -7.602147740588724],
              [-39.996210786124323, -7.604352137349905],
              [-39.999060357059825, -7.606642868146626],
              [-40.001188966892848, -7.606791648047143],
              [-40.002516475491838, -7.606815956015093],
              [-40.004713747327607, -7.606385846407101],
              [-40.006811810588012, -7.605096475812671],
              [-40.008791663736957, -7.603744145492403],
              [-40.010233608574701, -7.60371366888085],
              [-40.01253388485538, -7.604269156512753],
              [-40.013396011659296, -7.604394094493895],
              [-40.014551854480551, -7.604409344371664],
              [-40.015932773560124, -7.604604852369],
              [-40.019514784490617, -7.607888840895549],
              [-40.019911504391807, -7.608240741141397],
              [-40.020949114949829, -7.608373781222217],
              [-40.022627593362621, -7.608053359529113],
              [-40.024080993799323, -7.607217938700879],
              [-40.024485350989593, -7.607162617367834],
              [-40.024546375657678, -7.607044829117393],
              [-40.025774721981016, -7.605857041189709],
              [-40.027647730224032, -7.604107037135693],
              [-40.029314753238062, -7.600793014182885],
              [-40.030676603539334, -7.598510385858758],
              [-40.031634097702593, -7.596220143241364],
              [-40.033255345904529, -7.591875190364578],
              [-40.034342526872919, -7.588725173955337],
              [-40.034880412085855, -7.587520715548422],
              [-40.035361044695009, -7.586325757037444],
              [-40.035773016310536, -7.5857006290894],
              [-40.035952342431663, -7.58523901897713],
              [-40.037192120257906, -7.58335506618717],
              [-40.038763775807453, -7.582582580082266],
              [-40.040667307437722, -7.582496269184939],
              [-40.042914195401238, -7.582698924860856],
              [-40.045492891286578, -7.583778003616921],
              [-40.047045501060907, -7.584090813059887],
              [-40.048125043650316, -7.585029238397726],
              [-40.049200791978599, -7.586310506699926],
              [-40.05101659453549, -7.588241700768271],
              [-40.053595335376059, -7.589375144205746],
              [-40.054056900156162, -7.58932888186619],
              [-40.05411794786977, -7.589156749937708],
              [-40.055285246436306, -7.588195436613421],
              [-40.057383331851945, -7.586733934032947],
              [-40.059832362934969, -7.585100274766204],
              [-40.06122853378816, -7.58425579694017],
              [-40.062968059967758, -7.583708877637084],
              [-40.064749519077139, -7.584084129902227],
              [-40.066244875258853, -7.584396939298521],
              [-40.068777842247663, -7.584553820487067],
              [-40.071387085428732, -7.583841916889067],
              [-40.073469921279397, -7.583185310522845],
              [-40.075545119462312, -7.583388431054498],
              [-40.077311327762288, -7.58485803782199],
              [-40.080069349778789, -7.585475552469784],
              [-40.082609953105489, -7.5851708464011],
              [-40.084021375478272, -7.583584405983006],
              [-40.083926007412416, -7.582083320798716],
              [-40.082629023127105, -7.580223653735073],
              [-40.081366357301178, -7.579909913538811],
              [-40.080622485989629, -7.579387278014427],
              [-40.079607785469705, -7.577816111478492],
              [-40.07922249721269, -7.576596355386299],
              [-40.078146748834655, -7.575368963762786],
              [-40.076956564499419, -7.573861219706052],
              [-40.075651941887664, -7.572453593960946],
              [-40.07462578667382, -7.571805088948525],
              [-40.07331352877484, -7.570804171786814],
              [-40.072176731683044, -7.569748959093984],
              [-40.070647033038263, -7.5678897798406],
              [-40.070944591469257, -7.567256060846575],
              [-40.073077018294534, -7.567404350746906],
              [-40.073370735456983, -7.567123486657315],
              [-40.072481903721787, -7.5647469340073],
              [-40.070662306939752, -7.563105172224777],
              [-40.069300454821416, -7.561589303078493],
              [-40.068392553962191, -7.560714324510503],
              [-40.067538065491668, -7.560119231327416],
              [-40.067545701914874, -7.559549400713367],
              [-40.069182177548747, -7.558134650468086],
              [-40.070975115208448, -7.557759327188465],
              [-40.075079737808416, -7.557079855639215],
              [-40.077681342703308, -7.556775173614443],
              [-40.079813771674424, -7.5567517975304],
              [-40.081362563339383, -7.557236271948594],
              [-40.083254640242018, -7.558126990244465],
              [-40.085543460240316, -7.559143134128059],
              [-40.087557610812539, -7.559400622281057],
              [-40.08865242906716, -7.559424930049949],
              [-40.089461142453644, -7.559314312380178],
              [-40.090216469307521, -7.559095451307599],
              [-40.091769056037933, -7.55947168237201],
              [-40.092753232421927, -7.559080200513557],
              [-40.09426004415748, -7.558642455365168],
              [-40.095190831963968, -7.558196607784385],
              [-40.094923798104581, -7.55669601045743],
              [-40.094603375810848, -7.554969382426369],
              [-40.093531446688324, -7.553398658845187],
              [-40.092284031667617, -7.551990543154357],
              [-40.09154779659459, -7.551115098731676],
              [-40.091109096944741, -7.549614968261121],
              [-40.091055685419711, -7.549497667912189],
              [-40.091116734178442, -7.549271170306061],
              [-40.089865501130852, -7.547926944234962],
              [-40.089823544296436, -7.546886981367003],
              [-40.09007150950017, -7.545746853334495],
              [-40.090376681262683, -7.544651547083927],
              [-40.09063225985075, -7.542986922168468],
              [-40.090544528867532, -7.541314170015841],
              [-40.0898121120537, -7.540094881637883],
              [-40.088221365543049, -7.538579501302681],
              [-40.086558161735667, -7.538031626169176],
              [-40.085287858620312, -7.538125550555748],
              [-40.08406335455561, -7.538969562530993],
              [-40.083071518895039, -7.539758720025101],
              [-40.081900402310019, -7.540774864023546],
              [-40.080965819094629, -7.541744744493083],
              [-40.079916776034324, -7.542298836333639],
              [-40.078436670458053, -7.541126765055013],
              [-40.077250280096635, -7.539610430063513],
              [-40.076006707236964, -7.537976304723966],
              [-40.074515146356106, -7.537265821699136],
              [-40.073313529233786, -7.536789449526667],
              [-40.071440498162033, -7.534740955124514],
              [-40.069288977485179, -7.532177462548545],
              [-40.068545152989749, -7.531871825744444],
              [-40.067274850922644, -7.531910918457329],
              [-40.066008367069756, -7.53166106688816],
              [-40.064684676575887, -7.531465069951995],
              [-40.062907012579501, -7.530917660724542],
              [-40.061644347745549, -7.530613444481608],
              [-40.061014913481401, -7.530262032044735],
              [-40.062357717903403, -7.529182464567218],
              [-40.064383300957012, -7.528761877745132],
              [-40.06624487675689, -7.527635628975021],
              [-40.06845358110008, -7.526517876442279],
              [-40.07061271422311, -7.524884706927123],
              [-40.071657939263481, -7.524384515763159],
              [-40.072825235871946, -7.523368860759587],
              [-40.075396342563927, -7.521164905973063],
              [-40.076861197601133, -7.519515018980168],
              [-40.078215435516114, -7.517748367588377],
              [-40.079043195716082, -7.516498111695113],
              [-40.079748929490499, -7.51559209565758],
              [-40.080752197746015, -7.513988496006949],
              [-40.081518932187961, -7.512964714718885],
              [-40.082388696368746, -7.512582778706627],
              [-40.083906939600332, -7.511448355697842],
              [-40.085989775450685, -7.510792262317749],
              [-40.087790303345606, -7.510073186077307],
              [-40.088660065481797, -7.509745593742194],
              [-40.089068241934115, -7.509346474449148],
              [-40.090178309759438, -7.508556828055259],
              [-40.091051892064407, -7.507994168118294],
              [-40.091456248303004, -7.507884482104201],
              [-40.091799557874268, -7.507892608340365],
              [-40.092779938899731, -7.507962689736551],
              [-40.093355962732211, -7.508087627472247],
              [-40.094038785427323, -7.508674128557286],
              [-40.095175582575237, -7.509675022559484],
              [-40.097124889181124, -7.510447975587999],
              [-40.100828972722383, -7.50964310143354],
              [-40.102232780899506, -7.508346069292936],
              [-40.102873646899582, -7.507720453536123],
              [-40.1029919021111, -7.507665620959424],
              [-40.104384253751995, -7.507110575406403],
              [-40.105898702908334, -7.506103046639279],
              [-40.107008769976446, -7.505023944961715],
              [-40.10794337689822, -7.504406429399877],
              [-40.109034375950202, -7.504711135495541],
              [-40.109827840723945, -7.505468837657117],
              [-40.111483431110855, -7.506595134346315],
              [-40.113684498043838, -7.506047236420512],
              [-40.114573330199477, -7.504335393312799],
              [-40.114592399117818, -7.50306937483977],
              [-40.114260521179517, -7.502202008711044],
              [-40.113398395415587, -7.502014138111834],
              [-40.112658343219735, -7.501310335674977],
              [-40.112456175125857, -7.499529342075233],
              [-40.112795689648046, -7.496019345184108],
              [-40.113406032200523, -7.493783910954606],
              [-40.114127016732382, -7.491837467101909],
              [-40.114546623577354, -7.490869007196222],
              [-40.114615286618779, -7.49023577701095],
              [-40.114687767061149, -7.489087057888202],
              [-40.114600035113504, -7.48735947606872],
              [-40.114668698297102, -7.486554577902627],
              [-40.115527006051977, -7.483169984700287],
              [-40.116041981181262, -7.479840221889909],
              [-40.117468699634586, -7.476807062072328],
              [-40.119032717160863, -7.472515987835818],
              [-40.119173859499007, -7.47090570451177],
              [-40.119551511543882, -7.468842891069636],
              [-40.120554779647101, -7.467420504341352],
              [-40.121512272790127, -7.468756604198578],
              [-40.122469766693378, -7.470382137338155],
              [-40.124247407609431, -7.471046892858214],
              [-40.12775311932355, -7.471734466668705],
              [-40.128733499451698, -7.471686293763447],
              [-40.130579825551294, -7.471546106118674],
              [-40.131502977030195, -7.471561869786725],
              [-40.132075182705279, -7.471913280173124],
              [-40.132517677575429, -7.473133036298976],
              [-40.132506222989342, -7.473937932512569],
              [-40.130762923638791, -7.47471088448649],
              [-40.129248498427607, -7.475782349877498],
              [-40.127787461601088, -7.477142269600413],
              [-40.126566752837576, -7.477588606943637],
              [-40.124407641563984, -7.479393908446534],
              [-40.122820713009929, -7.481496278658507],
              [-40.120974409805996, -7.485381088077904],
              [-40.120474685377467, -7.487733797702831],
              [-40.120257244478111, -7.490664441132168],
              [-40.120158058789038, -7.493540742982741],
              [-40.120806562955991, -7.496261559375633],
              [-40.121744963936052, -7.499153110996907],
              [-40.122687207316702, -7.501529197680931],
              [-40.124064309060572, -7.502185803926365],
              [-40.125437591883816, -7.502779476542202],
              [-40.125883928864972, -7.503827565694931],
              [-40.126932971063042, -7.506718628416102],
              [-40.128008720215753, -7.508235452311461],
              [-40.129656651380579, -7.509696467012913],
              [-40.130446296843587, -7.510860923762064],
              [-40.130762923977535, -7.512822619391645],
              [-40.130667556637924, -7.515237820990822],
              [-40.130404340624182, -7.517481334469427],
              [-40.130190740784975, -7.520294677611166],
              [-40.130747672796225, -7.521568309522333],
              [-40.133158567282848, -7.52218628986086],
              [-40.135580892172648, -7.522224916643869],
              [-40.138403781043493, -7.522498144369019],
              [-40.139952549720981, -7.522982618783801],
              [-40.141318196063004, -7.524218089595256],
              [-40.141413563334687, -7.525483620173392],
              [-40.141024502630366, -7.528415218474474],
              [-40.141161802835327, -7.530603433619845],
              [-40.143160702569972, -7.532009150951664],
              [-40.144133446261847, -7.53254892356214],
              [-40.144724720873327, -7.535206363306846],
              [-40.145380861170331, -7.537692113124058],
              [-40.146384130111706, -7.540067710884151],
              [-40.147745981387601, -7.541411914054569],
              [-40.150488752524147, -7.543240592506116],
              [-40.152708911305524, -7.545115999247001],
              [-40.153136156508673, -7.547248894969504],
              [-40.152369396957432, -7.548390441568863],
              [-40.150042415135268, -7.549852899966377],
              [-40.14732634993441, -7.550266803249888],
              [-40.144156333516428, -7.549985939270249],
              [-40.141161802810075, -7.549767543067179],
              [-40.138571605247165, -7.549557274153393],
              [-40.136957993786531, -7.549362720960852],
              [-40.134833202232393, -7.548807209480914],
              [-40.132181981010582, -7.548705624872992],
              [-40.129000532906403, -7.549346980421024],
              [-40.126398902852458, -7.549823818427039],
              [-40.125025620032893, -7.549230634797571],
              [-40.123461578847085, -7.549487656970959],
              [-40.123110632033317, -7.549832409635418],
              [-40.122996195794435, -7.549887241215157],
              [-40.122115001249895, -7.550793699108972],
              [-40.120806561179954, -7.553537425759111],
              [-40.121413085252868, -7.555507246592554],
              [-40.122706274430925, -7.55753904663235],
              [-40.125864836161192, -7.562306005026193],
              [-40.12700924553971, -7.562963566086347],
              [-40.128676291575616, -7.563212463003852],
              [-40.130289903170478, -7.563244430161955],
              [-40.13300978749114, -7.562649313958196],
              [-40.135962362354299, -7.561836804236232],
              [-40.136366720549674, -7.561781506901387],
              [-40.136885513885453, -7.561789120289515],
              [-40.13847623734587, -7.563367924267028],
              [-40.140066984806417, -7.564946262248221],
              [-40.141157983858989, -7.565250944355316],
              [-40.142138364835503, -7.565383984386505],
              [-40.142714388768113, -7.565391621822537],
              [-40.144678946180647, -7.565078789316424],
              [-40.145197762562333, -7.565032084026424],
              [-40.14564407649079, -7.566134538871147],
              [-40.146014092000321, -7.568376630904362],
              [-40.146746529950128, -7.569432822262962],
              [-40.148810273654554, -7.570322609720154],
              [-40.151053320422164, -7.570823288631519],
              [-40.152823347052781, -7.57188569782223],
              [-40.154478914489921, -7.572948546915212],
              [-40.155562300019994, -7.573940360950571],
              [-40.156935582788456, -7.574596967212946],
              [-40.15750397038822, -7.575065679914231],
              [-40.158244045534907, -7.575823429048538],
              [-40.157118704883125, -7.577707379956025],
              [-40.156348127210109, -7.579021082535662],
              [-40.155924702037836, -7.580396764039121],
              [-40.154639148199138, -7.581467251627003],
              [-40.152277848021399, -7.581256492897053],
              [-40.149405365842043, -7.58057699851373],
              [-40.146876217137617, -7.580022907676135],
              [-40.143832092268838, -7.579225647847506],
              [-40.141245714022148, -7.578608622335686],
              [-40.139227746275907, -7.578577631830365],
              [-40.137846848031842, -7.578554255813137],
              [-40.136229396455491, -7.578531368595101],
              [-40.1347874276425, -7.578507992526852],
              [-40.133055561683129, -7.578711602872041],
              [-40.132452832487836, -7.580431083186436],
              [-40.133059379607516, -7.582337968403733],
              [-40.135882245518928, -7.582556830471313],
              [-40.138594515598676, -7.582314593176331],
              [-40.140841356632734, -7.582462417182454],
              [-40.142271869751305, -7.583408456780066],
              [-40.14241685318509, -7.585243816037247],
              [-40.141062616200323, -7.587065324120727],
              [-40.138155815516726, -7.588465802395377],
              [-40.135763989770027, -7.590380278058491],
              [-40.134283884041011, -7.592952362592905],
              [-40.133433212729365, -7.595695111645069],
              [-40.133330207834007, -7.598806501143145],
              [-40.133517125183474, -7.601573604923277],
              [-40.135779262682192, -7.6045972419289],
              [-40.138033740996363, -7.604346901303304],
              [-40.140227194740966, -7.604097027623681],
              [-40.142081133986153, -7.603721284403665],
              [-40.143851161655626, -7.604729792922895],
              [-40.144747630719287, -7.606644781345936],
              [-40.144892567140879, -7.608489174555991],
              [-40.146426084003792, -7.610058921558296],
              [-40.146735074825344, -7.612427394807379],
              [-40.14688767168019, -7.613693390447049],
              [-40.148665312486798, -7.614475399316307],
              [-40.150164463806433, -7.614607018798577],
              [-40.151961219647944, -7.614005243868863],
              [-40.15473831051969, -7.613473575348792],
              [-40.159888158314054, -7.612175611355714],
              [-40.161463631258925, -7.610932504032033],
              [-40.163157359462637, -7.609580197453361],
              [-40.163985166691361, -7.608320861596815],
              [-40.164114876647929, -7.607289466805535],
              [-40.164015667224731, -7.606249945870048],
              [-40.163458736324557, -7.604858547631255],
              [-40.163157360027434, -7.601802478453742],
              [-40.163180247079225, -7.600364350979464],
              [-40.161818395857139, -7.598966248106239],
              [-40.15988436307083, -7.597090374579838],
              [-40.158518693784252, -7.595800538064521],
              [-40.157515425659945, -7.593660005887016],
              [-40.157481082357471, -7.591932423079754],
              [-40.157912145820355, -7.590150008987559],
              [-40.159132854629995, -7.589658385919903],
              [-40.160857107313205, -7.589852427217459],
              [-40.162741569983901, -7.591095558442695],
              [-40.164446730590598, -7.59272777421647],
              [-40.166914854484652, -7.593571320296815],
              [-40.170546411837869, -7.593634254971398],
              [-40.171957879235443, -7.592038757593194],
              [-40.17226686931626, -7.590545285686644],
              [-40.172015109222755, -7.588013761450785],
              [-40.171965516014012, -7.587489239512132],
              [-40.17066850781886, -7.585512272120567],
              [-40.170390042169018, -7.585051638612431],
              [-40.168875593235725, -7.58220730517198],
              [-40.167971511632622, -7.580988504626216],
              [-40.168806932481495, -7.578924271540254],
              [-40.170088689059867, -7.578197582913476],
              [-40.171671776382951, -7.576438521647675],
              [-40.173033627849485, -7.573984250310271],
              [-40.173186224903013, -7.571451281426978],
              [-40.173453236050619, -7.56903610194298],
              [-40.173495214927421, -7.566449257287498],
              [-40.174494689124586, -7.564972478863146],
              [-40.175364405225984, -7.564698786210017],
              [-40.176661412426817, -7.566675754594395],
              [-40.177451058839125, -7.567894532042219],
              [-40.181075024727875, -7.568581197009911],
              [-40.183020514318244, -7.569417083691367],
              [-40.183978007206591, -7.571042150877359],
              [-40.184416707847184, -7.572551338315469],
              [-40.184557849065982, -7.57467661986003],
              [-40.184885907827642, -7.575779050612753],
              [-40.185851016193809, -7.576770888553442],
              [-40.186243941695878, -7.577638253751541],
              [-40.185473363921069, -7.579069232641287],
              [-40.185393270176107, -7.58062517237033],
              [-40.186579636640552, -7.582023719091968],
              [-40.187708796173013, -7.583819986414012],
              [-40.188300070716089, -7.586540802817945],
              [-40.188551853756124, -7.58913577468267],
              [-40.188975256686, -7.59161246739187],
              [-40.190627028810042, -7.593082073087976],
              [-40.190680440334859, -7.593199373439195],
              [-40.191023749718859, -7.593433508342937],
              [-40.191710414531876, -7.593847878469162],
              [-40.19252294813726, -7.593456861463184],
              [-40.193972530393943, -7.592900883967235],
              [-40.194960524710403, -7.592572801736327],
              [-40.197459172189561, -7.591290602414383],
              [-40.199488572599797, -7.590453272068059],
              [-40.201235713188765, -7.589390398818165],
              [-40.201956696750564, -7.587443953931165],
              [-40.202330530710654, -7.585552808426295],
              [-40.202635701483707, -7.58445750115848],
              [-40.203097289370639, -7.584293471515411],
              [-40.204371409719187, -7.583910091850622],
              [-40.20756431325875, -7.582689382745312],
              [-40.209479277514987, -7.581806766569223],
              [-40.210417702722914, -7.580836397093136],
              [-40.211241667926842, -7.579640483854526],
              [-40.212290710249334, -7.578796494723834],
              [-40.214838951850446, -7.578147035699225],
              [-40.217337576288443, -7.57691917905246],
              [-40.220068892233805, -7.575518700620795],
              [-40.222281414785982, -7.574174031424104],
              [-40.22420785755746, -7.572586146425282],
              [-40.224917386514257, -7.57145365671485],
              [-40.225096666739297, -7.570874303290107],
              [-40.225283606191439, -7.569960674848166],
              [-40.225295038696778, -7.569264044976364],
              [-40.225535366628954, -7.568521593567155],
              [-40.225832900960206, -7.568014696813182],
              [-40.226183870778968, -7.56766996714517],
              [-40.226241077475223, -7.567552177889483],
              [-40.228175133573366, -7.565511786571868],
              [-40.229353886705923, -7.563808511628815],
              [-40.229647602828805, -7.563581548216473],
              [-40.230986566302057, -7.562510571668912],
              [-40.232562040255438, -7.561266975339612],
              [-40.233218180739414, -7.559953761652847],
              [-40.23532769889129, -7.557569572513748],
              [-40.236742961556864, -7.555630276163508],
              [-40.238963121733384, -7.553480198732118],
              [-40.240252491798714, -7.552120278850579],
              [-40.241423608605295, -7.550869045227436],
              [-40.243479715186432, -7.548421432417611],
              [-40.245520571093834, -7.546905098088073],
              [-40.246397993678933, -7.54599815118354],
              [-40.246516225899953, -7.545934727657271],
              [-40.246809965258024, -7.545427365902522],
              [-40.246932037740308, -7.545020145412378],
              [-40.247355464860824, -7.543707419528137],
              [-40.247714071012474, -7.542901568592627],
              [-40.248011582487742, -7.542222540856214],
              [-40.248251909229189, -7.541706122110551],
              [-40.248373982756874, -7.541244556941768],
              [-40.24854946785549, -7.540846393442733],
              [-40.248614311642108, -7.54055647121126],
              [-40.24878597742677, -7.540565063303728],
              [-40.249476437462754, -7.54069000014106],
              [-40.249819746895987, -7.540869745364683],
              [-40.250601779696105, -7.542893466050976],
              [-40.250632280276101, -7.544331127575859],
              [-40.250494955991066, -7.545823620636606],
              [-40.250071529862417, -7.547145426467837],
              [-40.249106399255155, -7.550005963749362],
              [-40.248618129051302, -7.55196053378585],
              [-40.249346727710268, -7.553405832850459],
              [-40.250376702008673, -7.553945138523271],
              [-40.250487319086012, -7.554116782601098],
              [-40.250582687207654, -7.55555446718217],
              [-40.250388156414253, -7.556938716836914],
              [-40.249278065373574, -7.557954860901964],
              [-40.246710778689298, -7.560041958417461],
              [-40.246169097299862, -7.561472495508288],
              [-40.245390884095727, -7.563473305030493],
              [-40.244898795969114, -7.565364917437813],
              [-40.244524938858, -7.567437252876266],
              [-40.24437618207174, -7.569616876869525],
              [-40.244624123998683, -7.572383491726713],
              [-40.245242104767144, -7.573431115053502],
              [-40.246951106402562, -7.575017532111143],
              [-40.248721110049893, -7.576079940317179],
              [-40.249522210964152, -7.576611143850092],
              [-40.249613760063149, -7.578103171111135],
              [-40.24919033493034, -7.579424509949018],
              [-40.248698245609631, -7.581542132024846],
              [-40.249365795639996, -7.583050364674369],
              [-40.251036636761867, -7.583190551248305],
              [-40.253066060986534, -7.582587822512017],
              [-40.254805563396353, -7.5818138925436],
              [-40.255793581872048, -7.58130466738082],
              [-40.256026273227199, -7.581313235525444],
              [-40.25653745333392, -7.581664181881241],
              [-40.256525997591019, -7.582641234099998],
              [-40.256377218891814, -7.584712614739514],
              [-40.256297125196937, -7.586205107856498],
              [-40.256682413357659, -7.587542163282604],
              [-40.257636089183386, -7.58927596284327],
              [-40.259562484654616, -7.59161246866255],
              [-40.259844792279104, -7.592073078179588],
              [-40.259833336772076, -7.592769266058663],
              [-40.259367955763388, -7.593105450682199],
              [-40.258730906008367, -7.593387735479795],
              [-40.25683498786605, -7.592777834488335],
              [-40.255461681031434, -7.592185138834753],
              [-40.254134172294762, -7.592279553161729],
              [-40.252730365080232, -7.593576560321811],
              [-40.251887331026239, -7.59593071470962],
              [-40.252272619246575, -7.597195313565071],
              [-40.253409392354399, -7.59825052728928],
              [-40.254199039713917, -7.59953226138433],
              [-40.254229540093554, -7.601195955585716],
              [-40.25411892175299, -7.60488618770283],
              [-40.254557622251745, -7.606557983201215],
              [-40.25593092803129, -7.607214124483423],
              [-40.257723819684742, -7.606892723890415],
              [-40.259284018876514, -7.606689114406007],
              [-40.260893811429185, -7.606828835936455],
              [-40.260893811329574, -7.60694662524114],
              [-40.261008272478485, -7.607000502019419],
              [-40.26111127611879, -7.607751033675459],
              [-40.261099821373293, -7.608727595900881],
              [-40.260607733235886, -7.610619208319974],
              [-40.259909635810338, -7.611019281370273],
              [-40.258803385980904, -7.61174599314853],
              [-40.258620264687735, -7.612433589935312],
              [-40.25918865209178, -7.613137392259706],
              [-40.260573390525956, -7.612925189679125],
              [-40.26196576499818, -7.612604767743653],
              [-40.26299573949187, -7.612909473812996],
              [-40.264479641057662, -7.614080567151387],
              [-40.26562025638291, -7.614845905595713],
              [-40.266020794220665, -7.615251706538326],
              [-40.2662954425994, -7.616064705971725],
              [-40.266348853932378, -7.616408504987751],
              [-40.265475295602734, -7.616971651927735],
              [-40.264266019178564, -7.616893934366825],
              [-40.262488378243532, -7.616238746735089],
              [-40.262026790498389, -7.616231134390899],
              [-40.262179388306649, -7.617551006729578],
              [-40.262148863713357, -7.619685345107332],
              [-40.262198457019736, -7.620092076748789],
              [-40.261096002407953, -7.620529334238391],
              [-40.259894385321253, -7.619990516404886],
              [-40.259219176052554, -7.618834649657397],
              [-40.259051328578025, -7.618428382913723],
              [-40.25905514575026, -7.618193316304358],
              [-40.258719450793784, -7.617389374766462],
              [-40.258666039269066, -7.61727205041391],
              [-40.258032808885247, -7.617083713991709],
              [-40.257689476354578, -7.617021246070984],
              [-40.257575040205573, -7.61696736829251],
              [-40.25688455722441, -7.616779496819398],
              [-40.256537452666606, -7.616780451521181],
              [-40.256072046657536, -7.617116613144571],
              [-40.255709645188503, -7.618320628732194],
              [-40.254809381584771, -7.620610870454931],
              [-40.254218107878955, -7.621634675913416],
              [-40.253573421794371, -7.622369001035419],
              [-40.253043172996463, -7.622994594895687],
              [-40.252516742317127, -7.623448544772228],
              [-40.251639365816274, -7.624246759320715],
              [-40.251002317114875, -7.624465643491331],
              [-40.250205033131074, -7.623988806633155],
              [-40.249831200471021, -7.621972722217912],
              [-40.250025754419816, -7.620416806566321],
              [-40.249983820641575, -7.619322941988075],
              [-40.249247540657407, -7.618339232174331],
              [-40.247706409359672, -7.617330700828994],
              [-40.246153822499508, -7.61708180498982],
              [-40.244711877769092, -7.616941128605062],
              [-40.2435598299448, -7.616863409092945],
              [-40.241946219323168, -7.616841010865359],
              [-40.240721691182742, -7.617739831526417],
              [-40.240115143751304, -7.61974923315127],
              [-40.24222086629289, -7.621678517536459],
              [-40.242938032284179, -7.623991670485797],
              [-40.242911326054454, -7.625664397588906],
              [-40.242873188083813, -7.62830560996602],
              [-40.242678634233854, -7.629743760312931],
              [-40.242091179792844, -7.630423719803557],
              [-40.241213779292345, -7.631222422348432],
              [-40.239638306382879, -7.632402596052166],
              [-40.238429051962882, -7.632324412492588],
              [-40.237036700240743, -7.632943346663484],
              [-40.235930427366966, -7.633724402118902],
              [-40.234763129861555, -7.63457793709483],
              [-40.233767497109874, -7.63547582695389],
              [-40.233393641037196, -7.637484738780151],
              [-40.235442134167691, -7.639188014457776],
              [-40.237513538165928, -7.639679636306876],
              [-40.238490099985455, -7.640038685013252],
              [-40.238600718007412, -7.640273284722276],
              [-40.239287359968607, -7.641691412933646],
              [-40.239310247801271, -7.642875870963179],
              [-40.239302633270675, -7.643563466899119],
              [-40.239005074827226, -7.64419720990771],
              [-40.239447570683019, -7.645407884137853],
              [-40.239726059077491, -7.64616654089671],
              [-40.239539142762915, -7.646908480360654],
              [-40.237803435517748, -7.647456378659202],
              [-40.235548980270366, -7.647598474913393],
              [-40.234156580609906, -7.648144928510701],
              [-40.232119543902606, -7.649380888493599],
              [-40.230196919236782, -7.650797106509351],
              [-40.22880072530377, -7.65169641702072],
              [-40.227507559207346, -7.653110725580049],
              [-40.226153345047976, -7.65510438766875],
              [-40.225920653640003, -7.655159219148156],
              [-40.22465416962509, -7.65508150053448],
              [-40.223502122804867, -7.655003292020647],
              [-40.221140821759541, -7.654621333275676],
              [-40.219981160500943, -7.655122012339119],
              [-40.219920111784866, -7.655293679274314],
              [-40.218977891302892, -7.656607846720751],
              [-40.21850105473667, -7.657694561903568],
              [-40.218546828496457, -7.662297467779376],
              [-40.218985528975296, -7.663978321232709],
              [-40.220572434308025, -7.665728326251995],
              [-40.221777893412678, -7.666204209466142],
              [-40.223731042237837, -7.666696297218907],
              [-40.22487922374615, -7.667172180384954],
              [-40.225787124553015, -7.668110140621372],
              [-40.225695576332228, -7.670298821632061],
              [-40.22601220228308, -7.672432671313156],
              [-40.226572952495104, -7.673416381986396],
              [-40.22776316096671, -7.674760585067884],
              [-40.229418728254558, -7.675995126191825],
              [-40.231150595039921, -7.676017057532929],
              [-40.233050331489267, -7.676219713950784],
              [-40.234885201955919, -7.676937811274025],
              [-40.236136412191527, -7.678055539745281],
              [-40.237452488271209, -7.678820856350251],
              [-40.239420887725139, -7.678453216190153],
              [-40.241831758130644, -7.679178972958956],
              [-40.243098242201398, -7.679193268950647],
              [-40.246836645577005, -7.6760351727467],
              [-40.248888957024327, -7.673813592588265],
              [-40.249648101252824, -7.673142201191428],
              [-40.249999070875631, -7.673032537136047],
              [-40.252081882781532, -7.672366384832077],
              [-40.253649720358162, -7.671647309402768],
              [-40.255103119601493, -7.671091332914578],
              [-40.258635537717311, -7.669941659691262],
              [-40.261130344012052, -7.668939345718056],
              [-40.263453507414631, -7.668055285901676],
              [-40.264903112732163, -7.667445408727337],
              [-40.265715622347521, -7.667053926724781],
              [-40.265776648018424, -7.666936161470797],
              [-40.265894902332946, -7.666764029585917],
              [-40.266070387291016, -7.666537531070034],
              [-40.266184823532015, -7.666483164487683],
              [-40.266474744585068, -7.666373478380309],
              [-40.266825667355221, -7.666092148336594],
              [-40.267928144830371, -7.665817500891188],
              [-40.269022962058713, -7.665895709359165],
              [-40.271784803390197, -7.666167981097897],
              [-40.273806590283272, -7.666026350648549],
              [-40.275160828306674, -7.664204820534687],
              [-40.275530842222793, -7.662421940357942],
              [-40.275443111258092, -7.660758246090652],
              [-40.275111234478814, -7.659719214934288],
              [-40.275069255714328, -7.658615805403481],
              [-40.276518860887158, -7.658177594215849],
              [-40.278536805708114, -7.658153752067346],
              [-40.279521028916399, -7.657996870826657],
              [-40.280051253818463, -7.657253953655549],
              [-40.280459430232838, -7.656917791983247],
              [-40.280688326668972, -7.65686296050053],
              [-40.280810400002977, -7.65663646293918],
              [-40.280882879525379, -7.655424345145972],
              [-40.281771688529282, -7.653947567604946],
              [-40.282710113799084, -7.652922854440952],
              [-40.283534079066236, -7.651672575509696],
              [-40.284663239071989, -7.649381378973493],
              [-40.284861588039064, -7.647780153581491],
              [-40.284663239303853, -7.645582881367992],
              [-40.283701927203168, -7.644247269429017],
              [-40.282385850166655, -7.643419462196069],
              [-40.280547185367979, -7.643108096475995],
              [-40.279280701491025, -7.642858710874799],
              [-40.278883956681469, -7.642389487306024],
              [-40.279543916500018, -7.640669542006889],
              [-40.281752619857038, -7.639613816100952],
              [-40.282965692601834, -7.639284803067205],
              [-40.282996216000996, -7.637385553292858],
              [-40.282370600046285, -7.636627851232138],
              [-40.281691595567239, -7.635752383820962],
              [-40.281710663454525, -7.634549811929299],
              [-40.281722096057656, -7.633744914686006],
              [-40.281512291761771, -7.632298662038411],
              [-40.281405493004669, -7.631719774362452],
              [-40.281531361702804, -7.631032177524252],
              [-40.281657252446891, -7.630290192006949],
              [-40.281668684957346, -7.629594027122757],
              [-40.281508473821013, -7.628726196106173],
              [-40.281859442636659, -7.628390500382681],
              [-40.285109552775545, -7.630741301263278],
              [-40.286299715015943, -7.632374471637282],
              [-40.287951510347526, -7.633608521756044],
              [-40.288813636077137, -7.633850294059088],
              [-40.289969478933472, -7.633865055949157],
              [-40.291007089621637, -7.633879840739204],
              [-40.291827236768313, -7.632801250792644],
              [-40.29199508461312, -7.62923784194775],
              [-40.291956946955942, -7.627971823381564],
              [-40.292082838753821, -7.627166438237536],
              [-40.292555858010772, -7.626477886697769],
              [-40.292674113271524, -7.626368688441636],
              [-40.293311160883775, -7.626258583629064],
              [-40.293776566841466, -7.625985798630841],
              [-40.295130781738393, -7.627844488813695],
              [-40.297232686116331, -7.630008884826815],
              [-40.297637043219538, -7.630070841803219],
              [-40.298327503303426, -7.630140969973232],
              [-40.298541125772438, -7.631352134012406],
              [-40.298815773003987, -7.632337288436029],
              [-40.300128031078572, -7.633156015718299],
              [-40.302142205540441, -7.633593271843416],
              [-40.302947101865918, -7.633609011431212],
              [-40.303580354306362, -7.63373345923303],
              [-40.304266996132711, -7.634138795424119],
              [-40.3057547160589, -7.634857357446334],
              [-40.307078429537363, -7.635106743095834],
              [-40.307887143890341, -7.635059059060908],
              [-40.308009217516414, -7.634488763525872],
              [-40.308157997275728, -7.632363505179826],
              [-40.308798841201046, -7.631854723720898],
              [-40.309088761162549, -7.631854234968898],
              [-40.310412474834095, -7.631877121953186],
              [-40.311335626329139, -7.63189239564367],
              [-40.311396673999909, -7.631774605389641],
              [-40.312041360143901, -7.63097685763718],
              [-40.312579223376822, -7.629781433138676],
              [-40.314028804655386, -7.629225432641481],
              [-40.31483372498186, -7.629241172229133],
              [-40.316100184772161, -7.629599266188857],
              [-40.317492560445913, -7.62905234558968],
              [-40.318209726010153, -7.627159779351487],
              [-40.318842956551556, -7.627166927844514],
              [-40.319708878349964, -7.627299966792144],
              [-40.3218375111122, -7.627618968710993],
              [-40.322875098803927, -7.627634219498099],
              [-40.32287509870433, -7.627751518806741],
              [-40.323046765497736, -7.627751053954555],
              [-40.324675649802032, -7.626859846584045],
              [-40.325778081576246, -7.626241377160933],
              [-40.326876717031581, -7.626030153334361],
              [-40.328143200915882, -7.626279537934967],
              [-40.328776454411631, -7.626341053107613],
              [-40.329302885142276, -7.625832736548307],
              [-40.330531207569678, -7.624581036963283],
              [-40.330706692382542, -7.624526204434694],
              [-40.331168256223158, -7.624425575417822],
              [-40.332213504154431, -7.624096561240081],
              [-40.332339371953275, -7.623291176092954],
              [-40.332648361948301, -7.621915983458771],
              [-40.33333882308883, -7.621922665002677],
              [-40.33368597359545, -7.62198513392778],
              [-40.334548100475921, -7.622054750245855],
              [-40.335185148183498, -7.621835890073868],
              [-40.335215672626774, -7.619882274613247],
              [-40.336390584721435, -7.618286777008818],
              [-40.337195502957826, -7.618411224955086],
              [-40.337771503849154, -7.618481796025566],
              [-40.337943169497258, -7.618652996161567],
              [-40.338000400995426, -7.618770296518585],
              [-40.338114836137635, -7.618833231244337],
              [-40.338114836290949, -7.61865250830946],
              [-40.3384619648902, -7.618606243875231],
              [-40.340247265177481, -7.618799377244749],
              [-40.341845625841884, -7.620096874948618],
              [-40.342589474462265, -7.620275200526104],
              [-40.346202008753046, -7.62182871842421],
              [-40.347060316461949, -7.622133424354122],
              [-40.348403098788125, -7.621234113787505],
              [-40.34893332473883, -7.620436365935872],
              [-40.349421617919802, -7.61853616154461],
              [-40.349436868641646, -7.617559110314562],
              [-40.349402549394135, -7.615777649782775],
              [-40.348925711044686, -7.613066356357308],
              [-40.349238496457517, -7.611166152819399],
              [-40.350817788504997, -7.609813847121397],
              [-40.352088090757356, -7.609602134440867],
              [-40.353469010742771, -7.609968330448052],
              [-40.354216701383017, -7.610092802345445],
              [-40.354922411338102, -7.609014189296994],
              [-40.354769813636103, -7.607567935690971],
              [-40.354155651984634, -7.606349135355561],
              [-40.354216700747472, -7.606122637741857],
              [-40.354807974459824, -7.605098879273847],
              [-40.355445023268437, -7.604762228795077],
              [-40.355464069160924, -7.603550576951882],
              [-40.355483185053082, -7.602338459111724],
              [-40.354693538633484, -7.601120124626749],
              [-40.354083172290991, -7.599503647639911],
              [-40.353983986967492, -7.598346361377739],
              [-40.353930598633596, -7.598002563359175],
              [-40.354338775003441, -7.597721233361335],
              [-40.35480415607136, -7.597321649108689],
              [-40.35503684852862, -7.597212450948363],
              [-40.355445023898461, -7.596931121950473],
              [-40.35625755742101, -7.596656916251185],
              [-40.356440654716195, -7.595969320458353],
              [-40.356280467576013, -7.595101488438837],
              [-40.35599434188422, -7.594749588272183],
              [-40.355712058211068, -7.594343810426376],
              [-40.355895156307632, -7.593890791250924],
              [-40.356703870810563, -7.593671441224916],
              [-40.357920760584626, -7.593287596512186],
              [-40.358210682593878, -7.593232764079544],
              [-40.358504444821335, -7.59288801135904],
              [-40.359259724990679, -7.592324863314481],
              [-40.35937798020575, -7.592270496734492],
              [-40.359320773706919, -7.592153196377772],
              [-40.359610671709135, -7.592106956894387],
              [-40.359915866490972, -7.591011650607745],
              [-40.360095145613947, -7.590559119426143],
              [-40.360102782003743, -7.590034597524474],
              [-40.36011421564983, -7.589175334599285],
              [-40.360125670249751, -7.588370438353725],
              [-40.36018669596649, -7.588198306419756],
              [-40.360598689600096, -7.587573177451596],
              [-40.361117483002211, -7.587517368217132],
              [-40.361670619596261, -7.589315056085556],
              [-40.362219937369559, -7.590932021016696],
              [-40.362445015398663, -7.591392630491223],
              [-40.362559475541232, -7.591455588216496],
              [-40.362792142899679, -7.591463691364589],
              [-40.363131656270319, -7.591752168950084],
              [-40.364211200747441, -7.592861305321781],
              [-40.364779611258214, -7.593447341346422],
              [-40.366271149270808, -7.594048185061425],
              [-40.367362148348583, -7.594352379187596],
              [-40.368685861891592, -7.594538340206659],
              [-40.370291835192845, -7.595030429659582],
              [-40.372191573630346, -7.595286496751059],
              [-40.373694566251935, -7.595073828270437],
              [-40.37543025042369, -7.594643254269909],
              [-40.377452038341524, -7.594501623807738],
              [-40.379065649034501, -7.594469190358253],
              [-40.379817157750139, -7.59447587295202],
              [-40.380843313986645, -7.595132478947995],
              [-40.381301083427807, -7.595538279942823],
              [-40.381400268904926, -7.596514354275786],
              [-40.380633510224072, -7.597774156207358],
              [-40.380507617480092, -7.598516141729874],
              [-40.38112941647131, -7.59927333179323],
              [-40.382682003202561, -7.599702486574008],
              [-40.383776820446521, -7.599780694036387],
              [-40.385035667156458, -7.60030998897991],
              [-40.386977360421, -7.601606041982993],
              [-40.38823238975926, -7.602605958151944],
              [-40.389830749633767, -7.603667877245792],
              [-40.390227470450256, -7.604136612819816],
              [-40.390628009339331, -7.60448804808799],
              [-40.392398036862815, -7.605721610316785],
              [-40.393489012843666, -7.606143150755107],
              [-40.39561382746011, -7.606687695341876],
              [-40.398661793573723, -7.60725657156505],
              [-40.399462871697267, -7.607552685497806],
              [-40.400668306776019, -7.608091037345693],
              [-40.401530433663162, -7.608160654663749],
              [-40.403662861565181, -7.608307501599784],
              [-40.404757678865252, -7.608322286437267],
              [-40.405162036065704, -7.608275557053834],
              [-40.406142418077152, -7.6083990741545],
              [-40.406768035187753, -7.608984620235649],
              [-40.40761488715421, -7.610266355405278],
              [-40.408103180008311, -7.612290051904861],
              [-40.409068287390213, -7.613281888881993],
              [-40.410330952167456, -7.61370294047062],
              [-40.411990338661965, -7.614710003945602],
              [-40.412791440640369, -7.615177785869484],
              [-40.414454644499472, -7.615724240059322],
              [-40.415087897048011, -7.615730922555345],
              [-40.416423044100718, -7.615229287636386],
              [-40.417998494043282, -7.614048647916555],
              [-40.418635588710856, -7.613883663426088],
              [-40.420497118592444, -7.612765470580282],
              [-40.420916750722711, -7.611498031394026],
              [-40.420897681197467, -7.608740008610079],
              [-40.420905341581097, -7.608224543650395],
              [-40.420626829034262, -7.607637553870061],
              [-40.419596855614451, -7.607225093427799],
              [-40.418437193529883, -7.60749068288513],
              [-40.417220302693046, -7.60793795222471],
              [-40.415766903086698, -7.608892117380731],
              [-40.414313502626165, -7.609674614547125],
              [-40.412696072854018, -7.609824814296684],
              [-40.411906426531623, -7.608488713494698],
              [-40.411292264976943, -7.607152612841317],
              [-40.41176528427885, -7.606418287563629],
              [-40.412509155904743, -7.606597101139058],
              [-40.412867738917463, -7.605971950122023],
              [-40.412924945661821, -7.605799818183606],
              [-40.412993606670732, -7.60522093064946],
              [-40.412829577358906, -7.604588166236067],
              [-40.413298802549072, -7.604016914995136],
              [-40.41400451236327, -7.603110457927214],
              [-40.414473712699113, -7.602367540696835],
              [-40.414656834850902, -7.601851609888781],
              [-40.415465550405905, -7.601577428185367],
              [-40.415759265482642, -7.601413864396163],
              [-40.416682440142431, -7.601247925152114],
              [-40.417205052758618, -7.600911763572085],
              [-40.417327126239549, -7.600513111022011],
              [-40.417391969274973, -7.599934269484422],
              [-40.417411015160432, -7.598731651580554],
              [-40.417128754440121, -7.598379750414185],
              [-40.41586226964165, -7.598013065504778],
              [-40.414015967528769, -7.598100795430945],
              [-40.412577817836095, -7.597851875683507],
              [-40.411311334647166, -7.597946778056595],
              [-40.409632856158609, -7.598268666745607],
              [-40.408763116032723, -7.598542825397729],
              [-40.407721710065623, -7.59887183858206],
              [-40.407084638452943, -7.598972933449415],
              [-40.406565845101547, -7.598965786053911],
              [-40.406283559473387, -7.598505641525438],
              [-40.407511882810127, -7.597371243241294],
              [-40.408095543188828, -7.596808561046838],
              [-40.408969101440952, -7.59635365546218],
              [-40.410071580071367, -7.595915932969265],
              [-40.41099852599492, -7.595406661749678],
              [-40.411467726985478, -7.595070500124693],
              [-40.41147536336841, -7.594555036166411],
              [-40.410384364484564, -7.594015728422504],
              [-40.410735310304943, -7.593680032695119],
              [-40.411376178190032, -7.593226082908502],
              [-40.412306965979596, -7.592843191947706],
              [-40.412306966079321, -7.592725403641318],
              [-40.411914063425847, -7.592030635401493],
              [-40.411284629195137, -7.591616754308305],
              [-40.411059575019763, -7.591327788820388],
              [-40.411006162593345, -7.59109318715692],
              [-40.411933109463426, -7.590646874566287],
              [-40.412165823830506, -7.590645919768039],
              [-40.412280260165097, -7.590482844824767],
              [-40.412638842375699, -7.589622627192723],
              [-40.412829578009145, -7.588473443121008],
              [-40.412776190628421, -7.588184476777797],
              [-40.411967451219752, -7.588286526495559],
              [-40.411853015068715, -7.588232625716601],
              [-40.411624142828039, -7.588052391585308],
              [-40.410594168415564, -7.587630874200213],
              [-40.410079193373889, -7.587225562153616],
              [-40.410082988446888, -7.587107772850555],
              [-40.411425771025399, -7.585919007982703],
              [-40.412360377086372, -7.585183262102961],
              [-40.412547294646458, -7.584151867334877],
              [-40.412219234973236, -7.582941145189664],
              [-40.411776717156634, -7.581667537318778],
              [-40.411891176498557, -7.581495405429012],
              [-40.413676453649472, -7.581869238729633],
              [-40.414809409514014, -7.583267319449003],
              [-40.415473163341851, -7.585055950473838],
              [-40.415854633242127, -7.586736802896292],
              [-40.416289515775183, -7.588408110420453],
              [-40.417163097065966, -7.587907920101821],
              [-40.418395237667355, -7.58642972284443],
              [-40.418986512390553, -7.585396884424596],
              [-40.419619765931671, -7.585413089862469],
              [-40.420134742019911, -7.585764035229566],
              [-40.420386501111196, -7.588295094537484],
              [-40.420588692077615, -7.590202446476984],
              [-40.422011567998716, -7.59149135208723],
              [-40.423800664422856, -7.591512330471736],
              [-40.425475299741983, -7.591425554394156],
              [-40.426230626817478, -7.590980172652956],
              [-40.42750474815405, -7.590650204669504],
              [-40.427966311908001, -7.590657841013548],
              [-40.428420261424733, -7.591008787330115],
              [-40.428919986739565, -7.592399719563026],
              [-40.42843935404683, -7.593658566747219],
              [-40.428420262108418, -7.594924585278496],
              [-40.429377755965653, -7.59660353020312],
              [-40.430003372124574, -7.597134733604124],
              [-40.43097229762953, -7.597954414596879],
              [-40.432055684131861, -7.599009163303067],
              [-40.432967403237576, -7.599593777631639],
              [-40.43435593681837, -7.599209444065465],
              [-40.434661131543514, -7.598186617342737],
              [-40.435599533926172, -7.597044115858716],
              [-40.435897091275557, -7.596527720149027],
              [-40.436362473340573, -7.596137168840214],
              [-40.437583182390107, -7.595409991149819],
              [-40.437384810590068, -7.593276141541611],
              [-40.436366291822516, -7.591995362221357],
              [-40.435225700673783, -7.590995446145438],
              [-40.434085084425476, -7.590113296376662],
              [-40.433238209499585, -7.588777660525387],
              [-40.433139046121418, -7.587683796881332],
              [-40.433200094983953, -7.587339998956715],
              [-40.433669295929626, -7.587058180011142],
              [-40.434645857864687, -7.587308054362938],
              [-40.435897091191805, -7.58836187121379],
              [-40.437110163666745, -7.58837616715199],
              [-40.437224600009785, -7.588203545264537],
              [-40.4383003486832, -7.585577608628773],
              [-40.438475832597412, -7.585405476789925],
              [-40.438430059507695, -7.584709287849643],
              [-40.437983721518535, -7.583670745587466],
              [-40.437812055822029, -7.583553911130654],
              [-40.4377586674875, -7.583210112109665],
              [-40.436976635403582, -7.581521622391603],
              [-40.436816424353374, -7.580545525003457],
              [-40.436995727385792, -7.580201726178544],
              [-40.437976108493537, -7.580216510916824],
              [-40.438658931306321, -7.580684315737574],
              [-40.439509603299292, -7.581911195230996],
              [-40.440005509596858, -7.583356493140278],
              [-40.440772268770054, -7.586247555767778],
              [-40.440798973684352, -7.58849013759006],
              [-40.440768450144702, -7.590561518372358],
              [-40.440860022139148, -7.592170846977568],
              [-40.441756491273004, -7.594030513788769],
              [-40.442836034804408, -7.595084796494825],
              [-40.444205499447669, -7.595966480461614],
              [-40.44559405678477, -7.595871579191637],
              [-40.446753717172321, -7.595252643812215],
              [-40.447276329744184, -7.594970825911491],
              [-40.447451814459932, -7.595033293691905],
              [-40.448241459886745, -7.59625162818909],
              [-40.448794596627316, -7.597877628088376],
              [-40.448886146620431, -7.599486955696619],
              [-40.447695960936009, -7.601886884571414],
              [-40.4476196622971, -7.603271157362673],
              [-40.447886695204353, -7.604707864135703],
              [-40.448859439829015, -7.605355903144973],
              [-40.45006489815971, -7.605604799699184],
              [-40.451449614424988, -7.605627198737343],
              [-40.451930270220473, -7.604251050238923],
              [-40.451163512053029, -7.601359987599468],
              [-40.450717198065391, -7.600321398332965],
              [-40.451194036541501, -7.599352472436193],
              [-40.451773878666479, -7.599124577266507],
              [-40.453749890751666, -7.598005428518962],
              [-40.454238160975436, -7.596059939377283],
              [-40.453994037232135, -7.593058211832838],
              [-40.453852895029755, -7.590924875266686],
              [-40.453654523420163, -7.588564992986007],
              [-40.45368122884048, -7.586666233182203],
              [-40.453685047058784, -7.586376776888841],
              [-40.453917738473081, -7.586321480410407],
              [-40.454894301310603, -7.586689120069975],
              [-40.455542805366143, -7.589517248974574],
              [-40.455538986101786, -7.589861046948351],
              [-40.455653423153919, -7.590032224037619],
              [-40.455767883297625, -7.590095157763795],
              [-40.456790197186002, -7.591204295099724],
              [-40.457591298216144, -7.59161722034581],
              [-40.457751508502348, -7.592313385384875],
              [-40.458247415553743, -7.594047674599503],
              [-40.458476287803059, -7.594218851785475],
              [-40.459281207997478, -7.594406257362091],
              [-40.459967849975648, -7.594648005519232],
              [-40.461173308162309, -7.595069057059435],
              [-40.462668665316727, -7.595488688852759],
              [-40.463759664451786, -7.595738516303896],
              [-40.46497273616837, -7.595471994895567],
              [-40.466540573742364, -7.594797763184902],
              [-40.467002160590305, -7.594696644169267],
              [-40.467177645360167, -7.594695712322872],
              [-40.467749851240676, -7.594820626070977],
              [-40.46878362058608, -7.595296509144277],
              [-40.469756387505022, -7.595600727175933],
              [-40.470679537871959, -7.595787178858527],
              [-40.471530210064365, -7.596778967743271],
              [-40.471877360722424, -7.596669328681194],
              [-40.471892611689015, -7.595403333140187],
              [-40.471225037749413, -7.593777777138054],
              [-40.470435392320212, -7.592559930635229],
              [-40.470099697171818, -7.591973428801973],
              [-40.470973278714141, -7.591183317186691],
              [-40.47161796376863, -7.59050333473044],
              [-40.471591258810186, -7.588314630583053],
              [-40.471308950988558, -7.588080517722175],
              [-40.470439211001938, -7.58818305738551],
              [-40.469180363287379, -7.58764470549106],
              [-40.468268644422487, -7.586770657864514],
              [-40.467715507579371, -7.585262425271681],
              [-40.467551478364932, -7.584512337546627],
              [-40.467738395827666, -7.583598242082345],
              [-40.467929130561778, -7.582331757694824],
              [-40.469146021351328, -7.58194791297861],
              [-40.469897529265978, -7.581728562902414],
              [-40.471114420910517, -7.581516384176049],
              [-40.47285392432007, -7.580795864880886],
              [-40.473029385281144, -7.580569390361148],
              [-40.473208688360963, -7.580170737857717],
              [-40.473445198093145, -7.579708683775491],
              [-40.473510040974872, -7.579311009169845],
              [-40.473510041074526, -7.579193219862881],
              [-40.473346011759851, -7.578560943444845],
              [-40.472663189197036, -7.577794650378452],
              [-40.471579826388876, -7.577092757596063],
              [-40.470389617872591, -7.575749486498117],
              [-40.469245207499938, -7.575039002715559],
              [-40.467589617101403, -7.573868840208189],
              [-40.466792357340715, -7.573103059043887],
              [-40.466174376552623, -7.572064981633195],
              [-40.46572422239629, -7.571252914033852],
              [-40.464926962635928, -7.570486643873098],
              [-40.464411986353618, -7.570361707175223],
              [-40.463607090249234, -7.57006605723831],
              [-40.46265341545601, -7.568269814009027],
              [-40.461856131741222, -7.567449666166245],
              [-40.46122669730741, -7.567270852689597],
              [-40.460292091150592, -7.568114841935519],
              [-40.459410918465899, -7.569140043159681],
              [-40.458167321307172, -7.571368304997178],
              [-40.457411996084041, -7.571985819726853],
              [-40.455565691962079, -7.572073549657186],
              [-40.454249615985681, -7.571137523060202],
              [-40.453688865704706, -7.570208155059641],
              [-40.454104655614181, -7.56922968216958],
              [-40.455378776045933, -7.568791471820772],
              [-40.456538436188971, -7.568461991735576],
              [-40.456889383010555, -7.568126296006866],
              [-40.456916089327486, -7.566344813516923],
              [-40.456916089480053, -7.566164112580715],
              [-40.456748240992901, -7.565766902778072],
              [-40.456412544789842, -7.5652433355783],
              [-40.456240879047215, -7.565180378804651],
              [-40.456065417277287, -7.565181333651569],
              [-40.455489394331835, -7.565165128262818],
              [-40.454913370378854, -7.565157980820733],
              [-40.454337347425827, -7.565150832378734],
              [-40.453421832250598, -7.564674459414004],
              [-40.452746622916727, -7.563573449329159],
              [-40.450694311697788, -7.561934551254988],
              [-40.449729203404445, -7.560825413975467],
              [-40.449683429556963, -7.559839794741039],
              [-40.449519353043065, -7.559442607942198],
              [-40.449012038386456, -7.558512750992036],
              [-40.449134110873402, -7.558105552492564],
              [-40.449431645312693, -7.557480890421035],
              [-40.450194586778288, -7.556510520776156],
              [-40.450202223205068, -7.555940690138426],
              [-40.449290503292396, -7.555121008200552],
              [-40.448252916584302, -7.555106223416769],
              [-40.448203323373896, -7.554582190461478],
              [-40.446963544737685, -7.552605688062425],
              [-40.446910134257038, -7.552434022028713],
              [-40.447032206979898, -7.551746425181136],
              [-40.446978795598461, -7.551456993841141],
              [-40.446868177617986, -7.551168027450408],
              [-40.44665073677497, -7.550418427683343],
              [-40.445677993244324, -7.549653111375593],
              [-40.445685605579641, -7.549191525100926],
              [-40.446616394470475, -7.548691333827885],
              [-40.447715030040122, -7.548361831690033],
              [-40.448981513899433, -7.548665116965591],
              [-40.450583694054394, -7.549383215079526],
              [-40.45132756553302, -7.549742752585739],
              [-40.451442001684789, -7.549796652364271],
              [-40.451789105243307, -7.549804265610536],
              [-40.452540636965438, -7.549810948201515],
              [-40.453341716187865, -7.549998353769685],
              [-40.45396351298993, -7.550990632447222],
              [-40.454299209003359, -7.551740232314202],
              [-40.454394577122905, -7.553177893927502],
              [-40.454665429248287, -7.554334202345286],
              [-40.454837071991079, -7.554397183118444],
              [-40.456092123298532, -7.555450976966378],
              [-40.456427819403324, -7.556091867474272],
              [-40.456759696397341, -7.556895810018889],
              [-40.456870314179476, -7.557419842026103],
              [-40.457328083718203, -7.557716445664029],
              [-40.458247416210483, -7.557786062026119],
              [-40.459399440117771, -7.557799892912078],
              [-40.460021261918584, -7.55879266058896],
              [-40.460356957030882, -7.559424470151043],
              [-40.46093680000336, -7.559377275915908],
              [-40.462111710153337, -7.557727877613742],
              [-40.462649596442049, -7.556469053477],
              [-40.463061591132892, -7.555780455877271],
              [-40.464366212552115, -7.557476116989316],
              [-40.465205428348575, -7.559037761500292],
              [-40.46593404886697, -7.560662828545576],
              [-40.466956386905721, -7.56159981088878],
              [-40.468955286746564, -7.562949741622322],
              [-40.471080078277886, -7.563620643459283],
              [-40.471705695250499, -7.564378346525467],
              [-40.472857742159157, -7.564392176412587],
              [-40.474002152777274, -7.564813716893759],
              [-40.47595527862017, -7.565358238331257],
              [-40.47739722338963, -7.565498425718729],
              [-40.47849967818118, -7.564879492288139],
              [-40.478907853556834, -7.56459769628969],
              [-40.478514927898878, -7.563902928048063],
              [-40.476512209845971, -7.56283386165616],
              [-40.47336889948415, -7.560945113130987],
              [-40.471659920853391, -7.559305261347633],
              [-40.47133186212973, -7.558148928878776],
              [-40.470656675839621, -7.556993552113078],
              [-40.470259908162404, -7.556353173549678],
              [-40.470279000096554, -7.555086689021528],
              [-40.470465916609484, -7.554109636930534],
              [-40.470755838523452, -7.554172105806165],
              [-40.471385249859594, -7.554468220591342],
              [-40.471667534628956, -7.554765801076449],
              [-40.472831036089929, -7.554029543387676],
              [-40.473590158195549, -7.553520273023004],
              [-40.474288232675505, -7.553074913230852],
              [-40.474345462373428, -7.552957123972321],
              [-40.474292050846891, -7.552839824618022],
              [-40.474520947333772, -7.552730625453016],
              [-40.475100765452076, -7.55251176522815],
              [-40.475913298939354, -7.552291926204319],
              [-40.476432115340813, -7.55224473091768],
              [-40.477175963874885, -7.55254084479891],
              [-40.476985228999872, -7.553978996172985],
              [-40.476966160119048, -7.555182057075234],
              [-40.477244649480816, -7.555994591526326],
              [-40.479022289455635, -7.556657880115136],
              [-40.481208106760377, -7.557093692386101],
              [-40.482524183932817, -7.557803710312848],
              [-40.483729666270008, -7.558052583860946],
              [-40.484816823054686, -7.558754523643346],
              [-40.485724723943392, -7.559637628209301],
              [-40.48612526283874, -7.55998857448068],
              [-40.48691490841891, -7.561034752987967],
              [-40.487193374787715, -7.561838695491301],
              [-40.486933978047851, -7.563684556411673],
              [-40.487269672916064, -7.564605798273307],
              [-40.488642956787395, -7.565197561946367],
              [-40.489741593117785, -7.565157981103251],
              [-40.491008076318288, -7.565063078726819],
              [-40.4919884574322, -7.565077863464376],
              [-40.493434221468299, -7.564873787881052],
              [-40.493445677166932, -7.563951590316276],
              [-40.492198261001121, -7.562662684850674],
              [-40.49152686988235, -7.561272217467505],
              [-40.491214085219092, -7.55873259114814],
              [-40.491751947411515, -7.557591043317394],
              [-40.492671280754813, -7.55784140661666],
              [-40.493747052185711, -7.559058274360443],
              [-40.494880008062623, -7.560456355086654],
              [-40.496016805004672, -7.561745237459109],
              [-40.497096348645222, -7.562682241854202],
              [-40.499324121644186, -7.564320162091963],
              [-40.499835301611519, -7.564851855394175],
              [-40.500003150190715, -7.565140798835665],
              [-40.500117562289546, -7.56525812124174],
              [-40.500052743407949, -7.565655796847908],
              [-40.500346482540586, -7.565428810430245],
              [-40.50057917304752, -7.56526529258787],
              [-40.501216221766683, -7.565046385411463],
              [-40.50139170672827, -7.564819421894213],
              [-40.501860907921781, -7.564248170649222],
              [-40.501864726139502, -7.563958738354981],
              [-40.501925750857183, -7.563786582419064],
              [-40.501929569120406, -7.563443274441781],
              [-40.501998230319373, -7.562637888237248],
              [-40.502124123017609, -7.561950291388037],
              [-40.502482706329438, -7.560972774440017],
              [-40.50272303312677, -7.560401988999905],
              [-40.502852721141679, -7.559307636546921],
              [-40.502974793568946, -7.558972429621536],
              [-40.502982431049595, -7.558339665350531],
              [-40.50305872953782, -7.557127546545745],
              [-40.503070161136996, -7.556322650290745],
              [-40.503077798518454, -7.555807185329824],
              [-40.503081616834898, -7.555400452726056],
              [-40.503058729887343, -7.553157870901221],
              [-40.503291420547079, -7.552813118125144],
              [-40.503295216711074, -7.552587109458112],
              [-40.503131210302314, -7.552063518401075],
              [-40.502570436116322, -7.551016385091487],
              [-40.502288152483047, -7.550556241561977],
              [-40.502005868857388, -7.550087040085835],
              [-40.501666354526378, -7.549744219818056],
              [-40.501494710926941, -7.54951006105184],
              [-40.501212404248356, -7.549103818205329],
              [-40.500701246371392, -7.548463369545027],
              [-40.500590606245417, -7.548346582139393],
              [-40.500476169146516, -7.548229280733637],
              [-40.500018400605825, -7.547932212097539],
              [-40.499732297762932, -7.54775244391531],
              [-40.499507243781807, -7.547228876807561],
              [-40.499453831247465, -7.54712063339975],
              [-40.498767189462697, -7.5466437946297],
              [-40.497790627557229, -7.546348635547285],
              [-40.49744729502391, -7.546277574677433],
              [-40.496756834869835, -7.546270893137407],
              [-40.496352476710932, -7.546263279842949],
              [-40.496005350143633, -7.546264234546027],
              [-40.496009144360933, -7.545974778252789],
              [-40.496135036966052, -7.545395937765202],
              [-40.496196084827794, -7.545052116839948],
              [-40.496089262010919, -7.544536651784144],
              [-40.496039668845832, -7.543958230146982],
              [-40.496051124588092, -7.542981665904386],
              [-40.49600534944193, -7.542348901590363],
              [-40.496077829901154, -7.541200183412475],
              [-40.496550849309898, -7.540339523874993],
              [-40.496840771324038, -7.5402842034437],
              [-40.497226035540585, -7.540382434188557],
              [-40.500018400189752, -7.53774122307572],
              [-40.501460369047869, -7.53778175804578],
              [-40.50319223582968, -7.537875216947381],
              [-40.505797683011814, -7.53853513024765],
              [-40.507067985073625, -7.538576155071183],
              [-40.509192776880184, -7.537745996738082],
              [-40.510669063900117, -7.536122371530765],
              [-40.511565510515148, -7.533867404552943],
              [-40.511542622174396, -7.532085921018524],
              [-40.511565510280434, -7.529391320897314],
              [-40.511813452303407, -7.526107821431836],
              [-40.511973686100291, -7.52501346900764],
              [-40.512069053686155, -7.52352097687301],
              [-40.513156233708564, -7.522757547276698],
              [-40.514720275471326, -7.523086560648713],
              [-40.516410186295936, -7.524672535727762],
              [-40.517260880440602, -7.528114825179743],
              [-40.518451041746218, -7.530913872703731],
              [-40.518580751916978, -7.532007736375166],
              [-40.518302263137542, -7.532876522029285],
              [-40.517970385541531, -7.533971828305712],
              [-40.518386198761171, -7.535001314596205],
              [-40.519770937023381, -7.535041850518331],
              [-40.520636882011068, -7.53497556363425],
              [-40.521396004123254, -7.535650773297632],
              [-40.521697356946497, -7.536915814106924],
              [-40.521258656519301, -7.538698717249006],
              [-40.520575833761214, -7.539343402883278],
              [-40.519034702528209, -7.540569351377654],
              [-40.516692470320486, -7.542494351086848],
              [-40.515834162944252, -7.542958778634222],
              [-40.515208545641194, -7.543774665308915],
              [-40.514937716280976, -7.545267648297648],
              [-40.515132246607394, -7.546822121315455],
              [-40.516356774068484, -7.547957474664061],
              [-40.518729530936469, -7.548554965141392],
              [-40.520182930886385, -7.549580632328408],
              [-40.520476647447602, -7.550031719921679],
              [-40.521685925129496, -7.54984671202557],
              [-40.522548027387998, -7.549491458839266],
              [-40.523471202875925, -7.549542472314495],
              [-40.524005247174337, -7.550679758073726],
              [-40.523917516041905, -7.552750626819978],
              [-40.523837399221613, -7.55557258515586],
              [-40.525580720056766, -7.556696484011712],
              [-40.527137125162923, -7.556682187404267],
              [-40.528750735967925, -7.55655010253759],
              [-40.530078268822805, -7.55636462974431],
              [-40.531051012452828, -7.555836778666058],
              [-40.531733836212872, -7.55519207003111],
              [-40.532161080602172, -7.552369179990108],
              [-40.530707680452558, -7.551578579418226],
              [-40.529380171978794, -7.551312033870452],
              [-40.528502817894193, -7.55040175848685],
              [-40.5278352217438, -7.547826833070538],
              [-40.529425969119814, -7.546021997021749],
              [-40.531642287156487, -7.547723363876126],
              [-40.532691329947895, -7.54874136977016],
              [-40.533618276364535, -7.548846772928828],
              [-40.534133274956268, -7.548609797755038],
              [-40.534190505647047, -7.548501065442563],
              [-40.535212842562721, -7.54722268381921],
              [-40.535376872047316, -7.546471175376614],
              [-40.536044444881746, -7.544678749478569],
              [-40.53701339382831, -7.543807076418463],
              [-40.538630823463812, -7.543846657543422],
              [-40.539378490281493, -7.543780859558057],
              [-40.541095105336098, -7.542499590534051],
              [-40.541140903693723, -7.54169469330609],
              [-40.541240066165237, -7.540310908527475],
              [-40.542209016066323, -7.539493602147198],
              [-40.542720172165389, -7.538686797321073],
              [-40.542888021188098, -7.538451241847251],
              [-40.544326171461044, -7.53803924747678],
              [-40.544902194481196, -7.537973915344241],
              [-40.546172473594538, -7.537960573488408],
              [-40.546958324410369, -7.536393202365305],
              [-40.54698882503196, -7.534214066206832],
              [-40.546985007401631, -7.533806845598616],
              [-40.547835701321105, -7.532763972445439],
              [-40.548468931039856, -7.5325808970531],
              [-40.549102184606006, -7.532578987595208],
              [-40.551005717294352, -7.532554191337015],
              [-40.551986122438549, -7.532541315234726],
              [-40.552497255347234, -7.531961008076036],
              [-40.553176283613489, -7.530746979785078],
              [-40.552466731783397, -7.529320750578298],
              [-40.551989894034818, -7.528237364550577],
              [-40.551986122259009, -7.52800229892997],
              [-40.551871639106196, -7.527948398151056],
              [-40.551970825377992, -7.526799702990441],
              [-40.552821519540721, -7.525467421539292],
              [-40.554019317893406, -7.524585736728488],
              [-40.556079266689466, -7.523131869005075],
              [-40.560576791381706, -7.522907746089873],
              [-40.562880909466614, -7.522646440556397],
              [-40.563567528048338, -7.522182966857176],
              [-40.563731557531291, -7.521431947411343],
              [-40.564227464413882, -7.519820221305493],
              [-40.564387674265269, -7.518662469248742],
              [-40.56378115048642, -7.51631355355649],
              [-40.561850911820265, -7.514150157666615],
              [-40.560168615256259, -7.513369568851105],
              [-40.558036210023261, -7.513566985906134],
              [-40.556064015633808, -7.512615219855871],
              [-40.554438949631844, -7.511879915822878],
              [-40.552745220779428, -7.510348330417989],
              [-40.552501097399301, -7.509263990593995],
              [-40.557517416511743, -7.508975023487309],
              [-40.560283075266597, -7.508829596654836],
              [-40.561442735823086, -7.509214396360488],
              [-40.563121214604244, -7.509768953501443],
              [-40.56351793521457, -7.509306457553826],
              [-40.564479247791766, -7.507747654527565],
              [-40.56580675641694, -7.507842533079071],
              [-40.566737543424189, -7.508409010524869],
              [-40.566913029150619, -7.508462422357384],
              [-40.568313041251585, -7.509768488845234],
              [-40.56901872835612, -7.510905285748713],
              [-40.570643772458901, -7.511523731470295],
              [-40.57143344104891, -7.510191448966821],
              [-40.571483034233445, -7.509558194732952],
              [-40.57170047567589, -7.508409011674074],
              [-40.571753886499295, -7.508173945101388],
              [-40.571868322789356, -7.508065213836609],
              [-40.573012733463983, -7.507246974606191],
              [-40.573760399284772, -7.507181153618363],
              [-40.574611094158747, -7.506192670143628],
              [-40.575412172046327, -7.505601884288174],
              [-40.576445965107887, -7.505245676247403],
              [-40.577063944891613, -7.503922939544056],
              [-40.577460665600839, -7.503343097286168],
              [-40.578093918222976, -7.503277276202471],
              [-40.578959840216726, -7.503211012315824],
              [-40.579478657594038, -7.503200046813867],
              [-40.58040180914211, -7.503187636658282],
              [-40.580905352483725, -7.502154821153722],
              [-40.580840485566974, -7.50140475651124],
              [-40.580603976464609, -7.501116256010524],
              [-40.580939671934956, -7.500138273043405],
              [-40.581397440875698, -7.499965186443881],
              [-40.583533664853093, -7.500047677743133],
              [-40.584166917422479, -7.500045279286193],
              [-40.586165817748551, -7.498474113196899],
              [-40.587062263265935, -7.496336910515919],
              [-40.587733654069552, -7.494544018621995],
              [-40.587775633738474, -7.493395300413817],
              [-40.588534755707933, -7.4894771270952],
              [-40.588633965975582, -7.488328431935234],
              [-40.588603418165356, -7.485968549792456],
              [-40.588477525863624, -7.484992476429756],
              [-40.58829824649635, -7.484540899937011],
              [-40.58822194714265, -7.48321194769197],
              [-40.588614850309277, -7.482116663463444],
              [-40.58993092673775, -7.481352280057491],
              [-40.59120122880573, -7.481401872824364],
              [-40.592654629881821, -7.482300716747736],
              [-40.594939632505699, -7.485149846892218],
              [-40.5964006688545, -7.486853564089156],
              [-40.597636628724231, -7.488621682720515],
              [-40.598353794882243, -7.490797977519159],
              [-40.599475318355147, -7.492801161674459],
              [-40.599833901035645, -7.49376771428969],
              [-40.602553785450134, -7.49442669468557],
              [-40.605494904946973, -7.494334680682617],
              [-40.606822437669507, -7.494320850872532],
              [-40.608554303329335, -7.494585974759984],
              [-40.610171733880875, -7.494742856188367],
              [-40.61091942394772, -7.494387160904131],
              [-40.612113427522139, -7.493279909411197],
              [-40.612567377832633, -7.492699601202179],
              [-40.613143401047147, -7.492407771398929],
              [-40.615214781667554, -7.492273776916308],
              [-40.617175520168409, -7.492013426084105],
              [-40.618598420522282, -7.490452735447453],
              [-40.619101963827305, -7.489465205673621],
              [-40.619304131436401, -7.487050493039083],
              [-40.620147187897295, -7.48542877827741],
              [-40.620551546069827, -7.485427335623225],
              [-40.62206215274999, -7.486325713601768],
              [-40.622603834613606, -7.487933597600975],
              [-40.625453429022265, -7.489912009349295],
              [-40.628123719313493, -7.491141287353052],
              [-40.629451251850412, -7.491353000217256],
              [-40.630492657478335, -7.491457914470332],
              [-40.631411991104137, -7.491391161630411],
              [-40.633254498571901, -7.490968200655112],
              [-40.634402726893313, -7.490548103083093],
              [-40.635898083689654, -7.490244352117064],
              [-40.638770565254198, -7.489464741097599],
              [-40.64233348433438, -7.488275976058864],
              [-40.645484431727176, -7.486573654693601],
              [-40.647654997874973, -7.48499198780025],
              [-40.649936181582873, -7.483012621336019],
              [-40.650504569140779, -7.482377482542746],
              [-40.65056179883927, -7.482259716282559],
              [-40.650676235085108, -7.48220488470028],
              [-40.651477336925595, -7.481677498467946],
              [-40.652453899252173, -7.48149295736725],
              [-40.654239176934645, -7.481296494011328],
              [-40.654643535018067, -7.481403293721168],
              [-40.654551985210695, -7.483130899493124],
              [-40.653476236470908, -7.484635802752453],
              [-40.652400487632306, -7.486258471319722],
              [-40.651038636596148, -7.488117185261139],
              [-40.648597218233128, -7.491019703167616],
              [-40.6456713486062, -7.492314802113541],
              [-40.643485531436951, -7.492856483104458],
              [-40.641532428588782, -7.493460144925455],
              [-40.638953663636158, -7.494636522858032],
              [-40.637984737537096, -7.495679815917662],
              [-40.637370553525599, -7.497237198252876],
              [-40.636241415908515, -7.499041056709376],
              [-40.634517163378312, -7.499743438140495],
              [-40.632094837388721, -7.499662389591465],
              [-40.631175480664609, -7.499846442741264],
              [-40.630073026660774, -7.499506928814714],
              [-40.629374952966195, -7.499003384190309],
              [-40.626788573647218, -7.499492144155798],
              [-40.624774423282496, -7.50003289329396],
              [-40.622950984575027, -7.501775726526526],
              [-40.621859985998519, -7.502024157154222],
              [-40.620990222316024, -7.501746646057949],
              [-40.619479615645588, -7.500839211128398],
              [-40.618037646767071, -7.500799163158338],
              [-40.61603111000931, -7.501747110908974],
              [-40.61580223742002, -7.501974075383512],
              [-40.614196238960382, -7.502802837169352],
              [-40.613158652118152, -7.502923955589867],
              [-40.611659476640611, -7.502884351569159],
              [-40.610972835192236, -7.503185262226212],
              [-40.609729239052648, -7.505377762298341],
              [-40.609561390884721, -7.505785471761425],
              [-40.608252949965092, -7.507065297144068],
              [-40.606460035687235, -7.506855004880424],
              [-40.605483473366078, -7.507039056981628],
              [-40.60514014162311, -7.507212167676852],
              [-40.604224626487273, -7.507857784115817],
              [-40.604171214617892, -7.508147240369595],
              [-40.604247513830948, -7.509638778665768],
              [-40.604190284086798, -7.509810933605945],
              [-40.60368292195205, -7.510626308388456],
              [-40.603000099142889, -7.511325359707956],
              [-40.601626815065011, -7.512144086746048],
              [-40.599910200001169, -7.51342535577766],
              [-40.59992163234358, -7.514112486748216],
              [-40.600173415183711, -7.515775225185531],
              [-40.600776121279324, -7.517780784902257],
              [-40.600860079805756, -7.520022877795006],
              [-40.601214820700555, -7.520762931742855],
              [-40.602343934580212, -7.523399370195207],
              [-40.602656766846707, -7.525233775677659],
              [-40.603072579214938, -7.526091128988306],
              [-40.602042604779996, -7.526854535638647],
              [-40.599791944516312, -7.526871718650329],
              [-40.597350502981172, -7.525234730223308],
              [-40.596465512519188, -7.523637788866772],
              [-40.594962519315331, -7.523308775540792],
              [-40.59328024560201, -7.522699852709938],
              [-40.591609405184961, -7.522832426530519],
              [-40.590506950324333, -7.522329837560916],
              [-40.590087342324878, -7.521065750639823],
              [-40.589785966300745, -7.520035753442048],
              [-40.588107511462212, -7.519544619923292],
              [-40.587996869901794, -7.519942784489902],
              [-40.587424641418693, -7.520523557596463],
              [-40.586276435246972, -7.520771988174156],
              [-40.585357102435928, -7.521064772682863],
              [-40.585132024622766, -7.521526826777998],
              [-40.585902601930144, -7.523070379349682],
              [-40.586551105380728, -7.524270552838047],
              [-40.5866731791386, -7.524731186232675],
              [-40.58652058180602, -7.52639534632275],
              [-40.585719504015323, -7.526868365870762],
              [-40.584216487813528, -7.52653935154475],
              [-40.583400158004842, -7.525972874190344],
              [-40.581141862324671, -7.525365860865854],
              [-40.579585456154618, -7.525444045101847],
              [-40.57878056058923, -7.525681998028352],
              [-40.577121151273026, -7.526791135116945],
              [-40.576495558255054, -7.527263665814549],
              [-40.575980559372134, -7.527843973971247],
              [-40.575759323387544, -7.528477693060214],
              [-40.575309166859952, -7.529284498939656],
              [-40.574511907609427, -7.530272983460078],
              [-40.574347879125405, -7.531024002907419],
              [-40.575103182504819, -7.531418837219772],
              [-40.576030175781391, -7.531695883368681],
              [-40.576091200163766, -7.531921892091237],
              [-40.576003446888976, -7.534164473835172],
              [-40.575839417549226, -7.53474382729204],
              [-40.575957671432022, -7.535087160373105],
              [-40.577063944019777, -7.535878691648927],
              [-40.57839527132937, -7.53631739218769],
              [-40.580596362553301, -7.536752274479149],
              [-40.582213791854024, -7.537198587213757],
              [-40.58476582819587, -7.538138922830025],
              [-40.586909665478586, -7.538981002681648],
              [-40.589000137944176, -7.540049604157971],
              [-40.589934744688222, -7.540895990969729],
              [-40.589084073564699, -7.542173906746535],
              [-40.587241566250249, -7.542425201722066],
              [-40.584762009585184, -7.542451907482124],
              [-40.582515145093872, -7.542713214059122],
              [-40.581714067355982, -7.543123298976395],
              [-40.579993632708508, -7.543943468711388],
              [-40.577975663624322, -7.544194786539126],
              [-40.576415439871305, -7.543811383481525],
              [-40.574553863806841, -7.542742293200851],
              [-40.573214899993687, -7.541779560734573],
              [-40.572059057365259, -7.541449126706045],
              [-40.571826343039703, -7.54139571582454],
              [-40.571479215560736, -7.541288427163683],
              [-40.570212731317952, -7.541419557329421],
              [-40.569590933892052, -7.542344152372681],
              [-40.56804980150411, -7.543742232860732],
              [-40.566436190600079, -7.543983027090054],
              [-40.56500185928887, -7.544404078410365],
              [-40.563392043106973, -7.544943384888084],
              [-40.561416029800696, -7.543928683193069],
              [-40.560492878499261, -7.543651638046394],
              [-40.558410043308179, -7.54338793384757],
              [-40.558181170857203, -7.543451846279608],
              [-40.55755173592032, -7.543860977345836],
              [-40.556578991188438, -7.544506593733371],
              [-40.556525579227078, -7.544904292350475],
              [-40.556697245439395, -7.549153877513032],
              [-40.556937573123662, -7.549903453308784],
              [-40.556949005173387, -7.550934382258475],
              [-40.556952823948166, -7.551169471879795],
              [-40.556510329641029, -7.552835052717605],
              [-40.556304343380383, -7.554852089688774],
              [-40.556540876434177, -7.555194933872563],
              [-40.559138664118294, -7.555339428208232],
              [-40.560481422565204, -7.556699837342412],
              [-40.560801869190286, -7.559221349793739],
              [-40.561324480386375, -7.559391573233258],
              [-40.562991504022683, -7.55902393279651],
              [-40.565005677420451, -7.558428839988468],
              [-40.565638906988106, -7.558426464535304],
              [-40.566642176375645, -7.560303758348788],
              [-40.566657426244426, -7.561515411242286],
              [-40.567027441201709, -7.56323202746788],
              [-40.566169156620425, -7.563931544635182],
              [-40.564086320720136, -7.563324065450526],
              [-40.562045464351954, -7.561740000040042],
              [-40.559722300715514, -7.560446343686453],
              [-40.557063465604159, -7.560130206306618],
              [-40.555796981508649, -7.560089669478994],
              [-40.55446565492106, -7.5599857109696],
              [-40.553252581498164, -7.559890343509324],
              [-40.552619329076649, -7.559721074971287],
              [-40.552501097095401, -7.559495532197423],
              [-40.552382819022988, -7.559378230787359],
              [-40.55157410377695, -7.559272362729156],
              [-40.550822572229507, -7.559049195408485],
              [-40.55023132128585, -7.557966297276139],
              [-40.549979561255725, -7.556538205458712],
              [-40.549800259045767, -7.55590588302479],
              [-40.549151777456672, -7.554877352525518],
              [-40.548385042985323, -7.5535059549427],
              [-40.547747946728563, -7.553173611360863],
              [-40.547458048758429, -7.553174565111524],
              [-40.545798616446014, -7.554283680196116],
              [-40.545192114890504, -7.556419927127688],
              [-40.545207365668837, -7.557739799381531],
              [-40.545039517653315, -7.55796629790888],
              [-40.544291828735815, -7.558149885200763],
              [-40.543185579054899, -7.557475629234008],
              [-40.542079305473287, -7.55668409695692],
              [-40.541213361529472, -7.556696018158619],
              [-40.540694567162298, -7.556697927711099],
              [-40.540351234566678, -7.556698905416639],
              [-40.538913131232391, -7.55718333335923],
              [-40.537364339490118, -7.557885248929926],
              [-40.536460257652806, -7.559271898017006],
              [-40.535487512968423, -7.559863171722499],
              [-40.53543028326991, -7.559980937981988],
              [-40.53537307657885, -7.560089670294585],
              [-40.53525861742628, -7.560035792515102],
              [-40.535029743830783, -7.560271347937666],
              [-40.534167617715582, -7.5604554001303],
              [-40.533419927806726, -7.560629931475175],
              [-40.5330766180127, -7.560865951798667],
              [-40.531981801573139, -7.560996616109414],
              [-40.530886983141421, -7.561118200473548],
              [-40.529506063507739, -7.561484421158501],
              [-40.527324041194852, -7.562197279131326],
              [-40.525126769768526, -7.561987940513691],
              [-40.525077176726001, -7.562449527757762],
              [-40.525267912029875, -7.564058390458173],
              [-40.526500077145862, -7.565537053801015],
              [-40.527369816717659, -7.565931888211663],
              [-40.527903884005788, -7.567078673918938],
              [-40.528136575139008, -7.567358094472088],
              [-40.528781260996638, -7.568097193669689],
              [-40.5295403830984, -7.568781925283444],
              [-40.52971968650602, -7.569179159099223],
              [-40.528861377974913, -7.569823844586458],
              [-40.527770355119308, -7.570415584188906],
              [-40.526164380779022, -7.571117500709768],
              [-40.52315457551942, -7.57035027568542],
              [-40.521590556949192, -7.569795229631659],
              [-40.520137134048028, -7.568715196758133],
              [-40.519259756866873, -7.56792268667939],
              [-40.518851580917357, -7.56769807465651],
              [-40.517928429575605, -7.567474929191564],
              [-40.515910460696901, -7.567500214344299],
              [-40.513274487932968, -7.568965047511389],
              [-40.512591664883345, -7.569953065126424],
              [-40.512248333089737, -7.570189575446564],
              [-40.512378043408766, -7.571102227188993],
              [-40.513140983145867, -7.572184657466494],
              [-40.514937715838997, -7.573028671017027],
              [-40.517146420455454, -7.574096759597597],
              [-40.51860363919068, -7.575339890516652],
              [-40.518451041943166, -7.576895806240728],
              [-40.518641776244372, -7.578504668943252],
              [-40.519927329174529, -7.579756833666079],
              [-40.522715875391846, -7.581221224409573],
              [-40.524218868488326, -7.581658947104319],
              [-40.525317503800281, -7.581646072106652],
              [-40.527766536860447, -7.583744623838759],
              [-40.52806411814796, -7.584484699740382],
              [-40.527167648618324, -7.586621879424005],
              [-40.526942571803851, -7.58708393251867],
              [-40.527411773026721, -7.587660910524027],
              [-40.528228123733186, -7.588336586242555],
              [-40.529448833554049, -7.589073775941256],
              [-40.530841209134486, -7.589855320524587],
              [-40.532347997296824, -7.590184822860925],
              [-40.533622117889763, -7.590749856617069],
              [-40.534503312797455, -7.591831799004524],
              [-40.535090768808104, -7.592860819455161],
              [-40.53419432337536, -7.594880232833705],
              [-40.53402645230554, -7.595170152988569],
              [-40.534087476686075, -7.595396162712365],
              [-40.534087476586478, -7.595513486023081],
              [-40.53420195973856, -7.595567363803326],
              [-40.534262985211413, -7.595684663165789],
              [-40.532721853876524, -7.597019809019518],
              [-40.532016119687214, -7.596000335412349],
              [-40.530963259128555, -7.59474719288502],
              [-40.529731118170794, -7.593087805593619],
              [-40.527865723341677, -7.591792241630452],
              [-40.525374734649567, -7.590788018427743],
              [-40.522590007260739, -7.589495781670403],
              [-40.520377460918233, -7.588137748783942],
              [-40.518985108392734, -7.587292781575007],
              [-40.51741345426904, -7.586276637221292],
              [-40.516310999521544, -7.585656281937489],
              [-40.51566631352086, -7.585088872728873],
              [-40.515261956591317, -7.584809896027645],
              [-40.514849961814697, -7.584413640009389],
              [-40.514560041091123, -7.584125162460616],
              [-40.514441785965673, -7.584071262677697],
              [-40.514209094741162, -7.58390057448485],
              [-40.513331717707935, -7.582935932415561],
              [-40.512923541904968, -7.582539188403721],
              [-40.511821087965792, -7.582145330789484],
              [-40.509913737704437, -7.581709028746199],
              [-40.508239100430245, -7.581723790247397],
              [-40.506507211695393, -7.581575989656114],
              [-40.505351368932097, -7.581417198615269],
              [-40.504370987847707, -7.581366162088809],
              [-40.503100684832972, -7.581271283575785],
              [-40.502177534248311, -7.581337570407726],
              [-40.501601510330396, -7.581285579227819],
              [-40.501082715957999, -7.581296545726016],
              [-40.500274001625449, -7.581298920030311],
              [-40.499923032521089, -7.580784898757098],
              [-40.499690341143904, -7.580794910502093],
              [-40.499289801759296, -7.581022363826929],
              [-40.498660366825611, -7.581431517890254],
              [-40.498610774637463, -7.582064259127612],
              [-40.498622228821887, -7.582932579031171],
              [-40.498587887408313, -7.584885704517327],
              [-40.498595523772288, -7.585573301480619],
              [-40.499236390990099, -7.585914702013748],
              [-40.500678358801096, -7.586009137674794],
              [-40.501544280741086, -7.585996774477936],
              [-40.502696328675832, -7.585983852525737],
              [-40.503852193583938, -7.585970976576472],
              [-40.505183520872201, -7.586418268069869],
              [-40.506221108609296, -7.586405882018027],
              [-40.507891949208677, -7.586038218589167],
              [-40.509448355357122, -7.585969557305852],
              [-40.510775863871807, -7.586182224175683],
              [-40.511298475064763, -7.58635198161866],
              [-40.511882136592234, -7.586802114465033],
              [-40.511496848072994, -7.588241218680361],
              [-40.510470691381222, -7.58928453468144],
              [-40.510649971780147, -7.589690801444636],
              [-40.512507729805485, -7.590814723406477],
              [-40.513495747499761, -7.591263411603358],
              [-40.513915354539947, -7.592464563897833],
              [-40.514914852225345, -7.594007627672915],
              [-40.516822155578893, -7.594335686355985],
              [-40.517859767416986, -7.594205510997473],
              [-40.518782918811787, -7.594364767841386],
              [-40.519160570216883, -7.596660271670914],
              [-40.519458128349434, -7.597581513508925],
              [-40.519877735342661, -7.598837031486029],
              [-40.520057038794917, -7.599179875623085],
              [-40.52018293122952, -7.599984306002813],
              [-40.520961120834819, -7.602269310234989],
              [-40.52126247489398, -7.603244918757855],
              [-40.521739312385613, -7.604617737095659],
              [-40.52268537429795, -7.606332443822442],
              [-40.523562751329088, -7.607297085897994],
              [-40.52454695019609, -7.607574132103418],
              [-40.525927870630348, -7.607442536045124],
              [-40.526687015774357, -7.608072902984794],
              [-40.528018320072199, -7.608511113537632],
              [-40.529815053102077, -7.608956984439653],
              [-40.530684792679267, -7.609342739910216],
              [-40.530818298374953, -7.610952066571183],
              [-40.530600880213392, -7.611757429657041],
              [-40.53026136704527, -7.612400694591524],
              [-40.531310409874145, -7.613364382820373],
              [-40.531951276000243, -7.613814514719725],
              [-40.532309859803696, -7.614617991304976],
              [-40.532309859657595, -7.61478965829686],
              [-40.533080436998034, -7.616269740258181],
              [-40.534022657035585, -7.617867148676543],
              [-40.534659705327208, -7.618154205530968],
              [-40.535762159274469, -7.618539028205869],
              [-40.536807384522533, -7.619041617140939],
              [-40.537505481293941, -7.619437383409099],
              [-40.538565979356626, -7.62120596792464],
              [-40.539737071983822, -7.622575921875026],
              [-40.539687479691317, -7.623326942423977],
              [-40.538890220489158, -7.624252026316854],
              [-40.538787215478472, -7.62511128918513],
              [-40.539260234379171, -7.626032066181158],
              [-40.541404094840324, -7.626639079437252],
              [-40.543345788583117, -7.625058832361025],
              [-40.543437337552817, -7.623168640534296],
              [-40.544120184271726, -7.622578320578888],
              [-40.545562129181015, -7.622564489883222],
              [-40.547023166878198, -7.623816189775384],
              [-40.547606803359727, -7.624320199312247],
              [-40.547915793723313, -7.626046361442809],
              [-40.55017029570449, -7.626309576811417],
              [-40.551768656460624, -7.625191382730751],
              [-40.552749037741862, -7.625016410589692],
              [-40.553328856496748, -7.625231452819747],
              [-40.553401336909872, -7.626496982454159],
              [-40.553065641574207, -7.627302833439932],
              [-40.552333224569139, -7.628634628002346],
              [-40.553798080846043, -7.6303474491949],
              [-40.554839486368508, -7.630561070825816],
              [-40.556281431279835, -7.63054675213455],
              [-40.557834018717827, -7.630179111610472],
              [-40.559150095343696, -7.62918920753664],
              [-40.559897785209394, -7.629068554879566],
              [-40.560485240417108, -7.629862485720048],
              [-40.561419847140719, -7.630717929494743],
              [-40.562583326369889, -7.631446061211046],
              [-40.563224193396692, -7.632013517426307],
              [-40.563247081615451, -7.633912277306205],
              [-40.563258536801499, -7.634771540275003],
              [-40.56315935050754, -7.635929292398767],
              [-40.562995320579887, -7.636018955733358],
              [-40.563983316564943, -7.637300690054281],
              [-40.56451356407252, -7.638211921159861],
              [-40.566371322347656, -7.639046387844866],
              [-40.567122830939887, -7.639215190493883],
              [-40.569087388854136, -7.639597148924432],
              [-40.573138599883784, -7.640296667267882],
              [-40.575576223761558, -7.64152643312983],
              [-40.576327709408467, -7.641632278148568],
              [-40.577643761938774, -7.64075916238842],
              [-40.579249760393154, -7.639939503561381],
              [-40.580756572467543, -7.640377249276483],
              [-40.581694996824623, -7.641630858724159],
              [-40.580676454523271, -7.643307404026819],
              [-40.579017069287616, -7.64430780874845],
              [-40.57707155676065, -7.645653456201947],
              [-40.575362578137735, -7.647513123842557],
              [-40.57360400537415, -7.649943088096815],
              [-40.572589282045683, -7.651619657402113],
              [-40.572089556158389, -7.653239951476141],
              [-40.571998007328027, -7.654958477322323],
              [-40.571956051390593, -7.656396138857794],
              [-40.571986576108557, -7.658810386730047],
              [-40.572028531955404, -7.662146808206959],
              [-40.571238886398149, -7.663415667095212],
              [-40.569575681235612, -7.664362195127565],
              [-40.56820237415242, -7.665180899156386],
              [-40.567290678690071, -7.666169894580844],
              [-40.567656875846289, -7.66766094415304],
              [-40.570201298706884, -7.668031449153391],
              [-40.572101012523682, -7.667888886610866],
              [-40.573836673187174, -7.668099179860109],
              [-40.57517184235148, -7.668664213688514],
              [-40.577323339219888, -7.669903992259946],
              [-40.57807482471889, -7.670181527275308],
              [-40.578940769711437, -7.670115239403351],
              [-40.57922687276308, -7.670050862024988],
              [-40.579516792687357, -7.670103784962467],
              [-40.582354955052445, -7.670988798200682],
              [-40.58305302877028, -7.671447522109045],
              [-40.584735302514318, -7.67200161329964],
              [-40.586230659257374, -7.671752228038495],
              [-40.587092786425238, -7.671513809172015],
              [-40.587500961327621, -7.671792763886333],
              [-40.588317313023929, -7.6724770075749],
              [-40.588614847351352, -7.673163183808988],
              [-40.588618666130522, -7.673389216488025],
              [-40.588618666029781, -7.673506516800783],
              [-40.588736921054767, -7.673677693898991],
              [-40.590911306086632, -7.676871088049758],
              [-40.591143997458168, -7.676870133254289],
              [-40.592532554359764, -7.6773078548775],
              [-40.593585415105338, -7.678334965761417],
              [-40.594863331532572, -7.679062632592173],
              [-40.596888936976384, -7.679497979774952],
              [-40.598220264418629, -7.679773582299695],
              [-40.599898742440111, -7.680038241185595],
              [-40.600699820331577, -7.679456978275177],
              [-40.601375052749276, -7.678125161652731],
              [-40.601935803913349, -7.676857257558122],
              [-40.602374480328066, -7.675128231059929],
              [-40.603007734052326, -7.674945132673819],
              [-40.604522182328694, -7.676250709322367],
              [-40.605060044485306, -7.677505272435081],
              [-40.606517241107802, -7.67887427266447],
              [-40.60687964249145, -7.680129789622311],
              [-40.607524328389836, -7.680814523164376],
              [-40.60861914478388, -7.680747304494868],
              [-40.609240943381828, -7.6796419864992],
              [-40.609466021102747, -7.679297233710513],
              [-40.612121038542298, -7.679269573144224],
              [-40.613616418290256, -7.679019721886693],
              [-40.615680161855458, -7.677845252529162],
              [-40.617171699972872, -7.677197703596669],
              [-40.617801135919194, -7.676788595530483],
              [-40.617972801772709, -7.676724683051581],
              [-40.619979338637819, -7.675668025954717],
              [-40.621703568034775, -7.675128230589991],
              [-40.623084510678353, -7.674770113867869],
              [-40.62491938264381, -7.673832200928393],
              [-40.626120999039387, -7.672896150436277],
              [-40.627082334511826, -7.671499979432404],
              [-40.62816949255236, -7.670745629778438],
              [-40.629367337974585, -7.669809556282881],
              [-40.630221803697196, -7.668992761795389],
              [-40.630679572790996, -7.668647055210696],
              [-40.631885007965735, -7.667937525395091],
              [-40.63264794859689, -7.669137257023429],
              [-40.632503010730659, -7.671325938086386],
              [-40.63150353819529, -7.67426850300583],
              [-40.630198916679987, -7.676181093693777],
              [-40.628379296813023, -7.678041715245191],
              [-40.627067083949392, -7.679258141001569],
              [-40.625873056955712, -7.680826933796328],
              [-40.624621823564155, -7.68216114791512],
              [-40.623782584792821, -7.684072294009619],
              [-40.622195655955899, -7.686329637437297],
              [-40.621104680034989, -7.686975743720901],
              [-40.619964065165561, -7.687965670949354],
              [-40.619334653265263, -7.688320435333512],
              [-40.618880702043811, -7.688783443234432],
              [-40.618075805324125, -7.689193529144337],
              [-40.61778588550029, -7.689022816895187],
              [-40.616469808053481, -7.689787178293312],
              [-40.616134112701332, -7.690602110235488],
              [-40.616210410882211, -7.692094137567539],
              [-40.616633836588043, -7.693638598890596],
              [-40.61669868054215, -7.694325729924414],
              [-40.616645292619552, -7.694669527866429],
              [-40.616656724846329, -7.695473958168078],
              [-40.617141223022934, -7.697135743857813],
              [-40.619563526092385, -7.697108548094653],
              [-40.623691034979743, -7.695049577684019],
              [-40.62541147055304, -7.69433765032559],
              [-40.627128109643806, -7.693056381295433],
              [-40.628447980964872, -7.692409321213589],
              [-40.62971825984598, -7.692675889742845],
              [-40.631415807398568, -7.694495952548523],
              [-40.632708996724546, -7.696380905628827],
              [-40.633056124249869, -7.696433827616995],
              [-40.63462398474654, -7.69705134234921],
              [-40.636584722947561, -7.697143357493222],
              [-40.639174921401803, -7.696473409635003],
              [-40.639739489248491, -7.695549303525538],
              [-40.640426131750516, -7.69519404919293],
              [-40.641116591124778, -7.694956143177007],
              [-40.642272457049344, -7.694942755246905],
              [-40.643367274346453, -7.694992836893145],
              [-40.64446209179998, -7.694861706599499],
              [-40.64509532242608, -7.694796373524937],
              [-40.645614139899628, -7.69467667567024],
              [-40.646132910373488, -7.69455700081535],
              [-40.646769981824953, -7.694663334739847],
              [-40.647174339849343, -7.694833557091097],
              [-40.647471898230677, -7.695456310703636],
              [-40.648009737136555, -7.697000306133987],
              [-40.647796137631843, -7.698158547176112],
              [-40.64804410261079, -7.699649620667883],
              [-40.648105127035734, -7.699821286716265],
              [-40.648860431411187, -7.700216098055628],
              [-40.649493684990979, -7.700204665666188],
              [-40.649955272818225, -7.700139333444582],
              [-40.650477883820749, -7.700535122578579],
              [-40.650840262249105, -7.701736274865115],
              [-40.651961785822564, -7.703567793117669],
              [-40.652949803375797, -7.704187658341622],
              [-40.653468597803055, -7.704122373169072],
              [-40.655139438183163, -7.704043676064621],
              [-40.65612363925495, -7.70409422461463],
              [-40.657218455561356, -7.704135249315317],
              [-40.658210268986842, -7.704872903854374],
              [-40.658740518592587, -7.705666368670767],
              [-40.658637513461429, -7.706652477816738],
              [-40.657088722735224, -7.707300493696812],
              [-40.654128533455193, -7.706127467011546],
              [-40.652648450554452, -7.707579937246652],
              [-40.65098522237983, -7.708517384335658],
              [-40.650481679900999, -7.709676580124798],
              [-40.649680601150912, -7.710086619038567],
              [-40.648700219998403, -7.710099541120194],
              [-40.647532922895209, -7.709254085060526],
              [-40.645095322917435, -7.70813305052103],
              [-40.643992890970203, -7.707739658874202],
              [-40.642604333868078, -7.707527944919176],
              [-40.642085540543107, -7.707475511780031],
              [-40.641219595637715, -7.707433999278756],
              [-40.640529135419712, -7.707490739353616],
              [-40.640540567395043, -7.708584602966032],
              [-40.641097522015876, -7.711458040640331],
              [-40.641402692944112, -7.712541891564429],
              [-40.641478993121233, -7.714033429907973],
              [-40.641486629567446, -7.714612317529491],
              [-40.641444649720171, -7.715932213775383],
              [-40.64129589426684, -7.71766026254303],
              [-40.642241932117933, -7.719428869016122],
              [-40.643069715842778, -7.721143576703243],
              [-40.643439731667023, -7.722977493300055],
              [-40.64387463568481, -7.725218142575628],
              [-40.644420136085031, -7.727277603006461],
              [-40.644610870560882, -7.728651375140464],
              [-40.643878453531464, -7.729983657721701],
              [-40.643020122931105, -7.730683174893099],
              [-40.64065505050317, -7.730656004013676],
              [-40.637774908959045, -7.726842254827157],
              [-40.635943855841617, -7.723295539986902],
              [-40.634692646119689, -7.720379681959746],
              [-40.634131872817676, -7.717171992235098],
              [-40.633647421699116, -7.715446784907677],
              [-40.632762408171025, -7.713967166800087],
              [-40.630767302399256, -7.711343603429792],
              [-40.630118821919261, -7.710206805521872],
              [-40.629180397521679, -7.709008051727556],
              [-40.628947705297939, -7.708836851529082],
              [-40.627906276621417, -7.708795849874146],
              [-40.627097561278568, -7.708798713162583],
              [-40.626525356078027, -7.709036178281851],
              [-40.625434357405332, -7.709392852257904],
              [-40.624164055275919, -7.709415739032628],
              [-40.623187491904339, -7.709654647795922],
              [-40.622962437932173, -7.7102883438953],
              [-40.622977688935094, -7.711319272877417],
              [-40.622992938633452, -7.712702590798235],
              [-40.62288613898221, -7.713164155006383],
              [-40.622252886162158, -7.713455985754905],
              [-40.622027831385033, -7.71386369617638],
              [-40.621417465163042, -7.715592698537533],
              [-40.621207661036557, -7.717438070562582],
              [-40.621211478767457, -7.71771844592581],
              [-40.620952081690412, -7.719907616897729],
              [-40.619986928441726, -7.721068697276098],
              [-40.618720490996765, -7.721426326094599],
              [-40.61803003056945, -7.721727213740556],
              [-40.617640900494351, -7.722650855003168],
              [-40.617190791892675, -7.72352061752852],
              [-40.616332484295441, -7.72422013569826],
              [-40.615645842700467, -7.724684097393503],
              [-40.614497613327494, -7.725158537630128],
              [-40.613402795933126, -7.725225778294448],
              [-40.613288336632081, -7.725344034504208],
              [-40.611995170260194, -7.723522504046195],
              [-40.61117118101901, -7.722377139037274],
              [-40.609767374217554, -7.720791187107469],
              [-40.608600075175403, -7.719881865423281],
              [-40.606788092581638, -7.717890554515631],
              [-40.605494903107569, -7.716186371375183],
              [-40.604854036084092, -7.715619404141872],
              [-40.603686738041425, -7.714710572456672],
              [-40.602992459993956, -7.714604215482377],
              [-40.601897641546145, -7.714734879776808],
              [-40.601809911425711, -7.716742836951338],
              [-40.601821342454926, -7.717773765928573],
              [-40.601142338503472, -7.718590095568057],
              [-40.59970418816178, -7.719065513549585],
              [-40.599128164036614, -7.719248610982837],
              [-40.598449159889938, -7.720290972299503],
              [-40.598048621442956, -7.720581848252956],
              [-40.597823542814858, -7.720817892678161],
              [-40.596961416403303, -7.721336219903696],
              [-40.595832280656438, -7.723248321743753],
              [-40.594584864932607, -7.724934902801661],
              [-40.593909655563586, -7.726203296798852],
              [-40.59339849843721, -7.727019158585162],
              [-40.593348905393405, -7.727471200897462],
              [-40.593013209843143, -7.728512630514945],
              [-40.592299862394754, -7.731336451378814],
              [-40.59197180339185, -7.732838491308234],
              [-40.591582696316642, -7.733762597566523],
              [-40.591067697569187, -7.734171262732031],
              [-40.59049169749921, -7.734290937535704],
              [-40.589152733662274, -7.733382570697015],
              [-40.588538571591037, -7.730346523932465],
              [-40.588504229966908, -7.727878422345035],
              [-40.588199058198256, -7.726613381485087],
              [-40.587489528298683, -7.725304941531586],
              [-40.586146745831627, -7.723998876019922],
              [-40.585105340316964, -7.723776638424802],
              [-40.583026322956215, -7.723685135172127],
              [-40.581797976735437, -7.722324215081164],
              [-40.580974012441608, -7.721241806708286],
              [-40.580096635474085, -7.720214696966151],
              [-40.579165846529598, -7.719593852800259],
              [-40.578063391684921, -7.71909170579216],
              [-40.577369113740808, -7.718868094504454],
              [-40.57528627851017, -7.718658756939893],
              [-40.574595817336515, -7.71867021227948],
              [-40.573783284267151, -7.718393166203171],
              [-40.571402914862269, -7.717326008405666],
              [-40.569213279076379, -7.717469526684038],
              [-40.565871597052976, -7.717970205881332],
              [-40.564082500518417, -7.718048901883377],
              [-40.563105938052253, -7.718405087958633],
              [-40.561457983944422, -7.720319121334658],
              [-40.560885778552866, -7.720782595128956],
              [-40.559283622819962, -7.721891242260111],
              [-40.55802472902667, -7.722601284020302],
              [-40.557910316726527, -7.722719050232294],
              [-40.557853109978609, -7.722891182175202],
              [-40.557738649733182, -7.722946013755724],
              [-40.557280903884269, -7.723010856982552],
              [-40.555495604129284, -7.723316051660723],
              [-40.553763737242953, -7.723340359029718],
              [-40.551852568112551, -7.72278675662904],
              [-40.550109246057183, -7.721942745073794],
              [-40.548320149627209, -7.721904117762531],
              [-40.547110896941184, -7.722089149639698],
              [-40.545493466254484, -7.722113456107431],
              [-40.544524540291988, -7.722984640172112],
              [-40.543216077105988, -7.724553920856057],
              [-40.542426454543396, -7.725822779745528],
              [-40.541793200674775, -7.726177545123222],
              [-40.540873868005178, -7.726307720570445],
              [-40.53935560092247, -7.724830011920752],
              [-40.538825374183467, -7.724199156160372],
              [-40.538169234310807, -7.722429127964973],
              [-40.537875494665556, -7.722086308720822],
              [-40.537295675969297, -7.721808307851196],
              [-40.536258065230477, -7.721821160884498],
              [-40.536082603459811, -7.72182162673099],
              [-40.535811775263888, -7.723088599076164],
              [-40.535315844145046, -7.724934901834274],
              [-40.534800845502957, -7.72522624368499],
              [-40.533183415975053, -7.725069386213795],
              [-40.531966524836477, -7.724685051419509],
              [-40.53063519845314, -7.724355083208541],
              [-40.52924664127557, -7.724251589621716],
              [-40.52815561870905, -7.724499554231475],
              [-40.526503869735883, -7.726295799288274],
              [-40.525813409370834, -7.726533264303579],
              [-40.525641766471914, -7.726651518463249],
              [-40.524089179991499, -7.727073059659071],
              [-40.523173642906599, -7.727655276460995],
              [-40.522483181393525, -7.72806438347053],
              [-40.520415620366101, -7.728668556153394],
              [-40.517421065936709, -7.729158735707139],
              [-40.516619986996005, -7.729803445240933],
              [-40.515296296967541, -7.730161050007619],
              [-40.513854328065641, -7.730174880686918],
              [-40.512469589856217, -7.730080467049755],
              [-40.510905549106916, -7.729751453633291],
              [-40.509578040403156, -7.729765283411149],
              [-40.507563889254797, -7.730079512838392],
              [-40.505656538811103, -7.729869219431424],
              [-40.504088701433126, -7.72914253034285],
              [-40.502349198212144, -7.728470183788611],
              [-40.501307768551186, -7.728428717136738],
              [-40.500499054368312, -7.728259891431391],
              [-40.500266362093235, -7.728152136863054],
              [-40.500388435839312, -7.728612746268136],
              [-40.499163931359774, -7.727532247549365],
              [-40.49836664815227, -7.728457308442001],
              [-40.497157371418517, -7.728705739946758],
              [-40.496291450481131, -7.728717660132868],
              [-40.49578790691757, -7.729985565268088],
              [-40.494990623710208, -7.73091064916041],
              [-40.494021697596622, -7.731963022159009],
              [-40.492987905641328, -7.732201440872901],
              [-40.49160698496302, -7.732622492217824],
              [-40.490641877783773, -7.733720173953859],
              [-40.490485461732021, -7.735049593026757],
              [-40.49078301990312, -7.73590790125168],
              [-40.49119119569761, -7.736304178279932],
              [-40.491778651890904, -7.737098086131873],
              [-40.493003178571264, -7.737943519230019],
              [-40.495547601201444, -7.738549577866883],
              [-40.496989523249347, -7.738363615197186],
              [-40.49831705500538, -7.738286851789116],
              [-40.498839668044525, -7.738628740235209],
              [-40.500174813098681, -7.739302041438414],
              [-40.500697426192744, -7.739580507256318],
              [-40.502143188917223, -7.739738343575506],
              [-40.503176982077456, -7.73926436924243],
              [-40.505652718989658, -7.738948208225406],
              [-40.506457592645418, -7.73860154594923],
              [-40.50685435927106, -7.738129970053739],
              [-40.507773716225998, -7.737665518566175],
              [-40.510356253040129, -7.736598362040841],
              [-40.511561688998157, -7.736123898857834],
              [-40.513007475826207, -7.736163968866848],
              [-40.513530087866187, -7.736505858313383],
              [-40.514128999135664, -7.738276350455957],
              [-40.514727887592954, -7.739829403873002],
              [-40.514567699823644, -7.740860821692798],
              [-40.514411282777345, -7.742181648820615],
              [-40.514796549243528, -7.745453715985406],
              [-40.515456507075569, -7.747232800133128],
              [-40.515921890320691, -7.747800722207678],
              [-40.516917545355867, -7.748936542413229],
              [-40.518084842430937, -7.749791520411856],
              [-40.519069019243986, -7.750122443322113],
              [-40.520522420424022, -7.750849621316371],
              [-40.522952381964416, -7.751338379549094],
              [-40.525435757476096, -7.751428974159714],
              [-40.52756436782704, -7.751113790840571],
              [-40.529353464455617, -7.750917351533369],
              [-40.529990511795724, -7.751141451770526],
              [-40.530177428686486, -7.752063159535079],
              [-40.530413938386587, -7.75280370240319],
              [-40.531413412238933, -7.754120244557297],
              [-40.532466249975947, -7.755138298505387],
              [-40.532767625868324, -7.756285573048983],
              [-40.532893494288253, -7.757090003448405],
              [-40.532908768184988, -7.758238209740002],
              [-40.533225395028204, -7.760470779944497],
              [-40.533587796437445, -7.761680989173489],
              [-40.533599228509544, -7.762657552467233],
              [-40.533328376214186, -7.764032745184121],
              [-40.532256422115054, -7.765890504384631],
              [-40.531924568293697, -7.767211819364348],
              [-40.531882612637084, -7.768306171923144],
              [-40.531596510390379, -7.768596558976338],
              [-40.530227021027798, -7.76970474130686],
              [-40.529776889231115, -7.77080051350464],
              [-40.529910394242577, -7.772011677531723],
              [-40.529860825890417, -7.772816573778285],
              [-40.529597609989523, -7.774825020796831],
              [-40.528914763114003, -7.775587006747014],
              [-40.527034118735379, -7.777384205602894],
              [-40.526397069246769, -7.77733223835591],
              [-40.524779639667557, -7.777238780503601],
              [-40.524146362097873, -7.777249745891659],
              [-40.522651029065884, -7.777842929126518],
              [-40.521331134757233, -7.778489990197731],
              [-40.520060855856499, -7.778268265396454],
              [-40.517974201913084, -7.777778551457667],
              [-40.516646692405814, -7.777566372551284],
              [-40.514628723235134, -7.777934967648351],
              [-40.514064131539385, -7.778687896752825],
              [-40.514083201061084, -7.780233801720778],
              [-40.514262503355781, -7.780748776861742],
              [-40.514273935426019, -7.781725340155839],
              [-40.514579131138333, -7.78304428070049],
              [-40.514758410433039, -7.783559255841709],
              [-40.514876665549046, -7.783622213575604],
              [-40.515574762262595, -7.784071857548341],
              [-40.517443951477375, -7.786000186881653],
              [-40.518904990040866, -7.787369210135594],
              [-40.519198729588439, -7.787820296750446],
              [-40.520079924364971, -7.789020004493723],
              [-40.521998707082716, -7.789971305589861],
              [-40.523387263957432, -7.790418574176063],
              [-40.523734414524654, -7.79041715448518],
              [-40.526328406668995, -7.790037103956392],
              [-40.528281532446634, -7.789496842809457],
              [-40.529773070656269, -7.788731993576384],
              [-40.533065160611564, -7.788692901657472],
              [-40.535025922833285, -7.788731038133347],
              [-40.537913655521436, -7.788810690169679],
              [-40.540927255626997, -7.78969521560765],
              [-40.54346022240108, -7.789089156347663],
              [-40.545127246076888, -7.788676207207585],
              [-40.546618807092813, -7.788136900654367],
              [-40.548056934427841, -7.787661505680992],
              [-40.548747393748314, -7.787478408350135],
              [-40.550708156225674, -7.787227090519064],
              [-40.552390452850545, -7.787898971047885],
              [-40.552737580419148, -7.787897527357162],
              [-40.554465651432452, -7.787755918682969],
              [-40.555796978760526, -7.78814882153938],
              [-40.556784973548666, -7.788479744460705],
              [-40.557071099647125, -7.788361023402957],
              [-40.558627480809101, -7.788282816209323],
              [-40.55914627432503, -7.788109240673949],
              [-40.560119043071282, -7.787463623292152],
              [-40.560519581423435, -7.787281456704425],
              [-40.560809502290525, -7.787397802275465],
              [-40.562083600028025, -7.78778213613267],
              [-40.562598598776042, -7.787373005971189],
              [-40.563796397498173, -7.786093669489441],
              [-40.564719572032757, -7.786090316309203],
              [-40.565410032157608, -7.786133227656712],
              [-40.5659898510972, -7.786130852173142],
              [-40.566451437872253, -7.78612035163458],
              [-40.568927176897247, -7.785695016267068],
              [-40.569846531766586, -7.785338808146387],
              [-40.570418737011579, -7.785047466345565],
              [-40.572379476549187, -7.784732771884626],
              [-40.572612190817111, -7.784849583403416],
              [-40.573016547741183, -7.785128538123147],
              [-40.575572379812321, -7.786303473470194],
              [-40.577430138212982, -7.786974865156229],
              [-40.578250307422167, -7.788174107857638],
              [-40.578658483422323, -7.788335272269602],
              [-40.57965411555189, -7.789362849127073],
              [-40.580466649515863, -7.789757683524157],
              [-40.581443211836131, -7.789573142449744],
              [-40.582591441356954, -7.788926547224629],
              [-40.583457385301749, -7.788914627045331],
              [-40.583571821453624, -7.788968061832277],
              [-40.583861743423675, -7.788967083089462],
              [-40.584552202542469, -7.789018585387348],
              [-40.586230657704547, -7.789112044296979],
              [-40.586570172627148, -7.788767290606196],
              [-40.588000685424767, -7.787830309322606],
              [-40.588115120678523, -7.78776690879233],
              [-40.588405042751489, -7.78764814173784],
              [-40.589381605167425, -7.787354891298494],
              [-40.590133113914391, -7.787342971019886],
              [-40.5903619864619, -7.787170350227155],
              [-40.590701501439959, -7.786762173906845],
              [-40.591041014615058, -7.786127988907339],
              [-40.591269887115168, -7.786009733796855],
              [-40.591323298843783, -7.785891944531521],
              [-40.59138432431412, -7.786009267898851],
              [-40.591788681436043, -7.786062190940206],
              [-40.59340611097997, -7.786209526481747],
              [-40.593696031800675, -7.786380237735605],
              [-40.594394129519586, -7.78682990471345],
              [-40.595210480410827, -7.787279081796776],
              [-40.595672045132659, -7.787331537890585],
              [-40.596480759377265, -7.78743691797632],
              [-40.596999576745652, -7.787435008437443],
              [-40.597449709454523, -7.786447478598072],
              [-40.597274247926919, -7.786168036078713],
              [-40.596919460009723, -7.785481882780362],
              [-40.59758321437333, -7.783173968841893],
              [-40.599017545791135, -7.782643743183677],
              [-40.599364673369067, -7.782633730543089],
              [-40.599536340167781, -7.782632775697469],
              [-40.599650753516627, -7.782460620802595],
              [-40.599700368618421, -7.781945179857553],
              [-40.599639344400195, -7.78153842418152],
              [-40.59870473763511, -7.780746402999716],
              [-40.598231718741836, -7.779834682917698],
              [-40.597869317428213, -7.778516231308665],
              [-40.597632807335728, -7.778227729789752],
              [-40.598361428842637, -7.776379983219562],
              [-40.599223532012822, -7.776142053356808],
              [-40.600081839509528, -7.775559836502818],
              [-40.601024083670325, -7.77698511098961],
              [-40.601088925665181, -7.777617875347989],
              [-40.601497102461856, -7.778014108386109],
              [-40.60178322875857, -7.777669354648651],
              [-40.602061670518637, -7.776854446652023],
              [-40.602332522622866, -7.775705262602368],
              [-40.602424072635479, -7.773805990781112],
              [-40.604331423050063, -7.774070183888911],
              [-40.605559769406341, -7.775268005952947],
              [-40.606318891652577, -7.775771549668014],
              [-40.606616425869596, -7.776575026230705],
              [-40.606921597686203, -7.777776643473722],
              [-40.606872027434633, -7.778463774415797],
              [-40.606070926481721, -7.779108925951929],
              [-40.605727616632116, -7.779399800954804],
              [-40.605163025724856, -7.780387330695264],
              [-40.604995177552745, -7.780785984220465],
              [-40.605006608771689, -7.78159039153025],
              [-40.605018064887652, -7.782512612151815],
              [-40.604857853204791, -7.783435764619235],
              [-40.604468745974451, -7.784531070882337],
              [-40.603862221311289, -7.786721661557908],
              [-40.60353034463246, -7.787871333553277],
              [-40.603419703901878, -7.788450665072777],
              [-40.602626262501126, -7.789547391977102],
              [-40.602172312220574, -7.790073822507731],
              [-40.601035514914976, -7.79152484804421],
              [-40.600425171679703, -7.793254340410807],
              [-40.599524908371144, -7.795102087835037],
              [-40.599315103426562, -7.796721427193256],
              [-40.592704242235804, -7.800715898116732],
              [-40.592204493631364, -7.800835573983425],
              [-40.590907485355928, -7.80124707945265],
              [-40.590075883735167, -7.801756838752141],
              [-40.588992498027487, -7.802095374833163],
              [-40.587722218581114, -7.80248876643124],
              [-40.586383255757667, -7.802720037916701],
              [-40.584880262248802, -7.802761994365651],
              [-40.583632846824457, -7.802947490198074],
              [-40.582595258836413, -7.803240741583199],
              [-40.582339656563114, -7.803313709934815],
              [-40.581923868379427, -7.803423861929947],
              [-40.581416505828969, -7.803543537789865],
              [-40.58030643790638, -7.804343196153162],
              [-40.579753300637101, -7.804508180708561],
              [-40.578967473068275, -7.804592581532537],
              [-40.578044322469047, -7.804668391287451],
              [-40.577605622525198, -7.804687948791923],
              [-40.576499350161065, -7.804818613067169],
              [-40.575320620114731, -7.805166696008991],
              [-40.574393650187204, -7.80564926138381],
              [-40.574279213948913, -7.805695036016882],
              [-40.573150053682241, -7.805898157848735],
              [-40.572268858592608, -7.806199534321927],
              [-40.571555511374534, -7.806446521258769],
              [-40.570399645192367, -7.806767432379019],
              [-40.569545155757922, -7.807249531819283],
              [-40.56841220031302, -7.807687743273885],
              [-40.56677186001825, -7.808589427579101],
              [-40.565524468074962, -7.809371460924893],
              [-40.56429994095577, -7.81017158418472],
              [-40.563166985234687, -7.810926422788731],
              [-40.561389321029274, -7.811683634818185],
              [-40.5606034936241, -7.812726486040709],
              [-40.560096131640357, -7.813343044996486],
              [-40.559584974722355, -7.813914762210973],
              [-40.558429108774796, -7.815112584205987],
              [-40.557437295796831, -7.816174014140588],
              [-40.556418777195432, -7.81701898131687],
              [-40.555404052623416, -7.817800035869833],
              [-40.554686887203907, -7.818309330269694],
              [-40.553901060210158, -7.818881978239578],
              [-40.553000818891476, -7.819599610262565],
              [-40.552005163742869, -7.820887095872429],
              [-40.551032419457457, -7.822147386661129],
              [-40.550109244982778, -7.823226930549088],
              [-40.548953402710531, -7.824795722375523],
              [-40.548098912936865, -7.825666905540748],
              [-40.547610620795652, -7.826310637353899],
              [-40.547496183375614, -7.826564329771786],
              [-40.547404634834784, -7.82677269047455],
              [-40.547217717986221, -7.826953903252873],
              [-40.547057483902684, -7.827189923734186],
              [-40.546824792350691, -7.827398796310955],
              [-40.546527257118811, -7.827761173934313],
              [-40.545832978238487, -7.82861377834749],
              [-40.545600264527977, -7.829003816865661],
              [-40.545531602850943, -7.829184539749845],
              [-40.545390437618209, -7.829438232144454],
              [-40.545253160318531, -7.829737188278015],
              [-40.545043333360695, -7.830226435236418],
              [-40.544791549760461, -7.830643179581963],
              [-40.544513084331356, -7.831078060798206],
              [-40.544028585862129, -7.832065590605795],
              [-40.543586113193555, -7.832944412085211],
              [-40.543261873886735, -7.833605791938196],
              [-40.542941428648533, -7.834158439429765],
              [-40.542640075292447, -7.834692972043893],
              [-40.542498956884934, -7.83514550327677],
              [-40.542430248795561, -7.835796847411213],
              [-40.542128895288407, -7.836503536019463],
              [-40.541964866073215, -7.836919815444253],
              [-40.54185042969273, -7.837128175127073],
              [-40.541758880032617, -7.837472462035694],
              [-40.541644420691952, -7.837635536983139],
              [-40.541713104965027, -7.837915446407639],
              [-40.542079301955091, -7.838420410776711],
              [-40.542449340163714, -7.83864551178429],
              [-40.542819355444486, -7.838788539271349],
              [-40.543166505988424, -7.838814290423681],
              [-40.543559385259044, -7.838786141930784],
              [-40.544391033725475, -7.838448559628393],
              [-40.545012808591167, -7.838192957663924],
              [-40.545684199982446, -7.838081850898565],
              [-40.546237313130852, -7.838052769550818],
              [-40.547068962296407, -7.838058498242685],
              [-40.547946314966886, -7.838263528810119],
              [-40.548709256074609, -7.838884372848749],
              [-40.54954085822439, -7.838908703431974],
              [-40.550715815984809, -7.838913476429007],
              [-40.551455845570359, -7.839172873559336],
              [-40.552516320736409, -7.83963015381334],
              [-40.553416608421458, -7.839635881565338],
              [-40.554248209413068, -7.839840913093274],
              [-40.554942488303638, -7.840118447077986],
              [-40.555701610819298, -7.840305363648611],
              [-40.556605692462135, -7.840329206298166],
              [-40.557387702999932, -7.840308227103058],
              [-40.558013318720477, -7.840188529348065],
              [-40.558913582509582, -7.840076467788075],
              [-40.559260734172931, -7.83996680473148],
              [-40.55979095964117, -7.839757000419474],
              [-40.56039368969676, -7.839438487805588],
              [-40.56120238033369, -7.839091802535884],
              [-40.561755540475225, -7.839071289138346],
              [-40.562377338675127, -7.839575787734509],
              [-40.562537550386736, -7.839764590771696],
              [-40.562701579221773, -7.839782261811648],
              [-40.563392039322139, -7.839851879007592],
              [-40.563971881276395, -7.839831855630473],
              [-40.56447924281769, -7.839721702716513],
              [-40.564895033032514, -7.839575321934146],
              [-40.565310846151824, -7.839537650517021],
              [-40.565448169189942, -7.839537161639706],
              [-40.565635109904633, -7.839509034967073],
              [-40.566005124312667, -7.839508080295512],
              [-40.566420913416685, -7.839488057775272],
              [-40.566882501159597, -7.83951380802772],
              [-40.567481388719116, -7.839791808926907],
              [-40.567965839040333, -7.84011558345904],
              [-40.568335901250116, -7.840340171470588],
              [-40.568610549223521, -7.840456518030869],
              [-40.568797464771507, -7.840618638247363],
              [-40.569003474185678, -7.840780758480529],
              [-40.569327691073802, -7.840598614827078],
              [-40.569487948951313, -7.840598125969808],
              [-40.569880851199514, -7.84059670532099],
              [-40.570090654746181, -7.840577636615492],
              [-40.570460693297335, -7.840413606896186],
              [-40.571059604261563, -7.840230485488166],
              [-40.571429619193808, -7.839632529302601],
              [-40.571639423002473, -7.839314970339978],
              [-40.571936980362828, -7.838807608562147],
              [-40.572101009555425, -7.838418035980061],
              [-40.57212389775993, -7.83800222342804],
              [-40.57226503913671, -7.837585478984678],
              [-40.572356588780991, -7.837259794966313],
              [-40.572772401083022, -7.837014227763013],
              [-40.573119529652139, -7.83701278507426],
              [-40.573462861263771, -7.836993250487891],
              [-40.573764215216762, -7.836919815179635],
              [-40.5740655913282, -7.836665656926643],
              [-40.574527155357465, -7.836365724080634],
              [-40.574691184367616, -7.836184046284595],
              [-40.574805643724609, -7.836002857442463],
              [-40.574942967389582, -7.835288065736058],
              [-40.574912443730156, -7.835143594553047],
              [-40.576156039836825, -7.831928756410313],
              [-40.576384912281846, -7.831873435932932],
              [-40.577655214497106, -7.831742305806328],
              [-40.579089546912932, -7.831212545150427],
              [-40.579776188517314, -7.830739502511325],
              [-40.580516241143869, -7.829814441558283],
              [-40.581420324081421, -7.828364393813768],
              [-40.583018685212984, -7.826848524058675],
              [-40.583938017879049, -7.826727405567118],
              [-40.584987060883918, -7.827465061174041],
              [-40.585509697026396, -7.827689183320921],
              [-40.586551102743165, -7.82767581930672],
              [-40.587642102318291, -7.827427877705371],
              [-40.589538020662587, -7.826715484517405],
              [-40.591330911920117, -7.82692575385248],
              [-40.592955979052135, -7.827479822034144],
              [-40.595031177540015, -7.827454093993404],
              [-40.596187043503889, -7.827386386396388],
              [-40.597690036792194, -7.827597634473447],
              [-40.59954779393896, -7.828558458484379],
              [-40.60156194441111, -7.827899477087287],
              [-40.602477482414685, -7.827425968649551],
              [-40.602824609985021, -7.827424524960613],
              [-40.603866039655529, -7.827466015626459],
              [-40.604037683502028, -7.827410718098887],
              [-40.604499270278801, -7.827400217562623],
              [-40.605662749484843, -7.828137407274276],
              [-40.606181566758444, -7.828243741106065],
              [-40.606746157869402, -7.827029713684321],
              [-40.606845321332607, -7.825709351477587],
              [-40.606818615278833, -7.82370234916934],
              [-40.607039874237628, -7.823122506746859],
              [-40.607905796287746, -7.822992796258665],
              [-40.608077463030916, -7.823055288043479],
              [-40.608901427217702, -7.82424593981178],
              [-40.609599523927798, -7.824704174745623],
              [-40.610522675577833, -7.82457399831014],
              [-40.610862213605834, -7.824111456305829],
              [-40.611152110679612, -7.823993201248728],
              [-40.612067648779366, -7.82341098444496],
              [-40.613398976073945, -7.823848729050516],
              [-40.614051275304014, -7.825220616612329],
              [-40.61469216557299, -7.82549861754865],
              [-40.616027311729901, -7.826063161418081],
              [-40.617072535923796, -7.826619628082096],
              [-40.616797864981699, -7.827606203085248],
              [-40.614608229988065, -7.827976241016112],
              [-40.613940656994231, -7.8298778653365],
              [-40.615039293011968, -7.830208322366533],
              [-40.616832208425244, -7.830246459708171],
              [-40.618041461970854, -7.830232629843898],
              [-40.61925453348951, -7.830219288980119],
              [-40.62121147816022, -7.829795862158153],
              [-40.623408750546879, -7.8300585885423],
              [-40.624331902000108, -7.830154421788696],
              [-40.6255487922393, -7.830430047242393],
              [-40.626532992059033, -7.830760970170592],
              [-40.627875774759325, -7.831786171361482],
              [-40.628699740041696, -7.832868113768916],
              [-40.629744965085322, -7.833596735430982],
              [-40.631614177705394, -7.835072068457083],
              [-40.632071946558028, -7.835007202235188],
              [-40.633338406959354, -7.83470393910998],
              [-40.633395636654257, -7.834595230794073],
              [-40.633624509259448, -7.834359186370741],
              [-40.635180914585113, -7.834108847189913],
              [-40.635753142787863, -7.833871872071787],
              [-40.636149863464929, -7.833354963433129],
              [-40.636149863568328, -7.833237197120021],
              [-40.634398905119767, -7.831761375199783],
              [-40.633758038047958, -7.831257365580412],
              [-40.633914430064834, -7.829990881104714],
              [-40.634936744880775, -7.828883653455744],
              [-40.635093184150982, -7.827327737668721],
              [-40.634841400316233, -7.825728420779056],
              [-40.634417973388643, -7.82446433578439],
              [-40.634234875422926, -7.823606026632183],
              [-40.634231057751073, -7.82326222863465],
              [-40.634852856312804, -7.822219844258353],
              [-40.636062108963841, -7.822088737077562],
              [-40.637053922327588, -7.822880758321628],
              [-40.638328043168727, -7.823155894827543],
              [-40.639480091117342, -7.823142529910101],
              [-40.640300237785624, -7.823817251689296],
              [-40.641398873902176, -7.824038976354063],
              [-40.642360186493676, -7.82253456196823],
              [-40.641700249869814, -7.820529933087045],
              [-40.640414697861203, -7.819395557584283],
              [-40.639132940628052, -7.818487166766854],
              [-40.637389641663198, -7.817535400800698],
              [-40.635089365137198, -7.818249703628539],
              [-40.633304087460431, -7.818446631923724],
              [-40.631095383911408, -7.81731556159921],
              [-40.629344425357722, -7.815957063001448],
              [-40.629516091402095, -7.815676198789999],
              [-40.632850137805043, -7.814831255624807],
              [-40.63434167513828, -7.813948616074515],
              [-40.634398904840843, -7.81383082681166],
              [-40.634395087065506, -7.813604818127247],
              [-40.634391291242352, -7.813433152125679],
              [-40.634730782421961, -7.812798477124955],
              [-40.636031609094935, -7.810768145105818],
              [-40.63664574731051, -7.809038629733418],
              [-40.636798344651247, -7.807428812261003],
              [-40.637942755310178, -7.806664917714986],
              [-40.639377086640593, -7.806243400423019],
              [-40.640761802009763, -7.806174715028773],
              [-40.641459921833686, -7.806506615699445],
              [-40.64222286359518, -7.807534192366997],
              [-40.642119858563554, -7.808393944260393],
              [-40.640754188795064, -7.809908859231599],
              [-40.640655049435601, -7.811111920125202],
              [-40.640902967442592, -7.812548626957916],
              [-40.641723138008594, -7.813341137048109],
              [-40.643165082930338, -7.813326352390288],
              [-40.644423952837158, -7.812508091260887],
              [-40.644595596636833, -7.81250713641593],
              [-40.645179280308675, -7.812785136302348],
              [-40.645709505696914, -7.813814156760559],
              [-40.645827760719435, -7.813985333864912],
              [-40.645202144588332, -7.814566596928702],
              [-40.64480542275345, -7.815264204513147],
              [-40.644481159659051, -7.816820610150539],
              [-40.645785758076968, -7.819618702964382],
              [-40.646888234969275, -7.820066484313402],
              [-40.647578695401279, -7.819765573670641],
              [-40.647979257750379, -7.819591997032364],
              [-40.649192330273721, -7.819578167170588],
              [-40.650458790526919, -7.819446571042291],
              [-40.651084407705959, -7.818811385292664],
              [-40.649772149752771, -7.815543159213333],
              [-40.649470795913857, -7.814342007957804],
              [-40.649577595768825, -7.813654411062133],
              [-40.650325285594967, -7.813588124100155],
              [-40.651477333600837, -7.81351135955158],
              [-40.653090945474212, -7.813323954050549],
              [-40.653190130992279, -7.811940169209291],
              [-40.652945983705308, -7.81079291768937],
              [-40.652118199745424, -7.809367643283462],
              [-40.65240048527788, -7.808787800912096],
              [-40.653609737828724, -7.80877397004566],
              [-40.654265879981452, -7.810208792246466],
              [-40.65486097146546, -7.811752787757705],
              [-40.654921996943699, -7.811861030179434],
              [-40.655807010296961, -7.813521371265192],
              [-40.656276211619691, -7.813971504048336],
              [-40.657031537838087, -7.814538470399431],
              [-40.657329073155523, -7.815224157659244],
              [-40.657981395539615, -7.816424378227299],
              [-40.658389571384092, -7.81676626758903],
              [-40.65919828568115, -7.816817303996279],
              [-40.660293103239063, -7.816568850399619],
              [-40.661147593873849, -7.815869333224153],
              [-40.662356846679501, -7.815566046046953],
              [-40.662875664051434, -7.815564137510289],
              [-40.663630967476827, -7.815895549235784],
              [-40.664157398391488, -7.816354739016704],
              [-40.665091982158906, -7.817146783212436],
              [-40.665671823858737, -7.817424760096944],
              [-40.666949762375971, -7.818043694601851],
              [-40.668071285932527, -7.819865713955489],
              [-40.66855191931095, -7.8213014660031],
              [-40.669372089727673, -7.822265619097],
              [-40.671046748875575, -7.822422035646078],
              [-40.672076723340858, -7.821658139998412],
              [-40.67274809101108, -7.820100314666655],
              [-40.672896870683637, -7.818146699186988],
              [-40.672881619797131, -7.816998469868661],
              [-40.672866369013633, -7.815732474237191],
              [-40.672507787225769, -7.814929487606832],
              [-40.672488694563789, -7.813555201603877],
              [-40.673400414053376, -7.812565787167973],
              [-40.673564443460876, -7.81193255600347],
              [-40.674716490668338, -7.811629270775854],
              [-40.674830926971062, -7.811511504562286],
              [-40.674827109100988, -7.81139371524583],
              [-40.674766083773022, -7.81111382982478],
              [-40.674762265005718, -7.810878740192305],
              [-40.674747015316164, -7.809504501192877],
              [-40.675487044649543, -7.808922750229962],
              [-40.677306664907931, -7.806655395037029],
              [-40.677703408538385, -7.806192387082586],
              [-40.678844000475166, -7.805148093165712],
              [-40.679191129000976, -7.805201039159176],
              [-40.680076142563223, -7.806626290618565],
              [-40.679519186999919, -7.808291894420931],
              [-40.678905026026115, -7.809741011435917],
              [-40.6786685162567, -7.813648218447693],
              [-40.679545893267516, -7.814620963540966],
              [-40.680587322693896, -7.814951419522175],
              [-40.681178596807328, -7.815808285042082],
              [-40.68176605190007, -7.816719049244673],
              [-40.68252137937403, -7.816996560285491],
              [-40.682998217089398, -7.818079481388378],
              [-40.683070697575339, -7.819227686758191],
              [-40.683822182076575, -7.819504732798752],
              [-40.684966592838272, -7.818632128883666],
              [-40.685363312517161, -7.81811522024287],
              [-40.685584572731734, -7.817246434500294],
              [-40.686496291969476, -7.816546429375968],
              [-40.68765213479508, -7.816650387777923],
              [-40.687812368483584, -7.815727702296233],
              [-40.687922987067473, -7.815320014769251],
              [-40.688499010144177, -7.815200315969256],
              [-40.688911004915582, -7.815596571018625],
              [-40.689258132386477, -7.815712451645846],
              [-40.689544258394307, -7.81570243995375],
              [-40.689776926815746, -7.815647118479084],
              [-40.689887568295809, -7.81535768726271],
              [-40.68976931237615, -7.815068719844026],
              [-40.689761675881485, -7.814553254842098],
              [-40.689982911842293, -7.813973925412027],
              [-40.690619983445011, -7.813908080351302],
              [-40.691138777715928, -7.814023495130829],
              [-40.691772031401001, -7.813894227436506],
              [-40.691817806040206, -7.812799921855261],
              [-40.691981835289859, -7.812347391636172],
              [-40.692496811102956, -7.811874860839533],
              [-40.693664108191491, -7.81272935087615],
              [-40.693908255430863, -7.813930968084897],
              [-40.69488863679284, -7.813683049384723],
              [-40.695178557513117, -7.813971038958895],
              [-40.695891904219302, -7.81546020190128],
              [-40.695781263540901, -7.815975666800456],
              [-40.694816155223364, -7.817191113874644],
              [-40.694720788628651, -7.818629264409552],
              [-40.695151851751355, -7.82075261441087],
              [-40.695624870842394, -7.821437835830375],
              [-40.696330581958101, -7.822519778141289],
              [-40.696918060995046, -7.823493942977332],
              [-40.696990540686485, -7.824407106719423],
              [-40.696544203742356, -7.825620179260967],
              [-40.695457023625863, -7.826438439543521],
              [-40.694079921789886, -7.826968689251046],
              [-40.692836323703204, -7.828998580336481],
              [-40.693194907394272, -7.829910765334521],
              [-40.694579645668298, -7.829959403260966],
              [-40.695224331662011, -7.830526370520716],
              [-40.695529503218118, -7.832016977102502],
              [-40.69692187820057, -7.832336910455405],
              [-40.698241750333942, -7.831924451011947],
              [-40.699264087261561, -7.830699945039414],
              [-40.699657013373525, -7.829721939080766],
              [-40.700461909907318, -7.829537885856332],
              [-40.700980703281971, -7.829535488323606],
              [-40.702354010558416, -7.828535572349015],
              [-40.704890773045761, -7.828280925048896],
              [-40.705405771701123, -7.827989583196118],
              [-40.705680419647635, -7.827003009183612],
              [-40.706077163478639, -7.826314457541575],
              [-40.706531113719521, -7.825842392688316],
              [-40.70750767620541, -7.825485695619351],
              [-40.70831257288264, -7.825139056341792],
              [-40.708884778297289, -7.824666502594518],
              [-40.709693493646476, -7.824663173320237],
              [-40.711013388892447, -7.824124332612013],
              [-40.711860241086953, -7.822855007746201],
              [-40.711616094650338, -7.821879399216727],
              [-40.711616094753339, -7.821762076900191],
              [-40.711188850246529, -7.820045948525366],
              [-40.710364861059439, -7.818846705793316],
              [-40.710189399483973, -7.818621162954297],
              [-40.709830816846448, -7.817646019323071],
              [-40.709991027535978, -7.81672333383899],
              [-40.711028639396886, -7.81659269250552],
              [-40.712302736352136, -7.816750039700362],
              [-40.71334034715769, -7.816682820997348],
              [-40.713485307905906, -7.814674839823215],
              [-40.712363785351727, -7.812843786514112],
              [-40.711536001490927, -7.811301212781026],
              [-40.710708193678151, -7.809703804368002],
              [-40.710109282320474, -7.80804297452473],
              [-40.710090213761205, -7.8065509472035],
              [-40.711070594966628, -7.806483729449557],
              [-40.712340897003955, -7.806578142006209],
              [-40.712516358722631, -7.806640610795054],
              [-40.712630818978525, -7.806576698267303],
              [-40.713889665745768, -7.805929637135105],
              [-40.71515996787835, -7.805915808322591],
              [-40.717181755563537, -7.806124190870032],
              [-40.718108725047884, -7.80616568243604],
              [-40.719848228253817, -7.806890950725901],
              [-40.721870015145676, -7.80686471064094],
              [-40.722961014833409, -7.806507571672708],
              [-40.723716341152695, -7.806965805661185],
              [-40.723956669721183, -7.807823647872534],
              [-40.724155017726396, -7.809658496355365],
              [-40.724166474043827, -7.810345627362095],
              [-40.724246567875021, -7.812180474742441],
              [-40.724776816361789, -7.813100809843439],
              [-40.726337039375842, -7.813193778662959],
              [-40.72650488756036, -7.812786092184258],
              [-40.727122867401164, -7.81146382142612],
              [-40.727923945170218, -7.811044678566478],
              [-40.7284084203745, -7.812652097625349],
              [-40.729243840833881, -7.814592348052257],
              [-40.729823682482042, -7.814933771569491],
              [-40.730743016219193, -7.814749229446639],
              [-40.73107873478677, -7.813707823805436],
              [-40.731475432420311, -7.813245280845751],
              [-40.73158607384579, -7.813018783261572],
              [-40.731875970876779, -7.812953939892178],
              [-40.731933200580194, -7.812836174628046],
              [-40.733607860692842, -7.813046442864338],
              [-40.735511393611844, -7.812794683991474],
              [-40.736606211020863, -7.81272695234119],
              [-40.737868876511939, -7.812368858528442],
              [-40.738334282063214, -7.812592981629181],
              [-40.738990399274869, -7.813964845212015],
              [-40.738311418858522, -7.815287581916093],
              [-40.737170778904463, -7.816340908786204],
              [-40.736438363059342, -7.817438125757017],
              [-40.73577460856783, -7.819863805051361],
              [-40.734698859810528, -7.821315296659288],
              [-40.734016036976215, -7.822005268044927],
              [-40.733966443607812, -7.822819245261589],
              [-40.733928282563689, -7.82431127353994],
              [-40.733020381991643, -7.82535458867136],
              [-40.731998045012354, -7.826633459331133],
              [-40.731032937640997, -7.827903297093443],
              [-40.729945733615082, -7.828613291008855],
              [-40.728400783644133, -7.829487339568743],
              [-40.72795828836793, -7.83104421011635],
              [-40.72883566543593, -7.831954019585986],
              [-40.730624760050034, -7.831820025930678],
              [-40.731803513209975, -7.833641533356331],
              [-40.732963175656344, -7.834152248397439],
              [-40.734511943355528, -7.833558109211203],
              [-40.734725589127933, -7.832119492773527],
              [-40.735751721779458, -7.831184398115043],
              [-40.737514111402213, -7.828979977490232],
              [-40.737777327129827, -7.827197563097839],
              [-40.73765907205928, -7.827080261677398],
              [-40.737765872020411, -7.826275365457138],
              [-40.738914101310783, -7.825918202540189],
              [-40.738940806245594, -7.823503977620268],
              [-40.739032355274361, -7.821604728759028],
              [-40.739764795278838, -7.820326812839907],
              [-40.740626898464264, -7.820088393981127],
              [-40.741668328048405, -7.820246694968938],
              [-40.742145165606651, -7.821510316027869],
              [-40.742633435432992, -7.823515898777273],
              [-40.74302254204602, -7.826551921441672],
              [-40.743613815859597, -7.827752118971421],
              [-40.744754408808127, -7.826707850047844],
              [-40.745246519247388, -7.824571624914348],
              [-40.745856862653518, -7.822670466515965],
              [-40.746520617789585, -7.820651542850083],
              [-40.74690590641741, -7.819149013934639],
              [-40.748107546891674, -7.818158620750426],
              [-40.748912443326901, -7.818091868841973],
              [-40.74960672243116, -7.818143370148407],
              [-40.750369662301935, -7.819053180517793],
              [-40.75086175084855, -7.821347707589503],
              [-40.750476461124634, -7.822958944873095],
              [-40.75042307324324, -7.82324839914144],
              [-40.750613807902461, -7.824395674629496],
              [-40.750907525350875, -7.824964061577573],
              [-40.751266107036273, -7.82588483853115],
              [-40.751632327119459, -7.827429788858473],
              [-40.751937498979039, -7.828576550450501],
              [-40.753337511304963, -7.82959225276317],
              [-40.754554379410393, -7.830039521224363],
              [-40.756705876243011, -7.831332222583186],
              [-40.758506427979313, -7.832111367624345],
              [-40.759719500414263, -7.83221486108353],
              [-40.760989779449787, -7.832318331592974],
              [-40.761165263226921, -7.832314978766035],
              [-40.761626851010107, -7.832306410219825],
              [-40.762141826621431, -7.832069412049515],
              [-40.763003952857403, -7.831776161510304],
              [-40.763637183547957, -7.831646939816335],
              [-40.764621382581446, -7.831751364069962],
              [-40.765658993241978, -7.8318557893703],
              [-40.766467708604182, -7.8318434031501],
              [-40.767844810452281, -7.831313130440532],
              [-40.768939628024548, -7.831064233846689],
              [-40.769858960813792, -7.830825349041074],
              [-40.770934732672679, -7.829265614057279],
              [-40.771220812834919, -7.829083447367482],
              [-40.771503096317744, -7.828567051618999],
              [-40.771503096524555, -7.828331961986795],
              [-40.770686768668007, -7.827829350197158],
              [-40.768733642681738, -7.828542720337826],
              [-40.766833905577577, -7.828966636209333],
              [-40.764762524672363, -7.829391017927892],
              [-40.763324352442325, -7.829704291846797],
              [-40.761996865654154, -7.829773442283151],
              [-40.760715132247007, -7.829046264393661],
              [-40.759418123218595, -7.826872832908019],
              [-40.759158727048785, -7.824577329041199],
              [-40.759151090602991, -7.824007522350945],
              [-40.758662820680506, -7.822111136962002],
              [-40.758071545658872, -7.821146006063065],
              [-40.757991429838512, -7.819302076726404],
              [-40.758380535979867, -7.818323604762019],
              [-40.758132570973373, -7.816887363906136],
              [-40.758113502265509, -7.815567491573038],
              [-40.759029040381776, -7.814984785764632],
              [-40.760718949535793, -7.816171152334037],
              [-40.762351631089153, -7.817358007851288],
              [-40.762935291514367, -7.817924975060892],
              [-40.763034476742405, -7.818015104622961],
              [-40.763461721537425, -7.818266398533488],
              [-40.764964715854262, -7.818477156619724],
              [-40.766002279571275, -7.818518135287648],
              [-40.767322199024584, -7.817752842894845],
              [-40.767547252858556, -7.817290790780722],
              [-40.769153251163722, -7.816696628641634],
              [-40.770232818795236, -7.815362926346917],
              [-40.770450236031749, -7.814503174540734],
              [-40.770789750122397, -7.813977232898347],
              [-40.770904186474354, -7.813805101000095],
              [-40.770953779786929, -7.813054546411911],
              [-40.771293292774558, -7.812645905086785],
              [-40.770885116766323, -7.812485204665758],
              [-40.77065242574804, -7.812078938827294],
              [-40.770759248351489, -7.811680750238248],
              [-40.770877503581673, -7.811616884713207],
              [-40.770866048264807, -7.810929753702794],
              [-40.770621901873348, -7.809900268482104],
              [-40.770900391484943, -7.809266083416619],
              [-40.772224080713428, -7.808727242708254],
              [-40.77390637832054, -7.809461568902097],
              [-40.774947783908978, -7.809619892889454],
              [-40.776912364958733, -7.809883109072159],
              [-40.778594638781023, -7.810373288687748],
              [-40.779121045551811, -7.811003677478525],
              [-40.779250755789171, -7.8119797519113],
              [-40.779262210899461, -7.812901972554377],
              [-40.779281279511814, -7.814330577257365],
              [-40.779178275365489, -7.815316196431827],
              [-40.779483493179086, -7.816517348708821],
              [-40.780131950802989, -7.817482456659485],
              [-40.781398435123073, -7.81729602885085],
              [-40.782062213261696, -7.815277081178592],
              [-40.781169562254526, -7.813273431058311],
              [-40.781848567523966, -7.812122337349766],
              [-40.784156480385413, -7.812149044209573],
              [-40.785777751682517, -7.812584857089381],
              [-40.786243111434949, -7.812582947506844],
              [-40.788020775648732, -7.811870555204464],
              [-40.788192418498852, -7.811815256676118],
              [-40.789405514096991, -7.811738004184919],
              [-40.790496512841287, -7.811326474532128],
              [-40.791518849779209, -7.810101504552218],
              [-40.791618037039463, -7.809016208953699],
              [-40.790397327255896, -7.80822560848845],
              [-40.78866164270881, -7.807844138193224],
              [-40.788642573904788, -7.806632974224558],
              [-40.788627300217158, -7.805258736208474],
              [-40.788783716037848, -7.804218260399995],
              [-40.788776078646201, -7.803585496075772],
              [-40.787673623886583, -7.802966095718846],
              [-40.786353752533927, -7.803613644798906],
              [-40.784755391363376, -7.805130469576787],
              [-40.783038798525929, -7.806068871617679],
              [-40.780509626550227, -7.806793674192461],
              [-40.778892172878052, -7.806755513003312],
              [-40.777560845463384, -7.806426499756752],
              [-40.77564967609591, -7.806099860990611],
              [-40.773795737527379, -7.805547190590418],
              [-40.771834974245181, -7.805519088043671],
              [-40.769412647883733, -7.805836646082092],
              [-40.767348904472762, -7.806776514810405],
              [-40.76557123996205, -7.807832706110845],
              [-40.763736370199645, -7.808491686673576],
              [-40.760623582708526, -7.808757767408732],
              [-40.758426311183477, -7.808613272333102],
              [-40.756869906042063, -7.808628545886409],
              [-40.755599602903224, -7.808642841695027],
              [-40.75421106968497, -7.808549382027937],
              [-40.753284100403121, -7.808272826829691],
              [-40.752341857290645, -7.806784617673962],
              [-40.752502066972546, -7.805870988134027],
              [-40.753821963374953, -7.805160504422347],
              [-40.755080810346968, -7.804286922607022],
              [-40.756156558056603, -7.80289030867785],
              [-40.756087896439411, -7.801859822618933],
              [-40.755096083863215, -7.801294299047886],
              [-40.753802893492427, -7.79947325852652],
              [-40.754317869150249, -7.799181915672015],
              [-40.7565685526029, -7.798982589793859],
              [-40.758525497162204, -7.798721283020721],
              [-40.759776706789246, -7.797161060197006],
              [-40.75884210101114, -7.796369038995459],
              [-40.757049208670374, -7.796214066336429],
              [-40.755546192457032, -7.795885517940893],
              [-40.753566384372007, -7.794646229435051],
              [-40.752269376224362, -7.792599155229877],
              [-40.751666647094389, -7.790702769752309],
              [-40.751418706075555, -7.789275120853369],
              [-40.751395817643186, -7.787665792209043],
              [-40.751731513058438, -7.786796518565345],
              [-40.753055204124891, -7.786438423802982],
              [-40.754382712714062, -7.78659577204107],
              [-40.756076417600205, -7.788062513970033],
              [-40.757205601660083, -7.790408565286708],
              [-40.758430129161546, -7.791488598061658],
              [-40.75981866323076, -7.791753723726156],
              [-40.760390868639675, -7.791290248923764],
              [-40.760547284655146, -7.790024230433809],
              [-40.761115671193814, -7.789442968314103],
              [-40.762378336893264, -7.788850250865257],
              [-40.762477522308551, -7.787583789328139],
              [-40.761474253759474, -7.785933437068289],
              [-40.760196339123453, -7.785432291875843],
              [-40.759719500606543, -7.784114305138788],
              [-40.759467717715907, -7.782569377919828],
              [-40.761485708794503, -7.782370982830256],
              [-40.762412680078349, -7.782647563025161],
              [-40.763621932747675, -7.782515943843375],
              [-40.764537470862763, -7.781933261033743],
              [-40.765868798379643, -7.782144973958152],
              [-40.766276974135458, -7.782595106691112],
              [-40.76662792038514, -7.782937460002144],
              [-40.766929273475348, -7.783858237900998],
              [-40.767737988790707, -7.783900216359862],
              [-40.76894344688256, -7.783316555807909],
              [-40.769576677518309, -7.783250269744766],
              [-40.769988672191545, -7.783764313109113],
              [-40.770473146109978, -7.7857060082218],
              [-40.770949960927567, -7.786680637960551],
              [-40.77106442008148, -7.786734049749089],
              [-40.771068238951784, -7.786851839066419],
              [-40.770949960824943, -7.786797939277387],
              [-40.770900391672562, -7.787367768915734],
              [-40.771049147521431, -7.789781527988436],
              [-40.771236087109088, -7.791046102787189],
              [-40.771705287588667, -7.791324568574135],
              [-40.772899291267692, -7.79016250938052],
              [-40.773334172996869, -7.788144540510249],
              [-40.774192480615739, -7.78744453333368],
              [-40.774596838595947, -7.787668657380438],
              [-40.775901460187477, -7.790294595225141],
              [-40.776610989230164, -7.791439960173776],
              [-40.778110163830569, -7.791361285937335],
              [-40.779189731357242, -7.790144883959806],
              [-40.779105818802542, -7.78802055725589],
              [-40.778625163414745, -7.786585270195014],
              [-40.778319967494113, -7.785501419240748],
              [-40.778880740695961, -7.784223969167525],
              [-40.779498744592715, -7.782838298774405],
              [-40.779533063807889, -7.781174604492741],
              [-40.779636044895554, -7.780251917955677],
              [-40.780265479906262, -7.779787956204989],
              [-40.781371729640597, -7.780407379559722],
              [-40.781798974109329, -7.782177872621109],
              [-40.782172830492073, -7.784472422585131],
              [-40.78317228046545, -7.785670710477461],
              [-40.783977177054211, -7.785432291566179],
              [-40.784137388742884, -7.784509605078004],
              [-40.784587543360786, -7.783639842533995],
              [-40.7856823608792, -7.783454369566518],
              [-40.786372821219615, -7.783270293239025],
              [-40.786422414371799, -7.782700485599936],
              [-40.785770091975365, -7.781500754018176],
              [-40.785182635722727, -7.780762143808096],
              [-40.785010969975865, -7.78069967602259],
              [-40.784774459823929, -7.780474598127525],
              [-40.784133592691539, -7.780024954188537],
              [-40.783839853089276, -7.779628210243406],
              [-40.783664369519173, -7.779394076454281],
              [-40.784629499796175, -7.778232505057751],
              [-40.784976651476214, -7.778113785050896],
              [-40.785380985551946, -7.778229641727803],
              [-40.786555920104654, -7.779653007460937],
              [-40.78696027723533, -7.779705929504426],
              [-40.787688898416178, -7.778255881581659],
              [-40.788310673088965, -7.777096220875062],
              [-40.78848233989136, -7.777095242030014],
              [-40.789245280769336, -7.778005541392657],
              [-40.789333033141418, -7.780310568047343],
              [-40.789695411616599, -7.781448296738095],
              [-40.790904689384547, -7.781207969189304],
              [-40.79185454667828, -7.779006900830982],
              [-40.793140099427539, -7.775891226090205],
              [-40.793929745127016, -7.774504600850635],
              [-40.794196778669914, -7.772894318459286],
              [-40.794002225583398, -7.77111379065854],
              [-40.794677411883448, -7.769953641001076],
              [-40.79531068867027, -7.769716175933996],
              [-40.796348253439703, -7.769702368915716],
              [-40.796641993137357, -7.769990847491027],
              [-40.797416388989625, -7.77198548654917],
              [-40.79767958004431, -7.774398290729046],
              [-40.798167873930538, -7.776349484793126],
              [-40.799293215332902, -7.778342658167557],
              [-40.799590749601037, -7.779091768065217],
              [-40.800079043439091, -7.781096885817246],
              [-40.800559699685799, -7.782695246931121],
              [-40.80173461034213, -7.784001289351274],
              [-40.802833246377503, -7.784339848336871],
              [-40.803168941842635, -7.783416208005234],
              [-40.802959138774128, -7.780596206237559],
              [-40.80277222182908, -7.77973836306855],
              [-40.802600555073987, -7.779684951230137],
              [-40.802421275578055, -7.779395985756307],
              [-40.802005462174186, -7.778602544013537],
              [-40.802058897260274, -7.778077533116336],
              [-40.802909545235202, -7.777034216929755],
              [-40.803302470300167, -7.776110576648475],
              [-40.803577118135117, -7.775250358895187],
              [-40.802810359637476, -7.773933839893101],
              [-40.802680672593127, -7.772731709779204],
              [-40.802612009822766, -7.771872889719758],
              [-40.803241422590034, -7.771689349335343],
              [-40.804458313808873, -7.772018828474639],
              [-40.805274664719114, -7.772467539571741],
              [-40.806716609668683, -7.77245231291367],
              [-40.807693173062248, -7.772212915156157],
              [-40.808166216067086, -7.773006845945412],
              [-40.808246309102003, -7.774615685649453],
              [-40.808215808504876, -7.776686134570569],
              [-40.808173829595795, -7.778060838532074],
              [-40.808421793861868, -7.779208113069796],
              [-40.809425063361338, -7.780921400970588],
              [-40.809528066342097, -7.781264733061916],
              [-40.809371651480895, -7.781210832239518],
              [-40.809432699857027, -7.781436864977056],
              [-40.809734052798056, -7.782529308881179],
              [-40.810035405896691, -7.783441492834542],
              [-40.810512243565526, -7.784587814578269],
              [-40.810813619759365, -7.785390802167547],
              [-40.810825051777059, -7.78642173117803],
              [-40.810958580634406, -7.787804560247163],
              [-40.811664290865004, -7.788768690251442],
              [-40.813117691133463, -7.78944059360775],
              [-40.813636485467384, -7.789492561757355],
              [-40.813632667699963, -7.789257473121958],
              [-40.813724238771371, -7.787304346566683],
              [-40.813239764551767, -7.785706008445741],
              [-40.812869726691915, -7.783926434479593],
              [-40.812854475953444, -7.782606538147589],
              [-40.81360216584909, -7.782476851553096],
              [-40.814582548011209, -7.782472544431516],
              [-40.816741658361515, -7.7842807337887],
              [-40.817321500072296, -7.784558244678736],
              [-40.81779070130095, -7.785126165782605],
              [-40.817928025029268, -7.786626296173325],
              [-40.817779245507005, -7.788408221675573],
              [-40.818717671011605, -7.789489233203542],
              [-40.819358538043282, -7.790055710466085],
              [-40.819892605415461, -7.791084240947543],
              [-40.820895874920815, -7.792788936904633],
              [-40.822131834645027, -7.794672936022363],
              [-40.822791770536377, -7.796388107620754],
              [-40.823402135964336, -7.799025501812443],
              [-40.824241352200083, -7.801200841892435],
              [-40.824771577701128, -7.802111606058196],
              [-40.825118775279797, -7.802110162369649],
              [-40.825561247511239, -7.800607168496986],
              [-40.824222283320999, -7.795514059952377],
              [-40.823329656155117, -7.793682542827864],
              [-40.823081691192165, -7.79219144528574],
              [-40.822772700606777, -7.790755228371571],
              [-40.822292045162563, -7.789383363933777],
              [-40.822398868067147, -7.788641401343439],
              [-40.823146534963755, -7.788511714749301],
              [-40.823955250181378, -7.788670971527377],
              [-40.825408650396596, -7.789405785518885],
              [-40.825813008529316, -7.789458219566252],
              [-40.827499123129371, -7.790183487816488],
              [-40.828437524531978, -7.791381799663858],
              [-40.828902929937989, -7.791777564767438],
              [-40.829368312550137, -7.791938265238334],
              [-40.829765056131912, -7.791539145905732],
              [-40.83010454727188, -7.790958838577089],
              [-40.831081132723753, -7.790656018189715],
              [-40.831943235918033, -7.790417622327745],
              [-40.832622265046851, -7.789429138669151],
              [-40.832793931048094, -7.78920217513889],
              [-40.832793931150867, -7.789084875821176],
              [-40.832786294560513, -7.788678119180506],
              [-40.83339284214513, -7.786604828768923],
              [-40.833316542880624, -7.7852310556937],
              [-40.832965573834358, -7.784654077744326],
              [-40.83342334284076, -7.784425670471716],
              [-40.835334512127062, -7.7848605526066],
              [-40.837009172213079, -7.78512472252866],
              [-40.83734868624974, -7.784662203514713],
              [-40.837341049857542, -7.784028950191591],
              [-40.835822804788577, -7.782498784770302],
              [-40.835349762688445, -7.781813563344524],
              [-40.835796099589409, -7.780654367476578],
              [-40.837459303705167, -7.77983373169837],
              [-40.838145945329622, -7.779360223050637],
              [-40.839004253654025, -7.779004037869765],
              [-40.840217326299339, -7.778881475638649],
              [-40.841823324720181, -7.778169083180557],
              [-40.841987354027459, -7.777653152324507],
              [-40.841048928724739, -7.776337075163796],
              [-40.838851680177747, -7.776193069089663],
              [-40.836417897881439, -7.775362909801888],
              [-40.835952492324076, -7.77513878770095],
              [-40.835311625243072, -7.774626186125861],
              [-40.835296374353575, -7.773477956793348],
              [-40.836677295033887, -7.773119374080744],
              [-40.836784094692469, -7.772657809859154],
              [-40.836772661673137, -7.771627345844869],
              [-40.83775304390128, -7.771550582144008],
              [-40.838680013193695, -7.771826673343757],
              [-40.840186825245596, -7.772326840744111],
              [-40.840476724122126, -7.772442743321912],
              [-40.841102339321459, -7.771798546613913],
              [-40.841556290560213, -7.771335049705329],
              [-40.841247300065675, -7.769790565422622],
              [-40.840884898848309, -7.768354813461175],
              [-40.84070561944597, -7.767957603616495],
              [-40.840289806095527, -7.767100250243696],
              [-40.839824424537142, -7.766876616140499],
              [-40.838496891784374, -7.766891400900678],
              [-40.837756862390769, -7.767527494556189],
              [-40.837249500067074, -7.768515024368882],
              [-40.836448399296941, -7.768925132286184],
              [-40.835807531962615, -7.768701941027875],
              [-40.834655507034022, -7.768661894259512],
              [-40.83378954006384, -7.768674769431918],
              [-40.833152514552808, -7.768623268177894],
              [-40.832454416767874, -7.768228456868719],
              [-40.832786294409971, -7.767132683533911],
              [-40.834285469170268, -7.766882343293533],
              [-40.834914904034306, -7.766590513538747],
              [-40.834373200311823, -7.764874852052304],
              [-40.83396504751309, -7.764469539056535],
              [-40.833099102487303, -7.764545837860216],
              [-40.832130176540446, -7.765354530312047],
              [-40.831382486595793, -7.765538605591137],
              [-40.831126908933271, -7.763758566728921],
              [-40.831344326167226, -7.762898837921501],
              [-40.830692026970453, -7.761464040706047],
              [-40.830386855297697, -7.76009122243163],
              [-40.830947605295435, -7.759048837985849],
              [-40.833984116882363, -7.761603738751698],
              [-40.836662020530568, -7.763517772938262],
              [-40.837470759947344, -7.763451020029271],
              [-40.838435866229545, -7.762289426627453],
              [-40.838592259188999, -7.76108636576457],
              [-40.837661494325076, -7.760349176246534],
              [-40.837062583700209, -7.758977312710703],
              [-40.83734489128441, -7.75834312664684],
              [-40.837684381374885, -7.75781765099985],
              [-40.838023872363372, -7.757409008672912],
              [-40.838081103059011, -7.757300277355424],
              [-40.838073466467165, -7.756893543716063],
              [-40.838763925803228, -7.756719014331177],
              [-40.839160692597297, -7.756075283422134],
              [-40.839149214426534, -7.755216509410475],
              [-40.838504528520751, -7.75453175283666],
              [-40.836929054592488, -7.753291020691591],
              [-40.837203725631639, -7.752195736305366],
              [-40.83852359783954, -7.751720319217607],
              [-40.839145396466463, -7.750614535193566],
              [-40.839130145668577, -7.749357596496977],
              [-40.839183557445665, -7.749185930542557],
              [-40.839050028534878, -7.747857467160068],
              [-40.838977547985763, -7.746772660412258],
              [-40.837741588295629, -7.744834318625375],
              [-40.837325798997938, -7.743913541625712],
              [-40.837432598804483, -7.743280288405067],
              [-40.837829319440054, -7.742817793441477],
              [-40.838809700708069, -7.742695697001754],
              [-40.840083844337485, -7.743250695874524],
              [-40.840858193442777, -7.744965448562919],
              [-40.84103747379141, -7.7454255940396],
              [-40.842036946579178, -7.746849913614652],
              [-40.84239171150724, -7.747535576930974],
              [-40.843154651495624, -7.748327621982062],
              [-40.843806974703035, -7.749753363249022],
              [-40.844703419404233, -7.752100369366284],
              [-40.845241305653332, -7.753246200164174],
              [-40.846915941743625, -7.753509905082546],
              [-40.847308867808835, -7.752586264798816],
              [-40.847293617916591, -7.751438034468102],
              [-40.847629289333526, -7.750568760818385],
              [-40.848361729331621, -7.74929990083845],
              [-40.847942098257967, -7.748153092149191],
              [-40.846671819151695, -7.748113534277953],
              [-40.846084339798367, -7.747483144437687],
              [-40.845325217885737, -7.746573334075238],
              [-40.84571432512675, -7.745477560790875],
              [-40.845584614826166, -7.744565375988198],
              [-40.845119232471987, -7.744106187257819],
              [-40.845103959523456, -7.743021380559456],
              [-40.845969926495137, -7.743008505384766],
              [-40.848411322240885, -7.744417551298608],
              [-40.850341560148209, -7.746289629075511],
              [-40.850455996303587, -7.746343016863968],
              [-40.851218937189586, -7.747253316227417],
              [-40.851859804376225, -7.747648128485341],
              [-40.852206931909315, -7.747701027478517],
              [-40.853759519301271, -7.747449756287211],
              [-40.854274517113524, -7.746986749428317],
              [-40.855079390661643, -7.7468022292001],
              [-40.856178049909786, -7.746906165547922],
              [-40.857158430982842, -7.747010101793173],
              [-40.857848891281243, -7.74688041514665],
              [-40.857997670796465, -7.745098954642732],
              [-40.858115925019668, -7.745044146064207],
              [-40.859039076593795, -7.745031270939453],
              [-40.860488658853676, -7.745756981973147],
              [-40.861243962196063, -7.746205694016993],
              [-40.862598200008797, -7.748206992546234],
              [-40.862396009564883, -7.750332716002347],
              [-40.86269356789208, -7.751018427270816],
              [-40.864162218111545, -7.75278414727227],
              [-40.86510443835455, -7.754145045172811],
              [-40.866103912237755, -7.755460633387167],
              [-40.867046132275284, -7.757056619921261],
              [-40.867942578181108, -7.759168536415324],
              [-40.869121331316876, -7.761053000478542],
              [-40.869529484125515, -7.761448766531388],
              [-40.871032500407559, -7.761722483245537],
              [-40.871619955816655, -7.762289426458801],
              [-40.873077175572803, -7.76353064850692],
              [-40.873893527284402, -7.764213961242849],
              [-40.875186716780249, -7.765917212462623],
              [-40.876479882220949, -7.767683422317178],
              [-40.877532744983867, -7.768709578266521],
              [-40.879039556098157, -7.76914588004064],
              [-40.880306040480171, -7.768905086542431],
              [-40.880935451450881, -7.768495490471351],
              [-40.881622117927847, -7.768194113820886],
              [-40.882022656437705, -7.767848895176416],
              [-40.881355083199189, -7.765437999613034],
              [-40.879909295611739, -7.765055574579394],
              [-40.878864072186367, -7.764725607589157],
              [-40.878623720611614, -7.763867764368323],
              [-40.878963257601491, -7.763459589036986],
              [-40.880401407740088, -7.763272206376833],
              [-40.881152892559648, -7.763205919415551],
              [-40.881107118059191, -7.76299944457637],
              [-40.881892945917762, -7.762614644496952],
              [-40.882121841576016, -7.762324234384581],
              [-40.88358665097234, -7.763918311389353],
              [-40.884750154124106, -7.764762790491183],
              [-40.886710916575218, -7.764627840980858],
              [-40.8880803819996, -7.763518727619855],
              [-40.889556692280394, -7.761721993349299],
              [-40.890876588599937, -7.761128344948071],
              [-40.892368125771426, -7.760479841014892],
              [-40.892280396238881, -7.758355514289166],
              [-40.891174122694977, -7.757501513293191],
              [-40.888915826056973, -7.756788630476069],
              [-40.884959981642396, -7.75448264944919],
              [-40.882552905904525, -7.751636383912031],
              [-40.88125592073866, -7.749580741742235],
              [-40.879852089812239, -7.748103988113673],
              [-40.877692955391922, -7.746350654439619],
              [-40.876189985201925, -7.745968695357892],
              [-40.874507665317353, -7.745523825486803],
              [-40.872192184883616, -7.745036975311185],
              [-40.870105531258254, -7.744087584025479],
              [-40.869693536428507, -7.743745694657497],
              [-40.869045055892315, -7.742663752390633],
              [-40.868797090817623, -7.74129044516658],
              [-40.8687894544695, -7.740603338158161],
              [-40.868026513431111, -7.739865658789558],
              [-40.866622706409707, -7.738497613534265],
              [-40.86434531706599, -7.736455313447022],
              [-40.862418897127426, -7.734582770677297],
              [-40.861015088995465, -7.733341571686396],
              [-40.860896834922052, -7.733224271266811],
              [-40.860141530625732, -7.732721681538434],
              [-40.859618918417375, -7.732551925074218],
              [-40.858566057643515, -7.731534803083694],
              [-40.85711647533553, -7.730862968740221],
              [-40.855552432675786, -7.730363709293284],
              [-40.854739899582533, -7.730086664203111],
              [-40.854678875109677, -7.729969851830403],
              [-40.854327905854781, -7.729627498519904],
              [-40.854209650875546, -7.72940193173082],
              [-40.853629832112787, -7.729178762528465],
              [-40.853061444673251, -7.729642259338228],
              [-40.852672338331644, -7.730855331939023],
              [-40.851009133412354, -7.731450425035131],
              [-40.849609121058819, -7.730434769737178],
              [-40.84888816087652, -7.728376241099363],
              [-40.848525758400363, -7.727229944460556],
              [-40.847995509842988, -7.726373055991679],
              [-40.847820025317539, -7.726084554519935],
              [-40.847240206453087, -7.725978710634908],
              [-40.846149206798493, -7.726281506928286],
              [-40.845519771729876, -7.726808892312897],
              [-40.844482160907496, -7.726876599021384],
              [-40.843490346709032, -7.725859011089732],
              [-40.843185175888983, -7.724648802873828],
              [-40.84391377401181, -7.723262642576462],
              [-40.845050571391674, -7.721756785328997],
              [-40.845042933892628, -7.721241321324529],
              [-40.844161738976922, -7.720160332859019],
              [-40.842651108346708, -7.719199509150243],
              [-40.84130453076942, -7.718011698905799],
              [-40.839374293005683, -7.715968001143803],
              [-40.837859866551447, -7.714834999436652],
              [-40.8368108004059, -7.714215599139599],
              [-40.835368855496895, -7.714176973119976],
              [-40.834686008559387, -7.714984266827186],
              [-40.835296375209587, -7.717395628305952],
              [-40.836833688208543, -7.720020145344861],
              [-40.837665289723354, -7.721960395761376],
              [-40.837920891394482, -7.723740435614152],
              [-40.837928505744479, -7.724427543618651],
              [-40.837016809346245, -7.725299216753584],
              [-40.835868580193676, -7.725485177677873],
              [-40.835063660593129, -7.725732653540641],
              [-40.833251677568704, -7.724194385934603],
              [-40.832244613325024, -7.722200723677822],
              [-40.831592291066137, -7.720829326103353],
              [-40.830245712741757, -7.719352106546215],
              [-40.829074597044396, -7.718046507141429],
              [-40.828319270703254, -7.717597329106325],
              [-40.827472417305152, -7.719101743609831],
              [-40.828181946267321, -7.720355351918863],
              [-40.829360699415766, -7.722231249008376],
              [-40.829204283454224, -7.723443366811887],
              [-40.828765630059642, -7.725118026677142],
              [-40.82936069966982, -7.726543767886145],
              [-40.830012998880576, -7.727969508145162],
              [-40.831142159011883, -7.730261193773265],
              [-40.83243534845537, -7.732027426604879],
              [-40.833644601985291, -7.732067450417917],
              [-40.83554813477744, -7.731986867534046],
              [-40.837280024719149, -7.731961582179627],
              [-40.83821078963274, -7.732644895005691],
              [-40.83871053770303, -7.735454419077897],
              [-40.837970484311505, -7.736090536733232],
              [-40.836528493403641, -7.736051421712776],
              [-40.83565877660876, -7.735892164887378],
              [-40.834563935233724, -7.735905994859169],
              [-40.83289311771685, -7.736103412263337],
              [-40.830627160553675, -7.734919420197428],
              [-40.828624465048698, -7.732016925369387],
              [-40.827979779337532, -7.731106649113725],
              [-40.826995556349573, -7.730938778240299],
              [-40.826087701767712, -7.731991640322319],
              [-40.825568885235903, -7.732165681859803],
              [-40.824065890952042, -7.731900556104102],
              [-40.82260485433445, -7.730532511809598],
              [-40.822196678526204, -7.730136745762152],
              [-40.82196398624329, -7.730028968188769],
              [-40.821902938771188, -7.729911667819327],
              [-40.821902938873137, -7.729794367502679],
              [-40.820724208530301, -7.728144993092381],
              [-40.820258803223595, -7.727631437681984],
              [-40.818858813826523, -7.726670124072652],
              [-40.817584716198226, -7.726115078205474],
              [-40.816367824975039, -7.725785600073591],
              [-40.815673546863998, -7.725734097773715],
              [-40.815387443702079, -7.725916241465172],
              [-40.815578180280241, -7.727172224311812],
              [-40.816810321297311, -7.728767257083564],
              [-40.816993418470332, -7.729399532558103],
              [-40.817077354140771, -7.731406069940579],
              [-40.81738252581814, -7.73277888820676],
              [-40.817569419759991, -7.733646253315547],
              [-40.817874613538798, -7.73490177226561],
              [-40.817878433063818, -7.7354172362662],
              [-40.817653377428847, -7.735653280696454],
              [-40.816318232227474, -7.73509823477464],
              [-40.813495344341348, -7.731168140229297],
              [-40.812488257061752, -7.729219763710738],
              [-40.811778727204249, -7.727848389088145],
              [-40.811252297166376, -7.727516023569848],
              [-40.810561836981925, -7.727518886956444],
              [-40.810172729643291, -7.728731959551534],
              [-40.810554199688362, -7.731370795505359],
              [-40.810912783440948, -7.732228149820048],
              [-40.8109204199868, -7.732689249140128],
              [-40.81028719024642, -7.732872346525664],
              [-40.809074117754207, -7.7328232427627],
              [-40.808437045441124, -7.732545708828847],
              [-40.808322609034953, -7.732781286357247],
              [-40.807571123323491, -7.732730250004751],
              [-40.807399457520603, -7.732731203850768],
              [-40.807059943433138, -7.7332566804953],
              [-40.805980375807572, -7.734590429790215],
              [-40.805812504679778, -7.734934669639274],
              [-40.805030519427909, -7.736900206510415],
              [-40.804980926325747, -7.737415671464169],
              [-40.80593078295442, -7.739418856615067],
              [-40.806865389743429, -7.740210411812479],
              [-40.80796784446396, -7.740883711844306],
              [-40.808784195274058, -7.741450189250962],
              [-40.809486088733571, -7.742188334558698],
              [-40.809318239652121, -7.742478720721771],
              [-40.807178197760024, -7.74227939402995],
              [-40.80544251320449, -7.741898412746721],
              [-40.80451554391437, -7.741621856555389],
              [-40.803825082628144, -7.741742486254881],
              [-40.803489387214469, -7.74261176089985],
              [-40.803908995492904, -7.743523014954368],
              [-40.804439244849661, -7.744605911104975],
              [-40.804561317644016, -7.745012178843958],
              [-40.803703010229391, -7.745477094392227],
              [-40.803313903038315, -7.746518501988474],
              [-40.803264309683911, -7.747322933259401],
              [-40.803043050573685, -7.748074418690049],
              [-40.802005462708976, -7.748196491080022],
              [-40.800143886781399, -7.7469567126897],
              [-40.798160237106949, -7.745256301878109],
              [-40.79670683583624, -7.744584443533971],
              [-40.795550993088639, -7.744372241769536],
              [-40.794284510719642, -7.744612570272609],
              [-40.792731923134582, -7.745098000099811],
              [-40.791404414388232, -7.745112784864195],
              [-40.790599494846781, -7.745296837094751],
              [-40.790370621396363, -7.745352158574187],
              [-40.789394059005012, -7.745591531333786],
              [-40.788993519540043, -7.745891465239182],
              [-40.788306877929479, -7.746355426941022],
              [-40.788024570544913, -7.746763602318046],
              [-40.788039844438686, -7.747911808640668],
              [-40.788520500636658, -7.749573128376819],
              [-40.789706867366661, -7.751855756105073],
              [-40.789836554414059, -7.753057863213807],
              [-40.78943601500405, -7.753294372488685],
              [-40.788512864437266, -7.753307247613561],
              [-40.787704149071679, -7.753320123837613],
              [-40.787536301093482, -7.753492255689221],
              [-40.788074163945822, -7.755090616843178],
              [-40.789142298546565, -7.757319344785127],
              [-40.789207142644898, -7.757834344841223],
              [-40.788806603179609, -7.758134276746581],
              [-40.787944477139931, -7.758201029609988],
              [-40.787131944156144, -7.757806684202662],
              [-40.786494895545687, -7.757872971264054],
              [-40.785926485157255, -7.758282101387586],
              [-40.785068153437017, -7.759099384881798],
              [-40.783973359021324, -7.759167092537748],
              [-40.783145599192544, -7.757570174123716],
              [-40.783530864662389, -7.756239801208923],
              [-40.783809330523198, -7.755316160831867],
              [-40.783908515933902, -7.754050165294201],
              [-40.783664369338837, -7.753246199767061],
              [-40.782092713345335, -7.752059367320646],
              [-40.78128018025911, -7.751782787228293],
              [-40.779822961365007, -7.75071276520121],
              [-40.779590247138486, -7.750542053994434],
              [-40.778968472469749, -7.751701738697674],
              [-40.77893415325979, -7.753365897970253],
              [-40.779300349507388, -7.754738227290884],
              [-40.779849667672501, -7.757032777397015],
              [-40.779864941367997, -7.758407015403147],
              [-40.779700911953299, -7.759049325518506],
              [-40.779010451668988, -7.759169956216868],
              [-40.778018615339448, -7.758315000338285],
              [-40.777785924207329, -7.75803604576187],
              [-40.777148874700636, -7.757984077512329],
              [-40.775649677052684, -7.758117094437309],
              [-40.774661681444307, -7.757551571876092],
              [-40.774585383072022, -7.756295098131725],
              [-40.774810461005259, -7.755715744702947],
              [-40.774856235386942, -7.754910848433141],
              [-40.774627316031918, -7.754857460545834],
              [-40.774173365601285, -7.755546477137153],
              [-40.773502021025934, -7.756996060107812],
              [-40.772929815467926, -7.757631198910561],
              [-40.772704760881105, -7.757812876656779],
              [-40.771659513420531, -7.757536785359464],
              [-40.771541258497699, -7.757248307938059],
              [-40.770492215615121, -7.756339452325251],
              [-40.770137451587715, -7.755771066330214],
              [-40.770919483838625, -7.753806018460273],
              [-40.772292743963334, -7.752986336427297],
              [-40.773547796164856, -7.751878154973213],
              [-40.774230619005756, -7.751179102638844],
              [-40.774558677818284, -7.749912153306969],
              [-40.774493833821971, -7.749279387938723],
              [-40.773681300838803, -7.748885042533425],
              [-40.771682400521946, -7.750348944269987],
              [-40.771045351913067, -7.750414742334686],
              [-40.770930892814029, -7.750297906916511],
              [-40.770805024440214, -7.749439598810259],
              [-40.771186494281046, -7.747711062215841],
              [-40.772155420227058, -7.74689328882137],
              [-40.772960315814224, -7.746654869907966],
              [-40.773925447133266, -7.745438934830896],
              [-40.775298730359793, -7.744501951481096],
              [-40.776504190356206, -7.744027023292735],
              [-40.777351042650103, -7.742639909110793],
              [-40.777316723036357, -7.74017178446779],
              [-40.777015346137716, -7.739024998891986],
              [-40.776099832921268, -7.739724982019641],
              [-40.775424622477566, -7.741056798671934],
              [-40.77445187761348, -7.741811147433065],
              [-40.772842061635778, -7.742061976577977],
              [-40.771579395936499, -7.742655160027563],
              [-40.770663881775612, -7.743291743524376],
              [-40.77054942251926, -7.743355632052938],
              [-40.770492215824149, -7.743464364369571],
              [-40.7702709558652, -7.744043717800774],
              [-40.769645339476362, -7.744914412183705],
              [-40.769424103619222, -7.745376465298747],
              [-40.768973948005851, -7.746246228838929],
              [-40.768233918462748, -7.747063046436883],
              [-40.767081871298998, -7.747302909041269],
              [-40.766105307855526, -7.747605217433589],
              [-40.76570476945431, -7.74783313475819],
              [-40.764743456802606, -7.749401460783447],
              [-40.763656252682921, -7.750219722072039],
              [-40.76234017611403, -7.751092815842663],
              [-40.761886225826075, -7.751619223380877],
              [-40.76055110283842, -7.750829110830519],
              [-40.76024972673688, -7.749917391884602],
              [-40.76081049987706, -7.748703363447694],
              [-40.761031758836374, -7.748123545019571],
              [-40.7609630750161, -7.747319113649922],
              [-40.760848637861777, -7.747265702862242],
              [-40.76032984348339, -7.747267611402027],
              [-40.759692795922177, -7.747279532781091],
              [-40.758773439182569, -7.747464051909502],
              [-40.75791131289391, -7.747820259086444],
              [-40.756412138249345, -7.747952833015759],
              [-40.754447580152657, -7.747743984532959],
              [-40.753699889419458, -7.74769294718338],
              [-40.75266230260835, -7.747760188893218],
              [-40.750877024607604, -7.7483014051922],
              [-40.749614336116501, -7.748659033013871],
              [-40.748401263522986, -7.748736262513495],
              [-40.747592548310777, -7.748577006742211],
              [-40.746997479614258, -7.747259019912972],
              [-40.746982182672603, -7.746165157278791],
              [-40.748294463516856, -7.745002632193725],
              [-40.750583261850217, -7.743483433033323],
              [-40.751670465873502, -7.742773415114338],
              [-40.752009980100674, -7.742084864423394],
              [-40.752399064288298, -7.741043456833138],
              [-40.752795808021624, -7.740463125560617],
              [-40.753833418935045, -7.740278118536785],
              [-40.754871006699489, -7.740264776511797],
              [-40.75641213704926, -7.738983508316291],
              [-40.756911862762834, -7.737598303826412],
              [-40.757083528768554, -7.737362725348609],
              [-40.757831195698763, -7.737187729015486],
              [-40.758170733635254, -7.73683345337505],
              [-40.759025222471642, -7.735907903511166],
              [-40.759776708233666, -7.735896006230051],
              [-40.760779975588704, -7.73778140110276],
              [-40.761428479213478, -7.738755100985617],
              [-40.762256263248702, -7.740116952760535],
              [-40.763358718058321, -7.74068201041932],
              [-40.764629020097622, -7.740784991917621],
              [-40.766185402297282, -7.740706317722559],
              [-40.76773798892463, -7.740166546212514],
              [-40.768756531167739, -7.738607277184362],
              [-40.768909104352311, -7.737169148701097],
              [-40.768962516231305, -7.736879228437676],
              [-40.769244800866005, -7.73618208574646],
              [-40.769527085296346, -7.735720009684675],
              [-40.770389211334766, -7.735652790821885],
              [-40.771068239546281, -7.73455606380332],
              [-40.771575577806615, -7.733631957629969],
              [-40.77249493475221, -7.733211860873914],
              [-40.773635526498992, -7.732393599630561],
              [-40.774203913231574, -7.731586327828616],
              [-40.774245869786718, -7.730609764558895],
              [-40.774062771763262, -7.729805823088303],
              [-40.772491115618443, -7.728790609650354],
              [-40.771667150514595, -7.7274740666159],
              [-40.771194107506282, -7.726680601834468],
              [-40.770778318302519, -7.725651604476237],
              [-40.769672045611848, -7.724977792450701],
              [-40.769023542032023, -7.723949772886396],
              [-40.767974498145641, -7.723040917281685],
              [-40.766864453786923, -7.722014785308692],
              [-40.767089508561149, -7.721616107828198],
              [-40.767432817416115, -7.721325255820916],
              [-40.767428998544538, -7.721207955501754],
              [-40.76753964007051, -7.720863668605348],
              [-40.768226258686255, -7.720390648956759],
              [-40.768447540792309, -7.719639629528166],
              [-40.768489497589101, -7.718382691895942],
              [-40.767902018386955, -7.717580169070374],
              [-40.766738539047658, -7.71696123467737],
              [-40.765922186332901, -7.716286490909479],
              [-40.765552172411574, -7.714561305654567],
              [-40.765834456841176, -7.714098763596336],
              [-40.767276424785067, -7.714083978925592],
              [-40.768493316104575, -7.714295692739568],
              [-40.769191389888107, -7.714690527038153],
              [-40.769229550961093, -7.713144599089139],
              [-40.769225731136871, -7.712972467090009],
              [-40.770534171164471, -7.711629219053652],
              [-40.771106376672627, -7.711047955937659],
              [-40.7715450770594, -7.709373784082239],
              [-40.771941798588379, -7.709028542438146],
              [-40.773025182983268, -7.707920849833495],
              [-40.773425722485641, -7.707575631192496],
              [-40.773700370320611, -7.706706356500264],
              [-40.773688937143774, -7.705847558500624],
              [-40.772857335488347, -7.704069918155536],
              [-40.771625170416904, -7.702537843033578],
              [-40.770984304334867, -7.702025242472801],
              [-40.77046553304897, -7.701918908646757],
              [-40.769832256366691, -7.702039073400509],
              [-40.769446991803854, -7.703487211619164],
              [-40.769065521877607, -7.705324480570871],
              [-40.768745075415907, -7.707287618841024],
              [-40.768703119863481, -7.708264181107332],
              [-40.768245350611906, -7.708781555694549],
              [-40.767501479600007, -7.709019485665527],
              [-40.765662789766857, -7.709795790552305],
              [-40.76422848117469, -7.710497683221414],
              [-40.762908586128134, -7.710801900309217],
              [-40.761001235472385, -7.710773308832692],
              [-40.759845369735835, -7.710552049127886],
              [-40.758803964294216, -7.710222081156072],
              [-40.757934223506048, -7.710062801336254],
              [-40.757705327103764, -7.710063756133523],
              [-40.757709145779813, -7.710407088133377],
              [-40.757842651705033, -7.711726471549723],
              [-40.758021954053881, -7.712187128016305],
              [-40.757274264069522, -7.71242505798373],
              [-40.755942937605141, -7.71215041043914],
              [-40.754901531164485, -7.711819953470137],
              [-40.754436125506984, -7.71171361968964],
              [-40.75339853764833, -7.711835228086062],
              [-40.752715690812217, -7.712534256418557],
              [-40.751468299165367, -7.714103537186257],
              [-40.75026665870039, -7.715093930371289],
              [-40.748374581298044, -7.715807300577688],
              [-40.747397994810889, -7.716163485657411],
              [-40.746070485969106, -7.716295570742371],
              [-40.744868869802069, -7.716942630930554],
              [-40.743663409912728, -7.717300259804373],
              [-40.742751691479576, -7.718225809617092],
              [-40.742301559768258, -7.719213339465964],
              [-40.741210559931382, -7.719742144439301],
              [-40.740062330588174, -7.720162706994896],
              [-40.738731004173822, -7.719833693767096],
              [-40.736938089029863, -7.719452223447596],
              [-40.73496971311161, -7.719071241974262],
              [-40.733569701070515, -7.717711764702372],
              [-40.732459632714992, -7.716685608735435],
              [-40.731399134553548, -7.715035279455178],
              [-40.731502138531226, -7.714229894245354],
              [-40.731898883410388, -7.713477918976909],
              [-40.731765354391335, -7.712267244929454],
              [-40.731345746362663, -7.711066093580306],
              [-40.730815496902572, -7.710100961758293],
              [-40.730514143851792, -7.709125819192],
              [-40.730567531729641, -7.708835897930657],
              [-40.732242191894478, -7.708991825461973],
              [-40.734225841712544, -7.710521059242279],
              [-40.735332114252117, -7.711366492259619],
              [-40.737121186673754, -7.71145850726326],
              [-40.737598025498812, -7.712433182984561],
              [-40.738776778639888, -7.71430905705064],
              [-40.74169121595056, -7.71238023781947],
              [-40.742885219819406, -7.710983112002594],
              [-40.743224709996952, -7.710348903996749],
              [-40.744025811708951, -7.70999318476293],
              [-40.744937531095253, -7.709121046638503],
              [-40.745799634381228, -7.708765326457132],
              [-40.745624172849077, -7.708485464939522],
              [-40.745135879900701, -7.706589055588277],
              [-40.745185473102175, -7.705955802327385],
              [-40.744712477092868, -7.705161871554574],
              [-40.74396476325839, -7.705228158524123],
              [-40.742919539048231, -7.704663100923085],
              [-40.742965313370171, -7.703921138293755],
              [-40.744342439263754, -7.703327954941558],
              [-40.745383844954034, -7.703368956598318],
              [-40.746482504190091, -7.703472915936818],
              [-40.748088455439721, -7.702933143469571],
              [-40.748595816843952, -7.701836882305258],
              [-40.748408900993013, -7.70086127283949],
              [-40.747771852432685, -7.700872706224708],
              [-40.746619804467443, -7.700886535152936],
              [-40.745925526361397, -7.700835056856223],
              [-40.74527702177982, -7.699806991300073],
              [-40.745101561153369, -7.699635791148618],
              [-40.745029080847019, -7.698262018106482],
              [-40.745296114178366, -7.696877302420337],
              [-40.746432888390558, -7.69554357618442],
              [-40.746486299267495, -7.695254143920124],
              [-40.745368594554314, -7.693540856962591],
              [-40.745254135298588, -7.693604746491171],
              [-40.745139698199246, -7.693487446077718],
              [-40.744269958458318, -7.693274312574761],
              [-40.743857963834337, -7.692697333589634],
              [-40.743381103147613, -7.69155960482325],
              [-40.743087386596137, -7.691099927255082],
              [-40.742610548815186, -7.690070907854335],
              [-40.741912499078872, -7.689621729877723],
              [-40.740935889795722, -7.689743313328937],
              [-40.739032356820459, -7.690058520853209],
              [-40.738101567959227, -7.689320842361799],
              [-40.738078681609522, -7.687593724428894],
              [-40.738242710955774, -7.687023428900442],
              [-40.738071068255536, -7.686906616435275],
              [-40.737090685944437, -7.687091624513037],
              [-40.736808402463019, -7.687617101201663],
              [-40.736072144706412, -7.688659951478794],
              [-40.734977327249297, -7.688782024825684],
              [-40.733989331631584, -7.688225558227418],
              [-40.733474333079627, -7.688399133771419],
              [-40.733489606982104, -7.689547340078293],
              [-40.733684161133041, -7.691273502163789],
              [-40.733691773533614, -7.691906266474813],
              [-40.733417126506708, -7.693001550842212],
              [-40.732093435640266, -7.693133635933565],
              [-40.730876522333112, -7.692912376180339],
              [-40.729842752341028, -7.693160806841298],
              [-40.7281261372747, -7.694388199197832],
              [-40.72686347163927, -7.694917960018806],
              [-40.725822042154135, -7.694641868736506],
              [-40.725852566681823, -7.692625318541183],
              [-40.72653538960828, -7.69181755884585],
              [-40.726512502406479, -7.689919263914474],
              [-40.726096712903214, -7.689233577562785],
              [-40.725390955916282, -7.687979502282615],
              [-40.724807342288102, -7.687638567773276],
              [-40.723708705194689, -7.687371557390049],
              [-40.722671118386153, -7.687439241103995],
              [-40.721519070174686, -7.68774203834614],
              [-40.720077102237646, -7.687756823022045],
              [-40.719375209779287, -7.687018189735],
              [-40.719478192003464, -7.685923837217188],
              [-40.720725605640304, -7.684354580456083],
              [-40.721335972014437, -7.682453421087714],
              [-40.720973570683014, -7.681134969480513],
              [-40.720737061529995, -7.680909426595555],
              [-40.720553940548776, -7.68005158345103],
              [-40.720302179795134, -7.678325420321757],
              [-40.720237336544017, -7.677982112272289],
              [-40.719649857332413, -7.67718864640544],
              [-40.719363755377117, -7.67713572446946],
              [-40.719359936504873, -7.677018424151671],
              [-40.719108176649648, -7.675409562337862],
              [-40.718928873244124, -7.67501284050268],
              [-40.718226980784593, -7.674274231217785],
              [-40.717578477302517, -7.673128377358942],
              [-40.718432966027478, -7.67232015081459],
              [-40.719997008722935, -7.672756918601827],
              [-40.721442772337781, -7.67308593191762],
              [-40.722713074528954, -7.673008213460097],
              [-40.72432668526794, -7.672992962931739],
              [-40.725429140133429, -7.673494596944942],
              [-40.726375178148572, -7.675091538424122],
              [-40.727828579361024, -7.675826795373648],
              [-40.728583905697086, -7.676275996396478],
              [-40.729858004364068, -7.676777118560763],
              [-40.730681991675787, -7.677858594948695],
              [-40.731860721722967, -7.679852280311995],
              [-40.733497220869879, -7.681500212088828],
              [-40.734832343110838, -7.682001380306678],
              [-40.735679219343695, -7.680668608818454],
              [-40.735076513227156, -7.678727403641348],
              [-40.73490484652703, -7.678610592176531],
              [-40.735240565627237, -7.678093684484093],
              [-40.736098850133239, -7.677511466621303],
              [-40.736953363922474, -7.676631226496204],
              [-40.737388221716849, -7.674504525293143],
              [-40.735026920652444, -7.670508656615463],
              [-40.734210614990275, -7.669770488229264],
              [-40.73310050062063, -7.668753389221549],
              [-40.733028020068254, -7.66765952655135],
              [-40.733020383619696, -7.667080638927503],
              [-40.733016588035142, -7.666628597565799],
              [-40.731132124604066, -7.663833391287467],
              [-40.730300521888097, -7.662118660597224],
              [-40.729762659972913, -7.660574665161099],
              [-40.728641113529413, -7.658571945907495],
              [-40.727996427567184, -7.657950635987819],
              [-40.726947384723658, -7.656987414721168],
              [-40.726363747241926, -7.656474348221359],
              [-40.726245492113954, -7.65642096043231],
              [-40.725719061279626, -7.655853015302274],
              [-40.724788273416145, -7.655115360818845],
              [-40.723971921559446, -7.654603225115057],
              [-40.723857485451532, -7.654495471647197],
              [-40.723559904018714, -7.653927083715781],
              [-40.723208981865199, -7.653467383103446],
              [-40.72285421673854, -7.653007727487611],
              [-40.722560500131252, -7.652611006556067],
              [-40.724811160577566, -7.65241216864176],
              [-40.725211698881566, -7.652292959680919],
              [-40.724681451368447, -7.651381705555444],
              [-40.723517971129475, -7.650645004868614],
              [-40.722869466488653, -7.649679873957593],
              [-40.72296483425783, -7.648007099819782],
              [-40.724036764207597, -7.64633008754086],
              [-40.725223131706954, -7.644245831740883],
              [-40.725898341131959, -7.642913991105007],
              [-40.726115782303815, -7.642108140002786],
              [-40.726214967696251, -7.64084216849034],
              [-40.726264561039784, -7.640037271239448],
              [-40.726424772804535, -7.639005853407724],
              [-40.726344654642439, -7.637514315061903],
              [-40.726043301785062, -7.636303616885297],
              [-40.725665650288363, -7.634126391296698],
              [-40.725425321748553, -7.633214182427019],
              [-40.726111964209728, -7.632912828774865],
              [-40.727973540289767, -7.633981430111962],
              [-40.729388803247147, -7.636498170600468],
              [-40.731257992778019, -7.638145635560875],
              [-40.732364265372169, -7.63893672487886],
              [-40.733005133564419, -7.639322969167184],
              [-40.73392448934726, -7.639084060348429],
              [-40.734107587526303, -7.639716336807775],
              [-40.73309668270835, -7.641908371128561],
              [-40.732246011701527, -7.643015109931664],
              [-40.731338110854011, -7.644402248046468],
              [-40.730723948855022, -7.645905217734861],
              [-40.730342478230682, -7.647407746624591],
              [-40.729724498210899, -7.648965571989265],
              [-40.729343027593906, -7.650459018932435],
              [-40.729354483574767, -7.651552906547347],
              [-40.730414958796089, -7.653148870123961],
              [-40.731643282039087, -7.654518382168432],
              [-40.732932651910104, -7.655824400636894],
              [-40.73445853339171, -7.65798830929418],
              [-40.735522850391035, -7.659810304556173],
              [-40.735541919021912, -7.661247965170638],
              [-40.736487957737737, -7.663196808591509],
              [-40.736842721723974, -7.663819561256093],
              [-40.737376789108666, -7.664848092703079],
              [-40.737968062945974, -7.666048289196284],
              [-40.738380058626248, -7.666561844548347],
              [-40.73884925875921, -7.66724706594642],
              [-40.739726635847745, -7.668156876382039],
              [-40.74024542942832, -7.667919412213809],
              [-40.740619285747478, -7.665675898642217],
              [-40.740661242543588, -7.664409880075427],
              [-40.741458548576247, -7.663710362845279],
              [-40.742152780737484, -7.663698442509205],
              [-40.743781666487052, -7.664713631972143],
              [-40.744605677653169, -7.665967240353532],
              [-40.744739159484524, -7.667403948074192],
              [-40.744750592517363, -7.668434854061277],
              [-40.744529355462518, -7.669131995799035],
              [-40.744418714087104, -7.669304151698527],
              [-40.744300459865833, -7.669358983276886],
              [-40.744254685539424, -7.670109513853124],
              [-40.744266139665378, -7.671031757475336],
              [-40.744235616196683, -7.672984860039842],
              [-40.744479762456379, -7.674186478229966],
              [-40.745994188097562, -7.675093424230635],
              [-40.747550593193331, -7.675132563336438],
              [-40.748469925986164, -7.674884575573129],
              [-40.749492286940288, -7.673614271869268],
              [-40.7499996253487, -7.672509442757332],
              [-40.750327707047539, -7.671359792753646],
              [-40.750957119050938, -7.670896296000334],
              [-40.751941318892989, -7.671226753913604],
              [-40.75340235562016, -7.672468440913511],
              [-40.754386532509336, -7.672744532144693],
              [-40.755424143220161, -7.672794590741821],
              [-40.756579986160538, -7.67278073781357],
              [-40.757384905651364, -7.672651074260887],
              [-40.758475905387364, -7.672239544599492],
              [-40.758468268086737, -7.671489479973396],
              [-40.758445381578348, -7.669943109984957],
              [-40.758845919132959, -7.669534933712122],
              [-40.76109278466982, -7.669272184172098],
              [-40.76190152287738, -7.669440985879374],
              [-40.762591960168031, -7.669311300228411],
              [-40.762931474195689, -7.66884875722061],
              [-40.762969611260445, -7.667302829281624],
              [-40.76295817998642, -7.666552298655089],
              [-40.763064978832567, -7.665864701762382],
              [-40.763698233522177, -7.665735480059547],
              [-40.764220845828426, -7.665787472203535],
              [-40.76520504557071, -7.666235695428979],
              [-40.765266070043694, -7.666352994796223],
              [-40.765494942399663, -7.666406406680374],
              [-40.766078603085219, -7.666684384557068],
              [-40.766543985541936, -7.667025806961933],
              [-40.767585415134484, -7.667184108929804],
              [-40.768214826944444, -7.66694664485582],
              [-40.768443722393947, -7.666891346374867],
              [-40.768733644425225, -7.666826968999168],
              [-40.769836075241898, -7.667392027644164],
              [-40.769794119499245, -7.668594599585992],
              [-40.769797938223356, -7.668884031899173],
              [-40.769981036307186, -7.669624573731998],
              [-40.770106927735384, -7.670429005142813],
              [-40.770011583255808, -7.671758422298736],
              [-40.770080221888762, -7.672788863341109],
              [-40.770778318580973, -7.673292406999763],
              [-40.771827338567618, -7.674092554227784],
              [-40.772243151980305, -7.674886483949286],
              [-40.773170121270368, -7.675163041129862],
              [-40.773685120027615, -7.674753910960131],
              [-40.774081863703323, -7.674237025318054],
              [-40.774486197889622, -7.674226548726033],
              [-40.775291117273234, -7.674223195436019],
              [-40.775279663101585, -7.673354875495421],
              [-40.775272025848139, -7.67255044518518],
              [-40.776019715685543, -7.672484181213235],
              [-40.777465479504386, -7.67258669585461],
              [-40.778735781555774, -7.672680620395721],
              [-40.781047490146157, -7.672996781532778],
              [-40.782031689936552, -7.673390638077872],
              [-40.784011520048566, -7.674629951542316],
              [-40.785354279698694, -7.675763396078782],
              [-40.78622786186348, -7.676618839835625],
              [-40.787048009307426, -7.677582970912566],
              [-40.787921590823338, -7.678031217046385],
              [-40.789077409721088, -7.678071752801999],
              [-40.790172250091523, -7.678057921822492],
              [-40.790214229835783, -7.676855350876533],
              [-40.789146094212754, -7.674626598967178],
              [-40.788322129044076, -7.673373479574043],
              [-40.787551552604505, -7.672002080917536],
              [-40.786723768757987, -7.670405628526083],
              [-40.786140108375434, -7.669774772708041],
              [-40.786021853246815, -7.669721384918319],
              [-40.785556470789679, -7.669379472515118],
              [-40.784801166401792, -7.668984661172301],
              [-40.78358045754878, -7.668257483370838],
              [-40.783000614835629, -7.667979483496684],
              [-40.782996796963332, -7.667862182178224],
              [-40.783622414198504, -7.667163130795417],
              [-40.784774461464757, -7.666805990867775],
              [-40.785457285152916, -7.666278605531756],
              [-40.786605513640723, -7.665695433920201],
              [-40.787467639782228, -7.665510891736351],
              [-40.788390791350146, -7.665498016602664],
              [-40.788863811263944, -7.666409247688726],
              [-40.788997339195582, -7.667728678101236],
              [-40.789539019835402, -7.669562105863781],
              [-40.790031108571959, -7.671684521897743],
              [-40.791438735178311, -7.673505075479695],
              [-40.793594050919772, -7.674915588097295],
              [-40.795451809033452, -7.675983677458648],
              [-40.796844183884453, -7.676484357732092],
              [-40.798408202686701, -7.676812439160167],
              [-40.799098662878563, -7.676800541822318],
              [-40.800079044095469, -7.676732788059198],
              [-40.800479582598143, -7.676387569417345],
              [-40.80092589643661, -7.675282716247121],
              [-40.800735160749163, -7.674144521724805],
              [-40.800029450614566, -7.673053522485477],
              [-40.799968403282293, -7.67277314806914],
              [-40.800182047883197, -7.671506663642898],
              [-40.80033844083669, -7.670294521850619],
              [-40.800315553484722, -7.668567427909885],
              [-40.799892126668716, -7.667140732872998],
              [-40.80029266632679, -7.666614767285957],
              [-40.801444713299531, -7.66660093735435],
              [-40.802722629896259, -7.667165040157967],
              [-40.803420750637876, -7.667614218135267],
              [-40.803535162793082, -7.667668118918826],
              [-40.804233259581629, -7.668062953213363],
              [-40.805854507838511, -7.668562631687905],
              [-40.807067604441016, -7.668484936181649],
              [-40.808795628499048, -7.668351407442776],
              [-40.8093220592827, -7.668981798216014],
              [-40.81037492001316, -7.67005326286748],
              [-40.81147355730517, -7.670102855520596],
              [-40.812499735804053, -7.669338959857805],
              [-40.813346565137884, -7.667897945991711],
              [-40.814071367820851, -7.665879021392868],
              [-40.815150934446038, -7.664536239153203],
              [-40.815833759081976, -7.664072276445694],
              [-40.816409782166957, -7.663952112640621],
              [-40.817561829196471, -7.663874859078982],
              [-40.818431546890913, -7.66415143921087],
              [-40.818439206486651, -7.664558660841556],
              [-40.818507868213601, -7.66548036852287],
              [-40.818458275068544, -7.666050199155868],
              [-40.818233197139513, -7.666629575582651],
              [-40.81817980936362, -7.666801684532762],
              [-40.818179809262112, -7.666919472845572],
              [-40.818240834743129, -7.667027717266438],
              [-40.817897501934453, -7.667264226592135],
              [-40.81755416912582, -7.667500735917876],
              [-40.817043012191213, -7.668081533091046],
              [-40.816760728715451, -7.668597952836061],
              [-40.816886620190594, -7.6693480165682],
              [-40.817870843086361, -7.669624107801924],
              [-40.818732922278258, -7.669385200935223],
              [-40.819770533105945, -7.669308436273215],
              [-40.820125298187939, -7.669822433579164],
              [-40.819900220359749, -7.670284510690509],
              [-40.819389063370132, -7.67092873049392],
              [-40.818877883240539, -7.671735536348783],
              [-40.818256084929018, -7.672488464423035],
              [-40.818259902606272, -7.672831797423559],
              [-40.819995588168794, -7.673213267688108],
              [-40.821143816420332, -7.672909982442766],
              [-40.822349275467658, -7.672380687564801],
              [-40.823608123493919, -7.671444195107724],
              [-40.823779789344165, -7.671388873577696],
              [-40.823589054812004, -7.670069956107285],
              [-40.823577598539003, -7.669319402475544],
              [-40.822868069670626, -7.667948027866491],
              [-40.822917662815868, -7.667378197233091],
              [-40.823940000685532, -7.666216603886155],
              [-40.824161259789278, -7.665465606456896],
              [-40.824844082776934, -7.664594423124589],
              [-40.825820646170953, -7.664355515355651],
              [-40.8272702275355, -7.664963972049313],
              [-40.829230989928618, -7.664883366200975],
              [-40.830653866357196, -7.663313154580386],
              [-40.830756871278005, -7.662571191996716],
              [-40.830623365399909, -7.661188827946119],
              [-40.830623365501339, -7.661071061633101],
              [-40.830505110465808, -7.660908941477443],
              [-40.830207553131707, -7.660223231222552],
              [-40.830146527806626, -7.65993379885867],
              [-40.82995579332723, -7.658551457759489],
              [-40.830238076803049, -7.658035015014229],
              [-40.830062592127526, -7.657918203545348],
              [-40.829131804005421, -7.657469980362791],
              [-40.827510531836936, -7.656862035520735],
              [-40.827159609578963, -7.656520123214984],
              [-40.826862052096821, -7.656006079959246],
              [-40.826972692823752, -7.655426725434314],
              [-40.828292564972642, -7.6550142659713],
              [-40.829383587724891, -7.654593703359474],
              [-40.830692027646108, -7.653376811579859],
              [-40.831599928414178, -7.652097918818967],
              [-40.832290387803319, -7.651859987798344],
              [-40.833099103119203, -7.651911001193087],
              [-40.834380838305904, -7.652927145362173],
              [-40.834796649668647, -7.65378405671906],
              [-40.836364487961831, -7.654681899824046],
              [-40.837115972576129, -7.654850236485736],
              [-40.839256038332941, -7.655220740157672],
              [-40.839431499063387, -7.655274151996419],
              [-40.84048054205865, -7.656074742225218],
              [-40.841411330940986, -7.656802873775647],
              [-40.843040215903137, -7.657591565571293],
              [-40.84413887605924, -7.657803767275203],
              [-40.845409154159256, -7.657852871077539],
              [-40.845577002293076, -7.657499551282022],
              [-40.844520322739484, -7.656247385679617],
              [-40.84335302370804, -7.655284629294933],
              [-40.841842394115893, -7.654269439922376],
              [-40.840270738995727, -7.653200372812335],
              [-40.839107259540519, -7.65269922773932],
              [-40.838466392404442, -7.652240503866577],
              [-40.838294725647529, -7.652187092031166],
              [-40.837825525361005, -7.651674026622556],
              [-40.837417349493911, -7.651341171214874],
              [-40.836421741265653, -7.650377971981916],
              [-40.83583423805306, -7.649575450161111],
              [-40.835082753578519, -7.64924452744865],
              [-40.833690377719606, -7.64874384817818],
              [-40.833053329408948, -7.648457256305173],
              [-40.832816819352786, -7.648114901100343],
              [-40.832523079789247, -7.64766381448102],
              [-40.832401005935402, -7.647320971377082],
              [-40.832030990999037, -7.645595763127414],
              [-40.831382487452174, -7.644513331889133],
              [-40.830390651049434, -7.643722265657142],
              [-40.83010075331142, -7.643442845039842],
              [-40.830440244348878, -7.642971246082387],
              [-40.831409194239178, -7.642216896313196],
              [-40.832557422639134, -7.641741968066622],
              [-40.833591191783107, -7.641330415352234],
              [-40.833526347732175, -7.640752040672035],
              [-40.833408093649439, -7.640644286199772],
              [-40.832648971530929, -7.63996048454139],
              [-40.830959061584153, -7.638484708709728],
              [-40.829620097693926, -7.637586306808965],
              [-40.828746516225962, -7.637074661048811],
              [-40.827651697850008, -7.637088491032588],
              [-40.826789595502632, -7.637508122846594],
              [-40.826232640298677, -7.638776026970022],
              [-40.825717640593908, -7.639121734512013],
              [-40.824092597403855, -7.638568131354607],
              [-40.82217379175114, -7.637446118264516],
              [-40.820491518061473, -7.636775216743694],
              [-40.819915494023434, -7.636840548870205],
              [-40.817889888673456, -7.636234489677902],
              [-40.816383077567302, -7.635788640993774],
              [-40.815044112678727, -7.634890263095332],
              [-40.814399426910846, -7.634033864545024],
              [-40.814155280450521, -7.633058745030167],
              [-40.814094255023505, -7.632887077980406],
              [-40.813979818914646, -7.63277883551473],
              [-40.814662642657588, -7.632188515544576],
              [-40.814243034560114, -7.631051252826915],
              [-40.814754191593323, -7.630352690342276],
              [-40.815730753892929, -7.630222025938736],
              [-40.817756360537437, -7.630484775133482],
              [-40.819141098689187, -7.630705079029164],
              [-40.819663711053558, -7.630693624542163],
              [-40.819778148302305, -7.630638792960003],
              [-40.819892607558316, -7.630575369427994],
              [-40.820174890838757, -7.630284494367261],
              [-40.820285509408585, -7.629886329786402],
              [-40.820560180284218, -7.628963177410858],
              [-40.82059831749887, -7.627236083527646],
              [-40.821109475284139, -7.626826953352678],
              [-40.821735114619905, -7.626010600653112],
              [-40.821483331653603, -7.624519994142171],
              [-40.820953082032041, -7.623726552322997],
              [-40.820377058094486, -7.623674538135455],
              [-40.819801058055845, -7.623740383259751],
              [-40.81827135893846, -7.625826548772923],
              [-40.817882251713925, -7.62692183204519],
              [-40.81759614940642, -7.627275618735261],
              [-40.81713838049702, -7.627395339645562],
              [-40.816138930689696, -7.625971020110176],
              [-40.815887169630486, -7.624588678967941],
              [-40.815929126469022, -7.623268293714636],
              [-40.81607026865322, -7.620736303622192],
              [-40.81634112070175, -7.619640997250045],
              [-40.816726409292805, -7.618147549300894],
              [-40.817107879111113, -7.616419476718977],
              [-40.817428301602995, -7.614401507778617],
              [-40.817523692121178, -7.613018188939917],
              [-40.817283341431164, -7.612277693060623],
              [-40.816760728314442, -7.611999670239613],
              [-40.815738368452301, -7.613161240583882],
              [-40.815578179733798, -7.61413829174024],
              [-40.814792352634306, -7.615986039277961],
              [-40.814628323383474, -7.616447626442056],
              [-40.813945476455551, -7.617255387142115],
              [-40.813201605139028, -7.617846172057518],
              [-40.812270817317916, -7.617045093943617],
              [-40.812152539235221, -7.616937316472427],
              [-40.811973282879542, -7.616477173007505],
              [-40.811675724488325, -7.615854420391767],
              [-40.811389621436881, -7.615909740824887],
              [-40.81093567115316, -7.61643617136306],
              [-40.810249029445906, -7.617017434382716],
              [-40.809669209829224, -7.616622133197894],
              [-40.809009252001346, -7.614789661340509],
              [-40.808814697735009, -7.613180798573736],
              [-40.808452319390817, -7.611862345967856],
              [-40.807803815639254, -7.611015470363582],
              [-40.806708998320076, -7.610965411724108],
              [-40.806560219834118, -7.612747360186381],
              [-40.806098631898209, -7.61292999272636],
              [-40.805125887186392, -7.613512675493837],
              [-40.804683392235468, -7.614726237026938],
              [-40.804526976243203, -7.615992721494584],
              [-40.804366765378049, -7.61714141664725],
              [-40.804271397905175, -7.618471323796688],
              [-40.803989113479147, -7.618933377856948],
              [-40.803367314979518, -7.619912314608251],
              [-40.802566237163376, -7.620385823159468],
              [-40.802039806175188, -7.619990034023001],
              [-40.801498102477225, -7.618211437952173],
              [-40.801021264630613, -7.617245841187296],
              [-40.799461063897894, -7.616800481460357],
              [-40.798350973320439, -7.616009392137071],
              [-40.797591851396099, -7.615099115809324],
              [-40.797523189416012, -7.614466840445324],
              [-40.798610346623128, -7.613539869787398],
              [-40.799461064579546, -7.612496530608107],
              [-40.799556432197704, -7.610995446460583],
              [-40.79851500264602, -7.61078277881693],
              [-40.796218590911664, -7.611669212682451],
              [-40.795623475337372, -7.610180048878382],
              [-40.795730275033144, -7.609664118984368],
              [-40.796241455916217, -7.609137687495802],
              [-40.796001126326345, -7.608279868304451],
              [-40.796054538293923, -7.607881704676858],
              [-40.796905209303944, -7.6067749658685],
              [-40.797183675856388, -7.606195123494114],
              [-40.79687850415857, -7.604822305257836],
              [-40.795837097514301, -7.604717879982561],
              [-40.795196230369811, -7.604268703062932],
              [-40.794833829024576, -7.602950250460986],
              [-40.795688318852967, -7.602024211600034],
              [-40.795619656918383, -7.601337569555154],
              [-40.794517203193962, -7.600654746609378],
              [-40.793483410048943, -7.601074843275144],
              [-40.7925144839189, -7.602118648350226],
              [-40.791839273740074, -7.603161474680608],
              [-40.790862734249941, -7.603518148765569],
              [-40.789752643919172, -7.602437650137915],
              [-40.788661644127494, -7.60291208943712],
              [-40.788142827745993, -7.602914487982128],
              [-40.787780449199879, -7.601831102007378],
              [-40.787948296376591, -7.601423414533302],
              [-40.788970657326068, -7.600153577825538],
              [-40.789531360595944, -7.598767907402618],
              [-40.789809873147249, -7.598188065029065],
              [-40.789687799539074, -7.59755530062462],
              [-40.788715032683243, -7.598310138385283],
              [-40.788093234225627, -7.599243790398689],
              [-40.787112876109866, -7.599193708858574],
              [-40.786639857088353, -7.598399778098297],
              [-40.785537402163712, -7.597952509776777],
              [-40.784434947293676, -7.597441328829136],
              [-40.782813699173381, -7.596778971324935],
              [-40.780902529890348, -7.596335079297457],
              [-40.780601176771604, -7.595422870375677],
              [-40.780418055735289, -7.594618928921299],
              [-40.780810980726926, -7.593758246287872],
              [-40.781264931960855, -7.593286646431005],
              [-40.781264932107653, -7.593114491437702],
              [-40.781432779183383, -7.592824593275444],
              [-40.78165022054516, -7.591793198491429],
              [-40.781753200659331, -7.590816146292561],
              [-40.781108538742089, -7.590131413749866],
              [-40.780467672597567, -7.589682235833019],
              [-40.780170113312714, -7.588933125961592],
              [-40.780509628291384, -7.588524461639665],
              [-40.781665471236046, -7.588511119698233],
              [-40.783336311542833, -7.588549233892739],
              [-40.785056746189753, -7.587782497836152],
              [-40.786147745980344, -7.587308058536168],
              [-40.787006052586683, -7.58660854135496],
              [-40.788024572715287, -7.585157515704369],
              [-40.787532484149693, -7.582808623023904],
              [-40.787120490554152, -7.58218682431118],
              [-40.786410960480666, -7.581041459408546],
              [-40.78575863934671, -7.579497928883618],
              [-40.78556790284695, -7.578124156757347],
              [-40.785663270358846, -7.576740371932293],
              [-40.785304711782736, -7.575656985965812],
              [-40.784370104932307, -7.574919330489335],
              [-40.783134145160204, -7.573034866466789],
              [-40.782828950357541, -7.571779348553518],
              [-40.782233857630537, -7.570461850757748],
              [-40.781074196295748, -7.569788549715633],
              [-40.779807712930143, -7.570028878237748],
              [-40.778953223153131, -7.570900062417413],
              [-40.779094365554272, -7.57279835643045],
              [-40.779399536302954, -7.574117273972492],
              [-40.779410992346577, -7.575147760953465],
              [-40.779307987426705, -7.575898757472764],
              [-40.778979929438457, -7.577400797407821],
              [-40.777953773904429, -7.578218568754539],
              [-40.775703089589335, -7.578246229686174],
              [-40.774482380636016, -7.57762729427319],
              [-40.773734713705998, -7.577801801618929],
              [-40.772700921617847, -7.578158964653685],
              [-40.771087309813836, -7.57823763782662],
              [-40.77008022263945, -7.576126210327698],
              [-40.770465534173738, -7.574687105074514],
              [-40.770568516193244, -7.573818761242672],
              [-40.770312914582988, -7.571930036078309],
              [-40.769027361666389, -7.570623970633383],
              [-40.768848081308789, -7.570163827174452],
              [-40.770114565580457, -7.570032208016261],
              [-40.772971773664956, -7.572352973853841],
              [-40.7751194529598, -7.573194122750007],
              [-40.775741251314038, -7.572377771052875],
              [-40.775260594080969, -7.570716474366593],
              [-40.773330357044813, -7.568961232004182],
              [-40.773273126636319, -7.568735223278305],
              [-40.773212101261571, -7.568500621599558],
              [-40.773208283388314, -7.5683828332856],
              [-40.773078573268748, -7.567235091892377],
              [-40.774230620285451, -7.567167362263162],
              [-40.774924899442176, -7.567164521867006],
              [-40.775725977153741, -7.56680831262906],
              [-40.775130884472382, -7.56543645015373],
              [-40.774318350524482, -7.56498773809251],
              [-40.77328076376272, -7.564992045189968],
              [-40.772250812402703, -7.565638151537819],
              [-40.771041534777574, -7.565715404063128],
              [-40.770339619211846, -7.565085503156101],
              [-40.770843163628058, -7.563989241997821],
              [-40.772109646992298, -7.563748914475083],
              [-40.774013178827893, -7.563605862921611],
              [-40.774921080331346, -7.562616424479693],
              [-40.774730345728855, -7.561360417669251],
              [-40.773627914048497, -7.560623717049189],
              [-40.77349820388217, -7.559529853341848],
              [-40.772559779295392, -7.558512288504789],
              [-40.772079123013924, -7.556904869507482],
              [-40.77166715241713, -7.556282605801967],
              [-40.771598466532737, -7.55553251713473],
              [-40.771651878551395, -7.555070952881693],
              [-40.773257875792396, -7.554540238344448],
              [-40.775043153545454, -7.554288944322944],
              [-40.776126515877749, -7.553235593403415],
              [-40.775821344025992, -7.552034441176847],
              [-40.77534450727228, -7.550951079116059],
              [-40.775111816140416, -7.550663067605655],
              [-40.774993561011421, -7.550609166821378],
              [-40.774470948948185, -7.550267766378854],
              [-40.774238256762985, -7.550042688500072],
              [-40.773708008235971, -7.549131457387141],
              [-40.773585935332839, -7.548842491975805],
              [-40.773399018655574, -7.547640850852994],
              [-40.773383767834098, -7.546375344249127],
              [-40.773360857257792, -7.544883339965164],
              [-40.773345607390318, -7.543671641046735],
              [-40.773341788661675, -7.543382697735266],
              [-40.773273126814352, -7.542587323334367],
              [-40.77315105310997, -7.542063291299756],
              [-40.772109647657999, -7.541732833356787],
              [-40.771361956865903, -7.541745243653836],
              [-40.770438806252528, -7.541811996484831],
              [-40.769118910108048, -7.54223351290393],
              [-40.768489499108796, -7.542697010659253],
              [-40.768039367368914, -7.543738416181666],
              [-40.767879108759509, -7.544598168012222],
              [-40.76784481349015, -7.546379628552111],
              [-40.767570142625374, -7.547302779915213],
              [-40.76666605960758, -7.548870616972618],
              [-40.766444823423321, -7.549730369752058],
              [-40.766517304135903, -7.550652565413801],
              [-40.766700402130425, -7.551510873543136],
              [-40.766719470824725, -7.552894193460351],
              [-40.766666059806624, -7.553355291715734],
              [-40.7666202854437, -7.554160187964897],
              [-40.765868800878408, -7.553937020636933],
              [-40.764003406096464, -7.552525109328645],
              [-40.763236646565126, -7.551208077392781],
              [-40.762641554736234, -7.550007879918132],
              [-40.761939662261291, -7.549269736648132],
              [-40.761348365352404, -7.548132473808729],
              [-40.761508599015407, -7.547209810346857],
              [-40.762878064494124, -7.545983838680928],
              [-40.764281871188253, -7.543039365107377],
              [-40.764197959380795, -7.541141093153362],
              [-40.763038274900303, -7.540639436113967],
              [-40.761241566009858, -7.539968556529082],
              [-40.760776159639548, -7.539518401773464],
              [-40.760081881629219, -7.539349156179798],
              [-40.759616520967747, -7.539242799410676],
              [-40.758277534128993, -7.538280531918867],
              [-40.757518412052754, -7.537542388603099],
              [-40.756869908640532, -7.536288313402901],
              [-40.756686810499389, -7.535601648271098],
              [-40.75656091801244, -7.534851583558956],
              [-40.756961457561403, -7.534443430287165],
              [-40.758510225152477, -7.533967058381672],
              [-40.760287890345438, -7.53325466505133],
              [-40.761382706806295, -7.533132591688097],
              [-40.762824675761081, -7.533108750041678],
              [-40.76427043958271, -7.533211265658349],
              [-40.765948894299406, -7.533882657139023],
              [-40.767520549524285, -7.534834912885172],
              [-40.76971782182116, -7.535268817194464],
              [-40.770461693021723, -7.534804854538105],
              [-40.770511263165282, -7.534225989969991],
              [-40.770103087448419, -7.533712434633938],
              [-40.77032436948577, -7.533024349850171],
              [-40.770366326018994, -7.532047319607758],
              [-40.769195186495637, -7.530506165648514],
              [-40.768554342256593, -7.53016523110603],
              [-40.768439906100866, -7.530111353325336],
              [-40.768321650971778, -7.530057453541508],
              [-40.76820339613284, -7.529660243768594],
              [-40.768077504691234, -7.528855836374448],
              [-40.768302582510344, -7.528394248267577],
              [-40.769160889820697, -7.528038064076113],
              [-40.769904739029158, -7.527564555475564],
              [-40.770465535186318, -7.526296650374159],
              [-40.770454056136849, -7.525266187400596],
              [-40.770663883895018, -7.523818514057255],
              [-40.770827913430281, -7.523013128913093],
              [-40.770232820642754, -7.521758587761918],
              [-40.76987423796816, -7.520783911170398],
              [-40.769405012722373, -7.520216013102953],
              [-40.768756533207736, -7.51907921521796],
              [-40.768176691496919, -7.518792158412442],
              [-40.767360338817056, -7.518054503048313],
              [-40.767120010928522, -7.517539992860929],
              [-40.767509117281953, -7.516272553613914],
              [-40.767730377371592, -7.515521069202921],
              [-40.767020825328011, -7.514330417583462],
              [-40.766013761045912, -7.51232720947597],
              [-40.766067149107798, -7.51181174554135],
              [-40.766875864427995, -7.511853700974495],
              [-40.767924907385549, -7.512708679845368],
              [-40.768916744551731, -7.513789645344318],
              [-40.769736890205614, -7.514527322710912],
              [-40.7703701446964, -7.514633656619782],
              [-40.770660065681831, -7.514623179924633],
              [-40.771011010989632, -7.514911191531994],
              [-40.771777770535166, -7.516219142512993],
              [-40.772658965320424, -7.517481343858657],
              [-40.773299833277768, -7.518157018438826],
              [-40.774459493807321, -7.518604286793212],
              [-40.775268209174023, -7.518591876545597],
              [-40.776084560945442, -7.519221777541798],
              [-40.777721083088444, -7.520923632948613],
              [-40.779002816331655, -7.521885900390028],
              [-40.780387555589392, -7.521979824004282],
              [-40.781356480518674, -7.521162051608753],
              [-40.781928685917229, -7.520698555804603],
              [-40.782500891269827, -7.520289447681845],
              [-40.783477454710784, -7.51998711727366],
              [-40.784572272082826, -7.519973775272232],
              [-40.785327599334224, -7.520540229589773],
              [-40.786658925912086, -7.520697623787409],
              [-40.787696513683755, -7.520684259738132],
              [-40.788158101365326, -7.520800139447657],
              [-40.788581528252095, -7.522172468767125],
              [-40.788425135181733, -7.523547685582278],
              [-40.78827253807745, -7.524931446350763],
              [-40.789100321952482, -7.526528364696388],
              [-40.789508497716049, -7.526988066348133],
              [-40.790622384794503, -7.528348474320218],
              [-40.791377711091684, -7.528860609957488],
              [-40.792129195759124, -7.528966453970455],
              [-40.792705219895467, -7.528782889530091],
              [-40.793853448086949, -7.528543493898378],
              [-40.794604933800962, -7.52859452923219],
              [-40.795127569018888, -7.528755229731384],
              [-40.79570356900242, -7.528752832230365],
              [-40.796569514017627, -7.528686079350226],
              [-40.79737441045885, -7.528619326418633],
              [-40.798183125827514, -7.528606939172072],
              [-40.79893081662162, -7.528594995871449],
              [-40.799106300398243, -7.528594018024975],
              [-40.800548246160524, -7.528805289002224],
              [-40.800723730937207, -7.528804334155609],
              [-40.801177681161931, -7.528341326248201],
              [-40.801517195329694, -7.527707141246537],
              [-40.800979309096455, -7.526506920819863],
              [-40.799987495882974, -7.52548029899522],
              [-40.799579319920177, -7.525255710966515],
              [-40.799060525629187, -7.525149376152449],
              [-40.798595120164386, -7.5248079527597],
              [-40.798064894442327, -7.524122731325329],
              [-40.797767336097451, -7.523437044089565],
              [-40.797988619089139, -7.522803324986101],
              [-40.798328087257069, -7.522168650987709],
              [-40.797732993620372, -7.520733852888084],
              [-40.796737362379879, -7.51977016569356],
              [-40.795974421562342, -7.518751646016081],
              [-40.794509543272873, -7.516985436127353],
              [-40.7941548013264, -7.516299748844683],
              [-40.794089958266561, -7.515721816174707],
              [-40.794719394266821, -7.515257853420822],
              [-40.795818029468826, -7.515416155417064],
              [-40.797218018699752, -7.516603966637941],
              [-40.798682850944537, -7.518424052211582],
              [-40.800437628067812, -7.520360973346086],
              [-40.801078495164347, -7.520873574883844],
              [-40.802352592097819, -7.521085287716064],
              [-40.804023433410194, -7.521123914895385],
              [-40.804832148733652, -7.521165870330023],
              [-40.806853959455019, -7.521374254810921],
              [-40.807491007922124, -7.521480099727233],
              [-40.809222874665224, -7.521689902956057],
              [-40.809329697862928, -7.521757633649448],
              [-40.810268098805686, -7.522363692891318],
              [-40.811309529400226, -7.522531540785104],
              [-40.812457757747602, -7.5221104892159],
              [-40.813148217987582, -7.522044691182054],
              [-40.814185805762378, -7.522031327133329],
              [-40.815051750825369, -7.521910208570902],
              [-40.815162368199765, -7.521737587674383],
              [-40.814334584421111, -7.520023369011847],
              [-40.813518232692161, -7.519339591327741],
              [-40.812919321272204, -7.517678271549118],
              [-40.812858296934188, -7.517398362136384],
              [-40.811980919729741, -7.51659728408822],
              [-40.81087082842982, -7.515462884796075],
              [-40.81011170724841, -7.514842040792715],
              [-40.809711168040458, -7.514843950444883],
              [-40.809364016453287, -7.51485396209442],
              [-40.807925890226734, -7.515158668101588],
              [-40.80700653333858, -7.515514852243319],
              [-40.806262661326286, -7.515752805224793],
              [-40.805053408504641, -7.516056557429778],
              [-40.803844153782983, -7.516242030327278],
              [-40.802287749580856, -7.516311670611171],
              [-40.8010746772215, -7.516099933831228],
              [-40.798923179374817, -7.514743343966695],
              [-40.798217469065882, -7.513833067704224],
              [-40.797923729495203, -7.513382447096297],
              [-40.797218018285541, -7.512354381524035],
              [-40.79662292654826, -7.511036883740124],
              [-40.796668700098358, -7.510005954816404],
              [-40.797645263586588, -7.509649280725544],
              [-40.799739530741491, -7.511114602902874],
              [-40.800727572517388, -7.511507994429057],
              [-40.802055057979459, -7.511837496614755],
              [-40.803153694228271, -7.511941431929103],
              [-40.804191282055527, -7.511864668250338],
              [-40.806094837746528, -7.511901850631991],
              [-40.806846322515007, -7.511889930333183],
              [-40.807132425566081, -7.51183463289735],
              [-40.80718583733875, -7.511662500950147],
              [-40.807346047144883, -7.510567659495498],
              [-40.807326978391082, -7.509247811207977],
              [-40.807323160760774, -7.508841031586784],
              [-40.808303541880584, -7.508891114116901],
              [-40.809226692361044, -7.508986946331119],
              [-40.81074496061791, -7.510354992596144],
              [-40.81132477833944, -7.510632992455569],
              [-40.811793980779001, -7.510974415850723],
              [-40.812526418748746, -7.509696475948414],
              [-40.812804885341173, -7.509062779892637],
              [-40.813552575281854, -7.508878703598507],
              [-40.814532957356491, -7.508983127810594],
              [-40.815349285231413, -7.509495263497908],
              [-40.815875715134936, -7.509999272989307],
              [-40.816112225051512, -7.510513783175753],
              [-40.816230481028882, -7.51074838290169],
              [-40.81640978348922, -7.511091227045148],
              [-40.816585244976288, -7.511434094185311],
              [-40.816833209187536, -7.512689589043672],
              [-40.816837028916645, -7.512979043352385],
              [-40.816852278742203, -7.514244572956706],
              [-40.817096425269519, -7.515156758822105],
              [-40.817859365180787, -7.516066569137789],
              [-40.818553644051214, -7.516407503725892],
              [-40.820289328579115, -7.516843339634379],
              [-40.821212480098893, -7.516893887114874],
              [-40.821323120625721, -7.516540543276105],
              [-40.821666430568683, -7.516141423900956],
              [-40.821651180598614, -7.515047561290818],
              [-40.821651180698062, -7.514929771980238],
              [-40.82153294856758, -7.514876384193357],
              [-40.821395602074219, -7.513041512818299],
              [-40.821319302628481, -7.511830325844106],
              [-40.820834828645602, -7.5098881658063],
              [-40.820300784336276, -7.508742335065588],
              [-40.819072437904381, -7.507554057990459],
              [-40.818072987983896, -7.506237980856369],
              [-40.816489899893533, -7.504020219512427],
              [-40.814944927367016, -7.500817278971001],
              [-40.814235398562083, -7.499337173043283],
              [-40.813922567151366, -7.497665865568543],
              [-40.813903521685049, -7.496002171296134],
              [-40.814296447433378, -7.495421864024273],
              [-40.815162368549558, -7.495237810828302],
              [-40.817084993899989, -7.496712678803132],
              [-40.81888170366156, -7.497554758378859],
              [-40.819564549442752, -7.496910072726837],
              [-40.819549275759414, -7.495472900130099],
              [-40.819240286070261, -7.494099592913423],
              [-40.819007594783216, -7.493992304346619],
              [-40.818950364281022, -7.493875003985599],
              [-40.818881703338192, -7.493187873952132],
              [-40.818568895062896, -7.491353957430517],
              [-40.818557463017662, -7.490313970511941],
              [-40.819244104574153, -7.489904374486261],
              [-40.82029314744856, -7.490867596724049],
              [-40.821449013301184, -7.490971532084106],
              [-40.822292046886318, -7.489232028978057],
              [-40.822570513569616, -7.488489111562357],
              [-40.824184148285632, -7.488527737688167],
              [-40.826366146824533, -7.487632711758012],
              [-40.827678404409774, -7.48675917697315],
              [-40.829276766541113, -7.485242818192366],
              [-40.82875797205751, -7.48536249305696],
              [-40.828292565581812, -7.485030173613371],
              [-40.827590673906549, -7.484517573027587],
              [-40.827464805559138, -7.483595818320572],
              [-40.827571605491144, -7.482790898124308],
              [-40.827556308418679, -7.481814334831106],
              [-40.827483850934193, -7.480612228811283],
              [-40.828170516346631, -7.480374298779638],
              [-40.829280560798274, -7.481337032070159],
              [-40.82986042746893, -7.481677966558826],
              [-40.83043645060684, -7.48149486811342],
              [-40.830363969932527, -7.480518770769549],
              [-40.829662054636835, -7.479554127831886],
              [-40.828380320236924, -7.478764016386954],
              [-40.826282234293032, -7.477064094588696],
              [-40.825172142940445, -7.475983571989528],
              [-40.824527457154545, -7.475127173467133],
              [-40.824687691759223, -7.474267420637789],
              [-40.82612963671798, -7.474252659930207],
              [-40.828033169521234, -7.474163485044595],
              [-40.829261515951885, -7.475360818058307],
              [-40.830196121672259, -7.476270140513784],
              [-40.831001017972653, -7.476375054572688],
              [-40.831695297096744, -7.476417498904985],
              [-40.832385758285518, -7.476414635499434],
              [-40.832202636335936, -7.475493392742144],
              [-40.831149797674563, -7.474304137827372],
              [-40.828880045728248, -7.472839771507083],
              [-40.82730457186026, -7.471472215201124],
              [-40.826362327769402, -7.469875762766312],
              [-40.826118181290234, -7.468900154278054],
              [-40.825175961054441, -7.467475833835728],
              [-40.824248990939047, -7.466973245005987],
              [-40.82337161477389, -7.466117800285331],
              [-40.823020669553088, -7.465721569313887],
              [-40.822944370093033, -7.4645194392942],
              [-40.822719292517689, -7.464692060094739],
              [-40.822429371485164, -7.464756414475498],
              [-40.822318754112061, -7.464928593374172],
              [-40.822139473604054, -7.464640045915258],
              [-40.821265892169656, -7.464074079502135],
              [-40.820861535078635, -7.46396725679048],
              [-40.820342740838292, -7.463797500351983],
              [-40.81993456487227, -7.46357340032443],
              [-40.819522570364953, -7.462833812314586],
              [-40.81858798659097, -7.461987425494447],
              [-40.818412502912508, -7.46187059003271],
              [-40.817779248227453, -7.461990754799797],
              [-40.817149837416906, -7.462228707879761],
              [-40.816230480475994, -7.462648338652921],
              [-40.815013589480294, -7.462029381264608],
              [-40.814544388182526, -7.461515825882857],
              [-40.81510895711866, -7.460473953460575],
              [-40.815738369027272, -7.460118700068094],
              [-40.816066450800903, -7.458851749767653],
              [-40.815940582253795, -7.458165085687395],
              [-40.814265923153116, -7.45790044984128],
              [-40.813754743284051, -7.458418289379394],
              [-40.813461026520706, -7.458193258453359],
              [-40.813392341764036, -7.457280560745998],
              [-40.813678443967, -7.457044050371016],
              [-40.814422292221593, -7.456516665082962],
              [-40.814471909262117, -7.456055077830006],
              [-40.814048482461956, -7.454565448208871],
              [-40.813300792711324, -7.454522979834344],
              [-40.812839228882858, -7.454579255119492],
              [-40.812713336282506, -7.453956036667924],
              [-40.812881184453261, -7.453548349197871],
              [-40.81211442584938, -7.452294740907729],
              [-40.811027244988246, -7.452823545901218],
              [-40.809238148685502, -7.452559864955524],
              [-40.8086392381541, -7.451015869508645],
              [-40.808910067325286, -7.449748920162652],
              [-40.808944385575622, -7.447967925633764],
              [-40.808112806952209, -7.446081552001964],
              [-40.806361848494944, -7.444488428885752],
              [-40.805022884682167, -7.443472284729833],
              [-40.800895397682936, -7.440822015837512],
              [-40.798633258208092, -7.439926991204767],
              [-40.797073058220889, -7.439770597823944],
              [-40.795806574990074, -7.439847851317034],
              [-40.795177139083734, -7.4402031047094],
              [-40.794673619536873, -7.441471008853012],
              [-40.793872518684196, -7.441998371092867],
              [-40.791865981084754, -7.442711742238328],
              [-40.791175520755345, -7.442886249640032],
              [-40.790527017228293, -7.441749940766108],
              [-40.791263274963462, -7.440707114496234],
              [-40.792174971286457, -7.439889808049381],
              [-40.792560259702654, -7.438568002124144],
              [-40.792373342873923, -7.437528971064175],
              [-40.789809874407325, -7.435550093542256],
              [-40.787963549506905, -7.435286412554278],
              [-40.786754294644147, -7.435644040450521],
              [-40.784736302654736, -7.435724600300049],
              [-40.783984818083496, -7.435501454984642],
              [-40.783630029077855, -7.434879190341033],
              [-40.783389724568281, -7.433903582866169],
              [-40.782863294714247, -7.433336150758409],
              [-40.782794631721124, -7.432703385414579],
              [-40.783424043671765, -7.432293765341401],
              [-40.783767376473392, -7.432057745011716],
              [-40.783065460933756, -7.431382069393594],
              [-40.782714515667188, -7.431040180108368],
              [-40.782073648610925, -7.430473213903189],
              [-40.782867112900021, -7.429484264366212],
              [-40.782867113043153, -7.429312597373669],
              [-40.781879094407458, -7.428747050872109],
              [-40.781295457809108, -7.428351284710073],
              [-40.780536334811075, -7.427504408050095],
              [-40.779037137303078, -7.427465316034582],
              [-40.777705808963916, -7.427018513551632],
              [-40.776889457086305, -7.426506401879753],
              [-40.776885662346444, -7.426226003522468],
              [-40.777515074438995, -7.425645230454037],
              [-40.778430612348622, -7.425288555307664],
              [-40.78009381629284, -7.42463099454796],
              [-40.780425694078218, -7.423309679579138],
              [-40.779594091262908, -7.421649313635285],
              [-40.778728123534748, -7.421372758540166],
              [-40.777736333970417, -7.420752868356295],
              [-40.775993012023463, -7.419683802186126],
              [-40.775123272465223, -7.419235090099015],
              [-40.774989767638225, -7.417743551745101],
              [-40.776080742278111, -7.417441220424812],
              [-40.777637148432014, -7.41742596980496],
              [-40.777671490584801, -7.415752753656815],
              [-40.7773777501071, -7.415184365750357],
              [-40.776958142988299, -7.41403804613284],
              [-40.776836046411319, -7.413351381063324],
              [-40.778102553489234, -7.4134548745054],
              [-40.779334694663696, -7.414932581850878],
              [-40.780391351106779, -7.416356903365034],
              [-40.781673109273427, -7.417427879140724],
              [-40.782951024745564, -7.418164113878688],
              [-40.784755394223197, -7.419287080783566],
              [-40.786319412700927, -7.42002187276815],
              [-40.788127577956686, -7.421379905297697],
              [-40.79020659504689, -7.42186917715156],
              [-40.792590784339943, -7.42316138954697],
              [-40.793857268472721, -7.423201436363293],
              [-40.79540603630825, -7.422435631142887],
              [-40.795501403788293, -7.421060904289342],
              [-40.795379330068712, -7.420545928210188],
              [-40.794788056147105, -7.419400050444169],
              [-40.794326468553173, -7.419175997376088],
              [-40.792461073694703, -7.417818405796192],
              [-40.792289430936421, -7.417764994967271],
              [-40.792796769934, -7.417120798169011],
              [-40.792853999678094, -7.416948666226721],
              [-40.79307905353788, -7.416432712441692],
              [-40.792602216675064, -7.4154580587668],
              [-40.792186426522086, -7.414320330099915],
              [-40.792525940448378, -7.413966520458182],
              [-40.792865431465323, -7.4135039784546],
              [-40.79337661138306, -7.412923181287456],
              [-40.79360548507313, -7.412578917497891],
              [-40.793712307764849, -7.412053906668103],
              [-40.793353724069554, -7.411087845041527],
              [-40.79201857889656, -7.410469375565471],
              [-40.79166763277194, -7.409955354292018],
              [-40.791133565352403, -7.40892682288736],
              [-40.791061084767485, -7.407832936248972],
              [-40.791919392264361, -7.407251208375042],
              [-40.792705221131328, -7.405629470546536],
              [-40.792750994623695, -7.404652908317594],
              [-40.793887815802883, -7.403319205088816],
              [-40.793994615486703, -7.402803252206375],
              [-40.794101415267889, -7.402170021012447],
              [-40.795550996742101, -7.402670212275923],
              [-40.798167876132268, -7.404305291843982],
              [-40.799979859892709, -7.406233178027973],
              [-40.801894847649635, -7.407301267345131],
              [-40.804401109518345, -7.409289201754368],
              [-40.805869760529283, -7.411389662644755],
              [-40.807399460106524, -7.413499205533774],
              [-40.809203806393981, -7.41485723906147],
              [-40.810485541557483, -7.415936805790365],
              [-40.811885553708031, -7.417242383291796],
              [-40.812595081716481, -7.418495991525742],
              [-40.81374712973993, -7.418428283879075],
              [-40.814079007293238, -7.417386878264202],
              [-40.813941659688396, -7.41566071527748],
              [-40.813289360521729, -7.414117185792503],
              [-40.814254467616024, -7.413127747398287],
              [-40.815337853787995, -7.412255608413904],
              [-40.816371622975076, -7.411781169054803],
              [-40.816936214853229, -7.41080220726684],
              [-40.816695887009352, -7.410224763455648],
              [-40.81674166145033, -7.409311157854175],
              [-40.817535125785717, -7.408268331630692],
              [-40.817992894934079, -7.407859642408177],
              [-40.818164537738134, -7.407858688556006],
              [-40.818092058198097, -7.406710948230467],
              [-40.818427753580558, -7.40584167360932],
              [-40.818946523973239, -7.405830218106415],
              [-40.819751467416339, -7.40576397816206],
              [-40.819865903664166, -7.405709122578797],
              [-40.81990785932642, -7.404560893351051],
              [-40.819152556115988, -7.403930992421516],
              [-40.817752566721389, -7.402914848224692],
              [-40.816810323564916, -7.401381818440796],
              [-40.815761280686949, -7.400409539278161],
              [-40.814716055293907, -7.400025205668364],
              [-40.813564008412754, -7.399921246324746],
              [-40.81252642059146, -7.399988488071097],
              [-40.811374373514944, -7.400120084345045],
              [-40.811027245979311, -7.400067161368306],
              [-40.809905722455547, -7.398073499141891],
              [-40.809726419185807, -7.397495590385729],
              [-40.80970735055736, -7.396004028125302],
              [-40.808890998817375, -7.395320250463754],
              [-40.807498645941891, -7.394819570251098],
              [-40.805812507632076, -7.393696137451689],
              [-40.805400560118855, -7.392956060455875],
              [-40.805850692514234, -7.392312352607268],
              [-40.807811454005503, -7.392114934387645],
              [-40.809245762346897, -7.391702008997843],
              [-40.812080084135829, -7.392242247170184],
              [-40.814380360262916, -7.392042943242448],
              [-40.815879535018219, -7.391792603951793],
              [-40.816455559056735, -7.391726805814368],
              [-40.817775454151381, -7.391369154005235],
              [-40.819095326396734, -7.390830336259407],
              [-40.819381429500631, -7.390711616192866],
              [-40.819553095447105, -7.390538995348065],
              [-40.819431021680359, -7.390078362950864],
              [-40.81901902702387, -7.38951044094381],
              [-40.819118213330491, -7.388307380087676],
              [-40.819747671281547, -7.387898273009442],
              [-40.820087162351079, -7.387372307377504],
              [-40.820197780962772, -7.386910720178421],
              [-40.819953634331199, -7.38610726669299],
              [-40.817702973284597, -7.385790639691795],
              [-40.815520952755115, -7.386685665634316],
              [-40.81420107975805, -7.386925994132635],
              [-40.812808704925956, -7.386371437237671],
              [-40.812164019988721, -7.385686680725228],
              [-40.81277818114048, -7.383957189386725],
              [-40.815131845232784, -7.383351129889273],
              [-40.816852278828584, -7.382638737492901],
              [-40.817989077098254, -7.38119676789762],
              [-40.818664263307483, -7.380091006947172],
              [-40.81882067826637, -7.378824522512051],
              [-40.818233200019321, -7.378031058685753],
              [-40.816413602215682, -7.379828745628527],
              [-40.814918222336701, -7.380196386235231],
              [-40.813300792840302, -7.379922669506956],
              [-40.812179269311336, -7.377929008287675],
              [-40.812556921251932, -7.376028804757742],
              [-40.812381459759166, -7.375685960626225],
              [-40.809886630036331, -7.374620223826422],
              [-40.807395641279648, -7.373563566977279],
              [-40.806293187195323, -7.373287475689536],
              [-40.806007084234082, -7.373234552764397],
              [-40.805370036718791, -7.373183050541453],
              [-40.804149326832999, -7.372455384808757],
              [-40.802318274347627, -7.36913565679918],
              [-40.801315005992819, -7.367141505687033],
              [-40.801475216683407, -7.366164477558824],
              [-40.802955322376619, -7.364937573986051],
              [-40.803352067186779, -7.364239942411356],
              [-40.803504641356348, -7.362747449305297],
              [-40.8019787606421, -7.360755695750788],
              [-40.798328088252703, -7.359359524949032],
              [-40.795901942529376, -7.358926552496313],
              [-40.794631640608031, -7.358660473014483],
              [-40.793716126511967, -7.35924269083957],
              [-40.792976072954119, -7.360113385113782],
              [-40.794158621910739, -7.362287771309888],
              [-40.79457825204419, -7.363425522970013],
              [-40.794860536706061, -7.367501997250653],
              [-40.796321551198474, -7.36909654008639],
              [-40.797908479387061, -7.371205594004298],
              [-40.797580397484396, -7.372644698279818],
              [-40.795978240881901, -7.373582169451501],
              [-40.794944448744047, -7.374002243131234],
              [-40.793971704143388, -7.374467160597827],
              [-40.79158751598294, -7.373003282231709],
              [-40.790748276868101, -7.370547077970633],
              [-40.788676897106207, -7.370799815778344],
              [-40.787456187274707, -7.370008726420456],
              [-40.786548286257769, -7.366802945509951],
              [-40.786491079753731, -7.366685646152124],
              [-40.785617498404598, -7.366010924397594],
              [-40.784633321408116, -7.365843076573316],
              [-40.78342786361069, -7.366091996117651],
              [-40.782634422185964, -7.367252612643365],
              [-40.781379369024918, -7.368360306099828],
              [-40.780398988808102, -7.368427570896866],
              [-40.77769817324328, -7.368917726791112],
              [-40.775745047324115, -7.369576242311759],
              [-40.773788126723126, -7.369882367900271],
              [-40.772285133622319, -7.369382177601876],
              [-40.771220816606068, -7.367496758807349],
              [-40.771831182821828, -7.36571290079563],
              [-40.772658967334941, -7.362942956759638],
              [-40.773040437145568, -7.361160542551552],
              [-40.773486751086317, -7.35988353442589],
              [-40.773597414598086, -7.359539759537891],
              [-40.773749965863097, -7.357929477129987],
              [-40.773502023584101, -7.356728324987668],
              [-40.772742878444753, -7.356044058386331],
              [-40.771236090556343, -7.35531736941982],
              [-40.769618660013968, -7.35510709932622],
              [-40.767776153661409, -7.355358370333863],
              [-40.766460076354662, -7.3559510887666],
              [-40.765018108403282, -7.355965849494203],
              [-40.763850809386859, -7.354948774515208],
              [-40.763656257070842, -7.35334040081338],
              [-40.76444210696981, -7.351664297315888],
              [-40.763843196473324, -7.350057344274185],
              [-40.762454638619232, -7.349502321397681],
              [-40.761699335214715, -7.349107510099255],
              [-40.760535832888358, -7.34843372110652],
              [-40.759833940439322, -7.347640745194115],
              [-40.760398509410685, -7.346535426159033],
              [-40.760219229988401, -7.346138705345322],
              [-40.759170187146857, -7.345120652472347],
              [-40.759910239558799, -7.344421623191719],
              [-40.761283523517029, -7.343765482178526],
              [-40.762778879423486, -7.343352555834745],
              [-40.762881884322823, -7.342602002334113],
              [-40.761436120778278, -7.342155199775839],
              [-40.76097453298997, -7.342166166332517],
              [-40.760100951737449, -7.341374145274729],
              [-40.75882685399575, -7.340926875860509],
              [-40.757205605578513, -7.340598794460456],
              [-40.7563434785841, -7.340611227680012],
              [-40.755824685105694, -7.340730902550397],
              [-40.754726049947116, -7.340518211900413],
              [-40.754039407453675, -7.340864407300335],
              [-40.753295536307462, -7.341273981280367],
              [-40.752421978055317, -7.340481983223462],
              [-40.752231242375672, -7.339279853139918],
              [-40.752044326527439, -7.338249902046657],
              [-40.751868840944709, -7.338015767280368],
              [-40.751754404787647, -7.337961890503563],
              [-40.751464483854754, -7.337908478580098],
              [-40.750945713562459, -7.337802144780371],
              [-40.749553313832919, -7.337129332597676],
              [-40.749316804809794, -7.336732612738205],
              [-40.749648682349864, -7.335691695135494],
              [-40.749923353237328, -7.334713665115845],
              [-40.749679205980904, -7.333449089357413],
              [-40.749091726688349, -7.332709967225652],
              [-40.74774896908793, -7.331458291491705],
              [-40.747336973526657, -7.330772604189686],
              [-40.747562051379894, -7.330256674410535],
              [-40.749282485772873, -7.329770779679364],
              [-40.750838866924255, -7.329755506044044],
              [-40.752624144627887, -7.329558577665281],
              [-40.754291144483638, -7.328964438527142],
              [-40.75520670638759, -7.328608231373229],
              [-40.757335315838716, -7.328238216294041],
              [-40.758716236452834, -7.327934464212477],
              [-40.759341829565393, -7.327353202145272],
              [-40.759505882082415, -7.326547816018906],
              [-40.759093888378928, -7.32603426070367],
              [-40.757243743594252, -7.325661848380363],
              [-40.755118975027472, -7.326140128826069],
              [-40.753104800726625, -7.326573101628806],
              [-40.751315705242646, -7.326534940388535],
              [-40.749862304360555, -7.325347153189591],
              [-40.748351674506665, -7.324611384283786],
              [-40.746738062799679, -7.324572291193204],
              [-40.744540790394176, -7.324255665258669],
              [-40.742232900761216, -7.323939038233938],
              [-40.739688524016138, -7.323333933714212],
              [-40.737838356242754, -7.322952463446271],
              [-40.735992054205632, -7.322859958480461],
              [-40.734958262270034, -7.323044478548298],
              [-40.735084153634368, -7.323966698221573],
              [-40.736831292279881, -7.325379098335813],
              [-40.737353905396034, -7.325666621070808],
              [-40.737487410336087, -7.327040394092653],
              [-40.737735376380662, -7.328531466517926],
              [-40.73751793518467, -7.329400275225475],
              [-40.736415480247565, -7.328952517958606],
              [-40.735831842839573, -7.328321662194531],
              [-40.734672158294579, -7.327882961827798],
              [-40.733691777223669, -7.327778536639998],
              [-40.733413311602327, -7.328466623361122],
              [-40.733771894157279, -7.329613408903418],
              [-40.734305961584006, -7.330642428282955],
              [-40.734141909208795, -7.331275659420896],
              [-40.732707600733036, -7.331869331751363],
              [-40.732307039334763, -7.332105865030839],
              [-40.731105421191415, -7.332752435240266],
              [-40.730289069355607, -7.332185957906492],
              [-40.729766458329081, -7.331790168808516],
              [-40.729526129481556, -7.331212259014026],
              [-40.729175184301681, -7.33076161437971],
              [-40.728412243375452, -7.329851338114296],
              [-40.727885812657902, -7.329111774037587],
              [-40.728103252949822, -7.328125689021447],
              [-40.72723349047935, -7.327568244591726],
              [-40.726817700945006, -7.32688253428897],
              [-40.726516324042478, -7.325672347168223],
              [-40.726489618832019, -7.323719222647311],
              [-40.726478186757838, -7.32268829270876],
              [-40.726283632532272, -7.320962153714659],
              [-40.725925049686843, -7.320168199097078],
              [-40.724292369295995, -7.318691446459341],
              [-40.723708709791367, -7.318178356004126],
              [-40.723537043033978, -7.318124479181159],
              [-40.722430793450286, -7.317279046256394],
              [-40.721141422759914, -7.315683548600339],
              [-40.71898610620152, -7.313982671858501],
              [-40.717708190675346, -7.313309370780952],
              [-40.716659124692818, -7.31246347290742],
              [-40.715442257555424, -7.312016181548839],
              [-40.714809003064012, -7.311909847658196],
              [-40.713897307574989, -7.312953162767095],
              [-40.713740890770133, -7.314048004189255],
              [-40.715327796731387, -7.316437897406721],
              [-40.716098373945051, -7.318153070932944],
              [-40.716575211675796, -7.319299414570284],
              [-40.71712071222219, -7.321304997203148],
              [-40.717605186290918, -7.323192790480559],
              [-40.717616641454207, -7.324114988058573],
              [-40.71733433309074, -7.324522698426444],
              [-40.715591035999125, -7.323625274286058],
              [-40.714015562307715, -7.322030731389599],
              [-40.712955064118219, -7.320316978617219],
              [-40.711955613405983, -7.318720526204521],
              [-40.71108205491813, -7.318217472454124],
              [-40.710567056416487, -7.318336681330988],
              [-40.710132174709464, -7.320463359447393],
              [-40.710437369541204, -7.32172793524762],
              [-40.711387227034841, -7.324012006570123],
              [-40.711574143973337, -7.324933715293937],
              [-40.711520731967887, -7.325394836534232],
              [-40.711177422267724, -7.325513557554154],
              [-40.710189405579328, -7.325010967706287],
              [-40.709079336410291, -7.323695379547758],
              [-40.708491857022764, -7.32307358173011],
              [-40.707625912341605, -7.322742168974364],
              [-40.706584506699535, -7.322637744738003],
              [-40.706237379307879, -7.322413155777285],
              [-40.70554691912762, -7.322415554198632],
              [-40.705028125556154, -7.322652529378411],
              [-40.70486789187332, -7.323630047488776],
              [-40.704177430641145, -7.323695845536538],
              [-40.70325046143077, -7.323310580048292],
              [-40.70271641700436, -7.322281560673932],
              [-40.702594343471603, -7.321531960990432],
              [-40.702811784613885, -7.320726109915903],
              [-40.703898965692048, -7.319908337622461],
              [-40.704169816883784, -7.318587020628577],
              [-40.703578519856201, -7.317558466205524],
              [-40.703292417949562, -7.317442120657257],
              [-40.702529476927495, -7.316649145706273],
              [-40.701888609008343, -7.315910512535023],
              [-40.700389434414504, -7.315988253852388],
              [-40.699634131064933, -7.315529996935842],
              [-40.699039039485868, -7.31398600054827],
              [-40.698047202157682, -7.313076679097106],
              [-40.697410153738119, -7.31291646952117],
              [-40.696544208872361, -7.312811065435398],
              [-40.694984007942239, -7.31259984140657],
              [-40.692096276413984, -7.312230758224642],
              [-40.691520252524406, -7.312124424381195],
              [-40.690810724462558, -7.31092471487335],
              [-40.691039596101696, -7.310634305771876],
              [-40.692359492271379, -7.310159377648229],
              [-40.693221618259862, -7.310146990424733],
              [-40.695125151074052, -7.310013461765118],
              [-40.696147488884627, -7.308851890445439],
              [-40.696475546770777, -7.307413275192284],
              [-40.698665181551732, -7.307323635506841],
              [-40.699821048297892, -7.307545360143045],
              [-40.701503321770055, -7.308497591903623],
              [-40.702502771572391, -7.309985335951679],
              [-40.703719661715105, -7.310424035359655],
              [-40.705150175300133, -7.309722120663487],
              [-40.706420478492298, -7.309645379152464],
              [-40.708095114485282, -7.310027758266142],
              [-40.709998670206438, -7.310011552915366],
              [-40.711257539997604, -7.309301558126348],
              [-40.711238447396973, -7.307755164222494],
              [-40.710307660455584, -7.307071829489514],
              [-40.709079336961295, -7.305936987174635],
              [-40.707961632397684, -7.303942836014507],
              [-40.707130006607436, -7.30222812844178],
              [-40.706195423062489, -7.301092309373939],
              [-40.704875528802035, -7.301675946858039],
              [-40.704139294179292, -7.302610087752127],
              [-40.704211773549062, -7.30398432671186],
              [-40.704398690445593, -7.304959935114477],
              [-40.703193254571268, -7.305317098026361],
              [-40.701861881182452, -7.304933718199983],
              [-40.700702243603132, -7.304539837575754],
              [-40.699595970116874, -7.303576639350763],
              [-40.699409054405045, -7.302374998281164],
              [-40.699405234616577, -7.302148989610225],
              [-40.69910770016704, -7.301571056774314],
              [-40.698760549863664, -7.301238224453228],
              [-40.698623203190074, -7.299565939198909],
              [-40.69861940749756, -7.2992221642223],
              [-40.698554564327317, -7.298761042887693],
              [-40.697669573908904, -7.297046824272148],
              [-40.697375834233107, -7.296713038000596],
              [-40.695472278514288, -7.296729242354459],
              [-40.692611252246444, -7.298258942005297],
              [-40.691409635066279, -7.298959879893845],
              [-40.690597102056792, -7.298574125506153],
              [-40.691211262995623, -7.297070665870695],
              [-40.692470109687115, -7.296478413387928],
              [-40.693606906977131, -7.294973044189325],
              [-40.693767117656257, -7.293996016080334],
              [-40.69519381359742, -7.292887368778588],
              [-40.695884295784467, -7.292875912408256],
              [-40.696502253600222, -7.291490219081417],
              [-40.697417790497049, -7.291134034926774],
              [-40.699107700629916, -7.292438656610619],
              [-40.700858635107643, -7.294023653691981],
              [-40.702132755844602, -7.294470945092719],
              [-40.703631908158705, -7.294736534748083],
              [-40.705016669500523, -7.29472223996019],
              [-40.705131105746212, -7.294667384376734],
              [-40.703933284060923, -7.291055337694512],
              [-40.702708780486589, -7.289983895014405],
              [-40.701018870492796, -7.288507607342936],
              [-40.699908779171679, -7.287372254133861],
              [-40.699206886727794, -7.286570197292033],
              [-40.698394352576898, -7.286356108893425],
              [-40.697131688007424, -7.28683103806584],
              [-40.696754036094084, -7.28873124155398],
              [-40.695491370664897, -7.289034504738365],
              [-40.693580201375738, -7.288589610806695],
              [-40.692748599767491, -7.286648894574456],
              [-40.691226514023782, -7.284656187088247],
              [-40.689353504869601, -7.282493257321965],
              [-40.688075589440331, -7.281702166949183],
              [-40.686912063256464, -7.280856733990305],
              [-40.685985116861282, -7.28069698817928],
              [-40.684249432392335, -7.280197263716641],
              [-40.682746438254725, -7.279750950128198],
              [-40.681361699908955, -7.27977383686811],
              [-40.680739902498942, -7.280698431908481],
              [-40.680762789982985, -7.28236212611276],
              [-40.680366068185158, -7.283060200672396],
              [-40.679393323505693, -7.283642417447227],
              [-40.67767289008416, -7.284182212864385],
              [-40.676631483400605, -7.284131665316028],
              [-40.675704490330723, -7.283574709848248],
              [-40.67552139230957, -7.282716401762971],
              [-40.676013504612406, -7.280580642763124],
              [-40.677039637014353, -7.279880636726667],
              [-40.677154096266776, -7.279816748196537],
              [-40.677318149637088, -7.279183028068657],
              [-40.677489791718557, -7.278838765239149],
              [-40.677604227963414, -7.278784398652843],
              [-40.677718664216016, -7.278720509122753],
              [-40.678580791203132, -7.278708588893378],
              [-40.678809663789238, -7.278481601418457],
              [-40.678805845051748, -7.278192170122946],
              [-40.678565517429838, -7.277333861991591],
              [-40.67723037121668, -7.276769293238281],
              [-40.674243477396459, -7.277612326838546],
              [-40.673217344943325, -7.278375757500657],
              [-40.672122503537558, -7.278442975214853],
              [-40.671370995874149, -7.278337130229033],
              [-40.670779720982736, -7.277136932830869],
              [-40.671916517942634, -7.276029239290154],
              [-40.672946468456814, -7.275157566269912],
              [-40.673053292181834, -7.274578725772254],
              [-40.671660916361773, -7.274023166920379],
              [-40.670856018975435, -7.274035089196755],
              [-40.669978642757791, -7.273233521214939],
              [-40.669688722010754, -7.272954100628627],
              [-40.669517056165134, -7.273009421162833],
              [-40.669288182712187, -7.273073310600028],
              [-40.668937213347064, -7.272848721640668],
              [-40.668586267121924, -7.272452000697114],
              [-40.667880557029321, -7.271251803207798],
              [-40.667407537127254, -7.270277126576565],
              [-40.667232053352947, -7.27027761543133],
              [-40.665950318135394, -7.26926100539195],
              [-40.665313246812723, -7.268983005516096],
              [-40.664401550474153, -7.26985418863455],
              [-40.664149767032711, -7.272558800466931],
              [-40.664123061305823, -7.274909624570757],
              [-40.663501262654705, -7.276132709847681],
              [-40.662539974286368, -7.277465481201628],
              [-40.66151000005312, -7.277993797247394],
              [-40.659430982795456, -7.277730116115592],
              [-40.65842005363087, -7.275554776134125],
              [-40.657474038872898, -7.273550636195947],
              [-40.657390150231898, -7.271371966988331],
              [-40.658339984377385, -7.269180398695735],
              [-40.658618450234265, -7.268193358748299],
              [-40.658019538762858, -7.266541120016591],
              [-40.657252780191918, -7.265223599173376],
              [-40.65700483795279, -7.263958558441091],
              [-40.657668592182624, -7.261659725551513],
              [-40.660312178394015, -7.260818110662013],
              [-40.661750328400842, -7.260749449232321],
              [-40.662955763275711, -7.260383228370886],
              [-40.664111630070536, -7.260542020369766],
              [-40.665740491812969, -7.261621098319007],
              [-40.668471831216848, -7.263482675543292],
              [-40.670749197504577, -7.265698527310184],
              [-40.673705590756249, -7.267044174765299],
              [-40.675971547734363, -7.268509496955388],
              [-40.678535017260018, -7.270434962672748],
              [-40.681090871074623, -7.272070996093312],
              [-40.682254351207128, -7.272980340672414],
              [-40.683772594590607, -7.274222494576371],
              [-40.685111559358326, -7.275302527291811],
              [-40.686034709728922, -7.275525206727766],
              [-40.686484842293204, -7.274655466224198],
              [-40.686061415560097, -7.273047559366252],
              [-40.68448976060079, -7.271741982793121],
              [-40.683089771391209, -7.270499340988745],
              [-40.681167147022009, -7.269023541136705],
              [-40.680858157532818, -7.267361267694843],
              [-40.682242895916026, -7.267292605223127],
              [-40.683467422439293, -7.268427516519719],
              [-40.684810204953038, -7.269787901582395],
              [-40.686091940032789, -7.2709766666088],
              [-40.687266874526991, -7.272572630146678],
              [-40.687804737512558, -7.274117114471821],
              [-40.688109909211342, -7.275553356246956],
              [-40.688827074569097, -7.277557984004355],
              [-40.689582377927678, -7.278007161968365],
              [-40.690608534566437, -7.277018188635529],
              [-40.690585646317416, -7.275065063137921],
              [-40.690738243422956, -7.273626912745364],
              [-40.691768241659432, -7.273098595698186],
              [-40.693500084584379, -7.273073776250662],
              [-40.69435839192402, -7.272663713366416],
              [-40.695167107285833, -7.2726517930933],
              [-40.695926229331128, -7.273453848978696],
              [-40.695071739589125, -7.274325009146281],
              [-40.694629244551443, -7.275709749619173],
              [-40.695273930211087, -7.27673828007637],
              [-40.696425954176519, -7.276724939090863],
              [-40.697005796040457, -7.27683127293477],
              [-40.697886991916668, -7.278021481631101],
              [-40.69870716130719, -7.279103400916881],
              [-40.700324591839944, -7.279314647985529],
              [-40.700843385078677, -7.279484405406098],
              [-40.701076076410196, -7.279537816280254],
              [-40.702399790273823, -7.27940619713223],
              [-40.702628662772405, -7.279287477018357],
              [-40.702666823670398, -7.277858849474066],
              [-40.702643937288983, -7.276068821011808],
              [-40.703029201771251, -7.274629715811781],
              [-40.703891329085224, -7.274219629930542],
              [-40.704639018014213, -7.274035577623209],
              [-40.706237379616717, -7.273098596447098],
              [-40.707095688053599, -7.272570721257183],
              [-40.708018838757745, -7.272386669092263],
              [-40.708941990131088, -7.272608882531515],
              [-40.709186136631651, -7.273584490976543],
              [-40.707870060109585, -7.274457583758922],
              [-40.707068981457525, -7.274758937331138],
              [-40.706439546474883, -7.275222433086539],
              [-40.705878796401088, -7.276436461471839],
              [-40.706004688911158, -7.277186037153918],
              [-40.706294608607323, -7.277528881367831],
              [-40.706309836661077, -7.278559344303842],
              [-40.706252629875898, -7.278785842921761],
              [-40.705974164207625, -7.279537328264189],
              [-40.705474439567638, -7.280922532690081],
              [-40.705600331128629, -7.281609197743578],
              [-40.706698943232247, -7.281885265009064],
              [-40.706698944091812, -7.282057420994009],
              [-40.705562170170786, -7.283110281861775],
              [-40.704875527446958, -7.283745910555605],
              [-40.70460085761183, -7.284669062889238],
              [-40.706927839975648, -7.286369450756984],
              [-40.707397040140108, -7.287054696098882],
              [-40.707995951557777, -7.288770333472023],
              [-40.708476607984927, -7.290260453078948],
              [-40.710647174553934, -7.293046135425717],
              [-40.712100574619782, -7.294008402937714],
              [-40.712688030773897, -7.29491916804821],
              [-40.713801939832074, -7.296343954558476],
              [-40.714267322303677, -7.296685377925554],
              [-40.714469512636349, -7.294551039669167],
              [-40.713805735233827, -7.292148269290174],
              [-40.715007375416675, -7.291447308400175],
              [-40.71657521273665, -7.292345663382147],
              [-40.717734896037946, -7.293082852981947],
              [-40.718947969580192, -7.293069023051472],
              [-40.719813890411785, -7.293219710868763],
              [-40.720794271474787, -7.293333192998593],
              [-40.721725060425435, -7.294007936780539],
              [-40.722549047788924, -7.295089879076131],
              [-40.722381200442705, -7.295723599205048],
              [-40.722159941609988, -7.29618516330491],
              [-40.722102710913262, -7.296302952564176],
              [-40.722575753814127, -7.297277606206524],
              [-40.722694008759682, -7.297557515653573],
              [-40.722705440881363, -7.298534078908641],
              [-40.72282751474846, -7.298877387985292],
              [-40.723983379402121, -7.299216436929879],
              [-40.724738706857806, -7.299548314590329],
              [-40.725371914350958, -7.299654158482952],
              [-40.727042755751633, -7.299575485308816],
              [-40.72733267577312, -7.299520164871146],
              [-40.729224777199917, -7.298744348984654],
              [-40.730365368907442, -7.297926110735191],
              [-40.730823137950011, -7.29763523482208],
              [-40.73121601591145, -7.29677455121304],
              [-40.731605147283013, -7.295444155368402],
              [-40.73211250935676, -7.294691716215019],
              [-40.73326453337112, -7.294623984552885],
              [-40.733905399574844, -7.295019284746247],
              [-40.734782800618525, -7.296037339463603],
              [-40.735656357917861, -7.296775506827042],
              [-40.736114126864777, -7.296601931222699],
              [-40.737495025334461, -7.296470335123612],
              [-40.737788764152192, -7.296631966410938],
              [-40.738845444472041, -7.298227952871016],
              [-40.73908195440054, -7.298742463033149],
              [-40.74042473604954, -7.29993979607567],
              [-40.742622008358985, -7.300374677306432],
              [-40.743598570606181, -7.300298379552394],
              [-40.74479257431296, -7.299028077008042],
              [-40.746165857355201, -7.298263226632797],
              [-40.747451433411804, -7.299451503682866],
              [-40.748092277431063, -7.300073301543661],
              [-40.74849663456645, -7.300125758564571],
              [-40.749187095802128, -7.300059959515307],
              [-40.749591452122544, -7.299886384867362],
              [-40.74970588927988, -7.299940262643438],
              [-40.750857936296349, -7.299872554981963],
              [-40.751777293032184, -7.299688012818729],
              [-40.752929294004737, -7.29967467183707],
              [-40.754214892105914, -7.300809070205863],
              [-40.755210525133528, -7.30206172564225],
              [-40.756312978933771, -7.30268161489199],
              [-40.757529847129035, -7.303065458625148],
              [-40.758685712697748, -7.30351321593242],
              [-40.760535833521587, -7.303840320516893],
              [-40.762443184998389, -7.30410402552149],
              [-40.761993028957882, -7.300661246423061],
              [-40.762103693660059, -7.30008189292441],
              [-40.763866083746571, -7.298393403303663],
              [-40.764957083427582, -7.298036240296542],
              [-40.765128750274869, -7.297981408759421],
              [-40.76419414377591, -7.29678265405502],
              [-40.765689499355922, -7.296767869361743],
              [-40.765803935698415, -7.296595737468571],
              [-40.766257886917842, -7.296123649618345],
              [-40.766883503073451, -7.295488043870843],
              [-40.767394683123001, -7.29473558171805],
              [-40.767726583703507, -7.293640297437535],
              [-40.767715105493352, -7.292772443538079],
              [-40.767589237029156, -7.291968012172012],
              [-40.766837728498182, -7.291690967190721],
              [-40.766547807608426, -7.291583678586121],
              [-40.766101493781861, -7.292733350452795],
              [-40.765414852281125, -7.293089070798451],
              [-40.764087320530997, -7.293102899634776],
              [-40.763118393569961, -7.293984095656053],
              [-40.76261485109783, -7.295188577153083],
              [-40.762103693233314, -7.295715007636488],
              [-40.761188155048458, -7.296414525770945],
              [-40.760440466158812, -7.29654423639738],
              [-40.758536932663105, -7.296271008453879],
              [-40.758132575475344, -7.296281974059604],
              [-40.757495527103025, -7.296058340857005],
              [-40.756446484114221, -7.295212418983108],
              [-40.754829054764208, -7.294766571290024],
              [-40.753097164828176, -7.294791367732092],
              [-40.752116806850438, -7.294569177241425],
              [-40.749972922490862, -7.293736596397537],
              [-40.74874841877805, -7.292828229749777],
              [-40.746825793444764, -7.291298530193556],
              [-40.74531518686068, -7.290228508266011],
              [-40.744037248380614, -7.289491807564954],
              [-40.742591483827304, -7.289054527963486],
              [-40.740745157743582, -7.289016390684468],
              [-40.73814355229834, -7.288529051437055],
              [-40.736644353749583, -7.288543836129151],
              [-40.734801869492671, -7.288686422788711],
              [-40.733069979804171, -7.288412705991309],
              [-40.73162419306805, -7.288201481058808],
              [-40.730186066094433, -7.288215777803688],
              [-40.728461836592849, -7.288873338524991],
              [-40.726626965914889, -7.289468431524114],
              [-40.725417688301015, -7.289536139140475],
              [-40.725127791316524, -7.289546126845563],
              [-40.725238432780515, -7.289256718641187],
              [-40.725921232680861, -7.288440366008012],
              [-40.726718514634882, -7.287804271405744],
              [-40.728328307838716, -7.287264476897251],
              [-40.73016699744548, -7.286724238577611],
              [-40.731486892524664, -7.286366610759467],
              [-40.732177351855249, -7.286183022403327],
              [-40.732688509806067, -7.285547860562278],
              [-40.734298325730087, -7.285351886027307],
              [-40.735801318919613, -7.285744322938186],
              [-40.738692869297637, -7.286284584108623],
              [-40.740134815157582, -7.286378531728944],
              [-40.741977323319716, -7.286353269377818],
              [-40.743522272392006, -7.285307555796524],
              [-40.743796920229137, -7.284384380459205],
              [-40.742969136317228, -7.282777916250603],
              [-40.742614371537066, -7.281866196333924],
              [-40.743003477671564, -7.280824789786726],
              [-40.742995841049343, -7.280427115123718],
              [-40.742812743027386, -7.279568807032305],
              [-40.742808925430211, -7.279107708746243],
              [-40.74256859666707, -7.278421531594157],
              [-40.741237269366692, -7.277920363464749],
              [-40.738868331068588, -7.277712467763553],
              [-40.737426385348925, -7.277446389159206],
              [-40.735168065744396, -7.276668663906709],
              [-40.733142460236969, -7.276233315825897],
              [-40.731288520723758, -7.275562414273414],
              [-40.730819319697545, -7.27470505994563],
              [-40.729999149252365, -7.273686517282958],
              [-40.729354487405992, -7.272884018489718],
              [-40.728995881512077, -7.272143965560953],
              [-40.728988267080744, -7.271511201284923],
              [-40.729152296643633, -7.270642391539879],
              [-40.729838915133158, -7.270296219138266],
              [-40.731444936458793, -7.269639123316199],
              [-40.733169165628738, -7.269388317194933],
              [-40.734550086142313, -7.269202355412637],
              [-40.735183339916112, -7.268964868326792],
              [-40.736217108991767, -7.268608217268666],
              [-40.736899955603675, -7.268144721555268],
              [-40.736770245495229, -7.266933557589422],
              [-40.736064535257526, -7.265905492076822],
              [-40.73535880106413, -7.264823060884976],
              [-40.735122291273285, -7.264136883738228],
              [-40.735053629451457, -7.2632781337452],
              [-40.735038379536533, -7.262075514822883],
              [-40.73508797170571, -7.261442284596392],
              [-40.733443836947728, -7.259269808071519],
              [-40.733146278582588, -7.258583631876038],
              [-40.733134846456501, -7.257607069624893],
              [-40.733184439713327, -7.256865595037155],
              [-40.733172985307753, -7.256232830758969],
              [-40.732356632605786, -7.255494197452709],
              [-40.731311407448459, -7.25482043157863],
              [-40.729613860915549, -7.252828212951494],
              [-40.728278714927939, -7.251974677903504],
              [-40.726829133401381, -7.251536931311001],
              [-40.725497806196728, -7.250917997883075],
              [-40.724162660018543, -7.250299528449617],
              [-40.722941951174455, -7.249517519073732],
              [-40.722007344576447, -7.248436065696109],
              [-40.721122377962523, -7.246947367761481],
              [-40.721866202198413, -7.246419981474631],
              [-40.724109248702426, -7.246221633460194],
              [-40.726359931962953, -7.246258327065473],
              [-40.728561022828799, -7.247208208244493],
              [-40.730643834850042, -7.247752264721901],
              [-40.731628035661117, -7.248146144195744],
              [-40.733947380607383, -7.249213301780355],
              [-40.734817097224678, -7.249598590212582],
              [-40.736323932301943, -7.2500992714805],
              [-40.737418772620657, -7.250149330072157],
              [-40.737994772749758, -7.249966230618443],
              [-40.737750626289788, -7.248936745491473],
              [-40.736816019551078, -7.248026935099817],
              [-40.735869981589353, -7.24625832776398],
              [-40.734988785896114, -7.244833076456358],
              [-40.73452338046576, -7.244436820416709],
              [-40.734229664793496, -7.24409444219832],
              [-40.733188234285691, -7.243818350983926],
              [-40.732024755091849, -7.242972919028985],
              [-40.731265633849929, -7.24240595175898],
              [-40.730735384289979, -7.241494721705016],
              [-40.73065910908371, -7.23993975981581],
              [-40.730647653911625, -7.239017562246569],
              [-40.730052561126421, -7.237708633486969],
              [-40.72923620947391, -7.236907066555696],
              [-40.728694527740757, -7.235073615934524],
              [-40.72867164143193, -7.233183912065086],
              [-40.728648753946516, -7.231511650913144],
              [-40.728747939280169, -7.230245166465905],
              [-40.729075999016636, -7.228978216206466],
              [-40.729232391038721, -7.227603000447335],
              [-40.728988267532394, -7.22662739100706],
              [-40.728175734756782, -7.225943613386096],
              [-40.728046024496116, -7.224913173362093],
              [-40.728255828386281, -7.223239467408343],
              [-40.729514699028222, -7.222710661544768],
              [-40.731193154004835, -7.223093062642529],
              [-40.732120123311752, -7.223369642758446],
              [-40.733741371548724, -7.223924176794694],
              [-40.734565360688777, -7.225295109370527],
              [-40.734981149953747, -7.226324594631977],
              [-40.735454168819572, -7.227353614942535],
              [-40.736556646667978, -7.227919160496127],
              [-40.736915206383429, -7.228885223086206],
              [-40.737277606874173, -7.23009543223923],
              [-40.738326673912212, -7.230886988417044],
              [-40.739368057238394, -7.231389087296466],
              [-40.740016560835521, -7.23247200843241],
              [-40.741355524756393, -7.233379909159037],
              [-40.742618191147344, -7.233131012647704],
              [-40.743480316464037, -7.232720904763483],
              [-40.744803983290979, -7.232643185291668],
              [-40.745906461184106, -7.233154343167854],
              [-40.74609337790541, -7.23435647222878],
              [-40.746276476071174, -7.235043114327037],
              [-40.746810543371566, -7.236243311680476],
              [-40.746764769898952, -7.237219874884115],
              [-40.746539692138811, -7.237627562297726],
              [-40.747020348627352, -7.239054746269911],
              [-40.747428524406779, -7.239513934892203],
              [-40.747951112534615, -7.239792400672846],
              [-40.748302104668497, -7.240306444925544],
              [-40.748550046824128, -7.241679729028147],
              [-40.749770756804246, -7.242299130363732],
              [-40.750808343575756, -7.242285788282214],
              [-40.751735337788006, -7.242680134707317],
              [-40.752956022629199, -7.24347122403106],
              [-40.753875379225178, -7.243458813848489],
              [-40.754680275803217, -7.243220394905767],
              [-40.756183269101918, -7.243486009556583],
              [-40.756602877199597, -7.244686671817726],
              [-40.756511328200133, -7.246703686852666],
              [-40.757232311542033, -7.248707849622112],
              [-40.758514046590051, -7.249950957332939],
              [-40.759673731180456, -7.250343882956637],
              [-40.76007047478867, -7.249881364004392],
              [-40.76047099018686, -7.249644831723151],
              [-40.759791985076497, -7.250687192028891],
              [-40.760535834315945, -7.250159829740126],
              [-40.760760911121281, -7.249697777645991],
              [-40.760921122613993, -7.248946757202601],
              [-40.761611581895018, -7.248826593470352],
              [-40.762714036892099, -7.249210927098336],
              [-40.763591413980748, -7.250184138073156],
              [-40.764968515577287, -7.249934752659082],
              [-40.766685131549089, -7.248770783910446],
              [-40.768226262752137, -7.247598247069842],
              [-40.768905290723289, -7.246727551751681],
              [-40.769580500044093, -7.245459182774263],
              [-40.769401220712531, -7.244944648661663],
              [-40.768470432803994, -7.244206970255735],
              [-40.76823010503756, -7.243520817105503],
              [-40.767867702636337, -7.242201876586426],
              [-40.76681868373548, -7.241238654415012],
              [-40.765197411450401, -7.240738464051211],
              [-40.7638660850482, -7.240355084233701],
              [-40.763099325466079, -7.23903758638022],
              [-40.762561439610238, -7.237321925059841],
              [-40.762065533070349, -7.234855243202061],
              [-40.761237749330284, -7.23302321233484],
              [-40.760825755613688, -7.232518247979285],
              [-40.760646475376454, -7.231886461559737],
              [-40.76156580806898, -7.23175628607047],
              [-40.762729287216608, -7.23266465365496],
              [-40.763484614583597, -7.233113830617187],
              [-40.76496472053195, -7.23154408406935],
              [-40.76483501122793, -7.230568009719658],
              [-40.76356470826078, -7.23035578394432],
              [-40.762523301887938, -7.229908050742725],
              [-40.7621074894396, -7.229104573144627],
              [-40.761863365976694, -7.228075088018158],
              [-40.760642657271895, -7.227112331709455],
              [-40.758903153899183, -7.226503897892426],
              [-40.757346772788132, -7.226464805865883],
              [-40.756271000168852, -7.227852384813403],
              [-40.756225225697001, -7.228828949017032],
              [-40.755542402982243, -7.229419290983786],
              [-40.754390354869116, -7.229604764959083],
              [-40.7539402234001, -7.230356738160493],
              [-40.753490090791495, -7.231280868346605],
              [-40.752627964517586, -7.231637053549228],
              [-40.751075378242163, -7.231769626512551],
              [-40.750323892622383, -7.231600335903621],
              [-40.750201819840029, -7.231148783468179],
              [-40.750190340762472, -7.230109262589963],
              [-40.750060677457988, -7.229133189242182],
              [-40.750003447944927, -7.229024945834342],
              [-40.750342961950885, -7.228562403836839],
              [-40.750564221015921, -7.227810917448009],
              [-40.750556584442762, -7.22734979616165],
              [-40.75037348646633, -7.226428531447776],
              [-40.750358212407711, -7.225397624516335],
              [-40.750053041604701, -7.224078707049835],
              [-40.749007816398951, -7.223458817862734],
              [-40.748019797750189, -7.222893271401366],
              [-40.747840518417796, -7.222378296294609],
              [-40.747836699722335, -7.222034963316987],
              [-40.747829063236743, -7.221465155672335],
              [-40.747825267636365, -7.221004057389509],
              [-40.747821448757897, -7.220886757078392],
              [-40.747932067264713, -7.220542446198942],
              [-40.747981660525973, -7.219791915666742],
              [-40.747630714251535, -7.219449561403392],
              [-40.746818181232683, -7.219063783023826],
              [-40.745776751773612, -7.218724269186791],
              [-40.744788756168496, -7.218104403047108],
              [-40.743808375042605, -7.218062912501153],
              [-40.742198583167784, -7.218195463420662],
              [-40.740871050419329, -7.218209782265435],
              [-40.740348438203313, -7.218040025845625],
              [-40.740050903922295, -7.217245117296229],
              [-40.739749550857553, -7.216215631128073],
              [-40.739333737546986, -7.215240977544366],
              [-40.739390944338226, -7.215005422980523],
              [-40.74105037637807, -7.214239617837357],
              [-40.741916274338834, -7.214236288554988],
              [-40.742606735574263, -7.214170490499887],
              [-40.743804580877757, -7.213352229293938],
              [-40.744090660072843, -7.213115719920122],
              [-40.74431955561527, -7.212943099123248],
              [-40.744376762311298, -7.21282533286428],
              [-40.744426355337971, -7.212364210625283],
              [-40.744414923250744, -7.211333304699491],
              [-40.744002929730279, -7.210584660785361],
              [-40.743407835991093, -7.209212331404494],
              [-40.743205646161044, -7.207034127096581],
              [-40.742263425929593, -7.205554975067725],
              [-40.740695588784149, -7.204421530495335],
              [-40.739986036885853, -7.202995790339633],
              [-40.739562610904258, -7.201677337781386],
              [-40.739478698203875, -7.199552546256531],
              [-40.739814393467711, -7.198801013963017],
              [-40.74073754508747, -7.198725227152953],
              [-40.742011642836808, -7.199172495537616],
              [-40.742324450897428, -7.20135020993477],
              [-40.743339174784765, -7.203868883885286],
              [-40.74502908570711, -7.205462936837755],
              [-40.746604558573729, -7.206876290765421],
              [-40.748191464615218, -7.209212331258326],
              [-40.748565298335407, -7.211281314350926],
              [-40.749389286390837, -7.212760955284409],
              [-40.751472122361619, -7.213377026328486],
              [-40.753673189472224, -7.214037451206631],
              [-40.755458466942621, -7.214130421097588],
              [-40.755916236036022, -7.21377565655964],
              [-40.756457917393, -7.211133513583979],
              [-40.754016521841478, -7.209379689964086],
              [-40.75214348951647, -7.207388892201791],
              [-40.751025784741934, -7.205620796734963],
              [-40.750316256843085, -7.204195032577383],
              [-40.749957673183083, -7.203156490417591],
              [-40.750926599075747, -7.202348239967482],
              [-40.751193632658577, -7.200556768754997],
              [-40.75046885374757, -7.198037142042492],
              [-40.749473198748227, -7.196729655958729],
              [-40.749057409434904, -7.195755002377213],
              [-40.749217620054168, -7.194840906901629],
              [-40.749618159450513, -7.194604398619681],
              [-40.750606177108658, -7.195161353126696],
              [-40.752299906730499, -7.197098740053922],
              [-40.754123321754939, -7.199894435021402],
              [-40.756286273834263, -7.202164653365238],
              [-40.757869361087678, -7.204274661189241],
              [-40.759097707230829, -7.20587015876453],
              [-40.760394691434996, -7.208035021036804],
              [-40.762267723666163, -7.210143585106678],
              [-40.763953816040946, -7.211213141156345],
              [-40.764831192046969, -7.212294594484362],
              [-40.766116744980359, -7.213655026496448],
              [-40.766349483262573, -7.213771837995128],
              [-40.76634948316768, -7.213889184302993],
              [-40.766063357023398, -7.214062248051414],
              [-40.766067152814145, -7.214288256721411],
              [-40.765796323678494, -7.215555230030081],
              [-40.765746730365976, -7.216369184189248],
              [-40.76695980287213, -7.216409719928835],
              [-40.767474777515979, -7.216118378062584],
              [-40.768268242781389, -7.215129870532764],
              [-40.769477496453739, -7.214998274284319],
              [-40.770587587654177, -7.216305294471441],
              [-40.770671500438084, -7.218320889652404],
              [-40.770751617532866, -7.21998455990612],
              [-40.770885122489645, -7.221358332912809],
              [-40.771644243641184, -7.22204260049077],
              [-40.773429545210448, -7.222017338081289],
              [-40.774806646939979, -7.221605343622531],
              [-40.77542846830837, -7.220734625258716],
              [-40.775241528496778, -7.219641716552135],
              [-40.774593024939527, -7.21850496573178],
              [-40.775916716005376, -7.218137768965354],
              [-40.778503094269382, -7.21776630924308],
              [-40.779586456420297, -7.216894171259945],
              [-40.780322691130053, -7.215851345003067],
              [-40.780368489513236, -7.214983025160127],
              [-40.780009905802601, -7.214007881620979],
              [-40.779193555275128, -7.213043705648112],
              [-40.778602280230132, -7.212015174236638],
              [-40.778869290670137, -7.210404891948229],
              [-40.77903713882921, -7.209997227487716],
              [-40.778560301073682, -7.208859941810462],
              [-40.777171767117721, -7.208413139326316],
              [-40.774810466467606, -7.208558099568167],
              [-40.772967958208831, -7.208692094292794],
              [-40.770892759660768, -7.20871784546836],
              [-40.770240437318805, -7.207345981034767],
              [-40.770167955846709, -7.206080451441497],
              [-40.769752167482608, -7.205169221481881],
              [-40.769107481670964, -7.204312333017524],
              [-40.768577232148502, -7.203347225283965],
              [-40.768272038341983, -7.202028284819336],
              [-40.767329817114856, -7.20054007584091],
              [-40.76586498575724, -7.1987734000855],
              [-40.76498379014059, -7.197239881424908],
              [-40.764857898623703, -7.196489816749461],
              [-40.765144001723975, -7.196371073680333],
              [-40.766814842038947, -7.196410188793732],
              [-40.768500934555448, -7.197307589855321],
              [-40.770236641864578, -7.198033346970041],
              [-40.772731448605256, -7.199099549687588],
              [-40.773654599944095, -7.19937610679912],
              [-40.774741803839802, -7.198784366165174],
              [-40.775024088426498, -7.198096281451584],
              [-40.773910178343854, -7.196671959958044],
              [-40.771812092252674, -7.195088872610105],
              [-40.770179411830007, -7.193612096010325],
              [-40.76942028985826, -7.192701819771],
              [-40.769302035726419, -7.192647918993959],
              [-40.768146170053761, -7.19231795101159],
              [-40.767390865829348, -7.191688050121329],
              [-40.767264974166288, -7.191118708379832],
              [-40.76893581475894, -7.190814002521188],
              [-40.770312917450774, -7.190446850794819],
              [-40.771155974783383, -7.188942902347209],
              [-40.770740162461735, -7.187976816713964],
              [-40.77074016265113, -7.187741751101123],
              [-40.77025953010866, -7.18636939881359],
              [-40.769080776073345, -7.184204093645507],
              [-40.768664963714663, -7.183283316745918],
              [-40.768302585165259, -7.182136508223468],
              [-40.768405566371705, -7.180987813086077],
              [-40.768802309977097, -7.180525271134655],
              [-40.768962521551657, -7.179665542337695],
              [-40.768539094842218, -7.178003291809406],
              [-40.768298767164687, -7.177199815359288],
              [-40.768745079984576, -7.176040619559931],
              [-40.769244805700986, -7.174546682779523],
              [-40.768939633846081, -7.173282107999769],
              [-40.767837180032579, -7.172662707772179],
              [-40.76742900401613, -7.172492461449949],
              [-40.766730930246027, -7.172042818544712],
              [-40.765689499963791, -7.171477272048588],
              [-40.765456832629447, -7.171423883177238],
              [-40.765109682231106, -7.171199271225015],
              [-40.763652463392596, -7.169957094390859],
              [-40.763518934340695, -7.168691588755816],
              [-40.763507502161296, -7.167769391191766],
              [-40.763557095461969, -7.166964494984122],
              [-40.763251900555282, -7.165763342832135],
              [-40.762260087202897, -7.164853998407362],
              [-40.762012122940732, -7.163598014625591],
              [-40.761993030322117, -7.162043052792908],
              [-40.76186336624211, -7.160777522162793],
              [-40.761962552566189, -7.159511503718946],
              [-40.76211894535377, -7.158416687309923],
              [-40.763030664781056, -7.157436793826644],
              [-40.76400722711827, -7.157252252697907],
              [-40.765452991727699, -7.157635631590615],
              [-40.76646007805342, -7.159693182245745],
              [-40.767879112730725, -7.162716330530131],
              [-40.770095476676531, -7.164706173556259],
              [-40.7716213574355, -7.16669841501655],
              [-40.773257879422623, -7.168690634566826],
              [-40.775703093056649, -7.170326667869287],
              [-40.777743972181746, -7.172371342436895],
              [-40.778972295566568, -7.173677408713378],
              [-40.780139593463474, -7.174875695577631],
              [-40.780658387804237, -7.174927687687043],
              [-40.78157010606084, -7.174164746921186],
              [-40.782031694033073, -7.173927748690172],
              [-40.783652942232351, -7.17454526234661],
              [-40.784541774379456, -7.176603301914818],
              [-40.785827326409091, -7.177854978560576],
              [-40.787341752894349, -7.179042788767585],
              [-40.788337383856906, -7.180404152534547],
              [-40.788875269994541, -7.181785537816317],
              [-40.789573343678384, -7.182343471085326],
              [-40.790149367708153, -7.182286729882779],
              [-40.790477449586703, -7.180839033687467],
              [-40.790233303023307, -7.17992684887324],
              [-40.790168458984787, -7.179294107554375],
              [-40.79108015623568, -7.178540199734574],
              [-40.791541742975468, -7.178592679795511],
              [-40.792361912525323, -7.179502467086393],
              [-40.793643647589334, -7.180745086784698],
              [-40.794986406220602, -7.181996808478886],
              [-40.795924831574844, -7.183367229148572],
              [-40.796340644897832, -7.184333291784051],
              [-40.798537893274762, -7.184713365307941],
              [-40.799289401812565, -7.184990876275088],
              [-40.79993408776722, -7.185675119757386],
              [-40.800288874602749, -7.186532961982939],
              [-40.800304102622128, -7.187617768596975],
              [-40.799739533804875, -7.18855142063644],
              [-40.797961893622471, -7.189263325008163],
              [-40.796020199123838, -7.190546037881093],
              [-40.794582048999189, -7.190732465624851],
              [-40.794418019631991, -7.191365719757892],
              [-40.794658348308147, -7.192169661209515],
              [-40.796043086527867, -7.192327008395531],
              [-40.796966237963929, -7.19248628819883],
              [-40.797774953329039, -7.192482934869254],
              [-40.799224557637707, -7.193209646749848],
              [-40.799922631409167, -7.193658824662694],
              [-40.800269782756864, -7.193946837243582],
              [-40.801143341166984, -7.194567191288374],
              [-40.801147159957928, -7.194793223958586],
              [-40.801089930261824, -7.194911014217907],
              [-40.801261596065608, -7.194910524358976],
              [-40.80206649269816, -7.194609171783807],
              [-40.803039260343802, -7.194080854682113],
              [-40.802840888250884, -7.192191593663334],
              [-40.803764039869996, -7.192124840799701],
              [-40.804340062864213, -7.192113386330725],
              [-40.804908450370931, -7.19153261221284],
              [-40.804832151010849, -7.190158839252612],
              [-40.804591846334183, -7.189354873800385],
              [-40.805686663808508, -7.189224233451377],
              [-40.806377124910959, -7.18932961238529],
              [-40.8074337813024, -7.190871698141815],
              [-40.810264306138549, -7.191411959233528],
              [-40.812347118366205, -7.191729541036935],
              [-40.814075190197045, -7.191885468508229],
              [-40.814593984539542, -7.19193743561933],
              [-40.814700806357195, -7.191240781812652],
              [-40.814632122572107, -7.190328107138443],
              [-40.814849561896445, -7.189287167450748],
              [-40.814956385757306, -7.188536636961952],
              [-40.814712239245495, -7.187561027518004],
              [-40.814128601624738, -7.187174318328774],
              [-40.813255020497145, -7.186201551362302],
              [-40.813064285751594, -7.185053810976881],
              [-40.812125882344944, -7.183746813929831],
              [-40.809981998913599, -7.182968598796062],
              [-40.808593465089373, -7.18235013032765],
              [-40.807388006108397, -7.182825058557919],
              [-40.806518266531853, -7.182376370505308],
              [-40.806384738569157, -7.181002597500549],
              [-40.80637712418298, -7.180306432600474],
              [-40.805499724979995, -7.179460022888089],
              [-40.804980930638251, -7.179407565780763],
              [-40.803371137613276, -7.179712271690474],
              [-40.802108472126349, -7.180069899566733],
              [-40.801475218487376, -7.180135697669983],
              [-40.800830533575883, -7.179397088509107],
              [-40.800521543024658, -7.177788714748815],
              [-40.799407656978879, -7.17631051757486],
              [-40.798297588630049, -7.175175629379245],
              [-40.797137904117946, -7.174673529410754],
              [-40.795280145884142, -7.173659293904056],
              [-40.793193515194801, -7.172708924837429],
              [-40.792438188821585, -7.172259770881793],
              [-40.793117193926371, -7.171217408577237],
              [-40.794559161937123, -7.171139201195376],
              [-40.795303033037278, -7.170783946888258],
              [-40.796500856389052, -7.169911366997751],
              [-40.798518847343324, -7.16988512677109],
              [-40.800422356957263, -7.170031507434144],
              [-40.801501924453383, -7.168761693792156],
              [-40.801654522502588, -7.167377421082126],
              [-40.800956424918503, -7.166693176559797],
              [-40.799689940597233, -7.166878649449979],
              [-40.79928558345793, -7.16682619343533],
              [-40.79800766799373, -7.166044673022498],
              [-40.797351526858712, -7.164437719979385],
              [-40.797332458233527, -7.162891815086986],
              [-40.797721565401325, -7.161796042865452],
              [-40.7973553452453, -7.160188135061405],
              [-40.796714478167758, -7.159621190894106],
              [-40.796127021906983, -7.158818669160172],
              [-40.795535724992114, -7.157618471770534],
              [-40.795066524856409, -7.156878884760213],
              [-40.794532456404625, -7.155850353403633],
              [-40.793658898086321, -7.155112210061533],
              [-40.793368977193424, -7.155004944462695],
              [-40.79314010378814, -7.155005876273997],
              [-40.791518855491248, -7.154505660929383],
              [-40.790755915677671, -7.153423742705751],
              [-40.789878562567182, -7.15245956569583],
              [-40.789237671489701, -7.151892599530359],
              [-40.788825678005693, -7.151089611941473],
              [-40.788058918218496, -7.150007645716246],
              [-40.787238748803553, -7.148925704448424],
              [-40.786254571973231, -7.148540903933599],
              [-40.785098706209261, -7.148319178318711],
              [-40.783763561288765, -7.147356911933422],
              [-40.783588098504424, -7.147366457736806],
              [-40.783588098598415, -7.147249157429473],
              [-40.783183718416595, -7.147250578097837],
              [-40.783069282120088, -7.147368366310832],
              [-40.781177204878162, -7.147909535602824],
              [-40.781177204784164, -7.148026836910117],
              [-40.780887282847942, -7.147973936990741],
              [-40.780364671765696, -7.147632047591934],
              [-40.779723803594301, -7.147182381735152],
              [-40.778857858904352, -7.146842401050813],
              [-40.777877478729756, -7.146855277191288],
              [-40.77674454642316, -7.148360645395637],
              [-40.776118906320157, -7.148941443465578],
              [-40.775310190949241, -7.148953852745839],
              [-40.775012632530377, -7.148322042239319],
              [-40.774997382737688, -7.146947781343298],
              [-40.774982109763982, -7.145799551112596],
              [-40.774909652239565, -7.144587922210833],
              [-40.774436609267717, -7.143676668215194],
              [-40.773620281457021, -7.143055825230195],
              [-40.772639899376834, -7.142951400063978],
              [-40.771079676519506, -7.14262287575849],
              [-40.770328191931256, -7.142408763423106],
              [-40.77020993680626, -7.142345829700455],
              [-40.769748349204434, -7.1421217056558],
              [-40.768996863667041, -7.141844194695029],
              [-40.768073689188775, -7.14173928157769],
              [-40.766917846375875, -7.141580979589939],
              [-40.766624106741858, -7.141184235699242],
              [-40.766376142432655, -7.139982618600091],
              [-40.76654019498379, -7.139113808863854],
              [-40.766597401671817, -7.139005100551795],
              [-40.766925437404964, -7.137729070352346],
              [-40.766914028171414, -7.136870296416969],
              [-40.766734725877178, -7.136300954637669],
              [-40.765681887221064, -7.135048300198974],
              [-40.764567978178604, -7.133560557101132],
              [-40.763000140830322, -7.132662667156525],
              [-40.761146201382921, -7.131883056285286],
              [-40.760329849529043, -7.131316554982223],
              [-40.758716237908352, -7.131159673623028],
              [-40.758769649622693, -7.131050965308089],
              [-40.757445935937234, -7.130947959861664],
              [-40.754787098914719, -7.130406278943857],
              [-40.753112439720383, -7.130249886533534],
              [-40.751388187641034, -7.130382926373057],
              [-40.749091729038881, -7.131160162948777],
              [-40.747657420526728, -7.131807711978007],
              [-40.746230703593447, -7.132916360287582],
              [-40.746360412995507, -7.133783725264053],
              [-40.747474299043425, -7.135262388412309],
              [-40.747546779439617, -7.136636161350497],
              [-40.747451434998254, -7.138019946095916],
              [-40.747405660579709, -7.138942631606187],
              [-40.747184378573046, -7.139630717363062],
              [-40.746322275259004, -7.140040779251676],
              [-40.745864506262883, -7.140277778485737],
              [-40.745010016664715, -7.140976829672391],
              [-40.744552247668615, -7.141213804906525],
              [-40.743457430295663, -7.141227169953848],
              [-40.741672151730647, -7.141251966382637],
              [-40.740920643920944, -7.141317764394007],
              [-40.739837281918888, -7.142018236389323],
              [-40.739558793260613, -7.142769721725449],
              [-40.740844345246245, -7.144076276029474],
              [-40.742129898138266, -7.145499618643918],
              [-40.742198582971668, -7.146358415623532],
              [-40.740939713142417, -7.14712277610103],
              [-40.739844894820791, -7.147072717522506],
              [-40.738677597735929, -7.146109496282963],
              [-40.738261807366591, -7.145197799339289],
              [-40.73802147968636, -7.144394322896193],
              [-40.737731558983434, -7.144051502690981],
              [-40.73680458875927, -7.143666190228491],
              [-40.734313577898824, -7.142717287849131],
              [-40.733504862720103, -7.142494119522936],
              [-40.733443837239768, -7.142376819167626],
              [-40.733214941836103, -7.142377772979302],
              [-40.732059098933846, -7.142336771299323],
              [-40.730964281656661, -7.142232348043001],
              [-40.730273821465026, -7.142244268421592],
              [-40.729117954751345, -7.141968177131416],
              [-40.728480907238229, -7.141916185930519],
              [-40.728305421686201, -7.141636765442382],
              [-40.728068911704632, -7.141176621973982],
              [-40.726337046001817, -7.140911497159794],
              [-40.725410076735123, -7.140580574376863],
              [-40.724769185574289, -7.14012185057776],
              [-40.724303803181769, -7.139671718867964],
              [-40.724242778788359, -7.139445686155748],
              [-40.724063475458159, -7.138922119108419],
              [-40.723651480874111, -7.138245488780608],
              [-40.722949588363043, -7.137506390591139],
              [-40.72196538958962, -7.137058144456966],
              [-40.721618262199172, -7.136824032564541],
              [-40.719886372497207, -7.136559396748787],
              [-40.71930273479245, -7.136281396927523],
              [-40.717506002048957, -7.135383995802394],
              [-40.716174697834816, -7.134765060401778],
              [-40.7149043957331, -7.134724525629223],
              [-40.71461827072936, -7.134725479395551],
              [-40.713981198354404, -7.134501846210524],
              [-40.712932178445762, -7.133538158074378],
              [-40.711356704664766, -7.132007015875742],
              [-40.710544170557647, -7.131729503870039],
              [-40.709563789484378, -7.131624590709746],
              [-40.708121844580852, -7.131585031795362],
              [-40.708121844674878, -7.13146724249214],
              [-40.706225926434129, -7.132061869464688],
              [-40.704913666752951, -7.133115663185682],
              [-40.704001971601585, -7.133761302640173],
              [-40.703139845386445, -7.134053622224157],
              [-40.702220488563221, -7.134355441706294],
              [-40.701247744069406, -7.134711648824142],
              [-40.700606876902398, -7.134261982973752],
              [-40.700812862074763, -7.132244968070423],
              [-40.701430842592693, -7.131202607729261],
              [-40.701133308448028, -7.130227464261546],
              [-40.699050472379163, -7.129737750500771],
              [-40.697673370750998, -7.130041013610167],
              [-40.696406886488134, -7.130172143826037],
              [-40.696231448669131, -7.130226998361781],
              [-40.695884298228663, -7.130056753092762],
              [-40.691390568560799, -7.130109209205196],
              [-40.689258139429135, -7.130134960355401],
              [-40.688403649880662, -7.130780111857454],
              [-40.687144802974132, -7.13165273869136],
              [-40.685893569732983, -7.132878198443304],
              [-40.684871231988289, -7.133984914080082],
              [-40.684245614755817, -7.134737866126136],
              [-40.683394943819984, -7.135835081955708],
              [-40.682315376431568, -7.136996187224865],
              [-40.681750808722512, -7.137812050947198],
              [-40.680667423360269, -7.13897364421025],
              [-40.679927369970009, -7.139672672484039],
              [-40.679236909696229, -7.139792347225641],
              [-40.678138273769662, -7.139290247321429],
              [-40.67783692065337, -7.138314639854784],
              [-40.677814033192369, -7.136587523057565],
              [-40.677787351117956, -7.13440885293025],
              [-40.677405857931326, -7.131715673566307],
              [-40.676757353468297, -7.130452053529372],
              [-40.675708311469648, -7.129605665704416],
              [-40.674602060826736, -7.128814110509703],
              [-40.67303801946629, -7.127915754583335],
              [-40.672053843517375, -7.127693075118698],
              [-40.670726310914951, -7.127535238997154],
              [-40.669745929845924, -7.127430324838444],
              [-40.667907264257693, -7.127970121180251],
              [-40.666923040395957, -7.127639198356573],
              [-40.666808628331559, -7.127467998279159],
              [-40.66686201623277, -7.127124199357101],
              [-40.668685454569065, -7.125779041771195],
              [-40.669318685292197, -7.125595943358744],
              [-40.670119787023545, -7.125186300421025],
              [-40.671088712950962, -7.124314674351827],
              [-40.672873989638632, -7.124118699931426],
              [-40.674205317984303, -7.124565969342108],
              [-40.675715947531039, -7.125699412833522],
              [-40.676936656425561, -7.126427546494069],
              [-40.678386238715454, -7.127163781289066],
              [-40.680301226540628, -7.12816942486011],
              [-40.681800401946191, -7.12832679412145],
              [-40.683059248902183, -7.127390767663066],
              [-40.68379166471253, -7.126231083109589],
              [-40.683776414915023, -7.124856355234269],
              [-40.682841784209913, -7.123892202197896],
              [-40.681735558478799, -7.123209355360276],
              [-40.680923025510708, -7.122760178373098],
              [-40.680507212371417, -7.121559027152954],
              [-40.679053811330675, -7.120542393016105],
              [-40.676330109150051, -7.119539612788283],
              [-40.674937757452803, -7.118812434987293],
              [-40.673491969905569, -7.118365656483946],
              [-40.67320204910952, -7.118140577586301],
              [-40.673083793978343, -7.118086701811376],
              [-40.671058188752994, -7.117298008872371],
              [-40.668975376919995, -7.116518863831867],
              [-40.668052201357902, -7.116531250025348],
              [-40.666846765399207, -7.117006179255617],
              [-40.665591714326737, -7.1180595060212],
              [-40.664741043394919, -7.119156722847946],
              [-40.66434814054049, -7.119908697082828],
              [-40.662860397366551, -7.120619180693473],
              [-40.661475658934286, -7.120750799813823],
              [-40.661365040663682, -7.120805165404009],
              [-40.660262586627773, -7.120474709485069],
              [-40.659327957017624, -7.119393232147357],
              [-40.658961783855901, -7.117784859382115],
              [-40.659118175908063, -7.116346709023991],
              [-40.658469672393515, -7.11514604562052],
              [-40.655158514089969, -7.113177645651876],
              [-40.653655519944721, -7.112731332103047],
              [-40.652152527656256, -7.112465253486654],
              [-40.649314388418205, -7.111290806192724],
              [-40.646361811749813, -7.110406258091404],
              [-40.64285228210273, -7.110617506056212],
              [-40.63984631871579, -7.109850747215837],
              [-40.637591816865658, -7.109351976383813],
              [-40.635394544651795, -7.108808385863166],
              [-40.634295908548772, -7.108531828631205],
              [-40.63233135162411, -7.108095503659829],
              [-40.629840338923202, -7.10697397933272],
              [-40.627349350992787, -7.106141911290772],
              [-40.626887764310716, -7.106026031611907],
              [-40.625968431453401, -7.106381750774848],
              [-40.624934615668522, -7.106394626879363],
              [-40.624240359691136, -7.106180049601257],
              [-40.6231379058027, -7.105668402760871],
              [-40.622031656299797, -7.104704715597455],
              [-40.620532481759668, -7.104728069271459],
              [-40.618750998926551, -7.105087140731501],
              [-40.617717229955936, -7.105334616445399],
              [-40.616675799411851, -7.105112401937924],
              [-40.616153187285967, -7.104833936174999],
              [-40.615226217172079, -7.104321825477154],
              [-40.614413684208763, -7.10387215849987],
              [-40.613894890826572, -7.103883125023975],
              [-40.612632248369351, -7.104240287906282],
              [-40.612170638544761, -7.10430513115623],
              [-40.611308512382614, -7.104543061062645],
              [-40.610450204057244, -7.104953146951187],
              [-40.609691081857179, -7.104331837034241],
              [-40.609164651081194, -7.103646591680778],
              [-40.608230067473229, -7.102564672356667],
              [-40.607585381566608, -7.101825574229345],
              [-40.606776644440664, -7.101548039235062],
              [-40.605040958972253, -7.101047359830561],
              [-40.604865498420551, -7.100767938348877],
              [-40.604751061177531, -7.100822304935887],
              [-40.604636601927034, -7.100886194466359],
              [-40.604579395240741, -7.100994925776222],
              [-40.603545603272046, -7.101242401490269],
              [-40.602279119072911, -7.101310110086301],
              [-40.600837174143066, -7.101324404860296],
              [-40.599223539449454, -7.101293903764083],
              [-40.597781594706987, -7.10107264493532],
              [-40.596278600427112, -7.100806565324254],
              [-40.59495488671503, -7.100766029517199],
              [-40.593860069360545, -7.100778439577264],
              [-40.592479149689979, -7.101190411040731],
              [-40.591102046942957, -7.101665386133359],
              [-40.589904225713717, -7.102428768656878],
              [-40.587722227313712, -7.103259417002327],
              [-40.587611585814706, -7.103603191875369],
              [-40.587218683872756, -7.104472490407415],
              [-40.586940218266598, -7.105170121048456],
              [-40.586547292425365, -7.105912549333122],
              [-40.586089524345596, -7.106266847868938],
              [-40.585631755265837, -7.106621123404891],
              [-40.585517319022586, -7.106675955988983],
              [-40.585231215929625, -7.106794210060953],
              [-40.585116779686409, -7.106849065644889],
              [-40.585002343436386, -7.106912466178206],
              [-40.584540755663042, -7.10691437480138],
              [-40.584197446969789, -7.10703311882512],
              [-40.583907524989442, -7.107043130536121],
              [-40.583159836216694, -7.107045504930013],
              [-40.582469375036567, -7.107056960315377],
              [-40.58137455768334, -7.107069836372074],
              [-40.580218691850412, -7.106965411076446],
              [-40.579875383250737, -7.106966365798927],
              [-40.579471025027424, -7.107031209094079],
              [-40.579417614078274, -7.10743794163875],
              [-40.579318427680533, -7.108813158401498],
              [-40.579612167397443, -7.10910116992547],
              [-40.579902089190959, -7.109326247819837],
              [-40.580310263978106, -7.109777311467218],
              [-40.580489543495148, -7.110065811897736],
              [-40.580668846782224, -7.110643721611493],
              [-40.580790897476554, -7.111213086330574],
              [-40.580058480591937, -7.112481457226211],
              [-40.579085736969091, -7.113018388270326],
              [-40.578395276609825, -7.113255853314499],
              [-40.578166403030387, -7.113482815786795],
              [-40.575232896801396, -7.114424104878103],
              [-40.573565874351345, -7.115822186262987],
              [-40.571624181383619, -7.117772960151142],
              [-40.571212186496588, -7.119998824620228],
              [-40.570941357175379, -7.121528035334202],
              [-40.571235073690893, -7.125840554987162],
              [-40.572638880442177, -7.130148788549834],
              [-40.57446231991949, -7.133623978389613],
              [-40.576140797742191, -7.135426905032745],
              [-40.578093922759912, -7.1370839397603],
              [-40.580192008985151, -7.139717491814927],
              [-40.5827058848513, -7.141662026287085],
              [-40.583266658043662, -7.14277211815282],
              [-40.58299198696853, -7.144020953531465],
              [-40.580073753911371, -7.14711467187],
              [-40.57995549946417, -7.14745847073794],
              [-40.579722761997694, -7.147576724851678],
              [-40.577689565499519, -7.148478921884791],
              [-40.576236165221566, -7.149035853426005],
              [-40.575251965930406, -7.149256158336305],
              [-40.574664486634184, -7.149764475855253],
              [-40.574599642669014, -7.150288996694306],
              [-40.574641599400792, -7.151509683491899],
              [-40.574817084130153, -7.151563584312194],
              [-40.575038343164273, -7.152087151384976],
              [-40.575091731509339, -7.152439516338878],
              [-40.575255783712024, -7.153253005647725],
              [-40.575358764298748, -7.154120370588661],
              [-40.572966962622125, -7.155883250233427],
              [-40.571509743952554, -7.156964237664614],
              [-40.571032906362397, -7.158122968416375],
              [-40.571025269953118, -7.158701832979271],
              [-40.570998563614381, -7.160610138647515],
              [-40.570807828834994, -7.162002980240326],
              [-40.569125555252313, -7.162443101289845],
              [-40.567691222999187, -7.161552848422217],
              [-40.566546813711575, -7.160670232740907],
              [-40.564887427357924, -7.159373713102843],
              [-40.563880340316452, -7.156980932484151],
              [-40.563609488460685, -7.155417379537381],
              [-40.56385745374461, -7.154141838296876],
              [-40.563514121239756, -7.15402546871323],
              [-40.561713593348955, -7.154637278651517],
              [-40.561492333503033, -7.153878621973068],
              [-40.561629656928972, -7.15214198237878],
              [-40.56182420986049, -7.150522642134906],
              [-40.561553357772588, -7.149248521473863],
              [-40.560172438864008, -7.148710635563805],
              [-40.558776267001384, -7.149502213758553],
              [-40.558402410912905, -7.151637483799774],
              [-40.557986621128258, -7.15250675831434],
              [-40.556208980042008, -7.155668674150514],
              [-40.556891803427824, -7.15671534248892],
              [-40.558738106535294, -7.156681954158251],
              [-40.560115231093874, -7.15768999527957],
              [-40.560557726792055, -7.159198693688302],
              [-40.560653093918049, -7.160708369814822],
              [-40.560641639347466, -7.161522323980648],
              [-40.560172438079611, -7.162211364521906],
              [-40.559413316035261, -7.1626567232764],
              [-40.559054733755019, -7.163643787139236],
              [-40.56106511197752, -7.164532130477323],
              [-40.562392597671135, -7.164554552403557],
              [-40.563308135652612, -7.165320356594989],
              [-40.563861271199464, -7.167298745310632],
              [-40.56390322784236, -7.168627697467856],
              [-40.563594237845138, -7.17007586063855],
              [-40.563121218371862, -7.17105386646477],
              [-40.561255823470511, -7.172244982915912],
              [-40.559581163437493, -7.171934083420977],
              [-40.558432958946938, -7.171340901019649],
              [-40.557330480346984, -7.171733825810409],
              [-40.556804072717995, -7.172124353075388],
              [-40.556735388503306, -7.172992672874094],
              [-40.5578760034387, -7.174344979770552],
              [-40.55810487570006, -7.174516156938871],
              [-40.557910322945332, -7.175909486525994],
              [-40.556685818786342, -7.1768178541642],
              [-40.555800803919261, -7.178141102614926],
              [-40.557338117658389, -7.180016485730158],
              [-40.558024735333362, -7.180665479433326],
              [-40.558123945573691, -7.181994408637974],
              [-40.55654847086938, -7.182903752989538],
              [-40.555159937415738, -7.183116421618811],
              [-40.553656943951445, -7.183094488546645],
              [-40.553191538120167, -7.183204641521687],
              [-40.552260750225379, -7.183714377756549],
              [-40.551665657279123, -7.185099583071922],
              [-40.551074383639239, -7.186069462851565],
              [-40.549846037267734, -7.187276365716883],
              [-40.54729400059999, -7.187935812766941],
              [-40.546431897827453, -7.187694530506943],
              [-40.545569772200118, -7.187272524317936],
              [-40.544185032041071, -7.187087050309195],
              [-40.5432580634467, -7.187190032957183],
              [-40.542655333234734, -7.188973424905804],
              [-40.54350600510729, -7.190435393922692],
              [-40.545390445688788, -7.191966048360832],
              [-40.546702726324014, -7.193435653703538],
              [-40.547896729750107, -7.194951057680844],
              [-40.547950142231116, -7.195131781652848],
              [-40.548068373360373, -7.195185658428312],
              [-40.549273831534663, -7.195669644526425],
              [-40.550307600822947, -7.196262827840237],
              [-40.550868397824239, -7.19759941737108],
              [-40.551413897046039, -7.200038904356417],
              [-40.551444398058898, -7.202245234311014],
              [-40.55118118208965, -7.204497826756632],
              [-40.550811167187227, -7.206352745472535],
              [-40.550677662964567, -7.207790872847205],
              [-40.551112521277403, -7.209833173099948],
              [-40.551547402596995, -7.211866393407176],
              [-40.552787180760724, -7.214547651523987],
              [-40.553992615802969, -7.215194758660312],
              [-40.556056382265162, -7.216553234274208],
              [-40.557658562102226, -7.217732453579395],
              [-40.558863998086792, -7.218451529290662],
              [-40.56029832947727, -7.219161058242801],
              [-40.561324484300229, -7.220396552753213],
              [-40.561591519214616, -7.221905740033345],
              [-40.561065111393837, -7.22253135690638],
              [-40.559501046123593, -7.222798856064816],
              [-40.558215493292522, -7.223825012955832],
              [-40.557269454750845, -7.225266026663848],
              [-40.555884716460049, -7.225243605682563],
              [-40.554404610142377, -7.223602821206093],
              [-40.552531578418844, -7.220914880614864],
              [-40.550876011379472, -7.219211140372451],
              [-40.549205170513979, -7.218665151262726],
              [-40.547194837257052, -7.217831173586038],
              [-40.545264600028126, -7.215025444652023],
              [-40.544833535966447, -7.212639834435531],
              [-40.543807381680509, -7.207009328964089],
              [-40.543036804457479, -7.204046250899597],
              [-40.542353979802584, -7.203342915518923],
              [-40.541316369171895, -7.203210830469453],
              [-40.540557248119967, -7.203665736165625],
              [-40.540286395644593, -7.206614516478298],
              [-40.539851513738128, -7.209039218764959],
              [-40.538199764171445, -7.211685203764504],
              [-40.536681520834875, -7.212883957444379],
              [-40.535121297869502, -7.212744748016497],
              [-40.533240651733472, -7.210635206004633],
              [-40.532634127848063, -7.208312996276572],
              [-40.532496781005612, -7.205582633344185],
              [-40.532935480927449, -7.203103565385549],
              [-40.533034666798727, -7.199911126382798],
              [-40.532958368657184, -7.197017665468883],
              [-40.533221582863057, -7.194466582798728],
              [-40.533999775079558, -7.192393292709712],
              [-40.534079891912022, -7.1906571190641],
              [-40.533118556047114, -7.188905693675909],
              [-40.531798683762155, -7.18831346513037],
              [-40.530589429616498, -7.187829014034645],
              [-40.529620504057249, -7.187009308118721],
              [-40.529288627452857, -7.185672276779442],
              [-40.530639023191249, -7.184184998676222],
              [-40.532500598976135, -7.183165525208262],
              [-40.534259171133989, -7.181333051477425],
              [-40.535548543161795, -7.180017929304321],
              [-40.535624840787158, -7.178570721944837],
              [-40.534900038401581, -7.17664716476984],
              [-40.533572505937599, -7.176343879507632],
              [-40.532882069964494, -7.176102130389462],
              [-40.531855913905559, -7.175156092179177],
              [-40.530711503717598, -7.174156152194445],
              [-40.530024861898958, -7.17368839541984],
              [-40.52818235537967, -7.172961683258217],
              [-40.526217773106652, -7.172995536489224],
              [-40.524878832796979, -7.17385002635302],
              [-40.523532232128467, -7.175219981153732],
              [-40.522246679445608, -7.176074005062623],
              [-40.520049407621514, -7.176334822761326],
              [-40.519309354511002, -7.175450763413392],
              [-40.519275035321762, -7.173488580023268],
              [-40.51930935517214, -7.17087554054884],
              [-40.518580756530447, -7.169304327287038],
              [-40.518233605104939, -7.169124558076435],
              [-40.516085926566149, -7.169846008083717],
              [-40.514987291270536, -7.169831712292114],
              [-40.512793837653334, -7.169802632714854],
              [-40.511348073838938, -7.169725844017115],
              [-40.508719738936811, -7.167609643475448],
              [-40.508220013830254, -7.165865855422886],
              [-40.508979136065165, -7.165176350118979],
              [-40.510833098152325, -7.165088596117459],
              [-40.513247788570148, -7.165930699046487],
              [-40.514975882033006, -7.166476687185267],
              [-40.516761137189995, -7.166904887068707],
              [-40.517821635097341, -7.165237374806159],
              [-40.518588370598032, -7.164149704869613],
              [-40.519805261297506, -7.163874568471815],
              [-40.521880459469813, -7.164247469914132],
              [-40.523372021315232, -7.165137722822616],
              [-40.524699529916184, -7.165268854103305],
              [-40.526728954915818, -7.165008968262971],
              [-40.527728404157486, -7.163459244251626],
              [-40.526236866267567, -7.162623358020592],
              [-40.525550201311518, -7.162327243229681],
              [-40.525386172152906, -7.161459901243789],
              [-40.527358388835104, -7.160847136450316],
              [-40.52914748606176, -7.161158036032028],
              [-40.530646660317956, -7.161469400380113],
              [-40.532317501235859, -7.161951966849771],
              [-40.533706034635138, -7.161802722841785],
              [-40.534694053126245, -7.161292984652478],
              [-40.534770352661617, -7.159954486651716],
              [-40.533984524742017, -7.157977052753978],
              [-40.533030849141284, -7.155818872795477],
              [-40.532920208146464, -7.155529905421429],
              [-40.532924025444899, -7.15512268484003],
              [-40.532096242467524, -7.152331272739563],
              [-40.53175675149236, -7.151518786289258],
              [-40.530894578720272, -7.151277481034525],
              [-40.529635755243469, -7.150386273319871],
              [-40.528785083357498, -7.148933338263072],
              [-40.528464639128686, -7.147017394375752],
              [-40.52812132976922, -7.146719859868057],
              [-40.526213979145453, -7.146690290526477],
              [-40.524482089236628, -7.1467322459001],
              [-40.523082100227022, -7.147749345751324],
              [-40.521727886197382, -7.149643821432671],
              [-40.520324078976223, -7.150959898506521],
              [-40.518355679637537, -7.151111052043268],
              [-40.517352387844689, -7.148374473484321],
              [-40.517424891260099, -7.147216721412625],
              [-40.517367684848054, -7.14698163176224],
              [-40.515761687759216, -7.146145745445939],
              [-40.514964404132186, -7.145153907698529],
              [-40.515090295805877, -7.144457254933308],
              [-40.51700910103871, -7.1436189704338],
              [-40.518641782425632, -7.142591859827411],
              [-40.520091340763329, -7.14198105060453],
              [-40.520739868002615, -7.141002089929964],
              [-40.520816167390542, -7.139844336861854],
              [-40.519397108999506, -7.137742432209987],
              [-40.517337160691078, -7.136148355036124],
              [-40.515574770803568, -7.13392058186141],
              [-40.513804743552001, -7.132217305568309],
              [-40.512145357202158, -7.13092078594911],
              [-40.50819330789993, -7.128374453933312],
              [-40.505843462714367, -7.126664053226735],
              [-40.505156820808438, -7.126304515817435],
              [-40.503669078113766, -7.125188231267424],
              [-40.501979167587237, -7.121865639661729],
              [-40.501822775701683, -7.120581996162131],
              [-40.501597697569778, -7.120214355167529],
              [-40.50147942033486, -7.120293984600888],
              [-40.499865831068909, -7.119747529568738],
              [-40.498893087352577, -7.119153857324852],
              [-40.497634216869073, -7.118271706568282],
              [-40.496600447580249, -7.117678034276533],
              [-40.495860394320971, -7.116975186866106],
              [-40.495127954747112, -7.11534106199457],
              [-40.494754122071321, -7.113190030478578],
              [-40.495078363002513, -7.11041247323806],
              [-40.494639709499495, -7.108614784572781],
              [-40.493262607850532, -7.107723577779708],
              [-40.492110537066829, -7.107537614974055],
              [-40.49055413130791, -7.107108461294028],
              [-40.490092567589876, -7.107046458297622],
              [-40.489039706015738, -7.108243814349875],
              [-40.487098012705296, -7.110655176484932],
              [-40.485591200911927, -7.111094341683595],
              [-40.48432853636276, -7.110329492230478],
              [-40.484309467703135, -7.107544273768175],
              [-40.484031001254706, -7.106785641056915],
              [-40.482878953607901, -7.106428013272044],
              [-40.481608651567953, -7.106350317730062],
              [-40.479617388509553, -7.103834020113703],
              [-40.479403766065232, -7.102496009898587],
              [-40.478606483342553, -7.101621473467741],
              [-40.477229381465577, -7.101020186954876],
              [-40.476317662687642, -7.099964949508488],
              [-40.475184706966843, -7.098268357700984],
              [-40.474688799862712, -7.096470668998017],
              [-40.475596701971106, -7.093347869282095],
              [-40.477454459533334, -7.092618294085299],
              [-40.479434290462329, -7.091608366651481],
              [-40.481124200572118, -7.090409124114455],
              [-40.481139451422592, -7.089197470330841],
              [-40.480056088758829, -7.088251432102132],
              [-40.478793424072066, -7.087658691631638],
              [-40.478221218500309, -7.087127044342119],
              [-40.477534553730415, -7.086595374962713],
              [-40.47599723993082, -7.084782901530132],
              [-40.474974903018321, -7.083610365698232],
              [-40.473040846418151, -7.081265268123092],
              [-40.472136764133538, -7.079513843828946],
              [-40.47164085798299, -7.077770055811341],
              [-40.471720951655442, -7.076205571180866],
              [-40.471804887714548, -7.074116052677041],
              [-40.471061015386994, -7.073530970572734],
              [-40.468695896122171, -7.073321143961143],
              [-40.466258295631121, -7.074043572746184],
              [-40.464865944076863, -7.074436474313559],
              [-40.46389317645324, -7.073725035781544],
              [-40.462653420981667, -7.071387086717863],
              [-40.461699746449128, -7.069120220457374],
              [-40.46108174278892, -7.067837997602073],
              [-40.46028448311354, -7.066900060557463],
              [-40.459021818434593, -7.066298286146997],
              [-40.457923182144484, -7.066283501372061],
              [-40.456599468615885, -7.066034557814066],
              [-40.454936241014074, -7.065089986129853],
              [-40.453742260672968, -7.063447735967328],
              [-40.453414202116186, -7.061992914369543],
              [-40.452391864337415, -7.06064871156882],
              [-40.451190225409405, -7.059820438556571],
              [-40.449809304417307, -7.059399875976856],
              [-40.448424566317286, -7.059150955372452],
              [-40.44641802931271, -7.057963633867378],
              [-40.445506333353855, -7.057134407089511],
              [-40.443736306047803, -7.055494089468484],
              [-40.44253087001205, -7.054837482433244],
              [-40.441493259338692, -7.054768355031467],
              [-40.440383191448554, -7.055621867078797],
              [-40.440245845231452, -7.057123441029661],
              [-40.440325961530895, -7.059845234885698],
              [-40.439784280011054, -7.061519894489494],
              [-40.438082915538402, -7.063297535570227],
              [-40.4374840044688, -7.064909262503898],
              [-40.437705263228452, -7.065785685459515],
              [-40.438273649783383, -7.066371720416663],
              [-40.438723781939423, -7.067247187557959],
              [-40.440100883635616, -7.068074972711253],
              [-40.441756497875019, -7.069544112265809],
              [-40.442885634397356, -7.071530138330757],
              [-40.44395756518503, -7.073687829330023],
              [-40.44491505909442, -7.075439276658219],
              [-40.445761911006741, -7.076901734620153],
              [-40.446025126651435, -7.078818144421957],
              [-40.446295955520583, -7.080381697330195],
              [-40.446276886605894, -7.081711115404022],
              [-40.446265477908518, -7.082696736529849],
              [-40.446604969294434, -7.08299429402639],
              [-40.449027294924065, -7.08349306396523],
              [-40.451453439848457, -7.083585079327571],
              [-40.453418020014105, -7.083669480372067],
              [-40.455607655418291, -7.08398801620077],
              [-40.45704962416719, -7.084173513232545],
              [-40.458609824031463, -7.084430511932113],
              [-40.460273027624353, -7.085384187568543],
              [-40.461070310390305, -7.086204358317565],
              [-40.46099401183249, -7.08758814302602],
              [-40.459704640871536, -7.088849388507738],
              [-40.457541711544309, -7.090728124627843],
              [-40.457064873970538, -7.091886831359607],
              [-40.457507368677348, -7.093396018731555],
              [-40.45858693609334, -7.094685390913725],
              [-40.459964037881889, -7.095395874774015],
              [-40.461806545446706, -7.096059163282371],
              [-40.463649053147527, -7.096550786812078],
              [-40.466014173367064, -7.09681446710981],
              [-40.467162354946304, -7.097289884188559],
              [-40.468455567714244, -7.100035031885777],
              [-40.469298624285486, -7.101958635113927],
              [-40.470946579161932, -7.103951785565083],
              [-40.472026122491691, -7.105349865106088],
              [-40.47190025454254, -7.106399397767346],
              [-40.469920423520783, -7.107527114498981],
              [-40.467734583639846, -7.106511924807344],
              [-40.466010353332273, -7.105739927035124],
              [-40.462634328955204, -7.103254642146112],
              [-40.460177660795068, -7.101200444421966],
              [-40.458808195460811, -7.099848604380403],
              [-40.457030531671897, -7.098895394646668],
              [-40.454207665990666, -7.098216854455245],
              [-40.452239266665984, -7.098368009003359],
              [-40.451712859039233, -7.098767616211204],
              [-40.452262154396252, -7.101035437156024],
              [-40.454192392877395, -7.103552666708683],
              [-40.456130266669888, -7.105608330014095],
              [-40.457614168115157, -7.107068391503944],
              [-40.459441424954022, -7.108781212762947],
              [-40.459952605771868, -7.109538890661913],
              [-40.460345508324707, -7.110414847764251],
              [-40.460574404585152, -7.110586047927287],
              [-40.460688840733361, -7.11064898264357],
              [-40.461261046488737, -7.110945562332526],
              [-40.461547148205085, -7.111297486466367],
              [-40.461539510843039, -7.111822008341904],
              [-40.460311188552012, -7.112955918629474],
              [-40.459944969013712, -7.114349714053604],
              [-40.459868693358025, -7.115851288066419],
              [-40.459613091822263, -7.117524970914493],
              [-40.459628342004088, -7.120943418605614],
              [-40.459605454966358, -7.122453583610775],
              [-40.460738433544087, -7.124321796403384],
              [-40.46187902566394, -7.125439525663332],
              [-40.463183647038811, -7.127307318596027],
              [-40.463401087589212, -7.128473173839649],
              [-40.463217989275726, -7.129224193231368],
              [-40.462226153727563, -7.129851718716051],
              [-40.461241976499714, -7.130008601003724],
              [-40.459681753494515, -7.129931813223651],
              [-40.459159141069428, -7.130041965154821],
              [-40.458693758157317, -7.130260360488018],
              [-40.458163509275579, -7.131012823595792],
              [-40.459292646698458, -7.133107092044729],
              [-40.460379850514386, -7.133818041681833],
              [-40.460894825532947, -7.134286776304742],
              [-40.461680653582846, -7.136102137140588],
              [-40.462107921511432, -7.13871422195881],
              [-40.462085011197189, -7.140568160925262],
              [-40.461764588556839, -7.142938054590534],
              [-40.461619627916711, -7.145018493115399],
              [-40.461066491811522, -7.147507106891576],
              [-40.460646883793238, -7.148711123405558],
              [-40.459582566873607, -7.150441569280014],
              [-40.458392382056999, -7.153149534247616],
              [-40.458201647427629, -7.154361651895734],
              [-40.457667603210226, -7.155566156316198],
              [-40.457080123915112, -7.156083530775732],
              [-40.456439256044426, -7.156537946566759],
              [-40.455683953163216, -7.156757296663041],
              [-40.454760802595025, -7.156805446642753],
              [-40.453276877116849, -7.155399263815902],
              [-40.452101942633732, -7.152536326889888],
              [-40.451609854839418, -7.150331417598668],
              [-40.45128177229774, -7.148876595981811],
              [-40.450656179192599, -7.148236194289888],
              [-40.449443082756986, -7.148158940785317],
              [-40.448050755210716, -7.148542809398378],
              [-40.446185336381639, -7.149679118164455],
              [-40.444030044404052, -7.15104238935136],
              [-40.44239352187796, -7.15229550960473],
              [-40.442092169348477, -7.153101384576607],
              [-40.442073100235547, -7.154665915265043],
              [-40.441355934747826, -7.156567026398832],
              [-40.440642587557313, -7.158061452952215],
              [-40.439227323968517, -7.159955929570382],
              [-40.437579370779673, -7.162140326280735],
              [-40.435412622240939, -7.164308494674513],
              [-40.433604457781072, -7.165733768767151],
              [-40.431845885742661, -7.16744847717938],
              [-40.431250792953726, -7.16865295854929],
              [-40.431983209513135, -7.170287571421196],
              [-40.432887292006143, -7.17175002945313],
              [-40.433219168885763, -7.172743286816991],
              [-40.433264943690588, -7.173837615352661],
              [-40.432849154005069, -7.174589590554303],
              [-40.431807725131172, -7.174809894415598],
              [-40.429774505949126, -7.175358747534605],
              [-40.428378312013329, -7.176267113025458],
              [-40.427733626077021, -7.17683885311507],
              [-40.427367429400448, -7.178395724574472],
              [-40.427111827898166, -7.180015064751181],
              [-40.427207194841074, -7.181751215514243],
              [-40.427706920310875, -7.183033928293704],
              [-40.428427904296477, -7.185482006328406],
              [-40.428580501931165, -7.187100413837968],
              [-40.428965767202889, -7.188383079527682],
              [-40.428366879155504, -7.189940439800401],
              [-40.427787036901215, -7.190340513961376],
              [-40.426646444838035, -7.189168442011422],
              [-40.424372874172043, -7.186182011934102],
              [-40.422839357521426, -7.184188349552677],
              [-40.42156907858579, -7.183993307697706],
              [-40.420191953768423, -7.183337189496641],
              [-40.419177252559919, -7.181242433131833],
              [-40.418578341855309, -7.178621733224314],
              [-40.418544022620694, -7.176713916532269],
              [-40.418631753811923, -7.174452755037513],
              [-40.418784351002735, -7.171621762981128],
              [-40.418647004008719, -7.169063043076822],
              [-40.417803946362689, -7.167256787139846],
              [-40.417945111851907, -7.165402847270269],
              [-40.418940743780901, -7.164260344852583],
              [-40.421107492178201, -7.162263819442126],
              [-40.422652441051738, -7.158867769854727],
              [-40.423838830522598, -7.156621392149435],
              [-40.424609385129912, -7.155352999300804],
              [-40.425780500914691, -7.154101788668314],
              [-40.42781374333272, -7.153254446320317],
              [-40.42926714367858, -7.152580657479818],
              [-40.430728181252086, -7.151554035742389],
              [-40.431208814121874, -7.149997164377608],
              [-40.431632264068227, -7.148847515547069],
              [-40.432570665405521, -7.147695933137072],
              [-40.432357043054367, -7.146249679563898],
              [-40.430533628287783, -7.144428637941252],
              [-40.428298195049337, -7.143061082297032],
              [-40.42658539841382, -7.14135734206709],
              [-40.4258567998654, -7.1396683855308],
              [-40.425883529194437, -7.137760544895497],
              [-40.428271512671273, -7.136224651920759],
              [-40.431155427099164, -7.136667636573172],
              [-40.433673119850738, -7.138676549626197],
              [-40.435050221674445, -7.139332689817806],
              [-40.437236061593438, -7.140284456893934],
              [-40.438266012850796, -7.140941063808063],
              [-40.439929240346387, -7.142002982815795],
              [-40.442755923178211, -7.142446432423245],
              [-40.444487789970538, -7.142531299293323],
              [-40.44715046760048, -7.14216128460555],
              [-40.448493226961652, -7.14118949344641],
              [-40.449324852364434, -7.139640725310577],
              [-40.449870352146029, -7.137622266739195],
              [-40.450244185230439, -7.135477916780288],
              [-40.450453988873278, -7.132818591752569],
              [-40.450248003789078, -7.130965628603162],
              [-40.450259435355491, -7.130151651450551],
              [-40.450682885623955, -7.128594781040389],
              [-40.450991875607727, -7.127147084890797],
              [-40.450774458057047, -7.125981228649021],
              [-40.450225115564116, -7.123893643624321],
              [-40.450263253642646, -7.120936760233545],
              [-40.450137362235395, -7.117518777455662],
              [-40.449992425957014, -7.115366814136435],
              [-40.449847464807078, -7.113052240784782],
              [-40.449752097621541, -7.111605522312302],
              [-40.449477426376738, -7.11055743332782],
              [-40.448382609247609, -7.110308047945836],
              [-40.447051282453856, -7.11046586095536],
              [-40.445361371442104, -7.111547337188435],
              [-40.443022957258535, -7.113534805520735],
              [-40.440154295095503, -7.11640153720434],
              [-40.438575002130371, -7.117708558182658],
              [-40.437735787276381, -7.119899637488684],
              [-40.436625695288612, -7.120870471837144],
              [-40.43473361835818, -7.119854817380479],
              [-40.434310168712912, -7.116844521948986],
              [-40.434680207452502, -7.115161293252861],
              [-40.436194632598912, -7.114198071178934],
              [-40.437365748201643, -7.113172404204904],
              [-40.43720553824096, -7.112015583958875],
              [-40.436759224292544, -7.110850194537949],
              [-40.437529778166919, -7.109238491733328],
              [-40.438986997671357, -7.108329635290321],
              [-40.440848597156403, -7.107654426776741],
              [-40.442019688988054, -7.106339769521219],
              [-40.442553757154634, -7.105189653783946],
              [-40.442744491734089, -7.10403141282406],
              [-40.442691081337429, -7.103741980503521],
              [-40.442584257593573, -7.103100624234276],
              [-40.442263836398197, -7.101129849704753],
              [-40.441893798014235, -7.098572107628623],
              [-40.441699244528714, -7.095850779665245],
              [-40.441603876525981, -7.094168970596094],
              [-40.441394050281829, -7.092542018110817],
              [-40.440539583968942, -7.091658424694934],
              [-40.43990635350621, -7.091533486939387],
              [-40.439200619908604, -7.092223016280431],
              [-40.437491640712203, -7.094977709542654],
              [-40.436766838054979, -7.097177356883427],
              [-40.43569108945637, -7.099884366928994],
              [-40.434436060705558, -7.103107795761071],
              [-40.433428974489999, -7.104720453372081],
              [-40.432032802609058, -7.10556588524032],
              [-40.431060059034436, -7.104800081028614],
              [-40.430720545311232, -7.099872446079372],
              [-40.430758682194977, -7.09715065130507],
              [-40.430842618299941, -7.095007256125858],
              [-40.431727632183609, -7.093629688021056],
              [-40.432620259427608, -7.091728040041021],
              [-40.432696557899781, -7.09045249869198],
              [-40.431670402019058, -7.089279961861873],
              [-40.430781570267399, -7.086723641376313],
              [-40.430747250970164, -7.084870166381135],
              [-40.430880756175945, -7.083422493103045],
              [-40.430091087772212, -7.082077800485869],
              [-40.42888185658191, -7.081656284043689],
              [-40.426104741902321, -7.081845575732173],
              [-40.424594136004515, -7.082465463853599],
              [-40.423030093624384, -7.082904165011509],
              [-40.421755996525953, -7.082944211770699],
              [-40.420825208650612, -7.083453506006395],
              [-40.420584880650651, -7.084322268645295],
              [-40.420211046673167, -7.08634931828366],
              [-40.41949772249626, -7.08784325482877],
              [-40.418624118405496, -7.088117437508799],
              [-40.418009956901706, -7.086609205004097],
              [-40.417445387643795, -7.085615947473026],
              [-40.416175086569233, -7.085593036609803],
              [-40.415106973558728, -7.087495149444847],
              [-40.414851372895207, -7.089340521257398],
              [-40.413722235947411, -7.091704687293939],
              [-40.412665555363013, -7.092964489961972],
              [-40.410971827158271, -7.09433537546674],
              [-40.410330960386766, -7.094672047957586],
              [-40.409465015421866, -7.094719731991519],
              [-40.408259580301063, -7.094180891249751],
              [-40.40782087989006, -7.092264972310717],
              [-40.407420340983634, -7.091904480141558],
              [-40.407363111470438, -7.091796236740959],
              [-40.40679092872444, -7.091490576110233],
              [-40.405535877451328, -7.090318505100646],
              [-40.404811098095202, -7.088340116310171],
              [-40.404601270892812, -7.086658774156795],
              [-40.404639454771321, -7.083936513397751],
              [-40.40409775081396, -7.081089805924823],
              [-40.403830717061339, -7.079345040106126],
              [-40.40362852703155, -7.077374264685008],
              [-40.403266126042702, -7.07423763608299],
              [-40.402495571829391, -7.071210180511623],
              [-40.401988210076439, -7.070334736328408],
              [-40.40135877481692, -7.069920344302908],
              [-40.400256344375606, -7.07014064912511],
              [-40.399485766646897, -7.071581173938482],
              [-40.398928811417093, -7.074295796329811],
              [-40.397807288743209, -7.076252275794234],
              [-40.396514098550377, -7.077856831220156],
              [-40.395041629343844, -7.079805672454215],
              [-40.394210027728981, -7.081643872851043],
              [-40.394370260821709, -7.082638085055025],
              [-40.396956617016215, -7.083480654070256],
              [-40.398226918952439, -7.083675695907562],
              [-40.39892119807714, -7.08368284441101],
              [-40.399550610293396, -7.084151090117299],
              [-40.399539177731967, -7.084965045260739],
              [-40.398432904954191, -7.085646448332364],
              [-40.397959885636276, -7.086452788158041],
              [-40.397654713870281, -7.087602415071365],
              [-40.397174058149588, -7.088987176446342],
              [-40.396697220587072, -7.090137315221728],
              [-40.395003492429304, -7.091453393050898],
              [-40.393771350170105, -7.092596848271325],
              [-40.392653645744623, -7.094200937838605],
              [-40.39247436568801, -7.094599079326473],
              [-40.390612765943068, -7.095617620794714],
              [-40.389102136237, -7.096001955316068],
              [-40.387660190363654, -7.095988102262824],
              [-40.386614966572544, -7.096145007505079],
              [-40.385172997842368, -7.09595045352808],
              [-40.384196434884139, -7.095709171195949],
              [-40.384135410226492, -7.095817879500456],
              [-40.383155029109574, -7.095811685765247],
              [-40.381304908114153, -7.095781161489987],
              [-40.380320708981444, -7.095829334428017],
              [-40.379859121133478, -7.095938998411286],
              [-40.379439513262732, -7.096971347931869],
              [-40.378905446280463, -7.09789547700696],
              [-40.378428608718451, -7.099045126783863],
              [-40.377379565448067, -7.099844296197439],
              [-40.374869484762307, -7.101488431426869],
              [-40.373995926490146, -7.101997237707893],
              [-40.3735763183833, -7.103328075450589],
              [-40.372916359648237, -7.105057102632293],
              [-40.37209241766319, -7.106027472203235],
              [-40.370452076812462, -7.107750281648872],
              [-40.369628088641939, -7.108955717819628],
              [-40.368914741516527, -7.110386720734265],
              [-40.368373061086658, -7.111943591034803],
              [-40.368006840511271, -7.113391753121335],
              [-40.36791911038808, -7.115589491964012],
              [-40.368258623540882, -7.116176505736429],
              [-40.369872234877292, -7.116605636455102],
              [-40.372687486175643, -7.117800105565905],
              [-40.374110362840725, -7.119505290537047],
              [-40.374430808031676, -7.121475576059645],
              [-40.37475123030805, -7.123337618227285],
              [-40.376017714137141, -7.123695736096464],
              [-40.377463523931013, -7.123772500782416],
              [-40.378733779049469, -7.123732453025026],
              [-40.379710341862399, -7.124154925282218],
              [-40.380267297708713, -7.125726581367266],
              [-40.38008801769378, -7.126070868176145],
              [-40.379958307686081, -7.127228620182194],
              [-40.379355600357336, -7.129193225011732],
              [-40.379332690141084, -7.13092937566008],
              [-40.379298371358686, -7.133479504455309],
              [-40.379676023230957, -7.135341546672594],
              [-40.38083950211162, -7.139063745443516],
              [-40.381858020710141, -7.140643504850232],
              [-40.382666735800406, -7.14093959572936],
              [-40.384513061778122, -7.14102450968738],
              [-40.386370819322707, -7.140295422497728],
              [-40.388350650147906, -7.139394203428801],
              [-40.389163183621832, -7.139174364380682],
              [-40.390120677555593, -7.140871912039873],
              [-40.390162633341653, -7.142029199188057],
              [-40.390212226440418, -7.142725386085661],
              [-40.390700496075276, -7.145165361957501],
              [-40.39091411856311, -7.146439948544826],
              [-40.390551740234436, -7.147535278741771],
              [-40.390017672972924, -7.148803160776113],
              [-40.38998714948189, -7.150946556002442],
              [-40.390490692065335, -7.152057089796644],
              [-40.391284156571672, -7.153221547500655],
              [-40.391165902033919, -7.153683111661537],
              [-40.389773526677168, -7.153840948617136],
              [-40.388732119945416, -7.153889120503724],
              [-40.387732670817854, -7.155321054193138],
              [-40.387591528288311, -7.157229825728239],
              [-40.387797514316631, -7.15914625849406],
              [-40.388274351522327, -7.162219464596453],
              [-40.388297239141565, -7.165004706051759],
              [-40.38828198832546, -7.166162457155141],
              [-40.388430766940722, -7.167843776275997],
              [-40.388693982471239, -7.169877951390301],
              [-40.388671095289467, -7.171560248369641],
              [-40.387831856292138, -7.173922994653778],
              [-40.386756107772641, -7.176512680400673],
              [-40.38582150169313, -7.177310917910569],
              [-40.384379556197672, -7.176826931639379],
              [-40.383319057824075, -7.174090352065559],
              [-40.382727783613269, -7.170782057266416],
              [-40.382468387286686, -7.168458892874785],
              [-40.382037323070172, -7.166253517646952],
              [-40.381446048906476, -7.162882264226536],
              [-40.380561036571756, -7.15980095585004],
              [-40.379332690600791, -7.156775409871022],
              [-40.378562136324184, -7.153865744568084],
              [-40.37841335698721, -7.151831080554484],
              [-40.378367581998873, -7.150963226681323],
              [-40.377974657402497, -7.150150692203697],
              [-40.375556149864991, -7.149533667955878],
              [-40.374297279401162, -7.148651051208824],
              [-40.372862971131845, -7.147814676047778],
              [-40.372130531521478, -7.146243019819107],
              [-40.371726174814839, -7.141894249378065],
              [-40.371417184194648, -7.139109986703786],
              [-40.371077670856366, -7.138758574526327],
              [-40.369883690446272, -7.137233647656913],
              [-40.3695059916177, -7.135317704762025],
              [-40.369414488824013, -7.133346464423397],
              [-40.369330554256621, -7.131022345191986],
              [-40.368785054926754, -7.128699669587657],
              [-40.36827769323174, -7.12776077977598],
              [-40.367484228722937, -7.126596810078352],
              [-40.366755630361034, -7.124672787957718],
              [-40.366309293323923, -7.12362467584556],
              [-40.365100040194577, -7.123140224774422],
              [-40.364119658994433, -7.123242274391978],
              [-40.363124026392285, -7.123987077170127],
              [-40.361670627059759, -7.124660378012168],
              [-40.359465741007419, -7.125327020299697],
              [-40.357424862252749, -7.126689802571269],
              [-40.356997616967988, -7.128309608587952],
              [-40.357550753516662, -7.130288485242171],
              [-40.359145295886648, -7.132101423709093],
              [-40.359877712635701, -7.133500946956148],
              [-40.36003030950318, -7.134838957110924],
              [-40.359206321380668, -7.135980993659614],
              [-40.357924586793267, -7.136717229261806],
              [-40.356593258920071, -7.136992364569787],
              [-40.3554946235503, -7.137095346086864],
              [-40.354849937579274, -7.137720962851708],
              [-40.354731683365848, -7.137775794431676],
              [-40.354605791603561, -7.138589771485817],
              [-40.354926236883145, -7.140442757707911],
              [-40.355876093221028, -7.142954259509144],
              [-40.35691749963668, -7.147075087804946],
              [-40.357520229229316, -7.149813110986051],
              [-40.357817787372333, -7.153294027505097],
              [-40.358306057096954, -7.155616703069925],
              [-40.358294624392478, -7.156602788192727],
              [-40.357188375709377, -7.157166425960289],
              [-40.355586172895372, -7.155986740708848],
              [-40.354182364164252, -7.15295211565541],
              [-40.35371316459932, -7.149001554797316],
              [-40.353213439306572, -7.147492809381894],
              [-40.352927337458283, -7.14731255222808],
              [-40.352576367741364, -7.147540027595761],
              [-40.352030867916433, -7.149621420773584],
              [-40.351431956019645, -7.15100662605326],
              [-40.350615628209134, -7.151687540352698],
              [-40.349001993922037, -7.151204042950813],
              [-40.347769851798212, -7.148404040641715],
              [-40.347914812620473, -7.146088512541551],
              [-40.348757870535444, -7.143780156952338],
              [-40.348960037818614, -7.141636318876314],
              [-40.348940968249842, -7.138742346091973],
              [-40.348693004386838, -7.135722550876828],
              [-40.348410719877343, -7.135081195472455],
              [-40.345992212474883, -7.134301560201377],
              [-40.344313734004089, -7.134622983959177],
              [-40.342349152864614, -7.134538069910419],
              [-40.341544256664442, -7.134350687389584],
              [-40.339690317605402, -7.134446520352029],
              [-40.338812941039343, -7.135362524202363],
              [-40.33758459476482, -7.136496457476921],
              [-40.336875065920346, -7.137538329710311],
              [-40.336794948198438, -7.139157204005513],
              [-40.337573162840656, -7.14154281341467],
              [-40.338709936741495, -7.14294997493541],
              [-40.339903940146819, -7.14447490080093],
              [-40.339873415708986, -7.146554873391846],
              [-40.339739910539464, -7.147948180990129],
              [-40.339659792772288, -7.149621420964261],
              [-40.339804754000639, -7.151827749943389],
              [-40.339945896161929, -7.154151380221897],
              [-40.340323547986294, -7.156067300115909],
              [-40.340869070214119, -7.158507765021453],
              [-40.341189492439291, -7.160423707871382],
              [-40.341445070614796, -7.162973347898652],
              [-40.341704468036561, -7.165179211975945],
              [-40.341910476019962, -7.167149962410783],
              [-40.341429820334398, -7.168480825108396],
              [-40.340499008651818, -7.168755005736723],
              [-40.339633087737475, -7.168748324088126],
              [-40.337805829789225, -7.167216249754019],
              [-40.335780248314741, -7.16307108981733],
              [-40.335539920003669, -7.15930074107055],
              [-40.335349184695268, -7.156344346519305],
              [-40.334879983851607, -7.152746129569798],
              [-40.33409033864563, -7.151174939298678],
              [-40.333449470928826, -7.15144816616361],
              [-40.33298406596581, -7.151739042062654],
              [-40.331961728077417, -7.150566016236698],
              [-40.331565008379876, -7.149916091792271],
              [-40.330527419951316, -7.149557509105013],
              [-40.329794981113743, -7.148275308159983],
              [-40.329428761040653, -7.145310322527214],
              [-40.32986364177556, -7.143057262290076],
              [-40.330992803535686, -7.140928651861828],
              [-40.331153013166521, -7.137455372675925],
              [-40.330935573338344, -7.136642349346574],
              [-40.329016790141523, -7.13748873678673],
              [-40.327902880107729, -7.138568304474698],
              [-40.327380291554164, -7.138850123382414],
              [-40.326480004445663, -7.13687220045195],
              [-40.32621678872092, -7.13507309196111],
              [-40.326232038624738, -7.133798016569141],
              [-40.326418980136623, -7.132757564915398],
              [-40.326373205009929, -7.132061377026729],
              [-40.325854387709761, -7.13199034003972],
              [-40.324214070957019, -7.133595849177635],
              [-40.323622796477544, -7.13439313296012],
              [-40.322981929568321, -7.134910483371103],
              [-40.322070209851198, -7.133800880260676],
              [-40.32173069652184, -7.133439922142763],
              [-40.321555234796847, -7.133386044324995],
              [-40.321444593716819, -7.133205787311462],
              [-40.320876206309734, -7.132447619379213],
              [-40.320361232162028, -7.132150550742058],
              [-40.318270759316739, -7.132816680120293],
              [-40.317095847527071, -7.13413133735809],
              [-40.316607554349403, -7.136158852891524],
              [-40.315917095084906, -7.14008559395014],
              [-40.315466962583272, -7.143442062597694],
              [-40.314974874344394, -7.145577821484927],
              [-40.313807552773234, -7.14654865577698],
              [-40.312586867043507, -7.146932035526262],
              [-40.311026645014444, -7.146918205372779],
              [-40.309084973963778, -7.145268831661185],
              [-40.308608136946205, -7.141951478048524],
              [-40.309973783314547, -7.139478602863454],
              [-40.311030462891239, -7.13821831220889],
              [-40.311385226987902, -7.137475883913265],
              [-40.311400501712406, -7.136426841176137],
              [-40.310828296010179, -7.136075871814898],
              [-40.309901302534385, -7.136060132175547],
              [-40.308741642462998, -7.136334803621668],
              [-40.3074789767103, -7.135858919458893],
              [-40.306269722632415, -7.135320078713707],
              [-40.30511767592413, -7.135069738294619],
              [-40.304255549172389, -7.134827990053673],
              [-40.302474113350051, -7.133992103627016],
              [-40.300925321844019, -7.133218663012481],
              [-40.298869192875593, -7.131216898318946],
              [-40.29808718380071, -7.129410152470848],
              [-40.299075178126159, -7.129073036260873],
              [-40.300528601491003, -7.128345369746477],
              [-40.301180900955075, -7.127023099143686],
              [-40.301535664871444, -7.126506703502091],
              [-40.302977633636893, -7.126638299856368],
              [-40.305842477870527, -7.128710169769779],
              [-40.307562889079414, -7.129600887820269],
              [-40.309527469168827, -7.129739654545535],
              [-40.310805385588623, -7.129238952539348],
              [-40.311457708053588, -7.127916704934932],
              [-40.31067188000408, -7.126109960082199],
              [-40.310347640390596, -7.124709015175971],
              [-40.311637011510243, -7.123222227055738],
              [-40.313483313295031, -7.123533614657506],
              [-40.315138881495983, -7.125012278152128],
              [-40.315882775807367, -7.125597848249884],
              [-40.317618459684716, -7.125511537135147],
              [-40.319304551376121, -7.12483728248496],
              [-40.321448434667289, -7.124397161800897],
              [-40.323989016701567, -7.124552623881025],
              [-40.32635411293267, -7.124753835550212],
              [-40.328272895077767, -7.123970871730978],
              [-40.329215139501251, -7.122656703305512],
              [-40.330149722484464, -7.121966732154689],
              [-40.331137763899164, -7.121520884591415],
              [-40.331717582914827, -7.121410731705544],
              [-40.332354654571517, -7.121246191188964],
              [-40.333983516574271, -7.120689747790492],
              [-40.335021150276596, -7.120704532521708],
              [-40.337100144475833, -7.120961065634845],
              [-40.339232549157863, -7.121397856716509],
              [-40.341708286337379, -7.121951458373768],
              [-40.344363327480984, -7.122224220845944],
              [-40.346160059985884, -7.122074021159874],
              [-40.346633079303075, -7.121268146333856],
              [-40.346942069323184, -7.119765641527946],
              [-40.346518643591793, -7.11686407847855],
              [-40.345900640080849, -7.115409722654968],
              [-40.346034168152251, -7.114134181359571],
              [-40.346923000230269, -7.112467623989716],
              [-40.346369840541797, -7.110660901317773],
              [-40.344637974717806, -7.110638480085584],
              [-40.34336387758799, -7.110732893518313],
              [-40.342730624218504, -7.110499224411257],
              [-40.342452157773053, -7.10974058971205],
              [-40.343154073253984, -7.109223240348161],
              [-40.344950783073195, -7.108675342029419],
              [-40.346877225632717, -7.107303944719602],
              [-40.348746415679457, -7.105824792006402],
              [-40.349921350427472, -7.104564035444497],
              [-40.349990012674127, -7.103641372996345],
              [-40.349246141222785, -7.103227469876558],
              [-40.346694104660457, -7.103832108262951],
              [-40.344248890849649, -7.105069022965512],
              [-40.340773703825306, -7.105603556041698],
              [-40.339671249305972, -7.105932104215006],
              [-40.337412905956633, -7.106191989886608],
              [-40.336257086990571, -7.106294971362054],
              [-40.335517032927832, -7.105356545371839],
              [-40.335421666696789, -7.103964193595224],
              [-40.335555170902275, -7.102516496329067],
              [-40.336558440037919, -7.101021628029116],
              [-40.337496865164852, -7.100114658173935],
              [-40.339530130650544, -7.099150039524248],
              [-40.342204216624616, -7.098202068279525],
              [-40.344767684832654, -7.096675232397417],
              [-40.347979657303355, -7.094115581180783],
              [-40.349959488344439, -7.092924907834965],
              [-40.351012349583584, -7.092126203423489],
              [-40.352114804154063, -7.091734255626731],
              [-40.353228690193625, -7.090645630991001],
              [-40.353705528660349, -7.089613281518792],
              [-40.353720778558149, -7.088338206130343],
              [-40.353736052320329, -7.087234796718898],
              [-40.353400357181471, -7.086593930274813],
              [-40.352648848597269, -7.086415115749927],
              [-40.35132133993379, -7.086391762845617],
              [-40.34964668070657, -7.086369340662971],
              [-40.347685917764785, -7.085995507350275],
              [-40.346243973175724, -7.08562882140295],
              [-40.344576950258961, -7.085145301975456],
              [-40.342612370117443, -7.085060410937795],
              [-40.340003127009254, -7.085601626660851],
              [-40.338774756690221, -7.08679898355689],
              [-40.337885971703088, -7.088356809570651],
              [-40.336489755025362, -7.088966686837064],
              [-40.335002058307893, -7.087903837004566],
              [-40.334799867371963, -7.085815738295845],
              [-40.333953015512463, -7.084298425677851],
              [-40.332514865076682, -7.083705731082414],
              [-40.332171532628877, -7.083525961884689],
              [-40.330943210310998, -7.084723294780007],
              [-40.330058220483608, -7.086046054197021],
              [-40.328932854844595, -7.08800299864347],
              [-40.328272896166837, -7.089668579193073],
              [-40.32789908610939, -7.091803872167134],
              [-40.327094166566027, -7.095857948362252],
              [-40.326842406242456, -7.097242198911204],
              [-40.326476185722001, -7.098626938367277],
              [-40.326178651218605, -7.099378423652914],
              [-40.325541579519694, -7.099597307846503],
              [-40.324385737691038, -7.099528157349022],
              [-40.323416811420657, -7.098355131580164],
              [-40.322245695080973, -7.095265696079519],
              [-40.322032073500104, -7.094099841863299],
              [-40.322100734745099, -7.093177156419211],
              [-40.321940500696648, -7.09212860154587],
              [-40.321551417340523, -7.090971780138457],
              [-40.321047873801554, -7.089797800743692],
              [-40.321059305405143, -7.08892948093102],
              [-40.321765039120095, -7.088077364567342],
              [-40.321768857185631, -7.087960065269884],
              [-40.32177265347962, -7.087552843701427],
              [-40.321719264082127, -7.087263900382714],
              [-40.321604828028029, -7.087083177370596],
              [-40.321089828963821, -7.086677865383269],
              [-40.319998830915395, -7.086309759721561],
              [-40.318617911037272, -7.085771360848014],
              [-40.317870220521705, -7.085474803029547],
              [-40.31677540245731, -7.085161507038304],
              [-40.315627173896097, -7.084685622975132],
              [-40.314421738736762, -7.084209739867134],
              [-40.313555793873768, -7.08413968060509],
              [-40.313262054715473, -7.084420987696403],
              [-40.312541094147804, -7.086493788765836],
              [-40.312460953564283, -7.087950053016506],
              [-40.312384678053192, -7.089279495026837],
              [-40.312201579704571, -7.090085346076406],
              [-40.311789585191626, -7.090601765672464],
              [-40.311091487829771, -7.090938416115836],
              [-40.309821209670993, -7.091041397503291],
              [-40.308383060246605, -7.090439621962509],
              [-40.307238648888251, -7.0896738176313],
              [-40.306437571075826, -7.088961913248593],
              [-40.306326930080537, -7.088672946885444],
              [-40.306269723617547, -7.088501280864534],
              [-40.306113308634387, -7.087334960699282],
              [-40.306181946785827, -7.086530040555064],
              [-40.306311679743295, -7.085426165242286],
              [-40.306216312644196, -7.083861680500944],
              [-40.305766180452451, -7.083040067049398],
              [-40.30525118234609, -7.082689096739066],
              [-40.30438907868465, -7.082329583207413],
              [-40.302950929118154, -7.081908531590865],
              [-40.301333498497783, -7.081886109455313],
              [-40.300398892620379, -7.082449258361619],
              [-40.299346031118056, -7.083591759718212],
              [-40.297709531629245, -7.084844412959217],
              [-40.296134081939869, -7.085807146977907],
              [-40.294104634623807, -7.086536722031133],
              [-40.292597823043337, -7.086748901581967],
              [-40.291678489927598, -7.086208640084005],
              [-40.291354226350784, -7.084753329513854],
              [-40.29119019700061, -7.084121054158242],
              [-40.29062180969018, -7.083236039993641],
              [-40.289473581174661, -7.082705791257061],
              [-40.287406019543575, -7.081635327023499],
              [-40.286383682606413, -7.080516643898322],
              [-40.284495399986156, -7.07910233485901],
              [-40.283930832691347, -7.078162955023546],
              [-40.283820190738574, -7.077819645986073],
              [-40.284407647201498, -7.077067694935248],
              [-40.284533537999522, -7.076198862220235],
              [-40.283705754094157, -7.073298254886894],
              [-40.283556975417333, -7.071670788486237],
              [-40.284499219785481, -7.070410986745411],
              [-40.285540625597953, -7.070245537548857],
              [-40.286925363807732, -7.070322790172276],
              [-40.288596204681149, -7.070815833537748],
              [-40.290606560808115, -7.07176904342261],
              [-40.292780921329332, -7.073300163987146],
              [-40.294333508084115, -7.07372980663957],
              [-40.296580372770968, -7.074402152387909],
              [-40.297732419573563, -7.074534703499888],
              [-40.29952533386858, -7.074674425071543],
              [-40.301371636746602, -7.074868001363811],
              [-40.302928064660399, -7.075062554423118],
              [-40.305583081845704, -7.075272382252759],
              [-40.306799949372049, -7.075178434767508],
              [-40.307398860431604, -7.073566731854283],
              [-40.307715487799491, -7.071540171195517],
              [-40.307501865393, -7.070148284334207],
              [-40.307002140002616, -7.068747806300307],
              [-40.306780881195287, -7.067934316981804],
              [-40.306788517553393, -7.067409796118168],
              [-40.307837560577589, -7.066909604924946],
              [-40.308817941729423, -7.066861431980334],
              [-40.310088243745824, -7.066939640508559],
              [-40.311465345381258, -7.067821790322972],
              [-40.312487682322725, -7.068940450448474],
              [-40.313399401998296, -7.070113453163504],
              [-40.314478945360321, -7.071458143986047],
              [-40.315501329301583, -7.072576316115893],
              [-40.316531280466521, -7.073342120354416],
              [-40.317221717334725, -7.073702146747807],
              [-40.318144891796713, -7.073771763056325],
              [-40.318957424355382, -7.07343464770466],
              [-40.319846234422961, -7.071768089344205],
              [-40.320387937799026, -7.070265583732895],
              [-40.320971575106839, -7.06974823327692],
              [-40.322077848688323, -7.069302362804075],
              [-40.323351944864882, -7.06914501574133],
              [-40.325030423288212, -7.068877981649852],
              [-40.32700261677828, -7.068446917767059],
              [-40.327876176136073, -7.067820836186667],
              [-40.330664745301313, -7.066871910032656],
              [-40.333903422796496, -7.066573420350999],
              [-40.336325748404462, -7.067064088322363],
              [-40.33736336005839, -7.067142296669026],
              [-40.338572614329863, -7.067446513802208],
              [-40.341052170580305, -7.067882326146353],
              [-40.342730625007135, -7.067615316054417],
              [-40.343314285081846, -7.067396408817519],
              [-40.343665231023813, -7.066880035172256],
              [-40.345019445912371, -7.065103813826989],
              [-40.346079943599491, -7.063662800252342],
              [-40.347701215471389, -7.063341376439502],
              [-40.34960472495105, -7.063544056722088],
              [-40.350745315982124, -7.064770494301746],
              [-40.351203085578433, -7.065013197213141],
              [-40.351550236092741, -7.065075665112625],
              [-40.35189736360725, -7.065138134012139],
              [-40.353343126626292, -7.064925932410616],
              [-40.353518612487456, -7.06480767725332],
              [-40.35450281156767, -7.064822950933579],
              [-40.356001986027664, -7.064845837971983],
              [-40.356120241290583, -7.064727582769858],
              [-40.356295702286808, -7.06443766263611],
              [-40.356146946558916, -7.062873643845924],
              [-40.355864663085903, -7.062178409770558],
              [-40.355311550251777, -7.060542841090887],
              [-40.35491862468978, -7.059675965952893],
              [-40.35526957063194, -7.05915954630749],
              [-40.356661923033535, -7.058938286717376],
              [-40.357352383174977, -7.058954491161452],
              [-40.359084271868028, -7.059148580360281],
              [-40.360468987046907, -7.059280176659956],
              [-40.361685877538413, -7.059240128851865],
              [-40.363249919906757, -7.058801428692719],
              [-40.365458623157231, -7.057845844120464],
              [-40.367259175002069, -7.057234546175406],
              [-40.367728376157878, -7.056663294948985],
              [-40.367678782917373, -7.056138774037715],
              [-40.366828111849408, -7.054911404690445],
              [-40.365977417646526, -7.053856145317243],
              [-40.365237364710509, -7.052745587360746],
              [-40.364447742348396, -7.051346553086193],
              [-40.364405761557613, -7.050179720012127],
              [-40.364638476917861, -7.050188312142896],
              [-40.365851549394264, -7.05020260700655],
              [-40.367064620962779, -7.050099626569643],
              [-40.368685869653874, -7.050013781349864],
              [-40.370200294697298, -7.0491592916302],
              [-40.370330004645211, -7.04806447426053],
              [-40.370288048896114, -7.046843764507509],
              [-40.369258075630817, -7.046195726566933],
              [-40.36822428235012, -7.045601611301929],
              [-40.367148534185169, -7.043958895258601],
              [-40.366294044773994, -7.043201683107154],
              [-40.364974148776156, -7.042256108715804],
              [-40.36341776680738, -7.042115921335938],
              [-40.361914774372011, -7.042093523295223],
              [-40.360865732150941, -7.04283786103544],
              [-40.359690797274702, -7.044270283572614],
              [-40.358977450245305, -7.045592553127608],
              [-40.357176921225481, -7.04642988472538],
              [-40.355494624773272, -7.046760318439183],
              [-40.353983994847617, -7.047434107238828],
              [-40.352469568722427, -7.048396840311757],
              [-40.351534962791263, -7.049023388843322],
              [-40.349860302654861, -7.048883689367313],
              [-40.348891377239752, -7.04788279558089],
              [-40.348433608728087, -7.047531384319564],
              [-40.346297384838401, -7.047383559531657],
              [-40.345725180179429, -7.046969190556685],
              [-40.345286479532461, -7.045342678918413],
              [-40.345587856047558, -7.044537316959166],
              [-40.346171493355868, -7.044019943502177],
              [-40.346522439155279, -7.043684247779393],
              [-40.346419458656349, -7.042690035631164],
              [-40.345732816885068, -7.042167423213185],
              [-40.343711006058207, -7.042191265491766],
              [-40.342269037283195, -7.0420596931507],
              [-40.341406911665665, -7.041637173998741],
              [-40.340834730091537, -7.041114095673448],
              [-40.340510488640469, -7.03948711912976],
              [-40.340521921196874, -7.038673165996285],
              [-40.341632011979463, -7.037937839251241],
              [-40.344294667670049, -7.037450547237619],
              [-40.345931142296969, -7.036026227014785],
              [-40.3465873058664, -7.034523255496262],
              [-40.347579119437512, -7.033841829336763],
              [-40.34884942146067, -7.033919547864034],
              [-40.35033714123032, -7.034928079006377],
              [-40.3520613935667, -7.03564666506401],
              [-40.353274465998801, -7.035715816598123],
              [-40.354205253680682, -7.035441633960575],
              [-40.354617248285145, -7.034807426066413],
              [-40.354701160188135, -7.032899119518873],
              [-40.354300621231786, -7.032601584981837],
              [-40.352755672996963, -7.031421411819254],
              [-40.351561668568536, -7.029896484988416],
              [-40.349913714685052, -7.027911879547967],
              [-40.348780759877258, -7.026323530144906],
              [-40.348109391908466, -7.024634084705775],
              [-40.347609643551294, -7.023179728997572],
              [-40.347563868592609, -7.022257066469554],
              [-40.347636348944917, -7.021153657112723],
              [-40.347647781542086, -7.020285337305081],
              [-40.347369316130823, -7.019472314941726],
              [-40.346221086518838, -7.019050797564097],
              [-40.345473397047179, -7.018691260128455],
              [-40.344908828693043, -7.017815302918538],
              [-40.344226005083662, -7.017048567964736],
              [-40.343302830843477, -7.016689495390083],
              [-40.341803656384378, -7.016666608360052],
              [-40.340697406702745, -7.017239768076972],
              [-40.339343191781225, -7.019070356093062],
              [-40.338923560914672, -7.02011176254855],
              [-40.33781349204164, -7.020964831590982],
              [-40.336825474537754, -7.021528445456563],
              [-40.335887049410864, -7.022444449256735],
              [-40.335421667415979, -7.022780144889967],
              [-40.334952467348906, -7.023242686762905],
              [-40.334414581093142, -7.024564957449293],
              [-40.33404456529243, -7.026356429465211],
              [-40.333724120825444, -7.028555099089065],
              [-40.333651640430141, -7.029712897120524],
              [-40.333521953570362, -7.030699007132522],
              [-40.333396061867539, -7.031450026550917],
              [-40.333045115977455, -7.031903022573029],
              [-40.330943211486101, -7.033500429397878],
              [-40.329714865266119, -7.034580461992316],
              [-40.328551385994061, -7.035144565717277],
              [-40.327220059168951, -7.035365335360808],
              [-40.326693627602808, -7.035701030946108],
              [-40.326403707503175, -7.035874117686629],
              [-40.325938324600429, -7.036092024022668],
              [-40.325411893934813, -7.036555031847878],
              [-40.324828232720584, -7.036954640006865],
              [-40.323836420152396, -7.037635554168055],
              [-40.322032074026126, -7.038653607682325],
              [-40.319766140373758, -7.039374591609731],
              [-40.318263146946002, -7.039351704573488],
              [-40.318099117465472, -7.038881525252698],
              [-40.317957952400157, -7.036395332986672],
              [-40.318728528980202, -7.035126917161181],
              [-40.320002649332807, -7.034743537443888],
              [-40.32197863902725, -7.034014450338052],
              [-40.323721984312833, -7.033340194723172],
              [-40.325697996184523, -7.03238509896635],
              [-40.326689809660969, -7.031821461104764],
              [-40.327162829103706, -7.030843478312544],
              [-40.327525229464179, -7.029684771513093],
              [-40.327540504077078, -7.02876255103125],
              [-40.327273470271628, -7.027072174916176],
              [-40.327006436367256, -7.025508132047109],
              [-40.327017845098773, -7.024468146266055],
              [-40.327490911406507, -7.023661829449254],
              [-40.328196622081158, -7.022855022816546],
              [-40.329085454140802, -7.021188465460393],
              [-40.329280006903581, -7.019741258253943],
              [-40.328997699428022, -7.019045582188637],
              [-40.32825003268286, -7.019038410649591],
              [-40.327323063160541, -7.019077502694715],
              [-40.326045123784638, -7.019524306031829],
              [-40.324942693127923, -7.020033554132509],
              [-40.323672414241429, -7.019784167633246],
              [-40.322833151683632, -7.017796723850101],
              [-40.322901790878504, -7.016928403089723],
              [-40.324126364804748, -7.016255568061001],
              [-40.325114336173073, -7.015864086167684],
              [-40.325297458430221, -7.015167432471022],
              [-40.325076198709304, -7.014236178861085],
              [-40.324565018797308, -7.01359577628864],
              [-40.324469651593816, -7.012149057855887],
              [-40.325636948928818, -7.011467654833496],
              [-40.327143759698309, -7.011019896679321],
              [-40.329341032290749, -7.010995566532732],
              [-40.331130128712914, -7.011017988789696],
              [-40.332350837533376, -7.010516842731745],
              [-40.333346468390879, -7.009429173001836],
              [-40.33394156110915, -7.008278545336595],
              [-40.334303963551392, -7.007011595185287],
              [-40.334368806413195, -7.006604373667963],
              [-40.333918674254932, -7.005728417550204],
              [-40.333460905833263, -7.005259704994211],
              [-40.332831493440679, -7.005016978955119],
              [-40.331217859053709, -7.004650781889137],
              [-40.329379170527879, -7.003923603804935],
              [-40.326396116850425, -7.002150224082759],
              [-40.326052761450178, -7.001907055268981],
              [-40.32593832529551, -7.001853155502145],
              [-40.32548055664887, -7.001673875217868],
              [-40.324450582433933, -7.000961947671765],
              [-40.323767759049325, -6.999905756452225],
              [-40.323264239307441, -6.998975922617184],
              [-40.322875108987624, -6.997756167603419],
              [-40.322306722483141, -6.997106731043075],
              [-40.321734516914255, -6.996574549779201],
              [-40.320521444534229, -6.996442488627362],
              [-40.318476770424631, -6.99832932676624],
              [-40.316828815389776, -7.000395958138186],
              [-40.315497488665223, -7.000489882543822],
              [-40.31417377515433, -7.000240498006868],
              [-40.312457159308849, -6.998825234132656],
              [-40.310751998832338, -6.996831595724836],
              [-40.309859348820417, -6.994618094262487],
              [-40.309290962358531, -6.993914293028551],
              [-40.308314422544086, -6.99349228579399],
              [-40.306532939704489, -6.992655911412654],
              [-40.305903505530665, -6.992133299048862],
              [-40.305792887447829, -6.991952575043467],
              [-40.305335118935538, -6.991601139789569],
              [-40.304873531316829, -6.991421394506451],
              [-40.304068635115172, -6.991233989003092],
              [-40.302222308373231, -6.990859200813107],
              [-40.300665928322104, -6.990836312744142],
              [-40.299334600683949, -6.990821993798381],
              [-40.298522067171824, -6.991104278481139],
              [-40.297884995476643, -6.991323162678945],
              [-40.296656672399607, -6.992231063299099],
              [-40.295779294942697, -6.993029278846898],
              [-40.295653404103646, -6.993961021178658],
              [-40.29563815331187, -6.99511877424508],
              [-40.296404888532606, -6.998200594415478],
              [-40.297007618585823, -7.000405480702379],
              [-40.297003800296189, -7.000812702265186],
              [-40.296362933487117, -7.001212287379403],
              [-40.295378756370148, -7.001250913384958],
              [-40.29508883540592, -7.001251869154329],
              [-40.295088835314381, -7.001369657450215],
              [-40.294398375352614, -7.001127419362636],
              [-40.293707915433188, -7.000830816600065],
              [-40.292971657250156, -7.000000657991763],
              [-40.292002730960753, -6.998836689198915],
              [-40.290747680652309, -6.99770992596221],
              [-40.289885553938532, -6.997413812064172],
              [-40.288790759774486, -6.997226895332959],
              [-40.287920996805653, -6.997328456051425],
              [-40.286101400032237, -6.99933212966046],
              [-40.285651267586545, -7.002688575243591],
              [-40.285990757843805, -7.003148742755807],
              [-40.286620192874771, -7.00385254403652],
              [-40.287238173578359, -7.005080890172649],
              [-40.287219103762745, -7.006302064857517],
              [-40.286627830022177, -7.007452204523011],
              [-40.286036533464504, -7.008367253593833],
              [-40.285090493948275, -7.009861655925882],
              [-40.284846370853437, -7.010902596513765],
              [-40.285410939022825, -7.012013153313831],
              [-40.285861071319282, -7.012708386507819],
              [-40.286604942679389, -7.013240056907838],
              [-40.287634892798089, -7.014069284751779],
              [-40.288374946868359, -7.015007686718167],
              [-40.289038677664429, -7.016986563407084],
              [-40.288676323314547, -7.018136213254553],
              [-40.288085002657375, -7.019177642570849],
              [-40.288069751954176, -7.0202176293438],
              [-40.288744985119358, -7.021617619502665],
              [-40.289370578218183, -7.02226707710829],
              [-40.290568399846819, -7.023493980708843],
              [-40.292166760380901, -7.025080886470596],
              [-40.293246327833046, -7.026316846926217],
              [-40.294325895150799, -7.02772496235382],
              [-40.294943874717227, -7.029124952470972],
              [-40.295542785563242, -7.031619294036178],
              [-40.295638153714279, -7.033129435089263],
              [-40.295340595309675, -7.033763131071918],
              [-40.293494292303848, -7.033732164817942],
              [-40.292235423062586, -7.032560093838807],
              [-40.291037624258259, -7.031559688882253],
              [-40.290057242291958, -7.031372283238109],
              [-40.288210916329767, -7.031286927309733],
              [-40.287051256262643, -7.031561597765229],
              [-40.285895390352891, -7.031600690631265],
              [-40.283671435766053, -7.029309493589459],
              [-40.283114480997192, -7.027620048251253],
              [-40.282965702396545, -7.025883897510464],
              [-40.282912290905678, -7.025712231494807],
              [-40.282393496697651, -7.025523871216655],
              [-40.280947733775108, -7.025627341473126],
              [-40.280482328874839, -7.02584573680569],
              [-40.279906304061967, -7.025666456428834],
              [-40.279803323462211, -7.024798602535309],
              [-40.280165723819195, -7.023639895742365],
              [-40.280753180221595, -7.022959890263539],
              [-40.280985871629419, -7.022905080772147],
              [-40.28087525377309, -7.022434925496055],
              [-40.280070357616886, -7.022193153314897],
              [-40.279559176799417, -7.021435009448613],
              [-40.27890685460109, -7.018587788959675],
              [-40.27857497674929, -7.01753969996654],
              [-40.277258923824874, -7.016475872302839],
              [-40.276103058007543, -7.016397663872037],
              [-40.27477936850287, -7.016148279334131],
              [-40.27345565413259, -7.015726761825413],
              [-40.272372269524631, -7.014725890967314],
              [-40.271708514862517, -7.012575370309142],
              [-40.272418066814076, -7.011370377064824],
              [-40.273577727061429, -7.010861106010278],
              [-40.274912873936053, -7.010531603017166],
              [-40.276251814008987, -7.009913157754437],
              [-40.277071983916315, -7.009051520542783],
              [-40.277663281515238, -7.008082570795017],
              [-40.278311762719738, -7.007104098148552],
              [-40.278376628672198, -7.006579111337794],
              [-40.278437654552981, -7.006180946765767],
              [-40.278273625112924, -7.005656890771854],
              [-40.277701419455553, -7.005242521805363],
              [-40.277068212959477, -7.005171949736011],
              [-40.275904663879672, -7.005500497868623],
              [-40.274146114762992, -7.007395463173165],
              [-40.272925405817432, -7.008068275202682],
              [-40.271708514338073, -7.008107367024366],
              [-40.271136309631871, -7.007756420678736],
              [-40.270976098432456, -7.006888566743907],
              [-40.271975548437993, -7.005565341432041],
              [-40.272967385955511, -7.004938793948265],
              [-40.273844762410839, -7.004140555402258],
              [-40.274554290452841, -7.002818284859604],
              [-40.274684001167223, -7.002012899776371],
              [-40.274230049812616, -7.001426397931617],
              [-40.272669826884723, -7.00128621056003],
              [-40.270987554316115, -7.00178784425551],
              [-40.269240413851762, -7.002742475191414],
              [-40.267848039289163, -7.003189254439554],
              [-40.266573942162751, -7.003292259835332],
              [-40.265536331557989, -7.003159218826236],
              [-40.264735252509261, -7.00274533868037],
              [-40.264105817437283, -7.002095880076695],
              [-40.26365950434576, -7.001102133674448],
              [-40.263384833174484, -6.999945336380764],
              [-40.263564136230627, -6.999538114955532],
              [-40.265315071898669, -6.998284995808216],
              [-40.266364114005974, -6.997667481314866],
              [-40.26666549069612, -6.996626540774757],
              [-40.266627329045377, -6.995233699076663],
              [-40.265383756416973, -6.993129883698307],
              [-40.263621366348708, -6.991136222260457],
              [-40.262198466621896, -6.989493994984032],
              [-40.261858952322939, -6.989087729151936],
              [-40.261569055408692, -6.989025283301166],
              [-40.260352164705807, -6.989354296390835],
              [-40.258780510004996, -6.990317029414106],
              [-40.257609392483047, -6.991287888698664],
              [-40.25614835678067, -6.992540054075539],
              [-40.255679154727098, -6.992993050001866],
              [-40.255332004074965, -6.993111770022774],
              [-40.254019746305786, -6.991812875778554],
              [-40.253298738041025, -6.989662332088759],
              [-40.253314035921925, -6.988387255730663],
              [-40.253623025739067, -6.987111249605542],
              [-40.253882422479101, -6.985093745879555],
              [-40.25384046667758, -6.983927401827119],
              [-40.253733642876753, -6.983348514208971],
              [-40.253676413460703, -6.983113913568702],
              [-40.253451359399676, -6.982652815154275],
              [-40.253115664255439, -6.982011947730277],
              [-40.252718943547514, -6.98136153431629],
              [-40.252318404634863, -6.981010123109944],
              [-40.2518034053939, -6.98083033078795],
              [-40.250704770127456, -6.980815546027335],
              [-40.249598520508968, -6.981324817124094],
              [-40.248431222962353, -6.982295652410989],
              [-40.247431773103315, -6.983437688802831],
              [-40.246779450664626, -6.984759959384277],
              [-40.246474277870455, -6.985972565890584],
              [-40.245532035517876, -6.987232368621756],
              [-40.245173452414107, -6.988038219521311],
              [-40.244799618429198, -6.990119146777904],
              [-40.244551654407516, -6.991105720681638],
              [-40.244410511786533, -6.99318615912185],
              [-40.244097703771743, -6.994751132514282],
              [-40.243666640236455, -6.996769125104471],
              [-40.243361469398685, -6.998036099286519],
              [-40.243170733931706, -6.999077015910037],
              [-40.242640507984433, -6.99994629129759],
              [-40.242465023091157, -7.000109855182916],
              [-40.242346768831119, -7.000227620386618],
              [-40.24217510303756, -7.000228109250672],
              [-40.24165630882429, -7.000048340924369],
              [-40.241312976377579, -6.999868571734012],
              [-40.240633972062348, -6.998694591233119],
              [-40.24019527140905, -6.997067614629556],
              [-40.239920624153058, -6.996019525689142],
              [-40.23935985009048, -6.994736836931376],
              [-40.238627434234076, -6.993454614038401],
              [-40.238085753116685, -6.990779082632856],
              [-40.237944588812105, -6.988581786675771],
              [-40.238078116952884, -6.987188502119031],
              [-40.239539153700143, -6.985873380121097],
              [-40.240538603558775, -6.984730878732801],
              [-40.241766926717226, -6.983706141816458],
              [-40.243582728370981, -6.98187413518637],
              [-40.245577810072398, -6.979644429075214],
              [-40.245997441103505, -6.978377012986329],
              [-40.24619199485614, -6.976929293803003],
              [-40.246550577916928, -6.976177831578497],
              [-40.247260129819367, -6.975027227014455],
              [-40.248824124206486, -6.974535116168134],
              [-40.25015927007663, -6.974205637171455],
              [-40.252535821887662, -6.973539482994131],
              [-40.25381376116016, -6.973210469950344],
              [-40.254801755518891, -6.972818989056503],
              [-40.256079694924757, -6.972317819043108],
              [-40.256953253178672, -6.971809036762973],
              [-40.257418659132334, -6.971518650860449],
              [-40.257765785777572, -6.971408963784967],
              [-40.257769604841407, -6.971291663490062],
              [-40.258585955630295, -6.970611215193196],
              [-40.259699842685336, -6.969468712891734],
              [-40.260172861984373, -6.968662396083233],
              [-40.26000501438731, -6.968373452683326],
              [-40.258975040217621, -6.967607625472825],
              [-40.258643163499912, -6.96637832357566],
              [-40.258662254301882, -6.965166695843659],
              [-40.258627912842663, -6.963484420888231],
              [-40.258299853313716, -6.961975210670627],
              [-40.257792468514246, -6.961153154201195],
              [-40.257044801955431, -6.960911382072386],
              [-40.255766886682927, -6.96123993012027],
              [-40.253947265875432, -6.963307027341298],
              [-40.253306399078092, -6.963697554509001],
              [-40.252745649870171, -6.962595588674513],
              [-40.252257356144263, -6.960209489585232],
              [-40.251654627089543, -6.957995057380817],
              [-40.251029033162041, -6.957119566141567],
              [-40.249873168297533, -6.957104781339749],
              [-40.2469015236994, -6.959029781522662],
              [-40.245310799195266, -6.961213713218473],
              [-40.244891169257478, -6.962363363010486],
              [-40.24447156131243, -6.963522069748405],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3317',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATAS DE SIRIJI',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2014',
        GID7: '12306',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 40.548 de 28/03/2014',
        DT_ULTIM10: '24/04/2018',
        CODIGO_U11: '0000.26.3317',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.515662737730416, -7.593716012074344],
              [-35.514531106707039, -7.600262751113062],
              [-35.511826727827881, -7.603176637953798],
              [-35.510888439725633, -7.607713512773796],
              [-35.508731131324389, -7.608350317311352],
              [-35.507612268542772, -7.609966701385955],
              [-35.503890339483611, -7.611024767216597],
              [-35.502105578873127, -7.615262311349245],
              [-35.498756857196248, -7.616093166860319],
              [-35.497613096140221, -7.619860717383228],
              [-35.498346945179961, -7.626497201128447],
              [-35.503099699589612, -7.628364064599611],
              [-35.509182107913396, -7.629063390315929],
              [-35.514136425683667, -7.628202129084625],
              [-35.514982855561776, -7.623540722974104],
              [-35.516410572440975, -7.62282258945623],
              [-35.519785558525577, -7.622584716234472],
              [-35.522754664898564, -7.620474674512576],
              [-35.523682793137674, -7.61880358365007],
              [-35.524648970878225, -7.615977255079375],
              [-35.526617568100043, -7.610307380072462],
              [-35.525552813151847, -7.607149779216616],
              [-35.524839558985086, -7.606361799692293],
              [-35.521109821209919, -7.605602597951707],
              [-35.517872773988479, -7.606004160241223],
              [-35.517342611747452, -7.604794352495248],
              [-35.51794606407536, -7.60359673743752],
              [-35.521127195820327, -7.600083310689226],
              [-35.521737788260317, -7.598641532172794],
              [-35.520854450370884, -7.596355533961805],
              [-35.515662737730416, -7.593716012074344],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2247',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DE MIRITIBA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5414',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '21/05/2014',
        CODIGO_U11: '0000.26.2247',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.107489080402246, -7.832007318148269],
              [-35.107540490796431, -7.832352013183895],
              [-35.107556691205502, -7.833248124547044],
              [-35.107527136404634, -7.833914403329954],
              [-35.107520926771485, -7.834247547304184],
              [-35.107497680193859, -7.834419868893143],
              [-35.107434333050378, -7.834477224995643],
              [-35.106795136657688, -7.834470929305427],
              [-35.106011963149506, -7.83445875473817],
              [-35.105689387965619, -7.834521747772148],
              [-35.105493475505497, -7.834607700381997],
              [-35.105251125622786, -7.835049774269873],
              [-35.105204528462167, -7.835446125699922],
              [-35.105221202926352, -7.835986073596589],
              [-35.105519649983066, -7.836767506639586],
              [-35.105717404841108, -7.837377373705927],
              [-35.106153396310546, -7.837915293812261],
              [-35.106408502543424, -7.838382110861221],
              [-35.107062039182395, -7.839098975238795],
              [-35.107498587446194, -7.839744917110326],
              [-35.107862193202543, -7.840247183698269],
              [-35.108337014667171, -7.841325007348884],
              [-35.108732499029962, -7.841963059872256],
              [-35.108755440547313, -7.842072145015237],
              [-35.108887771641811, -7.842158443092315],
              [-35.109354179033957, -7.842544142304757],
              [-35.109932613523341, -7.842685270782726],
              [-35.111089122107877, -7.842895508312603],
              [-35.111776245530756, -7.843108100299958],
              [-35.111857747680673, -7.843470657983948],
              [-35.112392759828673, -7.843936406385651],
              [-35.113031159892394, -7.844626268466398],
              [-35.113738679336336, -7.845252957430261],
              [-35.114653674477509, -7.84587408227608],
              [-35.11532695416961, -7.846253796309391],
              [-35.116587434491933, -7.846823458965259],
              [-35.117203139568673, -7.847272068351893],
              [-35.117646038552564, -7.847697502694792],
              [-35.118353917397151, -7.848088697082003],
              [-35.119390301207723, -7.848261826060252],
              [-35.120196598533781, -7.848199277025766],
              [-35.120570923884955, -7.848222609214811],
              [-35.121209745609846, -7.848527546630414],
              [-35.121589658315244, -7.848723145887712],
              [-35.121806870550529, -7.848782668660497],
              [-35.121816079249946, -7.848539404834025],
              [-35.121699282372454, -7.848120828765957],
              [-35.122955858813462, -7.844425477771286],
              [-35.122902350452968, -7.84400256996168],
              [-35.123106197963345, -7.842032542085618],
              [-35.123541038690227, -7.83949402927126],
              [-35.123693075138625, -7.837477890551275],
              [-35.124109685263313, -7.835777978770726],
              [-35.124226137458194, -7.834663717584537],
              [-35.124232474494832, -7.834232950096909],
              [-35.124163627169992, -7.833991669877998],
              [-35.123542636947114, -7.833158189504299],
              [-35.122823707380043, -7.83238793466657],
              [-35.121673334076647, -7.831284120533618],
              [-35.121247641731671, -7.830887436479128],
              [-35.120902588832287, -7.830519453185865],
              [-35.120724321061275, -7.830347063237662],
              [-35.120545885355277, -7.830249246969907],
              [-35.120407651931565, -7.830231864359774],
              [-35.119998643837043, -7.830409559821685],
              [-35.119479586740447, -7.831052535264442],
              [-35.119341258805527, -7.831195966715674],
              [-35.118932314683349, -7.831207152171267],
              [-35.118460560836674, -7.830879307987022],
              [-35.1182131215173, -7.83074124982627],
              [-35.118069083284063, -7.830741161413552],
              [-35.117953822571906, -7.830821469576721],
              [-35.117884568700461, -7.830965004814631],
              [-35.117831683437359, -7.831872574360485],
              [-35.117808404933449, -7.832073551515082],
              [-35.117704545474794, -7.83225730684086],
              [-35.117117034790859, -7.83236014631664],
              [-35.116443558905743, -7.832170081153022],
              [-35.115948686025575, -7.831859435337177],
              [-35.11561508349245, -7.831514530625733],
              [-35.115459970649376, -7.831152549173908],
              [-35.115373936713212, -7.830916957137982],
              [-35.115097772637476, -7.830727155685921],
              [-35.114803823224378, -7.830910688826388],
              [-35.114285151756206, -7.831254897747087],
              [-35.114066180480251, -7.831369622895641],
              [-35.11309864696976, -7.831449233468531],
              [-35.111739541229085, -7.831534238331832],
              [-35.111111895839642, -7.831516412000878],
              [-35.110956453201894, -7.831430137023662],
              [-35.110806915873511, -7.83129212971386],
              [-35.110617110214243, -7.831096648477439],
              [-35.110381151974174, -7.83097009831377],
              [-35.109880061848827, -7.831044382669879],
              [-35.109407764699917, -7.831130093117739],
              [-35.108970103199923, -7.831181459032459],
              [-35.108849040523289, -7.831261703967396],
              [-35.108675891826138, -7.831566031807039],
              [-35.108612472467208, -7.83168088105739],
              [-35.108410645228332, -7.831870276587021],
              [-35.108116897103109, -7.83195039022116],
              [-35.107898042793174, -7.831944526295327],
              [-35.107725266663643, -7.831944305118092],
              [-35.107489080402246, -7.832007318148269],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2248',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DE BOM JARDIM',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5418',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2248',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.083448977407912, -8.224443724924578],
              [-35.083771833258844, -8.224446660647736],
              [-35.083732362478209, -8.224463860427646],
              [-35.083676190863002, -8.224514954194943],
              [-35.083620644721591, -8.224633752063355],
              [-35.083558967626011, -8.224707472029117],
              [-35.083407391836047, -8.224855153155699],
              [-35.083374737285581, -8.224996159231429],
              [-35.083375837185933, -8.225119997501201],
              [-35.083388100913695, -8.225226963281798],
              [-35.083394292246183, -8.225283158044824],
              [-35.083453499405394, -8.225569858667948],
              [-35.083494788241531, -8.22574411051867],
              [-35.083581707416528, -8.225968564892009],
              [-35.083736944348033, -8.226226294090129],
              [-35.083777010638791, -8.226271013147713],
              [-35.083953322376814, -8.226359681600647],
              [-35.084174764328026, -8.226419730502437],
              [-35.084282461883951, -8.226435715614555],
              [-35.08446425216011, -8.226496150551876],
              [-35.084532390917886, -8.226512521343462],
              [-35.084577607086629, -8.226500806244905],
              [-35.084746567452271, -8.226398051519277],
              [-35.08488185934646, -8.226329371394691],
              [-35.084949640125359, -8.226311936614611],
              [-35.085287974633815, -8.226151444126746],
              [-35.085321851256651, -8.226139878067576],
              [-35.085423506248013, -8.226110874857447],
              [-35.085525548535834, -8.226121284138722],
              [-35.085616488984947, -8.226159953512692],
              [-35.085679298107287, -8.226215673733657],
              [-35.085730974248776, -8.226294139616311],
              [-35.085765922288147, -8.226400806882607],
              [-35.085778453725986, -8.226541488120112],
              [-35.08577919910423, -8.2266147061111],
              [-35.085797118294352, -8.226727156886586],
              [-35.085837395482862, -8.226794381946409],
              [-35.085917290455505, -8.226855618241872],
              [-35.086019244653592, -8.226866027804737],
              [-35.086087202106818, -8.22686549210243],
              [-35.086177696253742, -8.226853451450834],
              [-35.086273963623654, -8.226852680764607],
              [-35.086347639356653, -8.226852027384682],
              [-35.086409882697666, -8.226851523618476],
              [-35.086580122613761, -8.226889510095667],
              [-35.086682403247835, -8.226928120256629],
              [-35.08677887851821, -8.226949765473245],
              [-35.086824364941954, -8.226971948608922],
              [-35.086966562540724, -8.227043978141282],
              [-35.087029283954614, -8.227082883959957],
              [-35.087069232564616, -8.227121908080724],
              [-35.087285761093582, -8.227266502321235],
              [-35.08737691034024, -8.227327679768583],
              [-35.087434391430179, -8.227422929705817],
              [-35.087554655572056, -8.227568384969308],
              [-35.08763481852278, -8.227663426728943],
              [-35.087669230394646, -8.227719474595691],
              [-35.087664409013897, -8.227803928816668],
              [-35.087659350091364, -8.22787717688187],
              [-35.087660184389797, -8.227967296812155],
              [-35.087638275400415, -8.228046325244183],
              [-35.087610652296739, -8.22812538568953],
              [-35.087537515556754, -8.228176658420489],
              [-35.087475748124596, -8.228233476908047],
              [-35.087413652295055, -8.228262184894147],
              [-35.08741463584694, -8.228363513767274],
              [-35.087427498810086, -8.228532307577284],
              [-35.087513704768547, -8.228672334920253],
              [-35.087702285183958, -8.228867876301253],
              [-35.087907175606553, -8.228973115941876],
              [-35.088129095835662, -8.229089569175159],
              [-35.088208751678629, -8.229139594037942],
              [-35.088373994616582, -8.229245132085014],
              [-35.088550011256046, -8.229294387335818],
              [-35.088702479273685, -8.229248033606281],
              [-35.088804760640535, -8.229286643019392],
              [-35.088867391507065, -8.229325458635797],
              [-35.088941544364424, -8.229381209353882],
              [-35.089095146527114, -8.229464388192216],
              [-35.08917489545486, -8.229514414644024],
              [-35.089316855871914, -8.229558241822561],
              [-35.08941934824341, -8.229619357267129],
              [-35.089504840277364, -8.229674868348956],
              [-35.089584052129005, -8.229674275694384],
              [-35.089618135085637, -8.229685217789459],
              [-35.089674902688166, -8.229696039528219],
              [-35.08980576165029, -8.22976812899565],
              [-35.089914805049354, -8.229919246387109],
              [-35.090147557863929, -8.229990532348785],
              [-35.090278478166255, -8.230057014140085],
              [-35.090455209341826, -8.230190603445514],
              [-35.090677548053471, -8.230352069653645],
              [-35.090831148514091, -8.230435247651418],
              [-35.090871007284257, -8.230457457159948],
              [-35.090933999366534, -8.230530080021316],
              [-35.090996002678125, -8.230501462201175],
              [-35.091018686887701, -8.23050125382608],
              [-35.091103823500553, -8.230523047024247],
              [-35.091188987690522, -8.230550537463406],
              [-35.091461692193278, -8.230643941238432],
              [-35.091580971203399, -8.230687975840542],
              [-35.091671851419534, -8.23071534382062],
              [-35.091814232612556, -8.23080427488631],
              [-35.091933393543457, -8.230825802692328],
              [-35.092035286903354, -8.230824908514084],
              [-35.092159596885253, -8.230790092620541],
              [-35.092126228801931, -8.230863575977958],
              [-35.092211483676621, -8.230891065691731],
              [-35.092382052294575, -8.230974064297595],
              [-35.092603258880544, -8.231005996708257],
              [-35.092654489546433, -8.231033751568864],
              [-35.092830834088439, -8.231128019761238],
              [-35.093212641118747, -8.231383837913082],
              [-35.093321684386602, -8.231551858689279],
              [-35.093424174908201, -8.23161288258628],
              [-35.093641545934275, -8.231847591728368],
              [-35.093931724065932, -8.232002826217268],
              [-35.09407383782397, -8.232057948155662],
              [-35.094312875894907, -8.232185424890146],
              [-35.094547093130977, -8.232414350368771],
              [-35.094712398074854, -8.23253118462366],
              [-35.094871448076624, -8.232580522756786],
              [-35.095081402432378, -8.232629507224713],
              [-35.095388878326659, -8.232812671908389],
              [-35.095594520216807, -8.233008028803308],
              [-35.095640064758015, -8.233041507923293],
              [-35.095765479167817, -8.233130525728738],
              [-35.095828111612811, -8.233169430178885],
              [-35.095947511681565, -8.233219067362509],
              [-35.096430350444265, -8.233378354563124],
              [-35.096561002393869, -8.233427930450864],
              [-35.096685312381275, -8.2333931130545],
              [-35.096758209283379, -8.233313635371688],
              [-35.096830869935786, -8.233206046882676],
              [-35.096907719764772, -8.232946391323477],
              [-35.096904850400392, -8.232625411232279],
              [-35.096886418359688, -8.232467857500357],
              [-35.096880404361784, -8.232428567239751],
              [-35.096804695068997, -8.232198271811217],
              [-35.096723810850563, -8.232018807755155],
              [-35.096705797018366, -8.231906268551411],
              [-35.096670009349367, -8.231709573984746],
              [-35.096664233431497, -8.231698304255843],
              [-35.096656755404453, -8.231501281339785],
              [-35.096632459248845, -8.231315643819817],
              [-35.09654535198122, -8.231074290684015],
              [-35.096550527553035, -8.231023551832916],
              [-35.096554809991737, -8.230871484242213],
              [-35.09654778009439, -8.230725170788034],
              [-35.096512828257957, -8.23061841505111],
              [-35.096466087154504, -8.230461189528354],
              [-35.096402409756671, -8.230309657230199],
              [-35.096371652159576, -8.230034019846235],
              [-35.096359861960082, -8.229983369560337],
              [-35.096148266861569, -8.22975993176564],
              [-35.096072979574195, -8.229574743077572],
              [-35.096044251740992, -8.229529966496722],
              [-35.095968576135988, -8.229305365526008],
              [-35.09591534350082, -8.229052354839119],
              [-35.095840056527635, -8.228867166040585],
              [-35.095805285083337, -8.228777401865546],
              [-35.095707284268954, -8.228586726712473],
              [-35.095739695784324, -8.228417607546314],
              [-35.095668989866546, -8.228119761387573],
              [-35.095594032231027, -8.227962682807402],
              [-35.095559558731992, -8.22791233129783],
              [-35.095490495310237, -8.22778903138099],
              [-35.095415626680307, -8.22764885699633],
              [-35.095375318051246, -8.227575936091643],
              [-35.095250325840333, -8.227531932287734],
              [-35.095079456814268, -8.22742642899787],
              [-35.094976996823902, -8.227371007664994],
              [-35.094919693626068, -8.22729266305512],
              [-35.094861790531276, -8.22715230745087],
              [-35.094861134063251, -8.227079091738185],
              [-35.094905335354198, -8.226960532726316],
              [-35.094949924407139, -8.226881293422288],
              [-35.09505092177001, -8.226779072198086],
              [-35.095174841793273, -8.226704843482283],
              [-35.095247917598357, -8.226642270075502],
              [-35.095467507080933, -8.226505350041442],
              [-35.095596811594767, -8.226402890834601],
              [-35.095703852232297, -8.226345654779292],
              [-35.095862242978441, -8.226321777454343],
              [-35.095963869434371, -8.226287167073089],
              [-35.096240699872645, -8.226217293907219],
              [-35.096415610669666, -8.226142618345117],
              [-35.096562121128287, -8.226068181179645],
              [-35.096691786414738, -8.225999526956768],
              [-35.09683808780018, -8.225902581271097],
              [-35.096972869178444, -8.225771890877541],
              [-35.097073686198648, -8.225652765564561],
              [-35.097134585798642, -8.225500219671041],
              [-35.097229806880947, -8.225386818624672],
              [-35.09726877001625, -8.225307701144487],
              [-35.097419503143726, -8.225069896660235],
              [-35.097464271004249, -8.225007561020181],
              [-35.097536957640635, -8.224905577467393],
              [-35.097576039059831, -8.224848966577055],
              [-35.097682124629408, -8.224679192151234],
              [-35.09770361071552, -8.224555056600041],
              [-35.097708461228443, -8.22445930273823],
              [-35.097719473829358, -8.224431040654967],
              [-35.097729947786156, -8.224335257437939],
              [-35.097824482107072, -8.224143033796103],
              [-35.097841181103284, -8.22410904927326],
              [-35.097851325994938, -8.223985153437424],
              [-35.097816285989424, -8.223861495792491],
              [-35.097787142956541, -8.223771704705166],
              [-35.097735881349003, -8.223738343379267],
              [-35.097592457046495, -8.223536786279778],
              [-35.097557986185478, -8.223486434886672],
              [-35.097546734679192, -8.223486493611647],
              [-35.097477492365535, -8.223346290200366],
              [-35.097345917668051, -8.223206682810682],
              [-35.097294447244806, -8.223150815272534],
              [-35.097231607618681, -8.223089404545389],
              [-35.097197226961953, -8.223038962377849],
              [-35.097089142348494, -8.22298357071929],
              [-35.096992127492172, -8.222911218866274],
              [-35.096924050535023, -8.222889245020125],
              [-35.096731340076587, -8.222873979768533],
              [-35.096685853922082, -8.222851800086135],
              [-35.096549671164119, -8.222819152310306],
              [-35.096543925384545, -8.222813579773742],
              [-35.096452805918034, -8.222758009158401],
              [-35.096367732757898, -8.222747514006622],
              [-35.09617514003925, -8.222737852675641],
              [-35.096146830599302, -8.222738090570971],
              [-35.095972282180725, -8.222846570919295],
              [-35.095893608441948, -8.22291477828664],
              [-35.095692273952608, -8.223192348324099],
              [-35.09564750589702, -8.223254683790191],
              [-35.095574816574214, -8.223356669380685],
              [-35.095479625178584, -8.223475764963169],
              [-35.095361481311052, -8.223561170746139],
              [-35.095294151765074, -8.223629318906145],
              [-35.095192707522088, -8.223680830438431],
              [-35.09502976588405, -8.223822969242489],
              [-35.094866585878876, -8.223936994857386],
              [-35.094753561128705, -8.223960454597746],
              [-35.094679888654788, -8.223961109371096],
              [-35.094356705570206, -8.223913076978656],
              [-35.094231712320209, -8.223869072545833],
              [-35.094197241771504, -8.223818720765633],
              [-35.09413419128019, -8.223734800987117],
              [-35.094082514203436, -8.223656334193782],
              [-35.094001871945416, -8.223504979629332],
              [-35.093938435811367, -8.223381649795432],
              [-35.093805488229499, -8.223084307473334],
              [-35.09378033053973, -8.222803035221363],
              [-35.093778421651386, -8.222594682857217],
              [-35.093777286529836, -8.222465152806345],
              [-35.093792372618601, -8.222262316741427],
              [-35.093796776859556, -8.222115850771534],
              [-35.093850676810838, -8.221822598326858],
              [-35.093922290682762, -8.221602382563038],
              [-35.094010753690355, -8.221359479203525],
              [-35.094060104046541, -8.221184576769312],
              [-35.09409308333997, -8.22107168120727],
              [-35.09411477987927, -8.220970144335293],
              [-35.094136866756024, -8.220908017441047],
              [-35.094208838564754, -8.220721519072114],
              [-35.094241697804165, -8.220603019309383],
              [-35.094297449351245, -8.22050681961978],
              [-35.094337977679153, -8.219982762738326],
              [-35.094353812648912, -8.219853144318932],
              [-35.094380628696392, -8.219689659210488],
              [-35.094402862601676, -8.219638741222022],
              [-35.09435996162334, -8.219278737423339],
              [-35.09439796979585, -8.219103894110145],
              [-35.094412669750774, -8.218844743361181],
              [-35.094433918936637, -8.218692496922101],
              [-35.094454989299095, -8.218523436922871],
              [-35.094499309941739, -8.218410392073823],
              [-35.094592115940877, -8.218026720607909],
              [-35.094619826052728, -8.217964564448785],
              [-35.094731296763634, -8.217766560505641],
              [-35.094792584920199, -8.217653427281791],
              [-35.094837144011926, -8.217568583534986],
              [-35.094875390117451, -8.217421853416857],
              [-35.094902981560864, -8.217337098054026],
              [-35.094919739999341, -8.217314413252948],
              [-35.094958106533667, -8.217173375278286],
              [-35.09498016148229, -8.217105646062249],
              [-35.095002068848785, -8.217026615390894],
              [-35.095024391318752, -8.216992601696663],
              [-35.095118896845477, -8.216794774060043],
              [-35.095196999694586, -8.216670163084249],
              [-35.095297727508296, -8.21653422689935],
              [-35.095325586860014, -8.216483277305732],
              [-35.095447983251375, -8.216240109358091],
              [-35.095503702381087, -8.216138305045146],
              [-35.095565437499971, -8.216075881372685],
              [-35.095627290262804, -8.216035874079008],
              [-35.095782454037128, -8.215657302157874],
              [-35.095787841539803, -8.21562916201545],
              [-35.095826147948117, -8.215476734219694],
              [-35.095859156535013, -8.21536953364939],
              [-35.095874242189325, -8.215166697773023],
              [-35.095889388548187, -8.214958166503674],
              [-35.095871406823456, -8.214851321843611],
              [-35.095870392609889, -8.214744388784259],
              [-35.095869319096053, -8.214626156127707],
              [-35.095867975247742, -8.214474117727212],
              [-35.095878273019224, -8.214361430657595],
              [-35.095960418963955, -8.214056641166202],
              [-35.095975776889048, -8.213870706299149],
              [-35.096036941539118, -8.213751880979993],
              [-35.096070367478667, -8.213689695061218],
              [-35.096097722782197, -8.213576828888577],
              [-35.096108286224805, -8.213497947594337],
              [-35.096123342030701, -8.213289416818677],
              [-35.09613953528806, -8.213193603780967],
              [-35.096138699951439, -8.213103484279394],
              [-35.096138491230484, -8.213080975809063],
              [-35.096103512822758, -8.212968614859996],
              [-35.096034989013575, -8.212912925852637],
              [-35.096023320383352, -8.212867969829629],
              [-35.096023170510279, -8.212856673019134],
              [-35.09602308179808, -8.212839768748834],
              [-35.096022873087541, -8.212817260276504],
              [-35.095981582350809, -8.212643103207727],
              [-35.095980896952007, -8.212564280505266],
              [-35.095940411178574, -8.212474457763976],
              [-35.095917432997567, -8.212435255715214],
              [-35.095888706901107, -8.212390478773315],
              [-35.095831047887181, -8.212278326141655],
              [-35.095796428248725, -8.212199772725343],
              [-35.095766837577472, -8.212059269249252],
              [-35.09576609108386, -8.211986053994256],
              [-35.095765614444097, -8.21192973975525],
              [-35.095770554720445, -8.211850887742496],
              [-35.095797549257298, -8.211704216313317],
              [-35.095819662783541, -8.21164769418171],
              [-35.095836182915349, -8.211596896258188],
              [-35.09585282071297, -8.211551702550132],
              [-35.095880711999406, -8.211506447922885],
              [-35.095964589416809, -8.21139310691709],
              [-35.09600962452744, -8.211364579800042],
              [-35.096032040072259, -8.21133065359289],
              [-35.096076419229725, -8.211228908442328],
              [-35.096093234374926, -8.211217520961997],
              [-35.096211136465158, -8.21110391247905],
              [-35.096227862911334, -8.211075623039838],
              [-35.096233458611508, -8.211069898817525],
              [-35.096345376456917, -8.210922512021799],
              [-35.096384577202883, -8.210871505713961],
              [-35.0965309945557, -8.21078025821153],
              [-35.096552483685009, -8.210656122728311],
              [-35.096506522880098, -8.210577718693433],
              [-35.096444133075494, -8.210567014510325],
              [-35.096347870264061, -8.210567786643876],
              [-35.096296881012293, -8.210568232711879],
              [-35.096223328750689, -8.210574491360799],
              [-35.096093131779796, -8.210575532727709],
              [-35.095878579442619, -8.210650593460787],
              [-35.095369762554824, -8.210739298318559],
              [-35.095239565445944, -8.210740337109135],
              [-35.095120649368546, -8.210747014531732],
              [-35.094984856517051, -8.21075377745087],
              [-35.0948828778738, -8.210754669247471],
              [-35.094803672368393, -8.210755261991244],
              [-35.094735774951808, -8.210767095782655],
              [-35.094662222557552, -8.210773354161352],
              [-35.094650881276301, -8.210773503435483],
              [-35.09445826671238, -8.21075814487293],
              [-35.094225614939539, -8.210703853265153],
              [-35.094197127119195, -8.210687186988496],
              [-35.094123186952913, -8.210654035256967],
              [-35.094009093024717, -8.210559261043846],
              [-35.093843739744706, -8.210431126744131],
              [-35.093757387664567, -8.210279801067399],
              [-35.093694190309499, -8.210184581463453],
              [-35.093658081760637, -8.209942777828026],
              [-35.093657694072903, -8.20990336780434],
              [-35.093690434999345, -8.209762271293998],
              [-35.093762735601231, -8.2096208764598],
              [-35.093790148498563, -8.209519309955665],
              [-35.093794937154925, -8.209429161050666],
              [-35.093805564704461, -8.20934467454901],
              [-35.093861404066779, -8.209265379535248],
              [-35.093871908383228, -8.209175108338801],
              [-35.09388196908214, -8.20903431030874],
              [-35.093932511315394, -8.208983154963212],
              [-35.093943224632383, -8.208915482525933],
              [-35.093943075308573, -8.208904273632186],
              [-35.093999363507635, -8.208858783483734],
              [-35.094009691444995, -8.208751701018894],
              [-35.094008797639617, -8.208650281757878],
              [-35.094018706204508, -8.208498184606261],
              [-35.094097643552423, -8.208463784010688],
              [-35.094142471882108, -8.208412658382368],
              [-35.094169646773693, -8.208282981019181],
              [-35.094208847665435, -8.208231972598396],
              [-35.094214414013535, -8.208220646038587],
              [-35.094348892822104, -8.208067447357774],
              [-35.094460960751121, -8.207931362556105],
              [-35.094505787119694, -8.207880324859554],
              [-35.094696225165649, -8.207653525556408],
              [-35.094740813089913, -8.207574289050489],
              [-35.094824810296693, -8.207466550282517],
              [-35.09487499433952, -8.207381679825819],
              [-35.094885528813322, -8.207297196156194],
              [-35.094884843433931, -8.207218283126982],
              [-35.094873055292986, -8.207167722776074],
              [-35.094850136863151, -8.207139820161746],
              [-35.094821649319911, -8.207123153869212],
              [-35.094810248920076, -8.207112003513348],
              [-35.094736669664968, -8.207112566796244],
              [-35.094651746918068, -8.207113281639922],
              [-35.094567064853642, -8.207142195255436],
              [-35.094493780384639, -8.207182171300525],
              [-35.094465653494765, -8.207199310295197],
              [-35.094341768268158, -8.207279232301087],
              [-35.094263037357045, -8.20733613908498],
              [-35.093998750492553, -8.207546690587652],
              [-35.093886326665441, -8.207632157729945],
              [-35.093757264297118, -8.207762725938725],
              [-35.093577448866419, -8.207921945537739],
              [-35.093515743657996, -8.207989973373994],
              [-35.093307653272397, -8.20813812793337],
              [-35.093251696274379, -8.208211820950217],
              [-35.093206958214537, -8.208279760546498],
              [-35.093100755140476, -8.208427024311083],
              [-35.093033900818789, -8.208551485956777],
              [-35.09297237734387, -8.20863650782819],
              [-35.092910821683198, -8.208715832459344],
              [-35.092866203459863, -8.208789376260334],
              [-35.092737795615243, -8.208993162509506],
              [-35.092726900660104, -8.209043933454065],
              [-35.092699249654359, -8.209117386735354],
              [-35.092654631832595, -8.209191020781455],
              [-35.092633648694957, -8.209376982822823],
              [-35.092612008498577, -8.209489819240705],
              [-35.092567627981943, -8.209591476255563],
              [-35.092584744572235, -8.209602594642867],
              [-35.09258578969451, -8.209715222884167],
              [-35.092593113316958, -8.209901040092486],
              [-35.092577755778564, -8.210086972895656],
              [-35.092567248381592, -8.210177151583135],
              [-35.092568739842505, -8.210340399247338],
              [-35.092581421463201, -8.210492379054346],
              [-35.092553858670115, -8.210565834207831],
              [-35.092497721682498, -8.210622533143558],
              [-35.092385235496927, -8.210713605310856],
              [-35.092306891706421, -8.210810012248452],
              [-35.092183632651434, -8.210957457232732],
              [-35.092099395796659, -8.211036990011754],
              [-35.091975837308631, -8.211145024444495],
              [-35.091885378770698, -8.211162667837808],
              [-35.091715592241172, -8.211175303791826],
              [-35.091619597049444, -8.211209970935643],
              [-35.091405162411917, -8.211290543547847],
              [-35.091382599226449, -8.211296355891371],
              [-35.09130934343608, -8.211342024246239],
              [-35.091202096949281, -8.211376752087627],
              [-35.091151227348497, -8.211382801751649],
              [-35.091105862953881, -8.211383127709858],
              [-35.090891249465955, -8.211446886489224],
              [-35.090778585675899, -8.211504152011317],
              [-35.09072205897214, -8.211521528644194],
              [-35.090412224221232, -8.211698688744599],
              [-35.0902770877927, -8.211778668466271],
              [-35.0902264855835, -8.21181852391312],
              [-35.090147633237621, -8.211869826026627],
              [-35.089962161429824, -8.212023379065942],
              [-35.089838631743056, -8.21213710568998],
              [-35.089771301982864, -8.212205162464445],
              [-35.089681914963691, -8.212341128842613],
              [-35.089609016310803, -8.212420514473013],
              [-35.089576244555623, -8.212556006353251],
              [-35.089498019304493, -8.212674831773752],
              [-35.089419137851962, -8.212720621673688],
              [-35.089335674703825, -8.212878976670375],
              [-35.089307993148687, -8.212946737295654],
              [-35.08926917852272, -8.213037153317071],
              [-35.089246941722749, -8.213088071121112],
              [-35.089230631325762, -8.213161377555924],
              [-35.089175503942265, -8.213325100026898],
              [-35.08916481963405, -8.21339846518924],
              [-35.089154460781359, -8.213499855229523],
              [-35.089160920211754, -8.213589945929455],
              [-35.089178721054893, -8.213679887487517],
              [-35.089332703729809, -8.213802477924077],
              [-35.089451888722245, -8.213829612486792],
              [-35.08956517891356, -8.213839960763199],
              [-35.089689246213297, -8.213776943369817],
              [-35.089779526480164, -8.213742394118944],
              [-35.089909516187689, -8.213718760351297],
              [-35.090022421017167, -8.213672793588987],
              [-35.090106955666819, -8.213632671589531],
              [-35.09016887060978, -8.213587152486802],
              [-35.090343596880906, -8.213495576780584],
              [-35.090551631884395, -8.213336120864842],
              [-35.090613576060484, -8.213296206410474],
              [-35.090743327751753, -8.21324445917938],
              [-35.090788363546864, -8.213215930189389],
              [-35.090901326769533, -8.213181172624655],
              [-35.09105334240855, -8.213084202425726],
              [-35.091143771614796, -8.213060861848282],
              [-35.091279744734514, -8.21307100386452],
              [-35.091393392360551, -8.213115069118379],
              [-35.091501682470863, -8.213193059677309],
              [-35.091496981984996, -8.213300112999738],
              [-35.091447451415583, -8.213458111371459],
              [-35.091409233801699, -8.213610536350442],
              [-35.091410305918032, -8.213728769356884],
              [-35.091416824568938, -8.213830069271406],
              [-35.091434596567375, -8.213914405908344],
              [-35.091483003273879, -8.214251874964338],
              [-35.091461629994114, -8.214398427046],
              [-35.091450678023079, -8.214437896066213],
              [-35.091395610764636, -8.21461291827689],
              [-35.091340217122678, -8.214742925256258],
              [-35.091382458670431, -8.215029712020435],
              [-35.09140154364809, -8.215260485104606],
              [-35.091447710537288, -8.215361398371206],
              [-35.091488195871044, -8.215451221733382],
              [-35.091489059657718, -8.215546946186761],
              [-35.09150177042946, -8.215704440493383],
              [-35.091554490945143, -8.215895536314122],
              [-35.09156735155949, -8.216064327448759],
              [-35.091579586695957, -8.216165597606189],
              [-35.091620368787666, -8.216294743523095],
              [-35.091627425141752, -8.216446752616129],
              [-35.091657670525244, -8.216660472558026],
              [-35.09168761887085, -8.216834779642113],
              [-35.091717000431657, -8.21695277512851],
              [-35.091751590175981, -8.217025636354105],
              [-35.091797907719595, -8.217137936587218],
              [-35.091844227157871, -8.217250148860963],
              [-35.091845002570366, -8.217328971285394],
              [-35.091812498921861, -8.217498181132157],
              [-35.091773684742222, -8.217588597345165],
              [-35.091694534612039, -8.217600489455021],
              [-35.091575586814656, -8.21760155935219],
              [-35.09150194675383, -8.217590915465868],
              [-35.091336916953232, -8.217507793619854],
              [-35.091257320430415, -8.21746906619363],
              [-35.09119433282909, -8.217396354890042],
              [-35.091125869330625, -8.217334969665778],
              [-35.091057675863752, -8.217307392582304],
              [-35.090994686461798, -8.217234769178317],
              [-35.090920538436116, -8.217179020616353],
              [-35.09087487451265, -8.217140026423385],
              [-35.090840704168514, -8.217112179845756],
              [-35.090789085691888, -8.217045014185238],
              [-35.090681032721271, -8.216995224848112],
              [-35.090550178449341, -8.21692313461325],
              [-35.090516155367531, -8.216923401709195],
              [-35.090221466562184, -8.216892121131082],
              [-35.090210124601704, -8.216892180074929],
              [-35.090193037021585, -8.216886664031751],
              [-35.09009677277048, -8.216887525302869],
              [-35.090068465955945, -8.216887762654579],
              [-35.089893230168713, -8.216917329243744],
              [-35.089842389634462, -8.216929073866615],
              [-35.089713112648617, -8.217037135573506],
              [-35.089630362887092, -8.217279918501582],
              [-35.089602681742825, -8.217347769446347],
              [-35.089569313634719, -8.217421252473169],
              [-35.08949236853352, -8.217680910046544],
              [-35.089459716208708, -8.217821915978947],
              [-35.08943810687849, -8.217940357205944],
              [-35.089411259791149, -8.218098328028944],
              [-35.089384649969965, -8.218284319550865],
              [-35.089374438992266, -8.218413911256977],
              [-35.089369289258819, -8.218470254941384],
              [-35.089331281546635, -8.21864518855844],
              [-35.089298477578353, -8.218774985595404],
              [-35.089254812211429, -8.218961158312471],
              [-35.089233320446183, -8.219085203790856],
              [-35.089227993949457, -8.219124643598711],
              [-35.089201234835393, -8.219299428529725],
              [-35.089202724036475, -8.219462764421388],
              [-35.089215108183787, -8.219575243681543],
              [-35.08924454862283, -8.219704539108259],
              [-35.089279496503501, -8.219811206010613],
              [-35.089308578572577, -8.219889788892702],
              [-35.089349182048977, -8.220002121489804],
              [-35.089406423777341, -8.220069167817666],
              [-35.089509688462634, -8.220209108720084],
              [-35.089584195016066, -8.220315477398772],
              [-35.089630006730786, -8.220365770853077],
              [-35.089670104453901, -8.22041609399932],
              [-35.089704424834622, -8.220455237474887],
              [-35.089761070657261, -8.220460367376132],
              [-35.089874661826876, -8.220493223171207],
              [-35.090011442168219, -8.220587789871223],
              [-35.090080323199665, -8.220694189965938],
              [-35.090171679917049, -8.220777963549967],
              [-35.09024606838377, -8.220861735078604],
              [-35.090389934712455, -8.221113915468266],
              [-35.090481500118038, -8.221220197483731],
              [-35.090538950749675, -8.221309752097918],
              [-35.090585298169074, -8.221427659414637],
              [-35.090682876682351, -8.22157323192085],
              [-35.090774233266892, -8.221656915033321],
              [-35.090854216573092, -8.221735142682633],
              [-35.090951259736009, -8.221813103377318],
              [-35.091053627674341, -8.22186861465636],
              [-35.091110781383854, -8.2219187585947],
              [-35.091208122279944, -8.222036219984968],
              [-35.091282212533756, -8.222097575706652],
              [-35.091362556272458, -8.222209518314511],
              [-35.091414440748437, -8.222310401610301],
              [-35.091414857919553, -8.222355416353958],
              [-35.091279836703443, -8.222457906229931],
              [-35.091047832742554, -8.222476740077949],
              [-35.090917631917854, -8.222477868680624],
              [-35.090827021503358, -8.222484305887985],
              [-35.090674288082113, -8.222496761311316],
              [-35.090549771725883, -8.222509069915592],
              [-35.090447909759455, -8.22251556787675],
              [-35.090329318248244, -8.222550352915412],
              [-35.090238857096672, -8.222567998909392],
              [-35.090148334878009, -8.222574435512451],
              [-35.090052247935333, -8.222592110723435],
              [-35.089967530143774, -8.22261533164119],
              [-35.08992788126875, -8.222615718311209],
              [-35.089775236238573, -8.222645077742044],
              [-35.089752969923481, -8.222690390975764],
              [-35.089702336703247, -8.222724551753544],
              [-35.089657299897574, -8.222753080897705],
              [-35.08961782966594, -8.222770281152117],
              [-35.089578389249183, -8.222793174032722],
              [-35.089494118164929, -8.222867104610293],
              [-35.089336382517267, -8.222964107235226],
              [-35.089313850805716, -8.222975614564598],
              [-35.089150639038394, -8.223083943239041],
              [-35.088964983489326, -8.223220593273382],
              [-35.088914409767483, -8.223266143875913],
              [-35.088829959336195, -8.223323170499604],
              [-35.088768195039343, -8.223379988952546],
              [-35.088700594174583, -8.223414330397501],
              [-35.088633022631527, -8.223454274217376],
              [-35.088559499239253, -8.223466136771574],
              [-35.088463527928887, -8.223506410975713],
              [-35.088390600463335, -8.223580189891296],
              [-35.088340146555105, -8.223631344676331],
              [-35.088323207944832, -8.223637037547576],
              [-35.088300585567893, -8.223648545307306],
              [-35.088249770675198, -8.223665804413333],
              [-35.088199078502925, -8.223688757979323],
              [-35.088159667233803, -8.223717255461878],
              [-35.088069205611127, -8.223734901056426],
              [-35.087967372936937, -8.223747091111289],
              [-35.08790515948111, -8.223753199697427],
              [-35.087803267640453, -8.223754090063185],
              [-35.087752273627977, -8.223754445234677],
              [-35.087678662122059, -8.223749495859344],
              [-35.087616149662963, -8.223733096289676],
              [-35.087570845017559, -8.223727817029229],
              [-35.087531043932309, -8.223716906635635],
              [-35.087451686228135, -8.223706289644959],
              [-35.087361133749262, -8.22370703074416],
              [-35.087270463681719, -8.223702169893196],
              [-35.087202504956316, -8.223702793607794],
              [-35.087117578257519, -8.223703505433514],
              [-35.087004344200118, -8.2237044544986],
              [-35.086913703429438, -8.223705195955361],
              [-35.086874053895144, -8.223705492102521],
              [-35.086800498691488, -8.223711751981289],
              [-35.08662523058242, -8.223735803491005],
              [-35.086500951049764, -8.223776221957294],
              [-35.086314639735214, -8.223839742873004],
              [-35.086263976686148, -8.223868300933784],
              [-35.086201792906259, -8.223880104222527],
              [-35.086162172650781, -8.223886005010128],
              [-35.085857087263342, -8.223967408139652],
              [-35.085687682277936, -8.224019541086006],
              [-35.085551886334471, -8.22402630428733],
              [-35.085381915042689, -8.224022123007531],
              [-35.085285645976953, -8.224022893321667],
              [-35.085132762096187, -8.224024140191379],
              [-35.084974252853364, -8.224025504126738],
              [-35.084872419343171, -8.224037603189434],
              [-35.084747842823163, -8.224038610487002],
              [-35.084504970467243, -8.224113904565487],
              [-35.084414687322933, -8.224148453316017],
              [-35.084183126773262, -8.224217903187423],
              [-35.084036226345162, -8.224252926008615],
              [-35.083894892652701, -8.224276619930411],
              [-35.083714144862142, -8.224328810958546],
              [-35.083601148310464, -8.224357962715688],
              [-35.083499581781545, -8.224403867470469],
              [-35.083448977407912, -8.224443724924578],
            ],
          ],
          [
            [
              [-35.099888140466341, -8.214429101382747],
              [-35.099939139430383, -8.21442866394402],
              [-35.09997304203177, -8.214422791919008],
              [-35.100012689821561, -8.21442240215042],
              [-35.100052219884191, -8.214416410493824],
              [-35.100114551319678, -8.214415904622003],
              [-35.100216410485253, -8.214409407080714],
              [-35.100255908737886, -8.214397810759129],
              [-35.100312435537546, -8.214380523038427],
              [-35.100380272031359, -8.214374293324829],
              [-35.100476267086435, -8.214339712028616],
              [-35.100527077175073, -8.214322361563465],
              [-35.100572082625575, -8.214288229281022],
              [-35.100656618078077, -8.214248102971764],
              [-35.100684656087864, -8.214214059273109],
              [-35.100746060432812, -8.214123432244916],
              [-35.100779396641158, -8.214044344208963],
              [-35.100790408902498, -8.21401608215505],
              [-35.10080671706443, -8.213942778092909],
              [-35.100822759108034, -8.213835666057761],
              [-35.100844603455883, -8.213745335843539],
              [-35.100855795233429, -8.213733979848724],
              [-35.10087758060596, -8.213632440323282],
              [-35.100893979989877, -8.213576038154804],
              [-35.100910587228682, -8.2135251494605],
              [-35.100932672833686, -8.213463022541125],
              [-35.100932102515621, -8.213406711295178],
              [-35.100931475543227, -8.213339188410789],
              [-35.100929295793804, -8.213097028956833],
              [-35.100900360183317, -8.213029746441631],
              [-35.100848445463818, -8.212923169722137],
              [-35.100819748334636, -8.212883997938212],
              [-35.100739047643835, -8.212721345183722],
              [-35.100681776308456, -8.212648696003736],
              [-35.100624772889823, -8.212609759901596],
              [-35.100584913534412, -8.212587551043296],
              [-35.100567707744908, -8.212559526551159],
              [-35.100470667859483, -8.212481480105691],
              [-35.100413635021077, -8.212436941618019],
              [-35.100350767672623, -8.212369926386131],
              [-35.100248041023328, -8.212280699920379],
              [-35.100190858238065, -8.212224862293537],
              [-35.100133824990266, -8.212180233500117],
              [-35.100076912281416, -8.212141299130444],
              [-35.100002942122579, -8.212102541699149],
              [-35.099945759406786, -8.212046706335986],
              [-35.099882951112008, -8.211990898000785],
              [-35.099763498479348, -8.21192996089429],
              [-35.099723550843756, -8.211890937938355],
              [-35.099631988601587, -8.211784658051418],
              [-35.099517950437175, -8.21170118540029],
              [-35.099466722339869, -8.211673430886561],
              [-35.099426776606236, -8.21163431762632],
              [-35.09936959449611, -8.211578570077224],
              [-35.099318125294111, -8.211522612213766],
              [-35.099255526509985, -8.211489402411175],
              [-35.0991813200704, -8.21142244374016],
              [-35.099141401991723, -8.211389025361388],
              [-35.099084248931085, -8.211338792166957],
              [-35.098975958925017, -8.211260894138011],
              [-35.098907675257884, -8.211216414025904],
              [-35.098714609608756, -8.211167340790476],
              [-35.098601171137766, -8.211145786102188],
              [-35.098476122755663, -8.211090482496566],
              [-35.098447605374197, -8.211068214221319],
              [-35.098328391416466, -8.211035389667749],
              [-35.098294310131237, -8.211024447962336],
              [-35.098265822187763, -8.21100778202263],
              [-35.098175066752269, -8.210986018690164],
              [-35.098175037316011, -8.210980414023542],
              [-35.098101394511353, -8.210986673499781],
              [-35.098044779689239, -8.210987149128163],
              [-35.097954292378105, -8.210999191457063],
              [-35.097830109118902, -8.211039614009904],
              [-35.097790640461945, -8.21105681238298],
              [-35.097660770896766, -8.211102959603135],
              [-35.097536799110536, -8.211165978455204],
              [-35.097469260020254, -8.211211527710145],
              [-35.097401542060638, -8.211240265735597],
              [-35.097294296540213, -8.21127499506197],
              [-35.097237919815711, -8.211303581399399],
              [-35.097069627850132, -8.211479554695719],
              [-35.097001909804113, -8.21150829267221],
              [-35.096894902304804, -8.211571132736456],
              [-35.096855880910134, -8.211639042883588],
              [-35.096756110427393, -8.211870795279413],
              [-35.096740278245356, -8.212000413553445],
              [-35.096712836719256, -8.21209637542964],
              [-35.096720579566423, -8.21232711641138],
              [-35.096761571692006, -8.21247885790677],
              [-35.096825394081044, -8.212641599823824],
              [-35.096848672640064, -8.212720302439154],
              [-35.096856717324336, -8.212973549050581],
              [-35.096829543904235, -8.213103318990823],
              [-35.096785791138871, -8.213272587287738],
              [-35.096718728934299, -8.213374540971994],
              [-35.096668306743112, -8.21343130090672],
              [-35.096651400957242, -8.213442688901292],
              [-35.096617525890053, -8.213454255570063],
              [-35.096533021696892, -8.21349998343697],
              [-35.096393273301331, -8.213703832132609],
              [-35.096366279044425, -8.213850503567221],
              [-35.096423461723013, -8.213906341809929],
              [-35.096429475409948, -8.213945722441142],
              [-35.096458707950205, -8.214052418177388],
              [-35.096504369980387, -8.214091501953947],
              [-35.096555807080186, -8.214141765278791],
              [-35.096578669614608, -8.214158460614797],
              [-35.096629956819655, -8.214197424806278],
              [-35.096692526543578, -8.214225030290548],
              [-35.096737979380393, -8.214241517634768],
              [-35.096834334124232, -8.214257647119467],
              [-35.096970245658028, -8.214256487494973],
              [-35.097015430850952, -8.214239257008099],
              [-35.09715095652961, -8.214198685073137],
              [-35.097281304059649, -8.214208854247111],
              [-35.097400339652516, -8.214224775107592],
              [-35.097474367141572, -8.21427483026171],
              [-35.097514435410787, -8.214319547876533],
              [-35.097543430026633, -8.214398132650189],
              [-35.097589421017268, -8.214465326595066],
              [-35.097618474547211, -8.214555120691706],
              [-35.097653124205408, -8.214622464005796],
              [-35.09768762579224, -8.214678420256154],
              [-35.097863873940398, -8.214755783577889],
              [-35.098130430888006, -8.214804202781497],
              [-35.098283162322517, -8.214791655056491],
              [-35.098373591530255, -8.214768403112069],
              [-35.098373562077462, -8.214762798447195],
              [-35.098396095317696, -8.214751290775284],
              [-35.098469466926858, -8.214728218036052],
              [-35.09856525299741, -8.214671040766362],
              [-35.098627166776978, -8.214625520564866],
              [-35.098728220029408, -8.214534596636678],
              [-35.098919733625337, -8.214425937370928],
              [-35.099004357633241, -8.214385813213799],
              [-35.09910047121393, -8.214373741113382],
              [-35.099185485842163, -8.214373024471287],
              [-35.099281778856067, -8.21437785609062],
              [-35.099361107222848, -8.214382866462179],
              [-35.099395039307112, -8.214382599146044],
              [-35.09947996334715, -8.214381885228075],
              [-35.099559318365984, -8.21439240766999],
              [-35.099610487463664, -8.214408864823557],
              [-35.099667162114123, -8.214419686108041],
              [-35.099735119183237, -8.214419150934848],
              [-35.099808671920279, -8.214412891480158],
              [-35.099876749040497, -8.214417960470119],
              [-35.099888140466341, -8.214429101382747],
            ],
          ],
          [
            [
              [-35.101717880009502, -8.217617746241942],
              [-35.101627630160046, -8.217657900467215],
              [-35.101593368668738, -8.217630057791851],
              [-35.101525172549437, -8.217602482691872],
              [-35.101468288341223, -8.21756915109683],
              [-35.101417057466641, -8.217541487323553],
              [-35.101377379411801, -8.217536180092702],
              [-35.101303407591175, -8.217497335259814],
              [-35.101155287692578, -8.217402922289306],
              [-35.101063930252153, -8.217319151575179],
              [-35.101024102643237, -8.217302637780737],
              [-35.100848450605383, -8.217287192196558],
              [-35.100825737795439, -8.217281796281748],
              [-35.100667232382854, -8.217283077790453],
              [-35.100633330049057, -8.217289040180265],
              [-35.100616212925139, -8.217277919964825],
              [-35.100383586193836, -8.217229145198608],
              [-35.100281126478144, -8.21717372704583],
              [-35.100167477236852, -8.217129664850416],
              [-35.10011645731484, -8.217124416706318],
              [-35.099951549711164, -8.217046995534879],
              [-35.099900320816985, -8.217019241263628],
              [-35.099820694520247, -8.216974911058127],
              [-35.099718266710696, -8.216925095058286],
              [-35.099672542783303, -8.216874802505684],
              [-35.099558116277215, -8.216751827822058],
              [-35.099529538943123, -8.216718260177645],
              [-35.099380521619999, -8.216522427518916],
              [-35.099323099756276, -8.216438479135581],
              [-35.099242666871582, -8.216309634126707],
              [-35.099134136371077, -8.216203533172818],
              [-35.099088292552047, -8.216147636323573],
              [-35.099025274636652, -8.216069321886931],
              [-35.098911416869363, -8.216002750793876],
              [-35.098877096277477, -8.215963608227364],
              [-35.098779964437647, -8.215868657285522],
              [-35.098728497390383, -8.215812789712473],
              [-35.098665659030722, -8.215751376639599],
              [-35.098608625768875, -8.21570683796949],
              [-35.098472502211372, -8.215685401915666],
              [-35.098387727811996, -8.215697414796091],
              [-35.09825788875569, -8.215749164900673],
              [-35.09809423562681, -8.215806878979206],
              [-35.097998389851575, -8.215852756638832],
              [-35.097868697830812, -8.215915715515097],
              [-35.097682751822227, -8.216013047906022],
              [-35.09763220905274, -8.21606411356079],
              [-35.097564729131001, -8.216121052859197],
              [-35.097514247473057, -8.21616651336614],
              [-35.097446558213093, -8.216200856271096],
              [-35.097317197609932, -8.216292015586236],
              [-35.097221201662876, -8.216326596311925],
              [-35.096995843099961, -8.216452426522055],
              [-35.096888893347206, -8.216526476243454],
              [-35.096736581006745, -8.216584038204413],
              [-35.096555422019811, -8.216591221443263],
              [-35.096498806347142, -8.216591697054243],
              [-35.096447816332315, -8.216592143334701],
              [-35.096328868729593, -8.216593124311633],
              [-35.096232604460077, -8.216593896782856],
              [-35.096153276015436, -8.216588978457693],
              [-35.095955539775652, -8.216635657480891],
              [-35.095809091490558, -8.216721393211332],
              [-35.095645734381073, -8.216818424346895],
              [-35.095561704366141, -8.216920559016474],
              [-35.09550589645859, -8.21700545908506],
              [-35.095450413577574, -8.217135466835785],
              [-35.095417196104684, -8.217220158952003],
              [-35.095328824899809, -8.217463061750163],
              [-35.095312155217776, -8.217502650838012],
              [-35.095284265920419, -8.217547903208628],
              [-35.095257182613921, -8.217677672750018],
              [-35.095224412451188, -8.217813076750394],
              [-35.095169943661787, -8.218050015285115],
              [-35.095136726582304, -8.218134799971416],
              [-35.095132712398964, -8.218320583241917],
              [-35.09510649389302, -8.218546077006906],
              [-35.095090482131212, -8.218658793887728],
              [-35.095091526306717, -8.218771421904037],
              [-35.095092211839088, -8.218850242312813],
              [-35.095036908029137, -8.218997153897536],
              [-35.094986666671161, -8.219070815323413],
              [-35.094975950904782, -8.219138397544221],
              [-35.094965921141281, -8.219284890427629],
              [-35.094949641783494, -8.219363891798203],
              [-35.09495000042255, -8.219397697093147],
              [-35.094950656549678, -8.219470915144811],
              [-35.094950894708113, -8.219499025986943],
              [-35.094923243924896, -8.219572481778954],
              [-35.094828737967553, -8.219770307219743],
              [-35.094823830018122, -8.219854854176344],
              [-35.094825709180554, -8.220057511503214],
              [-35.094826992021439, -8.220198340622886],
              [-35.094822531007537, -8.220333506903728],
              [-35.094807057201649, -8.220496932903396],
              [-35.094808339602828, -8.220637671772852],
              [-35.094844363154166, -8.220862660739808],
              [-35.094839661208724, -8.220969623624411],
              [-35.094840528467529, -8.221065347829297],
              [-35.094841393409808, -8.221161072045762],
              [-35.094847645906228, -8.221228656103497],
              [-35.094847884089823, -8.221256766944967],
              [-35.094826217177093, -8.221363908488106],
              [-35.094815889994031, -8.221470990938537],
              [-35.094833066557257, -8.221493410994636],
              [-35.094844646803047, -8.221521462728777],
              [-35.094880014356335, -8.221673233611742],
              [-35.094897396903853, -8.221718069589629],
              [-35.09497765079599, -8.22183001402705],
              [-35.095011647088732, -8.221824141761942],
              [-35.095011676519981, -8.22182974643696],
              [-35.095045938129033, -8.221857589693109],
              [-35.095148517517835, -8.221935610559008],
              [-35.095347688592994, -8.222040876631826],
              [-35.095495215453433, -8.222073463452244],
              [-35.095653484570576, -8.222043982427513],
              [-35.095749540883972, -8.222020611050285],
              [-35.095856969175145, -8.222002877748409],
              [-35.095936209928368, -8.221990893911741],
              [-35.095981484797633, -8.221990567579017],
              [-35.096111476183403, -8.221966928917745],
              [-35.096269715703919, -8.221931843001583],
              [-35.096455785130942, -8.221840204957049],
              [-35.096551634764346, -8.221794327259749],
              [-35.096602266594964, -8.221760073231046],
              [-35.096680937688795, -8.221691868135087],
              [-35.096686474582761, -8.22167493453542],
              [-35.096719453083296, -8.221562038936797],
              [-35.096763444218759, -8.221420881282448],
              [-35.096790199809845, -8.221246098974468],
              [-35.096806031569237, -8.221116478351561],
              [-35.096816537642496, -8.221026299767058],
              [-35.096826832043341, -8.220913522495593],
              [-35.096826175977313, -8.220840394787565],
              [-35.096824951751962, -8.220710865442497],
              [-35.096824056910499, -8.220609536678822],
              [-35.096823039239659, -8.220502513481676],
              [-35.096822382719843, -8.220429295523321],
              [-35.096804341236158, -8.220311241718788],
              [-35.096797879617817, -8.220221151630085],
              [-35.096796387693139, -8.22005790448779],
              [-35.096796235409066, -8.220046605385559],
              [-35.096796205971401, -8.220041003030524],
              [-35.096806891478593, -8.219967725917707],
              [-35.096812010653274, -8.219905777731505],
              [-35.096822573338585, -8.219826808500391],
              [-35.096871594761303, -8.219606800754857],
              [-35.096893528865401, -8.219533374730123],
              [-35.09698892844721, -8.219436877832287],
              [-35.097050872509136, -8.219396960006744],
              [-35.097118591756896, -8.219368224010786],
              [-35.097197711865427, -8.219350635869477],
              [-35.097310914484247, -8.219344079578883],
              [-35.097367530504727, -8.219343601441535],
              [-35.097452841707316, -8.219382299918198],
              [-35.097538005730293, -8.219409787185729],
              [-35.097616914587114, -8.2193696905353],
              [-35.097628106596567, -8.219358334570495],
              [-35.097712940613903, -8.21934071652449],
              [-35.097837394097517, -8.219334011139663],
              [-35.09797322120005, -8.219316036734821],
              [-35.098091898966707, -8.219298061522574],
              [-35.098255405601684, -8.219212235839247],
              [-35.098351281972121, -8.219172050528032],
              [-35.098497700784549, -8.219080711442716],
              [-35.098576639386131, -8.219046309555255],
              [-35.098700735442712, -8.218988894008335],
              [-35.098847451931995, -8.218936965176063],
              [-35.098949105692753, -8.218907959227286],
              [-35.098994320696377, -8.218896333072067],
              [-35.099045310920445, -8.218895886395151],
              [-35.099152917520684, -8.218895053901889],
              [-35.099215158629484, -8.218894458217695],
              [-35.099345448632299, -8.218893414686253],
              [-35.099470917765544, -8.218993731870556],
              [-35.099522176416592, -8.219027090797271],
              [-35.09952220590101, -8.219032695456825],
              [-35.099613113308095, -8.219065757091739],
              [-35.099744328312092, -8.21917164896894],
              [-35.099755729244286, -8.21918279905735],
              [-35.09977847213333, -8.219193889915591],
              [-35.099824463718569, -8.219260993107556],
              [-35.09980241012056, -8.219328814968421],
              [-35.099740645966364, -8.219385634583722],
              [-35.099661825877419, -8.219442543261092],
              [-35.09954341622543, -8.21949423434743],
              [-35.099413485537674, -8.219529173436397],
              [-35.099351482344666, -8.219557792003247],
              [-35.099148507088628, -8.219660907055109],
              [-35.099086533803572, -8.219695220500205],
              [-35.098871411216869, -8.219696975306658],
              [-35.098786727255906, -8.219725892702836],
              [-35.098640307862283, -8.21981714168521],
              [-35.098555801009503, -8.219862962819342],
              [-35.098471355390465, -8.219919990940481],
              [-35.098358809489461, -8.21999976548107],
              [-35.098319399496653, -8.220028263691921],
              [-35.098240906182887, -8.220113375054645],
              [-35.098212808219515, -8.220136119153905],
              [-35.098134164861989, -8.220209931402232],
              [-35.098111842602542, -8.22024394755687],
              [-35.09797828721296, -8.220504079118012],
              [-35.097934476014224, -8.220662138234083],
              [-35.097952129469412, -8.220740782007274],
              [-35.097986602843697, -8.22079122365647],
              [-35.097986871183998, -8.220825029354254],
              [-35.097998212725017, -8.220824879914549],
              [-35.098026938019125, -8.22086974673668],
              [-35.098141035707684, -8.220964428534741],
              [-35.098278026910343, -8.221081588249229],
              [-35.098420793461614, -8.221209929696657],
              [-35.098506614302075, -8.221310541954004],
              [-35.098614727983595, -8.221371537962014],
              [-35.098677299017965, -8.221399142810583],
              [-35.098751330490316, -8.221449197325592],
              [-35.098842629313928, -8.221521671176816],
              [-35.098916571895799, -8.221554821418575],
              [-35.099013464194201, -8.22162156863091],
              [-35.099098687734475, -8.221660264738093],
              [-35.099132890558167, -8.22167690057138],
              [-35.09936061035075, -8.221810129655825],
              [-35.099395411468755, -8.221905586096938],
              [-35.099412946863829, -8.221961720830194],
              [-35.09945307343294, -8.222017737565425],
              [-35.099464653994723, -8.222045789080198],
              [-35.099567772188905, -8.222174427277206],
              [-35.099625015672004, -8.22224147163252],
              [-35.09970503223753, -8.222325301697477],
              [-35.099745099415401, -8.22237001881903],
              [-35.099762096691364, -8.222375534835585],
              [-35.099727354422342, -8.222291287776521],
              [-35.099772840587008, -8.222313467088503],
              [-35.099818264968981, -8.222324349156713],
              [-35.099959984280247, -8.22234006218839],
              [-35.100033567924221, -8.222339404670807],
              [-35.100203357975772, -8.222326766395247],
              [-35.100434673315391, -8.22222910726815],
              [-35.100479529403444, -8.222183673382458],
              [-35.100563556355276, -8.222081540073058],
              [-35.100652552169237, -8.221906249834671],
              [-35.100707765325453, -8.221742526265698],
              [-35.100869953480135, -8.221527170905519],
              [-35.10092567375473, -8.221425366015607],
              [-35.101003954634706, -8.221317655587242],
              [-35.101037170924869, -8.221232963237638],
              [-35.101115754250387, -8.221147850954083],
              [-35.101188648454581, -8.221068375520719],
              [-35.101261633964221, -8.221005801961432],
              [-35.101346138723216, -8.22096007053821],
              [-35.101425229226464, -8.220936879194868],
              [-35.101459072790789, -8.220919707360094],
              [-35.10149300770653, -8.220919439715059],
              [-35.101606598668802, -8.220952201667744],
              [-35.101640860980439, -8.220980134513898],
              [-35.101675214185015, -8.221024881297398],
              [-35.101709894338065, -8.221097740644545],
              [-35.101761633566603, -8.221187413147199],
              [-35.101961226273268, -8.221337782585238],
              [-35.102097647632398, -8.221398627186716],
              [-35.102143133283114, -8.221420718289384],
              [-35.102324921745669, -8.221481145048561],
              [-35.102461463317972, -8.221547595988241],
              [-35.102512363177546, -8.221547149260692],
              [-35.102625565931667, -8.221540589023993],
              [-35.102682063464421, -8.221517605850842],
              [-35.102738499927973, -8.221500225478414],
              [-35.10274972132629, -8.221494471726828],
              [-35.102755287479354, -8.221483142756044],
              [-35.102799665206611, -8.221381397058007],
              [-35.102816063511241, -8.221308090168469],
              [-35.10282707569624, -8.221279830368081],
              [-35.102821031568887, -8.221234845385736],
              [-35.102796973700499, -8.221077411973827],
              [-35.102785213298482, -8.221032456889851],
              [-35.102744757146297, -8.220948328040446],
              [-35.102727371059316, -8.220903402377983],
              [-35.102658307495879, -8.220780103906344],
              [-35.102560934795541, -8.220657041406684],
              [-35.102526672948407, -8.22062919889609],
              [-35.102444891053011, -8.220348315011259],
              [-35.102392824889471, -8.220230532361292],
              [-35.102341386263724, -8.220180270180435],
              [-35.102324239438182, -8.220163545433401],
              [-35.10231221044836, -8.220084784709634],
              [-35.102311762114091, -8.220034075383605],
              [-35.102310624907126, -8.21990463397078],
              [-35.10231562262156, -8.219836991505746],
              [-35.102320142498399, -8.219713037379092],
              [-35.102325497163299, -8.219679200057122],
              [-35.102330644996364, -8.219622856665634],
              [-35.102330376315116, -8.219589051061361],
              [-35.102324362317042, -8.219549760944997],
              [-35.102439929968199, -8.219182694620155],
              [-35.102523358372089, -8.219018730960983],
              [-35.10259538528932, -8.218843531690414],
              [-35.102667983393466, -8.218724733835932],
              [-35.10268985728765, -8.218640098476078],
              [-35.102711583394694, -8.218544166348057],
              [-35.102721846676545, -8.218425784700029],
              [-35.102709219784543, -8.21828510584823],
              [-35.102696772541158, -8.218161328398388],
              [-35.102689859611203, -8.218020619663328],
              [-35.102689591410524, -8.217986904312454],
              [-35.102666102827165, -8.217902596394536],
              [-35.102642702533892, -8.217818290324971],
              [-35.102573430618179, -8.217672483309775],
              [-35.102556045260528, -8.217627647840523],
              [-35.102492966977842, -8.21753803463665],
              [-35.102360942799372, -8.217347719492709],
              [-35.102287571615214, -8.2173708809385],
              [-35.102253666535162, -8.217376753224613],
              [-35.102157372944951, -8.217371924823436],
              [-35.102106442036124, -8.217383578832692],
              [-35.10207268983671, -8.217400840417019],
              [-35.101982317201362, -8.217435302601276],
              [-35.101841557246978, -8.217515405746685],
              [-35.10179640156359, -8.217538239152175],
              [-35.101774106947921, -8.217577860062203],
              [-35.101723505664829, -8.217617716841971],
              [-35.101717880009502, -8.217617746241942],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3268',
        NOME_UC1: 'PARQUE ESTADUAL MATA DA PIMENTEIRA',
        ID_WCMC2: null,
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2012',
        GID7: '12092',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 37.823/12 de 30/01/2012',
        DT_ULTIM10: '24/04/2018',
        CODIGO_U11: '0000.26.3268',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.301182205715669, -7.952138050706641],
              [-38.301311307582971, -7.952409336178277],
              [-38.301406322604208, -7.952800204907677],
              [-38.301408354401218, -7.953048457421543],
              [-38.301206711633363, -7.953332311568466],
              [-38.301019807778317, -7.953591698873123],
              [-38.300476128254559, -7.954154033093208],
              [-38.300063874974164, -7.954470297887037],
              [-38.300064690960802, -7.954480126828773],
              [-38.299585640918529, -7.955114386666617],
              [-38.2987717933079, -7.957028827580634],
              [-38.298578149410332, -7.957407831163446],
              [-38.298311532830617, -7.958058159065395],
              [-38.298207130196502, -7.958563402959112],
              [-38.298277567258467, -7.959132613601544],
              [-38.298489158502342, -7.959444707891218],
              [-38.298750014527279, -7.95957934628139],
              [-38.299103463951504, -7.959629691246504],
              [-38.302164859318466, -7.959175028281663],
              [-38.306391990329779, -7.958704784290029],
              [-38.307807340351935, -7.958464759796545],
              [-38.308513958488994, -7.9581889429824],
              [-38.309457252164187, -7.957601142216039],
              [-38.310333152310136, -7.957030478296142],
              [-38.310753969838473, -7.956307602944606],
              [-38.310854224482931, -7.955828106893037],
              [-38.310901831624136, -7.95387512063002],
              [-38.311097407695343, -7.953249347526868],
              [-38.311364177046961, -7.952898328831305],
              [-38.311676642448823, -7.952048907156436],
              [-38.311572073612872, -7.951504226341082],
              [-38.311427778984331, -7.951061872882711],
              [-38.311207769928771, -7.950580636600254],
              [-38.310875210824356, -7.94991061936419],
              [-38.310751902154351, -7.949324332784256],
              [-38.31063131393929, -7.948112601954238],
              [-38.309347198868203, -7.939260600035696],
              [-38.308515352839095, -7.935300594145005],
              [-38.30838112618477, -7.933475336985032],
              [-38.307983252416335, -7.93033714849786],
              [-38.30282308919886, -7.926713395342788],
              [-38.302358758923795, -7.926195410099452],
              [-38.30184892513887, -7.925461205118499],
              [-38.301923621038846, -7.924884440632935],
              [-38.302063894342616, -7.92447352920226],
              [-38.302415861184436, -7.923986330388122],
              [-38.302700903613719, -7.923357548372651],
              [-38.302903023605943, -7.922771495038648],
              [-38.302820203647634, -7.922179924523673],
              [-38.302544443075419, -7.921586918872393],
              [-38.302141703239606, -7.921225524736903],
              [-38.301661013020123, -7.920789036993257],
              [-38.300940842679715, -7.920140815339464],
              [-38.300580975527744, -7.919790505169945],
              [-38.300267562990101, -7.919509275621025],
              [-38.299837318328322, -7.919178854279434],
              [-38.299566550560208, -7.918797432363489],
              [-38.29943453173702, -7.918494250082555],
              [-38.299416869252553, -7.917979708155118],
              [-38.299569987587446, -7.917405128716005],
              [-38.299962990432071, -7.916523684297405],
              [-38.300352795717636, -7.915088709196235],
              [-38.300566478124281, -7.912518786770251],
              [-38.300380094034118, -7.909111313068723],
              [-38.299965907255356, -7.906131654631648],
              [-38.299137386515859, -7.904390701454226],
              [-38.29898468137295, -7.903381660393142],
              [-38.299030728634655, -7.901269765097577],
              [-38.299118790452788, -7.900672392747465],
              [-38.299487006743661, -7.899433151458306],
              [-38.300219445470567, -7.898126736845088],
              [-38.300543762075542, -7.896888481517311],
              [-38.300605644339015, -7.895949163199514],
              [-38.300982226902327, -7.891611935506037],
              [-38.301352807252705, -7.888941375808541],
              [-38.299979852583782, -7.888308777577498],
              [-38.298621380746866, -7.887761499845531],
              [-38.298040639068503, -7.887558246630847],
              [-38.29761192917789, -7.887515670565668],
              [-38.297141259491134, -7.887602529692327],
              [-38.296767861040699, -7.887776381369632],
              [-38.296418327429883, -7.887935717151932],
              [-38.295826863222118, -7.888538376092656],
              [-38.294328964443103, -7.890573600757861],
              [-38.289776893268666, -7.897018998671088],
              [-38.289218355605293, -7.897912742889596],
              [-38.288710056755015, -7.898575743530435],
              [-38.287956972810996, -7.899041165173488],
              [-38.287056695986493, -7.899423320206009],
              [-38.28610212965058, -7.899674469983923],
              [-38.285276032339077, -7.899996441433096],
              [-38.284690683200999, -7.900448557281539],
              [-38.284167194581059, -7.90095077687566],
              [-38.283716429483619, -7.901393773879581],
              [-38.283341236657385, -7.902083866457385],
              [-38.283081703957386, -7.902831480776643],
              [-38.283106752716662, -7.904238871350151],
              [-38.283444149844698, -7.904835087025381],
              [-38.283471499323809, -7.905265576462774],
              [-38.28503720975683, -7.907023326089479],
              [-38.289001772103752, -7.907274111557044],
              [-38.290774810179968, -7.90753206933882],
              [-38.292071334591064, -7.907904488076693],
              [-38.293724006458973, -7.908259010189182],
              [-38.294176106073692, -7.908444207417221],
              [-38.294876088972522, -7.908545035337347],
              [-38.295446842858382, -7.90856728163196],
              [-38.296111444061218, -7.908601906922646],
              [-38.296803205597421, -7.909261677483762],
              [-38.29613782642658, -7.910517194453312],
              [-38.293972672893943, -7.911942870277],
              [-38.293221841885817, -7.912470647547778],
              [-38.292656966236386, -7.913404293523454],
              [-38.292430586573865, -7.913785434067293],
              [-38.292218454572335, -7.914480136740966],
              [-38.292172792310708, -7.915260812023992],
              [-38.292132492947324, -7.916125130809332],
              [-38.292236515777319, -7.916685511526216],
              [-38.292823220787028, -7.917715838786747],
              [-38.293906852975404, -7.919082069406093],
              [-38.295113974489638, -7.920167013805707],
              [-38.296750419110595, -7.921002036027055],
              [-38.297959265783824, -7.921860289788223],
              [-38.298673437918964, -7.92283661047002],
              [-38.2991703284331, -7.924052001555216],
              [-38.299000614987264, -7.924930346160735],
              [-38.29696156529387, -7.928013139142689],
              [-38.29643802462801, -7.927252160312144],
              [-38.295935974527325, -7.926861970274459],
              [-38.29497243475091, -7.926457095978002],
              [-38.294236395182189, -7.926262584591164],
              [-38.293346596567417, -7.926266337899845],
              [-38.292303374858449, -7.926529093540079],
              [-38.291398128891693, -7.927145165165174],
              [-38.290996231120396, -7.928013566655509],
              [-38.290154878147, -7.930676012057319],
              [-38.293260515972271, -7.933157225515363],
              [-38.295002013863069, -7.935279269102672],
              [-38.299229643236536, -7.943563358625355],
              [-38.299501191031609, -7.943900363809941],
              [-38.300078184781739, -7.944042321393803],
              [-38.300427200358435, -7.943933806307152],
              [-38.300685352691822, -7.943687582977432],
              [-38.300942397121673, -7.943324596346748],
              [-38.301166339863116, -7.94286407327576],
              [-38.301480332795435, -7.942618114986493],
              [-38.301762062636207, -7.942798468117727],
              [-38.302077096802122, -7.943553779827912],
              [-38.302251019891152, -7.944427589721717],
              [-38.302283978186601, -7.945044826047151],
              [-38.301990305661164, -7.94588055981693],
              [-38.301764461869425, -7.946425182382641],
              [-38.301283258777289, -7.947146061699558],
              [-38.300837176183641, -7.948195649072907],
              [-38.300693070215509, -7.949517493042331],
              [-38.300858521854913, -7.951063354902842],
              [-38.301182205715669, -7.952138050706641],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2253',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATAS DO SISTEMA GURJAÚ',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5439',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2253',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.059451892525381, -8.196112187510037],
              [-35.05265772735634, -8.193862670222467],
              [-35.05210952725426, -8.197512044237843],
              [-35.057570218514883, -8.200138853773865],
              [-35.056521168123602, -8.205522301728445],
              [-35.052217402267246, -8.209066412061272],
              [-35.054507452092643, -8.211102591332603],
              [-35.054106093491768, -8.215486615982632],
              [-35.056300756711401, -8.216460479076012],
              [-35.055963599051211, -8.219002655572529],
              [-35.053092967352775, -8.218875650392874],
              [-35.05240280479758, -8.222683102199927],
              [-35.047396027837998, -8.225503347771568],
              [-35.048124362847538, -8.229165262773945],
              [-35.048282364909461, -8.230058107474472],
              [-35.045744326778156, -8.232038849158263],
              [-35.04405808723758, -8.233235038104102],
              [-35.043859245911833, -8.23350040428992],
              [-35.043631944445792, -8.233867430184869],
              [-35.0432114650418, -8.234505488545198],
              [-35.042580703074506, -8.235504938723629],
              [-35.04242146315633, -8.235894635110585],
              [-35.042920374118864, -8.236279317531048],
              [-35.043742495235271, -8.236856412857493],
              [-35.045251531703371, -8.237038650480891],
              [-35.047021513020745, -8.237266332423191],
              [-35.047088942646738, -8.237938751310699],
              [-35.047206144813686, -8.239955916414143],
              [-35.047408580699823, -8.241843215118914],
              [-35.047465280133395, -8.241882817771568],
              [-35.047743420966214, -8.241753130908466],
              [-35.048276916107142, -8.241584138336384],
              [-35.04925879383201, -8.241257358869294],
              [-35.050439235224246, -8.240947721746036],
              [-35.051676413336196, -8.240626826711431],
              [-35.052697956131027, -8.240350932139675],
              [-35.053469692162302, -8.24023865695103],
              [-35.05398601834581, -8.240222181316575],
              [-35.053062229674737, -8.239136522530671],
              [-35.052404685857667, -8.238497454025397],
              [-35.052489939746962, -8.238339335853787],
              [-35.052705989485979, -8.23786494382515],
              [-35.052814302191408, -8.237333942519331],
              [-35.052808976697285, -8.236961040302988],
              [-35.052701759173758, -8.236350735072831],
              [-35.052520666200174, -8.235864667690358],
              [-35.052396132763548, -8.235565100802335],
              [-35.053850449546545, -8.233617212991859],
              [-35.05686125918767, -8.235620110747229],
              [-35.060365380629065, -8.237968076868295],
              [-35.061204543216633, -8.238556439188747],
              [-35.061232817799478, -8.238658164049548],
              [-35.061244050463593, -8.238776826001251],
              [-35.061323313074048, -8.238957702199617],
              [-35.064924137214291, -8.241091001045694],
              [-35.065168025090848, -8.241187266808261],
              [-35.065349573316233, -8.241193084658496],
              [-35.065559491347869, -8.241204569511094],
              [-35.066098512434642, -8.241188106974569],
              [-35.066342480245467, -8.24118832185963],
              [-35.066575033260946, -8.241261982395184],
              [-35.066796154223312, -8.241431684218771],
              [-35.067300734756486, -8.241844576442045],
              [-35.067538805501748, -8.242087739243493],
              [-35.067601405905087, -8.241884401364265],
              [-35.067681083410498, -8.241613269796309],
              [-35.067879991891402, -8.241257507569065],
              [-35.068141401305418, -8.240800097166375],
              [-35.068510678187408, -8.240263682465541],
              [-35.068993441312116, -8.239710420646194],
              [-35.069765854918934, -8.238841009281803],
              [-35.070333771419236, -8.23823132534736],
              [-35.071248124963596, -8.237243400643615],
              [-35.07215666129003, -8.236408015252154],
              [-35.072860710424663, -8.23582669927003],
              [-35.073252446188114, -8.235544553292927],
              [-35.073803254834459, -8.235025242156953],
              [-35.074405103316487, -8.234528581626305],
              [-35.075154453074646, -8.234043355931631],
              [-35.075574458624132, -8.23385727116702],
              [-35.075042859600622, -8.231947130748981],
              [-35.074500064800382, -8.229884431801286],
              [-35.074437952619284, -8.229551034480536],
              [-35.074466528264324, -8.229325057187545],
              [-35.07446123270568, -8.2289069619779],
              [-35.074399020549009, -8.22869220982262],
              [-35.074274345261159, -8.228528252079824],
              [-35.074126986877602, -8.228364272557066],
              [-35.073979619339433, -8.228200295310907],
              [-35.073945695511654, -8.228070315084858],
              [-35.073725151234115, -8.227279126372258],
              [-35.073153675002366, -8.225572342476971],
              [-35.072344608891797, -8.223119542692121],
              [-35.074433360779992, -8.222087472067159],
              [-35.072335321700393, -8.22086521487822],
              [-35.072215961007146, -8.221096758336151],
              [-35.072034171712467, -8.221367793134498],
              [-35.071880816809404, -8.221559745178045],
              [-35.071721898854385, -8.221627404277857],
              [-35.071625451764483, -8.221621673919277],
              [-35.071517695112128, -8.221582029867069],
              [-35.071375997646705, -8.221429347054311],
              [-35.071251419229817, -8.221174986383618],
              [-35.071223156529591, -8.22105631577239],
              [-35.071172477788664, -8.220638173313784],
              [-35.071155714520117, -8.220361309513359],
              [-35.071224239689009, -8.219875479131627],
              [-35.07128142460602, -8.219389633133348],
              [-35.070895489321408, -8.219547484803291],
              [-35.070464192572601, -8.219677047655823],
              [-35.069930762839157, -8.219812164620048],
              [-35.069062516980146, -8.220048673085389],
              [-35.068279391681507, -8.220268318363271],
              [-35.067757369078251, -8.220335638334475],
              [-35.067326274455787, -8.220250504575192],
              [-35.067161791938261, -8.220193847775366],
              [-35.067048428922902, -8.220080746923708],
              [-35.066935127335775, -8.219905496297629],
              [-35.066861552015332, -8.21971332844698],
              [-35.066754232524332, -8.219199085484815],
              [-35.066697784229198, -8.218893941855908],
              [-35.066579075835342, -8.21842488362747],
              [-35.066448981988188, -8.218006668320482],
              [-35.066296402782029, -8.217356790072714],
              [-35.066262647325296, -8.217051654589479],
              [-35.066291215154209, -8.216836986255561],
              [-35.066410688299662, -8.216481146849643],
              [-35.066450594292832, -8.216277782871042],
              [-35.066445076884023, -8.216102630609388],
              [-35.06639417710322, -8.215933089620773],
              [-35.066292271200858, -8.215701344135166],
              [-35.066173382908687, -8.215430039393935],
              [-35.066071601682623, -8.215068349962468],
              [-35.065981210328154, -8.214661466311856],
              [-35.065884988759429, -8.214418428296868],
              [-35.065726363337745, -8.214180982327262],
              [-35.065550650908584, -8.214000022722738],
              [-35.065369264356377, -8.213830364474948],
              [-35.065295653539479, -8.213683394170364],
              [-35.065307093492841, -8.213576052601297],
              [-35.065358220586361, -8.213513957719023],
              [-35.065471737921754, -8.213457554797211],
              [-35.065613607160991, -8.213418135825577],
              [-35.065755522294019, -8.213327869530945],
              [-35.065931603984218, -8.213102039324358],
              [-35.066085119171852, -8.212740573183334],
              [-35.066488690442434, -8.211921706351271],
              [-35.067147736591856, -8.210905320291456],
              [-35.067789428905776, -8.210256152576527],
              [-35.068459412214018, -8.209669178098277],
              [-35.069509703352885, -8.208873490921398],
              [-35.070270205246445, -8.208557783592035],
              [-35.070786695682401, -8.208315312418017],
              [-35.071626844603173, -8.207762377731548],
              [-35.07261458215411, -8.207113523703748],
              [-35.073295658869981, -8.20679774874216],
              [-35.074334334062087, -8.20626759132934],
              [-35.074867942706788, -8.205912122361726],
              [-35.075634355393966, -8.205325221868721],
              [-35.076003460226126, -8.204941355637139],
              [-35.076911960197741, -8.204072081025096],
              [-35.079583984969972, -8.204091415321802],
              [-35.079556679563652, -8.202921859155358],
              [-35.079349206223519, -8.200243615783387],
              [-35.079102702370449, -8.196830846172357],
              [-35.078738168943097, -8.1922258433904],
              [-35.0785083093227, -8.189242475730778],
              [-35.078323388800676, -8.18675634096099],
              [-35.078036899217395, -8.183682521749718],
              [-35.077997525687977, -8.183320893848524],
              [-35.077958274894023, -8.182818018195341],
              [-35.08064442463121, -8.179656492128643],
              [-35.077004909894576, -8.177065523652281],
              [-35.076930656358385, -8.183726723325837],
              [-35.072764667143353, -8.185988542918849],
              [-35.069172223252572, -8.187567222811129],
              [-35.063055714336691, -8.18869151500069],
              [-35.057558482312174, -8.188810611811098],
              [-35.059451892525381, -8.196112187510037],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2189',
        NOME_UC1: 'RESERVA PARTICULAR DO PATRIMÔNIO NATURAL RESERVA SIRIEMA',
        ID_WCMC2: null,
        CATEGORI3: 'Reserva Particular do Patrimônio Natural',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '2007',
        GID7: '9970',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Portaria nº 35 de 12/04/2007',
        DT_ULTIM10: '26/08/2011',
        CODIGO_U11: '0000.00.2189',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.129860596671691, -8.596823327591219],
              [-39.143629489426296, -8.637500475887734],
              [-39.144723000641022, -8.635943171299443],
              [-39.147257185682825, -8.636124894836453],
              [-39.147399223368183, -8.636825807131249],
              [-39.148555851046552, -8.634043343728576],
              [-39.149183887616871, -8.632752787603387],
              [-39.148203954463106, -8.631728346421362],
              [-39.14878120023387, -8.629927296678646],
              [-39.148782394682463, -8.627129704637792],
              [-39.149242523427766, -8.625315045985063],
              [-39.149308998592794, -8.624512200765965],
              [-39.145026546439503, -8.620104663313848],
              [-39.144429361595868, -8.620294592132476],
              [-39.141633227972441, -8.616082834073563],
              [-39.139978817180655, -8.613825002739318],
              [-39.13787621290021, -8.605585990313548],
              [-39.137136494024226, -8.601509036198376],
              [-39.136209274981987, -8.601072567244641],
              [-39.1326180579916, -8.597987377173228],
              [-39.132109391023164, -8.595688533341768],
              [-39.129860662110794, -8.596823237881029],
              [-39.129860596671691, -8.596823327591219],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3281',
        NOME_UC1: 'PARQUE NATURAL MUNICIPAL DO FORTE DE TAMANDARÉ',
        ID_WCMC2: null,
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'municipal',
        ANO_CRIA6: '2003',
        GID7: '12748',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 13/2003 de 10/09/2003',
        DT_ULTIM10: '05/07/2016',
        CODIGO_U11: '1485.26.3281',
        NOME_ORG12: 'Secretaria do Meio Ambiente de Tamandaré - PE',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.097655317185456, -8.755264507784091],
              [-35.097000965408881, -8.756870643963055],
              [-35.096197897319428, -8.756811157437767],
              [-35.095900464693614, -8.757316792901577],
              [-35.096763019308355, -8.757465509214512],
              [-35.094294328514707, -8.76145110639925],
              [-35.094280250037116, -8.761397965103356],
              [-35.082163241441776, -8.765941149317086],
              [-35.088702595151062, -8.780941866363037],
              [-35.098369155487262, -8.77885983798302],
              [-35.09845838527491, -8.770412751412266],
              [-35.104317808001838, -8.765885826848773],
              [-35.104050118638611, -8.76558839422313],
              [-35.103960888850963, -8.765320704859846],
              [-35.103782429275498, -8.76511250202185],
              [-35.103514739912384, -8.764636609820741],
              [-35.103098334236392, -8.763833541731287],
              [-35.102652185297643, -8.763149446692012],
              [-35.102295266146768, -8.762673554490902],
              [-35.102027576783655, -8.762346378602558],
              [-35.101521941320016, -8.761840743138919],
              [-35.10086758954327, -8.761216134624874],
              [-35.100153751241635, -8.760621269373416],
              [-35.099439912939829, -8.760145377172194],
              [-35.098726074638023, -8.759639741708554],
              [-35.098220439174384, -8.759401795607971],
              [-35.098012236336388, -8.759104362982157],
              [-35.101105535643853, -8.756692184387589],
              [-35.098726074638023, -8.7557403999852],
              [-35.098577358325258, -8.756186548923779],
              [-35.098012236336388, -8.755889116298135],
              [-35.098220439174384, -8.755532197147204],
              [-35.097655317185456, -8.755264507784091],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2239',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA LANÇO DOS CAÇÕES',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12094',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '25/01/2016',
        CODIGO_U11: '0000.26.2239',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.845257654852688, -7.706956483884322],
              [-34.845048996420033, -7.706950205783913],
              [-34.844811685182542, -7.706972758481554],
              [-34.844538466204291, -7.707009817063154],
              [-34.844294085000385, -7.707061104573947],
              [-34.844028214942867, -7.707134013933239],
              [-34.843826905430959, -7.707163586610573],
              [-34.843632664885504, -7.707164423474778],
              [-34.843424006452878, -7.707158145374374],
              [-34.843200834148348, -7.707123224445725],
              [-34.843063899212552, -7.707066402713846],
              [-34.842905008499322, -7.706923558512326],
              [-34.842796817353104, -7.706859435845995],
              [-34.842645680494599, -7.7068457338313],
              [-34.842509148492809, -7.706882203508445],
              [-34.842365576519491, -7.706954584953319],
              [-34.842099892431527, -7.707070551039888],
              [-34.841870085089504, -7.707164833287937],
              [-34.841676093503423, -7.707223078788516],
              [-34.841474844981448, -7.707267003374937],
              [-34.841266526493001, -7.707339662774495],
              [-34.841086984789072, -7.707412199194167],
              [-34.840907691044514, -7.707542145250178],
              [-34.840821578655458, -7.707592750871616],
              [-34.840692395574465, -7.707665070326454],
              [-34.840563366468004, -7.707773271554072],
              [-34.840470432071889, -7.707910022624979],
              [-34.840399359468435, -7.708111268301843],
              [-34.840364226600705, -7.708305182049271],
              [-34.84037210243509, -7.70846302905424],
              [-34.840409034008275, -7.708685339670187],
              [-34.840489286232838, -7.708943345089057],
              [-34.840627246000977, -7.709236985320849],
              [-34.840772461705079, -7.709544946466815],
              [-34.840917243480277, -7.709752439249184],
              [-34.841047637363211, -7.709959995021509],
              [-34.841178001251237, -7.710160373839319],
              [-34.841257911531621, -7.710339439758245],
              [-34.841323434919715, -7.710518568667125],
              [-34.841374507425755, -7.710683405656859],
              [-34.841396835142199, -7.710855543581055],
              [-34.841411998907425, -7.711034888454722],
              [-34.841434170649272, -7.711171145606166],
              [-34.84149932209835, -7.711264158060567],
              [-34.841571480524046, -7.711314082792687],
              [-34.841665128803356, -7.711342384676267],
              [-34.841823400618118, -7.711341703787012],
              [-34.841952739673779, -7.711305265104825],
              [-34.842103444603389, -7.711218499755971],
              [-34.842325904024698, -7.711088366730134],
              [-34.842498004823192, -7.710958450669104],
              [-34.842584210197018, -7.710929373911287],
              [-34.842670508555649, -7.710921825017156],
              [-34.842756868904075, -7.71092863003208],
              [-34.842829183304325, -7.711014435536968],
              [-34.842937592414913, -7.711128790885171],
              [-34.843096733087449, -7.711329044723158],
              [-34.843241268903249, -7.711479126869334],
              [-34.84337144682172, -7.711636446959909],
              [-34.843487236847992, -7.71179382904045],
              [-34.843624516727822, -7.711929589272495],
              [-34.843740368743894, -7.712101323262143],
              [-34.843856096780243, -7.712244352433627],
              [-34.844044046232185, -7.712451658246422],
              [-34.844217265847696, -7.712580086549067],
              [-34.844375848611683, -7.712651167205276],
              [-34.844512661567791, -7.712679283119116],
              [-34.844685352269742, -7.712685715194495],
              [-34.844857733022536, -7.71262038372435],
              [-34.844994079054828, -7.712540856319936],
              [-34.84508757235956, -7.712533276430839],
              [-34.845159513820867, -7.712532966481159],
              [-34.845253194095115, -7.712568444319371],
              [-34.84536144823111, -7.712646917894936],
              [-34.845549242708415, -7.712818340934973],
              [-34.845744201136931, -7.71298255702555],
              [-34.845975254275807, -7.713175322959477],
              [-34.846141217955427, -7.713289428348085],
              [-34.846285692781393, -7.713425156585193],
              [-34.846451750445731, -7.713560790837504],
              [-34.846610676153809, -7.713710808993821],
              [-34.846726405190196, -7.71385383716536],
              [-34.846892836793486, -7.714075586872278],
              [-34.847080726255598, -7.714268538776091],
              [-34.847153041655808, -7.714354342281049],
              [-34.847196580271614, -7.714440271765891],
              [-34.847204086166876, -7.714512002316442],
              [-34.847197421134318, -7.714634030538799],
              [-34.847248309670796, -7.714755808801423],
              [-34.847335044958243, -7.714848727271026],
              [-34.847421531286336, -7.714884236104254],
              [-34.84751514857075, -7.714905360033386],
              [-34.847622999772824, -7.7148905421998],
              [-34.847759408795064, -7.714825364704513],
              [-34.847909956750165, -7.714702716582877],
              [-34.848010115087476, -7.714573108471436],
              [-34.848110178440102, -7.714421972496282],
              [-34.848195916890241, -7.714285250420247],
              [-34.848288942271267, -7.714170026212869],
              [-34.84840348850085, -7.71404035611147],
              [-34.848525165686716, -7.713896303106052],
              [-34.848574964401138, -7.713766912959331],
              [-34.848596048320893, -7.713651999701606],
              [-34.848602463394094, -7.713472562842864],
              [-34.848594458580159, -7.713286011019492],
              [-34.848608285563913, -7.713156776847598],
              [-34.848629213509085, -7.713005981817108],
              [-34.848678794259065, -7.712826357988581],
              [-34.848820775491745, -7.712387985861655],
              [-34.848863255280357, -7.712229921891748],
              [-34.84887702027428, -7.712086334810785],
              [-34.848883497337319, -7.711921249861171],
              [-34.848839584782525, -7.711749206921743],
              [-34.848838992879095, -7.711612857785295],
              [-34.848895984539631, -7.711483436643674],
              [-34.84898181497455, -7.711368243431236],
              [-34.849132486909362, -7.71127430012783],
              [-34.849268676967121, -7.71115888895066],
              [-34.849311373720177, -7.711051058662627],
              [-34.849332394650062, -7.710921793495781],
              [-34.849346346613437, -7.710821264142155],
              [-34.849288171146057, -7.710677988010883],
              [-34.849194148927651, -7.710563571672689],
              [-34.849093025747919, -7.71047071619304],
              [-34.848883805406672, -7.710335268910659],
              [-34.848710865745318, -7.710271428198939],
              [-34.848566609883662, -7.710185934643675],
              [-34.848429454983417, -7.710078881229808],
              [-34.848357015603419, -7.709964371906702],
              [-34.848334996836208, -7.709863997527938],
              [-34.848334342942898, -7.709713296482435],
              [-34.84831213820614, -7.709569864376375],
              [-34.848254148708222, -7.709469645972448],
              [-34.848145926567085, -7.709398349351451],
              [-34.847965855949433, -7.709348891543836],
              [-34.847800205218917, -7.709306548700784],
              [-34.847627110582899, -7.709206826216348],
              [-34.847518826451974, -7.709121177686324],
              [-34.847446418066845, -7.709013844317794],
              [-34.847409825437801, -7.708870474201718],
              [-34.84735884391651, -7.708727167075538],
              [-34.847315337295619, -7.708648414545308],
              [-34.847228758982745, -7.708591376848593],
              [-34.847113622849712, -7.708584696813531],
              [-34.846977028858007, -7.708606815581562],
              [-34.846718601705923, -7.708737105582261],
              [-34.846431459759181, -7.7088818724719],
              [-34.846273189944313, -7.708882555361205],
              [-34.846179511670059, -7.708847076523105],
              [-34.846071289528886, -7.708775779902133],
              [-34.845998975128609, -7.7086899753973],
              [-34.845948180576784, -7.70858972399841],
              [-34.845947838632696, -7.708510785498413],
              [-34.846005080252503, -7.708438774993223],
              [-34.846112525520638, -7.708330665750522],
              [-34.846241769591586, -7.708272697204712],
              [-34.846428629221457, -7.708228832608242],
              [-34.846572231189725, -7.708163626117979],
              [-34.846636572770969, -7.708070054754145],
              [-34.846657595700769, -7.707940789587428],
              [-34.846656879817608, -7.707775735632862],
              [-34.846627419144852, -7.707617981612771],
              [-34.846554793795285, -7.70746041456245],
              [-34.846431903807606, -7.707324593340572],
              [-34.846280393009998, -7.707224776871332],
              [-34.846049747804948, -7.70712530234672],
              [-34.845732805241326, -7.707033375716336],
              [-34.84545918232903, -7.70697714388882],
              [-34.845257654852688, -7.706956483884322],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2251',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA SERRA DO COTOVELO',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5430',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2251',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.205464558891642, -8.235060027459516],
              [-35.205441511023466, -8.234930120613827],
              [-35.205412897645928, -8.234834148625863],
              [-35.205321158799222, -8.234467111082109],
              [-35.205218723848517, -8.234314861686403],
              [-35.204991337202415, -8.234066942696654],
              [-35.204872092202493, -8.233982559374674],
              [-35.204707338393021, -8.233836166292299],
              [-35.204622021488184, -8.233729072307661],
              [-35.204519480684084, -8.233542916576683],
              [-35.204394081038984, -8.233289015244946],
              [-35.204296887839611, -8.232995466391976],
              [-35.204171572889727, -8.232769818029935],
              [-35.204063626247383, -8.232674100924164],
              [-35.203967202633876, -8.232640508502973],
              [-35.203870789371436, -8.232612564402944],
              [-35.203672414491606, -8.232596263935513],
              [-35.20353078353844, -8.232608023509423],
              [-35.203406178980707, -8.232625384266729],
              [-35.203219280646344, -8.232659900214255],
              [-35.203128767881921, -8.232711059102478],
              [-35.203027235350355, -8.232869625982124],
              [-35.202976583289718, -8.232988469636267],
              [-35.202926112372758, -8.233169479503415],
              [-35.202858648548954, -8.233350538666208],
              [-35.202808309080403, -8.233576753604824],
              [-35.202808872211143, -8.233768900552406],
              [-35.202832125557897, -8.233972266065392],
              [-35.202889182544183, -8.234107718353417],
              [-35.203037221326532, -8.234350243210804],
              [-35.203293155463186, -8.234671528709896],
              [-35.203685464467902, -8.235122353300255],
              [-35.203986949154377, -8.235522613698613],
              [-35.204123763100014, -8.235799078334768],
              [-35.204198029951385, -8.236007932064105],
              [-35.204221524959159, -8.236290424486425],
              [-35.204205720392679, -8.236697372204981],
              [-35.204110330315061, -8.237019808943735],
              [-35.20401449508185, -8.237189662562452],
              [-35.203958026546744, -8.237257656421525],
              [-35.203737848783369, -8.237535295220768],
              [-35.203506378527898, -8.237829915761873],
              [-35.203353845411556, -8.237988654354009],
              [-35.203206925256282, -8.238130414558562],
              [-35.203082535056524, -8.238221243112276],
              [-35.20290731448501, -8.238374404579131],
              [-35.202749048753887, -8.238510550818651],
              [-35.202675731126597, -8.238629463536462],
              [-35.20259135926122, -8.238844492291392],
              [-35.202591875607567, -8.239019682682793],
              [-35.202609350375234, -8.239183508588726],
              [-35.202660663645858, -8.239290717809244],
              [-35.202763096195575, -8.239437319354938],
              [-35.202831340272802, -8.239521865062422],
              [-35.202922251958853, -8.239606341791664],
              [-35.203075565833117, -8.239713205894025],
              [-35.20328570015856, -8.239876417712471],
              [-35.203501638503653, -8.240084807868202],
              [-35.203604036857612, -8.240220100869839],
              [-35.203638333085202, -8.240321708670166],
              [-35.203644234203836, -8.240400811758228],
              [-35.203621810644975, -8.240485658717555],
              [-35.203537041358175, -8.240565051732869],
              [-35.203452261672204, -8.240638796390721],
              [-35.203344911482176, -8.240746525391664],
              [-35.203271727972584, -8.240910650230042],
              [-35.20326074336964, -8.241029364121514],
              [-35.203272533909825, -8.24118756345875],
              [-35.203295666428389, -8.241345725930373],
              [-35.203369958997648, -8.241560227873354],
              [-35.20339309161448, -8.241718390320734],
              [-35.203393875753164, -8.241989645976462],
              [-35.203365766162463, -8.242063204247298],
              [-35.203309331809635, -8.242142515656242],
              [-35.203264065809385, -8.242165263130044],
              [-35.203179067191016, -8.242165540417309],
              [-35.203094033887773, -8.242154518751079],
              [-35.202997633156976, -8.242132223368321],
              [-35.202838884196218, -8.242104487863461],
              [-35.202742555189232, -8.242104799531491],
              [-35.202601024910209, -8.242150474999352],
              [-35.202516389196781, -8.242275079921688],
              [-35.202460158037539, -8.24242219881258],
              [-35.202477633324939, -8.242586033929179],
              [-35.202540399027065, -8.242732762957372],
              [-35.20269955640974, -8.242901785134755],
              [-35.202875634313642, -8.243042483783197],
              [-35.203119825501261, -8.243222527374046],
              [-35.203222021243384, -8.243290010449121],
              [-35.203278888489592, -8.243357645407936],
              [-35.203364163822485, -8.243453431045124],
              [-35.203545937522392, -8.243605424857861],
              [-35.203693451608331, -8.243667109775231],
              [-35.204022328684857, -8.243739503982173],
              [-35.204215145931833, -8.243789730739046],
              [-35.204504631898082, -8.24395832454563],
              [-35.204675047936284, -8.244099043989273],
              [-35.204828536820138, -8.244268077597392],
              [-35.204970652595449, -8.244420200705463],
              [-35.20512417885265, -8.244600539979746],
              [-35.205363050947916, -8.244899273558257],
              [-35.205647707972844, -8.24535045139875],
              [-35.205773353123391, -8.245683466542195],
              [-35.205717959518694, -8.246118797338761],
              [-35.20565059389466, -8.246333763143303],
              [-35.205487195314873, -8.246650771224973],
              [-35.205380158957325, -8.246865872049664],
              [-35.205239440738673, -8.247188459251111],
              [-35.205183418105555, -8.247409048080327],
              [-35.205009776338279, -8.248099078009275],
              [-35.205005008855544, -8.248404258865213],
              [-35.205028121187873, -8.248556772616098],
              [-35.205108281800868, -8.248839068332281],
              [-35.205216803792446, -8.249132581051295],
              [-35.205325155410677, -8.249363932194742],
              [-35.205393571631539, -8.249504987628374],
              [-35.205547447749161, -8.249804007624302],
              [-35.205763708411837, -8.250119768028547],
              [-35.206156254383124, -8.250644050072708],
              [-35.206338482475545, -8.250948617262393],
              [-35.206407188864027, -8.251185755888365],
              [-35.206390533450971, -8.251304482371774],
              [-35.206345616629122, -8.251445909506915],
              [-35.206075061436245, -8.251938467454849],
              [-35.205985600548267, -8.252351306776958],
              [-35.205997635381394, -8.252588621084458],
              [-35.206043434393266, -8.252746704293061],
              [-35.206094933646888, -8.252916071381534],
              [-35.206197349998639, -8.253057020114531],
              [-35.206555759570556, -8.253536206878264],
              [-35.207033481709679, -8.254116709605253],
              [-35.207209857690671, -8.254359138734005],
              [-35.207210027637728, -8.254415658700845],
              [-35.207187579232794, -8.25448919529453],
              [-35.207170974256591, -8.25462488053954],
              [-35.207154476317449, -8.254794469408543],
              [-35.207177492701852, -8.25491307668838],
              [-35.207211842105231, -8.255031642310719],
              [-35.207308539423174, -8.255155646788367],
              [-35.207547347892195, -8.255426121402289],
              [-35.207752111084183, -8.255685412718735],
              [-35.207945541454286, -8.255944729339443],
              [-35.208383016033899, -8.256327584153533],
              [-35.208553622517691, -8.256530468328339],
              [-35.208661821595349, -8.25671094816583],
              [-35.208684986755365, -8.256880417589249],
              [-35.208691203581061, -8.2570668840194],
              [-35.208691569704115, -8.257191211192618],
              [-35.208669459747476, -8.257377780707364],
              [-35.20873230707879, -8.257552760507201],
              [-35.208857537767386, -8.257744489243967],
              [-35.208999711073361, -8.257913565585234],
              [-35.2091247938584, -8.2580544341143],
              [-35.209278326170761, -8.258234769809585],
              [-35.209341012933798, -8.258353241043348],
              [-35.209415238195568, -8.258539484639599],
              [-35.209528968469421, -8.258674748733828],
              [-35.209631169488091, -8.258742219535025],
              [-35.20971050823232, -8.258741961957787],
              [-35.209903079914014, -8.258707417922723],
              [-35.210084281106994, -8.258661612076121],
              [-35.210525381812495, -8.258354989717953],
              [-35.210677524429293, -8.258060619000615],
              [-35.210744732035046, -8.257794785753218],
              [-35.210727349266683, -8.257664865029785],
              [-35.210681652534781, -8.257540688898498],
              [-35.210573344918664, -8.257326297119953],
              [-35.210305636765156, -8.256863771043207],
              [-35.210214405000158, -8.256671926872057],
              [-35.210123136030603, -8.256468783793533],
              [-35.21000343308755, -8.25623183101977],
              [-35.209889317384444, -8.255966589241293],
              [-35.209837537018053, -8.255701151400961],
              [-35.209780014464826, -8.255413126260684],
              [-35.209711216132717, -8.255142085317098],
              [-35.20955049805972, -8.254447511279903],
              [-35.209475433330098, -8.253973056665716],
              [-35.209411945500406, -8.253583323972416],
              [-35.209297079667792, -8.253063786065995],
              [-35.209273544308665, -8.252769987219928],
              [-35.209232928141496, -8.252448001340403],
              [-35.20923159512035, -8.251995899865804],
              [-35.209224927877948, -8.251656850964636],
              [-35.209223244453455, -8.251086070603916],
              [-35.209261953175542, -8.25075817000366],
              [-35.209345402828632, -8.250232322374208],
              [-35.209338846558403, -8.249932827721796],
              [-35.209321452455939, -8.249797258367545],
              [-35.209275770484993, -8.249678729987048],
              [-35.209156231797408, -8.249498286914925],
              [-35.209002436164461, -8.249227524328138],
              [-35.208928201167026, -8.249035629439412],
              [-35.20891640737041, -8.248877433294068],
              [-35.208944055019614, -8.248645639314619],
              [-35.208988938175175, -8.248492906354956],
              [-35.209067524069681, -8.248238335213406],
              [-35.209117824513633, -8.248000821203405],
              [-35.209145228817114, -8.247684254258157],
              [-35.20912769077605, -8.247503473119021],
              [-35.208926703612505, -8.24659993032836],
              [-35.208783324810966, -8.246018314539548],
              [-35.208646246787453, -8.245651432064637],
              [-35.208440705326439, -8.24512653354919],
              [-35.208052287636924, -8.244076671071866],
              [-35.207824108439361, -8.243563151595037],
              [-35.207596291110562, -8.243168310798527],
              [-35.207362982097301, -8.242835653100574],
              [-35.207135917789117, -8.242700767859764],
              [-35.207011056460217, -8.242633355140295],
              [-35.206767031627777, -8.242509825058715],
              [-35.206505837439316, -8.242329842858823],
              [-35.206312561752242, -8.242121383590556],
              [-35.206283934367228, -8.242019745052771],
              [-35.206255090259681, -8.241844655432805],
              [-35.206214696684107, -8.241596126167009],
              [-35.206197065860472, -8.241381431441143],
              [-35.206213395931606, -8.241155331761314],
              [-35.206235398068017, -8.240929200879666],
              [-35.206324822177805, -8.240505063131584],
              [-35.20644794492992, -8.239979089749617],
              [-35.206480855444696, -8.239605996671601],
              [-35.20644587135429, -8.239272688570205],
              [-35.206376467863144, -8.238792551838888],
              [-35.206285194439403, -8.238583757793323],
              [-35.206091522666199, -8.238239662199769],
              [-35.205903563863878, -8.237912501024102],
              [-35.205749741973264, -8.237630436360501],
              [-35.205567488324313, -8.237314561128098],
              [-35.205373783284415, -8.236959165920053],
              [-35.205242699025398, -8.236699627329234],
              [-35.205191301201701, -8.236564163103813],
              [-35.205156823577326, -8.236400396270257],
              [-35.205162060583312, -8.236253439674265],
              [-35.205190096826932, -8.236151623657189],
              [-35.205223877993028, -8.236078050463481],
              [-35.205297179397306, -8.235953480017674],
              [-35.205387498043635, -8.23583451072362],
              [-35.205432527139571, -8.235732638343606],
              [-35.205471748858827, -8.235579925267837],
              [-35.205476819215036, -8.235376457854604],
              [-35.205476433943005, -8.235246482005381],
              [-35.205464558891642, -8.235060027459516],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '200',
        NOME_UC1: 'RESERVA BIOLÓGICA DE SERRA NEGRA',
        ID_WCMC2: '52',
        CATEGORI3: 'Reserva Biológica',
        GRUPO4: 'PI',
        ESFERA5: 'federal',
        ANO_CRIA6: '1982',
        GID7: '303',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 87591 de 20/09/1982',
        DT_ULTIM10: '27/09/2007',
        CODIGO_U11: '0000.00.0200',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.031688240127103, -8.646624390987197],
              [-38.031585215306698, -8.64656048385034],
              [-38.031306685768115, -8.646524211799374],
              [-38.031005315189738, -8.646478478406461],
              [-38.030715405096473, -8.646449909484671],
              [-38.030494128384085, -8.646440331049185],
              [-38.03017751963727, -8.646421322532985],
              [-38.029666430289872, -8.646411729155375],
              [-38.029349818821096, -8.646401176932917],
              [-38.029162879311158, -8.646419309000922],
              [-38.028891950172309, -8.646463169470904],
              [-38.028437948606012, -8.646453735113127],
              [-38.028018413271703, -8.646443247652146],
              [-38.027633144241037, -8.646460396268115],
              [-38.027366110235228, -8.646450828637427],
              [-38.02693505760292, -8.646449917727997],
              [-38.026736664268867, -8.64644033089027],
              [-38.025958486575966, -8.646347868566556],
              [-38.025584561154908, -8.646256261107022],
              [-38.024951436381961, -8.646074110458375],
              [-38.02451269684925, -8.645918611957901],
              [-38.024325641297679, -8.645800415361711],
              [-38.024100785840197, -8.645582974683284],
              [-38.023913780501154, -8.645167127179336],
              [-38.023536247803918, -8.644559671977033],
              [-38.023269147483944, -8.644061916962553],
              [-38.023097455419297, -8.643898859093103],
              [-38.022975378761402, -8.643681349936816],
              [-38.022765649659654, -8.64324643475998],
              [-38.022578670590157, -8.64288407906813],
              [-38.022242992042848, -8.642747677923992],
              [-38.022002637135984, -8.642620851603667],
              [-38.021735622526073, -8.64259220248219],
              [-38.021441742925361, -8.642600863200574],
              [-38.021293089933536, -8.642618939900389],
              [-38.020881053111133, -8.642608419717906],
              [-38.020415768464417, -8.642706651334825],
              [-38.020236447393174, -8.642842064396547],
              [-38.020057211787744, -8.643004223660164],
              [-38.019629957988087, -8.643238855389091],
              [-38.019507816660095, -8.643346604519508],
              [-38.019305653400664, -8.643526836893495],
              [-38.019088134177231, -8.643743401847152],
              [-38.019004216644468, -8.643833892803793],
              [-38.018775360467409, -8.644104853896716],
              [-38.01858468070612, -8.644230620779865],
              [-38.018310037439925, -8.644491978465759],
              [-38.017871349507622, -8.644717030194531],
              [-38.017436489766006, -8.644923926065369],
              [-38.017211286880382, -8.644977377393049],
              [-38.01680325067489, -8.645012667076703],
              [-38.016307338799599, -8.645129056888894],
              [-38.015826679027143, -8.645299674848973],
              [-38.015567150572494, -8.645316884973816],
              [-38.015109532565006, -8.645045084583943],
              [-38.014426570330983, -8.644916353821253],
              [-38.014029932223337, -8.644824751413358],
              [-38.013511083872203, -8.644696984780721],
              [-38.012965647994704, -8.644623592954662],
              [-38.012603262439761, -8.644522474774117],
              [-38.012237025312565, -8.644331816124128],
              [-38.011802184651458, -8.643969371602536],
              [-38.011199470578667, -8.643868271664832],
              [-38.010756905125511, -8.643813028856904],
              [-38.010325870601065, -8.643829159759919],
              [-38.010009257154074, -8.643900700448974],
              [-38.009913900092563, -8.64394641307897],
              [-38.009639266462614, -8.644153403514004],
              [-38.009437126450464, -8.644351764966226],
              [-38.009158515258264, -8.644631182835386],
              [-38.009013659721305, -8.644857179027388],
              [-38.008975433776861, -8.645020361334899],
              [-38.008792365822721, -8.645209116377529],
              [-38.008578822989357, -8.645407443623993],
              [-38.00837275926925, -8.645605811808595],
              [-38.008185830924646, -8.645759450570626],
              [-38.007777683058144, -8.646129507764158],
              [-38.007575477572516, -8.646273438871669],
              [-38.007384746126228, -8.646417404470149],
              [-38.007274158133583, -8.646516651741353],
              [-38.007117701861979, -8.646760746656993],
              [-38.007075748510118, -8.646995352120852],
              [-38.007083424563831, -8.647112632470444],
              [-38.007445837343603, -8.647575140722623],
              [-38.00757167483799, -8.647657183681925],
              [-38.00766315066619, -8.647701974044866],
              [-38.00799511553226, -8.64791089786519],
              [-38.008189598012038, -8.647984325585412],
              [-38.008479593800971, -8.648138821508187],
              [-38.008719861816978, -8.648311404038054],
              [-38.008937373248095, -8.648465926679933],
              [-38.009280630301177, -8.648638524629307],
              [-38.009406607347067, -8.648729101537683],
              [-38.00984132578278, -8.649055266788491],
              [-38.009955905832918, -8.64913735527586],
              [-38.010318172743069, -8.649473017170459],
              [-38.010646232372402, -8.649808654733802],
              [-38.010966756678989, -8.650116711770641],
              [-38.011119309126144, -8.650080492969241],
              [-38.011352018982791, -8.65048865778191],
              [-38.011638218552065, -8.65107705167488],
              [-38.011847923186053, -8.651457608369357],
              [-38.012073027283925, -8.651838119248159],
              [-38.012183643986788, -8.652019309465389],
              [-38.012328637699163, -8.652218600193949],
              [-38.012557382417647, -8.652409363962571],
              [-38.012935154689622, -8.652708777397415],
              [-38.013301312803065, -8.653305792740179],
              [-38.01371339835476, -8.653614777096042],
              [-38.014113841764114, -8.653905651636654],
              [-38.014525876030781, -8.654141200311303],
              [-38.015197132271773, -8.654387293099528],
              [-38.015601554483254, -8.654478816888391],
              [-38.016353134237356, -8.65469819394891],
              [-38.016967277988279, -8.65488889958819],
              [-38.017463264466492, -8.655016688955438],
              [-38.017707332283315, -8.655045284082508],
              [-38.017917146009715, -8.655073057506705],
              [-38.018351942593227, -8.6554267751056],
              [-38.018779229171372, -8.655897977585033],
              [-38.019084403705662, -8.656350869184937],
              [-38.01948109884578, -8.657111943666294],
              [-38.019805347696121, -8.657636475195547],
              [-38.019980942531134, -8.658089375416228],
              [-38.020232700485927, -8.658659760191162],
              [-38.020381379003268, -8.659157557559126],
              [-38.02048438378209, -8.659592484027574],
              [-38.020560605707814, -8.659737436941558],
              [-38.020846649649584, -8.660045402583668],
              [-38.021083294905296, -8.660272370527785],
              [-38.021445688945626, -8.660652942181502],
              [-38.021720251340575, -8.660834093840293],
              [-38.021918680790378, -8.660853164421244],
              [-38.022357468415926, -8.660999078772248],
              [-38.022830416054589, -8.661163159148092],
              [-38.023444577447144, -8.661408229619854],
              [-38.023940476021203, -8.661626590865897],
              [-38.024352512884036, -8.661889833291674],
              [-38.024749224540884, -8.662198879083668],
              [-38.025199315941059, -8.662435319341123],
              [-38.0260118487179, -8.662961769519455],
              [-38.026564998514822, -8.663261186391551],
              [-38.027129563810632, -8.663416650610333],
              [-38.02727069752801, -8.663462509663523],
              [-38.027427161022736, -8.663643632257086],
              [-38.027804718282368, -8.663933515077236],
              [-38.028132932479252, -8.664251112848195],
              [-38.028567810993202, -8.664578250528502],
              [-38.028693577102608, -8.664723184441369],
              [-38.02881945181133, -8.664777552092875],
              [-38.028968272943978, -8.664886273832643],
              [-38.02942603500216, -8.665213407984089],
              [-38.029933386235733, -8.665476695043608],
              [-38.030265264273439, -8.665676889817966],
              [-38.030539817538511, -8.665839975068867],
              [-38.030871794512855, -8.666093590915432],
              [-38.031249404262702, -8.666330134278246],
              [-38.031608038211431, -8.666448489629849],
              [-38.031977976430795, -8.666521841352623],
              [-38.032492941807988, -8.666550398696154],
              [-38.032840222257725, -8.666569477309885],
              [-38.033374209070139, -8.666606714025836],
              [-38.03366802888447, -8.666625752642119],
              [-38.034037996284567, -8.666662961959025],
              [-38.034385037650829, -8.666799348275024],
              [-38.03472072780589, -8.666917560661945],
              [-38.035022175148057, -8.66693664514394],
              [-38.035281578135795, -8.666847007350912],
              [-38.035399770691612, -8.666792683036745],
              [-38.035552421603427, -8.666350151866478],
              [-38.035594383806945, -8.666150866721292],
              [-38.035701259807617, -8.665716946445583],
              [-38.03577358911997, -8.665563381428875],
              [-38.035952931476331, -8.665392765356883],
              [-38.036113174239965, -8.665176167082212],
              [-38.03627730046324, -8.665085694764574],
              [-38.036536641706938, -8.664987377480658],
              [-38.036925738046826, -8.664861444633466],
              [-38.037387383825987, -8.66466312807413],
              [-38.038081566433064, -8.664366505068775],
              [-38.038211241348435, -8.66430368334783],
              [-38.038520270448792, -8.664205343201566],
              [-38.03890940188225, -8.664034662553069],
              [-38.039191671042218, -8.663962143319571],
              [-38.03952347233561, -8.663764833298929],
              [-38.039653109502687, -8.663665577145871],
              [-38.039916431943574, -8.663530164920919],
              [-38.040221656188621, -8.663368006651707],
              [-38.040481029839576, -8.663314646146251],
              [-38.040763310028382, -8.66327935204613],
              [-38.040973056960915, -8.663288902303197],
              [-38.041251475212142, -8.663316507894127],
              [-38.041438439665356, -8.663289851659785],
              [-38.041766536567671, -8.663236403235061],
              [-38.042102266848111, -8.663264106070926],
              [-38.042514229633603, -8.663591174329486],
              [-38.042887965861652, -8.663935429659809],
              [-38.043231423981752, -8.664216763627129],
              [-38.043525111342106, -8.664606834942381],
              [-38.043708256130117, -8.664733655739923],
              [-38.044223196841088, -8.665051267823275],
              [-38.044726641099928, -8.665350701164433],
              [-38.045359956906807, -8.665723577661637],
              [-38.045668996071448, -8.665896235586713],
              [-38.045993203858245, -8.665996334404909],
              [-38.046340386847646, -8.666114647101191],
              [-38.046985052886932, -8.666378762042944],
              [-38.047240696486121, -8.66637016007053],
              [-38.047606815235355, -8.666334935809902],
              [-38.047980648036614, -8.666190894619886],
              [-38.048560474166322, -8.66593916454886],
              [-38.048846603334255, -8.665776987973654],
              [-38.048900030249762, -8.665542448281823],
              [-38.048865679570504, -8.665054113318476],
              [-38.048755121183262, -8.664845363332859],
              [-38.048678730388801, -8.664619213810228],
              [-38.048495762541876, -8.664537218016395],
              [-38.048362110533638, -8.66437415323024],
              [-38.048201916342222, -8.664247329229021],
              [-38.048041700458235, -8.664102360289448],
              [-38.047709824789777, -8.663857352556462],
              [-38.047458049233079, -8.663702792654309],
              [-38.047194867613726, -8.663548273233049],
              [-38.047057463010631, -8.663403296380766],
              [-38.047019385486635, -8.663132494808719],
              [-38.047015581511097, -8.662878778549839],
              [-38.047042290693213, -8.662670860598659],
              [-38.047175819590521, -8.662174077887855],
              [-38.047404708574192, -8.661903156622111],
              [-38.047511524459345, -8.661812576437317],
              [-38.0476907641523, -8.661632326301543],
              [-38.047862464596562, -8.661334781852782],
              [-38.04792733123125, -8.661145021672413],
              [-38.048064518856243, -8.660801757376866],
              [-38.048167595747813, -8.660511821892442],
              [-38.048301201369377, -8.660250552545808],
              [-38.048293474726442, -8.66009689114588],
              [-38.048263022171554, -8.659915772130097],
              [-38.048186576439008, -8.659797548601052],
              [-38.048087454929338, -8.659508522368023],
              [-38.047843388136229, -8.659190934416566],
              [-38.047583940847183, -8.658801830210429],
              [-38.047336011859997, -8.658547217245316],
              [-38.047255868950266, -8.658474697304547],
              [-38.047156663495102, -8.658194344050971],
              [-38.046977431331655, -8.657932051591066],
              [-38.046859068582592, -8.657569715071876],
              [-38.046733208918049, -8.657180663279012],
              [-38.046737049756494, -8.657045256633117],
              [-38.046763813797973, -8.656854400334513],
              [-38.046859212797891, -8.656728582568382],
              [-38.04698112943597, -8.656602666496418],
              [-38.047091940316072, -8.656457717364841],
              [-38.04707662114086, -8.656150617927119],
              [-38.047015533790464, -8.655743413547146],
              [-38.04697743416871, -8.655435457057717],
              [-38.046992673858121, -8.655009991834341],
              [-38.046931602294784, -8.654675365127678],
              [-38.04675616285941, -8.654241378850845],
              [-38.046557846106595, -8.653842748637206],
              [-38.046260333659909, -8.653651997297203],
              [-38.046061848447906, -8.653588124414062],
              [-38.045852061095566, -8.653588125423818],
              [-38.045668949210551, -8.653460302004763],
              [-38.0454933502619, -8.653496540015599],
              [-38.04523798307995, -8.653541378596444],
              [-38.044951831831689, -8.6536129144062],
              [-38.044787741688118, -8.653702534680599],
              [-38.044619899255295, -8.653910431306914],
              [-38.044417838069535, -8.6540182601395],
              [-38.044215542247706, -8.65420798146455],
              [-38.043845562049668, -8.654477872570654],
              [-38.043551791703393, -8.65454083030024],
              [-38.04321232136602, -8.654612405026228],
              [-38.042964409693823, -8.65464759416423],
              [-38.042731660980252, -8.654656198289006],
              [-38.042380775414308, -8.654646706963677],
              [-38.04202218671891, -8.654591409543753],
              [-38.041572011458136, -8.654372986178441],
              [-38.041365980893403, -8.654254708962098],
              [-38.04104177989025, -8.654163152479542],
              [-38.0407174025854, -8.654018242963158],
              [-38.040583850037478, -8.653873332997533],
              [-38.040274972616793, -8.653646263190415],
              [-38.04011476353655, -8.653501302126784],
              [-38.039920248632434, -8.653427877942613],
              [-38.039618801933941, -8.653319179198686],
              [-38.039309914917261, -8.653100783973095],
              [-38.039092524662358, -8.652929130816126],
              [-38.038970300242546, -8.652792708140087],
              [-38.038714787063178, -8.652303493609928],
              [-38.038562179235178, -8.6519506144239],
              [-38.038451635777179, -8.651733201927728],
              [-38.038363948144351, -8.65155199700626],
              [-38.038161664965017, -8.651280245198647],
              [-38.038096810897528, -8.651117103636787],
              [-38.037841241892394, -8.650998928395353],
              [-38.037585725333969, -8.650916825137719],
              [-38.037307205592583, -8.650898768453729],
              [-38.037108823816062, -8.650907327616228],
              [-38.036803624336585, -8.650914951749876],
              [-38.03650237902783, -8.650932108878552],
              [-38.036208598441654, -8.650922633195437],
              [-38.035758387114626, -8.650776641350904],
              [-38.035437874454786, -8.650523079636873],
              [-38.035232042287809, -8.650495311338176],
              [-38.03498003190478, -8.65033135818504],
              [-38.034694174865109, -8.650077618272764],
              [-38.034301043683776, -8.649904969971026],
              [-38.033923530181283, -8.649678068996536],
              [-38.03360302602956, -8.649424364523744],
              [-38.033477242159556, -8.649342323736938],
              [-38.033347555979759, -8.649034334447192],
              [-38.033217774953904, -8.648708198991429],
              [-38.033011801200601, -8.648482152498863],
              [-38.032904925513463, -8.648174082898793],
              [-38.032786754577749, -8.647766885051354],
              [-38.032569335456778, -8.647295765777521],
              [-38.032489224924504, -8.647187037361013],
              [-38.032309935248733, -8.646924758398145],
              [-38.032218397275962, -8.646797926915864],
              [-38.032012378156438, -8.646679655009514],
              [-38.031794973198949, -8.64663392445817],
              [-38.031688240127103, -8.646624390987197],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3320',
        NOME_UC1: 'REFÚGIO DA VIDA SILVESTRE RIACHO PONTAL',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2014',
        GID7: '12325',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 40.552/14 de 28/03/2014',
        DT_ULTIM10: '16/03/2016',
        CODIGO_U11: '0000.26.3320',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.596850375397864, -9.057132643108554],
              [-40.596859417308359, -9.057161636953635],
              [-40.597491542449035, -9.059844058432743],
              [-40.597739794017699, -9.060610049347563],
              [-40.598341745644738, -9.061895564659279],
              [-40.598538951101666, -9.062079061805118],
              [-40.5993077687396, -9.063173384343534],
              [-40.599511247930074, -9.06360044412499],
              [-40.599078601771652, -9.063792652653023],
              [-40.602143766705062, -9.06782809672386],
              [-40.602625190057047, -9.070331953076892],
              [-40.607335519596703, -9.078646836580816],
              [-40.614875990596325, -9.073645376522748],
              [-40.616016975202086, -9.075216411722227],
              [-40.616901304489183, -9.077997098889989],
              [-40.620964299581338, -9.075354340422932],
              [-40.623690252363723, -9.072310240001704],
              [-40.627218150321426, -9.068429410009275],
              [-40.626569403699754, -9.067494429679027],
              [-40.628085063199272, -9.067422702452642],
              [-40.632321972018971, -9.067034503465921],
              [-40.633976724689255, -9.066810451221604],
              [-40.625341367453991, -9.043208991142759],
              [-40.623200822706039, -9.03846262391491],
              [-40.622856865956351, -9.03862429869533],
              [-40.620615863800346, -9.039478173871585],
              [-40.618351087767458, -9.041475537592573],
              [-40.615444801131019, -9.04355668726358],
              [-40.615437033248526, -9.043487264647208],
              [-40.614340987092653, -9.035589632098002],
              [-40.616433589047574, -9.029505509221861],
              [-40.615481006287403, -9.027750282243112],
              [-40.614956190829233, -9.026780738227796],
              [-40.615036282065873, -9.025907701213825],
              [-40.614907812478975, -9.025339423296835],
              [-40.614783689649954, -9.02500372107472],
              [-40.613647879965782, -9.022370285876526],
              [-40.613539189922726, -9.022151459010576],
              [-40.609471228282445, -9.021054993524734],
              [-40.607607582804967, -9.018275508497871],
              [-40.610292433621701, -9.015487137610631],
              [-40.61272755545054, -9.013500661983622],
              [-40.612735188393209, -9.013505184964986],
              [-40.612762705184942, -9.013523295887769],
              [-40.612805191863274, -9.013551271768387],
              [-40.612835151640958, -9.013566064712062],
              [-40.612848210541145, -9.013575737669303],
              [-40.612898460156934, -9.013612958504895],
              [-40.612955614737977, -9.013635536429101],
              [-40.612979362562925, -9.013645958391765],
              [-40.612986327511258, -9.01364939737867],
              [-40.613000195403671, -9.013661381323681],
              [-40.613046072052931, -9.013695361173538],
              [-40.613080753793824, -9.013714392097571],
              [-40.613123563468477, -9.013743978969485],
              [-40.613157411218971, -9.013758843916172],
              [-40.613185074009593, -9.013777049838513],
              [-40.613198031913385, -9.013783457814068],
              [-40.613268007382601, -9.013830699611011],
              [-40.61329403518814, -9.013844981553984],
              [-40.613325701945278, -9.013869307445432],
              [-40.613375421573842, -9.013896584336548],
              [-40.613414648282756, -9.013915948262794],
              [-40.613454062990272, -9.013935410188591],
              [-40.613473818844632, -9.01394408115757],
              [-40.613493231697689, -9.013956863103045],
              [-40.613525596453876, -9.013976847019617],
              [-40.613558154209393, -9.013996132940283],
              [-40.613579320049112, -9.0140100688808],
              [-40.613637918599665, -9.014055067680115],
              [-40.61373935886764, -9.014082392617441],
              [-40.613759300720467, -9.014091345584916],
              [-40.613786983515027, -9.014105014532884],
              [-40.613829137200156, -9.014128133440591],
              [-40.613898196696127, -9.01415294436296],
              [-40.613933379444546, -9.014159887355444],
              [-40.613962846229477, -9.014170473322356],
              [-40.614011002880765, -9.014184730285335],
              [-40.614055464561929, -9.014194491270333],
              [-40.614092805296011, -9.014200638269301],
              [-40.614114266141158, -9.014206417256045],
              [-40.61417770767892, -9.014228347189585],
              [-40.614261764066477, -9.014257396101545],
              [-40.614321140641891, -9.014269117088938],
              [-40.614376729249351, -9.014274603108024],
              [-40.614415424979576, -9.014274605142763],
              [-40.614440293803987, -9.014277059151322],
              [-40.614485770482872, -9.014281547166924],
              [-40.614534836140777, -9.014281550210971],
              [-40.61457000589558, -9.014281549242588],
              [-40.614594826722552, -9.014281549264895],
              [-40.614620616540442, -9.014284094273711],
              [-40.61464918633898, -9.014286659284991],
              [-40.61467343516555, -9.014291444279827],
              [-40.614718599842575, -9.014300371270163],
              [-40.614765629510451, -9.014305012286279],
              [-40.614818149140383, -9.01430932630921],
              [-40.614865510810226, -9.014309329351722],
              [-40.614904148540845, -9.014309322386499],
              [-40.614946419246131, -9.014309326424478],
              [-40.6149850609768, -9.014309319459203],
              [-40.615023773706895, -9.014309321494018],
              [-40.61505892946176, -9.014309320525626],
              [-40.615097668191751, -9.014309322560406],
              [-40.615136316922289, -9.014309315595197],
              [-40.615175056652198, -9.014309316629994],
              [-40.615213706382782, -9.014309309664727],
              [-40.615252396113036, -9.014309311699522],
              [-40.615291117843093, -9.014309313734287],
              [-40.615333343548727, -9.014309308772255],
              [-40.615361639351455, -9.014309303797708],
              [-40.615386518175789, -9.014311764806219],
              [-40.61541490697536, -9.014314566815996],
              [-40.615467375601682, -9.014323204814513],
              [-40.615513851277711, -9.014323195856312],
              [-40.615543903065749, -9.014325894868113],
              [-40.61558519577477, -9.014329294886105],
              [-40.615629001465457, -9.014333617901123],
              [-40.61567800512384, -9.01433360894519],
              [-40.615720241829386, -9.01433361298314],
              [-40.615755420584151, -9.014333612014772],
              [-40.615790645338549, -9.014333611046441],
              [-40.61580520123708, -9.01433360705953],
              [-40.615815312164777, -9.014335606057369],
              [-40.615880880692963, -9.014351791025163],
              [-40.615949091202111, -9.014368629991671],
              [-40.615983109956616, -9.014377789970661],
              [-40.616024146659491, -9.014389948939082],
              [-40.616102355099471, -9.014406204917826],
              [-40.616132436884868, -9.01441160491448],
              [-40.616150772752079, -9.014417037900381],
              [-40.616168309624122, -9.014423334880661],
              [-40.616184670501902, -9.014432312844798],
              [-40.616307474583188, -9.014501138567695],
              [-40.616328059427374, -9.014514691509925],
              [-40.616339660338681, -9.01452328347195],
              [-40.616362192161361, -9.014545530366952],
              [-40.616390330943076, -9.014569855255321],
              [-40.616402991843465, -9.014582355196293],
              [-40.616413655756361, -9.014596398126841],
              [-40.616446316487583, -9.014641552901979],
              [-40.61664134787322, -9.014921667500241],
              [-40.616777091676973, -9.01519643907532],
              [-40.617125507874903, -9.01560675407848],
              [-40.618002418621053, -9.016860733807306],
              [-40.618028885402822, -9.016897807622367],
              [-40.618069243058073, -9.0169675452661],
              [-40.618146084408693, -9.017092534631516],
              [-40.618223842796787, -9.017169321269169],
              [-40.618253123569616, -9.017194626153046],
              [-40.61830311017625, -9.017243988920118],
              [-40.61833734190688, -9.017277792760595],
              [-40.618377825592795, -9.017312769600075],
              [-40.618407093369093, -9.017334446504366],
              [-40.618434996152622, -9.017358562393715],
              [-40.618467956899806, -9.017383881280791],
              [-40.618498643661745, -9.017410394159151],
              [-40.618527907438001, -9.017432062063474],
              [-40.61855126825683, -9.017452259970792],
              [-40.61859087894954, -9.017486480813728],
              [-40.618642269560496, -9.0175203136695],
              [-40.618675219314348, -9.01753838759735],
              [-40.618697859142962, -9.017553290533831],
              [-40.618829555139456, -9.01764722412346],
              [-40.619364658087818, -9.018000391616484],
              [-40.619486714402001, -9.017818964747498],
              [-40.62501047474737, -9.017982998790886],
              [-40.625014880148903, -9.01750798146856],
              [-40.625592808769085, -9.016795032001205],
              [-40.625812073452678, -9.015462785697348],
              [-40.625687242986082, -9.014733626689519],
              [-40.625105275409908, -9.014329848439294],
              [-40.625503493131518, -9.013783000875483],
              [-40.625714564982161, -9.013429049057654],
              [-40.62595020836455, -9.012301982614005],
              [-40.625603699515835, -9.011492555859141],
              [-40.633143734516658, -9.008682465452774],
              [-40.631828240525422, -9.006657928667662],
              [-40.630356976708896, -9.00453497929783],
              [-40.631167558991486, -9.003509008801839],
              [-40.633013010109053, -9.00132983772745],
              [-40.632633932072743, -8.998773378780641],
              [-40.632466315265383, -8.997645073983151],
              [-40.63049989364989, -8.993591308045616],
              [-40.631145410281334, -8.993447635433631],
              [-40.641387564319025, -8.988581904017771],
              [-40.643622020093403, -8.988201987160485],
              [-40.645996131682018, -8.985852038520941],
              [-40.643376256913122, -8.982570007654521],
              [-40.638536027739931, -8.980254486358866],
              [-40.635905743235391, -8.978967689250938],
              [-40.634434843807874, -8.977508312154285],
              [-40.631622671483889, -8.976318113341206],
              [-40.630486576885417, -8.976888020113575],
              [-40.63070509849139, -8.973432598776066],
              [-40.633081051250343, -8.971977797098516],
              [-40.632501961711434, -8.969297927679063],
              [-40.631676658750855, -8.965663809417739],
              [-40.631052673623927, -8.962872267590447],
              [-40.630253552366739, -8.959361627660771],
              [-40.629956812501014, -8.958181755045327],
              [-40.629383622136643, -8.957472388531343],
              [-40.629346972538414, -8.957310389411674],
              [-40.622608786862834, -8.961334094712829],
              [-40.621160123354734, -8.959790630118196],
              [-40.62080266460584, -8.960056612299597],
              [-40.62052846420135, -8.962618496614443],
              [-40.619364450341358, -8.963695062506279],
              [-40.622957862090814, -8.968344128517289],
              [-40.622936249258338, -8.971641055919195],
              [-40.625531322144582, -8.971670519074781],
              [-40.625524009301365, -8.978180638388489],
              [-40.62454727111902, -8.978169641576031],
              [-40.624516959734891, -8.983241891973829],
              [-40.627062508966688, -8.983270747091582],
              [-40.627041767475568, -8.985075323907987],
              [-40.627940856199956, -8.98508551765614],
              [-40.628557110641154, -8.985376424569765],
              [-40.628520631026184, -8.988540859713764],
              [-40.627073261128864, -8.988524454508795],
              [-40.62027096061194, -8.988447274846783],
              [-40.617090094817463, -8.988411141199714],
              [-40.616847926850014, -8.988031181123198],
              [-40.616834023703326, -8.984990305241773],
              [-40.61524703578236, -8.984972263921827],
              [-40.615266622188791, -8.98326953553266],
              [-40.613783838496794, -8.983300910027962],
              [-40.607236543208032, -8.983226372584745],
              [-40.607209762289543, -8.985549909468055],
              [-40.604027025512167, -8.985513636821842],
              [-40.604030747763119, -8.985208053547167],
              [-40.60372870087199, -8.98520474229532],
              [-40.603733103187103, -8.984823078449963],
              [-40.598751606971305, -8.984766245309361],
              [-40.598715802060099, -8.987151190837006],
              [-40.598576566952275, -8.989444801787416],
              [-40.606150496398428, -8.990267677937139],
              [-40.603814160216587, -8.996300184856434],
              [-40.603595684228111, -8.996864288482611],
              [-40.602232731537804, -9.000383398435723],
              [-40.601716594788776, -8.999664808020798],
              [-40.600668724423514, -8.998201008327323],
              [-40.599604455285515, -8.996612521322206],
              [-40.599513989018718, -8.996499637877045],
              [-40.599364571239036, -8.996302883851603],
              [-40.599342295424321, -8.996269873017642],
              [-40.599313324673894, -8.996217419287182],
              [-40.599222376421267, -8.996092660908522],
              [-40.599153879973358, -8.996010510309995],
              [-40.599121808230514, -8.995973549489449],
              [-40.599077826576632, -8.995930106694798],
              [-40.599052158775685, -8.995907922796786],
              [-40.599031757946683, -8.995876260956857],
              [-40.598997503238152, -8.995818249252977],
              [-40.598971957448299, -8.99578293042906],
              [-40.598953352603061, -8.995755359567744],
              [-40.59893604976245, -8.995712655792824],
              [-40.598898340081682, -8.995650582108777],
              [-40.598858412406109, -8.995599871358667],
              [-40.59882931163876, -8.99556702751765],
              [-40.598815314755171, -8.995546286621945],
              [-40.598804454856868, -8.995517678773412],
              [-40.598774898115913, -8.995459292075854],
              [-40.598736915434259, -8.995400343373943],
              [-40.598677623922931, -8.995317379788222],
              [-40.598646337177108, -8.995277653983987],
              [-40.598629294318833, -8.995252405110993],
              [-40.598618384419332, -8.995225465253002],
              [-40.598604496557684, -8.995179737498159],
              [-40.598581568768893, -8.995123121796601],
              [-40.598558422971756, -8.995077399033493],
              [-40.598544316101695, -8.995042565217094],
              [-40.598532195222411, -8.995002652431083],
              [-40.598520173342166, -8.99496307664328],
              [-40.598511255444244, -8.994919034883479],
              [-40.598495656604243, -8.994862554187717],
              [-40.598481269747559, -8.994815185441704],
              [-40.598465614903432, -8.994763649718035],
              [-40.598447099073994, -8.994717927959067],
              [-40.598436793176653, -8.99468399514102],
              [-40.598252916271591, -8.993787738026056],
              [-40.598243404380547, -8.993740768282152],
              [-40.598233867485455, -8.993698371512531],
              [-40.598215619687146, -8.993616266958803],
              [-40.598177476094207, -8.993460701801105],
              [-40.598167830204638, -8.993413067060869],
              [-40.598156351336122, -8.993356377369993],
              [-40.598146151507684, -8.99324559398511],
              [-40.59814161557992, -8.993200806233375],
              [-40.598137830640226, -8.993163418440639],
              [-40.598129511739231, -8.99311818568807],
              [-40.598116985901378, -8.993035713141522],
              [-40.598090070177406, -8.992938243666588],
              [-40.598069919417192, -8.992828751265508],
              [-40.598066827454005, -8.992811953357396],
              [-40.598028196786267, -8.992742580713632],
              [-40.59800442099997, -8.99268976498993],
              [-40.597983712190725, -8.992638626259527],
              [-40.597936592630511, -8.992515918908721],
              [-40.59779656287737, -8.992217629463969],
              [-40.597756441225037, -8.992142763849884],
              [-40.59772948045326, -8.992098388075766],
              [-40.597688852809299, -8.99201812749161],
              [-40.597668490984134, -8.991981917677379],
              [-40.597651630139424, -8.991940289896872],
              [-40.597606230541842, -8.991845628389548],
              [-40.596850374937837, -8.990604113708104],
              [-40.596273335211322, -8.99032849874426],
              [-40.596187151842742, -8.990294882856423],
              [-40.596166541993725, -8.990286884883028],
              [-40.596150403102889, -8.990290574847801],
              [-40.596047268800874, -8.990313905623953],
              [-40.595365692200311, -8.990703585817364],
              [-40.595276303783585, -8.990747768488294],
              [-40.595183987393078, -8.990785539192737],
              [-40.595089210025719, -8.990816704932179],
              [-40.594992448678312, -8.990841111707962],
              [-40.594894189347578, -8.990858635521162],
              [-40.594794929030172, -8.990869187372757],
              [-40.594695164722623, -8.99087271426354],
              [-40.594595400421518, -8.990869199193964],
              [-40.594496137123222, -8.990858660164402],
              [-40.594397876824274, -8.990841149175036],
              [-40.594301111521133, -8.990816754225843],
              [-40.594206331210316, -8.990785600316485],
              [-40.594114009888315, -8.990747841446543],
              [-40.594024615551746, -8.990703669615369],
              [-40.594010559656773, -8.990695935646352],
              [-40.593970783953921, -8.990674049734039],
              [-40.593930988251323, -8.990652154821793],
              [-40.593896078512159, -8.990632946898733],
              [-40.59389120654852, -8.990630266909504],
              [-40.593851428845767, -8.990608379997203],
              [-40.593811632143115, -8.990586485084906],
              [-40.593771857440309, -8.990564600172627],
              [-40.593732061737647, -8.990542704260347],
              [-40.593692286034859, -8.990520819348015],
              [-40.593652491332186, -8.990498923435791],
              [-40.593612715629391, -8.990477038523458],
              [-40.593572919926736, -8.990455142611237],
              [-40.593533144223947, -8.990433257698909],
              [-40.593493348521335, -8.990411362786606],
              [-40.593453573818529, -8.990389476874359],
              [-40.593413778115867, -8.990367581962055],
              [-40.593374003413061, -8.990345697049785],
              [-40.593334201710441, -8.990323798137547],
              [-40.59329443200761, -8.99030191622518],
              [-40.593254647304875, -8.990280026312925],
              [-40.593214851602205, -8.990258130400644],
              [-40.593175076899399, -8.990236245488376],
              [-40.593135281196794, -8.990214349576096],
              [-40.593095504493974, -8.990192464663826],
              [-40.593055709791351, -8.990170569751536],
              [-40.593015934088555, -8.990148683839225],
              [-40.592976139385883, -8.990126788926986],
              [-40.59293636368308, -8.990104903014677],
              [-40.592896568980414, -8.990083007102404],
              [-40.592856792277637, -8.990061122190133],
              [-40.592816996574982, -8.990039226277855],
              [-40.592777215872232, -8.990017338365575],
              [-40.59273742716956, -8.989995446453296],
              [-40.592697652466747, -8.989973560540989],
              [-40.592657856764085, -8.989951665628755],
              [-40.592618078061292, -8.989929778716462],
              [-40.592610812115581, -8.989925780732472],
              [-40.592604722160928, -8.989922591745003],
              [-40.592596758219827, -8.989918931758492],
              [-40.592591967254876, -8.989917082764618],
              [-40.592587068290456, -8.989915533768968],
              [-40.592582086326324, -8.989914293771479],
              [-40.592574508380416, -8.989912899772568],
              [-40.592565136446893, -8.989911701770914],
              [-40.592517126786191, -8.989906712756033],
              [-40.592471900105799, -8.989902013742011],
              [-40.592426675425408, -8.989897315727974],
              [-40.592381450745087, -8.989892616713904],
              [-40.592336226064702, -8.989887917699882],
              [-40.592291001384318, -8.989883218685861],
              [-40.59224577670394, -8.989878519671844],
              [-40.592200549023609, -8.989873820657765],
              [-40.592155324343231, -8.989869121643746],
              [-40.592110099662854, -8.989864422629729],
              [-40.592064874982462, -8.989859723615711],
              [-40.592019649302095, -8.989855024601692],
              [-40.591974410621852, -8.989850323587634],
              [-40.591929198941358, -8.989845625573613],
              [-40.591883975260963, -8.989840926559598],
              [-40.591838748580621, -8.989836227545521],
              [-40.591793522900254, -8.989831528531498],
              [-40.591748297219894, -8.989826829517481],
              [-40.591703072539509, -8.989822130503464],
              [-40.591657847859132, -8.989817431489445],
              [-40.591612622178772, -8.989812732475372],
              [-40.591567398498434, -8.989808033461353],
              [-40.591522215817768, -8.989803338447366],
              [-40.591476944137703, -8.989798638433266],
              [-40.591431718457343, -8.989793939419247],
              [-40.591386493776959, -8.98978924040523],
              [-40.591341268096606, -8.989784541391211],
              [-40.591296042416246, -8.989779842377137],
              [-40.591250817735869, -8.989775142363134],
              [-40.59120559505552, -8.989770443349121],
              [-40.591160367375132, -8.989765744335047],
              [-40.591115141694772, -8.989761044321044],
              [-40.591069916014412, -8.989756345307025],
              [-40.591024691334034, -8.98975164629301],
              [-40.590979466653657, -8.989746947278938],
              [-40.590934241973343, -8.989742248264923],
              [-40.590889017292959, -8.989737549250902],
              [-40.590843789612585, -8.989732850236885],
              [-40.590798564932207, -8.989728151222868],
              [-40.590753340251887, -8.989723451208814],
              [-40.590708115571509, -8.989718752194795],
              [-40.590662889891156, -8.989714053180778],
              [-40.590617664210804, -8.989709354166706],
              [-40.590572439530426, -8.989704655152689],
              [-40.59052721685002, -8.989699956138674],
              [-40.59048197516978, -8.989695255124621],
              [-40.590436763489343, -8.98969055611062],
              [-40.590391538808973, -8.989685857096603],
              [-40.59034631312862, -8.989681158082586],
              [-40.590301088448236, -8.989676459068573],
              [-40.590255863767865, -8.989671760054499],
              [-40.590210639087495, -8.9896670600405],
              [-40.590165412407146, -8.989662361026484],
              [-40.5901201867268, -8.98965766201241],
              [-40.59007496204643, -8.989652962998397],
              [-40.590029737366052, -8.989648263984384],
              [-40.589984511685707, -8.989643563970379],
              [-40.589939287005393, -8.989638864956314],
              [-40.589894063324998, -8.989634165942297],
              [-40.589848835644617, -8.989629466928285],
              [-40.589803610964303, -8.989624766914227],
              [-40.58975838528395, -8.98962006790021],
              [-40.58971316060358, -8.9896153688862],
              [-40.589667934923227, -8.989610668872196],
              [-40.589622710242864, -8.989605968858143],
              [-40.589577485562494, -8.98960126984413],
              [-40.589532260882123, -8.9895965718301],
              [-40.589487034201781, -8.989591871816101],
              [-40.589441808521435, -8.989587171802045],
              [-40.589396583841072, -8.98958247278803],
              [-40.589351359160695, -8.98957777377402],
              [-40.589306133480349, -8.989573073759962],
              [-40.589260908799979, -8.98956837474595],
              [-40.589215685119591, -8.989563675731937],
              [-40.58917045743928, -8.989558975717884],
              [-40.58912523275891, -8.989554276703869],
              [-40.58908000807854, -8.989549577689857],
              [-40.589034782398194, -8.989544877675861],
              [-40.58898954371795, -8.989540176661807],
              [-40.588944332037471, -8.98953547864779],
              [-40.588899107357165, -8.989530778633796],
              [-40.588853882676794, -8.989526079619726],
              [-40.588808655996402, -8.989521380605712],
              [-40.588763430316057, -8.989516680591715],
              [-40.588718205635743, -8.989511981577641],
              [-40.58867298095538, -8.98950728156365],
              [-40.588627764274932, -8.98950258354963],
              [-40.588603929443416, -8.989500106542227],
              [-40.588592396524341, -8.989499535535129],
              [-40.588587400559149, -8.989499599530303],
              [-40.588582418593546, -8.989499972523715],
              [-40.588575850638527, -8.98950087751277],
              [-40.588564418716203, -8.989503127489813],
              [-40.588522130002481, -8.989512615398446],
              [-40.588477764302866, -8.989522570302654],
              [-40.588433417603142, -8.989532519206804],
              [-40.588389061903449, -8.989542472111003],
              [-40.588344695203851, -8.989552427015148],
              [-40.588300350504028, -8.989562375919359],
              [-40.588255981804465, -8.989572331823492],
              [-40.588211640104653, -8.989582280727701],
              [-40.588167269405062, -8.989592235631854],
              [-40.588122925705285, -8.989602184536064],
              [-40.588078572005564, -8.989612137440254],
              [-40.588034201305966, -8.989622093344385],
              [-40.587989856606214, -8.989632042248594],
              [-40.587945490906584, -8.989641996152763],
              [-40.587901145206843, -8.989651946056954],
              [-40.587856791507143, -8.989661897961165],
              [-40.587812420807545, -8.989671853865296],
              [-40.587768076107785, -8.989681802769507],
              [-40.58772371040817, -8.989691756673675],
              [-40.587679364708428, -8.989701706577867],
              [-40.587634996008802, -8.989711661482016],
              [-40.587590655309029, -8.989721609386242],
              [-40.587546296609347, -8.989731563290416],
              [-40.587501926909731, -8.989741518194561],
              [-40.587457586209972, -8.989751466098792],
              [-40.587413215510374, -8.989761422002923],
              [-40.587368870810622, -8.98977137090713],
              [-40.58732451811089, -8.989781321811304],
              [-40.587280146411324, -8.989791278715471],
              [-40.587235802711547, -8.989801226619637],
              [-40.587191437011931, -8.989811182523832],
              [-40.587147091312126, -8.989821131428037],
              [-40.587102735612469, -8.989831083332186],
              [-40.587058367912874, -8.989841038236335],
              [-40.587014023213122, -8.989850987140546],
              [-40.586969656513524, -8.989860942044695],
              [-40.586925310813726, -8.989870890948904],
              [-40.586880941114131, -8.989880845853047],
              [-40.586836601414348, -8.989890794757262],
              [-40.586792242714665, -8.989900746661467],
              [-40.586747873015113, -8.989910703565581],
              [-40.586703533315273, -8.989920650469823],
              [-40.586659160615724, -8.989930607373937],
              [-40.586614817915937, -8.98994055427818],
              [-40.58657046421628, -8.989950507182371],
              [-40.586526092516657, -8.989960463086502],
              [-40.586481748816894, -8.989970410990729],
              [-40.586437382117296, -8.989980365894878],
              [-40.586393036417554, -8.989990315799067],
              [-40.58634866671796, -8.990000270703213],
              [-40.586304327018183, -8.99001021860744],
              [-40.586259967318512, -8.990020171511572],
              [-40.58621559761891, -8.990030126415718],
              [-40.58617125791914, -8.990040074319948],
              [-40.58612688621956, -8.99005003022407],
              [-40.58608254351978, -8.990059978128301],
              [-40.586038189820073, -8.990069930032512],
              [-40.585993813120545, -8.990079886936615],
              [-40.585967785296781, -8.990085725880375],
              [-40.585922249605808, -8.990095137786593],
              [-40.585841031160683, -8.990107894641927],
              [-40.585754182758961, -8.990116052518168],
              [-40.585666982364614, -8.990118802424524],
              [-40.585579771975191, -8.990116131361463],
              [-40.585492904588349, -8.990108048329256],
              [-40.585406725201587, -8.990094590327903],
              [-40.585321539812711, -8.990075803357504],
              [-40.585237704419143, -8.990051762417895],
              [-40.585155546018576, -8.990022564508882],
              [-40.585075382608679, -8.989988320630081],
              [-40.58499752018718, -8.989949164781015],
              [-40.584922266751775, -8.989905245961149],
              [-40.584855062261305, -8.989860189154941],
              [-40.584823329503855, -8.98983678725841],
              [-40.58479914968909, -8.989818440340128],
              [-40.584762995966109, -8.989791009462378],
              [-40.584726846243036, -8.989763581584581],
              [-40.584690707519961, -8.989736160706796],
              [-40.584654550796962, -8.989708726829036],
              [-40.584618413073848, -8.989681306951182],
              [-40.58458225635087, -8.989653873073422],
              [-40.584546116627735, -8.989626452195635],
              [-40.584509959904743, -8.989599019317861],
              [-40.584473822181636, -8.989571598440079],
              [-40.584437667458666, -8.989544166562291],
              [-40.584401518735589, -8.989516738684497],
              [-40.584365379012503, -8.989489317806717],
              [-40.584329223289515, -8.989461883928959],
              [-40.584293084566418, -8.989434464051106],
              [-40.58425692784342, -8.989407030173348],
              [-40.584220789120266, -8.989379609295568],
              [-40.584184641397236, -8.989352182417766],
              [-40.584148484674245, -8.989324749540046],
              [-40.584112339951204, -8.989297323662228],
              [-40.584076191228178, -8.989269894784458],
              [-40.584040051505056, -8.989242474906659],
              [-40.584003894782057, -8.989215041028908],
              [-40.583967757058957, -8.989187620151069],
              [-40.583931600335966, -8.989160187273358],
              [-40.583895461612876, -8.98913276539554],
              [-40.583859313889782, -8.989105339517723],
              [-40.583823158166823, -8.98907790563997],
              [-40.58378701244375, -8.989050479762211],
              [-40.583750862720748, -8.989023051884427],
              [-40.583714724997584, -8.988995631006651],
              [-40.583678568274642, -8.988968197128898],
              [-40.583642429551503, -8.988940776251066],
              [-40.583606273828501, -8.9889133433733],
              [-40.583570133105432, -8.988885921495539],
              [-40.583547136281602, -8.988868472573255],
              [-40.583537684353466, -8.988861936601632],
              [-40.583534597376648, -8.988860075609367],
              [-40.583531398400481, -8.98885840861591],
              [-40.583528101424818, -8.988856944621229],
              [-40.583524722449496, -8.988855688625263],
              [-40.583521267474531, -8.988854643628084],
              [-40.583517764499746, -8.988853818629567],
              [-40.583514210525045, -8.988853210629818],
              [-40.583510626550435, -8.98885282562876],
              [-40.583507036575604, -8.988852664626448],
              [-40.583503437600626, -8.988852726622902],
              [-40.583499848625387, -8.98885301161808],
              [-40.583496290649798, -8.988853518612016],
              [-40.583492768673658, -8.988854245604808],
              [-40.583489302696954, -8.98885518959635],
              [-40.583485911719613, -8.988856343586825],
              [-40.583480325756447, -8.988858641568838],
              [-40.583472300808936, -8.988862474540097],
              [-40.583470932817889, -8.988863168534953],
              [-40.583430419081758, -8.988883739382716],
              [-40.583389927345543, -8.988904298230578],
              [-40.583349431609356, -8.988924859078406],
              [-40.583308934873159, -8.988945420926212],
              [-40.583268450136899, -8.988965975774088],
              [-40.583227943400765, -8.988986544621888],
              [-40.583187445664542, -8.989007105469714],
              [-40.583146949928349, -8.989027666317593],
              [-40.583106464192092, -8.989048222165454],
              [-40.583065957455965, -8.989068790013269],
              [-40.583025454719831, -8.989089353861038],
              [-40.582984962983566, -8.989109912708901],
              [-40.582944479247274, -8.989130467556777],
              [-40.582903971511172, -8.989151035404593],
              [-40.582863474774982, -8.989171597252398],
              [-40.582822979038781, -8.989192159100266],
              [-40.582782482302605, -8.989212719948089],
              [-40.58274199756633, -8.989233274795966],
              [-40.582701489830164, -8.989253843643764],
              [-40.582660993093988, -8.989274404491587],
              [-40.582620491357837, -8.989294968339413],
              [-40.582580010621548, -8.989315521187324],
              [-40.582539502885389, -8.98933608903514],
              [-40.58249900614922, -8.989356649882962],
              [-40.582458510413019, -8.989377210730789],
              [-40.582418013676836, -8.989397771578609],
              [-40.582377528940562, -8.989418327426524],
              [-40.582337021204459, -8.989438895274283],
              [-40.582296524468219, -8.989459457122145],
              [-40.582256028732033, -8.989480017969967],
              [-40.582215536995818, -8.989500576817827],
              [-40.582175035259667, -8.989521141665639],
              [-40.582134538523441, -8.989541702513462],
              [-40.582094041787258, -8.989562263361286],
              [-40.58205355705099, -8.989582819209199],
              [-40.582013049314888, -8.989603387056956],
              [-40.581972552578712, -8.989623947904777],
              [-40.581932056842511, -8.989644508752658],
              [-40.581891560106278, -8.989665069600481],
              [-40.581851068370064, -8.989685628448337],
              [-40.58181054863406, -8.9897062022961],
              [-40.581788850775389, -8.989717218214548],
              [-40.581743021074892, -8.989739403048432],
              [-40.581666532579028, -8.989771797797337],
              [-40.581584139127393, -8.989800794560114],
              [-40.581500076692123, -8.989824621350532],
              [-40.581414681270857, -8.989843180169451],
              [-40.581328290861428, -8.989856400017535],
              [-40.581241235461462, -8.989864229895447],
              [-40.58115385406871, -8.989866638803603],
              [-40.581066488680769, -8.989863616742404],
              [-40.580979485295266, -8.989855174712046],
              [-40.580893177909751, -8.989841346712726],
              [-40.58080790752183, -8.989822184744369],
              [-40.58072400512912, -8.989797766806852],
              [-40.58064180472924, -8.989768184899956],
              [-40.58056161931988, -8.989733558023339],
              [-40.580483755898719, -8.989694012176532],
              [-40.580414947415385, -8.989653487343306],
              [-40.580387975619267, -8.989636066417354],
              [-40.580359380835816, -8.989617184498172],
              [-40.580321494122707, -8.98959216860526],
              [-40.580283615409563, -8.989567156712342],
              [-40.580245737696409, -8.989542144819429],
              [-40.580207867983219, -8.989517139926463],
              [-40.580169980270128, -8.989492123033505],
              [-40.580132101556984, -8.989467110140607],
              [-40.580094222843783, -8.989442098247633],
              [-40.580056345130629, -8.989417086354718],
              [-40.580018466417485, -8.989392074461808],
              [-40.579980587704348, -8.989367063568878],
              [-40.57994270999118, -8.989342051675907],
              [-40.579904830278068, -8.989317039782994],
              [-40.579866952564913, -8.98929202789008],
              [-40.579829073851762, -8.989267014997131],
              [-40.579791205138498, -8.989242011104155],
              [-40.579753317425414, -8.989216992211293],
              [-40.579715438712277, -8.989191981318308],
              [-40.579677565999084, -8.989166973425393],
              [-40.579639680286007, -8.989141957532485],
              [-40.579601801572871, -8.989116945639518],
              [-40.579563923859709, -8.989091933746607],
              [-40.579526045146508, -8.989066921853697],
              [-40.579488167433354, -8.989041908960747],
              [-40.579450288720217, -8.989016897067836],
              [-40.57941241000708, -8.988991886174908],
              [-40.579374531293936, -8.988966874281941],
              [-40.579336663580719, -8.988941868389004],
              [-40.579298774867638, -8.988916851496114],
              [-40.579260897154477, -8.98889183960315],
              [-40.579223018441347, -8.98886682771024],
              [-40.579185139728146, -8.98884181481735],
              [-40.579147261015009, -8.988816802924386],
              [-40.579109382301866, -8.988791791031479],
              [-40.579071504588704, -8.988766779138576],
              [-40.579033625875567, -8.988741767245614],
              [-40.578995754162364, -8.988716760352688],
              [-40.578957869449276, -8.9886917434598],
              [-40.57891999973603, -8.988666738566788],
              [-40.578882112022946, -8.988641720673915],
              [-40.578844233309795, -8.98861670778097],
              [-40.57880635559664, -8.988591695888067],
              [-40.578768476883504, -8.988566683995161],
              [-40.578730599170349, -8.988541672102205],
              [-40.578692719457223, -8.988516661209283],
              [-40.578654841744076, -8.988491648316398],
              [-40.578616963030868, -8.988466636423434],
              [-40.578579085317713, -8.988441624530534],
              [-40.578541206604577, -8.988416612637629],
              [-40.578503328891422, -8.988391600744674],
              [-40.578465460178215, -8.988366594851739],
              [-40.578427571465141, -8.98834157695887],
              [-40.578389693751987, -8.988316565065912],
              [-40.57835181503885, -8.988291553173008],
              [-40.57831615030895, -8.98826800327385],
              [-40.578306936378368, -8.988262263297953],
              [-40.578302327412814, -8.988259750307966],
              [-40.578299025437275, -8.988258208313695],
              [-40.578295633462083, -8.988256873318205],
              [-40.578292167487312, -8.988255754321418],
              [-40.578288634512731, -8.98825485032336],
              [-40.578285055538309, -8.988254168323996],
              [-40.578281445563938, -8.988253712323326],
              [-40.578277815589431, -8.988253480321394],
              [-40.578274180614805, -8.988253473318133],
              [-40.578270545639974, -8.988253693313647],
              [-40.578266934664747, -8.988254138307916],
              [-40.578260555708134, -8.9882553302955],
              [-40.578252856760159, -8.988257204278062],
              [-40.57824923778454, -8.988258173269342],
              [-40.578205319080197, -8.988269941163658],
              [-40.578161424375658, -8.988281702058053],
              [-40.57811750767133, -8.98829346895239],
              [-40.578073602966917, -8.9883052318468],
              [-40.578029711262396, -8.988316991741208],
              [-40.577985792558046, -8.988328758635543],
              [-40.57794189785352, -8.988340520529924],
              [-40.577897982149182, -8.988352286424274],
              [-40.57785407644478, -8.988364049318681],
              [-40.577810184740251, -8.988375809213096],
              [-40.577766262035922, -8.988387578107389],
              [-40.577722360331457, -8.988399340001765],
              [-40.577678467626946, -8.988411099896174],
              [-40.577634549922642, -8.988422867790549],
              [-40.577590658218114, -8.988434626684922],
              [-40.577546735513792, -8.988446395579276],
              [-40.577502833809326, -8.98845815747365],
              [-40.577458941104823, -8.988469917368057],
              [-40.577415023400512, -8.988481685262375],
              [-40.57737113169599, -8.988493444156804],
              [-40.577327208991655, -8.988505213051155],
              [-40.577283307287253, -8.988516974945526],
              [-40.577239414582692, -8.988528733839955],
              [-40.577195496878396, -8.988540501734272],
              [-40.577151601173881, -8.988552262628664],
              [-40.577107685469542, -8.988564028523019],
              [-40.577063780765137, -8.988575792417411],
              [-40.577019888060626, -8.988587551311777],
              [-40.576975970356266, -8.988599319206154],
              [-40.576932074651758, -8.988611080100547],
              [-40.576888158947426, -8.988622845994897],
              [-40.576844253242975, -8.988634608889246],
              [-40.57680036153851, -8.988646368783659],
              [-40.576756443834157, -8.988658135677989],
              [-40.576712535129758, -8.988669900572361],
              [-40.576668645425194, -8.98868165946679],
              [-40.576624726720866, -8.988693426361124],
              [-40.576580834016369, -8.988705186255538],
              [-40.576536916312058, -8.988716953149869],
              [-40.576493007607667, -8.988728717044255],
              [-40.576449121903096, -8.988740474938647],
              [-40.576431548021382, -8.988745183896361],
              [-40.57636130749426, -8.98876400272737],
              [-40.576334172676944, -8.988771273662101],
              [-40.576229591381001, -8.988799292410519],
              [-40.576143015963851, -8.988822489202251],
              [-40.575236966059805, -8.989069515998368],
              [-40.569824826862032, -8.991221071022562],
              [-40.56981387993649, -8.991223146001056],
              [-40.56916588022245, -8.991481745962833],
              [-40.569166995213884, -8.991482579959175],
              [-40.566188587918028, -8.992666574616747],
              [-40.564076164893109, -8.993506919987089],
              [-40.563189353759043, -8.9938608781972],
              [-40.562763877626658, -8.993971955189904],
              [-40.562234306354995, -8.993934594926204],
              [-40.559643701213481, -8.993068308490411],
              [-40.559047663542543, -8.992879960018664],
              [-40.558978884033188, -8.992868053024166],
              [-40.558688779904912, -8.99193228904079],
              [-40.556394507295693, -8.991508445376574],
              [-40.556194066691425, -8.991511470180074],
              [-40.554244127312685, -8.991493437535993],
              [-40.551859087947506, -8.988187548044671],
              [-40.551618496523254, -8.987196703417625],
              [-40.550200068482376, -8.984918428998439],
              [-40.549201660489167, -8.984873860356764],
              [-40.548230599299409, -8.984835683703457],
              [-40.548884669989967, -8.990078012720835],
              [-40.549233928041694, -8.992850794395952],
              [-40.54924400189644, -8.99293359393806],
              [-40.550982379972282, -8.992706555774785],
              [-40.552861394083877, -8.992461141841016],
              [-40.553154720071674, -8.992423136318006],
              [-40.553475549861162, -8.99239219977969],
              [-40.553506762643977, -8.992391533811386],
              [-40.553667458516948, -8.992397959919053],
              [-40.553742246990993, -8.992402524960251],
              [-40.553819744301677, -8.993670386880211],
              [-40.547276841413897, -8.999785739536387],
              [-40.5493259938563, -9.003379655108867],
              [-40.54923891385733, -9.002945865476541],
              [-40.549215905443823, -9.002476154104253],
              [-40.549306173258223, -9.001986225947469],
              [-40.549436476696258, -9.001603212223772],
              [-40.549614777716769, -9.001311309029347],
              [-40.549695173265796, -9.001190011785349],
              [-40.549808633607149, -9.001043219714685],
              [-40.550054902124124, -9.000783648398862],
              [-40.550293273621961, -9.000605860614924],
              [-40.550440329680868, -9.000512129275148],
              [-40.550787313420898, -9.000334289588805],
              [-40.551020785853559, -9.000266185182033],
              [-40.551245346340998, -9.000206215721327],
              [-40.551542528319025, -9.000149268308753],
              [-40.551955812482632, -9.000097054973478],
              [-40.552254248455206, -9.00003642158274],
              [-40.552400439037818, -8.999371658461831],
              [-40.553124524055917, -8.995984210210462],
              [-40.554492716491303, -8.996004356322359],
              [-40.555760207630854, -8.99602301635244],
              [-40.562477999787966, -8.995994594530512],
              [-40.562492901535514, -8.999467517965243],
              [-40.560848575004933, -8.999470160476729],
              [-40.560801796407816, -9.002693715262939],
              [-40.558919392566629, -9.00266554073421],
              [-40.558965327115665, -8.999501372613077],
              [-40.556728275752647, -8.999469265789427],
              [-40.556682340204922, -9.002632200916825],
              [-40.554607382695458, -9.002617872137673],
              [-40.554539340979936, -9.007236869037845],
              [-40.557355252343179, -9.00722496363041],
              [-40.55734801392795, -9.005534170154656],
              [-40.564078098999218, -9.00550563335084],
              [-40.564079809626257, -9.005903466110073],
              [-40.56578208575705, -9.005896228677596],
              [-40.567489150517147, -9.007362780943444],
              [-40.567503570379358, -9.010708196103295],
              [-40.576354974668163, -9.010670376260428],
              [-40.576410441507981, -9.015924489708169],
              [-40.578112750641431, -9.01591611528422],
              [-40.578140577297667, -9.022680319205683],
              [-40.579798149900377, -9.024697795332347],
              [-40.586348256221989, -9.024688836271888],
              [-40.586355329557051, -9.02976012472052],
              [-40.586372142492486, -9.041776468082125],
              [-40.589594057461561, -9.042389381534539],
              [-40.594048146624324, -9.043236638778883],
              [-40.599232984366502, -9.047729843165481],
              [-40.603240104715127, -9.051791383925929],
              [-40.602329145568511, -9.05233808902813],
              [-40.601875470478866, -9.052615579057571],
              [-40.601701162597081, -9.052722194300493],
              [-40.601079172537311, -9.053157274291351],
              [-40.600960812255984, -9.053274189526771],
              [-40.600931225435573, -9.05330342333561],
              [-40.600911502549422, -9.053329405171642],
              [-40.600906455578532, -9.053336054129675],
              [-40.600891778663211, -9.053355386007631],
              [-40.600872051774097, -9.053384613825401],
              [-40.600664031956654, -9.05367823398571],
              [-40.600604306292333, -9.053766724433929],
              [-40.600586047400832, -9.053787341301476],
              [-40.60056303953759, -9.053813319134555],
              [-40.600540023674377, -9.053839305967534],
              [-40.600520296785255, -9.053868533785305],
              [-40.600503861873158, -9.053897764605997],
              [-40.600480851006942, -9.053926988420814],
              [-40.600443374207437, -9.053993647011977],
              [-40.600336201849608, -9.054108870267005],
              [-40.600286887125286, -9.054183570802172],
              [-40.600267168236087, -9.054212798619956],
              [-40.600264950249262, -9.054215354603601],
              [-40.600222509491658, -9.054274017235254],
              [-40.600211278551704, -9.054293993112706],
              [-40.600191555665532, -9.054319973948752],
              [-40.60014765491232, -9.054385015543152],
              [-40.600132378001106, -9.054404410420284],
              [-40.600084852283523, -9.054458091075375],
              [-40.599977855945234, -9.05455057545846],
              [-40.599973819968696, -9.054555701425999],
              [-40.599808954999482, -9.054685971544281],
              [-40.599780578184593, -9.054699992439758],
              [-40.599750988376051, -9.054716234321688],
              [-40.599721398567581, -9.05473247620362],
              [-40.599691808759104, -9.054748717085513],
              [-40.59966220994481, -9.054771450930922],
              [-40.599636806104101, -9.054790962798213],
              [-40.599216974391169, -9.055492615471632],
              [-40.599069855133521, -9.055803152591706],
              [-40.599019697389764, -9.055905560970288],
              [-40.598999630505581, -9.055931995803448],
              [-40.598919357920494, -9.056090589838732],
              [-40.598899317042203, -9.056110392709215],
              [-40.598875908178307, -9.056140124520843],
              [-40.598855840294092, -9.056166550354028],
              [-40.598691294243707, -9.056383236986427],
              [-40.598489285510674, -9.05653850893048],
              [-40.598319248543405, -9.056706158833816],
              [-40.598305925622547, -9.056721207737136],
              [-40.598288783730091, -9.056734374647547],
              [-40.59827545281275, -9.056745662572007],
              [-40.598260220906944, -9.05675883048418],
              [-40.598243087012669, -9.056773878384078],
              [-40.598229756093623, -9.056787047297956],
              [-40.598214523186101, -9.05680209519951],
              [-40.598201191265353, -9.056817146102787],
              [-40.598185957359576, -9.056830314014961],
              [-40.598168815467055, -9.05684348092543],
              [-40.598149772589601, -9.056854766844769],
              [-40.598065039146512, -9.05689196755899],
              [-40.597993006631171, -9.056911369384782],
              [-40.597876642416452, -9.056940101118141],
              [-40.5973161962321, -9.057041852039813],
              [-40.597022036230442, -9.057100069446763],
              [-40.596879413199403, -9.057128245159516],
              [-40.596850375397864, -9.057132643108554],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2192',
        NOME_UC1: 'RESERVA PARTICULAR DO PATRIMÔNIO NATURAL RESERVA UMBURANA',
        ID_WCMC2: null,
        CATEGORI3: 'Reserva Particular do Patrimônio Natural',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '2007',
        GID7: '9972',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Portaria nº 34 de 12/04/2007',
        DT_ULTIM10: '26/08/2011',
        CODIGO_U11: '0000.00.2192',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.125867958391872, -8.575683420786431],
              [-39.123742633656157, -8.580353892802698],
              [-39.12296022403423, -8.582070242788362],
              [-39.121016459645062, -8.586352400602971],
              [-39.118642460559556, -8.591564174884043],
              [-39.118297971119013, -8.592486251425317],
              [-39.117935853952432, -8.593457004103085],
              [-39.116712890225934, -8.596735985311675],
              [-39.11650675307839, -8.597285905706276],
              [-39.116192018649883, -8.598133781396259],
              [-39.116034625414514, -8.598558047248281],
              [-39.115942903300926, -8.598795258758415],
              [-39.115616841193415, -8.599638980944322],
              [-39.115455053178088, -8.6000616062978],
              [-39.114860049632121, -8.601615558908225],
              [-39.114121099174675, -8.603535692906895],
              [-39.113899002833399, -8.604120423261232],
              [-39.122893847415888, -8.599534784175702],
              [-39.124201739056481, -8.588889780092964],
              [-39.125868013843032, -8.575683259309136],
              [-39.125867958391872, -8.575683420786431],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2179',
        NOME_UC1: 'RESERVA PARTICULAR DO PATRIMÔNIO NATURAL RESERVA JUREMA',
        ID_WCMC2: null,
        CATEGORI3: 'Reserva Particular do Patrimônio Natural',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '2007',
        GID7: '9966',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Portaria nº 33 de 12/04/2007',
        DT_ULTIM10: '26/08/2011',
        CODIGO_U11: '0000.00.2179',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-39.129544772023237, -8.571995997331149],
              [-39.141304882292189, -8.574669789228262],
              [-39.138291330715234, -8.58183773472077],
              [-39.136999292559238, -8.58740891327094],
              [-39.137350840304009, -8.592238075487208],
              [-39.139295532265066, -8.591225849050453],
              [-39.139302768395851, -8.589640598525191],
              [-39.140017985222961, -8.589126457221798],
              [-39.142060865493669, -8.589762751110873],
              [-39.148883500650825, -8.586336617714545],
              [-39.14497984228165, -8.578300703697501],
              [-39.148991707965457, -8.576171702639785],
              [-39.139512990730651, -8.564178385369372],
              [-39.13656327331114, -8.566492096461154],
              [-39.135072277098118, -8.567661824145869],
              [-39.133669512597599, -8.568761497032327],
              [-39.129544704209309, -8.571996035237518],
              [-39.129544772023237, -8.571995997331149],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2161',
        NOME_UC1: 'RESERVA PARTICULAR DO PATRIMÔNIO NATURAL RESERVA CALAÇA',
        ID_WCMC2: null,
        CATEGORI3: 'Reserva Particular do Patrimônio Natural',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '2007',
        GID7: '10890',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Portaria nº 32 de 12/04/2007',
        DT_ULTIM10: '26/08/2011',
        CODIGO_U11: '0000.00.2161',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.217541043682502, -8.719735651940425],
              [-36.22201505767066, -8.72624134013995],
              [-36.226209604191467, -8.722180605242443],
              [-36.228514587138818, -8.720004997911531],
              [-36.228933897508, -8.714927115907074],
              [-36.227615294006682, -8.714736646735162],
              [-36.227760585865155, -8.712654058025805],
              [-36.215202024895461, -8.716461999651813],
              [-36.217541043682502, -8.719735651940425],
            ],
          ],
          [
            [
              [-36.218123531073161, -8.70758761221083],
              [-36.216678825633267, -8.708409497222249],
              [-36.215002393207328, -8.708066681350658],
              [-36.210490593171698, -8.706688194767253],
              [-36.210975307074932, -8.709078115109492],
              [-36.212565719416943, -8.709306360830681],
              [-36.212358439477043, -8.709916395689561],
              [-36.211843251928542, -8.711056155368567],
              [-36.211748284885402, -8.712550754663027],
              [-36.213270117656577, -8.714361950004275],
              [-36.222648515903757, -8.706903589593765],
              [-36.220255789772978, -8.702624669745262],
              [-36.218123531073161, -8.70758761221083],
            ],
          ],
          [
            [
              [-36.222159882958692, -8.696728803198198],
              [-36.223407643966993, -8.697356834633979],
              [-36.225005724330572, -8.696532139609133],
              [-36.224497984648565, -8.694633369799874],
              [-36.227643443649015, -8.692554419466887],
              [-36.2235453921881, -8.690800006452037],
              [-36.220929332495757, -8.696109586030616],
              [-36.222159882958692, -8.696728803198198],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3239',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DE MUSSAÍBA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12249',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '15/03/2016',
        CODIGO_U11: '0000.26.3239',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.995738372015289, -8.084694840667638],
              [-34.994981023877195, -8.084423470834697],
              [-34.994560556091038, -8.084329848124755],
              [-34.994452614890072, -8.084342333970064],
              [-34.994309614815094, -8.084546342588816],
              [-34.994131084937798, -8.084846200580992],
              [-34.993999848808897, -8.085002314508626],
              [-34.993724319802126, -8.085099334704944],
              [-34.993269085097268, -8.085257028393427],
              [-34.992874083907701, -8.085462265826227],
              [-34.992599258793483, -8.085702796122517],
              [-34.992444844718747, -8.08602645498245],
              [-34.992385959997399, -8.086253971513166],
              [-34.992326312392564, -8.086326020018868],
              [-34.992206430272475, -8.086350522780336],
              [-34.991954253184701, -8.086315874819347],
              [-34.991630018644628, -8.086269616880234],
              [-34.991438253045246, -8.086318389438544],
              [-34.991283250060157, -8.086522457048423],
              [-34.991092834254786, -8.086846290881743],
              [-34.990950536072361, -8.087193809600471],
              [-34.990892120279383, -8.087516999531225],
              [-34.990857234378531, -8.087744400079691],
              [-34.990725821276527, -8.087864635232268],
              [-34.990438171377562, -8.087937794569694],
              [-34.990078521017253, -8.088011303853975],
              [-34.98989834439088, -8.087976302946155],
              [-34.989752935683278, -8.087689983639816],
              [-34.989655585607629, -8.087415389293954],
              [-34.989570471406331, -8.087188573656965],
              [-34.989484477339367, -8.086782369145034],
              [-34.989481779751387, -8.08623224659509],
              [-34.989467903127782, -8.085849609986303],
              [-34.989454144486231, -8.085490892227527],
              [-34.989440736790982, -8.085203930018757],
              [-34.989391389356079, -8.08492910170841],
              [-34.98935468573287, -8.084785766581893],
              [-34.989294334235524, -8.084714302987683],
              [-34.98919839294485, -8.084726729841847],
              [-34.989078919762058, -8.084834947078248],
              [-34.988972502328345, -8.085158370973472],
              [-34.98877172636206, -8.085817120697712],
              [-34.988643007848147, -8.086487479400182],
              [-34.988585527911901, -8.087002017130789],
              [-34.98857645855383, -8.087600036371875],
              [-34.988518861635427, -8.088090656252595],
              [-34.988521089295027, -8.088545106402579],
              [-34.988523316954534, -8.088999556552645],
              [-34.988573428272844, -8.089429853888147],
              [-34.988659716294912, -8.089895855025228],
              [-34.988721593559227, -8.090278256669622],
              [-34.988964705288552, -8.090910925872027],
              [-34.989099346897575, -8.091448447594756],
              [-34.989209577748909, -8.091902370824725],
              [-34.989343985393816, -8.092392054847522],
              [-34.989454628182315, -8.092929693552625],
              [-34.989540684240069, -8.093347855989968],
              [-34.989650858100262, -8.093789820295127],
              [-34.989724502310501, -8.094124326248586],
              [-34.989821737403958, -8.094375001744728],
              [-34.989966269245791, -8.094481932176214],
              [-34.990230392252577, -8.094504563221291],
              [-34.990542457909214, -8.094515001376783],
              [-34.990914231161696, -8.094465349951509],
              [-34.991226120845241, -8.094439909331982],
              [-34.991645365819373, -8.094282390617071],
              [-34.99234026378231, -8.094051769555989],
              [-34.9930953962605, -8.093868690239285],
              [-34.994377655922897, -8.09350364343738],
              [-34.995648087648334, -8.093174529401695],
              [-34.997014459664726, -8.092832983511903],
              [-34.997661466969227, -8.092626507265351],
              [-34.997913116137731, -8.092553518901379],
              [-34.998045118150216, -8.092552872998912],
              [-34.998129178512805, -8.092564420986021],
              [-34.99818964999217, -8.092659800430495],
              [-34.998333946870027, -8.092718891161944],
              [-34.998825658234068, -8.092656686900339],
              [-34.99943754767353, -8.092629771502509],
              [-34.99967766886008, -8.09265251452986],
              [-34.999845847576339, -8.09268756842901],
              [-34.99993049784927, -8.092818707666302],
              [-34.999907972804564, -8.093117803774099],
              [-34.999958038131567, -8.093536137185298],
              [-35.000068458955802, -8.094025932190984],
              [-35.000250762259348, -8.094491456399899],
              [-35.000577069485558, -8.094956273715272],
              [-35.000902905783541, -8.095325418630521],
              [-35.001192505386669, -8.095646901819061],
              [-35.001397630690057, -8.095873124545374],
              [-35.001518932594927, -8.096135636984652],
              [-35.001567937083266, -8.096338705745602],
              [-35.001532941200018, -8.096542187444459],
              [-35.001198410489351, -8.096842813321746],
              [-35.000828586940322, -8.097287125272796],
              [-35.000362761109486, -8.097731908152831],
              [-35.000088111967941, -8.098008320224414],
              [-34.999969171704159, -8.098224172786177],
              [-35.000041749343268, -8.100771171862091],
              [-35.001246514647235, -8.101722013752454],
              [-35.003958070438976, -8.101601063433375],
              [-35.004126194164215, -8.101624156407778],
              [-35.004258553122831, -8.101695262055619],
              [-35.004475448372169, -8.101873586091052],
              [-35.004656223908846, -8.102028169249719],
              [-35.004825294490317, -8.102242607024788],
              [-35.007404665297386, -8.102086404833278],
              [-35.008055968680431, -8.100325173340471],
              [-35.011534807836888, -8.100056884489385],
              [-35.013072323847197, -8.097932498901958],
              [-35.014465120318214, -8.098081094957317],
              [-35.014970543057061, -8.095949842681367],
              [-35.013773023379571, -8.094042262796505],
              [-35.01296284232685, -8.092802489998263],
              [-35.012992607999855, -8.09154661389638],
              [-35.013661639422011, -8.090945349141471],
              [-35.013971976339562, -8.090608957471199],
              [-35.01406702576503, -8.090417139741634],
              [-35.013970610545925, -8.090333900195473],
              [-35.013730195403838, -8.090251370542884],
              [-35.013394017943973, -8.090217150519424],
              [-35.013105781134072, -8.090170733606485],
              [-35.0125894860385, -8.090113482599932],
              [-35.012133546440381, -8.090127688187966],
              [-35.011652835138378, -8.089986544732891],
              [-35.010955709514903, -8.089762750643077],
              [-35.010390406930988, -8.089502428875702],
              [-35.009728927092041, -8.08920670126237],
              [-35.009223862023489, -8.088993917239577],
              [-35.008790796416356, -8.088780779269939],
              [-35.008129792505741, -8.088580721057001],
              [-35.007240611327546, -8.088345904900535],
              [-35.006363549041723, -8.088134945603075],
              [-35.005558487217392, -8.087923630358878],
              [-35.004777248240472, -8.087676318357413],
              [-35.003947774658492, -8.08738140362046],
              [-35.00269771230807, -8.086968955323329],
              [-35.001459651867968, -8.086556444035265],
              [-35.00036594629691, -8.086214977403658],
              [-34.999608711140702, -8.085967536420345],
              [-34.99871912502573, -8.085648984789598],
              [-34.997745421557191, -8.08530692324692],
              [-34.996891837172996, -8.084988190642974],
              [-34.996182781630935, -8.084776385470336],
              [-34.995738372015289, -8.084694840667638],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2213',
        NOME_UC1: 'RESERVA PARTICULAR DO PATRIMÔNIO NATURAL SERRA DO CONTENTE',
        ID_WCMC2: null,
        CATEGORI3: 'Reserva Particular do Patrimônio Natural',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '2009',
        GID7: '9974',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Portaria nº 68 de 17/08/2009',
        DT_ULTIM10: '26/08/2011',
        CODIGO_U11: '0000.00.2213',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.553122984278296, -8.261144793185174],
              [-35.552109560783201, -8.261548876833244],
              [-35.551265352435472, -8.261463894564255],
              [-35.551403744754651, -8.261824481976435],
              [-35.551745772380606, -8.262816342664411],
              [-35.551902134216164, -8.263149704714507],
              [-35.552434972478942, -8.264021577426927],
              [-35.552621440318667, -8.263560804515359],
              [-35.552874506696739, -8.263414601265893],
              [-35.553583627377805, -8.262870383081491],
              [-35.553502581536556, -8.262811374059016],
              [-35.553482485295326, -8.262586262074283],
              [-35.553322697931357, -8.262646633727524],
              [-35.553325570265208, -8.262556313503447],
              [-35.553535862437819, -8.262509627076009],
              [-35.553569142120672, -8.262746199029419],
              [-35.55364512534512, -8.26280462473856],
              [-35.554011092988453, -8.262413303555258],
              [-35.55408721039089, -8.261554327763399],
              [-35.554229791906792, -8.261155801078212],
              [-35.554146580941037, -8.260912340565467],
              [-35.553917939808827, -8.260624624775582],
              [-35.55352497082567, -8.260175296358536],
              [-35.553405417662304, -8.259923030702709],
              [-35.55328307536179, -8.259237019222532],
              [-35.55335233273459, -8.258721483075872],
              [-35.553194404027863, -8.258144139356935],
              [-35.553169953924488, -8.258055461120689],
              [-35.552930061867407, -8.257947017494816],
              [-35.552209062391945, -8.260093325686936],
              [-35.552582958065038, -8.260398189652458],
              [-35.553184855631009, -8.26089136956069],
              [-35.553122984278296, -8.261144793185174],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2244',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DE SANTA CRUZ',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12128',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '01/02/2016',
        CODIGO_U11: '0000.26.2244',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.851738981414144, -7.708270332914189],
              [-34.851494945154165, -7.708400562924983],
              [-34.851301142537451, -7.708501870152804],
              [-34.851121695818051, -7.708595939436114],
              [-34.850906341357856, -7.708704515603371],
              [-34.850712475751372, -7.708791470922091],
              [-34.850468314511694, -7.708892995114663],
              [-34.850253084031252, -7.709030277100128],
              [-34.850016489676754, -7.709217885752329],
              [-34.84986616268607, -7.70939076855581],
              [-34.849730347567288, -7.709592294187557],
              [-34.849616239266595, -7.709822431652595],
              [-34.849573978442407, -7.710030728304352],
              [-34.849553362446507, -7.710253285880227],
              [-34.849576067101928, -7.710511537259144],
              [-34.849641967429022, -7.710776778622794],
              [-34.849743557532584, -7.710977277420682],
              [-34.849924408023178, -7.711206140092147],
              [-34.850069007828864, -7.711370571147462],
              [-34.850271004229072, -7.711498872470345],
              [-34.850379259365099, -7.711577345045952],
              [-34.850451574765401, -7.711663148550892],
              [-34.850495144376126, -7.711756252990288],
              [-34.85049548732021, -7.711835191490345],
              [-34.850452790567211, -7.711943021778392],
              [-34.850395830901569, -7.712079619874626],
              [-34.850389072884234, -7.712180118233301],
              [-34.850418348587446, -7.712294813526299],
              [-34.850533891654344, -7.712394782970537],
              [-34.850714181236448, -7.712494472460157],
              [-34.850851275146859, -7.712587171964988],
              [-34.850981300090815, -7.71270860828303],
              [-34.851060904421992, -7.712815908656728],
              [-34.851155083614884, -7.712966205767793],
              [-34.851213323072123, -7.713123833808248],
              [-34.851242973714257, -7.713324643555904],
              [-34.851222420708055, -7.713561553041065],
              [-34.851187448814706, -7.713791348561584],
              [-34.851138306993462, -7.714071438753971],
              [-34.851103584059409, -7.714358643910947],
              [-34.851104707876324, -7.714616989274902],
              [-34.851141802423946, -7.714875177664074],
              [-34.851178491037643, -7.715040075643989],
              [-34.851214961686907, -7.715154739942071],
              [-34.85131605487171, -7.715240418467246],
              [-34.851474576645877, -7.715297143214538],
              [-34.851625841484108, -7.715339546047606],
              [-34.851920803275497, -7.715338269254158],
              [-34.852208570120766, -7.71533702345563],
              [-34.852431714430487, -7.715364762430028],
              [-34.852554140494327, -7.715392937333903],
              [-34.852619168963777, -7.715457242970201],
              [-34.852648289692361, -7.71553605649048],
              [-34.852641438690263, -7.71561502698555],
              [-34.852577066114058, -7.715701421394939],
              [-34.852441126015485, -7.715874243208544],
              [-34.852161521958628, -7.716097921603368],
              [-34.851953545414027, -7.716249526502936],
              [-34.851767121712903, -7.716393860463111],
              [-34.851645350542221, -7.716516386604901],
              [-34.85156699600762, -7.716696135413438],
              [-34.851567744885521, -7.716868364322785],
              [-34.851604340514406, -7.717011733439162],
              [-34.851684351779411, -7.717212325222182],
              [-34.851807746685559, -7.717462963717341],
              [-34.851894794922124, -7.717627642732652],
              [-34.851953036379257, -7.71778527077324],
              [-34.852004301854635, -7.717993163490672],
              [-34.851997888781248, -7.718172600349627],
              [-34.851998888618482, -7.718402239895483],
              [-34.852028384285951, -7.718567168870476],
              [-34.852086374783774, -7.718667386274624],
              [-34.852173018086468, -7.71873877488071],
              [-34.852309896032388, -7.718781239703798],
              [-34.852396477345231, -7.718838275400793],
              [-34.852540518242449, -7.718873533274348],
              [-34.852648400439485, -7.718865889395389],
              [-34.852784809461753, -7.718800710900114],
              [-34.852949839293998, -7.718699526652181],
              [-34.853158033803062, -7.718598155434476],
              [-34.853359128350675, -7.718518343075413],
              [-34.853473892544777, -7.718438905655889],
              [-34.853581273823266, -7.718316442504],
              [-34.853652497401569, -7.718151077599489],
              [-34.853702262121196, -7.718014510498174],
              [-34.85381671336615, -7.717863310533],
              [-34.853931320585659, -7.7177479923407],
              [-34.854053058761501, -7.717618290244263],
              [-34.854232413496206, -7.717502690097346],
              [-34.854411799225836, -7.717394266904912],
              [-34.85454783230923, -7.717242973954868],
              [-34.854662063589807, -7.717041540307879],
              [-34.854768475025985, -7.716696613564568],
              [-34.854839353660317, -7.716452310159985],
              [-34.854910544243765, -7.716279768300939],
              [-34.855017893527283, -7.716150128194457],
              [-34.855168471477498, -7.716034654027351],
              [-34.855319298387023, -7.715976588496676],
              [-34.855527647870645, -7.715911097051785],
              [-34.855807594871592, -7.715766356157068],
              [-34.856058984052417, -7.715671971924135],
              [-34.856267332536071, -7.715606480479238],
              [-34.856454318145737, -7.715591316701119],
              [-34.856634297778768, -7.715619240645313],
              [-34.856836110209457, -7.715704480241079],
              [-34.857009239840366, -7.715811373680398],
              [-34.857211428210007, -7.715982728730923],
              [-34.857391971751476, -7.71613982385723],
              [-34.857550587510488, -7.716218074468239],
              [-34.85763701184878, -7.716239228392411],
              [-34.857716022276641, -7.716210179629567],
              [-34.857794750750259, -7.716116545275723],
              [-34.857873072290012, -7.715929620512553],
              [-34.85795126785014, -7.715713989931181],
              [-34.858007912566798, -7.715505630289266],
              [-34.858071814219343, -7.715311591561495],
              [-34.858135526902487, -7.715074495106355],
              [-34.858142219930009, -7.714959644838457],
              [-34.858091391383397, -7.714852220484786],
              [-34.85799746014974, -7.714759336010001],
              [-34.857831462475119, -7.714638058666628],
              [-34.857615168167349, -7.714531353197107],
              [-34.857341258300735, -7.714410544778162],
              [-34.857110482115978, -7.714282372434897],
              [-34.856879611946454, -7.714132671227988],
              [-34.856663130669254, -7.713982908031133],
              [-34.856511239932644, -7.713796982106893],
              [-34.856424159701092, -7.713625127137064],
              [-34.856365792264256, -7.713438795278296],
              [-34.85632162675018, -7.713209343702224],
              [-34.856248996400751, -7.713051779651608],
              [-34.856162198123386, -7.712944510272829],
              [-34.856032172179404, -7.712823076954718],
              [-34.855779627186742, -7.712651939869116],
              [-34.855534370124971, -7.712502300652195],
              [-34.855361023529646, -7.712345172531111],
              [-34.855223678659847, -7.712195064389767],
              [-34.855100659692518, -7.712030541349383],
              [-34.854977546740479, -7.711844489445362],
              [-34.854883178578014, -7.711651137606943],
              [-34.854817557205131, -7.711450483834091],
              [-34.854708019277503, -7.711077788121781],
              [-34.854642115950405, -7.710812548757962],
              [-34.85459066350537, -7.710561599313354],
              [-34.854546498991262, -7.710332146737436],
              [-34.854545687123419, -7.710145564919105],
              [-34.854559356132611, -7.709980448974473],
              [-34.854587382039135, -7.709808095085327],
              [-34.854658666607214, -7.709657083090073],
              [-34.854694262398851, -7.709570812660628],
              [-34.85468684948831, -7.709520609973728],
              [-34.854614565082883, -7.70944198342332],
              [-34.854498866041325, -7.709306134206241],
              [-34.854404749838146, -7.709170191004359],
              [-34.854331965514042, -7.708976744181126],
              [-34.854244824292536, -7.708790536302399],
              [-34.854078548663566, -7.708604671368267],
              [-34.85396995058337, -7.708447261292679],
              [-34.853896979289736, -7.7082107577422],
              [-34.853874491598681, -7.708002741045117],
              [-34.853873929690181, -7.707873568863209],
              [-34.853894730655625, -7.707694069014568],
              [-34.853944308405559, -7.707514446186046],
              [-34.853979498263264, -7.70733488434745],
              [-34.853979029339492, -7.707227241029235],
              [-34.853942715664722, -7.70714845850398],
              [-34.853849005395482, -7.707105805711285],
              [-34.853748321144508, -7.70711341759538],
              [-34.853576069371215, -7.707207456883765],
              [-34.8534471082544, -7.707330013020524],
              [-34.853318177132536, -7.707459746111812],
              [-34.853275387394753, -7.70754604753621],
              [-34.85327579332872, -7.707639337945342],
              [-34.853312293973026, -7.707761178197859],
              [-34.853385265266674, -7.707997682748344],
              [-34.853457986600944, -7.708176776662444],
              [-34.853530738930203, -7.708363047531028],
              [-34.853625167082569, -7.70857075327844],
              [-34.853683469529678, -7.708742733227917],
              [-34.853749089902564, -7.708943387000658],
              [-34.853814742270359, -7.709151217727937],
              [-34.853916490348524, -7.709387596298603],
              [-34.854004068498853, -7.709674271541094],
              [-34.853322349336594, -7.710071924563185],
              [-34.853069026470969, -7.709721379613987],
              [-34.853148004903865, -7.709685155896617],
              [-34.852901875984337, -7.709334578952491],
              [-34.852557091483582, -7.709458069938247],
              [-34.851738981414144, -7.708270332914189],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3312',
        NOME_UC1: 'ESTAÇÃO ECOLÓGICA SERRA DA CANOA',
        ID_WCMC2: null,
        CATEGORI3: 'Estação Ecológica',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2012',
        GID7: '12281',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº Decreto N° 38.133/12 de 27/04/2012',
        DT_ULTIM10: '14/03/2016',
        CODIGO_U11: '0000.26.3312',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-38.349945329750497, -8.445699806959196],
              [-38.349939213840017, -8.449352975315744],
              [-38.348827630908723, -8.452957274076695],
              [-38.347767342571508, -8.454135849245887],
              [-38.346564155131432, -8.455474274353652],
              [-38.342761936488394, -8.457892802978703],
              [-38.34147806071929, -8.45911891968659],
              [-38.340368266678112, -8.461606162065273],
              [-38.338713666681258, -8.463932386960348],
              [-38.338196348046196, -8.466834870356459],
              [-38.336647355404779, -8.472719458266315],
              [-38.334555066105438, -8.478060424960564],
              [-38.33253222866194, -8.48049813990067],
              [-38.331183024025322, -8.48213835010432],
              [-38.329704022530656, -8.484752446437774],
              [-38.32935020045155, -8.485517924621211],
              [-38.32954271702895, -8.485566192490419],
              [-38.329733877981361, -8.486395920732226],
              [-38.329537679706824, -8.488469535298711],
              [-38.32878204097095, -8.489904534195448],
              [-38.328106568763104, -8.491211233916333],
              [-38.328105986495331, -8.491545886934841],
              [-38.328538046208337, -8.491770035956019],
              [-38.329051460569602, -8.491707607739134],
              [-38.330173877697135, -8.491469853050539],
              [-38.33145641077693, -8.491105745238892],
              [-38.331647221990941, -8.491616194371099],
              [-38.331358039512125, -8.492301287083029],
              [-38.330346669834178, -8.493464508383051],
              [-38.330008505745568, -8.494102483334389],
              [-38.329591743429965, -8.494484356738292],
              [-38.329286317547272, -8.49457970492788],
              [-38.328774139332218, -8.494451293275226],
              [-38.328084997480339, -8.494210420145738],
              [-38.327380188636724, -8.494097954243568],
              [-38.327075531683818, -8.494272895962581],
              [-38.326930634517964, -8.49452770533761],
              [-38.326849990773354, -8.494926437912433],
              [-38.327025491161855, -8.4953572685037],
              [-38.327617553348112, -8.496044783911374],
              [-38.329954899575114, -8.497245425688854],
              [-38.331219630667626, -8.498252466748193],
              [-38.331314773438613, -8.498922842857461],
              [-38.331009150466748, -8.49912944138832],
              [-38.330192999618298, -8.498777103815618],
              [-38.328559403749026, -8.498295825347117],
              [-38.326940728526303, -8.498117564097441],
              [-38.326010981053997, -8.498306799227954],
              [-38.325625562305014, -8.498944687141744],
              [-38.325639474491332, -8.499822046960137],
              [-38.325671483936752, -8.500220973624884],
              [-38.325910832983077, -8.500508105118426],
              [-38.326071445737398, -8.500620536582527],
              [-38.326166701987951, -8.50070029518724],
              [-38.3261991026123, -8.500875818174579],
              [-38.326150699843161, -8.501019545284866],
              [-38.325974110956494, -8.501210987009452],
              [-38.325957423921132, -8.501401800866676],
              [-38.326245665537286, -8.501785794826528],
              [-38.326725607776176, -8.502169215944319],
              [-38.327109470809461, -8.502424940740489],
              [-38.327365257775845, -8.502665067525646],
              [-38.327685579291646, -8.502889024458582],
              [-38.327877687677478, -8.503176072914762],
              [-38.32802158838328, -8.503495599139917],
              [-38.328212676724142, -8.503846863215292],
              [-38.328404868147608, -8.50408687994994],
              [-38.328740526501612, -8.504374175520546],
              [-38.329268544355948, -8.504805614393362],
              [-38.329573061815942, -8.505237571082905],
              [-38.329748402127088, -8.505764274107554],
              [-38.329794455237881, -8.506449942087357],
              [-38.329506309244195, -8.506529039386818],
              [-38.32917020568582, -8.506496803306341],
              [-38.32888156689004, -8.506337119018399],
              [-38.328417388265507, -8.506288380932254],
              [-38.328176486932243, -8.506367558269371],
              [-38.328111147012343, -8.506845909385994],
              [-38.328399560705073, -8.507134028913764],
              [-38.328831749468975, -8.507293961316153],
              [-38.329280291115381, -8.50745392173158],
              [-38.329600672656376, -8.507646221852202],
              [-38.329984680753348, -8.507822351119898],
              [-38.33027343059986, -8.507918721782731],
              [-38.330561438529777, -8.507919217012226],
              [-38.330850520530021, -8.507823840809845],
              [-38.331138749562221, -8.507696806794131],
              [-38.331491789271453, -8.507394415868346],
              [-38.331684511938597, -8.507330529401884],
              [-38.331877122554182, -8.50733086055533],
              [-38.33216471429192, -8.507571039366324],
              [-38.332693293402201, -8.507683196129273],
              [-38.333253835360139, -8.507700437479613],
              [-38.333559437319835, -8.507509213857723],
              [-38.333992068288453, -8.507414080769777],
              [-38.334328062795478, -8.507510529470286],
              [-38.334503989374923, -8.507702578475969],
              [-38.334503494144904, -8.507989294779122],
              [-38.334454873273408, -8.508260552134915],
              [-38.334326299976468, -8.508531673427001],
              [-38.33421391448509, -8.508898696164737],
              [-38.33397265498558, -8.509185000276061],
              [-38.333699951880014, -8.509265031582379],
              [-38.333443770734569, -8.509248311474492],
              [-38.333059593560613, -8.509168059639231],
              [-38.332674370329379, -8.509167399332176],
              [-38.332354395551086, -8.509262723509901],
              [-38.33182523517246, -8.509485219766272],
              [-38.331296101806927, -8.50969143311907],
              [-38.330847225007467, -8.509722317573996],
              [-38.330189437735449, -8.509721185049704],
              [-38.32993333762775, -8.509657431220214],
              [-38.329837310448397, -8.509497175090926],
              [-38.329805954881486, -8.50924206057512],
              [-38.329694536838502, -8.509050120620879],
              [-38.32940589704284, -8.508890436332804],
              [-38.328780901574255, -8.508841421118401],
              [-38.328460240904867, -8.508809212050203],
              [-38.327868149291135, -8.508648097525668],
              [-38.327451544480098, -8.508408597606635],
              [-38.327067649434696, -8.508168249719089],
              [-38.326730607869877, -8.508152290541544],
              [-38.326394114133208, -8.508342551144127],
              [-38.326137234667328, -8.508725600670113],
              [-38.326056195743853, -8.509346830928701],
              [-38.326087157130871, -8.509826253116772],
              [-38.326198128970006, -8.510272348566687],
              [-38.326421562908642, -8.510831697437263],
              [-38.326485485176519, -8.511166461508072],
              [-38.326485010958905, -8.511437801902115],
              [-38.326564517179634, -8.511692999456331],
              [-38.326916309312722, -8.512108760280292],
              [-38.326963933723519, -8.512411839525482],
              [-38.326947272700295, -8.512587277473964],
              [-38.326851317129893, -8.512906389508338],
              [-38.326674667217638, -8.513129487045713],
              [-38.326577943718327, -8.513368099555807],
              [-38.326577495513, -8.513624063040925],
              [-38.326801324631361, -8.513959104239337],
              [-38.327296502655535, -8.514470986610654],
              [-38.327664682684734, -8.51486959054963],
              [-38.327920506663787, -8.515093436431901],
              [-38.328721269634386, -8.515430379085164],
              [-38.329586933321401, -8.515543123117336],
              [-38.329875140340505, -8.515432370005374],
              [-38.330228078999035, -8.515193293704883],
              [-38.330356185078195, -8.515193514807041],
              [-38.330661182755478, -8.51535413110317],
              [-38.331044118352722, -8.516152532689111],
              [-38.331379538590873, -8.516583637409687],
              [-38.331844308055551, -8.516823217367861],
              [-38.332660858069268, -8.516968431168497],
              [-38.33333219834644, -8.518070323192555],
              [-38.333780339800164, -8.518469963190615],
              [-38.334436819886299, -8.518710773296664],
              [-38.334677535130353, -8.518742841301499],
              [-38.335351170470858, -8.518520590160554],
              [-38.335735520720903, -8.518505871558245],
              [-38.336119818946294, -8.518521904773923],
              [-38.336424904662096, -8.518634580353995],
              [-38.337049230810564, -8.519082454209356],
              [-38.337368937461136, -8.519146313090042],
              [-38.337769671606814, -8.519115339597107],
              [-38.337770439965652, -8.518668532240618],
              [-38.337563035768369, -8.518285587338097],
              [-38.337227580517897, -8.517869863526096],
              [-38.33708358577406, -8.517598277016255],
              [-38.337052279232836, -8.517311506687236],
              [-38.337180936568544, -8.516992447678547],
              [-38.337341256611687, -8.51675303622425],
              [-38.337341723829581, -8.516481695829736],
              [-38.337134073516701, -8.516242561076526],
              [-38.336701513015676, -8.515763358561721],
              [-38.336285942262705, -8.515443371118906],
              [-38.336030195322053, -8.515171592520048],
              [-38.335886311628975, -8.514836692384957],
              [-38.335855393267707, -8.514325614383374],
              [-38.335983802487824, -8.514150366523825],
              [-38.33617569919933, -8.514039444335049],
              [-38.336384667697452, -8.514039800501719],
              [-38.336848831309801, -8.514103905497389],
              [-38.337328799547613, -8.514488219611623],
              [-38.337665216817918, -8.51487138461674],
              [-38.338352508215408, -8.515685674355099],
              [-38.338545040792603, -8.515733939225182],
              [-38.33883305472267, -8.515734429454961],
              [-38.339009643609145, -8.515543886724736],
              [-38.339219188376688, -8.51520868387686],
              [-38.339348280917129, -8.514634564377298],
              [-38.339557461946875, -8.513980986412436],
              [-38.339975173269963, -8.513582824105185],
              [-38.340231386428592, -8.513583259309572],
              [-38.340551778969719, -8.513775551430879],
              [-38.340711330654223, -8.513982945332968],
              [-38.340983488502928, -8.514223092132262],
              [-38.341127704350001, -8.514366243402003],
              [-38.341335382675318, -8.514589999246162],
              [-38.341511533357028, -8.514654515006853],
              [-38.34160751351093, -8.5148464259492],
              [-38.341623295124677, -8.51518110698227],
              [-38.341413808383152, -8.515483750022884],
              [-38.340980922298797, -8.515722701259657],
              [-38.340596381958434, -8.515849579198514],
              [-38.340323486764071, -8.516039959851867],
              [-38.340114796964464, -8.516406821513216],
              [-38.339969458592265, -8.516917600374475],
              [-38.339968939348942, -8.517220597581861],
              [-38.340256052856205, -8.517747487697322],
              [-38.340704066246182, -8.518226713224861],
              [-38.341408404845623, -8.518643060331511],
              [-38.341857238619994, -8.518643820689817],
              [-38.342194509287609, -8.518532237622368],
              [-38.342562833809517, -8.518325737141412],
              [-38.342580278204935, -8.517687208932205],
              [-38.342581448755652, -8.517001621988241],
              [-38.342630610883958, -8.51641108452089],
              [-38.342855977719417, -8.515853407001559],
              [-38.343064529455162, -8.515566137869254],
              [-38.343240898239266, -8.515503122384475],
              [-38.343433513855068, -8.515503447538217],
              [-38.343673129015784, -8.515647663878815],
              [-38.343720605350107, -8.516045711562789],
              [-38.343688179283681, -8.516412872364874],
              [-38.343735789681176, -8.516732231514462],
              [-38.344007896504387, -8.51700403212673],
              [-38.344295858409183, -8.517036175169332],
              [-38.34506560296817, -8.516926222441743],
              [-38.345449900193941, -8.51694224665753],
              [-38.345786652617583, -8.517134561792219],
              [-38.345978090112013, -8.51729407100353],
              [-38.346073720099433, -8.517693104720752],
              [-38.346024889125047, -8.518091894322703],
              [-38.346136738930966, -8.518570548582247],
              [-38.346889618364159, -8.518763563049284],
              [-38.347321935179245, -8.518860163827442],
              [-38.347610347859472, -8.51916364626581],
              [-38.347801489212877, -8.519498621439256],
              [-38.348009649325881, -8.519978341770525],
              [-38.348441535935962, -8.52033000204006],
              [-38.348825594046176, -8.520489833405398],
              [-38.349482304235515, -8.52060308826661],
              [-38.349771071082948, -8.520698540935658],
              [-38.350075119741597, -8.520890798044556],
              [-38.350395174116201, -8.521290205941451],
              [-38.350730325213313, -8.521896763625689],
              [-38.350841939903347, -8.522519227035096],
              [-38.35111266250172, -8.523078643946096],
              [-38.35194518276402, -8.52400531014006],
              [-38.352936869960807, -8.52483636602839],
              [-38.353816697908989, -8.525731450447887],
              [-38.354329246835228, -8.526210770028683],
              [-38.354968786744855, -8.526817829956997],
              [-38.355337272901281, -8.527058127834534],
              [-38.355689645291029, -8.527154587549369],
              [-38.356058588666556, -8.527122639036898],
              [-38.356363313646142, -8.526916019505071],
              [-38.356443937392605, -8.526517279927736],
              [-38.356396690175252, -8.525974518098479],
              [-38.356253825540101, -8.525560032433468],
              [-38.356013852655856, -8.525080263075882],
              [-38.355438502571332, -8.524122375273928],
              [-38.355055507974662, -8.523323993684709],
              [-38.354767648563623, -8.52268586122501],
              [-38.354528800775242, -8.522078562622751],
              [-38.354337064582609, -8.521551840582219],
              [-38.354097094697437, -8.521072975219722],
              [-38.353858060818759, -8.520577830954331],
              [-38.353714453266981, -8.520067468856105],
              [-38.353811297831591, -8.5197483508215],
              [-38.354019628464513, -8.519589509929215],
              [-38.354116231913572, -8.519414202043999],
              [-38.354196641557188, -8.519142993712785],
              [-38.354213745798823, -8.51869621236861],
              [-38.354150307775598, -8.518057549094037],
              [-38.354150574903564, -8.517898362035622],
              [-38.354263425184151, -8.517786394788976],
              [-38.354488028661052, -8.517675517626239],
              [-38.354824601438168, -8.51743639131244],
              [-38.35498485645558, -8.517229532665272],
              [-38.354985549788516, -8.516815285115529],
              [-38.354970613143728, -8.516511356900757],
              [-38.354682463591182, -8.51604869240396],
              [-38.354363131120351, -8.515760538849932],
              [-38.3540581474549, -8.515584564644136],
              [-38.353625776613328, -8.515520532673369],
              [-38.353401361225835, -8.515520159494232],
              [-38.353161527962506, -8.515503479399007],
              [-38.353000899207402, -8.515391961928726],
              [-38.352953066707485, -8.515200134024528],
              [-38.353049642143375, -8.515041107043082],
              [-38.353242740990403, -8.514753805898255],
              [-38.353306740725259, -8.514515131361534],
              [-38.353243677438734, -8.514195747199455],
              [-38.352955371809792, -8.513828051141131],
              [-38.352636470543715, -8.513284836096172],
              [-38.352444660312095, -8.512806049772921],
              [-38.352332803505888, -8.512327397513527],
              [-38.352285079056742, -8.512072255984007],
              [-38.352285454236174, -8.511848852305652],
              [-38.352350041811128, -8.511801022640689],
              [-38.352478256942192, -8.511737018122806],
              [-38.352639070786672, -8.511737286251135],
              [-38.35287868794768, -8.511881496591787],
              [-38.354127176157498, -8.512888442644932],
              [-38.355568017297138, -8.513513112114744],
              [-38.3558405573256, -8.513529844236018],
              [-38.356145005177154, -8.513482411762517],
              [-38.356562380347114, -8.513275978320612],
              [-38.356803415745787, -8.513116284459819],
              [-38.356995414509292, -8.512941134650777],
              [-38.35720475618735, -8.512718075139107],
              [-38.357396914027909, -8.512447050897189],
              [-38.357445438860609, -8.512224630251811],
              [-38.357445811040122, -8.512001225573304],
              [-38.357398004553168, -8.511793117765471],
              [-38.357302150463113, -8.511521616293699],
              [-38.357110763993795, -8.511330456269656],
              [-38.357094809303852, -8.511090743674441],
              [-38.357095287534136, -8.510804026370563],
              [-38.357288353368119, -8.510533003129401],
              [-38.357641219002332, -8.5103255566413],
              [-38.358010541569186, -8.510055729535457],
              [-38.358363804395189, -8.509609501459893],
              [-38.358652345582279, -8.509290697578447],
              [-38.358797309787683, -8.508987937486154],
              [-38.358941980852151, -8.508860645355854],
              [-38.359166447265928, -8.508828453727544],
              [-38.359326165026978, -8.508940872191669],
              [-38.35940574627314, -8.509164408933893],
              [-38.359469431412307, -8.50965926105868],
              [-38.359452414209436, -8.510058106686079],
              [-38.359516392490249, -8.510376586854251],
              [-38.359707434792519, -8.510775775647753],
              [-38.359803315894936, -8.511031899210524],
              [-38.359882077744338, -8.51174927803164],
              [-38.359641129941558, -8.512403720966695],
              [-38.359319348733642, -8.513040844938885],
              [-38.359125619579018, -8.513710741820804],
              [-38.359205148799596, -8.513965934375936],
              [-38.359397445261585, -8.514158000395534],
              [-38.359781687461769, -8.514206570418835],
              [-38.360070557360466, -8.514238702462281],
              [-38.360263171976541, -8.514239019616072],
              [-38.360406565868104, -8.514335130163618],
              [-38.360454323330217, -8.514573989789749],
              [-38.360374051750576, -8.514765606591732],
              [-38.36016567209137, -8.514957012291497],
              [-38.359844737293201, -8.515084015281062],
              [-38.359219459695055, -8.5151942351239],
              [-38.358979439341383, -8.515289713365275],
              [-38.358770229727284, -8.515433180347827],
              [-38.358530023284203, -8.515639907931153],
              [-38.358384977040124, -8.515990604740107],
              [-38.358384497808963, -8.516278227038899],
              [-38.35848030087346, -8.516581384323658],
              [-38.358719551854819, -8.516948995343247],
              [-38.359007866484454, -8.517315783407467],
              [-38.359199175914377, -8.517555785142953],
              [-38.359391395338427, -8.517794882884539],
              [-38.359407031874063, -8.51822634334591],
              [-38.359342046107052, -8.518512954598638],
              [-38.359053337842667, -8.518927631913211],
              [-38.358715353828295, -8.519469758434324],
              [-38.358586474377105, -8.519931732598469],
              [-38.358537283222731, -8.520553929879721],
              [-38.358632608055771, -8.521144708463693],
              [-38.358824536338588, -8.521559274167727],
              [-38.35919213951496, -8.521783286140545],
              [-38.359768980317916, -8.521848455221861],
              [-38.360026507663243, -8.521609193844787],
              [-38.360170459823813, -8.521370650371619],
              [-38.360395197365627, -8.52117927168495],
              [-38.36084411517929, -8.52113207332712],
              [-38.361405590131866, -8.521149277679562],
              [-38.361741897781286, -8.521069332424231],
              [-38.361983067243408, -8.520830947028774],
              [-38.36232000820057, -8.520368409035994],
              [-38.362464683265202, -8.520241115905646],
              [-38.362561071611758, -8.520193336266113],
              [-38.362704651592765, -8.520178196471731],
              [-38.362849010503879, -8.520241746212747],
              [-38.363009512194246, -8.520433758207306],
              [-38.363040837735156, -8.520720528537126],
              [-38.362943602977481, -8.521279334154665],
              [-38.362750218989611, -8.521741203267489],
              [-38.362717678858637, -8.522187960599874],
              [-38.362941622014873, -8.522475951078375],
              [-38.36332587321499, -8.522524519102108],
              [-38.363806882941489, -8.52230190180739],
              [-38.364144231648446, -8.522142362023505],
              [-38.364481038540944, -8.521760320554646],
              [-38.364866908081019, -8.52137835812497],
              [-38.365252105743259, -8.520852583545015],
              [-38.365412437800295, -8.520596879186508],
              [-38.36554091505964, -8.520373683609979],
              [-38.36568561413668, -8.520231012570534],
              [-38.365830078098789, -8.52023124868599],
              [-38.366022486612358, -8.520359094085778],
              [-38.366133848617366, -8.520598056763083],
              [-38.366213356824687, -8.520869529222422],
              [-38.366212621465209, -8.521316338580283],
              [-38.366276552719391, -8.521667379556623],
              [-38.36627613251418, -8.521922441048378],
              [-38.366179111858983, -8.522353717420224],
              [-38.366018361597362, -8.522863579275969],
              [-38.365921525032377, -8.52318270131102],
              [-38.365792942722067, -8.523469209513232],
              [-38.365727906928562, -8.52378838357374],
              [-38.365631070363555, -8.524107505608814],
              [-38.365406307809231, -8.524314262204642],
              [-38.364925058966911, -8.524680692648989],
              [-38.364571842166292, -8.525094363917672],
              [-38.364443256855267, -8.525381777114527],
              [-38.364346418290197, -8.525700898149626],
              [-38.364281763233926, -8.526338447328381],
              [-38.364344125173375, -8.527088358945797],
              [-38.364343176711017, -8.527662698549877],
              [-38.364182577525689, -8.528077589967323],
              [-38.363908968983054, -8.528699420069966],
              [-38.363651900413252, -8.529209122848872],
              [-38.363410196693096, -8.529767691351417],
              [-38.363040248830046, -8.530404739286135],
              [-38.36287980672185, -8.530723755270511],
              [-38.362702744603034, -8.531201931300631],
              [-38.362637624770798, -8.531569041077894],
              [-38.362684935013881, -8.532079242100403],
              [-38.36278066503926, -8.53243033610269],
              [-38.363084333504737, -8.53286226879557],
              [-38.363612852563961, -8.533038604181382],
              [-38.364414112741969, -8.533119511352192],
              [-38.364942711839809, -8.533247908021508],
              [-38.365391247460494, -8.533440391247202],
              [-38.365631582512634, -8.533711222841161],
              [-38.36590369032244, -8.533999290358979],
              [-38.366143168405898, -8.534239368134093],
              [-38.366528025445596, -8.534478778030918],
              [-38.366736044925219, -8.534510774010315],
              [-38.36691234167116, -8.534495686242327],
              [-38.367296921025705, -8.534352502400147],
              [-38.367538097488961, -8.534113210009826],
              [-38.367683721016618, -8.533411574274641],
              [-38.367588694338195, -8.53262995281731],
              [-38.36749353659561, -8.531927924889525],
              [-38.367319236825949, -8.530731019822733],
              [-38.367368310929486, -8.530173038160534],
              [-38.36769001810093, -8.529582940908965],
              [-38.367995185299485, -8.529104969981221],
              [-38.368188412211296, -8.528738067306412],
              [-38.368284785001542, -8.528147601874572],
              [-38.368285596399225, -8.527653759794305],
              [-38.368511250390654, -8.526904316406986],
              [-38.36870426519976, -8.526664944978171],
              [-38.36900935035969, -8.526234909767119],
              [-38.369298779527888, -8.525932380789634],
              [-38.369442572612328, -8.525788802754731],
              [-38.369587117613051, -8.525741100153699],
              [-38.36976338434674, -8.525741387294714],
              [-38.3699239690756, -8.525885459573081],
              [-38.370018875146265, -8.526188612857938],
              [-38.370018169799621, -8.526619142312516],
              [-38.369952060023472, -8.528150312208471],
              [-38.369951092547794, -8.528740933716726],
              [-38.369998044610277, -8.529475444413311],
              [-38.370157280127742, -8.529889952092342],
              [-38.370477728682168, -8.530065941311681],
              [-38.371166318670255, -8.530146651392284],
              [-38.371648189364457, -8.529956587905914],
              [-38.372032919796418, -8.529717524630422],
              [-38.372513725420703, -8.529622427581831],
              [-38.372849777941468, -8.529702564380409],
              [-38.373234842083562, -8.529815342025683],
              [-38.373602359209023, -8.530102654625257],
              [-38.373907024706845, -8.530486644602622],
              [-38.374178100460078, -8.530853394654679],
              [-38.374386482662928, -8.531220947651269],
              [-38.374465763753989, -8.531636230261192],
              [-38.374561194625464, -8.532178166135772],
              [-38.374560492277794, -8.532609599585525],
              [-38.374479419299561, -8.533295062468948],
              [-38.374462571172856, -8.533598034664868],
              [-38.374526692516177, -8.533837824299781],
              [-38.374718019945725, -8.534077819036556],
              [-38.374845901910184, -8.534220932294422],
              [-38.375102934011224, -8.534285564120797],
              [-38.375871749553355, -8.534207208206837],
              [-38.376304742691389, -8.533904001349439],
              [-38.376498168705744, -8.533410467422721],
              [-38.376547463925561, -8.532708672609891],
              [-38.376837463377754, -8.532054297711889],
              [-38.377030212514477, -8.531416951634538],
              [-38.376983013336407, -8.530826252087154],
              [-38.376535172062191, -8.530203252406206],
              [-38.376295973119113, -8.529788617662909],
              [-38.376168275245639, -8.529532446073418],
              [-38.376104151901444, -8.529293560433331],
              [-38.376104437042805, -8.529118092470585],
              [-38.37612094700259, -8.529022245050419],
              [-38.376217492426591, -8.52887858797761],
              [-38.376313464023994, -8.528527805128466],
              [-38.37620248221225, -8.528049158867454],
              [-38.375867182064383, -8.527505933806561],
              [-38.375547119702098, -8.527091167998822],
              [-38.375243233132664, -8.526787679546491],
              [-38.375099155363515, -8.526547760847855],
              [-38.37514783027467, -8.526228558773907],
              [-38.375259845600048, -8.526069551804385],
              [-38.375436241397949, -8.525990241415972],
              [-38.375628863014207, -8.525990551570102],
              [-38.376061199830943, -8.526086216354692],
              [-38.376413371118588, -8.526310188316049],
              [-38.376893629472818, -8.526550646283685],
              [-38.377470251161462, -8.526758696520883],
              [-38.378046486657027, -8.527206432341321],
              [-38.378766751896279, -8.527925740673442],
              [-38.379422587637443, -8.528597007237471],
              [-38.380143244069721, -8.529076627986704],
              [-38.381168617482565, -8.529349607204001],
              [-38.381600907273103, -8.529477827796448],
              [-38.382257826554444, -8.529478874322402],
              [-38.382594892120039, -8.52949569149602],
              [-38.382899428010973, -8.529400301306394],
              [-38.383188620064608, -8.529240668483975],
              [-38.383268858631695, -8.529065327585144],
              [-38.383380897970355, -8.528890036711763],
              [-38.383605500447985, -8.528779142548972],
              [-38.384038198444266, -8.528652297649018],
              [-38.384343872444582, -8.528413096310153],
              [-38.384487889644518, -8.528126605119803],
              [-38.384488400901823, -8.527807325006954],
              [-38.384281046756406, -8.527360184481406],
              [-38.383945300389705, -8.527088307816133],
              [-38.383272669547061, -8.526688364634976],
              [-38.381927849080377, -8.525617132876818],
              [-38.380791985099428, -8.524562510190107],
              [-38.379543282838114, -8.523618955756012],
              [-38.378806101432652, -8.523251464331395],
              [-38.377941693928499, -8.522899142714273],
              [-38.376741055269584, -8.522019874939781],
              [-38.37541236555716, -8.521107836255778],
              [-38.374115534641739, -8.52016418878469],
              [-38.373043101101054, -8.51923717939723],
              [-38.37265947520833, -8.51880602863573],
              [-38.372515375425877, -8.518582390841251],
              [-38.372420703470674, -8.518135426407028],
              [-38.372421040637249, -8.517928301631827],
              [-38.372564854734051, -8.517769345687864],
              [-38.37270937072249, -8.517737017995826],
              [-38.372869279573742, -8.517737276123626],
              [-38.373574824296469, -8.517994381174281],
              [-38.374054993613086, -8.518281873863094],
              [-38.37450336215749, -8.518570220521182],
              [-38.375159926271984, -8.518778401821582],
              [-38.37560824479084, -8.519098403292514],
              [-38.376119906724789, -8.519577693873181],
              [-38.376312216187536, -8.519768846898792],
              [-38.376551821335674, -8.519929324144012],
              [-38.376888774850258, -8.520009458942946],
              [-38.377080536498596, -8.519978109283427],
              [-38.377273259166635, -8.519914200817116],
              [-38.377562728360544, -8.519580008026768],
              [-38.377771335136003, -8.519244781177083],
              [-38.377996061677578, -8.51905429648486],
              [-38.378252435914234, -8.518958832256605],
              [-38.378573189623516, -8.518943064609262],
              [-38.378717653585824, -8.518943295724762],
              [-38.378877459385471, -8.519007768472944],
              [-38.379149415119635, -8.519390797428857],
              [-38.379197105542261, -8.519678497766819],
              [-38.37919669433694, -8.519933560258808],
              [-38.379292541414365, -8.520220432640654],
              [-38.379628741011985, -8.520205594000425],
              [-38.379788960017692, -8.520014100261925],
              [-38.379789911493077, -8.519423477753804],
              [-38.379774825783812, -8.519200047062313],
              [-38.379871340194669, -8.519072669893321],
              [-38.380015152291641, -8.518913711949285],
              [-38.380272433521867, -8.518818247721699],
              [-38.380592303250069, -8.518787101164557],
              [-38.380736639148338, -8.518866924809505],
              [-38.381217225670476, -8.518899347006656],
              [-38.3816661905106, -8.518820467836129],
              [-38.382003451178953, -8.518709753763384],
              [-38.382323346920394, -8.518662325302518],
              [-38.382564095178594, -8.518678084404144],
              [-38.382756482679319, -8.51882220170792],
              [-38.382851551827343, -8.519029478559684],
              [-38.382915369016715, -8.519461016060648],
              [-38.382914601631526, -8.519939484231983],
              [-38.382849580837835, -8.520258661292823],
              [-38.3828491716325, -8.520513723784918],
              [-38.382944995696803, -8.520816876070725],
              [-38.383185437800606, -8.521024383038796],
              [-38.384018351687295, -8.521185797758854],
              [-38.384691357723341, -8.521346053356311],
              [-38.385732033962057, -8.52169863811474],
              [-38.386645104908972, -8.522306082263755],
              [-38.387173214763422, -8.522738352136578],
              [-38.387813789706726, -8.523297426351126],
              [-38.388518017218658, -8.523824942803824],
              [-38.389238072812091, -8.524113699680399],
              [-38.389543385632578, -8.524097900020935],
              [-38.389783506038036, -8.523939089153837],
              [-38.389928604321824, -8.523540441627132],
              [-38.389961290543745, -8.52298152495651],
              [-38.389962353083661, -8.522311306918084],
              [-38.389835455620108, -8.52154591833858],
              [-38.389740081787515, -8.52095514275269],
              [-38.389820517457309, -8.520652268607751],
              [-38.389885609289728, -8.520285152830057],
              [-38.390126761752754, -8.52004674843417],
              [-38.390350424224678, -8.519951225179694],
              [-38.390511243069696, -8.519951477308355],
              [-38.390879925317691, -8.52007958684961],
              [-38.391327426882164, -8.520351632604054],
              [-38.391808397128656, -8.52071869982406],
              [-38.392464419960604, -8.521278695045678],
              [-38.392992587841604, -8.521678397111193],
              [-38.393713175236471, -8.522205929577151],
              [-38.394097205321813, -8.522398278751425],
              [-38.394481337458956, -8.522526409305261],
              [-38.394961981007114, -8.522527158689941],
              [-38.395507284169355, -8.522432132692957],
              [-38.395700205940521, -8.522240682980403],
              [-38.395845196172694, -8.521906251074013],
              [-38.395894278302706, -8.521315701603404],
              [-38.39579947076524, -8.520358614182339],
              [-38.395544420678391, -8.519034057803273],
              [-38.395417438176828, -8.518315702945735],
              [-38.395417888407692, -8.5180289836405],
              [-38.395498977942822, -8.517885297555267],
              [-38.39569076260463, -8.51783765799194],
              [-38.396123295524291, -8.517806673525076],
              [-38.39650828262954, -8.517966459892302],
              [-38.397099850951413, -8.518493786254798],
              [-38.397868918167788, -8.518814260983389],
              [-38.398718141913491, -8.519006421535128],
              [-38.399535813015085, -8.519040247997015],
              [-38.400320827377811, -8.519041459625257],
              [-38.400849224375065, -8.519297339540923],
              [-38.401072824339423, -8.519824091609367],
              [-38.400927143747175, -8.520605338875125],
              [-38.400589749530852, -8.521387194982166],
              [-38.40034783066875, -8.522120355454252],
              [-38.400298432371578, -8.522918031701083],
              [-38.40053789196093, -8.523764090896076],
              [-38.400729305429678, -8.523956136916384],
              [-38.400938208891063, -8.524003491805766],
              [-38.401370698785342, -8.524004159152073],
              [-38.401931572901113, -8.523829553637963],
              [-38.402573633603232, -8.523447946412549],
              [-38.40295816494617, -8.523321006473989],
              [-38.403391735923705, -8.523210421478463],
              [-38.404000788706959, -8.523019607099076],
              [-38.404786108224521, -8.522829060860786],
              [-38.405155069614331, -8.522781688439489],
              [-38.405443092546186, -8.522782129670126],
              [-38.405731925419708, -8.522846790522019],
              [-38.405876268318174, -8.522926605167354],
              [-38.406019629184556, -8.523054356528666],
              [-38.406035786964303, -8.523181913788173],
              [-38.406019160940282, -8.523357357738155],
              [-38.405827177175397, -8.523532533547753],
              [-38.405393359068697, -8.523803213597237],
              [-38.404783985118378, -8.524201156752609],
              [-38.404334398955605, -8.524678937565799],
              [-38.404109654399377, -8.524886624156744],
              [-38.403917250416526, -8.525332237368218],
              [-38.403900252199165, -8.525747368901877],
              [-38.403899731928554, -8.526082930919019],
              [-38.404043285415611, -8.526672872550076],
              [-38.404282537371479, -8.527072116385334],
              [-38.404586647044233, -8.527248052592356],
              [-38.40485902998406, -8.527376002057158],
              [-38.405115793944333, -8.527616082846922],
              [-38.405355270016116, -8.527871514532018],
              [-38.405499021605216, -8.528334828911541],
              [-38.405530280093714, -8.528685815863437],
              [-38.405577944491085, -8.529004266020873],
              [-38.406009552922086, -8.52957927297401],
              [-38.40639411279524, -8.530026675642823],
              [-38.406649699620374, -8.530442223395351],
              [-38.406889030614721, -8.530793528514165],
              [-38.407112890706898, -8.53116108952449],
              [-38.407352861553065, -8.53168786460752],
              [-38.407464079467346, -8.532038973623765],
              [-38.407592684321848, -8.532310515124122],
              [-38.407704272429577, -8.532421936556439],
              [-38.40792862577937, -8.532470216453158],
              [-38.408425637210591, -8.532470974851703],
              [-38.408698099189039, -8.532550984600011],
              [-38.408793356426585, -8.532647005110087],
              [-38.408841143887337, -8.53288676573268],
              [-38.408840237411269, -8.533476486248812],
              [-38.408887803755839, -8.533860059021864],
              [-38.409031563345316, -8.534322468407314],
              [-38.409191087991104, -8.53457777502881],
              [-38.409559570123172, -8.534849680721747],
              [-38.409720174852211, -8.534993738001175],
              [-38.409863320602263, -8.535265300513409],
              [-38.409958947549868, -8.535712259951236],
              [-38.410006002624108, -8.536430490747547],
              [-38.410133677472587, -8.53671740515669],
              [-38.410565840186578, -8.536941470183983],
              [-38.411110893207763, -8.537021892151206],
              [-38.411448016799696, -8.53700702851245],
              [-38.411960751262548, -8.536831431965428],
              [-38.412265408218765, -8.536656424246145],
              [-38.412746704102148, -8.536242901078724],
              [-38.413181360647656, -8.535429525234857],
              [-38.413518962981144, -8.534504749970155],
              [-38.413984483291685, -8.533707700054991],
              [-38.414338348973025, -8.533436889941353],
              [-38.414851003396599, -8.533310131105726],
              [-38.415684011311399, -8.533438919020687],
              [-38.41659744941331, -8.533855452301617],
              [-38.417493554600512, -8.534319894285657],
              [-38.418327112316035, -8.534687460791183],
              [-38.419032082189503, -8.534768111886798],
              [-38.41951276575103, -8.534753456363259],
              [-38.419865552349336, -8.534593890591742],
              [-38.420106648787986, -8.534387124008317],
              [-38.420523783328569, -8.533733806204664],
              [-38.420813325576368, -8.53333536079244],
              [-38.421246475819061, -8.532905473682344],
              [-38.42179203111197, -8.532650317631576],
              [-38.422417397752639, -8.53249206007334],
              [-38.422930287305256, -8.532206102177978],
              [-38.4230751174617, -8.53196662970957],
              [-38.423123848424993, -8.531584105007997],
              [-38.42312408855431, -8.53142401195362],
              [-38.422948127987048, -8.531216623035609],
              [-38.422595754608572, -8.531103942415264],
              [-38.422114213078167, -8.531087849119809],
              [-38.421169063759166, -8.531213972608569],
              [-38.420447528886712, -8.531277113650576],
              [-38.41966258457461, -8.531211722400288],
              [-38.418893159190418, -8.531098414445511],
              [-38.418027613599975, -8.530858333161561],
              [-38.417547321244335, -8.530617925192008],
              [-38.417018823208181, -8.530410003991481],
              [-38.416074741456299, -8.529834237626609],
              [-38.415482124089984, -8.529370249886604],
              [-38.415241779050163, -8.529083167387402],
              [-38.415194470846309, -8.528525029645639],
              [-38.415291341451166, -8.528157955892283],
              [-38.415484232209828, -8.527982776083286],
              [-38.415740604447585, -8.527887286855099],
              [-38.416349683243993, -8.527681076566699],
              [-38.41663905540171, -8.527393886497526],
              [-38.416879677078775, -8.526899495612653],
              [-38.416704302820158, -8.526309508955272],
              [-38.416417027287288, -8.525814324647339],
              [-38.415600523815101, -8.52499906080133],
              [-38.414672193105837, -8.524232469577482],
              [-38.413872100541653, -8.523385570932067],
              [-38.413536844444089, -8.522779061243355],
              [-38.413473113319363, -8.522267932211129],
              [-38.413489759355834, -8.522077111351894],
              [-38.41366629423247, -8.521901003535218],
              [-38.413874384751047, -8.521885941792736],
              [-38.414146815716663, -8.521982227444552],
              [-38.414402674684531, -8.522221397238951],
              [-38.415108066306587, -8.52260596153854],
              [-38.415636891523143, -8.52259047605836],
              [-38.416021856098624, -8.522175897819132],
              [-38.416215614320343, -8.521426372403729],
              [-38.416311547918404, -8.521075576553677],
              [-38.416504652792995, -8.520756583594412],
              [-38.416842293668253, -8.520390775028803],
              [-38.417130605755041, -8.520199457392449],
              [-38.417483475404218, -8.519976578994848],
              [-38.41762820650856, -8.519801326147331],
              [-38.417757039974617, -8.519323047077009],
              [-38.417917263993566, -8.519116160428929],
              [-38.418141926511439, -8.518956403554592],
              [-38.418431048539972, -8.518829304539473],
              [-38.418879961356275, -8.518782038182216],
              [-38.419248081791871, -8.518686713043392],
              [-38.419681667783259, -8.518559829144023],
              [-38.420066046049641, -8.51852874563887],
              [-38.420659350790281, -8.518529631113996],
              [-38.421026966955345, -8.518769866992555],
              [-38.421283495787783, -8.519169126841776],
              [-38.421650873824412, -8.519568551779903],
              [-38.421763466977659, -8.519615752592241],
              [-38.421891578057981, -8.519615943694886],
              [-38.42200340429364, -8.519569077062251],
              [-38.422131707477519, -8.519440830923735],
              [-38.422148468578179, -8.51917041453464],
              [-38.422084390273262, -8.518882693182343],
              [-38.421786877682315, -8.518547862919286],
              [-38.425471306788502, -8.515425347347396],
              [-38.427188999722318, -8.513880307864625],
              [-38.428103558951527, -8.512909332340657],
              [-38.430414041865411, -8.511077522031387],
              [-38.431039817751056, -8.510616245262671],
              [-38.431633371634639, -8.51044073777968],
              [-38.43248183046633, -8.510537852892552],
              [-38.434021947971722, -8.509854496175402],
              [-38.434951877557893, -8.509536564805867],
              [-38.435721875278645, -8.509234679212163],
              [-38.436427689642599, -8.508709290886728],
              [-38.437872485901103, -8.506716986824248],
              [-38.438113393250369, -8.506621459583439],
              [-38.439572184092903, -8.506240965011044],
              [-38.440422542472042, -8.505651558180515],
              [-38.441336310291383, -8.505190677642101],
              [-38.442586067088264, -8.505479192948679],
              [-38.443742349157262, -8.503805734768918],
              [-38.443502456373373, -8.503199385156623],
              [-38.443568183591736, -8.502353782204906],
              [-38.444514649177364, -8.501892035697825],
              [-38.445348223442529, -8.501590220154666],
              [-38.44643857719057, -8.500889889173175],
              [-38.44650338189453, -8.500682853448563],
              [-38.446583494411094, -8.500570810175198],
              [-38.446824876030128, -8.500140616911599],
              [-38.447113410259163, -8.499773803311699],
              [-38.447301108477809, -8.499080688557818],
              [-38.439333313264768, -8.489736515328865],
              [-38.43909954814437, -8.489488258607162],
              [-38.437611413850512, -8.488226162864201],
              [-38.435897443879149, -8.487330051776663],
              [-38.433767549984374, -8.486321174020416],
              [-38.432199521277361, -8.485042662311786],
              [-38.427765049572074, -8.482005244689097],
              [-38.426371925350004, -8.481046251234874],
              [-38.425091769486293, -8.479879388100981],
              [-38.423698469161813, -8.479047918893505],
              [-38.421825590332126, -8.478151516683811],
              [-38.421392337494296, -8.478086655718137],
              [-38.420559587652846, -8.477862917369865],
              [-38.419951332252936, -8.477574386585722],
              [-38.348860973301264, -8.442283608326836],
              [-38.349945329750497, -8.445699806959196],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '966',
        NOME_UC1: 'PARQUE ESTADUAL DE DOIS IRMÃOS',
        ID_WCMC2: null,
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '4144',
        QUALIDAD8:
          'Esquemático (O poligono é uma representação esquemática da dimensão da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '27/09/2010',
        CODIGO_U11: '0000.26.0966',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.943216352599997, -8.01517835376],
              [-34.951485143699998, -8.01105399327],
              [-34.957044199599999, -8.00251446503],
              [-34.9431911271, -7.99200064993],
              [-34.9427114016, -8.014156989929999],
              [-34.943216352599997, -8.01517835376],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '964',
        NOME_UC1: 'ESTAÇÃO ECOLÓGICA DE CAETÉS',
        ID_WCMC2: null,
        CATEGORI3: 'Estação Ecológica',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12064',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '12/01/2016',
        CODIGO_U11: '0000.26.0964',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.931660267728788, -7.923331094093646],
              [-34.931583946767383, -7.923337922711322],
              [-34.931511966102413, -7.923336821144889],
              [-34.93145908704215, -7.92331601670605],
              [-34.931430217153768, -7.923298928171621],
              [-34.931399517181006, -7.923300984047777],
              [-34.931343839464198, -7.923297414901263],
              [-34.931278591980536, -7.923299630876161],
              [-34.931217209869345, -7.923307569708296],
              [-34.93112710060408, -7.923329036807178],
              [-34.931015887872647, -7.923352515269896],
              [-34.930852862755316, -7.92337814736643],
              [-34.93072057327494, -7.923409377787669],
              [-34.930528829821412, -7.923450451571601],
              [-34.930308350191495, -7.923503139951263],
              [-34.930032246191693, -7.923563740231143],
              [-34.929852062955931, -7.923614328168734],
              [-34.929681422720115, -7.923653390278154],
              [-34.929503176505683, -7.923707796304277],
              [-34.9293533544516, -7.92368361325466],
              [-34.929180830430703, -7.923730338280314],
              [-34.92902024106322, -7.92386694699404],
              [-34.928913258654426, -7.923974603588595],
              [-34.928857901583292, -7.924039922092299],
              [-34.928762363679709, -7.924132216923719],
              [-34.928699266938715, -7.92418417606946],
              [-34.928586419502309, -7.924268896611158],
              [-34.928462136317926, -7.924370892425496],
              [-34.928357153128871, -7.924495762054037],
              [-34.928194493178196, -7.924728054842801],
              [-34.928185055723276, -7.92482501985054],
              [-34.928129213889264, -7.925007747176951],
              [-34.92807240050287, -7.925172579398813],
              [-34.92799794796283, -7.925259035785104],
              [-34.927921924236699, -7.925317562340489],
              [-34.927877733724451, -7.925450726569506],
              [-34.927809638297063, -7.925547759959073],
              [-34.927732685787895, -7.925587488293326],
              [-34.927648618687861, -7.925670161943665],
              [-34.927530012670729, -7.925754908984241],
              [-34.927176159858661, -7.925985591184117],
              [-34.927128807272183, -7.926015925874764],
              [-34.926982328685682, -7.926119557834202],
              [-34.926802237189726, -7.926247148388438],
              [-34.926691783207822, -7.926312615565543],
              [-34.926545609187599, -7.926417270378328],
              [-34.926311099385984, -7.9265851699235],
              [-34.926098683765588, -7.92672199794326],
              [-34.925965258148608, -7.926882580030007],
              [-34.925902270701144, -7.926939402724686],
              [-34.925742654225573, -7.927094628684501],
              [-34.925562255172984, -7.927317851138202],
              [-34.925432408075807, -7.927461971330964],
              [-34.925352187677348, -7.927546540525405],
              [-34.925293017618337, -7.927617617213338],
              [-34.925209011885926, -7.927713685487962],
              [-34.925022820431565, -7.927870868470555],
              [-34.924924879692696, -7.927949863227011],
              [-34.924735258898103, -7.928048350297291],
              [-34.924595404984395, -7.928132468609214],
              [-34.924525467565942, -7.928173385202749],
              [-34.924357537013172, -7.92828758369736],
              [-34.924243794267809, -7.928374785597143],
              [-34.923965543278555, -7.92857842610294],
              [-34.923823473329328, -7.928668286907805],
              [-34.923723107807334, -7.928730173112434],
              [-34.923629493579263, -7.92878625681123],
              [-34.923496484362914, -7.928874855346145],
              [-34.923355662871224, -7.928977269155103],
              [-34.92324200314922, -7.929073611287348],
              [-34.923133846044259, -7.929151597807047],
              [-34.923049083896728, -7.929183568193892],
              [-34.922991337545106, -7.929193471207664],
              [-34.922966516529399, -7.929207510793535],
              [-34.92279472324973, -7.929643998385981],
              [-34.922711071418739, -7.930013892233871],
              [-34.922665531561307, -7.930127005037158],
              [-34.922811590558119, -7.930621918614258],
              [-34.922757183173211, -7.930624921465038],
              [-34.922374084429201, -7.930648256273274],
              [-34.922043010389281, -7.930656049801788],
              [-34.921887809020873, -7.93067409382558],
              [-34.921504574418286, -7.930682574667326],
              [-34.921467050668312, -7.930671642466929],
              [-34.921436226180269, -7.930649195037208],
              [-34.921417718412542, -7.930609444863751],
              [-34.921403245770549, -7.930514792322322],
              [-34.921367486567085, -7.930324434378162],
              [-34.921316861297377, -7.930120560117008],
              [-34.921236918303741, -7.929810802074381],
              [-34.921103157299584, -7.929320065545962],
              [-34.921026136900345, -7.928957705054296],
              [-34.920595813199242, -7.929149636056881],
              [-34.92055477560411, -7.929250733825434],
              [-34.920443823095781, -7.929519586087533],
              [-34.920402785445326, -7.929620683858089],
              [-34.920417090983683, -7.929697055626325],
              [-34.920466052563334, -7.929967228150349],
              [-34.920447265901323, -7.930021182278779],
              [-34.920340640412242, -7.930267123710302],
              [-34.920294881795186, -7.930472268646172],
              [-34.920198964332762, -7.930897471514324],
              [-34.920927642806227, -7.931732969348025],
              [-34.921406906423506, -7.932282486786916],
              [-34.922121681641066, -7.933093308266946],
              [-34.922815163662619, -7.933879970349849],
              [-34.923238322204398, -7.933929792494204],
              [-34.92366726126297, -7.933975164465794],
              [-34.923706734191846, -7.933979339778256],
              [-34.924195626502659, -7.934142974428548],
              [-34.924206420208229, -7.934156109793672],
              [-34.924375563411886, -7.93429522173943],
              [-34.924405805999051, -7.934338107187868],
              [-34.924476563088596, -7.934397548197837],
              [-34.924503276995303, -7.934438843942162],
              [-34.924536687760693, -7.93447382700867],
              [-34.92455066887166, -7.934484113617261],
              [-34.924576828153768, -7.934503360306152],
              [-34.92461927090902, -7.934525025277245],
              [-34.924670450652542, -7.934525605927441],
              [-34.924715209335552, -7.934516932764522],
              [-34.924755652635788, -7.934485960000255],
              [-34.924758705799889, -7.934483151499354],
              [-34.924790002643583, -7.934454362574753],
              [-34.924856872743959, -7.934388647035973],
              [-34.924897611024484, -7.934365440481269],
              [-34.924984708403663, -7.93433004102266],
              [-34.925030398386738, -7.93432305962404],
              [-34.925077281948411, -7.934324673328125],
              [-34.925126959533358, -7.934331362391258],
              [-34.925228210173486, -7.934348986989998],
              [-34.925320278493885, -7.934353145314756],
              [-34.925420433889052, -7.934364763514983],
              [-34.925514056363319, -7.934387008934074],
              [-34.925545093530957, -7.934382331938029],
              [-34.925569073296352, -7.934374475593215],
              [-34.925655206458678, -7.93433652130823],
              [-34.925703690452842, -7.934334615206258],
              [-34.925747536996987, -7.934347582842078],
              [-34.925835557884731, -7.9343821472295],
              [-34.925923040804086, -7.934425374765386],
              [-34.926006194805687, -7.934468772459088],
              [-34.926035483145391, -7.934477244451776],
              [-34.926050907151641, -7.934481706026422],
              [-34.926096190256537, -7.934486839327646],
              [-34.926149035959071, -7.934485625888855],
              [-34.926196391922538, -7.934476850473038],
              [-34.926197227967428, -7.934476495855192],
              [-34.926281823141466, -7.934443244662715],
              [-34.926330208789722, -7.934438749748598],
              [-34.926436995426897, -7.934442329159063],
              [-34.926531123102862, -7.934455048263369],
              [-34.926622882888736, -7.934473909840711],
              [-34.926628765107473, -7.934474498282959],
              [-34.926672494915721, -7.934478872881662],
              [-34.926768957690904, -7.934484586514841],
              [-34.926815368828038, -7.934496588993237],
              [-34.926866010547023, -7.934505832531301],
              [-34.926915589781224, -7.934509932632793],
              [-34.926962407753138, -7.934509820290639],
              [-34.927011986986024, -7.934513920380277],
              [-34.927113382206755, -7.934512526381867],
              [-34.92721634506821, -7.934506749721605],
              [-34.927310092755469, -7.934486643940945],
              [-34.927407828107981, -7.934480208572318],
              [-34.927509419986229, -7.934483991949287],
              [-34.927607745434884, -7.934493088942308],
              [-34.927705939741564, -7.934498734259129],
              [-34.927797811285323, -7.934497714561851],
              [-34.927908631725906, -7.934493357177234],
              [-34.927956918950436, -7.934486273342698],
              [-34.928056025196597, -7.934470277709463],
              [-34.928064229288459, -7.934467850344287],
              [-34.928148644717609, -7.934443302502632],
              [-34.92824100195029, -7.934409423994624],
              [-34.928307910204424, -7.93439037333323],
              [-34.928405658813396, -7.934361467994562],
              [-34.928495549783442, -7.934331143214266],
              [-34.928583348523837, -7.934291394474767],
              [-34.928629674629882, -7.934278338277617],
              [-34.928689525421262, -7.934256108274548],
              [-34.928737714231382, -7.934246435626405],
              [-34.928837882835744, -7.934235583247602],
              [-34.92894773899269, -7.934228670918607],
              [-34.92906099269306, -7.934219896562456],
              [-34.929110978620649, -7.934211881606749],
              [-34.92920591970352, -7.934200370502918],
              [-34.929314516579524, -7.934183137191048],
              [-34.929363800650506, -7.934179470809249],
              [-34.929460801146362, -7.93417652066854],
              [-34.929504778840652, -7.934192939522914],
              [-34.929523766673498, -7.934236267064096],
              [-34.929501065770523, -7.934277777133695],
              [-34.929474737790507, -7.934315108825921],
              [-34.929458195723015, -7.93435896930787],
              [-34.929464295182072, -7.934405396257039],
              [-34.929495043333219, -7.934438755141951],
              [-34.929530393983093, -7.934461055115428],
              [-34.929535020025533, -7.934463973321585],
              [-34.929554066021765, -7.934469482429682],
              [-34.929584992663791, -7.93447842802434],
              [-34.929631075923758, -7.934481801068378],
              [-34.929719549786554, -7.93448264295205],
              [-34.929868464554424, -7.934476787027462],
              [-34.929916915637214, -7.934474017527815],
              [-34.930011725561052, -7.934459054593524],
              [-34.9300576120798, -7.934457250120229],
              [-34.930095744847655, -7.934461830829473],
              [-34.930106391045875, -7.934463109706303],
              [-34.930135473258446, -7.9344982624408],
              [-34.930182258350328, -7.934497286820648],
              [-34.930275369471978, -7.934483254830936],
              [-34.930320862507472, -7.934471095408664],
              [-34.930350053282716, -7.934457253992778],
              [-34.930368559415918, -7.934448478925676],
              [-34.930400180371393, -7.934413531631675],
              [-34.930410629057342, -7.93436904656128],
              [-34.930416545810743, -7.934273751931159],
              [-34.930429493467855, -7.934226575976376],
              [-34.93042502082735, -7.934131689913086],
              [-34.930420789830961, -7.934004896865385],
              [-34.930420476342221, -7.933989275699333],
              [-34.930418672920489, -7.933941815646916],
              [-34.930434794882615, -7.93393253964571],
              [-34.930472771383805, -7.933905120223402],
              [-34.930518034823407, -7.933886920429691],
              [-34.930566944876638, -7.933896231612928],
              [-34.930610351881761, -7.933920450593516],
              [-34.930655995551632, -7.933935075410345],
              [-34.930696766112916, -7.933912731282665],
              [-34.93071770252547, -7.933870426372265],
              [-34.9307488971467, -7.933824261235647],
              [-34.930778753675909, -7.933788519122702],
              [-34.930835869378484, -7.933717137032722],
              [-34.930851447123509, -7.9336707218562],
              [-34.930856569913104, -7.933622989431166],
              [-34.930858196791554, -7.933574530287649],
              [-34.930872974334726, -7.933529874974523],
              [-34.930897439409719, -7.933489159679635],
              [-34.930916677010991, -7.933447785773804],
              [-34.930946566294665, -7.933412906564816],
              [-34.930991193402896, -7.933400781151017],
              [-34.931041343098968, -7.933397080546792],
              [-34.931088633121959, -7.93338657880613],
              [-34.931175991796465, -7.933358081496202],
              [-34.931227072843328, -7.933356072643441],
              [-34.93132539787824, -7.933365168802766],
              [-34.931366109131034, -7.933386901120911],
              [-34.931414408832488, -7.9334714215415],
              [-34.931441024865627, -7.93351012796195],
              [-34.931476803790623, -7.933538967835771],
              [-34.931516780448483, -7.933564185817852],
              [-34.931596064753727, -7.933619833282348],
              [-34.931637235132904, -7.933653646296781],
              [-34.931677244602511, -7.933679727173514],
              [-34.931713922129418, -7.933709395874368],
              [-34.93174563828871, -7.933744325834055],
              [-34.931746500295574, -7.933745275187973],
              [-34.93176975162919, -7.933786706507584],
              [-34.931797135126885, -7.93382279011307],
              [-34.931822085237926, -7.933863290409477],
              [-34.931841086453268, -7.933884148099359],
              [-34.931884559086377, -7.933910092741499],
              [-34.931915189343968, -7.933917530077415],
              [-34.931979955570149, -7.933811663049838],
              [-34.932011386269892, -7.933790851973166],
              [-34.93203411965164, -7.933745843966756],
              [-34.932079312009812, -7.933723409821284],
              [-34.932124778748843, -7.933633393805938],
              [-34.932170062555535, -7.933588432366421],
              [-34.932418574619184, -7.93347630813988],
              [-34.932419214754646, -7.933318617125519],
              [-34.932487231806995, -7.933228647655769],
              [-34.932577616354102, -7.933183779309617],
              [-34.932668092323681, -7.933116383666141],
              [-34.932668366634985, -7.933048801810318],
              [-34.93289460209477, -7.932869049009651],
              [-34.933030452973426, -7.932734164556933],
              [-34.933098744071494, -7.932576613199305],
              [-34.933166760866037, -7.932486643680343],
              [-34.933438187905359, -7.932284456491113],
              [-34.933460921069766, -7.932239448460918],
              [-34.933461195285417, -7.932171866633169],
              [-34.933574312627485, -7.932081990122503],
              [-34.933732439187565, -7.932014733881515],
              [-34.933867741185978, -7.932015012899696],
              [-34.933890108753069, -7.932060113939519],
              [-34.934024862492087, -7.932195556506072],
              [-34.934114607007963, -7.932308378787318],
              [-34.934136974613295, -7.932353479802992],
              [-34.934181983940185, -7.932376100027621],
              [-34.934204351566493, -7.932421201036878],
              [-34.934271819899273, -7.932466394983066],
              [-34.93429418753243, -7.932511495983816],
              [-34.934339196892168, -7.932534116185056],
              [-34.934316829243805, -7.932489015188307],
              [-34.934362021306292, -7.93246658086536],
              [-34.934452040013625, -7.932511821247765],
              [-34.934587342118618, -7.932512099992558],
              [-34.934654901831166, -7.932534766605642],
              [-34.934722552889887, -7.932534905949625],
              [-34.934790295265522, -7.932512518027174],
              [-34.935083449768427, -7.93251312167688],
              [-34.935174016386092, -7.932423198369285],
              [-34.935219664910775, -7.932288127697521],
              [-34.935220212774531, -7.932152964187213],
              [-34.93515310969395, -7.932017661383857],
              [-34.935198575584671, -7.93192764523169],
              [-34.935334151370505, -7.931860342023327],
              [-34.935559654414796, -7.931860806186606],
              [-34.935672223348369, -7.931906092711768],
              [-34.935739874255333, -7.931906231915009],
              [-34.935942461867889, -7.931996758432203],
              [-34.936032389283618, -7.932064525705453],
              [-34.936054757053519, -7.932109626562238],
              [-34.936099766405277, -7.932132246560768],
              [-34.936122134196196, -7.932177347420172],
              [-34.936167143558151, -7.932199967408647],
              [-34.936211970414824, -7.93226764187486],
              [-34.936301897930264, -7.932335409055106],
              [-34.936331071713539, -7.932394232934705],
              [-34.936451887209806, -7.932393762870114],
              [-34.936431275760548, -7.931702468800697],
              [-34.936429941099327, -7.931533962977572],
              [-34.936424054284373, -7.930790704491151],
              [-34.936408661602165, -7.929605947282745],
              [-34.936387966696564, -7.928461253938941],
              [-34.936371393241693, -7.927518760694849],
              [-34.936377920846859, -7.927241002776507],
              [-34.936592127214183, -7.92710333494053],
              [-34.937455764950556, -7.926553715431551],
              [-34.937417465815926, -7.926086865207949],
              [-34.936714727041718, -7.925879045199658],
              [-34.936496421769441, -7.925816797839386],
              [-34.936490993026872, -7.925471779745826],
              [-34.936485794617617, -7.925151897595757],
              [-34.936297948001112, -7.925072107241401],
              [-34.936143099578615, -7.925004450172418],
              [-34.93614313881006, -7.924927048968282],
              [-34.936143146253777, -7.924912363760121],
              [-34.936192236212015, -7.924044179343115],
              [-34.936020609631356, -7.923974652414981],
              [-34.93583894028648, -7.923912348012947],
              [-34.935733697436568, -7.923878392325733],
              [-34.935562091081536, -7.923813170653755],
              [-34.935337157038866, -7.923733844655401],
              [-34.93514751932635, -7.923655534380216],
              [-34.934851400994532, -7.923582339121015],
              [-34.934662635193732, -7.923535679394446],
              [-34.934522680101978, -7.923501084787521],
              [-34.934355960896816, -7.923459874457008],
              [-34.934231933525389, -7.92342921681601],
              [-34.934060500599081, -7.923385024450445],
              [-34.933967385308947, -7.923371399631691],
              [-34.933934375196699, -7.923365407168106],
              [-34.93384619447481, -7.923349399339004],
              [-34.93378598003477, -7.923338468338441],
              [-34.933716280542413, -7.923342905982171],
              [-34.933508675858, -7.923307044846869],
              [-34.933317734765353, -7.923282563407271],
              [-34.933118417237395, -7.923242824216979],
              [-34.932891450257628, -7.923211292550652],
              [-34.932581430766945, -7.923169891362541],
              [-34.932455557022891, -7.923154946019827],
              [-34.932190631268519, -7.923147551155442],
              [-34.932155897387467, -7.923146824338467],
              [-34.932004863718539, -7.923171444458131],
              [-34.931895691310899, -7.923203004556491],
              [-34.931780814105679, -7.923263815251449],
              [-34.931714464781884, -7.923305239378885],
              [-34.931660267728788, -7.923331094093646],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2060',
        NOME_UC1:
          'PARQUE NATURAL MUNICIPAL PROFESSOR JOÃO VASCONCELOS SOBRINHO',
        ID_WCMC2: null,
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'municipal',
        ANO_CRIA6: '1983',
        GID7: '13708',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 2796 de 07/07/1983',
        DT_ULTIM10: '13/03/2012',
        CODIGO_U11: '0410.26.2060',
        NOME_ORG12: 'Prefeitura Municipal de Caruaru - PE',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.027646382793122, -8.357135562568219],
              [-36.027572820687645, -8.357152566660154],
              [-36.027222296835873, -8.35725692189766],
              [-36.027096545654516, -8.357510879593832],
              [-36.026064774367413, -8.358430888947423],
              [-36.025664144175323, -8.358797647224304],
              [-36.025385959602112, -8.358911885814843],
              [-36.025046911986259, -8.358864776591901],
              [-36.023412705029635, -8.358136881439327],
              [-36.02357687651638, -8.358829257074643],
              [-36.02376374338877, -8.359531903718747],
              [-36.023359993935493, -8.359475916080124],
              [-36.023026601043647, -8.35974924128961],
              [-36.023015977904123, -8.359797716618523],
              [-36.022691109712817, -8.360161927512017],
              [-36.02206761110471, -8.360245000337519],
              [-36.021416652921978, -8.360937948247289],
              [-36.021167044247136, -8.361256110270444],
              [-36.020967680152644, -8.361792717755911],
              [-36.020733754795529, -8.361992854841068],
              [-36.021094675243134, -8.363251069778935],
              [-36.02168735833969, -8.364530581860976],
              [-36.021908834455765, -8.366447736081875],
              [-36.021444658903832, -8.369173920924913],
              [-36.021818880484403, -8.37381611905699],
              [-36.022943163003099, -8.374971059981595],
              [-36.024266012793099, -8.375609081335813],
              [-36.025515217477391, -8.376413384289204],
              [-36.026492108238244, -8.376983381265122],
              [-36.030727539011131, -8.375109665608894],
              [-36.030780685786866, -8.375097615092587],
              [-36.033882012427938, -8.373345663525519],
              [-36.033759100640246, -8.373058952734247],
              [-36.033842646095579, -8.372893624777621],
              [-36.034018561260083, -8.372601509932498],
              [-36.034184087373838, -8.372103210651401],
              [-36.034168288228109, -8.370774545791141],
              [-36.034090371137452, -8.370335014293731],
              [-36.034192845173713, -8.369537201779064],
              [-36.034375786262558, -8.369284374099939],
              [-36.034712377628146, -8.368908569752705],
              [-36.035504101327028, -8.368091323169162],
              [-36.0355509277097, -8.367750712523208],
              [-36.036527527901285, -8.367791006263127],
              [-36.036806918828795, -8.367798332185094],
              [-36.037768809981991, -8.367690752724654],
              [-36.03782351685301, -8.36769424132655],
              [-36.038789731549159, -8.367696087052124],
              [-36.039076728748043, -8.367373339049795],
              [-36.039090059036909, -8.366990380420711],
              [-36.041030846017975, -8.364932620330499],
              [-36.041745007374139, -8.364903162040848],
              [-36.04225889574942, -8.364915033416747],
              [-36.042336273537167, -8.364722179954406],
              [-36.04261985965374, -8.36427191040193],
              [-36.042552863022259, -8.363549108417148],
              [-36.042410944351523, -8.361595586144759],
              [-36.043258018909611, -8.359587334750344],
              [-36.043214372410915, -8.359247016716646],
              [-36.043079260991874, -8.358691608907943],
              [-36.042784948641362, -8.358252912662907],
              [-36.042510637384723, -8.358121165913026],
              [-36.040097660564143, -8.35775021708721],
              [-36.039810938451993, -8.357991085336494],
              [-36.039057569460397, -8.357809052246237],
              [-36.038551247614492, -8.359076495933826],
              [-36.038377140505233, -8.359839046567185],
              [-36.038103295116215, -8.360289466400388],
              [-36.037709419090078, -8.360694469915325],
              [-36.037618855086542, -8.360683927551717],
              [-36.037434409170366, -8.36092733272398],
              [-36.037084107897542, -8.36131494569743],
              [-36.037040006771221, -8.36135717257328],
              [-36.035524720780693, -8.360344598245607],
              [-36.033887138506408, -8.359169822898259],
              [-36.033873448921931, -8.359093725543831],
              [-36.033251768370029, -8.358649410602174],
              [-36.033190872072453, -8.358621855160878],
              [-36.033390543133805, -8.358209077176426],
              [-36.033105195725547, -8.357002083655617],
              [-36.032507647259926, -8.357049821730506],
              [-36.031685305916547, -8.357154285498565],
              [-36.031577025635507, -8.357124312374848],
              [-36.031386176665876, -8.356843840486865],
              [-36.030340756481294, -8.356400313587077],
              [-36.02992302081649, -8.3559422077285],
              [-36.029851245573226, -8.355082682029817],
              [-36.028135891091026, -8.3546849798638],
              [-36.027661869885009, -8.355347304911451],
              [-36.027440849502419, -8.355916465415589],
              [-36.027646382793122, -8.357135562568219],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3314',
        NOME_UC1: 'ESTAÇÃO ECOLÓGICA BITA E UTINGA',
        ID_WCMC2: null,
        CATEGORI3: 'Estação Ecológica',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2012',
        GID7: '12286',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 38.261/2012 de 05/06/2012',
        DT_ULTIM10: '15/03/2016',
        CODIGO_U11: '0000.26.3314',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.061210591890394, -8.38216968299572],
              [-35.06388111845677, -8.382771959205215],
              [-35.0648950521636, -8.383723028025514],
              [-35.070392944035021, -8.380999048032219],
              [-35.082824698498662, -8.380333360310786],
              [-35.08786246594461, -8.380143509194548],
              [-35.08817661800213, -8.376892839670148],
              [-35.088090479070132, -8.374970629578833],
              [-35.088044640170523, -8.373947692916449],
              [-35.087785225396239, -8.368158266787756],
              [-35.08614698216045, -8.36747316585358],
              [-35.084169644044884, -8.367352088157313],
              [-35.078804701431203, -8.367023526268978],
              [-35.07716868097711, -8.366612647628997],
              [-35.076226033205927, -8.366375902412742],
              [-35.074800243139954, -8.366017811598322],
              [-35.073222857236701, -8.365621639910149],
              [-35.071966799381009, -8.367319177412069],
              [-35.070328594007982, -8.368174443881644],
              [-35.070318505478504, -8.368992475777342],
              [-35.070007635943959, -8.370156497296724],
              [-35.069779722638081, -8.371523865610106],
              [-35.069339634645353, -8.371910761876737],
              [-35.068423181422922, -8.372020069523295],
              [-35.06814324678232, -8.37301432312332],
              [-35.067334557571151, -8.373370145313011],
              [-35.066531499538229, -8.373428163362266],
              [-35.066158891038697, -8.37381757766264],
              [-35.065512679369895, -8.373148666356116],
              [-35.064042446766528, -8.37396871416794],
              [-35.06303561503784, -8.374325564205785],
              [-35.062033754789944, -8.373982466608375],
              [-35.06113663777181, -8.373621009202353],
              [-35.060746307297016, -8.372804793001812],
              [-35.061111542158962, -8.371999695286348],
              [-35.062286320654117, -8.371610880570792],
              [-35.062463230781844, -8.371000056506659],
              [-35.062929277614046, -8.370567105546238],
              [-35.063616192130766, -8.371029911166312],
              [-35.063562925938463, -8.370476853573365],
              [-35.064418904382755, -8.370678911942179],
              [-35.064655977042776, -8.370091690775041],
              [-35.065021293282989, -8.3687743132517],
              [-35.065403152762649, -8.368317882375841],
              [-35.065441761111295, -8.368807991350218],
              [-35.066081209903679, -8.369378671263206],
              [-35.067397859647677, -8.368570545264285],
              [-35.068019306414925, -8.368005230242476],
              [-35.068732271134301, -8.366578403655947],
              [-35.068873714447676, -8.366075393894036],
              [-35.068473605979705, -8.365348002133274],
              [-35.068398459077493, -8.364623650591865],
              [-35.061355935905389, -8.364458458459382],
              [-35.061340799750653, -8.36482111018824],
              [-35.060599956557297, -8.365819724421826],
              [-35.054651183630291, -8.367783249824416],
              [-35.052939051421959, -8.365108927237161],
              [-35.058318411907891, -8.360033988809327],
              [-35.060682156883821, -8.359130822169973],
              [-35.06147007850592, -8.358428025127431],
              [-35.068974975022599, -8.354796264259079],
              [-35.088819129627915, -8.345192392640048],
              [-35.088822510094914, -8.343169415250436],
              [-35.092918354176057, -8.343544777907653],
              [-35.090101369998692, -8.341174297620601],
              [-35.088304485691786, -8.339478944873333],
              [-35.087027916653497, -8.336207594331357],
              [-35.094274210311049, -8.336386778511509],
              [-35.097971330262567, -8.33556137435891],
              [-35.097971370216897, -8.327478326742442],
              [-35.083061925081957, -8.321595918534618],
              [-35.081243290099273, -8.322396737213962],
              [-35.079720144940651, -8.319070990842844],
              [-35.077396778405188, -8.317599809323854],
              [-35.07565120164729, -8.315991857080137],
              [-35.070241340048987, -8.316096003487297],
              [-35.06864151827115, -8.319836385990399],
              [-35.068148777561497, -8.321736364780454],
              [-35.06634743786384, -8.321976991965913],
              [-35.066468349539235, -8.32254799449267],
              [-35.065201248231375, -8.323621909870292],
              [-35.063225067750615, -8.323987683147463],
              [-35.060946871427213, -8.328572500889594],
              [-35.059524428124213, -8.329861167814309],
              [-35.058934163264709, -8.33023744603673],
              [-35.058997409407141, -8.33211847535153],
              [-35.057761903483708, -8.332349275010204],
              [-35.057689488578504, -8.332956771168636],
              [-35.056450355783717, -8.334408371210996],
              [-35.05480709237159, -8.335373670991],
              [-35.053849362086382, -8.334630823924444],
              [-35.054471066611413, -8.337105457945503],
              [-35.054480182628907, -8.338346980209804],
              [-35.054812997035349, -8.33984404511723],
              [-35.051622273853873, -8.341286389791515],
              [-35.050240516827699, -8.341786263664677],
              [-35.049660798774681, -8.342318490922045],
              [-35.048896793351716, -8.3425105001664],
              [-35.047235783898223, -8.343713234452277],
              [-35.045965433441957, -8.345022904356687],
              [-35.046960751243525, -8.34739490929301],
              [-35.046849565722098, -8.347874645220317],
              [-35.045654004341792, -8.351033894646896],
              [-35.045185091084747, -8.352073966818892],
              [-35.037595986474862, -8.358066004905309],
              [-35.0375960294745, -8.358066060905026],
              [-35.041475983716104, -8.363095280391111],
              [-35.041718909249482, -8.365326612659361],
              [-35.042243550864619, -8.368652563311722],
              [-35.04227969835101, -8.370334441854951],
              [-35.043816348901252, -8.371614623002383],
              [-35.044456083058378, -8.373039962587992],
              [-35.045007883283056, -8.375263752133504],
              [-35.049078158105374, -8.377573343726954],
              [-35.051571481506329, -8.378837170681571],
              [-35.053253465132812, -8.37989543732227],
              [-35.053576296096168, -8.380733222339295],
              [-35.054221895293374, -8.380693162062878],
              [-35.054428161308131, -8.381289219500536],
              [-35.056464067156725, -8.381170930732381],
              [-35.058185071939995, -8.382973856763787],
              [-35.061210591890394, -8.38216968299572],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3302',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO QUIZANGA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12245',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '11/03/2016',
        CODIGO_U11: '0000.26.3302',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.06069150595598, -7.947649586016198],
              [-35.060326987659906, -7.947680095570503],
              [-35.059943515472014, -7.947758534810204],
              [-35.059704083194283, -7.947855394034441],
              [-35.059560615199374, -7.947951778328538],
              [-35.059484607660089, -7.94810523131081],
              [-35.059437579877418, -7.948296809073592],
              [-35.0594290807867, -7.948516897684008],
              [-35.059430274610101, -7.948756073181187],
              [-35.059431276461751, -7.948956980918796],
              [-35.059432899221584, -7.949282258874913],
              [-35.059434235023822, -7.94955013519174],
              [-35.059425640947183, -7.949751089922406],
              [-35.059416712919997, -7.949885076073868],
              [-35.059369828116083, -7.950105355656347],
              [-35.059303035561605, -7.950182224126555],
              [-35.059207166264635, -7.950201833936449],
              [-35.059101511067631, -7.95018322397984],
              [-35.058995329948331, -7.950059375684422],
              [-35.058927248584588, -7.949877935777669],
              [-35.058906718925847, -7.949610154446892],
              [-35.058838208625652, -7.949342611081127],
              [-35.058751030391136, -7.949180399040252],
              [-35.058635730272925, -7.949152269136737],
              [-35.058511215169958, -7.949200722745383],
              [-35.058406704803424, -7.94941172034603],
              [-35.0582831915521, -7.949661080692247],
              [-35.058159678300768, -7.949910441038516],
              [-35.057968894565555, -7.950141000456054],
              [-35.057682241533449, -7.950391169683539],
              [-35.057433735249745, -7.950593313239538],
              [-35.057213541821561, -7.950699644417644],
              [-35.057031352163058, -7.950729248104538],
              [-35.056858664446921, -7.950739670918669],
              [-35.056647113088331, -7.95065461330604],
              [-35.056445111665049, -7.95055994076051],
              [-35.056233417327626, -7.950446181328168],
              [-35.056031224932624, -7.950313241023077],
              [-35.055848797309423, -7.950295009010595],
              [-35.055570500389301, -7.950296386808062],
              [-35.055225171950013, -7.950326797376215],
              [-35.054908536309767, -7.950337931085635],
              [-35.054639979296745, -7.950367961709705],
              [-35.054342536513033, -7.950379000433079],
              [-35.054112507191959, -7.950437541904726],
              [-35.053930603491054, -7.95052454723097],
              [-35.053739673777201, -7.950726403828858],
              [-35.053596678711735, -7.950918457521714],
              [-35.053348264414083, -7.951139732957467],
              [-35.053214675305156, -7.951293469897812],
              [-35.053013910698326, -7.951447538789277],
              [-35.052755519528858, -7.951592325698933],
              [-35.052535133129041, -7.951660386117435],
              [-35.052353132442363, -7.951728256563904],
              [-35.052190610569795, -7.951853434663653],
              [-35.052066474410381, -7.951978422791303],
              [-35.051999869827711, -7.952093559021034],
              [-35.052010322628789, -7.952265718945954],
              [-35.05210738174911, -7.952485287633098],
              [-35.05220429889058, -7.952676153500672],
              [-35.052253375369453, -7.952895959152958],
              [-35.052254327228191, -7.953087299950657],
              [-35.052226441309131, -7.953269216787526],
              [-35.05214045389809, -7.953346180243749],
              [-35.052006149895163, -7.953356411085798],
              [-35.051862058992242, -7.953328421161366],
              [-35.051688750368037, -7.953214469757],
              [-35.051553400520547, -7.953014225921578],
              [-35.051398476873004, -7.952737539553177],
              [-35.051272722953776, -7.952537247724764],
              [-35.051157326849783, -7.952489980941566],
              [-35.051071148467059, -7.952528676638212],
              [-35.050985635985434, -7.952701310493258],
              [-35.050909909403792, -7.952912165114785],
              [-35.050833849871665, -7.953056050157146],
              [-35.050738455504124, -7.953171328365895],
              [-35.050537355946766, -7.953258427678173],
              [-35.050345377388332, -7.953249808598544],
              [-35.050143754908703, -7.95323166957211],
              [-35.049971255164493, -7.95328035714578],
              [-35.049837283112133, -7.953357557567051],
              [-35.049655281425466, -7.953425427013459],
              [-35.049492187637746, -7.953435799834589],
              [-35.049251989473277, -7.953379581020664],
              [-35.04909825364917, -7.953342071149388],
              [-35.048925515940248, -7.953342923023619],
              [-35.048733870332391, -7.953401273523207],
              [-35.048513671904423, -7.953507599701218],
              [-35.048293615455293, -7.953642627698939],
              [-35.048035268278873, -7.953796979548414],
              [-35.047824618786272, -7.95389369179356],
              [-35.047576057509914, -7.954086264409614],
              [-35.047327305261852, -7.954240568266067],
              [-35.047097604891476, -7.954366076316862],
              [-35.046973323753306, -7.954462362624885],
              [-35.04691617112011, -7.954548750041911],
              [-35.046926289970699, -7.954653940387596],
              [-35.046993940397947, -7.954749279835356],
              [-35.047176513999759, -7.954796216667488],
              [-35.047368350579291, -7.954776135927443],
              [-35.047627221677942, -7.954727022416685],
              [-35.047847610077689, -7.954658963998186],
              [-35.04804899659257, -7.954629268325238],
              [-35.048355802339678, -7.954570350909524],
              [-35.048490107342495, -7.954560121067407],
              [-35.048615005388854, -7.954588205977882],
              [-35.04874968933526, -7.954654512654939],
              [-35.048932594887603, -7.954768418066266],
              [-35.049134883268287, -7.954920496251179],
              [-35.049424254897467, -7.955215653597199],
              [-35.049655524034655, -7.955405857562641],
              [-35.049818998765844, -7.955472022260592],
              [-35.049943753833396, -7.955471405351483],
              [-35.050010597380762, -7.955404104821183],
              [-35.050038674271605, -7.955260456743862],
              [-35.050056869276531, -7.955059453020225],
              [-35.050113639966305, -7.954896529084049],
              [-35.050189842477266, -7.954781344861351],
              [-35.050352318356857, -7.954646599821723],
              [-35.050476741473851, -7.954579014333354],
              [-35.050591709641445, -7.954540177657679],
              [-35.050744779564489, -7.954443748370632],
              [-35.050926734258226, -7.954366310984296],
              [-35.051195247278145, -7.954326714420388],
              [-35.051387368815419, -7.954364034319666],
              [-35.051579728317286, -7.954449189918392],
              [-35.051801258547499, -7.954610739057156],
              [-35.051965256200994, -7.954782139093902],
              [-35.052129016889801, -7.954905704431246],
              [-35.052263653843276, -7.9549624431684],
              [-35.052407697753289, -7.954980865152981],
              [-35.052580293483409, -7.954951309459148],
              [-35.052714217542878, -7.954864542097972],
              [-35.052819302824602, -7.954768349775969],
              [-35.05290481530627, -7.954595715920869],
              [-35.052980444902104, -7.954365726419498],
              [-35.05300794987771, -7.954107274063497],
              [-35.05300676005438, -7.953868097566304],
              [-35.053054123787575, -7.953743488382711],
              [-35.05311113144203, -7.953628399145983],
              [-35.053206953746027, -7.953599223396198],
              [-35.053293180121706, -7.953570095639423],
              [-35.053369666590321, -7.95351231305601],
              [-35.053388335524588, -7.953406980731211],
              [-35.05334909293866, -7.953234963785305],
              [-35.053242197925464, -7.952967608391648],
              [-35.053173927589981, -7.952747898725367],
              [-35.053201955487886, -7.952594683708158],
              [-35.05328775292714, -7.952479451492379],
              [-35.053431462886643, -7.952430904897795],
              [-35.053719262749098, -7.952410347227588],
              [-35.053958936991357, -7.952361324702788],
              [-35.054131388742753, -7.952303068189251],
              [-35.054265310802251, -7.95221629982808],
              [-35.054341511313211, -7.952101115605362],
              [-35.054417235894846, -7.95189026098374],
              [-35.054511581417856, -7.951564507097493],
              [-35.054635287640899, -7.951353414510827],
              [-35.054778615656936, -7.95122833139711],
              [-35.054912632702269, -7.951160696915719],
              [-35.055104514274944, -7.951150180115522],
              [-35.055315731682953, -7.951168269148956],
              [-35.05554647589809, -7.951253232775638],
              [-35.055748525314236, -7.951357472261077],
              [-35.055845300476946, -7.951519637308995],
              [-35.055884401084079, -7.951662952435234],
              [-35.055866161086243, -7.951854388219008],
              [-35.055789865589382, -7.951950438562004],
              [-35.05568482930056, -7.952056198823893],
              [-35.055646967509723, -7.952161626134687],
              [-35.055676471188583, -7.952304989253951],
              [-35.055686640032079, -7.952419746539583],
              [-35.055667971097812, -7.952525078864395],
              [-35.055562695844344, -7.952583003426884],
              [-35.055399793028243, -7.952631646007505],
              [-35.055246869083909, -7.952756778114285],
              [-35.055132615810344, -7.952939120888305],
              [-35.05507599009934, -7.95313074664413],
              [-35.055038557244913, -7.953322277413916],
              [-35.055049439982419, -7.953580540797889],
              [-35.055002315213798, -7.953752984680922],
              [-35.054868535133117, -7.953868453861785],
              [-35.054687011375684, -7.954031995707108],
              [-35.054591712994011, -7.954166409795658],
              [-35.054554232146579, -7.954348373625615],
              [-35.05460321463957, -7.954549043398248],
              [-35.054728922565872, -7.954739767286863],
              [-35.054883516262798, -7.954949482076231],
              [-35.055076161722354, -7.955092037314377],
              [-35.055220396604156, -7.955148727058565],
              [-35.055354748600017, -7.955148061156411],
              [-35.055536892265565, -7.955108890529668],
              [-35.055680507239231, -7.955041208055278],
              [-35.055881318839099, -7.954896705103636],
              [-35.056015146912799, -7.954790801862736],
              [-35.05610041842985, -7.954570332308116],
              [-35.056233959545906, -7.95440702742783],
              [-35.056406029353845, -7.954272233395155],
              [-35.056558954298239, -7.954147101288413],
              [-35.056644559765815, -7.953993601313062],
              [-35.05666265578494, -7.953773464709528],
              [-35.056680370860526, -7.953476791586965],
              [-35.056698466879638, -7.953256654983447],
              [-35.056745400676519, -7.953045942340833],
              [-35.056811622315792, -7.952854269591953],
              [-35.05691637364702, -7.952691106690692],
              [-35.057050151727751, -7.952575636509893],
              [-35.057193860687271, -7.952527088915261],
              [-35.057328306669064, -7.95254555689287],
              [-35.057472635536769, -7.952621379516777],
              [-35.057588795527742, -7.952821716338323],
              [-35.057657449806641, -7.953117961523816],
              [-35.057668620501701, -7.95343362554719],
              [-35.057631760562465, -7.953739960595726],
              [-35.057537084088786, -7.953998745902902],
              [-35.057403447986871, -7.954142916903402],
              [-35.057222116201125, -7.954344728508316],
              [-35.057089053014352, -7.954603703787582],
              [-35.05702330830438, -7.954891046935414],
              [-35.056957801559044, -7.955226225782672],
              [-35.056892199827814, -7.955542270750203],
              [-35.056806785331922, -7.955734039485156],
              [-35.056663981238081, -7.955964360937718],
              [-35.05656873084925, -7.956108341966205],
              [-35.056406352955385, -7.956262222885662],
              [-35.056186060541314, -7.95634942018393],
              [-35.055946337306104, -7.956388876768699],
              [-35.055658487450692, -7.956399869498929],
              [-35.055207256849883, -7.956363835410809],
              [-35.054928764958127, -7.956326945448478],
              [-35.054717735521898, -7.956347124174528],
              [-35.054430314602861, -7.95644422036372],
              [-35.054258147809037, -7.956559879516606],
              [-35.054086789895095, -7.95683817864762],
              [-35.053905932038688, -7.957135659651445],
              [-35.053849733264052, -7.957413388866409],
              [-35.053745266890374, -7.957633953407028],
              [-35.053630869637956, -7.957787596361382],
              [-35.053468584730034, -7.95796061016063],
              [-35.053277033107932, -7.958038096539948],
              [-35.053133607106084, -7.958144045773875],
              [-35.053086100393969, -7.95823995413788],
              [-35.053096220244491, -7.958345144483677],
              [-35.053173422607053, -7.958430867998619],
              [-35.053308156546365, -7.958506739615668],
              [-35.053605891287525, -7.958553102531842],
              [-35.053912888006529, -7.95853244787583],
              [-35.054172284027516, -7.958588567703924],
              [-35.054432155977842, -7.958740356931033],
              [-35.054692170906968, -7.958920847977844],
              [-35.054884533408838, -7.959006000576735],
              [-35.055105541716806, -7.959062309376936],
              [-35.055345457923707, -7.959061121551747],
              [-35.055537248510461, -7.959031468871908],
              [-35.055738587032565, -7.958992202259194],
              [-35.055949379504163, -7.958924186833761],
              [-35.05614059617578, -7.958779730875138],
              [-35.056465736906944, -7.958548504555427],
              [-35.056809831530082, -7.958269348550158],
              [-35.057163284116783, -7.957942309852417],
              [-35.057373406687404, -7.957740355268426],
              [-35.057488136890441, -7.957653680893232],
              [-35.057612892958055, -7.957653062984124],
              [-35.057785965617562, -7.957719175689258],
              [-35.057911246607411, -7.95782379411903],
              [-35.057950397207399, -7.957976676185339],
              [-35.057960947994275, -7.958167967990271],
              [-35.057913920211448, -7.958359546753277],
              [-35.057789832044811, -7.958494103820886],
              [-35.057579231544842, -7.958600388005924],
              [-35.057407351708555, -7.95877345079822],
              [-35.057350631011559, -7.958945942674392],
              [-35.057332393013567, -7.959137378458364],
              [-35.057409929326681, -7.959290070552631],
              [-35.057602673772017, -7.959451757670818],
              [-35.057794989280971, -7.959527342329868],
              [-35.058035239438468, -7.959593121084016],
              [-35.058466993225188, -7.959571844518915],
              [-35.059062079764381, -7.959588025832386],
              [-35.059321284813798, -7.959605873901],
              [-35.059455924767271, -7.959662608638314],
              [-35.059571609828836, -7.959767274061173],
              [-35.059639598206559, -7.959929580088423],
              [-35.05969842159233, -7.960178035567811],
              [-35.059796347585099, -7.960569807173745],
              [-35.059874745770777, -7.960894704186328],
              [-35.060011155462512, -7.961305417700103],
              [-35.060205383688491, -7.961763680955323],
              [-35.060369962257035, -7.962049880271428],
              [-35.060554068632534, -7.96240295318079],
              [-35.060747532971682, -7.962708143397677],
              [-35.060950260288671, -7.962946317042305],
              [-35.061085666128882, -7.963156122818181],
              [-35.061192235191356, -7.963356505633163],
              [-35.061202740985188, -7.963538230498381],
              [-35.061194340879943, -7.963777452989089],
              [-35.061214730559541, -7.964016532500717],
              [-35.061302201751282, -7.964236144181592],
              [-35.061428347613656, -7.964512966529928],
              [-35.061554157525613, -7.964722820298875],
              [-35.061641581724359, -7.964932865039863],
              [-35.061738745830183, -7.965171562607607],
              [-35.061874057684577, -7.965362234503756],
              [-35.062057162208653, -7.96551439867535],
              [-35.062220737925685, -7.965599690253542],
              [-35.06239357562059, -7.96561796525937],
              [-35.062528074595278, -7.965645997177105],
              [-35.06263397375681, -7.965712440833457],
              [-35.062663434442534, -7.965846236013197],
              [-35.062664488286757, -7.966056709691252],
              [-35.062569813812772, -7.96631549599884],
              [-35.062446684504408, -7.966641393864814],
              [-35.062371538837375, -7.966967053765819],
              [-35.062372640674504, -7.967187094383767],
              [-35.062459969887392, -7.967378004245032],
              [-35.06256587004895, -7.967444447901472],
              [-35.062671482252917, -7.967453488918412],
              [-35.06279590537018, -7.967385899430037],
              [-35.062910396608657, -7.967251388355294],
              [-35.063024263939248, -7.966992507061666],
              [-35.063127193539771, -7.966465797443517],
              [-35.063192071377657, -7.966006247376784],
              [-35.06322791846604, -7.965499006589497],
              [-35.06326434146942, -7.965106569081181],
              [-35.063340205030194, -7.964924414278843],
              [-35.063474320061559, -7.964875910677344],
              [-35.063647060770613, -7.964875050803351],
              [-35.063858285178696, -7.964893133837163],
              [-35.064127137149391, -7.96492049785291],
              [-35.064261539138286, -7.964929395890803],
              [-35.064376556298889, -7.964900121155109],
              [-35.064414464082851, -7.964804259783977],
              [-35.064394120396187, -7.964574748212153],
              [-35.064383085680277, -7.96428778700783],
              [-35.06442032156329, -7.964057988477985],
              [-35.064467490325079, -7.963895110534502],
              [-35.06450506215856, -7.963732280584026],
              [-35.064600214561743, -7.963569164675556],
              [-35.064733705685114, -7.963396289855214],
              [-35.06485764787314, -7.963233029967735],
              [-35.064933223476352, -7.962993473525964],
              [-35.064960910423999, -7.962773289929065],
              [-35.065017676114032, -7.962610364992633],
              [-35.065103662525253, -7.962533398536316],
              [-35.065237967528326, -7.962523162694407],
              [-35.065334125782819, -7.962560952524055],
              [-35.065421167038309, -7.962694460745731],
              [-35.065508640230064, -7.962914070426676],
              [-35.065634308163261, -7.963095221375929],
              [-35.065730562403665, -7.963152145085363],
              [-35.065874512327937, -7.963151428190374],
              [-35.06598909755229, -7.963036050995473],
              [-35.066122443696969, -7.962834475355311],
              [-35.066207231285105, -7.962518334401324],
              [-35.06637858019954, -7.962240032269918],
              [-35.066550312057323, -7.962038265657785],
              [-35.066741333757541, -7.961855536939478],
              [-35.067018916784015, -7.961710645043771],
              [-35.067267949990203, -7.961613731826662],
              [-35.067584350666152, -7.96155475141816],
              [-35.067891299392613, -7.961524519822416],
              [-35.068063798137139, -7.961475824248819],
              [-35.068168929412046, -7.961389194866618],
              [-35.068254770844476, -7.961283527590594],
              [-35.06828245679214, -7.961063343993676],
              [-35.068252179226675, -7.960766911835485],
              [-35.068182507096694, -7.960269764911239],
              [-35.068112930952474, -7.959791752866839],
              [-35.068150117842563, -7.959552387397129],
              [-35.068216381475033, -7.959370280587876],
              [-35.068311867828626, -7.959274132258772],
              [-35.068474673659111, -7.959206349798348],
              [-35.068666221281461, -7.959128857419027],
              [-35.068809497304876, -7.958994202365278],
              [-35.068914195643437, -7.958821470523908],
              [-35.068961266419301, -7.958639459700668],
              [-35.068950373682085, -7.95838120031618],
              [-35.068891738268, -7.958171013596223],
              [-35.068784689276143, -7.95787496438221],
              [-35.068630232557929, -7.957693958412028],
              [-35.068428129148728, -7.957580158986305],
              [-35.068255247460741, -7.957552319040645],
              [-35.068025123153532, -7.957591735632359],
              [-35.067737754227039, -7.957698406761469],
              [-35.067546207604643, -7.957775898140812],
              [-35.067325822204538, -7.957843966559262],
              [-35.067105053860978, -7.957835499458477],
              [-35.066912787344712, -7.95776948673921],
              [-35.06668175117192, -7.957627129472761],
              [-35.066383634486996, -7.957504240037181],
              [-35.06605696898179, -7.957429329280179],
              [-35.065682559799775, -7.957402491187695],
              [-35.065308773525885, -7.957500024313896],
              [-35.065155611616724, -7.957577323721255],
              [-35.064983687787297, -7.957740822573682],
              [-35.064802646958874, -7.958000037818167],
              [-35.064669587771888, -7.958259015097577],
              [-35.064459661172748, -7.958499240441265],
              [-35.064287545371677, -7.958624470534124],
              [-35.064038800123072, -7.958778783390686],
              [-35.063856991407839, -7.958884926596687],
              [-35.063781032861336, -7.959047947519112],
              [-35.06377234579859, -7.959229768370279],
              [-35.063744657851032, -7.95944995196706],
              [-35.063687557210585, -7.959545908324109],
              [-35.063601618792326, -7.959632440720283],
              [-35.063400233277193, -7.959662144393065],
              [-35.063121739385245, -7.959625260430587],
              [-35.062939499733695, -7.959645301177506],
              [-35.062671464642698, -7.959780575140265],
              [-35.062394263559767, -7.960001999555158],
              [-35.062222241744557, -7.960146362527778],
              [-35.06204993297181, -7.960233324860982],
              [-35.061886886176858, -7.960253269621879],
              [-35.061809777800107, -7.960186681986615],
              [-35.061770339242649, -7.959976399280725],
              [-35.061759211540689, -7.959670303196904],
              [-35.061786757509488, -7.959421417780386],
              [-35.061881574962072, -7.959191333292706],
              [-35.06203406897022, -7.958980095726779],
              [-35.062340154823843, -7.958777663212713],
              [-35.062656124563311, -7.958632583344986],
              [-35.06289570481993, -7.958564421940544],
              [-35.063125640155299, -7.958486740589221],
              [-35.063278707078616, -7.958390307302114],
              [-35.063498710535278, -7.958245705364431],
              [-35.063651249536377, -7.958044034738403],
              [-35.063832004407153, -7.95772741785455],
              [-35.06399327946388, -7.957353494317288],
              [-35.064106809843793, -7.95702764344459],
              [-35.06413397186909, -7.956702223508941],
              [-35.06411329423176, -7.95640574235805],
              [-35.064035468961052, -7.956195650624291],
              [-35.063929092870126, -7.956033536569111],
              [-35.063755829238765, -7.95592915910435],
              [-35.063448453576058, -7.955873283241228],
              [-35.063179846569938, -7.955893753925207],
              [-35.062892380657743, -7.955981287174515],
              [-35.062624347566796, -7.956116561137298],
              [-35.062346765540475, -7.956261449033007],
              [-35.062126332147493, -7.956319947511609],
              [-35.061953929388864, -7.956387774965028],
              [-35.061695396240353, -7.956503866055002],
              [-35.061465604883807, -7.956610247226076],
              [-35.061292963160518, -7.956630238979986],
              [-35.061139035364604, -7.956554465348949],
              [-35.061032756259536, -7.956411484173575],
              [-35.061003058609039, -7.956229854294524],
              [-35.060992744786809, -7.956086397189101],
              [-35.061040010534249, -7.955942654125593],
              [-35.061048794582767, -7.95577996715436],
              [-35.061019478875707, -7.955674872794488],
              [-35.060961182412051, -7.955531653654113],
              [-35.060816661572616, -7.955417565270525],
              [-35.06061451317057, -7.955294193905056],
              [-35.060441440510999, -7.955228083199922],
              [-35.060297636565551, -7.955257497914777],
              [-35.060182860369579, -7.955334606350003],
              [-35.060059013167589, -7.955516998117182],
              [-35.059924996122163, -7.955584633598562],
              [-35.059809789989821, -7.955575638574797],
              [-35.059703654863561, -7.955461358219123],
              [-35.059702746997878, -7.95527958536112],
              [-35.05974012985947, -7.955078487651315],
              [-35.059816185391782, -7.954934601608789],
              [-35.060045928755414, -7.954818654497851],
              [-35.06021799756342, -7.954683859465154],
              [-35.060322268965386, -7.95442502616489],
              [-35.060330910035063, -7.95423363837399],
              [-35.060282019527953, -7.954052103481048],
              [-35.060127615802664, -7.953880659451154],
              [-35.059944661257177, -7.953757193099764],
              [-35.059771542604622, -7.953681513454808],
              [-35.059560324196553, -7.953663427421247],
              [-35.059339270895528, -7.953597552681233],
              [-35.059156555314679, -7.953521921029359],
              [-35.05895459888437, -7.953436817423515],
              [-35.058820247888448, -7.953437483325679],
              [-35.058676538928857, -7.953486032920332],
              [-35.058523186048106, -7.953525061568076],
              [-35.058408027908719, -7.953525633484181],
              [-35.05831172867537, -7.95345913983505],
              [-35.058243838283389, -7.953315967687794],
              [-35.058224024518722, -7.95319169145524],
              [-35.058232905552991, -7.953048138363838],
              [-35.058260978443847, -7.952904490286452],
              [-35.058346965854888, -7.952827525830219],
              [-35.058452288101336, -7.952779168207669],
              [-35.058577233140767, -7.952816817058061],
              [-35.05880807434179, -7.952920912564607],
              [-35.059067704327674, -7.953024865092039],
              [-35.059317260455956, -7.953033194213657],
              [-35.059528048927632, -7.952965177788174],
              [-35.059623536281123, -7.952869032459104],
              [-35.059708949777033, -7.952677263724214],
              [-35.059736831696135, -7.95249534688724],
              [-35.059707183038491, -7.95232328394821],
              [-35.059648744596004, -7.952151362988259],
              [-35.059522892690865, -7.951931941279952],
              [-35.059397135771562, -7.951731652451416],
              [-35.059223636175503, -7.951579436287418],
              [-35.059012036823944, -7.951484813734807],
              [-35.058733357960278, -7.951409657013046],
              [-35.058492921830954, -7.951305609499629],
              [-35.058377239769278, -7.951200943076998],
              [-35.058299657463124, -7.951038684042983],
              [-35.058279796705463, -7.950904840870576],
              [-35.058317561510449, -7.950780279679982],
              [-35.058412857892151, -7.950645865591425],
              [-35.058488818438512, -7.950482845669234],
              [-35.058584210806146, -7.950367565460467],
              [-35.058718416823275, -7.950338198738581],
              [-35.058843313869787, -7.950366281649082],
              [-35.058987928695117, -7.950499505912315],
              [-35.059248511539735, -7.950794799237485],
              [-35.059470231741876, -7.950994612135827],
              [-35.059653089301548, -7.951098944607327],
              [-35.059845447803603, -7.951184096206063],
              [-35.060027827433935, -7.951192758278742],
              [-35.06020982612084, -7.951124884832244],
              [-35.060410634720753, -7.950980379880656],
              [-35.060611347334863, -7.950816741049271],
              [-35.060821513898411, -7.950624353405183],
              [-35.060964981893363, -7.95052796911108],
              [-35.061118524745922, -7.950527207222858],
              [-35.061233920850015, -7.950574471006136],
              [-35.061320814126852, -7.950679278407822],
              [-35.061331078956229, -7.950813169573277],
              [-35.061245236523916, -7.950918834849213],
              [-35.060967657497685, -7.951063720744919],
              [-35.060651977715906, -7.951266199252021],
              [-35.060461147987688, -7.95148719272976],
              [-35.06036656849993, -7.95176511291672],
              [-35.060310183753572, -7.952004573372092],
              [-35.060205912351663, -7.952263406672237],
              [-35.060236134924452, -7.952550273889986],
              [-35.060342698987171, -7.952750656704572],
              [-35.060526034476155, -7.95295065957511],
              [-35.060814693211604, -7.953102302823033],
              [-35.061093898997541, -7.953282693883696],
              [-35.061421517361289, -7.953548950438374],
              [-35.06177773453819, -7.953776795254434],
              [-35.06209513606553, -7.953918726583551],
              [-35.062421894556536, -7.954012776220199],
              [-35.06266190274949, -7.954030718274748],
              [-35.06293084370617, -7.954077218169961],
              [-35.063171042870891, -7.954133427984074],
              [-35.06364160530741, -7.954198058905889],
              [-35.063900614385304, -7.95417763721487],
              [-35.064140383613669, -7.954147743569928],
              [-35.064341718135942, -7.954108473957146],
              [-35.064657922840141, -7.954011228788886],
              [-35.065012226299856, -7.953856391009188],
              [-35.065327808095979, -7.953634775622277],
              [-35.06571008646079, -7.953317155885282],
              [-35.065948847837703, -7.953086354502488],
              [-35.066139770551914, -7.952884492904482],
              [-35.066321624260098, -7.952787915638315],
              [-35.066446282341985, -7.952768160849413],
              [-35.066523149754168, -7.952786912785152],
              [-35.066619544973435, -7.952872538314123],
              [-35.066658649580532, -7.953015851440566],
              [-35.066640127624972, -7.95314988458514],
              [-35.066583218956296, -7.953284108701779],
              [-35.066478137674409, -7.95338030402382],
              [-35.06630602387331, -7.953505534116686],
              [-35.066085785451932, -7.953602302354854],
              [-35.065884785880108, -7.95370854154696],
              [-35.065655237488102, -7.95386275941751],
              [-35.065397234261667, -7.954084088846439],
              [-35.065205927604048, -7.954209414925263],
              [-35.065014524960496, -7.954315606124291],
              [-35.064669530471441, -7.954412994271597],
              [-35.064362681730913, -7.954462356747376],
              [-35.063988562506538, -7.954492918294418],
              [-35.063614347296323, -7.954504345961653],
              [-35.063220699264861, -7.954468033915406],
              [-35.062884630803147, -7.954431434911145],
              [-35.062673795338455, -7.954489886396739],
              [-35.062549519200054, -7.954586175704812],
              [-35.062473655639458, -7.954768330506907],
              [-35.062474707483936, -7.954978804184648],
              [-35.062514098048567, -7.955179519950438],
              [-35.062572538491033, -7.955351439910537],
              [-35.062688366531439, -7.955484805153005],
              [-35.062890275968897, -7.955560339819021],
              [-35.063264444186132, -7.955539345211912],
              [-35.063705836894329, -7.955527583593661],
              [-35.063917390252996, -7.955612637206527],
              [-35.064138780504592, -7.955745477525972],
              [-35.064370054641991, -7.955935671491746],
              [-35.064563230023879, -7.956183458069021],
              [-35.064766289291569, -7.956488599292681],
              [-35.064978802508747, -7.956764990703704],
              [-35.06518099990376, -7.956897926009188],
              [-35.065392363290734, -7.956944709862512],
              [-35.065555361092827, -7.956915197161745],
              [-35.065660443374711, -7.95681900183964],
              [-35.065745949856598, -7.956646365984364],
              [-35.065802379596036, -7.956416472468663],
              [-35.065791536851648, -7.956167779024249],
              [-35.065819272792098, -7.955957162367425],
              [-35.065914710152789, -7.955851448098425],
              [-35.066038746326569, -7.955707322090824],
              [-35.066086010074088, -7.955563579027262],
              [-35.066085387166076, -7.955439208808584],
              [-35.066075120336734, -7.955305318642969],
              [-35.066084333321598, -7.95522873513076],
              [-35.066151172869162, -7.955161431600383],
              [-35.066237014301514, -7.955055765324449],
              [-35.06625558425003, -7.954931299119701],
              [-35.066149736081265, -7.954874422403425],
              [-35.065957805515495, -7.954875378263526],
              [-35.065737709072948, -7.955000847321401],
              [-35.065556382286985, -7.955202660926468],
              [-35.065308309939418, -7.955490911941667],
              [-35.065117194253418, -7.955654505780116],
              [-35.064934956601846, -7.955674546527032],
              [-35.064771288898747, -7.955570121069253],
              [-35.064665200765397, -7.955465409653447],
              [-35.064520486954201, -7.955313054510174],
              [-35.064491122254204, -7.955198393210426],
              [-35.064557531865333, -7.95504498722102],
              [-35.064748983501786, -7.954948362961844],
              [-35.065045852370595, -7.9548225129599],
              [-35.065265852827331, -7.954677910022217],
              [-35.065457303463752, -7.95458128576309],
              [-35.065686900848739, -7.954436634832397],
              [-35.065897352370023, -7.954301646827624],
              [-35.066117160855043, -7.954118775130386],
              [-35.066270081799559, -7.95399364102354],
              [-35.066442386572412, -7.953906678690294],
              [-35.066729657512958, -7.953780874681375],
              [-35.066921156142406, -7.953693816362104],
              [-35.06706467113036, -7.953606997007849],
              [-35.067150224605172, -7.953443928092508],
              [-35.067206892309265, -7.953261869276387],
              [-35.067215530378945, -7.953070482485386],
              [-35.067233333440534, -7.952792945242344],
              [-35.06728040521628, -7.952610933419208],
              [-35.067375410640608, -7.952419116691192],
              [-35.067508993749755, -7.952265376750669],
              [-35.067594594217503, -7.952111874775239],
              [-35.067651213928627, -7.951920249019182],
              [-35.067650160084156, -7.951709775341483],
              [-35.067582074720434, -7.951528337434491],
              [-35.067428052938411, -7.951433432923658],
              [-35.067245673307966, -7.951424773850957],
              [-35.067054032699801, -7.95148313035055],
              [-35.066863206971384, -7.95170412582842],
              [-35.066643495472299, -7.951906131405461],
              [-35.066500028477236, -7.952002516699577],
              [-35.066260884156947, -7.952156782563202],
              [-35.066040980686132, -7.952320519380645],
              [-35.065677753199083, -7.952609342311907],
              [-35.065477233556564, -7.952811251902972],
              [-35.065305550691853, -7.953022584454848],
              [-35.06513377284125, -7.95321478312697],
              [-35.064980803903822, -7.953330350293901],
              [-35.064846740865377, -7.953388420835423],
              [-35.064683601084518, -7.953389231716592],
              [-35.064462594776344, -7.953332927916378],
              [-35.064260303395386, -7.953180857731299],
              [-35.064067225999317, -7.95295220503398],
              [-35.063922465195198, -7.952790281950915],
              [-35.063749490521339, -7.952743305125614],
              [-35.063615235511215, -7.952763106907544],
              [-35.063433620767718, -7.952907517873161],
              [-35.063213332353556, -7.952994717171453],
              [-35.062934891454468, -7.95296739914909],
              [-35.062704242225038, -7.952901574402047],
              [-35.062406369504728, -7.952826516666225],
              [-35.0621275456622, -7.952722662124742],
              [-35.061724016744961, -7.952628993432251],
              [-35.061310890899357, -7.95253537273278],
              [-35.060974871430602, -7.952508338668529],
              [-35.060734816244711, -7.952480828674143],
              [-35.060609776219422, -7.952424044943908],
              [-35.060590009447644, -7.952309336651224],
              [-35.060637418173862, -7.952194294407472],
              [-35.060809533974869, -7.952069066314679],
              [-35.061039036373842, -7.951905284504227],
              [-35.06123979698085, -7.951751211612749],
              [-35.061593575518188, -7.951491140494176],
              [-35.061832816824278, -7.951356011510399],
              [-35.062053439188979, -7.951335780791331],
              [-35.062322187173898, -7.951344013926903],
              [-35.062649134636729, -7.95147633132299],
              [-35.062966486171241, -7.951608695712114],
              [-35.063226547093606, -7.951798748698642],
              [-35.063496202944272, -7.951988753692222],
              [-35.063755737944433, -7.952073568339954],
              [-35.063957406417231, -7.952101267306452],
              [-35.064149238997132, -7.952081179566403],
              [-35.064321735741622, -7.952032485992749],
              [-35.064627672616446, -7.951801351658906],
              [-35.06489512779229, -7.951551274417384],
              [-35.06509598238528, -7.951416334405628],
              [-35.065306241934856, -7.951243078641256],
              [-35.065583437017786, -7.951021654226357],
              [-35.065851417115923, -7.950876813323621],
              [-35.066099822414017, -7.950655531887779],
              [-35.066252215436251, -7.950425160442104],
              [-35.066375579708954, -7.950147098276031],
              [-35.066460653254396, -7.949888359961798],
              [-35.066574421598808, -7.949610344788703],
              [-35.066621301402748, -7.94939006620608],
              [-35.066601198680431, -7.949208389334146],
              [-35.066504517503517, -7.94906536216585],
              [-35.066283035265883, -7.948913387966874],
              [-35.066080984849556, -7.948809153481352],
              [-35.065830857805871, -7.948686024081077],
              [-35.065523392157097, -7.948611015338372],
              [-35.065024142921381, -7.948565661275667],
              [-35.06465948164638, -7.948567474010173],
              [-35.06438123471893, -7.948578423747456],
              [-35.064035766300528, -7.948580140495926],
              [-35.063661557090228, -7.948591567163366],
              [-35.063325731593082, -7.948602801858795],
              [-35.063086062350585, -7.948651828383587],
              [-35.062923545477837, -7.948777009483363],
              [-35.062837943010209, -7.948930510458708],
              [-35.062858187711399, -7.949140889150249],
              [-35.062945701896432, -7.949370067770505],
              [-35.063071745773357, -7.949627757238364],
              [-35.063091990474518, -7.949838134929949],
              [-35.06300619603514, -7.949953368145774],
              [-35.062852845154296, -7.94999239879352],
              [-35.062612935947122, -7.949993590618856],
              [-35.062257773614633, -7.949976219480555],
              [-35.061979382708486, -7.949958467398139],
              [-35.061652961167979, -7.949931385340954],
              [-35.061412669017322, -7.949856040647146],
              [-35.061277699113198, -7.949732336330816],
              [-35.06120001982115, -7.949550944417043],
              [-35.061189421041206, -7.949350084672401],
              [-35.061236494816875, -7.949168073849409],
              [-35.061302714456168, -7.948976400100553],
              [-35.061436203579319, -7.948803527280377],
              [-35.061588933551946, -7.948640126414047],
              [-35.06174147255291, -7.948438457788144],
              [-35.061855529854768, -7.948217845254543],
              [-35.061892864723319, -7.948007180604974],
              [-35.061824639380703, -7.947797039878548],
              [-35.0617088143402, -7.947663673636321],
              [-35.061516409845048, -7.947568956097752],
              [-35.061276452644954, -7.947560578983286],
              [-35.061026995502473, -7.947571383741571],
              [-35.060873692614607, -7.947619980329245],
              [-35.06069150595598, -7.947649586016198],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '198',
        NOME_UC1: 'RESERVA BIOLÓGICA DE SALTINHO',
        ID_WCMC2: '6957',
        CATEGORI3: 'Reserva Biológica',
        GRUPO4: 'PI',
        ESFERA5: 'federal',
        ANO_CRIA6: '1983',
        GID7: '300',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 88744 de 21/09/1983',
        DT_ULTIM10: '30/11/2007',
        CODIGO_U11: '0000.00.0198',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.175566109272069, -8.714694143761283],
              [-35.175436319184207, -8.714739934282573],
              [-35.175268611930626, -8.714822875730846],
              [-35.17517702373852, -8.714967836196717],
              [-35.175077880211006, -8.715058421178634],
              [-35.174993887918554, -8.715185355476587],
              [-35.174948166738915, -8.71530350105103],
              [-35.174902336065209, -8.715420856455461],
              [-35.174871826318771, -8.715583922338183],
              [-35.174795478478458, -8.71580136544266],
              [-35.174723056905059, -8.716190415454069],
              [-35.174627712721545, -8.71648044840868],
              [-35.174486430688603, -8.716689259140427],
              [-35.174284403645224, -8.717006789299711],
              [-35.174230971738616, -8.717097399729434],
              [-35.174093695107203, -8.717314806391547],
              [-35.173853332281737, -8.71761434433224],
              [-35.173658696547761, -8.71788699045152],
              [-35.173456626347679, -8.718140681841172],
              [-35.173204871067242, -8.718449726645478],
              [-35.172846266733416, -8.718976119405742],
              [-35.172636474521212, -8.719275604122771],
              [-35.172426736769722, -8.719475840921909],
              [-35.172216789173376, -8.719739045221223],
              [-35.171968918748703, -8.720129150688356],
              [-35.171778132246459, -8.720437157255008],
              [-35.171576088842627, -8.720736613044611],
              [-35.171137383358385, -8.721480558402892],
              [-35.170866424987352, -8.721924864975225],
              [-35.170713820438117, -8.722179593878316],
              [-35.170557496662099, -8.722541908798936],
              [-35.170423986766131, -8.722949183289073],
              [-35.17038583817795, -8.723301989707391],
              [-35.170370566290529, -8.72361864631009],
              [-35.170427802794116, -8.723879928660885],
              [-35.170484980622319, -8.724087799946192],
              [-35.170530795403103, -8.724223211642444],
              [-35.170633742909594, -8.724529363956664],
              [-35.170710085200916, -8.724755406810218],
              [-35.170778748697032, -8.725026247233586],
              [-35.170889442308528, -8.725314238786593],
              [-35.171026750201925, -8.725801582754338],
              [-35.171137374270437, -8.726235518363861],
              [-35.171240284213667, -8.726650345331199],
              [-35.171301343578996, -8.726984124019726],
              [-35.171328061876487, -8.727336996263952],
              [-35.171308877898468, -8.727544883938865],
              [-35.171186948198283, -8.727780431220545],
              [-35.171034297428498, -8.728016090584088],
              [-35.170862654694226, -8.728216306076591],
              [-35.170629947202585, -8.72845282000136],
              [-35.170340002965119, -8.728842823905087],
              [-35.170050138607607, -8.729269102179209],
              [-35.169794496093552, -8.729677352355598],
              [-35.169584625595874, -8.729968178363354],
              [-35.169420793227076, -8.730195143557344],
              [-35.169226183346737, -8.730458347067534],
              [-35.168985796700241, -8.730757788604382],
              [-35.168573860623404, -8.731366259172713],
              [-35.168211464835125, -8.731856467088573],
              [-35.16788710333433, -8.732137811803938],
              [-35.167562975225422, -8.732365685828388],
              [-35.167292094522836, -8.732656603361969],
              [-35.167109047113229, -8.732911228559058],
              [-35.166876299231767, -8.733400417402569],
              [-35.166605382213, -8.733944018662417],
              [-35.166372786836938, -8.734361667864039],
              [-35.166220158653722, -8.73485007543171],
              [-35.16608671045438, -8.735329736945255],
              [-35.166040872908582, -8.735818009682379],
              [-35.165968380688788, -8.736379824588495],
              [-35.165926486427381, -8.736975795728135],
              [-35.165888321051952, -8.737445904228901],
              [-35.165899796499019, -8.737717719500154],
              [-35.165934117320383, -8.737934322159479],
              [-35.166048503162102, -8.738005773544289],
              [-35.166208814237883, -8.738059130941513],
              [-35.166548229020002, -8.738147873233904],
              [-35.166941107029857, -8.738344367886292],
              [-35.167093731445604, -8.738361480849818],
              [-35.167261493470058, -8.738423440372932],
              [-35.167433291060306, -8.738557902567431],
              [-35.167658419073646, -8.738728639155017],
              [-35.167894878498068, -8.738926066296376],
              [-35.168150421135714, -8.739024278472368],
              [-35.168444143315106, -8.739185462161322],
              [-35.168650096146763, -8.739220713417637],
              [-35.168817926713039, -8.739219842607014],
              [-35.168943754970009, -8.739110067576348],
              [-35.169092628538657, -8.738928879077738],
              [-35.169256685877876, -8.738683793983926],
              [-35.169344382196968, -8.738456926055957],
              [-35.169573246197245, -8.738148857745097],
              [-35.169771667024882, -8.737975290221865],
              [-35.170023354840986, -8.737793081584249],
              [-35.17019505773046, -8.737648142345078],
              [-35.170397291845546, -8.737510795166488],
              [-35.170664334353738, -8.737265695103197],
              [-35.171133553191609, -8.737063534579042],
              [-35.171472934878111, -8.736926209842336],
              [-35.17185064903456, -8.736825157833493],
              [-35.172083331292477, -8.736823278993374],
              [-35.172358007916102, -8.736839408525963],
              [-35.172644046258689, -8.736901437239169],
              [-35.172884508114265, -8.736990073575203],
              [-35.17341459477052, -8.737077843100444],
              [-35.173765485558576, -8.73707588898246],
              [-35.174230946478389, -8.736973881666106],
              [-35.174562865616913, -8.736881358892663],
              [-35.174845137128564, -8.736753647772908],
              [-35.175047392268134, -8.736625791502691],
              [-35.175238017598737, -8.736498007709862],
              [-35.175321893565773, -8.736407348914035],
              [-35.177130084477014, -8.736839427878001],
              [-35.177179684747131, -8.736559076623148],
              [-35.177217955893823, -8.736359712500178],
              [-35.177309335988049, -8.736052589324647],
              [-35.177347540629412, -8.735889567127945],
              [-35.177412334559129, -8.735644497650437],
              [-35.177534466839788, -8.73550899162867],
              [-35.177812960004651, -8.73532598800602],
              [-35.177996070678397, -8.735207660350348],
              [-35.178163870578814, -8.735016914555823],
              [-35.178301230092742, -8.734852926528827],
              [-35.178495780952744, -8.734544865061132],
              [-35.178705493275636, -8.734354128253344],
              [-35.178964995008904, -8.734198655563905],
              [-35.179254944613525, -8.734070977771083],
              [-35.179483787592623, -8.733861065232212],
              [-35.179678286740838, -8.73373329690717],
              [-35.180010122030609, -8.733541575422883],
              [-35.180475560846062, -8.73330416250811],
              [-35.180853214623916, -8.73320301546681],
              [-35.181116538753741, -8.733120107360193],
              [-35.181459795188445, -8.733054260120047],
              [-35.181833560600218, -8.732961727487931],
              [-35.182077737876334, -8.732825371740123],
              [-35.182249401433268, -8.732706190094765],
              [-35.182558281858675, -8.732505910253414],
              [-35.182840689726298, -8.732386684965217],
              [-35.18318389119198, -8.732375243629017],
              [-35.183481531393852, -8.732265595218506],
              [-35.183702784682218, -8.732128255040717],
              [-35.183908727894362, -8.732001398488253],
              [-35.184023227345648, -8.731901260611226],
              [-35.184225497012733, -8.731655210956973],
              [-35.184397030078699, -8.731482608114238],
              [-35.18462221123184, -8.731156491179012],
              [-35.184839501052302, -8.730875093531269],
              [-35.185163804345969, -8.730547147672965],
              [-35.185514763647141, -8.730201918210192],
              [-35.185808501122807, -8.730046395776535],
              [-35.186079318693942, -8.729747005569829],
              [-35.186308241012242, -8.729528511627372],
              [-35.1865218505557, -8.729255806179385],
              [-35.187086420032216, -8.728855300594688],
              [-35.187456386300362, -8.728473744396693],
              [-35.187753949097072, -8.728146643979381],
              [-35.187990420827965, -8.727828140184844],
              [-35.188223137576941, -8.727520114733462],
              [-35.188574153826814, -8.727201652871617],
              [-35.188921286016139, -8.726801975045266],
              [-35.189260752202053, -8.726302281586333],
              [-35.189535276565934, -8.725948481669448],
              [-35.189779516574568, -8.725639444186323],
              [-35.190061895909196, -8.725331393976823],
              [-35.190462354852158, -8.725039602630536],
              [-35.190779080422175, -8.724820351639575],
              [-35.191141373037041, -8.724519856321823],
              [-35.191541901523472, -8.724183218749928],
              [-35.191877595418241, -8.723928570745805],
              [-35.192259082946364, -8.723718724693274],
              [-35.192663432080387, -8.723507997988063],
              [-35.19302971545887, -8.723379335094929],
              [-35.193426378716424, -8.723241901009851],
              [-35.19394517249637, -8.723166599239114],
              [-35.194387630197674, -8.72311888311574],
              [-35.194711874793967, -8.723071255876945],
              [-35.19500179451942, -8.723034015431978],
              [-35.195158200244741, -8.722960576900725],
              [-35.195352744548607, -8.722778438898606],
              [-35.195463352057239, -8.722660268032072],
              [-35.195532035216388, -8.722460825743628],
              [-35.195612173459345, -8.722388412132362],
              [-35.195901935928823, -8.722178563761041],
              [-35.196100362879498, -8.721943031936114],
              [-35.196226292348747, -8.721734251811091],
              [-35.196382673953465, -8.721380309070529],
              [-35.196573427801766, -8.720954995203845],
              [-35.19664966775489, -8.720691818561766],
              [-35.19664210429503, -8.720176838097412],
              [-35.196623097855458, -8.719960294351603],
              [-35.196580987182415, -8.719643788522054],
              [-35.196565752219499, -8.719517811976385],
              [-35.196447543269798, -8.71928324898767],
              [-35.196401778709436, -8.719102981881788],
              [-35.196355990241059, -8.718868370352382],
              [-35.196314082416883, -8.718678556307738],
              [-35.196241481711454, -8.71851642417298],
              [-35.196180619856442, -8.718353365283873],
              [-35.196161360167288, -8.718227502864504],
              [-35.196134671220641, -8.718010988868174],
              [-35.196146185250797, -8.717757401942796],
              [-35.196222451530261, -8.717521790683652],
              [-35.196390256088868, -8.71735871381868],
              [-35.196665023484918, -8.717185136494644],
              [-35.196870956589386, -8.717129803299983],
              [-35.197141846203252, -8.716956273549568],
              [-35.19743565773711, -8.716673963252832],
              [-35.197611121570453, -8.716501356513531],
              [-35.19775211156378, -8.716274361986029],
              [-35.197797994713106, -8.716094151371767],
              [-35.197836132210021, -8.715795638957776],
              [-35.197782701228277, -8.715560058074654],
              [-35.197752162892243, -8.715416075171778],
              [-35.197702534341268, -8.715272062879283],
              [-35.19764155864781, -8.715154845240489],
              [-35.197511896046507, -8.715019342133354],
              [-35.19737453799678, -8.714912503448573],
              [-35.197206699950918, -8.714768515466718],
              [-35.196954957271174, -8.714579670461479],
              [-35.196825317425457, -8.71447194762507],
              [-35.196592550438076, -8.714310763460817],
              [-35.196260735980381, -8.71407808265627],
              [-35.195883030197457, -8.71381780694233],
              [-35.195558750485127, -8.713566882003265],
              [-35.195112443111739, -8.713253120541014],
              [-35.194940868948606, -8.713064343616203],
              [-35.194555499362487, -8.712795459227689],
              [-35.194284678329417, -8.712742909390405],
              [-35.194101582042549, -8.712653257904007],
              [-35.193788770272775, -8.712600843540601],
              [-35.193491120126986, -8.712584638194096],
              [-35.192934271979027, -8.712542657109726],
              [-35.192548968409035, -8.712472079995811],
              [-35.192163676680892, -8.712366298538297],
              [-35.191786065773513, -8.712313758931234],
              [-35.191347410445687, -8.712235547849623],
              [-35.191099448158049, -8.712137342957567],
              [-35.190950630365279, -8.712138273024786],
              [-35.190657006377378, -8.712166939606858],
              [-35.19022570451547, -8.712187870898916],
              [-35.189840581859755, -8.712189779259743],
              [-35.189447700626062, -8.712173575260161],
              [-35.188833544208997, -8.712159347685045],
              [-35.18839865100292, -8.712162128303342],
              [-35.188158265132472, -8.712181211943765],
              [-35.187906649831007, -8.712219381514791],
              [-35.187670146015158, -8.712256625158638],
              [-35.187467873449094, -8.712275621933355],
              [-35.187181705485983, -8.71226797117972],
              [-35.186899512646995, -8.712261346847118],
              [-35.186670621692024, -8.712298486221259],
              [-35.18641102635587, -8.712371945572817],
              [-35.186250970565673, -8.712472077597889],
              [-35.186037282255135, -8.712627615581502],
              [-35.185785592199892, -8.712882142310157],
              [-35.185606338424272, -8.713072925517313],
              [-35.185461373361171, -8.713209190036016],
              [-35.185282088839905, -8.71332749336603],
              [-35.18319154183488, -8.712074412767222],
              [-35.18303900820051, -8.712138303387221],
              [-35.182775879598843, -8.712338638891726],
              [-35.182512622021591, -8.712485413856232],
              [-35.182157873304504, -8.712740029490563],
              [-35.181951944446972, -8.712940307332721],
              [-35.181757332037336, -8.713086228711058],
              [-35.181467417577828, -8.713250356320481],
              [-35.181043938565857, -8.71348782193853],
              [-35.180944869526321, -8.713578343633149],
              [-35.180822699141764, -8.713660380540979],
              [-35.180700630635307, -8.713769984071705],
              [-35.18044124807269, -8.714006533234684],
              [-35.180078967498041, -8.714279313741665],
              [-35.179666953137556, -8.714435719643497],
              [-35.179277799952423, -8.714546331386142],
              [-35.178915415732121, -8.714656943906082],
              [-35.178663636645965, -8.714694095140013],
              [-35.178442367076592, -8.714696133857531],
              [-35.17833932806586, -8.714687445496939],
              [-35.178190569474118, -8.714678873672403],
              [-35.177912241089537, -8.714662764262121],
              [-35.177660374274929, -8.714618867030033],
              [-35.177217866490942, -8.714522550349642],
              [-35.176897469726512, -8.714523421403591],
              [-35.176565561935618, -8.714534939083523],
              [-35.176241342227762, -8.714591126743645],
              [-35.175985706188321, -8.714637892949753],
              [-35.175775829489858, -8.714683616083031],
              [-35.175566109272069, -8.714694143761283],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2240',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO AMPARO',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12221',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '04/03/2016',
        CODIGO_U11: '0000.26.2240',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.857945498063259, -7.768508911656859],
              [-34.857570807953714, -7.768386160179826],
              [-34.85721551566634, -7.768311166413662],
              [-34.856841246488543, -7.76828409733143],
              [-34.856543808722364, -7.768275828182296],
              [-34.856313693425996, -7.768305539838407],
              [-34.856131586763617, -7.768344608467783],
              [-34.855853587812774, -7.768393665969021],
              [-34.855585139796993, -7.768433111537571],
              [-34.855422303977278, -7.768491234059423],
              [-34.855297883863358, -7.768558756547882],
              [-34.855259888089243, -7.768645037976116],
              [-34.855260308020924, -7.768740721370869],
              [-34.85533755936428, -7.768855204698719],
              [-34.855443969430574, -7.769055677502224],
              [-34.855463828177555, -7.769208686547375],
              [-34.855416281468493, -7.769304577908337],
              [-34.855244061686449, -7.769410583120857],
              [-34.85507242980875, -7.76965054448608],
              [-34.854949311483097, -7.770014683098332],
              [-34.854883668740001, -7.770359433872279],
              [-34.854750536554498, -7.770627932083012],
              [-34.854559842800001, -7.770896681253115],
              [-34.854321140411514, -7.77115607244996],
              [-34.854120726749379, -7.771396158794888],
              [-34.853920061128228, -7.771578835502921],
              [-34.853681189767123, -7.771799952941826],
              [-34.853432681484691, -7.772011543434509],
              [-34.85325145467889, -7.772251545792949],
              [-34.853098923671197, -7.772472286292758],
              [-34.852956406523361, -7.772788667194119],
              [-34.852890468828058, -7.77306644039174],
              [-34.852714960966374, -7.773325554633145],
              [-34.852514881249569, -7.773642187493921],
              [-34.852458768444805, -7.773972544365421],
              [-34.85243130944508, -7.774274070438846],
              [-34.852418367317014, -7.774604239340821],
              [-34.852333346748281, -7.774906016373673],
              [-34.852262402123074, -7.775135968870479],
              [-34.852220175292572, -7.775351444478434],
              [-34.852221183128528, -7.77558108302604],
              [-34.852236454877186, -7.77578195376536],
              [-34.852251600646376, -7.775954119686254],
              [-34.852261005474858, -7.776102390754426],
              [-34.852271817204716, -7.776379829006254],
              [-34.852197061585166, -7.776643287288526],
              [-34.852097462154951, -7.776902070583581],
              [-34.852055424293646, -7.777160602919241],
              [-34.852013260452871, -7.777390430436413],
              [-34.851913535043117, -7.777620507913005],
              [-34.851779412887751, -7.777855521335028],
              [-34.851588421181027, -7.778057291928862],
              [-34.851483897807235, -7.778287391402059],
              [-34.851446110998886, -7.778421514527795],
              [-34.85153119431525, -7.778489319156495],
              [-34.851664715251708, -7.778582028660441],
              [-34.851859742673724, -7.778753409708301],
              [-34.852061840062341, -7.778896054942855],
              [-34.852336078881372, -7.779081442955847],
              [-34.852533891278433, -7.779258791967911],
              [-34.852793802195009, -7.779458594880071],
              [-34.85331350004855, -7.779829494885898],
              [-34.853659923291133, -7.780067192617025],
              [-34.853823562980807, -7.780190866945563],
              [-34.854006515506327, -7.780343163106121],
              [-34.854121934596279, -7.780409637763832],
              [-34.854246736648626, -7.780428228730893],
              [-34.854534967424875, -7.780522653329016],
              [-34.855351778099177, -7.780825271968615],
              [-34.856322172610362, -7.781146353595631],
              [-34.856908315077455, -7.781354293677859],
              [-34.857494331565043, -7.781533527941555],
              [-34.858195599170038, -7.781740961105139],
              [-34.858406828562138, -7.781778310012221],
              [-34.858502976809497, -7.78182573077745],
              [-34.858637583755815, -7.781892119448845],
              [-34.858743156958695, -7.781901225462877],
              [-34.858848477202692, -7.781852921839909],
              [-34.858934566597163, -7.781795134263916],
              [-34.859049775721431, -7.781813766224207],
              [-34.859393039050538, -7.781959375536013],
              [-34.859767668171756, -7.782065378119649],
              [-34.860473248819694, -7.782162751982518],
              [-34.861164438575393, -7.782260186835287],
              [-34.861574727478576, -7.782294267898122],
              [-34.861862665302894, -7.782321708919948],
              [-34.861968792471451, -7.782375065654461],
              [-34.862064829681351, -7.782478700064084],
              [-34.86218001680902, -7.782492547054661],
              [-34.862251716311292, -7.782434822468508],
              [-34.862416859128558, -7.782355157084512],
              [-34.862632400563569, -7.78228244769077],
              [-34.862797479391098, -7.782188430397521],
              [-34.862955554241701, -7.782137502827016],
              [-34.863135086951274, -7.782057774453168],
              [-34.863249736166239, -7.781949626215134],
              [-34.863320927750635, -7.781777082354939],
              [-34.863437372859977, -7.781804079263209],
              [-34.863749378491043, -7.781850551180943],
              [-34.864229114890669, -7.781860402444249],
              [-34.864472391094317, -7.78182225585067],
              [-34.864665932757767, -7.78165635003151],
              [-34.864772783124181, -7.781411886650459],
              [-34.864965436931321, -7.781045048101728],
              [-34.865186871522994, -7.780678083573358],
              [-34.865486628655511, -7.780124190280583],
              [-34.865793738708433, -7.779606146766103],
              [-34.866072130966629, -7.779102582140513],
              [-34.866172006350943, -7.778908382436777],
              [-34.866270251684661, -7.778806286149268],
              [-34.866366313945797, -7.778834569035534],
              [-34.866529701676754, -7.778900828727221],
              [-34.866654672701834, -7.778957689452333],
              [-34.866712572216095, -7.779033982009008],
              [-34.866713080133984, -7.779148800283],
              [-34.866656068475997, -7.779273440455873],
              [-34.866541707214822, -7.779446174285563],
              [-34.866350469548827, -7.779590541242562],
              [-34.866197818561268, -7.779782580924151],
              [-34.866176481627171, -7.779920220038981],
              [-34.866191474421875, -7.780056504187102],
              [-34.866314428403371, -7.780199489366002],
              [-34.866501598987192, -7.780220194362037],
              [-34.866911503952252, -7.780168156969401],
              [-34.867515616473, -7.78010091080474],
              [-34.867997250935296, -7.77999832278053],
              [-34.868261249987114, -7.77995529823182],
              [-34.868337829439888, -7.779916687528049],
              [-34.868384902169801, -7.779795679325446],
              [-34.86834077864706, -7.779580584656148],
              [-34.868339951780591, -7.779394005835782],
              [-34.868375165634404, -7.779221619950178],
              [-34.868439223262499, -7.779063458994136],
              [-34.868510857775085, -7.778991380498687],
              [-34.868567751451913, -7.778840428492176],
              [-34.868567275528882, -7.778732786172741],
              [-34.868530695895821, -7.778596598009429],
              [-34.868465240493585, -7.778439007961896],
              [-34.868449801770737, -7.778202258449073],
              [-34.868506632457795, -7.778036953533341],
              [-34.868621119698439, -7.777892923522143],
              [-34.868757223772405, -7.777755975480775],
              [-34.868878874964366, -7.77760473752003],
              [-34.868978940317881, -7.777453594544006],
              [-34.869100558514994, -7.777295180628637],
              [-34.869150321235388, -7.77715861252631],
              [-34.869156690314959, -7.776972000711144],
              [-34.869134183625427, -7.77676398801175],
              [-34.869003558774693, -7.776513393508401],
              [-34.868894360788111, -7.776226823247198],
              [-34.868778126829675, -7.775976164754041],
              [-34.868669373771183, -7.775790060857581],
              [-34.868524487007683, -7.775568233162635],
              [-34.868365241346808, -7.775353646412145],
              [-34.868199435637223, -7.775282612749094],
              [-34.867990983221624, -7.775248844821308],
              [-34.867856716220409, -7.775259003665975],
              [-34.867722830157625, -7.775355276966168],
              [-34.867579690111981, -7.775528137775517],
              [-34.867484685679742, -7.775739060376715],
              [-34.867341332668282, -7.775864079488569],
              [-34.867140038149373, -7.775903239104335],
              [-34.866938531664708, -7.775894557022547],
              [-34.866756001070563, -7.775837949256895],
              [-34.866535180750049, -7.775800647343102],
              [-34.866208832219684, -7.775763808354806],
              [-34.865930577309683, -7.77575546321891],
              [-34.865652449379184, -7.775775822901487],
              [-34.865451027880809, -7.775786276698754],
              [-34.865306912992516, -7.775739067899673],
              [-34.865094967716466, -7.775539063021259],
              [-34.864844648727825, -7.775339226115754],
              [-34.864613685568109, -7.775177577981799],
              [-34.864411504192873, -7.775015803868193],
              [-34.864267516284023, -7.774997298887611],
              [-34.864133376262352, -7.775036161550766],
              [-34.863846162321614, -7.775171380500517],
              [-34.863692792450493, -7.775200759210656],
              [-34.863539126627209, -7.775163160344332],
              [-34.86344260044126, -7.775029626123674],
              [-34.863374432108273, -7.774800283528444],
              [-34.863364121296385, -7.774637666550301],
              [-34.863401862111601, -7.774493974485014],
              [-34.863544751198226, -7.774263705038746],
              [-34.863735184994034, -7.773937544231415],
              [-34.863801115689498, -7.773659771033567],
              [-34.863819204723647, -7.773410912620332],
              [-34.863808470980118, -7.773152613247255],
              [-34.863845662884209, -7.772884533968584],
              [-34.863950346230801, -7.772692706253282],
              [-34.863968900189697, -7.772549099174426],
              [-34.863949206415413, -7.772434364886989],
              [-34.863900900829279, -7.772358029337211],
              [-34.863766592834907, -7.772358619242449],
              [-34.863604263933055, -7.77253156303822],
              [-34.86333627984186, -7.772676264941009],
              [-34.86302945711315, -7.772715884482333],
              [-34.862665073815506, -7.772755756983035],
              [-34.862425237611397, -7.772756808813813],
              [-34.862233114089108, -7.772700241041492],
              [-34.862031356645495, -7.772634147322877],
              [-34.861877522849568, -7.772558274698696],
              [-34.861656704529139, -7.772520968785027],
              [-34.861368731711508, -7.772483957823983],
              [-34.861061319078658, -7.772389621212488],
              [-34.860830358918882, -7.772227970078771],
              [-34.860666890201664, -7.772142570508334],
              [-34.860446029888081, -7.772095696655198],
              [-34.860177116947305, -7.772029894889285],
              [-34.859965555609733, -7.771916000466522],
              [-34.85981117490266, -7.771715739629059],
              [-34.859694747976633, -7.771419628423802],
              [-34.859539778365203, -7.771085412433503],
              [-34.859471403066252, -7.770808227141081],
              [-34.859470013291812, -7.770492475138115],
              [-34.85950586041448, -7.769918212796153],
              [-34.859485451756321, -7.769640817537617],
              [-34.859455956087949, -7.769478283546093],
              [-34.859378661751492, -7.769354232278699],
              [-34.859205306153882, -7.769201896125172],
              [-34.858907194496943, -7.76904053694426],
              [-34.85859953190505, -7.768888788696071],
              [-34.85837812668025, -7.768717525629858],
              [-34.858176202263998, -7.768613156153479],
              [-34.857945498063259, -7.768508911656859],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2252',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE SERRA DO CUMARU',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5435',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2252',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.168685057326826, -8.185074629307907],
              [-35.168600140230531, -8.185103099106072],
              [-35.16845856584159, -8.185126048812501],
              [-35.16839624912015, -8.185126203043257],
              [-35.168248947921505, -8.185120919883964],
              [-35.168158255576266, -8.18509288887152],
              [-35.168016501361734, -8.185031090361388],
              [-35.167914489674388, -8.185008740851305],
              [-35.167818267332471, -8.185048524333419],
              [-35.167761687046195, -8.185082571343932],
              [-35.167665541587894, -8.185161910421538],
              [-35.167603491094269, -8.185292019056456],
              [-35.167575310313566, -8.185365550593048],
              [-35.167592505262839, -8.1854615622683],
              [-35.167740137897788, -8.185630698029637],
              [-35.16781949895924, -8.185658752938714],
              [-35.168085889772883, -8.185725893927378],
              [-35.16834659448039, -8.185781756598621],
              [-35.168516573368407, -8.185798279718831],
              [-35.169088828852722, -8.185842064228428],
              [-35.169649921453043, -8.185970631378716],
              [-35.169905161760077, -8.186128205291459],
              [-35.170041401710726, -8.186269126042248],
              [-35.170092630278035, -8.186387652763225],
              [-35.17008716818291, -8.186489372034369],
              [-35.170076257901755, -8.186692803560383],
              [-35.170048205124438, -8.186828477675492],
              [-35.170043166943543, -8.18713925310241],
              [-35.170089008541666, -8.187399054628223],
              [-35.17015199515059, -8.187726611195981],
              [-35.170208913067547, -8.187862073686839],
              [-35.170299955343879, -8.188059604580427],
              [-35.170362889616939, -8.188364564851451],
              [-35.170357514213883, -8.188505830347699],
              [-35.170318233803606, -8.188692392450909],
              [-35.170245228717121, -8.189008985209558],
              [-35.170245616916958, -8.189201091267105],
              [-35.170274494181832, -8.189472230066883],
              [-35.170320235217261, -8.189681176623738],
              [-35.170371618710867, -8.189873154139141],
              [-35.170490982425328, -8.190070622730888],
              [-35.170650032156587, -8.190284936737083],
              [-35.170865642752332, -8.190448252944424],
              [-35.171001819469943, -8.190555265814073],
              [-35.17122308105305, -8.190718579112664],
              [-35.171353634147231, -8.190842553796822],
              [-35.171404777315921, -8.190921533862234],
              [-35.171444844939181, -8.191124843577271],
              [-35.171445854913152, -8.191622054846752],
              [-35.171391679292995, -8.192842651787005],
              [-35.171381638478941, -8.193475501208139],
              [-35.171427407465941, -8.193695737311888],
              [-35.171592450557739, -8.19406824646364],
              [-35.171649406124935, -8.194215007534041],
              [-35.171768888281584, -8.194468969149446],
              [-35.171791813001917, -8.19459887529011],
              [-35.171797886814382, -8.194802268375989],
              [-35.171769899809988, -8.194966189490453],
              [-35.171724945844211, -8.19514711518835],
              [-35.171668894733344, -8.195441064345442],
              [-35.171646701033232, -8.195672775984557],
              [-35.171658432170922, -8.19587050627327],
              [-35.171749594113116, -8.19612454161468],
              [-35.171789755139116, -8.196373055013884],
              [-35.17184118002271, -8.196587630414559],
              [-35.171887221550364, -8.196943478134761],
              [-35.171882365750619, -8.197339012486275],
              [-35.171809258047809, -8.197604750767059],
              [-35.17173589094125, -8.197740544167305],
              [-35.171577515288973, -8.197865243937541],
              [-35.171419059105453, -8.19794473733776],
              [-35.171130242911119, -8.197996315334073],
              [-35.170937699418097, -8.19803068821532],
              [-35.170501565073707, -8.198071327091984],
              [-35.170360076176642, -8.198139484935268],
              [-35.17029234434758, -8.198263957747111],
              [-35.170213379600831, -8.198433658500205],
              [-35.17008379304, -8.198784299353099],
              [-35.169994001813173, -8.199202644920339],
              [-35.169909732709534, -8.199553166763831],
              [-35.169808299959492, -8.199818976616037],
              [-35.169525591600554, -8.200090897258903],
              [-35.169423846328883, -8.200198503435539],
              [-35.169237222738758, -8.200362827442795],
              [-35.169005309818111, -8.200538560359082],
              [-35.168869530394161, -8.20062930221399],
              [-35.168722457554395, -8.200737018046153],
              [-35.168609460254643, -8.20088985448759],
              [-35.168553146119933, -8.201053858812498],
              [-35.168485553371475, -8.20124613183642],
              [-35.168434940509137, -8.201432720477371],
              [-35.168350266297153, -8.201579836936446],
              [-35.168203116054173, -8.20165365491899],
              [-35.168129464899842, -8.20165383604953],
              [-35.167948111086552, -8.201620390729328],
              [-35.167795080584348, -8.201581213809975],
              [-35.167602397686395, -8.201553443105595],
              [-35.167347541826437, -8.201587978762507],
              [-35.167177688744822, -8.201639250307025],
              [-35.167036197870871, -8.201707407447749],
              [-35.166928705181789, -8.201781120495628],
              [-35.1668440571339, -8.20194519386671],
              [-35.166838628389669, -8.20205821483523],
              [-35.166867224762619, -8.202193746498192],
              [-35.166895640263625, -8.202238881547744],
              [-35.166935385275032, -8.202283981111258],
              [-35.167037526638822, -8.202362830708555],
              [-35.167230497458625, -8.202537506200805],
              [-35.167338393539922, -8.20266719866413],
              [-35.16747479147201, -8.202875919177961],
              [-35.167560098511551, -8.203039557233492],
              [-35.167617161494782, -8.20324283150325],
              [-35.167623224768413, -8.20344057659914],
              [-35.167606705837848, -8.203672274310016],
              [-35.167516475099731, -8.203875906123212],
              [-35.167431773815963, -8.204011725648117],
              [-35.167233714402272, -8.204125220767237],
              [-35.167143198541268, -8.204187598807641],
              [-35.166945237612531, -8.204346297865921],
              [-35.166798240295414, -8.204493566763791],
              [-35.166640014654568, -8.204691716763996],
              [-35.166504544463045, -8.20493502065063],
              [-35.166408653230235, -8.205144315219535],
              [-35.166312645803785, -8.205291464811921],
              [-35.166244829534115, -8.205370733401793],
              [-35.166137347923929, -8.205450106574391],
              [-35.166035511619576, -8.205518156895439],
              [-35.165911049829091, -8.205603225937866],
              [-35.165831957399512, -8.205716425610087],
              [-35.165781251143166, -8.205852159380171],
              [-35.165702351157812, -8.206055764901709],
              [-35.165572383368378, -8.206219938592932],
              [-35.165476274531692, -8.206321884060946],
              [-35.165312219024344, -8.206440943848818],
              [-35.165029180084105, -8.206549001439317],
              [-35.164734696137053, -8.20660623944597],
              [-35.164434522727134, -8.206646532093027],
              [-35.164230747675347, -8.206731791073453],
              [-35.164061175906483, -8.206924325248051],
              [-35.163891882926528, -8.207252462591057],
              [-35.163852744255585, -8.207512467199685],
              [-35.163915423117331, -8.207687473938197],
              [-35.163983666141064, -8.207817262296059],
              [-35.164080202086843, -8.207930022307568],
              [-35.164176559770489, -8.207952383212421],
              [-35.164289803484863, -8.207918202478591],
              [-35.164380426922705, -8.207912328243463],
              [-35.164482466129286, -8.207940325595359],
              [-35.164533560792954, -8.207996698425973],
              [-35.164550641852948, -8.208036206636784],
              [-35.164573486727491, -8.208126557867692],
              [-35.164670232411204, -8.208341025221936],
              [-35.164795160556395, -8.208487620822684],
              [-35.164925669072844, -8.208589007186527],
              [-35.165158065237151, -8.208644930167429],
              [-35.165401796252944, -8.208706477604016],
              [-35.165571910873247, -8.208785162903382],
              [-35.165611670968588, -8.208835910926441],
              [-35.165600427837305, -8.208875490432915],
              [-35.165549670864401, -8.208988618779388],
              [-35.165481794264061, -8.209045298177063],
              [-35.165413929206309, -8.209101968249827],
              [-35.165312091974798, -8.209170020964377],
              [-35.165215953402608, -8.209255016432673],
              [-35.165119942510103, -8.209402159154328],
              [-35.165108921482151, -8.209554745526823],
              [-35.165160064616977, -8.209633716772204],
              [-35.165256488014634, -8.209689982134002],
              [-35.165409492201533, -8.209706549020002],
              [-35.165681717430104, -8.209852786766907],
              [-35.165857542875038, -8.209948395979753],
              [-35.166022077612489, -8.210066648475335],
              [-35.166418828668711, -8.210156060899221],
              [-35.166543214073371, -8.210031447340265],
              [-35.166706908005544, -8.209737225958687],
              [-35.166808418477999, -8.209505313630228],
              [-35.166909803589185, -8.209216895699461],
              [-35.167067902238841, -8.208956593387178],
              [-35.167384417769242, -8.208588541659648],
              [-35.167667323729972, -8.208412679589815],
              [-35.168024017382926, -8.20830444087904],
              [-35.16830162579916, -8.208309399646009],
              [-35.168471701401593, -8.208365473468499],
              [-35.168483178867227, -8.208438900515834],
              [-35.168471975059923, -8.208501078603646],
              [-35.168409848463092, -8.208597292633357],
              [-35.168359116533686, -8.208721718070032],
              [-35.168257749768784, -8.209021433056225],
              [-35.1682353955511, -8.209174045542122],
              [-35.168218829322718, -8.209383146970422],
              [-35.168219177810265, -8.209552656845764],
              [-35.168236402408823, -8.209665615866031],
              [-35.16825971804073, -8.209987619053541],
              [-35.168226261363124, -8.210247613620067],
              [-35.168181269488933, -8.210411580351572],
              [-35.168023147257607, -8.210660595349253],
              [-35.167864780391533, -8.210790943684493],
              [-35.167723376486762, -8.210904296646376],
              [-35.167542342425712, -8.211029053655123],
              [-35.167265167505263, -8.211238799642937],
              [-35.167067283753482, -8.211437055183911],
              [-35.166807724551099, -8.211951877973629],
              [-35.166820065465878, -8.212449066285114],
              [-35.166803644110004, -8.212731618978612],
              [-35.166826606099839, -8.212878473194165],
              [-35.1669003540852, -8.212929137409835],
              [-35.167019324423528, -8.21292884149261],
              [-35.167183572301937, -8.212900179290424],
              [-35.167347837835131, -8.212888474130603],
              [-35.167727483184343, -8.212921428706128],
              [-35.167965658543345, -8.213033840605636],
              [-35.168204003774171, -8.213231000335309],
              [-35.168357271155891, -8.213377522352525],
              [-35.168544315086024, -8.213422262593323],
              [-35.169037338819827, -8.213488836063135],
              [-35.169320714793237, -8.213538975848921],
              [-35.169524870034408, -8.213640171152718],
              [-35.169785814861569, -8.213803376744588],
              [-35.169990111116995, -8.213972371894645],
              [-35.170149027510533, -8.214113232493927],
              [-35.170438701111621, -8.214474119982063],
              [-35.170546640968631, -8.214620760414913],
              [-35.17075684326354, -8.214902739995891],
              [-35.170972606776502, -8.215139512710854],
              [-35.171176838358313, -8.215274613809042],
              [-35.17132988600644, -8.215313776331977],
              [-35.171528184987878, -8.21531893293087],
              [-35.171788742138602, -8.215295679596855],
              [-35.172145500141667, -8.215215682878579],
              [-35.172462579596484, -8.215130131313938],
              [-35.17260427071384, -8.21515802984959],
              [-35.172746115338462, -8.21525937912379],
              [-35.172825599955864, -8.215343934487709],
              [-35.172905150087431, -8.215456739026083],
              [-35.172973233619395, -8.215501765366872],
              [-35.173018514269693, -8.215484700148053],
              [-35.173114651881882, -8.215399709821941],
              [-35.173238955270904, -8.215235538930029],
              [-35.173312296282809, -8.215082806186254],
              [-35.17337419077888, -8.214879233806229],
              [-35.173419021583257, -8.214636165382059],
              [-35.173520218676842, -8.214257348281754],
              [-35.173661021339782, -8.213855829588711],
              [-35.173790749640816, -8.213572989652606],
              [-35.17397136452108, -8.213244823151657],
              [-35.174168773248994, -8.212814913395642],
              [-35.174320816715642, -8.212368164378178],
              [-35.174472959716518, -8.21196661918078],
              [-35.174675832349287, -8.211440638946154],
              [-35.174963721612755, -8.210937040970949],
              [-35.175217987243464, -8.210608693935644],
              [-35.175494787126674, -8.210218135356728],
              [-35.17586201100994, -8.209719992962471],
              [-35.176116211944326, -8.209363391947928],
              [-35.176341980297877, -8.208950357668588],
              [-35.176465811637847, -8.208560183907021],
              [-35.176583684784482, -8.208028769444212],
              [-35.176577435318059, -8.207740625052905],
              [-35.176462636922309, -8.207017683151113],
              [-35.176433447737409, -8.206593996616425],
              [-35.176347914145488, -8.206322994809135],
              [-35.176279702866189, -8.206215817264608],
              [-35.176166206913798, -8.206120047230801],
              [-35.176041384338184, -8.206029959100338],
              [-35.175922180196707, -8.205917251132339],
              [-35.175734800990149, -8.205708655302644],
              [-35.175439430765479, -8.205330835498456],
              [-35.175228980745601, -8.204918901623072],
              [-35.175115439649829, -8.20480618119405],
              [-35.174984994042852, -8.204733054599368],
              [-35.174871555467675, -8.20467117943085],
              [-35.17484306088744, -8.20458650139858],
              [-35.174865556775224, -8.204507342331031],
              [-35.174927654548888, -8.204399828652273],
              [-35.174967090921584, -8.204292377175925],
              [-35.174978030399977, -8.204100245373137],
              [-35.17492060091881, -8.203721824793639],
              [-35.174857889290365, -8.20352987312252],
              [-35.174698548343329, -8.203179960610969],
              [-35.174436604541867, -8.202519537498722],
              [-35.174317195177679, -8.202305133257811],
              [-35.174072427317327, -8.201735071902005],
              [-35.174071389269798, -8.201232201753292],
              [-35.174127489067821, -8.200960853341623],
              [-35.174257409882834, -8.200774072780721],
              [-35.174398954576567, -8.200734160433528],
              [-35.174495320202723, -8.200762176899348],
              [-35.174574878859808, -8.200880630271794],
              [-35.174626075215755, -8.200982205507769],
              [-35.174671599910752, -8.201083795142242],
              [-35.174791026261509, -8.201309507849556],
              [-35.17487636199229, -8.201484442879442],
              [-35.175120738445756, -8.201862398168609],
              [-35.175404978113171, -8.202341961645642],
              [-35.175654961420811, -8.202685997279906],
              [-35.176063858225803, -8.203176537762245],
              [-35.176324892264311, -8.203390592572102],
              [-35.176665220372684, -8.20359314610892],
              [-35.176914647027999, -8.203671624802688],
              [-35.177124246974152, -8.203665447460544],
              [-35.177316786471387, -8.203625411008629],
              [-35.177424252501289, -8.203540387107628],
              [-35.177508976534, -8.203415877145069],
              [-35.177621738607556, -8.203155681499688],
              [-35.177660934416281, -8.202929573265019],
              [-35.17763163102881, -8.202455023268385],
              [-35.177574250879793, -8.202099211275863],
              [-35.177533931169933, -8.201777249000139],
              [-35.177499266449573, -8.20144962151325],
              [-35.177492903422809, -8.20111062734718],
              [-35.17748562488174, -8.200325267054243],
              [-35.177427711137668, -8.199709536093883],
              [-35.177415644498844, -8.199347955515394],
              [-35.177375638098482, -8.199178542810218],
              [-35.177296013211212, -8.199026194777609],
              [-35.177188194119566, -8.19893606089817],
              [-35.177074822750647, -8.198902442078849],
              [-35.176751724783593, -8.198812846060823],
              [-35.17617876044995, -8.198430068581592],
              [-35.175849805695314, -8.19824443689059],
              [-35.175475432005349, -8.198013718378638],
              [-35.175259669075437, -8.197776943912055],
              [-35.175140129705987, -8.197494728021677],
              [-35.175065893475697, -8.197206753310848],
              [-35.175059662297173, -8.196929910007793],
              [-35.175070551583026, -8.196715170517004],
              [-35.175103440263349, -8.196172672791672],
              [-35.175283089634902, -8.19537554477867],
              [-35.175469188483568, -8.194962610847414],
              [-35.175480208863419, -8.194810025212604],
              [-35.175468444301679, -8.194595345500554],
              [-35.175451234452481, -8.19449368403088],
              [-35.175382775233693, -8.194256551781169],
              [-35.175325453660378, -8.193928973865496],
              [-35.175347179093954, -8.193471259566413],
              [-35.175271984859471, -8.192714312370011],
              [-35.175197451629636, -8.192279435866938],
              [-35.175157522628091, -8.192143927053165],
              [-35.175060894654841, -8.19198596558884],
              [-35.174924743146534, -8.191890251406654],
              [-35.174811425178888, -8.19188487832384],
              [-35.174652872914159, -8.191919179965483],
              [-35.17440934699578, -8.191953692015366],
              [-35.174143002502234, -8.191909155032002],
              [-35.173853944267208, -8.191842069804492],
              [-35.173768883194136, -8.191797078516393],
              [-35.1737005959817, -8.191650344193404],
              [-35.173683396175676, -8.191548682433892],
              [-35.173660253262064, -8.191311430239157],
              [-35.173665561681979, -8.191136260329497],
              [-35.173727476913399, -8.190938348666538],
              [-35.17381199233153, -8.190712132772717],
              [-35.17392506815353, -8.190604490761652],
              [-35.17410049475771, -8.190508001850811],
              [-35.174451457505143, -8.19037717553387],
              [-35.174587164322347, -8.190252526029532],
              [-35.174654831130404, -8.190099806254183],
              [-35.174654550073804, -8.189964203904296],
              [-35.174648538803382, -8.189794706595912],
              [-35.174584990135244, -8.18919029391445],
              [-35.174488207974505, -8.188953221168015],
              [-35.174334574968249, -8.188620242366051],
              [-35.174050488548232, -8.188208486581079],
              [-35.17389725820216, -8.188073263811358],
              [-35.173738603102763, -8.188056707728036],
              [-35.17357438983057, -8.188091020565846],
              [-35.173506464007843, -8.188119434664241],
              [-35.173325336874491, -8.188193338859707],
              [-35.173149726590488, -8.188193777792495],
              [-35.173007946713454, -8.188120682293015],
              [-35.172849119813215, -8.188019366449428],
              [-35.172599563799679, -8.187873084619319],
              [-35.172554211233525, -8.187856244338667],
              [-35.172435193160787, -8.187828292964797],
              [-35.172316228404092, -8.187828590861088],
              [-35.172231337599904, -8.187868349169827],
              [-35.172061479006004, -8.187913972655853],
              [-35.171948162055159, -8.187908607718589],
              [-35.171857403668803, -8.187846679988276],
              [-35.171794920297877, -8.187767733150375],
              [-35.171743727141099, -8.187666156863518],
              [-35.171635668948291, -8.18745736309465],
              [-35.171476622648562, -8.187243051595416],
              [-35.171436633251751, -8.187079294958114],
              [-35.171300122653776, -8.186808420697341],
              [-35.171169312913356, -8.186554482107061],
              [-35.171072610900247, -8.186356963349517],
              [-35.170515876616975, -8.185584270448228],
              [-35.170283106964398, -8.18533623186188],
              [-35.170101606646725, -8.185223674483897],
              [-35.169897355027878, -8.185071632397245],
              [-35.169783970919497, -8.185026711014855],
              [-35.16965931127941, -8.185015720613171],
              [-35.169523370196622, -8.18502170171225],
              [-35.169393139760167, -8.185055929500379],
              [-35.169274201899611, -8.18506752322841],
              [-35.169081639998417, -8.185090603645628],
              [-35.168877681634783, -8.185079805345207],
              [-35.168685057326826, -8.185074629307907],
            ],
          ],
          [
            [
              [-35.177834181673568, -8.196256239849889],
              [-35.177670217368984, -8.196414858008255],
              [-35.177602353932386, -8.196471529171378],
              [-35.177483647629344, -8.196596134418034],
              [-35.177274626893741, -8.196884814403745],
              [-35.177212842551072, -8.197144880089283],
              [-35.177235988767613, -8.197382131377239],
              [-35.17727607519128, -8.197591087940774],
              [-35.177372796220702, -8.197794254356072],
              [-35.177480753452528, -8.197946539632611],
              [-35.177605719819702, -8.198110078580921],
              [-35.177776052438197, -8.198296114485325],
              [-35.177952071881052, -8.198493428132682],
              [-35.178105554594744, -8.198747302240376],
              [-35.178185155308313, -8.198888350852174],
              [-35.178225069141533, -8.199012559645666],
              [-35.178220064040509, -8.199334632577047],
              [-35.178186801701933, -8.199690682168288],
              [-35.178187583278067, -8.200069245914415],
              [-35.178188211108704, -8.200374347502233],
              [-35.178205779782935, -8.200656816707481],
              [-35.178314222499075, -8.201046412456442],
              [-35.1784507568061, -8.201322930091294],
              [-35.178519210157887, -8.201554412498378],
              [-35.178508230361459, -8.201723947847714],
              [-35.17846328201135, -8.201904873630914],
              [-35.178361750196707, -8.202119829785603],
              [-35.178288526908126, -8.202323426117013],
              [-35.178277366461593, -8.202408209419676],
              [-35.178295011140868, -8.202724573809213],
              [-35.178329349213527, -8.202893989088937],
              [-35.178375199856866, -8.203148136789515],
              [-35.178477986845969, -8.203543395129662],
              [-35.178585979101676, -8.203718278023073],
              [-35.178676790597969, -8.203797150543661],
              [-35.178784481875454, -8.203825132221993],
              [-35.178863898234425, -8.20387578215141],
              [-35.17893212470306, -8.203994267686769],
              [-35.178932717277007, -8.204282421443155],
              [-35.178831511242407, -8.20465559890873],
              [-35.178696522283452, -8.205130546592461],
              [-35.178640305584096, -8.2053397550978],
              [-35.178584233603296, -8.205622402633754],
              [-35.178483171373784, -8.206063370690053],
              [-35.17830865581697, -8.206600577900454],
              [-35.178179115826353, -8.20697381849998],
              [-35.177908004451389, -8.207370013984145],
              [-35.177812013862386, -8.207522813780962],
              [-35.177732964114, -8.207652962625986],
              [-35.177631431261716, -8.207867928280306],
              [-35.177580861876727, -8.208071464743982],
              [-35.17756980370347, -8.208201442531305],
              [-35.177530711029853, -8.208478405370805],
              [-35.177491684018122, -8.208783615026015],
              [-35.177390427623038, -8.209134182738669],
              [-35.177153036633925, -8.20940034032494],
              [-35.177045644813056, -8.209519262143145],
              [-35.176921251462929, -8.209638229614654],
              [-35.176836540984162, -8.209768399811276],
              [-35.176808424795468, -8.209870169495472],
              [-35.176808713664194, -8.210011431576406],
              [-35.176877058505369, -8.210186411024651],
              [-35.176939685166666, -8.210338815766802],
              [-35.176968270953715, -8.210463039420681],
              [-35.176940233357442, -8.210604373705445],
              [-35.176861234110476, -8.210757127923674],
              [-35.176731207490171, -8.210893055241959],
              [-35.176606735226926, -8.210972469716173],
              [-35.17650488514397, -8.211029225633938],
              [-35.176018117797625, -8.21125081222749],
              [-35.175837295051103, -8.211477269890706],
              [-35.175605772251181, -8.211845121890709],
              [-35.175476018308068, -8.212111004989554],
              [-35.175414299171273, -8.212404973433978],
              [-35.175415369916088, -8.212924790580384],
              [-35.175416022671136, -8.213241201005976],
              [-35.175433682364279, -8.213563214266095],
              [-35.175355376860558, -8.21405497735393],
              [-35.175282322651398, -8.214343323920504],
              [-35.175209099941256, -8.214552569071248],
              [-35.175056509654532, -8.214733760124526],
              [-35.174898102746496, -8.21484151256629],
              [-35.174643245776565, -8.214881705671935],
              [-35.174399669780243, -8.214899264411295],
              [-35.174354380011941, -8.214916332117625],
              [-35.17419027125456, -8.215007139530361],
              [-35.174105534691179, -8.21512601043101],
              [-35.174083209739187, -8.215289919597817],
              [-35.174094971893247, -8.215498950692226],
              [-35.174151924159553, -8.21564571721936],
              [-35.174333585156873, -8.215826068649342],
              [-35.174566088489271, -8.215932839334714],
              [-35.174957079966653, -8.215971401119104],
              [-35.17512137240022, -8.215970991711725],
              [-35.175370621388275, -8.215959068720263],
              [-35.175687630812881, -8.215833961546222],
              [-35.17589685814098, -8.215646984269656],
              [-35.175992862332564, -8.215494184222601],
              [-35.17609438249707, -8.215273567574245],
              [-35.176099792669298, -8.215149250245533],
              [-35.176093532809091, -8.214861108242914],
              [-35.176098419064282, -8.21448253208286],
              [-35.176143214393065, -8.214228155385719],
              [-35.176295945877996, -8.214114773218336],
              [-35.176420617373672, -8.214131409941817],
              [-35.176488688675171, -8.214170787201825],
              [-35.176545481972639, -8.214238444868593],
              [-35.17663634097228, -8.214345571164317],
              [-35.176744252935507, -8.214475259355982],
              [-35.176971453423839, -8.21475719814506],
              [-35.177136068886107, -8.21491499077038],
              [-35.177255107695323, -8.214948584653126],
              [-35.177424975812848, -8.214902958499541],
              [-35.177492866182781, -8.214857585782216],
              [-35.177617420164339, -8.214817716818365],
              [-35.177668421257543, -8.214823244281927],
              [-35.17774792106205, -8.214913439908823],
              [-35.177827430244612, -8.215003644715717],
              [-35.177969199920639, -8.215071096573604],
              [-35.178138974615386, -8.214980264092359],
              [-35.178223741811316, -8.21487834262572],
              [-35.178336568201864, -8.214646402246032],
              [-35.178426633990604, -8.214363667600624],
              [-35.178454170115685, -8.213979383305668],
              [-35.178515168008012, -8.213340756054391],
              [-35.178610974866771, -8.213097555345001],
              [-35.178808024632637, -8.212498135962463],
              [-35.178955030771874, -8.212356511550803],
              [-35.179051139313017, -8.212260211893124],
              [-35.179113435353678, -8.212248758041493],
              [-35.179170086336811, -8.212248613239748],
              [-35.179238130658113, -8.212276700137455],
              [-35.179368567219953, -8.212344166791047],
              [-35.179566863777069, -8.212349319782675],
              [-35.179714093013551, -8.212315040888877],
              [-35.179866967291446, -8.212269462140215],
              [-35.180031243034577, -8.212263391137196],
              [-35.180212534933332, -8.212262934222206],
              [-35.180399499292662, -8.212268113497476],
              [-35.180569564827017, -8.212318532880918],
              [-35.180751132585158, -8.212453686684469],
              [-35.180751331843034, -8.212549735465217],
              [-35.180711990477548, -8.212702393236373],
              [-35.180649758135004, -8.212742096413114],
              [-35.180536697475475, -8.212861038263693],
              [-35.180491765367393, -8.213053254234975],
              [-35.180491976909856, -8.213154960714082],
              [-35.180531788207787, -8.21322831424207],
              [-35.180650932314265, -8.213312763883154],
              [-35.180798528266784, -8.213453650648431],
              [-35.180861075757392, -8.213566489761524],
              [-35.180867117197202, -8.213747285178329],
              [-35.180788274268338, -8.213973490324664],
              [-35.180624303712001, -8.214132111166315],
              [-35.180488499625113, -8.21421156054663],
              [-35.180420636677503, -8.214268232812609],
              [-35.180318947477822, -8.214409739469071],
              [-35.180257111163471, -8.21464155647754],
              [-35.180206570111949, -8.214856392242877],
              [-35.180201279280098, -8.215037212124491],
              [-35.180213047522578, -8.215251890687192],
              [-35.180253151959057, -8.215466496649618],
              [-35.180435193813061, -8.215827652256516],
              [-35.180611302619639, -8.216058866641424],
              [-35.180844060816696, -8.216289935718445],
              [-35.181025591577367, -8.216408132041421],
              [-35.181337429487975, -8.216525991793731],
              [-35.18164376017868, -8.216717323072565],
              [-35.18192206314405, -8.217055629792876],
              [-35.182035719156872, -8.217224847316885],
              [-35.182217265261066, -8.217348693615447],
              [-35.182381722142793, -8.217421731362325],
              [-35.182495326913838, -8.217568352693061],
              [-35.182591966212158, -8.217726309799149],
              [-35.182728497168895, -8.217997165840345],
              [-35.182836742977202, -8.218285051364075],
              [-35.182950466796335, -8.218488175171556],
              [-35.183126365761382, -8.218617681461968],
              [-35.183273727525624, -8.21864556112852],
              [-35.183466302481271, -8.21862246994538],
              [-35.183636119011062, -8.218554242672472],
              [-35.183732174884511, -8.218429697386611],
              [-35.183833148788338, -8.217949173830521],
              [-35.183747405813598, -8.217582127121592],
              [-35.183712882330326, -8.217327954695014],
              [-35.183689542430194, -8.217000298194018],
              [-35.18369997189108, -8.21656520888885],
              [-35.183705066108786, -8.216293991575313],
              [-35.183732529740844, -8.215875810771612],
              [-35.183725878465204, -8.2154012053906],
              [-35.183713859635667, -8.215073531347358],
              [-35.183582129460376, -8.214384539025879],
              [-35.183473763447942, -8.214034500021908],
              [-35.183331544581378, -8.21375235009992],
              [-35.183160989354896, -8.213464631295585],
              [-35.183047245953325, -8.213255856471354],
              [-35.182870518269084, -8.212725181859353],
              [-35.182875250325473, -8.212273156916236],
              [-35.182925610085071, -8.211973571785702],
              [-35.183286490503363, -8.211153379628126],
              [-35.183399115574993, -8.210825380944454],
              [-35.183516973507963, -8.210288318287439],
              [-35.183617501776439, -8.209593092599949],
              [-35.183639292109852, -8.209169275556658],
              [-35.1835763530948, -8.208869971889449],
              [-35.183422819680622, -8.208593503572377],
              [-35.183201425545263, -8.20837935831945],
              [-35.182923447411333, -8.208193602461169],
              [-35.182634100754996, -8.207990923170351],
              [-35.182174688562505, -8.207737824515632],
              [-35.181380681843791, -8.20731606975705],
              [-35.181198981295218, -8.207113113628095],
              [-35.18110792049616, -8.206915587950645],
              [-35.18109021824354, -8.206570968313343],
              [-35.181067222475292, -8.206407177762166],
              [-35.180987098290331, -8.206017511526239],
              [-35.180861456793963, -8.205526268387855],
              [-35.180741832071483, -8.205210158405466],
              [-35.180559637648699, -8.204769900333764],
              [-35.180326220998083, -8.204216768893525],
              [-35.180172282695708, -8.203742533562014],
              [-35.180120402730594, -8.203307599941693],
              [-35.18014733907814, -8.202635162455655],
              [-35.180146525318605, -8.202239651594192],
              [-35.180157097553625, -8.201872363262583],
              [-35.180280203967406, -8.201131873330292],
              [-35.180347476814802, -8.200787038821147],
              [-35.180358191135475, -8.20048755024786],
              [-35.180238779950997, -8.200273148415572],
              [-35.180079657281802, -8.200030584634927],
              [-35.179988635534627, -8.19984435719231],
              [-35.179954092137194, -8.199578884038239],
              [-35.18001595016441, -8.199352727434302],
              [-35.180078061029761, -8.19925651273982],
              [-35.180157033350213, -8.19909245954438],
              [-35.180190685285488, -8.198922866479947],
              [-35.180257814205476, -8.198510239242227],
              [-35.180370113211332, -8.198024032069423],
              [-35.180477366725817, -8.197837310598365],
              [-35.180618644707906, -8.197667452377086],
              [-35.181189919835717, -8.197230945346281],
              [-35.181371048235867, -8.197157036955428],
              [-35.181592039160208, -8.197179080664716],
              [-35.181660134828661, -8.197235414608883],
              [-35.181745447396487, -8.197399048485817],
              [-35.181791102942505, -8.197562797467672],
              [-35.181808368820676, -8.19769270500154],
              [-35.181814489466589, -8.19791304413517],
              [-35.181860051944149, -8.198031589437605],
              [-35.181945129532672, -8.198082226833904],
              [-35.182052774226484, -8.198081951881687],
              [-35.18210377596872, -8.198093127807429],
              [-35.182171822911357, -8.198126856326953],
              [-35.182217303883391, -8.198200197856842],
              [-35.182217358072904, -8.19822844465445],
              [-35.182178004982966, -8.198375442156825],
              [-35.182138506807824, -8.198448998470738],
              [-35.182048191563773, -8.198607427709581],
              [-35.181991849760607, -8.198760133288527],
              [-35.18194683423728, -8.198907145257087],
              [-35.181930210871215, -8.199087998611411],
              [-35.181930537178204, -8.199246198626401],
              [-35.181959608873029, -8.199607741492164],
              [-35.182005409471657, -8.199839280733634],
              [-35.182113335145672, -8.19998026734474],
              [-35.182260849501397, -8.200087250389034],
              [-35.182419626792637, -8.200160304553377],
              [-35.182584184287101, -8.200289837695291],
              [-35.18270366312592, -8.200538145279884],
              [-35.182800598962665, -8.200843013523874],
              [-35.182886264537892, -8.201176155124539],
              [-35.182937922312597, -8.201498093691706],
              [-35.182977843205244, -8.201627940748676],
              [-35.183057377336951, -8.201735092590923],
              [-35.183148037152598, -8.201746171388484],
              [-35.183249826473663, -8.201655507815731],
              [-35.183294878258522, -8.201525436401077],
              [-35.183328544006145, -8.201367151851347],
              [-35.183373453226842, -8.201169276221455],
              [-35.183373217243016, -8.201056270721924],
              [-35.183367264578557, -8.200915032933109],
              [-35.183389023333397, -8.200479916682937],
              [-35.183445327865115, -8.200310265733146],
              [-35.183495973009961, -8.200146284682635],
              [-35.183624902617808, -8.199484882005585],
              [-35.183630156775216, -8.199287114833504],
              [-35.183601331850454, -8.199044228701931],
              [-35.183561259518676, -8.198846579822183],
              [-35.183521117658202, -8.198609368748871],
              [-35.183446727693472, -8.19824794454715],
              [-35.183434764640474, -8.197942868345777],
              [-35.183451387398634, -8.197762015110822],
              [-35.183547349655093, -8.197597922864139],
              [-35.183688913066113, -8.19756365846731],
              [-35.183893033966534, -8.19765354859492],
              [-35.184000902048517, -8.197760627889611],
              [-35.184125794610296, -8.197890269000931],
              [-35.184295993802117, -8.198008490210587],
              [-35.184386625163867, -8.198002612068766],
              [-35.184465871195137, -8.197974162332919],
              [-35.18457322826756, -8.197838292257806],
              [-35.184590019908342, -8.197736537796729],
              [-35.18458957235633, -8.197521835347485],
              [-35.184583597433516, -8.197369296176714],
              [-35.184549064004152, -8.197109474862623],
              [-35.184417504310403, -8.19649958908991],
              [-35.184332006539726, -8.196245548846576],
              [-35.184212438348617, -8.195957686515916],
              [-35.184121256422905, -8.195692359152748],
              [-35.183978862737419, -8.195319807385141],
              [-35.183813745091712, -8.194919059762336],
              [-35.183728037147681, -8.194563310518905],
              [-35.183670382487229, -8.19407753804105],
              [-35.183578716167055, -8.193580557809986],
              [-35.183447643440999, -8.193202323483611],
              [-35.183333878763747, -8.192976599451285],
              [-35.183197568963003, -8.192807438323364],
              [-35.182976291283261, -8.192644142515373],
              [-35.182760923372527, -8.192599481482363],
              [-35.182500262720097, -8.192560586626445],
              [-35.182290551843742, -8.192510260096457],
              [-35.182041135491097, -8.192437436251929],
              [-35.181882332025232, -8.192347433450992],
              [-35.181689534200537, -8.192257514936893],
              [-35.181525124958888, -8.192195773188532],
              [-35.181224732319606, -8.192123078661101],
              [-35.180896152156919, -8.192123906604692],
              [-35.180516643498883, -8.19214180236966],
              [-35.180023690627124, -8.192097844184294],
              [-35.179745861229364, -8.191979884775215],
              [-35.179598414093157, -8.191901155870394],
              [-35.179507786058075, -8.1919070325799],
              [-35.179462538385508, -8.191946696258373],
              [-35.179406046202921, -8.192020290887413],
              [-35.179355282306645, -8.192127768730892],
              [-35.179293228636269, -8.192257878449983],
              [-35.179146406117638, -8.192484260160718],
              [-35.179061662106122, -8.192597472898417],
              [-35.17881847576291, -8.192795838750248],
              [-35.178688403248813, -8.19290916904472],
              [-35.178513154673169, -8.193084768154128],
              [-35.178383459595118, -8.193378906451827],
              [-35.178327177210996, -8.193559849311271],
              [-35.178310473551406, -8.193701147600651],
              [-35.178333736675839, -8.193994901701572],
              [-35.17840231391321, -8.194282887455739],
              [-35.178504823400687, -8.19454819301634],
              [-35.178596067007014, -8.194841778772659],
              [-35.178647434043071, -8.195028103271488],
              [-35.178647734295346, -8.195175006778646],
              [-35.178602810309215, -8.195367231617659],
              [-35.178557730309826, -8.195485994323448],
              [-35.178472956151133, -8.195582259457167],
              [-35.178359847207901, -8.195678603621248],
              [-35.178212884197947, -8.195837174001065],
              [-35.178054628881952, -8.196018378566043],
              [-35.177834181673568, -8.196256239849889],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3308',
        NOME_UC1: 'PARQUE ESTADUAL MATA DE DUAS LAGOAS',
        ID_WCMC2: null,
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12265',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '05/09/2017',
        CODIGO_U11: '0000.26.3308',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.988656733218995, -8.290939044227645],
              [-34.98856971195432, -8.290824667879191],
              [-34.988432517028485, -8.290760771178924],
              [-34.9883172938858, -8.290768522046944],
              [-34.988173508920141, -8.290826643579514],
              [-34.987979838249245, -8.290992649401874],
              [-34.987836845162327, -8.291208630948677],
              [-34.987751158693648, -8.291359746942383],
              [-34.987686757112776, -8.291446175355691],
              [-34.98762938050556, -8.291496690998443],
              [-34.98755745102828, -8.291518575809508],
              [-34.987470862697457, -8.291490304923395],
              [-34.987419931152111, -8.291390100512528],
              [-34.987390504461544, -8.291268261252442],
              [-34.987310578159494, -8.2911323220439],
              [-34.98719499407192, -8.29106831735999],
              [-34.987058124096514, -8.291068999256488],
              [-34.986885811302493, -8.291184668408771],
              [-34.98674998616719, -8.291393439005851],
              [-34.986686269481531, -8.291616201567788],
              [-34.986723442035434, -8.291845637161229],
              [-34.986818459124869, -8.292117837529355],
              [-34.986898637388308, -8.292304004424347],
              [-34.98687065147714, -8.292469184371996],
              [-34.9867990829447, -8.292562823735024],
              [-34.986684111763381, -8.292620802289406],
              [-34.986561575691177, -8.292607061286361],
              [-34.986417104830373, -8.29252884967025],
              [-34.986322843625167, -8.292407333361167],
              [-34.986277999196851, -8.292084652344812],
              [-34.986226456745307, -8.291862464695697],
              [-34.98615369839488, -8.291719313537349],
              [-34.986016467474634, -8.2916482408819],
              [-34.985807488049502, -8.291634930813538],
              [-34.985483500448602, -8.291672422344332],
              [-34.985231693286437, -8.291738256750376],
              [-34.985052140551893, -8.291846785942086],
              [-34.984951900213083, -8.291969271104087],
              [-34.984866355722353, -8.292149087918503],
              [-34.984831633793448, -8.29240758427817],
              [-34.984876080282383, -8.292651335787399],
              [-34.984913250836314, -8.292880771380782],
              [-34.985029663796979, -8.293109812034134],
              [-34.98515309973132, -8.293302939916998],
              [-34.98520449820488, -8.293496426745342],
              [-34.985162068407099, -8.293654501726897],
              [-34.985025845332473, -8.293784341816838],
              [-34.984874674448385, -8.293806621567985],
              [-34.984636517294199, -8.293721699925857],
              [-34.984289873015065, -8.293551209739674],
              [-34.984131101245609, -8.293494594978249],
              [-34.983943947625683, -8.293524229657384],
              [-34.983821843487306, -8.293596594116675],
              [-34.983743249981238, -8.293726146250235],
              [-34.983751353789444, -8.293905497135478],
              [-34.983795872267294, -8.29416359955513],
              [-34.983933896066169, -8.294392533224851],
              [-34.984237246679818, -8.294548887467984],
              [-34.984598083884173, -8.294676252933966],
              [-34.984829290053675, -8.294811439257002],
              [-34.984945018119078, -8.294904145761754],
              [-34.984981721744646, -8.295040300937693],
              [-34.98493903898548, -8.295148148232943],
              [-34.984824282771079, -8.295249178518464],
              [-34.984695010681122, -8.295328754927507],
              [-34.984486100245036, -8.295329794769435],
              [-34.984053512535851, -8.295260191890506],
              [-34.983592109042462, -8.295190731989758],
              [-34.983080099954158, -8.295085645274916],
              [-34.98279904407395, -8.295065517196843],
              [-34.982511035209065, -8.295095652799604],
              [-34.982273633939059, -8.295161414216533],
              [-34.981964481164304, -8.295284938220485],
              [-34.981713247913945, -8.295465578909614],
              [-34.98151964323219, -8.295645932642307],
              [-34.981261528986131, -8.295891188922692],
              [-34.981125626861882, -8.296085607609319],
              [-34.981076207084897, -8.296286772316593],
              [-34.981098608801759, -8.296444525347182],
              [-34.981171726096953, -8.296659432057526],
              [-34.981230219533131, -8.296831357025789],
              [-34.981252874211364, -8.297039338742767],
              [-34.981246893077696, -8.297283340213905],
              [-34.981240803960596, -8.297505816819493],
              [-34.981292454395522, -8.297749532334265],
              [-34.981365463707242, -8.297942912179071],
              [-34.981496823477151, -8.298279515171343],
              [-34.98160596050262, -8.298494241908992],
              [-34.981722374463125, -8.298723284562461],
              [-34.981838823418144, -8.298959501171172],
              [-34.981897497826644, -8.299167303915496],
              [-34.981934884347304, -8.299439793240268],
              [-34.981965139910827, -8.299726668469917],
              [-34.981981094565633, -8.300035142554353],
              [-34.982004146183087, -8.300322054778574],
              [-34.982012825902871, -8.300616212947158],
              [-34.981999785800987, -8.300888953233779],
              [-34.981986745699096, -8.301161693520408],
              [-34.981966393667719, -8.301412942936015],
              [-34.98180880166597, -8.303028250730486],
              [-34.981528523563433, -8.306036246736346],
              [-34.981507883576107, -8.306230092510448],
              [-34.981437391877982, -8.306538997529598],
              [-34.981366576229505, -8.306783321951984],
              [-34.98126726774705, -8.307092369949341],
              [-34.981146203448461, -8.307372823109525],
              [-34.981010551285408, -8.307617471482782],
              [-34.980824434505401, -8.30785519486261],
              [-34.980716950226025, -8.307970540063991],
              [-34.980638029769572, -8.308035513600855],
              [-34.980616740881644, -8.308100201181267],
              [-34.980645845621709, -8.308157460844743],
              [-34.980869962828223, -8.308314210028458],
              [-34.981267409674054, -8.308556203805621],
              [-34.981737077952836, -8.308833715413375],
              [-34.982235527021494, -8.309103908087829],
              [-34.982538674667872, -8.309217208600398],
              [-34.982812680579755, -8.309266072494285],
              [-34.983108227340864, -8.309300477494144],
              [-34.983418253983061, -8.309349161415312],
              [-34.983829209859202, -8.30941169432335],
              [-34.984492885759273, -8.309587775705801],
              [-34.985041079555444, -8.309721378269742],
              [-34.985416087690226, -8.309798439060906],
              [-34.985834608457203, -8.309932687526597],
              [-34.98572557241539, -8.30973948865404],
              [-34.985659473094223, -8.309488671172547],
              [-34.985593409767489, -8.30924502964633],
              [-34.985520177489164, -8.309008599069843],
              [-34.985454259140312, -8.308793658364447],
              [-34.985345151109541, -8.308586109581549],
              [-34.985149343764604, -8.308328763046692],
              [-34.985025864836153, -8.308128461208042],
              [-34.984967294411256, -8.30794218632902],
              [-34.984966320560439, -8.307748448538437],
              [-34.98502967730159, -8.307453931424059],
              [-34.985107802879618, -8.307231096872716],
              [-34.98522908114515, -8.30699369544366],
              [-34.985386234163059, -8.306727412221093],
              [-34.98553668815763, -8.30656162236599],
              [-34.985644279420498, -8.30646780203017],
              [-34.985737173835375, -8.306316650041802],
              [-34.985829598322113, -8.306072216635735],
              [-34.986029072154551, -8.305626332565762],
              [-34.986221595002263, -8.305230711176849],
              [-34.986450678497476, -8.304942542143236],
              [-34.986693880929025, -8.304596898478943],
              [-34.986901640541845, -8.304366241070619],
              [-34.987102448169907, -8.304185847343252],
              [-34.987202834486745, -8.304092063001988],
              [-34.987440420729193, -8.304062175361253],
              [-34.987988028614062, -8.304080967641564],
              [-34.988556850397963, -8.304020722430963],
              [-34.988967222362739, -8.303968443055579],
              [-34.989348669559796, -8.303894780792712],
              [-34.989730079762452, -8.303813942574696],
              [-34.990068267288692, -8.303733320323873],
              [-34.990233487120157, -8.303639211031735],
              [-34.990297707728729, -8.303516904842306],
              [-34.990296731877848, -8.303323168051728],
              [-34.990309837968887, -8.303064779675225],
              [-34.990293948303147, -8.302770658500869],
              [-34.990285334572505, -8.30249085224238],
              [-34.990320016507198, -8.302225180927062],
              [-34.99035480642538, -8.301981035477377],
              [-34.990453925935455, -8.301636110703965],
              [-34.99060368903497, -8.301333985699939],
              [-34.990760259141268, -8.300952895194079],
              [-34.990881097473078, -8.300629388302635],
              [-34.991038100513073, -8.300334402259278],
              [-34.991187935601559, -8.300046629165671],
              [-34.991373789420344, -8.299758675099381],
              [-34.991502337620879, -8.299535587586229],
              [-34.991623175952597, -8.299212080694806],
              [-34.99170107956374, -8.298946193412293],
              [-34.991742639494149, -8.298615907505699],
              [-34.991741337692908, -8.298357591118391],
              [-34.991682478311958, -8.298113914597874],
              [-34.9915734082754, -8.297913542770427],
              [-34.991370399983985, -8.297656234230486],
              [-34.990936834423678, -8.297392903560876],
              [-34.990496390867598, -8.297194186482598],
              [-34.990128237733686, -8.297045337145288],
              [-34.989846530952619, -8.296896055873454],
              [-34.989593674950427, -8.296753806578671],
              [-34.989261758513749, -8.296647828999859],
              [-34.988822001852853, -8.29658544307048],
              [-34.988439875760577, -8.296522769184753],
              [-34.988216052509557, -8.296423427642969],
              [-34.988042547897585, -8.296302307273759],
              [-34.987882910260524, -8.296073483587531],
              [-34.987766458305465, -8.295837267978785],
              [-34.987700505961797, -8.295615152318589],
              [-34.987648925515856, -8.295385789714118],
              [-34.987662250573521, -8.295170454069128],
              [-34.987725678303335, -8.294890287865499],
              [-34.987811077816268, -8.294681768230218],
              [-34.987939519033176, -8.294437155851654],
              [-34.988096595061961, -8.294156522718945],
              [-34.988282593858486, -8.293897272473558],
              [-34.988396411216321, -8.293609678352961],
              [-34.988459873940627, -8.293336688104553],
              [-34.988487389923506, -8.293078227739347],
              [-34.988442832451184, -8.2928129513644],
              [-34.98839802301741, -8.292497447303136],
              [-34.988411349075008, -8.292282111658217],
              [-34.988461125796825, -8.292152702502857],
              [-34.988539536330514, -8.291987271593309],
              [-34.988682276456089, -8.291721061360112],
              [-34.988753412054749, -8.291541315534747],
              [-34.988774013047355, -8.291340294805664],
              [-34.988766015222602, -8.291182470785923],
              [-34.988729239608034, -8.291031965699664],
              [-34.988656733218995, -8.290939044227645],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2249',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO CONTRA-AÇUDE',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5422',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2249',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.029092351619909, -8.228588708815824],
              [-35.029019493507576, -8.228679384851077],
              [-35.028963520463627, -8.22875307248923],
              [-35.028929640821147, -8.228764634247446],
              [-35.028799845401061, -8.228810762092543],
              [-35.02861893423168, -8.228851630970848],
              [-35.028460445865441, -8.228858578018226],
              [-35.028165388352541, -8.228793443842386],
              [-35.028006458461356, -8.228749681309351],
              [-35.027836274601832, -8.228722966570526],
              [-35.027620932260668, -8.228702177352513],
              [-35.027428416072496, -8.228709388330538],
              [-35.027189801994957, -8.228626882517801],
              [-35.026865968326263, -8.228505663749825],
              [-35.02652973569743, -8.22826626407775],
              [-35.026438678049914, -8.228221975567656],
              [-35.026290888480581, -8.228155645554496],
              [-35.026029823279217, -8.228101455672103],
              [-35.02575191116437, -8.228053048196832],
              [-35.025576425493405, -8.22805447469827],
              [-35.025434784071138, -8.228055642527112],
              [-35.025344258277649, -8.22806206506902],
              [-35.025174398632885, -8.228063463028201],
              [-35.025083548371654, -8.228041683630412],
              [-35.024833506269843, -8.227942328365362],
              [-35.024714259539671, -8.22790396707151],
              [-35.024589061883177, -8.22783734194887],
              [-35.024463602900241, -8.227737087694285],
              [-35.024241341031853, -8.227586877912707],
              [-35.024218541282799, -8.227564484592989],
              [-35.023996839588335, -8.227470590940699],
              [-35.023860476269903, -8.227421015187906],
              [-35.023746855343596, -8.227382534947763],
              [-35.023513606250305, -8.227266190929427],
              [-35.023456781597119, -8.227244149449065],
              [-35.023230154326157, -8.227234712551464],
              [-35.023105572384388, -8.227235793570507],
              [-35.023003674975726, -8.227236577490872],
              [-35.022913146869186, -8.227242999460803],
              [-35.022528293235382, -8.227274316457068],
              [-35.02243776695169, -8.227280648059214],
              [-35.022349813433173, -8.227579863035722],
              [-35.022282115178157, -8.227614195317372],
              [-35.022287624755279, -8.22759165702967],
              [-35.022276312757739, -8.227597319051437],
              [-35.022180477367669, -8.227648786386569],
              [-35.022084615844022, -8.227694648761513],
              [-35.022028376991926, -8.227734528399589],
              [-35.022000244509364, -8.227751665744954],
              [-35.021960797680826, -8.22777446280157],
              [-35.021937907879405, -8.227752160032868],
              [-35.021831113477297, -8.22784318644489],
              [-35.021718720916624, -8.227939758150535],
              [-35.021668313261735, -8.228002206905082],
              [-35.021578077204005, -8.228047950624013],
              [-35.021452822942571, -8.227970114540284],
              [-35.021372698524907, -8.227880660641684],
              [-35.021327011863619, -8.227836052240779],
              [-35.02125237059078, -8.227718364816239],
              [-35.021210583463471, -8.227476575509309],
              [-35.021198569308766, -8.227397806166083],
              [-35.021191128877589, -8.227200772589589],
              [-35.021166950897729, -8.227032026804231],
              [-35.021120445152349, -8.226897294575487],
              [-35.021096622126841, -8.226762355651244],
              [-35.021107759722824, -8.226739701111256],
              [-35.021113152689345, -8.226711558334554],
              [-35.021067668125731, -8.226689369159347],
              [-35.021067406170168, -8.226655561811706],
              [-35.021025938628753, -8.226458877578104],
              [-35.020905610935493, -8.226296493561208],
              [-35.020870883540617, -8.226212323605461],
              [-35.020835924979259, -8.226099953528097],
              [-35.020829594331161, -8.22603245596863],
              [-35.020777608351445, -8.225909049302988],
              [-35.020714600747056, -8.225830719200859],
              [-35.020691449219619, -8.225774606643897],
              [-35.020621382627333, -8.225538654680633],
              [-35.020609425992312, -8.225471185397739],
              [-35.020438514942349, -8.225354345098275],
              [-35.020301071748669, -8.22518083434087],
              [-35.020283927239198, -8.225164107612313],
              [-35.020050296971469, -8.225008346852405],
              [-35.019981430626494, -8.224901930350523],
              [-35.019935801280496, -8.224868531544262],
              [-35.019548524243639, -8.224618263246743],
              [-35.019508639967839, -8.224590438271903],
              [-35.019439626047593, -8.224472724255929],
              [-35.019274805738952, -8.22441216964992],
              [-35.019138593878424, -8.224373891562035],
              [-35.018957654198182, -8.224409153217527],
              [-35.018895613099616, -8.224432156024093],
              [-35.018833833711284, -8.224488966221505],
              [-35.018704589388179, -8.224608310503729],
              [-35.01863718389427, -8.224665148946775],
              [-35.018525055529878, -8.224795617388942],
              [-35.018345780871179, -8.225016731652856],
              [-35.018216683552879, -8.225147285287944],
              [-35.018098897721579, -8.225272086745315],
              [-35.018020442122484, -8.225368484701933],
              [-35.017897322906094, -8.225532816990627],
              [-35.017847090423693, -8.22561207972884],
              [-35.01772898090713, -8.225708767057293],
              [-35.017576351444767, -8.225743795834537],
              [-35.017468447871678, -8.225705284845885],
              [-35.017383079235501, -8.22565526998509],
              [-35.017171697119075, -8.225448683685105],
              [-35.016780166392643, -8.225361784530923],
              [-35.016581615435996, -8.225323998996577],
              [-35.016502106234881, -8.225302160474584],
              [-35.016280786703035, -8.225247583502513],
              [-35.015980835455082, -8.225272594290391],
              [-35.015901297468673, -8.225245058117959],
              [-35.015742780757151, -8.22524639721142],
              [-35.015578547355602, -8.225247762604281],
              [-35.015431921787219, -8.225322174329975],
              [-35.015375710632952, -8.225367655761634],
              [-35.015336439505383, -8.225407359183961],
              [-35.014897625248366, -8.225732017403452],
              [-35.014740300621796, -8.225879705287433],
              [-35.014498399068586, -8.226061899090016],
              [-35.014318274923646, -8.226187192845341],
              [-35.014127191395488, -8.226352138219632],
              [-35.013986720951912, -8.226477142829276],
              [-35.01381254615417, -8.226630610321896],
              [-35.013705899137442, -8.226732844408486],
              [-35.013548953884545, -8.226919946348017],
              [-35.013498924553538, -8.227021716118802],
              [-35.01348262596953, -8.227100718218725],
              [-35.013354020050222, -8.227293192943165],
              [-35.013168827781435, -8.227474921544072],
              [-35.013112906104929, -8.227559905520058],
              [-35.01309142045482, -8.227689557757833],
              [-35.013081184693775, -8.227819151304168],
              [-35.01308238469008, -8.227948689854324],
              [-35.013089060836982, -8.228066900494531],
              [-35.013152884732449, -8.228235266027959],
              [-35.01321612698915, -8.228341802426666],
              [-35.013279338198856, -8.228442643603259],
              [-35.013325314369922, -8.228526755842889],
              [-35.013400072316166, -8.22864995952005],
              [-35.013474654789022, -8.228756348742452],
              [-35.013526378403427, -8.228846037229932],
              [-35.013619099091066, -8.229076182319806],
              [-35.013642304787794, -8.229143595655938],
              [-35.013688839299881, -8.229283934233035],
              [-35.01370038647093, -8.229306387000717],
              [-35.01371796838086, -8.229373826208533],
              [-35.013775582564037, -8.22948039065489],
              [-35.013840136570323, -8.229738882873654],
              [-35.013887515035329, -8.229974952845009],
              [-35.01400259838347, -8.230176779560663],
              [-35.014036856917819, -8.230204633250004],
              [-35.014122839447488, -8.230322172946945],
              [-35.014432656921926, -8.230775784456537],
              [-35.014467122227373, -8.230826145389804],
              [-35.01451919619106, -8.230949642972172],
              [-35.014553923022326, -8.231033811369201],
              [-35.014571383975174, -8.231095648304747],
              [-35.014582755213659, -8.231101196352109],
              [-35.014601537724339, -8.231298083611247],
              [-35.014762511091583, -8.231567119040283],
              [-35.014757353183938, -8.231623464354612],
              [-35.014725838056719, -8.231905309914968],
              [-35.014676479086425, -8.232085906605901],
              [-35.014660972375694, -8.232249337622902],
              [-35.014638669745729, -8.23228895367817],
              [-35.014532746517503, -8.23248122491994],
              [-35.014437905076178, -8.232639719702307],
              [-35.014316327606743, -8.232984212513536],
              [-35.014216560765163, -8.23322716488798],
              [-35.014206473409089, -8.233368060403048],
              [-35.014117171963534, -8.233509531575812],
              [-35.014067080572765, -8.233616997216195],
              [-35.014022358522567, -8.233673631309005],
              [-35.013994572169203, -8.23374148088465],
              [-35.013984166117048, -8.233837266444688],
              [-35.014007550257865, -8.233921491755968],
              [-35.014097904893994, -8.233898259292387],
              [-35.014295759365048, -8.233851613391597],
              [-35.014510463560178, -8.233799189802657],
              [-35.014685253746677, -8.233713245600464],
              [-35.014820594080589, -8.233650190506662],
              [-35.014967484091585, -8.233609585730907],
              [-35.015001158046907, -8.233575517666278],
              [-35.015113525146241, -8.233473251882881],
              [-35.01515271094901, -8.233416555246087],
              [-35.015213819253091, -8.233280920816995],
              [-35.015376653137544, -8.23312759707748],
              [-35.015399134143181, -8.233104885635335],
              [-35.015466044153072, -8.232986035042309],
              [-35.015657215466462, -8.232838083811002],
              [-35.01572465603288, -8.232769944841195],
              [-35.015808796662434, -8.232684818787524],
              [-35.015904398678487, -8.232605147697027],
              [-35.015971572440314, -8.232520104507262],
              [-35.016129364670249, -8.232428732707399],
              [-35.016247942441829, -8.23238836003071],
              [-35.016316576869642, -8.232466664829726],
              [-35.016402120449385, -8.232533491673555],
              [-35.016436614991186, -8.232589547539607],
              [-35.016461050862269, -8.23279201116217],
              [-35.016799211294732, -8.233251082429989],
              [-35.01690790919573, -8.233374021877131],
              [-35.017174544875168, -8.233416888738967],
              [-35.017486956078002, -8.233521357208335],
              [-35.017571917020646, -8.233526264520066],
              [-35.017651454500417, -8.233553797621251],
              [-35.017876391068143, -8.233371686519471],
              [-35.017915662474323, -8.233331982734866],
              [-35.018039604528887, -8.233257776141219],
              [-35.018226376681284, -8.233244992906171],
              [-35.018282764081491, -8.233216325276079],
              [-35.018417668074541, -8.233102647368288],
              [-35.018607728711331, -8.232825156448753],
              [-35.018686066177686, -8.23272315360394],
              [-35.018746967997238, -8.232564919131008],
              [-35.018964787522506, -8.232213975832421],
              [-35.019021024998089, -8.232174098730777],
              [-35.019167737451809, -8.232116590344514],
              [-35.019279867496429, -8.231986211573027],
              [-35.019357797175815, -8.231839099296826],
              [-35.019491969549307, -8.231635296919313],
              [-35.019525671292939, -8.231606831230566],
              [-35.019598589630355, -8.231527456409252],
              [-35.01967707401792, -8.231436660595518],
              [-35.019817952922438, -8.231356670818792],
              [-35.019908013654771, -8.23129402266124],
              [-35.019975362540066, -8.231225885980587],
              [-35.020048690086945, -8.231191525581329],
              [-35.020178225944896, -8.231111592779849],
              [-35.020330709009606, -8.231065265223938],
              [-35.020455292821822, -8.231064275027791],
              [-35.0205742505022, -8.23106331541857],
              [-35.02080776323178, -8.231213380201126],
              [-35.020870214584647, -8.231235393635052],
              [-35.020973082354104, -8.231335943170629],
              [-35.021078112345251, -8.231684176662],
              [-35.021107243271103, -8.231774067645979],
              [-35.021096367225802, -8.231830441614154],
              [-35.021007126596018, -8.231983306344695],
              [-35.020867066843316, -8.232153330043422],
              [-35.02076126650902, -8.232351294613792],
              [-35.020665636913037, -8.232425271425388],
              [-35.0206100711654, -8.23254397514912],
              [-35.020560362841557, -8.23269085298033],
              [-35.020449050247116, -8.232911355847733],
              [-35.020344361773134, -8.233238861092619],
              [-35.020217575862439, -8.233628487507563],
              [-35.020213264304694, -8.233780563762201],
              [-35.020272225850711, -8.23404468465565],
              [-35.020347454352574, -8.234224293380574],
              [-35.02041831652032, -8.23454467585846],
              [-35.02053844214123, -8.234684461929566],
              [-35.02061880181234, -8.234802118089274],
              [-35.0206646373219, -8.234858026047915],
              [-35.020774446884687, -8.235110502386849],
              [-35.020792497335435, -8.235234260100526],
              [-35.020827607107208, -8.235357839342631],
              [-35.020891288433809, -8.235514995419656],
              [-35.020965669489769, -8.235598872892657],
              [-35.02104600062934, -8.235710836015084],
              [-35.021126298566067, -8.235834099684107],
              [-35.021531439116345, -8.236168615784393],
              [-35.021612061199967, -8.236319990974398],
              [-35.021709592880761, -8.236459980537655],
              [-35.021761085744281, -8.236521464856148],
              [-35.021819170406758, -8.236684344172904],
              [-35.021904746941708, -8.236756864558631],
              [-35.022074963031017, -8.236789183683412],
              [-35.022114672877805, -8.23680019371862],
              [-35.022471869403667, -8.236842236657973],
              [-35.022534116837825, -8.236841742399779],
              [-35.022669957119788, -8.23682379119894],
              [-35.022765674814593, -8.236766628527175],
              [-35.022877570523448, -8.236608046380347],
              [-35.023005643619648, -8.236364857664803],
              [-35.023048404702337, -8.236082956116727],
              [-35.023066871280236, -8.235609801650787],
              [-35.023065435892434, -8.235452061590605],
              [-35.023064761414332, -8.235373237626812],
              [-35.02307510777932, -8.235266152251244],
              [-35.023102363570658, -8.235147681245227],
              [-35.023146707553934, -8.235034729170982],
              [-35.023219241298882, -8.234915849415048],
              [-35.023224371765707, -8.234853899342227],
              [-35.023282168520019, -8.23435225285605],
              [-35.02328172974498, -8.234301538681951],
              [-35.023292631593335, -8.234250769656692],
              [-35.023403065078973, -8.233928842590295],
              [-35.023402095091363, -8.233827509596876],
              [-35.023373113734756, -8.233748918584807],
              [-35.023372496661196, -8.233681392418553],
              [-35.023371471629012, -8.233568759306701],
              [-35.023371443162638, -8.233563154381335],
              [-35.023346826829503, -8.233343697222203],
              [-35.023356470307412, -8.233152182976481],
              [-35.023355387930685, -8.233028249756515],
              [-35.02331942928965, -8.232808942315742],
              [-35.023284290741252, -8.232679756169755],
              [-35.023260318226889, -8.232533520337256],
              [-35.023190190679827, -8.232286269447096],
              [-35.023184564996434, -8.23228638805185],
              [-35.023081872927754, -8.23220274385878],
              [-35.023006906167616, -8.232056945447184],
              [-35.022983960959387, -8.232023340383424],
              [-35.022794902608332, -8.231782744921214],
              [-35.022737228953766, -8.231664974902642],
              [-35.02261710389066, -8.231525189436795],
              [-35.022577011510471, -8.231474857809546],
              [-35.022497238311779, -8.231419120981728],
              [-35.022366004275774, -8.231307594917256],
              [-35.022337137631247, -8.231251511384331],
              [-35.022166016233484, -8.231112165382953],
              [-35.022118808711703, -8.230892911954482],
              [-35.022127867044944, -8.230639476276771],
              [-35.022199114040447, -8.230374153955934],
              [-35.022282199652508, -8.230170788852378],
              [-35.022410800694125, -8.229978221857396],
              [-35.022540719534845, -8.229937792838021],
              [-35.022682325854127, -8.229947836192107],
              [-35.022823877611025, -8.229946671940404],
              [-35.022976917792889, -8.229956750164256],
              [-35.023090186689899, -8.22996142130115],
              [-35.023214917450439, -8.229971642044946],
              [-35.023328391661281, -8.229998912868069],
              [-35.023426305355237, -8.230178314033296],
              [-35.023472607603289, -8.230290538507017],
              [-35.023569525057098, -8.230362911410513],
              [-35.023615241183165, -8.230413214598062],
              [-35.0236728294269, -8.230514172016953],
              [-35.023730385896975, -8.230626249462066],
              [-35.023759281566562, -8.230688028037386],
              [-35.023816957598541, -8.23080579786375],
              [-35.02387979028687, -8.230867224867614],
              [-35.023908890444822, -8.230951420347782],
              [-35.023960588033049, -8.231035504234757],
              [-35.024024035521073, -8.231164455046464],
              [-35.024075468526163, -8.231214729326755],
              [-35.024132527668151, -8.231264975235471],
              [-35.024144074981066, -8.231287337291704],
              [-35.024195331554289, -8.231320706983444],
              [-35.024282370940838, -8.23155657194979],
              [-35.024459705602609, -8.23175781198306],
              [-35.02449466787143, -8.231870093397323],
              [-35.024563394045842, -8.231948393645862],
              [-35.024614622261417, -8.231976158332383],
              [-35.024819267657243, -8.232070227269981],
              [-35.024910178180157, -8.232103216333481],
              [-35.025040683688928, -8.23212470759495],
              [-35.025137189555316, -8.232152063313965],
              [-35.02526754703397, -8.232162252546573],
              [-35.025363818964749, -8.232161405876818],
              [-35.025475977380751, -8.232036630768301],
              [-35.025576878811876, -8.231928817877389],
              [-35.025564014481787, -8.231754320506356],
              [-35.025523277807068, -8.231630770319569],
              [-35.025483392437103, -8.231602946218542],
              [-35.025328418606435, -8.231373390442792],
              [-35.025265762159229, -8.231328868294618],
              [-35.025197360067622, -8.231278679794606],
              [-35.025065978000882, -8.231155947715326],
              [-35.024957192535126, -8.231016015661657],
              [-35.024831173911366, -8.230859355265697],
              [-35.024671275386169, -8.230714256272838],
              [-35.024601996762925, -8.230562824476626],
              [-35.024651789278124, -8.230432851573187],
              [-35.024657096090429, -8.230387803784199],
              [-35.0246737998919, -8.230353820723073],
              [-35.024786630450201, -8.230307872093181],
              [-35.024911242154097, -8.230312485494359],
              [-35.024973664958786, -8.230328895663227],
              [-35.025201814502068, -8.230512793220832],
              [-35.025298880250787, -8.230596465251377],
              [-35.025401011834205, -8.230623792618392],
              [-35.025469151396742, -8.230640173858873],
              [-35.025515015305359, -8.230701683829771],
              [-35.025623333392858, -8.230785301309057],
              [-35.025691882981519, -8.230846696810053],
              [-35.025840290985329, -8.230980552888438],
              [-35.025925778313564, -8.231036169876633],
              [-35.026028351352259, -8.231114208814738],
              [-35.026142178534386, -8.231175197551879],
              [-35.026250232038343, -8.231225005264603],
              [-35.026278691398012, -8.231236071767528],
              [-35.026483894256998, -8.231386363513044],
              [-35.026637871809491, -8.231508980954981],
              [-35.026802545767211, -8.23155832217631],
              [-35.026904358050466, -8.231540632031923],
              [-35.026915757899005, -8.231551784598587],
              [-35.027108714231723, -8.231595197338457],
              [-35.027414587443033, -8.23160965352068],
              [-35.02757797276621, -8.231512552049681],
              [-35.0276340944677, -8.231450164075152],
              [-35.027711579527832, -8.231252431797126],
              [-35.027800731576164, -8.231082753815366],
              [-35.027828484497157, -8.231026204408108],
              [-35.02786212840379, -8.230986530668302],
              [-35.027986711142752, -8.230985447891548],
              [-35.028067362817055, -8.231142516642354],
              [-35.028069679706384, -8.231401499152588],
              [-35.028075659074922, -8.231435279782474],
              [-35.028128352407037, -8.231626298223549],
              [-35.028096350191369, -8.231846130761584],
              [-35.028097083000844, -8.231936254561118],
              [-35.028092921971862, -8.232099537226043],
              [-35.028094361334098, -8.232257276801354],
              [-35.028101686272052, -8.232448614649199],
              [-35.028217449912582, -8.232729263708393],
              [-35.028252530564821, -8.232847236913393],
              [-35.028333005771955, -8.232987310708317],
              [-35.028461343856456, -8.233402951910652],
              [-35.028423010062802, -8.233555379938432],
              [-35.02833895870598, -8.233657413003405],
              [-35.028152803843113, -8.23373781482792],
              [-35.02806227419152, -8.233744148043296],
              [-35.027966178232155, -8.233761809722315],
              [-35.027875531760003, -8.233762538433904],
              [-35.027711501272314, -8.233786419067043],
              [-35.027502395843527, -8.233833215983893],
              [-35.02740609466764, -8.233828370414177],
              [-35.027394284442074, -8.233772201315649],
              [-35.0273829415517, -8.233772258605097],
              [-35.027241391166356, -8.233773424779779],
              [-35.027094298313813, -8.233791524317876],
              [-35.02694143397845, -8.233798442835081],
              [-35.026805743326328, -8.23382769481052],
              [-35.026749299278933, -8.233845065745033],
              [-35.026489842872877, -8.23396543111366],
              [-35.026428681770334, -8.234089858838143],
              [-35.02633932361838, -8.234237029655871],
              [-35.026232733762797, -8.234350476593036],
              [-35.026164215956435, -8.234919796162181],
              [-35.026172275647184, -8.235184445251239],
              [-35.026191327030112, -8.235415140036521],
              [-35.026249264995627, -8.235566718695409],
              [-35.026277753197725, -8.235583387721487],
              [-35.026397002904886, -8.235621838195618],
              [-35.026504618551293, -8.235620933775669],
              [-35.026561239460136, -8.235620467349559],
              [-35.026787665560391, -8.235607299173017],
              [-35.026974027245373, -8.235549497406877],
              [-35.027002162220739, -8.235532359625633],
              [-35.02710356469494, -8.23546956236666],
              [-35.027289224263427, -8.235327241550394],
              [-35.027519868573414, -8.235162090382207],
              [-35.027626662860058, -8.23507106232635],
              [-35.027773023172962, -8.234962836367686],
              [-35.027914192090662, -8.234922258263774],
              [-35.028061049175648, -8.234876044007331],
              [-35.028145599594659, -8.234835932434997],
              [-35.028269921232919, -8.234801132350078],
              [-35.02837173369452, -8.234783351398123],
              [-35.028580923598092, -8.234753546856924],
              [-35.028648858214765, -8.234747327677399],
              [-35.028727985277996, -8.234729753871669],
              [-35.028970882973695, -8.234654577341489],
              [-35.029100063894887, -8.23454083512706],
              [-35.029223974779342, -8.234461018275947],
              [-35.029313885951019, -8.234387070914909],
              [-35.029494651172065, -8.234334899224393],
              [-35.029618559570636, -8.234255082297105],
              [-35.029826960367153, -8.234123853434609],
              [-35.030210791212319, -8.233979895358775],
              [-35.030305157450293, -8.233765081760843],
              [-35.030309522727357, -8.233624308700469],
              [-35.030285576144777, -8.233483676520819],
              [-35.030273146879864, -8.233359893791134],
              [-35.03026620281458, -8.233207967832774],
              [-35.030236511036179, -8.233061761962244],
              [-35.030189766962991, -8.232898827181321],
              [-35.030143168791533, -8.23274719199442],
              [-35.029974795653096, -8.232292430846476],
              [-35.029972156988812, -8.231988341452677],
              [-35.029925645190978, -8.231853608835346],
              [-35.029839510225898, -8.231724774140892],
              [-35.029838689223709, -8.231634741137926],
              [-35.029843847272581, -8.231578396134323],
              [-35.029762168180291, -8.231308695264888],
              [-35.029715160022334, -8.23111204342608],
              [-35.029718439205652, -8.23084733758631],
              [-35.029727521199867, -8.230599505499054],
              [-35.029724971466962, -8.230312320984227],
              [-35.029769367955133, -8.230210578558262],
              [-35.02992753914085, -8.230158611582944],
              [-35.030114776190757, -8.230202142125332],
              [-35.030278861973159, -8.230189470703463],
              [-35.030454261941181, -8.230171136964341],
              [-35.03060727498773, -8.230175514730751],
              [-35.030919328628606, -8.230246165855299],
              [-35.031123244982602, -8.230250107780353],
              [-35.03141126837118, -8.230163223736479],
              [-35.031523335580353, -8.230021542587906],
              [-35.031618403215028, -8.229891247764822],
              [-35.03176384862023, -8.229692990919608],
              [-35.031808365989193, -8.229596852835471],
              [-35.031812523387309, -8.229433570501167],
              [-35.031851147301154, -8.229320556857074],
              [-35.031867442503646, -8.229241645131641],
              [-35.031900851449812, -8.229173766653757],
              [-35.03196289198975, -8.229150764159396],
              [-35.032023819184801, -8.228998221878642],
              [-35.03204600500824, -8.228936097790546],
              [-35.032045388536901, -8.228868481713537],
              [-35.032033195914302, -8.228772901312631],
              [-35.031991899062952, -8.22859303382991],
              [-35.031968482931951, -8.228503115241955],
              [-35.031917021043171, -8.22844723722684],
              [-35.031899727803697, -8.228419211561208],
              [-35.031819515274506, -8.228312855396648],
              [-35.031778337771293, -8.228138592292396],
              [-35.031778309205791, -8.22813298739481],
              [-35.031748711109955, -8.227986871426971],
              [-35.031691501339822, -8.22792532713458],
              [-35.031628491592528, -8.227846999350746],
              [-35.03145763484715, -8.227741464836622],
              [-35.031337976998287, -8.227657996616145],
              [-35.030974341950916, -8.227525774765656],
              [-35.030872211638105, -8.227498538965433],
              [-35.030685322043311, -8.227505630340765],
              [-35.030470420058336, -8.227535554174468],
              [-35.030380069100936, -8.227558882317981],
              [-35.030227293108346, -8.22758261388975],
              [-35.030164842216024, -8.227560601894856],
              [-35.030039997971464, -8.227527875616657],
              [-35.029932150560782, -8.227500578198823],
              [-35.029852492673335, -8.227467440687997],
              [-35.029585513546969, -8.227373962037442],
              [-35.029483261378395, -8.227341031162306],
              [-35.029272872996053, -8.227241388231462],
              [-35.029210274073669, -8.227208076453511],
              [-35.029085224803254, -8.227152840408127],
              [-35.029005538051265, -8.227114009848876],
              [-35.028954635078193, -8.227114447453431],
              [-35.028767864980971, -8.227127232603832],
              [-35.028609639926827, -8.227167989044279],
              [-35.02846935163803, -8.227309902210292],
              [-35.028436175735699, -8.227405892540599],
              [-35.028403324506144, -8.227530087009297],
              [-35.028387470077661, -8.227659708138484],
              [-35.028388963820888, -8.22782865755155],
              [-35.028440983547576, -8.227940852323986],
              [-35.028441248478082, -8.227974659501205],
              [-35.028566474606556, -8.22804680022552],
              [-35.028680477616156, -8.228124690575697],
              [-35.028686280286628, -8.228141564366821],
              [-35.028686456905319, -8.22815846888682],
              [-35.028755502609037, -8.228281787698458],
              [-35.028772910207103, -8.22833232343948],
              [-35.028886913338106, -8.228410213700339],
              [-35.028938227244247, -8.228454882851358],
              [-35.028966685955886, -8.228465858942538],
              [-35.029092351619909, -8.228588708815824],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2255',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DE CARAÚNA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5447',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2255',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.096560548022801, -8.181501082661448],
              [-35.096486769935204, -8.181546211730241],
              [-35.096384445766006, -8.181783416317815],
              [-35.096247972844573, -8.182156195850256],
              [-35.096162431505029, -8.182675908501842],
              [-35.096099735308286, -8.183014849678088],
              [-35.096042749235615, -8.183302938200951],
              [-35.095985783719364, -8.183574087159833],
              [-35.096047613057323, -8.184229524840518],
              [-35.096206263523079, -8.184438699631958],
              [-35.096381958484116, -8.18461964594635],
              [-35.096495292703629, -8.184766636710833],
              [-35.096529169233143, -8.184941811969846],
              [-35.096517688570898, -8.185105649779315],
              [-35.096250484569218, -8.185760807956463],
              [-35.096034553371318, -8.186178709415755],
              [-35.095767698561573, -8.186449673158169],
              [-35.095557701028739, -8.186562492167301],
              [-35.095387316821927, -8.186788336093434],
              [-35.09517148326497, -8.187093240001669],
              [-35.094955764965995, -8.187262551114634],
              [-35.094723102724267, -8.187352753415437],
              [-35.094433715108586, -8.187437247107484],
              [-35.09376977336354, -8.187679617403685],
              [-35.093593787207048, -8.18783201611777],
              [-35.093480173503224, -8.188001412569424],
              [-35.093468747965915, -8.188103104142789],
              [-35.093497042337859, -8.188182225845354],
              [-35.093565065910553, -8.188238775302052],
              [-35.093650115926117, -8.188284056812639],
              [-35.093740864998431, -8.188295427446771],
              [-35.093950692229086, -8.188380359886677],
              [-35.094109396458997, -8.188538698093449],
              [-35.094120636444693, -8.188651696923113],
              [-35.09411479515898, -8.188849442459633],
              [-35.094029556333226, -8.189007560824543],
              [-35.09387051890225, -8.18923341740984],
              [-35.093762562082084, -8.18943106803186],
              [-35.093699949735523, -8.189673963107895],
              [-35.093705447526716, -8.189871712327873],
              [-35.093750723134569, -8.189990400903183],
              [-35.093960491352206, -8.190131830757585],
              [-35.094198571331241, -8.190346723884989],
              [-35.094306257655525, -8.190459819103467],
              [-35.094306181189516, -8.190538916682772],
              [-35.094260727965214, -8.19062927666857],
              [-35.094101744559303, -8.190787335913967],
              [-35.093999556241982, -8.190871989817001],
              [-35.09377255773861, -8.190973497371942],
              [-35.093551243592032, -8.191046754422686],
              [-35.093233446212267, -8.19117642388216],
              [-35.093142625093328, -8.191238499778136],
              [-35.092960832598621, -8.191532125907745],
              [-35.092835816598225, -8.191774965276348],
              [-35.092778937033557, -8.191950068069458],
              [-35.092733318654489, -8.192215572116094],
              [-35.092784142887659, -8.192475503213192],
              [-35.092857765972944, -8.192622467037467],
              [-35.093022136288624, -8.192786452516822],
              [-35.093634380432292, -8.193278516589928],
              [-35.09407672523993, -8.193437098930332],
              [-35.094331935894466, -8.193522065582682],
              [-35.094536140700136, -8.193544843045611],
              [-35.094660873409929, -8.193624042181916],
              [-35.094745894748854, -8.193708860291009],
              [-35.094802529914517, -8.193821914791149],
              [-35.094853489691573, -8.193923652549662],
              [-35.09513110552669, -8.19434198059878],
              [-35.095272788420637, -8.19449464847334],
              [-35.095312408508796, -8.194602031359755],
              [-35.095312347625942, -8.194669828876354],
              [-35.09530088186164, -8.194805408800415],
              [-35.095204188222979, -8.195104779390578],
              [-35.095164265335136, -8.195342030587497],
              [-35.095129893840856, -8.195726193556814],
              [-35.095186509041255, -8.195856187465111],
              [-35.095243222580869, -8.195873183969541],
              [-35.095345332015341, -8.195878926960487],
              [-35.095850185955051, -8.195930206143078],
              [-35.096241593538863, -8.195953138571566],
              [-35.096638664321013, -8.195993026892069],
              [-35.096746513836408, -8.195925321502024],
              [-35.096752249938575, -8.195846229339718],
              [-35.096723955785968, -8.195772759294687],
              [-35.096644616625966, -8.195670989024714],
              [-35.096559662657327, -8.195512722918378],
              [-35.096463420777937, -8.195286646576868],
              [-35.096231666905219, -8.194325975023061],
              [-35.096044734316671, -8.194015073194219],
              [-35.09553457864962, -8.193534394243814],
              [-35.09520583286244, -8.193212070107496],
              [-35.094888428584945, -8.192895407204597],
              [-35.0947581438909, -8.192680598498985],
              [-35.094724292594435, -8.192460225605489],
              [-35.094753019517896, -8.192047817793519],
              [-35.094912218483124, -8.191646807613136],
              [-35.095116667693091, -8.191387088007922],
              [-35.095372163480484, -8.19113306675824],
              [-35.095644659332514, -8.190912956381609],
              [-35.095979577685334, -8.190658994428945],
              [-35.096371164349733, -8.190484186687987],
              [-35.09673419922246, -8.190512744927297],
              [-35.09684756335173, -8.190620197997767],
              [-35.096853086792834, -8.190789698028006],
              [-35.096813266948075, -8.190919602871745],
              [-35.096682630318853, -8.19110028896409],
              [-35.096523644417879, -8.191269648898135],
              [-35.096307839599149, -8.191540656164033],
              [-35.096137435790446, -8.191789107651974],
              [-35.096006696672177, -8.192094086324202],
              [-35.095989594262498, -8.192184472692055],
              [-35.096074350356687, -8.19256872923925],
              [-35.096238660252496, -8.192806168622743],
              [-35.096465414457455, -8.192987156335782],
              [-35.096652563257237, -8.19305511923613],
              [-35.096851134708075, -8.193038330027443],
              [-35.097049743512294, -8.192976352470357],
              [-35.097583318409058, -8.192603923204029],
              [-35.097787815944017, -8.19228770279401],
              [-35.098004052996529, -8.191519508539947],
              [-35.09795337141616, -8.191090074993243],
              [-35.097834628793542, -8.190643632383521],
              [-35.097784012529367, -8.190129452578768],
              [-35.097886316263946, -8.189909196202196],
              [-35.097960147245509, -8.18981320861365],
              [-35.098062447360853, -8.189604261410949],
              [-35.098073892019471, -8.189485618881442],
              [-35.098108036505138, -8.189361346062517],
              [-35.098130769049355, -8.189310525249763],
              [-35.098329471335994, -8.189135543436587],
              [-35.09852238971434, -8.189096158040494],
              [-35.098743634359863, -8.189085047384403],
              [-35.098896766188496, -8.189130378465949],
              [-35.099049848627111, -8.18922656042486],
              [-35.099276587891922, -8.189424495530607],
              [-35.099724431174643, -8.189786467071558],
              [-35.099888759797082, -8.190001295607741],
              [-35.099888697169987, -8.190069092955705],
              [-35.099814870774964, -8.190170729558165],
              [-35.099724047129072, -8.19022714860165],
              [-35.099616243713349, -8.190249656811741],
              [-35.099355219761001, -8.190339838293912],
              [-35.099190619321476, -8.190441396385424],
              [-35.09904295025806, -8.190639009171615],
              [-35.098792926744466, -8.191124687383006],
              [-35.098599631925495, -8.191604761852906],
              [-35.098508600180011, -8.191915419118928],
              [-35.098485731112397, -8.192118802035564],
              [-35.098434286650388, -8.192565092825367],
              [-35.098360387444657, -8.19273452449842],
              [-35.098223989175231, -8.193022549573987],
              [-35.09814447484775, -8.193135478560334],
              [-35.097775327622557, -8.193609752221871],
              [-35.097752414082905, -8.193858332725076],
              [-35.097769270560825, -8.194050435949295],
              [-35.09784859500823, -8.194157857197665],
              [-35.098035713043259, -8.194259707548291],
              [-35.098188884170405, -8.19425984093248],
              [-35.098381782288044, -8.194237406107682],
              [-35.098773355319459, -8.194073893102487],
              [-35.099363560211373, -8.193820145445782],
              [-35.099743715394681, -8.193735716295819],
              [-35.099982021669646, -8.193685075822646],
              [-35.100248744782839, -8.193572301019328],
              [-35.100543878897611, -8.193408703349299],
              [-35.100816278791605, -8.193290293872806],
              [-35.101224833536989, -8.193166338423614],
              [-35.101576674782905, -8.193025386983534],
              [-35.101803735182074, -8.192861726761965],
              [-35.101860564448764, -8.192731830345441],
              [-35.101866322163758, -8.192635791907815],
              [-35.101832392410437, -8.19251711754441],
              [-35.101747448553056, -8.192341901831096],
              [-35.101617250273279, -8.192019752716369],
              [-35.101526749991343, -8.191708937242909],
              [-35.101487183645929, -8.19153940684749],
              [-35.101447761468947, -8.191200375205545],
              [-35.101453688963971, -8.19091224488119],
              [-35.101493653657265, -8.190612836400861],
              [-35.101630061630487, -8.190313513359428],
              [-35.10172096495338, -8.190149741235002],
              [-35.10187424475702, -8.190014272219704],
              [-35.102254538282338, -8.189771647397032],
              [-35.102521328876733, -8.189574135215375],
              [-35.102691724047844, -8.189336982130117],
              [-35.102748580475001, -8.189184485882905],
              [-35.102828265323076, -8.188868157947175],
              [-35.102885094694592, -8.188755210232609],
              [-35.102958900521124, -8.188687470717415],
              [-35.103180140852281, -8.188687664541614],
              [-35.103395641959828, -8.188761289844992],
              [-35.103922987475912, -8.189032928640286],
              [-35.104427496964831, -8.189462730939898],
              [-35.104710971093624, -8.189660718134542],
              [-35.104892441531632, -8.189734311673405],
              [-35.105397359180792, -8.189689534913756],
              [-35.105567652412446, -8.189559733599848],
              [-35.105641550296184, -8.189390299174162],
              [-35.105630403858406, -8.18915299801745],
              [-35.105590805559167, -8.189017368091656],
              [-35.105500142725255, -8.188898645435962],
              [-35.105403790365365, -8.188796875594942],
              [-35.105023946251009, -8.188519716325192],
              [-35.104734844665735, -8.18827652207764],
              [-35.104451415352401, -8.188033346551233],
              [-35.104377735390152, -8.187948540968504],
              [-35.104122754644067, -8.187598033541136],
              [-35.103737646921829, -8.186851938275653],
              [-35.103357991621728, -8.186354436379988],
              [-35.103057518411354, -8.186139485554742],
              [-35.102830672298623, -8.186071498151218],
              [-35.102688889989807, -8.18602617868547],
              [-35.102479034569313, -8.185975150709513],
              [-35.102371294973949, -8.185929864391428],
              [-35.102184204407777, -8.185799755084197],
              [-35.102065163923605, -8.185697956394106],
              [-35.101844143218365, -8.185443532822811],
              [-35.10144741060698, -8.185019454079987],
              [-35.101288757620722, -8.184810282105119],
              [-35.101249145262024, -8.184697251094315],
              [-35.101232235292791, -8.184572936258267],
              [-35.101238027757375, -8.184437349174953],
              [-35.101272218717618, -8.184256579070482],
              [-35.101278132944884, -8.183974088254297],
              [-35.101249916595506, -8.183804572550237],
              [-35.101261441851193, -8.183595544578035],
              [-35.101301232690332, -8.183505176803601],
              [-35.101375006794235, -8.183471337548395],
              [-35.101443085329628, -8.183465748701396],
              [-35.101562185773382, -8.183494101722486],
              [-35.101698280176464, -8.1835507119883],
              [-35.101862681565457, -8.183686445246892],
              [-35.102106333897275, -8.183997394100418],
              [-35.102310370066213, -8.184212258223228],
              [-35.102667441754299, -8.184585451406155],
              [-35.102814812174365, -8.184721170959971],
              [-35.102956599173808, -8.184760839378207],
              [-35.103053065218596, -8.184727025072652],
              [-35.103109855521019, -8.184653619114945],
              [-35.103166651577752, -8.184568920197501],
              [-35.103263213870925, -8.184427762390863],
              [-35.103365451279174, -8.184275300600047],
              [-35.103479002858606, -8.184162393031604],
              [-35.103569793478904, -8.184139873369988],
              [-35.10367188676603, -8.184156914912638],
              [-35.103768284580113, -8.184202188326365],
              [-35.103881631732158, -8.184332227666941],
              [-35.103960936512557, -8.184462245341846],
              [-35.104000542524616, -8.184575268993887],
              [-35.104040097584594, -8.184761757052126],
              [-35.104062257657112, -8.185377605566472],
              [-35.104045002486266, -8.185654432208686],
              [-35.104078662342552, -8.186095141149353],
              [-35.104276836541167, -8.186530348008739],
              [-35.10442414519752, -8.186745163672411],
              [-35.104809640576413, -8.187044929004013],
              [-35.105200860362288, -8.187282546007594],
              [-35.10545034684791, -8.187418356283777],
              [-35.105716790858949, -8.187633264774238],
              [-35.106164594136757, -8.188046076594169],
              [-35.106385564752635, -8.188362654236021],
              [-35.106459334814964, -8.188323162883117],
              [-35.1064028104568, -8.188091476712755],
              [-35.106159697217691, -8.187125154232309],
              [-35.106176989020156, -8.186808779365569],
              [-35.106245233811862, -8.186605442559417],
              [-35.106324769822514, -8.186469913023753],
              [-35.106563218281579, -8.18623846703589],
              [-35.106750522478336, -8.186114327473209],
              [-35.106971822406749, -8.186046713262959],
              [-35.107363325271798, -8.185956642863122],
              [-35.107601579357222, -8.185951187301077],
              [-35.107749112727106, -8.185911761973857],
              [-35.10785696059596, -8.185832754889152],
              [-35.107947799051409, -8.185736786189169],
              [-35.108038615994118, -8.185674707838306],
              [-35.108254245856799, -8.185607097189878],
              [-35.108407426135571, -8.185584620852064],
              [-35.108566357683287, -8.185471755113754],
              [-35.108594772962519, -8.185409630028696],
              [-35.108606180742434, -8.185336196753555],
              [-35.108577945324356, -8.185189272871009],
              [-35.108532705748722, -8.185019745562833],
              [-35.108476107269915, -8.184867156728384],
              [-35.108419517174489, -8.184697607668618],
              [-35.108363034238231, -8.184415075899649],
              [-35.108357594685856, -8.184138222530844],
              [-35.108403220863735, -8.183850122023264],
              [-35.108545459128514, -8.183353063715112],
              [-35.108806874558653, -8.182793940989317],
              [-35.108886720526122, -8.182296831348788],
              [-35.108881237736988, -8.182065177591758],
              [-35.108830349296667, -8.181878694120469],
              [-35.108717154646584, -8.181562216466665],
              [-35.108598210055355, -8.181347416333503],
              [-35.10835455911571, -8.181025172379471],
              [-35.107952175671109, -8.180578504951031],
              [-35.107549660676263, -8.180290030714236],
              [-35.107430572070967, -8.180244730660998],
              [-35.107209393713049, -8.180182401282577],
              [-35.105797018125664, -8.180034322341491],
              [-35.104225810237764, -8.179886107521256],
              [-35.102478795056271, -8.179681237436457],
              [-35.101418146402992, -8.179510836202065],
              [-35.100141891630997, -8.179391109908005],
              [-35.099189012265668, -8.179232092297296],
              [-35.099013146489341, -8.179248890259087],
              [-35.098820212736875, -8.179322181449477],
              [-35.098666978815174, -8.17939549311688],
              [-35.098468336134168, -8.17950831605439],
              [-35.098269663400892, -8.179660699160697],
              [-35.09793467047524, -8.179999397472539],
              [-35.097747276733465, -8.180225230270263],
              [-35.097605307529015, -8.180394600667679],
              [-35.097440677592495, -8.180535715127149],
              [-35.097179485597159, -8.180817974668319],
              [-35.097060274264678, -8.180913926641855],
              [-35.096946724670929, -8.181015523456031],
              [-35.096776387643075, -8.181196169654212],
              [-35.096657127768211, -8.181348616762978],
              [-35.096560548022801, -8.181501082661448],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3313',
        NOME_UC1: 'MONUMENTO NATURAL PEDRA DO CACHORRO',
        ID_WCMC2: null,
        CATEGORI3: 'Monumento Natural',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2014',
        GID7: '12284',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 40.549/2014 de 28/03/2014',
        DT_ULTIM10: '15/03/2016',
        CODIGO_U11: '0000.26.3313',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.147396673630652, -8.196345328976856],
              [-36.146211135402517, -8.197665582970931],
              [-36.145671111103937, -8.198050245203307],
              [-36.145057881749558, -8.19789231272309],
              [-36.144367048854448, -8.197887418243168],
              [-36.14382703761634, -8.198190765976241],
              [-36.143672990647133, -8.198334227979018],
              [-36.143593723004386, -8.198640841032216],
              [-36.143664215797884, -8.199562868406058],
              [-36.143891014779108, -8.200025239726131],
              [-36.144195985296925, -8.200334578046402],
              [-36.145037694794787, -8.200719997294613],
              [-36.14526697198626, -8.200875210508173],
              [-36.145496249177725, -8.201030423721747],
              [-36.145800125589361, -8.201493341095565],
              [-36.146025315414313, -8.202181566023995],
              [-36.14625050523923, -8.202869791952448],
              [-36.146553319547621, -8.203481773407825],
              [-36.146856133855991, -8.20409375486334],
              [-36.147216322822977, -8.204595241041652],
              [-36.148121897197392, -8.205856051947839],
              [-36.149387670538459, -8.207618348033195],
              [-36.14997855431119, -8.208778451327728],
              [-36.150654210572021, -8.209785363628388],
              [-36.153754885668214, -8.213822936066993],
              [-36.156597830845079, -8.217620622795765],
              [-36.158424682310212, -8.219023402514859],
              [-36.16001751463731, -8.22024647416734],
              [-36.16140747561284, -8.221267794911668],
              [-36.162849171108107, -8.222420761884639],
              [-36.163612832014124, -8.223031480692356],
              [-36.164453458398711, -8.223579497943094],
              [-36.165298520216041, -8.22350414903298],
              [-36.166065911485845, -8.223590876068004],
              [-36.167741852734494, -8.225436750952545],
              [-36.168196668746802, -8.226280190100269],
              [-36.168883778272402, -8.226818085300241],
              [-36.169187697682879, -8.227281001677483],
              [-36.169568708584599, -8.227663147608919],
              [-36.170413717395931, -8.227596824643481],
              [-36.171181185672175, -8.227674510734619],
              [-36.172173829765185, -8.228449459703203],
              [-36.172554268610376, -8.22891291313419],
              [-36.173089962423354, -8.229223868618565],
              [-36.174241145865132, -8.229304250977933],
              [-36.175009078246234, -8.229237376958899],
              [-36.175776553522688, -8.229315055050344],
              [-36.177222453470669, -8.231159293778392],
              [-36.178213521406555, -8.232160094357752],
              [-36.178514190496443, -8.233083760899509],
              [-36.178737266105315, -8.234079161942633],
              [-36.178043085888625, -8.234535055623329],
              [-36.179180719998008, -8.236530720193604],
              [-36.179714262657953, -8.237067527288234],
              [-36.181132500924647, -8.237420822168561],
              [-36.182550743191491, -8.237774112049065],
              [-36.184523525716429, -8.240330241795252],
              [-36.185075906097715, -8.241053434758557],
              [-36.188020510083035, -8.242762963434863],
              [-36.188577295570703, -8.243300591542983],
              [-36.189360469450286, -8.243754359335076],
              [-36.190677330159119, -8.244517337622936],
              [-36.192415535639967, -8.245438153253927],
              [-36.193040167444231, -8.246219595914175],
              [-36.193943469616968, -8.24643359227028],
              [-36.194833455549144, -8.245765319040423],
              [-36.195378554682613, -8.245556350730396],
              [-36.196554468738334, -8.244576188630436],
              [-36.197748031507381, -8.243804911259383],
              [-36.198780806342981, -8.243194111782163],
              [-36.199697288035217, -8.242585482205122],
              [-36.20018105489725, -8.242008500111837],
              [-36.200721691195568, -8.241623770878917],
              [-36.201182508792392, -8.2416269882017],
              [-36.201590004996675, -8.241344587240672],
              [-36.202021862426101, -8.241856621413927],
              [-36.202493897478305, -8.242476397954727],
              [-36.203283965449224, -8.242741538912222],
              [-36.204106292131051, -8.243074864474531],
              [-36.204454271139021, -8.243640875253924],
              [-36.207334800113493, -8.244666763089924],
              [-36.207835676308108, -8.244812559566286],
              [-36.208226590627554, -8.244540216531025],
              [-36.208613560115325, -8.244082124634547],
              [-36.208697621239324, -8.243088853802405],
              [-36.208627001451354, -8.242166786417743],
              [-36.208317644278125, -8.24144068365109],
              [-36.207418560493345, -8.239330400955128],
              [-36.206147058686739, -8.23691236087547],
              [-36.204662815407673, -8.234550196295583],
              [-36.203541695302448, -8.233719740568848],
              [-36.202393194128639, -8.233250949598352],
              [-36.201779355722557, -8.233165354668794],
              [-36.201166532417147, -8.232935205628872],
              [-36.200861482838917, -8.232707202804306],
              [-36.20071111829408, -8.232245368532444],
              [-36.200943904858875, -8.231867522028615],
              [-36.201175674323046, -8.231634228635219],
              [-36.201636987970417, -8.231565165402378],
              [-36.202414134204567, -8.230269540946109],
              [-36.202877095015509, -8.22996557715887],
              [-36.203186453960761, -8.22966054126416],
              [-36.203428936487704, -8.227900402267508],
              [-36.203473344815094, -8.227024313687828],
              [-36.203517751142449, -8.226148226108171],
              [-36.203291406215776, -8.225609066255828],
              [-36.203065061289138, -8.225069906403546],
              [-36.202957230885659, -8.223998505912403],
              [-36.20284940048213, -8.222927105421377],
              [-36.202545436071077, -8.222464203042788],
              [-36.202163878118277, -8.222154355665447],
              [-36.201244981160521, -8.221804626134892],
              [-36.200326086202786, -8.221454894604463],
              [-36.199409892542427, -8.220680535687817],
              [-36.198493126764824, -8.220068801770726],
              [-36.198186218061679, -8.220026004806444],
              [-36.197879310358545, -8.219983207842166],
              [-36.196663422707644, -8.21814062927459],
              [-36.196665009864574, -8.217914765665085],
              [-36.19681968483701, -8.217762249717865],
              [-36.198048452762613, -8.217770816575793],
              [-36.198624835563429, -8.217698035450686],
              [-36.199201218364323, -8.217625254325524],
              [-36.199971354906474, -8.217323428752835],
              [-36.200241337054422, -8.21713557410839],
              [-36.200511320202352, -8.216947719463937],
              [-36.201130013092282, -8.216337645675015],
              [-36.201517457629166, -8.215807277224407],
              [-36.201829525844104, -8.215113752721564],
              [-36.201910853756168, -8.214427658002066],
              [-36.201914590126485, -8.213894617283666],
              [-36.201764224580792, -8.21343278601316],
              [-36.200621097932519, -8.212205105718358],
              [-36.200390990631725, -8.212203504557769],
              [-36.200197269863359, -8.212468688783016],
              [-36.200003550095033, -8.212733873008208],
              [-36.199542766497743, -8.21273066568661],
              [-36.199083061007158, -8.212573869310679],
              [-36.198394326322465, -8.212261883719828],
              [-36.196089399175463, -8.212471698721952],
              [-36.195629633678728, -8.212323934290483],
              [-36.194864394619898, -8.211930096146727],
              [-36.1944036150228, -8.21192688282526],
              [-36.193865241884154, -8.212076721504742],
              [-36.192786336393688, -8.212683570972299],
              [-36.190256082478321, -8.212132844486831],
              [-36.18910441998397, -8.212124798683552],
              [-36.189103370880524, -8.212273867765633],
              [-36.189102320777067, -8.212422935847673],
              [-36.189177237994578, -8.212730647009925],
              [-36.18951500323405, -8.213089427052694],
              [-36.190701285538623, -8.214349511178851],
              [-36.190699124325334, -8.214656684287464],
              [-36.190237833678275, -8.214725740520947],
              [-36.189777561131585, -8.214650245644451],
              [-36.188858445149059, -8.214336634893096],
              [-36.187939332166586, -8.214023022141854],
              [-36.187479061619939, -8.213947524265475],
              [-36.186941259538166, -8.21401604244565],
              [-36.185867756581523, -8.213854939641863],
              [-36.184873955373334, -8.213242646673191],
              [-36.184259584911111, -8.213238346244722],
              [-36.183643621292198, -8.213459906425413],
              [-36.182909896256639, -8.214064622161279],
              [-36.182502998009724, -8.214399973796583],
              [-36.182176168221098, -8.214669334897231],
              [-36.181715899674728, -8.214593831020952],
              [-36.181180203800089, -8.21436420503713],
              [-36.180875188282236, -8.214054881714562],
              [-36.181112367217835, -8.213134988550365],
              [-36.181345724837897, -8.212675846548361],
              [-36.181348913151311, -8.212224125330266],
              [-36.181044472689777, -8.211833493508591],
              [-36.179362553854325, -8.210827870451739],
              [-36.177523286783781, -8.210354196005321],
              [-36.17622182079478, -8.209812009378785],
              [-36.175458213893414, -8.209192282627583],
              [-36.172475386119366, -8.20757217639164],
              [-36.172017369792307, -8.207180462463766],
              [-36.171636943945913, -8.206717015034778],
              [-36.17072078727923, -8.205951655067995],
              [-36.168422403769611, -8.205248852694202],
              [-36.167657786707565, -8.204854979553227],
              [-36.166583190699995, -8.204775140250158],
              [-36.166278194182006, -8.204465812928756],
              [-36.166129475792154, -8.203778125052025],
              [-36.165823392167745, -8.203622379784463],
              [-36.165056027836741, -8.203616978250421],
              [-36.164287583459931, -8.203683845270755],
              [-36.163600511932835, -8.20314595707408],
              [-36.16275716327776, -8.202986425432798],
              [-36.162066311381693, -8.202981557952089],
              [-36.161911635410043, -8.203134057898938],
              [-36.161979485000394, -8.20435422343761],
              [-36.162816870073591, -8.205353944902424],
              [-36.163276540563203, -8.20551077427692],
              [-36.163735632996485, -8.205748911150451],
              [-36.163858707966071, -8.205912451234758],
              [-36.165419139993773, -8.206519687650749],
              [-36.166261409542514, -8.206832799346321],
              [-36.167866752942359, -8.207837921346911],
              [-36.16817066335387, -8.208300832722507],
              [-36.169012939902665, -8.208613940418418],
              [-36.170613435826546, -8.210305665190544],
              [-36.171906700013203, -8.212004263693839],
              [-36.17236587145279, -8.212233360623834],
              [-36.172747389405387, -8.212543225999292],
              [-36.173203300525394, -8.21323307509145],
              [-36.173354205127602, -8.213613596861467],
              [-36.173658070593149, -8.214004228682601],
              [-36.174116669976328, -8.21431463511194],
              [-36.174576357466037, -8.214471456487274],
              [-36.175037133062283, -8.214474692808519],
              [-36.175496885558282, -8.214622479239523],
              [-36.176183419028597, -8.21524166893777],
              [-36.176868869392273, -8.216014441690294],
              [-36.177485412067206, -8.215711586010366],
              [-36.178021616991821, -8.215868941439282],
              [-36.178326119459371, -8.216250541316791],
              [-36.179017004356091, -8.216255389798656],
              [-36.179246591545564, -8.216410592013709],
              [-36.17924442033241, -8.21671776212213],
              [-36.179011575823075, -8.217023313069623],
              [-36.179007233396611, -8.217637651286489],
              [-36.179311737864133, -8.218019252164158],
              [-36.181528863462077, -8.219408101261878],
              [-36.184587700606968, -8.221119063002753],
              [-36.185353510721171, -8.221431613647599],
              [-36.186041731354457, -8.221815898793775],
              [-36.187262407481434, -8.22297187858767],
              [-36.188449420026295, -8.223901025752395],
              [-36.188690539724149, -8.224600337629687],
              [-36.18853422824531, -8.225446804307154],
              [-36.187717526214477, -8.225525351218355],
              [-36.185733798689625, -8.224409781609292],
              [-36.182469738539027, -8.222057396660402],
              [-36.181050202108729, -8.221934504364228],
              [-36.17998097563413, -8.221086760787271],
              [-36.177835561940427, -8.220457331069877],
              [-36.175319954404593, -8.217918944824376],
              [-36.17119092367944, -8.215513762566617],
              [-36.169733689613111, -8.215277638940771],
              [-36.168277611659732, -8.214878893316628],
              [-36.165069067074917, -8.212561463204125],
              [-36.164839479824956, -8.212487567489021],
              [-36.164533391200848, -8.212331819221108],
              [-36.163237919795513, -8.210949407771142],
              [-36.162547630955956, -8.210863226790902],
              [-36.162166073057783, -8.210481077860813],
              [-36.161861131485814, -8.210244024094335],
              [-36.16109319015947, -8.210238606559559],
              [-36.160100546058899, -8.209472686539851],
              [-36.159486772654219, -8.209387042613079],
              [-36.15879481965213, -8.209535747185894],
              [-36.157413611910975, -8.209453714669225],
              [-36.157580261106844, -8.207539539575968],
              [-36.157122767769685, -8.207156851593556],
              [-36.156970820063989, -8.206925393906069],
              [-36.156818873358283, -8.206693936218654],
              [-36.15682215167724, -8.206233191057519],
              [-36.155761774052756, -8.204156760057094],
              [-36.155380537125218, -8.203810749905626],
              [-36.154999302197645, -8.20346473875416],
              [-36.15415809680988, -8.202925750450987],
              [-36.153016855324985, -8.201463104613333],
              [-36.14965879118796, -8.198683793246602],
              [-36.149591252105076, -8.198121441659655],
              [-36.149436877681339, -8.197534826158066],
              [-36.148234743479009, -8.196296177898311],
              [-36.147833828494079, -8.19622249905604],
              [-36.147396673630652, -8.196345328976856],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '1563',
        NOME_UC1: 'RESERVA EXTRATIVISTA ACAÚ-GOIANA',
        ID_WCMC2: null,
        CATEGORI3: 'Reserva Extrativista',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '2007',
        GID7: '1973',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº S/N de 27/09/2007',
        DT_ULTIM10: '27/04/2011',
        CODIGO_U11: '0000.00.1563',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.852949287536944, -7.53758601965977],
              [-34.852088885175185, -7.538044579613086],
              [-34.851510626987803, -7.538321415248774],
              [-34.850787315309212, -7.538555573027423],
              [-34.85013601814881, -7.538688309777555],
              [-34.84937121604947, -7.538679648477604],
              [-34.849099106875848, -7.538276362519163],
              [-34.848625063684707, -7.538076174731472],
              [-34.848085129421321, -7.538280669886078],
              [-34.847072595516785, -7.538621953044021],
              [-34.846263718576537, -7.539164611425946],
              [-34.845849419728644, -7.539556511965891],
              [-34.845735216297768, -7.539903033813324],
              [-34.845457519188699, -7.540256261183309],
              [-34.844633182293684, -7.540577474339742],
              [-34.843461229247218, -7.540914589829157],
              [-34.842520337331834, -7.541077431581556],
              [-34.841825268010361, -7.541138130530442],
              [-34.841115646773645, -7.541184440545528],
              [-34.840202927366178, -7.5411594112513],
              [-34.839232000297727, -7.541076826221882],
              [-34.83813133999444, -7.541139248117531],
              [-34.837312265071986, -7.541420414403198],
              [-34.836560105676611, -7.541683536313961],
              [-34.835953605132111, -7.542133807496356],
              [-34.835390853465611, -7.542656093196925],
              [-34.834971851438709, -7.542917818123826],
              [-34.834624421551766, -7.542977050239649],
              [-34.834164371482323, -7.543013558084631],
              [-34.83382031763972, -7.543041404629648],
              [-34.833422223804639, -7.542805477338106],
              [-34.833129555685161, -7.54246354225436],
              [-34.833021545549421, -7.541962420859321],
              [-34.832886061397687, -7.541223835490491],
              [-34.832539562833318, -7.540670932560232],
              [-34.832246009957387, -7.540117796799329],
              [-34.832003959219904, -7.539221282404689],
              [-34.831998371284449, -7.538781302336766],
              [-34.83228780305798, -7.538357713546169],
              [-34.832603381712829, -7.537854822638238],
              [-34.832840217043888, -7.537510652380313],
              [-34.832970136543224, -7.536929344111485],
              [-34.832915188511251, -7.536454416163899],
              [-34.832992604700372, -7.535978929422768],
              [-34.833149881800381, -7.535608682132711],
              [-34.833148321835061, -7.535239133334585],
              [-34.833120405440852, -7.534896057380685],
              [-34.833039869321098, -7.534632423242506],
              [-34.83298692363266, -7.534632632790085],
              [-34.832458351504698, -7.534846045358357],
              [-34.83214165526276, -7.535084952882144],
              [-34.831773037787208, -7.535561673625693],
              [-34.831589264559916, -7.535932014048001],
              [-34.831777927230732, -7.536723172576245],
              [-34.831752548690538, -7.536987249092647],
              [-34.831701372514992, -7.537409836711468],
              [-34.831623397532326, -7.537753355043591],
              [-34.831413174743872, -7.538123788598163],
              [-34.831071565623027, -7.538732384618463],
              [-34.831047188254601, -7.539234064807047],
              [-34.831048981050564, -7.539656419595225],
              [-34.831051751735224, -7.540316354772514],
              [-34.831213266352954, -7.540949235028982],
              [-34.831587006102666, -7.541686819226044],
              [-34.831827846120802, -7.542292970524578],
              [-34.832094693321864, -7.542968808034857],
              [-34.832362215731798, -7.543627648908121],
              [-34.832708271917944, -7.544074963141334],
              [-34.832895374623448, -7.544496549588388],
              [-34.833135073771423, -7.544838717502778],
              [-34.832864152034091, -7.545155227480208],
              [-34.832540773552665, -7.545437022408613],
              [-34.832142307188562, -7.545870157255607],
              [-34.831828893858699, -7.5466234808042],
              [-34.831599113296157, -7.547101039738067],
              [-34.831325886535446, -7.547578784936448],
              [-34.830951215463152, -7.54804251373006],
              [-34.830750212768208, -7.548476626465764],
              [-34.830530280941908, -7.548918026800621],
              [-34.83031516870993, -7.549438845668135],
              [-34.829795257881926, -7.549816543538796],
              [-34.829332483693953, -7.550006230664444],
              [-34.828478111645872, -7.55008201703906],
              [-34.82746394792683, -7.550057383556933],
              [-34.826530227195278, -7.550148280640336],
              [-34.825944192464384, -7.549719266895742],
              [-34.825446307415298, -7.549098191152929],
              [-34.825119971984698, -7.548473203855295],
              [-34.824870237835967, -7.547651032285273],
              [-34.824575683788268, -7.546496867500004],
              [-34.823999567642808, -7.543841876383652],
              [-34.823485477580903, -7.542063865170903],
              [-34.823182169100988, -7.539390107904637],
              [-34.822397948925939, -7.535364442787177],
              [-34.821455799725079, -7.532081577273196],
              [-34.787650815845907, -7.532618088926121],
              [-34.787974240893462, -7.577478104612062],
              [-34.831881746414879, -7.577223155056906],
              [-34.832078045930821, -7.575810827653591],
              [-34.832273996200797, -7.574317824432136],
              [-34.832469783489316, -7.572784518226214],
              [-34.832625803303848, -7.571579270397656],
              [-34.832903337431468, -7.570080655957682],
              [-34.833179847104262, -7.568506674238257],
              [-34.833458917914129, -7.567537586531884],
              [-34.833817034727637, -7.566124583919702],
              [-34.833987583174348, -7.565531261589655],
              [-34.834577273346355, -7.564145267332172],
              [-34.834931827851008, -7.563719280385953],
              [-34.835270922400902, -7.563957512700867],
              [-34.835524544821389, -7.564411253059859],
              [-34.835841567026229, -7.564974516953768],
              [-34.836221523354141, -7.565537524733976],
              [-34.836616660240033, -7.565974990230949],
              [-34.837164440896125, -7.566348706697603],
              [-34.836985557112357, -7.566580000659329],
              [-34.836704227845239, -7.566888571111797],
              [-34.836577055747497, -7.567196489638455],
              [-34.836347112203498, -7.567504850543344],
              [-34.836301360981956, -7.567918171502534],
              [-34.836283921966725, -7.5684480474821],
              [-34.836266413102294, -7.568958272555321],
              [-34.836209183330006, -7.569390196682967],
              [-34.836152069973039, -7.56984172515083],
              [-34.836153979184317, -7.570293020788057],
              [-34.836239078784693, -7.570599262934081],
              [-34.836215120511426, -7.571178615425495],
              [-34.83621835685738, -7.57194216023936],
              [-34.836379615361402, -7.57259972054409],
              [-34.836382618876705, -7.573310622349346],
              [-34.836492491657602, -7.574310699814433],
              [-34.836521921451002, -7.575021485204374],
              [-34.836525367344528, -7.575837696309904],
              [-34.836527835349358, -7.576416932385996],
              [-34.836444738092524, -7.576749693942368],
              [-34.836474144602853, -7.577084620823733],
              [-34.836615961748102, -7.577502807943389],
              [-34.836729769366883, -7.577921134761425],
              [-34.837011005501743, -7.578199134550331],
              [-34.837545398395804, -7.578727357432328],
              [-34.838247103390628, -7.57908736017405],
              [-34.838752159623546, -7.579308572568898],
              [-34.839201569332559, -7.579613813543155],
              [-34.839595798178941, -7.580114702107544],
              [-34.839876568652514, -7.580281036319604],
              [-34.840462021306799, -7.580339174131403],
              [-34.840806331263117, -7.580574682327786],
              [-34.841255950519702, -7.580730771991512],
              [-34.841679143498204, -7.580886954787495],
              [-34.842419475096861, -7.581094453457411],
              [-34.843240016852377, -7.581538577910798],
              [-34.844033503687058, -7.581824843187569],
              [-34.844668525985441, -7.582111760390148],
              [-34.845328507728865, -7.582056300130752],
              [-34.845854635135069, -7.581580138180747],
              [-34.846327956551214, -7.581104185778329],
              [-34.846591357858742, -7.580945092599293],
              [-34.847408779683626, -7.580651982101593],
              [-34.848120985340493, -7.580464646565446],
              [-34.849098757629882, -7.580618477771921],
              [-34.849918181798309, -7.580799294050038],
              [-34.850843148097638, -7.580953334804093],
              [-34.851663037927352, -7.581239460382477],
              [-34.852192424962567, -7.581526843246344],
              [-34.852789798545821, -7.581633363265965],
              [-34.853688897360612, -7.581919162881448],
              [-34.854904459586699, -7.58209827949586],
              [-34.855774896949214, -7.581857602459181],
              [-34.856619164147347, -7.581669684846425],
              [-34.857832677463769, -7.581374874685018],
              [-34.859018530499682, -7.580790562900887],
              [-34.859703331989749, -7.58036634546756],
              [-34.859913461645945, -7.580102152535837],
              [-34.860308202719743, -7.579784501688252],
              [-34.860809277548654, -7.579624384054391],
              [-34.861707142361027, -7.579620542348763],
              [-34.862710735568839, -7.579642568127682],
              [-34.863636237378657, -7.579928228044778],
              [-34.864483112279999, -7.580345879653947],
              [-34.8653831191343, -7.580842274586914],
              [-34.865913647039669, -7.581392919059923],
              [-34.866495979763329, -7.58170635567285],
              [-34.867525999249224, -7.581728265036446],
              [-34.868740537020479, -7.581670383338356],
              [-34.869920802120937, -7.581588752914577],
              [-34.87054530047422, -7.581508193511759],
              [-34.871034780337055, -7.581488263208634],
              [-34.871581070877028, -7.581516179602851],
              [-34.872269364826757, -7.581634224739346],
              [-34.872881057494617, -7.581866333608325],
              [-34.873382597984815, -7.582126777966685],
              [-34.873911309811163, -7.582256138672477],
              [-34.874783004459154, -7.582305033107716],
              [-34.875601171342097, -7.582196184781651],
              [-34.876049300482563, -7.582009943549537],
              [-34.876551166935663, -7.582034111370376],
              [-34.876869423142914, -7.582348688853457],
              [-34.877267028033643, -7.582689250236406],
              [-34.877823400140493, -7.583108112564929],
              [-34.878352717326514, -7.583369115716827],
              [-34.879251140932439, -7.583496869891178],
              [-34.880385631528291, -7.583255005418209],
              [-34.881599470807608, -7.583039124845115],
              [-34.882417055613942, -7.582798610789887],
              [-34.883496551611685, -7.582056719225911],
              [-34.884327221500214, -7.581234361391374],
              [-34.88501025347739, -7.580415193336663],
              [-34.885506019767611, -7.579043937258952],
              [-34.885820387703113, -7.578463327482055],
              [-34.88639906498566, -7.577934243126677],
              [-34.887005379265645, -7.577694637110959],
              [-34.887756723753768, -7.577559246091489],
              [-34.888575519279449, -7.577228626577039],
              [-34.889188166552948, -7.576653604735604],
              [-34.889717553588163, -7.57579273671255],
              [-34.890246940623378, -7.574931891972568],
              [-34.890366033497777, -7.574031931684395],
              [-34.890397069822619, -7.572659441604266],
              [-34.89092210292479, -7.571946281341759],
              [-34.891553562914325, -7.571416964155738],
              [-34.892449308967841, -7.570939125825099],
              [-34.89347872309407, -7.57082934617646],
              [-34.8945877885836, -7.570824526582136],
              [-34.895960930589531, -7.57081854283458],
              [-34.897202034337262, -7.570813117880576],
              [-34.898310308202603, -7.570623989548473],
              [-34.899312713974126, -7.570382660585992],
              [-34.900316167483545, -7.570378283369884],
              [-34.901187582734764, -7.570374464947321],
              [-34.901609727975362, -7.57029362614773],
              [-34.901872756753868, -7.570055533531196],
              [-34.90229364470899, -7.56968507669356],
              [-34.902583192897843, -7.569473177524479],
              [-34.902872275425409, -7.569155969055117],
              [-34.903188226609288, -7.568943953470715],
              [-34.903266294724212, -7.568680319332536],
              [-34.903159029646531, -7.568312190801343],
              [-34.902814486859569, -7.568024086162481],
              [-34.902549502303657, -7.567814608432094],
              [-34.902283935671136, -7.567473488255601],
              [-34.902150523712137, -7.567158119148331],
              [-34.901911709320608, -7.566895881994014],
              [-34.901620205354348, -7.566660187533117],
              [-34.901302624355957, -7.566503608925039],
              [-34.900853144797757, -7.566373922256339],
              [-34.900408787513719, -7.566093780425504],
              [-34.900141381519113, -7.565331399764865],
              [-34.900004383968195, -7.564199842835123],
              [-34.900081404345222, -7.563699256950571],
              [-34.900290695811094, -7.563250755281068],
              [-34.900551652396864, -7.562538735888715],
              [-34.90068147876395, -7.562037917173519],
              [-34.900891468721753, -7.561747391095963],
              [-34.901129002544742, -7.561720010212227],
              [-34.901419458773105, -7.561718752926751],
              [-34.902025982600669, -7.561531813202699],
              [-34.90242070039141, -7.561214139072057],
              [-34.902815394899086, -7.560896464941415],
              [-34.903209996274498, -7.560552457664933],
              [-34.903735099225862, -7.559865607265195],
              [-34.904103274323191, -7.559521739687099],
              [-34.904286954418225, -7.559257639887633],
              [-34.904708401166893, -7.559018825496103],
              [-34.904971662776035, -7.558833375888177],
              [-34.905208963768381, -7.55875335199584],
              [-34.905711016485995, -7.558830139542223],
              [-34.905869923400509, -7.558934773633631],
              [-34.906003801020795, -7.559355452041174],
              [-34.905978888141895, -7.559697829503143],
              [-34.905954114961375, -7.560066540110945],
              [-34.905903148333408, -7.560488033425742],
              [-34.905904079655983, -7.560698652026289],
              [-34.905905336941458, -7.560988270064335],
              [-34.90609157817358, -7.561303406340961],
              [-34.906858964693072, -7.561668647772166],
              [-34.90778397755853, -7.561848905256738],
              [-34.908655718772657, -7.561924062988616],
              [-34.909819080368365, -7.562261248327225],
              [-34.910824582787448, -7.562730751320807],
              [-34.911485309588933, -7.562859506666932],
              [-34.911960959311521, -7.562936387345573],
              [-34.912332044791896, -7.563250708714939],
              [-34.912915518385702, -7.563827383653951],
              [-34.913630215330464, -7.564219167778575],
              [-34.914288660391634, -7.564698403093075],
              [-34.914950551346337, -7.565090420048342],
              [-34.915690626831292, -7.565245136011271],
              [-34.916218384052002, -7.565163831550393],
              [-34.916640994953887, -7.56518830205107],
              [-34.917011265527009, -7.565318314682671],
              [-34.917672225159137, -7.565499713037397],
              [-34.918385990781317, -7.565680878561473],
              [-34.919602414480785, -7.566070427511917],
              [-34.920475552678774, -7.56646151314461],
              [-34.921084288397452, -7.56677478677608],
              [-34.921771907138321, -7.567008688441021],
              [-34.922828492600694, -7.567083007982582],
              [-34.924333626298697, -7.567076372309231],
              [-34.925231886923171, -7.567177723488555],
              [-34.926103278891325, -7.567173858499864],
              [-34.926816252889317, -7.567170692003103],
              [-34.927527806620382, -7.566851620888592],
              [-34.927974468927793, -7.56634938190647],
              [-34.928499082934806, -7.565557198923393],
              [-34.928840249677428, -7.565081782031449],
              [-34.929578439234163, -7.564815237510224],
              [-34.930184590532704, -7.564549251782545],
              [-34.931477988043071, -7.56443821484843],
              [-34.932877719308543, -7.564484664561903],
              [-34.933749111276697, -7.564480776290146],
              [-34.934699712229936, -7.564476562055489],
              [-34.935437901786671, -7.5642099942512],
              [-34.93585075708458, -7.564180331627156],
              [-34.93651160030138, -7.564335350269921],
              [-34.937119753943456, -7.56451695817222],
              [-34.937833635980958, -7.564724410276007],
              [-34.939181725409597, -7.565034331146208],
              [-34.940423387950879, -7.565160455506053],
              [-34.941504700027643, -7.564866017873676],
              [-34.942347803072558, -7.564441008816154],
              [-34.942741985352811, -7.564018002102173],
              [-34.943162826741805, -7.563647521981473],
              [-34.943478638227297, -7.563409149968173],
              [-34.943847372118171, -7.563196878270055],
              [-34.944348819476104, -7.563142000087268],
              [-34.944771546793312, -7.563192757167656],
              [-34.945036671047617, -7.563428521477746],
              [-34.945090291944922, -7.563612597384882],
              [-34.945118487735911, -7.564007385024809],
              [-34.945120024418159, -7.564349646071456],
              [-34.945253576075544, -7.564691325041501],
              [-34.945440073421373, -7.565059081043664],
              [-34.945968994795301, -7.565241014908871],
              [-34.946655658930524, -7.5652642746902],
              [-34.947209586315616, -7.565130164239271],
              [-34.947735690438748, -7.564680219019777],
              [-34.94823536828379, -7.564230413498677],
              [-34.948841170336365, -7.563885428333492],
              [-34.949368438612716, -7.563698768006219],
              [-34.949795985524254, -7.563319230773466],
              [-34.950246512820357, -7.563194573246676],
              [-34.95090262957509, -7.563191616297502],
              [-34.951436277411091, -7.563311873325112],
              [-34.951736046865214, -7.563954672167014],
              [-34.952029576458074, -7.564637890408704],
              [-34.952191463604834, -7.565400690164509],
              [-34.952352605693534, -7.566005514328374],
              [-34.952476378463871, -7.566618673829296],
              [-34.952911864900372, -7.567056139326269],
              [-34.953625048445943, -7.567105592555045],
              [-34.954153154912618, -7.56710321768248],
              [-34.954892625037893, -7.567126221350101],
              [-34.955342360709807, -7.567308504461273],
              [-34.955844646258065, -7.567437888450137],
              [-34.956821184545042, -7.567328178650691],
              [-34.956977833002306, -7.56693255282044],
              [-34.956975574545062, -7.566432339464917],
              [-34.956866865917384, -7.565748306315967],
              [-34.956731498180979, -7.565011723290681],
              [-34.956729006893084, -7.564458843643486],
              [-34.956858786694042, -7.56395802492829],
              [-34.957147450126449, -7.563561793738366],
              [-34.957805685640039, -7.563137599588096],
              [-34.9592083040055, -7.562829471513858],
              [-34.959471262934812, -7.562591332331195],
              [-34.959866516236026, -7.562405254080524],
              [-34.960262840558208, -7.562456127576233],
              [-34.960817932096518, -7.562585278734453],
              [-34.961267411654724, -7.562714895553952],
              [-34.962007463856608, -7.562869518384616],
              [-34.962482531502587, -7.562814733334093],
              [-34.962673103384681, -7.563297880203415],
              [-34.96272793500134, -7.563745217719692],
              [-34.96267631644757, -7.564008735442542],
              [-34.96244010975925, -7.564325734364324],
              [-34.962336267292038, -7.564721127363931],
              [-34.96233840933396, -7.565195007573621],
              [-34.962420249305318, -7.565773871120683],
              [-34.962607235595499, -7.566246913140056],
              [-34.962714989617531, -7.566720350971522],
              [-34.962822999753278, -7.567246408528526],
              [-34.962877598539286, -7.567641079753137],
              [-34.962800764426774, -7.568167998783522],
              [-34.962723930314262, -7.56869489453085],
              [-34.962622020341392, -7.569511524731539],
              [-34.962730728969063, -7.570195581163553],
              [-34.962838389858845, -7.570642662566122],
              [-34.963209731452928, -7.571009580377968],
              [-34.963686708310178, -7.571376009245462],
              [-34.964479589785185, -7.571530399245482],
              [-34.965058243784668, -7.571027554903679],
              [-34.965160759117211, -7.570342567149098],
              [-34.965421110343307, -7.569525238456471],
              [-34.965684092555684, -7.569287099273808],
              [-34.966290686232441, -7.569126376280273],
              [-34.966844962863497, -7.569071218700714],
              [-34.967633071310303, -7.568172515698016],
              [-34.967734143092855, -7.567171600042613],
              [-34.967570346734817, -7.565987563085727],
              [-34.967407132453388, -7.564935191858005],
              [-34.967350251927066, -7.564040307277871],
              [-34.967530788808403, -7.563091685385103],
              [-34.967721523671955, -7.562380737013711],
              [-34.96759758792016, -7.561601080319285],
              [-34.967262824020246, -7.560792389643552],
              [-34.978816835180133, -7.55903149148314],
              [-34.978663842163975, -7.557560653738996],
              [-34.978660163439798, -7.55675189321407],
              [-34.977980228110191, -7.555878778299146],
              [-34.97709840532918, -7.55520878483803],
              [-34.976151785779955, -7.555145687733514],
              [-34.975206353667012, -7.555352161948598],
              [-34.973587132953462, -7.556033494262067],
              [-34.972913018389946, -7.556440947889037],
              [-34.971428071108775, -7.556919461428542],
              [-34.969875812488432, -7.55746565883625],
              [-34.969335971357303, -7.557670316972306],
              [-34.968525115356584, -7.557741376884849],
              [-34.968050420239635, -7.557406519852677],
              [-34.967575422442856, -7.557004258349075],
              [-34.967233464076045, -7.556129606751902],
              [-34.965756572735152, -7.553063250453768],
              [-34.965336895499384, -7.553600716712324],
              [-34.964954983394065, -7.554004142369145],
              [-34.964668741400352, -7.554349732894003],
              [-34.96426722495481, -7.55465760485454],
              [-34.963788432018532, -7.554851040553558],
              [-34.963213177346454, -7.554930133123321],
              [-34.962445371731803, -7.5548570941503],
              [-34.961773073247315, -7.554687965970516],
              [-34.96131146321256, -7.554441375035474],
              [-34.960984359440836, -7.554232432815567],
              [-34.960561655406693, -7.554100417840431],
              [-34.960235645938994, -7.554140138748295],
              [-34.959641997646038, -7.554391479428475],
              [-34.959001876356545, -7.554799515132046],
              [-34.958638474287426, -7.555049808074322],
              [-34.95831456029552, -7.55517840043899],
              [-34.95705275790354, -7.555355095614708],
              [-34.956020596375716, -7.555002310622953],
              [-34.954897630896738, -7.554381886805942],
              [-34.953507841499764, -7.554209405864881],
              [-34.951894814081342, -7.554216623614842],
              [-34.949990794924076, -7.554235645878457],
              [-34.947704607547777, -7.553626421215419],
              [-34.945600796414908, -7.552390649289435],
              [-34.943699291828601, -7.551701819829226],
              [-34.941801791929365, -7.551909504763657],
              [-34.94028627398464, -7.552408530682897],
              [-34.939066520806961, -7.551739957488707],
              [-34.938318156551084, -7.550664861990136],
              [-34.938177247445346, -7.549384875524858],
              [-34.937429185869306, -7.548377184497717],
              [-34.937021220014927, -7.547839788088353],
              [-34.936284171328346, -7.547756085471839],
              [-34.93553559752489, -7.548183399552734],
              [-34.934591073451458, -7.548591970766786],
              [-34.933104868884811, -7.548800773288306],
              [-34.932022718617723, -7.548670760656712],
              [-34.931412865311948, -7.548336462418085],
              [-34.930534115895441, -7.548340350689841],
              [-34.929859349406129, -7.54861292552475],
              [-34.929182557290211, -7.549265316989178],
              [-34.929021182370867, -7.549534189816846],
              [-34.928663158689616, -7.550074752722971],
              [-34.9277923953642, -7.550128513318668],
              [-34.926934461007271, -7.549275258857676],
              [-34.925735406473841, -7.54849634722131],
              [-34.924782197817386, -7.546950328462223],
              [-34.92307857599539, -7.543857382904541],
              [-34.922394868809349, -7.54210794001181],
              [-34.921645363683318, -7.540763226627504],
              [-34.920724006258965, -7.539462006007497],
              [-34.919570144153532, -7.538388656738753],
              [-34.918013973978368, -7.538058479602526],
              [-34.915172019854893, -7.537396937893789],
              [-34.913886235906581, -7.537065550038214],
              [-34.912901385565618, -7.537019845382801],
              [-34.9123312531677, -7.537004944221586],
              [-34.911475391003499, -7.536812113882242],
              [-34.910751567097499, -7.536323006548436],
              [-34.910085485191203, -7.53600402856619],
              [-34.909191601782837, -7.535705004170126],
              [-34.908203235699148, -7.535944563619715],
              [-34.907323624809258, -7.535746191911045],
              [-34.90624003099218, -7.535279110356157],
              [-34.904410261527275, -7.534208648187402],
              [-34.903122708066071, -7.533472833503232],
              [-34.902039137532057, -7.53300572866528],
              [-34.900614935765901, -7.531933496983626],
              [-34.899189569846527, -7.530591647416244],
              [-34.898714362502162, -7.530121888308955],
              [-34.898071586943331, -7.529444886645457],
              [-34.897232767982267, -7.529309239512273],
              [-34.896255438071101, -7.52945275632122],
              [-34.895280552881694, -7.530153436861212],
              [-34.89476806935123, -7.53087313966482],
              [-34.894814053403415, -7.532017688544492],
              [-34.895105557369682, -7.533038511219949],
              [-34.895396688806912, -7.533977587056313],
              [-34.895770544971946, -7.535079807324912],
              [-34.896249663871131, -7.536246428549632],
              [-34.896851368104365, -7.536937609599349],
              [-34.897259007995842, -7.537407648103411],
              [-34.897260474828897, -7.537744670460576],
              [-34.897127039586834, -7.538149656082709],
              [-34.896435881820182, -7.538723769884633],
              [-34.895735550526162, -7.539047520895089],
              [-34.895121413136536, -7.539021024767806],
              [-34.894829094263017, -7.538227025705709],
              [-34.894080846422462, -7.537151837074873],
              [-34.893672042377766, -7.536412180685083],
              [-34.893208499848669, -7.536172155574206],
              [-34.892565631157574, -7.536262400731808],
              [-34.892274010775992, -7.536438583680109],
              [-34.891894031165016, -7.536469387174307],
              [-34.891371745464447, -7.53595031453662],
              [-34.891166901063869, -7.535479391276105],
              [-34.890555324811331, -7.534740596359697],
              [-34.890416324916877, -7.533864966873821],
              [-34.890275881472427, -7.532652338313852],
              [-34.889664584616661, -7.531980947868874],
              [-34.88891957312206, -7.531647161857663],
              [-34.887637025519695, -7.53205710677252],
              [-34.886760348295923, -7.532532709928972],
              [-34.885816336449906, -7.533075996953635],
              [-34.884883104662713, -7.533519213367512],
              [-34.883705423982398, -7.534752494005602],
              [-34.882346088833657, -7.535138294382676],
              [-34.881254229528714, -7.534437381012047],
              [-34.880168400537457, -7.534334469867403],
              [-34.879078054631705, -7.534772401025663],
              [-34.878046265632911, -7.535373895711324],
              [-34.876632238565897, -7.535705632812864],
              [-34.87548969202976, -7.535873364008786],
              [-34.874891084444087, -7.535930221252045],
              [-34.874508846375868, -7.535660463667931],
              [-34.874560930590924, -7.535117456040041],
              [-34.875593185251006, -7.534624530283672],
              [-34.876951379529594, -7.533967389074107],
              [-34.87809157447623, -7.533256883080938],
              [-34.878960498439554, -7.532764655816507],
              [-34.878903711045488, -7.532222113849905],
              [-34.878467037172705, -7.531952612379499],
              [-34.877529521301661, -7.531684438043762],
              [-34.87618688011009, -7.531277100832121],
              [-34.874555901449014, -7.531832006105915],
              [-34.873676290559132, -7.531633564548052],
              [-34.873067345292867, -7.531501363308401],
              [-34.872524849892393, -7.531099264786249],
              [-34.872386478640678, -7.530358420960177],
              [-34.872248386785735, -7.529684981605527],
              [-34.872110597610629, -7.529078946722315],
              [-34.871906637966504, -7.528810213593033],
              [-34.8715666353771, -7.528339825843005],
              [-34.870958295470508, -7.528342433546214],
              [-34.870082153757217, -7.52895282236247],
              [-34.86982126702064, -7.529669032706416],
              [-34.869412858788031, -7.530505942456202],
              [-34.869281425889504, -7.53138275937836],
              [-34.869013647365868, -7.5319905404914],
              [-34.868408450672973, -7.53273455081424],
              [-34.86793673578827, -7.533073598797998],
              [-34.867532308959682, -7.533344939630496],
              [-34.866653559543174, -7.533348688203859],
              [-34.865774810126666, -7.533352436777228],
              [-34.864514614266128, -7.533131992723511],
              [-34.863663478564, -7.533385801408514],
              [-34.863007385092331, -7.533388595376238],
              [-34.862473411293429, -7.53318645181114],
              [-34.861734942339922, -7.533107824902665],
              [-34.861175683133084, -7.532765400874567],
              [-34.860364524452521, -7.532768846768093],
              [-34.859745614034701, -7.532423419224692],
              [-34.859277228628208, -7.53142539066927],
              [-34.859342234944002, -7.530818494312676],
              [-34.859610036750709, -7.530210713199637],
              [-34.859617207934541, -7.529741675867335],
              [-34.858998786461072, -7.529539276188522],
              [-34.858796293650002, -7.529607542133341],
              [-34.858458596083977, -7.529676390154762],
              [-34.857715051422424, -7.529679533368459],
              [-34.857105524079557, -7.529412523185947],
              [-34.856698815510654, -7.529144628246982],
              [-34.855887074753483, -7.529013265197648],
              [-34.855482089131343, -7.529149773804214],
              [-34.85534918939976, -7.529689568369214],
              [-34.855621857366927, -7.530227663270516],
              [-34.856299254842519, -7.530561798527692],
              [-34.857178865732408, -7.530760286651685],
              [-34.857450113432641, -7.530961335912757],
              [-34.857453279929402, -7.531702761815446],
              [-34.857388273613601, -7.532309681455104],
              [-34.85684980618327, -7.532851198966874],
              [-34.85604064984625, -7.533326452877361],
              [-34.85556863228171, -7.533598073106624],
              [-34.855232075585839, -7.533936515730716],
              [-34.854759778624526, -7.534140731488549],
              [-34.853745242376455, -7.534010229912603],
              [-34.853134853560199, -7.533541006315787],
              [-34.852661695125505, -7.533543008659329],
              [-34.852461763451522, -7.534217891563962],
              [-34.852262134457369, -7.534960178940032],
              [-34.852394941056694, -7.535722373336164],
              [-34.852509470450471, -7.536444218181693],
              [-34.852949287536944, -7.53758601965977],
            ],
            [
              [-34.868145119214631, -7.565557245489522],
              [-34.868050031179628, -7.566806591442052],
              [-34.866054206899442, -7.566755508398764],
              [-34.865965498424089, -7.565238477054848],
              [-34.863005894976212, -7.565128115329607],
              [-34.859589361389524, -7.565203482609057],
              [-34.858412006672111, -7.567280308670291],
              [-34.858082365046371, -7.568722065867],
              [-34.857395584495826, -7.570005405093546],
              [-34.855800159074086, -7.571047624905191],
              [-34.852686561438283, -7.570841267105436],
              [-34.853238160516931, -7.569551618168433],
              [-34.854478076828379, -7.56907766810955],
              [-34.854880035652144, -7.567229039362488],
              [-34.856786848777141, -7.564895121704176],
              [-34.857369647162088, -7.561488553269051],
              [-34.861918738850278, -7.559194566066182],
              [-34.861481529467014, -7.554379069555146],
              [-34.857284943373806, -7.555230321672603],
              [-34.853260349277306, -7.556105299232676],
              [-34.848176655147469, -7.556298432251857],
              [-34.846574570769313, -7.555006129045509],
              [-34.844369548155512, -7.556339061199232],
              [-34.842446599866889, -7.554680422257647],
              [-34.844623938917124, -7.552612304062507],
              [-34.841212341340089, -7.548563192899195],
              [-34.841993627849021, -7.547272333242844],
              [-34.845049413635927, -7.548688735182424],
              [-34.847073713103875, -7.551082257485596],
              [-34.849750380753193, -7.550923862798498],
              [-34.853310221601241, -7.550516595436051],
              [-34.856180744044735, -7.549744575586743],
              [-34.85960780157653, -7.547009281181275],
              [-34.861025484084649, -7.54538554355323],
              [-34.862204026238345, -7.545282469427143],
              [-34.863731465112039, -7.546354444995082],
              [-34.865198205036855, -7.548149196731131],
              [-34.866888718493591, -7.549453839961608],
              [-34.86865981463621, -7.550059013371438],
              [-34.87042942066271, -7.550321064261233],
              [-34.872338748358665, -7.550199875911048],
              [-34.874308518889791, -7.549912260216537],
              [-34.87533546501119, -7.548829388174454],
              [-34.876260571008913, -7.546913587786598],
              [-34.878495768474174, -7.546928488947813],
              [-34.878132156857475, -7.548871577087141],
              [-34.878015043043554, -7.550747190905845],
              [-34.878568574616544, -7.552818033219523],
              [-34.881124542860022, -7.552750698597286],
              [-34.88400598506071, -7.551966874234331],
              [-34.886759649803992, -7.550747842831647],
              [-34.888557125658572, -7.549257610294859],
              [-34.888904741810038, -7.546227645426377],
              [-34.889240157635754, -7.545273179484447],
              [-34.890935071591663, -7.54460933275233],
              [-34.891567462903772, -7.544405326542076],
              [-34.891325342317096, -7.54651365458944],
              [-34.892755527830808, -7.548413482795112],
              [-34.892660742475641, -7.551018787852051],
              [-34.891694006358776, -7.55320432254095],
              [-34.891986767610518, -7.557078624456764],
              [-34.889750149878324, -7.559458270053554],
              [-34.885313957769398, -7.561165081655368],
              [-34.884960823531671, -7.563846964711111],
              [-34.880820000377696, -7.564857566121297],
              [-34.877173569813145, -7.565171119149547],
              [-34.874222930345063, -7.565349257875255],
              [-34.870607792219062, -7.565232423458106],
              [-34.868145119214631, -7.565557245489522],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3328',
        NOME_UC1: 'REFÚGIO DA VIDA SILVESTRE MATA DO ENGENHO SALGADINHO',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '13763',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '05/09/2017',
        CODIGO_U11: '0000.26.3328',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.020344552982806, -8.187691782730342],
              [-35.020175126448883, -8.187417568325223],
              [-35.020030007689925, -8.187203028564026],
              [-35.019885611821969, -8.187131995905096],
              [-35.019705510177943, -8.187120937845243],
              [-35.019417985251181, -8.18723000995646],
              [-35.019010672186042, -8.187387516678722],
              [-35.018663614635578, -8.187592560146564],
              [-35.018424462295187, -8.187773144844865],
              [-35.018209916684356, -8.188073197813067],
              [-35.017983850090609, -8.188468983173779],
              [-35.017876908735197, -8.188684784746423],
              [-35.017732994794606, -8.188709422489071],
              [-35.017600661827792, -8.188650287764327],
              [-35.017503795673335, -8.188483342739834],
              [-35.017478706016469, -8.188268202068432],
              [-35.017585226435465, -8.187968688019435],
              [-35.01769186683628, -8.187693092820869],
              [-35.017678961062359, -8.187513768934062],
              [-35.017510439392161, -8.187418937406854],
              [-35.017222612510899, -8.187468212892121],
              [-35.016887074943448, -8.187577522967464],
              [-35.016623252882617, -8.187626677470712],
              [-35.016335785946886, -8.187747706507137],
              [-35.016240425565286, -8.187879734612382],
              [-35.016253090375542, -8.188011222798453],
              [-35.016241689374496, -8.18813087304134],
              [-35.016037880864744, -8.188179727589523],
              [-35.01578497887764, -8.18801356144831],
              [-35.015411866815811, -8.187812117441789],
              [-35.015062882556201, -8.18763447130365],
              [-35.014533673670861, -8.187421846255299],
              [-35.014164411027664, -8.187985774460826],
              [-35.013627106641692, -8.188550540540621],
              [-35.013185643564597, -8.189078950916722],
              [-35.012731695649983, -8.189511749882282],
              [-35.012528667022131, -8.189716069457862],
              [-35.012481316281431, -8.189847858598961],
              [-35.012518105893925, -8.190003145653376],
              [-35.012869016862588, -8.190563480397664],
              [-35.013267939472044, -8.191123576177908],
              [-35.013799738966412, -8.191850437009744],
              [-35.014066149636349, -8.192315518289845],
              [-35.014308556485844, -8.192780719552081],
              [-35.014490646829763, -8.193186425143466],
              [-35.014769244382528, -8.193687322208277],
              [-35.015180597838388, -8.194331067474389],
              [-35.015422585751374, -8.194712554260439],
              [-35.015567646519187, -8.194915136096679],
              [-35.015628745906554, -8.195130097795159],
              [-35.015557991254383, -8.195381595170449],
              [-35.015390972357011, -8.195585737773053],
              [-35.015187400810866, -8.195682429021897],
              [-35.014875320145727, -8.195683988788248],
              [-35.014670545781499, -8.195541500533118],
              [-35.014501057256936, -8.195255325202524],
              [-35.014283919037027, -8.195041143387249],
              [-35.014079806572774, -8.195030203309329],
              [-35.013887996973267, -8.195078997866366],
              [-35.01379263459166, -8.195211026971634],
              [-35.013853552006232, -8.195390110894548],
              [-35.013974906908196, -8.195652609338781],
              [-35.014036367240912, -8.195939324588482],
              [-35.014073760762351, -8.196214200895],
              [-35.014038593904488, -8.196381805820835],
              [-35.01390691983773, -8.196454219273228],
              [-35.013690863454215, -8.196455298111433],
              [-35.013354775968693, -8.196456976859858],
              [-35.013102709854628, -8.196458235671138],
              [-35.012911261200522, -8.196578783779326],
              [-35.012684644688562, -8.196866937813386],
              [-35.012469671141353, -8.197083277305222],
              [-35.012266819486207, -8.197323475656443],
              [-35.011968303494882, -8.197635906486946],
              [-35.01169325240582, -8.197840587008647],
              [-35.011502463651766, -8.198092684294069],
              [-35.011443469946158, -8.198296285977523],
              [-35.011444431800648, -8.198487629780752],
              [-35.011493406295919, -8.198678733619911],
              [-35.011566267629711, -8.198845799626662],
              [-35.011686720667988, -8.198928914192942],
              [-35.011975218448974, -8.199011188885084],
              [-35.012647579392528, -8.199043710164023],
              [-35.013043745419857, -8.199053691385853],
              [-35.013236458882886, -8.199184280706886],
              [-35.013429714264099, -8.199422500354808],
              [-35.013539248228405, -8.199720934565809],
              [-35.013660785102942, -8.20001930878588],
              [-35.013878589222578, -8.200365039778632],
              [-35.014036136827642, -8.200663234025692],
              [-35.014097839123878, -8.20099778497646],
              [-35.014123410707676, -8.201308597049838],
              [-35.014089087722326, -8.201643627928689],
              [-35.013970861347353, -8.202002996595027],
              [-35.013744845744263, -8.202410741881261],
              [-35.013566722800128, -8.202794328353093],
              [-35.013376475963987, -8.203154056965502],
              [-35.013245763751655, -8.203417814221201],
              [-35.013066676953152, -8.203610056889667],
              [-35.012720024339089, -8.203898811833858],
              [-35.012336819076474, -8.204080115424217],
              [-35.011725669501978, -8.204286475694067],
              [-35.011102396035568, -8.204468976104561],
              [-35.010611279563001, -8.204674736464369],
              [-35.010036438673737, -8.204940709387252],
              [-35.009545440183089, -8.20517038559745],
              [-35.009102696296786, -8.205447657544518],
              [-35.008779985512369, -8.20572433158147],
              [-35.008481280548352, -8.206000884636392],
              [-35.008098672194869, -8.206301775478819],
              [-35.007811550204764, -8.206494556066287],
              [-35.007404633076419, -8.206735770264592],
              [-35.007021841750245, -8.207000783331377],
              [-35.006615404549088, -8.207337668931389],
              [-35.00637659415424, -8.207590004180883],
              [-35.00630547355663, -8.207769749005058],
              [-35.006306254438265, -8.207925216032788],
              [-35.006366992879926, -8.208068425180267],
              [-35.006487627890742, -8.208187416522346],
              [-35.006728179021543, -8.208281892103969],
              [-35.006980434108087, -8.208316513068525],
              [-35.007196317518748, -8.208279559454832],
              [-35.007567645852809, -8.208122236705941],
              [-35.008010754684399, -8.207916719310253],
              [-35.008382502954753, -8.207843109037873],
              [-35.008670767771925, -8.207877549029408],
              [-35.008947149660692, -8.207935966862436],
              [-35.009211767602842, -8.208042280387277],
              [-35.009356711388499, -8.208220946373444],
              [-35.009417811775506, -8.208435908072252],
              [-35.009466487316089, -8.208567217285626],
              [-35.009503459900969, -8.208758382116025],
              [-35.009600330054973, -8.208925328141046],
              [-35.009876952907319, -8.20903158067496],
              [-35.009974124015777, -8.209258322326029],
              [-35.010059712150287, -8.209568836444658],
              [-35.010169128132532, -8.209843353805599],
              [-35.010289705152438, -8.209950385222569],
              [-35.010493886607392, -8.209973284225985],
              [-35.010721652946486, -8.20991234977093],
              [-35.010888559862131, -8.209684290317753],
              [-35.011018131247589, -8.209193312482853],
              [-35.011016505493515, -8.208870419501991],
              [-35.011038466623475, -8.208463695062653],
              [-35.011084133619363, -8.2079970550152],
              [-35.011189698184388, -8.207506197162424],
              [-35.011355099327638, -8.20697916257893],
              [-35.011509278442389, -8.206607655014281],
              [-35.011687886313709, -8.206319740944117],
              [-35.011926931672271, -8.206115238395411],
              [-35.012202348706786, -8.205982310424957],
              [-35.012502193497973, -8.205932975948972],
              [-35.012802399234552, -8.205955394024322],
              [-35.013103026907416, -8.206061524576157],
              [-35.013379767741668, -8.206191692960484],
              [-35.013572907140855, -8.206405993758271],
              [-35.013586536805221, -8.206728825748124],
              [-35.013528989880939, -8.207219443636983],
              [-35.01357935316657, -8.207685602756451],
              [-35.013713500859893, -8.208103506238059],
              [-35.013955317799621, -8.208449115249383],
              [-35.014269458154956, -8.208854157940827],
              [-35.014642953161413, -8.20912735249993],
              [-35.015003721366945, -8.209257098947393],
              [-35.01534006081593, -8.209303251900282],
              [-35.015711988059273, -8.209265512403668],
              [-35.016023656788036, -8.209180237161146],
              [-35.016574429866459, -8.208902416295038],
              [-35.017148000947536, -8.208385295942509],
              [-35.017661976413812, -8.207952188021519],
              [-35.018044275813381, -8.207591495552874],
              [-35.018318301057576, -8.207183508302339],
              [-35.018544312660971, -8.206775762015761],
              [-35.018722431605411, -8.206392173543668],
              [-35.018947959281654, -8.205888755855412],
              [-35.019065939693277, -8.205481550487892],
              [-35.019147305465296, -8.20495493684122],
              [-35.019275959987418, -8.204284576127915],
              [-35.019309071155035, -8.203710366744456],
              [-35.01924585408657, -8.203076845662961],
              [-35.019255563342682, -8.202622346513776],
              [-35.019290123291768, -8.202335151335559],
              [-35.019444113433856, -8.201927765995194],
              [-35.019634051315641, -8.201508241756569],
              [-35.019824292151895, -8.201148511144069],
              [-35.020062423647225, -8.200764622717083],
              [-35.020336745845775, -8.200416430092726],
              [-35.020623916827155, -8.200235602430372],
              [-35.021091074470704, -8.200041912977685],
              [-35.021426197102024, -8.199848884426086],
              [-35.021676874425594, -8.199572565335021],
              [-35.021999086283223, -8.199200213896228],
              [-35.022356279026233, -8.19862437975574],
              [-35.02277276142938, -8.197904738557707],
              [-35.022963179238381, -8.19758088472082],
              [-35.02307066251209, -8.197472712474832],
              [-35.023190693614197, -8.197472110564741],
              [-35.023359402256972, -8.197602814867953],
              [-35.023564542566739, -8.19781705067466],
              [-35.023722095171934, -8.198115238921911],
              [-35.023771559594373, -8.198402011162967],
              [-35.023653463201057, -8.198785298679898],
              [-35.023403514768169, -8.199205124873576],
              [-35.022986488446854, -8.199817137744756],
              [-35.022796856519442, -8.200296457609516],
              [-35.022678941098846, -8.200715620902116],
              [-35.022681178762035, -8.20115810013518],
              [-35.022791323635261, -8.201576119598709],
              [-35.022985613861692, -8.202017633975741],
              [-35.023299694226608, -8.202410710741871],
              [-35.023636752566645, -8.20260036479729],
              [-35.023985088926153, -8.202646451759131],
              [-35.024368769116478, -8.202560809570459],
              [-35.024739597524068, -8.202307803419764],
              [-35.025145224844458, -8.201815438791551],
              [-35.025585528095696, -8.201059797835493],
              [-35.025906767099194, -8.200496101593055],
              [-35.026061355150418, -8.200208303504764],
              [-35.026131737857447, -8.199885052577777],
              [-35.026142347977022, -8.199609938306725],
              [-35.026057417742095, -8.199430977365544],
              [-35.025852032468798, -8.199168907857837],
              [-35.02573066956694, -8.1989064154131],
              [-35.025693086072984, -8.198595666330506],
              [-35.025727582031081, -8.19829651322709],
              [-35.025834519386834, -8.198080710654088],
              [-35.025953945579893, -8.197960518491874],
              [-35.026110167385347, -8.197995611384433],
              [-35.026604473376302, -8.19842365706095],
              [-35.026990451220897, -8.198792450030489],
              [-35.027304288622396, -8.199137688095679],
              [-35.027726276197583, -8.199506299092345],
              [-35.027894804867714, -8.19960112461999],
              [-35.028014957951612, -8.199624438560416],
              [-35.028110618287918, -8.199552202081072],
              [-35.02814577914603, -8.199384597154921],
              [-35.028107708724896, -8.198978176670501],
              [-35.028178454377326, -8.198726678294864],
              [-35.028273994731848, -8.198630524965056],
              [-35.028525270964657, -8.198473791126071],
              [-35.028932284075722, -8.198256477777939],
              [-35.029339297186809, -8.198039164429746],
              [-35.029614275285546, -8.1978225149827],
              [-35.029732668633464, -8.197499022091682],
              [-35.029743276752981, -8.197223907820684],
              [-35.029585903120463, -8.196961599348926],
              [-35.029440714370807, -8.196735105661878],
              [-35.029103477057703, -8.196509579830959],
              [-35.028742113942386, -8.196260256131675],
              [-35.028477017072412, -8.196058283205145],
              [-35.028355896134066, -8.195843628461381],
              [-35.028318614594511, -8.195592673004908],
              [-35.028352745607215, -8.195221767350199],
              [-35.028338682006321, -8.194815226883943],
              [-35.028264361890763, -8.19436115267194],
              [-35.028130572142238, -8.194015009741864],
              [-35.027985083437969, -8.193728721428956],
              [-35.027804432875747, -8.193610038041983],
              [-35.027588380491885, -8.193611123880189],
              [-35.027348805214842, -8.193708002102108],
              [-35.026953859004976, -8.193937211384515],
              [-35.026331157455616, -8.194227360122158],
              [-35.025756164592636, -8.194457473269653],
              [-35.025216998487629, -8.194651527668515],
              [-35.024677891373521, -8.194857540992583],
              [-35.024330590859229, -8.195014753759644],
              [-35.02409095359134, -8.195099671056303],
              [-35.023911573838141, -8.195232123098817],
              [-35.023816154465521, -8.195352193278968],
              [-35.023816940347253, -8.195507659306701],
              [-35.023841551076714, -8.195627127576742],
              [-35.0237818984711, -8.195699182083089],
              [-35.02364992544971, -8.195711803909399],
              [-35.023541777276002, -8.195688427978077],
              [-35.023242124457141, -8.195773646229837],
              [-35.023002426198403, -8.195846603601286],
              [-35.022870090231628, -8.195787471876367],
              [-35.022797286888625, -8.195632367794687],
              [-35.02279589609784, -8.195357312514941],
              [-35.022758799530941, -8.195142232834215],
              [-35.022685573251636, -8.194903416276119],
              [-35.022708673208491, -8.194723912415986],
              [-35.022851923249227, -8.194567724496153],
              [-35.023067312733012, -8.194435092480694],
              [-35.023486087790388, -8.194169889440701],
              [-35.023676987526521, -8.193941707005534],
              [-35.023855520407032, -8.193641831010211],
              [-35.02393766706043, -8.193270684391731],
              [-35.023899844602674, -8.192912097608573],
              [-35.02375369399843, -8.192494259118565],
              [-35.023535646914716, -8.192100699425088],
              [-35.023245643360646, -8.191719460602922],
              [-35.022956666652007, -8.191541521509297],
              [-35.022787841027295, -8.191386898355889],
              [-35.022798938073848, -8.191207454486834],
              [-35.022941885160016, -8.190991471940997],
              [-35.023120841976805, -8.190775309422133],
              [-35.023288277810664, -8.190654877295891],
              [-35.023359328417406, -8.190463174546593],
              [-35.023370425463945, -8.190283730677553],
              [-35.023237366606182, -8.190081090850349],
              [-35.022936268005481, -8.189879294897388],
              [-35.022419239002822, -8.189702497633121],
              [-35.022045942968127, -8.189465184850704],
              [-35.021612512400566, -8.18920425317304],
              [-35.021275105114626, -8.188942840567275],
              [-35.021021295263822, -8.188597294547963],
              [-35.020707411871022, -8.188240090558633],
              [-35.020525921436025, -8.187953977219289],
              [-35.020344552982806, -8.187691782730342],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2257',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DE TAPACURÁ',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5455',
        QUALIDAD8:
          'Esquemático (O poligono é uma representação esquemática da dimensão da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2257',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.124685198070978, -8.040787600782066],
              [-35.125615079509494, -8.040769465211273],
              [-35.127021284613797, -8.040773339892315],
              [-35.12863145780733, -8.040669523613229],
              [-35.130292487852415, -8.040429946959806],
              [-35.131970558666076, -8.040224262771483],
              [-35.133756371249184, -8.040024084708534],
              [-35.135315364587605, -8.039812887719707],
              [-35.13668724358859, -8.039607582838665],
              [-35.138013910457879, -8.03950975311367],
              [-35.13928951434405, -8.039389370585484],
              [-35.141398600972281, -8.039256594648489],
              [-35.142107321093476, -8.039227398753097],
              [-35.142844456637143, -8.03924339786788],
              [-35.143972681777264, -8.039162773823955],
              [-35.14510102026, -8.039155636679954],
              [-35.146257722934195, -8.039165430772744],
              [-35.146762300173698, -8.039125190759634],
              [-35.147550475295226, -8.039146771631055],
              [-35.148735619151452, -8.039218711824523],
              [-35.149149451384957, -8.039161622693594],
              [-35.149257142271289, -8.039133209476567],
              [-35.149347716074345, -8.039031323764306],
              [-35.149573714191597, -8.038465642201079],
              [-35.149681101791792, -8.038228033853061],
              [-35.149952490926296, -8.03768490379953],
              [-35.149952395992052, -8.037617064995981],
              [-35.149929593969595, -8.037532285667185],
              [-35.14988412279191, -8.037453194906046],
              [-35.149759269062756, -8.037374202407182],
              [-35.149577757618481, -8.037323555099604],
              [-35.149288580108681, -8.037318287471077],
              [-35.148976748286714, -8.037335656347331],
              [-35.14865935323737, -8.03742088418281],
              [-35.148319343358374, -8.03755702394724],
              [-35.147882975032616, -8.037715912371963],
              [-35.14759947947244, -8.037721932054957],
              [-35.147349961479321, -8.037699641777314],
              [-35.147236495916296, -8.03764891085256],
              [-35.147168263232565, -8.037513306243477],
              [-35.147145316569485, -8.037326763603586],
              [-35.147213177252659, -8.037202288982813],
              [-35.147337749360211, -8.037077735545445],
              [-35.147569936597584, -8.036879545873166],
              [-35.147785031220728, -8.036624840890747],
              [-35.147937805192349, -8.036398482703973],
              [-35.148016921141995, -8.036211804771234],
              [-35.148101466579782, -8.035855502315046],
              [-35.148050116842846, -8.035635065904517],
              [-35.147942118821945, -8.03544298365836],
              [-35.147834309888651, -8.035386581477683],
              [-35.147369235250423, -8.035291077539719],
              [-35.14718211210905, -8.035285675196286],
              [-35.146824916473285, -8.035297447564155],
              [-35.146535766383387, -8.035309135019645],
              [-35.146195623196725, -8.035349158290284],
              [-35.145464283917519, -8.035417960305679],
              [-35.144449513386618, -8.035532369441555],
              [-35.14389397076706, -8.035612247753969],
              [-35.143621891177276, -8.035674790983062],
              [-35.14343494906916, -8.035793765533878],
              [-35.143344389199505, -8.035906958988779],
              [-35.143310699708735, -8.036138813495441],
              [-35.143327990210459, -8.036336676726323],
              [-35.143419222720468, -8.036704058726288],
              [-35.14342592803785, -8.037439052065027],
              [-35.143216776809318, -8.037891640943164],
              [-35.142814804230497, -8.038321860943789],
              [-35.141397561483195, -8.038515931448325],
              [-35.140122095095322, -8.03873243448699],
              [-35.139481510485886, -8.038823733774823],
              [-35.138954236471669, -8.038858332572646],
              [-35.138874775056017, -8.038801898393531],
              [-35.138834948863895, -8.038705835076884],
              [-35.138806334336287, -8.038513644064009],
              [-35.138873874889903, -8.038157358127346],
              [-35.138867474803263, -8.037631559136242],
              [-35.138867042976841, -8.03732624767993],
              [-35.13882638042643, -8.036630877120791],
              [-35.13881422415411, -8.036048540165915],
              [-35.138631628809947, -8.035217647610041],
              [-35.1384553808467, -8.0348786482315],
              [-35.138193888969475, -8.034398407422213],
              [-35.138023374560156, -8.034098967268131],
              [-35.137909557920828, -8.033799461221911],
              [-35.137903464434466, -8.033499809150449],
              [-35.137959835922018, -8.03326227562177],
              [-35.138084241939403, -8.033018994161512],
              [-35.138146388370103, -8.032860607155703],
              [-35.138179938481436, -8.032526986409099],
              [-35.13814568609525, -8.032357414560446],
              [-35.138003744728259, -8.032221901406944],
              [-35.137873279233695, -8.032176839477515],
              [-35.137550113590535, -8.032199871690294],
              [-35.137351794864138, -8.032290592099823],
              [-35.137147853348246, -8.032420887146163],
              [-35.137006233982355, -8.032511530249966],
              [-35.136881553604056, -8.032556923497774],
              [-35.136739767495001, -8.032534491678444],
              [-35.13635417960532, -8.032506719897313],
              [-35.136138782413191, -8.03255222327955],
              [-35.13590657884442, -8.032744756384297],
              [-35.135747997217251, -8.032874998077357],
              [-35.135544143137388, -8.033067494877544],
              [-35.135470595903755, -8.033186319082313],
              [-35.135216046560728, -8.033621994054844],
              [-35.135097381981716, -8.033910499707853],
              [-35.134945000113035, -8.03442520026168],
              [-35.134950938596006, -8.034617419642105],
              [-35.135047801125538, -8.034956537453285],
              [-35.135314855814393, -8.035368919226171],
              [-35.135439952844301, -8.035623189719304],
              [-35.135423185199947, -8.035798479074282],
              [-35.135332622639673, -8.03591167134601],
              [-35.135321289912198, -8.03591734421278],
              [-35.13504361347831, -8.036030777291373],
              [-35.134941730701755, -8.036160948970648],
              [-35.134783249316669, -8.03635903938186],
              [-35.134698306368975, -8.036438305559699],
              [-35.13455660538375, -8.036472406165514],
              [-35.134414831051245, -8.036455622504223],
              [-35.134250375546415, -8.036444526602553],
              [-35.134097303084658, -8.036456033446665],
              [-35.133966953788665, -8.036501427862284],
              [-35.133774352845101, -8.036631720991767],
              [-35.133576434327352, -8.037016434741258],
              [-35.133531288181075, -8.037169156413869],
              [-35.133407215669003, -8.037655552249342],
              [-35.133226607887238, -8.038260748763316],
              [-35.133079925824191, -8.038792405419263],
              [-35.132961352912638, -8.039154408676989],
              [-35.132910525850029, -8.039301471834829],
              [-35.132768866250785, -8.039369498855297],
              [-35.132683853779426, -8.039397880573738],
              [-35.132604483244769, -8.039403633528643],
              [-35.132507985165539, -8.03933025692143],
              [-35.132382974422669, -8.039132525263916],
              [-35.132252303942245, -8.038951773666154],
              [-35.132042182015404, -8.038714574622542],
              [-35.131871874716204, -8.038562130900653],
              [-35.131468818336174, -8.03821775764351],
              [-35.131082733627878, -8.037839445159236],
              [-35.130832790283002, -8.037511831575616],
              [-35.130759008780913, -8.037455391466425],
              [-35.130651159697337, -8.037370717786636],
              [-35.130565956888283, -8.037263401211403],
              [-35.130441131271766, -8.037201368681865],
              [-35.130395573267052, -8.037060074547734],
              [-35.130486161844715, -8.036958191823516],
              [-35.130622217625856, -8.036941060968456],
              [-35.130763919401772, -8.036906952336398],
              [-35.130882879276541, -8.036821998187451],
              [-35.130945056437525, -8.036686220016206],
              [-35.130990227869944, -8.036544816515924],
              [-35.130950337093267, -8.036403518538844],
              [-35.13085381094605, -8.036301874703073],
              [-35.130740269129035, -8.036200244998147],
              [-35.130586965243701, -8.036047783917498],
              [-35.130507417517819, -8.035929156698996],
              [-35.130376886731227, -8.035838859117815],
              [-35.130286014809542, -8.035731543992508],
              [-35.130161138851044, -8.035629938081117],
              [-35.130041920876721, -8.03552831899246],
              [-35.129939738114231, -8.035437983065522],
              [-35.129792205180493, -8.035353361881029],
              [-35.129644737325286, -8.035325280712577],
              [-35.129508656190708, -8.035325453516986],
              [-35.129383997736547, -8.035382151499254],
              [-35.129270715944166, -8.03547275458042],
              [-35.129191499110668, -8.03559158908565],
              [-35.12915768634047, -8.035738628688192],
              [-35.129163558283388, -8.03588562469851],
              [-35.129163749758298, -8.036026973644036],
              [-35.129027721510013, -8.036066728781334],
              [-35.128925527824769, -8.035970736972809],
              [-35.128823297560395, -8.035852134226566],
              [-35.128726782901516, -8.03575614763843],
              [-35.128624844623431, -8.035846735819595],
              [-35.12862503981772, -8.035993733517387],
              [-35.128693254237007, -8.03611803394932],
              [-35.128795471347622, -8.036230985750722],
              [-35.128909012783865, -8.0363326068615],
              [-35.129016873831652, -8.036428590118156],
              [-35.129119116856558, -8.036558499394921],
              [-35.129141993687995, -8.036699821060679],
              [-35.129045735846788, -8.036796056440396],
              [-35.128909640966299, -8.036790580520865],
              [-35.128784815632848, -8.03672854738282],
              [-35.128654490999587, -8.036790907150944],
              [-35.12851844752641, -8.036819344001042],
              [-35.128416217088279, -8.03670074349664],
              [-35.128353681438078, -8.036576429814035],
              [-35.128217661432686, -8.036621835744757],
              [-35.128217867486072, -8.03677449371423],
              [-35.128189710772325, -8.036915880783717],
              [-35.128104815191875, -8.037029061196744],
              [-35.128019932118491, -8.037153557501995],
              [-35.128025802177497, -8.037294895647952],
              [-35.128099696574274, -8.037430501223632],
              [-35.128201943623594, -8.037566061751788],
              [-35.128326858270277, -8.037695944041426],
              [-35.128423385095267, -8.037791939815909],
              [-35.128519924499344, -8.037899244507363],
              [-35.128605150489108, -8.038023521541835],
              [-35.128673354473968, -8.038142163956286],
              [-35.128724606812938, -8.038306063124841],
              [-35.128736170303071, -8.038464357196117],
              [-35.128730696193479, -8.038611365727563],
              [-35.128651467885007, -8.038724544703841],
              [-35.128521127800774, -8.038775595656462],
              [-35.128384996260316, -8.038741853162144],
              [-35.128294096512015, -8.038611919747673],
              [-35.128237204359628, -8.038470646916249],
              [-35.128180309947858, -8.038329376391797],
              [-35.128134754741275, -8.038188079516239],
              [-35.128055203065102, -8.038063793796095],
              [-35.127964307614583, -8.037939527454981],
              [-35.127879103575225, -8.037826552197838],
              [-35.127793899622844, -8.037713583852245],
              [-35.127703027731336, -8.037606277352074],
              [-35.1276065033964, -8.03751027902562],
              [-35.127492998348586, -8.037431266163923],
              [-35.127362481542484, -8.037357936831858],
              [-35.127243310889305, -8.037284583422943],
              [-35.127118448917308, -8.037199931888118],
              [-35.1269936001815, -8.037120931276077],
              [-35.126829138319472, -8.037098525732924],
              [-35.126738550915938, -8.037200407884256],
              [-35.126727407100475, -8.037341769420845],
              [-35.126778642176262, -8.037488713708552],
              [-35.126835535959763, -8.037629984589906],
              [-35.126892427532113, -8.037771267034442],
              [-35.126949319844343, -8.037906889157057],
              [-35.126994878464011, -8.038053825733547],
              [-35.127006404714706, -8.038189511423173],
              [-35.127017956674607, -8.038342157046442],
              [-35.127018185795002, -8.038511770442248],
              [-35.127018391538151, -8.038664419260071],
              [-35.126984567160029, -8.0388058195183],
              [-35.126848522789835, -8.038834256018236],
              [-35.126689665968698, -8.038766613231738],
              [-35.12655908484345, -8.038648040989377],
              [-35.126485201497651, -8.038518099940076],
              [-35.126445335894026, -8.038388112401403],
              [-35.126388433466403, -8.038241181206411],
              [-35.126348531371086, -8.038088573452431],
              [-35.126297292431708, -8.037935982697363],
              [-35.126251710652795, -8.037772069817755],
              [-35.126211835954315, -8.03764207994762],
              [-35.126154931455297, -8.037495148678246],
              [-35.126064035051925, -8.037365226062986],
              [-35.125939209725161, -8.037303191994347],
              [-35.125803189007719, -8.037348594943033],
              [-35.125695584639907, -8.037439193456843],
              [-35.125593635881927, -8.03752978121204],
              [-35.125457628245343, -8.037580844311771],
              [-35.125304555803119, -8.037597996779938],
              [-35.125157111027747, -8.037581223050221],
              [-35.124992655067864, -8.037564465497715],
              [-35.124839605872062, -8.037598584711317],
              [-35.124737706497562, -8.037717448137036],
              [-35.124686889405126, -8.037870161996503],
              [-35.12468143780994, -8.038028479776299],
              [-35.124681631839238, -8.038175487088175],
              [-35.124710221250346, -8.038350716209097],
              [-35.124744457072417, -8.038508981482487],
              [-35.124789998832405, -8.038638970050613],
              [-35.124852597632064, -8.038808506421503],
              [-35.124898181436244, -8.038972419553184],
              [-35.12491540000228, -8.039125052317369],
              [-35.124915603458611, -8.039272050295548],
              [-35.124961171021233, -8.03941899644814],
              [-35.125023706376197, -8.039543299217463],
              [-35.125137248333672, -8.039644930617024],
              [-35.125273486017861, -8.039757843846584],
              [-35.125386998479478, -8.039836847964763],
              [-35.125506195438874, -8.039927159434741],
              [-35.125602749698672, -8.040045773830318],
              [-35.125676629313539, -8.040170064004021],
              [-35.125688157701632, -8.040305749804482],
              [-35.125557828967217, -8.04036809975706],
              [-35.125421682060406, -8.040323047395898],
              [-35.125268492709836, -8.040249735349004],
              [-35.125126654432869, -8.040193375507345],
              [-35.124990556504983, -8.040182238274076],
              [-35.124854522274163, -8.040216332203023],
              [-35.124752624049243, -8.040340853771863],
              [-35.124713143888378, -8.040493555340829],
              [-35.124690664177777, -8.040640589664969],
              [-35.124685198070978, -8.040787600782066],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2254',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DA USINA SÃO JOSÉ',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5443',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '21/05/2014',
        CODIGO_U11: '0000.26.2254',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.005951659751979, -7.833030182318415],
              [-35.005491851772739, -7.832194253428678],
              [-35.005636513879736, -7.831867663605076],
              [-35.005711776196485, -7.831701509318044],
              [-35.005706499767449, -7.831242924412218],
              [-35.005637617702774, -7.83090478648746],
              [-35.005638195931141, -7.8304004293211],
              [-35.005655806844693, -7.830102379981679],
              [-35.005731638730737, -7.829391729501665],
              [-35.005870761599368, -7.828893133055884],
              [-35.006067650033117, -7.82836026943178],
              [-35.006223717530979, -7.828125385010875],
              [-35.006495233512751, -7.827919150671071],
              [-35.006749358110476, -7.827810453918636],
              [-35.006916771124232, -7.827730283800006],
              [-35.006668757768963, -7.827581125271113],
              [-35.006599522858359, -7.827529473374249],
              [-35.006397653000391, -7.827437681644872],
              [-35.006629037489688, -7.827142412978909],
              [-35.0070965193392, -7.82674205793804],
              [-35.008017062916117, -7.826015271621634],
              [-35.008484060168989, -7.825570981135217],
              [-35.008629403174389, -7.825349528693245],
              [-35.008876215189062, -7.824906920661507],
              [-35.009152444188061, -7.824493462168484],
              [-35.009502262849637, -7.824094297919119],
              [-35.009705972353196, -7.823813714920356],
              [-35.009778262742479, -7.823651640976874],
              [-35.00982089939567, -7.82343094615322],
              [-35.009891326649189, -7.822974894471003],
              [-35.010035623315424, -7.822591988142078],
              [-35.010063856426733, -7.822430124101422],
              [-35.009943538516076, -7.821990169768898],
              [-35.009781259817537, -7.821873691495647],
              [-35.009517389329602, -7.821934161631372],
              [-35.009385756714551, -7.82200846393567],
              [-35.009254870517232, -7.822200379570261],
              [-35.009139199265853, -7.822465627441905],
              [-35.008950314895024, -7.822775429201492],
              [-35.008818752461224, -7.822864468493149],
              [-35.008628076534805, -7.822895119565153],
              [-35.008422926772873, -7.822969954498461],
              [-35.008276910753239, -7.823088534982521],
              [-35.008189615922937, -7.823221388209022],
              [-35.008001333830087, -7.823619421657486],
              [-35.00784272305156, -7.824061428939566],
              [-35.007769970004858, -7.824164832993025],
              [-35.007652699741556, -7.824209689003082],
              [-35.007565123356571, -7.824283783630505],
              [-35.007347750495839, -7.824740986173639],
              [-35.007231314277, -7.824903450561266],
              [-35.007085297269917, -7.825022028564205],
              [-35.006851592969717, -7.825229439046342],
              [-35.006778909998559, -7.825347577813905],
              [-35.006649740994547, -7.825804272697377],
              [-35.006591813021494, -7.825936986123606],
              [-35.006254334534646, -7.825998166183123],
              [-35.006165921161958, -7.825954652167411],
              [-35.006089499870058, -7.825499388776519],
              [-35.006088083379375, -7.825278905650662],
              [-35.00613086165837, -7.825087409482154],
              [-35.0062601949829, -7.82464554156662],
              [-35.006259150039099, -7.82448381895819],
              [-35.006081791560241, -7.824323293560234],
              [-35.00584629691658, -7.824251459842723],
              [-35.005464159746204, -7.824224709019601],
              [-35.005347029923833, -7.824299034036666],
              [-35.005011271735718, -7.824624812097691],
              [-35.004806401364249, -7.824758496883843],
              [-35.003943169396194, -7.825338099909618],
              [-35.003300284037515, -7.825930936729047],
              [-35.002891357638553, -7.826331012473907],
              [-35.002467793198669, -7.826760625499712],
              [-35.002293037245202, -7.827012050033774],
              [-35.00201678843392, -7.827499547592502],
              [-35.001857760723247, -7.827912627697536],
              [-35.001425084046801, -7.829225425624021],
              [-35.001295727478954, -7.829682570399783],
              [-35.0011141311358, -7.830029855656773],
              [-35.000924777674939, -7.830499942188617],
              [-35.00070551572847, -7.830809889069526],
              [-35.000498065732181, -7.830878585558755],
              [-35.000123735484067, -7.830912634966613],
              [-34.999755091448321, -7.830940961577798],
              [-34.99949873838392, -7.830887032508535],
              [-34.999429901372302, -7.830899835461703],
              [-34.99932761885573, -7.830970744910526],
              [-34.999250514623199, -7.831150286384458],
              [-34.999214126593735, -7.831351967765933],
              [-34.999209543661706, -7.831702389046233],
              [-34.999202806546108, -7.831943705729428],
              [-34.999159147773483, -7.832065143028538],
              [-34.999109096127199, -7.832158042882075],
              [-34.999023063146872, -7.832176891355323],
              [-34.998835211718827, -7.832002492302629],
              [-34.998655750124527, -7.831667047144522],
              [-34.998421025389632, -7.83145336257929],
              [-34.998089465479964, -7.83129320392286],
              [-34.997929078757409, -7.831270279622569],
              [-34.997834024242522, -7.831374151905704],
              [-34.997673839647454, -7.831640512527724],
              [-34.997411378059276, -7.832055077757358],
              [-34.997290234303378, -7.832201018136204],
              [-34.997211918488702, -7.832240987080644],
              [-34.997112539361368, -7.832065172646002],
              [-34.996913317032721, -7.831635076935322],
              [-34.996788343291001, -7.831339058141993],
              [-34.996639553789009, -7.831104805240229],
              [-34.996431678124878, -7.830856369995111],
              [-34.996239252228904, -7.830710829622936],
              [-34.995958442413482, -7.830566068682489],
              [-34.995796219753707, -7.830538178714194],
              [-34.995501586936491, -7.830555574762175],
              [-34.995207184818192, -7.83060243993829],
              [-34.994912391626229, -7.830605191395213],
              [-34.994705609199755, -7.830548221965024],
              [-34.994454150475796, -7.830418148757865],
              [-34.994202320967624, -7.830229131610084],
              [-34.993920730622612, -7.829996229875378],
              [-34.993831354682492, -7.829864574844704],
              [-34.993741395862351, -7.829629861602232],
              [-34.993769977096484, -7.829482279310665],
              [-34.993871744067675, -7.829245666472488],
              [-34.994061822365204, -7.828993537387118],
              [-34.994325300487873, -7.828696494389913],
              [-34.9946039949861, -7.828473055416707],
              [-34.994912219859508, -7.828264123884199],
              [-34.995205598956304, -7.828040615180936],
              [-34.995498957772185, -7.827831934226093],
              [-34.995792385968862, -7.827637897757619],
              [-34.996379383039447, -7.827279564915807],
              [-34.99667286101262, -7.827115178704878],
              [-34.996966268986888, -7.826936147737414],
              [-34.997259513679957, -7.826742382204611],
              [-34.997552690213574, -7.826533881663516],
              [-34.997830861177675, -7.82629625246562],
              [-34.997991416174749, -7.82608892151308],
              [-34.997856961043723, -7.825781015098469],
              [-34.997956972009732, -7.825309362435164],
              [-34.998206511616168, -7.825189662407101],
              [-34.998380270535598, -7.824746952580622],
              [-34.998231642360864, -7.824527524500371],
              [-34.997862053110232, -7.82428030659276],
              [-34.997418997131618, -7.82406317958356],
              [-34.99699062847867, -7.823845889895924],
              [-34.996894329847528, -7.823663795419678],
              [-34.996878093985131, -7.823362405860014],
              [-34.996898348680737, -7.823218480369266],
              [-34.997143364612633, -7.823081716069832],
              [-34.99733986464669, -7.823112336833988],
              [-34.997754242091965, -7.823380047255485],
              [-34.997934961320802, -7.823485684301075],
              [-34.998370593357166, -7.823666867820853],
              [-34.998786495772336, -7.823722030822231],
              [-34.99901785598243, -7.823763515513213],
              [-34.999267058154906, -7.823839176481648],
              [-34.999376820392946, -7.823854296370178],
              [-34.999462613237384, -7.823823875545771],
              [-34.999487015784716, -7.823599471375928],
              [-34.999495751237937, -7.823168571260533],
              [-34.99949014993674, -7.822887266306424],
              [-34.999440153673405, -7.822687260334545],
              [-34.999163417915611, -7.822388163256138],
              [-34.998751901591199, -7.822264001650375],
              [-34.998461892419371, -7.822166204962429],
              [-34.99818077284921, -7.821936105641611],
              [-34.997995053014542, -7.821865023993494],
              [-34.997596372566932, -7.821809505109736],
              [-34.99731419764116, -7.821814909352396],
              [-34.996969017900035, -7.821838781210419],
              [-34.996714988300425, -7.821814942489232],
              [-34.996275354732148, -7.821725806337374],
              [-34.995998656992462, -7.821719612230263],
              [-34.995734764784473, -7.821776366777756],
              [-34.995386289219162, -7.821926637428519],
              [-34.994829909948564, -7.822260142761549],
              [-34.994491714594375, -7.82238071097341],
              [-34.994346172808243, -7.822676293129697],
              [-34.994273657930997, -7.822868198407528],
              [-34.994097982980016, -7.823061044873601],
              [-34.993449882175248, -7.823169878552717],
              [-34.993155441115057, -7.823246214046969],
              [-34.992964600106603, -7.823395194610392],
              [-34.992878349893914, -7.823749168979774],
              [-34.992864615286187, -7.823911235811442],
              [-34.99247051849477, -7.824592202686307],
              [-34.992545787405703, -7.824826986082663],
              [-34.992797562622208, -7.824986442464446],
              [-34.993445936340976, -7.824877246740045],
              [-34.993842055028118, -7.824774394174311],
              [-34.994167296717244, -7.824694024319418],
              [-34.994329397851018, -7.824677800676309],
              [-34.994785898122892, -7.824614891513883],
              [-34.995022367782369, -7.824742939514402],
              [-34.995127806181699, -7.824933570469291],
              [-34.995163642509773, -7.825141897185744],
              [-34.995146965031999, -7.825298344764521],
              [-34.994953397907835, -7.825466957578741],
              [-34.994674054155638, -7.825572512970409],
              [-34.994380074685623, -7.825707610171848],
              [-34.994086023030874, -7.825828060276291],
              [-34.993498113794118, -7.826127992558667],
              [-34.993277651334175, -7.826262561154602],
              [-34.99319005839142, -7.826410602839398],
              [-34.993177295463191, -7.826719931735614],
              [-34.993134841905913, -7.827000108703611],
              [-34.993048059672653, -7.82728067802259],
              [-34.992931516635281, -7.827517360512496],
              [-34.992756330972945, -7.827813624423827],
              [-34.992595902714122, -7.82812455612912],
              [-34.992435331465089, -7.828405925459919],
              [-34.992259985524548, -7.828687635368821],
              [-34.992069793561988, -7.828954680168659],
              [-34.99183497555422, -7.829178181351443],
              [-34.990643661574673, -7.830031245841419],
              [-34.990252630298407, -7.830309000389373],
              [-34.990233515235808, -7.830407449093538],
              [-34.990470388142185, -7.830443848017413],
              [-34.990823796220255, -7.830471198260054],
              [-34.991178326584915, -7.830467084405718],
              [-34.991829002197463, -7.830592290402454],
              [-34.992183490448973, -7.83061791712483],
              [-34.992360479200677, -7.830586524715177],
              [-34.992744200294432, -7.830582542320763],
              [-34.993084774168238, -7.83077041645379],
              [-34.993425559383269, -7.831002675427428],
              [-34.993766044164225, -7.831190729783683],
              [-34.993957978202992, -7.831232944393892],
              [-34.994311875648812, -7.831229462548502],
              [-34.994651068130572, -7.831240965728404],
              [-34.994887241152959, -7.83128288026631],
              [-34.995080940037127, -7.831619797130847],
              [-34.995111977135949, -7.831855149743493],
              [-34.994952261932532, -7.832239574411028],
              [-34.994806347616688, -7.832476669574773],
              [-34.9947485390447, -7.832653950911403],
              [-34.994957084397313, -7.833005444672748],
              [-34.995090609775751, -7.833136709649864],
              [-34.995461262321719, -7.833471975858897],
              [-34.995712841431235, -7.833646345531782],
              [-34.995860611668697, -7.83370395692636],
              [-34.996095933114262, -7.833642813667481],
              [-34.996462829851318, -7.833418503801005],
              [-34.996624522010777, -7.833372807698922],
              [-34.996977759190401, -7.833325481750852],
              [-34.997125205564863, -7.833353621523652],
              [-34.997361550322864, -7.833469299705927],
              [-34.997479907327467, -7.833556610551341],
              [-34.997644430042499, -7.83395278963669],
              [-34.997808813047556, -7.834319586809725],
              [-34.997899265577843, -7.834657534846567],
              [-34.997885767456872, -7.83484916088263],
              [-34.997578203927432, -7.83510230010658],
              [-34.997256005394199, -7.835385161789743],
              [-34.997110132020552, -7.835592694522933],
              [-34.997008620453954, -7.83584413224999],
              [-34.996878318128815, -7.836198947699335],
              [-34.996777547016478, -7.836568268096802],
              [-34.996661859271306, -7.836908188834855],
              [-34.996270952709224, -7.837973086591216],
              [-34.996140720592351, -7.838342817479072],
              [-34.996010327573025, -7.838697813942091],
              [-34.995865179405939, -7.839038325669499],
              [-34.995720008462499, -7.839393572708592],
              [-34.995560315609929, -7.839763804299375],
              [-34.995352914029695, -7.840186155747713],
              [-34.995186151567125, -7.840309983983587],
              [-34.994827149546317, -7.840442406614533],
              [-34.994468179468356, -7.840600594053729],
              [-34.994117629451246, -7.840889465183008],
              [-34.993909983698181, -7.841222950343706],
              [-34.993831637097593, -7.841656891319429],
              [-34.993908516334713, -7.842456410703389],
              [-34.994054426479515, -7.842827275013295],
              [-34.994214659833531, -7.843197890817182],
              [-34.994506303092123, -7.843939619659198],
              [-34.994666356458488, -7.844310235923095],
              [-34.994812018713155, -7.844666724304328],
              [-34.994943429652359, -7.84503792971237],
              [-34.995074997177127, -7.845423413557099],
              [-34.99523480232147, -7.845779656325868],
              [-34.995366120929503, -7.846150769271377],
              [-34.995511831392456, -7.846536007494873],
              [-34.996033972644042, -7.847647019877648],
              [-34.996149763147052, -7.847860363253366],
              [-34.996336211874628, -7.84798622135996],
              [-34.99665104444167, -7.848095471119413],
              [-34.996893850657777, -7.848119812806233],
              [-34.997193312343818, -7.848085664343182],
              [-34.997307005566441, -7.848011807274814],
              [-34.997562176210053, -7.847777447778447],
              [-34.997716331311466, -7.847386901108301],
              [-34.99781301571052, -7.846940033388082],
              [-34.997952938424554, -7.846549825114346],
              [-34.998293818997922, -7.846342811541023],
              [-34.998492539100596, -7.846210061028061],
              [-34.998633717225282, -7.846006525440706],
              [-34.998546185654426, -7.845749655487286],
              [-34.99808495624675, -7.845111436511902],
              [-34.997925399000962, -7.844769568323022],
              [-34.997880494041567, -7.84449748969831],
              [-34.997906178231432, -7.844123642247252],
              [-34.997974718661141, -7.843763423931833],
              [-34.998044463744677, -7.843561225259416],
              [-34.99799819293434, -7.843116753602674],
              [-34.99792389130549, -7.84274464905483],
              [-34.997806642902397, -7.842358825445837],
              [-34.997846447396647, -7.841999104595025],
              [-34.997945270748261, -7.841868094845426],
              [-34.998313665346821, -7.84169015114175],
              [-34.998688964355452, -7.842085327975642],
              [-34.999020601420298, -7.842524917175181],
              [-34.999378400244609, -7.842691185136737],
              [-34.999748766829967, -7.842641873597872],
              [-34.999990865781356, -7.842594707977239],
              [-35.000333886667185, -7.842703821816912],
              [-35.000705828123955, -7.842869931004377],
              [-35.000848876192748, -7.84292493767949],
              [-35.001104957998074, -7.842863240553808],
              [-35.001218605959835, -7.842818223957899],
              [-35.00158931805116, -7.842840777447326],
              [-35.001702579527183, -7.842752820741706],
              [-35.001827884984806, -7.842377417013026],
              [-35.002142101375917, -7.842472743476328],
              [-35.002426941550837, -7.842453667842803],
              [-35.002598202411278, -7.842493897110034],
              [-35.002885551541993, -7.842790585948997],
              [-35.003144644368575, -7.843130711801774],
              [-35.003317638237199, -7.843400552404894],
              [-35.003491705264096, -7.843799481579897],
              [-35.003607384862903, -7.844027106625478],
              [-35.003736772629175, -7.844182795844747],
              [-35.003893255048318, -7.844165778876025],
              [-35.004147876402328, -7.843931960992823],
              [-35.004258948571199, -7.843557076083493],
              [-35.00431414065541, -7.843326649537043],
              [-35.004182998216386, -7.842955720764592],
              [-35.004080638841153, -7.842612951739582],
              [-35.004178755514246, -7.842410434895529],
              [-35.00414853629168, -7.842195694053203],
              [-35.003988164400582, -7.841854374500539],
              [-35.003881846524052, -7.841499511120698],
              [-35.003863600859518, -7.841285796288554],
              [-35.003941731489647, -7.840885122753297],
              [-35.004040106586636, -7.840812242424982],
              [-35.004182923586306, -7.840838503258229],
              [-35.004342311708065, -7.840992874011623],
              [-35.004590855655223, -7.841345523262793],
              [-35.004811526449352, -7.84172705550608],
              [-35.005303756842373, -7.842420716489387],
              [-35.005375987232085, -7.842548653706524],
              [-35.005208079534746, -7.842924441208553],
              [-35.005081657769502, -7.843141737908048],
              [-35.00499943814885, -7.843559154029588],
              [-35.005356239899136, -7.843668017071682],
              [-35.005741116734995, -7.843733350624812],
              [-35.005955804000934, -7.843887637271833],
              [-35.006099858449183, -7.844114674742902],
              [-35.00623036492324, -7.844428018858606],
              [-35.006288868795494, -7.844642173078261],
              [-35.006291492597541, -7.845000605246129],
              [-35.00630842006354, -7.845373162760676],
              [-35.00635606119026, -7.845742317583468],
              [-35.006422474075727, -7.845980030524223],
              [-35.006509773660959, -7.846130494491091],
              [-35.006617944451563, -7.846172105632626],
              [-35.006725623905723, -7.846148539350516],
              [-35.006867940056686, -7.845994538617859],
              [-35.006980975080516, -7.845877923763664],
              [-35.007250856181422, -7.845801692522294],
              [-35.0074785631585, -7.845853582516429],
              [-35.008127320108102, -7.846103068098544],
              [-35.008408506293073, -7.846228560501618],
              [-35.00849471260257, -7.846227063540804],
              [-35.008580411718761, -7.84613878311057],
              [-35.008530409005097, -7.845636839411321],
              [-35.008529059674551, -7.845450526986411],
              [-35.008768375433064, -7.845088230285361],
              [-35.008681567937337, -7.84488903720525],
              [-35.008180480318622, -7.844467263527785],
              [-35.00810797409126, -7.844282102946011],
              [-35.008077318883238, -7.843995855743882],
              [-35.008131756551876, -7.843665267986237],
              [-35.008130775054106, -7.843536179213371],
              [-35.008043649382557, -7.843308239229123],
              [-35.007867306485572, -7.843002617523961],
              [-35.007886814277732, -7.842741715405575],
              [-35.008036951734674, -7.842630616692063],
              [-35.008145124337247, -7.842672317881749],
              [-35.00831072010233, -7.842873576891649],
              [-35.008482256581843, -7.842971205920234],
              [-35.008653246096657, -7.843011433815898],
              [-35.008837802853947, -7.842951066436073],
              [-35.008951043142012, -7.842877482301345],
              [-35.009035291480345, -7.842732707817361],
              [-35.009105081814099, -7.842559527326703],
              [-35.009132015547465, -7.842372628647728],
              [-35.009130190135913, -7.842143196377994],
              [-35.009047386658352, -7.841758299405897],
              [-35.009041890613439, -7.841463253693022],
              [-35.009119348107092, -7.841301064242213],
              [-35.009263794119491, -7.841137740428375],
              [-35.009395406185511, -7.841001508750958],
              [-35.00959155535562, -7.840768782497684],
              [-35.009988141894866, -7.84032798897284],
              [-35.009971983939174, -7.840077923915088],
              [-35.010307188152247, -7.839692385393027],
              [-35.01018778724243, -7.839369589214186],
              [-35.010053540262192, -7.839032216689057],
              [-35.009963044553302, -7.8386503397423],
              [-35.010019710782323, -7.838311424018666],
              [-35.01012152934161, -7.838163402112998],
              [-35.010008421032296, -7.83773667568769],
              [-35.009794416807054, -7.837074653191511],
              [-35.009399942752381, -7.83597684043795],
              [-35.009134371392285, -7.835287182806981],
              [-35.00877954375332, -7.834907468231878],
              [-35.008663755764118, -7.834638081418887],
              [-35.008548613288042, -7.83429901740762],
              [-35.008352769094671, -7.834041201651785],
              [-35.00812756036251, -7.834000229030861],
              [-35.007871106981874, -7.833793525973128],
              [-35.007710115577034, -7.833644044636728],
              [-35.006783091384811, -7.83349360214151],
              [-35.006483755737968, -7.833418637432703],
              [-35.006213402724562, -7.833205762231518],
              [-35.005951659751979, -7.833030182318415],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '1015',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL LAGO DE SOBRADINHO',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2006',
        GID7: '2030',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 9.957 de 31/03/2006',
        DT_ULTIM10: '28/11/2007',
        CODIGO_U11: '0000.29.1015',
        NOME_ORG12: 'Secretaria do Meio Ambiente da Bahia',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.765239089710356, -9.447797428419781],
              [-40.751820781416725, -9.445250888159675],
              [-40.742026395800984, -9.445740607440463],
              [-40.733950439553404, -9.447381828813928],
              [-40.723906782411866, -9.446720046002053],
              [-40.719989028165557, -9.44769948456363],
              [-40.71622348396599, -9.451542236757913],
              [-40.71803235698512, -9.452989335173205],
              [-40.722735426834838, -9.452265785965558],
              [-40.72454429985396, -9.456787968513384],
              [-40.726845098096582, -9.457493870179386],
              [-40.730762852342885, -9.457493870179379],
              [-40.732721729466022, -9.462880782268053],
              [-40.732232010185243, -9.470716290760647],
              [-40.733226890345762, -9.478132670139077],
              [-40.737206410987831, -9.480303317762022],
              [-40.738110847497396, -9.483016627290713],
              [-40.735660045150759, -9.487366746307451],
              [-40.73761892227391, -9.492263939115327],
              [-40.739015284006953, -9.497487611443717],
              [-40.742633030045205, -9.501286244783881],
              [-40.741366818931816, -9.507798187652744],
              [-40.744984564970068, -9.51087327178525],
              [-40.745944150047286, -9.513321868189186],
              [-40.743718353856686, -9.51756610195603],
              [-40.743356579252854, -9.519194087673242],
              [-40.743899241158594, -9.522269171805757],
              [-40.757207693505393, -9.520667657401006],
              [-40.765786604689985, -9.518108763861759],
              [-40.773202984068398, -9.509607060671872],
              [-40.775327306894518, -9.509404113942889],
              [-40.778755341860034, -9.506955517538945],
              [-40.777286184017662, -9.509404113942887],
              [-40.77630674545609, -9.513811587469981],
              [-40.777775903298455, -9.517729341716288],
              [-40.783162815387115, -9.5250751309281],
              [-40.791977762441284, -9.531441481578355],
              [-40.793936639564429, -9.536338674386215],
              [-40.795405797406794, -9.540746147913319],
              [-40.798344113091517, -9.544174182878825],
              [-40.802261867337812, -9.544174182878832],
              [-40.80373102518017, -9.548581656405913],
              [-40.807648779426465, -9.549561094967494],
              [-40.810587095111195, -9.549561094967503],
              [-40.815484287919062, -9.550050814248287],
              [-40.817443165042206, -9.546133060001971],
              [-40.824788954254018, -9.544663902159618],
              [-40.827237550657948, -9.545643340721201],
              [-40.830175866342678, -9.548091937125129],
              [-40.833114182027394, -9.552009691371429],
              [-40.837521655554482, -9.55298912993301],
              [-40.84241884836235, -9.554458287775374],
              [-40.843888006204708, -9.557396603460102],
              [-40.846336602608645, -9.557886322740879],
              [-40.939383265958149, -9.600981619450199],
              [-41.035368244992306, -9.609796566504372],
              [-41.05250841981983, -9.692069405676746],
              [-41.085319611632514, -9.747897403686554],
              [-41.128414908341682, -9.77630112197224],
              [-41.230766238025886, -9.822824453647073],
              [-41.272882096173404, -9.822824453647048],
              [-41.272882096173404, -9.823803892208625],
              [-41.272882096173404, -9.827721646454929],
              [-41.27435125401577, -9.831639400701224],
              [-41.276799850419692, -9.835557154947534],
              [-41.275330692577327, -9.839964628474629],
              [-41.275330692577334, -9.842413224878568],
              [-41.277779288981264, -9.844861821282501],
              [-41.278758727542829, -9.848779575528805],
              [-41.277289569700478, -9.850248733371183],
              [-41.277289569700478, -9.853187049055903],
              [-41.277779288981272, -9.855145926179045],
              [-41.279248446823622, -9.858084241863786],
              [-41.282676481789117, -9.861512276829284],
              [-41.282186762508339, -9.863960873233236],
              [-41.282676481789117, -9.866899188917962],
              [-41.286104516754612, -9.868858066041113],
              [-41.286104516754612, -9.871796381725837],
              [-41.284635358912261, -9.875224416691347],
              [-41.284635358912261, -9.879631890218446],
              [-41.286594236035398, -9.883059925183954],
              [-41.287083955316184, -9.885018802307112],
              [-41.287573674596977, -9.887957117991826],
              [-41.287083955316184, -9.892364591518916],
              [-41.287083955316184, -9.897261784326806],
              [-41.292960586685602, -9.900200100011528],
              [-41.295409183089532, -9.901179538573102],
              [-41.297857779493455, -9.898730942169159],
              [-41.301775533739736, -9.89824122288837],
              [-41.304224130143673, -9.897751503607584],
              [-41.304713849424445, -9.895302907203645],
              [-41.309121322951512, -9.89530290720365],
              [-41.313528796478565, -9.894323468642074],
              [-41.314018515759358, -9.889915995114981],
              [-41.314508235040144, -9.888446837272612],
              [-41.319405427847983, -9.888446837272607],
              [-41.323812901375042, -9.890405714395765],
              [-41.329689532744467, -9.895302907203634],
              [-41.33409700627152, -9.892364591518913],
              [-41.339483918360152, -9.88991599511497],
              [-41.342422234044861, -9.888446837272602],
              [-41.348298865414264, -9.886977679430238],
              [-41.352706338941324, -9.886487960149452],
              [-41.359072689591521, -9.888446837272602],
              [-41.364459601680146, -9.886977679430228],
              [-41.367397917364855, -9.888446837272589],
              [-41.368867075207213, -9.890405714395749],
              [-41.372295110172693, -9.888936556553382],
              [-41.37425398729583, -9.888936556553373],
              [-41.374743706576616, -9.890895433676528],
              [-41.377192302980532, -9.891385152957316],
              [-41.380130618665241, -9.889915995114949],
              [-41.381599776507592, -9.892854310799684],
              [-41.384538092192301, -9.892364591518888],
              [-41.391883881404063, -9.892364591518884],
              [-41.391883881404056, -9.888446837272578],
              [-41.392373600684842, -9.884529083026276],
              [-41.398250232054245, -9.882570205903125],
              [-41.406575459827572, -9.886487960149431],
              [-41.41000349479306, -9.887467398710998],
              [-41.414900687600898, -9.886977679430212],
              [-41.4178390032856, -9.888446837272564],
              [-41.420777318970295, -9.888446837272575],
              [-41.425674511778141, -9.893833749361235],
              [-41.429592266024414, -9.897261784326744],
              [-41.429592266024414, -9.901179538573043],
              [-41.514313701599889, -9.912443082031123],
              [-41.603442610702217, -9.909504766346336],
              [-41.62596969761816, -9.921747748366023],
              [-41.637722960356911, -9.933990730385696],
              [-41.658291170149703, -9.952110343774839],
              [-41.69991730901603, -9.966312202917637],
              [-41.730769623705129, -9.969740237883132],
              [-41.759663061271077, -9.96435332579442],
              [-41.788066779556246, -9.976106588533311],
              [-41.808634989348917, -9.99079816695691],
              [-41.864952706638277, -10.007448622503626],
              [-41.883072320026976, -9.998143956168617],
              [-41.888948951396266, -9.981003781341037],
              [-41.892376986361668, -9.960435571547944],
              [-41.885520916430778, -9.934970168946984],
              [-41.877195688657558, -9.912932801311543],
              [-41.883072320026876, -9.9183197134002],
              [-41.888459232115423, -9.923216906208078],
              [-41.891397547800075, -9.929583256858315],
              [-41.894335863484734, -9.933990730385398],
              [-41.90217137197714, -9.934970168946965],
              [-41.906089126223343, -9.934480449666173],
              [-41.910006880469538, -9.930562695419852],
              [-41.913434915434962, -9.930562695419852],
              [-41.917842388961937, -9.931542133981413],
              [-41.920290985365803, -9.930072976139051],
              [-41.927147055296651, -9.929583256858249],
              [-41.93400312522747, -9.922237467646418],
              [-41.96926291344343, -10.06572521691719],
              [-41.977098421935807, -10.074050444690569],
              [-41.982975053305104, -10.079437356779229],
              [-41.988851684674373, -10.079927076059997],
              [-41.990810561797467, -10.077968198936851],
              [-41.990320842516688, -10.074540163971333],
              [-41.991300281078217, -10.072581286848187],
              [-41.992279719639775, -10.073071006128965],
              [-42.000604947412917, -10.076988760375245],
              [-42.001094666693696, -10.08237567246392],
              [-42.002074105255254, -10.089231742394936],
              [-42.004522701659134, -10.096577531606755],
              [-42.00354326309759, -10.100495285853057],
              [-42.005502140220692, -10.108820513626448],
              [-42.006971298063021, -10.115186864276692],
              [-42.007950736624593, -10.125470969173239],
              [-42.01039933302846, -10.12840928485795],
              [-42.012358210151554, -10.131837319823465],
              [-42.011868490870796, -10.136734512631341],
              [-42.018724560801601, -10.140652266877627],
              [-42.017745122240065, -10.143590582562366],
              [-42.014806806555441, -10.148487775370246],
              [-42.014806806555455, -10.15240552961656],
              [-42.012358210151596, -10.157302722424435],
              [-42.013827367993926, -10.16464851163626],
              [-42.013337648713168, -10.172484020128852],
              [-42.017255402959364, -10.180319528621459],
              [-42.008440455905465, -10.178850370779104],
              [-42.002074105255417, -10.178850370779125],
              [-41.993748877482282, -10.178360651498355],
              [-42.135277749625295, -10.312543734433868],
              [-42.136257188186811, -10.305197945222057],
              [-42.157804836540549, -10.289526928236771],
              [-42.159763713663608, -10.281201700463384],
              [-42.174455292086563, -10.26063349067025],
              [-42.194533782597858, -10.220476509645581],
              [-42.219019746636043, -10.198439142010031],
              [-42.336062654737972, -10.172973739408619],
              [-42.456043878522813, -10.148487775368624],
              [-42.465348544857321, -10.196480264885761],
              [-42.470735456946059, -10.293934401762513],
              [-42.47318405335016, -10.363474539634375],
              [-42.531460647759616, -10.421751134047749],
              [-42.548111103304983, -10.419792256924472],
              [-42.58043257583433, -10.452113729456245],
              [-42.580922295114277, -10.325276435732201],
              [-42.582881172236149, -10.147018617525426],
              [-42.583370891516402, -10.0681738133186],
              [-42.588268084323815, -10.069642971160924],
              [-42.592185838569726, -10.067194374756959],
              [-42.596103592815624, -10.063276620510623],
              [-42.601000785622993, -10.057889708421929],
              [-42.607856855553287, -10.050054199929267],
              [-42.611774609799191, -10.050054199929239],
              [-42.620589556852508, -10.053971954175479],
              [-42.625486749659878, -10.052992515613859],
              [-42.63038394246724, -10.050054199929102],
              [-42.634791415993853, -10.0485850420867],
              [-42.643116643766326, -10.043198129997974],
              [-42.65046243297737, -10.044177568559478],
              [-42.655359625784754, -10.049074761367317],
              [-42.66172597643429, -10.047115884244107],
              [-42.667112888522347, -10.046136445682491],
              [-42.673479239171861, -10.042708410716916],
              [-42.678866151259903, -10.040749533593722],
              [-42.689150256155244, -10.037321498628122],
              [-42.690619413997439, -10.037321498628112],
              [-42.696496045366231, -10.040259814312785],
              [-42.700903518892808, -10.039770095031946],
              [-42.705800711700086, -10.036831779347185],
              [-42.713146500911023, -10.036342060066328],
              [-42.717064255156849, -10.035852340785501],
              [-42.721471728683362, -10.031444867258379],
              [-42.729307237174936, -10.022140200923339],
              [-42.732735272140019, -10.021160762361722],
              [-42.734204429982192, -10.019691604519339],
              [-42.737142745666524, -10.017243008115379],
              [-42.748896008403932, -10.015284130992118],
              [-42.751834324088314, -10.018712165957593],
              [-42.75183432408835, -10.023119639484683],
              [-42.753793201211252, -10.023119639484673],
              [-42.766036183229254, -10.011856096026422],
              [-42.770443656755759, -10.011856096026383],
              [-42.772892253159362, -10.010876657464779],
              [-42.776810007405103, -10.006469183937647],
              [-42.784155796615835, -9.996185079041037],
              [-42.789542708703699, -9.989329009109939],
              [-42.799337094318048, -9.982962658459599],
              [-42.807172602809558, -9.983452377740292],
              [-42.811090357055285, -9.981983219897902],
              [-42.814028672739568, -9.978555184932338],
              [-42.817946426985273, -9.975127149966788],
              [-42.821864181230914, -9.965822483631779],
              [-42.832148286125829, -9.953579501611971],
              [-42.839004356055852, -9.955048659454256],
              [-42.846350145266562, -9.955048659454162],
              [-42.854185653757909, -9.949172028084616],
              [-42.854185653757867, -9.945254273838321],
              [-42.808641760651064, -9.915381397710787],
              [-42.744978254157331, -9.920278590519327],
              [-42.630873661747209, -9.937908484628695],
              [-42.531460647756887, -9.935949607506259],
              [-42.444290615783494, -9.873265539565933],
              [-42.39874672267333, -9.818906699398731],
              [-42.398746722673316, -9.815478664433209],
              [-42.401685038357826, -9.813519787310042],
              [-42.402174757638569, -9.81009175234453],
              [-42.398257003392516, -9.804215120975087],
              [-42.393849529865712, -9.798828208886452],
              [-42.392282428167285, -9.794518679215534],
              [-42.392674203591874, -9.790600924969221],
              [-42.391107101893439, -9.786291395298292],
              [-42.387972898496614, -9.784332518175161],
              [-42.386014021373583, -9.782765416476657],
              [-42.384838695099774, -9.779631213079616],
              [-42.384055144250553, -9.77728056053183],
              [-42.382488042552133, -9.776888785107207],
              [-42.38052916542911, -9.774929907984063],
              [-42.377394962032277, -9.771795704587047],
              [-42.374260758635437, -9.769836827463905],
              [-42.373085432361613, -9.767094399491503],
              [-42.372301881512392, -9.76474374694372],
              [-42.371126555238583, -9.762393094395954],
              [-42.370343004389362, -9.760825992697427],
              [-42.367992351841735, -9.759650666423539],
              [-42.366425250143315, -9.758475340149671],
              [-42.364074597595682, -9.755732912177256],
              [-42.29527883303448, -9.628876029682297],
              [-42.272712568576971, -9.592519270276682],
              [-42.165209392063872, -9.549267263397864],
              [-42.165209392063858, -9.542058595584665],
              [-42.16489597172415, -9.540178073546437],
              [-42.160821507308178, -9.535790188790605],
              [-42.155179941193758, -9.532342565053867],
              [-42.149224954739644, -9.530148622675945],
              [-42.146404171682427, -9.52858152097744],
              [-42.140449185228299, -9.519805751465746],
              [-42.136061300472619, -9.51322392433196],
              [-42.135121039453544, -9.513223924331966],
              [-42.135121039453544, -9.512283663312857],
              [-42.136061300472612, -9.51071656161432],
              [-42.138882083529822, -9.509776300595203],
              [-42.139195503869509, -9.508209198896694],
              [-42.000977134065231, -9.452420378429675],
              [-41.919801266084392, -9.474673222548821],
              [-41.802895479377668, -9.475926903907801],
              [-41.765911879293363, -9.470598758132873],
              [-41.654020818021003, -9.514477605691582],
              [-41.579113356832771, -9.499120009046139],
              [-41.405556843723431, -9.491676275978257],
              [-41.426556006483366, -9.354711587527495],
              [-41.445047806525722, -9.284505431433736],
              [-41.385811362322158, -9.280117546677911],
              [-41.309023379095208, -9.237805800817872],
              [-41.281442389201416, -9.209911390584194],
              [-41.224713307715298, -9.207404027866586],
              [-41.18334182287456, -9.188285387144633],
              [-41.136328771919132, -9.13939181415078],
              [-41.14761190414842, -9.069185658057039],
              [-41.049824758161094, -9.010576054532359],
              [-41.017229042831957, -8.995845298566271],
              [-40.970842832555874, -8.99365135618835],
              [-40.950157090135455, -8.998039240944209],
              [-40.916621113787194, -9.018411563024975],
              [-40.904084300199045, -9.025306810498481],
              [-40.905651401897572, -9.03376915967049],
              [-40.909412445974013, -9.040037566464573],
              [-40.916307693447486, -9.049126756315996],
              [-40.915680852768077, -9.052260959713045],
              [-40.911606388351942, -9.055081742770374],
              [-40.909725866313714, -9.059783047865945],
              [-40.910666127332824, -9.066991715679137],
              [-40.915680852768084, -9.072006441114402],
              [-40.917561374806304, -9.079215108927601],
              [-40.915994273107785, -9.08109563096583],
              [-40.910039286653422, -9.085483515721677],
              [-40.907531923935785, -9.085170095381981],
              [-40.904711140878462, -9.086423776740798],
              [-40.890293805252092, -9.097706908970162],
              [-40.875876469625737, -9.108990041199513],
              [-40.846728378033305, -9.129989203959694],
              [-40.835445245803975, -9.139705234490528],
              [-40.830743940708416, -9.142526017547857],
              [-40.819147388139385, -9.150674946380176],
              [-40.808804516929165, -9.154749410796326],
              [-40.796894544020418, -9.156943353174247],
              [-40.794387181302803, -9.159764136231599],
              [-40.794073760963087, -9.168853326083015],
              [-40.791879818585159, -9.1744948921977],
              [-40.786865093149899, -9.183270661709402],
              [-40.777462482958796, -9.197687997335812],
              [-40.776208801599971, -9.202389302431373],
              [-40.774328279561743, -9.205836926168105],
              [-40.763358567672128, -9.220881102473914],
              [-40.758970682916271, -9.231223973684163],
              [-40.758343842236869, -9.235611858440024],
              [-40.756463320198641, -9.243133946592915],
              [-40.752702276122193, -9.251282875425224],
              [-40.748627811706044, -9.25755128221931],
              [-40.748627811706044, -9.262879427994283],
              [-40.749254652385453, -9.26695389241044],
              [-40.75113517442368, -9.269147834788367],
              [-40.750508333744271, -9.271655197506012],
              [-40.751762015103083, -9.27447598056334],
              [-40.75520963883983, -9.277610183960379],
              [-40.75520963883983, -9.28105780769712],
              [-40.757090160878043, -9.284505431433878],
              [-40.761478045633908, -9.28826647551033],
              [-40.764612249030947, -9.287953055170615],
              [-40.773701438882348, -9.29265436026618],
              [-40.774955120241167, -9.296415404342644],
              [-40.779969845676426, -9.298609346720571],
              [-40.782477208394056, -9.302370390797011],
              [-40.783730889752874, -9.306758275552875],
              [-40.784044310092575, -9.313340102686666],
              [-40.75520963883983, -9.313653523026364],
              [-40.745180187969311, -9.318041407782234],
              [-40.694719513276993, -9.333712424767443],
              [-40.703808703128402, -9.355965268886447],
              [-40.714151574338629, -9.371009445192247],
              [-40.727315228606201, -9.388247563875977],
              [-40.7442399269502, -9.406112523239116],
              [-40.767119611748576, -9.4421558623051],
              [-40.765239089710356, -9.447797428419781],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3322',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO OUTEIRO DO PEDRO',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '13896',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Lei ordinária nº 9989 de 13/01/1987',
        DT_ULTIM10: '04/10/2017',
        CODIGO_U11: '0000.26.3322',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.195350874237441, -8.058780580424475],
              [-35.195364353940604, -8.059055541712643],
              [-35.195425632312606, -8.059294373260823],
              [-35.195480814742943, -8.05951530091683],
              [-35.195596275685006, -8.059789715280667],
              [-35.195693449801944, -8.060010417967863],
              [-35.195881220161702, -8.060344235011131],
              [-35.196305106473403, -8.061077379721961],
              [-35.196426890325597, -8.061411549716281],
              [-35.196536096348417, -8.061638166375049],
              [-35.196662911291035, -8.061792939496073],
              [-35.196789338287822, -8.061875968065941],
              [-35.196909316392443, -8.061875324155087],
              [-35.197011038667448, -8.06182694753001],
              [-35.197166136625206, -8.061664682655811],
              [-35.197273664882502, -8.061580400259633],
              [-35.197393448014246, -8.061543884573188],
              [-35.197555642773921, -8.061584867431593],
              [-35.197736350327183, -8.06172141470493],
              [-35.197893257032476, -8.061893963736027],
              [-35.197996142145151, -8.062060822764481],
              [-35.198190107433035, -8.062430479587995],
              [-35.19835323906198, -8.062644847361748],
              [-35.198509984789794, -8.0627875015799],
              [-35.198678373477676, -8.062864324218392],
              [-35.198822539376174, -8.062899425100941],
              [-35.199026565845053, -8.062910287177647],
              [-35.199182277717213, -8.062861619592688],
              [-35.199343665589716, -8.062753131386236],
              [-35.199492763592318, -8.062590898507551],
              [-35.199587776011306, -8.06241101970091],
              [-35.199682626452912, -8.062201246081335],
              [-35.199759158073846, -8.061931780822396],
              [-35.199841654654549, -8.061656305605323],
              [-35.19988819149934, -8.061387001324128],
              [-35.199940920272311, -8.06115353882295],
              [-35.199963849242067, -8.060956109075098],
              [-35.199998679135788, -8.060740679448411],
              [-35.200039249020826, -8.060477386125362],
              [-35.200067563031403, -8.060166328092667],
              [-35.200072204176038, -8.059915185668116],
              [-35.200083394199218, -8.059765651612155],
              [-35.200136672895496, -8.059633827475169],
              [-35.200189918596287, -8.059496025375541],
              [-35.200237133346356, -8.059352276308893],
              [-35.200260386270962, -8.059214635187056],
              [-35.20025970736566, -8.059089080972553],
              [-35.200246998554334, -8.058957611786576],
              [-35.200240062729847, -8.058784257866876],
              [-35.200239157856089, -8.058616851914239],
              [-35.200214323152316, -8.058461531868954],
              [-35.200219126274355, -8.058240284257433],
              [-35.200224156364825, -8.058060887384073],
              [-35.200217478504214, -8.057935365165138],
              [-35.200210672661669, -8.057785927095864],
              [-35.200203833823643, -8.057630510063964],
              [-35.200178805146898, -8.057439317243192],
              [-35.200165902362606, -8.05727197528163],
              [-35.200159064524556, -8.057116559249781],
              [-35.200146161740314, -8.056949217288285],
              [-35.200097235229101, -8.056776089337458],
              [-35.200041824830279, -8.056513311943283],
              [-35.199986996350368, -8.056358151875861],
              [-35.199938166825625, -8.056202960812822],
              [-35.199943325898026, -8.056047479789855],
              [-35.199984477701825, -8.055891805793649],
              [-35.200013566604177, -8.055724237863354],
              [-35.200048848434719, -8.05559251171305],
              [-35.200054104493596, -8.055454966577875],
              [-35.200023464807508, -8.055335551303767],
              [-35.199968734314048, -8.055198329124128],
              [-35.199901810937114, -8.055025297160004],
              [-35.199829018586911, -8.054876214041816],
              [-35.199737972407114, -8.054679396209545],
              [-35.199586777697384, -8.054453005519816],
              [-35.199357598569698, -8.054227033772234],
              [-35.199158672182065, -8.054048730747622],
              [-35.198965679758743, -8.053858438802312],
              [-35.198760690424876, -8.053668209848139],
              [-35.198549541158343, -8.053448120076544],
              [-35.198368546645412, -8.053257762140198],
              [-35.198055130180443, -8.05298440862971],
              [-35.197802345177685, -8.052830309415482],
              [-35.197676146149284, -8.052789133583939],
              [-35.197574199905837, -8.052795658470888],
              [-35.197460611702319, -8.05286801493736],
              [-35.197305063807683, -8.052946575335318],
              [-35.197089496365237, -8.053019477726169],
              [-35.196963459314311, -8.053008194707553],
              [-35.196771402760369, -8.052991286677427],
              [-35.196501298797351, -8.052962838664222],
              [-35.196273218516453, -8.052940143644799],
              [-35.196111317716145, -8.05295296844983],
              [-35.19592561406791, -8.053001795012594],
              [-35.195758004271845, -8.053068461476455],
              [-35.195614550274193, -8.053164892771028],
              [-35.195501187039177, -8.053279100975642],
              [-35.195442069365285, -8.05344084992103],
              [-35.195395013592787, -8.053614492800499],
              [-35.195384175520211, -8.053829794444756],
              [-35.195391270322297, -8.054033042177176],
              [-35.195422197967851, -8.054206268114479],
              [-35.195429131792459, -8.054379621033963],
              [-35.195406135831902, -8.054565092856441],
              [-35.195412971669988, -8.054720510888139],
              [-35.195407810597644, -8.054875991911015],
              [-35.195426806323695, -8.055061238764601],
              [-35.195482116736116, -8.055306082270793],
              [-35.195531653161751, -8.055592808510671],
              [-35.195581448551302, -8.055927365451298],
              [-35.195624761053217, -8.056172271948649],
              [-35.195643917756712, -8.056387413615225],
              [-35.195639047643787, -8.056596704301445],
              [-35.195628113584654, -8.056794070058013],
              [-35.195581283780555, -8.057009563675617],
              [-35.195516683079283, -8.057267007018082],
              [-35.195451953395981, -8.057500534510099],
              [-35.19540515558743, -8.057722008090389],
              [-35.195382321604221, -8.057937373725808],
              [-35.195353390679379, -8.058134835469057],
              [-35.195342650593169, -8.058368074001205],
              [-35.195337715489231, -8.058565406762282],
              [-35.195350874237441, -8.058780580424475],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '186',
        NOME_UC1: 'PARQUE NACIONAL MARINHO DE FERNANDO DE NORONHA',
        ID_WCMC2: '41087',
        CATEGORI3: 'Parque',
        GRUPO4: 'PI',
        ESFERA5: 'federal',
        ANO_CRIA6: '1988',
        GID7: '283',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 96693 de 14/10/1988',
        DT_ULTIM10: '29/11/2007',
        CODIGO_U11: '0000.00.0186',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-32.403688022442211, -3.827720452527217],
              [-32.403977913642471, -3.828055425718051],
              [-32.40426780399175, -3.827956006681799],
              [-32.404065664938322, -3.827738822498267],
              [-32.404035142323309, -3.827440310080259],
              [-32.403901640665133, -3.827132764705262],
              [-32.403680386624529, -3.827394768353195],
              [-32.403688022442211, -3.827720452527217],
            ],
          ],
          [
            [
              [-32.354500756825153, -3.825646896551741],
              [-32.350114984427165, -3.843263965958314],
              [-32.35545078234609, -3.85673330879956],
              [-32.35525137081224, -3.878080538330278],
              [-32.355718349835648, -3.900077053511624],
              [-32.386153425189427, -3.916222750120345],
              [-32.420989083530294, -3.910470279338094],
              [-32.427681505433597, -3.896518008728274],
              [-32.483515888035903, -3.877856925223798],
              [-32.484530862235097, -3.870401760235222],
              [-32.484953864399408, -3.867294672331635],
              [-32.477998680199029, -3.860294572617071],
              [-32.465596021294488, -3.847871320074364],
              [-32.459415203623109, -3.841740960122736],
              [-32.44095583099643, -3.82287904245481],
              [-32.440488596150082, -3.837942046521569],
              [-32.440450607080322, -3.839166682877774],
              [-32.440372469936641, -3.84168552698595],
              [-32.440391788232162, -3.846112781563817],
              [-32.440380279123403, -3.846737271139402],
              [-32.439985368845569, -3.849445461495996],
              [-32.439999227245281, -3.850812677634192],
              [-32.440454676679735, -3.85182251502823],
              [-32.439943270049056, -3.852881592658282],
              [-32.439687620262049, -3.853327493699263],
              [-32.439609697875682, -3.853639690962546],
              [-32.43923167909454, -3.854453517767556],
              [-32.438609404574613, -3.855267875482954],
              [-32.439097673926746, -3.855635513462488],
              [-32.439607492732129, -3.857007513733106],
              [-32.440150481156877, -3.858591420176686],
              [-32.440215886452492, -3.858876952721496],
              [-32.440112498346984, -3.859058372111095],
              [-32.440000371351069, -3.859157059215506],
              [-32.440026860018783, -3.859408612041682],
              [-32.439972095415037, -3.859728780244067],
              [-32.439816432736677, -3.860074381190546],
              [-32.439542072083242, -3.860634329235368],
              [-32.43927186349336, -3.86089924884211],
              [-32.4388763291077, -3.861037801501615],
              [-32.43862654239279, -3.861117627721574],
              [-32.438435048192389, -3.861041083318968],
              [-32.43801726210625, -3.860909569314446],
              [-32.437662058437567, -3.860786665257077],
              [-32.437317929651115, -3.860708373816307],
              [-32.437095840688158, -3.860763985256139],
              [-32.436707293309027, -3.860696815703453],
              [-32.435918994600542, -3.860718595239299],
              [-32.435319476163457, -3.86068474032374],
              [-32.434864210348138, -3.860773650393293],
              [-32.434509694583824, -3.860909139093675],
              [-32.43417372262531, -3.861109768235135],
              [-32.434164514597299, -3.861437249389931],
              [-32.434061856350795, -3.862006613412793],
              [-32.433907865408393, -3.863070268128159],
              [-32.433763139543885, -3.86366121294912],
              [-32.433529584497997, -3.864263249642946],
              [-32.433484922780174, -3.864642378337085],
              [-32.433251137616629, -3.865590118030816],
              [-32.433123700556322, -3.866283725967063],
              [-32.432494742343643, -3.866389402830078],
              [-32.431351765779524, -3.866815532732503],
              [-32.430674383990272, -3.86699350495115],
              [-32.430352286911081, -3.867171715292085],
              [-32.429728055583823, -3.867482235379009],
              [-32.42945845106852, -3.867516335988794],
              [-32.429070212653293, -3.867546103594712],
              [-32.428791474241699, -3.867524938681022],
              [-32.428336535463458, -3.86749903474667],
              [-32.428055424068205, -3.867494264689864],
              [-32.427778649632188, -3.867491406617829],
              [-32.42750458016949, -3.867492794510008],
              [-32.427148203520623, -3.867345786661117],
              [-32.426949998398982, -3.867221927666877],
              [-32.426757862252494, -3.867007545460177],
              [-32.426672627064534, -3.866942014999074],
              [-32.426676900082803, -3.866775132448923],
              [-32.42664407550172, -3.866443169318399],
              [-32.426611360900829, -3.866165533716529],
              [-32.426394390024448, -3.865835513504794],
              [-32.426489667251921, -3.865518652288035],
              [-32.426467536495394, -3.865407120248269],
              [-32.426145821609857, -3.86501659152521],
              [-32.425912875875163, -3.864704186155088],
              [-32.425635491536525, -3.864436356382706],
              [-32.425280371910162, -3.864190778390078],
              [-32.42499180863642, -3.864034458645917],
              [-32.4249474130553, -3.864012124824368],
              [-32.424647746884418, -3.8638557970765],
              [-32.424392454283286, -3.863755257859869],
              [-32.424126089800247, -3.863610105026717],
              [-32.424048499584373, -3.863420472645445],
              [-32.423782240154367, -3.863119196167342],
              [-32.423626810597604, -3.863107938210751],
              [-32.42313832613673, -3.863062998430224],
              [-32.422871939642263, -3.862951300306683],
              [-32.422594502277107, -3.862761531856933],
              [-32.422383680295134, -3.862571809430024],
              [-32.422239527722716, -3.862304069703542],
              [-32.42231742709388, -3.862036482053254],
              [-32.42236201877359, -3.861768871391503],
              [-32.422406730513892, -3.861322834278616],
              [-32.422483238922695, -3.860983586249857],
              [-32.422349432159002, -3.860991895130996],
              [-32.422086929550382, -3.861105937049593],
              [-32.421868258584624, -3.861079428203363],
              [-32.421789619358506, -3.860947581320347],
              [-32.421745981814269, -3.860798184601825],
              [-32.421754872804868, -3.860587320435147],
              [-32.421807554417214, -3.860297410969856],
              [-32.421781596803534, -3.859875652621616],
              [-32.421755532136032, -3.859612047900615],
              [-32.421659452098694, -3.85940111169794],
              [-32.421371104933115, -3.85892645871741],
              [-32.421082591683962, -3.858697818601312],
              [-32.420356683476399, -3.858495238106534],
              [-32.419604414452216, -3.858468365077613],
              [-32.418983341203933, -3.858467938864638],
              [-32.418362345994531, -3.858353292643343],
              [-32.418012540282717, -3.858212472743739],
              [-32.417540330734802, -3.857983705564907],
              [-32.416989567002609, -3.857500083570924],
              [-32.416396245699097, -3.856916291431807],
              [-32.416396697694992, -3.856915853435736],
              [-32.416119059350947, -3.856670656467509],
              [-32.415716692178592, -3.85637757287148],
              [-32.415078279163303, -3.856167985468402],
              [-32.414009460095826, -3.856069645949691],
              [-32.412662932561865, -3.856082657367577],
              [-32.412079835928665, -3.856179855320546],
              [-32.411830254384597, -3.855761388866326],
              [-32.411900420115153, -3.854659929452937],
              [-32.412913901791271, -3.854479370373317],
              [-32.413413897290518, -3.854103251812781],
              [-32.41502422941025, -3.854006761211181],
              [-32.415371565342639, -3.85357476308242],
              [-32.415885240614266, -3.853491457984513],
              [-32.416440628533216, -3.853310578748027],
              [-32.416690620280555, -3.853129490407226],
              [-32.416829598075317, -3.852892551512545],
              [-32.416760399821996, -3.852585755151809],
              [-32.416649583968621, -3.85223710113989],
              [-32.416371696412206, -3.85261337477668],
              [-32.416052352336081, -3.852710756820072],
              [-32.41587196804516, -3.85259908772673],
              [-32.415747129249418, -3.852459569894446],
              [-32.415844518458201, -3.852138944711733],
              [-32.41580298890058, -3.851971599150057],
              [-32.415483635819669, -3.852082924072524],
              [-32.414872780443318, -3.852180105016159],
              [-32.414303587690725, -3.852249429216277],
              [-32.413692799347473, -3.852249007007305],
              [-32.413304114947273, -3.852248738874271],
              [-32.412977199984212, -3.852221848993851],
              [-32.412797759656527, -3.852191530194759],
              [-32.412792579743254, -3.852079000169826],
              [-32.412798935704238, -3.85202171466941],
              [-32.413061901682902, -3.850821507180699],
              [-32.413216020424358, -3.850331925484724],
              [-32.413027255319953, -3.849904528129547],
              [-32.412809969397891, -3.849714480704012],
              [-32.412422585077238, -3.849448358879702],
              [-32.412101344131081, -3.849220259748371],
              [-32.411902884995293, -3.849144164340021],
              [-32.411666434119716, -3.849333896610416],
              [-32.411354417973492, -3.849438123596992],
              [-32.411023469989487, -3.84958031724732],
              [-32.410739759554822, -3.849760521584191],
              [-32.410229148205509, -3.849988043431189],
              [-32.410068495716281, -3.849921467953327],
              [-32.409860767758119, -3.849579509850048],
              [-32.409719151158129, -3.849323052027402],
              [-32.409577403493849, -3.849256489555995],
              [-32.409411254139513, -3.848946604188654],
              [-32.408930605589319, -3.848951663977672],
              [-32.408897470029046, -3.848566068313992],
              [-32.409245183850814, -3.848443984494879],
              [-32.409224164193049, -3.848015781205304],
              [-32.409604176978888, -3.847129694030845],
              [-32.409654660596367, -3.846882839191725],
              [-32.409616967004339, -3.846711905662783],
              [-32.409569853515954, -3.846493492542779],
              [-32.409390373240385, -3.846312967047774],
              [-32.409002807829928, -3.846312696915453],
              [-32.408785307801807, -3.846435977769417],
              [-32.408548764881459, -3.84675863788576],
              [-32.408302683006866, -3.84720472292704],
              [-32.407896112723449, -3.847346861551632],
              [-32.407754347049554, -3.847308783832982],
              [-32.407423498113822, -3.847308553719972],
              [-32.407262145503971, -3.84761127203541],
              [-32.406515815416135, -3.847611754771863],
              [-32.406373891665176, -3.847801551074542],
              [-32.406033227640542, -3.848323528423919],
              [-32.405976248037391, -3.848703281106946],
              [-32.405900375615701, -3.84906403094832],
              [-32.405824502194136, -3.849424779789724],
              [-32.405720455125071, -3.849519655929816],
              [-32.405574919403684, -3.849720967131248],
              [-32.405464977366996, -3.849880279709809],
              [-32.405171880052237, -3.849965526867661],
              [-32.404736969041174, -3.850079160729764],
              [-32.404528900915984, -3.850230931339679],
              [-32.404235775588418, -3.850354158167866],
              [-32.4039993957484, -3.850439445345118],
              [-32.403592671391181, -3.850799963073315],
              [-32.403356084451637, -3.851179588695016],
              [-32.40296396396171, -3.851532685492747],
              [-32.402843976044956, -3.85161420874317],
              [-32.402766497757774, -3.851628058596035],
              [-32.402812892228404, -3.851917089102607],
              [-32.402920472099964, -3.852299670818293],
              [-32.403071360525551, -3.852812495418249],
              [-32.403326410074669, -3.853069035279634],
              [-32.40349640442156, -3.853297029359323],
              [-32.403656923846249, -3.853553502188448],
              [-32.403467581459211, -3.85396164557886],
              [-32.403514673937806, -3.854208543451732],
              [-32.40359936696327, -3.854759301699384],
              [-32.403605305866371, -3.854880717647309],
              [-32.403562025235416, -3.854972929831718],
              [-32.403476668015031, -3.855004446528332],
              [-32.403408973656717, -3.854961970873559],
              [-32.403270376968621, -3.85488048353269],
              [-32.40302015532373, -3.854771786389378],
              [-32.402720366163926, -3.85458777688261],
              [-32.402408579130487, -3.85435968275442],
              [-32.402172395386906, -3.854160124404815],
              [-32.401907932944688, -3.853846610034678],
              [-32.401756873435552, -3.853580649291379],
              [-32.401502082011838, -3.85295381364507],
              [-32.401351135557327, -3.852526440303231],
              [-32.401238376935865, -3.851557891673584],
              [-32.401153719926292, -3.85095965883842],
              [-32.401390592003949, -3.850171756762009],
              [-32.401683253643064, -3.849156786676712],
              [-32.401882888813816, -3.849099190246147],
              [-32.402260567430808, -3.848765007279106],
              [-32.402513210199665, -3.848449174109382],
              [-32.402800575690073, -3.848007934040537],
              [-32.402941814518378, -3.847611682530218],
              [-32.402980728292903, -3.847219990944847],
              [-32.402924202911336, -3.846944602316363],
              [-32.402801981148102, -3.846640692912836],
              [-32.404806420099035, -3.845142029622306],
              [-32.405174601141141, -3.843828325157286],
              [-32.40331054579579, -3.842695759344666],
              [-32.40250609432065, -3.842480783932158],
              [-32.399483064748729, -3.838327540950408],
              [-32.399626940441827, -3.838252608650964],
              [-32.399726398559018, -3.838140876655844],
              [-32.399774263195191, -3.837909320683369],
              [-32.399814414912058, -3.837651157939183],
              [-32.399826219851171, -3.837510064168708],
              [-32.399822344936396, -3.83736631741576],
              [-32.399778760410108, -3.837162647169431],
              [-32.399710866120358, -3.836925610204388],
              [-32.399651895753884, -3.83667142839144],
              [-32.399572561572377, -3.836427803479763],
              [-32.399414971109444, -3.836202284383707],
              [-32.399233871836991, -3.836056549586642],
              [-32.399096209150102, -3.835945770500973],
              [-32.398900073002203, -3.835842672328401],
              [-32.398634900498976, -3.835724388263821],
              [-32.398305038594103, -3.835608361157185],
              [-32.398062592860363, -3.835548295594853],
              [-32.397793406357138, -3.83553826358881],
              [-32.397585718351586, -3.835332341305287],
              [-32.397506357156544, -3.835128646046805],
              [-32.397395191254837, -3.834928921742896],
              [-32.397400320289776, -3.834688753830493],
              [-32.397403440325064, -3.834501684456365],
              [-32.397477153718874, -3.834278745418125],
              [-32.397643047253325, -3.834071657274144],
              [-32.397666217093857, -3.833910917678145],
              [-32.39760509771402, -3.833753442024714],
              [-32.397463678065876, -3.833631269036769],
              [-32.397380251864675, -3.833555345667417],
              [-32.397388432826503, -3.833445740622165],
              [-32.39744401735355, -3.833323800700503],
              [-32.397543461463933, -3.833232034531922],
              [-32.397694804080963, -3.833176853063593],
              [-32.397756223568329, -3.833012954508214],
              [-32.397758371595174, -3.832876814691375],
              [-32.39760910906778, -3.832614242920226],
              [-32.397353118455811, -3.832565081258619],
              [-32.397255575337127, -3.832629805662721],
              [-32.397116199596269, -3.832722285811284],
              [-32.397068834995046, -3.832838827782679],
              [-32.397081610817125, -3.833012479278826],
              [-32.397025303295088, -3.833139492156239],
              [-32.396915458319292, -3.833119788289393],
              [-32.396870774760316, -3.83304056396203],
              [-32.396852041998528, -3.832852217591276],
              [-32.396891379672425, -3.83274059357445],
              [-32.396955267128959, -3.832599678820431],
              [-32.397011329660096, -3.832453038113509],
              [-32.397025897580235, -3.832292467513019],
              [-32.397007597813797, -3.832105651129333],
              [-32.396959273316703, -3.831944545511934],
              [-32.397309897104059, -3.831842331520959],
              [-32.397619334307663, -3.831639062393477],
              [-32.3977315773502, -3.831399415513818],
              [-32.397874755073651, -3.831254807819261],
              [-32.397998164002829, -3.83104423269087],
              [-32.398010772980555, -3.83076851609006],
              [-32.3981734695735, -3.830477360675122],
              [-32.398292909557981, -3.830212415017622],
              [-32.39852156347952, -3.83009777909242],
              [-32.398740236472861, -3.830043030643565],
              [-32.39885448239405, -3.830103004162198],
              [-32.398903350880545, -3.83028072763546],
              [-32.402926989033901, -3.826753095175228],
              [-32.403061291667818, -3.823572334339314],
              [-32.403373617325556, -3.819040957806868],
              [-32.403459453278543, -3.816852864843354],
              [-32.403842515514583, -3.81162979734738],
              [-32.403913660289128, -3.810359305408983],
              [-32.404013019855327, -3.808933544829193],
              [-32.404269408174287, -3.803964496087264],
              [-32.404368706711253, -3.802623437772487],
              [-32.404354865945237, -3.802312851466299],
              [-32.3841995910905, -3.792133695008732],
              [-32.367777038228255, -3.792133680387991],
              [-32.354500756825153, -3.825646896551741],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2250',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO URUCU',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5426',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '10/09/2011',
        CODIGO_U11: '0000.26.2250',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.265084986680598, -8.262888073445167],
              [-35.265021377171053, -8.262585369116808],
              [-35.264995075973353, -8.262428246205415],
              [-35.264964960497856, -8.262350052685612],
              [-35.26492313361333, -8.262255203087504],
              [-35.264879866989382, -8.262098539485189],
              [-35.264835893161987, -8.261913588105971],
              [-35.264794069216649, -8.261818828696814],
              [-35.264756763571597, -8.26167324716797],
              [-35.264748991461097, -8.261583267488172],
              [-35.264638476566532, -8.261456178688512],
              [-35.264589216899701, -8.261282736292548],
              [-35.264553508454618, -8.261210266489696],
              [-35.2644307945192, -8.261044109720265],
              [-35.264330055230332, -8.260849175775729],
              [-35.26427105243998, -8.260749179982531],
              [-35.264247266279668, -8.260698971566896],
              [-35.264134941258291, -8.260493075148149],
              [-35.264052856795431, -8.260371066098585],
              [-35.263965514878947, -8.26026598964401],
              [-35.263878452879233, -8.260177814452566],
              [-35.263859921994964, -8.260110671063345],
              [-35.263773531891324, -8.260044999124617],
              [-35.263704507833253, -8.260001460654756],
              [-35.263458058069986, -8.259871796772348],
              [-35.263331900710334, -8.259801465057917],
              [-35.26298215965312, -8.259617791379149],
              [-35.262867956395411, -8.259575322709543],
              [-35.262794011070312, -8.259560013429546],
              [-35.262771329402931, -8.259560504970416],
              [-35.2627034706911, -8.259562061831614],
              [-35.262543904108696, -8.259520573812203],
              [-35.262498143083477, -8.259499049685422],
              [-35.262441161928862, -8.259483463932334],
              [-35.262327326272619, -8.259457805659224],
              [-35.262194960582534, -8.259365094034015],
              [-35.262183251706205, -8.259348440125432],
              [-35.262074212730255, -8.25928894884947],
              [-35.26193573097251, -8.259173766872994],
              [-35.261860959589519, -8.259124748810891],
              [-35.261763719644144, -8.259081822908717],
              [-35.261728716089003, -8.259037550260427],
              [-35.261653699745438, -8.258977325088056],
              [-35.26151451408748, -8.258833945290291],
              [-35.261450132222258, -8.258745280201968],
              [-35.261409135605255, -8.258684228708328],
              [-35.261345244281152, -8.258618065634689],
              [-35.261275327961968, -8.258529522012404],
              [-35.261252033337271, -8.258501905560594],
              [-35.26111275792762, -8.258358616286083],
              [-35.260998061823386, -8.25829355465161],
              [-35.26092879100775, -8.258238810797733],
              [-35.260854355969123, -8.258201086941716],
              [-35.260791416147825, -8.258174326098311],
              [-35.26071698062858, -8.258136514285098],
              [-35.260665256830698, -8.258103905352273],
              [-35.26044690488466, -8.257968022568113],
              [-35.260268440416745, -8.257842400885977],
              [-35.260187431437558, -8.257765396479215],
              [-35.260111799826227, -8.257676975058517],
              [-35.260047786395305, -8.257605208136706],
              [-35.260001532945331, -8.25756117925684],
              [-35.259942989803804, -8.257477994794815],
              [-35.259808849403413, -8.257311988208142],
              [-35.259792709927012, -8.257346155907975],
              [-35.259740159879236, -8.257279747931252],
              [-35.259710291435489, -8.257212848611848],
              [-35.25965101309891, -8.257095952611413],
              [-35.259614813333343, -8.257000979945952],
              [-35.259561863596019, -8.256912069336202],
              [-35.259484454747735, -8.256750444108516],
              [-35.259487412162947, -8.25663211152863],
              [-35.259513784101685, -8.256546999121445],
              [-35.259518550946794, -8.256507474196233],
              [-35.259596433829664, -8.256449458166616],
              [-35.259685409185572, -8.256379810086658],
              [-35.259773526750365, -8.256270849308741],
              [-35.259861185785603, -8.256144990499328],
              [-35.259934468342053, -8.255884264789618],
              [-35.259974922892376, -8.255674972324282],
              [-35.260028652432453, -8.255549846511954],
              [-35.260041016266115, -8.255346858311825],
              [-35.260038993256622, -8.255262359733257],
              [-35.260030119520202, -8.255121769619246],
              [-35.260027942172002, -8.255026061140148],
              [-35.260031265131317, -8.254924629456054],
              [-35.260029119687509, -8.254834527337845],
              [-35.260033271355503, -8.254766894513237],
              [-35.260040044415753, -8.254569634655031],
              [-35.260031535809205, -8.254445852842618],
              [-35.260018510999075, -8.254372985781263],
              [-35.259991597486646, -8.254187665964304],
              [-35.260004729141649, -8.254024083507813],
              [-35.260001112733526, -8.253866470533945],
              [-35.260008464510001, -8.253691712243775],
              [-35.26001215723803, -8.25360708884029],
              [-35.260010470870121, -8.253533977291402],
              [-35.260002700458813, -8.253443994838696],
              [-35.259937584307707, -8.253321527454952],
              [-35.25992538542183, -8.253282369012421],
              [-35.259924189406526, -8.253231759533364],
              [-35.259920325391974, -8.253062851555063],
              [-35.259922944619454, -8.252933222204309],
              [-35.259928734980313, -8.2526909535574],
              [-35.259933043385175, -8.252634528302602],
              [-35.259964304499341, -8.252515494572799],
              [-35.260001803916964, -8.252424534712711],
              [-35.260001436572544, -8.252407726413285],
              [-35.260032236887554, -8.252271792359936],
              [-35.260069245970222, -8.252158330427893],
              [-35.260074012811053, -8.252118803203409],
              [-35.260165699954676, -8.251919617980505],
              [-35.260215243820618, -8.251856611296526],
              [-35.260264696560853, -8.251793512569254],
              [-35.260308985042187, -8.251747436150547],
              [-35.260359143975172, -8.251712537368327],
              [-35.260397715016481, -8.251666673694521],
              [-35.260503164658893, -8.251574154889454],
              [-35.260535776972979, -8.251517118699853],
              [-35.260548388224819, -8.251325339922756],
              [-35.26054755955758, -8.2512915387547],
              [-35.260546946780231, -8.251263433150493],
              [-35.260607691677471, -8.250952252408519],
              [-35.260653158296307, -8.25071473246887],
              [-35.260667240533294, -8.250590462219014],
              [-35.26066921508059, -8.25042712326885],
              [-35.260667528238741, -8.250353919188678],
              [-35.260634532273393, -8.250151911277733],
              [-35.260616127613552, -8.250090373269764],
              [-35.260603592852526, -8.250039918033846],
              [-35.260595913704009, -8.249950027637288],
              [-35.260507624465454, -8.249805544803335],
              [-35.260489710147631, -8.24976650885027],
              [-35.260471727854451, -8.249708980716067],
              [-35.260463294503857, -8.249700497010178],
              [-35.260412969376063, -8.249610122978359],
              [-35.260396173696819, -8.24957623141092],
              [-35.260311997422406, -8.249468786772651],
              [-35.260233335259123, -8.249389510307378],
              [-35.260188463756329, -8.249332999237714],
              [-35.260143562232564, -8.249270796172285],
              [-35.259715064604961, -8.24902067581985],
              [-35.259534736945959, -8.24890147955387],
              [-35.259433214608158, -8.248838961678453],
              [-35.259342949276174, -8.248793373468796],
              [-35.259235513747377, -8.248775903215369],
              [-35.259235572488819, -8.248770298651669],
              [-35.259218691823051, -8.248753310416062],
              [-35.259139998159249, -8.248668429582924],
              [-35.259056024526608, -8.248532781743787],
              [-35.258926660308695, -8.248408234963994],
              [-35.25884831825978, -8.248289548481916],
              [-35.258820848716738, -8.248165512402119],
              [-35.258776789007378, -8.247996287068604],
              [-35.258727217753403, -8.247798891131158],
              [-35.258711088585663, -8.247674971581668],
              [-35.258616010135455, -8.247516788721111],
              [-35.258538043266775, -8.247336094746251],
              [-35.258527659547326, -8.247200936627701],
              [-35.258513069034088, -8.246868578011957],
              [-35.258445861960801, -8.246761215218443],
              [-35.258401685708129, -8.246603289120905],
              [-35.258374451105013, -8.246456746737504],
              [-35.258358034288406, -8.246377840804142],
              [-35.258359455971103, -8.246180701119275],
              [-35.258411923847696, -8.245961398300473],
              [-35.258418130561218, -8.245888242158701],
              [-35.25847611224382, -8.245697109942132],
              [-35.25849389244808, -8.245584568694222],
              [-35.258551932337824, -8.245387741680126],
              [-35.258586710513818, -8.24528097961611],
              [-35.25859825525491, -8.245252895698211],
              [-35.258621227193821, -8.245207934682261],
              [-35.258742033556096, -8.244943959001228],
              [-35.258782261344066, -8.244854069180342],
              [-35.258822986955209, -8.244707865794062],
              [-35.258909419972539, -8.244494339137123],
              [-35.25894381953276, -8.244432591263575],
              [-35.258972651905012, -8.244365182600038],
              [-35.259086931525012, -8.244213772661844],
              [-35.259149899585957, -8.244118333254175],
              [-35.259201732988132, -8.243994752628986],
              [-35.259236713156717, -8.243859790104777],
              [-35.259237991286987, -8.243685246426377],
              [-35.259188708868322, -8.243442839074733],
              [-35.259171943181357, -8.243414551415613],
              [-35.259110743059182, -8.243262142718519],
              [-35.259060595200957, -8.243154866565479],
              [-35.259027583843086, -8.243013958677269],
              [-35.258960960695362, -8.242833381135361],
              [-35.258894076622205, -8.242686610929315],
              [-35.25883771986009, -8.242652490848409],
              [-35.258803839866133, -8.242629813568715],
              [-35.258747805244461, -8.242556283836509],
              [-35.258702902633857, -8.242494080335872],
              [-35.258686342137715, -8.24243768237192],
              [-35.25861980452509, -8.242240201269981],
              [-35.258575136568062, -8.242155489230116],
              [-35.258541720478753, -8.242070894285645],
              [-35.258536560740957, -8.242008920406564],
              [-35.258531225163708, -8.241963845841182],
              [-35.258514979231911, -8.241851135306039],
              [-35.25851002179099, -8.241760958617403],
              [-35.258534211747225, -8.241547148695965],
              [-35.258563158143517, -8.241451537781785],
              [-35.258563765532998, -8.241367024229811],
              [-35.258564697928023, -8.241243188948365],
              [-35.258565654487256, -8.241108057129022],
              [-35.258566408241698, -8.241001035563],
              [-35.258573512888425, -8.240798349864466],
              [-35.258574471794184, -8.24066321571657],
              [-35.258570090576811, -8.240483011649708],
              [-35.258542537051653, -8.24037587645657],
              [-35.25853244421144, -8.240212424702628],
              [-35.25846558796777, -8.240054445619023],
              [-35.25842651931579, -8.239981000667994],
              [-35.258393713399087, -8.239811892064955],
              [-35.258395077637424, -8.239620444825556],
              [-35.258395831543901, -8.239513423240949],
              [-35.258419648371635, -8.239361532767061],
              [-35.258420258218742, -8.239277016872986],
              [-35.258427304872157, -8.23908002595679],
              [-35.258439342359537, -8.238978725563232],
              [-35.258445636903957, -8.238888665980378],
              [-35.25845764506164, -8.238798571914884],
              [-35.258469624274561, -8.238702966330949],
              [-35.258504428875789, -8.238584907841101],
              [-35.258533465839633, -8.238489296462374],
              [-35.258539613984837, -8.238421742585887],
              [-35.258545967321282, -8.238326078439188],
              [-35.258552320148986, -8.238230324054019],
              [-35.258581179429335, -8.238151706876188],
              [-35.25861563704489, -8.238084264340184],
              [-35.25864409058908, -8.238061869989151],
              [-35.25868443570667, -8.237960771195601],
              [-35.2587132944413, -8.237882063780845],
              [-35.258736469016839, -8.237808990293296],
              [-35.258816871048651, -8.237651808711446],
              [-35.258816988113423, -8.237640509337414],
              [-35.258840047412271, -8.237578644974077],
              [-35.258880392472193, -8.237477548499054],
              [-35.258892198236602, -8.237415657257403],
              [-35.258893882470133, -8.237184800436411],
              [-35.258894231208757, -8.237134091933656],
              [-35.258873290231939, -8.236897487530372],
              [-35.25885707659485, -8.236790381011009],
              [-35.258857628227275, -8.236711562252736],
              [-35.258852114664144, -8.23668339162638],
              [-35.258853857326976, -8.236446842299657],
              [-35.25884962295148, -8.236244130209537],
              [-35.258850030520797, -8.236187817135505],
              [-35.258800723476263, -8.2359566156508],
              [-35.258772762945604, -8.235905793461132],
              [-35.25864424636103, -8.23567422561848],
              [-35.258571501448017, -8.235549900277801],
              [-35.258554941526022, -8.235493502207431],
              [-35.258470857295869, -8.235369152723653],
              [-35.258386483059901, -8.235289907161318],
              [-35.258347502769496, -8.235199558604512],
              [-35.25824091114967, -8.235058252254001],
              [-35.258167910518765, -8.234984637094923],
              [-35.258117409859452, -8.234911075795788],
              [-35.25809499000102, -8.234877215541133],
              [-35.258072863183052, -8.234815154265458],
              [-35.258061520959373, -8.234815037600644],
              [-35.258044521654561, -8.234809348525369],
              [-35.257886912373671, -8.234678996100307],
              [-35.257870005467652, -8.234673216253828],
              [-35.257813474645914, -8.234656087565979],
              [-35.257830905941219, -8.234594166992018],
              [-35.257662885504253, -8.234339860070317],
              [-35.257646037431783, -8.234328475636906],
              [-35.257595452116867, -8.234271907907987],
              [-35.257561722408553, -8.234243625220302],
              [-35.257455419242454, -8.234057214539289],
              [-35.257393787197309, -8.233972326719957],
              [-35.257348742390555, -8.233932718674268],
              [-35.25724746183046, -8.233847692843176],
              [-35.257141131872515, -8.233672490647058],
              [-35.257040979980694, -8.233418525825478],
              [-35.256977839203991, -8.23354777155159],
              [-35.256961017750463, -8.233525088194455],
              [-35.25688232834591, -8.233440205915635],
              [-35.256803553569853, -8.233372227069385],
              [-35.256758422968801, -8.233349432141141],
              [-35.256628368995067, -8.233326209927911],
              [-35.256605685122558, -8.233326066731971],
              [-35.256571837888245, -8.233308993072766],
              [-35.256442275057715, -8.233212556487414],
              [-35.256172568000586, -8.232929535516829],
              [-35.256088720006815, -8.232782676618051],
              [-35.255970557971175, -8.232680661719225],
              [-35.255897291589655, -8.232623950393936],
              [-35.255846796802423, -8.232567289252639],
              [-35.255735509962236, -8.232285186956393],
              [-35.255719271047788, -8.232189378899163],
              [-35.25546708241756, -8.231827530770204],
              [-35.255433787924119, -8.231731638013507],
              [-35.255354954876346, -8.231669263301004],
              [-35.25519842349135, -8.231392475335962],
              [-35.255143029690906, -8.231223219694293],
              [-35.255151589717734, -8.230812184660131],
              [-35.255169430260544, -8.230694041140204],
              [-35.25518718296037, -8.230592798798506],
              [-35.255233359117206, -8.230463465796525],
              [-35.255279420800186, -8.230362337494681],
              [-35.255382414478611, -8.230205298112649],
              [-35.255468765331301, -8.230008675869755],
              [-35.255503602537836, -8.229896219317268],
              [-35.255617851632785, -8.229756022013436],
              [-35.255715018410697, -8.229627036599027],
              [-35.255812065597226, -8.229509350590121],
              [-35.25584643790593, -8.229458811811741],
              [-35.255875238748636, -8.229385799404726],
              [-35.255926867076042, -8.229290332006528],
              [-35.256035431763607, -8.229155768532801],
              [-35.256166826691313, -8.228998842590878],
              [-35.256201775793492, -8.228858186007791],
              [-35.256208189032549, -8.22875691716672],
              [-35.256209147516806, -8.22862178506799],
              [-35.256215907306377, -8.228469717390752],
              [-35.256251817722905, -8.22819401663202],
              [-35.256247152833225, -8.22805882588241],
              [-35.256264670368246, -8.227980089827893],
              [-35.256294172615974, -8.227822560874422],
              [-35.256318044565845, -8.227648162879481],
              [-35.256318944238394, -8.227518633049584],
              [-35.256342730177657, -8.22736104597271],
              [-35.256389141304041, -8.227209299333628],
              [-35.256469687229917, -8.22702952019098],
              [-35.256515892738626, -8.226905881565848],
              [-35.256573584098746, -8.226759766057416],
              [-35.256591018410148, -8.226697933468342],
              [-35.256625909022219, -8.226562971745516],
              [-35.256633688551091, -8.22627016512061],
              [-35.256634994192048, -8.226084324457657],
              [-35.256635928446272, -8.225960488941807],
              [-35.256636945388536, -8.225819662048686],
              [-35.256626332638653, -8.225723910315455],
              [-35.256615628823482, -8.225628071163806],
              [-35.256599358699489, -8.22552665902734],
              [-35.256588107309206, -8.225526541740455],
              [-35.256370964173158, -8.225007315903483],
              [-35.256320906121793, -8.224883135028957],
              [-35.256287754551437, -8.224764734175485],
              [-35.256288891795641, -8.224612698070667],
              [-35.25630159809171, -8.224421277015315],
              [-35.256330312690459, -8.22436507790993],
              [-35.256264072751904, -8.224122581323494],
              [-35.256225236771876, -8.224009726365251],
              [-35.25616407487415, -8.223863010283679],
              [-35.256102761254581, -8.22372189928179],
              [-35.256063868829557, -8.223614648850676],
              [-35.256019466711457, -8.223496220840607],
              [-35.255963871286205, -8.223355077685303],
              [-35.255864130612636, -8.223044798344585],
              [-35.255820138967302, -8.222869966806845],
              [-35.255786902295647, -8.222768469292104],
              [-35.255787369689969, -8.222706551491298],
              [-35.255776257822575, -8.222667023133319],
              [-35.25572666785505, -8.222480926551846],
              [-35.255682615681074, -8.222311787482095],
              [-35.255587108622493, -8.222204222974383],
              [-35.25552603065713, -8.222040603178352],
              [-35.255498333727942, -8.22195597751027],
              [-35.255481887586569, -8.221871376630103],
              [-35.255426528307346, -8.221707817257112],
              [-35.255404659963467, -8.22159504735628],
              [-35.255394015710664, -8.221493603460997],
              [-35.255394889509816, -8.221375372417516],
              [-35.255395095227264, -8.221347259755321],
              [-35.255395271420333, -8.221330358079079],
              [-35.255420044177441, -8.221026339904377],
              [-35.255421353853549, -8.220840586964805],
              [-35.255444937078579, -8.220711112553872],
              [-35.255457005932222, -8.220615506437328],
              [-35.25547980141193, -8.220587447514562],
              [-35.255514606848116, -8.220469389374619],
              [-35.255554986281069, -8.220373895143407],
              [-35.255606205491254, -8.220334741272911],
              [-35.255697481078833, -8.22023950522872],
              [-35.255737596339884, -8.220177816161343],
              [-35.255783218909954, -8.22012730469468],
              [-35.255931398056539, -8.219987368834014],
              [-35.256027983427387, -8.219948504124877],
              [-35.256056436135559, -8.219926107935709],
              [-35.256073461948731, -8.219920588623623],
              [-35.256085065020081, -8.219886810075769],
              [-35.256159256190188, -8.219802785800436],
              [-35.256222631880242, -8.21965112462847],
              [-35.256229565941815, -8.219465249970655],
              [-35.256224755660853, -8.219352655130461],
              [-35.256219768712945, -8.219256874032261],
              [-35.256208750418566, -8.219217437697109],
              [-35.256170323900008, -8.21904826936183],
              [-35.256137319633638, -8.218907270012407],
              [-35.256115016156386, -8.218862112258247],
              [-35.256059216571551, -8.218749169498853],
              [-35.2560370599533, -8.218681505977617],
              [-35.255998344590445, -8.218557351392555],
              [-35.255931612488205, -8.218388068759335],
              [-35.255909338112275, -8.218331612069743],
              [-35.255898173045466, -8.218314681469247],
              [-35.255830770052079, -8.218235429250145],
              [-35.255752116919048, -8.218156240123628],
              [-35.255724014072193, -8.218127927614962],
              [-35.255617369981621, -8.217992131002655],
              [-35.255533176984486, -8.217895979638945],
              [-35.255482596941619, -8.21783941094354],
              [-35.255370327443948, -8.21770355784564],
              [-35.255336626300782, -8.217663975627541],
              [-35.255274675504907, -8.217618588929003],
              [-35.255218384283161, -8.21757886266049],
              [-35.255122876975697, -8.2174713856172],
              [-35.25502836310374, -8.217234290095702],
              [-35.254949737088808, -8.217143801808398],
              [-35.254893531651284, -8.217087174289953],
              [-35.254798170974922, -8.216957098780963],
              [-35.254736364951981, -8.21688920387224],
              [-35.254657535545078, -8.216826828158137],
              [-35.254585093174917, -8.216674389845529],
              [-35.254534775397453, -8.216584013451865],
              [-35.254473055192292, -8.216499217291906],
              [-35.254383352040122, -8.216374804394794],
              [-35.254366997775179, -8.216290295366575],
              [-35.254350236366669, -8.216262007056638],
              [-35.254311140246635, -8.216182956708808],
              [-35.254277879982006, -8.216092667440931],
              [-35.254227560050502, -8.21600229098077],
              [-35.254176953157874, -8.215956928358274],
              [-35.25414380364024, -8.215838526907003],
              [-35.254110220172798, -8.215787647402703],
              [-35.254021071031879, -8.21558441526849],
              [-35.253998709112885, -8.215544861865039],
              [-35.253959995411087, -8.215420797051147],
              [-35.253943320333654, -8.215375697756016],
              [-35.253881982226844, -8.215245794468984],
              [-35.253848426149801, -8.215183703931309],
              [-35.253837204673609, -8.215172380115146],
              [-35.253814698110283, -8.215155332470534],
              [-35.253725140240789, -8.21500841349925],
              [-35.253669631478353, -8.214850456178857],
              [-35.253652752831691, -8.214833469544152],
              [-35.25358963841191, -8.214951325194303],
              [-35.253555910905291, -8.214923041626578],
              [-35.253454340264703, -8.214866124140482],
              [-35.253347789056669, -8.214730418506669],
              [-35.253240857893729, -8.214639727225352],
              [-35.253077107283254, -8.214576921931094],
              [-35.253048801196066, -8.214576719497892],
              [-35.253031427575962, -8.21463294505201],
              [-35.252952743625144, -8.214548060696336],
              [-35.252868289411836, -8.214485625687461],
              [-35.252755527314683, -8.214423076135066],
              [-35.25272176779027, -8.214389100455486],
              [-35.252654194550544, -8.214343652060974],
              [-35.252592068175808, -8.214315166431881],
              [-35.252558163895422, -8.214303696532633],
              [-35.252501667853402, -8.214292172641203],
              [-35.252461961628626, -8.214297545701903],
              [-35.252399663649754, -8.214302867078421],
              [-35.25225206362682, -8.214352773589598],
              [-35.252189620321424, -8.214380510503265],
              [-35.252104408820024, -8.214425095117166],
              [-35.252036044290833, -8.214481066981891],
              [-35.251922495034442, -8.21451984496901],
              [-35.251854309186811, -8.214558912767357],
              [-35.251757579493436, -8.214620284365825],
              [-35.251660357370291, -8.214754963094098],
              [-35.251563512557667, -8.214844534825374],
              [-35.251477920655283, -8.214934136117742],
              [-35.251409413431766, -8.21501261372576],
              [-35.251358363572223, -8.215017959946989],
              [-35.251347115272345, -8.215017932659043],
              [-35.251341399129835, -8.215017874408737],
              [-35.251143861556486, -8.214932298847735],
              [-35.251093049444655, -8.214915138691918],
              [-35.250946324046481, -8.214846723166433],
              [-35.250912392844342, -8.214846552063436],
              [-35.250844437525423, -8.214846208054773],
              [-35.250781844941073, -8.214879640144417],
              [-35.250747766551839, -8.214901977181905],
              [-35.250701880761021, -8.214986203131343],
              [-35.250678849634419, -8.215036768128968],
              [-35.250650048060642, -8.215109780188154],
              [-35.250604888386626, -8.215081379800186],
              [-35.250508307879748, -8.215137146072923],
              [-35.250474286066044, -8.215136975446288],
              [-35.250445978076669, -8.215136863092464],
              [-35.250361057720085, -8.215136343200459],
              [-35.250321586796666, -8.215119209740253],
              [-35.250253979680195, -8.21506815913299],
              [-35.250203314766331, -8.215028490697485],
              [-35.250175210153159, -8.215000175329967],
              [-35.250152674454846, -8.214977525638558],
              [-35.250090930744442, -8.214904025095423],
              [-35.250046006087018, -8.214853025730207],
              [-35.250012600156317, -8.214785331903474],
              [-35.249978926670309, -8.214734452391893],
              [-35.249945226270299, -8.214694869504013],
              [-35.249906165045466, -8.21462142253039],
              [-35.249872609080583, -8.214559243552149],
              [-35.24979436508071, -8.214423739283584],
              [-35.249755213342958, -8.214350292786275],
              [-35.2497328518849, -8.214310739010346],
              [-35.249716032379624, -8.214288145238717],
              [-35.249585778460123, -8.214293031712447],
              [-35.249475010179026, -8.214294102313668],
              [-35.249311171761313, -8.214102675064868],
              [-35.24919658124491, -8.213957069995853],
              [-35.249156438170765, -8.213900983227916],
              [-35.249133476360377, -8.213867397999239],
              [-35.24910495494256, -8.213845050091388],
              [-35.249059248666036, -8.213800383449042],
              [-35.248967654524272, -8.213694060148116],
              [-35.248882176796116, -8.213627108435452],
              [-35.248790580407402, -8.213520782769047],
              [-35.248715981336296, -8.213419966347967],
              [-35.248612919480649, -8.213291109405281],
              [-35.248520856607087, -8.213134079304558],
              [-35.248389303827096, -8.212988658883198],
              [-35.248343663141583, -8.212955197992985],
              [-35.248309297202226, -8.212910377603031],
              [-35.24823488411203, -8.212826463076249],
              [-35.248080432979442, -8.212658663068762],
              [-35.248000550937704, -8.212585987593073],
              [-35.247949097271174, -8.21253565833538],
              [-35.247783090811716, -8.212345415327588],
              [-35.247725299223077, -8.212216305114262],
              [-35.247628512914112, -8.21215502044396],
              [-35.247560096514412, -8.212104876097216],
              [-35.247520017967886, -8.212060177744981],
              [-35.247468500855852, -8.211998549932984],
              [-35.247428421823258, -8.211953761326169],
              [-35.24734816686906, -8.211847371778365],
              [-35.24730821048135, -8.211808276994645],
              [-35.247291090677983, -8.21179707378788],
              [-35.247238643615944, -8.211634127392836],
              [-35.247198313108093, -8.21156114054962],
              [-35.247163761766807, -8.211499417927991],
              [-35.247117591908363, -8.211404046530737],
              [-35.24708872416231, -8.211336597630392],
              [-35.24707698070911, -8.211297255026476],
              [-35.247024751028569, -8.211156814735039],
              [-35.247018845273487, -8.211123041504456],
              [-35.246995666147775, -8.211066859641186],
              [-35.246983329241885, -8.210954215715487],
              [-35.246977550616663, -8.21094304171887],
              [-35.246863647816113, -8.21087064373806],
              [-35.246829597301925, -8.210865319790988],
              [-35.246789982489048, -8.210871235479688],
              [-35.246733622546273, -8.210899932524194],
              [-35.246649085243718, -8.210951291941285],
              [-35.246626526621583, -8.210957112402184],
              [-35.2465644848821, -8.210991443121374],
              [-35.246468446519685, -8.211014757340145],
              [-35.246445922234344, -8.211026272132173],
              [-35.246412149554487, -8.21105466257718],
              [-35.246339075381641, -8.211111652493759],
              [-35.246299680481336, -8.211140162243405],
              [-35.246271750435774, -8.211191115399],
              [-35.246243944796753, -8.21124767214959],
              [-35.246221386146942, -8.211253492593782],
              [-35.246188236650809, -8.211343975636231],
              [-35.246121502329231, -8.211479746550211],
              [-35.246104880870909, -8.211524944368653],
              [-35.246105596291962, -8.211603847019953],
              [-35.246094568333966, -8.211643407015547],
              [-35.246072727415871, -8.211728132411343],
              [-35.246061853056958, -8.21177890013047],
              [-35.246056977807122, -8.211863530834036],
              [-35.246040605468885, -8.211936924572612],
              [-35.24600173979362, -8.212027349285647],
              [-35.245980211521868, -8.212151569072587],
              [-35.246003324780126, -8.212196454871693],
              [-35.246003791068944, -8.21224715929446],
              [-35.246004323205256, -8.21230915985849],
              [-35.246004821012825, -8.212365468395184],
              [-35.246000287660507, -8.212495199927504],
              [-35.24596229152111, -8.212675825269059],
              [-35.245956884115778, -8.212698450784307],
              [-35.245957227829983, -8.212743463672815],
              [-35.245924513008944, -8.21287904473243],
              [-35.245919483156236, -8.212952287238963],
              [-35.245914356435186, -8.213008717415372],
              [-35.245909044082715, -8.213048245514571],
              [-35.245898482422952, -8.213138512256142],
              [-35.245889072848641, -8.213352691711471],
              [-35.245889724786721, -8.213420295898709],
              [-35.245890506148314, -8.213510499323416],
              [-35.245891375757189, -8.21360069994115],
              [-35.24587059401798, -8.213809338589176],
              [-35.245871092373548, -8.213865737366415],
              [-35.245872056557502, -8.2139727503267],
              [-35.245873301966398, -8.214113658185383],
              [-35.245909379607546, -8.214350088787549],
              [-35.245932961734113, -8.214445678960592],
              [-35.245979881812225, -8.21462564744845],
              [-35.245979976889082, -8.214642550029284],
              [-35.245989118662912, -8.215025740307334],
              [-35.245961124877802, -8.215065395049248],
              [-35.24594469183571, -8.215127582886748],
              [-35.245927975234636, -8.215155878155672],
              [-35.245900885001703, -8.21529133760729],
              [-35.245896224725165, -8.215398472204507],
              [-35.245897533321553, -8.215550585964206],
              [-35.245903781261759, -8.215612556922144],
              [-35.245904030483793, -8.215640757464426],
              [-35.245905026844056, -8.215753464765788],
              [-35.245905212495352, -8.215770364526954],
              [-35.24589974605432, -8.215798596983706],
              [-35.245900398123652, -8.215866203475651],
              [-35.245901050183321, -8.215933807653469],
              [-35.245863119975866, -8.216125731511799],
              [-35.24586368147709, -8.216193338510084],
              [-35.245799342820071, -8.216594022703683],
              [-35.245783156143595, -8.21668431855659],
              [-35.245761097970757, -8.21674653781827],
              [-35.245755785145541, -8.216785975686538],
              [-35.245756813591079, -8.216904377346495],
              [-35.245729320479469, -8.217000430871249],
              [-35.245719035152803, -8.217107596887317],
              [-35.245714689559897, -8.217254140251169],
              [-35.245720844670636, -8.217316111726362],
              [-35.245733584786713, -8.217468161652242],
              [-35.245734331995017, -8.217552668413557],
              [-35.24571914411186, -8.217755671565667],
              [-35.24571982828342, -8.217828972412729],
              [-35.245708956243796, -8.217879740152066],
              [-35.245693268080863, -8.218026437101534],
              [-35.245694264570488, -8.218139144405571],
              [-35.245700729834624, -8.218223619232145],
              [-35.245701477080807, -8.218308125992097],
              [-35.245691101230001, -8.218415292518015],
              [-35.245629427432377, -8.218483427579802],
              [-35.245455191545183, -8.218636975236018],
              [-35.245370927075619, -8.218705326677366],
              [-35.245314631391629, -8.218745229741598],
              [-35.245264080613573, -8.218790705012081],
              [-35.245219184684963, -8.218841845550285],
              [-35.244961682524561, -8.219176449532865],
              [-35.244849149849784, -8.219267556410879],
              [-35.244792973809979, -8.219313063060619],
              [-35.244764950496041, -8.219347113694324],
              [-35.244725552478045, -8.219375625854964],
              [-35.244675033075666, -8.219426705207001],
              [-35.244528788212548, -8.219540595595161],
              [-35.244517633498809, -8.219557651236387],
              [-35.244456677195203, -8.219704688911877],
              [-35.244446051877702, -8.219783657247559],
              [-35.244437357886255, -8.220076739564423],
              [-35.244438073465282, -8.220155644586372],
              [-35.244427201777491, -8.220206502586304],
              [-35.244388582268783, -8.220325125688795],
              [-35.244382959432762, -8.220325157080767],
              [-35.24437820847281, -8.220432292266999],
              [-35.244317530474554, -8.220613134662893],
              [-35.244267448569865, -8.220709404586978],
              [-35.244239327243427, -8.220726464711385],
              [-35.244177345135085, -8.220772094001633],
              [-35.244098643961905, -8.220829115454404],
              [-35.243992854165995, -8.221038501895304],
              [-35.243959640342375, -8.22111768427532],
              [-35.243926086750875, -8.221168668902278],
              [-35.243825949018891, -8.221366724956269],
              [-35.243764681778728, -8.221491169034607],
              [-35.24372012747569, -8.22157050727651],
              [-35.243647426505483, -8.221678112045186],
              [-35.243610208537525, -8.221948938838334],
              [-35.243604615378807, -8.221954664599178],
              [-35.243571777194163, -8.222084551973516],
              [-35.243560902573449, -8.222135319762565],
              [-35.243561273856663, -8.222169123956728],
              [-35.24356224087623, -8.222276227270125],
              [-35.243562739241163, -8.222332626113396],
              [-35.24356317355474, -8.222377636235125],
              [-35.243621061899724, -8.222523739795088],
              [-35.243649771320626, -8.222562897528171],
              [-35.243723968885028, -8.222624308736579],
              [-35.243786857535056, -8.222691477601538],
              [-35.243844369709265, -8.222786785876124],
              [-35.24390728993351, -8.222859558828834],
              [-35.243981424030189, -8.22290967148526],
              [-35.244050340815221, -8.223016212547225],
              [-35.244175555818117, -8.223082762561001],
              [-35.244192585561294, -8.223093966293746],
              [-35.244346010325636, -8.22314345558382],
              [-35.244522306776709, -8.223226530750575],
              [-35.24455079910485, -8.223243182179354],
              [-35.244579289125497, -8.223259835932271],
              [-35.24468157635296, -8.223298492341677],
              [-35.24476714438876, -8.223365444057379],
              [-35.244790043582199, -8.22338782124107],
              [-35.244801664892613, -8.2234214723193],
              [-35.244813132531462, -8.223443913357173],
              [-35.244763297568632, -8.223568383960179],
              [-35.244741116503832, -8.223624909441821],
              [-35.244690750880324, -8.22368728689629],
              [-35.244651538203577, -8.223732698929659],
              [-35.24464026312296, -8.223744060738081],
              [-35.244487986539035, -8.223818576980197],
              [-35.244459899410785, -8.223841331517837],
              [-35.244397789786106, -8.223864364010554],
              [-35.244369702648456, -8.22388711854409],
              [-35.2443245569062, -8.223910058594367],
              [-35.244290908116632, -8.223944140703249],
              [-35.24425707364157, -8.22396132303745],
              [-35.244234545798427, -8.22397274768589],
              [-35.244033183349593, -8.224256241226895],
              [-35.244005472049075, -8.224329700715034],
              [-35.243983444992487, -8.224397521850614],
              [-35.243944417853733, -8.224459835944335],
              [-35.243922268177741, -8.224521965541895],
              [-35.243877745267632, -8.224606907991996],
              [-35.243839032727372, -8.224708628566045],
              [-35.243772575467894, -8.224878294766592],
              [-35.243722991142647, -8.225030873372704],
              [-35.243717461018285, -8.225047807384637],
              [-35.243689998879049, -8.225149465136832],
              [-35.243679840009399, -8.225279137983092],
              [-35.243680460621874, -8.22534114042679],
              [-35.243698110568182, -8.225414254046305],
              [-35.243755406083032, -8.225487058324624],
              [-35.24387034387, -8.225677765362304],
              [-35.243939134156058, -8.225761711669524],
              [-35.243978843307175, -8.225772698464381],
              [-35.24401852041278, -8.225777990896763],
              [-35.244070256703715, -8.225862122668749],
              [-35.244172695993718, -8.225911986779179],
              [-35.244269422316222, -8.225961973013927],
              [-35.244309502406352, -8.226006671384157],
              [-35.244389294518761, -8.226062446717879],
              [-35.244520290054083, -8.226140260567522],
              [-35.244628635581172, -8.226223897765227],
              [-35.244719800372785, -8.226285211442423],
              [-35.244754133183683, -8.22632433988238],
              [-35.244960637354644, -8.226615768307605],
              [-35.244966882962089, -8.226677649014622],
              [-35.244962289070578, -8.226796082103855],
              [-35.244962474814024, -8.226812981866296],
              [-35.244918046700853, -8.226914736775356],
              [-35.244896017466836, -8.22698255796265],
              [-35.244890424280356, -8.226988283754114],
              [-35.244845312158688, -8.22701682804777],
              [-35.244805883785432, -8.227039643727275],
              [-35.244755269040532, -8.227073913298781],
              [-35.244738366319865, -8.227085306581523],
              [-35.244721465920698, -8.227096699850849],
              [-35.244670694629448, -8.227119669150502],
              [-35.244636705753003, -8.227125553617897],
              [-35.244614146219618, -8.22713137421559],
              [-35.244546631020185, -8.227177034720647],
              [-35.244473429209464, -8.227228333599784],
              [-35.244417193714582, -8.227279535355635],
              [-35.244394885822658, -8.227313554172291],
              [-35.244338772435327, -8.227370359533301],
              [-35.244289000247988, -8.227506038460243],
              [-35.244278560830594, -8.227601906598025],
              [-35.244353541003989, -8.227753428299371],
              [-35.244364974950791, -8.22777026752939],
              [-35.244455981368503, -8.227803382432674],
              [-35.244501283940878, -8.227808643326455],
              [-35.244580731461063, -8.227819227450873],
              [-35.244688361985759, -8.227824047449102],
              [-35.244835669316927, -8.227834071785107],
              [-35.244994193317623, -8.227821525966821],
              [-35.245078982799456, -8.227798273905044],
              [-35.245124036460844, -8.227775424426193],
              [-35.245180149239637, -8.227718528731534],
              [-35.245208207299093, -8.227690172272883],
              [-35.245258348382208, -8.227588297468179],
              [-35.245274412800484, -8.227492395609948],
              [-35.24530753132354, -8.227396310493642],
              [-35.245340305238152, -8.22725512457821],
              [-35.245394768792529, -8.22701800536872],
              [-35.245409987468321, -8.226820603974156],
              [-35.245431796945063, -8.226730276613807],
              [-35.245493190717859, -8.226628338923012],
              [-35.245593481829602, -8.226441490085652],
              [-35.245615508593907, -8.2263736665975],
              [-35.245671340680801, -8.226283058812875],
              [-35.245704708482002, -8.226215171928214],
              [-35.245732734331405, -8.226181118804517],
              [-35.245760726301967, -8.226141463895525],
              [-35.245810810953557, -8.226045283949144],
              [-35.245973773542381, -8.225903006967703],
              [-35.246028292911596, -8.225660192965147],
              [-35.246050385552522, -8.225603667929677],
              [-35.246083191286935, -8.22546817640236],
              [-35.246155021465604, -8.225270368342622],
              [-35.246182079291437, -8.225129214426754],
              [-35.246226415893432, -8.225027372090409],
              [-35.246309677561925, -8.224829500131023],
              [-35.246309523339498, -8.224818202178588],
              [-35.246353950979739, -8.224716449577965],
              [-35.246455802937582, -8.22470991488145],
              [-35.246483826314986, -8.224675861761666],
              [-35.24668124408737, -8.224578497062204],
              [-35.246799593037437, -8.22452116307373],
              [-35.246912593116512, -8.224480759750293],
              [-35.247059464248821, -8.224445773581936],
              [-35.247121573676559, -8.224422738381172],
              [-35.24722892155485, -8.224393665401848],
              [-35.247313744513619, -8.224376107427945],
              [-35.247534001710136, -8.22430663375634],
              [-35.247692707482862, -8.224310989329592],
              [-35.247766284662049, -8.224310397261238],
              [-35.247862541478327, -8.224309678304859],
              [-35.248038525591653, -8.224353252653684],
              [-35.248072516436864, -8.224347367961576],
              [-35.248157400161091, -8.224341018040292],
              [-35.248417586360958, -8.224305126679567],
              [-35.248474038839376, -8.224276518671305],
              [-35.248659928326731, -8.22416222436695],
              [-35.248699201839074, -8.224128108194243],
              [-35.24891701341771, -8.224043100423589],
              [-35.249187017729632, -8.223914687763079],
              [-35.249454627520791, -8.223860041735657],
              [-35.249505644118685, -8.223848998808641],
              [-35.249619282025932, -8.223793317196497],
              [-35.249723068661197, -8.223534863207075],
              [-35.249786505730341, -8.22337750509892],
              [-35.249895976354594, -8.223113415023832],
              [-35.249976525748089, -8.222933726822955],
              [-35.25000614345165, -8.222747997559731],
              [-35.250030135982314, -8.222562299792592],
              [-35.250042438529661, -8.222427191543687],
              [-35.25006602405081, -8.222297807200627],
              [-35.250095061268588, -8.222202286387814],
              [-35.250164474138273, -8.221994278228607],
              [-35.250204965409566, -8.221870584004344],
              [-35.250228055730801, -8.221814414324584],
              [-35.250251087079612, -8.221763849247452],
              [-35.250285428732944, -8.221707799375467],
              [-35.25034848530818, -8.221595455777566],
              [-35.250399825603068, -8.221545095490086],
              [-35.250456702891043, -8.221511604941524],
              [-35.250524745171965, -8.221495045172995],
              [-35.250671880251254, -8.221507146792963],
              [-35.250700189198241, -8.22150734924816],
              [-35.250857935272904, -8.221615200796698],
              [-35.250931139777258, -8.221677518410162],
              [-35.250976123975576, -8.221722822583613],
              [-35.251049034753763, -8.221813343640678],
              [-35.25119526756297, -8.221954882777283],
              [-35.251296136684573, -8.222096224720817],
              [-35.251363304552449, -8.222197986386274],
              [-35.251402401080192, -8.222277036997873],
              [-35.251469480411671, -8.222395609614276],
              [-35.251479775924196, -8.222547762641003],
              [-35.251569916172421, -8.222604563719099],
              [-35.251607611486257, -8.22286945611291],
              [-35.25164047026184, -8.223032961959115],
              [-35.251666591056377, -8.223337149037336],
              [-35.251671665302176, -8.22341602692066],
              [-35.251658197466668, -8.223714470573022],
              [-35.251622224366713, -8.223995774008991],
              [-35.251592984428093, -8.224119497816714],
              [-35.251580477073496, -8.224282804316225],
              [-35.251579925681504, -8.224361625794099],
              [-35.251590392449337, -8.22447988380919],
              [-35.251628494992993, -8.224688462771226],
              [-35.251627184529966, -8.224874306385564],
              [-35.251632258870323, -8.224953184260652],
              [-35.251631678404365, -8.225043302357713],
              [-35.251647565566529, -8.225189822039697],
              [-35.251663631371869, -8.22531943767537],
              [-35.251662756759771, -8.225437666676688],
              [-35.251622495786428, -8.225521861439187],
              [-35.251616172278766, -8.225623220253995],
              [-35.251609758195592, -8.225724579576763],
              [-35.251597397460941, -8.225865292334122],
              [-35.251613171716834, -8.226039921898918],
              [-35.251681861103322, -8.226716079620731],
              [-35.251681248607227, -8.226800505694534],
              [-35.251663874727058, -8.22685673366772],
              [-35.251663203802856, -8.226946852263545],
              [-35.251634140186596, -8.227053672084814],
              [-35.251611140549002, -8.227109839038848],
              [-35.251582305442092, -8.22717733979132],
              [-35.251479514019238, -8.227306265697539],
              [-35.251433772322002, -8.227367983907369],
              [-35.251319666027356, -8.227485584201618],
              [-35.25125115727927, -8.227564062327803],
              [-35.251205238722768, -8.227642594321194],
              [-35.25119346373522, -8.227710179937588],
              [-35.251176028520462, -8.227772012527268],
              [-35.25111851122557, -8.227901226107567],
              [-35.251078250014707, -8.22798542090408],
              [-35.251059651416597, -8.228210586703877],
              [-35.251025133811609, -8.228283633333875],
              [-35.250950737126104, -8.228395857994894],
              [-35.250823306971135, -8.228789303667979],
              [-35.250759495042793, -8.229008577178453],
              [-35.250758882975681, -8.229093091221094],
              [-35.250775500313985, -8.229143887714576],
              [-35.250869754169678, -8.229414699037353],
              [-35.250931474196619, -8.229499497695294],
              [-35.251004445288736, -8.229584323510338],
              [-35.251251757056842, -8.229839092824648],
              [-35.251313156367537, -8.229963301216358],
              [-35.25132945296874, -8.230053507489249],
              [-35.251323276546074, -8.230132358187015],
              [-35.25130537588673, -8.230256108750273],
              [-35.251161973090468, -8.230520028678722],
              [-35.251024979207322, -8.230665686235106],
              [-35.250985039247055, -8.23071056149044],
              [-35.250916818760054, -8.230743935542927],
              [-35.250808775596958, -8.230810883082926],
              [-35.250672893481742, -8.230815802878938],
              [-35.250468031807586, -8.230961118816479],
              [-35.250416692968727, -8.231011481716413],
              [-35.250159615774713, -8.231325479798514],
              [-35.250091047354303, -8.231409562553155],
              [-35.250062534603053, -8.23143756090643],
              [-35.250010674147127, -8.231572437351783],
              [-35.249930296036823, -8.231718411436475],
              [-35.249838345277581, -8.231903675646617],
              [-35.249865256545888, -8.232106625286008],
              [-35.249892809028239, -8.23221375957328],
              [-35.249914704069688, -8.232315231049093],
              [-35.249936979006847, -8.232371688053554],
              [-35.249964761904224, -8.232439412967485],
              [-35.250009049324717, -8.232586044296507],
              [-35.250064644531342, -8.23272709819965],
              [-35.250109539652577, -8.232772400331676],
              [-35.250148926832267, -8.23280643686275],
              [-35.250300415405874, -8.233009951436721],
              [-35.250503500753602, -8.233112487217182],
              [-35.250576676161877, -8.23316920021745],
              [-35.250621603063088, -8.233220108666575],
              [-35.250660963056212, -8.233265351497955],
              [-35.250694489322321, -8.233321835569956],
              [-35.250722534877241, -8.233355845369651],
              [-35.250970113729686, -8.233576808978817],
              [-35.251144774168644, -8.233690348970709],
              [-35.25135328733149, -8.233837956260155],
              [-35.251611998327384, -8.234070243550692],
              [-35.251611971612093, -8.23408154247119],
              [-35.251673457661532, -8.234188849376519],
              [-35.251886373021982, -8.234505362384933],
              [-35.25188048539691, -8.234539111241517],
              [-35.251822674493269, -8.234696526218308],
              [-35.251861652883868, -8.234786785585159],
              [-35.251895125554057, -8.234865774755356],
              [-35.251990459659915, -8.23499024586217],
              [-35.251971397184789, -8.235277329493204],
              [-35.252049533231869, -8.235441034343912],
              [-35.252066119502331, -8.235486224255199],
              [-35.25220000136175, -8.235768474207012],
              [-35.252228164512154, -8.235791184393166],
              [-35.252227961660154, -8.235819385009792],
              [-35.252227611515487, -8.235870003499404],
              [-35.252227146429838, -8.23593192139923],
              [-35.252238224669846, -8.235965843442335],
              [-35.252461145523895, -8.236462623963737],
              [-35.252476921831295, -8.236637253194765],
              [-35.252481877034562, -8.236727340125949],
              [-35.252548730777789, -8.236885413576225],
              [-35.252603806765755, -8.237094077079705],
              [-35.25262567407929, -8.237206847050741],
              [-35.252675850939681, -8.237319728790034],
              [-35.252850080891697, -8.237500879636341],
              [-35.252900691083006, -8.237546241683937],
              [-35.252984600755759, -8.237687496662113],
              [-35.253039968196148, -8.237867958911732],
              [-35.253118276955078, -8.237997946527436],
              [-35.253332242556922, -8.238162424360159],
              [-35.25334338167216, -8.238190651487495],
              [-35.253382710841471, -8.238230292060907],
              [-35.253433149680049, -8.238309368429016],
              [-35.253517204094649, -8.238428115118941],
              [-35.253556386625199, -8.238490261440022],
              [-35.253617847485486, -8.238608864289958],
              [-35.253662513932809, -8.238693577288325],
              [-35.253684875438601, -8.238733132653966],
              [-35.253808664673421, -8.238835116264555],
              [-35.253853651567347, -8.238880417155292],
              [-35.254084412450126, -8.239078784179755],
              [-35.254123274806325, -8.239180342462676],
              [-35.254179137188828, -8.239287677863441],
              [-35.254212696349221, -8.239349765429479],
              [-35.254290947006908, -8.239485357209162],
              [-35.254347014446957, -8.23956449197536],
              [-35.254420047880863, -8.239643802092136],
              [-35.254459203597492, -8.239717156908318],
              [-35.254763550621362, -8.239915924181417],
              [-35.254791597051685, -8.23994993115347],
              [-35.254836583690128, -8.239995143942243],
              [-35.254966090605691, -8.24009727534621],
              [-35.255061309495531, -8.240232951943989],
              [-35.255173645672436, -8.240363108483416],
              [-35.255308572824376, -8.240493320728591],
              [-35.255336245518208, -8.240589244889984],
              [-35.255561784888158, -8.240714338150324],
              [-35.255555753429491, -8.240770592723313],
              [-35.255531618256278, -8.240978798325777],
              [-35.255518827834408, -8.241187120606801],
              [-35.255506120476099, -8.24137853943585],
              [-35.25549950521188, -8.241508008859542],
              [-35.25545863410462, -8.241676720216086],
              [-35.25541103579387, -8.242003101046627],
              [-35.255399229517586, -8.242064992355555],
              [-35.255391104725504, -8.242408417430665],
              [-35.255390061840217, -8.242560452992857],
              [-35.255422774184964, -8.242729562626513],
              [-35.255417151075761, -8.242729594258085],
              [-35.25537552494206, -8.243005234870516],
              [-35.255363166679778, -8.243145945104745],
              [-35.25529184116499, -8.243624220735454],
              [-35.255267733130054, -8.243821217729749],
              [-35.255238118577168, -8.244006856724654],
              [-35.255167313212723, -8.244400707087065],
              [-35.255143500557779, -8.244569503021189],
              [-35.255119424285688, -8.244772104103287],
              [-35.255095435686385, -8.244957801707285],
              [-35.255089170025997, -8.24503665509401],
              [-35.255076404023875, -8.245233678544261],
              [-35.255052532548795, -8.245408076756277],
              [-35.255045483148486, -8.245605160602928],
              [-35.255038377265464, -8.245807846702348],
              [-35.255037590617704, -8.245909174187736],
              [-35.255030135838105, -8.246162568900276],
              [-35.255029989368801, -8.24618507696117],
              [-35.255029293839655, -8.246286494174806],
              [-35.255026941580788, -8.246607379570893],
              [-35.255026390218347, -8.246686288735599],
              [-35.255025779553023, -8.246770714554474],
              [-35.255024356290356, -8.246967854668355],
              [-35.255022908345552, -8.24717620341692],
              [-35.254886666456478, -8.247214841547427],
              [-35.254868103905686, -8.247445613371477],
              [-35.254867493747824, -8.247530127121667],
              [-35.254866242981493, -8.247693372373963],
              [-35.254864707407968, -8.247918624619928],
              [-35.254869520362114, -8.248031309578964],
              [-35.254880193548075, -8.248121454320977],
              [-35.254901481671318, -8.248307440931535],
              [-35.254946323580342, -8.248375249417784],
              [-35.254968805404658, -8.248403502788568],
              [-35.255053326772206, -8.248460242725114],
              [-35.255177149923902, -8.248567919277832],
              [-35.255250534441579, -8.24859652011944],
              [-35.255346220306961, -8.24867027828183],
              [-35.255425235659203, -8.248715750368522],
              [-35.255520921068538, -8.248789418233381],
              [-35.255594161564325, -8.248840527030936],
              [-35.255656115901878, -8.248885914621434],
              [-35.255791020324033, -8.249027422398838],
              [-35.255864319681386, -8.249072926549271],
              [-35.255954320940766, -8.249152230515126],
              [-35.255993771064432, -8.249180571047162],
              [-35.256021876718876, -8.249208882899959],
              [-35.256128355816863, -8.249361488327885],
              [-35.256206929801195, -8.24945766928807],
              [-35.256240549303278, -8.249514151764194],
              [-35.256319560074736, -8.249542810897571],
              [-35.256330611854061, -8.249587853323272],
              [-35.25633035077275, -8.249621658421841],
              [-35.256347722607508, -8.249709236209494],
              [-35.256360422158224, -8.24974052715525],
              [-35.256367118405535, -8.24978550155295],
              [-35.256373571185385, -8.24981909066604],
              [-35.256380144534553, -8.249858371270285],
              [-35.256392342903297, -8.249897529949582],
              [-35.256398918580309, -8.249936810539443],
              [-35.256399989822782, -8.249981906858949],
              [-35.256437290350036, -8.250127490367843],
              [-35.256421030088333, -8.250155964137226],
              [-35.256370871359991, -8.250190955021218],
              [-35.256309496195222, -8.250231703562424],
              [-35.256281437531882, -8.250243611537993],
              [-35.256253376543732, -8.250255519525105],
              [-35.256168430544122, -8.2502517496587],
              [-35.256105859923188, -8.250241888545936],
              [-35.256031916868203, -8.250226667709294],
              [-35.255975061857036, -8.250216684143258],
              [-35.255929299870992, -8.250195158883859],
              [-35.255844140899285, -8.250185785898887],
              [-35.255793030976889, -8.250181283955097],
              [-35.255758857233459, -8.250170811665647],
              [-35.255718842651888, -8.250154765703243],
              [-35.255633438931383, -8.250134187870685],
              [-35.255559740653453, -8.250130174049939],
              [-35.255514471542085, -8.25013124111295],
              [-35.255446643554642, -8.250138402711112],
              [-35.255350479285134, -8.25014048055399],
              [-35.255288031556987, -8.250136313099881],
              [-35.255239067487096, -8.250221823292696],
              [-35.255087396315162, -8.250275915180195],
              [-35.255009391639582, -8.250328416879654],
              [-35.254959599828076, -8.250380215989393],
              [-35.254898682650513, -8.250437864767786],
              [-35.254854885794565, -8.250506442870364],
              [-35.254839483523021, -8.250574409929731],
              [-35.254817629873472, -8.250608609644106],
              [-35.254807852617539, -8.250676452485438],
              [-35.254781445012021, -8.250755870476381],
              [-35.254759959491444, -8.250807061348674],
              [-35.25474385373478, -8.250846830643649],
              [-35.254716620796778, -8.250892539861246],
              [-35.254700760290085, -8.250943606515412],
              [-35.254701585606469, -8.250977315290672],
              [-35.254674599711592, -8.251034229302139],
              [-35.254585989420505, -8.251120777132028],
              [-35.254569638386279, -8.251149249067636],
              [-35.254531187113244, -8.25120080616114],
              [-35.254509456282015, -8.251240697359934],
              [-35.254398624575124, -8.251344543963382],
              [-35.254337739465548, -8.251407884711881],
              [-35.254310261203216, -8.25144229656175],
              [-35.254288407984518, -8.251476586509929],
              [-35.254245559112242, -8.251584567187612],
              [-35.254195857660399, -8.25163636807247],
              [-35.254163156027865, -8.251693404489645],
              [-35.254114804437599, -8.251807112944139],
              [-35.254109424617454, -8.251818529893423],
              [-35.254064522379949, -8.251836409845893],
              [-35.253975423623274, -8.25190036287972],
              [-35.253914168437504, -8.251946802688686],
              [-35.253869356757441, -8.251964682115629],
              [-35.253767966758254, -8.251989474524022],
              [-35.253604466301333, -8.252021308187395],
              [-35.253514047056733, -8.252028957636947],
              [-35.253502922132604, -8.25203489521196],
              [-35.253422647911435, -8.251991598069836],
              [-35.253393763253051, -8.251969706854094],
              [-35.253386575484996, -8.251902320397033],
              [-35.253385962674145, -8.251874122093561],
              [-35.253379509554925, -8.251840444951256],
              [-35.253377488673053, -8.251756033864167],
              [-35.253386409151439, -8.251648875793158],
              [-35.253395481437536, -8.251552925375732],
              [-35.253436892027139, -8.251382947379707],
              [-35.253453120790844, -8.251348869587442],
              [-35.253485611395774, -8.2512861399003],
              [-35.253601330966475, -8.251148372061511],
              [-35.253639167771297, -8.251068709292426],
              [-35.25369363446768, -8.250977383483981],
              [-35.253721325716924, -8.250948574724356],
              [-35.253776372914032, -8.250879752898369],
              [-35.253818763730045, -8.250754871833207],
              [-35.253855407131965, -8.250624509098252],
              [-35.253915221768985, -8.250516159957218],
              [-35.253924783907081, -8.250442711742808],
              [-35.253938133676513, -8.2502847318584],
              [-35.253943268776084, -8.250262107788169],
              [-35.253860116564312, -8.250094998785359],
              [-35.253779355175659, -8.250029199421443],
              [-35.253705044560867, -8.249997077187853],
              [-35.253641736486713, -8.249953416123313],
              [-35.253589066210751, -8.249881403210754],
              [-35.253526096274072, -8.249849036652224],
              [-35.253450874972238, -8.249768112491925],
              [-35.253439528135743, -8.249671372204725],
              [-35.253431381375755, -8.249594589771609],
              [-35.253426248533515, -8.249521314446785],
              [-35.253404206319978, -8.249442351807758],
              [-35.253376565120853, -8.249352119592826],
              [-35.253360148978786, -8.249273215543683],
              [-35.253332390164132, -8.249194192489519],
              [-35.253282008197985, -8.249109511929088],
              [-35.253242821371821, -8.249047275655569],
              [-35.253198155561932, -8.248962562926543],
              [-35.253125061778491, -8.248888947841078],
              [-35.25300149846003, -8.248747555610715],
              [-35.252962575931875, -8.248651602024671],
              [-35.252850178399051, -8.248527049937147],
              [-35.252760031168727, -8.248470250844875],
              [-35.252703641994643, -8.248430525910354],
              [-35.252613521903932, -8.248362520420445],
              [-35.252489787572998, -8.248254842373324],
              [-35.252428385382025, -8.248130544872561],
              [-35.252383685811324, -8.248040227908032],
              [-35.252372868897282, -8.247972588718017],
              [-35.252345983069489, -8.247775336673396],
              [-35.252285131576144, -8.247572307989],
              [-35.252280116770763, -8.247487825713852],
              [-35.252280898361604, -8.247369504823013],
              [-35.252322382065913, -8.2471163696601],
              [-35.252363804591553, -8.246868839106446],
              [-35.25238703885713, -8.246790073046283],
              [-35.252399077633306, -8.246688772392455],
              [-35.252451233816799, -8.246525782221941],
              [-35.252486066998003, -8.246396424723555],
              [-35.252503966640312, -8.246272674102313],
              [-35.252498834187968, -8.246199401010729],
              [-35.252471279061858, -8.246092267420748],
              [-35.252415212973496, -8.246013132549887],
              [-35.252309056626054, -8.24582111338896],
              [-35.252241503726296, -8.245764369819133],
              [-35.252162550422121, -8.24571329223868],
              [-35.252078088349087, -8.245650858900573],
              [-35.25192552143092, -8.245610588906699],
              [-35.251761350914144, -8.245604099844037],
              [-35.25163131922988, -8.245569580421982],
              [-35.251501290312028, -8.24555196165023],
              [-35.251337205280208, -8.24552856889512],
              [-35.251133645960628, -8.245488045845006],
              [-35.250986299089817, -8.245504147426566],
              [-35.250878455372593, -8.245542895385084],
              [-35.250759416796917, -8.245559201198114],
              [-35.250663147895537, -8.245558658881285],
              [-35.250549825350937, -8.245574930224361],
              [-35.25049883053223, -8.245574675104713],
              [-35.250436908892517, -8.245534890499787],
              [-35.25028396309407, -8.245539726809533],
              [-35.250181745251403, -8.245578533257095],
              [-35.250085009474752, -8.245639999017316],
              [-35.249994134169889, -8.245678922236719],
              [-35.249874950477825, -8.245717643379257],
              [-35.249806470910983, -8.245801726204698],
              [-35.249675419287037, -8.245908033557242],
              [-35.24964684684803, -8.245941636749022],
              [-35.249601162351595, -8.245997753064398],
              [-35.249583931997357, -8.246031382834483],
              [-35.249458334590798, -8.246171465636932],
              [-35.249395248504094, -8.246295016461902],
              [-35.249406507072152, -8.24650492132672],
              [-35.249456444348965, -8.246639317044101],
              [-35.249643321900727, -8.247066973781939],
              [-35.249767716199891, -8.247323514126368],
              [-35.249868354341388, -8.247727159802158],
              [-35.249907359574209, -8.248094453007345],
              [-35.2499345807745, -8.248559701918422],
              [-35.249912904032186, -8.249074305544795],
              [-35.249877779881913, -8.249392934843906],
              [-35.249806668598943, -8.249605562840896],
              [-35.249808720802982, -8.249695666175811],
              [-35.249810282810664, -8.24976317702361],
              [-35.249811967618491, -8.249836379376912],
              [-35.249814967541134, -8.249965887742608],
              [-35.249816071568503, -8.250016498117445],
              [-35.249818214386963, -8.250106600940732],
              [-35.249848695339885, -8.250201697665037],
              [-35.249854564990393, -8.250212782991078],
              [-35.249872356122488, -8.250246216115254],
              [-35.249907602186497, -8.250301695229236],
              [-35.249943796499721, -8.250396669604166],
              [-35.249978797996597, -8.250440943859964],
              [-35.250016096420758, -8.250586528585577],
              [-35.250018853669388, -8.250704737201222],
              [-35.250005135432161, -8.250845908950556],
              [-35.24995201696423, -8.250999142245551],
              [-35.249940717962986, -8.251247225741988],
              [-35.249954813651584, -8.251365192497403],
              [-35.249957480378036, -8.251483403935399],
              [-35.249958305818765, -8.251517203066751],
              [-35.249956417552752, -8.251680542633977],
              [-35.249959420008445, -8.251810048644371],
              [-35.249950253986924, -8.251906002043123],
              [-35.249897593475758, -8.252076133521633],
              [-35.249878404236043, -8.252228634792054],
              [-35.249849526974266, -8.252448888270351],
              [-35.249808329979324, -8.25262446938671],
              [-35.249781587866146, -8.252692590441534],
              [-35.249755211376076, -8.252777702727334],
              [-35.249701480684699, -8.252902827932449],
              [-35.249652637867285, -8.252994031665006],
              [-35.249614920068467, -8.253079297907343],
              [-35.249532760385684, -8.253199432072609],
              [-35.249487980469731, -8.253223003113632],
              [-35.249444395235642, -8.253297184020694],
              [-35.249303602643558, -8.253429848725382],
              [-35.249022454997778, -8.253692917232529],
              [-35.248814959322104, -8.253903689693697],
              [-35.248676790212976, -8.254628462419948],
              [-35.248419751790649, -8.255352007728579],
              [-35.248140381450561, -8.256041509111872],
              [-35.247831639394043, -8.256832140316632],
              [-35.247541930352007, -8.257442703183395],
              [-35.24732822756517, -8.257823346314327],
              [-35.247156798177109, -8.257962330915335],
              [-35.246849590576574, -8.258094303042331],
              [-35.246684115006488, -8.258210836980451],
              [-35.246620361878485, -8.25834532729167],
              [-35.246611935499679, -8.258604152435202],
              [-35.246802715542564, -8.258758096786648],
              [-35.247094689544539, -8.258980616872188],
              [-35.247223429435152, -8.259122613210943],
              [-35.247264230516002, -8.259534006705127],
              [-35.24722799141886, -8.259736225576976],
              [-35.247015672722505, -8.259993030253058],
              [-35.246469630815419, -8.2602182681663],
              [-35.245999539285663, -8.260236000818729],
              [-35.245497305981033, -8.260073322189045],
              [-35.244792418186499, -8.259807200425106],
              [-35.244325894184037, -8.259492830473112],
              [-35.244100735108766, -8.25937234232911],
              [-35.243750810048112, -8.259267466753322],
              [-35.243381821619444, -8.259353775712748],
              [-35.242685243648218, -8.259369249809987],
              [-35.24234642408932, -8.259286998562855],
              [-35.241927561032547, -8.259265845379868],
              [-35.241304277486584, -8.259310191281118],
              [-35.241082764968738, -8.259369821185157],
              [-35.241079547325498, -8.259668118788897],
              [-35.241227103257266, -8.260153755484922],
              [-35.24182323899462, -8.261037928314749],
              [-35.242390605429804, -8.261454686523173],
              [-35.24296563622449, -8.261685656143358],
              [-35.243192678554053, -8.262155210495491],
              [-35.243298902840316, -8.262792386080179],
              [-35.243377319428959, -8.263389855788533],
              [-35.24355301412443, -8.263892596687866],
              [-35.243866172486193, -8.264250490635494],
              [-35.244309663656615, -8.264598341615859],
              [-35.244966833561797, -8.265089160337936],
              [-35.245427354890957, -8.265431582715483],
              [-35.245662270652964, -8.265704135049932],
              [-35.246083761288027, -8.265995524847368],
              [-35.246409914919184, -8.266195798249523],
              [-35.246838918990626, -8.266323996037336],
              [-35.247183545403665, -8.266389488727139],
              [-35.247964245159217, -8.266453702718831],
              [-35.248426716678829, -8.266627266507204],
              [-35.24881579833643, -8.266771867488789],
              [-35.249047154024524, -8.26684739454315],
              [-35.249541733041688, -8.266666640815931],
              [-35.249724866712505, -8.26655958521282],
              [-35.249879593325787, -8.266434796022658],
              [-35.250023270112784, -8.266186328619757],
              [-35.250053307103393, -8.266010627239263],
              [-35.25003145023495, -8.265804396747507],
              [-35.249978059958252, -8.265525946414904],
              [-35.249914795378395, -8.265330252671401],
              [-35.249851471953917, -8.265124165196415],
              [-35.249736575568321, -8.264959675562631],
              [-35.249612488915453, -8.264854801967982],
              [-35.24931464540002, -8.264654735360065],
              [-35.249006195152617, -8.264375819465849],
              [-35.248580198976761, -8.26397744025383],
              [-35.248206586816003, -8.263455747320515],
              [-35.24795337610238, -8.262788941042871],
              [-35.247845994930984, -8.262253098629037],
              [-35.24782089776695, -8.261442290882442],
              [-35.247814624551744, -8.260462712335451],
              [-35.247911756712305, -8.259861366476549],
              [-35.24837327907472, -8.259083616317749],
              [-35.248734692043008, -8.258653874770722],
              [-35.249086678672604, -8.258049468038307],
              [-35.249589175960367, -8.257666206307519],
              [-35.24972168130045, -8.257688059072585],
              [-35.249875978308609, -8.257776584635364],
              [-35.25000533891351, -8.257980130790981],
              [-35.250044823263437, -8.258158694568083],
              [-35.250021026225902, -8.258490817393966],
              [-35.249984673411305, -8.25884859328996],
              [-35.24989689252336, -8.259193911074007],
              [-35.249847490294499, -8.259539013092054],
              [-35.249861604161808, -8.259755952063388],
              [-35.249908269531026, -8.259904739511727],
              [-35.25002235015225, -8.259941606340972],
              [-35.250153029407208, -8.259961210657897],
              [-35.250255034001604, -8.259964522054968],
              [-35.250323231507394, -8.259974261828022],
              [-35.250442325107493, -8.259982903159855],
              [-35.250584345301341, -8.260002260743716],
              [-35.250801013399652, -8.260064944928668],
              [-35.250920474568126, -8.260090484750556],
              [-35.25109486379813, -8.26004150881429],
              [-35.251289387023469, -8.259879434237805],
              [-35.251388635904206, -8.259764627144421],
              [-35.251525140800339, -8.259547474997268],
              [-35.251566182739474, -8.259360686121067],
              [-35.251578337627834, -8.259152096275448],
              [-35.2516541687339, -8.259003885633888],
              [-35.251713737668929, -8.258884331550767],
              [-35.25179162196568, -8.258826226376366],
              [-35.25197147916419, -8.258765920705066],
              [-35.252010543647224, -8.258742471670333],
              [-35.252142863762693, -8.258587436437155],
              [-35.252215213883048, -8.258529545152898],
              [-35.252305757048994, -8.258527499161929],
              [-35.252350904835318, -8.258520826321883],
              [-35.252378628881004, -8.258497711813474],
              [-35.252574958990941, -8.2584144449911],
              [-35.252843092467273, -8.258256386804948],
              [-35.253033829468578, -8.258178936034222],
              [-35.253107406471962, -8.25817725612087],
              [-35.253215158477623, -8.25818614108581],
              [-35.25325492863486, -8.25819088742252],
              [-35.253368518753447, -8.258205253485061],
              [-35.253419872235646, -8.258220962605257],
              [-35.253533584729141, -8.258240929865483],
              [-35.253710852175011, -8.258315857456985],
              [-35.253802252934101, -8.258353214234885],
              [-35.253893989587517, -8.258401867837589],
              [-35.254025371203859, -8.258449573877392],
              [-35.254124786827184, -8.258588210804312],
              [-35.254190730536983, -8.258744388113703],
              [-35.254300874166582, -8.258854580006794],
              [-35.254382710931253, -8.258965384842313],
              [-35.254436486115665, -8.259088097685661],
              [-35.254499918388561, -8.259137364062859],
              [-35.254569341221192, -8.259203407102802],
              [-35.25459859660571, -8.259242198966138],
              [-35.254644604535159, -8.259274928908743],
              [-35.254830590763959, -8.259479238873437],
              [-35.254930006457471, -8.259617785209716],
              [-35.254970144484915, -8.259639432238204],
              [-35.254984242580541, -8.259757395975569],
              [-35.254959430624844, -8.259910018953688],
              [-35.254948704594923, -8.259938369040805],
              [-35.254817333999569, -8.26013280756807],
              [-35.254734472157367, -8.260224837534414],
              [-35.254657445597914, -8.260322256060615],
              [-35.254524023590463, -8.260426679509917],
              [-35.254457879568513, -8.260506955545171],
              [-35.254397206455934, -8.260575899698971],
              [-35.254252597584362, -8.260691777424501],
              [-35.254201824459393, -8.260698570157569],
              [-35.253936068470999, -8.260715794911121],
              [-35.253913969605456, -8.260738787611437],
              [-35.253897248792541, -8.260750451273472],
              [-35.253875027521673, -8.260767840412681],
              [-35.253815579786206, -8.260893088520913],
              [-35.253733913053239, -8.261035725753423],
              [-35.253657221990842, -8.261144441041841],
              [-35.253571155502904, -8.261343506526055],
              [-35.253584304996423, -8.261422067818053],
              [-35.253609775686058, -8.261545391389701],
              [-35.253612042544887, -8.261641099771721],
              [-35.253641912929119, -8.261707997393215],
              [-35.253723412634066, -8.261807595643216],
              [-35.253822429273583, -8.261923637198361],
              [-35.25386880526424, -8.26197326807794],
              [-35.253881217095959, -8.26201802982782],
              [-35.253898229315432, -8.262265502031569],
              [-35.254191846784138, -8.262472912386889],
              [-35.25449872539896, -8.262522430999397],
              [-35.254543751113367, -8.262510156557751],
              [-35.25462829973376, -8.262491330851761],
              [-35.254685156618386, -8.262501316453688],
              [-35.254736175280996, -8.262505818636951],
              [-35.254849521247593, -8.262508884265696],
              [-35.254911880910235, -8.262513144327002],
              [-35.254960110296537, -8.262393831987637],
              [-35.254976951222851, -8.26238777183989],
              [-35.255077762746403, -8.262340474496394],
              [-35.255179277456236, -8.262321282319114],
              [-35.255368132874729, -8.262407168960214],
              [-35.255521740092696, -8.262437577407711],
              [-35.255653032640772, -8.262485283002668],
              [-35.255744525643046, -8.26252273106379],
              [-35.255954160687111, -8.262529230945066],
              [-35.256092808772372, -8.262402270259068],
              [-35.256119306309856, -8.262322761336369],
              [-35.256162768194528, -8.262242976251095],
              [-35.256206842762779, -8.262191296840564],
              [-35.256274550275236, -8.262178533331094],
              [-35.256353998414369, -8.262188025679022],
              [-35.256468170451612, -8.262224892130185],
              [-35.256635785352671, -8.262373176245379],
              [-35.256716427677532, -8.262433370644473],
              [-35.256774848321733, -8.262510861759118],
              [-35.256798513200934, -8.262555379355364],
              [-35.256862647867962, -8.262632840523509],
              [-35.256880349758852, -8.262666273519736],
              [-35.256893500006441, -8.262744836823948],
              [-35.256929116338085, -8.262817215604532],
              [-35.257010464479613, -8.262905515040011],
              [-35.257165265563728, -8.26298653233456],
              [-35.257371578037187, -8.263094553462443],
              [-35.257440266304855, -8.263126796245468],
              [-35.257559821249615, -8.263152333192217],
              [-35.257701258487501, -8.263149093718669],
              [-35.257791555082193, -8.263135839136311],
              [-35.257950296829314, -8.263143529881129],
              [-35.258030203639869, -8.263169924500382],
              [-35.258276898016931, -8.263310888970395],
              [-35.258357753279242, -8.263376595708046],
              [-35.258369832451734, -8.263410150584557],
              [-35.258382154255138, -8.263454912554522],
              [-35.25840187899108, -8.263572756150712],
              [-35.258403198192582, -8.263629059433399],
              [-35.258371075420612, -8.263708600169199],
              [-35.258344825620163, -8.263799313894095],
              [-35.258341500457611, -8.263900747985955],
              [-35.258338423064671, -8.264013474756986],
              [-35.258339739952881, -8.264069778053887],
              [-35.258347510857895, -8.264159760540613],
              [-35.258389091209573, -8.264243313869095],
              [-35.258476062863679, -8.264331490839998],
              [-35.258528645893357, -8.264403502818228],
              [-35.258653946600525, -8.264434520856296],
              [-35.258738865465716, -8.264432595066459],
              [-35.258778388380179, -8.264426043363111],
              [-35.258872255132459, -8.264322563801322],
              [-35.25898069724613, -8.264117408461157],
              [-35.259000863124704, -8.264009916403422],
              [-35.2590002501789, -8.263981808475179],
              [-35.259009320546475, -8.263885858273945],
              [-35.259055002433975, -8.263653850774235],
              [-35.259063950303336, -8.263552294724269],
              [-35.259067643282933, -8.263467763841204],
              [-35.259067520284127, -8.263462070064756],
              [-35.259072655027062, -8.263439443661317],
              [-35.259075487280136, -8.263315509863348],
              [-35.2590736775458, -8.263236702217371],
              [-35.259071745345651, -8.263152293346783],
              [-35.259069477083202, -8.263056587672759],
              [-35.259063147860196, -8.263028512029189],
              [-35.259060967873154, -8.262932805855309],
              [-35.259072997685671, -8.262718523056073],
              [-35.259065317850741, -8.262628632655037],
              [-35.259033423286269, -8.262477234789051],
              [-35.259020978994862, -8.262426869310399],
              [-35.258983674691514, -8.262281196452319],
              [-35.258952364069572, -8.26215239266911],
              [-35.258939458952703, -8.262085126851231],
              [-35.258910170554351, -8.262040643505937],
              [-35.258868838239792, -8.261968385180662],
              [-35.258832760883038, -8.261879108626664],
              [-35.25880922066662, -8.26184019472641],
              [-35.258767642950311, -8.261756641375351],
              [-35.258744470670344, -8.261734626011441],
              [-35.258721266520055, -8.261707008907964],
              [-35.258674218025433, -8.261634785131152],
              [-35.258645053183685, -8.261596083452233],
              [-35.258550679452128, -8.261434824721638],
              [-35.258539183671438, -8.261423773712405],
              [-35.258475047200037, -8.261346403096292],
              [-35.258376490523048, -8.261247263356504],
              [-35.258346006137508, -8.261152168107611],
              [-35.258321392381482, -8.261068248180232],
              [-35.258308825289561, -8.261012188859477],
              [-35.258306679826305, -8.260922086660697],
              [-35.258305696779281, -8.260877080175643],
              [-35.258336010048964, -8.260718731791975],
              [-35.258325159505674, -8.260741480694742],
              [-35.258341019556752, -8.2606904139399],
              [-35.258346154350676, -8.26066778754142],
              [-35.258415406915432, -8.260480388344043],
              [-35.258463266646807, -8.260344087133022],
              [-35.258600226123733, -8.260143921837752],
              [-35.258627336423601, -8.26009260878384],
              [-35.258660775808544, -8.260069371404354],
              [-35.258703991551485, -8.259978291232562],
              [-35.258734549613443, -8.25983114995431],
              [-35.258745061779024, -8.259797106571325],
              [-35.258809181637453, -8.259632331456787],
              [-35.258872811094825, -8.259445054260278],
              [-35.258904073031594, -8.259326108345123],
              [-35.258952421115033, -8.259212401800472],
              [-35.258984175213335, -8.259115870034689],
              [-35.25905068588699, -8.259052496724756],
              [-35.259067649042947, -8.259052039998025],
              [-35.259198573063998, -8.259082934005987],
              [-35.259250419925444, -8.259121236902379],
              [-35.259292948521207, -8.259244192667627],
              [-35.25928160766081, -8.259244437180133],
              [-35.259256674938463, -8.259391456436736],
              [-35.259243450482245, -8.259555039483899],
              [-35.259218520079564, -8.259702058731982],
              [-35.25918808466286, -8.259854801221834],
              [-35.259178889385531, -8.259945150196776],
              [-35.25915838742084, -8.260041345410521],
              [-35.259027315451092, -8.260494829518285],
              [-35.258985171158855, -8.260630917996984],
              [-35.258960608614636, -8.260794835808044],
              [-35.258989619041301, -8.26082232988335],
              [-35.259089404296702, -8.260977773235961],
              [-35.259089740347456, -8.260989070040241],
              [-35.259136697695631, -8.261061204043429],
              [-35.259253417274401, -8.261210676742598],
              [-35.259300039138637, -8.261271604147094],
              [-35.259364050915714, -8.261343371066792],
              [-35.259356084396586, -8.261490023800262],
              [-35.259342492702586, -8.261636796230837],
              [-35.259353833116876, -8.261636461459601],
              [-35.259365664472412, -8.261658719000359],
              [-35.259430393371424, -8.2622543592082],
              [-35.259441199988181, -8.262479360469307],
              [-35.259460802410437, -8.262591600410335],
              [-35.259494291012764, -8.262816110255216],
              [-35.259650708750939, -8.263212474294784],
              [-35.259664102733737, -8.263302332362075],
              [-35.25966710945373, -8.263431837355155],
              [-35.259669164304384, -8.263521852036439],
              [-35.259680505283143, -8.263521607480119],
              [-35.259662024115997, -8.263702211053767],
              [-35.259697028556431, -8.263746574063433],
              [-35.259717491503146, -8.263898126605788],
              [-35.259709279814665, -8.264033481999192],
              [-35.259674816335945, -8.264259551142876],
              [-35.259644383553628, -8.264412293669297],
              [-35.259500479715307, -8.264556368567142],
              [-35.259372190197297, -8.264638079336036],
              [-35.25922589475153, -8.26468075475435],
              [-35.259017577792434, -8.264730469925395],
              [-35.258933026860639, -8.264749296643719],
              [-35.258759711428098, -8.264843282233846],
              [-35.258720433432046, -8.264861038710245],
              [-35.258658811774971, -8.264890581052443],
              [-35.258580099065334, -8.264914888723974],
              [-35.258523947712085, -8.264933011208587],
              [-35.258468652950697, -8.264990629194182],
              [-35.258452179453386, -8.265013497809445],
              [-35.258441328866901, -8.265036246740706],
              [-35.258443138581505, -8.265115054414668],
              [-35.258445777114531, -8.265227658651799],
              [-35.25848139405111, -8.265300039463396],
              [-35.25851808531214, -8.265417514147058],
              [-35.25851965009246, -8.265485114735929],
              [-35.258533413827706, -8.26559178348489],
              [-35.2585542103728, -8.265754630672687],
              [-35.258568344960501, -8.265878290495442],
              [-35.258654385327723, -8.266169208278722],
              [-35.258696576709781, -8.266280869437217],
              [-35.258779613432843, -8.26644237231174],
              [-35.258861085342154, -8.266536274582467],
              [-35.258896089534467, -8.266580547374698],
              [-35.258988932676637, -8.26667989934559],
              [-35.259006480073374, -8.266702034320163],
              [-35.259023691387313, -8.266712874806673],
              [-35.259087000674292, -8.266756534119937],
              [-35.259237407305235, -8.266893975854339],
              [-35.259248870885429, -8.266899334820183],
              [-35.259318666328042, -8.266982274951914],
              [-35.259394635738673, -8.267081902613347],
              [-35.259378285282665, -8.26711046736132],
              [-35.259437779455929, -8.26723305622879],
              [-35.259467067943838, -8.267277451480876],
              [-35.259495865896575, -8.267299342351366],
              [-35.259571344890176, -8.26737646788686],
              [-35.259794011249703, -8.26745601475154],
              [-35.259931757696016, -8.267537395153786],
              [-35.260030564517152, -8.267647831246142],
              [-35.260036402873993, -8.267653312223418],
              [-35.260072634000785, -8.267753888572804],
              [-35.260101522605247, -8.267775778868673],
              [-35.260376405097446, -8.267910436023135],
              [-35.260479485775896, -8.267958842670678],
              [-35.260595379396207, -8.268074513115911],
              [-35.260710536902167, -8.268156384168696],
              [-35.260814601244029, -8.268249797083476],
              [-35.260832148827483, -8.268271934243907],
              [-35.26088341368331, -8.268287732718354],
              [-35.26095185750949, -8.268308677275511],
              [-35.260968823871643, -8.268308310587551],
              [-35.261082048544985, -8.268305770460014],
              [-35.261210426809214, -8.268224058431734],
              [-35.261232034285477, -8.268178473064889],
              [-35.261235113384501, -8.268065834417397],
              [-35.261243569321984, -8.267941686190161],
              [-35.261273758097232, -8.267777646353409],
              [-35.261292726508934, -8.26761954493235],
              [-35.261297370457825, -8.267574414176838],
              [-35.261343941982993, -8.267387503831893],
              [-35.261359924179821, -8.267342040532972],
              [-35.261408396675328, -8.267233934859387],
              [-35.261569826873348, -8.266869941388865],
              [-35.26171993684796, -8.26674824750333],
              [-35.261820993649479, -8.26671224336134],
              [-35.261974108937416, -8.266720054177076],
              [-35.26209320340233, -8.266728601182329],
              [-35.262195334407139, -8.266737602784531],
              [-35.262388956882148, -8.266783960232724],
              [-35.26260467785508, -8.266807324445296],
              [-35.262678746645207, -8.266828146676341],
              [-35.26278087532615, -8.266837148131419],
              [-35.262877135491216, -8.26683497665924],
              [-35.263007323551463, -8.266832071677037],
              [-35.263069561080584, -8.266830724423517],
              [-35.263103493111316, -8.266829900663717],
              [-35.263317648384771, -8.266785664128228],
              [-35.263663138461261, -8.266541382687322],
              [-35.263701712345984, -8.266495520740801],
              [-35.263745542709678, -8.266432543359343],
              [-35.263801079982912, -8.266386224399469],
              [-35.263820876745932, -8.266261921878586],
              [-35.263815689366204, -8.266036709157422],
              [-35.263813755987115, -8.265952300596247],
              [-35.263812683873077, -8.265907292492782],
              [-35.263811732012876, -8.265867890233595],
              [-35.263816252637284, -8.26581706808598],
              [-35.263831037528114, -8.265720995484459],
              [-35.263858462596545, -8.265438746153457],
              [-35.263861295498465, -8.265314810405121],
              [-35.263881091764723, -8.265190417656347],
              [-35.263885151806278, -8.265122785484516],
              [-35.263946965194499, -8.264856610355151],
              [-35.263964614973432, -8.264642206049972],
              [-35.263979770374071, -8.264563034236181],
              [-35.264045298681822, -8.264454561228691],
              [-35.264127577643059, -8.264340118453157],
              [-35.264198760720966, -8.264237127395358],
              [-35.264220860783738, -8.264214043986541],
              [-35.26433811206028, -8.264138269386491],
              [-35.264393771542714, -8.264097551646598],
              [-35.264426965105081, -8.26406301883328],
              [-35.264465783831589, -8.264028361613713],
              [-35.264537305324055, -8.263936667275512],
              [-35.264570285181435, -8.263896438902849],
              [-35.264592964755821, -8.263895949520652],
              [-35.264653636013968, -8.263827001856013],
              [-35.264715136586346, -8.263791855224962],
              [-35.264913623217467, -8.2635621457407],
              [-35.265046466403106, -8.26318177434227],
              [-35.265084986680598, -8.262888073445167],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '923',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL DE MURICÍ',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1997',
        GID7: '5093',
        QUALIDAD8:
          'Esquemático (O poligono é uma representação esquemática da dimensão da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 5907 de 17/03/1997',
        DT_ULTIM10: '30/09/2010',
        CODIGO_U11: '0000.27.0923',
        NOME_ORG12: 'Instituto do Meio Ambiente de Alagoas',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-35.71808885426713, -9.056963326538487],
              [-35.719473028370416, -9.057967136753419],
              [-35.726980963873778, -9.060986533107863],
              [-35.729932875182904, -9.061786666029365],
              [-35.74117222944767, -9.068422158522077],
              [-35.742196236424526, -9.069423202418109],
              [-35.74429738863418, -9.074254076990451],
              [-35.744354384110757, -9.075316642729124],
              [-35.743597587040227, -9.077396008597765],
              [-35.743835964857766, -9.078913121608551],
              [-35.748325514981779, -9.088031392609658],
              [-35.748528689543619, -9.089077949168285],
              [-35.748311850650715, -9.09020924574429],
              [-35.748524915459193, -9.091601134066142],
              [-35.749326636675711, -9.092257851837925],
              [-35.753178671753389, -9.093831461647564],
              [-35.754164708916193, -9.09498061260709],
              [-35.755147223376653, -9.098731402479336],
              [-35.75632897575192, -9.10039343832444],
              [-35.759313066272583, -9.10899521913438],
              [-35.760166167851239, -9.109918898518812],
              [-35.761295450789071, -9.110685502491069],
              [-35.763566253427697, -9.113003166733805],
              [-35.764031862930636, -9.114135218286126],
              [-35.763889034868207, -9.116048456667926],
              [-35.764455476114883, -9.119004815511033],
              [-35.765315869569534, -9.120276425030536],
              [-35.768340449524153, -9.123076632406123],
              [-35.769338975369266, -9.124489643669696],
              [-35.769522368371433, -9.126027375969239],
              [-35.769528736069873, -9.128792041667808],
              [-35.770833112417947, -9.13285002624273],
              [-35.769740031580071, -9.140285973524641],
              [-35.768903271362873, -9.142914852689],
              [-35.768342441582213, -9.144797161720833],
              [-35.768632616557717, -9.149110875302224],
              [-35.767900126863445, -9.151086390229246],
              [-35.768686172165168, -9.153838735329142],
              [-35.768648369158605, -9.154905911008584],
              [-35.7665845929413, -9.157823627245659],
              [-35.766676288246039, -9.159912884462059],
              [-35.769518311580207, -9.163157748248864],
              [-35.771484184825148, -9.163809773244292],
              [-35.773600844670426, -9.167199893653756],
              [-35.774547736242162, -9.171052271430232],
              [-35.776392658915192, -9.172587909766902],
              [-35.782065805923295, -9.182692221531033],
              [-35.783928748326986, -9.185547748049261],
              [-35.786388642221212, -9.190901649804852],
              [-35.787124900321523, -9.192700057804927],
              [-35.786862965843909, -9.19530571980903],
              [-35.787795861400831, -9.197343094212249],
              [-35.788722302289919, -9.200106922583196],
              [-35.78823162915419, -9.201152472720384],
              [-35.787010730977919, -9.202443777717248],
              [-35.785413821528593, -9.206472339488831],
              [-35.784258553117482, -9.207702624757811],
              [-35.781837629251008, -9.208377192974643],
              [-35.775487469282794, -9.21233131974849],
              [-35.774782891961635, -9.213317777729602],
              [-35.774475946185184, -9.216891039173655],
              [-35.7757607940406, -9.219625531435712],
              [-35.775895740373933, -9.22082279341917],
              [-35.775786775084804, -9.222503938740832],
              [-35.775997241883097, -9.223482602435235],
              [-35.774961663099759, -9.227861944672199],
              [-35.774280303298383, -9.229003382875703],
              [-35.772794539565417, -9.230081453653657],
              [-35.77165645336855, -9.231895031104743],
              [-35.770941901468973, -9.233103440170947],
              [-35.765974995412023, -9.236058285355684],
              [-35.76388733201096, -9.237831293851539],
              [-35.763147717796308, -9.239753248837651],
              [-35.761887676392298, -9.241146560777905],
              [-35.759512517462611, -9.241653408435276],
              [-35.756834353729211, -9.244066369796149],
              [-35.75690249447397, -9.247915310266064],
              [-35.75841884950956, -9.249429155551269],
              [-35.759606973540947, -9.249451703768655],
              [-35.761148475838326, -9.249017358267468],
              [-35.762732642171684, -9.24799863099825],
              [-35.764793398662199, -9.247589431481515],
              [-35.765862248961632, -9.247970051748259],
              [-35.771726170555461, -9.253388329144839],
              [-35.781116236940356, -9.265622134405056],
              [-35.782545588970954, -9.266736584998036],
              [-35.787693957288326, -9.268500882923798],
              [-35.789404100782313, -9.269353066032535],
              [-35.799154419624664, -9.27992743521051],
              [-35.80117871408612, -9.280702757145161],
              [-35.802767489017256, -9.281228216067005],
              [-35.80274041214102, -9.28512174787004],
              [-35.805696176065098, -9.289200940111037],
              [-35.807461723449329, -9.290042059250563],
              [-35.812162336223821, -9.289976433712438],
              [-35.81808434723802, -9.291734194531205],
              [-35.819221424831667, -9.292968169895561],
              [-35.820806337593865, -9.300593968433786],
              [-35.821372443001941, -9.304099589283906],
              [-35.821501521145109, -9.306273838434135],
              [-35.822076010572701, -9.307396753925252],
              [-35.825159263919936, -9.309485678640129],
              [-35.828308985758738, -9.310924675621557],
              [-35.829296531641624, -9.312055218963444],
              [-35.830644910849728, -9.317906665816595],
              [-35.829722570031528, -9.322799228225811],
              [-35.827947463524083, -9.326121033229347],
              [-35.828885804112581, -9.331545844114588],
              [-35.830479521420592, -9.335211559045398],
              [-35.830975552609836, -9.341018329940722],
              [-35.83158541323769, -9.342257836853292],
              [-35.833337884091733, -9.344046355265531],
              [-35.833547428473523, -9.345459952610703],
              [-35.832592065704112, -9.347690359107748],
              [-35.832632631609599, -9.35000307617358],
              [-35.833293537548641, -9.351261442272827],
              [-35.836976760100697, -9.355512882039541],
              [-35.837978049199457, -9.359855429951415],
              [-35.837709074098086, -9.362288509948163],
              [-35.836929646645075, -9.363699172749834],
              [-35.834923957799937, -9.36513053757279],
              [-35.83397966049754, -9.365787421436711],
              [-35.831866850925167, -9.368266516044365],
              [-35.834234298492689, -9.367864944300038],
              [-35.836271334200433, -9.367698147565749],
              [-35.840752007180463, -9.368326454320991],
              [-35.8443786558122, -9.368888711404008],
              [-35.84665340067599, -9.368157062752424],
              [-35.848627658187603, -9.366168473237959],
              [-35.849736075625273, -9.361251938906644],
              [-35.850848431585661, -9.357343572184035],
              [-35.854339632845061, -9.354715449735194],
              [-35.859145604947862, -9.35492927484213],
              [-35.864261032024288, -9.357412315526078],
              [-35.868800965428981, -9.35708090176019],
              [-35.871415091331968, -9.355449546810055],
              [-35.872216478555345, -9.354708676786339],
              [-35.875015844189655, -9.35163078421647],
              [-35.875476680358254, -9.34808291979231],
              [-35.877683449431586, -9.346461455039146],
              [-35.881695999104345, -9.345207870852624],
              [-35.883525166040478, -9.344455267192835],
              [-35.886167119687947, -9.342744368613413],
              [-35.887544841895107, -9.341445855361656],
              [-35.893960299416186, -9.334247789274613],
              [-35.895705145708817, -9.332911306626549],
              [-35.903156593076986, -9.330091578943478],
              [-35.904906383773515, -9.329701908841679],
              [-35.905870377770071, -9.329701155356021],
              [-35.912375596704209, -9.331117859736688],
              [-35.913824730767104, -9.331244594466865],
              [-35.915020482716983, -9.330791892429428],
              [-35.915747606898933, -9.330106593929317],
              [-35.916465762846244, -9.329074538737856],
              [-35.917161790494376, -9.328338781538005],
              [-35.918038865512884, -9.327718358609269],
              [-35.921340469070458, -9.325936382632529],
              [-35.922465059756526, -9.324995103129176],
              [-35.935837609732296, -9.309309844569848],
              [-35.937290516887991, -9.308066232429328],
              [-35.939520754681539, -9.306887412613253],
              [-35.954594808064172, -9.299457093635919],
              [-35.955802378745652, -9.298625532480107],
              [-35.956789004658667, -9.297567493830813],
              [-35.957346229348019, -9.296716989126175],
              [-35.960666864159712, -9.290064997015273],
              [-35.961822218313756, -9.286784430068799],
              [-35.962132935604224, -9.283597822770728],
              [-35.962798874051018, -9.281170945690507],
              [-35.964524694635614, -9.278714984592881],
              [-35.965027940611833, -9.277584442596304],
              [-35.965034984490728, -9.27417160918837],
              [-35.963816353159302, -9.271351454508666],
              [-35.964363776009449, -9.265175799803835],
              [-35.97781854458848, -9.257239300067106],
              [-35.980304930043729, -9.257147940277845],
              [-35.981722044955418, -9.257908174367103],
              [-35.984267100490605, -9.260768227804064],
              [-35.985685388963134, -9.261414468884308],
              [-35.987292772259259, -9.261201906905159],
              [-35.988967127516183, -9.259731817670723],
              [-35.9911238632627, -9.251406306518302],
              [-35.992558917759375, -9.249477646651485],
              [-35.997377967422693, -9.247701539325037],
              [-35.999880699441526, -9.245440205233502],
              [-36.001422705006249, -9.244659270686215],
              [-36.005548910643022, -9.245223539834596],
              [-36.007190072671911, -9.245050791619988],
              [-36.008562597714509, -9.244233479737114],
              [-36.010877576874243, -9.241885728712923],
              [-36.01234992976223, -9.240767341260909],
              [-36.015116100951531, -9.240333583597327],
              [-36.01654595776381, -9.239443433584418],
              [-36.017186246504906, -9.238442139669422],
              [-36.018183267145119, -9.236014089431231],
              [-36.019332752395485, -9.234496726146361],
              [-36.025286535363534, -9.231140475541693],
              [-36.026091275487467, -9.230223838350176],
              [-36.026709103732479, -9.226168956623894],
              [-36.028014156670245, -9.223600510861004],
              [-36.031007645740523, -9.220215004955014],
              [-36.031573504386614, -9.218629245334993],
              [-36.03210047993214, -9.207678624540161],
              [-36.031331365831583, -9.205041110551559],
              [-36.031469667084103, -9.20384552515501],
              [-36.032378930072966, -9.201318150049497],
              [-36.03083785106201, -9.197118931116634],
              [-36.031034322853813, -9.19520644708104],
              [-36.032366447961074, -9.193871556598856],
              [-36.034617640018027, -9.193045360757276],
              [-36.035988572934514, -9.191769227001513],
              [-36.036545966769481, -9.189265823640035],
              [-36.03544493687167, -9.181605408503142],
              [-36.034335855540924, -9.179283554275671],
              [-36.025664358882459, -9.171526992641068],
              [-36.0210444677273, -9.166402080662664],
              [-36.014628353101813, -9.158838471025469],
              [-36.0134179351139, -9.156906204597332],
              [-36.012691486639355, -9.151918842117034],
              [-36.016271626496717, -9.141174836503851],
              [-36.018859435363318, -9.137472832010284],
              [-36.025110689971363, -9.133464642224313],
              [-36.042203095619449, -9.117151168188855],
              [-36.048881157070063, -9.108552413217421],
              [-36.0508613653287, -9.106961290500411],
              [-36.053753516402388, -9.105425404917785],
              [-36.054569826745606, -9.100860320880237],
              [-36.052743265044811, -9.093992743324771],
              [-36.051761587828203, -9.091277194267827],
              [-36.051984129035418, -9.087207308752555],
              [-36.050456292001648, -9.084398134070574],
              [-36.049269512604901, -9.080410137868348],
              [-36.046885891342306, -9.077166866989909],
              [-36.04512176840344, -9.076769650070531],
              [-36.04266027642992, -9.077438434802918],
              [-36.037347874969385, -9.076333703697991],
              [-36.036288748886115, -9.074475029975902],
              [-36.038985272758318, -9.067763442511835],
              [-36.035851812626071, -9.065132380491022],
              [-36.034853119923859, -9.062824104988637],
              [-36.035231142140709, -9.0608442311602],
              [-36.034902325003436, -9.05865967219761],
              [-36.033275832888336, -9.056917254082865],
              [-36.035966487360895, -9.052548642048833],
              [-36.037099545098634, -9.050041132136],
              [-36.038325993275251, -9.028745898117277],
              [-36.038197669824584, -9.02614560013466],
              [-36.036433217058473, -9.01967180151526],
              [-36.035627807862966, -9.018959679773364],
              [-36.032250016634656, -9.017220780495235],
              [-36.029545627076999, -9.009200447974413],
              [-36.029067698949831, -9.005072475747191],
              [-36.029434577604256, -8.998305985227104],
              [-36.028479052280112, -8.994898599267309],
              [-36.028063901825277, -8.993246370833823],
              [-36.027705416257199, -8.987416131078817],
              [-36.028119987906656, -8.980193081857299],
              [-36.027177370415274, -8.976982250038791],
              [-36.020170414925182, -8.975749526832411],
              [-36.018310153829376, -8.973767890867919],
              [-36.017559061467601, -8.970145594759011],
              [-36.011117040415208, -8.969766310882271],
              [-36.006479039743873, -8.969856663021179],
              [-36.00264778952225, -8.970482699186174],
              [-35.992646929004628, -8.969223989657809],
              [-35.986371873318788, -8.96958431938485],
              [-35.982422438847024, -8.97075701896987],
              [-35.971909112957128, -8.969995603490396],
              [-35.958947289933207, -8.966558954395962],
              [-35.957180987600125, -8.966301378939731],
              [-35.954719915003551, -8.96667570969451],
              [-35.951105001453669, -8.965414491246031],
              [-35.944956515079149, -8.961624637471438],
              [-35.939748047107429, -8.961866197490252],
              [-35.936106477837633, -8.962307414282835],
              [-35.935750584375043, -8.963497718884716],
              [-35.935553191603674, -8.964317713991878],
              [-35.934613756069098, -8.96463555249071],
              [-35.934149233839648, -8.966140259767171],
              [-35.932409165573489, -8.967073743615698],
              [-35.930631380542749, -8.966363800316255],
              [-35.928342555093352, -8.966084347804188],
              [-35.924935427486794, -8.964343856007288],
              [-35.922807198313052, -8.963551435109093],
              [-35.919566660310331, -8.96364282746401],
              [-35.918991548296404, -8.961438231410618],
              [-35.918033028273193, -8.959521191364191],
              [-35.916786952243008, -8.957987559327048],
              [-35.914869912196586, -8.956645631294549],
              [-35.91266531614319, -8.955687111271335],
              [-35.911515092115337, -8.954536887243478],
              [-35.910460720089802, -8.9532908112133],
              [-35.909693904071226, -8.952428143192407],
              [-35.908735384048015, -8.951565475171515],
              [-35.906722491999268, -8.951853031178478],
              [-35.904613747948197, -8.952140587185443],
              [-35.901642335876232, -8.952811551201693],
              [-35.896274623746237, -8.953099107208658],
              [-35.893878323688199, -8.95252399519473],
              [-35.892823951662663, -8.951469623169194],
              [-35.891290319625519, -8.950702807150622],
              [-35.890044243595341, -8.949935991132051],
              [-35.888318907553561, -8.949648435125088],
              [-35.886976979521059, -8.948881619106515],
              [-35.885730903490881, -8.94715628306473],
              [-35.88477238346767, -8.945622651027589],
              [-35.884580679463028, -8.944280722995089],
              [-35.884580679463028, -8.943034646964911],
              [-35.88477238346767, -8.942171978944019],
              [-35.884484827460703, -8.94150101492777],
              [-35.882855343421241, -8.941117606918484],
              [-35.881992675400348, -8.940063234892948],
              [-35.881130007379454, -8.938625454858128],
              [-35.880459043363203, -8.936995970818664],
              [-35.879692227344634, -8.936133302797771],
              [-35.878925411326065, -8.935462338781521],
              [-35.877583483293563, -8.9353664867792],
              [-35.876241555261061, -8.935558190783842],
              [-35.874803775226241, -8.93603745079545],
              [-35.873270143189103, -8.936325006802415],
              [-35.871353103142674, -8.93603745079545],
              [-35.869915323107854, -8.935558190783842],
              [-35.868477543073034, -8.934983078769914],
              [-35.866656355028923, -8.93459967076063],
              [-35.864164202968567, -8.93392870674438],
              [-35.863205682945356, -8.933545298735094],
              [-35.86215131091982, -8.932778482716524],
              [-35.86100108689196, -8.932299222704916],
              [-35.859755010861782, -8.932682630714201],
              [-35.858413082829287, -8.933737002739736],
              [-35.857262858801427, -8.935462338781521],
              [-35.855729226764289, -8.938146194846521],
              [-35.854387298731787, -8.939200566872056],
              [-35.852470258685358, -8.93948812287902],
              [-35.85122418265518, -8.93948812287902],
              [-35.849019586601791, -8.94025493889759],
              [-35.847390102562329, -8.941213458920805],
              [-35.846144026532144, -8.941788570934733],
              [-35.84432283848804, -8.942842942960269],
              [-35.843268466462504, -8.943226350969555],
              [-35.842453724442777, -8.943753536982323],
              [-35.84044083239403, -8.944136944991609],
              [-35.838907200356886, -8.944616205003214],
              [-35.836606752301172, -8.945766429031071],
              [-35.834881416259385, -8.945862281033392],
              [-35.833251932219923, -8.94643739304732],
              [-35.831334892173494, -8.946341541044999],
              [-35.830088816143316, -8.945574725026429],
              [-35.829417852127072, -8.944712057005537],
              [-35.82807592409457, -8.944136944991609],
              [-35.827117404071359, -8.943082572966073],
              [-35.825392068029572, -8.942411608949824],
              [-35.822516507959932, -8.941261384921967],
              [-35.819257539881001, -8.939919456889466],
              [-35.816094423804394, -8.939248492873217],
              [-35.814081531755647, -8.938769232861612],
              [-35.812547899718503, -8.938960936866254],
              [-35.811493527692967, -8.939152640870896],
              [-35.810247451662789, -8.939919456889466],
              [-35.809384783641903, -8.94078212491036],
              [-35.809097227634936, -8.941644792931251],
              [-35.80852211562101, -8.942603312954466],
              [-35.807467743595474, -8.942603312954466],
              [-35.806125815562972, -8.941740644933574],
              [-35.805263147542078, -8.940590420905718],
              [-35.804592183525834, -8.939823604887145],
              [-35.803921219509583, -8.938577528856968],
              [-35.803729515504941, -8.937235600824469],
              [-35.803058551488689, -8.936181228798931],
              [-35.802483439474756, -8.93608537679661],
              [-35.801812475458512, -8.935989524794289],
              [-35.801524919451545, -8.935989524794289],
              [-35.801045659439936, -8.93608537679661],
              [-35.800278843421367, -8.93608537679661],
              [-35.800182991419049, -8.93608537679661],
              [-35.799416175400474, -8.93608537679661],
              [-35.798649359381905, -8.93608537679661],
              [-35.797594987356369, -8.93608537679661],
              [-35.796253059323867, -8.93608537679661],
              [-35.794623575284405, -8.935797820789645],
              [-35.793377499254227, -8.935701968787324],
              [-35.792131423224049, -8.935510264782682],
              [-35.791556311210122, -8.935126856773396],
              [-35.790310235179945, -8.934839300766432],
              [-35.789447567159051, -8.934839300766432],
              [-35.788201491128873, -8.934839300766432],
              [-35.787242971105663, -8.934743448764111],
              [-35.785421783061551, -8.934839300766432],
              [-35.784175707031373, -8.934455892757146],
              [-35.78302548300352, -8.934455892757146],
              [-35.781878527065231, -8.933678522845568],
              [-35.77845555566217, -8.93440857800041],
              [-35.777609745490587, -8.934349066209792],
              [-35.776160193061763, -8.933398313000998],
              [-35.775232492475752, -8.933292114298521],
              [-35.774543506041638, -8.933524625899448],
              [-35.772961022759773, -8.93263470471304],
              [-35.770468870699418, -8.931772036692148],
              [-35.768935238662273, -8.931676184689826],
              [-35.767497458627453, -8.930717664666613],
              [-35.7663472345996, -8.928896476622507],
              [-35.765580418581024, -8.927554548590006],
              [-35.764046786543886, -8.926308472559828],
              [-35.762704858511384, -8.925925064550544],
              [-35.761171226474247, -8.926787732571436],
              [-35.759781372440585, -8.92645225056331],
              [-35.758151888401123, -8.92578128654706],
              [-35.756905812370945, -8.924631062519204],
              [-35.756043144350052, -8.923576690493668],
              [-35.755372180333801, -8.92300157847974],
              [-35.754413660310583, -8.922234762461169],
              [-35.75326343628273, -8.922234762461169],
              [-35.751442248238625, -8.92233061446349],
              [-35.749237652185229, -8.92233061446349],
              [-35.748087428157376, -8.922618170470455],
              [-35.746266240113272, -8.922234762461169],
              [-35.744924312080769, -8.921276242437955],
              [-35.743103124036665, -8.919646758398493],
              [-35.741665344001845, -8.91811312636135],
              [-35.740610971976309, -8.917250458340458],
              [-35.739556599950774, -8.917058754335814],
              [-35.738502227925238, -8.916291938317244],
              [-35.737352003897378, -8.91466245427778],
              [-35.736776891883451, -8.913799786256888],
              [-35.735722519857916, -8.913416378247602],
              [-35.732463551778991, -8.913416378247602],
              [-35.730738215737205, -8.912937118235995],
              [-35.727670951662923, -8.912074450215103],
              [-35.7208654594981, -8.909965706164032],
              [-35.71798989942846, -8.90910303814314],
              [-35.714539227344893, -8.908719630133854],
              [-35.710034183235784, -8.908815482136175],
              [-35.707829587182395, -8.90833622212457],
              [-35.703612099080225, -8.906994294092062],
              [-35.701982615040777, -8.906706738085099],
              [-35.700161426996665, -8.905939922066528],
              [-35.697190014924708, -8.904406290029385],
              [-35.694602010862027, -8.903543622008494],
              [-35.692684970815598, -8.902872657992242],
              [-35.690192818755243, -8.902489249982958],
              [-35.688179926706496, -8.902585101985279],
              [-35.68453755061828, -8.9026809539876],
              [-35.678498874472034, -8.901818285966709],
              [-35.671309974297934, -8.900955617945815],
              [-35.665175446149362, -8.900476357934208],
              [-35.661916478070438, -8.899613689913316],
              [-35.652714685847585, -8.898846873894746],
              [-35.650030829782587, -8.898942725897067],
              [-35.644183857640982, -8.899805393917958],
              [-35.639873130198431, -8.901193327308663],
              [-35.636418559911895, -8.901287284729891],
              [-35.636974943442986, -8.903778283763723],
              [-35.634945616046465, -8.907368977759518],
              [-35.634613190694587, -8.909738439369228],
              [-35.633422801164087, -8.912468570743322],
              [-35.634076581327598, -8.915188058779933],
              [-35.635434684744915, -8.919859090865456],
              [-35.63736350546899, -8.926041285098711],
              [-35.643260197671026, -8.934315841110823],
              [-35.646827251058689, -8.935387881343543],
              [-35.650992013081158, -8.935874031589304],
              [-35.654260002935089, -8.936866947009559],
              [-35.657572080501915, -8.938657226760983],
              [-35.65921894119289, -8.939542685605989],
              [-35.662137995184288, -8.94097355178547],
              [-35.663894322883131, -8.941472021245144],
              [-35.66610963671468, -8.945088870286156],
              [-35.667614591082035, -8.948229546469022],
              [-35.670387876725165, -8.959162902811292],
              [-35.672459689402082, -8.967611965451368],
              [-35.67491556008936, -8.973023617802067],
              [-35.675033239678221, -8.974461773755269],
              [-35.673066104947232, -8.978574740193331],
              [-35.673588960772868, -8.981489524991556],
              [-35.673459878924056, -8.983419359377903],
              [-35.671378417669587, -8.985714724036606],
              [-35.672103692096115, -8.991491990687125],
              [-35.675525317691829, -8.996047942009545],
              [-35.67576721604582, -8.997390209843305],
              [-35.674267620898959, -9.000086478349942],
              [-35.674211377401583, -9.001706855325939],
              [-35.675456412425184, -9.003552621079704],
              [-35.675902490214369, -9.007018846979763],
              [-35.678733949725981, -9.013380581098435],
              [-35.678838469790882, -9.014873554272807],
              [-35.678009335592641, -9.019180226013299],
              [-35.681374886042136, -9.024531446158186],
              [-35.68124949104498, -9.027748647145085],
              [-35.681063748683279, -9.028946327895092],
              [-35.681335318728841, -9.030258253506569],
              [-35.681948448122526, -9.031708228299637],
              [-35.682779254174058, -9.03248589601229],
              [-35.683891354493127, -9.033110260144731],
              [-35.68484361311716, -9.034009548442302],
              [-35.685754800335765, -9.035415518846232],
              [-35.690746930417703, -9.038301390258699],
              [-35.697140167563418, -9.040316892788345],
              [-35.69840054243106, -9.040919799547147],
              [-35.708426543228803, -9.047672469005601],
              [-35.714360620522633, -9.053441023206199],
              [-35.715083049686882, -9.054058513894269],
              [-35.71808885426713, -9.056963326538487],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3318',
        NOME_UC1: 'REFÚGIO DA VIDA SILVESTRE TATU-BOLA',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2015',
        GID7: '13488',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 41.546/2015 de 17/03/2015',
        DT_ULTIM10: '05/07/2017',
        CODIGO_U11: '0000.26.3318',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-40.136170512817607, -8.911188039743534],
              [-40.119400287766297, -8.900778411627261],
              [-40.118350454669482, -8.900176607095393],
              [-40.117812105736043, -8.899852406448991],
              [-40.117237470086415, -8.899495488955067],
              [-40.116124188500159, -8.898820319781274],
              [-40.114952312359641, -8.898106484774026],
              [-40.1144726169912, -8.897808577031329],
              [-40.113006880090879, -8.896894867892625],
              [-40.112702311380843, -8.896723547590582],
              [-40.111907577407216, -8.89621910373531],
              [-40.111169949960988, -8.895795561474413],
              [-40.110905321076068, -8.895505407877048],
              [-40.109513921067929, -8.892997834785819],
              [-40.10935491232388, -8.892840286534128],
              [-40.109236729456875, -8.892498281357634],
              [-40.108368590938611, -8.890933726412797],
              [-40.108165192591692, -8.890679746665679],
              [-40.10801290786749, -8.89044656084624],
              [-40.10761792129005, -8.88971369062998],
              [-40.107324249917632, -8.889075288970261],
              [-40.10727802529437, -8.889016355261884],
              [-40.107223945785684, -8.888890400924323],
              [-40.106914618410627, -8.888378819536239],
              [-40.10645360113476, -8.8878285722332],
              [-40.106081813116432, -8.88740919427091],
              [-40.105805203374608, -8.887052277040034],
              [-40.105545997410687, -8.886808088189124],
              [-40.103959115343741, -8.884784877201923],
              [-40.103807216397236, -8.883670733348875],
              [-40.103668624454464, -8.883575565763854],
              [-40.101737106031386, -8.886942176094953],
              [-40.100785471598925, -8.887070216539223],
              [-40.100162710808824, -8.887253309961338],
              [-40.099705022656742, -8.88766556523651],
              [-40.099288974043979, -8.888267772477453],
              [-40.098952723639037, -8.889131612313623],
              [-40.098337754972292, -8.890242056515541],
              [-40.097972168766361, -8.891113612282668],
              [-40.097965940210933, -8.891789430468057],
              [-40.097733604222356, -8.892488434324628],
              [-40.097364853092365, -8.893299213431607],
              [-40.097326161781979, -8.893955555698467],
              [-40.097430712507538, -8.894564833356334],
              [-40.095451617931431, -8.890585605048912],
              [-40.094070994415823, -8.887436843588025],
              [-40.089472248145562, -8.883553825450127],
              [-40.085964794992663, -8.878789021245849],
              [-40.089793147193113, -8.866259815256276],
              [-40.092045482883456, -8.858897884751206],
              [-40.109938808669092, -8.864106123015114],
              [-40.112281000856456, -8.867920150558831],
              [-40.105198213199465, -8.880772494904324],
              [-40.111301781755905, -8.89160175422084],
              [-40.142347840189757, -8.905880440056897],
              [-40.151367402752527, -8.906052298018981],
              [-40.151794044266182, -8.90660682527268],
              [-40.152507877199987, -8.907796550203562],
              [-40.153269297884549, -8.908891096712356],
              [-40.153078941256993, -8.909973745451204],
              [-40.152745818006409, -8.911758330113857],
              [-40.152031980255799, -8.915981849715564],
              [-40.151459585213793, -8.919416358883653],
              [-40.157380116516613, -8.919595571089008],
              [-40.156232099413366, -8.920894827767302],
              [-40.163501471013277, -8.924713560687373],
              [-40.159569935644967, -8.92690010692162],
              [-40.158188423566614, -8.927764322842116],
              [-40.151214392054932, -8.932745341671762],
              [-40.154794406129085, -8.940373867919917],
              [-40.152450595790313, -8.942371655614778],
              [-40.191120360660051, -8.955653303129743],
              [-40.19138261851419, -8.954878677714239],
              [-40.194079651496722, -8.954994786448243],
              [-40.195655700978257, -8.956632943639224],
              [-40.197062540873908, -8.955782923659831],
              [-40.199347024043597, -8.949984844257083],
              [-40.205115731425252, -8.944585078698065],
              [-40.207139205657107, -8.936284695128293],
              [-40.208220714511924, -8.924593963789917],
              [-40.208332217660818, -8.923550847751851],
              [-40.20657440776656, -8.919309584044605],
              [-40.202445648310935, -8.909659477667828],
              [-40.199109974011812, -8.907060028350333],
              [-40.19872760477908, -8.906962972559672],
              [-40.198144962786671, -8.907049974557451],
              [-40.19759900068069, -8.906975695494674],
              [-40.197073309628315, -8.906681503686656],
              [-40.196565464574213, -8.906248754673724],
              [-40.196249906328347, -8.905640147819236],
              [-40.196287153149328, -8.903300295013571],
              [-40.196242229934107, -8.902772245944407],
              [-40.195881054323451, -8.90180884804607],
              [-40.195749436530882, -8.900364636054627],
              [-40.195200575725664, -8.899975044763559],
              [-40.193165069017333, -8.898828361424547],
              [-40.19203848204932, -8.896429456930786],
              [-40.190109838926418, -8.893780908138208],
              [-40.186403497480647, -8.890893261131529],
              [-40.186078749039915, -8.88944968497043],
              [-40.185584813326592, -8.880636072144362],
              [-40.183443189947553, -8.872060615544203],
              [-40.181812701165434, -8.872303635746711],
              [-40.1809807245155, -8.868335168363593],
              [-40.180145544436904, -8.867132858402506],
              [-40.180041289826356, -8.86300119557945],
              [-40.18020915085738, -8.86163630141353],
              [-40.180919070732145, -8.860672742461903],
              [-40.181534306912972, -8.858983379515374],
              [-40.178781006404172, -8.847464684994728],
              [-40.178072162292715, -8.843029180366962],
              [-40.177123764924943, -8.83738574534055],
              [-40.17501058546857, -8.835429986518273],
              [-40.172861754191494, -8.831286451998041],
              [-40.173330644183316, -8.823026751969598],
              [-40.172580686189129, -8.81990791690037],
              [-40.172241432753616, -8.816289635006846],
              [-40.173306188852123, -8.811102343185624],
              [-40.177370090913861, -8.811606271881161],
              [-40.179362048816927, -8.804932740248505],
              [-40.179962000426038, -8.802867548417431],
              [-40.179809111629318, -8.80158033454428],
              [-40.176087394544851, -8.794953042691496],
              [-40.177574344328718, -8.791297503605557],
              [-40.177521087319867, -8.790593683530025],
              [-40.177854745510579, -8.789989717227131],
              [-40.178355639589249, -8.787037502318119],
              [-40.180074567683889, -8.786871266748271],
              [-40.184964939840867, -8.788647738972227],
              [-40.193961995264118, -8.788087709944653],
              [-40.195626200492455, -8.788209228704654],
              [-40.197053216540638, -8.787012463694021],
              [-40.197742986858024, -8.786839261269931],
              [-40.198555571862705, -8.784902006902216],
              [-40.19894073266417, -8.784330989457338],
              [-40.199476087180834, -8.78402457665047],
              [-40.199786728661429, -8.783274775149311],
              [-40.200849255671464, -8.78275387600981],
              [-40.205350055920476, -8.784130068155605],
              [-40.208849711721356, -8.786036746442139],
              [-40.210542567958178, -8.78478343698008],
              [-40.218015987069968, -8.776257301550192],
              [-40.218136508061434, -8.75475046797424],
              [-40.235911241725283, -8.761393603895739],
              [-40.236484634332186, -8.76068182640693],
              [-40.236514844294064, -8.759341576991927],
              [-40.236844686537786, -8.758708588847801],
              [-40.237840836220649, -8.75795422496549],
              [-40.238842813303364, -8.757838821484409],
              [-40.239832938697901, -8.757462215466228],
              [-40.240556654106712, -8.756916289172182],
              [-40.240561553251496, -8.756711645330604],
              [-40.240452610421464, -8.756246790857993],
              [-40.239235071862389, -8.754060867132493],
              [-40.237423024083448, -8.754599318526154],
              [-40.236101002347617, -8.752308729301973],
              [-40.235705927299648, -8.752097586150994],
              [-40.234474937211466, -8.752910198501281],
              [-40.233129423091633, -8.751246590721225],
              [-40.231923902635131, -8.747702538672231],
              [-40.230938396452721, -8.74093453000793],
              [-40.227344328995933, -8.737079176658545],
              [-40.227117129571901, -8.737097067361271],
              [-40.22650799185562, -8.737078030942326],
              [-40.226203898186412, -8.736848330976002],
              [-40.2261035559006, -8.736835378962375],
              [-40.225413706570428, -8.735876306779367],
              [-40.224237239943207, -8.734580214078534],
              [-40.22391908728541, -8.734450259537256],
              [-40.223590679391918, -8.73352678046621],
              [-40.223559390526049, -8.732478210358016],
              [-40.22340532004425, -8.731975134064729],
              [-40.222326584758939, -8.730648133624179],
              [-40.221866166262018, -8.730329866023347],
              [-40.221417317876615, -8.729790833678793],
              [-40.221135645026074, -8.72958885257586],
              [-40.220749743723211, -8.729596091201879],
              [-40.220197712416251, -8.729795964596979],
              [-40.219807482967504, -8.730005355077992],
              [-40.219360147694658, -8.730471727058967],
              [-40.219028752803638, -8.730715649395782],
              [-40.218985611028543, -8.730804280858198],
              [-40.21866336813688, -8.730975395613967],
              [-40.218388384148774, -8.730877542928983],
              [-40.218059072764831, -8.73052335464426],
              [-40.217814384863594, -8.730082515921822],
              [-40.216215913127002, -8.730008601959272],
              [-40.216049236444569, -8.729836781785423],
              [-40.216067472471401, -8.729659538801867],
              [-40.216257828577632, -8.729155096814198],
              [-40.216226709096539, -8.728810028735589],
              [-40.216433352691212, -8.728761347188797],
              [-40.217528452165269, -8.728593548081315],
              [-40.217920585720883, -8.728246148381148],
              [-40.218272743535891, -8.727922544512102],
              [-40.219110308580852, -8.726875588145912],
              [-40.219709928587534, -8.726637644006795],
              [-40.220357138172012, -8.726501540333702],
              [-40.221123319070003, -8.726196971714396],
              [-40.221128078103014, -8.726120829148391],
              [-40.221128078609141, -8.725540243426314],
              [-40.221142356185332, -8.724764543818216],
              [-40.221151022015533, -8.723742363596989],
              [-40.221221750663631, -8.723577563588449],
              [-40.221323195005162, -8.723517714013926],
              [-40.221603969394828, -8.723108448566917],
              [-40.221461025473275, -8.723020043942711],
              [-40.218805589614639, -8.722402139141126],
              [-40.216407362555124, -8.722242719972924],
              [-40.21586981132053, -8.722243500504883],
              [-40.214442687417595, -8.722130365912953],
              [-40.213908401095296, -8.722205704026722],
              [-40.213131914338689, -8.722431665081048],
              [-40.212740709228299, -8.72226098670756],
              [-40.212507576124281, -8.721959560208724],
              [-40.209439232249665, -8.729272105268592],
              [-40.208007415125522, -8.732891942593625],
              [-40.208008505981539, -8.735340885767689],
              [-40.207946036517782, -8.736374058880596],
              [-40.205809360836064, -8.738269146335838],
              [-40.205289031710464, -8.739153350894421],
              [-40.203172201477358, -8.739229783634167],
              [-40.200949947612663, -8.730560424667313],
              [-40.198114992113823, -8.730979804852463],
              [-40.196992015498324, -8.731536441739706],
              [-40.19635879089757, -8.732727451467097],
              [-40.189660928221201, -8.742606259896876],
              [-40.178289089365983, -8.748982929061329],
              [-40.156620872708018, -8.732449119756279],
              [-40.163573444734418, -8.716624311188891],
              [-40.169287960083778, -8.710399044297601],
              [-40.172933023513721, -8.708117750329604],
              [-40.182244830172444, -8.702443395414509],
              [-40.188114140109995, -8.698904264466282],
              [-40.192882622311799, -8.702795638572097],
              [-40.199689179634632, -8.708524167057375],
              [-40.213372389878948, -8.719877363713181],
              [-40.212804289619484, -8.721300570186015],
              [-40.21733484571169, -8.721491589014272],
              [-40.220414986062877, -8.721572270215113],
              [-40.221016485934364, -8.721474343286822],
              [-40.221391248148308, -8.721658750568805],
              [-40.221652987128145, -8.721872900585355],
              [-40.221994437357729, -8.722307150428017],
              [-40.222922420442202, -8.722783040541541],
              [-40.224570492941851, -8.723879055775285],
              [-40.224883802804499, -8.72381308741805],
              [-40.225110334425779, -8.724721567484536],
              [-40.224959817223365, -8.725016153691518],
              [-40.225051186848987, -8.725858478015017],
              [-40.225429518277586, -8.726914953377293],
              [-40.226342175488867, -8.729661721659433],
              [-40.226618770583705, -8.730770974636759],
              [-40.227154610295031, -8.732530451168095],
              [-40.22779323880566, -8.734840234682785],
              [-40.231306069915661, -8.738600606496231],
              [-40.232063398324193, -8.738928790298852],
              [-40.232670156554533, -8.739523652466513],
              [-40.232884305711551, -8.739916261436179],
              [-40.23307942883585, -8.740498929316997],
              [-40.234636324635943, -8.747701775023472],
              [-40.237287126144132, -8.74647139625848],
              [-40.237724754182828, -8.747496295855138],
              [-40.243259338630558, -8.744966157917247],
              [-40.243442800394348, -8.746054326937513],
              [-40.244088165522918, -8.747600051776722],
              [-40.243980535130952, -8.74891025229314],
              [-40.247205294364548, -8.751404272017913],
              [-40.248888268578931, -8.751403322480552],
              [-40.249840114404186, -8.75084188047116],
              [-40.251972611253464, -8.751089618920595],
              [-40.254114277163872, -8.752337374738797],
              [-40.254166806659143, -8.752493795902192],
              [-40.258445974142802, -8.753123700056804],
              [-40.260228879921492, -8.752823129296065],
              [-40.261374018541609, -8.75323619495907],
              [-40.265574394868096, -8.753536801903351],
              [-40.266610315242694, -8.751677396284073],
              [-40.26680555314951, -8.750222001658834],
              [-40.26302855068208, -8.746693062286523],
              [-40.258435087258626, -8.742789646324304],
              [-40.259482829205218, -8.74246596005646],
              [-40.259147125986438, -8.739685108450379],
              [-40.269752212087589, -8.749572373873669],
              [-40.27020561491269, -8.74957304726256],
              [-40.280655428227696, -8.746751112222015],
              [-40.284093483541909, -8.738313519766587],
              [-40.283869305518486, -8.735559291101634],
              [-40.284128701391303, -8.734771464768176],
              [-40.283860597094652, -8.7338252798714],
              [-40.286851217895851, -8.730696545099494],
              [-40.289720075152751, -8.728022095660249],
              [-40.291892159022055, -8.726795722452982],
              [-40.293961033562951, -8.729063218456005],
              [-40.294786847112874, -8.729831196840005],
              [-40.298216380607975, -8.733841970194641],
              [-40.300865590486936, -8.7367957298368],
              [-40.30250307636117, -8.738702060509302],
              [-40.304001273932393, -8.736352170050738],
              [-40.306939446010468, -8.731047247494351],
              [-40.307222607473562, -8.730538730605687],
              [-40.307485916778823, -8.730369733786043],
              [-40.310688753787133, -8.727602271155634],
              [-40.311081404965805, -8.727687075017128],
              [-40.311434574317722, -8.727889820179719],
              [-40.311562052206625, -8.728140651876091],
              [-40.311604325492084, -8.728619982210823],
              [-40.311751490075039, -8.729063389838803],
              [-40.311980233382769, -8.729168468444339],
              [-40.315177616101295, -8.729067091781236],
              [-40.319158614720791, -8.72852849226018],
              [-40.330583394286151, -8.729118386819595],
              [-40.330945198013836, -8.72882280879813],
              [-40.329306643070318, -8.722419029467437],
              [-40.329511395916086, -8.722100764438082],
              [-40.32981777770447, -8.722179295260371],
              [-40.330285314364723, -8.722258871216141],
              [-40.330345387596296, -8.722657617020925],
              [-40.330366043025634, -8.723145533289145],
              [-40.330160751994882, -8.723679755101017],
              [-40.330458613482705, -8.724170553592757],
              [-40.330501321098168, -8.724268801076002],
              [-40.330398313486171, -8.724649385842268],
              [-40.330270837198789, -8.724854279577359],
              [-40.3307583693474, -8.72535938915258],
              [-40.331695720762667, -8.725391868780276],
              [-40.332864949876601, -8.725057636674906],
              [-40.333019545801349, -8.725050728847549],
              [-40.333377588043824, -8.725340551524081],
              [-40.334271448881822, -8.725237652877015],
              [-40.334350946963959, -8.725651708612649],
              [-40.334371499265416, -8.726286604053],
              [-40.335290013489505, -8.727833098133841],
              [-40.337808882714782, -8.730301015408998],
              [-40.338352869993059, -8.731349817971187],
              [-40.338700867845979, -8.733308631237545],
              [-40.339152876532516, -8.733482562649087],
              [-40.340502690970283, -8.730191754356042],
              [-40.340245035430215, -8.729439258372356],
              [-40.34675232575885, -8.726194744281669],
              [-40.345061502983164, -8.730311996624676],
              [-40.353385656029374, -8.738019120426024],
              [-40.353744908237168, -8.73719686336772],
              [-40.358319946542387, -8.731146400403935],
              [-40.358725684966892, -8.729704493875747],
              [-40.361036526922881, -8.729575480603094],
              [-40.365281928228917, -8.728446621636023],
              [-40.364817228615308, -8.724858813438939],
              [-40.374554100677713, -8.728140257389734],
              [-40.38099759576636, -8.733706345635669],
              [-40.382967825229606, -8.732296385279842],
              [-40.383039101897921, -8.73210621641209],
              [-40.383627179060092, -8.732937154244288],
              [-40.384073228555792, -8.733378819144706],
              [-40.385844024367692, -8.7343054944635],
              [-40.386011223967586, -8.73456987212035],
              [-40.389877022573359, -8.737282405205315],
              [-40.392842630110522, -8.738123453044045],
              [-40.392368810974062, -8.737493251179508],
              [-40.392899605472024, -8.737256437972485],
              [-40.393632439563135, -8.737013840973203],
              [-40.39559454440176, -8.738667875367215],
              [-40.393674256139604, -8.740587089902048],
              [-40.393358366621122, -8.741415454966797],
              [-40.392811301028168, -8.741890254820529],
              [-40.392210761001451, -8.74214545686346],
              [-40.391113517546458, -8.743426915700756],
              [-40.39139102349592, -8.745834404395387],
              [-40.389366046316027, -8.747353680089374],
              [-40.382173747254988, -8.762541491395908],
              [-40.381650428177089, -8.764517193827205],
              [-40.384831863111273, -8.771145478314669],
              [-40.385782535815189, -8.773022271587712],
              [-40.387660975361953, -8.776798742987619],
              [-40.386526338002888, -8.777128346146027],
              [-40.388638459129602, -8.78293156835811],
              [-40.389394382124571, -8.782615099796113],
              [-40.390747371771134, -8.782497503636504],
              [-40.391351911472164, -8.782580908694543],
              [-40.391901533930501, -8.781104922471236],
              [-40.392639403930644, -8.780926152119324],
              [-40.393236225163427, -8.780467181219654],
              [-40.393465657303594, -8.780758155783774],
              [-40.393567186198986, -8.781206642350996],
              [-40.393462233601767, -8.781582190148322],
              [-40.39271607209875, -8.782397636913871],
              [-40.392732656558941, -8.782878887222918],
              [-40.392948802704652, -8.783268868219036],
              [-40.395133148626179, -8.78532465156739],
              [-40.397853543034174, -8.782576770385132],
              [-40.399053145707001, -8.781377164173902],
              [-40.401790760120463, -8.7796222754244],
              [-40.403091083197253, -8.778300605986823],
              [-40.403315403326054, -8.776373940012512],
              [-40.402907103459803, -8.776055437447219],
              [-40.403497673314774, -8.774939895232734],
              [-40.407248424023813, -8.777302899217617],
              [-40.409797353360212, -8.777135106381925],
              [-40.414321216098358, -8.777875122165339],
              [-40.417507088187236, -8.777479253166888],
              [-40.418857687450014, -8.775549908186589],
              [-40.420989825778335, -8.773023321243818],
              [-40.42417121800483, -8.772509285904414],
              [-40.427355514754765, -8.77137873503195],
              [-40.428030412113486, -8.771295401088215],
              [-40.428138744326681, -8.771329538990743],
              [-40.428955102000131, -8.770902093104025],
              [-40.429351993534418, -8.770553579408233],
              [-40.429853295017736, -8.770570405750421],
              [-40.4305048087787, -8.770215916310043],
              [-40.430704801580035, -8.769990508750938],
              [-40.430592204576051, -8.769752550990061],
              [-40.432340387269605, -8.768724177291748],
              [-40.432677004305241, -8.768284499055731],
              [-40.432205887082681, -8.767731633752289],
              [-40.432158932757503, -8.767337490926316],
              [-40.432450850908928, -8.765984531783841],
              [-40.431610754924812, -8.765816688995622],
              [-40.432546724426132, -8.764635448449116],
              [-40.432735488021471, -8.764733370063091],
              [-40.432916336789859, -8.764697390422802],
              [-40.434052023788915, -8.7636381313645],
              [-40.43460284889683, -8.76368879655929],
              [-40.435167103984625, -8.763655832235758],
              [-40.43567371036503, -8.763747952159077],
              [-40.436078742555594, -8.763725611637264],
              [-40.437527979787433, -8.764459579774359],
              [-40.436245601289109, -8.766113604362928],
              [-40.434889964463039, -8.767585189912852],
              [-40.434618460878497, -8.768131705605315],
              [-40.434518804882224, -8.768918289098441],
              [-40.434679816996052, -8.769783391377565],
              [-40.434409858531005, -8.770181768903829],
              [-40.434058253811365, -8.770381287476418],
              [-40.435424854647749, -8.772218750342558],
              [-40.436045954362555, -8.772158384222971],
              [-40.442768904123923, -8.771342677664579],
              [-40.445455337688578, -8.770971454091319],
              [-40.466521334788219, -8.768472446488342],
              [-40.48570607580568, -8.766267067595447],
              [-40.497014259751829, -8.763010417736369],
              [-40.500425241786587, -8.762058765051787],
              [-40.505756089182938, -8.760247738864235],
              [-40.509141942464176, -8.75581131671828],
              [-40.510248808675293, -8.754750260638913],
              [-40.510998036779533, -8.754373362407261],
              [-40.513458368910072, -8.754036881439202],
              [-40.523259062808933, -8.748046365600915],
              [-40.524926755411812, -8.74550418632278],
              [-40.529667765142236, -8.741182405708814],
              [-40.530206934960162, -8.740524213872675],
              [-40.530267342599409, -8.740455305312057],
              [-40.530362519836196, -8.740568329760421],
              [-40.531371244594986, -8.741939605940624],
              [-40.531371243741397, -8.742910420491301],
              [-40.531763850753855, -8.744330950859585],
              [-40.532898842804656, -8.747778771496492],
              [-40.532827907230477, -8.747857146994829],
              [-40.533095974640382, -8.748675869633427],
              [-40.532976073813188, -8.751702184545334],
              [-40.534415163560134, -8.756490004932976],
              [-40.535366316301463, -8.757199170783048],
              [-40.537521037651814, -8.759039570335968],
              [-40.538823354091242, -8.760716365064537],
              [-40.540422928008134, -8.762902729194762],
              [-40.5449981492999, -8.766078521373654],
              [-40.546817279709408, -8.769370655498113],
              [-40.547583896282376, -8.772861544591244],
              [-40.547778117436152, -8.773418629637025],
              [-40.547609724206531, -8.773876185924156],
              [-40.547198155762572, -8.774231973569535],
              [-40.546566350047257, -8.774369547246097],
              [-40.5464549126186, -8.774602635841688],
              [-40.546416278106214, -8.775488135842542],
              [-40.54658405811314, -8.776420132763185],
              [-40.546273300847524, -8.780306480700885],
              [-40.546200784896094, -8.781955653391146],
              [-40.546429521526264, -8.782832216676704],
              [-40.546784644496576, -8.785720522794888],
              [-40.547490180292122, -8.787172777271051],
              [-40.547906437656088, -8.789132531649717],
              [-40.547861624585927, -8.78956515518548],
              [-40.547837665314809, -8.791191049050907],
              [-40.54794724941231, -8.792477618935481],
              [-40.548202416167705, -8.794133262879223],
              [-40.547862999253418, -8.796710803135889],
              [-40.547846374127062, -8.798114091256771],
              [-40.548085895391814, -8.799316617727468],
              [-40.548018254464033, -8.799767728140147],
              [-40.54858190234868, -8.801105074139677],
              [-40.550009087855635, -8.802843231650556],
              [-40.551064044546031, -8.803918079552613],
              [-40.551654897857048, -8.804559567476305],
              [-40.552821758762285, -8.806767973126146],
              [-40.553493778732495, -8.80828326422612],
              [-40.554954811205789, -8.80866433637282],
              [-40.556345447209729, -8.809000741708209],
              [-40.55796441814293, -8.808749245537372],
              [-40.560101992380247, -8.808587817316912],
              [-40.560327929780208, -8.80861545336035],
              [-40.560479315579258, -8.808779356575011],
              [-40.560896037693198, -8.809884661749239],
              [-40.561103691780673, -8.810408477997365],
              [-40.561346153974988, -8.81053812148398],
              [-40.56227305242961, -8.810630645779087],
              [-40.56315754219959, -8.810701183160191],
              [-40.56871794806451, -8.809984833052184],
              [-40.568921010765273, -8.809853423966322],
              [-40.569060811077136, -8.8095303608984],
              [-40.569217072219217, -8.809269235498023],
              [-40.569235443247202, -8.809093283499603],
              [-40.56941390107891, -8.809007624135941],
              [-40.569827925306242, -8.808879134218889],
              [-40.570134873280097, -8.808750644207874],
              [-40.570534619575312, -8.808657846078372],
              [-40.57084870642349, -8.808615015593892],
              [-40.57155254654792, -8.808579325411415],
              [-40.571909463072181, -8.808565048804585],
              [-40.574093792640745, -8.808793477442126],
              [-40.575507182635072, -8.808964798723137],
              [-40.576592208760502, -8.809314578716592],
              [-40.578342527690651, -8.810292532776593],
              [-40.579577458448234, -8.811006368863541],
              [-40.581254965943231, -8.811920078220188],
              [-40.582304300805646, -8.811720205260629],
              [-40.584460078163175, -8.811286195583543],
              [-40.587158368899281, -8.810672300389797],
              [-40.589706767484415, -8.810279755825039],
              [-40.591068952249735, -8.809986896660659],
              [-40.591782226667199, -8.809548497741282],
              [-40.595164057431852, -8.805789399755334],
              [-40.596595990412844, -8.803578747388693],
              [-40.597235199228358, -8.802146149970342],
              [-40.597319007946076, -8.800677930264403],
              [-40.599571879933421, -8.795388898854881],
              [-40.602014865618635, -8.791201124446424],
              [-40.602279617428849, -8.790461373820943],
              [-40.603036666799511, -8.78521347987339],
              [-40.604074912462266, -8.778547524117601],
              [-40.604095606743492, -8.774673392836768],
              [-40.604590740684934, -8.773099103088773],
              [-40.604475325237487, -8.769988940411439],
              [-40.604736566108805, -8.76920603902591],
              [-40.604362969579427, -8.763694455579705],
              [-40.604807259462383, -8.760319828877115],
              [-40.607910316876222, -8.760280492807707],
              [-40.608232682891774, -8.75998406175029],
              [-40.608294193019304, -8.759353949334709],
              [-40.608299147110877, -8.758077741490171],
              [-40.608086420331411, -8.757240833994159],
              [-40.607731735471155, -8.756483005931111],
              [-40.606850576445318, -8.755536661465076],
              [-40.606826614246771, -8.754817288475568],
              [-40.606111578499082, -8.753376844924057],
              [-40.606326828838569, -8.752425032446263],
              [-40.606825965235011, -8.751433556438499],
              [-40.607183900413823, -8.750670503027415],
              [-40.607632891142096, -8.750833249507012],
              [-40.608821896094305, -8.749430767404609],
              [-40.609282885598041, -8.748618978356504],
              [-40.609552423066958, -8.747090734157016],
              [-40.609768645243626, -8.746315512690634],
              [-40.609743807759507, -8.745926409849858],
              [-40.609600082071587, -8.74557374870119],
              [-40.609169192821973, -8.744724233087126],
              [-40.608557859826739, -8.743877838298383],
              [-40.608210128692647, -8.74337461481603],
              [-40.608845093462158, -8.739748310697236],
              [-40.608856940020502, -8.739020359788382],
              [-40.606245220234534, -8.732181238857375],
              [-40.605523429989852, -8.730218032237254],
              [-40.605237953357069, -8.729787911400729],
              [-40.6041748595404, -8.728904639428547],
              [-40.6025949110889, -8.727152273880925],
              [-40.600186369855763, -8.72375493883963],
              [-40.592854463590733, -8.719602332759111],
              [-40.589543137425402, -8.714178679312008],
              [-40.588905199367446, -8.713612631934176],
              [-40.58770642564037, -8.712564763773141],
              [-40.586888277934435, -8.71188903785462],
              [-40.586413925037341, -8.710979979544193],
              [-40.585578435474652, -8.707997565556525],
              [-40.584722063923387, -8.706308156295131],
              [-40.583347880796154, -8.704828914405573],
              [-40.579762846776411, -8.701409111492845],
              [-40.578212504083588, -8.699693475780169],
              [-40.576459084867778, -8.694479649533838],
              [-40.575070924128461, -8.690380645348657],
              [-40.568693035691545, -8.691397474115691],
              [-40.566559975287078, -8.691708856519874],
              [-40.560960642863485, -8.692228413753583],
              [-40.558468944007231, -8.692486777144971],
              [-40.557110336835869, -8.693221441842057],
              [-40.556835091574833, -8.694570390026673],
              [-40.556454508337922, -8.697873785142878],
              [-40.556468341610724, -8.700879602273305],
              [-40.556138173340685, -8.701532767318918],
              [-40.542162321728441, -8.70045347227129],
              [-40.540859688253654, -8.699948427980038],
              [-40.540246573853572, -8.699577384533459],
              [-40.539945576500514, -8.70352187711396],
              [-40.536336828028993, -8.704249263900598],
              [-40.534739544388536, -8.699426169613259],
              [-40.533742352454247, -8.699298636466178],
              [-40.533830964949793, -8.696882257119812],
              [-40.528152220537841, -8.694612481958671],
              [-40.530272667804937, -8.688827853302872],
              [-40.521690391879964, -8.681704688923856],
              [-40.52042562362562, -8.680645546785735],
              [-40.522470002917657, -8.677721878989747],
              [-40.520111600870003, -8.670262948895923],
              [-40.520509817650336, -8.666179720203301],
              [-40.520748118950351, -8.663926993078849],
              [-40.522031275061799, -8.660411912956086],
              [-40.512212191767944, -8.656710364318062],
              [-40.504214051620295, -8.65549043629343],
              [-40.499422947676116, -8.654763220260227],
              [-40.48876760438835, -8.653186257963494],
              [-40.465724972228948, -8.649691105819503],
              [-40.444977262754094, -8.646589813455247],
              [-40.435201806311063, -8.645087705521693],
              [-40.429129357459288, -8.644228989146246],
              [-40.431814968457402, -8.643361767339949],
              [-40.435253037645111, -8.639676394062187],
              [-40.435306860419516, -8.639503114084999],
              [-40.431804660989961, -8.634753017854024],
              [-40.427817048180408, -8.636663700662464],
              [-40.427585369014565, -8.636414019871207],
              [-40.424570243547159, -8.638172327372365],
              [-40.423153573183157, -8.639623711974933],
              [-40.42129716714539, -8.639626694365115],
              [-40.420242215136234, -8.641215683502038],
              [-40.417607523323817, -8.639154096863606],
              [-40.412487500536344, -8.634013905454907],
              [-40.406865004367738, -8.635679252242904],
              [-40.407369400798714, -8.641504816825426],
              [-40.405275121491201, -8.650660815405532],
              [-40.401761425799286, -8.649782557339337],
              [-40.39994800227327, -8.651160371013827],
              [-40.399989416527056, -8.655145232583717],
              [-40.399938256505926, -8.656733785584441],
              [-40.3966220239843, -8.657529628247964],
              [-40.395767274025772, -8.656297257461166],
              [-40.393890396547491, -8.653521479498648],
              [-40.393101059506897, -8.651856240210103],
              [-40.39289389316837, -8.651609513423361],
              [-40.391467592303385, -8.650260929803093],
              [-40.390254144393047, -8.649556104735874],
              [-40.389330779869539, -8.649527627103762],
              [-40.389575862409863, -8.649236063958373],
              [-40.389545823130263, -8.648647147253646],
              [-40.3874544362804, -8.645720038966664],
              [-40.381711701200935, -8.648268286666228],
              [-40.373812212950249, -8.648794429919358],
              [-40.372907995336988, -8.645254119115114],
              [-40.372256733036437, -8.643928829033259],
              [-40.369918890381747, -8.645076569552984],
              [-40.36961644498443, -8.643357404992953],
              [-40.366616616572209, -8.64148509998498],
              [-40.364087598813384, -8.643143393459733],
              [-40.357760226082682, -8.644251469781963],
              [-40.353758448477251, -8.643766595087426],
              [-40.353808516532943, -8.642142580295738],
              [-40.348358992987102, -8.642886197427998],
              [-40.347606337691467, -8.642375100667701],
              [-40.347513378825361, -8.641815871744525],
              [-40.347775837244001, -8.641522831623471],
              [-40.347581816012323, -8.641046860143833],
              [-40.347557136687101, -8.640466380399205],
              [-40.347087912529531, -8.63981691766319],
              [-40.346844265298671, -8.639741001882957],
              [-40.346479183422865, -8.639080075301004],
              [-40.34593152602627, -8.638184688886254],
              [-40.345867998690657, -8.63793007926912],
              [-40.344617975172689, -8.638227858517428],
              [-40.343772459236284, -8.638052020785832],
              [-40.338727946070307, -8.638557116613706],
              [-40.337076657844811, -8.639449435161579],
              [-40.336450646953637, -8.639759892872585],
              [-40.336171772687727, -8.640009485224594],
              [-40.336648204744286, -8.640717007638251],
              [-40.335844379562019, -8.642801098183806],
              [-40.335053717329821, -8.644836114017886],
              [-40.332342658784484, -8.644262062935328],
              [-40.331730663877359, -8.644477906192341],
              [-40.331355501657072, -8.64429735889016],
              [-40.32983264210322, -8.644532407258188],
              [-40.328727897944944, -8.644398362068369],
              [-40.323880136546414, -8.645902539421629],
              [-40.327345837134892, -8.649568952692437],
              [-40.327528581132505, -8.651560355607156],
              [-40.329304838789177, -8.654932698094557],
              [-40.329649262011124, -8.655359033983634],
              [-40.328006864088593, -8.655830572912922],
              [-40.328145950776069, -8.657376744305063],
              [-40.327142937439376, -8.657782250155549],
              [-40.326366866329096, -8.658402908986345],
              [-40.325705862221447, -8.659245827661339],
              [-40.32279559590738, -8.656555377350472],
              [-40.318998939187573, -8.653409204854947],
              [-40.318178876050432, -8.652107851498936],
              [-40.318226696921137, -8.651870883877834],
              [-40.318229625913233, -8.651856365962313],
              [-40.318215137524838, -8.651267009277415],
              [-40.318215137878347, -8.650858696582768],
              [-40.31816279055348, -8.650501778553098],
              [-40.317667866616674, -8.649806980051819],
              [-40.31641532887943, -8.64807188577544],
              [-40.316110760438065, -8.647576960309156],
              [-40.315220850215084, -8.645782855677762],
              [-40.315120913058209, -8.64561629453272],
              [-40.314821103258183, -8.645497321947673],
              [-40.313807461579287, -8.644074411114914],
              [-40.313927978854132, -8.64393814098775],
              [-40.313896905110859, -8.643892621218184],
              [-40.31388150350147, -8.643565609051791],
              [-40.313994438932959, -8.643309564594528],
              [-40.314106497669037, -8.642708430085454],
              [-40.314062320227244, -8.64242029567489],
              [-40.313821535775844, -8.641420731113893],
              [-40.313738673984808, -8.640692811154116],
              [-40.313448702531097, -8.64009365728981],
              [-40.31303644094244, -8.639483326384045],
              [-40.311805056426323, -8.638476224018248],
              [-40.311503933963536, -8.637977661576596],
              [-40.311303864446259, -8.637881339949429],
              [-40.311095040040321, -8.637727043642247],
              [-40.31091030948452, -8.637551179477496],
              [-40.310498620571984, -8.637310818482851],
              [-40.310348397652, -8.637276988545372],
              [-40.309960365510562, -8.637110088156051],
              [-40.309132971787946, -8.636543405649086],
              [-40.308667664178778, -8.636386046139814],
              [-40.307860245104557, -8.636064606264739],
              [-40.30703520433832, -8.635529471581805],
              [-40.306704173929099, -8.635211011097644],
              [-40.306228832523182, -8.63489975644937],
              [-40.305835883562409, -8.634563562012461],
              [-40.30545853453868, -8.634173969890698],
              [-40.305150052874644, -8.63396787979114],
              [-40.304948650320007, -8.633925674857297],
              [-40.304169460753087, -8.633946227074619],
              [-40.303672664261555, -8.633907903866223],
              [-40.303316027835024, -8.633816980074856],
              [-40.302821166505325, -8.633575664014954],
              [-40.302095111054712, -8.633031295469621],
              [-40.301937701276408, -8.632891797123165],
              [-40.301445813036665, -8.633680176248072],
              [-40.301011247521792, -8.634322794245577],
              [-40.300468757843475, -8.634870999684244],
              [-40.294872568166795, -8.631198544649456],
              [-40.287214931576187, -8.622121763407808],
              [-40.28505386900553, -8.622924203026242],
              [-40.28221636297674, -8.623949435807292],
              [-40.28037815726401, -8.624617279461978],
              [-40.279073347043614, -8.625024531045259],
              [-40.274600763312485, -8.626219877467488],
              [-40.270326731231592, -8.627371417306433],
              [-40.265727129430218, -8.628524223859678],
              [-40.266061789365459, -8.633997775199271],
              [-40.265515700179542, -8.637482646031627],
              [-40.266093018039896, -8.637597018878743],
              [-40.269500727296702, -8.638629718955221],
              [-40.272796199442865, -8.639543221610504],
              [-40.276309970914035, -8.640628192484332],
              [-40.28011854923048, -8.641820355005848],
              [-40.28268985252852, -8.642639645576676],
              [-40.28376107482147, -8.642883636114808],
              [-40.285766956517634, -8.643192967083852],
              [-40.284619318499473, -8.650188267578393],
              [-40.284806428077914, -8.650317908006281],
              [-40.28544886360433, -8.650292500700164],
              [-40.285884648687251, -8.650139428938266],
              [-40.286539468034533, -8.650220922038825],
              [-40.287730987862666, -8.6523407510846],
              [-40.288660563260045, -8.654764720188966],
              [-40.288767049086282, -8.655259860483486],
              [-40.288815333375652, -8.655690442092734],
              [-40.289313579638474, -8.655980057884046],
              [-40.289419636515341, -8.656420099489523],
              [-40.289157974230434, -8.656553938509225],
              [-40.288917727856258, -8.656617787942617],
              [-40.288677419595601, -8.656551071113444],
              [-40.288538139685585, -8.656417781746885],
              [-40.288354932660141, -8.656772937583785],
              [-40.28826316268345, -8.657487622468404],
              [-40.287988629075592, -8.659253195260892],
              [-40.287800248104169, -8.660742153689846],
              [-40.287635625561528, -8.661543963020204],
              [-40.287512748185492, -8.661816312376626],
              [-40.287241092803072, -8.662143377296426],
              [-40.28705605656603, -8.662757285670551],
              [-40.286941882849547, -8.663352209212727],
              [-40.287997539863163, -8.664043602213864],
              [-40.288505295354852, -8.663991915941445],
              [-40.288929668373662, -8.664005280230093],
              [-40.289719912553025, -8.664340836013276],
              [-40.28992114807334, -8.665577214204037],
              [-40.290070594699337, -8.667109469679868],
              [-40.288912292081569, -8.667943602975408],
              [-40.287762237158546, -8.669063296665508],
              [-40.287006804267847, -8.669267572863388],
              [-40.286455720195022, -8.669186000850878],
              [-40.286310149353902, -8.669024334638824],
              [-40.285060493816658, -8.668196652239972],
              [-40.284766888718963, -8.667218877509596],
              [-40.281728452755992, -8.668634017909799],
              [-40.277025062796184, -8.670799404643178],
              [-40.27630018758007, -8.671133282135283],
              [-40.276181571322162, -8.671234894459552],
              [-40.27613031156578, -8.671367736665323],
              [-40.276019782299812, -8.67141333631292],
              [-40.275857046499056, -8.671343886565412],
              [-40.274652351161023, -8.671078420030396],
              [-40.273475288644818, -8.669642131131985],
              [-40.270587697406448, -8.66785359675599],
              [-40.269321742403015, -8.667697408551925],
              [-40.268838061733959, -8.667760469780715],
              [-40.268480652264977, -8.667726749664473],
              [-40.260466570395025, -8.66422855055262],
              [-40.260228465114011, -8.664168397688305],
              [-40.259545164665596, -8.664436144589525],
              [-40.25929788926868, -8.664583885542735],
              [-40.25809007381514, -8.66678787405421],
              [-40.257981063197207, -8.667227762475356],
              [-40.257029966602033, -8.672136242928245],
              [-40.258830923411431, -8.676267683135963],
              [-40.260409725443573, -8.679627892512402],
              [-40.260642333196536, -8.680340829685603],
              [-40.261233631229892, -8.681293951810584],
              [-40.263310810201212, -8.681855205425768],
              [-40.267240909719057, -8.682972776492313],
              [-40.269721756671061, -8.683758254189497],
              [-40.269485917214972, -8.685033086788341],
              [-40.267689138133441, -8.685792034958128],
              [-40.265524951458872, -8.686741307737114],
              [-40.266219345789402, -8.687672080078636],
              [-40.267119959761942, -8.688504291154265],
              [-40.268914165723864, -8.690205796090874],
              [-40.270320448234024, -8.69209954960939],
              [-40.268865554314594, -8.692218969683305],
              [-40.268778980335149, -8.692892554806043],
              [-40.268746651596572, -8.694002572511728],
              [-40.268777697858219, -8.694601870155198],
              [-40.268616657418669, -8.696403918843739],
              [-40.2684068396265, -8.696704049968853],
              [-40.268123022461289, -8.696878853737768],
              [-40.267889116069846, -8.696912063348895],
              [-40.267465376825562, -8.697154240617037],
              [-40.266651731891521, -8.696728608319301],
              [-40.266325638229837, -8.696664796398801],
              [-40.265266520815807, -8.695317374093804],
              [-40.264911085840602, -8.694700171272331],
              [-40.264799895836653, -8.694449824590059],
              [-40.264346003828216, -8.693513325486656],
              [-40.264720705542203, -8.693125484998776],
              [-40.264005319762312, -8.692881575760303],
              [-40.263633394557516, -8.692661261684195],
              [-40.263573996585521, -8.691956843610271],
              [-40.263343731441957, -8.69167557200028],
              [-40.262957911668622, -8.691070648083866],
              [-40.262411906302169, -8.690137150885116],
              [-40.2624231027918, -8.689483329586524],
              [-40.261346450858909, -8.688873559103699],
              [-40.259254494060954, -8.688233081921435],
              [-40.259339714809933, -8.687835169241742],
              [-40.259310799073916, -8.687764286617119],
              [-40.259151291518052, -8.687387277609027],
              [-40.25913483474919, -8.687253771348766],
              [-40.259112316091667, -8.687040981531096],
              [-40.259021579831767, -8.686920252134875],
              [-40.259016931960517, -8.686809442756624],
              [-40.258683827620771, -8.686431514604489],
              [-40.257724423373844, -8.686389861014929],
              [-40.257650397907817, -8.686371780053395],
              [-40.25694182796002, -8.686266588038341],
              [-40.25611378101771, -8.685966777019674],
              [-40.255635513403391, -8.685923946850451],
              [-40.252180560343241, -8.68506020375912],
              [-40.251721894890473, -8.688128722033987],
              [-40.25159613476302, -8.689289403370692],
              [-40.251073186106041, -8.691958570846825],
              [-40.246237427789445, -8.691011617036011],
              [-40.248163223710861, -8.687089105846477],
              [-40.248136516149124, -8.686799710458089],
              [-40.247737045444936, -8.686225313359129],
              [-40.245521049040683, -8.684982607474245],
              [-40.243881919719378, -8.684751191372952],
              [-40.242628320246268, -8.683888809167517],
              [-40.242256683644435, -8.682971777028857],
              [-40.242065577903411, -8.681910551859991],
              [-40.241752116410986, -8.680397574138592],
              [-40.242613161457712, -8.679156747888468],
              [-40.242647116604672, -8.678713134423939],
              [-40.242750984328701, -8.678192666453773],
              [-40.242422931933561, -8.677837857176755],
              [-40.242444035593394, -8.676906475457288],
              [-40.24155250668116, -8.674778583715296],
              [-40.245973583179961, -8.66501784566511],
              [-40.243547341659543, -8.66329580231743],
              [-40.243435362081698, -8.662558733387286],
              [-40.242595475630871, -8.661786772030586],
              [-40.241874664930698, -8.660339447593531],
              [-40.241583927048005, -8.660245320876445],
              [-40.241221771032542, -8.659726848496817],
              [-40.23956262973369, -8.657318878687153],
              [-40.238817248648061, -8.657671641054552],
              [-40.237127841891706, -8.658349781773962],
              [-40.236771322215056, -8.658550051336414],
              [-40.235914720625473, -8.660385795225091],
              [-40.235302012629411, -8.661872949293381],
              [-40.234297884887162, -8.663922841845419],
              [-40.233698263891789, -8.665302921530408],
              [-40.232727448721121, -8.667577672840316],
              [-40.232489504016669, -8.668005973215394],
              [-40.231842293411837, -8.669319427236449],
              [-40.230328962780597, -8.674202052342078],
              [-40.230106286389812, -8.674144747474912],
              [-40.229950967500528, -8.674118330490936],
              [-40.227251785582716, -8.671604884381567],
              [-40.224608875054429, -8.669341122911304],
              [-40.223364959741765, -8.668215401208579],
              [-40.22207203290538, -8.669246184272902],
              [-40.221959783169986, -8.668693686300088],
              [-40.222313438969714, -8.668373899411101],
              [-40.219835946284498, -8.664952863628779],
              [-40.218974815793132, -8.664401904006057],
              [-40.217996055429012, -8.663500504264121],
              [-40.216113192109347, -8.661776685398584],
              [-40.212986632106819, -8.655883092051344],
              [-40.211290074236793, -8.657912991118575],
              [-40.211034086142362, -8.657783050634334],
              [-40.210735225226614, -8.657793933316961],
              [-40.21023459655904, -8.657995937745911],
              [-40.20975845078236, -8.658125803603815],
              [-40.209558351233206, -8.658069038753563],
              [-40.209444530117693, -8.657968254226176],
              [-40.20910419180629, -8.65761620992593],
              [-40.206919166201324, -8.655200754718207],
              [-40.206351901809981, -8.654466318387213],
              [-40.205573113836984, -8.653805456459205],
              [-40.205341159505203, -8.653755460543548],
              [-40.20494121321196, -8.653865658578013],
              [-40.204589022713279, -8.653826357499007],
              [-40.201274549554284, -8.653109036721585],
              [-40.198885468162686, -8.650944114926409],
              [-40.197715681038993, -8.650156317383427],
              [-40.197251196876358, -8.649481108806709],
              [-40.196296979408928, -8.648500256540871],
              [-40.195024892300601, -8.648525086312894],
              [-40.194268228585926, -8.648544121558393],
              [-40.193483012046734, -8.648591709617913],
              [-40.192935422856245, -8.648623434970279],
              [-40.191222222770257, -8.648728128913396],
              [-40.186122597283365, -8.648985105100236],
              [-40.185456352911103, -8.649032693261487],
              [-40.180078808342024, -8.649327740995568],
              [-40.177956344118407, -8.649441952535232],
              [-40.173711414713566, -8.649622786884018],
              [-40.16749630598887, -8.649955903687461],
              [-40.166582599336053, -8.650022527529556],
              [-40.162613685968395, -8.650241433899074],
              [-40.161665759644691, -8.650205124294743],
              [-40.161219588968976, -8.649977289203436],
              [-40.160087850823857, -8.648909483282267],
              [-40.158965381218941, -8.648299351778011],
              [-40.157521391951477, -8.647592544546772],
              [-40.156828774472039, -8.647981013755594],
              [-40.155872764029908, -8.648148304991823],
              [-40.155367101774566, -8.647916844870585],
              [-40.154206055409119, -8.647343095127956],
              [-40.153936850534052, -8.647067543458437],
              [-40.153865900373965, -8.646670362646969],
              [-40.15378644436727, -8.645004613012103],
              [-40.153624123295145, -8.644088301062654],
              [-40.152963578621737, -8.64328129906905],
              [-40.152193627339571, -8.642910164513738],
              [-40.151114246233639, -8.642531234738595],
              [-40.149987835570556, -8.642021106666551],
              [-40.14896045829714, -8.641413601230655],
              [-40.148424898695602, -8.640666671004309],
              [-40.14736679084956, -8.63981322993568],
              [-40.14696599331328, -8.640215300316274],
              [-40.146559107821744, -8.640615047704836],
              [-40.146452032394137, -8.64082205944093],
              [-40.144853042994953, -8.64385157091685],
              [-40.144103517050645, -8.645243546393006],
              [-40.142169025599514, -8.648741332930694],
              [-40.141991493908897, -8.648666499202962],
              [-40.141833524073903, -8.648599993444748],
              [-40.140919817807102, -8.64822166080738],
              [-40.141019755266356, -8.648036063943922],
              [-40.142040537628667, -8.646272893801852],
              [-40.141858530009088, -8.646152011331141],
              [-40.14099516759557, -8.645534157093769],
              [-40.143466671049339, -8.641143391073744],
              [-40.136903724502062, -8.637157100058843],
              [-40.135595880132286, -8.636623788965364],
              [-40.125094039844036, -8.629576728950994],
              [-40.120771055748939, -8.626569570315246],
              [-40.116191193112336, -8.621634463395628],
              [-40.111510659175941, -8.627182751955965],
              [-40.107830095313794, -8.631464321548538],
              [-40.106849700605579, -8.629819508038905],
              [-40.105968277891684, -8.630863160371389],
              [-40.104174398876232, -8.62574392987025],
              [-40.102173158586076, -8.626127721991029],
              [-40.0996019797131, -8.61903821300943],
              [-40.097569503520589, -8.613738101345007],
              [-40.096449991863558, -8.61083645985452],
              [-40.094422699685012, -8.606639354945175],
              [-40.092899705948561, -8.603630523724961],
              [-40.0952911251592, -8.60130902392714],
              [-40.097428609384494, -8.599883171831314],
              [-40.099762519906101, -8.604674849623867],
              [-40.100725321999668, -8.606019090814666],
              [-40.10097870221734, -8.607191274379796],
              [-40.101552318285563, -8.607083611477574],
              [-40.102477815805081, -8.607069528343301],
              [-40.104475057737901, -8.607135276666192],
              [-40.116154925330207, -8.604196602252751],
              [-40.119467684507732, -8.604882645174579],
              [-40.119892825547396, -8.6048576916771],
              [-40.120646214803592, -8.604227849889055],
              [-40.121371080101888, -8.603782289031564],
              [-40.12181589820058, -8.603527668853353],
              [-40.129381874241581, -8.601078294168394],
              [-40.132356889026298, -8.595656149444224],
              [-40.133075932128236, -8.591985309874293],
              [-40.132855827151701, -8.591424546868229],
              [-40.134741073770535, -8.584603711157049],
              [-40.135267869051056, -8.584633910432165],
              [-40.135940253947453, -8.585088850421291],
              [-40.137181349589333, -8.584687148751822],
              [-40.149633746500363, -8.579765255221938],
              [-40.149271734426101, -8.57580301938987],
              [-40.150441890123744, -8.573579873991671],
              [-40.151392354937983, -8.56951096887861],
              [-40.153742258594761, -8.565848216646495],
              [-40.154177605849924, -8.564317981696373],
              [-40.155071356345786, -8.563427373505219],
              [-40.156220087357681, -8.56218160354465],
              [-40.156363362412044, -8.551562473921347],
              [-40.156497292562804, -8.54676409226637],
              [-40.156942059643413, -8.546531893959704],
              [-40.157615589430648, -8.545935921910708],
              [-40.158071213717804, -8.545371994495905],
              [-40.158710346370938, -8.545216504917811],
              [-40.159283107330772, -8.545246999228224],
              [-40.16065029862385, -8.545393958548198],
              [-40.161458320133249, -8.545191858377221],
              [-40.162167701741531, -8.544511408837707],
              [-40.162588180218108, -8.544014464012932],
              [-40.163242078895145, -8.543709764294027],
              [-40.16337659123343, -8.543379915279594],
              [-40.163190990214424, -8.542581202655949],
              [-40.16301830510907, -8.540603130736899],
              [-40.14825127798003, -8.535463130462668],
              [-40.1354119940736, -8.527121024021978],
              [-40.13515111876103, -8.52611036754316],
              [-40.135325786708819, -8.5211972845996],
              [-40.135108122945319, -8.520360256171687],
              [-40.131108282191718, -8.509508029471549],
              [-40.130926032575189, -8.509383190027892],
              [-40.131033736964163, -8.509213276083957],
              [-40.131855821425987, -8.507767959988168],
              [-40.132212311269669, -8.507363486586247],
              [-40.132602539731309, -8.507130688236993],
              [-40.136307507592385, -8.504967055644247],
              [-40.13891741127285, -8.502635500085752],
              [-40.140638135227526, -8.501435875347738],
              [-40.145481538676314, -8.504507892957475],
              [-40.146423985871188, -8.503563281116339],
              [-40.152327192486396, -8.509474715489764],
              [-40.156858827594036, -8.506094635499334],
              [-40.159654394882075, -8.502698626140065],
              [-40.159513189014881, -8.501349488686076],
              [-40.159630667054991, -8.49915020027953],
              [-40.160091623788119, -8.498013054127187],
              [-40.163227588446041, -8.498446856295203],
              [-40.167110015593479, -8.494490027033832],
              [-40.166848591140813, -8.493644895615956],
              [-40.166879239177042, -8.490985959748743],
              [-40.167975335349638, -8.48406876397271],
              [-40.167060020008293, -8.476680491194182],
              [-40.169661249954729, -8.472926627708269],
              [-40.171778055822102, -8.473284705445263],
              [-40.171882824096116, -8.473275188587044],
              [-40.172039867253147, -8.472970619449566],
              [-40.172196910593527, -8.472449044545529],
              [-40.172330159889086, -8.472177787198836],
              [-40.172572863506289, -8.47180183453878],
              [-40.173129654256627, -8.471030894386688],
              [-40.173419946765478, -8.470388444281227],
              [-40.173591267963054, -8.469917313102448],
              [-40.173819694623994, -8.469607985051818],
              [-40.174133781661546, -8.469326258915975],
              [-40.174490698390137, -8.469055002756464],
              [-40.174814303476751, -8.468636220407634],
              [-40.175118872793036, -8.468103223692001],
              [-40.175122785947728, -8.467887517921346],
              [-40.175210285622974, -8.467546098935159],
              [-40.175176420754326, -8.466486880927571],
              [-40.174812875496073, -8.463885850408648],
              [-40.174620625851865, -8.462689938046195],
              [-40.174762562973953, -8.45899509016988],
              [-40.174660836906341, -8.45636229605328],
              [-40.174944552233796, -8.45598868841452],
              [-40.174778512130182, -8.455118800221632],
              [-40.174702480668465, -8.455112035196544],
              [-40.174458587497334, -8.454969267804518],
              [-40.17401244183079, -8.45472239983545],
              [-40.173536553395145, -8.454448762993724],
              [-40.173428215298252, -8.454277696875891],
              [-40.173249497153293, -8.453562064795674],
              [-40.173465324566607, -8.452463947219949],
              [-40.17417148504083, -8.450777225400801],
              [-40.16914130375617, -8.449023828171907],
              [-40.166346362393881, -8.444204675248272],
              [-40.166154177754159, -8.444188223181506],
              [-40.165816295997125, -8.444335748060478],
              [-40.164880064007889, -8.444987398572811],
              [-40.164082949382276, -8.445237240486643],
              [-40.163447637562989, -8.44555846512946],
              [-40.1628151808439, -8.445736922586661],
              [-40.161972857745411, -8.445736921883864],
              [-40.160630851118, -8.445772612561134],
              [-40.159736140330402, -8.445839553433858],
              [-40.159608245264067, -8.445794939580942],
              [-40.159521990920993, -8.445732478864308],
              [-40.159319737390625, -8.445670018050846],
              [-40.159199576208543, -8.445698570788165],
              [-40.15816451705232, -8.446183977163395],
              [-40.155958772435412, -8.446269635835844],
              [-40.154966543697071, -8.447090544338085],
              [-40.153557435393942, -8.448488706208643],
              [-40.153051802466358, -8.449047876605771],
              [-40.152682989015638, -8.449089516061129],
              [-40.144888642036129, -8.449802154502731],
              [-40.137803846143733, -8.450450547901907],
              [-40.137224308906987, -8.450805278400004],
              [-40.138172481647679, -8.452722184284498],
              [-40.134739642075523, -8.454661461385397],
              [-40.134247456118302, -8.452769568739125],
              [-40.133857636628143, -8.453033856910022],
              [-40.133676427897647, -8.453034615754463],
              [-40.133275394940426, -8.452759219986799],
              [-40.132957382184927, -8.452740857825894],
              [-40.132658962169664, -8.452867923853832],
              [-40.132048977989839, -8.453408957266237],
              [-40.131734284701025, -8.453996423660838],
              [-40.131293378385926, -8.454478213551436],
              [-40.130785827845195, -8.454595080462848],
              [-40.130031696100311, -8.454631960623578],
              [-40.129749146285455, -8.454389025770086],
              [-40.129261833796967, -8.454275448009623],
              [-40.128442236243671, -8.454629989308144],
              [-40.127643116375111, -8.455188494463123],
              [-40.123435750781589, -8.460042085332665],
              [-40.119600131057588, -8.470261577983022],
              [-40.119515441764321, -8.471313190929541],
              [-40.123639667279086, -8.480283018357493],
              [-40.117399130532711, -8.480874043772396],
              [-40.116364315211968, -8.47918794949706],
              [-40.115150800335535, -8.477274873364165],
              [-40.113690270459642, -8.475043078838835],
              [-40.113606548182808, -8.474881754686637],
              [-40.109110917448689, -8.468071991674428],
              [-40.106241786850823, -8.464175895449621],
              [-40.104235653861437, -8.458306777180111],
              [-40.103817795940991, -8.458129358841283],
              [-40.104541161728868, -8.457107416262099],
              [-40.105620628831176, -8.455121318468379],
              [-40.106822361367705, -8.452788411751019],
              [-40.107044207986227, -8.45258153611374],
              [-40.107963768964233, -8.450886730528685],
              [-40.10821276085651, -8.45012811105498],
              [-40.109468000965471, -8.449045854262739],
              [-40.109075224992985, -8.448720578787036],
              [-40.10799051785515, -8.448415549619288],
              [-40.107570114065489, -8.448103330046338],
              [-40.104798946308243, -8.447138109231803],
              [-40.102935248568187, -8.446910349975326],
              [-40.102395576322543, -8.446945949322849],
              [-40.101776920708765, -8.446888842132443],
              [-40.101262961352823, -8.446841252975073],
              [-40.100815625481715, -8.446850769548114],
              [-40.100492020694979, -8.446917393899056],
              [-40.099863846924016, -8.447126785183244],
              [-40.099507882308181, -8.447260034127892],
              [-40.099127170833903, -8.44743135383508],
              [-40.098422855733055, -8.447478941977158],
              [-40.098023108584307, -8.447421834969163],
              [-40.097109402087938, -8.447307620858068],
              [-40.096224249423372, -8.447155334987611],
              [-40.095929198668543, -8.446945943934121],
              [-40.095519933730692, -8.446717516893857],
              [-40.094729958342811, -8.446631855723457],
              [-40.09436828288721, -8.446622337476288],
              [-40.09157386340506, -8.446717513605901],
              [-40.08924200775941, -8.447907234887527],
              [-40.08670172950869, -8.449177149538457],
              [-40.087202650252884, -8.451249616153396],
              [-40.087267858638846, -8.451435954146641],
              [-40.086649202617814, -8.451864254191852],
              [-40.086410735289618, -8.451865257987361],
              [-40.081369490353708, -8.455784365465846],
              [-40.078648818448571, -8.458149346728874],
              [-40.079247524325659, -8.460434523215325],
              [-40.077540731227209, -8.460519403308007],
              [-40.077465860285606, -8.459885864853259],
              [-40.076780223073605, -8.458723374901364],
              [-40.076677928029206, -8.457251978195513],
              [-40.075930163629941, -8.455641072746374],
              [-40.075269506675333, -8.456341472206519],
              [-40.073895375788858, -8.458155799727622],
              [-40.073770325756634, -8.458048166236308],
              [-40.073041873886964, -8.45802441976403],
              [-40.072669575357779, -8.458192065498764],
              [-40.071540667811405, -8.453990620487335],
              [-40.065059963165169, -8.455314756542844],
              [-40.064083413853716, -8.455508759623674],
              [-40.054761410672143, -8.457401086072908],
              [-40.052098187605303, -8.458299536734229],
              [-40.050238229675564, -8.458278253304996],
              [-40.050076245702932, -8.458408298429079],
              [-40.049533732518128, -8.458398781031045],
              [-40.048629543895558, -8.458360708494265],
              [-40.047944804792344, -8.458252008542841],
              [-40.046759386445821, -8.457854442820034],
              [-40.045231323864421, -8.457028311253794],
              [-40.042672832164783, -8.456618862454867],
              [-40.041612812494833, -8.456749268828476],
              [-40.041278384775474, -8.460396379767403],
              [-40.038404875707052, -8.461866953992372],
              [-40.038352166006916, -8.461950334473281],
              [-40.038284351422831, -8.462021718010007],
              [-40.038195121989119, -8.462093101528827],
              [-40.038073770777828, -8.462168054000585],
              [-40.037913157833074, -8.462253713378541],
              [-40.037616916804325, -8.46238220339939],
              [-40.037370644454846, -8.46247500166529],
              [-40.037231446260762, -8.462678444389999],
              [-40.037124371769131, -8.462967547653346],
              [-40.037099386857442, -8.463071053042741],
              [-40.037035142164299, -8.463242373012955],
              [-40.036920928659711, -8.46360642884329],
              [-40.036778161339875, -8.463989043544093],
              [-40.036796007043506, -8.464192486399771],
              [-40.036785299893459, -8.464460173865557],
              [-40.03687095914804, -8.464631494960781],
              [-40.037060124335007, -8.465208272832122],
              [-40.037063693228873, -8.46530464028603],
              [-40.037117230789995, -8.465379592903579],
              [-40.037170768309139, -8.465504514236452],
              [-40.037220736862274, -8.465618727627367],
              [-40.037263566474529, -8.465722234073343],
              [-40.037288551224115, -8.465811462585801],
              [-40.037295689086925, -8.465914969002005],
              [-40.037292120042885, -8.465997059531269],
              [-40.037302826886645, -8.466093427991122],
              [-40.037317103702335, -8.466193364433636],
              [-40.037338518470911, -8.466289731902442],
              [-40.037334949399856, -8.466403945248663],
              [-40.03731353446608, -8.466503882661396],
              [-40.03724928888095, -8.466546712363819],
              [-40.037220736006283, -8.466635941831557],
              [-40.037206415872035, -8.466914903230219],
              [-40.037482015496082, -8.467439475471325],
              [-40.037607632523923, -8.467546972963628],
              [-40.037797987899353, -8.467889613170277],
              [-40.037978825381749, -8.468184664640127],
              [-40.038040690787234, -8.468375020607221],
              [-40.038174364527222, -8.46875728454085],
              [-40.038173939423359, -8.468884222817334],
              [-40.038269116607054, -8.469060301893538],
              [-40.038444122201533, -8.469270740840701],
              [-40.038545132449379, -8.469322041632706],
              [-40.038716452046543, -8.469559986420027],
              [-40.038887771539542, -8.469921662502539],
              [-40.039249446412008, -8.47062121981897],
              [-40.039524354888229, -8.471326843028566],
              [-40.039615087105986, -8.471499542120469],
              [-40.039719386176131, -8.471734385869643],
              [-40.039797907258311, -8.472169824454655],
              [-40.039794099474769, -8.473131834971223],
              [-40.039805996251019, -8.473298396032336],
              [-40.039801237069923, -8.47355299757805],
              [-40.039820272525454, -8.474040783815257],
              [-40.039851204841725, -8.474594719685687],
              [-40.039848825766192, -8.474704174060179],
              [-40.039848825567738, -8.474939739718323],
              [-40.0398726192103, -8.475165786450566],
              [-40.039922587651063, -8.475413249082658],
              [-40.039989211806578, -8.475860585590173],
              [-40.040022523314903, -8.476166582875019],
              [-40.040060594785032, -8.476478290131249],
              [-40.040156842714396, -8.476947160540909],
              [-40.040243096727984, -8.477399256037856],
              [-40.040320428867844, -8.477775802957678],
              [-40.040430477639234, -8.478317127966287],
              [-40.040564321140742, -8.478980398300237],
              [-40.040608934586906, -8.479265932711169],
              [-40.040707086529437, -8.479703156302932],
              [-40.040889113670822, -8.480392283530055],
              [-40.040960496828788, -8.480796790285867],
              [-40.041084227510915, -8.481329786353603],
              [-40.041222233787884, -8.482224458373528],
              [-40.041304687782095, -8.482730867558335],
              [-40.04134264046251, -8.482793956230736],
              [-40.041426865798783, -8.482879996811151],
              [-40.041591047415615, -8.483153633389946],
              [-40.041792665771482, -8.483425208011885],
              [-40.042049644602471, -8.483858266760404],
              [-40.04225725176066, -8.484314335336718],
              [-40.042591973941946, -8.484870751447829],
              [-40.042863413806927, -8.485143169123425],
              [-40.043034733387657, -8.48540014980324],
              [-40.043182259095275, -8.485704719192194],
              [-40.043415443954309, -8.486302436983468],
              [-40.043810431499367, -8.48711144970691],
              [-40.044062651112256, -8.487842415755523],
              [-40.044462397447106, -8.488860819290924],
              [-40.044885937675069, -8.489807840252919],
              [-40.045114364035605, -8.489850670200129],
              [-40.04533327340679, -8.489960124760076],
              [-40.045517549897312, -8.490216069456924],
              [-40.047062960982821, -8.491480983548009],
              [-40.047117140603994, -8.491479284603024],
              [-40.047386256561097, -8.491661788789139],
              [-40.047739840728831, -8.492077002721077],
              [-40.04809675777777, -8.492605240012375],
              [-40.048403705337172, -8.492944311338896],
              [-40.049124676550598, -8.493804482045304],
              [-40.050217528779939, -8.49510757054192],
              [-40.053531687778587, -8.494802250054574],
              [-40.061343077828909, -8.494959082702303],
              [-40.065721107591919, -8.493203094362324],
              [-40.068746580129819, -8.493479617321874],
              [-40.071041938229413, -8.496787355420793],
              [-40.063718615155921, -8.503243609545619],
              [-40.05727921111513, -8.504696624879481],
              [-40.054171208155793, -8.506790438358584],
              [-40.050275535724616, -8.510066959446144],
              [-40.050152086336034, -8.510368322627624],
              [-40.049552466357305, -8.510589610865459],
              [-40.048795802347918, -8.510967942077867],
              [-40.048653035259299, -8.511075017348738],
              [-40.048545960883992, -8.511224922405907],
              [-40.048396055948182, -8.511210645361434],
              [-40.048288980754144, -8.511146400637196],
              [-40.048241654194712, -8.511018320326366],
              [-40.047585359019259, -8.510760589242684],
              [-40.047419889138347, -8.51081059981929],
              [-40.047214304587378, -8.510803461687496],
              [-40.0470072930041, -8.510846291270125],
              [-40.04660754664075, -8.51104616479742],
              [-40.04580805278723, -8.511595816998817],
              [-40.045636732923249, -8.511674338408287],
              [-40.045486827951287, -8.511702891120059],
              [-40.045322646085602, -8.511724305860477],
              [-40.045137049309744, -8.511817104176712],
              [-40.044937175676473, -8.511859934765299],
              [-40.044715887102839, -8.512009839726634],
              [-40.044530290248467, -8.512195436514702],
              [-40.044103418026097, -8.512453843686085],
              [-40.043746501501175, -8.51248953518358],
              [-40.043418137642902, -8.512682269811265],
              [-40.042561537160282, -8.51326761376154],
              [-40.041433680236096, -8.514261270160505],
              [-40.040434312604638, -8.515025071975277],
              [-40.039113719895731, -8.516181482286127],
              [-40.037554670831391, -8.517372760198359],
              [-40.038746978716027, -8.520614180752927],
              [-40.031254484384789, -8.526433505346898],
              [-40.032544885158806, -8.530168268179635],
              [-40.037764268416431, -8.537357424667551],
              [-40.040425343261965, -8.535558675137588],
              [-40.041981461257599, -8.538009593505283],
              [-40.05039270940653, -8.547199788321844],
              [-40.049431018026326, -8.550881648577795],
              [-40.048432636554161, -8.551446314526126],
              [-40.047593551072431, -8.550707629018968],
              [-40.04657565933968, -8.551740002291512],
              [-40.039523982553519, -8.556782546677848],
              [-40.031152839574979, -8.562366022873004],
              [-40.023406531997864, -8.565854953501065],
              [-40.024161298781998, -8.566930206023939],
              [-40.025290581498574, -8.568519201942317],
              [-40.026147180561551, -8.56960137551261],
              [-40.027217929844468, -8.571007630421608],
              [-40.029309458651326, -8.573948628468759],
              [-40.031142044632716, -8.576470963679219],
              [-40.033781797062716, -8.580028715688989],
              [-40.034345724469652, -8.580771103946542],
              [-40.036849848878873, -8.584296019032589],
              [-40.038127609377653, -8.586087744932387],
              [-40.040029258808048, -8.588684674786048],
              [-40.040950102269527, -8.589941024427512],
              [-40.041949468075906, -8.591318725446502],
              [-40.043284335154503, -8.593131865276478],
              [-40.044119518376803, -8.594201189909363],
              [-40.045275927811609, -8.59589297727903],
              [-40.046787824428179, -8.59796167080985],
              [-40.04840822417755, -8.600153142736632],
              [-40.050281320768939, -8.602792904333228],
              [-40.050980876045315, -8.603742303535064],
              [-40.053860000453696, -8.607689809562517],
              [-40.054787982881614, -8.608924744338379],
              [-40.056015774801068, -8.610630808694467],
              [-40.056986586830163, -8.611979955859319],
              [-40.058360000577743, -8.613860197351736],
              [-40.058752608362859, -8.614395572646119],
              [-40.059652036978306, -8.615644783319016],
              [-40.061273864693923, -8.617860526120221],
              [-40.062671548242115, -8.619773603450041],
              [-40.064113489393606, -8.6217866172514],
              [-40.06524134515378, -8.623328500460303],
              [-40.066881731696171, -8.625592784006697],
              [-40.067795437200488, -8.626853415630874],
              [-40.069401561020712, -8.629073441401461],
              [-40.069846992165836, -8.629921476969241],
              [-40.069961204818448, -8.63055678946219],
              [-40.070246736146117, -8.632498419690332],
              [-40.070482299897769, -8.634354388362521],
              [-40.070817799466482, -8.636770003945832],
              [-40.071024809773682, -8.638211948943285],
              [-40.071096193051694, -8.63846892954648],
              [-40.071203266968702, -8.638854400451295],
              [-40.071338895746614, -8.639168487785312],
              [-40.071908534238872, -8.639760969910073],
              [-40.072508153674185, -8.640174995072567],
              [-40.074892355564756, -8.641773986035155],
              [-40.076562723889317, -8.642880430183702],
              [-40.078534092926581, -8.644190079437113],
              [-40.080324623351714, -8.645361959318368],
              [-40.081987853781946, -8.646404158826968],
              [-40.08245898398394, -8.646975225990849],
              [-40.084791787866088, -8.650164639898129],
              [-40.086819072191616, -8.653005701522293],
              [-40.090984997987903, -8.658770630403094],
              [-40.092484046695589, -8.660826475032547],
              [-40.097366660683008, -8.667490835442861],
              [-40.099279730978722, -8.670132024115368],
              [-40.101949465056784, -8.673823977485286],
              [-40.107160441286638, -8.681005154274622],
              [-40.111637896568112, -8.68713580439735],
              [-40.114997667968915, -8.691787626943078],
              [-40.116960706867843, -8.694476404407213],
              [-40.119570954530737, -8.697967056890676],
              [-40.122188339899097, -8.701738482794914],
              [-40.124339354305896, -8.704624755310681],
              [-40.126135833508876, -8.707154109543403],
              [-40.127468320571403, -8.708986285323185],
              [-40.12918151843617, -8.711425219998935],
              [-40.130537799341518, -8.713245498868631],
              [-40.131966454991051, -8.715926343934257],
              [-40.132144913713475, -8.715956086919531],
              [-40.132376909070722, -8.715973933018226],
              [-40.132549418845144, -8.715991779065694],
              [-40.13272192858345, -8.716051265877644],
              [-40.132864695520013, -8.716122649596759],
              [-40.133007462456568, -8.716194033315821],
              [-40.133108588654189, -8.716301107797257],
              [-40.133185920003257, -8.716426029157263],
              [-40.1332156626912, -8.716545001509964],
              [-40.133233508478121, -8.716646127953375],
              [-40.133233508405738, -8.716729408482371],
              [-40.133239457260615, -8.716848380814662],
              [-40.133245406151687, -8.716925713382398],
              [-40.13323350813171, -8.717044685699282],
              [-40.133221611122103, -8.717151761083473],
              [-40.133185919294917, -8.717240989548149],
              [-40.133179971217679, -8.717377807769221],
              [-40.13315022733839, -8.717478934171691],
              [-40.133090741652218, -8.717597906447638],
              [-40.133025307049124, -8.717669289987644],
              [-40.132989615232269, -8.717746622519552],
              [-40.135651614585733, -8.722318137958355],
              [-40.135515284467914, -8.722403239359716],
              [-40.135175046712241, -8.722566609143012],
              [-40.130542793974392, -8.725130064658565],
              [-40.131110652145644, -8.726101852652366],
              [-40.126947999524553, -8.729343616735791],
              [-40.127048974659928, -8.729523191807347],
              [-40.122657461899948, -8.732472736344965],
              [-40.12305989052912, -8.733101683135299],
              [-40.11865298489608, -8.736030743774544],
              [-40.120198320012591, -8.738374660853468],
              [-40.115779122501785, -8.741262683713297],
              [-40.112876967438993, -8.746340478499924],
              [-40.108520270421145, -8.74930918195556],
              [-40.108611501668356, -8.749438341304089],
              [-40.104088765962686, -8.752240148558986],
              [-40.104692747016983, -8.75305082849701],
              [-40.100981538700935, -8.756902565515835],
              [-40.10092683215138, -8.756826351899473],
              [-40.097447407952075, -8.760972361454284],
              [-40.085074482318653, -8.774115387457678],
              [-40.096855634708476, -8.787728439725768],
              [-40.096404261617991, -8.788023834665612],
              [-40.092909857135254, -8.790319787664739],
              [-40.093397183210762, -8.79089472983884],
              [-40.05323114412694, -8.810418166677303],
              [-40.054635589813792, -8.813208452128782],
              [-40.055444484738871, -8.814795951860933],
              [-40.059532290756231, -8.812827338534449],
              [-40.060641385587132, -8.814398973617747],
              [-39.983206577859917, -8.85181432782686],
              [-39.991822681719256, -8.856990909075016],
              [-39.996255721247579, -8.8596060791612],
              [-40.005086737563879, -8.864824300382118],
              [-40.005902452397187, -8.865314581324384],
              [-40.015361766044883, -8.870822620472635],
              [-40.023005126345474, -8.875327266714489],
              [-40.023942402153146, -8.876012089667297],
              [-40.024927056337461, -8.877025547806465],
              [-40.042342032481024, -8.89451788533084],
              [-40.054051444828389, -8.906227308556245],
              [-40.055935962922632, -8.908104694624507],
              [-40.057049542199572, -8.9091183398869],
              [-40.058155958806182, -8.909817289917529],
              [-40.087433677147054, -8.919963265462547],
              [-40.092727077358767, -8.921780536881325],
              [-40.127818790454128, -8.933622163110629],
              [-40.137464902593699, -8.93043808253171],
              [-40.136931883970838, -8.924112569660494],
              [-40.138944444745157, -8.924227514786194],
              [-40.139303048989312, -8.918881612190361],
              [-40.139322434019249, -8.918695211256615],
              [-40.139230826268964, -8.918013500013151],
              [-40.139064265760773, -8.917650633909027],
              [-40.138463457295202, -8.916169426717961],
              [-40.138195769749593, -8.9155222171256],
              [-40.137886443544879, -8.914820279804822],
              [-40.136548008673323, -8.911744842940818],
              [-40.136170512817607, -8.911188039743534],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2242',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DE JAGUARIBE',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12050',
        QUALIDAD8:
          'Esquemático (O poligono é uma representação esquemática da dimensão da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '01/02/2016',
        CODIGO_U11: '0000.26.2242',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.846876291049945, -7.728659619468944],
              [-34.846826366355771, -7.72876030479754],
              [-34.846747882841406, -7.728911347787927],
              [-34.846690795195997, -7.729019241066043],
              [-34.846597642835285, -7.729105760455267],
              [-34.846454127851644, -7.729192497809146],
              [-34.846195440740267, -7.729265380173153],
              [-34.845886422000895, -7.729345656456433],
              [-34.845613438981999, -7.729440129674109],
              [-34.845398011532041, -7.729534353932102],
              [-34.845197283923454, -7.729700278745804],
              [-34.844989455353428, -7.729887763418201],
              [-34.84482513640409, -7.730153999621073],
              [-34.844696913165038, -7.730448785667456],
              [-34.844568690925968, -7.730743571713788],
              [-34.844483479389098, -7.731002290017637],
              [-34.844397986898038, -7.731196422730331],
              [-34.84428374861745, -7.731397854377477],
              [-34.844154964470057, -7.731563468241681],
              [-34.843954079886906, -7.731693511282589],
              [-34.843731580470525, -7.73181647135377],
              [-34.84349450519246, -7.731896435687474],
              [-34.843199686376067, -7.731933591253436],
              [-34.842955198187568, -7.731963351900088],
              [-34.842703328083495, -7.731950086814339],
              [-34.842444171048626, -7.731915323859886],
              [-34.842271378362128, -7.731887363920516],
              [-34.842055294019261, -7.73183088613264],
              [-34.841795826035344, -7.731724359632511],
              [-34.841637329256336, -7.731674808839536],
              [-34.841522279108261, -7.731689657667967],
              [-34.841428937778119, -7.731733119329766],
              [-34.841350327284204, -7.731855456501852],
              [-34.841257607852178, -7.732042443254932],
              [-34.841150436538094, -7.732215139088784],
              [-34.84105055415489, -7.732409332791397],
              [-34.841015172327467, -7.732545837902944],
              [-34.841022896186978, -7.732667803135591],
              [-34.841081074654035, -7.73281108026726],
              [-34.841211166587371, -7.732946870494827],
              [-34.841376983292037, -7.73302509611108],
              [-34.841564507814759, -7.733131932560808],
              [-34.841687218832519, -7.733224696055945],
              [-34.841766857158333, -7.733339175384491],
              [-34.841817840679241, -7.733482482511172],
              [-34.841897789954054, -7.733668724385439],
              [-34.841984685215778, -7.733797524628165],
              [-34.842136175018048, -7.73389016314353],
              [-34.842294641802098, -7.733932537981984],
              [-34.842474473460207, -7.73392458515357],
              [-34.842783714163765, -7.733894543552343],
              [-34.842956320880852, -7.733879445764335],
              [-34.843143253500379, -7.733849933077276],
              [-34.843430878370668, -7.733812808506382],
              [-34.843625130915939, -7.733811968642623],
              [-34.843848317220214, -7.73384688657197],
              [-34.844006784004286, -7.733889260410397],
              [-34.844129309052668, -7.733938965178165],
              [-34.844230406237216, -7.734024644703696],
              [-34.844302818621976, -7.734131978072703],
              [-34.84433932126592, -7.734253819325605],
              [-34.844339852179274, -7.73437581555323],
              [-34.844275630577549, -7.734498091735473],
              [-34.844147064394463, -7.734713939281709],
              [-34.844025568177862, -7.734901050014681],
              [-34.843875355166574, -7.735102638636625],
              [-34.843753951934715, -7.73531127823329],
              [-34.84366842844868, -7.735498234991537],
              [-34.8435760209656, -7.735756984290403],
              [-34.843533695151265, -7.735950930033508],
              [-34.843520117126474, -7.736137574842207],
              [-34.84353547186069, -7.736359976443992],
              [-34.843565092507468, -7.736553611237591],
              [-34.843638191780038, -7.736818821607185],
              [-34.843703815152352, -7.737019477380604],
              [-34.843790961373216, -7.737205686260033],
              [-34.84386365571207, -7.737377606220243],
              [-34.843979423742901, -7.737527809347069],
              [-34.844124313502121, -7.737756827994367],
              [-34.84423998854826, -7.737885502257454],
              [-34.844370083481564, -7.73802129148523],
              [-34.844500022440307, -7.738121198940131],
              [-34.844730655649897, -7.738213494511238],
              [-34.844925221144159, -7.7382844161933],
              [-34.845105304761475, -7.738333872001575],
              [-34.845198959040651, -7.738362171885528],
              [-34.845292801289226, -7.738433530496916],
              [-34.84537938660182, -7.738490566194164],
              [-34.84545877596841, -7.7385476338863],
              [-34.845552524232254, -7.738597462633956],
              [-34.84566044342408, -7.73859699570971],
              [-34.845818568264214, -7.738560430047954],
              [-34.845940876348294, -7.738559900133856],
              [-34.846063466386475, -7.738623956810867],
              [-34.8461788914734, -7.738695221437452],
              [-34.846258531799144, -7.738809698766208],
              [-34.846302324373838, -7.738953036887978],
              [-34.846303011261625, -7.739110913888608],
              [-34.846332570918563, -7.739290195773203],
              [-34.846362004595981, -7.739440771839483],
              [-34.84644176990129, -7.739583953986532],
              [-34.846535862109022, -7.739712722234572],
              [-34.846629923321892, -7.739834312528016],
              [-34.846745568373095, -7.739955810836677],
              [-34.846918738998404, -7.740069882231121],
              [-34.847005199331427, -7.740098213110063],
              [-34.8471563801847, -7.740119088079904],
              [-34.847300116132622, -7.740082583408038],
              [-34.847544389356891, -7.740002584079518],
              [-34.847774429663403, -7.739958529513684],
              [-34.847968715203606, -7.73996486360471],
              [-34.848134347938952, -7.740000027493793],
              [-34.848249711036054, -7.740056938211253],
              [-34.848329352361759, -7.740171415540057],
              [-34.848380496857104, -7.740350603439845],
              [-34.848431609357498, -7.740522614385079],
              [-34.848489791824449, -7.740665889517014],
              [-34.848554762303813, -7.740715842244568],
              [-34.84864835459323, -7.740729789219428],
              [-34.848777732643846, -7.74070052249207],
              [-34.848942708486298, -7.74058498533505],
              [-34.849201026658967, -7.740425984516216],
              [-34.849538234279834, -7.740209231116371],
              [-34.850104918299124, -7.739819248968369],
              [-34.850714675010515, -7.739407549986901],
              [-34.850953443013168, -7.739715097200459],
              [-34.851271915331424, -7.74015147265211],
              [-34.851495918503105, -7.740372966400726],
              [-34.851734311567022, -7.740594398159438],
              [-34.851914835112979, -7.740744318331952],
              [-34.85211684851258, -7.740872614655911],
              [-34.852318766927517, -7.740979383116136],
              [-34.852484462652669, -7.741028897914442],
              [-34.852642838452162, -7.741049738889356],
              [-34.852858644840978, -7.741041624086443],
              [-34.853081458206617, -7.74099042156106],
              [-34.853347251279637, -7.740895973338496],
              [-34.853627339260193, -7.740779934262291],
              [-34.853871265540739, -7.740620993433367],
              [-34.854000297647573, -7.740512787205639],
              [-34.854035831449657, -7.740412163866749],
              [-34.854021097613561, -7.740333287356293],
              [-34.853955844180078, -7.740218750037461],
              [-34.853825651262085, -7.740061435945754],
              [-34.853731495064473, -7.739918318788458],
              [-34.853644563807926, -7.739782345590747],
              [-34.853564796502617, -7.73963916544356],
              [-34.853521001927916, -7.739495828321657],
              [-34.853498791191598, -7.739352398214928],
              [-34.853490906357735, -7.739194553209087],
              [-34.853526064221036, -7.739007814415291],
              [-34.853575235037816, -7.738734899176685],
              [-34.853617367882912, -7.738497896705852],
              [-34.853609546038847, -7.738354403609237],
              [-34.853580078366605, -7.738196651588304],
              [-34.853529057850871, -7.7380461705068],
              [-34.853478037335073, -7.737895688425263],
              [-34.853434211765489, -7.737745175348825],
              [-34.853419258965054, -7.737616066156324],
              [-34.853368395423786, -7.737501465847705],
              [-34.85325253040822, -7.737329735856993],
              [-34.853129314471992, -7.737122157088373],
              [-34.853049391192087, -7.736943096168395],
              [-34.853034437391678, -7.736813985975875],
              [-34.853062652268022, -7.736684688813711],
              [-34.853090928134122, -7.736569744560678],
              [-34.853155115741082, -7.736440291423768],
              [-34.853226747253373, -7.73636821692852],
              [-34.853348772383413, -7.736303099423192],
              [-34.853542616995156, -7.736208964150107],
              [-34.853671429137655, -7.736050525240331],
              [-34.853778688436677, -7.735899355269963],
              [-34.85379980135157, -7.735791617966475],
              [-34.853799143458694, -7.73564091792037],
              [-34.853740991986577, -7.735504819743038],
              [-34.853661350660779, -7.735390344414244],
              [-34.853495250001814, -7.73524753820653],
              [-34.853322080376387, -7.735133468812117],
              [-34.8530913861768, -7.735026826331886],
              [-34.852968767143622, -7.734955594700302],
              [-34.85286760496929, -7.734855565265547],
              [-34.85280948549206, -7.734726643042766],
              [-34.852787337745433, -7.734597564845293],
              [-34.852729093288609, -7.734439937804245],
              [-34.852721146464901, -7.734267739889385],
              [-34.852748859422725, -7.734023623454107],
              [-34.852776541385744, -7.733772331064205],
              [-34.852761400615861, -7.73360016414433],
              [-34.85271754505122, -7.733442474113388],
              [-34.852644848712359, -7.733270557153159],
              [-34.85254365654302, -7.733163350763887],
              [-34.852406585627811, -7.733077830213062],
              [-34.852240955892377, -7.733042667324066],
              [-34.852025090513656, -7.733036427218027],
              [-34.851902659449877, -7.733008253313943],
              [-34.851794491298776, -7.732951311601616],
              [-34.85171488296784, -7.732844012227469],
              [-34.851664052421413, -7.732736586873549],
              [-34.851606120913623, -7.732650722378332],
              [-34.851483501880438, -7.73257949074675],
              [-34.851303233293557, -7.732486979211133],
              [-34.851238232819142, -7.732429850529098],
              [-34.851216243047034, -7.732336653104522],
              [-34.851208485192686, -7.732207511917198],
              [-34.8512294111381, -7.732056717886373],
              [-34.851300730701318, -7.731912880845387],
              [-34.851314681664903, -7.73181235149149],
              [-34.851292565913205, -7.731690449248664],
              [-34.849986211342184, -7.730741656377259],
              [-34.849921929750629, -7.730849580650388],
              [-34.849807256541233, -7.730950546933645],
              [-34.849685200416324, -7.731008486484383],
              [-34.849592017060651, -7.731087830919046],
              [-34.849469898945912, -7.731131417560658],
              [-34.849354818802816, -7.731139092434485],
              [-34.849217967851892, -7.731103803565709],
              [-34.849124189593041, -7.731046798863577],
              [-34.849008672521315, -7.73095400637341],
              [-34.848936134156844, -7.73081797018613],
              [-34.848856369851433, -7.730674788039274],
              [-34.848681766460309, -7.730230608734916],
              [-34.848455647634879, -7.729521126076322],
              [-34.848295684095206, -7.729134294418735],
              [-34.848201343928075, -7.728948117534433],
              [-34.848092990807494, -7.728848117094809],
              [-34.847956015876953, -7.728784122407854],
              [-34.84785526263633, -7.728777382382653],
              [-34.847704179767668, -7.728778036276725],
              [-34.847581843688637, -7.728771389236372],
              [-34.84750254930676, -7.728735850408043],
              [-34.847401452122135, -7.728650170882644],
              [-34.847314619850124, -7.728535724549071],
              [-34.847206516688821, -7.728493133746004],
              [-34.847105701458375, -7.728472040811694],
              [-34.847005135187153, -7.728508357513861],
              [-34.846926339723723, -7.728587638958589],
              [-34.846876291049945, -7.728659619468944],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '197',
        NOME_UC1: 'RESERVA BIOLÓGICA DE PEDRA TALHADA',
        ID_WCMC2: '19454',
        CATEGORI3: 'Reserva Biológica',
        GRUPO4: 'PI',
        ESFERA5: 'federal',
        ANO_CRIA6: '1989',
        GID7: '299',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 98524 de 13/12/1989',
        DT_ULTIM10: '30/11/2007',
        CODIGO_U11: '0000.00.0197',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-36.42119808773122, -9.183334259847893],
              [-36.417379504525094, -9.188591731926433],
              [-36.417706132009535, -9.188991556005449],
              [-36.4175359593807, -9.189564132878161],
              [-36.417528734570233, -9.190561052547082],
              [-36.417508416165063, -9.192368365796906],
              [-36.417492696232273, -9.192385684915502],
              [-36.414364650261405, -9.197351201503345],
              [-36.41189236218748, -9.201394664710667],
              [-36.408228375465761, -9.203753822474312],
              [-36.407867781362242, -9.203623667813611],
              [-36.407528065645103, -9.203699909158551],
              [-36.407384924688692, -9.203674389951754],
              [-36.407231293877807, -9.20370310192504],
              [-36.407172602681769, -9.203782467530797],
              [-36.406792641738775, -9.20475562515216],
              [-36.406476533115921, -9.205702133970501],
              [-36.406462827856458, -9.206334560172957],
              [-36.406035852865195, -9.206832998338085],
              [-36.405958249621435, -9.206921166822186],
              [-36.405729605528997, -9.207174325615487],
              [-36.405705530191121, -9.207408975682997],
              [-36.405628702253523, -9.208157773575117],
              [-36.405592049081925, -9.208515008396887],
              [-36.404440075309296, -9.209347049275406],
              [-36.404016953061323, -9.210109367702126],
              [-36.404001582037324, -9.2101370608256],
              [-36.403386021724735, -9.211298905304936],
              [-36.403352112071268, -9.211372346463277],
              [-36.403212038324853, -9.213115317035943],
              [-36.402700745694432, -9.213564284654966],
              [-36.402841795482871, -9.214019554286734],
              [-36.403691420445554, -9.214302646167319],
              [-36.404171429188743, -9.214473468818982],
              [-36.404626853195069, -9.21471583725328],
              [-36.404937209944805, -9.215052789629503],
              [-36.405247392703068, -9.215413648579032],
              [-36.405436918548872, -9.215821452236602],
              [-36.405460059534995, -9.216138359257364],
              [-36.405397985044161, -9.216400898624229],
              [-36.405213204915306, -9.21662648987073],
              [-36.405004942988306, -9.216882776043287],
              [-36.404809036961389, -9.217166224882899],
              [-36.404608295608107, -9.217447145533049],
              [-36.40429278524644, -9.217815439441708],
              [-36.404011616454397, -9.218049869822872],
              [-36.403748211049511, -9.218181768406778],
              [-36.402968766458457, -9.218421321920454],
              [-36.402134251255717, -9.218458757090486],
              [-36.400722780554126, -9.217825972746496],
              [-36.398052908923567, -9.221514192290199],
              [-36.399308498036859, -9.222688953408621],
              [-36.3962079856814, -9.224261988027891],
              [-36.39807225082577, -9.229245299034956],
              [-36.397509978076307, -9.23151790536626],
              [-36.399825131350561, -9.239513533384557],
              [-36.39964545031399, -9.241689898575963],
              [-36.398043593817626, -9.244316775461657],
              [-36.392682784573431, -9.247797364479473],
              [-36.392358133014504, -9.247837966184454],
              [-36.39201328297262, -9.247685079994632],
              [-36.391819631302575, -9.247554776572839],
              [-36.391560638185581, -9.247488444190912],
              [-36.391365720925556, -9.24752998956202],
              [-36.39123345505682, -9.247872742817984],
              [-36.39095141518991, -9.247999582542064],
              [-36.390839730472557, -9.248047159727868],
              [-36.390604349876327, -9.248147430630176],
              [-36.390236453617391, -9.248187715280029],
              [-36.389955046687064, -9.248228629671216],
              [-36.389860055228517, -9.248196401914806],
              [-36.389696212191062, -9.248140814812791],
              [-36.38950240315522, -9.248031991386132],
              [-36.389286021721936, -9.248051896840812],
              [-36.389047226050089, -9.248179049990741],
              [-36.388635134917322, -9.248347904225213],
              [-36.388180749560178, -9.248387556183822],
              [-36.387509190342371, -9.248554517124813],
              [-36.389814571887563, -9.251386052707646],
              [-36.398161188145174, -9.254266001740456],
              [-36.402873404762452, -9.256649609805631],
              [-36.408676556166768, -9.26036029599222],
              [-36.410262245102452, -9.258709121526641],
              [-36.420311297528905, -9.26269433955982],
              [-36.420852482373732, -9.263076518515867],
              [-36.421022144078123, -9.263256236868321],
              [-36.421230039327796, -9.263391132181262],
              [-36.42140815044246, -9.263518401793503],
              [-36.42161680283521, -9.263549549477084],
              [-36.421773292175089, -9.263572910165855],
              [-36.421899348589257, -9.263677571638478],
              [-36.422076757501564, -9.263901177988906],
              [-36.422201733152171, -9.264154050149308],
              [-36.422386385879562, -9.264407352361232],
              [-36.422490171989196, -9.264497031394317],
              [-36.422706446942804, -9.264506000745131],
              [-36.42286390955578, -9.264395971349211],
              [-36.423036723577049, -9.264226765008951],
              [-36.423142617018016, -9.264027432733156],
              [-36.423537695006374, -9.263840932930355],
              [-36.424173540478215, -9.263773787483558],
              [-36.424578045927284, -9.263748010159603],
              [-36.42512694307446, -9.263723271742728],
              [-36.425373767208768, -9.263538564053833],
              [-36.425693831578393, -9.263210933717389],
              [-36.42628970120704, -9.262684457920283],
              [-36.426478034865269, -9.262660323562223],
              [-36.42695358837009, -9.262732268300008],
              [-36.42744548743697, -9.262905185983328],
              [-36.428130576345076, -9.2650064699856],
              [-36.430282061762085, -9.263097197586355],
              [-36.431791960453545, -9.263099001073423],
              [-36.434221111497685, -9.260469228633328],
              [-36.43857314641582, -9.258638952716897],
              [-36.443040688141807, -9.257188945487785],
              [-36.447690569854672, -9.257469271935889],
              [-36.447768560670063, -9.257473973240044],
              [-36.449841530440423, -9.257598926143977],
              [-36.450588444668227, -9.251143310792282],
              [-36.447379308487619, -9.248382436547004],
              [-36.451545481246129, -9.240758320497839],
              [-36.452801394956268, -9.239393715170378],
              [-36.459425700178244, -9.242820325789724],
              [-36.459642837442871, -9.240445281403586],
              [-36.46083239644868, -9.23691142766488],
              [-36.461663966719321, -9.235100994164814],
              [-36.460845411552988, -9.228742611191938],
              [-36.468041191272945, -9.220904663407453],
              [-36.467663873938605, -9.220251415409942],
              [-36.467394264588734, -9.220242348229013],
              [-36.46698898045927, -9.220382944413714],
              [-36.466512427794143, -9.220393929019819],
              [-36.466109395392934, -9.220218941760669],
              [-36.465836099518889, -9.220073560142886],
              [-36.465547649382067, -9.220028489026518],
              [-36.465332514937998, -9.219826135991735],
              [-36.465104069234286, -9.219465889956968],
              [-36.46478858742848, -9.219162411136118],
              [-36.464693966433671, -9.219098768906751],
              [-36.46471286990748, -9.21840730217636],
              [-36.464807502331432, -9.218212898777734],
              [-36.46504147375542, -9.217732249929799],
              [-36.465289233994788, -9.217335762225222],
              [-36.46515504424405, -9.216690969755653],
              [-36.464498866016847, -9.216253405041517],
              [-36.46401898827871, -9.215504875131632],
              [-36.463744425831003, -9.215362421160886],
              [-36.463561162189912, -9.215069251298846],
              [-36.463217047081251, -9.214859798114432],
              [-36.462872978132765, -9.214401933843323],
              [-36.462431238018283, -9.214099676550521],
              [-36.461906076190601, -9.214393752424316],
              [-36.461695137770207, -9.213934602032122],
              [-36.46168229569021, -9.213915576775504],
              [-36.461522895132227, -9.213679428225605],
              [-36.460953994806651, -9.21320887776413],
              [-36.460646446816966, -9.213007571753828],
              [-36.460229808428593, -9.212880169875168],
              [-36.46003087762837, -9.212671333657484],
              [-36.459786609453296, -9.212135130474598],
              [-36.459639814760465, -9.211694088548771],
              [-36.459616548490914, -9.21162418541372],
              [-36.45936859004869, -9.210954191477734],
              [-36.459163249302954, -9.210517664746236],
              [-36.458718014130035, -9.21077787002665],
              [-36.458341102976846, -9.210990385340819],
              [-36.457979654702505, -9.211059556483933],
              [-36.457746706809488, -9.211330470036765],
              [-36.457111255090282, -9.211354666684537],
              [-36.456752577627498, -9.211036533284727],
              [-36.456407106499093, -9.210890636515334],
              [-36.455888900472914, -9.210671790869737],
              [-36.45555766225332, -9.210554684712871],
              [-36.45526912029883, -9.210523952124047],
              [-36.454964908732769, -9.210665251642991],
              [-36.45457376463149, -9.210848971204268],
              [-36.45431286475484, -9.210990576666569],
              [-36.454108789562071, -9.211261691627474],
              [-36.453689386321585, -9.211359138296523],
              [-36.453414767845075, -9.211400228982503],
              [-36.453126945336628, -9.211269082885334],
              [-36.452852429774758, -9.211295828622301],
              [-36.452491084432545, -9.211350649385279],
              [-36.452275346472881, -9.211234358702294],
              [-36.4519433901051, -9.211217660966289],
              [-36.451654026043087, -9.211301681488296],
              [-36.451392816666939, -9.211486317970857],
              [-36.451203896723023, -9.211657121472225],
              [-36.450972487460731, -9.21171286172644],
              [-36.45072684656175, -9.211739810441939],
              [-36.450509668835572, -9.21182434173264],
              [-36.450379423902227, -9.211866453734894],
              [-36.450192458519695, -9.211764711168811],
              [-36.449903608522362, -9.211777007615984],
              [-36.449744283051139, -9.211847603532394],
              [-36.449455844794016, -9.211802521658651],
              [-36.448489366527561, -9.211680902002348],
              [-36.447826485292708, -9.211504054439208],
              [-36.447466376425538, -9.211386736328603],
              [-36.447194436897952, -9.211054866065979],
              [-36.446895065563837, -9.210521968254808],
              [-36.446483880113654, -9.209900996408608],
              [-36.445939333177741, -9.20954954419533],
              [-36.445072662556306, -9.208146657820272],
              [-36.444518320875737, -9.208061563925085],
              [-36.443842450995902, -9.206736588743935],
              [-36.443530763221389, -9.206781203532328],
              [-36.442534979977751, -9.205641564058169],
              [-36.442000192672339, -9.205289632845295],
              [-36.441786277477256, -9.205043282221256],
              [-36.442001004325846, -9.204571253877063],
              [-36.441890634709601, -9.204338149677644],
              [-36.441968356754714, -9.204104075449726],
              [-36.442106351510425, -9.203446175020154],
              [-36.441683073424102, -9.203107027869851],
              [-36.441135094310077, -9.202754874687036],
              [-36.439901669682655, -9.202529395418059],
              [-36.438133173023537, -9.202843429555545],
              [-36.437353158326033, -9.202734830179233],
              [-36.436194225025901, -9.201813112228017],
              [-36.438402595584975, -9.186257659225065],
              [-36.430128287023102, -9.183999990607024],
              [-36.428171261651507, -9.184248088735744],
              [-36.428464674306504, -9.186436975647522],
              [-36.42119808773122, -9.183334259847893],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '2243',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO ENGENHO SÃO JOÃO',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '5398',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9.989 de 13/01/1987',
        DT_ULTIM10: '08/03/2016',
        CODIGO_U11: '0000.26.2243',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.869394967546889, -7.757862255783683],
              [-34.869185588226806, -7.757698121680733],
              [-34.869019536557929, -7.757569677381196],
              [-34.868867874781294, -7.757441171091821],
              [-34.868774152512273, -7.757398524298307],
              [-34.868723788889341, -7.757398746262805],
              [-34.868644772461394, -7.757427798025484],
              [-34.868522745331113, -7.757492920530791],
              [-34.868479861608222, -7.757557696091824],
              [-34.868465883649442, -7.757651048491588],
              [-34.868509718218718, -7.757801558568763],
              [-34.868582521541839, -7.757994998393811],
              [-34.868640840988022, -7.758166973344927],
              [-34.86866309071894, -7.758317577406939],
              [-34.86867104654219, -7.758489773322577],
              [-34.868650063606516, -7.758626215444886],
              [-34.868621791740054, -7.758741159698347],
              [-34.868571998024983, -7.758870551845815],
              [-34.868493266551013, -7.758964189199967],
              [-34.868385692302709, -7.759043601624586],
              [-34.868227627452001, -7.759094529195326],
              [-34.868062240675599, -7.75911678494257],
              [-34.867803194620016, -7.759110745805439],
              [-34.867637712858972, -7.759111472688882],
              [-34.867486715974877, -7.759133664446207],
              [-34.867357429908694, -7.759184466037244],
              [-34.867250045629717, -7.759306935189533],
              [-34.867178825050622, -7.759472302094775],
              [-34.867122280317723, -7.759702191601672],
              [-34.867030044808118, -7.759996824674682],
              [-34.866958919213715, -7.760183720443846],
              [-34.866959236162522, -7.760255481989932],
              [-34.866995432857308, -7.760305557697516],
              [-34.867081866195541, -7.760326706622207],
              [-34.867175874418379, -7.760433939007235],
              [-34.867270263579819, -7.760627283847594],
              [-34.867321166115666, -7.760749058111403],
              [-34.867321736023499, -7.760878228294396],
              [-34.867250262485399, -7.760986186562817],
              [-34.867107029455248, -7.761137517508174],
              [-34.866877426076663, -7.761282051438101],
              [-34.866640564762214, -7.761412264453758],
              [-34.866396571491435, -7.76155686237354],
              [-34.866102181602834, -7.76169450330319],
              [-34.865987411408412, -7.761773946722745],
              [-34.865923163811303, -7.761889048950899],
              [-34.865902117885895, -7.762011139164019],
              [-34.865931657547449, -7.762183240094954],
              [-34.866215836867759, -7.762992914163648],
              [-34.866481985884782, -7.762977393442054],
              [-34.86666201751239, -7.763012484341933],
              [-34.866791779501753, -7.763069325070114],
              [-34.866892793701439, -7.763133468732579],
              [-34.866950796198772, -7.763233682137751],
              [-34.86699453578337, -7.763362663351162],
              [-34.866995359650168, -7.763549243171039],
              [-34.866917134094152, -7.763757698799025],
              [-34.866831872566465, -7.764002067194915],
              [-34.866717798259337, -7.764239386615929],
              [-34.866546608311474, -7.764577423360996],
              [-34.866849239977142, -7.7646765629386],
              [-34.867058084384155, -7.764718702813401],
              [-34.867216563168242, -7.764761064652665],
              [-34.86733833833955, -7.764638532510524],
              [-34.867474472408219, -7.764508761423846],
              [-34.86760344252562, -7.764386198286759],
              [-34.867754029476309, -7.764270716119485],
              [-34.86794778510383, -7.764155043982665],
              [-34.868134504759531, -7.764075283613829],
              [-34.868321414384546, -7.764038580972621],
              [-34.868472508253248, -7.764037917079181],
              [-34.868652476891171, -7.764058654069845],
              [-34.868767817993351, -7.764108381833265],
              [-34.868904901908365, -7.764193894385055],
              [-34.86906376163099, -7.764322368679706],
              [-34.869200973525508, -7.764436586049885],
              [-34.869345189396896, -7.764507714697478],
              [-34.869517995083555, -7.764535659637697],
              [-34.869661862011299, -7.764527850784593],
              [-34.86981279690562, -7.764491305118024],
              [-34.870006552533177, -7.764375631981205],
              [-34.870164301435139, -7.764252941864373],
              [-34.870401003775356, -7.764086846075635],
              [-34.870594505443869, -7.763913763301896],
              [-34.870716406594703, -7.763819934978176],
              [-34.870817007861021, -7.763790787230733],
              [-34.870946484896528, -7.763783041367441],
              [-34.871148038372844, -7.763803683373317],
              [-34.87132081106467, -7.763824451358936],
              [-34.871435930202757, -7.76382394444013],
              [-34.87153646847937, -7.76378044478346],
              [-34.871651078699479, -7.763665119590783],
              [-34.871765657924705, -7.763542618443503],
              [-34.871865941242284, -7.763441709149908],
              [-34.8720162092443, -7.763254463436486],
              [-34.872080549826087, -7.763160889072158],
              [-34.872101880705493, -7.763103384450493],
              [-34.872094431800342, -7.763046007808485],
              [-34.872043654244024, -7.762952939362985],
              [-34.871927805223741, -7.762788395325678],
              [-34.871790371365051, -7.762623946273174],
              [-34.871674491349886, -7.762452225281285],
              [-34.871616423862747, -7.762337660966893],
              [-34.871637309813863, -7.762179690980656],
              [-34.87166532772136, -7.76200733709021],
              [-34.871643170975126, -7.761878261891952],
              [-34.871599589364926, -7.76178516145155],
              [-34.871534421916337, -7.761692156995935],
              [-34.871454992555009, -7.761627920348624],
              [-34.87139730700639, -7.761599468489622],
              [-34.87136821027309, -7.761527833923169],
              [-34.871353185483287, -7.761384374820773],
              [-34.871373912459987, -7.761190524061463],
              [-34.871394862400848, -7.761046905984468],
              [-34.871451913051857, -7.760931834751219],
              [-34.871537870466916, -7.760845341356777],
              [-34.871624018851207, -7.760801904689912],
              [-34.871717298191911, -7.760744083118978],
              [-34.871767217886578, -7.760643395789934],
              [-34.871831463483701, -7.76052829256176],
              [-34.871939163711538, -7.760477584955536],
              [-34.872075675718825, -7.760433926324252],
              [-34.872168891069769, -7.760361752844037],
              [-34.872254943469486, -7.760296788313402],
              [-34.871756561498678, -7.759861228732195],
              [-34.871620526414581, -7.760012529682683],
              [-34.871362369215511, -7.760207425274603],
              [-34.871312195561885, -7.7602507029668],
              [-34.871240151115899, -7.760229491052222],
              [-34.871124716028994, -7.760158236425007],
              [-34.870973053252392, -7.760029731135509],
              [-34.870878949044837, -7.759900971886607],
              [-34.870806428675685, -7.759772117652894],
              [-34.870784209939693, -7.759628689545488],
              [-34.870762022198591, -7.759492438392678],
              [-34.870689343855076, -7.759327703385957],
              [-34.870609502560214, -7.759170176328779],
              [-34.870457110901398, -7.75887661908332],
              [-34.870370010670676, -7.758704771111835],
              [-34.870275780483603, -7.75854730704456],
              [-34.870124054717209, -7.758404448845926],
              [-34.869907512445849, -7.758247522692422],
              [-34.869604282877219, -7.758012037977434],
              [-34.869394967546889, -7.757862255783683],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3303',
        NOME_UC1: 'REFÚGIO DE VIDA SILVESTRE MATA DO CURADO',
        ID_WCMC2: null,
        CATEGORI3: 'Refúgio de Vida Silvestre',
        GRUPO4: 'PI',
        ESFERA5: 'estadual',
        ANO_CRIA6: '1987',
        GID7: '12254',
        QUALIDAD8:
          'Aproximado (O poligono representa uma estimativa dos limites da unidade).',
        ATO_LEGA9: 'Lei ordinária nº 9989/87 de 13/01/1987',
        DT_ULTIM10: '22/09/2017',
        CODIGO_U11: '0000.26.3303',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.958052491646967, -8.074149389233817],
              [-34.957938607482326, -8.074173852999806],
              [-34.957818464402784, -8.074144529099126],
              [-34.957662350588251, -8.074121356134468],
              [-34.957542524459605, -8.074157808821015],
              [-34.957458813043608, -8.074218008383932],
              [-34.957332871977769, -8.074230570216161],
              [-34.957176642181068, -8.074183478401624],
              [-34.957020269406662, -8.074106488774685],
              [-34.956905665353695, -8.073981459478919],
              [-34.956809378116851, -8.073922121783601],
              [-34.956737234678059, -8.073892566918252],
              [-34.956671264167554, -8.073898862832142],
              [-34.95661749453604, -8.073946958492186],
              [-34.956635984325423, -8.074048527867463],
              [-34.956654589096928, -8.07417401609257],
              [-34.956619222268152, -8.074305742240508],
              [-34.956553740681805, -8.074413692516394],
              [-34.956475914238773, -8.07444994423386],
              [-34.956361915092018, -8.074450489149967],
              [-34.956181714470532, -8.074409492280237],
              [-34.955977255068831, -8.074314791730588],
              [-34.955742450945202, -8.074148477609238],
              [-34.955592107121518, -8.074077437949271],
              [-34.955490049893847, -8.074065965949245],
              [-34.955412050477683, -8.074066338891896],
              [-34.955304224259216, -8.074102733587257],
              [-34.955214195937323, -8.074097183558552],
              [-34.955130023592822, -8.074061706721944],
              [-34.955057593198703, -8.073972355231943],
              [-34.954973247880943, -8.07390099962047],
              [-34.954793019263946, -8.073854021788289],
              [-34.954643049382156, -8.073860717640425],
              [-34.954523280244644, -8.07390912925192],
              [-34.954356028386009, -8.074065404152533],
              [-34.954213122294178, -8.074293322620438],
              [-34.954082359090222, -8.074551080909554],
              [-34.953993395603156, -8.07476678149226],
              [-34.953916172066442, -8.074928607421571],
              [-34.953844948484814, -8.075090403355334],
              [-34.953768155881122, -8.075341924721689],
              [-34.953709162173993, -8.075551502364011],
              [-34.953662140381468, -8.075755043052705],
              [-34.953603204665356, -8.075976579619994],
              [-34.953568037805312, -8.076150163505135],
              [-34.953526786003629, -8.076305837498515],
              [-34.953461562377051, -8.076467605436658],
              [-34.953372222948026, -8.076605569507294],
              [-34.953312654330112, -8.076695551900222],
              [-34.953211057031105, -8.076779755299723],
              [-34.953073516992582, -8.076876089597745],
              [-34.952971774715856, -8.076930395184903],
              [-34.952900120201178, -8.077002495681578],
              [-34.952864781367929, -8.077140200791968],
              [-34.952841384453841, -8.077265889986156],
              [-34.952829672499007, -8.07732574460206],
              [-34.952787901777619, -8.077373783270959],
              [-34.952691930491724, -8.077380221162743],
              [-34.95261981504855, -8.077356646259867],
              [-34.952559843493162, -8.0773629121782],
              [-34.952518159758419, -8.077428889734492],
              [-34.952530763574835, -8.077554407955212],
              [-34.952591625991744, -8.077733512873547],
              [-34.952688402152617, -8.077894507930916],
              [-34.952881323572548, -8.078084942871355],
              [-34.953122330619863, -8.078293087734508],
              [-34.953375223595145, -8.078477255756665],
              [-34.953543828243852, -8.07860202709225],
              [-34.953742548650204, -8.078750574299791],
              [-34.953851095756917, -8.0788636736663],
              [-34.953941614002886, -8.078970879057069],
              [-34.954020304311868, -8.079114020213863],
              [-34.95406911082749, -8.079281223198445],
              [-34.954117859352102, -8.079436466258091],
              [-34.954172175898755, -8.079501985885077],
              [-34.95427437810401, -8.07954335669749],
              [-34.954388436241778, -8.079554770706336],
              [-34.954610178620364, -8.079499892207494],
              [-34.954784036340286, -8.079469161523237],
              [-34.954922095298407, -8.07948046154976],
              [-34.95506632618487, -8.079527610355578],
              [-34.955198701138805, -8.079604715964919],
              [-34.955361191850315, -8.079705596446294],
              [-34.95544527920817, -8.079723134395568],
              [-34.955517279669245, -8.079722789448534],
              [-34.955625049896604, -8.079674434828258],
              [-34.955768647881342, -8.07959003045962],
              [-34.955918244821163, -8.079505597095425],
              [-34.95604401591379, -8.079457156488401],
              [-34.956175814957092, -8.079414666848198],
              [-34.956295873049967, -8.079426051861546],
              [-34.956518162343663, -8.079484786649724],
              [-34.956680624059636, -8.079579687168614],
              [-34.956806798089737, -8.079614963036242],
              [-34.956890942438697, -8.079644458910435],
              [-34.95697491381447, -8.079638077009824],
              [-34.957094597965401, -8.079571726510951],
              [-34.957214080147708, -8.079463516274744],
              [-34.957303734527649, -8.079391329791292],
              [-34.957387590921321, -8.079361029040722],
              [-34.957453591427324, -8.079360713089299],
              [-34.957543793722373, -8.079402139892938],
              [-34.957688226577623, -8.079491146436084],
              [-34.957844688338525, -8.079586074950566],
              [-34.957959006436099, -8.07965130562175],
              [-34.958067093614353, -8.079668727588684],
              [-34.958157237918392, -8.079698195467312],
              [-34.958271383042792, -8.079727548363637],
              [-34.958457354655167, -8.079720677538132],
              [-34.958613182514377, -8.079684051878143],
              [-34.958762866440821, -8.079617556401354],
              [-34.958906462425574, -8.079533150032681],
              [-34.959049973423781, -8.079430805776642],
              [-34.959181339534105, -8.079298618699342],
              [-34.959306560711703, -8.079136562805232],
              [-34.959455926687326, -8.079004290741166],
              [-34.959563522941565, -8.078920057346023],
              [-34.959653407285799, -8.078895706562408],
              [-34.959779408342747, -8.078895102655142],
              [-34.959893639453774, -8.078942394438927],
              [-34.959989985681659, -8.079013691059311],
              [-34.960056476111717, -8.079115029470042],
              [-34.96017685215552, -8.07919219107057],
              [-34.960278967374244, -8.079215619995598],
              [-34.960440968161784, -8.079214844114901],
              [-34.960632824747115, -8.079184024443888],
              [-34.960782651651236, -8.079147426779468],
              [-34.960956594357924, -8.079134632982653],
              [-34.961160536840026, -8.079121695207911],
              [-34.961322421645434, -8.079096999477265],
              [-34.961460421612642, -8.079096337578891],
              [-34.961532278096072, -8.079066093819501],
              [-34.961591988691708, -8.079006009238929],
              [-34.961657411287142, -8.078886098037971],
              [-34.961680862192416, -8.078772368768554],
              [-34.961692516156212, -8.078700554227652],
              [-34.961686140259218, -8.078622846711031],
              [-34.961637851663134, -8.078563280050918],
              [-34.961583476125334, -8.078485803498955],
              [-34.961583014196819, -8.078390128099317],
              [-34.961671136813663, -8.078001015604546],
              [-34.961747577471179, -8.07767773668825],
              [-34.961782191416134, -8.077390538515862],
              [-34.962231930088457, -8.077334562184829],
              [-34.962717813469055, -8.077308311692773],
              [-34.963197637903477, -8.077270129271295],
              [-34.96331160905477, -8.07726360239271],
              [-34.963279701574166, -8.076869086847168],
              [-34.96327842977071, -8.076605979498183],
              [-34.962990200961841, -8.076559524586328],
              [-34.962904756813892, -8.076260942400657],
              [-34.962837167553509, -8.075932375415903],
              [-34.962781608198817, -8.075609730402434],
              [-34.96274453963354, -8.075388653764355],
              [-34.962737731803472, -8.075221250810598],
              [-34.962725038000521, -8.075077795702365],
              [-34.962455300980977, -8.075132908165889],
              [-34.961951650699369, -8.075207082344642],
              [-34.96192641007103, -8.074950069940559],
              [-34.961883111586353, -8.07468118459828],
              [-34.961864273850573, -8.074507859673288],
              [-34.961839812101644, -8.074412299256014],
              [-34.961803524415735, -8.07435267460477],
              [-34.961671352430429, -8.074317429732789],
              [-34.961533180489994, -8.074282212856383],
              [-34.961220923865241, -8.074229890964528],
              [-34.960824552887033, -8.074154053160962],
              [-34.960458152688773, -8.074072090416994],
              [-34.960007436168304, -8.073924754024043],
              [-34.959616719198806, -8.073777128675253],
              [-34.9591599447322, -8.073617860352979],
              [-34.958901428743523, -8.073511460838656],
              [-34.958534885567602, -8.073399598282377],
              [-34.958234426884367, -8.073305358662102],
              [-34.957994285702959, -8.073276607673176],
              [-34.95782043198291, -8.073307339357543],
              [-34.957694691885806, -8.073361759927099],
              [-34.957599067546241, -8.073439955368602],
              [-34.957575413672281, -8.073511826900575],
              [-34.957575787614267, -8.073589563412728],
              [-34.957600162376615, -8.073667184942506],
              [-34.957690448658354, -8.073726551633445],
              [-34.957852649414626, -8.073767634489922],
              [-34.958002878256217, -8.073814754300036],
              [-34.958087223573997, -8.073886108911493],
              [-34.95811765528245, -8.073975661370653],
              [-34.958118087215517, -8.074065356807765],
              [-34.958052491646967, -8.074149389233817],
            ],
          ],
          [
            [
              [-34.965154487364579, -8.070075602825488],
              [-34.964371912838018, -8.072026394024087],
              [-34.964309907244328, -8.072108017467835],
              [-34.964190302081612, -8.07218991686918],
              [-34.964084980829071, -8.072247828431218],
              [-34.963965260684219, -8.072305809982655],
              [-34.963762815618118, -8.073124823697238],
              [-34.964185787367619, -8.073242390257594],
              [-34.964700074416754, -8.073383436734963],
              [-34.965147091979496, -8.073510455252947],
              [-34.965757539285264, -8.07368452559089],
              [-34.966699553055214, -8.073933543692311],
              [-34.967237909914111, -8.074088823097417],
              [-34.967266222773652, -8.073988225749039],
              [-34.967304136561339, -8.073887582407647],
              [-34.96736588719439, -8.073753337294136],
              [-34.967413167945942, -8.073604810260031],
              [-34.967441272837611, -8.073461159181768],
              [-34.967454861854904, -8.073293659243054],
              [-34.967468150918677, -8.073063969694543],
              [-34.967486446914343, -8.072877310879415],
              [-34.967500244899476, -8.072752864670488],
              [-34.967538111694225, -8.072642653389209],
              [-34.967585763388641, -8.072570666874805],
              [-34.96764774498596, -8.072484259461058],
              [-34.967848415620203, -8.07229193981021],
              [-34.9680634851467, -8.072099551169948],
              [-34.968235702942856, -8.071979126047577],
              [-34.968259053863321, -8.071845065905793],
              [-34.968474447339787, -8.071719649845257],
              [-34.968661203006775, -8.071627858553409],
              [-34.968818973917841, -8.071497935480478],
              [-34.968967237886488, -8.071387193280431],
              [-34.969120394804918, -8.071295562963799],
              [-34.969326303335421, -8.071194111746122],
              [-34.969498891074451, -8.071150226143445],
              [-34.969705216540632, -8.071134881385387],
              [-34.969916434956581, -8.071138649510786],
              [-34.970089300652759, -8.071152168547888],
              [-34.970214306686721, -8.071194621369555],
              [-34.970401803239426, -8.071255909117113],
              [-34.97061821555878, -8.071340976735716],
              [-34.970844204809829, -8.071421213391455],
              [-34.971103909791587, -8.071525207921834],
              [-34.971353897863054, -8.071605329595211],
              [-34.971589534035189, -8.07169508719798],
              [-34.971719293040366, -8.071727949083256],
              [-34.971796091465663, -8.071727579139772],
              [-34.971863150984234, -8.071698553369345],
              [-34.971920470596082, -8.07164087077193],
              [-34.97195845237303, -8.071554578340537],
              [-34.971977211297236, -8.071463594925008],
              [-34.972000328253429, -8.071281697083316],
              [-34.971994786403684, -8.071128641040382],
              [-34.972027875230857, -8.071023236725511],
              [-34.972075409943152, -8.070927330361203],
              [-34.972137529519074, -8.070869624767338],
              [-34.972238048807647, -8.07081173420176],
              [-34.972338544099834, -8.070749060666181],
              [-34.972405371654141, -8.070672197195876],
              [-34.972448175391584, -8.070590664738027],
              [-34.972481171233056, -8.070466126543215],
              [-34.972495107196714, -8.070370382154168],
              [-34.972489750318417, -8.070255595871105],
              [-34.972460463608932, -8.070155275480255],
              [-34.972411931050289, -8.070045481135399],
              [-34.97231982887206, -8.069859355239007],
              [-34.972232641640041, -8.069697124196036],
              [-34.972150394350663, -8.06956357297655],
              [-34.972044125244445, -8.06942535276942],
              [-34.971875247637321, -8.069244379786491],
              [-34.971696818094891, -8.069073020736552],
              [-34.971547257326243, -8.068915873617689],
              [-34.971426657317075, -8.068792074309929],
              [-34.971354172930809, -8.068691962887389],
              [-34.971276864584084, -8.068587090491324],
              [-34.971238071932149, -8.068505951973385],
              [-34.971199418258799, -8.068453515275394],
              [-34.971136880747167, -8.068425113409605],
              [-34.971079282178188, -8.068425390367201],
              [-34.971041069436971, -8.068463845098774],
              [-34.970988757757048, -8.068564558429538],
              [-34.970912261285306, -8.068627115982745],
              [-34.970768567316469, -8.068689998486537],
              [-34.970572143731978, -8.068767484861425],
              [-34.97043797970197, -8.068815969462381],
              [-34.970318191567799, -8.068859601103881],
              [-34.97020818833326, -8.068941455512336],
              [-34.970060064343166, -8.069080900532258],
              [-34.969907233374698, -8.069239502428578],
              [-34.969787677204799, -8.069330971769931],
              [-34.969663298074423, -8.069417679137716],
              [-34.969558117800375, -8.069504294519692],
              [-34.969457714493942, -8.069586102935181],
              [-34.96931404252156, -8.069653768408953],
              [-34.96915590166757, -8.06970715196214],
              [-34.968983337924946, -8.069755820534827],
              [-34.968849195891451, -8.069809088105703],
              [-34.9687295927287, -8.069890988507112],
              [-34.968566882875002, -8.069992231756563],
              [-34.968428126848814, -8.070083793083777],
              [-34.968275062916099, -8.070194557280287],
              [-34.968145859825199, -8.070276504674585],
              [-34.96802134071627, -8.070334509222544],
              [-34.967891951653989, -8.070378185857015],
              [-34.967733671821456, -8.070402866590319],
              [-34.967570452046346, -8.070398867500263],
              [-34.967335187817163, -8.070385646417312],
              [-34.967162299124602, -8.070367341410238],
              [-34.966970095593553, -8.070325210539174],
              [-34.966796998933113, -8.070263851802284],
              [-34.966676584895453, -8.070178321254405],
              [-34.966556032879197, -8.070064087886607],
              [-34.966459363701176, -8.069925819686606],
              [-34.966362486555283, -8.069744498756789],
              [-34.966284671287163, -8.069534382021262],
              [-34.966231177789382, -8.069391122883053],
              [-34.96621135402949, -8.069262053679504],
              [-34.966225313989618, -8.069171093260559],
              [-34.966234359003437, -8.06905623698812],
              [-34.964283406633342, -8.067616089655223],
              [-34.96343477784508, -8.067816298799444],
              [-34.963334095581565, -8.067840700575209],
              [-34.963276635991221, -8.067869679352741],
              [-34.963187886295344, -8.068377196103118],
              [-34.963346721042058, -8.068467327649136],
              [-34.963510378749319, -8.068562220168728],
              [-34.963645352654225, -8.068681169516989],
              [-34.96374192784657, -8.068800303836989],
              [-34.963819418164732, -8.068943447992766],
              [-34.963887379543998, -8.069100990051426],
              [-34.963916687249878, -8.069206094412154],
              [-34.963936418024069, -8.069316028735706],
              [-34.965154487364579, -8.070075602825488],
            ],
          ],
          [
            [
              [-34.96111624910435, -8.070774040590674],
              [-34.961044394620849, -8.070804284350082],
              [-34.960936339438049, -8.07079284234568],
              [-34.960840139187809, -8.070751443537775],
              [-34.960725680112382, -8.070656314054411],
              [-34.960538702656137, -8.0704538951936],
              [-34.960230978214106, -8.070096578220328],
              [-34.960050088699575, -8.069912068251336],
              [-34.959827026526163, -8.069691882476711],
              [-34.959604195317034, -8.06951953340182],
              [-34.959209471084407, -8.06978453545927],
              [-34.959270392492584, -8.069975599302381],
              [-34.959325456923303, -8.07019659095349],
              [-34.959374320430186, -8.070375752862875],
              [-34.959435039869582, -8.070524957968754],
              [-34.959495615331221, -8.070644265262308],
              [-34.959616276330493, -8.070781224487348],
              [-34.959748850253327, -8.070900186833883],
              [-34.959875511207684, -8.071037117063366],
              [-34.960008374085824, -8.071215876034572],
              [-34.960105237233442, -8.071394807979344],
              [-34.960214302259992, -8.071615539670216],
              [-34.960275137681535, -8.071788664626014],
              [-34.960305800354298, -8.071926053784949],
              [-34.960306694215966, -8.072111425621531],
              [-34.961159864656402, -8.072352511709711],
              [-34.962067120679464, -8.072611275725123],
              [-34.962938349976376, -8.072864230751632],
              [-34.963581323019916, -8.073064460948169],
              [-34.963829216768005, -8.072214133462287],
              [-34.963834898772198, -8.072148328879464],
              [-34.963816378987318, -8.072040780541691],
              [-34.963725715763516, -8.071903679338583],
              [-34.963647024454417, -8.07176054018186],
              [-34.963610591790818, -8.071671017718288],
              [-34.963604186898209, -8.071587330239232],
              [-34.963627637803413, -8.071473600969892],
              [-34.963752105097093, -8.071156072051522],
              [-34.963964922971599, -8.070497269338457],
              [-34.963235617918166, -8.069813086120144],
              [-34.962843808118059, -8.069438234197564],
              [-34.962584601236308, -8.069188323584072],
              [-34.962524343725541, -8.069134793877705],
              [-34.962368779826079, -8.069225237199992],
              [-34.962147592362548, -8.069393732985894],
              [-34.961956433669982, -8.069568064756345],
              [-34.961807331654263, -8.069754154482599],
              [-34.961712199238818, -8.069934006286122],
              [-34.961593210980645, -8.070143870884317],
              [-34.961498251538472, -8.070359600462684],
              [-34.961402975145333, -8.070509553453833],
              [-34.961301582814947, -8.070635615590684],
              [-34.961211959430486, -8.07071378303662],
              [-34.96111624910435, -8.070774040590674],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '3316',
        NOME_UC1: 'ÁREA DE RELEVANTE INTERESSE ECOLÓGICO IPOJUCA-MEREPE',
        ID_WCMC2: null,
        CATEGORI3: 'Área de Relevante Interesse Ecológico',
        GRUPO4: 'US',
        ESFERA5: 'estadual',
        ANO_CRIA6: '2014',
        GID7: '12312',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 41.405/14 de 30/12/2014',
        DT_ULTIM10: '24/04/2018',
        CODIGO_U11: '0000.26.3316',
        NOME_ORG12: 'Agência Estadual do Meio Ambiente de Pernambuco',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-34.972998433704539, -8.409875943298914],
              [-34.97293743724795, -8.410525939350469],
              [-34.973189435472221, -8.410655947250442],
              [-34.973338434323622, -8.410788939822478],
              [-34.973395428656147, -8.410847939227658],
              [-34.973448433835735, -8.410925942995927],
              [-34.973463430438791, -8.411027940277815],
              [-34.973448434269109, -8.411117947141525],
              [-34.973421428839735, -8.411306940847883],
              [-34.973425430240681, -8.411571942355899],
              [-34.973467427814889, -8.411736940644904],
              [-34.973521434474321, -8.411958940182007],
              [-34.973593431728034, -8.412165942149153],
              [-34.973616433758657, -8.41231594770395],
              [-34.97356643079803, -8.412475947519967],
              [-34.973540434235765, -8.412770941173887],
              [-34.97356642685844, -8.413057944489628],
              [-34.973734431096339, -8.413420940603791],
              [-34.973853807537438, -8.413427249267198],
              [-34.974028649742316, -8.41361545900673],
              [-34.974204674078997, -8.413915632573907],
              [-34.9743476985062, -8.414233290035648],
              [-34.974519564040982, -8.414487067281456],
              [-34.974654560670757, -8.414662630598723],
              [-34.974822371153294, -8.414935536291079],
              [-34.97499078102873, -8.415203939169682],
              [-34.975136180524643, -8.415476609704568],
              [-34.975140867635915, -8.415490471857209],
              [-34.975239252176145, -8.415749087726015],
              [-34.975239251801469, -8.415758332763378],
              [-34.97531941659107, -8.415997462806921],
              [-34.975393146421233, -8.416304575712118],
              [-34.975463514630071, -8.41658314899802],
              [-34.975466708108613, -8.416728761216358],
              [-34.97546670808228, -8.416731926694911],
              [-34.975447497550093, -8.416981999872332],
              [-34.975437888807143, -8.417175094827016],
              [-34.975441046394842, -8.417277004554418],
              [-34.975450485717545, -8.417489059227057],
              [-34.975482382081339, -8.417704551911278],
              [-34.975533591418809, -8.417866314056761],
              [-34.975597647340408, -8.418088535898903],
              [-34.975652147839675, -8.418263286976373],
              [-34.975767667704488, -8.418488946030381],
              [-34.97581246865505, -8.418687884738491],
              [-34.975844202616997, -8.418988816545538],
              [-34.975872716928436, -8.419371377363078],
              [-34.975907578309631, -8.419689838942224],
              [-34.975904382313665, -8.419702453650171],
              [-34.975939254316742, -8.419976298589379],
              [-34.975945620305524, -8.41998572346154],
              [-34.975948803166588, -8.419992012501361],
              [-34.976002886818456, -8.420171402146254],
              [-34.976009241166487, -8.420347907526994],
              [-34.976015502711554, -8.42059061103004],
              [-34.976040975927944, -8.42083959455303],
              [-34.976091859726402, -8.421041258204784],
              [-34.976187108250812, -8.42120488590715],
              [-34.976193454455846, -8.421208019913445],
              [-34.976305033527154, -8.421434918168849],
              [-34.97631458530897, -8.421444367681298],
              [-34.976451629686274, -8.421608298308323],
              [-34.976457998595784, -8.421614598989091],
              [-34.976696524166613, -8.421803322179453],
              [-34.977018177121529, -8.422036485542581],
              [-34.97717732702467, -8.422184760943823],
              [-34.977193257589796, -8.422187917573011],
              [-34.977314247928369, -8.422282408953626],
              [-34.977326984545371, -8.4222887088851],
              [-34.977377898263903, -8.422392636098307],
              [-34.977377893981533, -8.422405236561772],
              [-34.977349167220567, -8.422540772723014],
              [-34.977342788802041, -8.422550236706945],
              [-34.977199409737018, -8.422651091610588],
              [-34.977189844321266, -8.422660553959433],
              [-34.977170711912954, -8.422676328309349],
              [-34.977151584425698, -8.422685793421856],
              [-34.977017635412771, -8.422767849393253],
              [-34.977014433816514, -8.422777320621359],
              [-34.976931273317248, -8.422941578857222],
              [-34.97693391020929, -8.423254785471897],
              [-34.97715046300452, -8.423540839939896],
              [-34.977338906303515, -8.423750279488971],
              [-34.977342102368922, -8.423756592072909],
              [-34.977492592039376, -8.423974690299218],
              [-34.977611090826642, -8.42425696853384],
              [-34.977649476866979, -8.424564276301119],
              [-34.977643279381319, -8.424910405351682],
              [-34.977662674000022, -8.425190342750691],
              [-34.977704507514034, -8.425390922593895],
              [-34.977804476525371, -8.42560076432156],
              [-34.977814199979335, -8.425605600967799],
              [-34.977896813387737, -8.425752561117688],
              [-34.978003642725092, -8.425877802068262],
              [-34.978064298582765, -8.425950004694776],
              [-34.978129740812136, -8.426108707689814],
              [-34.978219661230675, -8.426299613496965],
              [-34.978306971476769, -8.426499278532983],
              [-34.978408756488442, -8.426609793587986],
              [-34.978510459435334, -8.426710609462274],
              [-34.978512879842917, -8.426713008742849],
              [-34.978624292332583, -8.426792144339325],
              [-34.978750454393023, -8.426861653524959],
              [-34.978852605076369, -8.426991188303298],
              [-34.978938063872107, -8.427180945415859],
              [-34.979040571369993, -8.427344524501477],
              [-34.979101713243686, -8.427457717703057],
              [-34.979124533269506, -8.427651201076745],
              [-34.979084840859507, -8.427800432435333],
              [-34.978752389163787, -8.428406167960489],
              [-34.978704368285022, -8.4285250385091],
              [-34.978712512355159, -8.428685172524894],
              [-34.978749617080723, -8.428783200695809],
              [-34.9787808095221, -8.428919340764006],
              [-34.978827428148513, -8.42909512326756],
              [-34.978898980219967, -8.429273861525012],
              [-34.978983794115983, -8.429383594071512],
              [-34.979072237829264, -8.429470284652504],
              [-34.979133983496475, -8.429545474648092],
              [-34.97920477697761, -8.429663224079137],
              [-34.979209137682624, -8.429667545095452],
              [-34.979291671283811, -8.429871250380396],
              [-34.979354886149956, -8.430102627145033],
              [-34.979423165509296, -8.430292841639968],
              [-34.979566631924484, -8.430441497115515],
              [-34.979706036064826, -8.430545195174055],
              [-34.979883791534327, -8.430599416957159],
              [-34.981532002592736, -8.429672026230838],
              [-34.98139239570002, -8.430873080113203],
              [-34.981387951832701, -8.431062221477273],
              [-34.981394603810521, -8.431286540388241],
              [-34.981413029936853, -8.431438589181413],
              [-34.981398345053542, -8.431547476113066],
              [-34.981349322688843, -8.431654445263586],
              [-34.981287737910911, -8.431738147155242],
              [-34.981265969203506, -8.43180906142797],
              [-34.981361273787343, -8.432080453854498],
              [-34.981420646956302, -8.432158860725915],
              [-34.981496244609865, -8.432214061105364],
              [-34.981568200474818, -8.432292407386502],
              [-34.981589822056542, -8.432411839864903],
              [-34.981814713759306, -8.433555825727653],
              [-34.981852983380875, -8.433675562665879],
              [-34.981914958140919, -8.433861889202536],
              [-34.981961239129575, -8.434108776226998],
              [-34.981988551381328, -8.434288264483802],
              [-34.982040455038529, -8.434482487691003],
              [-34.982143303287025, -8.434669377685132],
              [-34.982375965304428, -8.434996586775313],
              [-34.982548387076598, -8.43520357972025],
              [-34.982737570284442, -8.435453583690792],
              [-34.98284588156357, -8.435597871775725],
              [-34.982913349167497, -8.435741877690125],
              [-34.983008007993625, -8.435862758764085],
              [-34.983106334020619, -8.435962914901564],
              [-34.9831983900472, -8.436092215641096],
              [-34.983268610651486, -8.436232440314066],
              [-34.983347195461725, -8.436388673956042],
              [-34.983376525240942, -8.436529594898127],
              [-34.983553605426444, -8.436501307500075],
              [-34.98564381671261, -8.438318895968916],
              [-34.9863369791656, -8.438579264183529],
              [-34.986935808317128, -8.438965404781102],
              [-34.987363197779842, -8.43888314277102],
              [-34.987327252110759, -8.438423094314464],
              [-34.986457035736116, -8.437661538665065],
              [-34.986248600593434, -8.43626229376533],
              [-34.985451907853708, -8.435835478132127],
              [-34.985054133892945, -8.435191767028453],
              [-34.984308614964398, -8.435165346527146],
              [-34.983426959804369, -8.433762443310961],
              [-34.983665926186276, -8.433407594763224],
              [-34.982953351824605, -8.432026795736828],
              [-34.983852332084048, -8.429885991683607],
              [-34.986605799741184, -8.427169180463435],
              [-34.988086401582251, -8.429055933651068],
              [-34.990211803936177, -8.434048450700674],
              [-34.991306143181816, -8.436168024960532],
              [-34.992289621387279, -8.438848812134577],
              [-34.993908339000072, -8.441627980559733],
              [-34.994148852274655, -8.443061493421331],
              [-34.993787302525007, -8.443359214436018],
              [-34.992505921725432, -8.441918339645865],
              [-34.991643900701384, -8.440298774896958],
              [-34.990914543785003, -8.440539782701945],
              [-34.991188802507168, -8.441936335586266],
              [-34.990408882278786, -8.444588848091083],
              [-34.990619441701682, -8.449884957207308],
              [-34.992511343319883, -8.447345391575629],
              [-34.993486234996126, -8.447002150917143],
              [-34.991948978797261, -8.446657140452205],
              [-34.992359691676825, -8.444792447078255],
              [-34.994116284858244, -8.446496587567969],
              [-34.994928168597092, -8.446269353276143],
              [-34.995542252696488, -8.445874881307944],
              [-34.99559029396562, -8.444587606235334],
              [-34.995241946892833, -8.443496629761636],
              [-34.996067124354028, -8.443278177138481],
              [-34.996908894312575, -8.44356768821897],
              [-34.997481037547018, -8.443351968951234],
              [-34.997833590697304, -8.443441656219703],
              [-34.998453152872131, -8.442929361866568],
              [-34.998994966771619, -8.44364627368938],
              [-34.998912767991293, -8.444416170716817],
              [-34.998533769219563, -8.444251234861824],
              [-34.998419538988657, -8.444301528757622],
              [-34.998583331852174, -8.444690318055665],
              [-34.998423939465447, -8.445303008547139],
              [-34.998844297208393, -8.445219591124491],
              [-34.999308909450747, -8.44519039594932],
              [-34.999714318562816, -8.445162191344517],
              [-35.000090984917527, -8.445161739395683],
              [-35.000453467603322, -8.445120070209574],
              [-35.000994338567565, -8.444969335792768],
              [-35.001492254096839, -8.444736610361829],
              [-35.001910975995671, -8.444529615263239],
              [-35.002453771782335, -8.4442680531895],
              [-35.003023405463225, -8.443938024490128],
              [-35.003343467748998, -8.44384165998715],
              [-35.003597574911112, -8.443811597905386],
              [-35.003904590666416, -8.443866264786038],
              [-35.004198494148419, -8.443937732740412],
              [-35.004476912069912, -8.444000335048404],
              [-35.004907303323051, -8.444062958108294],
              [-35.005392846784048, -8.444063029324965],
              [-35.005743434951718, -8.444050476111672],
              [-35.006089433823682, -8.444054643662092],
              [-35.00648191229488, -8.444084010500251],
              [-35.006794498799692, -8.444142628243597],
              [-35.007018555761114, -8.444167822596173],
              [-35.007280989867859, -8.444155494846713],
              [-35.007538972851094, -8.444084437206309],
              [-35.007805646883156, -8.444000724026326],
              [-35.008029646453885, -8.44388785491304],
              [-35.008296047639384, -8.443766590668057],
              [-35.008688087065643, -8.443582850211644],
              [-35.008881843382071, -8.443470251496187],
              [-35.009116807304181, -8.443283018497976],
              [-35.009330600906765, -8.443025214387539],
              [-35.009440418271218, -8.442854233324891],
              [-35.009527196561706, -8.44258560866084],
              [-35.009620907430907, -8.441760326184555],
              [-35.009680092278963, -8.441210378005078],
              [-35.009764703256984, -8.440712742743631],
              [-35.009871266639855, -8.440258558993751],
              [-35.010113084046758, -8.439599136100734],
              [-35.010307103003193, -8.439148559892985],
              [-35.010497911251413, -8.43862527526553],
              [-35.010458371851882, -8.438615991408371],
              [-35.010317369382427, -8.43843599672967],
              [-35.009886369001464, -8.437515994952944],
              [-35.00979537302532, -8.437340995754246],
              [-35.009741372688964, -8.437170995794155],
              [-35.009634374586362, -8.43691599348754],
              [-35.009451370300596, -8.436427995837946],
              [-35.008955374159918, -8.435673991579035],
              [-35.00873037589556, -8.435451992623346],
              [-35.00854037837388, -8.435238989763739],
              [-35.008387373012035, -8.435068990292283],
              [-35.008250379167329, -8.434899993523734],
              [-35.008067374432137, -8.434623991058523],
              [-35.007830378374763, -8.434421994947625],
              [-35.007647377341328, -8.434230995062686],
              [-35.007468380289929, -8.434039992633055],
              [-35.007338377599901, -8.433731987952918],
              [-35.007250374877366, -8.433402994345155],
              [-35.007197381186621, -8.433052993396036],
              [-35.007121380036871, -8.432765992265908],
              [-35.007025381056302, -8.432468986718716],
              [-35.006857377184218, -8.432185990852579],
              [-35.006655382032172, -8.432092990349505],
              [-35.00643838187375, -8.432054993428906],
              [-35.005949376827594, -8.432007991493688],
              [-35.00523238142538, -8.431826988513729],
              [-35.005007379034787, -8.431826990574868],
              [-35.00480538242617, -8.431789982708118],
              [-35.004637378027105, -8.43173199094522],
              [-35.004332384354385, -8.431455985378744],
              [-35.004134384387392, -8.431184983438211],
              [-35.004015383034329, -8.431014988503048],
              [-35.003851383552778, -8.430898984236169],
              [-35.003668385899452, -8.430855984520049],
              [-35.003638381767885, -8.430635986602994],
              [-35.003477386927379, -8.430295982625609],
              [-35.003477385997542, -8.429595988094514],
              [-35.003386382135567, -8.428915984578659],
              [-35.003168388083843, -8.428435988433694],
              [-35.002798390147447, -8.428705985516784],
              [-35.002688390979856, -8.428735981192931],
              [-35.002547390283119, -8.42863598605541],
              [-35.001978385291437, -8.427725983854815],
              [-35.001837385118293, -8.427715981652693],
              [-35.001425388605298, -8.427265982811059],
              [-35.001467393492497, -8.427065984279254],
              [-35.001406390081648, -8.426955980062575],
              [-35.001208390279636, -8.427015984826484],
              [-35.001116388448537, -8.427095978946694],
              [-35.001109394527646, -8.427195979108916],
              [-35.001028387262991, -8.427215981851182],
              [-35.000819387265594, -8.427085985207444],
              [-35.000437389798911, -8.42716598394898],
              [-35.000197391385193, -8.427135982913024],
              [-35.000037389179809, -8.426565984181549],
              [-34.999926392660669, -8.426385975301972],
              [-34.999678395379796, -8.426505979548512],
              [-34.999266392488657, -8.426585976261352],
              [-34.999148389661769, -8.426515974699065],
              [-34.998915394077372, -8.426155976055266],
              [-34.999976394116288, -8.425605982387394],
              [-35.000128396192707, -8.425235977246603],
              [-35.000048395857426, -8.424935975843059],
              [-35.000079398617203, -8.424645976677338],
              [-35.000449394416215, -8.424085978696418],
              [-35.000319392468541, -8.423655983454449],
              [-35.000197394604712, -8.422885979177432],
              [-35.000067397098888, -8.422795978948217],
              [-34.999949396924151, -8.422545981444774],
              [-35.002085394818387, -8.42116097870902],
              [-35.002646396325652, -8.418175981484016],
              [-35.004488399568814, -8.417865980646505],
              [-35.004687403238265, -8.417897979392597],
              [-35.004866398277237, -8.417885983010558],
              [-35.005019395368315, -8.41779698090785],
              [-35.005179399537077, -8.417585984378086],
              [-35.005347398153233, -8.417495983550833],
              [-35.005492400494873, -8.417345984065037],
              [-35.005637397313038, -8.417201987136046],
              [-35.005732397945167, -8.417101980888793],
              [-35.005988400302599, -8.417065987562969],
              [-35.006209399879452, -8.417155982386227],
              [-35.006365399494271, -8.417286988940388],
              [-35.006571398093051, -8.417467984343123],
              [-35.006731400705704, -8.417515983137465],
              [-35.006888394112885, -8.417645989933481],
              [-35.007025401279712, -8.417716986250504],
              [-35.007166401369801, -8.417725983951438],
              [-35.007285399955983, -8.417668984576128],
              [-35.007407396160033, -8.417525990542323],
              [-35.007777400300512, -8.417375988870303],
              [-35.008215397137931, -8.417305986776617],
              [-35.008337393810066, -8.416935989562615],
              [-35.008288396256106, -8.416565989428811],
              [-35.008349400342212, -8.416205985790182],
              [-35.008166397236167, -8.415735983085893],
              [-35.008166396162082, -8.415615986349488],
              [-35.008456395012175, -8.415105987301839],
              [-35.008276397320024, -8.414945986367645],
              [-35.008017396822993, -8.414915986171993],
              [-35.007887404174333, -8.414815990059504],
              [-35.007819396041398, -8.414665984444134],
              [-35.008028396793542, -8.414525990232171],
              [-35.008807403498523, -8.414585985532293],
              [-35.008955399911471, -8.414495986425303],
              [-35.009066399509436, -8.414505986798966],
              [-35.009116402801183, -8.414415991330682],
              [-35.009207399537203, -8.414435983651716],
              [-35.009287403548441, -8.414295988372631],
              [-35.00937939777679, -8.413945987350852],
              [-35.009318399275116, -8.413915988123955],
              [-35.009245400704323, -8.41375599205381],
              [-35.009299404552621, -8.413675992438],
              [-35.009299402260908, -8.413465984712065],
              [-35.009158397376268, -8.41302598836668],
              [-35.00903540356444, -8.412805987203171],
              [-35.008818402761939, -8.412585987213594],
              [-35.009028398748725, -8.412235988966703],
              [-35.009138401250098, -8.411835986277753],
              [-35.009127399251497, -8.411615991305407],
              [-35.008856407226943, -8.411065982297934],
              [-35.008849406112496, -8.410855983128629],
              [-35.009165408381875, -8.410695988146133],
              [-35.009257401519314, -8.410565984222394],
              [-35.009196405551478, -8.410405989920374],
              [-35.009077408859099, -8.410365990315988],
              [-35.008147404659823, -8.410415985850827],
              [-35.008047408237211, -8.41022598782582],
              [-35.008086405330914, -8.410115989015893],
              [-35.008208403163472, -8.41004598413136],
              [-35.009077404757392, -8.409855990771595],
              [-35.008997404843242, -8.409275987450364],
              [-35.009165404275905, -8.40907598748873],
              [-35.009219409976573, -8.408895987737056],
              [-35.009589408212747, -8.408335987171418],
              [-35.009646406591379, -8.408165988430126],
              [-35.009688406549138, -8.407875988286843],
              [-35.008955406062313, -8.40707598779816],
              [-35.008929410842867, -8.406355986457475],
              [-35.009039410715665, -8.404932983423876],
              [-35.009097408577787, -8.403505988102461],
              [-35.009257412993485, -8.403149981266093],
              [-35.009417415336493, -8.402775984872376],
              [-35.009127418786129, -8.402055984240929],
              [-35.008757416348928, -8.402125988982082],
              [-35.008418412313475, -8.4019659845059],
              [-35.008227419459566, -8.402075981263334],
              [-35.008067419367563, -8.402035982009881],
              [-35.007998416003318, -8.401945979334529],
              [-35.007986414635219, -8.401735987348541],
              [-35.006766418894081, -8.40154597776464],
              [-35.006075417778064, -8.401505981351034],
              [-35.004958421896958, -8.401525982603038],
              [-35.004706421027898, -8.401435980074895],
              [-35.004538422384201, -8.401165978872985],
              [-35.004439416217181, -8.40110597823338],
              [-35.004187423872423, -8.401105977583343],
              [-35.003607421412944, -8.40083597590592],
              [-35.002657418484368, -8.40025597490237],
              [-34.993807434518381, -8.398335963150881],
              [-34.991686433892625, -8.398075964268955],
              [-34.989710436678841, -8.397704958727076],
              [-34.987563438921654, -8.397216958677395],
              [-34.985396443980569, -8.396785953975073],
              [-34.984355438791702, -8.3965715434685],
              [-34.983653714785952, -8.397109927967101],
              [-34.983125611248497, -8.398311823169573],
              [-34.981013436860202, -8.399212952948153],
              [-34.980326444089364, -8.399503948408944],
              [-34.97987244201817, -8.399636952033422],
              [-34.978938445485106, -8.39994594789],
              [-34.977297441087039, -8.400615950039391],
              [-34.976416444027883, -8.401065945213569],
              [-34.976145446302183, -8.400995942678197],
              [-34.975836441037096, -8.400775946510512],
              [-34.97549744415749, -8.400665947530065],
              [-34.975207440695613, -8.400655939742451],
              [-34.972998433704539, -8.409875943298914],
            ],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        ID_UC0: '15',
        NOME_UC1: 'ÁREA DE PROTEÇÃO AMBIENTAL DE FERNANDO DE NORONHA',
        ID_WCMC2: '116096',
        CATEGORI3: 'Área de Proteção Ambiental',
        GRUPO4: 'US',
        ESFERA5: 'federal',
        ANO_CRIA6: '1986',
        GID7: '25',
        QUALIDAD8:
          'Correto (O poligono corresponde ao memorial descritivo do ato legal de criação).',
        ATO_LEGA9: 'Decreto nº 92755 de 05/06/1986',
        DT_ULTIM10: '27/09/2007',
        CODIGO_U11: '0000.00.0015',
        NOME_ORG12: 'Instituto Chico Mendes de Conservação da Biodiversidade',
      },
      geometry: {
        type: 'MultiPolygon',
        coordinates: [
          [
            [
              [-33.718880380012926, -3.803048045948373],
              [-33.719193184002968, -3.803473147418397],
              [-33.719406802944576, -3.803771403939634],
              [-33.719807337158478, -3.80412379206407],
              [-33.720207892362545, -3.80450335295566],
              [-33.720608425557195, -3.804910084614607],
              [-33.72082586346697, -3.805199540212706],
              [-33.721108143774558, -3.805515910603529],
              [-33.721367537316993, -3.805759823606842],
              [-33.721653634592926, -3.806067137076612],
              [-33.721958801691279, -3.80638350747564],
              [-33.722241105033852, -3.806600482718031],
              [-33.722355539955799, -3.806690866984693],
              [-33.722710299620161, -3.806952871867253],
              [-33.722939168489766, -3.807061114021999],
              [-33.723454135673705, -3.807368427573889],
              [-33.723923353243791, -3.807765892337189],
              [-33.724251407165035, -3.807991878518821],
              [-33.724640508582986, -3.808063893041286],
              [-33.725189818552643, -3.80809059900929],
              [-33.725399619586966, -3.808226012924483],
              [-33.725563646049189, -3.808334489054054],
              [-33.725845926398463, -3.80853334945175],
              [-33.726238846720555, -3.80877723950333],
              [-33.726635560017598, -3.808993958789123],
              [-33.727146734261986, -3.809228814960564],
              [-33.727772318438269, -3.809526837631985],
              [-33.72814235102733, -3.809607887070357],
              [-33.728443699228201, -3.809734221096198],
              [-33.728767958219592, -3.809860624129732],
              [-33.729160854551573, -3.810077343414231],
              [-33.729679640633314, -3.810574436342479],
              [-33.730164108163734, -3.810691736511424],
              [-33.7307630096483, -3.810808804723401],
              [-33.731041472064781, -3.810917047896107],
              [-33.731594599941985, -3.811106571471109],
              [-33.732052362732603, -3.81117858601842],
              [-33.73251392045961, -3.81133190587073],
              [-33.732838178476818, -3.811386014523542],
              [-33.733295941283579, -3.811412744458758],
              [-33.733685018714873, -3.81144848429215],
              [-33.734051233339059, -3.811529555729076],
              [-33.734261034392752, -3.811610837108169],
              [-33.734379287270166, -3.811728393143746],
              [-33.734772207627771, -3.811872865047319],
              [-33.735252880215576, -3.81192674175823],
              [-33.735966192674091, -3.811989466476897],
              [-33.736813033902706, -3.812079106012897],
              [-33.737728557506962, -3.812159689651105],
              [-33.73873560730695, -3.812149911096059],
              [-33.740013527651058, -3.812085300107737],
              [-33.741516498967513, -3.811939385896447],
              [-33.742447274506766, -3.811811841322624],
              [-33.743381867985491, -3.811756754129615],
              [-33.744133341166552, -3.81162038456696],
              [-33.744564399275674, -3.811484480885439],
              [-33.745422694506409, -3.811275632981803],
              [-33.745853751622136, -3.811121382457391],
              [-33.746464085102772, -3.810958052074986],
              [-33.746918028993171, -3.810849111170731],
              [-33.747307130456932, -3.810794511777844],
              [-33.747658072378705, -3.810432344005095],
              [-33.748115834182883, -3.810468106862997],
              [-33.749084746258482, -3.810666246513738],
              [-33.75011852373494, -3.810882476032859],
              [-33.751037843273423, -3.811053676896614],
              [-33.75140405693044, -3.811044131109717],
              [-33.751884706528863, -3.811070836053441],
              [-33.752392061841384, -3.811215307998354],
              [-33.753013851082692, -3.811431793367648],
              [-33.753475407829839, -3.811530979683926],
              [-33.754379454450358, -3.811864766150161],
              [-33.755024153491995, -3.812054313758428],
              [-33.755508621029968, -3.812153243085148],
              [-33.755943496037169, -3.812207143779696],
              [-33.756427940568877, -3.812324443949173],
              [-33.756931476942377, -3.812396202515445],
              [-33.757488423769793, -3.81263103570461],
              [-33.758320014071913, -3.812910711608434],
              [-33.759372860416846, -3.813009199143096],
              [-33.759967943963602, -3.813053995973189],
              [-33.760063310076156, -3.813099071621505],
              [-33.76042952469156, -3.813207291826385],
              [-33.76091396923313, -3.81329742022864],
              [-33.76169978494719, -3.813595211961108],
              [-33.762184252475798, -3.813721336055172],
              [-33.762828929537399, -3.813856517129288],
              [-33.763496492386679, -3.814000756134067],
              [-33.764347150543976, -3.814198894743195],
              [-33.764835437005537, -3.814415614063138],
              [-33.765224515415341, -3.814514799353721],
              [-33.765731869728569, -3.814659039301022],
              [-33.76614385797096, -3.814640667606343],
              [-33.767032676860353, -3.814612542166431],
              [-33.768165616390569, -3.814946328715799],
              [-33.769271873158232, -3.815298229100631],
              [-33.770519247614281, -3.81571308699728],
              [-33.771514887395945, -3.816055930420126],
              [-33.772384638405114, -3.816181822655033],
              [-33.773193317920672, -3.816452418629242],
              [-33.773952429960019, -3.816524176287833],
              [-33.775096823470065, -3.816622640856343],
              [-33.775901732078317, -3.816730418223074],
              [-33.776290810488547, -3.816829627513739],
              [-33.776912598782609, -3.81690136312323],
              [-33.777320769057113, -3.816892073349464],
              [-33.777847169248346, -3.816891583542919],
              [-33.778213406906033, -3.81688206075616],
              [-33.778850447093085, -3.816863458144403],
              [-33.779422643856385, -3.816890164121557],
              [-33.780162686025228, -3.817088512689837],
              [-33.780826430952381, -3.817114985701875],
              [-33.781783911218909, -3.817078035362524],
              [-33.782356085998735, -3.817059432727489],
              [-33.783222041107869, -3.817004367510236],
              [-33.784042176632454, -3.816958338199088],
              [-33.784511370459498, -3.816641246081979],
              [-33.785011111926863, -3.816550419039116],
              [-33.78560237756475, -3.816441222186339],
              [-33.786994709879089, -3.816349416472834],
              [-33.788360361446877, -3.816230696980007],
              [-33.789363615312169, -3.816148205046832],
              [-33.790000654525741, -3.816057122055469],
              [-33.790866586642117, -3.815984175991207],
              [-33.791595174013167, -3.815910973879717],
              [-33.792300897583381, -3.81586519952526],
              [-33.793601682728308, -3.815791509623728],
              [-33.794692666815351, -3.815645850262711],
              [-33.795692125764269, -3.815427711489019],
              [-33.796508443360132, -3.815282263027363],
              [-33.7974430358597, -3.815181890222467],
              [-33.798289900138052, -3.815144938843261],
              [-33.799155809239238, -3.815117046393313],
              [-33.800593940120955, -3.815070550308429],
              [-33.801871836472671, -3.815005940320892],
              [-33.803054392730772, -3.814841912149856],
              [-33.803756274369057, -3.814705542569318],
              [-33.804324653226665, -3.814569406938761],
              [-33.805064696606827, -3.814179811538918],
              [-33.805514844576557, -3.813953081641078],
              [-33.805945855695136, -3.813799064114109],
              [-33.806533349410479, -3.81357233326559],
              [-33.807429783381927, -3.813128162388923],
              [-33.807807429087966, -3.812693514244229],
              [-33.808257555038509, -3.812521382879064],
              [-33.808772546432195, -3.812249576390162],
              [-33.809424836597934, -3.811905080572661],
              [-33.809962715808517, -3.811560794712258],
              [-33.810615027967572, -3.811234644737719],
              [-33.811221544557789, -3.810872245056923],
              [-33.811759423742664, -3.810600205578148],
              [-33.812179049055679, -3.810192752215731],
              [-33.812671106700819, -3.80980338972451],
              [-33.813140323512698, -3.809531606218878],
              [-33.813525584055846, -3.80935924183226],
              [-33.813929959408313, -3.809232395062925],
              [-33.814044394389065, -3.809159915724299],
              [-33.814605136391009, -3.808815652871751],
              [-33.81516590136706, -3.808543589401345],
              [-33.815822010449814, -3.808334997421962],
              [-33.816554416762003, -3.808334275691192],
              [-33.817275390288053, -3.808026007588099],
              [-33.817927703453933, -3.80768172076847],
              [-33.818850842150226, -3.807327912127603],
              [-33.819751093081315, -3.806901856096723],
              [-33.820590321492375, -3.806693007185174],
              [-33.821448616748683, -3.806430024743699],
              [-33.822352687619315, -3.806076239095664],
              [-33.823031706503073, -3.805840428357201],
              [-33.823573379611922, -3.805686153871803],
              [-33.824183714113232, -3.805477538876027],
              [-33.825087806967822, -3.805169037840336],
              [-33.825709573493548, -3.804598509945446],
              [-33.826434365949872, -3.804389893990719],
              [-33.82702181365066, -3.804208239755905],
              [-33.82744907582039, -3.804008890614985],
              [-33.828013636746284, -3.803782159757691],
              [-33.828803271646144, -3.803473868679045],
              [-33.82950515431186, -3.803265252715923],
              [-33.830035396419262, -3.803409258673966],
              [-33.830687708585913, -3.803064995853761],
              [-33.831320929888278, -3.802829208098497],
              [-33.831858809073999, -3.802557402617218],
              [-33.83264842098724, -3.802212882848367],
              [-33.833346507739414, -3.801859306124153],
              [-33.833995003957114, -3.801469734690162],
              [-33.834666385991724, -3.801007681884529],
              [-33.835410246300285, -3.800726585556477],
              [-33.836196063274144, -3.800309586406339],
              [-33.836711031675669, -3.8000196660717],
              [-33.837203113305492, -3.799675402193808],
              [-33.8372650486575, -3.799619918463975],
              [-33.716684860986774, -3.79962002011593],
              [-33.716767075480384, -3.799802200001558],
              [-33.716892966191871, -3.80019107371476],
              [-33.71709131118665, -3.800733708136571],
              [-33.717236269807117, -3.800887469871035],
              [-33.717491867416321, -3.801041045646605],
              [-33.717636801985712, -3.801339534141148],
              [-33.717850421962588, -3.801538371513985],
              [-33.718178499877261, -3.801782284541575],
              [-33.718186135711363, -3.802053602219806],
              [-33.718525621406322, -3.802623176461499],
              [-33.718735422408429, -3.802849207600029],
              [-33.718880380012926, -3.803048045948373],
            ],
          ],
          [
            [
              [-33.566289034853519, -3.799596467071245],
              [-33.566288950855935, -3.982928594691272],
              [-33.982952820256813, -3.982929526955998],
              [-33.982952911140174, -3.799597254857271],
              [-33.923986393371031, -3.799641077643626],
              [-33.923639269327815, -3.800229254491001],
              [-33.923242553791624, -3.80066390263288],
              [-33.922727562369623, -3.800999108582298],
              [-33.922185889213594, -3.801271148062086],
              [-33.921781536830849, -3.801470706210771],
              [-33.921285636296105, -3.801634013636449],
              [-33.920911784532009, -3.802122772323963],
              [-33.9201870150657, -3.8023407012004],
              [-33.920030600482669, -3.802368105909931],
              [-33.919645317951911, -3.802494950687048],
              [-33.918943412264021, -3.802749109261908],
              [-33.91858485648951, -3.802876188046644],
              [-33.918314007908549, -3.803021148710048],
              [-33.918115661656394, -3.803193280167236],
              [-33.918027909389195, -3.803383293511408],
              [-33.917856268861293, -3.803645786205728],
              [-33.917791402388438, -3.803826765635367],
              [-33.91785245067674, -3.804260923945977],
              [-33.917951634671965, -3.804541298584985],
              [-33.918005045094418, -3.804794502439713],
              [-33.918058432552641, -3.804948031146602],
              [-33.918111843958883, -3.805246543614062],
              [-33.918272051252181, -3.805933906796125],
              [-33.918394123032179, -3.806232395288658],
              [-33.918401760881821, -3.806458427359272],
              [-33.918455147304492, -3.80671163021412],
              [-33.918493307824086, -3.807082366058867],
              [-33.918622993470002, -3.80756181100731],
              [-33.918703108600297, -3.807950707712038],
              [-33.918741245061867, -3.80848426316513],
              [-33.918748881911448, -3.808710528233781],
              [-33.918745063755765, -3.809244105671599],
              [-33.918733631737148, -3.809587903728827],
              [-33.91864488144325, -3.809770328652914],
              [-33.918561967177112, -3.809940757650979],
              [-33.918176684413616, -3.810719203858321],
              [-33.917970701017801, -3.811488336209987],
              [-33.917909653500352, -3.811696486408857],
              [-33.917871516773779, -3.811904614616139],
              [-33.917676965432115, -3.812230297762245],
              [-33.917524369730344, -3.81249279046362],
              [-33.917398503768553, -3.812800592787327],
              [-33.917066606653734, -3.81319898926244],
              [-33.916578342952377, -3.813633637371244],
              [-33.916360905825918, -3.813941415662171],
              [-33.916166354500334, -3.814222047193367],
              [-33.91598707706774, -3.814412292502624],
              [-33.915792525732542, -3.814719861803545],
              [-33.915617043229766, -3.815009549264248],
              [-33.915262284372005, -3.815272275890611],
              [-33.915083006945856, -3.815444383354874],
              [-33.914774021664215, -3.815725037844601],
              [-33.914392556072784, -3.81592459700126],
              [-33.914125525431032, -3.816142037046368],
              [-33.913721174032268, -3.816386648809691],
              [-33.913343526347816, -3.816748793578046],
              [-33.912824739999408, -3.81697554745259],
              [-33.912420387616891, -3.81717485060316],
              [-33.912103742469824, -3.817274781634774],
              [-33.911481974976731, -3.817736810461153],
              [-33.911234013170713, -3.817927078745365],
              [-33.911054736760953, -3.818053902596648],
              [-33.910703793826407, -3.818434184219582],
              [-33.910505425568189, -3.818624197523848],
              [-33.910333737975719, -3.819067669671422],
              [-33.910211688923866, -3.819529233682244],
              [-33.910150665428809, -3.819673960423194],
              [-33.91013539145149, -3.819999643634027],
              [-33.910082004773322, -3.820460974671646],
              [-33.910028593118007, -3.820859138249199],
              [-33.909990432359145, -3.821157650684029],
              [-33.910024773932527, -3.821474252990508],
              [-33.910104890078614, -3.821817818083014],
              [-33.910177369349235, -3.82200785448493],
              [-33.910375716387506, -3.822432699925548],
              [-33.910528310846367, -3.822848745424929],
              [-33.910646563696254, -3.823047583768238],
              [-33.910917413021195, -3.823617179997997],
              [-33.911085257418584, -3.823816017359344],
              [-33.911378969502557, -3.824475952825386],
              [-33.911527747038356, -3.824774208330135],
              [-33.911668886679415, -3.824973046681813],
              [-33.912031283169043, -3.825542642944993],
              [-33.912294495638179, -3.825904321949214],
              [-33.91300019595387, -3.826590962336468],
              [-33.913122267781887, -3.826753570990982],
              [-33.913496118261072, -3.827060885499925],
              [-33.913618167076329, -3.827259722844879],
              [-33.913759306723861, -3.827440445351544],
              [-33.913950062928535, -3.827593997108327],
              [-33.914136977165015, -3.827756583786541],
              [-33.914369664980676, -3.827928249403797],
              [-33.914888428095779, -3.828352861962975],
              [-33.915216506015227, -3.828596774997354],
              [-33.915494991406838, -3.828786323478393],
              [-33.915891706710013, -3.829003041770143],
              [-33.916193055889629, -3.829201646182055],
              [-33.916402855926911, -3.82933729409887],
              [-33.916807232095287, -3.829734759848887],
              [-33.917039918924011, -3.829870173776],
              [-33.917367973811373, -3.830204425038561],
              [-33.917512932433823, -3.830358209776962],
              [-33.917745620246222, -3.830538933316967],
              [-33.918073675159512, -3.830800728198741],
              [-33.918355978486815, -3.831071789984787],
              [-33.918733624921785, -3.831406064265253],
              [-33.919179931707589, -3.831803739028888],
              [-33.919763585234151, -3.832219062691594],
              [-33.919908519856797, -3.832372847430103],
              [-33.920259460541843, -3.832688984856015],
              [-33.920446398781351, -3.832842536611746],
              [-33.920827864165069, -3.833222096506812],
              [-33.920923230230215, -3.833402819997235],
              [-33.920949934937845, -3.833538466847917],
              [-33.921045302025654, -3.833656023878042],
              [-33.921304695597691, -3.833827689505322],
              [-33.92158315700236, -3.83398121829446],
              [-33.921766276319048, -3.834016981055294],
              [-33.922002782080391, -3.834243012209817],
              [-33.922239288825487, -3.834514096979395],
              [-33.922380428499054, -3.834622574103751],
              [-33.922731370193858, -3.834911539762049],
              [-33.922964058009562, -3.835083205379691],
              [-33.923368410184537, -3.835462766283431],
              [-33.923509550822274, -3.835670660558398],
              [-33.923726987708889, -3.836032083549273],
              [-33.924009268062299, -3.836230943952644],
              [-33.924478485655612, -3.83658307711439],
              [-33.924871382993707, -3.836799796405398],
              [-33.925359669438947, -3.837079706191183],
              [-33.925596175226318, -3.837233233965373],
              [-33.926088256594632, -3.837630698748248],
              [-33.926649022326764, -3.838055312324185],
              [-33.927431043897421, -3.838877623582852],
              [-33.927888783478046, -3.839555439958363],
              [-33.928155812830646, -3.840143152034604],
              [-33.928357977797724, -3.840667672627076],
              [-33.928518187079014, -3.841381973583014],
              [-33.928975948652834, -3.842077926804029],
              [-33.929120907216451, -3.842394531152109],
              [-33.929441348055775, -3.843054489630769],
              [-33.929616806249982, -3.843624062828962],
              [-33.929796107431976, -3.844130469568197],
              [-33.929845699924122, -3.844284254272579],
              [-33.930116525222346, -3.844926074888403],
              [-33.930284370384705, -3.845776255687074],
              [-33.930299643039916, -3.846346085825076],
              [-33.930234777363864, -3.847088046463485],
              [-33.930181389658188, -3.84762162388623],
              [-33.930135591857244, -3.8482279156907],
              [-33.930063136309087, -3.848806802719507],
              [-33.929784650620583, -3.849440288206962],
              [-33.929349774348665, -3.850101177403348],
              [-33.929201020587904, -3.850426860567306],
              [-33.928846261686452, -3.850807375187747],
              [-33.928136718932763, -3.851432269591516],
              [-33.927690410879606, -3.851776300490296],
              [-33.926763451981813, -3.852754281798787],
              [-33.926198892026726, -3.853044459114336],
              [-33.925756378922536, -3.853433797627374],
              [-33.924882831704167, -3.853950447895852],
              [-33.923784211463918, -3.854675227304402],
              [-33.923585841204719, -3.854865495606795],
              [-33.923406540820601, -3.854919862076996],
              [-33.923089941652883, -3.855074111643948],
              [-33.922746613781456, -3.855083401439418],
              [-33.921853997688324, -3.855735723541849],
              [-33.920961359585512, -3.856415215412202],
              [-33.920091631293467, -3.857040342755108],
              [-33.919469840762666, -3.857601813732287],
              [-33.918535247026377, -3.858326382202061],
              [-33.917711292274454, -3.859069041557677],
              [-33.917020864389357, -3.859576426971679],
              [-33.916379958987186, -3.860264486860337],
              [-33.915445388257318, -3.860971151482936],
              [-33.914640479380957, -3.861577931006022],
              [-33.913992007149261, -3.861985873284581],
              [-33.913160392565629, -3.862456982956691],
              [-33.912496647394789, -3.863081853376681],
              [-33.911939723295262, -3.863580156916769],
              [-33.910944081047603, -3.864494738740713],
              [-33.91037188400874, -3.865209994422046],
              [-33.909704318882781, -3.865807717072374],
              [-33.909013867998212, -3.86631505448639],
              [-33.908342509946188, -3.866804302061882],
              [-33.907533782163817, -3.867248239974118],
              [-33.906725078391084, -3.867665239116468],
              [-33.905756165109501, -3.868000933894598],
              [-33.904966530179415, -3.86837264843068],
              [-33.904314239006112, -3.868717120249614],
              [-33.903795428673881, -3.86889856550997],
              [-33.902979135036318, -3.869134609195069],
              [-33.902322978973849, -3.869270977790173],
              [-33.901735532258051, -3.869479826791296],
              [-33.901327361926391, -3.869634100324118],
              [-33.900900122762337, -3.869806231697463],
              [-33.900102850016879, -3.869861528933412],
              [-33.899484855642477, -3.86989824539365],
              [-33.898939386586626, -3.869989329416071],
              [-33.898329052106256, -3.870125722027639],
              [-33.897466936893707, -3.87034362785086],
              [-33.8962614948205, -3.870543884699111],
              [-33.895319287214186, -3.871105844553811],
              [-33.894407579503671, -3.871188103517501],
              [-33.893614149674498, -3.871379047596053],
              [-33.892729124697695, -3.871524520029386],
              [-33.891954762737377, -3.871597699120947],
              [-33.891229970301737, -3.871734323688539],
              [-33.89077223145572, -3.871797979977303],
              [-33.89018094081186, -3.87190717682805],
              [-33.88961638092092, -3.87202543061125],
              [-33.888792426423173, -3.872071438916671],
              [-33.887495436232228, -3.872145338810684],
              [-33.886457815799361, -3.871874975740844],
              [-33.885862754229507, -3.871875697516948],
              [-33.88463063045684, -3.871922193668844],
              [-33.883783789175034, -3.871950086120774],
              [-33.882986517424392, -3.872023288203676],
              [-33.882261724996006, -3.872141774926162],
              [-33.881422519585605, -3.872332509989543],
              [-33.880644316677589, -3.872360402466541],
              [-33.879938616111232, -3.872379238047407],
              [-33.878477598424929, -3.872435024036257],
              [-33.877245474646635, -3.872499635033466],
              [-33.876379564532684, -3.872545898321356],
              [-33.875422060231926, -3.872655328036092],
              [-33.874262414736691, -3.872873723745782],
              [-33.873510940559051, -3.872973840615456],
              [-33.872778533292724, -3.87283891450029],
              [-33.871847735709117, -3.873057053295931],
              [-33.870871185543216, -3.87327521607462],
              [-33.870279919900199, -3.873384412925204],
              [-33.869692472196256, -3.873565834160132],
              [-33.869150775084641, -3.873720107643742],
              [-33.868567145260521, -3.874136851869231],
              [-33.867853807577646, -3.874671384040755],
              [-33.867041285891936, -3.87495271233957],
              [-33.866160102806802, -3.875306288995847],
              [-33.865217871246138, -3.875750459855626],
              [-33.864020090027807, -3.876167948849802],
              [-33.863321978285001, -3.876485272882807],
              [-33.862623914512291, -3.876883902221012],
              [-33.862173788542187, -3.877101343198126],
              [-33.861429927213742, -3.877427981134726],
              [-33.860869185230356, -3.877708843529443],
              [-33.860148210696408, -3.878026168553868],
              [-33.859541671098235, -3.878397626157619],
              [-33.858851220233248, -3.878859677956535],
              [-33.858156974402917, -3.879321730754036],
              [-33.857577139538023, -3.879756611825484],
              [-33.856669251513395, -3.880616827142302],
              [-33.856249649179347, -3.88106958911974],
              [-33.855780431304808, -3.881504214233882],
              [-33.855341760144888, -3.881956977204295],
              [-33.855051842712456, -3.88217441724001],
              [-33.854700878742086, -3.882654118012317],
              [-33.854307982091917, -3.883305950298411],
              [-33.853895993619361, -3.883948493656878],
              [-33.853457322410101, -3.884536903468068],
              [-33.85299955936518, -3.885152507039968],
              [-33.852427338345471, -3.88582245410546],
              [-33.852053533586769, -3.886293329944777],
              [-33.851836095423621, -3.886700551385375],
              [-33.851680255100732, -3.887037289424793],
              [-33.851622476205904, -3.88716213736283],
              [-33.85143553873138, -3.887659952040576],
              [-33.8510884156698, -3.88829343850019],
              [-33.850802317176353, -3.888583102920126],
              [-33.850554355120558, -3.88946096732812],
              [-33.850325484953117, -3.890166676214062],
              [-33.850142366377824, -3.890845214349061],
              [-33.849932564108627, -3.891352133940695],
              [-33.849760923547116, -3.891704964862889],
              [-33.849593077917945, -3.8921484140122],
              [-33.84926500072185, -3.892673424405739],
              [-33.849009424867162, -3.893189352903686],
              [-33.84863559711512, -3.893642115897747],
              [-33.84817019717277, -3.894167102241004],
              [-33.847845961954768, -3.894655882945628],
              [-33.847266127053707, -3.895190182167286],
              [-33.846415467631815, -3.895706577442353],
              [-33.845335915299579, -3.896223461628955],
              [-33.844733217643672, -3.896658109693545],
              [-33.844107609186977, -3.897120161515539],
              [-33.843550662139769, -3.897482562214274],
              [-33.843195902246777, -3.897844706989473],
              [-33.842730526320601, -3.898324639717614],
              [-33.842360492484509, -3.898913073553708],
              [-33.842226989432703, -3.899655034165109],
              [-33.84186841133026, -3.900686427220818],
              [-33.841784477037123, -3.900849501796577],
              [-33.841708178571949, -3.901292718981638],
              [-33.841433535831719, -3.901971490081081],
              [-33.841109275561024, -3.902604974549232],
              [-33.840834632837392, -3.903238461035613],
              [-33.840662968252666, -3.903654738415816],
              [-33.84054089621192, -3.904080072736831],
              [-33.840304390182844, -3.904596002241726],
              [-33.839911493565182, -3.905157240301848],
              [-33.839476617311021, -3.905772611883997],
              [-33.838816690022192, -3.906632827291296],
              [-33.838198718413196, -3.907320888184895],
              [-33.837340421885955, -3.908316775359975],
              [-33.836794929598675, -3.909049911895147],
              [-33.83621127777554, -3.909466679119194],
              [-33.835745901875839, -3.909874108466658],
              [-33.83530338975077, -3.910326870435146],
              [-33.834834194919026, -3.910643962552956],
              [-33.834387863851973, -3.911033558059736],
              [-33.833945374769876, -3.911368532034618],
              [-33.833609683755562, -3.91158597205294],
              [-33.83329687949589, -3.911902831230447],
              [-33.833148101761651, -3.912156267010311],
              [-33.832854390370684, -3.91235557019909],
              [-33.83274756928639, -3.912518667766212],
              [-33.832526336237002, -3.9127086810612],
              [-33.832022799573167, -3.913423936764494],
              [-33.831828249220486, -3.913776791678017],
              [-33.831454420451792, -3.914274839284999],
              [-33.83115307198814, -3.914863016148658],
              [-33.830821174906049, -3.91517107339416],
              [-33.830493120729287, -3.915641694252247],
              [-33.830077313327315, -3.916184818458573],
              [-33.829573776689934, -3.916827593781067],
              [-33.829131288538193, -3.917352836130277],
              [-33.828589614156272, -3.918248815275448],
              [-33.827777091227667, -3.919199391854053],
              [-33.827334580076077, -3.919724611203383],
              [-33.826914976751802, -3.920150225412133],
              [-33.826560216885248, -3.920439866806521],
              [-33.826136796625683, -3.920784152708616],
              [-33.825667579771107, -3.921164435286261],
              [-33.825156429296079, -3.92155400776894],
              [-33.824328633649593, -3.922115711664015],
              [-33.823855620889496, -3.922333151631382],
              [-33.823157557134714, -3.92268672835245],
              [-33.822409877879529, -3.922913458138785],
              [-33.821662197627703, -3.923131388000255],
              [-33.820712354222124, -3.923349526785185],
              [-33.819815920475001, -3.923169291993329],
              [-33.818625752330632, -3.923206730233105],
              [-33.818034462720547, -3.923234367778224],
              [-33.81732494420023, -3.923235089509506],
              [-33.816686293611042, -3.92322281250608],
              [-33.816432329358676, -3.923217930325897],
              [-33.815451961308682, -3.923227941877663],
              [-33.814460140194129, -3.923699283483609],
              [-33.813960421741363, -3.9237450579076],
              [-33.813620934805336, -3.923844964928391],
              [-33.813102148476922, -3.924026410186201],
              [-33.812335377337156, -3.924415982573952],
              [-33.811477081069761, -3.924706367775267],
              [-33.810664582354264, -3.925078058298817],
              [-33.809939766914404, -3.925241598633243],
              [-33.809218815396079, -3.925522718964491],
              [-33.808677142253785, -3.92576733067397],
              [-33.807864619551985, -3.926103025504936],
              [-33.807414494583533, -3.92632046548046],
              [-33.806827023866155, -3.926547172325998],
              [-33.805976364463035, -3.927018304985554],
              [-33.805091363460036, -3.927254348641068],
              [-33.804687012096736, -3.927408599173368],
              [-33.804439073324872, -3.927508273229916],
              [-33.803668506260045, -3.927789602540797],
              [-33.802669071332303, -3.927944341848576],
              [-33.801684885318494, -3.927954353398711],
              [-33.80051760401043, -3.927874002653057],
              [-33.799583009533933, -3.927910952991284],
              [-33.79871328249483, -3.927866599048328],
              [-33.79802665076673, -3.927867319788011],
              [-33.797233196934712, -3.928085226632182],
              [-33.795978186375386, -3.928149603617532],
              [-33.795364009978961, -3.928168440229146],
              [-33.794883361372811, -3.928159871124444],
              [-33.794448508338448, -3.928178195806869],
              [-33.793807650209089, -3.928133630950469],
              [-33.793189656864286, -3.928107157947933],
              [-33.792388590205228, -3.9280444561872],
              [-33.791381516411285, -3.928027528959052],
              [-33.790630043256172, -3.928082384212169],
              [-33.790153211582101, -3.928164387334875],
              [-33.789634425238361, -3.928390885207213],
              [-33.78893633751256, -3.928671980546706],
              [-33.788070405379926, -3.928790468213583],
              [-33.787360886861585, -3.928791189944782],
              [-33.78665898122064, -3.928936615442197],
              [-33.78582357274297, -3.92923584957562],
              [-33.784984368313246, -3.929499064015549],
              [-33.784305349450129, -3.929680485213756],
              [-33.7834737599078, -3.93006125665172],
              [-33.782768059288593, -3.930242678839975],
              [-33.78229502256039, -3.93037881350142],
              [-33.781795305085801, -3.930488011383156],
              [-33.78124981301297, -3.930642261862922],
              [-33.780498337841962, -3.930742402728804],
              [-33.779659111475944, -3.930833717637666],
              [-33.778861839713421, -3.930961029254371],
              [-33.778385009056542, -3.930997746764032],
              [-33.777767037695924, -3.93101634937618],
              [-33.777129998482856, -3.931107433361899],
              [-33.776512027118962, -3.931135070896852],
              [-33.775989421890358, -3.931144615621752],
              [-33.775257016572048, -3.931172509112188],
              [-33.774707706607487, -3.931127665292036],
              [-33.774223238106835, -3.930929316807975],
              [-33.773689179056106, -3.93073979323009],
              [-33.772834701979455, -3.930423865613975],
              [-33.771980224886278, -3.930153269610521],
              [-33.771148633573993, -3.929909844383384],
              [-33.770385704627749, -3.929684534026796],
              [-33.769615138625333, -3.929802811730712],
              [-33.76881404896158, -3.929758200815492],
              [-33.768283829859399, -3.929613938852414],
              [-33.767868046688335, -3.929532914391151],
              [-33.767356874451913, -3.929280176362311],
              [-33.766803745569099, -3.929108766622828],
              [-33.765949268476298, -3.928838147619909],
              [-33.765258818861497, -3.928630741137424],
              [-33.764495888922333, -3.928387293936225],
              [-33.763687186421336, -3.928089527182476],
              [-33.762897573772598, -3.927719255055623],
              [-33.762222352058544, -3.927403351506431],
              [-33.761661609354931, -3.926933428316191],
              [-33.761169528964167, -3.926626348759322],
              [-33.760562989665793, -3.926192655242656],
              [-33.759536848267992, -3.925578494113713],
              [-33.758606073955733, -3.925081866015431],
              [-33.75804912714127, -3.924819838049779],
              [-33.757721073228531, -3.924575948013576],
              [-33.756931438593362, -3.924169682195157],
              [-33.755981595415705, -3.92378152916275],
              [-33.755287303859767, -3.923510700221776],
              [-33.754589263364558, -3.923167601897319],
              [-33.753753855151224, -3.922752278139773],
              [-33.753082473405819, -3.922427316670182],
              [-33.752475935067949, -3.92210212122673],
              [-33.751690118355206, -3.92181338840525],
              [-33.751030191362879, -3.921877277615399],
              [-33.750038369516275, -3.921633852330636],
              [-33.749439491075364, -3.921399252115565],
              [-33.748672743208409, -3.921056175766044],
              [-33.748070021819252, -3.920776266936928],
              [-33.747116337716889, -3.920279638831558],
              [-33.745952898571474, -3.919674535576458],
              [-33.744983963522614, -3.919413229453457],
              [-33.743671724661169, -3.919016252364148],
              [-33.743023252657288, -3.918826960743688],
              [-33.742260323745896, -3.918510800165921],
              [-33.741520280618637, -3.918213010439616],
              [-33.740826013046622, -3.917987490112208],
              [-33.740200404860332, -3.917725695120351],
              [-33.739761711929354, -3.917563342347033],
              [-33.738975918220369, -3.91726555160398],
              [-33.738396084589382, -3.917076004011229],
              [-33.738094713390161, -3.916949646980819],
              [-33.737591177022225, -3.916868575488555],
              [-33.737080027763511, -3.91667905092103],
              [-33.736347621474202, -3.916634464032463],
              [-33.735340549692133, -3.916599166963254],
              [-33.734402138289255, -3.916545755074322],
              [-33.733417975307873, -3.916483310245823],
              [-33.733163011398339, -3.916509394008157],
              [-33.732346038066055, -3.916592972912992],
              [-33.731544972411342, -3.916530271154098],
              [-33.730904114288911, -3.916476627376795],
              [-33.730240369379146, -3.916413669670689],
              [-33.729759719792355, -3.916360024952447],
              [-33.729073089078582, -3.916333341927805],
              [-33.727600640620466, -3.916108498308504],
              [-33.72666604715036, -3.916145447648196],
              [-33.725933594855555, -3.916118997604664],
              [-33.725361422102409, -3.916074177777306],
              [-33.724739655845376, -3.91591203393507],
              [-33.723911861461879, -3.915777108784382],
              [-33.723152750467207, -3.915596851046657],
              [-33.722458481916135, -3.915316942185233],
              [-33.721485751940286, -3.914955984914661],
              [-33.720631274867017, -3.914640103302029],
              [-33.719769160896618, -3.914233813460095],
              [-33.719078687294456, -3.913999213212707],
              [-33.718632404509648, -3.913628477219762],
              [-33.718189892660497, -3.913339510528786],
              [-33.717770290575594, -3.913122792228311],
              [-33.717091272977669, -3.912589702538676],
              [-33.71644280100486, -3.912318827617317],
              [-33.715698940914081, -3.912020826893019],
              [-33.715164880898691, -3.911740918091071],
              [-33.714249380284663, -3.911696564133835],
              [-33.713555135747001, -3.911380426583028],
              [-33.712948573437409, -3.910982984760019],
              [-33.712273373728998, -3.910657766294079],
              [-33.711716449929327, -3.91035974263899],
              [-33.710972589881621, -3.909944186920696],
              [-33.710106635006149, -3.909375079471364],
              [-33.709454392104369, -3.909004808399517],
              [-33.708798237281279, -3.908516771333838],
              [-33.708103968763766, -3.908146501246706],
              [-33.707470747711149, -3.907712830724581],
              [-33.706681114098515, -3.907252199375638],
              [-33.705815182216533, -3.906700971773998],
              [-33.70493018151538, -3.906131864318464],
              [-33.704323666169344, -3.905833840645807],
              [-33.703648466487564, -3.905436398798646],
              [-33.703095338610666, -3.905255931139834],
              [-33.702626145022322, -3.904921656827858],
              [-33.702095902949367, -3.904704937487649],
              [-33.701607640499141, -3.904470337315923],
              [-33.700978214379219, -3.904127005022795],
              [-33.700505202885054, -3.903629911102883],
              [-33.699879594713764, -3.903331888423579],
              [-33.699326466840262, -3.903142340842675],
              [-33.69867799587152, -3.902862431000089],
              [-33.698052388650936, -3.902700079159958],
              [-33.697339051255568, -3.902474535828389],
              [-33.69596196992277, -3.902267595094342],
              [-33.694787052771176, -3.90197909413244],
              [-33.693890597014217, -3.901853202881115],
              [-33.693017052051118, -3.901718277715568],
              [-33.692097732519869, -3.901547076843672],
              [-33.691525535771767, -3.901493433093023],
              [-33.691090660751634, -3.901484608009163],
              [-33.690701559319507, -3.901457902095006],
              [-33.690423097888832, -3.901394735533552],
              [-33.690262889360369, -3.901376854627874],
              [-33.689965335094421, -3.901340857826894],
              [-33.689484686515925, -3.901268843267079],
              [-33.689183338294534, -3.90120590969521],
              [-33.688840010447201, -3.901169913877449],
              [-33.688565366978359, -3.901115781240102],
              [-33.688263995790066, -3.900962251443657],
              [-33.687680367271639, -3.900564786631481],
              [-33.687073828942971, -3.900221478347511],
              [-33.68629564931868, -3.899507409173942],
              [-33.68554797034971, -3.898974297462937],
              [-33.685010116419264, -3.898558740822682],
              [-33.684514217115037, -3.898115757432649],
              [-33.684044999560186, -3.897691144895205],
              [-33.683526213465342, -3.89724816049688],
              [-33.682965471754031, -3.896805409081354],
              [-33.682492460224111, -3.896407735312104],
              [-33.682019424763368, -3.89582004716936],
              [-33.681401454683744, -3.895078783288265],
              [-33.680741527935282, -3.894482272152977],
              [-33.679986236101627, -3.8937682029891],
              [-33.679394947728774, -3.893162610956869],
              [-33.67894482201573, -3.892692688814941],
              [-33.678635836985734, -3.89229478110826],
              [-33.678277260428906, -3.89184273984693],
              [-33.677804247931704, -3.891354680852067],
              [-33.67719391370683, -3.890821335194784],
              [-33.67672090222927, -3.890278933665336],
              [-33.676389030411713, -3.889872201026097],
              [-33.676030475835049, -3.889474526299579],
              [-33.675675670233808, -3.889049657807292],
              [-33.675175952007081, -3.888489141423453],
              [-33.67465334901577, -3.887856376649906],
              [-33.674271884681289, -3.887368083691095],
              [-33.673867555552583, -3.886889105644193],
              [-33.673714961138032, -3.886364351081216],
              [-33.673264835415026, -3.885921600707665],
              [-33.672955850388199, -3.885514613079375],
              [-33.672383677872993, -3.884818681829075],
              [-33.671864844811111, -3.884285337206252],
              [-33.671441424837361, -3.883860467689588],
              [-33.670873047238473, -3.883299951281534],
              [-33.670445808315911, -3.882830028149391],
              [-33.670232190374293, -3.88254057454987],
              [-33.669942273199091, -3.882061361547444],
              [-33.669606582455572, -3.881546153836493],
              [-33.669339553071005, -3.881066918842667],
              [-33.668809311214659, -3.880253198617091],
              [-33.668523213984969, -3.879828073153103],
              [-33.668332481794693, -3.879647372630953],
              [-33.668118861869317, -3.879312889417267],
              [-33.667809877793324, -3.87904180462602],
              [-33.667622963558379, -3.878888019874779],
              [-33.667291091737376, -3.878490344159297],
              [-33.667146133138253, -3.878282427886881],
              [-33.666860034892174, -3.877902633034946],
              [-33.666764668806884, -3.877785101006743],
              [-33.666669302718269, -3.877676601901133],
              [-33.666528163064704, -3.877522840166429],
              [-33.6664785705509, -3.877432478922152],
              [-33.666383204481868, -3.877269869279913],
              [-33.666333612994187, -3.87710702765636],
              [-33.666215359105159, -3.877025723309584],
              [-33.665864418419346, -3.876736524658324],
              [-33.66548677199426, -3.876402017385475],
              [-33.665368519108441, -3.876311655116207],
              [-33.665101489720428, -3.875841500045251],
              [-33.664914575505072, -3.875633605757563],
              [-33.664655182972254, -3.875371344909118],
              [-33.664433927152473, -3.874937418544675],
              [-33.664216490288076, -3.874530430950898],
              [-33.664075350618127, -3.874421954828521],
              [-33.663735840919095, -3.873879553350152],
              [-33.663571813500859, -3.873653543226079],
              [-33.663468810566968, -3.873309723133116],
              [-33.663228509905089, -3.872920895375678],
              [-33.662946207575096, -3.872676982361967],
              [-33.662503742680833, -3.8725143735937],
              [-33.661984932605463, -3.8720172806624],
              [-33.661702652291652, -3.871728058036786],
              [-33.661225822861596, -3.871149426819162],
              [-33.660726081660577, -3.870516663056976],
              [-33.660371299042573, -3.870136870180869],
              [-33.659829650189032, -3.869603757550129],
              [-33.659379524485381, -3.869106408646335],
              [-33.65895228655291, -3.868663424285403],
              [-33.65837245397374, -3.868338229860151],
              [-33.657952851899708, -3.868094338796728],
              [-33.657464565457182, -3.867841368786264],
              [-33.65710598889973, -3.86738956052594],
              [-33.656774117088396, -3.866964459046637],
              [-33.656350697107925, -3.866557727376807],
              [-33.655946345959507, -3.866132857869363],
              [-33.655568699556987, -3.865735182138671],
              [-33.654981229106319, -3.865247123106175],
              [-33.654439533236967, -3.864759087090119],
              [-33.654019955179031, -3.864469887415151],
              [-33.653846234454583, -3.864364254906718],
              [-33.653737675822867, -3.86429824478309],
              [-33.653039589358066, -3.863882666089832],
              [-33.652593258583913, -3.863484990334553],
              [-33.652097360305405, -3.862969759568426],
              [-33.651456503443875, -3.862201348918605],
              [-33.650979673013666, -3.861622694702913],
              [-33.650407475530514, -3.860836169233492],
              [-33.649980237607672, -3.860365991106629],
              [-33.649850552959691, -3.859904661011766],
              [-33.649633116078562, -3.859542982031484],
              [-33.64929742531826, -3.859072802937963],
              [-33.648988440290836, -3.858666094310411],
              [-33.648729047767539, -3.858376871694309],
              [-33.648240761562136, -3.857463478342808],
              [-33.647905071801688, -3.856993322249398],
              [-33.64761897256497, -3.856586334632662],
              [-33.647184098818833, -3.855817924058733],
              [-33.646844613109316, -3.855302460352256],
              [-33.646623357314951, -3.854796053611062],
              [-33.646287666564199, -3.854298703750811],
              [-33.646184663636149, -3.853937024812408],
              [-33.64600920541308, -3.853457579856856],
              [-33.645902384545948, -3.853023397538325],
              [-33.64577651783673, -3.852634547824539],
              [-33.645631560250301, -3.852390423863849],
              [-33.645528557315686, -3.852046858770324],
              [-33.645475146904751, -3.851766462153548],
              [-33.645375985883184, -3.851567390823418],
              [-33.645204321618792, -3.851106292712201],
              [-33.645124205481352, -3.85074438178429],
              [-33.645036477491196, -3.850165494712816],
              [-33.644834289561032, -3.849550611908385],
              [-33.644723673776973, -3.848980781751177],
              [-33.644613034986598, -3.848429090438453],
              [-33.644502418221904, -3.847804917746943],
              [-33.644479531703183, -3.847045075249674],
              [-33.644342210205437, -3.846357480092121],
              [-33.644117136468203, -3.845787882891751],
              [-33.643983609919474, -3.845145573347646],
              [-33.643892109008782, -3.844440072360281],
              [-33.643876812297435, -3.844024074919568],
              [-33.643880630469582, -3.843444954883656],
              [-33.643865380806254, -3.842893239606053],
              [-33.64386919895243, -3.842386599949082],
              [-33.643926405597149, -3.841916213000736],
              [-33.64395692947312, -3.841481820734296],
              [-33.64398361144557, -3.840875738937816],
              [-33.644014158337335, -3.840396294057548],
              [-33.643991248802124, -3.839681760172717],
              [-33.643983611923673, -3.839537033410232],
              [-33.64397981700693, -3.839401129573596],
              [-33.64393788462943, -3.838731882293856],
              [-33.643872995403932, -3.838225242612344],
              [-33.6437814485191, -3.837447285246597],
              [-33.643815791321146, -3.837121602050094],
              [-33.643914975571917, -3.836678385884244],
              [-33.64396074940823, -3.835963619026409],
              [-33.643975999359036, -3.835710415201958],
              [-33.644071365702899, -3.835104124432406],
              [-33.64415148211188, -3.83470619387161],
              [-33.644216324551032, -3.834615599671405],
              [-33.644269736220473, -3.834172382489171],
              [-33.644277348299255, -3.833756105059601],
              [-33.644353646798066, -3.833204156817496],
              [-33.64438417092191, -3.832073345520288],
              [-33.64452531083063, -3.831512339379278],
              [-33.644597790373872, -3.830933453366835],
              [-33.644620677444877, -3.830146439120419],
              [-33.64470458876751, -3.829893002322736],
              [-33.644887707343763, -3.829187270437396],
              [-33.645040280254044, -3.828327775858929],
              [-33.645223398833366, -3.827613009051064],
              [-33.645253899737874, -3.827097312482124],
              [-33.645292059446803, -3.826934470891541],
              [-33.645341652205587, -3.826337470026434],
              [-33.645375995033049, -3.825939306451563],
              [-33.645410313851123, -3.825568337643597],
              [-33.645395063154773, -3.825106983592578],
              [-33.645353084751171, -3.824509982694647],
              [-33.645311129331191, -3.823958290408447],
              [-33.645295879641203, -3.823478822512799],
              [-33.645257720189775, -3.822917817307803],
              [-33.645196694892675, -3.822510852774344],
              [-33.64513946655444, -3.822121979087116],
              [-33.645059373409353, -3.82177818100541],
              [-33.644979258280415, -3.821389331309856],
              [-33.644899142119371, -3.82109081883993],
              [-33.644841936764919, -3.820747020766496],
              [-33.644784707420129, -3.820376284923916],
              [-33.64471986525119, -3.819706803639539],
              [-33.644704592528981, -3.819317952967352],
              [-33.644651205133066, -3.818992269740019],
              [-33.644597794923605, -3.818142065009038],
              [-33.644540566585263, -3.817752958324128],
              [-33.64450242909794, -3.81729162826516],
              [-33.644490974334289, -3.816920892439154],
              [-33.64443376900217, -3.816513670909582],
              [-33.644418495305672, -3.816052317859085],
              [-33.644407086541555, -3.815681581033193],
              [-33.644384177014409, -3.814939620385558],
              [-33.644414700882763, -3.814523597962962],
              [-33.644426132900115, -3.814179776914526],
              [-33.644410882203275, -3.813718446864015],
              [-33.644368927824559, -3.813048965588378],
              [-33.644311698444241, -3.812777647893864],
              [-33.644181990800718, -3.812298434955772],
              [-33.644124785455553, -3.811927444115859],
              [-33.644094261824542, -3.811674240275688],
              [-33.644059943269951, -3.81130327144378],
              [-33.643979827140683, -3.810914397749015],
              [-33.643941666627576, -3.810525547069114],
              [-33.643934052812661, -3.810199863858597],
              [-33.643930235895603, -3.810064217020155],
              [-33.643922599013678, -3.809928313182553],
              [-33.644044646079863, -3.809412616647437],
              [-33.644025601449513, -3.808860878370953],
              [-33.643995078081197, -3.807865759891671],
              [-33.643976009473441, -3.807250622158839],
              [-33.643930236138715, -3.806554225113193],
              [-33.643907349575414, -3.805911914612073],
              [-33.64388064411429, -3.805079592738765],
              [-33.643838688718944, -3.804455420075383],
              [-33.643827234945334, -3.804111599019182],
              [-33.643804347397875, -3.803424259904271],
              [-33.643762391995999, -3.802818178086022],
              [-33.643785280039339, -3.802103412222756],
              [-33.64378146136859, -3.801271088358013],
              [-33.643773847543699, -3.800972599914644],
              [-33.643712801281175, -3.800466193235033],
              [-33.643659389817699, -3.800330522379328],
              [-33.643556410903585, -3.799923558832133],
              [-33.643449590059731, -3.79941691913825],
              [-33.566289034853519, -3.799596467071245],
            ],
          ],
          [
            [
              [-32.3166387680809, -3.750019003783274],
              [-32.31663876156307, -3.950018673820122],
              [-32.683304836062263, -3.950018686294592],
              [-32.683304842718314, -3.750019015626603],
              [-32.3166387680809, -3.750019003783274],
            ],
            [
              [-32.403373617325578, -3.81904095780698],
              [-32.403061291667846, -3.823572334339253],
              [-32.402926989033929, -3.826753095175263],
              [-32.398908104704617, -3.830276294230933],
              [-32.398903350880509, -3.830280727635461],
              [-32.398854482394107, -3.830103004162185],
              [-32.398740236472818, -3.830043030643564],
              [-32.398521563479491, -3.83009777909237],
              [-32.398292909558045, -3.830212415017711],
              [-32.39817346957345, -3.830477360675193],
              [-32.398010772980605, -3.830768516090045],
              [-32.397998164002814, -3.83104423269095],
              [-32.39787475507373, -3.83125480781941],
              [-32.397731577350264, -3.831399415513777],
              [-32.397619334307649, -3.83163906239362],
              [-32.397309897104115, -3.831842331520992],
              [-32.396959273316718, -3.8319445455119],
              [-32.397007597813783, -3.832105651129409],
              [-32.397025897580214, -3.832292467513141],
              [-32.397011329660074, -3.832453038113487],
              [-32.396955267129037, -3.832599678820518],
              [-32.39689137967239, -3.832740593574433],
              [-32.396852041998493, -3.832852217591285],
              [-32.39687077476038, -3.833040563962013],
              [-32.396915458319285, -3.833119788289372],
              [-32.397025303295095, -3.83313949215631],
              [-32.397081610817111, -3.833012479278902],
              [-32.397068834995018, -3.832838827782669],
              [-32.397116199596319, -3.83272228581125],
              [-32.397255575337141, -3.83262980566281],
              [-32.397353118455804, -3.832565081258707],
              [-32.397609109067844, -3.832614242920285],
              [-32.397758371595231, -3.832876814691504],
              [-32.397756223568379, -3.833012954508336],
              [-32.397694804081041, -3.833176853063578],
              [-32.397543461463904, -3.833232034531919],
              [-32.397444017353536, -3.833323800700474],
              [-32.397388432826489, -3.833445740622138],
              [-32.397380251864647, -3.833555345667358],
              [-32.397463678065847, -3.833631269036858],
              [-32.397605097714077, -3.833753442024796],
              [-32.397666217093921, -3.833910917678216],
              [-32.39764304725329, -3.834071657274126],
              [-32.397477153718832, -3.834278745418099],
              [-32.397403440325022, -3.834501684456356],
              [-32.397400320289805, -3.83468875383054],
              [-32.397395191254809, -3.834928921742998],
              [-32.397506357156594, -3.835128646046901],
              [-32.397585718351579, -3.835332341305265],
              [-32.397793406357103, -3.835538263588767],
              [-32.398062592860335, -3.835548295594931],
              [-32.398305038594067, -3.835608361157244],
              [-32.398634900498948, -3.835724388263733],
              [-32.398900073002167, -3.835842672328438],
              [-32.399096209150152, -3.835945770501016],
              [-32.399233871836962, -3.836056549586622],
              [-32.399414971109572, -3.836202284383682],
              [-32.399572561572448, -3.836427803479733],
              [-32.399651895753934, -3.836671428391399],
              [-32.399710866120429, -3.836925610204452],
              [-32.399778760410122, -3.837162647169451],
              [-32.399822344936453, -3.837366317415697],
              [-32.399826219851143, -3.837510064168712],
              [-32.399814414912015, -3.837651157939331],
              [-32.399774263195233, -3.837909320683356],
              [-32.399726398559046, -3.838140876655821],
              [-32.399626940441806, -3.838252608651099],
              [-32.399483064748701, -3.838327540950379],
              [-32.402506094320643, -3.842480783932224],
              [-32.403310545795797, -3.842695759344648],
              [-32.405174601141205, -3.84382832515726],
              [-32.404806420099007, -3.845142029622266],
              [-32.40280198114808, -3.846640692912838],
              [-32.402924202911422, -3.846944602316412],
              [-32.40298072829296, -3.847219990944813],
              [-32.402941814518456, -3.847611682530298],
              [-32.402800575690065, -3.848007934040487],
              [-32.402513210199636, -3.848449174109358],
              [-32.402260567430886, -3.848765007279184],
              [-32.401882888813809, -3.849099190246231],
              [-32.401683253643064, -3.849156786676708],
              [-32.401390592003906, -3.850171756762091],
              [-32.401153719926256, -3.850959658838476],
              [-32.401238376935844, -3.851557891673594],
              [-32.401351135557391, -3.852526440303289],
              [-32.401502082011795, -3.852953813645152],
              [-32.401756873435609, -3.85358064929136],
              [-32.401907932944624, -3.853846610034763],
              [-32.402172395386977, -3.854160124404765],
              [-32.402408579130487, -3.854359682754396],
              [-32.402720366163884, -3.854587776882616],
              [-32.403020155323716, -3.854771786389349],
              [-32.403270376968599, -3.854880483532747],
              [-32.403408973656781, -3.85496197087352],
              [-32.403476668015117, -3.855004446528291],
              [-32.403562025235445, -3.854972929831697],
              [-32.403605305866328, -3.854880717647381],
              [-32.403599366963277, -3.85475930169943],
              [-32.403514673937778, -3.854208543451691],
              [-32.403467581459225, -3.853961645578932],
              [-32.403656923846327, -3.853553502188432],
              [-32.40349640442151, -3.853297029359305],
              [-32.40332641007474, -3.853069035279687],
              [-32.403071360525523, -3.852812495418303],
              [-32.402920472100014, -3.852299670818339],
              [-32.402812892228354, -3.851917089102626],
              [-32.402766497757838, -3.851628058596019],
              [-32.402843976044934, -3.851614208743254],
              [-32.402963963961696, -3.851532685492805],
              [-32.403356084451609, -3.851179588695031],
              [-32.403592671391223, -3.850799963073371],
              [-32.403999395748414, -3.850439445345091],
              [-32.404235775588504, -3.850354158167829],
              [-32.40452890091607, -3.850230931339749],
              [-32.404736969041231, -3.850079160729838],
              [-32.405171880052308, -3.849965526867734],
              [-32.405464977366975, -3.849880279709758],
              [-32.405574919403648, -3.849720967131302],
              [-32.405720455125156, -3.849519655929808],
              [-32.405824502194108, -3.849424779789726],
              [-32.405900375615765, -3.849064030948279],
              [-32.405976248037348, -3.848703281106906],
              [-32.406033227640535, -3.848323528423961],
              [-32.406373891665218, -3.847801551074592],
              [-32.4065158154161, -3.847611754771835],
              [-32.407262145503964, -3.84761127203549],
              [-32.407423498113864, -3.847308553719933],
              [-32.40775434704959, -3.847308783832958],
              [-32.407896112723456, -3.847346861551625],
              [-32.40830268300693, -3.847204722927022],
              [-32.408548764881495, -3.846758637885749],
              [-32.408785307801942, -3.846435977769489],
              [-32.409002807829992, -3.84631269691542],
              [-32.409390373240441, -3.846312967047857],
              [-32.409569853515926, -3.84649349254276],
              [-32.409616967004403, -3.846711905662773],
              [-32.409654660596338, -3.846882839191703],
              [-32.409604176978853, -3.847129694030834],
              [-32.409224164192999, -3.84801578120535],
              [-32.409245183850786, -3.848443984494837],
              [-32.408897470029089, -3.848566068313959],
              [-32.408930605589305, -3.848951663977623],
              [-32.409411254139478, -3.848946604188722],
              [-32.40957740349382, -3.849256489555977],
              [-32.409719151158157, -3.849323052027367],
              [-32.409860767758119, -3.849579509850027],
              [-32.410068495716338, -3.849921467953359],
              [-32.410229148205467, -3.849988043431281],
              [-32.410739759554943, -3.849760521584171],
              [-32.411023469989473, -3.849580317247445],
              [-32.411354417973484, -3.849438123597068],
              [-32.411666434119688, -3.84933389661049],
              [-32.41190288499525, -3.849144164340012],
              [-32.412101344131081, -3.849220259748355],
              [-32.412422585077287, -3.849448358879743],
              [-32.412809969397877, -3.849714480704097],
              [-32.413027255320003, -3.849904528129648],
              [-32.413216020424393, -3.850331925484766],
              [-32.413061901682894, -3.850821507180712],
              [-32.41279893570421, -3.852021714669399],
              [-32.412792579743375, -3.852079000169882],
              [-32.412797759656506, -3.852191530194763],
              [-32.412977199984219, -3.852221848993904],
              [-32.41330411494733, -3.852248738874333],
              [-32.413692799347523, -3.852249007007265],
              [-32.414303587690789, -3.852249429216211],
              [-32.414872780443332, -3.85218010501621],
              [-32.415483635819633, -3.852082924072522],
              [-32.415802988900545, -3.851971599150101],
              [-32.415844518458186, -3.852138944711803],
              [-32.415747129249489, -3.852459569894435],
              [-32.415871968045238, -3.85259908772674],
              [-32.416052352336131, -3.852710756820129],
              [-32.416371696412263, -3.852613374776644],
              [-32.416649583968621, -3.852237101139981],
              [-32.416760399822039, -3.852585755151807],
              [-32.416829598075282, -3.852892551512515],
              [-32.416690620280619, -3.853129490407224],
              [-32.416440628533266, -3.853310578748113],
              [-32.41588524061423, -3.853491457984511],
              [-32.415371565342696, -3.853574763082472],
              [-32.415024229410214, -3.854006761211219],
              [-32.413413897290475, -3.854103251812761],
              [-32.412913901791249, -3.854479370373406],
              [-32.411900420115153, -3.854659929452967],
              [-32.411830254384675, -3.855761388866426],
              [-32.412079835928665, -3.856179855320625],
              [-32.41266293256195, -3.856082657367559],
              [-32.414009460095876, -3.856069645949649],
              [-32.41507827916336, -3.856167985468372],
              [-32.415716692178592, -3.856377572871465],
              [-32.416119059351033, -3.856670656467466],
              [-32.416396697695042, -3.856915853435792],
              [-32.416396245699048, -3.856916291431859],
              [-32.416989567002581, -3.857500083570988],
              [-32.41754033073493, -3.857983705564858],
              [-32.418012540282675, -3.858212472743712],
              [-32.418362345994488, -3.858353292643319],
              [-32.418983341203955, -3.858467938864699],
              [-32.419604414452174, -3.858468365077637],
              [-32.420356683476399, -3.858495238106654],
              [-32.421082591684026, -3.858697818601389],
              [-32.421371104933094, -3.858926458717416],
              [-32.421659452098723, -3.859401111698024],
              [-32.421755532136075, -3.859612047900608],
              [-32.421781596803513, -3.859875652621659],
              [-32.421807554417263, -3.86029741096991],
              [-32.42175487280484, -3.860587320435192],
              [-32.421745981814297, -3.860798184601787],
              [-32.421789619358456, -3.860947581320268],
              [-32.421868258584652, -3.861079428203361],
              [-32.422086929550431, -3.861105937049532],
              [-32.422349432159017, -3.860991895131053],
              [-32.422483238922759, -3.860983586249827],
              [-32.42240673051387, -3.861322834278606],
              [-32.422362018773569, -3.861768871391569],
              [-32.422317427093915, -3.862036482053203],
              [-32.422239527722695, -3.862304069703519],
              [-32.422383680295113, -3.86257180943002],
              [-32.422594502277143, -3.862761531856905],
              [-32.422871939642242, -3.862951300306735],
              [-32.423138326136694, -3.86306299843028],
              [-32.423626810597568, -3.863107938210737],
              [-32.423782240154331, -3.863119196167311],
              [-32.424048499584416, -3.863420472645439],
              [-32.424126089800232, -3.863610105026718],
              [-32.424392454283335, -3.863755257859947],
              [-32.424647746884418, -3.863855797076488],
              [-32.4249474130553, -3.864012124824344],
              [-32.424991808636456, -3.864034458645996],
              [-32.425280371910162, -3.864190778390046],
              [-32.425635491536589, -3.864436356382797],
              [-32.425912875875234, -3.864704186155213],
              [-32.426145821609829, -3.865016591525288],
              [-32.426467536495458, -3.865407120248343],
              [-32.426489667251936, -3.865518652288128],
              [-32.426394390024406, -3.86583551350486],
              [-32.426611360900871, -3.866165533716511],
              [-32.426644075501784, -3.86644316931842],
              [-32.426676900082747, -3.866775132448868],
              [-32.426672627064526, -3.866942014999166],
              [-32.426757862252472, -3.86700754546023],
              [-32.426949998399017, -3.867221927666825],
              [-32.427148203520666, -3.867345786661237],
              [-32.427504580169639, -3.867492794509962],
              [-32.427778649632188, -3.867491406617873],
              [-32.428055424068255, -3.867494264689835],
              [-32.428336535463458, -3.867499034746721],
              [-32.428791474241763, -3.867524938680998],
              [-32.429070212653265, -3.867546103594745],
              [-32.429458451068584, -3.867516335988853],
              [-32.429728055583773, -3.867482235379045],
              [-32.430352286911109, -3.867171715292042],
              [-32.43067438399035, -3.866993504951223],
              [-32.431351765779581, -3.866815532732451],
              [-32.432494742343621, -3.866389402830054],
              [-32.433123700556308, -3.866283725967044],
              [-32.433251137616615, -3.865590118030804],
              [-32.433484922780252, -3.864642378337156],
              [-32.433529584497983, -3.864263249642891],
              [-32.433763139543906, -3.863661212949161],
              [-32.43390786540845, -3.863070268128138],
              [-32.43406185635078, -3.862006613412861],
              [-32.434164514597306, -3.861437249389893],
              [-32.434173722625353, -3.861109768235189],
              [-32.434509694583866, -3.860909139093679],
              [-32.434864210348181, -3.860773650393287],
              [-32.435319476163471, -3.860684740323817],
              [-32.435918994600598, -3.860718595239274],
              [-32.436707293309098, -3.860696815703486],
              [-32.437095840688201, -3.860763985256218],
              [-32.437317929651094, -3.860708373816367],
              [-32.437662058437681, -3.860786665257029],
              [-32.438017262106271, -3.860909569314501],
              [-32.438435048192346, -3.861041083319031],
              [-32.438626542392853, -3.861117627721606],
              [-32.438876329107657, -3.86103780150154],
              [-32.43927186349341, -3.860899248842026],
              [-32.439542072083221, -3.860634329235417],
              [-32.439816432736748, -3.860074381190501],
              [-32.439972095414987, -3.859728780244029],
              [-32.44002686001879, -3.859408612041622],
              [-32.440000371351026, -3.859157059215473],
              [-32.440112498346942, -3.859058372111073],
              [-32.440215886452471, -3.85887695272153],
              [-32.440150481156827, -3.858591420176697],
              [-32.439607492732101, -3.857007513733154],
              [-32.439097673926803, -3.855635513462451],
              [-32.438609404574748, -3.855267875482909],
              [-32.439231679094526, -3.854453517767612],
              [-32.439609697875653, -3.85363969096249],
              [-32.439687620262021, -3.853327493699233],
              [-32.439943270049021, -3.852881592658292],
              [-32.440454676679799, -3.851822515028321],
              [-32.439999227245337, -3.85081267763424],
              [-32.439986366296722, -3.849543865872713],
              [-32.439985368845555, -3.849445461495998],
              [-32.440380279123396, -3.846737271139367],
              [-32.440391788232155, -3.846112781563829],
              [-32.440372469936626, -3.841685526985928],
              [-32.440450607080379, -3.839166682877834],
              [-32.440488596150075, -3.83794204652168],
              [-32.440955830996458, -3.822879042454788],
              [-32.459415203623102, -3.841740960122786],
              [-32.465596021294544, -3.847871320074296],
              [-32.477998680199015, -3.860294572617065],
              [-32.484953864399429, -3.867294672331595],
              [-32.48453086223509, -3.870401760235292],
              [-32.483515888035946, -3.87785692522385],
              [-32.427681505433569, -3.896518008728276],
              [-32.420989083530309, -3.910470279338206],
              [-32.38615342518947, -3.916222750120351],
              [-32.355718349835634, -3.900077053511636],
              [-32.355251370812226, -3.878080538330273],
              [-32.355450782346054, -3.85673330879957],
              [-32.350114984427137, -3.843263965958379],
              [-32.354500756825203, -3.825646896551798],
              [-32.367777038228233, -3.792133680388007],
              [-32.384199591090486, -3.792133695008704],
              [-32.404354865945237, -3.802312851466313],
              [-32.40436870671131, -3.802623437772602],
              [-32.404269408174279, -3.803964496087249],
              [-32.404013019855313, -3.808933544829294],
              [-32.403913660289206, -3.81035930540894],
              [-32.403842515514583, -3.811629797347432],
              [-32.403459453278593, -3.816852864843369],
              [-32.403373617325578, -3.81904095780698],
            ],
            [
              [-32.404267803991736, -3.827956006681784],
              [-32.403977913642535, -3.828055425718083],
              [-32.403688022442275, -3.827720452527201],
              [-32.4036803866246, -3.827394768353172],
              [-32.403901640665168, -3.827132764705246],
              [-32.404035142323352, -3.827440310080338],
              [-32.404065664938301, -3.827738822498258],
              [-32.404267803991736, -3.827956006681784],
            ],
          ],
          [
            [
              [-29.266643781213549, 0.883306738443505],
              [-29.399976896344072, 0.8833067392777],
              [-29.399976895807402, 0.966639936030405],
              [-29.266643780681918, 0.966639935117858],
              [-29.266643781213549, 0.883306738443505],
            ],
          ],
        ],
      },
    },
  ],
};
