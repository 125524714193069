import { useLayers } from '../../../context/LayersContext';
import { GeoJSON, Pane, Marker } from 'react-leaflet';
import { jsonEOLExpansaoPlanejada16 } from '../../../LocalStore/EOL__Expanso_Planejada_16';
import { jsonLinhasDeTranmissaoExpansaoPlanejada17 } from '../../../LocalStore/Linhas_de_Transmisso__Expanso_Planejada_17';
import { jsonSubestaesExpansaoPlanejada18 } from '../../../LocalStore/Subestaes__Expanso_Planejada_18';
import { jsonUFVExpansoPlanejada19 } from '../../../LocalStore/UFV__Expanso_Planejada_19';
import {
  styleEOLExpansaoPlanejada16,
  styleLinhasDeTranmissaoExpansaoPlanejada17,
  styleSubestaesExpansoPlanejada18,
  styleUFVExpansaoPlanejada19,
} from './layerStyles';
export function RenderSistemaEletricoPlanejado() {
  const { /*addNewLayer,*/ layers } = useLayers();
  if (!layers) return null;
  const localStorageDict = [
    {
      name: 'Parques Eólicos - Sistema Elétrico Planejado',
      data: jsonEOLExpansaoPlanejada16,
      style: styleEOLExpansaoPlanejada16,
    },
    // Checar se é possível utilizar as funções de estilo que existem na main (Panes_seplan) e comentadas em layerStyles
    {
      name: 'Linhas de Transmissão - Sistema Elétrico Planejado',
      data: jsonLinhasDeTranmissaoExpansaoPlanejada17,
      style: styleLinhasDeTranmissaoExpansaoPlanejada17,
      layerType: 'GEOJSON',
    },
    {
      name: 'Subestações - Sistema Elétrico Planejado',
      data: jsonSubestaesExpansaoPlanejada18,
      style: styleSubestaesExpansoPlanejada18,
    },
    {
      name: 'Usinas Fotovotaicas - Sistema Elétrico Planejado',
      data: jsonUFVExpansoPlanejada19,
      style: styleUFVExpansaoPlanejada19,
    },
  ];

  return (
    <>
      {localStorageDict.map((obj) => {
        if (layers.includes(obj.name)) {
          if (obj.data) {
            if (obj.layerType === 'GEOJSON') {
              return (
                <GeoJSON
                  //@ts-ignore
                  data={obj.data}
                  key={obj.name}
                  //@ts-ignore
                  style={obj.style}
                />
              );
            }
            return (
              <Pane
                name={obj.name}
                style={{ zIndex: 418, mixBlendMode: 'normal' }}
              >
                {obj.data.features.map((feature, index) => (
                  <Marker
                    key={index}
                    position={[
                      // @ts-ignore
                      feature.geometry.coordinates[1],
                      // @ts-ignore
                      feature.geometry.coordinates[0],
                    ]}
                    // @ts-ignore
                    icon={obj.style?.icon}
                  />
                ))}
              </Pane>
            );
          }
        }
      })}
    </>
  );
}
