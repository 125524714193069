// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-container {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 360px !important;
  height: auto;
  z-index: 1000;
  pointer-events: none;
}

.modal-container.show {
  display: flex;
}

.modal-content {
  pointer-events: auto;
  position: absolute;
  top: 340px;
  left: 300px;
  transform: translate(-50%, -50%);
  width: fit-content;
  max-height: 80vh;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.modal-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 8px;
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: 600;
}

.clean-layers-button:hover,
.close-button:hover {
  color: #333;
}
`, "",{"version":3,"sources":["webpack://./src/Components/RulerModal/index.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,uBAAuB;EACvB,YAAY;EACZ,aAAa;EACb,oBAAoB;AACtB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,oBAAoB;EACpB,kBAAkB;EAClB,UAAU;EACV,WAAW;EACX,gCAAgC;EAChC,kBAAkB;EAClB,gBAAgB;EAChB,sBAAsB;EACtB,aAAa;EACb,kBAAkB;EAClB,wCAAwC;AAC1C;;AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,6BAA6B;EAC7B,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;EAEE,WAAW;AACb","sourcesContent":[".modal-container {\n  display: none;\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 360px !important;\n  height: auto;\n  z-index: 1000;\n  pointer-events: none;\n}\n\n.modal-container.show {\n  display: flex;\n}\n\n.modal-content {\n  pointer-events: auto;\n  position: absolute;\n  top: 340px;\n  left: 300px;\n  transform: translate(-50%, -50%);\n  width: fit-content;\n  max-height: 80vh;\n  background-color: #fff;\n  padding: 20px;\n  border-radius: 8px;\n  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);\n}\n\n.modal-title {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  border-bottom: 1px solid #ddd;\n  padding-bottom: 8px;\n  margin-bottom: 16px;\n  font-size: 20px;\n  font-weight: 600;\n}\n\n.clean-layers-button:hover,\n.close-button:hover {\n  color: #333;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
