import { ElementType } from 'react';
import miniMapBG from '../../assets/minimap-bg.png';
import { useRulerModalContext } from '../../context/RulerContext';
import './index.css';

interface RulerComponentProps {
  icon: ElementType;
  iconLabel: string;
  rulerAction: 'LOCATION' | 'PRINT' | 'DRAW' | 'MEASURE';
}

export default function RulerComponent({
  icon: Icon,
  iconLabel,
  rulerAction,
}: RulerComponentProps) {
  const { activeFunction, closeModal, activeFunctionHandler } =
    useRulerModalContext();

  return (
    <div
      className="ruler"
      onClick={() => {
        closeModal();
        switch (rulerAction) {
          case 'LOCATION':
          case 'MEASURE':
          case 'PRINT':
          case 'DRAW':
            activeFunctionHandler(rulerAction);
            if (rulerAction === 'PRINT') {
              new Promise((resolve) => setTimeout(resolve, 10)).then(() =>
                window.print()
              );
            }
            break;
        }
      }}
    >
      <div
        className="ruler-container"
        style={{
          border: activeFunction === rulerAction ? '1px solid #9FC131' : 'none',
          borderRadius: '4px',
        }}
      >
        <img
          style={{
            display: 'block',
            width: '88px',
            height: '88px',
          }}
          src={miniMapBG}
          alt="minimap-bg"
        />
        <Icon
          style={{
            position: 'absolute',
            top: '36%',
            left: '38%',
            color: activeFunction === rulerAction ? 'red' : '#015C53',
            pointerEvents: 'click',
          }}
          fontSize="large"
        />
      </div>
      <h2
        className="ruler-label"
        style={{ color: activeFunction === rulerAction ? '#9FC131' : 'black' }}
      >
        {iconLabel}
      </h2>
    </div>
  );
}
