import L, { Control } from 'leaflet';
import { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useMap } from 'react-leaflet';
import { useLayers } from '../../context/LayersContext';
import { layersDict } from '../../global/constants/layersDict';
import { LegendData } from '../LegendData';
import {
  LegendContainer,
  LegendHeader,
  LegendLabel,
  LegendMessage,
  LegendTitle,
} from './styles';
import './styles.css';
export function Legend() {
  const { layers } = useLayers();
  const map = useMap();
  const [expanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (map && layers.length === 0) {
      const noLayersControl = new Control({ position: 'bottomright' });

      noLayersControl.onAdd = () => {
        const div = L.DomUtil.create('div', 'no-layers-message');

        L.DomEvent.disableScrollPropagation(div);
        L.DomEvent.disableClickPropagation(div);

        createRoot(div).render(
          <LegendContainer expanded={expanded}>
            <div style={{ marginTop: '180px' }} />
            <LegendMessage>Não há legendas para exibir.</LegendMessage>
            <LegendMessage>
              Primeiro selecione alguma camada que deseja visualizar.
            </LegendMessage>
          </LegendContainer>
        );
        return div;
      };

      noLayersControl.addTo(map);
      return () => {
        map.removeControl(noLayersControl);
      };
    }

    if (map && layers.length > 0) {
      const legend = new Control({ position: 'bottomright' });

      legend.onAdd = () => {
        const div = L.DomUtil.create('div');

        L.DomEvent.disableScrollPropagation(div);
        L.DomEvent.disableClickPropagation(div);

        createRoot(div).render(
          <LegendContainer expanded={expanded}>
            <LegendHeader>
              <LegendTitle>Legendas</LegendTitle>
              {/* <IconButton onClick={() => toggleExpanded()}>
                {expanded === false ? (
                  <KeyboardArrowUpIcon fontSize="large" />
                ) : (
                  <KeyboardArrowDownIcon fontSize="large" />
                )}
              </IconButton> */}
            </LegendHeader>
            {layersDict.map((layer, index) => {
              if (layers.includes(layer.title)) {
                return (
                  <div key={index}>
                    <LegendLabel>{layer.title}</LegendLabel>
                    <LegendData
                      layer={layer.layerName}
                      workspace={layer.workspace}
                    />
                  </div>
                );
              }
            })}
          </LegendContainer>
        );
        return div;
      };

      legend.addTo(map);
      return () => {
        map.removeControl(legend);
      };
    }

    return undefined;
  }, [map, layers, expanded]);

  return null;
}
