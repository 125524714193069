import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import HighchartsMore from 'highcharts/highcharts-more';

if (typeof Highcharts === 'object') {
  HighchartsMore(Highcharts);
}

interface WindDirectionChartProps {
  windData: number[];
}

export const WindDirectionChart: React.FC<WindDirectionChartProps> = ({
  windData,
}) => {
  const options = {
    chart: {
      polar: true,
    },

    title: {
      text: 'Direção do vento predominante',
      style: {
        fontSize: '12px',
        color: '#015C53',
      },
    },
    pane: {
      startAngle: 0,
      endAngle: 360,
      color: '#015C53',
    },

    xAxis: {
      tickInterval: 90,
      min: 0,
      max: 360,
      labels: {
        format: '{value}%',
      },
    },

    yAxis: {
      tickInterval: 10,
      min: 0,
      max: 40,
      labels: {
        format: '{value}%',
      },
    },

    plotOptions: {
      series: {
        pointStart: 0,
        pointInterval: 45,
      },
      column: {
        pointPadding: 0,
        groupPadding: 0,
      },
    },

    series: [
      {
        type: 'column',
        name: 'Column',
        data: [0, 0, 40, 30],
        pointPlacement: 'between',
        color: '#E2E2E2',
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
