import styled from 'styled-components';
interface LegendContainerProps {
  expanded: boolean;
}
export const LegendContainer = styled.div.attrs({
  className: 'info-legend',
})<LegendContainerProps>`
  position: fixed;
  top: 210px;
  right: 20px;
  width: 500px;
  height: 500px;
  background-color: white;
  padding: 20px;
  transition: transform 0.3s ease-in-out;
  border-radius: 4px;
  overflow: auto;
`;

export const LegendHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
`;

export const LegendTitle = styled.h4`
  text-align: center;
  font-size: 15px;
  font-weight: 600;
`;

export const LegendLabel = styled.h5`
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const LegendMessage = styled.div`
  color: #707070;
  font-style: italic;
  text-align: center;
`;
