import React, { useEffect, useState } from 'react';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { FormControlLabel } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useLayers } from '../../context/LayersContext';
import { StyledCheckbox, TreeViewContainer } from './styles';

interface Layer {
  title: string;
  children?: Layer[];
}

interface TreeNode {
  value: string;
  label: string;
  children?: TreeNode[];
}

export interface CheckboxTreeViewProps {
  data: Layer[];
}

function mapLayerToTreeNode(layer: Layer): TreeNode {
  return {
    value: layer.title,
    label: layer.title,
    children: layer.children?.map(mapLayerToTreeNode),
  };
}

function leafIds(node: TreeNode): string[] {
  if (!node.children || node.children.length === 0) {
    return [node.value];
  } else {
    return node.children.flatMap(leafIds);
  }
}

function leafNodes(layers: Layer[]): Layer[] {
  return layers.flatMap((layer) => {
    if (layer.children && layer.children.length > 0) {
      return leafNodes(layer.children);
    } else {
      return [layer];
    }
  });
}

export function CheckboxTreeView({ data }: CheckboxTreeViewProps) {
  const { addNewLayer, layers } = useLayers();
  const [checked, setChecked] = useState<string[]>(layers);
  const [expanded, setExpanded] = useState<string[]>([]);

  useEffect(() => {
    setChecked(layers);
  }, [layers]);

  useEffect(() => {
    const visibleLayerTitles = new Set(
      leafNodes(data).map((layer) => layer.title)
    );
    const newChecked = checked.filter((id) => visibleLayerTitles.has(id));

    if (newChecked.length !== checked.length) {
      setChecked(newChecked);
    }
  }, [data]);

  const nodes = data.map(mapLayerToTreeNode);

  const handleCheck = (node: TreeNode, newValue: boolean) => {
    const isChecked = checked.includes(node.value);

    if (!node.children || node.children.length === 0) {
      if (isChecked === newValue) return;

      const updatedChecked = newValue
        ? [...checked, node.value]
        : checked.filter((id) => id !== node.value);

      setChecked(updatedChecked);
      addNewLayer(updatedChecked);
    } else {
      const ids = leafIds(node);
      const remaining = checked.filter((id) => !ids.includes(id));
      const updatedChecked = newValue ? [...remaining, ...ids] : remaining;

      setChecked(updatedChecked);
      addNewLayer(updatedChecked);
    }
  };

  function TreeNodeComponent({ node }: { node: TreeNode }) {
    const visibleLeafIds = leafIds(node);

    const isChecked = visibleLeafIds.every((id) => checked.includes(id));
    const isIndeterminate =
      !isChecked && visibleLeafIds.some((id) => checked.includes(id));

    const onChange = () => {
      handleCheck(node, !isChecked);
    };

    return (
      <TreeItem
        key={node.value}
        nodeId={node.value}
        label={
          <FormControlLabel
            control={
              <StyledCheckbox
                checked={isChecked}
                indeterminate={isIndeterminate}
                onChange={onChange}
                onClick={(e) => e.stopPropagation()}
              />
            }
            label={node.label}
            onClick={(e) => e.stopPropagation()}
          />
        }
      >
        {node.children &&
          node.children.map((child) => (
            <TreeNodeComponent key={child.value} node={child} />
          ))}
      </TreeItem>
    );
  }

  return (
    <TreeViewContainer
      defaultCollapseIcon={<ExpandLessIcon />}
      defaultExpandIcon={<ExpandMoreIcon />}
      expanded={expanded}
      onNodeToggle={(_, nodeIds) => setExpanded(nodeIds)}
    >
      {nodes.map((node) => (
        <TreeNodeComponent key={node.value} node={node} />
      ))}
    </TreeViewContainer>
  );
}
