export var jsonUTEFssilBaseExistente20 = {
  type: 'FeatureCollection',
  name: 'UTE_Fssil__Base_Existente_20',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        nome: 'Pamesa do Brasil',
        propriet: '100% para Pamesa do Brasil S.A. (REG)',
        potencia: 4072.0,
        ini_oper: '2004-06-30',
        combust: 'Gás Natural',
        ceg: 'UTE.GN.PE.029189-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.005034846999934, -8.328210784999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Campina Grande',
        propriet: '100% para BORBOREMA ENERGÉTICA S.A. (PIE)',
        potencia: 169080.0,
        ini_oper: '2011-02-08',
        combust: 'Óleo Combustível',
        ceg: 'UTE.PE.PB.000612-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.908435911999959, -7.296897301999934],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Pau Ferro I',
        propriet: '100% para Centrais Elétricas de Pernambuco S.A. (PIE)',
        potencia: 94080.0,
        ini_oper: '2009-06-18',
        combust: 'Óleo Diesel',
        ceg: 'UTE.PE.PE.029561-2.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.017740565999929, -7.858206483999936],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Pernambuco III',
        propriet: '100% para TERMELÉTRICA PERNAMBUCO III S.A. (PIE)',
        potencia: 200790.0,
        ini_oper: '2013-12-05',
        combust: 'Óleo Combustível',
        ceg: 'UTE.PE.PE.030120-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.019341675999954, -7.863746925999976],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Petrolina',
        propriet: '100% para COMPANHIA ENERGÉTICA DE PETROLINA (PIE)',
        potencia: 136200.0,
        ini_oper: '2002-10-03',
        combust: 'Óleo Diesel',
        ceg: 'UTE.PE.PE.028584-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.583389390999969, -9.371422024999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Suape II',
        propriet: '100% para Energética Suape II S.A. (PIE)',
        potencia: 381260.0,
        ini_oper: '2013-01-24',
        combust: 'Óleo Combustível',
        ceg: 'UTE.PE.PE.029719-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.02166409299997, -8.358088503999966],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Termocabo',
        propriet: '100% para TERMOCABO S.A. (PIE)',
        potencia: 49730.0,
        ini_oper: '2002-09-19',
        combust: 'Gás Natural',
        ceg: 'UTE.GN.PE.028326-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.031103602999963, -8.276542812999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Termomanaus',
        propriet: '100% para Centrais Elétricas de Pernambuco S.A. (PIE)',
        potencia: 143040.0,
        ini_oper: '2009-06-18',
        combust: 'Óleo Diesel',
        ceg: 'UTE.PE.PE.029562-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.018766759999949, -7.86077303999997],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Termonordeste',
        propriet: '100% para Centrais Elétricas da Paraíba S.A. (PIE)',
        potencia: 170850.0,
        ini_oper: '2010-12-24',
        combust: 'Óleo Combustível',
        ceg: 'UTE.PE.PB.029641-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.921999344999961, -7.208635767999965],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Termoparaíba',
        propriet: '100% para Centrais Elétricas da Paraíba S.A. (PIE)',
        potencia: 170850.0,
        ini_oper: '2011-01-13',
        combust: 'Óleo Combustível',
        ceg: 'UTE.PE.PB.029638-4.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.922958919999928, -7.209573569999975],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Termopernambuco',
        propriet: '100% para TERMOPERNAMBUCO S/A (PIE)',
        potencia: 550000.0,
        ini_oper: '2004-05-15',
        combust: 'Gás Natural',
        ceg: 'UTE.GN.PE.028031-3.XX',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-34.956923549999942, -8.399065659999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Crato',
        propriet: 'Enguia GEN CE Ltda',
        potencia: 13120.0,
        ini_oper: '2002-04-23',
        combust: 'Óleo Diesel',
        ceg: 'UTE.PE.CE.028530-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.398922701999936, -7.224151986999971],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Juazeiro do Norte',
        propriet: 'Enguia GEN CE Ltda',
        potencia: 14760.0,
        ini_oper: '2002-04-21',
        combust: 'Óleo Diesel',
        ceg: 'UTE.PE.CE.028534-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.322584165999956, -7.231600434999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Iguatu',
        propriet: 'Enguia GEN CE Ltda.',
        potencia: 14760.0,
        ini_oper: '2002-04-24',
        combust: 'Óleo Diesel',
        ceg: 'UTE.PE.CE.028532-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.296689705999938, -6.375916263999954],
      },
    },
  ],
};
