import { useState } from 'react';
import { FeatureGroup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import {
  Footer,
  MapActionBar,
  MapComponent,
  Navbar,
  UploadFileModal,
} from '../../Components';
import { MeasureComponent } from '../../Components/MeasureComponent';
import RulerModal from '../../Components/RulerModal';
import { useRulerModalContext } from '../../context/RulerContext';
import { HomeContainer } from '../styles';
import TabComponent from '../../Components/TabComponent';
import TabLegendComponent from '../../Components/TabLegendComponent';
import LegendActionBar from '../../Components/LegendActionBar';

export function Home() {
  const [showModal, setShowModal] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedLegendTab, setSelectedLegendTab] = useState(0);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [showToolModal, setShowToolModal] = useState(false);
  const [showMiniMap, setShowMiniMap] = useState(false);
  const [showLegendModal, setShowLegendModal] = useState(false);
  const [modalLeftPosition, setModalLeftPosition] = useState('0px');
  const [modalRightPositionMiniMap, setModalRightPositionMiniMap] =
    useState('20px');
  const [latitude, setLatitude] = useState<string | null>(null);
  const [longitude, setLongitude] = useState<string | null>(null);
  const [address, setAddress] = useState<string | null>(null);
  const [globalIrradiation, setGlobalIrradiation] = useState<string | null>(
    null
  );
  const [difuseIrradiation, setDifuseIrradiation] = useState<string | null>(
    null
  );
  const [directIrradiation, setDirectIrradiation] = useState<string | null>(
    null
  );
  const [monthlyIrradiation, setMonthlyIrradiation] = useState<number[]>([]);

  const { activeFunction } = useRulerModalContext();

  const toggleModal = () => {
    setShowModal((prev) => !prev);
  };

  const toolModal = () => {
    setShowToolModal((prev) => !prev);
  };

  const miniMapModal = () => {
    setShowMiniMap((prev) => !prev);
  };

  const uploadFileModal = () => {
    setShowUploadFileModal((prev) => !prev);
  };

  const toggleLegend = () => {
    setShowLegendModal((prev) => !prev);
  };

  return (
    <HomeContainer>
      <Navbar />
      <MapComponent
        showMiniMap={showMiniMap}
        rightPosition={modalRightPositionMiniMap}
        setLatitude={setLatitude}
        setLongitude={setLongitude}
        setAddress={setAddress}
        setGlobalIrradiation={setGlobalIrradiation}
        setDifuseIrradiation={setDifuseIrradiation}
        setDirectIrradiation={setDirectIrradiation}
        setMonthlyIrradiaton={setMonthlyIrradiation}
      >
        <MapActionBar
          showModal={showModal}
          handleToggleModal={toggleModal}
          handleToolModal={toolModal}
          showToolModal={showToolModal}
          handleUploadFileModal={uploadFileModal}
          showUploadFileModal={showUploadFileModal}
          onPositionChange={setModalLeftPosition}
        />
        <LegendActionBar
          showLegendModal={showLegendModal}
          handleLegendModal={toggleLegend}
          handleMiniMapModal={miniMapModal}
          showMiniMapModal={showMiniMap}
          onPositionChangeMiniMap={setModalRightPositionMiniMap}
        />
        <FeatureGroup>
          {activeFunction === 'LOCATION' && (
            <EditControl
              position="bottomright"
              draw={{
                polyline: false,
                polygon: false,
                rectangle: false,
                circle: false,
                marker: true,
                circlemarker: false,
              }}
            />
          )}
          {activeFunction === 'DRAW' && (
            <EditControl
              position="bottomright"
              draw={{
                polyline: true,
                polygon: false,
                rectangle: false,
                circle: false,
                marker: false,
                circlemarker: false,
              }}
            />
          )}
          {activeFunction === 'MEASURE' && <MeasureComponent />}
        </FeatureGroup>
        {showLegendModal && (
          <TabLegendComponent
            selectedLegendTab={selectedLegendTab}
            setSelectedLegendTab={setSelectedLegendTab}
            onClose={() => setShowLegendModal(false)}
            latitude={latitude}
            longitude={longitude}
            address={address}
            globalIrradiation={globalIrradiation}
            difuseIrradiation={difuseIrradiation}
            directIrradiation={directIrradiation}
            monthlyIrradiation={monthlyIrradiation}
          />
        )}
      </MapComponent>
      {showModal && (
        <TabComponent
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          onClose={() => setShowModal(false)}
        />
      )}

      <UploadFileModal
        show={showUploadFileModal}
        onClose={setShowUploadFileModal}
        leftPosition={modalLeftPosition}
      />

      <RulerModal
        show={showToolModal}
        onClose={setShowToolModal}
        leftPosition={modalLeftPosition}
      />

      <Footer />
    </HomeContainer>
  );
}

export default Home;
