import { useEffect, useState } from 'react';
import { useMapEvents } from 'react-leaflet';
import parse from 'html-react-parser';
import { StyledPopup } from './styles';
import {
  geoServerPopupDefaultParams,
  geoServer,
} from '../../services/geoServer';
import { useLayers } from '../../context/LayersContext';
import './tableStyle.css';
import { layersDict } from '../../global/constants/layersDict';

interface RenderAttributeTableProps {
  setLatitude: (value: string | null) => void;
  setLongitude: (value: string | null) => void;
  setAddress: (value: string | null) => void;
  setGlobalIrradiation: (value: string | null) => void;
  setDifuseIrradiation: (value: string | null) => void;
  setDirectIrradiation: (value: string | null) => void;
  setMonthlyIrradiation: (value: number[]) => void;
}

export const RenderAttributeTable: React.FC<RenderAttributeTableProps> = ({
  setLatitude,
  setLongitude,
  setAddress,
  setGlobalIrradiation,
  setDifuseIrradiation,
  setDirectIrradiation,
  setMonthlyIrradiation,
}) => {
  const { layers } = useLayers();
  const [coords, setCoords] = useState<any>(null);
  const [attributeTable, setAttributeTable] = useState('');
  const [layerList, setLayerList] = useState('');

  useMapEvents({
    click(e) {
      const { lat, lng } = e.latlng;

      const dentroDePernambuco =
        lat >= -9.78 && lat <= -7.3 && lng >= -41.0 && lng <= -34.8;

      if (!dentroDePernambuco) {
        return;
      }

      const target = e.originalEvent.target as HTMLElement;

      return target.closest('#mapActionBar') ||
        target.closest('#legendActionBar')
        ? null
        : layers.length > 0
        ? (setCoords(e.latlng), setAttributeTable(''))
        : null;
    },
  });

  useEffect(() => {
    setLayerList('');
    layersDict.forEach((obj) => {
      if (layers.includes(obj.title)) {
        setLayerList((prevLayerList) => {
          const newLayerList = prevLayerList
            ? prevLayerList + ',' + obj.workspace + ':' + obj.layerName
            : obj.workspace + ':' + obj.layerName;
          return Array.from(new Set(newLayerList.split(','))).toString();
        });
      }
    });
  }, [layers]);

  useEffect(() => {
    async function getFeatures() {
      if (!coords || !layerList) return;
      layerList.split(',').forEach(async (layerName) => {
        const resp = await geoServer.get('wms', {
          params: {
            ...geoServerPopupDefaultParams,
            QUERY_LAYERS: layerName,
            LAYERS: layerName,
            BBOX:
              coords.lng -
              0.1 +
              ',' +
              (coords.lat - 0.1) +
              ',' +
              (coords.lng + 0.1) +
              ',' +
              (coords.lat + 0.1),
          },
        });

        const htmlString = resp.data;
        const regex = /<table class="featureInfo">(.*?)<\/table>/gs;
        const matches = [...htmlString.matchAll(regex)];

        if (matches) {
          matches.forEach((match) => {
            setAttributeTable((prevTable) => prevTable + match[0]);

            const rowRegexDifuse =
              /<tr>\s*<td>Iradiacao_Difusa_Anual\.\d+<\/td>\s*<td>(\d+)<\/td>\s*<td>(.*?)<\/td>\s*<td>([-+]?\d*\.\d+|\d+)<\/td>\s*<td>([-+]?\d*\.\d+|\d+)<\/td>\s*<td>([-+]?\d*\.\d+|\d+)<\/td>/;

            const rowRegexDirect =
              /<td>Iradiacao_Direta_Normal_Anual\.\d+<\/td>\s*<td>(.*?)<\/td>\s*<td>([-+]?\d*\.\d+|\d+)<\/td>\s*<td>([-+]?\d*\.\d+|\d+)<\/td>\s*<td>(\d+)<\/td>(?:\s*<td>(\d+)<\/td>){12}/i;

            const rowRegex =
              /<tr>\s*<td>Irradiacao_Global_Horizontal_Anual\.\d+<\/td>\s*<td>(\d+)<\/td>\s*<td>(.*?)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>\s*<td>([-+]?\d+\.\d+|\d+)<\/td>/;

            const rowMatch = match[1].match(rowRegex);
            const rowMatchDifuse = match[1].match(rowRegexDifuse);
            const rowMatchDirect = match[1].match(rowRegexDirect);

            if (rowMatchDirect) {
              const directIrradiation = rowMatchDirect[4];
              setDirectIrradiation(directIrradiation);
            }

            if (rowMatchDifuse) {
              const difuseIrradiation = rowMatchDifuse[5];
              setDifuseIrradiation(difuseIrradiation);
            }

            if (rowMatch) {
              const country = rowMatch[2];
              const lon = rowMatch[3];
              const lat = rowMatch[4];
              const globalIrradiation = rowMatch[5];
              const janIrradiation = rowMatch[6];
              const febIrradiation = rowMatch[7];
              const marIrradiation = rowMatch[8];
              const aprIrradiation = rowMatch[9];
              const mayIrradiation = rowMatch[10];
              const junIrradiation = rowMatch[11];
              const julIrradiation = rowMatch[12];
              const augIrradiation = rowMatch[13];
              const sepIrradiation = rowMatch[14];
              const octIrradiation = rowMatch[15];
              const novIrradiation = rowMatch[16];
              const decIrradiation = rowMatch[17];
              const monthlyIrradiation = [
                janIrradiation,
                febIrradiation,
                marIrradiation,
                aprIrradiation,
                mayIrradiation,
                junIrradiation,
                julIrradiation,
                augIrradiation,
                sepIrradiation,
                octIrradiation,
                novIrradiation,
                decIrradiation,
              ].map((value) => Number(value));
              console.log(monthlyIrradiation);
              setLatitude(lat);
              setLongitude(lon);
              setAddress(country);
              setGlobalIrradiation(globalIrradiation);
              setMonthlyIrradiation(monthlyIrradiation);
            }
          });
        }
        if (!matches || matches.length === 0) {
          return;
        }
      });
    }
    getFeatures();
  }, [coords, layerList]);

  return attributeTable && coords ? (
    <StyledPopup position={coords}>{parse(attributeTable)}</StyledPopup>
  ) : null;
};
