export var jsonAterrosSanitariosAtivosPe15 = {
  type: 'FeatureCollection',
  name: 'ATERROS_SANITARIOS_ATIVOS_PE_15',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario ECOPARQUE (CTR PERNAMBUCO)',
        Municipio: 'Igarassu',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -34.94262,
        Lat: -7.72089,
      },
      geometry: { type: 'Point', coordinates: [-34.942615, -7.720893] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario CTR Candeias',
        Municipio: 'Jaboatao dos Guararapes',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -34.97964,
        Lat: -8.1659,
      },
      geometry: { type: 'Point', coordinates: [-34.979644, -8.165895] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario CTR Ipojuca',
        Municipio: 'Ipojuca',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -35.09629,
        Lat: -8.43758,
      },
      geometry: { type: 'Point', coordinates: [-35.096292, -8.437583] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario Municipal de Ipojuca',
        Municipio: 'Ipojuca',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -35.04568,
        Lat: -8.48063,
      },
      geometry: { type: 'Point', coordinates: [-35.04568, -8.480627] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario CTR Capibaribe_Passira_PE',
        Municipio: 'Passira',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -35.53351,
        Lat: -7.96671,
      },
      geometry: { type: 'Point', coordinates: [-35.533514, -7.966708] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Escada',
        Municipio: 'Escada',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -35.24163,
        Lat: -8.38284,
      },
      geometry: { type: 'Point', coordinates: [-35.241628, -8.382837] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Belo Jardim',
        Municipio: 'Belo Jardim',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -36.44516,
        Lat: -8.36299,
      },
      geometry: { type: 'Point', coordinates: [-36.445158, -8.362991] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Altinho',
        Municipio: 'Altinho',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -36.10907,
        Lat: -8.52403,
      },
      geometry: { type: 'Point', coordinates: [-36.109067, -8.524034] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario CTR Caruraru',
        Municipio: 'Caruaru',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -35.99556,
        Lat: -8.2423,
      },
      geometry: { type: 'Point', coordinates: [-35.995559, -8.242298] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Gravata',
        Municipio: 'Gravata',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -35.62391,
        Lat: -8.21257,
      },
      geometry: { type: 'Point', coordinates: [-35.623912, -8.212568] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Garanhuns',
        Municipio: 'Garanhuns',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -36.5318,
        Lat: -8.83823,
      },
      geometry: { type: 'Point', coordinates: [-36.531803, -8.838231] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Arcoverde',
        Municipio: 'Arcoverde',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -37.06494,
        Lat: -8.39302,
      },
      geometry: { type: 'Point', coordinates: [-37.064944, -8.393015] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Salgueiro',
        Municipio: 'Salgueiro',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -39.11982,
        Lat: -8.11146,
      },
      geometry: { type: 'Point', coordinates: [-39.119823, -8.111463] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Petrolandia',
        Municipio: 'Petrolandia',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -38.22663,
        Lat: -8.93202,
      },
      geometry: { type: 'Point', coordinates: [-38.226634, -8.932015] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario Privado de Afogados da Ingazeira',
        Municipio: 'Afogados da Ingazeira',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -37.58135,
        Lat: -7.66757,
      },
      geometry: { type: 'Point', coordinates: [-37.581352, -7.667565] },
    },
    {
      type: 'Feature',
      properties: {
        AS: 'Aterro Sanitario de Petrolina - CRT Petrolina',
        Municipio: 'Petrolina',
        Situacao: 'Com Licenca de Operacao (LO)',
        Long: -40.50159,
        Lat: -9.36871,
      },
      geometry: { type: 'Point', coordinates: [-40.501589, -9.368711] },
    },
  ],
};
