export var jsonUFVBaseExistente14 = {
  type: 'FeatureCollection',
  name: 'UFV__Base_Existente_14',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        nome: 'Brígida',
        propriet: '100% para BRIGIDA SOLAR SPE LTDA (PIE)',
        Potencia: 31500.0,
        ini_oper: '2021-06-26',
        ceg: 'UFV.RS.PE.034169-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.696447219999982, -7.893361109999944],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Brigida 2',
        propriet: '100% para BRIGIDA 2 SOLAR SPE LTDA (PIE)',
        Potencia: 31500.0,
        ini_oper: '2021-06-26',
        ceg: 'UFV.RS.PE.034234-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.703250129999958, -7.89800557999996],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas II',
        propriet: '100% para COREMAS II GERAÇÃO DE ENERGIA SPE S.A (PIE)',
        Potencia: 27000.0,
        ini_oper: '2018-10-16',
        ceg: 'UFV.RS.PB.032926-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.001866379999967, -6.962953889999937],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas III',
        propriet: '100% para COREMAS III GERAÇÃO DE ENERGIA SPE S.A (PIE)',
        Potencia: 27000.0,
        ini_oper: '2020-11-17',
        ceg: 'UFV.RS.PB.032544-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.993843889999937, -6.947217499999965],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Fazenda Esmeralda',
        propriet: '100% para ESMERALDA ENERGIAS RENOVÁVEIS S.A. (PIE)',
        Potencia: 29000.0,
        ini_oper: '2019-04-18',
        ceg: 'UFV.RS.PE.034305-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.958002779999958, -8.41599666999997],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Fontes Solar I',
        propriet: '100% para ENEL SOLUÇÕES ENERGÉTICAS LTDA (REG)',
        Potencia: 5000.0,
        ini_oper: '2015-09-02',
        ceg: 'UFV.RS.PE.032372-1.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.147116659999938, -9.070061109999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Fontes Solar II',
        propriet: '100% para ENEL SOLUÇÕES ENERGÉTICAS LTDA (REG)',
        Potencia: 5000.0,
        ini_oper: '2015-09-08',
        ceg: 'UFV.RS.PE.032373-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.145986109999967, -9.071136109999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Sobrado 1',
        propriet: '100% para OH SOBRADO GERADORA DE ENERGIA SOLAR S.A (PIE)',
        Potencia: 30000.0,
        ini_oper: '2019-11-14',
        ceg: 'UFV.RS.BA.034387-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.812090739999974, -9.381151969999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Solar Salgueiro',
        propriet: '100% para SALGUEIRO I ENERGIAS RENOVÁVEIS S.A. (PIE)',
        Potencia: 30000.0,
        ini_oper: '2021-07-08',
        ceg: 'UFV.RS.PE.033606-8.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.344524989999968, -8.080372229999966],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Solar Salgueiro II',
        propriet: '100% para SALGUEIRO II ENERGIAS RENOVÁVEIS S.A. (PIE)',
        Potencia: 30000.0,
        ini_oper: '2021-07-08',
        ceg: 'UFV.RS.PE.034160-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.353402089999975, -8.085474849999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Solar Salgueiro III',
        propriet: '100% para SALGUEIRO III ENERGIAS RENOVÁVEIS S.A. (PIE)',
        Potencia: 30000.0,
        ini_oper: '2021-08-19',
        ceg: 'UFV.RS.PE.034105-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.355472219999967, -8.083333329999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Angico I',
        propriet: '100% para ANGICO ENERGIAS RENOVÁVEIS S.A. (PIE)',
        Potencia: 27200.0,
        ini_oper: '2018-09-29',
        ceg: 'UFV.RS.PB.034085-5.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.560173619999944, -6.917041349999977],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas I',
        propriet: '100% para COREMAS I GERAÇÃO DE ENERGIA SPE S.A (PIE)',
        Potencia: 27000.0,
        ini_oper: '2019-02-01',
        ceg: 'UFV.RS.PB.032311-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.997797299999945, -6.956731759999968],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Malta',
        propriet: '100% para MALTA ENERGIAS RENOVÁVEIS S.A. (PIE)',
        Potencia: 27200.0,
        ini_oper: '2018-10-27',
        ceg: 'UFV.RS.PB.034086-3.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.560142209999981, -6.909753659999978],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Bom Nome 1-5',
        propriet: '100% para Bon Nome Serviços Administrativos III Ltda. (PIE)',
        Potencia: 50000.0,
        ini_oper: '2022-02-03',
        ceg: 'UFV.RS.PE.042927-9.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-38.694731789999935, -7.884] },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Bom Nome 1-6',
        propriet: '100% para Bon Nome Serviços Administrativos III Ltda. (PIE)',
        Potencia: 50000.0,
        ini_oper: '2022-02-03',
        ceg: 'UFV.RS.PE.042928-7.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.705413889999932, -7.885322219999978],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas IV',
        propriet: '100% para Coremas IV Geração de Energia SPE Ltda. (PIE)',
        Potencia: 27000.0,
        ini_oper: '2022-09-02',
        ceg: 'UFV.RS.PB.032843-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.989651949999939, -6.95712027999997],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas VI',
        propriet: '100% para Coremas VI Geração de Energia SPE LTDA. (PIE)',
        Potencia: 27000.0,
        ini_oper: '2022-09-09',
        ceg: 'UFV.RS.PB.032865-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.98305887999993, -6.949473619999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas VIII',
        propriet: '100% para Coremas VIII Geração de Energia SPE Ltda. (PIE)',
        Potencia: 27000.0,
        ini_oper: '2022-08-30',
        ceg: 'UFV.RS.PB.037916-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.002406109999981, -6.949812219999956],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas V',
        propriet: '100% para Coremas V Geração de Energia SPE Ltda. (PIE)',
        Potencia: 27000.0,
        ini_oper: '2022-08-24',
        ceg: 'UFV.RS.PB.032888-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.995980269999961, -6.95228721999996],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Coremas VII',
        propriet: '100% para Coremas VII Geração de Energia SPE Ltda. (PIE)',
        Potencia: 27000.0,
        ini_oper: '2022-05-26',
        ceg: 'UFV.RS.PB.032882-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.995986669999979, -6.948082499999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Polpa Ideal I',
        propriet: '100% para Industria de Polpa de Frutas Ideal LTDA (REG)',
        Potencia: 217.2,
        ini_oper: '2020-02-17',
        ceg: 'UFV.RS.PB.052044-6.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-34.82389, -7.16381] },
    },
    {
      type: 'Feature',
      properties: {
        nome: 'Polpa Ideal Ii',
        propriet: '100% para Industria de Polpa de Frutas Ideal LTDA (REG)',
        Potencia: 226.4,
        ini_oper: '2020-12-28',
        ceg: 'UFV.RS.PB.050792-0.01',
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: { type: 'Point', coordinates: [-34.82441, -7.16741] },
    },
  ],
};
