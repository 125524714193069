export var jsonSubestaesExpansaoPlanejada18 = {
  type: 'FeatureCollection',
  name: 'Subestaes__Expanso_Planejada_18',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Lagoa do Barro 01',
        Concession: '-',
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -41.60575,
        latitude: -8.67367,
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.605746050999983, -8.673665467999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Elevadora Lagoa dos Ventos',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -41.51089,
        latitude: -8.73124,
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.510894593999978, -8.731236445999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Coletora UFSJP',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '500',
        longitude: -42.15449,
        latitude: -8.26274,
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.154486050999935, -8.262737925999943],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Crato',
        Concession: null,
        Ano_Opera: '2023',
        Tensao: '230',
        longitude: -39.38086,
        latitude: -7.19064,
      },
      geometry: {
        type: 'Point',
        coordinates: [-39.380862371999967, -7.190639799999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Mauriti',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -38.78205,
        latitude: -7.36503,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.782045421999953, -7.365026607999937],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Elevadora Abaiara',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -38.97157,
        latitude: -7.39155,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.971565926999972, -7.391547841999966],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Serrote',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -36.7432,
        latitude: -9.6738,
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.74319520499995, -9.673804201999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Bom Lugar Norte',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -38.95437,
        latitude: -6.38265,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.954373929999974, -6.38264980799994],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Lagoa dos Ventos III',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '500',
        longitude: -41.51331,
        latitude: -8.76323,
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.513310206999961, -8.763227107999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE UFV Belmonte',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -38.83471,
        latitude: -7.8701,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.83471188599998, -7.870097265999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Canudos I',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '500',
        longitude: -38.56151,
        latitude: -10.14311,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.561506169999973, -10.143113687999971],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Nossa Senhora da Glória II',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -37.43606,
        latitude: -10.27959,
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.436059344999933, -10.27959154499996],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Taboleiro do Meio',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -37.98673,
        latitude: -6.9811,
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.986731813999938, -6.981095128999925],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Serra Talhada',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -38.58338,
        latitude: -8.00303,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.583375992999947, -8.003027428999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE UFV Nelore',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -38.15335,
        latitude: -9.3898,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.15335307099997, -9.389801252999973],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE São Mamede',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '500',
        longitude: -37.0826,
        latitude: -6.93186,
      },
      geometry: {
        type: 'Point',
        coordinates: [-37.08260179499996, -6.931863700999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Chateau Fort',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500',
        longitude: -42.16644,
        latitude: -8.38356,
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.166441753999948, -8.383555582999975],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Eólico Oeste Seridó',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500',
        longitude: -36.66943,
        latitude: -6.7863,
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.669428171999982, -6.786295389999964],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Bom Nome II',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500/230',
        longitude: -38.65098,
        latitude: -8.03116,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.650980555999979, -8.03116111099996],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Zebu III',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500/230',
        longitude: -38.14779,
        latitude: -9.34184,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.147794443999942, -9.341844443999946],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Campo Formoso II',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500',
        longitude: -40.98222,
        latitude: -10.265,
      },
      geometry: { type: 'Point', coordinates: [-40.982222221999962, -10.265] },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Pilões III',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -35.61792,
        latitude: -6.87978,
      },
      geometry: { type: 'Point', coordinates: [-35.617916, -6.87978] },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE São João do Piauí II',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500',
        longitude: -42.50658,
        latitude: -8.27507,
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.506575, -8.275072221999949],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Gameleira',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -38.92954,
        latitude: -7.26813,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.929545, -7.268125027999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Coletora Zebu',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -38.18205,
        latitude: -9.36038,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.182050188999938, -9.360379639999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Araripe IV',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500',
        longitude: -40.65661,
        latitude: -7.63434,
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.65661079399996, -7.634338758999945],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Bom Jardim',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -38.86497,
        latitude: -6.35778,
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.864970667999955, -6.357779594999954],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Serra da Palmeira',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500',
        longitude: -36.33581,
        latitude: -6.66835,
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.33581322799995, -6.668345363999947],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Bonito',
        Concession: null,
        Ano_Opera: null,
        Tensao: '500',
        longitude: -41.6999,
        latitude: -8.68594,
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.699902840999982, -8.685940048999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Elevadora Umari',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -35.74699,
        latitude: -6.40624,
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.746991587999958, -6.406240059999959],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE UFV Seridó',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -36.54128,
        latitude: -6.92746,
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.541276899999957, -6.927460492999955],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Frei Damião',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -35.91934,
        latitude: -6.62884,
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.919340457999965, -6.628844021999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Elevadora UFV Graviola',
        Concession: null,
        Ano_Opera: '-',
        Tensao: '230',
        longitude: -42.34691,
        latitude: -8.29405,
      },
      geometry: {
        type: 'Point',
        coordinates: [-42.346912550999946, -8.294049385999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE UFV Humaita',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -40.42888,
        latitude: -9.53672,
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.428884616999937, -9.536718667999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        Nome: 'SE Serra da Borborema',
        Concession: null,
        Ano_Opera: null,
        Tensao: '230',
        longitude: -35.98853,
        latitude: -7.04545,
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.98852827199994, -7.045449664999978],
      },
    },
  ],
};
