import L from 'leaflet';
import estacoes_solarimetricas from '../../../assets/markers/estacoes_solarimetricas.svg';

// function style_Empresas_para_Biomassa_7_0(feature) {
//   return {
//     pane: 'pane_Empresas_para_Biomassa_7',
//     radius: 3.2,
//     opacity: 1,
//     color: 'rgba(255,255,255,1.0)',
//     dashArray: '',
//     lineCap: 'butt',
//     lineJoin: 'miter',
//     weight: 2.0,
//     fill: true,
//     fillOpacity: 1,
//     fillColor: 'rgba(0,0,0,1.0)',
//     interactive: true
//   };
// }

export const style_TRA_PEAD_REDE_COPERGAS_01092023rede_copergs_01092023_19_0 = {
  pane: 'pane_TRA_PEAD_REDE_COPERGAS_01092023rede_copergs_01092023_19',
  opacity: 1,
  color: 'rgba(253, 93, 7, 1)',
  dashArray: '',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 5,
  fillOpacity: 0,
  interactive: true,
};

export const style_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18_0 = {
  opacity: 1,
  color: 'rgba(8, 213, 249, 1)',
  dashArray: '',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 6,
  fillOpacity: 0,
  interactive: true,
};

export const style_TRA_ACO_REDE_COPERGAS_01092023rede_copergs_01092023_18_1 = {
  opacity: 1,
  color: 'rgba(35, 35, 35, 1)',
  dashArray: '',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 1,
  fillOpacity: 0,
  interactive: true,
};

export const style_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20_0 = {
  opacity: 1,
  color: 'rgba(255, 0, 255, 1.0)',
  dashArray: '',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 5.0,
  fillOpacity: 0,
  interactive: true,
};

export const style_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20_1 = {
  opacity: 1,
  color: 'rgba(35, 35, 35, 1.0)',
  dashArray: '',
  lineCap: 'square',
  lineJoin: 'bevel',
  weight: 1.0,
  fillOpacity: 0,
  interactive: true,
};

// @ts-ignore
export const style_CNAE_EMPRESAS_total_1_0 = (feature) => {
  const defaultStyle = {
    stroke: false,
    fill: true,
    fillOpacity: 1,
    fillColor: 'rgba(125,8,33,1.0)',
    interactive: true,
  };
  if (
    feature.properties.Cons_Eng_T >= 2812.0 &&
    feature.properties.Cons_Eng_T <= 48656.0
  ) {
    return {
      radius: 2.0,
      ...defaultStyle,
    };
  }
  if (
    feature.properties.Cons_Eng_T >= 48656.0 &&
    feature.properties.Cons_Eng_T <= 246550.0
  ) {
    return {
      radius: 5.5,
      ...defaultStyle,
    };
  }
  if (
    feature.properties.Cons_Eng_T >= 246550.0 &&
    feature.properties.Cons_Eng_T <= 607886.0
  ) {
    return {
      radius: 9.0,
      ...defaultStyle,
    };
  }
  if (
    feature.properties.Cons_Eng_T >= 607886.0 &&
    feature.properties.Cons_Eng_T <= 964957.0
  ) {
    return {
      radius: 12.5,
      ...defaultStyle,
    };
  }
  if (
    feature.properties.Cons_Eng_T >= 964957.0 &&
    feature.properties.Cons_Eng_T <= 3173321.0
  ) {
    return {
      radius: 16.0,
      ...defaultStyle,
    };
  }
};

// @ts-ignore
// export function renderCustomMarkers(data, styleFunction, popupFunction) {
//   return data.features.map((feature, index) => (
//     <CircleMarker
//       key={index}
//       center={[
//         feature.geometry.coordinates[1],
//         feature.geometry.coordinates[0]
//       ]}
//       pathOptions={styleFunction(feature)}
//     >
//       {popupFunction(feature)}
//     </CircleMarker>
//   ));
// }

export const style_ATERROS_SANITARIOS_ATIVOS_PE_15_0 = {
  radius: 4.0,
  opacity: 1,
  color: 'rgba(35,35,35,1.0)',
  dashArray: '',
  lineCap: 'butt',
  lineJoin: 'miter',
  weight: 1,
  fill: true,
  fillOpacity: 1,
  fillColor: 'rgba(255,158,23,1.0)',
  interactive: true,
};

export const style_estacoes_solarimetricas_ifpe_16_0 = {
  rotationAngle: 0.0,
  rotationOrigin: 'center center',
  icon: L.icon({
    iconUrl: estacoes_solarimetricas,
    iconSize: [11.399999999999999, 11.399999999999999],
  }),
  interactive: true,
};
