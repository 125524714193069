import React, { useState } from 'react';
import SolarPowerIcon from '@mui/icons-material/LightModeOutlined';
import { Box } from '@mui/material';
import {
  EnergyBox,
  LargeEnergyBox,
  SmallEnergyBox,
  EnergyTypographyTitle,
  EnergyTypographyData,
  SolarPowerText,
  SolarIconContainer,
  ChangeText,
} from './styles';
import { GraphIrradiation } from '../EnergyGraphComponent';
import { WindComponent } from '../WindComponent';

interface EnergyProps {
  latitude: string | null;
  longitude: string | null;
  address: string | null;
  globalIrradiation: string | null;
  difuseIrradiation: string | null;
  directIrradiation: string | null;
  monthlyIrradiation: number[];
}

export const Energy: React.FC<EnergyProps> = ({
  latitude,
  longitude,
  address,
  globalIrradiation,
  difuseIrradiation,
  directIrradiation,
  monthlyIrradiation,
}) => {
  const [activeLayout, setActiveLayout] = useState<'solar' | 'eolico'>('solar');

  const handleSolarBoxClick = () => {
    setActiveLayout('eolico');
  };

  const renderSolarLayout = () => (
    <div>
      <EnergyBox>
        <Box sx={{ textAlign: 'center' }}>
          <EnergyTypographyTitle>Latitude</EnergyTypographyTitle>
          <EnergyTypographyData>{latitude || '-'}</EnergyTypographyData>
        </Box>
        <Box sx={{ textAlign: 'center', marginX: '24px' }}>
          <EnergyTypographyTitle>Longitude</EnergyTypographyTitle>
          <EnergyTypographyData>{longitude || '-'}</EnergyTypographyData>
        </Box>
        <Box sx={{ textAlign: 'center' }}>
          <EnergyTypographyTitle>Endereço</EnergyTypographyTitle>
          <EnergyTypographyData>{address || '-'}</EnergyTypographyData>
        </Box>
      </EnergyBox>

      <Box sx={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
        <LargeEnergyBox onClick={handleSolarBoxClick}>
          <SolarIconContainer>
            <SolarPowerIcon fontSize="large" />
            <SolarPowerText>Solar</SolarPowerText>
          </SolarIconContainer>
          <ChangeText>Clique para alterar</ChangeText>
        </LargeEnergyBox>

        <SmallEnergyBox>
          <EnergyTypographyTitle>
            Radiação solar global horizontal
          </EnergyTypographyTitle>
          <EnergyTypographyData>
            {globalIrradiation ? `${globalIrradiation} Wh/m²` : '-'}
          </EnergyTypographyData>
        </SmallEnergyBox>

        <SmallEnergyBox>
          <EnergyTypographyTitle>
            Radiação solar horizontal difusa
          </EnergyTypographyTitle>
          <EnergyTypographyData>
            {difuseIrradiation ? `${difuseIrradiation} Wh/m²` : '-'}
          </EnergyTypographyData>
        </SmallEnergyBox>
      </Box>

      <Box sx={{ display: 'flex', marginTop: '10px', gap: '10px' }}>
        <SmallEnergyBox>
          <EnergyTypographyTitle>
            Radiação solar horizontal direta
          </EnergyTypographyTitle>
          <EnergyTypographyData>
            {directIrradiation ? `${directIrradiation} Wh/m²` : '-'}
          </EnergyTypographyData>
        </SmallEnergyBox>

        <SmallEnergyBox>
          <EnergyTypographyTitle>Insolação</EnergyTypographyTitle>
          <EnergyTypographyData>-</EnergyTypographyData>
        </SmallEnergyBox>

        <SmallEnergyBox>
          <EnergyTypographyTitle>Albedo médio</EnergyTypographyTitle>
          <EnergyTypographyData>-</EnergyTypographyData>
        </SmallEnergyBox>

        <SmallEnergyBox>
          <EnergyTypographyTitle>Temperatura</EnergyTypographyTitle>
          <EnergyTypographyData>-</EnergyTypographyData>
        </SmallEnergyBox>
      </Box>
      <div style={{ marginTop: '20px' }}>
        <GraphIrradiation monthlyIrradiation={monthlyIrradiation} />
      </div>
    </div>
  );

  return (
    <>
      {activeLayout === 'solar' ? (
        renderSolarLayout()
      ) : (
        <WindComponent
          latitude={latitude}
          longitude={longitude}
          address={address}
          globalIrradiation={globalIrradiation}
          difuseIrradiation={difuseIrradiation}
          directIrradiation={directIrradiation}
          monthlyIrradiation={monthlyIrradiation}
        />
      )}
    </>
  );
};
