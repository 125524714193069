export var jsonEOLExpansaoPlanejada16 = {
  type: 'FeatureCollection',
  name: 'EOL__Expanso_Planejada_16',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Baraúnas XX',
        ceg: 'EOL.CV.BA.036993-4.01',
        ano_prev: '2023-12-31',
        potencia: 31185.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.012894389999985, -9.789150679999977],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Canudos I',
        ceg: 'EOL.CV.BA.046757-0.01',
        ano_prev: '2023-12-31',
        potencia: 49700.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.775069819999942, -10.189229079999961],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Canudos II',
        ceg: 'EOL.CV.BA.046758-8.01',
        ano_prev: '2023-12-31',
        potencia: 49700.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-38.77132040999993, -10.146645379999939],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '34º LEN',
        nome: 'Oeste Serido I',
        ceg: 'EOL.CV.RN.044974-1.01',
        ano_prev: '2025-12-31',
        potencia: 25200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.665798559999985, -6.760553699999946],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '33º LEN',
        nome: 'Oeste Serido II',
        ceg: 'EOL.CV.RN.044975-0.01',
        ano_prev: '2024-12-31',
        potencia: 21000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.675304149999931, -6.793988399999932],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '33º LEN',
        nome: 'Oeste Serido III',
        ceg: 'EOL.CV.RN.044976-8.01',
        ano_prev: '2024-12-31',
        potencia: 25200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.687452859999951, -6.847710069999948],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '33º LEN',
        nome: 'Oeste Seridó IV',
        ceg: 'EOL.CV.RN.047165-8.01',
        ano_prev: '2024-12-31',
        potencia: 25200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.659402159999956, -6.740646939999976],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '33º LEN',
        nome: 'Oeste Seridó IX',
        ceg: 'EOL.CV.RN.047162-3.01',
        ano_prev: '2024-12-31',
        potencia: 25200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.72409571999998, -6.783093079999958],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '34º LEN',
        nome: 'Oeste Seridó V',
        ceg: 'EOL.CV.RN.047166-6.01',
        ano_prev: '2025-12-31',
        potencia: 25200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.651703399999974, -6.724656779999975],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '34º LEN',
        nome: 'Oeste Seridó XI',
        ceg: 'EOL.CV.RN.047161-5.01',
        ano_prev: '2025-12-31',
        potencia: 33600.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.723895869999978, -6.815940069999954],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '29º LEN',
        nome: 'Oitis 1',
        ceg: 'EOL.CV.PI.044555-0.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.717691189999982, -9.004351089999943],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 10',
        ceg: 'EOL.CV.PI.044358-1.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.772819039999945, -9.05061429999995],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 2',
        ceg: 'EOL.CV.PI.044359-0.01',
        ano_prev: '2023-12-31',
        potencia: 27500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.727448559999971, -9.005101109999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 21',
        ceg: 'EOL.CV.BA.044360-3.01',
        ano_prev: '2023-12-31',
        potencia: 44000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.707053499999972, -9.053699629999926],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 22',
        ceg: 'EOL.CV.BA.044361-1.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.71480374999993, -9.073836229999927],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 3',
        ceg: 'EOL.CV.PI.044362-0.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.748460129999955, -9.00625483999994],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 4',
        ceg: 'EOL.CV.PI.044363-8.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.750009459999944, -9.022434179999948],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 5',
        ceg: 'EOL.CV.PI.044364-6.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.752748659999952, -9.039661639999963],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 6',
        ceg: 'EOL.CV.PI.044365-4.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.736395709999954, -9.05265019999996],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 7',
        ceg: 'EOL.CV.PI.044366-2.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.734972609999943, -9.071183],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '29º LEN',
        nome: 'Oitis 8',
        ceg: 'EOL.CV.PI.044367-0.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.760940139999946, -9.098210149999943],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Oitis 9',
        ceg: 'EOL.CV.PI.044368-9.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.773324, -9.053646209999954],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '26º LEN',
        nome: 'Ouro Branco 1',
        ceg: 'EOL.CV.PE-031807-8.01',
        ano_prev: '2023-12-31',
        potencia: 33600.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.750989019999963, -8.187651839999944],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '26º LEN',
        nome: 'Ouro Branco 2',
        ceg: 'EOL.CV.PE-031808-6.01',
        ano_prev: '2023-12-31',
        potencia: 33600.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.737648469999954, -8.177264159999936],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '26º LEN',
        nome: 'Quatro Ventos',
        ceg: 'EOL.CV.PE-031809-4.01',
        ano_prev: '2023-12-31',
        potencia: 21000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.493657549999966, -7.48559604999997],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '35º LEN',
        nome: 'Serra das Vacas B',
        ceg: 'EOL.CV.PE.049354-6.01',
        ano_prev: '2026-12-31',
        potencia: 44000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.759884089999957, -8.954323019999947],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '30º LEN',
        nome: 'Serra do Seridó II',
        ceg: 'EOL.CV.PB.035225-0.01',
        ano_prev: '2025-12-31',
        potencia: 33000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.759075849999931, -6.969681369999932],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '30º LEN',
        nome: 'Serra do Seridó III',
        ceg: 'EOL.CV.PB.035226-8.01',
        ano_prev: '2025-12-31',
        potencia: 44000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.793166689999964, -6.96893794999994],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '30º LEN',
        nome: 'Serra do Seridó IV',
        ceg: 'EOL.CV.PB.035227-6.01',
        ano_prev: '2025-12-31',
        potencia: 44000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.785883689999935, -6.961328869999932],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '30º LEN',
        nome: 'Serra do Seridó IX',
        ceg: 'EOL.CV.PB.040613-9.01',
        ano_prev: '2025-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.800132409999947, -7.001874059999977],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '30º LEN',
        nome: 'Serra do Seridó VI',
        ceg: 'EOL.CV.PB.038304-0.01',
        ano_prev: '2025-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.80143389999995, -6.973125619999962],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '30º LEN',
        nome: 'Serra do Seridó VII',
        ceg: 'EOL.CV.PB.038305-8.01',
        ano_prev: '2025-12-31',
        potencia: 44000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-36.78330251999995, -7.008808649999935],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de Santa Alexandrina',
        ceg: 'EOL.CV.PI.048511-0.01',
        ano_prev: '2023-12-31',
        potencia: 44000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.580234529999984, -7.985693659999981],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '33º LEN',
        nome: 'Ventos de Santa Luzia 11',
        ceg: 'EOL.CV.RN.051585-0.01',
        ano_prev: '2024-12-31',
        potencia: 5300.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.78961615999998, -6.453773729999966],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '33º LEN',
        nome: 'Ventos de Santa Luzia 12',
        ceg: 'EOL.CV.RN.051586-8.01',
        ano_prev: '2024-12-31',
        potencia: 5300.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.772153599999967, -6.425243739999928],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '33º LEN',
        nome: 'Ventos de Santa Luzia 13',
        ceg: 'EOL.CV.RN.051587-6.01',
        ano_prev: '2024-12-31',
        potencia: 5300.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.738241519999974, -6.385821459999931],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de Santo Antero',
        ceg: 'EOL.CV.PE.035249-7.01',
        ano_prev: '2023-12-31',
        potencia: 48400.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.51767512999993, -8.003669299999956],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '34º LEN',
        nome: 'Ventos de Santo Antonio 01',
        ceg: 'EOL.CV.RN.047205-0.01',
        ano_prev: '2025-12-31',
        potencia: 5300.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.751189919999945, -6.403661649999947],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de Santo Apolinário',
        ceg: 'EOL.CV.PI.048513-6.01',
        ano_prev: '2023-12-31',
        potencia: 35200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.599596039999938, -7.970509799999945],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Bernardo',
        ceg: 'EOL.CV.PE.035250-0.01',
        ano_prev: '2023-12-31',
        potencia: 44000.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.513186639999958, -7.996804429999941],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Caio',
        ceg: 'EOL.CV.PI.048514-4.01',
        ano_prev: '2023-12-31',
        potencia: 35200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.769658799999945, -7.99574008999997],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Ciríaco',
        ceg: 'EOL.CV.PI.048515-2.01',
        ano_prev: '2023-12-31',
        potencia: 48400.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.684191679999969, -8.047201439999981],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Ciro',
        ceg: 'EOL.CV.PI.048516-0.01',
        ano_prev: '2023-12-31',
        potencia: 35200.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.741439039999932, -8.037875889999953],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Crispim',
        ceg: 'EOL.CV.PI.048704-0.01',
        ano_prev: '2023-12-31',
        potencia: 48400.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.676379219999944, -8.035750339999936],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São João Paulo II',
        ceg: 'EOL.CV.PI.048517-9.01',
        ano_prev: '2023-12-31',
        potencia: 30800.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-40.560210479999967, -7.986854759999972],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Roque 11',
        ceg: 'EOL.CV.PI.040629-5.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.626931279999951, -8.826359069999967],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Roque 16',
        ceg: 'EOL.CV.PI.038110-1.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.591134309999973, -8.899756789999969],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Roque 17',
        ceg: 'EOL.CV.PI.038111-0.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.555462209999973, -8.897491289999948],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Roque 04',
        ceg: 'EOL.CV.PI.038106-3.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.590799199999935, -8.829935699999965],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: 'ANEEL',
        nome: 'Ventos de São Roque 08',
        ceg: 'EOL.CV.PI.040626-0.01',
        ano_prev: '2023-12-31',
        potencia: 49500.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.604470309999954, -8.935202979999929],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '36º LEN',
        nome: 'Baraúnas IV (Antiga Massaroca II)',
        ceg: 'EOL.CV.BA.038032-6.01',
        ano_prev: '2023-12-31',
        potencia: 41580.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-41.110137709999947, -9.674182179999946],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '36º LEN',
        nome: 'Serra da Borborema I',
        ceg: 'EOL.CV.PB.044988-1.01',
        ano_prev: '2026-12-31',
        potencia: 43400.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.981265679999979, -7.034211149999976],
      },
    },
    {
      type: 'Feature',
      properties: {
        leilao: '36º LEN',
        nome: 'Serra da Borborema II',
        ceg: 'EOL.CV.PB.044989-0.01',
        ano_prev: '2026-12-31',
        potencia: 49600.0,
        created_us: 'BERNARDO.OLIVEIRA',
        created_da: '2023-04-04',
        last_edite: 'BERNARDO.OLIVEIRA',
        last_edi_1: '2023-04-04',
      },
      geometry: {
        type: 'Point',
        coordinates: [-35.980661269999985, -7.045760449999932],
      },
    },
  ],
};
