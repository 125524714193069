import styled, { css } from 'styled-components';
import { Box } from '@mui/material';

export const LegendContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 0px;
  margin-top: 325px;
  overflow-y: hidden;
`;

export const LegendWrapper = styled(Box)`
  display: flex;
  color: #707070;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f5f5f5;
  &:hover,
  &:active,
  &.active {
    cursor: pointer;
    color: #f5f5f5;
    background-color: ${({ theme }) => theme.colors.greenDark};
  }
`;

export const IconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  margin-top: 325px;
`;

export const IconWrapper = styled(Box)`
  display: flex;
  color: #707070;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  margin-left: 20px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f5f5f5;
  &:hover,
  &:active,
  &.active {
    cursor: pointer;
    color: #f5f5f5;
    background-color: ${({ theme }) => theme.colors.greenDark};
  }
`;
