export var json_TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20 = {
  type: 'FeatureCollection',
  name: 'TRA_COBRE_REVESTIDO_REDE_COPERGS_01092023_20',
  crs: { type: 'name', properties: { name: 'urn:ogc:def:crs:OGC:1.3:CRS84' } },
  features: [
    {
      type: 'Feature',
      properties: {
        id: 1.0,
        cod_seg_ga: 108537.0,
        cod_status: 'EM OPERAÇÃO',
        cod_materi: 'CABO COBRE REVESTIDO',
        cod_diam_e: 1.0,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.941966676660016, -8.026906742006874],
            [-34.942724676924662, -8.026874609655055],
            [-34.942763069170802, -8.026877344326275],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: 2.0,
        cod_seg_ga: 104122.0,
        cod_status: 'EM OPERAÇÃO',
        cod_materi: 'CABO COBRE REVESTIDO',
        cod_diam_e: 1.0,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-35.011922863532952, -8.402323975322009],
            [-35.011405693717975, -8.402326618554799],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: 3.0,
        cod_seg_ga: 95230.0,
        cod_status: 'EM OPERAÇÃO',
        cod_materi: 'CABO COBRE REVESTIDO',
        cod_diam_e: 1.0,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-36.429744356044594, -8.336462762168308],
            [-36.429789589542992, -8.336466651865377],
            [-36.429834823043869, -8.336470541557496],
            [-36.429879927288319, -8.336475325671264],
            [-36.429924932472673, -8.336481008021721],
            [-36.429969937659877, -8.336486690367423],
            [-36.430014942849908, -8.336492372708603],
            [-36.430059948042782, -8.336498055045009],
            [-36.430104953238519, -8.336503737376537],
            [-36.430149958437077, -8.33650941970348],
            [-36.43086235916352, -8.336545008409889],
            [-36.430883832874827, -8.336565239366067],
          ],
        ],
      },
    },
    {
      type: 'Feature',
      properties: {
        id: 4.0,
        cod_seg_ga: 92323.0,
        cod_status: 'EM OPERAÇÃO',
        cod_materi: 'CABO COBRE REVESTIDO',
        cod_diam_e: 1.0,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: [
          [
            [-34.9325068660049, -8.062968389589576],
            [-34.932611783385909, -8.062726565661395],
          ],
        ],
      },
    },
  ],
};
